import { BehaviorSubject, scan, shareReplay } from 'rxjs';

export const hiddenConnections$ = new BehaviorSubject<{
  from?: string | string[];
  to?: string | string[];
  action?: 'add' | 'remove' | 'load' | 'clear';
}>({});

export const hiddenConnectionsPipe$ = hiddenConnections$.pipe(
  scan(
    (currentValue, newValue) => {
      currentValue.lastAction = newValue.action || '';
      switch (newValue.action) {
        case 'add':
          if (newValue.to && typeof newValue.to === 'string') {
            currentValue.to.add(newValue.to);
          }
          if (newValue.from && typeof newValue.from === 'string') {
            currentValue.from.add(newValue.from);
          }
          break;
        case 'remove':
          if (newValue.to && typeof newValue.to === 'string') {
            currentValue.to.delete(newValue.to);
          }
          if (newValue.from && typeof newValue.from === 'string') {
            currentValue.from.delete(newValue.from);
          }
          break;
        case 'load':
          if (newValue.to) {
            currentValue.to = new Set(newValue.to);
          }
          if (newValue.from) {
            currentValue.from = new Set(newValue.from);
          }
          break;
        case 'clear':
          currentValue.to.clear();
          currentValue.from.clear();
          break;
      }
      return currentValue;
    },
    {
      from: new Set<string>(),
      to: new Set<string>(),
      lastAction: '',
    }
  ),
  shareReplay(1)
);
