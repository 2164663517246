import { getRandomString, uniquify, findDuplicates } from './common';
export var IntentItemType;
(function (IntentItemType) {
    IntentItemType[IntentItemType["phrase"] = 0] = "phrase";
    IntentItemType[IntentItemType["pattern"] = 1] = "pattern";
})(IntentItemType || (IntentItemType = {}));
export const FAQBaseIntentName = 'KnowledgeBase';
export function generateFAQPathByTemplateName(name) {
    return `/${FAQBaseIntentName}/${name}`;
}
export const getFAQTemplateName = (path) => {
    const parts = path.split('/').filter(Boolean);
    if (parts.length > 1 && parts[0] === FAQBaseIntentName) {
        return parts[1];
    }
    return '';
};
export const mapIntentsToTree = (intents) => intents.reduce(addIntentToDataset, {});
export const addIntentToDataset = (dataset, intent) => {
    const node = toTreeNode(intent);
    getAllParentPathsReverse(node.path).forEach((element, i, array) => addChildToNode(dataset, element, array[i - 1] || node.path, node.itemsCount));
    const optionalNode = dataset[node.path];
    if (optionalNode) {
        node.children = optionalNode.children;
        node.childrenItemsCount = optionalNode.childrenItemsCount;
    }
    return addNode(dataset, node);
};
export const getAllParentPathsReverse = (path) => {
    const parents = [];
    for (let i = path.length - 1; i > 0; i--) {
        if (path[i] === '/')
            parents.push(path.slice(0, i));
    }
    return parents;
};
const addChildToNode = (dataset, path, childPath, itemsCount) => {
    const optionalNode = dataset[path];
    const node = optionalNode ? optionalNode : createGhostTreeNode(path);
    node.children = uniquify([...node.children, childPath]);
    node.childrenItemsCount = node.childrenItemsCount + itemsCount;
    return addNode(dataset, node);
};
const replaceChildInNode = (dataset, node, oldChild, newChild) => {
    const oldChildIndex = node.children.findIndex(child => child === oldChild);
    node.children[oldChildIndex] = newChild;
    return addNode(dataset, node);
};
export const toTreeNode = (intent) => ({
    id: Number(intent.id),
    nodeId: intent.path ? intent.path : '',
    path: intent.path ? intent.path : '',
    enabled: Boolean(intent.enabled),
    name: getNameFromPath(intent.path),
    parent: getParentPathFromPath(intent.path),
    itemsCount: intent.itemsCount ? intent.itemsCount : 0,
    children: [],
    childrenItemsCount: 0,
    isGhostNode: false,
});
const createGhostTreeNode = (path) => ({
    id: NaN,
    nodeId: path,
    path,
    enabled: false,
    name: getNameFromPath(path),
    parent: getParentPathFromPath(path),
    itemsCount: 0,
    children: [],
    childrenItemsCount: 0,
    isGhostNode: true,
});
export const getNameFromPath = (path) => (path ? path.substring(path.lastIndexOf('/') + 1) : '');
export const getParentPathFromPath = (path) => (path ? path.substring(0, path.lastIndexOf('/')) : '');
export const getItemsCountString = (node) => node.childrenItemsCount ? `${node.itemsCount} / ${node.childrenItemsCount}` : `${node.itemsCount}`;
export const getNewUniquePath = (defaultNewIntentName, parentPath, tree) => {
    const simplePath = `${parentPath}/${defaultNewIntentName}`;
    if (!tree) {
        return `${simplePath}_${(Math.random() * 10000).toFixed()}`;
    }
    if (!isIntentWithPathExists(simplePath, tree))
        return simplePath;
    for (let index = 1; index <= Infinity; index++) {
        const path = `${parentPath}/${defaultNewIntentName} ${index}`;
        if (!isIntentWithPathExists(path, tree))
            return path;
    }
    return `${parentPath}/${defaultNewIntentName} ${getRandomString()}`;
};
const isIntentWithPathExists = (path, tree) => Object.values(tree).findIndex(node => node.path === path) > -1;
export const updateIntentPathInTree = (dataset, oldPath, newPath) => {
    const newDataset = Object.assign({}, dataset);
    const parentPath = newDataset[oldPath].parent;
    if (parentPath)
        replaceChildInNode(newDataset, newDataset[parentPath], oldPath, newPath);
    return updatePathInTree(newDataset, oldPath, newPath);
};
const updatePathInTree = (dataset, oldPath, newPath) => {
    const node = dataset[oldPath];
    node.path = newPath;
    node.nodeId = newPath;
    node.name = getNameFromPath(newPath);
    const oldChildren = node.children;
    node.children = node.children.map(child => newPath + child.slice(oldPath.length));
    if (node.parent)
        node.parent = getParentPathFromPath(newPath);
    delete dataset[oldPath];
    addNode(dataset, node);
    return oldChildren.length
        ? oldChildren.reduce((acc, child, childIndex) => updatePathInTree(acc, child, node.children[childIndex]), dataset)
        : dataset;
};
export const updateIntentItemsCountInTree = (dataset, path, newItemsCount) => {
    const newDataset = Object.assign({}, dataset);
    const node = newDataset[path];
    addNode(newDataset, Object.assign(Object.assign({}, node), { itemsCount: newItemsCount }));
    const diff = newItemsCount - node.itemsCount;
    return updateChildrenItemsCountUpToRoot(newDataset, node.parent, diff);
};
const updateChildrenItemsCountUpToRoot = (dataset, path, diff) => {
    if (!path)
        return dataset;
    const node = dataset[path];
    addNode(dataset, Object.assign(Object.assign({}, node), { childrenItemsCount: node.childrenItemsCount + diff }));
    return updateChildrenItemsCountUpToRoot(dataset, node.parent, diff);
};
export const updateIntentEnabledInTree = (tree, path, enabled) => {
    const updatedNodes = {};
    updateEnabled(tree, path, enabled, updatedNodes);
    return Object.assign(Object.assign({}, tree), updatedNodes);
};
const updateEnabled = (dataset, path, enabled, updatedNodes) => {
    updatedNodes[path] = Object.assign(Object.assign({}, dataset[path]), { enabled });
    dataset[path].children.forEach(childPath => updateEnabled(dataset, childPath, enabled, updatedNodes));
};
export const mergeNewIntentIntoTree = (intent, dataset) => {
    const newDataset = Object.assign({}, dataset);
    const node = toTreeNode(toSummaryData(intent));
    addNode(newDataset, node);
    if (!node.parent)
        return newDataset;
    const parent = dataset[node.parent];
    //TODO: Add logic to create ghost nodes
    if (!parent)
        return newDataset;
    return addChildToNode(newDataset, node.parent, node.path, 0);
};
export const replaceGhostNodeWithRealNode = (intent, path, dataset) => {
    const newDataset = Object.assign({}, dataset);
    const node = toTreeNode(toSummaryData(intent));
    const ghostNode = dataset[path];
    node.children = ghostNode.children;
    node.childrenItemsCount = ghostNode.childrenItemsCount;
    return addNode(newDataset, node);
};
export const removeIntentsFromTree = (removedIds, dataset) => Object.values(dataset)
    .filter(({ id }) => !removedIds.includes(id))
    .map(node => node.children
    ? Object.assign(Object.assign({}, node), { children: node.children.filter(childPath => dataset[childPath] && !removedIds.includes(dataset[childPath].id)) }) : node)
    .reduce(addNode, {});
const addNode = (dataset, node) => Object.assign(dataset, { [node.nodeId]: node });
export const getRealChildrenIds = (dataset, selectedIntentPaths) => {
    const realChildrenIds = [];
    selectedIntentPaths.forEach(path => updateRealChildrenIds(dataset, path, realChildrenIds));
    return uniquify(realChildrenIds);
};
const updateRealChildrenIds = (dataset, path, realChildrenIds) => {
    if (!dataset[path].isGhostNode) {
        realChildrenIds.push(dataset[path].id);
    }
    dataset[path].children.forEach(childPath => updateRealChildrenIds(dataset, childPath, realChildrenIds));
};
export const getDuplicateSlotNames = (slots) => {
    const slotsNames = slots ? slots.map(slot => String(slot.name)) : [];
    return findDuplicates(slotsNames);
};
export const getEmptySlotNames = (slots) => slots
    ? slots.filter(slot => !slot.name || !/\S/.test(String(slot.name)) || !slot.entity).map(slot => String(slot.name))
    : [];
export const toIntentItem = (text, type) => ({
    type: type,
    text: text,
});
export const phraseToIntentItem = (phrase) => {
    return toIntentItem(phrase.text || '', IntentItemType.phrase);
};
export const patternToIntentItem = (pattern) => {
    return toIntentItem(pattern || '', IntentItemType.pattern);
};
const intentItemToPhrase = (item) => ({
    text: item.text,
});
const intentItemToPattern = (item) => item.text;
const splitItemsByType = (items) => items.reduce((acc, item) => Object.assign(acc, {
    [item.type]: [
        ...acc[item.type],
        item.type === IntentItemType.phrase ? intentItemToPhrase(item) : intentItemToPattern(item),
    ],
}), { [IntentItemType.phrase]: [], [IntentItemType.pattern]: [] });
export const updateItems = (intent, items) => {
    const phrasesAndPatterns = splitItemsByType(items);
    return Object.assign(Object.assign({}, intent), { phrases: phrasesAndPatterns[IntentItemType.phrase], patterns: phrasesAndPatterns[IntentItemType.pattern] });
};
export const convertToIntentWithItems = (intent) => {
    const denulledIntent = Object.assign(Object.assign({}, intent), { phrases: (intent === null || intent === void 0 ? void 0 : intent.phrases) ? intent === null || intent === void 0 ? void 0 : intent.phrases : [], patterns: (intent === null || intent === void 0 ? void 0 : intent.patterns) ? intent === null || intent === void 0 ? void 0 : intent.patterns : [] });
    return {
        intent: denulledIntent,
        items: [...denulledIntent.phrases.map(phraseToIntentItem), ...denulledIntent.patterns.map(patternToIntentItem)],
    };
};
export const toGroup = (intent) => ({
    intentId: intent.id,
    name: intent.path,
    size: intent.stagedPhrases ? intent.stagedPhrases.length : 0,
    phrasesIndexes: intent.stagedPhrases ? intent.stagedPhrases.map(phraseIdx => ({ phraseIdx })) : [],
});
const toSummaryData = (intent) => ({
    id: Number(intent.id),
    path: String(intent.path),
    enabled: Boolean(intent.enabled),
    itemsCount: intent.phrases ? intent.phrases.length : 0,
});
export const isValidPath = (path) => path && !path.endsWith('/') && !path.match(/\/[\s]+$/);
