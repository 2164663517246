import {
  DIAGRAM_INIT,
  DIAGRAM_SESSION,
  DIAGRAM_INIT_LABEL_TIME,
  TOGGLE_COMMON_CHART_LABELS,
  TOGGLE_SESSION_AND_LABEL_CHART_LABELS,
  DIAGRAM_DIALOGS,
  TOGGLE_COMMON_CHART_LABELS_PHRASE,
} from '../../constants/diagramAnalytics.actions';
import { axios } from '../../pipes/functions';

export const initDiagram = (projectShortName, params) => {
  return {
    type: DIAGRAM_INIT,
    payload: axios.get(`/restapi/logs/${projectShortName}/stats`, { params: params }),
  };
};
export const initDiagramSession = (projectShortName, params, action = null) => {
  return {
    type: DIAGRAM_SESSION,
    payload: action
      ? action()
      : axios.get(`/restapi/logs/${projectShortName}/sessions-labels-stats`, { params: params }),
  };
};
export const initDiagramDialogs = (projectShortName, params, action = null) => {
  return {
    type: DIAGRAM_DIALOGS,
    payload: action ? action() : axios.get(`/restapi/logs/${projectShortName}/logs-labels-stats`, { params: params }),
  };
};
export const initDiagramLabelTime = (projectShortName, params) => {
  return {
    type: DIAGRAM_INIT_LABEL_TIME,
    payload: axios.get(`/restapi/logs/${projectShortName}/sessions-stats/labels-time`, { params: params }),
  };
};
export const toggleCommonChartLabels = (name, flag) => {
  return {
    type: TOGGLE_COMMON_CHART_LABELS,
    name,
    flag,
  };
};
export const toggleCommonPhraseLabels = (name, flag) => {
  return {
    type: TOGGLE_COMMON_CHART_LABELS_PHRASE,
    name,
    flag,
  };
};
export const toggleSessionChartLabels = (name, flag) => {
  return {
    type: TOGGLE_SESSION_AND_LABEL_CHART_LABELS,
    name,
    flag,
  };
};
