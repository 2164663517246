import insetJsxInRawTest from './insetJsxInRawTest';

type SearchMatch = { from: number; to: number; text: string };

export default function insertJSXMatchesToAdjustedText(
  text: string,
  matches: SearchMatch[],
  renderMatch: (match: SearchMatch) => JSX.Element,
  config?: {
    charCountInLine?: number;
  }
) {
  let offset = 0;
  let displayedContent = text;

  if (config?.charCountInLine) {
    const charCountInHalfLine = config.charCountInLine / 2;
    const isContentBiggerThenLineSize = text.length > config.charCountInLine;
    const firstMatch = matches[0];
    if (isContentBiggerThenLineSize && firstMatch.from > charCountInHalfLine) {
      offset = firstMatch.from - charCountInHalfLine;
      displayedContent = displayedContent.substring(offset);
    }
  }

  return [
    offset > 0 ? '...' : '',
    ...insetJsxInRawTest(
      displayedContent,
      matches.map(el => ({
        start: el.from - offset,
        end: el.to - offset,
        jsx: renderMatch(el),
      }))
    ),
  ];
}
