import { GET_SALES_REQUEST_DATA, SEND_SALES_REQUEST } from '../../constants/salesRequest.actions';
import { axios } from '../../pipes/functions';

export const getSalesRequestData = () => {
  return {
    type: GET_SALES_REQUEST_DATA,
    payload: axios.get('/restapi/sales-request'),
  };
};

export const sendSalesRequest = obj => {
  return {
    type: SEND_SALES_REQUEST,
    payload: axios.post('/restapi/sales-request', obj),
  };
};
