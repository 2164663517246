import { axios } from '../../../pipes/functions';

import { QueryLocation, ReplaceInfo, SearchApi } from '../api/client';

export default class SearchService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private projectShortName: string;
  private searchApi: SearchApi;

  constructor(accountId: number, projectShortName: string) {
    this.accountId = accountId;
    this.projectShortName = projectShortName;
    this.searchApi = new SearchApi({}, SearchService.BASE_PATH, axios);
  }

  search = async (
    query: string,
    fileExtensions?: string[],
    isRegex?: boolean,
    isCaseSensitive?: boolean,
    isWholeWord?: boolean,
    isHiddenFiles?: boolean
  ) => {
    const { data: result } = await this.searchApi.search(
      this.accountId,
      this.projectShortName,
      query,
      fileExtensions,
      isRegex,
      isCaseSensitive,
      isWholeWord,
      isHiddenFiles
    );
    return result;
  };

  replace = async (replaceValue: string, queryLocation: QueryLocation) => {
    await this.searchApi.replace(this.accountId, this.projectShortName, {
      substitution: replaceValue,
      replaceInfos: [{ fileName: queryLocation.fileName, queryLocations: [queryLocation] }],
    });
  };

  replaceAll = async (replaceValue: string, replaceInfos: ReplaceInfo[]) => {
    await this.searchApi.replace(this.accountId, this.projectShortName, {
      substitution: replaceValue,
      replaceInfos,
    });
  };

  searchFull = async (
    query: string,
    fileExtensions?: string[],
    isRegex?: boolean,
    isCaseSensitive?: boolean,
    isWholeWord?: boolean
  ) => {
    const { data: result } = await this.searchApi.searchStatistics(
      this.accountId,
      this.projectShortName,
      query,
      fileExtensions,
      isRegex,
      isCaseSensitive,
      isWholeWord
    );
    return result;
  };
}
