import { Modal } from '@just-ai/just-ui';
import { t } from 'localization';
import React, { FC, useCallback, useEffect, useState } from 'react';

import classes from './Activations.module.scss';

import { CailaIntentsSimple } from './CailaIntentsSimple';

export const EditIntentsModal: FC<{
  open: boolean;
  toggle: () => unknown;
  externalEditId: string;
  chooseIntent: (id: string) => unknown;
}> = ({ open, toggle, externalEditId = '', chooseIntent }) => {
  const [id, setId] = useState<string>('');
  useEffect(() => {
    setId(externalEditId);
  }, [externalEditId]);

  const onConfirm = useCallback(() => {
    chooseIntent(id);
  }, [chooseIntent, id]);

  const handleToggle = useCallback(async () => {
    // await dispatch(cailaIntentChange());
    toggle();
  }, [toggle]);

  return (
    <Modal
      size='lg'
      className={classes.CreateCailaIntentModal}
      isOpen={open}
      title={t(`EditIntentsModal:title`)}
      buttonSubmitColor='primary'
      buttonSubmitText={t('Choose ready intent choose')}
      buttonSubmitDisabled={!id}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Close')}
      onCancelClick={handleToggle}
      onActionClick={onConfirm}
    >
      <CailaIntentsSimple intentId={id} onSelectIntent={setId} />
    </Modal>
  );
};
