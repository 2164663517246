import React from 'react';

import { t } from 'localization';

import { ClipboardService } from 'services/ClipboardService';

import { getKnowledgeBaseStateTemplate } from '../../external';

import { KnowledgeBaseIntegration } from '../../domain/types';

import styles from './styles.module.scss';

const copyCode = (template: string, secret: string) => {
  const state = getKnowledgeBaseStateTemplate();
  state.blocks[1].tagValue = template.replaceAll('${secretName}', secret);
  void ClipboardService.copyToClipboard(JSON.stringify(state));
};

interface CopyKnowledgeBaseStateInJgraphProps {
  template: string;
  base: KnowledgeBaseIntegration;
}
export const CopyKnowledgeBaseStateInJgraph = React.memo(({ base, template }: CopyKnowledgeBaseStateInJgraphProps) => {
  return (
    <div className={styles.CopyKnowledgeBaseStateInJgraph} onClick={() => copyCode(template, base.secretName)}>
      <div>{t('KhubIntegration:IntegrationList:CopyJGraph')}</div>
      <div className={styles.subTitle}>{t('KhubIntegration:IntegrationList:CopyJGraph:Description')}</div>
    </div>
  );
});

CopyKnowledgeBaseStateInJgraph.displayName = 'CopyKnowledgeBaseStateInJgraph';
