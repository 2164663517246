import React, { useRef, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

import { DebugIsRunning } from '../DebugIsRunning';
import LeftSideBar from '../LeftSideBar';
import { RightSideMenuWrapper } from '../RightSideMenu';
import SearchState from '../SearchState.feature';
import { CreationScreenMenu } from '../CreationScreenMenu';
import ThemeCreationMenu from '../ThemeCreationMenu';

import styles from './styles.module.scss';
import AppErrorBoundary from 'components/AppErrorBoundary';

type JGraphHudInfoType = {
  position: { x: number; y: number };
  size: { width: number; height: number };
};
export const JGraphHudInfo = new BehaviorSubject<JGraphHudInfoType>({
  position: { x: 0, y: 0 },
  size: { width: 0, height: 0 },
});

const JGraphHud = () => {
  const hudWindowContainer = useRef<HTMLDivElement | null>(null);
  const hudContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!hudWindowContainer.current) return;
    let observer = new ResizeObserver(() => {
      if (!hudWindowContainer.current || !hudContainer.current) return;
      const hudWindowRect = hudWindowContainer.current.getBoundingClientRect();
      const hudContainerRect = hudContainer.current.getBoundingClientRect();

      JGraphHudInfo.next({
        position: {
          x: hudWindowRect.x - hudContainerRect.x,
          y: hudWindowRect.y - hudContainerRect.y,
        },
        size: {
          width: hudWindowRect.width,
          height: hudWindowRect.height,
        },
      });
    });
    observer.observe(hudWindowContainer.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div className={styles.JGraphHud} ref={hudContainer}>
      <AppErrorBoundary prefix='JGraph:LeftSideBar'>
        <LeftSideBar />
      </AppErrorBoundary>
      <div className={styles.canvasArea} ref={hudWindowContainer}>
        <div id='JGHud-stage-ui' />
        <DebugIsRunning />
        <SearchState />
        <CreationScreenMenu />
        <ThemeCreationMenu />
      </div>
      <AppErrorBoundary prefix='JGraph:RightSideMenu'>
        <RightSideMenuWrapper />
      </AppErrorBoundary>
      <div id='JGHud-container' />
    </div>
  );
};

JGraphHud.displayName = 'JGraphHud';

export default React.memo(JGraphHud);
