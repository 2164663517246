import { TestWidgetApi } from '../api/client';
import { axios } from '../../../pipes/functions';

const BASE_PATH = '/restapi';
const SCRIPT_TAG_REGEX =
  /<script[-a-zA-Z0-9@:%_ '"+.~#?&/=]* src="([-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?)"[-a-zA-Z0-9@:%_ +.~#?&/=]*><\/script>/;
const api = new TestWidgetApi({}, BASE_PATH, axios);

export const getUrl = (widgetImport: string) => {
  const matchArray = widgetImport.match(SCRIPT_TAG_REGEX);
  if (!matchArray || matchArray.length < 2) throw new Error('TestWidgetService.error.UrlNotFond');
  return matchArray[1];
};

export const getWidgetUrl = async (projectShortName: string, forceDeploy?: boolean, skipTests: boolean = false) => {
  const { data: widgetImportString } = await api.publish(projectShortName, forceDeploy, skipTests);
  return getUrl(widgetImportString);
};

export const getVoiceWidgetUrl = async (projectShortName: string, skipTests: boolean = false) => {
  const { data: widgetImportString } = await axios.post(
    `/restapi/botconfig/voicetestwidget/${projectShortName}/publish`,
    {
      skipTests,
    }
  );
  return getUrl(widgetImportString);
};
