export const ThemesInformerModalLocalization = {
  'ThemesInformerModal:Title': {
    ru: 'Управляйте темами в J-Graph',
    eng: 'Manage themes in J-Graph',
  },
  'ThemesInformerModal:Next': {
    ru: 'Далее',
    eng: 'Next',
  },
  'ThemesInformerModal:Done': {
    ru: 'Отлично!',
    eng: 'OK',
  },
  'ThemesInformerModal:Step:1:Title': {
    ru: 'Что такое тема?',
    eng: 'What is theme?',
  },
  'ThemesInformerModal:Step:1:Description': {
    ru: 'Тема — это группа шагов в сценарии.\n\nРазделите сценарий на темы, чтобы сделать его структуру более простой и понятной.\n\n Подробнее о темах читайте в <a href="#">документации</a>.',
    eng: 'A theme is a group of states within a script. \n\nDivide the script into themes to make its structure simpler and clearer. \n\nLearn more about themes in the <a href="#">documentation</a>.',
  },
  'ThemesInformerModal:Step:2:Title': {
    ru: 'Выбор тем',
    eng: 'Select themes',
  },
  'ThemesInformerModal:Step:2:Description': {
    ru: 'По умолчанию в сценарии есть одна тема — <b>Корневая тема</b>. Чтобы выбрать тему или создать новую, перейдите на <b>уровень тем</b>.',
    eng: 'By default, the script has one theme — the <b>Root theme</b>. To select a theme or create a new one, go to the theme level.',
  },

  'ThemesInformerModal:Step:3:Title': {
    ru: 'Связи между темами',
    eng: 'Links between themes',
  },
  'ThemesInformerModal:Step:3:Description': {
    ru: 'Теперь вы можете посмотреть, к каким темам относятся связанные шаги.',
    eng: 'Now you can view the themes of the linked states.',
  },
};
