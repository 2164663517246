import { skipUntil, Subject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

export type RevertEvent<PAYLOAD = object, RESULT = object, PREV_STATE = RootState> = {
  type: string;
  payload: PAYLOAD;
  result: RESULT;
  prevState: PREV_STATE;
};

export type RevertAction<PAYLOAD = object, RESULT = object, PREV_STATE = RootState> = {
  event: RevertEvent<PAYLOAD, RESULT, PREV_STATE>;
  action: () => Promise<void>;
};

export const rollbackUserEvent$: Subject<any> = new Subject();

class JGraphRollbackService {
  private events: RevertAction[] = [];
  private rollbackUserEventSubscription?: Subscription;

  constructor(private saveCount: number) {}

  public init() {
    this.unsubscribeAll();
    this.rollbackUserEventSubscription = rollbackUserEvent$.pipe(skipUntil(mainSave$)).subscribe(() => {
      const lastEvent = this.popEvent();
      if (!lastEvent) return;
      return mainSave$.next({
        type: lastEvent.event.type,
        path: '',
        action: () => lastEvent.action(),
      });
    });
  }

  public unsubscribeAll() {
    this.events = [];
    this.rollbackUserEventSubscription?.unsubscribe();
  }

  public rollback() {
    rollbackUserEvent$.next(null);
  }

  public popEvent(): RevertAction | undefined {
    return this.events.pop();
  }

  public pushEvent(revertAction: RevertAction) {
    if (this.events.length >= this.saveCount) {
      const extraElementsCount = this.events.length - this.saveCount;
      this.events = this.events.slice(extraElementsCount + 1);
    }
    this.events.push(revertAction);
    return;
  }

  public addRevertAsyncDecorator = <Returned, ThunkArg = void>(
    rollback: AsyncThunk<any, any, {}>,
    actionFn: AsyncThunk<Returned, ThunkArg, {}>
  ): AsyncThunk<Returned, ThunkArg, {}> => {
    const type = actionFn.typePrefix;
    let localDispatch: ((action: object) => void) | null = null;

    const returnFn = (arg: any) => {
      return (dispatch: (action: object) => void, getState: () => RootState, extra: any) => {
        localDispatch = dispatch;
        const prevState = cloneDeep(getState());
        // @ts-ignore
        return actionFn(arg)(dispatch, getState, extra)
          .unwrap()
          .then((data: any) => {
            const event: RevertEvent = {
              type,
              payload: arg,
              result: data,
              prevState,
            };
            this.pushEvent({
              event,
              action: () => {
                if (!localDispatch) return Promise.resolve();
                return Promise.resolve(localDispatch(rollback(event)));
              },
            });
            return data;
          });
      };
    };
    return Object.assign(returnFn, actionFn);
  };
}

const JGraphRollbackServiceInstance = new JGraphRollbackService(50);

export default JGraphRollbackServiceInstance;
