import { makeSplines, updateUsingStageProps } from './connectorsLayer';
import { RefObject, useEffect, useState, useCallback } from 'react';
import Konva from 'konva';
import { useVisualEditorContext, VisualEditorContextType } from '../../../context/VisualEditorContext';
import { Block, BlockPositionNN } from '../../../model/Block';
import { Connection } from '../../../model/Graph';
import { KonvaEventObject } from 'konva/lib/Node';
import { IRect, Vector2d } from 'konva/lib/types';

export const updateSplines = (splinesLayer: RefObject<Konva.Layer>, stage: RefObject<Konva.Stage>, block: Block) => {
  if (splinesLayer.current && stage.current) {
    const stageStage: Konva.Stage | null = stage.current;
    if (!stageStage) return;

    const targets = splinesLayer.current.children || [];
    targets.forEach(target => {
      const connector: Connection = target.getAttr('data');

      if (connector && (connector.from === block.id || connector.to === block.id)) {
        let points = makeSplines(stageStage, connector);
        if (points) {
          switch (target.className) {
            case 'Line':
              (target as Konva.Line).points(points);
              break;
            case 'RegularPolygon':
              (target as Konva.RegularPolygon).x(points[points.length - 2] - 4);
              (target as Konva.RegularPolygon).y(points[points.length - 1]);
              break;
            default:
              break;
          }
        }
      }
    });
    splinesLayer.current.batchDraw();
  }
};

export const bgAnimation = (stage: RefObject<Konva.Stage>, position: { x: number; y: number }) => {
  const bg: HTMLDivElement = stage.current?.content.parentNode as HTMLDivElement;
  if (bg) {
    bg.style.backgroundPositionX = parseInt(bg.style.backgroundPositionX) + position.x + 'px';
    bg.style.backgroundPositionY = parseInt(bg.style.backgroundPositionY) + position.y + 'px';
  }
  moveAddingMenu(stage);
  moveAceEditor(stage);
};

export const getAddingButtonId = (block: Block) => `${block.id}_addingButton`;

export const getAddingButton = (stage: Konva.Stage, block: Block) => stage.findOne(`#${getAddingButtonId(block)}`);

const moveAddingMenu = (stage?: RefObject<Konva.Stage>, stageStage?: Konva.Stage | null) => {
  let localStage = stageStage;
  if (stage && !stageStage) {
    localStage = stage.current;
  }
  if (localStage) {
    const { addingIntoBlock }: VisualEditorContextType = localStage.getAttr('context');
    if (addingIntoBlock) {
      const addingButton = getAddingButton(localStage, addingIntoBlock);
      const offset = localStage.content.getBoundingClientRect();
      if (!addingButton) return;
      const addingButtonPosition = addingButton.getAbsolutePosition();
      const VisualEditorAddingMenu = document.getElementById('VisualEditorAddingActionMenu');

      if (VisualEditorAddingMenu) {
        if (
          addingButtonPosition.x + offset.left + 150 >= window.innerWidth ||
          addingButtonPosition.y + offset.top + VisualEditorAddingMenu.clientHeight >= window.innerHeight
        ) {
          VisualEditorAddingMenu.style.display = 'none';
        } else {
          VisualEditorAddingMenu.style.display = 'block';
        }

        VisualEditorAddingMenu.style.top = addingButtonPosition.y + offset.top + 'px';
        VisualEditorAddingMenu.style.left = addingButtonPosition.x + offset.left + 'px';
      }
    }
  }
};

const getBgId = (id: string) => `${id}_bg`;

export const getBlockBgId = (block: Block) => getBgId(block.id);

export const getBlockBg = (stage: Konva.Stage, block: Block) => stage.findOne(`#${getBlockBgId(block)}`);

export const getConnectionFromBg = (stage: Konva.Stage, connection: Connection) =>
  stage.findOne(`#${getBgId(connection.from)}`);

export const getConnectionToBg = (stage: Konva.Stage, connection: Connection) =>
  stage.findOne(`#${getBgId(connection.to)}`);

const moveAceEditor = (stage?: RefObject<Konva.Stage>, stageStage?: Konva.Stage | null) => {
  let localStage = stageStage;
  if (stage && !stageStage) {
    localStage = stage.current;
  }
  if (localStage) {
    const { openCodeEditor }: VisualEditorContextType = localStage.getAttr('context');
    const stageScale = localStage.scale() ?? { x: 1, y: 1 };
    if (openCodeEditor) {
      const codeBlock = getBlockBg(localStage, openCodeEditor);
      if (!codeBlock) return;
      const offset = localStage.content.getBoundingClientRect();
      const codeBlockPosition = codeBlock.getAbsolutePosition();
      const VisualEditorAce = document.getElementById('ace_popup');
      if (VisualEditorAce) {
        VisualEditorAce.style.top = codeBlockPosition.y + offset.top + 'px';
        VisualEditorAce.style.left = codeBlockPosition.x + offset.left + codeBlock.width() * stageScale.x + 'px';
      }
    }
  }
};

export const setScale = (e: KonvaEventObject<WheelEvent>): [number, { x: number; y: number }] => {
  e.evt.preventDefault();
  const scaleBy = 1.05;
  // const bgSize = 112;
  const stageStage = getStageFromEvent(e);

  if (stageStage) {
    const oldScale = stageStage.scaleX();

    const stagePointerPosition = stageStage.getPointerPosition() || { x: 0, y: 0 };

    const mousePointTo = {
      x: stagePointerPosition.x / oldScale - stageStage.x() / oldScale,
      y: stagePointerPosition.y / oldScale - stageStage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    // bg.style.backgroundSize = bgSize*newScale + 'px';

    stageStage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: -(mousePointTo.x - stagePointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - stagePointerPosition.y / newScale) * newScale,
    };
    stageStage.position(newPos);

    stageStage.batchDraw();

    moveAddingMenu(undefined, stageStage);
    moveAceEditor(undefined, stageStage);

    return [newScale, newPos];
  }
  return [1, { x: 0, y: 0 }];
};

export const useMakeNewConnector = (
  stage?: Konva.Stage | null
): [
  (ev: KonvaEventObject<MouseEvent>, startProps: Connection) => unknown,
  (ev: KonvaEventObject<MouseEvent>) => unknown,
  boolean
] => {
  const { addConnection } = useVisualEditorContext();

  const [draggable, setDraggable] = useState<boolean>(true);
  const [newConnector, setNewConnector] = useState<Konva.Line | null>(null);
  const [fromNode, setFromNode] = useState<Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'> | null>(null);
  const [startPosition, setStartPosition] = useState<BlockPositionNN>({ x: 0, y: 0 });

  useEffect(() => {
    if (!newConnector || !stage) return;

    if (draggable) {
      stage.off('mousemove');
      newConnector.points([]);
      stage.batchDraw();
      return;
    }

    stage.on('mousemove', () => {
      const [start, end, dist] = updateUsingStageProps(
        stage,
        { ...startPosition },
        stage.getPointerPosition() || { x: 0, y: 0 }
      );

      newConnector.points([
        start.x, // start x
        start.y, // start y
        start.x + dist * 0.5, // cp1 x
        start.y, // cp1 y
        end.x - dist * 0.5, // cp2 x
        end.y, // cp2 y
        end.x, // end x
        end.y,
      ]);

      stage.batchDraw();
    });
  }, [draggable, newConnector, stage, startPosition]);

  const setStart = useCallback(
    (ev: KonvaEventObject<MouseEvent>, startProps: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>) => {
      if (!stage) return;

      if (!newConnector) {
        setNewConnector(stage.findOne('.new_connector') as Konva.Line);
      }

      setStartPosition(ev.currentTarget.getAbsolutePosition() || { x: 0, y: 0 });
      setFromNode(startProps);
      setDraggable(false);
    },
    [newConnector, stage]
  );

  const setEnd = useCallback(
    (ev: KonvaEventObject<MouseEvent>) => {
      if (draggable) return;

      const to: string | undefined = ev.target.getAttr('name');
      setStartPosition({ x: 0, y: 0 });
      setDraggable(true);
      if (to && fromNode) {
        addConnection(fromNode, to);
      }
    },
    [addConnection, draggable, fromNode]
  );

  return [setStart, setEnd, draggable];
};

export const setCursorOnMouseEnterAndLeave = (e: KonvaEventObject<MouseEvent>, fn?: () => unknown) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const evtType = e.type;
    const parent: HTMLDivElement = stage.content.parentNode as HTMLDivElement;
    parent.style.cursor = evtType === 'mouseenter' ? 'pointer' : '';
    if (evtType === 'mouseenter') {
      parent.style.cursor = 'pointer';
    } else {
      parent.style.cursor = '';
    }
    if (fn) {
      fn();
    }
  }
};

export const getStageFromEvent = (event: KonvaEventObject<MouseEvent>): Konva.Stage | null => {
  return event.currentTarget.getStage();
};

export const showRemoveSplineIcon = (e: KonvaEventObject<MouseEvent>, stage: Konva.Stage) => {
  let position = getPointerPosition(stage);
  if (position) {
    const removeIcon = stage.findOne('#removeSplineIcon');
    if (removeIcon) {
      removeIcon.setAttr('data', e.currentTarget.getAttr('data'));
      removeIcon.position(position);
      removeIcon.visible(true);
    }
    stage.batchDraw();
  }
};

export const hideRemoveSplineIcon = (stage: RefObject<Konva.Stage>) => {
  const stageStage = stage.current;
  if (stageStage) {
    const removeIcon = stageStage.findOne('#removeSplineIcon');
    if (!removeIcon) return;
    removeIcon.visible(false);
    stageStage.batchDraw();
  }
};

export const isMetaKey = (e: KonvaEventObject<MouseEvent>) => {
  return e.evt.metaKey || e.evt.ctrlKey;
};

export const getRemoveBlockIconId = (block: Block) => `${block.id}_removeBlockIcon`;
export const getRemoveBlockIcon = (stage: Konva.Stage, block: Block) =>
  stage.findOne(`#${getRemoveBlockIconId(block)}`);

export const showRemoveBlockIcon = (e: KonvaEventObject<MouseEvent>, block: Block) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const removeIcon = getRemoveBlockIcon(stage, block);
    if (removeIcon) {
      removeIcon.visible(true);
    }
    stage.batchDraw();
  }
};

export const hideRemoveBlockIcon = (e: KonvaEventObject<MouseEvent>, block: Block) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const removeIcon = getRemoveBlockIcon(stage, block);
    if (removeIcon) {
      removeIcon.visible(false);
      stage.batchDraw();
    }
  }
};

const getPointerPosition = (stage: Konva.Stage): Vector2d | null => {
  let position = stage.getPointerPosition();

  if (position) {
    const stageRelativePos = stage.position();

    position.x -= stageRelativePos.x;
    position.y -= stageRelativePos.y;
    position.x = position.x / stage.scaleX();
    position.y = position.y / stage.scaleX();

    return position;
  }

  return null;
};

const drawRectFunction = (
  e: KonvaEventObject<MouseEvent>,
  stage: Konva.Stage,
  initialPosition: Vector2d,
  rect: Konva.Node
) => {
  const position = getPointerPosition(stage);
  if (position) {
    const rectWidth = position.x - initialPosition.x;
    const rectHeight = position.y - initialPosition.y;

    rect.width(rectWidth);
    rect.height(rectHeight);

    stage.batchDraw();
  }
};

export const drawSelectionRect = (e: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(e);
  if (stage && e.target.getAttr('isStage') && e.evt.button === 0 && !isMetaKey(e)) {
    const rect = stage.findOne('#SelectionRect');
    if (!rect) return;
    const position = getPointerPosition(stage);
    if (position) {
      rect.position(position);
      rect.visible(true);

      stage.on('mousemove', e => drawRectFunction(e, stage, position, rect));
    }

    stage.batchDraw();
  }
};

export const hideSelectionRect = (e: KonvaEventObject<MouseEvent>): Block[] => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const rect = stage.findOne('#SelectionRect');
    if (!rect) return [];
    stage.off('mousemove');
    rect.visible(false);
    const clientRect = rect.getClientRect();
    const intersections = getIntersections(clientRect, stage);
    rect.width(0);
    rect.height(0);
    stage.batchDraw();
    return intersections;
  }
  return [];
};

const getIntersections = (finalRectPositions: IRect, stage: Konva.Stage): Block[] => {
  const blocks = Array.from(stage.find('.basebg'));
  let blocksInRange: Block[] = [];
  blocks.forEach(block => {
    const blockRect = block.getClientRect();
    let center = {
      x: blockRect.x + blockRect.width / 2,
      y: blockRect.y + blockRect.height / 2,
    };

    if (
      center.x >= finalRectPositions.x &&
      center.x <= finalRectPositions.x + finalRectPositions.width &&
      center.y >= finalRectPositions.y &&
      center.y <= finalRectPositions.y + finalRectPositions.height
    ) {
      blocksInRange.push(block.getAttr('block'));
    }
  });

  return blocksInRange;
};
