import React from 'react';
import cn from 'classnames';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, useToggle, ICONS, Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import { JGToolbarIconButton } from '../JGToolbar';

import styles from './styles.module.scss';

type Option = {
  label: React.ReactNode;
  icon?: ICONS;
  onClick?: () => void;
  link?: string;
  dataTestId?: string;
};

type OtherHudActionsProps = {
  options: Option[];
  className?: string;
};
export const OtherHudActions = ({ className, options }: OtherHudActionsProps) => {
  const [isOpen, , , toggleOpen] = useToggle(false);

  return (
    <>
      <div className='d-inline-block'>
        <Dropdown isOpen={isOpen} toggle={toggleOpen}>
          <DropdownToggle tag='div' data-toggle='dropdown' aria-expanded={isOpen}>
            <JGToolbarIconButton
              className={cn(className, { active: isOpen })}
              id='JGToolbar_OtherActions'
              tooltip={isOpen ? '' : t('JGToolbar:OtherActions')}
              placement='bottom'
              iconName='farEllipsisH'
              data-test-id='JGToolbar:OtherActions'
            />
          </DropdownToggle>
          <DropdownMenu className={styles.OtherHudActions} positionFixed>
            {options.map(({ label, onClick, icon, link, dataTestId }, index) => (
              <DropdownItem
                key={index}
                tag={link ? 'a' : 'div'}
                className={cn(styles.OtherHudActions_Item)}
                onClick={onClick}
                href={link}
                target='_blank'
                data-test-id={dataTestId}
              >
                {icon && <Icon name={icon} color='secondary' />}
                <span>{label}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};
