import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { axios } from 'pipes/functions';
import { LogsApi, WatchOptions } from '../../api/client';
import { LogItem } from '../../model/LogItem';

export class LogService {
  private static readonly BASE_PATH = '/restapi';
  private api: LogsApi = new LogsApi({}, LogService.BASE_PATH, axios);

  private projectId: string;
  private timezone?: number;

  constructor(projectId: string, timezone?: number) {
    this.projectId = projectId;
    this.timezone = timezone;
  }

  private static extractStreamId = (options: WatchOptions) => {
    const indexOfLastSlash = options.logStreamUrl.lastIndexOf('/');
    return options.logStreamUrl.substring(indexOfLastSlash + 1);
  };

  private static splitByLines = (rawLogs: string) => rawLogs.split(/\r?\n/);
  private static splitByDate = (rawLogs: string) => rawLogs.split(/\n(?=\d\d\d\d-\d\d-\d\d)/g);

  getStreamId = async (projectId?: string, botId?: string, clientId?: string) => {
    if (projectId === '') projectId = undefined;
    const { data: options } = await this.api.watch(projectId, botId, clientId);
    return LogService.extractStreamId(options);
  };

  fetchLogs = async (streamId: string) => {
    const { data: rawLogs } = await this.api.getLogs(streamId);

    const logItems = LogService.splitByDate(rawLogs)
      .filter(str => str !== '')
      .map(LogItem.fromString);
    return logItems;
  };

  downloadLogs = async (botId?: string, sessionId?: string, questionId?: string, range?: Range) => {
    return await this.api.download(this.projectId, this.timezone, {
      botId,
      sessionId,
      questionId,
      fromTimestamp: range?.startDate,
      toTimestamp: range?.endDate,
    });
  };

  downloadLogsByBot = async (botId: string, range?: Range) => {
    return await this.downloadLogs(botId, undefined, undefined, range);
  };
  downloadLogsBySession = async (sessionId: string, range?: Range) => {
    return await this.downloadLogs(undefined, sessionId, undefined, range);
  };
  downloadLogsByQuestion = async (questionId: string, range?: Range) => {
    return await this.downloadLogs(undefined, undefined, questionId, range);
  };

  deleteLogsDownloadTask = async (taskId: string) => {
    return await this.api.deleteLogDownloadTask(taskId);
  };

  deleteCompletedLogsDownloadTasks = async () => {
    return await this.api.deleteCompletedLogDownloadTasks(this.projectId);
  };
}
