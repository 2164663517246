import isAccess from 'isAccessFunction';

import { generateContextFromHook } from 'utils/hooks/generateContextFromHook';

import { useGetIntegrationListApi } from './api';

function useKnowledgeBaseLLMIntegrationsContextInner() {
  const [getIntegrationListState, getIntegrationList] = useGetIntegrationListApi();

  const isKnowledgeBaseLLMSectionWritable = isAccess('SECRETS_WRITE');

  return {
    isKnowledgeBaseLLMSectionWritable,
    getIntegrationListState,
    getIntegrationList,
  };
}

export const {
  Provider: KnowledgeBaseLLMIntegrationContextProvider,
  useContext: useKnowledgeBaseLLMIntegrationContext,
} = generateContextFromHook(useKnowledgeBaseLLMIntegrationsContextInner);
