import { isDev } from '../../pipes/pureFunctions';

import { LocalStorageService } from '../Storage/LocalStorageService';

import { SyncFrontendVersionService } from './SyncFrontendVersionService';
import { IncorrectFrontVersionErrorProcessor } from './IncorectFrontVersionErrorProsessor';

export const syncFrontendVersionService = isDev() ? undefined : new SyncFrontendVersionService();

export const incorrectFrontVersionErrorProcessor = isDev()
  ? undefined
  : new IncorrectFrontVersionErrorProcessor(
      new LocalStorageService(),
      window.env?.BUILD_NUMBER,
      syncFrontendVersionService
    );
