import React from 'react';
import { useLocation } from 'react-router';

import { OAuthResponseType } from 'components/OAuthBehaviour/types';

function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OAuthCallback = function () {
  const query = useQuery();
  window.opener?.postMessage(
    { type: 'OAuthResponse', payload: Object.fromEntries(query.entries()) } as OAuthResponseType,
    window.opener.location
  );
  return <div>Processing...</div>;
};

export default OAuthCallback;
