var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Icon } from '..';
import { createId } from './utils';
import './Tree.scss';
import { InputText } from '../InputText';
import { useDrag } from 'react-dnd';
import { translate } from './localization';
export const DefaultList = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return _jsx("div", Object.assign({}, props, { children: children }));
};
DefaultList.displayName = 'DefaultList';
const messages = (locale) => ({
    nothingFound: translate("Nothing's found", locale),
    isEmpty: translate('No elements', locale),
});
export const DefaultMessage = (_a) => {
    var { type, locale = 'ru' } = _a, props = __rest(_a, ["type", "locale"]);
    return (_jsx("div", Object.assign({ className: 'tree-message' }, props, { children: messages(locale)[type] })));
};
const getLeafStyle = (depth) => ({
    paddingLeft: depth < 5 ? depth * 10 : `calc(45px + ${(Math.log(depth) - 1.5) * 20}%)`, // linear progression smoothly turnes into logarithmic
});
export var Theme;
(function (Theme) {
    Theme["dark"] = "dark";
    Theme["light"] = "light";
})(Theme || (Theme = {}));
export const Link = ({ id, prefix, children, onClick: onClickProp, onKeyUp, onKeyDown, toggleExpanded, node, depth, isSelected, isExpanded, flat, theme, itemHeight, iconsMode, onContextMenu = () => { }, }) => {
    const onClick = e => {
        e.preventDefault();
        e.stopPropagation();
        if (onClickProp) {
            onClickProp(e);
        }
    };
    const onExpand = e => {
        if (toggleExpanded) {
            e.preventDefault();
            e.stopPropagation();
            toggleExpanded(e);
        }
    };
    const handleContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return onContextMenu(event, node);
    };
    const isFolder = Boolean(node.children && node.children.length);
    return (_jsx("span", { id: createId(id, prefix), onClick: onClick, onKeyUp: onKeyUp, onKeyDown: onKeyDown, className: 'tree-link', onContextMenu: handleContextMenu, children: _jsxs("div", { id: `${prefix}_${node.nodeId}`, "data-test-id": `${prefix}_${node.nodeId}`, style: Object.assign({ minHeight: itemHeight }, getLeafStyle(depth)), className: classNames('tree-link__leaf', theme === Theme.dark ? 'tree-link__leaf-dark' : 'tree-link__leaf-light', { selected: isSelected }), children: [!flat ? (isFolder ? (_jsx("span", { id: `${prefix}.Carret_${node.nodeId}`, "data-test-id": `${prefix}.Carret_${node.nodeId}`, className: 'tree-link__carret-style', onClick: onExpand, children: _jsx(Icon, { className: iconsMode !== 'folders' ? 'chevron-icon' : '', name: isExpanded
                            ? iconsMode === 'folders'
                                ? 'farFolderOpen'
                                : 'faChevronDown'
                            : iconsMode === 'folders'
                                ? 'farFolder'
                                : 'faChevronRight', size: 'sm' }) })) : (_jsx("span", { className: 'tree-link__carret-style', children: iconsMode === 'folders' && _jsx(Icon, { name: 'farFile', size: 'sm' }) }))) : null, _jsx("span", { style: { flexGrow: 1, width: 0 }, children: children })] }) }));
};
Link.displayName = 'Link';
export const DraggableLink = ({ id, prefix, children, onClick: onClickProp, onKeyUp, onKeyDown, toggleExpanded, node, depth, isSelected, isExpanded, flat, theme, itemHeight, onContextMenu = () => { }, }) => {
    const [, drag] = useDrag({
        item: { node, type: 'node' },
    });
    const onClick = e => {
        e.preventDefault();
        if (onClickProp) {
            onClickProp(e);
        }
    };
    const onExpand = e => {
        if (toggleExpanded) {
            e.preventDefault();
            e.stopPropagation();
            toggleExpanded(e);
        }
    };
    const handleContextMenu = (event) => onContextMenu(event, node);
    const isFolder = Boolean(node.children && node.children.length);
    return (_jsx("span", { id: createId(id, prefix), onClick: onClick, onKeyUp: onKeyUp, onKeyDown: onKeyDown, className: 'tree-link', onContextMenu: handleContextMenu, children: _jsxs("div", { id: `${prefix}_${node.nodeId}`, "data-test-id": `${prefix}_${node.nodeId}`, style: Object.assign({ minHeight: itemHeight }, getLeafStyle(depth)), className: classNames('tree-link__leaf', theme === Theme.dark ? 'tree-link__leaf-dark' : 'tree-link__leaf-light', { selected: isSelected }), ref: drag, children: [!flat ? (isFolder ? (_jsx("span", { id: `${prefix}.Carret_${node.nodeId}`, "data-test-id": `${prefix}.Carret_${node.nodeId}`, className: 'tree-link__carret-style', onClick: onExpand, children: _jsx(Icon, { name: isExpanded ? 'faChevronDown' : 'faChevronRight', size: 'sm' }) })) : (_jsx("span", { className: 'tree-link__carret-style' }))) : null, _jsx("span", { style: { flexGrow: 1, width: 0 }, children: children })] }) }));
};
export const LinkWithCheckbox = ({ id, prefix, children, onClick: onClickProp, onKeyUp, onKeyDown, toggleExpanded, node, depth, isSelected, isExpanded, flat, theme, itemHeight, onContextMenu = () => { }, }) => {
    const onClick = e => {
        e.preventDefault();
        if (onClickProp) {
            onClickProp(e);
        }
    };
    const onExpand = e => {
        if (toggleExpanded) {
            e.preventDefault();
            e.stopPropagation();
            toggleExpanded(e);
        }
    };
    const handleContextMenu = (event) => onContextMenu(event, node);
    const isFolder = Boolean(node.children && node.children.length);
    return (_jsx("span", { id: createId(id, prefix), onClick: onClick, onKeyUp: onKeyUp, onKeyDown: onKeyDown, className: 'tree-link', onContextMenu: handleContextMenu, children: _jsxs("div", { id: `${prefix}_${node.nodeId}`, "data-test-id": `${prefix}_${node.nodeId}`, style: Object.assign({ minHeight: itemHeight }, getLeafStyle(depth)), className: classNames('tree-link__leaf', theme === Theme.dark ? 'tree-link__leaf-dark' : 'tree-link__leaf-light', { selected: isSelected }), children: [!flat ? (isFolder ? (_jsxs("span", { id: `${prefix}.Carret_${node.nodeId}`, "data-test-id": `${prefix}.Carret_${node.nodeId}`, className: 'tree-link__carret-style', onClick: onExpand, children: [_jsx(Icon, { name: isExpanded ? 'faChevronDown' : 'faChevronRight', size: 'sm' }), _jsx("input", { type: 'checkbox' })] })) : (_jsx("span", { className: 'tree-link__carret-style' }))) : null, _jsx("span", { style: { flexGrow: 1, width: 0 }, children: children })] }) }));
};
export const DefaultLeaf = ({ node }) => (_jsx("div", { style: { paddingLeft: 10, paddingRight: 10 }, children: node.name }));
DefaultLeaf.displayName = 'DefaultLeaf';
export const DefaultHead = ({ node }) => (_jsx("div", { style: { paddingLeft: 10, paddingRight: 10 }, children: node.name }));
DefaultHead.displayName = 'DefaultHead';
// @ts-ignore
export const DefaultEdit = ({ node }) => (_jsx("div", { style: { paddingLeft: 10, paddingRight: 10 }, children: _jsx(InputText, { compact: true, value: node.name }) }));
DefaultHead.displayName = 'DefaultEdit';
