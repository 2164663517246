import React, { useEffect, useRef, RefCallback, useCallback } from 'react';
import Konva from 'konva';
import { Layer } from 'react-konva';

import { ActionLayerEvent$ } from 'modules/JGraph/view/Layers/ActionLayer/RxSubjects';

const ActionLayer = React.forwardRef<Konva.Layer>((props, ref) => {
  const layerRef = useRef<Konva.Layer>(null);

  const getRef: RefCallback<Konva.Layer> = useCallback(
    compRef => {
      if (typeof ref === 'function') {
        ref(compRef);
      }
      if (ref && ref.hasOwnProperty('current')) {
        //@ts-ignore
        ref.current = compRef;
      }
      // @ts-ignore
      layerRef.current = compRef;
    },
    [ref]
  );

  useEffect(() => {
    const sub = ActionLayerEvent$.subscribe(({ node, eventType }) => {
      const stage = layerRef.current?.getStage();
      if (!node && layerRef.current) {
        layerRef.current.destroyChildren();
        layerRef.current.batchDraw();
      }
      if (!node || !layerRef.current || !stage) return;

      const absolutePosition = node.getAbsolutePosition(stage);
      let actionStyles = node.attrs.actionStyles;
      switch (eventType) {
        case 'dragstart':
          node.opacity(0);
        // eslint-disable-next-line no-fallthrough
        case 'mouseenter':
          const eventStyles = actionStyles?.[eventType] || {};
          layerRef.current.add(
            node.clone({
              x: absolutePosition.x,
              y: absolutePosition.y,
              opacity: 1,
              ...eventStyles,
            })
          );
          break;

        case 'dragmove': {
          layerRef.current.children?.map(child => {
            child.x(node.x());
            child.y(node.y());
          });
          break;
        }
        case 'dragend':
          node.opacity(1);
        // eslint-disable-next-line no-fallthrough
        case 'mouseleave':
        default:
          layerRef.current.destroyChildren();
      }
      layerRef.current.batchDraw();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return <Layer name='action-layer' ref={getRef} isLayer={true} listening={false} />;
});

export default React.memo(ActionLayer);
