var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LabelsApi, } from '../api/client';
import { LabelsApi as LabelsReporterApi, } from '../../Reporter/api/client';
import { axios } from '../../Reporter/utils/pipes/functions';
class LabelsService {
    constructor(accountId, useReporter) {
        this.createLabel = (logLabelCreate, group) => {
            if (this.useReporter) {
                const label = { name: logLabelCreate.name, priority: logLabelCreate.priority, group };
                return this.labelsReporterService.createMessageLabel(this.accountId, label);
            }
            return this.labelsService.createLabel(this.accountId, logLabelCreate);
        };
        this.createLabels = (labels) => {
            return this.labelsReporterService.createMessageLabels(this.accountId, labels);
        };
        this.getLabels = (options) => {
            return this.useReporter
                ? this.labelsReporterService.getMessageLabels(this.accountId)
                : this.labelsService.getLabels(this.accountId);
        };
        this.deleteLabel = (labelId, options) => {
            return this.useReporter
                ? this.labelsReporterService.deleteMessageLabel(this.accountId, labelId)
                : this.labelsService.deleteLabel(this.accountId, labelId);
        };
        this.updateLabel = (labelId, logLabelCreate, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateMessageLabel(this.accountId, labelId, logLabelCreate)
                : this.labelsService.updateLabel(this.accountId, labelId, logLabelCreate);
        };
        this.getLabelGroups = (options) => __awaiter(this, void 0, void 0, function* () {
            try {
                return this.useReporter
                    ? yield this.getReporterLabelsGroups()
                    : yield this.labelsService.getLabelGroups(this.accountId);
            }
            catch (e) {
                return { data: [] };
            }
        });
        this.getReporterLabelsGroups = () => __awaiter(this, void 0, void 0, function* () {
            const { data: labels } = yield this.labelsReporterService.getMessageLabels(this.accountId);
            let groups = {};
            labels.map(label => {
                //@ts-ignore
                const group = label.group;
                if (!groups[group.id])
                    groups[group.id] = Object.assign(Object.assign({}, group), { labels: [] });
                //@ts-ignore
                groups[group.id].labels.push(Object.assign({}, label));
            });
            let returnGroups = Object.values(groups);
            returnGroups.sort((aGroup, bGroup) => (aGroup.priority || 0) - (bGroup.priority || 0));
            return { data: returnGroups };
        });
        this.getLabelGroup = (labelGroupId, options) => {
            return this.useReporter
                ? this.labelsReporterService.getLabelGroup(this.accountId, labelGroupId)
                : this.labelsService.getLabelGroup(this.accountId, labelGroupId);
        };
        this.createLabelGroup = (logLabelGroupCreate, labels, options) => __awaiter(this, void 0, void 0, function* () {
            if (this.useReporter) {
                const { 
                //@ts-ignore
                data: { group },
                //@ts-ignore
                 } = yield this.createLabel({ name: labels[0], priority: 1 }, logLabelGroupCreate);
                if (labels.length > 1) {
                    return this.createLabels(
                    //@ts-ignore
                    labels.slice(1, labels.length).map((x, index) => ({ name: x, priority: index + 2, group })));
                }
                else {
                    return { data: { group } };
                }
            }
            return this.labelsService.createLabelGroup(this.accountId, logLabelGroupCreate);
        });
        this.deleteLabelGroup = (labelGroupId, options) => {
            return this.useReporter
                ? this.labelsReporterService.deleteMessageLabelGroup(this.accountId, labelGroupId)
                : this.labelsService.deleteLabelGroup(this.accountId, labelGroupId);
        };
        this.updateLabelGroup = (labelGroupId, logLabelGroupCreate, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateMessageLabelGroup(this.accountId, labelGroupId, logLabelGroupCreate)
                : this.labelsService.updateLabelGroup(this.accountId, labelGroupId, logLabelGroupCreate);
        };
        this.createSessionLabel = (sessionLabelCreate, options) => {
            return this.useReporter
                ? this.labelsReporterService.createSessionLabel(this.accountId, sessionLabelCreate)
                : this.labelsService.createSessionLabel(this.accountId, sessionLabelCreate);
        };
        this.getSessionLabels = (options) => __awaiter(this, void 0, void 0, function* () {
            try {
                return this.useReporter
                    ? yield this.labelsReporterService.getSessionLabels(this.accountId)
                    : yield this.labelsService.getSessionLabels(this.accountId);
            }
            catch (e) {
                return { data: [] };
            }
        });
        this.getSessionLabel = (sessionLabelId, options) => {
            return this.useReporter
                ? this.labelsReporterService.getSessionLabel(this.accountId, sessionLabelId)
                : this.labelsService.getSessionLabel(this.accountId, sessionLabelId);
        };
        this.deleteSessionLabel = (sessionLabelId, options) => {
            return this.useReporter
                ? this.labelsReporterService.deleteSessionLabel(this.accountId, sessionLabelId)
                : this.labelsService.deleteSessionLabel(this.accountId, sessionLabelId);
        };
        this.updateSessionLabel = (sessionLabelId, sessionLabelCreate, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateSessionLabel(this.accountId, sessionLabelId, sessionLabelCreate)
                : this.labelsService.updateSessionLabel(this.accountId, sessionLabelId, sessionLabelCreate);
        };
        this.updateGroupsPriority = (labelPriorityChangeDto, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateGroupsPriority(this.accountId, labelPriorityChangeDto, options)
                : this.labelsService.updateGroupsPriority(this.accountId, labelPriorityChangeDto, options);
        };
        this.updateSessionsPriority = (labelPriorityChangeDto, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateSessionsPriority(this.accountId, labelPriorityChangeDto, options)
                : this.labelsService.updateSessionsPriority(this.accountId, labelPriorityChangeDto, options);
        };
        this.updateLogLabelsPriority = (labelPriorityChangeDto, options) => {
            return this.useReporter
                ? this.labelsReporterService.updateMessageLabelsPriority(this.accountId, labelPriorityChangeDto, options)
                : this.labelsService.updateLogLabelsPriority(this.accountId, labelPriorityChangeDto, options);
        };
        this.getIssuesRequestedType = (reqType, options) => {
            return axios.get(`/restapi/logs/issues/${reqType}`);
        };
        this.createIssuesRequestedType = (reqType, value, options) => {
            return axios.post(`/restapi/logs/issues/${reqType}`, value, options);
        };
        this.updateIssueRequestedType = (reqType, id, value, options) => {
            return axios.put(`/restapi/logs/issues/${reqType}/${id}`, value, options);
        };
        this.updateIssuesRequestedTypePriority = (reqType, value, options) => {
            return axios.put(`/restapi/logs/issues/${reqType}/change-priority`, value, options);
        };
        this.deleteIssueRequestedType = (reqType, id, options) => {
            return axios.delete(`/restapi/logs/issues/${reqType}/${id}`, options);
        };
        this.setLabelToSessions = (bulkLabelingDto) => {
            return this.labelsReporterService.setLabelToSessions(this.accountId, bulkLabelingDto);
        };
        this.setLabelToMessages = (bulkLabelingDto) => {
            return this.labelsReporterService.setLabelToMessages(this.accountId, bulkLabelingDto);
        };
        this.accountId = accountId;
        this.useReporter = Boolean(useReporter);
        this.labelsService = new LabelsApi({}, '', axios);
        this.labelsReporterService = new LabelsReporterApi({}, '', axios);
    }
}
export default LabelsService;
