import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'storeTypes';
import type { StickerInfo } from 'modules/JGraph/view/Sticker/types';

import { jGraphStickersApi } from '../utils';
import { loadStickers } from '../index';

const batchUpdateStickers = createAsyncThunk(
  'JGraph/batchUpdateStickers',
  async (stickers: StickerInfo[], thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const { data: response } = await jGraphStickersApi.batchUpsertJGraphSticker(
      userAccountId || accountId,
      projectShortName,
      {
        version: state.JGraphReducer.stickersVersion.version,
        lastModified: state.JGraphReducer.stickersVersion.lastModified,
        stickers: stickers,
      }
    );

    thunkAPI.dispatch(loadStickers(response));
  }
);

export default batchUpdateStickers;
