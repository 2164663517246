import React, { useCallback, useState, useEffect, useRef } from 'react';
import { InputText, FormText } from '@just-ai/just-ui';
import { t } from 'localization';

import { session } from 'modules/JGraph/utils/insertSessionFunction';
import Banner, { BannerType } from 'modules/JGraph/components/Banner';

interface AudioUrlFieldInterface {
  onChange: (url: string) => void;
  url?: string;
}
const AudioUrlField = function ({ url = '', onChange }: AudioUrlFieldInterface) {
  const [urlError, setUrlError] = useState('');
  const urlInputRef = useRef<HTMLInputElement>(null);

  const checkUrl = useCallback(
    (url: string) => {
      if (url.length >= 5 && !url.startsWith('https')) {
        setUrlError(t('AudioUploadUrlField:FormatError'));
        return false;
      }
      if (urlError) setUrlError('');
      return true;
    },
    [urlError]
  );

  useEffect(() => {
    checkUrl(url);
    if (urlInputRef.current) {
      urlInputRef.current.value = url;
    }
  }, [checkUrl, url]);

  const innerOnChange = useCallback(
    (url: string) => {
      if (!checkUrl(url)) {
        onChange('');
        return;
      }
      onChange(url);
    },
    [checkUrl, onChange]
  );

  return (
    <div>
      <InputText
        innerRef={urlInputRef}
        data-test-id='JGraph.RightSideMenu.Audio.FileLink'
        placeholder={t('AudioSettings:urlPlaceholder')}
        defaultValue={session.parse(url || '')}
        onChange={innerOnChange}
      />
      {urlError ? (
        <div className='margin-top-1x' style={{ color: 'var(--red-600)', fontSize: 12 }}>
          {urlError}
        </div>
      ) : (
        <FormText>{t('AudioSettings:urlHint')}</FormText>
      )}
      <Banner type={BannerType.INFO} content={t('AudioUploadUrlField:BannerHint')} className='margin-top-2x' />
    </div>
  );
};

export default React.memo(AudioUrlField);
