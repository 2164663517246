import {
  FETCH_PROJECT_STATISTIC_FULFILLED,
  FETCH_PROJECT_STATISTIC_PENDING,
  FETCH_PROJECT_STATISTIC_REJECTED,
} from '../../constants/projects.actions';
import localize from '../../localization';

const InitialState = {
  projectName: '',
  developmentActivityChartData: null,
  clientActivityChartData: null,
  tasksCreatedActivityChartData: null,
  chartMonths: {
    startMonthName: '',
    endMonthName: '',
  },
  channelList: [],
  fetching: false,
  fetched: false,
  error: [],
};

export default function ProjectReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_STATISTIC_FULFILLED:
      const {
        messagesCount,
        sessionsCount,
        uniqueClientsCount,
        commitsCount,
        botStats,
        projectName,
        issuesCreatedCount,
        issuesFinishedCount,
      } = action.payload.data;

      let clientActivityLabels = messagesCount.dates.map(dateMilliseconds => {
        return new Date(dateMilliseconds).getDate();
      });

      //todo get current locale
      const locale = localStorage.getItem('USER_LANGUAGE') || 'ru';

      let startMonthDate = new Date(messagesCount.dates[0]);
      let startMonthName = startMonthDate.toLocaleString(locale, { month: 'long' });
      let endMonthDate = new Date(messagesCount.dates[messagesCount.dates.length - 1]);
      let endMonthName = endMonthDate.toLocaleString(locale, { month: 'long' });

      let clientActivityChartData = {
        labels: clientActivityLabels,
        datasets: [
          {
            label: localize.translate('Messages'),
            data: messagesCount.values,
            fill: false,
            borderColor: 'rgb(255, 127, 80)',
            lineTension: 0.1,
          },
          {
            label: localize.translate('Sessions'),
            data: sessionsCount.values,
            fill: false,
            borderColor: 'rgb(220, 20, 60)',
            lineTension: 0.1,
          },
          {
            label: localize.translate('Unique users'),
            data: uniqueClientsCount.values,
            fill: false,
            borderColor: 'rgb(255, 215, 0)',
            lineTension: 0.1,
          },
        ],
      };

      let developmetActivityLabels = commitsCount.dates.map(dateMilliseconds => {
        return new Date(dateMilliseconds).getDate();
      });

      let developmentActivityChartData = {
        labels: developmetActivityLabels,
        datasets: [
          {
            label: localize.translate('Number of completed tasks'),
            data: issuesFinishedCount.values,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            lineTension: 0.1,
          },
        ],
      };

      let tasksCreatedActivityLabels = issuesCreatedCount.dates.map(dateMilliseconds => {
        return `${new Date(dateMilliseconds).getDate()}`;
      });

      let tasksCreatedActivityChartData = {
        labels: tasksCreatedActivityLabels,
        datasets: [
          {
            label: localize.translate('Number of tasks created'),
            data: issuesCreatedCount.values,
            fill: false,
            borderColor: '#D9534F',
            lineTension: 0.1,
          },
          {
            label: localize.translate('Commits'),
            data: commitsCount.values,
            fill: false,
            borderColor: '#5cb85c',
            lineTension: 0.1,
          },
        ],
      };

      return {
        ...state,
        projectName: projectName,
        clientActivityChartData: clientActivityChartData,
        developmentActivityChartData: developmentActivityChartData,
        tasksCreatedActivityChartData: tasksCreatedActivityChartData,
        channelList: botStats,
        chartMonths: { startMonthName, endMonthName },
        fetched: true,
        fetching: false,
      };

    case FETCH_PROJECT_STATISTIC_REJECTED:
      return {
        ...state,
        error: !!action.payload.response.data.error
          ? [action.payload.response.data.error]
          : action.payload.response.data.errors,
        fetched: true,
        fetching: false,
      };

    case FETCH_PROJECT_STATISTIC_PENDING:
      return {
        ...state,
        fetching: true,
      };

    default:
      return state;
  }
}
