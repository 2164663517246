import React from 'react';
import cn from 'classnames';
import { Icon } from '@just-ai/just-ui';

import ClickAwayListener from 'components/ClickAwayListener';

import styles from './styles.module.scss';

interface TooltipProps {
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}
const Tooltip = ({ children, className, onClose }: TooltipProps) => {
  return (
    <ClickAwayListener handleClickOut={onClose}>
      <div className={cn(styles.Tooltip, className)}>
        {children}
        <Icon name='farTimes' wrapperClassName={styles.Tooltip__close} onClick={onClose} />
        <div className={styles.Tooltip__arrow} />
      </div>
    </ClickAwayListener>
  );
};

export default Tooltip;
