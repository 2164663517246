const prefix = '@deployInfo/';

export const INIT = prefix + 'INIT';
export const INIT_PENDING = prefix + 'INIT_PENDING';
export const INIT_FULFILLED = prefix + 'INIT_FULFILLED';
export const INIT_REJECTED = prefix + 'INIT_REJECTED';

export const RELOAD = prefix + 'RELOAD';
export const RELOAD_PENDING = prefix + 'RELOAD_PENDING';
export const RELOAD_FULFILLED = prefix + 'RELOAD_FULFILLED';
export const RELOAD_REJECTED = prefix + 'RELOAD_REJECTED';
