import { Button, Icon, Label, useToggle } from '@just-ai/just-ui';
import localize, { t } from 'localization';
import React, { FC, useCallback, useState } from 'react';
import cn from 'classnames';

import { amplitudeInstance } from 'pipes/functions';

import classes from './JGraphCailaIntentChooseCreation.module.scss';
import { CustomHelperText } from './CustomHelperText';
import { useJGraphContext } from '../../../../../../contexts/JGraphContext';
import { useRightSideMenuContext } from '../../../../index';
import { saveIntentAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { RightSideMenuExternalEventsPipe$ } from '../../../../hooks/EventsHook';
import { choosereadyintentLocalization } from '../../../../../../../Editor/components/VisualEditor/localization/choosereadyintent.loc';
import { EditIntentsModal } from '../../EditIntentsModal';
import { NewSelectIntent, shouldGoDetail$ } from './NewSelectIntent';
import { guideTourEvent$ } from '../../../../../JGraphGuideTour/guideTourEvents';
localize.addTranslations(choosereadyintentLocalization);

export const backToNewCailaIntentsCreation = () => {
  RightSideMenuExternalEventsPipe$.next({
    setAddingMenuType: {
      menuType: 'toStateEvents',
      activationsMenuType: 'intent',
    },
  });
};

export const JGraphCailaIntentChooseCreation: FC<{ expanded: boolean }> = ({ expanded }) => {
  const { dispatch } = useRightSideMenuContext();
  const [expandedInner, setExpandedTrue] = useToggle(expanded);
  const { IntentsService } = useJGraphContext();
  const [isIntentCreationOpen, openIntentCreation] = useState(false);

  const setExpandedTrueHandler = useCallback(() => {
    setExpandedTrue();
    guideTourEvent$.next(`JGraphCailaIntentChooseCreation:ClickCreate`);
  }, [setExpandedTrue]);

  const createIntentAction = useCallback(
    async (intentText: string, shouldCreateNew: boolean = false) => {
      if (shouldCreateNew) {
        await IntentsService.createTopLevelIntentFromText(intentText);
      }
      amplitudeInstance.logEvent('Expected intent created');
      await dispatch(
        saveIntentAsync({
          isGlobal: false,
          value: intentText.startsWith('/') ? intentText : `/${intentText}`,
          tagParameters: [
            { name: 'fromState', value: undefined, required: false },
            { name: 'onlyThisState', value: null, required: false },
            { name: 'toState', value: './', required: false },
          ],
          shouldGoDetail: shouldGoDetail$.getValue(),
        })
      );
    },
    [IntentsService, dispatch]
  );

  const openCaila = useCallback(() => {
    openIntentCreation(true);
  }, []);

  const closeModal = useCallback(() => {
    openIntentCreation(false);
  }, []);

  const chooseIntent = useCallback(
    async (id: string) => {
      closeModal();
      await IntentsService.listIntents();
      const foundItem = IntentsService.intents.find(intent => String(intent.id) === String(id));
      if (foundItem) {
        await createIntentAction(foundItem.path!);
        backToNewCailaIntentsCreation();
      } else {
        await dispatch(
          saveIntentAsync({
            isGlobal: false,
            value: id,
            tagParameters: [
              { name: 'fromState', value: undefined, required: false },
              { name: 'onlyThisState', value: null, required: false },
              { name: 'toState', value: './', required: false },
            ],
            shouldGoDetail: shouldGoDetail$.getValue(),
          })
        );
      }
    },
    [IntentsService, closeModal, createIntentAction, dispatch]
  );

  return (
    <div className='JGraphCailaIntentChooseCreation' data-test-id='JGraphCailaIntentChooseCreation'>
      <Label>{t('JGraphCailaIntentChooseCreation:createLabel')}</Label>
      <div
        className={cn(classes.JGraphCailaIntentChooseCreation, { [classes.expanded]: expandedInner })}
        data-test-id='JGraphCailaIntentChooseCreation:createContainer'
      >
        {!expandedInner && (
          <Button
            outline
            color='secondary'
            onClick={setExpandedTrueHandler}
            data-test-id='JGraphCailaIntentChooseCreation:create'
          >
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.5 5H10.5C10.3674 5 10.2402 5.05268 10.1464 5.14645C10.0527 5.24021 10 5.36739 10 5.5C10 5.63261 10.0527 5.75979 10.1464 5.85355C10.2402 5.94732 10.3674 6 10.5 6H12.5C12.6326 6 12.7598 6.05268 12.8536 6.14645C12.9473 6.24021 13 6.36739 13 6.5V9.5C13 9.63261 12.9473 9.75979 12.8536 9.85355C12.7598 9.94732 12.6326 10 12.5 10H3.85L5.58 8.27C5.62783 8.22511 5.66595 8.17089 5.69201 8.11069C5.71807 8.0505 5.73152 7.9856 5.73152 7.92C5.73152 7.8544 5.71807 7.7895 5.69201 7.72931C5.66595 7.66911 5.62783 7.61489 5.58 7.57C5.53352 7.52314 5.47822 7.48594 5.41729 7.46055C5.35636 7.43517 5.291 7.4221 5.225 7.4221C5.15899 7.4221 5.09364 7.43517 5.03271 7.46055C4.97178 7.48594 4.91648 7.52314 4.87 7.57L2.37 10.07C2.27838 10.1635 2.22707 10.2891 2.22707 10.42C2.22707 10.5509 2.27838 10.6765 2.37 10.77L4.87 13.27C4.96539 13.3657 5.09486 13.4197 5.23 13.42C5.36188 13.4181 5.48768 13.3642 5.58 13.27C5.62783 13.2251 5.66595 13.1709 5.69201 13.1107C5.71807 13.0505 5.73152 12.9856 5.73152 12.92C5.73152 12.8544 5.71807 12.7895 5.69201 12.7293C5.66595 12.6691 5.62783 12.6149 5.58 12.57L4 11H12.5C12.8978 11 13.2794 10.842 13.5607 10.5607C13.842 10.2794 14 9.89782 14 9.5V6.5C14 6.10218 13.842 5.72064 13.5607 5.43934C13.2794 5.15804 12.8978 5 12.5 5Z'
                fill='currentColor'
              />
            </svg>
            {t('JGraphCailaIntentChooseCreation:Create')}
          </Button>
        )}
        {expandedInner && (
          <div className='position-relative d-flex flex-grow-1'>
            <NewSelectIntent onSelectIntent={createIntentAction} inlineCreation={true} />
            <Icon name='faEnterSymbol' wrapperClassName={classes.faEnterSymbol} size='sm' />
          </div>
        )}

        <span className={classes.splitter} />
        <Button
          outline
          color='secondary'
          className={cn({ [classes.buttonCollapse]: expandedInner })}
          onClick={openCaila}
        >
          <Icon name='farCommentAltDots' wrapperClassName={classes.heightFix} />
          {!expandedInner && t('JGraphCailaIntentChooseCreation:Choose')}
        </Button>
      </div>
      {expandedInner && <CustomHelperText />}
      <EditIntentsModal toggle={closeModal} open={isIntentCreationOpen} externalEditId='' chooseIntent={chooseIntent} />
    </div>
  );
};
