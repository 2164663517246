import React, { FC, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { IconButton, Spinner, useForceUpdate, usePromiseProcessing, useToggle } from '@just-ai/just-ui';
import { NluIntentItem } from './NluIntentItem';
import { NluIntentTitle } from './NluIntentTitle';
import { NluItemsContainer } from './NluItemsContainer';
import { NluSystemIntentItem } from './NluSystemIntent';
import { IntentItemPlaceholder } from './IntentItemPlaceholder';
import { t } from 'localization';
import localize from 'localization';
import { nluIntentSimpleModalLocalization } from './nluIntentSimpleModal.loc';
import cn from 'classnames';
import classes from './styles.module.scss';
import { IntentContentPlaceholder } from './IntentContentPlaceholder';
import { useJGraphContext } from '../../../../../contexts/JGraphContext';
import { IntentDetail } from './IntentDetail';
import { IntentData } from '../../../../../../Caila/api/client';

import { IntentSystemDetail } from './IntentSystemDetail';
import { convertIntentItemWithId, getPureDisplayValue, validateIntent } from './utils';
import { getErrorArgsFromReason, getErrorMessageFromReason, Intents } from '../../../../../../Caila/utils';
import { convertToIntentWithItems, updateItems } from '../../../../../../Caila/utils/intents';
import { IntentItem } from '../../../../../../Caila/model';
import { CustomIntentItem } from '../../../../../../Caila/pages/IntentsPage/IntentItemsEdit';
import { ComplexErrorsType, IntentWithItems } from './types';
import { Search } from './Search';

localize.addTranslations(nluIntentSimpleModalLocalization);

type CailaIntentsSimpleProps = {
  onSelectIntent: (value: string) => unknown;
  onIntentRenamed?: (intentNameData: { oldValue: string; newValue: string }) => unknown;
  intentId?: number | string;
  isSystemIntents?: boolean;
};
const DEBOUNCE_TIMEOUT = 500;

export const CailaIntentsSimple: FC<CailaIntentsSimpleProps> = ({ intentId, onSelectIntent, onIntentRenamed }) => {
  const [isDirty, setIsDirty, setClear] = useToggle(false);
  const [selected, setSelected] = useState(String(intentId));
  const timeout = useRef<NodeJS.Timeout>();
  const [isSearchOpen, openSearch, closeSearch] = useToggle(false);
  const [search, setSearch] = useState('');
  const [intentEditError, setIntentEditError] = useState('');
  const [intentEditErrorArgs, setIntentEditErrorArgs] = useState<any>(undefined);
  const [complexErrors, setComplexErrors] = useState(new ComplexErrorsType());
  const forceUpdate = useForceUpdate();
  const { IntentsService, currentBotLanguage, SettingsService } = useJGraphContext();
  const UserIntentsToShow = IntentsService.intents.filter(intent => {
    return !intent.path?.startsWith('/KnowledgeBase');
  });
  const SystemIntentsToShow = IntentsService.SystemIntents[currentBotLanguage || 'EN'];

  const [intentDetail, setIntentDetail] = useState<IntentWithItems>({
    intent: {},
    items: [],
  });
  const [intentRequestState, getIntentDetail] = usePromiseProcessing((id: string) =>
    IntentsService.getIntent(Number(id))
  );
  const [status, listIntents, listIntentsOptimistic] = usePromiseProcessing(() => IntentsService.listIntents());
  const [deletionStatus, deleteIntent] = usePromiseProcessing(async (ids: number[]) => {
    await IntentsService.deleteIntents(ids).then(() => {
      setSelected(prevSelected => {
        if (prevSelected && ids.includes(parseInt(prevSelected, 10))) return '';
        return prevSelected;
      });
    });
    await IntentsService.trainNLU();
  });
  const [savingIntentDataStatus, saveIntentData] = usePromiseProcessing(
    (intent: IntentData, softValidate: boolean = true) =>
      IntentsService.saveIntentData(intent.id!, { ...intent, path: intent.path?.trim() }, undefined, softValidate),
    { throwOnError: true }
  );
  const [creatingStatus, createTopLevelIntentFromTextWithoutFirstPhrase] = usePromiseProcessing(
    (text: string) => IntentsService.createTopLevelIntentFromTextWithoutFirstPhrase(text),
    { throwOnError: true }
  );

  useEffect(() => {
    onSelectIntent(selected);
    setIntentEditError('');
    setIntentEditErrorArgs(undefined);
    setComplexErrors(new ComplexErrorsType());
  }, [onSelectIntent, selected]);

  useEffect(() => {
    setSelected(prevSelected => {
      if (intentId && prevSelected !== String(intentId)) return String(intentId);
      return prevSelected;
    });
  }, [intentId]);

  useEffect(() => {
    listIntents();
  }, [listIntents]);

  useEffect(() => {
    if (!isSearchOpen) {
      setSearch('');
    }
  }, [isSearchOpen]);

  useEffect(() => {
    if (!selected) return;
    if (IntentsService.isSystemIntent(currentBotLanguage, selected)) return;
    getIntentDetail(selected).then(({ data }) => {
      const convertedToIntentWithItems = convertToIntentWithItems(data);
      convertedToIntentWithItems.items = convertedToIntentWithItems.items.map((item, index) =>
        convertIntentItemWithId(item, index)
      );
      setIntentDetail(convertedToIntentWithItems as IntentWithItems);
    });
  }, [IntentsService, currentBotLanguage, getIntentDetail, selected]);

  const pushIntent = useCallback(
    (intent: IntentData, softValidate: boolean = true) => {
      const complexValidationData = validateIntent(intent);
      setComplexErrors(complexValidationData);
      if (!intent.id || !complexValidationData.isIntentValid)
        return new Promise((resolve, reject) => {
          window.setTimeout(() => {
            return reject(t('IntentsPage:intentCreatingError'));
          }, DEBOUNCE_TIMEOUT);
        });
      return saveIntentData(intent, softValidate).then(data => {
        const oldPath = UserIntentsToShow.find(userIntent => userIntent.id === intent.id)?.path;
        if (oldPath && intent.path !== oldPath && onIntentRenamed) {
          onIntentRenamed({
            oldValue: oldPath,
            newValue: intent.path!,
          });
        }
        setIntentDetail(prevState => {
          if (prevState.intent.id === data.updatedIntent?.id && data.updatedIntent) {
            return {
              intent: data.updatedIntent,
              items: prevState.items,
            };
          }
          return prevState;
        });
        setClear();
        return data;
      });
    },
    [saveIntentData, UserIntentsToShow, onIntentRenamed, setClear]
  );

  const setErrorFromResponse = useCallback((reason: any) => {
    let errorArgs = getErrorArgsFromReason(reason);
    if (reason.response?.status === 406) {
      errorArgs = reason.response.data;
      setIntentEditError('validation');
      setIntentEditErrorArgs(errorArgs);
      return;
    }
    setIntentEditError(getErrorMessageFromReason(reason, t, true));
    setIntentEditErrorArgs(errorArgs);
  }, []);

  const debouncedPushIntent = useCallback(
    (intent: IntentData, softValidate?: boolean) => {
      setIsDirty();
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        pushIntent(intent, softValidate)
          .then(() => {
            if (complexErrors.isIntentValid) {
              setIntentEditError('');
              setIntentEditErrorArgs(undefined);
              listIntentsOptimistic().then(() => forceUpdate());
            }
          })
          .catch(error => error !== 'notValid' && setErrorFromResponse(error));
      }, DEBOUNCE_TIMEOUT);
    },
    [complexErrors.isIntentValid, forceUpdate, listIntentsOptimistic, pushIntent, setErrorFromResponse, setIsDirty]
  );

  const clearValError = useCallback(() => {
    if (intentEditError === 'validation') {
      setIntentEditError('');
      setIntentEditErrorArgs(undefined);
    }
  }, [intentEditError]);

  const handleIntentChange = useCallback(
    <TKey extends keyof IntentData>(value: IntentData[TKey], key?: TKey, softValidate?: boolean) => {
      if (!key || !intentDetail) return null;
      if (key === 'path' && intentDetail.intent.path === value) return;
      const updatedIntent = { ...intentDetail.intent, [key]: value };
      setIntentDetail(prevValue => ({
        intent: updatedIntent,
        items: prevValue.items,
      }));
      debouncedPushIntent(updatedIntent, softValidate);
    },
    [debouncedPushIntent, intentDetail]
  );

  const createNewIntent = useCallback(() => {
    createTopLevelIntentFromTextWithoutFirstPhrase(t('IntentsPage:defaultNewIntentName')).then(data => {
      setSelected(String(data.id));
      savingIntentDataStatus.reset();
      setClear();
    });
  }, [createTopLevelIntentFromTextWithoutFirstPhrase, savingIntentDataStatus, setClear]);

  const handleAddPhrases = useCallback(
    async (newPhrasesTexts: IntentItem[], softValidate?: boolean) => {
      if (!intentDetail) return Promise.reject('no intent');
      const updatedItems = [...intentDetail.items];

      newPhrasesTexts.forEach(item => updatedItems.push(convertIntentItemWithId(item, updatedItems.length)));

      const updatedIntent = updateItems(intentDetail.intent, updatedItems);
      clearValError();
      return pushIntent(updatedIntent, softValidate)
        .then(data => {
          setIntentDetail({ intent: updatedIntent, items: updatedItems });
          return data;
        })
        .catch(error => {
          setErrorFromResponse(error);
          throw error;
        });
    },
    [clearValError, intentDetail, pushIntent, setErrorFromResponse]
  );

  const handleDeleteItems = useCallback(
    (deletedIndexes: number[]) => {
      if (!intentDetail.intent) return;

      const updatedItems = intentDetail.items.filter(_item => !deletedIndexes.includes(_item.index));
      const updatedIntent = updateItems(intentDetail.intent, updatedItems);

      pushIntent(updatedIntent);
    },
    [pushIntent, intentDetail.intent, intentDetail.items]
  );
  const onDeleteItem = useCallback(
    (indexes: number[]) => {
      setIntentDetail(prevIntentDetail => {
        const newIntentDetail = { ...prevIntentDetail };
        const itemsToDelete = prevIntentDetail.items.filter((_item, i) =>
          indexes.includes(prevIntentDetail.items.length - 1 - i)
        );
        const newItems = prevIntentDetail.items.filter(
          (_item, i) => !indexes.includes(prevIntentDetail.items.length - 1 - i)
        );
        const indexesToDelete: number[] = itemsToDelete ? itemsToDelete.map(item => item.index) : [];
        handleDeleteItems(indexesToDelete);
        newIntentDetail.items = newItems;
        return newIntentDetail;
      });
    },
    [handleDeleteItems]
  );

  const handleChangeItemText = useCallback(
    (updateData: CustomIntentItem | CustomIntentItem[], index?: number, softValidate?: boolean) => {
      const currentIntent = intentDetail;
      if (!currentIntent) return;

      let updatedItems = Array.isArray(updateData) ? [...updateData] : [...currentIntent.items];
      if (index === undefined && !Array.isArray(updateData)) {
        index = currentIntent.items.findIndex(item => item.id === updateData.id);
      }
      if (!Array.isArray(updateData) && index !== undefined) {
        updatedItems[index] = updateData;
      }

      updatedItems = updatedItems.map((item, index) => convertIntentItemWithId(item, index));

      const updatedIntent = updateItems(currentIntent.intent, updatedItems);

      setIntentDetail({
        intent: updatedIntent,
        items: updatedItems,
      });

      debouncedPushIntent(updatedIntent, softValidate);
    },
    [debouncedPushIntent, intentDetail]
  );

  const payloadMemo = useMemo(() => {
    return {
      openedItem: -1,
      setOpenedItem: () => {},
      onDelete: onDeleteItem,
      editDisabled: false,
    };
  }, [onDeleteItem]);

  const changePath = useCallback(
    (value: string) => {
      handleIntentChange(`${Intents.getParentPathFromPath(intentDetail.intent.path)}/${value}`, 'path');
    },
    [intentDetail.intent.path, handleIntentChange]
  );

  const isSystemIntentsOn = Boolean(
    SettingsService.projectSettingsData &&
      SettingsService.projectSettingsData.extendedSettings?.useShared &&
      SettingsService.projectSettingsData.extendedSettings?.zflPatternsEnabled
  );
  const savingStatus = useMemo(() => {
    switch (true) {
      case savingIntentDataStatus.loading:
        return t('CailaIntentsSimple:IntentDetail:saveStatus:Saving');
      case Boolean(isDirty || savingIntentDataStatus.error):
        return t('CailaIntentsSimple:IntentDetail:saveStatus:NotSaved');
      default:
        return t('CailaIntentsSimple:IntentDetail:saveStatus:Saved');
    }
  }, [isDirty, savingIntentDataStatus.error, savingIntentDataStatus.loading]);

  return (
    <div className={classes.CailaIntentsSimpleWrapper}>
      {(status.loading || creatingStatus.loading) && <Spinner />}
      {!status.loading && !creatingStatus.loading && (
        <div className={cn('d-flex', classes.CailaIntentsSimple_body)}>
          <div className={cn('d-flex flex-column', classes.CailaIntentsSimple_list_container)}>
            <div
              className={cn(
                'd-flex flex-row w-100 justify-space-between position-relative',
                classes.CailaIntentsSimple_list_container_header
              )}
            >
              <Search
                isSearchOpen={isSearchOpen}
                onChangeSearch={setSearch}
                searchValue={search}
                closeSearch={closeSearch}
              />
              <div className='font-size-14 font-bold color-gray-800 d-flex flex-grow-1'>{t('Intents')}</div>
              <div className='d-flex gap-8'>
                <IconButton
                  type='button'
                  name='farPlus'
                  className={cn('color-gray-800', classes.CailaIntentsSimple_list_container_header_customButtons)}
                  onClick={createNewIntent}
                />
                <IconButton
                  type='button'
                  name='farSearch'
                  onClick={openSearch}
                  className={cn('color-gray-800', classes.CailaIntentsSimple_list_container_header_customButtons)}
                />
              </div>
            </div>

            <div className={classes.CailaIntentsSimple_list_container_list}>
              {isSystemIntentsOn && UserIntentsToShow.length > 0 && (
                <NluIntentTitle title={t('CailaIntentsSimple:IntentListTitle:User')} count={UserIntentsToShow.length} />
              )}
              <NluItemsContainer>
                {deletionStatus.loading && <Spinner />}
                {UserIntentsToShow.filter(intent => {
                  if (search) {
                    let pureSearchValueLowercase = getPureDisplayValue(intent.path).toLowerCase();
                    return pureSearchValueLowercase.includes(search.toLowerCase());
                  }
                  return true;
                }).map(intent => (
                  <NluIntentItem
                    intent={intent}
                    selectedId={selected}
                    setSelectedId={setSelected}
                    key={`${intent.path}${intent?.enabled}`}
                    deleteIntent={deleteIntent}
                    pushIntent={pushIntent}
                  />
                ))}
                {UserIntentsToShow.length === 0 && <IntentItemPlaceholder />}
              </NluItemsContainer>
              {isSystemIntentsOn && (
                <>
                  <NluIntentTitle title={t('CailaIntentsSimple:IntentListTitle:System')} />
                  <NluItemsContainer>
                    {SystemIntentsToShow.filter(systemIntent => {
                      if (search) {
                        let pureSearchValueLowercase = t(`ChooseReadyIntent ${systemIntent}`).toLowerCase();
                        return pureSearchValueLowercase.includes(search.toLowerCase());
                      }
                      return true;
                    }).map(systemIntent => (
                      <NluSystemIntentItem
                        key={systemIntent}
                        id={systemIntent}
                        selectedId={selected}
                        setSelectedId={setSelected}
                        path={systemIntent}
                      />
                    ))}
                  </NluItemsContainer>
                </>
              )}
            </div>
          </div>
          <div
            className={cn('d-flex', classes.CailaIntentsSimple_content, {
              'justify-content-center': !Boolean(selected),
            })}
          >
            {!Boolean(selected) && (
              <IntentContentPlaceholder createNewIntent={createNewIntent} hasSystemIntents={isSystemIntentsOn} />
            )}
            {Boolean(selected) && !IntentsService.isSystemIntent(currentBotLanguage, selected) && (
              <IntentDetail
                saveStatus={savingStatus}
                intentDetail={intentDetail}
                handleAddPhrases={handleAddPhrases}
                onDeleteItem={onDeleteItem}
                handleChangeItemText={handleChangeItemText}
                intentRequestState={intentRequestState}
                payloadMemo={payloadMemo}
                changePath={changePath}
                intentEditError={intentEditError}
                intentEditErrorArgs={intentEditErrorArgs}
                complexErrors={complexErrors}
                clearValError={clearValError}
              />
            )}
            {Boolean(selected) && IntentsService.isSystemIntent(currentBotLanguage, selected) && (
              <IntentSystemDetail systemIntent={selected} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
