// tslint:disable
// @ts-nocheck
/**
 * CAILA REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from './base';
/**
 * @export
 * @enum {string}
 */
export var StatusEnum;
(function (StatusEnum) {
    StatusEnum["InProgress"] = "inProgress";
    StatusEnum["Completed"] = "completed";
    StatusEnum["Failed"] = "failed";
    StatusEnum["Canceled"] = "canceled";
})(StatusEnum || (StatusEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var LanguageCodeEnum;
(function (LanguageCodeEnum) {
    LanguageCodeEnum["EnRU"] = "enRU";
    LanguageCodeEnum["Multi"] = "multi";
    LanguageCodeEnum["Test"] = "test";
})(LanguageCodeEnum || (LanguageCodeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ClassificationPredicate;
(function (ClassificationPredicate) {
    ClassificationPredicate["CONTAINS"] = "CONTAINS";
    ClassificationPredicate["CONTAINSNOT"] = "CONTAINS_NOT";
})(ClassificationPredicate || (ClassificationPredicate = {}));
/**
 * @export
 * @enum {string}
 */
export var MethodEnum;
(function (MethodEnum) {
    MethodEnum["Simple"] = "simple";
})(MethodEnum || (MethodEnum = {}));
/**
 * @export
 * @enum {string}
 */
(function (MethodEnum) {
    MethodEnum["Duplicates"] = "duplicates";
    MethodEnum["UdpipeKeywords"] = "udpipeKeywords";
    MethodEnum["TfidfKeywords"] = "tfidfKeywords";
    MethodEnum["LinkageClustering"] = "linkageClustering";
    MethodEnum["KmeansClustering"] = "kmeansClustering";
    MethodEnum["StsdbscanClustering"] = "stsdbscanClustering";
    MethodEnum["Classification"] = "classification";
    MethodEnum["NotRecognizedMessages"] = "notRecognizedMessages";
})(MethodEnum || (MethodEnum = {}));
/**
 * Strategy to resolve confilcs on import
 * @export
 * @enum {string}
 */
export var ImportFileStrategy;
(function (ImportFileStrategy) {
    ImportFileStrategy["Overwrite"] = "overwrite";
    ImportFileStrategy["Merge"] = "merge";
    ImportFileStrategy["Unresolved"] = "unresolved";
    ImportFileStrategy["Cleanwrite"] = "cleanwrite";
    ImportFileStrategy["Skipwrite"] = "skipwrite";
})(ImportFileStrategy || (ImportFileStrategy = {}));
/**
 * @export
 * @enum {string}
 */
export var TypeEnum;
(function (TypeEnum) {
    TypeEnum["FileSession"] = "fileSession";
    TypeEnum["ChatHistorySession"] = "chatHistorySession";
})(TypeEnum || (TypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var TrainingStatusEnum;
(function (TrainingStatusEnum) {
    TrainingStatusEnum["NONE"] = "NONE";
    TrainingStatusEnum["TRAINING"] = "TRAINING";
    TrainingStatusEnum["READY"] = "READY";
    TrainingStatusEnum["FAILED"] = "FAILED";
})(TrainingStatusEnum || (TrainingStatusEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var NamedEntityRecordType;
(function (NamedEntityRecordType) {
    NamedEntityRecordType["Pattern"] = "pattern";
    NamedEntityRecordType["Synonyms"] = "synonyms";
})(NamedEntityRecordType || (NamedEntityRecordType = {}));
/**
 * Type of the named entity. Dictionary - for rules and dictionaries and Annotation - for ML-based entities.
 * @export
 * @enum {string}
 */
export var NamedEntityType;
(function (NamedEntityType) {
    NamedEntityType["Dictionary"] = "dictionary";
    NamedEntityType["Annotation"] = "annotation";
})(NamedEntityType || (NamedEntityType = {}));
/**
 * @export
 * @enum {string}
 */
export var NluTypeEnum;
(function (NluTypeEnum) {
    NluTypeEnum["Caila"] = "caila";
    NluTypeEnum["Internal"] = "internal";
    NluTypeEnum["External"] = "external";
})(NluTypeEnum || (NluTypeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PhraseStatusData;
(function (PhraseStatusData) {
    PhraseStatusData["P"] = "p";
    PhraseStatusData["S"] = "s";
    PhraseStatusData["A"] = "a";
    PhraseStatusData["D"] = "d";
})(PhraseStatusData || (PhraseStatusData = {}));
/**
 * @export
 * @enum {string}
 */
export var ClassificationAlgorithmEnum;
(function (ClassificationAlgorithmEnum) {
    ClassificationAlgorithmEnum["Sts"] = "sts";
    ClassificationAlgorithmEnum["Regression"] = "regression";
    ClassificationAlgorithmEnum["Deeplearning"] = "deeplearning";
    ClassificationAlgorithmEnum["Logreg"] = "logreg";
    ClassificationAlgorithmEnum["Transformer"] = "transformer";
})(ClassificationAlgorithmEnum || (ClassificationAlgorithmEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var SkillTrainingStatusDataEnum;
(function (SkillTrainingStatusDataEnum) {
    SkillTrainingStatusDataEnum["NONE"] = "NONE";
    SkillTrainingStatusDataEnum["TRAINING"] = "TRAINING";
    SkillTrainingStatusDataEnum["READY"] = "READY";
    SkillTrainingStatusDataEnum["FAILED"] = "FAILED";
})(SkillTrainingStatusDataEnum || (SkillTrainingStatusDataEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var SkillType;
(function (SkillType) {
    SkillType["Faq"] = "faq";
    SkillType["Cdqa"] = "cdqa";
})(SkillType || (SkillType = {}));
/**
 * @export
 * @enum {string}
 */
export var SpelledByEnum;
(function (SpelledByEnum) {
    SpelledByEnum["Mlps"] = "mlps";
    SpelledByEnum["Local"] = "local";
})(SpelledByEnum || (SpelledByEnum = {}));
/**
 * AsyncApi - axios parameter creator
 * @export
 */
export const AsyncApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAsyncTasks(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getActiveAsyncTasks.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getActiveAsyncTasks.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsyncTaskStatus(accountId, projectId, taskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAsyncTaskStatus.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getAsyncTaskStatus.');
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId', 'Required parameter taskId was null or undefined when calling getAsyncTaskStatus.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async/{taskId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAsyncTask(accountId, projectId, taskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling stopAsyncTask.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling stopAsyncTask.');
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId', 'Required parameter taskId was null or undefined when calling stopAsyncTask.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async/{taskId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AsyncApi - functional programming interface
 * @export
 */
export const AsyncApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAsyncTasks(accountId, projectId, options) {
            const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).getActiveAsyncTasks(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsyncTaskStatus(accountId, projectId, taskId, options) {
            const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).getAsyncTaskStatus(accountId, projectId, taskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAsyncTask(accountId, projectId, taskId, options) {
            const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).stopAsyncTask(accountId, projectId, taskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AsyncApi - factory interface
 * @export
 */
export const AsyncApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAsyncTasks(accountId, projectId, options) {
            return AsyncApiFp(configuration).getActiveAsyncTasks(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsyncTaskStatus(accountId, projectId, taskId, options) {
            return AsyncApiFp(configuration).getAsyncTaskStatus(accountId, projectId, taskId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAsyncTask(accountId, projectId, taskId, options) {
            return AsyncApiFp(configuration).stopAsyncTask(accountId, projectId, taskId, options)(axios, basePath);
        },
    };
};
/**
 * AsyncApi - object-oriented interface
 * @export
 * @class AsyncApi
 * @extends {BaseAPI}
 */
export class AsyncApi extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsyncApi
     */
    getActiveAsyncTasks(accountId, projectId, options) {
        return AsyncApiFp(this.configuration).getActiveAsyncTasks(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsyncApi
     */
    getAsyncTaskStatus(accountId, projectId, taskId, options) {
        return AsyncApiFp(this.configuration).getAsyncTaskStatus(accountId, projectId, taskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsyncApi
     */
    stopAsyncTask(accountId, projectId, taskId, options) {
        return AsyncApiFp(this.configuration).stopAsyncTask(accountId, projectId, taskId, options)(this.axios, this.basePath);
    }
}
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCurrentAccountTariffLimits(options = {}) {
            const localVarPath = `/api/caila/billing/limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountTariffLimits(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getCurrentAccountTariffLimits.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/billing/limits`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {TariffUpdateData} tariffUpdateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountTariff(accountId, tariffUpdateData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateAccountTariff.');
            }
            // verify required parameter 'tariffUpdateData' is not null or undefined
            if (tariffUpdateData === null || tariffUpdateData === undefined) {
                throw new RequiredError('tariffUpdateData', 'Required parameter tariffUpdateData was null or undefined when calling updateAccountTariff.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/billing/account-tariff`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tariffUpdateData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tariffUpdateData !== undefined ? tariffUpdateData : {})
                : tariffUpdateData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCurrentAccountTariffLimits(options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getAllCurrentAccountTariffLimits(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountTariffLimits(accountId, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getCurrentAccountTariffLimits(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {TariffUpdateData} tariffUpdateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountTariff(accountId, tariffUpdateData, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateAccountTariff(accountId, tariffUpdateData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCurrentAccountTariffLimits(options) {
            return BillingApiFp(configuration).getAllCurrentAccountTariffLimits(options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountTariffLimits(accountId, options) {
            return BillingApiFp(configuration).getCurrentAccountTariffLimits(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {TariffUpdateData} tariffUpdateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountTariff(accountId, tariffUpdateData, options) {
            return BillingApiFp(configuration).updateAccountTariff(accountId, tariffUpdateData, options)(axios, basePath);
        },
    };
};
/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    getAllCurrentAccountTariffLimits(options) {
        return BillingApiFp(this.configuration).getAllCurrentAccountTariffLimits(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    getCurrentAccountTariffLimits(accountId, options) {
        return BillingApiFp(this.configuration).getCurrentAccountTariffLimits(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {TariffUpdateData} tariffUpdateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    updateAccountTariff(accountId, tariffUpdateData, options) {
        return BillingApiFp(this.configuration).updateAccountTariff(accountId, tariffUpdateData, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling analyzeE2ECheck.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling analyzeE2ECheck.');
            }
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling analyzeE2ECheck.');
            }
            // verify required parameter 'analyzeRequestData' is not null or undefined
            if (analyzeRequestData === null || analyzeRequestData === undefined) {
                throw new RequiredError('analyzeRequestData', 'Required parameter analyzeRequestData was null or undefined when calling analyzeE2ECheck.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/e2e-check/nlu/analyze`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter['Z-requestId'] = String(zRequestId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyzeRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyzeRequestData !== undefined ? analyzeRequestData : {})
                : analyzeRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options) {
            return E2ECheckApiFp(configuration).analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).analyzeE2ECheck(accountId, projectId, loggingEnabledE2ECheck, analyzeRequestData, zTimezone, zRefTime, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * EntitiesApi - axios parameter creator
 * @export
 */
export const EntitiesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create new entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntity(accountId, projectId, namedEntityData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createEntity.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createEntity.');
            }
            // verify required parameter 'namedEntityData' is not null or undefined
            if (namedEntityData === null || namedEntityData === undefined) {
                throw new RequiredError('namedEntityData', 'Required parameter namedEntityData was null or undefined when calling createEntity.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof namedEntityData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(namedEntityData !== undefined ? namedEntityData : {})
                : namedEntityData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createEntityRecord.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createEntityRecord.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling createEntityRecord.');
            }
            // verify required parameter 'createNamedEntityRecordData' is not null or undefined
            if (createNamedEntityRecordData === null ||
                createNamedEntityRecordData === undefined) {
                throw new RequiredError('createNamedEntityRecordData', 'Required parameter createNamedEntityRecordData was null or undefined when calling createEntityRecord.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createNamedEntityRecordData !== undefined
                    ? createNamedEntityRecordData
                    : {})
                : createNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createEntityRecordByEntityName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createEntityRecordByEntityName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling createEntityRecordByEntityName.');
            }
            // verify required parameter 'createNamedEntityRecordData' is not null or undefined
            if (createNamedEntityRecordData === null ||
                createNamedEntityRecordData === undefined) {
                throw new RequiredError('createNamedEntityRecordData', 'Required parameter createNamedEntityRecordData was null or undefined when calling createEntityRecordByEntityName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createNamedEntityRecordData !== undefined
                    ? createNamedEntityRecordData
                    : {})
                : createNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of entities ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntities(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteEntities.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/delete-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of entities names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntitiesByNames(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteEntitiesByNames.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteEntitiesByNames.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteEntitiesByNames.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/delete-multiple-by-name`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecords(accountId, projectId, entityId, requestBody, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteRecords.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteRecords.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling deleteRecords.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteRecords.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/delete-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteRecordsByEntityName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteRecordsByEntityName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling deleteRecordsByEntityName.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteRecordsByEntityName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records/delete-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export all project entities with records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntities(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling exportEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling exportEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/export-file`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all available entity names. It will be used for autocompletion.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntityNames(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAllEntityNames.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getAllEntityNames.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/all-names`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get entities by names.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [names]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesByNames(accountId, projectId, names, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntitiesByNames.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntitiesByNames.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/get-by-names`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (names) {
                localVarQueryParameter['names'] = names;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get named entity.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntity(accountId, projectId, entityId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntity.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntity.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling getEntity.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByName(accountId, projectId, entityName, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntityByName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntityByName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling getEntityByName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-name/{entityName}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get sentences with this entity. It could be useful for ML-based entities
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOccurrences(accountId, projectId, entityId, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntityOccurrences.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntityOccurrences.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling getEntityOccurrences.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/occurences`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntityRecords.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntityRecords.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling getEntityRecords.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getEntityRecordsByEntityName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getEntityRecordsByEntityName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling getEntityRecordsByEntityName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Import all project entities with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntities(accountId, projectId, strategy, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importEntities.');
            }
            // verify required parameter 'strategy' is not null or undefined
            if (strategy === null || strategy === undefined) {
                throw new RequiredError('strategy', 'Required parameter strategy was null or undefined when calling importEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/import`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (strategy !== undefined) {
                localVarQueryParameter['strategy'] = strategy;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List of all available system entities. Method is only for testing purpose.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableSystemEntities(options = {}) {
            const localVarPath = `/api/caila/system-entities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnabledSystemEntities(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listEnabledSystemEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listEnabledSystemEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/enabled-system-entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List project entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [withRecordsCount] Add records count to entites in list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntities(accountId, projectId, withRecordsCount, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (withRecordsCount !== undefined) {
                localVarQueryParameter['withRecordsCount'] = withRecordsCount;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} entityNames Entity names
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesByNames(accountId, projectId, entityNames, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listEntitiesByNames.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listEntitiesByNames.');
            }
            // verify required parameter 'entityNames' is not null or undefined
            if (entityNames === null || entityNames === undefined) {
                throw new RequiredError('entityNames', 'Required parameter entityNames was null or undefined when calling listEntitiesByNames.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            if (entityNames) {
                localVarQueryParameter['entityNames'] = entityNames;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPermittedSystemEntities(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listPermittedSystemEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listPermittedSystemEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/permitted-system-entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, entitiesSearchCriteria, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling search.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling search.');
            }
            // verify required parameter 'entitiesSearchCriteria' is not null or undefined
            if (entitiesSearchCriteria === null ||
                entitiesSearchCriteria === undefined) {
                throw new RequiredError('entitiesSearchCriteria', 'Required parameter entitiesSearchCriteria was null or undefined when calling search.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/search`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof entitiesSearchCriteria !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(entitiesSearchCriteria !== undefined ? entitiesSearchCriteria : {})
                : entitiesSearchCriteria || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateAllEntityRecords.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateAllEntityRecords.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling updateAllEntityRecords.');
            }
            // verify required parameter 'updateAllNamedEntityRecordData' is not null or undefined
            if (updateAllNamedEntityRecordData === null ||
                updateAllNamedEntityRecordData === undefined) {
                throw new RequiredError('updateAllNamedEntityRecordData', 'Required parameter updateAllNamedEntityRecordData was null or undefined when calling updateAllEntityRecords.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateAllNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateAllNamedEntityRecordData !== undefined
                    ? updateAllNamedEntityRecordData
                    : {})
                : updateAllNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateAllEntityRecordsByEntityName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateAllEntityRecordsByEntityName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling updateAllEntityRecordsByEntityName.');
            }
            // verify required parameter 'updateAllNamedEntityRecordData' is not null or undefined
            if (updateAllNamedEntityRecordData === null ||
                updateAllNamedEntityRecordData === undefined) {
                throw new RequiredError('updateAllNamedEntityRecordData', 'Required parameter updateAllNamedEntityRecordData was null or undefined when calling updateAllEntityRecordsByEntityName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-name/{entityName}/records`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateAllNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateAllNamedEntityRecordData !== undefined
                    ? updateAllNamedEntityRecordData
                    : {})
                : updateAllNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateEnabledSystemEntities.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateEnabledSystemEntities.');
            }
            // verify required parameter 'systemNamedEntitySummary' is not null or undefined
            if (systemNamedEntitySummary === null ||
                systemNamedEntitySummary === undefined) {
                throw new RequiredError('systemNamedEntitySummary', 'Required parameter systemNamedEntitySummary was null or undefined when calling updateEnabledSystemEntities.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/enabled-system-entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof systemNamedEntitySummary !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(systemNamedEntitySummary !== undefined
                    ? systemNamedEntitySummary
                    : {})
                : systemNamedEntitySummary || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update named entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntity(accountId, projectId, entityId, namedEntityData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateEntity.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateEntity.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling updateEntity.');
            }
            // verify required parameter 'namedEntityData' is not null or undefined
            if (namedEntityData === null || namedEntityData === undefined) {
                throw new RequiredError('namedEntityData', 'Required parameter namedEntityData was null or undefined when calling updateEntity.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof namedEntityData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(namedEntityData !== undefined ? namedEntityData : {})
                : namedEntityData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateEntityRecord.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateEntityRecord.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling updateEntityRecord.');
            }
            // verify required parameter 'recordId' is not null or undefined
            if (recordId === null || recordId === undefined) {
                throw new RequiredError('recordId', 'Required parameter recordId was null or undefined when calling updateEntityRecord.');
            }
            // verify required parameter 'updateNamedEntityRecordData' is not null or undefined
            if (updateNamedEntityRecordData === null ||
                updateNamedEntityRecordData === undefined) {
                throw new RequiredError('updateNamedEntityRecordData', 'Required parameter updateNamedEntityRecordData was null or undefined when calling updateEntityRecord.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/{recordId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)))
                .replace(`{${'recordId'}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateNamedEntityRecordData !== undefined
                    ? updateNamedEntityRecordData
                    : {})
                : updateNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateEntityRecordByEntityName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateEntityRecordByEntityName.');
            }
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError('entityName', 'Required parameter entityName was null or undefined when calling updateEntityRecordByEntityName.');
            }
            // verify required parameter 'recordId' is not null or undefined
            if (recordId === null || recordId === undefined) {
                throw new RequiredError('recordId', 'Required parameter recordId was null or undefined when calling updateEntityRecordByEntityName.');
            }
            // verify required parameter 'updateNamedEntityRecordData' is not null or undefined
            if (updateNamedEntityRecordData === null ||
                updateNamedEntityRecordData === undefined) {
                throw new RequiredError('updateNamedEntityRecordData', 'Required parameter updateNamedEntityRecordData was null or undefined when calling updateEntityRecordByEntityName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records/{recordId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)))
                .replace(`{${'recordId'}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateNamedEntityRecordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateNamedEntityRecordData !== undefined
                    ? updateNamedEntityRecordData
                    : {})
                : updateNamedEntityRecordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Upload records from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {'zb-csv'} type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecords(accountId, projectId, entityId, type, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling uploadRecords.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling uploadRecords.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId', 'Required parameter entityId was null or undefined when calling uploadRecords.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling uploadRecords.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/upload`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EntitiesApi - functional programming interface
 * @export
 */
export const EntitiesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create new entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntity(accountId, projectId, namedEntityData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntity(accountId, projectId, namedEntityData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of entities ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntities(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteEntities(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of entities names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntitiesByNames(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteEntitiesByNames(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecords(accountId, projectId, entityId, requestBody, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteRecords(accountId, projectId, entityId, requestBody, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Export all project entities with records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntities(accountId, projectId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).exportEntities(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all available entity names. It will be used for autocompletion.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntityNames(accountId, projectId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getAllEntityNames(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get entities by names.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [names]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesByNames(accountId, projectId, names, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntitiesByNames(accountId, projectId, names, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get named entity.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntity(accountId, projectId, entityId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntity(accountId, projectId, entityId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByName(accountId, projectId, entityName, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityByName(accountId, projectId, entityName, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get sentences with this entity. It could be useful for ML-based entities
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOccurrences(accountId, projectId, entityId, page, size, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityOccurrences(accountId, projectId, entityId, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Import all project entities with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntities(accountId, projectId, strategy, file, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).importEntities(accountId, projectId, strategy, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List of all available system entities. Method is only for testing purpose.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableSystemEntities(options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listAvailableSystemEntities(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnabledSystemEntities(accountId, projectId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEnabledSystemEntities(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List project entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [withRecordsCount] Add records count to entites in list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntities(accountId, projectId, withRecordsCount, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEntities(accountId, projectId, withRecordsCount, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} entityNames Entity names
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesByNames(accountId, projectId, entityNames, clientId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEntitiesByNames(accountId, projectId, entityNames, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPermittedSystemEntities(accountId, projectId, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listPermittedSystemEntities(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, entitiesSearchCriteria, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).search(accountId, projectId, entitiesSearchCriteria, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update named entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntity(accountId, projectId, entityId, namedEntityData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntity(accountId, projectId, entityId, namedEntityData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Upload records from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {'zb-csv'} type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecords(accountId, projectId, entityId, type, file, options) {
            const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).uploadRecords(accountId, projectId, entityId, type, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * EntitiesApi - factory interface
 * @export
 */
export const EntitiesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create new entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntity(accountId, projectId, namedEntityData, options) {
            return EntitiesApiFp(configuration).createEntity(accountId, projectId, namedEntityData, options)(axios, basePath);
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Create new entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of entities ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntities(accountId, projectId, requestBody, options) {
            return EntitiesApiFp(configuration).deleteEntities(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete a list of entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of entities names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntitiesByNames(accountId, projectId, requestBody, options) {
            return EntitiesApiFp(configuration).deleteEntitiesByNames(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecords(accountId, projectId, entityId, requestBody, clientId, options) {
            return EntitiesApiFp(configuration).deleteRecords(accountId, projectId, entityId, requestBody, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete multiple records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {Array<number>} requestBody Record IDs to be deleted
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options) {
            return EntitiesApiFp(configuration).deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary Export all project entities with records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntities(accountId, projectId, options) {
            return EntitiesApiFp(configuration).exportEntities(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all available entity names. It will be used for autocompletion.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntityNames(accountId, projectId, options) {
            return EntitiesApiFp(configuration).getAllEntityNames(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get entities by names.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [names]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntitiesByNames(accountId, projectId, names, options) {
            return EntitiesApiFp(configuration).getEntitiesByNames(accountId, projectId, names, options)(axios, basePath);
        },
        /**
         *
         * @summary Get named entity.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntity(accountId, projectId, entityId, options) {
            return EntitiesApiFp(configuration).getEntity(accountId, projectId, entityId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByName(accountId, projectId, entityName, clientId, options) {
            return EntitiesApiFp(configuration).getEntityByName(accountId, projectId, entityName, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get sentences with this entity. It could be useful for ML-based entities
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityOccurrences(accountId, projectId, entityId, page, size, options) {
            return EntitiesApiFp(configuration).getEntityOccurrences(accountId, projectId, entityId, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options) {
            return EntitiesApiFp(configuration).getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get named entity dictionary records
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {string} [searchQuery] Search query to filter entity records
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options) {
            return EntitiesApiFp(configuration).getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary Import all project entities with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntities(accountId, projectId, strategy, file, options) {
            return EntitiesApiFp(configuration).importEntities(accountId, projectId, strategy, file, options)(axios, basePath);
        },
        /**
         *
         * @summary List of all available system entities. Method is only for testing purpose.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableSystemEntities(options) {
            return EntitiesApiFp(configuration).listAvailableSystemEntities(options)(axios, basePath);
        },
        /**
         *
         * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnabledSystemEntities(accountId, projectId, options) {
            return EntitiesApiFp(configuration).listEnabledSystemEntities(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary List project entities.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [withRecordsCount] Add records count to entites in list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntities(accountId, projectId, withRecordsCount, options) {
            return EntitiesApiFp(configuration).listEntities(accountId, projectId, withRecordsCount, options)(axios, basePath);
        },
        /**
         *
         * @summary Get entity with records by name
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} entityNames Entity names
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesByNames(accountId, projectId, entityNames, clientId, options) {
            return EntitiesApiFp(configuration).listEntitiesByNames(accountId, projectId, entityNames, clientId, options)(axios, basePath);
        },
        /**
         *
         * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPermittedSystemEntities(accountId, projectId, options) {
            return EntitiesApiFp(configuration).listPermittedSystemEntities(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, entitiesSearchCriteria, options) {
            return EntitiesApiFp(configuration).search(accountId, projectId, entitiesSearchCriteria, options)(axios, basePath);
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update content of the entity all at once.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options) {
            return EntitiesApiFp(configuration).updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options)(axios, basePath);
        },
        /**
         *
         * @summary Update named entity
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {NamedEntityData} namedEntityData New intents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntity(accountId, projectId, entityId, namedEntityData, options) {
            return EntitiesApiFp(configuration).updateEntity(accountId, projectId, entityId, namedEntityData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update entity record
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} entityName Entity unique name.
         * @param {number} recordId Record identifier.
         * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options) {
            return EntitiesApiFp(configuration).updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options)(axios, basePath);
        },
        /**
         *
         * @summary Upload records from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} entityId Entity identifier.
         * @param {'zb-csv'} type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecords(accountId, projectId, entityId, type, file, options) {
            return EntitiesApiFp(configuration).uploadRecords(accountId, projectId, entityId, type, file, options)(axios, basePath);
        },
    };
};
/**
 * EntitiesApi - object-oriented interface
 * @export
 * @class EntitiesApi
 * @extends {BaseAPI}
 */
export class EntitiesApi extends BaseAPI {
    /**
     *
     * @summary Create new entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    createEntity(accountId, projectId, namedEntityData, options) {
        return EntitiesApiFp(this.configuration).createEntity(accountId, projectId, namedEntityData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).createEntityRecord(accountId, projectId, entityId, createNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).createEntityRecordByEntityName(accountId, projectId, entityName, createNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of entities ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    deleteEntities(accountId, projectId, requestBody, options) {
        return EntitiesApiFp(this.configuration).deleteEntities(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of entities names.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    deleteEntitiesByNames(accountId, projectId, requestBody, options) {
        return EntitiesApiFp(this.configuration).deleteEntitiesByNames(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    deleteRecords(accountId, projectId, entityId, requestBody, clientId, options) {
        return EntitiesApiFp(this.configuration).deleteRecords(accountId, projectId, entityId, requestBody, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options) {
        return EntitiesApiFp(this.configuration).deleteRecordsByEntityName(accountId, projectId, entityName, requestBody, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Export all project entities with records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    exportEntities(accountId, projectId, options) {
        return EntitiesApiFp(this.configuration).exportEntities(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get all available entity names. It will be used for autocompletion.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getAllEntityNames(accountId, projectId, options) {
        return EntitiesApiFp(this.configuration).getAllEntityNames(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get entities by names.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [names]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntitiesByNames(accountId, projectId, names, options) {
        return EntitiesApiFp(this.configuration).getEntitiesByNames(accountId, projectId, names, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get named entity.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntity(accountId, projectId, entityId, options) {
        return EntitiesApiFp(this.configuration).getEntity(accountId, projectId, entityId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntityByName(accountId, projectId, entityName, clientId, options) {
        return EntitiesApiFp(this.configuration).getEntityByName(accountId, projectId, entityName, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get sentences with this entity. It could be useful for ML-based entities
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntityOccurrences(accountId, projectId, entityId, page, size, options) {
        return EntitiesApiFp(this.configuration).getEntityOccurrences(accountId, projectId, entityId, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options) {
        return EntitiesApiFp(this.configuration).getEntityRecords(accountId, projectId, entityId, page, size, searchQuery, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options) {
        return EntitiesApiFp(this.configuration).getEntityRecordsByEntityName(accountId, projectId, entityName, page, size, searchQuery, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Import all project entities with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    importEntities(accountId, projectId, strategy, file, options) {
        return EntitiesApiFp(this.configuration).importEntities(accountId, projectId, strategy, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List of all available system entities. Method is only for testing purpose.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    listAvailableSystemEntities(options) {
        return EntitiesApiFp(this.configuration).listAvailableSystemEntities(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    listEnabledSystemEntities(accountId, projectId, options) {
        return EntitiesApiFp(this.configuration).listEnabledSystemEntities(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List project entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [withRecordsCount] Add records count to entites in list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    listEntities(accountId, projectId, withRecordsCount, options) {
        return EntitiesApiFp(this.configuration).listEntities(accountId, projectId, withRecordsCount, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} entityNames Entity names
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    listEntitiesByNames(accountId, projectId, entityNames, clientId, options) {
        return EntitiesApiFp(this.configuration).listEntitiesByNames(accountId, projectId, entityNames, clientId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    listPermittedSystemEntities(accountId, projectId, options) {
        return EntitiesApiFp(this.configuration).listPermittedSystemEntities(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    search(accountId, projectId, entitiesSearchCriteria, options) {
        return EntitiesApiFp(this.configuration).search(accountId, projectId, entitiesSearchCriteria, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).updateAllEntityRecords(accountId, projectId, entityId, updateAllNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).updateAllEntityRecordsByEntityName(accountId, projectId, entityName, updateAllNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options) {
        return EntitiesApiFp(this.configuration).updateEnabledSystemEntities(accountId, projectId, systemNamedEntitySummary, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update named entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateEntity(accountId, projectId, entityId, namedEntityData, options) {
        return EntitiesApiFp(this.configuration).updateEntity(accountId, projectId, entityId, namedEntityData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).updateEntityRecord(accountId, projectId, entityId, recordId, updateNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options) {
        return EntitiesApiFp(this.configuration).updateEntityRecordByEntityName(accountId, projectId, entityName, recordId, updateNamedEntityRecordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Upload records from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {'zb-csv'} type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    uploadRecords(accountId, projectId, entityId, type, file, options) {
        return EntitiesApiFp(this.configuration).uploadRecords(accountId, projectId, entityId, type, file, options)(this.axios, this.basePath);
    }
}
/**
 * ExternalNluApi - axios parameter creator
 * @export
 */
export const ExternalNluApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Checks that the external NLU endpoint is available.
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testExternalNluLink(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling testExternalNluLink.');
            }
            const localVarPath = `/api/caila/external-nlu/test-link`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'text/plain';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof body !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ExternalNluApi - functional programming interface
 * @export
 */
export const ExternalNluApiFp = function (configuration) {
    return {
        /**
         * Checks that the external NLU endpoint is available.
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testExternalNluLink(body, options) {
            const localVarAxiosArgs = ExternalNluApiAxiosParamCreator(configuration).testExternalNluLink(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ExternalNluApi - factory interface
 * @export
 */
export const ExternalNluApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Checks that the external NLU endpoint is available.
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testExternalNluLink(body, options) {
            return ExternalNluApiFp(configuration).testExternalNluLink(body, options)(axios, basePath);
        },
    };
};
/**
 * ExternalNluApi - object-oriented interface
 * @export
 * @class ExternalNluApi
 * @extends {BaseAPI}
 */
export class ExternalNluApi extends BaseAPI {
    /**
     * Checks that the external NLU endpoint is available.
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalNluApi
     */
    testExternalNluLink(body, options) {
        return ExternalNluApiFp(this.configuration).testExternalNluLink(body, options)(this.axios, this.basePath);
    }
}
/**
 * FAQApi - axios parameter creator
 * @export
 */
export const FAQApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create a single FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {FaqQuestionData} faqQuestionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion(accountId, projectId, faqQuestionData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createFaqQuestion.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createFaqQuestion.');
            }
            // verify required parameter 'faqQuestionData' is not null or undefined
            if (faqQuestionData === null || faqQuestionData === undefined) {
                throw new RequiredError('faqQuestionData', 'Required parameter faqQuestionData was null or undefined when calling createFaqQuestion.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof faqQuestionData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(faqQuestionData !== undefined ? faqQuestionData : {})
                : faqQuestionData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export intents with id\'s from request from project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFAQ(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling exportFAQ.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling exportFAQ.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling exportFAQ.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/export-file`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Method to get all FAQ templates for project by it\'s language
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [language] User language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQTemplates(accountId, projectId, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getFAQTemplates.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getFAQTemplates.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/templates`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} intentPath Intent path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionByIntent(accountId, projectId, intentPath, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getFaqQuestionByIntent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getFaqQuestionByIntent.');
            }
            // verify required parameter 'intentPath' is not null or undefined
            if (intentPath === null || intentPath === undefined) {
                throw new RequiredError('intentPath', 'Required parameter intentPath was null or undefined when calling getFaqQuestionByIntent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (intentPath !== undefined) {
                localVarQueryParameter['intentPath'] = intentPath;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project FAQ questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [faqName] Faq name (intent prefix)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionList(accountId, projectId, faqName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getFaqQuestionList.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getFaqQuestionList.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions/list`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (faqName !== undefined) {
                localVarQueryParameter['faqName'] = faqName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importFAQFromFile.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importFAQFromFile.');
            }
            // verify required parameter 'ignoreNonCriticalErrors' is not null or undefined
            if (ignoreNonCriticalErrors === null ||
                ignoreNonCriticalErrors === undefined) {
                throw new RequiredError('ignoreNonCriticalErrors', 'Required parameter ignoreNonCriticalErrors was null or undefined when calling importFAQFromFile.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-file`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (ignoreNonCriticalErrors !== undefined) {
                localVarQueryParameter['ignoreNonCriticalErrors'] =
                    ignoreNonCriticalErrors;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {string} faqName FAQ name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importFAQFromFileToExistingFAQ.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importFAQFromFileToExistingFAQ.');
            }
            // verify required parameter 'ignoreNonCriticalErrors' is not null or undefined
            if (ignoreNonCriticalErrors === null ||
                ignoreNonCriticalErrors === undefined) {
                throw new RequiredError('ignoreNonCriticalErrors', 'Required parameter ignoreNonCriticalErrors was null or undefined when calling importFAQFromFileToExistingFAQ.');
            }
            // verify required parameter 'faqName' is not null or undefined
            if (faqName === null || faqName === undefined) {
                throw new RequiredError('faqName', 'Required parameter faqName was null or undefined when calling importFAQFromFileToExistingFAQ.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-file-to-existing-faq`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (ignoreNonCriticalErrors !== undefined) {
                localVarQueryParameter['ignoreNonCriticalErrors'] =
                    ignoreNonCriticalErrors;
            }
            if (faqName !== undefined) {
                localVarQueryParameter['faqName'] = faqName;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * import FAQ from prepared template
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} faqTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromTemplate(accountId, projectId, faqTemplateId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importFAQFromTemplate.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importFAQFromTemplate.');
            }
            // verify required parameter 'faqTemplateId' is not null or undefined
            if (faqTemplateId === null || faqTemplateId === undefined) {
                throw new RequiredError('faqTemplateId', 'Required parameter faqTemplateId was null or undefined when calling importFAQFromTemplate.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-from-template`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (faqTemplateId !== undefined) {
                localVarQueryParameter['faqTemplateId'] = faqTemplateId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update FAQ question and all child questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} faqQuestionId FAQ Question identifier.
         * @param {FaqQuestionData} faqQuestionData
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateFaqQuestion.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateFaqQuestion.');
            }
            // verify required parameter 'faqQuestionId' is not null or undefined
            if (faqQuestionId === null || faqQuestionId === undefined) {
                throw new RequiredError('faqQuestionId', 'Required parameter faqQuestionId was null or undefined when calling updateFaqQuestion.');
            }
            // verify required parameter 'faqQuestionData' is not null or undefined
            if (faqQuestionData === null || faqQuestionData === undefined) {
                throw new RequiredError('faqQuestionData', 'Required parameter faqQuestionData was null or undefined when calling updateFaqQuestion.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions/{faqQuestionId}/recursive-update`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'faqQuestionId'}}`, encodeURIComponent(String(faqQuestionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (softValidate !== undefined) {
                localVarQueryParameter['softValidate'] = softValidate;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof faqQuestionData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(faqQuestionData !== undefined ? faqQuestionData : {})
                : faqQuestionData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FAQApi - functional programming interface
 * @export
 */
export const FAQApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create a single FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {FaqQuestionData} faqQuestionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion(accountId, projectId, faqQuestionData, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).createFaqQuestion(accountId, projectId, faqQuestionData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Export intents with id\'s from request from project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFAQ(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).exportFAQ(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Method to get all FAQ templates for project by it\'s language
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [language] User language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQTemplates(accountId, projectId, language, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFAQTemplates(accountId, projectId, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} intentPath Intent path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionByIntent(accountId, projectId, intentPath, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFaqQuestionByIntent(accountId, projectId, intentPath, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project FAQ questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [faqName] Faq name (intent prefix)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionList(accountId, projectId, faqName, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFaqQuestionList(accountId, projectId, faqName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {string} faqName FAQ name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * import FAQ from prepared template
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} faqTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromTemplate(accountId, projectId, faqTemplateId, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromTemplate(accountId, projectId, faqTemplateId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update FAQ question and all child questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} faqQuestionId FAQ Question identifier.
         * @param {FaqQuestionData} faqQuestionData
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options) {
            const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FAQApi - factory interface
 * @export
 */
export const FAQApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create a single FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {FaqQuestionData} faqQuestionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion(accountId, projectId, faqQuestionData, options) {
            return FAQApiFp(configuration).createFaqQuestion(accountId, projectId, faqQuestionData, options)(axios, basePath);
        },
        /**
         * Export intents with id\'s from request from project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFAQ(accountId, projectId, requestBody, options) {
            return FAQApiFp(configuration).exportFAQ(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         * Method to get all FAQ templates for project by it\'s language
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [language] User language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQTemplates(accountId, projectId, language, options) {
            return FAQApiFp(configuration).getFAQTemplates(accountId, projectId, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Get FAQ question
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} intentPath Intent path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionByIntent(accountId, projectId, intentPath, options) {
            return FAQApiFp(configuration).getFaqQuestionByIntent(accountId, projectId, intentPath, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project FAQ questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [faqName] Faq name (intent prefix)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestionList(accountId, projectId, faqName, options) {
            return FAQApiFp(configuration).getFaqQuestionList(accountId, projectId, faqName, options)(axios, basePath);
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options) {
            return FAQApiFp(configuration).importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options)(axios, basePath);
        },
        /**
         * import FAQ from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
         * @param {string} faqName FAQ name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options) {
            return FAQApiFp(configuration).importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options)(axios, basePath);
        },
        /**
         * import FAQ from prepared template
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} faqTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFAQFromTemplate(accountId, projectId, faqTemplateId, options) {
            return FAQApiFp(configuration).importFAQFromTemplate(accountId, projectId, faqTemplateId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update FAQ question and all child questions
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} faqQuestionId FAQ Question identifier.
         * @param {FaqQuestionData} faqQuestionData
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options) {
            return FAQApiFp(configuration).updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options)(axios, basePath);
        },
    };
};
/**
 * FAQApi - object-oriented interface
 * @export
 * @class FAQApi
 * @extends {BaseAPI}
 */
export class FAQApi extends BaseAPI {
    /**
     *
     * @summary Create a single FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {FaqQuestionData} faqQuestionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    createFaqQuestion(accountId, projectId, faqQuestionData, options) {
        return FAQApiFp(this.configuration).createFaqQuestion(accountId, projectId, faqQuestionData, options)(this.axios, this.basePath);
    }
    /**
     * Export intents with id\'s from request from project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    exportFAQ(accountId, projectId, requestBody, options) {
        return FAQApiFp(this.configuration).exportFAQ(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     * Method to get all FAQ templates for project by it\'s language
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [language] User language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    getFAQTemplates(accountId, projectId, language, options) {
        return FAQApiFp(this.configuration).getFAQTemplates(accountId, projectId, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} intentPath Intent path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    getFaqQuestionByIntent(accountId, projectId, intentPath, options) {
        return FAQApiFp(this.configuration).getFaqQuestionByIntent(accountId, projectId, intentPath, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project FAQ questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [faqName] Faq name (intent prefix)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    getFaqQuestionList(accountId, projectId, faqName, options) {
        return FAQApiFp(this.configuration).getFaqQuestionList(accountId, projectId, faqName, options)(this.axios, this.basePath);
    }
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options) {
        return FAQApiFp(this.configuration).importFAQFromFile(accountId, projectId, ignoreNonCriticalErrors, file, options)(this.axios, this.basePath);
    }
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {string} faqName FAQ name
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options) {
        return FAQApiFp(this.configuration).importFAQFromFileToExistingFAQ(accountId, projectId, ignoreNonCriticalErrors, faqName, file, options)(this.axios, this.basePath);
    }
    /**
     * import FAQ from prepared template
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} faqTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    importFAQFromTemplate(accountId, projectId, faqTemplateId, options) {
        return FAQApiFp(this.configuration).importFAQFromTemplate(accountId, projectId, faqTemplateId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update FAQ question and all child questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} faqQuestionId FAQ Question identifier.
     * @param {FaqQuestionData} faqQuestionData
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options) {
        return FAQApiFp(this.configuration).updateFaqQuestion(accountId, projectId, faqQuestionId, faqQuestionData, softValidate, options)(this.axios, this.basePath);
    }
}
/**
 * InferenceApi - axios parameter creator
 * @export
 */
export const InferenceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling analyze.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling analyze.');
            }
            // verify required parameter 'analyzeRequestData' is not null or undefined
            if (analyzeRequestData === null || analyzeRequestData === undefined) {
                throw new RequiredError('analyzeRequestData', 'Required parameter analyzeRequestData was null or undefined when calling analyze.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/analyze`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyzeRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyzeRequestData !== undefined ? analyzeRequestData : {})
                : analyzeRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVocabulary(accountId, projectId, checkVocabularyRequestData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling checkVocabulary.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling checkVocabulary.');
            }
            // verify required parameter 'checkVocabularyRequestData' is not null or undefined
            if (checkVocabularyRequestData === null ||
                checkVocabularyRequestData === undefined) {
                throw new RequiredError('checkVocabularyRequestData', 'Required parameter checkVocabularyRequestData was null or undefined when calling checkVocabulary.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/check-vocabulary`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof checkVocabularyRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(checkVocabularyRequestData !== undefined
                    ? checkVocabularyRequestData
                    : {})
                : checkVocabularyRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to conform with number.
         * @param {number} number Number to conform with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conform(accountId, projectId, text, number, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling conform.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling conform.');
            }
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text', 'Required parameter text was null or undefined when calling conform.');
            }
            // verify required parameter 'number' is not null or undefined
            if (number === null || number === undefined) {
                throw new RequiredError('number', 'Required parameter number was null or undefined when calling conform.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/conform`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }
            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detects language for each element of incoming collection
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DetectLanguageRequestData} detectLanguageRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectLanguage(accountId, projectId, detectLanguageRequestData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling detectLanguage.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling detectLanguage.');
            }
            // verify required parameter 'detectLanguageRequestData' is not null or undefined
            if (detectLanguageRequestData === null ||
                detectLanguageRequestData === undefined) {
                throw new RequiredError('detectLanguageRequestData', 'Required parameter detectLanguageRequestData was null or undefined when calling detectLanguage.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/detectlanguage`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof detectLanguageRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(detectLanguageRequestData !== undefined
                    ? detectLanguageRequestData
                    : {})
                : detectLanguageRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Method for findind named entities in a given text.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling entitiesLookup.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling entitiesLookup.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling entitiesLookup.');
            }
            // verify required parameter 'showAll' is not null or undefined
            if (showAll === null || showAll === undefined) {
                throw new RequiredError('showAll', 'Required parameter showAll was null or undefined when calling entitiesLookup.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/entities`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (showAll !== undefined) {
                localVarQueryParameter['showAll'] = showAll;
            }
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Full inference method. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceRequestData} inferenceRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling inference.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling inference.');
            }
            // verify required parameter 'inferenceRequestData' is not null or undefined
            if (inferenceRequestData === null || inferenceRequestData === undefined) {
                throw new RequiredError('inferenceRequestData', 'Required parameter inferenceRequestData was null or undefined when calling inference.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof inferenceRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(inferenceRequestData !== undefined ? inferenceRequestData : {})
                : inferenceRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling inferenceMultiple.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling inferenceMultiple.');
            }
            // verify required parameter 'inferenceMultipleRequestData' is not null or undefined
            if (inferenceMultipleRequestData === null ||
                inferenceMultipleRequestData === undefined) {
                throw new RequiredError('inferenceMultipleRequestData', 'Required parameter inferenceMultipleRequestData was null or undefined when calling inferenceMultiple.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof inferenceMultipleRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(inferenceMultipleRequestData !== undefined
                    ? inferenceMultipleRequestData
                    : {})
                : inferenceMultipleRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to inflect.
         * @param {Array<string>} tags Inflect tags
         * @param {string} [dict] Version of pymorphy dictionary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inflect(accountId, projectId, text, tags, dict, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling inflect.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling inflect.');
            }
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text', 'Required parameter text was null or undefined when calling inflect.');
            }
            // verify required parameter 'tags' is not null or undefined
            if (tags === null || tags === undefined) {
                throw new RequiredError('tags', 'Required parameter tags was null or undefined when calling inflect.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inflect`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (dict !== undefined) {
                localVarQueryParameter['dict'] = dict;
            }
            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }
            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkup(accountId, projectId, query, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling initialMarkup.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling initialMarkup.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling initialMarkup.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/markup`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates initial markup for patterns, examples and dictionaries.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody Array of texts for processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkupInternal(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling initialMarkupInternal.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling initialMarkupInternal.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling initialMarkupInternal.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/markup-internal`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Simple inference method. Do classification and slot filling with minimum input parameters.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling simpleInference.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling simpleInference.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling simpleInference.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }
            if (zTimezone !== undefined && zTimezone !== null) {
                localVarHeaderParameter['Z-timezone'] = String(zTimezone);
            }
            if (zRefTime !== undefined && zRefTime !== null) {
                localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * InferenceApi - functional programming interface
 * @export
 */
export const InferenceApiFp = function (configuration) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVocabulary(accountId, projectId, checkVocabularyRequestData, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).checkVocabulary(accountId, projectId, checkVocabularyRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to conform with number.
         * @param {number} number Number to conform with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conform(accountId, projectId, text, number, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).conform(accountId, projectId, text, number, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detects language for each element of incoming collection
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DetectLanguageRequestData} detectLanguageRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectLanguage(accountId, projectId, detectLanguageRequestData, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).detectLanguage(accountId, projectId, detectLanguageRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Method for findind named entities in a given text.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Full inference method. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceRequestData} inferenceRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to inflect.
         * @param {Array<string>} tags Inflect tags
         * @param {string} [dict] Version of pymorphy dictionary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inflect(accountId, projectId, text, tags, dict, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inflect(accountId, projectId, text, tags, dict, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkup(accountId, projectId, query, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).initialMarkup(accountId, projectId, query, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates initial markup for patterns, examples and dictionaries.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody Array of texts for processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkupInternal(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).initialMarkupInternal(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Simple inference method. Do classification and slot filling with minimum input parameters.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options) {
            const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * InferenceApi - factory interface
 * @export
 */
export const InferenceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {AnalyzeRequestData} analyzeRequestData
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options) {
            return InferenceApiFp(configuration).analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVocabulary(accountId, projectId, checkVocabularyRequestData, options) {
            return InferenceApiFp(configuration).checkVocabulary(accountId, projectId, checkVocabularyRequestData, options)(axios, basePath);
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to conform with number.
         * @param {number} number Number to conform with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conform(accountId, projectId, text, number, options) {
            return InferenceApiFp(configuration).conform(accountId, projectId, text, number, options)(axios, basePath);
        },
        /**
         * Detects language for each element of incoming collection
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DetectLanguageRequestData} detectLanguageRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectLanguage(accountId, projectId, detectLanguageRequestData, options) {
            return InferenceApiFp(configuration).detectLanguage(accountId, projectId, detectLanguageRequestData, options)(axios, basePath);
        },
        /**
         * Method for findind named entities in a given text.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options) {
            return InferenceApiFp(configuration).entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options)(axios, basePath);
        },
        /**
         * Full inference method. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceRequestData} inferenceRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options) {
            return InferenceApiFp(configuration).inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options)(axios, basePath);
        },
        /**
         * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options) {
            return InferenceApiFp(configuration).inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options)(axios, basePath);
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} text Text to inflect.
         * @param {Array<string>} tags Inflect tags
         * @param {string} [dict] Version of pymorphy dictionary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inflect(accountId, projectId, text, tags, dict, options) {
            return InferenceApiFp(configuration).inflect(accountId, projectId, text, tags, dict, options)(axios, basePath);
        },
        /**
         * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkup(accountId, projectId, query, options) {
            return InferenceApiFp(configuration).initialMarkup(accountId, projectId, query, options)(axios, basePath);
        },
        /**
         * Creates initial markup for patterns, examples and dictionaries.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody Array of texts for processing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialMarkupInternal(accountId, projectId, requestBody, options) {
            return InferenceApiFp(configuration).initialMarkupInternal(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         * Simple inference method. Do classification and slot filling with minimum input parameters.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query Query text
         * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
         * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
         * @param {string} [clientId] Client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options) {
            return InferenceApiFp(configuration).simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options)(axios, basePath);
        },
    };
};
/**
 * InferenceApi - object-oriented interface
 * @export
 * @class InferenceApi
 * @extends {BaseAPI}
 */
export class InferenceApi extends BaseAPI {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options) {
        return InferenceApiFp(this.configuration).analyze(accountId, projectId, analyzeRequestData, zTimezone, zRefTime, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    checkVocabulary(accountId, projectId, checkVocabularyRequestData, options) {
        return InferenceApiFp(this.configuration).checkVocabulary(accountId, projectId, checkVocabularyRequestData, options)(this.axios, this.basePath);
    }
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to conform with number.
     * @param {number} number Number to conform with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    conform(accountId, projectId, text, number, options) {
        return InferenceApiFp(this.configuration).conform(accountId, projectId, text, number, options)(this.axios, this.basePath);
    }
    /**
     * Detects language for each element of incoming collection
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DetectLanguageRequestData} detectLanguageRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    detectLanguage(accountId, projectId, detectLanguageRequestData, options) {
        return InferenceApiFp(this.configuration).detectLanguage(accountId, projectId, detectLanguageRequestData, options)(this.axios, this.basePath);
    }
    /**
     * Method for findind named entities in a given text.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options) {
        return InferenceApiFp(this.configuration).entitiesLookup(accountId, projectId, query, showAll, zTimezone, zRefTime, clientId, options)(this.axios, this.basePath);
    }
    /**
     * Full inference method. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceRequestData} inferenceRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options) {
        return InferenceApiFp(this.configuration).inference(accountId, projectId, inferenceRequestData, zTimezone, zRefTime, options)(this.axios, this.basePath);
    }
    /**
     * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options) {
        return InferenceApiFp(this.configuration).inferenceMultiple(accountId, projectId, inferenceMultipleRequestData, zTimezone, zRefTime, options)(this.axios, this.basePath);
    }
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to inflect.
     * @param {Array<string>} tags Inflect tags
     * @param {string} [dict] Version of pymorphy dictionary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    inflect(accountId, projectId, text, tags, dict, options) {
        return InferenceApiFp(this.configuration).inflect(accountId, projectId, text, tags, dict, options)(this.axios, this.basePath);
    }
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    initialMarkup(accountId, projectId, query, options) {
        return InferenceApiFp(this.configuration).initialMarkup(accountId, projectId, query, options)(this.axios, this.basePath);
    }
    /**
     * Creates initial markup for patterns, examples and dictionaries.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody Array of texts for processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    initialMarkupInternal(accountId, projectId, requestBody, options) {
        return InferenceApiFp(this.configuration).initialMarkupInternal(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     * Simple inference method. Do classification and slot filling with minimum input parameters.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options) {
        return InferenceApiFp(this.configuration).simpleInference(accountId, projectId, query, zTimezone, zRefTime, clientId, options)(this.axios, this.basePath);
    }
}
/**
 * IntentsApi - axios parameter creator
 * @export
 */
export const IntentsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create a single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentData} intentData New intent
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntent(accountId, projectId, intentData, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createIntent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createIntent.');
            }
            // verify required parameter 'intentData' is not null or undefined
            if (intentData === null || intentData === undefined) {
                throw new RequiredError('intentData', 'Required parameter intentData was null or undefined when calling createIntent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof intentData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(intentData !== undefined ? intentData : {})
                : intentData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of intents ID\&#39;s.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteIntents.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/delete-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of intents paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleByPaths(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteMultipleByPaths.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteMultipleByPaths.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deleteMultipleByPaths.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/delete-multiple-by-paths`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export all project intents
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportIntents(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling exportIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling exportIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/export-file`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent(accountId, projectId, intentId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getIntent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getIntent.');
            }
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId', 'Required parameter intentId was null or undefined when calling getIntent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Filter project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [paths]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsByPaths(accountId, projectId, paths, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getIntentsByPaths.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getIntentsByPaths.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/get-by-paths`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (paths) {
                localVarQueryParameter['paths'] = paths;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Import intents from wile with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importIntents(accountId, projectId, strategy, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importIntents.');
            }
            // verify required parameter 'strategy' is not null or undefined
            if (strategy === null || strategy === undefined) {
                throw new RequiredError('strategy', 'Required parameter strategy was null or undefined when calling importIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/import`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (strategy !== undefined) {
                localVarQueryParameter['strategy'] = strategy;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntents(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntentsFullData(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listIntentsFullData.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listIntentsFullData.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents-full`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveDelete(accountId, projectId, intentId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling recursiveDelete.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling recursiveDelete.');
            }
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId', 'Required parameter intentId was null or undefined when calling recursiveDelete.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}/recursive-delete`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {IntentData} intentData Intent body
         * @param {number} [logLabelingSessionId]
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling recursiveUpdate.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling recursiveUpdate.');
            }
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId', 'Required parameter intentId was null or undefined when calling recursiveUpdate.');
            }
            // verify required parameter 'intentData' is not null or undefined
            if (intentData === null || intentData === undefined) {
                throw new RequiredError('intentData', 'Required parameter intentData was null or undefined when calling recursiveUpdate.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}/recursive-update`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            if (softValidate !== undefined) {
                localVarQueryParameter['softValidate'] = softValidate;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof intentData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(intentData !== undefined ? intentData : {})
                : intentData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update multiple intents and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling recursiveUpdateMultipleIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling recursiveUpdateMultipleIntents.');
            }
            // verify required parameter 'multipleRecursiveUpdateIntentData' is not null or undefined
            if (multipleRecursiveUpdateIntentData === null ||
                multipleRecursiveUpdateIntentData === undefined) {
                throw new RequiredError('multipleRecursiveUpdateIntentData', 'Required parameter multipleRecursiveUpdateIntentData was null or undefined when calling recursiveUpdateMultipleIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/multiple-recursive-update`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof multipleRecursiveUpdateIntentData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(multipleRecursiveUpdateIntentData !== undefined
                    ? multipleRecursiveUpdateIntentData
                    : {})
                : multipleRecursiveUpdateIntentData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents(accountId, projectId, intentsSearchCriteria, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling searchIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling searchIntents.');
            }
            // verify required parameter 'intentsSearchCriteria' is not null or undefined
            if (intentsSearchCriteria === null ||
                intentsSearchCriteria === undefined) {
                throw new RequiredError('intentsSearchCriteria', 'Required parameter intentsSearchCriteria was null or undefined when calling searchIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/search`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof intentsSearchCriteria !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(intentsSearchCriteria !== undefined ? intentsSearchCriteria : {})
                : intentsSearchCriteria || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * IntentsApi - functional programming interface
 * @export
 */
export const IntentsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create a single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentData} intentData New intent
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntent(accountId, projectId, intentData, logLabelingSessionId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).createIntent(accountId, projectId, intentData, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of intents ID\&#39;s.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of intents paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleByPaths(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).deleteMultipleByPaths(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Export all project intents
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportIntents(accountId, projectId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).exportIntents(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent(accountId, projectId, intentId, logLabelingSessionId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).getIntent(accountId, projectId, intentId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Filter project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [paths]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsByPaths(accountId, projectId, paths, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).getIntentsByPaths(accountId, projectId, paths, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Import intents from wile with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importIntents(accountId, projectId, strategy, file, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).importIntents(accountId, projectId, strategy, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntents(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).listIntents(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntentsFullData(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).listIntentsFullData(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveDelete(accountId, projectId, intentId, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveDelete(accountId, projectId, intentId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {IntentData} intentData Intent body
         * @param {number} [logLabelingSessionId]
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update multiple intents and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents(accountId, projectId, intentsSearchCriteria, options) {
            const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).searchIntents(accountId, projectId, intentsSearchCriteria, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * IntentsApi - factory interface
 * @export
 */
export const IntentsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create a single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentData} intentData New intent
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntent(accountId, projectId, intentData, logLabelingSessionId, options) {
            return IntentsApiFp(configuration).createIntent(accountId, projectId, intentData, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<number>} requestBody List of intents ID\&#39;s.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options) {
            return IntentsApiFp(configuration).deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete a list of intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody List of intents paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleByPaths(accountId, projectId, requestBody, options) {
            return IntentsApiFp(configuration).deleteMultipleByPaths(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Export all project intents
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportIntents(accountId, projectId, options) {
            return IntentsApiFp(configuration).exportIntents(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get single intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent(accountId, projectId, intentId, logLabelingSessionId, options) {
            return IntentsApiFp(configuration).getIntent(accountId, projectId, intentId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @summary Filter project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} [paths]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsByPaths(accountId, projectId, paths, options) {
            return IntentsApiFp(configuration).getIntentsByPaths(accountId, projectId, paths, options)(axios, basePath);
        },
        /**
         *
         * @summary Import intents from wile with specified strategy.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ImportFileStrategy} strategy
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importIntents(accountId, projectId, strategy, file, options) {
            return IntentsApiFp(configuration).importIntents(accountId, projectId, strategy, file, options)(axios, basePath);
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntents(accountId, projectId, logLabelingSessionId, options) {
            return IntentsApiFp(configuration).listIntents(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @summary List project intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntentsFullData(accountId, projectId, logLabelingSessionId, options) {
            return IntentsApiFp(configuration).listIntentsFullData(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveDelete(accountId, projectId, intentId, options) {
            return IntentsApiFp(configuration).recursiveDelete(accountId, projectId, intentId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update intent and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} intentId Intent identifier.
         * @param {IntentData} intentData Intent body
         * @param {number} [logLabelingSessionId]
         * @param {boolean} [softValidate] True to enable soft-validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options) {
            return IntentsApiFp(configuration).recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update multiple intents and all its children intents.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options) {
            return IntentsApiFp(configuration).recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options)(axios, basePath);
        },
        /**
         *
         * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents(accountId, projectId, intentsSearchCriteria, options) {
            return IntentsApiFp(configuration).searchIntents(accountId, projectId, intentsSearchCriteria, options)(axios, basePath);
        },
    };
};
/**
 * IntentsApi - object-oriented interface
 * @export
 * @class IntentsApi
 * @extends {BaseAPI}
 */
export class IntentsApi extends BaseAPI {
    /**
     *
     * @summary Create a single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentData} intentData New intent
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    createIntent(accountId, projectId, intentData, logLabelingSessionId, options) {
        return IntentsApiFp(this.configuration).createIntent(accountId, projectId, intentData, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of intents ID\&#39;s.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options) {
        return IntentsApiFp(this.configuration).deleteIntents(accountId, projectId, requestBody, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of intents paths.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    deleteMultipleByPaths(accountId, projectId, requestBody, options) {
        return IntentsApiFp(this.configuration).deleteMultipleByPaths(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Export all project intents
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    exportIntents(accountId, projectId, options) {
        return IntentsApiFp(this.configuration).exportIntents(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    getIntent(accountId, projectId, intentId, logLabelingSessionId, options) {
        return IntentsApiFp(this.configuration).getIntent(accountId, projectId, intentId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Filter project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [paths]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    getIntentsByPaths(accountId, projectId, paths, options) {
        return IntentsApiFp(this.configuration).getIntentsByPaths(accountId, projectId, paths, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Import intents from wile with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    importIntents(accountId, projectId, strategy, file, options) {
        return IntentsApiFp(this.configuration).importIntents(accountId, projectId, strategy, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    listIntents(accountId, projectId, logLabelingSessionId, options) {
        return IntentsApiFp(this.configuration).listIntents(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    listIntentsFullData(accountId, projectId, logLabelingSessionId, options) {
        return IntentsApiFp(this.configuration).listIntentsFullData(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    recursiveDelete(accountId, projectId, intentId, options) {
        return IntentsApiFp(this.configuration).recursiveDelete(accountId, projectId, intentId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {IntentData} intentData Intent body
     * @param {number} [logLabelingSessionId]
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options) {
        return IntentsApiFp(this.configuration).recursiveUpdate(accountId, projectId, intentId, intentData, logLabelingSessionId, softValidate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update multiple intents and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options) {
        return IntentsApiFp(this.configuration).recursiveUpdateMultipleIntents(accountId, projectId, multipleRecursiveUpdateIntentData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    searchIntents(accountId, projectId, intentsSearchCriteria, options) {
        return IntentsApiFp(this.configuration).searchIntents(accountId, projectId, intentsSearchCriteria, options)(this.axios, this.basePath);
    }
}
/**
 * LogLabelingApi - axios parameter creator
 * @export
 */
export const LogLabelingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Apply staged changes for log-labeling session
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyStagedChanges(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling applyStagedChanges.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling applyStagedChanges.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling applyStagedChanges.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/apply-staged-changes`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling cleanPhrases.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling cleanPhrases.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling cleanPhrases.');
            }
            // verify required parameter 'phrasesCleaningRequestData' is not null or undefined
            if (phrasesCleaningRequestData === null ||
                phrasesCleaningRequestData === undefined) {
                throw new RequiredError('phrasesCleaningRequestData', 'Required parameter phrasesCleaningRequestData was null or undefined when calling cleanPhrases.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/clean-phrases`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof phrasesCleaningRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(phrasesCleaningRequestData !== undefined
                    ? phrasesCleaningRequestData
                    : {})
                : phrasesCleaningRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a log labeling session from file with phrases.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSession(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createLogLabelingSession.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createLogLabelingSession.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a log labeling session from chat history.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSessionFromHistory(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createLogLabelingSessionFromHistory.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createLogLabelingSessionFromHistory.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/from-history`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteGroupingData.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteGroupingData.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling deleteGroupingData.');
            }
            // verify required parameter 'logLabelingGroupingId' is not null or undefined
            if (logLabelingGroupingId === null ||
                logLabelingGroupingId === undefined) {
                throw new RequiredError('logLabelingGroupingId', 'Required parameter logLabelingGroupingId was null or undefined when calling deleteGroupingData.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group/{logLabelingGroupingId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)))
                .replace(`{${'logLabelingGroupingId'}}`, encodeURIComponent(String(logLabelingGroupingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteLogLabelingSession.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteLogLabelingSession.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling deleteLogLabelingSession.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete specified phrases (mark them as deleted).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deletePhrases.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deletePhrases.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling deletePhrases.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling deletePhrases.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/delete`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteStagingIntentPhrases.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteStagingIntentPhrases.');
            }
            // verify required parameter 'deleteStagingIntentPhrasesRequest' is not null or undefined
            if (deleteStagingIntentPhrasesRequest === null ||
                deleteStagingIntentPhrasesRequest === undefined) {
                throw new RequiredError('deleteStagingIntentPhrasesRequest', 'Required parameter deleteStagingIntentPhrasesRequest was null or undefined when calling deleteStagingIntentPhrases.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intent/phrase`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof deleteStagingIntentPhrasesRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(deleteStagingIntentPhrasesRequest !== undefined
                    ? deleteStagingIntentPhrasesRequest
                    : {})
                : deleteStagingIntentPhrasesRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Discard changes to phrases (unmove them from intent and mark as pending).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling discardChanges.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling discardChanges.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling discardChanges.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling discardChanges.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/discard-changes`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start grouping
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {GroupingRequestData} groupingRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling executeGrouping.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling executeGrouping.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling executeGrouping.');
            }
            // verify required parameter 'groupingRequestData' is not null or undefined
            if (groupingRequestData === null || groupingRequestData === undefined) {
                throw new RequiredError('groupingRequestData', 'Required parameter groupingRequestData was null or undefined when calling executeGrouping.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof groupingRequestData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(groupingRequestData !== undefined ? groupingRequestData : {})
                : groupingRequestData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getGroupingData.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getGroupingData.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling getGroupingData.');
            }
            // verify required parameter 'logLabelingGroupingId' is not null or undefined
            if (logLabelingGroupingId === null ||
                logLabelingGroupingId === undefined) {
                throw new RequiredError('logLabelingGroupingId', 'Required parameter logLabelingGroupingId was null or undefined when calling getGroupingData.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group/{logLabelingGroupingId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)))
                .replace(`{${'logLabelingGroupingId'}}`, encodeURIComponent(String(logLabelingGroupingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single log labeling session by id.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelingSession(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getLogLabelingSession.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getLogLabelingSession.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling getLogLabelingSession.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all phrases in this log-labeling session.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrases(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getPhrases.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getPhrases.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling getPhrases.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get remove/accepted statuses of phrases
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrasesStatus(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getPhrasesStatus.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getPhrasesStatus.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling getPhrasesStatus.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/status`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStagingIntents(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getStagingIntents.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getStagingIntents.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intents`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get group list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGrouping(accountId, projectId, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listGrouping.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listGrouping.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling listGrouping.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all log labeling sessions.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogLabelingSession(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listLogLabelingSession.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listLogLabelingSession.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling moveStagingIntentPhrases.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling moveStagingIntentPhrases.');
            }
            // verify required parameter 'moveStagingIntentPhrasesRequest' is not null or undefined
            if (moveStagingIntentPhrasesRequest === null ||
                moveStagingIntentPhrasesRequest === undefined) {
                throw new RequiredError('moveStagingIntentPhrasesRequest', 'Required parameter moveStagingIntentPhrasesRequest was null or undefined when calling moveStagingIntentPhrases.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intent/phrase/move`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof moveStagingIntentPhrasesRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(moveStagingIntentPhrasesRequest !== undefined
                    ? moveStagingIntentPhrasesRequest
                    : {})
                : moveStagingIntentPhrasesRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move specified phrases into intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentData} moveToIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling moveToIntent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling moveToIntent.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling moveToIntent.');
            }
            // verify required parameter 'moveToIntentData' is not null or undefined
            if (moveToIntentData === null || moveToIntentData === undefined) {
                throw new RequiredError('moveToIntentData', 'Required parameter moveToIntentData was null or undefined when calling moveToIntent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/moveToIntent`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof moveToIntentData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(moveToIntentData !== undefined ? moveToIntentData : {})
                : moveToIntentData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move results of grouping to intents (works only for classification-based grouping).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentMultipleData} moveToIntentMultipleData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling moveToIntentMultiple.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling moveToIntentMultiple.');
            }
            // verify required parameter 'logLabelingSessionId' is not null or undefined
            if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
                throw new RequiredError('logLabelingSessionId', 'Required parameter logLabelingSessionId was null or undefined when calling moveToIntentMultiple.');
            }
            // verify required parameter 'moveToIntentMultipleData' is not null or undefined
            if (moveToIntentMultipleData === null ||
                moveToIntentMultipleData === undefined) {
                throw new RequiredError('moveToIntentMultipleData', 'Required parameter moveToIntentMultipleData was null or undefined when calling moveToIntentMultiple.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/moveToIntent-multiple`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof moveToIntentMultipleData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(moveToIntentMultipleData !== undefined
                    ? moveToIntentMultipleData
                    : {})
                : moveToIntentMultipleData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LogLabelingApi - functional programming interface
 * @export
 */
export const LogLabelingApiFp = function (configuration) {
    return {
        /**
         * Apply staged changes for log-labeling session
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyStagedChanges(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).applyStagedChanges(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a log labeling session from file with phrases.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSession(accountId, projectId, file, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).createLogLabelingSession(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a log labeling session from chat history.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSessionFromHistory(accountId, projectId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).createLogLabelingSessionFromHistory(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete specified phrases (mark them as deleted).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Discard changes to phrases (unmove them from intent and mark as pending).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Start grouping
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {GroupingRequestData} groupingRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a single log labeling session by id.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getLogLabelingSession(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all phrases in this log-labeling session.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrases(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getPhrases(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get remove/accepted statuses of phrases
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrasesStatus(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getPhrasesStatus(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStagingIntents(accountId, projectId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getStagingIntents(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get group list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGrouping(accountId, projectId, logLabelingSessionId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).listGrouping(accountId, projectId, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a list of all log labeling sessions.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogLabelingSession(accountId, projectId, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).listLogLabelingSession(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Move specified phrases into intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentData} moveToIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Move results of grouping to intents (works only for classification-based grouping).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentMultipleData} moveToIntentMultipleData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options) {
            const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LogLabelingApi - factory interface
 * @export
 */
export const LogLabelingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Apply staged changes for log-labeling session
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyStagedChanges(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).applyStagedChanges(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options) {
            return LogLabelingApiFp(configuration).cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options)(axios, basePath);
        },
        /**
         * Create a log labeling session from file with phrases.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSession(accountId, projectId, file, options) {
            return LogLabelingApiFp(configuration).createLogLabelingSession(accountId, projectId, file, options)(axios, basePath);
        },
        /**
         * Create a log labeling session from chat history.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogLabelingSessionFromHistory(accountId, projectId, options) {
            return LogLabelingApiFp(configuration).createLogLabelingSessionFromHistory(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
            return LogLabelingApiFp(configuration).deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         * Delete specified phrases (mark them as deleted).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options) {
            return LogLabelingApiFp(configuration).deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options) {
            return LogLabelingApiFp(configuration).deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options)(axios, basePath);
        },
        /**
         * Discard changes to phrases (unmove them from intent and mark as pending).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options) {
            return LogLabelingApiFp(configuration).discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options)(axios, basePath);
        },
        /**
         * Start grouping
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {GroupingRequestData} groupingRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options) {
            return LogLabelingApiFp(configuration).executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {number} logLabelingGroupingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
            return LogLabelingApiFp(configuration).getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options)(axios, basePath);
        },
        /**
         * Get a single log labeling session by id.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).getLogLabelingSession(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         * Get all phrases in this log-labeling session.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrases(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).getPhrases(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         * Get remove/accepted statuses of phrases
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhrasesStatus(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).getPhrasesStatus(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStagingIntents(accountId, projectId, options) {
            return LogLabelingApiFp(configuration).getStagingIntents(accountId, projectId, options)(axios, basePath);
        },
        /**
         * Get group list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGrouping(accountId, projectId, logLabelingSessionId, options) {
            return LogLabelingApiFp(configuration).listGrouping(accountId, projectId, logLabelingSessionId, options)(axios, basePath);
        },
        /**
         * Get a list of all log labeling sessions.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogLabelingSession(accountId, projectId, options) {
            return LogLabelingApiFp(configuration).listLogLabelingSession(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options) {
            return LogLabelingApiFp(configuration).moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options)(axios, basePath);
        },
        /**
         * Move specified phrases into intent.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentData} moveToIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options) {
            return LogLabelingApiFp(configuration).moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options)(axios, basePath);
        },
        /**
         * Move results of grouping to intents (works only for classification-based grouping).
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} logLabelingSessionId
         * @param {MoveToIntentMultipleData} moveToIntentMultipleData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options) {
            return LogLabelingApiFp(configuration).moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options)(axios, basePath);
        },
    };
};
/**
 * LogLabelingApi - object-oriented interface
 * @export
 * @class LogLabelingApi
 * @extends {BaseAPI}
 */
export class LogLabelingApi extends BaseAPI {
    /**
     * Apply staged changes for log-labeling session
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    applyStagedChanges(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).applyStagedChanges(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options) {
        return LogLabelingApiFp(this.configuration).cleanPhrases(accountId, projectId, logLabelingSessionId, phrasesCleaningRequestData, options)(this.axios, this.basePath);
    }
    /**
     * Create a log labeling session from file with phrases.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    createLogLabelingSession(accountId, projectId, file, options) {
        return LogLabelingApiFp(this.configuration).createLogLabelingSession(accountId, projectId, file, options)(this.axios, this.basePath);
    }
    /**
     * Create a log labeling session from chat history.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    createLogLabelingSessionFromHistory(accountId, projectId, options) {
        return LogLabelingApiFp(this.configuration).createLogLabelingSessionFromHistory(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
        return LogLabelingApiFp(this.configuration).deleteGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).deleteLogLabelingSession(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     * Delete specified phrases (mark them as deleted).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options) {
        return LogLabelingApiFp(this.configuration).deletePhrases(accountId, projectId, logLabelingSessionId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options) {
        return LogLabelingApiFp(this.configuration).deleteStagingIntentPhrases(accountId, projectId, deleteStagingIntentPhrasesRequest, options)(this.axios, this.basePath);
    }
    /**
     * Discard changes to phrases (unmove them from intent and mark as pending).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options) {
        return LogLabelingApiFp(this.configuration).discardChanges(accountId, projectId, logLabelingSessionId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     * Start grouping
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {GroupingRequestData} groupingRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options) {
        return LogLabelingApiFp(this.configuration).executeGrouping(accountId, projectId, logLabelingSessionId, groupingRequestData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options) {
        return LogLabelingApiFp(this.configuration).getGroupingData(accountId, projectId, logLabelingSessionId, logLabelingGroupingId, options)(this.axios, this.basePath);
    }
    /**
     * Get a single log labeling session by id.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    getLogLabelingSession(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).getLogLabelingSession(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     * Get all phrases in this log-labeling session.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    getPhrases(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).getPhrases(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     * Get remove/accepted statuses of phrases
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    getPhrasesStatus(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).getPhrasesStatus(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    getStagingIntents(accountId, projectId, options) {
        return LogLabelingApiFp(this.configuration).getStagingIntents(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     * Get group list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    listGrouping(accountId, projectId, logLabelingSessionId, options) {
        return LogLabelingApiFp(this.configuration).listGrouping(accountId, projectId, logLabelingSessionId, options)(this.axios, this.basePath);
    }
    /**
     * Get a list of all log labeling sessions.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    listLogLabelingSession(accountId, projectId, options) {
        return LogLabelingApiFp(this.configuration).listLogLabelingSession(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options) {
        return LogLabelingApiFp(this.configuration).moveStagingIntentPhrases(accountId, projectId, moveStagingIntentPhrasesRequest, options)(this.axios, this.basePath);
    }
    /**
     * Move specified phrases into intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentData} moveToIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options) {
        return LogLabelingApiFp(this.configuration).moveToIntent(accountId, projectId, logLabelingSessionId, moveToIntentData, options)(this.axios, this.basePath);
    }
    /**
     * Move results of grouping to intents (works only for classification-based grouping).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentMultipleData} moveToIntentMultipleData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogLabelingApi
     */
    moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options) {
        return LogLabelingApiFp(this.configuration).moveToIntentMultiple(accountId, projectId, logLabelingSessionId, moveToIntentMultipleData, options)(this.axios, this.basePath);
    }
}
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessKey(accountId, projectId, directAccessKeyData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createAccessKey.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createAccessKey.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/createAccessKey`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof directAccessKeyData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(directAccessKeyData !== undefined ? directAccessKeyData : {})
                : directAccessKeyData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createProject.');
            }
            // verify required parameter 'createProjectData' is not null or undefined
            if (createProjectData === null || createProjectData === undefined) {
                throw new RequiredError('createProjectData', 'Required parameter createProjectData was null or undefined when calling createProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createProjectData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createProjectData !== undefined ? createProjectData : {})
                : createProjectData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deactivates shared project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSharedProject(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deactivateSharedProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deactivateSharedProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/deactivate-shared-project`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteAccessKey.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteAccessKey.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/deleteAccessKey`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete project
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, accountId, options = {}) {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteProject.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Evicts project resources (models are removed from cache and external services)
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictProjectResources(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling evictProjectResources.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling evictProjectResources.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/evict-project-resources`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling exportProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling exportProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/export`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientEntitiesIncluded !== undefined) {
                localVarQueryParameter['clientEntitiesIncluded'] =
                    clientEntitiesIncluded;
            }
            if (spellerDictionaryIncluded !== undefined) {
                localVarQueryParameter['spellerDictionaryIncluded'] =
                    spellerDictionaryIncluded;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessKey(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAccessKey.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getAccessKey.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/getAccessKey`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get amount of entries in all client entities of the project where clientId != null
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientEntitiesTotalSize(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getClientEntitiesTotalSize.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getClientEntitiesTotalSize.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/stats/count-client-entities-entries`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project last change (change in intents/entities/project settings) timestamp.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectLastChangeDate(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getProjectLastChangeDate.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getProjectLastChangeDate.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/status/last-change`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get amount of entries in speller dictionary
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionarySize(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getSpellerDictionarySize.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getSpellerDictionarySize.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/stats/count-speller-dictionary-entries`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Import caila project from json
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importCaila.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importCaila.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/importFile`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (clientEntitiesIncluded !== undefined) {
                localVarQueryParameter['clientEntitiesIncluded'] =
                    clientEntitiesIncluded;
            }
            if (spellerDictionaryIncluded !== undefined) {
                localVarQueryParameter['spellerDictionaryIncluded'] =
                    spellerDictionaryIncluded;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Import all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectExportImportData} projectExportImportData
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling importProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling importProject.');
            }
            // verify required parameter 'projectExportImportData' is not null or undefined
            if (projectExportImportData === null ||
                projectExportImportData === undefined) {
                throw new RequiredError('projectExportImportData', 'Required parameter projectExportImportData was null or undefined when calling importProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/import`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientEntitiesIncluded !== undefined) {
                localVarQueryParameter['clientEntitiesIncluded'] =
                    clientEntitiesIncluded;
            }
            if (spellerDictionaryIncluded !== undefined) {
                localVarQueryParameter['spellerDictionaryIncluded'] =
                    spellerDictionaryIncluded;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof projectExportImportData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(projectExportImportData !== undefined ? projectExportImportData : {})
                : projectExportImportData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listProjects.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options = {}) {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateProject.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateProject.');
            }
            // verify required parameter 'projectData' is not null or undefined
            if (projectData === null || projectData === undefined) {
                throw new RequiredError('projectData', 'Required parameter projectData was null or undefined when calling updateProject.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof projectData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(projectData !== undefined ? projectData : {})
                : projectData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessKey(accountId, projectId, directAccessKeyData, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createAccessKey(accountId, projectId, directAccessKeyData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createProject(accountId, createProjectData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deactivates shared project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSharedProject(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deactivateSharedProject(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deleteAccessKey(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete project
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, accountId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deleteProject(projectId, accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Evicts project resources (models are removed from cache and external services)
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictProjectResources(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).evictProjectResources(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Export all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessKey(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getAccessKey(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get amount of entries in all client entities of the project where clientId != null
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientEntitiesTotalSize(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getClientEntitiesTotalSize(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getProject(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project last change (change in intents/entities/project settings) timestamp.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectLastChangeDate(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getProjectLastChangeDate(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get amount of entries in speller dictionary
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionarySize(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getSpellerDictionarySize(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Import caila project from json
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Import all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectExportImportData} projectExportImportData
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, page, size, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).listProjects(accountId, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).updateProject(projectId, accountId, projectData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessKey(accountId, projectId, directAccessKeyData, options) {
            return ProjectsApiFp(configuration).createAccessKey(accountId, projectId, directAccessKeyData, options)(axios, basePath);
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options) {
            return ProjectsApiFp(configuration).createProject(accountId, createProjectData, options)(axios, basePath);
        },
        /**
         *
         * @summary deactivates shared project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSharedProject(accountId, projectId, options) {
            return ProjectsApiFp(configuration).deactivateSharedProject(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey(accountId, projectId, options) {
            return ProjectsApiFp(configuration).deleteAccessKey(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete project
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, accountId, options) {
            return ProjectsApiFp(configuration).deleteProject(projectId, accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Evicts project resources (models are removed from cache and external services)
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictProjectResources(accountId, projectId, options) {
            return ProjectsApiFp(configuration).evictProjectResources(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Export all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
            return ProjectsApiFp(configuration).exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options)(axios, basePath);
        },
        /**
         *
         * @summary Get direct access key
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessKey(accountId, projectId, options) {
            return ProjectsApiFp(configuration).getAccessKey(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get amount of entries in all client entities of the project where clientId != null
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientEntitiesTotalSize(accountId, projectId, options) {
            return ProjectsApiFp(configuration).getClientEntitiesTotalSize(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options) {
            return ProjectsApiFp(configuration).getProject(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project last change (change in intents/entities/project settings) timestamp.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectLastChangeDate(accountId, projectId, options) {
            return ProjectsApiFp(configuration).getProjectLastChangeDate(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get amount of entries in speller dictionary
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionarySize(accountId, projectId, options) {
            return ProjectsApiFp(configuration).getSpellerDictionarySize(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Import caila project from json
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options) {
            return ProjectsApiFp(configuration).importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Import all project data.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectExportImportData} projectExportImportData
         * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
         * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
            return ProjectsApiFp(configuration).importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options)(axios, basePath);
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, page, size, options) {
            return ProjectsApiFp(configuration).listProjects(accountId, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options) {
            return ProjectsApiFp(configuration).updateProject(projectId, accountId, projectData, options)(axios, basePath);
        },
    };
};
/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     *
     * @summary Create direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    createAccessKey(accountId, projectId, directAccessKeyData, options) {
        return ProjectsApiFp(this.configuration).createAccessKey(accountId, projectId, directAccessKeyData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    createProject(accountId, createProjectData, options) {
        return ProjectsApiFp(this.configuration).createProject(accountId, createProjectData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deactivates shared project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    deactivateSharedProject(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).deactivateSharedProject(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    deleteAccessKey(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).deleteAccessKey(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete project
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    deleteProject(projectId, accountId, options) {
        return ProjectsApiFp(this.configuration).deleteProject(projectId, accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Evicts project resources (models are removed from cache and external services)
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    evictProjectResources(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).evictProjectResources(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Export all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
        return ProjectsApiFp(this.configuration).exportProject(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    getAccessKey(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).getAccessKey(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get amount of entries in all client entities of the project where clientId != null
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    getClientEntitiesTotalSize(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).getClientEntitiesTotalSize(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    getProject(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).getProject(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project last change (change in intents/entities/project settings) timestamp.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    getProjectLastChangeDate(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).getProjectLastChangeDate(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get amount of entries in speller dictionary
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    getSpellerDictionarySize(accountId, projectId, options) {
        return ProjectsApiFp(this.configuration).getSpellerDictionarySize(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Import caila project from json
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options) {
        return ProjectsApiFp(this.configuration).importCaila(accountId, projectId, clientEntitiesIncluded, spellerDictionaryIncluded, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Import all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectExportImportData} projectExportImportData
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options) {
        return ProjectsApiFp(this.configuration).importProject(accountId, projectId, projectExportImportData, clientEntitiesIncluded, spellerDictionaryIncluded, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    listProjects(accountId, page, size, options) {
        return ProjectsApiFp(this.configuration).listProjects(accountId, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    updateProject(projectId, accountId, projectData, options) {
        return ProjectsApiFp(this.configuration).updateProject(projectId, accountId, projectData, options)(this.axios, this.basePath);
    }
}
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Return this instance configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceConfiguration(options = {}) {
            const localVarPath = `/api/caila/public/instance-configuration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function (configuration) {
    return {
        /**
         * Return this instance configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceConfiguration(options) {
            const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getInstanceConfiguration(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Return this instance configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceConfiguration(options) {
            return PublicApiFp(configuration).getInstanceConfiguration(options)(axios, basePath);
        },
    };
};
/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * Return this instance configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    getInstanceConfiguration(options) {
        return PublicApiFp(this.configuration).getInstanceConfiguration(options)(this.axios, this.basePath);
    }
}
/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get NLU settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getProjectSettings.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getProjectSettings.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/settings`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List of supported languages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableLanguages(options = {}) {
            const localVarPath = `/api/caila/languages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSettingsData} projectSettingsData Project settings
         * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling setProjectSettings.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling setProjectSettings.');
            }
            // verify required parameter 'projectSettingsData' is not null or undefined
            if (projectSettingsData === null || projectSettingsData === undefined) {
                throw new RequiredError('projectSettingsData', 'Required parameter projectSettingsData was null or undefined when calling setProjectSettings.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/settings`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (mergeSettings !== undefined) {
                localVarQueryParameter['mergeSettings'] = mergeSettings;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof projectSettingsData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(projectSettingsData !== undefined ? projectSettingsData : {})
                : projectSettingsData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function (configuration) {
    return {
        /**
         * Get NLU settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, options) {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).getProjectSettings(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List of supported languages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableLanguages(options) {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).listAvailableLanguages(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSettingsData} projectSettingsData Project settings
         * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options) {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get NLU settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, options) {
            return SettingsApiFp(configuration).getProjectSettings(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary List of supported languages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableLanguages(options) {
            return SettingsApiFp(configuration).listAvailableLanguages(options)(axios, basePath);
        },
        /**
         * Update project settings.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSettingsData} projectSettingsData Project settings
         * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options) {
            return SettingsApiFp(configuration).setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options)(axios, basePath);
        },
    };
};
/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Get NLU settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    getProjectSettings(accountId, projectId, options) {
        return SettingsApiFp(this.configuration).getProjectSettings(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List of supported languages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    listAvailableLanguages(options) {
        return SettingsApiFp(this.configuration).listAvailableLanguages(options)(this.axios, this.basePath);
    }
    /**
     * Update project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectSettingsData} projectSettingsData Project settings
     * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options) {
        return SettingsApiFp(this.configuration).setProjectSettings(accountId, projectId, projectSettingsData, mergeSettings, options)(this.axios, this.basePath);
    }
}
/**
 * SkillsApi - axios parameter creator
 * @export
 */
export const SkillsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create skill with content from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSkillWithContent(accountId, projectId, skillName, skillType, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createSkillWithContent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling createSkillWithContent.');
            }
            // verify required parameter 'skillName' is not null or undefined
            if (skillName === null || skillName === undefined) {
                throw new RequiredError('skillName', 'Required parameter skillName was null or undefined when calling createSkillWithContent.');
            }
            // verify required parameter 'skillType' is not null or undefined
            if (skillType === null || skillType === undefined) {
                throw new RequiredError('skillType', 'Required parameter skillType was null or undefined when calling createSkillWithContent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (skillName !== undefined) {
                localVarQueryParameter['skillName'] = skillName;
            }
            if (skillType !== undefined) {
                localVarQueryParameter['skillType'] = skillType;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteSkillRequest} deleteSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill(accountId, projectId, deleteSkillRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteSkill.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteSkill.');
            }
            // verify required parameter 'deleteSkillRequest' is not null or undefined
            if (deleteSkillRequest === null || deleteSkillRequest === undefined) {
                throw new RequiredError('deleteSkillRequest', 'Required parameter deleteSkillRequest was null or undefined when calling deleteSkill.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof deleteSkillRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(deleteSkillRequest !== undefined ? deleteSkillRequest : {})
                : deleteSkillRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillContent(accountId, projectId, skillId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getSkillContent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getSkillContent.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling getSkillContent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary listContentFiles
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContentFiles(accountId, projectId, skillId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listContentFiles.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listContentFiles.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling listContentFiles.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content/files`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return list of skills
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkills(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling listSkills.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling listSkills.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Simple skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInference(accountId, projectId, skillId, query, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling simpleSkillInference.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling simpleSkillInference.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling simpleSkillInference.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling simpleSkillInference.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/inference`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Simple skill inference using skill name in query
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInferenceByName(accountId, projectId, skillName, query, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling simpleSkillInferenceByName.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling simpleSkillInferenceByName.');
            }
            // verify required parameter 'skillName' is not null or undefined
            if (skillName === null || skillName === undefined) {
                throw new RequiredError('skillName', 'Required parameter skillName was null or undefined when calling simpleSkillInferenceByName.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling simpleSkillInferenceByName.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill/inference-by-name`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (skillName !== undefined) {
                localVarQueryParameter['skillName'] = skillName;
            }
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillInference(accountId, projectId, skillId, body, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling skillInference.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling skillInference.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling skillInference.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling skillInference.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/inference`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof body !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Skill Status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillStatus(accountId, projectId, skillId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling skillStatus.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling skillStatus.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling skillStatus.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/status`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Train skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSkill(accountId, projectId, skillId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling trainSkill.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling trainSkill.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling trainSkill.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/train`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateSkillRequest} updateSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill(accountId, projectId, updateSkillRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateSkill.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateSkill.');
            }
            // verify required parameter 'updateSkillRequest' is not null or undefined
            if (updateSkillRequest === null || updateSkillRequest === undefined) {
                throw new RequiredError('updateSkillRequest', 'Required parameter updateSkillRequest was null or undefined when calling updateSkill.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateSkillRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateSkillRequest !== undefined ? updateSkillRequest : {})
                : updateSkillRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillContent(accountId, projectId, skillId, skillType, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateSkillContent.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling updateSkillContent.');
            }
            // verify required parameter 'skillId' is not null or undefined
            if (skillId === null || skillId === undefined) {
                throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling updateSkillContent.');
            }
            // verify required parameter 'skillType' is not null or undefined
            if (skillType === null || skillType === undefined) {
                throw new RequiredError('skillType', 'Required parameter skillType was null or undefined when calling updateSkillContent.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (skillType !== undefined) {
                localVarQueryParameter['skillType'] = skillType;
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SkillsApi - functional programming interface
 * @export
 */
export const SkillsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create skill with content from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSkillWithContent(accountId, projectId, skillName, skillType, file, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).createSkillWithContent(accountId, projectId, skillName, skillType, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteSkillRequest} deleteSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill(accountId, projectId, deleteSkillRequest, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).deleteSkill(accountId, projectId, deleteSkillRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary getSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillContent(accountId, projectId, skillId, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).getSkillContent(accountId, projectId, skillId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary listContentFiles
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContentFiles(accountId, projectId, skillId, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).listContentFiles(accountId, projectId, skillId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return list of skills
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkills(accountId, projectId, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).listSkills(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Simple skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInference(accountId, projectId, skillId, query, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).simpleSkillInference(accountId, projectId, skillId, query, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Simple skill inference using skill name in query
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInferenceByName(accountId, projectId, skillName, query, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).simpleSkillInferenceByName(accountId, projectId, skillName, query, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillInference(accountId, projectId, skillId, body, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).skillInference(accountId, projectId, skillId, body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Skill Status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillStatus(accountId, projectId, skillId, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).skillStatus(accountId, projectId, skillId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Train skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSkill(accountId, projectId, skillId, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).trainSkill(accountId, projectId, skillId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateSkillRequest} updateSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill(accountId, projectId, updateSkillRequest, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).updateSkill(accountId, projectId, updateSkillRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary updateSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillContent(accountId, projectId, skillId, skillType, file, options) {
            const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).updateSkillContent(accountId, projectId, skillId, skillType, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SkillsApi - factory interface
 * @export
 */
export const SkillsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create skill with content from file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSkillWithContent(accountId, projectId, skillName, skillType, file, options) {
            return SkillsApiFp(configuration).createSkillWithContent(accountId, projectId, skillName, skillType, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteSkillRequest} deleteSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill(accountId, projectId, deleteSkillRequest, options) {
            return SkillsApiFp(configuration).deleteSkill(accountId, projectId, deleteSkillRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary getSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillContent(accountId, projectId, skillId, options) {
            return SkillsApiFp(configuration).getSkillContent(accountId, projectId, skillId, options)(axios, basePath);
        },
        /**
         *
         * @summary listContentFiles
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContentFiles(accountId, projectId, skillId, options) {
            return SkillsApiFp(configuration).listContentFiles(accountId, projectId, skillId, options)(axios, basePath);
        },
        /**
         *
         * @summary Return list of skills
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkills(accountId, projectId, options) {
            return SkillsApiFp(configuration).listSkills(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Simple skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInference(accountId, projectId, skillId, query, options) {
            return SkillsApiFp(configuration).simpleSkillInference(accountId, projectId, skillId, query, options)(axios, basePath);
        },
        /**
         *
         * @summary Simple skill inference using skill name in query
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillName skill name
         * @param {string} query Phrase for process or search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleSkillInferenceByName(accountId, projectId, skillName, query, options) {
            return SkillsApiFp(configuration).simpleSkillInferenceByName(accountId, projectId, skillName, query, options)(axios, basePath);
        },
        /**
         *
         * @summary Skill inference
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillInference(accountId, projectId, skillId, body, options) {
            return SkillsApiFp(configuration).skillInference(accountId, projectId, skillId, body, options)(axios, basePath);
        },
        /**
         *
         * @summary Get Skill Status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillStatus(accountId, projectId, skillId, options) {
            return SkillsApiFp(configuration).skillStatus(accountId, projectId, skillId, options)(axios, basePath);
        },
        /**
         *
         * @summary Train skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainSkill(accountId, projectId, skillId, options) {
            return SkillsApiFp(configuration).trainSkill(accountId, projectId, skillId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update skill
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateSkillRequest} updateSkillRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill(accountId, projectId, updateSkillRequest, options) {
            return SkillsApiFp(configuration).updateSkill(accountId, projectId, updateSkillRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary updateSkillContent
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} skillId Skill identifier
         * @param {SkillType} skillType skill type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillContent(accountId, projectId, skillId, skillType, file, options) {
            return SkillsApiFp(configuration).updateSkillContent(accountId, projectId, skillId, skillType, file, options)(axios, basePath);
        },
    };
};
/**
 * SkillsApi - object-oriented interface
 * @export
 * @class SkillsApi
 * @extends {BaseAPI}
 */
export class SkillsApi extends BaseAPI {
    /**
     *
     * @summary Create skill with content from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    createSkillWithContent(accountId, projectId, skillName, skillType, file, options) {
        return SkillsApiFp(this.configuration).createSkillWithContent(accountId, projectId, skillName, skillType, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteSkillRequest} deleteSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    deleteSkill(accountId, projectId, deleteSkillRequest, options) {
        return SkillsApiFp(this.configuration).deleteSkill(accountId, projectId, deleteSkillRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary getSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    getSkillContent(accountId, projectId, skillId, options) {
        return SkillsApiFp(this.configuration).getSkillContent(accountId, projectId, skillId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary listContentFiles
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    listContentFiles(accountId, projectId, skillId, options) {
        return SkillsApiFp(this.configuration).listContentFiles(accountId, projectId, skillId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return list of skills
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    listSkills(accountId, projectId, options) {
        return SkillsApiFp(this.configuration).listSkills(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Simple skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    simpleSkillInference(accountId, projectId, skillId, query, options) {
        return SkillsApiFp(this.configuration).simpleSkillInference(accountId, projectId, skillId, query, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Simple skill inference using skill name in query
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    simpleSkillInferenceByName(accountId, projectId, skillName, query, options) {
        return SkillsApiFp(this.configuration).simpleSkillInferenceByName(accountId, projectId, skillName, query, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    skillInference(accountId, projectId, skillId, body, options) {
        return SkillsApiFp(this.configuration).skillInference(accountId, projectId, skillId, body, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get Skill Status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    skillStatus(accountId, projectId, skillId, options) {
        return SkillsApiFp(this.configuration).skillStatus(accountId, projectId, skillId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Train skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    trainSkill(accountId, projectId, skillId, options) {
        return SkillsApiFp(this.configuration).trainSkill(accountId, projectId, skillId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateSkillRequest} updateSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    updateSkill(accountId, projectId, updateSkillRequest, options) {
        return SkillsApiFp(this.configuration).updateSkill(accountId, projectId, updateSkillRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary updateSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsApi
     */
    updateSkillContent(accountId, projectId, skillId, skillType, file, options) {
        return SkillsApiFp(this.configuration).updateSkillContent(accountId, projectId, skillId, skillType, file, options)(this.axios, this.basePath);
    }
}
/**
 * SpellerApi - axios parameter creator
 * @export
 */
export const SpellerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExclusion(accountId, projectId, spellerWordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addExclusion.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling addExclusion.');
            }
            // verify required parameter 'spellerWordData' is not null or undefined
            if (spellerWordData === null || spellerWordData === undefined) {
                throw new RequiredError('spellerWordData', 'Required parameter spellerWordData was null or undefined when calling addExclusion.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/exclusion`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof spellerWordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
                : spellerWordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWord(accountId, projectId, spellerWordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addWord.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling addWord.');
            }
            // verify required parameter 'spellerWordData' is not null or undefined
            if (spellerWordData === null || spellerWordData === undefined) {
                throw new RequiredError('spellerWordData', 'Required parameter spellerWordData was null or undefined when calling addWord.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/word`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof spellerWordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
                : spellerWordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExclusion(accountId, projectId, spellerWordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteExclusion.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteExclusion.');
            }
            // verify required parameter 'spellerWordData' is not null or undefined
            if (spellerWordData === null || spellerWordData === undefined) {
                throw new RequiredError('spellerWordData', 'Required parameter spellerWordData was null or undefined when calling deleteExclusion.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/exclusion`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof spellerWordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
                : spellerWordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpellerDictionary(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteSpellerDictionary.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteSpellerDictionary.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWord(accountId, projectId, spellerWordData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteWord.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deleteWord.');
            }
            // verify required parameter 'spellerWordData' is not null or undefined
            if (spellerWordData === null || spellerWordData === undefined) {
                throw new RequiredError('spellerWordData', 'Required parameter spellerWordData was null or undefined when calling deleteWord.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/word`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof spellerWordData !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
                : spellerWordData || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionary(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getSpellerDictionary.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getSpellerDictionary.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSpellerDictionary(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling uploadSpellerDictionary.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling uploadSpellerDictionary.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SpellerApi - functional programming interface
 * @export
 */
export const SpellerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExclusion(accountId, projectId, spellerWordData, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).addExclusion(accountId, projectId, spellerWordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWord(accountId, projectId, spellerWordData, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).addWord(accountId, projectId, spellerWordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExclusion(accountId, projectId, spellerWordData, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteExclusion(accountId, projectId, spellerWordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpellerDictionary(accountId, projectId, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteSpellerDictionary(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWord(accountId, projectId, spellerWordData, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteWord(accountId, projectId, spellerWordData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionary(accountId, projectId, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).getSpellerDictionary(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSpellerDictionary(accountId, projectId, file, options) {
            const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).uploadSpellerDictionary(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SpellerApi - factory interface
 * @export
 */
export const SpellerApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExclusion(accountId, projectId, spellerWordData, options) {
            return SpellerApiFp(configuration).addExclusion(accountId, projectId, spellerWordData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWord(accountId, projectId, spellerWordData, options) {
            return SpellerApiFp(configuration).addWord(accountId, projectId, spellerWordData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExclusion(accountId, projectId, spellerWordData, options) {
            return SpellerApiFp(configuration).deleteExclusion(accountId, projectId, spellerWordData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpellerDictionary(accountId, projectId, options) {
            return SpellerApiFp(configuration).deleteSpellerDictionary(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {SpellerWordData} spellerWordData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWord(accountId, projectId, spellerWordData, options) {
            return SpellerApiFp(configuration).deleteWord(accountId, projectId, spellerWordData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpellerDictionary(accountId, projectId, options) {
            return SpellerApiFp(configuration).getSpellerDictionary(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSpellerDictionary(accountId, projectId, file, options) {
            return SpellerApiFp(configuration).uploadSpellerDictionary(accountId, projectId, file, options)(axios, basePath);
        },
    };
};
/**
 * SpellerApi - object-oriented interface
 * @export
 * @class SpellerApi
 * @extends {BaseAPI}
 */
export class SpellerApi extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    addExclusion(accountId, projectId, spellerWordData, options) {
        return SpellerApiFp(this.configuration).addExclusion(accountId, projectId, spellerWordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    addWord(accountId, projectId, spellerWordData, options) {
        return SpellerApiFp(this.configuration).addWord(accountId, projectId, spellerWordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    deleteExclusion(accountId, projectId, spellerWordData, options) {
        return SpellerApiFp(this.configuration).deleteExclusion(accountId, projectId, spellerWordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    deleteSpellerDictionary(accountId, projectId, options) {
        return SpellerApiFp(this.configuration).deleteSpellerDictionary(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    deleteWord(accountId, projectId, spellerWordData, options) {
        return SpellerApiFp(this.configuration).deleteWord(accountId, projectId, spellerWordData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    getSpellerDictionary(accountId, projectId, options) {
        return SpellerApiFp(this.configuration).getSpellerDictionary(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpellerApi
     */
    uploadSpellerDictionary(accountId, projectId, file, options) {
        return SpellerApiFp(this.configuration).uploadSpellerDictionary(accountId, projectId, file, options)(this.axios, this.basePath);
    }
}
/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNLUStatus(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getNLUStatus.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling getNLUStatus.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/status`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNLU(accountId, projectId, incremental, logLabelingSessionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling trainNLU.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling trainNLU.');
            }
            const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/train`
                .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (incremental !== undefined) {
                localVarQueryParameter['incremental'] = incremental;
            }
            if (logLabelingSessionId !== undefined) {
                localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function (configuration) {
    return {
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNLUStatus(accountId, projectId, options) {
            const localVarAxiosArgs = TrainingApiAxiosParamCreator(configuration).getNLUStatus(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNLU(accountId, projectId, incremental, logLabelingSessionId, options) {
            const localVarAxiosArgs = TrainingApiAxiosParamCreator(configuration).trainNLU(accountId, projectId, incremental, logLabelingSessionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNLUStatus(accountId, projectId, options) {
            return TrainingApiFp(configuration).getNLUStatus(accountId, projectId, options)(axios, basePath);
        },
        /**
         * Train nlu model
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
         * @param {number} [logLabelingSessionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainNLU(accountId, projectId, incremental, logLabelingSessionId, options) {
            return TrainingApiFp(configuration).trainNLU(accountId, projectId, incremental, logLabelingSessionId, options)(axios, basePath);
        },
    };
};
/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI {
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    getNLUStatus(accountId, projectId, options) {
        return TrainingApiFp(this.configuration).getNLUStatus(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    trainNLU(accountId, projectId, incremental, logLabelingSessionId, options) {
        return TrainingApiFp(this.configuration).trainNLU(accountId, projectId, incremental, logLabelingSessionId, options)(this.axios, this.basePath);
    }
}
