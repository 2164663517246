import React, { useCallback, useMemo, useContext } from 'react';
import { Controller, useForm, UseFormOptions } from 'react-hook-form';
import { Modal, Textarea, usePromiseProcessing, Spinner, BackgroundType, ModalProps } from '@just-ai/just-ui';
import { AnalyticsContext } from '@just-ai/analytic-front';

import { t } from 'localization';
import { buildCustomValidationResolver, ReactHookFormResolver } from 'utils/validator/customValidationResolver';
import { TokenType } from 'modules/Tokens/types';

import styles from 'modules/Tokens/modals.module.scss';
import TokensService from '../../../services/TokensService';

const validationResolver = buildCustomValidationResolver<TokenType>(async values => {
  const errors: any = {};
  if (!values.value) errors['value'] = t('TokensPage:Errors:FillField');
  return errors;
});

interface ChangeTokenModalPayloadInterface extends Pick<ModalProps, 'title' | 'buttonSubmitText' | 'buttonCancelText'> {
  secretName: string;
  isOpen: boolean;
  onSubmit?: (payload: TokenType) => void;
  onCancel?: () => void;
  valueFieldLabel?: string;
  valueFieldPlaceholder?: string;
  resolver?: ReactHookFormResolver<TokenType>;
  reValidateMode?: UseFormOptions['reValidateMode'];
}
const ChangeTokenModal = ({
  secretName,
  isOpen,
  onSubmit,
  onCancel,
  valueFieldLabel,
  valueFieldPlaceholder,
  resolver,
  reValidateMode,
  ...props
}: ChangeTokenModalPayloadInterface) => {
  const { control, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: reValidateMode ?? 'onChange',
    validateCriteriaMode: 'all',
    defaultValues: {
      value: '',
    },
    validationResolver: resolver ?? validationResolver,
  });

  const { accountId, projectShortName } = useContext(AnalyticsContext);
  const secretApiService = useMemo(() => new TokensService(accountId, projectShortName), [accountId, projectShortName]);

  const [updateSecretState, updateSecretApiCall] = usePromiseProcessing((token: TokenType) =>
    secretApiService.updateSecret(token.name, { secretValue: token.value })
  );

  const onInnerSubmit = useCallback(
    async (formPayload: { value: string }) => {
      updateSecretApiCall({
        name: secretName,
        value: formPayload.value,
      }).then(() => {
        onSubmit?.({
          value: formPayload.value,
          name: secretName,
        });
      });
    },
    [onSubmit, secretName, updateSecretApiCall]
  );

  return (
    <Modal
      size='md'
      data-test-id='projectsPage.tokens.ChangeTokenModal'
      isOpen={isOpen}
      title={t('TokensPage:ChangeToken')}
      disableActionButtonAutoFocus
      buttonCancelText={t('TokensPage:Cancel')}
      buttonCancelColor='secondary'
      buttonCancelTestId='projectsPage.tokens.ChangeTokenModal.cancelButton'
      onCancelClick={onCancel}
      onActionClick={handleSubmit(onInnerSubmit)}
      buttonSubmitTestId='projectsPage.tokens.ChangeTokenModal.submitButton'
      buttonSubmitText={t('TokensPage:ChangeTokenModal:Approve')}
      buttonSubmitColor='primary'
      {...props}
    >
      {updateSecretState.loading && (
        <Spinner backgroundColor='rgba(255, 255, 255, 0.5)' backgroundType={BackgroundType.compact} size='4x' />
      )}
      <div className={styles.controlsGroup}>
        <span className={styles.ChangeTokenModal__tokenName}>{secretName}</span>
        <div className={styles.fieldForm}>
          <label>
            {valueFieldLabel ?? t('TokensPage:ChangeTokenModal:NewValueField')} <span className='color-danger'>*</span>
          </label>
          <Controller
            data-test-id='projectsPage.tokens.ChangeTokenModal.value'
            as={Textarea}
            autoFocus
            placeholder={valueFieldPlaceholder ?? t('TokensPage:ChangeTokenModal:NewValueFieldPlaceholder')}
            name='value'
            control={control}
            inputState={!!errors.value && 'error'}
            clearable
          />
          {!!errors.value && <span className={styles.fieldForm__error}>{errors.value.message}</span>}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ChangeTokenModal);
