import { RefObject } from 'react';
import { getStageFromEvent } from './stageFunctions';
import { updateUsingStageProps } from './connectorsLayer';
import { CustomTagData, CustomTagParameterData, CustomTagParameterType } from '../../../api/client';
import { Activation, Block, BlockPosition, BlockPositionNN, SimpleBlock } from '../../../model/Block';
import { Transition } from '../../../model/Graph';
import { XYCoord } from 'react-dnd';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

export const SINGLE_BLOCK_HEIGHT = 20;
export const MARGIN_BETWEEN_BLOCKS = 4;
export const SINGLE_BLOCK_HEIGHT_WITH_MARGIN = SINGLE_BLOCK_HEIGHT + MARGIN_BETWEEN_BLOCKS;
export const COMMON_TEXT_WIDTH = 190;

const getActionsBlockHeight = (actions: SimpleBlock['transitions'], offsetTop: number): number => {
  let height = 0;
  if (!actions) return 0;

  actions.forEach(action => {
    action.offsetTop = offsetTop + height;
    height += SINGLE_BLOCK_HEIGHT_WITH_MARGIN;
  });
  return height;
};

const getSpecialBlocksHeight = (
  specialBlock: Activation['patterns'] | Activation['intents'] | Activation['examples'] | Activation['events'],
  offsetTop: number
) => {
  let height = 0;

  if (specialBlock) {
    if (specialBlock.global && specialBlock.global.length > 0) {
      specialBlock.globalOffsetTop = offsetTop;

      height += SINGLE_BLOCK_HEIGHT_WITH_MARGIN;
    }
    if (specialBlock.local && specialBlock.local.length > 0) {
      specialBlock.localOffsetTop = offsetTop;

      height += SINGLE_BLOCK_HEIGHT_WITH_MARGIN;
    }

    if (specialBlock.context && specialBlock.context.length > 0) {
      specialBlock.contextOffsetTop = offsetTop + height;

      height += specialBlock.context.length * SINGLE_BLOCK_HEIGHT_WITH_MARGIN;
    }
  }

  return height;
};

export const getCodeSnippetLines = (textCode: string) => {
  let lines = textCode.split('\n');
  lines.splice(0, 1);
  return lines.reduce((newLines, currentLine) => {
    const newLine = currentLine.trim();
    if (
      !newLine.startsWith('q') &&
      !newLine.startsWith('intent') &&
      !newLine.startsWith('event') &&
      !newLine.startsWith('example') &&
      !newLine.startsWith('go')
    ) {
      newLines.push(currentLine.replace('    ', ''));
    }
    return newLines;
  }, [] as string[]);
};

export const makeCodeSnippet = (textCode: string): [string, number] => {
  const newLines = getCodeSnippetLines(textCode);
  if (newLines.length > 5) {
    newLines.splice(4, newLines.length - 5);
  }
  const showText = newLines.join('\n');
  const height = SINGLE_BLOCK_HEIGHT + (newLines.length - 1) * 16;
  return [showText, height];
};

const getScreenOutputsHeight = (block: SimpleBlock): number => {
  let height = SINGLE_BLOCK_HEIGHT; //header
  if (block.activation) {
    height += getSpecialBlocksHeight(block.activation.patterns, height);
    height += getSpecialBlocksHeight(block.activation.examples, height);
    height += getSpecialBlocksHeight(block.activation.intents, height);
    height += getSpecialBlocksHeight(block.activation.events, height);
  }
  block.snippetOffsetTop = height;
  if (block.snippet) {
    const newLines = getCodeSnippetLines(block.snippet);
    if (newLines.length > 5) {
      newLines.splice(4, newLines.length - 5);
    }
    if (newLines.length > 0) {
      height += SINGLE_BLOCK_HEIGHT + (newLines.length - 1) * 16 + MARGIN_BETWEEN_BLOCKS; //snippet
    }
  } else {
    height += SINGLE_BLOCK_HEIGHT + MARGIN_BETWEEN_BLOCKS; //snippet
  }

  if (block.transitions) {
    height += getActionsBlockHeight(block.transitions, height);
  }
  if (height > SINGLE_BLOCK_HEIGHT) {
    height += MARGIN_BETWEEN_BLOCKS;
  }

  return height;
};

export const measureText = (text: string): number => {
  let fontSize = 12;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = 'italic ' + fontSize + 'px Roboto';

    const { width } = context.measureText(text);

    return width + 4;
  }
  return 0;
};

export const getMetaShowedTypes = (parameters?: CustomTagParameterData[]): CustomTagParameterData[] => {
  if (parameters) {
    return parameters.filter(
      param =>
        (param.required &&
          param.type !== CustomTagParameterType.Integer &&
          param.type !== CustomTagParameterType.Bool) ||
        param.type === CustomTagParameterType.State ||
        param.type === CustomTagParameterType.Intents ||
        (param.name === 'actions' && param.type === CustomTagParameterType.Json)
    );
  }
  return [];
};

export const getShowedTypesHeight = (block: Block, customTags?: CustomTagData[]): number => {
  switch (block.type) {
    case 'block': {
      return getScreenOutputsHeight(block as SimpleBlock);
    }
    case 'metaBlock':
      if (customTags) {
        const customTag = customTags.find(tag => tag.tagName === block.customTag);
        if (customTag) {
          let types = getMetaShowedTypes(customTag.parameters);
          const actionsIndex = types.findIndex(type => type.name === 'actions');
          if (
            actionsIndex > -1 &&
            ((block.parameters['actions'] &&
              (block.parameters['actions'].length === 0 ||
                (block.parameters['actions'].length > 0 && block.parameters['actions'][0].buttons.length === 0))) ||
              !block.parameters['actions'])
          ) {
            types.splice(actionsIndex, 1);
          }

          let extraAddingBlocks = 0;

          if (block.customTag === 'IntentGroup' && block.parameters['intents']) {
            extraAddingBlocks =
              block.parameters['intents'].length > 0
                ? block.parameters['intents'].length - 1 //-1 because of type Intents itself
                : 0;
          }

          return SINGLE_BLOCK_HEIGHT_WITH_MARGIN + (types.length + extraAddingBlocks) * SINGLE_BLOCK_HEIGHT_WITH_MARGIN;
          // console.log(customTag);
        }
      }
      return SINGLE_BLOCK_HEIGHT;
    default:
      return SINGLE_BLOCK_HEIGHT;
  }
};

export const getRectFill = (
  type: Transition['outerType'] | Block['type'] | CustomTagData['tagName'],
  customTags?: CustomTagData[]
): string => {
  // console.log(type);
  if (customTags) {
    const customTag = customTags.find(tag => tag.tagName === type);
    if (customTag) {
      return customTag.color || '#da7537';
    }
  }
  switch (type) {
    case 'anchor':
      return '#c1c1c1';
    case 'block':
      return '#000';
  }
  return '#000';
};

export const hexToRgbA = (hex: string, opacity: number | string): string => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  return `rgba(0,0,0,${opacity})`;
};

export const setColor = (color: string, event: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(event);
  switch (event.currentTarget.nodeType) {
    case 'Group': {
      //@ts-ignore
      const children = event.currentTarget.getChildren();
      if (!Array.isArray(children)) break;
      children.forEach(element => {
        if (element.getAttr('name') !== 'hiddenElement') {
          element.fill(color);
        }
      });
      if (stage) {
        stage.batchDraw();
      }
      break;
    }
    default:
      event.currentTarget.setAttr('fill', color);
      if (stage) {
        stage.batchDraw();
      }
      break;
  }
};

export const getDropPositionOnStage = (
  dropPosition: XYCoord | null,
  offset: XYCoord | undefined,
  stage: RefObject<Konva.Stage>
): BlockPosition => {
  const stageStage = stage.current;
  if (!stageStage || !dropPosition || !offset) return { x: 0, y: 0 };

  let position: BlockPositionNN = {
    x: dropPosition.x - offset.x,
    y: dropPosition.y - offset.y,
  };
  [position] = updateUsingStageProps(stageStage, position, { x: 0, y: 0 });

  return position;
};
