import React, { FC, useEffect, useMemo } from 'react';
import { Button } from '@just-ai/just-ui';
import { t } from 'localization';
import { useRightSideMenuContext } from './index';
import { validateBlockFulfillment } from './utils/common';
import keyboardJS, { KeyEvent } from 'keyboardjs';
import { TagNames } from 'modules/JGraph/utils/types';

export const Footer: FC<{ isDirty: boolean; save: () => unknown }> = ({ isDirty, save }) => {
  const {
    getEditBlockTag,
    eventsState,
    cancelAddingEvents,
    cancel,
    saveEvent,
    savePatterns,
    saveIntent,
    savePatternsFromState,
    saveIntentFromState,
    saveEventFromState,
    savePatternsToState,
    saveEventToState,
    customTagEdit,
    customTags,
  } = useRightSideMenuContext();

  const {
    activationIntent,
    addingMenuType,
    activationsMenuType,
    activationEvent,
    pattern,
    fromStateValue,
    toStateValue,
  } = eventsState;

  const [customTagDisabledSave, setCustomTagDisabledSave] = React.useState(true);

  useEffect(() => {
    const sub = customTagEdit.disabledSave.subscribe(value => {
      setCustomTagDisabledSave(value);
    });
    return () => sub.unsubscribe();
  }, [customTagEdit.disabledSave]);

  const editBlockTag = getEditBlockTag();
  const simpleBlockCheckDisabled = useMemo(() => {
    const editBlockTag = getEditBlockTag();
    return validateBlockFulfillment(editBlockTag, customTagDisabledSave, customTags);
  }, [customTagDisabledSave, customTags, getEditBlockTag]);

  useEffect(() => {
    const saveCtrlEnter = (event?: KeyEvent) => {
      event?.preventDefault();
      event?.stopPropagation();
      if (!(!isDirty || simpleBlockCheckDisabled)) {
        save();
      }
    };
    keyboardJS.bind(['ctrl + enter', 'command + enter'], saveCtrlEnter);
    return () => {
      keyboardJS.unbind(['ctrl + enter', 'command + enter'], saveCtrlEnter);
    };
  }, [isDirty, save, simpleBlockCheckDisabled]);

  if (addingMenuType) {
    let disabled: boolean | undefined = undefined;
    let saveAction: (() => unknown) | undefined = undefined;

    if (addingMenuType === 'activations') {
      if (activationsMenuType === 'pattern') {
        disabled = pattern.length === 0;
        saveAction = savePatterns;
      }
      if (activationsMenuType === 'intent') {
        disabled = !activationIntent;
        saveAction = saveIntent;
      }
      if (activationEvent) {
        disabled = false;
        saveAction = saveEvent;
      }
    }
    if (addingMenuType === 'fromStateEvents') {
      if (activationsMenuType === 'pattern') {
        disabled = pattern.length === 0 || !fromStateValue;
        saveAction = savePatternsFromState;
      }
      if (activationsMenuType === 'intent') {
        disabled = !activationIntent || !fromStateValue;
        saveAction = saveIntentFromState;
      }
      if (activationEvent) {
        disabled = !fromStateValue;
        saveAction = saveEventFromState;
      }
    }
    if (addingMenuType === 'toStateEvents') {
      if (activationsMenuType === 'pattern') {
        disabled = pattern.length === 0 || !toStateValue;
        saveAction = savePatternsToState;
      }
      if (activationsMenuType === 'intent') {
        return null;
        // disabled = !activationIntent || !toStateValue;
        // saveAction = saveIntentToState;
      }
      if (activationEvent) {
        disabled = !toStateValue;
        saveAction = saveEventToState;
      }
    }

    if (disabled !== undefined) {
      return (
        <div className='JGraph-RightSideMenu-footer'>
          {saveAction && (
            <Button color='primary' data-test-id='RightSideMenu:save' disabled={disabled} onClick={saveAction}>
              {t('Save')}
            </Button>
          )}
          <Button
            flat
            color='secondary'
            onClick={() => {
              cancelAddingEvents();
            }}
            data-test-id='RightSideMenu:cancel'
          >
            {t('Cancel')}
          </Button>
        </div>
      );
    }
    return null;
  }

  if (!editBlockTag) return null;
  if (editBlockTag.tagName === TagNames.random) return null;

  return (
    <div className='JGraph-RightSideMenu-footer'>
      <Button
        color='primary'
        data-test-id='RightSideMenu:save'
        disabled={!isDirty || simpleBlockCheckDisabled}
        onClick={save}
      >
        {t('Save')}
      </Button>
      <Button flat color='secondary' data-test-id='RightSideMenu:cancel' onClick={cancel}>
        {t('Cancel')}
      </Button>
    </div>
  );
};
