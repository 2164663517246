var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, Fragment, useState } from 'react';
import { Button, Icon, InputText } from '@just-ai/just-ui';
import { useLabelsContext } from '../../../../../Labels/context/LabelsContext';
export function LabelsGroups({ groups, currentLabels, onChange, t, createNewLabelAndAddToMessage, }) {
    const [addNew, setAddNew] = useState();
    const { hasTagCreatePermission } = useLabelsContext();
    const onChangeAddNew = useCallback((value, groupId) => __awaiter(this, void 0, void 0, function* () {
        yield createNewLabelAndAddToMessage(value, groupId);
        setAddNew(undefined);
    }), [createNewLabelAndAddToMessage]);
    return (_jsx("div", { className: 'MessageForm_labelsGroups', children: Array.isArray(groups) &&
            groups.map(x => (_jsxs(Fragment, { children: [x.name && _jsx("label", { children: x.name }, x.id + 'label'), _jsxs("div", { children: [Array.isArray(x === null || x === void 0 ? void 0 : x.labels) &&
                                x.labels.map(label => (_jsx(Button, { size: 'sm', disabled: !hasTagCreatePermission, className: 'Message_label_button', onClick: () => onChange(label), outline: Boolean(currentLabels.find(x => x.id === label.id)) === false, color: Boolean(currentLabels.find(x => x.id === label.id)) ? 'success' : 'secondary', children: label.name }, label.id))), addNew !== x.id && (_jsxs(Button, { color: 'primary', name: 'farPlus', size: 'sm', outline: true, onClick: () => setAddNew(x.id), disabled: !hasTagCreatePermission, children: [_jsx(Icon, { name: 'farPlus' }), t('Analytics: label new')] })), addNew === x.id && (_jsx(InputText, { autoFocus: true, 
                                //@ts-ignore
                                onBlur: event => {
                                    var _a;
                                    event.preventDefault();
                                    if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) {
                                        onChangeAddNew(event.target.value, x.id);
                                    }
                                }, 
                                //@ts-ignore
                                onKeyPress: event => {
                                    event.stopPropagation();
                                    if ((event === null || event === void 0 ? void 0 : event.key) === 'Enter') {
                                        event.preventDefault();
                                        event.target.blur();
                                        event.target.value = '';
                                    }
                                } }))] }, x.id + 'label list')] }, x.name))) }));
}
