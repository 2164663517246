import React from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import { cloudDomainsService } from 'services/CloudDomainsService';

import { useKnowledgeBaseLLMIntegrationContext } from '../../domain/context';
import { ConnectKnowledgeBaseButton } from '../ConnectKnowledgeBaseButton';

import styles from './styles.module.scss';

export const EmptyListOfIntegrationsState = React.memo(() => {
  const { getIntegrationList, isKnowledgeBaseLLMSectionWritable } = useKnowledgeBaseLLMIntegrationContext();

  return (
    <div className={styles.EmptyListOfIntegrationsState}>
      <h3 className={styles.EmptyListOfIntegrationsState__title}>{t('KnowledgeBaseLLM:NoIntegrations')}</h3>
      {isKnowledgeBaseLLMSectionWritable && <ConnectKnowledgeBaseButton onConnect={getIntegrationList} />}
      <Button
        tag='a'
        href={cloudDomainsService.getFullDomainPath('khub') + '/new_hub'}
        target='_blank'
        color='primary'
        flat
        iconLeft='farPlus'
      >
        {t('KnowledgeBaseLLM:ConnectFromKhub')}
      </Button>
    </div>
  );
});

EmptyListOfIntegrationsState.displayName = 'EmptyListOfIntegrationsState';
