import React, { ChangeEventHandler, useCallback } from 'react';
import { Icon, IconButton } from '@just-ai/just-ui';
import { t } from '../../../../../../../localization';
import cn from 'classnames';
import classes from './styles.module.scss';

type SearchProps = {
  isSearchOpen: boolean;
  closeSearch: () => unknown;
  onChangeSearch: (value: string) => unknown;
  searchValue: string;
};

export function Search({ isSearchOpen, onChangeSearch, searchValue, closeSearch }: SearchProps) {
  const onChangeSearchHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      onChangeSearch(event.target.value);
    },
    [onChangeSearch]
  );

  if (!isSearchOpen) return null;
  return (
    <div
      className={cn(
        'position-absolute d-flex padding-top-8 padding-left-16 padding-right-16 padding-bottom-7 w-100',
        classes.Search
      )}
    >
      <div className={cn('d-flex align-items-center justify-content-center w-100', classes.Search_inputGroup)}>
        <Icon name='farSearch' wrapperClassName='d-flex' />
        <input
          autoFocus
          value={searchValue}
          placeholder={t('Search')}
          onChange={onChangeSearchHandler}
          className={cn('d-flex flex-grow-1', classes.Search_inputText)}
        />
        <IconButton name='farTimesCircle' onClick={closeSearch} className={classes.Search_customButton} />
      </div>
    </div>
  );
}
