export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const FETCH_INTEGRATIONS_PENDING = 'FETCH_INTEGRATIONS_PENDING';
export const FETCH_INTEGRATIONS_FULFILLED = 'FETCH_INTEGRATIONS_FULFILLED';
export const FETCH_INTEGRATIONS_REJECTED = 'FETCH_INTEGRATIONS_REJECTED';

export const SET_EDITABLE_INTEGRATION = 'SET_EDITABLE_INTEGRATION';
export const CLEAR_EDITABLE_INTEGRATION = 'CLEAR_EDITABLE_INTEGRATION';
export const UPDATE_EDITABLE_INTEGRATION = 'UPDATE_EDITABLE_INTEGRATION';

export const SAVE_EDITABLE_INTEGRATION = 'SAVE_EDITABLE_INTEGRATION';
export const SAVE_EDITABLE_INTEGRATION_PENDING = 'SAVE_EDITABLE_INTEGRATION_PENDING';
export const SAVE_EDITABLE_INTEGRATION_FULFILLED = 'SAVE_EDITABLE_INTEGRATION_FULFILLED';
export const SAVE_EDITABLE_INTEGRATION_REJECTED = 'SAVE_EDITABLE_INTEGRATION_REJECTED';

export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const CREATE_INTEGRATION_PENDING = 'CREATE_INTEGRATION_PENDING';
export const CREATE_INTEGRATION_FULFILLED = 'CREATE_INTEGRATION_FULFILLED';
export const CREATE_INTEGRATION_REJECTED = 'CREATE_INTEGRATION_REJECTED';

export const REMOVE_EDITABLE_INTEGRATION = 'REMOVE_EDITABLE_INTEGRATION';
export const REMOVE_EDITABLE_INTEGRATION_PENDING = 'REMOVE_EDITABLE_INTEGRATION_PENDING';
export const REMOVE_EDITABLE_INTEGRATION_FULFILLED = 'REMOVE_EDITABLE_INTEGRATION_FULFILLED';
export const REMOVE_EDITABLE_INTEGRATION_REJECTED = 'REMOVE_EDITABLE_INTEGRATION_REJECTED';

export const SHOW_INTEGRATION_VALIDATION_ERRORS = 'SHOW_INTEGRATION_VALIDATION_ERRORS';
export const HIDE_INTEGRATION_VALIDATION_ERRORS = 'HIDE_INTEGRATION_VALIDATION_ERRORS';

export const CLEAR_VALIDATE_TOKEN_ERROR = 'CLEAR_VALIDATE_TOKEN_ERROR';

export const CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT = 'CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT';
