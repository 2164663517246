const prefix = '@diagramAnalytics/';

export const DIAGRAM_INIT = prefix + 'DIAGRAM_INIT';
export const DIAGRAM_INIT_FULFILLED = prefix + 'DIAGRAM_INIT_FULFILLED';
export const DIAGRAM_INIT_PENDING = prefix + 'DIAGRAM_INIT_PENDING';
export const DIAGRAM_INIT_REJECTED = prefix + 'DIAGRAM_INIT_REJECTED';

export const DIAGRAM_INIT_LABEL_COUNT = prefix + 'DIAGRAM_INIT_LABEL_COUNT';
export const DIAGRAM_INIT_LABEL_COUNT_FULFILLED = prefix + 'DIAGRAM_INIT_LABEL_COUNT_FULFILLED';
export const DIAGRAM_INIT_LABEL_COUNT_PENDING = prefix + 'DIAGRAM_INIT_LABEL_COUNT_PENDING';
export const DIAGRAM_INIT_LABEL_COUNT_REJECTED = prefix + 'DIAGRAM_INIT_LABEL_COUNT_REJECTED';

export const DIAGRAM_SESSION = prefix + 'DIAGRAM_SESSION';
export const DIAGRAM_SESSION_FULFILLED = prefix + 'DIAGRAM_SESSION_FULFILLED';
export const DIAGRAM_SESSION_PENDING = prefix + 'DIAGRAM_SESSION_PENDING';
export const DIAGRAM_SESSION_REJECTED = prefix + 'DIAGRAM_SESSION_REJECTED';

export const DIAGRAM_DIALOGS = prefix + 'DIAGRAM_DIALOGS';
export const DIAGRAM_DIALOGS_FULFILLED = prefix + 'DIAGRAM_DIALOGS_FULFILLED';
export const DIAGRAM_DIALOGS_PENDING = prefix + 'DIAGRAM_DIALOGS_PENDING';
export const DIAGRAM_DIALOGS_REJECTED = prefix + 'DIAGRAM_DIALOGS_REJECTED';

export const DIAGRAM_INIT_LABEL_TIME = prefix + 'DIAGRAM_INIT_LABEL_TIME';
export const DIAGRAM_INIT_LABEL_TIME_FULFILLED = prefix + 'DIAGRAM_INIT_LABEL_TIME_FULFILLED';
export const DIAGRAM_INIT_LABEL_TIME_PENDING = prefix + 'DIAGRAM_INIT_LABEL_TIME_PENDING';
export const DIAGRAM_INIT_LABEL_TIME_REJECTED = prefix + 'DIAGRAM_INIT_LABEL_TIME_REJECTED';

export const TOGGLE_COMMON_CHART_LABELS = prefix + 'TOGGLE_COMMON_CHART_LABELS';
export const TOGGLE_COMMON_CHART_LABELS_PHRASE = prefix + 'TOGGLE_COMMON_CHART_LABELS_PHRASE';

export const TOGGLE_SESSION_AND_LABEL_CHART_LABELS = prefix + 'TOGGLE_SESSION_AND_LABEL_CHART_LABELS';
