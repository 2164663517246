import React from 'react';
import './styles.scss';
import { Button } from '@just-ai/just-ui';
import { createPlanPaymentString } from 'utils';
import { t } from 'localization';
import { Link } from 'react-router-dom';

type TariffProps = {
  tariffName: string;
  dueDate: string;
  blockType: 'NOT_PAYED' | 'NONE' | 'FULL';
  demo: boolean;
  manualControl: boolean;
  fetchingAccountManagerData: boolean;
  price: number;
};

export default function TariffInfo(props: TariffProps) {
  const paymentString = createPlanPaymentString(
    props.blockType,
    props.demo,
    props.dueDate,
    props.manualControl,
    true,
    props.price
  );
  return props.tariffName ? (
    <div className='tariff-box'>
      <div>
        <p>{t(props.tariffName)}</p>
        <p>{paymentString}</p>
      </div>
      <Link to='/plans'>
        <Button size='sm' outline color='primary'>
          {t('Tariffs')}
        </Button>
      </Link>
    </div>
  ) : null;
}
