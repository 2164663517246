export const NEWS_ITEM_LOAD = 'NEWS_ITEM_LOAD';
export const NEWS_ITEM_LOAD_FULFILLED = 'NEWS_ITEM_LOAD_FULFILLED';
export const NEWS_ITEM_LOAD_REJECTED = 'NEWS_ITEM_LOAD_REJECTED';
export const NEWS_ITEM_LOAD_PENDING = 'NEWS_ITEM_LOAD_PENDING';

export const ADD_NEWS_ITEM = 'ADD_NEWS_ITEM';
export const ADD_NEWS_ITEM_FULFILLED = 'ADD_NEWS_ITEM_FULFILLED';
export const ADD_NEWS_ITEM_REJECTED = 'ADD_NEWS_ITEM_REJECTED';
export const ADD_NEWS_ITEM_PENDING = 'ADD_NEWS_ITEM_PENDING';

export const UPDATE_NEWS_ITEM = 'UPDATE_NEWS_ITEM';
export const UPDATE_NEWS_ITEM_FULFILLED = 'UPDATE_NEWS_ITEM_FULFILLED';
export const UPDATE_NEWS_ITEM_REJECTED = 'UPDATE_NEWS_ITEM_REJECTED';
export const UPDATE_NEWS_ITEM_PENDING = 'UPDATE_NEWS_ITEM_PENDING';

export const NEWS_LOCALE_LOAD = 'NEWS_LOCALE_LOAD';
export const NEWS_LOCALE_LOAD_FULFILLED = 'NEWS_LOCALE_LOAD_FULFILLED';
export const NEWS_LOCALE_LOAD_REJECTED = 'NEWS_LOCALE_LOAD_REJECTED';
export const NEWS_LOCALE_LOAD_PENDING = 'NEWS_LOCALE_LOAD_PENDING';

export const ACTIVATE_TAB = 'ACTIVATE_TAB';
export const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';
export const ADD_LOCALE = 'ADD_LOCALE';
export const DELETE_LOCALE = 'DELETE_LOCALE';
export const NEW_ITEM = 'NEW_ITEM';
export const SET_SELECT_CHANNEL = 'SET_SELECT_CHANNEL';
export const TOGGLE_NEWS_CLIENT_CHECKED = 'TOGGLE_NEWS_CLIENT_CHECKED';
export const SET_PUBLISH_DATE = 'SET_PUBLISH_DATE';
export const SELECT_ITEM_LOCALE = 'SELECT_ITEM_LOCALE';

export const PUBLISH_NEWS_ITEM = 'PUBLISH_NEWS_ITEM';
export const DROP_SELECTED_USERS = 'DROP_SELECTED_USERS';
