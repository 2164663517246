import React, { CSSProperties, useCallback, useContext, useMemo } from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import cn from 'classnames';

import { TreeNode, DragTypes } from '../../types';
import { TreeContext, TreeNodeViewProps, TREE_DEPTH_PADDING } from '../../index';

import styles from '../../styles.module.scss';

export type DragNodeType = {
  type: DragTypes;
  node: TreeNode;
};

function LeafDefaultView({ expanded, onToggle, node }: TreeNodeViewProps) {
  return <span className={styles.text}>{node.name}</span>;
}

const noneFn = () => {};

interface LeafInterface {
  node: TreeNode;
  className?: string;
  style?: CSSProperties;
}
const Leaf = function ({ node, className, style }: LeafInterface) {
  const context = useContext(TreeContext);
  const isSelected = useMemo(() => context.selectedIds.includes(node.id), [context.selectedIds, node.id]);
  const isActive = useMemo(() => context.activeId && context.activeId === node.id, [context.activeId, node.id]);

  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      context.handleContextMenu(event, node);
    },
    [context, node]
  );

  const [{ isDragging }, drag] = useDrag({
    canDrag: context.isDndEnabled,
    item: { node, type: DragTypes.LEAF } as DragNodeType,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    canDrop: (item: DragNodeType) => item.node !== node || !!context.isDndEnabled,
    accept: DragTypes.LEAF,
    drop: (item: DragNodeType) => {
      context.isDndEnabled && context.onDragDrop(item.node, node);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const { isDraggingOtherElement, draggingElement } = useDragLayer(monitor => ({
    isDraggingOtherElement: monitor.isDragging(),
    draggingElement: monitor.getItem(),
  }));

  const depthLevel = node.depthLevel ?? 0;
  const View = context.treeNodeView ?? LeafDefaultView;

  return (
    <div
      ref={drop}
      style={style}
      className={cn({
        [styles.node__dragging]: isDragging,
      })}
      onContextMenu={onContextMenu}
    >
      <div
        ref={drag}
        className={cn(styles.node, className, {
          [styles.node__selected]: isSelected,
          [styles.node__active]: isActive,
          [styles.node__dragging]: isDragging,
          [styles.node__dragged]: draggingElement?.node?.id === node?.id,
          [styles.node__elementDisabled]: !node.enabled,
          [styles.disableHover]:
            context.isDndEnabled && isDraggingOtherElement && draggingElement?.node?.id !== node?.id,
          [styles.node__isOver]: isOver,
        })}
        style={{
          paddingLeft: depthLevel ? depthLevel * TREE_DEPTH_PADDING : '',
        }}
        onClick={() => context.onItemClick(node)}
        onDoubleClick={() => context.onItemDoubleClick(node)}
        data-test-id={`Tree.Leaf.${node.name}`}
      >
        <View onToggle={noneFn} node={node} expanded={false} />
      </div>
    </div>
  );
};

export default React.memo(Leaf);
