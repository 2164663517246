import React, { useCallback } from 'react';
import localize from '../../../../localization';
import { Modal, IconButton } from '@just-ai/just-ui';
import './ErrorModal.scss';

const t = localize.translate;

interface ErrorModalInterface {
  text: string;
  toggle: () => void;
}

export function ErrorModal({ toggle, text }: ErrorModalInterface) {
  const copy = useCallback(() => {
    navigator.clipboard && navigator.clipboard.writeText(text);
  }, [text]);
  return (
    <Modal
      isOpen
      onClickCloseIcon={toggle}
      onActionClick={toggle}
      buttonSubmitText={t('Close')}
      title={t('ErrorModal: title')}
      className='block-user-modal'
    >
      <div data-test-id='ChatwidgetErrorModalBody' className='ChatwidgetErrorModalBody'>
        <span>{text}</span>
        <IconButton color='primary' flat name='farCopy' onClick={copy} />
      </div>
    </Modal>
  );
}
