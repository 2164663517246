import axios from 'axios';

const TIMEOUT_VALUE = 30_000;

export class SyncFrontendVersionService {
  private timeoutId!: NodeJS.Timeout;

  public isVersionActual(version: string) {
    return this.getCurrentVersion().then(buildNumber => buildNumber === version);
  }

  public getCurrentVersion(): Promise<string> {
    return axios
      .get(`/version`, {
        headers: {
          'Cache-Control': 'no-store',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      .then(response => response.data.buildNumber as string);
  }

  public startWatch = (currentValue: string) => {
    return this.isVersionActual(currentValue)
      .then(isActual => {
        if (!isActual) {
          return this.syncFrontend();
        }
        this.timeoutId = setTimeout(() => this.startWatch(currentValue), TIMEOUT_VALUE);
      })
      .catch(error => {
        console.error(error);
        this.timeoutId = setTimeout(() => this.startWatch(currentValue), TIMEOUT_VALUE);
      });
  };

  public stopWatch = () => {
    if (!this.timeoutId) return;
    clearTimeout(this.timeoutId);
  };

  public syncFrontend() {
    return window.location.reload();
  }
}
