import React, { FunctionComponent } from 'react';
import './style.scss';

import { useTestWidgetContext, TestWidget, NluWidget } from '../../modules/TestWidget';
import { TestTtsWidget } from 'modules/TestTtsWidget';
import classNames from 'classnames';

type GlobalRightPanelProps = {};
const GlobalRightPanel: FunctionComponent<GlobalRightPanelProps> = React.memo(() => {
  const { isTestWidgetShown, isNluWidgetShown, isRetraining, hideNluWidget, isTestTtsWidgetShown } =
    useTestWidgetContext();

  return (
    <div
      className={classNames('global-right-panel', {
        'visually-hidden': !isTestWidgetShown && !isNluWidgetShown && !isTestTtsWidgetShown,
        'tts-widget-background': isTestTtsWidgetShown,
      })}
    >
      {isTestWidgetShown && <TestWidget />}
      {isNluWidgetShown && <NluWidget close={hideNluWidget} retraining={isRetraining} />}
      {isTestTtsWidgetShown && <TestTtsWidget />}
    </div>
  );
});
GlobalRightPanel.displayName = 'Memo(GlobalRightPanel)';
export default GlobalRightPanel;
