// @file checkLocalizationScript:disabled

const russianLetters: Record<string, string> = {
  Ё: 'YO',
  Й: 'Y',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: '',
  ё: 'yo',
  й: 'y',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: '',
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: '',
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: '',
  б: 'b',
  ю: 'yu',
};

export function transliterate(word: string) {
  return word
    .split('')
    .map(char => russianLetters[char] ?? char)
    .join('');
}

export function transliterateText(
  text: string,
  options?: { collapseWhitespace?: boolean; whitespaceReplacement?: string; punctuationReplacement?: string }
) {
  let innerOptions = Object.assign({ collapseWhitespace: true, whitespaceReplacement: '_' }, options);
  let result = transliterate(text);

  if (innerOptions.collapseWhitespace) {
    result = result.replace(/\s+/g, innerOptions.whitespaceReplacement);
  } else {
    result = result.replace(/ /g, innerOptions.whitespaceReplacement);
  }

  if (innerOptions.punctuationReplacement !== undefined) {
    result = result.replace(/[.,/#!?$%^&*;:{}=\-`~()]/g, innerOptions.punctuationReplacement);
  }

  return result;
}
