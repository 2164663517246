import { aimybox, aimychat, aimylogic, aimyvoice, caila, cloud, jaicf, jaicp, tovie_cloud, tovie_dialogstudio, tovie_nlp, tovie_platform, tovie_voice, tovie_agent, } from './icons';
export const logoByProduct = {
    aimylogic: aimylogic,
    aimyvoice: aimyvoice,
    cc: cloud,
    jaicp: jaicp,
    jaicf: jaicf,
    caila: caila,
    aimybox: aimybox,
    aimychat: aimychat,
    tovie_agent: tovie_agent,
    'Tovie DialogStudio': tovie_dialogstudio,
    'Tovie Voice': tovie_voice,
    'Tovie Cloud': tovie_cloud,
    'Tovie Platform': tovie_platform,
    'Tovie NLP': tovie_nlp,
    'Tovie Agent': tovie_agent,
};
export const getProductName = (productName, isEuroInstance) => {
    if (!isEuroInstance)
        return productName;
    switch (productName.toLowerCase()) {
        case 'aimylogic':
            return 'Tovie DialogStudio';
        case 'jaicp':
            return 'Tovie Platform';
        case 'cc':
            return 'Tovie Cloud';
        case 'caila':
            return 'Tovie NLP';
        case 'aimyvoice':
            return 'Tovie Voice';
        case 'aimychat':
            return 'Tovie Agent';
        default:
            return productName;
    }
};
