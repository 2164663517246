export function provideRef(...to) {
    return function (ref) {
        to.forEach((toElement) => {
            if (!toElement)
                return;
            if (typeof toElement === 'function') {
                toElement(ref);
                return;
            }
            toElement.current = ref;
        });
    };
}
