import { State } from 'modules/Editor/api/client';
import { Ace } from 'ace-builds';
import { last } from 'lodash';

export const toIndentLevel = (col: number) => Math.floor(col / 4); //4 === session.getTabSize()

export const isPreviousNonEmptyTokenInArray = (tokens: string[], array: string[]) => {
  for (let i = tokens.length - 2; i >= 0; i--) {
    if (array.includes(tokens[i])) return true;
    if (tokens[i] !== '') return false;
  }
  return false;
};

export const getFullState = (state: string, row: number, indentLevel: number, states: State[]) => {
  if (state.startsWith('/')) return state;

  const optionalParent = states
    .slice()
    .reverse()
    .find(record => record.locator.line < row && toIndentLevel(record.locator.col) < indentLevel);

  if (optionalParent) return optionalParent.name + '/' + state;

  return state;
};

export const countIndentLevel = (line: string) => Math.floor(line.search(/\S/) / 4);

export const getCurrentTabIndent = (tokens: string[]): number => {
  let currentTabIndent = 0;
  if (tokens.length >= 1 && !tokens[tokens.length - 1]) {
    tokens.splice(tokens.length - 2, 1);
  }
  for (let token of tokens) {
    if (!token) {
      ++currentTabIndent;
    }
    if (token) {
      break;
    }
  }
  return currentTabIndent;
};

export function getCustomTagParametersTokens(
  contextStartRow: number,
  currentTabIndent: number,
  session: Ace.EditSession
) {
  const allLines = session.getDocument().getAllLines();
  let contextRow = contextStartRow;

  let nextLineTabTokens = session.getTokens(contextRow);
  if (nextLineTabTokens && nextLineTabTokens[0]?.type === 'text') {
    let nextLineTabIndent = nextLineTabTokens[0]?.value.split(session.getTabString()).length;
    if (currentTabIndent < nextLineTabIndent) {
      let customTagRows: Ace.Token[][] = [];
      while (contextRow <= allLines.length && currentTabIndent < nextLineTabIndent) {
        customTagRows.push(session.getTokens(contextRow));
        contextRow++;
        nextLineTabTokens = session.getTokens(contextRow);
        nextLineTabIndent = nextLineTabTokens[0]?.value.split(session.getTabString()).length;
      }
      return customTagRows;
    }
  }
  return [];
}

const NON_IDENTIFIER_REGEX = /[^a-zA-Z_0-9\$\-\u00A2-\u2000\u2070-\uFFFF]/g;

export const removePathPrefix = (item: string, completion: string) => {
  const pathSeparators = Array.from(completion.matchAll(NON_IDENTIFIER_REGEX));
  return pathSeparators.length > 0 ? item.substring((last(pathSeparators)?.index ?? -1) + 1) : item;
};
