export class GutterMarkerTooltip {
  private delayTimeoutId: NodeJS.Timeout | null = null;
  private node: HTMLElement | null = null;
  private isShowed: boolean = false;

  constructor(
    private showDelayTime: number,
    private tooltipHandler: (props: { isOpen: boolean; node: HTMLElement }) => void,
    private tooltipTargetId: string
  ) {}

  showWithDelay(node: HTMLElement) {
    this.hide();
    this.delayTimeoutId = setTimeout(() => {
      this.showTooltip(node);
    }, this.showDelayTime);
  }

  public hide() {
    if (this.delayTimeoutId) {
      clearTimeout(this.delayTimeoutId);
      this.delayTimeoutId = null;
    }
    if (this.isShowed) {
      this.hideTooltip();
    }
  }

  private showTooltip(node: HTMLElement) {
    this.isShowed = true;
    this.node = node;
    node.setAttribute('id', this.tooltipTargetId);
    this.tooltipHandler({ isOpen: true, node });
  }

  private hideTooltip() {
    if (this.node) {
      this.node.removeAttribute('id');
      this.tooltipHandler({ isOpen: false, node: this.node });
    }
    this.isShowed = false;
    this.node = null;
  }
}
