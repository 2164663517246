export const analyticsArrayFilter = [
  'dateFrom',
  'dateTo',
  'sessionId',
  'channel',
  'question',
  'client',
  'class',
  'state',
  'comment',
  'livechat',
  'logLabels',
  'withoutLogLabels',
  'sessionLabels',
  'withoutSessionLabels',
  'activeTab',
  'nlpClass.name',
  'nlpClass.like',
  'state.name',
  'state.like',
  'clicked',
  'dialogsPage',
  'withAnySessionLabel',
  'withAnyLogLabel',
  'responseDataJsonPath',
  'response',
  'size',
  'shouldBeLimited',
  'callTaskId',
  'isIncoming',
  'mailingResults',
  'withoutMailingResults',
  'withoutEmptyClientMessages',
  // 'filterSetId',
];
