export type SuccessFormatResult = [string, null];
export type FormatError = { message: string; codeFrame?: string; loc?: { start: { line: number; column: number } } };
export type ErrorFormatResult = [null, FormatError];

export type FormatResult = SuccessFormatResult | ErrorFormatResult;

export function isSyntaxError(e: any): e is FormatError {
  if (!(e instanceof SyntaxError)) return false;
  // @ts-ignore
  if (!e.loc) return false;
  // @ts-ignore
  if (!e.codeFrame) return false;
  // @ts-ignore
  if (!e.message) return false;
  return true;
}

export interface BaseFormatter {
  format(code: string): Promise<FormatResult>;
}
