import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Icon, useToggle } from '@just-ai/just-ui';
import { getDefaultLocale, GuideTourStepper } from '../../../../../components/GuideTourStepper';
import { useRightSideMenuContext } from '../index';
import { getTourSteps } from './blockGuideSteps';
import { t } from '../../../../../localization';

export const ShowBlockGuide = () => {
  const { getEditBlockTag, eventsState } = useRightSideMenuContext();
  const blockOpenedToEdit = useMemo(() => getEditBlockTag(), [getEditBlockTag]);
  const [guideSteps, setGuideSteps] = useState(() => getTourSteps(blockOpenedToEdit, eventsState));
  const [isShowGuideTour, showGuideTour, hideGuideTour] = useToggle(false);

  const locale = useRef({ ...getDefaultLocale(), last: t('JGraphGuideTour:Actions:Close') });

  useEffect(() => {
    if (!blockOpenedToEdit) {
      hideGuideTour();
    }
    setGuideSteps(getTourSteps(blockOpenedToEdit, eventsState));
  }, [blockOpenedToEdit, eventsState, hideGuideTour]);

  if (!guideSteps) return null;

  return (
    <>
      {guideSteps && guideSteps.length > 0 && (
        <Icon
          name='farQuestionCircle'
          wrapperClassName='px-2 cursor--pointer'
          size='md'
          color='primary'
          onClick={showGuideTour}
        />
      )}

      {isShowGuideTour && blockOpenedToEdit && (
        <GuideTourStepper
          guideSteps={guideSteps}
          skip={hideGuideTour}
          skipForever={hideGuideTour}
          locale={locale.current}
          styles={{ overlay: { zIndex: 600 } }}
        />
      )}
    </>
  );
};
