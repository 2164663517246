import FileNode from './FileNode';
import { TreeNode } from '@just-ai/just-ui';
import { FileTreeNode } from './FileTree';

export default class FolderNode implements TreeNode {
  static readonly PREFIX = 'd';

  static prefixed(path: string, prefix: string = '') {
    return FolderNode.PREFIX + prefix + path;
  }

  static deprefixed(folderId: string, prefix: string = '') {
    return folderId.startsWith(FolderNode.PREFIX + prefix)
      ? folderId.substring(FolderNode.PREFIX.length + prefix.length)
      : folderId;
  }

  path: string;
  name: string;
  nodeId: string;
  prefix: string;

  parentNode?: FolderNode;
  parent?: string;

  childrenNodes: Array<FileTreeNode> = [];
  children: string[] = [];

  constructor(path?: string, prefix: string = '') {
    this.path = path ? path : '/';
    this.prefix = prefix;
    this.name = this.path.substring(this.path.lastIndexOf('/') + 1);
    this.nodeId = FolderNode.prefixed(this.path, prefix);
  }

  setParentNode(parentNode?: FolderNode) {
    this.parentNode = parentNode;
    this.parent = this.parentNode ? this.parentNode.nodeId : '';
  }

  addChildNode(childNode: FileTreeNode) {
    this.childrenNodes.push(childNode);
    this.childrenNodes.sort(FolderNode.byTypeAndName);
    this.children = this.childrenNodes.map(node => node.nodeId);
  }

  removeChildNode(childNode: FileTreeNode) {
    const index = this.childrenNodes.indexOf(childNode);
    if (index > -1) {
      this.childrenNodes.splice(index, 1);
      this.children = this.childrenNodes.map(node => node.nodeId);
    }
  }

  getRenamedId(newName: string) {
    if (!this.parentNode || this.parentNode.path === '/') return FolderNode.prefixed('/' + newName, this.prefix);
    return FolderNode.prefixed(`${this.parentNode.path}/${newName}`, this.prefix);
  }

  static byTypeAndName(a: TreeNode, b: TreeNode): number {
    if (a instanceof FolderNode) {
      if (b instanceof FileNode) return -1;
      return FolderNode.byName(a, b);
    }

    if (b instanceof FolderNode) return 1;
    return FolderNode.byName(a, b);
  }

  static byName(a: TreeNode, b: TreeNode): number {
    return a.name < b.name ? -1 : 1;
  }
}
