import DOMPurify from 'dompurify';
import { ComponentType, useCallback } from 'react';

type CallbackFunction = (...args: unknown[]) => unknown;

export type DebounceFunction = (callback: CallbackFunction) => void;
export const getDebounceFunction = (ms: number = 500) => {
  let timeout: NodeJS.Timeout | null = null;
  return (callback: CallbackFunction) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(callback, ms);
  };
};

type PromiseFunction = (...args: unknown[]) => Promise<unknown>;
export const getQueueFunction = () => {
  const queue: PromiseFunction[] = [];
  let pendingPromise: Promise<unknown> | null = null;

  const runNextPromise = () => {
    const nextPromiseFunction = queue.length > 0 ? queue.shift() : null;
    pendingPromise = nextPromiseFunction ? nextPromiseFunction().finally(runNextPromise) : null;
  };
  return (promiseFunction: PromiseFunction) => {
    if (pendingPromise) {
      queue.push(promiseFunction);
      return;
    }
    pendingPromise = promiseFunction().finally(runNextPromise);
  };
};

export const toNumberNN = (value: any): number => (value && !isNaN(Number(value)) ? Number(value) : 0);

export const getRandomString = (length: number = 3): string => String(Math.random()).slice(2, 2 + length);

export const concatArrays = <Tresult extends unknown>(...arrays: Tresult[]): Tresult[] =>
  ([] as Tresult[]).concat(...arrays);

export const formatJsonOrReturnOriginalValue = (json: string) => {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (_e) {
    return json;
  }
};

export const onCtrlEnterKeyPressedAction =
  (callback: Function = () => {}) =>
  (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      callback();
    }
  };

export const findDuplicates = <Tkey>(arr: Tkey[]): Tkey[] => arr.filter((item, index) => arr.indexOf(item) !== index);
export const uniquify = <Tkey>(arr: Tkey[]): Tkey[] => Array.from(new Set(arr));
export const convertConfidenceToPercents = (confidence: number) => String(Math.round(confidence * 100) / 100);
export const getValueFromJson = (json: string) => {
  try {
    return JSON.parse(json).value;
  } catch (_e) {
    return false;
  }
};

export function getDisplayName(Component: ComponentType<any>) {
  return Component.displayName || Component.name || 'Unknown';
}

export function getCalculateDropdownOffsetFunction() {
  let prevData: any = {};
  return (data: any) => {
    if (data.offsets.reference.right !== 0 || !prevData.offsets) {
      prevData = { ...data };
    }
    return {
      ...data,
      styles: {
        ...data.styles,
        transform: `translate3d(${Math.round(Number(prevData.offsets.reference.right))}px, ${Math.round(
          Number(prevData.offsets.popper.top)
        )}px, 0px)`,
      },
    };
  };
}

export const getArrayWithSerialNumbers = (length: number) => Array.from(Array(length).keys());

export const withLog = <Func extends Function>(func: Func): Func => {
  //@ts-ignore
  return (...args) => {
    const result = func(...args);
    console.log('DEBUG_ARGS', args);
    console.log('DEBUG_RESULT', result);
    return result;
  };
};

export const withProfiler = <Func extends Function>(func: Func): Func => {
  //@ts-ignore
  return (...args) => {
    console.log('DEBUG_TIME_START', new Date().getMilliseconds());
    const result = func(...args);
    console.log('DEBUG_TIME_FINISH', new Date().getMilliseconds());
    return result;
  };
};

export const detach =
  <Args>(func: (args: Args) => void) =>
  (args: Args) =>
    setTimeout(() => func(args), 0);
export const useDetach = <Args>(func: (args: Args) => void) =>
  useCallback((args: Args) => () => setTimeout(() => func(args), 0), [func]);

export const getSelectedIdsFromId = (id?: string) => (typeof id === 'string' ? [id] : []);

export const normalize = (s?: string) => (s ? s.toUpperCase().trim() : '');

export const getCountryIsoCode = (code: string) => {
  switch (code.toLowerCase()) {
    case 'ru':
      return 'ru';
    case 'eng':
    case 'en':
      return 'gb';
    case 'pt':
      return 'pt';
    case 'cn':
    case 'zh':
      return 'cn';
    case 'ja':
      return 'jp';
    case 'es':
      return 'es';
    case 'da':
      return 'dk';
    case 'nl':
      return 'nl';
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'el':
      return 'gr';
    case 'it':
      return 'it';
    case 'lt':
      return 'lt';
    case 'pl':
      return 'pl';
    case 'ro':
      return 'ro';
    case 'kz':
      return 'kz';
    case 'uk':
      return 'ua';
    default:
      return 'gb';
  }
};

export const downloadTextFileWithContent = (fileContent: string, fileName: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const emphasizeMostWeightedText = (boldPart: string, source: string) => {
  const sanitizedBold = DOMPurify.sanitize(boldPart, { ALLOWED_TAGS: ['b', '#text'], ALLOWED_ATTR: ['class'] });
  var re = new RegExp(sanitizedBold, 'i');
  return source.replace(re, '<b>' + sanitizedBold + '</b>');
};
