import React, { FC, useMemo } from 'react';
import { Path } from 'react-konva';
import { debugActiveColor } from '../../colors';

export const DebugMark: FC<{ x: number; y: number; height: number; topCurved?: boolean; bottomCurved?: boolean }> = ({
  x,
  y,
  topCurved = false,
  bottomCurved = false,
  height = 30,
}) => {
  const pathData = useMemo(() => {
    if (topCurved && bottomCurved) {
      return `M 3 0 l 0 ${height} c -3 -2 -3 -4 -3 -6 v -${height - 12} c 0 -1 0 -4 3 -6`;
    }
    if (topCurved && !bottomCurved) {
      return `M 3 0 l 0 ${height} h -3 v -${height - 6} c 0 -1 0 -4 3 -6`;
    }
    if (!topCurved && bottomCurved) {
      return `M 3 0 l 0 ${height} c -3 -2 -3 -4 -3 -6 V 0 h 3`;
    }
    if (!topCurved && !bottomCurved) {
      return `M 3 0 l 0 ${height} h -3 v -${height} h 3`;
    }
  }, [bottomCurved, height, topCurved]);
  return <Path fill={debugActiveColor} data={pathData} x={x} y={y} />;
};
