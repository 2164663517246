// tslint:disable
// @ts-nocheck
/**
 * Reporter REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var AnalyticsChartReportType;
(function (AnalyticsChartReportType) {
    AnalyticsChartReportType["CLIENTSCHART"] = "CLIENTS_CHART";
    AnalyticsChartReportType["SESSIONSCHART"] = "SESSIONS_CHART";
    AnalyticsChartReportType["MESSAGESCHART"] = "MESSAGES_CHART";
    AnalyticsChartReportType["AUTOMATIONCHART"] = "AUTOMATION_CHART";
    AnalyticsChartReportType["NPSCHART"] = "NPS_CHART";
    AnalyticsChartReportType["SESSIONTOPICCHART"] = "SESSION_TOPIC_CHART";
    AnalyticsChartReportType["SESSIONRESULTCHART"] = "SESSION_RESULT_CHART";
    AnalyticsChartReportType["SCENARIOACTIONCHART"] = "SCENARIO_ACTION_CHART";
})(AnalyticsChartReportType || (AnalyticsChartReportType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AnalyticsEntitySort;
(function (AnalyticsEntitySort) {
    AnalyticsEntitySort["ASC"] = "ASC";
    AnalyticsEntitySort["DESC"] = "DESC";
    AnalyticsEntitySort["EDITABLEFIRST"] = "EDITABLE_FIRST";
    AnalyticsEntitySort["NOTEDITABLEFIRST"] = "NOT_EDITABLE_FIRST";
})(AnalyticsEntitySort || (AnalyticsEntitySort = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AnalyticsEntityType;
(function (AnalyticsEntityType) {
    AnalyticsEntityType["SCENARIOACTION"] = "SCENARIO_ACTION";
    AnalyticsEntityType["SESSIONTOPIC"] = "SESSION_TOPIC";
    AnalyticsEntityType["SESSIONRESULT"] = "SESSION_RESULT";
})(AnalyticsEntityType || (AnalyticsEntityType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AnalyticsSection;
(function (AnalyticsSection) {
    AnalyticsSection["CLIENTS"] = "CLIENTS";
    AnalyticsSection["SESSIONS"] = "SESSIONS";
    AnalyticsSection["CLIENTSDIALOGS"] = "CLIENTS_DIALOGS";
    AnalyticsSection["REPORTSACTIVITY"] = "REPORTS_ACTIVITY";
    AnalyticsSection["REPORTSEFFICIENCY"] = "REPORTS_EFFICIENCY";
    AnalyticsSection["REPORTSSESSIONTOPIC"] = "REPORTS_SESSION_TOPIC";
    AnalyticsSection["REPORTSSESSIONRESULT"] = "REPORTS_SESSION_RESULT";
    AnalyticsSection["REPORTSSCENARIOACTION"] = "REPORTS_SCENARIO_ACTION";
})(AnalyticsSection || (AnalyticsSection = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AutomationStatusType;
(function (AutomationStatusType) {
    AutomationStatusType["YES"] = "YES";
    AutomationStatusType["NO"] = "NO";
    AutomationStatusType["UNDEFINED"] = "UNDEFINED";
})(AutomationStatusType || (AutomationStatusType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BillingReportType;
(function (BillingReportType) {
    BillingReportType["NLUBILLING"] = "NLU_BILLING";
    BillingReportType["TELEPHONYBILLING"] = "TELEPHONY_BILLING";
    BillingReportType["ASRBILLING"] = "ASR_BILLING";
    BillingReportType["ASRBILLINGINTERNAL"] = "ASR_BILLING_INTERNAL";
    BillingReportType["ASRBILLINGCUSTOM"] = "ASR_BILLING_CUSTOM";
    BillingReportType["TTSBILLING"] = "TTS_BILLING";
})(BillingReportType || (BillingReportType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CheckboxComparisonType;
(function (CheckboxComparisonType) {
    CheckboxComparisonType["NOTIMPORTANT"] = "NOT_IMPORTANT";
    CheckboxComparisonType["NOTEXISTS"] = "NOT_EXISTS";
    CheckboxComparisonType["EXISTS"] = "EXISTS";
    CheckboxComparisonType["CONTAINS"] = "CONTAINS";
    CheckboxComparisonType["NOTCONTAINS"] = "NOT_CONTAINS";
})(CheckboxComparisonType || (CheckboxComparisonType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ClientType;
(function (ClientType) {
    ClientType["NEW"] = "NEW";
    ClientType["RETURNED"] = "RETURNED";
})(ClientType || (ClientType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CompositeComparisonType;
(function (CompositeComparisonType) {
    CompositeComparisonType["NOTIMPORTANT"] = "NOT_IMPORTANT";
    CompositeComparisonType["CONTAINS"] = "CONTAINS";
    CompositeComparisonType["NOTCONTAINS"] = "NOT_CONTAINS";
    CompositeComparisonType["EQUALS"] = "EQUALS";
    CompositeComparisonType["NOTEQUALS"] = "NOT_EQUALS";
    CompositeComparisonType["STARTSWITH"] = "STARTS_WITH";
    CompositeComparisonType["ENDSWITH"] = "ENDS_WITH";
})(CompositeComparisonType || (CompositeComparisonType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var DateTimeUnit;
(function (DateTimeUnit) {
    DateTimeUnit["SECOND"] = "SECOND";
    DateTimeUnit["MINUTE"] = "MINUTE";
    DateTimeUnit["HOUR"] = "HOUR";
    DateTimeUnit["DAY"] = "DAY";
    DateTimeUnit["WEEK"] = "WEEK";
    DateTimeUnit["MONTH"] = "MONTH";
    DateTimeUnit["QUARTER"] = "QUARTER";
    DateTimeUnit["YEAR"] = "YEAR";
})(DateTimeUnit || (DateTimeUnit = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var FileType;
(function (FileType) {
    FileType["XLSX"] = "XLSX";
    FileType["CSV"] = "CSV";
})(FileType || (FileType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var FilterKey;
(function (FilterKey) {
    FilterKey["SESSIONID"] = "SESSION_ID";
    FilterKey["SESSIONSTARTTIME"] = "SESSION_START_TIME";
    FilterKey["SESSIONENDTIME"] = "SESSION_END_TIME";
    FilterKey["SESSIONLABELS"] = "SESSION_LABELS";
    FilterKey["SESSIONRESULT"] = "SESSION_RESULT";
    FilterKey["INITIATOR"] = "INITIATOR";
    FilterKey["CALLTASKID"] = "CALL_TASK_ID";
    FilterKey["CHANNELTYPE"] = "CHANNEL_TYPE";
    FilterKey["BOTID"] = "BOT_ID";
    FilterKey["CLIENTIDORNAME"] = "CLIENT_ID_OR_NAME";
    FilterKey["CLIENTJOINTIME"] = "CLIENT_JOIN_TIME";
    FilterKey["CLIENTLASTACTIVITYTIME"] = "CLIENT_LAST_ACTIVITY_TIME";
    FilterKey["MESSAGETYPE"] = "MESSAGE_TYPE";
    FilterKey["MESSAGETIME"] = "MESSAGE_TIME";
    FilterKey["MESSAGETEXT"] = "MESSAGE_TEXT";
    FilterKey["ANSWERTEXT"] = "ANSWER_TEXT";
    FilterKey["MESSAGEORANSWERTEXT"] = "MESSAGE_OR_ANSWER_TEXT";
    FilterKey["MESSAGECOUNT"] = "MESSAGE_COUNT";
    FilterKey["LOGLABELS"] = "LOG_LABELS";
    FilterKey["COMMENTTEXT"] = "COMMENT_TEXT";
    FilterKey["NLPCLASS"] = "NLP_CLASS";
    FilterKey["NLPSTATE"] = "NLP_STATE";
    FilterKey["INTENT"] = "INTENT";
    FilterKey["TRANSFERTOOPERATOR"] = "TRANSFER_TO_OPERATOR";
    FilterKey["WITHOUTEMPTYSESSIONS"] = "WITHOUT_EMPTY_SESSIONS";
    FilterKey["WITHOUTEMPTYMESSAGES"] = "WITHOUT_EMPTY_MESSAGES";
    FilterKey["CUSTOMFIELDSINRESPONSE"] = "CUSTOM_FIELDS_IN_RESPONSE";
    FilterKey["EXCEPTIONEXISTENCE"] = "EXCEPTION_EXISTENCE";
    FilterKey["SESSIONDATA"] = "SESSION_DATA";
    FilterKey["STATEROUTES"] = "STATE_ROUTES";
    FilterKey["TEXTCAMPAIGNID"] = "TEXT_CAMPAIGN_ID";
    FilterKey["TEXTCAMPAIGNRESULT"] = "TEXT_CAMPAIGN_RESULT";
    FilterKey["SESSIONTOPIC"] = "SESSION_TOPIC";
    FilterKey["SCENARIOACTION"] = "SCENARIO_ACTION";
    FilterKey["PERIOD"] = "PERIOD";
    FilterKey["CHANNELNAME"] = "CHANNEL_NAME";
    FilterKey["BOTREPLIES"] = "BOT_REPLIES";
    FilterKey["CLIENTTYPE"] = "CLIENT_TYPE";
    FilterKey["AUTOMATIONSTATUS"] = "AUTOMATION_STATUS";
    FilterKey["NPS"] = "NPS";
})(FilterKey || (FilterKey = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var FilterType;
(function (FilterType) {
    FilterType["TEXT"] = "TEXT";
    FilterType["DATETIMERANGE"] = "DATE_TIME_RANGE";
    FilterType["NUMERICRANGE"] = "NUMERIC_RANGE";
    FilterType["CHECKBOX"] = "CHECKBOX";
    FilterType["RADIO"] = "RADIO";
    FilterType["TREE"] = "TREE";
    FilterType["CUSTOMFIELDSINRESPONSE"] = "CUSTOM_FIELDS_IN_RESPONSE";
    FilterType["COMPOSITEFILTER"] = "COMPOSITE_FILTER";
})(FilterType || (FilterType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var InitiatorType;
(function (InitiatorType) {
    InitiatorType["ANY"] = "ANY";
    InitiatorType["CLIENT"] = "CLIENT";
    InitiatorType["BOT"] = "BOT";
})(InitiatorType || (InitiatorType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var IntervalType;
(function (IntervalType) {
    IntervalType["HOUR"] = "HOUR";
    IntervalType["DAY"] = "DAY";
    IntervalType["WEEK"] = "WEEK";
    IntervalType["MONTH"] = "MONTH";
    IntervalType["QUARTER"] = "QUARTER";
    IntervalType["YEAR"] = "YEAR";
})(IntervalType || (IntervalType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["SENT"] = "SENT";
    MessageStatus["DELIVERED"] = "DELIVERED";
    MessageStatus["READ"] = "READ";
    MessageStatus["NOTSENT"] = "NOT_SENT";
    MessageStatus["NOTDELIVERED"] = "NOT_DELIVERED";
})(MessageStatus || (MessageStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ReportGenerationTaskStatus;
(function (ReportGenerationTaskStatus) {
    ReportGenerationTaskStatus["CREATED"] = "CREATED";
    ReportGenerationTaskStatus["STARTED"] = "STARTED";
    ReportGenerationTaskStatus["STOPPED"] = "STOPPED";
    ReportGenerationTaskStatus["COMPLETED"] = "COMPLETED";
    ReportGenerationTaskStatus["FAILED"] = "FAILED";
    ReportGenerationTaskStatus["DELETED"] = "DELETED";
})(ReportGenerationTaskStatus || (ReportGenerationTaskStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ReportType;
(function (ReportType) {
    ReportType["MESSAGES"] = "MESSAGES";
    ReportType["SESSIONS"] = "SESSIONS";
    ReportType["CLIENTS"] = "CLIENTS";
    ReportType["TELEPHONY"] = "TELEPHONY";
    ReportType["DAILY"] = "DAILY";
    ReportType["STATEROUTES"] = "STATE_ROUTES";
    ReportType["CLIENTSLIST"] = "CLIENTS_LIST";
    ReportType["CLIENTSCHART"] = "CLIENTS_CHART";
    ReportType["SESSIONSCHART"] = "SESSIONS_CHART";
    ReportType["MESSAGESCHART"] = "MESSAGES_CHART";
    ReportType["AUTOMATIONCHART"] = "AUTOMATION_CHART";
    ReportType["NPSCHART"] = "NPS_CHART";
    ReportType["SESSIONTOPICCHART"] = "SESSION_TOPIC_CHART";
    ReportType["SESSIONRESULTCHART"] = "SESSION_RESULT_CHART";
    ReportType["SCENARIOACTIONCHART"] = "SCENARIO_ACTION_CHART";
    ReportType["NLUBILLING"] = "NLU_BILLING";
    ReportType["TELEPHONYBILLING"] = "TELEPHONY_BILLING";
    ReportType["ASRBILLING"] = "ASR_BILLING";
    ReportType["ASRBILLINGINTERNAL"] = "ASR_BILLING_INTERNAL";
    ReportType["ASRBILLINGCUSTOM"] = "ASR_BILLING_CUSTOM";
    ReportType["TTSBILLING"] = "TTS_BILLING";
})(ReportType || (ReportType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ScenarioExperimentStatus;
(function (ScenarioExperimentStatus) {
    ScenarioExperimentStatus["CREATED"] = "CREATED";
    ScenarioExperimentStatus["RUNNING"] = "RUNNING";
    ScenarioExperimentStatus["STOPPED"] = "STOPPED";
})(ScenarioExperimentStatus || (ScenarioExperimentStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var SystemMessageType;
(function (SystemMessageType) {
    SystemMessageType["NEWOPERATORCHAT"] = "NEW_OPERATOR_CHAT";
    SystemMessageType["CLIENTMESSAGE"] = "CLIENT_MESSAGE";
    SystemMessageType["OPERATORMESSAGE"] = "OPERATOR_MESSAGE";
    SystemMessageType["BOTMESSAGE"] = "BOT_MESSAGE";
    SystemMessageType["OPERATORJOINED"] = "OPERATOR_JOINED";
    SystemMessageType["OPERATORLEFT"] = "OPERATOR_LEFT";
    SystemMessageType["CLIENTLEFT"] = "CLIENT_LEFT";
    SystemMessageType["TRANSFERTOOPERATORGROUP"] = "TRANSFER_TO_OPERATOR_GROUP";
    SystemMessageType["OPERATORCHATCOMPLETED"] = "OPERATOR_CHAT_COMPLETED";
    SystemMessageType["TRANSFERAFTEROPERATORSILENCE"] = "TRANSFER_AFTER_OPERATOR_SILENCE";
    SystemMessageType["CLIENTBLOCKINGBYOPERATOR"] = "CLIENT_BLOCKING_BY_OPERATOR";
    SystemMessageType["CLIENTUNBLOCKINGBYOPERATOR"] = "CLIENT_UNBLOCKING_BY_OPERATOR";
})(SystemMessageType || (SystemMessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TextComparisonType;
(function (TextComparisonType) {
    TextComparisonType["NOTIMPORTANT"] = "NOT_IMPORTANT";
    TextComparisonType["CONTAINS"] = "CONTAINS";
    TextComparisonType["NOTCONTAINS"] = "NOT_CONTAINS";
    TextComparisonType["EQUALS"] = "EQUALS";
})(TextComparisonType || (TextComparisonType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TransferToOperatorFilterType;
(function (TransferToOperatorFilterType) {
    TransferToOperatorFilterType["ANY"] = "ANY";
    TransferToOperatorFilterType["WITHTRANSFER"] = "WITH_TRANSFER";
    TransferToOperatorFilterType["WITHOUTTRANSFER"] = "WITHOUT_TRANSFER";
})(TransferToOperatorFilterType || (TransferToOperatorFilterType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var WithoutEmptyMessageType;
(function (WithoutEmptyMessageType) {
    WithoutEmptyMessageType["ONLYNOTEMPTY"] = "ONLY_NOT_EMPTY";
    WithoutEmptyMessageType["ONLYEMPTY"] = "ONLY_EMPTY";
    WithoutEmptyMessageType["EMPTYANDNOTEMPTY"] = "EMPTY_AND_NOT_EMPTY";
})(WithoutEmptyMessageType || (WithoutEmptyMessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var YesNoFilterOption;
(function (YesNoFilterOption) {
    YesNoFilterOption["NO"] = "NO";
    YesNoFilterOption["YES"] = "YES";
})(YesNoFilterOption || (YesNoFilterOption = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var YesNoNotImportantFilterOption;
(function (YesNoNotImportantFilterOption) {
    YesNoNotImportantFilterOption["NO"] = "NO";
    YesNoNotImportantFilterOption["YES"] = "YES";
    YesNoNotImportantFilterOption["NOTIMPORTANT"] = "NOT_IMPORTANT";
})(YesNoNotImportantFilterOption || (YesNoNotImportantFilterOption = {}));
/**
 * AdvancedStatsApi - axios parameter creator
 * @export
 */
export const AdvancedStatsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics for bot usage
         * @param {number} accountId Account identifier
         * @param {Array<string>} projectShortNames List of project short names
         * @param {string} startBefore Start before date-time for filtering
         * @param {string} startAfter Start after date-time for filtering
         * @param {string} endAfter End after date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getBotUsageStats.");
            }
            // verify required parameter 'projectShortNames' is not null or undefined
            if (projectShortNames === null || projectShortNames === undefined) {
                throw new RequiredError("projectShortNames", "Required parameter projectShortNames was null or undefined when calling getBotUsageStats.");
            }
            // verify required parameter 'startBefore' is not null or undefined
            if (startBefore === null || startBefore === undefined) {
                throw new RequiredError("startBefore", "Required parameter startBefore was null or undefined when calling getBotUsageStats.");
            }
            // verify required parameter 'startAfter' is not null or undefined
            if (startAfter === null || startAfter === undefined) {
                throw new RequiredError("startAfter", "Required parameter startAfter was null or undefined when calling getBotUsageStats.");
            }
            // verify required parameter 'endAfter' is not null or undefined
            if (endAfter === null || endAfter === undefined) {
                throw new RequiredError("endAfter", "Required parameter endAfter was null or undefined when calling getBotUsageStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/bot-usage`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortNames) {
                localVarQueryParameter["projectShortNames"] = projectShortNames;
            }
            if (startBefore !== undefined) {
                localVarQueryParameter["startBefore"] =
                    startBefore instanceof Date
                        ? startBefore.toISOString().substr(0, 10)
                        : startBefore;
            }
            if (startAfter !== undefined) {
                localVarQueryParameter["startAfter"] =
                    startAfter instanceof Date
                        ? startAfter.toISOString().substr(0, 10)
                        : startAfter;
            }
            if (endAfter !== undefined) {
                localVarQueryParameter["endAfter"] =
                    endAfter instanceof Date
                        ? endAfter.toISOString().substr(0, 10)
                        : endAfter;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics for log labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLogLabelStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/log-labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (step !== undefined) {
                localVarQueryParameter["step"] = step;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of messages
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessageStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/messages/with-advanced-filters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (step !== undefined) {
                localVarQueryParameter["step"] = step;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics for session labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionLabelStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/session-labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (step !== undefined) {
                localVarQueryParameter["step"] = step;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of sessions
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/sessions/with-advanced-filters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (step !== undefined) {
                localVarQueryParameter["step"] = step;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AdvancedStatsApi - functional programming interface
 * @export
 */
export const AdvancedStatsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics for bot usage
         * @param {number} accountId Account identifier
         * @param {Array<string>} projectShortNames List of project short names
         * @param {string} startBefore Start before date-time for filtering
         * @param {string} startAfter Start after date-time for filtering
         * @param {string} endAfter End after date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options) {
            const localVarAxiosArgs = AdvancedStatsApiAxiosParamCreator(configuration).getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics for log labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            const localVarAxiosArgs = AdvancedStatsApiAxiosParamCreator(configuration).getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of messages
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            const localVarAxiosArgs = AdvancedStatsApiAxiosParamCreator(configuration).getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics for session labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            const localVarAxiosArgs = AdvancedStatsApiAxiosParamCreator(configuration).getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of sessions
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            const localVarAxiosArgs = AdvancedStatsApiAxiosParamCreator(configuration).getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AdvancedStatsApi - factory interface
 * @export
 */
export const AdvancedStatsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get statistics for bot usage
         * @param {number} accountId Account identifier
         * @param {Array<string>} projectShortNames List of project short names
         * @param {string} startBefore Start before date-time for filtering
         * @param {string} startAfter Start after date-time for filtering
         * @param {string} endAfter End after date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options) {
            return AdvancedStatsApiFp(configuration).getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics for log labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            return AdvancedStatsApiFp(configuration).getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of messages
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            return AdvancedStatsApiFp(configuration).getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics for session labels
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            return AdvancedStatsApiFp(configuration).getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of sessions
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {number} [step] The step with which statistics will be built.
         * @param {number} [timezone] Timezone offset
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
            return AdvancedStatsApiFp(configuration).getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(axios, basePath);
        },
    };
};
/**
 * AdvancedStatsApi - object-oriented interface
 * @export
 * @class AdvancedStatsApi
 * @extends {BaseAPI}
 */
export class AdvancedStatsApi extends BaseAPI {
    /**
     *
     * @summary Get statistics for bot usage
     * @param {number} accountId Account identifier
     * @param {Array<string>} projectShortNames List of project short names
     * @param {string} startBefore Start before date-time for filtering
     * @param {string} startAfter Start after date-time for filtering
     * @param {string} endAfter End after date-time for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedStatsApi
     */
    getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options) {
        return AdvancedStatsApiFp(this.configuration).getBotUsageStats(accountId, projectShortNames, startBefore, startAfter, endAfter, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics for log labels
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {number} [step] The step with which statistics will be built.
     * @param {number} [timezone] Timezone offset
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedStatsApi
     */
    getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
        return AdvancedStatsApiFp(this.configuration).getLogLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of messages
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {number} [step] The step with which statistics will be built.
     * @param {number} [timezone] Timezone offset
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedStatsApi
     */
    getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
        return AdvancedStatsApiFp(this.configuration).getMessageStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics for session labels
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {number} [step] The step with which statistics will be built.
     * @param {number} [timezone] Timezone offset
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedStatsApi
     */
    getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
        return AdvancedStatsApiFp(this.configuration).getSessionLabelStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of sessions
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {number} [step] The step with which statistics will be built.
     * @param {number} [timezone] Timezone offset
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedStatsApi
     */
    getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options) {
        return AdvancedStatsApiFp(this.configuration).getSessionStats(accountId, projectShortName, intervalType, step, timezone, filtersDto, options)(this.axios, this.basePath);
    }
}
/**
 * AnalyticsEntitiesApi - axios parameter creator
 * @export
 */
export const AnalyticsEntitiesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createScenarioAction.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createScenarioAction.");
            }
            // verify required parameter 'analyticsEntityCreateDto' is not null or undefined
            if (analyticsEntityCreateDto === null ||
                analyticsEntityCreateDto === undefined) {
                throw new RequiredError("analyticsEntityCreateDto", "Required parameter analyticsEntityCreateDto was null or undefined when calling createScenarioAction.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/scenario-actions`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityCreateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityCreateDto !== undefined
                    ? analyticsEntityCreateDto
                    : {})
                : analyticsEntityCreateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createSessionResult.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createSessionResult.");
            }
            // verify required parameter 'analyticsEntityCreateDto' is not null or undefined
            if (analyticsEntityCreateDto === null ||
                analyticsEntityCreateDto === undefined) {
                throw new RequiredError("analyticsEntityCreateDto", "Required parameter analyticsEntityCreateDto was null or undefined when calling createSessionResult.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-results`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityCreateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityCreateDto !== undefined
                    ? analyticsEntityCreateDto
                    : {})
                : analyticsEntityCreateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createSessionTopic.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createSessionTopic.");
            }
            // verify required parameter 'analyticsEntityCreateDto' is not null or undefined
            if (analyticsEntityCreateDto === null ||
                analyticsEntityCreateDto === undefined) {
                throw new RequiredError("analyticsEntityCreateDto", "Required parameter analyticsEntityCreateDto was null or undefined when calling createSessionTopic.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-topics`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityCreateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityCreateDto !== undefined
                    ? analyticsEntityCreateDto
                    : {})
                : analyticsEntityCreateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteScenarioAction.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteScenarioAction.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling deleteScenarioAction.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/scenario-actions/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionResult(accountId, projectShortName, analyticsEntityId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteSessionResult.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteSessionResult.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling deleteSessionResult.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-results/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteSessionTopic.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteSessionTopic.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling deleteSessionTopic.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-topics/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get scenario actions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getScenarioActions.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getScenarioActions.");
            }
            // verify required parameter 'sort' is not null or undefined
            if (sort === null || sort === undefined) {
                throw new RequiredError("sort", "Required parameter sort was null or undefined when calling getScenarioActions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/scenario-actions`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (keyOrValue !== undefined) {
                localVarQueryParameter["keyOrValue"] = keyOrValue;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session results
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionResults.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getSessionResults.");
            }
            // verify required parameter 'sort' is not null or undefined
            if (sort === null || sort === undefined) {
                throw new RequiredError("sort", "Required parameter sort was null or undefined when calling getSessionResults.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-results`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (keyOrValue !== undefined) {
                localVarQueryParameter["keyOrValue"] = keyOrValue;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session topics
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionTopics.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getSessionTopics.");
            }
            // verify required parameter 'sort' is not null or undefined
            if (sort === null || sort === undefined) {
                throw new RequiredError("sort", "Required parameter sort was null or undefined when calling getSessionTopics.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-topics`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (keyOrValue !== undefined) {
                localVarQueryParameter["keyOrValue"] = keyOrValue;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateScenarioAction.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling updateScenarioAction.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling updateScenarioAction.");
            }
            // verify required parameter 'analyticsEntityUpdateDto' is not null or undefined
            if (analyticsEntityUpdateDto === null ||
                analyticsEntityUpdateDto === undefined) {
                throw new RequiredError("analyticsEntityUpdateDto", "Required parameter analyticsEntityUpdateDto was null or undefined when calling updateScenarioAction.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/scenario-actions/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityUpdateDto !== undefined
                    ? analyticsEntityUpdateDto
                    : {})
                : analyticsEntityUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionResult.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling updateSessionResult.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling updateSessionResult.");
            }
            // verify required parameter 'analyticsEntityUpdateDto' is not null or undefined
            if (analyticsEntityUpdateDto === null ||
                analyticsEntityUpdateDto === undefined) {
                throw new RequiredError("analyticsEntityUpdateDto", "Required parameter analyticsEntityUpdateDto was null or undefined when calling updateSessionResult.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-results/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityUpdateDto !== undefined
                    ? analyticsEntityUpdateDto
                    : {})
                : analyticsEntityUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionTopic.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling updateSessionTopic.");
            }
            // verify required parameter 'analyticsEntityId' is not null or undefined
            if (analyticsEntityId === null || analyticsEntityId === undefined) {
                throw new RequiredError("analyticsEntityId", "Required parameter analyticsEntityId was null or undefined when calling updateSessionTopic.");
            }
            // verify required parameter 'analyticsEntityUpdateDto' is not null or undefined
            if (analyticsEntityUpdateDto === null ||
                analyticsEntityUpdateDto === undefined) {
                throw new RequiredError("analyticsEntityUpdateDto", "Required parameter analyticsEntityUpdateDto was null or undefined when calling updateSessionTopic.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/{projectShortName}/analytics-entities/session-topics/{analyticsEntityId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)))
                .replace(`{${"analyticsEntityId"}}`, encodeURIComponent(String(analyticsEntityId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEntityUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEntityUpdateDto !== undefined
                    ? analyticsEntityUpdateDto
                    : {})
                : analyticsEntityUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AnalyticsEntitiesApi - functional programming interface
 * @export
 */
export const AnalyticsEntitiesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionResult(accountId, projectShortName, analyticsEntityId, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).deleteSessionResult(accountId, projectShortName, analyticsEntityId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get scenario actions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session results
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session topics
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            const localVarAxiosArgs = AnalyticsEntitiesApiAxiosParamCreator(configuration).updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AnalyticsEntitiesApi - factory interface
 * @export
 */
export const AnalyticsEntitiesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options) {
            return AnalyticsEntitiesApiFp(configuration).deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionResult(accountId, projectShortName, analyticsEntityId, options) {
            return AnalyticsEntitiesApiFp(configuration).deleteSessionResult(accountId, projectShortName, analyticsEntityId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options) {
            return AnalyticsEntitiesApiFp(configuration).deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get scenario actions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            return AnalyticsEntitiesApiFp(configuration).getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session results
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            return AnalyticsEntitiesApiFp(configuration).getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session topics
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {AnalyticsEntitySort} sort
         * @param {string} [keyOrValue]
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options) {
            return AnalyticsEntitiesApiFp(configuration).getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Update scenario action
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session result
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session topic
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} analyticsEntityId
         * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
            return AnalyticsEntitiesApiFp(configuration).updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(axios, basePath);
        },
    };
};
/**
 * AnalyticsEntitiesApi - object-oriented interface
 * @export
 * @class AnalyticsEntitiesApi
 * @extends {BaseAPI}
 */
export class AnalyticsEntitiesApi extends BaseAPI {
    /**
     *
     * @summary Create scenario action
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).createScenarioAction(accountId, projectShortName, analyticsEntityCreateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create session result
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).createSessionResult(accountId, projectShortName, analyticsEntityCreateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create session topic
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntityCreateDto} analyticsEntityCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).createSessionTopic(accountId, projectShortName, analyticsEntityCreateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete scenario action
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options) {
        return AnalyticsEntitiesApiFp(this.configuration).deleteScenarioAction(accountId, projectShortName, analyticsEntityId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete session result
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    deleteSessionResult(accountId, projectShortName, analyticsEntityId, options) {
        return AnalyticsEntitiesApiFp(this.configuration).deleteSessionResult(accountId, projectShortName, analyticsEntityId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete session topic
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options) {
        return AnalyticsEntitiesApiFp(this.configuration).deleteSessionTopic(accountId, projectShortName, analyticsEntityId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get scenario actions
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntitySort} sort
     * @param {string} [keyOrValue]
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options) {
        return AnalyticsEntitiesApiFp(this.configuration).getScenarioActions(accountId, projectShortName, sort, keyOrValue, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session results
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntitySort} sort
     * @param {string} [keyOrValue]
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options) {
        return AnalyticsEntitiesApiFp(this.configuration).getSessionResults(accountId, projectShortName, sort, keyOrValue, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session topics
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {AnalyticsEntitySort} sort
     * @param {string} [keyOrValue]
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options) {
        return AnalyticsEntitiesApiFp(this.configuration).getSessionTopics(accountId, projectShortName, sort, keyOrValue, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update scenario action
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).updateScenarioAction(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update session result
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).updateSessionResult(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update session topic
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} analyticsEntityId
     * @param {AnalyticsEntityUpdateDto} analyticsEntityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEntitiesApi
     */
    updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options) {
        return AnalyticsEntitiesApiFp(this.configuration).updateSessionTopic(accountId, projectShortName, analyticsEntityId, analyticsEntityUpdateDto, options)(this.axios, this.basePath);
    }
}
/**
 * AnalyticsTokenApi - axios parameter creator
 * @export
 */
export const AnalyticsTokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsToken(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createAnalyticsToken.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createAnalyticsToken.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/tokens`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createAnalyticsTokenByToken.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createAnalyticsTokenByToken.");
            }
            // verify required parameter 'analyticsToken' is not null or undefined
            if (analyticsToken === null || analyticsToken === undefined) {
                throw new RequiredError("analyticsToken", "Required parameter analyticsToken was null or undefined when calling createAnalyticsTokenByToken.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/tokens/{analyticsToken}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"analyticsToken"}}`, encodeURIComponent(String(analyticsToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsToken(accountId, analyticsToken, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteAnalyticsToken.");
            }
            // verify required parameter 'analyticsToken' is not null or undefined
            if (analyticsToken === null || analyticsToken === undefined) {
                throw new RequiredError("analyticsToken", "Required parameter analyticsToken was null or undefined when calling deleteAnalyticsToken.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/tokens/{analyticsToken}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"analyticsToken"}}`, encodeURIComponent(String(analyticsToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteAnalyticsTokenByProjectShortName.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteAnalyticsTokenByProjectShortName.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/tokens/by-project`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsToken(analyticsToken, options = {}) {
            // verify required parameter 'analyticsToken' is not null or undefined
            if (analyticsToken === null || analyticsToken === undefined) {
                throw new RequiredError("analyticsToken", "Required parameter analyticsToken was null or undefined when calling getAnalyticsToken.");
            }
            const localVarPath = `/api/reporter/tokens/{analyticsToken}`.replace(`{${"analyticsToken"}}`, encodeURIComponent(String(analyticsToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTokenByProjectShortName(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAnalyticsTokenByProjectShortName.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getAnalyticsTokenByProjectShortName.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/tokens/by-project`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AnalyticsTokenApi - functional programming interface
 * @export
 */
export const AnalyticsTokenApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsToken(accountId, projectShortName, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).createAnalyticsToken(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsToken(accountId, analyticsToken, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).deleteAnalyticsToken(accountId, analyticsToken, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsToken(analyticsToken, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).getAnalyticsToken(analyticsToken, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
            const localVarAxiosArgs = AnalyticsTokenApiAxiosParamCreator(configuration).getAnalyticsTokenByProjectShortName(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AnalyticsTokenApi - factory interface
 * @export
 */
export const AnalyticsTokenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsToken(accountId, projectShortName, options) {
            return AnalyticsTokenApiFp(configuration).createAnalyticsToken(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Create an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options) {
            return AnalyticsTokenApiFp(configuration).createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options)(axios, basePath);
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {number} accountId Account identifier
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsToken(accountId, analyticsToken, options) {
            return AnalyticsTokenApiFp(configuration).deleteAnalyticsToken(accountId, analyticsToken, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
            return AnalyticsTokenApiFp(configuration).deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get an analytics access token
         * @param {string} analyticsToken Analytics token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsToken(analyticsToken, options) {
            return AnalyticsTokenApiFp(configuration).getAnalyticsToken(analyticsToken, options)(axios, basePath);
        },
        /**
         *
         * @summary Get an analytics access token by projectShortName
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
            return AnalyticsTokenApiFp(configuration).getAnalyticsTokenByProjectShortName(accountId, projectShortName, options)(axios, basePath);
        },
    };
};
/**
 * AnalyticsTokenApi - object-oriented interface
 * @export
 * @class AnalyticsTokenApi
 * @extends {BaseAPI}
 */
export class AnalyticsTokenApi extends BaseAPI {
    /**
     *
     * @summary Create an analytics access token
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    createAnalyticsToken(accountId, projectShortName, options) {
        return AnalyticsTokenApiFp(this.configuration).createAnalyticsToken(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create an analytics access token
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} analyticsToken Analytics token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options) {
        return AnalyticsTokenApiFp(this.configuration).createAnalyticsTokenByToken(accountId, projectShortName, analyticsToken, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get an analytics access token
     * @param {number} accountId Account identifier
     * @param {string} analyticsToken Analytics token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    deleteAnalyticsToken(accountId, analyticsToken, options) {
        return AnalyticsTokenApiFp(this.configuration).deleteAnalyticsToken(accountId, analyticsToken, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete an analytics access token by projectShortName
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
        return AnalyticsTokenApiFp(this.configuration).deleteAnalyticsTokenByProjectShortName(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get an analytics access token
     * @param {string} analyticsToken Analytics token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    getAnalyticsToken(analyticsToken, options) {
        return AnalyticsTokenApiFp(this.configuration).getAnalyticsToken(analyticsToken, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get an analytics access token by projectShortName
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsTokenApi
     */
    getAnalyticsTokenByProjectShortName(accountId, projectShortName, options) {
        return AnalyticsTokenApiFp(this.configuration).getAnalyticsTokenByProjectShortName(accountId, projectShortName, options)(this.axios, this.basePath);
    }
}
/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Block client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {BlockClientDto} blockClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockClient(accountId, botId, clientId, blockClientDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling blockClient.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling blockClient.");
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling blockClient.");
            }
            // verify required parameter 'blockClientDto' is not null or undefined
            if (blockClientDto === null || blockClientDto === undefined) {
                throw new RequiredError("blockClientDto", "Required parameter blockClientDto was null or undefined when calling blockClient.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/clients/block`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter["clientId"] = clientId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof blockClientDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(blockClientDto !== undefined ? blockClientDto : {})
                : blockClientDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all clients by bot id and project short name
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllClientDataForBot.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getAllClientDataForBot.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getAllClientDataForBot.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getAllClientDataForBot.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/clients/all-for-bot`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get clients by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getClientDataByFilter.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/clients/filter`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Unblock client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockClient(accountId, botId, clientId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling unblockClient.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling unblockClient.");
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling unblockClient.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/clients/unblock`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter["clientId"] = clientId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Block client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {BlockClientDto} blockClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockClient(accountId, botId, clientId, blockClientDto, options) {
            const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).blockClient(accountId, botId, clientId, blockClientDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all clients by bot id and project short name
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options) {
            const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get clients by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Unblock client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockClient(accountId, botId, clientId, options) {
            const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).unblockClient(accountId, botId, clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Block client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {BlockClientDto} blockClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockClient(accountId, botId, clientId, blockClientDto, options) {
            return ClientsApiFp(configuration).blockClient(accountId, botId, clientId, blockClientDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all clients by bot id and project short name
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options) {
            return ClientsApiFp(configuration).getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Get clients by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            return ClientsApiFp(configuration).getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Unblock client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockClient(accountId, botId, clientId, options) {
            return ClientsApiFp(configuration).unblockClient(accountId, botId, clientId, options)(axios, basePath);
        },
    };
};
/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     *
     * @summary Block client
     * @param {number} accountId Account identifier
     * @param {string} botId Natural bot id
     * @param {string} clientId Client id
     * @param {BlockClientDto} blockClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    blockClient(accountId, botId, clientId, blockClientDto, options) {
        return ClientsApiFp(this.configuration).blockClient(accountId, botId, clientId, blockClientDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get all clients by bot id and project short name
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} botId Natural bot id
     * @param {Date} dateTo The date-time by which the report will be built.
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options) {
        return ClientsApiFp(this.configuration).getAllClientDataForBot(accountId, projectShortName, botId, dateTo, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get clients by filter
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
        return ClientsApiFp(this.configuration).getClientDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Unblock client
     * @param {number} accountId Account identifier
     * @param {string} botId Natural bot id
     * @param {string} clientId Client id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    unblockClient(accountId, botId, clientId, options) {
        return ClientsApiFp(this.configuration).unblockClient(accountId, botId, clientId, options)(this.axios, this.basePath);
    }
}
/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add comment to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {AddCommentDto} addCommentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToMessage(accountId, questionId, addCommentDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addCommentToMessage.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling addCommentToMessage.");
            }
            // verify required parameter 'addCommentDto' is not null or undefined
            if (addCommentDto === null || addCommentDto === undefined) {
                throw new RequiredError("addCommentDto", "Required parameter addCommentDto was null or undefined when calling addCommentToMessage.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/comments/{questionId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof addCommentDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(addCommentDto !== undefined ? addCommentDto : {})
                : addCommentDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete comment from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentFromMessage(accountId, questionId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteCommentFromMessage.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling deleteCommentFromMessage.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/comments/{questionId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Add comment to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {AddCommentDto} addCommentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToMessage(accountId, questionId, addCommentDto, options) {
            const localVarAxiosArgs = CommentsApiAxiosParamCreator(configuration).addCommentToMessage(accountId, questionId, addCommentDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete comment from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentFromMessage(accountId, questionId, options) {
            const localVarAxiosArgs = CommentsApiAxiosParamCreator(configuration).deleteCommentFromMessage(accountId, questionId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Add comment to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {AddCommentDto} addCommentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToMessage(accountId, questionId, addCommentDto, options) {
            return CommentsApiFp(configuration).addCommentToMessage(accountId, questionId, addCommentDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete comment from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentFromMessage(accountId, questionId, options) {
            return CommentsApiFp(configuration).deleteCommentFromMessage(accountId, questionId, options)(axios, basePath);
        },
    };
};
/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     *
     * @summary Add comment to message
     * @param {number} accountId Account identifier
     * @param {string} questionId Question identifier
     * @param {AddCommentDto} addCommentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    addCommentToMessage(accountId, questionId, addCommentDto, options) {
        return CommentsApiFp(this.configuration).addCommentToMessage(accountId, questionId, addCommentDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete comment from message
     * @param {number} accountId Account identifier
     * @param {string} questionId Question identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    deleteCommentFromMessage(accountId, questionId, options) {
        return CommentsApiFp(this.configuration).deleteCommentFromMessage(accountId, questionId, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         *
         * @summary end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     *
     * @summary end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
    }
}
/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get filterSetId by user filter set
         * @param {number} accountId Account identifier
         * @param {FiltersDto} filtersDto
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createFilterSetId.");
            }
            // verify required parameter 'filtersDto' is not null or undefined
            if (filtersDto === null || filtersDto === undefined) {
                throw new RequiredError("filtersDto", "Required parameter filtersDto was null or undefined when calling createFilterSetId.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/user-filter-sets/filterSetId`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, analyticsSection, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAvailableFilters.");
            }
            // verify required parameter 'analyticsSection' is not null or undefined
            if (analyticsSection === null || analyticsSection === undefined) {
                throw new RequiredError("analyticsSection", "Required parameter analyticsSection was null or undefined when calling getAvailableFilters.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/filters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (analyticsSection !== undefined) {
                localVarQueryParameter["analyticsSection"] = analyticsSection;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getFilterOptions.");
            }
            // verify required parameter 'filterKey' is not null or undefined
            if (filterKey === null || filterKey === undefined) {
                throw new RequiredError("filterKey", "Required parameter filterKey was null or undefined when calling getFilterOptions.");
            }
            // verify required parameter 'analyticsSection' is not null or undefined
            if (analyticsSection === null || analyticsSection === undefined) {
                throw new RequiredError("analyticsSection", "Required parameter analyticsSection was null or undefined when calling getFilterOptions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/filter-options`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (filterKey !== undefined) {
                localVarQueryParameter["filterKey"] = filterKey;
            }
            if (analyticsSection !== undefined) {
                localVarQueryParameter["analyticsSection"] = analyticsSection;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get interval filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getIntervalFilterOptions.");
            }
            // verify required parameter 'filterKey' is not null or undefined
            if (filterKey === null || filterKey === undefined) {
                throw new RequiredError("filterKey", "Required parameter filterKey was null or undefined when calling getIntervalFilterOptions.");
            }
            // verify required parameter 'analyticsSection' is not null or undefined
            if (analyticsSection === null || analyticsSection === undefined) {
                throw new RequiredError("analyticsSection", "Required parameter analyticsSection was null or undefined when calling getIntervalFilterOptions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/interval-filter-options`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (filterKey !== undefined) {
                localVarQueryParameter["filterKey"] = filterKey;
            }
            if (analyticsSection !== undefined) {
                localVarQueryParameter["analyticsSection"] = analyticsSection;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get user filter set
         * @param {number} accountId Account identifier
         * @param {string} filterSetId User filter set id
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFilterSet(accountId, filterSetId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getUserFilterSet.");
            }
            // verify required parameter 'filterSetId' is not null or undefined
            if (filterSetId === null || filterSetId === undefined) {
                throw new RequiredError("filterSetId", "Required parameter filterSetId was null or undefined when calling getUserFilterSet.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/user-filter-sets/{filterSetId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"filterSetId"}}`, encodeURIComponent(String(filterSetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get filterSetId by user filter set
         * @param {number} accountId Account identifier
         * @param {FiltersDto} filtersDto
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, analyticsSection, language, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getAvailableFilters(accountId, analyticsSection, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get interval filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get user filter set
         * @param {number} accountId Account identifier
         * @param {string} filterSetId User filter set id
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFilterSet(accountId, filterSetId, projectShortName, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getUserFilterSet(accountId, filterSetId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get filterSetId by user filter set
         * @param {number} accountId Account identifier
         * @param {FiltersDto} filtersDto
         * @param {string} [projectShortName] Project short name
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options) {
            return FiltersApiFp(configuration).createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options)(axios, basePath);
        },
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, analyticsSection, language, options) {
            return FiltersApiFp(configuration).getAvailableFilters(accountId, analyticsSection, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options) {
            return FiltersApiFp(configuration).getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get interval filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {AnalyticsSection} analyticsSection analytics section
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options) {
            return FiltersApiFp(configuration).getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get user filter set
         * @param {number} accountId Account identifier
         * @param {string} filterSetId User filter set id
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFilterSet(accountId, filterSetId, projectShortName, options) {
            return FiltersApiFp(configuration).getUserFilterSet(accountId, filterSetId, projectShortName, options)(axios, basePath);
        },
    };
};
/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
    /**
     *
     * @summary Get filterSetId by user filter set
     * @param {number} accountId Account identifier
     * @param {FiltersDto} filtersDto
     * @param {string} [projectShortName] Project short name
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options) {
        return FiltersApiFp(this.configuration).createFilterSetId(accountId, filtersDto, projectShortName, intervalType, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get available filters
     * @param {number} accountId Account identifier
     * @param {AnalyticsSection} analyticsSection analytics section
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getAvailableFilters(accountId, analyticsSection, language, options) {
        return FiltersApiFp(this.configuration).getAvailableFilters(accountId, analyticsSection, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {AnalyticsSection} analyticsSection analytics section
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options) {
        return FiltersApiFp(this.configuration).getFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, page, size, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get interval filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {AnalyticsSection} analyticsSection analytics section
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options) {
        return FiltersApiFp(this.configuration).getIntervalFilterOptions(accountId, filterKey, analyticsSection, projectShortName, language, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get user filter set
     * @param {number} accountId Account identifier
     * @param {string} filterSetId User filter set id
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getUserFilterSet(accountId, filterSetId, projectShortName, options) {
        return FiltersApiFp(this.configuration).getUserFilterSet(accountId, filterSetId, projectShortName, options)(this.axios, this.basePath);
    }
}
/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add label to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToMessage(accountId, questionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addLabelToMessage.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling addLabelToMessage.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling addLabelToMessage.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/logs`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Add label to session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToSession(accountId, sessionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addLabelToSession.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling addLabelToSession.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling addLabelToSession.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/session-labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create message label
         * @param {number} accountId Account identifier
         * @param {MessageLabelCreate} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabel(accountId, messageLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createMessageLabel.");
            }
            // verify required parameter 'messageLabelCreate' is not null or undefined
            if (messageLabelCreate === null || messageLabelCreate === undefined) {
                throw new RequiredError("messageLabelCreate", "Required parameter messageLabelCreate was null or undefined when calling createMessageLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof messageLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(messageLabelCreate !== undefined ? messageLabelCreate : {})
                : messageLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create message labels
         * @param {number} accountId Account identifier
         * @param {Array<MessageLabelCreate>} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabels(accountId, messageLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createMessageLabels.");
            }
            // verify required parameter 'messageLabelCreate' is not null or undefined
            if (messageLabelCreate === null || messageLabelCreate === undefined) {
                throw new RequiredError("messageLabelCreate", "Required parameter messageLabelCreate was null or undefined when calling createMessageLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/by-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof messageLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(messageLabelCreate !== undefined ? messageLabelCreate : {})
                : messageLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createSessionLabel.");
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError("sessionLabelCreate", "Required parameter sessionLabelCreate was null or undefined when calling createSessionLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sessionLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete label from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromMessage(accountId, questionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteLabelFromMessage.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling deleteLabelFromMessage.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling deleteLabelFromMessage.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/logs`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete label from session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromSession(accountId, sessionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteLabelFromSession.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling deleteLabelFromSession.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling deleteLabelFromSession.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/session-labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteMessageLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteMessageLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabelGroup(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteMessageLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteMessageLabelGroup.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteSessionLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getLabelGroup.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabelGroups.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get message label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessageLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getMessageLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get message labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabels(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessageLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getSessionLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set label to messages
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToMessages(accountId, bulkLabelingDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling setLabelToMessages.");
            }
            // verify required parameter 'bulkLabelingDto' is not null or undefined
            if (bulkLabelingDto === null || bulkLabelingDto === undefined) {
                throw new RequiredError("bulkLabelingDto", "Required parameter bulkLabelingDto was null or undefined when calling setLabelToMessages.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/logs/bulk-labeling`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof bulkLabelingDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(bulkLabelingDto !== undefined ? bulkLabelingDto : {})
                : bulkLabelingDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set label to sessions
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToSessions(accountId, bulkLabelingDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling setLabelToSessions.");
            }
            // verify required parameter 'bulkLabelingDto' is not null or undefined
            if (bulkLabelingDto === null || bulkLabelingDto === undefined) {
                throw new RequiredError("bulkLabelingDto", "Required parameter bulkLabelingDto was null or undefined when calling setLabelToSessions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/session-labels/sessions/bulk-labeling`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof bulkLabelingDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(bulkLabelingDto !== undefined ? bulkLabelingDto : {})
                : bulkLabelingDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set message labels
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessageLabels(accountId, questionId, sessionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling setMessageLabels.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling setMessageLabels.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling setMessageLabels.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling setMessageLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/logs/set-by-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set session labels
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSessionLabels(accountId, sessionId, labelIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling setSessionLabels.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling setSessionLabels.");
            }
            // verify required parameter 'labelIdDto' is not null or undefined
            if (labelIdDto === null || labelIdDto === undefined) {
                throw new RequiredError("labelIdDto", "Required parameter labelIdDto was null or undefined when calling setSessionLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/session-labels/sessions/set-by-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelIdDto !== undefined ? labelIdDto : {})
                : labelIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateGroupsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateGroupsPriority.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {MessageLabelUpdate} messageLabelUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabel(accountId, id, messageLabelUpdate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateMessageLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateMessageLabel.");
            }
            // verify required parameter 'messageLabelUpdate' is not null or undefined
            if (messageLabelUpdate === null || messageLabelUpdate === undefined) {
                throw new RequiredError("messageLabelUpdate", "Required parameter messageLabelUpdate was null or undefined when calling updateMessageLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof messageLabelUpdate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(messageLabelUpdate !== undefined ? messageLabelUpdate : {})
                : messageLabelUpdate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update message label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateMessageLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateMessageLabelGroup.");
            }
            // verify required parameter 'logLabelGroupCreate' is not null or undefined
            if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
                throw new RequiredError("logLabelGroupCreate", "Required parameter logLabelGroupCreate was null or undefined when calling updateMessageLabelGroup.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof logLabelGroupCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
                : logLabelGroupCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update message label groups
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelGroupDto>} updateMessageLabelGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateMessageLabelGroups.");
            }
            // verify required parameter 'updateMessageLabelGroupDto' is not null or undefined
            if (updateMessageLabelGroupDto === null ||
                updateMessageLabelGroupDto === undefined) {
                throw new RequiredError("updateMessageLabelGroupDto", "Required parameter updateMessageLabelGroupDto was null or undefined when calling updateMessageLabelGroups.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateMessageLabelGroupDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateMessageLabelGroupDto !== undefined
                    ? updateMessageLabelGroupDto
                    : {})
                : updateMessageLabelGroupDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelDto>} updateMessageLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabels(accountId, updateMessageLabelDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateMessageLabels.");
            }
            // verify required parameter 'updateMessageLabelDto' is not null or undefined
            if (updateMessageLabelDto === null ||
                updateMessageLabelDto === undefined) {
                throw new RequiredError("updateMessageLabelDto", "Required parameter updateMessageLabelDto was null or undefined when calling updateMessageLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/by-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateMessageLabelDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateMessageLabelDto !== undefined ? updateMessageLabelDto : {})
                : updateMessageLabelDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update message labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateMessageLabelsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateMessageLabelsPriority.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/message/labels/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateSessionLabel.");
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError("sessionLabelCreate", "Required parameter sessionLabelCreate was null or undefined when calling updateSessionLabel.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sessionLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateSessionLabelDto>} updateSessionLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabels(accountId, updateSessionLabelDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionLabels.");
            }
            // verify required parameter 'updateSessionLabelDto' is not null or undefined
            if (updateSessionLabelDto === null ||
                updateSessionLabelDto === undefined) {
                throw new RequiredError("updateSessionLabelDto", "Required parameter updateSessionLabelDto was null or undefined when calling updateSessionLabels.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/session-labels/by-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateSessionLabelDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateSessionLabelDto !== undefined ? updateSessionLabelDto : {})
                : updateSessionLabelDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateSessionsPriority.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/logs/labels/sessions/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Add label to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToMessage(accountId, questionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).addLabelToMessage(accountId, questionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Add label to session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToSession(accountId, sessionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).addLabelToSession(accountId, sessionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create message label
         * @param {number} accountId Account identifier
         * @param {MessageLabelCreate} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabel(accountId, messageLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createMessageLabel(accountId, messageLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create message labels
         * @param {number} accountId Account identifier
         * @param {Array<MessageLabelCreate>} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabels(accountId, messageLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createMessageLabels(accountId, messageLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createSessionLabel(accountId, sessionLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete label from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromMessage(accountId, questionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabelFromMessage(accountId, questionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete label from session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromSession(accountId, sessionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabelFromSession(accountId, sessionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteMessageLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabelGroup(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteMessageLabelGroup(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteSessionLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroup(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroups(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get message label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getMessageLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get message labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabels(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getMessageLabels(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabels(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Set label to messages
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToMessages(accountId, bulkLabelingDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).setLabelToMessages(accountId, bulkLabelingDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Set label to sessions
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToSessions(accountId, bulkLabelingDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).setLabelToSessions(accountId, bulkLabelingDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Set message labels
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessageLabels(accountId, questionId, sessionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).setMessageLabels(accountId, questionId, sessionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Set session labels
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSessionLabels(accountId, sessionId, labelIdDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).setSessionLabels(accountId, sessionId, labelIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {MessageLabelUpdate} messageLabelUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabel(accountId, id, messageLabelUpdate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateMessageLabel(accountId, id, messageLabelUpdate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update message label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update message label groups
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelGroupDto>} updateMessageLabelGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelDto>} updateMessageLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabels(accountId, updateMessageLabelDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateMessageLabels(accountId, updateMessageLabelDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update message labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateSessionLabelDto>} updateSessionLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabels(accountId, updateSessionLabelDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionLabels(accountId, updateSessionLabelDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Add label to message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToMessage(accountId, questionId, labelIdDto, options) {
            return LabelsApiFp(configuration).addLabelToMessage(accountId, questionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Add label to session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToSession(accountId, sessionId, labelIdDto, options) {
            return LabelsApiFp(configuration).addLabelToSession(accountId, sessionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create message label
         * @param {number} accountId Account identifier
         * @param {MessageLabelCreate} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabel(accountId, messageLabelCreate, options) {
            return LabelsApiFp(configuration).createMessageLabel(accountId, messageLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create message labels
         * @param {number} accountId Account identifier
         * @param {Array<MessageLabelCreate>} messageLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageLabels(accountId, messageLabelCreate, options) {
            return LabelsApiFp(configuration).createMessageLabels(accountId, messageLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).createSessionLabel(accountId, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete label from message
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromMessage(accountId, questionId, labelIdDto, options) {
            return LabelsApiFp(configuration).deleteLabelFromMessage(accountId, questionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete label from session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {LabelIdDto} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromSession(accountId, sessionId, labelIdDto, options) {
            return LabelsApiFp(configuration).deleteLabelFromSession(accountId, sessionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabel(accountId, id, options) {
            return LabelsApiFp(configuration).deleteMessageLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageLabelGroup(accountId, id, options) {
            return LabelsApiFp(configuration).deleteMessageLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options) {
            return LabelsApiFp(configuration).deleteSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options) {
            return LabelsApiFp(configuration).getLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options) {
            return LabelsApiFp(configuration).getLabelGroups(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get message label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabel(accountId, id, options) {
            return LabelsApiFp(configuration).getMessageLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get message labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageLabels(accountId, options) {
            return LabelsApiFp(configuration).getMessageLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options) {
            return LabelsApiFp(configuration).getSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options) {
            return LabelsApiFp(configuration).getSessionLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Set label to messages
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToMessages(accountId, bulkLabelingDto, options) {
            return LabelsApiFp(configuration).setLabelToMessages(accountId, bulkLabelingDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Set label to sessions
         * @param {number} accountId Account identifier
         * @param {BulkLabelingDto} bulkLabelingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLabelToSessions(accountId, bulkLabelingDto, options) {
            return LabelsApiFp(configuration).setLabelToSessions(accountId, bulkLabelingDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Set message labels
         * @param {number} accountId Account identifier
         * @param {string} questionId Question identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessageLabels(accountId, questionId, sessionId, labelIdDto, options) {
            return LabelsApiFp(configuration).setMessageLabels(accountId, questionId, sessionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Set session labels
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {Array<LabelIdDto>} labelIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSessionLabels(accountId, sessionId, labelIdDto, options) {
            return LabelsApiFp(configuration).setSessionLabels(accountId, sessionId, labelIdDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {MessageLabelUpdate} messageLabelUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabel(accountId, id, messageLabelUpdate, options) {
            return LabelsApiFp(configuration).updateMessageLabel(accountId, id, messageLabelUpdate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update message label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options) {
            return LabelsApiFp(configuration).updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update message label groups
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelGroupDto>} updateMessageLabelGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options) {
            return LabelsApiFp(configuration).updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update message labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateMessageLabelDto>} updateMessageLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabels(accountId, updateMessageLabelDto, options) {
            return LabelsApiFp(configuration).updateMessageLabels(accountId, updateMessageLabelDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update message labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session labels
         * @param {number} accountId Account identifier
         * @param {Array<UpdateSessionLabelDto>} updateSessionLabelDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabels(accountId, updateSessionLabelDto, options) {
            return LabelsApiFp(configuration).updateSessionLabels(accountId, updateSessionLabelDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
    };
};
/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
    /**
     *
     * @summary Add label to message
     * @param {number} accountId Account identifier
     * @param {string} questionId Question identifier
     * @param {LabelIdDto} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    addLabelToMessage(accountId, questionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).addLabelToMessage(accountId, questionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Add label to session
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {LabelIdDto} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    addLabelToSession(accountId, sessionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).addLabelToSession(accountId, sessionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create message label
     * @param {number} accountId Account identifier
     * @param {MessageLabelCreate} messageLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createMessageLabel(accountId, messageLabelCreate, options) {
        return LabelsApiFp(this.configuration).createMessageLabel(accountId, messageLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create message labels
     * @param {number} accountId Account identifier
     * @param {Array<MessageLabelCreate>} messageLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createMessageLabels(accountId, messageLabelCreate, options) {
        return LabelsApiFp(this.configuration).createMessageLabels(accountId, messageLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createSessionLabel(accountId, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).createSessionLabel(accountId, sessionLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete label from message
     * @param {number} accountId Account identifier
     * @param {string} questionId Question identifier
     * @param {LabelIdDto} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteLabelFromMessage(accountId, questionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).deleteLabelFromMessage(accountId, questionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete label from session
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {LabelIdDto} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteLabelFromSession(accountId, sessionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).deleteLabelFromSession(accountId, sessionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete message labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteMessageLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteMessageLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteMessageLabelGroup(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteMessageLabelGroup(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteSessionLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteSessionLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabelGroup(accountId, id, options) {
        return LabelsApiFp(this.configuration).getLabelGroup(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabelGroups(accountId, options) {
        return LabelsApiFp(this.configuration).getLabelGroups(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get message label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getMessageLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).getMessageLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get message labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getMessageLabels(accountId, options) {
        return LabelsApiFp(this.configuration).getMessageLabels(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getSessionLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).getSessionLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getSessionLabels(accountId, options) {
        return LabelsApiFp(this.configuration).getSessionLabels(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Set label to messages
     * @param {number} accountId Account identifier
     * @param {BulkLabelingDto} bulkLabelingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    setLabelToMessages(accountId, bulkLabelingDto, options) {
        return LabelsApiFp(this.configuration).setLabelToMessages(accountId, bulkLabelingDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Set label to sessions
     * @param {number} accountId Account identifier
     * @param {BulkLabelingDto} bulkLabelingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    setLabelToSessions(accountId, bulkLabelingDto, options) {
        return LabelsApiFp(this.configuration).setLabelToSessions(accountId, bulkLabelingDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Set message labels
     * @param {number} accountId Account identifier
     * @param {string} questionId Question identifier
     * @param {string} sessionId Session string identifier
     * @param {Array<LabelIdDto>} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    setMessageLabels(accountId, questionId, sessionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).setMessageLabels(accountId, questionId, sessionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Set session labels
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {Array<LabelIdDto>} labelIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    setSessionLabels(accountId, sessionId, labelIdDto, options) {
        return LabelsApiFp(this.configuration).setSessionLabels(accountId, sessionId, labelIdDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update message labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {MessageLabelUpdate} messageLabelUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateMessageLabel(accountId, id, messageLabelUpdate, options) {
        return LabelsApiFp(this.configuration).updateMessageLabel(accountId, id, messageLabelUpdate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update message label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options) {
        return LabelsApiFp(this.configuration).updateMessageLabelGroup(accountId, id, logLabelGroupCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update message label groups
     * @param {number} accountId Account identifier
     * @param {Array<UpdateMessageLabelGroupDto>} updateMessageLabelGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options) {
        return LabelsApiFp(this.configuration).updateMessageLabelGroups(accountId, updateMessageLabelGroupDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update message labels
     * @param {number} accountId Account identifier
     * @param {Array<UpdateMessageLabelDto>} updateMessageLabelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateMessageLabels(accountId, updateMessageLabelDto, options) {
        return LabelsApiFp(this.configuration).updateMessageLabels(accountId, updateMessageLabelDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update message labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateMessageLabelsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateSessionLabel(accountId, id, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update session labels
     * @param {number} accountId Account identifier
     * @param {Array<UpdateSessionLabelDto>} updateSessionLabelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateSessionLabels(accountId, updateSessionLabelDto, options) {
        return LabelsApiFp(this.configuration).updateSessionLabels(accountId, updateSessionLabelDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
}
/**
 * LogRecordApi - axios parameter creator
 * @export
 */
export const LogRecordApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get timestamp of last saved log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastLogTimestamp(options = {}) {
            const localVarPath = `/api/reporter/last-log-timestamp`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Cleanup logs
         * @param {Array<CleanupLogDto>} cleanupLogDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCleanupLogs(cleanupLogDto, options = {}) {
            // verify required parameter 'cleanupLogDto' is not null or undefined
            if (cleanupLogDto === null || cleanupLogDto === undefined) {
                throw new RequiredError("cleanupLogDto", "Required parameter cleanupLogDto was null or undefined when calling postCleanupLogs.");
            }
            const localVarPath = `/api/reporter/cleanup-logs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof cleanupLogDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(cleanupLogDto !== undefined ? cleanupLogDto : {})
                : cleanupLogDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LogRecordApi - functional programming interface
 * @export
 */
export const LogRecordApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get timestamp of last saved log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastLogTimestamp(options) {
            const localVarAxiosArgs = LogRecordApiAxiosParamCreator(configuration).getLastLogTimestamp(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Cleanup logs
         * @param {Array<CleanupLogDto>} cleanupLogDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCleanupLogs(cleanupLogDto, options) {
            const localVarAxiosArgs = LogRecordApiAxiosParamCreator(configuration).postCleanupLogs(cleanupLogDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LogRecordApi - factory interface
 * @export
 */
export const LogRecordApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get timestamp of last saved log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastLogTimestamp(options) {
            return LogRecordApiFp(configuration).getLastLogTimestamp(options)(axios, basePath);
        },
        /**
         *
         * @summary Cleanup logs
         * @param {Array<CleanupLogDto>} cleanupLogDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCleanupLogs(cleanupLogDto, options) {
            return LogRecordApiFp(configuration).postCleanupLogs(cleanupLogDto, options)(axios, basePath);
        },
    };
};
/**
 * LogRecordApi - object-oriented interface
 * @export
 * @class LogRecordApi
 * @extends {BaseAPI}
 */
export class LogRecordApi extends BaseAPI {
    /**
     *
     * @summary Get timestamp of last saved log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogRecordApi
     */
    getLastLogTimestamp(options) {
        return LogRecordApiFp(this.configuration).getLastLogTimestamp(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Cleanup logs
     * @param {Array<CleanupLogDto>} cleanupLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogRecordApi
     */
    postCleanupLogs(cleanupLogDto, options) {
        return LogRecordApiFp(this.configuration).postCleanupLogs(cleanupLogDto, options)(this.axios, this.basePath);
    }
}
/**
 * MessageStatusesApi - axios parameter creator
 * @export
 */
export const MessageStatusesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add statuses to list of messages
         * @param {UpdateStatusesDto} updateStatusesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusesForListOfMessages(updateStatusesDto, options = {}) {
            // verify required parameter 'updateStatusesDto' is not null or undefined
            if (updateStatusesDto === null || updateStatusesDto === undefined) {
                throw new RequiredError("updateStatusesDto", "Required parameter updateStatusesDto was null or undefined when calling updateStatusesForListOfMessages.");
            }
            const localVarPath = `/api/reporter/message-statuses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateStatusesDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateStatusesDto !== undefined ? updateStatusesDto : {})
                : updateStatusesDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MessageStatusesApi - functional programming interface
 * @export
 */
export const MessageStatusesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Add statuses to list of messages
         * @param {UpdateStatusesDto} updateStatusesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusesForListOfMessages(updateStatusesDto, options) {
            const localVarAxiosArgs = MessageStatusesApiAxiosParamCreator(configuration).updateStatusesForListOfMessages(updateStatusesDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * MessageStatusesApi - factory interface
 * @export
 */
export const MessageStatusesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Add statuses to list of messages
         * @param {UpdateStatusesDto} updateStatusesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusesForListOfMessages(updateStatusesDto, options) {
            return MessageStatusesApiFp(configuration).updateStatusesForListOfMessages(updateStatusesDto, options)(axios, basePath);
        },
    };
};
/**
 * MessageStatusesApi - object-oriented interface
 * @export
 * @class MessageStatusesApi
 * @extends {BaseAPI}
 */
export class MessageStatusesApi extends BaseAPI {
    /**
     *
     * @summary Add statuses to list of messages
     * @param {UpdateStatusesDto} updateStatusesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageStatusesApi
     */
    updateStatusesForListOfMessages(updateStatusesDto, options) {
        return MessageStatusesApiFp(this.configuration).updateStatusesForListOfMessages(updateStatusesDto, options)(this.axios, this.basePath);
    }
}
/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get messages by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessageDataByFilter.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/filter`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get page for message within a client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessagePageInClient.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getMessagePageInClient.");
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling getMessagePageInClient.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling getMessagePageInClient.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/page-in-client`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter["clientId"] = clientId;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get page for message within a session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessagePageInSession.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling getMessagePageInSession.");
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError("questionId", "Required parameter questionId was null or undefined when calling getMessagePageInSession.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/page-in-session`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get messages for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {string} [sort] sort asc or desc
         * @param {string} [questionId] Question identifier
         * @param {string} [channelType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessagesForClient.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getMessagesForClient.");
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling getMessagesForClient.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/by-client`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter["clientId"] = clientId;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (startTime !== undefined) {
                localVarQueryParameter["startTime"] =
                    startTime instanceof Date
                        ? startTime.toISOString()
                        : startTime;
            }
            if (endTime !== undefined) {
                localVarQueryParameter["endTime"] =
                    endTime instanceof Date
                        ? endTime.toISOString()
                        : endTime;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            if (questionId !== undefined) {
                localVarQueryParameter["questionId"] = questionId;
            }
            if (channelType !== undefined) {
                localVarQueryParameter["channelType"] = channelType;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get messages for session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {number} [page] Page number
         * @param {Array<string>} [responseDataJsonPath] List of fields in the responseData that will need to be returned separately (in customFields field)
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {Date} [startTime] Start date-time for filtering
         * @param {boolean} [hideEmptyMessage] Hide empty message for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getMessagesForSession.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling getMessagesForSession.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/by-session`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (responseDataJsonPath) {
                localVarQueryParameter["responseDataJsonPath"] = responseDataJsonPath;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (startTime !== undefined) {
                localVarQueryParameter["startTime"] =
                    startTime instanceof Date
                        ? startTime.toISOString()
                        : startTime;
            }
            if (hideEmptyMessage !== undefined) {
                localVarQueryParameter["hideEmptyMessage"] = hideEmptyMessage;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get messages count for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSeparatedMessagesCountForClient.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getSeparatedMessagesCountForClient.");
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling getSeparatedMessagesCountForClient.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/messages/separated-count/by-client`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter["clientId"] = clientId;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (startTime !== undefined) {
                localVarQueryParameter["startTime"] =
                    startTime instanceof Date
                        ? startTime.toISOString()
                        : startTime;
            }
            if (endTime !== undefined) {
                localVarQueryParameter["endTime"] =
                    endTime instanceof Date
                        ? endTime.toISOString()
                        : endTime;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get messages by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get page for message within a client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get page for message within a session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get messages for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {string} [sort] sort asc or desc
         * @param {string} [questionId] Question identifier
         * @param {string} [channelType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get messages for session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {number} [page] Page number
         * @param {Array<string>} [responseDataJsonPath] List of fields in the responseData that will need to be returned separately (in customFields field)
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {Date} [startTime] Start date-time for filtering
         * @param {boolean} [hideEmptyMessage] Hide empty message for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get messages count for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options) {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get messages by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            return MessagesApiFp(configuration).getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Get page for message within a client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options) {
            return MessagesApiFp(configuration).getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get page for message within a session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} questionId Question identifier
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options) {
            return MessagesApiFp(configuration).getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get messages for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {string} [sort] sort asc or desc
         * @param {string} [questionId] Question identifier
         * @param {string} [channelType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options) {
            return MessagesApiFp(configuration).getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options)(axios, basePath);
        },
        /**
         *
         * @summary Get messages for session
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {number} [page] Page number
         * @param {Array<string>} [responseDataJsonPath] List of fields in the responseData that will need to be returned separately (in customFields field)
         * @param {number} [size] Page size
         * @param {string} [projectShortName] Project short name
         * @param {string} [botId] Natural bot id
         * @param {Date} [startTime] Start date-time for filtering
         * @param {boolean} [hideEmptyMessage] Hide empty message for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options) {
            return MessagesApiFp(configuration).getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options)(axios, basePath);
        },
        /**
         *
         * @summary Get messages count for client
         * @param {number} accountId Account identifier
         * @param {string} botId Natural bot id
         * @param {string} clientId Client id
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options) {
            return MessagesApiFp(configuration).getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options)(axios, basePath);
        },
    };
};
/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     *
     * @summary Get messages by filter
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
        return MessagesApiFp(this.configuration).getMessageDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get page for message within a client
     * @param {number} accountId Account identifier
     * @param {string} botId Natural bot id
     * @param {string} clientId Client id
     * @param {string} questionId Question identifier
     * @param {number} [size] Page size
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options) {
        return MessagesApiFp(this.configuration).getMessagePageInClient(accountId, botId, clientId, questionId, size, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get page for message within a session
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {string} questionId Question identifier
     * @param {number} [size] Page size
     * @param {string} [projectShortName] Project short name
     * @param {string} [botId] Natural bot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options) {
        return MessagesApiFp(this.configuration).getMessagePageInSession(accountId, sessionId, questionId, size, projectShortName, botId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get messages for client
     * @param {number} accountId Account identifier
     * @param {string} botId Natural bot id
     * @param {string} clientId Client id
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [projectShortName] Project short name
     * @param {Date} [startTime] Start date-time for filtering
     * @param {Date} [endTime] End date-time for filtering
     * @param {string} [sort] sort asc or desc
     * @param {string} [questionId] Question identifier
     * @param {string} [channelType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options) {
        return MessagesApiFp(this.configuration).getMessagesForClient(accountId, botId, clientId, page, size, projectShortName, startTime, endTime, sort, questionId, channelType, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get messages for session
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {number} [page] Page number
     * @param {Array<string>} [responseDataJsonPath] List of fields in the responseData that will need to be returned separately (in customFields field)
     * @param {number} [size] Page size
     * @param {string} [projectShortName] Project short name
     * @param {string} [botId] Natural bot id
     * @param {Date} [startTime] Start date-time for filtering
     * @param {boolean} [hideEmptyMessage] Hide empty message for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options) {
        return MessagesApiFp(this.configuration).getMessagesForSession(accountId, sessionId, page, responseDataJsonPath, size, projectShortName, botId, startTime, hideEmptyMessage, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get messages count for client
     * @param {number} accountId Account identifier
     * @param {string} botId Natural bot id
     * @param {string} clientId Client id
     * @param {string} [projectShortName] Project short name
     * @param {Date} [startTime] Start date-time for filtering
     * @param {Date} [endTime] End date-time for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options) {
        return MessagesApiFp(this.configuration).getSeparatedMessagesCountForClient(accountId, botId, clientId, projectShortName, startTime, endTime, options)(this.axios, this.basePath);
    }
}
/**
 * OverviewStatsApi - axios parameter creator
 * @export
 */
export const OverviewStatsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOverviewCapacityTable.");
            }
            // verify required parameter 'dateFromP1' is not null or undefined
            if (dateFromP1 === null || dateFromP1 === undefined) {
                throw new RequiredError("dateFromP1", "Required parameter dateFromP1 was null or undefined when calling getOverviewCapacityTable.");
            }
            // verify required parameter 'dateToP1' is not null or undefined
            if (dateToP1 === null || dateToP1 === undefined) {
                throw new RequiredError("dateToP1", "Required parameter dateToP1 was null or undefined when calling getOverviewCapacityTable.");
            }
            // verify required parameter 'dateFromP2' is not null or undefined
            if (dateFromP2 === null || dateFromP2 === undefined) {
                throw new RequiredError("dateFromP2", "Required parameter dateFromP2 was null or undefined when calling getOverviewCapacityTable.");
            }
            // verify required parameter 'dateToP2' is not null or undefined
            if (dateToP2 === null || dateToP2 === undefined) {
                throw new RequiredError("dateToP2", "Required parameter dateToP2 was null or undefined when calling getOverviewCapacityTable.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/capacity/table`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (dateFromP1 !== undefined) {
                localVarQueryParameter["dateFromP1"] =
                    dateFromP1 instanceof Date
                        ? dateFromP1.toISOString()
                        : dateFromP1;
            }
            if (dateToP1 !== undefined) {
                localVarQueryParameter["dateToP1"] =
                    dateToP1 instanceof Date
                        ? dateToP1.toISOString()
                        : dateToP1;
            }
            if (dateFromP2 !== undefined) {
                localVarQueryParameter["dateFromP2"] =
                    dateFromP2 instanceof Date
                        ? dateFromP2.toISOString()
                        : dateFromP2;
            }
            if (dateToP2 !== undefined) {
                localVarQueryParameter["dateToP2"] =
                    dateToP2 instanceof Date
                        ? dateToP2.toISOString()
                        : dateToP2;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get xlsx statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {boolean} [shouldRenderUrls] Should render urls in workbook
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {string} [referer] Referer of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOverviewCapacityTableXlsx.");
            }
            // verify required parameter 'dateFromP1' is not null or undefined
            if (dateFromP1 === null || dateFromP1 === undefined) {
                throw new RequiredError("dateFromP1", "Required parameter dateFromP1 was null or undefined when calling getOverviewCapacityTableXlsx.");
            }
            // verify required parameter 'dateToP1' is not null or undefined
            if (dateToP1 === null || dateToP1 === undefined) {
                throw new RequiredError("dateToP1", "Required parameter dateToP1 was null or undefined when calling getOverviewCapacityTableXlsx.");
            }
            // verify required parameter 'dateFromP2' is not null or undefined
            if (dateFromP2 === null || dateFromP2 === undefined) {
                throw new RequiredError("dateFromP2", "Required parameter dateFromP2 was null or undefined when calling getOverviewCapacityTableXlsx.");
            }
            // verify required parameter 'dateToP2' is not null or undefined
            if (dateToP2 === null || dateToP2 === undefined) {
                throw new RequiredError("dateToP2", "Required parameter dateToP2 was null or undefined when calling getOverviewCapacityTableXlsx.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/capacity/table/download`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFromP1 !== undefined) {
                localVarQueryParameter["dateFromP1"] =
                    dateFromP1 instanceof Date
                        ? dateFromP1.toISOString()
                        : dateFromP1;
            }
            if (dateToP1 !== undefined) {
                localVarQueryParameter["dateToP1"] =
                    dateToP1 instanceof Date
                        ? dateToP1.toISOString()
                        : dateToP1;
            }
            if (dateFromP2 !== undefined) {
                localVarQueryParameter["dateFromP2"] =
                    dateFromP2 instanceof Date
                        ? dateFromP2.toISOString()
                        : dateFromP2;
            }
            if (dateToP2 !== undefined) {
                localVarQueryParameter["dateToP2"] =
                    dateToP2 instanceof Date
                        ? dateToP2.toISOString()
                        : dateToP2;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (shouldRenderUrls !== undefined) {
                localVarQueryParameter["shouldRenderUrls"] = shouldRenderUrls;
            }
            if (timezone !== undefined) {
                localVarQueryParameter["timezone"] = timezone;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            if (referer !== undefined && referer !== null) {
                localVarHeaderParameter["Referer"] = String(referer);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of clients by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOverviewClientCapacityByInterval.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOverviewClientCapacityByInterval.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOverviewClientCapacityByInterval.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/capacity/clients`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of messages by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOverviewMessagesCapacityByInterval.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOverviewMessagesCapacityByInterval.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOverviewMessagesCapacityByInterval.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/capacity/messages`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of sessions by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOverviewSessionsCapacityByInterval.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOverviewSessionsCapacityByInterval.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOverviewSessionsCapacityByInterval.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/capacity/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionsResultByInterval.");
            }
            // verify required parameter 'dateFromP1' is not null or undefined
            if (dateFromP1 === null || dateFromP1 === undefined) {
                throw new RequiredError("dateFromP1", "Required parameter dateFromP1 was null or undefined when calling getSessionsResultByInterval.");
            }
            // verify required parameter 'dateToP1' is not null or undefined
            if (dateToP1 === null || dateToP1 === undefined) {
                throw new RequiredError("dateToP1", "Required parameter dateToP1 was null or undefined when calling getSessionsResultByInterval.");
            }
            // verify required parameter 'dateFromP2' is not null or undefined
            if (dateFromP2 === null || dateFromP2 === undefined) {
                throw new RequiredError("dateFromP2", "Required parameter dateFromP2 was null or undefined when calling getSessionsResultByInterval.");
            }
            // verify required parameter 'dateToP2' is not null or undefined
            if (dateToP2 === null || dateToP2 === undefined) {
                throw new RequiredError("dateToP2", "Required parameter dateToP2 was null or undefined when calling getSessionsResultByInterval.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/session-results`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFromP1 !== undefined) {
                localVarQueryParameter["dateFromP1"] =
                    dateFromP1 instanceof Date
                        ? dateFromP1.toISOString()
                        : dateFromP1;
            }
            if (dateToP1 !== undefined) {
                localVarQueryParameter["dateToP1"] =
                    dateToP1 instanceof Date
                        ? dateToP1.toISOString()
                        : dateToP1;
            }
            if (dateFromP2 !== undefined) {
                localVarQueryParameter["dateFromP2"] =
                    dateFromP2 instanceof Date
                        ? dateFromP2.toISOString()
                        : dateFromP2;
            }
            if (dateToP2 !== undefined) {
                localVarQueryParameter["dateToP2"] =
                    dateToP2 instanceof Date
                        ? dateToP2.toISOString()
                        : dateToP2;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionsResultByIntervalTable.");
            }
            // verify required parameter 'dateFromP1' is not null or undefined
            if (dateFromP1 === null || dateFromP1 === undefined) {
                throw new RequiredError("dateFromP1", "Required parameter dateFromP1 was null or undefined when calling getSessionsResultByIntervalTable.");
            }
            // verify required parameter 'dateToP1' is not null or undefined
            if (dateToP1 === null || dateToP1 === undefined) {
                throw new RequiredError("dateToP1", "Required parameter dateToP1 was null or undefined when calling getSessionsResultByIntervalTable.");
            }
            // verify required parameter 'dateFromP2' is not null or undefined
            if (dateFromP2 === null || dateFromP2 === undefined) {
                throw new RequiredError("dateFromP2", "Required parameter dateFromP2 was null or undefined when calling getSessionsResultByIntervalTable.");
            }
            // verify required parameter 'dateToP2' is not null or undefined
            if (dateToP2 === null || dateToP2 === undefined) {
                throw new RequiredError("dateToP2", "Required parameter dateToP2 was null or undefined when calling getSessionsResultByIntervalTable.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/session-results/table`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (dateFromP1 !== undefined) {
                localVarQueryParameter["dateFromP1"] =
                    dateFromP1 instanceof Date
                        ? dateFromP1.toISOString()
                        : dateFromP1;
            }
            if (dateToP1 !== undefined) {
                localVarQueryParameter["dateToP1"] =
                    dateToP1 instanceof Date
                        ? dateToP1.toISOString()
                        : dateToP1;
            }
            if (dateFromP2 !== undefined) {
                localVarQueryParameter["dateFromP2"] =
                    dateFromP2 instanceof Date
                        ? dateFromP2.toISOString()
                        : dateFromP2;
            }
            if (dateToP2 !== undefined) {
                localVarQueryParameter["dateToP2"] =
                    dateToP2 instanceof Date
                        ? dateToP2.toISOString()
                        : dateToP2;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get xlsx sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionsResultByIntervalTableXlsx.");
            }
            // verify required parameter 'dateFromP1' is not null or undefined
            if (dateFromP1 === null || dateFromP1 === undefined) {
                throw new RequiredError("dateFromP1", "Required parameter dateFromP1 was null or undefined when calling getSessionsResultByIntervalTableXlsx.");
            }
            // verify required parameter 'dateToP1' is not null or undefined
            if (dateToP1 === null || dateToP1 === undefined) {
                throw new RequiredError("dateToP1", "Required parameter dateToP1 was null or undefined when calling getSessionsResultByIntervalTableXlsx.");
            }
            // verify required parameter 'dateFromP2' is not null or undefined
            if (dateFromP2 === null || dateFromP2 === undefined) {
                throw new RequiredError("dateFromP2", "Required parameter dateFromP2 was null or undefined when calling getSessionsResultByIntervalTableXlsx.");
            }
            // verify required parameter 'dateToP2' is not null or undefined
            if (dateToP2 === null || dateToP2 === undefined) {
                throw new RequiredError("dateToP2", "Required parameter dateToP2 was null or undefined when calling getSessionsResultByIntervalTableXlsx.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/overview/session-results/table/download`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFromP1 !== undefined) {
                localVarQueryParameter["dateFromP1"] =
                    dateFromP1 instanceof Date
                        ? dateFromP1.toISOString()
                        : dateFromP1;
            }
            if (dateToP1 !== undefined) {
                localVarQueryParameter["dateToP1"] =
                    dateToP1 instanceof Date
                        ? dateToP1.toISOString()
                        : dateToP1;
            }
            if (dateFromP2 !== undefined) {
                localVarQueryParameter["dateFromP2"] =
                    dateFromP2 instanceof Date
                        ? dateFromP2.toISOString()
                        : dateFromP2;
            }
            if (dateToP2 !== undefined) {
                localVarQueryParameter["dateToP2"] =
                    dateToP2 instanceof Date
                        ? dateToP2.toISOString()
                        : dateToP2;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OverviewStatsApi - functional programming interface
 * @export
 */
export const OverviewStatsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get xlsx statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {boolean} [shouldRenderUrls] Should render urls in workbook
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {string} [referer] Referer of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of clients by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of messages by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of sessions by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get xlsx sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            const localVarAxiosArgs = OverviewStatsApiAxiosParamCreator(configuration).getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OverviewStatsApi - factory interface
 * @export
 */
export const OverviewStatsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options) {
            return OverviewStatsApiFp(configuration).getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Get xlsx statistics between p1 and p2
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {boolean} [shouldRenderUrls] Should render urls in workbook
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {string} [referer] Referer of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options) {
            return OverviewStatsApiFp(configuration).getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of clients by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            return OverviewStatsApiFp(configuration).getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of messages by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            return OverviewStatsApiFp(configuration).getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of sessions by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the report will be built.
         * @param {Date} dateTo The date-time by which the report will be built.
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
            return OverviewStatsApiFp(configuration).getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            return OverviewStatsApiFp(configuration).getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Get sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            return OverviewStatsApiFp(configuration).getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Get xlsx sessions result by interval
         * @param {number} accountId Account identifier
         * @param {Date} dateFromP1 The date-time from which the report will be built.
         * @param {Date} dateToP1 The date-time by which the report will be built.
         * @param {Date} dateFromP2 The date-time from which the report will be built.
         * @param {Date} dateToP2 The date-time by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
            return OverviewStatsApiFp(configuration).getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(axios, basePath);
        },
    };
};
/**
 * OverviewStatsApi - object-oriented interface
 * @export
 * @class OverviewStatsApi
 * @extends {BaseAPI}
 */
export class OverviewStatsApi extends BaseAPI {
    /**
     *
     * @summary Get statistics between p1 and p2
     * @param {number} accountId Account identifier
     * @param {Date} dateFromP1 The date-time from which the report will be built.
     * @param {Date} dateToP1 The date-time by which the report will be built.
     * @param {Date} dateFromP2 The date-time from which the report will be built.
     * @param {Date} dateToP2 The date-time by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options) {
        return OverviewStatsApiFp(this.configuration).getOverviewCapacityTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get xlsx statistics between p1 and p2
     * @param {number} accountId Account identifier
     * @param {Date} dateFromP1 The date-time from which the report will be built.
     * @param {Date} dateToP1 The date-time by which the report will be built.
     * @param {Date} dateFromP2 The date-time from which the report will be built.
     * @param {Date} dateToP2 The date-time by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {boolean} [shouldRenderUrls] Should render urls in workbook
     * @param {number} [timezone] Timezone offset
     * @param {string} [language] Language of resources
     * @param {string} [referer] Referer of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options) {
        return OverviewStatsApiFp(this.configuration).getOverviewCapacityTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, shouldRenderUrls, timezone, language, referer, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of clients by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the report will be built.
     * @param {Date} dateTo The date-time by which the report will be built.
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
        return OverviewStatsApiFp(this.configuration).getOverviewClientCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of messages by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the report will be built.
     * @param {Date} dateTo The date-time by which the report will be built.
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
        return OverviewStatsApiFp(this.configuration).getOverviewMessagesCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of sessions by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the report will be built.
     * @param {Date} dateTo The date-time by which the report will be built.
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options) {
        return OverviewStatsApiFp(this.configuration).getOverviewSessionsCapacityByInterval(accountId, dateFrom, dateTo, intervalType, projectShortName, botIds, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get sessions result by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFromP1 The date-time from which the report will be built.
     * @param {Date} dateToP1 The date-time by which the report will be built.
     * @param {Date} dateFromP2 The date-time from which the report will be built.
     * @param {Date} dateToP2 The date-time by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
        return OverviewStatsApiFp(this.configuration).getSessionsResultByInterval(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get sessions result by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFromP1 The date-time from which the report will be built.
     * @param {Date} dateToP1 The date-time by which the report will be built.
     * @param {Date} dateFromP2 The date-time from which the report will be built.
     * @param {Date} dateToP2 The date-time by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
        return OverviewStatsApiFp(this.configuration).getSessionsResultByIntervalTable(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get xlsx sessions result by interval
     * @param {number} accountId Account identifier
     * @param {Date} dateFromP1 The date-time from which the report will be built.
     * @param {Date} dateToP1 The date-time by which the report will be built.
     * @param {Date} dateFromP2 The date-time from which the report will be built.
     * @param {Date} dateToP2 The date-time by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverviewStatsApi
     */
    getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options) {
        return OverviewStatsApiFp(this.configuration).getSessionsResultByIntervalTableXlsx(accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, projectShortName, botIds, timezone, language, options)(this.axios, this.basePath);
    }
}
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create analytic chart report
         * @param {number} accountId Account identifier
         * @param {AnalyticsChartReportType} type
         * @param {number} [analyticsEntityId]
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {AnalyticsEntityType} [analyticsEntityType]
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createAnalyticChartReport.");
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError("type", "Required parameter type was null or undefined when calling createAnalyticChartReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/analytics-charts`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (type !== undefined) {
                localVarQueryParameter["type"] = type;
            }
            if (analyticsEntityId !== undefined) {
                localVarQueryParameter["analyticsEntityId"] = analyticsEntityId;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (analyticsEntityType !== undefined) {
                localVarQueryParameter["analyticsEntityType"] = analyticsEntityType;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId Account identifier
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createBillingReportForAccount.");
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError("type", "Required parameter type was null or undefined when calling createBillingReportForAccount.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling createBillingReportForAccount.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling createBillingReportForAccount.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/billing`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (type !== undefined) {
                localVarQueryParameter["type"] = type;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString().substr(0, 10)
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString().substr(0, 10)
                        : dateTo;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId User identifier
         * @param {number} reportAccountId
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling createBillingReportForSystemAdmin.");
            }
            // verify required parameter 'reportAccountId' is not null or undefined
            if (reportAccountId === null || reportAccountId === undefined) {
                throw new RequiredError("reportAccountId", "Required parameter reportAccountId was null or undefined when calling createBillingReportForSystemAdmin.");
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError("type", "Required parameter type was null or undefined when calling createBillingReportForSystemAdmin.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling createBillingReportForSystemAdmin.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling createBillingReportForSystemAdmin.");
            }
            const localVarPath = `/api/reporter/system-admin/{userId}/reports/billing`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (reportAccountId !== undefined) {
                localVarQueryParameter["reportAccountId"] = reportAccountId;
            }
            if (type !== undefined) {
                localVarQueryParameter["type"] = type;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString().substr(0, 10)
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString().substr(0, 10)
                        : dateTo;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create client list report
         * @param {number} accountId Account identifier
         * @param {CreateReportDto} createReportDto
         * @param {string} [projectShortName] Project short name
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListReport(accountId, createReportDto, projectShortName, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createClientListReport.");
            }
            // verify required parameter 'createReportDto' is not null or undefined
            if (createReportDto === null || createReportDto === undefined) {
                throw new RequiredError("createReportDto", "Required parameter createReportDto was null or undefined when calling createClientListReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/client-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create client report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientReport(accountId, projectShortName, language, timezone, createReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createClientReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/clients`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create daily message report
         * @param {number} accountId Account identifier
         * @param {CreateDailyReportDto} createDailyReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDailyReport(accountId, createDailyReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createDailyReport.");
            }
            // verify required parameter 'createDailyReportDto' is not null or undefined
            if (createDailyReportDto === null || createDailyReportDto === undefined) {
                throw new RequiredError("createDailyReportDto", "Required parameter createDailyReportDto was null or undefined when calling createDailyReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/daily`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createDailyReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createDailyReportDto !== undefined ? createDailyReportDto : {})
                : createDailyReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create log message report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createMessageReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/messages`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createSessionReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create state routes report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createStateRoutesReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/routes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createReportDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createReportDto !== undefined ? createReportDto : {})
                : createReportDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create telephony report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createTelephonyReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/telephony`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (startTime !== undefined) {
                localVarQueryParameter["startTime"] =
                    startTime instanceof Date
                        ? startTime.toISOString()
                        : startTime;
            }
            if (endTime !== undefined) {
                localVarQueryParameter["endTime"] =
                    endTime instanceof Date
                        ? endTime.toISOString()
                        : endTime;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter["language"] = String(language);
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete all report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllReportGenerationTasks(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteAllReportGenerationTasks.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/all`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete all completed report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompletedReportGenerationTasks(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteCompletedReportGenerationTasks.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportGenerationTask(accountId, reportGenerationTaskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteReportGenerationTask.");
            }
            // verify required parameter 'reportGenerationTaskId' is not null or undefined
            if (reportGenerationTaskId === null ||
                reportGenerationTaskId === undefined) {
                throw new RequiredError("reportGenerationTaskId", "Required parameter reportGenerationTaskId was null or undefined when calling deleteReportGenerationTask.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/tasks/{reportGenerationTaskId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"reportGenerationTaskId"}}`, encodeURIComponent(String(reportGenerationTaskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download report
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(accountId, reportGenerationTaskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling downloadReport.");
            }
            // verify required parameter 'reportGenerationTaskId' is not null or undefined
            if (reportGenerationTaskId === null ||
                reportGenerationTaskId === undefined) {
                throw new RequiredError("reportGenerationTaskId", "Required parameter reportGenerationTaskId was null or undefined when calling downloadReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/tasks/{reportGenerationTaskId}/download`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"reportGenerationTaskId"}}`, encodeURIComponent(String(reportGenerationTaskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download report for system admin
         * @param {number} userId User identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportForSystemAdmin(userId, reportGenerationTaskId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling downloadReportForSystemAdmin.");
            }
            // verify required parameter 'reportGenerationTaskId' is not null or undefined
            if (reportGenerationTaskId === null ||
                reportGenerationTaskId === undefined) {
                throw new RequiredError("reportGenerationTaskId", "Required parameter reportGenerationTaskId was null or undefined when calling downloadReportForSystemAdmin.");
            }
            const localVarPath = `/api/reporter/system-admin/{userId}/reports/tasks/{reportGenerationTaskId}/download`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"reportGenerationTaskId"}}`, encodeURIComponent(String(reportGenerationTaskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all not deleted report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotDeletedReportGenerationTasks(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getNotDeletedReportGenerationTasks.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportGenerationTask(accountId, reportGenerationTaskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getReportGenerationTask.");
            }
            // verify required parameter 'reportGenerationTaskId' is not null or undefined
            if (reportGenerationTaskId === null ||
                reportGenerationTaskId === undefined) {
                throw new RequiredError("reportGenerationTaskId", "Required parameter reportGenerationTaskId was null or undefined when calling getReportGenerationTask.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/reports/tasks/{reportGenerationTaskId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"reportGenerationTaskId"}}`, encodeURIComponent(String(reportGenerationTaskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create analytic chart report
         * @param {number} accountId Account identifier
         * @param {AnalyticsChartReportType} type
         * @param {number} [analyticsEntityId]
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {AnalyticsEntityType} [analyticsEntityType]
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId Account identifier
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId User identifier
         * @param {number} reportAccountId
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create client list report
         * @param {number} accountId Account identifier
         * @param {CreateReportDto} createReportDto
         * @param {string} [projectShortName] Project short name
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListReport(accountId, createReportDto, projectShortName, timezone, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createClientListReport(accountId, createReportDto, projectShortName, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create client report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createClientReport(accountId, projectShortName, language, timezone, createReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create daily message report
         * @param {number} accountId Account identifier
         * @param {CreateDailyReportDto} createDailyReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDailyReport(accountId, createDailyReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createDailyReport(accountId, createDailyReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create log message report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create state routes report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create telephony report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete all report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllReportGenerationTasks(accountId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).deleteAllReportGenerationTasks(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete all completed report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompletedReportGenerationTasks(accountId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).deleteCompletedReportGenerationTasks(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportGenerationTask(accountId, reportGenerationTaskId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).deleteReportGenerationTask(accountId, reportGenerationTaskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Download report
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(accountId, reportGenerationTaskId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).downloadReport(accountId, reportGenerationTaskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Download report for system admin
         * @param {number} userId User identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportForSystemAdmin(userId, reportGenerationTaskId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).downloadReportForSystemAdmin(userId, reportGenerationTaskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all not deleted report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotDeletedReportGenerationTasks(accountId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).getNotDeletedReportGenerationTasks(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportGenerationTask(accountId, reportGenerationTaskId, options) {
            const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).getReportGenerationTask(accountId, reportGenerationTaskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create analytic chart report
         * @param {number} accountId Account identifier
         * @param {AnalyticsChartReportType} type
         * @param {number} [analyticsEntityId]
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {IntervalType} [intervalType] Interval with which statistics will be generated
         * @param {AnalyticsEntityType} [analyticsEntityType]
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options) {
            return ReportApiFp(configuration).createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId Account identifier
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options) {
            return ReportApiFp(configuration).createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId User identifier
         * @param {number} reportAccountId
         * @param {BillingReportType} type
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options) {
            return ReportApiFp(configuration).createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Create client list report
         * @param {number} accountId Account identifier
         * @param {CreateReportDto} createReportDto
         * @param {string} [projectShortName] Project short name
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListReport(accountId, createReportDto, projectShortName, timezone, options) {
            return ReportApiFp(configuration).createClientListReport(accountId, createReportDto, projectShortName, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Create client report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            return ReportApiFp(configuration).createClientReport(accountId, projectShortName, language, timezone, createReportDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create daily message report
         * @param {number} accountId Account identifier
         * @param {CreateDailyReportDto} createDailyReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDailyReport(accountId, createDailyReportDto, options) {
            return ReportApiFp(configuration).createDailyReport(accountId, createDailyReportDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create log message report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            return ReportApiFp(configuration).createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            return ReportApiFp(configuration).createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create state routes report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {CreateReportDto} [createReportDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options) {
            return ReportApiFp(configuration).createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Create telephony report
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {Date} [startTime] Start date-time for filtering
         * @param {Date} [endTime] End date-time for filtering
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [language] Language of resources
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options) {
            return ReportApiFp(configuration).createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete all report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllReportGenerationTasks(accountId, options) {
            return ReportApiFp(configuration).deleteAllReportGenerationTasks(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete all completed report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompletedReportGenerationTasks(accountId, options) {
            return ReportApiFp(configuration).deleteCompletedReportGenerationTasks(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportGenerationTask(accountId, reportGenerationTaskId, options) {
            return ReportApiFp(configuration).deleteReportGenerationTask(accountId, reportGenerationTaskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Download report
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(accountId, reportGenerationTaskId, options) {
            return ReportApiFp(configuration).downloadReport(accountId, reportGenerationTaskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Download report for system admin
         * @param {number} userId User identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportForSystemAdmin(userId, reportGenerationTaskId, options) {
            return ReportApiFp(configuration).downloadReportForSystemAdmin(userId, reportGenerationTaskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all not deleted report generation tasks for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotDeletedReportGenerationTasks(accountId, options) {
            return ReportApiFp(configuration).getNotDeletedReportGenerationTasks(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get report generation task
         * @param {number} accountId Account identifier
         * @param {string} reportGenerationTaskId Report generation task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportGenerationTask(accountId, reportGenerationTaskId, options) {
            return ReportApiFp(configuration).getReportGenerationTask(accountId, reportGenerationTaskId, options)(axios, basePath);
        },
    };
};
/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     *
     * @summary Create analytic chart report
     * @param {number} accountId Account identifier
     * @param {AnalyticsChartReportType} type
     * @param {number} [analyticsEntityId]
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {IntervalType} [intervalType] Interval with which statistics will be generated
     * @param {AnalyticsEntityType} [analyticsEntityType]
     * @param {CreateReportDto} [createReportDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options) {
        return ReportApiFp(this.configuration).createAnalyticChartReport(accountId, type, analyticsEntityId, projectShortName, language, timezone, intervalType, analyticsEntityType, createReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId Account identifier
     * @param {BillingReportType} type
     * @param {string} dateFrom The date from which the report will be built.
     * @param {string} dateTo The date by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options) {
        return ReportApiFp(this.configuration).createBillingReportForAccount(accountId, type, dateFrom, dateTo, projectShortName, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId User identifier
     * @param {number} reportAccountId
     * @param {BillingReportType} type
     * @param {string} dateFrom The date from which the report will be built.
     * @param {string} dateTo The date by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options) {
        return ReportApiFp(this.configuration).createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create client list report
     * @param {number} accountId Account identifier
     * @param {CreateReportDto} createReportDto
     * @param {string} [projectShortName] Project short name
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createClientListReport(accountId, createReportDto, projectShortName, timezone, options) {
        return ReportApiFp(this.configuration).createClientListReport(accountId, createReportDto, projectShortName, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create client report
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {CreateReportDto} [createReportDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createClientReport(accountId, projectShortName, language, timezone, createReportDto, options) {
        return ReportApiFp(this.configuration).createClientReport(accountId, projectShortName, language, timezone, createReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create daily message report
     * @param {number} accountId Account identifier
     * @param {CreateDailyReportDto} createDailyReportDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createDailyReport(accountId, createDailyReportDto, options) {
        return ReportApiFp(this.configuration).createDailyReport(accountId, createDailyReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create log message report
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {CreateReportDto} [createReportDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options) {
        return ReportApiFp(this.configuration).createMessageReport(accountId, projectShortName, language, timezone, createReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create session report
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {CreateReportDto} [createReportDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options) {
        return ReportApiFp(this.configuration).createSessionReport(accountId, projectShortName, language, timezone, createReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create state routes report
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {CreateReportDto} [createReportDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options) {
        return ReportApiFp(this.configuration).createStateRoutesReport(accountId, projectShortName, language, timezone, createReportDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create telephony report
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {Date} [startTime] Start date-time for filtering
     * @param {Date} [endTime] End date-time for filtering
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {string} [language] Language of resources
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options) {
        return ReportApiFp(this.configuration).createTelephonyReport(accountId, projectShortName, startTime, endTime, botIds, language, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete all report generation tasks for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    deleteAllReportGenerationTasks(accountId, options) {
        return ReportApiFp(this.configuration).deleteAllReportGenerationTasks(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete all completed report generation tasks for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    deleteCompletedReportGenerationTasks(accountId, options) {
        return ReportApiFp(this.configuration).deleteCompletedReportGenerationTasks(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete report generation task
     * @param {number} accountId Account identifier
     * @param {string} reportGenerationTaskId Report generation task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    deleteReportGenerationTask(accountId, reportGenerationTaskId, options) {
        return ReportApiFp(this.configuration).deleteReportGenerationTask(accountId, reportGenerationTaskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Download report
     * @param {number} accountId Account identifier
     * @param {string} reportGenerationTaskId Report generation task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    downloadReport(accountId, reportGenerationTaskId, options) {
        return ReportApiFp(this.configuration).downloadReport(accountId, reportGenerationTaskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Download report for system admin
     * @param {number} userId User identifier
     * @param {string} reportGenerationTaskId Report generation task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    downloadReportForSystemAdmin(userId, reportGenerationTaskId, options) {
        return ReportApiFp(this.configuration).downloadReportForSystemAdmin(userId, reportGenerationTaskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get all not deleted report generation tasks for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    getNotDeletedReportGenerationTasks(accountId, options) {
        return ReportApiFp(this.configuration).getNotDeletedReportGenerationTasks(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get report generation task
     * @param {number} accountId Account identifier
     * @param {string} reportGenerationTaskId Report generation task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    getReportGenerationTask(accountId, reportGenerationTaskId, options) {
        return ReportApiFp(this.configuration).getReportGenerationTask(accountId, reportGenerationTaskId, options)(this.axios, this.basePath);
    }
}
/**
 * ScenarioExperimentsApi - axios parameter creator
 * @export
 */
export const ScenarioExperimentsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Count scenario experiment joined sessions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countScenarioExperimentSessions(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling countScenarioExperimentSessions.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling countScenarioExperimentSessions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments/count-sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {ScenarioExperimentDto} scenarioExperimentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createScenarioExperiment.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createScenarioExperiment.");
            }
            // verify required parameter 'scenarioExperimentDto' is not null or undefined
            if (scenarioExperimentDto === null ||
                scenarioExperimentDto === undefined) {
                throw new RequiredError("scenarioExperimentDto", "Required parameter scenarioExperimentDto was null or undefined when calling createScenarioExperiment.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof scenarioExperimentDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(scenarioExperimentDto !== undefined ? scenarioExperimentDto : {})
                : scenarioExperimentDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioExperiment(accountId, projectShortName, experimentKey, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteScenarioExperiment.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteScenarioExperiment.");
            }
            // verify required parameter 'experimentKey' is not null or undefined
            if (experimentKey === null || experimentKey === undefined) {
                throw new RequiredError("experimentKey", "Required parameter experimentKey was null or undefined when calling deleteScenarioExperiment.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (experimentKey !== undefined) {
                localVarQueryParameter["experimentKey"] = experimentKey;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Fetch scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioExperiments(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getScenarioExperiments.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getScenarioExperiments.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Join to scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} experimentKey Scenario experiment key
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling joinScenarioExperiment.");
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError("sessionId", "Required parameter sessionId was null or undefined when calling joinScenarioExperiment.");
            }
            // verify required parameter 'experimentKey' is not null or undefined
            if (experimentKey === null || experimentKey === undefined) {
                throw new RequiredError("experimentKey", "Required parameter experimentKey was null or undefined when calling joinScenarioExperiment.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling joinScenarioExperiment.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling joinScenarioExperiment.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments/join`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sessionId !== undefined) {
                localVarQueryParameter["sessionId"] = sessionId;
            }
            if (experimentKey !== undefined) {
                localVarQueryParameter["experimentKey"] = experimentKey;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Stop scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopScenarioExperiment(accountId, projectShortName, experimentKey, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling stopScenarioExperiment.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling stopScenarioExperiment.");
            }
            // verify required parameter 'experimentKey' is not null or undefined
            if (experimentKey === null || experimentKey === undefined) {
                throw new RequiredError("experimentKey", "Required parameter experimentKey was null or undefined when calling stopScenarioExperiment.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/scenario-exeperiments/stop`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (experimentKey !== undefined) {
                localVarQueryParameter["experimentKey"] = experimentKey;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ScenarioExperimentsApi - functional programming interface
 * @export
 */
export const ScenarioExperimentsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Count scenario experiment joined sessions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countScenarioExperimentSessions(accountId, projectShortName, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).countScenarioExperimentSessions(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {ScenarioExperimentDto} scenarioExperimentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioExperiment(accountId, projectShortName, experimentKey, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).deleteScenarioExperiment(accountId, projectShortName, experimentKey, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Fetch scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioExperiments(accountId, projectShortName, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).getScenarioExperiments(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Join to scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} experimentKey Scenario experiment key
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Stop scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopScenarioExperiment(accountId, projectShortName, experimentKey, options) {
            const localVarAxiosArgs = ScenarioExperimentsApiAxiosParamCreator(configuration).stopScenarioExperiment(accountId, projectShortName, experimentKey, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ScenarioExperimentsApi - factory interface
 * @export
 */
export const ScenarioExperimentsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Count scenario experiment joined sessions
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countScenarioExperimentSessions(accountId, projectShortName, options) {
            return ScenarioExperimentsApiFp(configuration).countScenarioExperimentSessions(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Create scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {ScenarioExperimentDto} scenarioExperimentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options) {
            return ScenarioExperimentsApiFp(configuration).createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenarioExperiment(accountId, projectShortName, experimentKey, options) {
            return ScenarioExperimentsApiFp(configuration).deleteScenarioExperiment(accountId, projectShortName, experimentKey, options)(axios, basePath);
        },
        /**
         *
         * @summary Fetch scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioExperiments(accountId, projectShortName, options) {
            return ScenarioExperimentsApiFp(configuration).getScenarioExperiments(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Join to scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} sessionId Session string identifier
         * @param {string} experimentKey Scenario experiment key
         * @param {string} projectShortName Project short name
         * @param {string} botId Natural bot id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options) {
            return ScenarioExperimentsApiFp(configuration).joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options)(axios, basePath);
        },
        /**
         *
         * @summary Stop scenario experiment
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} experimentKey Scenario experiment key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopScenarioExperiment(accountId, projectShortName, experimentKey, options) {
            return ScenarioExperimentsApiFp(configuration).stopScenarioExperiment(accountId, projectShortName, experimentKey, options)(axios, basePath);
        },
    };
};
/**
 * ScenarioExperimentsApi - object-oriented interface
 * @export
 * @class ScenarioExperimentsApi
 * @extends {BaseAPI}
 */
export class ScenarioExperimentsApi extends BaseAPI {
    /**
     *
     * @summary Count scenario experiment joined sessions
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    countScenarioExperimentSessions(accountId, projectShortName, options) {
        return ScenarioExperimentsApiFp(this.configuration).countScenarioExperimentSessions(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create scenario experiment
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {ScenarioExperimentDto} scenarioExperimentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options) {
        return ScenarioExperimentsApiFp(this.configuration).createScenarioExperiment(accountId, projectShortName, scenarioExperimentDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete scenario experiment
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} experimentKey Scenario experiment key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    deleteScenarioExperiment(accountId, projectShortName, experimentKey, options) {
        return ScenarioExperimentsApiFp(this.configuration).deleteScenarioExperiment(accountId, projectShortName, experimentKey, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Fetch scenario experiment
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    getScenarioExperiments(accountId, projectShortName, options) {
        return ScenarioExperimentsApiFp(this.configuration).getScenarioExperiments(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Join to scenario experiment
     * @param {number} accountId Account identifier
     * @param {string} sessionId Session string identifier
     * @param {string} experimentKey Scenario experiment key
     * @param {string} projectShortName Project short name
     * @param {string} botId Natural bot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options) {
        return ScenarioExperimentsApiFp(this.configuration).joinScenarioExperiment(accountId, sessionId, experimentKey, projectShortName, botId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Stop scenario experiment
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} experimentKey Scenario experiment key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioExperimentsApi
     */
    stopScenarioExperiment(accountId, projectShortName, experimentKey, options) {
        return ScenarioExperimentsApiFp(this.configuration).stopScenarioExperiment(accountId, projectShortName, experimentKey, options)(this.axios, this.basePath);
    }
}
/**
 * SessionsApi - axios parameter creator
 * @export
 */
export const SessionsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Count project sessions
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllProjectsSessions(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling countAllProjectsSessions.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/projects/sessions/count`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of audio call url
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} [callTaskId] CallTask identifier
         * @param {Array<string>} [mailingResults] List of mailing results
         * @param {Date} [dateFrom] The date-time from which the report will be built.
         * @param {Date} [dateTo] The date-time by which the report will be built.
         * @param {Array<string>} [mailingResultsNotLike] List of values that should not be present in mailing results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAudioCallUrls.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getAudioCallUrls.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/audio-call-urls`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (callTaskId !== undefined) {
                localVarQueryParameter["callTaskId"] = callTaskId;
            }
            if (mailingResults) {
                localVarQueryParameter["mailingResults"] = mailingResults;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (mailingResultsNotLike) {
                localVarQueryParameter["mailingResultsNotLike"] = mailingResultsNotLike;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get sessions by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {boolean} [needToReturnSessionLabels] Need to return session labels parameter
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionDataByFilter.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/sessions/filter`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (needToReturnSessionLabels !== undefined) {
                localVarQueryParameter["needToReturnSessionLabels"] =
                    needToReturnSessionLabels;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Count project sessions
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllProjectsSessions(accountId, options) {
            const localVarAxiosArgs = SessionsApiAxiosParamCreator(configuration).countAllProjectsSessions(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of audio call url
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} [callTaskId] CallTask identifier
         * @param {Array<string>} [mailingResults] List of mailing results
         * @param {Date} [dateFrom] The date-time from which the report will be built.
         * @param {Date} [dateTo] The date-time by which the report will be built.
         * @param {Array<string>} [mailingResultsNotLike] List of values that should not be present in mailing results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options) {
            const localVarAxiosArgs = SessionsApiAxiosParamCreator(configuration).getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get sessions by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {boolean} [needToReturnSessionLabels] Need to return session labels parameter
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options) {
            const localVarAxiosArgs = SessionsApiAxiosParamCreator(configuration).getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Count project sessions
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllProjectsSessions(accountId, options) {
            return SessionsApiFp(configuration).countAllProjectsSessions(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of audio call url
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {number} [callTaskId] CallTask identifier
         * @param {Array<string>} [mailingResults] List of mailing results
         * @param {Date} [dateFrom] The date-time from which the report will be built.
         * @param {Date} [dateTo] The date-time by which the report will be built.
         * @param {Array<string>} [mailingResultsNotLike] List of values that should not be present in mailing results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options) {
            return SessionsApiFp(configuration).getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options)(axios, basePath);
        },
        /**
         *
         * @summary Get sessions by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {boolean} [needToReturnSessionLabels] Need to return session labels parameter
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options) {
            return SessionsApiFp(configuration).getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options)(axios, basePath);
        },
    };
};
/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI {
    /**
     *
     * @summary Count project sessions
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    countAllProjectsSessions(accountId, options) {
        return SessionsApiFp(this.configuration).countAllProjectsSessions(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get list of audio call url
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {number} [callTaskId] CallTask identifier
     * @param {Array<string>} [mailingResults] List of mailing results
     * @param {Date} [dateFrom] The date-time from which the report will be built.
     * @param {Date} [dateTo] The date-time by which the report will be built.
     * @param {Array<string>} [mailingResultsNotLike] List of values that should not be present in mailing results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options) {
        return SessionsApiFp(this.configuration).getAudioCallUrls(accountId, projectShortName, callTaskId, mailingResults, dateFrom, dateTo, mailingResultsNotLike, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get sessions by filter
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {boolean} [needToReturnSessionLabels] Need to return session labels parameter
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options) {
        return SessionsApiFp(this.configuration).getSessionDataByFilter(accountId, projectShortName, page, size, needToReturnSessionLabels, filtersDto, options)(this.axios, this.basePath);
    }
}
/**
 * SimpleStatsApi - axios parameter creator
 * @export
 */
export const SimpleStatsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics on the number of unique clients
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsUniqueClients(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllProjectsUniqueClients.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/unique-clients`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get statistics on the number of switched sessions by day
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {'SWITCH' | 'TEXT'} transferToOperatorMethod The method of transfer to the operator.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [textForTransfer] The text response of the bot, which will be considered a transfer to the operator.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSwitchedSessionCountByDay.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getSwitchedSessionCountByDay.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getSwitchedSessionCountByDay.");
            }
            // verify required parameter 'transferToOperatorMethod' is not null or undefined
            if (transferToOperatorMethod === null ||
                transferToOperatorMethod === undefined) {
                throw new RequiredError("transferToOperatorMethod", "Required parameter transferToOperatorMethod was null or undefined when calling getSwitchedSessionCountByDay.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/switched-sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString().substr(0, 10)
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString().substr(0, 10)
                        : dateTo;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (transferToOperatorMethod !== undefined) {
                localVarQueryParameter["transferToOperatorMethod"] =
                    transferToOperatorMethod;
            }
            if (textForTransfer !== undefined) {
                localVarQueryParameter["textForTransfer"] = textForTransfer;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get telephony statistics
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTelephonyStats.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getTelephonyStats.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getTelephonyStats.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/stats/telephony`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString().substr(0, 10)
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString().substr(0, 10)
                        : dateTo;
            }
            if (botIds) {
                localVarQueryParameter["botIds"] = botIds;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SimpleStatsApi - functional programming interface
 * @export
 */
export const SimpleStatsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get statistics on the number of unique clients
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsUniqueClients(accountId, projectShortName, options) {
            const localVarAxiosArgs = SimpleStatsApiAxiosParamCreator(configuration).getAllProjectsUniqueClients(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get statistics on the number of switched sessions by day
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {'SWITCH' | 'TEXT'} transferToOperatorMethod The method of transfer to the operator.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [textForTransfer] The text response of the bot, which will be considered a transfer to the operator.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options) {
            const localVarAxiosArgs = SimpleStatsApiAxiosParamCreator(configuration).getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get telephony statistics
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options) {
            const localVarAxiosArgs = SimpleStatsApiAxiosParamCreator(configuration).getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SimpleStatsApi - factory interface
 * @export
 */
export const SimpleStatsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get statistics on the number of unique clients
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsUniqueClients(accountId, projectShortName, options) {
            return SimpleStatsApiFp(configuration).getAllProjectsUniqueClients(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get statistics on the number of switched sessions by day
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {'SWITCH' | 'TEXT'} transferToOperatorMethod The method of transfer to the operator.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {string} [textForTransfer] The text response of the bot, which will be considered a transfer to the operator.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options) {
            return SimpleStatsApiFp(configuration).getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Get telephony statistics
         * @param {number} accountId Account identifier
         * @param {string} dateFrom The date from which the report will be built.
         * @param {string} dateTo The date by which the report will be built.
         * @param {string} [projectShortName] Project short name
         * @param {Array<string>} [botIds] List of string bot ids.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options) {
            return SimpleStatsApiFp(configuration).getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options)(axios, basePath);
        },
    };
};
/**
 * SimpleStatsApi - object-oriented interface
 * @export
 * @class SimpleStatsApi
 * @extends {BaseAPI}
 */
export class SimpleStatsApi extends BaseAPI {
    /**
     *
     * @summary Get statistics on the number of unique clients
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleStatsApi
     */
    getAllProjectsUniqueClients(accountId, projectShortName, options) {
        return SimpleStatsApiFp(this.configuration).getAllProjectsUniqueClients(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get statistics on the number of switched sessions by day
     * @param {number} accountId Account identifier
     * @param {string} dateFrom The date from which the report will be built.
     * @param {string} dateTo The date by which the report will be built.
     * @param {'SWITCH' | 'TEXT'} transferToOperatorMethod The method of transfer to the operator.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {string} [textForTransfer] The text response of the bot, which will be considered a transfer to the operator.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleStatsApi
     */
    getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options) {
        return SimpleStatsApiFp(this.configuration).getSwitchedSessionCountByDay(accountId, dateFrom, dateTo, transferToOperatorMethod, projectShortName, botIds, textForTransfer, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get telephony statistics
     * @param {number} accountId Account identifier
     * @param {string} dateFrom The date from which the report will be built.
     * @param {string} dateTo The date by which the report will be built.
     * @param {string} [projectShortName] Project short name
     * @param {Array<string>} [botIds] List of string bot ids.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleStatsApi
     */
    getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options) {
        return SimpleStatsApiFp(this.configuration).getTelephonyStats(accountId, dateFrom, dateTo, projectShortName, botIds, timezone, options)(this.axios, this.basePath);
    }
}
/**
 * StateRoutesApi - axios parameter creator
 * @export
 */
export const StateRoutesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get state routes by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getStateRoutesDataByFilter.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/state-routes/filter`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * StateRoutesApi - functional programming interface
 * @export
 */
export const StateRoutesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get state routes by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            const localVarAxiosArgs = StateRoutesApiAxiosParamCreator(configuration).getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * StateRoutesApi - factory interface
 * @export
 */
export const StateRoutesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get state routes by filter
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
            return StateRoutesApiFp(configuration).getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(axios, basePath);
        },
    };
};
/**
 * StateRoutesApi - object-oriented interface
 * @export
 * @class StateRoutesApi
 * @extends {BaseAPI}
 */
export class StateRoutesApi extends BaseAPI {
    /**
     *
     * @summary Get state routes by filter
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateRoutesApi
     */
    getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options) {
        return StateRoutesApiFp(this.configuration).getStateRoutesDataByFilter(accountId, projectShortName, page, size, filtersDto, options)(this.axios, this.basePath);
    }
}
/**
 * TextCampaignReportApi - axios parameter creator
 * @export
 */
export const TextCampaignReportApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Text campaign results report
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReport(accountId, textCampaignId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTextCampaignResultReport.");
            }
            // verify required parameter 'textCampaignId' is not null or undefined
            if (textCampaignId === null || textCampaignId === undefined) {
                throw new RequiredError("textCampaignId", "Required parameter textCampaignId was null or undefined when calling getTextCampaignResultReport.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/text-campaign/{textCampaignId}/results`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"textCampaignId"}}`, encodeURIComponent(String(textCampaignId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Text campaign result report xlsx
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReportXlsx(accountId, textCampaignId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTextCampaignResultReportXlsx.");
            }
            // verify required parameter 'textCampaignId' is not null or undefined
            if (textCampaignId === null || textCampaignId === undefined) {
                throw new RequiredError("textCampaignId", "Required parameter textCampaignId was null or undefined when calling getTextCampaignResultReportXlsx.");
            }
            const localVarPath = `/api/reporter/accounts/{accountId}/text-campaign/{textCampaignId}/result-report`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"textCampaignId"}}`, encodeURIComponent(String(textCampaignId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TextCampaignReportApi - functional programming interface
 * @export
 */
export const TextCampaignReportApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Text campaign results report
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReport(accountId, textCampaignId, options) {
            const localVarAxiosArgs = TextCampaignReportApiAxiosParamCreator(configuration).getTextCampaignResultReport(accountId, textCampaignId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Text campaign result report xlsx
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReportXlsx(accountId, textCampaignId, options) {
            const localVarAxiosArgs = TextCampaignReportApiAxiosParamCreator(configuration).getTextCampaignResultReportXlsx(accountId, textCampaignId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TextCampaignReportApi - factory interface
 * @export
 */
export const TextCampaignReportApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Text campaign results report
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReport(accountId, textCampaignId, options) {
            return TextCampaignReportApiFp(configuration).getTextCampaignResultReport(accountId, textCampaignId, options)(axios, basePath);
        },
        /**
         *
         * @summary Text campaign result report xlsx
         * @param {number} accountId Account identifier
         * @param {number} textCampaignId Text campaign identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextCampaignResultReportXlsx(accountId, textCampaignId, options) {
            return TextCampaignReportApiFp(configuration).getTextCampaignResultReportXlsx(accountId, textCampaignId, options)(axios, basePath);
        },
    };
};
/**
 * TextCampaignReportApi - object-oriented interface
 * @export
 * @class TextCampaignReportApi
 * @extends {BaseAPI}
 */
export class TextCampaignReportApi extends BaseAPI {
    /**
     *
     * @summary Text campaign results report
     * @param {number} accountId Account identifier
     * @param {number} textCampaignId Text campaign identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextCampaignReportApi
     */
    getTextCampaignResultReport(accountId, textCampaignId, options) {
        return TextCampaignReportApiFp(this.configuration).getTextCampaignResultReport(accountId, textCampaignId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Text campaign result report xlsx
     * @param {number} accountId Account identifier
     * @param {number} textCampaignId Text campaign identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextCampaignReportApi
     */
    getTextCampaignResultReportXlsx(accountId, textCampaignId, options) {
        return TextCampaignReportApiFp(this.configuration).getTextCampaignResultReportXlsx(accountId, textCampaignId, options)(this.axios, this.basePath);
    }
}
