import { useMemo } from 'react';

import { t } from 'localization';

import { buildCustomValidationResolver } from 'utils/validator/customValidationResolver';

import { TokenType } from '../external';

import * as api from './api';

export function useKhubTokenValidationResolver() {
  const [, getKnowledgeBaseByTokenCall] = api.useGetKnowledgeBaseByTokenApi();

  return useMemo(() => {
    return buildCustomValidationResolver<TokenType>(async values => {
      const errors: any = {};
      if (!values.value) errors['value'] = t('KhubIntegration:Errors:RequiredField');
      else {
        await getKnowledgeBaseByTokenCall(values.value).catch((err: Error) => {
          errors['value'] = err.message;
        });
      }
      return errors;
    });
  }, [getKnowledgeBaseByTokenCall]);
}
