var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { Modal } from '@just-ai/just-ui';
import { useLabelsContext } from '../../../../Labels';
import { LabelsGroups } from '../MessagesList/Message/LabelsGroups';
import { useAnalyticsContext } from '../../../context/AnalyticsContext';
export default function MassLabelsModal({ t, onClose, onSubmit, isSession, }) {
    const [labels, setLabels] = useState([]);
    const { labelsContext } = useAnalyticsContext();
    const { hasTagCreatePermission } = useLabelsContext();
    const groups = isSession
        ? [{ labels: labelsContext.sessionLabels, id: 1, name: '', priority: 1 }]
        : labelsContext.groups;
    const onChange = useCallback((label) => {
        setLabels(prevState => {
            if (prevState.find(x => x.id === label.id)) {
                return prevState.filter(x => x.id !== label.id);
            }
            return [...prevState, label];
        });
    }, []);
    const handleSubmit = useCallback((e) => {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        onSubmit(labels);
    }, [labels]);
    const onCreate = useCallback((labelName, labelGroupId) => __awaiter(this, void 0, void 0, function* () {
        return isSession ? labelsContext.createSessionLabel(labelName) : labelsContext.createLabel(labelName, labelGroupId);
    }), []);
    return (_jsx(Modal, { isOpen: true, title: t('Analytics: set mass labels'), buttonSubmitText: t('Analytics: messages add labels'), onActionClick: handleSubmit, onCancelClick: onClose, buttonSubmitDisabled: !hasTagCreatePermission, children: _jsx(LabelsGroups, { groups: groups, currentLabels: labels, onChange: onChange, t: t, createNewLabelAndAddToMessage: onCreate }) }));
}
