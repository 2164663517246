import React, { useEffect, lazy, Suspense, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localize from 'localization';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AmplitudeProvider } from 'react-amplitude-hooks';

import { Spinner } from '@just-ai/just-ui';
import { usePrevious } from '@just-ai/just-ui/dist/utils';

import FAQTemplate from '@just-ai/nlu-modules/dist/views/FAQTemplatePage';
import FAQ from '@just-ai/nlu-modules/dist/views/FAQTreePage';

import { SpeedAudioContextProvider } from '@just-ai/analytic-front/dist/Reporter/context/SpeedAudioContext';

import DeployHistory from './views/DeployHistory';
import isAccess, {
  hasFeatures,
  isReporterEnabled,
  isAccountManager,
  isInternal,
  isLoggedIn,
  isLoggedInAccount,
  isSystemAccess,
} from './isAccessFunction';

import { addMessage as addMessageRedux } from 'actions/globalAlert.actions';
import { loadChannels as loadChannelsRedux } from 'actions/channels.actions';

// Containers
import Full from './containers/Full/';
import NonProjectedPage from './containers/NonProjectedPage';

import Page404 from './views/Pages/Page404/Page404';
import Page503 from './views/Pages/Page503';
import Page403 from './views/Pages/Page403/Page403';

import { access } from 'modules/KnowledgeBaseLLM';
import history from './appHistory';
import { useAppContext } from './modules/Caila/components/AppContext';
import OAuthProvider from './components/OAuthBehaviour/OAuthProvider';
import AppErrorBoundary from './components/AppErrorBoundary';
import NluModuleWrapper from './components/NLUModuleWrapper';
import { WSContextProvider } from './modules/Notifications/context/WSContext';
import { NotificationsProvider } from './modules/Notifications/context/NotificationsContext';
import { TasksProvider } from './modules/Notifications/context/TasksContext';
import { TasksList } from './modules/Notifications/components/Tasks/TasksList';
import { AnalyticsProvider, AnalyticsContext, LabelsContextProvider, LabelsContext } from '@just-ai/analytic-front';
import { cloudDomainsService } from 'services/CloudDomainsService';

import NotificationsList from './modules/Notifications/components/Notifications/NotificationsList';

import { AnalyticConfig } from './analyticConfig';
import { amplitudeInstance, axios, getAmplitudeKey } from './pipes/functions';

import Logout from './views/Pages/Logout';
import ProjectGroupsContextProvider from './modules/ProjectGroups/context';
import { JGraphIframeWrapper } from './views/JGraphIframeWrapper';
import { useAppDispatch, useAppSelector } from './storeHooks';

const ProjectReports = lazy(() => import('./modules/ProjectGroups/components/ProjectReports'));
const AcceptInvitationByEmail = lazy(() => import('./modules/ProjectGroups/components/AcceptInvitationByEmail'));

const JGraph = lazy(() => import('./modules/JGraph/view/JGraphWrapper'));
const Dialogs = lazy(() => import('./views/Dialogs'));
const CreateProject = lazy(() => import('./views/CreateProject'));
const ProjectItem = lazy(() => import('./views/ProjectItem'));
const Tokens = lazy(() => import('./modules/Tokens/page'));
const BotScorer = lazy(() => import('./views/BotScorer'));
const ClientsListWrapper = lazy(() => import('./views/ClientsListWrapper'));
const ClientDetailWrapper = lazy(() => import('./views/ClientDetailWrapper/'));
const LogsMaskingPage = lazy(() => import('modules/Logs/pages/LogsMaskingPage'));
const EditRulePage = lazy(() => import('modules/Logs/pages/EditRulePage'));
const PageFlow = lazy(() => import('./views/pageflow/'));
const SystemStatus = lazy(() => import('./views/SystemStatus'));
const Channels = lazy(() => import('./views/Channels'));

const DetailDialog = lazy(() => import('./views/SystemDialogDetail'));
const Experiments = lazy(() => import('./views/Experiments'));
const SystemDialogsSettings = lazy(() => import('./views/SystemDialogsSettings'));
const TokensSettings = lazy(() => import('./views/TokensSettings'));
const Tasks = lazy(() => import('./views/Tasks'));
const DeployInfo = lazy(() => import('./views/DeployInfo'));
const UserLogPage = lazy(() => import('./modules/UserLogs/UserLogPage'));
const Licenses = lazy(() => import('./views/licenses'));
const CallLists = lazy(() => import('./modules/MailingList'));
const ConnectionsList = lazy(() => import('./views/ConnectionsList'));
const TelephonySettings = lazy(() => import('./views/ConnectionsList/TelephonySettings'));
const CallTaskList = lazy(() => import('./views/CallTaskList'));
const TextCampaign = lazy(() => import('./modules/TextCampaign'));
const CallTaskEdit = lazy(() => import('./views/CallTaskEdit'));
const KeysAndPlans = lazy(() => import('./views/KeysAndPlans'));
const ChatWidgetEditPage = lazy(() => import('./views/ChatWidgetEditPage'));
const AccountManager = lazy(() => import('./views/AccountManager'));
const TariffPage = lazy(() => import('./views/TariffPage'));
const Caila = lazy(() => import('./views/Caila'));
const EditorPage = lazy(() => import('./modules/Editor/pages/EditorPage'));
const TelephonyOverview = lazy(() => import('./views/TelephonyOverview'));
const PartnerPayments = lazy(() => import('./views/PartnerPayments'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const Imputer = lazy(() => import('./modules/Imputer/ImputerPage'));
const AnalyticsTags = lazy(() => import('./views/AnalyticsTags'));
const Reports = lazy(() => import('./views/Reports'));
const KnowledgeBaseLLMPage = lazy(() => import('./views/KnowledgeBaseLLMPage'));

export const EVENT_PATH = '/app/account-notifications';

const SADMIN_PAGES = ['/partner_payments', '/system/status', '/keysandplans'];

const { translate } = localize;

function redirectUnloginUser({ location, pathname }) {
  if (!isLoggedIn()) {
    return true;
  } else if (
    isInternal() &&
    !isLoggedInAccount() &&
    !SADMIN_PAGES.includes(pathname) &&
    !pathname.includes('/accountmanager/')
  ) {
    return (
      <Redirect
        to={{
          pathname: '/system/status',
          state: { from: location },
        }}
      />
    );
  }
}

const PrivateRoute = connect(store => ({
  currentLanguage: store.CurrentUserReducer.language,
}))(({ component: Component, options, checker, ...rest }) => {
  const { setProjectShortName, setCurrentLang, setAppConfig } = useAppContext();
  const { appConfig } = useAppSelector(store => ({ appConfig: { botadmin: store.AppConfigReducer.botadmin } }));
  useEffect(() => setProjectShortName(''), [setProjectShortName]);

  useEffect(() => setCurrentLang(rest.currentLanguage), [rest.currentLanguage, setCurrentLang]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAppConfig(appConfig), [appConfig.botadmin, setAppConfig]);

  return (
    <Route
      {...rest}
      render={props => {
        const { location } = props;
        const redirect = redirectUnloginUser({ location, pathname: location.pathname });
        if (redirect) return redirect;
        if (location.search.startsWith('?template')) {
          if (isAccess('framework.cloud')) {
            return (
              <Redirect
                to={{
                  pathname: '/project-create/jaicf/clone',
                  state: {
                    from: props.location,
                    gitTemplate: props.location.search,
                  },
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/project-create/jaicf',
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }
        if (
          (!rest.access || isAccess(rest.access) || isAccess(rest.orAccess)) &&
          (!rest.systemAccess || isSystemAccess(rest.systemAccess))
        ) {
          return (
            <NonProjectedPage>
              <Component {...props} />
            </NonProjectedPage>
          );
        } else {
          if ((isInternal() && !isLoggedInAccount()) || isAccountManager()) {
            window.location.href = `${cloudDomainsService.getFullDomainPath('cc')}/c/accounts`;
            return;
          }
          return (
            <Redirect
              to={{
                pathname: '/pages/403',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
});

const ProjectPrivateRoute = memo(({ component: Component, checker, ...rest }) => {
  const { appConfig, currentAccount, projectList, projectShortName, currentLanguage, currentUser } = useAppSelector(
    store => ({
      appConfig: {
        botadmin: store.AppConfigReducer.botadmin,
        paraphrase: store.AppConfigReducer.paraphrase,
      },
      currentAccount: store.CurrentAccountReducer.account,
      currentLanguage: store.CurrentUserReducer.language,
      currentUser: store.CurrentUserReducer.currentUser,
      projectList: store.ProjectsReducer.projectList,
      projectShortName: store.CurrentProjectsReducer.currentProject,
    })
  );

  const dispatch = useAppDispatch();
  const { loadChannels, addMessage } = useMemo(() => {
    return {
      loadChannels: bindActionCreators(loadChannelsRedux, dispatch),
      addMessage: bindActionCreators(addMessageRedux, dispatch),
    };
  }, [dispatch]);

  const { setCurrentProject, setCurrentLang, setAppConfig, SettingsService } = useAppContext();

  const prevShortName = usePrevious(projectShortName);

  const currentProject = useMemo(() => {
    return projectList.find(project => project.shortName === projectShortName);
  }, [projectList, projectShortName]);

  useEffect(() => {
    if (!currentProject && rest.name !== 'Client page') return history.push('/404');
    if (!isAccess(['nlu', 'NLU_READ'])) return undefined;
    const projectSetter = async () => {
      if (currentAccount?.id && projectShortName && isAccess(['nlu', 'NLU_READ'])) {
        let projectSettings = (await SettingsService.getProjectSettings()).data;
        projectSettings = { ...currentProject, ...projectSettings, ...projectSettings.extendedSettings };
        if (prevShortName !== projectShortName) {
          setCurrentProject(projectSettings);
        }
      }
    };
    projectSetter();
  }, [
    SettingsService,
    currentAccount?.id,
    currentProject,
    projectShortName,
    setCurrentProject,
    prevShortName,
    rest.name,
  ]);

  useEffect(() => {
    setCurrentProject(currentProject);
  }, [currentProject, setCurrentProject]);
  useEffect(() => setCurrentLang(currentLanguage), [currentLanguage, setCurrentLang]);
  // eslint-disable-next-line
  useEffect(() => setAppConfig(appConfig), [appConfig.botadmin, setAppConfig]);

  Component = useMemo(() => {
    if (checker) {
      return checker(currentProject);
    }
    return Component;
  }, [Component, checker, currentProject]);

  const mainAccess = !rest.access || isAccess(rest.access);
  const altAccess = isAccess(rest.orAccess);
  const systemAccess = !rest.systemAccess || isSystemAccess(rest.systemAccess);
  const altSystemAccess = isSystemAccess(rest.altSystemAccess);
  const isHasAccess = (mainAccess || altAccess) && (systemAccess || altSystemAccess);

  if (!currentAccount?.id) {
    return history.push('/');
  }
  return (
    <Route
      {...rest}
      render={props => {
        const { location } = props;
        const redirect = redirectUnloginUser({ location, pathname: location.pathname });
        if (redirect) return redirect;
        if (isHasAccess) {
          if (!rest.computedMatch.isExact) {
            return (
              <Redirect
                to={{
                  pathname: '/404',
                  state: { from: props.location },
                }}
              />
            );
          }
          if (Boolean(projectShortName) && Boolean(Component)) {
            return (
              <>
                <AnalyticsProvider
                  accountId={currentAccount.id}
                  projectShortName={projectShortName}
                  t={translate}
                  locale={currentLanguage}
                  history={history}
                  config={AnalyticConfig()}
                  axios={axios}
                  useReporter={isReporterEnabled()}
                  timeZone={currentUser?.timeZone}
                  isAccess={isAccess}
                >
                  <AppErrorBoundary prefix='ProjectPrivateRoute:Content'>
                    <Component {...props} {...rest} />
                  </AppErrorBoundary>
                  <AnalyticsContext.Consumer>
                    {({ deleteReportGenerationTask, deleteCompletedReportGenerationTasks }) => (
                      <TasksProvider
                        projectShortName={projectShortName}
                        deleteReportGenerationTask={deleteReportGenerationTask}
                        deleteCompletedReportGenerationTasks={deleteCompletedReportGenerationTasks}
                        locale={currentLanguage === 'ru' ? 'ru' : 'en'}
                        addMessage={addMessage}
                      >
                        <TasksList />
                      </TasksProvider>
                    )}
                  </AnalyticsContext.Consumer>
                </AnalyticsProvider>
                <NotificationsProvider
                  projectShortName={projectShortName}
                  addMessage={addMessage}
                  loadChannels={loadChannels}
                >
                  <NotificationsList locale={currentLanguage === 'ru' ? 'ru' : 'en'} />
                </NotificationsProvider>
              </>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/404',
                  state: { from: props.location },
                }}
              />
            );
          }
        } else {
          if (isInternal() || isAccountManager()) {
            window.location.href = `${cloudDomainsService.getFullDomainPath('cc')}/c/accounts`;
            return;
          }
          return (
            <Redirect
              to={{
                pathname: '/pages/403',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
});
ProjectPrivateRoute.displayName = 'ProjectPrivateRoute';

const ProjectRedirect = props => {
  const { currentProject } = useAppSelector(store => ({
    currentProject: store.CurrentProjectsReducer.currentProject,
  }));
  let newProps = { ...props };
  newProps.from = newProps.from.replace(/:projectShortName/g, currentProject);
  newProps.to = newProps.to.replace(/:projectShortName/g, currentProject);
  return <Redirect {...newProps} />;
};
const RouteLabelsContextProvider = ({ children }) => {
  const { accountId } = useAppContext();

  return (
    <LabelsContextProvider
      accountId={accountId}
      translate={localize.translate}
      useReporter={isReporterEnabled()}
      disabled={false}
      config={AnalyticConfig()}
    >
      {children}
    </LabelsContextProvider>
  );
};
export default (
  <AmplitudeProvider amplitudeInstance={amplitudeInstance} apiKey={getAmplitudeKey()} userId={undefined}>
    <ConnectedRouter history={history}>
      <Router history={history}>
        <OAuthProvider>
          <Switch>
            <Route path='/logout' exact name='Login Page' component={Logout} />
            <ProjectGroupsContextProvider>
              <WSContextProvider>
                <Full path='/'>
                  <RouteLabelsContextProvider>
                    <Suspense fallback={<Spinner />}>
                      <Switch>
                        <Route path='/503' exact name='503' component={Page503} />
                        <Route path='/404' exact name='404' component={Page404} />

                        <PrivateRoute access={['PROJECTS_WRITE']} path='/project-create*' component={CreateProject} />
                        <PrivateRoute path='/projects' name='Projects' component={Dashboard} />

                        <PrivateRoute
                          access={['LICENSE_KEYS_VIEW']}
                          path='/licenses'
                          name='Licenses'
                          component={Licenses}
                        />

                        <PrivateRoute
                          access={['LICENSE_KEYS_VIEW']}
                          path='/keysandplans'
                          name='Licenses keys'
                          component={KeysAndPlans}
                        />

                        <PrivateRoute
                          access={['ACCOUNTS_READ']}
                          systemAccess={['botadmin_partner_feature']}
                          path='/partner_payments'
                          exact
                          component={PartnerPayments}
                        />

                        <PrivateRoute
                          access={['SPECIFIC_ACCOUNT_MANAGEMENT']}
                          orAccess={['ACCOUNTS_TARIFF_READ']}
                          systemAccess={['botadmin_billing_feature']}
                          path='/accountmanager/:accountId/:ownerId'
                          exact
                          component={AccountManager}
                        />

                        <PrivateRoute
                          access={['SPECIFIC_ACCOUNT_MANAGEMENT']}
                          orAccess={['ACCOUNTS_TARIFF_READ']}
                          systemAccess={['botadmin_billing_feature']}
                          path='/accountmanager'
                          exact
                          component={AccountManager}
                        />

                        <PrivateRoute path='/project-reports' exact component={ProjectReports} />

                        <PrivateRoute path='/accept-invitation' exact component={AcceptInvitationByEmail} />

                        <PrivateRoute
                          access={['ACCOUNTS_TARIFF_READ']}
                          orAccess={['SPECIFIC_ACCOUNT_MANAGEMENT']}
                          systemAccess={['botadmin_billing_feature']}
                          path='/plans'
                          exact
                          component={TariffPage}
                        />

                        <PrivateRoute
                          access={['MASKING_RULE_READ']}
                          exact
                          path='/logsmasking'
                          component={LogsMaskingPage}
                        />

                        <PrivateRoute
                          access={['MASKING_RULE_READ']}
                          exact
                          path='/logsmasking/:ruleId'
                          component={EditRulePage}
                        />

                        <ProjectRedirect exact from='/clientslist' to='/:projectShortName/clientslist' />

                        <ProjectPrivateRoute
                          access={['new-analytics']}
                          path='/:projectShortName/analytics_tags'
                          exact
                          component={AnalyticsTags}
                        />

                        <ProjectPrivateRoute
                          access={['new-analytics']}
                          path='/:projectShortName/reports'
                          exact
                          component={Reports}
                        />

                        <ProjectPrivateRoute
                          access={['GENERAL_ANALYTICS_READ']}
                          systemAccess={['botadmin_dialogs_feature']}
                          path='/:projectShortName/clientslist'
                          exact
                          name='Clients'
                          component={ClientsListWrapper}
                        />

                        <ProjectPrivateRoute
                          access={['ALL']}
                          // systemAccess={['jgraph_licensed']}
                          // Allow access for everyone
                          path='/:projectShortName/jgraph'
                          exact
                          component={() => {
                            const { IntentsService, SettingsService, currentProject } = useAppContext();
                            const { uiMode } = useAppSelector(store => ({
                              uiMode: store.CurrentUserReducer.uiMode,
                            }));
                            if (!currentProject?.name) return null;
                            return (
                              <JGraph
                                IntentsService={IntentsService}
                                SettingsService={SettingsService}
                                projectName={currentProject?.name}
                                uiMode={uiMode}
                              />
                            );
                          }}
                        />
                        <ProjectPrivateRoute
                          access={['ALL']}
                          // systemAccess={['jgraph_licensed']}
                          // Allow access for everyone
                          path='/:projectShortName/iFrameJgraph'
                          exact
                          component={JGraphIframeWrapper}
                        />

                        <ProjectPrivateRoute
                          access={['GENERAL_ANALYTICS_READ']}
                          systemAccess={['botadmin_dialogs_feature']}
                          path='/clientslist/:id/:channelType/:botId'
                          name='Client page'
                          component={ClientDetailWrapper}
                        />
                        <ProjectPrivateRoute
                          access={['GENERAL_ANALYTICS_READ']}
                          systemAccess={['botadmin_dialogs_feature']}
                          path='/:projectShortName/clientslist/:id/:channelType/:botId'
                          name='Client dialog page'
                          component={ClientDetailWrapper}
                        />

                        <ProjectPrivateRoute
                          access='ALL'
                          path='/:projectShortName/experiments'
                          exact
                          name='Experiments'
                          component={Experiments}
                        />

                        <PrivateRoute path='/calllists' name='Clients lists' component={CallLists} />

                        <PrivateRoute
                          access='SIP_TRUNK_WRITE'
                          path='/connectionslist/telephonysettings'
                          name='Phone settigns'
                          component={TelephonySettings}
                        />

                        <PrivateRoute
                          access='SIP_TRUNK_READ'
                          path='/connectionslist'
                          name='Connections list'
                          component={ConnectionsList}
                        />

                        <PrivateRoute access='AUDIT_READ' path='/userlog' name='User logs' component={UserLogPage} />

                        <ProjectPrivateRoute
                          path='/:projectShortName/chatwidgetEditor/:channelId'
                          name='EditorChatWidget'
                          component={ChatWidgetEditPage}
                        />

                        <ProjectPrivateRoute
                          access='CODE_READ'
                          path={['/:projectShortName/editor', '/:projectShortName/editor/visual']}
                          exact
                          name='Editor editor'
                          checker={currentProject => {
                            if (!currentProject?.jaicfProject || currentProject?.environment === 'jaicpCloud') {
                              return EditorPage;
                            }
                            if (currentProject?.environment === 'external') {
                              return () => <Redirect to={`/${currentProject?.shortName}/channels`} />;
                            }
                            return;
                          }}
                        />

                        <ProjectPrivateRoute
                          access={['NLU_READ', 'nlu']}
                          systemAccess={['botadmin_nlu_feature']}
                          path='/:projectShortName/intents/:query?'
                          name='Intents'
                          component={Caila}
                        />

                        <ProjectPrivateRoute
                          access={['NLU_READ', 'nlu']}
                          systemAccess={['botadmin_nlu_feature']}
                          path='/:projectShortName/entities/:type?/:query?'
                          name='Entities'
                          component={Caila}
                        />

                        <ProjectPrivateRoute
                          access={['BOT_CONTENT_READ', 'nlu']}
                          systemAccess={['botadmin_nlu_feature', 'knowledge_base']}
                          exact
                          path='/:projectShortName/faq-template'
                          name='Creation FAQ page'
                          component={() => (
                            <NluModuleWrapper>
                              <FAQTemplate />
                            </NluModuleWrapper>
                          )}
                        />

                        <ProjectPrivateRoute
                          access={['BOT_CONTENT_READ', 'nlu']}
                          systemAccess={['botadmin_nlu_feature', 'knowledge_base']}
                          exact
                          path='/:projectShortName/faq'
                          name='FAQ page'
                          component={() => (
                            <NluModuleWrapper>
                              <FAQ />
                            </NluModuleWrapper>
                          )}
                        />

                        <ProjectPrivateRoute
                          access={access.accountFeatures}
                          systemAccess={access.systemFeatures}
                          name='Knowledge base llm page'
                          exact
                          path='/:projectShortName/khub'
                          component={KnowledgeBaseLLMPage}
                        />

                        <ProjectPrivateRoute
                          access={['BOT_CONTENT_READ', 'nlu', 'nlu.log_labeling']}
                          systemAccess={['botadmin_nlu_feature', 'botadmin_log_labeling_feature']}
                          path='/:projectShortName/log-labeling/:type?/:query?'
                          name='Log markup'
                          component={Caila}
                        />
                        <ProjectPrivateRoute
                          access='BOT_CONFIG_READ'
                          path='/:projectShortName/channels'
                          exact
                          name='Channels'
                          component={Channels}
                        />

                        <ProjectPrivateRoute
                          path='/:projectShortName/channels/:id/deployments'
                          name='History and results of deploy'
                          component={DeployHistory}
                        />

                        <PrivateRoute path='/system/status' name='System' component={SystemStatus} />

                        <PrivateRoute
                          access={['ACCOUNTS_LOGIN_READ']}
                          path='/system/deploy-info'
                          component={DeployInfo}
                        />

                        <ProjectRedirect
                          exact
                          from='/:projectShortName/statistic'
                          to={
                            hasFeatures('pageflow')
                              ? '/:projectShortName/statistic/pageflow'
                              : '/:projectShortName/statistic/dialogs'
                          }
                        />

                        <ProjectPrivateRoute
                          access='CLIENT_ANALYTICS_READ'
                          systemAccess={['botadmin_dialogs_feature']}
                          path='/:projectShortName/statistic/pageflow'
                          name='PageFlow'
                          component={PageFlow}
                        />

                        <ProjectPrivateRoute
                          access={['GENERAL_ANALYTICS_READ']}
                          systemAccess={['botadmin_dialogs_feature']}
                          exact
                          path='/:projectShortName/statistic/dialogs'
                          component={() => (
                            <LabelsContext.Consumer>
                              {({
                                loaded,
                                groups,
                                sessionLabels,
                                getLabels,
                                issuesPriorities,
                                issuesLabels,
                                issuesStates,
                              }) => (
                                <Dialogs
                                  getLabels={getLabels}
                                  labelsContextInited={loaded}
                                  groups={groups}
                                  sessionLabels={sessionLabels}
                                  issuesPriorities={issuesPriorities}
                                  issuesLabels={issuesLabels}
                                  issuesStates={issuesStates}
                                />
                              )}
                            </LabelsContext.Consumer>
                          )}
                        />

                        <ProjectPrivateRoute
                          access={['GENERAL_ANALYTICS_READ']}
                          systemAccess={['botadmin_dialogs_feature']}
                          path='/:projectShortName/statistic/dialogs/:id'
                          component={() => {
                            return (
                              <LabelsContext.Consumer>
                                {({
                                  loaded,
                                  groups,
                                  sessionLabels,
                                  getLabels,
                                  issuesPriorities,
                                  issuesLabels,
                                  issuesStates,
                                }) => (
                                  <SpeedAudioContextProvider>
                                    <DetailDialog
                                      getLabels={getLabels}
                                      labelsContextInited={loaded}
                                      groups={groups}
                                      sessionLabels={sessionLabels}
                                      issuesPriorities={issuesPriorities}
                                      issuesLabels={issuesLabels}
                                      issuesStates={issuesStates}
                                    />
                                  </SpeedAudioContextProvider>
                                )}
                              </LabelsContext.Consumer>
                            );
                          }}
                        />
                        <ProjectPrivateRoute
                          access='GENERAL_ANALYTICS_READ'
                          systemAccess={['botadmin_dialogs_feature']}
                          exact
                          path='/:projectShortName/statistic/tasks'
                          component={Tasks}
                        />
                        <ProjectPrivateRoute
                          access='ALL'
                          systemAccess={['botadmin_telephony_feature']}
                          exact
                          path='/:projectShortName/statistic/telephony'
                          component={TelephonyOverview}
                          reportTypeOptions={[
                            {
                              value: 'telephony',
                              label: 'Phone channel usage',
                            },
                          ]}
                          useTelephonyReportType={true}
                        />

                        <ProjectPrivateRoute
                          access='ALL'
                          exact
                          path='/:projectShortName/statistic/operator'
                          component={TelephonyOverview}
                          reportTypeOptions={[
                            {
                              value: 'transfer',
                              label: 'Transferred to agent',
                            },
                          ]}
                          useTransferReportType={true}
                        />

                        <ProjectPrivateRoute
                          access='DIALER_CALL_TASK_READ'
                          systemAccess={['botadmin_dialer_feature']}
                          path='/:projectShortName/calltasklist'
                          name='Call list'
                          component={CallTaskList}
                        />

                        <ProjectPrivateRoute
                          access='DIALER_CALL_TASK_READ'
                          path='/:projectShortName/mailingtasks'
                          name='Mailing list'
                          component={TextCampaign}
                        />

                        <ProjectPrivateRoute
                          access='DIALER_CALL_TASK_READ'
                          systemAccess={['botadmin_dialer_feature']}
                          path='/:projectShortName/calltask'
                          name='Call settings'
                          component={CallTaskEdit}
                        />

                        <ProjectPrivateRoute path='/:projectShortName/tokens' name='Tokens list' component={Tokens} />
                        <ProjectPrivateRoute
                          access={['BOT_CONTENT_READ', 'botscorer']}
                          systemAccess={['botscorer']}
                          path='/:projectShortName/bot-scorer'
                          name='Bot-scorer'
                          component={BotScorer}
                        />

                        <PrivateRoute
                          access='SPECIFIC_ACCOUNT_MANAGEMENT'
                          exact
                          path='/tokens'
                          component={TokensSettings}
                        />
                        <PrivateRoute access='ALL' exact path='/tagsettings' component={SystemDialogsSettings} />

                        <Route path='/pages/403' component={Page403} />

                        <PrivateRoute path='/imputer' name='Imputer' component={Imputer} />

                        <ProjectPrivateRoute
                          path='/:projectShortName'
                          name='Project overview'
                          checker={currentProject => {
                            if (isAccess('GENERAL_ANALYTICS_READ')) {
                              return ProjectItem;
                            }

                            return () => <Redirect to={`/${currentProject?.shortName}/channels`} />;
                          }}
                        />

                        <PrivateRoute path='/' name='Control' component={Dashboard} />
                        <Route component={Page404} />
                      </Switch>
                    </Suspense>
                  </RouteLabelsContextProvider>
                </Full>
              </WSContextProvider>
            </ProjectGroupsContextProvider>
          </Switch>
        </OAuthProvider>
      </Router>
    </ConnectedRouter>
  </AmplitudeProvider>
);
