import React, { createContext, FC, useContext } from 'react';
import { useOnClickBlockDefault } from '../../../hooks';
import { BaseItemBody, TBaseItemBodyProps } from '../BaseItemBody';
import { EditMenuBlock } from '../../../../../reducers/JGraph.reducer/types';
import { TagNames, TJBlock } from '../../../utils/types';
import { RenderBlocks, useRenderBlocksContext } from '../RenderBlocks';
import { Group, Rect } from 'react-konva';

interface ConditionBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick' | 'blockId'> {
  screenBlock: TJBlock;
  currentContextIndex: number;
  currentLevelBlocks: TJBlock[];
  prefix?: string;
  editMenuBlock?: EditMenuBlock;
  cornerRadius?: number | number[];
  yOffset?: number;
}

type ConditionBodyContextType = {
  blocks: TJBlock[];
};
export const ConditionBodyContext = createContext({} as ConditionBodyContextType);
export const useConditionBodyContext = () => useContext(ConditionBodyContext);
export const ConditionBody: FC<ConditionBodyProps> = ({
  globalJBlockIterator,
  screenBlock,
  prefix,
  editMenuBlock,
  currentContextIndex,
  currentLevelBlocks,
  ...rest
}) => {
  const { baseWidth, activeType, screen } = useRenderBlocksContext();
  const { onClickDefault } = useOnClickBlockDefault(screen, undefined, prefix);
  return (
    <ConditionBodyContext.Provider value={{ blocks: currentLevelBlocks }}>
      <BaseItemBody
        {...rest}
        currentContextIndex={currentContextIndex}
        globalJBlockIterator={globalJBlockIterator}
        screenBlock={screenBlock}
        active={activeType === 'border' ? rest.active : false}
        onClick={onClickDefault}
        title={`${screenBlock.tagName}: ${screenBlock.tagValue}`}
        collapsible={true}
        hasChildren={screenBlock.tagName === TagNames.else ? screenBlock.jblocks.length > 0 : true}
        renderChild={({ collapsed }) => (
          <Group>
            {[TagNames.else, TagNames.elseif].includes(screenBlock.tagName) && baseWidth === 280 && (
              <Rect x={-11} y={-2} width={280 - 24 - 2} height={4} fill='white' />
            )}
            {!collapsed && (
              <Group y={28} x={-12}>
                <RenderBlocks
                  blocks={screenBlock.jblocks}
                  baseWidth={baseWidth - 13}
                  activeType='background'
                  prefix={prefix}
                  externalGlobalIndex={globalJBlockIterator}
                />
              </Group>
            )}
          </Group>
        )}
      />
    </ConditionBodyContext.Provider>
  );
};

ConditionBody.displayName = 'ConditionBody';
