import { useEffect } from 'react';
import { useToggle } from './index';
export function useIsMounted() {
    const [isMounted, setMountedTrue, setMountedFalse] = useToggle();
    useEffect(() => {
        setMountedTrue();
        return () => setMountedFalse();
    }, [setMountedTrue, setMountedFalse]);
    return isMounted;
}
