import {
  ACTIVATE_TAB,
  ADD_LOCALE,
  DELETE_LOCALE,
  ADD_NEWS_ITEM_FULFILLED,
  ADD_NEWS_ITEM_PENDING,
  NEW_ITEM,
  NEWS_ITEM_LOAD_FULFILLED,
  NEWS_ITEM_LOAD_PENDING,
  NEWS_ITEM_LOAD_REJECTED,
  NEWS_LOCALE_LOAD_FULFILLED,
  NEWS_LOCALE_LOAD_PENDING,
  NEWS_LOCALE_LOAD_REJECTED,
  SET_PUBLISH_DATE,
  SET_SELECT_CHANNEL,
  TOGGLE_DROPDOWN,
  TOGGLE_NEWS_CLIENT_CHECKED,
  UPDATE_NEWS_ITEM_FULFILLED,
  UPDATE_NEWS_ITEM_REJECTED,
  SELECT_ITEM_LOCALE,
  DROP_SELECTED_USERS,
} from '../../constants/newsItem.actions';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import moment from 'moment';
import 'moment/locale/ru';
import { CHANGE_CURRENT_PROJECT } from '../../constants/projects.actions';

const InitialState = {
  currentNewsItem: {
    localizedNews: [],
    credentials: [],
  },
  title: '',
  locales: {},
  createdLocales: [],
  selectedUsers: [],
  activeTopTab: '1',
  activeLocaleTab: 'RU',
  isDropDown: false,
  fetching: false,
  newsItemSelectedChannel: [],
  now: moment(),
};

export default function NewsItemReducer(state = InitialState, action) {
  switch (action.type) {
    case DROP_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: [],
      };
    }
    case CHANGE_CURRENT_PROJECT: {
      return {
        ...InitialState,
      };
    }
    case ADD_NEWS_ITEM_PENDING:
    case NEWS_ITEM_LOAD_PENDING:
    case NEWS_LOCALE_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case TOGGLE_NEWS_CLIENT_CHECKED: {
      const userId = action.value.id;
      let newSelectedUsersObj = !state.currentNewsItem.credentials ? [] : [...state.currentNewsItem.credentials];
      let index = findIndex(newSelectedUsersObj, user => {
        return user.id === userId;
      });
      if (index > -1) {
        newSelectedUsersObj.splice(index, 1);
      } else {
        newSelectedUsersObj.push({ id: userId });
      }

      let newSelectedUsers = [...state.selectedUsers];
      if (newSelectedUsers.indexOf(userId) > -1) {
        newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
      } else {
        newSelectedUsers.push(userId);
      }

      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          credentials: newSelectedUsersObj,
        },
        selectedUsers: newSelectedUsers,
      };
    }
    case UPDATE_NEWS_ITEM_FULFILLED:
    case NEWS_ITEM_LOAD_FULFILLED: {
      let locales = [];
      let createdLocales = [];
      let title = '';
      each(action.payload.data.localizedNews, locale => {
        locales.push(locale);
        createdLocales.push(locale.locale);
        if (title === '') {
          title = locale.title;
        }
      });

      if (locales.length === 0) {
        locales.push({
          locale: state.activeLocaleTab,
          title: '',
          body: '',
        });
      }

      return {
        ...state,
        currentNewsItem: {
          ...action.payload.data,
          localizedNews: locales,
        },
        activeTopTab: '1',
        title: title,
        createdLocales: createdLocales,
        fetching: false,
        newsItemSelectedChannel: action.payload.data.types,
        now: action.payload.data.publicationDate ? moment(action.payload.data.publicationDate) : state.now,
      };
    }
    case ACTIVATE_TAB: {
      const type = action.payload.type;
      return {
        ...state,
        [type]: action.payload.tab,
      };
    }
    case NEWS_LOCALE_LOAD_FULFILLED: {
      return {
        ...state,
        locales: action.payload.data,
        fetching: false,
      };
    }
    case TOGGLE_DROPDOWN: {
      return {
        ...state,
        isDropDown: action.payload,
      };
    }
    case ADD_LOCALE: {
      let createdLocales = [...state.createdLocales];
      if (!createdLocales) {
        createdLocales = [];
      }
      let localizedNews = [...state.currentNewsItem.localizedNews];
      if (!localizedNews) {
        localizedNews = [];
      }
      localizedNews.push({
        locale: action.payload,
        title: '',
        body: '',
      });
      createdLocales.push(action.payload);
      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          localizedNews: localizedNews,
        },
        createdLocales: createdLocales,
      };
    }

    case DELETE_LOCALE: {
      let createdLocales = [...state.createdLocales];
      if (!createdLocales) {
        createdLocales = [];
      }
      let localizedNews = [...state.currentNewsItem.localizedNews];
      if (!localizedNews) {
        localizedNews = [];
      }

      let newLocalizedNews = [];
      forEach(localizedNews, item => {
        if (item.locale !== action.payload) {
          newLocalizedNews.push(item);
        }
      });

      let newCreatedLocales = [];
      forEach(newLocalizedNews, item => {
        newCreatedLocales.push(item.locale);
      });

      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          localizedNews: newLocalizedNews,
        },
        createdLocales: newCreatedLocales,
      };
    }

    case NEW_ITEM: {
      let newLocalizedNews = [];
      const date = Date.now();
      newLocalizedNews.push({
        saveDate: date,
        locale: state.activeLocaleTab,
        title: '',
        body: '',
      });
      return {
        ...InitialState,
        currentNewsItem: {
          ...InitialState.currentNewsItem,
          localizedNews: newLocalizedNews,
        },
        createdLocales: [state.activeLocaleTab],
        now: moment(),
      };
    }

    case SELECT_ITEM_LOCALE: {
      let newLocalizedItem = [...state.currentNewsItem.localizedNews];
      newLocalizedItem[action.obj.arrayIndex].locale = action.obj.value;

      let newCreatedLocales = [...state.createdLocales];
      newCreatedLocales[action.obj.arrayIndex] = action.obj.value;
      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          localizedNews: newLocalizedItem,
        },
        createdLocales: newCreatedLocales,
      };
    }

    case ADD_NEWS_ITEM_FULFILLED: {
      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          ...action.payload.data,
        },
      };
    }
    case UPDATE_NEWS_ITEM_REJECTED:
    case NEWS_LOCALE_LOAD_REJECTED:
    case NEWS_ITEM_LOAD_REJECTED: {
      return state;
    }
    case SET_SELECT_CHANNEL: {
      return {
        ...state,
        currentNewsItem: {
          ...state.currentNewsItem,
          credentials: [],
        },
        newsItemSelectedChannel: action.payload,
        selectedUsers: [],
      };
    }
    case SET_PUBLISH_DATE: {
      return {
        ...state,
        now: action.payload,
      };
    }
    default:
      return state;
  }
}
