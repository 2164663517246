import {
  LIST_CALL_TASK_PENDING,
  LIST_CALL_TASK_FULFILLED,
  LIST_CALL_TASK_REJECTED,
  CREATE_CALL_TASK_FULFILLED,
  DELETE_CALL_TASK_FULFILLED,
  DELETE_CALL_TASK_PENDING,
  CONTROL_CALL_TASK_REJECTED,
  CONTROL_CALL_TASK_FULFILLED,
  DELETE_CALL_TASK_REJECTED,
  GET_CALL_TASK_FULFILLED,
  UPDATE_CALL_TASK_FULFILLED,
} from '../../constants/callTask.actions';

const InitialState = {
  callTasks: [],
  fetching: false,
  fetched: false,
  error: null,
  page: 0,
  totalPages: 1,
  pageSize: 10,
};

export default function CallTaskListReducer(state = InitialState, action) {
  switch (action.type) {
    case DELETE_CALL_TASK_PENDING:
    case LIST_CALL_TASK_PENDING:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };

    case LIST_CALL_TASK_FULFILLED:
      const totalPages =
        parseInt((action.payload.data.paging.totalCount - 1) / action.payload.data.paging.pageSize) + 1;
      return {
        ...state,
        callTasks: action.payload.data.records,
        page: action.payload.data.paging.pageNum,
        totalPages: totalPages,
        pageSize: action.payload.data.paging.pageSize,
        fetched: true,
        fetching: false,
      };

    case CONTROL_CALL_TASK_REJECTED:
    case DELETE_CALL_TASK_REJECTED:
    case LIST_CALL_TASK_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data,
      };

    case CREATE_CALL_TASK_FULFILLED:
      let newCallTasks = [action.payload.data, ...state.callTasks];
      let newTotalPages = state.totalPages;
      if (state.callTasks.length % state.pageSize === 0 && state.callTasks.length !== 0) {
        newCallTasks.pop();
        newTotalPages++;
      }
      return {
        ...state,
        callTasks: newCallTasks,
        fetched: true,
        fetching: false,
        totalPages: newTotalPages,
      };

    case GET_CALL_TASK_FULFILLED:
    case UPDATE_CALL_TASK_FULFILLED:
    case CONTROL_CALL_TASK_FULFILLED:
      return {
        ...state,
        callTasks: state.callTasks.map(callTask =>
          callTask.id === action.payload.data.id ? action.payload.data : callTask
        ),
      };

    case DELETE_CALL_TASK_FULFILLED:
      let page = state.page;
      if (state.callTasks.length === 1 && page !== 0) {
        page = page - 1;
        localStorage.setItem('CURRENT_CALLTASKS_PAGE', page);
      }
      return {
        ...state,
        callTasks: state.callTasks.filter(callTask => callTask.id !== action.payload.data.id),
        fetched: true,
        fetching: false,
        page: page,
      };

    default:
      return state;
  }
}
