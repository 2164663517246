import { createAsyncThunk } from '@reduxjs/toolkit';
import { JGraphMoveRequestData } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import { GraphApi } from '../utils';

const moveTheme = createAsyncThunk('moveTheme/moveState', async (moveJStateData: JGraphMoveRequestData, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const { data: graphModel } = await GraphApi.move(userAccountId || accountId, projectShortName, moveJStateData);

  return graphModel;
});

export default moveTheme;
