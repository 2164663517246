import { BaseCompleter } from './BaseCompleter';
import { Ace } from 'ace-builds';

export class ButtonsCompleter extends BaseCompleter {
  getButtonsTemplateValue = (value: string): string => {
    return `"${value}" -> /StatePath`;
  };

  getObjButtonsTemplateValue = (value: string): string => {
    return `{ text: "${value
      .replace('{', '')
      .trim()}", transition: "/StatePath",  request_location: true, one_time_keyboard: false }`;
  };

  customCompleter = (setCursorPositionOffset?: number) => {
    return {
      insertMatch: function (
        insertEditor: Ace.Editor,
        data: { value: string; name: string; score: number; meta: string }
      ) {
        let insertValue = data.value;
        let lastPosition = insertEditor.selection.getCursor();

        insertEditor.navigateLineStart();
        let startPosition = insertEditor.selection.getCursor();

        insertEditor.session.replace(
          {
            start: { row: startPosition.row, column: startPosition.column },
            end: { row: lastPosition.row, column: lastPosition.column },
          } as Ace.Range,
          insertValue
        );
        if (Number.isInteger(setCursorPositionOffset)) {
          insertEditor.moveCursorTo(
            startPosition.row,
            startPosition.column + data.name.length + (setCursorPositionOffset ?? 0)
          );
        }
      },
    };
  };

  getCompletions = (value: string) => {
    if (value.startsWith('{')) {
      return [
        {
          value: this.getObjButtonsTemplateValue(value),
          name: value,
          score: 10000,
          meta: this.NAME,
          completer: value.length > 0 ? this.customCompleter(7) : {},
        },
      ];
    }

    return [
      {
        value: this.getButtonsTemplateValue(value),
        name: value,
        score: 10000,
        meta: this.NAME,
        completer: value.length > 0 ? this.customCompleter(1) : {},
      },
    ];
  };

  hasCompletions = () => true;
}
