var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Nav } from 'reactstrap';
import { DropdownItem, DropdownToggle, DropdownButton, DropdownMenu } from '@just-ai/just-ui/dist/Dropdown';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { IntersectionObserverRootType, ProviderObserverScroll, } from '@just-ai/just-ui/dist/ProviderObserverScroll';
import './style.scss';
import cn from 'classnames';
import Scrollbar from 'react-perfect-scrollbar';
import '@just-ai/just-ui/dist/Tabs/Tabs.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import TabWithContextMenu from './TabWithContextMenu';
const TabsView = function TabView(_a) {
    var { tabs, onChange, activeTab, blackBackground, onClose, className, collapseLimit, dropdownClass, wrapperId = 'tabsWrapper', onVisibilityChange, contextKey, tabSelectProp, renameTab, onTabDisable } = _a, restProps = __rest(_a, ["tabs", "onChange", "activeTab", "blackBackground", "onClose", "className", "collapseLimit", "dropdownClass", "wrapperId", "onVisibilityChange", "contextKey", "tabSelectProp", "renameTab", "onTabDisable"]);
    const [collapsedTabs, setCollapsedTabs] = useState(() => tabs.slice(collapseLimit));
    const [loading, setLoading] = useState(true);
    const [maxWidth, setMaxWidth] = useState(window.innerWidth / 1.6);
    const timeout = useRef();
    useEffect(() => {
        const resizer = () => setMaxWidth(window.innerWidth / 1.6);
        window.addEventListener('resize', resizer);
        return () => window.removeEventListener('resize', resizer);
    }, []);
    useLayoutEffect(() => {
        if (tabs.length - 1 <= collapseLimit)
            setLoading(true);
        setCollapsedTabs(tabs.slice(collapseLimit));
        timeout.current = setTimeout(() => {
            setLoading(false);
        }, 200);
        return () => {
            clearTimeout(timeout.current);
        };
    }, [collapseLimit, tabs]);
    const onTabSelect = useCallback((tab) => {
        tabSelectProp(tab.value)(true);
    }, [tabSelectProp]);
    return (_jsx("div", { className: 'tabs-collapsible__wrapper', id: wrapperId, style: restProps.style, children: _jsxs(Nav, { className: cn('justui_tabs', {
                'justui_tabs-closable': restProps.closable,
                'justui_tabs-dark': blackBackground,
                'justui_tabs-compact': restProps.compact,
                'justui_tabs-bordered': restProps.bordered,
                'justui_tabs-bold': restProps.bold,
                'justui_tabs-fullwidth': restProps.fullWidth,
            }, className), children: [_jsx(ProviderObserverScroll, { contextKey: contextKey, debounceTime: 100, threshold: 1, onChange: onVisibilityChange, intersectionObserverRootType: IntersectionObserverRootType.CHILDREN, childrenRefPropName: 'containerRef', children: _jsx(Scrollbar, { options: { suppressScrollX: true, suppressScrollY: true }, style: { display: 'flex', overflow: 'hidden', maxWidth: maxWidth, minWidth: '50px' }, children: tabs.map((tabItem, index) => (_jsx(TabWithContextMenu, Object.assign({ tabItem: tabItem, contextKey: contextKey, index: index, collapseLimit: collapseLimit, activeTab: activeTab, tabSelectProp: tabSelectProp, onClose: onClose, renameTab: renameTab, onTabDisable: onTabDisable }, restProps), tabItem.value))) }) }), !loading && collapsedTabs.length > 0 && (_jsxs(DropdownButton, { direction: 'down', dark: blackBackground, "data-test-id": 'more-button', className: dropdownClass, children: [_jsx(DropdownToggle, { iconButton: true, compact: true, children: _jsx(Icon, { name: 'faChevronDown' }) }), _jsx(DropdownMenu, { className: 'faq-tabs-dropdown-menu', children: collapsedTabs.map(tab => (_jsx(DropdownItem, { onClick: () => onTabSelect(tab), "data-test-id": 'dropdown-item', className: cn({ disabled: tab.skillDisabled }), children: tab.name }, tab.value))) })] }))] }) }));
};
export const TabsCollapsible = function TabsCollapsible(_a) {
    var { scrollIntoView, tabs: tabsProp, name, onChange, onClose, onTabDelete, onTabRename, onTabDisable } = _a, restProps = __rest(_a, ["scrollIntoView", "tabs", "name", "onChange", "onClose", "onTabDelete", "onTabRename", "onTabDisable"]);
    const [tabs, setTabs] = useState(tabsProp);
    const [limit, setLimit] = useState(tabs.length);
    const contextKey = useRef((Math.random() + 1).toString(36).substring(7));
    useEffect(() => {
        setTabs(tabsProp);
        setLimit(tabsProp.length);
    }, [tabsProp]);
    const onVisibilityChange = useCallback((visibleList) => {
        if (tabs.length > 0 && visibleList.length < 1)
            return;
        setLimit(visibleList.length);
    }, [tabs]);
    const getTabClickHandler = useCallback((value) => (fromCollapsed) => {
        if (fromCollapsed === true) {
            const updatedTabs = [...tabs];
            const targetElemIndex = updatedTabs.findIndex(tab => tab.value === value);
            updatedTabs.unshift(updatedTabs.splice(targetElemIndex, 1)[0]);
            setTabs(updatedTabs);
        }
        onChange && onChange(value, name);
    }, [name, onChange, tabs]);
    return (_jsx(TabsView, Object.assign({ contextKey: contextKey.current, tabs: tabs, collapseLimit: limit, onVisibilityChange: onVisibilityChange, tabSelectProp: getTabClickHandler, onClose: onTabDelete, renameTab: onTabRename, onTabDisable: onTabDisable }, restProps)));
};
