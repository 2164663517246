import React from 'react';
import moment, { duration } from 'moment';

import { TaskStatusData as BaseTaskStatusData } from '../api/client/api';

import { CommonErrorType, getMessageFromError } from 'modules/Caila/utils';
import safeJsonParse from 'utils/safeJsonParse';
import { AppLogger } from 'services/AppLogger';
import localize from '../../../localization';
require('moment-duration-format');

export const HOURS_FOR_TASK_LIMIT = 24;

export interface TaskStatusData extends BaseTaskStatusData {
  index?: number;
  hovered?: boolean;
  startTimestamp?: number;
}

export interface DateTasks {
  [key: string]: TaskStatusData[];
}

export const toSortedByDateObject = (tasks: TaskStatusData[]) => {
  return tasks.reduce((acc, task) => {
    const date = moment(task.timestamp).format('YYYYMMDD');
    if (!acc[date]) acc[date] = [];
    acc[date].push(task);
    return acc;
  }, {} as DateTasks);
};

export const getTypeByNotification = (t: TaskStatusData) => {
  if (t && t.completed === true && !t.canceled) {
    return 'completed';
  }
  if (t && t.completed === true && t.canceled === true) {
    return 'canceled';
  }
  return 'progress';
};

export const filterTasks = (tasks: TaskStatusData[], type?: string, projectId?: string) => {
  const newTasks: TaskStatusData[] = [];
  tasks.forEach((task, index) => {
    if (
      task.projectId === projectId &&
      (!type || type === 'all' || getTypeByNotification(task) === type) &&
      ((!task.completed && Number(duration(moment().diff(moment(task.timestamp))).hours()) < HOURS_FOR_TASK_LIMIT) ||
        task.completed)
    ) {
      task.index = index;
      newTasks.push(task);
    }
  });
  return newTasks;
};

export const TASK_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  ERRORS: 'ERRORS',
  WARN: 'WARN',
};

export const getTaskStatus = (task: TaskStatusData) => {
  if (
    task.canceled &&
    (task?.message?.code?.type === 'warn' ||
      task.notifications?.some(notification => notification?.message?.code?.type === 'warn'))
  ) {
    return TASK_STATUSES.WARN;
  }
  if (
    task.completed &&
    (task.notifications?.some(
      notification => notification?.message?.code?.type === 'error' || notification?.message?.code?.type === 'warn'
    ) ||
      task.message?.code?.type === 'warn' ||
      task.message?.code?.type === 'error')
  ) {
    return TASK_STATUSES.ERRORS;
  }
  return task.completed ? TASK_STATUSES.COMPLETED : TASK_STATUSES.IN_PROGRESS;
};

export const tsToString = (timestamp?: number) => {
  return moment(timestamp).format('HH:mm, DD.MM.YYYY');
};

export const dateExtract = (timestamp?: number): string => {
  const date = moment(timestamp);
  return moment
    .duration(moment().diff(date))
    .format(`hh [${localize.translate('h.')}] mm  [${localize.translate('min.')}] ss [${localize.translate('sec.')}]`);
};

export const getLatestTaskNotificationMessage = (task: TaskStatusData) => {
  if (!task.notifications || task.notifications.length < 1) return '';

  const latestNotification = task.notifications.reduce((latest, notification) => {
    if (!notification?.timestamp || !latest?.timestamp) return latest;
    return notification.timestamp > latest.timestamp ? notification : latest;
  }, task.notifications[0]);

  if (!latestNotification || !latestNotification.message?.data?.output) return '';
  return getLogOutputFromMessage(latestNotification.message);
};

export const getLogOutputFromMessage = (message: TaskStatusData['message']) => {
  let log = '';
  if (!message?.data?.output) {
    log += JSON.stringify(message?.data);
    return log;
  }
  if (message.data.botName) {
    log += localize.translate('Bot name') + ': ' + message?.data?.botName + '\n';
  }
  if (message.data.botId) {
    log += localize.translate('Bot ID') + ': ' + message?.data?.botId + '\n';
  }
  if (String(message?.data?.output) === 'Training failed') {
    log += String(message?.data?.output);
    return log;
  }

  const errorMessage = getNlpErrorFromOutput(message?.data?.output);
  log += errorMessage ? String(errorMessage) : String(message?.data?.output);
  return log;
};

export const getNlpErrorFromOutput = (output: string | object) => {
  try {
    const taskMessageParsed: { error: CommonErrorType } | string =
      safeJsonParse<{ error: CommonErrorType }>(output as string) || (output as string);
    if (typeof taskMessageParsed !== 'string' && taskMessageParsed.error) {
      const errorMessage = getMessageFromError(taskMessageParsed.error.args, taskMessageParsed.error.errorCode);
      return errorMessage;
    }
    return output;
  } catch (error) {
    AppLogger.error({
      message: `error getting error message from notification output`,
      exception: error as Error,
    });
    return output;
  }
};

export const getEarliestTimestamp = (task: TaskStatusData): number => {
  if (!task.notifications) return task.startTimestamp || task.timestamp;
  return task.notifications.reduce((prevTimestamp, notification) => {
    if (notification.timestamp && notification.timestamp < prevTimestamp) {
      return notification.timestamp;
    }
    return prevTimestamp;
  }, task.startTimestamp || task.timestamp);
};

export const getLatestTimestamp = (task: TaskStatusData): number => {
  if (!task.notifications) return task.timestamp;
  return task.notifications.reduce((prevTimestamp, notification) => {
    if (notification.timestamp && notification.timestamp > prevTimestamp) {
      return notification.timestamp;
    }
    return prevTimestamp;
  }, task.timestamp);
};

export const findFileUrl = (notifications: TaskStatusData['notifications']): TaskStatusData['message'] => {
  const successMessage = (notifications || []).find(item =>
    (item.message?.code?.code || '').endsWith('task_completed')
  );
  if (successMessage) {
    return successMessage.message || undefined;
  }
  return undefined;
};
export const findFileName = (notifications: TaskStatusData['notifications']): string | undefined => {
  const successMessage = (notifications || []).find(item => !!item.message?.data?.fileName);
  if (successMessage) {
    // @ts-ignore
    return (successMessage.message?.data?.fileName as string) || undefined;
  }
  return undefined;
};

export const download = (name: string, fileUrl: string) => () => {
  const anchor = document.createElement('a');
  anchor.href = fileUrl;
  anchor.target = '_blank';
  anchor.download = name;
  anchor.click();
};

const toElement = (text: string) => React.createElement('p', { className: 'text-muted mb-0 task-card__bot-id' }, text);

const getTaskBodyTextByCode = (task: TaskStatusData, code: string) => {
  const TABLE_FILE_URL_REGEX = /.*\/(.*\.xlsx)|.*\/(.*\.csv)/g;
  let taskMessageWithFileUrl: TaskStatusData['message'] = task.message;
  let taskMessageWithFileName: string | undefined = '';
  switch (code) {
    case 'task_progress':
    case 'task_created':
      taskMessageWithFileUrl = findFileUrl(task.notifications) || {};
      taskMessageWithFileName = findFileName(task.notifications);
      if (taskMessageWithFileName) return toElement(taskMessageWithFileName);
    //fallthrough is OK!
    case 'task_completed':
      taskMessageWithFileName = findFileName(task.notifications);
      if (taskMessageWithFileName) return toElement(taskMessageWithFileName);
      if (!taskMessageWithFileUrl?.data?.fileUrl) return undefined;
      // @ts-ignore
      let fileUrl = taskMessageWithFileUrl.data.fileUrl as string;
      let match = TABLE_FILE_URL_REGEX.exec(fileUrl);

      if (match && match.length > 0) fileUrl = match[match.length - 1];

      return toElement(fileUrl);
    default:
      return undefined;
  }
};

const REPORT_GENERATION_PREFIX = 'reporter.reports.report_generation_';
export const isReportGeneration = (code: string) => code.startsWith(REPORT_GENERATION_PREFIX);

const LOGS_DOWNLOAD_PREFIX = 'botadmin.logs.logs_download_';
export const isLogsDownload = (code: string) => code.startsWith(LOGS_DOWNLOAD_PREFIX);

export const deprefixTaskCode = (code: string) =>
  isReportGeneration(code)
    ? code.slice(REPORT_GENERATION_PREFIX.length)
    : isLogsDownload(code)
    ? code.slice(LOGS_DOWNLOAD_PREFIX.length)
    : code;

export const getTaskBodyText = (task: TaskStatusData): string | JSX.Element | undefined => {
  const code = task.message?.code?.code;

  if (!code) return undefined;
  if (isReportGeneration(code)) return getTaskBodyTextByCode(task, code.slice(REPORT_GENERATION_PREFIX.length));
  if (isLogsDownload(code)) return getTaskBodyTextByCode(task, code.slice(LOGS_DOWNLOAD_PREFIX.length));
};
