import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface StepperControlProps {
  count: number;
  index: number;
  onSelect(index: number): void;
  className?: string;
}
const StepperControl = ({ count, index, className, onSelect }: StepperControlProps) => {
  return (
    <div className={cn(styles.StepperControl, className)}>
      {new Array(count).fill('').map((_, idx) => (
        <div
          key={idx}
          className={cn(styles.StepperControl__item, {
            [styles.StepperControl__item_active]: idx === index,
          })}
          onClick={() => idx !== index && onSelect(idx)}
        />
      ))}
      <div></div>
    </div>
  );
};

StepperControl.displayName = 'StepperControl';

export default React.memo(StepperControl);
