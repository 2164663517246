import React, { useLayoutEffect, CSSProperties } from 'react';
import { arrow, autoUpdate, computePosition, ReferenceElement, ComputePositionConfig } from '@floating-ui/react-dom';

type VirtualNode = { width: number; height: number; x: number; y: number };

function isElement(element: any): element is HTMLElement {
  return element instanceof Element;
}

function buildVirtualFloaterNode(rect: VirtualNode): ReferenceElement {
  return {
    getBoundingClientRect() {
      return {
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
        left: rect.x,
        right: rect.x,
        top: rect.y,
        bottom: rect.y,
      };
    },
  };
}

function getFloatingNode(
  target: VirtualNode | HTMLElement,
  floatingElement: HTMLElement,
  options?: Partial<ComputePositionConfig>
): {
  node: ReferenceElement;
  optionsModified?: Partial<ComputePositionConfig>;
} {
  if (!isElement(target)) {
    const node = buildVirtualFloaterNode(target);
    return { node, optionsModified: options };
  }

  if ((options?.placement as string) === 'center') {
    const targetRect = target.getBoundingClientRect();
    const floatingRect = floatingElement.getBoundingClientRect();
    const node = buildVirtualFloaterNode({
      ...targetRect,
      height: 0,
      width: 0,
      x: targetRect.x + targetRect.width / 2,
      y: targetRect.y + targetRect.height / 2 + floatingRect.height / 2,
    });
    return { node, optionsModified: { ...options, placement: 'top' } };
  }

  return { node: target, optionsModified: options };
}

const TIP_SIDES_MAP: Record<string, keyof CSSProperties> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

export default function useFloaterPosition({
  enable,
  floaterElement,
  target,
  arrowElement,
  options,
}: {
  target:
    | {
        width: number;
        height: number;
        x: number;
        y: number;
      }
    | HTMLElement
    | null;
  enable?: boolean;
  floaterElement: React.RefObject<HTMLElement | null>;
  arrowElement?: React.RefObject<HTMLElement | null>;
  options?: Partial<ComputePositionConfig>;
}) {
  useLayoutEffect(() => {
    if (!floaterElement.current || !enable || !target) return;

    const { node, optionsModified } = getFloatingNode(target, floaterElement.current, options);
    if (arrowElement?.current && optionsModified) {
      if (!optionsModified.middleware) {
        optionsModified.middleware = [];
      }
      optionsModified.middleware.push(
        arrow({
          element: arrowElement,
          padding: 20,
        })
      );
    }

    const cleanup = autoUpdate(node, floaterElement.current, () => {
      computePosition(node, floaterElement.current!, optionsModified).then(({ x, y, strategy, middlewareData }) => {
        if (!floaterElement.current) return;
        Object.assign(floaterElement.current.style, {
          position: strategy,
          top: '0',
          left: '0',
          transform: `translate3d(${Math.round(x)}px,${Math.round(y)}px,0)`,
        });

        if (middlewareData.hide) {
          const { referenceHidden } = middlewareData.hide;
          Object.assign(floaterElement.current.style, {
            display: referenceHidden ? 'none' : 'block',
          });
        }

        if (middlewareData.arrow && arrowElement?.current) {
          const staticSide = options?.placement ? TIP_SIDES_MAP[options.placement.split('-')[0]] : null;
          const arrowWidth = arrowElement.current?.clientWidth ?? 0;
          const { x, y } = middlewareData.arrow;

          Object.assign(arrowElement.current.style, {
            position: 'absolute',
            left: x !== null ? `${x}px` : '',
            top: y !== null ? `${y}px` : '',
            right: '',
            bottom: '',
            [staticSide as any]: staticSide ? `-${arrowWidth / 2.5}px` : '',
          });
        }
      });
    });

    return () => {
      cleanup();
    };
  }, [arrowElement, enable, target, options, floaterElement]);
}
