import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import TextAreaLikeElement from '../TextAreaLikeElement';
import MediaPartBlock from './MediaPartBlock';
import { useTranslation } from '../contexts/transtationContext';
import { ClickAwayListener } from '../ClickAwayListener';
import { WysiwygEditor } from '../Wysiwyg';
import { DragHandler } from '../DragHandler';
import { IconButton } from '../index';
export default function AnswerPart(props) {
    const { value: partValue, onChange, type, setOpenDelete, setOpenEdit, markupMode, selected, setSelected, clearSelection, lastRemaining, error, fileName, onBlur, audioName, uploadDate, dataTestId, writeDisabled, maxTextSize, } = props;
    const { t } = useTranslation();
    const htmlInputRef = useRef(null);
    const htmlValue = useRef(partValue);
    const didMount = useRef(false);
    const [textValue, setTextValue] = useState(DOMPurify.sanitize(partValue, { ALLOWED_TAGS: ['#text'] }));
    useEffect(() => {
        //we don't need to do it on first render
        if (didMount.current)
            onChange(markupMode ? htmlValue.current : textValue);
        didMount.current = true;
        //do not add textValue to deps array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markupMode]);
    useEffect(() => {
        const cleanText = DOMPurify.sanitize(partValue, { ALLOWED_TAGS: ['#text'] }).replace(/\\&nbsp;/g, ' ');
        if (markupMode || textValue !== cleanText) {
            htmlValue.current = partValue;
        }
        setTextValue(cleanText);
        //do not add textValue to deps array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partValue, markupMode, maxTextSize]);
    let PartComponent;
    switch (type) {
        case 'text':
            PartComponent = markupMode ? (_jsx(WysiwygEditor, { showControls: selected, autoFocus: true, ref: htmlInputRef, defaultText: htmlValue.current, maxLength: maxTextSize, onChange: onChange, onFocus: setSelected, onBlur: onBlur, editDisabled: writeDisabled })) : (_jsx(TextAreaLikeElement, { name: 'answer', value: textValue, richValue: textValue, placeholder: t('FAQPage:Field:Answer:Placeholder'), onChange: onChange, onFocus: setSelected, autoFocus: true, invalid: !!error, "data-test-id": 'FAQPage.IntentEdit.Answer', minRows: 1, maxRows: 30, maxLength: maxTextSize, className: 'form-control form-control-md justui_input-text answers-textarea just-ui-custom-scrollbar', onBlur: onBlur, dataTestId: 'FAQPage:AnswerPart:Input', disabled: writeDisabled }));
            break;
        default:
            PartComponent = (_jsx(MediaPartBlock, { saveAudioToServer: props.saveAudioToServer, onPartEdit: setOpenEdit, type: type, value: partValue, fileName: fileName, error: error, audioName: audioName, uploadDate: uploadDate, writeDisabled: writeDisabled }));
            break;
    }
    return (_jsx("div", { className: 'faq-answer__part-container', "data-test-id": dataTestId, children: _jsxs("div", { className: 'faq-answer__part-content', children: [_jsx(DragHandler, { className: 'faq-answer__drag-handler' }), _jsx(ClickAwayListener, { handleClickOut: () => selected && clearSelection(), children: _jsx("div", { className: 'faq-answer__payload', children: PartComponent }) }), _jsx(IconButton, { className: 'faq-answer__delete-btn', name: 'farTrashAlt', flat: true, color: 'secondary', onClick: setOpenDelete, disabled: writeDisabled || (lastRemaining && !textValue), "data-test-id": `${dataTestId}.DeleteBtn` })] }) }));
}
