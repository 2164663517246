import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation, IconButton, Icon } from '@just-ai/just-ui';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { useNLUModuleContext } from '../../../context';
export default function FAQTemplateHeader() {
    const history = useHistory();
    const { wrappedHistoryPush } = useNLUModuleContext();
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.faqAddSkill__header, children: [_jsxs("div", { onClick: () => wrappedHistoryPush({ url: 'faq', historyState: Object.assign({}, history.location.state) }), children: [_jsx(Icon, { name: 'falArrowLeft' }), _jsx("p", { children: t('Back') })] }), _jsx(IconButton, { name: 'falTimes', onClick: () => wrappedHistoryPush({ url: 'faq', historyState: Object.assign({}, history.location.state) }), flat: true, color: 'secondary' })] }));
}
