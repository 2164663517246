import React, { FC, useCallback } from 'react';
import { CustomRxTooltip, Icon } from '@just-ai/just-ui';

import { tWithCheck } from 'localization';
import { useAppDispatch } from 'storeHooks';

import { TReactionsTagNames, TagNames } from 'modules/JGraph/utils/types';
import { addNewBlockInReduxAndApi } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';

import { mapIconToTagName } from '../settings/ScreenBlocksList/ReactionItem';
import { useRightSideMenuContext } from '../index';
import classes from './RSAddingMenu.module.scss';

export const RSAddingMenuItem: FC<{ name: TReactionsTagNames }> = ({ name }) => {
  const dispatch = useAppDispatch();
  const { setAddingMenuType, eventsState } = useRightSideMenuContext();
  const createBlock = useCallback(() => {
    setAddingMenuType(undefined);
    dispatch(
      addNewBlockInReduxAndApi({
        tagName: name,
        prefix: eventsState.addingMenuTypePrefix,
        withoutSetEdit:
          ScreenBlockPath.isPathContainsIfElse(eventsState.addingMenuTypePrefix || '') &&
          [TagNames.if, TagNames.else, TagNames.elseif].includes(name),
      })
    );
  }, [setAddingMenuType, dispatch, name, eventsState.addingMenuTypePrefix]);

  const hint = tWithCheck(`CustomTag:${name}:hint`);

  return (
    <>
      <div
        className={classes.RSAddingMenuItem}
        onClick={createBlock}
        id={`CustomTag_${name}`}
        data-test-id={`RSAddingMenuItem:${name}`}
      >
        <Icon className={classes.RSAddingMenuItemIcon} name={mapIconToTagName[name] || 'farCube'} />
        <div className={classes.name}>
          {tWithCheck(`CustomTag:${name}:caption`) || tWithCheck(`ReactionItem ${name}`) || name}
        </div>
        <Icon name='farQuestionCircle' className={classes.docLink} />
      </div>
      {hint && (
        <CustomRxTooltip
          target={`#CustomTag_${name}`}
          innerClassName={classes.tooltipCustomWidth}
          placement='left'
          textAlign='left'
        >
          {hint}
        </CustomRxTooltip>
      )}
    </>
  );
};
