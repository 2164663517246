import React from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import { GuideStep } from 'components/GuideTourStepper/types';

import classes from './styles.module.scss';

interface JGraphStartTooltipProps {
  backProps: object;
  closeProps: object;
  continuous: boolean;
  index: number;
  isLastStep: boolean;
  primaryProps: object;
  size: number;
  skipProps: object;
  step: GuideStep;
  tooltipProps: object;
  arrowRef?: React.RefObject<HTMLElement>;
}
export default class JGraphStartTooltip extends React.Component<JGraphStartTooltipProps> {
  render() {
    const { continuous, isLastStep, primaryProps, skipProps, step, tooltipProps } = this.props;
    const { content, hideFooter, showSkipButton, title, styles } = step;
    const { close, skip } = step.locale!;
    const output: any = {
      primary: close,
    };

    if (continuous) {
      output.primary = <span>{t('JGraphGuideTour:MainModal:Action:Next')}</span>;
    }

    if (showSkipButton && !isLastStep) {
      output.skip = (
        <button style={styles.buttonSkip} type='button' data-test-id='button-skip' aria-live='off' {...skipProps}>
          {skip}
        </button>
      );
    }

    return (
      <>
        <div className={classes.JGraphStartTooltip} data-test-id={step.dataTestId} {...tooltipProps}>
          <div style={styles.tooltipContainer}>
            {title && (
              <h3 className={classes.JGraphStartTooltip__title} dangerouslySetInnerHTML={{ __html: title as string }} />
            )}
            <div className={classes.JGraphStartTooltip__content} style={styles.tooltipContent}>
              {content}
            </div>
          </div>
          {!hideFooter && (
            <div className={classes.JGraphStartTooltip__footer}>
              <div style={styles.tooltipFooterSpacer}>{output.skip}</div>
              <Button
                style={styles.buttonNext}
                color='primary'
                type='button'
                data-test-id='button-primary'
                {...primaryProps}
              >
                {output.primary}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}
