import React, { RefCallback, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Group } from 'react-konva';
import Konva from 'konva';
import { callBlocksRedraw, getAboveGroupsHeightsAndOffsets } from '../../utils/blockLayerUtils';
import { NodeConfig } from 'konva/lib/Node';

interface TAutopositionGroupProps extends NodeConfig {
  pathId?: string;
  yOffset?: number;
  xOffset?: number;
  isFallbackFrom?: boolean;

  isScreen?: boolean;
  dataType?: string;
}
export const AutopositionGroup = React.forwardRef<Konva.Group, TAutopositionGroupProps>(
  ({ yOffset = 6, xOffset = 12, children, name, onClick = () => {}, hasChildren, isScreen = false, ...rest }, ref) => {
    const selfRef = useRef<Konva.Group | null>(null);
    const [position, setPosition] = useState(yOffset);

    const calculateOffset = useCallback(() => {
      if (selfRef.current) {
        let onlyGroups = [...(selfRef.current.parent?.children || []).filter(child => child.getType() === 'Group')];
        const selfIndex = onlyGroups.findIndex(childGroup => childGroup._id === selfRef.current!._id);
        if (selfIndex > 0) {
          onlyGroups.splice(selfIndex, onlyGroups.length);
          const aboveGroupsHaO = getAboveGroupsHeightsAndOffsets(onlyGroups);
          setPosition(yOffset + aboveGroupsHaO.height + aboveGroupsHaO.offsetY);
        } else {
          setPosition(yOffset);
        }
      }
    }, [yOffset]);

    useLayoutEffect(() => {
      requestAnimationFrame(calculateOffset);
    }, [calculateOffset]);

    useEffect(() => {
      const parent = selfRef.current?.getParent();
      return () => {
        if (parent) {
          callBlocksRedraw(parent as unknown as Konva.Group, parent.getStage());
        }
      };
    }, []);

    const getRef: RefCallback<Konva.Group> = useCallback(
      compRef => {
        if (typeof ref === 'function') {
          ref(compRef);
        }
        if (ref && ref.hasOwnProperty('current')) {
          //@ts-ignore
          ref.current = compRef;
        }
        selfRef.current = compRef;
      },
      [ref]
    );

    return (
      <Group
        {...rest}
        x={xOffset}
        y={position}
        ref={getRef}
        name={name}
        calculateOffset={calculateOffset}
        onClick={onClick}
        hasChildren={hasChildren}
        isScreen={isScreen}
      >
        {children}
      </Group>
    );
  }
);
AutopositionGroup.displayName = 'AutopositionGroup';
