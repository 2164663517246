import React from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import Tooltip from '../index';
import styles from '../contentStyles.module.scss';

interface NoConnectionTooltipProps {
  onClose: () => void;
  isActionsDisabled: boolean;
  onRetry: () => void;
}
const NoConnectionTooltip = ({ onClose, isActionsDisabled, onRetry }: NoConnectionTooltipProps) => {
  return (
    <Tooltip className={styles.ContentTooltip} onClose={onClose}>
      <span className={styles.ContentTooltip__title}>{t('StatusIndicator:NoConnectionTooltip:Title')}</span>
      <div className={styles.ContentTooltip__divider} />
      <div className={styles.ContentTooltip__content}>
        <span className={styles.ContentTooltip__description}>
          {t('StatusIndicator:NoConnectionTooltip:Description')}
        </span>
        <div className={styles.ContentTooltip__actions}>
          <Button size='sm' onClick={onRetry} color='primary' disabled={isActionsDisabled}>
            {t('StatusIndicator:TryAgain')}
          </Button>
        </div>
      </div>
    </Tooltip>
  );
};

export default React.memo(NoConnectionTooltip);
