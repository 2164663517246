import history from 'appHistory';
import { EditorMode } from './EditorContext';
import queryString from 'query-string';

const URL_BY_MODE = {
  editor: 'editor',
  'visual-editor': 'editor/visual',
};

export function getMode(): EditorMode {
  return history.location.pathname.includes('/visual') ? 'visual-editor' : 'editor';
}

export function getFileId() {
  const fileId = queryString.parse(history.location.search).file;
  return typeof fileId === 'string' ? fileId : undefined;
}

export function isRefreshEditor() {
  return Boolean(window.history.state?.state?.refreshEditor);
}

export function clearRefresh(prevState?: { deploy?: boolean; isNewProject?: boolean }) {
  history.replace(window.location.pathname, { ...prevState, refreshEditor: false });
}

function getBasePath() {
  return history.location.pathname.slice(0, history.location.pathname.indexOf('/', 1));
}

export function setFile(fileId?: string, mode?: EditorMode) {
  if (!fileId) {
    history.push(`${history.location.pathname}`);
    return;
  }
  history.push(
    mode ? `${getBasePath()}/${URL_BY_MODE[mode]}?file=${fileId}` : `${history.location.pathname}?file=${fileId}`
  );
}
