const prefix = '@callList/';

export const LIST_CALL_LIST = prefix + 'LIST_CALL_LIST';
export const LIST_CALL_LIST_PENDING = prefix + 'LIST_CALL_LIST_PENDING';
export const LIST_CALL_LIST_FULFILLED = prefix + 'LIST_CALL_LIST_FULFILLED';
export const LIST_CALL_LIST_REJECTED = prefix + 'LIST_CALL_LIST_REJECTED';

export const CREATE_CALL_LIST = prefix + 'CREATE_CALL_LIST';
export const CREATE_CALL_LIST_PENDING = prefix + 'CREATE_CALL_LIST_PENDING';
export const CREATE_CALL_LIST_FULFILLED = prefix + 'CREATE_CALL_LIST_FULFILLED';
export const CREATE_CALL_LIST_REJECTED = prefix + 'CREATE_CALL_LIST_REJECTED';

export const VALIDATE_CALL_LIST = prefix + 'VALIDATE_CALL_LIST';
export const VALIDATE_CALL_LIST_PENDING = prefix + 'VALIDATE_CALL_LIST_PENDING';
export const VALIDATE_CALL_LIST_FULFILLED = prefix + 'VALIDATE_CALL_LIST_FULFILLED';
export const VALIDATE_CALL_LIST_REJECTED = prefix + 'VALIDATE_CALL_LIST_REJECTED';

export const DOWNLOAD_CALL_LIST = prefix + 'DOWNLOAD_CALL_LIST';
export const DOWNLOAD_CALL_LIST_PENDING = prefix + 'DOWNLOAD_CALL_LIST_PENDING';
export const DOWNLOAD_CALL_LIST_FULFILLED = prefix + 'DOWNLOAD_CALL_LIST_FULFILLED';
export const DOWNLOAD_CALL_LIST_REJECTED = prefix + 'DOWNLOAD_CALL_LIST_REJECTED';

export const DELETE_CALL_LIST = prefix + 'DELETE_CALL_LIST';
export const DELETE_CALL_LIST_PENDING = prefix + 'DELETE_CALL_LIST_PENDING';
export const DELETE_CALL_LIST_FULFILLED = prefix + 'DELETE_CALL_LIST_FULFILLED';
export const DELETE_CALL_LIST_REJECTED = prefix + 'DELETE_CALL_LIST_REJECTED';

export const RESET_CREATION_FORM = prefix + 'RESET_CREATION_FORM';
