import React, { FC, useMemo } from 'react';
import { Line, Text } from 'react-konva';
import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';

import { t } from 'localization';
import { TagNames, TJBlock, TTagParameters } from '../../utils/types';
import { IconNames, KIcon } from './KIcons';
import { getConnectorFrom } from '../../../../reducers/JGraph.reducer/Graph';
import { TransitionCircle } from './TransitionCircle';
import { useOnClickBlockDefault } from '../../hooks';
import { useRenderBlocksContext } from './RenderBlocks';

export interface ItemBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  bodyText: string;
  screenBlock: TJBlock<TagNames.Email | TagNames.HttpRequest>;
}

export const CommonMetaBody: FC<ItemBodyProps> = ({ screenBlock, globalJBlockIterator = 0, bodyText, ...rest }) => {
  const { baseWidth, prefix, screen } = useRenderBlocksContext();
  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);
  // const okState = screenBlock.tagParameters.find(tagParam => tagParam.name === 'okState')?.value;
  // const errorState = screenBlock.tagParameters.find(tagParam => tagParam.name === 'errorState')?.value;

  const mapTagParameters = useMemo(() => {
    let obj: Record<string, TTagParameters<string, any>> = {};
    screenBlock.tagParameters.forEach(tagParam => {
      obj[tagParam.name] = tagParam;
    });
    return obj;
  }, [screenBlock.tagParameters]);

  return (
    <BaseItemBody
      {...rest}
      debugActive={screenBlock.debugActive}
      onClick={onClickDefault}
      renderChild={({ innerHeight = 1 }) => {
        return (
          <>
            <Text
              x={24}
              y={32}
              verticalAlign='top'
              key={`isTextShorten${innerHeight}`}
              text={bodyText ? bodyText : t(`${screenBlock.tagName}Body:placeholder`)}
              height={innerHeight * 21}
              width={baseWidth - 96}
              lineHeight={21 / 14}
              fontSize={14}
              letterSpacing={0.02}
              ellipsis={true}
              fill={bodyText ? '#333C45' : '#888E93'}
            />
            <Line points={[0, 60, baseWidth - 36, 60]} stroke='#E0E1E3' strokeWidth={1} />
            <KIcon icon={IconNames.SuccessCircle} width={16} height={16} x={12} y={68} />
            <Text x={34} y={66} lineHeight={21 / 14} fontSize={14} letterSpacing={0.02} text={t('Email:success')} />
            <TransitionCircle
              x={baseWidth - 50}
              y={76}
              tagName={screenBlock.tagName}
              hasTransition={!!mapTagParameters['okState']?.value}
              name={getConnectorFrom(globalJBlockIterator, screen.pathId, `${prefix}${screenBlock.tagName}`, 0)}
              transitionTo={mapTagParameters['okState']?.value}
              debugActive={mapTagParameters['okState']?.debugActive}
            />
            <Line points={[0, 92, baseWidth - 36, 92]} stroke='#E0E1E3' strokeWidth={1} />

            <KIcon icon={IconNames.ErrorCircle} width={16} height={16} x={12} y={100} />
            <Text x={34} y={98} lineHeight={21 / 14} fontSize={14} letterSpacing={0.02} text={t('Email:error')} />
            <TransitionCircle
              x={baseWidth - 50}
              y={108}
              tagName={screenBlock.tagName}
              hasTransition={!!mapTagParameters['errorState']?.value}
              name={getConnectorFrom(globalJBlockIterator, screen.pathId, `${prefix}${screenBlock.tagName}`, 1)}
              transitionTo={mapTagParameters['errorState']?.value}
              debugActive={mapTagParameters['errorState']?.debugActive}
            />
          </>
        );
      }}
    />
  );
};
