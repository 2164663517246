import React from 'react';
import { t } from 'localization';
import cn from 'classnames';
import classes from './styles.module.scss';

export const ShowFullPath = ({ path }: { path: string }) => {
  return (
    <div className={cn('d-flex flex-column', classes.ShowFullPath)}>
      <b className='color-gray-600 mb-0 font-size-12'>{t('CailaIntentsSimple:IntentDetail:ShowFullPath:title')}</b>
      <div className='color-gray-600 font-size-12'>{path}</div>
    </div>
  );
};
