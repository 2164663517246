var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SkillsApi, SkillType } from '../../generated/Caila';
class SkillsApiService {
    constructor(accountId, projectShortName, axios) {
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.skillsApi = new SkillsApi({}, SkillsApiService.BASE_PATH, axios);
    }
    listSkills() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.listSkills(this.accountId, this.projectShortName);
            return res.data;
        });
    }
    deleteSkill(deleteSkillRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.deleteSkill(this.accountId, this.projectShortName, deleteSkillRequest);
            return res.data;
        });
    }
    updateSkill(updateSkillRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.updateSkill(this.accountId, this.projectShortName, updateSkillRequest);
            return res.data;
        });
    }
    createCdqaSkill(name, file, lang) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdSkill = yield this.skillsApi.createSkillWithContent(this.accountId, this.projectShortName, name, SkillType.Cdqa, file);
            const updatedSkill = yield this.updateSkill(Object.assign(Object.assign({}, createdSkill.data), { settings: Object.assign(Object.assign({}, createdSkill.data.settings), { languageCode: lang }) }));
            return updatedSkill;
        });
    }
    updateSkillFile(skillId, skillType, file) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.skillsApi.updateSkillContent(this.accountId, this.projectShortName, skillId, skillType, file);
        });
    }
    downloadSkillFile(skillId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.getSkillContent(this.accountId, this.projectShortName, skillId);
            return res.data;
        });
    }
    getSkillFileName(skillId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.listContentFiles(this.accountId, this.projectShortName, skillId);
            const file = res.data.files && res.data.files[0];
            return file === null || file === void 0 ? void 0 : file.name;
        });
    }
    simpleCDQAInference(skillId, text) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.skillsApi.simpleSkillInference(this.accountId, this.projectShortName, skillId, text);
            return res.data;
        });
    }
}
SkillsApiService.BASE_PATH = '/cailapub';
export default SkillsApiService;
