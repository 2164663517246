export const demoblockedLocalization = {
  'DemoBlocked YandexPayment bold text': {
    eng: 'Your payment is being processed by $[1].',
    ru: 'Ожидаем подтверждения от платежной системы $[1].',
  },
  'DemoBlocked YandexPayment text': {
    eng: 'Please don’t worry, it won’t affect your current work',
    ru: 'Вы можете продолжать работу',
  },
  'DemoBlocked paymentUnsuccessful bold text': {
    eng: 'Your #{config.botadmin.productName} subscription has been paused',
    ru: 'Закончилась подписка на #{config.botadmin.productName}.',
  },
  'DemoBlocked paymentUnsuccessful button text': {
    eng: 'Pay now',
    ru: 'Оплатить подписку',
  },
  'DemoBlocked noSubscriptionCard text': {
    eng: 'A bank card is not added to your account. Your account will be blocked on $[1]',
    ru: 'Банковская карта не привязана к вашему аккаунту. $[1] ваш аккаунт будет заблокирован',
  },
  'DemoBlocked noSubscriptionCard button text': {
    eng: 'Add card',
    ru: 'Привязать карту',
    pt: 'Adicionar cartão',
  },
  'DemoBlocked subscriptionExpires bold text': {
    eng: 'Your #{config.botadmin.productName} subscription is ending',
    ru: 'Заканчивается подписка на сервис #{config.botadmin.productName}',
  },
  'DemoBlocked subscriptionExpires button text': {
    eng: 'Your #{config.botadmin.productName} subscription is ending',
    ru: 'Продлить подписку',
  },
  'DemoBlocked YandexPaymentSuccess bold text': {
    eng: 'Payment is confirmed!',
    ru: 'Оплата подтверждена!',
  },
  'DemoBlocked YandexPaymentSuccess text': {
    eng: 'Use with pleasure!',
    ru: 'Пользуйтесь с удовольствием!',
  },
  'DemoBlocked blocked bold text': {
    eng: 'Make a payment for subscription',
    ru: 'Внесите платеж за пользование сервисом',
  },
  'DemoBlocked blocked button text': {
    eng: 'Purchase now',
    ru: 'Оплатить подписку',
  },
  'DemoBlocked freeTariffLimitsWarning text': {
    eng: 'Limits left: $[1] $[2] and $[3] $[4] for telephony. To increase the limit, ',
    ru: 'Остаток лимитов: $[1] $[2] и $[3] $[4] для звонков. Для увеличения лимитов ',
  },
  'DemoBlocked freeTariffLimitsWarning link': {
    eng: 'switch to another plan.',
    ru: 'выберите другой тариф.',
  },
  'DemoBlocked freeTariffLimitsDanger text': {
    eng: 'Limits left: $[1] $[2] and $[3] $[4] for telephony. To increase the limit, ',
    ru: 'Остаток лимитов: $[1] $[2] и $[3] $[4] для звонков. Для увеличения лимитов ',
  },
  'DemoBlocked freeTariffLimitsDanger link': {
    eng: 'switch to another plan.',
    ru: 'выберите другой тариф.',
  },
  'DemoBlocked uniques one': {
    eng: 'user',
    ru: 'пользователь',
  },
  'DemoBlocked uniques few': {
    eng: 'users',
    ru: 'пользователей',
  },
  'DemoBlocked uniques many': {
    eng: 'users',
    ru: 'пользователей',
  },
  'DemoBlocked uniques other': {
    eng: 'users',
    ru: 'пользователей',
  },
  'DemoBlocked minutes one': {
    eng: 'minute',
    ru: 'минута',
  },
  'DemoBlocked minutes few': {
    eng: 'minutes',
    ru: 'минуты',
  },
  'DemoBlocked minutes many': {
    eng: 'minutes',
    ru: 'минут',
  },
  'DemoBlocked minutes other': {
    eng: 'minutes',
    ru: 'минут',
  },
  'Payment:BE-error accountsadmin.user.email.not.filled': {
    eng: 'To be able to subscribe to a plan and buy additional packages, fill out your email in the profile settings',
    ru: 'Чтобы подключить тариф или купить дополнительные пакеты, заполните email в личном кабинете',
  },
};
