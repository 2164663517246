import { PhraseMarkupData, IntentData } from '../api/client';
export enum Locale {
  eng = 'en',
  ru = 'ru',
  cn = 'cn',
}

export enum EntityType {
  named = 'my',
  system = 'sys',
}

export enum IntentItemType {
  phrase,
  pattern,
}

export interface IntentItem extends Omit<PhraseMarkupData, 'text'> {
  type: IntentItemType;
  text: string;
  error?: string;
}

export type IntentWithItems = {
  intent: IntentData;
  items: IntentItem[];
};
