import { useState, useCallback, useEffect } from 'react';
export const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);
    const setTrue = useCallback(() => setValue(true), []);
    const setFalse = useCallback(() => setValue(false), []);
    const toggle = useCallback(() => setValue(value => !value), []);
    return [value, setTrue, setFalse, toggle, setValue];
};
export const useLoading = () => {
    const [isLoading, setLoading] = useState(false);
    const load = useCallback(promise => {
        setLoading(true);
        return promise.finally(() => setLoading(false));
    }, []);
    return [isLoading, load];
};
export const useDebounce = (state, delay, callback) => {
    const [debouncedState, setDebouncedState] = useState(state);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedState(state);
            if (callback) {
                callback(state);
            }
        }, delay);
        return () => clearTimeout(handler);
    }, [state, delay, callback]);
    return debouncedState;
};
export function useDeParametrizedFunction(func, ...args) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(() => func(...args), [func, ...args]);
}
