import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { TStateToUpdate, getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi } from '../utils';

const createState = createAsyncThunk('JGraph/createState', async (createJStateData: TStateToUpdate, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const { data: graphModel } = await GraphApi.createState(userAccountId || accountId, projectShortName, {
    stateToCreate: createJStateData,
    parentThemePath: createJStateData.theme,
    parentStatePath: createJStateData.parentStatePath,
    file: {
      filename: createJStateData.filename,
      lastModification: state.JGraphReducer.graph.files[createJStateData.filename],
    },
  });
  return graphModel;
});

export const revertCreateState = createAsyncThunk(
  'JGraph/revertCreateState',
  async (revertEvent: RevertEvent<TStateToUpdate>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    const { data } = await GraphApi.deleteState(userAccountId || accountId, projectShortName, {
      targetStatePath: revertEvent.payload.path,
      file: {
        filename: revertEvent.payload.filename,
        lastModification: state.JGraphReducer.graph.files[revertEvent.payload.filename],
      },
    });
    await thunkAPI.dispatch(getJGraphVisuals());
    await thunkAPI.dispatch(getGraph({}));
    return data;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertCreateState, createState);
