import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { ComputePositionConfig } from '@floating-ui/react-dom';
import {
  useBehaviorSubject,
  ClickAwayListener,
  Icon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  usePromisifyComponent,
} from '@just-ai/just-ui';

import { useAppDispatch, useAppSelector } from 'storeHooks';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';

import { complexRenameStateInReduxAndApi, deleteState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import deleteTheme from 'reducers/JGraph.reducer/AsyncActions/deleteTheme';
import renameTheme from 'reducers/JGraph.reducer/AsyncActions/renameTheme';
import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';
import { openScreenCreationMenu, openThemeCreationMenu } from 'reducers/JGraph.reducer';
import { getAllInnerStates } from 'reducers/JGraph.reducer/Graph';

import DeleteStateModal from 'modules/JGraph/view/RightSideMenu/Modals/DeleteStateModal';
import RenameStateModal from 'modules/JGraph/view/RightSideMenu/Modals/RenameStateModal';
import DeleteThemeModal from 'modules/JGraph/view/ThemesContextMenu/modals/DeleteThemeModal';
import RenameThemeModal from 'modules/JGraph/view/ThemesContextMenu/modals/RenameThemeModal';
import { JGraphHudInfo } from 'modules/JGraph/view/JGraphHud';
import { Vector2D } from 'modules/JGraph/utils/2DVector';

import { HierarchyViewContext } from '../index';
import { getStateOption, getThemeOption, getNoContextOptions, ItemMenu, ContextAction } from './options';

import styles from './styles.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'bottom-start',
};

const voidFn = () => {};
const DEFAULT_MODIFIERS = {
  offset: { offset: '-9, -5' },
};

interface MenuItemInterface {
  menuItem: ItemMenu;
  onSelect: (item: ItemMenu) => void;
  expanded: boolean;
  'data-test-id'?: string;
}
const MenuItem = function ({ menuItem, onSelect, expanded, 'data-test-id': dataTestId }: MenuItemInterface) {
  const [expandedChild, setExpandedChild] = useState<ItemMenu>();

  const onToggle = useCallback(() => onSelect(menuItem), [menuItem, onSelect]);

  const onSelectInner = useCallback(
    item => {
      if (item.children && item.children?.length > 0) {
        setExpandedChild(prevItem => (prevItem?.value === item.value ? prevItem : item));
        return;
      }
      onSelect(item);
    },
    [onSelect]
  );

  useEffect(() => {
    if (expanded) return;
    setExpandedChild(undefined);
  }, [expanded]);

  const IconCmp = menuItem?.iconCmp;
  return (
    <Dropdown direction='right' isOpen={expanded} toggle={voidFn}>
      <DropdownToggle tag='div' outline compact disabled>
        <div
          data-test-id={dataTestId}
          className={cn(styles.MenuItem, {
            [styles.MenuItem__danger]: menuItem.color === 'danger',
          })}
          onClick={onToggle}
        >
          <div className={styles.MenuItem__text}>
            <div className={styles.MenuItem__icon}>{IconCmp && <IconCmp />}</div>
            <div>{menuItem.label}</div>
          </div>
          {menuItem?.children && menuItem?.children.length > 0 && (
            <>
              <Icon name='farChevronRight' wrapperClassName={styles.MenuItem__arrowIcon} />
              <DropdownMenu positionFixed className={styles.dropdown} flip={false} modifiers={DEFAULT_MODIFIERS}>
                {menuItem.children.map(option => (
                  <MenuItem
                    key={option.value}
                    menuItem={option}
                    onSelect={onSelectInner}
                    expanded={expandedChild?.value === option.value}
                    data-test-id={`MultiLevelSelect.Item.${option.value}`}
                  />
                ))}
              </DropdownMenu>
            </>
          )}
        </div>
      </DropdownToggle>
    </Dropdown>
  );
};

function useContextAction() {
  const { themes, screens } = useAppSelector(state => ({
    themes: state.JGraphReducer.graph.themes,
    screens: state.JGraphReducer.graph.blocks,
  }));

  const flattenScreens = useMemo(() => {
    return screens.flatMap(screen => [screen, ...getAllInnerStates(screen)]);
  }, [screens]);

  const dispatch = useAppDispatch();
  const [deleteThemeModalNode, showDeleteThemeModal] = usePromisifyComponent(
    (resolve, reject, opened) => <DeleteThemeModal isOpen={opened} onCancel={reject} onSubmit={resolve} />,
    undefined,
    []
  );
  const deleteThemeInner = useCallback(
    (pathId: string) => {
      const currentTheme = themes.find(el => el.pathId === pathId);
      if (!currentTheme) return;
      showDeleteThemeModal().then(() => dispatch(deleteTheme({ theme: currentTheme })));
    },
    [dispatch, showDeleteThemeModal, themes]
  );

  const [deleteStateModalNode, showDeleteStateModal] = usePromisifyComponent(
    (resolve, reject, opened) => <DeleteStateModal isOpen={opened} onCancel={reject} onSubmit={resolve} />,
    undefined,
    []
  );
  const deleteStateInner = useCallback(
    (pathId: string) => {
      const currentState = flattenScreens.find(el => el.pathId === pathId);
      if (!currentState) return;
      showDeleteStateModal().then(() =>
        dispatch(deleteState({ targetStatePath: currentState.path, filename: currentState.filename }))
      );
    },
    [dispatch, flattenScreens, showDeleteStateModal]
  );

  const [renameThemeModalNode, showRenameThemeModal] = usePromisifyComponent<string, JGraphTheme>(
    (resolve, reject, opened, theme) =>
      theme && <RenameThemeModal isOpen={opened} theme={theme} onClose={reject} onRename={resolve} />,
    undefined,
    []
  );
  const renameThemeInner = useCallback(
    (pathId: string) => {
      const currentTheme = themes.find(el => el.pathId === pathId);
      if (!currentTheme) return;
      showRenameThemeModal(currentTheme).then(newName => dispatch(renameTheme({ theme: currentTheme, newName })));
    },
    [dispatch, showRenameThemeModal, themes]
  );

  const [renameStateModalNode, showRenameStateModal] = usePromisifyComponent<string, JStateWithId>(
    (resolve, reject, opened, screen) =>
      screen && <RenameStateModal screen={screen} isOpen={opened} onRename={resolve} onClose={reject} />
  );
  const renameStateInner = useCallback(
    (pathId: string) => {
      const currentState = flattenScreens.find(el => el.pathId === pathId);
      if (!currentState) return;
      showRenameStateModal(currentState).then(newName =>
        dispatch(
          complexRenameStateInReduxAndApi({
            statePath: currentState.path,
            newValue: newName,
            oldValue: currentState.value,
          })
        )
      );
    },
    [dispatch, flattenScreens, showRenameStateModal]
  );

  const createTheme = useCallback(() => {
    const hudInfo = JGraphHudInfo.getValue();
    if (!hudInfo) return;
    const centerOfHUD = Vector2D.fromObj({
      x: hudInfo.position.x + hudInfo.size.width / 2,
      y: hudInfo.position.y + hudInfo.size.height / 2,
    });
    dispatch(
      openThemeCreationMenu({
        themePosition: centerOfHUD,
        pointerPosition: centerOfHUD.subtractX(130),
      })
    );
  }, [dispatch]);

  const mapPathIdToNodeInfo = useMemo(() => {
    const map: Record<string, { theme: string; path?: string } | undefined> = {};
    themes.forEach(theme => {
      map[theme.pathId] = { theme: theme.value };
    });
    flattenScreens.forEach(state => {
      map[state.pathId] = { path: state.path, theme: state.theme };
    });
    return map;
  }, [themes, flattenScreens]);

  const createState = useCallback(
    (parentPathId: string) => {
      const nodeInfo = mapPathIdToNodeInfo[parentPathId];
      if (!nodeInfo) return;
      const hudInfo = JGraphHudInfo.getValue();
      if (!hudInfo) return;
      const centerOfHUD = Vector2D.fromObj({
        x: hudInfo.position.x + hudInfo.size.width / 2,
        y: hudInfo.position.y + hudInfo.size.height / 2,
      });
      dispatch(
        openScreenCreationMenu({
          screenPosition: centerOfHUD,
          pointerPosition: centerOfHUD.subtract({ x: 130, y: 150 }),
          parentStatePath: nodeInfo.path,
          parentThemeValue: nodeInfo.theme,
          fixedParentPath: true,
        })
      );
    },
    [dispatch, mapPathIdToNodeInfo]
  );

  return {
    deleteTheme: deleteThemeInner,
    deleteState: deleteStateInner,
    renameTheme: renameThemeInner,
    renameState: renameStateInner,
    createTheme,
    createState,
    modalsNodes: [deleteThemeModalNode, deleteStateModalNode, renameThemeModalNode, renameStateModalNode].map(
      (node, idx) => <React.Fragment key={idx}>{node}</React.Fragment>
    ),
  };
}

const HierarchyContextMenu = () => {
  const { openContextMenu$ } = React.useContext(HierarchyViewContext);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const openContextMenuInfo = useBehaviorSubject(openContextMenu$);
  const [expandedChild, setExpandedChild] = useState<ItemMenu>();

  const isEnabled = !!openContextMenuInfo?.event;

  useFloaterPosition({
    enable: isEnabled,
    floaterElement: wrapperRef,
    target: useMemo(() => {
      if (!openContextMenuInfo?.event) return null;
      return {
        width: 0,
        height: 0,
        x: openContextMenuInfo.event.pageX,
        y: openContextMenuInfo.event.pageY,
      };
    }, [openContextMenuInfo?.event]),
    options: floaterOptions,
  });

  const options = useMemo(() => {
    const node = openContextMenuInfo?.node;
    if (!node) return getNoContextOptions();
    switch (node.nodeType) {
      case 'theme':
        return getThemeOption();
      case 'state':
        return getStateOption();
      default:
        return [];
    }
  }, [openContextMenuInfo?.node]);

  useEffect(() => {
    if (isEnabled) return;
    setExpandedChild(undefined);
  }, [isEnabled]);

  const { modalsNodes, deleteTheme, deleteState, renameTheme, renameState, createTheme, createState } =
    useContextAction();

  const onSelect = useCallback(
    (item: ItemMenu) => {
      const node = openContextMenuInfo?.node;
      switch (item.value) {
        case ContextAction.CreateStep:
          if (!node) return;
          if (node.nodeType === 'theme') {
            createState(node.nodeId);
          }
          if (node.nodeType === 'state' && node.parentId) {
            createState(node.parentId);
          }
          break;
        case ContextAction.CreateInnerStep:
          if (!node) return;
          if (node.nodeType === 'state') {
            createState(node.nodeId);
          }
          break;
        case ContextAction.CreateTheme:
          createTheme();
          break;
        case ContextAction.Delete:
          if (!node) return;
          if (node.nodeType === 'theme') {
            deleteTheme(node.nodeId);
          }
          if (node.nodeType === 'state') {
            deleteState(node.nodeId);
          }
          break;
        case ContextAction.Rename:
          if (!node) return;
          if (node.nodeType === 'theme') {
            renameTheme(node.nodeId);
          }
          if (node.nodeType === 'state') {
            renameState(node.nodeId);
          }
          break;

        default:
          return;
      }
    },
    [openContextMenuInfo?.node, createState, createTheme, deleteTheme, deleteState, renameTheme, renameState]
  );

  const onSelectInner = useCallback(
    (item: ItemMenu) => {
      if (item.cantSelect && item.children && item.children.length > 0) {
        setExpandedChild(prevItem => (prevItem?.value === item.value ? prevItem : item));
        return;
      }
      openContextMenu$.next(undefined);
      onSelect(item);
    },
    [onSelect, openContextMenu$]
  );

  return (
    <div className={styles.HierarchyContextMenu} ref={wrapperRef}>
      {isEnabled && (
        <ClickAwayListener handleClickOut={() => openContextMenu$.next(undefined)}>
          <Dropdown direction='down' isOpen={true} addonType='prepend' toggle={voidFn}>
            <DropdownToggle tag='div' outline compact disabled>
              <DropdownMenu positionFixed className={styles.dropdown}>
                {options.map(option => (
                  <MenuItem
                    key={option.value}
                    menuItem={option}
                    expanded={expandedChild?.value === option.value}
                    onSelect={onSelectInner}
                  />
                ))}
              </DropdownMenu>
            </DropdownToggle>
          </Dropdown>
        </ClickAwayListener>
      )}
      {modalsNodes}
    </div>
  );
};

HierarchyContextMenu.displayName = 'HierarchyContextMenu';

export default React.memo(HierarchyContextMenu);
