import { Icon } from '@just-ai/just-ui';
import React from 'react';

import styles from './styles.module.scss';

interface ThemesHeaderIconProps {
  active: boolean;
  onClick: () => void;
}
const ThemesHeaderIcon = ({ active, onClick }: ThemesHeaderIconProps) => {
  return (
    <div className={styles.ThemesHeaderIcon}>
      <Icon
        size='md'
        name='farGlobe'
        color={active ? 'primary' : 'secondary'}
        onClick={onClick}
        data-test-id='Editor.MainToolbar.Themes.Globe'
      />
    </div>
  );
};

ThemesHeaderIcon.displayName = 'ThemesHeaderIcon';

export default React.memo(ThemesHeaderIcon);
