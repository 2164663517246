import { t } from 'localization';
import React, { useCallback } from 'react';
import classes from './HowToCreateCustomTag.module.scss';
import localize from 'localization';
import { howToCreateCustomTagLocalization } from './howToCreateCustomTag.loc';

localize.addTranslations(howToCreateCustomTagLocalization);

export const HowToCreateCustomTag = () => {
  const onClick = useCallback(e => {
    if (e.target.tagName !== 'A') {
      window.open(t('HowToCreateCustomTag:linkToDock'), '_blank');
    }
  }, []);

  return (
    <>
      <hr className={classes.hr} />
      <div className={classes.HowToCreateCustomTag} onClick={onClick}>
        <b>{t('HowToCreateCustomTag:boldText')}</b>
        <p dangerouslySetInnerHTML={{ __html: t('HowToCreateCustomTag:text', t('HowToCreateCustomTag:linkToDock')) }} />
      </div>
    </>
  );
};
