export const GET_KEYS = 'GET_KEYS';
export const GET_KEYS_PENDING = 'GET_KEYS_PENDING';
export const GET_KEYS_FULFILLED = 'GET_KEYS_FULFILLED';
export const GET_KEYS_REJECTED = 'GET_KEYS_REJECTED';

export const GET_CURRENT_TARIFF = 'GET_CURRENT_TARIFF';
export const GET_CURRENT_TARIFF_PENDING = 'GET_CURRENT_TARIFF_PENDING';
export const GET_CURRENT_TARIFF_FULFILLED = 'GET_CURRENT_TARIFF_FULFILLED';
export const GET_CURRENT_TARIFF_REJECTED = 'GET_CURRENT_TARIFF_REJECTED';

export const ACTIVATE_PLATFORM = 'ACTIVATE_PLATFORM';
export const ACTIVATE_PLATFORM_PENDING = 'ACTIVATE_PLATFORM_PENDING';
export const ACTIVATE_PLATFORM_FULFILLED = 'ACTIVATE_PLATFORM_FULFILLED';
export const ACTIVATE_PLATFORM_REJECTED = 'ACTIVATE_PLATFORM_REJECTED';

export const GET_KEY_INFO = 'GET_KEY_INFO';

export const GET_KEY_WITH_LIMITS = 'GET_KEY_WITH_LIMITS';
export const GET_KEY_WITH_LIMITS_PENDING = 'GET_KEY_WITH_LIMITS_PENDING';
export const GET_KEY_WITH_LIMITS_FULFILLED = 'GET_KEY_WITH_LIMITS_FULFILLED';
export const GET_KEY_WITH_LIMITS_REJECTED = 'GET_KEY_WITH_LIMITS_REJECTED';
