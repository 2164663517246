var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDrop, useDragLayer } from 'react-dnd';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { DragTypes } from '../../../../../../views/FAQTreePage/DragTypes';
import { TreeContext, TREE_DEPTH_PADDING } from '../../index';
import styles from '../../styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
const GROUP_ICON_WIDTH = 40;
const Branch = function ({ node, isEdit, style, initialChildrenLength, searchValue, isSearchActive, toggleSearchMode, }) {
    var _a, _b, _c;
    const context = useContext(TreeContext);
    const isExpanded = useMemo(() => context.expandedMap[node.id], [context.expandedMap, node.id]);
    const isSelected = useMemo(() => context.selectedIds.includes(node.id), [context.selectedIds, node.id]);
    const [editText, setEditText] = useState(node.name);
    const containerNodeRef = useRef(null);
    const { t } = useTranslation();
    useEffect(() => {
        if (!!searchValue && node.children.length > 0 && !isExpanded) {
            context.onExpandToggle(node);
        }
        if (!!searchValue && node.children.length === 0 && isExpanded) {
            context.onExpandToggle(node);
        }
        if (isSearchActive && (searchValue === null || searchValue === void 0 ? void 0 : searchValue.length) === 0 && isExpanded) {
            context.onExpandToggle(node);
        }
    }, [searchValue, node, isExpanded, context, isSearchActive, isSelected]);
    useEffect(() => {
        setEditText(node.name);
    }, [node.name]);
    const onContextMenu = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        !isEdit && context.handleContextMenu(event, node);
    }, [context, isEdit, node]);
    const onRename = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const { setEditId, onRename, setError, hasError } = context;
        if (node.name === editText) {
            setEditId(undefined);
            return;
        }
        if (hasError) {
            setEditText(node.name);
            setEditId(undefined);
            setError(undefined);
            return;
        }
        onRename(editText, node)
            .then(() => {
            setEditText(node.name);
            setEditId(undefined);
            setError(undefined);
        })
            .catch(error => {
            setError({
                text: t('FAQ:RenameError:AlreadyExists'),
                node: containerNodeRef.current || undefined,
                direction: 'left',
            });
        });
    }), [context, editText, node]);
    const onKeydownInput = useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        if (e.key !== 'Enter')
            return;
        yield onRename();
    }), [onRename]);
    const onChange = useCallback((e) => {
        setEditText(e.target.value);
        const { hasError, setError } = context;
        if (hasError)
            setError(undefined);
    }, [context]);
    const [{ isOver }, drop] = useDrop({
        accept: DragTypes.LEAF,
        drop: (item) => {
            context.onDragDrop(item.node, node);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    });
    const { isDraggingOtherElement } = useDragLayer(monitor => ({
        isDraggingOtherElement: monitor.isDragging(),
    }));
    const handleGroupClick = useCallback(() => {
        if (isSearchActive) {
            toggleSearchMode && toggleSearchMode();
            context.onSelectToggle(node);
            !isExpanded && context.onExpandToggle(node);
        }
        else if (!isEdit)
            context.onSelectToggle(node);
    }, [isSearchActive, isEdit, context, node, toggleSearchMode, isExpanded]);
    const depthLevel = (_a = node.depthLevel) !== null && _a !== void 0 ? _a : 0;
    const paddingLeft = depthLevel ? depthLevel * TREE_DEPTH_PADDING : '';
    return (_jsxs("div", { style: style, ref: containerNodeRef, children: [_jsxs("div", { ref: drop, className: cn(styles.node, {
                    [styles.node__groupSelected]: isSelected,
                    [styles.disableHover]: isDraggingOtherElement && !isOver,
                    [styles.node__elementDisabled]: !node.enabled,
                    [styles.node__isOver]: isOver,
                }), style: { paddingLeft }, onClick: handleGroupClick, onContextMenu: onContextMenu, "data-test-id": `FAQ.Tree.Branch.${node.name}`, children: [_jsx("span", { onClick: e => {
                            if (isSearchActive)
                                return;
                            e.stopPropagation();
                            !isEdit && context.onExpandToggle(node);
                        }, "data-test-id": `FAQ.Tree.Branch.${node.name}.toggle`, children: isExpanded ? _jsx(Icon, { name: 'faChevronDown', size: 'sm' }) : _jsx(Icon, { name: 'faChevronRight', size: 'sm' }) }), isEdit ? (_jsxs("div", { className: styles.editContainer, children: [_jsx("input", { value: editText, type: 'text', autoFocus: true, "data-test-id": `FAQ.Tree.Branch.${node.name}.input`, placeholder: t('FAQ:Intent:BaseGroupName'), className: styles.node__editControl, onKeyDown: onKeydownInput, onChange: onChange, onBlur: onRename }), context.hasError && _jsx(Icon, { name: 'farExclamationCircle', className: styles.errorIcon })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.text, children: node.name }), _jsx("span", { style: { color: 'var(--gray-500)' }, children: !!searchValue ? `${node.children.length}/${initialChildrenLength}` : node.children.length })] }))] }), isExpanded &&
                (!node.leafs ||
                    (((_b = node.leafs) === null || _b === void 0 ? void 0 : _b.length) === 0 && ((_c = node === null || node === void 0 ? void 0 : node.branches) === null || _c === void 0 ? void 0 : _c.length) === 0 && (_jsx("div", { style: { paddingLeft: (paddingLeft || 0) + GROUP_ICON_WIDTH }, className: styles.node__emptyMessage, children: t('FAQ:Intent:EmptyGroup') }))))] }));
};
export default React.memo(Branch);
