import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import Konva from 'konva';
import cn from 'classnames';
import { Portal, useBehaviorSubject } from '@just-ai/just-ui';

import { Vector2d } from 'konva/lib/types';
import { KonvaEventObject } from 'konva/lib/Node';

import { useAppDispatch, useAppSelector } from 'storeHooks';

import { getConnectionsFromBlocks } from 'reducers/JGraph.reducer/Graph';
import { EditMenuBlock, JStateWithId, GroupedConnector, TConnector, JGraphTheme } from 'reducers/JGraph.reducer/types';
import {
  makeNewConnectorAsync,
  onStageClickAsync,
  updateJGraphVisuals,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';
import {
  JGLS,
  openContextMenu,
  openScreenCreationMenu,
  setEditMenuBlock,
  showStateNameEditField,
  toggleAddingActionsMenu,
  closeScreenCreationMenu,
  setSelectedTheme,
} from 'reducers/JGraph.reducer';
import LabelingToolMenu, { LabelingToolMenu$ } from 'modules/JGraph/view/LabelingToolMenu';
import SearchState from 'modules/JGraph/view/SearchState.feature';

import { useStageObservableContext } from '../contexts/StageObservablesProvider';
import { ConnectorLayer } from './ConnectorLayer';
import { StateScreen } from './StateScreen';
import {
  findParentScreenName,
  initialNewConnectorState,
  lazyActionsSubject,
  newConnectorPipe$,
  newConnectorSubject$,
  useStageSizes,
} from '../hooks';
import {
  getCenterOfStage,
  getInnerScreensPathIdMap,
  getStageFromEvent,
  setScale,
  StageActions,
} from '../utils/stageUtils';
import { updateUsingStageProps } from '../utils/connectionLayerUtils';

import { FloatingConnectorMenu, FloatingConnectorMenu$ } from './FloatingConnectorMenu';
import { mainSave$, useSavingPipe } from '../hooks/savingPipe';
import { IncomingOutgoingConnections } from './IncomingOutgoingConnections';
import ActionLayer from './Layers/ActionLayer';
import { getMinMaxScreensCoords } from '../utils/common';
import { JGToolbar, JGToolbarIconButton } from './JGToolbar';

import { t } from 'localization';
import JGToolbarClasses from './JGToolbar/JGToolbar.module.scss';

import { CommitButtonWithContext } from './CommitButton';
import { useCopyToClipboardStateKeyboardHandlers } from 'modules/JGraph/utils/clipboard';
import { HighLightConnectorsStatus } from './HighLightConnectorsStatus';

import AutoPlacementButton from './AutoPlacementButton';
import { highLightConnectors$ } from '../hooks/highLightConnectors';
import { StickerLayer } from './StickerLayer';
import { useStickerActions, useStickerCreationMode } from './Sticker/hooks';
import keyboardjs from 'keyboardjs';
import { callIfAbleToApplyHotKey } from 'utils/hotkeys';
import { useStatesStoreRef } from '../hooks/useStatesStore';
import { getIntersection } from '../utils/blockLayerUtils';
import { CollapsedConnectorsMenu, CollapsedConnectorsMenuClose } from './Connector/CollapsedConnectorsMenu';
import { hiddenConnectionsPipe$ } from '../hooks/hiddenConnections';
import { StateCollapseSubjectPipe$ } from './StateScreen.hooks';
import { combineLatest } from 'rxjs';
import { LevelUpButton } from './LevelUpButton';
import { groupBy } from 'lodash';
import { OtherHudActions } from './OtherHudActions';

import { useStageActions } from '../hooks/useStageActions';
import { useStickersByPath } from '../hooks/useStickersByPath';

type StageViewProps = {
  containerRef: RefObject<HTMLDivElement>;
  screens: JStateWithId[];
  showIncomingOutgoingConnections?: boolean;
  parentScreenPath?: string;
  StageID?: string;
};

function groupConnectorsByThemes(
  themes: JGraphTheme[],
  incomingConnectors: TConnector[],
  groupByConnectionType: 'from' | 'to'
): GroupedConnector[] {
  const sortedThemes = [...themes].sort((a, b) => b.value.length - a.value.length);
  const groups = groupBy(incomingConnectors, connector => {
    const theme = sortedThemes.find(theme =>
      connector[groupByConnectionType === 'from' ? 'fromNodeOriginalPath' : 'toNodeOriginalPath'].startsWith(
        theme.value
      )
    );
    if (!theme) return '';
    return theme.value;
  });
  return Object.entries(groups).reduce((acc, [groupKey, connectors]) => {
    const theme = themes.find(theme => theme.value === groupKey);
    if (!theme) return acc;
    acc.push({ theme, connectors });
    return acc;
  }, [] as GroupedConnector[]);
}

export const StageView = React.memo(
  ({
    containerRef,
    parentScreenPath = '',
    screens,
    showIncomingOutgoingConnections = false,
    StageID,
  }: StageViewProps) => {
    const observableProps = useStageObservableContext();
    const {
      isEditModeEnable,
      fromStateTransitions,
      isAddingMenuOpen,
      screenCreationMenu,
      contextMenu,
      loadingGraph,
      loadingCustomTags,
      selectedTheme,
      themes,
      allScreens,
    } = useAppSelector(state => {
      return {
        isEditModeEnable: state.JGraphReducer.isEditModeEnable,
        fromStateTransitions: state.JGraphReducer.graph.fromStateTransitions,
        isAddingMenuOpen: state.JGraphReducer.isAddingMenuOpen,
        screenCreationMenu: state.JGraphReducer.screenCreationMenu,
        contextMenu: state.JGraphReducer.contextMenu,
        selectedTheme: state.JGraphReducer.selectedTheme,
        themes: state.JGraphReducer.graph.themes,
        allScreens: state.JGraphReducer.graph.blocks,
        loadingGraph: state.JGraphReducer.loadingGraph,
        loadingCustomTags: state.JGraphReducer.loadingCustomTags,
        stickers: state.JGraphReducer.stickers,
      };
    });

    const stickersOnStage = useStickersByPath(observableProps.selectedGroupPath);

    const screenSizesMap = useStatesStoreRef();
    const dispatch = useAppDispatch();
    const { saveMove } = useSavingPipe();

    useCopyToClipboardStateKeyboardHandlers(observableProps.isActive);

    const stage = React.useRef<Konva.Stage | null>(null);
    const blocksLayer = React.useRef<Konva.Layer>(null);
    const splinesLayer = React.useRef<Konva.Layer>(null);
    const actionLayer = React.useRef<Konva.Layer>(null);

    const [stageDidMount, setDidMount] = useState(false);
    useEffect(() => {
      if (stageDidMount) return;
      if (stage.current) {
        setDidMount(true);
      }
    }, [stageDidMount]);

    const parentPath = observableProps?.selectedGroupPath || selectedTheme?.value || '';
    const size = useStageSizes(containerRef.current);
    useEffect(() => {
      if (!loadingGraph && !loadingCustomTags && stage.current) {
        JGLS.store.getStagePathSettings(parentPath).then(stageSettings => {
          if (!stage.current) return;
          stage.current.position(stageSettings?.stagePosition);
          stage.current.scale({ x: stageSettings.stageScale, y: stageSettings.stageScale });
        });
      }
    }, [loadingGraph, loadingCustomTags, parentPath]);

    const newConnectorEndRx = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        const sub = newConnectorPipe$.subscribe(innerStore => {
          if (!innerStore.mouseUp && innerStore.started) {
            if (innerStore.from) {
              const [targetStateName] = findParentScreenName(event.target);
              if (targetStateName) {
                newConnectorSubject$.next(initialNewConnectorState);
                dispatch(
                  makeNewConnectorAsync({
                    from: innerStore.from,
                    to: targetStateName,
                  })
                );
              } else {
                newConnectorSubject$.next({ mouseUp: true });
                dispatch(
                  openScreenCreationMenu({
                    screenPosition: innerStore.toPosition,
                    pointerPosition: { x: event.evt.pageX, y: event.evt.pageY },
                    from: innerStore.from,
                    transitionTo: innerStore.transitionTo,
                    parentStatePath: observableProps.selectedGroupPath,
                    parentThemeValue: selectedTheme?.value,
                  })
                );
              }
            }
          }
        });
        sub.unsubscribe();
      },
      [dispatch, observableProps.selectedGroupPath, selectedTheme?.value]
    );

    const setScaleAndSave = useCallback(
      (ev: KonvaEventObject<WheelEvent | DragEvent>) => {
        if (isAddingMenuOpen) return;
        LabelingToolMenu$.next(null);
        CollapsedConnectorsMenuClose();
        const [newScale, newPosition] =
          ev.evt.type === 'wheel'
            ? setScale(ev as KonvaEventObject<WheelEvent>)
            : [stage.current!.scaleX(), stage.current!.position()];
        if (ev.evt.type === 'wheel' || !!ev.target.attrs.isStage) {
          const settings = {
            stageScale: newScale,
            stagePosition: newPosition,
          };
          const stageStage = getStageFromEvent(ev);
          observableProps.getStage(stageStage);
          lazyActionsSubject.next({
            type: 'saveVisualStageFileSettings',
            action: async () => await JGLS.store.saveStagePathSettings(settings, parentPath),
          });
        }
      },
      [isAddingMenuOpen, observableProps, parentPath]
    );

    const openContextMenuHandler = useCallback(
      (event: Konva.KonvaEventObject<PointerEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();
        event.evt.stopPropagation();

        if (contextMenu.open || !stage.current) return;

        dispatch(closeScreenCreationMenu());

        let screenPosition = stage.current.getPointerPosition() || { x: 0, y: 0 };
        [, screenPosition] = updateUsingStageProps(stage.current, { x: 0, y: 0 }, screenPosition);

        const intersection = getIntersection(screenPosition, screenSizesMap.current, event.currentTarget);
        const pointerPosition = {
          x: event.evt.pageX,
          y: event.evt.pageY,
        };
        dispatch(
          openContextMenu({
            screenPosition,
            pointerPosition,
            statePath: intersection?.statePath,
            statePosition: intersection?.coords,
            themePath: selectedTheme?.value,
          })
        );
      },
      [contextMenu.open, dispatch, screenSizesMap, selectedTheme?.value]
    );

    const alternateOpenCreationMenu = useCallback(
      (event: React.SyntheticEvent) => {
        if (!screenCreationMenu.open) {
          if (stage.current) {
            event.stopPropagation();
            let screenPosition = getCenterOfStage(stage.current) || { x: 0, y: 0 };
            const targetPosition = event.currentTarget.getBoundingClientRect();
            dispatch(
              openScreenCreationMenu({
                screenPosition: screenPosition,
                pointerPosition: {
                  x: targetPosition.right + 18,
                  y: targetPosition.top + 22,
                },
                parentStatePath: observableProps.selectedGroupPath,
                parentThemeValue: selectedTheme?.value,
                isOpenedByButton: true,
              })
            );
          }
        }
      },
      [screenCreationMenu.open, dispatch, observableProps.selectedGroupPath, selectedTheme?.value]
    );

    const dropNewConnection = useCallback(isKonvaCreationMenuOpen => {
      isKonvaCreationMenuOpen && newConnectorSubject$.next(initialNewConnectorState);
    }, []);

    const stageActions = useMemo(
      (): StageActions => ({
        toggleAddingActionsMenu: (event: Konva.KonvaEventObject<MouseEvent>, screenId?: string) => {
          event.cancelBubble = true;
          isEditModeEnable && dispatch(toggleAddingActionsMenu({ event, screenId }));
        },
        showStateNameEditField: (event: Konva.KonvaEventObject<MouseEvent>, stateName: string) => {
          event.cancelBubble = true;
          dispatch(showStateNameEditField({ event, stateName }));
        },
        setEditMenuBlock: (editMenuBlock?: EditMenuBlock) =>
          isEditModeEnable && dispatch(setEditMenuBlock(editMenuBlock)),
      }),
      [isEditModeEnable, dispatch]
    );

    const onStageClick = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        CollapsedConnectorsMenuClose();
        FloatingConnectorMenu$.next({ connector: undefined });
        const highLightConnectorsValue = highLightConnectors$.getValue();
        if (highLightConnectorsValue.connector) {
          highLightConnectors$.next({ ...highLightConnectorsValue, connector: null });
        }
        if (!isEditModeEnable) return;
        dispatch(onStageClickAsync({ event }));
        dropNewConnection(screenCreationMenu.open);
      },
      [dispatch, dropNewConnection, isEditModeEnable, screenCreationMenu.open]
    );

    const stageGetRef = useCallback(
      Stage => {
        if (window.isUnderTest) {
          //@ts-ignore
          window.jgStage = Stage;
        }
        observableProps.getStage(Stage);
        stage.current = Stage;
      },
      [observableProps]
    );

    const flatScreensPathId = useMemo(() => getInnerScreensPathIdMap(screens), [screens]);
    const allScreensPathId = useMemo(() => new Set(getInnerScreensPathIdMap(allScreens)), [allScreens]);

    const incomingConnectors = useMemo(() => {
      if (!showIncomingOutgoingConnections) return [];
      const incomingConnectors = observableProps
        .getConnectors()
        .filter(
          connector => flatScreensPathId.includes(connector.to || '') && !flatScreensPathId.includes(connector.fromNode)
        );

      return groupConnectorsByThemes(themes, incomingConnectors, 'from');
    }, [showIncomingOutgoingConnections, observableProps, flatScreensPathId, themes]);

    const outgoingConnectors = useMemo(() => {
      if (!showIncomingOutgoingConnections) return [];
      const tConnectors = getConnectionsFromBlocks(screens).connections;

      let connections = tConnectors.filter(
        connector =>
          allScreensPathId.has(connector.to || '') &&
          !flatScreensPathId.includes(connector.to || '') &&
          !connector.from.includes('fromState')
      );
      //INFO this is for "fromState" connections
      let aboveConnectors = observableProps
        .getConnectors()
        .filter(
          aboveConnection =>
            aboveConnection.from.includes('fromState') &&
            allScreensPathId.has(aboveConnection.fromNode) &&
            flatScreensPathId.includes(aboveConnection.fromNode) &&
            !aboveConnection.toNodeOriginalPath.startsWith(observableProps.selectedGroupPath!)
        );
      aboveConnectors.forEach(connect => connections.push(connect));
      return groupConnectorsByThemes(themes, connections, 'to');
    }, [allScreensPathId, showIncomingOutgoingConnections, screens, observableProps, themes, flatScreensPathId]);

    const initialIncomingOutgoingPosition = useMemo<{ incoming: Vector2d; outgoing: Vector2d }>(() => {
      const { minX, minY, maxX } = getMinMaxScreensCoords(screens);
      return {
        incoming: { x: minX - 850, y: minY },
        outgoing: { x: maxX + 850, y: minY },
      };
    }, [screens]);

    const StageIDInner = StageID || 'StageID';

    const onStageDragStart = useCallback(() => {
      LabelingToolMenu$.next(null);
      CollapsedConnectorsMenuClose();
    }, []);

    useEffect(() => {
      const sub = combineLatest([hiddenConnectionsPipe$, StateCollapseSubjectPipe$]).subscribe(
        ([valueConnectors, collapsedState]) => {
          if (valueConnectors.lastAction === 'load' && collapsedState.lastAction === 'load') return;
          requestAnimationFrame(() =>
            mainSave$.next({
              path: '',
              type: 'updateJGraphVisuals',
              action: () =>
                dispatch(
                  updateJGraphVisuals({
                    jGraphVisualsData: {
                      connectors: {
                        from: Array.from(valueConnectors.from),
                        to: Array.from(valueConnectors.to),
                      },
                      collapsedStates: collapsedState.value
                        .filter(stateStatus => stateStatus.collapsed)
                        .map(stateStatus => stateStatus.statePath),
                    },
                  })
                ),
            })
          );
        }
      );

      return () => sub.unsubscribe();
    }, [dispatch]);

    const stickerActions = useStickerActions();
    const stickerFeatureState = useStickerCreationMode(stage.current);

    useEffect(() => {
      const tryToggleStickerMode = () => callIfAbleToApplyHotKey(stickerFeatureState.toggleStickerMode);
      keyboardjs.on(['t'], tryToggleStickerMode);
      return () => {
        keyboardjs.off(['t'], tryToggleStickerMode);
      };
    }, [stickerFeatureState]);

    const onSelectTheme = useCallback(
      (theme: JGraphTheme) => {
        if (!theme.value) return;
        dispatch(setSelectedTheme({ value: theme.value }));
      },
      [dispatch]
    );

    const isGlobalSearchOpenedByButton = useBehaviorSubject(SearchState.GlobalSearchOpenedByButton$);
    const stageHudActions = useStageActions(stage, observableProps.selectedGroupPath);

    return (
      <div
        style={{ flex: 1 }}
        className={cn({
          'sticker-mode-enabled': stickerFeatureState.stickerModeOpened,
        })}
      >
        {isEditModeEnable && (
          <Portal targetNodeSelector='#JGHud-stage-ui'>
            <div className='jgraph-toolbar-container'>
              <div className='vertical-container'>
                <div className='horizontal-container'>
                  <JGToolbar type='horizontal'>
                    <JGToolbarIconButton
                      className={cn(JGToolbarClasses.jgToolbarButton, {
                        active: isGlobalSearchOpenedByButton,
                      })}
                      iconName='farSearch'
                      onClick={stageHudActions.actions.openSearch}
                      data-test-id='JGToolbar:Search'
                      id={`${StageIDInner}_Search`}
                      tooltip={t('JGToolbar:Search')}
                      placement='bottom'
                    />
                    <CommitButtonWithContext className={JGToolbarClasses.jgToolbarButton} />
                    <OtherHudActions
                      className={JGToolbarClasses.jgToolbarButton}
                      options={[
                        {
                          label: t('JGToolbar:Export'),
                          icon: 'farArrowToBottom',
                          dataTestId: 'JGToolbar:Export',
                          onClick: stageHudActions.actions.createStatesPagePng,
                        },
                        {
                          label: t('JGToolbar:Help'),
                          dataTestId: 'JGToolbar:Help',
                          icon: 'faQuestionCircle',
                          link: t('JGToolbar:Help:Url'),
                        },
                      ]}
                    />
                    {stageHudActions.nodes}
                  </JGToolbar>
                  <JGToolbar type='horizontal'>
                    <HighLightConnectorsStatus StageID={StageIDInner} />
                  </JGToolbar>
                  <JGToolbar type='horizontal'>
                    <LevelUpButton />
                  </JGToolbar>
                </div>
                <JGToolbar type='vertical'>
                  <JGToolbarIconButton
                    className={cn(JGToolbarClasses.jgToolbarButton, {
                      active: screenCreationMenu.open && screenCreationMenu.isOpenedByButton,
                    })}
                    iconName='farPlusSquare'
                    onMouseDown={alternateOpenCreationMenu}
                    data-test-id='JGToolbar:ShowMenuButton'
                    id={`${StageIDInner}_ShowMenuButton`}
                    tooltip={t('JGToolbar:ShowMenuButton')}
                    placement='right'
                  />
                  <JGToolbarIconButton
                    className={cn(JGToolbarClasses.jgToolbarButton, {
                      active: stickerFeatureState.stickerModeOpened,
                    })}
                    iconName='farStickyNote'
                    onClick={stickerFeatureState.toggleStickerMode}
                    data-test-id='JGToolbar:CreateSticker'
                    id={`${StageIDInner}_CreateSticker`}
                    tooltip={t('JGToolbar:StickyNote')}
                    placement='right'
                  />
                </JGToolbar>
                <JGToolbar type='vertical'>
                  <AutoPlacementButton stageInnerId={StageIDInner} classname={JGToolbarClasses.jgToolbarButton} />
                </JGToolbar>
              </div>

              <Portal targetNodeSelector='#JGHud-container'>
                <FloatingConnectorMenu />
                <LabelingToolMenu />
              </Portal>
              <CollapsedConnectorsMenu />
            </div>
          </Portal>
        )}
        <Stage
          id={StageIDInner}
          width={size.width}
          height={size.height}
          isStage={true}
          style={{ cursor: '' }}
          ref={stageGetRef}
          onDragStart={onStageDragStart}
          draggable={!isAddingMenuOpen}
          onDragEnd={setScaleAndSave}
          onWheel={setScaleAndSave}
          actions={stageActions}
          onClick={onStageClick}
          onMouseUp={newConnectorEndRx}
          onContextMenu={openContextMenuHandler}
          useStrictMode
          ctx={observableProps}
          parentScreenPath={parentScreenPath}
        >
          {stageDidMount ? (
            <>
              <ConnectorLayer ref={splinesLayer} isEditModeEnable={isEditModeEnable} />
              <Layer ref={blocksLayer} isLayer={true}>
                {showIncomingOutgoingConnections && (
                  <IncomingOutgoingConnections
                    type='incoming'
                    selectedTheme={selectedTheme}
                    groupedConnections={incomingConnectors}
                    position={initialIncomingOutgoingPosition.incoming}
                    connectorsFromStore$={observableProps.connectorsFromStore$}
                    onSelectTheme={onSelectTheme}
                  />
                )}
                {screens.map(screen => (
                  <StateScreen
                    key={screen.path}
                    screen={screen}
                    draggable={isEditModeEnable && !isAddingMenuOpen}
                    isEditModeEnable={isEditModeEnable}
                    saveBlockMovement={saveMove}
                    setEditMenuBlock={stageActions.setEditMenuBlock}
                    recalculateConnections={observableProps.recalculateConnections}
                    fromStateTransitions={fromStateTransitions}
                  />
                ))}

                {showIncomingOutgoingConnections && (
                  <IncomingOutgoingConnections
                    type='outgoing'
                    selectedTheme={selectedTheme}
                    groupedConnections={outgoingConnectors}
                    position={initialIncomingOutgoingPosition.outgoing}
                    connectorsFromStore$={observableProps.connectorsFromStore$}
                    onSelectTheme={onSelectTheme}
                  />
                )}
              </Layer>
              <ActionLayer ref={actionLayer} />
              {observableProps.isActive && <StickerLayer stickerActions={stickerActions} stickers={stickersOnStage} />}
            </>
          ) : null}
        </Stage>
      </div>
    );
  }
);
StageView.displayName = 'StageView';
