var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, PureComponent, useContext } from 'react';
import LabelsService from '../service/LabelsService';
import { Spinner } from '@just-ai/just-ui';
import { makeLabelsFromGroups } from './utils';
import { colorsForLabels } from './helpers';
export const LabelsContext = createContext({});
export var TypeMapToState;
(function (TypeMapToState) {
    TypeMapToState["states"] = "issuesStates";
    TypeMapToState["labels"] = "issuesLabels";
    TypeMapToState["priorities"] = "issuesPriorities";
})(TypeMapToState || (TypeMapToState = {}));
export class LabelsContextProviderState {
    constructor() {
        this.loaded = false;
        this.loading = false;
        this.labels = [];
        this.groups = [];
        this.sessionLabels = [];
        this.sessionLabelColors = [];
        this.labelColors = [];
        this[_a] = [];
        this[_b] = [];
        this[_c] = [];
    }
}
_a = TypeMapToState.labels, _b = TypeMapToState.states, _c = TypeMapToState.priorities;
export class LabelsContextProvider extends PureComponent {
    constructor() {
        super(...arguments);
        this.translate = this.props.translate;
        this.labelsServiceApi = new LabelsService(NaN, this.props.useReporter);
        this.state = new LabelsContextProviderState();
        this.load = promise => {
            this.setState({ loading: true });
            return promise.finally(() => this.setState({ loading: false }));
        };
        this.getLabels = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            try {
                if (this.props.disabled) {
                    this.setState({ loading: false, loaded: true });
                    return;
                }
                const resultsArray = yield Promise.all([
                    this.labelsServiceApi.getLabelGroups(),
                    this.labelsServiceApi.getSessionLabels(),
                    this.labelsServiceApi.getIssuesRequestedType('labels'),
                    this.labelsServiceApi.getIssuesRequestedType('states'),
                    this.labelsServiceApi.getIssuesRequestedType('priorities'),
                ]);
                //ts-error Promise.all infer only first type of return data in array;
                let [{ data: labelGroups }, { data: sessionLabels }, { data: issuesLabels }, { data: issuesStates }, { data: issuesPriorities },] = resultsArray;
                issuesLabels.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                issuesStates.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                issuesPriorities.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                let labelsColors = [];
                if (sessionLabels === null || sessionLabels === void 0 ? void 0 : sessionLabels.map) {
                    //@ts-ignore
                    sessionLabels === null || sessionLabels === void 0 ? void 0 : sessionLabels.map((sessionLabel, i) => {
                        labelsColors.push({
                            name: sessionLabel.name,
                            color: colorsForLabels[i],
                            active: true,
                        });
                    });
                }
                let labelsPhraseColors = [];
                if (Array.isArray(labelGroups)) {
                    labelGroups.map((labelGroup, i) => {
                        labelGroup.labels.map((label, j) => {
                            labelsPhraseColors.push({
                                name: label.name,
                                color: colorsForLabels[labelsColors.length + i + j],
                                active: true,
                            });
                        });
                    });
                }
                this.setState({
                    loaded: true,
                    loading: false,
                    groups: labelGroups,
                    sessionLabels: sessionLabels,
                    labels: makeLabelsFromGroups(labelGroups),
                    sessionLabelColors: labelsColors,
                    labelColors: labelsPhraseColors,
                    issuesLabels: issuesLabels,
                    issuesStates: issuesStates,
                    issuesPriorities: issuesPriorities,
                });
            }
            catch (e) {
                this.setState({ loading: false });
            }
        });
        this.deleteLabelGroup = (id) => {
            this.setState({
                groups: this.state.groups.filter(group => group.id !== id),
                labels: this.state.labels.filter(label => {
                    var _d;
                    //@ts-ignore
                    return (label.groupId || ((_d = label.group) === null || _d === void 0 ? void 0 : _d.id)) !== id;
                }),
            });
            this.labelsServiceApi.deleteLabelGroup(id);
        };
        this.deleteLabel = (id) => {
            const label = this.state.labels.find(label => label.id === id);
            if (label) {
                let newGroups = [...this.state.groups];
                //@ts-ignore
                const foundGroupId = newGroups.find(group => { var _d; return group.id === label.groupId || group.id === ((_d = label.group) === null || _d === void 0 ? void 0 : _d.id); });
                if (foundGroupId) {
                    foundGroupId.labels = foundGroupId.labels.filter(label => label.id !== id);
                    this.setState({
                        labels: this.state.labels.filter(label => label.id !== id),
                        groups: newGroups,
                    });
                    this.labelsServiceApi.deleteLabel(id);
                }
            }
        };
        this.resortGroups = (groups) => {
            this.setState({
                groups: groups,
            });
            const newPriority = groups.map((item, index) => ({
                id: item.id,
                newPriority: index + 1,
            }));
            this.labelsServiceApi.updateGroupsPriority(newPriority);
        };
        this.resortLabelsInGroup = (id, labels) => {
            let newGroups = [...this.state.groups];
            const group = newGroups.find(group => group.id === id);
            if (group) {
                group.labels = labels;
                this.setState({
                    groups: newGroups,
                });
            }
            const newPriority = labels.map((item, index) => ({
                id: item.id,
                newPriority: index + 1,
            }));
            this.labelsServiceApi.updateLogLabelsPriority(newPriority);
        };
        this.updateLabel = (labelId, newName) => {
            var _d;
            const foundLabel = this.state.labels.find(label => label.id === labelId);
            if (foundLabel) {
                foundLabel.name = newName;
                let newGroups = [...this.state.groups];
                this.setState({
                    groups: newGroups,
                });
                this.labelsServiceApi.updateLabel(labelId, {
                    //@ts-ignore
                    groupId: ((_d = foundLabel.group) === null || _d === void 0 ? void 0 : _d.id) || foundLabel.groupId,
                    name: newName,
                    priority: foundLabel.priority,
                });
            }
        };
        this.createLabel = (value, groupId) => __awaiter(this, void 0, void 0, function* () {
            const newGroups = [...this.state.groups];
            const foundGroup = newGroups.find(group => group.id === groupId);
            if (foundGroup) {
                const newValue = {
                    name: value,
                    groupId: groupId,
                    priority: foundGroup.labels.length + 1,
                };
                this.setState({ loading: true });
                const { data } = yield this.labelsServiceApi.createLabel(newValue, foundGroup);
                foundGroup.labels.push(data);
                this.setState({
                    groups: newGroups,
                    labels: makeLabelsFromGroups(newGroups),
                    loading: false,
                });
                return data;
            }
        });
        this.createSessionLabel = (value) => __awaiter(this, void 0, void 0, function* () {
            const newSessionLabel = {
                name: value,
                priority: this.state.sessionLabels.length + 1,
            };
            const { data } = yield this.labelsServiceApi.createSessionLabel(newSessionLabel);
            this.setState({ sessionLabels: [...this.state.sessionLabels, data] });
            return data;
        });
        this.deleteSessionLabel = (id) => {
            this.setState({
                sessionLabels: this.state.sessionLabels.filter(sessionLabel => sessionLabel.id !== id),
            });
            this.labelsServiceApi.deleteSessionLabel(id);
        };
        this.createLabelGroup = (name, labels) => {
            const group = {
                name: name,
                priority: this.state.groups.length + 1,
            };
            //@ts-ignore
            this.load(this.labelsServiceApi.createLabelGroup(group, labels)).then(({ data }) => {
                const newGroups = [...this.state.groups];
                if (this.props.useReporter) {
                    return this.getLabels();
                }
                else {
                    newGroups.push(data);
                }
                this.setState({
                    groups: newGroups,
                });
            });
        };
        this.updateSessionsPriority = (newOrderedSessions) => {
            this.setState({
                sessionLabels: newOrderedSessions,
            });
            const labelPriorityChange = newOrderedSessions.map((session, index) => ({
                id: session.id,
                newPriority: index + 1,
            }));
            this.labelsServiceApi.updateSessionsPriority(labelPriorityChange);
        };
        this.updateSessionLabel = (sessionLabelId, newName) => {
            const newSessionsLabels = [...this.state.sessionLabels];
            const foundIndex = newSessionsLabels.findIndex(label => label.id === sessionLabelId);
            if (foundIndex > -1) {
                const updateSessionLabel = Object.assign(Object.assign({}, newSessionsLabels[foundIndex]), { name: newName });
                newSessionsLabels.splice(foundIndex, 1, updateSessionLabel);
                this.setState({
                    sessionLabels: newSessionsLabels,
                });
                this.labelsServiceApi.updateSessionLabel(sessionLabelId, updateSessionLabel);
            }
        };
        this.updateLabelGroup = (id, name) => {
            const newGroups = [...this.state.groups];
            const foundGroupIndex = newGroups.findIndex(group => group.id === id);
            if (foundGroupIndex > -1) {
                let newValue = Object.assign({}, newGroups[foundGroupIndex]);
                newValue.name = name;
                newGroups.splice(foundGroupIndex, 1, newValue);
                this.setState({
                    groups: newGroups,
                    labels: makeLabelsFromGroups(newGroups),
                });
                this.labelsServiceApi.updateLabelGroup(id, newValue);
            }
        };
        this.updateIssuesRequestedTypePriority = (type, newPriority) => {
            const newPriorityList = newPriority.map((item, index) => {
                return Object.assign(Object.assign({}, item), { priority: index + 1 });
            });
            this.labelsServiceApi.updateIssuesRequestedTypePriority(type, newPriorityList.map(item => ({ id: item.id, newPriority: item.priority })));
            //@ts-ignore
            this.setState({
                [TypeMapToState[type]]: newPriorityList,
            });
        };
        this.createIssuesRequestedType = (type, value) => {
            let mapRequestTypeToStateLength = {
                labels: this.state.issuesLabels.length + 1,
                priorities: this.state.issuesPriorities.length + 1,
                states: this.state.issuesStates.length + 1,
            };
            const issueReq = {
                active: true,
                name: value,
                priority: mapRequestTypeToStateLength[type],
            };
            this.load(this.labelsServiceApi.createIssuesRequestedType(type, issueReq)).then(({ data }) => {
                let mapRequestTypeToState = {
                    labels: [...this.state.issuesLabels],
                    priorities: [...this.state.issuesPriorities],
                    states: [...this.state.issuesStates],
                };
                mapRequestTypeToState[type].push(data);
                //@ts-ignore
                this.setState({
                    [TypeMapToState[type]]: mapRequestTypeToState[type],
                });
            });
        };
        this.updateIssueRequestedType = (type, id, value) => {
            let foundIndex = -1;
            let mapRequestTypeToState = {
                labels: [...this.state.issuesLabels],
                priorities: [...this.state.issuesPriorities],
                states: [...this.state.issuesStates],
            };
            foundIndex = mapRequestTypeToState[type].findIndex(state => state.id === id);
            if (foundIndex > -1) {
                const creationValue = Object.assign(Object.assign({}, mapRequestTypeToState[type][foundIndex]), { name: value });
                mapRequestTypeToState[type].splice(foundIndex, 1, creationValue);
                //@ts-ignore
                this.setState({
                    [TypeMapToState[type]]: mapRequestTypeToState[type],
                });
                this.labelsServiceApi.updateIssueRequestedType(type, id, creationValue);
            }
        };
        this.deleteIssueRequestedType = (type, id) => {
            let mapRequestTypeToState = {
                labels: [...this.state.issuesLabels],
                priorities: [...this.state.issuesPriorities],
                states: [...this.state.issuesStates],
            };
            mapRequestTypeToState[type] = mapRequestTypeToState[type].filter(label => label.id !== id);
            const stateName = TypeMapToState[type];
            const stateValue = mapRequestTypeToState[type];
            //@ts-ignore stupid typescript
            this.setState({ [stateName]: stateValue });
            this.labelsServiceApi.deleteIssueRequestedType(type, id);
        };
        this.setLabelToSessions = (_d) => __awaiter(this, [_d], void 0, function* ({ filters, labels, comparisonType, sessionIds, }) {
            yield this.labelsServiceApi.setLabelToSessions({ filters, labels, comparisonType, objectIds: sessionIds });
            return;
        });
        this.setLabelToMessages = (_e) => __awaiter(this, [_e], void 0, function* ({ filters, labels, comparisonType, sessionIds, }) {
            yield this.labelsServiceApi.setLabelToMessages({ filters, labels, comparisonType, objectIds: sessionIds });
            return;
        });
    }
    componentDidMount() {
        this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
    }
    componentDidUpdate(prevProps) {
        if (this.props.accountId !== prevProps.accountId) {
            this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
            this.setState(new LabelsContextProviderState());
        }
    }
    componentWillUnmount() {
        this.setState(new LabelsContextProviderState());
    }
    render() {
        var _d, _e;
        const { children } = this.props;
        this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
        return (_jsxs(LabelsContext.Provider, { value: {
                t: this.translate,
                groups: this.state.groups,
                sessionLabels: this.state.sessionLabels,
                issuesLabels: this.state.issuesLabels,
                issuesStates: this.state.issuesStates,
                issuesPriorities: this.state.issuesPriorities,
                labelColors: this.state.labelColors,
                sessionLabelColors: this.state.sessionLabelColors,
                loaded: this.state.loaded,
                loading: this.state.loading,
                getLabels: this.getLabels,
                deleteLabelGroup: this.deleteLabelGroup,
                deleteLabel: this.deleteLabel,
                resortGroups: this.resortGroups,
                resortLabelsInGroup: this.resortLabelsInGroup,
                updateLabel: this.updateLabel,
                createLabel: this.createLabel,
                setLabelToSessions: this.setLabelToSessions,
                setLabelToMessages: this.setLabelToMessages,
                updateSessionLabel: this.updateSessionLabel,
                createSessionLabel: this.createSessionLabel,
                deleteSessionLabel: this.deleteSessionLabel,
                createLabelGroup: this.createLabelGroup,
                updateLabelGroup: this.updateLabelGroup,
                updateSessionsPriority: this.updateSessionsPriority,
                updateIssuesRequestedTypePriority: this.updateIssuesRequestedTypePriority,
                createIssuesRequestedType: this.createIssuesRequestedType,
                updateIssueRequestedType: this.updateIssueRequestedType,
                deleteIssueRequestedType: this.deleteIssueRequestedType,
                useReporter: Boolean(this.props.useReporter),
                hasTagCreatePermission: !!((_e = (_d = this.props.config) === null || _d === void 0 ? void 0 : _d.tags) === null || _e === void 0 ? void 0 : _e.hasWriteRight),
            }, children: [this.state.loading && _jsx(Spinner, { size: '4x' }), children] }));
    }
}
export const useLabelsContext = () => useContext(LabelsContext);
