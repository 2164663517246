import React, { useMemo, useRef, useCallback } from 'react';
import { ComputePositionConfig, flip, offset, shift } from '@floating-ui/react-dom';
import { usePromisifyComponent } from '@just-ai/just-ui';

import { t } from 'localization';
import { useAppSelector, useAppDispatch } from 'storeHooks';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';

import deleteTheme from 'reducers/JGraph.reducer/AsyncActions/deleteTheme';
import { closeContextMenu } from 'reducers/JGraph.reducer';
import { JGraphTheme } from 'reducers/JGraph.reducer/types';
import renameTheme from 'reducers/JGraph.reducer/AsyncActions/renameTheme';

import DeleteThemeModal from './modals/DeleteThemeModal';
import RenameThemeModal from './modals/RenameThemeModal';

import styles from './styles.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  middleware: [
    offset({
      mainAxis: -2,
      alignmentAxis: -2,
    }),
    flip(),
    shift(),
  ],
};

const ThemesContextMenu = () => {
  const dispatch = useAppDispatch();
  const { contextMenu, themes } = useAppSelector(state => ({
    contextMenu: state.JGraphReducer.contextMenu,
    themes: state.JGraphReducer.graph.themes,
  }));
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useFloaterPosition({
    enable: contextMenu.open,
    floaterElement: wrapperRef,
    target: useMemo(
      () => ({
        width: 0,
        height: 0,
        x: contextMenu.pointerPosition.x,
        y: contextMenu.pointerPosition.y,
      }),
      [contextMenu.pointerPosition]
    ),
    options: floaterOptions,
  });

  const [deleteThemeModalNode, showDeleteThemeModal] = usePromisifyComponent(
    (resolve, reject, opened) => <DeleteThemeModal isOpen={opened} onCancel={reject} onSubmit={resolve} />,
    undefined,
    []
  );
  const deleteThemeInner = useCallback(() => {
    const currentTheme = themes.find(el => el.value === contextMenu.themePath);
    if (!currentTheme) return;
    dispatch(closeContextMenu());
    showDeleteThemeModal().then(() => dispatch(deleteTheme({ theme: currentTheme })));
  }, [contextMenu.themePath, dispatch, showDeleteThemeModal, themes]);

  const [renameThemeModalNode, showRenameThemeModal] = usePromisifyComponent<string, JGraphTheme>(
    (resolve, reject, opened, theme) =>
      theme && <RenameThemeModal isOpen={opened} theme={theme} onClose={reject} onRename={resolve} />,
    undefined,
    []
  );
  const renameThemeInner = useCallback(() => {
    const currentTheme = themes.find(el => el.value === contextMenu.themePath);
    if (!currentTheme) return;
    dispatch(closeContextMenu());
    showRenameThemeModal(currentTheme).then(newName => dispatch(renameTheme({ theme: currentTheme, newName })));
  }, [contextMenu.themePath, dispatch, showRenameThemeModal, themes]);

  return (
    <>
      {contextMenu.open && (
        <div className={styles.ThemesContextMenu} ref={wrapperRef}>
          <div
            className={styles.ThemesContextMenu__item}
            onClick={renameThemeInner}
            data-test-id='JGraph:ContextMenu:RenameTheme'
          >
            {t('JGraph:ContextMenu:RenameTheme')}
          </div>
          <div
            className={styles.ThemesContextMenu__item}
            onClick={deleteThemeInner}
            data-test-id='JGraph:ContextMenu:DeleteTheme'
          >
            {t('JGraph:ContextMenu:DeleteTheme')}
          </div>
        </div>
      )}
      {deleteThemeModalNode}
      {renameThemeModalNode}
    </>
  );
};

export default React.memo(ThemesContextMenu);
