import { DISMISS_ALERT, DISMISS_ALL_ALERTS, ALERT_RECEIVING } from '../../constants/globalAlert.actions';

export function dismissMessage(time) {
  return {
    type: DISMISS_ALERT,
    payload: time,
  };
}
export function addMessage(message) {
  return {
    type: ALERT_RECEIVING,
    payload: message,
  };
}
export function dismissAllAlerts() {
  return {
    type: DISMISS_ALL_ALERTS,
  };
}
