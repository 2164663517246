// tslint:disable
// @ts-nocheck
/**
 * Integrationadapter REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AmoCrmContractRequestData
 */
export interface AmoCrmContractRequestData {
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  accountEmail: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  inn: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmContractRequestData
   */
  address?: string;
}
/**
 *
 * @export
 * @interface AmoCrmCreatePartnerRequestData
 */
export interface AmoCrmCreatePartnerRequestData {
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  contactPhone: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  inn: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  bic?: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  settlementAccount?: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmCreatePartnerRequestData
   */
  email: string;
}
/**
 *
 * @export
 * @interface AmoCrmDevelopmentRequestData
 */
export interface AmoCrmDevelopmentRequestData {
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  accountEmail: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmDevelopmentRequestData
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AmoCrmDevelopmentRequestData
   */
  files?: Array<string>;
}
/**
 *
 * @export
 * @interface AmoCrmTokenData
 */
export interface AmoCrmTokenData {
  /**
   *
   * @type {string}
   * @memberof AmoCrmTokenData
   */
  tokenType: string;
  /**
   *
   * @type {number}
   * @memberof AmoCrmTokenData
   */
  expiresIn: number;
  /**
   *
   * @type {string}
   * @memberof AmoCrmTokenData
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof AmoCrmTokenData
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface BitbucketProject
 */
export interface BitbucketProject {
  /**
   *
   * @type {string}
   * @memberof BitbucketProject
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BitbucketProject
   */
  name: string;
}
/**
 *
 * @export
 * @interface BitbucketWorkspace
 */
export interface BitbucketWorkspace {
  /**
   *
   * @type {string}
   * @memberof BitbucketWorkspace
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BitbucketWorkspace
   */
  name: string;
}
/**
 *
 * @export
 * @interface CustomRequestData
 */
export interface CustomRequestData {
  /**
   *
   * @type {string}
   * @memberof CustomRequestData
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof CustomRequestData
   */
  method: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomRequestData
   */
  headers?: { [key: string]: string };
  /**
   *
   * @type {object}
   * @memberof CustomRequestData
   */
  body?: object;
}
/**
 *
 * @export
 * @interface CustomRequestResponseData
 */
export interface CustomRequestResponseData {
  /**
   *
   * @type {number}
   * @memberof CustomRequestResponseData
   */
  statusCode: number;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomRequestResponseData
   */
  responseHeaders: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof CustomRequestResponseData
   */
  responseBody?: string;
}
/**
 *
 * @export
 * @interface E2ECheckResult
 */
export interface E2ECheckResult {
  /**
   *
   * @type {string}
   * @memberof E2ECheckResult
   */
  totalStatus?: string;
  /**
   *
   * @type {number}
   * @memberof E2ECheckResult
   */
  totalTime?: number;
  /**
   *
   * @type {Array<E2EComponent>}
   * @memberof E2ECheckResult
   */
  e2EComponents?: Array<E2EComponent>;
}
/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime?: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime?: number;
}
/**
 *
 * @export
 * @interface GitRepository
 */
export interface GitRepository {
  /**
   *
   * @type {string}
   * @memberof GitRepository
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GitRepository
   */
  url: string;
}
/**
 *
 * @export
 * @interface GoogleTokenData
 */
export interface GoogleTokenData {
  /**
   *
   * @type {string}
   * @memberof GoogleTokenData
   */
  accessToken: string;
  /**
   *
   * @type {number}
   * @memberof GoogleTokenData
   */
  expiresIn: number;
  /**
   *
   * @type {string}
   * @memberof GoogleTokenData
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof GoogleTokenData
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof GoogleTokenData
   */
  tokenType: string;
}
/**
 *
 * @export
 * @interface IntegrationDto
 */
export interface IntegrationDto {
  /**
   *
   * @type {IntegrationType}
   * @memberof IntegrationDto
   */
  integrationType: IntegrationType;
  /**
   *
   * @type {string}
   * @memberof IntegrationDto
   */
  integrationId: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationDto
   */
  integrationName: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationDto
   */
  projectShortName?: string;
  /**
   *
   * @type {number}
   * @memberof IntegrationDto
   */
  accountId: number;
  /**
   *
   * @type {TokenStatus}
   * @memberof IntegrationDto
   */
  status?: TokenStatus;
  /**
   *
   * @type {object}
   * @memberof IntegrationDto
   */
  specificData?: object;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum IntegrationType {
  Google = 'google',
  Stub = 'stub',
  Amocrm = 'amocrm',
  Github = 'github',
  Bitbucket = 'bitbucket',
}

/**
 *
 * @export
 * @interface SecretBatchResponse
 */
export interface SecretBatchResponse {
  /**
   *
   * @type {number}
   * @memberof SecretBatchResponse
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof SecretBatchResponse
   */
  projectId: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SecretBatchResponse
   */
  secrets: { [key: string]: string };
}
/**
 *
 * @export
 * @interface SecretFullResponse
 */
export interface SecretFullResponse {
  /**
   *
   * @type {number}
   * @memberof SecretFullResponse
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof SecretFullResponse
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof SecretFullResponse
   */
  secretName: string;
  /**
   *
   * @type {string}
   * @memberof SecretFullResponse
   */
  secretValue: string;
  /**
   *
   * @type {Date}
   * @memberof SecretFullResponse
   */
  creationDate: Date;
  /**
   *
   * @type {Date}
   * @memberof SecretFullResponse
   */
  updateDate: Date;
  /**
   *
   * @type {string}
   * @memberof SecretFullResponse
   */
  lastUserId: string;
}
/**
 *
 * @export
 * @interface SecretRequest
 */
export interface SecretRequest {
  /**
   *
   * @type {string}
   * @memberof SecretRequest
   */
  secretValue: string;
}
/**
 *
 * @export
 * @interface SecretShortResponse
 */
export interface SecretShortResponse {
  /**
   *
   * @type {number}
   * @memberof SecretShortResponse
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof SecretShortResponse
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof SecretShortResponse
   */
  secretName: string;
  /**
   *
   * @type {Date}
   * @memberof SecretShortResponse
   */
  creationDate: Date;
  /**
   *
   * @type {Date}
   * @memberof SecretShortResponse
   */
  updateDate: Date;
  /**
   *
   * @type {string}
   * @memberof SecretShortResponse
   */
  lastUserId: string;
}
/**
 *
 * @export
 * @interface SheetDto
 */
export interface SheetDto {
  /**
   *
   * @type {number}
   * @memberof SheetDto
   */
  sheetId: number;
  /**
   *
   * @type {string}
   * @memberof SheetDto
   */
  sheetName: string;
}
/**
 *
 * @export
 * @interface SpreadsheetCellData
 */
export interface SpreadsheetCellData {
  /**
   *
   * @type {string}
   * @memberof SpreadsheetCellData
   */
  cell: string;
  /**
   *
   * @type {string}
   * @memberof SpreadsheetCellData
   */
  value: string;
}
/**
 *
 * @export
 * @interface SpreadsheetCellListData
 */
export interface SpreadsheetCellListData {
  /**
   *
   * @type {string}
   * @memberof SpreadsheetCellListData
   */
  cell: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SpreadsheetCellListData
   */
  values: Array<string>;
}
/**
 *
 * @export
 * @interface SpreadsheetFile
 */
export interface SpreadsheetFile {
  /**
   *
   * @type {string}
   * @memberof SpreadsheetFile
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SpreadsheetFile
   */
  name: string;
}
/**
 *
 * @export
 * @interface SpreadsheetWriteResultDto
 */
export interface SpreadsheetWriteResultDto {
  /**
   *
   * @type {number}
   * @memberof SpreadsheetWriteResultDto
   */
  updatedCells: number;
  /**
   *
   * @type {number}
   * @memberof SpreadsheetWriteResultDto
   */
  updatedColumns: number;
  /**
   *
   * @type {number}
   * @memberof SpreadsheetWriteResultDto
   */
  updatedRows: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SpreadsheetWriteResultDto
   */
  updatedRanges: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SpreadsheetWriteResultDto
   */
  updatedData?: Array<string>;
}
/**
 *
 * @export
 * @interface TokenData
 */
export interface TokenData {
  /**
   *
   * @type {string}
   * @memberof TokenData
   */
  token: string;
  /**
   *
   * @type {number}
   * @memberof TokenData
   */
  tokenTimeoutSeconds?: number;
  /**
   *
   * @type {Date}
   * @memberof TokenData
   */
  tokenValidUntil?: Date;
  /**
   *
   * @type {string}
   * @memberof TokenData
   */
  refreshToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TokenData
   */
  scopes?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof TokenData
   */
  refreshTokenValidUtil?: Date;
  /**
   *
   * @type {TokenStatus}
   * @memberof TokenData
   */
  status?: TokenStatus;
  /**
   *
   * @type {object}
   * @memberof TokenData
   */
  specificData?: object;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TokenStatus {
  OK = 'OK',
  INVALIDGRANT = 'INVALID_GRANT',
  UNKNOWNERROR = 'UNKNOWN_ERROR',
}

/**
 * AccountTokenApi - axios parameter creator
 * @export
 */
export const AccountTokenApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete account all integrations
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccountAllIntegrations(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteAccountAllIntegrations.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntegrationTokenData(accountId: number, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteIntegrationTokenData.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling deleteIntegrationTokenData.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProjectIntegrations(accountId: number, projectShortName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteProjectIntegrations.'
        );
      }
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling deleteProjectIntegrations.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations/by-project`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntegrationTokenData(accountId: number, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getIntegrationTokenData.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getIntegrationTokenData.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectIntegrations(accountId: number, projectShortName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectIntegrations.'
        );
      }
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling getProjectIntegrations.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations/by-project`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new integration data
     * @param {number} accountId Account identifier
     * @param {IntegrationType} integrationType Integration type, google just for example
     * @param {TokenData} tokenData
     * @param {string} [projectShortName] Project short name
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNewIntegrationForAccount(
      accountId: number,
      integrationType: IntegrationType,
      tokenData: TokenData,
      projectShortName?: string,
      integrationName?: string,
      integrationGroup?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling saveNewIntegrationForAccount.'
        );
      }
      // verify required parameter 'integrationType' is not null or undefined
      if (integrationType === null || integrationType === undefined) {
        throw new RequiredError(
          'integrationType',
          'Required parameter integrationType was null or undefined when calling saveNewIntegrationForAccount.'
        );
      }
      // verify required parameter 'tokenData' is not null or undefined
      if (tokenData === null || tokenData === undefined) {
        throw new RequiredError(
          'tokenData',
          'Required parameter tokenData was null or undefined when calling saveNewIntegrationForAccount.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationType !== undefined) {
        localVarQueryParameter['integrationType'] = integrationType;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (integrationName !== undefined) {
        localVarQueryParameter['integrationName'] = integrationName;
      }

      if (integrationGroup !== undefined) {
        localVarQueryParameter['integrationGroup'] = integrationGroup;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof tokenData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tokenData !== undefined ? tokenData : {})
        : tokenData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update integration token data
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {TokenData} tokenData
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIntegrationTokenData(
      accountId: number,
      integrationId: string,
      tokenData: TokenData,
      integrationName?: string,
      integrationGroup?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateIntegrationTokenData.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling updateIntegrationTokenData.'
        );
      }
      // verify required parameter 'tokenData' is not null or undefined
      if (tokenData === null || tokenData === undefined) {
        throw new RequiredError(
          'tokenData',
          'Required parameter tokenData was null or undefined when calling updateIntegrationTokenData.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (integrationName !== undefined) {
        localVarQueryParameter['integrationName'] = integrationName;
      }

      if (integrationGroup !== undefined) {
        localVarQueryParameter['integrationGroup'] = integrationGroup;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof tokenData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tokenData !== undefined ? tokenData : {})
        : tokenData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountTokenApi - functional programming interface
 * @export
 */
export const AccountTokenApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete account all integrations
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccountAllIntegrations(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteAccountAllIntegrations(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntegrationTokenData(
      accountId: number,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteIntegrationTokenData(
        accountId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProjectIntegrations(
      accountId: number,
      projectShortName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteProjectIntegrations(
        accountId,
        projectShortName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntegrationTokenData(
      accountId: number,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenData> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).getIntegrationTokenData(
        accountId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectIntegrations(
      accountId: number,
      projectShortName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationDto>> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).getProjectIntegrations(
        accountId,
        projectShortName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Add new integration data
     * @param {number} accountId Account identifier
     * @param {IntegrationType} integrationType Integration type, google just for example
     * @param {TokenData} tokenData
     * @param {string} [projectShortName] Project short name
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNewIntegrationForAccount(
      accountId: number,
      integrationType: IntegrationType,
      tokenData: TokenData,
      projectShortName?: string,
      integrationName?: string,
      integrationGroup?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).saveNewIntegrationForAccount(
        accountId,
        integrationType,
        tokenData,
        projectShortName,
        integrationName,
        integrationGroup,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update integration token data
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {TokenData} tokenData
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIntegrationTokenData(
      accountId: number,
      integrationId: string,
      tokenData: TokenData,
      integrationName?: string,
      integrationGroup?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenData> {
      const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).updateIntegrationTokenData(
        accountId,
        integrationId,
        tokenData,
        integrationName,
        integrationGroup,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountTokenApi - factory interface
 * @export
 */
export const AccountTokenApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Delete account all integrations
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccountAllIntegrations(accountId: number, options?: any): AxiosPromise<void> {
      return AccountTokenApiFp(configuration).deleteAccountAllIntegrations(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntegrationTokenData(accountId: number, integrationId: string, options?: any): AxiosPromise<void> {
      return AccountTokenApiFp(configuration).deleteIntegrationTokenData(
        accountId,
        integrationId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProjectIntegrations(accountId: number, projectShortName: string, options?: any): AxiosPromise<void> {
      return AccountTokenApiFp(configuration).deleteProjectIntegrations(
        accountId,
        projectShortName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntegrationTokenData(accountId: number, integrationId: string, options?: any): AxiosPromise<TokenData> {
      return AccountTokenApiFp(configuration).getIntegrationTokenData(
        accountId,
        integrationId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectIntegrations(
      accountId: number,
      projectShortName: string,
      options?: any
    ): AxiosPromise<Array<IntegrationDto>> {
      return AccountTokenApiFp(configuration).getProjectIntegrations(
        accountId,
        projectShortName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Add new integration data
     * @param {number} accountId Account identifier
     * @param {IntegrationType} integrationType Integration type, google just for example
     * @param {TokenData} tokenData
     * @param {string} [projectShortName] Project short name
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNewIntegrationForAccount(
      accountId: number,
      integrationType: IntegrationType,
      tokenData: TokenData,
      projectShortName?: string,
      integrationName?: string,
      integrationGroup?: string,
      options?: any
    ): AxiosPromise<string> {
      return AccountTokenApiFp(configuration).saveNewIntegrationForAccount(
        accountId,
        integrationType,
        tokenData,
        projectShortName,
        integrationName,
        integrationGroup,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update integration token data
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {TokenData} tokenData
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIntegrationTokenData(
      accountId: number,
      integrationId: string,
      tokenData: TokenData,
      integrationName?: string,
      integrationGroup?: string,
      options?: any
    ): AxiosPromise<TokenData> {
      return AccountTokenApiFp(configuration).updateIntegrationTokenData(
        accountId,
        integrationId,
        tokenData,
        integrationName,
        integrationGroup,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccountTokenApi - object-oriented interface
 * @export
 * @class AccountTokenApi
 * @extends {BaseAPI}
 */
export class AccountTokenApi extends BaseAPI {
  /**
   *
   * @summary Delete account all integrations
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public deleteAccountAllIntegrations(accountId: number, options?: any) {
    return AccountTokenApiFp(this.configuration).deleteAccountAllIntegrations(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete integration token
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public deleteIntegrationTokenData(accountId: number, integrationId: string, options?: any) {
    return AccountTokenApiFp(this.configuration).deleteIntegrationTokenData(
      accountId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete project integrations
   * @param {number} accountId Account identifier
   * @param {string} projectShortName Project short name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public deleteProjectIntegrations(accountId: number, projectShortName: string, options?: any) {
    return AccountTokenApiFp(this.configuration).deleteProjectIntegrations(
      accountId,
      projectShortName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get integration token
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public getIntegrationTokenData(accountId: number, integrationId: string, options?: any) {
    return AccountTokenApiFp(this.configuration).getIntegrationTokenData(
      accountId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project integrations
   * @param {number} accountId Account identifier
   * @param {string} projectShortName Project short name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public getProjectIntegrations(accountId: number, projectShortName: string, options?: any) {
    return AccountTokenApiFp(this.configuration).getProjectIntegrations(
      accountId,
      projectShortName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Add new integration data
   * @param {number} accountId Account identifier
   * @param {IntegrationType} integrationType Integration type, google just for example
   * @param {TokenData} tokenData
   * @param {string} [projectShortName] Project short name
   * @param {string} [integrationName] Integration name
   * @param {string} [integrationGroup] Integration group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public saveNewIntegrationForAccount(
    accountId: number,
    integrationType: IntegrationType,
    tokenData: TokenData,
    projectShortName?: string,
    integrationName?: string,
    integrationGroup?: string,
    options?: any
  ) {
    return AccountTokenApiFp(this.configuration).saveNewIntegrationForAccount(
      accountId,
      integrationType,
      tokenData,
      projectShortName,
      integrationName,
      integrationGroup,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update integration token data
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {TokenData} tokenData
   * @param {string} [integrationName] Integration name
   * @param {string} [integrationGroup] Integration group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountTokenApi
   */
  public updateIntegrationTokenData(
    accountId: number,
    integrationId: string,
    tokenData: TokenData,
    integrationName?: string,
    integrationGroup?: string,
    options?: any
  ) {
    return AccountTokenApiFp(this.configuration).updateIntegrationTokenData(
      accountId,
      integrationId,
      tokenData,
      integrationName,
      integrationGroup,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AmoCrmApi - axios parameter creator
 * @export
 */
export const AmoCrmApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Contract request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByContractRequest(
      integrationId: string,
      amoCrmContractRequestData: AmoCrmContractRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling addLeadByContractRequest.'
        );
      }
      // verify required parameter 'amoCrmContractRequestData' is not null or undefined
      if (amoCrmContractRequestData === null || amoCrmContractRequestData === undefined) {
        throw new RequiredError(
          'amoCrmContractRequestData',
          'Required parameter amoCrmContractRequestData was null or undefined when calling addLeadByContractRequest.'
        );
      }
      const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/contract-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof amoCrmContractRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(amoCrmContractRequestData !== undefined ? amoCrmContractRequestData : {})
        : amoCrmContractRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create partner request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByCreatePartnerRequest(
      integrationId: string,
      amoCrmCreatePartnerRequestData: AmoCrmCreatePartnerRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling addLeadByCreatePartnerRequest.'
        );
      }
      // verify required parameter 'amoCrmCreatePartnerRequestData' is not null or undefined
      if (amoCrmCreatePartnerRequestData === null || amoCrmCreatePartnerRequestData === undefined) {
        throw new RequiredError(
          'amoCrmCreatePartnerRequestData',
          'Required parameter amoCrmCreatePartnerRequestData was null or undefined when calling addLeadByCreatePartnerRequest.'
        );
      }
      const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/create-partner-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof amoCrmCreatePartnerRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(amoCrmCreatePartnerRequestData !== undefined ? amoCrmCreatePartnerRequestData : {})
        : amoCrmCreatePartnerRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Development request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByDevelopmentRequest(
      integrationId: string,
      amoCrmDevelopmentRequestData: AmoCrmDevelopmentRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling addLeadByDevelopmentRequest.'
        );
      }
      // verify required parameter 'amoCrmDevelopmentRequestData' is not null or undefined
      if (amoCrmDevelopmentRequestData === null || amoCrmDevelopmentRequestData === undefined) {
        throw new RequiredError(
          'amoCrmDevelopmentRequestData',
          'Required parameter amoCrmDevelopmentRequestData was null or undefined when calling addLeadByDevelopmentRequest.'
        );
      }
      const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/development-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof amoCrmDevelopmentRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(amoCrmDevelopmentRequestData !== undefined ? amoCrmDevelopmentRequestData : {})
        : amoCrmDevelopmentRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AmoCrmApi - functional programming interface
 * @export
 */
export const AmoCrmApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Contract request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByContractRequest(
      integrationId: string,
      amoCrmContractRequestData: AmoCrmContractRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByContractRequest(
        integrationId,
        amoCrmContractRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create partner request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByCreatePartnerRequest(
      integrationId: string,
      amoCrmCreatePartnerRequestData: AmoCrmCreatePartnerRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByCreatePartnerRequest(
        integrationId,
        amoCrmCreatePartnerRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Development request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByDevelopmentRequest(
      integrationId: string,
      amoCrmDevelopmentRequestData: AmoCrmDevelopmentRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByDevelopmentRequest(
        integrationId,
        amoCrmDevelopmentRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AmoCrmApi - factory interface
 * @export
 */
export const AmoCrmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Contract request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByContractRequest(
      integrationId: string,
      amoCrmContractRequestData: AmoCrmContractRequestData,
      options?: any
    ): AxiosPromise<void> {
      return AmoCrmApiFp(configuration).addLeadByContractRequest(
        integrationId,
        amoCrmContractRequestData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Create partner request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByCreatePartnerRequest(
      integrationId: string,
      amoCrmCreatePartnerRequestData: AmoCrmCreatePartnerRequestData,
      options?: any
    ): AxiosPromise<void> {
      return AmoCrmApiFp(configuration).addLeadByCreatePartnerRequest(
        integrationId,
        amoCrmCreatePartnerRequestData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Development request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLeadByDevelopmentRequest(
      integrationId: string,
      amoCrmDevelopmentRequestData: AmoCrmDevelopmentRequestData,
      options?: any
    ): AxiosPromise<void> {
      return AmoCrmApiFp(configuration).addLeadByDevelopmentRequest(
        integrationId,
        amoCrmDevelopmentRequestData,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AmoCrmApi - object-oriented interface
 * @export
 * @class AmoCrmApi
 * @extends {BaseAPI}
 */
export class AmoCrmApi extends BaseAPI {
  /**
   *
   * @summary Contract request
   * @param {string} integrationId Account integration identifier
   * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AmoCrmApi
   */
  public addLeadByContractRequest(
    integrationId: string,
    amoCrmContractRequestData: AmoCrmContractRequestData,
    options?: any
  ) {
    return AmoCrmApiFp(this.configuration).addLeadByContractRequest(
      integrationId,
      amoCrmContractRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create partner request
   * @param {string} integrationId Account integration identifier
   * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AmoCrmApi
   */
  public addLeadByCreatePartnerRequest(
    integrationId: string,
    amoCrmCreatePartnerRequestData: AmoCrmCreatePartnerRequestData,
    options?: any
  ) {
    return AmoCrmApiFp(this.configuration).addLeadByCreatePartnerRequest(
      integrationId,
      amoCrmCreatePartnerRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Development request
   * @param {string} integrationId Account integration identifier
   * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AmoCrmApi
   */
  public addLeadByDevelopmentRequest(
    integrationId: string,
    amoCrmDevelopmentRequestData: AmoCrmDevelopmentRequestData,
    options?: any
  ) {
    return AmoCrmApiFp(this.configuration).addLeadByDevelopmentRequest(
      integrationId,
      amoCrmDevelopmentRequestData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * CustomRequestApi - axios parameter creator
 * @export
 */
export const CustomRequestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Execute some custom request with token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {CustomRequestData} customRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeCustomRequest(
      accountId: number,
      integrationId: string,
      customRequestData: CustomRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling executeCustomRequest.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling executeCustomRequest.'
        );
      }
      // verify required parameter 'customRequestData' is not null or undefined
      if (customRequestData === null || customRequestData === undefined) {
        throw new RequiredError(
          'customRequestData',
          'Required parameter customRequestData was null or undefined when calling executeCustomRequest.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/custom-request`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof customRequestData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(customRequestData !== undefined ? customRequestData : {})
        : customRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomRequestApi - functional programming interface
 * @export
 */
export const CustomRequestApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Execute some custom request with token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {CustomRequestData} customRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeCustomRequest(
      accountId: number,
      integrationId: string,
      customRequestData: CustomRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequestResponseData> {
      const localVarAxiosArgs = CustomRequestApiAxiosParamCreator(configuration).executeCustomRequest(
        accountId,
        integrationId,
        customRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CustomRequestApi - factory interface
 * @export
 */
export const CustomRequestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Execute some custom request with token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {CustomRequestData} customRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeCustomRequest(
      accountId: number,
      integrationId: string,
      customRequestData: CustomRequestData,
      options?: any
    ): AxiosPromise<CustomRequestResponseData> {
      return CustomRequestApiFp(configuration).executeCustomRequest(
        accountId,
        integrationId,
        customRequestData,
        options
      )(axios, basePath);
    },
  };
};

/**
 * CustomRequestApi - object-oriented interface
 * @export
 * @class CustomRequestApi
 * @extends {BaseAPI}
 */
export class CustomRequestApi extends BaseAPI {
  /**
   *
   * @summary Execute some custom request with token
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {CustomRequestData} customRequestData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomRequestApi
   */
  public executeCustomRequest(
    accountId: number,
    integrationId: string,
    customRequestData: CustomRequestData,
    options?: any
  ) {
    return CustomRequestApiFp(this.configuration).executeCustomRequest(
      accountId,
      integrationId,
      customRequestData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.'
        );
      }
      const localVarPath = `/api/integrationadapter/e2e-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (zRequestId !== undefined && zRequestId !== null) {
        localVarHeaderParameter['Z-requestId'] = String(zRequestId);
      }

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(
      loggingEnabledE2ECheck: boolean,
      zRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ECheckResult> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(
        loggingEnabledE2ECheck,
        zRequestId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any): AxiosPromise<E2ECheckResult> {
      return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   * Method for E2ECheck.
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {string} [zRequestId] Request id header
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any) {
    return E2ECheckApiFp(this.configuration).e2ECheck(
      loggingEnabledE2ECheck,
      zRequestId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * GitApi - axios parameter creator
 * @export
 */
export const GitApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create git repository for integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryName Name to create repository
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {boolean} [isPrivate] Whether the repository is private. Default false.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepository(
      accountId: number,
      integrationId: string,
      repositoryName: string,
      workspaceId?: string,
      projectId?: string,
      isPrivate?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createRepository.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling createRepository.'
        );
      }
      // verify required parameter 'repositoryName' is not null or undefined
      if (repositoryName === null || repositoryName === undefined) {
        throw new RequiredError(
          'repositoryName',
          'Required parameter repositoryName was null or undefined when calling createRepository.'
        );
      }
      const localVarPath = `/api/integrationadapter/accounts/{accountId}/git/repository/create`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (repositoryName !== undefined) {
        localVarQueryParameter['repositoryName'] = repositoryName;
      }

      if (workspaceId !== undefined) {
        localVarQueryParameter['workspaceId'] = workspaceId;
      }

      if (projectId !== undefined) {
        localVarQueryParameter['projectId'] = projectId;
      }

      if (isPrivate !== undefined) {
        localVarQueryParameter['isPrivate'] = isPrivate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of projects for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} workspaceId Workspace id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketProjects(
      accountId: number,
      integrationId: string,
      workspaceId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getBitbucketProjects.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getBitbucketProjects.'
        );
      }
      // verify required parameter 'workspaceId' is not null or undefined
      if (workspaceId === null || workspaceId === undefined) {
        throw new RequiredError(
          'workspaceId',
          'Required parameter workspaceId was null or undefined when calling getBitbucketProjects.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/projects`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (workspaceId !== undefined) {
        localVarQueryParameter['workspaceId'] = workspaceId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of workspaces for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketWorkspaces(accountId: number, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getBitbucketWorkspaces.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getBitbucketWorkspaces.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/workspaces`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get branches for repository.
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryUri Repository URI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(accountId: number, integrationId: string, repositoryUri: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getBranches.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getBranches.'
        );
      }
      // verify required parameter 'repositoryUri' is not null or undefined
      if (repositoryUri === null || repositoryUri === undefined) {
        throw new RequiredError(
          'repositoryUri',
          'Required parameter repositoryUri was null or undefined when calling getBranches.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/repository/branches`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (repositoryUri !== undefined) {
        localVarQueryParameter['repositoryUri'] = repositoryUri;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of repositories for integratioId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepositories(
      accountId: number,
      integrationId: string,
      workspaceId?: string,
      projectId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getRepositories.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getRepositories.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/repositories`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (workspaceId !== undefined) {
        localVarQueryParameter['workspaceId'] = workspaceId;
      }

      if (projectId !== undefined) {
        localVarQueryParameter['projectId'] = projectId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access token by integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCredentialsByIntegrationId(accountId: number, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getUserCredentialsByIntegrationId.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getUserCredentialsByIntegrationId.'
        );
      }
      const localVarPath = `/api/integrationadapter/accounts/{accountId}/git/user-credentials`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GitApi - functional programming interface
 * @export
 */
export const GitApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create git repository for integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryName Name to create repository
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {boolean} [isPrivate] Whether the repository is private. Default false.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepository(
      accountId: number,
      integrationId: string,
      repositoryName: string,
      workspaceId?: string,
      projectId?: string,
      isPrivate?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).createRepository(
        accountId,
        integrationId,
        repositoryName,
        workspaceId,
        projectId,
        isPrivate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of projects for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} workspaceId Workspace id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketProjects(
      accountId: number,
      integrationId: string,
      workspaceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BitbucketProject>> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getBitbucketProjects(
        accountId,
        integrationId,
        workspaceId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of workspaces for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketWorkspaces(
      accountId: number,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BitbucketWorkspace>> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getBitbucketWorkspaces(
        accountId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get branches for repository.
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryUri Repository URI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(
      accountId: number,
      integrationId: string,
      repositoryUri: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getBranches(
        accountId,
        integrationId,
        repositoryUri,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of repositories for integratioId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepositories(
      accountId: number,
      integrationId: string,
      workspaceId?: string,
      projectId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GitRepository>> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getRepositories(
        accountId,
        integrationId,
        workspaceId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get access token by integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCredentialsByIntegrationId(
      accountId: number,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getUserCredentialsByIntegrationId(
        accountId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GitApi - factory interface
 * @export
 */
export const GitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create git repository for integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryName Name to create repository
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {boolean} [isPrivate] Whether the repository is private. Default false.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepository(
      accountId: number,
      integrationId: string,
      repositoryName: string,
      workspaceId?: string,
      projectId?: string,
      isPrivate?: boolean,
      options?: any
    ): AxiosPromise<string> {
      return GitApiFp(configuration).createRepository(
        accountId,
        integrationId,
        repositoryName,
        workspaceId,
        projectId,
        isPrivate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get list of projects for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} workspaceId Workspace id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketProjects(
      accountId: number,
      integrationId: string,
      workspaceId: string,
      options?: any
    ): AxiosPromise<Array<BitbucketProject>> {
      return GitApiFp(configuration).getBitbucketProjects(
        accountId,
        integrationId,
        workspaceId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get list of workspaces for integrationId (Available only for Bitbucket)
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBitbucketWorkspaces(
      accountId: number,
      integrationId: string,
      options?: any
    ): AxiosPromise<Array<BitbucketWorkspace>> {
      return GitApiFp(configuration).getBitbucketWorkspaces(accountId, integrationId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get branches for repository.
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryUri Repository URI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(
      accountId: number,
      integrationId: string,
      repositoryUri: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return GitApiFp(configuration).getBranches(accountId, integrationId, repositoryUri, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of repositories for integratioId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} [workspaceId] Workspace id
     * @param {string} [projectId] Project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepositories(
      accountId: number,
      integrationId: string,
      workspaceId?: string,
      projectId?: string,
      options?: any
    ): AxiosPromise<Array<GitRepository>> {
      return GitApiFp(configuration).getRepositories(
        accountId,
        integrationId,
        workspaceId,
        projectId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get access token by integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCredentialsByIntegrationId(accountId: number, integrationId: string, options?: any): AxiosPromise<string> {
      return GitApiFp(configuration).getUserCredentialsByIntegrationId(
        accountId,
        integrationId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GitApi - object-oriented interface
 * @export
 * @class GitApi
 * @extends {BaseAPI}
 */
export class GitApi extends BaseAPI {
  /**
   *
   * @summary Create git repository for integrationId
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} repositoryName Name to create repository
   * @param {string} [workspaceId] Workspace id
   * @param {string} [projectId] Project id
   * @param {boolean} [isPrivate] Whether the repository is private. Default false.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public createRepository(
    accountId: number,
    integrationId: string,
    repositoryName: string,
    workspaceId?: string,
    projectId?: string,
    isPrivate?: boolean,
    options?: any
  ) {
    return GitApiFp(this.configuration).createRepository(
      accountId,
      integrationId,
      repositoryName,
      workspaceId,
      projectId,
      isPrivate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of projects for integrationId (Available only for Bitbucket)
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} workspaceId Workspace id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public getBitbucketProjects(accountId: number, integrationId: string, workspaceId: string, options?: any) {
    return GitApiFp(this.configuration).getBitbucketProjects(
      accountId,
      integrationId,
      workspaceId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of workspaces for integrationId (Available only for Bitbucket)
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public getBitbucketWorkspaces(accountId: number, integrationId: string, options?: any) {
    return GitApiFp(this.configuration).getBitbucketWorkspaces(
      accountId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get branches for repository.
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} repositoryUri Repository URI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public getBranches(accountId: number, integrationId: string, repositoryUri: string, options?: any) {
    return GitApiFp(this.configuration).getBranches(
      accountId,
      integrationId,
      repositoryUri,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of repositories for integratioId
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} [workspaceId] Workspace id
   * @param {string} [projectId] Project id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public getRepositories(
    accountId: number,
    integrationId: string,
    workspaceId?: string,
    projectId?: string,
    options?: any
  ) {
    return GitApiFp(this.configuration).getRepositories(
      accountId,
      integrationId,
      workspaceId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get access token by integrationId
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GitApi
   */
  public getUserCredentialsByIntegrationId(accountId: number, integrationId: string, options?: any) {
    return GitApiFp(this.configuration).getUserCredentialsByIntegrationId(
      accountId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * GoogleSheetsApi - axios parameter creator
 * @export
 */
export const GoogleSheetsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear cell data from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearCellData(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling clearCellData.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling clearCellData.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling clearCellData.'
        );
      }
      // verify required parameter 'sheetName' is not null or undefined
      if (sheetName === null || sheetName === undefined) {
        throw new RequiredError(
          'sheetName',
          'Required parameter sheetName was null or undefined when calling clearCellData.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling clearCellData.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (sheetName !== undefined) {
        localVarQueryParameter['sheetName'] = sheetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetName New spreasheet name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewSpreadsheet(
      accountId: number,
      integrationId: string,
      spreadsheetName: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createNewSpreadsheet.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling createNewSpreadsheet.'
        );
      }
      // verify required parameter 'spreadsheetName' is not null or undefined
      if (spreadsheetName === null || spreadsheetName === undefined) {
        throw new RequiredError(
          'spreadsheetName',
          'Required parameter spreadsheetName was null or undefined when calling createNewSpreadsheet.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (spreadsheetName !== undefined) {
        localVarQueryParameter['spreadsheetName'] = spreadsheetName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete row or column from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRowOrColumn(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteRowOrColumn.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling deleteRowOrColumn.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling deleteRowOrColumn.'
        );
      }
      // verify required parameter 'sheetName' is not null or undefined
      if (sheetName === null || sheetName === undefined) {
        throw new RequiredError(
          'sheetName',
          'Required parameter sheetName was null or undefined when calling deleteRowOrColumn.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteRowOrColumn.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/line`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (sheetName !== undefined) {
        localVarQueryParameter['sheetName'] = sheetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSheets(accountId: number, spreadsheetId: string, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getListOfSheets.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling getListOfSheets.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getListOfSheets.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSpreadsheets(accountId: number, integrationId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getListOfSpreadsheets.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling getListOfSpreadsheets.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Read data from cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDataFromCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling readDataFromCells.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling readDataFromCells.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling readDataFromCells.'
        );
      }
      // verify required parameter 'sheetName' is not null or undefined
      if (sheetName === null || sheetName === undefined) {
        throw new RequiredError(
          'sheetName',
          'Required parameter sheetName was null or undefined when calling readDataFromCells.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling readDataFromCells.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells/value`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (sheetName !== undefined) {
        localVarQueryParameter['sheetName'] = sheetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Write data to cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      spreadsheetCellListData: Array<SpreadsheetCellListData>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling writeDataToCells.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling writeDataToCells.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling writeDataToCells.'
        );
      }
      // verify required parameter 'sheetName' is not null or undefined
      if (sheetName === null || sheetName === undefined) {
        throw new RequiredError(
          'sheetName',
          'Required parameter sheetName was null or undefined when calling writeDataToCells.'
        );
      }
      // verify required parameter 'spreadsheetCellListData' is not null or undefined
      if (spreadsheetCellListData === null || spreadsheetCellListData === undefined) {
        throw new RequiredError(
          'spreadsheetCellListData',
          'Required parameter spreadsheetCellListData was null or undefined when calling writeDataToCells.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (sheetName !== undefined) {
        localVarQueryParameter['sheetName'] = sheetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof spreadsheetCellListData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(spreadsheetCellListData !== undefined ? spreadsheetCellListData : {})
        : spreadsheetCellListData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Write data to line
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody List of strings to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToLine(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling writeDataToLine.'
        );
      }
      // verify required parameter 'integrationId' is not null or undefined
      if (integrationId === null || integrationId === undefined) {
        throw new RequiredError(
          'integrationId',
          'Required parameter integrationId was null or undefined when calling writeDataToLine.'
        );
      }
      // verify required parameter 'spreadsheetId' is not null or undefined
      if (spreadsheetId === null || spreadsheetId === undefined) {
        throw new RequiredError(
          'spreadsheetId',
          'Required parameter spreadsheetId was null or undefined when calling writeDataToLine.'
        );
      }
      // verify required parameter 'sheetName' is not null or undefined
      if (sheetName === null || sheetName === undefined) {
        throw new RequiredError(
          'sheetName',
          'Required parameter sheetName was null or undefined when calling writeDataToLine.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling writeDataToLine.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/line`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'spreadsheetId'}}`, encodeURIComponent(String(spreadsheetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (integrationId !== undefined) {
        localVarQueryParameter['integrationId'] = integrationId;
      }

      if (sheetName !== undefined) {
        localVarQueryParameter['sheetName'] = sheetName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GoogleSheetsApi - functional programming interface
 * @export
 */
export const GoogleSheetsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Clear cell data from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearCellData(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).clearCellData(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetName New spreasheet name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewSpreadsheet(
      accountId: number,
      integrationId: string,
      spreadsheetName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpreadsheetFile> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).createNewSpreadsheet(
        accountId,
        integrationId,
        spreadsheetName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete row or column from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRowOrColumn(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).deleteRowOrColumn(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSheets(
      accountId: number,
      spreadsheetId: string,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SheetDto>> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).getListOfSheets(
        accountId,
        spreadsheetId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSpreadsheets(
      accountId: number,
      integrationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpreadsheetFile>> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).getListOfSpreadsheets(
        accountId,
        integrationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Read data from cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDataFromCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpreadsheetCellData>> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).readDataFromCells(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Write data to cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      spreadsheetCellListData: Array<SpreadsheetCellListData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpreadsheetWriteResultDto> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).writeDataToCells(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        spreadsheetCellListData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Write data to line
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody List of strings to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToLine(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpreadsheetWriteResultDto> {
      const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).writeDataToLine(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GoogleSheetsApi - factory interface
 * @export
 */
export const GoogleSheetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Clear cell data from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearCellData(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return GoogleSheetsApiFp(configuration).clearCellData(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Create a new spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetName New spreasheet name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewSpreadsheet(
      accountId: number,
      integrationId: string,
      spreadsheetName: string,
      options?: any
    ): AxiosPromise<SpreadsheetFile> {
      return GoogleSheetsApiFp(configuration).createNewSpreadsheet(
        accountId,
        integrationId,
        spreadsheetName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete row or column from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRowOrColumn(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return GoogleSheetsApiFp(configuration).deleteRowOrColumn(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSheets(
      accountId: number,
      spreadsheetId: string,
      integrationId: string,
      options?: any
    ): AxiosPromise<Array<SheetDto>> {
      return GoogleSheetsApiFp(configuration).getListOfSheets(
        accountId,
        spreadsheetId,
        integrationId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfSpreadsheets(
      accountId: number,
      integrationId: string,
      options?: any
    ): AxiosPromise<Array<SpreadsheetFile>> {
      return GoogleSheetsApiFp(configuration).getListOfSpreadsheets(accountId, integrationId, options)(axios, basePath);
    },
    /**
     *
     * @summary Read data from cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDataFromCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<Array<SpreadsheetCellData>> {
      return GoogleSheetsApiFp(configuration).readDataFromCells(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Write data to cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToCells(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      spreadsheetCellListData: Array<SpreadsheetCellListData>,
      options?: any
    ): AxiosPromise<SpreadsheetWriteResultDto> {
      return GoogleSheetsApiFp(configuration).writeDataToCells(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        spreadsheetCellListData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Write data to line
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody List of strings to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeDataToLine(
      accountId: number,
      integrationId: string,
      spreadsheetId: string,
      sheetName: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<SpreadsheetWriteResultDto> {
      return GoogleSheetsApiFp(configuration).writeDataToLine(
        accountId,
        integrationId,
        spreadsheetId,
        sheetName,
        requestBody,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GoogleSheetsApi - object-oriented interface
 * @export
 * @class GoogleSheetsApi
 * @extends {BaseAPI}
 */
export class GoogleSheetsApi extends BaseAPI {
  /**
   *
   * @summary Clear cell data from spreadsheet
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} sheetName Google spreadsheet sheet name
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public clearCellData(
    accountId: number,
    integrationId: string,
    spreadsheetId: string,
    sheetName: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return GoogleSheetsApiFp(this.configuration).clearCellData(
      accountId,
      integrationId,
      spreadsheetId,
      sheetName,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new spreadsheet
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetName New spreasheet name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public createNewSpreadsheet(accountId: number, integrationId: string, spreadsheetName: string, options?: any) {
    return GoogleSheetsApiFp(this.configuration).createNewSpreadsheet(
      accountId,
      integrationId,
      spreadsheetName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete row or column from spreadsheet
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} sheetName Google spreadsheet sheet name
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public deleteRowOrColumn(
    accountId: number,
    integrationId: string,
    spreadsheetId: string,
    sheetName: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return GoogleSheetsApiFp(this.configuration).deleteRowOrColumn(
      accountId,
      integrationId,
      spreadsheetId,
      sheetName,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of sheets of spreadsheets
   * @param {number} accountId Account identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public getListOfSheets(accountId: number, spreadsheetId: string, integrationId: string, options?: any) {
    return GoogleSheetsApiFp(this.configuration).getListOfSheets(
      accountId,
      spreadsheetId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of sheets of spreadsheets
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public getListOfSpreadsheets(accountId: number, integrationId: string, options?: any) {
    return GoogleSheetsApiFp(this.configuration).getListOfSpreadsheets(
      accountId,
      integrationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Read data from cells
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} sheetName Google spreadsheet sheet name
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public readDataFromCells(
    accountId: number,
    integrationId: string,
    spreadsheetId: string,
    sheetName: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return GoogleSheetsApiFp(this.configuration).readDataFromCells(
      accountId,
      integrationId,
      spreadsheetId,
      sheetName,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Write data to cells
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} sheetName Google spreadsheet sheet name
   * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public writeDataToCells(
    accountId: number,
    integrationId: string,
    spreadsheetId: string,
    sheetName: string,
    spreadsheetCellListData: Array<SpreadsheetCellListData>,
    options?: any
  ) {
    return GoogleSheetsApiFp(this.configuration).writeDataToCells(
      accountId,
      integrationId,
      spreadsheetId,
      sheetName,
      spreadsheetCellListData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Write data to line
   * @param {number} accountId Account identifier
   * @param {string} integrationId Account integration identifier
   * @param {string} spreadsheetId Google spreadsheet identifier
   * @param {string} sheetName Google spreadsheet sheet name
   * @param {Array<string>} requestBody List of strings to write
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleSheetsApi
   */
  public writeDataToLine(
    accountId: number,
    integrationId: string,
    spreadsheetId: string,
    sheetName: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return GoogleSheetsApiFp(this.configuration).writeDataToLine(
      accountId,
      integrationId,
      spreadsheetId,
      sheetName,
      requestBody,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OAuthAuthenticationApi - axios parameter creator
 * @export
 */
export const OAuthAuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Handle amoCrm oauth code
     * @param {string} code AmoCrm oauth code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleAmoCrmOAuthCode(code: string, options: any = {}): RequestArgs {
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError(
          'code',
          'Required parameter code was null or undefined when calling handleAmoCrmOAuthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/oauth-authentication/amo-crm/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Handle bitbucket oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleBitbucketOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'state' is not null or undefined
      if (state === null || state === undefined) {
        throw new RequiredError(
          'state',
          'Required parameter state was null or undefined when calling handleBitbucketOAuthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/0/oauth-authentication/bitbucket/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (error !== undefined) {
        localVarQueryParameter['error'] = error;
      }

      if (errorDescription !== undefined) {
        localVarQueryParameter['error_description'] = errorDescription;
      }

      if (errorUri !== undefined) {
        localVarQueryParameter['error_uri'] = errorUri;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Handle github oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGitHubOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'state' is not null or undefined
      if (state === null || state === undefined) {
        throw new RequiredError(
          'state',
          'Required parameter state was null or undefined when calling handleGitHubOAuthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/0/oauth-authentication/github/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (error !== undefined) {
        localVarQueryParameter['error'] = error;
      }

      if (errorDescription !== undefined) {
        localVarQueryParameter['error_description'] = errorDescription;
      }

      if (errorUri !== undefined) {
        localVarQueryParameter['error_uri'] = errorUri;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Handle google oauth code
     * @param {string} code Google oauth code
     * @param {string} state Google oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGoogleOAuthCode(code: string, state: string, options: any = {}): RequestArgs {
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError(
          'code',
          'Required parameter code was null or undefined when calling handleGoogleOAuthCode.'
        );
      }
      // verify required parameter 'state' is not null or undefined
      if (state === null || state === undefined) {
        throw new RequiredError(
          'state',
          'Required parameter state was null or undefined when calling handleGoogleOAuthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/0/oauth-authentication/google/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request bitbucket oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestBitbucketOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling requestBitbucketOauthCode.'
        );
      }
      // verify required parameter 'redirectUrl' is not null or undefined
      if (redirectUrl === null || redirectUrl === undefined) {
        throw new RequiredError(
          'redirectUrl',
          'Required parameter redirectUrl was null or undefined when calling requestBitbucketOauthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/oauth-authentication/bitbucket`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirectUrl'] = redirectUrl;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request github oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGitHubOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling requestGitHubOauthCode.'
        );
      }
      // verify required parameter 'redirectUrl' is not null or undefined
      if (redirectUrl === null || redirectUrl === undefined) {
        throw new RequiredError(
          'redirectUrl',
          'Required parameter redirectUrl was null or undefined when calling requestGitHubOauthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/oauth-authentication/github`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirectUrl'] = redirectUrl;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request google oauth code
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [redirectUrl] Redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGoogleOauthCode(
      accountId: number,
      projectShortName?: string,
      redirectUrl?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling requestGoogleOauthCode.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/oauth-authentication/google`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirectUrl'] = redirectUrl;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OAuthAuthenticationApi - functional programming interface
 * @export
 */
export const OAuthAuthenticationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Handle amoCrm oauth code
     * @param {string} code AmoCrm oauth code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleAmoCrmOAuthCode(
      code: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleAmoCrmOAuthCode(
        code,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Handle bitbucket oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleBitbucketOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleBitbucketOAuthCode(
        state,
        code,
        error,
        errorDescription,
        errorUri,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Handle github oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGitHubOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleGitHubOAuthCode(
        state,
        code,
        error,
        errorDescription,
        errorUri,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Handle google oauth code
     * @param {string} code Google oauth code
     * @param {string} state Google oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGoogleOAuthCode(
      code: string,
      state: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleGoogleOAuthCode(
        code,
        state,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Request bitbucket oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestBitbucketOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).requestBitbucketOauthCode(
        accountId,
        redirectUrl,
        projectShortName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Request github oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGitHubOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).requestGitHubOauthCode(
        accountId,
        redirectUrl,
        projectShortName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Request google oauth code
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [redirectUrl] Redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGoogleOauthCode(
      accountId: number,
      projectShortName?: string,
      redirectUrl?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).requestGoogleOauthCode(
        accountId,
        projectShortName,
        redirectUrl,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OAuthAuthenticationApi - factory interface
 * @export
 */
export const OAuthAuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Handle amoCrm oauth code
     * @param {string} code AmoCrm oauth code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleAmoCrmOAuthCode(code: string, options?: any): AxiosPromise<string> {
      return OAuthAuthenticationApiFp(configuration).handleAmoCrmOAuthCode(code, options)(axios, basePath);
    },
    /**
     *
     * @summary Handle bitbucket oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleBitbucketOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options?: any
    ): AxiosPromise<void> {
      return OAuthAuthenticationApiFp(configuration).handleBitbucketOAuthCode(
        state,
        code,
        error,
        errorDescription,
        errorUri,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Handle github oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGitHubOAuthCode(
      state: string,
      code?: string,
      error?: string,
      errorDescription?: string,
      errorUri?: string,
      options?: any
    ): AxiosPromise<void> {
      return OAuthAuthenticationApiFp(configuration).handleGitHubOAuthCode(
        state,
        code,
        error,
        errorDescription,
        errorUri,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Handle google oauth code
     * @param {string} code Google oauth code
     * @param {string} state Google oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleGoogleOAuthCode(code: string, state: string, options?: any): AxiosPromise<void> {
      return OAuthAuthenticationApiFp(configuration).handleGoogleOAuthCode(code, state, options)(axios, basePath);
    },
    /**
     *
     * @summary Request bitbucket oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestBitbucketOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options?: any
    ): AxiosPromise<void> {
      return OAuthAuthenticationApiFp(configuration).requestBitbucketOauthCode(
        accountId,
        redirectUrl,
        projectShortName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Request github oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGitHubOauthCode(
      accountId: number,
      redirectUrl: string,
      projectShortName?: string,
      options?: any
    ): AxiosPromise<void> {
      return OAuthAuthenticationApiFp(configuration).requestGitHubOauthCode(
        accountId,
        redirectUrl,
        projectShortName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Request google oauth code
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [redirectUrl] Redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestGoogleOauthCode(
      accountId: number,
      projectShortName?: string,
      redirectUrl?: string,
      options?: any
    ): AxiosPromise<string> {
      return OAuthAuthenticationApiFp(configuration).requestGoogleOauthCode(
        accountId,
        projectShortName,
        redirectUrl,
        options
      )(axios, basePath);
    },
  };
};

/**
 * OAuthAuthenticationApi - object-oriented interface
 * @export
 * @class OAuthAuthenticationApi
 * @extends {BaseAPI}
 */
export class OAuthAuthenticationApi extends BaseAPI {
  /**
   *
   * @summary Handle amoCrm oauth code
   * @param {string} code AmoCrm oauth code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public handleAmoCrmOAuthCode(code: string, options?: any) {
    return OAuthAuthenticationApiFp(this.configuration).handleAmoCrmOAuthCode(code, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Handle bitbucket oauth code
   * @param {string} state Google oauth state
   * @param {string} [code] oauth code
   * @param {string} [error] Error from github
   * @param {string} [errorDescription] Error description from github
   * @param {string} [errorUri] Error uri from github
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public handleBitbucketOAuthCode(
    state: string,
    code?: string,
    error?: string,
    errorDescription?: string,
    errorUri?: string,
    options?: any
  ) {
    return OAuthAuthenticationApiFp(this.configuration).handleBitbucketOAuthCode(
      state,
      code,
      error,
      errorDescription,
      errorUri,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Handle github oauth code
   * @param {string} state Google oauth state
   * @param {string} [code] oauth code
   * @param {string} [error] Error from github
   * @param {string} [errorDescription] Error description from github
   * @param {string} [errorUri] Error uri from github
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public handleGitHubOAuthCode(
    state: string,
    code?: string,
    error?: string,
    errorDescription?: string,
    errorUri?: string,
    options?: any
  ) {
    return OAuthAuthenticationApiFp(this.configuration).handleGitHubOAuthCode(
      state,
      code,
      error,
      errorDescription,
      errorUri,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Handle google oauth code
   * @param {string} code Google oauth code
   * @param {string} state Google oauth state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public handleGoogleOAuthCode(code: string, state: string, options?: any) {
    return OAuthAuthenticationApiFp(this.configuration).handleGoogleOAuthCode(
      code,
      state,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Request bitbucket oauth code
   * @param {number} accountId Account identifier
   * @param {string} redirectUrl Redirect url
   * @param {string} [projectShortName] Project short name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public requestBitbucketOauthCode(accountId: number, redirectUrl: string, projectShortName?: string, options?: any) {
    return OAuthAuthenticationApiFp(this.configuration).requestBitbucketOauthCode(
      accountId,
      redirectUrl,
      projectShortName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Request github oauth code
   * @param {number} accountId Account identifier
   * @param {string} redirectUrl Redirect url
   * @param {string} [projectShortName] Project short name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public requestGitHubOauthCode(accountId: number, redirectUrl: string, projectShortName?: string, options?: any) {
    return OAuthAuthenticationApiFp(this.configuration).requestGitHubOauthCode(
      accountId,
      redirectUrl,
      projectShortName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Request google oauth code
   * @param {number} accountId Account identifier
   * @param {string} [projectShortName] Project short name
   * @param {string} [redirectUrl] Redirect url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuthAuthenticationApi
   */
  public requestGoogleOauthCode(accountId: number, projectShortName?: string, redirectUrl?: string, options?: any) {
    return OAuthAuthenticationApiFp(this.configuration).requestGoogleOauthCode(
      accountId,
      projectShortName,
      redirectUrl,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SecretApi - axios parameter creator
 * @export
 */
export const SecretApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createSecret.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createSecret.'
        );
      }
      // verify required parameter 'secretName' is not null or undefined
      if (secretName === null || secretName === undefined) {
        throw new RequiredError(
          'secretName',
          'Required parameter secretName was null or undefined when calling createSecret.'
        );
      }
      // verify required parameter 'secretRequest' is not null or undefined
      if (secretRequest === null || secretRequest === undefined) {
        throw new RequiredError(
          'secretRequest',
          'Required parameter secretRequest was null or undefined when calling createSecret.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/projects/{projectId}/secrets/{secretName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'secretName'}}`, encodeURIComponent(String(secretName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof secretRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(secretRequest !== undefined ? secretRequest : {})
        : secretRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecret(accountId: number, projectId: string, secretName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteSecret.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteSecret.'
        );
      }
      // verify required parameter 'secretName' is not null or undefined
      if (secretName === null || secretName === undefined) {
        throw new RequiredError(
          'secretName',
          'Required parameter secretName was null or undefined when calling deleteSecret.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/projects/{projectId}/secrets/{secretName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'secretName'}}`, encodeURIComponent(String(secretName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of secret names
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretNames(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSecretNames.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getSecretNames.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/projects/{projectId}/secrets`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of secrets
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecrets(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSecrets.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getSecrets.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/projects/{projectId}/reveal-secrets`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of secrets
     * @param {Array<number>} accountIds Array of account identifiers
     * @param {Array<string>} projectIds Array of project identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretsBatch(accountIds: Array<number>, projectIds: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'accountIds' is not null or undefined
      if (accountIds === null || accountIds === undefined) {
        throw new RequiredError(
          'accountIds',
          'Required parameter accountIds was null or undefined when calling getSecretsBatch.'
        );
      }
      // verify required parameter 'projectIds' is not null or undefined
      if (projectIds === null || projectIds === undefined) {
        throw new RequiredError(
          'projectIds',
          'Required parameter projectIds was null or undefined when calling getSecretsBatch.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/reveal-secrets-batch`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (accountIds) {
        localVarQueryParameter['accountIds'] = accountIds;
      }

      if (projectIds) {
        localVarQueryParameter['projectIds'] = projectIds;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateSecret.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateSecret.'
        );
      }
      // verify required parameter 'secretName' is not null or undefined
      if (secretName === null || secretName === undefined) {
        throw new RequiredError(
          'secretName',
          'Required parameter secretName was null or undefined when calling updateSecret.'
        );
      }
      // verify required parameter 'secretRequest' is not null or undefined
      if (secretRequest === null || secretRequest === undefined) {
        throw new RequiredError(
          'secretRequest',
          'Required parameter secretRequest was null or undefined when calling updateSecret.'
        );
      }
      const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/projects/{projectId}/secrets/{secretName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'secretName'}}`, encodeURIComponent(String(secretName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof secretRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(secretRequest !== undefined ? secretRequest : {})
        : secretRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SecretApi - functional programming interface
 * @export
 */
export const SecretApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).createSecret(
        accountId,
        projectId,
        secretName,
        secretRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).deleteSecret(
        accountId,
        projectId,
        secretName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of secret names
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretNames(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretShortResponse>> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).getSecretNames(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of secrets
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecrets(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretFullResponse>> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).getSecrets(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of secrets
     * @param {Array<number>} accountIds Array of account identifiers
     * @param {Array<string>} projectIds Array of project identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretsBatch(
      accountIds: Array<number>,
      projectIds: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretBatchResponse>> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).getSecretsBatch(
        accountIds,
        projectIds,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SecretApiAxiosParamCreator(configuration).updateSecret(
        accountId,
        projectId,
        secretName,
        secretRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SecretApi - factory interface
 * @export
 */
export const SecretApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options?: any
    ): AxiosPromise<void> {
      return SecretApiFp(configuration).createSecret(
        accountId,
        projectId,
        secretName,
        secretRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecret(accountId: number, projectId: string, secretName: string, options?: any): AxiosPromise<void> {
      return SecretApiFp(configuration).deleteSecret(accountId, projectId, secretName, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of secret names
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretNames(accountId: number, projectId: string, options?: any): AxiosPromise<Array<SecretShortResponse>> {
      return SecretApiFp(configuration).getSecretNames(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of secrets
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecrets(accountId: number, projectId: string, options?: any): AxiosPromise<Array<SecretFullResponse>> {
      return SecretApiFp(configuration).getSecrets(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get list of secrets
     * @param {Array<number>} accountIds Array of account identifiers
     * @param {Array<string>} projectIds Array of project identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretsBatch(
      accountIds: Array<number>,
      projectIds: Array<string>,
      options?: any
    ): AxiosPromise<Array<SecretBatchResponse>> {
      return SecretApiFp(configuration).getSecretsBatch(accountIds, projectIds, options)(axios, basePath);
    },
    /**
     *
     * @summary Update secret
     * @param {number} accountId Account identifier
     * @param {string} projectId Project identifier
     * @param {string} secretName Project secret name
     * @param {SecretRequest} secretRequest Secret value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSecret(
      accountId: number,
      projectId: string,
      secretName: string,
      secretRequest: SecretRequest,
      options?: any
    ): AxiosPromise<void> {
      return SecretApiFp(configuration).updateSecret(
        accountId,
        projectId,
        secretName,
        secretRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * SecretApi - object-oriented interface
 * @export
 * @class SecretApi
 * @extends {BaseAPI}
 */
export class SecretApi extends BaseAPI {
  /**
   *
   * @summary Create secret
   * @param {number} accountId Account identifier
   * @param {string} projectId Project identifier
   * @param {string} secretName Project secret name
   * @param {SecretRequest} secretRequest Secret value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public createSecret(
    accountId: number,
    projectId: string,
    secretName: string,
    secretRequest: SecretRequest,
    options?: any
  ) {
    return SecretApiFp(this.configuration).createSecret(
      accountId,
      projectId,
      secretName,
      secretRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete secret
   * @param {number} accountId Account identifier
   * @param {string} projectId Project identifier
   * @param {string} secretName Project secret name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public deleteSecret(accountId: number, projectId: string, secretName: string, options?: any) {
    return SecretApiFp(this.configuration).deleteSecret(
      accountId,
      projectId,
      secretName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of secret names
   * @param {number} accountId Account identifier
   * @param {string} projectId Project identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public getSecretNames(accountId: number, projectId: string, options?: any) {
    return SecretApiFp(this.configuration).getSecretNames(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of secrets
   * @param {number} accountId Account identifier
   * @param {string} projectId Project identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public getSecrets(accountId: number, projectId: string, options?: any) {
    return SecretApiFp(this.configuration).getSecrets(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of secrets
   * @param {Array<number>} accountIds Array of account identifiers
   * @param {Array<string>} projectIds Array of project identifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public getSecretsBatch(accountIds: Array<number>, projectIds: Array<string>, options?: any) {
    return SecretApiFp(this.configuration).getSecretsBatch(accountIds, projectIds, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update secret
   * @param {number} accountId Account identifier
   * @param {string} projectId Project identifier
   * @param {string} secretName Project secret name
   * @param {SecretRequest} secretRequest Secret value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretApi
   */
  public updateSecret(
    accountId: number,
    projectId: string,
    secretName: string,
    secretRequest: SecretRequest,
    options?: any
  ) {
    return SecretApiFp(this.configuration).updateSecret(
      accountId,
      projectId,
      secretName,
      secretRequest,
      options
    )(this.axios, this.basePath);
  }
}
