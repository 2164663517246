import { ALERT_RECEIVING, DISMISS_ALERT, DISMISS_ALL_ALERTS } from '../../constants/globalAlert.actions';

import { ADD_NEWS_ITEM_REJECTED, UPDATE_NEWS_ITEM_REJECTED } from '../../constants/newsItem.actions';
import { ACTIVATE_PLATFORM_FULFILLED } from '../../constants/keysAndPlans.actions';
import { t } from '../../localization';
import moment from 'moment';

const initialState = {
  alerts: [],
};

export default function GlobalAlert(state = initialState, action) {
  switch (action.type) {
    case ALERT_RECEIVING: {
      const mesAlert = [...state.alerts];

      if (state.alerts[0]?.type !== 'info' && state.alerts[0]?.message === action.payload.message) {
        mesAlert[0].alertsStackCount = mesAlert[0].alertsStackCount + 1;
      } else {
        action.payload.alertsStackCount = 1;
        action.payload.showed = true;
        action.payload.time = action?.payload?.time || Date.now();
        mesAlert.splice(0, 0, action.payload);
      }

      return {
        ...state,
        alerts: mesAlert,
      };
    }
    case DISMISS_ALERT: {
      const mesAlert = state.alerts.slice();
      const showedIndex = mesAlert.findIndex(mes => {
        return mes.time === action.payload;
      });
      if (showedIndex !== -1) {
        mesAlert[showedIndex]['showed'] = false;
      }
      return {
        ...state,
        alerts: mesAlert,
      };
    }
    case DISMISS_ALL_ALERTS: {
      return {
        ...state,
        alerts: [],
      };
    }
    case ADD_NEWS_ITEM_REJECTED:
    case UPDATE_NEWS_ITEM_REJECTED: {
      const mesAlert = [...state.alerts];
      let alert = {
        type: 'error',
        title: t('GlobalAlert: update news error'),
        message: action.payload.response.data.error,
        time: Date.now(),
        showed: true,
      };
      mesAlert.splice(0, 0, alert);
      return {
        ...state,
        alerts: mesAlert,
      };
    }

    case ACTIVATE_PLATFORM_FULFILLED: {
      const mesAlert = [...state.alerts];
      let message = Boolean(action.payload.data.expiringAt)
        ? t(
            'GlobalAlert: key life period',
            moment(action.payload.data.expiringAt).fromNow(true),
            moment(action.payload.data.expiringAt).format('DD MMM YYYY, HH:mm')
          )
        : t('GlobalAlert: unlimited key');
      let alert = {
        type: 'success',
        title: t('GlobalAlert: succes activated key'),
        message: message,
        time: Date.now(),
        showed: true,
      };
      mesAlert.splice(0, 0, alert);
      return {
        ...state,
        alerts: mesAlert,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
