import { CustomProviderOption, CustomProvider } from './types';
import { TextToSpeechProviderDetail } from './api/client';
import { OptionType } from '@just-ai/just-ui';
import { isEuroInstance } from '../../isAccessFunction';

export const prepareTextForTts = (text: string): string => {
  const cleanedText = text
    .split(/^\s*a:/g)
    .map(value => {
      const splittedValue = value.split(/\n/g);
      let returnValue: string[] = [];
      for (let sentence of splittedValue) {
        if (/^\s*\w+!*:/.test(sentence)) break;
        if (sentence.startsWith('#')) break;
        returnValue.push(sentence);
      }
      return returnValue.join('\n');
    })
    .join('')
    .trim();

  const validSsmlRegex = /(tts\s*=\s*)(["'])(?:)(.+)(["'])/;
  const textWithPipesRegex = /(^.*)\s*(\|{2})/;

  if (validSsmlRegex.test(cleanedText)) {
    const groups = cleanedText.match(validSsmlRegex);
    return (groups && groups[3]) || cleanedText;
  } else if (textWithPipesRegex.test(cleanedText)) {
    const groups = cleanedText.match(textWithPipesRegex);
    return (groups && groups[1].trim()) || cleanedText;
  }

  return cleanedText;
};

export const getAvailableProviderOptions = (providers: TextToSpeechProviderDetail[]): OptionType[] =>
  providers.map(provider => ({
    label: isEuroInstance()
      ? `Tovie AI - ${provider === 'AIMYVOICE' ? 'Tovie Voice' : provider[0] + provider.slice(1).toLowerCase()}`
      : `Just AI - ${provider[0]}${provider.slice(1).toLowerCase()}`,
    value: provider,
  }));

export const getCustomProviderOptions = (arr: Array<CustomProvider>): Array<CustomProviderOption> => {
  return arr
    .filter(element => !element.azure?.connectionType || element.azure.connectionType === 'TTS')
    .map(element => {
      let providerName: TextToSpeechProviderDetail;

      Object.entries(element).forEach(([key, value]) => {
        if (value && key !== 'id' && key !== 'name') {
          providerName = key.toUpperCase() as TextToSpeechProviderDetail;
        }
      });

      return {
        id: element.id,
        name: element.name,
        // @ts-ignore
        providerName,
      };
    });
};
