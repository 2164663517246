import { CustomTagData, GraphV2UpdateActionType, GraphV2UpdateItemData } from '../api/client';
import { Block, BlockPosition, Intent, MetaBlock, SimpleBlock } from '../model/Block';
import { getNewUniqId, getParamDefaults } from '../context/contextUtils';
import { cloneDeep, isEqual, isUndefined, omit, pick } from 'lodash';
import { Connection, CUSTOM_TRANSITIONS, Graph, ORIGINAL_TRANSITIONS, TransitionType } from '../model/Graph';
import { decode, encode } from 'js-base64';

const INDENT = '    ';
const LINE_DELIMITER = '\n';

const TRANSITION_BY_TYPE = {
  buttons: 'buttons:',
  condition: 'if:',
  answers: 'a:',
  answer: 'a:',
  image: 'image:',
  audios: 'audio:',
  script: 'script:',
  transition: 'go!:',
  immediate: 'go!:',
  random: 'random:',
  intents: 'intents:',
};

export type BlocksPositions = {
  [blockId: string]: BlockPosition;
};

const notNull = <TValue>(value: TValue | null): value is TValue => {
  return value !== null;
};

export default class GraphService {
  clone(graph: Readonly<Graph>): Graph {
    return JSON.parse(JSON.stringify(graph));
  }

  setBlocksPositions(graph: Readonly<Graph>, positions: Readonly<BlocksPositions>): [Graph, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const actions = Object.entries(positions)
      .map(([blockId, position]) => this.setBlockPositionMutably(newGraph, blockId, position))
      .filter(notNull);
    return [newGraph, actions];
  }

  addBlock(
    graph: Readonly<Graph>,
    tag: string,
    position: Readonly<BlockPosition>,
    customTags: Readonly<CustomTagData[]>
  ): [Graph, Block, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const newBlock = this.createBlock(graph.path, tag, position, newGraph.blocks, customTags);
    newGraph.blocks.push(newBlock);
    return [newGraph, newBlock, newBlock.type === 'block' ? [this.createAddBlockAction(newBlock)] : []];
  }

  removeNewBlock(graph: Readonly<Graph>, statePath: string): Graph {
    const newGraph = this.clone(graph);
    const newBlockIndex = graph.blocks.findIndex(block => block.statePath === statePath && block.isNew);
    if (newBlockIndex > -1) {
      newGraph.blocks.splice(newBlockIndex, 1);
    }
    return newGraph;
  }

  updateBlockProps(graph: Readonly<Graph>, block: Block, editBlock?: Block): [Graph, GraphV2UpdateItemData] {
    const newGraph = this.clone(graph);
    const action = this.updateBlockPropsMutably(newGraph, block, editBlock);
    return [newGraph, action];
  }

  updateBlockCode(graph: Readonly<Graph>, block: Block, code: string): [Graph, GraphV2UpdateItemData] {
    const newGraph = this.clone(graph);
    const newBlock = cloneDeep(block);
    const action = this.updateBlockCodeMutably(newGraph, newBlock, code);
    return [graph, action]; // graph -> newGraph when action canceling will be implemented
  }

  removeBlocks(graph: Readonly<Graph>, blocks: Block[]): [Graph, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const actions = blocks
      .map(block => block.statePath)
      .map(blockId => this.removeBlockMutably(newGraph, blockId))
      .filter(notNull);
    return [newGraph, actions];
  }

  removeBlock(graph: Readonly<Graph>, blockToRemoveId: string) {
    const newGraph = this.clone(graph);
    this.removeBlockMutably(newGraph, blockToRemoveId);
    return newGraph;
  }

  addBlockTransition(graph: Readonly<Graph>, fromBlock: Block, type: TransitionType): [Graph, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const blockIndex = newGraph.blocks.findIndex(block => block.statePath === fromBlock.statePath);
    if (blockIndex < 0) return [newGraph, []];
    const block = newGraph.blocks[blockIndex];

    const action = this.updateBlockCodeMutably(
      newGraph,
      block,
      this.addTransitionToCodeSnippet(block.snippet || '', type)
    );
    return [newGraph, [action]];
  }

  private addTransitionToCodeSnippet(code: string, type: TransitionType): string {
    return code + LINE_DELIMITER + INDENT + TRANSITION_BY_TYPE[type];
  }

  addConnection(
    graph: Readonly<Graph>,
    connectionFrom: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>,
    to: string
  ): [Graph, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const foundConnectionIndex = newGraph.connections.findIndex(connection =>
      isEqual(pick(connection, ['from', 'outerType', 'innerPath']), connectionFrom)
    );
    const blockIndex = newGraph.blocks.findIndex(block => block.id === connectionFrom.from);
    if (blockIndex === -1) return [newGraph, []];

    const [newConnection, newBlock, actions] = this.createConnection(
      newGraph.blocks[blockIndex],
      connectionFrom,
      to,
      foundConnectionIndex > -1 ? newGraph.connections[foundConnectionIndex] : undefined
    );
    if (foundConnectionIndex > -1) {
      newGraph.connections.splice(foundConnectionIndex, 1, newConnection);
    } else {
      newGraph.connections.push(newConnection);
    }

    newGraph.blocks.splice(blockIndex, 1, newBlock);

    return [newGraph, actions];
  }

  private createConnection(
    block: Block,
    from: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>,
    to: string,
    foundConnection?: Connection
  ): [Connection, Block, GraphV2UpdateItemData[]] {
    const connection: Connection = foundConnection
      ? { ...foundConnection }
      : {
          label: '',
          ...from,
          to,
        };

    const [newBlock, actions] = this.setConnectionToBlock(block, connection, to);

    return [
      {
        ...connection,
        ...from,
        to,
      },
      newBlock,
      actions,
    ];
  }

  removeConnections(graph: Readonly<Graph>, connections: Readonly<Connection[]>): [Graph, GraphV2UpdateItemData[]] {
    const newGraph = this.clone(graph);
    const actions: GraphV2UpdateItemData[] = [];
    connections.forEach(connection => {
      const [block, index] = this.getBlockAndIndexFromId(graph, connection.from);
      newGraph.connections = newGraph.connections.filter(con => !isEqual(con, connection));
      if (block && index > -1) {
        const [newBlock, newActions] = this.setConnectionToBlock(block, connection, '');
        newGraph.blocks.splice(index, 1, newBlock);
        actions.push(...newActions);
      }
    });
    return [newGraph, actions];
  }

  findBlockWithStatePath(graph: Readonly<Graph>, statePath: string) {
    const block = graph.blocks.find(block => block.statePath === statePath);
    return block?.type === 'block' ? block : undefined;
  }

  findMetaBlockWithStatePath(graph: Readonly<Graph>, statePath: string) {
    const block = graph.blocks.find(block => block.statePath === statePath);
    return block?.type === 'metaBlock' ? block : undefined;
  }

  private setConnectionToBlock(
    block: Block,
    connection: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>,
    to: string
  ): [Block, GraphV2UpdateItemData[]] {
    const newBlock = cloneDeep(block);

    const toStatePath = decode(to);

    switch (newBlock.type) {
      case 'metaBlock': {
        switch (connection.type) {
          case 'actions': {
            let index = connection.innerPath.pop();
            let actionIndex = connection.innerPath.pop();
            //@ts-ignore
            newBlock.parameters[connection.type][actionIndex].buttons[index].transition = toStatePath;
            break;
          }
          case 'intents': {
            let actionIndex = connection.innerPath.pop();
            if (!isUndefined(actionIndex) && actionIndex > -1) {
              (newBlock.parameters[connection.type][actionIndex] as Intent).then = toStatePath;
            }
            break;
          }
          default: {
            newBlock.parameters[connection.type] = toStatePath;

            break;
          }
        }

        const updateBlock = omit(cloneDeep(newBlock), ['snippet']);

        if (updateBlock.parameters['actions']) {
          updateBlock.parameters['actions'] = JSON.stringify(updateBlock.parameters['actions']);
        }

        return [
          newBlock,
          [
            {
              action: GraphV2UpdateActionType.UpdateProps,
              data: updateBlock,
              oldPath: decode(connection.from),
            },
          ],
        ];
      }

      case 'block': {
        if (newBlock.transitions && ORIGINAL_TRANSITIONS.includes(connection.outerType)) {
          const connectionAsTransition = pick(connection, ['innerPath', 'label', 'outerType', 'path', 'type']);
          const foundTransition = newBlock.transitions.find(transition =>
            isEqual(omit(transition, ['offsetTop']), connectionAsTransition)
          );
          if (foundTransition) {
            foundTransition.path = toStatePath;
          }
        }

        if (newBlock.activation && CUSTOM_TRANSITIONS.includes(connection.outerType)) {
          const contextIndex = connection.innerPath.pop();
          const fromOrToState = connection.innerPath.pop() === 1 ? 'toState' : 'fromState';
          //@ts-ignore
          newBlock.activation[connection.outerType].context[contextIndex][fromOrToState] = toStatePath;
        }
        const updateBlock = omit(newBlock, ['snippet']);

        return [
          newBlock,
          [
            {
              action: GraphV2UpdateActionType.UpdateProps,
              data: updateBlock,
              oldPath: decode(connection.from),
            },
          ],
        ];
      }
    }
    return [newBlock, []];
  }

  private getBlockAndIndexFromId(graph: Readonly<Graph>, id: string): [Block | null, number] {
    const blockIndex = graph.blocks.findIndex(block => block.id === id);
    if (blockIndex > -1) {
      return [graph.blocks[blockIndex], blockIndex];
    }
    return [null, blockIndex];
  }

  private setBlockPositionMutably(
    graph: Readonly<Graph>,
    blockId: string,
    position: BlockPosition
  ): GraphV2UpdateItemData | null {
    const optionalBlockIndex = graph.blocks.findIndex(block => block.id === blockId);

    if (optionalBlockIndex === -1) {
      console.error(`No blocks with id: ${blockId}`);
      return null;
    }

    graph.blocks[optionalBlockIndex] = {
      ...graph.blocks[optionalBlockIndex],
      x: position.x,
      y: position.y,
    };

    return {
      action: GraphV2UpdateActionType.UpdateLayout,
      data: graph.blocks[optionalBlockIndex],
    };
  }

  private removeBlockMutably(graph: Graph, blockToRemovePath: string): GraphV2UpdateItemData | null {
    const blockToRemoveIndex = graph.blocks.findIndex(block => block.statePath === blockToRemovePath);
    if (blockToRemoveIndex < 0) return null;

    graph.blocks.splice(blockToRemoveIndex, 1);
    graph.connections = graph.connections.filter(
      connection => !this.isConnectedToBlock(connection, encode(blockToRemovePath))
    );
    return {
      action: GraphV2UpdateActionType.Delete,
      oldPath: blockToRemovePath,
    };
  }

  private isConnectedToBlock(connection: Connection, blockId: string) {
    return connection.from === blockId || connection.to === blockId;
  }

  private updateBlockPropsMutably(graph: Graph, updatedBlock: Block, editBlock?: Block): GraphV2UpdateItemData {
    const isNew = editBlock?.isNew || updatedBlock.isNew;
    let updateStatePath = updatedBlock.statePath;
    if (editBlock && editBlock.statePath !== updatedBlock.statePath) {
      updateStatePath = editBlock.statePath;
    }
    const blockToReplaceIndex = graph.blocks.findIndex(block => block.statePath === updateStatePath);
    if (blockToReplaceIndex < 0) {
      graph.blocks.push(updatedBlock);
      return {
        action: GraphV2UpdateActionType.Add,
        data: omit(updatedBlock, 'isNew'),
      };
    }

    graph.blocks[blockToReplaceIndex] = updatedBlock;
    return {
      action: isNew ? GraphV2UpdateActionType.Add : GraphV2UpdateActionType.UpdateProps,
      oldPath: isNew ? undefined : updateStatePath,
      data: omit(updatedBlock, 'isNew'),
    };
  }

  private createAddBlockAction(block: Block) {
    return {
      action: GraphV2UpdateActionType.Add,
      data: block,
    };
  }

  private updateBlockCodeMutably(graph: Graph, blockToUpdate: Block, code: string): GraphV2UpdateItemData {
    const blockToReplaceIndex = graph.blocks.findIndex(block => block.statePath === blockToUpdate.statePath);
    blockToUpdate.snippet = code;
    if (blockToReplaceIndex < 0) {
      graph.blocks.push(blockToUpdate);
      return this.createAddBlockAction(blockToUpdate);
    }

    graph.blocks[blockToReplaceIndex] = blockToUpdate;
    return {
      action: blockToUpdate.isNew ? GraphV2UpdateActionType.Add : GraphV2UpdateActionType.UpdateCode,
      oldPath: blockToUpdate.statePath,
      data: blockToUpdate,
    };
  }

  private createBlock(
    filePath: string,
    tag: string,
    position: BlockPosition,
    blocks: Block[],
    customTags: Readonly<CustomTagData[]>
  ): SimpleBlock | MetaBlock {
    const template = customTags.find(customTag => customTag.tagName === tag);

    if (tag === 'block' || !template) {
      const statePath = getNewUniqId('/newNode', blocks);
      return {
        id: encode(statePath),
        statePath,
        type: 'block',
        filePath,
        snippet: `state: ${statePath}`,
        ...position,
      };
    }

    const statePath = getNewUniqId('/' + template.tagName, blocks);
    const block: MetaBlock = {
      isNew: true,
      id: encode(statePath),
      statePath,
      type: 'metaBlock',
      customTag: template.tagName!,
      parameters: {},
      ...position,
    };
    template.parameters?.forEach(param => {
      if (param.name) block.parameters[param.name] = getParamDefaults(param);
    });

    return block;
  }
}
