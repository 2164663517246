export const salesrequestmodalLocalization = {
  'SalesRequestModal title demoPeriodWillEndSoon': {
    eng: 'Request the price',
    ru: 'Запросить цену',
    cn: '选择套餐',
  },
  'SalesRequestModal title payedPeriodWillEndSoon': {
    eng: 'Payment for the plan',
    ru: 'Оплата тарифа',
    cn: '支付套餐 ',
  },
  'SalesRequestModal title uniqueWillEndSoon': {
    eng: 'Add unique users',
    ru: 'Добавить уникальных пользователей',
    cn: '添加单个用户',
  },
  'SalesRequestModal title nluMinutesWillEndSoon': {
    eng: 'Add NLU minutes',
    ru: 'Добавить NLU-минуты',
    cn: '增加自然语言理解分钟量',
  },
  'SalesRequestModal title telephonyMinutesWillEndSoon': {
    eng: 'Add Just AI telephony minutes',
    ru: 'Добавить минуты телефонии Just AI',
    cn: '增加Just AI电话分钟量',
  },
  'SalesRequestModal title asrMinutesWillEndSoon': {
    eng: 'Add #{config.botadmin.companyName} ASR minutes',
    ru: 'Добавить минуты ASR #{config.botadmin.companyName} ',
    cn: '增加#{config.botadmin.companyName} 语音识别、语音合成分钟量',
  },
  'SalesRequestModal title demoPeriodFinishedWithoutPay': {
    eng: 'Select plan',
    ru: 'Выбрать тариф',
    cn: '选择套餐',
  },
  'SalesRequestModal title payedPeriodFinishedWithoutPay': {
    eng: 'Payment for the plan',
    ru: 'Оплата тарифа',
    cn: '支付套餐',
  },
  'SalesRequestModal title uniqueExceed': {
    eng: 'Add unique users',
    ru: 'Добавить уникальных пользователей',
    cn: '添加单个用户',
  },
  'SalesRequestModal title nluMinutesExceed': {
    eng: 'Add NLU minutes',
    ru: 'Добавить NLU-минуты',
    cn: '增加自然语言理解分钟量',
  },
  'SalesRequestModal title telephonyMinutesExceed': {
    eng: 'Add Just AI telephony minutes',
    ru: 'Добавить минуты телефонии Just AI',
    cn: '增加Just AI电话分钟量',
  },
  'SalesRequestModal title asrMinutesExceed': {
    eng: 'Add #{config.botadmin.companyName} ASR minutes',
    ru: 'Добавить минуты ASR #{config.botadmin.companyName}',
    cn: '增加#{config.botadmin.companyName}语音识别、语音合成分钟量',
  },
  'SalesRequestModal description demoPeriodWillEndSoon': {
    eng: '<ul><li>You will need to sign a contract if you want to buy a business plan.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для покупки бизнес тарифа необходимо оформление договора.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*您可以从套餐页面自行购买开发套餐</li><li>如果您想购买商业套餐，您需要签订合同</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。$[1]</li></ul>',
  },
  'SalesRequestModal description payedPeriodWillEndSoon': {
    eng: '<ul><li>You need to subscribe to a plan to resume development.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения разработки необходима подписка на тариф.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*如果您希望继续开发，请购买一个套餐</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们$[1]</li></ul>',
  },
  'SalesRequestModal description uniqueWillEndSoon': {
    eng: '<ul><li>You need to buy a package of unique users to have your bot resume operation.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы бота нужно приобрести пакет уникальных пользователей.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*如果您希望机器人恢复运行，请购买单个用户套餐。</li><li>请填写联系方式，#{config.botadmin.productName}客户代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal description nluMinutesWillEndSoon': {
    eng: '<ul><li>You need to buy an NLU minutes package to have your telephony bots resume operation.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов нужно приобрести пакет NLU-минут.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*如果您希望电话机器人恢复运行，请购买一个自然语言理解分钟套餐包。</li><li>请填写联系方式，#{config.botadmin.productName}客户代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal description telephonyMinutesWillEndSoon': {
    eng: '<ul><li>You need to buy a #{config.botadmin.productName} telephony minutes package to have your bots resume operation over the #{config.botadmin.productName} telephony channel.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов с использованием телефонии Jus tAI нужно приобрести пакет минут телефонии #{config.botadmin.productName}.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>如果您想让您的机器人通过#{config.botadmin.productName} 电话通道恢复运行，请购买一个#{config.botadmin.productName} 电话分钟套餐包。</li><li>请填写联系方式，#{config.botadmin.productName}客户代表会尽快给您回电。或者您可以发邮件给我们。 $[1].</li></ul>',
  },
  'SalesRequestModal description asrMinutesWillEndSoon': {
    eng: '<ul><li>You need to buy a #{config.botadmin.productName} ASR minutes package to have your bots resume operation over the #{config.botadmin.productName} ASR channel.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов с использованием ASR #{config.botadmin.productName} нужно приобрести пакет минут ASR #{config.botadmin.productName}.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*如果您想让您的机器人通过#{config.botadmin.productName} 语音识别/语音合成通道恢复运行，请购买一个#{config.botadmin.productName} 语音识别/语音合成分钟套餐包。</li><li>请填写联系方式，#{config.botadmin.productName} 代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal description demoPeriodFinishedWithoutPay': {
    eng: '<ul><li>You will need to sign a contract if you want to buy a business plan.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для покупки бизнес тарифа необходимо оформление договора.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>您可以从套餐页面自行购买开发套餐。</li><li>如果您想购买商业套餐，需要签订合同</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。 [1].</li></ul>',
  },
  'SalesRequestModal description payedPeriodFinishedWithoutPay': {
    eng: '<ul><li>You need to subscribe to a plan to resume development.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения разработки необходима подписка на тариф.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>你需要购买套餐进行重启</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。 $[1].</li></ul>',
  },
  'SalesRequestModal description uniqueExceed': {
    eng: '<ul><li>You need to buy a package of unique users to have your bot resume operation.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы бота нужно приобрести пакет уникальных пользователей.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*如果您想让您的机器人恢复运行，请购买一个用户套餐。</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal description nluMinutesExceed': {
    eng: '<ul><li>You need to buy an NLU minutes package to have your telephony bots resume operation.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов нужно приобрести пакет NLU-минут.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>如果您想让您的电话机器人恢复运行，请购买一个自然语言理解分钟套餐包。</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们$[1].</li></ul>',
  },
  'SalesRequestModal description telephonyMinutesExceed': {
    eng: '<ul><li>You need to buy a #{config.botadmin.productName} telephony minutes package to have your bots resume operation over the #{config.botadmin.productName} telephony channel.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов с использованием телефонии Jus tAI нужно приобрести пакет минут телефонии #{config.botadmin.productName}.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>如果您想让您的机器人通过#{config.botadmin.productName} 电话通道恢复运行，请购买一个#{config.botadmin.productName} 电话套餐包。</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal description asrMinutesExceed': {
    eng: '<ul><li>You need to buy a #{config.botadmin.productName} ASR minutes package to have your bots resume operation over the #{config.botadmin.productName} ASR channel.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для продолжения работы телефонных ботов с использованием ASR #{config.botadmin.productName} нужно приобрести пакет минут ASR #{config.botadmin.productName}.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>如果您想让您的机器人通过#{config.botadmin.productName} 语音识别、语音合成通道恢复运行，请购买一个#{config.botadmin.productName} 分钟套餐包。</li><li>请填写联系方式，#{config.botadmin.productName} 客户代表会尽快给您回电。或者您可以发邮件给我们。$[1].</li></ul>',
  },
  'SalesRequestModal query demoPeriodWillEndSoon': {
    eng: 'Select plan for the account $[1]',
    ru: 'Выбрать тариф на аккаунте $[1]',
    cn: '为账户选择套餐 $[1]',
  },
  'SalesRequestModal query payedPeriodWillEndSoon': {
    eng: 'Pay for the plan in the account $[1]',
    ru: 'Оплатить тариф на аккаунте $[1]',
    cn: '支付账户中的套餐$[1]',
  },
  'SalesRequestModal query uniqueWillEndSoon': {
    eng: 'Add unique users for the account $[1]',
    ru: 'Добавить уникальных пользователей для аккаунта $[1]',
    cn: '为账户添加单个用户 $[1]',
  },
  'SalesRequestModal query nluMinutesWillEndSoon': {
    eng: 'Add NLU minutes package for the account $[1]',
    ru: 'Добавить пакет NLU-минут для аккаунта $[1]',
    cn: '为账户添加自然语言理解分钟包 $[1]',
  },
  'SalesRequestModal query telephonyMinutesWillEndSoon': {
    eng: 'Add Just AI telephony minutes package for the account $[1]',
    ru: 'Добавить пакет минут телефонии Just AI для аккаунта $[1]',
    cn: '为账户添加电话分钟包$[1]',
  },
  'SalesRequestModal query asrMinutesWillEndSoon': {
    eng: 'Add #{config.botadmin.companyName} ASR minutes package for the account $[1]',
    ru: 'Добавить пакет минут ASR #{config.botadmin.companyName} для аккаунта $[1]',
    cn: '为账户添加 #{config.botadmin.companyName}语音识别、语音合成分钟包 $[1]',
  },
  'SalesRequestModal query demoPeriodFinishedWithoutPay': {
    eng: 'Select plan for the account $[1]',
    ru: 'Выбрать тариф на аккаунте $[1]',
    cn: '为账户选择套餐$[1]',
  },
  'SalesRequestModal query payedPeriodFinishedWithoutPay': {
    eng: 'Pay for the plan in the account $[1]',
    ru: 'Оплатить тариф на аккаунте $[1]',
    cn: '支付账户中的套餐$[1]',
  },
  'SalesRequestModal query uniqueExceed': {
    eng: 'Add unique users for the account $[1]',
    ru: 'Добавить уникальных пользователей для аккаунта $[1]',
    cn: '为账户添加用户$[1]',
  },
  'SalesRequestModal query nluMinutesExceed': {
    eng: 'Add NLU minutes package for the account $[1]',
    ru: 'Добавить пакет NLU-минут для аккаунта $[1]',
    cn: '为账户添加自然语言理解分钟包 $[1]',
  },
  'SalesRequestModal query telephonyMinutesExceed': {
    eng: 'Add Just AI telephony minutes package for the account $[1]',
    ru: 'Добавить пакет минут телефонии Just AI для аккаунта $[1]',
    cn: '为用户添加 Just AI电话分钟包$[1]',
  },
  'SalesRequestModal query asrMinutesExceed': {
    eng: 'Add #{config.botadmin.companyName} ASR minutes package for the account $[1]',
    ru: 'Добавить пакет минут ASR #{config.botadmin.companyName} для аккаунта $[1]',
    cn: '为账户添加#{config.botadmin.companyName} 语音识别、语音合成分钟包$[1]',
  },
  'SalesRequestModal title default': {
    eng: 'Request help from the #{config.botadmin.companyName} manager',
    ru: 'Запрос менеджеру #{config.botadmin.companyName}',
    cn: '请找#{config.botadmin.companyName}管理员帮助',
  },
  'SalesRequestModal description default': {
    eng: 'You need assistance from your account manager to continue. Please enter your contact details, and our manager will call you.',
    ru: 'Для продолжения необходимо участие вашего менеджера. Укажите данные для связи с вами и менеджер вам перезвонит',
    cn: '您需要账户经理的帮助才能继续。请输入您的联系方式，我们的管理员会联系您',
  },
  'SalesRequestModal label fullName or IE': {
    eng: 'Company name',
    ru: 'Название юрлица или ФИО ИП*',
    cn: '公司名称或个人用户姓名*',
  },
  'SalesRequestModal label email': {
    eng: 'Email',
    ru: 'Email',
    cn: '邮件',
  },
  'SalesRequestModal label phoneNumber': {
    eng: 'Phone number',
    ru: 'Номер телефона',
    cn: '电话',
  },
  'SalesRequestModal placeholder phoneNumber': {
    eng: 'Your number',
    ru: 'Ваш номер',
    cn: '您的号码',
  },
  'SalesRequestModal label requestText': {
    eng: 'Request',
    ru: 'Запрос',
    cn: '请求',
  },
  'SalesRequestModal ok btn text': {
    eng: 'Send request',
    ru: 'Отправить запрос',
    cn: '发送请求',
  },
  'SalesRequestModal required text': {
    eng: 'This field is required',
    ru: 'Поле обязательно для заполнения',
    cn: '此字段必填',
  },
  'SalesRequestModal title addPackagesRequest': {
    eng: 'Request the limit expansion',
    ru: 'Увеличить лимиты на аккаунте',
    cn: '在账户中增加额度',
  },
  'SalesRequestModal query addPackagesRequest': {
    eng: 'Increase the limits for the account $[1]',
    ru: 'Увеличить лимиты для аккаунта $[1]',
    cn: '为账户增加额度$[1]',
  },
  'SalesRequestModal description addPackagesRequest': {
    eng: '<ul><li>Please specify the expected amount and type of the limit you want to increase.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Укажите предполагаемые объемы и тип лимита, который нужно добавить.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>*请指定您希望增加的额度的金额和类型。</li><li>请填写联系方式，#{config.botadmin.productName}客户代表会很快给您回电。或者你可以发邮件给我们 $[1]。</li></ul>',
  },
  'SalesRequestModal title changeTariffRequest': {
    eng: 'Select plan',
    ru: 'Выбрать тариф',
    cn: '选择套餐',
  },
  'SalesRequestModal description changeTariffRequest': {
    eng: '<ul><li>You will need to sign a contract if you want to buy a business plan.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1].</li></ul>',
    ru: '<ul><li>Для покупки бизнес тарифа необходимо оформление договора.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1].</li></ul>',
    cn: '<ul><li>您可以从套餐页面自行购买开发套餐</li><li>如果您要购买商业套餐，需要签订合同</li><li>请填写联系方式， #{config.botadmin.productName}客户代表会很快给您回电。或者你可以发邮件给我们 $[1].</li></ul>',
  },
  'SalesRequestModal query changeTariffRequest': {
    eng: 'Select plan for the account $[1]',
    ru: 'Выбрать тариф на аккаунте $[1]',
    cn: '为账户选择套餐$[1]',
  },
  'SalesRequestModal title asrtts': {
    eng: 'ASR provider selection',
    ru: 'Выбор провайдера ASR',
  },
  'SalesRequestModal description asrtts': {
    eng: '<ul><li>Please contact your account manager if you want to use a special ASR connection.</li><li>Please fill in our contact form, and a #{config.botadmin.productName} representative will call you back soon. Or you can email us at $[1]</li></ul>',
    ru: '<ul><li>Для использования особого подключения ASR обратитесь к аккаунт-менеджеру.</li><li>Заполните форму для связи и представитель #{config.botadmin.productName} перезвонит вам в ближайшее время. Или напишите нам на $[1]</li></ul>',
  },
  'SalesRequestModal query asrtts': {
    eng: 'Enabling ASR provider for an account $[1]',
    ru: 'Подключение провайдера ASR для аккаунта $[1]',
  },
  'SalesRequestModal title developerTariff': {
    eng: 'Subscription plan request',
    ru: 'Запрос на подключение',
  },
  'SalesRequestModal description developerTariff': {
    eng: 'Fill out this contact form, and we will get back to you shortly. Or contact us at $[1].',
    ru: 'Заполните форму для связи, и представитель $[2] перезвонит вам в ближайшее время. Или напишите нам на $[1].',
  },
  'SalesRequestModal query developerTariff': {
    eng: 'Please switch my account $[1] from $[2] to the Free plan',
    ru: 'Прошу перевести аккаунт $[1] с $[2] на тариф Free',
  },
  'SalesRequestModal label fullName': {
    eng: 'Company name',
    ru: 'Название юрлица или ФИО',
  },
};
