import {
  VALIDATE_CALL_LIST_PENDING,
  VALIDATE_CALL_LIST_FULFILLED,
  VALIDATE_CALL_LIST_REJECTED,
  CREATE_CALL_LIST_PENDING,
  CREATE_CALL_LIST_FULFILLED,
  CREATE_CALL_LIST_REJECTED,
  RESET_CREATION_FORM,
  DELETE_CALL_LIST_FULFILLED,
} from '../../constants/callList.actions';

const InitialState = {
  validating: false,
  validated: false,
  validationErrors: [],
  validNumbersCount: 0,
  uploading: false,
  uploaded: false,
  error: null,
  limitExceeded: false,
  limitValue: 0,
  deleteStatus: 'ok',
  deletePreventionCallTaskNames: [],
};

export default function CallListReducer(state = InitialState, action) {
  switch (action.type) {
    case VALIDATE_CALL_LIST_PENDING:
      return {
        ...state,
        validating: true,
        validated: false,
      };

    case VALIDATE_CALL_LIST_FULFILLED:
      return {
        ...state,
        validating: false,
        validated: true,
        validationErrors: action.payload.data.errors,
        validNumbersCount: action.payload.data.validNumbersCount,
        limitExceeded: action.payload.data.limitExceeded,
        limitValue: action.payload.data.limitValue,
      };

    case VALIDATE_CALL_LIST_REJECTED:
      return {
        ...state,
        validating: false,
        error: action.payload.data,
      };

    case CREATE_CALL_LIST_PENDING:
      return {
        ...state,
        uploaded: false,
        uploading: true,
      };

    case CREATE_CALL_LIST_FULFILLED:
      return {
        ...state,
        uploading: false,
        uploaded: true,
        validationErrors: action.payload.data.fileValidationInfo.errors,
        validNumbersCount: action.payload.data.fileValidationInfo.validNumbersCount,
      };

    case CREATE_CALL_LIST_REJECTED:
      return {
        ...state,
        uploading: false,
        error: action.payload.data,
      };

    case DELETE_CALL_LIST_FULFILLED:
      return {
        ...state,
        deleteStatus: action.payload.data.status,
        deletePreventionCallTaskNames: action.payload.data.callListTaskNames,
      };

    case RESET_CREATION_FORM:
      return InitialState;

    default:
      return state;
  }
}
