export const commonLocalization = {
    'ProjectReview: Filter Headline': {
        eng: 'Project overview',
        ru: 'Обзор проекта',
        pt: 'Visão geral do projeto',
        cn: 'Project overview',
    },
    'ProjectReview: Period(P1)': {
        eng: 'Period(P1)',
        ru: 'Период(П1)',
        pt: 'Período(P1)',
        cn: 'Period(P1)',
    },
    'ProjectReview: Period(P2)': {
        eng: 'Period(P2)',
        ru: 'Период(П2)',
        pt: 'Período(P2)',
        cn: 'Period(P2)',
    },
    'ProjectReview: no volumes for': {
        eng: 'No volumes for $[1] — $[2]',
        ru: 'Нет объемов за $[1] — $[2]',
        pt: 'Nenhum volume para $[1] — $[2]',
        cn: 'No volumes for $[1] — $[2]',
    },
    'ProjectReview: we found volumes': {
        eng: 'Volumes found for',
        ru: 'Мы нашли объемы за',
        pt: 'Volumes encontrados para',
        cn: 'Volumes found for',
    },
    'ProjectReview:volumeTable header result': {
        eng: 'Result',
        ru: 'Результат',
        pt: 'Resultado',
        cn: 'Result',
    },
    'ProjectReview:volumeTable header from p1': {
        eng: 'Beginning of P1',
        ru: 'Начало П1',
        pt: 'Início do P1',
        cn: 'Beginning of P1',
    },
    'ProjectReview:volumeTable header to p1': {
        eng: 'End of P1',
        ru: 'Конец П1',
        pt: 'Término do P1',
        cn: 'End of P1',
    },
    'ProjectReview:volumeTable header diff p1': {
        eng: 'Difference in P1',
        ru: 'Разница в П1',
        pt: 'Diferença no P1',
        cn: 'Difference in P1',
    },
    'ProjectReview:volumeTable header from p2': {
        eng: 'Beginning of P2',
        ru: 'Начало П2',
        pt: 'Início do P2',
        cn: 'Beginning of P2',
    },
    'ProjectReview:volumeTable header to p2': {
        eng: 'End of P2',
        ru: 'Конец П2',
        pt: 'Término do P2',
        cn: 'End of P2',
    },
    'ProjectReview:volumeTable header diff p2': {
        eng: 'Difference in P2',
        ru: 'Разница в П2',
        pt: 'Diferença no P2',
        cn: 'Difference in P2',
    },
    And: {
        eng: 'and',
        ru: 'и',
        pt: 'e',
        cn: 'and',
    },
    'TreeComparisonOptions:LOG_LABELS': {
        eng: 'Select labeled conversations from the list:',
        ru: 'Выбрать диалоги с метками из списка:',
        pt: 'Selecionar conversas rotuladas na lista:',
        cn: 'Select labeled conversations from the list:',
    },
    'TreeModal:nothingFound': {
        eng: 'Nothing found',
        ru: 'Ничего не найдено',
        pt: 'Nada encontrado',
        cn: 'Nothing found',
    },
    'TreeModal:isEmpty': {
        eng: 'No data',
        ru: 'Нет данных',
        pt: 'Nenhum dado',
        cn: 'No data',
    },
    'NumericRangeModal:clear': {
        eng: 'Clear',
        ru: 'Очистить',
        pt: 'Limpar',
        cn: 'Clear',
    },
    'ResultsOverview: No Session Result': {
        eng: 'No result',
        ru: 'Без результата',
        pt: 'Nenhum resultado',
        cn: 'No result',
    },
    'ResultsOverviewTable: Show all list': {
        eng: 'Show all',
        ru: 'Показать весь список',
        pt: 'Mostrar tudo',
        cn: 'Show all',
    },
    'LabelsPage:title': {
        eng: 'Label catalog',
        ru: 'Справочник меток',
        pt: 'Catálogo de rótulos',
        cn: 'Label catalog',
    },
    'LabelsPage:phrase': {
        eng: 'Phrase labels',
        ru: 'Метки фраз',
        pt: 'Rótulos de frase',
        cn: 'Phrase labels',
    },
    'LabelsPage:sessions': {
        eng: 'Session labels',
        ru: 'Метки сессий',
        pt: 'Rótulos de sessão',
        cn: 'Session labels',
    },
    'LabelsPage:labels': {
        eng: 'Task labels and properties',
        ru: 'Метки и свойства задач',
        pt: 'Rótulos e propriedades de tarefa',
        cn: 'Task labels and properties',
    },
    'LabelsPage:messages-labels-title': {
        eng: 'Phrase label groups',
        ru: 'Группы меток фраз',
        pt: 'Grupos de rótulos de frase',
        cn: 'Phrase label groups',
    },
    'LabelsPage:create-label-button': {
        eng: 'Add label',
        ru: 'Добавить метку',
        pt: 'Adicionar rótulo',
        cn: 'Add label',
    },
    'LabelsPage:create-group-button': {
        eng: 'Add group',
        ru: 'Добавить группу',
        pt: 'Adicionar grupo',
        cn: 'Add group',
    },
    'LabelsPage:sessions-labels-title': {
        eng: 'Session labels',
        ru: 'Метки сессии',
        pt: 'Rótulos de sessão',
        cn: 'Session labels',
    },
    'LabelsPage:issues-labels-title': {
        eng: 'Task labels and properties',
        ru: 'Метки и свойства задач',
        pt: 'Rótulos e propriedades de tarefa',
        cn: 'Task labels and properties',
    },
    'LabelsPage:issues-labels-subtitle': {
        eng: 'Configure labels and properties and use them in tasks to improve the project',
        ru: 'Настройте метки и свойства для будущих задач по доработке проекта',
        pt: 'Configurar rótulos e propriedades e usá-los nas tarefas para aprimorar o projeto',
        cn: 'Configure labels and properties and use them in tasks to improve the project',
    },
    'LabelsList.addItem': {
        eng: 'Add label',
        ru: 'Добавить метку',
        pt: 'Adicionar rótulo',
        cn: 'Add label',
    },
    'CreateLabelInGroupModal:Label name label': {
        eng: 'Label name',
        ru: 'Название метки',
        pt: 'Nome do rótulo',
        cn: 'Label name',
    },
    'CreateLabelInGroupModal:label name placeholder': {
        eng: 'Name',
        ru: 'Название',
        pt: 'Nome',
        cn: 'Name',
    },
    'CreateLabelInGroupModal:modal title': {
        eng: 'New label',
        ru: 'Новая метка',
        pt: 'Novo rótulo',
        cn: 'New label',
    },
    'CreateLabelInGroupModal:group label': {
        eng: 'Add to group',
        ru: 'Поместить в группу',
        pt: 'Adicionar ao grupo',
        cn: 'Add to group',
    },
    'CreateLabelInGroupModal:submit button text': {
        eng: 'Create label',
        ru: 'Создать метку',
        pt: 'Criar rótulo',
        cn: 'Create label',
    },
    'CreateLabelInGroupModal:cancel button text': {
        eng: 'Cancel',
        ru: 'Отмена',
        pt: 'Cancelar',
        cn: 'Cancel',
    },
    'CreateGroupModal:modal title': {
        eng: 'New label group',
        ru: 'Новая группа меток',
        pt: 'Novo grupo de rótulos',
        cn: 'New label group',
    },
    'CreateGroupModal:submit button text': {
        eng: 'Create group',
        ru: 'Создать группу',
        pt: 'Criar grupo',
        cn: 'Create group',
    },
    'CreateGroupModal:cancel button text': {
        eng: 'Cancel',
        ru: 'Отмена',
        pt: 'Cancelar',
        cn: 'Cancel',
    },
    'CreateGroupModal:group label': {
        eng: 'Group name',
        ru: 'Название группы',
        pt: 'Nome de grupo',
        cn: 'Group name',
    },
    'CreateGroupModal:group label label': {
        eng: 'A group must contain at least one label',
        ru: 'Добавьте хотя бы одну метку в группу',
        pt: 'Um grupo deve conter, pelo menos, um rótulo',
        cn: 'A group must contain at least one label',
    },
    'CreateGroupModal:group name placeholder': {
        eng: 'Name',
        ru: 'Название',
        pt: 'Nome',
        cn: 'Name',
    },
    'DeleteGroup:modal title': {
        eng: 'Delete group',
        ru: 'Удалить группу',
        pt: 'Excluir grupo',
        cn: 'Delete group',
    },
    'DeleteGroup:submit button text': {
        eng: 'Delete group',
        ru: 'Удалить группу',
        pt: 'Excluir grupo',
        cn: 'Delete group',
    },
    'DeleteGroup:cancel button text': {
        eng: 'Cancel',
        ru: 'Отмена',
        pt: 'Cancelar',
        cn: 'Cancel',
    },
    'DeleteGroup:innerText': {
        eng: 'Once deleted, a group and its labels cannot be restored',
        ru: 'Группу и входящие в нее метки нельзя будет восстановить',
        pt: 'Depois da exclusão, o grupo e os rótulos dele não pode ser restaurados',
        cn: 'Once deleted, a group and its labels cannot be restored',
    },
    'IssueTypeGroup:labels group title': {
        eng: 'Labels',
        ru: 'Метки',
        pt: 'Rótulos',
        cn: 'Labels',
    },
    'IssueTypeGroup:labels group subtitle': {
        eng: 'List of all task labels',
        ru: 'Список меток задач',
        pt: 'Lista de todos os rótulos de tarefa',
        cn: 'List of all task labels',
    },
    'IssueTypeGroup:states group title': {
        eng: 'Status',
        ru: 'Статус',
        pt: 'Status',
        cn: 'Status',
    },
    'IssueTypeGroup:states group subtitle': {
        eng: 'For monitoring the status of future tasks',
        ru: 'Для отслеживания статуса будущих задач',
        pt: 'Para monitorar o status de futuras tarefas',
        cn: 'For monitoring the status of future tasks',
    },
    'IssueTypeGroup:priorities group title': {
        eng: 'Task priorities',
        ru: 'Приоритеты задач',
        pt: 'Prioridades de tarefa',
        cn: 'Task priorities',
    },
    'IssueTypeGroup:priorities group subtitle': {
        eng: 'Priorities will help choose which tasks are more important',
        ru: 'Приоритеты могут помочь при выборе первостепенных задач',
        pt: 'As prioridades ajudarão a escolher quais tarefas são mais importantes',
        cn: 'Priorities will help choose which tasks are more important',
    },
    Save: {
        eng: 'Save',
        ru: 'Сохранить',
        pt: 'Salvar',
        cn: '保存',
    },
    'SESSION_DATA:AddNewFilter': {
        eng: 'Add session property',
        ru: 'Добавить свойство',
        pt: 'Adicionar propriedade de sessão',
        cn: 'Add session property',
    },
    'COMMENT_TEXT:AddNewFilter': {
        eng: 'Add condition',
        ru: 'Добавить условие',
        pt: 'Adicionar condição',
        cn: 'Add condition',
    },
    'STATE_ROUTES:AddNewFilter': {
        eng: 'Add condition',
        ru: 'Добавить условие',
        pt: 'Adicionar condição',
        cn: 'Add condition',
    },
    'SessionData:SuggestionsLimitText': {
        eng: 'Too many options found. Specify session data name',
        ru: 'Найдено слишком много вариантов. Уточните название свойства',
        pt: 'Muitas opções encontradas. Especifique o nome dos dados da sessão',
        cn: 'Too many options found. Specify session data name',
    },
    Between: {
        eng: 'Between',
        ru: 'Между',
        pt: 'Entre',
        cn: 'Between',
    },
    Any: {
        eng: 'Any',
        ru: 'Любое',
        pt: 'Qualquer',
        cn: 'Any',
    },
    After: {
        eng: 'After',
        ru: 'После',
        pt: 'Após',
        cn: 'After',
    },
    Before: {
        eng: 'Before',
        ru: 'До',
        pt: 'Antes',
        cn: 'Before',
    },
    'details download audio': {
        eng: 'Download recording',
        ru: 'Скачать запись',
        pt: 'Baixar gravação',
        cn: '下载音频',
    },
    'Dialogs:Report:Load:Failed': {
        eng: 'Failed to load the report',
        ru: 'Не удалось загрузить отчет',
    },
    'Dialogs:Report:Load:Repeat': {
        eng: 'Try again',
        ru: 'Повторить',
    },
};
