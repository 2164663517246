export const testTtsWidgetLocalization = {
  'TestTtsWidget: title': {
    eng: 'Speech synthesis',
    ru: 'Синтез речи',
  },
  'TestTtsWidget: subtitle 1': {
    eng: 'TTS provider and voice',
    ru: 'Провайдер и голос',
  },
  'TestTtsWidget: subtitle 2': {
    eng: 'Text to synthesize',
    ru: 'Текст для синтеза',
  },
  'TestTtsWidget: synthesis btn': {
    eng: 'Synthesize',
    ru: 'Синтезировать',
  },
  'TestTtsWidget: copy btn': {
    eng: 'Copy',
    ru: 'Скопировать',
  },
  'TestTtsWidget: close btn': {
    eng: 'Close',
    ru: 'Закрыть',
  },
  'TestTtsWidget:SSMLHint': {
    eng: 'Customize the way a phrase is spoken',
    ru: 'Управляйте звучанием реплики при помощи',
  },
  'TestTtsWidget:SSMLHint:TextInLink': {
    eng: 'with SSML',
    ru: 'разметки SSML',
  },
  'TestTtsWidget:LinkDocs': {
    eng: '#{config.botadmin.helpUrl}/telephony/speech_markup',
    ru: '#{config.botadmin.helpUrl}/telephony/speech_markup',
  },
  'TestTtsWidget: warning variable': {
    eng: 'This phrase contains variables. Replace them with the appropriate text.',
    ru: 'В этой фразе используются переменные. Вставьте вместо них подходящий текст.',
  },
  'TestTtsWidget: synthesis error': {
    eng: 'Failed to synthesize the phrase',
    ru: 'Ошибка синтеза',
  },
  'TestTtsWidget: placeholder': {
    eng: 'Default',
    ru: 'По умолчанию',
  },
  'TestTtsWidget:Text:Placeholder': {
    eng: 'To test how a phrase is spoken, enter it or click the Play icon near the a tag.',
    ru: 'Чтобы протестировать звучание реплики, введите ее или нажмите иконку воспроизведения рядом с тегом a.',
  },
};
