import { captureException, captureMessage } from '@sentry/react';
var SentryLogTransport = /** @class */ (function () {
    function SentryLogTransport(level) {
        this.level = level;
    }
    SentryLogTransport.prototype.log = function (event, context) {
        if (event.exception) {
            captureException(event.exception, {
                extra: {
                    message: event.message,
                    context: context,
                },
            });
            return;
        }
        captureMessage(event.message, { level: 'error', extra: { context: context } });
    };
    return SentryLogTransport;
}());
export { SentryLogTransport };
