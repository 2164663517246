import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Collapse } from 'reactstrap';
import classes from './CollapseCustom.module.scss';
import { IconButton, Icon } from '..';
import cn from 'classnames';
export const CustomCollapse = ({ children, title, open = false, onDelete, collapseClassName, headerClassName, headerIconClassName, headerOpenClassName, wrapperClassName, className, chevronPosition = 'left', toggleIcon = 'farChevronUp', customRightElements = null, dataTestId, }) => {
    const [innerOpen, setOpen] = useState(open);
    const chevron = (_jsx("div", { className: cn(classes['Collapse-custom-heading-icon'], headerIconClassName, {
            [classes['opened-icon']]: innerOpen,
            opened: innerOpen,
        }), children: _jsx(Icon, { name: toggleIcon }) }));
    return (_jsxs("div", { className: cn(classes['Collapse-custom__wrapper'], wrapperClassName, { open: innerOpen }), "data-test-id": dataTestId, children: [_jsxs("div", { onClick: () => setOpen(prevOpen => !prevOpen), "data-test-id": 'JGraph.RightSideMenu.Buttons.Callapse', className: cn(classes['Collapse-custom'], className, {
                    [classes['opened']]: innerOpen,
                    headerOpenClassName: innerOpen,
                }), children: [_jsx("div", { className: cn(classes['Collapse-custom-heading'], headerClassName), children: chevronPosition === 'left' ? (_jsxs(_Fragment, { children: [chevron, title] })) : (_jsxs(_Fragment, { children: [title, chevron] })) }), onDelete && _jsx(IconButton, { "data-test-id": 'Collapse:delete', name: 'farTrashAlt', onClick: onDelete }), customRightElements] }), _jsx(Collapse, { isOpen: innerOpen, className: cn(collapseClassName), children: children })] }));
};
