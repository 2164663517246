import { axios } from '../../../pipes/functions';
import { AccountEventApi, CheckboxFilter, DateTimeRangeFilter, TextFilter } from '../api';

export type FiltersList = (CheckboxFilter | DateTimeRangeFilter | TextFilter)[];
export type Filters = { filters?: FiltersList };

export default class AccountEventService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private language: string;
  private configsApi: AccountEventApi;
  filters?: Filters;
  page?: number;
  size?: number;

  constructor(accountId: number, language: string) {
    this.accountId = accountId;
    this.language = language;
    this.configsApi = new AccountEventApi(undefined, AccountEventService.BASE_PATH, axios);
  }

  getAccountEventsByFilters = async (page?: number, size?: number, filtersDto?: Filters) => {
    const { accountId, language } = this;
    this.filters = filtersDto;
    this.page = page;
    this.size = size;
    const { data } = await this.configsApi.getAccountEventsByFilters(accountId, page, size, language, filtersDto);
    return data;
  };
}
