var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { InputGroup, InputText, IconButton, DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon, } from '..';
import './style.scss';
import classNames from 'classnames';
export function SearchInput(_a) {
    var { onOpen = () => { }, onClear = () => { }, onChange = () => { }, maxWidth, initiallyOpen = false, alwaysOpen = false, clearable, dropdownLeft, dropdownRight, className, size, dark, compact, PrependInner, AppendInner, buttonColor = 'primary', 'data-test-id': dataTestId = 'SearchInput' } = _a, restProps = __rest(_a, ["onOpen", "onClear", "onChange", "maxWidth", "initiallyOpen", "alwaysOpen", "clearable", "dropdownLeft", "dropdownRight", "className", "size", "dark", "compact", "PrependInner", "AppendInner", "buttonColor", 'data-test-id']);
    const [isOpened, setOpened] = useState(initiallyOpen);
    const inputRef = useRef(null);
    const open = () => {
        setOpened(true);
        onOpen();
    };
    useEffect(() => {
        if (isOpened && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpened]);
    const close = useCallback(() => {
        setOpened(false);
        onChange('');
        onClear();
    }, [onChange, onClear]);
    const clear = useCallback(() => {
        onChange('');
        onClear();
    }, [onChange, onClear]);
    const appends = useMemo(() => {
        let appendsArr = AppendInner && AppendInner.length > 0 ? [...AppendInner] : [];
        if (clearable || !alwaysOpen) {
            appendsArr.unshift({
                name: 'faTimesCircle',
                color: 'secondary',
                onClick: alwaysOpen ? clear : close,
                'data-test-id': `${dataTestId}.CloseButton`,
                size: compact ? 'sm' : size,
            });
        }
        return appendsArr;
    }, [AppendInner, alwaysOpen, clear, clearable, close, compact, size]);
    const prepends = useMemo(() => {
        let prependsArr = PrependInner && PrependInner.length > 0 ? [...PrependInner] : [];
        if (!dropdownLeft) {
            prependsArr.unshift({
                name: 'farSearch',
                color: 'secondary',
                'data-test-id': `${dataTestId}.SearchButton`,
                size: compact ? 'sm' : size || 'md',
            });
        }
        return prependsArr;
    }, [PrependInner, compact, dropdownLeft, size]);
    const dropdownRightContent = useMemo(() => {
        if (!dropdownRight)
            return;
        return (_jsxs(DropdownButton, { dark: dark, compact: true, flat: true, direction: 'down', children: [_jsx(DropdownToggle, { caret: true, compact: true, color: 'secondary', iconButton: true, size: size, children: _jsx(Icon, { className: 'btn-icon', color: 'none', name: dropdownRight.iconName, size: size }) }), _jsx(DropdownMenu, { children: dropdownRight.options.map(option => (_jsx(DropdownItem, Object.assign({}, option)))) })] }));
    }, [dropdownRight, size, dark]);
    const dropdownLeftContent = useMemo(() => {
        if (!dropdownLeft)
            return;
        return (_jsxs(DropdownButton, { dark: dark, compact: true, flat: true, direction: 'down', children: [_jsx(DropdownToggle, { caret: true, compact: true, color: 'secondary', iconButton: true, size: size, children: _jsx(Icon, { className: 'btn-icon', color: 'none', name: dropdownLeft.iconName, size: size }) }), _jsxs(DropdownMenu, { children: [dropdownLeft.options.map(option => (_jsx(DropdownItem, Object.assign({}, option)))), _jsx(DropdownItem, { header: true, children: "Header" }), _jsx(DropdownItem, { children: "Action" }), _jsx(DropdownItem, { disabled: true, children: "Action" })] })] }));
    }, [dropdownLeft, size, dark]);
    return alwaysOpen || isOpened ? (_jsx(InputGroup, { Prepend: dropdownLeftContent, Append: dropdownRightContent, PrependInner: prepends, AppendInner: appends, className: classNames('justui_search-input', 'justui_search-input__input-group', { compact: compact, dark: dark }, className), style: {
            width: maxWidth || '100%',
            maxWidth: maxWidth ? maxWidth : undefined,
        }, size: size, children: _jsx(InputText, Object.assign({ innerRef: inputRef, onChange: onChange, compact: compact, "data-test-id": dataTestId, className: classNames({
                'right-sharp': dropdownRight,
                'left-sharp': dropdownLeft,
            }), dark: dark }, restProps)) })) : (_jsx(IconButton, { name: 'faSearch', "data-test-id": `${dataTestId}.SearchButton`, className: classNames('justui_search-input', 'justui_search-input__search-icon', className), onClick: open, size: size, withoutPadding: compact, color: buttonColor, flat: true }));
}
