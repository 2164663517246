import { composeSubComponents } from 'utils/helpFunctions';

import { GlobalSearchOpenedByButton$, ToggleGlobalSearch$ } from './hooks';
import { JGSearchBtn } from './JGSearchBtn';

export type { SearchFilterState } from './JGSearchBtn';

export default composeSubComponents(JGSearchBtn, {
  GlobalSearchOpenedByButton$,
  ToggleGlobalSearch$,
});
