import {
  GET_FULL_REPORT_PENDING,
  GET_FULL_REPORT_FULFILLED,
  GET_FULL_REPORT_REJECTED,
  RESET,
} from '../../constants/callTaskReport.actions';

const InitialState = {
  report: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function CallTaskReportReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_FULL_REPORT_PENDING:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };

    case GET_FULL_REPORT_FULFILLED:
      return {
        ...state,
        report: action.payload.data,
        fetched: true,
        fetching: false,
      };

    case GET_FULL_REPORT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data,
      };

    case RESET:
      return InitialState;

    default:
      return state;
  }
}
