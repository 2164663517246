export const intentspageLocalization = {
  'IntentsPage:intent': {
    eng: 'intent',
    ru: 'интент',
    cn: '意图',
  },
  'IntentsPage:intent_0': {
    eng: 'intent',
    ru: 'интент',
    cn: '意图',
  },
  'IntentsPage:intent_1': {
    eng: 'intent',
    ru: 'интента',
    cn: '意图',
  },
  'IntentsPage:intent_2': {
    eng: 'intents',
    ru: 'интентов',
    cn: '意图',
  },
  'IntentsPage:intentWithCount': {
    eng: '{{count}} $t(IntentsPage:intent, { "count": {{count}} })',
    ru: '{{count}} $t(IntentsPage:intent, { "count": {{count}} })',
  },
  'IntentsPage:treeSearchNotFound': {
    eng: 'Not found',
    ru: 'Не найдено',
    cn: '未找到',
  },
  'IntentsPage:treeEmptyList': {
    eng: 'List of project intents',
    ru: 'Список ваших интентов',
    cn: '项目意图列表',
  },
  'IntentsPage:dropdownCreateNestedIntent': {
    eng: 'Create nested intent',
    ru: 'Создать вложенный интент',
    cn: '创建嵌套意图',
  },
  'IntentsPage:dropdownDeleteIntent': {
    eng: 'Delete intent',
    ru: 'Удалить интент',
    cn: '删除意图',
  },
  'IntentsPage:toolbarButtonCreateIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
    cn: '创建意图',
  },
  'IntentsPage:toolbarButtonImport': {
    eng: 'Import',
    ru: 'Импорт',
    cn: '导入',
  },
  'IntentsPage:intentNotExist': {
    eng: 'Intent «{{name}}» not exist',
    ru: 'Интента «{{name}}» не существует',
    cn: '意图 «{{name}}» 不存在',
  },
  'IntentsPage:buttonCreateGhostIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
    cn: '创建意图',
  },
  'IntentsPage:buttonCreateTopLevelIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
    cn: '创建意图',
  },
  'IntentsPage:selectParent_placeholder': {
    eng: 'Select parent',
    ru: 'Выбрать родителя',
  },
  'IntentsPage:NoParent': {
    eng: 'No parent',
    ru: 'Без родителя',
  },
  'IntentsPage:intentsSelected_0': {
    eng: 'Selected $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    ru: 'Выбран $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    cn: '已选择$t(IntentsPage:intentWithCount, { "count": {{count}} })',
  },
  'IntentsPage:intentsSelected_1': {
    eng: 'Selected $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    ru: 'Выбрано $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    cn: '已选择$t(IntentsPage:intentWithCount, { "count": {{count}} })',
  },
  'IntentsPage:intentsSelected_2': {
    eng: 'Selected $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    ru: 'Выбрано $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    cn: '已选择$t(IntentsPage:intentWithCount, { "count": {{count}} })',
  },
  'IntentsPage:deleteButton': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'IntentsPage:openTestWidgetButton': {
    eng: 'Test',
    ru: 'Тестировать',
    cn: '测试',
  },
  'IntentsPage:modalDeleteIntentTitle': {
    eng: 'Delete intent',
    ru: 'Удаление интента',
    cn: '删除意图',
  },
  'IntentsPage:modalDeleteIntentTitle_0': {
    eng: 'Delete {{count}} intent',
    ru: 'Удаление {{count}} интента',
    cn: '删除 {{count}} 意图',
  },
  'IntentsPage:modalDeleteIntentTitle_1': {
    eng: 'Delete {{count}} intents',
    ru: 'Удаление {{count}} интентов',
    cn: '删除{{count}}意图',
  },
  'IntentsPage:modalDeleteIntentTitle_2': {
    eng: 'Delete {{count}} intents',
    ru: 'Удаление {{count}} интентов',
    cn: '删除{{count}}意图',
  },
  'IntentsPage:modalDeleteIntentActionButton': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'IntentsPage:modalDeleteIntentCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'IntentsPage:modalDeleteIntentMessage': {
    eng: 'Are you sure you want to remove intent along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить интент вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要删除意图以及相关的训练短语？此操作无法回滚恢复。',
  },
  'IntentsPage:modalDeleteIntentMessage_0': {
    eng: 'Are you sure you want to remove intent along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $t(IntentsPage:intentWithCount, { "count": {{count}} }) вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要删除意图以及相关的训练短语？此操作无法回滚恢复。',
  },
  'IntentsPage:modalDeleteIntentMessage_1': {
    eng: 'Are you sure you want to remove $t(IntentsPage:intentWithCount, { "count": {{count}} }) along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $t(IntentsPage:intentWithCount, { "count": {{count}} }) вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要移除 $t(IntentsPage:intentWithCount, { "总数": {{count}} }) 以及相关的训练短语？此操作无法回滚恢复。',
  },
  'IntentsPage:modalDeleteIntentMessage_2': {
    eng: 'Are you sure you want to remove $t(IntentsPage:intentWithCount, { "count": {{count}} }) along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $t(IntentsPage:intentWithCount, { "count": {{count}} }) вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要移除 $t(IntentsPage:intentWithCount, { "总数": {{count}} }) 以及相关的训练短语？此操作无法回滚恢复。',
  },
  'IntentsPage:modalImportIntentsTitle': {
    eng: 'Import intents',
    ru: 'Импорт интентов',
    cn: '导入意图',
  },
  'IntentsPage:modalImportIntentsActionButton': {
    eng: 'Import',
    ru: 'Импорт',
    cn: '导入',
  },
  'IntentsPage:modalImportIntentsCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'IntentsPage:modalImportSelectFileCaption': {
    eng: 'Select a file to import intents',
    ru: 'Выберите файл для импорта интентов',
    cn: '选择文件以导入意图',
  },
  'IntentsPage:modalImportErrorImport': {
    eng: 'An error occurred while importing the intents',
    ru: 'Произошла ошибка при импорте интентов',
    cn: '发生了一个错误',
  },
  'IntentsPage:intentCreatingError': {
    eng: 'An error occurred while creating the intent',
    ru: 'Произошла ошибка при создании интента',
    cn: '发生了一个错误',
  },
  'IntentsPage:modalImportErrorFileNotSelected': {
    eng: 'Error. No file selected',
    ru: 'Ошибка. Файл не выбран',
    cn: '错误. 未选择文件',
  },
  'IntentsPage:defaultNewIntentName': {
    eng: 'New intent',
    ru: 'Новый интент',
    cn: '新意图',
  },
  'IntentsPage:intentsSelected': {
    eng: 'Selected $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    ru: 'Выбрано $t(IntentsPage:intentWithCount, { "count": {{count}} })',
    cn: '选中$t(IntentsPage:intentWithCount, { "count": {{count}} })',
  },
  'TestPublishTrainWidget:trainNLU': {
    eng: 'Train',
    ru: 'Обучить',
  },
  'IntentsPage:answer:validationTooltip': {
    eng: 'Other intents have similar answers',
    ru: 'В других интентах есть похожие ответы',
  },
  'PhraseBlocks:validationTooltip': {
    eng: 'Other intents already have similar phrases',
    ru: 'В других интентах уже есть похожие фразы',
  },
  'intentValErrorPanel.answers:header': {
    eng: 'Other intents have similar answers',
    ru: 'В других интентах есть похожие ответы',
  },
  'intentValErrorPanel.answers:info': {
    eng: 'We recommend you rewrite this answer or move it to another intent. Make sure the content of the intents is not the same.',
    ru: 'Рекомендуем переписать этот ответ или переместить его в другой интент. Убедитесь, что содержание интентов не пересекается.',
  },
  'intentValErrorPanel.questions:header': {
    eng: 'Other intents have similar phrases',
    ru: 'В других интентах есть похожие фразы',
  },
  'intentValErrorPanel.questions:info': {
    eng: 'We recommend you rewrite this phrase or move it to another intent. Make sure the content of the intents is not the same.',
    ru: 'Рекомендуем переписать эту фразу или переместить ее в другой интент. Убедитесь, что содержание интентов не пересекается.',
  },
  'intentValErrorPanel.similarity': {
    eng: 'similarity',
    ru: 'сходства',
  },
};
