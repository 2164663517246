import React, { memo } from 'react';
import { Icon } from '@just-ai/just-ui';
import cn from 'classnames';
import { t } from 'localization';
import classes from './styles.module.scss';

export const IntentItemPlaceholder = memo(() => {
  return (
    <div className={cn('d-flex flex-column gap-8', classes.IntentItemPlaceholder)}>
      <Icon name='farList' size='2x' wrapperClassName='d-flex' className='color-gray-500' />
      <p className={cn('color-gray-600', classes.IntentItemPlaceholder_text)}>{t('IntentItemPlaceholder:text')}</p>
    </div>
  );
});

IntentItemPlaceholder.displayName = 'memo(IntentItemPlaceholder)';
