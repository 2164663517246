export default function shortPulling(cb, { delay = 100, timeout = 1000 } = {}) {
    const endTime = Date.now() + timeout;
    return new Promise((resolve, reject) => {
        const checkCondition = () => {
            if (Date.now() > endTime) {
                resolve(undefined);
                return;
            }
            try {
                const result = cb();
                if (result !== undefined) {
                    resolve(result);
                    return;
                }
                setTimeout(checkCondition, delay);
            }
            catch (e) {
                reject(e);
            }
        };
        checkCondition();
    });
}
