export const accountsAdminNotificationsLocalization = {
  'BillingReportTask:Title:pending': {
    eng: 'Report is being generated',
    ru: 'Отчет формируется',
  },
  'BillingReportTask:Title:success': {
    eng: 'Report is generated',
    ru: 'Отчет сгенерирован',
  },
  'BillingReportTask:Title:error': {
    eng: 'Error while generating report',
    ru: 'Ошибка при формировании отчета',
  },
  'BillingReportTask:Description:pending': {
    eng: 'Report {name} is being generated',
    ru: 'Отчет {name} формируется',
  },
  'BillingReportTask:Description:success': {
    eng: 'Report {name} is generated.',
    ru: 'Отчет {name} сформирован.',
  },
  'BillingReportTask:Description:error': {
    eng: 'Error while generating {name} report: {errorInfo}',
    ru: 'Ошибка при формировании отчета {name}: {errorInfo}',
  },
  'BillingReportTask:DownloadReport': {
    eng: 'Download report',
    ru: 'Скачать отчет',
  },
  'BillingReportTask:NotificationListEmpty': {
    eng: 'The notification list is empty',
    ru: 'Список уведомлений пуст',
  },
  'BillingReportTask:DeleteAllNotification': {
    eng: 'Delete all',
    ru: 'Удалить все',
  },
  'BillingReportTask:Notification': {
    eng: 'Notifications',
    ru: 'Уведомления',
  },
};
