import React, { Fragment, useMemo } from 'react';
import localize from '../../../localization';
import { InputText, Select, FormText } from '@just-ai/just-ui';
import { DropdownMenu } from 'reactstrap';

const REPO_LOCATION_TAB = '2';

export function BaseDescriptionJAICF({
  editableProject,
  nameRef,
  onValidateForm,
  customStyles,
  environments,
  environment,
  changeEnvironment,
  initialCodeValues,
  initialCode,
  changeInitialCode,
  setActiveTab,
  botTemplates,
  changeProjectTemplate,
  selectedProjectTemplate,
  isEdit,
  externalTemplateRef,
  filteredTemplates,
  externalTemplate,
  changeExternalTemplate,
}) {
  const openRepoLocationTab = e => {
    if (e?.target?.tagName?.toUpperCase() === 'A' && e.target?.href.endsWith('#')) {
      setActiveTab(REPO_LOCATION_TAB);
    }
  };
  const botTemplatesOptions = filteredTemplates.map(botTemplate => {
    return { value: botTemplate.name, label: botTemplate.title };
  });

  const initialCodeOptions = useMemo(() => {
    return environment === 'jaicpCloud'
      ? initialCodeValues
      : initialCodeValues.filter(code => code.value !== 'connect');
  }, [initialCodeValues, environment]);

  const selectedProjectTemplateSummary = Boolean(selectedProjectTemplate)
    ? botTemplates.find(botTemplate => botTemplate.name === selectedProjectTemplate.value).summary
    : null;
  return (
    <Fragment>
      {/*NAME*/}
      <div className='form-group row'>
        <label className='col-md-4 form-control-label form-control-label-jaicf' htmlFor='name'>
          <span>
            {localize.translate('jaicf form field name')}
            <span className='red-dot-jaicf'>*</span>
          </span>
        </label>
        <div className='col-md-8'>
          <InputText
            type='text'
            name='name'
            maxLength='30'
            id='name'
            required
            placeholder={localize.translate('Description')}
            defaultValue={Boolean(editableProject) ? editableProject.name : ''}
            innerRef={nameRef}
            onChange={onValidateForm}
            data-test-id='projectsPage.createProjectModal.nameInputText'
            autoFocus
          />
        </div>
      </div>

      {/*ENVIRONMENT*/}
      <div className='form-group row'>
        <label className='col-md-4 form-control-label form-control-label-jaicf'>
          {localize.translate('jaicf runtime environment')}
        </label>
        <div className='col-md-8'>
          <Select
            options={environments}
            styles={customStyles}
            isDisabled={isEdit}
            onChange={changeEnvironment}
            value={environments.find(env => env.value === environment) || environments[0]}
            dropdownComponent={DropdownMenu}
            menuPosition='fixed'
            data-test-id='projectsPage.createProjectModal.environmentsSelect'
            noOptionsMessage={() => localize.translate('BotControls:not_found')}
          />
          <FormText tag='small'>
            <p
              dangerouslySetInnerHTML={{
                __html: localize.translate(
                  `jaicf runtime environment ${environment} help text`,
                  localize.translate('ProjectEditForm tabs placement')
                ),
              }}
              onClick={openRepoLocationTab}
            />
          </FormText>
        </div>
      </div>

      {/*INITIAL CODE*/}
      {!isEdit && Boolean(environment) && environment !== 'external' && (
        <div className='form-group row'>
          <label className='col-md-4 form-control-label form-control-label-jaicf'>
            {localize.translate('project initial code')}
          </label>
          <div className='col-md-8'>
            <Select
              options={initialCodeOptions}
              styles={customStyles}
              onChange={changeInitialCode}
              value={initialCodeValues.find(code => code.value === initialCode)}
              dropdownComponent={DropdownMenu}
              menuPosition='fixed'
              data-test-id='projectsPage.createProjectModal.initialCodeSelect'
              noOptionsMessage={() => localize.translate('BotControls:not_found')}
            />
            <FormText tag='small'>
              <p
                dangerouslySetInnerHTML={{
                  __html: localize.translate(
                    `jaicf initial code ${initialCode} template help text${
                      initialCode === ' connect' ? ' with link' : ''
                    }`,
                    localize.translate('ProjectEditForm tabs placement')
                  ),
                }}
                onClick={openRepoLocationTab}
              />
            </FormText>
            {initialCode === 'system' && (
              <>
                <Select
                  options={botTemplatesOptions}
                  isMulti={false}
                  isClearable
                  isDisabled={isEdit}
                  onChange={changeProjectTemplate}
                  placeholder={localize.translate('Choose template')}
                  value={selectedProjectTemplate}
                  data-test-id='projectsPage.createProjectModal.templatesSelect'
                  menuPosition='fixed'
                  noOptionsMessage={() => localize.translate('BotControls:not_found')}
                />
                <FormText tag='small'>{selectedProjectTemplateSummary}</FormText>
              </>
            )}
            {initialCode === 'external' && (
              <InputText
                type='text'
                name='externalTemplate'
                innerRef={externalTemplateRef}
                onChange={changeExternalTemplate}
                required
                value={externalTemplate}
                placeholder={localize.translate('jaicf initial code external template placeholder')}
                pattern='https?://.+'
                data-test-id='projectsPage.createProjectModal.externalTemplate'
              />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
