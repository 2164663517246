import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { TJBlock, TagNames } from 'modules/JGraph/utils/types';

function getAscendingBlockIndex(blocks: TJBlock[], currentIndex: number, tag: TagNames) {
  let iterator = currentIndex - 1;
  while (iterator > 0) {
    if (blocks[iterator].tagName === tag) {
      return iterator;
    }
    iterator -= 1;
  }
  return 0;
}

function getIfElseifCount(blocks: TJBlock[], index: number) {
  let innerIndex = index + 1;
  let count = 1;
  while (innerIndex < blocks.length && [TagNames.else, TagNames.elseif].includes(blocks[innerIndex].tagName)) {
    count += 1;
    innerIndex += 1;
  }
  return count;
}

export class ScreenBlockPath {
  static isPathRecursive(path = ''): boolean {
    return path.includes('if') || path.includes('else') || path.includes('random');
  }

  static getBlockByPathAndIndex(blocks: TJBlock[], path?: string, index?: number): TJBlock | undefined {
    if (ScreenBlockPath.isPathContainsIfElse(path)) {
      const [block] = ScreenBlockPath.getMainIfBlockAndIndex(path, index, blocks);
      if (block) {
        const numberPath = ScreenBlockPath.getRecursiveNumberPath(path);
        const possibleBlockToEdit = ScreenBlockPath.getBlockByNumberPath(blocks, numberPath, index);
        if (
          possibleBlockToEdit &&
          ![TagNames.if, TagNames.else, TagNames.elseif].includes(possibleBlockToEdit.tagName)
        ) {
          return possibleBlockToEdit;
        }
        return block;
      }
    }
    if (path) {
      const numberPath = ScreenBlockPath.getRecursiveNumberPath(path);
      return ScreenBlockPath.getBlockByNumberPath(blocks, numberPath, index);
    }
    if (index !== undefined && blocks[index]) {
      return blocks[index];
    }
    return;
  }

  static getBlockByPath(blocks: TJBlock[], path: string, index?: number): TJBlock | undefined {
    const numberPath = ScreenBlockPath.getRecursiveNumberPath(path);
    return ScreenBlockPath.getBlockByNumberPath(blocks, numberPath, index);
  }

  static getRecursiveNumberPath(path = '') {
    const innerPath = path.replace(/\d+_if_(\d+)_else/g, (match, g1) => g1);
    const numberPathString = innerPath.split('_');
    return numberPathString.map(value => parseInt(value)).filter(value => Number.isInteger(value));
  }

  static getBlockByNumberPath(blocks: TJBlock[] = [], path: number[], clickedBlockIndex?: number): TJBlock | undefined {
    let tmpBlock: TJBlock | undefined;
    let tmpBlocks: TJBlock[] = [];

    path.forEach(blockIndex => {
      let innerBlock = (tmpBlocks.length > 0 ? tmpBlocks : blocks)[blockIndex];
      if (innerBlock) {
        tmpBlock = innerBlock;
        tmpBlocks = innerBlock.jblocks;
      }
    });
    if (clickedBlockIndex !== undefined && tmpBlocks && tmpBlocks[clickedBlockIndex]) {
      return tmpBlocks[clickedBlockIndex];
    }
    return tmpBlock;
  }

  static joinIndexToPath(path = '', tag: TagNames, index?: number, blocks?: TJBlock[]) {
    if (index === undefined) return path;
    const isItElseOrElseIf = [TagNames.else, TagNames.elseif].includes(tag);
    let innerPrefix =
      isItElseOrElseIf && blocks ? ScreenBlockPath.getAscendingBlockPath(blocks, index, TagNames.if) : '';

    return `${path}${innerPrefix}${index}_${tag}_`;
  }

  static isRecursivePathStillExists(state: JStateWithId, path?: string) {
    if (path && this.isPathRecursive(path)) {
      return !!ScreenBlockPath.getBlockByPath(state?.blocks, path);
    }
    return false;
  }

  static getMainIfBlockAndIndex(
    path: string = '',
    jBlockIndex: number = 0,
    editMenuBlocks: TJBlock[] = []
  ): [TJBlock | undefined, number | undefined] {
    if (!path && editMenuBlocks[jBlockIndex].tagName === TagNames.if) {
      return [editMenuBlocks[jBlockIndex], jBlockIndex];
    }

    const splitPath = parseInt(path.split('_')[0]);
    if (editMenuBlocks[splitPath]) {
      return [editMenuBlocks[splitPath], splitPath];
    }
    return [undefined, undefined];
  }

  static isPathContainsIfElse(path = '') {
    return path.includes('if') || path.includes('else');
  }

  static getCurrentIfBlock(blocks: TJBlock[], path = '', index?: number) {
    const possibleBlockEditPath = ScreenBlockPath.getCurrentIfBlockNumberPath(path);
    return ScreenBlockPath.getBlockByNumberPath(blocks, possibleBlockEditPath, index);
  }

  static getCurrentIfBlockNumberPath(path = '') {
    const innerPath = path.replace(/\d+_if_(\d+)_else/g, (match, g1) => g1);
    return innerPath
      .split('_')
      .map(value => parseInt(value))
      .filter(value => Number.isInteger(value));
  }

  static getIfChainBlocks(mainIfIndex: number, screenBlocks: TJBlock[]): TJBlock[] {
    let tmpBlocks = [screenBlocks[mainIfIndex]];
    let innerIndex = mainIfIndex + 1;
    while (screenBlocks[innerIndex] && [TagNames.else, TagNames.elseif].includes(screenBlocks[innerIndex].tagName)) {
      tmpBlocks.push(screenBlocks[innerIndex]);
      innerIndex += 1;
    }
    return tmpBlocks;
  }

  static getAscendingBlockPath(blocks: TJBlock[], currentIndex: number, tag: TagNames) {
    const index = getAscendingBlockIndex(blocks, currentIndex, tag);
    return `${index}_${tag}_`;
  }

  static getBlockWithNeighborsCount(blocks: TJBlock[], index: number): number {
    const block = blocks[index];
    if (!block) return 0;
    switch (block.tagName) {
      case TagNames.if:
        return getIfElseifCount(blocks, index);
      default:
        return 1;
    }
  }

  static separateIndexFromPath(path: string): [number | undefined, string | undefined] {
    const numberPath = ScreenBlockPath.getRecursiveNumberPath(path);
    if (numberPath.length === 0) return [undefined, path];
    const paths = path.split('_');
    const newPath = paths.slice(0, paths.length - 3).join('_');
    return [numberPath[numberPath.length - 1], newPath];
  }
}
