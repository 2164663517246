export default class HTMLService {
    downloadLink(href, fileName) {
        const linkElement = document.createElement('a');
        linkElement.href = href;
        linkElement.setAttribute('download', fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    }
}
