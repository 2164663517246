import React, { FC, ReactText, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useRightSideMenuContext } from '../index';
import {
  InputText,
  Label,
  InlineRadioButton,
  Option,
  IconButton,
  Message,
  SwitchButton,
  useToggle,
  CustomCollapse,
} from '@just-ai/just-ui';
import { UncontrolledTooltip } from 'reactstrap';
import { useAppSelector } from '../../../../../storeHooks';
import { get } from 'lodash';
import { t } from 'localization';
import { TButtonParameters } from 'modules/JGraph/utils/types';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { session } from '../../../utils/insertSessionFunction';
import { getAllStates } from '../../../../../reducers/JGraph.reducer/Graph';
import { CreateOrChooseState } from '../../../components/CreateOrChooseState';
import { AddBlockButton } from '../../../components/AddBlockButton';
import keyboardjs from 'keyboardjs';

const ButtonSettings: FC<{ index: number; tag: TButtonParameters; active: boolean }> = ({ tag, active, index }) => {
  const { onAddNewButton } = useRightSideMenuContext();

  const { graph } = useAppSelector(state => ({
    graph: state.JGraphReducer.graph,
  }));
  const allStates = useMemo(() => {
    let returnOptions: OptionType[] = getAllStates(graph.blocks).map(state => ({
      value: state,
      label: hideRootSlashInPath(state),
    }));
    return returnOptions;
  }, [graph.blocks]);
  const LinkInputRef = useRef<HTMLInputElement>(null);
  const InputRef = useRef<HTMLInputElement>(null);

  const { onChangeButtons, deleteButton, isForCampaign } = useRightSideMenuContext();
  const [toggleActive, setToggleActive] = useState(!!(tag.transition || tag.url));

  const [activeTab, setActiveTab] = useState<string | number | undefined>(
    isForCampaign ? 'link' : tag.transition ? 'state' : tag.url ? 'link' : 'state'
  );

  const [isInFocus, setIsFocus, setBlured] = useToggle(false);

  const setToggleActiveHandle = useCallback((flag: boolean) => {
    setToggleActive(flag);
  }, []);

  const tabChange = useCallback((_, tab: Option) => {
    setActiveTab(tab.value);
  }, []);

  const deleteButtonHandler = useCallback(() => {
    deleteButton(index);
  }, [deleteButton, index]);

  const openUrl = useCallback(() => {
    tag.url && window.open(tag.url, '_blank');
  }, [tag]);

  const onTransitionChange = (value: ReactText) => {
    tag.jsonObjects = null;
    tag.url = null;
    tag.transition = String(value);
    onChangeButtons(tag, index);
  };

  const onUrlChange = (value: string) => {
    tag.transition = null;
    tag.url = session.insert(value);
    tag.jsonObjects = null;
    onChangeButtons(tag, index);
  };

  const onLinkInputClear = useCallback(() => {
    if (LinkInputRef.current) {
      LinkInputRef.current.value = '';
    }
  }, []);
  const onNameChange = useCallback(
    (value: string) => {
      tag.name = value;
      onChangeButtons(tag, index);
    },
    [index, onChangeButtons, tag]
  );

  useEffect(() => {
    tag.name === '' && onNameChange(t('ButtonSettings-button-placeholder', index + 1));
  }, [index, onNameChange, tag.name]);

  useEffect(() => {
    if (isInFocus) {
      keyboardjs.bind('enter', onAddNewButton);
    }
    return () => {
      keyboardjs.unbind('enter', onAddNewButton);
    };
  }, [isInFocus, onAddNewButton]);

  useEffect(() => {
    InputRef.current && InputRef.current.select();
  }, []);

  const isTagUrlWith$Session = tag.url?.includes('{{$');

  return (
    <CustomCollapse
      title={tag.name}
      open={active}
      collapseClassName='ButtonSettings-collapse-custom'
      onDelete={deleteButtonHandler}
    >
      <div className='inner-content no-border'>
        <Label>{t('ButtonSettings-buttonName')}</Label>
        <InputText
          innerRef={InputRef}
          placeholder={t('ButtonSettings-button-placeholder', index + 1)}
          type='text'
          data-test-id={`JGraph.RightSideMenu.Buttons.Text:${index}`}
          defaultValue={tag.name || t('ButtonSettings-button-placeholder', index + 1)}
          autoFocus
          onChange={onNameChange}
          onFocus={setIsFocus}
          onBlur={setBlured}
        />
      </div>
      <div className='divider' />
      <div className='pt-5 inner-content' data-test-id={`JGraph.RightSideMenu.Buttons.Settings:${index}`}>
        {isForCampaign && (
          <InputText defaultValue={tag.url ? tag.url : undefined} placeholder='/' fullWidth onChange={onUrlChange} />
        )}
        {!isForCampaign && (
          <>
            <SwitchButton
              id={`switch-button-${index}`}
              label={t('ButtonSettings-reaction')}
              data-test-id={`JGraph.RightSideMenu.Buttons.SwitchAction:${index}`}
              labelPosition='left'
              onChange={setToggleActiveHandle}
              size='md'
              value={toggleActive}
            />
            {toggleActive && (
              <>
                <div
                  className='ButtonSettings-reaction-tabs'
                  data-test-id={`JGraph.RightSideMenu.Buttons.toggleActive:${index}`}
                >
                  <InlineRadioButton
                    active={activeTab}
                    color='primary'
                    name='buttonType'
                    onChange={tabChange}
                    options={[
                      {
                        name: t('ButtonSettings-state-state'),
                        value: 'state',
                        dataTestId: 'ButtonSettings-state-state',
                      },
                      { name: t('ButtonSettings-state-link'), value: 'link', dataTestId: 'ButtonSettings-state-link' },
                    ]}
                    outline
                    size='xs'
                  />
                </div>
                {activeTab === 'state' && (
                  <CreateOrChooseState
                    selectOptions={allStates}
                    onSelectChange={onTransitionChange}
                    value={tag.transition || ''}
                  />
                )}
                {activeTab === 'link' && (
                  <>
                    <div className='ButtonSettings-reaction-tab-link'>
                      <InputText
                        innerRef={LinkInputRef}
                        placeholder={t('ButtonSettings-state-link')}
                        defaultValue={session.parse(tag.url || '')}
                        onChange={onUrlChange}
                        clearable
                        onClear={onLinkInputClear}
                        data-test-id={`ButtonSettings-state-link-input_${index}`}
                      />
                      <IconButton
                        name='farLink'
                        onClick={openUrl}
                        disabled={isTagUrlWith$Session}
                        id={`ButtonSetting-url-tag_${index}`}
                      />
                      {isTagUrlWith$Session && (
                        <UncontrolledTooltip target={`ButtonSetting-url-tag_${index}`} placement='left'>
                          {t('ButtonSettingItem-tooltip')}
                        </UncontrolledTooltip>
                      )}
                    </div>
                    {isTagUrlWith$Session && <Message type='info' text={t('ButtonSettings bot runtime link')} />}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </CustomCollapse>
  );
};

export const ButtonsSettings: FC<{}> = () => {
  const { editMenuScreen, getEditBlockTag, jBlockIndex, path, onAddNewButton } = useRightSideMenuContext();
  if (!editMenuScreen || jBlockIndex === undefined) return null;
  const buttonsTag = getEditBlockTag();
  return (
    <>
      {((buttonsTag?.tagParameters || []) as TButtonParameters[]).map((tag, index) => {
        let getPathArr = (path || '').split('_');

        return (
          <ButtonSettings
            key={`${editMenuScreen.pathId}${jBlockIndex}${index}`}
            index={index}
            tag={tag}
            active={tag === get(buttonsTag, getPathArr[getPathArr.length - 1]) || tag.name === ''}
          />
        );
      })}
      <div className='ButtonsSettings-addNewButton'>
        <AddBlockButton
          onClick={onAddNewButton}
          disabled={((getEditBlockTag() || {})?.tagParameters || []).length >= 8}
          text={t('ButtonsSettings add button')}
        />
      </div>
    </>
  );
};
