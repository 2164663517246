import classNames from 'classnames';
import { t, tWithCheck } from 'localization';
import React, { FC, useCallback } from 'react';
import classes from './CreationScreenMenu.module.scss';
import {
  BotResponsesDefault,
  LogicResponsesDefault,
  MetaResponsesDefault,
} from '../RightSideMenu/RSAddingMenu/responses';
import { Icon } from '@just-ai/just-ui';
import { TReactionsTagNames } from '../../utils/types';
import { mapIconToTagName } from '../RightSideMenu/settings/ScreenBlocksList/ReactionItem';

const AddingBlockItem: FC<{
  reaction: TReactionsTagNames;
  onSelectReaction: (value: TReactionsTagNames) => unknown;
  dataTestId?: string;
}> = ({ reaction, onSelectReaction, dataTestId }) => {
  const onSelectReactionHandler = useCallback(() => {
    onSelectReaction(reaction);
  }, [onSelectReaction, reaction]);

  return (
    <div className={classes.addingItem} onClick={onSelectReactionHandler} data-test-id={dataTestId}>
      <Icon
        color='secondary'
        className={classes.reactionIcon}
        name={mapIconToTagName[reaction as TReactionsTagNames] || 'farCube'}
      />
      <div className={classes.reactionName}>
        {tWithCheck(`CustomTag:${reaction}:caption`) || tWithCheck(`ReactionItem ${reaction}`) || reaction}
      </div>
    </div>
  );
};

export const AddingSimpleBlock: FC<{
  type: 'meta' | 'reactions';
  onSelectReaction: (value: TReactionsTagNames) => unknown;
}> = ({ onSelectReaction, type }) => {
  return (
    <div className={classNames(classes.AddingSimpleBlock, classes[type] || '')}>
      <div className={classes.container}>
        {type === 'reactions' && (
          <>
            <div className={classNames(classes.addingItem, classes.addingItemTitle)}>
              {t('AddingSimpleBlock:reactionsGroup')}
            </div>
            {BotResponsesDefault.map((reaction, index) => (
              <AddingBlockItem
                key={'BotResponsesDefault_' + index}
                reaction={reaction}
                onSelectReaction={onSelectReaction}
                dataTestId={`BotResponsesDefault_${reaction}`}
              />
            ))}
            <div className={classNames(classes.addingItem, classes.addingItemTitle)}>
              {t('AddingSimpleBlock:logicGroup')}
            </div>
            {LogicResponsesDefault.map((reaction, index) => (
              <AddingBlockItem
                key={'LogicResponsesDefault' + index}
                reaction={reaction}
                onSelectReaction={onSelectReaction}
                dataTestId={`LogicResponsesDefault_${reaction}`}
              />
            ))}
          </>
        )}
        {type === 'meta' && (
          <>
            {MetaResponsesDefault().map((reaction, index) => (
              <AddingBlockItem
                key={'MetaResponsesDefault' + index}
                reaction={reaction}
                onSelectReaction={onSelectReaction}
                dataTestId={`MetaResponsesDefault_${reaction}`}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
