const prefix = '@tasks/';

export const TASKS_LOAD = prefix + 'TASKS_LOAD';
export const TASKS_LOAD_FULFILLED = prefix + 'TASKS_LOAD_FULFILLED';
export const TASKS_LOAD_PENDING = prefix + 'TASKS_LOAD_PENDING';
export const TASKS_LOAD_REJECTED = prefix + 'TASKS_LOAD_REJECTED';

export const TOTAL_ELEMENTS_LOAD = prefix + 'TOTAL_ELEMENTS_LOAD';
export const TOTAL_ELEMENTS_LOAD_FULFILLED = prefix + 'TOTAL_ELEMENTS_LOAD_FULFILLED';
export const TOTAL_ELEMENTS_LOAD_PENDING = prefix + 'TOTAL_ELEMENTS_LOAD_PENDING';
export const TOTAL_ELEMENTS_LOAD_REJECTED = prefix + 'TOTAL_ELEMENTS_LOAD_REJECTED';

export const FILTER_SETTINGS = prefix + 'FILTER_SETTINGS';
export const FILTER_SETTINGS_FULFILLED = prefix + 'FILTER_SETTINGS_FULFILLED';
export const FILTER_SETTINGS_PENDING = prefix + 'FILTER_SETTINGS_PENDING';
export const FILTER_SETTINGS_REJECTED = prefix + 'FILTER_SETTINGS_REJECTED';

export const FILTER_PRIORITY_SETTINGS = prefix + 'FILTER_PRIORITY_SETTINGS';
export const FILTER_PRIORITY_SETTINGS_FULFILLED = prefix + 'FILTER_PRIORITY_SETTINGS_FULFILLED';
export const FILTER_PRIORITY_SETTINGS_PENDING = prefix + 'FILTER_PRIORITY_SETTINGS_PENDING';
export const FILTER_PRIORITY_SETTINGS_REJECTED = prefix + 'FILTER_PRIORITY_SETTINGS_REJECTED';

export const DELETE_ISSUE = prefix + 'DELETE_ISSUE';
export const DELETE_ISSUE_FULFILLED = prefix + 'DELETE_ISSUE_FULFILLED';
export const DELETE_ISSUE_PENDING = prefix + 'DELETE_ISSUE_PENDING';
export const DELETE_ISSUE_REJECTED = prefix + 'DELETE_ISSUE_REJECTED';

export const UPDATE_ISSUE = prefix + 'UPDATE_ISSUE';
export const UPDATE_ISSUE_FULFILLED = prefix + 'UPDATE_ISSUE_FULFILLED';
export const UPDATE_ISSUE_PENDING = prefix + 'UPDATE_ISSUE_PENDING';
export const UPDATE_ISSUE_REJECTED = prefix + 'UPDATE_ISSUE_REJECTED';

export const UPDATE_SESSION_ISSUE = prefix + 'UPDATE_SESSION_ISSUE';
export const UPDATE_SESSION_ISSUE_FULFILLED = prefix + 'UPDATE_SESSION_ISSUE_FULFILLED';
export const UPDATE_SESSION_ISSUE_PENDING = prefix + 'UPDATE_SESSION_ISSUE_PENDING';
export const UPDATE_SESSION_ISSUE_REJECTED = prefix + 'UPDATE_SESSION_ISSUE_REJECTED';

export const DELETE_SESSION_ISSUE = prefix + 'DELETE_SESSION_ISSUE';
export const DELETE_SESSION_ISSUE_FULFILLED = prefix + 'DELETE_SESSION_ISSUE_FULFILLED';
export const DELETE_SESSION_ISSUE_PENDING = prefix + 'DELETE_SESSION_ISSUE_PENDING';
export const DELETE_SESSION_ISSUE_REJECTED = prefix + 'DELETE_SESSION_ISSUE_REJECTED';

export const FETCH_ISSUE_DATA = prefix + 'FETCH_ISSUE_DATA';
export const FETCH_ISSUE_DATA_FULFILLED = prefix + 'FETCH_ISSUE_DATA_FULFILLED';
export const FETCH_ISSUE_DATA_PENDING = prefix + 'FETCH_ISSUE_DATA_PENDING';
export const FETCH_ISSUE_DATA_REJECTED = prefix + 'FETCH_ISSUE_DATA_REJECTED';

export const GET_PAGE_FOR_ISSUE_LOGID = prefix + 'GET_PAGE_FOR_ISSUE_LOGID';
export const GET_PAGE_FOR_ISSUE_LOGID_FULFILLED = prefix + 'GET_PAGE_FOR_ISSUE_LOGID_FULFILLED';
export const GET_PAGE_FOR_ISSUE_LOGID_PENDING = prefix + 'GET_PAGE_FOR_ISSUE_LOGID_PENDING';
export const GET_PAGE_FOR_ISSUE_LOGID_REJECTED = prefix + 'GET_PAGE_FOR_ISSUE_LOGID_REJECTED';

export const SET_TASK_SELECT = prefix + 'SET_TASK_SELECT';

export const SET_SELECT = prefix + 'SET_SELECT';
export const MARK_TASK_AS_CHANGEABLE = prefix + 'MARK_TASK_AS_CHANGEABLE';

export const SET_STORE = prefix + 'SET_STORE';
export const SET_STORE_NEW_FILTER = prefix + 'SET_STORE_NEW_FILTER';
