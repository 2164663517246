import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { EntityMarkupData } from '../../../Caila/api/client';
import { getValueFromJson } from '../../../Caila/utils';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';

interface EntityProps {
  entityObj?: EntityMarkupData;
  isShared?: boolean;
  onNameClick: () => unknown;
  onValueClick: () => unknown;
}

const WidgetEntity: FunctionComponent<EntityProps> = ({ entityObj, isShared = false, onNameClick, onValueClick }) => {
  const { t } = i18nTranslation();

  if (!entityObj) return null;

  const { text, entity, value } = entityObj;

  const isJson = value && value[0] === '{';
  const valueFromJson = value ? getValueFromJson(value) : false;

  return (
    <div className='entity card' data-test-id='Widget.Entity'>
      <div className='entity-text' data-test-id='Widget.Entity.Text'>
        {text}
      </div>
      <div
        className={classNames('entity-name', { 'pseudo-link': !isShared })}
        style={{ cursor: isShared ? 'inherit' : 'pointer' }}
        onClick={onNameClick}
        data-test-id='Widget.Entity.Name'
      >
        @{entity}
      </div>
      <div className='entity-value text-muted'>
        <b>{t('Widget:value')}:</b>{' '}
        {value ? (
          isJson ? (
            <span className='pseudo-link curp' onClick={onValueClick} data-test-id='Widget.Entity.Value'>
              {valueFromJson ? valueFromJson : t('Widget:value')}
            </span>
          ) : (
            <span data-test-id='Widget.Entity.Value'>{value}</span>
          )
        ) : null}
      </div>
    </div>
  );
};

export default WidgetEntity;
