import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled';

import { BaseAutoLayout } from './BaseAutoLayout';

export class ElkAutoLayout extends BaseAutoLayout {
  private nodes: ElkNode[] = [];
  private edges: ElkExtendedEdge[] = [];

  private existedNodes = new Set<string>([]);
  private existedEdges = new Set<string>([]);

  addNode(node: { id: string; width: number; height: number; label: string }) {
    this.existedNodes.add(node.id);
    this.nodes.push({
      ...node,
      labels: [{ text: node.label }],
      ports: [
        {
          id: node.id + '->from',
          width: 5,
          height: 5,
          layoutOptions: {
            'port.side': 'EAST',
            allowNonFlowPortsToSwitchSides: 'true',
          },
        },
        {
          id: node.id + '->to',
          width: 5,
          height: 5,
          layoutOptions: {
            'port.side': 'WEST',
            allowNonFlowPortsToSwitchSides: 'true',
          },
        },
      ],
      layoutOptions: {
        portConstraints: 'FIXED_ORDER',
        portAlignment: 'CENTER',
      },
    });
  }

  getCountOfNode() {
    return this.nodes.length;
  }

  addEdge(from: string, to: string) {
    const name = from + '_' + to;
    if (!this.existedEdges.has(name) && this.existedNodes.has(from) && this.existedNodes.has(to)) {
      this.existedEdges.add(name);
      this.edges.push({
        id: name,
        sources: [from + '->from'],
        targets: [to + '->to'],
      });
    }
  }

  async calculate() {
    const elk = new ELK({
      workerFactory: () => new Worker('/elk-worker.js'),
    });
    const graph = {
      id: 'root',
      layoutOptions: {
        'elk.algorithm': 'layered',
        'elk.spacing.nodeNode': '120',
        'elk.spacing.edgeNode': '120',
        'elk.layered.spacing.baseValue': this.options.nodeSpacing?.toString() || '100',
        'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
        'elk.layered.layering.strategy': 'MIN_WIDTH',
        'elk.layered.layering.minWidth.upperBoundOnWidth': '-1',
        hierarchyHandling: 'INCLUDE_CHILDREN',
      },
      children: this.nodes,
      edges: this.edges,
    };

    const layout = await elk.layout(graph);

    if (!layout.children) return;

    return layout.children.map(node => ({
      id: node.id,
      height: node.height || 0,
      width: node.width || 0,
      label: node.labels?.[0].text || '',
      x: node.x || 0,
      y: node.y || 0,
    }));
  }
}
