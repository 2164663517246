import React, { FC, useCallback, useEffect, useState } from 'react';
import { TtsWidgetForm } from './components/TtsWidgetForm';
import { useAppContext } from '../Caila/components/AppContext';
import { useTestWidgetContext } from '../TestWidget';
import { TextToSpeechProviderDetail, TtsRequestDTO } from './api/client';
import { CustomProviderOption, CustomProvider } from './types';
import { prepareTextForTts, getCustomProviderOptions } from './utils';
import { amplitudeInstance, isDev } from '../../pipes/functions';

export const TestTtsWidget: FC = () => {
  const { TextToSpeechService, currentProject } = useAppContext();
  const { hideTestTtsWidget, textForTestTts, ttsProvider, ttsVoice, setTtsVoice, setTtsProvider } =
    useTestWidgetContext();

  const [text, setText] = useState('');
  const [audioString, setAudioString] = useState('');
  const [customProviderOptions, setCustomProviderOptions] = useState<CustomProviderOption[]>([]);
  const [providers, setProviders] = useState<TextToSpeechProviderDetail[]>([]);
  const [fetching, setFetching] = useState(false);
  const [isContainVariable, setIsContainVariable] = useState(false);
  const [isSynthesisError, setIsSynthesisError] = useState(false);

  useEffect(() => {
    if (isDev())
      console.log(`amplitudeInstance.logEvent('tts_test_panel_open', { has_text: ${Boolean(textForTestTts)} })`);
    amplitudeInstance.logEvent('tts_test_panel_open', { has_text: Boolean(textForTestTts) });
    const prepared = prepareTextForTts(textForTestTts);
    setText(prepared);
  }, [textForTestTts]);

  useEffect(() => {
    const fetchCustomProviders = async () => {
      const { data } = await TextToSpeechService.getSpeechProviderList();
      const customProviderOptions = getCustomProviderOptions(data as Array<CustomProvider>);

      setCustomProviderOptions(customProviderOptions);
    };

    const fetchAvailableProviders = async () => {
      const {
        data: { internalProviders },
      } = await TextToSpeechService.getAvailableProvidersForAccount();

      setProviders(internalProviders);
    };

    fetchAvailableProviders().catch(e => console.log(e));
    fetchCustomProviders().catch(e => console.log(e));
  }, [TextToSpeechService]);

  useEffect(() => {
    const isContainVariable = text.search(/{+|}+/g) !== -1;
    setIsContainVariable(isContainVariable);
  }, [text]);

  useEffect(() => {
    setIsSynthesisError(false);
  }, [text, ttsVoice, ttsProvider]);

  const handleSynthesize = useCallback(async () => {
    let ttsRequest: TtsRequestDTO = {
      speech: {
        text,
      },
      ttsConfig: {
        provider: ttsProvider as TextToSpeechProviderDetail,
        voiceName: ttsVoice,
        projectLanguage: currentProject?.language,
      },
    };

    const isJustAiProvider = Object.values(TextToSpeechProviderDetail).includes(
      ttsProvider as TextToSpeechProviderDetail
    );

    if (!isJustAiProvider) {
      const providerName = customProviderOptions.find(option => option.id === ttsProvider)?.providerName;

      ttsRequest.speechId = ttsProvider;

      if (providerName) {
        ttsRequest.ttsConfig = {
          ...ttsRequest.ttsConfig,
          provider: providerName,
        };
      }
    }

    if (isDev())
      console.log(
        `amplitudeInstance.logEvent('tts_test_panel_generate',${JSON.stringify({
          provider: ttsRequest.ttsConfig.provider,
          voice: ttsVoice,
          is_just_ai_provider: isJustAiProvider,
        })})`
      );
    amplitudeInstance.logEvent('tts_test_panel_generate', {
      provider: ttsRequest.ttsConfig.provider,
      voice: ttsVoice,
      is_just_ai_provider: isJustAiProvider,
    });

    try {
      setFetching(true);
      const { data }: any = await TextToSpeechService.synthesize(ttsRequest);
      const audioKey: string = data.resultDetail.key;
      const { data: dataAudio }: any = await TextToSpeechService.downloadAudio(audioKey);
      const audioString: string = dataAudio.audio;

      setAudioString(`data:audio/wav;base64,${audioString}`);
    } catch (e) {
      setIsSynthesisError(true);
    }

    setFetching(false);
  }, [text, ttsProvider, ttsVoice, currentProject?.language, customProviderOptions, TextToSpeechService]);

  const copyTextResult = useCallback(async () => {
    await navigator.clipboard.writeText(text);
  }, [text]);

  const onDownloadAudio = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'audio.wav';
    link.href = audioString;
    link.click();
  }, [audioString]);

  return (
    <TtsWidgetForm
      provider={ttsProvider}
      voice={ttsVoice}
      text={text}
      audio={audioString}
      fetching={fetching}
      isContainVariable={isContainVariable}
      isSynthesizedTextEmpty={text.trim() === ''}
      isSynthesisError={isSynthesisError}
      handleCloseWidget={hideTestTtsWidget}
      handleSynthesize={handleSynthesize}
      setProvider={setTtsProvider}
      setVoice={setTtsVoice}
      setText={setText}
      copyTextResult={copyTextResult}
      onDownloadAudio={onDownloadAudio}
      customProviderOptions={customProviderOptions}
      providers={providers}
    />
  );
};
