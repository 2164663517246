import { useCallback, useEffect, useState } from 'react';
export const useToggleSelect = (initialValue = undefined) => {
    const [value, setValue] = useState(initialValue);
    const toggleSelect = useCallback((newSelectedValue) => {
        if (newSelectedValue === value) {
            setValue(undefined);
        }
        else {
            setValue(newSelectedValue);
        }
    }, [value]);
    const clearSelect = useCallback(() => {
        setValue(undefined);
    }, []);
    return [value, setValue, toggleSelect, clearSelect];
};
export const useErrorDirty = (dependencie) => {
    const [error, setError] = useState(undefined);
    useEffect(() => {
        if (dependencie && error) {
            setError(undefined);
        }
    }, [error, dependencie]);
    const clearError = useCallback(() => {
        setError(undefined);
    }, []);
    return [error, setError, clearError];
};
