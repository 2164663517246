import React, { FC } from 'react';
import { useRightSideMenuContext } from '../../index';
import { IntentsActivationEdit } from './IntentsActivationEdit';

export const IntentsActivation: FC = () => {
  const { eventsState, changeIntent, changeEventParams } = useRightSideMenuContext();
  const { activationIntent, isGlobal } = eventsState;

  return (
    <IntentsActivationEdit
      isFromState={eventsState.addingMenuType !== 'toStateEvents'}
      isToState={eventsState.addingMenuType === 'toStateEvents'}
      eventState={eventsState}
      isGlobal={isGlobal}
      intent={activationIntent || ''}
      changeIntent={changeIntent}
      changeEventParams={changeEventParams}
    />
  );
};
