import {
  DELETE_NEWS_ITEM_FULFILLED,
  DELETE_NEWS_ITEM_PENDING,
  DELETE_NEWS_ITEM_REJECTED,
  DROP_FILTER_FULFILLED,
  DROP_FILTER_PENDING,
  DROP_FILTER_REJECTED,
  LOAD_AVAILABLE_FILTER_FULFILLED,
  LOAD_AVAILABLE_FILTER_REJECTED,
  LOAD_NEWS_PERIOD_SETTING_FULFILLED,
  LOAD_NEWS_PERIOD_SETTING_PENDING,
  LOAD_NEWS_SETTINGS_FULFILLED,
  LOAD_NEWS_SETTINGS_PENDING,
  NEWS_LOAD_FULFILLED,
  NEWS_LOAD_PENDING,
  NEWS_LOAD_REJECTED,
  PAGINATE_NEWS_FULFILLED,
  PAGINATE_NEWS_PENDING,
  SET_CLIENT_PENDING_TIME,
  SET_FILTER_FULFILLED,
  SET_FILTER_PENDING,
  SET_FILTER_REJECTED,
  SET_PARSER_TIME,
  SET_SELECT,
  SETTINGS_UPDATE_OTHER_FULFILLED,
  SETTINGS_UPDATE_OTHER_PENDING,
  SETTINGS_UPDATE_OTHER_REJECTED,
  SETTINGS_UPDATE_TRIGGER_PERIOD_PENDING,
  SETTINGS_UPDATE_TRIGGER_PERIOD_REJECTED,
} from '../../constants/newsList.actions';

const InitialState = {
  newsList: [],
  pageSize: 0,
  page: 0,
  totalPages: 0,
  totalElements: 0,
  fetching: false,
  settingsFetching: false,

  filterActive: false,
  newsSource: [],
  channelType: [],
  newsItemState: [],
  locale: [],

  selectedNewsSourceOptions: null,
  selectedChannelTypeOptions: null,
  selectedNewsItemStateOptions: null,
  selectedLocaleOptions: null,

  botPageSize: 0,
  inactiveInterval: 0,
  publishImmediately: false,
  triggerPeriod: 0,
};

export default function NewsListReducer(state = InitialState, action) {
  switch (action.type) {
    case NEWS_LOAD_PENDING:
    case SET_FILTER_PENDING:
    case DROP_FILTER_PENDING:
    case DELETE_NEWS_ITEM_PENDING:
    case PAGINATE_NEWS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case LOAD_NEWS_SETTINGS_PENDING: {
      return {
        ...state,
        newsSettingsFetching: true,
      };
    }
    case LOAD_NEWS_PERIOD_SETTING_PENDING: {
      return {
        ...state,
        newsSettingsPeriodFetching: true,
      };
    }
    case LOAD_NEWS_PERIOD_SETTING_FULFILLED: {
      return {
        ...state,
        triggerPeriod: action.payload.data,
        newsSettingsPeriodFetching: false,
      };
    }
    case LOAD_NEWS_SETTINGS_FULFILLED: {
      let filtered = {};
      for (let i = 0; i < action.payload.data.length; i += 1) {
        let obj = action.payload.data[i];
        filtered = { ...filtered, [obj.name]: obj.value };
      }
      return {
        ...state,
        botPageSize: parseInt(filtered['news.subscription.page.size'], 10),
        inactiveInterval: parseInt(filtered['news.subscription.publish.client.inactive.interval'], 10),
        publishImmediately: String(filtered['news.publish.immediately']) === 'true',
        newsSettingsFetching: false,
      };
    }
    case SET_PARSER_TIME: {
      return {
        ...state,
        triggerPeriod: action.value,
      };
    }
    case SET_CLIENT_PENDING_TIME: {
      return {
        ...state,
        inactiveInterval: action.value,
      };
    }
    case NEWS_LOAD_FULFILLED:
    case PAGINATE_NEWS_FULFILLED: {
      return {
        ...state,
        newsList: action.payload.data.content,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        fetching: false,
      };
    }
    case LOAD_AVAILABLE_FILTER_FULFILLED: {
      return {
        ...state,
        newsSource: action.payload.data.sources || InitialState.newsSource,
        channelType: action.payload.data.types || InitialState.channelType,
        newsItemState: action.payload.data.states || InitialState.newsItemState,
        locale: action.payload.data.locale || InitialState.locale,
      };
    }
    case SET_SELECT: {
      return {
        ...state,
        [action.payload.type]: action.payload.selected,
      };
    }
    case SET_FILTER_FULFILLED: {
      return {
        ...state,
        newsList: action.payload.data.content,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        fetching: false,
        filterActive: true,
      };
    }
    case DROP_FILTER_FULFILLED: {
      return {
        ...state,
        newsList: action.payload.data.content,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        fetching: false,

        filterActive: false,
        selectedNewsSourceOptions: null,
        selectedChannelTypeOptions: null,
        selectedNewsItemStateOptions: null,
        selectedLocaleOptions: null,
      };
    }
    case SETTINGS_UPDATE_TRIGGER_PERIOD_PENDING:
    case SETTINGS_UPDATE_OTHER_PENDING: {
      return {
        ...state,
        settingsFetching: true,
      };
    }
    case SETTINGS_UPDATE_TRIGGER_PERIOD_REJECTED:
    case SETTINGS_UPDATE_OTHER_REJECTED: {
      return {
        ...state,
        settingsFetching: false,
      };
    }
    case SETTINGS_UPDATE_OTHER_FULFILLED: {
      const settings = action.payload.config.settings;
      return {
        ...state,
        settingsFetching: false,
        botPageSize: settings['news.subscription.page.size'] || InitialState.botPageSize,
        publishImmediately: !!settings['news.publish.immediately']
          ? String(settings['news.publish.immediately']) === 'true'
          : InitialState.publishImmediately,
      };
    }
    case DELETE_NEWS_ITEM_FULFILLED: {
      let newNewsList = state.newsList.slice();
      newNewsList = newNewsList.filter(item => {
        return item.id !== action.payload.config.additionalParams.id;
      });
      return {
        ...state,
        newsList: newNewsList,
      };
    }
    case DELETE_NEWS_ITEM_REJECTED:
    case NEWS_LOAD_REJECTED:
    case DROP_FILTER_REJECTED:
    case SET_FILTER_REJECTED:
    case LOAD_AVAILABLE_FILTER_REJECTED: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
