import {
  GET_COMPONENT_SETTINGS_FULFILLED,
  TOGGLE_SETTING,
  ADD_CHILD_FULFILLED,
  SAVE_CHILD_FULFILLED,
  EDIT_CHILD,
  DELETE_CHILD_FULFILLED,
  SIMPLE_ADD_CHILD_FULFILLED,
  SIMPLE_DELETE_CHILD_FULFILLED,
  SIMPLE_EDIT_CHILD,
  SIMPLE_SAVE_CHILD_FULFILLED,
  ADD_PARENT,
  EDIT_PARENT,
  DELETE_PARENT_FULFILLED,
  SAVE_PARENT_FULFILLED,
  REMOVE_ADDED_CHILD,
} from '../../constants/systemDialogsSettings.actions';

import { sort } from '../../pipes/functions';

const InitialState = {
  labels: [],
  sessions: [],
  issuesLabels: [],
  issuesPriorities: [],
  issuesStates: [],
};

export default function SystemDialogsSettingsReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_COMPONENT_SETTINGS_FULFILLED: {
      const data = sort(action.payload.data, 'priority');
      return {
        ...state,
        [action.payload.config.name]: data,
      };
    }
    case TOGGLE_SETTING: {
      const setting = state[action.reducerObjName].slice();
      const index = setting.findIndex(item => {
        return item.id === action.id;
      });
      setting[index] = {
        ...setting[index],
        toggled: !setting[index].toggled,
      };
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    case ADD_CHILD_FULFILLED: {
      const setting = state[action.payload.config.reducerObjName].slice();
      const index = setting.findIndex(item => {
        return item.id === action.payload.config.id;
      });
      if (index > -1) {
        if (!setting[index].labels) setting[index].labels = [];
        setting[index].labels.push(action.payload.data);
        setting[index].labels = sort(setting[index].labels, 'priority');
        return {
          ...state,
          [action.payload.config.reducerObjName]: setting,
        };
      }

      return state;
    }
    case DELETE_CHILD_FULFILLED: {
      const setting = state[action.payload.config.reducerObjName].slice();
      const parentIndex = setting.findIndex(item => {
        return item.id === action.payload.config.parentId;
      });
      const childIndex = setting[parentIndex].labels.findIndex(child => {
        return child.id === action.payload.config.id;
      });
      setting[parentIndex].labels.splice(childIndex, 1);
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case SAVE_CHILD_FULFILLED: {
      const setting = state[action.payload.config.reducerObjName].slice();
      const index = setting.findIndex(item => {
        return item.id === action.payload.config.parentId;
      });
      const childIndex = setting[index].labels.findIndex(child => {
        return child.id === action.payload.config.id;
      });
      setting[index].labels[childIndex] = {
        ...setting[index].labels[childIndex],
        ...action.payload.config.obj,
        ...action.payload.data,
        edit: false,
      };
      setting[index].labels = sort(setting[index].labels, 'priority');
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case EDIT_CHILD: {
      const setting = state[action.reducerObjName].slice();
      const index = setting.findIndex(item => {
        return item.id === action.parentId;
      });

      const childIndex = setting[index].labels.findIndex(child => {
        return child.id === action.id;
      });
      setting[index].labels[childIndex] = {
        ...setting[index].labels[childIndex],
        edit: !setting[index].labels[childIndex].edit,
      };
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    case SIMPLE_ADD_CHILD_FULFILLED: {
      let setting = state[action.payload.config.reducerObjName].slice();
      setting.push({ ...action.payload.config.obj, ...action.payload.data });
      setting = sort(setting, 'priority');
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case SIMPLE_DELETE_CHILD_FULFILLED: {
      const setting = state[action.payload.config.reducerObjName].slice();

      const childIndex = setting.findIndex(child => {
        return child.id === action.payload.config.id;
      });
      setting.splice(childIndex, 1);
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case SIMPLE_SAVE_CHILD_FULFILLED: {
      let setting = state[action.payload.config.reducerObjName].slice();

      const childIndex = setting.findIndex(child => {
        return child.id === action.payload.config.id;
      });
      setting[childIndex] = {
        ...setting[childIndex],
        ...action.payload.config.obj,
        ...action.payload.data,
        edit: false,
      };
      setting = sort(setting, 'priority');
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case SIMPLE_EDIT_CHILD: {
      const setting = state[action.reducerObjName].slice();
      const index = setting.findIndex(item => {
        return item.id === action.id;
      });

      setting[index] = {
        ...setting[index],
        edit: !setting[index].edit,
      };
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    case ADD_PARENT: {
      let setting = state[action.reducerObjName].slice();
      setting.push(action.obj);
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    case DELETE_PARENT_FULFILLED: {
      let setting = state[action.payload.config.reducerObjName].slice();

      const index = setting.findIndex(parent => {
        return parent.id === action.payload.config.id;
      });
      setting.splice(index, 1);
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case REMOVE_ADDED_CHILD: {
      let setting = state[action.reducerObjName].slice();
      const index = setting.findIndex(parent => {
        return parent.id === action.id;
      });
      setting.splice(index, 1);
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    case SAVE_PARENT_FULFILLED: {
      let setting = state[action.payload.config.reducerObjName].slice();
      let index = setting.findIndex(parent => {
        return parent.added === true;
      });
      if (index === -1) {
        index = setting.findIndex(parent => {
          return parent.id === action.payload.config.obj.id;
        });
      }
      setting.splice(index, 1, { ...setting[index], ...action.payload.config.obj, ...action.payload.data });
      setting = sort(setting, 'priority');
      return {
        ...state,
        [action.payload.config.reducerObjName]: setting,
      };
    }
    case EDIT_PARENT: {
      let setting = state[action.reducerObjName].slice();
      const index = setting.findIndex(parent => {
        return parent.id === action.id;
      });
      setting[index].editing = true;
      return {
        ...state,
        [action.reducerObjName]: setting,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
