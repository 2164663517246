import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
const SpeedAudioContext = createContext({});
const getNumberForInitialState = (value, defaultValue) => {
    const optionalNumber = Number(value);
    if (Number.isNaN(optionalNumber))
        return defaultValue;
    return optionalNumber;
};
export const SpeedAudioContextProvider = ({ children }) => {
    const initialValue = getNumberForInitialState(localStorage.AUDIO_PLAYBACK_RATE, 1);
    const [currentAudioSpeed, setCurrentAudioSpeed] = useState(initialValue);
    return (_jsx(SpeedAudioContext.Provider, { value: {
            currentAudioSpeed,
            setCurrentAudioSpeed,
        }, children: children }));
};
export const useSpeedAudioContext = () => useContext(SpeedAudioContext);
