import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AudioFieldMemo, FileUpload, Icon, IconButton } from '../../index';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function FileUploadContent(props) {
    const { type, localFile, isLoading, handleAudioChange, localChange } = props;
    const { t } = useTranslation();
    if (type === AnswerReplyType.image && localFile)
        return null;
    if (type === AnswerReplyType.audio && !isLoading)
        return (_jsx(AudioFieldMemo, { saveAudioToServer: props.saveAudioToServer, onChange: handleAudioChange, fileName: localFile === null || localFile === void 0 ? void 0 : localFile.name, url: localFile instanceof File ? undefined : localFile === null || localFile === void 0 ? void 0 : localFile.url }));
    let acceptFile = '*';
    if (type === 'file' && localFile) {
        return (_jsx(FileUpload, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.FileUploader', accept: acceptFile, onChange: localChange, loading: isLoading, loadingType: 'progressBar', children: _jsxs("div", { className: 'faq-answer-part__file-preview', children: [_jsx(Icon, { name: 'farCheckCircle', color: 'success', size: 'lg' }), _jsxs("div", { className: 'faq-answer-part__file-preview_fileName', children: [_jsx("p", { className: 'text-secondary', children: t('FAQ:importModal:success') }), _jsx("p", { className: 'faq-answer-part__file-preview_fileName_p', children: _jsx("b", { className: 'faq-answer-part__file-preview_fileName_b', children: localFile.name }) })] }), _jsx(IconButton, { onClick: e => {
                            e.preventDefault();
                            e.stopPropagation();
                            localChange(undefined);
                        }, name: 'farTrashAlt', type: 'button', flat: true, color: 'secondary' })] }) }));
    }
    acceptFile = type === AnswerReplyType.audio ? '.wav, .mp3' : type === 'file' ? '*' : 'image/*';
    return (_jsx(FileUpload, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.FileUploader', accept: acceptFile, onChange: localChange, loading: isLoading, loadingType: 'progressBar', children: _jsxs("p", { children: [_jsx("span", { className: 'pseudoLink', onClick: e => e.preventDefault(), children: t('Attach file') }), ' ', t('or drop it here')] }) }));
}
