import { TIMEZONES, getClassificationAlgorithmTypes } from '../../../views/ProjectEditForm/constants';
import { BotProjectModificationDto, RepositoryAuthType } from '../api/client';

export class ProjectSettingsData {
  language = '';
  classificationAlgorithm = '';
  spellingCorrection = false;
  shared = false;
  timezone = '';
  extendedSettings: string | null = null;

  constructor({
    language = 'ru',
    classificationAlgorithm = getClassificationAlgorithmTypes()[0].value,
    spellingCorrection = false,
    shared = false,
    timezone = TIMEZONES[12].value,
    extendedSettings = null,
  } = {}) {
    this.language = language;
    this.classificationAlgorithm = classificationAlgorithm;
    this.spellingCorrection = spellingCorrection;
    this.shared = shared;
    this.timezone = timezone;
    this.extendedSettings = extendedSettings;
  }
}

export class ProjectCreationData implements BotProjectModificationDto {
  name: string = '';
  description?: string = '';

  language?: string = '';

  repositoryUrl?: string = '';
  contentDirectory?: string = '.';
  defaultBranch?: string = 'master';
  repositoryAuthType?: RepositoryAuthType;
  repositoryLogin?: string = '';
  repositoryPassword?: string = '';

  location?: string = '';
  gitIntegration?: string = '';
  workspaceIdGitIntegration?: string = '';
  projectIdGitIntegration?: string = '';
  repositoryNameGitIntegration?: string = '';
  repositoryUrlGitIntegration?: string = '';
  repositoryIsPrivateGitIntegration?: boolean = false;
  repositoryVisibility?: 'public' | 'private' = 'public';
  branchNameGitIntegration?: string = '';

  justAiKey?: string = '';

  template?: string = '';
  externalTemplate?: string = '';

  cleanupDialogs?: boolean = false;
  cleanupOlderThenDays?: number = -1;

  jaicfProject?: boolean = false;
  webHookUrl?: string = '';
  externalBotToken?: string = '';
  file?: File | null = null;
  environment?: any = undefined;
  projectSettingsData?: ProjectSettingsData = new ProjectSettingsData();
}
