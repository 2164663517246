import React, { Fragment, MutableRefObject, useCallback, useMemo } from 'react';
import { FileUpload, Icon, InputGroup, InputText, Select, SwitchButton, Tooltip, useToggle } from '@just-ai/just-ui';
import SimilarsRange from '@just-ai/just-ui/dist/SimilarsRange';
import { Button, DropdownMenu } from 'reactstrap';
import { ValueType } from 'react-select';
import cn from 'classnames';

import { t } from 'localization';
import isAccess, { isConfigEnabled, isEuroInstance, isWhiteLabel } from 'isAccessFunction';

import { cloudDomainsService } from 'services/CloudDomainsService';
import { Popohover } from 'views/TariffPage/Popohover';
import { ClassificationAlgorithmType, TimeZoneType } from 'views/ProjectEditForm/constants';
import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { SoftValidationSettingsData } from 'modules/Caila/api/client/api';

import RadioPads from './RadioPads';

const defaultValues: SoftValidationSettingsData = {
  answerValidationEnabled: false,
  answerValidationThreshold: 0.5,
  phraseValidationEnabled: false,
  phraseValidationThreshold: 0.5,
};

type Option = { label: string; value: string };
type ClassificationProps = {
  classificationAlgorithmTypes: ClassificationAlgorithmType[];
  changeAlgorithm: (options: ClassificationAlgorithmType) => void;
  classificationAlgorithm: ClassificationAlgorithmType;
  spellingCorrection: boolean;
  setSpellingCorrection: () => void;
  changeTimezone: (timeZone: ValueType<TimeZoneType>) => void;
  timezone: TimeZoneType;
  timezones: TimeZoneType[];
  isEdit: boolean;
  accessKey: string;
  getCailaAccessKey: () => void;
  copyAccessKey: () => void;
  accessKeyRef: MutableRefObject<string>;
  importCailaProject: (fileList: FileList) => void;
  customStyles: any;
  shared: boolean;
  setShared: () => void;
  languages: Option[];
  selectedLanguage: Option;
  changeLanguage: (lang: ValueType<Option>) => void;
  isJaicfProject: boolean;
  validationData: SoftValidationSettingsData;
  setValidationData: (data: SoftValidationSettingsData) => void;
};

export const Classificator = React.memo(
  ({
    classificationAlgorithmTypes,
    changeAlgorithm,
    classificationAlgorithm,
    spellingCorrection,
    setSpellingCorrection,
    changeTimezone,
    timezone,
    timezones,
    isEdit,
    accessKey,
    getCailaAccessKey,
    copyAccessKey,
    accessKeyRef,
    importCailaProject,
    customStyles,
    shared,
    setShared,
    languages,
    selectedLanguage,
    changeLanguage,
    isJaicfProject,
    validationData = defaultValues,
    setValidationData,
  }: ClassificationProps) => {
    const transformerOption = useMemo(
      () => classificationAlgorithmTypes.find(type => type.value === 'transformer'),
      [classificationAlgorithmTypes]
    );

    const [showApiKey, , , switchApiKeyShow] = useToggle(false);
    const nluWriteDisabled = !isAccess('NLU_WRITE');

    const handleSimilarPhrasesToggle = useCallback(
      value => {
        setValidationData({ ...validationData, phraseValidationEnabled: value });
      },
      [setValidationData, validationData]
    );

    const handleSimilarAnswersToggle = useCallback(
      value => {
        setValidationData({ ...validationData, answerValidationEnabled: value });
      },
      [setValidationData, validationData]
    );

    const handleSimilarPhrasesRange = useCallback(
      (value: number) => {
        setValidationData({ ...validationData, phraseValidationThreshold: value });
      },
      [setValidationData, validationData]
    );

    const handleSimilarAnswersRange = useCallback(
      (value: number) => {
        setValidationData({ ...validationData, answerValidationThreshold: value });
      },
      [setValidationData, validationData]
    );

    return (
      <Fragment>
        {isAccess(['BOT_CONTENT_READ', 'nlu']) && (
          <div className='form-group row select-access'>
            <div className='col-md-4 flex-column'>
              <label className='form-control-label'>
                {t('Classification Algorithm')}
                <Icon color='primary' name='faInfoCircle' id='classificationAlgorithmTooltip' size='sm' />
                <Tooltip placement='top' target='classificationAlgorithmTooltip'>
                  {t('classificationAlgorithm tooltip')}
                </Tooltip>
              </label>
              {!isWhiteLabel() && (
                <a
                  className='padding-top-2x'
                  target='_blank'
                  href={t('ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink:Link')}
                  rel='noreferrer'
                >
                  {t('ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink')}
                </a>
              )}
            </div>
            <div className='col-md-8'>
              <RadioPads
                name='classificationAlgorithm'
                options={classificationAlgorithmTypes}
                onChange={value => changeAlgorithm(value as ClassificationAlgorithmType)}
                selectedOption={classificationAlgorithm}
                padsDisabled={nluWriteDisabled}
              />
              {transformerOption?.disabled && (
                <Banner
                  className='padding-3x margin-top-2x'
                  type={BannerType.DEFAULT}
                  title={t('ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Title')}
                  content={
                    <div className='flex-column gap-2x font-size-12'>
                      <div>{t('ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Description1')}</div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Description2',
                            cloudDomainsService.getProductDomainData('jaicp')?.email?.client ?? ''
                          ),
                        }}
                      />
                    </div>
                  }
                />
              )}
            </div>
          </div>
        )}

        {isJaicfProject && isAccess(['BOT_CONTENT_READ', 'nlu']) && !!languages.length && (
          <div className='form-group row select-access'>
            <label className='col-md-4 form-control-label'>
              {t('caila project language')}
              <Icon color='primary' name='faInfoCircle' size='sm' id='cailaLanguage' />
              <Popohover className='project-language-popover' target='cailaLanguage' closable={false}>
                <p>{t('caila project language tooltip')}</p>
                <p>{t('caila project language tooltip list header')}</p>
                <ul>
                  <li>{t('caila project language tooltip list item1')}</li>
                  <li>{t('caila project language tooltip list item2')}</li>
                  <li>{t('caila project language tooltip list item3')}</li>
                </ul>
                {isConfigEnabled('showHelp') && (
                  <a href={t('caila project language tooltip link')}>
                    <Button color='primary'>{t('caila project language tooltip btn caption')}</Button>
                  </a>
                )}
              </Popohover>
            </label>
            <div className='col-md-8'>
              <Select
                options={languages}
                isMulti={false}
                isClearable={false}
                styles={customStyles}
                onChange={changeLanguage}
                value={selectedLanguage}
                dropdownComponent={DropdownMenu}
                menuPosition='fixed'
                data-test-id='projectsPage.createProjectModal.languageSelect'
                noOptionsMessage={() => t('BotControls:not_found')}
                isDisabled={nluWriteDisabled}
              />
            </div>
          </div>
        )}

        {isAccess(['BOT_CONTENT_READ', 'nlu']) && !isEuroInstance() && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label' htmlFor='speelingCorrection'>
              {t('Spelling Correction')}
              <Icon color='primary' name='faInfoCircle' id='classificationSpellcheckerTooltip' size='sm' />
              <Tooltip placement='top' target='classificationSpellcheckerTooltip'>
                {t('classificationSpellchecker tooltip')}
              </Tooltip>
            </label>
            <div className='col-md-8'>
              <SwitchButton
                id='speelingCorrection'
                label=''
                disabled={nluWriteDisabled || (selectedLanguage?.value !== 'ru' && selectedLanguage?.value !== 'uk')}
                onChange={setSpellingCorrection}
                value={spellingCorrection}
              />
            </div>
          </div>
        )}

        {isAccess(['BOT_CONTENT_READ', 'nlu']) && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label' style={{ alignItems: 'flex-start', marginTop: 4 }}>
              {t('ProjectEditForm:similarsHeading')}
              <Icon color='primary' name='faInfoCircle' id='similarsTooltip' size='sm' />
              <Tooltip placement='bottom' target='similarsTooltip'>
                {t('ProjectEditForm:similarsTooltip')}
              </Tooltip>
            </label>
            <div className='col-md-8'>
              <div className='mb-5'>
                <SwitchButton
                  id='similarsPhrasesToggle'
                  data-test-id='Settings.similarsPhrasesToggle'
                  label={t('ProjectEditForm:similarsPhrasesLabel')}
                  disabled={nluWriteDisabled}
                  onChange={handleSimilarPhrasesToggle}
                  value={validationData.phraseValidationEnabled}
                />
                {validationData.phraseValidationEnabled && (
                  <SimilarsRange
                    value={validationData.phraseValidationThreshold || 0}
                    onChange={handleSimilarPhrasesRange}
                    inputTestId='Settings.similarsPhrasesInput'
                  />
                )}
              </div>
              <div>
                <SwitchButton
                  id='similarsAnswersToggle'
                  data-test-id='Settings.similarsAnswersToggle'
                  label={t('ProjectEditForm:similarsAnswersLabel')}
                  disabled={nluWriteDisabled}
                  onChange={handleSimilarAnswersToggle}
                  value={validationData.answerValidationEnabled}
                />
                {validationData.answerValidationEnabled && (
                  <SimilarsRange
                    value={validationData.answerValidationThreshold || 0}
                    onChange={handleSimilarAnswersRange}
                    inputTestId='Settings.similarsAnswersInput'
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {isAccess(['BOT_CONTENT_READ', 'nlu']) && (
          <div className='form-group row select-access'>
            <label className='col-md-4 form-control-label'>
              {t('Timezone')}
              <Icon color='primary' name='faInfoCircle' id='timezoneTooltip' size='sm' />
              <Tooltip placement='top' target='timezoneTooltip'>
                {t('timezone tooltip')}
              </Tooltip>
            </label>
            <div className='col-md-8'>
              <Select
                options={timezones}
                isMulti={false}
                isClearable={false}
                styles={customStyles}
                onChange={changeTimezone}
                value={timezone}
                menuPosition='fixed'
                data-test-id='projectsPage.createProjectModal.timezonesSelect'
                noOptionsMessage={() => t('BotControls:not_found')}
                isDisabled={nluWriteDisabled}
              />
            </div>
          </div>
        )}

        {isEdit && isAccess(['NLU_WRITE', 'nlu']) && (
          <div className='form-group row'>
            <div className='col-md-4 form-control-label'>
              <span>{t(isEuroInstance() ? 'tovie access key' : 'caila access key')}</span>
              <Icon color='primary' name='faInfoCircle' size='sm' id='cailaAccessKeyTooltip' />
              <Tooltip placement='top' target='cailaAccessKeyTooltip'>
                {t('cailaAccessKey tooltip')}
              </Tooltip>
            </div>
            <div className='col-md-8'>
              <InputGroup
                style={{ zIndex: 0 }}
                // @ts-ignore
                Append={
                  !Boolean(accessKey) ? (
                    <Button
                      color='primary'
                      outline
                      onClick={getCailaAccessKey}
                      data-test-id='projectsPage.createProjectModal.geterateKeyButton'
                      disabled={nluWriteDisabled}
                    >
                      {t('caila generate access key')}
                    </Button>
                  ) : null
                }
                AppendInner={[
                  {
                    name: showApiKey ? 'faEyeSlash' : 'faEye',
                    onClick: switchApiKeyShow,
                  },
                  {
                    name: 'faCopy',
                    onClick: copyAccessKey,
                  },
                ]}
              >
                <InputText
                  readOnly
                  className='skip-readonly'
                  placeholder={t('ProjectEditForm Secret key')}
                  innerRef={accessKeyRef}
                  type={showApiKey ? 'text' : 'password'}
                  value={Boolean(accessKey) ? accessKey : ''}
                  data-test-id='projectsPage.createProjectModal.justAiKeyInput'
                />
              </InputGroup>
            </div>
          </div>
        )}

        {isEdit && isAccess(['BOT_CONTENT_READ', 'nlu']) && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label'>
              {t(isEuroInstance() ? 'tovie import project' : 'Caila project import')}
            </label>
            <div className='col-md-8'>
              <FileUpload
                className={cn({ disabled: nluWriteDisabled })}
                onChange={importCailaProject}
                disabled={nluWriteDisabled}
                accept='.zip'
              >
                <p>
                  <a href='!#' onClick={e => e.preventDefault()}>
                    {t('ProjectEditForm_Classificator:add_file')}
                  </a>{' '}
                  {t('ProjectEditForm_Classificator:or_drop_it_here')}
                </p>
              </FileUpload>
            </div>
          </div>
        )}

        {isAccess(['nlu', 'NLU_READ', 'system_admin']) && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label' htmlFor='shared'>
              {t('ProjectEditForm shared project')}
            </label>
            <div className='col-md-8'>
              <SwitchButton id='shared' label='' disabled={nluWriteDisabled} onChange={setShared} value={shared} />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
);
