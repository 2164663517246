import { useEffect, useRef } from 'react';

import { StatesStore, StatesStorePipe$ } from '../utils/blockLayerUtils';

export function useStatesStoreRef() {
  const screenSizesMap = useRef<StatesStore>({});

  useEffect(() => {
    const sub = StatesStorePipe$.subscribe(value => {
      screenSizesMap.current = value;
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return screenSizesMap;
}
