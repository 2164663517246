import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

import { deleteBlockInScreen, restoreState } from '../index';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { createState, updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { deleteStateNotRevertible } from 'reducers/JGraph.reducer/AsyncActions/deleteState';

type DeleteBlockInStatePayload = {
  screenId: string;
  blockIndex: number;
  path?: string;
};
export const deleteBlockInScreenAsync = createAsyncThunk(
  'JGraph/deleteBlockInScreenAsync',
  async (data: DeleteBlockInStatePayload, thunkAPI) => {
    await thunkAPI.dispatch(deleteBlockInScreen(data));
    const state = thunkAPI.getState() as RootState;
    const storeScreens = state.JGraphReducer.graph.blocks;
    const editMenuScreen = state.JGraphReducer.editMenuBlock;
    const screenToSave = findScreenByPathId(editMenuScreen!.screen.pathId, storeScreens);
    if (!screenToSave) return;
    if (screenToSave.isUnsaved) {
      mainSave$.next({
        type: 'create',
        path: screenToSave.path,
        action: () => thunkAPI.dispatch(createState(screenToSave)),
      });
      return;
    }
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () => thunkAPI.dispatch(updateState(screenToSave)),
    });
  }
);

export const revertDeletingBlockInScreenAsync = createAsyncThunk(
  'JGraph/revertDeletingBlockInScreenAsync',
  async (revertEvent: RevertEvent<DeleteBlockInStatePayload>, thunkAPI) => {
    const { screenId } = revertEvent.payload;
    const storeScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const screenToSave = findScreenByPathId(screenId, storeScreens);
    if (!screenToSave) return;

    const screenHasBeenCreated = screenToSave.isUnsaved;
    if (screenHasBeenCreated) {
      mainSave$.next({
        type: 'delete',
        path: screenToSave.path,
        action: () =>
          thunkAPI.dispatch(
            deleteStateNotRevertible({
              filename: screenToSave.filename,
              targetStatePath: screenToSave.path,
            })
          ),
      });
      return;
    }
    await thunkAPI.dispatch(restoreState({ screen: screenToSave }));
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () => thunkAPI.dispatch(updateState(screenToSave)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertDeletingBlockInScreenAsync,
  deleteBlockInScreenAsync
);
