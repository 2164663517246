export const generateId = (): string => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

export function getUsedNodes(editableDiv: HTMLDivElement | null) {
  let selection,
    range,
    scope = [];
  if (window.getSelection) {
    selection = window.getSelection();
    if (selection?.rangeCount && editableDiv?.contains(selection.anchorNode)) {
      range = selection.getRangeAt(0);
      let ancestorContainer = range.commonAncestorContainer;
      if (ancestorContainer !== editableDiv) {
        let parent: Node | null = ancestorContainer;
        while (parent && parent !== editableDiv && ancestorContainer !== editableDiv) {
          scope.push(parent.nodeName);
          parent = parent.parentNode;
        }
      }
    }
  }

  return scope;
}

export const checkNodeIsSelected = (selectedNodes: string[], nodeName: string) => {
  return selectedNodes.indexOf(nodeName) > -1 ? 'active' : '';
};

export function saveEditorSelection(container: HTMLElement) {
  if (window.getSelection) {
    let selection = window.getSelection();
    if (selection && selection.getRangeAt && selection.rangeCount) {
      let ranges = [];
      for (let i = 0, len = selection.rangeCount; i < len; ++i) {
        const range = selection.getRangeAt(i);
        if (range && container.contains(range.startContainer)) {
          ranges.push(range);
        }
      }
      return ranges;
    }
  }
  return null;
}

export function restoreEditorSelection(savedSel: Range[]) {
  if (savedSel) {
    if (window.getSelection) {
      let selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        for (let i = 0, len = savedSel.length; i < len; ++i) {
          selection.addRange(savedSel[i]);
        }
      }
    }
  }
}

export function dropSelections(savedSel: Range[]) {
  if (savedSel) {
    if (window.getSelection) {
      let selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }
}

export function replaceSelectedText(rangeToReplace: Range, replacementText: string) {
  rangeToReplace.deleteContents();
  const value = document.createTextNode(replacementText);
  rangeToReplace.insertNode(value);
  rangeToReplace.selectNode(value);
  return rangeToReplace;
}
