import { EventType, NotificationData as BaseNotificationData } from '../api/client/api';
import moment from 'moment';

export interface NotificationData extends BaseNotificationData {
  index?: number;
  hovered?: boolean;
}

export const getTypeByCode = (code?: string) => {
  code = code || '';
  if (code.includes('error')) {
    return 'error';
  }
  if (code.includes('warn')) {
    return 'warn';
  }
  if (code.includes('finished')) {
    return 'success';
  }
  return 'info';
};

export type NotificationType = EventType | 'error' | 'warn' | 'success' | 'info';

export const getTypeByNotification = (notification: NotificationData): NotificationType => {
  if (notification?.message?.code?.type) {
    return notification.message.code.type;
  }
  return getTypeByCode(
    (notification && notification.message && notification.message.code && notification.message.code.code) || ''
  );
};

export interface DateNotifications {
  [key: string]: NotificationData[];
}
export const filterByTypeAndProject = (notifications: NotificationData[], type?: string, projectId?: string) => {
  const newNotifications: NotificationData[] = [];
  notifications.forEach((notification, index) => {
    if (
      (!type || type === 'all' || getTypeByNotification(notification) === type) &&
      notification.projectId === projectId
    ) {
      notification.index = index;
      newNotifications.push(notification);
    }
  });
  return newNotifications;
};
export const toSortedByDateObject = (notifications: NotificationData[]) => {
  return notifications.reduce((acc, notification) => {
    const date = moment(notification.timestamp).format('YYYYMMDD');
    if (!acc[date]) acc[date] = [];
    acc[date].push(notification);
    return acc;
  }, {} as DateNotifications);
};
