import { CHANGE_CURRENT_PROJECT, SET_CURRENT_PROJECT, CLEAR_CURRENT_PROJECT } from '../../constants/projects.actions';
import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

const InitialState = {
  currentProject: '',
};

export default function CurrentProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
    case CHANGE_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.projectShortName,
      };

    case LOGOUT_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED:
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: '',
      };

    default:
      return state;
  }
}
