import React, { FunctionComponent } from 'react';
import { Button } from '@just-ai/just-ui';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';
import { useEditorContext } from 'modules/Editor/context/EditorContext';
import ReactDOM from 'react-dom';
import { useGlobalBottomPanelContext } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import { ERRORS_PANEL_TYPE } from '.';

const { t } = i18nTranslation('Editor');

const BOUNDING_PLACE_ID = 'errorsButtonBoundingPlace';

export const ParseErrorsButtonPortal: FunctionComponent = () => {
  const { parseErrors } = useEditorContext();
  const { togglePanel } = useGlobalBottomPanelContext();

  const bondingPlace = document.getElementById(BOUNDING_PLACE_ID);
  if (!bondingPlace) return null;

  return ReactDOM.createPortal(
    <Button
      data-test-id='LogPanel.bottomToolbar.errorsButton'
      iconLeft='faBug'
      size='sm'
      color='none'
      onClick={() => togglePanel(ERRORS_PANEL_TYPE)}
    >
      {t('parseErrorsButton')}
      {parseErrors.length > 0 && (
        <>
          {' '}
          <span className='warning'>
            [<b>{parseErrors.length}</b>]
          </span>
        </>
      )}
    </Button>,
    bondingPlace
  );
};

export const ParseErrorsButtonBoundingPlace: FunctionComponent = () => <span id={BOUNDING_PLACE_ID} />;
