import React, { useEffect } from 'react';

import { UiMode } from 'types';

import { JGraph } from 'modules/JGraph/view/JGraphWrapper';
import { useAppContext } from '../../modules/Caila/components/AppContext';
import { useTestWidgetContext } from '../../modules/TestWidget';

export const JGraphIframeWrapper = () => {
  const { IntentsService, SettingsService, currentProject } = useAppContext();

  const { showTestWidget, hideTestWidget } = useTestWidgetContext();

  useEffect(() => {
    const retarget = (event: Event) => {
      window.parent.postMessage(event.type, '*');
    };

    const listener = (event: MessageEvent) => {
      if (event.data === 'showWidget') {
        showTestWidget();
      }
      if (event.data === 'showVoiceWidget') {
        showTestWidget(undefined, undefined, undefined, 'voiceWidget');
      }
      if (event.data === 'hideWidget') {
        hideTestWidget();
      }
    };

    window.addEventListener('message', listener);

    document.addEventListener('justwidget_unmounted', retarget);
    document.addEventListener('justwidget_mounted', retarget);

    return () => {
      window.removeEventListener('message', listener);

      document.removeEventListener('justwidget_unmounted', retarget);
      document.removeEventListener('justwidget_mounted', retarget);
    };
  }, [hideTestWidget, showTestWidget]);

  if (!currentProject?.name) return null;
  return (
    <JGraph
      IntentsService={IntentsService}
      SettingsService={SettingsService}
      projectName={currentProject?.name}
      uiMode={UiMode.NoCode_user}
    />
  );
};
