import React, { useCallback, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Rect, Text, Group } from 'react-konva';

import { ScreenLabel } from 'modules/JGraph/view/LabelingToolMenu/types';
import { setCursorOnMouseEnterAndLeave } from 'modules/JGraph/utils/stageUtils';

const cornerRadius = [8, 8, 0, 0];

interface ScreenLabelInterface {
  screenLabel: ScreenLabel;
  onLabelClick: () => void;
}
const ScreenLabelView = function ({ screenLabel, onLabelClick }: ScreenLabelInterface) {
  const ref = useRef<Konva.Group | null>(null);

  const onClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      onLabelClick();
    },
    [onLabelClick]
  );

  return (
    <Group
      x={0}
      y={-24}
      ref={ref}
      onClick={onClick}
      onMouseEnter={setCursorOnMouseEnterAndLeave()}
      onMouseLeave={setCursorOnMouseEnterAndLeave()}
    >
      <Rect
        stroke={screenLabel.color.altBorderColor}
        strokeWidth={1}
        fill={screenLabel.color.mainColor}
        cornerRadius={cornerRadius}
        height={32}
        width={280}
      />
      <Text
        text={screenLabel.text}
        fill={screenLabel.color.textColor}
        x={8}
        y={4}
        width={280 - 16}
        fontVariant='bold'
        fontSize={12}
        lineHeight={16 / 12}
        letterSpacing={0.01}
        ellipsis
        wrap='none'
      />
    </Group>
  );
};

export default React.memo(ScreenLabelView);
