import React, { useRef, useCallback, useEffect } from 'react';
import keyboardJS from 'keyboardjs';
import ReactDOM from 'react-dom';
import Konva from 'konva';

import { Text } from 'react-konva';

import { useToggle } from '@just-ai/just-ui';

import TextEditLayer from '../TextEditLayer';

type EditableTextProps = Konva.TextConfig & {
  fontSize: number;
  onChangeEditMode?: (isOpen: boolean) => void;
  onChange?: (text: string, fontSize: number) => void;
};
const EditableText = ({ onChangeEditMode, onChange, ...props }: EditableTextProps) => {
  const textRef = useRef<Konva.Text | null>(null);
  const textStyles = useRef({
    fontSize: props.fontSize,
  });
  const editableText = useRef(props.text || '');
  const [editModeOpened, showEditMode, closeEditMode] = useToggle(false);

  useEffect(() => {
    onChangeEditMode?.(editModeOpened);
  }, [editModeOpened, onChangeEditMode]);

  const onSubmit = useCallback(() => {
    closeEditMode();
    TextEditLayer.TextEditLayerReceiverSubject$.next(undefined);
    onChange?.(editableText.current, textStyles.current.fontSize);
  }, [closeEditMode, onChange]);

  useEffect(() => {
    if (!editModeOpened) return;
    const sub = TextEditLayer.TextEditLayerSenderSubject$.subscribe(data => {
      switch (data?.type) {
        case 'onChange':
          editableText.current = data.content;
          textStyles.current.fontSize = data.styles.fontSize as number;
          break;
        case 'onSubmit':
          ReactDOM.unstable_batchedUpdates(onSubmit);
          break;
      }
    });

    return () => sub.unsubscribe();
  }, [editModeOpened, onSubmit]);

  const handleTransform = useCallback(() => {
    if (!textRef.current) return;
    const absoluteTransform = textRef.current.getAbsoluteTransform();
    const attrs = absoluteTransform.decompose();

    TextEditLayer.TextEditLayerReceiverSubject$.next({
      content: editableText.current,
      styles: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: props.width,
        maxWidth: props.width,
        height: props.height as number,
        maxHeight: props.height,
        verticalAlign: 'center',
        transform: `translateX(${attrs.x}px) translateY(${attrs.y}px) rotate(${attrs.rotation}deg) scaleX(${attrs.scaleX}) scaleY(${attrs.scaleY})`,
        transformOrigin: 'top left',
        ...textStyles.current,
      },
    });
  }, [props.height, props.width]);

  const onDblClick = useCallback(() => {
    showEditMode();
    handleTransform();
  }, [handleTransform, showEditMode]);

  useEffect(() => {
    if (!editModeOpened) return;
    const submitKeymap = ['esc'];
    keyboardJS.bind(submitKeymap, onSubmit);
    return () => keyboardJS.unbind(submitKeymap, onSubmit);
  }, [editModeOpened, onSubmit]);

  const checkClickOutside = useCallback(() => {
    if (!editModeOpened) return;
    onSubmit();
  }, [editModeOpened, onSubmit]);

  useEffect(() => {
    if (!textRef.current || !editModeOpened) return;
    textRef.current.on('absoluteTransformChange', handleTransform);
    textRef.current.getStage()?.on('click', checkClickOutside);
    return () => {
      if (!textRef.current) return;
      textRef.current.off('absoluteTransformChange', handleTransform);
      textRef.current.getStage()?.off('click', checkClickOutside);
    };
  }, [checkClickOutside, editModeOpened, handleTransform]);

  return (
    <Text
      {...props}
      text={props.text}
      fontSize={textStyles.current.fontSize}
      opacity={editModeOpened ? 0 : 1}
      ref={ref => {
        textRef.current = ref;
      }}
      onDblClick={onDblClick}
    />
  );
};

export default React.memo(EditableText);
