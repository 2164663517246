import memoize from 'memoizerific';
import { normalize } from '../utils';
import { without } from 'lodash';
export const prevent = (event) => event.preventDefault();
const toList = memoize(1)(dataset => Object.values(dataset));
export const hasShiftOrMetaOrCtrlModifier = ({ nativeEvent }) => nativeEvent.ctrlKey || nativeEvent.shiftKey || nativeEvent.metaKey;
export const keyEventToAction = (event) => {
    const { keyCode, ctrlKey, shiftKey, altKey, metaKey } = event.nativeEvent;
    if (shiftKey || metaKey || ctrlKey || altKey) {
        return false;
    }
    switch (keyCode) {
        case 18: {
            return 'ENTER';
        }
        case 32: {
            return 'SPACE';
        }
        case 38: {
            return 'UP';
        }
        case 40: {
            return 'DOWN';
        }
        case 37: {
            return 'LEFT';
        }
        case 39: {
            return 'RIGHT';
        }
        default: {
            return false;
        }
    }
};
export const isParent = (node) => node.children && node.children.length > 0;
export const hasParent = (node) => !!node.parent;
export const createId = (id, prefix) => `${prefix}_${id}`;
export const get = memoize(1000)((id, dataset) => dataset[id]);
export const getParent = memoize(1000)((id, dataset) => {
    const item = get(id, dataset);
    if (!item || !item.parent) {
        return undefined;
    }
    return get(item.parent, dataset);
});
export const getParents = memoize(1000)((id, dataset) => {
    const parent = getParent(id, dataset);
    return parent ? [parent, ...getParents(parent.nodeId, dataset)] : [];
});
export const getNeighbors = memoize(1000)((id, dataset) => {
    const parent = getParent(id, dataset);
    return parent ? getChildren(parent, dataset) : [];
});
export const getChildren = memoize(1000)((parent, dataset) => { var _a; return ((_a = parent === null || parent === void 0 ? void 0 : parent.children) === null || _a === void 0 ? void 0 : _a.map(id => get(id, dataset))) || []; });
export const markAllChecked = (treeNodes, dataset) => {
    treeNodes.forEach(treeNode => {
        treeNode.checkbox = 'ALL';
        if (treeNode.children && treeNode.children.length > 0) {
            const children = getChildren(treeNode, dataset);
            markAllChecked(children, dataset);
        }
    });
};
export const markAllUnchecked = (treeNodes, dataset) => {
    treeNodes.forEach(treeNode => {
        treeNode.checkbox = 'NONE';
        if (isParent(treeNode)) {
            const children = getChildren(treeNode, dataset);
            markAllUnchecked(children, dataset);
        }
    });
};
export const getChildSelectionCount = (children) => children.filter(({ checkbox }) => ['ALL', 'PARTIAL', 'PARENT'].includes(checkbox)).length;
export const recursiveSetSelectionToChildren = (treeNodes, dataset) => {
    treeNodes.forEach(treeNode => {
        if (isParent(treeNode)) {
            const children = getChildren(treeNode, dataset);
            treeNode.selectedChildCount = getChildSelectionCount(children);
            recursiveSetSelectionToChildren(children, dataset);
        }
    });
};
export const recursiveSetSelectionToParents = recursiveSetSelectionToChildren;
export const getMains = memoize(1)((dataset) => toList(dataset).filter(m => !m.parent));
const getMainsKeys = memoize(1)((dataset) => getMains(dataset).map(m => m.nodeId));
export const getPrevious = ({ id, dataset, expanded, }) => {
    // STEP 1
    // find parent
    // if no previous sibling, use parent
    // unless parent is root
    //
    // STEP 2
    // find previous sibling
    // recurse into that sibling's last children that are expanded
    const current = get(id, dataset);
    const parent = getParent(id, dataset);
    const mains = getMainsKeys(dataset);
    const siblings = (parent === null || parent === void 0 ? void 0 : parent.children) || mains;
    const index = siblings.indexOf(current.nodeId);
    if (index === 0) {
        return parent || undefined;
    }
    let item = get(siblings[index - 1], dataset);
    while (item.children && expanded[item.nodeId]) {
        item = get(item.children.slice(-1)[0], dataset);
    }
    return item;
};
export const getNext = ({ id, dataset, expanded, }) => {
    // STEP 1:
    // find any children if the node is expanded, first child
    //
    // STEP 2
    // iterate over parents, + fake 'root':
    // - find index of last parent as child in grandparent
    // - if child has next sibling, return
    // - if not, continue iterating
    const current = get(id, dataset);
    if (!current) {
        return undefined;
    }
    const { children } = current;
    if (children && children.length && expanded[current.nodeId]) {
        return get(children[0], dataset);
    }
    const mains = getMainsKeys(dataset);
    const parents = [...getParents(id, dataset), { children: mains, nodeId: '', name: '' }];
    const next = parents.reduce((acc, item) => {
        if (acc.result) {
            return acc;
        }
        const parent = item;
        const siblings = parent.children || mains;
        const index = siblings.indexOf(acc.child ? acc.child.nodeId : '');
        return siblings[index + 1] ? { result: get(siblings[index + 1], dataset) } : { child: parent };
    }, { child: current, result: undefined });
    return next.result;
};
const exactMatch = memoize(1)((filter, searchField = '', node) => normalize(node[searchField]).includes(normalize(filter)) || normalize(node.parent || '').includes(normalize(filter)));
export const toId = (base, addition) => (base === '' ? `${addition}` : `${base}-${addition}`);
export const toFiltered = (dataset, filter, searchField) => {
    const found = toList(dataset).filter(node => {
        var _a;
        return exactMatch(filter, 'name', node) ||
            exactMatch(filter, searchField, node) ||
            ((_a = node.customData) === null || _a === void 0 ? void 0 : _a.some((item) => item.key === 'botId' && normalize(item.value).includes(normalize(filter))));
    });
    // get all parents for all results
    const result = found.reduce((acc, item) => {
        const parents = getParents(item.nodeId, dataset).reduce((pacc, pitem) => (Object.assign(Object.assign({}, pacc), { [pitem.nodeId]: Object.assign({}, pitem) })), {});
        return Object.assign(Object.assign(Object.assign({}, acc), { [item.nodeId]: item }), parents);
    }, {});
    // filter the children of the found items (and their parents) so only found entries are present
    return Object.entries(result).reduce((acc, [k, v]) => (Object.assign(Object.assign({}, acc), { [k]: v.children ? Object.assign(Object.assign({}, v), { children: v.children.filter(c => !!result[c]) }) : v })), {});
};
export const clickOnCheckbox = (treeNode, datasetFromState, options) => {
    let originalNode = datasetFromState[treeNode.nodeId];
    if (originalNode.hasOwnProperty('checkbox')) {
        const checkboxType = originalNode.checkbox;
        //@ts-ignore
        const isAltKeyPressed = (options === null || options === void 0 ? void 0 : options.event) && (options.event.altKey || options.event.nativeEvent.altKey);
        let children = getChildren(originalNode, datasetFromState);
        if (isParent(originalNode)) {
            originalNode.checkbox = 'ALL';
            switch (checkboxType) {
                case 'PARENT':
                    if ((options === null || options === void 0 ? void 0 : options.altClickEnabled) && isAltKeyPressed) {
                        originalNode.checkbox = 'NONE-PARENT';
                    }
                    else {
                        markAllChecked(children, datasetFromState);
                    }
                    break;
                case 'PARTIAL':
                case 'ALL': {
                    originalNode.checkbox = (options === null || options === void 0 ? void 0 : options.altClickEnabled) && isAltKeyPressed ? 'NONE-PARENT' : 'NONE';
                    if (originalNode.checkbox === 'NONE') {
                        markAllUnchecked(children, datasetFromState);
                    }
                    break;
                }
                case 'NONE-PARENT':
                case 'NONE': {
                    if ((options === null || options === void 0 ? void 0 : options.altClickEnabled) && isAltKeyPressed) {
                        const everyChildIsNONE = children.every(({ checkbox }) => checkbox === 'NONE' || checkbox === 'NONE-PARENT');
                        const everyChildIsALL = children.every(child => child.checkbox === 'ALL');
                        originalNode.checkbox = everyChildIsNONE ? 'PARENT' : everyChildIsALL ? 'ALL' : 'PARTIAL';
                    }
                    else {
                        markAllChecked(children, datasetFromState);
                    }
                    break;
                }
                default:
                    markAllChecked(children, datasetFromState);
            }
        }
        else {
            originalNode.checkbox = checkboxType === 'NONE' ? 'ALL' : 'NONE';
        }
        if (hasParent(originalNode)) {
            const parent = getParent(originalNode.nodeId, datasetFromState);
            let parents = getParents(originalNode.nodeId, datasetFromState);
            const neighbours = getNeighbors(originalNode.nodeId, datasetFromState);
            if (parent) {
                parents = without(parents, parent);
                const isAllNeighboursChecked = neighbours.every(neighbour => neighbour.checkbox === 'ALL');
                const isAllNeighboursUnchecked = neighbours.every(neighbour => neighbour.checkbox === 'NONE');
                if (parent.checkbox !== 'NONE-PARENT') {
                    if (isAllNeighboursChecked && originalNode.checkbox !== 'NONE') {
                        parent.checkbox = 'ALL';
                    }
                    if (isAllNeighboursUnchecked) {
                        parent.checkbox = 'NONE';
                    }
                    if (!isAllNeighboursChecked && !isAllNeighboursUnchecked) {
                        parent.checkbox = 'PARTIAL';
                    }
                }
            }
            parents.forEach(parent => {
                if (parent.checkbox === 'NONE-PARENT') {
                    return;
                }
                const children = getChildren(parent, datasetFromState);
                if (children.some(({ checkbox }) => checkbox === 'ALL' || checkbox === 'PARTIAL')) {
                    parent.checkbox = 'PARTIAL';
                    return;
                }
                if (children.every(child => child.checkbox === 'NONE')) {
                    parent.checkbox = 'NONE';
                    return;
                }
                if (children.every(child => child.checkbox === 'ALL')) {
                    parent.checkbox = 'ALL';
                    return;
                }
            });
        }
        if (isParent(originalNode)) {
            originalNode.selectedChildCount = getChildSelectionCount(children);
            recursiveSetSelectionToChildren(children, datasetFromState);
        }
        if (hasParent(originalNode)) {
            recursiveSetSelectionToParents(getParents(originalNode.nodeId, datasetFromState), datasetFromState);
        }
        return Object.assign({}, datasetFromState);
    }
    return {};
};
