import { useRange } from "../utils";
export const useVariableHeight = (direction, initialHeight, minHeight, maxHeight) => {
    const [height, setHeight] = useRange(initialHeight, minHeight, maxHeight);
    const handleTopBoundDrag = (dragEvent, ref) => {
        if (!ref.current)
            return;
        const rect = ref.current.getBoundingClientRect();
        // Chrome sends last dragEvent with 0. Do not react.
        if (!rect || dragEvent.clientY === 0 || dragEvent.clientX < 0)
            return;
        setHeight(rect.bottom - dragEvent.clientY);
    };
    const handleBottomBoundDrag = (dragEvent, ref) => {
        if (!ref.current)
            return;
        const rect = ref.current.getBoundingClientRect();
        // Chrome sends last dragEvent with 0. Do not react.
        if (!rect || dragEvent.clientY === 0 || dragEvent.clientX < 0)
            return;
        setHeight(dragEvent.clientY - rect.top);
    };
    return [
        height,
        direction === 'up' ? handleTopBoundDrag : handleBottomBoundDrag,
    ];
};
export const useVariableWidth = (direction, initialWidth, minWidth, maxWidth) => {
    const [width, setWidth] = useRange(initialWidth, minWidth, maxWidth);
    const handleLeftBoundDrag = (dragEvent, ref) => {
        if (!ref.current)
            return;
        const rect = ref.current.getBoundingClientRect();
        // Chrome sends last dragEvent with 0. Do not react.
        if (!rect || dragEvent.clientX === 0 || dragEvent.clientX < 0)
            return;
        setWidth(rect.right - dragEvent.clientX);
    };
    const handleRightBoundDrag = (dragEvent, ref) => {
        if (!ref.current)
            return;
        const rect = ref.current.getBoundingClientRect();
        // Chrome sends last dragEvent with 0. Do not react.
        if (!rect || dragEvent.clientX === 0 || dragEvent.clientX < 0)
            return;
        setWidth(dragEvent.clientX - rect.left);
    };
    return [
        width,
        direction === 'left' ? handleLeftBoundDrag : handleRightBoundDrag,
    ];
};
