import React, { createContext, FC, useCallback, useContext } from 'react';

import { RandomBody } from 'modules/JGraph/view/parts/RandomBody';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

import { TagNames, TButtonParameters, TJBlock } from '../../utils/types';
import { AnswerBody } from './AnswerBody';
import { IconNames } from './KIcons';
import { ConditionBody } from './ConditionBody';
import { Buttons } from './Buttons';
import { ImageBody } from './ImageBody';
import { TransitionBody } from './TransitionBody';
import { CommonMetaBody } from './CommonMetaBody';
import { t, tWithCheck } from 'localization';
import { useScreenContext } from '../../hooks';
import { AudioBody } from './AudioBody';
import { CustomTagsStore$ } from '../../../../reducers/JGraph.reducer/customTags.store';
import { CustomTagBody } from './CustomTagBody';
import { tagParametersToObj } from 'reducers/JGraph.reducer/utils';

const getButtonsKeys = (tagParameters: TButtonParameters[] = []) => {
  return tagParameters.map(param => `${[param.name, param.transition, param.url].join('_')}`).join('__');
};

const metablockBodyFieldByType: Record<string, string> = {
  [TagNames.Email]: 'destination',
  [TagNames.HttpRequest]: 'url',
};

type TRenderBlocksProps = {
  blocks: TJBlock[];
  activeType?: 'border' | 'background';
  baseWidth?: number;
  prefix?: string;
  externalGlobalIndex?: number;
};
export type RenderBlocksContextType = {
  screen: JStateWithId;
  baseWidth: number;
  activeType: 'border' | 'background';
  prefix: string;
};
export const RenderBlocksContext = createContext<RenderBlocksContextType>({} as RenderBlocksContextType);
export const useRenderBlocksContext = () => useContext(RenderBlocksContext);
export const RenderBlocks: FC<TRenderBlocksProps> = React.memo(
  ({ blocks, baseWidth = 280, activeType = 'border', prefix = '', externalGlobalIndex = 0 }) => {
    const { screen, editMenuBlock } = useScreenContext();

    const checkIsActive = useCallback(
      index => {
        return (
          editMenuBlock?.screen &&
          editMenuBlock.screen?.pathId === screen.pathId &&
          editMenuBlock.jBlockIndex === index &&
          (editMenuBlock.path ? editMenuBlock.path === prefix : !prefix)
        );
      },
      [screen.pathId, editMenuBlock, prefix]
    );
    const checkButtonsIsActive = useCallback(
      index => {
        return (
          editMenuBlock?.screen &&
          editMenuBlock.screen?.pathId === screen.pathId &&
          editMenuBlock.jBlockIndex === index &&
          (editMenuBlock.path ? editMenuBlock.path === prefix || editMenuBlock.path.includes('tagParameters') : !prefix)
        );
      },
      [screen.pathId, editMenuBlock, prefix]
    );

    const isConditionBodyActive = useCallback(
      (providedParentPrefix, index) => {
        if (editMenuBlock?.path) {
          const editMenuSplitPath = editMenuBlock.path.split('_');
          let computedParent = `${editMenuSplitPath[0]}_${editMenuSplitPath[1]}`;
          return (
            editMenuBlock?.screen &&
            editMenuBlock.screen.pathId === screen.pathId &&
            (editMenuBlock.path?.includes('if') || editMenuBlock.path?.includes('else')) &&
            providedParentPrefix.startsWith(computedParent)
          );
        }
        return false;
      },
      [editMenuBlock, screen.pathId]
    );

    const isRandomBodyActive = useCallback(
      (providedParentPrefix, index) => {
        if (!editMenuBlock?.screen || editMenuBlock.screen?.pathId !== screen.pathId) {
          return false;
        }
        if (!editMenuBlock.path) return checkIsActive(index);
        if (!providedParentPrefix) return;
        const editMenuBlockPath = ScreenBlockPath.joinIndexToPath(
          editMenuBlock.path,
          TagNames.random,
          editMenuBlock.jBlockIndex
        );

        return providedParentPrefix === editMenuBlockPath || editMenuBlockPath.startsWith(providedParentPrefix);
      },
      [checkIsActive, editMenuBlock, screen.pathId]
    );

    let index = -1;
    return (
      <RenderBlocksContext.Provider
        value={{
          screen,
          baseWidth: baseWidth,
          activeType: activeType,
          prefix: prefix,
        }}
      >
        {blocks.map(screenBlock => {
          index += 1;
          switch (screenBlock.tagName) {
            case TagNames.a:
              const tagParameters = tagParametersToObj(screenBlock.tagParameters);
              const text = screenBlock.tagValue?.trim();
              const ttsText = tagParameters['tts']?.value;
              const value = text || ttsText || '';
              return (
                <AnswerBody
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  name='AnswerBody'
                  icon={IconNames.answer}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  text={hideRootSlashInPath(value)}
                  maxHeight={5}
                  bottomPadding={8}
                  active={checkIsActive(index)}
                />
              );
            case TagNames.script:
            case TagNames.scriptEs6:
              return (
                <AnswerBody
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  name='ScriptBody'
                  icon={IconNames.script}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  maxHeight={5}
                  text={screenBlock.tagValue || ''}
                  bottomPadding={8}
                  active={checkIsActive(index)}
                />
              );
            case TagNames.if:
            case TagNames.elseif:
            case TagNames.else:
              const providedPrefix = ScreenBlockPath.joinIndexToPath(prefix, screenBlock.tagName, index, blocks);
              let cornerRadius = [8, 8, 8, 8];
              const nextBlock = blocks[index + 1];
              const prevBlock = blocks[index - 1];
              if (nextBlock || prevBlock) {
                switch (screenBlock.tagName) {
                  case TagNames.if: {
                    if (nextBlock && [TagNames.else, TagNames.elseif].includes(nextBlock.tagName)) {
                      cornerRadius = [8, 8, 0, 0];
                    }
                    break;
                  }
                  case TagNames.elseif: {
                    if (prevBlock && [TagNames.elseif, TagNames.if].includes(prevBlock.tagName)) {
                      cornerRadius = [0, 0, 8, 8];
                    }
                    if (nextBlock && TagNames.elseif === nextBlock.tagName) {
                      cornerRadius[2] = 0;
                      cornerRadius[3] = 0;
                    }
                    if (nextBlock && TagNames.else === nextBlock.tagName && nextBlock.jblocks.length > 0) {
                      cornerRadius[2] = 0;
                      cornerRadius[3] = 0;
                    }
                    break;
                  }
                  case TagNames.else: {
                    if (prevBlock && [TagNames.elseif, TagNames.if].includes(prevBlock.tagName)) {
                      cornerRadius = [0, 0, 8, 8];
                    }
                    break;
                  }
                }
              }

              return (
                <ConditionBody
                  currentContextIndex={index}
                  cornerRadius={cornerRadius}
                  name={`ConditionBody_${screenBlock.tagName}`}
                  globalJBlockIterator={index + externalGlobalIndex}
                  key={providedPrefix}
                  screenBlock={screenBlock}
                  prefix={providedPrefix}
                  editMenuBlock={editMenuBlock}
                  active={isConditionBodyActive(providedPrefix, index)}
                  bottomPadding={4}
                  yOffset={cornerRadius[0] === 8 && cornerRadius[1] === 8 ? 4 : 0}
                  currentLevelBlocks={blocks}
                />
              );
            case TagNames.random:
              const providedPath = ScreenBlockPath.joinIndexToPath(prefix, TagNames.random, index);
              return (
                <RandomBody
                  key={`${screenBlock.tagName}-${index}`}
                  name='RandomBody'
                  prefix={prefix}
                  globalJBlockIterator={index}
                  icon={IconNames.random}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  blocks={screenBlock.jblocks}
                  active={isRandomBodyActive(providedPath, index)}
                />
              );
            case TagNames.buttons:
            case TagNames.inlineButtons:
              return (
                <Buttons
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}-${getButtonsKeys(
                    screenBlock.tagParameters as TButtonParameters[]
                  )}`}
                  name='Buttons'
                  icon={IconNames.buttons}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  buttons={(screenBlock.tagParameters || []) as TButtonParameters[]}
                  screenBlock={screenBlock}
                  active={checkButtonsIsActive(index)}
                />
              );

            case TagNames.image:
              return (
                <ImageBody
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  name='ImageBody'
                  icon={IconNames.image}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  src={screenBlock.tagValue || ''}
                  active={checkIsActive(index)}
                />
              );
            case TagNames.audio:
              const tagParametersObj = tagParametersToObj(screenBlock.tagParameters);
              return (
                <AudioBody
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  name='AudioBody'
                  icon={IconNames.audio}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  text={
                    tagParametersObj.name?.value ? String(tagParametersObj.name?.value) : screenBlock.tagValue || ''
                  }
                  bottomPadding={8}
                  active={checkIsActive(index)}
                />
              );
            case TagNames.go:
            case TagNames.go_:
              return (
                <TransitionBody
                  name='transition'
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  icon={IconNames.transition}
                  title={t(`ScreenBody:BlockTitle_${screenBlock.tagName}`)}
                  placeholder={screenBlock.tagValue ? undefined : t(`ScreenBody:Block_go_placeholder`)}
                  text={hideRootSlashInPath(screenBlock.tagValue || '')}
                  bottomPadding={8}
                  screenBlock={screenBlock}
                  active={checkIsActive(index)}
                />
              );
            case TagNames.HttpRequest:
            case TagNames.Email:
              screenBlock = screenBlock as TJBlock<typeof screenBlock.tagName>;
              const bodyTextIndex = screenBlock.tagParameters.findIndex(
                tagParam => tagParam.name === metablockBodyFieldByType[screenBlock.tagName]
              );

              return (
                <CommonMetaBody
                  name={screenBlock.tagName}
                  bodyText={(screenBlock.tagParameters[bodyTextIndex]?.value as string) || ''}
                  title={t(`${screenBlock.tagName}:title`)}
                  globalJBlockIterator={index}
                  key={`${screenBlock.tagName}-${index}`}
                  icon={IconNames[screenBlock.tagName as keyof typeof IconNames]}
                  bottomPadding={4}
                  screenBlock={screenBlock as TJBlock<TagNames.Email>}
                  active={checkIsActive(index)}
                />
              );
            default:
              const customBlockListNames = CustomTagsStore$.getValue().map(({ tagName }) => tagName);
              const isCustomBlock = customBlockListNames.includes(screenBlock.tagName);
              if (isCustomBlock) {
                const descriptor = CustomTagsStore$.getValue().find(({ tagName }) => tagName === screenBlock.tagName);
                if (descriptor) {
                  return (
                    <CustomTagBody
                      descriptor={descriptor}
                      name={screenBlock.tagName}
                      title={
                        tWithCheck(`CustomTag:${screenBlock.tagName}:caption`) ||
                        tWithCheck(`${screenBlock.tagName}:title`) ||
                        screenBlock.tagName
                      }
                      globalJBlockIterator={index}
                      key={`${screenBlock.tagName}-${index}`}
                      icon={IconNames.customTag}
                      bottomPadding={4}
                      screenBlock={screenBlock as TJBlock<TagNames.Email>}
                      active={checkIsActive(index)}
                    />
                  );
                }
              }
              return null;
          }
        })}
      </RenderBlocksContext.Provider>
    );
  }
);
