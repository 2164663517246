import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '@just-ai/just-ui/dist/Dropdown';
import { ClickAwayListener, useTranslation } from '@just-ai/just-ui';
import classes from './styles.module.scss';
import './style.scss';
export default function FAQTabContextMenu(props) {
    const { onDelete, onRename, toggle, position, tabValue, isDisabled, onDisable, writeDisabled } = props;
    const { t } = useTranslation();
    const handleRenameTab = useCallback(() => {
        onRename(tabValue);
    }, [tabValue, onRename]);
    const handleDeleteTab = useCallback(() => {
        onDelete(tabValue);
    }, [tabValue, onDelete]);
    const handleDisableTab = useCallback(() => {
        onDisable(tabValue);
    }, [onDisable, tabValue]);
    return (_jsxs(Dropdown, { isOpen: !!position, toggle: toggle, direction: 'down', style: {
            zIndex: 3002,
            position: 'fixed',
            left: position.x,
            top: position.y,
        }, children: [_jsx(DropdownToggle, { className: classes.faqContext__toggle }), _jsx(ClickAwayListener, { handleClickOut: toggle, handleEnabled: !!position, children: _jsxs(DropdownMenu, { className: 'faq-tabs-context-menu', id: 'tabContextMenu', children: [_jsx(DropdownItem, { disabled: writeDisabled, onClick: handleRenameTab, iconLeft: 'farPen', children: t('Rename') }), _jsx(DropdownItem, { disabled: writeDisabled, onClick: handleDisableTab, iconLeft: isDisabled ? 'farToggleOn' : 'farToggleOff', children: t(isDisabled ? 'enableSkill' : 'disableSkill') }), _jsx(DropdownItem, { disabled: writeDisabled, className: 'faq-tabs-context-menu__tab-delete', onClick: handleDeleteTab, iconLeft: 'farTrashAlt', children: t('Delete') })] }) })] }));
}
