import React from 'react';
import { CustomRxTooltip, Icon, ICONS } from '@just-ai/just-ui';
import cn from 'classnames';

import classes from './style.module.scss';

interface JGraphStatusWidgetProps {
  title: string;
  icon: ICONS;
  iconTooltip: string;
  onClick: () => void;
}
const JGraphStatusWidget = ({ onClick, title, icon, iconTooltip }: JGraphStatusWidgetProps, ref: any) => {
  return (
    <div ref={ref} className={cn(classes.JGraphStatusWidget)} onClick={onClick}>
      <div className={classes.text}>{title}</div>
      <div className={classes.button}>
        <Icon id='StatusIndicator-WidgetButton' name={icon} size='md' color='secondary' />
      </div>
      <CustomRxTooltip target='#StatusIndicator-WidgetButton' placement='top' textAlign='left'>
        {iconTooltip}
      </CustomRxTooltip>
    </div>
  );
};

export default React.memo(React.forwardRef(JGraphStatusWidget));
