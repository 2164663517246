import moment from 'moment';
import localize from '../localization';
import { tWithCheck, t } from '../localization';

const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => moment(date).format(format);

export const getTimezoneOffsetInSeconds = timezoneOption => {
  if (!timezoneOption || typeof timezoneOption.value !== 'string') return -new Date().getTimezoneOffset() * 60;

  const timezoneString = timezoneOption.value || timezoneOption;
  return moment(timezoneString).utcOffset() * 60;
};
export const secondsToMinutesSeconds = seconds => ({
  minutes: Math.floor(seconds / 60),
  seconds: seconds % 60,
});
export const percentsOf = (piece, whole, precision = 2) =>
  whole ? ((piece / whole) * 100).toFixed(precision) + '%' : '';

export const rgb = (red, green, blue) => ({ red, green, blue });
const GRADIENT_PALETTE_AMERICAN = Object.freeze({
  0: rgb(0, 184, 148),
  12: rgb(0, 206, 201),
  25: rgb(9, 132, 227),
  37: rgb(108, 92, 231),
  50: rgb(99, 110, 114),
  62: rgb(253, 203, 110),
  75: rgb(225, 112, 85),
  100: rgb(232, 67, 147),
});

export const getRgbString = color => `rgb(${color.red}, ${color.green}, ${color.blue})`;
export const getColorPalete = (length, gradientPalette = GRADIENT_PALETTE_AMERICAN) => {
  const gradientKeys = Object.keys(gradientPalette);
  return Array.apply(null, { length }) // Empty array with defined length
    .map((_, index) => {
      const gradientIndex = (index + 1) * (100 / (length + 1));
      for (let j = 0; j < gradientKeys.length; j++) {
        const gradientKey = gradientKeys[j];
        if (gradientIndex === gradientKey) return getRgbString(gradientPalette[gradientKey]);
        else if (gradientIndex < gradientKey) {
          const prevKey = gradientKeys[j - 1];
          const gradientPartIndex = (gradientIndex - prevKey) / (gradientKey - prevKey);
          return getRgbString(
            rgb(
              Math.floor(
                gradientPalette[prevKey].red -
                  (gradientPalette[prevKey].red - gradientPalette[gradientKey].red) * gradientPartIndex
              ),
              Math.floor(
                gradientPalette[prevKey].green -
                  (gradientPalette[prevKey].green - gradientPalette[gradientKey].green) * gradientPartIndex
              ),
              Math.floor(
                gradientPalette[prevKey].blue -
                  (gradientPalette[prevKey].blue - gradientPalette[gradientKey].blue) * gradientPartIndex
              )
            )
          );
        }
      }
      return undefined;
    });
};

export const getCailaConfiguration = () => {
  return {
    basePath: '/cailapub',
  };
};

export const stopPropagationAndDefault = e => {
  e.stopPropagation();
  e.preventDefault();
  return e;
};

export const createPlanPaymentString = (blockType, demo, rawDueDate, manualControl, short, cost, onlyDate) => {
  const dueDate = moment(rawDueDate).utc().subtract(1, 'days');
  let paymentString = '';
  if (cost === 0) return;
  if (blockType === 'NOT_PAYED') {
    if (manualControl) {
      paymentString = localize.translate('status blocked not paid');
    } else {
      paymentString = localize.translate('status not paid');
    }
  } else if (demo) {
    paymentString = `${localize.translate('status demo', moment(dueDate).utc().format('DD.MM.YYYY'))}`;
  } else if (manualControl) {
    paymentString = localize.translate('status agreement');
  } else if (onlyDate) {
    return moment(dueDate).utc();
  } else {
    paymentString = `${
      !short
        ? localize.translate('status paid', moment(dueDate).utc().format('DD.MM.YYYY'))
        : localize.translate('status paid', moment(dueDate).utc().format('DD.MM.YYYY')).split(' ').splice(1).join(' ')
    }`;
  }
  return paymentString;
};

export function retractErrorMessage(data) {
  if (data.error && data.error === 'editorbe.common.common_error') {
    const { message, args, error } = data;
    return [args?.message || message || error];
  }
  return !!data.error ? [data.error] : data.errors;
}

export function getGoogleTag() {
  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('test-ai.net') ? 'GTM-PFWDJ2F' : 'GTM-KHB42L5';
}
export function getYmTag() {
  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('test-ai.net') ? '85193425' : '84112120';
}

export function getChannelNameForBackend(normalName) {
  return (
    {
      ALL: 'all',
      AZURE: 'azure',
      TELEGRAM: 'telegram',
      FACEBOOK: 'facebook',
      INSTAGRAM: 'facebook',
      VIBER: 'viber',
      CHAT_API: 'chatapi',
      CHAT_WIDGET: 'chatwidget',
      TEST_WIDGET: 'testwidget',
      YANDEX: 'yandex',
      MARUSIA: 'marusia',
      RESTERISK: 'resterisk',
      SLACK: 'slack',
      VK: 'vk',
      LIVETEX_PROMPTER: 'levetex_prompter',
      PROMPTER_API: 'prompter_api',
      ZENDESK_PROMPTER: 'zendesk_prompter',
      WECHAT: 'wechat',
      ZENDESK: 'zendesk',
      WEBIM: 'incoming_webim',
      WEBIM2: 'incoming_webim2',
      ZOPIM: 'zopim',
      VOICE_TEST_WIDGET: 'testwidget',
      GOOGLE: 'google',
      ALEXA: 'alexa',
      ZENBOX: 'zenbox',
      BITRIX: 'bitrix',
      CHAT2DESK: 'chat2desk',
      ODNOKLASSNIKI: 'odnoklassniki',
      NEXMO: 'nexmo',
      I_DIGITAL: 'i_digital',
      THREADS: 'threads',
      INCOMING_JIVOSITE: 'incoming_jivosite',
      JAICP_ALEXA: 'Alexa',
      DIALOGFLOW: 'dialogflow',
    }[normalName] || normalName.toLowerCase().replace('_', '')
  );
}

export function extractErrorText(response, label) {
  try {
    const result =
      tWithCheck(
        `${label}:BE-error:${response.data?.error}`,
        t(`${label}:form-${response.data?.args?.field || ''}-label`).toLowerCase()
      ) ||
      tWithCheck(response.data?.error) ||
      tWithCheck(`Errors:${response.data.message}`) ||
      response.data.message ||
      response.data.error;

    return result;
  } catch (e) {
    return 'Error';
  }
}
