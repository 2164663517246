import React, { FC } from 'react';
import { useRightSideMenuContext } from '../../index';
import { PatternActivationEdit } from './PatternActivationEdit';

export const PatternsActivation: FC = () => {
  const { changePattern, eventsState, changeEventParams } = useRightSideMenuContext();
  const { pattern, isGlobal } = eventsState;

  return (
    <PatternActivationEdit
      isFromState={eventsState.addingMenuType !== 'toStateEvents'}
      isToState={eventsState.addingMenuType === 'toStateEvents'}
      eventState={eventsState}
      pattern={pattern}
      changePattern={changePattern}
      isGlobal={isGlobal}
      changeEventParams={changeEventParams}
    />
  );
};
