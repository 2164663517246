import { createContext, FC, useContext, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

import IntentsService from '../../Caila/service/IntentsService';
import SettingsService from '../services/SettingsService';

import { UiMode } from 'types';

export type JGraphContextType = {
  uiMode: UiMode;
  isJGraphLite: boolean;
  IntentsService: IntentsService;
  SettingsService: SettingsService;
  currentBotLanguage: 'RU';
  projectName: string;
};
export const JGraphContext = createContext({
  uiMode: UiMode.DevX_user,
  IntentsService: new IntentsService(-1, ''),
  SettingsService: new SettingsService(-1, ''),
  currentBotLanguage: 'RU',
  projectName: '',
} as JGraphContextType);

export const JGraphProviderSubject$ = new BehaviorSubject<JGraphContextType>({
  uiMode: UiMode.DevX_user,
  isJGraphLite: false,
  IntentsService: new IntentsService(-1, ''),
  SettingsService: new SettingsService(-1, ''),
  currentBotLanguage: 'RU',
  projectName: '',
});

export const JGraphContextProvider: FC<{
  IntentsService: IntentsService;
  SettingsService: SettingsService;
  projectName: string;
  uiMode: UiMode;
}> = ({ IntentsService, SettingsService, uiMode, projectName, children }) => {
  useEffect(() => {
    JGraphProviderSubject$.next({
      uiMode,
      isJGraphLite: uiMode === UiMode.NoCode_user,
      currentBotLanguage: 'RU',
      projectName: projectName,
      IntentsService: IntentsService,
      SettingsService: SettingsService,
    });
  }, [IntentsService, SettingsService, uiMode, projectName]);
  return (
    <JGraphContext.Provider
      value={{
        uiMode,
        isJGraphLite: uiMode === UiMode.NoCode_user,
        currentBotLanguage: 'RU',
        IntentsService: IntentsService,
        SettingsService: SettingsService,
        projectName: projectName,
      }}
    >
      {children}
    </JGraphContext.Provider>
  );
};

export const useJGraphContext = () => useContext(JGraphContext);
