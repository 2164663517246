import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle } from '../Dropdown';
import { getProductName, logoByProduct } from './utils';
import './style.scss';
import { menuButton } from './icons';
const CloudMenu = ({ cloudDomains, countryIsoCode, universalLoginMenuEnabled, translate, isEuroInstance = false, }) => {
    const filteredMenu = Object.keys(cloudDomains).filter(domain => cloudDomains[domain].showInNavigation);
    if (!universalLoginMenuEnabled || (universalLoginMenuEnabled && filteredMenu.length < 2)) {
        return null;
    }
    return (_jsx("li", { className: 'nav-item cloud-menu-wrap', children: _jsxs(DropdownButton, { direction: 'down', children: [_jsx(DropdownToggle, { children: _jsx("div", { className: 'menu-button', children: menuButton }) }), _jsx(DropdownMenu, { className: 'product-dropdown', children: filteredMenu.map(domain => (_jsx(DropdownItem, { children: _jsxs("a", { href: `${window.location.protocol}//${cloudDomains[domain].domain}`, style: countryIsoCode !== 'RU' && cloudDomains[domain].product === 'aimyvoice'
                                ? { pointerEvents: 'none' }
                                : undefined, children: [logoByProduct[getProductName(cloudDomains[domain].product, isEuroInstance)], _jsx("span", { children: translate(`CloudMenu: ${getProductName(cloudDomains[domain].product, isEuroInstance)}`) })] }) }, `header_menu_${cloudDomains[domain].domain}`))) })] }) }));
};
export default CloudMenu;
