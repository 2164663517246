import { useCallback } from 'react';
import Konva from 'konva';
import { getStageFromEvent } from '../../Editor/components/VisualEditor/utils/stageFunctions';
import { getIntersection } from '../utils/blockLayerUtils';
import { updateUsingStageProps } from '../utils/connectionLayerUtils';
import { GroupHighlighterSubject$ } from '../view/GroupHighlighter';
import { ActionLayerEvent$ } from '../view/Layers/ActionLayer/RxSubjects';
import { MoveStateInGroup$ } from '../view/MoveStateInGroup';
import { LabelingToolMenu$ } from '../view/LabelingToolMenu';
import { useStatesStoreRef } from './useStatesStore';

export function useDraggingZIndex() {
  const screenSizesMap = useStatesStoreRef();

  const onStateDragStart = useCallback((event: Konva.KonvaEventObject<DragEvent>) => {
    ActionLayerEvent$.next({ node: event.currentTarget, eventType: 'dragstart' });
    LabelingToolMenu$.next(null);
  }, []);

  const onStateDragEnd = useCallback(
    (event: Konva.KonvaEventObject<DragEvent>) => {
      screenSizesMap.current = {};
      ActionLayerEvent$.next({ node: event.currentTarget, eventType: 'dragend' });
      const toGroupValue = GroupHighlighterSubject$.getValue();
      if (toGroupValue) {
        MoveStateInGroup$.next({
          what: {
            pathId: event.currentTarget.attrs.path,
            stateValue: event.currentTarget.attrs.nameValue,
          },
          where: {
            pathId: toGroupValue.statePath,
            stateValue: toGroupValue.nameValue,
          },
        });
      } else {
        MoveStateInGroup$.next(null);
      }
      GroupHighlighterSubject$.next(undefined);
    },
    [screenSizesMap]
  );

  const onStateDragMove = useCallback(
    (event: Konva.KonvaEventObject<DragEvent>) => {
      const stage = getStageFromEvent(event);
      LabelingToolMenu$.next(null);
      if (stage) {
        const pointerPosition = stage.getPointerPosition();
        let [, toPosition] = updateUsingStageProps(stage, { x: 0, y: 0 }, pointerPosition || { x: 0, y: 0 });
        if (toPosition) {
          const intersection = getIntersection(toPosition, screenSizesMap.current, event.currentTarget);
          GroupHighlighterSubject$.next(intersection ? { ...intersection, type: 'withBottomRect' } : undefined);
          ActionLayerEvent$.next({ node: event.currentTarget, eventType: 'dragmove' });
        }
      }
    },
    [screenSizesMap]
  );

  return {
    dragProps: {
      onDragStart: onStateDragStart,
      onDragEnd: onStateDragEnd,
      onDragMove: onStateDragMove,
    },
  };
}
