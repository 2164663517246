import React, { FC, useCallback, useEffect } from 'react';
import './JGraph.scss';
import { Icon } from '@just-ai/just-ui';

import { t, tWithCheck } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { onAddingMenuElementClick, toggleAddingActionsMenu } from 'reducers/JGraph.reducer';
import { ReactionsTagNames, TActivationTagNames, TagNames, TReactionsTagNames } from 'modules/JGraph/utils/types';
import {
  BotResponsesDefault,
  LogicResponsesDefault,
  MetaResponsesDefault,
} from '../RightSideMenu/RSAddingMenu/responses';
import { buildIcon, mapIconToTagName } from '../RightSideMenu/settings/ScreenBlocksList/ReactionItem';

const AddingMenuItems = () => {
  return [
    {
      title: 'BotResponses',
      actions: BotResponsesDefault,
    },
    {
      title: 'LogicResponses',
      actions: LogicResponsesDefault,
    },
    {
      title: 'MetaResponses',
      actions: MetaResponsesDefault(),
    },
  ];
};

export type TActionsAddingMenuProps = {
  locale?: 'RU' | 'EN';
};

export const ActionsAddingMenu: FC<TActionsAddingMenuProps> = ({ locale = 'RU' }) => {
  const { actionAddingMenuPosition, isAddingMenuOpen, addingMenuOpenType } = useAppSelector(state => ({
    actionAddingMenuPosition: state.JGraphReducer.actionAddingMenuPosition,
    isAddingMenuOpen: state.JGraphReducer.isAddingMenuOpen,
    addingMenuOpenType: state.JGraphReducer.addingMenuOpenType,
  }));
  const dispatch = useAppDispatch();

  const checkKeyboardEvents = useCallback(
    event => {
      if (event.key === 'Escape') dispatch(toggleAddingActionsMenu({ event: false }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAddingMenuOpen) {
      document.addEventListener('keydown', checkKeyboardEvents);
    }

    return () => {
      document.removeEventListener('keydown', checkKeyboardEvents);
    };
  }, [checkKeyboardEvents, isAddingMenuOpen]);

  return (
    <div
      className={`JGraph-addingMenu ${addingMenuOpenType}`}
      style={{
        left: actionAddingMenuPosition.x,
        top: actionAddingMenuPosition.y,
        display: isAddingMenuOpen ? 'flex' : 'none',
      }}
    >
      <div className='JGraph-addingMenu-scrollContainer'>
        {addingMenuOpenType === 'actions' &&
          AddingMenuItems().map(group => (
            <div className='JGraph-addingMenu-group' key={group.title}>
              <div className='JGraph-addingMenu-group-title'>{t(`ActionAddingMenu:${group.title}_title`)}</div>
              {group.actions.map((action, index) => {
                const isReactionIcon = ReactionsTagNames().includes(action as TReactionsTagNames);
                return <AddingMenuItem key={index} isReactionIcon={isReactionIcon} action={action} />;
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

const AddingMenuItem: FC<{ isReactionIcon: boolean; action: string }> = ({ isReactionIcon, action }) => {
  const dispatch = useAppDispatch();

  const selectAddingType = useCallback(() => {
    dispatch(onAddingMenuElementClick(action));
  }, [dispatch, action]);

  return (
    <div className='JGraph-addingMenu-action' key={action} onClick={selectAddingType}>
      {isReactionIcon ? (
        <Icon name={mapIconToTagName[action as TReactionsTagNames] || 'farCube'} />
      ) : (
        buildIcon((action === 'pattern' ? TagNames.q : action) as TActivationTagNames)
      )}
      {isReactionIcon
        ? tWithCheck(`CustomTag:${action}:caption`) || tWithCheck(`ReactionItem ${action}`) || action
        : t(`ActivationItem ${action === 'pattern' ? TagNames.q : action}`, '')}
    </div>
  );
};
