import React from 'react';
import { Button, usePromisifyComponent } from '@just-ai/just-ui';

import { t } from 'localization';

import CreateKhubIntegrationModal from './CreateKhubIntegrationModal';

import styles from './styles.module.scss';

type ConnectKnowledgeBaseButtonProps = {
  onConnect?: () => void;
};
export const ConnectKnowledgeBaseButton = (props: ConnectKnowledgeBaseButtonProps) => {
  const [createIntegrationNode, showCreateIntegration] = usePromisifyComponent(
    (resolve, reject, opened) => {
      if (!opened) return;
      return <CreateKhubIntegrationModal isOpen={opened} onCancel={reject} onSubmit={resolve} />;
    },
    { onResolve: props.onConnect }
  );

  return (
    <>
      <Button
        className={styles.ConnectKnowledgeBaseButton}
        color='primary'
        iconLeft='farCompressAlt'
        data-test-id='KnowledgeBaseLLM.Connect'
        onClick={() => showCreateIntegration().catch(() => {})}
      >
        {t('KnowledgeBaseLLM:Connect')}
      </Button>
      {createIntegrationNode}
    </>
  );
};
