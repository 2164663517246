import React, { FC } from 'react';
import classes from './styles.module.scss';
import { t } from '../../../../../../../localization';
import cn from 'classnames';
import { Icon, InputText, Label } from '@just-ai/just-ui';
import { getPureDisplayValue } from './utils';

type IntentSystemDetailType = {
  systemIntent: string;
};

export const IntentSystemDetail: FC<IntentSystemDetailType> = ({ systemIntent }) => {
  return (
    <div className={classes.IntentDetail}>
      <h3 className='color-gray-800 margin-bottom-8'>{t('CailaIntentsSimple:IntentDetail:title')}</h3>
      <div className={classes.IntentDetail_card}>
        <div className={cn('d-flex flex-row justify-content-center margin-bottom-16', classes.IntentDetail_warning)}>
          <Icon name='faBolt' />
          {t('CailaIntentsSimple:IntentSystemDetail:warning_text')}
        </div>
        <Label>{t('CailaIntentsSimple:IntentDetail:Name')}</Label>
        <InputText
          className={classes.disabledInputContrast}
          fullWidth
          value={t(`ChooseReadyIntent ${systemIntent}`)}
          disabled
        />
        <div className='d-flex margin-top-16 flex-column w-100 gap-4'>
          <b className='mb-0'>{t('CailaIntentsSimple:IntentSystemDetail:description_title')}</b>
          <p className='mb-0'>
            {t(`CailaIntentsSimple:IntentSystemDetail:description:${getPureDisplayValue(systemIntent)}`)}
          </p>
        </div>
      </div>
    </div>
  );
};
