import React from 'react';
import { Rect } from 'react-konva';
import Konva from 'konva';

export const RectWithBorder = React.forwardRef<
  Konva.Rect | null,
  { x?: number; y?: number; cornerRadius?: number | number[]; height?: number; width?: number; fill?: string }
>(({ x = 0, y = 0, cornerRadius = 8, height = 36, width = 280 - 24, fill = 'white' }, ref) => {
  return (
    <Rect
      ref={ref}
      x={x}
      y={y}
      cornerRadius={cornerRadius}
      width={width}
      strokeWidth={1}
      stroke='#E0E1E3'
      fill={fill}
      height={height}
    />
  );
});
