import { BaseFormatter, FormatResult, isSyntaxError } from './BaseFormatter';

export class YamlFormatter implements BaseFormatter {
  async format(code: string): Promise<FormatResult> {
    const prettier = await import(
      /* webpackChunkName: "prettier" */
      /* webpackMode: "lazy-once" */
      'prettier'
    );
    const prettierYamlParser = await import(
      /* webpackChunkName: "prettier-parser-yaml" */
      /* webpackMode: "lazy-once" */
      'prettier/parser-yaml'
    );
    try {
      return [
        await prettier.format(code, {
          parser: 'yaml',
          //@ts-ignore
          plugins: [prettierYamlParser],
        }),
        null,
      ];
    } catch (e) {
      if (isSyntaxError(e)) return [null, e];
    }
    return ['', null];
  }
}
