import { encode } from 'js-base64';
import { CustomTagData, CustomTagParameterData, CustomTagParameterType, GraphV2Data } from '../api/client';

import { ActionButtons, Block, ContextRules, Intent, MetaBlock, SimpleBlock } from './Block';

export type TransitionType =
  | 'buttons'
  | 'condition'
  | 'answers'
  | 'answer'
  | 'image'
  | 'audios'
  | 'script'
  | 'transition'
  | 'random'
  | 'intents'
  | 'immediate';

export type Transition = {
  path: string;
  type: TransitionType;
  label: string;
  innerPath: number[];
  outerType: TransitionOuterType;
  offsetTop: number;
};

export type TransitionOuterType =
  | 'state'
  | '_if'
  | '_elseif'
  | '_else'
  | 'random'
  | 'button'
  | 'customTag'
  | 'events'
  | 'examples'
  | 'intents'
  | 'patterns';

export const ORIGINAL_TRANSITIONS: TransitionOuterType[] = [
  'state',
  '_if',
  '_elseif',
  '_else',
  'random',
  'button',
  'customTag',
];
export const CUSTOM_TRANSITIONS: TransitionOuterType[] = ['events', 'examples', 'intents', 'patterns'];

export type Connection = {
  from: string;
  to: string;

  type: string;
  label: string;
  innerPath: number[];
  outerType: TransitionOuterType;
};

export class Graph {
  path: string = '';
  lastModified: number = 0;
  blocks: Block[] = [];
  connections: Connection[] = [];
}

export type GraphData = {
  rootStatePath: string;
  rootFilePath?: string | null;
  blocks: Block[];
};

export function dtoToGraph(dto: GraphV2Data, customTags: CustomTagData[]): Graph {
  const content = dto.content as GraphData;
  const blocks = getBlocksFromContent(content);
  const connections = getConnectionsFromContent(blocks, customTags);
  return {
    path: dto.file ? dto.file : '',
    lastModified: dto.lastModified ? dto.lastModified : 0,
    blocks,
    connections,
  };
}

function getBlocksFromContent(content: GraphData) {
  const blocks = content.blocks;
  blocks.forEach(block => {
    block.id = encode(block.statePath);
    if (block.type === 'metaBlock' && block.parameters.actions) {
      try {
        block.parameters.actions = JSON.parse(block.parameters.actions);
      } catch (e) {}
    }
  });

  return blocks;
}

function addConnectionsOfType(
  customTag: CustomTagParameterData,
  globalIndex: number,
  block: MetaBlock,
  connections: Connection[]
) {
  if (!customTag.name || !block.parameters[customTag.name]) return;

  switch (customTag.type) {
    case CustomTagParameterType.State:
      const connection: Connection = {
        from: block.id,
        to: encode(block.parameters[customTag.name]),
        type: customTag.name,
        label: '',
        innerPath: [globalIndex],
        outerType: 'state',
      };
      connections.push(connection);
      return;

    case CustomTagParameterType.Json:
      block.parameters[customTag.name].forEach((action: ActionButtons, actionGroup: number) => {
        action.buttons.forEach((action, index) => {
          const connection: Connection = {
            from: block.id,
            to: encode(action.transition),
            type: customTag.name || '',
            label: '',
            innerPath: [globalIndex, actionGroup, index],
            outerType: 'button',
          };
          connections.push(connection);
        });
      });
      return;

    case CustomTagParameterType.Intents:
      (block.parameters[customTag.name] as Intent[]).forEach((intent, index) => {
        const connection: Connection = {
          from: block.id,
          to: encode(intent.then),
          type: 'intents',
          label: '',
          innerPath: [globalIndex, index],
          outerType: 'intents',
        };
        connections.push(connection);
      });
      if (block.parameters['fallback']) {
        connections.push({
          from: block.id,
          to: encode(block.parameters['fallback']),
          type: 'intents',
          label: '',
          innerPath: [globalIndex, block.parameters[customTag.name].length],
          outerType: 'intents',
        });
      }
      return;
  }
}

function addConnectionsFromBlock(block: SimpleBlock, connections: Connection[]) {
  if (!block.transitions) return;

  if (block.activation) {
    if (block.activation.patterns) {
      connections.push(...buildSpecialConnectors(block.id, 'patterns', block.activation.patterns));
    }
    if (block.activation.examples) {
      connections.push(...buildSpecialConnectors(block.id, 'examples', block.activation.examples));
    }
    if (block.activation.intents) {
      connections.push(...buildSpecialConnectors(block.id, 'intents', block.activation.intents));
    }
    if (block.activation.events) {
      connections.push(...buildSpecialConnectors(block.id, 'events', block.activation.events));
    }
  }

  connections.push(
    ...block.transitions.map(transition => ({
      ...transition,
      from: block.id,
      to: encode(transition.path),
    }))
  );
}

function getConnectionsFromContent(blocks: Block[], customTags: CustomTagData[]) {
  if (!customTags) return [];

  return blocks.flatMap(block => {
    const connections: Connection[] = [];

    if (block.type === 'metaBlock') {
      const descriptor = getDescriptor(customTags, block.customTag);
      const types = getMetaConnectionTypes(descriptor.parameters);
      types.forEach((type, globalIndex) => addConnectionsOfType(type, globalIndex, block, connections));
    }

    if (block.type === 'block') {
      addConnectionsFromBlock(block, connections);
    }
    return connections;
  });
}

export function getDescriptor(customTags: CustomTagData[], type: string): CustomTagData {
  return customTags.find(tag => tag.tagName === type) || {};
}

function getMetaConnectionTypes(parameters: CustomTagParameterData[] = []): CustomTagParameterData[] {
  return parameters.filter(
    param =>
      param.type === CustomTagParameterType.State ||
      param.type === CustomTagParameterType.Intents ||
      (param.name === 'actions' && param.type === CustomTagParameterType.Json)
  );
}

function buildSpecialConnectors(id: string, outerType: TransitionOuterType, special: ContextRules): Connection[] {
  if (!special.context) return [];

  const connectors: Connection[] = [];
  special.context.forEach((contextConnector, index) => {
    if (contextConnector.toState) {
      connectors.push({
        from: id,
        to: encode(contextConnector.toState),

        outerType: outerType,
        label: 'toState',
        innerPath: [1, index],

        type: 'immediate',
      });
    }
    if (contextConnector.fromState) {
      connectors.push({
        from: id,
        to: encode(contextConnector.fromState),

        outerType: outerType,
        label: 'fromState',
        innerPath: [0, index],

        type: 'immediate',
      });
    }
  });
  return connectors;
}
