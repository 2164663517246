import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '../../Icon';
import cn from 'classnames';
export const JustSelectContainer = ({ focused, onFocus, selected, inputPlaceholder, searchValue, onChange, iconLeft, autoFocus, invalid, disabled, withoutSearch, size = 'md', dropdownIconName = 'faJustSelect', dataTestId, closeable, blockHtml, }) => {
    var _a, _b;
    const inputElement = useRef(null);
    const handleClickArrow = (evt) => {
        const previousNode = evt.currentTarget.previousSibling;
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'DIV') {
            const justSelect = previousNode.previousSibling;
            justSelect === null || justSelect === void 0 ? void 0 : justSelect.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'SPAN') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'INPUT') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
    };
    useEffect(() => {
        var _a;
        if (autoFocus) {
            (_a = inputElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (_jsxs("div", { className: classNames(`just-select-container ${invalid ? 'error' : ''} ${size}`, { focused: focused }, { closeable }, { disabled: disabled }), children: [iconLeft && _jsx(Icon, { wrapperClassName: 'leftIcon', name: iconLeft, size: size }), withoutSearch ? (_jsx("span", { className: cn('single', {
                    withLeftIcon: iconLeft,
                }), tabIndex: 0, onKeyDown: e => {
                    e.preventDefault();
                }, onFocus: onFocus, "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single`, dangerouslySetInnerHTML: blockHtml ? undefined : { __html: (_a = selected === null || selected === void 0 ? void 0 : selected[0]) === null || _a === void 0 ? void 0 : _a.label },
                children: blockHtml ? (_b = selected === null || selected === void 0 ? void 0 : selected[0]) === null || _b === void 0 ? void 0 : _b.label : undefined })) : (_jsx("input", { ref: inputElement, type: 'text', className: cn('single', {
                    withLeftIcon: iconLeft,
                }), "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single_search`, onFocus: onFocus, placeholder: selected.length > 0 ? selected[0].label : inputPlaceholder, value: selected.length > 0 && !focused && !searchValue ? selected[0].label : searchValue, onChange: onChange, onSubmit: e => e.stopPropagation(), autoFocus: autoFocus, "aria-invalid": invalid, disabled: disabled })), _jsx("div", { className: 'just-select-container-open', onClick: handleClickArrow, children: _jsx(Icon, { name: dropdownIconName, size: size }) })] }));
};
