import React, { Fragment } from 'react';
import { Icon, Tabs, Button, Spinner } from '@just-ai/just-ui';
import { t } from '../../localization';
import { Alert } from 'reactstrap';
import isAccess, { isReporterEnabled, isConfigEnabled } from '../../isAccessFunction';

export function FormHeader({
  success,
  fetching,
  nluSettingsError,
  nameError,
  repositoryUrlError,
  tab,
  setActiveTab,
  error,
  errorArgs,
  isJaicfProject,
  editableProject,
}) {
  let errorHtml = '';
  if (error) {
    if (error.map) {
      errorHtml = error.map(text => t(text)).join('<br>');
    } else {
      errorHtml += t(error);
    }
  }

  let tabs = [
    {
      name: t('ProjectEditForm tabs name'),
      value: '1',
      error: nameError,
      dataTestId: 'projectsPage.createProjectModal.tab.name',
    },
  ];

  if ((isJaicfProject && isAccess(['framework.cloud'])) || !isJaicfProject) {
    tabs = [
      ...tabs,
      {
        name: t('ProjectEditForm tabs placement'),
        value: '2',
        error: repositoryUrlError,
        dataTestId: 'projectsPage.createProjectModal.tab.placement',
      },
    ];
  }

  if (isAccess(['BOT_CONTENT_READ', 'nlu'])) {
    tabs = [
      ...tabs,
      {
        name: t('ProjectEditForm tabs classifier'),
        value: '3',
        dataTestId: 'projectsPage.createProjectModal.tab.classifier',
      },
    ];
  }

  if (isAccess(['NLU_READ', 'nlu'])) {
    tabs = [
      ...tabs,
      {
        name: t('ProjectEditForm tabs NLU settings'),
        value: '4',
        error: nluSettingsError,
        dataTestId: 'projectsPage.createProjectModal.tab.nluSettings',
      },
    ];
  }

  if (isAccess(['dialogs.cleanup'])) {
    tabs = [
      ...tabs,
      {
        name: t('ProjectEditForm tabs other'),
        value: '5',
        dataTestId: 'projectsPage.createProjectModal.tab.other',
      },
    ];
  }

  if (!isJaicfProject && isReporterEnabled() && editableProject.shortName) {
    tabs = [
      ...tabs,
      {
        name: t('ProjectEditForm tabs API tokens'),
        value: '6',
        dataTestId: 'projectsPage.createProjectModal.tab.tokens',
      },
    ];
  }

  return (
    <Fragment>
      {success && (
        <div className='spinner animated fadeIn'>
          <i className='fa fa-check-circle-o color-success' />
        </div>
      )}
      {fetching && <Spinner size='4x' style={{ position: 'sticky', maxHeight: 'inherit', height: '100vh' }} />}
      <Tabs activeTab={tab} name='tabs' className='createProjectModalTabs' onChange={setActiveTab} tabs={tabs} />
      {isConfigEnabled('showHelp') && (
        <div className='help-link'>
          <a
            href={isJaicfProject ? t('ProjectEditForm helpLink JAICF') : t('ProjectEditForm helpLink')}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('ProjectEditForm helpLink text')}
            <Icon name='faQuestionCircle' size='sm' color='primary' />
          </a>
        </div>
      )}
      {!!errorHtml && tab !== '4' && (
        <div style={{ width: '100%' }}>
          <div>
            <Alert className='edit-form__alert' color='danger'>
              <div
                data-test-id='projectsPage.createProjectModal.errorAlert'
                dangerouslySetInnerHTML={{ __html: errorHtml }}
              />
              {errorArgs?.message && (
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => navigator.clipboard && navigator.clipboard.writeText(errorArgs?.message)}
                >
                  {t('notifications copy buffer button')}
                </Button>
              )}
            </Alert>
          </div>
        </div>
      )}
    </Fragment>
  );
}
