import React from 'react';
import { Icon } from '@just-ai/just-ui';

import styles from './styles.module.scss';

interface BottomLeftTriangleWrapperInterface {
  children: React.ReactChild;
}
const BottomLeftTriangleWrapper = function ({ children }: BottomLeftTriangleWrapperInterface) {
  return (
    <div className={styles.BottomLeftTriangleWrapper}>
      {children}
      <Icon wrapperClassName={styles.BottomLeftTriangleWrapper__icon} name='faCaretDown' size='xs' />
    </div>
  );
};

export default React.memo(BottomLeftTriangleWrapper);
