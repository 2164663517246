import React, { PureComponent, FC } from 'react';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VirtualList from 'react-tiny-virtual-list';
import ResizeObserver from 'react-resize-observer';
import { ALIGNMENT } from 'react-tiny-virtual-list/types/constants';
import { isEqual } from 'lodash';

import { safeWhile } from 'utils/safeWhile';
import KeyboardService from 'services/KeyboardService';

import Leaf from './NodesView/Leaf';
import Branch from './NodesView/Branch';
import DragArea from './NodesView/DragArea';
import { TreeStructure } from './TreeStructure';

import { TreeNode } from './types';

import styles from './styles.module.scss';

export const TREE_DEPTH_PADDING = 16;

VirtualList.prototype.scrollTo = function (value) {
  // @ts-ignore
  this.rootNode.scrollTo({ top: value, behavior: 'smooth' });
};

type TreeContextType = {
  selectedIds: string[];
  activeId?: string;
  expandedMap: Record<string, boolean>;
  onExpandToggle: (node: TreeNode) => void;
  onItemClick: (node: TreeNode) => void;
  onItemDoubleClick: (node: TreeNode) => void;
  maxDepthLevel: number;
  handleContextMenu: (e: React.MouseEvent, node: TreeNode) => void;
  onDragDrop: (firstNode: TreeNode, secondNode: TreeNode) => void;
  treeNodeView?: FC<TreeNodeViewProps>;
  isDndEnabled?: boolean;
};

export const TreeContext = React.createContext<TreeContextType>({
  expandedMap: {},
  selectedIds: [],
  onExpandToggle: () => {},
  onItemClick: () => {},
  onItemDoubleClick: () => {},
  maxDepthLevel: Infinity,
  onDragDrop: () => {},
  handleContextMenu: () => {},
});
export type TreeNodeViewProps<NODE extends TreeNode = TreeNode> = {
  node: NODE;
  onToggle: () => void;
  expanded: boolean;
};

interface TreePropsInterface<NODE extends TreeNode = TreeNode> {
  className?: string;
  treeLogicState: TreeStructure<NODE>;
  onItemClick?: (node: NODE) => void;
  onItemDoubleClick?: (node: NODE) => void;
  handleContextMenu: (e: React.MouseEvent, targetNode?: NODE, nodes?: NODE[]) => void;
  onKeyDownOnNode?: (node: NODE, event?: KeyboardEvent) => void;
  activeId?: string;
  writeDisabled?: boolean;
  treeNodeView?: (props: TreeNodeViewProps<NODE>) => React.ReactNode;
  cmpRef?: React.MutableRefObject<Tree<NODE> | undefined>;
  expandedMap?: Record<string, boolean> | null;
  onExpandChange?: (expandedMap: Record<string, boolean>) => void;
}
interface TreeStateInterface {
  selectedIds: string[];
  expandedMap: Record<string, boolean>;
  maxContainerHeight: number;
  activeId?: string;
  preventSelections: boolean;
}
class Tree<NODE extends TreeNode = TreeNode> extends PureComponent<TreePropsInterface<NODE>, TreeStateInterface> {
  keyboardService: KeyboardService;
  shiftPressed: boolean = false;
  ctrlOrCmdPressed: boolean = false;
  lastSelectedId: string | null = null;
  containerRef: React.RefObject<HTMLDivElement>;
  scrollRef: React.RefObject<VirtualList>;
  indexMap: [number, Set<string>][] = [];

  state: TreeStateInterface = {
    selectedIds: [],
    expandedMap: {},
    maxContainerHeight: 0,
    preventSelections: false,
  };

  constructor(props: TreePropsInterface<NODE>) {
    super(props);
    this.containerRef = React.createRef();
    this.keyboardService = new KeyboardService();
    this.scrollRef = React.createRef();

    if (props.cmpRef) {
      props.cmpRef.current = this;
    }
  }

  onContainerFocus = () => {
    this.bindKeys();
  };
  onContainerBlur = () => {
    this.unbindKeys();
  };

  componentDidMount() {
    this.containerRef.current?.addEventListener('focus', this.onContainerFocus);
    this.containerRef.current?.addEventListener('blur', this.onContainerBlur);
    this.recalculateContainerHeight();

    if (this.props.expandedMap) {
      this.setState({ expandedMap: this.props.expandedMap });
    }
    if (this.props.activeId !== undefined) {
      this.lastSelectedId = this.props.activeId;

      let newState = {
        activeId: this.props.activeId,
        selectedIds: [this.props.activeId],
        expandedMap: this.state.expandedMap,
      };

      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this.unbindKeys();
    this.containerRef.current?.removeEventListener('focus', this.onContainerFocus);
    this.containerRef.current?.removeEventListener('blur', this.onContainerBlur);
  }

  componentDidUpdate(prevProps: Readonly<TreePropsInterface<NODE>>, prevState: Readonly<TreeStateInterface>) {
    if (this.props.activeId !== prevProps.activeId) this.onChangeActiveId();

    if (this.props.expandedMap && !isEqual(prevProps.expandedMap, this.props.expandedMap)) {
      this.setState({ expandedMap: this.props.expandedMap });
    } else if (this.state.expandedMap !== prevState.expandedMap) {
      this.onChangeExpandedMap();
    }
  }

  onChangeExpandedMap() {
    this.props.onExpandChange?.(this.state.expandedMap);
  }

  onChangeActiveId() {
    if (this.props.activeId === undefined) {
      this.setState({ activeId: undefined });
      return;
    }
    this.lastSelectedId = this.props.activeId;

    const parentIds: string[] = [];
    let parentNodeId = this.props.treeLogicState.getParentNode(this.props.activeId);
    safeWhile(
      () => !!parentNodeId,
      () => {
        if (!parentNodeId) return false;
        parentIds.push(parentNodeId);
        parentNodeId = this.props.treeLogicState.getParentNode(parentNodeId);
        return !!parentNodeId;
      },
      this.props.treeLogicState.maxDepthLevel
    );

    const expandedMap = { ...this.state.expandedMap };
    for (const id of parentIds) {
      expandedMap[id] = true;
    }

    this.setState({
      activeId: this.props.activeId,
      selectedIds: [this.props.activeId],
      expandedMap,
    });
  }

  selectNode(newSelectedId: string) {
    this.setState({ selectedIds: [newSelectedId] });
    this.lastSelectedId = newSelectedId;
    this.scrollToNode(newSelectedId);
  }

  selectNextVisibleNode = () => {
    const newSelectedId = this.props.treeLogicState.getNextDisplayedNode(this.lastSelectedId, this.state.expandedMap);
    if (!newSelectedId) return;
    this.selectNode(newSelectedId);
  };

  selectPrevVisibleNode = () => {
    const newSelectedId = this.props.treeLogicState.getPrevDisplayedNode(this.lastSelectedId, this.state.expandedMap);
    if (!newSelectedId) return;
    this.selectNode(newSelectedId);
  };

  bindKeys = () => {
    this.keyboardService.bind(
      'shift',
      () => {
        this.shiftPressed = true;
        this.setState({ preventSelections: this.shiftPressed });
      },
      () => {
        this.shiftPressed = false;
        this.setState({ preventSelections: this.shiftPressed });
      }
    );
    this.keyboardService.bind(
      ['ctrl', 'command'],
      () => {
        this.ctrlOrCmdPressed = true;
      },
      () => {
        this.ctrlOrCmdPressed = false;
      }
    );
    this.keyboardService.bind('down', this.selectNextVisibleNode);
    this.keyboardService.bind('up', this.selectPrevVisibleNode);

    this.keyboardService.bind('left', () => {
      if (this.state.selectedIds.length !== 1) return;
      const selectedId = this.state.selectedIds[0];
      if (!selectedId) return;
      const isExpanded = this.state.expandedMap[selectedId];
      if (isExpanded) {
        this.setExpandNodeById(selectedId, false);
        return;
      }

      const node = this.props.treeLogicState.getElementById(selectedId);
      if (!node || !node.parentId) return;
      this.selectNode(node.parentId);
    });

    this.keyboardService.bind('right', () => {
      if (this.state.selectedIds.length !== 1) return;
      const selectedId = this.state.selectedIds[0];
      if (!selectedId) return;

      const node = this.props.treeLogicState.getElementById(selectedId);
      if (!node) return;
      if (node.isFolder) {
        const isExpanded = this.state.expandedMap[selectedId];
        if (!isExpanded) {
          this.setExpandNodeById(selectedId, true);
          return;
        }
      }
      this.selectNextVisibleNode();
    });
    this.keyboardService.bind('', event => {
      if (this.state.selectedIds.length !== 1) return;
      const selectedId = this.state.selectedIds[0];
      if (!selectedId) return;
      const node = this.props.treeLogicState.getElementById(selectedId);
      if (!node) return;

      this.props.onKeyDownOnNode?.(node, event);
    });
  };

  unbindKeys = () => {
    this.keyboardService.unbindAll();
    this.shiftPressed = false;
    this.ctrlOrCmdPressed = false;
  };

  onItemClick = (node: TreeNode) => {
    if (!this.props.treeLogicState) return;

    this.props.onItemClick?.(node as NODE);

    this.lastSelectedId = node.id;
    this.setState({
      selectedIds: [node.id],
    });
  };

  onItemDoubleClick = (node: TreeNode) => {
    if (!this.props.treeLogicState) return;

    this.props.onItemDoubleClick?.(node as NODE);

    this.lastSelectedId = node.id;
    this.setState({
      selectedIds: [node.id],
    });
  };

  onExpandToggle = (node: TreeNode) => {
    const isExpanded = this.state.expandedMap[node.id];
    const newExpandedMap = {
      ...this.state.expandedMap,
      [node.id]: !isExpanded,
    };
    this.setState({ expandedMap: newExpandedMap });
  };

  setExpandNodeById = (nodeId: TreeNode['id'], value: boolean) => {
    const newExpandedMap = {
      ...this.state.expandedMap,
      [nodeId]: value,
    };
    this.setState({ expandedMap: newExpandedMap });
  };

  setCollapseForAll = (value: boolean) => {
    if (!this.props.treeLogicState) return;
    const nodes = this.props.treeLogicState.getViewedTreeAsPlainList();
    const newExpandedMap = nodes.reduce((acc, node) => ({ ...acc, [node.id]: value }), {});
    this.setState({ expandedMap: newExpandedMap });
  };

  getSelectedNodesBy(node: TreeNode): TreeNode[] {
    const isDraggedNodeIsSelected = this.state.selectedIds.includes(node.id);
    if (!isDraggedNodeIsSelected) {
      return [node];
    }
    return this.state.selectedIds
      .map(id => Object.values(this.props.treeLogicState.getViewedTreeAsPlainList()).find(node => node.id === id))
      .filter(Boolean) as TreeNode[];
  }

  onDragDrop = (draggedNode: TreeNode, dropTargetNode: TreeNode) => {};
  onDropInRoot = (draggedNode: TreeNode) => {};

  handleContextMenu = (e: React.MouseEvent, node?: TreeNode) => {
    if (!node) {
      this.props.handleContextMenu(e);
      return;
    }
    const selectedNodes = this.getSelectedNodesBy(node);
    this.setState({ selectedIds: selectedNodes.map(node => node.id) });
    this.props.handleContextMenu(e, node as NODE, selectedNodes as NODE[]);
  };

  getItemHeight(node: TreeNode): number {
    return 24;
  }

  private recalculateContainerHeight = () => {
    const clientRect = this.containerRef.current?.getBoundingClientRect();
    if (!clientRect) return;
    this.setState({
      maxContainerHeight: clientRect.height,
    });
  };

  private setupScrollActions(nodes: TreeNode[]) {
    this.indexMap = [];
    nodes.forEach((node, index) => {
      const containIds = node._containIds;
      if (containIds) {
        this.indexMap.push([index, containIds]);
      }
    });
    this.indexMap.sort((a, b) => a[1].size - b[1].size);
  }

  public scrollToNode(id: string) {
    const mapElement = this.indexMap.find(el => el[1].has(id));
    if (!mapElement) return;
    this.scrollRef.current?.scrollTo(this.scrollRef.current?.getOffsetForIndex(mapElement[0]));
  }

  private renderVirtualList() {
    if (!this.props.treeLogicState) return;
    const nodes = this.props.treeLogicState.getViewedTreeAsPlainList(this.state.expandedMap);
    this.setupScrollActions(nodes);

    return (
      <VirtualList
        itemCount={nodes.length}
        height={this.state.maxContainerHeight}
        className={cn('just-ui-custom-scrollbar just-ui-custom-scrollbar-horizontal', {
          [styles.noselect]: this.state.preventSelections,
        })}
        itemSize={index => this.getItemHeight(nodes[index])}
        scrollToAlignment={'center' as ALIGNMENT.CENTER}
        width='100%'
        ref={this.scrollRef}
        overscanCount={0}
        renderItem={itemInfo => {
          const node = nodes[itemInfo.index];
          return (
            <div style={{ ...itemInfo.style, minWidth: '100%', width: 'auto' }} key={node.nodeId}>
              {node.isFolder ? <Branch node={node} /> : <Leaf node={node} />}
              {!this.props.writeDisabled && <DragArea onDrop={this.onDropInRoot} />}
            </div>
          );
        }}
      />
    );
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <TreeContext.Provider
          value={{
            activeId: this.state.activeId,
            selectedIds: this.state.selectedIds,
            expandedMap: this.state.expandedMap,
            onExpandToggle: this.onExpandToggle,
            onItemClick: this.onItemClick,
            onItemDoubleClick: this.onItemDoubleClick,
            maxDepthLevel: this.props.treeLogicState.maxDepthLevel,
            onDragDrop: this.onDragDrop,
            handleContextMenu: this.handleContextMenu,
            treeNodeView: this.props.treeNodeView as FC<TreeNodeViewProps>,
            isDndEnabled: !this.props.writeDisabled,
          }}
        >
          <div
            ref={this.containerRef}
            tabIndex={0}
            className={cn(styles.tree, this.props.className)}
            onContextMenu={this.handleContextMenu}
          >
            {this.renderVirtualList()}
            <ResizeObserver onResize={this.recalculateContainerHeight} />
          </div>
        </TreeContext.Provider>
      </DndProvider>
    );
  }
}

export default Tree;
