import React, { FC, useCallback, useState } from 'react';
import { Button, useDebounceFn } from '@just-ai/just-ui';
import { v4 as uuid } from 'uuid';

import { t } from 'localization';

import safeJsonParse from 'utils/safeJsonParse';

import { CommonFieldProps } from './types';
import { withController } from './Controller';
import { StringArrayItem } from './StringArrayItem.field';
import CustomTagWarningBlock from './CustomTagWarningBlock';
import { CommonLabel } from './CommonLabel';

const prepareValues = (value: string | string[]): { key: string; value: string }[] => {
  let stringArray: string[] = [];
  if (typeof value === 'string') {
    stringArray = safeJsonParse(value) ?? [];
  }
  if (Array.isArray(value)) {
    stringArray = value;
  }
  if (!Array.isArray(stringArray)) {
    stringArray = [];
  }
  return stringArray.map(str => ({
    key: uuid(),
    value: str || '',
  }));
};

const StringArrayView: FC<CommonFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  onBlur,
  type,
  isValidFormat,
  invalid,
  description,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  const [innerValues, setInnerValues] = useState(prepareValues(value));

  const triggerOnChange = useDebounceFn(
    useCallback(() => onChange(innerValues.map(value => value.value || '')), [innerValues, onChange]),
    300
  );

  const onClickAdd = useCallback(() => {
    setInnerValues(innerValues => {
      innerValues.push({
        key: uuid(),
        value: '',
      });
      return [...innerValues];
    });
    triggerOnChange();
  }, [triggerOnChange]);

  const onChangeItem = useCallback(
    (index: number, value: string) => {
      setInnerValues(innerValues => {
        innerValues[index].value = value || '';
        return [...innerValues];
      });
      triggerOnChange();
    },
    [triggerOnChange]
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      setInnerValues(innerValues => {
        innerValues.splice(index, 1);
        return [...innerValues];
      });
      triggerOnChange();
    },
    [triggerOnChange]
  );

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      {innerValues.map((innerValue, index) => (
        <StringArrayItem
          key={innerValue.key}
          onRemove={onRemoveItem}
          index={index}
          onChange={onChangeItem}
          value={innerValue.value}
          invalid={invalid}
          onBlur={onBlur}
        />
      ))}
      <div>
        <Button color='primary' iconLeft='farPlus' iconRight='' flat onClick={onClickAdd} size='md'>
          {t('CustomTags:StringArray:AddField')}
        </Button>
      </div>
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const StringArray = withController(StringArrayView);
