import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { saveLabel } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';

const saveScreenLabelAsync = createAsyncThunk(
  'JGraph/saveScreenLabelAsync',
  async (data: { text: string; color: string; targetState: JStateWithId }, thunkAPI) => {
    thunkAPI.dispatch(saveLabel(data));
    const state = thunkAPI.getState() as RootState;
    const storeScreens = state.JGraphReducer.graph.blocks;
    const screenToSave = findScreenByPathId(data.targetState!.pathId, storeScreens);
    if (!screenToSave) return;
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () => thunkAPI.dispatch(updateState(screenToSave)),
    });
  }
);

export default saveScreenLabelAsync;
