import {
  LIST_CALL_LIST_PENDING,
  LIST_CALL_LIST_FULFILLED,
  LIST_CALL_LIST_REJECTED,
  CREATE_CALL_LIST_FULFILLED,
  DELETE_CALL_LIST_FULFILLED,
} from '../../constants/callList.actions';

const InitialState = {
  callLists: [],
  fetching: false,
  fetched: false,
  error: null,
  page: 0,
  totalPages: 1,
  pageSize: 20,
  totalCount: 0,
};

export default function CallListsReducer(state = InitialState, action) {
  switch (action.type) {
    case LIST_CALL_LIST_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case LIST_CALL_LIST_FULFILLED:
      let totalPages =
        Math.floor((action.payload.data.paging.totalCount - 1) / action.payload.data.paging.pageSize) + 1;
      if (totalPages < 1) totalPages = 1;
      return {
        ...state,
        callLists: action.payload.data.records,
        page: action.payload.data.paging.pageNum,
        totalPages: totalPages,
        fetching: false,
        fetched: true,
        totalCount: action.payload.data.paging.totalCount,
      };

    case LIST_CALL_LIST_REJECTED:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
        fetched: true,
      };

    case CREATE_CALL_LIST_FULFILLED:
      let newCallLists = [action.payload.data.newCallListInfo, ...state.callLists];
      let newTotalPages = state.totalPages;
      let newTotalCount = state.totalCount;
      if (state.totalCount % state.pageSize === 0 && state.totalCount !== 0) {
        newCallLists.pop();
        newTotalPages++;
      }
      newTotalCount++;
      return {
        ...state,
        callLists: newCallLists,
        fetched: true,
        fetching: false,
        totalPages: newTotalPages,
        totalCount: newTotalCount,
      };

    case DELETE_CALL_LIST_FULFILLED:
      let page = state.page;
      let newTotalListsCount = state.totalCount;
      if ((state.totalCount - 1) % state.pageSize === 0 && page !== 0) {
        page = page - 1;
      }
      newTotalListsCount--;
      return action.payload.data.callListData === null
        ? state
        : {
            ...state,
            callLists: state.callLists.filter(callList => callList.id !== action.payload.data.callListData.id),
            page: page,
            totalCount: newTotalListsCount,
          };

    default:
      return state;
  }
}
