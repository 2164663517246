import React, { Component } from 'react';

class Robot404 extends Component {
  render() {
    let icon = (
      <svg viewBox='0 0 287 226' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <path
            d='M0.746,109.552 C0.746,169.599 49.426,218.278 109.473,218.278 C169.521,218.278 218.2,169.599 218.2,109.552 C218.2,49.504 169.521,0.824 109.473,0.824 C49.426,0.824 0.746,49.504 0.746,109.552 Z'
            id='path-1'
          />
          <path
            d='M0.72,32.54 L6.932,34.078 C15.732,36.257 24.775,30.803 26.952,22.003 C29.131,13.203 23.678,4.162 14.877,1.983 L8.665,0.445 L0.72,32.54 Z'
            id='path-3'
          />
          <path
            d='M0.72,32.54 L6.932,34.078 C15.732,36.257 24.775,30.803 26.952,22.003 C29.131,13.203 23.678,4.162 14.877,1.983 L8.665,0.445 L0.72,32.54 Z'
            id='path-5'
          />
          <path
            d='M0.518,7.104 C-0.685,11.964 2.28,16.879 7.139,18.083 C11.999,19.286 16.916,16.321 18.119,11.461 C19.322,6.601 16.357,1.685 11.497,0.482 C10.765,0.301 10.032,0.214 9.311,0.214 C5.24,0.214 1.54,2.975 0.518,7.104 Z'
            id='path-7'
          />
          <path
            d='M0.518,7.104 C-0.685,11.964 2.28,16.879 7.139,18.083 C11.999,19.286 16.916,16.321 18.119,11.461 C19.322,6.601 16.357,1.685 11.497,0.482 C10.765,0.301 10.032,0.214 9.311,0.214 C5.24,0.214 1.54,2.975 0.518,7.104 Z'
            id='path-9'
          />
          <path
            d='M12.704,1.983 C3.903,4.162 -1.55,13.203 0.628,22.003 C2.807,30.803 11.848,36.257 20.649,34.078 L26.861,32.54 L18.915,0.445 L12.704,1.983 Z'
            id='path-11'
          />
          <path
            d='M12.704,1.983 C3.903,4.162 -1.55,13.203 0.628,22.003 C2.807,30.803 11.848,36.257 20.649,34.078 L26.861,32.54 L18.915,0.445 L12.704,1.983 Z'
            id='path-13'
          />
          <path
            d='M7.084,0.482 C2.225,1.685 -0.741,6.601 0.462,11.461 C1.666,16.321 6.581,19.286 11.441,18.083 C16.301,16.879 19.266,11.964 18.063,7.104 C17.041,2.975 13.34,0.214 9.269,0.214 C8.548,0.214 7.815,0.301 7.084,0.482 Z'
            id='path-15'
          />
          <path
            d='M7.084,0.482 C2.225,1.685 -0.741,6.601 0.462,11.461 C1.666,16.321 6.581,19.286 11.441,18.083 C16.301,16.879 19.266,11.964 18.063,7.104 C17.041,2.975 13.34,0.214 9.269,0.214 C8.548,0.214 7.815,0.301 7.084,0.482 Z'
            id='path-17'
          />
          <polygon id='path-19' points='0 226 286.581 226 286.581 0.825 0 0.825' />
          <polygon id='path-21' points='0.558 0.15 8.023 0.15 8.023 38.547 0.558 38.547' />
          <path
            d='M0.759,16.814 L0.759,23.213 L33.823,23.213 L33.823,16.814 C33.823,7.748 26.356,0.282 17.291,0.282 C8.224,0.282 0.759,7.748 0.759,16.814 Z'
            id='path-23'
          />
          <path
            d='M0.224,9.15 C0.224,14.158 4.284,18.216 9.291,18.216 C14.298,18.216 18.356,14.158 18.356,9.15 C18.356,4.144 14.298,0.085 9.291,0.085 C4.284,0.085 0.224,4.144 0.224,9.15 Z'
            id='path-25'
          />
          <path
            d='M21.095,0.628 C9.896,0.628 0.83,9.694 0.83,20.893 L0.83,76.889 C0.83,88.088 9.896,97.154 21.095,97.154 L139.486,97.154 C150.685,97.154 159.752,88.088 159.752,76.889 L159.752,20.893 C159.752,9.694 150.685,0.628 139.486,0.628 L21.095,0.628 Z'
            id='path-27'
          />
        </defs>
        <g id='404' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='UnloginnedUser' transform='translate(-270.000000, -150.000000)'>
            <g id='Group-3' transform='translate(221.000000, 149.000000)'>
              <g id='Group-4' transform='translate(39.000000, 0.000000)'>
                <g id='Group-2'>
                  <g id='Group-109'>
                    <g id='Group-5' transform='translate(34.000000, 1.175000)'>
                      <path
                        d='M218.1998,109.5512 C218.1998,169.5992 169.5208,218.2782 109.4728,218.2782 C49.4258,218.2782 0.7468,169.5992 0.7468,109.5512 C0.7468,49.5032 49.4258,0.8242 109.4728,0.8242 C169.5208,0.8242 218.1998,49.5032 218.1998,109.5512'
                        id='Fill-1'
                        fill='var(--white)'
                      />
                      <path
                        d='M218.1998,109.5512 C218.1998,169.5992 169.5208,218.2782 109.4728,218.2782 C49.4258,218.2782 0.7468,169.5992 0.7468,109.5512 C0.7468,49.5032 49.4258,0.8242 109.4728,0.8242 C169.5208,0.8242 218.1998,49.5032 218.1998,109.5512 Z'
                        id='Stroke-3'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                    </g>
                    <g id='Group-9' transform='translate(34.000000, 1.175000)'>
                      <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1' />
                      </mask>
                      <g id='Clip-7' />
                      <polygon
                        id='Fill-6'
                        fill='#C8E6FC'
                        mask='url(#mask-2)'
                        points='59.132 224 158.324 224 158.324 171.006 59.132 171.006'
                      />
                      <polygon
                        id='Stroke-8'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        mask='url(#mask-2)'
                        points='59.132 224 158.324 224 158.324 171.006 59.132 171.006'
                      />
                    </g>
                    <g id='Group-18' transform='translate(34.000000, 1.175000)'>
                      <path
                        d='M218.1998,109.5512 C218.1998,169.5992 169.5208,218.2782 109.4728,218.2782 C49.4258,218.2782 0.7468,169.5992 0.7468,109.5512 C0.7468,49.5032 49.4258,0.8242 109.4728,0.8242 C169.5208,0.8242 218.1998,49.5032 218.1998,109.5512 Z'
                        id='Stroke-10'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <polygon
                        id='Fill-12'
                        fill='var(--white)'
                        points='189.5299 124.4643 193.1569 109.8123 237.8359 120.8733 234.2089 135.5263'
                      />
                      <polygon
                        id='Fill-14'
                        fill='#1A5E8F'
                        points='195.943 114.4321 194.149 121.6791 231.422 130.9061 233.215 123.6591'
                      />
                      <path
                        d='M194.8776,100.9828 L188.6656,99.4448 L180.7206,131.5408 L186.9326,133.0778 C195.7316,135.2558 204.7736,129.8028 206.9526,121.0038 C209.1316,112.2028 203.6786,103.1618 194.8776,100.9828'
                        id='Fill-16'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-25' transform='translate(214.000000, 100.175000)'>
                      <g id='Group-21'>
                        <mask id='mask-4' fill='white'>
                          <use xlinkHref='#path-3' />
                        </mask>
                        <g id='Clip-20' />
                        <polygon
                          id='Fill-19'
                          fill='#C8E6FC'
                          mask='url(#mask-4)'
                          points='-7.3529 37.4086 27.8491 46.1236 38.9981 1.0866 3.7961 -7.6274'
                        />
                      </g>
                      <g id='Group-24'>
                        <mask id='mask-6' fill='white'>
                          <use xlinkHref='#path-5' />
                        </mask>
                        <g id='Clip-23' />
                        <polygon
                          id='Stroke-22'
                          stroke='#1A5E8F'
                          strokeWidth='2'
                          mask='url(#mask-6)'
                          points='-7.3529 37.4086 27.8491 46.1236 38.9981 1.0866 3.7961 -7.6274'
                        />
                      </g>
                    </g>
                    <g id='Group-30' transform='translate(214.000000, 100.175000)'>
                      <path
                        d='M14.8776,1.9828 L8.6656,0.4448 L0.7206,32.5408 L6.9326,34.0778 C15.7316,36.2558 24.7736,30.8028 26.9526,22.0038 C29.1316,13.2028 23.6786,4.1618 14.8776,1.9828 Z'
                        id='Stroke-26'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <path
                        d='M50.1393,37.0824 C54.9993,38.2854 59.9153,35.3214 61.1183,30.4614 C62.3223,25.6014 59.3573,20.6854 54.4963,19.4814 C49.6363,18.2784 44.7213,21.2434 43.5183,26.1034 C42.3153,30.9644 45.2803,35.8794 50.1393,37.0824'
                        id='Fill-28'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-37' transform='translate(257.000000, 119.175000)'>
                      <g id='Group-33'>
                        <mask id='mask-8' fill='white'>
                          <use xlinkHref='#path-7' />
                        </mask>
                        <g id='Clip-32' />
                        <polygon
                          id='Fill-31'
                          fill='#C8E6FC'
                          mask='url(#mask-8)'
                          points='-9.7337 20.7729 20.8093 28.3349 28.3703 -2.2081 -2.1717 -9.7691'
                        />
                      </g>
                      <g id='Group-36'>
                        <mask id='mask-10' fill='white'>
                          <use xlinkHref='#path-9' />
                        </mask>
                        <g id='Clip-35' />
                        <polygon
                          id='Stroke-34'
                          stroke='#1A5E8F'
                          strokeWidth='2'
                          mask='url(#mask-10)'
                          points='-9.7337 20.7729 20.8093 28.3349 28.3703 -2.2081 -2.1717 -9.7691'
                        />
                      </g>
                    </g>
                    <g id='Group-46' transform='translate(14.000000, 100.175000)'>
                      <path
                        d='M250.1393,37.0824 C254.9993,38.2854 259.9153,35.3214 261.1183,30.4614 C262.3223,25.6014 259.3573,20.6854 254.4963,19.4814 C249.6363,18.2784 244.7213,21.2434 243.5183,26.1034 C242.3153,30.9644 245.2803,35.8794 250.1393,37.0824 Z'
                        id='Stroke-38'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <polygon
                        id='Fill-40'
                        fill='var(--white)'
                        points='49.0514 25.4643 45.4244 10.8123 0.7454 21.8733 4.3724 36.5263'
                      />
                      <polygon
                        id='Fill-42'
                        fill='#1A5E8F'
                        points='42.6373 15.4321 44.4313 22.6791 7.1583 31.9061 5.3663 24.6591'
                      />
                      <path
                        d='M43.7038,1.9828 L49.9158,0.4448 L57.8608,32.5408 L51.6488,34.0778 C42.8488,36.2558 33.8078,30.8028 31.6288,22.0038 C29.4498,13.2028 34.9028,4.1618 43.7038,1.9828'
                        id='Fill-44'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-53' transform='translate(45.000000, 100.175000)'>
                      <g id='Group-49'>
                        <mask id='mask-12' fill='white'>
                          <use xlinkHref='#path-11' />
                        </mask>
                        <g id='Clip-48' />
                        <polygon
                          id='Fill-47'
                          fill='#C8E6FC'
                          mask='url(#mask-12)'
                          points='34.9332 37.4086 -0.2678 46.1236 -11.4178 1.0866 23.7842 -7.6274'
                        />
                      </g>
                      <g id='Group-52'>
                        <mask id='mask-14' fill='white'>
                          <use xlinkHref='#path-13' />
                        </mask>
                        <g id='Clip-51' />
                        <polygon
                          id='Stroke-50'
                          stroke='#1A5E8F'
                          strokeWidth='2'
                          mask='url(#mask-14)'
                          points='34.9332 37.4086 -0.2678 46.1236 -11.4178 1.0866 23.7842 -7.6274'
                        />
                      </g>
                    </g>
                    <g id='Group-58' transform='translate(11.000000, 100.175000)'>
                      <path
                        d='M46.7038,1.9828 L52.9158,0.4448 L60.8608,32.5408 L54.6488,34.0778 C45.8488,36.2558 36.8078,30.8028 34.6288,22.0038 C32.4498,13.2028 37.9028,4.1618 46.7038,1.9828 Z'
                        id='Stroke-54'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <path
                        d='M11.4411,37.0824 C6.5811,38.2854 1.6661,35.3214 0.4621,30.4614 C-0.7409,25.6014 2.2241,20.6854 7.0841,19.4814 C11.9441,18.2784 16.8601,21.2434 18.0631,26.1034 C19.2661,30.9644 16.3011,35.8794 11.4411,37.0824'
                        id='Fill-56'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-65' transform='translate(11.000000, 119.175000)'>
                      <g id='Group-61'>
                        <mask id='mask-16' fill='white'>
                          <use xlinkHref='#path-15' />
                        </mask>
                        <g id='Clip-60' />
                        <polygon
                          id='Fill-59'
                          fill='#C8E6FC'
                          mask='url(#mask-16)'
                          points='28.3141 20.7729 -2.2279 28.3349 -9.7899 -2.2081 20.7531 -9.7691'
                        />
                      </g>
                      <g id='Group-64'>
                        <mask id='mask-18' fill='white'>
                          <use xlinkHref='#path-17' />
                        </mask>
                        <g id='Clip-63' />
                        <polygon
                          id='Stroke-62'
                          stroke='#1A5E8F'
                          strokeWidth='2'
                          mask='url(#mask-18)'
                          points='28.3141 20.7729 -2.2279 28.3349 -9.7899 -2.2081 20.7531 -9.7691'
                        />
                      </g>
                    </g>
                    <g id='Group-70' transform='translate(0.000000, 0.175000)'>
                      <path
                        d='M22.4411,137.0824 C17.5811,138.2854 12.6661,135.3214 11.4621,130.4614 C10.2591,125.6014 13.2241,120.6854 18.0841,119.4814 C22.9441,118.2784 27.8601,121.2434 29.0631,126.1034 C30.2661,130.9644 27.3011,135.8794 22.4411,137.0824 Z'
                        id='Stroke-66'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <mask id='mask-20' fill='white'>
                        <use xlinkHref='#path-19' />
                      </mask>
                      <g id='Clip-69' />
                      <polygon
                        id='Fill-68'
                        fill='#1A5E8F'
                        mask='url(#mask-20)'
                        points='139.557 64.547 147.023 64.547 147.023 26.151 139.557 26.151'
                      />
                    </g>
                    <g id='Group-73' transform='translate(139.000000, 26.175000)'>
                      <mask id='mask-22' fill='white'>
                        <use xlinkHref='#path-21' />
                      </mask>
                      <g id='Clip-72' />
                      <polygon
                        id='Fill-71'
                        fill='#1A5E8F'
                        mask='url(#mask-22)'
                        points='-6.109 45.214 14.69 45.214 14.69 -6.515 -6.109 -6.515'
                      />
                    </g>
                    <path
                      d='M126.7584,68.9889 L126.7584,75.3879 L159.8224,75.3879 L159.8224,68.9889 C159.8224,59.9239 152.3564,52.4569 143.2904,52.4569 C134.2244,52.4569 126.7584,59.9239 126.7584,68.9889'
                      id='Fill-74'
                      fill='#C8E6FC'
                    />
                    <g id='Group-79' transform='translate(126.000000, 52.175000)'>
                      <mask id='mask-24' fill='white'>
                        <use xlinkHref='#path-23' />
                      </mask>
                      <g id='Clip-77' />
                      <polygon
                        id='Fill-76'
                        fill='#C8E6FC'
                        mask='url(#mask-24)'
                        points='-5.907 29.881 40.489 29.881 40.489 -6.384 -5.907 -6.384'
                      />
                      <polygon
                        id='Stroke-78'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        mask='url(#mask-24)'
                        points='-5.907 29.881 40.489 29.881 40.489 -6.384 -5.907 -6.384'
                      />
                    </g>
                    <g id='Group-84' transform='translate(126.000000, 17.175000)'>
                      <path
                        d='M0.7584,51.8139 L0.7584,58.2129 L33.8224,58.2129 L33.8224,51.8139 C33.8224,42.7489 26.3564,35.2819 17.2904,35.2819 C8.2244,35.2819 0.7584,42.7489 0.7584,51.8139 Z'
                        id='Stroke-80'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <path
                        d='M26.3561,9.1508 C26.3561,4.1438 22.2971,0.0848 17.2911,0.0848 C12.2841,0.0848 8.2241,4.1438 8.2241,9.1508 C8.2241,14.1578 12.2841,18.2158 17.2911,18.2158 C22.2971,18.2158 26.3561,14.1578 26.3561,9.1508'
                        id='Fill-82'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-88' transform='translate(134.000000, 17.175000)'>
                      <mask id='mask-26' fill='white'>
                        <use xlinkHref='#path-25' />
                      </mask>
                      <g id='Clip-86' />
                      <polygon
                        id='Fill-85'
                        fill='#C8E6FC'
                        mask='url(#mask-26)'
                        points='-6.442 24.883 25.023 24.883 25.023 -6.582 -6.442 -6.582'
                      />
                      <polygon
                        id='Stroke-87'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        mask='url(#mask-26)'
                        points='-6.442 24.883 25.023 24.883 25.023 -6.582 -6.442 -6.582'
                      />
                    </g>
                    <g id='Group-93' transform='translate(63.000000, 17.175000)'>
                      <path
                        d='M89.3561,9.1508 C89.3561,4.1438 85.2971,0.0848 80.2911,0.0848 C75.2841,0.0848 71.2241,4.1438 71.2241,9.1508 C71.2241,14.1578 75.2841,18.2158 80.2911,18.2158 C85.2971,18.2158 89.3561,14.1578 89.3561,9.1508 Z'
                        id='Stroke-89'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <path
                        d='M139.486,155.1537 L21.095,155.1537 C9.896,155.1537 0.83,146.0887 0.83,134.8887 L0.83,78.8927 C0.83,67.6937 9.896,58.6287 21.095,58.6287 L139.486,58.6287 C150.685,58.6287 159.752,67.6937 159.752,78.8927 L159.752,134.8887 C159.752,146.0887 150.685,155.1537 139.486,155.1537'
                        id='Fill-91'
                        fill='#C8E6FC'
                      />
                    </g>
                    <g id='Group-97' transform='translate(63.000000, 75.175000)'>
                      <mask id='mask-28' fill='white'>
                        <use xlinkHref='#path-27' />
                      </mask>
                      <g id='Clip-95' />
                      <polygon
                        id='Fill-94'
                        fill='#C8E6FC'
                        mask='url(#mask-28)'
                        points='-5.836 103.82 166.418 103.82 166.418 -6.038 -5.836 -6.038'
                      />
                      <polygon
                        id='Stroke-96'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        mask='url(#mask-28)'
                        points='-5.836 103.82 166.418 103.82 166.418 -6.038 -5.836 -6.038'
                      />
                    </g>
                    <g id='Group-108' transform='translate(63.000000, 75.175000)'>
                      <path
                        d='M139.486,97.1537 L21.095,97.1537 C9.896,97.1537 0.83,88.0887 0.83,76.8887 L0.83,20.8927 C0.83,9.6937 9.896,0.6287 21.095,0.6287 L139.486,0.6287 C150.685,0.6287 159.752,9.6937 159.752,20.8927 L159.752,76.8887 C159.752,88.0887 150.685,97.1537 139.486,97.1537 Z'
                        id='Stroke-98'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                      />
                      <polygon
                        id='Fill-100'
                        fill='#1A5E8F'
                        opacity='0.239999995'
                        points='30.879 110.338 128.161 110.338 128.161 98.498 30.879 98.498'
                      />
                      <path
                        d='M106.5104,27.9213 C106.5024,28.1083 106.4824,28.2903 106.4824,28.4783 C106.4824,36.1363 112.6904,42.3443 120.3474,42.3443 C128.0054,42.3443 134.2134,36.1363 134.2134,28.4783 C134.2134,28.2903 134.1934,28.1083 134.1854,27.9213'
                        id='Stroke-102'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        strokeLinecap='round'
                      />
                      <path
                        d='M26.3961,27.9213 C26.3881,28.1083 26.3681,28.2903 26.3681,28.4783 C26.3681,36.1363 32.5761,42.3443 40.2331,42.3443 C47.8911,42.3443 54.0991,36.1363 54.0991,28.4783 C54.0991,28.2903 54.0791,28.1083 54.0711,27.9213'
                        id='Stroke-104'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        strokeLinecap='round'
                      />
                      <path
                        d='M116.7155,61.5512 C110.7555,56.8412 96.7735,53.5342 80.4735,53.5342 C64.1745,53.5342 50.1915,56.8412 44.2315,61.5512'
                        id='Stroke-106'
                        stroke='#1A5E8F'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <path
          d='M226.494298,53.6662265 L216.945872,59.1865267 C215.212559,60.2220589 212.9851,60.3058151 211.114946,59.2245977 L201.498099,53.6662265 L192.246161,48.3134388 C190.322792,47.3768914 189,45.4048117 189,43.1129354 L189,31.9961929 L189,20.8794504 L189.015205,20.8794504 C189.015205,18.8921424 190.049111,16.9581337 191.888855,15.8921447 L201.505702,10.3337735 L211.054128,4.81347332 C212.787441,3.77794115 215.0149,3.69418487 216.885054,4.77540229 L226.501901,10.3337735 L235.753839,15.6865612 C237.677208,16.6231086 239,18.5951883 239,20.8870646 L239,32.0038071 L239,43.1205496 L238.984795,43.1205496 C238.984795,45.1078576 237.950889,47.0418663 236.111145,48.1078553 L226.494298,53.6662265 Z'
          id='Path'
          fill='#1B7ABE'
        />
        <path
          d='M225.855951,25.656878 L217.245583,10.8803192 C216.926961,10.3244141 216.464201,9.83611911 215.864889,9.4980687 C214.089712,8.47640526 211.813844,9.07738376 210.789703,10.8352459 L202.14899,25.656878 L202.156576,25.656878 L202.14899,25.6643902 L193.690347,40.1780208 C193.257932,40.7789993 193,41.515198 193,42.3114945 C193,44.3473091 194.66897,46 196.724838,46 L214.006264,46 L231.287689,46 L231.287689,45.9849755 C231.917346,45.9849755 232.554589,45.8272187 233.138729,45.4966805 C234.913906,44.4825293 235.528391,42.2438844 234.50425,40.4860223 L225.97733,25.8597082 C225.939399,25.7920981 225.909054,25.724488 225.863537,25.656878 L225.855951,25.656878 Z'
          id='Path'
          fill='#93D5F6'
        />
        <path
          d='M227,47 C223.640115,50.0975955 219.051003,52 214,52 C208.948997,52 204.359885,50.0905233 201,47 L214.00745,47 L227,47 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M202.313433,25.4579595 L195.564953,37 C195.201216,35.5247591 195,33.9883682 195,32.406115 C195,23.6769691 200.912659,16.3084081 209,14 L202.305694,25.4503157 L202.313433,25.4503157 L202.305694,25.4579595 L202.313433,25.4579595 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M232.442169,37 L225.810183,25.6606786 L225.693968,25.4540918 L219,14 C227.088545,16.3183633 233,23.6866267 233,32.416833 C233,33.993014 232.806309,35.5309381 232.442169,37 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M226.492501,26.4363383 L233,37.7992565 C232.133347,41.3568773 230.286008,44.5234665 227.762071,47 L214.503801,47 L201.230327,47 C198.713992,44.5311338 196.866653,41.3722119 196,37.8222584 L202.629135,26.2446561 L202.636737,26.2369888 L202.629135,26.2369888 L209.205054,14.7513941 C210.885145,14.2606877 212.664064,14 214.503801,14 C216.35114,14 218.137662,14.268355 219.825354,14.7590613 L226.393672,26.2369888 L226.507705,26.4440056 L226.492501,26.4363383 Z'
          id='Path'
          fill='#FEFEFE'
        />
      </svg>
    );

    return <div>{icon}</div>;
  }
}

export default Robot404;
