var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CustomInput } from 'reactstrap';
import classNames from 'classnames';
export function SwitchButton(_a) {
    var { id, value, name, type, label = '', onLabel = '', offLabel = '', onChange = () => { }, className, labelPosition = 'right', size = 'md', color } = _a, restProps = __rest(_a, ["id", "value", "name", "type", "label", "onLabel", "offLabel", "onChange", "className", "labelPosition", "size", "color"]);
    return (_jsx(_Fragment, { children: _jsx(CustomInput, Object.assign({ id: id, type: 'switch', name: name, onChange: event => onChange(event.target.checked, name), checked: value, label: `${label ? label + ' ' : ''}${value ? onLabel : offLabel}`, className: classNames(className, size === 'md' ? 'custom-switch-md' : 'custom-switch-sm', {
                'SwitchButton-label-left': labelPosition === 'left',
                [`SwitchButton-color--${color}`]: !!color,
            }) }, restProps)) }));
}
