import { FileContentData } from '../api/client';

export default class FileContent {
  path: string;
  get name() {
    return FileContent.getNameFromPath(this.path);
  }
  get extension() {
    return this.path.substring(this.path.lastIndexOf('.') + 1);
  }
  content: string;
  readonly: boolean;
  lastModified: number;

  constructor(path?: string, lastModified?: number, content?: string, readonly?: boolean) {
    this.path = path ? path : '';
    this.lastModified = lastModified ? lastModified : 0;
    this.content = content ? content : '';
    this.readonly = Boolean(readonly);
  }

  static getNameFromPath(path: string) {
    return path.substring(path.lastIndexOf('/') + 1);
  }

  setContent(content: string) {
    this.content = content;
  }

  setLastModified(lastModified: number) {
    this.lastModified = lastModified;
  }

  static fromDto(data: FileContentData, readonly?: boolean) {
    return new FileContent(data.name, data.lastModified, data.content, readonly);
  }

  toDto(): FileContentData {
    return {
      name: this.path,
      lastModified: this.lastModified,
      content: this.content,
    };
  }

  clone() {
    return new FileContent(this.path, this.lastModified, this.content);
  }
}
