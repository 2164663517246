import React, { FC, useCallback, useMemo } from 'react';
import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Modal,
  Spinner,
  usePromiseProcessing,
  useToggle,
} from '@just-ai/just-ui';
import cn from 'classnames';
import { t, tWithPlural } from '../../../../../../../localization';
import classes from './styles.module.scss';
import { useSelectIntent } from './hooks';
import { getPureDisplayValue } from './utils';
import { IntentData, IntentSummaryData } from '../../../../../../Caila/api/client';

import { useJGraphContext } from '../../../../../contexts/JGraphContext';

type NluIntentItemProps = {
  selectedId?: string;
  setSelectedId: (id: string) => unknown;
  intent: IntentSummaryData;
  deleteIntent: (ids: number[]) => Promise<unknown>;
  pushIntent: (intent: IntentData, softValidate?: boolean) => Promise<unknown>;
};

export const NluIntentItem: FC<NluIntentItemProps> = ({
  selectedId,
  intent,
  setSelectedId,
  deleteIntent,
  pushIntent,
}) => {
  const [isDeletionOpen, openDeleteModal, closeDeleteModal] = useToggle(false);
  const [isActive, setIsActive, , toggleActive] = useToggle(false);
  const { IntentsService } = useJGraphContext();
  const toggleEnableIntentHandler = useCallback(async () => {
    const { data: detailIntent } = await IntentsService.getIntent(intent.id!);
    await pushIntent({ ...detailIntent, enabled: !detailIntent.enabled }, false);
  }, [IntentsService, pushIntent, intent.id]);
  const [enablingStatus, changeIntent] = usePromiseProcessing(toggleEnableIntentHandler);
  const setSelectedIdHandler = useSelectIntent(String(intent.id), setSelectedId);

  const children = useMemo(() => {
    return IntentsService.intents.filter(storeIntent => storeIntent.path?.startsWith(intent.path!));
  }, [IntentsService.intents, intent.path]);

  const deleteIntentHandler = useCallback(() => {
    closeDeleteModal();
    deleteIntent(children.map(intent => intent.id!));
  }, [children, closeDeleteModal, deleteIntent]);

  return (
    <div
      className={cn('d-flex w-100 position-relative', classes.CailaIntentsSimple_listItem, {
        [classes.isActive]: isActive,
        [classes.isSelected]: String(selectedId) === String(intent.id),
        [classes.disabled]: !intent.enabled,
      })}
      onClick={setSelectedIdHandler}
    >
      {enablingStatus.loading && <Spinner size='sm' />}
      <div className='d-flex font-size-14 flex-grow-1 overflow-hidden'>
        <div className={classes.CailaIntentsSimple_listItem_text}>{getPureDisplayValue(intent.path)}</div>
      </div>
      <div className={cn('d-flex position-relative', classes.CailaIntentsSimple_listItem_action)}>
        <div
          className={cn(
            'd-flex color-gray-600 font-size-14 justify-content-center',
            classes.CailaIntentsSimple_listItem_counter
          )}
        >
          {intent.itemsCount || 0}
        </div>
        <div className={cn('position-absolute ', classes.dropDownContainer)}>
          <DropdownButton
            className={classes.noBorder}
            direction='down'
            flat
            color='none'
            onClick={event => {
              event.stopPropagation();
              setIsActive();
            }}
            onToggle={event => {
              event.stopPropagation();
              toggleActive();
            }}
          >
            <DropdownToggle color='secondary' iconButton className={classes.customDropdownButton}>
              <Icon
                wrapperClassName='d-flex'
                className='btn-icon'
                name='farEllipsisH'
                data-test-id='ReactionItem:dropdownToggle'
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={changeIntent} data-test-id='RightSideMenu:dropdownMenu:disable'>
                {intent.enabled
                  ? t('CailaIntentsSimple:ListIntents:DropDown:disableIntent')
                  : t('CailaIntentsSimple:ListIntents:DropDown:enableIntent')}
              </DropdownItem>
              <DropdownItem danger onClick={openDeleteModal} data-test-id='RightSideMenu:dropdownMenu:delete'>
                {t('CailaIntentsSimple:ListIntents:DropDown:deleteIntent')}
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
        </div>
      </div>
      <Modal
        isOpen={isDeletionOpen}
        title={
          children.length > 1
            ? tWithPlural('CailaIntentsSimple:ListIntents:modalDeleteIntentTitle', children.length)
            : t('CailaIntentsSimple:ListIntents:modalDeleteIntentTitle')
        }
        buttonSubmitText={t('CailaIntentsSimple:ListIntents:modalDeleteIntentActionButton')}
        buttonCancelText={t('CailaIntentsSimple:ListIntents:modalDeleteIntentCancelButton')}
        buttonCancelOutline
        buttonSubmitColor='danger'
        onActionClick={deleteIntentHandler}
        onCancelClick={closeDeleteModal}
      >
        {children.length > 1
          ? tWithPlural('CailaIntentsSimple:ListIntents:modalDeleteIntentMessage', children.length)
          : t('CailaIntentsSimple:ListIntents:modalDeleteIntentMessage')}
      </Modal>
    </div>
  );
};
