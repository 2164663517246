import { PubSub } from 'services/PubSub';

type SubscriptionMap = {
  isPlayed: boolean;
};

export class AudioService extends PubSub<SubscriptionMap> {
  private docFragment = document.createDocumentFragment();
  private audioNode?: HTMLAudioElement;

  play(src: string) {
    this.audioNode = new Audio(src);
    this.docFragment.appendChild(this.audioNode);
    this.audioNode.addEventListener('ended', () => this.clear());
    return this.audioNode.play().then(() => this.notify('isPlayed', true));
  }

  clear() {
    if (this.audioNode) {
      this.docFragment.removeChild(this.audioNode);
      this.audioNode = undefined;
    }
    this.notify('isPlayed', false);
  }
}
