import React, { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import classes from './styles.module.scss';
import { IRect } from 'konva/lib/types';
import { computePosition, flip } from '@floating-ui/react-dom';

import { hideRootSlashInPath } from '../../../utils/state';
import Konva from 'konva';
import cn from 'classnames';

import { scrollToTargetGlobal$ } from '../../../utils/stageUtils';

type SubjectStateValue = {
  clientRect: IRect;
  values: MenuItemProps['value'][];
  target: Konva.Node;
} | null;

export const CollapsedConnectorsMenuSubject$ = new Subject<SubjectStateValue>();
export const CollapsedConnectorsMenuClose = () => CollapsedConnectorsMenuSubject$.next(null);

type MenuItemProps = { value: { fromPathId?: string; toPathId?: string; fromPath: string; toPath: string } };

function MenuItem({ value }: MenuItemProps) {
  const scrollToFrom = useCallback(() => {
    scrollToTargetGlobal$.next({
      targetPathId: value.fromPathId,
    });
    CollapsedConnectorsMenuClose();
  }, [value.fromPathId]);

  const scrollToTo = useCallback(() => {
    scrollToTargetGlobal$.next({
      targetPathId: value.toPathId,
    });
    CollapsedConnectorsMenuClose();
  }, [value.toPathId]);

  return (
    <div className={classes.CollapsedConnectorsMenu_menu_item}>
      <div className={cn(classes.CollapsedConnectorsMenu_menu_item_name, 'text-right')} onClick={scrollToFrom}>
        <span>{hideRootSlashInPath(value.fromPath)}</span>
      </div>
      <div className={classes.CollapsedConnectorsMenu_menu_item_arrow}>
        <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M10.5 4.00488L6.5 1.00488V4.00488V7.00488L10.5 4.00488Z' fill='#888E93' />
          <path
            d='M6.5 4.00488V7.00488L10.5 4.00488L6.5 1.00488V4.00488ZM6.5 4.00488H1.5'
            stroke='#888E93'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <div className={cn(classes.CollapsedConnectorsMenu_menu_item_name, 'text-left')} onClick={scrollToTo}>
        <span>{hideRootSlashInPath(value.toPath)}</span>
      </div>
    </div>
  );
}

export const CollapsedConnectorsMenu = memo(() => {
  const [subjectState, setSubjectState] = useState<SubjectStateValue>(null);
  const [floatingPosition, setFloatingPosition] = useState({ x: 0, y: 0 });
  const reference = useRef<HTMLDivElement>(null);
  const floating = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sub = CollapsedConnectorsMenuSubject$.subscribe(setSubjectState);
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (!reference.current || !floating.current || !subjectState) return;
    computePosition(reference.current, floating.current, {
      placement: 'top-start',
      strategy: 'absolute',
      middleware: [flip()],
    }).then(({ x, y }) => setFloatingPosition({ x, y }));
  }, [subjectState]);

  const referenceStyle: CSSProperties = {
    position: 'absolute',
    left: subjectState ? subjectState.clientRect.x : 0,
    top: subjectState ? subjectState.clientRect.y : 0,
    width: subjectState ? subjectState.clientRect.width : 0,
    height: subjectState ? subjectState.clientRect.height : 0,
  };

  if (!subjectState) return null;

  return (
    <>
      <div ref={reference} style={referenceStyle} className={classes.CollapsedConnectorsMenu_ghost} />
      <div
        ref={floating}
        style={{ left: floatingPosition.x, top: floatingPosition.y }}
        className={classes.CollapsedConnectorsMenu_menu}
      >
        <div className={classes.CollapsedConnectorsMenu_menu_container}>
          {subjectState.values.map(value => (
            <MenuItem key={value.fromPathId || '' + value.toPathId || ''} value={value} />
          ))}
        </div>
      </div>
    </>
  );
});
