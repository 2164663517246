import { GET_APP_CONFIG, GET_APP_STATUS, GET_OPTIONS } from '../../constants/appConfig.actions';
import { axios } from '../../pipes/functions';

export const getAppConfig = () => {
  return {
    type: GET_APP_CONFIG,
    payload: axios.get(`/restapi/public/config`),
  };
};

export const getAppStatus = () => {
  return {
    type: GET_APP_STATUS,
    payload: axios.get(`/restapi/license/status`),
  };
};

export const getCloudAppOptions = () => {
  return {
    type: GET_OPTIONS,
    payload: axios.get('/api/accountsadmin/public/options'),
  };
};
