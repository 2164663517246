import { useEffect, useState } from 'react';

export default function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const cbForOnline = () => setIsOnline(true);
    window.addEventListener('online', cbForOnline);
    const cbForOffline = () => setIsOnline(false);
    window.addEventListener('offline', cbForOffline);
    return () => {
      window.removeEventListener('offline', cbForOffline);
      window.removeEventListener('online', cbForOnline);
    };
  });

  return isOnline;
}
