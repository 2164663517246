import React, { Component } from 'react';

export class CollapsibleContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: props.hasOwnProperty('collapsed') ? props.collapsed : true,
    };
  }

  toggleCollapse() {
    this.setState(state => {
      return Object.assign({}, state, {
        isCollapsed: !this.state.isCollapsed,
      });
    });
  }

  render() {
    return (
      <div className={this.state.isCollapsed ? 'collapsible-content collapsed' : 'collapsible-content'}>
        <div className='collapsible-content-headline' onClick={this.toggleCollapse.bind(this)}>
          {this.props.headline}{' '}
          <i className={this.state.isCollapsed ? 'float-right fa fa-chevron-down' : 'float-right fa fa-chevron-up'} />
        </div>
        <div className='collapsible-content-body'>{this.props.children}</div>
      </div>
    );
  }
}
