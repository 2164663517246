import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal, Spinner, useTranslation } from '@just-ai/just-ui';
import { useLoading } from '../../../../nlu-related/utils';
const DeleteSkillModal = function ({ isOpen, onClose, onDelete, skill }) {
    const [isLoading, load] = useLoading();
    const { t } = useTranslation();
    return (_jsxs(Modal, { isOpen: isOpen, title: t(`FAQ:Home:DeleteModal:Title`), buttonSubmitColor: 'danger', buttonSubmitText: t(`Delete`), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: onClose, onActionClick: () => load(onDelete()).finally(() => onClose()), children: [isLoading && _jsx(Spinner, { size: 'sm' }), _jsx("div", { className: 'margin-bottom-2x', style: { color: 'var(--gray-800)' }, children: t('FAQ:Home:DeleteModal:Content $[1]', skill.name) })] }));
};
export default React.memo(DeleteSkillModal);
