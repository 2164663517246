import { JState } from 'modules/Editor/api/client';

import { ScreenLabel, ScreenParameter } from './types';
import { LabelColor, labelColors } from './colors';

export class LabelingService {
  static getLabelColorByHash(hash: string): LabelColor | undefined {
    return labelColors.find(col => col.mainColor === hash);
  }

  static setLabelToScreenInPlace(screen: JState, text: string, color: string) {
    if (!screen.parameters) {
      screen.parameters = [];
    }
    screen.parameters = screen.parameters.filter(
      // @ts-ignore
      el => !['sessionResult', 'sessionResultColor'].includes(el.name)
    );
    screen.parameters?.push(
      { name: 'sessionResult', required: false, value: text },
      { name: 'sessionResultColor', required: false, value: color }
    );
  }

  static deleteLabelFromScreenInPlace(screen: JState) {
    if (!screen.parameters) return;
    screen.parameters = screen.parameters.filter(
      // @ts-ignore
      el => !['sessionResult', 'sessionResultColor'].includes(el.name)
    );
  }

  static getLabelByScreen(screen: JState): ScreenLabel | undefined {
    if (!screen.parameters) return;
    const params = screen.parameters as ScreenParameter[];
    const sessionResult = params.find(el => el.name === 'sessionResult');
    const sessionResultColor = params.find(el => el.name === 'sessionResultColor');
    if (!sessionResult || !sessionResult.value) {
      return;
    }
    let labelColor: LabelColor | undefined;
    if (sessionResultColor) {
      labelColor = LabelingService.getLabelColorByHash(sessionResultColor.value);
    }
    if (!labelColor) {
      labelColor = labelColors[8];
    }
    return {
      text: sessionResult.value,
      color: labelColor,
    };
  }
}
