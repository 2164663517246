import { createBrowserHistory } from 'history';
import { clearCurrentProject } from './routerFunctions';
import { locationParserForAmplitude } from './utils/historyToAnalitycsListener';
import { urlWithoutProjectSidebar } from './routerFunctions';

const history = createBrowserHistory();

history.listen(location => {
  let splitPathname = location.pathname.split('/');
  locationParserForAmplitude(splitPathname, location.pathname);

  if (urlWithoutProjectSidebar.some(url => window.location.pathname.startsWith(url))) {
    if (!!localStorage['CURRENT_PROJECT_SHORT_NAME'] && localStorage['CURRENT_PROJECT_SHORT_NAME'] !== '') {
      clearCurrentProject();
    }
  }
});

export default history;
