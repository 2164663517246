import React, { useMemo, useState } from 'react';
import { t } from 'localization';
import { Scrollbar, Tabs } from '@just-ai/just-ui';
import JsonContainer from 'components/JsonContainer';

import classes from './style.module.scss';

type CdqaModalProps = {
  question?: string;
  answerData: {
    text: string;
    confidence: number;
  };
  restAnswers: string;
  skillName: string;
};

export default function CdqaModalContent(props: CdqaModalProps) {
  const { question, answerData, skillName, restAnswers } = props;

  const tabs = useMemo(
    () => [
      { name: t('Widget:cdqaModal:AnswerText'), value: 'answerText' },
      { name: t('Widget:cdqaModal:OtherAnswers'), value: 'restAnswers' },
    ],
    []
  );
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <div className={classes.cdqaWidgetModal}>
      <p>{t('Widget:cdqaModal:Info')}</p>
      <div className='grey-panel'>
        <p className='text-secondary mt-1'>{t('Question')}:</p>
        <p>{question}</p>
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} fullWidth />
      {activeTab === 'answerText' ? (
        <div className='grey-panel'>
          <p className={classes.cdqaWidgetModal__skillNameRow}>
            <span className='score-block'>{answerData.confidence.toFixed(2)}</span>
            <b>{skillName}</b>
          </p>
          <Scrollbar
            options={{ suppressScrollX: true }}
            className={classes.cdqaWidgetModal__answerText}
            dangerouslySetInnerHTML={{ __html: `<span>${answerData.text}</span>` }}
          />
        </div>
      ) : (
        <div>
          <JsonContainer value={restAnswers} />
        </div>
      )}
    </div>
  );
}
