import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, Icon, useTranslation } from '@just-ai/just-ui';
import './style.scss';
import { useNLUModuleContext } from '../../../../../../context';
export default function ValidationErrorPanel(props) {
    const { validationErrorData, type, updateWithoutValidation, handleLinkToAnswer, usageArea } = props;
    const { setCurrentProjectToEdit, isContentManager } = useNLUModuleContext();
    const { t } = useTranslation();
    const LinksArr = useMemo(() => {
        if (!validationErrorData)
            return [];
        return validationErrorData.map(answerData => {
            var _a;
            const stringsArr = ((_a = answerData.intentPath) === null || _a === void 0 ? void 0 : _a.split('/')) || [];
            return {
                skillName: (stringsArr === null || stringsArr === void 0 ? void 0 : stringsArr.find(string => string.includes('FAQ.'))) || '',
                questionName: stringsArr[stringsArr.length - 1] || '',
                intentPath: answerData.intentPath || '',
                intentId: answerData.intentId || 0,
                score: answerData.score || 0,
            };
        });
    }, [validationErrorData]);
    return (_jsxs("div", { className: 'validation-error-panel', children: [_jsx("div", { className: 'validation-error-panel__icon-block', children: _jsx(Icon, { name: 'faExclamationCircle', color: 'primary' }) }), _jsxs("div", { className: 'validation-error-panel__info-block', children: [_jsx("h3", { className: 'mb-2', children: t(`${usageArea}ValErrorPanel.${type}:header`) }), _jsx("p", { children: t(`${usageArea}ValErrorPanel.${type}:info`) }), _jsx("div", { className: 'validation-error-panel__links-block mb-4', children: LinksArr.map(link => (_jsxs("div", { className: 'validation-error-panel__link', children: [usageArea === 'FAQ' && _jsx("p", { children: link.skillName }), _jsxs("p", { className: 'pseudoLink', onClick: () => handleLinkToAnswer === null || handleLinkToAnswer === void 0 ? void 0 : handleLinkToAnswer(link.intentPath, link.intentId), "data-test-id": 'ValErr.Link', children: [usageArea === 'intent' ? link.intentPath : link.questionName, _jsx(Icon, { name: 'farArrowUp', color: 'primary', size: 'sm' })] }), usageArea === 'intent' && (_jsxs("p", { children: ["\u2014 ", Math.round(link.score * 100), "% ", t('intentValErrorPanel.similarity')] }))] }, link.questionName))) }), _jsxs("div", { className: 'validation-error-panel__btn-block', children: [_jsx(Button, { outline: true, color: 'secondary', onClick: updateWithoutValidation, children: t('saveAnyway') }), isContentManager && !isContentManager() && setCurrentProjectToEdit && (_jsx(Button, { flat: true, color: 'secondary', onClick: setCurrentProjectToEdit, children: t('similarsSettingBtn') }))] })] })] }));
}
