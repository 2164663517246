import { BotProjectModificationDto, ProjectsApi as BotProjectsApi } from '../api/client';
import { ProjectCreationData } from './helperClasses';
import { axios } from 'pipes/functions';

export default class ProjectService {
  private static readonly BASE_PATH = window.location.origin;
  private accountId: number;
  private projectsApi: BotProjectsApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.projectsApi = new BotProjectsApi({}, ProjectService.BASE_PATH, axios);
  }

  getProjects = (options?: any) => {
    return this.projectsApi.readAll(this.accountId, undefined, options);
  };

  createProject = (botProjectCreateDto: ProjectCreationData, options?: any) => {
    return this.projectsApi.create(this.accountId, botProjectCreateDto, undefined, options);
  };

  updateProject = (projectId: number, botProjectCreateDto: BotProjectModificationDto, options?: any) => {
    return this.projectsApi.update(this.accountId, projectId, botProjectCreateDto, options);
  };

  createProjectByZip = (file: File, botProjectCreateDto: ProjectCreationData, options?: any) => {
    delete botProjectCreateDto.file;
    (Object.keys(botProjectCreateDto) as (keyof ProjectCreationData)[]).forEach(createDTO_key => {
      const value = botProjectCreateDto[createDTO_key];
      if (value === null || value === undefined) {
        delete botProjectCreateDto[createDTO_key];
      }
    });
    const json = JSON.stringify(botProjectCreateDto);
    const botProjectFile = new Blob([json], { type: 'application/json' });

    // mm thread https://mm.just-ai.com/just-ai/pl/ueb5nkafjiys7puym44475yu9r
    const blobFile = new Blob([file], { type: 'application/zip' });
    return this.projectsApi.createByZip(this.accountId, undefined, blobFile, botProjectFile, options);
  };

  cloneProject = (projectShortName: string, name?: string, options?: any) => {
    if (!name) {
      name = projectShortName + '-clone';
    }
    return this.projectsApi.clone(this.accountId, projectShortName, name, options);
  };

  deleteProject = (projectId: number, options?: any) => {
    return this.projectsApi._delete(this.accountId, projectId, options);
  };
}
