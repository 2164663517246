import React from 'react';

import { highLightConnectors$ } from 'modules/JGraph/hooks/highLightConnectors';
import { CustomRxTooltip, IconButton, useBehaviorSubject } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../localization';

export const HighLightConnectorsStatus = React.memo(({ StageID }: { StageID: string }) => {
  const visible = useBehaviorSubject(highLightConnectors$);

  if (!visible.activePathId) return null;

  return (
    <>
      <IconButton
        flat
        id={`${StageID}_HighLightConnectorsStatus`}
        data-test-id='JGraph.JGToolbar.HighLightConnectorsStatus'
        color='secondary'
        name='faLightbulb'
        className={cn(
          styles.selectLabelColor,
          styles.selectLabelColor_fillBlue,
          styles.selectLabelColor_fillBlue_active
        )}
        onClick={() => highLightConnectors$.next({ connector: null })}
      />
      <CustomRxTooltip target={`#${StageID}_HighLightConnectorsStatus`} placement='bottom'>
        {t('JGraph:LabellingToolMenu:HighLightConnectorsButton:tooltip_off')}
      </CustomRxTooltip>
    </>
  );
});
HighLightConnectorsStatus.displayName = 'HighLightConnectorsStatus';
