import axios from 'axios';
import { MetaChannelApi, MetaChannelTokenRequiredDto } from '../api/chatadapterClient';

export type Response = {
  id: string;
  status: string;
  authResponse: { userID: string; accessToken: string };
  error: string;
  data: { id: string; tasks: string[] }[];
};

type InstagramAccount = {
  status: string;
  value: {
    data?: {
      instagram_business_account: {
        id: string;
      };
      id: string;
    };
  };
};

export type PageWithInstagram = Record<string, string[]>;

export default class FacebookService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private configsApi: MetaChannelApi;
  host = 'https://graph.facebook.com/v15.0';

  constructor(accountId: number) {
    this.accountId = accountId;
    this.configsApi = new MetaChannelApi({}, FacebookService.BASE_PATH, axios);
  }

  async sendFacebookData(payload: MetaChannelTokenRequiredDto, channelType: string) {
    try {
      const { data } = await this.configsApi.getMetaChannelToken(this.accountId, channelType, payload);
      return data.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  getFacebookAccounts = (callback: (response: Response) => void) =>
    window.FB.api('/me', (response: Response) => window.FB.api(response.id + '/accounts', 'GET', callback));

  getPagesByInstagramBussinessAccounts = async (response: Response, accessToken: string) => {
    const defaultData = {} as PageWithInstagram;
    try {
      const pagesOnlyWithManageTask = response.data.filter(({ tasks }) => !tasks || tasks.includes('MANAGE'));
      const data = await Promise.allSettled(
        pagesOnlyWithManageTask.map(
          async ({ id }) =>
            await axios.get(`${this.host}/${id}?fields=instagram_business_account&access_token=${accessToken}`)
        )
      ).then(res =>
        (res as InstagramAccount[]).filter(({ status }) => status === 'fulfilled').map(({ value }) => value)
      );

      return data.reduce((acc: PageWithInstagram, current) => {
        const instagramId = current.data?.instagram_business_account?.id;
        if (instagramId && current.data) {
          const pageIds = acc[instagramId] || [];
          pageIds.push(current.data.id);
          acc[instagramId] = pageIds;
        }
        return acc;
      }, defaultData);
    } catch (e) {
      console.error(e);
      return defaultData;
    }
  };
}
