import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Icon, useToggle } from '@just-ai/just-ui';
import { mapTemplateIdToIconName } from '../../../constants/faqTemplates';
import styles from './styles.module.scss';
import cn from 'classnames';
import FAQTemplateQuestionsModal from './FAQTemplateQuestionsModal';
import { useNLUModuleContext } from '../../../context';
const FAQTemplateCard = ({ className, template, selectFAQTemplate, toggleFAQTemplate, createSkill, toggleFileImportModal, disabled, }) => {
    const [modalOpen, openModal, , toggleModal] = useToggle();
    const { isAccessFunction: isAccess } = useNLUModuleContext();
    const { type, name, description, sampleQuestions: questions } = template;
    const iconName = useMemo(() => (type && mapTemplateIdToIconName[type]) || '', [type]);
    const writeDisabled = !isAccess('BOT_CONTENT_WRITE');
    const handleSubmit = () => {
        createSkill();
        toggleModal();
    };
    const closeModal = () => {
        toggleModal();
        toggleFAQTemplate(template);
    };
    const handleCardClick = useCallback((event) => {
        if (writeDisabled)
            return;
        if (type === 'faq_from_user_file' || type === 'cdqa') {
            selectFAQTemplate(template);
            toggleFileImportModal();
            return;
        }
        event.stopPropagation();
        openModal();
        selectFAQTemplate(template);
    }, [writeDisabled, type, openModal, selectFAQTemplate, template, toggleFileImportModal]);
    return (_jsxs("div", { className: cn(styles['faq-template-card'], className && styles[className], (writeDisabled || disabled) && styles['faq-template-card--disabled']), onClick: handleCardClick, "data-test-id": `FAQ.FAQTemplateCard.${name}`, children: [_jsx("div", { className: styles['faq-template-card__icon'], children: _jsx(Icon, { name: iconName }) }), _jsx("h4", { className: styles['faq-template-card__title'], children: name }), type !== 'empty_template' && _jsx("span", { className: styles['faq-template-card__description'], children: description }), _jsx(FAQTemplateQuestionsModal, { isOpen: modalOpen, toggle: closeModal, type: type, name: name, onSubmit: handleSubmit, questions: questions })] }));
};
export default React.memo(FAQTemplateCard);
