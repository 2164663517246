import { KnowledgeHubApi, } from "../../generated/Editorbe";
class KnowledgeHubIntegrationService {
    constructor(accountId, projectId, axios) {
        this.getIntegrations = () => this.knowledgeHubApi
            .getIntegrations(this.accountId, this.projectId)
            .then((res) => res.data);
        this.getPreviews = () => this.knowledgeHubApi.getPreviews(this.accountId).then((res) => res.data);
        this.createIntegration = (data, addSnippetToProject) => this.knowledgeHubApi
            .createIntegration(this.accountId, this.projectId, data, addSnippetToProject)
            .then((res) => res.data);
        this.createIntegrationByToken = (data, addSnippetToProject) => this.knowledgeHubApi
            .createIntegrationByToken(this.accountId, this.projectId, data, addSnippetToProject)
            .then((res) => res.data);
        this.getPreviewByToken = (knowledgeHubToken) => this.knowledgeHubApi
            .getPreviewByToken(this.accountId, knowledgeHubToken)
            .then((res) => res.data);
        this.deleteIntegration = (knowledgeHubIntegrationId, deleteSecret) => this.knowledgeHubApi
            .deleteIntegration(this.accountId, this.projectId, knowledgeHubIntegrationId, deleteSecret)
            .then((res) => res.data);
        this.accountId = accountId;
        this.projectId = projectId;
        this.knowledgeHubApi = new KnowledgeHubApi({}, KnowledgeHubIntegrationService.BASE_PATH, axios);
    }
}
KnowledgeHubIntegrationService.BASE_PATH = "";
export default KnowledgeHubIntegrationService;
