import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface EmptyBlocksPlaceholderInterface {
  title: string;
  description?: string;
  className?: string;
}
const EmptyBlocksPlaceholder = function ({ title, description, className }: EmptyBlocksPlaceholderInterface) {
  return (
    <div className={cn(styles.EmptyBlocksPlaceholder, className)}>
      <div className={styles.EmptyBlocksPlaceholder__title}>{title}</div>
      <div className={styles.EmptyBlocksPlaceholder__description}>{description}</div>
    </div>
  );
};

export default React.memo(EmptyBlocksPlaceholder);
