import React from 'react';
import styles from 'modules/JGraph/view/RightSideMenu/settings/AudioSettings/styles.module.scss';
import { t } from 'localization';
import { CustomCollapse } from '@just-ai/just-ui';

const AudioUploadHint = () => {
  return (
    <CustomCollapse
      title={<span className={styles.collapse__title}>{t('AudioSettings:collapsableHintText')}</span>}
      chevronPosition='right'
      toggleIcon='faCaretDown'
      headerClassName={styles.collapse__header}
      className={styles.collapse}
      collapseClassName={styles.collapse__body}
    >
      <p>
        <span>{t('AudioSettings:collapsableHintTextContent:PartOne:Header')}</span>
        <ul>
          <li>{t('AudioSettings:collapsableHintTextContent:PartOne:Option:1')}</li>
          <li>{t('AudioSettings:collapsableHintTextContent:PartOne:Option:2')}</li>
          <li>{t('AudioSettings:collapsableHintTextContent:PartOne:Option:3')}</li>
          <li>{t('AudioSettings:collapsableHintTextContent:PartOne:Option:4')}</li>
        </ul>
      </p>
      <p>
        <span>{t('AudioSettings:collapsableHintTextContent:PartTwo:Header')}</span>
        <ul>
          <li>{t('AudioSettings:collapsableHintTextContent:PartTwo:Option:1')}</li>
          <li>{t('AudioSettings:collapsableHintTextContent:PartTwo:Option:2')}</li>
          <li>{t('AudioSettings:collapsableHintTextContent:PartTwo:Option:3')}</li>
        </ul>
      </p>
      <p>
        {t('AudioSettings:collapsableHintTextContent:PartThree')}
        <a href={t('Config:Botadmin:HelpUrl') + '/telephony/audio_playback'} target='_blank' rel='noreferrer'>
          {t('AudioSettings:collapsableHintTextContent:PartThree:LinkTitle')}
        </a>
      </p>
    </CustomCollapse>
  );
};

export default React.memo(AudioUploadHint);
