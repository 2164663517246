import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Label } from 'reactstrap';
import { Button } from '@just-ai/just-ui';
import { addPhraseComment } from '../../../../actions/phrases.actions';
import localize from '../../../../localization';

class EditCommentModal extends Component {
  constructor(props) {
    super(props);
    this.form = {
      comment: React.createRef(),
    };
  }

  saveTask = e => {
    e.preventDefault();
    this.props.saveComment(this.form.comment.current.value);
    //todo do it only after success
    this.props.togglePopup();
  };

  render() {
    const { popup, togglePopup, logId, comment } = this.props;

    return (
      <Modal isOpen={popup} toggle={togglePopup}>
        <ModalHeader toggle={togglePopup}>{localize.translate('Comment')}</ModalHeader>
        <form key={`edit_comment_${logId}`} onSubmit={this.saveTask}>
          <ModalBody>
            <div className='row form-group'>
              <Label className='col-3 col-form-label'>{localize.translate('Comment')}</Label>
              <div className='col-9'>
                <Input
                  type='textarea'
                  maxLength='140'
                  placeholder={localize.translate('Comment')}
                  innerRef={this.form.comment}
                  defaultValue={comment}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type='reset' outline color='secondary' onClick={togglePopup}>
              {localize.translate('Cancel')}
            </Button>{' '}
            <Button type='submit' color='primary'>
              {localize.translate('Save')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

EditCommentModal.propTypes = {
  togglePopup: PropTypes.func.isRequired,
  popup: PropTypes.bool.isRequired,
  logId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  comment: PropTypes.string,
  saveComment: PropTypes.func.isRequired,
};

export function EditCommentModalForReporter({ isShown, comment, questionId, toggle, saveComment, projectShortName }) {
  const save = async newComment => {
    await addPhraseComment(questionId, projectShortName, newComment);
    saveComment(newComment);
  };

  return (
    <EditCommentModal togglePopup={toggle} popup={isShown} logId={questionId} saveComment={save} comment={comment} />
  );
}

export default EditCommentModal;
