import React from 'react';

import { SelectLabelProps } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';
import classes from './CommonActivationParams.module.scss';
import { t } from 'localization';

export function LabelComponentSelectState({ label, value, rightSideText }: SelectLabelProps) {
  return (
    <div className={classes.LabelComponentSelectState}>
      <div className='Just-select-label-text'>{label}</div>
      {value === 'new_value' && (
        <div className={classes.LabelComponentSelectStateCreate}>
          {rightSideText || t('LabelComponentSelectState:Create_new_item')}
        </div>
      )}
    </div>
  );
}
