export default function clamp(from = 0, to = Infinity) {
    return (value) => {
        if (value < from) {
            return from;
        }
        if (value > to) {
            return to;
        }
        return value;
    };
}
