import { FormGroup, JustSelect, Label, OptionType, Spinner } from '@just-ai/just-ui';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CommonActivationParams } from './CommonActivationParams';
import { EventStateChange } from './PatternActivationEdit';
import classes from './Activations.module.scss';

import { t } from 'localization';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { useAppContext } from '../../../../../Caila/components/AppContext';
import localize from '../../../../../../localization';
import { choosereadyintentLocalization } from '../../../../../Editor/components/VisualEditor/localization/choosereadyintent.loc';
import { EditIntentsModal } from './EditIntentsModal';
import { IconButton } from '@just-ai/just-ui/dist';
import { useJGraphContext } from '../../../../contexts/JGraphContext';
import { PhrasesAdding, PhrasesExtendDisabled } from './JGraphCailaIntents/PhrasesAdding';
import { buildIcon } from '../../settings/ScreenBlocksList/ReactionItem';
import { guideTourEvent$ } from '../../../JGraphGuideTour/guideTourEvents';

localize.addTranslations(choosereadyintentLocalization);

type IntentActivationProps = {
  isGlobal: boolean;
  intent: string;
  eventState: EventStateChange;
  changeEventParams: (value: EventStateChange) => unknown;
  changeIntent: (value: string) => unknown;
  isFromState?: boolean;
  isToState?: boolean;
};

interface ExtendedOption extends OptionType {
  id?: string;
  path?: string;
}

export const IntentsActivationEdit: FC<IntentActivationProps> = ({
  isGlobal,
  changeEventParams,
  eventState,
  changeIntent,
  intent,
  isFromState,
  isToState,
}) => {
  const [userIntents, setUserIntents] = useState<ExtendedOption[]>([]);
  const [userIntentsLoading, setUserIntentsIsLoading] = useState(true);
  const [externalEditId, setExternalEditId] = useState('');
  const [isIntentCreationOpen, openIntentCreation] = useState(false);
  const { currentBotLanguage, SettingsService } = useJGraphContext();

  const { IntentsService } = useAppContext();
  const refSaveId = useRef<string>('');

  const isSystemIntent = useMemo(() => {
    return IntentsService.isSystemIntent(currentBotLanguage, intent);
  }, [IntentsService, currentBotLanguage, intent]);

  const closeModal = useCallback(() => {
    openIntentCreation(false);
    setUserIntentsIsLoading(true);
    setExternalEditId('');
  }, []);

  useEffect(() => {
    if (userIntentsLoading) {
      IntentsService.listIntents().then(payload => {
        const stateUserIntents = payload.data.map(intent => ({
          label: intent.path || '',
          value: intent.path || '',
          id: String(intent.id || ''),
          path: intent.path,
        }));
        setUserIntents(stateUserIntents);
        if (refSaveId.current) {
          const found = stateUserIntents.find(intent => String(intent.id) === String(refSaveId.current));
          if (found) {
            changeIntent(found.value);
            refSaveId.current = '';
          }
        }
        guideTourEvent$.next(`IntentsActivationEdit:userIntentsLoaded`);
        setUserIntentsIsLoading(false);
      });
    }
  }, [IntentsService, changeIntent, userIntentsLoading]);

  const selectOptions = useMemo(() => {
    let options = userIntents.map(el => ({
      ...el,
      label: hideRootSlashInPath(el.label),
    }));
    IntentsService.SystemIntents[currentBotLanguage].forEach(value =>
      options.push({
        label: t(`ChooseReadyIntent ${value}`),
        value,
      })
    );
    options.splice(0, 0, { label: t('IntentActivationEdit:createIntent'), value: 'new_intent_create' });
    return options;
  }, [IntentsService.SystemIntents, currentBotLanguage, userIntents]);

  const onChangeIntent = useCallback(
    (value: (string | number)[] | null) => {
      if (value && value[0]) {
        if (value[0] === 'new_intent_create') {
          openIntentCreation(true);
          return;
        }
        if (
          IntentsService.isSystemIntent(currentBotLanguage, String(value[0])) &&
          SettingsService.projectSettingsData &&
          (!SettingsService.projectSettingsData.extendedSettings?.useShared ||
            !SettingsService.projectSettingsData.extendedSettings?.zflPatternsEnabled)
        ) {
          SettingsService.setProjectSettings(
            {
              extendedSettings: {
                zflPatternsEnabled: true,
                useShared: true,
              },
            },
            true
          );
        }
        changeIntent(String(value[0]));
        return;
      }
      changeIntent('');
    },
    [IntentsService, SettingsService, changeIntent, currentBotLanguage]
  );

  const setEdit = useCallback(() => {
    if (isSystemIntent) {
      setExternalEditId(intent);
      openIntentCreation(true);
    } else {
      const find = userIntents.find(uIntent => uIntent.value === intent);
      if (find && find.id) {
        setExternalEditId(find.id);
        openIntentCreation(true);
      }
    }
  }, [intent, isSystemIntent, userIntents]);

  const isKnowledgeBase = useMemo(() => {
    const find = userIntents.find(uIntent => uIntent.value === intent);
    return Boolean(find && find.path?.startsWith('/KnowledgeBase'));
  }, [intent, userIntents]);

  const chooseIntent = useCallback(
    (id: string) => {
      if (parseInt(id) > 0) {
        refSaveId.current = id;
      } else {
        changeIntent(String(id));
      }
      closeModal();
    },
    [changeIntent, closeModal]
  );

  return (
    <div className={classes.PatternsActivation}>
      {userIntentsLoading && <Spinner />}
      <FormGroup tag='div' data-test-id='IntentsActivationEdit:main'>
        <Label>{t('IntentActivationEdit:label')}</Label>
        <div
          className={`position-relative d-flex justify-content-between gap-8 align-items-center ${
            isSystemIntent && 'isSystemIntent'
          }`}
        >
          {isSystemIntent && buildIcon('systemIntent', 'thunderIconSelect absolute')}
          <JustSelect
            data-test-id='IntentActivation:SelectIntent'
            key={`recreateOnLoad${userIntentsLoading}`}
            inputPlaceholder={t('IntentActivationEdit:placeholder')}
            fullWidth
            value={intent}
            options={selectOptions}
            onChange={onChangeIntent}
            position='fixed'
            className={isSystemIntent ? 'isSystemIntent' : undefined}
          />
          <span className={classes.splitter} />
          <div id='farCommentAltDotsId'>
            <IconButton
              name='farCommentAltDots'
              color='secondary'
              outline
              flat
              onClick={setEdit}
              disabled={isKnowledgeBase}
            />
          </div>
        </div>
        <PhrasesExtendDisabled
          targetId='#farCommentAltDotsId'
          placement='left'
          show={isKnowledgeBase}
          text={t('PhrasesAddingToggle:knowledgeBase_cant_edit_from_here')}
        />
        {isToState && !userIntentsLoading && <PhrasesAdding intent={intent} />}
      </FormGroup>
      <EditIntentsModal
        toggle={closeModal}
        open={isIntentCreationOpen}
        externalEditId={externalEditId}
        chooseIntent={chooseIntent}
      />
      <div className={classes.divider2} />
      <CommonActivationParams
        isFromState={isFromState}
        isToState={isToState}
        isGlobal={isGlobal}
        isIntentMenu={true}
        onChangeIsGlobal={value =>
          changeEventParams({
            isGlobal: value,
            onlyThisState: eventState.onlyThisState,
            fromStateValue: eventState.fromStateValue,
            toStateValue: eventState.toStateValue,
          })
        }
        onlyThisState={eventState.onlyThisState}
        onChangeOnlyThisState={value =>
          changeEventParams({
            isGlobal: isGlobal,
            onlyThisState: value,
            fromStateValue: eventState.fromStateValue,
            toStateValue: eventState.toStateValue,
          })
        }
        fromStateValue={eventState.fromStateValue}
        onChangeStateValue={value =>
          changeEventParams({
            isGlobal: isGlobal,
            onlyThisState: eventState.onlyThisState,
            fromStateValue: isFromState ? value : '',
            toStateValue: isToState ? value : '',
          })
        }
        toStateValue={eventState.toStateValue}
      />
    </div>
  );
};
