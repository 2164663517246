import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../../Caila/components/AppContext';
import { useAppDispatch } from '../../../../storeHooks';
import { ConflictResolutionType, StorageApi } from '../../../Editor/api/client';
import { axios } from '../../../../pipes/functions';
import { getErrorMessageFromReason, ModalControl, useModal } from '../../../Caila/utils';
import { bindActionCreators } from 'redux';
import { addMessage } from '../../../../actions/globalAlert.actions';
import { getGraph } from '../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';

import { Spinner, useToggle } from '@just-ai/just-ui';
import RenderingBlockWrapper from '../RenderingModal/RenderingBlockWrapper';
import ReactDOM from 'react-dom';
import { useWSContext } from '../../../Notifications/context/WSContext';
import {
  MARK_SEEN_PATH,
  Notifications_RemoteChangesRemove$,
  Notifications_RemoteChangesStore$,
} from '../../../Notifications/context/NotificationsContext';

type LoadCallback = <TResult>(promise: Promise<TResult>) => Promise<TResult>;

type CommitButtonContextType = {
  showError: (reason: any) => unknown;
  commit: (message: string) => Promise<unknown>;
  discard: () => Promise<unknown>;
  pull: () => Promise<unknown>;
  resolve: (message: string, resolution: ConflictResolutionType) => Promise<unknown>;
  commitDialog: ModalControl;
  discardDialog: ModalControl;
  commitMessage: string;
  isGitProject: boolean;
  hasExternalChanges: boolean;
  setCommitMessage: (value: string) => unknown;
};

export const CommitButtonContext = createContext({} as CommitButtonContextType);

export const CommitButtonContextProvider: FC = ({ children }) => {
  const { accountId, currentProject, projectShortName } = useAppContext();
  const { send } = useWSContext();
  const isGitProject = useMemo(() => Boolean(currentProject?.repositoryUrl), [currentProject?.repositoryUrl]);
  const dispatch = useAppDispatch();
  const storageApi = useMemo(() => {
    return new StorageApi({}, '', axios);
  }, []);
  const [inProgress, setInProgress, setLoaded] = useToggle(false);
  const commitDialog = useModal();
  const discardDialog = useModal();
  const [commitMessage, setCommitMessage] = useState('');
  const [hasExternalChanges, setHasExternalChanges] = useState(false);

  const boundActionCreators = useMemo(() => bindActionCreators({ addMessage }, dispatch), [dispatch]);

  const showError = useCallback(
    (reason: any) => {
      if (!reason) return;
      const { t } = i18nTranslation();
      boundActionCreators.addMessage({
        type: 'error',
        title: t('Errors:error'),
        message: getErrorMessageFromReason(reason, t),
        time: Date.now(),
        showed: true,
      });
    },
    [boundActionCreators]
  );
  const load: LoadCallback = useCallback(
    promise => {
      setInProgress();
      return promise.finally(() => setLoaded());
    },
    [setInProgress, setLoaded]
  );

  const markSeen = useCallback(() => {
    const notificationsToDelete = Notifications_RemoteChangesStore$.getValue().map(notification => notification.id);
    Notifications_RemoteChangesRemove$.next(true);
    send(MARK_SEEN_PATH, {
      notifications: notificationsToDelete,
    });
  }, [send]);

  const commit = useCallback(
    (commitMessage: string, conflictResolution?: ConflictResolutionType) => {
      if (conflictResolution && [ConflictResolutionType.IgnoreRemote].includes(conflictResolution)) {
        markSeen();
      }
      return load(
        storageApi.commitAndPush(accountId, projectShortName, { commitMessage, conflictResolution }).then(() => {
          setCommitMessage('');
          setHasExternalChanges(false);
        })
      );
    },
    [accountId, load, markSeen, projectShortName, storageApi]
  );

  const resolve = useCallback(
    (message: string, conflictResolution: ConflictResolutionType) => commit(message, conflictResolution),
    [commit]
  );

  const pull = useCallback(async () => {
    await load(
      storageApi
        .pull(accountId, projectShortName)
        .then(() => {
          markSeen();
          setHasExternalChanges(false);
        })
        .catch(showError)
    );
    RenderingBlockWrapper.Percentage$.next({ type: 'cleanup' });
    await load(dispatch(getGraph({})));
  }, [accountId, dispatch, load, markSeen, projectShortName, showError, storageApi]);

  const discard = useCallback(async () => {
    await load(storageApi.discardChanges(accountId, projectShortName));
    if (hasExternalChanges) {
      markSeen();
    }
    RenderingBlockWrapper.Percentage$.next({ type: 'cleanup' });
    setHasExternalChanges(false);
    dispatch(getGraph({}));
  }, [accountId, dispatch, hasExternalChanges, load, markSeen, projectShortName, storageApi]);

  useEffect(() => {
    const sub = Notifications_RemoteChangesStore$.subscribe(notifications => {
      setHasExternalChanges(notifications.length > 0);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data === 'commit') {
        load(commit('Commit from iframe'));
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [commit, load]);

  return (
    <CommitButtonContext.Provider
      value={{
        showError,
        discard,
        pull,
        resolve,
        commit,
        commitDialog,
        discardDialog,
        commitMessage,
        setCommitMessage,
        isGitProject,
        hasExternalChanges: Boolean(hasExternalChanges),
      }}
    >
      {inProgress && ReactDOM.createPortal(<Spinner />, document.body)}
      {children}
    </CommitButtonContext.Provider>
  );
};
