import { debounce, difference } from 'lodash';
import { IAceEditor } from 'react-ace/lib/types';

import { GutterMarkerTooltip } from './GutterMarkerTooltip';
import { GutterMarker, GutterMarkerMatch, AceGutterLine, tags } from './types';

export class GutterMarkerWidget {
  private markers: GutterMarker[] = [];
  private markersMap: Record<string, GutterMarker> = {};
  private matches: GutterMarkerMatch[] = [];
  private renderedMatches: HTMLElement[] = [];
  private tooltip: GutterMarkerTooltip;

  constructor(private editor: IAceEditor, tooltipTargetId: string) {
    this.tooltip = new GutterMarkerTooltip(500, this.tooltipHandler, tooltipTargetId);
  }

  public addMarker(marker: GutterMarker) {
    const isExist = this.markers.find(el => el.className === marker.className);
    if (isExist) return;
    this.markers.push(marker);
    this.markersMap[marker.className] = marker;
  }

  public apply() {
    this.editor.renderer.on(
      'afterRender',
      debounce(() => {
        this.updateMarkers();
        this.updateSubscriptions();
      }, 200)
    );
    // @ts-ignore
    this.editor.on('gutterclick', this.onGutterClick);
  }

  public deactivate() {
    this.unsubscribeAll();
    this.resetAllDecorators();
    this.editor.off('gutterclick', this.onGutterClick);
    this.tooltip.hide();
  }

  private onGutterClick = ({ domEvent }: { domEvent: { target: HTMLElement } }) => {
    const lineNumber = GutterMarkerWidget.getLineNumberFromNode(domEvent.target);
    if (!lineNumber) return;
    const match = this.matches.find(match => match.rowNumber === lineNumber);
    if (!match) return;
    const marker = this.markersMap[match.className];
    marker?.onClick(match.text, lineNumber);
  };

  private static getLineNumberFromNode(node?: HTMLElement) {
    let lineNumber = node?.innerText;
    if (!lineNumber) return;
    return parseInt(lineNumber) - 1;
  }

  private tooltipHandler = ({ node, isOpen }: { isOpen: boolean; node: HTMLElement }) => {
    const lineNumber = GutterMarkerWidget.getLineNumberFromNode(node);
    if (!lineNumber) return;
    const match = this.matches.find(match => match.rowNumber === lineNumber);
    if (!match) return;
    const marker = this.markersMap[match.className];
    if (!marker) return;

    if (marker.tooltipHandler) {
      marker.tooltipHandler({ isOpen, node });
    }
  };

  private updateSubscriptions() {
    const lines = this.getGutterRenderedLines();
    const renderedLineNodes = lines.map(line => line.element);

    const newRenderedMatches = difference(renderedLineNodes, this.renderedMatches);
    newRenderedMatches.forEach(node => {
      node.addEventListener('mouseenter', this.onMouseEnter);
      node.addEventListener('mouseleave', this.onMouseLeave);
    });

    const deletedRenderedMatches = difference(this.renderedMatches, renderedLineNodes);
    deletedRenderedMatches.forEach(node => {
      node.removeEventListener('mouseenter', this.onMouseEnter);
      node.removeEventListener('mouseleave', this.onMouseLeave);
    });

    this.renderedMatches = renderedLineNodes;
  }

  private unsubscribeAll() {
    this.renderedMatches.forEach(node => {
      node.removeEventListener('mouseenter', this.onMouseEnter);
      node.removeEventListener('mouseleave', this.onMouseLeave);
    });
  }

  private onMouseEnter = (e: MouseEvent) => {
    this.tooltip.showWithDelay(e.target as any);
  };

  private onMouseLeave = () => {
    this.tooltip.hide();
  };

  private parseTags() {
    const tagRegex = /\s*([\w!]+): (.+)$/;

    const lineCount = this.editor.session.getLength();
    const emptyCurrent = {
      tagName: '',
      value: '',
      lineNumber: 0,
    };

    let current = { ...emptyCurrent };
    const tagValues = [];

    for (let lineCursor = 0; lineCursor <= lineCount; lineCursor++) {
      let lineText = this.editor.session.getLine(lineCursor);
      const matchTag = lineText.match(tagRegex);

      const nextTag = (matchTag && matchTag[1]) || '';
      const isValidTag = tags.includes(nextTag);

      if (isValidTag) {
        if (current.tagName) {
          tagValues.push(current);
          current = { ...emptyCurrent };
        }
        const parsedValue = (matchTag && matchTag[2]) || '';
        if (parsedValue) {
          current.tagName = nextTag;
          current.value = parsedValue;
          current.lineNumber = lineCursor;
        }
      } else {
        current.value += '\n' + lineText.trim();
      }
    }

    if (current.tagName) {
      tagValues.push(current);
    }

    return tagValues;
  }

  private updateMarkers() {
    this.matches = [];

    const lineCount = this.editor.session.getLength();
    for (let lineCursor = 0; lineCursor <= lineCount; lineCursor++) {
      // @ts-ignore
      const isRowAlreadyDecorated = !!this.editor.session.$decorations[lineCursor];

      if (isRowAlreadyDecorated) {
        for (const marker of this.markers) {
          this.editor.session.removeGutterDecoration(lineCursor, marker.className);
        }
      }
    }

    const tags = this.parseTags().filter(tag => tag.tagName === 'a');
    tags.forEach(tag => {
      for (const marker of this.markers) {
        const match = {
          className: marker.className,
          rowNumber: tag.lineNumber,
          text: tag.value,
        };

        this.editor.session.addGutterDecoration(match.rowNumber, match.className);
        this.matches.push(match);
      }
    });
  }

  private resetAllDecorators() {
    this.matches.forEach(match => {
      this.editor.session.removeGutterDecoration(match.rowNumber, match.className);
    });
    this.matches = [];
  }

  private getGutterRenderedLines(): AceGutterLine[] {
    // @ts-ignore
    return [...this.editor.renderer.$gutterLayer.$lines.cells];
  }
}
