var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnalyticsEntitiesApi, } from "../../generated/Reporter";
import axios from "axios";
export class AnalyticsEntityService {
    constructor(accountId, projectShortName) {
        this.createSessionTopic = (analyticsEntityCreateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.createSessionTopic(this.accountId, this.projectShortName, analyticsEntityCreateDto);
            return data;
        });
        this.createScenarioAction = (analyticsEntityCreateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.createScenarioAction(this.accountId, this.projectShortName, analyticsEntityCreateDto);
            return data;
        });
        this.createSessionResult = (analyticsEntityCreateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.createSessionResult(this.accountId, this.projectShortName, analyticsEntityCreateDto);
            return data;
        });
        this.getSessionTopics = (sort, keyOrValue, page, size, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.getSessionTopics(this.accountId, this.projectShortName, sort, keyOrValue, page, size, options);
            return data;
        });
        this.getScenarioActions = (sort, keyOrValue, page, size, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.getScenarioActions(this.accountId, this.projectShortName, sort, keyOrValue, page, size, options);
            return data;
        });
        this.getSessionResults = (sort, keyOrValue, page, size, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.getSessionResults(this.accountId, this.projectShortName, sort, keyOrValue, page, size, options);
            return data;
        });
        this.deleteSessionTopic = (id) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.deleteSessionTopic(this.accountId, this.projectShortName, id);
            return data;
        });
        this.deleteScenarioAction = (id) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.deleteScenarioAction(this.accountId, this.projectShortName, id);
            return data;
        });
        this.deleteSessionResult = (id) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.deleteSessionResult(this.accountId, this.projectShortName, id);
            return data;
        });
        this.updateSessionTopic = (id, analyticsEntityUpdateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.updateSessionTopic(this.accountId, this.projectShortName, id, analyticsEntityUpdateDto);
            return data;
        });
        this.updateScenarioAction = (id, analyticsEntityUpdateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.updateScenarioAction(this.accountId, this.projectShortName, id, analyticsEntityUpdateDto);
            return data;
        });
        this.updateSessionResult = (id, analyticsEntityUpdateDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.service.updateSessionResult(this.accountId, this.projectShortName, id, analyticsEntityUpdateDto);
            return data;
        });
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.service = new AnalyticsEntitiesApi({}, AnalyticsEntityService.BASE_PATH, axios);
    }
}
AnalyticsEntityService.BASE_PATH = "";
