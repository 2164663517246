import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import memoize from 'memoizerific';
import ResizeObserver from 'react-resize-observer';
import VirtualList from 'react-tiny-virtual-list';
import { Branch } from './Branch';
const getItemHeight = (item, expanded, dataset, itemHeight) => {
    if (!expanded[item.nodeId] || !item.children)
        return itemHeight;
    return itemHeight + item.children.reduce((acc, childId) => acc + getItemHeight(dataset[childId], expanded, dataset, itemHeight), 0);
};
export const getMainBranches = memoize(1)((disableVirtualList) => {
    return disableVirtualList
        ? props => _jsx(SimpleMainBranches, Object.assign({}, props))
        : props => _jsx(VirtualMainBranches, Object.assign({}, props));
});
const SimpleMainBranches = (props) => (_jsx(_Fragment, { children: props.mains.map(node => (_jsx(Branch, { depth: 0, root: node.nodeId, itemHeight: props.itemHeight, dataset: props.filteredDataset, selected: props.selected, expanded: props.expanded, editId: props.editId, Head: props.Head, Leaf: props.Leaf, Edit: props.Edit, InnerContainer: props.InnerContainer }, node.nodeId))) }));
const VirtualMainBranches = (props) => (_jsxs(_Fragment, { children: [_jsx(VirtualList, { height: props.treeHeight, width: '100%', itemCount: props.mains.length, itemSize: index => getItemHeight(props.mains[index], props.expanded, props.filteredDataset, props.itemHeight), renderItem: ({ index, style }) => _jsx(Branch, { style: style, depth: 0, itemHeight: props.itemHeight, dataset: props.filteredDataset, selected: props.selected, expanded: props.expanded, root: props.mains[index].nodeId, editId: props.editId, Head: props.Head, Leaf: props.Leaf, Edit: props.Edit, InnerContainer: props.InnerContainer }, props.mains[index].nodeId) }), _jsx(ResizeObserver, { onResize: props.onResize })] }));
