import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { TStateToUpdate, updateState, getGraph } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { saveScreen, restoreState } from 'reducers/JGraph.reducer/index';
import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';

const updateStateInReduxAndApi = createAsyncThunk(
  'JGraph/updateStateInReduxAndApi',
  async (createJStateData: TStateToUpdate, thunkAPI) => {
    let state = thunkAPI.getState() as RootState;
    thunkAPI.dispatch(saveScreen(createJStateData));
    const editMenuBlockOldPath = createJStateData.useSelfPathValue
      ? createJStateData.path
      : state.JGraphReducer.editMenuBlock
      ? state.JGraphReducer.editMenuBlock.screen.path
      : createJStateData.path;

    if (editMenuBlockOldPath !== createJStateData.path) debugger;
    mainSave$.next({
      type: 'update',
      path: editMenuBlockOldPath,
      action: () => thunkAPI.dispatch(updateState(createJStateData)),
    });
    if (
      state.JGraphReducer.editMenuBlock?.screen.value &&
      createJStateData.value !== state.JGraphReducer.editMenuBlock?.screen.value
    ) {
      mainSave$.next({
        type: 'syncGraph',
        path: editMenuBlockOldPath,
        action: () => thunkAPI.dispatch(getGraph({})),
      });
    }
  }
);

export const revertUpdatingStateInReduxAndApi = createAsyncThunk(
  'JGraph/revertUpdatingStateInReduxAndApi',
  async (revertEvent: RevertEvent<TStateToUpdate>, thunkAPI) => {
    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevScreen = findScreenByPath(revertEvent.payload.path, prevScreens);
    if (!prevScreen) return;
    thunkAPI.dispatch(restoreState({ screen: prevScreen }));

    mainSave$.next({
      type: 'update',
      path: prevScreen.path,
      action: () => thunkAPI.dispatch(updateState(prevScreen)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertUpdatingStateInReduxAndApi,
  updateStateInReduxAndApi
);
