import React, { FC, useCallback } from 'react';

import { t } from 'localization';

import { CommonActivationSelectState } from '../../RSAddingMenu/ActivationsAddingMenu/CommonActivationSelectState';
import { CommonFieldProps } from './types';
import { withController } from './Controller';
import { CommonLabel } from './CommonLabel';
import CustomTagWarningBlock from './CustomTagWarningBlock';

const StateFieldView: FC<CommonFieldProps> = ({
  name,
  label,
  onChange,
  isRequired,
  value,
  description,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  invalid,
  isShouldShowFormatWarning,
}) => {
  const onChangeWithCreationHandler = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className='form-group form-group-fix'>
      <CommonActivationSelectState
        fromStateValue=''
        toStateValue={value}
        isGlobal={false}
        isFromState={false}
        invalid={invalid}
        isToState={true}
        onChangeStateValue={onChangeWithCreationHandler}
        label={<CommonLabel name={name} label={label} description={description} isRequired={isRequired} />}
      />
      {invalid && (
        <div className='invalid-feedback' style={{ display: 'block' }}>
          {t('Required Field')}
        </div>
      )}
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const StateField = withController(StateFieldView);
