import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TActivationParameters } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { saveIntent, restoreState } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId, findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

type SaveIntentAsyncPayload = {
  value: string;
  isGlobal: boolean;
  tagParameters: TActivationParameters;
  targetState?: JStateWithId;
  shouldGoDetail?: boolean;
};
const saveIntentAsync = createAsyncThunk('JGraph/saveIntentAsync', async (data: SaveIntentAsyncPayload, thunkAPI) => {
  thunkAPI.dispatch(saveIntent(data));
  const state = thunkAPI.getState() as RootState;
  const storeScreens = state.JGraphReducer.graph.blocks;
  const editMenuScreen = data.targetState || state.JGraphReducer.editMenuBlock?.screen;
  const screenToSave = findScreenByPathId(editMenuScreen!.pathId, storeScreens);
  if (screenToSave) {
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () =>
        thunkAPI.dispatch(updateState(data.targetState ? { ...screenToSave, useSelfPathValue: true } : screenToSave)),
    });
  }
});

const revertSaveIntentAsync = createAsyncThunk(
  'JGraph/revertSaveIntentAsync',
  async (revertEvent: RevertEvent<SaveIntentAsyncPayload>, thunkAPI) => {
    const editMenuScreen = revertEvent.payload.targetState || revertEvent.prevState.JGraphReducer.editMenuBlock?.screen;
    if (!editMenuScreen) return;
    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevScreen = findScreenByPath(editMenuScreen.path, prevScreens);
    if (!prevScreen) return;
    thunkAPI.dispatch(restoreState({ screen: prevScreen }));

    mainSave$.next({
      type: 'update',
      path: prevScreen.path,
      action: () => thunkAPI.dispatch(updateState(prevScreen)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertSaveIntentAsync, saveIntentAsync);
