const prefix = '@currentClient/';
export const SET_CURRENT_CLIENT = prefix + 'SET_CURRENT_CLIENT';
export const SET_CURRENT_CLIENT_PENDING = prefix + 'SET_CURRENT_CLIENT_PENDING';
export const SET_CURRENT_CLIENT_FULFILLED = prefix + 'SET_CURRENT_CLIENT_FULFILLED';

export const LOAD_CLIENTS_LOG = prefix + 'LOAD_CLIENTS_LOG';
export const LOAD_CLIENTS_LOG_FULFILLED = prefix + 'LOAD_CLIENTS_LOG_FULFILLED';
export const LOAD_CLIENTS_LOG_PENDING = prefix + 'LOAD_CLIENTS_LOG_PENDING';
export const LOAD_CLIENTS_LOG_REJECTED = prefix + 'LOAD_CLIENTS_LOG_REJECTED';
export const SET_CURRENT_CLIENT_REJECTED = prefix + 'SET_CURRENT_CLIENT_REJECTED';

export const TOGGLE_BLOCK_CLIENT = prefix + 'TOGGLE_BLOCK_CLIENT';
export const TOGGLE_BLOCK_CLIENT_PENDING = prefix + 'TOGGLE_BLOCK_CLIENT_PENDING';
export const TOGGLE_BLOCK_CLIENT_FULFILLED = prefix + 'TOGGLE_BLOCK_CLIENT_FULFILLED';
export const TOGGLE_BLOCK_CLIENT_REJECTED = prefix + 'TOGGLE_BLOCK_CLIENT_REJECTED';

export const TOGGLE_MODAL = prefix + 'TOGGLE_MODAL';
