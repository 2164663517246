var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProjectApi } from '@just-ai/api/dist/generated/Editorbe/api';
export class CustomBlocksService {
    constructor(accountId, localize, axios) {
        this.listCustomBlocks = (projectId_1, snapshot_1, ...args_1) => __awaiter(this, [projectId_1, snapshot_1, ...args_1], void 0, function* (projectId, snapshot, branch = 'master') {
            const { data } = yield this.service.listCustomTextCampaignBlocks(this.accountId, projectId, snapshot, undefined, branch);
            const topLevelLocalizationFields = ['caption', 'hint', 'description'];
            const localizations = data.reduce((loca, currentValue) => {
                topLevelLocalizationFields.forEach(param => {
                    if (currentValue[param] && currentValue.tagName) {
                        if (!loca[`CustomTag:${currentValue.tagName}:${param}`])
                            loca[`CustomTag:${currentValue.tagName}:${param}`] = {};
                        loca[`CustomTag:${currentValue.tagName}:${param}`] = currentValue[param];
                    }
                });
                if (currentValue.parameters && currentValue.tagName) {
                    currentValue.parameters.forEach((param) => {
                        if (param.localization) {
                            if (!loca[`CustomTag:${currentValue.tagName}:param:${param.name}`])
                                loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = {};
                            loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = param.localization;
                        }
                        if (param.description) {
                            if (!loca[`CustomTag:${currentValue.tagName}:param:${param.name}`])
                                loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = {};
                            loca[`CustomTag:${currentValue.tagName}:param:${param.name}:description`] = param.description;
                        }
                        if (param.description) {
                            if (!loca[`CustomTag:${currentValue.tagName}:${param.name}`])
                                loca[`CustomTag:${currentValue.tagName}:${param.name}`] = {};
                            loca[`CustomTag:${currentValue.tagName}:${param.name}:description`] = param.description;
                        }
                    });
                }
                return loca;
            }, {});
            this.localize.addTranslations(localizations);
            return data;
        });
        this.accountId = accountId;
        this.localize = localize;
        this.service = new ProjectApi({}, CustomBlocksService.BASE_PATH, axios);
    }
}
CustomBlocksService.BASE_PATH = '';
