import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle } from '../Dropdown';
import classNames from "classnames";
const SPEEDS = [4, 3, 2, 1.5, 1];
const SpeedAudio = ({ onChangeSpeed, currentSpeed }) => {
    const onDropdownItemClick = useCallback((speed) => () => {
        onChangeSpeed(speed);
    }, [onChangeSpeed]);
    return (_jsxs(DropdownButton, { direction: "up", "data-test-id": "dropdown-wrapper", className: "audio-speed", children: [_jsx(DropdownToggle, { color: "none", "data-test-id": "dropdown-toggle", className: "audio-speed__button audio-speed__value audio-speed__value_current", children: `${currentSpeed}x` }), _jsx(DropdownMenu, { "data-test-id": "dropdown-menu", className: "audio-speed__menu", children: SPEEDS.map((speed) => _jsx(DropdownItem, { className: classNames("audio-speed__value", { "audio-speed__value_current": currentSpeed === speed }), onClick: onDropdownItemClick(speed), "data-test-id": "dropdown-item", children: `${speed}x` }, `speed-${speed}`)) })] }));
};
export default React.memo(SpeedAudio);
