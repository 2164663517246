import React from 'react';

import styles from './styles.module.scss';

interface StageLabelInterface {
  theme: {
    mainColor: string;
    altBorderColor?: string;
  };
}
const StageLabel = function ({ theme }: StageLabelInterface) {
  return (
    <div
      className={styles.StageLabel}
      style={{ backgroundColor: theme.mainColor, borderColor: theme.altBorderColor }}
    />
  );
};

export default React.memo(StageLabel);
