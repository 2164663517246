import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { TagNames } from 'modules/JGraph/utils/types';

import { getSortableActivations } from '../view/RightSideMenu/utils/activations';

export const scenarioStart = ['$regex</start>', '*start'];

type StateType = 'start' | 'noMatch' | 'noMatchGlobal';
export function isStateType(screen: JStateWithId, type: StateType) {
  switch (type) {
    case 'start':
      return getSortableActivations(screen.blocks).some(
        activationBlock =>
          activationBlock.block.tagName === TagNames.q_ && scenarioStart.includes(activationBlock.block.tagValue ?? '')
      );
    case 'noMatch':
      return screen.blocks.some(
        block => [TagNames.event_, TagNames.event].includes(block.tagName) && block.tagValue === 'noMatch'
      );
    case 'noMatchGlobal':
      return screen.blocks.some(block => block.tagName === TagNames.event_ && block.tagValue === 'noMatch');
    default:
      return false;
  }
}
