import React, { FunctionComponent, useEffect } from 'react';
import { Toolbar, ToolbarLeft, VerticallyResizableArea } from '@just-ai/just-ui';
import './style.scss';
import { LogPanel, LogContextProvider } from '../../modules/Logs';
import { ParseErrorsButtonBoundingPlace } from 'modules/Editor/components/ParseErrorsPanel/ParseErrorsButton';
import { useGlobalBottomPanelContext } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import { LogButton } from 'modules/Logs/components/LogButton';
import { useToggle } from 'modules/Caila/utils';
import { ParseErrorsPanelBoundingPlace } from 'modules/Editor/components/ParseErrorsPanel';
import classNames from 'classnames';
import { LOGS_PANEL_TYPE } from 'modules/Logs/components/LogPanel';

const INITIAL_HEIGHT = 300;
const MIN_HEIGHT = 200;
const MAX_HEIGHT = 500;

type Props = {
  location: Location;
};

const GlobalBottomPanel: FunctionComponent<Props> = ({ location }) => {
  const { shownPanel, showPanel, hidePanel, togglePanel } = useGlobalBottomPanelContext();
  const [isLogPanelMaximized, , , toggleLogPanelMaximized] = useToggle(false);

  useEffect(() => {
    if (
      !location.pathname.endsWith('/editor') &&
      !location.pathname.endsWith('/visualeditor') &&
      shownPanel === 'errors'
    )
      hidePanel('errors');
  }, [location.pathname, shownPanel, hidePanel]);

  return (
    <LogContextProvider>
      <VerticallyResizableArea
        resizeDirection='up'
        maximized={isLogPanelMaximized}
        initialHeight={INITIAL_HEIGHT}
        minHeight={MIN_HEIGHT}
        maxHeight={MAX_HEIGHT}
        className={classNames('global-bottom-panel', { hidden: shownPanel === undefined })}
      >
        <LogPanel
          control={{
            isShown: shownPanel === LOGS_PANEL_TYPE,
            isMaximized: isLogPanelMaximized,
            show: () => showPanel(LOGS_PANEL_TYPE),
            hide: () => hidePanel(LOGS_PANEL_TYPE),
            toggle: () => togglePanel(LOGS_PANEL_TYPE),
            toggleMaximize: toggleLogPanelMaximized,
          }}
        />
        <ParseErrorsPanelBoundingPlace />
      </VerticallyResizableArea>
      <Toolbar className='global-bottom-panel-toolbar'>
        <ToolbarLeft>
          <LogButton />
          <ParseErrorsButtonBoundingPlace />
        </ToolbarLeft>
      </Toolbar>
    </LogContextProvider>
  );
};
export default GlobalBottomPanel;
