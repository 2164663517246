import React, { useContext, useMemo, FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import OAuthCallback from '../OAuthCallback';
import { OAuthBehaviour } from '../index';

type OAuthContextType = {
  redirectUrl: string;
};

export const OAuthContext = React.createContext<OAuthContextType>({
  redirectUrl: '/oauth/callback',
});

export function useOAuthBehaviour(): OAuthBehaviour {
  const context = useContext(OAuthContext);
  return useMemo(() => new OAuthBehaviour(context.redirectUrl), [context]);
}

const CallbackWrapper: FC = ({ children }) => {
  const context = useContext(OAuthContext);
  return (
    <Switch>
      <Route exact path={context.redirectUrl} component={OAuthCallback} />;
      <Route render={() => children} />
    </Switch>
  );
};

interface OAuthProviderInterface {
  children: React.ReactNode;
}
const OAuthProvider = function ({ children }: OAuthProviderInterface) {
  return (
    <OAuthContext.Provider
      value={{
        redirectUrl: '/oauth/callback',
      }}
    >
      <CallbackWrapper>{children}</CallbackWrapper>
    </OAuthContext.Provider>
  );
};

export default React.memo(OAuthProvider);
