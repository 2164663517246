export const getRandomString = (length = 3) => String(Math.random()).slice(2, 2 + length);
export const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);
export const uniquify = (arr) => Array.from(new Set(arr));
export const downloadTextFileWithContent = (fileContent, fileName) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
