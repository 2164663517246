import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Icon, InputText } from '@just-ai/just-ui';
import { chain } from 'lodash';

import { t, tWithCheck } from 'localization';

import { useRightSideMenuContext } from '../../index';
import RSAddingMenuClasses from '../RSAddingMenu.module.scss';
import { AddingType } from './index';
import EventActivationEdit from './EventActivationEdit';
import classes from './Activations.module.scss';

function getEventTitle(name: string): string {
  switch (name) {
    case 'Custom event':
      return t(`EventActivationItem:title_${name}`);
    default:
      return name;
  }
}

const CustomEventGroup = {
  name: 'Custom events',
  events: ['Custom event'],
};

export const EventActivationsGroups = [
  {
    name: 'Customer engagement platforms',
    events: ['livechatFinished', 'noLivechatOperatorsOnline'],
  },
  {
    name: 'Telephony',
    events: [
      'hangup',
      'transfer',
      'botHangup',
      'noDtmfAnswerEvent',
      'speechNotRecognized',
      'onCallNotConnected',
      'bargeInIntent',
    ],
  },
  {
    name: 'Facebook',
    events: ['facebookSendLocation', 'rawRequestEvent'],
  },
  {
    name: 'Telegram',
    events: [
      'telegramSendContact',
      'telegramSendLocation',
      'telegramApiRequestFailed',
      'telegramAnyMessage',
      'telegramCallbackQuery',
      'telegramAnyRequest',
    ],
  },
  {
    name: 'Bitrix24',
    events: [
      'ONAPPINSTALL',
      'ONAPPUPDATE',
      'ONIMBOTMESSAGEADD',
      'ONIMBOTMESSAGEUPDATE',
      'ONIMBOTMESSAGEDELETE',
      'ONIMCOMMANDADD',
      'ONIMJOINCHAT',
      'ONIMBOTDELETE',
      'CRM_SUCCESS_EVENT',
      'CRM_FAILED_EVENT',
    ],
  },
  {
    name: 'Viber',
    events: ['viberSendLocation', 'viberSendSticker', 'viberSendContact', 'viberUnsubscribe', 'conversationStarted'],
  },
  {
    name: 'WeChat',
    events: ['weChatSendLocation', 'weChatAnyMessage'],
  },
  {
    name: 'Webim (External Bot API 2.0)',
    events: ['noLivechatOperatorsOnline'],
  },
  {
    name: 'edna WhatsApp',
    events: ['locationEvent'],
  },
  {
    name: 'System events',
    events: [
      'tariffBlocked',
      'sessionDataSoftLimitExceeded',
      'clientDataSoftLimitExceeded',
      'sessionDataHardLimitExceeded',
      'clientDataHardLimitExceeded',
      'smsFailedEvent',
      'smsSuccessEvent',
    ],
  },
  {
    name: 'STS classifier events',
    events: ['match', 'noMatch', 'timeLimit', 'lengthLimit'],
  },
];

const AllEventActivationsGroups = [CustomEventGroup].concat(EventActivationsGroups);

export const allUniqEvents = chain(EventActivationsGroups)
  .flatMap(eventGroup => eventGroup.events)
  .uniq()
  .value();

const EventActivationsGroupsObj: Record<string, string[]> = {};
AllEventActivationsGroups.forEach(eventGroup => {
  EventActivationsGroupsObj[eventGroup.name] = eventGroup.events;
});

type StateGroupEvent = { name: string; description: string; event: string };
type StateGroup = { name: string; events: StateGroupEvent[] };

export const getAllActions = (groups: { name: string; events: string[] }[] = AllEventActivationsGroups) =>
  groups.reduce((prevActions, currActions) => {
    const pushedGroup = {
      name: t(`EventActivationItem:title_${currActions.name.replace(/\W/g, '_')}`),
      events: [] as { name: string; description: string; event: string }[],
    };
    currActions.events.forEach(action => {
      pushedGroup.events.push({
        name: t(`EventActivationItem:title_${currActions.name.replace(/\W/g, '_')}_${action}`),
        description:
          tWithCheck(`EventActivationItem:description_${currActions.name.replace(/\W/g, '_')}_${action}`) || action,
        event: action,
      });
    });
    prevActions.push(pushedGroup);
    return prevActions;
  }, [] as StateGroup[]);

export const EventActivation: FC = () => {
  const { selectActivationEvent, eventsState, changeEventParams } = useRightSideMenuContext();
  const { activationEvent, isGlobal } = eventsState;
  const [filter, setFilter] = useState('');

  const eventActivations = useMemo(() => getAllActions(), []);

  const filterValues = useMemo(() => {
    let newGroups: StateGroup[] = [];
    const lowerCaseFilterTest = filter.toLowerCase();
    eventActivations.forEach(group => {
      let newGroupEvents = group.events.filter(
        event =>
          event.name.toLowerCase().includes(lowerCaseFilterTest) ||
          event.description.toLowerCase().includes(lowerCaseFilterTest)
      );
      if (newGroupEvents.length > 0) {
        newGroups.push({
          name: group.name,
          events: newGroupEvents,
        });
      }
    });
    return newGroups;
  }, [eventActivations, filter]);

  const isFromState = eventsState.addingMenuType !== 'toStateEvents';
  const isToState = eventsState.addingMenuType === 'toStateEvents';

  if (activationEvent)
    return (
      <EventActivationEdit
        isFromState={isFromState}
        isToState={isToState}
        activationEvent={activationEvent}
        onChange={selectActivationEvent}
        isGlobal={isGlobal}
        onChangeIsGlobal={value =>
          changeEventParams({
            isGlobal: value,
            onlyThisState: eventsState.onlyThisState,
            fromStateValue: eventsState.fromStateValue,
            toStateValue: eventsState.toStateValue,
          })
        }
        onlyThisState={eventsState.onlyThisState}
        onChangeOnlyThisState={value =>
          changeEventParams({
            isGlobal: eventsState.isGlobal,
            onlyThisState: value,
            fromStateValue: eventsState.fromStateValue,
            toStateValue: eventsState.toStateValue,
          })
        }
        fromStateValue={eventsState.fromStateValue}
        onChangeFromStateValue={value =>
          changeEventParams({
            isGlobal: eventsState.isGlobal,
            onlyThisState: eventsState.onlyThisState,
            fromStateValue: isFromState ? value : '',
            toStateValue: isToState ? value : '',
          })
        }
        toStateValue={eventsState.toStateValue}
      />
    );

  return (
    <div className={classes.EventActivation}>
      <div className={RSAddingMenuClasses.RSAddingMenuListHeaderHint}>
        {t('RSAddingMenu:Activations:ListHeaderHint')}
      </div>
      <div className={classNames(RSAddingMenuClasses.RSAddingMenuSearchBar, classes.SearchBar)}>
        <Icon name='farSearch' wrapperClassName={RSAddingMenuClasses.RSAddingMenuSearchInputIcon} />
        <InputText
          placeholder={t('RSAddingMenu:search-placeholder')}
          onChange={setFilter}
          value={filter}
          clearable={filter.length > 0}
        />
      </div>
      {(filter ? filterValues : eventActivations).map(eventsGroup => (
        <>
          <div className={classes.GroupTitle}>{eventsGroup.name}</div>
          {eventsGroup.events.map(event => (
            <AddingType
              key={event.event}
              testId={event.event}
              title={event.name}
              description={event.description}
              onClick={() => selectActivationEvent(event.event)}
            />
          ))}
        </>
      ))}
    </div>
  );
};
