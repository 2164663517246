import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import cn from 'classnames';
import { TreeContext, TREE_DEPTH_PADDING, TREE_NODE_PADDING } from '../../index';
import { DragTypes } from '../../../../../FAQTreePage/DragTypes';
import styles from '../../styles.module.scss';
const Leaf = function ({ node, isParentSelected, className, style, categoryName, isSearchActive, isDndEnabled, }) {
    var _a, _b, _c;
    const context = useContext(TreeContext);
    const isSelected = useMemo(() => context.selectedIds.includes(node.id), [context.selectedIds, node.id]);
    const isActive = useMemo(() => context.activeId && context.activeId === node.id, [context.activeId, node.id]);
    const onContextMenu = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        context.handleContextMenu(event, node);
    }, [context, node]);
    const [{ isDragging }, drag] = useDrag({
        item: { node, type: DragTypes.LEAF },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !isSearchActive,
    });
    const [{ isOver }, drop] = useDrop({
        canDrop: (item) => item.node !== node || isDndEnabled,
        accept: DragTypes.LEAF,
        drop: (item) => {
            isDndEnabled && context.onDragDrop(item.node, node);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    });
    const { isDraggingOtherElement, draggingElement } = useDragLayer(monitor => ({
        isDraggingOtherElement: monitor.isDragging(),
        draggingElement: monitor.getItem(),
    }));
    const depthLevel = (_a = node.depthLevel) !== null && _a !== void 0 ? _a : 0;
    return (_jsx("div", { ref: drop, style: style, className: cn({
            [styles.node__dragging]: isDragging,
        }), onContextMenu: onContextMenu, children: _jsx("div", { ref: drag, className: cn(styles.node, className, {
                [styles.node__selected]: isSelected,
                [styles.node__secondary_selected]: isParentSelected,
                [styles.node__active]: isActive,
                [styles.node__dragging]: isDragging,
                [styles.node__dragged]: ((_b = draggingElement === null || draggingElement === void 0 ? void 0 : draggingElement.node) === null || _b === void 0 ? void 0 : _b.id) === (node === null || node === void 0 ? void 0 : node.id),
                [styles.node__elementDisabled]: !node.enabled,
                [styles.disableHover]: isDndEnabled && isDraggingOtherElement && ((_c = draggingElement === null || draggingElement === void 0 ? void 0 : draggingElement.node) === null || _c === void 0 ? void 0 : _c.id) !== (node === null || node === void 0 ? void 0 : node.id),
                [styles.node__isOver]: isOver,
            }), style: {
                paddingLeft: depthLevel ? depthLevel * TREE_DEPTH_PADDING + TREE_NODE_PADDING : '',
            }, onClick: () => context.onSelectToggle(node), "data-test-id": `FAQ.Tree.Leaf.${node.name}`, children: categoryName ? (_jsxs("div", { className: styles.node__category, children: [_jsx("span", { children: categoryName }), _jsx("span", { className: styles.text, children: `/ ${node.name}` })] })) : (_jsx("span", { className: styles.text, children: node.name })) }) }));
};
export default React.memo(Leaf);
