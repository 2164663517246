import React, { useMemo } from 'react';
import { CustomRxTooltip, useId } from '@just-ai/just-ui';

import { t } from 'localization';

import insertJSXMatchesToAdjustedText from 'utils/text/insertJSXMatchesToAdjustedText';

import { SearchResult } from 'modules/JGraph/services/JGSearch';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { getThemeName } from 'modules/JGraph/utils/themesUtils';

import { searchResultTypes } from './searchResultTypes';

import styles from './style.module.scss';

type SearchResultViewProps = {
  searchResult: SearchResult;
  dataTestId?: string;
};

export const SearchResultRow = ({
  searchResult,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & SearchResultViewProps) => {
  const relativePath = hideRootSlashInPath(
    searchResult.location?.path.substring(searchResult.location.theme.length) || ''
  );

  const result = useMemo(() => {
    if (searchResult.label) return searchResult.label;
    if (searchResult.matches.length === 0) return searchResult.content;
    return insertJSXMatchesToAdjustedText(
      searchResult.content,
      searchResult.matches,
      match => <span className={styles.searchMatch}>{match.text}</span>,
      { charCountInLine: 70 }
    );
  }, [searchResult]);
  const tooltipId = useId();

  const searchResultTypeInfo = searchResultTypes[searchResult.type];
  return (
    <div className={styles.searchOption} tabIndex={0} role='button' {...props}>
      {searchResultTypeInfo && (
        <>
          <div id={tooltipId} className={styles.searchOption__icon}>
            {searchResultTypeInfo.icon || ''}
          </div>
          <CustomRxTooltip target={`#${tooltipId}`} placement='top' textAlign='left' debounceTime={800}>
            {t(`JGGlobalSearch:IconHint:${searchResult.type}`)}
          </CustomRxTooltip>
        </>
      )}

      <div className={styles.searchOption__content}>
        <div className={styles.text}>{result}</div>
        {searchResult.location && (
          <div className={styles.searchOption__location} title={searchResult.location.path}>
            <span className={styles.theme}>
              {getThemeName(searchResult.location.theme)}
              {relativePath ? '/' : ''}
            </span>
            {relativePath && <span className={styles.path}>{relativePath}</span>}
          </div>
        )}
      </div>
    </div>
  );
};
