import React, { createContext, useContext } from 'react';
import { WSContext, WSContextType } from './WSContext';
import { NotificationData } from '../utils/notificaions';
import { getNlpErrorFromOutput } from '../utils/tasks';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import localize from '../../../localization/index';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import NotificationLog from '../components/Notifications/NotificationLog';

export const WS_NOTIFICATIONS_PATH = '/user/topic/notifications';
export const NOTIFICATIONS_PATH = '/app/notifications';
export const MARK_SEEN_PATH = '/app/notifications/markSeen';
export const NOTIFICATIONS_LIMIT = 15;

const IGNORE_ALERTS = ['editorbe.deploy.bot.finished'];

const LOG_ALERTS = ['editorbe.deploy.bot.failed', 'editorbe.deploy.bot.test_failed'];

const IGNORE_NOTIFICATIONS = ['editorbe.deploy.bot', 'editorbe.deploy.bot.finished'];

export class NotificationsContextType {
  notifications: any[] = [];
  setNotifications: (notifications: any[]) => unknown = () => {};
  wsContext: any = {};
}

export const NotificationsContext = createContext(new NotificationsContextType());

interface NotificationsProviderProps {
  addMessage: (message: AlertNotificationItemProps) => void;
  loadChannels: (projectShortName: string) => void;
  projectShortName: string;
}
interface NotificationsProviderState {
  notifications: NotificationData[];
}

export const Notifications_RemoteChangesStore$ = new BehaviorSubject<NotificationData[]>([]);
export const Notifications_RemoteChangesRemove$ = new Subject();

export class NotificationsProvider extends React.Component<NotificationsProviderProps, NotificationsProviderState> {
  static contextType = WSContext;
  context!: WSContextType;

  subscribeId = '';

  state = {
    notifications: [],
  };

  rxNotificationRemoteChangesSub: Subscription = Notifications_RemoteChangesStore$.subscribe();
  rxNotificationRemoveSub: Subscription = Notifications_RemoteChangesRemove$.subscribe(() => {
    this.setState(prevState => {
      return {
        notifications: prevState.notifications.filter(
          notification => !notification?.message?.code?.code?.includes('editorbe.projects.remote_changes_found')
        ),
      };
    });
  });

  setNotifications = (newNotifications: any[]) => this.setState({ notifications: newNotifications });

  subscriber = (body: any) => {
    const prevNotifications = this.state.notifications || [];
    if (Array.isArray(body)) {
      //одно или несколько уведомлений
      const hoveredNotifications = body
        .filter(n => !IGNORE_NOTIFICATIONS.includes(n.message.code.code))
        .map(notification => {
          notification.hovered = true;
          const formattedOutput = notification.message?.data?.output?.includes('caila.')
            ? getNlpErrorFromOutput(notification.message?.data?.output)
            : notification.message?.data?.output;
          if (notification.message?.data?.output && formattedOutput) {
            notification.message.data.output = formattedOutput;
          }
          if (notification.message.code.code.includes('editorbe.projects.remote_changes_found')) {
            this.props.loadChannels(this.props.projectShortName);
            Notifications_RemoteChangesStore$.next([notification]);
          }
          if (!IGNORE_ALERTS.includes(notification.message.code.code)) {
            if (!notification.projectId || notification.projectId === this.props.projectShortName) {
              const message = localize.checkExistKey(`notifications body ${notification.message.code.code}`)
                ? notification.projectId +
                  ' ' +
                  localize.translate(`notifications body ${notification.message.code.code}`)
                : `${notification.projectId} \n ${formattedOutput || ''} ${notification.message.code.text || ''}`;
              this.props.addMessage({
                type: notification.message?.code?.type.replace('warn', 'warning') || 'info',
                title: localize.translate(`notifications ${notification.message.code.code}`),
                message,
                time: Date.now(),
                showed: true,
                modalBodyComponent: LOG_ALERTS.includes(notification.message?.code?.code) ? NotificationLog : undefined,
              });
            }
          }
          return notification;
        });
      const notifications = hoveredNotifications.concat(prevNotifications);
      this.setState({ notifications });
    } else if (body && body.records) {
      //первоначальная пачка уведомлений
      const filteredNotifications = body.records.filter(
        (notification: NotificationData) =>
          !notification.seen && !IGNORE_NOTIFICATIONS.includes(String(notification?.message?.code?.code))
      );
      const newNotifications = prevNotifications.concat(
        filteredNotifications.map(this.processNotificationForCailaError)
      );
      this.setState({ notifications: newNotifications });
    }
  };

  processNotificationForCailaError = (notification: NotificationData) => {
    if (
      !notification.message?.data?.output ||
      notification.message?.code?.type !== 'error' ||
      !String(notification.message?.data?.output).includes('caila.')
    ) {
      return notification;
    }

    notification.message.data.output = getNlpErrorFromOutput(notification.message?.data?.output);
    return notification;
  };

  componentDidMount() {
    this.subscribeId = this.context.subscribe(WS_NOTIFICATIONS_PATH, this.subscriber);
    this.context.send(NOTIFICATIONS_PATH, { size: NOTIFICATIONS_LIMIT });
  }

  componentWillUnmount() {
    if (this.subscribeId) this.context.unsubscribe(WS_NOTIFICATIONS_PATH, this.subscribeId);
    Notifications_RemoteChangesStore$.next([]);
    this.rxNotificationRemoteChangesSub.unsubscribe();
    this.rxNotificationRemoveSub.unsubscribe();
  }

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          notifications: this.state.notifications,
          setNotifications: this.setNotifications,
          wsContext: this.context,
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

export const useNotifications = () => useContext(NotificationsContext);
