// tslint:disable
// @ts-nocheck
/**
 * BotScorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 * @export
 * @enum {string}
 */
export var TypeEnum;
(function (TypeEnum) {
    TypeEnum["OK"] = "OK";
    TypeEnum["DEPLOYFAILED"] = "DEPLOY_FAILED";
    TypeEnum["ERROR"] = "ERROR";
})(TypeEnum || (TypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
(function (TypeEnum) {
    TypeEnum["READY"] = "READY";
    TypeEnum["RUNNING"] = "RUNNING";
})(TypeEnum || (TypeEnum = {}));
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/api/bot-scorer/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter["Z-requestId"] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
/**
 * TestSuiteApi - axios parameter creator
 * @export
 */
export const TestSuiteApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create new Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} name Test Suite name.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestSuite(accountId, projectId, name, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createTestSuite.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling createTestSuite.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling createTestSuite.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete TestSuite and results.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestSuite(accountId, projectId, checksum, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteTestSuite.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteTestSuite.");
            }
            // verify required parameter 'checksum' is not null or undefined
            if (checksum === null || checksum === undefined) {
                throw new RequiredError("checksum", "Required parameter checksum was null or undefined when calling deleteTestSuite.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite/{checksum}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"checksum"}}`, encodeURIComponent(String(checksum)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all test suites for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuite(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTestSuite.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTestSuite.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get test suite in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuiteFile(accountId, projectId, checksum, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTestSuiteFile.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTestSuiteFile.");
            }
            // verify required parameter 'checksum' is not null or undefined
            if (checksum === null || checksum === undefined) {
                throw new RequiredError("checksum", "Required parameter checksum was null or undefined when calling getTestSuiteFile.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite/{checksum}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"checksum"}}`, encodeURIComponent(String(checksum)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Rename Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {string} name Test Suite name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameTestSuite(accountId, projectId, checksum, name, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling renameTestSuite.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling renameTestSuite.");
            }
            // verify required parameter 'checksum' is not null or undefined
            if (checksum === null || checksum === undefined) {
                throw new RequiredError("checksum", "Required parameter checksum was null or undefined when calling renameTestSuite.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling renameTestSuite.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite/{checksum}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"checksum"}}`, encodeURIComponent(String(checksum)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TestSuiteApi - functional programming interface
 * @export
 */
export const TestSuiteApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create new Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} name Test Suite name.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestSuite(accountId, projectId, name, file, options) {
            const localVarAxiosArgs = TestSuiteApiAxiosParamCreator(configuration).createTestSuite(accountId, projectId, name, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete TestSuite and results.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestSuite(accountId, projectId, checksum, options) {
            const localVarAxiosArgs = TestSuiteApiAxiosParamCreator(configuration).deleteTestSuite(accountId, projectId, checksum, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all test suites for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuite(accountId, projectId, options) {
            const localVarAxiosArgs = TestSuiteApiAxiosParamCreator(configuration).getTestSuite(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get test suite in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuiteFile(accountId, projectId, checksum, options) {
            const localVarAxiosArgs = TestSuiteApiAxiosParamCreator(configuration).getTestSuiteFile(accountId, projectId, checksum, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Rename Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {string} name Test Suite name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameTestSuite(accountId, projectId, checksum, name, options) {
            const localVarAxiosArgs = TestSuiteApiAxiosParamCreator(configuration).renameTestSuite(accountId, projectId, checksum, name, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TestSuiteApi - factory interface
 * @export
 */
export const TestSuiteApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create new Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} name Test Suite name.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestSuite(accountId, projectId, name, file, options) {
            return TestSuiteApiFp(configuration).createTestSuite(accountId, projectId, name, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete TestSuite and results.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestSuite(accountId, projectId, checksum, options) {
            return TestSuiteApiFp(configuration).deleteTestSuite(accountId, projectId, checksum, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all test suites for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuite(accountId, projectId, options) {
            return TestSuiteApiFp(configuration).getTestSuite(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get test suite in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestSuiteFile(accountId, projectId, checksum, options) {
            return TestSuiteApiFp(configuration).getTestSuiteFile(accountId, projectId, checksum, options)(axios, basePath);
        },
        /**
         *
         * @summary Rename Test Suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {string} name Test Suite name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameTestSuite(accountId, projectId, checksum, name, options) {
            return TestSuiteApiFp(configuration).renameTestSuite(accountId, projectId, checksum, name, options)(axios, basePath);
        },
    };
};
/**
 * TestSuiteApi - object-oriented interface
 * @export
 * @class TestSuiteApi
 * @extends {BaseAPI}
 */
export class TestSuiteApi extends BaseAPI {
    /**
     *
     * @summary Create new Test Suite.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {string} name Test Suite name.
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteApi
     */
    createTestSuite(accountId, projectId, name, file, options) {
        return TestSuiteApiFp(this.configuration).createTestSuite(accountId, projectId, name, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete TestSuite and results.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {string} checksum Test Suite checksum.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteApi
     */
    deleteTestSuite(accountId, projectId, checksum, options) {
        return TestSuiteApiFp(this.configuration).deleteTestSuite(accountId, projectId, checksum, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get all test suites for project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteApi
     */
    getTestSuite(accountId, projectId, options) {
        return TestSuiteApiFp(this.configuration).getTestSuite(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get test suite in csv file format.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {string} checksum Test Suite checksum.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteApi
     */
    getTestSuiteFile(accountId, projectId, checksum, options) {
        return TestSuiteApiFp(this.configuration).getTestSuiteFile(accountId, projectId, checksum, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Rename Test Suite.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {string} checksum Test Suite checksum.
     * @param {string} name Test Suite name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteApi
     */
    renameTestSuite(accountId, projectId, checksum, name, options) {
        return TestSuiteApiFp(this.configuration).renameTestSuite(accountId, projectId, checksum, name, options)(this.axios, this.basePath);
    }
}
/**
 * TestSuiteRunApi - axios parameter creator
 * @export
 */
export const TestSuiteRunApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get test run result in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {number} id id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestRunResult(accountId, projectId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTestRunResult.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTestRunResult.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getTestRunResult.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-run/{id}/export`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Running test suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestSuite(accountId, projectId, checksum, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling runTestSuite.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling runTestSuite.");
            }
            // verify required parameter 'checksum' is not null or undefined
            if (checksum === null || checksum === undefined) {
                throw new RequiredError("checksum", "Required parameter checksum was null or undefined when calling runTestSuite.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/project/{projectId}/test-suite/{checksum}/run`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"checksum"}}`, encodeURIComponent(String(checksum)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TestSuiteRunApi - functional programming interface
 * @export
 */
export const TestSuiteRunApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get test run result in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {number} id id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestRunResult(accountId, projectId, id, options) {
            const localVarAxiosArgs = TestSuiteRunApiAxiosParamCreator(configuration).getTestRunResult(accountId, projectId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Running test suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestSuite(accountId, projectId, checksum, options) {
            const localVarAxiosArgs = TestSuiteRunApiAxiosParamCreator(configuration).runTestSuite(accountId, projectId, checksum, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TestSuiteRunApi - factory interface
 * @export
 */
export const TestSuiteRunApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get test run result in csv file format.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {number} id id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestRunResult(accountId, projectId, id, options) {
            return TestSuiteRunApiFp(configuration).getTestRunResult(accountId, projectId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Running test suite.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here.
         * @param {string} checksum Test Suite checksum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestSuite(accountId, projectId, checksum, options) {
            return TestSuiteRunApiFp(configuration).runTestSuite(accountId, projectId, checksum, options)(axios, basePath);
        },
    };
};
/**
 * TestSuiteRunApi - object-oriented interface
 * @export
 * @class TestSuiteRunApi
 * @extends {BaseAPI}
 */
export class TestSuiteRunApi extends BaseAPI {
    /**
     *
     * @summary Get test run result in csv file format.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {number} id id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteRunApi
     */
    getTestRunResult(accountId, projectId, id, options) {
        return TestSuiteRunApiFp(this.configuration).getTestRunResult(accountId, projectId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Running test suite.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here.
     * @param {string} checksum Test Suite checksum.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteRunApi
     */
    runTestSuite(accountId, projectId, checksum, options) {
        return TestSuiteRunApiFp(this.configuration).runTestSuite(accountId, projectId, checksum, options)(this.axios, this.basePath);
    }
}
/**
 * TestSuiteTemplateApi - axios parameter creator
 * @export
 */
export const TestSuiteTemplateApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Download test suite template file
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling downloadTemplate.");
            }
            const localVarPath = `/api/bot-scorer/account/{accountId}/downloadTemplate`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TestSuiteTemplateApi - functional programming interface
 * @export
 */
export const TestSuiteTemplateApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Download test suite template file
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate(accountId, options) {
            const localVarAxiosArgs = TestSuiteTemplateApiAxiosParamCreator(configuration).downloadTemplate(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TestSuiteTemplateApi - factory interface
 * @export
 */
export const TestSuiteTemplateApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Download test suite template file
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate(accountId, options) {
            return TestSuiteTemplateApiFp(configuration).downloadTemplate(accountId, options)(axios, basePath);
        },
    };
};
/**
 * TestSuiteTemplateApi - object-oriented interface
 * @export
 * @class TestSuiteTemplateApi
 * @extends {BaseAPI}
 */
export class TestSuiteTemplateApi extends BaseAPI {
    /**
     *
     * @summary Download test suite template file
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSuiteTemplateApi
     */
    downloadTemplate(accountId, options) {
        return TestSuiteTemplateApiFp(this.configuration).downloadTemplate(accountId, options)(this.axios, this.basePath);
    }
}
