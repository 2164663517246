import { axios } from 'pipes/functions';
import { IntentData, IntentsApi, IntentSummaryData, TrainingApi } from '../api/client';
import { AccountEndpointApi, ModelEndpointApi, ProcessEndpointApi, ProcessServletApi } from '../CailagateApi/client';
import { Intents } from '../utils';
import isAccess, { isSystemAccess } from '../../../isAccessFunction';

export type ParaphraserResponse = {
  data: {
    texts_list: {
      values: string[];
      similarity_scores: null;
      perplexity_scores: null;
    }[];
  };
};
export default class IntentsService {
  static readonly BASE_PATH = '/cailapub';
  static readonly BASE_PATH_CAILA_2 = '';
  private accountId: number;
  private projectId: string;
  private intentsApi: IntentsApi;
  private trainingApi: TrainingApi;
  private caila2AccountApi: AccountEndpointApi;
  private caila2ModelApi: ModelEndpointApi;
  private caila2ProcessApi: ProcessEndpointApi;
  private caila2ServletApi: ProcessServletApi;

  public loading: boolean = true;
  public intents: IntentSummaryData[] = [];
  public SystemIntents = {
    RU: [
      '/sys/aimylogic/ru/agreement',
      '/sys/aimylogic/ru/negation',
      '/sys/aimylogic/ru/hello',
      '/sys/aimylogic/ru/parting',
      '/sys/aimylogic/ru/thanks',
      '/sys/aimylogic/ru/uncertainty',
      '/sys/aimylogic/ru/insults',
      '/sys/aimylogic/ru/approval',
      '/sys/aimylogic/ru/negative',
      '/sys/aimylogic/ru/normal',
      '/sys/aimylogic/ru/gender',
      '/sys/aimylogic/ru/phone',
      '/sys/aimylogic/ru/datetime',
      '/sys/aimylogic/ru/city',
      '/sys/aimylogic/ru/capital',
      '/sys/aimylogic/ru/country',
      '/sys/aimylogic/ru/name',
      '/sys/aimylogic/ru/email',
      '/sys/aimylogic/ru/switch',
    ],
    EN: [
      '/sys/aimylogic/en/agreement',
      '/sys/aimylogic/en/negation',
      '/sys/aimylogic/en/phone',
      '/sys/aimylogic/en/datetime',

      '/sys/aimylogic/en/hello',
      '/sys/aimylogic/en/parting',
      '/sys/aimylogic/en/thanks',
      '/sys/aimylogic/en/uncertainty',

      '/sys/aimylogic/en/email',
      '/sys/aimylogic/en/name',
      '/sys/aimylogic/en/gender',

      '/sys/aimylogic/en/city',
      '/sys/aimylogic/en/country',
      '/sys/aimylogic/en/capital',

      '/sys/aimylogic/en/insults',
      '/sys/aimylogic/en/approval',
      '/sys/aimylogic/en/negative',
    ],
  };

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
    this.intentsApi = new IntentsApi({}, IntentsService.BASE_PATH, axios);
    this.trainingApi = new TrainingApi({}, IntentsService.BASE_PATH, axios);
    this.listIntents().then(({ data }) => {
      this.loading = false;
      this.intents = data;
    });

    this.caila2AccountApi = new AccountEndpointApi({}, IntentsService.BASE_PATH_CAILA_2, axios);
    this.caila2ModelApi = new ModelEndpointApi({}, IntentsService.BASE_PATH_CAILA_2, axios);
    this.caila2ProcessApi = new ProcessEndpointApi({}, IntentsService.BASE_PATH_CAILA_2, axios);
    this.caila2ServletApi = new ProcessServletApi({}, IntentsService.BASE_PATH_CAILA_2, axios);
  }

  getCailagateAccount = async () => {
    const { data: account } = await this.caila2AccountApi.ensureAccount();
    return account;
  };

  getCailagateModel = async (projectLang: string, caila2AccountId: number) => {
    const modelName = projectLang === 'ru' ? 'jaicp-paraphraser-ru' : 'jaicp-paraphraser-en';
    const { data: model } = await this.caila2ModelApi.getPagedModels(caila2AccountId, undefined, 'just-ai', modelName);
    return model;
  };

  getIntentNamesAndGroups = async () => {
    const { data: tags } = await this.intentsApi.listIntents(this.accountId, this.projectId);
    const IntentsGroups = Object.values(Intents.mapIntentsToTree(tags))
      .filter(intentData => intentData.enabled && intentData.children.length > 0)
      .map(intentData => intentData.path);
    return {
      intentNames: tags.map(intent => String(intent.path)),
      intentGroups: IntentsGroups,
    };
  };

  getParaphrasedItems = async (newItemText: string, projectLang: string = 'eng') => {
    const modelName = projectLang === 'ru' ? 'caila-paraphraser-ru' : 'caila-paraphraser-en';
    const dataBlock = { texts: [newItemText] };
    const { data: paraphraserResponse } = await this.caila2ServletApi.predictByName('just-ai', modelName, dataBlock);

    return paraphraserResponse as ParaphraserResponse['data'];
  };
  getNewUniquePathForTopLevelIntent = (base: string) => {
    let intentName = base;
    let iterator = 1;
    const intentsNames = this.intents
      .map(intent => {
        let splittedPath = intent.path?.split('/');
        return splittedPath?.[1];
      })
      .filter(Boolean);
    while (intentsNames.includes(intentName)) {
      intentName = `${base}_${iterator}`;
      iterator++;
    }
    return `/${intentName}`;
  };
  createTopLevelIntentFromText = async (text: string) => {
    const IntentToAdd = this.getNewUniquePathForTopLevelIntent(text);
    return this.intentsApi
      .createIntent(this.accountId, this.projectId, {
        path: IntentToAdd,
        phrases: [{ text: text }],
      })
      .then(({ data }) => {
        this.intents.push({
          id: data.id,
          path: IntentToAdd,
          enabled: true,
          itemsCount: (data.phrases?.length || 0) + (data.patterns?.length || 0),
        });
        return data;
      });
  };
  createTopLevelIntentFromTextWithoutFirstPhrase = async (text: string) => {
    const IntentToAdd = this.getNewUniquePathForTopLevelIntent(text);
    return this.intentsApi
      .createIntent(this.accountId, this.projectId, {
        path: IntentToAdd,
        phrases: [],
      })
      .then(({ data }) => {
        this.intents.push({
          id: data.id,
          path: IntentToAdd,
          enabled: true,
          itemsCount: (data.phrases?.length || 0) + (data.patterns?.length || 0),
        });
        return data;
      });
  };
  saveIntentData = async (
    intentId: number,
    intentData: IntentData,
    logLabelingSessionId?: number,
    softValidate?: boolean,
    options?: any
  ) => {
    return this.intentsApi
      .recursiveUpdate(
        this.accountId,
        this.projectId,
        intentId,
        intentData,
        logLabelingSessionId,
        softValidate,
        options
      )
      .then(({ data }) => {
        const oldIntentIndex = this.intents.findIndex(intent => intent.id === intentId);
        this.intents.splice(oldIntentIndex, 1, {
          ...data.updatedIntent,
          itemsCount: (data.updatedIntent?.phrases?.length || 0) + (data.updatedIntent?.patterns?.length || 0),
        });
        return data;
      });
  };
  listIntents = async () => {
    if (!isAccess(['NLU_READ', 'nlu']) || !isSystemAccess(['botadmin_nlu_feature']))
      return Promise.resolve({ data: [] });
    if (!this.projectId || this.accountId === -1 || !Number.isInteger(this.accountId))
      return Promise.resolve({ data: [] });
    return this.intentsApi.listIntents(this.accountId, this.projectId).then(payload => {
      this.intents = payload.data;
      return payload;
    });
  };
  getIntent = async (intentId: number) => {
    return this.intentsApi.getIntent(this.accountId, this.projectId, intentId);
  };
  isSystemIntent = (language: 'RU', intentPath: string) => {
    return this.SystemIntents[language].includes(intentPath);
  };
  deleteIntents = async (requestBody: number[], logLabelingSessionId?: number) => {
    try {
      await this.intentsApi.deleteIntents(this.accountId, this.projectId, requestBody, logLabelingSessionId);
      this.intents = this.intents.filter(intent => !requestBody.includes(intent.id!));
    } catch (error) {
      throw error;
    }
  };
  trainNLU = () => {
    return this.trainingApi.trainNLU(this.accountId, this.projectId);
  };
}
