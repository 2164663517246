import React, { FC } from 'react';
import { Group, Text } from 'react-konva';
import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';
import { TransitionCircle } from './TransitionCircle';

import { getConnectorFrom } from '../../../../reducers/JGraph.reducer/Graph';

import { TJBlock } from 'modules/JGraph/utils/types';
import { useOnClickBlockDefault } from '../../hooks';
import { useRenderBlocksContext } from './RenderBlocks';

export interface ItemBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  text: string;
  screenBlock: TJBlock;
  globalJBlockIterator: number;
  placeholder?: string;
  maxHeight?: number;
}

export const TransitionBody: FC<ItemBodyProps> = ({ text, placeholder, globalJBlockIterator, ...rest }) => {
  const { baseWidth, prefix, screen } = useRenderBlocksContext();
  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);

  const textWidth = baseWidth - 24 /*main offset*/ - 24 /*icon with gap*/ - 24 /*circle + gap*/ - 12; /*right offset*/

  return (
    <BaseItemBody
      {...rest}
      onClick={onClickDefault}
      renderChild={({ innerHeight = 1 }) => (
        <Group y={34} x={16 /*leftIcon width*/ + 8 /*gap*/}>
          <Text
            verticalAlign='middle'
            key={`isTextShorten${innerHeight}`}
            fill={text.length > 0 ? '#333C45' : '#888E93'}
            text={placeholder || text || 'unknown'}
            height={innerHeight * (21 / 14) * 14}
            width={textWidth}
            lineHeight={21 / 14}
            fontSize={14}
            letterSpacing={0.02}
            ellipsis={true}
          />
          {/*<Rect fill='red' opacity={0.3} height={24} width={textWidth} onMouseEnter={e => console.log(e)} />*/}
          <TransitionCircle
            debugActive={rest.screenBlock.debugActive}
            x={textWidth + 6}
            y={10}
            tagName={rest.screenBlock.tagName}
            hasTransition={!!rest.screenBlock.tagValue}
            name={getConnectorFrom(globalJBlockIterator, screen.pathId, `${prefix}${rest.screenBlock.tagName}`)}
            transitionTo={rest.screenBlock.tagValue}
          />
        </Group>
      )}
    />
  );
};
