import React, { FunctionComponent } from 'react';
import { IconButton, Icon, IconProps } from '@just-ai/just-ui';
import classNames from 'classnames';

interface ColorsForType {
  [key: string]: string;
}
const colorsForType: ColorsForType = {
  error: 'notification-red',
  success: 'notification-blue',
  info: 'notification-green',
  warn: 'notification-orange',
};

interface IconsForType {
  [key: string]: IconProps['name'];
}
const iconsForType: IconsForType = {
  error: 'faExclamationCircle',
  success: 'faInfoCircle',
  info: 'faCheckSquare',
  warn: 'faExclamationTriangle',
};

interface NotificationProps {
  type: string;
  onDelete?: (e: React.MouseEvent) => void;
  id?: number;
  hovered?: boolean;
  onClick?: (e: React.MouseEvent) => unknown;
  onMouseLeave?: (e: React.MouseEvent) => unknown;
}
export const Notification: FunctionComponent<NotificationProps> = ({
  type,
  children,
  onDelete = () => {},
  hovered,
  onClick = () => {},
  onMouseLeave = () => {},
}) => {
  return (
    <div
      className={classNames(`notification ${colorsForType[type]}`, { seen: !hovered })}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      <div className='notification__icon'>
        <Icon name={iconsForType[type]} size='sm' />
      </div>
      <div className='notification__body'>{children}</div>
      <div className='notification__delete'>
        <IconButton name='farTrashAlt' onClick={onDelete} color='secondary' flat />
      </div>
    </div>
  );
};
