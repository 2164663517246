import axios from 'axios';
import { BotConfigsApi, CustomDataDto } from 'modules/Botadmin/api/client';

export default class BotConfigService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private service: BotConfigsApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.service = new BotConfigsApi({}, BotConfigService.BASE_PATH, axios);
  }

  async validateCustomDataForChannel(channelType: string, customDataDto: CustomDataDto) {
    const { data } = await this.service.validateCustomDataForChannel(this.accountId, channelType, customDataDto);
    return data;
  }
}
