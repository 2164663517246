import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { omit } from 'lodash';
import VirtualListWithDynamicHeight from '@just-ai/just-ui/dist/VirtualListWithDynamicHeight';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toIntentPhrase } from '../../../../../views/FAQTreePage/components/PhrasesBlock/utils';
import PhraseRow from '../PhraseRow';
import PhraseRowForInsertion from '../PhraseRow/PhraseRowForInsertion';
import styles from './styles.module.scss';
export const DEFAULT_PHRASE_HEIGHT = 38;
const DefaultItemForInsertion = {
    id: 'ForInsertion',
    text: '',
    type: IntentItemType.phrase,
};
const PhraseList = function ({ items, filteredItems, isSearching, onChange, writeDisabled }) {
    const [innerItems, setInnerItems] = useState(items);
    const [itemForInsertion, setItemForInsertion] = useState(DefaultItemForInsertion);
    useEffect(() => {
        setInnerItems(isSearching
            ? filteredItems
            : [itemForInsertion, ...items].map(el => omit(el, ['searchMatches'])));
    }, [filteredItems, isSearching, itemForInsertion, items]);
    const onRowChange = useCallback((item) => {
        if (item.id === 'ForInsertion') {
            setItemForInsertion(item);
            return;
        }
        const newItems = items.concat();
        const index = newItems.findIndex(el => el.id === item.id);
        newItems[index] = item;
        onChange(newItems);
    }, [onChange, items]);
    const onDelete = useCallback((item) => {
        const newItems = items.concat();
        const index = newItems.findIndex(el => el.id === item.id);
        newItems.splice(index, 1);
        onChange(newItems);
    }, [items, onChange]);
    const onSubmit = useCallback((item) => {
        var _a, _b;
        const index = innerItems.findIndex(el => el.id === item.id);
        if (index === -1)
            return;
        const nextElement = innerItems[index + 1];
        if (!nextElement) {
            (_a = document.getElementById(`PhraseRow-${item.id}`)) === null || _a === void 0 ? void 0 : _a.blur();
            return;
        }
        (_b = document.getElementById(`PhraseRow-${nextElement.id}`)) === null || _b === void 0 ? void 0 : _b.focus();
    }, [innerItems]);
    const onInsert = useCallback(() => {
        var _a;
        if (itemForInsertion.text === '')
            return;
        const newItems = items.concat();
        const newIntentItem = toIntentPhrase(itemForInsertion.text, itemForInsertion.type);
        onChange([newIntentItem, ...newItems]);
        setItemForInsertion(Object.assign(Object.assign({}, DefaultItemForInsertion), { type: itemForInsertion.type }));
        (_a = document.getElementById(`PhraseRow-${DefaultItemForInsertion.id}`)) === null || _a === void 0 ? void 0 : _a.focus();
    }, [itemForInsertion, items, onChange]);
    return (_jsx(VirtualListWithDynamicHeight, { itemCount: innerItems.length, containerMaxHeight: 440, initialContainerHeight: 200, paddingsHeight: 4, className: cn('just-ui-custom-scrollbar just-ui-custom-scrollbar_pink with-gutter question-phrases-list', styles.phraseList), itemId: index => innerItems[index].id, defaultElementHeight: DEFAULT_PHRASE_HEIGHT, withItemsOffset: true, itemRender: index => {
            const item = innerItems[index];
            return item.id === 'ForInsertion' ? (_jsx(PhraseRowForInsertion, { index: index, item: item, onChange: onRowChange, onInsert: onInsert, writeDisabled: writeDisabled && !item.error })) : (_jsx(PhraseRow, { index: index, item: item, onChange: onRowChange, onDelete: onDelete, onSubmit: onSubmit, writeDisabled: writeDisabled && !item.error }));
        } }));
};
export default React.memo(PhraseList);
