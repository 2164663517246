import Konva from 'konva';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Group, Text } from 'react-konva';
import { textParams } from '.';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import { EditMenuBlock, JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { GroupStateItemTransitionCircle } from './GroupStateItemTransitionCircle';
import { Subject } from 'rxjs';
import { ConnectorStore } from '../../contexts/types';
import {
  getStageContextFromRef,
  getStageFromEvent,
  getStageFromRef,
  scrollToTargetGlobal$,
  setCursorOnMouseEnterAndLeave,
} from '../../utils/stageUtils';
import { getAllStatePathIds, getAllStatePaths, getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { GroupOffsetProvider, PADDING_LEFT, useGroupOffset } from './context';
import { KonvaEventObject } from 'konva/lib/Node';
import { GroupStateItemOpenClose } from './GroupStateItemOpenClose';
import { useToggle } from '@just-ai/just-ui';
import { IconNames, KIcon } from '../parts/KIcons';
import { callBlocksRedraw } from '../../utils/blockLayerUtils';

export const GroupStateItem: FC<{
  state: JStateWithId;
  setEditMenuBlock: (screen: EditMenuBlock | undefined) => unknown;
}> = ({ state, setEditMenuBlock }) => {
  const StateRef = useRef<Konva.Group | null>(null);
  const { paddingLeft } = useGroupOffset();

  const [isOpen, , , toggle] = useToggle(state.states?.length === 0);

  const allSubStates = useMemo(() => {
    return getAllStatePaths(state);
  }, [state]);
  const allSubStatesPathIds = useMemo(() => {
    return getAllStatePathIds(state);
  }, [state]);

  useEffect(() => {
    let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;
    if (StateRef.current && state.path) {
      const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
      connectorsFromPipeSave$ = connectorsFromPipe$;
      connectorsFromPipe$.next({
        [getValidKonvaName(state.path)]: {
          toRef: StateRef.current,
        },
      });
      if (!isOpen) {
        const removeToRefs = allSubStates.reduce((acc, currentPath) => {
          acc[getValidKonvaName(currentPath)] = {
            toRef: StateRef.current,
          };
          return acc;
        }, {} as ConnectorStore);
        connectorsFromPipeSave$.next(removeToRefs);
      }
    }
    return () => {
      if (connectorsFromPipeSave$) {
        connectorsFromPipeSave$.next({
          [getValidKonvaName(state.path)]: {
            toRef: undefined,
          },
        });
      }
    };
  }, [state.path, isOpen, allSubStates]);

  let textWidth = 280 - 24 - 12 - 20 /*left Icon*/ - paddingLeft;
  if (textWidth < 50) {
    textWidth = 50;
  }

  const setTitle = useCallback(() => {
    const stage = getStageFromRef(StateRef);
    if (stage) {
      const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
      if (parent) {
        parent.title = state.path;
      }
    }
  }, [state.path]);

  const removeTitle = useCallback(() => {
    const stage = getStageFromRef(StateRef);
    if (stage) {
      const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
      if (parent) {
        parent.title = '';
      }
    }
  }, []);

  const goToState = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      setEditMenuBlock({ screen: state, path: undefined, jBlockIndex: undefined });
      scrollToTargetGlobal$.next({ targetPathId: state.pathId });
    },
    [setEditMenuBlock, state]
  );

  const toggleHandler = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      toggle();
      const stage = getStageFromEvent(event);
      callBlocksRedraw(StateRef.current, stage);
    },
    [toggle]
  );

  return (
    <>
      <AutopositionGroup yOffset={8} padding={8} isScreen={false} type='ChildState'>
        <Group
          ref={StateRef}
          name={state.pathId}
          onMouseEnter={setCursorOnMouseEnterAndLeave(setTitle)}
          onMouseLeave={setCursorOnMouseEnterAndLeave(removeTitle)}
          onClick={goToState}
          substates={allSubStatesPathIds}
          isCollapsed={!isOpen}
        >
          {state.states && state.states.length > 0 && (
            <GroupStateItemOpenClose isOpen={isOpen} toggle={toggleHandler} />
          )}
          {!state.states ||
            (state.states.length === 0 && (
              <KIcon width={16} height={16} x={paddingLeft - 2} icon={IconNames.nestedStateNoChildStates} />
            ))}
          <Text
            text={state.value}
            fill='#333C45'
            {...textParams}
            x={paddingLeft + 20}
            width={textWidth}
            ellipsis
            wrap='none'
          />
        </Group>
        <GroupStateItemTransitionCircle
          screen={state}
          showConnectionsFromChildren={!isOpen}
          substates={allSubStatesPathIds}
        />
      </AutopositionGroup>
      {state.states &&
        isOpen &&
        state.states.length > 0 &&
        state.states.map(innState => (
          <GroupOffsetProvider
            key={`GroupStateItem${innState.pathId}`}
            value={{
              paddingLeft: paddingLeft + PADDING_LEFT,
            }}
          >
            <GroupStateItem state={innState} setEditMenuBlock={setEditMenuBlock} />
          </GroupOffsetProvider>
        ))}
    </>
  );
};
