import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Modal, useTranslation } from '@just-ai/just-ui';
import classes from './styles.module.scss';
const DeleteModal = ({ isOpen, close, handleDelete, deleteItem }) => {
    const { t } = useTranslation();
    const [modalContent, modalTitle] = useMemo(() => {
        var _a;
        const folderNodes = deleteItem === null || deleteItem === void 0 ? void 0 : deleteItem.filter(item => item === null || item === void 0 ? void 0 : item.isFolder);
        const groupCount = (folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.length) || 0;
        const groupsQuestionsCount = (folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.reduce((acc, node) => acc + node.children.length, 0)) || 0;
        const questionsCount = ((_a = deleteItem === null || deleteItem === void 0 ? void 0 : deleteItem.filter(item => !(item === null || item === void 0 ? void 0 : item.isFolder) && ((item === null || item === void 0 ? void 0 : item.isInRoot) || !(folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.find(folderNode => folderNode.path === item.parent))))) === null || _a === void 0 ? void 0 : _a.length) || 0;
        let messages = [];
        if (questionsCount === 1 && !groupCount) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteQuestion');
        }
        else if (questionsCount > 1 && !groupCount) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteQuestions');
            messages[1] = t('FAQ:deleteModal:Message:DeleteQuestionsSelected $[1]', questionsCount) + '.';
        }
        else if (!questionsCount && groupCount === 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroup');
            messages[1] = t('FAQ:deleteModal:Message:DeleteQuestionsInGroupsSelected $[1]', groupsQuestionsCount) + '.';
        }
        else if (!questionsCount && groupCount > 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroups');
            messages[1] = t('FAQ:deleteModal:Message:DeleteGroupsSelected $[1]', groupCount) + '.';
            messages[2] = t('FAQ:deleteModal:Message:DeleteQuestionsInGroupSelected $[1]', groupsQuestionsCount) + '.';
        }
        else if (questionsCount >= 1 && groupCount >= 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroupsAndQuestions');
            messages[1] = t('FAQ:deleteModal:Message:DeleteGroupsSelected $[1]', groupCount);
            messages[1] += ` (${t('FAQ:deleteModal:Message:DeleteQuestionsInGroupSelected $[1]', groupsQuestionsCount).toLowerCase()}).`;
            messages[2] = t('FAQ:deleteModal:Message:DeleteUngroupedQuestionsSelected $[1]', questionsCount) + '.';
        }
        else {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroupsAndQuestions');
        }
        const title = t(groupCount > 0 ? 'FAQ:deleteModal:Title:DeleteGroupsAndQuestions' : 'FAQ:deleteModal:Title:DeleteQuestions');
        return [messages, title];
    }, [deleteItem, t]);
    return (_jsx(_Fragment, { children: _jsx(Modal, { isOpen: isOpen, title: modalTitle, onCancelClick: close, onActionClick: handleDelete, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: t('FAQ:deleteModal:Button:Delete'), buttonSubmitColor: 'danger', centered: true, children: _jsx("div", { className: classes.deleteModal__content, children: modalContent.map((content, index) => (_jsx("div", { children: content }, index))) }) }) }));
};
export function DeleteSkillModal({ isOpen, close, handleDelete, deleteItem }) {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsx(Modal, { isOpen: isOpen, title: t('FAQ:DeleteSkill'), onCancelClick: close, onActionClick: handleDelete, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: t('FAQ:deleteModal:Button:Delete'), buttonSubmitColor: 'danger', centered: true, children: _jsx("div", { className: classes.deleteModal__content, children: t('FAQ:DeleteSkill:prompt', deleteItem) }) }) }));
}
export default React.memo(DeleteModal);
