import Konva from 'konva';
import { Block, BlockPosition, BlockPositionNN } from '../../../model/Block';
import { isNumber } from 'lodash';
import { Connection } from '../../../model/Graph';
import { Circle } from 'konva/lib/shapes/Circle';
import { Rect } from 'konva/lib/shapes/Rect';

const distance = (a: number[], b: number[]) => {
  return Math.sqrt((b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]));
};

const defaultZeroPosition = {
  x: 0,
  y: 0,
};

const substractStageOffset = (
  start: BlockPosition = defaultZeroPosition,
  end: BlockPosition = defaultZeroPosition,
  stageOffset: BlockPosition = defaultZeroPosition
): [BlockPositionNN, BlockPositionNN] => {
  if (
    isNumber(start.x) &&
    isNumber(start.y) &&
    isNumber(end.x) &&
    isNumber(end.y) &&
    isNumber(stageOffset.x) &&
    isNumber(stageOffset.y)
  ) {
    start.x -= stageOffset.x;
    start.y -= stageOffset.y;

    end.x -= stageOffset.x;
    end.y -= stageOffset.y;
  }
  //@ts-ignore
  return [start, end];
};

const divideStageScale = (
  start: BlockPosition = defaultZeroPosition,
  end: BlockPosition = defaultZeroPosition,
  stageScale: number
) => {
  if (isNumber(start.x) && isNumber(start.y) && isNumber(end.x) && isNumber(end.y)) {
    start.x = start.x / stageScale;
    start.y = start.y / stageScale;
    end.x = end.x / stageScale;
    end.y = end.y / stageScale;
  }

  return [start, end];
};

export const updateUsingStageProps = (
  stage: Konva.Stage,
  start: BlockPosition = defaultZeroPosition,
  end: BlockPosition = defaultZeroPosition
): [BlockPositionNN, BlockPositionNN, number] => {
  const stageAbsolutePos = stage.position();

  let dist = 0;

  if (isNumber(start.x) && isNumber(start.y) && isNumber(end.x) && isNumber(end.x)) {
    [start, end] = substractStageOffset(start, end, stageAbsolutePos);
    [start, end] = divideStageScale(start, end, stage.scaleX());

    dist = distance(
      //@ts-ignore
      [start.x, start.y],
      [end.x, end.y]
    );
  }

  //@ts-ignore
  return [start, end, dist];
};

const calculatePointsFromState = (stage: Konva.Stage, connector: Connection): number[] | null => {
  let points: number[] | null = [];

  const circleFrom = stage.findOne<Circle>(`.${getNameFromConnector(connector)}`);

  if (!circleFrom) {
    // console.log('no fromNode connector', connector);
    return null;
  }

  const blockTo = stage.findOne<Circle | Rect>(`#${connector.to}_bg`);

  if (!blockTo) {
    // console.log('no toNode connector', connector);
    return null;
  }

  let dist;
  let start = circleFrom.getAbsolutePosition();
  let end = blockTo.getAbsolutePosition();

  end.x += (blockTo.width() * stage.scaleX()) / 2;
  end.y += blockTo.height() * stage.scaleX();
  start.x -= 15 * stage.scaleX();

  [start, end, dist] = updateUsingStageProps(stage, start, end);

  points = [
    end.x, // start x
    end.y, // start y
    end.x, // cp1 x
    end.y, // cp1 y
    start.x - dist * 0.5, // cp2 x
    start.y, // cp2 y
    start.x, // end x
    start.y,
  ];

  return points;
};

export const getNameFromConnector = (connector: {
  from: Connection['from'];
  outerType: Connection['outerType'] | 'header';
  innerPath: Connection['innerPath'];
}) => {
  return `${connector.from}_${connector.outerType}_${JSON.stringify(connector.innerPath).replace(/,/g, '_')}`;
};

const getBaseInClassName = (id: string) => `${id}_baseIn`;

export const getConnectionToBaseInClassName = (connection: Connection) => getBaseInClassName(connection.to);

export const getBlockBaseInClassName = (block: Block) => getBaseInClassName(block.id);

export const getBaseIn = (stage: Konva.Stage, connection: Connection) =>
  stage.findOne(`.${getConnectionToBaseInClassName(connection)}`);

const calculatePoints = (stage: Konva.Stage, connector: Connection): number[] | null => {
  const circleFrom = stage.findOne<Circle>(`.${getNameFromConnector(connector)}`);
  if (!circleFrom) return null;

  const circleTo = getBaseIn(stage, connector);
  if (!circleTo) return null;

  let dist;
  let start = circleFrom.getAbsolutePosition();
  let end = circleTo.getAbsolutePosition();
  end.x -= 15 * stage.scaleX();

  [start, end, dist] = updateUsingStageProps(stage, start, end);

  return [
    start.x, // start x
    start.y, // start y
    start.x + dist * 0.5, // cp1 x
    start.y, // cp1 y
    end.x - dist * 0.5, // cp2 x
    end.y, // cp2 y
    end.x, // end x
    end.y,
  ];
};

export const makeSplines = (stageStage: Konva.Stage, connector: Connection) => {
  return connector.label === 'fromState'
    ? calculatePointsFromState(stageStage, connector)
    : calculatePoints(stageStage, connector);
};
