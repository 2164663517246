var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActiveTokenApi, RemovedTokenApi, } from '../../generated/ApiGateway';
import axios from 'axios';
class ApiGatewayService {
    constructor() {
        this.getAllTokens = (params) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.getAllActiveTokens(params.text || '', params.tokenTypes, params.sort, params.page, 10);
                return data;
            }
            catch (e) {
                console.error(e);
                return null;
            }
        });
        this.createToken = (createTokenDto) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.createActiveToken(createTokenDto);
                return data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        });
        this.getToken = (tokenId) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.getActiveToken(tokenId);
                return data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        });
        this.updateToken = (tokenId, updateTokenDto) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.updateActiveToken(tokenId, updateTokenDto);
                return data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        });
        this.refreshToken = (tokenId, refreshTokenDto) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.refreshToken(tokenId, refreshTokenDto);
                return data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        });
        this.removeToken = (tokenId) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.service.deleteToken(tokenId);
                return true;
            }
            catch (e) {
                console.log(e);
                return false;
            }
        });
        this.getAllRemovedTokens = (params) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.removedTokensService.getAllRemovedTokens(params.text || '', params.tokenTypes, params.sort, params.page, 10);
                return data;
            }
            catch (e) {
                console.error(e);
                return null;
            }
        });
        this.getTokenApiTypes = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.getActiveTypes();
                return data;
            }
            catch (e) {
                console.error(e);
                return null;
            }
        });
        this.getAvailableTypes = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.service.getAvailableTypes();
                return data;
            }
            catch (e) {
                console.error(e);
                return null;
            }
        });
        this.service = new ActiveTokenApi({}, ApiGatewayService.BASE_PATH, axios);
        this.removedTokensService = new RemovedTokenApi({}, ApiGatewayService.BASE_PATH, axios);
    }
}
ApiGatewayService.BASE_PATH = '';
export default ApiGatewayService;
