import React, { FunctionComponent } from 'react';
import { ToolbarVertical, ToolbarTop, IconButton } from '@just-ai/just-ui';
import { useLogContext } from '../LogContext';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';

const ActionsToolbar: FunctionComponent = () => {
  const { clear } = useLogContext();
  const { t } = i18nTranslation('LogPanel');
  return (
    <ToolbarVertical className='actions'>
      <ToolbarTop>
        <IconButton
          data-test-id='LogPanel.verticalToolbar.cleanButton'
          title={t('clearButtonTitle')}
          name='faEraser'
          size='sm'
          color='none'
          onClick={clear}
          flat
        />
      </ToolbarTop>
    </ToolbarVertical>
  );
};

export default ActionsToolbar;
