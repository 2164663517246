import { useEffect, useRef } from 'react';
import { filter, map } from 'rxjs';

import { useAppDispatch } from 'storeHooks';
import waitTime from 'utils/rxjsOperators/waitTime';

import { JGraphReduxActionEvent, JGraphReduxActionEventStatus, JGraphReduxStatus } from './types';
import { JGraphReduxActions$ } from './index';

function mapEventToState() {
  const eventsInPendingStatus = new Set();
  return map<JGraphReduxActionEvent, JGraphReduxStatus | undefined>(event => {
    // @ts-ignore
    const requestId: string = event.action?.meta?.requestId;
    if (!requestId) {
      console.warn('JGraphStatusIndicator => no found requestId in event.', event);
      return;
    }
    if (event.type === JGraphReduxActionEventStatus.PENDING) {
      eventsInPendingStatus.add(requestId);
    }
    if ([JGraphReduxActionEventStatus.SUCCESS, JGraphReduxActionEventStatus.ERROR].includes(event.type)) {
      eventsInPendingStatus.delete(requestId);
    }
    return {
      isError: event.type === JGraphReduxActionEventStatus.ERROR,
      isPending: eventsInPendingStatus.size > 0,
      action: event.action,
    };
  });
}

const updatedActionKeyPrefixes = [
  'JGraph/delete',
  'JGraph/add',
  'JGraph/make',
  'JGraph/resort',
  'JGraph/create',
  'JGraph/move',
  'JGraph/save',
  'JGraph/update',
  'JGraph/upsert',
  'JGraph/revert',
  'JGraph/rename',
];

export default function useJGraphStatusIndicatorSubscribe(subscribe: (status: JGraphReduxStatus) => void) {
  const subscribeRef = useRef(subscribe);
  subscribeRef.current = subscribe;

  const dispatch = useAppDispatch();
  useEffect(() => {
    const sub = JGraphReduxActions$.pipe(
      filter(event =>
        updatedActionKeyPrefixes.some(
          modifyEventPrefix => !event.action || event.action.type.startsWith(modifyEventPrefix)
        )
      ),
      mapEventToState(),
      waitTime(
        event => {
          if (event?.isPending) return 1000;
        },
        {
          passAnyway: event => !!event?.isError,
        }
      )
    ).subscribe(status => {
      if (!status) return;
      subscribeRef.current(status);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [dispatch]);
}
