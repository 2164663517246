var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { InlineRadioButton, Modal } from '../../index';
import FileTab from './FileTab';
import LinkTab from './LinkTab';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function AddOrEditPartModal(props) {
    const { type, cancelHandler, submitHandler, value } = props;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(value ? 'link' : 'file');
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [localFile, setLocalFile] = useState();
    const [localUrl, setLocalUrl] = useState(() => value || '');
    const abortController = useRef(null);
    useEffect(() => () => { var _a; return (_a = abortController.current) === null || _a === void 0 ? void 0 : _a.abort(); }, []);
    const tabChange = useCallback((_, tab) => {
        setActiveTab(tab.value);
        setError('');
    }, []);
    const localSubmit = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (isLoading || (!localFile && !localUrl))
            return;
        if (localFile instanceof File) {
            setLoading(true);
            let url;
            (_a = abortController.current) === null || _a === void 0 ? void 0 : _a.abort();
            abortController.current = new AbortController();
            try {
                if (type === AnswerReplyType.audio) {
                    url = yield props.saveAudioToServer(localFile, { signal: abortController.current.signal });
                }
                else {
                    url = yield props.saveFileToServer(localFile, { signal: abortController.current.signal });
                }
                submitHandler(url, localFile.name, type === 'file' ? localFile.type : undefined);
            }
            catch (error) {
                setError(props.getErrorMessageFromReason(error, t));
            }
            finally {
                setLoading(false);
            }
        }
        else {
            try {
                const parsedUrl = localFile ? new URL(localUrl || localFile.url) : new URL(localUrl);
                const fileNameFromUrl = decodeURI(parsedUrl.href).split('/').pop();
                submitHandler(localUrl || (localFile === null || localFile === void 0 ? void 0 : localFile.url) || '', (localFile === null || localFile === void 0 ? void 0 : localFile.name) || fileNameFromUrl || 'audio');
            }
            catch (error) {
                setError(t('AnswerBlocks:invalid_url_format'));
            }
            finally {
                setLoading(false);
            }
        }
    }), [isLoading, localFile, localUrl, props, submitHandler, t, type]);
    const localChange = useCallback((files) => __awaiter(this, void 0, void 0, function* () {
        setError('');
        if (!files)
            return setLocalFile(undefined);
        try {
            props.validateFile(type, files[0]);
            setLocalFile(files[0]);
        }
        catch (error) {
            setError(error.message);
        }
    }), [props, type]);
    const handleAudioChange = (file) => {
        setError('');
        setLocalFile(file);
    };
    return (_jsxs(Modal, { isOpen: true, title: t(`AnswerModal:title_${type}`), buttonSubmitColor: 'primary', buttonSubmitText: t(`Save`), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: cancelHandler, onActionClick: localSubmit, className: `faq-answer-part__modal faq-answer-part__modal--${type}`, buttonSubmitDisabled: isLoading || !!error, centered: true, disableActionButtonAutoFocus: true, buttonCancelTestId: 'FAQPage.AnswerBlock.ModalEdit.CancelBtn', buttonSubmitTestId: 'FAQPage.AnswerBlock.ModalEdit.SubmitBtn', children: [_jsx("div", { className: 'InlineButtons-full-width margin-bottom-3x', children: _jsx(InlineRadioButton, { fullWidth: true, active: activeTab, color: 'primary', name: 'buttonType', onChange: tabChange, options: [
                        {
                            name: t('AnswersPartModal:upload-file'),
                            value: 'file',
                            dataTestId: 'FAQPage.AnswerBlock.ModalEdit.FileTab',
                        },
                        {
                            name: t('AnswersPartModal:InsertLink'),
                            value: 'link',
                            dataTestId: 'FAQPage.AnswerBlock.ModalEdit.LinkTab',
                        },
                    ], outline: true, size: 'default' }) }), activeTab === 'file' && (_jsx(FileTab, { saveAudioToServer: props.saveAudioToServer, localFile: localFile, localChange: localChange, handleAudioChange: handleAudioChange, error: error, type: type, isLoading: isLoading, isWhiteLabel: props.isWhiteLabel })), activeTab === 'link' && (_jsx(LinkTab, { type: type, setLocalUrl: value => {
                    setError('');
                    setLocalUrl(value);
                }, localUrl: localUrl, error: error, isWhiteLabel: props.isWhiteLabel }))] }));
}
