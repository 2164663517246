import { padLeft } from '../pad';

export default function invertColor(hex: string, bw = false): string {
  if (hex.indexOf('#') === 0) hex = hex.slice(1);
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return '';
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  const sR = (255 - r).toString(16);
  const sG = (255 - g).toString(16);
  const sB = (255 - b).toString(16);
  return '#' + padLeft(sR) + padLeft(sG) + padLeft(sB);
}
