import { Cords } from './2DVector';

export function getMinMaxScreensCoords(elements: Cords[]) {
  const screensPositionsX = elements.map(screen => screen.x);
  const screensPositionsY = elements.map(screen => screen.y);

  const maxX = Math.max.apply(null, screensPositionsX);
  const minX = Math.min.apply(null, screensPositionsX);
  const minY = Math.min.apply(null, screensPositionsY);
  const maxY = Math.max.apply(null, screensPositionsY);

  return {
    maxX,
    minX,
    minY,
    maxY,
  };
}

export function joinPaths(path1 = '', path2 = '') {
  if (path1.endsWith('/')) {
    path1 = path1.substring(-1, path1.length - 1);
  }
  if (path2.startsWith('/')) {
    path2 = path2.substring(1);
  }
  return path1 + '/' + path2;
}
