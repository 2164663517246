import {
  CHANGE_PROJECT_TEMPLATE,
  CLEAR_EDITABLE_PROJECT,
  CLEAR_NLU_SETTINGS_ERROR,
  CREATE_CAILA_PROJECT_FULFILLED,
  CREATE_CAILA_PROJECT_PENDING,
  CREATE_CAILA_PROJECT_REJECTED,
  CREATE_PROJECT_FULFILLED,
  CREATE_PROJECT_PENDING,
  CREATE_PROJECT_REJECTED,
  DISABLE_SPELLCHECKER,
  FETCH_BOT_TEMPLATES_FULFILLED,
  FETCH_BOT_TEMPLATES_PENDING,
  FETCH_BOT_TEMPLATES_REJECTED,
  GET_CAILA_ACCESS_KEY_FULFILLED,
  GET_CAILA_ACCESS_KEY_PENDING,
  GET_CAILA_ACCESS_KEY_REJECTED,
  GET_CAILA_LANGUAGES_FULFILLED,
  GET_CAILA_LANGUAGES_PENDING,
  GET_CAILA_LANGUAGES_REJECTED,
  GET_CAILA_PROJECT_SETTINGS_FULFILLED,
  GET_CAILA_PROJECT_SETTINGS_PENDING,
  GET_CAILA_PROJECT_SETTINGS_REJECTED,
  IMPORT_CAILA_PROJECT_FULFILLED,
  IMPORT_CAILA_PROJECT_PENDING,
  IMPORT_CAILA_PROJECT_REJECTED,
  REMOVE_EDITABLE_PROJECT_FULFILLED,
  REMOVE_EDITABLE_PROJECT_PENDING,
  REMOVE_EDITABLE_PROJECT_REJECTED,
  SAVE_EDITABLE_PROJECT_FULFILLED,
  SAVE_EDITABLE_PROJECT_PENDING,
  SAVE_EDITABLE_PROJECT_REJECTED,
  SET_CAILA_PROJECT_LANGUAGE_FULFILLED,
  SET_CAILA_PROJECT_LANGUAGE_PENDING,
  SET_CAILA_PROJECT_LANGUAGE_REJECTED,
  SET_EDITABLE_PROJECT,
  TOGGLE_EDIT_PROJECT_MODAL,
  TOGGLE_EXTERNAL_REPOSITORY,
  UPDATE_EDITABLE_PROJECT,
} from '../../constants/projects.actions';

import { LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import { merge } from 'lodash/object';
import localize from '../../localization';
import { getErrorArgsFromReason, getErrorMessageFromReason, processErrorsArray } from 'modules/Caila/utils';

const blankProject = {
  id: null,
  name: '',
  shortName: '',
  description: '',
  repositoryUrl: '',
  contentDirectory: '.',
  defaultBranch: 'master',
  branch: 'master',
};

const InitialState = {
  editableProject: blankProject,
  useExternalRepository: false,
  botTemplates: [],
  selectedProjectTemplate: '',
  showDeleteConfirm: false,
  fetching: false,
  fetched: false,
  error: null,
  isEdit: false,
  repositoryAuthTypes: null,
  nluSettingsError: false,
};

export default function EditProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case UPDATE_EDITABLE_PROJECT:
      return {
        ...state,
        editableProject: merge({}, state.editableProject, action.editableProject),
      };

    case DISABLE_SPELLCHECKER:
      return {
        ...state,
        error: action.payload,
      };

    case SET_EDITABLE_PROJECT:
      return {
        ...state,
        editableProject: action.project,
        error: null,
        isEdit: true,
      };

    case TOGGLE_EXTERNAL_REPOSITORY: {
      const branch = action.useExternalRepository ? InitialState.editableProject.defaultBranch : 'master';
      const contentDirectory = action.useExternalRepository ? InitialState.editableProject.contentDirectory : '.';
      return {
        ...state,
        useExternalRepository: action.useExternalRepository,
        editableProject: {
          ...state.editableProject,
          branch: branch,
          contentDirectory: contentDirectory,
        },
      };
    }

    case CHANGE_PROJECT_TEMPLATE: {
      return {
        ...state,
        selectedProjectTemplate: action.projectTemplate,
      };
    }

    case FETCH_BOT_TEMPLATES_PENDING:
    case SAVE_EDITABLE_PROJECT_PENDING:
    case CREATE_PROJECT_PENDING:
    case REMOVE_EDITABLE_PROJECT_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case SAVE_EDITABLE_PROJECT_FULFILLED:
      return {
        ...state,
        editableProject: action.payload.data,
        fetched: true,
        fetching: false,
        error: null,
      };

    case FETCH_BOT_TEMPLATES_REJECTED:
    case SAVE_EDITABLE_PROJECT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: getErrorMessageFromReason(action.payload, localize.translate),
      };

    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case CLEAR_EDITABLE_PROJECT:
      return {
        ...state,
        editableProject: blankProject,
        useExternalRepository: InitialState.useExternalRepository,
        selectedProjectTemplate: InitialState.selectedProjectTemplate,
        error: null,
        isEdit: false,
      };

    case FETCH_BOT_TEMPLATES_FULFILLED: {
      const botTemplates = action.payload.data;
      return {
        ...state,
        botTemplates: botTemplates.map(botTemplate => ({
          ...botTemplate,
          parsedJson: JSON.parse(botTemplate.json),
        })),
        fetching: false,
      };
    }

    case REMOVE_EDITABLE_PROJECT_FULFILLED:
      return {
        ...state,
        editableProject: blankProject,
        channelName: null,
        error: null,
        isEdit: false,
        fetching: false,
      };

    case REMOVE_EDITABLE_PROJECT_REJECTED:
      return {
        ...state,
        error: getErrorMessageFromReason(action.payload),
        fetching: false,
      };

    case CREATE_PROJECT_FULFILLED:
      //that's here because for some purpose ProjectEditForm open's on project create
      const isFromProjectCreate = window.location.pathname.includes('project-create');
      return {
        ...state,
        editableProject: isFromProjectCreate ? blankProject : action.payload.data,
        isEdit: !isFromProjectCreate,
        fetching: false,
        error: null,
      };

    case CREATE_PROJECT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: getErrorMessageFromReason(action.payload, localize.translate),
      };

    case TOGGLE_EDIT_PROJECT_MODAL:
      return {
        ...state,
        showDeleteConfirm: action.flag,
      };

    case CREATE_CAILA_PROJECT_PENDING:
    case SET_CAILA_PROJECT_LANGUAGE_PENDING:
    case GET_CAILA_PROJECT_SETTINGS_PENDING:
    case GET_CAILA_LANGUAGES_PENDING:
    case GET_CAILA_ACCESS_KEY_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case CREATE_CAILA_PROJECT_FULFILLED:
    case GET_CAILA_PROJECT_SETTINGS_FULFILLED:
    case GET_CAILA_ACCESS_KEY_FULFILLED:
    case GET_CAILA_LANGUAGES_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_CAILA_PROJECT_SETTINGS_REJECTED: {
      const errorsArr = action.payload.response.data.errors;
      return {
        ...state,
        fetching: false,
        error: processErrorsArray(errorsArr),
      };
    }

    case CREATE_CAILA_PROJECT_REJECTED: {
      const errorsArr = action.payload.response.data.errors;
      return {
        ...state,
        fetching: false,
        error: processErrorsArray(errorsArr),
      };
    }

    case SET_CAILA_PROJECT_LANGUAGE_REJECTED: {
      let cailaError = null;
      let nluSettingsError = false;
      const errorsArr = action.payload.response.data.errors;
      try {
        if (action.payload.response.data.error.errorCode !== 'caila.common.http_message_not_readable') {
          cailaError = processErrorsArray(errorsArr);
        } else {
          nluSettingsError = true;
        }
      } catch (error) {
        console.error('Error processing errors array', error);
      }
      if (errorsArr && errorsArr.length > 0) {
        const nluErrors = errorsArr.filter(item => item.errorCode.includes('extended_settings'));
        if (
          nluErrors &&
          nluErrors.length > 0 &&
          (!cailaError || cailaError.includes(localize.translate('caila set language error')))
        ) {
          nluSettingsError = true;
          cailaError = nluErrors.map(error => error.message);
        }
      }
      return {
        ...state,
        fetching: false,
        error: cailaError,
        nluSettingsError: nluSettingsError,
      };
    }

    case GET_CAILA_LANGUAGES_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: [localize.translate('caila get languages error')],
        nluSettingsError: false,
      };
    }

    case SET_CAILA_PROJECT_LANGUAGE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        nluSettingsError: false,
      };
    }
    case CLEAR_NLU_SETTINGS_ERROR: {
      return {
        ...state,
        nluSettingsError: false,
      };
    }

    case GET_CAILA_ACCESS_KEY_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: [localize.translate('caila error generate access key')],
      };
    }

    case IMPORT_CAILA_PROJECT_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case IMPORT_CAILA_PROJECT_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case IMPORT_CAILA_PROJECT_REJECTED: {
      const errorArgs = getErrorArgsFromReason(action.payload);
      return {
        ...state,
        fetching: false,
        error: [localize.translate('import project error')],
        errorArgs: errorArgs,
      };
    }

    default:
      return state;
  }
}
