const prefix = '@systemDialogsSettings/';
export const GET_COMPONENT_SETTINGS = prefix + 'GET_COMPONENT_SETTINGS';
export const GET_COMPONENT_SETTINGS_PENDING = prefix + 'GET_COMPONENT_SETTINGS_PENDING';
export const GET_COMPONENT_SETTINGS_FULFILLED = prefix + 'GET_COMPONENT_SETTINGS_FULFILLED';
export const GET_COMPONENT_SETTINGS_REJECTED = prefix + 'GET_COMPONENT_SETTINGS_REJECTED';

export const TOGGLE_SETTING = prefix + 'TOGGLE_SETTING';

export const ADD_CHILD = prefix + 'ADD_CHILD';
export const ADD_CHILD_PENDING = prefix + 'ADD_CHILD_PENDING';
export const ADD_CHILD_FULFILLED = prefix + 'ADD_CHILD_FULFILLED';
export const ADD_CHILD_REJECTED = prefix + 'ADD_CHILD_REJECTED';

export const EDIT_CHILD = prefix + 'EDIT_CHILD';

export const REMOVE_ADDED_CHILD = prefix + 'REMOVE_ADDED_CHILD';

export const SAVE_CHILD = prefix + 'SAVE_CHILD';
export const SAVE_CHILD_PENDING = prefix + 'SAVE_CHILD_PENDING';
export const SAVE_CHILD_FULFILLED = prefix + 'SAVE_CHILD_FULFILLED';
export const SAVE_CHILD_REJECTED = prefix + 'SAVE_CHILD_REJECTED';

export const DELETE_CHILD = prefix + 'DELETE_CHILD';
export const DELETE_CHILD_PENDING = prefix + 'DELETE_CHILD_PENDING';
export const DELETE_CHILD_FULFILLED = prefix + 'DELETE_CHILD_FULFILLED';
export const DELETE_CHILD_REJECTED = prefix + 'DELETE_CHILD_REJECTED';

export const SIMPLE_ADD_CHILD = prefix + 'SIMPLE_ADD_CHILD';
export const SIMPLE_ADD_CHILD_PENDING = prefix + 'SIMPLE_ADD_CHILD_PENDING';
export const SIMPLE_ADD_CHILD_FULFILLED = prefix + 'SIMPLE_ADD_CHILD_FULFILLED';
export const SIMPLE_ADD_CHILD_REJECTED = prefix + 'SIMPLE_ADD_CHILD_REJECTED';

export const SIMPLE_EDIT_CHILD = prefix + 'SIMPLE_EDIT_CHILD';

export const SIMPLE_SAVE_CHILD = prefix + 'SIMPLE_SAVE_CHILD';
export const SIMPLE_SAVE_CHILD_PENDING = prefix + 'SIMPLE_SAVE_CHILD_PENDING';
export const SIMPLE_SAVE_CHILD_FULFILLED = prefix + 'SIMPLE_SAVE_CHILD_FULFILLED';
export const SIMPLE_SAVE_CHILD_REJECTED = prefix + 'SIMPLE_SAVE_CHILD_REJECTED';

export const SIMPLE_DELETE_CHILD = prefix + 'SIMPLE_DELETE_CHILD';
export const SIMPLE_DELETE_CHILD_PENDING = prefix + 'SIMPLE_DELETE_CHILD_PENDING';
export const SIMPLE_DELETE_CHILD_FULFILLED = prefix + 'SIMPLE_DELETE_CHILD_FULFILLED';
export const SIMPLE_DELETE_CHILD_REJECTED = prefix + 'SIMPLE_DELETE_CHILD_REJECTED';

export const ADD_PARENT = prefix + 'ADD_PARENT';

export const EDIT_PARENT = prefix + 'EDIT_PARENT';

export const DELETE_PARENT = prefix + 'DELETE_PARENT';
export const DELETE_PARENT_PENDING = prefix + 'DELETE_PARENT_PENDING';
export const DELETE_PARENT_FULFILLED = prefix + 'DELETE_PARENT_FULFILLED';
export const DELETE_PARENT_REJECTED = prefix + 'DELETE_PARENT_REJECTED';

export const SAVE_PARENT = prefix + 'SAVE_PARENT';
export const SAVE_PARENT_PENDING = prefix + 'SAVE_PARENT_PENDING';
export const SAVE_PARENT_FULFILLED = prefix + 'SAVE_PARENT_FULFILLED';
export const SAVE_PARENT_REJECTED = prefix + 'SAVE_PARENT_REJECTED';
