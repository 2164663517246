import React, { FC, useCallback, useRef } from 'react';
import { Rect, Path, Group } from 'react-konva';
import { setCursorOnMouseEnterAndLeave } from '../../utils/stageUtils';
import Konva from 'konva';
import { JGraphAddingMenuType } from '../../../../reducers/JGraph.reducer';

export const KonvaButton: FC<{
  x?: number;
  y?: number;
  onClick?: (event: Konva.KonvaEventObject<MouseEvent>) => unknown;
  type?: JGraphAddingMenuType;
}> = ({ x, y, onClick = () => {}, type }) => {
  const rectButton = useRef<Konva.Rect>(null);
  const rectIcon = useRef<Konva.Path>(null);

  const hoverHandler = useCallback(
    (enterOrLeave?: 'enter' | 'leave') => () => {
      switch (enterOrLeave) {
        case 'enter':
          rectButton.current?.fill('#0D1822');
          rectIcon.current?.fill('white');
          break;
        case 'leave':
          rectButton.current?.fill('white');
          rectIcon.current?.fill('#6A7178');
          break;
        default:
          break;
      }
    },
    []
  );

  return (
    <Group
      x={x}
      y={y}
      onMouseEnter={setCursorOnMouseEnterAndLeave(hoverHandler('enter'))}
      onMouseLeave={setCursorOnMouseEnterAndLeave(hoverHandler('leave'))}
      onClick={onClick}
      button={true}
      actionType={type}
    >
      <Rect
        ref={rectButton}
        x={0}
        y={0}
        fill='white'
        width={23}
        height={23}
        cornerRadius={3}
        stroke='#E0E1E3'
        strokeEnabled={true}
        strokeWidth={1}
        border={1}
        borderEnableed={true}
      />
      <Path
        ref={rectIcon}
        data='M11.5 5H7V0.5C7 0.22375 6.77625 0 6.5 0H5.5C5.22375 0 5 0.22375 5 0.5V5H0.5C0.22375 5 0 5.22375 0 5.5V6.5C0 6.77625 0.22375 7 0.5 7H5V11.5C5 11.7762 5.22375 12 5.5 12H6.5C6.77625 12 7 11.7762 7 11.5V7H11.5C11.7762 7 12 6.77625 12 6.5V5.5C12 5.22375 11.7762 5 11.5 5Z'
        fill='#6A7178'
        width={12}
        height={12}
        x={6}
        y={6}
      />
    </Group>
  );
};
