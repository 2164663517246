import {
  CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED,
  CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED,
  CHANGE_SELECTED_ITEM,
  DESELECT_ITEM,
  DROP_CURRENT_LOGIN_ACCOUNT,
  LOGIN_TO_ACCOUNT_FULFILLED,
  LOGIN_TO_ACCOUNT_REJECTED,
  LOGOUT_FROM_ACCOUNT_FULFILLED,
  LOGOUT_FROM_ACCOUNT_REJECTED,
  NEW_SELECT_FEATURES,
  REMOVE_ADD_ACCOUNT_ALERT,
  REMOVE_EDIT_ACCOUNT_ALERT,
  DROP_EDIT_ACCOUNT_ERRORS,
  SELECT_ITEM,
  SET_CURRENT_LOGIN_ACCOUNT,
} from '../../constants/accounts.actions';

import {
  DROP_CURRENT_USER,
  LOGIN_FULFILLED,
  LOGOUT_FULFILLED,
  LOGOUT_PENDING,
} from '../../constants/currentUser.actions';

const InitialState = {
  accounts: [],
  selectedAccount: null,
  errors: [],
  fetching: false,
  filterFetching: false,
  page: Boolean(localStorage.getItem('ACCOUNTS_PAGE')) ? localStorage.getItem('ACCOUNTS_PAGE') : 0,
  totalPages: 0,
  filterData: getInitialFilterData(),
  loggedInAccount: null,
  loggedInAccountFeatures: [],
  addAccountErrors: [],
  editAccountErrors: [],
  accountStatus: [],
  totalElements: 0,
};

export default function AccountsReducer(state = InitialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED: {
      let account = state.accounts.find(a => a.accountId === action.payload.data.account.id);
      account.id = account.accountId;

      localStorage.setItem('LOGIN_TO_ACCOUNT', JSON.stringify(account));
      localStorage.setItem('LOGIN_TO_ACCOUNT_FEATURES', JSON.stringify(action.payload.data.features));
      return {
        ...state,
        loggedInAccount: account,
        loggedInAccountFeatures: action.payload.data.features,
      };
    }

    case CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED:
    case LOGIN_TO_ACCOUNT_REJECTED: {
      // TODO remove mock
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case LOGOUT_PENDING: // Logout fulfilled constantly not happening
    case LOGOUT_FULFILLED:
    case DROP_CURRENT_USER:
    case DROP_CURRENT_LOGIN_ACCOUNT:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      localStorage.removeItem('LOGIN_TO_ACCOUNT');
      localStorage.removeItem('LOGIN_TO_ACCOUNT_FEATURES');
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case LOGOUT_FROM_ACCOUNT_REJECTED: {
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case CHANGE_SELECTED_ITEM: {
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          [action.what]: action.value,
        },
      };
    }
    case SET_CURRENT_LOGIN_ACCOUNT: {
      localStorage.setItem('LOGIN_TO_ACCOUNT', JSON.stringify(action.obj));
      localStorage.setItem('LOGIN_TO_ACCOUNT_FEATURES', JSON.stringify(action.features));
      return {
        ...state,
        loggedInAccount: action.obj,
        loggedInAccountFeatures: action.features,
      };
    }

    case REMOVE_ADD_ACCOUNT_ALERT: {
      let newAccountAlert = [...state.addAccountErrors];
      newAccountAlert.splice(action.key, 1);
      return {
        ...state,
        addAccountErrors: newAccountAlert,
      };
    }

    case REMOVE_EDIT_ACCOUNT_ALERT: {
      let newEditAccountAlert = [...state.editAccountErrors];
      newEditAccountAlert.splice(action.key, 1);
      return {
        ...state,
        editAccountErrors: newEditAccountAlert,
      };
    }

    case DROP_EDIT_ACCOUNT_ERRORS: {
      return {
        ...state,
        editAccountErrors: InitialState.editAccountErrors,
      };
    }

    case SELECT_ITEM: {
      let selectedAccount = state.accounts.filter(item => {
        return item.accountId === action.id;
      })[0];
      return {
        ...state,
        selectedAccount: selectedAccount,
      };
    }
    case DESELECT_ITEM: {
      return {
        ...state,
        selectedAccount: null,
      };
    }

    case NEW_SELECT_FEATURES: {
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          [action.data.name]: action.data.data,
        },
      };
    }

    case LOGIN_FULFILLED: {
      if (action.payload.data.ccSession) {
        const logInAccount = action.payload.data.logInAccount;
        if (logInAccount) {
          logInAccount.id = logInAccount.accountId;
          const features = logInAccount?.projectFeatures.map(feature => feature.feature) || [];
          localStorage.LOGIN_TO_ACCOUNT = JSON.stringify(logInAccount);
          if (features.length > 0) {
            localStorage.LOGIN_TO_ACCOUNT_FEATURES = JSON.stringify(features);
          } else {
            localStorage.removeItem('LOGIN_TO_ACCOUNT_FEATURES');
          }
          return {
            ...state,
            loggedInAccount: logInAccount,
            loggedInAccountFeatures: features,
          };
        } else {
          localStorage.removeItem('LOGIN_TO_ACCOUNT');
          localStorage.removeItem('LOGIN_TO_ACCOUNT_FEATURES');
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

function getInitialFilterData() {
  const emptyFilterData = {
    name: '',
    createDateFrom: null,
    createDateTo: null,
    ownerName: '',
    ownerLogin: '',
    featureNames: [],
    featureTypes: [],
    accountStatus: [],
  };

  const data = { ...emptyFilterData };
  const savedFilterDataStr = localStorage.getItem('ACCOUNTS_FILTER_DATA');

  if (Boolean(savedFilterDataStr)) {
    try {
      const savedFilterData = JSON.parse(savedFilterDataStr);

      if (Boolean(savedFilterData.name)) {
        data.name = savedFilterData.name;
      }

      if (Boolean(savedFilterData.ownerName)) {
        data.ownerName = savedFilterData.ownerName;
      }

      if (Boolean(savedFilterData.ownerLogin)) {
        data.ownerLogin = savedFilterData.ownerLogin;
      }

      if (Boolean(savedFilterData.createDateFrom)) {
        data.createDateFrom = savedFilterData.createDateFrom;
      }

      if (Boolean(savedFilterData.createDateTo)) {
        data.createDateTo = savedFilterData.createDateTo;
      }

      if (Boolean(savedFilterData.featureNames) && savedFilterData.featureNames.length > 0) {
        data.featureNames = savedFilterData.featureNames;
      }

      if (Boolean(savedFilterData.featureTypes) && savedFilterData.featureTypes.length > 0) {
        data.featureTypes = savedFilterData.featureTypes;
      }

      if (Boolean(savedFilterData.dateFromTimeLimit)) {
        data.dateFromTimeLimit = savedFilterData.dateFromTimeLimit;
      }
      if (Boolean(savedFilterData.accountStatus)) {
        data.accountStatus = savedFilterData.accountStatus;
      }
    } catch (e) {
      console.error("Can't parse saved accounts filter data");
    }
  }

  return data;
}
