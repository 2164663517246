import {
  FETCH,
  ADD_LABELS,
  ADD_TEXTAREA,
  CHANGE_MARK_FIELD,
  SET_SELECTED_LOG_MARKS,
  ADD_LOG_TASK,
  UPDATE_LOG_TASK,
} from '../../constants/phrases.actions';

import { axios } from '../../pipes/functions';
import { isReporterEnabled } from '../../isAccessFunction';

export const fetchPhrases = ({ projectShortName, filterParams = {}, $filter = false, action = null }) => {
  let newFilterParams = { ...filterParams };
  if (newFilterParams.hasOwnProperty('phrasesPage')) {
    newFilterParams.page = newFilterParams.phrasesPage;
    delete newFilterParams.phrasesPage;
  }
  return {
    type: FETCH,
    payload: action
      ? action()
      : axios._get(`/restapi/logs/${projectShortName}/steps/filter`, {
          params: newFilterParams,
          filter: $filter,
          searchObj: newFilterParams,
        }),
  };
};

export const addPhraseLabels = (logId, projectShortName, array = []) => {
  return {
    type: ADD_LABELS,
    logId,
    payload: axios._put(
      isReporterEnabled()
        ? `/restapi/logs/${projectShortName}/${logId /*that is questionId*/}/labels/byQuestionId`
        : `/restapi/logs/${projectShortName}/${logId}/labels`,
      {
        labels: array,
      },
      {
        logId: logId,
      }
    ),
  };
};

export const addPhraseComment = (logId, projectShortName, comment = '') => {
  return {
    type: ADD_TEXTAREA,
    logId,
    payload: axios._put(
      isReporterEnabled()
        ? `/restapi/logs/${projectShortName}/${logId /*that is questionId*/}/comment/byQuestionId`
        : `/restapi/logs/${projectShortName}/${logId}/comment`,
      {
        comment: comment,
      },
      {
        logId: logId,
      }
    ),
  };
};

export const changeMarkField = (logId, value) => {
  return {
    type: CHANGE_MARK_FIELD,
    logId,
    value,
  };
};

export const setSelectLogItemMarks = (value, type, logId) => {
  return {
    type: SET_SELECTED_LOG_MARKS,
    value: value,
    markType: type,
    logId: logId,
  };
};

export const createLogTask = (projectShortName, obj = {}) => {
  return {
    type: ADD_LOG_TASK,
    payload: axios._post(
      isReporterEnabled()
        ? `/restapi/logs/issues/${projectShortName}/createByQuestionId`
        : `/restapi/logs/issues/${projectShortName}`,
      obj,
      { obj: obj }
    ),
  };
};
export const updateLogTask = (projectShortName, obj = {}) => {
  return {
    type: UPDATE_LOG_TASK,
    payload: axios._put(`/restapi/logs/issues/${projectShortName}/${obj.issueId}`, obj, { obj: obj }),
  };
};
