import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Icon, InputText } from '@just-ai/just-ui';

import { t } from 'localization';

import { useJGraphContext } from '../../../contexts/JGraphContext';
import { useRightSideMenuContext } from '../index';
import { RSAddingMenuItem } from './RSAddingMenuItem';
import { ActivationsAddingMenu } from './ActivationsAddingMenu';
import { getResponsesByBlockType } from './responses';
import { HowToCreateCustomTag } from './HowToCreateCustomTag';

import classes from './RSAddingMenu.module.scss';

export const RSAddingMenu: FC<{}> = () => {
  const { isJGraphLite } = useJGraphContext();
  const { eventsState, getEditBlockType } = useRightSideMenuContext();
  const { addingMenuType, activationEvent, activationsMenuType } = eventsState;
  const [stateResponses] = useState(() => getResponsesByBlockType(getEditBlockType()));
  const [showedResponses, setShowedResponses] = useState(stateResponses);

  const filterByName = useCallback(
    (value: string) => {
      if (!value) {
        setShowedResponses(stateResponses);
        return;
      }
      const lowerValue = value.toLowerCase();
      const newBotResponses = showedResponses.BotResponses.filter(response =>
        t(`ReactionItem ${response}`).toLowerCase().includes(lowerValue)
      );
      const newLogicResponses = showedResponses.LogicResponses.filter(response =>
        t(`ReactionItem ${response}`).toLowerCase().includes(lowerValue)
      );
      const newMetaResponses = showedResponses.MetaResponses.filter(
        response =>
          t(`ReactionItem ${response}`).toLowerCase().includes(lowerValue) ||
          t(`CustomTag:${response}:caption`).toLowerCase().includes(lowerValue)
      );
      setShowedResponses({
        BotResponses: newBotResponses,
        LogicResponses: newLogicResponses,
        MetaResponses: newMetaResponses,
      });
    },
    [showedResponses, stateResponses]
  );

  if (addingMenuType === 'reactions') {
    return (
      <div className={classes.RSAddingMenu}>
        <div className={classes.RSAddingMenuSearchBar}>
          <Icon name='farSearch' wrapperClassName={classes.RSAddingMenuSearchInputIcon} />
          <InputText placeholder={t('RSAddingMenu:search-placeholder')} onChange={filterByName} />
        </div>
        {showedResponses.BotResponses.length > 0 && (
          <>
            <h5>{t('RSAddingMenu:title-BotResponses')}</h5>
            {showedResponses.BotResponses.map(name => (
              <RSAddingMenuItem key={`BotResponses-${name}`} name={name} />
            ))}
          </>
        )}
        {showedResponses.LogicResponses.length > 0 && (
          <>
            <h5>{t('RSAddingMenu:title-LogicResponses')}</h5>
            {showedResponses.LogicResponses.map(name => (
              <RSAddingMenuItem key={`LogicResponses-${name}`} name={name} />
            ))}
          </>
        )}
        {showedResponses.MetaResponses.length > 0 && (
          <>
            <h5>{t('RSAddingMenu:title-MetaResponses')}</h5>
            {showedResponses.MetaResponses.map(name => (
              <RSAddingMenuItem key={`MetaResponses-${name}`} name={name} />
            ))}
          </>
        )}
        {!isJGraphLite && <HowToCreateCustomTag />}
      </div>
    );
  }
  if (addingMenuType === 'activations' || addingMenuType === 'fromStateEvents' || addingMenuType === 'toStateEvents') {
    return (
      <div
        className={classNames(classes.RSAddingMenu, {
          [classes.RSAddingMenuEvent]:
            activationEvent || activationsMenuType === 'pattern' || activationsMenuType === 'intent',
        })}
      >
        <ActivationsAddingMenu />
      </div>
    );
  }
  return null;
};
