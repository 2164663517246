import { LogLabelingReducer, LogLabelingState } from '.';
import { Phrases, Groups } from '../../../utils';
import { filterAllPhrasesAndAllGroups } from './grouping.reducer';
import { GroupInfo } from '../../../utils/groups';

const selectGroups = (state: LogLabelingState, selectedGroupIds: string[]) => {
  const selectedGroups = selectedGroupIds.map(id => state.filteredAllGroups[id]).filter(group => group !== undefined);

  if (selectedGroups.length === 0) {
    return { ...state, selectedGroupIds: [], groups: state.filteredAllGroups, phrases: {} };
  }

  const commonPhraseIdxs = Groups.getCommonPhraseIdxs(selectedGroups);

  if (commonPhraseIdxs.length === 0) {
    return { ...state, selectedGroupIds: [], groups: state.filteredAllGroups, phrases: {} };
  }
  const selectedGroupsDataset = selectedGroups
    .map(group => Groups.filterPhrases(group, commonPhraseIdxs))
    .sort(Groups.byPhraseCountDescending)
    .reduce(Groups.addNode, {});

  const groups = Object.values(state.allGroups)
    .filter(group => !selectedGroupIds.includes(group.nodeId))
    .map(group => Groups.filterPhrases(group, commonPhraseIdxs))
    .filter(group => group.phrases.length > 0)
    .sort(Groups.byPhraseCountDescending)
    .reduce(Groups.addNode, selectedGroupsDataset);

  return {
    ...state,
    selectedGroupIds,
    groups,
    phrases: Phrases.sortDataset(
      Phrases.selectGroupPhrases(
        commonPhraseIdxs.map(phraseIdx => ({ phraseIdx })),
        state.filteredAllPhrases
      )
    ),
  };
};

const initReducer = (state: LogLabelingState, allGroups: GroupInfo[]) => {
  const newState = filterAllPhrasesAndAllGroups({
    ...state,
    allGroups: Groups.toDatasetWithoutUngrouped(allGroups),
  });
  return {
    ...newState,
    groups: newState.filteredAllGroups,
    multipleSelectByClick: true,
  };
};

const KeywordsReducer: LogLabelingReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initReducer(state, action.allGroups);
    case 'DELETE_PHRASES':
    case 'STAGE_PHRASES':
    case 'CLEAR_PHRASES':
    case 'TOGGLE_SHOW_LABELED':
    case 'SET_PHRASES_FILTER':
      return selectGroups(state, state.selectedGroupIds);
    case 'SELECT_GROUPS':
      return selectGroups(state, action.groupIds);
    default:
      return { ...state };
  }
};

export default KeywordsReducer;
