import {
  GET_SALES_REQUEST_DATA_FULFILLED,
  GET_SALES_REQUEST_DATA_PENDING,
  GET_SALES_REQUEST_DATA_REJECTED,
  SEND_SALES_REQUEST_FULFILLED,
  SEND_SALES_REQUEST_PENDING,
  SEND_SALES_REQUEST_REJECTED,
} from '../../constants/salesRequest.actions';

const InitialState = {
  fetching: false,
  salesRequestData: {
    fullName: null,
    email: null,
    phoneCountryCode: null,
    phoneNumber: null,
    mailTo: null,
  },
};

export default function SalesRequestReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_SALES_REQUEST_DATA_PENDING:
    case SEND_SALES_REQUEST_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_SALES_REQUEST_DATA_REJECTED:
    case SEND_SALES_REQUEST_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case SEND_SALES_REQUEST_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_SALES_REQUEST_DATA_FULFILLED: {
      return {
        ...state,
        fetching: false,
        salesRequestData: action.payload.data.requstData,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
