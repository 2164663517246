import { useRef, useState, useCallback, useEffect } from 'react';

import { AppLogger } from 'services/AppLogger';
import { LocalStorageService } from 'services/Storage/LocalStorageService';

import useService from './useSevice';

type Serializer<DATA = any> = {
  serialize(data: DATA): string;
  deserialize(str: string): DATA;
};

export const boolSerializer: Serializer<boolean> = {
  deserialize: str => str === 'true',
  serialize: data => (data ? 'true' : 'false'),
};
export const objectSerializer: Serializer<Record<string, any>> = {
  deserialize: str => JSON.parse(str),
  serialize: data => JSON.stringify(data),
};
export const stringSerializer: Serializer<string> = {
  deserialize: str => str,
  serialize: data => data,
};
export const intSerializer: Serializer<number> = {
  deserialize: str => parseInt(str),
  serialize: data => data.toString(),
};

export function useLocalStorageState<VALUE = any>(
  initialValue: VALUE,
  key: string,
  isPermanentStored = false,
  serializer: Serializer = stringSerializer
): [VALUE, (value: VALUE) => void] {
  const lsService = useService(LocalStorageService);
  const refStorageValue = useRef<string | null>(null);
  const [value, setValue] = useState<VALUE>(initialValue);

  const setValueHandler = useCallback(
    (value: VALUE) => {
      setValue(value);
      try {
        const stringValue = serializer.serialize(value);
        refStorageValue.current = stringValue;
        // noinspection JSIgnoredPromiseFromCall
        lsService.set(key, stringValue, isPermanentStored, null);
        return;
      } catch (error) {
        AppLogger.error({
          message: `Serialization error in useLocalStorageState by key: "${key}"`,
          exception: error as Error,
        });
      }
    },
    [isPermanentStored, key, lsService, serializer]
  );

  useEffect(() => {
    const syncValue = async () => {
      const storageValue = await lsService.get<string>(key, null);
      if (!storageValue.success || !storageValue.payload) {
        refStorageValue.current = null;
        return;
      }
      if (refStorageValue.current === storageValue.payload) return;
      refStorageValue.current = storageValue.payload;

      try {
        const parsedValue = serializer.deserialize(storageValue.payload);
        if (parsedValue) {
          setValue(parsedValue);
          return;
        }
      } catch (error) {
        AppLogger.error({
          message: `Serialization error in useLocalStorageState by key: "${key}"`,
          exception: error as Error,
        });
      }
    };

    syncValue();
    window.addEventListener('storage', syncValue);
    return () => window.removeEventListener('storage', syncValue);
  }, [key, lsService, serializer]);

  return [value, setValueHandler];
}
