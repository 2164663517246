import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';

export enum EventClickName {
  MouseUp = 'mouseup',
  MouseDown = 'mousedown',
}

interface ClickOutSideInterface {
  children: ReactNode;
  handleClickOut: () => void;
  handleEnabled?: boolean;
  delay?: number;
  clickEventName?: EventClickName;
  refs?: (HTMLElement | undefined)[];
}
class ClickAwayListener extends React.PureComponent<ClickOutSideInterface> {
  static defaultProps = {
    handleEnabled: true,
  };
  handler: ((event: any) => void) | null = null;

  componentDidMount() {
    this.handler = this.props.delay ? debounce(this.handleClickOut, this.props.delay) : this.handleClickOut;
    document.addEventListener(this.props.clickEventName || EventClickName.MouseDown, this.handler);
  }

  componentWillUnmount() {
    if (!this.handler) return;
    document.removeEventListener(this.props.clickEventName || EventClickName.MouseDown, this.handler);
  }

  hasElementIntoRefs = (refs: HTMLElement[], element: HTMLElement): boolean => {
    if (!refs || !refs.length) return false;
    return refs.filter(Boolean).some((elem: HTMLElement) => {
      return elem.contains(element);
    });
  };

  handleClickOut = (event: any) => {
    const { handleEnabled, handleClickOut, refs } = this.props;
    if (!handleEnabled) return;

    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      // @ts-ignore
      if (this.hasElementIntoRefs(refs, event.target)) return;
      handleClickOut();
    }
    event.stopPropagation();
  };

  render() {
    return this.props.children;
  }
}

export default ClickAwayListener;
