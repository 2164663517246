import React, { FC, useCallback } from 'react';
import { IconButton, useId } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from '../styles.module.scss';
import { CustomRxTooltip } from '@just-ai/just-ui';
import { t } from '../../../../../localization';
import { StateCollapseSubject$, useStateCollapse } from '../../StateScreen.hooks';

export const CollapseBlockButton: FC<{ screenPathId: string; screenPath: string }> = ({ screenPathId, screenPath }) => {
  const id = useId();
  const { isCollapsed } = useStateCollapse(screenPathId);

  const onClickHandler = useCallback(() => {
    StateCollapseSubject$.next({ statePathId: screenPathId, statePath: screenPath, collapsed: !isCollapsed });
  }, [isCollapsed, screenPath, screenPathId]);

  return (
    <>
      <IconButton
        flat
        data-test-id='JGraph.LabellingToolMenu.HighLightConnectorsButton'
        id={id}
        color='secondary'
        name={isCollapsed ? 'faVisibilityBlockCollapsed' : 'faVisibilityBlock'}
        className={cn(styles.selectLabelColor, {
          [styles.selectLabelColor_fillBlue]: isCollapsed,
          [styles.selectLabelColor_fillBlue_active]: isCollapsed,
        })}
        onClick={onClickHandler}
      />
      <CustomRxTooltip target={`#${id}`} placement='bottom'>
        {isCollapsed
          ? t('JGraph:LabellingToolMenu:CollapseBlockButton:tooltip_off')
          : t('JGraph:LabellingToolMenu:CollapseBlockButton:tooltip_on')}
      </CustomRxTooltip>
    </>
  );
};
