import {
  CLEAR_EDITABLE_CHANNEL,
  CREATE_CHANNEL_FULFILLED,
  FETCH_CHANNELS_FULFILLED,
  FETCH_CHANNELS_PENDING,
  FETCH_CHANNELS_REJECTED,
  GET_CHANNEL_FULFILLED,
  PUBLISH_CHANNEL_FULFILLED,
  PUBLISH_CHANNEL_REJECTED,
  REMOVE_EDITABLE_CHANNEL_FULFILLED,
  SAVE_EDITABLE_CHANNEL_FULFILLED,
  SET_EDITABLE_CHANNEL,
  SET_PUBLISH_CHANNEL_ID,
  SET_CHANNEL,
} from '../../constants/channels.actions';

import { REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED } from '../../constants/livechatChannels.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import { ALERT_RECEIVING } from '../../constants/globalAlert.actions';

const InitialState = {
  channelList: [],
  branchList: [],
  selectedChannelId: null,
  publishingChannelId: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function ChannelsReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_CHANNELS_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_CHANNELS_FULFILLED:
      let channelList = action.payload.data;
      channelList = channelList.reverse();
      return {
        ...state,
        channelList: channelList,
        fetched: true,
        fetching: false,
      };

    case FETCH_CHANNELS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data,
      };

    case ALERT_RECEIVING: {
      const channelListNew = [...state.channelList];

      if (action.payload.title === 'Changes in the scenario') {
        channelListNew.forEach(channel => {
          if (channel.rollout === 'MANUAL') {
            channel.outdated = true;
          }
        });
      }

      return {
        ...state,
        channelList: channelListNew,
      };
    }

    case SET_EDITABLE_CHANNEL:
      return {
        ...state,
        selectedChannelId: action.channel.id,
      };

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case CLEAR_EDITABLE_CHANNEL:
      return {
        ...state,
        selectedChannelId: null,
      };

    case CREATE_CHANNEL_FULFILLED:
      let channelListAdded = [...state.channelList];
      const channel = action.payload.data;

      if (channel.project.jaicfProject) {
        if (channel.lastDeployResult) channel.lastDeployResult.status = 'IN_PROGRESS';
        else channel.lastDeployResult = { status: 'IN_PROGRESS' };
      }

      channelListAdded.unshift(channel);
      return {
        ...state,
        channelList: channelListAdded,
        selectedChannelId: null,
        fetched: true,
        fetching: false,
      };

    case SAVE_EDITABLE_CHANNEL_FULFILLED:
      return {
        ...state,
        channelList: state.channelList.map(channel => {
          return channel.id === action.payload.data.id ? action.payload.data : channel;
        }),
      };

    case REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED: {
      const newChannelList = [...state.channelList];
      newChannelList.forEach(item => {
        item.livechatId = item.livechatId === action.payload.data.liveChatId ? null : item.livechatId;
      });

      return {
        ...state,
        channelList: newChannelList,
      };
    }

    case REMOVE_EDITABLE_CHANNEL_FULFILLED:
      let channelListRemoved = [...state.channelList];
      channelListRemoved = channelListRemoved.filter(channel => {
        return channel.id !== action.payload.data.id;
      });
      return {
        ...state,
        channelList: channelListRemoved,
        selectedChannelId: null,
      };

    case SET_PUBLISH_CHANNEL_ID:
      return {
        ...state,
        publishingChannelId: action.id,
      };

    case PUBLISH_CHANNEL_REJECTED:
      return {
        ...state,
        publishingChannelId: null,
      };
    case PUBLISH_CHANNEL_FULFILLED:
      return {
        ...state,
        channelList: state.channelList.map(channel => {
          return channel.id === action.payload.data.id ? action.payload.data : channel;
        }),
        publishingChannelId: null,
      };

    case GET_CHANNEL_FULFILLED: {
      return {
        ...state,
        channelList: state.channelList.map(channel => {
          return channel.id === action.payload.data.id ? action.payload.data : channel;
        }),
      };
    }
    case SET_CHANNEL: {
      return {
        ...state,
        channelList: state.channelList.map(channel => {
          return channel.id === action.payload.data.id ? action.payload.data : channel;
        }),
      };
    }
    default:
      return state;
  }
}
