import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateJThemeData } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi } from '../utils';
import { JGraphTheme } from '../types';
import { createThemeInState, deleteThemeInState } from '..';

type DeleteThemePayload = {
  theme: JGraphTheme;
};
const deleteTheme = createAsyncThunk('JGraph/deleteTheme', async (deleteThemePayload: DeleteThemePayload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const theme = state.JGraphReducer.graph.themes.find(theme => theme.value === deleteThemePayload.theme.value);
  if (!theme) return;

  thunkAPI.dispatch(deleteThemeInState({ themeValue: theme.value }));

  const action = () => {
    return GraphApi.deleteTheme(userAccountId || accountId, projectShortName, {
      targetTheme: theme.value,
      files: theme.filenames.map(filename => ({
        filename,
        lastModification: state.JGraphReducer.graph.files[filename],
      })),
    });
  };

  const actionPromise = action();
  mainSave$.next({ type: 'delete', path: theme.value, action: () => actionPromise });
  return actionPromise;
});

export const revertDeleteTheme = createAsyncThunk(
  'JGraph/revertDeleteTheme',
  async (revertEvent: RevertEvent<DeleteThemePayload>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const themeStates = revertEvent.prevState.JGraphReducer.graph.blocks.filter(
      state => state.theme === revertEvent.payload.theme.value
    );

    thunkAPI.dispatch(createThemeInState({ theme: revertEvent.payload.theme }));

    const action = async () => {
      await GraphApi.createTheme(
        userAccountId || accountId,
        projectShortName,
        revertEvent.payload.theme.filenames.map<CreateJThemeData>(fileName => ({
          themeToCreate: {
            value: revertEvent.payload.theme.value,
            x: revertEvent.payload.theme.x,
            y: revertEvent.payload.theme.y,
          },
          states: themeStates.filter(state => state.filename === fileName),
          file: {
            filename: fileName,
            lastModification: state.JGraphReducer.graph.files[fileName] ?? 0,
          },
        }))
      );
      await thunkAPI.dispatch(getJGraphVisuals());
      await thunkAPI.dispatch(getGraph({}));
    };

    const actionPromise = action();
    mainSave$.next({ type: 'create', path: revertEvent.payload.theme.value, action: () => actionPromise });
    await actionPromise;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertDeleteTheme, deleteTheme);
