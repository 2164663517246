const prefix = '@projectReportFilter/';

export const FILTER = prefix + 'FILTER';
export const FILTER_PENDING = prefix + 'FILTER_PENDING';
export const FILTER_FULFILLED = prefix + 'FILTER_FULFILLED';
export const FILTER_REJECTED = prefix + 'FILTER_REJECTED';

export const FETCH_STATISTICS = prefix + 'FETCH_STATISTICS';
export const FETCH_STATISTICS_PENDING = prefix + 'FETCH_STATISTICS_PENDING';
export const FETCH_STATISTICS_FULFILLED = prefix + 'FETCH_STATISTICS_FULFILLED';
export const FETCH_STATISTICS_REJECTED = prefix + 'FETCH_STATISTICS_REJECTED';

export const FETCH_CHANNELS = prefix + 'FETCH_CHANNELS';
export const FETCH_CHANNELS_PENDING = prefix + 'FETCH_CHANNELS_PENDING';
export const FETCH_CHANNELS_FULFILLED = prefix + 'FETCH_CHANNELS_FULFILLED';
export const FETCH_CHANNELS_REJECTED = prefix + 'FETCH_CHANNELS_REJECTED';

export const SAVE_REPORT_PARAMETERS = prefix + 'SAVE_REPORT_PARAMETERS';
export const SAVE_REPORT_PARAMETERS_PENDING = prefix + 'SAVE_REPORT_PARAMETERS_PENDING';
export const SAVE_REPORT_PARAMETERS_FULFILLED = prefix + 'SAVE_REPORT_PARAMETERS_FULFILLED';
export const SAVE_REPORT_PARAMETERS_REJECTED = prefix + 'SAVE_REPORT_PARAMETERS_REJECTED';

export const GET_TELEPHONY_REPORT_DETAIL = prefix + 'GET_TELEPHONY_REPORT_DETAIL';
export const GET_TELEPHONY_REPORT_DETAIL_PENDING = prefix + 'GET_TELEPHONY_REPORT_DETAIL_PENDING';
export const GET_TELEPHONY_REPORT_DETAIL_FULFILLED = prefix + 'GET_TELEPHONY_REPORT_DETAIL_FULFILLED';
export const GET_TELEPHONY_REPORT_DETAIL_REJECTED = prefix + 'GET_TELEPHONY_REPORT_DETAIL_REJECTED';

export const DATE_RANGE_CHANGED = prefix + 'DATE_RANGE_CHANGED';
export const SELECT_CHANNELS = prefix + 'SELECT_CHANNELS';
export const DROP_FILTER = prefix + 'DROP_FILTER';
export const UPDATE_FILTER_PARAMS = prefix + 'UPDATE_FILTER_PARAMS';
export const SET_CHANNELS_AS_FETCHED = prefix + 'SET_CHANNELS_AS_FETCHED';

export const FETCH_MESSAGE_STATISTICS = prefix + 'FETCH_MESSAGE_STATISTICS';
export const FETCH_MESSAGE_STATISTICS_PENDING = prefix + 'FETCH_MESSAGE_STATISTICS_PENDING';
export const FETCH_MESSAGE_STATISTICS_FULFILLED = prefix + 'FETCH_MESSAGE_STATISTICS_FULFILLED';
export const FETCH_MESSAGE_STATISTICS_REJECTED = prefix + 'FETCH_MESSAGE_STATISTICS_REJECTED';

export const FETCH_SESSION_STATISTICS = prefix + 'FETCH_SESSION_STATISTICS';
export const FETCH_SESSION_STATISTICS_PENDING = prefix + 'FETCH_SESSION_STATISTICS_PENDING';
export const FETCH_SESSION_STATISTICS_FULFILLED = prefix + 'FETCH_SESSION_STATISTICS_FULFILLED';
export const FETCH_SESSION_STATISTICS_REJECTED = prefix + 'FETCH_SESSION_STATISTICS_REJECTED';

export const FETCH_UNIQ_USERS_STATISTICS = prefix + 'FETCH_UNIQ_USERS_STATISTICS';
export const FETCH_UNIQ_USERS_STATISTICS_PENDING = prefix + 'FETCH_UNIQ_USERS_STATISTICS_PENDING';
export const FETCH_UNIQ_USERS_STATISTICS_FULFILLED = prefix + 'FETCH_UNIQ_USERS_STATISTICS_FULFILLED';
export const FETCH_UNIQ_USERS_STATISTICS_REJECTED = prefix + 'FETCH_UNIQ_USERS_STATISTICS_REJECTED';

export const FETCH_DIALOGS_STATISTICS = prefix + 'FETCH_DIALOGS_STATISTICS';
export const FETCH_DIALOGS_STATISTICS_PENDING = prefix + 'FETCH_DIALOGS_STATISTICS_PENDING';
export const FETCH_DIALOGS_STATISTICS_FULFILLED = prefix + 'FETCH_DIALOGS_STATISTICS_FULFILLED';
export const FETCH_DIALOGS_STATISTICS_REJECTED = prefix + 'FETCH_DIALOGS_STATISTICS_REJECTED';

export const FETCH_SWITCHED_STATISTICS = prefix + 'FETCH_SWITCHED_STATISTICS';
export const FETCH_SWITCHED_STATISTICS_PENDING = prefix + 'FETCH_SWITCHED_STATISTICS_PENDING';
export const FETCH_SWITCHED_STATISTICS_FULFILLED = prefix + 'FETCH_SWITCHED_STATISTICS_FULFILLED';
export const FETCH_SWITCHED_STATISTICS_REJECTED = prefix + 'FETCH_SWITCHED_STATISTICS_REJECTED';

export const FETCH_UNRECOGNIZED_STATISTICS = prefix + 'FETCH_UNRECOGNIZED_STATISTICS';
export const FETCH_UNRECOGNIZED_STATISTICS_PENDING = prefix + 'FETCH_UNRECOGNIZED_STATISTICS_PENDING';
export const FETCH_UNRECOGNIZED_STATISTICS_FULFILLED = prefix + 'FETCH_UNRECOGNIZED_STATISTICS_FULFILLED';
export const FETCH_UNRECOGNIZED_STATISTICS_REJECTED = prefix + 'FETCH_UNRECOGNIZED_STATISTICS_REJECTED';

export const FETCH_USER_MESSAGE_STATISTICS = prefix + 'FETCH_USER_MESSAGE_STATISTICS';
export const FETCH_USER_MESSAGE_STATISTICS_PENDING = prefix + 'FETCH_USER_MESSAGE_STATISTICS_PENDING';
export const FETCH_USER_MESSAGE_STATISTICS_FULFILLED = prefix + 'FETCH_USER_MESSAGE_STATISTICS_FULFILLED';
export const FETCH_USER_MESSAGE_STATISTICS_REJECTED = prefix + 'FETCH_USER_MESSAGE_STATISTICS_REJECTED';

export const FETCH_TELEPHONY_STATISTICS = prefix + 'FETCH_TELEPHONY_STATISTICS';
export const FETCH_TELEPHONY_STATISTICS_PENDING = prefix + 'FETCH_TELEPHONY_STATISTICS_PENDING';
export const FETCH_TELEPHONY_STATISTICS_FULFILLED = prefix + 'FETCH_TELEPHONY_STATISTICS_FULFILLED';
export const FETCH_TELEPHONY_STATISTICS_REJECTED = prefix + 'FETCH_TELEPHONY_STATISTICS_REJECTED';
