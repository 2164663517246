import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService from 'modules/JGraph/services/JGraphRollbackService';
import { TJBlock } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { migrateTextToRandom } from '../index';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';

import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { revertAddNewBlockInReduxAndApi } from './addNewBlockInReduxAndApi';

export type MigrateBlockPayload = { block: TJBlock; prefix?: string; blockIndex?: number };

const migrateTextToRandomInReduxAndApi = createAsyncThunk(
  'JGraph/addNewBlockInReduxAndApi',
  async (payload: MigrateBlockPayload, thunkAPI) => {
    await Promise.resolve(thunkAPI.dispatch(migrateTextToRandom(payload)));
    const state = thunkAPI.getState() as RootState;
    let newBlocks = [...state.JGraphReducer.graph.blocks];
    if (!state.JGraphReducer.editMenuBlock?.screen) return;
    const editBlock = findScreenByPathId(state.JGraphReducer.editMenuBlock!.screen.pathId, newBlocks);
    if (!editBlock) return;

    mainSave$.next({
      type: 'update',
      path: editBlock.path,
      action: () => thunkAPI.dispatch(updateState(editBlock)),
    });
    return { affectedScreen: editBlock };
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertAddNewBlockInReduxAndApi,
  migrateTextToRandomInReduxAndApi
);
