import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'storeTypes';

import { GraphApi } from 'reducers/JGraph.reducer/utils';

type getGraphParamsType = {
  context?: string;
  options?: any;
};

const getGraphLocators = createAsyncThunk(
  'JGraph/getGraphLocators',
  async (getGraphParams: getGraphParamsType, thunkAPI) => {
    const { options } = getGraphParams;
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const { data: graphLocators } = await GraphApi.getTagLocators(
      userAccountId || accountId,
      projectShortName,
      options
    );
    return graphLocators;
  }
);

export default getGraphLocators;
