import React, { useCallback, useMemo, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Placement, offset, flip, ComputePositionConfig } from '@floating-ui/react-dom';
import { ClickAwayListener, useBehaviorSubject } from '@just-ai/just-ui';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';

type KonvaTooltipAreaPayload = {
  id: string;
  content: React.ReactNode;
  target: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  platform?: HTMLElement;
  mainAxisOffset?: number;
  crossAxisOffset?: number;
  alignmentAxisOffset?: number | null;
  placement?: Placement;
};
export const KonvaTooltipArea$ = new BehaviorSubject<KonvaTooltipAreaPayload | null>(null);

const KonvaTooltipArea = () => {
  const tooltipInfo = useBehaviorSubject(KonvaTooltipArea$);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useFloaterPosition({
    enable: !!tooltipInfo?.content,
    floaterElement: wrapperRef,
    target: tooltipInfo?.target ?? null,
    options: useMemo(() => {
      if (!tooltipInfo) return {};
      return {
        strategy: 'absolute',
        placement: tooltipInfo.placement,
        middleware: [
          offset({
            mainAxis: tooltipInfo.mainAxisOffset ?? 0,
            crossAxis: tooltipInfo.crossAxisOffset ?? 0,
            alignmentAxis: tooltipInfo.alignmentAxisOffset ?? 0,
          }),
          flip({
            boundary: document.getElementById('jgraph-wrapper') || document.body,
          }),
        ],
      } as Partial<ComputePositionConfig>;
    }, [tooltipInfo]),
  });

  const onClickOutside = useCallback(() => {
    KonvaTooltipArea$.next(null);
  }, []);

  if (!tooltipInfo?.content) return null;
  return (
    <ClickAwayListener handleClickOut={onClickOutside}>
      <div ref={wrapperRef} id='KonvaTooltipArea' style={{ display: 'inline-block', zIndex: 10 }}>
        {tooltipInfo.content}
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(KonvaTooltipArea);
