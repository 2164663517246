export const FETCH_LIVECHAT_CHANNELS = 'FETCH_LIVECHAT_CHANNELS';
export const FETCH_LIVECHAT_CHANNELS_PENDING = 'FETCH_LIVECHAT_CHANNELS_PENDING';
export const FETCH_LIVECHAT_CHANNELS_FULFILLED = 'FETCH_LIVECHAT_CHANNELS_FULFILLED';
export const FETCH_LIVECHAT_CHANNELS_REJECTED = 'FETCH_LIVECHAT_CHANNELS_REJECTED';

export const SET_EDITABLE_LIVECHAT_CHANNEL = 'SET_EDITABLE_LIVECHAT_CHANNEL';
export const CLEAR_EDITABLE_LIVECHAT_CHANNEL = 'CLEAR_EDITABLE_LIVECHAT_CHANNEL';
export const UPDATE_EDITABLE_LIVECHAT_CHANNEL = 'UPDATE_EDITABLE_LIVECHAT_CHANNEL';

export const SAVE_EDITABLE_LIVECHAT_CHANNEL = 'SAVE_EDITABLE_LIVECHAT_CHANNEL';
export const SAVE_EDITABLE_LIVECHAT_CHANNEL_PENDING = 'SAVE_EDITABLE_LIVECHAT_CHANNEL_PENDING';
export const SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED = 'SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED';
export const SAVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED = 'SAVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED';

export const CREATE_LIVECHAT_CHANNEL = 'CREATE_LIVECHAT_CHANNEL';
export const CREATE_LIVECHAT_CHANNEL_PENDING = 'CREATE_LIVECHAT_CHANNEL_PENDING';
export const CREATE_LIVECHAT_CHANNEL_FULFILLED = 'CREATE_LIVECHAT_CHANNEL_FULFILLED';
export const CREATE_LIVECHAT_CHANNEL_REJECTED = 'CREATE_LIVECHAT_CHANNEL_REJECTED';

export const REMOVE_EDITABLE_LIVECHAT_CHANNEL = 'REMOVE_EDITABLE_LIVECHAT_CHANNEL';
export const REMOVE_EDITABLE_LIVECHAT_CHANNEL_PENDING = 'REMOVE_EDITABLE_LIVECHAT_CHANNEL_PENDING';
export const REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED = 'REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED';
export const REMOVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED = 'REMOVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED';

export const SHOW_LIVECHAT_CHANNEL_VALIDATION_ERRORS = 'SHOW_LIVECHAT_CHANNEL_VALIDATION_ERRORS';
export const HIDE_LIVECHAT_CHANNEL_VALIDATION_ERRORS = 'HIDE_LIVECHAT_CHANNEL_VALIDATION_ERRORS';

export const CLEAR_VALIDATE_TOKEN_ERROR = 'CLEAR_VALIDATE_TOKEN_ERROR';
export const VALIDATE_LIVECHAT_TOKEN = 'VALIDATE_LIVECHAT_TOKEN';
export const VALIDATE_LIVECHAT_TOKEN_PENDING = 'VALIDATE_LIVECHAT_TOKEN_PENDING';
export const VALIDATE_LIVECHAT_TOKEN_FULFILLED = 'VALIDATE_LIVECHAT_TOKEN_FULFILLED';
export const VALIDATE_LIVECHAT_TOKEN_REJECTED = 'VALIDATE_LIVECHAT_TOKEN_REJECTED';

export const CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT = 'CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT';

export const ADD_DESTINATION = 'ADD_DESTINATION';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const DELETE_IDS_DESTINATION = 'DELETE_IDS_DESTINATION';
export const ADD_DESTINATION_IDS = 'ADD_DESTINATION_IDS';
export const SELECT_DESTINATION_IDS = 'SELECT_DESTINATION_IDS ';

export const FETCH_I_JIVOSITE_WEBHOOK_URL = 'FETCH_I_JIVOSITE_WEBHOOK_URL';
export const FETCH_I_JIVOSITE_WEBHOOK_URL_PENDING = 'FETCH_I_JIVOSITE_WEBHOOK_URL_PENDING';
export const FETCH_I_JIVOSITE_WEBHOOK_URL_FULFILLED = 'FETCH_I_JIVOSITE_WEBHOOK_URL_FULFILLED';
export const FETCH_I_JIVOSITE_WEBHOOK_URL_REJECTED = 'FETCH_I_JIVOSITE_WEBHOOK_URL_REJECTED';
