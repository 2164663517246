export type GutterMarker = {
  className: string;
  condition: (lineText: string) => boolean;
  onClick: (lineText: string, lineNumber: number) => void;
  tooltipHandler?: (props: { isOpen: boolean; node: HTMLElement }) => void;
};

export type AceGutterLine = {
  element: HTMLElement;
  text: string;
  row: number;
};

export type GutterMarkerMatch = {
  className: string;
  rowNumber: number;
  text: string;
};

export type GutterRenderedMatch = {
  node: HTMLElement;
};

export const tags = [
  'patterns',
  'fake-pattern',
  'require',
  'theme',
  'init',
  'state',
  'e!',
  'e',
  'eg!',
  'eg',
  'q!',
  'q',
  'intent!',
  'intent',
  'event!',
  'event',
  'script',
  'image',
  'a',
  'audio',
  'video',
  'buttons',
  'inlineButtons',
  'timeout',
  'if',
  'else',
  'elseif',
  'go!',
  'go',
  'random',
  'newSession',
  'customTag',
];
