import { axios } from '../../../pipes/functions';
import { FiltersDto, ReportsApi } from '../api';

export default class EventReportService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private language: string;
  private configsApi: ReportsApi;

  constructor(accountId: number, language: string) {
    this.accountId = accountId;
    this.language = language === 'eng' ? 'en' : language;
    this.configsApi = new ReportsApi(undefined, EventReportService.BASE_PATH, axios);
  }

  getAccountEventsByFilters = async (filtersDto?: FiltersDto) =>
    await this.configsApi.getAccountEventReport(this.accountId, this.language, filtersDto, {
      responseType: 'arraybuffer',
    });
}
