import React, { useState, useMemo, ForwardedRef } from 'react';
import Konva from 'konva';
import { Text } from 'react-konva';

import KonvaTooltip from 'modules/JGraph/view/Sticker/KonvaTooltip';
import Hint from 'modules/JGraph/view/Hint';

interface AccessibleTextProps extends Konva.TextConfig {}

const AccessibleText = React.forwardRef((props: AccessibleTextProps, ref: ForwardedRef<any>) => {
  const [textRef, setTextRef] = useState<Konva.Text | null>(null);

  const isNeedToShowTooltip = useMemo(() => {
    return textRef?.measureSize(props.text).width > textRef?.getWidth();
  }, [props.text, textRef]);

  return (
    <>
      <Text
        ref={innerRef => {
          setTextRef(innerRef);
          if (!ref) return;
          if (typeof ref === 'function') {
            ref(innerRef);
          } else {
            ref.current = innerRef;
          }
        }}
        {...props}
      />
      {isNeedToShowTooltip && (
        <KonvaTooltip target={textRef} trigger='hover' debounceTime={800} placement='bottom-start' mainAxisOffset={8}>
          <Hint>{props.text}</Hint>
        </KonvaTooltip>
      )}
    </>
  );
});

AccessibleText.displayName = 'AccessibleText';

export default React.memo(AccessibleText);
