import { useState, useMemo, useCallback } from 'react';

import { useAppSelector } from 'storeHooks';

import { getAllStates } from 'reducers/JGraph.reducer/Graph';

import { joinPaths } from './stageUtils';
import { stateNameValidation } from './validators/stateName';

export default function useNewStateNameValidation() {
  const { blocks, themes } = useAppSelector(state => ({
    blocks: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
  }));
  const [errorText, setErrorText] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const allStates = useMemo(() => getAllStates(blocks), [blocks]);
  const allThemesNames = useMemo(() => themes.map(el => el.value), [themes]);

  const triggerValidation = useCallback(
    (name: string, parentPath = '/', renameStatePath?: string) => {
      const stateValue = name.replace(/\/+/g, '/').trim();

      return stateNameValidation(
        { name: stateValue },
        {
          allStates: allStates.filter(el => !renameStatePath || el !== joinPaths(renameStatePath, parentPath)),
          parentPath,
          existedThemes: allThemesNames,
        }
      ).then(validationResult => {
        setErrorText(validationResult.errors?.name?.message || '');
        setIsInvalid(!validationResult.isValid);
        return validationResult;
      });
    },
    [allStates, allThemesNames]
  );

  const reset = useCallback(() => {
    setErrorText('');
    setIsInvalid(false);
  }, []);

  return { isInvalid, errorText, triggerValidation, reset };
}
