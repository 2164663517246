export const projecteditformLocalization = {
  'ProjectEditForm Generate password': {
    eng: 'Generate',
    ru: 'Сгенерировать',
    cn: '生成',
  },
  'ProjectEditForm Secret key': {
    eng: 'Secret key',
    ru: 'Ключ',
    cn: '密钥',
  },
  'ProjectEditForm copy justAiKey': {
    eng: 'Copy',
    ru: 'Скопировать',
    cn: '复制',
  },
  'ProjectEditForm instruction': {
    eng: 'What is a private key?',
    ru: 'Что такое приватный ключ?',
    cn: '什么是私钥？',
  },
  'ProjectEditForm tabs name': {
    eng: 'Name',
    ru: 'Наименование',
    cn: '名称',
  },
  'ProjectEditForm tabs placement': {
    eng: 'Location',
    ru: 'Размещение',
    cn: '位置',
  },
  'ProjectEditForm tabs classifier': {
    eng: 'Classifier',
    ru: 'Классификатор',
    cn: '分类器;',
  },
  'ProjectEditForm shared project': {
    eng: 'Shared project',
    ru: 'Общий проект',
    cn: '共享项目',
  },
  'ProjectEditForm tabs NLU settings': {
    eng: 'NLU settings',
    ru: 'Настройки NLU',
    cn: 'NLU设置',
  },
  'ProjectEditForm tabs API tokens': {
    eng: 'API tokens',
    ru: 'API-токены',
  },
  'ProjectEditForm tabs analytic api': {
    eng: 'Analytics API',
    ru: 'Analytics API',
  },
  'ProjectEditForm tabs other': {
    eng: 'Miscellaneous',
    ru: 'Прочее',
    cn: '其他',
  },
  'ProjectEditForm helpLink text': {
    eng: 'Help',
    ru: 'Справка',
    cn: '帮助',
  },
  'ProjectEditForm:GithubTokenWarning.text': {
    eng: 'Use a personal access token instead of a password',
    ru: 'Используйте в качестве пароля персональный токен',
    cn: 'Use a personal access token instead of a password',
  },
  'ProjectEditForm:GithubTokenWarning.linkLabel': {
    eng: 'How to get a personal access token',
    ru: 'Инструкция по получению токена',
    cn: 'How to get a personal access token',
  },
  'ProjectEditForm:similarsHeading': {
    eng: 'Search for matches',
    ru: 'Поиск совпадений',
  },
  'ProjectEditForm:similarsPhrasesLabel': {
    eng: 'In intent phrases and knowledge base questions',
    ru: 'Во фразах интентов и вопросах базы знаний',
  },
  'ProjectEditForm:similarsAnswersLabel': {
    eng: 'In intent and knowledge base answers',
    ru: 'В ответах интентов и базы знаний',
  },
  'ProjectEditForm:similarsTooltip': {
    eng: 'Determine the percentage of acceptable matches. \n This needs a pre-trained classifier to work.',
    ru: 'Установите, на какое значение допустимы совпадения. \n Поиск работает только на обученном классификаторе.',
  },
};
