import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Tooltip, ChannelIcon } from '../../index';
import cn from 'classnames';
import { useSupportAudioInfo } from './useSupportAudioInfo';
import { useTranslation } from '../../contexts/transtationContext';
const channelTypeMap = {
    ALISA: 'YANDEX',
    GOOGLE_ASSISTANCE: 'GOOGLE',
    OUTGOING_CALLS: 'telephony',
    MESSENGERS: 'messengers',
};
const SupportChannelIcon = React.memo(({ channel, compatibleFlag }) => {
    const type = channel in channelTypeMap ? channelTypeMap[channel] : channel;
    const { t } = useTranslation();
    if (compatibleFlag) {
        if (type === 'messengers') {
            return (_jsxs(_Fragment, { children: [_jsx(ChannelIcon, { size: 'medium', type: 'chat_widget' }), _jsx("span", { id: `compatibleChannel-${type}-${channel}`, children: _jsx(ChannelIcon, { size: 'medium', type: 'messengers' }) }), _jsx(Tooltip, { placement: 'top', target: `compatibleChannel-${type}-${channel}`, textAlign: 'left', children: t(`Player renderCompatibleChannels ${channel}`)
                            .split('\n')
                            .map((text) => (_jsx("div", { children: text }, text))) })] }));
        }
        return _jsx(ChannelIcon, { size: 'medium', type: type });
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { id: `incompatibleChannel-${type}-${channel}`, children: _jsx(ChannelIcon, { size: 'medium', type: type }) }), _jsx(Tooltip, { placement: 'top', target: `incompatibleChannel-${type}-${channel}`, textAlign: 'left', children: _jsx(_Fragment, { children: t(`Player renderIncompatibleChannels ${channel}`)
                        .split('\n')
                        .map((text) => (_jsx("div", { children: text }, text))) }) })] }));
});
const SupportAudioChannels = function ({ fileUrl, className }) {
    const supportInfo = useSupportAudioInfo(fileUrl);
    const { t } = useTranslation();
    if (supportInfo.incompatible.length === 0 && supportInfo.compatible.length === 0)
        return null;
    return (_jsxs("div", { className: cn('flex-column gap-4x', className), children: [supportInfo.compatible.length && (_jsxs("div", { className: 'flex-column gap-2x supported-channels-block', children: [_jsx("div", { children: t('AudioSettings:compatibleChannelsTitle') }), _jsx("div", { className: 'flex-row gap-1x', children: supportInfo.compatible.map(channel => (_jsx(SupportChannelIcon, { channel: channel, compatibleFlag: true }, channel))) })] })), supportInfo.incompatible.length && (_jsxs("div", { className: 'flex-column gap-2x unsupported-channels-block', children: [_jsx("div", { children: t('AudioSettings:incompatibleChannelsTitle') }), _jsx("div", { className: 'flex-row gap-1x', children: supportInfo.incompatible.map(channel => (_jsx(SupportChannelIcon, { channel: channel }, channel))) })] }))] }));
};
export default React.memo(SupportAudioChannels);
