import React, { useMemo, useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from 'lodash';

import { useAppSelector, useAppDispatch } from 'storeHooks';

import { useLocalStorageState, objectSerializer } from 'utils/hooks/useLocalStorageState';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { setEditMenuBlock } from 'reducers/JGraph.reducer';

import { LabelingService } from 'modules/JGraph/view/LabelingToolMenu/LabelingService';
import { getThemeName } from 'modules/JGraph/utils/themesUtils';
import { TreeStructure } from 'components/Tree/TreeStructure';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { ScreenLabel } from 'modules/JGraph/view/LabelingToolMenu/types';

import { TreeDataset, TreeNode } from 'components/Tree/types';

export type HierarchyTreeNode = TreeNode & {
  nodeType: 'theme' | 'state';
  path: string;
  screenLabel?: ScreenLabel;
};

function transformStateToTreeNode(state: JStateWithId, parentId?: string): HierarchyTreeNode[] {
  const children = state.states
    ? Object.values(state.states).flatMap(childState => transformStateToTreeNode(childState, state.pathId))
    : [];

  const node: HierarchyTreeNode = {
    id: state.pathId,
    name: state.value,
    nodeId: state.pathId,
    path: state.path,
    enabled: true,
    parentId: parentId ?? '',
    isFolder: children.length > 0,
    isInRoot: false,
    nodeType: 'state',
    screenLabel: LabelingService.getLabelByScreen(state),
  };

  return [node, ...children];
}

export function useHierarchyModel() {
  const { themes, states, projectShortName, accountId, selectedTheme, selectedScreen, isDebugRunning } = useAppSelector(
    store => ({
      projectShortName: store.CurrentProjectsReducer.currentProject,
      accountId: store.CurrentAccountReducer.account?.id,
      states: store.JGraphReducer.graph.blocks,
      themes: store.JGraphReducer.graph.themes,
      selectedTheme: store.JGraphReducer.selectedTheme,
      selectedScreen: store.JGraphReducer.editMenuBlock?.screen,
      isDebugRunning: store.JGraphReducer.tagLocatorsLoaded,
    })
  );

  const treeStructure = useMemo(() => {
    const themesValues = new Set(Object.values(themes).map(theme => theme.value));
    const groupedStatesByThemes = groupBy(states, state => {
      if (!themesValues.has(state.theme)) return '';
      return state.theme;
    }) as Record<string, JStateWithId[]>;

    const dataset: TreeDataset<HierarchyTreeNode> = [];
    for (const theme of themes) {
      const themeScreens = groupedStatesByThemes[theme.value] ?? [];
      dataset.push({
        id: theme.pathId,
        name: getThemeName(theme.value),
        nodeId: theme.pathId,
        path: theme.value,
        enabled: true,
        parentId: '',
        isFolder: themeScreens.length > 0,
        isInRoot: true,
        nodeType: 'theme',
      });

      const children = themeScreens.flatMap(state => transformStateToTreeNode(state, theme.pathId)) ?? [];
      for (const node of children) {
        dataset.push(node);
      }
    }

    return new TreeStructure(dataset, 20, {
      sort: (a, b) => {
        if (a.nodeType === 'state' && b.nodeType === 'state') return 0;
        if (a.nodeType === 'theme' && b.nodeType === 'theme') {
          if (a.path === '/') return -1;
          if (b.path === '/') return 1;
          const aPathLength = a.path.split('/').length;
          const bPathLength = b.path.split('/').length;
          if (aPathLength === bPathLength) return a.name.localeCompare(b.name);
          return aPathLength - bPathLength;
        }
        return a.name.localeCompare(b.name);
      },
    });
  }, [states, themes]);

  const [expandedNodesMap, setExpandedNodesMap] = useLocalStorageState<Record<string, boolean>>(
    {},
    `JG.Hierarchy.expandedNodesMap.${projectShortName}.${accountId}`,
    true,
    objectSerializer
  );

  const dispatch = useAppDispatch();
  const selectNodeInCanvas = useCallback(
    (node: HierarchyTreeNode) => {
      switch (node.nodeType) {
        case 'state':
          scrollToTargetGlobal$.next({
            targetPathId: node.nodeId,
            type: 'state',
          });
          const targetState = findScreenByPathId(node.nodeId, states);
          dispatch(setEditMenuBlock({ screen: targetState, jBlockIndex: undefined, path: undefined }));
          break;
        case 'theme':
          scrollToTargetGlobal$.next({
            targetPathId: node.nodeId,
            type: 'theme',
          });
          break;
      }
    },
    [dispatch, states]
  );

  const onKeyDownOnNode = useCallback(
    (node: HierarchyTreeNode, event?: KeyboardEvent) => {
      if (event?.key === 'Enter') {
        selectNodeInCanvas(node);
      }
    },
    [selectNodeInCanvas]
  );

  const activeId = isDebugRunning ? undefined : selectedScreen?.pathId ?? selectedTheme?.pathId;

  const openContextMenu$ = useMemo(
    () =>
      new BehaviorSubject<
        | {
            event: React.MouseEvent;
            node?: HierarchyTreeNode;
          }
        | undefined
      >(undefined),
    []
  );

  const openContextMenu = useCallback(
    (event: React.MouseEvent, node?: HierarchyTreeNode) => {
      openContextMenu$.next({ event, node });
      event.preventDefault();
    },
    [openContextMenu$]
  );

  return {
    treeStructure,
    selectNodeInCanvas,
    activeId,
    expandedNodesMap,
    setExpandedNodesMap,
    onKeyDownOnNode,
    openContextMenu,
    openContextMenu$,
  };
}
