import { CustomTagData, CustomTagParameterData, JGraphSticker } from '@just-ai/api/dist/generated/Editorbe';
import { getAllInnerStates, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';

import { tWithCheck } from 'localization';

import { PubSub } from 'services/PubSub';

import { isActivationToState } from '../utils/isActivationToState';
import { hideRootSlashInPath } from '../utils/state';
import { getThemeName } from '../utils/themesUtils';
import {
  getActivationsTagNames,
  ReactionsTagNamesWithElseif,
  TagNames,
  TJBlock,
  TReactionsTagNames,
  TTagParameters,
} from '../utils/types';

export enum SearchContentLocationType {
  STATE = 'STATE',
  THEME = 'THEME',
  STICKER = 'STICKER',
}
type SearchContentLocation = {
  type: SearchContentLocationType;
  id: string;
  path: string;
  theme: string;
  label?: string;
};
export enum SearchContentType {
  STATE = 'STATE',
  STATE_LABEL = 'STATE_LABEL',
  THEME = 'THEME',
  STICKER = 'STICKER',
  PATTERN = 'PATTERN',
  EVENT = 'EVENT',
  INTENT = 'INTENT',
  INTENT_GROUP = 'INTENT_GROUP',
  EXAMPLE = 'EXAMPLE',
  AUDIO = 'AUDIO',
  ANSWER = 'ANSWER',
  RANDOM = 'RANDOM',
  BUTTONS = 'BUTTONS',
  INLINE_BUTTONS = 'INLINE_BUTTONS',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  SCRIPT = 'SCRIPT',
  IF = 'IF',
  GO = 'GO',
  EMAIL = 'EMAIL',
  HTTP_REQUEST = 'HTTP_REQUEST',
  CUSTOM_TAG = 'CUSTOM_TAG',
}

const mapCustomTagsByName: Partial<Record<TagNames, SearchContentType>> = {
  [TagNames.Email]: SearchContentType.EMAIL,
  [TagNames.HttpRequest]: SearchContentType.HTTP_REQUEST,
};

export enum SearchGroupType {
  REACTION = 'REACTION',
  STATE = 'STATE',
  THEME = 'THEME',
  LABEL = 'LABEL',
  STICKER = 'STICKER',
  PHRASES_AND_EVENTS = 'PHRASES_AND_EVENTS',
  ACTIVATION = 'ACTIVATION',
}

type SearchIndex = {
  type: SearchContentType;
  groupType: SearchGroupType;
  label?: string;
  content: string;
  matchRule?: 'exact';
  location: SearchContentLocation;
};

type SearchMatch = { from: number; to: number; text: string };
export interface SearchResult extends SearchIndex {
  matches: SearchMatch[];
}
export interface SearchResultInfo {
  results: SearchResult[];
  matches: number;
  searchTime: number;
}

export class JGSearch extends PubSub<{ indexChanged: void }> {
  public storedIndex: SearchIndex[] = [];

  static instance: JGSearch = new JGSearch();

  private mapPathsToTheme: Record<string, string | undefined> = {};
  private mapCustomTagsByName: Record<string, CustomTagData | undefined> = {};
  private language: string = 'eng';

  private constructor() {
    super();
  }

  public buildSearchIndex(
    themes: JGraphTheme[],
    screens: JStateWithId[],
    stickers: JGraphSticker[],
    customTags: CustomTagData[],
    language: string = 'eng'
  ): void {
    this.language = language;

    const flatScreens = screens.flatMap(screen => [screen, ...getAllInnerStates(screen)]);
    this.buildMapPathsToTheme(flatScreens, themes);
    this.buildMapCustomTagsByName(customTags);

    this.storedIndex = [
      ...this.buildIndexForThemes(themes),
      ...this.buildIndexForStates(flatScreens),
      ...this.buildIndexForStickers(stickers),
    ];
    this.notify('indexChanged');
  }

  private buildMapPathsToTheme(screens: JStateWithId[], themes: JGraphTheme[]) {
    for (const screen of screens) {
      this.mapPathsToTheme[screen.path] = screen.theme;
    }
    for (const theme of themes) {
      this.mapPathsToTheme[theme.value] = theme.value;
    }
  }

  private buildIndexForStates(screens: JStateWithId[]): SearchIndex[] {
    const contentList: SearchIndex[] = [];

    for (let screen of screens) {
      const location = {
        type: SearchContentLocationType.STATE,
        id: getValidKonvaName(screen.path),
        path: screen.path,
        theme: screen.theme,
      };
      contentList.push({
        type: SearchContentType.STATE,
        groupType: SearchGroupType.STATE,
        content: screen.value,
        location: location,
      });
      contentList.push({
        type: SearchContentType.STATE,
        groupType: SearchGroupType.STATE,
        label: screen.value,
        matchRule: 'exact',
        content: screen.path,
        location: location,
      });

      const label = (screen.parameters as TTagParameters[])?.find(el => el.name === 'sessionResult');
      if (label?.value) {
        contentList.push({
          type: SearchContentType.STATE_LABEL,
          groupType: SearchGroupType.LABEL,
          content: label.value,
          location: location,
        });
      }

      contentList.push(...this.parseStateBlocks(screen.blocks, location));
    }

    return contentList;
  }

  private buildIndexForThemes(themes: JGraphTheme[]): SearchIndex[] {
    return themes.flatMap(theme => {
      const contentList: SearchIndex[] = [];

      const translatedName = getThemeName(theme.value);
      const location = {
        type: SearchContentLocationType.THEME,
        id: getValidKonvaName(theme.value),
        path: theme.value,
        theme: theme.value,
      };

      contentList.push({
        type: SearchContentType.THEME,
        groupType: SearchGroupType.THEME,
        matchRule: 'exact',
        content: theme.value,
        location: location,
      });
      if (theme.value !== translatedName) {
        contentList.push({
          type: SearchContentType.THEME,
          groupType: SearchGroupType.THEME,
          content: translatedName,
          location: location,
        });
      }

      return contentList;
    });
  }

  private buildIndexForStickers(stickers: JGraphSticker[]): SearchIndex[] {
    return stickers
      .map(sticker => {
        const themePath = this.mapPathsToTheme[sticker.stagePath];
        if (!themePath) return null;
        return {
          type: SearchContentType.STICKER,
          groupType: SearchGroupType.STICKER,
          content: sticker.content,
          location: {
            type: SearchContentLocationType.STICKER,
            id: sticker.id,
            path: sticker.stagePath,
            theme: themePath,
          },
        } as SearchIndex;
      })
      .filter(Boolean) as SearchIndex[];
  }

  private parseStateBlocks(blocks: TJBlock[], location: SearchContentLocation): SearchIndex[] {
    const context = { location };
    const contentList: SearchIndex[] = [];

    for (let stateBlock of blocks) {
      const isReaction = ReactionsTagNamesWithElseif().includes(stateBlock.tagName as TReactionsTagNames);
      const isActivation = getActivationsTagNames().includes(stateBlock.tagName);
      const isToStateActivation = isActivation && isActivationToState(stateBlock);

      const groupType = isReaction
        ? SearchGroupType.REACTION
        : isToStateActivation
          ? SearchGroupType.PHRASES_AND_EVENTS
          : isActivation
            ? SearchGroupType.ACTIVATION
            : SearchGroupType.REACTION;

      if (!groupType) {
        console.warn('Unknown group type', stateBlock);
        continue;
      }

      const localContext = { ...context, groupType };

      switch (stateBlock.tagName) {
        case TagNames.q:
        case TagNames.q_:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.PATTERN, content: stateBlock.tagValue, ...localContext });
          contentList.push(
            ...this.parseBlockParameters(
              stateBlock.tagParameters,
              SearchContentType.PATTERN,
              ['fromState', 'toState'],
              localContext.location,
              localContext.groupType
            )
          );
          break;
        case TagNames.event:
        case TagNames.event_:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.EVENT, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.intent:
        case TagNames.intent_:
          if (!stateBlock.tagValue) break;
          contentList.push({
            type: SearchContentType.INTENT,
            content: hideRootSlashInPath(stateBlock.tagValue),
            ...localContext,
          });
          const translatedIntentName = tWithCheck(`ChooseReadyIntent ${stateBlock.tagValue}`);
          if (translatedIntentName) {
            contentList.push({ type: SearchContentType.INTENT, content: translatedIntentName, ...localContext });
          }
          break;
        case TagNames.intentGroup:
        case TagNames.intentGroup_:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.INTENT_GROUP, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.e:
        case TagNames.e_:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.EXAMPLE, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.audio:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.AUDIO, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.a:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.ANSWER, content: stateBlock.tagValue, ...localContext });
          contentList.push(
            ...this.parseBlockParameters(
              stateBlock.tagParameters,
              SearchContentType.ANSWER,
              ['html', 'tts', 'alexaVoice'],
              localContext.location,
              localContext.groupType
            )
          );
          break;
        case TagNames.random:
          contentList.push(...this.parseStateBlocks(stateBlock.jblocks, location));
          break;
        case TagNames.buttons:
          contentList.push(
            ...this.parseBlockParameters(
              stateBlock.tagParameters,
              SearchContentType.BUTTONS,
              ['name', 'url', 'transition'],
              localContext.location,
              localContext.groupType
            )
          );
          break;
        case TagNames.inlineButtons:
          contentList.push(
            ...this.parseBlockParameters(
              stateBlock.tagParameters,
              SearchContentType.INLINE_BUTTONS,
              ['name', 'url', 'transition'],
              localContext.location,
              localContext.groupType
            )
          );
          break;
        case TagNames.image:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.IMAGE, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.video:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.VIDEO, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.script:
        case TagNames.scriptEs6:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.SCRIPT, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.if:
        case TagNames.else:
        case TagNames.elseif:
          if (stateBlock.tagValue) {
            contentList.push({ type: SearchContentType.IF, content: stateBlock.tagValue, ...localContext });
          }
          contentList.push(...this.parseStateBlocks(stateBlock.jblocks, location));
          break;
        case TagNames.go:
        case TagNames.go_:
          if (!stateBlock.tagValue) break;
          contentList.push({ type: SearchContentType.GO, content: stateBlock.tagValue, ...localContext });
          break;
        case TagNames.Email:
        case TagNames.HttpRequest:
        case TagNames.InputNumber:
        default:
          if (!stateBlock.tagName) break;
          contentList.push(...this.parseCustomTag(stateBlock, localContext.location, localContext.groupType));
          break;
      }
    }
    return contentList;
  }

  private parseBlockParameters<TagName = TagNames>(
    parameters: TJBlock<TagName>['tagParameters'],
    searchType: SearchContentType,
    allowedNames: string[] | 'all' = 'all',
    location: SearchContentLocation,
    groupType: SearchGroupType
  ): SearchIndex[] {
    const contentList: SearchIndex[] = [];
    for (let param of parameters) {
      if (!param) continue;
      for (let [key, value] of Object.entries(param)) {
        if (!value || typeof value !== 'string') continue;
        if (allowedNames === 'all' || allowedNames.includes(key)) {
          contentList.push({ type: searchType, content: value, location, groupType });
        }
      }
    }
    return contentList;
  }

  private parseCustomTagParameters<TagName = TagNames>(
    parameters: TJBlock<TagName>['tagParameters'],
    descriptor: CustomTagData,
    searchType: SearchContentType,
    location: SearchContentLocation,
    groupType: SearchGroupType
  ): SearchIndex[] {
    const nameToPropertyMap =
      descriptor.parameters?.reduce(
        (acc, val) => {
          if (!val.name) return acc;
          acc[val.name] = val;
          return acc;
        },
        {} as Record<string, CustomTagParameterData>
      ) ?? {};

    const contentList: SearchIndex[] = [];
    for (let param of parameters) {
      if (!param || !param.name) continue;
      const property = nameToPropertyMap[param.name];
      if (!property || !property.localization) continue;
      const translatedName = property.localization[this.language] || property.localization?.eng;
      if (!translatedName) continue;
      contentList.push({ type: searchType, content: translatedName, location, groupType });
    }
    return contentList;
  }

  findMatches(index: SearchIndex, needle: string): SearchMatch[] {
    if (!needle) return [];

    if (index.matchRule === 'exact') {
      if (index.content.toLowerCase() !== needle.toLowerCase()) return [];
      return [{ from: 0, to: index.content.length + 1, text: index.content }];
    }

    const contentLowerCase = index.content.toLowerCase();
    const matches: SearchMatch[] = [];
    let lastIndex = contentLowerCase.indexOf(needle);
    while (lastIndex !== -1) {
      const to = lastIndex + needle.length;
      matches.push({ from: lastIndex, to: to, text: index.content.substring(lastIndex, to) });
      lastIndex = contentLowerCase.indexOf(needle, to);
    }
    return matches;
  }

  search(searchText: string, filters?: (SearchGroupType | string)[]): SearchResultInfo {
    const result: SearchResultInfo = { results: [], matches: 0, searchTime: 0 };
    if (!searchText) return result;
    searchText = searchText.toLowerCase();

    const timeStart = performance.now();

    const foundedSearchIndices = this.storedIndex
      .filter(row => !filters || filters.length === 0 || filters.includes(row.groupType))
      .reduce((acc, res) => {
        const matches = this.findMatches(res, searchText);
        if (matches.length > 0) acc.push({ ...res, matches });
        return acc;
      }, [] as SearchResult[]);

    result.results = foundedSearchIndices;
    result.matches = foundedSearchIndices.reduce((acc, res) => acc + res.matches.length, 0);
    result.searchTime = performance.now() - timeStart;

    return result;
  }

  private buildMapCustomTagsByName(customTags: CustomTagData[]) {
    for (let customTag of customTags) {
      if (!customTag.tagName) continue;
      this.mapCustomTagsByName[customTag.tagName] = customTag;
    }
  }

  private parseCustomTag(stateBlock: TJBlock, location: SearchContentLocation, groupType: SearchGroupType) {
    const contentList: SearchIndex[] = [];
    const localContext = { location, groupType };

    const searchType = mapCustomTagsByName[stateBlock.tagName] ?? SearchContentType.CUSTOM_TAG;
    contentList.push({ type: searchType, content: stateBlock.tagName, ...localContext });

    contentList.push(...this.parseBlockParameters(stateBlock.tagParameters, searchType, 'all', location, groupType));

    const customTag = this.mapCustomTagsByName[stateBlock.tagName];
    if (customTag) {
      const translatedCustomTagName = customTag?.caption?.[this.language] || customTag?.caption?.eng;
      if (translatedCustomTagName) {
        contentList.push({
          type: searchType,
          content: translatedCustomTagName,
          ...localContext,
        });
      }
      contentList.push(
        ...this.parseCustomTagParameters(
          stateBlock.tagParameters,
          customTag,
          searchType,
          location,
          SearchGroupType.REACTION
        )
      );
    }

    return contentList;
  }
}
