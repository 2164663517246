import { DomainOptions } from '@just-ai/api/src/generated/Accountsadmin';

type DomainsType = {
  [key: string]: DomainOptions;
};

class CloudDomainsService {
  private cloudDomains: DomainsType = {};

  setDomains(domains: DomainsType) {
    if (!domains) return;
    this.cloudDomains = domains;
  }

  getProductDomainData(product: string) {
    return this.cloudDomains[product];
  }

  getFullDomainPath(product: string) {
    return `${window.location.protocol}//${this.getProductDomainData(product)?.domain}`;
  }

  getSadminLogoutPath(redirectUrl: string) {
    return `${this.getFullDomainPath('cc')}/api/accountsadmin/c/authorization/change-account-and-redirect?forDomain=${
      this.getProductDomainData('cc')?.domain
    }&redirectUrl=${redirectUrl}`;
  }
}

export const cloudDomainsService = new CloudDomainsService();
