export const STATE_COMPLETIONS = [
  'a:',
  'go:',
  'go!:',
  'random:',
  'buttons:',
  'inlineButtons:',
  'image:',
  'audio:',
  'video:',
  'script:',
  'scriptEs6:',
  'q:',
  'q!:',
  'event:',
  'event!:',
  'intent:',
  'intent!:',
  'intentGroup:',
  'intentGroup!:',
];
export const RANDOM_COMPLETIONS = [
  'a:',
  'go:',
  'go!:',
  'random:',
  'buttons:',
  'inlineButtons:',
  'script:',
  'scriptEs6:',
];
export const THEME_COMPLETIONS = ['state:', 'init:'];
export const JS_COMPLETIONS = [
  'bind("preProcess", function($context) {}, "/", "");',
  'toPrettyString();',
  'log("");',
  'parseXml("");',
  'hasOperatorsOnline();',
  'capitalize("");',
  '$client.',
  '$context.',
  '$conversationApi.',
  '$injector.',
  '$parseTree.',
  '$request.',
  '$response.replies.push();',
  '$response.replies = $response.replies || []',
  '$session.',
  '$temp.',
  '$analytics.',
  '$caila.',
  '$dialer.',
  '$env.get("", "");',
  '$faq.',
  '$gpt.',
  '$imputer.',
  '$integration.',
  '$jsapi.',
  '$mail.',
  '$nlp.',
  '$prompter.pushReply();',
  '$pushgate.',
  '$secrets.get("", "");',
  '$reactions.',
  '$http.',
  '$aimychat.',
  '$entities',
];

export const ES6_UNSUPPORTED_BUILTINS_REGEX = /^\$(http)/i;

export const ES6_COMPLETIONS = JS_COMPLETIONS.filter(completion => !ES6_UNSUPPORTED_BUILTINS_REGEX.test(completion));

export const JS_$Http_COMPLETIONS = [
  '$http.check("HEAD", ["https://example.com"]);',
  '$http.checkUrls("HEAD", ["https://example.com"], true);',
  '$http.config({ url: { protocol: "https", host: "example.com", port: 443 }, timeout: 10000 });',
  '$http.query("https://example.com", {});',
  '$http.get("https://example.com", {});',
  '$http.post("https://example.com", {});',
  '$http.put("https://example.com", {});',
  '$http.delete("https://example.com", {});',
];
export const JS_$Context_COMPLETIONS = [
  '$context.client',
  '$context.injector',
  '$context.parseTree',
  '$context.request',
  '$context.response.replies.push();',
  '$context.response.replies = $context.response.replies || []',
  '$context.session',
  '$context.temp',
  '$context.currentState',
  '$context.contextPath',
  '$context.testContext',
  '$context.intent',
  '$context.entities',
  '$context.nluResults',
];
export const JS_$conversationApi_COMPLETIONS = [
  '$conversationApi.sendTextToClient("");',
  '$conversationApi.sendRepliesToClient([{type: "text", text: ""}]);',
  '$conversationApi.sendRepliesToClient([{type: "buttons", buttons: [{ "text": "button name" }]}]);',
  '$conversationApi.sendRepliesToClient([{type: "inlineButtons", buttons: [{ "text": "button name", "url": "https://example.com" }]}]);',
  '$conversationApi.sendRepliesToClient([{type: "image", "imageUrl": "http://..."}]);',
  '$conversationApi.sendRepliesToClient([{type: "raw", "body": {}, "method": "sendMessage"}]);',
  '$conversationApi.sendRepliesToClient([{type: "location", "lat": "", "lon": ""}]);',
  '$conversationApi.sendRepliesToClient([{type: "timeout", "interval": 10, "targetState": "/timedout"}]);',
  '$conversationApi.sendRepliesToClient([{type: "file", fileUrl: "https://example.com/file.xlsx", fileName: "file.xlsx", mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}]);',
  '$conversationApi.sendRepliesToClient([{type: "audio", audioUrl: "https://example.com/example.mp3", audioName: "Example"}]);',
  '$conversationApi.sendRepliesToClient([{type: "video", videoUrl: "https://example.com/video.mp4", videoName: "Example"}]);',
];

export const JS_$ParseTree_COMPLETIONS = [
  '$parseTree.tag',
  '$parseTree.pattern',
  '$parseTree.text',
  '$parseTree.words',
  '$parseTree.value',
];
export const JS_$Request_COMPLETIONS = [
  '$request.data',
  '$request.requestType',
  '$request.version',
  '$request.botId',
  '$request.channelBotId',
  '$request.accountId',
  '$request.channelType',
  '$request.channelUserId',
  '$request.questionId',
  '$request.query',
  '$request.rawRequest',
  '$request.language',
  '$request.userFrom',
];
export const JS_$Request_userFrom_COMPLETIONS = [
  '$request.userFrom.id',
  '$request.userFrom.firstName',
  '$request.userFrom.lastName',
];
export const JS_$Request_data_COMPLETIONS = [
  '$request.data.livechatStatus',
  '$request.data.JustWidgetRawParams',
  '$request.data.isTestChannel',
  '$request.data.requestHeaders',
];
export const JS_$Response_replies_push_COMPLETIONS = [
  `$response.replies.push({ type: "text", text: "", tts: "", markup: "plain" });`,
  `$response.replies.push({ type: "buttons", buttons: [{ text: "Button 1" }, { text: "Button 2" }] });`,
  `$response.replies.push({ type: "inlineButtons", buttons: [{ text: "", url: "https://example.com" }] });`,
  `$response.replies.push({ type: "image", imageUrl: "https://example.com/image.png", text: "" });`,
  `$response.replies.push({ type: "raw", body: {}, method: "" });`,
  `$response.replies.push({ type: "switch" });`,
  `$response.replies.push({ type: "location", lat: 0, lon: 0 });`,
  `$response.replies.push({ type: "timeout", interval: 10, targetState: "/" });`,
  `$response.replies.push({ type: "dtmf", timeout: 5000, max: 3 });`,
  `$response.replies.push({ type: "hangup" });`,
  `$response.replies.push({ type: "htmlResponse", updatedState: {}, suppressMic: true, url: "https://example.com" });`,
  `$response.replies.push({ type: "audio", audioUrl: "https://example.com/example.mp3", audioName: "" });`,
  `$response.replies.push({ type: "carousel", text: "", content: [{ title: "", btnText: "" }] });`,
  `$response.replies.push({ type: "file", fileName: "https://example.com/file.pdf", fileName: "", mimeType: "application/pdf" });`,
  `$response.replies.push({ type: "sms", text: "", destination: "", providerConfiguration: {} });`,
  `$response.replies.push({ type: "video", videoUrl: "https://example.com/video.mp4", videoName: "" });`,
];

export const JS_$Analytics_COMPLETIONS = [
  '$analytics.joinExperiment("");',
  '$analytics.setSessionResult("");',
  '$analytics.setSessionData("", "");',
  '$analytics.setSessionLabel("");',
  '$analytics.setMessageLabel("", "");',
  '$analytics.setComment("");',
  '$analytics.setTextCampaignResult("", 1);',
  '$analytics.setAutomationStatus(false);',
  '$analytics.setNps(5);',
  '$analytics.setScenarioAction("");',
  '$analytics.setSessionTopic("");',
];
export const JS_$Caila_COMPLETIONS = [
  '$caila.cdqaQuery("", "CDQA.");',
  '$caila.markup("");',
  '$caila.getEntity("");',
  '$caila.entitiesLookup("", true);',
  '$caila.simpleInference("");',
  '$caila.inference({});',
  '$caila.inflect("", []);',
  '$caila.conform("", 2);',
  '$caila.detectLanguage([]);',
  '$caila.checkVocabulary([{ word: "" }]);',
  '$caila.addClientEntityRecords("", [{ type: "", rule: [], value: "" }]);',
  '$caila.deleteClientEntityRecords("", [1]);',
  '$caila.getClientEntityRecords("");',
  '$caila.setClientEntityRecords("", [{ type: "", rule: [], value: "" }]);',
  '$caila.setClientEntityRecord("", { id: 1, type: "", rule: [], value: "" });',
  '$caila.setClientNerId("");',
  '$caila.clearClientNerId();',
];
export const JS_$Dialer_COMPLETIONS = [
  '$dialer.getAudioToken();',
  '$dialer.getCaller();',
  '$dialer.getCallRecordingFullUrl();',
  '$dialer.getCallRecordingPath();',
  '$dialer.getTransferStatus();',
  '$dialer.hangUp("");',
  '$dialer.isIncomingCall();',
  '$dialer.setNoInputTimeout(15000);',
  '$dialer.getAbonentTimezone();',
  '$dialer.getCallNotConnectedReason();',
  '$dialer.getCampaignSchedule();',
  '$dialer.getCampaignToken();',
  '$dialer.getDialHistory();',
  '$dialer.getDialSchedule();',
  '$dialer.getPayload();',
  '$dialer.redial({ startDateTime: new Date("2020-01-01T00:00:00") });',
  '$dialer.reportData("", "");',
  '$dialer.setCallResult("");',
  '$dialer.bargeInInterrupt(true);',
  '$dialer.bargeInResponse({ bargeIn: "", bargeInTrigger: "", noInterruptTime: 0 });',
  '$dialer.getBargeInIntentStatus();',
  '$dialer.getBargeInLabel();',
  '$dialer.getBargeInTransition();',
  '$dialer.isBargeInInterrupted();',
  '$dialer.getBargeInTransition();',
  '$dialer.getAsrProvider();',
  '$dialer.getTtsProvider();',
  '$dialer.getAsrConfig();',
  '$dialer.getTtsConfig();',
  '$dialer.setAsrConfig({});',
  '$dialer.setTtsConfig({});',
  '$dialer.continueSpeech();',
];
export const JS_$Faq_COMPLETIONS = ['$faq.getReplies("");', '$faq.pushReplies();'];
export const JS_$Gpt_COMPLETIONS = [
  '$gpt.createChatCompletion([{ role: "system", content: "" }, { role: "user", content: "" }]);',
];
export const JS_$Imputer_COMPLETIONS = ['$imputer.cacheAudio("", {});', '$imputer.generateAudioUrl("", {});'];
export const JS_$Integration_COMPLETIONS = [
  '$integration.googleSheets.readDataFromCells("", "", "", []);',
  '$integration.googleSheets.writeDataToCells("", "", "", []);',
  '$integration.googleSheets.writeDataToLine("", "", "", []);',
  '$integration.googleSheets.deleteRowOrColumn("", "", "", []);',
  '$integration.googleSheets.clearCellData("", "", "", []);',
  '$integration.customRequest("", "", "", {}, {});',
];
export const JS_$JsApi_COMPLETIONS = [
  '$jsapi.bind({ type: "preProcess", handler: function($context) {}, path: "/", name: "" });',
  '$jsapi.chatHistory();',
  '$jsapi.context();',
  '$jsapi.dateForZone("UTC", "dd.MM");',
  '$jsapi.startSession();',
  '$jsapi.stopSession();',
  '$jsapi.random();',
  '$jsapi.context();',
  '$jsapi.resolvePath("/", "/");',
  '$jsapi.timeForZone("UTC");',
  '$jsapi.version();',
  '$jsapi.getVoiceMessageAsrLang();',
  '$jsapi.setVoiceMessageAsrLang("");',
  '$jsapi.getStates();',
  '$jsapi.getCustomTags("myCustomTag");',
];
export const JS_$Mail_COMPLETIONS = [
  '$mail.config("smtp.example.com", 2525, "user@example.com", "qwerty", "bot@example.com");',
  '$mail.debug(true);',
  '$mail.send({ smtpHost: "", user: "", password: "", from: "", to: "", subject: "", content: "" });',
  '$mail.sendMessage("user@example.com", "subject", "body");',
];
export const JS_$Nlp_COMPLETIONS = [
  '$nlp.conform("", 2);',
  '$nlp.fixKeyboardLayout("");',
  '$nlp.inflect("", []);',
  '$nlp.match("", "/");',
  '$nlp.matchExamples("", []);',
  '$nlp.matchPatterns("", []);',
  '$nlp.parseMorph("");',
  '$nlp.setClass("/");',
];
export const JS_$Pushgate_COMPLETIONS = [
  '$pushgate.createEvent("2020-01-01T00:00:00", "myEvent", {});',
  '$pushgate.cancelEvent("");',
  '$pushgate.createPushback($request.channelType, $request.botId, $request.channelUserId, "myEvent", {});',
];
export const JS_$Reactions_COMPLETIONS = [
  '$reactions.answer("");',
  '$reactions.answer({ value: "", tts: "", html: "" });',
  '$reactions.audio("https://example.com/audio.wav");',
  '$reactions.audio({ name: "", value: "https://example.com/audio.wav" });',
  '$reactions.buttons("");',
  '$reactions.buttons(["Button 1", "Button 2"]);',
  '$reactions.buttons({ button: { text: "", request_contact: true } });',
  '$reactions.buttons({ text: "", transition: "/" });',
  '$reactions.buttons([{ text: "" }, { text: "", transition: "/" }]);',
  '$reactions.getClientTimezone();',
  '$reactions.inlineButtons({ text: "", callback_data: "" });',
  '$reactions.inlineButtons({ text: "", url: "" });',
  '$reactions.inlineButtons({ text: "", transition: "" });',
  '$reactions.location(0, 0);',
  '$reactions.random();',
  '$reactions.setClientTimezone("UTC");',
  '$reactions.timeout({interval: "5 seconds", targetState: "/"});',
  '$reactions.transition("/");',
  '$reactions.transition({ value: "/", deferred: true });',
  '$reactions.ttsWithVariables({});',
  '$reactions.video("https://example.com/video.mp4");',
  '$reactions.video({ name: "", value: "https://example.com/video.mp4" });',
];

export const JS_$aimychat_COMPLETIONS = ['$aimychat.findOperatorGroupByMainOperator("");'];
export const JS_$textCampaign_COMPLETIONS = ['$textCampaign.cancelClient();', '$textCampaign.postponeClient("");'];
export const JS_$storage_COMPLETIONS = [
  '$storage.getRequestDir();',
  '$storage.deleteRequestDir();',
  '$storage.getTempDir();',
  '$storage.clear();',
  '$storage.getAvailableSpace();',
];
