import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { WindowPane } from '@just-ai/just-ui';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { ParseErrorsArea } from './ParseErrorsArea';
import { useGlobalBottomPanelContext } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import './style.scss';
import classNames from 'classnames';

const { t } = i18nTranslation('Editor');

const BOUNDING_PLACE_ID = 'errorsPanelBoundingPlace';

export const ERRORS_PANEL_TYPE = 'errors';

export const ParseErrorsPanelPortal: FunctionComponent = () => {
  const { shownPanel, showPanel, hidePanel, togglePanel } = useGlobalBottomPanelContext();
  const bondingPlace = document.getElementById(BOUNDING_PLACE_ID);

  if (!bondingPlace) return null;
  return ReactDOM.createPortal(
    <WindowPane
      title={t('ParseErrorsPanel.title')}
      className={classNames('parse-errors-panel', { hidden: shownPanel !== ERRORS_PANEL_TYPE })}
      dark
      minimizable
      onMinimize={() => hidePanel(ERRORS_PANEL_TYPE)}
      control={{
        isShown: shownPanel === ERRORS_PANEL_TYPE,
        isMaximized: false,
        show: () => showPanel(ERRORS_PANEL_TYPE),
        hide: () => hidePanel(ERRORS_PANEL_TYPE),
        toggle: () => togglePanel(ERRORS_PANEL_TYPE),
        toggleMaximize: () => {},
      }}
    >
      <ParseErrorsArea />
    </WindowPane>,
    bondingPlace
  );
};

export const ParseErrorsPanelBoundingPlace: FunctionComponent = () => {
  const { shownPanel } = useGlobalBottomPanelContext();
  return <div id={BOUNDING_PLACE_ID} className={classNames({ hidden: shownPanel !== ERRORS_PANEL_TYPE })} />;
};
