export const knowledgeBaseLLMLocalization = {
  'KnowledgeBaseLLM:Title': {
    eng: '#{config.khub.productName}',
    ru: '#{config.khub.productName}',
  },
  'KnowledgeBaseLLM:Description': {
    eng: 'A knowledge base finds answers to user questions in the documents. Connect knowledge bases from #{config.khub.productName} to use them in your script.',
    ru: 'База знаний находит в документах ответы на вопросы пользователей. Подключите базы знаний из #{config.khub.productName}, чтобы использовать их в сценарии.',
  },
  'KnowledgeBaseLLM:Description:Link': {
    eng: 'Learn more about working with knowledge bases',
    ru: 'Подробнее о работе с базами знаний',
  },
  'KnowledgeBaseLLM:Link': {
    eng: '#{config.botadmin.helpUrl}/knowledge-base/knowledge-hub',
    ru: '#{config.botadmin.helpUrl}/knowledge-base/knowledge-hub',
  },
  'KnowledgeBaseLLM:Connect': {
    eng: 'Connect knowledge base',
    ru: 'Подключить базу знаний',
  },
  'KnowledgeBaseLLM:ConnectFromKhub': {
    eng: 'Create knowledge base in #{config.khub.productName}',
    ru: 'Создать базу знаний в #{config.khub.productName}',
  },
  'KnowledgeBaseLLM:NoIntegrations': {
    eng: 'Here you will see the knowledge bases connected to the project',
    ru: 'Здесь будут базы знаний, которые вы подключили к проекту',
  },
  'KhubIntegration:CreateIntegrationModal:Title': {
    eng: 'Connect knowledge base',
    ru: 'Подключить базу знаний',
  },
  'KhubIntegration:CreateIntegrationModal:Submit': {
    eng: 'Connect',
    ru: 'Подключить',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:apiKey': {
    eng: 'Add by API key',
    ru: 'Добавить по API-ключу',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase': {
    eng: 'Knowledge base from #{config.khub.productName}',
    ru: 'База знаний #{config.khub.productName}',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase:Placeholder': {
    eng: 'Select or add by API key',
    ru: 'Выберите или добавьте по API-ключу',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase:Hint': {
    eng: 'The list contains knowledge bases from <a href="#{config.khub.productUrl}/hubs" target="_blank">your account</a>. You can also connect a knowledge base by <a href="#{config.botadmin.helpUrl}/knowledge-base/connect-knowledge-hub" target="_blank">API key</a>',
    ru: 'Список содержит базы знаний из <a href="#{config.khub.productUrl}/hubs" target="_blank">вашего аккаунта</a>. Вы также можете подключить базу знаний по <a href="#{config.botadmin.helpUrl}/knowledge-base/connect-knowledge-hub" target="_blank">API-ключу</a>',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:Name': {
    eng: 'Connection name',
    ru: 'Название подключения',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:Name:Placeholder': {
    eng: 'For example: Knowledge_Hub_1',
    ru: 'Например: Knowledge_Hub_1',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:Name:Hint': {
    eng: 'A secret with the same name will be added to the <a href="#{config.botadmin.helpUrl}/script_development/secrets" target="_blank">“Secrets and variables”</a> section',
    ru: 'Токен с таким же названием будет добавлен в раздел <a href="#{config.botadmin.helpUrl}/script_development/secrets" target="_blank">«Токены и переменные»</a>',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:ApiKey': {
    eng: 'API key',
    ru: 'API-ключ',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:ApiKey:Placeholder': {
    eng: 'Enter API key',
    ru: 'Введите API-ключ',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:ApiKey:HintLoading': {
    eng: 'Searching for the knowledge base…',
    ru: 'Ищем базу знаний…',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:ApiKey:HintLoaded': {
    eng: 'Found: {name}',
    ru: 'Найдено: {name}',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:NeedToInsertCode': {
    eng: 'Add code to script',
    ru: 'Добавить код в сценарий',
  },
  'KhubIntegration:CreateIntegrationModal:Fields:NeedToInsertCode:Hint': {
    eng: '<a href="#{config.botadmin.helpUrl}/knowledge-base/call-knowledge-hub" target="_blank">How it works</a>',
    ru: ' <a href="#{config.botadmin.helpUrl}/knowledge-base/call-knowledge-hub" target="_blank">Как это работает</a>',
  },
  'KhubIntegration:Errors:RequiredField': {
    eng: 'Fill out this field',
    ru: 'Заполните поле',
  },
  'KhubIntegration:Errors:RequiredField:Name': {
    eng: 'Enter connection name',
    ru: 'Введите название подключения',
  },
  'KhubIntegration:Errors:MoreThen100Chars': {
    eng: 'The name must be no more than 100 characters long',
    ru: 'Длина названия — не более 100 символов',
  },
  'KhubIntegration:Errors:NamePattern': {
    eng: 'Use only Latin letters, numbers, and underscores',
    ru: 'Используйте только латинские буквы, цифры и нижнее подчеркивание',
  },
  'KhubIntegration:Errors:TokenAlreadyExists': {
    eng: 'A secret with the same name already exists in the “Secrets and variables” section',
    ru: 'Токен с таким названием уже существует в разделе «Токены и переменные»',
  },
  'KhubIntegration:GetByToken:Errors:invalidToken': {
    eng: 'This API key does not exist or an error occurred in #{config.khub.productName}',
    ru: 'Этот API-ключ не существует или возникла ошибка в #{config.khub.productName}',
  },
  'KhubIntegration:GetByToken:Errors:tokenExpired': {
    eng: 'API key expired. Create a new one in #{config.khub.productName}',
    ru: 'Истек срок действия API-ключа. Создайте новый в #{config.khub.productName}',
  },
  'KhubIntegration:IntegrationList:Col:Name': {
    eng: 'Name',
    ru: 'Название',
  },
  'KhubIntegration:IntegrationList:Col:UpdatedAt': {
    eng: 'Last update',
    ru: 'Последнее изменение',
  },
  'KhubIntegration:IntegrationList:Col:Status': {
    eng: 'Status',
    ru: 'Статус',
  },
  'KhubIntegration:IntegrationList:Delete': {
    eng: 'Delete connection',
    ru: 'Удалить подключение',
  },
  'KhubIntegration:KhubLink': {
    eng: '#{config.khub.productName}',
    ru: '#{config.khub.productName}',
  },
  'KhubIntegration:IntegrationList:CopyCode': {
    eng: 'Copy code',
    ru: 'Скопировать код',
  },
  'KhubIntegration:IntegrationList:CopyCode:Description': {
    eng: 'Code to call the knowledge base',
    ru: 'Код для вызова базы знаний',
  },
  'KhubIntegration:IntegrationList:CopyJGraph': {
    eng: 'Copy J-Graph step',
    ru: 'Скопировать шаг J-Graph',
  },
  'KhubIntegration:IntegrationList:CopyJGraph:Description': {
    eng: 'Step to call the knowledge base',
    ru: 'Шаг для вызова базы знаний',
  },
  'KhubIntegration:IntegrationList:Action:AddApiKey': {
    eng: 'Add API key',
    ru: 'Добавить API-ключ',
  },
  'KhubIntegration:IntegrationList:Action:ReplaceApiKey': {
    eng: 'Replace API key',
    ru: 'Заменить API-ключ',
  },
  'KhubIntegration:IntegrationList:Action:GoToKnowledgeBase': {
    eng: 'To knowledge base',
    ru: 'В базу знаний',
  },
  'KhubIntegration:Status:ACTIVE': {
    eng: 'Ready',
    ru: 'Готова к работе',
  },
  'KhubIntegration:Status:SECRET_NOT_FOUND': {
    eng: 'API key not found',
    ru: 'Не найден API-ключ',
  },
  'KhubIntegration:Status:CREATED': {
    eng: 'Not indexed',
    ru: 'Не проиндексирована',
  },
  'KhubIntegration:Status:TOKEN_NOT_VALID': {
    eng: 'API key expired or does not exist',
    ru: 'API-ключ истек или не существует',
  },
  'KhubIntegration:Status:INGESTING_DOCUMENTS': {
    eng: 'Indexing in progress',
    ru: 'Идет индексация',
  },
  'KhubIntegration:Status:INGEST_FAILED': {
    eng: 'Indexing error',
    ru: 'Ошибка индексации',
  },
  'KhubIntegration:Status:DELETED': {
    eng: 'Knowledge base not found',
    ru: 'База знаний не найдена',
  },
  // 'KhubIntegration:Status:noSources': {
  //   eng: 'No sources',
  //   ru: 'Нет источников',
  // },
  // 'KhubIntegration:Status:expiredApiKey': {
  //   eng: 'API key expired',
  //   ru: 'Срок жизни токена доступа истёк',
  // },
  'KhubIntegration:AddExistedTokenByNameModal:Title': {
    eng: 'Add API key',
    ru: 'Добавить API-ключ',
  },
  'KhubIntegration:AddExistedTokenByNameModal:FieldLabel': {
    eng: 'Value',
    ru: 'Значение',
  },
  'KhubIntegration:AddExistedTokenByNameModal:FieldPlaceholder': {
    eng: 'Enter new API key',
    ru: 'Введите новый API-ключ',
  },
  'KhubIntegration:AddExistedTokenByNameModal:Submit': {
    eng: 'Add',
    ru: 'Добавить',
  },
  'KhubIntegration:AddExistedTokenByNameModal:Cancel': {
    eng: 'Cancel',
    ru: 'Отменить',
  },
  'KhubIntegration:ChangeTokenByNameModal:Title': {
    eng: 'Replace API key',
    ru: 'Заменить API-ключ',
  },
  'KhubIntegration:ChangeTokenByNameModal:FieldLabel': {
    eng: 'New value',
    ru: 'Новое значение',
  },
  'KhubIntegration:ChangeTokenByNameModal:FieldPlaceholder': {
    eng: 'Enter API key',
    ru: 'Введите API-ключ',
  },
  'KhubIntegration:ChangeTokenByNameModal:Submit': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'KhubIntegration:ChangeTokenByNameModal:Cancel': {
    eng: 'Cancel',
    ru: 'Отменить',
  },
  'KnowledgeBaseLLM:DeleteConnectionModal:Title': {
    eng: 'Delete connection?',
    ru: 'Удалить подключение?',
  },
  'KnowledgeBaseLLM:DeleteConnectionModal:Cancel': {
    eng: 'Cancel',
    ru: 'Отменить',
  },
  'KnowledgeBaseLLM:DeleteConnectionModal:Submit': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'KnowledgeBaseLLM:DeleteConnectionModal:All': {
    eng: 'Delete connection and the <b>{secretName}</b> secret in the “Secrets and variables” section',
    ru: 'Удалить подключение и токен <b>{secretName}</b> в разделе «Токены и переменные»',
  },
  'KnowledgeBaseLLM:DeleteConnectionModal:OnlyConnection': {
    eng: 'Delete connection only',
    ru: 'Удалить только подключение',
  },
};
