import React from 'react';
import { NotificationData } from './notificaions';
import { TaskStatusData } from './tasks';
import moment from 'moment';
import localize from '../../../localization';

export const markHovered = (
  data: NotificationData[] | TaskStatusData[],
  setData: (data: NotificationData[] | TaskStatusData[]) => unknown,
  index?: number
) => (e: React.MouseEvent) => {
  if (typeof index === 'undefined') return null;
  e.stopPropagation();
  const newData = [...data];
  if (newData[index] && newData[index].hovered) {
    newData[index].hovered = false;
    setData(newData);
  }
};

export const markHoveredAll = (
  data: any[],
  setData: (data: NotificationData[] | TaskStatusData[]) => unknown,
  projectId?: string
) => {
  let isChanged = false;
  const newData = data.map((item: NotificationData | TaskStatusData) => {
    if (item.hovered && item.projectId === projectId) {
      item.hovered = false;
      isChanged = true;
    }
    return item;
  });
  isChanged && setData(newData);
};

export const getDateStr = (key: string) => {
  const diffDays = moment(key, 'YYYYMMDD').diff(moment(), 'days');
  switch (diffDays) {
    case 0:
      return localize.translate('notifications today');
    case -1:
      return localize.translate('notifications yesterday');
    default:
      return moment(key, 'YYYYMMDD').format('DD/MM/YYYY');
  }
};
