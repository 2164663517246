import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '@just-ai/just-ui';
import './StatusMessage.scss';
export const MessageStatusDialog = ({ status }) => {
    switch (status) {
        case 'SENT':
            return _jsx(Icon, { name: 'farCheck', className: 'grayColorIconMessageStatus ml-2', size: 'sm' });
        case 'DELIVERED':
            return _jsx(Icon, { name: 'farCheck', className: 'primaryColorIconMessageStatus ml-2', size: 'sm' });
        case 'NOT_DELIVERED':
            return _jsx(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus ml-2', size: 'sm' });
        case 'READ':
            return _jsx(Icon, { name: 'farCheckDouble', className: 'primaryColorIconMessageStatus ml-2', size: 'sm' });
        case 'NOT_SENT':
            return _jsx(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus ml-2', size: 'sm' });
        default:
            return null;
    }
};
export const MessageStatusSession = ({ status, t }) => {
    switch (status) {
        case 'SENT':
            return (_jsxs("div", { "data-test-id": 'messageStatusSessionSent', className: 'px-2 grayBackgroundMessageStatus', children: [_jsx(Icon, { name: 'farCheck', className: 'grayColorIconMessageStatus mr-1', size: 'sm' }), _jsx("span", { children: t(`Analytics:ClientsDialogs:${status}`) })] }));
        case 'DELIVERED':
            return (_jsxs("div", { "data-test-id": 'messageStatusSessionDelivered', className: 'px-2 primaryBackgroundMessageStatus', style: { borderRadius: '.15rem' }, children: [_jsx(Icon, { name: 'farCheck', className: 'primaryColorIconMessageStatus mr-1', size: 'sm' }), _jsx("span", { children: t(`Analytics:ClientsDialogs:${status}`) })] }));
        case 'NOT_DELIVERED':
            return (_jsxs("div", { "data-test-id": 'messageStatusSessionNotDelivered', className: 'px-2 errorBackgroundMessageStatus', style: { borderRadius: '.15rem' }, children: [_jsx(Icon, { name: 'farTimes', className: 'errorBackgroundMessageStatus mr-1', size: 'sm' }), _jsx("span", { children: t(`Analytics:ClientsDialogs:${status}`) })] }));
        case 'READ':
            return (_jsxs("div", { "data-test-id": 'messageStatusSessionRead', className: 'px-2 primaryBackgroundMessageStatus', style: { borderRadius: '.15rem' }, children: [_jsx(Icon, { name: 'farCheckDouble', className: 'primaryColorIconMessageStatus mr-1', size: 'sm' }), _jsx("span", { children: t(`Analytics:ClientsDialogs:${status}`) })] }));
        case 'NOT_SENT':
            return (_jsxs("div", { "data-test-id": 'messageStatusSessionNotSent', className: 'errorBackgroundMessageStatus mr-1', children: [_jsx(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus mr-1', size: 'sm' }), _jsx("span", { children: t(`Analytics:ClientsDialogs:${status}`) })] }));
        default:
            return null;
    }
};
export const checkChannelForSystemMessage = (channelType) => channelType && ['chatapi', 'telegram', 'viber', 'vk'].includes(channelType);
