import {
  CHANGE_CURRENT_PROJECT,
  CHANGE_PROJECT_TEMPLATE,
  CLEAR_CURRENT_PROJECT,
  CLEAR_EDITABLE_PROJECT,
  CLEAR_NLU_SETTINGS_ERROR,
  CREATE_CAILA_PROJECT,
  CREATE_PROJECT,
  FETCH_BOT_TEMPLATES,
  FETCH_PROJECT_STATISTIC,
  FETCH_PROJECTS,
  GET_CAILA_LANGUAGES,
  GET_CAILA_PROJECT_SETTINGS,
  REMOVE_EDITABLE_PROJECT,
  SAVE_EDITABLE_PROJECT,
  SET_CAILA_PROJECT_LANGUAGE,
  SET_CURRENT_PROJECT,
  SET_EDITABLE_PROJECT,
  TOGGLE_EDIT_PROJECT_MODAL,
  TOGGLE_EXTERNAL_REPOSITORY,
  UPDATE_EDITABLE_PROJECT,
  UPLOAD_PROJECT_ZIP,
  GET_CAILA_ACCESS_KEY,
  IMPORT_CAILA_PROJECT,
  SET_BOT_TO_DELETE,
  FINAL_DELETE,
  UPDATE_CHANNELS,
  DELETE_ACCOUNT_TAG,
  UPDATE_PROJECT_TAGS,
  DISABLE_SPELLCHECKER,
} from '../../constants/projects.actions';
import history from '../../appHistory';
import { axios } from '../../pipes/functions';
import { getBreadcrumbConfig } from '../../views/Breadcrumb';
import pathToName from 'auto-breadcrumb/lib/pathToName';
import { find } from 'lodash';
import { ProjectsApi, SettingsApi } from '../../modules/Caila/api/client';
import { getCailaConfiguration } from '../../utils';
import { MAX_NETWORK_TIMEOUT_FOR_IMPORT } from '../../modules/Caila/components/AppContext';
import { urlWithoutProjectSidebar } from '../../routerFunctions';

const settingsApi = new SettingsApi(getCailaConfiguration());
const projectsApi = new ProjectsApi(getCailaConfiguration());

export const setCurrentProject = (newProjectShortName, fromTemplate, isNewProject, shouldRedirect = false) => {
  localStorage.setItem('CURRENT_PROJECT_SHORT_NAME', newProjectShortName);
  window.requestAnimationFrame(() => {
    if (isNewProject) {
      typeof shouldRedirect !== 'string'
        ? history.push(`/${newProjectShortName}/editor`, { deploy: fromTemplate, isNewProject })
        : history.push(`/${newProjectShortName}/${shouldRedirect}`, { deploy: fromTemplate, isNewProject });
    }
    !isNewProject && shouldRedirect && history.push(`/${newProjectShortName}`);
  });
  return {
    type: CHANGE_CURRENT_PROJECT,
    projectShortName: newProjectShortName,
  };
};

export const changeProjectTemplate = val => {
  return {
    type: CHANGE_PROJECT_TEMPLATE,
    projectTemplate: val,
  };
};

export const toggleExternalRepository = useExternalRepository => {
  return {
    type: TOGGLE_EXTERNAL_REPOSITORY,
    useExternalRepository: useExternalRepository,
  };
};

export const fetchBotTemplates = () => {
  return {
    type: FETCH_BOT_TEMPLATES,
    payload: axios.get(`/restapi/bottemplates/botadmin/`, { params: { ignoreLanguage: true } }),
  };
};

export const disableSpellchecker = error => {
  return {
    type: DISABLE_SPELLCHECKER,
    payload: error,
  };
};

export const getCurrentProject = () => {
  let currentProjectShortName = localStorage.getItem('CURRENT_PROJECT_SHORT_NAME') || '';

  if (urlWithoutProjectSidebar.some(url => window.location.pathname.startsWith(url))) {
    return { type: SET_CURRENT_PROJECT, projectShortName: '' };
  }

  const paths = ['/'];
  window.location.pathname.split('/').reduce(function (prev, curr, index) {
    paths[index] = prev + '/' + curr;
    return paths[index];
  });

  const config = getBreadcrumbConfig();
  const patToProject = find(paths, path => {
    const pathName = pathToName(path, config);
    return path.match(pathName);
  });

  const urlProjectShortName = !!patToProject ? pathToName(patToProject, config) : '';
  if (urlProjectShortName && !['404', 'pages', currentProjectShortName].includes(urlProjectShortName)) {
    currentProjectShortName = urlProjectShortName;
    localStorage.setItem('CURRENT_PROJECT_SHORT_NAME', urlProjectShortName);
  }

  return {
    type: SET_CURRENT_PROJECT,
    projectShortName: currentProjectShortName,
  };
};

export const updateChannels = (projectShortName, channels) => {
  return {
    type: UPDATE_CHANNELS,
    data: { projectShortName, channels },
  };
};

export const setCurrentProjectShortName = projectShortName => {
  if (projectShortName) {
    localStorage.setItem('CURRENT_PROJECT_SHORT_NAME', projectShortName);
  }

  return {
    type: SET_CURRENT_PROJECT,
    projectShortName: projectShortName,
  };
};

export const unsetCurrentProject = () => {
  localStorage.removeItem('CURRENT_PROJECT_SHORT_NAME');
  return {
    type: CLEAR_CURRENT_PROJECT,
  };
};

export const loadProjects = action => {
  return {
    type: FETCH_PROJECTS,
    payload: action(),
  };
};

export const setProjectToEdit = project => {
  return {
    type: SET_EDITABLE_PROJECT,
    project: project,
  };
};

export const saveEditableProject = action => {
  return {
    type: SAVE_EDITABLE_PROJECT,
    payload: action(),
  };
};

export const updateEditableProject = editableProject => {
  return {
    type: UPDATE_EDITABLE_PROJECT,
    editableProject: editableProject,
  };
};

export const removeEditableProject = action => {
  return {
    type: REMOVE_EDITABLE_PROJECT,
    payload: action(),
  };
};

export const dropEditableProject = () => {
  return {
    type: CLEAR_EDITABLE_PROJECT,
  };
};

export const clearNluSettingsError = () => {
  return {
    type: CLEAR_NLU_SETTINGS_ERROR,
  };
};

export const createNewProject = action => {
  return {
    type: CREATE_PROJECT,
    payload: action(),
  };
};
export const createNewProjectByZip = action => {
  return {
    type: CREATE_PROJECT,
    payload: action(),
  };
};

export const toggleModal = flag => {
  return {
    type: TOGGLE_EDIT_PROJECT_MODAL,
    flag: flag,
  };
};

export const fetchProjectStatistic = projectShortName => {
  return {
    type: FETCH_PROJECT_STATISTIC,
    payload: axios.get(`/restapi/botproject/${projectShortName}/stats`),
  };
};

export const uploadProjectZipFile = (projectShortName, file) => {
  let fd = new FormData();
  fd.append('file', file);
  return {
    type: UPLOAD_PROJECT_ZIP,
    payload: axios.post(`/restapi/content/${projectShortName}/zip`, fd),
  };
};

export const createNewCailaProject = (accountId, projectData, settings) => {
  return {
    type: CREATE_CAILA_PROJECT,
    payload: projectsApi.createProject(accountId, {
      project: projectData,
      settings,
    }),
  };
};

export const setCailaProjectSettings = (accountId, projectShortName, settings) => {
  return {
    type: SET_CAILA_PROJECT_LANGUAGE,
    payload: settingsApi.setProjectSettings(accountId, projectShortName, settings),
  };
};

export const getCailaProjectSetings = (accountId, ProjectShortName) => {
  return {
    type: GET_CAILA_PROJECT_SETTINGS,
    payload: settingsApi.getProjectSettings(accountId, ProjectShortName),
  };
};

export const getCailaLanguages = () => {
  return {
    type: GET_CAILA_LANGUAGES,
    payload: settingsApi.listAvailableLanguages(),
  };
};

export const getCailaAccessKey = (accountId, ProjectShortName) => {
  return {
    type: GET_CAILA_ACCESS_KEY,
    payload: projectsApi.getAccessKey(accountId, ProjectShortName),
  };
};

export const importCailaProject = (
  accountId,
  ProjectShortName,
  file,
  clientEntitiesIncluded = true,
  spellerDictionaryIncluded = true
) => {
  return {
    type: IMPORT_CAILA_PROJECT,
    payload: projectsApi.importCaila(
      accountId,
      ProjectShortName,
      clientEntitiesIncluded,
      spellerDictionaryIncluded,
      file,
      {
        timeout: MAX_NETWORK_TIMEOUT_FOR_IMPORT,
      }
    ),
  };
};

export const setBotToDelete = id => {
  return {
    type: SET_BOT_TO_DELETE,
    id,
  };
};
export const finalDelete = id => {
  return {
    type: FINAL_DELETE,
    id,
  };
};

export const updateProjectTags = (projectShortName, tagsList) => {
  return {
    type: UPDATE_PROJECT_TAGS,
    payload: {
      projectShortName,
      tagsList,
    },
  };
};
export const deleteAccountTag = tagId => {
  return {
    type: DELETE_ACCOUNT_TAG,
    payload: {
      tagId,
    },
  };
};
