import React from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';

interface DeleteItemsActionProps {
  onClick: () => void;
}

const DeleteItemsAction = ({ onClick }: DeleteItemsActionProps) => {
  return (
    <Button
      className={styles.DeleteItemsAction}
      size='sm'
      color='danger'
      onClick={onClick}
      data-test-id='Table.DeleteButton'
    >
      <Icon name='faTrash' size='sm' color='danger' className='mr-2' /> {t('IntentItemsEdit:deleteLabel')}
    </Button>
  );
};

DeleteItemsAction.displayName = 'DeleteItemsAction';

export default React.memo(DeleteItemsAction);
