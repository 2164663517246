var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import queryString from 'query-string';
import { merge } from 'lodash';
import { FiltersApi } from '../api/client';
const filterSessionIdController = {
    save: (location, filterSetId, history) => {
        const { search } = location;
        let parsedLocationSearch = queryString.parse(search);
        let params = merge(parsedLocationSearch, {
            filterSetId: filterSetId,
        });
        //@ts-ignore
        history.push({ search: queryString.stringify(params) });
    },
    get: (accountId, projectShortName, history, propFilterSetId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        //@ts-ignore
        if (!history && !propFilterSetId)
            return [];
        const filterService = new FiltersApi({}, '');
        try {
            let filterSetId = propFilterSetId || null;
            if (!filterSetId) {
                const params = queryString.parse((_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.search);
                filterSetId = params.filterSetId;
            }
            if (filterSetId) {
                const { data: { filters }, } = yield filterService.getUserFilterSet(accountId, filterSetId, projectShortName);
                return filters;
            }
            else {
                return [];
            }
        }
        catch (e) {
            return [];
        }
    }),
};
export default filterSessionIdController;
