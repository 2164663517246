import React from 'react';
import { NLUModuleContextProvider } from '@just-ai/nlu-modules/dist/context';
import { validateFile } from '@just-ai/nlu-modules/dist/utils';

import { AppLogger } from '../../services/AppLogger';
import { axios, saveAudioToServer, saveFileToServer } from '../../pipes/functions';
import isAccess, { isContentManager, isWhiteLabel } from '../../isAccessFunction';
import { useTestWidgetContext } from '../../modules/TestWidget';
import { useAppContext } from '../../modules/Caila/components/AppContext';

const NluModuleWrapper = (props: any) => {
  const {
    setCurrentProject,
    currentProject,
    accountId,
    projectShortName,
    language,
    setCurrentProjectToEdit,
    addAlert,
  } = useAppContext();
  const { showNluWidget, trainNlu, trainingNlu } = useTestWidgetContext();

  return (
    <NLUModuleContextProvider
      accountId={accountId}
      projectShortName={projectShortName}
      axiosInstance={axios}
      language={language}
      isAccessFunction={isAccess}
      isWhiteLabel={isWhiteLabel}
      isContentManager={isContentManager}
      showNluWidget={showNluWidget}
      trainNlu={trainNlu}
      trainingNlu={trainingNlu}
      actions={{
        addAlert: addAlert,
      }}
      appLogger={AppLogger}
      currentProject={currentProject}
      setCurrentProject={setCurrentProject}
      setCurrentProjectToEdit={() => setCurrentProjectToEdit('3')}
      saveFileToServer={saveFileToServer}
      saveAudioToServer={saveAudioToServer}
      validateFile={validateFile}
    >
      {props.children}
    </NLUModuleContextProvider>
  );
};

export default NluModuleWrapper;
