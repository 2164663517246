import { Spoiler } from '@just-ai/just-ui';
import React, { forwardRef, Ref } from 'react';
import { LogItem } from '../../model/LogItem';
import { ItemStyle } from 'react-tiny-virtual-list';

type Props = {
  logItem: LogItem;
  style: ItemStyle;
  isOpen: boolean;
  openedItemRef: Ref<HTMLDivElement>;
  toggleOpened: () => void;
};

export const LogItemPanel = forwardRef<HTMLDivElement, Props>(
  ({ logItem, style, isOpen, openedItemRef, toggleOpened }, ref) => (
    <div className='log-item-panel' style={style} ref={ref}>
      <Spoiler
        controlled
        withWrapper
        openProp={isOpen}
        toggleProp={toggleOpened}
        wrapperStyle={{ alignItems: 'flex-start' }}
        Header={() => <div>{logItem.timestamp}</div>}
        HeaderContent={() => (
          <div className='log-header-content'>{logItem.isParsed ? logItem.message : logItem.text}</div>
        )}
      >
        <div className='log-item' ref={openedItemRef}>
          <div className='log-item__line'>
            <p className='log-item__line__heading'>bot</p>
            <p className='log-item__line__value'>{logItem.botId || logItem.meta}</p>
          </div>
          <div className='log-item__line'>
            <p className='log-item__line__heading'>client</p>
            <p className='log-item__line__value'>{logItem.clientId || logItem.accountId}</p>
          </div>
          <div className='log-item__line'>
            <p className='log-item__line__heading'>message</p>
            <p className='log-item__line__value'>{logItem.message}</p>
          </div>
        </div>
      </Spoiler>
    </div>
  )
);
