import React, { FC, useCallback, useMemo, useState } from 'react';
import { Rect, Text } from 'react-konva';
import { IconNames, KIcon } from './KIcons';
import { AutopositionGroup } from './AutopositionGroup';
import { getStageActionsFromEvent, setCursorOnMouseEnterAndLeave } from '../../utils/stageUtils';
import { TagNames, TJBlock } from '../../utils/types';
import { KonvaEventObject } from 'konva/lib/Node';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { t } from 'localization';
import Konva from 'konva';

import { DebugMark } from './DebugMark';
import { JGraphProviderSubject$ } from '../../contexts/JGraphContext';
import { hideRootSlashInPath } from '../../utils/state';

const textProps = {
  fontFamily: 'Roboto',
  verticalAlign: 'middle',
  fontSize: 14,
  letterSpacing: 0.0075,
  height: 24,
};

export const Activation: FC<{
  icon: IconNames;
  value: string;
  index: number;
  tagName: TagNames;
  topCornerRadius: boolean;
  bottomCornerRadius: boolean;
  screen: JStateWithId;
  activation: TJBlock;
}> = ({ icon, value, topCornerRadius, bottomCornerRadius, tagName, index, screen, activation }) => {
  const [hover, setHover] = useState(false);
  const [isSystemIntent, setIsSystemIntent] = useState(false);

  const isGlobal = useMemo(() => {
    return [TagNames.event_, TagNames.q_, TagNames.intent_, TagNames.intentGroup_].includes(tagName);
  }, [tagName]);

  const onClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      const stageActions = getStageActionsFromEvent(event);
      stageActions.setEditMenuBlock({ screen: screen, jBlockIndex: index });
    },
    [screen, index]
  );
  const showValue = useMemo(() => {
    if ([TagNames.intent_, TagNames.intent].includes(tagName)) {
      const jgContext = JGraphProviderSubject$.getValue();
      if (jgContext.IntentsService.isSystemIntent(jgContext.currentBotLanguage, value)) {
        setIsSystemIntent(true);
        return t(`ChooseReadyIntent ${value}`);
      } else {
        setIsSystemIntent(false);
        const renderedText = new Konva.Text({ ...textProps, text: value });
        if (renderedText.width() > 194) {
          const letterSize = renderedText.width() / value.length;
          const newTextLength = Math.floor(194 / letterSize) - 3;
          return `...${value.slice(-newTextLength)}`;
        }
      }
    }
    return hideRootSlashInPath(value);
  }, [value, tagName]);

  const cornerRadius = [0, 0, 0, 0];
  if (topCornerRadius) {
    cornerRadius[0] = 8;
    cornerRadius[1] = 8;
  }
  if (bottomCornerRadius) {
    cornerRadius[2] = 8;
    cornerRadius[3] = 8;
  }

  return (
    <AutopositionGroup yOffset={4}>
      <KIcon icon={isSystemIntent ? IconNames.systemIntent : icon} y={4} />
      <Text
        y={3}
        x={24}
        fontSize={14}
        lineHeight={20 / 14}
        height={20}
        letterSpacing={0.75}
        text={showValue}
        fill='#333C45'
        width={isGlobal ? 194 : 220}
        ellipsis={true}
      />
      {isGlobal && <KIcon icon={IconNames.global} y={6} x={220} />}
      {activation.debugActive && (
        <DebugMark
          height={topCornerRadius && bottomCornerRadius ? 28 : 30}
          topCurved={topCornerRadius}
          bottomCurved={bottomCornerRadius}
          x={-12}
          y={topCornerRadius ? -2 : bottomCornerRadius && !topCornerRadius ? -4 : -2}
        />
      )}

      <Rect
        onClick={onClick}
        y={-4}
        x={-12}
        width={280 - 24}
        height={32}
        cornerRadius={cornerRadius}
        opacity={hover ? 0.04 : 0}
        fill='#000000'
        onMouseEnter={setCursorOnMouseEnterAndLeave(() => setHover(true))}
        onMouseLeave={setCursorOnMouseEnterAndLeave(() => setHover(false))}
      />
    </AutopositionGroup>
  );
};
