import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';
import { Group, Rect } from 'react-konva';
import Konva from 'konva';
import { usePrevious } from '@just-ai/just-ui/dist/utils';
import { cacheGroup, clearCache, ICache } from '../../utils/blockLayerUtils';

import { findParentScreenName, useOnClickBlockDefault } from '../../hooks';
import { useRenderBlocksContext } from './RenderBlocks';

export interface TImageBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  src: string;
  maxHeight?: number;
}

const imageHeight = 155;
const imageWidth = 232;

export const ImageBody: FC<TImageBodyProps> = ({ src, globalJBlockIterator = 0, ...rest }) => {
  const [isImageLoaded, setImageLoaded] = useState<boolean>(!!ICache.has(src));
  const loadImageRef = useRef<HTMLImageElement | undefined>(ICache.imagesSet[src]);
  const groupRef = useRef<Konva.Group>(null);
  const RectRef = useRef<Konva.Rect>(null);
  const [scaleFactor, setScaleFactor] = useState<Konva.Vector2d>({ x: 1, y: 1 });
  const prevSrc = usePrevious(src);
  const { baseWidth, prefix, screen } = useRenderBlocksContext();

  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);

  const handleLoad = useCallback((element: HTMLImageElement) => {
    const scaleX = imageWidth / (element.naturalWidth || imageWidth);
    setScaleFactor({
      x: scaleX,
      y: scaleX,
    });
    loadImageRef.current = element;
    if (RectRef.current) {
      const [, group] = findParentScreenName(RectRef.current as Konva.Shape);
      if (!group) return;
      requestAnimationFrame(() => {
        clearCache(group);
      });
      requestAnimationFrame(() => {
        cacheGroup(group);
      });
    }
    setImageLoaded(true);
  }, []);

  const loadImage = useCallback(
    src => {
      if (src !== prevSrc) {
        setImageLoaded(false);
        ICache.load(src, handleLoad);
      }
    },
    [handleLoad, prevSrc]
  );

  useEffect(() => {
    loadImage(src);
  }, [loadImage, src]);

  return (
    <BaseItemBody
      {...rest}
      maxHeight={5}
      bottomPadding={8}
      renderChild={() => (
        <Group x={0} y={36} ref={groupRef} onClick={onClickDefault}>
          <Rect
            ref={RectRef}
            cornerRadius={8}
            strokeWidth={1}
            stroke='#E0E1E3'
            height={imageHeight}
            width={baseWidth - 48}
            fillPatternImage={isImageLoaded ? loadImageRef.current : undefined}
            fillPatternScale={isImageLoaded ? scaleFactor : { x: 1, y: 1 }}
            fillPatternRepeat='no-repeat'
          />
        </Group>
      )}
    />
  );
};
