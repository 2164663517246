import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Icon, useTranslation } from '@just-ai/just-ui';
import { downloadTxtFile } from '../../../utils';
import styles from './style.module.scss';
export default function FileImportError(props) {
    const { error, fileName } = props;
    const [blockScrolled, setBlockScrolled] = useState(false);
    const { t } = useTranslation();
    const copyError = () => {
        navigator.clipboard.writeText(error.text);
    };
    const downloadError = () => {
        const errorsFileName = fileName ? `Errors_file_${fileName}` : 'errors';
        downloadTxtFile(error.text, errorsFileName);
    };
    const handleBlockScroll = useCallback((event) => {
        if (event.currentTarget.scrollTop > 0 && !blockScrolled) {
            return setBlockScrolled(true);
        }
        if (event.currentTarget.scrollTop === 0 && blockScrolled) {
            return setBlockScrolled(false);
        }
    }, [blockScrolled]);
    if (error.type === 'local')
        return _jsx("p", { className: 'error font-size-12 mb-0', children: t(error.text) });
    return (_jsxs("div", { children: [_jsxs("div", { style: {
                    borderBottom: '1px solid transparent',
                    borderBottomColor: blockScrolled ? '#D6E2F2' : 'transparent',
                }, className: styles.faqImportModal__fileErrorBlock__errorHeader, children: [_jsx("p", { className: 'font-size-12 font-bold', children: t('FAQ:importModal:error:area:title') }), _jsxs("div", { children: [_jsxs("div", { onClick: copyError, children: [_jsx(Icon, { name: 'farCopy', size: 'sm', color: 'primary' }), _jsx("p", { children: t('FAQ:importModal:button:copy') })] }), _jsxs("div", { onClick: downloadError, children: [_jsx(Icon, { name: 'farArrowToBottom', size: 'sm', color: 'primary' }), _jsx("p", { children: t('Download') })] })] })] }), _jsx("div", { onScroll: handleBlockScroll, className: `${styles.faqImportModal__fileErrorBlock__errorBody} just-ui-custom-scrollbar just-ui-custom-scrollbar_indigo-light`, children: error.text })] }));
}
