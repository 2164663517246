var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputGroup as RsInputGroup, InputGroupAddon, } from 'reactstrap';
import classNames from 'classnames';
import { IconButton } from "..";
const InputGroup = (_a) => {
    var { children, Append, Prepend, AppendInner = [], PrependInner, className, size } = _a, restProps = __rest(_a, ["children", "Append", "Prepend", "AppendInner", "PrependInner", "className", "size"]);
    return (_jsxs(RsInputGroup, Object.assign({}, restProps, { size: size, className: classNames(className, {
            'InputGroup-AppendInner': AppendInner && AppendInner.length > 0,
            'InputGroup-AppendInner-2': AppendInner && AppendInner.length === 2
        }, {
            'InputGroup-PrependInner': PrependInner && PrependInner.length > 0,
            'InputGroup-PrependInner-2': PrependInner && PrependInner.length === 2
        }), children: [Prepend &&
                _jsx(InputGroupAddon, { addonType: 'prepend', children: Prepend }), PrependInner &&
                _jsx("div", { className: 'IconButtons-wrap IconButtons-wrap-prepend', children: PrependInner.map((_a, index) => {
                        var props = __rest(_a, []);
                        return _createElement(IconButton, Object.assign({ withoutPadding: true, flat: true, size: size }, props, { key: `IconButtons-wrap-prepend${index}` }));
                    }) }), children, AppendInner &&
                _jsx("div", { className: 'IconButtons-wrap IconButtons-wrap-append', children: AppendInner.map((_a, index) => {
                        var props = __rest(_a, []);
                        return _createElement(IconButton, Object.assign({ withoutPadding: true, flat: true, size: size }, props, { key: `IconButtons-wrap-append${index}` }));
                    }) }), Append &&
                _jsx(InputGroupAddon, { addonType: 'append', children: Append })] })));
};
export default InputGroup;
