export const JGLocalization = {
  'JGraph:DebugIsRunning': {
    eng: 'Testing in progress',
    ru: 'Тестирование запущено',
  },
  'JGraph:LastScreenFromDebug': {
    eng: 'Current state',
    ru: 'Текущий шаг',
  },
  'JGraph:ReloadWidget': {
    eng: 'Restart the widget to apply the changes',
    ru: 'Перезапустите виджет, чтобы изменения вступили в силу',
  },
  'ScreenBody:BlockTitle_audio': {
    eng: 'Audio',
    ru: 'Аудио',
  },
  'AudioSettings:UploadLabelFirst': {
    eng: 'Attach a file',
    ru: 'Загрузите аудиофайл',
  },
  'AudioSettings:UploadLabelSecond': {
    eng: ' or drag it here',
    ru: ' или перетащите его сюда',
  },
  'AudioSettings:upload-file': {
    eng: 'From device',
    ru: 'C компьютера',
  },
  'AudioSettings:file-link': {
    eng: 'By URL',
    ru: 'По ссылке',
  },
  'AudioSettings:urlHint': {
    eng: 'URLs should use the HTTPS protocol.',
    ru: 'Ссылки должны использовать протокол HTTPS.',
  },
  'AudioSettings:collapsableHintText': {
    eng: 'Audio file requirements',
    ru: 'Рекомендации к аудиофайлам',
  },
  'AudioSettings:collapsableHintTextContent:PartOne:Header': {
    eng: 'For the phone channel:',
    ru: 'Для телефонного канала:',
  },
  'AudioSettings:collapsableHintTextContent:PartOne:Option:1': {
    eng: 'File format: .wav',
    ru: 'Файл формата .wav',
  },
  'AudioSettings:collapsableHintTextContent:PartOne:Option:2': {
    eng: '1 channel (mono)',
    ru: '1 канал (моно)',
  },
  'AudioSettings:collapsableHintTextContent:PartOne:Option:3': {
    eng: '16-bit depth',
    ru: 'Глубина 16 бит',
  },
  'AudioSettings:collapsableHintTextContent:PartOne:Option:4': {
    eng: 'Sampling rate: 8–48 kHz',
    ru: 'Частота дискретизации 8–48 кГц',
  },
  'AudioSettings:collapsableHintTextContent:PartTwo:Header': {
    eng: 'For voice assistants:',
    ru: 'Для голосовых ассистентов:',
  },
  'AudioSettings:collapsableHintTextContent:PartTwo:Option:1': {
    eng: 'File format: .mp3',
    ru: 'Файл формата .mp3',
  },
  'AudioSettings:collapsableHintTextContent:PartTwo:Option:2': {
    eng: 'Duration: up to 240 seconds',
    ru: 'Продолжительность не более 240 секунд',
  },
  'AudioSettings:collapsableHintTextContent:PartTwo:Option:3': {
    eng: 'File size: up to 10 MB',
    ru: 'Размер файла не более 10 Мбайт',
  },
  'AudioSettings:collapsableHintTextContent:PartThree': {
    eng: 'To find out more about file requirements, refer to ',
    ru: 'Подробные рекомендации смотрите в ',
  },
  'AudioSettings:collapsableHintTextContent:PartThree:LinkTitle': {
    eng: 'the documentation.',
    ru: 'документации.',
  },
  'AudioSettings:urlPlaceholder': {
    eng: 'Enter file URL',
    ru: 'Введите ссылку на файл',
  },
  'AudioSettings:compatibleChannelsTitle': {
    eng: 'This file is supported in:',
    ru: 'Этот файл поддерживается в:',
  },
  'AudioSettings:incompatibleChannelsTitle': {
    eng: 'Not supported in:',
    ru: 'Не поддерживается в:',
  },
  'AudioBody:placeholder': {
    eng: 'Add file',
    ru: 'Добавьте файл',
  },
  'Player renderIncompatibleChannels ALISA': {
    eng: 'Audio requirements:\nFile format: .mp3, .wav\nFile size: up to 5 MB\nDuration: up to 120 seconds',
    ru: 'Требования к аудио:\nФайл формата .mp3, .wav\nРазмер не более 5 Мбайт\nДлительность до 120 секунд',
  },
  'Player renderIncompatibleChannels GOOGLE_ASSISTANCE': {
    eng: 'Audio requirements:\nFile format: .mp3 (MPEG-2)\nSampling rate: 24 kHz\nBitrate: 24–96 kbps\nDuration: up to 240 seconds',
    ru: 'Требования к аудио:\nФайл формата .mp3 (MPEG-2)\nЧастота 24 кГц\nБитрейт 24–96 кбит/с\nДлительность не более 240 секунд',
  },
  'Player renderIncompatibleChannels TELEGRAM': {
    eng: 'Audio requirements:\nFile format: .mp3 (MPEG-2)\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3 (MPEG-2)\nРазмер не более 10 Мбайт',
  },
  'Player renderIncompatibleChannels FACEBOOK': {
    eng: 'Audio requirements:\nFile format: .mp3 (MPEG-2), .wav\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3 (MPEG-2), .wav\nРазмер не более 10 Мбайт',
  },
  'Player renderIncompatibleChannels VK': {
    eng: 'Audio requirements:\nFile format: .mp3 (MPEG-2), .wav\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3 (MPEG-2), .wav\nРазмер не более 10 Мбайт',
  },
  'Player renderIncompatibleChannels WHATSAPP': {
    eng: 'Audio requirements:\nFile format: .mp3 (MPEG-2), .wav\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3 (MPEG-2), .wav\nРазмер не более 10 Мбайт',
  },
  'Player renderIncompatibleChannels OUTGOING_CALLS': {
    eng: 'Audio requirements:\nFile format: .wav\n1 channel (mono)\nCodec: 16-bit (PCM)\nSampling rate: 8–48 kHz',
    ru: 'Требования к аудио:\nФайл формата .wav\n1 канал (моно)\nКодирование 16-bit (PCM)\nЧастота 8–48 кГц',
  },
  'Player renderIncompatibleChannels ALEXA': {
    eng: 'Audio requirements:\nFile format: .mp3\nSampling rate: 16 kHz\nBitrate: 48 kbps\nDuration: up to 240 seconds',
    ru: 'Требования к аудио:\nФайл формата .mp3\nЧастота 16 кГц\nБитрейт 48 кбит/с\nДлительность не более 240 секунд',
  },
  'Player renderIncompatibleChannels AIMYBOX': {
    eng: 'Audio requirements:\nFile format: .mp3, .wav\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3, .wav\nРазмер не более 10 Мбайт',
  },
  'Player renderIncompatibleChannels messengers': {
    eng: 'Audio requirements:\nFile format: .mp3, .wav\nFile size: up to 10 MB',
    ru: 'Требования к аудио:\nФайл формата .mp3, .wav\nРазмер не более 10 Мбайт',
  },
  'Player renderCompatibleChannels messengers': {
    eng: 'Messengers:\nTelegram\nFacebook\nVK\nWhatsApp Business API',
    ru: 'Мессенджеры:\nTelegram\nFacebook\nВКонтакте\nWhatsApp Business API',
  },
  'AudioUploadField:Error:InvalidFileExtension': {
    eng: 'Invalid file extension. Supported file formats: .wav or .mp3.',
    ru: 'Неверное расширение файла. Поддерживаемые форматы: .wav или .mp3.',
  },
  'AudioUploadField:Error:FileSize': {
    eng: 'File size cannot exceed 10 MB.',
    ru: 'Размер файла не должен превышать 10 Мбайт.',
  },
  'AudioUploadField:Error:UnexpectedError': {
    eng: 'Errors occurred while checking the audio file.',
    ru: 'Возникли ошибки при проверке аудио файла.',
  },
  'AudioUploadUrlField:FormatError': {
    eng: 'Check the URL format. URLs should use the HTTPS protocol.',
    ru: 'Проверьте формат ссылки. Ссылки должны использовать протокол HTTPS.',
  },
  'AudioUploadUrlField:BannerHint': {
    eng: 'You can use variables from the bot script inside the URL. The bot will load the file automatically when the script is running.',
    ru: 'В URL можно вставлять переменные из сценария. Бот загрузит файл автоматически во время выполнения сценария.',
  },
  'RightSideMenu:Header:LinkToDock:a': {
    eng: '/visual-editor/script-development/reactions/text',
    ru: '/visual-editor/script-development/reactions/text',
  },
  'RightSideMenu:Header:LinkToDock:intent': {
    eng: '/platform_ux/nlu_core/intents',
    ru: '/platform_ux/nlu_core/intents',
  },
  'Reactions:EmptyPlaceholder:Title': {
    eng: 'Add reactions',
    ru: 'Добавьте реакции',
  },
  'Reactions:EmptyPlaceholder:Description': {
    eng: 'Reactions are actions the bot will execute when it goes to the current state',
    ru: 'Реакции — это действия, которые бот выполнит, когда перейдет в текущий шаг',
  },
  'PhrasesAndEvents:EmptyPlaceholder:Title': {
    eng: 'Add phrases or events',
    ru: 'Добавьте фразы или события',
  },
  'PhrasesAndEvents:EmptyPlaceholder:Description': {
    eng: 'The bot will continue along the script if the user sends a matching phrase or an event occurs',
    ru: 'Бот перейдет дальше по сценарию, если клиент произнесет подходящую фразу или произойдет событие',
  },
  'CustomTags:StringArray:AddField': {
    eng: 'Add parameter',
    ru: 'Добавить параметр',
  },
  'ScreenBody:CollapseGroup_ActivationTitle': {
    eng: 'Triggers',
    ru: 'Способы активации',
  },
  'ScreenBody:CollapseGroup_ReactionsTitle': {
    eng: 'Reactions',
    ru: 'Реакции бота',
  },
  'ScreenBody:CollapseGroup_ScreensTitle': {
    eng: 'Nested states',
    ru: 'Вложенные шаги',
  },
  'ScreenBody:BlockTitle_a': {
    eng: 'Text',
    ru: 'Текст',
  },
  'ScreenBody:BlockTitle_random': {
    eng: 'Random answer',
    ru: 'Случайный ответ',
  },
  'ScreenBody:BlockTitle_inlineButtons': {
    eng: 'Inline buttons',
    ru: 'Inline-кнопки',
  },
  'ScreenBody:BlockTitle_image': {
    eng: 'Image',
    ru: 'Изображение',
  },
  'ScreenBody:BlockTitle_go': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ScreenBody:BlockTitle_go!': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ScreenBody:BlockTitle_script': {
    eng: 'Code',
    ru: 'Код',
  },
  'ScreenBody:BlockTitle_scriptEs6': {
    eng: 'Code',
    ru: 'Код',
  },
  'ScreenBody:Block_go_placeholder': {
    eng: 'Configure transition',
    ru: 'Настройте переход',
  },
  'AnswerSettings:BotAnswer': {
    eng: 'Bot phrase',
    ru: 'Реплика бота',
  },
  'AnswerSettings:BotWrite': {
    eng: 'Bot writes',
    ru: 'Бот напишет',
  },
  'AnswerSettings:BotSay': {
    eng: 'Bot phrase',
    ru: 'Реплика бота',
  },
  'AnswerSettings:BotSay:Placeholder': {
    eng: '<speak><emphasis>Enter</emphasis> a text with <sub alias="speech synthesis markup">SSML markup</sub></speak>',
    ru: '<speak><emphasis>Введите</emphasis> текст c <sub alias="разметкой синтеза речи">SSML-разметкой</sub></speak>',
  },
  'AnswerSettings:BotSay:Play': {
    eng: 'Listen',
    ru: 'Прослушать',
  },
  'AnswerSettings:BotSay:SynthesizeError:Empty': {
    eng: 'Enter a text in the field',
    ru: 'Введите текст в поле',
  },
  'AnswerSettings:BotSay:TtsAdapterError:Unavailable': {
    eng: 'tts-adapter is unavailable',
    ru: 'tts-adapter недоступен',
  },
  'AnswerSettings:BotSay:SynthesizeError:Common': {
    eng: 'Failed to synthesize the phrase',
    ru: 'Ошибка синтеза',
  },
  'AnswerSettings:BotSay:SynthesizeError:RateLimit': {
    eng: 'You have reached your synthesis attempt limit. Try again later.',
    ru: 'Вы достигли лимита попыток синтеза. Попробуйте позже.',
  },
  'ScriptSettings:ScriptAnswer': {
    eng: 'JavaScript code',
    ru: 'JavaScript код',
  },
  'TagNameChange:condition_placeholder': {
    eng: 'JavaScript expression',
    ru: 'JavaScript-выражение',
  },
  'JGraphActivations:AddReaction': {
    eng: 'Add reaction',
    ru: 'Добавить реакцию',
  },
  'JGraphActivations:AddPhrase': {
    eng: 'Add phrase',
    ru: 'Добавить фразу',
  },
  'JGraph-addingMenu-searchContainer placeholder': {
    eng: 'Search',
    ru: 'Поиск',
  },
  'JGraph-addingMenu-group-title-main': {
    eng: 'Client phrase',
    ru: 'Реплика клиента',
  },
  'JGraph-addingMenu-group-title-emotions': {
    eng: 'Emotions',
    ru: 'Эмоции',
  },
  'JGraph-addingMenu-group-title-additional': {
    eng: 'Additional tools',
    ru: 'Дополнительные инструменты',
  },
  'JGraph-addingMenu-createIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
  },
  'JGraph-RightSideMenu-stateName-label': {
    eng: 'State name',
    ru: 'Название шага',
  },
  'JGraph-RightSideMenu-stateName-placeholder': {
    eng: 'State name',
    ru: 'Название шага',
  },
  'JGraph-RightSideMenu-isParentState-label': {
    eng: 'Nesting',
    ru: 'Вложенность',
  },
  'JGraph-RightSideMenu-isParentState-text': {
    eng: 'Nested state',
    ru: 'Вложенный шаг',
  },
  'JGraph-RightSideMenu-context-label': {
    eng: 'Context management',
    ru: 'Управление контекстом',
  },
  'JGraph-RightSideMenu-noContext-label': {
    eng: 'noContext',
    ru: 'noContext',
  },
  'JGraph-RightSideMenu-modal-label': {
    eng: 'modal',
    ru: 'modal',
  },
  'JGraph-RightSideMenu-newStateName-label': {
    eng: 'New state name',
    ru: 'Название нового шага',
  },
  'JGraph-RightSideMenu-newStateName-placeholder': {
    eng: 'Enter name',
    ru: 'Введите название',
  },
  'JGraph-RightSideMenu-setTransitionInState-text': {
    eng: 'Make new state nested into',
    ru: 'Вложить новый шаг в',
  },
  'ButtonSettings-buttonName': {
    eng: 'Button text',
    ru: 'Текст кнопки',
  },
  'ButtonsSettings add button': {
    eng: 'Add button',
    ru: 'Добавить кнопку',
  },
  'ConditionalSettings:AddElseButton': {
    eng: 'Add else',
    ru: 'Добавить else',
  },
  'ScreenBlocksList activations': {
    eng: 'Triggers',
    ru: 'Способы активации',
  },
  'ScreenBlocksList awaited actions': {
    eng: 'Expected paths',
    ru: 'Ожидаемые пути',
  },
  'ScreenBlocksList:openReactionMenu': {
    eng: 'All reactions',
    ru: 'Все реакции',
  },
  'ScreenBlocksList:AddVariant': {
    eng: 'Add variant',
    ru: 'Добавить вариант',
  },
  'ButtonSettings bot runtime link': {
    eng: 'The link will be generated during bot runtime',
    ru: 'Внешняя ссылка будет сформирована во время работы бота',
  },
  'KonvaPlaceholder-SetButtons': {
    eng: 'Configure buttons',
    ru: 'Настройте кнопки',
  },
  'ButtonSettings-button-placeholder': {
    eng: 'Button $[1]',
    ru: 'Кнопка $[1]',
  },
  'ButtonSettingItem-tooltip': {
    eng: 'You can verify link URLs containing variables when testing your script',
    ru: 'Ссылку с переменной можно проверить во время тестирования сценария',
  },
  'ReactionItem:add_reactions': {
    eng: 'Add reaction',
    ru: 'Добавить реакцию',
  },
  'ReactionItem:add_activations': {
    eng: 'Add trigger',
    ru: 'Добавить способ активации',
  },
  'ScreenBlocksList:add': {
    eng: 'Add',
    ru: 'Добавить',
  },
  'ReactionItem a': {
    eng: 'Text',
    ru: 'Текст',
  },
  'ReactionItem image': {
    eng: 'Image',
    ru: 'Изображение',
  },
  'ReactionItem video': {
    eng: 'Video',
    ru: 'Видео',
  },
  'ReactionItem audio': {
    eng: 'Audio',
    ru: 'Аудио',
  },
  'ReactionItem random': {
    eng: 'Random answer',
    ru: 'Случайный ответ',
  },
  'ReactionItem if': {
    eng: 'Condition',
    ru: 'Условие',
  },
  'ReactionItem inlineButtons': {
    eng: 'Inline button',
    ru: 'Inline-кнопка',
  },
  'ReactionItem go': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ReactionItem go!': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ReactionItem script': {
    eng: 'Code',
    ru: 'Код',
  },
  'ReactionItem scriptEs6': {
    eng: 'Code',
    ru: 'Код',
  },
  'ReactionItem Email': {
    eng: 'Send email',
    ru: 'Отправка email',
  },
  'ActivationItem q': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'ActivationItem q!': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'ActivationItem go': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ActivationItem go!': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ActivationItem go_': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'ActivationItem event': {
    eng: 'Event',
    ru: 'Событие',
  },
  'ActivationItem event!': {
    eng: 'Event',
    ru: 'Событие',
  },
  'ActivationItem eg': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationItem eg!': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationItem e': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationItem e!': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationTitle q': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'ActivationTitle q!': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'ActivationTitle intent': {
    eng: 'User phrase (intent)',
    ru: 'Фраза клиента (интент)',
  },
  'ActivationTitle intent!': {
    eng: 'User phrase (intent)',
    ru: 'Фраза клиента (интент)',
  },
  'ActivationTitle intentGroup': {
    eng: 'Group of phrases (intents)',
    ru: 'Группа фраз (интентов)',
  },
  'ActivationTitle intentGroup!': {
    eng: 'Group of phrases (intents)',
    ru: 'Группа фраз (интентов)',
  },
  'ActivationTitle event': {
    eng: 'Event',
    ru: 'Событие',
  },
  'ActivationTitle event!': {
    eng: 'Event',
    ru: 'Событие',
  },
  'ActivationTitle eg': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationTitle eg!': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationTitle e': {
    eng: 'Example',
    ru: 'Пример',
  },
  'ActivationTitle e!': {
    eng: 'Example',
    ru: 'Пример',
  },
  'RSAddingMenu:search-placeholder': {
    eng: 'Search by name',
    ru: 'Искать по названию',
  },
  'RSAddingMenu:title-BotResponses': {
    eng: 'Bot response',
    ru: 'Ответ бота',
  },
  'RSAddingMenu:title-LogicResponses': {
    eng: 'Logic',
    ru: 'Логика',
  },
  'RSAddingMenu:title-MetaResponses': {
    eng: 'Actions',
    ru: 'Действия',
  },
  'UrlEditor:EnterToSave': {
    eng: 'Press Enter to save changes',
    ru: 'Нажмите Enter, чтобы сохранить изменения',
  },
  'TransitionSettings:ChoosePath': {
    eng: 'Select state',
    ru: 'Выберите шаг',
  },
  'TransitionSettings:TransitionType': {
    eng: 'Transition type',
    ru: 'Тип перехода',
  },
  'TransitionSettings:TransitionType_deffered': {
    eng: 'Deferred',
    ru: 'Отложенный',
  },
  'TransitionSettings:TransitionType_not_deffered': {
    eng: 'Immediate',
    ru: 'Немедленный',
  },
  'TransitionSettings:TransitionState': {
    eng: 'State',
    ru: 'Шаг',
  },
  'ImageSettings:upload-file': {
    eng: 'Upload file',
    ru: 'Загрузить файл',
  },
  'ImageSettings:file-link': {
    eng: 'Insert URL',
    ru: 'Вставить ссылку',
  },
  'ImageSettings:drop_image': {
    eng: 'Attach an image',
    ru: 'Прикрепите изображение',
  },
  'ImageSettings:drop_image_subtext': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
  },
  'ImageSettings:vars_can_be_added': {
    eng: 'You can use variables from the bot script inside the URL.',
    ru: 'В URL можно вставлять переменные из сценария',
  },
  'ImageSettings:info_message': {
    eng: 'The bot will load the image automatically when the script is running',
    ru: 'Бот загрузит изображение автоматически во время выполнения сценария',
  },
  'ImageSettings:image_url': {
    eng: 'Enter image URL',
    ru: 'Введите URL изображения',
  },
  'AnswerBody:placeholder': {
    eng: 'Enter text',
    ru: 'Введите текст',
  },
  'RandomBody:empty_text': {
    eng: 'Choose a reaction',
    ru: 'Выберите реакцию',
  },
  'RightSideMenu:activationMenuTitle_event': {
    eng: 'Events',
    ru: 'События',
  },
  'RightSideMenu:activationMenuTitle_pattern': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'RightSideMenu:activationMenuTitle_intent': {
    eng: 'Intent',
    ru: 'Интент',
  },
  'RightSideMenu:activationMenuTitle_intent_filled': {
    eng: 'Intent: $[1]',
    ru: 'Интент: $[1]',
  },
  'RightSideMenu:activationMenuTitle_pattern_filled': {
    eng: 'Pattern: $[1]',
    ru: 'Паттерн: $[1]',
  },
  'RightSideMenu:blockActions_duplicate': {
    eng: 'Duplicate',
    ru: 'Дублировать',
  },
  'RightSideMenu:blockActions_delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'RightSideMenu:delete_block_title': {
    eng: 'Delete block',
    ru: 'Удаление блока',
  },
  'RightSideMenu:delete_block_text': {
    eng: 'The block will be deleted. Are you sure?',
    ru: 'Вы действительно хотите удалить блок?',
  },
  'RightSideMenu:delete_block_submit': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'RightSideMenu:delete_state_title': {
    eng: 'Delete state',
    ru: 'Удаление шага',
  },
  'RightSideMenu:DeleteState:StickersLostWarning': {
    eng: 'Sticky notes that are not attached to states will stay nested in {levelPath}. You will not be able to view or edit them.\n\nTo make the sticky notes available again, add a state to that level.',
    ru: 'Стикеры, которые не прикреплены к шагам, останутся вложенными в {levelPath}. Вы не сможете просматривать или редактировать их.\n\nЧтобы стикеры снова стали доступны, добавьте шаг на этот уровень.',
  },
  'RightSideMenu:MoveLevelState:StickersLostWarning': {
    eng: 'Sticky notes that are not attached to states will stay nested in {levelPath}. You will not be able to view or edit them.\n\nTo make the sticky notes available again, add a state to that level.',
    ru: 'Стикеры, которые не прикреплены к шагам, останутся вложенными в {levelPath}. Вы не сможете просматривать или редактировать их.\n\nЧтобы стикеры снова стали доступны, добавьте шаг на этот уровень.',
  },
  'RightSideMenu:delete_state_text': {
    eng: 'The state will be deleted. Are you sure?',
    ru: 'Вы действительно хотите удалить шаг?',
  },
  'RightSideMenu:delete_state_submit': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'RightSideMenu:transitions_settings': {
    eng: 'Links',
    ru: 'Связи',
  },
  'RightSideMenu:open_change_group': {
    eng: 'Change nesting level',
    ru: 'Изменить вложенность',
  },
  'RightSideMenu:random_block:description': {
    eng: 'The bot will randomly select a reaction from the added options',
    ru: 'Бот случайным образом выберет реакцию из добавленных вариантов',
  },
  'CreationScreenMenu:title': {
    eng: 'New state name',
    ru: 'Название нового шага',
  },
  'CreationScreenMenu:title:hint': {
    eng: 'The name cannot contain the / character',
    ru: 'В названии нельзя использовать символ /',
  },
  'CreationScreenMenu:title:error:denySlash': {
    eng: 'The name cannot contain the / character',
    ru: 'В названии нельзя использовать символ /',
  },
  'CreationScreenMenu:addBlock': {
    eng: 'Add block',
    ru: 'Добавить блок',
  },
  'CreationScreenMenu:readyBlock': {
    eng: 'Select an action',
    ru: 'Выбрать действие',
  },
  'CreationScreenMenu:CreateState': {
    eng: 'Create state',
    ru: 'Создать шаг',
  },
  'CreationScreenMenu:delete_exist_connection_title': {
    eng: 'Delete existing link?',
    ru: 'Удалить существующую связь?',
  },
  'CreationScreenMenu:delete_exist_connection_text': {
    eng: 'This action will break the link between states $[1] and $[2]. Delete anyway?',
    ru: 'Данное действие разорвет связь между шагом $[1] и $[2]. Продолжить?',
  },
  'CreationScreenMenu:delete_exist_connection_submit': {
    eng: 'Confirm',
    ru: 'Да',
  },
  'AddingSimpleBlock:reactionsGroup': {
    eng: 'Bot phrase',
    ru: 'Реплика бота',
  },
  'AddingSimpleBlock:logicGroup': {
    eng: 'Logic',
    ru: 'Логика',
  },
  'HttpRequest:title': {
    eng: 'HTTP request',
    ru: 'HTTP-запрос',
  },
  'HttpRequest:method': {
    eng: 'Method',
    ru: 'Метод',
  },
  'HttpRequest:url': {
    eng: 'URL',
    ru: 'URL',
  },
  'EmailBody:placeholder': {
    eng: 'Enter email',
    ru: 'Введите email',
  },
  'EmailSettings:FileAdder:title': {
    eng: 'Attach files',
    ru: 'Прикрепить файлы',
  },
  'EmailSettings:FileAdder:upload-file': {
    eng: 'From your computer',
    ru: 'С компьютера',
  },
  'EmailSettings:FileAdder:file-link': {
    eng: 'From a variable',
    ru: 'Из переменной',
  },
  'EmailSettings:FileAdder:drop_file': {
    eng: 'Attach your file',
    ru: 'Прикрепите файл',
  },
  'EmailSettings:FileAdder:drop_file_subtext': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
  },
  'EmailSettings:FileAdder:drop_file_small_subtext': {
    eng: 'File size cannot exceed 25 MB',
    ru: 'Размер файла не должен превышать 25 Мб',
  },
  'EmailSettings:FileAdder:file_list_title': {
    eng: 'Files',
    ru: 'Файлы',
  },
  'EmailSettings:destination': {
    eng: 'Recipient email',
    ru: 'Email получателя',
  },
  'EmailSettings:subject': {
    eng: 'Subject',
    ru: 'Тема',
  },
  'EmailSettings:text': {
    eng: 'Message body',
    ru: 'Текст письма',
  },
  'EmailSettings:FileAdder:LinkAdder:helper_text': {
    eng: 'Use Aa–Zz, _, and 0–9 characters only. The name should start with Aa–Zz. JS reserved words are not allowed.',
    ru: 'Используйте Aa–Zz, _, 0–9. Начните имя с Aa–Zz. Нельзя использовать зарезервированные слова JS.',
  },
  'EmailSettings:FileAdder:LinkAdder:message_text': {
    eng: 'The file from the variable will be attached when the script is running',
    ru: 'Файл из переменной будет прикреплен к письму во время выполнения сценария',
  },
  'HttpResponseSettings:vars_table_header': {
    eng: 'Variable',
    ru: 'Переменная',
  },
  'HttpResponseSettings:vars_table_header_value': {
    eng: 'Value',
    ru: 'Значение',
  },
  'HttpResponseSettings:headers_table_header': {
    eng: 'Header',
    ru: 'Заголовок',
  },
  'HttpResponseSettings:headers_table_header_value': {
    eng: 'Value',
    ru: 'Значение',
  },
  'HttpResponseSettings:headers_button': {
    eng: 'Add header',
    ru: 'Добавить заголовок',
  },
  'HttpResponseSettings:vars_button': {
    eng: 'Add variable',
    ru: 'Добавить переменную',
  },
  'ActivationsMenuType:title_pattern': {
    eng: 'By pattern',
    ru: 'По паттерну',
  },
  'ActivationsMenuType:description_pattern': {
    eng: 'Patterns contain formal rules describing keywords and expressions',
    ru: 'Паттерн содержит формальные правила, описывающие ключевые слова и выражения',
  },
  'ActivationsMenuType:title_intent': {
    eng: 'By intent',
    ru: 'По интенту',
  },
  'ActivationsMenuType:description_intent': {
    eng: 'An intent is an NLU unit representing a set of phrases, the client’s intention, and other metadata',
    ru: 'Интент — это единица NLU, которая объединяет в себе набор фраз, намерение клиента и метаинформацию',
  },
  'ActivationsMenuType:title_event': {
    eng: 'By event',
    ru: 'По событию',
  },
  'ActivationsMenuType:description_event': {
    eng: 'Events can occur in the channel, account, or project during bot runtime',
    ru: 'Событие происходит в канале, аккаунте или проекте во время работы бота',
  },
  'EventActivationGroup:all': {
    eng: 'All events',
    ru: 'Все события',
  },
  'EventActivation:selected_event_description': {
    eng: 'Description',
    ru: 'Описание',
  },
  'EventActivation:make_global': {
    eng: 'Global trigger',
    ru: 'Глобальный способ активации',
  },
  'EventActivation:make_global_description': {
    eng: 'Available from anywhere in the script',
    ru: 'Будет доступен из любой точки сценария',
  },
  'EventActivation:fromStateLabelAsToState': {
    eng: 'Go to state',
    ru: 'Перейти в шаг',
  },
  'EventActivation:stateSelectPlaceholderAsToState': {
    eng: 'New or existing state name',
    ru: 'Название нового или существующего шага',
  },
  'EventActivation:fromStateLabel': {
    eng: 'Expect trigger from state',
    ru: 'Ожидать активацию из шага',
  },
  'EventActivation:stateSelectPlaceholder': {
    eng: 'Select state',
    ru: 'Выберите шаг',
  },
  'EventActivation:onlyThisState': {
    eng: 'Ignore triggers from nested states',
    ru: 'Игнорировать активацию из вложенных шагов',
  },
  'EventActivation:onlyThisStateAsToState': {
    eng: 'Ignore transitions from nested states',
    ru: 'Игнорировать переход из вложенных шагов',
  },
  'EventActivation:onlyThisStateDescription': {
    eng: 'Transitions to the current state will be possible strictly from the state specified above, but not from its nested states.',
    ru: 'Переход в текущий шаг будет возможен строго из шага, указанного выше, но не из его вложенных.',
  },
  'EventActivation:onlyThisStateAsToStateDescription': {
    eng: 'Transitions to the state specified above will be possible strictly from the current state, but not from its nested states.',
    ru: 'Переход в шаг, указанный выше, будет возможен строго из текущего шага, но не из его вложенных.',
  },
  'PatternsActivation:initialLabel': {
    eng: 'Pattern trigger',
    ru: 'Паттерн активации',
  },
  'PatternsActivation:initialPlaceholder': {
    eng: 'Enter pattern',
    ru: 'Введите паттерн',
  },
  'PatternsActivation:warningMessage': {
    eng: 'Increasing the number of patterns may lead to slower bot performance. Consider using intents instead.',
    ru: 'Увеличение количества паттернов может снизить производительность бота. Воспользуйтесь интентами.',
  },
  JGraphSoon: {
    eng: 'The $[1] tag will become available for editing soon.',
    ru: 'Редактирование тега $[1] скоро станет доступно.',
  },
  'JGraphSoon:intentGroup': {
    eng: 'Intent groups cannot be edited in J‑Graph at the moment. Use the code editor instead.',
    ru: 'Редактирование групп интентов через J‑Graph пока недоступно. Воспользуйтесь редактором кода.',
  },
  'IntentActivationEdit:label': {
    eng: 'User phrase (intent)',
    ru: 'Фраза клиента (интент)',
  },
  'IntentActivationEdit:placeholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'IntentActivationEdit:OpenIntentCreationModal': {
    eng: 'Create intent',
    ru: 'Создать интент',
  },
  'ActionAddingMenu:ActivationEvents_title': {
    eng: 'Triggers',
    ru: 'Способы активации',
  },
  'ActionAddingMenu:BotResponses_title': {
    eng: 'Bot phrase',
    ru: 'Реплика бота',
  },
  'ActionAddingMenu:LogicResponses_title': {
    eng: 'Logic',
    ru: 'Логика',
  },
  'ActionAddingMenu:MetaResponses_title': {
    eng: 'Actions',
    ru: 'Действия',
  },
  'EventsGroup:title': {
    eng: 'User phrases and events',
    ru: 'Фразы клиента и события',
  },
  'EventsGroup:onlyActivations:noMatch': {
    eng: 'Any other phrase',
    ru: 'Любая другая фраза',
  },
  'ReactionItemValue:noMatch': {
    eng: 'Any other phrase',
    ru: 'Любая другая фраза',
  },
  'TransitionMenu-incoming': {
    eng: 'Incoming',
    ru: 'Входящие',
  },
  'TransitionMenu-outgoing': {
    eng: 'Outgoing',
    ru: 'Исходящие',
  },
  'CommonActivationSelectState:create': {
    eng: 'Create state',
    ru: 'Создать шаг',
  },
  'IntentActivationEdit:createIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
  },
  'ScenarioStart:placeholder': {
    eng: 'Script start',
    ru: 'Сценарий начнется здесь',
  },
  'TransitionSettings:CreateNewState': {
    eng: 'Create a new state',
    ru: 'Создать новый шаг',
  },
  'TransitionSettings:UseExistingState': {
    eng: 'Use an existing one',
    ru: 'Использовать существующий',
  },
  'Email:success': {
    eng: 'Sent successfully',
    ru: 'Успешная отправка',
  },
  'Email:error': {
    eng: 'Error',
    ru: 'Ошибка',
  },
  'Email:title': {
    eng: 'Send email',
    ru: 'Отправка email',
  },
  'ReactionItem HttpRequest': {
    eng: 'HTTP request',
    ru: 'HTTP-запрос',
  },
  'StatesGroup:Title': {
    eng: 'Group',
    ru: 'Группа',
  },
  'IncomingOutgoingConnections:incomingTitle': {
    eng: 'Incoming links',
    ru: 'Входящие связи',
  },
  'IncomingOutgoingConnections:outgoingTitle': {
    eng: 'Outgoing links',
    ru: 'Исходящие связи',
  },
  'CreationScreenMenu:inGroupInfoTitle': {
    eng: 'Full state name',
    ru: 'Полное название шага',
  },
  'CreationScreenMenu:parentSelectionTitle': {
    eng: 'Nest into another state',
    ru: 'Вложить в шаг',
  },
  'CreationScreenMenu:parentSelectionPlaceholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'CreationScreenMenu:NameField:Error:AlreadyExist': {
    eng: 'A state with that name already exists',
    ru: 'Шаг с таким названием уже существует',
  },
  'CreationScreenMenu:NameField:Error:DoubleVerticalSlashes': {
    eng: 'The name must not contain two “|” characters in a row',
    ru: 'Название не должно содержать два символа «|» подряд',
  },
  'CreationScreenMenu:NameField:Error:AlreadyExistTheme': {
    eng: 'A theme with that name already exists: {themeName}',
    ru: 'Тема с таким названием уже существует: {themeName}',
  },
  'ScreenBlocksList reactions': {
    eng: 'Bot reactions',
    ru: 'Реакции бота',
  },
  'ScreenBlocksList:PhrasesAndEvents': {
    eng: 'User phrases and events',
    ru: 'Фразы клиента и события',
  },
  'ScreenBlocksList:awaitedActions_text': {
    eng: 'Configure the paths the script can follow after all bot reactions',
    ru: 'Добавьте пути, по которым может пойти сценарий после реакций бота',
  },
  'ActivationItem intent': {
    eng: 'User phrase',
    ru: 'Фраза клиента',
  },
  'ActivationItem intent!': {
    eng: 'User phrase',
    ru: 'Фраза клиента',
  },
  'ActivationItem intentGroup': {
    eng: 'Group of phrases',
    ru: 'Группа фраз',
  },
  'ActivationItem intentGroup!': {
    eng: 'Group of phrases',
    ru: 'Группа фраз',
  },
  'RightSideMenu:NewActivation': {
    eng: 'New trigger',
    ru: 'Новая активация',
  },
  'RightSideMenu:AddActivation': {
    eng: 'Add trigger',
    ru: 'Добавить активацию',
  },
  'RightSideMenu:Actions:Rename': {
    eng: 'Rename state',
    ru: 'Переименовать шаг',
  },
  'RightSideMenu:blockActions:Copy': {
    eng: 'Copy block ({cmd})',
    ru: 'Копировать блок ({cmd})',
  },
  'RightSideMenu:Actions:Paste': {
    eng: 'Paste block ({cmd})',
    ru: 'Вставить блок ({cmd})',
  },
  'RightSideMenu:Actions:Copy': {
    eng: 'Copy state ({cmd})',
    ru: 'Копировать шаг ({cmd})',
  },
  'ActivationsAddingMenu:title': {
    eng: 'Triggers determine the conditions when the state can become active. Select a trigger type, then configure it.',
    ru: 'Способы активации задают условия, при которых сценарий перейдет в шаг. Выберите тип активации, а затем настройте ее.',
  },
  'ButtonSettings-reaction': {
    eng: 'Button press reaction',
    ru: 'Действие по нажатию',
  },
  'ButtonSettings-state-state': {
    eng: 'Go to state',
    ru: 'Перейти в шаг',
  },
  'ButtonSettings-state-link': {
    eng: 'Go to link',
    ru: 'Открыть ссылку',
  },
  'ButtonSettings:create-new-state': {
    eng: 'New',
    ru: 'Новый',
  },
  'ButtonSettings:choose-existing-state': {
    eng: 'Existing',
    ru: 'Существующий',
  },
  'ScreenBody:BlockTitle_buttons': {
    eng: 'Button group',
    ru: 'Группа кнопок',
  },
  'ScreenBody:BlockTitle_reactions': {
    eng: 'Other reactions',
    ru: 'Другие реакции',
  },
  'ReactionItem buttons': {
    eng: 'Button group',
    ru: 'Группа кнопок',
  },
  'FastReactionItem buttons': {
    eng: 'Buttons',
    ru: 'Кнопки',
  },
  'AnswerSettings:Tabs:Say': {
    eng: 'Bot says',
    ru: 'Бот произнесет',
  },
  'AnswerSettings:Tabs:Write': {
    eng: 'Bot writes',
    ru: 'Бот напишет',
  },
  'AnswerSettings:Tabs:Say:Hint': {
    eng: 'You can adjust phrase pronunciation using',
    ru: 'Управляйте произношением реплики при помощи',
  },
  'AnswerSettings:Tabs:Say:HintPart2': {
    eng: 'speech synthesis markup',
    ru: 'разметки синтеза речи',
  },
  'TtsOperatorsInfo:Tabs:Tag': {
    eng: 'Tag',
    ru: 'Тег',
  },
  'TtsOperatorsInfo:Tabs:Description': {
    eng: 'Description',
    ru: 'Описание',
  },
  'TtsOperatorsInfo:Title': {
    eng: 'Speech synthesis markup',
    ru: 'Разметка синтеза речи',
  },
  'TtsOperatorsInfo:CopyNotificationText': {
    eng: 'Copied',
    ru: 'Скопировано',
  },
  'TtsOperatorsInfo:Documentation': {
    eng: 'Documentation',
    ru: 'Документация',
  },
  'AnswerSettings:RandomHint:Title': {
    eng: 'Tip',
    ru: 'Совет',
  },
  'AnswerSettings:RandomHint:Description': {
    eng: 'If you want the bot to choose among several phrases to answer with, use the <b>Random answer</b> reaction.',
    ru: 'Чтобы бот мог ответить одной из нескольких реплик, используйте реакцию <b>Случайный ответ</b>.',
  },
  'AnswerSettings:HtmlEnabler': {
    eng: 'Text formatting',
    ru: 'Форматирование текста',
  },
  'AnswerSettings:LinkTextFormattingDocs': {
    eng: 'Text formatting is not supported by all channels',
    ru: 'Форматирование текста поддерживают не все каналы',
  },
  'AnswerSettings:CreateRandomAnswer': {
    eng: 'Use it',
    ru: 'Использовать',
  },
  'EmptyMode:CreationPage:Title': {
    eng: 'This is a great place to start your script',
    ru: 'Это отличное место для начала сценария',
  },
  'EmptyMode:CreationPage:ActionButton': {
    eng: 'Start',
    ru: 'Начать',
  },
  'EmptyMode:CreateNoMatchForm:Title': {
    eng: 'State for unrecognized requests',
    ru: 'Шаг для нераспознанных фраз',
  },
  'EmptyMode:CreateForm:Title': {
    eng: 'New start state',
    ru: 'Новый стартовый шаг',
  },
  'EmptyMode:Errors:NameAlreadyExist': {
    eng: 'This state already exists',
    ru: 'Такой шаг уже существует',
  },
  'EmptyMode:CreateForm:StartName': {
    eng: 'State name',
    ru: 'Название шага',
  },
  'EmptyMode:CreateForm:StartName:Placeholder': {
    eng: 'For example, Start',
    ru: 'Например, Start',
  },
  'EmptyMode:CreateNoMatchForm:Placeholder': {
    eng: 'For example, NoMatch_01',
    ru: 'Например, NoMatch_01',
  },
  'EmptyMode:CreateNoMatchForm:Description': {
    eng: 'We will add a noMatch event trigger to the new state. It will activate the state if the bot fails to recognize the user request.',
    ru: 'Мы добавим в новый шаг активацию по событию noMatch. Шаг сработает, если бот не распознает фразу клиента.',
  },
  'EmptyMode:CreateForm:Description': {
    eng: 'We will create a new state that the script will start from. If you want to have the script start from an existing state, select it and click “Make this the start state”.',
    ru: 'Мы создадим новый шаг, с которого будет начинаться сценарий. Если вы хотите сделать стартовым уже существующий шаг, выберите его и нажмите «Сделать шаг стартовым».',
  },
  'EmptyMode:CreateForm:Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'EmptyMode:CreateForm:Submit': {
    eng: 'Create',
    ru: 'Создать',
  },
  'EmptyMode:WarningTitle': {
    eng: 'The script has no start state',
    ru: 'В сценарии нет стартового шага',
  },
  'EmptyMode:NotExist:WarningTitle': {
    eng: 'The script has no state for handling unrecognized requests',
    ru: 'В сценарии нет шага для обработки нераспознанных фраз',
  },
  'EmptyMode:WarningFixButton': {
    eng: 'Fix this issue',
    ru: 'Исправить',
  },
  'EmptyMode:ScreenBanner:Description': {
    eng: 'Do you want the script to start from this state? We can add a script start trigger to it.',
    ru: 'Вы хотите, чтобы сценарий начинался с этого шага? Мы можем добавить в него активацию по старту сценария.',
  },
  'EmptyMode:ScreenBanner:ActionButton': {
    eng: 'Make this the start state',
    ru: 'Сделать шаг стартовым',
  },
  'EmptyMode:InvalidScreenName': {
    eng: 'Invalid state name',
    ru: 'Некорректное имя шага',
  },
  'RenameState:Modal:InvalidName:Hint': {
    eng: 'The name of this state contains a “/” character, and you cannot rename it in J-Graph. Switch to the code editor to do so.',
    ru: 'В названии этого шага есть символ «/», поэтому переименовать его в J-Graph нельзя. Вы можете сделать это через редактор кода.',
  },
  'RenameState:Modal:SubmitTitle': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'RenameState:Modal:Title': {
    eng: 'New state name',
    ru: 'Новое название шага',
  },
  'RenameState:Modal:Name:Placeholder': {
    eng: 'Enter name',
    ru: 'Введите название',
  },
  'JGraphGuideTour:Step:1': {
    eng: 'Hi! This is the J‑Graph visual editor. Would you like to have a quick tour around here?',
    ru: 'Здравствуйте! Это графический редактор сценариев J‑Graph. Хотите узнать, как с ним работать?',
  },
  'JGraphGuideTour:Step:2': {
    eng: 'The dialog script consists of states which are displayed on the canvas. Find the start state and click on its title.',
    ru: 'Сценарий диалога состоит из шагов, которые вы видите на холсте. Найдите стартовый шаг и нажмите на его заголовок.',
  },
  'JGraphGuideTour:Step:3': {
    eng: 'This is the state editing menu. Usually, states contain some bot reactions and phrases that users may reply with.',
    ru: 'Это меню редактирования шага. Обычно шаг состоит из реакций бота и фраз, которые клиент может сказать боту в ответ.',
  },
  'JGraphGuideTour:Step:4': {
    eng: 'Possible bot reactions include text messages, images, buttons, and many other items.',
    ru: 'В качестве реакций бот может отправлять текст, изображения, кнопки и другие элементы.',
  },
  'JGraphGuideTour:Step:5': {
    eng: 'User phrases represent what people can say to the bot in response to its reactions.',
    ru: 'Фразы клиента — это то, что человек может сказать боту в ответ на его реакции.',
  },
  'JGraphGuideTour:Step:6': {
    eng: 'Add a new text block to the start state.',
    ru: 'Добавьте в стартовый шаг новый блок с текстом.',
  },
  'JGraphGuideTour:Step:7': {
    eng: 'Next, add a user phrase so that it makes the bot go to another state.',
    ru: 'Далее добавьте фразу клиента и переход в другой шаг по этой фразе.',
  },
  'JGraphGuideTour:Step:8': {
    eng: 'Finally, you can test the way your bot works.',
    ru: 'Теперь вы можете протестировать, как работает ваш бот.',
  },
  'JGraphGuideTour:Step:9': {
    eng: 'This is the test widget. When you launch it, the bot begins the script from the start state. Now enter the phrase you’ve just added to the state.',
    ru: 'Это тестовый виджет. При его запуске бот сразу переходит на стартовый шаг. Введите фразу, которую вы только что добавили в шаг.',
  },
  'JGraphGuideTour:Step:10': {
    eng: 'The state where the bot goes to is highlighted on the canvas.',
    ru: 'На холсте подсвечивается шаг, в который переходит бот.',
  },
  'JGraphGuideTour:Step:11': {
    eng: 'That’s it for now. Thank you and good luck doing bot development!',
    ru: 'На этом пока все. Спасибо и успехов в создании ботов!',
  },
  'JGraphGuideTour:Actions:Skip': {
    eng: 'Skip for now',
    ru: 'В другой раз',
  },
  'JGraphGuideTour:Actions:Next': {
    eng: 'Next',
    ru: 'Дальше',
  },
  'JGraphGuideTour:Actions:Back': {
    eng: 'Back',
    ru: 'Назад',
  },
  'JGraphGuideTour:Actions:Close': {
    eng: 'Close',
    ru: 'Закрыть',
  },
  'JGraphGuideTour:Actions:Last': {
    eng: 'Last',
    ru: 'Последний',
  },
  'JGraphGuideTour:Actions:OpenModal': {
    eng: 'Open modal window',
    ru: 'Открыть модальное окно',
  },
  'JGraphCailaIntentItemValue:ChooseTransition': {
    eng: '+ select transition',
    ru: '+ выбрать переход',
  },
  'JGraphCailaIntentItemValue:AddPhrasesToIntentPlaceholder': {
    eng: '+ add phrase examples',
    ru: '+ добавить примеры фразы',
  },
  'JGraphCailaIntentChooseCreation:Create': {
    eng: 'Create',
    ru: 'Создать',
  },
  'JGraphCailaIntentChooseCreation:Choose': {
    eng: 'Select',
    ru: 'Выбрать',
  },
  'JGraphCailaIntentChooseCreation:helperText': {
    eng: 'Tip',
    ru: 'Подсказка',
  },
  'JGraphCailaIntentChooseCreation:helperText:Line1': {
    eng: '— save and add another phrase',
    ru: '— сохранить и добавить еще одну фразу',
  },
  'JGraphCailaIntentChooseCreation:helperText:Line2': {
    eng: '— go to phrase settings',
    ru: '— перейти к настройке фразы',
  },
  'JGraphCailaIntentChooseCreation:EnterPhrasePlaceholder': {
    eng: 'Create phrase',
    ru: 'Создайте фразу',
  },
  'JGraphCailaIntentChooseCreation:createLabel': {
    eng: 'New phrase',
    ru: 'Новая фраза',
  },
  'JGraphCailaIntents:noMatchHelper': {
    eng: 'This block will handle all unrecognized user phrases',
    ru: 'Нераспознанные фразы клиента попадут в этот блок',
  },
  'NewCommonActivationSelectState:buttonText_toggleStateCreationAndSelection': {
    eng: 'Select where to go',
    ru: 'Выбрать, куда перейти',
  },
  'NewCommonActivationSelectState:ChooseStateOrCreateTitle': {
    eng: 'The bot should go…',
    ru: 'Бот должен перейти…',
  },
  'NewCommonActivationSelectState:Radio_1_text': {
    eng: 'To a new state',
    ru: 'В новый шаг',
  },
  'NewCommonActivationSelectState:Radio_2_text': {
    eng: 'To an existing state',
    ru: 'В существующий шаг',
  },
  'PhrasesAddingToggle:phrase': {
    eng: 'Add alternative examples',
    ru: 'Добавить альтернативные примеры',
  },
  'PhrasesAddingToggle:systemIntentTooltip': {
    eng: 'System intents cannot be extended with custom examples',
    ru: 'Системные интенты нельзя расширить своими примерами',
  },
  'PhrasesAddingToggle:knowledgeBase_cant_edit_from_here': {
    eng: '',
    ru: 'Для редактирования интентов FAQ пройдите в Базу знаний',
  },
  'NewCommonActivationSelectState:newStateSelectPlaceholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'NewSelectIntent:create intent': {
    eng: 'Create intent',
    ru: 'Создать интент',
  },
  'ChangeGroupModal:Modal:Title': {
    eng: 'Change state nesting level',
    ru: 'Изменение вложенности шага',
  },
  'ChangeGroupModal:Modal:SubmitTitle': {
    eng: 'Change',
    ru: 'Изменить',
  },
  'ChangeGroupModal:Modal:Label': {
    eng: 'Select the level where the state should be moved:',
    ru: 'Выберите уровень, на который хотите переместить шаг:',
  },
  'ChangeGroupModal:Main_script_level': {
    eng: 'Main script level',
    ru: 'Главный уровень сценария',
  },
  'Themes:MainTheme': {
    eng: 'Root theme',
    ru: 'Корневая тема',
  },
  'CurrentGroupPath:Main_Scenario': {
    eng: 'Main script',
    ru: 'Главный сценарий',
  },
  'CurrentGroupPath:UpInScenario': {
    eng: 'Move one level up',
    ru: 'На уровень выше',
  },
  'JGraphGuideTour:MainModal:Action:Next': {
    eng: 'Start learning now',
    ru: 'Начать обучение сейчас',
  },
  'KnowledgeBase:html_title': {
    eng: 'Knowledge base',
    ru: 'База знаний',
  },
  'StatusIndicator:Status:NoConnect': {
    eng: 'No connection',
    ru: 'Нет соединения',
  },
  'StatusIndicator:Status:Saving': {
    eng: 'Saving…',
    ru: 'Сохранение…',
  },
  'StatusIndicator:Status:ErrorSaving': {
    eng: 'Error saving the script',
    ru: 'Ошибка сохранения',
  },
  'StatusIndicator:Status:Saved': {
    eng: 'Saved',
    ru: 'Сохранено',
  },
  'StatusIndicator:SavedTooltip:Title': {
    eng: 'Saved',
    ru: 'Сохранено',
  },
  'StatusIndicator:SavedTooltip:Description': {
    eng: 'All changes are saved automatically when you are connected to the Internet.',
    ru: 'Все изменения автоматически сохраняются при подключении к интернету.',
  },
  'StatusIndicator:ErrorTooltip:Title': {
    eng: 'Error saving the script',
    ru: 'Ошибка сохранения сценария',
  },
  'StatusIndicator:ErrorTooltip:Description': {
    eng: 'We couldn’t save your script. Please wait for a moment and try again. Contact support if the error persists.',
    ru: 'Мы не смогли сохранить сценарий. Пожалуйста, подождите и повторите попытку. Если ошибка будет сохраняться, обратитесь в службу поддержки.',
  },
  'StatusIndicator:DiscardChanges': {
    eng: 'Discard changes',
    ru: 'Откатить изменения',
  },
  'StatusIndicator:NoConnectionTooltip:Title': {
    eng: 'No Internet connection',
    ru: 'Нет соединения с интернетом',
  },
  'StatusIndicator:NoConnectionTooltip:Description': {
    eng: 'The server is unreachable, or your Internet connection failed. Check your connection and try again.',
    ru: 'Сервер недоступен, или у вас отсутствует соединение с интернетом. Проверьте подключение и повторите попытку.',
  },
  'StatusIndicator:TryAgain': {
    eng: 'Try again',
    ru: 'Повторить попытку',
  },
  'RenderingModal:BuildingScenarioTitle': {
    eng: 'Building script',
    ru: 'Собираем сценарий',
  },
  'RenderingModal:ExportingTitle': {
    eng: 'Exporting script',
    ru: 'Экспортируем сценарий',
  },
  'RenderingModal:Below25Percentage': {
    eng: 'Creating states…',
    ru: 'Создаем шаги…',
  },
  'RenderingModal:Below50Percentage': {
    eng: 'Drawing links…',
    ru: 'Чертим связи…',
  },
  'RenderingModal:More50Percentage': {
    eng: 'Painting labels…',
    ru: 'Красим метки…',
  },
  'RenderingModal:More75Percentage': {
    eng: 'Preparing image…',
    ru: 'Готовим изображение…',
  },
  'JGToolbar:Search': {
    eng: 'Search',
    ru: 'Поиск',
  },
  'JGToolbar:OtherActions': {
    eng: 'More actions',
    ru: 'Другие действия',
  },
  'JGToolbar:Export': {
    eng: 'Export',
    ru: 'Экспортировать',
  },
  'JGToolbar:Help': {
    eng: 'Help',
    ru: 'Помощь',
  },
  'JGToolbar:Help:Url': {
    eng: '#{config.botadmin.helpUrl}/visual-editor/',
    ru: '#{config.botadmin.helpUrl}/visual-editor/',
  },
  'JGToolbar:ShowMenuButton': {
    eng: 'New state',
    ru: 'Новый шаг',
  },
  'JGToolbar:StickyNote': {
    eng: 'Sticky note',
    ru: 'Стикер',
  },
  'JGToolbar:Autoplacement': {
    eng: 'Layout',
    ru: 'Расстановка',
  },
  'Autoplacement:Type:Simple': {
    eng: 'Default layout',
    ru: 'Обычная расстановка',
  },
  'Autoplacement:Type:Alternative': {
    eng: 'Alternative layout',
    ru: 'Альтернативная расстановка',
  },
  'Autoplacement:Type:Hint': {
    eng: 'You can undo the auto-layout result using the Ctrl + Z key combination.\n\nIf you make changes to the script after applying the layout, undoing it won’t be possible.',
    ru: 'Результат авторасстановки можно отменить сочетанием клавиш Ctrl + Z.\n\nЕсли вы поменяете сценарий после расстановки, отменить ее будет нельзя.',
  },
  'ModalCreatePng:title': {
    eng: 'Export script as PNG',
    ru: 'Экспорт сценария как PNG',
  },
  'ModalCreatePng:text': {
    eng: 'The image will contain all elements on the canvas. The export will take some time.',
    ru: 'В изображение попадут все элементы холста. Экспорт займет некоторое время.',
  },
  'ModalCreatePng:actionButtonText': {
    eng: 'Export',
    ru: 'Экспортировать',
  },
  'CommitButton:Save': {
    eng: 'Save script',
    ru: 'Сохранить сценарий',
  },
  'CommitButton:Discard': {
    eng: 'Discard changes',
    ru: 'Отменить изменения',
  },
  'CommitButton:Update': {
    eng: 'Update script version',
    ru: 'Обновить версию сценария',
  },
  'CommitButton:UpdateText': {
    eng: 'There is a new version of the script in the repository.<br>Click to download the updates.',
    ru: 'В репозитории появилась новая версия сценария. Нажмите, чтобы загрузить обновления.',
  },
  'JGraph:LabellingToolMenu:HighLightConnectorsButton:tooltip_on': {
    eng: 'Highlight this state’s links',
    ru: 'Подсветить связи этого шага',
  },
  'JGraph:LabellingToolMenu:HighLightConnectorsButton:tooltip_off': {
    eng: 'Disable link highlighting',
    ru: 'Отключить подсветку связей',
  },
  'JGraph:LabellingToolMenu:ToggleButton': {
    eng: 'Add label',
    ru: 'Добавить метку',
  },
  'GuideTour:TagName_a:1': {
    eng: 'In this block, you can configure the text the bot will send to the user as a message in the chat or synthesized speech (depending on the channel).',
    ru: 'В этом блоке вы можете задать текст, который бот отправит пользователю как сообщение в чате или синтезированную речь (в зависимости от канала).',
  },
  'GuideTour:TagName_a:2': {
    eng: 'Several channels support message formatting with HTML markup.',
    ru: 'Некоторые каналы поддерживают форматирование сообщений при помощи HTML-разметки.',
  },
  'GuideTour:TagName_image': {
    eng: 'This block allows the bot to send an image to the chat. You can upload a file from your device or provide a link to an external resource.',
    ru: 'Этот блок позволяет боту отправить в чат изображение. Вы можете загрузить файл с компьютера или вставить ссылку на внешний ресурс.',
  },
  'GuideTour:TagName_audio': {
    eng: 'This block allows the bot to send an audio file to the chat or play it back as a phrase (in the phone channel). You can upload a file from your device or provide a link to an external resource.',
    ru: 'Этот блок позволяет боту отправить в чат аудиофайл или воспроизвести его как реплику (в телефонном канале). Вы можете загрузить файл с компьютера или вставить ссылку на внешний ресурс.',
  },
  'GuideTour:TagName_if:1': {
    eng: 'This block allows the bot to execute different reactions depending on some condition. Specify the condition as a JavaScript expression in the <b>if</b> field.',
    ru: 'Этот блок позволяет боту выполнить разные реакции в зависимости от какого-либо условия. Условие записывается в поле <b>if</b> как выражение на JavaScript.',
  },
  'GuideTour:TagName_if:2': {
    eng: 'Under the <b>if</b> field, configure the reactions that will be executed when this condition is true.',
    ru: 'Под полем <b>if</b> задайте реакции, которые выполнит бот, если это условие будет истинно.',
  },
  'GuideTour:TagName_if:3': {
    eng: 'Select <b>Add else</b> if you need to configure alternative reactions. They will be executed if the condition is false.',
    ru: 'Нажмите <b>Добавить else</b>, если вам нужно задать альтернативные реакции. Они будут выполнены, когда условие будет ложно.',
  },
  'GuideTour:TagName_if:4': {
    eng: 'You can change <b>else</b> to <b>elseif</b>. Each <b>elseif</b> can contain an alternative condition, which will be checked if the previous one is false.',
    ru: 'Вы можете поменять <b>else</b> на <b>elseif</b>. После каждого <b>elseif</b> можно задать альтернативное условие — оно будет проверяться, если будет ложно предыдущее.',
  },
  'GuideTour:TagName_random': {
    eng: 'This block allows the bot to execute one randomly chosen reaction.',
    ru: 'Этот блок позволяет боту выполнить одну из заданных реакций, выбранную случайным образом.',
  },
  'GuideTour:TagName_buttons': {
    eng: 'This block displays one or several buttons in the chat. Each button can be assigned a label and a reaction when the button is selected: whether the bot will go to another script state or open a link. If the action is not configured, selecting the button will send its label text to the bot.',
    ru: 'Этот блок выводит в чат одну или несколько кнопок. Для каждой кнопки можно задать текст и действие по нажатию: переход в другой шаг сценария или переход по ссылке. Если действие не задано, нажатие на кнопку отправит боту ее текст.',
  },
  'GuideTour:TagName_go': {
    eng: 'This block switches the dialog context to another script state.',
    ru: 'Этот блок переводит контекст диалога в другой шаг сценария.',
  },
  'GuideTour:TagName_script': {
    eng: 'This block allows writing custom JavaScript code that will be executed on reaching the state.',
    ru: 'Этот блок позволяет задать произвольный код на JavaScript, который будет выполнен при попадании в шаг.',
  },
  'FloatingIslandPopover:FullStateNameTitle': {
    eng: 'Full state name',
    ru: 'Полное название стейта:',
  },
  'JGraph:LabellingToolMenu:ToggleIncomingConnectionsButton:tooltip_off': {
    eng: 'Expand incoming links',
    ru: 'Показать входящие связи',
  },
  'JGraph:LabellingToolMenu:ToggleIncomingConnectionsButton:tooltip_on': {
    eng: 'Collapse incoming links',
    ru: 'Скрыть входящие связи',
  },
  'JGraph:LabellingToolMenu:ToggleOutgoingConnectionsButton:tooltip_off': {
    eng: 'Expand outgoing links',
    ru: 'Показать исходящие связи',
  },
  'JGraph:LabellingToolMenu:ToggleOutgoingConnectionsButton:tooltip_on': {
    eng: 'Collapse outgoing links',
    ru: 'Скрыть исходящие связи',
  },
  'JGraph:IslandConnector:HiddenConnections': {
    eng: 'Collapsed links: $[1]',
    ru: 'Скрытые связи: $[1]',
  },
  'JGraph:IslandConnector:OutgoingChildrenHiddenConnections': {
    eng: 'Collapsed child connections: $[1]',
    ru: 'Связи вложенных шагов: $[1]',
  },
  'JGraph:LabellingToolMenu:CollapseBlockButton:tooltip_off': {
    eng: 'Expand step',
    ru: 'Развернуть шаг',
  },
  'JGraph:LabellingToolMenu:CollapseBlockButton:tooltip_on': {
    eng: 'Collapse step',
    ru: 'Свернуть шаг',
  },
  'JGraph:Stickers:AuthorLabel:Created': {
    eng: 'Created:',
    ru: 'Создано:',
  },
  'JGraph:Stickers:AuthorLabel:Updated': {
    eng: 'Last update:',
    ru: 'Последнее изменение:',
  },
  'JGraph:ContextMenu:AddState': {
    eng: 'Create a new state',
    ru: 'Создать новый шаг',
  },
  'JGraph:ContextMenu:AddSticker': {
    eng: 'Add a sticky note',
    ru: 'Добавить стикер',
  },
  'JGraph:ContextMenu:PasteState': {
    eng: 'Paste state ({cmd})',
    ru: 'Вставить шаг ({cmd})',
  },
  'JGraph:ContextMenu:RenameTheme': {
    eng: 'Rename theme',
    ru: 'Переименовать тему',
  },
  'JGraph:ContextMenu:DeleteTheme': {
    eng: 'Delete theme',
    ru: 'Удалить тему',
  },
  'AuthorLabel:StickerSadminText': {
    eng: 'Unknown user',
    ru: 'Неизвестный пользователь',
  },
  'CustomTag:TypeMismatch': {
    eng: 'The value is not displayed because it is not suitable for a field of the <b>{type}</b> type.',
    ru: 'Значение не отображается, так как не подходит для поля типа <b>{type}</b>.',
  },
  'CustomTag:TypeMismatch:InvalidFormatCaseByVariable': {
    eng: 'The value is not displayed because the field contains a JavaScript expression.',
    ru: 'Значение не отображается, так как в поле указано JavaScript-выражение.',
  },
  'CustomTag:TypeMismatch:MaybeVariable': {
    eng: 'The field contains a JavaScript expression. The value is known only during script execution.',
    ru: 'В поле указано JavaScript-выражение. Значение известно только во время выполнения сценария.',
  },
  'CopyPastePermissionTipModal:Title': {
    eng: 'Allow clipboard access',
    ru: 'Разрешите доступ к буферу обмена',
  },
  'CopyPastePermissionTipModal:Button:Submit': {
    eng: 'Close',
    ru: 'Закрыть',
  },
  'CopyPastePermissionTipModal:Content:Base': {
    eng: 'You will be able to copy and paste data while working with J-Graph.',
    ru: 'Вы сможете копировать и вставлять данные во время работы с J-Graph.',
  },
  'CopyPastePermissionTipModal:Content:Banner': {
    eng: 'If the window does not appear, click the “View site information” button and allow access to the clipboard.',
    ru: 'Если окно не появилось, нажмите кнопку «Сведения о сайте» и разрешите доступ к буферу обмена.',
  },
  'CopyPastePermissionTipModal:Images:Base': {
    eng: '/img/jgraph/copyPasteTipEng.png',
    ru: '/img/jgraph/copyPasteTip.png',
  },
  'CopyPastePermissionTipModal:Images:Base:Tovie': {
    eng: '/img/jgraph/copyPasteTipTovieEng.png',
    ru: '/img/jgraph/copyPasteTipTovie.png',
  },
  'ThemeCreationMenu:title': {
    eng: 'Theme name',
    ru: 'Название темы',
  },
  'ThemeCreationMenu:Create': {
    eng: 'Create theme',
    ru: 'Создать тему',
  },
  'ThemeCreationMenu:Error:NameField:Empty': {
    eng: 'Enter a theme name',
    ru: 'Введите название темы',
  },
  'ThemeCreationMenu:NameField:Error:AlreadyExistRootTheme': {
    eng: 'A theme with this name already exists — it is the root theme.',
    ru: 'Тема с таким названием уже существует — это корневая тема',
  },
  'ThemeCreationMenu:NameField:Error:AlreadyExist': {
    eng: 'A theme with this name already exists.',
    ru: 'Тема с таким названием уже существует.',
  },
  'ThemeCreationMenu:NameField:Error:DoubleSlashes': {
    eng: 'The name must not contain two “/” characters in a row',
    ru: 'Название не должно содержать два символа «/» подряд',
  },
  'ThemeCreationMenu:NameField:Error:DoubleVerticalSlashes': {
    eng: 'The name must not contain two “|” characters in a row',
    ru: 'Название не должно содержать два символа «|» подряд',
  },
  'ThemeCreationMenu:Hint:ThisIsRootTheme': {
    eng: 'The “/” theme is called the root theme.',
    ru: 'Тема «/» называется корневой.',
  },
  'ThemeCreationMenu:NameField:Error:StateNamesIntersection': {
    eng: 'Full path to a state includes the theme name. States with these paths already exist: {paths}.',
    ru: 'Полный путь к шагу включает в себя название темы. Шаги с такими путями уже существуют: {paths}.',
  },
  'JGToolbar:NewTheme': {
    eng: 'New theme',
    ru: 'Новая тема',
  },
  'RightSideMenu:DeleteTheme:Title': {
    eng: 'Delete theme?',
    ru: 'Удалить тему?',
  },
  'RightSideMenu:DeleteTheme:Description': {
    eng: 'All theme states and links to other themes will be deleted.',
    ru: 'Будут удалены все шаги, которые содержит тема, а также связи с другими темами.',
  },
  'RightSideMenu:DeleteTheme:Submit': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'RenameThemeModal:Title': {
    eng: 'Theme name',
    ru: 'Название темы',
  },
  'RenameThemeModal:FieldName:Label': {
    eng: 'New name',
    ru: 'Новое название',
  },
  'JGGlobalSearch:SearchHint': {
    eng: '{matches} found in {elements}',
    ru: 'Найдено {matches} в {elements}',
  },
  'JGGlobalSearch:NoResults': {
    eng: 'No matches. \nTry changing the request or filters.',
    ru: 'Нет совпадений. \nПопробуйте изменить запрос или фильтры.',
  },
  'JGGlobalSearch:SearchHint:Matches_zero': {
    eng: '$[1] matches',
    ru: '$[1] совпадений',
  },
  'JGGlobalSearch:SearchHint:Matches_one': {
    eng: '$[1] match',
    ru: '$[1] совпадение',
  },
  'JGGlobalSearch:SearchHint:Matches_two': {
    eng: '$[1] matches',
    ru: '$[1] совпадений',
  },
  'JGGlobalSearch:SearchHint:Matches_few': {
    eng: '$[1] matches',
    ru: '$[1] совпадений',
  },
  'JGGlobalSearch:SearchHint:Matches_many': {
    eng: '$[1] matches',
    ru: '$[1] совпадений',
  },
  'JGGlobalSearch:SearchHint:Matches_other': {
    eng: '$[1] matches',
    ru: '$[1] совпадений',
  },
  'JGGlobalSearch:SearchHint:Elements_zero': {
    eng: '$[1] elements',
    ru: '$[1] элементах',
  },
  'JGGlobalSearch:SearchHint:Elements_one': {
    eng: '$[1] element',
    ru: '$[1] элементе',
  },
  'JGGlobalSearch:SearchHint:Elements_two': {
    eng: '$[1] elements',
    ru: '$[1] элементах',
  },
  'JGGlobalSearch:SearchHint:Elements_few': {
    eng: '$[1] elements',
    ru: '$[1] элементах',
  },
  'JGGlobalSearch:SearchHint:Elements_many': {
    eng: '$[1] elements',
    ru: '$[1] элементах',
  },
  'JGGlobalSearch:SearchHint:Elements_other': {
    eng: '$[1] elements',
    ru: '$[1] элементах',
  },
  'JGGlobalSearch:IconHint:ANSWER': {
    eng: 'Text',
    ru: 'Текст',
  },
  'JGGlobalSearch:IconHint:AUDIO': {
    eng: 'Audio',
    ru: 'Аудио',
  },
  'JGGlobalSearch:IconHint:BUTTONS': {
    eng: 'Button group',
    ru: 'Группа кнопок',
  },
  'JGGlobalSearch:IconHint:CUSTOM_TAG': {
    eng: 'Action',
    ru: 'Действие',
  },
  'JGGlobalSearch:IconHint:EMAIL': {
    eng: 'Action',
    ru: 'Действие',
  },
  'JGGlobalSearch:IconHint:HTTP_REQUEST': {
    eng: 'Action',
    ru: 'Действие',
  },
  'JGGlobalSearch:IconHint:EVENT': {
    eng: 'Event',
    ru: 'Событие',
  },
  'JGGlobalSearch:IconHint:EXAMPLE': {
    eng: 'Example',
    ru: 'Пример',
  },
  'JGGlobalSearch:IconHint:GO': {
    eng: 'Transition',
    ru: 'Переход',
  },
  'JGGlobalSearch:IconHint:IF': {
    eng: 'Condition',
    ru: 'Условие',
  },
  'JGGlobalSearch:IconHint:IMAGE': {
    eng: 'Image',
    ru: 'Изображение',
  },
  'JGGlobalSearch:IconHint:INLINE_BUTTONS': {
    eng: 'Button group',
    ru: 'Группа кнопок',
  },
  'JGGlobalSearch:IconHint:INTENT': {
    eng: 'Intent',
    ru: 'Интент',
  },
  'JGGlobalSearch:IconHint:INTENT_GROUP': {
    eng: 'Intent group',
    ru: 'Группа интентов',
  },
  'JGGlobalSearch:IconHint:PATTERN': {
    eng: 'Pattern',
    ru: 'Паттерн',
  },
  'JGGlobalSearch:IconHint:RANDOM': {
    eng: 'Random answer',
    ru: 'Случайный ответ',
  },
  'JGGlobalSearch:IconHint:SCRIPT': {
    eng: 'Code',
    ru: 'Код',
  },
  'JGGlobalSearch:IconHint:STATE': {
    eng: 'Step',
    ru: 'Шаг',
  },
  'JGGlobalSearch:IconHint:STATE_LABEL': {
    eng: 'Label',
    ru: 'Метка',
  },
  'JGGlobalSearch:IconHint:STICKER': {
    eng: 'Sticky note',
    ru: 'Стикер',
  },
  'JGGlobalSearch:IconHint:THEME': {
    eng: 'Theme',
    ru: 'Тема',
  },
  'JGGlobalSearch:IconHint:VIDEO': {
    eng: 'Video',
    ru: 'Видео',
  },
  'JGGlobalSearch:Filter:ALL': {
    eng: 'All elements',
    ru: 'Все элементы',
  },
  'JGGlobalSearch:Filter:REACTION': {
    eng: 'Bot reactions',
    ru: 'Реакции бота',
  },
  'JGGlobalSearch:Filter:PHRASES_AND_EVENTS': {
    eng: 'User phrases and events',
    ru: 'Фразы клиента и события',
  },
  'JGGlobalSearch:Filter:ACTIVATION': {
    eng: 'Triggers',
    ru: 'Способы активации',
  },
  'JGGlobalSearch:Filter:THEME': {
    eng: 'Themes',
    ru: 'Темы',
  },
  'JGGlobalSearch:Filter:STATE': {
    eng: 'States',
    ru: 'Шаги',
  },
  'JGGlobalSearch:Filter:STICKER': {
    eng: 'Sticky notes',
    ru: 'Стикеры',
  },
  'JGGlobalSearch:Filter:LABEL': {
    eng: 'Labels',
    ru: 'Метки',
  },
  'JGraph:Hierarchy:Title': {
    eng: 'Script structure',
    ru: 'Структура сценария',
  },
  'JGraph:Hierarchy:Actions:Dots:Tooltip': {
    eng: 'Actions',
    ru: 'Действия',
  },
  'JGraph:Hierarchy:Actions:Hide:Tooltip': {
    eng: 'Hide',
    ru: 'Скрыть',
  },
  'JGraph:Hierarchy:Actions:ExpandAll': {
    eng: 'Expand all',
    ru: 'Развернуть все',
  },
  'JGraph:Hierarchy:Actions:CollapseAll': {
    eng: 'Collapse all',
    ru: 'Свернуть все',
  },
  'JGraph:Hierarchy:Actions:Help': {
    eng: 'Help',
    ru: 'Помощь',
  },
  'JGraph:Hierarchy:Actions:Help:Url': {
    eng: '#{config.botadmin.helpUrl}/visual-editor/script-development/script-structure/',
    ru: '#{config.botadmin.helpUrl}/visual-editor/script-development/script-structure/',
  },
  'HierarchyContextMenu:Create': {
    eng: 'Create',
    ru: 'Создать',
  },
  'HierarchyContextMenu:Create:Step': {
    eng: 'State',
    ru: 'Шаг',
  },
  'HierarchyContextMenu:Create:InnerStep': {
    eng: 'Nested state',
    ru: 'Вложенный шаг',
  },
  'HierarchyContextMenu:Create:Theme': {
    eng: 'Theme',
    ru: 'Тему',
  },
  'HierarchyContextMenu:Rename': {
    eng: 'Rename',
    ru: 'Переименовать',
  },
  'HierarchyContextMenu:Delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'HierarchyContextMenu:CreateTheme': {
    eng: 'Create theme',
    ru: 'Создать тему',
  },
};
