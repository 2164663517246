export const packageNameByAnnouncementType: { [key: string]: string } = {
  uniqueWillEndSoon: 'jacp_unique_for_user_100',
  nluMinutesWillEndSoon: 'jacp_100',
  telephonyMinutesWillEndSoon: 'jacp_100_justai',
  asrMinutesWillEndSoon: 'jacp_100_asr',
  cailaDirectApiCallsWillEndSoon: 'billing.additionalPocket.jacp_caila_direct_api_calls_1000',
  uniqueExceed: 'jacp_unique_for_user_100',
  nluMinutesExceed: 'jacp_100',
  telephonyMinutesExceed: 'jacp_100_justai',
  asrMinutesExceed: 'jacp_100_asr',
  cailaDirectApiCallsExceed: 'billing.additionalPocket.jacp_caila_direct_api_calls_1000',
};
