import Konva from 'konva';
import React, { FC, useMemo } from 'react';
import { Group, Text } from 'react-konva';
import { TransitionCircle } from '../TransitionCircle';
import { getConnectorFrom } from '../../../../../reducers/JGraph.reducer/Graph';
import { TagNames } from '../../../utils/types';
import { useRenderBlocksContext } from '../RenderBlocks';

const TEXT_PROPS_NAME = {
  fontSize: 14,
  fontFamily: 'Roboto',
  fill: '#6A7178',
  fontStyle: 'bold',
  padding: 4,
};
const TEXT_PROPS_VALUE = {
  fontSize: 14,
  fontFamily: 'Roboto',
  fill: '#333C45',
  fontStyle: 'normal',
  padding: 4,
};

export const CustomTagValueItem: FC<{
  name: string;
  value: string;
  baseWidth: number;
  offsetTop: number;
  globalJBlockIterator: number;
  tagName: TagNames;
  transition?: { index: number; value: string; name: string; debugActive?: boolean };
}> = ({ name, value, baseWidth, offsetTop, transition, tagName, globalJBlockIterator }) => {
  const { prefix, screen } = useRenderBlocksContext();

  const nameWidth = useMemo(() => {
    const nameRender = new Konva.Text({ ...TEXT_PROPS_NAME, text: name + ':' });
    return nameRender.getWidth();
  }, [name]);

  const halfScreen = useMemo(() => (baseWidth - 56) / 2, [baseWidth]);

  if (transition) {
    return (
      <Group y={offsetTop} x={-4}>
        <Text
          {...TEXT_PROPS_VALUE}
          lineHeight={21 / 14}
          fontSize={14}
          letterSpacing={0.02}
          text={name}
          width={baseWidth - 24 - 36 - 24}
          ellipsis={true}
          wrap='none'
        />
        <TransitionCircle
          x={baseWidth - 24 - 36 - 12}
          y={14}
          tagName={tagName}
          hasTransition={!!value}
          name={getConnectorFrom(globalJBlockIterator, screen.pathId, `${prefix}${tagName}`, transition.index)}
          transitionTo={transition.value}
          debugActive={transition.debugActive}
        />
      </Group>
    );
  }

  return (
    <Group y={offsetTop} x={-4}>
      <Text
        x={0}
        {...TEXT_PROPS_NAME}
        width={halfScreen < nameWidth ? halfScreen : undefined}
        ellipsis={true}
        wrap='none'
        text={name + ':'}
      />
      {halfScreen < nameWidth && <Text x={halfScreen - 8} {...TEXT_PROPS_NAME} text=':' />}
      <Text
        x={halfScreen < nameWidth ? halfScreen + 4 : nameWidth - 4}
        {...TEXT_PROPS_VALUE}
        text={typeof value === 'object' ? JSON.stringify(value) : String(value).replace(/\r\n|\r|\n/g, '')}
        width={halfScreen < nameWidth ? halfScreen - 10 : baseWidth - nameWidth - 56}
        ellipsis={true}
        wrap='none'
      />
    </Group>
  );
};
