export const FEETCH_DEPLOY_HISTORY = 'FEETCH_DEPLOY_HISTORY';
export const FEETCH_DEPLOY_HISTORY_PENDING = 'FEETCH_DEPLOY_HISTORY_PENDING';
export const FEETCH_DEPLOY_HISTORY_FULFILLED = 'FEETCH_DEPLOY_HISTORY_FULFILLED';
export const FEETCH_DEPLOY_HISTORY_REJECTED = 'FEETCH_DEPLOY_HISTORY_REJECTED';

export const FEETCH_CHANNEL_DATA = 'FEETCH_CHANNEL_DATA';
export const FEETCH_CHANNEL_DATA_PENDING = 'FEETCH_CHANNEL_DATA_PENDING';
export const FEETCH_CHANNEL_DATA_FULFILLED = 'FEETCH_CHANNEL_DATA_FULFILLED';
export const FEETCH_CHANNEL_DATA_REJECTED = 'FEETCH_CHANNEL_DATA_REJECTED';

export const REFETCH_DEPLOY_HISTORY = 'REFETCH_DEPLOY_HISTORY';
export const REFETCH_DEPLOY_HISTORY_PENDING = 'REFETCH_DEPLOY_HISTORY_PENDING';
export const REFETCH_DEPLOY_HISTORY_FULFILLED = 'REFETCH_DEPLOY_HISTORY_FULFILLED';
export const REFETCH_DEPLOY_HISTORY_REJECTED = 'REFETCH_DEPLOY_HISTORY_REJECTED';

export const DEPLOY_HISTORY_PAGINATE = 'DEPLOY_HISTORY_PAGINATE';
export const DEPLOY_HISTORY_PAGINATE_PENDING = 'DEPLOY_HISTORY_PAGINATE_PENDING';
export const DEPLOY_HISTORY_PAGINATE_FULFILLED = 'DEPLOY_HISTORY_PAGINATE_FULFILLED';
export const DEPLOY_HISTORY_PAGINATE_REJECTED = 'DEPLOY_HISTORY_PAGINATE_REJECTED';

export const SET_CURRENT_BUILD = 'SET_CURRENT_BUILD';
