import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TJBlock } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

import { resortBlocksInScreen, restoreState } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';

type ResortBlocksInScreenPayload = { newBlocks: TJBlock[] };
const resortBlocksInScreenAsync = createAsyncThunk(
  'JGraph/resortBlocksInScreenAsync',
  async (data: ResortBlocksInScreenPayload, thunkAPI) => {
    await thunkAPI.dispatch(resortBlocksInScreen(data));
    const state = thunkAPI.getState() as RootState;
    const newScreen = findScreenByPathId(
      state.JGraphReducer.editMenuBlock?.screen.pathId || '',
      state.JGraphReducer.graph.blocks
    );
    if (newScreen) {
      mainSave$.next({
        type: 'update',
        path: newScreen.path,
        action: () => {
          return thunkAPI.dispatch(updateState(newScreen));
        },
      });
    }
  }
);

export const revertResortBlocksInScreenAsync = createAsyncThunk(
  'JGraph/revertResortBlocksInScreenAsync',
  async (revertEvent: RevertEvent<ResortBlocksInScreenPayload>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    if (!state.JGraphReducer.editMenuBlock?.screen) return;
    const screen = findScreenByPathId(state.JGraphReducer.editMenuBlock?.screen.pathId, prevScreens);
    if (!screen) return;
    thunkAPI.dispatch(restoreState({ screen }));

    mainSave$.next({
      type: 'update',
      path: screen.path,
      action: () => thunkAPI.dispatch(updateState(screen)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertResortBlocksInScreenAsync,
  resortBlocksInScreenAsync
);
