import React, { FC } from 'react';
import { SwitchButton } from '@just-ai/just-ui';

import CustomTagWarningBlock from './CustomTagWarningBlock';
import { withController } from './Controller';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

const BoolFieldView: FC<CommonFieldProps> = ({
  name,
  label,
  onChange,
  value,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
  description,
  isRequired,
}) => {
  return (
    <div className='form-group form-group-fix flex flex-row align-items-start gap-8'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <SwitchButton id={`switch-button-${name}`} onChange={onChange} size='md' value={value} />
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const BoolField = withController(BoolFieldView);
