import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormGroup, InputText, JustSelect, OptionType, RadioButton } from '@just-ai/just-ui';
import classNames from 'classnames';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import { findParentByPathId, getAllStates } from 'reducers/JGraph.reducer/Graph';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';
import { useRightSideMenuContext } from 'modules/JGraph/view/RightSideMenu';
import { dropShouldCreateStateOnSave, ShouldCreateStateOnSave$ } from '../../view/RightSideMenu/hooks';

import { joinPaths } from '../../utils/common';
import classes from './CommonActivationParams.module.scss';

export const NewCommonActivationSelectState: FC<{
  isFromState?: boolean;
  isToState?: boolean;
  isGlobal: boolean;
  fromStateValue: string;
  toStateValue: string;
  onChangeStateValue: (value: string) => unknown;
  onChangeOnlyThisState: (value: boolean) => unknown;
  isStateSelectionOpened?: boolean;
  onlyThisState?: boolean;
  label?: string | React.ReactNode;
}> = ({
  isFromState,
  isToState,
  isGlobal,
  fromStateValue,
  isStateSelectionOpened,
  toStateValue,
  onChangeStateValue,
  label,
  onlyThisState,
  onChangeOnlyThisState,
}) => {
  const { states, selectedTheme } = useAppSelector(state => ({
    selectedTheme: state.JGraphReducer.selectedTheme,
    states: state.JGraphReducer.graph.blocks,
  }));

  const [wayOfLink, setWayOfLink] = useState<string>(toStateValue && toStateValue !== './' ? 'choose' : 'create');
  const [isStateSelectionOpen, setStateSelectionOpen] = useState(
    (!!toStateValue && toStateValue !== './') || isStateSelectionOpened
  );
  const switchToMenu = useCallback(() => {
    setStateSelectionOpen(true);
  }, []);

  const [inputTextValue, setInputTextValue] = useState('');
  const { editMenuScreen } = useRightSideMenuContext();

  const parentState = useMemo(() => {
    return findParentByPathId(states, editMenuScreen.pathId);
  }, [editMenuScreen.pathId, states]);

  const { errorText, triggerValidation } = useNewStateNameValidation();
  const setTextToPipe = useCallback(
    async (value: string) => {
      setInputTextValue(value);

      const validationResult = await triggerValidation(value);
      const error = validationResult.errors?.name?.message || '';

      if (error) return;

      const statePath = joinPaths(parentState?.path ?? selectedTheme?.value, value);

      ShouldCreateStateOnSave$.next({
        value: statePath,
        parentStatePath: parentState?.path,
        theme: selectedTheme?.value,
        callback: () => Promise.resolve(onChangeStateValue(statePath)),
      });
    },
    [onChangeStateValue, parentState?.path, selectedTheme?.value, triggerValidation]
  );

  const allStates = useMemo(() => {
    let returnOptions: OptionType[] = getAllStates(states).map(state => ({
      value: state,
      label: hideRootSlashInPath(state),
    }));
    return returnOptions;
  }, [states]);

  const [localAllStates, setAllStates] = useState(allStates);

  useEffect(() => {
    setAllStates(allStates);
  }, [allStates]);

  const onSelectHandler = useCallback(
    value => {
      if (value && value[0]) {
        onChangeStateValue(value[0] as string);
        return;
      }
      onChangeStateValue('');
    },
    [onChangeStateValue]
  );

  const setRadioActive = useCallback(
    (value: string) => {
      value === 'choose'
        ? dropShouldCreateStateOnSave()
        : ShouldCreateStateOnSave$.next({
            value: inputTextValue,
            parentStatePath: parentState?.path,
            theme: selectedTheme?.value,
            callback: () =>
              Promise.resolve(
                onChangeStateValue(inputTextValue.startsWith('/') ? inputTextValue : '/' + inputTextValue)
              ),
          });
      setWayOfLink(value);
    },
    [inputTextValue, onChangeStateValue, parentState?.path, selectedTheme?.value]
  );

  return (
    <>
      <FormGroup
        tag='div'
        data-test-id='NewCommonActivationSelectState'
        className={classNames(classes.formGroupCustom, { [classes.formGroupDisabled]: isGlobal })}
      >
        {!isStateSelectionOpen && (
          <span
            data-test-id='NewCommonActivationSelectState:toggleStateCreationAndSelection'
            className='pseudoLink'
            onClick={switchToMenu}
          >
            {t('NewCommonActivationSelectState:buttonText_toggleStateCreationAndSelection')}
          </span>
        )}

        {isStateSelectionOpen && (
          <div
            className={classes.stateSelectionAndCreation}
            data-test-id='NewCommonActivationSelectState:ChooseStateOrCreate'
          >
            <div className={classes.title}>{t('NewCommonActivationSelectState:ChooseStateOrCreateTitle')}</div>
            <RadioButton
              data-test-id='NewCommonActivationSelectState:Radio_1'
              label={t('NewCommonActivationSelectState:Radio_1_text')}
              name='stateSelectionAndCreation'
              value='create'
              checked={wayOfLink === 'create'}
              onChange={setRadioActive}
            />
            <div className={classes.RadioOffset} data-test-id='NewCommonActivationSelectState:newPathInput'>
              <InputText
                onChange={setTextToPipe}
                errorText={errorText}
                hint={t('CreationScreenMenu:title:hint')}
                value={inputTextValue}
                disabled={wayOfLink === 'choose'}
              />
            </div>
            <RadioButton
              data-test-id='NewCommonActivationSelectState:Radio_2'
              label={t('NewCommonActivationSelectState:Radio_2_text')}
              name='stateSelectionAndCreation'
              value='choose'
              checked={wayOfLink === 'choose'}
              onChange={setRadioActive}
            />
            <div className={classes.RadioOffset}>
              <JustSelect
                data-test-id='NewCommonActivationSelectState:selectPath'
                // disabled={isGlobal}
                disabled={wayOfLink === 'create'}
                fullWidth
                value={fromStateValue || toStateValue}
                options={localAllStates}
                onChange={onSelectHandler}
                inputPlaceholder={t('NewCommonActivationSelectState:newStateSelectPlaceholder')}
                position='fixed'
              />
            </div>
          </div>
        )}
      </FormGroup>
      {isStateSelectionOpen && (isFromState || isToState) && (
        <>
          <div
            className='ButtonSettings-collapse-custom'
            style={{ width: 'calc(100% + 64px)', margin: '24px 0 24px -32px' }}
          >
            <div className='divider' />
          </div>
          <FormGroup tag='div' className={classNames(classes.formGroup, { [classes.formGroupDisabled]: isGlobal })}>
            <Checkbox
              disabled={isGlobal}
              label={
                <div className={classes.checkboxWithSmall}>
                  <div>
                    {isToState ? t('EventActivation:onlyThisStateAsToState') : t('EventActivation:onlyThisState')}
                  </div>
                  {(!isFromState || !isToState) && (
                    <small>
                      {isToState
                        ? t('EventActivation:onlyThisStateAsToStateDescription')
                        : t('EventActivation:onlyThisStateDescription')}
                    </small>
                  )}
                </div>
              }
              name='onlyThisState'
              value={onlyThisState || false}
              onChange={onChangeOnlyThisState}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};
