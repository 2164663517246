import React, { Component } from 'react';
import localize from '../../../localization';
import { getCurrentDate, getFilterFromLocation } from '../../../pipes/functions';
import * as DiagramActions from '../../../actions/diagramAnalytics.actions';
import moment from 'moment';
import ChartComponent from './PieChart';
import CommonChartControl from './CommonChartControl';
import { isEqual, forEach, max, difference } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isReporterEnabled } from '../../../isAccessFunction';
import { AnalyticsContext } from '@just-ai/analytic-front';
import { convertDatesToX } from '../utils';
import { colorsForLabels } from '../../../reducers/analyticsFilter.reducer';

class PhraseMarksDiagram extends Component {
  static contextType = AnalyticsContext;

  state = {
    graphDialogs: false,
    shouldUpdate: false,
    labelsPhraseColors: [],
  };
  componentWillMount() {
    this.setState({
      labelsPhraseColors: this.props.withReporter
        ? this.context.labelsContext.labelColors
        : this.props.labelsPhraseColors,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { projectShortName } = this.props.match.params;
    const { initDiagramDialogs } = this.props.actions;
    const params = getFilterFromLocation(location.search);
    const nextParams = getFilterFromLocation(nextProps.location.search);
    if (!isEqual(params, nextParams)) {
      if (!!nextParams.dateFrom) {
        nextParams.dateFrom = moment(nextParams.dateFrom).format('x');
      }
      if (!!nextParams.dateTo) {
        nextParams.dateTo = moment(nextParams.dateTo).format('x');
      }

      if (this.state.graphDialogs) {
        initDiagramDialogs(
          projectShortName,
          nextParams,
          isReporterEnabled() ? () => this.context.getLogLabelStats({ filters: nextProps.filters }) : null
        );
      }
    }

    if (this.props.withReporter) {
      this.setState({ labelsPhraseColors: this.context.labelsContext.labelColors });
    }

    if (
      !this.props.withReporter &&
      difference(nextProps.labelsPhraseColors, this.props.labelsPhraseColors).length > 0
    ) {
      this.setState({
        labelsPhraseColors: nextProps.labelsPhraseColors,
      });
    }
  }

  graphDialogsChanged = () => {
    const { projectShortName } = this.props.match.params;
    const { location } = this.props;
    const { initDiagramDialogs } = this.props.actions;

    if (this.graphDialogs.checked) {
      let params = getFilterFromLocation(location.search);
      if (!!params.dateFrom) {
        params.dateFrom = moment(params.dateFrom).format('x');
      }
      if (!!params.dateTo) {
        params.dateTo = moment(params.dateTo).format('x');
      }

      delete params.activeTab;

      if (isReporterEnabled() && Object.keys(params).length === 0) {
        params.dateFrom = getCurrentDate()[0];
        params = convertDatesToX(params);
      }

      const action = isReporterEnabled()
        ? () => this.context.getLogLabelsWithNewFilters({ filters: this.props.filters })
        : null;

      initDiagramDialogs(projectShortName, params, action).then(() => {
        this.setState({
          graphDialogs: this.graphDialogs.checked,
        });
      });
    } else {
      this.setState({
        graphDialogs: this.graphDialogs.checked,
      });
    }
  };
  toggleCommonPhraseLabels = (name, flag) => {
    let newLabelsPhraseColors = [...this.state.labelsPhraseColors];
    const indexPhrase = newLabelsPhraseColors.findIndex(item => {
      return item.name === name;
    });
    if (indexPhrase > -1) {
      newLabelsPhraseColors[indexPhrase].active = flag;
    }

    const setter = async () => {
      await this.setState({
        labelsPhraseColors: newLabelsPhraseColors,
        shouldUpdate: true,
      });
    };

    setter().then(() => {
      this.setState({
        shouldUpdate: false,
      });
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    /* eslint-disable */
    return (
      (!nextProps.phraseFetching && nextProps.phraseFetching !== this.props.phraseFetching) ||
      !this.state.graphDialogs ||
      this.state.shouldUpdate ||
      this.state.graphDialogs !== nextState.graphDialogs
    );
    /* eslint-disable */
  }

  render() {
    const { labelsPhraseColors } = this.state;
    const { phraseCount, phraseTime } = this.props;
    //Phrases
    let roundData = null;
    let phrasePercentages = [];
    if (!!phraseCount) {
      roundData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };
      forEach(phraseCount.labels, (labelsCountItem, key) => {
        let labelsCountItemColor = labelsPhraseColors.find(item => item.name === labelsCountItem);
        if (!!labelsCountItemColor) {
          if (labelsCountItemColor.active) {
            roundData.labels.push(labelsCountItem);
            roundData.datasets[0].data.push(phraseCount.datasets[0].data[key]);
            roundData.datasets[0].backgroundColor.push(labelsCountItemColor.color);
          }

          phrasePercentages.push({
            name: labelsCountItem,
            data: phraseCount.datasets[0].data[key],
          });
        } else {
          roundData.labels.push(labelsCountItem);
          roundData.datasets[0].data.push(phraseCount.datasets[0].data[key]);
          roundData.datasets[0].backgroundColor.push(colorsForLabels[key]);
          phrasePercentages.push({
            name: labelsCountItem,
            data: phraseCount.datasets[0].data[key],
          });
        }
      });
    }

    let barData = null;
    let maxPhraseData = [];
    if (!!phraseTime) {
      barData = {
        labels: phraseTime.labels,
        datasets: [],
      };
      forEach(phraseTime.datasets, (labelsTimeItem, index) => {
        let labelsTimeItemColor = labelsPhraseColors.find(item => item.name === labelsTimeItem.label);
        if (!!labelsTimeItemColor && labelsTimeItemColor.active) {
          maxPhraseData = this.props.sumArray(labelsTimeItem.data, maxPhraseData);
          barData.datasets.push({
            ...labelsTimeItem,
            backgroundColor: labelsTimeItemColor.color,
          });
        }
      });
    }

    let filteredPhraseColors = labelsPhraseColors.filter(item => {
      return !!phrasePercentages.find(perc => {
        return perc.name === item.name;
      });
    });

    return (
      <div className='mb-3'>
        <label className='switch switch-3d switch-xs switch-success'>
          <input
            type='checkbox'
            className='switch-input'
            ref={input => (this.graphDialogs = input)}
            onChange={this.graphDialogsChanged}
            defaultChecked={false}
            id={'phraseMarksLabels'}
          />
          <span className='switch-label' />
          <span className='switch-handle' />
        </label>{' '}
        <label htmlFor={'phraseMarksLabels'}>{localize.translate('Phrase marks')}</label>
        {this.state.graphDialogs ? (
          <div className='row'>
            <div className='col-8 dialogs-session-data-chart'>
              <ChartComponent
                chartData={barData}
                config={{
                  type: 'bar',
                  data: barData,
                  options: {
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontSize: 10,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            stepSize: max(maxPhraseData) < 10 ? 1 : null,
                            fontSize: 10,
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: false,
                      custom: this.props.customChart(),
                      mode: 'x',
                    },
                  },
                }}
              />
            </div>
            <div className='col-2 dialogs-session-data-chart'>
              <ChartComponent
                chartData={roundData}
                config={{
                  data: roundData,
                  options: {
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                      enabled: false,
                      custom: this.props.customChart(),
                    },
                  },
                }}
              />
            </div>
            <div className='col-2'>
              <Scrollbars
                renderThumbVertical={props => <div {...props} className='vertical-scroll_chart' />}
                renderThumbHorizontal={props => <div {...props} className='horizontal-scroll_chart' />}
                maxHeight={300}
              >
                <div className='form-inline'>
                  {filteredPhraseColors.map((labelColor, index) => (
                    <CommonChartControl
                      key={`labelPhraseColor${index}`}
                      percentages={phrasePercentages}
                      {...labelColor}
                      toggleCommonChartLabels={this.toggleCommonPhraseLabels}
                    />
                  ))}
                </div>
              </Scrollbars>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    phraseFetching: state.DiagramAnalyticsReducer.phraseFetching,

    phraseCount: state.DiagramAnalyticsReducer.phraseCount,
    phraseTime: state.DiagramAnalyticsReducer.phraseTime,

    labelsPhraseColors: state.AnalyticsFilterReducer.labelsPhraseColors,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DiagramActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhraseMarksDiagram));
