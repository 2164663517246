import React, { ReactNode } from 'react';
import { Group } from 'react-konva';

import { getThemeName } from 'modules/JGraph/utils/themesUtils';
import { GroupedConnector, JGraphTheme } from 'reducers/JGraph.reducer/types';

import { AutopositionGroup } from '../../parts/AutopositionGroup';
import { AutosizeRect } from '../../parts/AutosizeRect';
import { KIcon, IconNames } from '../../parts/KIcons';

import AccessibleText from 'modules/JGraph/components/AccessibleText';

interface GroupViewProps {
  group: GroupedConnector;
  width: number;
  onSelectTheme: (theme: JGraphTheme) => void;
  children?: ReactNode;
}
const GroupView = ({ children, onSelectTheme, group, width }: GroupViewProps) => {
  return (
    <AutopositionGroup>
      <AutosizeRect fill='#FFFFFF' stroke='#E0E1E3' cornerRadius={4} width={width - 24}>
        <Group y={6} x={12}>
          <KIcon width={16} height={16} x={0} y={0} icon={IconNames.themes} fill='#333C45' />
          <AccessibleText
            x={16 + 8}
            y={0}
            width={width - 80 - 16}
            fontStyle='bold'
            ellipsis
            wrap='none'
            text={getThemeName(group.theme.value)}
            fill='#333C45'
            fontSize={12}
            lineHeight={18 / 12}
            letterSpacing={0.01}
          />
          <KIcon
            width={16}
            height={16}
            x={width - 80 + 14}
            y={0}
            icon={IconNames.signIn}
            fill='#6A7178'
            onClick={() => onSelectTheme(group.theme)}
          />
        </Group>
        {children}
      </AutosizeRect>
    </AutopositionGroup>
  );
};

GroupView.displayName = 'GroupView';

export default React.memo(GroupView);
