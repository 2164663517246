import { Ace } from 'ace-builds';
import { last } from 'lodash';

import { FormatError } from 'services/Formatter/BaseFormatter';
import { codeFormatter, FormattedLanguages } from 'services/Formatter';

export class AceFormatBehaviour {
  private editor!: Ace.Editor;

  init(editor: Ace.Editor) {
    this.editor = editor;
    editor.commands.addCommand({
      name: 'undo',
      bindKey: { win: 'Ctrl-Z', mac: 'Command-Z' },
      exec: function (editor) {
        const cursorPosition = editor.getCursorPosition();
        editor.session.getUndoManager().undo(editor.session, true);
        // @ts-ignore
        const $redoStack = editor.session.getUndoManager().$redoStack;
        const currentRedo = last($redoStack as any[][])?.[0];
        const position = currentRedo?.beforePosition || currentRedo?.start || cursorPosition;
        editor.moveCursorToPosition(position);
      },
    });
    editor.commands.addCommand({
      name: 'redo',
      bindKey: { win: 'Ctrl-Shift-Z|Ctrl-Y', mac: 'Command-Shift-Z|Command-Y' },
      exec: function (editor) {
        editor.session.getUndoManager().redo(editor.session);
        editor.clearSelection();
        // @ts-ignore
        editor.renderer.scrollCursorIntoView(null, 0.5);
      },
    });
  }

  public async format(fileExt: FormattedLanguages): Promise<[string | null, FormatError | null]> {
    const cursorPosition = this.editor.getCursorPosition();
    const session = this.editor.getSession();

    const [formattedCode, error] = await codeFormatter.format(session.getValue(), fileExt);
    if (formattedCode) {
      this.editor.setValue(formattedCode, -1);
      this.editor.moveCursorToPosition(cursorPosition);

      // @ts-ignore
      const undoFormat = session.getUndoManager().$undoStack?.pop() as any[];
      undoFormat.forEach(el => {
        el.beforePosition = cursorPosition;
      });
      // @ts-ignore
      session.getUndoManager().$undoStack.push(undoFormat);
    }
    return [formattedCode, error];
  }
}
