import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { MovementsDataWithFiles } from '@just-ai/api/dist/generated/Editorbe';

import { JGraphTheme } from 'reducers/JGraph.reducer/types';
import batchMoveThemesAndSyncByApi from 'reducers/JGraph.reducer/AsyncActions/batchMoveThemesAndSyncByApi';
import { themesConnectors$ } from 'modules/JGraph/view/ThemesStage/ThemesConnectionsLayer';

import { BlocksPositions, AutoLayoutType } from '../../hooks/useAutoplacement';
import { PlacedNode } from '../AutoLayout/BaseAutoLayout';
import { INodeGraph, GraphOptions } from './INodeGraph';

type MapScreenPathIdToSize = Record<
  string,
  {
    height: number;
    path: string;
    filenames: string[];
  }
>;
export class ThemesNodeGraph implements INodeGraph {
  private mapScreenPathIdToSize: MapScreenPathIdToSize;

  constructor(private themes: JGraphTheme[], private stage: Stage, private dispatch: any) {
    this.mapScreenPathIdToSize = themes.reduce((prevValue, currentItem) => {
      const target = stage.findOne(`.${currentItem.pathId}`) as Konva.Group;
      prevValue[currentItem.pathId] = {
        height: target?.height() ?? 20,
        path: currentItem.value,
        filenames: currentItem.filenames,
      };
      return prevValue;
    }, {} as MapScreenPathIdToSize);
  }

  getGraphOptions(): GraphOptions {
    return {
      nodeSpacing: 100,
    };
  }

  getNodes() {
    return Object.keys(this.mapScreenPathIdToSize).map(id => ({
      id,
      width: 280,
      height: this.mapScreenPathIdToSize[id].height,
      label: this.mapScreenPathIdToSize[id].path,
    }));
  }

  getEdges(): [string, string][] {
    const connectors = themesConnectors$.getValue();
    return connectors.map(el => [el.fromPathId, el.toPathId]);
  }

  async applyNewPositions(placedNodes: PlacedNode[], type: AutoLayoutType) {
    const { positions, positionsToSave } = this.getStatesPositions(placedNodes);

    return this.dispatch(
      batchMoveThemesAndSyncByApi({
        themes: positions,
        positionsToSave,
        type,
      })
    );
  }

  private getStatesPositions(placedNodes: PlacedNode[]) {
    const padY: { [x: number]: number } = {};
    let positionsToSave = {} as Record<string, MovementsDataWithFiles['movementsData']>;
    const positions = placedNodes.reduce((ds, node) => {
      const nodeData = node;
      if (!nodeData || !nodeData.x || !nodeData.y) return ds;
      const x = nodeData.x || 0;
      let y = nodeData.y;

      const nodeInfo = this.mapScreenPathIdToSize[nodeData.id];

      nodeInfo.filenames.forEach(filename => {
        if (!positionsToSave[filename]) {
          positionsToSave[filename] = [];
        }
      });

      if (typeof y !== 'number' || isNaN(y)) {
        if (padY[nodeData.x]) {
          y = padY[nodeData.x];
          padY[nodeData.x] = y + nodeData.height! + 100;
        } else {
          y = 0;
          padY[nodeData.x] = nodeData.height! + 100;
        }
      }

      nodeInfo.filenames.forEach(filename => {
        positionsToSave[filename]?.push({
          target: nodeInfo.path,
          x,
          y,
        });
      });

      return Object.assign(ds, { [nodeData.id]: { x, y } });
    }, {} as BlocksPositions);
    return { positions, positionsToSave };
  }
}
