import React, { FC, Fragment, useCallback, useState } from 'react';
import { useRightSideMenuContext } from '../index';
import { TagNames, TJBlock, TTagParameters } from '../../../utils/types';
import classes from './HttpRequestSettings.module.scss';
import classNames from 'classnames';
import { Button, FormGroup, IconButton, InputText, JustSelect, Tabs } from '@just-ai/just-ui';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { t } from 'localization';
import AceEditor from 'react-ace';
import { DEFAULT_EDITOR_PROPS } from '../../../../Editor/components/CodeEditor';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import { CommonLabel } from './CommonCustomTagSettings/CommonLabel';
import { tWithCheck } from '../../../../../localization';

const httpMethods: OptionType[] = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
];

export const HttpRequestSettings: FC<{}> = () => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();
  const editBlock = getEditBlockTag() as TJBlock<TagNames.HttpRequest>;
  const mapTagParametersToObj: Record<string, TTagParameters<string, any>> = {};
  editBlock.tagParameters.forEach(tagParam => {
    mapTagParametersToObj[tagParam.name] = tagParam;
  });
  const [tab, setTab] = useState('vars');

  const selectMethod = (value: (string | number)[] | null) => {
    let paramIndex = editBlock.tagParameters.findIndex(tagParam => tagParam.name === 'method');
    let newTagParams = [...editBlock.tagParameters];
    let newTagParam: TTagParameters<'method'> = {
      name: 'method',
      required: newTagParams[paramIndex]?.required || false,
      value: value && value.length > 0 ? (value[0] as string) : '',
    };
    if (paramIndex > -1) {
      newTagParams.splice(paramIndex, 1, newTagParam);
    } else {
      newTagParams.push(newTagParam);
    }
    onChangeCommonTag({
      tagParameters: newTagParams,
    });
  };

  const changeCommonStringParam = (name: 'body' | 'url') => (value: string) => {
    let paramIndex = editBlock.tagParameters.findIndex(tagParam => tagParam.name === name);
    let newTagParams = [...editBlock.tagParameters];
    let newTagParam: TTagParameters<'body' | 'url'> = {
      name: name,
      required: newTagParams[paramIndex]?.required || false,
      value: value,
    };
    if (paramIndex > -1) {
      newTagParams.splice(paramIndex, 1, newTagParam);
    } else {
      newTagParams.push(newTagParam);
    }
    onChangeCommonTag({
      tagParameters: newTagParams,
    });
  };

  return (
    <div className={classes.HttpRequest}>
      <div className={classNames('JGraph-RightSideMenu-commonContainer', classes.HttpRequestMain)}>
        <div className={classes.methodAndUrl}>
          <div className={classes.method}>
            <FormGroup tag='div'>
              <CommonLabel
                name='method'
                label={tWithCheck('CustomTag:HttpRequest:method') || t('HttpRequest:method')}
                isRequired={true}
                description={tWithCheck('CustomTag:HttpRequest:method:description')}
              />
              <JustSelect
                fullWidth
                options={httpMethods}
                value={mapTagParametersToObj.method?.value}
                onChange={selectMethod}
              />
            </FormGroup>
          </div>
          <div className={classes.url}>
            <FormGroup tag='div'>
              <CommonLabel
                name='url'
                label={tWithCheck('CustomTag:HttpRequest:url') || t('HttpRequest:url')}
                isRequired={true}
                description={tWithCheck('CustomTag:HttpRequest:url:description')}
              />
              <InputText
                id='url'
                defaultValue={mapTagParametersToObj.url?.value}
                onChange={changeCommonStringParam('url')}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className={classes.tabs}>
        <Tabs
          activeTab={tab}
          onChange={setTab}
          className={classes.justui_tabs}
          tabs={[
            {
              dataTestId: 'HttpRequestSettings.response',
              name: 'Response',
              value: 'vars',
            },
            {
              dataTestId: 'HttpRequestSettings.body',
              name: 'Body',
              value: 'body',
            },
            {
              dataTestId: 'HttpRequestSettings.header',
              name: 'Headers',
              value: 'headers',
            },
          ]}
        />
      </div>
      <div className={classNames('JGraph-RightSideMenu-commonContainer', classes.requestSettings)}>
        {['vars', 'headers'].includes(tab) && (
          <CommonResponseHeadersTab key={tab} name={tab} values={mapTagParametersToObj[tab]?.value || []} />
        )}
        {tab === 'body' && (
          <AceEditor
            {...DEFAULT_EDITOR_PROPS}
            value={mapTagParametersToObj.body?.value}
            mode='json'
            theme='github'
            onChange={changeCommonStringParam('body')}
          />
        )}
      </div>
    </div>
  );
};

const CommonResponseHeadersTab: FC<{ values: { name: string; value: string }[]; name: string }> = ({
  values,
  name,
}) => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();

  const removeVar = useCallback(
    index => () => {
      const editBlock = getEditBlockTag() as TJBlock<TagNames.HttpRequest>;
      const paramIndex = editBlock.tagParameters.findIndex(tagParam => tagParam.name === name);
      let newTagParams = [...editBlock.tagParameters];
      const newValues = [...values];
      newValues.splice(index, 1);
      let newTagParam = {
        name: newTagParams[paramIndex].name,
        required: newTagParams[paramIndex].required,
        value: newValues,
      };
      // @ts-ignore
      newTagParams.splice(paramIndex, 1, newTagParam);
      onChangeCommonTag({
        tagParameters: newTagParams,
      });
    },
    [onChangeCommonTag, getEditBlockTag, values, name]
  );

  const addVar = useCallback(() => {
    const editBlock = getEditBlockTag() as TJBlock<TagNames.HttpRequest>;
    const paramIndex = editBlock.tagParameters.findIndex(tagParam => tagParam.name === name);
    let newTagParams = [...editBlock.tagParameters];
    const newValues = [...values];
    newValues.push({
      name: '',
      value: '',
    });
    let newTagParam = {
      name: newTagParams[paramIndex].name,
      required: newTagParams[paramIndex].required,
      value: newValues,
    };
    // @ts-ignore
    newTagParams.splice(paramIndex, 1, newTagParam);
    onChangeCommonTag({
      tagParameters: newTagParams,
    });
  }, [onChangeCommonTag, getEditBlockTag, values, name]);

  const onChange = useCallback(
    (field: 'name' | 'value', index: number) => (value: string) => {
      const editBlock = getEditBlockTag() as TJBlock<TagNames.HttpRequest>;
      const paramIndex = editBlock.tagParameters.findIndex(tagParam => tagParam.name === name);
      let newTagParams = [...editBlock.tagParameters];
      const newValues = [...values];
      newValues[index][field] = value;
      let newTagParam = {
        name: newTagParams[paramIndex].name,
        required: newTagParams[paramIndex].required,
        value: newValues,
      };
      // @ts-ignore
      newTagParams.splice(paramIndex, 1, newTagParam);
      onChangeCommonTag({
        tagParameters: newTagParams,
      });
    },
    [onChangeCommonTag, getEditBlockTag, values, name]
  );

  return (
    <>
      <div className={classes.responseTab}>
        <div className={classes.title}>{t(`HttpResponseSettings:${name}_table_header`)}</div>
        <div className={classes.title}>{t(`HttpResponseSettings:${name}_table_header_value`)}</div>
        <div className={classes.title} />
        {values.map((parsedValue, index) => (
          <Fragment key={`${name}_${values.length}_${index}`}>
            <div className={classes.headerName}>
              <InputText
                placeholder='Text'
                defaultValue={parsedValue.name}
                autoFocus={!parsedValue.name}
                onChange={onChange('name', index)}
                data-test-id={`HttpResponseSettings_Text_${index}`}
              />
            </div>
            <div className={classes.headerValue}>
              <InputText
                placeholder='Value'
                defaultValue={parsedValue.value}
                onChange={onChange('value', index)}
                data-test-id={`HttpResponseSettings_Value_${index}`}
              />
            </div>
            <div className={classes.headerDelete}>
              <IconButton
                name='farTrashAlt'
                flat
                color='secondary'
                onClick={removeVar(index)}
                data-test-id={`HttpResponseSettings_Delete_${index}`}
              />
            </div>
          </Fragment>
        ))}
      </div>
      <div>
        <Button color='secondary' flat iconLeft='farPlus' className={classes.addBtn} onClick={addVar}>
          {t(`HttpResponseSettings:${name}_button`)}
        </Button>
      </div>
    </>
  );
};
