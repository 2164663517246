import { createAsyncThunk } from '@reduxjs/toolkit';
import { ComplexRenameStateData } from 'modules/Editor/api/client';

import { RootState } from 'storeTypes';

import { GraphApi, renameNameInPath } from '../utils';
import { getValidKonvaName } from '../Graph';
import { setEditMenuBlock } from '../index';

const complexRenameState = createAsyncThunk(
  'JGraph/complexRenameState',
  async (complexRenameStateData: Omit<ComplexRenameStateData, 'files'>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const files = Object.keys(state.JGraphReducer.graph.files).map(_filename => ({
      filename: _filename,
      lastModification: state.JGraphReducer.graph.files[_filename],
    }));

    const editMenuBlock = state.JGraphReducer.editMenuBlock;
    if (editMenuBlock?.screen.path && editMenuBlock.screen.path === complexRenameStateData.statePath) {
      const newPath = renameNameInPath(complexRenameStateData.statePath, complexRenameStateData.newValue);
      const newEditMenuBlock = {
        ...editMenuBlock,
        screen: {
          ...editMenuBlock.screen,
          path: newPath,
          value: complexRenameStateData.newValue,
          pathId: getValidKonvaName(newPath),
        },
      };
      thunkAPI.dispatch(setEditMenuBlock(newEditMenuBlock));
    }

    const { data: lastModification } = await GraphApi.complexRenameState(userAccountId || accountId, projectShortName, {
      ...complexRenameStateData,
      files,
    });
    return lastModification;
  }
);

export default complexRenameState;
