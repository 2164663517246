import {
  REGISTER_FULFILLED,
  REGISTER_PENDING,
  REGISTER_REJECTED,
  REMOVE_REGISTER_ALERT,
  CLEAN_REGISTER_ERRORS,
  GET_PASSWORD_LENGTH_PENDING,
  GET_PASSWORD_LENGTH_REJECTED,
  GET_PASSWORD_LENGTH_FULFILLED,
} from '../../constants/register.actions';

const InitialState = {
  errors: [],
  fetching: false,
  passwordLength: null,
};

export default function RegisterReducer(state = InitialState, action) {
  switch (action.type) {
    case REGISTER_FULFILLED: {
      return {
        ...state,
        fetching: false,
        errors: [],
      };
    }
    case REGISTER_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case REGISTER_REJECTED: {
      return {
        ...state,
        fetching: false,
        errors:
          action.payload.response.data.errors || [action.payload.response.data.error] || action.payload.response.data,
      };
    }
    case REMOVE_REGISTER_ALERT: {
      let newRegisterAlert = [...state.errors];
      newRegisterAlert.splice(action.key, 1);
      return {
        ...state,
        errors: newRegisterAlert,
      };
    }
    case CLEAN_REGISTER_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    case GET_PASSWORD_LENGTH_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_PASSWORD_LENGTH_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_PASSWORD_LENGTH_FULFILLED: {
      return {
        ...state,
        fetching: false,
        passwordLength: action.payload.data.passwordLength,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
