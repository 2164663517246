import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@just-ai/just-ui';
import { blockmodalLocalization } from './blockmodal.loc';
import localize from 'localization';
import { t } from 'localization';
import { Subject } from 'rxjs';
localize.addTranslations(blockmodalLocalization);

export const blockModalSubject$ = new Subject<boolean>();

export const BlockModal = React.memo(() => {
  const [open, setOpen] = useState(false);
  const close = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const subscription = blockModalSubject$.subscribe(setOpen);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Modal
      isOpen={open}
      title={t(`BlockModal:title`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Close')}
      onCancelClick={close}
    >
      <p>{t(`BlockModal:text`)}</p>
    </Modal>
  );
});
