import React, { FC, useCallback } from 'react';
import { Group } from 'react-konva';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import { IconNames, KIcon } from '../parts/KIcons';
import { TransitionCircle } from '../parts/TransitionCircle';
import { getConnectorPropsFromName, getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { TagNames } from '../../utils/types';

import { scrollToTargetGlobal$ } from '../../utils/stageUtils';

import AccessibleText from 'modules/JGraph/components/AccessibleText';

type IncomingOutgoingItemProps = {
  path: string;
  displayPath?: string;
  connections: TConnector[];
  width?: number;
};

export const IncomingItem: FC<IncomingOutgoingItemProps> = ({ path, displayPath, connections, width = 190 }) => {
  const goToState = useCallback(
    event => {
      event.cancelBubble = true;
      scrollToTargetGlobal$.next({ targetPathId: getValidKonvaName(path) });
    },
    [path]
  );

  const padding = 12;
  const gap = 8;
  const actionsContainerWidth = 40;
  const innerContainerWidth = width - padding * 2;
  const textContainerWidth = innerContainerWidth - gap - actionsContainerWidth;
  return (
    <AutopositionGroup offsetY={0} padding={0} name={path} isScreen={true} type='IncomingItem'>
      <Group>
        <AccessibleText
          y={0}
          x={0}
          text={hideRootSlashInPath(displayPath || path)}
          fill='#0D1822'
          fontSize={12}
          lineHeight={18 / 12}
          letterSpacing={0.01}
          width={textContainerWidth}
          wrap='none'
          ellipsis
        />
        <KIcon
          width={16}
          height={16}
          x={textContainerWidth + gap}
          y={0}
          icon={IconNames.stateLocation}
          onClick={goToState}
        />
        {connections.map(connection => {
          const parseConnectionFrom = getConnectorPropsFromName(connection.from);
          return (
            <TransitionCircle
              key={connection.from}
              x={textContainerWidth + gap + actionsContainerWidth - 8}
              y={8}
              name={connection.from}
              tagName={parseConnectionFrom.tagName as TagNames}
              transitionTo={connection.toNodeOriginalPath}
              hasTransition={true}
              canStartConnection={false}
              debugActive={connection.debugActive}
            />
          );
        })}
      </Group>
    </AutopositionGroup>
  );
};
