var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { default as ReactSelect, components } from 'react-select';
import classNames from 'classnames';
import './Selects.scss';
import { Icon } from "../Icon";
import { Checkbox } from "../Checkbox";
const CustomOption = (props) => {
    const onClick = () => {
        var _a;
        if ((_a = props.data) === null || _a === void 0 ? void 0 : _a.onClick) {
            // @ts-ignore
            props.selectProps.onMenuClose();
            props.data.onClick();
        }
    };
    return (_jsx(components.Option, Object.assign({}, props, { children: _jsxs("span", { "data-test-id": props.selectProps.id + '.' + props.data.value, onClick: onClick, className: classNames({ 'multiple-option': props.selectProps.isMulti }), children: [props.selectProps.isMulti &&
                    _jsx(Checkbox, { name: props.label, value: props.isSelected, disabled: props.isDisabled }), props.children] }) })));
};
const CustomMultiValue = (props) => {
    return props.selectProps.groupOfMultipleValuesName && props.selectProps.value.length > 1
        ? props.index === 0
            ? _jsxs(components.MultiValue, Object.assign({}, props, { children: [props.selectProps.groupOfMultipleValuesName, ": ", props.selectProps.value.length] }))
            : null
        : _jsx(components.MultiValue, Object.assign({}, props, { children: props.children }));
};
const CustomMultiValueRemove = (props) => {
    const clearValue = () => {
        const { isMulti, onChange } = props.selectProps;
        onChange(isMulti ? [] : null, { action: 'clear' });
    };
    const innerProps = props.selectProps.groupOfMultipleValuesName
        ? Object.assign(Object.assign({}, props.innerProps), { onClick: clearValue, onTouchEnd: clearValue }) : props.innerProps;
    return _jsx(components.MultiValueRemove, Object.assign({}, props, { innerProps: innerProps, children: _jsx(Icon, { name: 'faTimes', size: 'sm' }) }));
};
const CustomClearIndicator = (props) => {
    const _a = props.innerProps, { ref } = _a, restInnerProps = __rest(_a, ["ref"]);
    return (_jsx("div", Object.assign({}, restInnerProps, { children: _jsx("div", { onMouseDown: (e) => {
                e.stopPropagation();
                e.preventDefault();
                props.clearValue();
            }, className: 'ReactSelectPrefix__indicator ReactSelectPrefix__clear-indicator clear', children: _jsx(Icon, { name: 'faTimesCircle', className: 'btn-icon', color: 'secondary' }) }) })));
};
const formatGroupLabel = (data) => (_jsx("div", { children: _jsx("span", { children: data.label }) }));
export default function Select(_a) {
    var { name, className, classNamePrefix, arrowLeft, dark, size = 'sm', ['data-test-id']: dataTestId = '', separatedOptions, isValid = true, closeMenuOnSelect, isMulti, arrowsIconName = 'faSort' } = _a, restProps = __rest(_a, ["name", "className", "classNamePrefix", "arrowLeft", "dark", "size", 'data-test-id', "separatedOptions", "isValid", "closeMenuOnSelect", "isMulti", "arrowsIconName"]);
    return _jsx(ReactSelect, Object.assign({ className: classNames(className, 'ReactSelect', { 'arrowLeft': arrowLeft, 'dark': dark, 'sm': size === 'sm', 'lg': size === 'lg', 'xs': size === 'xs', 'separatedOptions': separatedOptions, 'justui-select-error': !isValid }), classNamePrefix: 'ReactSelectPrefix', formatGroupLabel: formatGroupLabel, hideSelectedOptions: false, components: {
            DropdownIndicator: () => _jsx("div", { className: 'ReactSelectPrefix__indicator arrow-indicator', children: _jsx(Icon, { name: arrowsIconName, size: size, color: 'secondary' }) }),
            Option: (optionProps) => _jsx(CustomOption, Object.assign({}, optionProps)),
            ClearIndicator: (props) => _jsx(CustomClearIndicator, Object.assign({}, props)),
            MultiValue: (props) => _jsx(CustomMultiValue, Object.assign({}, props)),
            MultiValueRemove: (props) => _jsx(CustomMultiValueRemove, Object.assign({}, props))
        }, id: dataTestId, closeMenuOnSelect: closeMenuOnSelect || !isMulti, isMulti: isMulti }, restProps));
}
;
