import {
  FETCH_USER_LOG_PENDING,
  FILTER_DATA,
  DROP_FILTER,
  FETCH_USER_LOG_FULFILLED,
  FETCH_USER_LOG_REJECTED,
  FILTER_USER_LOG_PENDING,
  FILTER_USER_LOG_REJECTED,
  FILTER_USER_LOG_FULFILLED,
} from '../../constants/userLog.actions';

import localize from '../../localization';

const InitialState = {
  filterData: getInitialFilterData(),
  userLogItems: [],
  totalPages: 0,
  totalElements: 0,
};

export default function UserLogReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_USER_LOG_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FETCH_USER_LOG_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case FETCH_USER_LOG_FULFILLED: {
      return {
        ...state,
        userLogItems: action.payload.data.content,
        fetching: false,
        totalPages: action.payload.data.totalPages,
        page: action.payload.data.number,
        totalElements: action.payload.data.totalElements,
      };
    }

    case FILTER_USER_LOG_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FILTER_USER_LOG_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case FILTER_USER_LOG_FULFILLED: {
      return {
        ...state,
        userLogItems: action.payload.data.content,
        fetching: false,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
      };
    }

    case FILTER_DATA: {
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [action.what]: action.value,
          dateFromTimeLimit: action.dateFromTimeLimit,
        },
      };
    }

    case DROP_FILTER: {
      return {
        ...state,
        filterData: getInitialFilterData(),
        page: 0,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

function getInitialFilterData() {
  const emptyFilterData = {
    userEmail: '',
    dateFrom: null,
    dateTo: null,
    botProject: '',
    eventTypes: [],
  };

  const data = { ...emptyFilterData };
  const savedFilterDataStr = localStorage.getItem('USER_LOG_FILTER_DATA');
  if (Boolean(savedFilterDataStr)) {
    try {
      const savedFilterData = JSON.parse(savedFilterDataStr);

      if (Boolean(savedFilterData.dateFrom)) {
        data.dateFrom = savedFilterData.dateFrom;
      }

      if (Boolean(savedFilterData.dateTo)) {
        data.dateTo = savedFilterData.dateTo;
      }

      if (Boolean(savedFilterData.userEmail)) {
        data.userEmail = savedFilterData.userEmail;
      }

      if (Boolean(savedFilterData.botProject)) {
        data.botProject = savedFilterData.botProject;
      }

      if (Boolean(savedFilterData.eventTypes)) {
        data.eventTypes = savedFilterData.eventTypes.split(',').map(eventType => {
          return {
            value: eventType,
            label: localize.translate(`event type ${eventType}`),
          };
        });
      }

      if (Boolean(savedFilterData.dateFromTimeLimit)) {
        data.dateFromTimeLimit = savedFilterData.dateFromTimeLimit;
      }
    } catch (e) {
      console.error("Can't parse saved user log filter data");
    }
  }

  return data;
}
