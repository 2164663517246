import React, { FC } from 'react';
import { IconNames, KIcon } from '../parts/KIcons';
import { useGroupOffset } from './context';
import { KonvaEventObject } from 'konva/lib/Node';

export const GroupStateItemOpenClose: FC<{
  isOpen: boolean;
  toggle: (event: KonvaEventObject<MouseEvent>) => unknown;
}> = ({ isOpen, toggle }) => {
  const { paddingLeft } = useGroupOffset();

  return (
    <KIcon
      width={16}
      height={16}
      x={paddingLeft}
      icon={isOpen ? IconNames.nestedStateHasChildStates_Close : IconNames.nestedStateHasChildStates_Open}
      onClick={toggle}
    />
  );
};
