import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ProgressBar } from '@just-ai/just-ui';
export default function FakeProgressBar({ done }) {
    const [progress, setProgress] = useState(1);
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => prevProgress + (100 - prevProgress) / 30);
        }, 300);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        if (progress > 1)
            setProgress(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [done]);
    return _jsx(ProgressBar, { progress: done ? 100 : progress });
}
