import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';
import { Subject, throttleTime, debounceTime, groupBy, mergeMap } from 'rxjs';
import Konva from 'konva';
import { Group, Rect, Text, Path, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';
import { findScreenByCondition } from 'reducers/JGraph.reducer/Graph';
import { setCursorOnMouseEnterAndLeave, getStageContextFromRef } from 'modules/JGraph/utils/stageUtils';
import { getThemeName } from 'modules/JGraph/utils/themesUtils';
import { isStateType } from 'modules/JGraph/utils/isStateType';
import { Cords } from 'modules/JGraph/utils/2DVector';

import { Bookmark } from '../parts/Bookmark';
import { StartIcon } from '../parts/BookMarks';
import RenderingBlockWrapper from '../RenderingModal/RenderingBlockWrapper';
import { themesConnectorPipe$ } from './ThemesConnectionsLayer';

const textProps = {
  fontSize: 12,
  fontStyle: 'bold',
  fontFamily: 'Roboto',
  letterSpacing: 0.75,
  lineHeight: 20 / 12,
  wrap: 'char',
  width: 167,
};

export const themePositionPipe$ = new Subject<{ theme: JGraphTheme; pos: Cords }>();
export const themePositionState$ = themePositionPipe$.pipe(throttleTime(10));
export const themePositionSaving$ = themePositionPipe$.pipe(
  groupBy(event => event.theme.value),
  mergeMap(group => group.pipe(debounceTime(1000)))
);
export const themePositionBatch$ = new Subject<{ themeId: string; pos: Cords }[]>();

const cardWidth = 239;

function useThemeRender(theme: JGraphTheme, node: React.MutableRefObject<Konva.Group | null>) {
  const [canRender, setCanRender] = useState(() => theme.canRender);

  useEffect(() => {
    if (!theme.canRender) return;
    setCanRender(true);
  }, [theme.canRender]);

  useEffect(() => {
    if (canRender) return;
    const { renderStorePipe$ } = getStageContextFromRef(node);
    const sub = renderStorePipe$.subscribe(value => {
      if (!canRender && value === theme.pathId) {
        setCanRender(true);
        RenderingBlockWrapper.Percentage$.next({ type: 'tick' });
      }
    });
    return () => sub.unsubscribe();
  }, [canRender, theme.pathId, node]);

  return canRender;
}

interface ThemeCardProps {
  theme: JGraphTheme;
  states: JStateWithId[];
  onSelectTheme: (theme: JGraphTheme) => void;
  onContextMenu: (event: Konva.KonvaEventObject<PointerEvent>, theme: JGraphTheme) => void;
}
const ThemeCard = ({ theme, states, onSelectTheme, onContextMenu }: ThemeCardProps) => {
  const themeCardRef = useRef<Konva.Group | null>(null);
  const fromConnectionRef = useRef<Konva.Circle | null>(null);
  const toConnectionRef = useRef<Konva.Circle | null>(null);

  useThemeRender(theme, themeCardRef);

  const textAttrs = useMemo(() => {
    const text = getThemeName(theme.value);
    const pureTextRenderMain = new Konva.Text({ text, ...textProps });
    pureTextRenderMain.wrap();
    return { text, textHeight: pureTextRenderMain?.getHeight() };
  }, [theme]);

  useEffect(() => {
    themesConnectorPipe$.next({
      [theme.value]: { toRef: toConnectionRef.current, fromRef: fromConnectionRef.current },
    });

    return () => themesConnectorPipe$.next({ [theme.value]: { toRef: null, fromRef: null } });
  }, [theme.value]);

  const onDragCard = useCallback(
    (event: KonvaEventObject<DragEvent>) => {
      themePositionPipe$.next({
        theme,
        pos: event.target.attrs,
      });
    },
    [theme]
  );

  const isStartScreen = useMemo(() => {
    const themeStates = states.filter(state => state.theme === theme.value);
    return findScreenByCondition(themeStates, screen => isStateType(screen, 'start'));
  }, [states, theme.value]);

  const cardHeight = 16 + textAttrs.textHeight;
  return (
    <Group
      draggable={true}
      x={theme.x || 0}
      y={theme.y || 0}
      width={cardWidth}
      height={cardHeight}
      isThemeScreen={true}
      ref={themeCardRef}
      onDragMove={onDragCard}
      name={theme.pathId}
      onContextMenu={e => onContextMenu(e, theme)}
    >
      <Circle x={cardWidth} y={16} width={0} height={0} opacity={0} listening={false} ref={fromConnectionRef} />
      <Circle x={2} y={16} width={0} height={0} opacity={0} listening={false} ref={toConnectionRef} />
      <Rect width={cardWidth} height={cardHeight} fill='#333C45' stroke='#333C45' cornerRadius={8} />
      <Path
        width={16}
        height={16}
        x={12}
        y={8}
        data='M15 9H10C9.44781 9 9 9.44781 9 10V15C9 15.5522 9.44781 16 10 16H15C15.5522 16 16 15.5522 16 15V10C16 9.44781 15.5522 9 15 9ZM14.5 14.5H10.5V10.5H14.5V14.5ZM4 8C1.79094 8 0 9.79094 0 12C0 14.2091 1.79094 16 4 16C6.20906 16 8 14.2091 8 12C8 9.79094 6.20906 8 4 8ZM4 14.5C2.62156 14.5 1.5 13.3784 1.5 12C1.5 10.6216 2.62156 9.5 4 9.5C5.37844 9.5 6.5 10.6216 6.5 12C6.5 13.3784 5.37844 14.5 4 14.5ZM15.8431 6.28562L12.5022 0.571563C12.2797 0.190625 11.8897 0 11.5 0C11.1103 0 10.7203 0.190625 10.4978 0.571563L7.15688 6.28562C6.71125 7.0475 7.26813 8 8.15906 8H14.8409C15.7319 8 16.2887 7.0475 15.8431 6.28562ZM8.76906 6.5L11.5 1.82906L14.2309 6.5H8.76906Z'
        fill='white'
      />
      <Text {...textProps} {...textAttrs} fill='#FFFFFF' x={36} y={8} />
      <Path
        width={16}
        height={16}
        x={211}
        y={8}
        data='M4.5 3.50031V5.11281H1.5C0.671875 5.11281 0 5.78469 0 6.61281V9.38156C0 10.2097 0.671875 10.8816 1.5 10.8816H4.5V12.4941C4.5 13.8253 6.11562 14.5003 7.05937 13.5534L11.5594 9.05656C12.1438 8.47219 12.1438 7.52219 11.5594 6.93469L7.05937 2.43469C6.11875 1.50031 4.5 2.16594 4.5 3.50031ZM10.5 8.00031L6 12.5003V9.38469H1.5V6.61594H6V3.50031L10.5 8.00031ZM13 14.0003H10.375C10.1687 14.0003 10 13.8316 10 13.6253V12.8753C10 12.6691 10.1687 12.5003 10.375 12.5003H13C13.8281 12.5003 14.5 11.8284 14.5 11.0003V5.00031C14.5 4.17219 13.8281 3.50031 13 3.50031H10.375C10.1687 3.50031 10 3.33156 10 3.12531V2.37531C10 2.16906 10.1687 2.00031 10.375 2.00031H13C14.6562 2.00031 16 3.34406 16 5.00031V11.0003C16 12.6566 14.6562 14.0003 13 14.0003Z'
        fill='white'
        hitStrokeWidth={10}
        onClick={() => onSelectTheme(theme)}
        name={`${theme.pathId}_enterToNode`}
        onMouseEnter={setCursorOnMouseEnterAndLeave()}
        onMouseLeave={setCursorOnMouseEnterAndLeave()}
      />

      {isStartScreen && (
        <Bookmark x={0} y={cardHeight + 4} fill='#5B9C59' stroke='#B3CDB2' isCollapsed>
          <StartIcon />
        </Bookmark>
      )}
    </Group>
  );
};

ThemeCard.displayName = 'ThemeCard';

export default React.memo(ThemeCard);
