export enum LoggerEnum {
  ENGINE = 'engine',
  EXECUTOR = 'executor',
  MATCHER = 'matcher',
  JS = 'js',
  HTTP = 'http',
  BOT_API = 'com.justai.zb.endpoints.api.BotsApiImpl',
  OTHER = 'other',
}

export function LoggerEnumFrom(str: string): LoggerEnum {
  if (Object.values(LoggerEnum).includes(str as LoggerEnum)) {
    return str as LoggerEnum;
  }
  if (str === 'external-js') return LoggerEnum.JS;

  return LoggerEnum.OTHER;
}

export enum LevelEnum {
  ALERT = 'ALERT',
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  WARNING = 'WARN',
  NOTICE = 'NOTICE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
}

export enum TimeEnum {
  DAY = 'DAY',
  HOURS12 = 'HOURS12',
  HOUR = 'HOUR',
  MINUTES30 = 'MINUTES30',
  MINUTES5 = 'MINUTES5',
}

export enum TimeEnumMS {
  DAY = 24 * 60 * 60 * 1000,
  HOURS12 = 12 * 60 * 60 * 1000,
  HOUR = 60 * 60 * 1000,
  MINUTES30 = 30 * 60 * 1000,
  MINUTES5 = 5 * 60 * 1000,
}

export function TimeEnumFrom(str: string): TimeEnum | undefined {
  if (Object.values(TimeEnum).includes(str as TimeEnum)) {
    return str as TimeEnum;
  }
  return undefined;
}

export function LevelEnumFrom(str: string): LevelEnum | undefined {
  if (Object.values(LevelEnum).includes(str as LevelEnum)) {
    return str as LevelEnum;
  }
  return undefined;
}

export class LogItem {
  id: string;
  text: string;
  isParsed: boolean;
  logger: LoggerEnum;
  timestamp?: string;
  meta?: string;
  level?: LevelEnum;
  message?: string;
  clientId?: string;
  accountId?: string;
  botId?: string;

  constructor(
    id: string,
    text: string,
    logger: LoggerEnum,
    timestamp?: string,
    meta?: string,
    level?: LevelEnum,
    message?: string,
    isParsed: boolean = false,
    clientId?: string,
    accountId?: string,
    botId?: string
  ) {
    this.id = id;
    this.text = text;
    this.logger = logger;
    this.timestamp = timestamp;
    this.meta = meta;
    this.level = level;
    this.message = message;
    this.isParsed = isParsed;
    this.clientId = clientId;
    this.botId = botId;
    this.accountId = accountId;
  }

  static fromString(str: string) {
    const id = str;
    let text = str;
    const parsedLogItem = parseLogItem(str);
    if (!parsedLogItem) return new LogItem(id, text, LoggerEnum.OTHER, str.substr(0, 24), '', LevelEnum.ERROR, text);

    const timestamp = parsedLogItem.timestamp;
    const meta = parsedLogItem.meta;
    const message = parsedLogItem.message;

    const logger = LoggerEnumFrom(parsedLogItem.logger);
    const level = LevelEnumFrom(parsedLogItem.level);
    const { botId, accountId, clientId } = parsedLogItem;

    text = timestamp + '\n' + meta + ' ' + logger + ' ' + level + '\n' + message;

    return new LogItem(id, text, logger, timestamp, meta, level, message, true, clientId, accountId, botId);
  }
}

// '${DATE} ${TIME} [A:${ACCOUNT_ID},B:${BOT_ID},C:${CLIENT_ID},Q:${MYSTERY_Q}] ${LEVEL} [${LOGGER}] - ${MESSAGE}'
const LOG_ITEM_REG_EXP = /^ *([^ ]+ [^ ]+) *\[([^[\]]*)\] *(\w*) *\[ *(.*) *\] *- *(.*)/s;
const LOG_ITEM_REG_EXP_ALT = /^ *([^ ]+) *\[([^ ]+)\] *([^ ]+) *\[ *([^ ]*)\] *- *(.*)/s;
function parseLogItem(str: string) {
  let strComponents = str.match(LOG_ITEM_REG_EXP);
  if (!strComponents) {
    strComponents = str.match(LOG_ITEM_REG_EXP_ALT);
  }
  let accountId, clientId, botId;
  if (strComponents && strComponents[2]) {
    accountId = strComponents[2].match(/A:(.*),B/)?.[1];
    botId = strComponents[2].match(/B:(.*),C/)?.[1];
    clientId = strComponents[2].match(/C:(.*),/)?.[1];
  }

  if (!strComponents || strComponents.length !== 6) return null;
  return {
    timestamp: strComponents[1],
    meta: strComponents[2],
    level: strComponents[3],
    logger: strComponents[4],
    message: strComponents[5],
    accountId,
    clientId,
    botId,
  };
}
