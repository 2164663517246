import {
  DELETE_ISSUE,
  DELETE_SESSION_ISSUE,
  FILTER_PRIORITY_SETTINGS,
  FILTER_SETTINGS,
  GET_PAGE_FOR_ISSUE_LOGID,
  MARK_TASK_AS_CHANGEABLE,
  SET_SELECT,
  SET_STORE,
  SET_TASK_SELECT,
  TASKS_LOAD,
  TOTAL_ELEMENTS_LOAD,
  UPDATE_ISSUE,
  UPDATE_SESSION_ISSUE,
  FETCH_ISSUE_DATA,
} from '../../constants/tasks.actions';
import { axios } from '../../pipes/functions';
import moment from 'moment';

export const getTasks = (projectShortName, obj = {}, filterActive = false) => {
  return {
    type: TASKS_LOAD,
    payload: axios._get(`/restapi/logs/issues/${projectShortName}/filter`, { params: obj, filterActive: filterActive }),
  };
};
export const getTotalUserTasksCount = (projectShortName, obj = {}) => {
  return {
    type: TOTAL_ELEMENTS_LOAD,
    payload: axios.get(`/restapi/logs/issues/${projectShortName}/filter`, { params: obj }),
  };
};
export const getFilterSettings = () => {
  return {
    type: FILTER_SETTINGS,
    payload: axios.get(`/restapi/logs/issues/filter-data`),
  };
};
export const getFilterPrioritySettings = () => {
  return {
    type: FILTER_PRIORITY_SETTINGS,
    payload: axios.get(`/restapi/logs/issues/priorities`),
  };
};
export const setMultipleSelect = (arr, label) => {
  return {
    type: SET_SELECT,
    array: arr,
    label: label,
  };
};
export const setTaskSelect = (value, label) => {
  return {
    type: SET_TASK_SELECT,
    value: value,
    label: label,
  };
};
export const deleteIssue = (projectShortName, id) => {
  return {
    type: DELETE_ISSUE,
    payload: axios.delete(`/restapi/logs/issues/${projectShortName}/${id}`, {
      id,
    }),
  };
};
export const fetchIssueData = (projectShortName, id, logId) => {
  return {
    type: FETCH_ISSUE_DATA,
    payload: axios._get(`/restapi/logs/issues/${projectShortName}/${id}`, {
      logId,
    }),
  };
};
export const deleteSessionIssue = (projectShortName, id) => {
  return {
    type: DELETE_SESSION_ISSUE,
    payload: axios.delete(`/restapi/logs/issues/${projectShortName}/sessions/${id}`, {
      id,
    }),
  };
};
export const updateIssue = (projectShortName, id, data = {}) => {
  return {
    type: UPDATE_ISSUE,
    payload: axios.put(`/restapi/logs/issues/${projectShortName}/${id}`, data),
  };
};
export const updateSessionIssue = (projectShortName, id, data = {}) => {
  return {
    type: UPDATE_SESSION_ISSUE,
    payload: axios.put(`/restapi/logs/issues/${projectShortName}/sessions/${id}`, data),
  };
};
export const toggleChange = (taskId = 0) => {
  return {
    type: MARK_TASK_AS_CHANGEABLE,
    taskId,
  };
};

export const getPageForLogId = (projectShortName, logDialogId, logId, action = null) => {
  return {
    type: GET_PAGE_FOR_ISSUE_LOGID,
    payload: action ? action() : axios.get(`/restapi/logs/${projectShortName}/${logDialogId}/issue/${logId}`),
  };
};

export const setStore = obj => {
  let dateFromTimeLimit = '';
  if (Boolean(obj.dateFrom)) {
    dateFromTimeLimit = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    let dateFrom = moment(parseInt(obj.dateFrom, 10));
    if (dateFrom.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
      dateFromTimeLimit = moment();
    }
    if (dateFrom.format('x') > moment().format('x')) {
      obj.dateFrom = moment().format('x');
    }
  }
  return {
    type: SET_STORE,
    payload: obj,
    dateFromTimeLimit: dateFromTimeLimit,
  };
};
