export const CailaIntentsHelperLocalization = {
  'CailaIntentsHelper:Title': {
    eng: 'How to set up?',
    ru: 'Как настроить?',
  },
  'CailaIntentsHelper:CollapseList': {
    eng:
      "<ul>\n          <li>\n          Add a new user phrase or select a built-in set of phrases — an\n            <span class='pseudoLink' id='collapseInnerBoxIntentTooltip'>\n              intent\n            </span>.\n          </li>\n          <li>Set up the phrase by adding alternative examples and specifying the state where the bot should go.</li>\n        </ul>",
    ru:
      "<ul>\n          <li>\n            Добавьте новую фразу клиента или выберите уже готовый набор фраз —\n            <span class='pseudoLink' id='collapseInnerBoxIntentTooltip'>\n              интент\n            </span>.\n          </li>\n          <li>Настройте фразу: добавьте альтернативные примеры и укажите шаг, в который должен перейти бот.</li>\n        </ul>",
  },
  'CailaIntentsHelper:TooltipText': {
    eng:
      'An intent is a set of phrases which are similar in meaning and express some user intention. For example, phrases like “hello”, “good day”, and their synonyms can all be recognized with the built-in “<b>Greeting</b>” intent.',
    ru:
      'Интент — это набор фраз, которые имеют похожий смысл и выражают какое-либо намерение пользователя. Например, фразы «здравствуйте», «добрый день» и их синонимы можно распознать через готовый интент «<b>Приветствие</b>».',
  },
};
