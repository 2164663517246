import { useEffect, useMemo, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AppLogger } from '@just-ai/logger';
import { OptionType, Icon, useDebounceFn } from '@just-ai/just-ui';
import { SelectLabelProps } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';

import { t } from 'localization';

import { buildCustomValidationResolver } from 'utils/validator/customValidationResolver';
import { transliterateText } from 'utils/text/transliterate';

import * as api from '../../../domain/api';

type CreateIntegrationFormValues = {
  knowledgeBaseId?: string | 'byToken';
  apiKey?: string;
  knowledgeBaseIdByApiKey?: string;
  connectionName?: string;
  needToInsertCode?: boolean;
};

const transliterateName = (name: string) => transliterateText(name, { punctuationReplacement: '' });

const validationResolver = buildCustomValidationResolver<CreateIntegrationFormValues, { tokens: string[] }>(
  async (values, ctx) => {
    const errors: any = {};

    if (!values.knowledgeBaseId) {
      errors['knowledgeBaseId'] = t('KhubIntegration:Errors:RequiredField');
    } else if (values.knowledgeBaseId === 'byToken' && !values.apiKey) {
      errors['apiKey'] = t('KhubIntegration:Errors:RequiredField');
    } else if (values.knowledgeBaseId === 'byToken' && values.apiKey && !values.knowledgeBaseIdByApiKey) {
      errors['knowledgeBaseIdByApiKey'] = t('KhubIntegration:Errors:RequiredField');
    }

    if (!values.connectionName) errors['connectionName'] = t('KhubIntegration:Errors:RequiredField:Name');
    else if (values.connectionName.length > 100)
      errors['connectionName'] = t('KhubIntegration:Errors:MoreThen100Chars');
    else if (!values.connectionName.match(/^[a-zA-Z0-9_]+$/)) {
      errors['connectionName'] = t('KhubIntegration:Errors:NamePattern');
    } else if (ctx.tokens.includes(values.connectionName)) {
      errors['connectionName'] = t('KhubIntegration:Errors:TokenAlreadyExists');
    }

    return errors;
  }
);

export function useCreateIntegrationForm(config?: { onSubmit?: () => void }) {
  const onSubmit = config?.onSubmit;
  const [getExistedKnowledgeBasesRequestState, getExistedKnowledgeBasesCall] = api.useGetExistedKnowledgeBasesApi();
  const [getKnowledgeBaseByTokenRequestState, getKnowledgeBaseByTokenCall] = api.useGetKnowledgeBaseByTokenApi();
  const [getSecretNamesRequestState, getSecretNamesCall] = api.useGetSecretNamesApi();
  const [createKnowledgeBaseIntegrationState, createKnowledgeBaseIntegrationApiCall] =
    api.useCreateKnowledgeBaseIntegrationApi();
  const [createKnowledgeBaseIntegrationByTokenState, createKnowledgeBaseIntegrationByTokenApiCall] =
    api.useCreateIntegrationByTokenApi();

  useEffect(() => void getExistedKnowledgeBasesCall(), [getExistedKnowledgeBasesCall]);
  useEffect(() => void getSecretNamesCall(), [getSecretNamesCall]);

  const basesListOptions = useMemo(() => {
    const bases: OptionType[] =
      getExistedKnowledgeBasesRequestState?.result?.previews?.map(el => ({
        label: el.name,
        value: el.id.toString(),
      })) ?? [];
    bases.push({
      value: 'byToken',
      label: t('KhubIntegration:CreateIntegrationModal:Fields:apiKey'),
      LabelComponent: (props: SelectLabelProps) => (
        <div className='flex-row ai-center gap-8'>
          <Icon name='farPlus' color='secondary' />
          <span>{props.label}</span>
        </div>
      ),
    });
    return bases;
  }, [getExistedKnowledgeBasesRequestState?.result]);

  const form = useForm<CreateIntegrationFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      needToInsertCode: true,
    },
    validationContext: {
      tokens: getSecretNamesRequestState.result ?? [],
    },
    // @ts-ignore
    validationResolver: validationResolver,
  });
  const formRef = useRef(form);
  formRef.current = form;

  const watchCb = useRef<((changedName: string) => void) | null>(null);
  const setValue = useRef<typeof formRef.current.setValue | null>(null);

  setValue.current = (...args: Parameters<typeof formRef.current.setValue>) => {
    const changedName = args[0] as unknown as string;
    formRef.current.setValue(...args);
    requestAnimationFrame(() => watchCb.current?.(changedName));
  };

  const getKnowledgeBaseByTokenCallDebounced = useDebounceFn(
    (token: string) =>
      getKnowledgeBaseByTokenCall(token)
        .then(res => {
          if (!res) return;
          formRef.current.clearError('apiKey');
          setValue.current?.('knowledgeBaseIdByApiKey', res.id.toString());
        })
        .catch(err => {
          formRef.current.setError('apiKey', 'custom', err?.message);
          formRef.current.control.reRender();
        }),
    1000
  );

  watchCb.current = (changedName: string) => {
    if (changedName === 'knowledgeBaseId' && getExistedKnowledgeBasesRequestState?.result) {
      const baseId = formRef.current.getValues('knowledgeBaseId');
      if (baseId === 'byToken') {
        setValue.current?.('connectionName', '');
      } else {
        const base = getExistedKnowledgeBasesRequestState.result?.previews?.find(
          el => el.id.toString() === baseId?.toString()
        );
        if (base) {
          setValue.current?.('connectionName', transliterateName(base.name));
        }
      }
    }

    if (changedName === 'apiKey') {
      setValue.current?.('knowledgeBaseIdByApiKey', '');
      const apiKey = formRef.current.getValues('apiKey');
      if (!apiKey || apiKey.length === 0) {
        getKnowledgeBaseByTokenCallDebounced.cancel();
        getKnowledgeBaseByTokenRequestState.reset();
      } else {
        getKnowledgeBaseByTokenCallDebounced(apiKey);
      }
    }
  };

  useEffect(() => {
    if (!getExistedKnowledgeBasesRequestState?.result) return;
    if (formRef.current.getValues('knowledgeBaseId') === undefined) {
      setValue.current?.('knowledgeBaseId', basesListOptions[0]?.value as string);
    }
  }, [basesListOptions, getExistedKnowledgeBasesRequestState?.result]);

  useEffect(() => {
    if (!getKnowledgeBaseByTokenRequestState?.result) return;
    setValue.current?.('connectionName', transliterateName(getKnowledgeBaseByTokenRequestState?.result.name));
  }, [getKnowledgeBaseByTokenRequestState?.result]);

  const submit = useCallback(
    () =>
      form.handleSubmit(async () => {
        const values = formRef.current.getValues();
        if (!values.knowledgeBaseId || !values.connectionName) return;

        (values.knowledgeBaseId === 'byToken'
          ? createKnowledgeBaseIntegrationByTokenApiCall(
              {
                token: values.apiKey ?? '',
                secretName: values.connectionName,
              },
              values.needToInsertCode
            )
          : createKnowledgeBaseIntegrationApiCall(
              {
                id: parseInt(values.knowledgeBaseId),
                secretName: values.connectionName,
              },
              values.needToInsertCode
            )
        )
          .then(onSubmit)
          .catch(e => AppLogger.warn({ message: 'Error in createKnowledgeBaseIntegrationApiCall', exception: e }));
      })(),
    [createKnowledgeBaseIntegrationApiCall, createKnowledgeBaseIntegrationByTokenApiCall, form, onSubmit]
  );

  const isNeedToShowApiKeyField = formRef.current.watch('knowledgeBaseId') === 'byToken';
  const isNeedToDisableNameField = isNeedToShowApiKeyField && !formRef.current.watch('knowledgeBaseIdByApiKey');
  const isFormLoading =
    getExistedKnowledgeBasesRequestState.loading ||
    getSecretNamesRequestState.loading ||
    createKnowledgeBaseIntegrationByTokenState.loading ||
    createKnowledgeBaseIntegrationState.loading;
  const isCanSubmit =
    !isFormLoading &&
    !getKnowledgeBaseByTokenRequestState.error &&
    (!form.formState.isSubmitted || form.formState.isValid);

  return {
    form: { ...form, setValue: setValue.current },
    getExistedKnowledgeBasesRequestState,
    getKnowledgeBaseByTokenRequestState,
    basesListOptions,
    isNeedToShowApiKeyField,
    isFormLoading,
    isCanSubmit,
    submit,
    formError: createKnowledgeBaseIntegrationState.error || createKnowledgeBaseIntegrationByTokenState.error,
    isNeedToDisableNameField,
  };
}
