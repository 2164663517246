// tslint:disable
// @ts-nocheck
/**
 * CAILA REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountTariffLimitsData
 */
export interface AccountTariffLimitsData {
  /**
   *
   * @type {number}
   * @memberof AccountTariffLimitsData
   */
  accountId: number;
  /**
   * Number of Direct API calls that was made in the last period.
   * @type {number}
   * @memberof AccountTariffLimitsData
   */
  currentDirectApiCalls: number;
  /**
   * Number of Direct API calls that was made in the last period using the additional packages.
   * @type {number}
   * @memberof AccountTariffLimitsData
   */
  currentDirectApiCallsByPackages?: number;
}
/**
 *
 * @export
 * @interface AnalyzeRequestData
 */
export interface AnalyzeRequestData {
  /**
   *
   * @type {InferenceRequestData}
   * @memberof AnalyzeRequestData
   */
  data?: InferenceRequestData;
  /**
   * Don\'t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
   * @type {boolean}
   * @memberof AnalyzeRequestData
   */
  showAll?: boolean;
  /**
   * Time threshold for analyzing in ms (milliseconds). If exceeded, send response with corresponding message.
   * @type {number}
   * @memberof AnalyzeRequestData
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface AnalyzeResponseData
 */
export interface AnalyzeResponseData {
  /**
   *
   * @type {InitialMarkupData}
   * @memberof AnalyzeResponseData
   */
  markup?: InitialMarkupData;
  /**
   *
   * @type {PhraseMarkupData}
   * @memberof AnalyzeResponseData
   */
  entitiesLookup?: PhraseMarkupData;
  /**
   *
   * @type {InferenceResultsData}
   * @memberof AnalyzeResponseData
   */
  inference?: InferenceResultsData;
}
/**
 *
 * @export
 * @interface AsyncTaskResultData
 */
export interface AsyncTaskResultData {
  /**
   *
   * @type {number}
   * @memberof AsyncTaskResultData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AsyncTaskResultData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AsyncTaskResultData
   */
  status?: StatusEnum;
  /**
   *
   * @type {string}
   * @memberof AsyncTaskResultData
   */
  result?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum StatusEnum {
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
  Canceled = 'canceled',
}

/**
 *
 * @export
 * @interface AsyncTaskSummary
 */
export interface AsyncTaskSummary {
  /**
   *
   * @type {number}
   * @memberof AsyncTaskSummary
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AsyncTaskSummary
   */
  name?: string;
}
/**
 *
 * @export
 * @interface AuditEventRecord
 */
export interface AuditEventRecord {
  /**
   *
   * @type {string}
   * @memberof AuditEventRecord
   */
  user?: string;
  /**
   *
   * @type {number}
   * @memberof AuditEventRecord
   */
  timestamp?: number;
}
/**
 *
 * @export
 * @interface CdqaSettingsData
 */
export interface CdqaSettingsData {
  /**
   *
   * @type {string}
   * @memberof CdqaSettingsData
   */
  languageCode?: LanguageCodeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LanguageCodeEnum {
  EnRU = 'enRU',
  Multi = 'multi',
  Test = 'test',
}

/**
 *
 * @export
 * @interface CheckVocabularyRequestData
 */
export interface CheckVocabularyRequestData {
  /**
   *
   * @type {string}
   * @memberof CheckVocabularyRequestData
   */
  lang?: string;
  /**
   *
   * @type {string}
   * @memberof CheckVocabularyRequestData
   */
  engine?: string;
  /**
   *
   * @type {string}
   * @memberof CheckVocabularyRequestData
   */
  word?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ClassificationPredicate {
  CONTAINS = 'CONTAINS',
  CONTAINSNOT = 'CONTAINS_NOT',
}

/**
 *
 * @export
 * @interface ClassificationRuleEntityRef
 */
export interface ClassificationRuleEntityRef {
  /**
   * Entity Name
   * @type {string}
   * @memberof ClassificationRuleEntityRef
   */
  entityName?: string;
  /**
   *
   * @type {ClassificationPredicate}
   * @memberof ClassificationRuleEntityRef
   */
  predicate?: ClassificationPredicate;
}
/**
 *
 * @export
 * @interface ClassificationRulesData
 */
export interface ClassificationRulesData {
  /**
   *
   * @type {Array<ClassificationRuleEntityRef>}
   * @memberof ClassificationRulesData
   */
  refs?: Array<ClassificationRuleEntityRef>;
}
/**
 *
 * @export
 * @interface ClassificationRulesDebugInfo
 */
export interface ClassificationRulesDebugInfo {
  /**
   *
   * @type {ClassificationRulesData}
   * @memberof ClassificationRulesDebugInfo
   */
  valid?: ClassificationRulesData;
  /**
   *
   * @type {ClassificationRulesData}
   * @memberof ClassificationRulesDebugInfo
   */
  invalid?: ClassificationRulesData;
}
/**
 *
 * @export
 * @interface CreateNamedEntityRecordData
 */
export interface CreateNamedEntityRecordData {
  /**
   *
   * @type {NamedEntityRecordData}
   * @memberof CreateNamedEntityRecordData
   */
  data: NamedEntityRecordData;
  /**
   *
   * @type {string}
   * @memberof CreateNamedEntityRecordData
   */
  clientId?: string;
}
/**
 *
 * @export
 * @interface CreateProjectData
 */
export interface CreateProjectData {
  /**
   *
   * @type {ProjectData}
   * @memberof CreateProjectData
   */
  project?: ProjectData;
  /**
   *
   * @type {ProjectSettingsData}
   * @memberof CreateProjectData
   */
  settings?: ProjectSettingsData;
}
/**
 *
 * @export
 * @interface CreateSkillRequest
 */
export interface CreateSkillRequest {
  /**
   *
   * @type {SkillType}
   * @memberof CreateSkillRequest
   */
  type: SkillType;
  /**
   *
   * @type {string}
   * @memberof CreateSkillRequest
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateSkillRequest
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface CreatedLogLabelingSessionData
 */
export interface CreatedLogLabelingSessionData {
  /**
   *
   * @type {LogLabelingSessionData}
   * @memberof CreatedLogLabelingSessionData
   */
  session: LogLabelingSessionData;
  /**
   *
   * @type {number}
   * @memberof CreatedLogLabelingSessionData
   */
  numberOfDeletedPhrases?: number;
}
/**
 *
 * @export
 * @interface DeleteSkillRequest
 */
export interface DeleteSkillRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteSkillRequest
   */
  id: string;
  /**
   *
   * @type {SkillType}
   * @memberof DeleteSkillRequest
   */
  type: SkillType;
}
/**
 *
 * @export
 * @interface DeleteStagingIntentPhrasesRequest
 */
export interface DeleteStagingIntentPhrasesRequest {
  /**
   *
   * @type {number}
   * @memberof DeleteStagingIntentPhrasesRequest
   */
  intentId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteStagingIntentPhrasesRequest
   */
  phrases: Array<string>;
}
/**
 *
 * @export
 * @interface DetectLanguageRequestData
 */
export interface DetectLanguageRequestData {
  /**
   *
   * @type {Array<string>}
   * @memberof DetectLanguageRequestData
   */
  documents?: Array<string>;
}
/**
 *
 * @export
 * @interface DirectAccessKeyData
 */
export interface DirectAccessKeyData {
  /**
   * Direct access key to set
   * @type {string}
   * @memberof DirectAccessKeyData
   */
  directAccessKey?: string;
}
/**
 *
 * @export
 * @interface DuplicatesParameters
 */
export interface DuplicatesParameters {
  /**
   *
   * @type {string}
   * @memberof DuplicatesParameters
   */
  method?: MethodEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum MethodEnum {
  Simple = 'simple',
}

/**
 *
 * @export
 * @interface E2ECheckAnalyzeResponseData
 */
export interface E2ECheckAnalyzeResponseData {
  /**
   *
   * @type {AnalyzeResponseData}
   * @memberof E2ECheckAnalyzeResponseData
   */
  analyzeResponseData?: AnalyzeResponseData;
  /**
   *
   * @type {E2ECheckResult}
   * @memberof E2ECheckAnalyzeResponseData
   */
  e2eCheckResult?: E2ECheckResult;
}
/**
 *
 * @export
 * @interface E2ECheckResult
 */
export interface E2ECheckResult {
  /**
   *
   * @type {string}
   * @memberof E2ECheckResult
   */
  totalStatus?: string;
  /**
   *
   * @type {number}
   * @memberof E2ECheckResult
   */
  totalTime?: number;
  /**
   *
   * @type {Array<E2EComponent>}
   * @memberof E2ECheckResult
   */
  e2EComponents?: Array<E2EComponent>;
}
/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime?: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime?: number;
}
/**
 *
 * @export
 * @interface EntitiesByNamesRequestData
 */
export interface EntitiesByNamesRequestData {
  /**
   * Names of entities to find all data (entity and records)
   * @type {Array<string>}
   * @memberof EntitiesByNamesRequestData
   */
  names?: Array<string>;
}
/**
 *
 * @export
 * @interface EntitiesSearchCriteria
 */
export interface EntitiesSearchCriteria {
  /**
   * substring of entity name, ignore-case search
   * @type {string}
   * @memberof EntitiesSearchCriteria
   */
  entityName?: string;
  /**
   * substring of entity synonym, ignore-case search
   * @type {string}
   * @memberof EntitiesSearchCriteria
   */
  synonym?: string;
  /**
   * substring of entity pattern, ignore-case search
   * @type {string}
   * @memberof EntitiesSearchCriteria
   */
  pattern?: string;
  /**
   * substring of entity JSON, ignore-case search
   * @type {string}
   * @memberof EntitiesSearchCriteria
   */
  json?: string;
}
/**
 *
 * @export
 * @interface EntityMarkupData
 */
export interface EntityMarkupData {
  /**
   * Entity name (type). Must correspond to the one of the enabled entity in a project. If version is expected, it is separated with engine name with \'_\' (e.g. \"duckling_v2.number\")
   * @type {string}
   * @memberof EntityMarkupData
   */
  entity?: string;
  /**
   * Slot (variable) name. Must correspond to one of intent\'s slots
   * @type {string}
   * @memberof EntityMarkupData
   */
  slot?: string;
  /**
   * Start position in source string inclusive
   * @type {number}
   * @memberof EntityMarkupData
   */
  startPos?: number;
  /**
   * End position in source string exclusive
   * @type {number}
   * @memberof EntityMarkupData
   */
  endPos?: number;
  /**
   * Source text for the entity. Is a substring of source phrase from StartPos till EndPos
   * @type {string}
   * @memberof EntityMarkupData
   */
  text?: string;
  /**
   * Associated value
   * @type {string}
   * @memberof EntityMarkupData
   */
  value?: string;
  /**
   * Is this entity selected as a most probable entity for this position
   * @type {boolean}
   * @memberof EntityMarkupData
   */
  _default?: boolean;
  /**
   * Is this entity system. False means that it is user custom entity
   * @type {boolean}
   * @memberof EntityMarkupData
   */
  system?: boolean;
  /**
   * Entity ID.
   * @type {number}
   * @memberof EntityMarkupData
   */
  entityId?: number;
  /**
   * Is this entity fuzzy. True means that this entity is provided by lucene fuzzy search
   * @type {boolean}
   * @memberof EntityMarkupData
   */
  fuzzy?: boolean;
  /**
   * True hides entity in caila-front
   * @type {boolean}
   * @memberof EntityMarkupData
   */
  hidden?: boolean;
  /**
   * Is shared entity
   * @type {boolean}
   * @memberof EntityMarkupData
   */
  shared?: boolean;
}
/**
 *
 * @export
 * @interface ExtendedProjectSettings
 */
export interface ExtendedProjectSettings {
  /**
   * Enable or disable patterns in intents.
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  patternsEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExtendedProjectSettings
   */
  tokenizerEngine?: string;
  /**
   * Stringified sts classifier extended settings
   * @type {object}
   * @memberof ExtendedProjectSettings
   */
  stsSettings?: object;
  /**
   * Stringified cnn classifier extended settings
   * @type {object}
   * @memberof ExtendedProjectSettings
   */
  cnnSettings?: object;
  /**
   * Stringified classicML classifier extended settings
   * @type {object}
   * @memberof ExtendedProjectSettings
   */
  classicMLSettings?: object;
  /**
   * Stringified logreg classifier extended settings
   * @type {object}
   * @memberof ExtendedProjectSettings
   */
  logregSettings?: object;
  /**
   * Stringified predict transformer classifier extended settings
   * @type {object}
   * @memberof ExtendedProjectSettings
   */
  transformerPredictSettings?: object;
  /**
   * Extracts all words from synonyms and puts them to custom speller
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  dictionaryAutogeneration?: boolean;
  /**
   * Analyzer for current project, one of - standard, mystem, russian.
   * @type {string}
   * @memberof ExtendedProjectSettings
   */
  luceneAnalyzer?: string;
  /**
   * Whether to share all project intents or not
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  shareIntents?: boolean;
  /**
   * Whether to share all project entitie or not
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  shareEntities?: boolean;
  /**
   * Disables words tokenization in patterns for Chineese projects.
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  disableHieroglyphicsTokenization?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ExtendedProjectSettings
   */
  allowedPatterns?: Array<string>;
  /**
   * Whether to use shared intents and entities in project
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  useShared?: boolean;
  /**
   * Whether to recognize zfl patterns in entities lookup
   * @type {boolean}
   * @memberof ExtendedProjectSettings
   */
  zflPatternsEnabled?: boolean;
  /**
   * Timeout for zfl patterns recognition in milliseconds
   * @type {number}
   * @memberof ExtendedProjectSettings
   */
  zflExtractorTimeLimit?: number;
  /**
   *
   * @type {ExtendedProjectSettingsExternalNluSettings}
   * @memberof ExtendedProjectSettings
   */
  externalNluSettings?: ExtendedProjectSettingsExternalNluSettings;
}
/**
 *
 * @export
 * @interface ExtendedProjectSettingsExternalNluSettings
 */
export interface ExtendedProjectSettingsExternalNluSettings {
  /**
   *
   * @type {ExtendedProjectSettingsExternalNluSettingsNluProviderSettings}
   * @memberof ExtendedProjectSettingsExternalNluSettings
   */
  nluProviderSettings?: ExtendedProjectSettingsExternalNluSettingsNluProviderSettings;
  /**
   * External NLU language parameter. If not set, project language is used.
   * @type {string}
   * @memberof ExtendedProjectSettingsExternalNluSettings
   */
  language?: string;
  /**
   *
   * @type {ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties}
   * @memberof ExtendedProjectSettingsExternalNluSettings
   */
  nluActionAdditionalProperties?: ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties;
}
/**
 *
 * @export
 * @interface ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties
 */
export interface ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties {
  /**
   * Map of parameters for external NLU markup request. Transfered to external NLU service as is.
   * @type {{ [key: string]: string; }}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties
   */
  markup?: { [key: string]: string };
  /**
   * Map of parameters for external NLU NER request. Transfered to external NLU service as is.
   * @type {{ [key: string]: string; }}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties
   */
  ner?: { [key: string]: string };
  /**
   *
   * @type {ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalProperties
   */
  classification?: ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification;
}
/**
 *
 * @export
 * @interface ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification
 */
export interface ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification {
  /**
   * Classifier model ID. If not set, `default` will be used.
   * @type {string}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification
   */
  modelId?: string;
  /**
   * Classifier name. If not set, `default` will be used.
   * @type {string}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification
   */
  classifierName?: string;
  /**
   * Map of parameters for external NLU classification request. Transfered to external NLU service as is.
   * @type {{ [key: string]: string; }}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluActionAdditionalPropertiesClassification
   */
  properties?: { [key: string]: string };
}
/**
 * An object that determines where the NLU action is going to be performed.
 * @export
 * @interface ExtendedProjectSettingsExternalNluSettingsNluProviderSettings
 */
export interface ExtendedProjectSettingsExternalNluSettingsNluProviderSettings {
  /**
   *
   * @type {NluActionDescription}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluProviderSettings
   */
  markup?: NluActionDescription;
  /**
   *
   * @type {NluActionDescription}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluProviderSettings
   */
  ner?: NluActionDescription;
  /**
   *
   * @type {NluActionDescription}
   * @memberof ExtendedProjectSettingsExternalNluSettingsNluProviderSettings
   */
  classification?: NluActionDescription;
}
/**
 *
 * @export
 * @interface FAQTemplate
 */
export interface FAQTemplate {
  /**
   *
   * @type {string}
   * @memberof FAQTemplate
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FAQTemplate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FAQTemplate
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FAQTemplate
   */
  sampleQuestions?: Array<string>;
}
/**
 *
 * @export
 * @interface FaqQuestionData
 */
export interface FaqQuestionData {
  /**
   *
   * @type {number}
   * @memberof FaqQuestionData
   */
  id?: number;
  /**
   *
   * @type {IntentData}
   * @memberof FaqQuestionData
   */
  intent: IntentData;
  /**
   * Bot reply objects (https://help.just-ai.com/docs/ru/bot-replies/overview).
   * @type {Array<object>}
   * @memberof FaqQuestionData
   */
  replies: Array<object>;
}
/**
 *
 * @export
 * @interface FaqQuestionExportImportData
 */
export interface FaqQuestionExportImportData {
  /**
   * Path of intent associated with FAQ question
   * @type {string}
   * @memberof FaqQuestionExportImportData
   */
  intentPath: string;
  /**
   * Bot reply objects (https://help.just-ai.com/docs/ru/bot-replies/overview).
   * @type {Array<object>}
   * @memberof FaqQuestionExportImportData
   */
  replies: Array<object>;
}
/**
 *
 * @export
 * @interface GroupInfoData
 */
export interface GroupInfoData {
  /**
   *
   * @type {string}
   * @memberof GroupInfoData
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof GroupInfoData
   */
  size: number;
  /**
   *
   * @type {Array<PhraseIndexWithConfidence>}
   * @memberof GroupInfoData
   */
  phrasesIndexes: Array<PhraseIndexWithConfidence>;
}
/**
 *
 * @export
 * @interface GroupingRequestData
 */
export interface GroupingRequestData {
  /**
   *
   * @type {string}
   * @memberof GroupingRequestData
   */
  method?: MethodEnum;
  /**
   *
   * @type {DuplicatesParameters}
   * @memberof GroupingRequestData
   */
  duplicatesParameters?: DuplicatesParameters;
  /**
   *
   * @type {LinkageClusterizerParameters}
   * @memberof GroupingRequestData
   */
  linkageClusterizerParameters?: LinkageClusterizerParameters;
  /**
   *
   * @type {KmeansClusterizerParameters}
   * @memberof GroupingRequestData
   */
  kmeansClusterizerParameters?: KmeansClusterizerParameters;
  /**
   *
   * @type {StsDbscanClusterizerParameters}
   * @memberof GroupingRequestData
   */
  stsDbscanClusterizerParameters?: StsDbscanClusterizerParameters;
  /**
   *
   * @type {UDPipeKweParameters}
   * @memberof GroupingRequestData
   */
  udpipeKweParameters?: UDPipeKweParameters;
  /**
   *
   * @type {TfIdfKweParameters}
   * @memberof GroupingRequestData
   */
  tfidfKweParameters?: TfIdfKweParameters;
  /**
   *
   * @type {boolean}
   * @memberof GroupingRequestData
   */
  includeDeleted?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum MethodEnum {
  Duplicates = 'duplicates',
  UdpipeKeywords = 'udpipeKeywords',
  TfidfKeywords = 'tfidfKeywords',
  LinkageClustering = 'linkageClustering',
  KmeansClustering = 'kmeansClustering',
  StsdbscanClustering = 'stsdbscanClustering',
  Classification = 'classification',
  NotRecognizedMessages = 'notRecognizedMessages',
}

/**
 *
 * @export
 * @interface GroupingSummaryData
 */
export interface GroupingSummaryData {
  /**
   *
   * @type {number}
   * @memberof GroupingSummaryData
   */
  groupingId?: number;
  /**
   *
   * @type {string}
   * @memberof GroupingSummaryData
   */
  name?: string;
  /**
   *
   * @type {GroupingRequestData}
   * @memberof GroupingSummaryData
   */
  parameters?: GroupingRequestData;
  /**
   *
   * @type {number}
   * @memberof GroupingSummaryData
   */
  groupsCount?: number;
  /**
   *
   * @type {number}
   * @memberof GroupingSummaryData
   */
  groupedPhrasesCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof GroupingSummaryData
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof GroupingSummaryData
   */
  error?: string;
  /**
   * Shows that group created by system. For example, on chat history ll session creation
   * @type {boolean}
   * @memberof GroupingSummaryData
   */
  createdBySystem?: boolean;
}
/**
 * Strategy to resolve confilcs on import
 * @export
 * @enum {string}
 */
export enum ImportFileStrategy {
  Overwrite = 'overwrite',
  Merge = 'merge',
  Unresolved = 'unresolved',
  Cleanwrite = 'cleanwrite',
  Skipwrite = 'skipwrite',
}

/**
 *
 * @export
 * @interface InferenceMultipleRequestData
 */
export interface InferenceMultipleRequestData {
  /**
   *
   * @type {Array<InferenceRequestPhrasesAndSlots>}
   * @memberof InferenceMultipleRequestData
   */
  phrases?: Array<InferenceRequestPhrasesAndSlots>;
  /**
   *
   * @type {number}
   * @memberof InferenceMultipleRequestData
   */
  nBest?: number;
  /**
   *
   * @type {boolean}
   * @memberof InferenceMultipleRequestData
   */
  showDebugInfo?: boolean;
}
/**
 *
 * @export
 * @interface InferenceRequestData
 */
export interface InferenceRequestData {
  /**
   *
   * @type {PhraseMarkupData}
   * @memberof InferenceRequestData
   */
  phrase?: PhraseMarkupData;
  /**
   *
   * @type {Array<KnownSlotData>}
   * @memberof InferenceRequestData
   */
  knownSlots?: Array<KnownSlotData>;
  /**
   * Maximum amount of hypothesis to return
   * @type {number}
   * @memberof InferenceRequestData
   */
  nBest?: number;
  /**
   *
   * @type {boolean}
   * @memberof InferenceRequestData
   */
  showDebugInfo?: boolean;
  /**
   *
   * @type {string}
   * @memberof InferenceRequestData
   */
  clientId?: string;
}
/**
 *
 * @export
 * @interface InferenceRequestPhrasesAndSlots
 */
export interface InferenceRequestPhrasesAndSlots {
  /**
   *
   * @type {PhraseMarkupData}
   * @memberof InferenceRequestPhrasesAndSlots
   */
  phrase?: PhraseMarkupData;
  /**
   *
   * @type {Array<KnownSlotData>}
   * @memberof InferenceRequestPhrasesAndSlots
   */
  knownSlots?: Array<KnownSlotData>;
}
/**
 *
 * @export
 * @interface InferenceResultContent
 */
export interface InferenceResultContent {
  /**
   *
   * @type {Array<object>}
   * @memberof InferenceResultContent
   */
  replies?: Array<object>;
}
/**
 *
 * @export
 * @interface InferenceResultData
 */
export interface InferenceResultData {
  /**
   *
   * @type {IntentResultData}
   * @memberof InferenceResultData
   */
  intent?: IntentResultData;
  /**
   *
   * @type {number}
   * @memberof InferenceResultData
   */
  confidence?: number;
  /**
   *
   * @type {InferenceResultWeights}
   * @memberof InferenceResultData
   */
  weights?: InferenceResultWeights;
  /**
   *
   * @type {Array<KnownSlotData>}
   * @memberof InferenceResultData
   */
  slots?: Array<KnownSlotData>;
  /**
   *
   * @type {InferenceResultDebugInfo}
   * @memberof InferenceResultData
   */
  debug?: InferenceResultDebugInfo;
  /**
   *
   * @type {InferenceResultContent}
   * @memberof InferenceResultData
   */
  content?: InferenceResultContent;
}
/**
 *
 * @export
 * @interface InferenceResultDebugInfo
 */
export interface InferenceResultDebugInfo {
  /**
   *
   * @type {ClassificationRulesDebugInfo}
   * @memberof InferenceResultDebugInfo
   */
  rules?: ClassificationRulesDebugInfo;
  /**
   *
   * @type {object}
   * @memberof InferenceResultDebugInfo
   */
  alignments?: object;
}
/**
 *
 * @export
 * @interface InferenceResultWeights
 */
export interface InferenceResultWeights {
  /**
   *
   * @type {number}
   * @memberof InferenceResultWeights
   */
  patterns?: number;
  /**
   *
   * @type {number}
   * @memberof InferenceResultWeights
   */
  phrases?: number;
}
/**
 *
 * @export
 * @interface InferenceResultsData
 */
export interface InferenceResultsData {
  /**
   *
   * @type {PhraseMarkupData}
   * @memberof InferenceResultsData
   */
  phrase?: PhraseMarkupData;
  /**
   *
   * @type {Array<InferenceResultData>}
   * @memberof InferenceResultsData
   */
  variants?: Array<InferenceResultData>;
  /**
   *
   * @type {Array<SpelledWordsData>}
   * @memberof InferenceResultsData
   */
  spelledWords?: Array<SpelledWordsData>;
}
/**
 *
 * @export
 * @interface InitialMarkupData
 */
export interface InitialMarkupData {
  /**
   *
   * @type {string}
   * @memberof InitialMarkupData
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof InitialMarkupData
   */
  correctedText: string;
  /**
   *
   * @type {Array<WordMarkupData>}
   * @memberof InitialMarkupData
   */
  words: Array<WordMarkupData>;
}
/**
 *
 * @export
 * @interface InstanceConfigurationData
 */
export interface InstanceConfigurationData {
  /**
   *
   * @type {MplIntegration}
   * @memberof InstanceConfigurationData
   */
  mpl?: MplIntegration;
}
/**
 *
 * @export
 * @interface IntentData
 */
export interface IntentData {
  /**
   *
   * @type {number}
   * @memberof IntentData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof IntentData
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof IntentData
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof IntentData
   */
  answer?: string;
  /**
   * Any custom data attached to the intent. application/json type.
   * @type {string}
   * @memberof IntentData
   */
  customData?: string;
  /**
   *
   * @type {boolean}
   * @memberof IntentData
   */
  enabled?: boolean;
  /**
   * A shared intent is used for classification in all projects. It is only permitted for a shared project to set this field value to \'true\'.
   * @type {boolean}
   * @memberof IntentData
   */
  shared?: boolean;
  /**
   *
   * @type {Array<PhraseMarkupData>}
   * @memberof IntentData
   */
  phrases?: Array<PhraseMarkupData>;
  /**
   *
   * @type {ClassificationRulesData}
   * @memberof IntentData
   */
  classificationRules?: ClassificationRulesData;
  /**
   *
   * @type {Array<string>}
   * @memberof IntentData
   */
  patterns?: Array<string>;
  /**
   *
   * @type {Array<SlotData>}
   * @memberof IntentData
   */
  slots?: Array<SlotData>;
  /**
   * Intent priority. Might be used to choose a top intent in a situation of equal scores. More value - higher priority.
   * @type {number}
   * @memberof IntentData
   */
  priority?: number;
}
/**
 *
 * @export
 * @interface IntentRecursiveUpdateData
 */
export interface IntentRecursiveUpdateData {
  /**
   *
   * @type {IntentData}
   * @memberof IntentRecursiveUpdateData
   */
  updatedIntent?: IntentData;
  /**
   *
   * @type {Array<IntentSummaryData>}
   * @memberof IntentRecursiveUpdateData
   */
  affectedRecords?: Array<IntentSummaryData>;
}
/**
 *
 * @export
 * @interface IntentResultData
 */
export interface IntentResultData {
  /**
   *
   * @type {number}
   * @memberof IntentResultData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof IntentResultData
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof IntentResultData
   */
  answer?: string;
  /**
   *
   * @type {string}
   * @memberof IntentResultData
   */
  customData?: string;
  /**
   *
   * @type {Array<SlotData>}
   * @memberof IntentResultData
   */
  slots?: Array<SlotData>;
  /**
   * Intent priority. Might be used to choose a top intent in a situation of equal scores. More value - higher priority.
   * @type {number}
   * @memberof IntentResultData
   */
  priority?: number;
  /**
   *
   * @type {boolean}
   * @memberof IntentResultData
   */
  shared?: boolean;
}
/**
 *
 * @export
 * @interface IntentSummaryData
 */
export interface IntentSummaryData {
  /**
   *
   * @type {number}
   * @memberof IntentSummaryData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof IntentSummaryData
   */
  path?: string;
  /**
   *
   * @type {boolean}
   * @memberof IntentSummaryData
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof IntentSummaryData
   */
  itemsCount?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof IntentSummaryData
   */
  stagedPhrases?: Array<number>;
}
/**
 *
 * @export
 * @interface IntentsSearchCriteria
 */
export interface IntentsSearchCriteria {
  /**
   * substring of intent path, ignore-case search
   * @type {string}
   * @memberof IntentsSearchCriteria
   */
  path?: string;
  /**
   * substring of intent description, ignore-case search
   * @type {string}
   * @memberof IntentsSearchCriteria
   */
  description?: string;
  /**
   * substring of intent answer, ignore-case search
   * @type {string}
   * @memberof IntentsSearchCriteria
   */
  answer?: string;
  /**
   * substring of intent phrase, ignore-case search
   * @type {string}
   * @memberof IntentsSearchCriteria
   */
  phrases?: string;
  /**
   * substring of intent slot, ignore-case search
   * @type {string}
   * @memberof IntentsSearchCriteria
   */
  slots?: string;
}
/**
 *
 * @export
 * @interface KmeansClusterizerParameters
 */
export interface KmeansClusterizerParameters {
  /**
   * Language (ru, en, zh, etc.)
   * @type {string}
   * @memberof KmeansClusterizerParameters
   */
  lang?: string;
  /**
   * Number of clusters to split texts into
   * @type {number}
   * @memberof KmeansClusterizerParameters
   */
  clusterizeNClusters?: number;
}
/**
 *
 * @export
 * @interface KnownSlotData
 */
export interface KnownSlotData {
  /**
   *
   * @type {string}
   * @memberof KnownSlotData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof KnownSlotData
   */
  value?: string;
  /**
   * If slots are considered as array, parseTree value will contain list of values for this slot. False - only first value will be shown.
   * @type {boolean}
   * @memberof KnownSlotData
   */
  array?: boolean;
}
/**
 *
 * @export
 * @interface LinkageClusterizerParameters
 */
export interface LinkageClusterizerParameters {
  /**
   * Language (ru, en, zh, etc.)
   * @type {string}
   * @memberof LinkageClusterizerParameters
   */
  lang?: string;
  /**
   * Threshold for linkage clustering. Lower will produce more cluster.
   * @type {number}
   * @memberof LinkageClusterizerParameters
   */
  threshold?: number;
  /**
   * The number of examples to clusterize (random selection).
   * @type {number}
   * @memberof LinkageClusterizerParameters
   */
  clusterizeNExamples?: number;
  /**
   * Metric to compare vectors
   * @type {string}
   * @memberof LinkageClusterizerParameters
   */
  metric?: string;
}
/**
 *
 * @export
 * @interface LogGroupingData
 */
export interface LogGroupingData {
  /**
   *
   * @type {number}
   * @memberof LogGroupingData
   */
  groupingId?: number;
  /**
   *
   * @type {string}
   * @memberof LogGroupingData
   */
  name?: string;
  /**
   *
   * @type {GroupingRequestData}
   * @memberof LogGroupingData
   */
  parameters?: GroupingRequestData;
  /**
   *
   * @type {Array<GroupInfoData>}
   * @memberof LogGroupingData
   */
  groups?: Array<GroupInfoData>;
  /**
   *
   * @type {string}
   * @memberof LogGroupingData
   */
  error?: string;
}
/**
 *
 * @export
 * @interface LogLabelingSessionData
 */
export interface LogLabelingSessionData {
  /**
   *
   * @type {number}
   * @memberof LogLabelingSessionData
   */
  id?: number;
  /**
   *
   * @type {Date}
   * @memberof LogLabelingSessionData
   */
  startTime?: Date;
  /**
   *
   * @type {string}
   * @memberof LogLabelingSessionData
   */
  owner?: string;
  /**
   *
   * @type {number}
   * @memberof LogLabelingSessionData
   */
  phrasesTotal?: number;
  /**
   *
   * @type {number}
   * @memberof LogLabelingSessionData
   */
  phrasesProcessed?: number;
  /**
   *
   * @type {boolean}
   * @memberof LogLabelingSessionData
   */
  markupIsReady?: boolean;
  /**
   *
   * @type {string}
   * @memberof LogLabelingSessionData
   */
  type?: TypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TypeEnum {
  FileSession = 'fileSession',
  ChatHistorySession = 'chatHistorySession',
}

/**
 *
 * @export
 * @interface MoveStagingIntentPhrasesRequest
 */
export interface MoveStagingIntentPhrasesRequest {
  /**
   *
   * @type {number}
   * @memberof MoveStagingIntentPhrasesRequest
   */
  fromIntentId: number;
  /**
   *
   * @type {number}
   * @memberof MoveStagingIntentPhrasesRequest
   */
  toIntentId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MoveStagingIntentPhrasesRequest
   */
  phrases: Array<string>;
}
/**
 *
 * @export
 * @interface MoveToIntentData
 */
export interface MoveToIntentData {
  /**
   *
   * @type {number}
   * @memberof MoveToIntentData
   */
  intentId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof MoveToIntentData
   */
  phrases: Array<number>;
}
/**
 *
 * @export
 * @interface MoveToIntentMultipleData
 */
export interface MoveToIntentMultipleData {
  /**
   *
   * @type {number}
   * @memberof MoveToIntentMultipleData
   */
  groupingId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof MoveToIntentMultipleData
   */
  phrases: Array<number>;
}
/**
 *
 * @export
 * @interface MplIntegration
 */
export interface MplIntegration {
  /**
   *
   * @type {boolean}
   * @memberof MplIntegration
   */
  enabled?: boolean;
  /**
   *
   * @type {object}
   * @memberof MplIntegration
   */
  configuration?: object;
}
/**
 *
 * @export
 * @interface MultipleRecursiveUpdateIntentData
 */
export interface MultipleRecursiveUpdateIntentData {
  /**
   *
   * @type {IntentData}
   * @memberof MultipleRecursiveUpdateIntentData
   */
  intent?: IntentData;
  /**
   *
   * @type {number}
   * @memberof MultipleRecursiveUpdateIntentData
   */
  intentId?: number;
  /**
   *
   * @type {number}
   * @memberof MultipleRecursiveUpdateIntentData
   */
  logLabelingIdInQuery?: number;
}
/**
 *
 * @export
 * @interface NLUStatusData
 */
export interface NLUStatusData {
  /**
   *
   * @type {string}
   * @memberof NLUStatusData
   */
  trainingStatus?: TrainingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof NLUStatusData
   */
  lastError?: string;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  lastChangeInIntents?: Date;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  lastChangeInEntities?: Date;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  lastModelTrainStart?: Date;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  lastModelTrainTime?: Date;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  cachedModelTrainStart?: Date;
  /**
   *
   * @type {Date}
   * @memberof NLUStatusData
   */
  cachedModelTrainTime?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum TrainingStatusEnum {
  NONE = 'NONE',
  TRAINING = 'TRAINING',
  READY = 'READY',
  FAILED = 'FAILED',
}

/**
 *
 * @export
 * @interface NamedEntitiesWithRecordsData
 */
export interface NamedEntitiesWithRecordsData {
  /**
   *
   * @type {Array<NamedEntityWithRecordsData>}
   * @memberof NamedEntitiesWithRecordsData
   */
  entities?: Array<NamedEntityWithRecordsData>;
}
/**
 *
 * @export
 * @interface NamedEntityData
 */
export interface NamedEntityData {
  /**
   *
   * @type {number}
   * @memberof NamedEntityData
   */
  id?: number;
  /**
   * Name of the entity. Will be accessible as @<name> in patterns and phrase examples.
   * @type {string}
   * @memberof NamedEntityData
   */
  name: string;
  /**
   * Wheter this entity enabled or not
   * @type {boolean}
   * @memberof NamedEntityData
   */
  enabled?: boolean;
  /**
   * A shared entity is used for entities lookup in all projects. It is only permitted for a shared project to set this field value to \'true\'.
   * @type {boolean}
   * @memberof NamedEntityData
   */
  shared?: boolean;
  /**
   *
   * @type {NamedEntityType}
   * @memberof NamedEntityData
   */
  type: NamedEntityType;
  /**
   * It is a special field for manual conflict resolution, when some entitie handles one piece of text
   * @type {number}
   * @memberof NamedEntityData
   */
  priority?: number;
  /**
   * Disable automatic spelling correction for the entity. If enabled it means that entity extraction will be proceeded before spelling correction
   * @type {boolean}
   * @memberof NamedEntityData
   */
  noSpelling?: boolean;
  /**
   * Disable morphological insensitivity for the entity. If enabled it means that synonyms matching will be proceeded with exact text matching. So \'write\' and \'wrote\' will be a different words.
   * @type {boolean}
   * @memberof NamedEntityData
   */
  noMorph?: boolean;
  /**
   *
   * @type {number}
   * @memberof NamedEntityData
   */
  itemsCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof NamedEntityData
   */
  phoneticSearch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NamedEntityData
   */
  fuzzySearch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NamedEntityData
   */
  client?: boolean;
  /**
   * True hides entity in caila-front
   * @type {boolean}
   * @memberof NamedEntityData
   */
  hidden?: boolean;
}
/**
 *
 * @export
 * @interface NamedEntityOccurrenceData
 */
export interface NamedEntityOccurrenceData {
  /**
   *
   * @type {PhraseMarkupData}
   * @memberof NamedEntityOccurrenceData
   */
  phrase?: PhraseMarkupData;
}
/**
 *
 * @export
 * @interface NamedEntityOccurrencesData
 */
export interface NamedEntityOccurrencesData {
  /**
   *
   * @type {Pagination}
   * @memberof NamedEntityOccurrencesData
   */
  paging: Pagination;
  /**
   *
   * @type {Array<NamedEntityOccurrenceData>}
   * @memberof NamedEntityOccurrencesData
   */
  records: Array<NamedEntityOccurrenceData>;
}
/**
 *
 * @export
 * @interface NamedEntityRecordData
 */
export interface NamedEntityRecordData {
  /**
   *
   * @type {number}
   * @memberof NamedEntityRecordData
   */
  id?: number;
  /**
   *
   * @type {NamedEntityRecordType}
   * @memberof NamedEntityRecordData
   */
  type: NamedEntityRecordType;
  /**
   *
   * @type {Array<string>}
   * @memberof NamedEntityRecordData
   */
  rule: Array<string>;
  /**
   * Value associated with the entity. Usually it is a string, number or json object
   * @type {string}
   * @memberof NamedEntityRecordData
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof NamedEntityRecordData
   */
  clientId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum NamedEntityRecordType {
  Pattern = 'pattern',
  Synonyms = 'synonyms',
}

/**
 *
 * @export
 * @interface NamedEntityRecordsData
 */
export interface NamedEntityRecordsData {
  /**
   *
   * @type {Pagination}
   * @memberof NamedEntityRecordsData
   */
  paging: Pagination;
  /**
   *
   * @type {Array<NamedEntityRecordData>}
   * @memberof NamedEntityRecordsData
   */
  records: Array<NamedEntityRecordData>;
}
/**
 * Type of the named entity. Dictionary - for rules and dictionaries and Annotation - for ML-based entities.
 * @export
 * @enum {string}
 */
export enum NamedEntityType {
  Dictionary = 'dictionary',
  Annotation = 'annotation',
}

/**
 *
 * @export
 * @interface NamedEntityWithRecordsData
 */
export interface NamedEntityWithRecordsData {
  /**
   *
   * @type {NamedEntityData}
   * @memberof NamedEntityWithRecordsData
   */
  entity?: NamedEntityData;
  /**
   *
   * @type {Array<NamedEntityRecordData>}
   * @memberof NamedEntityWithRecordsData
   */
  records?: Array<NamedEntityRecordData>;
}
/**
 *
 * @export
 * @interface NluActionDescription
 */
export interface NluActionDescription {
  /**
   * NLU type.
   * @type {string}
   * @memberof NluActionDescription
   */
  nluType?: NluTypeEnum;
  /**
   * External NLU provider URL. Cannot be null if `external` value is set to `nluType` property.
   * @type {string}
   * @memberof NluActionDescription
   */
  url?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NluTypeEnum {
  Caila = 'caila',
  Internal = 'internal',
  External = 'external',
}

/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface PhraseIndexWithConfidence
 */
export interface PhraseIndexWithConfidence {
  /**
   *
   * @type {number}
   * @memberof PhraseIndexWithConfidence
   */
  phraseIdx: number;
  /**
   *
   * @type {number}
   * @memberof PhraseIndexWithConfidence
   */
  confidence?: number;
  /**
   *
   * @type {string}
   * @memberof PhraseIndexWithConfidence
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof PhraseIndexWithConfidence
   */
  questionId?: string;
}
/**
 *
 * @export
 * @interface PhraseMarkupData
 */
export interface PhraseMarkupData {
  /**
   *
   * @type {string}
   * @memberof PhraseMarkupData
   */
  text?: string;
  /**
   *
   * @type {Array<EntityMarkupData>}
   * @memberof PhraseMarkupData
   */
  entities?: Array<EntityMarkupData>;
  /**
   *
   * @type {number}
   * @memberof PhraseMarkupData
   */
  stagedPhraseIdx?: number;
  /**
   *
   * @type {Array<WordMarkupData>}
   * @memberof PhraseMarkupData
   */
  phraseMarkup?: Array<WordMarkupData>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PhraseStatusData {
  P = 'p',
  S = 's',
  A = 'a',
  D = 'd',
}

/**
 *
 * @export
 * @interface PhrasesCleaningRequestData
 */
export interface PhrasesCleaningRequestData {
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  specialSymbolsRemoving?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  shortStringsRemoving?: boolean;
  /**
   *
   * @type {number}
   * @memberof PhrasesCleaningRequestData
   */
  shortStringLength?: number;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  longStringsRemoving?: boolean;
  /**
   *
   * @type {number}
   * @memberof PhrasesCleaningRequestData
   */
  longStringLength?: number;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  spellingCorrection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  stopWordsRemoving?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  entitiesReplacing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhrasesCleaningRequestData
   */
  duplicatesRemoving?: boolean;
}
/**
 *
 * @export
 * @interface PhrasesCleaningResponse
 */
export interface PhrasesCleaningResponse {
  /**
   *
   * @type {number}
   * @memberof PhrasesCleaningResponse
   */
  numberOfDeletedPhrases?: number;
  /**
   *
   * @type {number}
   * @memberof PhrasesCleaningResponse
   */
  numberOfUpdatedPhrases?: number;
}
/**
 *
 * @export
 * @interface ProjectData
 */
export interface ProjectData {
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  folder?: string;
}
/**
 *
 * @export
 * @interface ProjectExportImportData
 */
export interface ProjectExportImportData {
  /**
   *
   * @type {ProjectData}
   * @memberof ProjectExportImportData
   */
  project?: ProjectData;
  /**
   *
   * @type {ProjectSettingsData}
   * @memberof ProjectExportImportData
   */
  settings?: ProjectSettingsData;
  /**
   *
   * @type {Array<IntentData>}
   * @memberof ProjectExportImportData
   */
  intents?: Array<IntentData>;
  /**
   *
   * @type {Array<NamedEntityWithRecordsData>}
   * @memberof ProjectExportImportData
   */
  entities?: Array<NamedEntityWithRecordsData>;
  /**
   *
   * @type {Array<string>}
   * @memberof ProjectExportImportData
   */
  enabledSystemEntities?: Array<string>;
  /**
   *
   * @type {Array<WordAndFrequencyData>}
   * @memberof ProjectExportImportData
   */
  spellerDictionary?: Array<WordAndFrequencyData>;
  /**
   * This tag overrides enabledSystemEntities tag.
   * @type {Array<SystemNamedEntitySummary>}
   * @memberof ProjectExportImportData
   */
  systemEntities?: Array<SystemNamedEntitySummary>;
  /**
   *
   * @type {Array<FaqQuestionExportImportData>}
   * @memberof ProjectExportImportData
   */
  faq?: Array<FaqQuestionExportImportData>;
  /**
   *
   * @type {Array<SkillWithContentData>}
   * @memberof ProjectExportImportData
   */
  skills?: Array<SkillWithContentData>;
}
/**
 *
 * @export
 * @interface ProjectSettingsData
 */
export interface ProjectSettingsData {
  /**
   *
   * @type {string}
   * @memberof ProjectSettingsData
   */
  language?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProjectSettingsData
   */
  spellingCorrection?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectSettingsData
   */
  classificationAlgorithm?: ClassificationAlgorithmEnum;
  /**
   *
   * @type {string}
   * @memberof ProjectSettingsData
   */
  timezone?: string;
  /**
   *
   * @type {ExtendedProjectSettings}
   * @memberof ProjectSettingsData
   */
  extendedSettings?: ExtendedProjectSettings;
  /**
   * Defines if the project entities and intents are shared for the platform. Settings this property to \'true\' is enabled only for the projects with feature \'system_admin\'.
   * @type {boolean}
   * @memberof ProjectSettingsData
   */
  shared?: boolean;
  /**
   *
   * @type {SoftValidationSettingsData}
   * @memberof ProjectSettingsData
   */
  validation?: SoftValidationSettingsData;
}

/**
 * @export
 * @enum {string}
 */
export enum ClassificationAlgorithmEnum {
  Sts = 'sts',
  Regression = 'regression',
  Deeplearning = 'deeplearning',
  Logreg = 'logreg',
  Transformer = 'transformer',
}

/**
 *
 * @export
 * @interface ProjectsData
 */
export interface ProjectsData {
  /**
   *
   * @type {Pagination}
   * @memberof ProjectsData
   */
  paging: Pagination;
  /**
   *
   * @type {Array<ProjectData>}
   * @memberof ProjectsData
   */
  records: Array<ProjectData>;
}
/**
 *
 * @export
 * @interface SimilarAnswerData
 */
export interface SimilarAnswerData {
  /**
   * New answer
   * @type {string}
   * @memberof SimilarAnswerData
   */
  origin?: string;
  /**
   * similar phrase
   * @type {string}
   * @memberof SimilarAnswerData
   */
  intentPath?: string;
  /**
   * similar answer
   * @type {string}
   * @memberof SimilarAnswerData
   */
  similar?: string;
  /**
   *
   * @type {number}
   * @memberof SimilarAnswerData
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof SimilarAnswerData
   */
  intentId?: number;
}
/**
 *
 * @export
 * @interface SimilarPhraseData
 */
export interface SimilarPhraseData {
  /**
   * phrase
   * @type {string}
   * @memberof SimilarPhraseData
   */
  origin?: string;
  /**
   * similar phrase
   * @type {string}
   * @memberof SimilarPhraseData
   */
  intentPath?: string;
  /**
   *
   * @type {number}
   * @memberof SimilarPhraseData
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof SimilarPhraseData
   */
  intentId?: number;
}
/**
 *
 * @export
 * @interface SkillContentData
 */
export interface SkillContentData {
  /**
   *
   * @type {string}
   * @memberof SkillContentData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SkillContentData
   */
  content?: string;
}
/**
 *
 * @export
 * @interface SkillContentFile
 */
export interface SkillContentFile {
  /**
   *
   * @type {string}
   * @memberof SkillContentFile
   */
  name?: string;
}
/**
 *
 * @export
 * @interface SkillContentFiles
 */
export interface SkillContentFiles {
  /**
   *
   * @type {Array<SkillContentFile>}
   * @memberof SkillContentFiles
   */
  files?: Array<SkillContentFile>;
}
/**
 *
 * @export
 * @interface SkillData
 */
export interface SkillData {
  /**
   *
   * @type {string}
   * @memberof SkillData
   */
  id: string;
  /**
   *
   * @type {SkillType}
   * @memberof SkillData
   */
  type: SkillType;
  /**
   *
   * @type {string}
   * @memberof SkillData
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof SkillData
   */
  enabled: boolean;
  /**
   *
   * @type {AuditEventRecord}
   * @memberof SkillData
   */
  created?: AuditEventRecord;
  /**
   *
   * @type {AuditEventRecord}
   * @memberof SkillData
   */
  updated?: AuditEventRecord;
  /**
   *
   * @type {object}
   * @memberof SkillData
   */
  settings?: object;
}
/**
 *
 * @export
 * @interface SkillStatusData
 */
export interface SkillStatusData {
  /**
   *
   * @type {string}
   * @memberof SkillStatusData
   */
  skillTrainingStatusData?: SkillTrainingStatusDataEnum;
  /**
   *
   * @type {string}
   * @memberof SkillStatusData
   */
  lastError?: string;
  /**
   *
   * @type {Date}
   * @memberof SkillStatusData
   */
  lastSkillTrainStart?: Date;
  /**
   *
   * @type {Date}
   * @memberof SkillStatusData
   */
  lastSkillTrainTime?: Date;
  /**
   *
   * @type {Date}
   * @memberof SkillStatusData
   */
  cachedSkillTrainStart?: Date;
  /**
   *
   * @type {Date}
   * @memberof SkillStatusData
   */
  cachedSkillTrainTime?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum SkillTrainingStatusDataEnum {
  NONE = 'NONE',
  TRAINING = 'TRAINING',
  READY = 'READY',
  FAILED = 'FAILED',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SkillType {
  Faq = 'faq',
  Cdqa = 'cdqa',
}

/**
 *
 * @export
 * @interface SkillWithContentData
 */
export interface SkillWithContentData {
  /**
   *
   * @type {CreateSkillRequest}
   * @memberof SkillWithContentData
   */
  skill: CreateSkillRequest;
  /**
   *
   * @type {SkillContentData}
   * @memberof SkillWithContentData
   */
  content: SkillContentData;
  /**
   *
   * @type {object}
   * @memberof SkillWithContentData
   */
  settings?: object;
}
/**
 * It\'s an experimental structure for slot-filling configuration
 * @export
 * @interface SlotData
 */
export interface SlotData {
  /**
   * Slot (variable) name.
   * @type {string}
   * @memberof SlotData
   */
  name?: string;
  /**
   * Entity (type) name.
   * @type {string}
   * @memberof SlotData
   */
  entity?: string;
  /**
   * Is this slot require fot this intent.
   * @type {boolean}
   * @memberof SlotData
   */
  required?: boolean;
  /**
   * Promts list to use as a questions to request a value
   * @type {Array<string>}
   * @memberof SlotData
   */
  prompts?: Array<string>;
  /**
   * If slots are considered as array, parseTree value will contain list of values for this slot. False - only first value will be shown.
   * @type {boolean}
   * @memberof SlotData
   */
  array?: boolean;
}
/**
 *
 * @export
 * @interface SoftValidationResponseData
 */
export interface SoftValidationResponseData {
  /**
   * Similar phrases
   * @type {Array<SimilarAnswerData>}
   * @memberof SoftValidationResponseData
   */
  answers?: Array<SimilarAnswerData>;
  /**
   * Similar to new phrase intents
   * @type {Array<SimilarPhraseData>}
   * @memberof SoftValidationResponseData
   */
  phrases?: Array<SimilarPhraseData>;
}
/**
 *
 * @export
 * @interface SoftValidationSettingsData
 */
export interface SoftValidationSettingsData {
  /**
   * Enables phrases validation in intents.
   * @type {boolean}
   * @memberof SoftValidationSettingsData
   */
  phraseValidationEnabled?: boolean;
  /**
   * Enables answer validation in intents.
   * @type {boolean}
   * @memberof SoftValidationSettingsData
   */
  answerValidationEnabled?: boolean;
  /**
   * Threshold for phrases validation. Higher will returns most similar intents for phrase.
   * @type {number}
   * @memberof SoftValidationSettingsData
   */
  phraseValidationThreshold?: number;
  /**
   * Threshold for answers validation. Higher will returns most similar intents for phrase.
   * @type {number}
   * @memberof SoftValidationSettingsData
   */
  answerValidationThreshold?: number;
}
/**
 *
 * @export
 * @interface SpelledWordWithInfoData
 */
export interface SpelledWordWithInfoData {
  /**
   *
   * @type {string}
   * @memberof SpelledWordWithInfoData
   */
  word: string;
  /**
   *
   * @type {string}
   * @memberof SpelledWordWithInfoData
   */
  spelledBy: SpelledByEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SpelledByEnum {
  Mlps = 'mlps',
  Local = 'local',
}

/**
 *
 * @export
 * @interface SpelledWordsData
 */
export interface SpelledWordsData {
  /**
   *
   * @type {string}
   * @memberof SpelledWordsData
   */
  source: string;
  /**
   *
   * @type {number}
   * @memberof SpelledWordsData
   */
  startPos: number;
  /**
   *
   * @type {number}
   * @memberof SpelledWordsData
   */
  endPos: number;
  /**
   *
   * @type {boolean}
   * @memberof SpelledWordsData
   */
  excluded: boolean;
  /**
   *
   * @type {Array<SpelledWordWithInfoData>}
   * @memberof SpelledWordsData
   */
  variants: Array<SpelledWordWithInfoData>;
}
/**
 *
 * @export
 * @interface SpellerWordData
 */
export interface SpellerWordData {
  /**
   *
   * @type {string}
   * @memberof SpellerWordData
   */
  word: string;
}
/**
 *
 * @export
 * @interface StagingIntentData
 */
export interface StagingIntentData {
  /**
   *
   * @type {number}
   * @memberof StagingIntentData
   */
  intentId?: number;
  /**
   *
   * @type {string}
   * @memberof StagingIntentData
   */
  path?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof StagingIntentData
   */
  stagedPhrases?: Array<string>;
}
/**
 *
 * @export
 * @interface StsDbscanClusterizerParameters
 */
export interface StsDbscanClusterizerParameters {
  /**
   *
   * @type {number}
   * @memberof StsDbscanClusterizerParameters
   */
  pts?: number;
  /**
   *
   * @type {number}
   * @memberof StsDbscanClusterizerParameters
   */
  eps?: number;
}
/**
 *
 * @export
 * @interface SystemNamedEntityData
 */
export interface SystemNamedEntityData {
  /**
   * Name of a system entity
   * @type {string}
   * @memberof SystemNamedEntityData
   */
  name?: string;
  /**
   * Alias of external entity.
   * @type {string}
   * @memberof SystemNamedEntityData
   */
  externalName?: string;
  /**
   *
   * @type {SystemNamedEntityEngine}
   * @memberof SystemNamedEntityData
   */
  engine?: SystemNamedEntityEngine;
  /**
   * List of available languages with localized information
   * @type {Array<SystemNamedEntityLocale>}
   * @memberof SystemNamedEntityData
   */
  locales?: Array<SystemNamedEntityLocale>;
}
/**
 *
 * @export
 * @interface SystemNamedEntityEngine
 */
export interface SystemNamedEntityEngine {
  /**
   * Name of an engine.
   * @type {string}
   * @memberof SystemNamedEntityEngine
   */
  name?: string;
  /**
   * Version of an engine.
   * @type {string}
   * @memberof SystemNamedEntityEngine
   */
  version?: string;
}
/**
 *
 * @export
 * @interface SystemNamedEntityExampleData
 */
export interface SystemNamedEntityExampleData {
  /**
   *
   * @type {string}
   * @memberof SystemNamedEntityExampleData
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof SystemNamedEntityExampleData
   */
  value?: string;
}
/**
 *
 * @export
 * @interface SystemNamedEntityLocale
 */
export interface SystemNamedEntityLocale {
  /**
   * Available language for a system entity
   * @type {string}
   * @memberof SystemNamedEntityLocale
   */
  language: string;
  /**
   *
   * @type {SystemNamedEntityLocalizedInfo}
   * @memberof SystemNamedEntityLocale
   */
  locale?: SystemNamedEntityLocalizedInfo;
}
/**
 *
 * @export
 * @interface SystemNamedEntityLocalizedInfo
 */
export interface SystemNamedEntityLocalizedInfo {
  /**
   * Description of entity
   * @type {string}
   * @memberof SystemNamedEntityLocalizedInfo
   */
  description?: string;
  /**
   * Examples of matched strings and its values
   * @type {Array<SystemNamedEntityExampleData>}
   * @memberof SystemNamedEntityLocalizedInfo
   */
  examples?: Array<SystemNamedEntityExampleData>;
}
/**
 *
 * @export
 * @interface SystemNamedEntitySummary
 */
export interface SystemNamedEntitySummary {
  /**
   * Name of an entity
   * @type {string}
   * @memberof SystemNamedEntitySummary
   */
  name?: string;
  /**
   * Last selected version of the engine
   * @type {string}
   * @memberof SystemNamedEntitySummary
   */
  version?: string;
  /**
   * If an entity is enabled
   * @type {boolean}
   * @memberof SystemNamedEntitySummary
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface TariffUpdateData
 */
export interface TariffUpdateData {
  /**
   * Maximum number of Direct API calls that is allowed for account tariff.
   * @type {number}
   * @memberof TariffUpdateData
   */
  maxDirectApiCalls: number;
  /**
   * Maximum number of Direct API calls that is allowed for account tariff by the limits of additional packages.
   * @type {number}
   * @memberof TariffUpdateData
   */
  maxDirectApiCallsByAdditionalPackages: number;
  /**
   * Whether to reset current limit values or not.
   * @type {boolean}
   * @memberof TariffUpdateData
   */
  resetLimits: boolean;
  /**
   * Is the current tariff blocked or not. If so, account functions will be limited.
   * @type {boolean}
   * @memberof TariffUpdateData
   */
  blocked: boolean;
}
/**
 *
 * @export
 * @interface TfIdfKweParameters
 */
export interface TfIdfKweParameters {
  /**
   * Text will be lower-cased
   * @type {boolean}
   * @memberof TfIdfKweParameters
   */
  lower?: boolean;
  /**
   * Word n-grams to use (from 1 to n)
   * @type {number}
   * @memberof TfIdfKweParameters
   */
  wordNgrams?: number;
  /**
   * Skip gap to form the n-grams (not larger than word_ngrams)
   * @type {number}
   * @memberof TfIdfKweParameters
   */
  skipGramN?: number;
  /**
   * Min frequency of the uni-gram to add it into the vocabulary
   * @type {number}
   * @memberof TfIdfKweParameters
   */
  unigramNoBelow?: number;
  /**
   * Min frequency of the n-gram to add it into the vocabulary
   * @type {number}
   * @memberof TfIdfKweParameters
   */
  ngramNoBelow?: number;
  /**
   * How many top keywords will be returned for each example (or less if not enough)
   * @type {number}
   * @memberof TfIdfKweParameters
   */
  showTopNKeywords?: number;
  /**
   * Language (ru, en etc.) to filter stopwords
   * @type {string}
   * @memberof TfIdfKweParameters
   */
  stopwordsLang?: string;
}
/**
 *
 * @export
 * @interface UDPipeKweParameters
 */
export interface UDPipeKweParameters {
  /**
   * Language (ru, en, zh, etc.)
   * @type {string}
   * @memberof UDPipeKweParameters
   */
  lang?: string;
  /**
   * Use only verb head
   * @type {boolean}
   * @memberof UDPipeKweParameters
   */
  onlyVerbHead?: boolean;
  /**
   * Text will be lemmatized
   * @type {boolean}
   * @memberof UDPipeKweParameters
   */
  lemmatize?: boolean;
  /**
   * Text will be lower-cased
   * @type {boolean}
   * @memberof UDPipeKweParameters
   */
  lower?: boolean;
}
/**
 *
 * @export
 * @interface UpdateAllNamedEntityRecordData
 */
export interface UpdateAllNamedEntityRecordData {
  /**
   *
   * @type {Array<NamedEntityRecordData>}
   * @memberof UpdateAllNamedEntityRecordData
   */
  data: Array<NamedEntityRecordData>;
  /**
   *
   * @type {string}
   * @memberof UpdateAllNamedEntityRecordData
   */
  clientId?: string;
}
/**
 *
 * @export
 * @interface UpdateNamedEntityRecordData
 */
export interface UpdateNamedEntityRecordData {
  /**
   *
   * @type {NamedEntityRecordData}
   * @memberof UpdateNamedEntityRecordData
   */
  data: NamedEntityRecordData;
  /**
   *
   * @type {string}
   * @memberof UpdateNamedEntityRecordData
   */
  clientId?: string;
}
/**
 *
 * @export
 * @interface UpdateSkillRequest
 */
export interface UpdateSkillRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSkillRequest
   */
  id: string;
  /**
   *
   * @type {SkillType}
   * @memberof UpdateSkillRequest
   */
  type: SkillType;
  /**
   *
   * @type {string}
   * @memberof UpdateSkillRequest
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSkillRequest
   */
  enabled: boolean;
  /**
   *
   * @type {object}
   * @memberof UpdateSkillRequest
   */
  settings?: object;
}
/**
 *
 * @export
 * @interface WordAndFrequencyData
 */
export interface WordAndFrequencyData {
  /**
   * The word
   * @type {string}
   * @memberof WordAndFrequencyData
   */
  word?: string;
  /**
   * The frequency of word
   * @type {number}
   * @memberof WordAndFrequencyData
   */
  frequency?: number;
}
/**
 *
 * @export
 * @interface WordMarkupData
 */
export interface WordMarkupData {
  /**
   *
   * @type {WordMarkupDataAnnotations}
   * @memberof WordMarkupData
   */
  annotations?: WordMarkupDataAnnotations;
  /**
   *
   * @type {number}
   * @memberof WordMarkupData
   */
  startPos?: number;
  /**
   *
   * @type {number}
   * @memberof WordMarkupData
   */
  endPos?: number;
  /**
   *
   * @type {boolean}
   * @memberof WordMarkupData
   */
  pattern?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WordMarkupData
   */
  punctuation?: boolean;
  /**
   *
   * @type {string}
   * @memberof WordMarkupData
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof WordMarkupData
   */
  word?: string;
}
/**
 *
 * @export
 * @interface WordMarkupDataAnnotations
 */
export interface WordMarkupDataAnnotations {
  /**
   *
   * @type {string}
   * @memberof WordMarkupDataAnnotations
   */
  lemma?: string;
  /**
   *
   * @type {string}
   * @memberof WordMarkupDataAnnotations
   */
  pos?: string;
}

/**
 * AsyncApi - axios parameter creator
 * @export
 */
export const AsyncApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveAsyncTasks(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getActiveAsyncTasks.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getActiveAsyncTasks.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsyncTaskStatus(accountId: number, projectId: string, taskId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAsyncTaskStatus.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getAsyncTaskStatus.'
        );
      }
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          'taskId',
          'Required parameter taskId was null or undefined when calling getAsyncTaskStatus.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async/{taskId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopAsyncTask(accountId: number, projectId: string, taskId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling stopAsyncTask.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling stopAsyncTask.'
        );
      }
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          'taskId',
          'Required parameter taskId was null or undefined when calling stopAsyncTask.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/async/{taskId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AsyncApi - functional programming interface
 * @export
 */
export const AsyncApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveAsyncTasks(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AsyncTaskSummary>> {
      const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).getActiveAsyncTasks(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsyncTaskStatus(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsyncTaskResultData> {
      const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).getAsyncTaskStatus(
        accountId,
        projectId,
        taskId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopAsyncTask(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AsyncApiAxiosParamCreator(configuration).stopAsyncTask(
        accountId,
        projectId,
        taskId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AsyncApi - factory interface
 * @export
 */
export const AsyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveAsyncTasks(accountId: number, projectId: string, options?: any): AxiosPromise<Array<AsyncTaskSummary>> {
      return AsyncApiFp(configuration).getActiveAsyncTasks(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsyncTaskStatus(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): AxiosPromise<AsyncTaskResultData> {
      return AsyncApiFp(configuration).getAsyncTaskStatus(accountId, projectId, taskId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopAsyncTask(accountId: number, projectId: string, taskId: number, options?: any): AxiosPromise<void> {
      return AsyncApiFp(configuration).stopAsyncTask(accountId, projectId, taskId, options)(axios, basePath);
    },
  };
};

/**
 * AsyncApi - object-oriented interface
 * @export
 * @class AsyncApi
 * @extends {BaseAPI}
 */
export class AsyncApi extends BaseAPI {
  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AsyncApi
   */
  public getActiveAsyncTasks(accountId: number, projectId: string, options?: any) {
    return AsyncApiFp(this.configuration).getActiveAsyncTasks(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AsyncApi
   */
  public getAsyncTaskStatus(accountId: number, projectId: string, taskId: number, options?: any) {
    return AsyncApiFp(this.configuration).getAsyncTaskStatus(
      accountId,
      projectId,
      taskId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AsyncApi
   */
  public stopAsyncTask(accountId: number, projectId: string, taskId: number, options?: any) {
    return AsyncApiFp(this.configuration).stopAsyncTask(
      accountId,
      projectId,
      taskId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCurrentAccountTariffLimits(options: any = {}): RequestArgs {
      const localVarPath = `/api/caila/billing/limits`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentAccountTariffLimits(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getCurrentAccountTariffLimits.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/billing/limits`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {TariffUpdateData} tariffUpdateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariff(accountId: number, tariffUpdateData: TariffUpdateData, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAccountTariff.'
        );
      }
      // verify required parameter 'tariffUpdateData' is not null or undefined
      if (tariffUpdateData === null || tariffUpdateData === undefined) {
        throw new RequiredError(
          'tariffUpdateData',
          'Required parameter tariffUpdateData was null or undefined when calling updateAccountTariff.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/billing/account-tariff`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof tariffUpdateData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tariffUpdateData !== undefined ? tariffUpdateData : {})
        : tariffUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCurrentAccountTariffLimits(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountTariffLimitsData>> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getAllCurrentAccountTariffLimits(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentAccountTariffLimits(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountTariffLimitsData> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getCurrentAccountTariffLimits(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {TariffUpdateData} tariffUpdateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariff(
      accountId: number,
      tariffUpdateData: TariffUpdateData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateAccountTariff(
        accountId,
        tariffUpdateData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCurrentAccountTariffLimits(options?: any): AxiosPromise<Array<AccountTariffLimitsData>> {
      return BillingApiFp(configuration).getAllCurrentAccountTariffLimits(options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentAccountTariffLimits(accountId: number, options?: any): AxiosPromise<AccountTariffLimitsData> {
      return BillingApiFp(configuration).getCurrentAccountTariffLimits(accountId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {TariffUpdateData} tariffUpdateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariff(accountId: number, tariffUpdateData: TariffUpdateData, options?: any): AxiosPromise<void> {
      return BillingApiFp(configuration).updateAccountTariff(accountId, tariffUpdateData, options)(axios, basePath);
    },
  };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAllCurrentAccountTariffLimits(options?: any) {
    return BillingApiFp(this.configuration).getAllCurrentAccountTariffLimits(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getCurrentAccountTariffLimits(accountId: number, options?: any) {
    return BillingApiFp(this.configuration).getCurrentAccountTariffLimits(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {TariffUpdateData} tariffUpdateData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public updateAccountTariff(accountId: number, tariffUpdateData: TariffUpdateData, options?: any) {
    return BillingApiFp(this.configuration).updateAccountTariff(
      accountId,
      tariffUpdateData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyzeE2ECheck(
      accountId: number,
      projectId: string,
      loggingEnabledE2ECheck: boolean,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      zRequestId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling analyzeE2ECheck.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling analyzeE2ECheck.'
        );
      }
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling analyzeE2ECheck.'
        );
      }
      // verify required parameter 'analyzeRequestData' is not null or undefined
      if (analyzeRequestData === null || analyzeRequestData === undefined) {
        throw new RequiredError(
          'analyzeRequestData',
          'Required parameter analyzeRequestData was null or undefined when calling analyzeE2ECheck.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/e2e-check/nlu/analyze`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      if (zRequestId !== undefined && zRequestId !== null) {
        localVarHeaderParameter['Z-requestId'] = String(zRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof analyzeRequestData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(analyzeRequestData !== undefined ? analyzeRequestData : {})
        : analyzeRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyzeE2ECheck(
      accountId: number,
      projectId: string,
      loggingEnabledE2ECheck: boolean,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      zRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ECheckAnalyzeResponseData> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).analyzeE2ECheck(
        accountId,
        projectId,
        loggingEnabledE2ECheck,
        analyzeRequestData,
        zTimezone,
        zRefTime,
        zRequestId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyzeE2ECheck(
      accountId: number,
      projectId: string,
      loggingEnabledE2ECheck: boolean,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      zRequestId?: string,
      options?: any
    ): AxiosPromise<E2ECheckAnalyzeResponseData> {
      return E2ECheckApiFp(configuration).analyzeE2ECheck(
        accountId,
        projectId,
        loggingEnabledE2ECheck,
        analyzeRequestData,
        zTimezone,
        zRefTime,
        zRequestId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {AnalyzeRequestData} analyzeRequestData
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {string} [zRequestId] Request id header
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public analyzeE2ECheck(
    accountId: number,
    projectId: string,
    loggingEnabledE2ECheck: boolean,
    analyzeRequestData: AnalyzeRequestData,
    zTimezone?: string,
    zRefTime?: number,
    zRequestId?: string,
    options?: any
  ) {
    return E2ECheckApiFp(this.configuration).analyzeE2ECheck(
      accountId,
      projectId,
      loggingEnabledE2ECheck,
      analyzeRequestData,
      zTimezone,
      zRefTime,
      zRequestId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * EntitiesApi - axios parameter creator
 * @export
 */
export const EntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create new entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntity(
      accountId: number,
      projectId: string,
      namedEntityData: NamedEntityData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createEntity.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createEntity.'
        );
      }
      // verify required parameter 'namedEntityData' is not null or undefined
      if (namedEntityData === null || namedEntityData === undefined) {
        throw new RequiredError(
          'namedEntityData',
          'Required parameter namedEntityData was null or undefined when calling createEntity.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof namedEntityData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(namedEntityData !== undefined ? namedEntityData : {})
        : namedEntityData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createEntityRecord.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createEntityRecord.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling createEntityRecord.'
        );
      }
      // verify required parameter 'createNamedEntityRecordData' is not null or undefined
      if (createNamedEntityRecordData === null || createNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'createNamedEntityRecordData',
          'Required parameter createNamedEntityRecordData was null or undefined when calling createEntityRecord.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createNamedEntityRecordData !== undefined ? createNamedEntityRecordData : {})
        : createNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling createEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'createNamedEntityRecordData' is not null or undefined
      if (createNamedEntityRecordData === null || createNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'createNamedEntityRecordData',
          'Required parameter createNamedEntityRecordData was null or undefined when calling createEntityRecordByEntityName.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createNamedEntityRecordData !== undefined ? createNamedEntityRecordData : {})
        : createNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of entities ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntities(accountId: number, projectId: string, requestBody: Array<number>, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteEntities.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/delete-multiple`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of entities names.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntitiesByNames(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteEntitiesByNames.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteEntitiesByNames.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteEntitiesByNames.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/delete-multiple-by-name`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      requestBody: Array<number>,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteRecords.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteRecords.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling deleteRecords.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteRecords.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/delete-multiple`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      requestBody: Array<number>,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteRecordsByEntityName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteRecordsByEntityName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling deleteRecordsByEntityName.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteRecordsByEntityName.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records/delete-multiple`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all project entities with records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportEntities(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling exportEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling exportEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/export-file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available entity names. It will be used for autocompletion.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEntityNames(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAllEntityNames.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getAllEntityNames.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/all-names`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get entities by names.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [names]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesByNames(accountId: number, projectId: string, names?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntitiesByNames.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntitiesByNames.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/get-by-names`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (names) {
        localVarQueryParameter['names'] = names;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get named entity.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntity(accountId: number, projectId: string, entityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntity.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntity.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling getEntity.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityByName(
      accountId: number,
      projectId: string,
      entityName: string,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntityByName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntityByName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling getEntityByName.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-name/{entityName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sentences with this entity. It could be useful for ML-based entities
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityOccurrences(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntityOccurrences.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntityOccurrences.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling getEntityOccurrences.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/occurences`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntityRecords.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntityRecords.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling getEntityRecords.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['searchQuery'] = searchQuery;
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEntityRecordsByEntityName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEntityRecordsByEntityName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling getEntityRecordsByEntityName.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['searchQuery'] = searchQuery;
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import all project entities with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importEntities(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importEntities.'
        );
      }
      // verify required parameter 'strategy' is not null or undefined
      if (strategy === null || strategy === undefined) {
        throw new RequiredError(
          'strategy',
          'Required parameter strategy was null or undefined when calling importEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/import`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (strategy !== undefined) {
        localVarQueryParameter['strategy'] = strategy;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List of all available system entities. Method is only for testing purpose.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableSystemEntities(options: any = {}): RequestArgs {
      const localVarPath = `/api/caila/system-entities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEnabledSystemEntities(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listEnabledSystemEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listEnabledSystemEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/enabled-system-entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List project entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [withRecordsCount] Add records count to entites in list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntities(accountId: number, projectId: string, withRecordsCount?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withRecordsCount !== undefined) {
        localVarQueryParameter['withRecordsCount'] = withRecordsCount;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} entityNames Entity names
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntitiesByNames(
      accountId: number,
      projectId: string,
      entityNames: Array<string>,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listEntitiesByNames.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listEntitiesByNames.'
        );
      }
      // verify required parameter 'entityNames' is not null or undefined
      if (entityNames === null || entityNames === undefined) {
        throw new RequiredError(
          'entityNames',
          'Required parameter entityNames was null or undefined when calling listEntitiesByNames.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (entityNames) {
        localVarQueryParameter['entityNames'] = entityNames;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPermittedSystemEntities(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listPermittedSystemEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listPermittedSystemEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/permitted-system-entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      entitiesSearchCriteria: EntitiesSearchCriteria,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling search.');
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling search.');
      }
      // verify required parameter 'entitiesSearchCriteria' is not null or undefined
      if (entitiesSearchCriteria === null || entitiesSearchCriteria === undefined) {
        throw new RequiredError(
          'entitiesSearchCriteria',
          'Required parameter entitiesSearchCriteria was null or undefined when calling search.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/search`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof entitiesSearchCriteria !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(entitiesSearchCriteria !== undefined ? entitiesSearchCriteria : {})
        : entitiesSearchCriteria || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAllEntityRecords.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateAllEntityRecords.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling updateAllEntityRecords.'
        );
      }
      // verify required parameter 'updateAllNamedEntityRecordData' is not null or undefined
      if (updateAllNamedEntityRecordData === null || updateAllNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'updateAllNamedEntityRecordData',
          'Required parameter updateAllNamedEntityRecordData was null or undefined when calling updateAllEntityRecords.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateAllNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAllNamedEntityRecordData !== undefined ? updateAllNamedEntityRecordData : {})
        : updateAllNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAllEntityRecordsByEntityName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateAllEntityRecordsByEntityName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling updateAllEntityRecordsByEntityName.'
        );
      }
      // verify required parameter 'updateAllNamedEntityRecordData' is not null or undefined
      if (updateAllNamedEntityRecordData === null || updateAllNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'updateAllNamedEntityRecordData',
          'Required parameter updateAllNamedEntityRecordData was null or undefined when calling updateAllEntityRecordsByEntityName.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-name/{entityName}/records`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateAllNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAllNamedEntityRecordData !== undefined ? updateAllNamedEntityRecordData : {})
        : updateAllNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnabledSystemEntities(
      accountId: number,
      projectId: string,
      systemNamedEntitySummary: Array<SystemNamedEntitySummary>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateEnabledSystemEntities.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateEnabledSystemEntities.'
        );
      }
      // verify required parameter 'systemNamedEntitySummary' is not null or undefined
      if (systemNamedEntitySummary === null || systemNamedEntitySummary === undefined) {
        throw new RequiredError(
          'systemNamedEntitySummary',
          'Required parameter systemNamedEntitySummary was null or undefined when calling updateEnabledSystemEntities.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/enabled-system-entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof systemNamedEntitySummary !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(systemNamedEntitySummary !== undefined ? systemNamedEntitySummary : {})
        : systemNamedEntitySummary || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update named entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntity(
      accountId: number,
      projectId: string,
      entityId: number,
      namedEntityData: NamedEntityData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateEntity.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateEntity.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling updateEntity.'
        );
      }
      // verify required parameter 'namedEntityData' is not null or undefined
      if (namedEntityData === null || namedEntityData === undefined) {
        throw new RequiredError(
          'namedEntityData',
          'Required parameter namedEntityData was null or undefined when calling updateEntity.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof namedEntityData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(namedEntityData !== undefined ? namedEntityData : {})
        : namedEntityData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateEntityRecord.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateEntityRecord.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling updateEntityRecord.'
        );
      }
      // verify required parameter 'recordId' is not null or undefined
      if (recordId === null || recordId === undefined) {
        throw new RequiredError(
          'recordId',
          'Required parameter recordId was null or undefined when calling updateEntityRecord.'
        );
      }
      // verify required parameter 'updateNamedEntityRecordData' is not null or undefined
      if (updateNamedEntityRecordData === null || updateNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'updateNamedEntityRecordData',
          'Required parameter updateNamedEntityRecordData was null or undefined when calling updateEntityRecord.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/{recordId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)))
        .replace(`{${'recordId'}}`, encodeURIComponent(String(recordId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateNamedEntityRecordData !== undefined ? updateNamedEntityRecordData : {})
        : updateNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling updateEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'recordId' is not null or undefined
      if (recordId === null || recordId === undefined) {
        throw new RequiredError(
          'recordId',
          'Required parameter recordId was null or undefined when calling updateEntityRecordByEntityName.'
        );
      }
      // verify required parameter 'updateNamedEntityRecordData' is not null or undefined
      if (updateNamedEntityRecordData === null || updateNamedEntityRecordData === undefined) {
        throw new RequiredError(
          'updateNamedEntityRecordData',
          'Required parameter updateNamedEntityRecordData was null or undefined when calling updateEntityRecordByEntityName.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/entities-by-names/{entityName}/records/{recordId}`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'entityName'}}`, encodeURIComponent(String(entityName)))
          .replace(`{${'recordId'}}`, encodeURIComponent(String(recordId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateNamedEntityRecordData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateNamedEntityRecordData !== undefined ? updateNamedEntityRecordData : {})
        : updateNamedEntityRecordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload records from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {'zb-csv'} type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      type: 'zb-csv',
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling uploadRecords.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling uploadRecords.'
        );
      }
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling uploadRecords.'
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling uploadRecords.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/entities/{entityId}/records/upload`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'entityId'}}`, encodeURIComponent(String(entityId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EntitiesApi - functional programming interface
 * @export
 */
export const EntitiesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create new entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntity(
      accountId: number,
      projectId: string,
      namedEntityData: NamedEntityData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntity(
        accountId,
        projectId,
        namedEntityData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntityRecord(
        accountId,
        projectId,
        entityId,
        createNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).createEntityRecordByEntityName(
        accountId,
        projectId,
        entityName,
        createNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of entities ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntities(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteEntities(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of entities names.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntitiesByNames(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteEntitiesByNames(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      requestBody: Array<number>,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteRecords(
        accountId,
        projectId,
        entityId,
        requestBody,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      requestBody: Array<number>,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).deleteRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        requestBody,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export all project entities with records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportEntities(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).exportEntities(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available entity names. It will be used for autocompletion.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEntityNames(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getAllEntityNames(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get entities by names.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [names]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesByNames(
      accountId: number,
      projectId: string,
      names?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntitiesByNames(
        accountId,
        projectId,
        names,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get named entity.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntity(
      accountId: number,
      projectId: string,
      entityId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntity(
        accountId,
        projectId,
        entityId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityByName(
      accountId: number,
      projectId: string,
      entityName: string,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityWithRecordsData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityByName(
        accountId,
        projectId,
        entityName,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get sentences with this entity. It could be useful for ML-based entities
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityOccurrences(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityOccurrencesData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityOccurrences(
        accountId,
        projectId,
        entityId,
        page,
        size,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordsData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityRecords(
        accountId,
        projectId,
        entityId,
        page,
        size,
        searchQuery,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordsData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).getEntityRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        page,
        size,
        searchQuery,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Import all project entities with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importEntities(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityWithRecordsData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).importEntities(
        accountId,
        projectId,
        strategy,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List of all available system entities. Method is only for testing purpose.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableSystemEntities(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemNamedEntityData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listAvailableSystemEntities(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEnabledSystemEntities(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemNamedEntitySummary>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEnabledSystemEntities(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List project entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [withRecordsCount] Add records count to entites in list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntities(
      accountId: number,
      projectId: string,
      withRecordsCount?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEntities(
        accountId,
        projectId,
        withRecordsCount,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} entityNames Entity names
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntitiesByNames(
      accountId: number,
      projectId: string,
      entityNames: Array<string>,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntitiesWithRecordsData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listEntitiesByNames(
        accountId,
        projectId,
        entityNames,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPermittedSystemEntities(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemNamedEntityData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).listPermittedSystemEntities(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      entitiesSearchCriteria: EntitiesSearchCriteria,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).search(
        accountId,
        projectId,
        entitiesSearchCriteria,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityRecordData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateAllEntityRecords(
        accountId,
        projectId,
        entityId,
        updateAllNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityRecordData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateAllEntityRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        updateAllNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnabledSystemEntities(
      accountId: number,
      projectId: string,
      systemNamedEntitySummary: Array<SystemNamedEntitySummary>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemNamedEntitySummary>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEnabledSystemEntities(
        accountId,
        projectId,
        systemNamedEntitySummary,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update named entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntity(
      accountId: number,
      projectId: string,
      entityId: number,
      namedEntityData: NamedEntityData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntity(
        accountId,
        projectId,
        entityId,
        namedEntityData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntityRecord(
        accountId,
        projectId,
        entityId,
        recordId,
        updateNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedEntityRecordData> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).updateEntityRecordByEntityName(
        accountId,
        projectId,
        entityName,
        recordId,
        updateNamedEntityRecordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload records from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {'zb-csv'} type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      type: 'zb-csv',
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NamedEntityRecordData>> {
      const localVarAxiosArgs = EntitiesApiAxiosParamCreator(configuration).uploadRecords(
        accountId,
        projectId,
        entityId,
        type,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EntitiesApi - factory interface
 * @export
 */
export const EntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create new entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntity(
      accountId: number,
      projectId: string,
      namedEntityData: NamedEntityData,
      options?: any
    ): AxiosPromise<NamedEntityData> {
      return EntitiesApiFp(configuration).createEntity(accountId, projectId, namedEntityData, options)(axios, basePath);
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options?: any
    ): AxiosPromise<NamedEntityRecordData> {
      return EntitiesApiFp(configuration).createEntityRecord(
        accountId,
        projectId,
        entityId,
        createNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Create new entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      createNamedEntityRecordData: CreateNamedEntityRecordData,
      options?: any
    ): AxiosPromise<NamedEntityRecordData> {
      return EntitiesApiFp(configuration).createEntityRecordByEntityName(
        accountId,
        projectId,
        entityName,
        createNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of entities ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntities(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return EntitiesApiFp(configuration).deleteEntities(accountId, projectId, requestBody, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a list of entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of entities names.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntitiesByNames(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return EntitiesApiFp(configuration).deleteEntitiesByNames(
        accountId,
        projectId,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      requestBody: Array<number>,
      clientId?: string,
      options?: any
    ): AxiosPromise<void> {
      return EntitiesApiFp(configuration).deleteRecords(
        accountId,
        projectId,
        entityId,
        requestBody,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete multiple records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {Array<number>} requestBody Record IDs to be deleted
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      requestBody: Array<number>,
      clientId?: string,
      options?: any
    ): AxiosPromise<void> {
      return EntitiesApiFp(configuration).deleteRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        requestBody,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Export all project entities with records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportEntities(accountId: number, projectId: string, options?: any): AxiosPromise<any> {
      return EntitiesApiFp(configuration).exportEntities(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available entity names. It will be used for autocompletion.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEntityNames(accountId: number, projectId: string, options?: any): AxiosPromise<Array<string>> {
      return EntitiesApiFp(configuration).getAllEntityNames(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get entities by names.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [names]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesByNames(
      accountId: number,
      projectId: string,
      names?: Array<string>,
      options?: any
    ): AxiosPromise<Array<NamedEntityData>> {
      return EntitiesApiFp(configuration).getEntitiesByNames(accountId, projectId, names, options)(axios, basePath);
    },
    /**
     *
     * @summary Get named entity.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntity(accountId: number, projectId: string, entityId: number, options?: any): AxiosPromise<NamedEntityData> {
      return EntitiesApiFp(configuration).getEntity(accountId, projectId, entityId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityByName(
      accountId: number,
      projectId: string,
      entityName: string,
      clientId?: string,
      options?: any
    ): AxiosPromise<NamedEntityWithRecordsData> {
      return EntitiesApiFp(configuration).getEntityByName(
        accountId,
        projectId,
        entityName,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get sentences with this entity. It could be useful for ML-based entities
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityOccurrences(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<NamedEntityOccurrencesData> {
      return EntitiesApiFp(configuration).getEntityOccurrences(
        accountId,
        projectId,
        entityId,
        page,
        size,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options?: any
    ): AxiosPromise<NamedEntityRecordsData> {
      return EntitiesApiFp(configuration).getEntityRecords(
        accountId,
        projectId,
        entityId,
        page,
        size,
        searchQuery,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get named entity dictionary records
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {string} [searchQuery] Search query to filter entity records
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      page?: number,
      size?: number,
      searchQuery?: string,
      clientId?: string,
      options?: any
    ): AxiosPromise<NamedEntityRecordsData> {
      return EntitiesApiFp(configuration).getEntityRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        page,
        size,
        searchQuery,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Import all project entities with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importEntities(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options?: any
    ): AxiosPromise<Array<NamedEntityWithRecordsData>> {
      return EntitiesApiFp(configuration).importEntities(
        accountId,
        projectId,
        strategy,
        file,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary List of all available system entities. Method is only for testing purpose.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableSystemEntities(options?: any): AxiosPromise<Array<SystemNamedEntityData>> {
      return EntitiesApiFp(configuration).listAvailableSystemEntities(options)(axios, basePath);
    },
    /**
     *
     * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEnabledSystemEntities(
      accountId: number,
      projectId: string,
      options?: any
    ): AxiosPromise<Array<SystemNamedEntitySummary>> {
      return EntitiesApiFp(configuration).listEnabledSystemEntities(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary List project entities.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [withRecordsCount] Add records count to entites in list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntities(
      accountId: number,
      projectId: string,
      withRecordsCount?: boolean,
      options?: any
    ): AxiosPromise<Array<NamedEntityData>> {
      return EntitiesApiFp(configuration).listEntities(
        accountId,
        projectId,
        withRecordsCount,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get entity with records by name
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} entityNames Entity names
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEntitiesByNames(
      accountId: number,
      projectId: string,
      entityNames: Array<string>,
      clientId?: string,
      options?: any
    ): AxiosPromise<NamedEntitiesWithRecordsData> {
      return EntitiesApiFp(configuration).listEntitiesByNames(
        accountId,
        projectId,
        entityNames,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPermittedSystemEntities(
      accountId: number,
      projectId: string,
      options?: any
    ): AxiosPromise<Array<SystemNamedEntityData>> {
      return EntitiesApiFp(configuration).listPermittedSystemEntities(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      entitiesSearchCriteria: EntitiesSearchCriteria,
      options?: any
    ): AxiosPromise<Array<NamedEntityData>> {
      return EntitiesApiFp(configuration).search(
        accountId,
        projectId,
        entitiesSearchCriteria,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options?: any
    ): AxiosPromise<Array<NamedEntityRecordData>> {
      return EntitiesApiFp(configuration).updateAllEntityRecords(
        accountId,
        projectId,
        entityId,
        updateAllNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update content of the entity all at once.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllEntityRecordsByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
      options?: any
    ): AxiosPromise<Array<NamedEntityRecordData>> {
      return EntitiesApiFp(configuration).updateAllEntityRecordsByEntityName(
        accountId,
        projectId,
        entityName,
        updateAllNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnabledSystemEntities(
      accountId: number,
      projectId: string,
      systemNamedEntitySummary: Array<SystemNamedEntitySummary>,
      options?: any
    ): AxiosPromise<Array<SystemNamedEntitySummary>> {
      return EntitiesApiFp(configuration).updateEnabledSystemEntities(
        accountId,
        projectId,
        systemNamedEntitySummary,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update named entity
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {NamedEntityData} namedEntityData New intents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntity(
      accountId: number,
      projectId: string,
      entityId: number,
      namedEntityData: NamedEntityData,
      options?: any
    ): AxiosPromise<NamedEntityData> {
      return EntitiesApiFp(configuration).updateEntity(
        accountId,
        projectId,
        entityId,
        namedEntityData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecord(
      accountId: number,
      projectId: string,
      entityId: number,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options?: any
    ): AxiosPromise<NamedEntityRecordData> {
      return EntitiesApiFp(configuration).updateEntityRecord(
        accountId,
        projectId,
        entityId,
        recordId,
        updateNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update entity record
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} entityName Entity unique name.
     * @param {number} recordId Record identifier.
     * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntityRecordByEntityName(
      accountId: number,
      projectId: string,
      entityName: string,
      recordId: number,
      updateNamedEntityRecordData: UpdateNamedEntityRecordData,
      options?: any
    ): AxiosPromise<NamedEntityRecordData> {
      return EntitiesApiFp(configuration).updateEntityRecordByEntityName(
        accountId,
        projectId,
        entityName,
        recordId,
        updateNamedEntityRecordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Upload records from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} entityId Entity identifier.
     * @param {'zb-csv'} type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRecords(
      accountId: number,
      projectId: string,
      entityId: number,
      type: 'zb-csv',
      file?: any,
      options?: any
    ): AxiosPromise<Array<NamedEntityRecordData>> {
      return EntitiesApiFp(configuration).uploadRecords(
        accountId,
        projectId,
        entityId,
        type,
        file,
        options
      )(axios, basePath);
    },
  };
};

/**
 * EntitiesApi - object-oriented interface
 * @export
 * @class EntitiesApi
 * @extends {BaseAPI}
 */
export class EntitiesApi extends BaseAPI {
  /**
   *
   * @summary Create new entity
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {NamedEntityData} namedEntityData New intents
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public createEntity(accountId: number, projectId: string, namedEntityData: NamedEntityData, options?: any) {
    return EntitiesApiFp(this.configuration).createEntity(
      accountId,
      projectId,
      namedEntityData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create new entity record
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public createEntityRecord(
    accountId: number,
    projectId: string,
    entityId: number,
    createNamedEntityRecordData: CreateNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).createEntityRecord(
      accountId,
      projectId,
      entityId,
      createNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create new entity record
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {CreateNamedEntityRecordData} createNamedEntityRecordData New record
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public createEntityRecordByEntityName(
    accountId: number,
    projectId: string,
    entityName: string,
    createNamedEntityRecordData: CreateNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).createEntityRecordByEntityName(
      accountId,
      projectId,
      entityName,
      createNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a list of entities.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<number>} requestBody List of entities ID\&#39;s.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public deleteEntities(accountId: number, projectId: string, requestBody: Array<number>, options?: any) {
    return EntitiesApiFp(this.configuration).deleteEntities(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a list of entities.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} requestBody List of entities names.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public deleteEntitiesByNames(accountId: number, projectId: string, requestBody: Array<string>, options?: any) {
    return EntitiesApiFp(this.configuration).deleteEntitiesByNames(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete multiple records
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {Array<number>} requestBody Record IDs to be deleted
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public deleteRecords(
    accountId: number,
    projectId: string,
    entityId: number,
    requestBody: Array<number>,
    clientId?: string,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).deleteRecords(
      accountId,
      projectId,
      entityId,
      requestBody,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete multiple records
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {Array<number>} requestBody Record IDs to be deleted
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public deleteRecordsByEntityName(
    accountId: number,
    projectId: string,
    entityName: string,
    requestBody: Array<number>,
    clientId?: string,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).deleteRecordsByEntityName(
      accountId,
      projectId,
      entityName,
      requestBody,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export all project entities with records
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public exportEntities(accountId: number, projectId: string, options?: any) {
    return EntitiesApiFp(this.configuration).exportEntities(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available entity names. It will be used for autocompletion.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getAllEntityNames(accountId: number, projectId: string, options?: any) {
    return EntitiesApiFp(this.configuration).getAllEntityNames(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get entities by names.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} [names]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntitiesByNames(accountId: number, projectId: string, names?: Array<string>, options?: any) {
    return EntitiesApiFp(this.configuration).getEntitiesByNames(
      accountId,
      projectId,
      names,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get named entity.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntity(accountId: number, projectId: string, entityId: number, options?: any) {
    return EntitiesApiFp(this.configuration).getEntity(
      accountId,
      projectId,
      entityId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get entity with records by name
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntityByName(accountId: number, projectId: string, entityName: string, clientId?: string, options?: any) {
    return EntitiesApiFp(this.configuration).getEntityByName(
      accountId,
      projectId,
      entityName,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get sentences with this entity. It could be useful for ML-based entities
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {number} [page] Page.
   * @param {number} [size] Maximum number of items to return.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntityOccurrences(
    accountId: number,
    projectId: string,
    entityId: number,
    page?: number,
    size?: number,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).getEntityOccurrences(
      accountId,
      projectId,
      entityId,
      page,
      size,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get named entity dictionary records
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {number} [page] Page.
   * @param {number} [size] Maximum number of items to return.
   * @param {string} [searchQuery] Search query to filter entity records
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntityRecords(
    accountId: number,
    projectId: string,
    entityId: number,
    page?: number,
    size?: number,
    searchQuery?: string,
    clientId?: string,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).getEntityRecords(
      accountId,
      projectId,
      entityId,
      page,
      size,
      searchQuery,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get named entity dictionary records
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {number} [page] Page.
   * @param {number} [size] Maximum number of items to return.
   * @param {string} [searchQuery] Search query to filter entity records
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public getEntityRecordsByEntityName(
    accountId: number,
    projectId: string,
    entityName: string,
    page?: number,
    size?: number,
    searchQuery?: string,
    clientId?: string,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).getEntityRecordsByEntityName(
      accountId,
      projectId,
      entityName,
      page,
      size,
      searchQuery,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Import all project entities with specified strategy.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ImportFileStrategy} strategy
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public importEntities(accountId: number, projectId: string, strategy: ImportFileStrategy, file?: any, options?: any) {
    return EntitiesApiFp(this.configuration).importEntities(
      accountId,
      projectId,
      strategy,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List of all available system entities. Method is only for testing purpose.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public listAvailableSystemEntities(options?: any) {
    return EntitiesApiFp(this.configuration).listAvailableSystemEntities(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary List of system entities that are currently configured for the project. Items of returned array consist of system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public listEnabledSystemEntities(accountId: number, projectId: string, options?: any) {
    return EntitiesApiFp(this.configuration).listEnabledSystemEntities(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List project entities.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [withRecordsCount] Add records count to entites in list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public listEntities(accountId: number, projectId: string, withRecordsCount?: boolean, options?: any) {
    return EntitiesApiFp(this.configuration).listEntities(
      accountId,
      projectId,
      withRecordsCount,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get entity with records by name
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} entityNames Entity names
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public listEntitiesByNames(
    accountId: number,
    projectId: string,
    entityNames: Array<string>,
    clientId?: string,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).listEntitiesByNames(
      accountId,
      projectId,
      entityNames,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List of system entities that are permitted for project according to it\'s settings (includes enabled and disabled entities).
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public listPermittedSystemEntities(accountId: number, projectId: string, options?: any) {
    return EntitiesApiFp(this.configuration).listPermittedSystemEntities(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Search entities by entity name, synonym, pattern, JSON. Results are combined by each search criteria using OR.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {EntitiesSearchCriteria} entitiesSearchCriteria Search criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public search(accountId: number, projectId: string, entitiesSearchCriteria: EntitiesSearchCriteria, options?: any) {
    return EntitiesApiFp(this.configuration).search(
      accountId,
      projectId,
      entitiesSearchCriteria,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update content of the entity all at once.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateAllEntityRecords(
    accountId: number,
    projectId: string,
    entityId: number,
    updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateAllEntityRecords(
      accountId,
      projectId,
      entityId,
      updateAllNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update content of the entity all at once.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {UpdateAllNamedEntityRecordData} updateAllNamedEntityRecordData New entity records list.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateAllEntityRecordsByEntityName(
    accountId: number,
    projectId: string,
    entityName: string,
    updateAllNamedEntityRecordData: UpdateAllNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateAllEntityRecordsByEntityName(
      accountId,
      projectId,
      entityName,
      updateAllNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update list of enabled and disabled system entities for project. Format of body array items is system entity internal name (which is \"<engine_name>.<entity_name>\"), last selected version of engine and \'enabled\' status.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<SystemNamedEntitySummary>} systemNamedEntitySummary List of system entities.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateEnabledSystemEntities(
    accountId: number,
    projectId: string,
    systemNamedEntitySummary: Array<SystemNamedEntitySummary>,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateEnabledSystemEntities(
      accountId,
      projectId,
      systemNamedEntitySummary,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update named entity
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {NamedEntityData} namedEntityData New intents
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateEntity(
    accountId: number,
    projectId: string,
    entityId: number,
    namedEntityData: NamedEntityData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateEntity(
      accountId,
      projectId,
      entityId,
      namedEntityData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update entity record
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {number} recordId Record identifier.
   * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateEntityRecord(
    accountId: number,
    projectId: string,
    entityId: number,
    recordId: number,
    updateNamedEntityRecordData: UpdateNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateEntityRecord(
      accountId,
      projectId,
      entityId,
      recordId,
      updateNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update entity record
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} entityName Entity unique name.
   * @param {number} recordId Record identifier.
   * @param {UpdateNamedEntityRecordData} updateNamedEntityRecordData New record
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public updateEntityRecordByEntityName(
    accountId: number,
    projectId: string,
    entityName: string,
    recordId: number,
    updateNamedEntityRecordData: UpdateNamedEntityRecordData,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).updateEntityRecordByEntityName(
      accountId,
      projectId,
      entityName,
      recordId,
      updateNamedEntityRecordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Upload records from file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} entityId Entity identifier.
   * @param {'zb-csv'} type
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntitiesApi
   */
  public uploadRecords(
    accountId: number,
    projectId: string,
    entityId: number,
    type: 'zb-csv',
    file?: any,
    options?: any
  ) {
    return EntitiesApiFp(this.configuration).uploadRecords(
      accountId,
      projectId,
      entityId,
      type,
      file,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ExternalNluApi - axios parameter creator
 * @export
 */
export const ExternalNluApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Checks that the external NLU endpoint is available.
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testExternalNluLink(body: string, options: any = {}): RequestArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling testExternalNluLink.'
        );
      }
      const localVarPath = `/api/caila/external-nlu/test-link`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'text/plain';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalNluApi - functional programming interface
 * @export
 */
export const ExternalNluApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Checks that the external NLU endpoint is available.
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testExternalNluLink(body: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ExternalNluApiAxiosParamCreator(configuration).testExternalNluLink(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ExternalNluApi - factory interface
 * @export
 */
export const ExternalNluApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Checks that the external NLU endpoint is available.
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testExternalNluLink(body: string, options?: any): AxiosPromise<void> {
      return ExternalNluApiFp(configuration).testExternalNluLink(body, options)(axios, basePath);
    },
  };
};

/**
 * ExternalNluApi - object-oriented interface
 * @export
 * @class ExternalNluApi
 * @extends {BaseAPI}
 */
export class ExternalNluApi extends BaseAPI {
  /**
   * Checks that the external NLU endpoint is available.
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalNluApi
   */
  public testExternalNluLink(body: string, options?: any) {
    return ExternalNluApiFp(this.configuration).testExternalNluLink(body, options)(this.axios, this.basePath);
  }
}

/**
 * FAQApi - axios parameter creator
 * @export
 */
export const FAQApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a single FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {FaqQuestionData} faqQuestionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionData: FaqQuestionData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createFaqQuestion.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createFaqQuestion.'
        );
      }
      // verify required parameter 'faqQuestionData' is not null or undefined
      if (faqQuestionData === null || faqQuestionData === undefined) {
        throw new RequiredError(
          'faqQuestionData',
          'Required parameter faqQuestionData was null or undefined when calling createFaqQuestion.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof faqQuestionData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(faqQuestionData !== undefined ? faqQuestionData : {})
        : faqQuestionData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Export intents with id\'s from request from project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportFAQ(accountId: number, projectId: string, requestBody: Array<number>, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling exportFAQ.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling exportFAQ.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling exportFAQ.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/export-file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Method to get all FAQ templates for project by it\'s language
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [language] User language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQTemplates(accountId: number, projectId: string, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFAQTemplates.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFAQTemplates.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/templates`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} intentPath Intent path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionByIntent(accountId: number, projectId: string, intentPath: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFaqQuestionByIntent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFaqQuestionByIntent.'
        );
      }
      // verify required parameter 'intentPath' is not null or undefined
      if (intentPath === null || intentPath === undefined) {
        throw new RequiredError(
          'intentPath',
          'Required parameter intentPath was null or undefined when calling getFaqQuestionByIntent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (intentPath !== undefined) {
        localVarQueryParameter['intentPath'] = intentPath;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project FAQ questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [faqName] Faq name (intent prefix)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionList(accountId: number, projectId: string, faqName?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFaqQuestionList.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFaqQuestionList.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions/list`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (faqName !== undefined) {
        localVarQueryParameter['faqName'] = faqName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFile(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importFAQFromFile.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importFAQFromFile.'
        );
      }
      // verify required parameter 'ignoreNonCriticalErrors' is not null or undefined
      if (ignoreNonCriticalErrors === null || ignoreNonCriticalErrors === undefined) {
        throw new RequiredError(
          'ignoreNonCriticalErrors',
          'Required parameter ignoreNonCriticalErrors was null or undefined when calling importFAQFromFile.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (ignoreNonCriticalErrors !== undefined) {
        localVarQueryParameter['ignoreNonCriticalErrors'] = ignoreNonCriticalErrors;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {string} faqName FAQ name
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFileToExistingFAQ(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      faqName: string,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importFAQFromFileToExistingFAQ.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importFAQFromFileToExistingFAQ.'
        );
      }
      // verify required parameter 'ignoreNonCriticalErrors' is not null or undefined
      if (ignoreNonCriticalErrors === null || ignoreNonCriticalErrors === undefined) {
        throw new RequiredError(
          'ignoreNonCriticalErrors',
          'Required parameter ignoreNonCriticalErrors was null or undefined when calling importFAQFromFileToExistingFAQ.'
        );
      }
      // verify required parameter 'faqName' is not null or undefined
      if (faqName === null || faqName === undefined) {
        throw new RequiredError(
          'faqName',
          'Required parameter faqName was null or undefined when calling importFAQFromFileToExistingFAQ.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-file-to-existing-faq`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (ignoreNonCriticalErrors !== undefined) {
        localVarQueryParameter['ignoreNonCriticalErrors'] = ignoreNonCriticalErrors;
      }

      if (faqName !== undefined) {
        localVarQueryParameter['faqName'] = faqName;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * import FAQ from prepared template
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} faqTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromTemplate(accountId: number, projectId: string, faqTemplateId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importFAQFromTemplate.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importFAQFromTemplate.'
        );
      }
      // verify required parameter 'faqTemplateId' is not null or undefined
      if (faqTemplateId === null || faqTemplateId === undefined) {
        throw new RequiredError(
          'faqTemplateId',
          'Required parameter faqTemplateId was null or undefined when calling importFAQFromTemplate.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/faq/import-from-template`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (faqTemplateId !== undefined) {
        localVarQueryParameter['faqTemplateId'] = faqTemplateId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update FAQ question and all child questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} faqQuestionId FAQ Question identifier.
     * @param {FaqQuestionData} faqQuestionData
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionId: number,
      faqQuestionData: FaqQuestionData,
      softValidate?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateFaqQuestion.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateFaqQuestion.'
        );
      }
      // verify required parameter 'faqQuestionId' is not null or undefined
      if (faqQuestionId === null || faqQuestionId === undefined) {
        throw new RequiredError(
          'faqQuestionId',
          'Required parameter faqQuestionId was null or undefined when calling updateFaqQuestion.'
        );
      }
      // verify required parameter 'faqQuestionData' is not null or undefined
      if (faqQuestionData === null || faqQuestionData === undefined) {
        throw new RequiredError(
          'faqQuestionData',
          'Required parameter faqQuestionData was null or undefined when calling updateFaqQuestion.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/faq/questions/{faqQuestionId}/recursive-update`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'faqQuestionId'}}`, encodeURIComponent(String(faqQuestionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (softValidate !== undefined) {
        localVarQueryParameter['softValidate'] = softValidate;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof faqQuestionData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(faqQuestionData !== undefined ? faqQuestionData : {})
        : faqQuestionData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FAQApi - functional programming interface
 * @export
 */
export const FAQApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a single FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {FaqQuestionData} faqQuestionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionData: FaqQuestionData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqQuestionData> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).createFaqQuestion(
        accountId,
        projectId,
        faqQuestionData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Export intents with id\'s from request from project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportFAQ(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).exportFAQ(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Method to get all FAQ templates for project by it\'s language
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [language] User language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQTemplates(
      accountId: number,
      projectId: string,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FAQTemplate>> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFAQTemplates(
        accountId,
        projectId,
        language,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} intentPath Intent path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionByIntent(
      accountId: number,
      projectId: string,
      intentPath: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqQuestionData> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFaqQuestionByIntent(
        accountId,
        projectId,
        intentPath,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project FAQ questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [faqName] Faq name (intent prefix)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionList(
      accountId: number,
      projectId: string,
      faqName?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FaqQuestionData>> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).getFaqQuestionList(
        accountId,
        projectId,
        faqName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFile(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromFile(
        accountId,
        projectId,
        ignoreNonCriticalErrors,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {string} faqName FAQ name
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFileToExistingFAQ(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      faqName: string,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromFileToExistingFAQ(
        accountId,
        projectId,
        ignoreNonCriticalErrors,
        faqName,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * import FAQ from prepared template
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} faqTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromTemplate(
      accountId: number,
      projectId: string,
      faqTemplateId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).importFAQFromTemplate(
        accountId,
        projectId,
        faqTemplateId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update FAQ question and all child questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} faqQuestionId FAQ Question identifier.
     * @param {FaqQuestionData} faqQuestionData
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionId: number,
      faqQuestionData: FaqQuestionData,
      softValidate?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqQuestionData> {
      const localVarAxiosArgs = FAQApiAxiosParamCreator(configuration).updateFaqQuestion(
        accountId,
        projectId,
        faqQuestionId,
        faqQuestionData,
        softValidate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FAQApi - factory interface
 * @export
 */
export const FAQApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a single FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {FaqQuestionData} faqQuestionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionData: FaqQuestionData,
      options?: any
    ): AxiosPromise<FaqQuestionData> {
      return FAQApiFp(configuration).createFaqQuestion(accountId, projectId, faqQuestionData, options)(axios, basePath);
    },
    /**
     * Export intents with id\'s from request from project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportFAQ(accountId: number, projectId: string, requestBody: Array<number>, options?: any): AxiosPromise<any> {
      return FAQApiFp(configuration).exportFAQ(accountId, projectId, requestBody, options)(axios, basePath);
    },
    /**
     * Method to get all FAQ templates for project by it\'s language
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [language] User language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQTemplates(
      accountId: number,
      projectId: string,
      language?: string,
      options?: any
    ): AxiosPromise<Array<FAQTemplate>> {
      return FAQApiFp(configuration).getFAQTemplates(accountId, projectId, language, options)(axios, basePath);
    },
    /**
     *
     * @summary Get FAQ question
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} intentPath Intent path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionByIntent(
      accountId: number,
      projectId: string,
      intentPath: string,
      options?: any
    ): AxiosPromise<FaqQuestionData> {
      return FAQApiFp(configuration).getFaqQuestionByIntent(accountId, projectId, intentPath, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project FAQ questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [faqName] Faq name (intent prefix)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaqQuestionList(
      accountId: number,
      projectId: string,
      faqName?: string,
      options?: any
    ): AxiosPromise<Array<FaqQuestionData>> {
      return FAQApiFp(configuration).getFaqQuestionList(accountId, projectId, faqName, options)(axios, basePath);
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFile(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      file?: any,
      options?: any
    ): AxiosPromise<string> {
      return FAQApiFp(configuration).importFAQFromFile(
        accountId,
        projectId,
        ignoreNonCriticalErrors,
        file,
        options
      )(axios, basePath);
    },
    /**
     * import FAQ from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
     * @param {string} faqName FAQ name
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromFileToExistingFAQ(
      accountId: number,
      projectId: string,
      ignoreNonCriticalErrors: boolean,
      faqName: string,
      file?: any,
      options?: any
    ): AxiosPromise<void> {
      return FAQApiFp(configuration).importFAQFromFileToExistingFAQ(
        accountId,
        projectId,
        ignoreNonCriticalErrors,
        faqName,
        file,
        options
      )(axios, basePath);
    },
    /**
     * import FAQ from prepared template
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} faqTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFAQFromTemplate(
      accountId: number,
      projectId: string,
      faqTemplateId: string,
      options?: any
    ): AxiosPromise<string> {
      return FAQApiFp(configuration).importFAQFromTemplate(
        accountId,
        projectId,
        faqTemplateId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update FAQ question and all child questions
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} faqQuestionId FAQ Question identifier.
     * @param {FaqQuestionData} faqQuestionData
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFaqQuestion(
      accountId: number,
      projectId: string,
      faqQuestionId: number,
      faqQuestionData: FaqQuestionData,
      softValidate?: boolean,
      options?: any
    ): AxiosPromise<FaqQuestionData> {
      return FAQApiFp(configuration).updateFaqQuestion(
        accountId,
        projectId,
        faqQuestionId,
        faqQuestionData,
        softValidate,
        options
      )(axios, basePath);
    },
  };
};

/**
 * FAQApi - object-oriented interface
 * @export
 * @class FAQApi
 * @extends {BaseAPI}
 */
export class FAQApi extends BaseAPI {
  /**
   *
   * @summary Create a single FAQ question
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {FaqQuestionData} faqQuestionData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public createFaqQuestion(accountId: number, projectId: string, faqQuestionData: FaqQuestionData, options?: any) {
    return FAQApiFp(this.configuration).createFaqQuestion(
      accountId,
      projectId,
      faqQuestionData,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Export intents with id\'s from request from project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public exportFAQ(accountId: number, projectId: string, requestBody: Array<number>, options?: any) {
    return FAQApiFp(this.configuration).exportFAQ(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Method to get all FAQ templates for project by it\'s language
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} [language] User language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public getFAQTemplates(accountId: number, projectId: string, language?: string, options?: any) {
    return FAQApiFp(this.configuration).getFAQTemplates(
      accountId,
      projectId,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get FAQ question
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} intentPath Intent path
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public getFaqQuestionByIntent(accountId: number, projectId: string, intentPath: string, options?: any) {
    return FAQApiFp(this.configuration).getFaqQuestionByIntent(
      accountId,
      projectId,
      intentPath,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project FAQ questions
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} [faqName] Faq name (intent prefix)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public getFaqQuestionList(accountId: number, projectId: string, faqName?: string, options?: any) {
    return FAQApiFp(this.configuration).getFaqQuestionList(
      accountId,
      projectId,
      faqName,
      options
    )(this.axios, this.basePath);
  }

  /**
   * import FAQ from file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public importFAQFromFile(
    accountId: number,
    projectId: string,
    ignoreNonCriticalErrors: boolean,
    file?: any,
    options?: any
  ) {
    return FAQApiFp(this.configuration).importFAQFromFile(
      accountId,
      projectId,
      ignoreNonCriticalErrors,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   * import FAQ from file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} ignoreNonCriticalErrors Whether faq import should ignore non-critical errors
   * @param {string} faqName FAQ name
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public importFAQFromFileToExistingFAQ(
    accountId: number,
    projectId: string,
    ignoreNonCriticalErrors: boolean,
    faqName: string,
    file?: any,
    options?: any
  ) {
    return FAQApiFp(this.configuration).importFAQFromFileToExistingFAQ(
      accountId,
      projectId,
      ignoreNonCriticalErrors,
      faqName,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   * import FAQ from prepared template
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} faqTemplateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public importFAQFromTemplate(accountId: number, projectId: string, faqTemplateId: string, options?: any) {
    return FAQApiFp(this.configuration).importFAQFromTemplate(
      accountId,
      projectId,
      faqTemplateId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update FAQ question and all child questions
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} faqQuestionId FAQ Question identifier.
   * @param {FaqQuestionData} faqQuestionData
   * @param {boolean} [softValidate] True to enable soft-validation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public updateFaqQuestion(
    accountId: number,
    projectId: string,
    faqQuestionId: number,
    faqQuestionData: FaqQuestionData,
    softValidate?: boolean,
    options?: any
  ) {
    return FAQApiFp(this.configuration).updateFaqQuestion(
      accountId,
      projectId,
      faqQuestionId,
      faqQuestionData,
      softValidate,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * InferenceApi - axios parameter creator
 * @export
 */
export const InferenceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyze(
      accountId: number,
      projectId: string,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling analyze.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling analyze.'
        );
      }
      // verify required parameter 'analyzeRequestData' is not null or undefined
      if (analyzeRequestData === null || analyzeRequestData === undefined) {
        throw new RequiredError(
          'analyzeRequestData',
          'Required parameter analyzeRequestData was null or undefined when calling analyze.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/analyze`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof analyzeRequestData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(analyzeRequestData !== undefined ? analyzeRequestData : {})
        : analyzeRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVocabulary(
      accountId: number,
      projectId: string,
      checkVocabularyRequestData: Array<CheckVocabularyRequestData>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling checkVocabulary.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling checkVocabulary.'
        );
      }
      // verify required parameter 'checkVocabularyRequestData' is not null or undefined
      if (checkVocabularyRequestData === null || checkVocabularyRequestData === undefined) {
        throw new RequiredError(
          'checkVocabularyRequestData',
          'Required parameter checkVocabularyRequestData was null or undefined when calling checkVocabulary.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/check-vocabulary`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof checkVocabularyRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkVocabularyRequestData !== undefined ? checkVocabularyRequestData : {})
        : checkVocabularyRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to conform with number.
     * @param {number} number Number to conform with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    conform(accountId: number, projectId: string, text: string, number: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling conform.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling conform.'
        );
      }
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError('text', 'Required parameter text was null or undefined when calling conform.');
      }
      // verify required parameter 'number' is not null or undefined
      if (number === null || number === undefined) {
        throw new RequiredError('number', 'Required parameter number was null or undefined when calling conform.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/conform`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      if (number !== undefined) {
        localVarQueryParameter['number'] = number;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Detects language for each element of incoming collection
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DetectLanguageRequestData} detectLanguageRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectLanguage(
      accountId: number,
      projectId: string,
      detectLanguageRequestData: DetectLanguageRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling detectLanguage.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling detectLanguage.'
        );
      }
      // verify required parameter 'detectLanguageRequestData' is not null or undefined
      if (detectLanguageRequestData === null || detectLanguageRequestData === undefined) {
        throw new RequiredError(
          'detectLanguageRequestData',
          'Required parameter detectLanguageRequestData was null or undefined when calling detectLanguage.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/detectlanguage`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof detectLanguageRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(detectLanguageRequestData !== undefined ? detectLanguageRequestData : {})
        : detectLanguageRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Method for findind named entities in a given text.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entitiesLookup(
      accountId: number,
      projectId: string,
      query: string,
      showAll: boolean,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling entitiesLookup.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling entitiesLookup.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError('query', 'Required parameter query was null or undefined when calling entitiesLookup.');
      }
      // verify required parameter 'showAll' is not null or undefined
      if (showAll === null || showAll === undefined) {
        throw new RequiredError(
          'showAll',
          'Required parameter showAll was null or undefined when calling entitiesLookup.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/entities`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (showAll !== undefined) {
        localVarQueryParameter['showAll'] = showAll;
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Full inference method. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceRequestData} inferenceRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inference(
      accountId: number,
      projectId: string,
      inferenceRequestData: InferenceRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling inference.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling inference.'
        );
      }
      // verify required parameter 'inferenceRequestData' is not null or undefined
      if (inferenceRequestData === null || inferenceRequestData === undefined) {
        throw new RequiredError(
          'inferenceRequestData',
          'Required parameter inferenceRequestData was null or undefined when calling inference.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof inferenceRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(inferenceRequestData !== undefined ? inferenceRequestData : {})
        : inferenceRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inferenceMultiple(
      accountId: number,
      projectId: string,
      inferenceMultipleRequestData: InferenceMultipleRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling inferenceMultiple.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling inferenceMultiple.'
        );
      }
      // verify required parameter 'inferenceMultipleRequestData' is not null or undefined
      if (inferenceMultipleRequestData === null || inferenceMultipleRequestData === undefined) {
        throw new RequiredError(
          'inferenceMultipleRequestData',
          'Required parameter inferenceMultipleRequestData was null or undefined when calling inferenceMultiple.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference-multiple`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof inferenceMultipleRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(inferenceMultipleRequestData !== undefined ? inferenceMultipleRequestData : {})
        : inferenceMultipleRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to inflect.
     * @param {Array<string>} tags Inflect tags
     * @param {string} [dict] Version of pymorphy dictionary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inflect(
      accountId: number,
      projectId: string,
      text: string,
      tags: Array<string>,
      dict?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling inflect.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling inflect.'
        );
      }
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError('text', 'Required parameter text was null or undefined when calling inflect.');
      }
      // verify required parameter 'tags' is not null or undefined
      if (tags === null || tags === undefined) {
        throw new RequiredError('tags', 'Required parameter tags was null or undefined when calling inflect.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inflect`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dict !== undefined) {
        localVarQueryParameter['dict'] = dict;
      }

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkup(accountId: number, projectId: string, query: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling initialMarkup.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling initialMarkup.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError('query', 'Required parameter query was null or undefined when calling initialMarkup.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/markup`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates initial markup for patterns, examples and dictionaries.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody Array of texts for processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkupInternal(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling initialMarkupInternal.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling initialMarkupInternal.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling initialMarkupInternal.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/markup-internal`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Simple inference method. Do classification and slot filling with minimum input parameters.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleInference(
      accountId: number,
      projectId: string,
      query: string,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling simpleInference.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling simpleInference.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling simpleInference.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/inference`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (zTimezone !== undefined && zTimezone !== null) {
        localVarHeaderParameter['Z-timezone'] = String(zTimezone);
      }

      if (zRefTime !== undefined && zRefTime !== null) {
        localVarHeaderParameter['Z-refTime'] = String(JSON.stringify(zRefTime));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InferenceApi - functional programming interface
 * @export
 */
export const InferenceApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyze(
      accountId: number,
      projectId: string,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyzeResponseData> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).analyze(
        accountId,
        projectId,
        analyzeRequestData,
        zTimezone,
        zRefTime,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVocabulary(
      accountId: number,
      projectId: string,
      checkVocabularyRequestData: Array<CheckVocabularyRequestData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<boolean>> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).checkVocabulary(
        accountId,
        projectId,
        checkVocabularyRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to conform with number.
     * @param {number} number Number to conform with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    conform(
      accountId: number,
      projectId: string,
      text: string,
      number: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).conform(
        accountId,
        projectId,
        text,
        number,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Detects language for each element of incoming collection
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DetectLanguageRequestData} detectLanguageRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectLanguage(
      accountId: number,
      projectId: string,
      detectLanguageRequestData: DetectLanguageRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).detectLanguage(
        accountId,
        projectId,
        detectLanguageRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Method for findind named entities in a given text.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entitiesLookup(
      accountId: number,
      projectId: string,
      query: string,
      showAll: boolean,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhraseMarkupData> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).entitiesLookup(
        accountId,
        projectId,
        query,
        showAll,
        zTimezone,
        zRefTime,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Full inference method. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceRequestData} inferenceRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inference(
      accountId: number,
      projectId: string,
      inferenceRequestData: InferenceRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InferenceResultsData> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inference(
        accountId,
        projectId,
        inferenceRequestData,
        zTimezone,
        zRefTime,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inferenceMultiple(
      accountId: number,
      projectId: string,
      inferenceMultipleRequestData: InferenceMultipleRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InferenceResultsData>> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inferenceMultiple(
        accountId,
        projectId,
        inferenceMultipleRequestData,
        zTimezone,
        zRefTime,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to inflect.
     * @param {Array<string>} tags Inflect tags
     * @param {string} [dict] Version of pymorphy dictionary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inflect(
      accountId: number,
      projectId: string,
      text: string,
      tags: Array<string>,
      dict?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).inflect(
        accountId,
        projectId,
        text,
        tags,
        dict,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkup(
      accountId: number,
      projectId: string,
      query: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitialMarkupData> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).initialMarkup(
        accountId,
        projectId,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Creates initial markup for patterns, examples and dictionaries.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody Array of texts for processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkupInternal(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InitialMarkupData>> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).initialMarkupInternal(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Simple inference method. Do classification and slot filling with minimum input parameters.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleInference(
      accountId: number,
      projectId: string,
      query: string,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InferenceResultData> {
      const localVarAxiosArgs = InferenceApiAxiosParamCreator(configuration).simpleInference(
        accountId,
        projectId,
        query,
        zTimezone,
        zRefTime,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InferenceApi - factory interface
 * @export
 */
export const InferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {AnalyzeRequestData} analyzeRequestData
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyze(
      accountId: number,
      projectId: string,
      analyzeRequestData: AnalyzeRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): AxiosPromise<AnalyzeResponseData> {
      return InferenceApiFp(configuration).analyze(
        accountId,
        projectId,
        analyzeRequestData,
        zTimezone,
        zRefTime,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVocabulary(
      accountId: number,
      projectId: string,
      checkVocabularyRequestData: Array<CheckVocabularyRequestData>,
      options?: any
    ): AxiosPromise<Array<boolean>> {
      return InferenceApiFp(configuration).checkVocabulary(
        accountId,
        projectId,
        checkVocabularyRequestData,
        options
      )(axios, basePath);
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to conform with number.
     * @param {number} number Number to conform with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    conform(accountId: number, projectId: string, text: string, number: number, options?: any): AxiosPromise<string> {
      return InferenceApiFp(configuration).conform(accountId, projectId, text, number, options)(axios, basePath);
    },
    /**
     * Detects language for each element of incoming collection
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DetectLanguageRequestData} detectLanguageRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectLanguage(
      accountId: number,
      projectId: string,
      detectLanguageRequestData: DetectLanguageRequestData,
      options?: any
    ): AxiosPromise<Array<string>> {
      return InferenceApiFp(configuration).detectLanguage(
        accountId,
        projectId,
        detectLanguageRequestData,
        options
      )(axios, basePath);
    },
    /**
     * Method for findind named entities in a given text.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entitiesLookup(
      accountId: number,
      projectId: string,
      query: string,
      showAll: boolean,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options?: any
    ): AxiosPromise<PhraseMarkupData> {
      return InferenceApiFp(configuration).entitiesLookup(
        accountId,
        projectId,
        query,
        showAll,
        zTimezone,
        zRefTime,
        clientId,
        options
      )(axios, basePath);
    },
    /**
     * Full inference method. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceRequestData} inferenceRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inference(
      accountId: number,
      projectId: string,
      inferenceRequestData: InferenceRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): AxiosPromise<InferenceResultsData> {
      return InferenceApiFp(configuration).inference(
        accountId,
        projectId,
        inferenceRequestData,
        zTimezone,
        zRefTime,
        options
      )(axios, basePath);
    },
    /**
     * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inferenceMultiple(
      accountId: number,
      projectId: string,
      inferenceMultipleRequestData: InferenceMultipleRequestData,
      zTimezone?: string,
      zRefTime?: number,
      options?: any
    ): AxiosPromise<Array<InferenceResultsData>> {
      return InferenceApiFp(configuration).inferenceMultiple(
        accountId,
        projectId,
        inferenceMultipleRequestData,
        zTimezone,
        zRefTime,
        options
      )(axios, basePath);
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} text Text to inflect.
     * @param {Array<string>} tags Inflect tags
     * @param {string} [dict] Version of pymorphy dictionary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inflect(
      accountId: number,
      projectId: string,
      text: string,
      tags: Array<string>,
      dict?: string,
      options?: any
    ): AxiosPromise<string> {
      return InferenceApiFp(configuration).inflect(accountId, projectId, text, tags, dict, options)(axios, basePath);
    },
    /**
     * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkup(accountId: number, projectId: string, query: string, options?: any): AxiosPromise<InitialMarkupData> {
      return InferenceApiFp(configuration).initialMarkup(accountId, projectId, query, options)(axios, basePath);
    },
    /**
     * Creates initial markup for patterns, examples and dictionaries.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody Array of texts for processing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initialMarkupInternal(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<Array<InitialMarkupData>> {
      return InferenceApiFp(configuration).initialMarkupInternal(
        accountId,
        projectId,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     * Simple inference method. Do classification and slot filling with minimum input parameters.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query Query text
     * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
     * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
     * @param {string} [clientId] Client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleInference(
      accountId: number,
      projectId: string,
      query: string,
      zTimezone?: string,
      zRefTime?: number,
      clientId?: string,
      options?: any
    ): AxiosPromise<InferenceResultData> {
      return InferenceApiFp(configuration).simpleInference(
        accountId,
        projectId,
        query,
        zTimezone,
        zRefTime,
        clientId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * InferenceApi - object-oriented interface
 * @export
 * @class InferenceApi
 * @extends {BaseAPI}
 */
export class InferenceApi extends BaseAPI {
  /**
   * Method for finding named entities in a given text. Performs three operations at once (by operationId) - initialMarkup, entitiesLookup and inference.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {AnalyzeRequestData} analyzeRequestData
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public analyze(
    accountId: number,
    projectId: string,
    analyzeRequestData: AnalyzeRequestData,
    zTimezone?: string,
    zRefTime?: number,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).analyze(
      accountId,
      projectId,
      analyzeRequestData,
      zTimezone,
      zRefTime,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<CheckVocabularyRequestData>} checkVocabularyRequestData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public checkVocabulary(
    accountId: number,
    projectId: string,
    checkVocabularyRequestData: Array<CheckVocabularyRequestData>,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).checkVocabulary(
      accountId,
      projectId,
      checkVocabularyRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} text Text to conform with number.
   * @param {number} number Number to conform with.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public conform(accountId: number, projectId: string, text: string, number: number, options?: any) {
    return InferenceApiFp(this.configuration).conform(
      accountId,
      projectId,
      text,
      number,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Detects language for each element of incoming collection
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {DetectLanguageRequestData} detectLanguageRequestData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public detectLanguage(
    accountId: number,
    projectId: string,
    detectLanguageRequestData: DetectLanguageRequestData,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).detectLanguage(
      accountId,
      projectId,
      detectLanguageRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Method for findind named entities in a given text.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} query Query text
   * @param {boolean} showAll Don\&#39;t apply conflict resolution, return all entities that are found. Several entities for single word could be returned.
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public entitiesLookup(
    accountId: number,
    projectId: string,
    query: string,
    showAll: boolean,
    zTimezone?: string,
    zRefTime?: number,
    clientId?: string,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).entitiesLookup(
      accountId,
      projectId,
      query,
      showAll,
      zTimezone,
      zRefTime,
      clientId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Full inference method. Do classification and slot filling. You could pass additional markup here.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {InferenceRequestData} inferenceRequestData NLU inference request
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public inference(
    accountId: number,
    projectId: string,
    inferenceRequestData: InferenceRequestData,
    zTimezone?: string,
    zRefTime?: number,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).inference(
      accountId,
      projectId,
      inferenceRequestData,
      zTimezone,
      zRefTime,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Full inference method for batch processing. Do classification and slot filling. You could pass additional markup here.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {InferenceMultipleRequestData} inferenceMultipleRequestData NLU inference request
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public inferenceMultiple(
    accountId: number,
    projectId: string,
    inferenceMultipleRequestData: InferenceMultipleRequestData,
    zTimezone?: string,
    zRefTime?: number,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).inferenceMultiple(
      accountId,
      projectId,
      inferenceMultipleRequestData,
      zTimezone,
      zRefTime,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} text Text to inflect.
   * @param {Array<string>} tags Inflect tags
   * @param {string} [dict] Version of pymorphy dictionary.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public inflect(
    accountId: number,
    projectId: string,
    text: string,
    tags: Array<string>,
    dict?: string,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).inflect(
      accountId,
      projectId,
      text,
      tags,
      dict,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Creates initial markup. Markup properties (spelling correction and language) are defined in project settings.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} query Query text
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public initialMarkup(accountId: number, projectId: string, query: string, options?: any) {
    return InferenceApiFp(this.configuration).initialMarkup(
      accountId,
      projectId,
      query,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Creates initial markup for patterns, examples and dictionaries.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} requestBody Array of texts for processing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public initialMarkupInternal(accountId: number, projectId: string, requestBody: Array<string>, options?: any) {
    return InferenceApiFp(this.configuration).initialMarkupInternal(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Simple inference method. Do classification and slot filling with minimum input parameters.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} query Query text
   * @param {string} [zTimezone] Таймзона клиента. Опциональный параметр, если не передан - то будет взята таймзона проекта, если в проекте таймзона не указана, то будет взята таймзона сервера. (должна поддерживаться как Europe/Moscow, так и GMT+3(по возможности))
   * @param {number} [zRefTime] Текущее время для тестов, для обработки фразы \&quot;сейчас\&quot; и \&quot;завтра\&quot;. Формат - 2019-12-31T00:00:00+3 (тот же в котором duckling возвращает value)
   * @param {string} [clientId] Client identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceApi
   */
  public simpleInference(
    accountId: number,
    projectId: string,
    query: string,
    zTimezone?: string,
    zRefTime?: number,
    clientId?: string,
    options?: any
  ) {
    return InferenceApiFp(this.configuration).simpleInference(
      accountId,
      projectId,
      query,
      zTimezone,
      zRefTime,
      clientId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * IntentsApi - axios parameter creator
 * @export
 */
export const IntentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentData} intentData New intent
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIntent(
      accountId: number,
      projectId: string,
      intentData: IntentData,
      logLabelingSessionId?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createIntent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createIntent.'
        );
      }
      // verify required parameter 'intentData' is not null or undefined
      if (intentData === null || intentData === undefined) {
        throw new RequiredError(
          'intentData',
          'Required parameter intentData was null or undefined when calling createIntent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof intentData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(intentData !== undefined ? intentData : {})
        : intentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of intents ID\&#39;s.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntents(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      logLabelingSessionId?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteIntents.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/delete-multiple`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of intents paths.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMultipleByPaths(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteMultipleByPaths.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteMultipleByPaths.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deleteMultipleByPaths.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/delete-multiple-by-paths`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all project intents
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportIntents(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling exportIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling exportIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/export-file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntent(
      accountId: number,
      projectId: string,
      intentId: number,
      logLabelingSessionId?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getIntent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getIntent.'
        );
      }
      // verify required parameter 'intentId' is not null or undefined
      if (intentId === null || intentId === undefined) {
        throw new RequiredError(
          'intentId',
          'Required parameter intentId was null or undefined when calling getIntent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [paths]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntentsByPaths(accountId: number, projectId: string, paths?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getIntentsByPaths.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getIntentsByPaths.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/get-by-paths`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (paths) {
        localVarQueryParameter['paths'] = paths;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import intents from wile with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importIntents(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importIntents.'
        );
      }
      // verify required parameter 'strategy' is not null or undefined
      if (strategy === null || strategy === undefined) {
        throw new RequiredError(
          'strategy',
          'Required parameter strategy was null or undefined when calling importIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/import`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (strategy !== undefined) {
        localVarQueryParameter['strategy'] = strategy;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntents(accountId: number, projectId: string, logLabelingSessionId?: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntentsFullData(
      accountId: number,
      projectId: string,
      logLabelingSessionId?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listIntentsFullData.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listIntentsFullData.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents-full`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveDelete(accountId: number, projectId: string, intentId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling recursiveDelete.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling recursiveDelete.'
        );
      }
      // verify required parameter 'intentId' is not null or undefined
      if (intentId === null || intentId === undefined) {
        throw new RequiredError(
          'intentId',
          'Required parameter intentId was null or undefined when calling recursiveDelete.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}/recursive-delete`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {IntentData} intentData Intent body
     * @param {number} [logLabelingSessionId]
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdate(
      accountId: number,
      projectId: string,
      intentId: number,
      intentData: IntentData,
      logLabelingSessionId?: number,
      softValidate?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling recursiveUpdate.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling recursiveUpdate.'
        );
      }
      // verify required parameter 'intentId' is not null or undefined
      if (intentId === null || intentId === undefined) {
        throw new RequiredError(
          'intentId',
          'Required parameter intentId was null or undefined when calling recursiveUpdate.'
        );
      }
      // verify required parameter 'intentData' is not null or undefined
      if (intentData === null || intentData === undefined) {
        throw new RequiredError(
          'intentData',
          'Required parameter intentData was null or undefined when calling recursiveUpdate.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/{intentId}/recursive-update`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'intentId'}}`, encodeURIComponent(String(intentId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      if (softValidate !== undefined) {
        localVarQueryParameter['softValidate'] = softValidate;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof intentData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(intentData !== undefined ? intentData : {})
        : intentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update multiple intents and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdateMultipleIntents(
      accountId: number,
      projectId: string,
      multipleRecursiveUpdateIntentData: Array<MultipleRecursiveUpdateIntentData>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling recursiveUpdateMultipleIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling recursiveUpdateMultipleIntents.'
        );
      }
      // verify required parameter 'multipleRecursiveUpdateIntentData' is not null or undefined
      if (multipleRecursiveUpdateIntentData === null || multipleRecursiveUpdateIntentData === undefined) {
        throw new RequiredError(
          'multipleRecursiveUpdateIntentData',
          'Required parameter multipleRecursiveUpdateIntentData was null or undefined when calling recursiveUpdateMultipleIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/multiple-recursive-update`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof multipleRecursiveUpdateIntentData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(multipleRecursiveUpdateIntentData !== undefined ? multipleRecursiveUpdateIntentData : {})
        : multipleRecursiveUpdateIntentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchIntents(
      accountId: number,
      projectId: string,
      intentsSearchCriteria: IntentsSearchCriteria,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling searchIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling searchIntents.'
        );
      }
      // verify required parameter 'intentsSearchCriteria' is not null or undefined
      if (intentsSearchCriteria === null || intentsSearchCriteria === undefined) {
        throw new RequiredError(
          'intentsSearchCriteria',
          'Required parameter intentsSearchCriteria was null or undefined when calling searchIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/intents/search`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof intentsSearchCriteria !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(intentsSearchCriteria !== undefined ? intentsSearchCriteria : {})
        : intentsSearchCriteria || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IntentsApi - functional programming interface
 * @export
 */
export const IntentsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentData} intentData New intent
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIntent(
      accountId: number,
      projectId: string,
      intentData: IntentData,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentData> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).createIntent(
        accountId,
        projectId,
        intentData,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of intents ID\&#39;s.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntents(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).deleteIntents(
        accountId,
        projectId,
        requestBody,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of intents paths.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMultipleByPaths(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).deleteMultipleByPaths(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export all project intents
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportIntents(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).exportIntents(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntent(
      accountId: number,
      projectId: string,
      intentId: number,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentData> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).getIntent(
        accountId,
        projectId,
        intentId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Filter project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [paths]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntentsByPaths(
      accountId: number,
      projectId: string,
      paths?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).getIntentsByPaths(
        accountId,
        projectId,
        paths,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Import intents from wile with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importIntents(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentSummaryData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).importIntents(
        accountId,
        projectId,
        strategy,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntents(
      accountId: number,
      projectId: string,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentSummaryData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).listIntents(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntentsFullData(
      accountId: number,
      projectId: string,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).listIntentsFullData(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveDelete(
      accountId: number,
      projectId: string,
      intentId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveDelete(
        accountId,
        projectId,
        intentId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {IntentData} intentData Intent body
     * @param {number} [logLabelingSessionId]
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdate(
      accountId: number,
      projectId: string,
      intentId: number,
      intentData: IntentData,
      logLabelingSessionId?: number,
      softValidate?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentRecursiveUpdateData> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveUpdate(
        accountId,
        projectId,
        intentId,
        intentData,
        logLabelingSessionId,
        softValidate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update multiple intents and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdateMultipleIntents(
      accountId: number,
      projectId: string,
      multipleRecursiveUpdateIntentData: Array<MultipleRecursiveUpdateIntentData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentRecursiveUpdateData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).recursiveUpdateMultipleIntents(
        accountId,
        projectId,
        multipleRecursiveUpdateIntentData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchIntents(
      accountId: number,
      projectId: string,
      intentsSearchCriteria: IntentsSearchCriteria,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentSummaryData>> {
      const localVarAxiosArgs = IntentsApiAxiosParamCreator(configuration).searchIntents(
        accountId,
        projectId,
        intentsSearchCriteria,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * IntentsApi - factory interface
 * @export
 */
export const IntentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentData} intentData New intent
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIntent(
      accountId: number,
      projectId: string,
      intentData: IntentData,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<IntentData> {
      return IntentsApiFp(configuration).createIntent(
        accountId,
        projectId,
        intentData,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<number>} requestBody List of intents ID\&#39;s.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntents(
      accountId: number,
      projectId: string,
      requestBody: Array<number>,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<void> {
      return IntentsApiFp(configuration).deleteIntents(
        accountId,
        projectId,
        requestBody,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete a list of intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody List of intents paths.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMultipleByPaths(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return IntentsApiFp(configuration).deleteMultipleByPaths(
        accountId,
        projectId,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Export all project intents
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportIntents(accountId: number, projectId: string, options?: any): AxiosPromise<any> {
      return IntentsApiFp(configuration).exportIntents(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get single intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntent(
      accountId: number,
      projectId: string,
      intentId: number,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<IntentData> {
      return IntentsApiFp(configuration).getIntent(
        accountId,
        projectId,
        intentId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Filter project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} [paths]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIntentsByPaths(
      accountId: number,
      projectId: string,
      paths?: Array<string>,
      options?: any
    ): AxiosPromise<Array<IntentData>> {
      return IntentsApiFp(configuration).getIntentsByPaths(accountId, projectId, paths, options)(axios, basePath);
    },
    /**
     *
     * @summary Import intents from wile with specified strategy.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ImportFileStrategy} strategy
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importIntents(
      accountId: number,
      projectId: string,
      strategy: ImportFileStrategy,
      file?: any,
      options?: any
    ): AxiosPromise<Array<IntentSummaryData>> {
      return IntentsApiFp(configuration).importIntents(accountId, projectId, strategy, file, options)(axios, basePath);
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntents(
      accountId: number,
      projectId: string,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<Array<IntentSummaryData>> {
      return IntentsApiFp(configuration).listIntents(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary List project intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntentsFullData(
      accountId: number,
      projectId: string,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<Array<IntentData>> {
      return IntentsApiFp(configuration).listIntentsFullData(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveDelete(accountId: number, projectId: string, intentId: number, options?: any): AxiosPromise<void> {
      return IntentsApiFp(configuration).recursiveDelete(accountId, projectId, intentId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update intent and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} intentId Intent identifier.
     * @param {IntentData} intentData Intent body
     * @param {number} [logLabelingSessionId]
     * @param {boolean} [softValidate] True to enable soft-validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdate(
      accountId: number,
      projectId: string,
      intentId: number,
      intentData: IntentData,
      logLabelingSessionId?: number,
      softValidate?: boolean,
      options?: any
    ): AxiosPromise<IntentRecursiveUpdateData> {
      return IntentsApiFp(configuration).recursiveUpdate(
        accountId,
        projectId,
        intentId,
        intentData,
        logLabelingSessionId,
        softValidate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update multiple intents and all its children intents.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recursiveUpdateMultipleIntents(
      accountId: number,
      projectId: string,
      multipleRecursiveUpdateIntentData: Array<MultipleRecursiveUpdateIntentData>,
      options?: any
    ): AxiosPromise<Array<IntentRecursiveUpdateData>> {
      return IntentsApiFp(configuration).recursiveUpdateMultipleIntents(
        accountId,
        projectId,
        multipleRecursiveUpdateIntentData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchIntents(
      accountId: number,
      projectId: string,
      intentsSearchCriteria: IntentsSearchCriteria,
      options?: any
    ): AxiosPromise<Array<IntentSummaryData>> {
      return IntentsApiFp(configuration).searchIntents(
        accountId,
        projectId,
        intentsSearchCriteria,
        options
      )(axios, basePath);
    },
  };
};

/**
 * IntentsApi - object-oriented interface
 * @export
 * @class IntentsApi
 * @extends {BaseAPI}
 */
export class IntentsApi extends BaseAPI {
  /**
   *
   * @summary Create a single intent.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {IntentData} intentData New intent
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public createIntent(
    accountId: number,
    projectId: string,
    intentData: IntentData,
    logLabelingSessionId?: number,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).createIntent(
      accountId,
      projectId,
      intentData,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a list of intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<number>} requestBody List of intents ID\&#39;s.
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public deleteIntents(
    accountId: number,
    projectId: string,
    requestBody: Array<number>,
    logLabelingSessionId?: number,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).deleteIntents(
      accountId,
      projectId,
      requestBody,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a list of intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} requestBody List of intents paths.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public deleteMultipleByPaths(accountId: number, projectId: string, requestBody: Array<string>, options?: any) {
    return IntentsApiFp(this.configuration).deleteMultipleByPaths(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export all project intents
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public exportIntents(accountId: number, projectId: string, options?: any) {
    return IntentsApiFp(this.configuration).exportIntents(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get single intent.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} intentId Intent identifier.
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public getIntent(
    accountId: number,
    projectId: string,
    intentId: number,
    logLabelingSessionId?: number,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).getIntent(
      accountId,
      projectId,
      intentId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Filter project intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} [paths]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public getIntentsByPaths(accountId: number, projectId: string, paths?: Array<string>, options?: any) {
    return IntentsApiFp(this.configuration).getIntentsByPaths(
      accountId,
      projectId,
      paths,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Import intents from wile with specified strategy.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ImportFileStrategy} strategy
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public importIntents(accountId: number, projectId: string, strategy: ImportFileStrategy, file?: any, options?: any) {
    return IntentsApiFp(this.configuration).importIntents(
      accountId,
      projectId,
      strategy,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List project intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public listIntents(accountId: number, projectId: string, logLabelingSessionId?: number, options?: any) {
    return IntentsApiFp(this.configuration).listIntents(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List project intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public listIntentsFullData(accountId: number, projectId: string, logLabelingSessionId?: number, options?: any) {
    return IntentsApiFp(this.configuration).listIntentsFullData(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete intent and all its children intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} intentId Intent identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public recursiveDelete(accountId: number, projectId: string, intentId: number, options?: any) {
    return IntentsApiFp(this.configuration).recursiveDelete(
      accountId,
      projectId,
      intentId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update intent and all its children intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} intentId Intent identifier.
   * @param {IntentData} intentData Intent body
   * @param {number} [logLabelingSessionId]
   * @param {boolean} [softValidate] True to enable soft-validation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public recursiveUpdate(
    accountId: number,
    projectId: string,
    intentId: number,
    intentData: IntentData,
    logLabelingSessionId?: number,
    softValidate?: boolean,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).recursiveUpdate(
      accountId,
      projectId,
      intentId,
      intentData,
      logLabelingSessionId,
      softValidate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update multiple intents and all its children intents.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<MultipleRecursiveUpdateIntentData>} multipleRecursiveUpdateIntentData List of intent bodies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public recursiveUpdateMultipleIntents(
    accountId: number,
    projectId: string,
    multipleRecursiveUpdateIntentData: Array<MultipleRecursiveUpdateIntentData>,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).recursiveUpdateMultipleIntents(
      accountId,
      projectId,
      multipleRecursiveUpdateIntentData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Ignore-case search for entities by path, description, answer, phrases, slots(all fields). Results are combined by each search criteria using OR.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {IntentsSearchCriteria} intentsSearchCriteria Search criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntentsApi
   */
  public searchIntents(
    accountId: number,
    projectId: string,
    intentsSearchCriteria: IntentsSearchCriteria,
    options?: any
  ) {
    return IntentsApiFp(this.configuration).searchIntents(
      accountId,
      projectId,
      intentsSearchCriteria,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * LogLabelingApi - axios parameter creator
 * @export
 */
export const LogLabelingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Apply staged changes for log-labeling session
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyStagedChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling applyStagedChanges.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling applyStagedChanges.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling applyStagedChanges.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/apply-staged-changes`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanPhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      phrasesCleaningRequestData: PhrasesCleaningRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling cleanPhrases.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling cleanPhrases.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling cleanPhrases.'
        );
      }
      // verify required parameter 'phrasesCleaningRequestData' is not null or undefined
      if (phrasesCleaningRequestData === null || phrasesCleaningRequestData === undefined) {
        throw new RequiredError(
          'phrasesCleaningRequestData',
          'Required parameter phrasesCleaningRequestData was null or undefined when calling cleanPhrases.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/clean-phrases`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof phrasesCleaningRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(phrasesCleaningRequestData !== undefined ? phrasesCleaningRequestData : {})
        : phrasesCleaningRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a log labeling session from file with phrases.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSession(accountId: number, projectId: string, file?: any, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createLogLabelingSession.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createLogLabelingSession.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a log labeling session from chat history.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSessionFromHistory(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createLogLabelingSessionFromHistory.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createLogLabelingSessionFromHistory.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/from-history`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteGroupingData.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteGroupingData.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling deleteGroupingData.'
        );
      }
      // verify required parameter 'logLabelingGroupingId' is not null or undefined
      if (logLabelingGroupingId === null || logLabelingGroupingId === undefined) {
        throw new RequiredError(
          'logLabelingGroupingId',
          'Required parameter logLabelingGroupingId was null or undefined when calling deleteGroupingData.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group/{logLabelingGroupingId}`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)))
          .replace(`{${'logLabelingGroupingId'}}`, encodeURIComponent(String(logLabelingGroupingId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteLogLabelingSession.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteLogLabelingSession.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling deleteLogLabelingSession.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete specified phrases (mark them as deleted).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deletePhrases.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deletePhrases.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling deletePhrases.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deletePhrases.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/delete`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStagingIntentPhrases(
      accountId: number,
      projectId: string,
      deleteStagingIntentPhrasesRequest: DeleteStagingIntentPhrasesRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteStagingIntentPhrases.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteStagingIntentPhrases.'
        );
      }
      // verify required parameter 'deleteStagingIntentPhrasesRequest' is not null or undefined
      if (deleteStagingIntentPhrasesRequest === null || deleteStagingIntentPhrasesRequest === undefined) {
        throw new RequiredError(
          'deleteStagingIntentPhrasesRequest',
          'Required parameter deleteStagingIntentPhrasesRequest was null or undefined when calling deleteStagingIntentPhrases.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intent/phrase`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof deleteStagingIntentPhrasesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deleteStagingIntentPhrasesRequest !== undefined ? deleteStagingIntentPhrasesRequest : {})
        : deleteStagingIntentPhrasesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Discard changes to phrases (unmove them from intent and mark as pending).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling discardChanges.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling discardChanges.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling discardChanges.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling discardChanges.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/discard-changes`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start grouping
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {GroupingRequestData} groupingRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeGrouping(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      groupingRequestData: GroupingRequestData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling executeGrouping.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling executeGrouping.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling executeGrouping.'
        );
      }
      // verify required parameter 'groupingRequestData' is not null or undefined
      if (groupingRequestData === null || groupingRequestData === undefined) {
        throw new RequiredError(
          'groupingRequestData',
          'Required parameter groupingRequestData was null or undefined when calling executeGrouping.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof groupingRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(groupingRequestData !== undefined ? groupingRequestData : {})
        : groupingRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getGroupingData.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getGroupingData.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling getGroupingData.'
        );
      }
      // verify required parameter 'logLabelingGroupingId' is not null or undefined
      if (logLabelingGroupingId === null || logLabelingGroupingId === undefined) {
        throw new RequiredError(
          'logLabelingGroupingId',
          'Required parameter logLabelingGroupingId was null or undefined when calling getGroupingData.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group/{logLabelingGroupingId}`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)))
          .replace(`{${'logLabelingGroupingId'}}`, encodeURIComponent(String(logLabelingGroupingId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a single log labeling session by id.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLogLabelingSession.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getLogLabelingSession.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling getLogLabelingSession.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all phrases in this log-labeling session.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrases(accountId: number, projectId: string, logLabelingSessionId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPhrases.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getPhrases.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling getPhrases.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get remove/accepted statuses of phrases
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrasesStatus(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPhrasesStatus.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getPhrasesStatus.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling getPhrasesStatus.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/status`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagingIntents(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getStagingIntents.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getStagingIntents.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intents`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get group list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGrouping(accountId: number, projectId: string, logLabelingSessionId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listGrouping.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listGrouping.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling listGrouping.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/group`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of all log labeling sessions.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLogLabelingSession(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listLogLabelingSession.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listLogLabelingSession.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStagingIntentPhrases(
      accountId: number,
      projectId: string,
      moveStagingIntentPhrasesRequest: MoveStagingIntentPhrasesRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling moveStagingIntentPhrases.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling moveStagingIntentPhrases.'
        );
      }
      // verify required parameter 'moveStagingIntentPhrasesRequest' is not null or undefined
      if (moveStagingIntentPhrasesRequest === null || moveStagingIntentPhrasesRequest === undefined) {
        throw new RequiredError(
          'moveStagingIntentPhrasesRequest',
          'Required parameter moveStagingIntentPhrasesRequest was null or undefined when calling moveStagingIntentPhrases.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling/staging/intent/phrase/move`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof moveStagingIntentPhrasesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(moveStagingIntentPhrasesRequest !== undefined ? moveStagingIntentPhrasesRequest : {})
        : moveStagingIntentPhrasesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Move specified phrases into intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentData} moveToIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntent(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentData: MoveToIntentData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling moveToIntent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling moveToIntent.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling moveToIntent.'
        );
      }
      // verify required parameter 'moveToIntentData' is not null or undefined
      if (moveToIntentData === null || moveToIntentData === undefined) {
        throw new RequiredError(
          'moveToIntentData',
          'Required parameter moveToIntentData was null or undefined when calling moveToIntent.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/moveToIntent`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof moveToIntentData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(moveToIntentData !== undefined ? moveToIntentData : {})
        : moveToIntentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Move results of grouping to intents (works only for classification-based grouping).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentMultipleData} moveToIntentMultipleData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntentMultiple(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentMultipleData: MoveToIntentMultipleData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling moveToIntentMultiple.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling moveToIntentMultiple.'
        );
      }
      // verify required parameter 'logLabelingSessionId' is not null or undefined
      if (logLabelingSessionId === null || logLabelingSessionId === undefined) {
        throw new RequiredError(
          'logLabelingSessionId',
          'Required parameter logLabelingSessionId was null or undefined when calling moveToIntentMultiple.'
        );
      }
      // verify required parameter 'moveToIntentMultipleData' is not null or undefined
      if (moveToIntentMultipleData === null || moveToIntentMultipleData === undefined) {
        throw new RequiredError(
          'moveToIntentMultipleData',
          'Required parameter moveToIntentMultipleData was null or undefined when calling moveToIntentMultiple.'
        );
      }
      const localVarPath =
        `/api/caila/accounts/{accountId}/projects/{projectId}/log-labeling-session/{logLabelingSessionId}/phrases/moveToIntent-multiple`
          .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
          .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
          .replace(`{${'logLabelingSessionId'}}`, encodeURIComponent(String(logLabelingSessionId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof moveToIntentMultipleData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(moveToIntentMultipleData !== undefined ? moveToIntentMultipleData : {})
        : moveToIntentMultipleData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogLabelingApi - functional programming interface
 * @export
 */
export const LogLabelingApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Apply staged changes for log-labeling session
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyStagedChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentSummaryData>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).applyStagedChanges(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanPhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      phrasesCleaningRequestData: PhrasesCleaningRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhrasesCleaningResponse> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).cleanPhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        phrasesCleaningRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Create a log labeling session from file with phrases.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSession(
      accountId: number,
      projectId: string,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedLogLabelingSessionData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).createLogLabelingSession(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Create a log labeling session from chat history.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSessionFromHistory(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelingSessionData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).createLogLabelingSessionFromHistory(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteGroupingData(
        accountId,
        projectId,
        logLabelingSessionId,
        logLabelingGroupingId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteLogLabelingSession(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete specified phrases (mark them as deleted).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deletePhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStagingIntentPhrases(
      accountId: number,
      projectId: string,
      deleteStagingIntentPhrasesRequest: DeleteStagingIntentPhrasesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StagingIntentData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).deleteStagingIntentPhrases(
        accountId,
        projectId,
        deleteStagingIntentPhrasesRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Discard changes to phrases (unmove them from intent and mark as pending).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).discardChanges(
        accountId,
        projectId,
        logLabelingSessionId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Start grouping
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {GroupingRequestData} groupingRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeGrouping(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      groupingRequestData: GroupingRequestData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupingSummaryData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).executeGrouping(
        accountId,
        projectId,
        logLabelingSessionId,
        groupingRequestData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogGroupingData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getGroupingData(
        accountId,
        projectId,
        logLabelingSessionId,
        logLabelingGroupingId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get a single log labeling session by id.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelingSessionData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getLogLabelingSession(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get all phrases in this log-labeling session.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getPhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get remove/accepted statuses of phrases
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrasesStatus(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PhraseStatusData>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getPhrasesStatus(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagingIntents(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StagingIntentData>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).getStagingIntents(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get group list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGrouping(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupingSummaryData>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).listGrouping(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get a list of all log labeling sessions.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLogLabelingSession(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogLabelingSessionData>> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).listLogLabelingSession(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStagingIntentPhrases(
      accountId: number,
      projectId: string,
      moveStagingIntentPhrasesRequest: MoveStagingIntentPhrasesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StagingIntentData> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveStagingIntentPhrases(
        accountId,
        projectId,
        moveStagingIntentPhrasesRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Move specified phrases into intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentData} moveToIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntent(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentData: MoveToIntentData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveToIntent(
        accountId,
        projectId,
        logLabelingSessionId,
        moveToIntentData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Move results of grouping to intents (works only for classification-based grouping).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentMultipleData} moveToIntentMultipleData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntentMultiple(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentMultipleData: MoveToIntentMultipleData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogLabelingApiAxiosParamCreator(configuration).moveToIntentMultiple(
        accountId,
        projectId,
        logLabelingSessionId,
        moveToIntentMultipleData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LogLabelingApi - factory interface
 * @export
 */
export const LogLabelingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Apply staged changes for log-labeling session
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyStagedChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<Array<IntentSummaryData>> {
      return LogLabelingApiFp(configuration).applyStagedChanges(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanPhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      phrasesCleaningRequestData: PhrasesCleaningRequestData,
      options?: any
    ): AxiosPromise<PhrasesCleaningResponse> {
      return LogLabelingApiFp(configuration).cleanPhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        phrasesCleaningRequestData,
        options
      )(axios, basePath);
    },
    /**
     * Create a log labeling session from file with phrases.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSession(
      accountId: number,
      projectId: string,
      file?: any,
      options?: any
    ): AxiosPromise<CreatedLogLabelingSessionData> {
      return LogLabelingApiFp(configuration).createLogLabelingSession(
        accountId,
        projectId,
        file,
        options
      )(axios, basePath);
    },
    /**
     * Create a log labeling session from chat history.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogLabelingSessionFromHistory(
      accountId: number,
      projectId: string,
      options?: any
    ): AxiosPromise<LogLabelingSessionData> {
      return LogLabelingApiFp(configuration).createLogLabelingSessionFromHistory(
        accountId,
        projectId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).deleteGroupingData(
        accountId,
        projectId,
        logLabelingSessionId,
        logLabelingGroupingId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).deleteLogLabelingSession(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     * Delete specified phrases (mark them as deleted).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).deletePhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStagingIntentPhrases(
      accountId: number,
      projectId: string,
      deleteStagingIntentPhrasesRequest: DeleteStagingIntentPhrasesRequest,
      options?: any
    ): AxiosPromise<StagingIntentData> {
      return LogLabelingApiFp(configuration).deleteStagingIntentPhrases(
        accountId,
        projectId,
        deleteStagingIntentPhrasesRequest,
        options
      )(axios, basePath);
    },
    /**
     * Discard changes to phrases (unmove them from intent and mark as pending).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      requestBody: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).discardChanges(
        accountId,
        projectId,
        logLabelingSessionId,
        requestBody,
        options
      )(axios, basePath);
    },
    /**
     * Start grouping
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {GroupingRequestData} groupingRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeGrouping(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      groupingRequestData: GroupingRequestData,
      options?: any
    ): AxiosPromise<GroupingSummaryData> {
      return LogLabelingApiFp(configuration).executeGrouping(
        accountId,
        projectId,
        logLabelingSessionId,
        groupingRequestData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {number} logLabelingGroupingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingData(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      logLabelingGroupingId: number,
      options?: any
    ): AxiosPromise<LogGroupingData> {
      return LogLabelingApiFp(configuration).getGroupingData(
        accountId,
        projectId,
        logLabelingSessionId,
        logLabelingGroupingId,
        options
      )(axios, basePath);
    },
    /**
     * Get a single log labeling session by id.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogLabelingSession(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<LogLabelingSessionData> {
      return LogLabelingApiFp(configuration).getLogLabelingSession(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     * Get all phrases in this log-labeling session.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrases(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<Array<string>> {
      return LogLabelingApiFp(configuration).getPhrases(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     * Get remove/accepted statuses of phrases
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhrasesStatus(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<Array<PhraseStatusData>> {
      return LogLabelingApiFp(configuration).getPhrasesStatus(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagingIntents(accountId: number, projectId: string, options?: any): AxiosPromise<Array<StagingIntentData>> {
      return LogLabelingApiFp(configuration).getStagingIntents(accountId, projectId, options)(axios, basePath);
    },
    /**
     * Get group list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGrouping(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      options?: any
    ): AxiosPromise<Array<GroupingSummaryData>> {
      return LogLabelingApiFp(configuration).listGrouping(
        accountId,
        projectId,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
    /**
     * Get a list of all log labeling sessions.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLogLabelingSession(
      accountId: number,
      projectId: string,
      options?: any
    ): AxiosPromise<Array<LogLabelingSessionData>> {
      return LogLabelingApiFp(configuration).listLogLabelingSession(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStagingIntentPhrases(
      accountId: number,
      projectId: string,
      moveStagingIntentPhrasesRequest: MoveStagingIntentPhrasesRequest,
      options?: any
    ): AxiosPromise<StagingIntentData> {
      return LogLabelingApiFp(configuration).moveStagingIntentPhrases(
        accountId,
        projectId,
        moveStagingIntentPhrasesRequest,
        options
      )(axios, basePath);
    },
    /**
     * Move specified phrases into intent.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentData} moveToIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntent(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentData: MoveToIntentData,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).moveToIntent(
        accountId,
        projectId,
        logLabelingSessionId,
        moveToIntentData,
        options
      )(axios, basePath);
    },
    /**
     * Move results of grouping to intents (works only for classification-based grouping).
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} logLabelingSessionId
     * @param {MoveToIntentMultipleData} moveToIntentMultipleData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveToIntentMultiple(
      accountId: number,
      projectId: string,
      logLabelingSessionId: number,
      moveToIntentMultipleData: MoveToIntentMultipleData,
      options?: any
    ): AxiosPromise<void> {
      return LogLabelingApiFp(configuration).moveToIntentMultiple(
        accountId,
        projectId,
        logLabelingSessionId,
        moveToIntentMultipleData,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LogLabelingApi - object-oriented interface
 * @export
 * @class LogLabelingApi
 * @extends {BaseAPI}
 */
export class LogLabelingApi extends BaseAPI {
  /**
   * Apply staged changes for log-labeling session
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public applyStagedChanges(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).applyStagedChanges(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {PhrasesCleaningRequestData} phrasesCleaningRequestData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public cleanPhrases(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    phrasesCleaningRequestData: PhrasesCleaningRequestData,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).cleanPhrases(
      accountId,
      projectId,
      logLabelingSessionId,
      phrasesCleaningRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Create a log labeling session from file with phrases.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public createLogLabelingSession(accountId: number, projectId: string, file?: any, options?: any) {
    return LogLabelingApiFp(this.configuration).createLogLabelingSession(
      accountId,
      projectId,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Create a log labeling session from chat history.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public createLogLabelingSessionFromHistory(accountId: number, projectId: string, options?: any) {
    return LogLabelingApiFp(this.configuration).createLogLabelingSessionFromHistory(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {number} logLabelingGroupingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public deleteGroupingData(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    logLabelingGroupingId: number,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).deleteGroupingData(
      accountId,
      projectId,
      logLabelingSessionId,
      logLabelingGroupingId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public deleteLogLabelingSession(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).deleteLogLabelingSession(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Delete specified phrases (mark them as deleted).
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public deletePhrases(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    requestBody: Array<number>,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).deletePhrases(
      accountId,
      projectId,
      logLabelingSessionId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {DeleteStagingIntentPhrasesRequest} deleteStagingIntentPhrasesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public deleteStagingIntentPhrases(
    accountId: number,
    projectId: string,
    deleteStagingIntentPhrasesRequest: DeleteStagingIntentPhrasesRequest,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).deleteStagingIntentPhrases(
      accountId,
      projectId,
      deleteStagingIntentPhrasesRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Discard changes to phrases (unmove them from intent and mark as pending).
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public discardChanges(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    requestBody: Array<number>,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).discardChanges(
      accountId,
      projectId,
      logLabelingSessionId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Start grouping
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {GroupingRequestData} groupingRequestData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public executeGrouping(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    groupingRequestData: GroupingRequestData,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).executeGrouping(
      accountId,
      projectId,
      logLabelingSessionId,
      groupingRequestData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {number} logLabelingGroupingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public getGroupingData(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    logLabelingGroupingId: number,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).getGroupingData(
      accountId,
      projectId,
      logLabelingSessionId,
      logLabelingGroupingId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get a single log labeling session by id.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public getLogLabelingSession(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).getLogLabelingSession(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get all phrases in this log-labeling session.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public getPhrases(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).getPhrases(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get remove/accepted statuses of phrases
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public getPhrasesStatus(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).getPhrasesStatus(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public getStagingIntents(accountId: number, projectId: string, options?: any) {
    return LogLabelingApiFp(this.configuration).getStagingIntents(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get group list
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public listGrouping(accountId: number, projectId: string, logLabelingSessionId: number, options?: any) {
    return LogLabelingApiFp(this.configuration).listGrouping(
      accountId,
      projectId,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get a list of all log labeling sessions.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public listLogLabelingSession(accountId: number, projectId: string, options?: any) {
    return LogLabelingApiFp(this.configuration).listLogLabelingSession(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {MoveStagingIntentPhrasesRequest} moveStagingIntentPhrasesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public moveStagingIntentPhrases(
    accountId: number,
    projectId: string,
    moveStagingIntentPhrasesRequest: MoveStagingIntentPhrasesRequest,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).moveStagingIntentPhrases(
      accountId,
      projectId,
      moveStagingIntentPhrasesRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Move specified phrases into intent.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {MoveToIntentData} moveToIntentData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public moveToIntent(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    moveToIntentData: MoveToIntentData,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).moveToIntent(
      accountId,
      projectId,
      logLabelingSessionId,
      moveToIntentData,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Move results of grouping to intents (works only for classification-based grouping).
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} logLabelingSessionId
   * @param {MoveToIntentMultipleData} moveToIntentMultipleData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogLabelingApi
   */
  public moveToIntentMultiple(
    accountId: number,
    projectId: string,
    logLabelingSessionId: number,
    moveToIntentMultipleData: MoveToIntentMultipleData,
    options?: any
  ) {
    return LogLabelingApiFp(this.configuration).moveToIntentMultiple(
      accountId,
      projectId,
      logLabelingSessionId,
      moveToIntentMultipleData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessKey(
      accountId: number,
      projectId: string,
      directAccessKeyData?: DirectAccessKeyData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createAccessKey.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createAccessKey.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/createAccessKey`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof directAccessKeyData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(directAccessKeyData !== undefined ? directAccessKeyData : {})
        : directAccessKeyData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(accountId: number, createProjectData: CreateProjectData, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createProject.'
        );
      }
      // verify required parameter 'createProjectData' is not null or undefined
      if (createProjectData === null || createProjectData === undefined) {
        throw new RequiredError(
          'createProjectData',
          'Required parameter createProjectData was null or undefined when calling createProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createProjectData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createProjectData !== undefined ? createProjectData : {})
        : createProjectData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary deactivates shared project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateSharedProject(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deactivateSharedProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deactivateSharedProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/deactivate-shared-project`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessKey(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteAccessKey.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteAccessKey.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/deleteAccessKey`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete project
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(projectId: string, accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteProject.'
        );
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Evicts project resources (models are removed from cache and external services)
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evictProjectResources(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling evictProjectResources.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling evictProjectResources.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/evict-project-resources`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportProject(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling exportProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling exportProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/export`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientEntitiesIncluded !== undefined) {
        localVarQueryParameter['clientEntitiesIncluded'] = clientEntitiesIncluded;
      }

      if (spellerDictionaryIncluded !== undefined) {
        localVarQueryParameter['spellerDictionaryIncluded'] = spellerDictionaryIncluded;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessKey(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccessKey.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getAccessKey.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/getAccessKey`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get amount of entries in all client entities of the project where clientId != null
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientEntitiesTotalSize(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getClientEntitiesTotalSize.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getClientEntitiesTotalSize.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/stats/count-client-entities-entries`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project last change (change in intents/entities/project settings) timestamp.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectLastChangeDate(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectLastChangeDate.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProjectLastChangeDate.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/status/last-change`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get amount of entries in speller dictionary
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionarySize(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSpellerDictionarySize.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getSpellerDictionarySize.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/stats/count-speller-dictionary-entries`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import caila project from json
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCaila(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importCaila.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importCaila.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/importFile`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (clientEntitiesIncluded !== undefined) {
        localVarQueryParameter['clientEntitiesIncluded'] = clientEntitiesIncluded;
      }

      if (spellerDictionaryIncluded !== undefined) {
        localVarQueryParameter['spellerDictionaryIncluded'] = spellerDictionaryIncluded;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectExportImportData} projectExportImportData
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importProject(
      accountId: number,
      projectId: string,
      projectExportImportData: ProjectExportImportData,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importProject.'
        );
      }
      // verify required parameter 'projectExportImportData' is not null or undefined
      if (projectExportImportData === null || projectExportImportData === undefined) {
        throw new RequiredError(
          'projectExportImportData',
          'Required parameter projectExportImportData was null or undefined when calling importProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/import`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientEntitiesIncluded !== undefined) {
        localVarQueryParameter['clientEntitiesIncluded'] = clientEntitiesIncluded;
      }

      if (spellerDictionaryIncluded !== undefined) {
        localVarQueryParameter['spellerDictionaryIncluded'] = spellerDictionaryIncluded;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof projectExportImportData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(projectExportImportData !== undefined ? projectExportImportData : {})
        : projectExportImportData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(accountId: number, page?: number, size?: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listProjects.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(projectId: string, accountId: number, projectData: ProjectData, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateProject.'
        );
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateProject.'
        );
      }
      // verify required parameter 'projectData' is not null or undefined
      if (projectData === null || projectData === undefined) {
        throw new RequiredError(
          'projectData',
          'Required parameter projectData was null or undefined when calling updateProject.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof projectData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(projectData !== undefined ? projectData : {})
        : projectData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessKey(
      accountId: number,
      projectId: string,
      directAccessKeyData?: DirectAccessKeyData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createAccessKey(
        accountId,
        projectId,
        directAccessKeyData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(
      accountId: number,
      createProjectData: CreateProjectData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createProject(
        accountId,
        createProjectData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary deactivates shared project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateSharedProject(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deactivateSharedProject(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessKey(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deleteAccessKey(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete project
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(
      projectId: string,
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).deleteProject(
        projectId,
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Evicts project resources (models are removed from cache and external services)
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evictProjectResources(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).evictProjectResources(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportProject(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectExportImportData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).exportProject(
        accountId,
        projectId,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessKey(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getAccessKey(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get amount of entries in all client entities of the project where clientId != null
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientEntitiesTotalSize(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getClientEntitiesTotalSize(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getProject(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project last change (change in intents/entities/project settings) timestamp.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectLastChangeDate(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getProjectLastChangeDate(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get amount of entries in speller dictionary
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionarySize(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).getSpellerDictionarySize(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Import caila project from json
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCaila(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectExportImportData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).importCaila(
        accountId,
        projectId,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Import all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectExportImportData} projectExportImportData
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importProject(
      accountId: number,
      projectId: string,
      projectExportImportData: ProjectExportImportData,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectExportImportData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).importProject(
        accountId,
        projectId,
        projectExportImportData,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(
      accountId: number,
      page?: number,
      size?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).listProjects(
        accountId,
        page,
        size,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      accountId: number,
      projectData: ProjectData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).updateProject(
        projectId,
        accountId,
        projectData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessKey(
      accountId: number,
      projectId: string,
      directAccessKeyData?: DirectAccessKeyData,
      options?: any
    ): AxiosPromise<string> {
      return ProjectsApiFp(configuration).createAccessKey(
        accountId,
        projectId,
        directAccessKeyData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(accountId: number, createProjectData: CreateProjectData, options?: any): AxiosPromise<ProjectData> {
      return ProjectsApiFp(configuration).createProject(accountId, createProjectData, options)(axios, basePath);
    },
    /**
     *
     * @summary deactivates shared project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateSharedProject(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return ProjectsApiFp(configuration).deactivateSharedProject(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessKey(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return ProjectsApiFp(configuration).deleteAccessKey(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete project
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(projectId: string, accountId: number, options?: any): AxiosPromise<void> {
      return ProjectsApiFp(configuration).deleteProject(projectId, accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Evicts project resources (models are removed from cache and external services)
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evictProjectResources(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return ProjectsApiFp(configuration).evictProjectResources(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Export all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportProject(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options?: any
    ): AxiosPromise<ProjectExportImportData> {
      return ProjectsApiFp(configuration).exportProject(
        accountId,
        projectId,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get direct access key
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessKey(accountId: number, projectId: string, options?: any): AxiosPromise<string> {
      return ProjectsApiFp(configuration).getAccessKey(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get amount of entries in all client entities of the project where clientId != null
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientEntitiesTotalSize(accountId: number, projectId: string, options?: any): AxiosPromise<number> {
      return ProjectsApiFp(configuration).getClientEntitiesTotalSize(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(accountId: number, projectId: string, options?: any): AxiosPromise<ProjectData> {
      return ProjectsApiFp(configuration).getProject(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project last change (change in intents/entities/project settings) timestamp.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectLastChangeDate(accountId: number, projectId: string, options?: any): AxiosPromise<number> {
      return ProjectsApiFp(configuration).getProjectLastChangeDate(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get amount of entries in speller dictionary
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionarySize(accountId: number, projectId: string, options?: any): AxiosPromise<number> {
      return ProjectsApiFp(configuration).getSpellerDictionarySize(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Import caila project from json
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCaila(
      accountId: number,
      projectId: string,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      file?: any,
      options?: any
    ): AxiosPromise<ProjectExportImportData> {
      return ProjectsApiFp(configuration).importCaila(
        accountId,
        projectId,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        file,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Import all project data.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectExportImportData} projectExportImportData
     * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
     * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importProject(
      accountId: number,
      projectId: string,
      projectExportImportData: ProjectExportImportData,
      clientEntitiesIncluded?: boolean,
      spellerDictionaryIncluded?: boolean,
      options?: any
    ): AxiosPromise<ProjectExportImportData> {
      return ProjectsApiFp(configuration).importProject(
        accountId,
        projectId,
        projectExportImportData,
        clientEntitiesIncluded,
        spellerDictionaryIncluded,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(accountId: number, page?: number, size?: number, options?: any): AxiosPromise<ProjectsData> {
      return ProjectsApiFp(configuration).listProjects(accountId, page, size, options)(axios, basePath);
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      accountId: number,
      projectData: ProjectData,
      options?: any
    ): AxiosPromise<ProjectData> {
      return ProjectsApiFp(configuration).updateProject(projectId, accountId, projectData, options)(axios, basePath);
    },
  };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
  /**
   *
   * @summary Create direct access key
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {DirectAccessKeyData} [directAccessKeyData] Request body to set direct access key for project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public createAccessKey(
    accountId: number,
    projectId: string,
    directAccessKeyData?: DirectAccessKeyData,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration).createAccessKey(
      accountId,
      projectId,
      directAccessKeyData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create project
   * @param {number} accountId User identifier.
   * @param {CreateProjectData} createProjectData New project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public createProject(accountId: number, createProjectData: CreateProjectData, options?: any) {
    return ProjectsApiFp(this.configuration).createProject(
      accountId,
      createProjectData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary deactivates shared project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public deactivateSharedProject(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).deactivateSharedProject(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete direct access key
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public deleteAccessKey(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).deleteAccessKey(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete project
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public deleteProject(projectId: string, accountId: number, options?: any) {
    return ProjectsApiFp(this.configuration).deleteProject(projectId, accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Evicts project resources (models are removed from cache and external services)
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public evictProjectResources(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).evictProjectResources(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export all project data.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
   * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public exportProject(
    accountId: number,
    projectId: string,
    clientEntitiesIncluded?: boolean,
    spellerDictionaryIncluded?: boolean,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration).exportProject(
      accountId,
      projectId,
      clientEntitiesIncluded,
      spellerDictionaryIncluded,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get direct access key
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getAccessKey(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).getAccessKey(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get amount of entries in all client entities of the project where clientId != null
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getClientEntitiesTotalSize(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).getClientEntitiesTotalSize(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getProject(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).getProject(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project last change (change in intents/entities/project settings) timestamp.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getProjectLastChangeDate(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).getProjectLastChangeDate(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get amount of entries in speller dictionary
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public getSpellerDictionarySize(accountId: number, projectId: string, options?: any) {
    return ProjectsApiFp(this.configuration).getSpellerDictionarySize(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Import caila project from json
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
   * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public importCaila(
    accountId: number,
    projectId: string,
    clientEntitiesIncluded?: boolean,
    spellerDictionaryIncluded?: boolean,
    file?: any,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration).importCaila(
      accountId,
      projectId,
      clientEntitiesIncluded,
      spellerDictionaryIncluded,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Import all project data.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ProjectExportImportData} projectExportImportData
   * @param {boolean} [clientEntitiesIncluded] True if we want import/export client entities with clientId !&#x3D; null, false otherwise
   * @param {boolean} [spellerDictionaryIncluded] True if we want import/export speller dictionary, false otherwise
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public importProject(
    accountId: number,
    projectId: string,
    projectExportImportData: ProjectExportImportData,
    clientEntitiesIncluded?: boolean,
    spellerDictionaryIncluded?: boolean,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration).importProject(
      accountId,
      projectId,
      projectExportImportData,
      clientEntitiesIncluded,
      spellerDictionaryIncluded,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return list of projects
   * @param {number} accountId User identifier.
   * @param {number} [page] Page.
   * @param {number} [size] Maximum number of items to return.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public listProjects(accountId: number, page?: number, size?: number, options?: any) {
    return ProjectsApiFp(this.configuration).listProjects(accountId, page, size, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update project.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} accountId User identifier.
   * @param {ProjectData} projectData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public updateProject(projectId: string, accountId: number, projectData: ProjectData, options?: any) {
    return ProjectsApiFp(this.configuration).updateProject(
      projectId,
      accountId,
      projectData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return this instance configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceConfiguration(options: any = {}): RequestArgs {
      const localVarPath = `/api/caila/public/instance-configuration`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Return this instance configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceConfiguration(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceConfigurationData> {
      const localVarAxiosArgs = PublicApiAxiosParamCreator(configuration).getInstanceConfiguration(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Return this instance configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceConfiguration(options?: any): AxiosPromise<InstanceConfigurationData> {
      return PublicApiFp(configuration).getInstanceConfiguration(options)(axios, basePath);
    },
  };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
  /**
   * Return this instance configuration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public getInstanceConfiguration(options?: any) {
    return PublicApiFp(this.configuration).getInstanceConfiguration(options)(this.axios, this.basePath);
  }
}

/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get NLU settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectSettings.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProjectSettings.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/settings`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List of supported languages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableLanguages(options: any = {}): RequestArgs {
      const localVarPath = `/api/caila/languages`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectSettingsData} projectSettingsData Project settings
     * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProjectSettings(
      accountId: number,
      projectId: string,
      projectSettingsData: ProjectSettingsData,
      mergeSettings?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling setProjectSettings.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling setProjectSettings.'
        );
      }
      // verify required parameter 'projectSettingsData' is not null or undefined
      if (projectSettingsData === null || projectSettingsData === undefined) {
        throw new RequiredError(
          'projectSettingsData',
          'Required parameter projectSettingsData was null or undefined when calling setProjectSettings.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/settings`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (mergeSettings !== undefined) {
        localVarQueryParameter['mergeSettings'] = mergeSettings;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof projectSettingsData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(projectSettingsData !== undefined ? projectSettingsData : {})
        : projectSettingsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get NLU settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingsData> {
      const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).getProjectSettings(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List of supported languages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableLanguages(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).listAvailableLanguages(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectSettingsData} projectSettingsData Project settings
     * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProjectSettings(
      accountId: number,
      projectId: string,
      projectSettingsData: ProjectSettingsData,
      mergeSettings?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingsData> {
      const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).setProjectSettings(
        accountId,
        projectId,
        projectSettingsData,
        mergeSettings,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Get NLU settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(accountId: number, projectId: string, options?: any): AxiosPromise<ProjectSettingsData> {
      return SettingsApiFp(configuration).getProjectSettings(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary List of supported languages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableLanguages(options?: any): AxiosPromise<Array<string>> {
      return SettingsApiFp(configuration).listAvailableLanguages(options)(axios, basePath);
    },
    /**
     * Update project settings.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectSettingsData} projectSettingsData Project settings
     * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProjectSettings(
      accountId: number,
      projectId: string,
      projectSettingsData: ProjectSettingsData,
      mergeSettings?: boolean,
      options?: any
    ): AxiosPromise<ProjectSettingsData> {
      return SettingsApiFp(configuration).setProjectSettings(
        accountId,
        projectId,
        projectSettingsData,
        mergeSettings,
        options
      )(axios, basePath);
    },
  };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
  /**
   * Get NLU settings
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public getProjectSettings(accountId: number, projectId: string, options?: any) {
    return SettingsApiFp(this.configuration).getProjectSettings(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List of supported languages.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public listAvailableLanguages(options?: any) {
    return SettingsApiFp(this.configuration).listAvailableLanguages(options)(this.axios, this.basePath);
  }

  /**
   * Update project settings.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ProjectSettingsData} projectSettingsData Project settings
   * @param {boolean} [mergeSettings] Flag whether we should rewrite settings or merge
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public setProjectSettings(
    accountId: number,
    projectId: string,
    projectSettingsData: ProjectSettingsData,
    mergeSettings?: boolean,
    options?: any
  ) {
    return SettingsApiFp(this.configuration).setProjectSettings(
      accountId,
      projectId,
      projectSettingsData,
      mergeSettings,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SkillsApi - axios parameter creator
 * @export
 */
export const SkillsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create skill with content from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSkillWithContent(
      accountId: number,
      projectId: string,
      skillName: string,
      skillType: SkillType,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createSkillWithContent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createSkillWithContent.'
        );
      }
      // verify required parameter 'skillName' is not null or undefined
      if (skillName === null || skillName === undefined) {
        throw new RequiredError(
          'skillName',
          'Required parameter skillName was null or undefined when calling createSkillWithContent.'
        );
      }
      // verify required parameter 'skillType' is not null or undefined
      if (skillType === null || skillType === undefined) {
        throw new RequiredError(
          'skillType',
          'Required parameter skillType was null or undefined when calling createSkillWithContent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (skillName !== undefined) {
        localVarQueryParameter['skillName'] = skillName;
      }

      if (skillType !== undefined) {
        localVarQueryParameter['skillType'] = skillType;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteSkillRequest} deleteSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSkill(
      accountId: number,
      projectId: string,
      deleteSkillRequest: DeleteSkillRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteSkill.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteSkill.'
        );
      }
      // verify required parameter 'deleteSkillRequest' is not null or undefined
      if (deleteSkillRequest === null || deleteSkillRequest === undefined) {
        throw new RequiredError(
          'deleteSkillRequest',
          'Required parameter deleteSkillRequest was null or undefined when calling deleteSkill.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof deleteSkillRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deleteSkillRequest !== undefined ? deleteSkillRequest : {})
        : deleteSkillRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSkillContent(accountId: number, projectId: string, skillId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSkillContent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getSkillContent.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling getSkillContent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary listContentFiles
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listContentFiles(accountId: number, projectId: string, skillId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listContentFiles.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listContentFiles.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling listContentFiles.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content/files`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of skills
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSkills(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listSkills.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listSkills.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Simple skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      query: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling simpleSkillInference.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling simpleSkillInference.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling simpleSkillInference.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling simpleSkillInference.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/inference`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Simple skill inference using skill name in query
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInferenceByName(
      accountId: number,
      projectId: string,
      skillName: string,
      query: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling simpleSkillInferenceByName.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling simpleSkillInferenceByName.'
        );
      }
      // verify required parameter 'skillName' is not null or undefined
      if (skillName === null || skillName === undefined) {
        throw new RequiredError(
          'skillName',
          'Required parameter skillName was null or undefined when calling simpleSkillInferenceByName.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling simpleSkillInferenceByName.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill/inference-by-name`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skillName !== undefined) {
        localVarQueryParameter['skillName'] = skillName;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      body: object,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling skillInference.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling skillInference.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling skillInference.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling skillInference.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/inference`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Skill Status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillStatus(accountId: number, projectId: string, skillId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling skillStatus.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling skillStatus.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling skillStatus.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Train skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainSkill(accountId: number, projectId: string, skillId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling trainSkill.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling trainSkill.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError('skillId', 'Required parameter skillId was null or undefined when calling trainSkill.');
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/train`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateSkillRequest} updateSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkill(
      accountId: number,
      projectId: string,
      updateSkillRequest: UpdateSkillRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateSkill.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateSkill.'
        );
      }
      // verify required parameter 'updateSkillRequest' is not null or undefined
      if (updateSkillRequest === null || updateSkillRequest === undefined) {
        throw new RequiredError(
          'updateSkillRequest',
          'Required parameter updateSkillRequest was null or undefined when calling updateSkill.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skill`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateSkillRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateSkillRequest !== undefined ? updateSkillRequest : {})
        : updateSkillRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary updateSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkillContent(
      accountId: number,
      projectId: string,
      skillId: string,
      skillType: SkillType,
      file?: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateSkillContent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateSkillContent.'
        );
      }
      // verify required parameter 'skillId' is not null or undefined
      if (skillId === null || skillId === undefined) {
        throw new RequiredError(
          'skillId',
          'Required parameter skillId was null or undefined when calling updateSkillContent.'
        );
      }
      // verify required parameter 'skillType' is not null or undefined
      if (skillType === null || skillType === undefined) {
        throw new RequiredError(
          'skillType',
          'Required parameter skillType was null or undefined when calling updateSkillContent.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/skills/{skillId}/content`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'skillId'}}`, encodeURIComponent(String(skillId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (skillType !== undefined) {
        localVarQueryParameter['skillType'] = skillType;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SkillsApi - functional programming interface
 * @export
 */
export const SkillsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create skill with content from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSkillWithContent(
      accountId: number,
      projectId: string,
      skillName: string,
      skillType: SkillType,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillData> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).createSkillWithContent(
        accountId,
        projectId,
        skillName,
        skillType,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteSkillRequest} deleteSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSkill(
      accountId: number,
      projectId: string,
      deleteSkillRequest: DeleteSkillRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).deleteSkill(
        accountId,
        projectId,
        deleteSkillRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary getSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSkillContent(
      accountId: number,
      projectId: string,
      skillId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).getSkillContent(
        accountId,
        projectId,
        skillId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary listContentFiles
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listContentFiles(
      accountId: number,
      projectId: string,
      skillId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillContentFiles> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).listContentFiles(
        accountId,
        projectId,
        skillId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return list of skills
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSkills(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillData>> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).listSkills(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Simple skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      query: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).simpleSkillInference(
        accountId,
        projectId,
        skillId,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Simple skill inference using skill name in query
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInferenceByName(
      accountId: number,
      projectId: string,
      skillName: string,
      query: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).simpleSkillInferenceByName(
        accountId,
        projectId,
        skillName,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      body: object,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).skillInference(
        accountId,
        projectId,
        skillId,
        body,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Skill Status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillStatus(
      accountId: number,
      projectId: string,
      skillId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillStatusData> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).skillStatus(
        accountId,
        projectId,
        skillId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Train skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainSkill(
      accountId: number,
      projectId: string,
      skillId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).trainSkill(
        accountId,
        projectId,
        skillId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateSkillRequest} updateSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkill(
      accountId: number,
      projectId: string,
      updateSkillRequest: UpdateSkillRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillData> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).updateSkill(
        accountId,
        projectId,
        updateSkillRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary updateSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkillContent(
      accountId: number,
      projectId: string,
      skillId: string,
      skillType: SkillType,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillData> {
      const localVarAxiosArgs = SkillsApiAxiosParamCreator(configuration).updateSkillContent(
        accountId,
        projectId,
        skillId,
        skillType,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SkillsApi - factory interface
 * @export
 */
export const SkillsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create skill with content from file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSkillWithContent(
      accountId: number,
      projectId: string,
      skillName: string,
      skillType: SkillType,
      file?: any,
      options?: any
    ): AxiosPromise<SkillData> {
      return SkillsApiFp(configuration).createSkillWithContent(
        accountId,
        projectId,
        skillName,
        skillType,
        file,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteSkillRequest} deleteSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSkill(
      accountId: number,
      projectId: string,
      deleteSkillRequest: DeleteSkillRequest,
      options?: any
    ): AxiosPromise<void> {
      return SkillsApiFp(configuration).deleteSkill(accountId, projectId, deleteSkillRequest, options)(axios, basePath);
    },
    /**
     *
     * @summary getSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSkillContent(accountId: number, projectId: string, skillId: string, options?: any): AxiosPromise<any> {
      return SkillsApiFp(configuration).getSkillContent(accountId, projectId, skillId, options)(axios, basePath);
    },
    /**
     *
     * @summary listContentFiles
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listContentFiles(
      accountId: number,
      projectId: string,
      skillId: string,
      options?: any
    ): AxiosPromise<SkillContentFiles> {
      return SkillsApiFp(configuration).listContentFiles(accountId, projectId, skillId, options)(axios, basePath);
    },
    /**
     *
     * @summary Return list of skills
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSkills(accountId: number, projectId: string, options?: any): AxiosPromise<Array<SkillData>> {
      return SkillsApiFp(configuration).listSkills(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Simple skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      query: string,
      options?: any
    ): AxiosPromise<object> {
      return SkillsApiFp(configuration).simpleSkillInference(
        accountId,
        projectId,
        skillId,
        query,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Simple skill inference using skill name in query
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillName skill name
     * @param {string} query Phrase for process or search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleSkillInferenceByName(
      accountId: number,
      projectId: string,
      skillName: string,
      query: string,
      options?: any
    ): AxiosPromise<object> {
      return SkillsApiFp(configuration).simpleSkillInferenceByName(
        accountId,
        projectId,
        skillName,
        query,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Skill inference
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillInference(
      accountId: number,
      projectId: string,
      skillId: string,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return SkillsApiFp(configuration).skillInference(accountId, projectId, skillId, body, options)(axios, basePath);
    },
    /**
     *
     * @summary Get Skill Status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    skillStatus(accountId: number, projectId: string, skillId: string, options?: any): AxiosPromise<SkillStatusData> {
      return SkillsApiFp(configuration).skillStatus(accountId, projectId, skillId, options)(axios, basePath);
    },
    /**
     *
     * @summary Train skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainSkill(accountId: number, projectId: string, skillId: string, options?: any): AxiosPromise<void> {
      return SkillsApiFp(configuration).trainSkill(accountId, projectId, skillId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update skill
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateSkillRequest} updateSkillRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkill(
      accountId: number,
      projectId: string,
      updateSkillRequest: UpdateSkillRequest,
      options?: any
    ): AxiosPromise<SkillData> {
      return SkillsApiFp(configuration).updateSkill(accountId, projectId, updateSkillRequest, options)(axios, basePath);
    },
    /**
     *
     * @summary updateSkillContent
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} skillId Skill identifier
     * @param {SkillType} skillType skill type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSkillContent(
      accountId: number,
      projectId: string,
      skillId: string,
      skillType: SkillType,
      file?: any,
      options?: any
    ): AxiosPromise<SkillData> {
      return SkillsApiFp(configuration).updateSkillContent(
        accountId,
        projectId,
        skillId,
        skillType,
        file,
        options
      )(axios, basePath);
    },
  };
};

/**
 * SkillsApi - object-oriented interface
 * @export
 * @class SkillsApi
 * @extends {BaseAPI}
 */
export class SkillsApi extends BaseAPI {
  /**
   *
   * @summary Create skill with content from file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillName skill name
   * @param {SkillType} skillType skill type
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public createSkillWithContent(
    accountId: number,
    projectId: string,
    skillName: string,
    skillType: SkillType,
    file?: any,
    options?: any
  ) {
    return SkillsApiFp(this.configuration).createSkillWithContent(
      accountId,
      projectId,
      skillName,
      skillType,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete skill
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {DeleteSkillRequest} deleteSkillRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public deleteSkill(accountId: number, projectId: string, deleteSkillRequest: DeleteSkillRequest, options?: any) {
    return SkillsApiFp(this.configuration).deleteSkill(
      accountId,
      projectId,
      deleteSkillRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary getSkillContent
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public getSkillContent(accountId: number, projectId: string, skillId: string, options?: any) {
    return SkillsApiFp(this.configuration).getSkillContent(
      accountId,
      projectId,
      skillId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary listContentFiles
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public listContentFiles(accountId: number, projectId: string, skillId: string, options?: any) {
    return SkillsApiFp(this.configuration).listContentFiles(
      accountId,
      projectId,
      skillId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return list of skills
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public listSkills(accountId: number, projectId: string, options?: any) {
    return SkillsApiFp(this.configuration).listSkills(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Simple skill inference
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {string} query Phrase for process or search
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public simpleSkillInference(accountId: number, projectId: string, skillId: string, query: string, options?: any) {
    return SkillsApiFp(this.configuration).simpleSkillInference(
      accountId,
      projectId,
      skillId,
      query,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Simple skill inference using skill name in query
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillName skill name
   * @param {string} query Phrase for process or search
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public simpleSkillInferenceByName(
    accountId: number,
    projectId: string,
    skillName: string,
    query: string,
    options?: any
  ) {
    return SkillsApiFp(this.configuration).simpleSkillInferenceByName(
      accountId,
      projectId,
      skillName,
      query,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Skill inference
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public skillInference(accountId: number, projectId: string, skillId: string, body: object, options?: any) {
    return SkillsApiFp(this.configuration).skillInference(
      accountId,
      projectId,
      skillId,
      body,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Skill Status
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public skillStatus(accountId: number, projectId: string, skillId: string, options?: any) {
    return SkillsApiFp(this.configuration).skillStatus(
      accountId,
      projectId,
      skillId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Train skill
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public trainSkill(accountId: number, projectId: string, skillId: string, options?: any) {
    return SkillsApiFp(this.configuration).trainSkill(
      accountId,
      projectId,
      skillId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update skill
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {UpdateSkillRequest} updateSkillRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public updateSkill(accountId: number, projectId: string, updateSkillRequest: UpdateSkillRequest, options?: any) {
    return SkillsApiFp(this.configuration).updateSkill(
      accountId,
      projectId,
      updateSkillRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary updateSkillContent
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} skillId Skill identifier
   * @param {SkillType} skillType skill type
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SkillsApi
   */
  public updateSkillContent(
    accountId: number,
    projectId: string,
    skillId: string,
    skillType: SkillType,
    file?: any,
    options?: any
  ) {
    return SkillsApiFp(this.configuration).updateSkillContent(
      accountId,
      projectId,
      skillId,
      skillType,
      file,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SpellerApi - axios parameter creator
 * @export
 */
export const SpellerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling addExclusion.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling addExclusion.'
        );
      }
      // verify required parameter 'spellerWordData' is not null or undefined
      if (spellerWordData === null || spellerWordData === undefined) {
        throw new RequiredError(
          'spellerWordData',
          'Required parameter spellerWordData was null or undefined when calling addExclusion.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/exclusion`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof spellerWordData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
        : spellerWordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWord(accountId: number, projectId: string, spellerWordData: SpellerWordData, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling addWord.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling addWord.'
        );
      }
      // verify required parameter 'spellerWordData' is not null or undefined
      if (spellerWordData === null || spellerWordData === undefined) {
        throw new RequiredError(
          'spellerWordData',
          'Required parameter spellerWordData was null or undefined when calling addWord.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/word`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof spellerWordData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
        : spellerWordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteExclusion.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteExclusion.'
        );
      }
      // verify required parameter 'spellerWordData' is not null or undefined
      if (spellerWordData === null || spellerWordData === undefined) {
        throw new RequiredError(
          'spellerWordData',
          'Required parameter spellerWordData was null or undefined when calling deleteExclusion.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/exclusion`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof spellerWordData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
        : spellerWordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpellerDictionary(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteSpellerDictionary.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteSpellerDictionary.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWord(accountId: number, projectId: string, spellerWordData: SpellerWordData, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteWord.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteWord.'
        );
      }
      // verify required parameter 'spellerWordData' is not null or undefined
      if (spellerWordData === null || spellerWordData === undefined) {
        throw new RequiredError(
          'spellerWordData',
          'Required parameter spellerWordData was null or undefined when calling deleteWord.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller/word`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof spellerWordData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(spellerWordData !== undefined ? spellerWordData : {})
        : spellerWordData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionary(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSpellerDictionary.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getSpellerDictionary.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSpellerDictionary(accountId: number, projectId: string, file?: any, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling uploadSpellerDictionary.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling uploadSpellerDictionary.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/speller`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpellerApi - functional programming interface
 * @export
 */
export const SpellerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).addExclusion(
        accountId,
        projectId,
        spellerWordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWord(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).addWord(
        accountId,
        projectId,
        spellerWordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteExclusion(
        accountId,
        projectId,
        spellerWordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpellerDictionary(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteSpellerDictionary(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWord(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).deleteWord(
        accountId,
        projectId,
        spellerWordData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionary(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).getSpellerDictionary(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSpellerDictionary(
      accountId: number,
      projectId: string,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SpellerApiAxiosParamCreator(configuration).uploadSpellerDictionary(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SpellerApi - factory interface
 * @export
 */
export const SpellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): AxiosPromise<void> {
      return SpellerApiFp(configuration).addExclusion(accountId, projectId, spellerWordData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWord(accountId: number, projectId: string, spellerWordData: SpellerWordData, options?: any): AxiosPromise<void> {
      return SpellerApiFp(configuration).addWord(accountId, projectId, spellerWordData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExclusion(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): AxiosPromise<void> {
      return SpellerApiFp(configuration).deleteExclusion(
        accountId,
        projectId,
        spellerWordData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpellerDictionary(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return SpellerApiFp(configuration).deleteSpellerDictionary(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {SpellerWordData} spellerWordData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWord(
      accountId: number,
      projectId: string,
      spellerWordData: SpellerWordData,
      options?: any
    ): AxiosPromise<void> {
      return SpellerApiFp(configuration).deleteWord(accountId, projectId, spellerWordData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpellerDictionary(accountId: number, projectId: string, options?: any): AxiosPromise<string> {
      return SpellerApiFp(configuration).getSpellerDictionary(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSpellerDictionary(accountId: number, projectId: string, file?: any, options?: any): AxiosPromise<string> {
      return SpellerApiFp(configuration).uploadSpellerDictionary(accountId, projectId, file, options)(axios, basePath);
    },
  };
};

/**
 * SpellerApi - object-oriented interface
 * @export
 * @class SpellerApi
 * @extends {BaseAPI}
 */
export class SpellerApi extends BaseAPI {
  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {SpellerWordData} spellerWordData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public addExclusion(accountId: number, projectId: string, spellerWordData: SpellerWordData, options?: any) {
    return SpellerApiFp(this.configuration).addExclusion(
      accountId,
      projectId,
      spellerWordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {SpellerWordData} spellerWordData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public addWord(accountId: number, projectId: string, spellerWordData: SpellerWordData, options?: any) {
    return SpellerApiFp(this.configuration).addWord(
      accountId,
      projectId,
      spellerWordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {SpellerWordData} spellerWordData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public deleteExclusion(accountId: number, projectId: string, spellerWordData: SpellerWordData, options?: any) {
    return SpellerApiFp(this.configuration).deleteExclusion(
      accountId,
      projectId,
      spellerWordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public deleteSpellerDictionary(accountId: number, projectId: string, options?: any) {
    return SpellerApiFp(this.configuration).deleteSpellerDictionary(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {SpellerWordData} spellerWordData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public deleteWord(accountId: number, projectId: string, spellerWordData: SpellerWordData, options?: any) {
    return SpellerApiFp(this.configuration).deleteWord(
      accountId,
      projectId,
      spellerWordData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public getSpellerDictionary(accountId: number, projectId: string, options?: any) {
    return SpellerApiFp(this.configuration).getSpellerDictionary(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpellerApi
   */
  public uploadSpellerDictionary(accountId: number, projectId: string, file?: any, options?: any) {
    return SpellerApiFp(this.configuration).uploadSpellerDictionary(
      accountId,
      projectId,
      file,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNLUStatus(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getNLUStatus.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getNLUStatus.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainNLU(
      accountId: number,
      projectId: string,
      incremental?: boolean,
      logLabelingSessionId?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling trainNLU.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling trainNLU.'
        );
      }
      const localVarPath = `/api/caila/accounts/{accountId}/projects/{projectId}/nlu/train`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (incremental !== undefined) {
        localVarQueryParameter['incremental'] = incremental;
      }

      if (logLabelingSessionId !== undefined) {
        localVarQueryParameter['logLabelingSessionId'] = logLabelingSessionId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNLUStatus(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NLUStatusData> {
      const localVarAxiosArgs = TrainingApiAxiosParamCreator(configuration).getNLUStatus(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainNLU(
      accountId: number,
      projectId: string,
      incremental?: boolean,
      logLabelingSessionId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TrainingApiAxiosParamCreator(configuration).trainNLU(
        accountId,
        projectId,
        incremental,
        logLabelingSessionId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNLUStatus(accountId: number, projectId: string, options?: any): AxiosPromise<NLUStatusData> {
      return TrainingApiFp(configuration).getNLUStatus(accountId, projectId, options)(axios, basePath);
    },
    /**
     * Train nlu model
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
     * @param {number} [logLabelingSessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainNLU(
      accountId: number,
      projectId: string,
      incremental?: boolean,
      logLabelingSessionId?: number,
      options?: any
    ): AxiosPromise<void> {
      return TrainingApiFp(configuration).trainNLU(
        accountId,
        projectId,
        incremental,
        logLabelingSessionId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI {
  /**
   * Train nlu model
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getNLUStatus(accountId: number, projectId: string, options?: any) {
    return TrainingApiFp(this.configuration).getNLUStatus(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   * Train nlu model
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [incremental] Incremental training. Retrain only changed parts. E.g. if only one named entity was changed - only this entity will be recreated, but classifier model will stay the same.
   * @param {number} [logLabelingSessionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public trainNLU(
    accountId: number,
    projectId: string,
    incremental?: boolean,
    logLabelingSessionId?: number,
    options?: any
  ) {
    return TrainingApiFp(this.configuration).trainNLU(
      accountId,
      projectId,
      incremental,
      logLabelingSessionId,
      options
    )(this.axios, this.basePath);
  }
}
