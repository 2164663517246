import { createContext, useContext } from 'react';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';

type StatesGroupContextType = {
  mainScreen: JStateWithId;
};
export const StatesGroupContext = createContext({} as StatesGroupContextType);
export const useStatesGroupContext = () => useContext(StatesGroupContext);

type groupOffsetContextType = {
  paddingLeft: number;
};
export const GroupOffsetContext = createContext({} as groupOffsetContextType);
export const GroupOffsetProvider = GroupOffsetContext.Provider;
export const useGroupOffset = () => useContext(GroupOffsetContext);

export const PADDING_LEFT = 20;
