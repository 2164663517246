export const isDev = () => process.env.NODE_ENV === 'development';
export const getUserLanguage = (userCountryIsoCode) => {
    if (userCountryIsoCode) {
        return userCountryIsoCode.toLowerCase();
    }
    const userLanguage = localStorage.getItem('USER_LANGUAGE');
    if (userLanguage) {
        return userLanguage;
    }
    const lang = window.navigator.language.split('-')[0];
    switch (lang) {
        case 'ru': {
            return 'ru';
        }
        case 'cn':
        case 'zh': {
            return 'cn';
        }
        default:
            return 'eng';
    }
};
export const timeoutPromise = (timeout, err, promise) => new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
});
export function flattenObject(ob) {
    const toReturn = {};
    for (let i in ob) {
        if (!ob.hasOwnProperty(i))
            continue;
        if (typeof ob[i] === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (let x in flatObject) {
                if (!flatObject.hasOwnProperty(x))
                    continue;
                toReturn[i + '.' + x] = flatObject[x];
            }
        }
        else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
export const detectSelection = () => window.getSelection ? window.getSelection().toString() : document.selection.createRange().text;
export const sort = (arr, field, dir = 'asc') => {
    const newArr = arr.slice();
    newArr.sort((a, b) => {
        switch (dir) {
            case 'desc': {
                if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
                    return 1;
                }
                if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
                    return -1;
                }
                return 0;
            }
            default: {
                if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
                    return 1;
                }
                if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
                    return -1;
                }
                return 0;
            }
        }
    });
    return newArr;
};
export const removeWidgetCode = () => {
    const justwidgetScriptElement = document.querySelector('script#justwidget');
    const firstJustwidgetElement = document.getElementsByClassName('justwidget')[0];
    const widgetJsElement = document.querySelector('script[src*="s/widget.js"]');
    const themesElement = document.querySelector('script[src*="s/themes/"]');
    if (justwidgetScriptElement) {
        justwidgetScriptElement.remove();
    }
    if (firstJustwidgetElement) {
        firstJustwidgetElement.remove();
    }
    if (widgetJsElement) {
        widgetJsElement.remove();
    }
    if (themesElement) {
        themesElement.remove();
    }
    let styleList = document.querySelectorAll('style[class="justwidget-styles"]');
    for (let i = 0; i < styleList.length; i++) {
        styleList[i].remove();
    }
    window.JustWidget = undefined;
};
export const delay = (ms) => new Promise(res => setTimeout(res, ms));
export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const getIntFromPageNumber = (number, fallBackNumber) => Number.isInteger(number) ? number : fallBackNumber;
export const normalizeNexmoName = (name) => name.replace('Nexmo whatsapp', 'Vonage').replace(/##\d*$/, '');
export const getUserValidName = (firstName = '', lastName = '', t, id) => `${firstName || ''} ${lastName || ''}`.trim() || id || t('Analytics: No data specified');
