import React, { FC, memo } from 'react';
import cn from 'classnames';
import { t } from 'localization';
import classes from './styles.module.scss';
import { Button } from '@just-ai/just-ui';

export const IntentContentPlaceholder: FC<{ createNewIntent: () => unknown; hasSystemIntents: boolean }> = memo(
  ({ createNewIntent, hasSystemIntents }) => {
    return (
      <div className={cn('d-flex flex-column gap-8', classes.IntentContentPlaceholder)}>
        <h3 className={cn('text-center color-gray-800')}>
          {hasSystemIntents
            ? t('IntentContentPlaceholder:title')
            : t('IntentContentPlaceholder:title_WithoutSystemIntents')}
        </h3>
        <p className={cn('color-gray-600 text-center', classes.IntentContentPlaceholder_text)}>
          {t('IntentContentPlaceholder:text')}
        </p>
        <Button color='primary' onClick={createNewIntent}>
          {t('IntentContentPlaceholder:CreateIntent')}
        </Button>
      </div>
    );
  }
);

IntentContentPlaceholder.displayName = 'memo(IntentContentPlaceholder)';
