export function formatNumberValue(code, number, country) {
    const cleanedNumber = number.replace(/\D/g, '');
    if (code !== '7' || country === 'kz' || cleanedNumber.length === 0)
        return cleanedNumber;
    return ('(' +
        cleanedNumber.slice(0, 3) +
        (cleanedNumber.length > 3 ? ') ' : '') +
        (cleanedNumber.length > 3 ? cleanedNumber.slice(3, 6) : '') +
        (cleanedNumber.length > 6 ? '-' : '') +
        (cleanedNumber.length > 6 ? cleanedNumber.slice(6, 8) : '') +
        (cleanedNumber.length > 8 ? '-' : '') +
        (cleanedNumber.length > 8 ? cleanedNumber.slice(8, 10) : ''));
}
