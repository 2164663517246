import { useCallback } from 'react';
const defaultOptions = {
    alt: false,
    shift: false,
    ctrl: false,
};
export const onPressKey = (options, fn) => (e) => {
    options = Object.assign(defaultOptions, options);
    if (e.key !== options.key)
        return;
    if (options.shift && !e.shiftKey)
        return;
    if (options.ctrl && !e.ctrlKey)
        return;
    if (options.alt && !e.altKey)
        return;
    e.preventDefault();
    fn && fn();
};
export default function usePressKey(options, fn) {
    return useCallback((e) => {
        if (!fn)
            return;
        onPressKey(options, fn)(e);
    }, 
    // eslint-disable-next-line
    [fn, ...Object.values(options)]);
}
