import { useContext, useMemo } from 'react';
import { AxiosError } from 'axios';
import { usePromiseProcessing } from '@just-ai/just-ui';
import KnowledgeHubIntegrationService from '@just-ai/api/dist/services/KnowledgeHubIntegrationService';
import { FakeKnowledgeHubIntegrationService } from './fakeApi';

import { t } from 'localization';

import { AnalyticsContext, TokensService } from '../external';

function useKnowledgeHubApi() {
  const { accountId, projectShortName } = useContext(AnalyticsContext);
  // return useMemo(
  //   () => new FakeKnowledgeHubIntegrationService(accountId, projectShortName),
  //   [accountId, projectShortName]
  // );
  return useMemo(() => new KnowledgeHubIntegrationService(accountId, projectShortName), [accountId, projectShortName]);
}

export const useGetIntegrationListApi = () => {
  const api = useKnowledgeHubApi();
  return usePromiseProcessing(api.getIntegrations, { deps: [api] });
};

export const useGetExistedKnowledgeBasesApi = () => {
  const api = useKnowledgeHubApi();
  return usePromiseProcessing(api.getPreviews, { deps: [api] });
};

export const useGetKnowledgeBaseByTokenApi = () => {
  const api = useKnowledgeHubApi();

  return usePromiseProcessing(api.getPreviewByToken, {
    transformError: (err: any) => {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400) {
          return new Error(t('KhubIntegration:GetByToken:Errors:invalidToken'));
        }
      }
      throw err;
    },
    throwOnError: true,
  });
};

export const useCreateKnowledgeBaseIntegrationApi = () => {
  const api = useKnowledgeHubApi();
  return usePromiseProcessing(api.createIntegration, { throwOnError: true });
};

export const useCreateIntegrationByTokenApi = () => {
  const api = useKnowledgeHubApi();
  return usePromiseProcessing(api.createIntegrationByToken, { throwOnError: true });
};

export const useDeleteKnowledgeBasesApiApi = () => {
  const api = useKnowledgeHubApi();
  return usePromiseProcessing(api.deleteIntegration, { throwOnError: true });
};

export const useGetSecretNamesApi = () => {
  const { accountId, projectShortName } = useContext(AnalyticsContext);
  const secretApiService = useMemo(() => new TokensService(accountId, projectShortName), [accountId, projectShortName]);

  return usePromiseProcessing(
    () =>
      secretApiService
        .getSecretNames()
        .then(res => res.data.map(el => el.secretName))
        .catch(() => [] as string[]),
    { deps: [secretApiService] }
  );
};
