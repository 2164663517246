import { useCallback, useMemo, useState } from 'react';

import { deleteBlockInScreenAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { TagNames, TJBlock } from 'modules/JGraph/utils/types';
import { useRenderConditionContext } from '../ConditionSettings';

export const useBlockDelete = (prefix: string) => {
  const { editMenuScreen } = useAppSelector(store => ({
    editMenuScreen: store.JGraphReducer.editMenuBlock?.screen,
  }));
  const dispatch = useAppDispatch();
  const [openDeleteIndex, setOpenDeleteIndex] = useState<number | undefined>(undefined);

  const setOpenDeleteHandler = useCallback((index: number | undefined) => {
    setOpenDeleteIndex(index);
  }, []);

  const deleteHandler = useCallback(() => {
    setOpenDeleteIndex(undefined);
    dispatch(
      deleteBlockInScreenAsync({ screenId: editMenuScreen!.pathId, blockIndex: openDeleteIndex || 0, path: prefix })
    );
  }, [dispatch, editMenuScreen, openDeleteIndex, prefix]);

  return {
    openDeleteIndex,
    setOpenDeleteIndex,
    setOpenDeleteHandler,
    deleteHandler,
  };
};

export const useCommonContextPrefix = (blockIndex?: number, block?: TJBlock) => {
  const { prefix, globalIndex } = useRenderConditionContext();

  let innerPrefix = useMemo(() => {
    const isItElseOrElseIf = [TagNames.else, TagNames.elseif].includes(block?.tagName || TagNames.a);
    if (isItElseOrElseIf) {
      return `${globalIndex}_if_`;
    }
    const isItRandomTag = TagNames.random === block?.tagName;
    if (isItRandomTag) {
      return `${globalIndex}_random_`;
    }
    return '';
  }, [block?.tagName, globalIndex]);

  const computedPrefix = useMemo(() => {
    if (!blockIndex) return '';
    return `${blockIndex}_${block?.tagName}_`;
  }, [block?.tagName, blockIndex]);

  const ContextPrefix = useMemo(() => {
    if (!prefix) return innerPrefix;
    return `${prefix}${innerPrefix}${computedPrefix}`;
  }, [computedPrefix, innerPrefix, prefix]);

  return { ContextPrefix };
};
