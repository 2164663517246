import React, { createContext, useContext, useMemo } from 'react';
import { groupBy } from 'lodash';

import { GroupedConnector } from 'reducers/JGraph.reducer/types';
import { getPathWithoutThemePrefix } from 'modules/JGraph/utils/themesUtils';

import { AutopositionGroup } from '../parts/AutopositionGroup';
import { IncomingItem } from './IncomingItem';

type PathContextType = {
  path: string;
};
export const PathContext = createContext({} as PathContextType);
export const usePathContext = () => useContext(PathContext);

type IncomingConnectionsListProps = {
  group: GroupedConnector;
  width: number;
  xOffset?: number;
};
function IncomingConnectionsList({ group, width, xOffset }: IncomingConnectionsListProps) {
  const groupedConnections = useMemo(() => {
    return groupBy(group.connectors, connector => connector.fromNodeOriginalPath);
  }, [group.connectors]);

  return (
    <AutopositionGroup xOffset={xOffset}>
      {Object.entries(groupedConnections).map(([fromNodeOriginalPath, connections]) => (
        <PathContext.Provider key={fromNodeOriginalPath} value={{ path: fromNodeOriginalPath }}>
          <IncomingItem
            path={fromNodeOriginalPath}
            displayPath={getPathWithoutThemePrefix(fromNodeOriginalPath, group.theme.value)}
            connections={connections}
            width={width}
          />
        </PathContext.Provider>
      ))}
    </AutopositionGroup>
  );
}

IncomingConnectionsList.displayName = 'IncomingConnectionsList';

export default React.memo(IncomingConnectionsList);
