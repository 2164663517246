import { LogLabelingReducer, LogLabelingState } from '.';
import { filterAllPhrases } from './grouping.reducer';
import { Groups, Phrases } from '../../../utils';
import { GroupInfo } from 'modules/Caila/utils/groups';

const initReducer = (state: LogLabelingState, allGroups: GroupInfo[]) => {
  const groupsDataset = Groups.toDatasetWithoutUngrouped(allGroups);
  const { filteredAllPhrases } = filterAllPhrases(state);
  const notRecognized = Phrases.sortDataset(
    Phrases.selectGroupPhrases(groupsDataset['group_noMatch'].phrases, filteredAllPhrases)
  );
  return {
    ...state,
    selectedGroupIds: ['group_noMatch'],
    phrases: notRecognized,
    filteredAllPhrases: notRecognized,
    groups: groupsDataset,
  };
};

const customFilter = (state: LogLabelingState) => {
  const notRecognized = Phrases.sortDataset(
    Phrases.selectGroupPhrases(state.groups['group_noMatch'].phrases, state.filteredAllPhrases)
  );
  return {
    ...state,
    phrases: notRecognized,
    filteredAllPhrases: notRecognized,
  };
};

const UnrecogReducer: LogLabelingReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initReducer(state, action.allGroups);
    case 'SET_PHRASES_FILTER':
      return customFilter(state);
    case 'TOGGLE_SHOW_LABELED':
    case 'DELETE_PHRASES':
    case 'STAGE_PHRASES':
    case 'CLEAR_PHRASES':
      return {
        ...state,
        phrases: Phrases.sortDataset(state.filteredAllPhrases),
        filteredAllPhrases: Phrases.sortDataset(state.filteredAllPhrases),
      };
    default:
      return { ...state };
  }
};

export default UnrecogReducer;
