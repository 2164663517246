import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { FAQApi, IntentsApi, SettingsApi, TrainingApi } from '@just-ai/api/dist/generated/Caila';
import SkillsApiService from '@just-ai/api/dist/services/SkillsApiService';
export const MAX_NETWORK_TIMEOUT = 30000; // 30sec
const CONFIGURATION = {
    basePath: '/cailapub',
    baseOptions: { timeout: MAX_NETWORK_TIMEOUT },
};
export const NLUModuleContext = createContext({});
const NLUModuleContextProviderComponent = React.memo(props => {
    const { accountId, axiosInstance, projectShortName, isAccessFunction, actions, appLogger, trainingNlu, trainNlu, showNluWidget, currentProject, setCurrentProject, isWhiteLabel, setCurrentProjectToEdit, isContentManager, language, saveAudioToServer, saveFileToServer, validateFile, isZfl, } = props;
    const history = useHistory();
    const wrappedHistoryPush = useCallback(({ url, skillName, historyState = {} }) => {
        if (isZfl)
            return history.push({
                pathname: `/${url}/${skillName || projectShortName}`,
                state: historyState,
            });
        return skillName ? history.push(`/${skillName}/${url}`, historyState) : history.push(url, historyState);
    }, [history, isZfl, projectShortName]);
    return (_jsx(NLUModuleContext.Provider, { value: {
            accountId: accountId,
            projectShortName: projectShortName,
            axiosInstance: axiosInstance,
            FAQApi: useMemo(() => new FAQApi(CONFIGURATION), []),
            IntentsApi: useMemo(() => new IntentsApi(CONFIGURATION), []),
            TrainingApi: useMemo(() => new TrainingApi(CONFIGURATION), []),
            SettingsApi: useMemo(() => new SettingsApi(CONFIGURATION), []),
            skillsApiService: useMemo(() => new SkillsApiService(accountId, projectShortName, axiosInstance), [accountId, axiosInstance, projectShortName]),
            isAccessFunction,
            actions,
            appLogger,
            trainingNlu,
            trainNlu,
            showNluWidget,
            currentProject,
            setCurrentProject,
            isWhiteLabel,
            setCurrentProjectToEdit,
            isContentManager,
            language,
            saveAudioToServer,
            saveFileToServer,
            validateFile,
            isZfl,
            wrappedHistoryPush,
        }, children: props.children }));
});
export const useNLUModuleContext = () => useContext(NLUModuleContext);
export const NLUModuleContextProvider = NLUModuleContextProviderComponent;
