export function safeWhile(condition: () => boolean, callback: () => boolean | void, availableLoops: number) {
  if (typeof availableLoops !== 'number') {
    throw new Error('availableLoops must be a number');
  }
  let limit = availableLoops;
  while (condition() && limit > 0) {
    if (callback() === false) break;
    limit -= 1;
  }
}
