import { Announcement } from '@just-ai/just-ui';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';
import React, { FunctionComponent } from 'react';

const { t } = i18nTranslation('Header', 'GithubTokenBanner');

export const GithubTokenBanner: FunctionComponent = () => (
  <Announcement
    AnnouncementText={() => <span>{t('text')}</span>}
    type='warning'
    action={{ callback: () => window.open(t('linkHref'), '_blank'), text: t('linkLabel') }}
  />
);
