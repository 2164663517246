const prefix = '@dialogList/';
export const INIT = prefix + 'DIALOGS_INIT';
export const INIT_FULFILLED = prefix + 'DIALOGS_INIT_FULFILLED';
export const INIT_PENDING = prefix + 'DIALOGS_INIT_PENDING';
export const INIT_REJECTED = prefix + 'DIALOGS_INIT_REJECTED';

export const PAGINATE = prefix + 'DIALOGS_PAGINATE';
export const PAGINATE_FULFILLED = prefix + 'DIALOGS_PAGINATE_FULFILLED';
export const PAGINATE_PENDING = prefix + 'DIALOGS_PAGINATE_PENDING';
export const PAGINATE_REJECTED = prefix + 'DIALOGS_PAGINATE_REJECTED';

export const FILTER_SETTINGS = prefix + 'FILTER_SETTINGS';
export const FILTER_SETTINGS_FULFILLED = prefix + 'FILTER_SETTINGS_FULFILLED';
export const FILTER_SETTINGS_PENDING = prefix + 'FILTER_SETTINGS_PENDING';
export const FILTER_SETTINGS_REJECTED = prefix + 'FILTER_SETTINGS_REJECTED';

export const GET_ISSUES_BY_SESSIONID = prefix + 'GET_ISSUES_BY_SESSIONID';
export const GET_ISSUES_BY_SESSIONID_FULFILLED = prefix + 'GET_ISSUES_BY_SESSIONID_FULFILLED';
export const GET_ISSUES_BY_SESSIONID_PENDING = prefix + 'GET_ISSUES_BY_SESSIONID_PENDING';
export const GET_ISSUES_BY_SESSIONID_REJECTED = prefix + 'GET_ISSUES_BY_SESSIONID_REJECTED';

export const UPDATE_FILTER_PARAMS = prefix + 'UPDATE_FILTER_PARAMS';
export const DROP_FILTER = prefix + 'DROP_FILTER';
export const DATE_CHANGED = prefix + 'DIALOGS_DATE_CHANGED';
export const SELECT_LABELS = prefix + 'SELECT_LABELS';
export const SELECT_SESSION_LABELS = prefix + 'SELECT_SESSION_LABELS';
export const SELECT_CHANNEL = prefix + 'DIALOGS_SELECT_CHANNEL';
export const LIVECHAT_CHANGE = prefix + 'LIVECHAT_CHANGE';

//todo remove after removing dialogs
export const SELECT_TAGS = prefix + 'DIALOGS_SELECT_TAGS';
