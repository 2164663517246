import { FileData } from '../api/client';
import { TreeNode } from '@just-ai/just-ui';
import FolderNode from './FolderNode';

export default class FileNode implements TreeNode {
  path: string;
  lastModified: number;
  temporaryModified?: number;
  name: string;
  extension: string;
  nodeId: string;
  hasLocalChanges: boolean;
  prefix: string;

  parentNode?: FolderNode;
  get parent(): string {
    return this.parentNode ? this.parentNode.nodeId : '';
  }

  static prefixed(path: string, prefix: string = '') {
    return prefix + path;
  }

  static deprefixed(fileId: string, prefix: string = '') {
    return fileId.startsWith(prefix) ? fileId.substring(prefix.length) : fileId;
  }

  static getName(path: string) {
    return path.substring(path.lastIndexOf('/') + 1);
  }

  static getExtension(path: string) {
    return path.substring(path.lastIndexOf('.') + 1);
  }

  constructor(path?: string, lastModified?: number, hasLocalChanges?: boolean, prefix: string = '') {
    this.path = path ? path : '/<no name>';
    this.prefix = prefix;
    this.name = FileNode.getName(this.path);
    this.extension = FileNode.getExtension(this.path);
    this.nodeId = FileNode.prefixed(this.path, prefix);
    this.lastModified = lastModified ? lastModified : 0;
    this.temporaryModified = this.lastModified;
    this.hasLocalChanges = hasLocalChanges ? hasLocalChanges : false;
  }

  setLastModified(lastModified: number) {
    this.lastModified = lastModified;
    this.temporaryModified = lastModified;
  }

  setParentNode(parentNode: FolderNode) {
    this.parentNode = parentNode;
  }

  setHasLocalChanges() {
    this.hasLocalChanges = true;
  }

  setTemporaryModified = (temporaryModified: number) => {
    this.temporaryModified = temporaryModified;
  };

  resetHasLocalChanges() {
    this.hasLocalChanges = false;
  }

  getRenamedId(newName: string) {
    if (!this.parentNode || this.parentNode.path === '/') return '/' + newName;
    return `${this.parentNode.path}/${newName}`;
  }

  static fromDto(file: FileData, prefix?: string) {
    return new FileNode(file.name, file.lastModified, file.hasLocalChanges, prefix);
  }
}
