import React from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' className='svg-inline--fa fa-code fa-w-20 mr-3'>
      <path
        d='M0 8C0 5.87827 0.842856 3.84344 2.34315 2.34314C3.84344 0.842854 5.87827 0 8 0H16V8C16 9.05057 15.7931 10.0909 15.391 11.0615C14.989 12.0321 14.3997 12.914 13.6569 13.6569C12.914 14.3997 12.0321 14.989 11.0615 15.391C10.0909 15.7931 9.05057 16 8 16V16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842856 12.1566 0 10.1217 0 8Z'
        fill='#C3C6C9'
      />
      <path
        d='M8.38298 6.79149V11.3775C8.38298 11.8871 8.58894 12.1419 9.15064 12.1419H9.76341V14.0307H8.96001C7.52001 14.0307 6.2366 13.2171 6.2366 11.3809V1.94556H8.38298V6.79149Z'
        fill='#1F2832'
      />
      <path d='M7.99999 4.71997H5.11148V6.49189H7.99999V4.71997Z' fill='#1F2832' />
      <path
        d='M10.2638 6.55492C10.9839 6.55492 11.5677 5.97118 11.5677 5.25109C11.5677 4.53101 10.9839 3.94727 10.2638 3.94727C9.54373 3.94727 8.95999 4.53101 8.95999 5.25109C8.95999 5.97118 9.54373 6.55492 10.2638 6.55492Z'
        fill='#1F2832'
      />
    </svg>
  );
};
