import { useRef, useEffect, RefObject } from 'react';
import { Subject } from 'rxjs';

import { isStateType } from 'modules/JGraph/utils/isStateType';
import { debugDataState$ } from 'modules/JGraph/pipes/debugDataPipe';

import { GuideStep } from '../../../../components/GuideTourStepper/types';
import { EditMenuBlockPipe$ } from '../StateScreen.hooks';

export const guideTourEvent$ = new Subject<string>();

export function useGuideTourEventsSubscribe(currentStep: RefObject<GuideStep | null>, onStepEventReceived: () => void) {
  const onStepEventReceivedRef = useRef(onStepEventReceived);
  useEffect(() => {
    onStepEventReceivedRef.current = onStepEventReceived;
  }, [onStepEventReceived]);

  useEffect(() => {
    const sub = guideTourEvent$.subscribe(eventName => {
      const event = currentStep.current?.waitEvent || currentStep.current?.goNextOnNextEvent;

      if (!event) return;
      const events = event
        .trim()
        .split(' ')
        .map(event => event.split('||'));
      if (events[0].includes(eventName)) {
        events.shift();
        if (events.length === 0) {
          onStepEventReceivedRef.current();
        } else {
          currentStep.current.waitEvent = events.map(event => event.join('||')).join(' ');
        }
        return;
      }
      if (!events[0].includes(eventName)) return;
      onStepEventReceivedRef.current();
    });

    const sub2 = EditMenuBlockPipe$.subscribe(editMenuBlock => {
      if (
        editMenuBlock?.screen &&
        !editMenuBlock.jBlockIndex &&
        !editMenuBlock.path &&
        isStateType(editMenuBlock?.screen, 'start')
      ) {
        guideTourEvent$.next(`OpenedRightSideMenuWithStartState`);
      }
      if (editMenuBlock) {
        guideTourEvent$.next(`OpenedRightSideMenu:${editMenuBlock?.screen.value}`);
      }
    });
    const sub3 = debugDataState$.subscribe(() => {
      guideTourEvent$.next(`DebugDataReceived`);
    });

    const justwidgetToggleCallback = (event: Event & { detail?: boolean }) => {
      guideTourEvent$.next(event.detail ? 'TestWidget:Opened' : 'TestWidget:Closed');
    };
    document.addEventListener('justwidget_toggle', justwidgetToggleCallback);

    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
      sub3.unsubscribe();
      document.removeEventListener('justwidget_toggle', justwidgetToggleCallback);
    };
  }, []);
}
