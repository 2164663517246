import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CloseBtn from './CloseBtn';
import { Button } from '@just-ai/just-ui';

import classes from './styles.module.scss';

export default class JoyrideTooltipContainer extends React.Component {
  static propTypes = {
    backProps: PropTypes.object.isRequired,
    closeProps: PropTypes.object.isRequired,
    continuous: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    isLastStep: PropTypes.bool.isRequired,
    primaryProps: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
    skipProps: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    tooltipProps: PropTypes.object.isRequired,
    arrowRef: PropTypes.object.isRequired,
  };

  render() {
    const {
      backProps,
      closeProps,
      continuous,
      index,
      isLastStep,
      primaryProps,
      size,
      skipProps,
      step,
      tooltipProps,
    } = this.props;
    const {
      content,
      afterContent,
      hideBackButton,
      hideCloseButton,
      hideFooter,
      showProgress,
      showSkipButton,
      title,
      styles,
    } = step;
    const { back, close, last, next, skip } = step.locale;
    const output = {
      primary: close,
    };

    if (continuous) {
      output.primary = isLastStep ? last : next;

      if (showProgress) {
        output.primary = (
          <span>
            {output.primary} ({index + 1}/{size})
          </span>
        );
      }
    }

    if (showSkipButton && !isLastStep) {
      output.skip = (
        <button style={styles.buttonSkip} type='button' data-test-id='button-skip' aria-live='off' {...skipProps}>
          {skip}
        </button>
      );
    }

    if (!hideBackButton && index > 0) {
      output.back = (
        <button style={styles.buttonBack} type='button' data-test-id='button-back' {...backProps}>
          {back}
        </button>
      );
    }

    output.close = !hideCloseButton && (
      <CloseBtn styles={styles.buttonClose} data-test-id='button-close' {...closeProps} />
    );

    return (
      <>
        <div
          key='JoyrideTooltip'
          className={cn('react-joyride__tooltip', classes.GuideTourDefaultTooltip)}
          style={styles.tooltip}
          data-test-id={step.dataTestId || ''}
          {...tooltipProps}
        >
          <div style={styles.tooltipContainer}>
            {title && (
              <h4 style={styles.tooltipTitle} aria-label={title}>
                {title}
              </h4>
            )}
            <div className={classes.GuideTourDefaultTooltip__content} style={styles.tooltipContent}>
              {content}
            </div>
            {afterContent && (
              <div className={classes.GuideTourDefaultTooltip__afterContent} style={styles.tooltipContent}>
                {afterContent}
              </div>
            )}
          </div>
          {!hideFooter && (
            <div style={styles.tooltipFooter}>
              <div style={styles.tooltipFooterSpacer}>{output.skip}</div>
              {output.back}

              <Button
                style={styles.buttonNext}
                color='primary'
                type='button'
                data-test-id='button-primary'
                {...primaryProps}
              >
                {output.primary}
              </Button>
            </div>
          )}
          {output.close}
        </div>
        <div style={styles.tooltipArrow} className={classes.GuideTourDefaultTooltip__arrow} ref={this.props.arrowRef} />
      </>
    );
  }
}
