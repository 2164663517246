const prefix = '@appConfig/';

export const GET_APP_CONFIG = prefix + 'GET_APP_CONFIG';
export const GET_APP_CONFIG_PENDING = prefix + 'GET_APP_CONFIG_PENDING';
export const GET_APP_CONFIG_FULFILLED = prefix + 'GET_APP_CONFIG_FULFILLED';
export const GET_APP_CONFIG_REJECTED = prefix + 'GET_APP_CONFIG_REJECTED';

export const GET_APP_STATUS = prefix + 'GET_APP_STATUS';
export const GET_APP_STATUS_PENDING = prefix + 'GET_APP_STATUS_PENDING';
export const GET_APP_STATUS_FULFILLED = prefix + 'GET_APP_STATUS_FULFILLED';
export const GET_APP_STATUS_REJECTED = prefix + 'GET_APP_STATUS_REJECTED';

export const GET_OPTIONS = prefix + 'GET_OPTIONS';
export const GET_OPTIONS_FULFILLED = prefix + 'GET_OPTIONS_FULFILLED';
export const GET_OPTIONS_PENDING = prefix + 'GET_OPTIONS_PENDING';
export const GET_OPTIONS_REJECTED = prefix + 'GET_OPTIONS_REJECTED';
