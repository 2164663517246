import React from 'react';

import styles from './styles.module.scss';

interface HintProps {
  children: React.ReactNode;
}
const Hint = ({ children }: HintProps) => {
  return <div className={styles.Hint}>{children}</div>;
};

Hint.displayName = 'Hint';

export default React.memo(Hint);
