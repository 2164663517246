import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "../Icon";
import classNames from "classnames";
import './Message.scss';
const IconByMessageType = {
    'success': 'faCheckSquare',
    'info': 'faInfoCircle',
    'danger': 'faExclamationCircle',
    'warning': 'faExclamationTriangle',
};
export const Message = ({ type, text = null }) => {
    const TextComponent = typeof text === 'string' ? () => _jsx(_Fragment, { children: text }) : text;
    return _jsxs("div", { className: classNames('Message', type), children: [_jsx("div", { className: 'Message-icon', children: _jsx(Icon, { className: 'justui_announcement-icon-type', name: IconByMessageType[type], color: type }) }), _jsx("div", { className: 'Message-text', children: _jsx(TextComponent, {}) })] });
};
