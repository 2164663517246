import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, IconButton, InlineRadioButton } from '@just-ai/just-ui';
import { t } from 'localization';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { useAppSelector } from '../../../../../storeHooks';
import { useRightSideMenuContext } from '../index';
import { highLightConnectors$ } from '../../../hooks/highLightConnectors';
import { TConnector } from '../../../../../reducers/JGraph.reducer/types';
import { ReactionsTagNames, TActivationTagNames, TagNames, TReactionsTagNames } from '../../../utils/types';
import { buildIcon, mapIconToTagName } from './ScreenBlocksList/ReactionItem';
import { scrollToTargetGlobal$ } from '../../../utils/stageUtils';
import { deleteConnectorAsync } from '../../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { getConnectionsFromBlocks } from '../../../../../reducers/JGraph.reducer/Graph';

import { useJGraphContext } from '../../../contexts/JGraphContext';

export const TransitionMenu: FC = () => {
  const { screens } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
  }));
  const { editMenuScreen } = useRightSideMenuContext();
  const [activeTab, setActiveTab] = useState<'incoming' | 'outgoing'>('incoming');
  const { connections } = useMemo(() => getConnectionsFromBlocks(screens), [screens]);

  const incoming = useMemo(() => {
    return connections.filter(connector => connector.to === editMenuScreen.pathId);
  }, [editMenuScreen.pathId, connections]);

  const outgoing = useMemo(() => {
    return connections.filter(connector => connector.fromNode === editMenuScreen.pathId);
  }, [editMenuScreen.pathId, connections]);

  const whatToShow = useMemo(() => {
    return activeTab === 'incoming' ? incoming : outgoing;
  }, [activeTab, incoming, outgoing]);

  const tabChange = useCallback((_, option) => {
    setActiveTab(option.value);
  }, []);

  useEffect(() => {
    highLightConnectors$.next({ activePathId: editMenuScreen.pathId, connector: null });
    return () => highLightConnectors$.next({ activePathId: undefined, connector: null });
  }, [editMenuScreen.pathId]);

  return (
    <div className='JGraph-RightSideMenu-commonContainer'>
      <div className='inlineFullWidth'>
        <InlineRadioButton
          active={activeTab}
          color='primary'
          name='buttonType'
          onChange={tabChange}
          options={[
            { name: t('TransitionMenu-incoming'), value: 'incoming' },
            { name: t('TransitionMenu-outgoing'), value: 'outgoing' },
          ]}
          outline
          size='default'
        />
      </div>
      <div className='TransitionItemContainer'>
        {whatToShow.map(connectionItem => (
          <TransitionItem
            key={`blockPath-${editMenuScreen.pathId}`}
            connector={connectionItem}
            activeTab={activeTab}
            blockPathId={editMenuScreen.pathId}
          />
        ))}
      </div>
    </div>
  );
};

const TransitionItem: FC<{ connector: TConnector; activeTab: 'incoming' | 'outgoing'; blockPathId: string }> = ({
  connector,
  activeTab,
  blockPathId,
}) => {
  const { dispatch } = useRightSideMenuContext();
  const { IntentsService, currentBotLanguage } = useJGraphContext();
  const mouseEnter = useCallback(() => {
    highLightConnectors$.next({ activePathId: blockPathId, connector: connector });
  }, [blockPathId, connector]);
  const mouseLeave = useCallback(() => {
    highLightConnectors$.next({ activePathId: blockPathId, connector: null });
  }, [blockPathId]);

  const onClickHandler = useCallback(() => {
    scrollToTargetGlobal$.next({
      targetPathId: activeTab === 'incoming' ? connector.fromNode || '' : connector.to || '',
    });
  }, [activeTab, connector.fromNode, connector.to]);

  const { value, tagName } = connector;

  const intentValue = useMemo(() => {
    if ([TagNames.intent_, TagNames.intent].includes(tagName as TagNames)) {
      if (IntentsService.isSystemIntent(currentBotLanguage, value)) {
        return t(`ChooseReadyIntent ${value}`);
      }
    }
    return value;
  }, [tagName, value, IntentsService, currentBotLanguage]);

  const isReaction = ReactionsTagNames().includes(connector.tagName as TReactionsTagNames);

  const deleteConnectionHandler = useCallback(() => {
    dispatch(deleteConnectorAsync(connector));
  }, [connector, dispatch]);

  const path = activeTab === 'incoming' ? connector.fromNodeOriginalPath : connector.toNodeOriginalPath;
  return (
    <div className='TransitionItem' onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div className='params'>
        <div className='fromOrTo'>{hideRootSlashInPath(path)}</div>
        <div className='value'>
          {isReaction ? (
            <Icon color='secondary' name={mapIconToTagName[connector.tagName as TReactionsTagNames]} />
          ) : (
            buildIcon(connector.tagName as TActivationTagNames)
          )}{' '}
          {intentValue}
        </div>
      </div>
      <div className='actions'>
        <Button flat color='secondary' onClick={onClickHandler}>
          <Target />
        </Button>
        <IconButton flat name='farMinusCircle' color='secondary' onClick={deleteConnectionHandler} />
      </div>
    </div>
  );
};

export function Target() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.8125 5.625H10.481C10.3003 3.43969 8.56031 1.69969 6.375 1.51898V0.1875C6.375 0.0839062 6.29109 0 6.1875 0H5.8125C5.70891 0 5.625 0.0839062 5.625 0.1875V1.51898C3.43969 1.69969 1.69969 3.43969 1.51898 5.625H0.1875C0.0839062 5.625 0 5.70891 0 5.8125V6.1875C0 6.29109 0.0839062 6.375 0.1875 6.375H1.51898C1.69969 8.56031 3.43969 10.3003 5.625 10.481V11.8125C5.625 11.9161 5.70891 12 5.8125 12H6.1875C6.29109 12 6.375 11.9161 6.375 11.8125V10.481C8.56031 10.3003 10.3003 8.56031 10.481 6.375H11.8125C11.9161 6.375 12 6.29109 12 6.1875V5.8125C12 5.70891 11.9161 5.625 11.8125 5.625ZM6 9.75C3.93234 9.75 2.25 8.06766 2.25 6C2.25 3.93234 3.93234 2.25 6 2.25C8.06766 2.25 9.75 3.93234 9.75 6C9.75 8.06766 8.06766 9.75 6 9.75ZM6 3.75C4.75734 3.75 3.75 4.75734 3.75 6C3.75 7.24266 4.75734 8.25 6 8.25C7.24266 8.25 8.25 7.24266 8.25 6C8.25 4.75734 7.24266 3.75 6 3.75ZM6 7.5C5.17289 7.5 4.5 6.82711 4.5 6C4.5 5.17289 5.17289 4.5 6 4.5C6.82711 4.5 7.5 5.17289 7.5 6C7.5 6.82711 6.82711 7.5 6 7.5Z'
        fill='currentColor'
      />
    </svg>
  );
}
