import { useState, useEffect } from 'react';

import { AudioService } from './index';

export function useAudioService(): [AudioService, { isPlayed: boolean }] {
  const [instance] = useState(() => new AudioService());
  const [isPlayed, setIsPlayed] = useState(false);

  useEffect(() => {
    const unsub = instance.subscribe('isPlayed', setIsPlayed);
    return () => {
      unsub();
    };
  }, [instance]);

  return [instance, { isPlayed }];
}
