const prefix = '@analitycsFilter/';

export const INIT = prefix + 'DIALOGS_INIT';
export const INIT_FULFILLED = prefix + 'DIALOGS_INIT_FULFILLED';
export const INIT_PENDING = prefix + 'DIALOGS_INIT_PENDING';
export const INIT_REJECTED = prefix + 'DIALOGS_INIT_REJECTED';

export const PAGINATE = prefix + 'DIALOGS_PAGINATE';
export const PAGINATE_FULFILLED = prefix + 'DIALOGS_PAGINATE_FULFILLED';
export const PAGINATE_PENDING = prefix + 'DIALOGS_PAGINATE_PENDING';
export const PAGINATE_REJECTED = prefix + 'DIALOGS_PAGINATE_REJECTED';

export const FILTER_SETTINGS = prefix + 'FILTER_SETTINGS';
export const FILTER_SETTINGS_FULFILLED = prefix + 'FILTER_SETTINGS_FULFILLED';
export const FILTER_SETTINGS_PENDING = prefix + 'FILTER_SETTINGS_PENDING';
export const FILTER_SETTINGS_REJECTED = prefix + 'FILTER_SETTINGS_REJECTED';

export const UPDATE_FILTER_PARAMS = prefix + 'UPDATE_FILTER_PARAMS';
export const DROP_FILTER = prefix + 'DROP_FILTER';
export const DATE_CHANGED = prefix + 'DIALOGS_DATE_CHANGED';
export const SELECT_LABELS = prefix + 'SELECT_LABELS';
export const SELECT_MAILING_RESULTS = prefix + 'SELECT_MAILING_RESULTS';
export const SELECT_SESSION_LABELS = prefix + 'SELECT_SESSION_LABELS';
export const SELECT_CHANNEL = prefix + 'DIALOGS_SELECT_CHANNEL';
export const LIVECHAT_CHANGE = prefix + 'LIVECHAT_CHANGE';

//todo remove after removing dialogs
export const SELECT_TAGS = prefix + 'DIALOGS_SELECT_TAGS';

export const SET_FILTER_NLP_SELECT = prefix + 'SET_FILTER_NLP_SELECT';
export const SET_FILTER_IS_INCOMING = prefix + 'SET_FILTER_IS_INCOMING';
export const SET_STR_SEARCH_IN_STORE = prefix + 'SET_STR_SEARCH_IN_STORE';
export const SET_STR_CLIENTS_SEARCH_IN_STORE = prefix + 'SET_STR_CLIENTS_SEARCH_IN_STORE';

export const FILTER_SETTINGS_FAST = prefix + 'FILTER_SETTINGS_FAST';
export const FILTER_SETTINGS_FAST_FULFILLED = prefix + 'FILTER_SETTINGS_FAST_FULFILLED';
export const FILTER_SETTINGS_FAST_PENDING = prefix + 'FILTER_SETTINGS_FAST_PENDING';
export const FILTER_SETTINGS_FAST_REJECTED = prefix + 'FILTER_SETTINGS_FAST_REJECTED';

export const FILTER_SETTINGS_SLOW = prefix + 'FILTER_SETTINGS_SLOW';
export const FILTER_SETTINGS_SLOW_FULFILLED = prefix + 'FILTER_SETTINGS_SLOW_FULFILLED';
export const FILTER_SETTINGS_SLOW_PENDING = prefix + 'FILTER_SETTINGS_SLOW_PENDING';
export const FILTER_SETTINGS_SLOW_REJECTED = prefix + 'FILTER_SETTINGS_SLOW_REJECTED';
