import { t } from 'localization';

import {
  buildCustomValidationResolver,
  composeCustomValidationResolver,
} from 'utils/validator/customValidationResolver';

const checkEmptyName = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (name) return {};
  return { name: t('ThemeCreationMenu:Error:NameField:Empty') };
});

const checkDoubleSlashes = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (!/\/\//.test(name)) return {};
  return { name: t('ThemeCreationMenu:NameField:Error:DoubleSlashes') };
});

const checkDoubleVerticalSlashes = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (!/\|\|/.test(name)) return {};
  return { name: t('ThemeCreationMenu:NameField:Error:DoubleVerticalSlashes') };
});

const checkNameIsBusy = buildCustomValidationResolver<
  { name: string },
  { existedThemes: string[]; existedStates: string[] }
>(async ({ name }, context) => {
  if (!context) throw new Error('Context in validator "checkNameIsBusy" not found');
  const existedTheme = context.existedThemes.find(el => el === name);
  if (existedTheme) {
    if (existedTheme === '/') return { name: t('ThemeCreationMenu:NameField:Error:AlreadyExistRootTheme') };
    return { name: t('ThemeCreationMenu:NameField:Error:AlreadyExist') };
  }
  const existedStates = context.existedStates.filter(el => el === name);
  if (existedStates.length > 0) {
    return { name: t('ThemeCreationMenu:NameField:Error:StateNamesIntersection', { paths: existedStates.join(', ') }) };
  }

  return {};
});

export const themeNameValidation = composeCustomValidationResolver(
  checkEmptyName,
  checkDoubleSlashes,
  checkDoubleVerticalSlashes,
  checkNameIsBusy
);
