var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { SwitchButton, Icon, DropdownMenu, DropdownItem, Dropdown, useToggle, DropdownToggle, Button, useTranslation, } from '@just-ai/just-ui';
import { SkillType } from '@just-ai/api/dist/generated/Caila';
import { useFormatDate, usePromiseProcessing } from '@just-ai/just-ui/dist/utils';
import { useDeParametrizedFunction } from '../../../../nlu-related/utils/hooks';
import CDQAModal from '../../../../views/FAQModal/CDQAModal';
import { useNLUModuleContext } from '../../../../context';
import { HOME_TAB_CODE } from '../../constants';
import RenameSkillModal from '../../Modals/RenameSkillModal';
import DeleteSkillModal from '../../Modals/DeleteSkillModal';
import SimilarsModal from '../../Modals/SimilarsModal';
import InfoCell from './cells/InfoCell';
import TitleCell from './cells/TitleCell';
import EmptyFaqContent from './EmptyFaqContent';
import styles from './styles.module.scss';
function SkillRow({ skill, onRename, toggleActive, onDelete, showAnalytic, isLoading, onClick, copySkill, writeDisabled, }) {
    var _a, _b;
    const onRenameInner = useDeParametrizedFunction(onRename, skill);
    const toggleActiveInner = useDeParametrizedFunction(toggleActive, skill);
    const onDeleteInner = useDeParametrizedFunction(onDelete, skill);
    const showAnalyticInner = useDeParametrizedFunction(showAnalytic, skill);
    const copySkillInner = useDeParametrizedFunction(copySkill, skill.name);
    const onClickInner = useDeParametrizedFunction(onClick, skill.name);
    const [dropDownOpen, , , dropDownToggle] = useToggle(false);
    const { isZfl } = useNLUModuleContext();
    const { t } = useTranslation();
    const updateDate = useFormatDate((_a = skill.updated) === null || _a === void 0 ? void 0 : _a.timestamp, 'd MMMM yyyy HH:mm');
    const createDate = useFormatDate((_b = skill.created) === null || _b === void 0 ? void 0 : _b.timestamp, 'd MMMM yyyy HH:mm');
    return (_jsxs("tr", { tabIndex: 0, "data-test-id": `FAQ.SkillRow.${skill.name}`, onClick: skill.type !== SkillType.Cdqa ? onClickInner : undefined, className: cn({
            [styles.loading]: isLoading,
            [styles.cdqa]: skill.type === SkillType.Cdqa,
        }), children: [_jsx("td", { children: _jsx(TitleCell, { className: cn(styles.titleCell, {
                        [styles.disabled]: !skill.enabled,
                    }), title: skill.name, "data-test-id": `FAQ.SkillRow.${skill.name}.Title` }) }), _jsx("td", { children: skill.updated && (_jsxs(InfoCell, { children: [skill.updated.timestamp && _jsx("span", { children: updateDate }), skill.updated.user && _jsx("span", { children: skill.updated.user })] })) }), _jsx("td", { children: skill.created && (_jsxs(InfoCell, { children: [skill.created.timestamp && _jsx("span", { children: createDate }), skill.created.user && _jsx("span", { children: skill.created.user })] })) }), _jsx("td", { onClick: ev => ev.stopPropagation(), children: _jsx(SwitchButton, { id: `skill-${skill.name}`, "data-test-id": `FAQ.SkillRow.${skill.name}.Toggle`, onChange: toggleActiveInner, value: skill.enabled, disabled: writeDisabled, size: 'sm' }) }), _jsx("td", { children: _jsxs(Dropdown, { isOpen: dropDownOpen, toggle: (event) => {
                        event.stopPropagation();
                        dropDownToggle();
                    }, direction: 'down', children: [_jsx(DropdownToggle, { color: 'secondary', size: 'md', flat: true, withoutPadding: true, compact: true, "data-test-id": `FAQ.SkillRow.${skill.name}.Dropdown`, children: _jsx(Icon, { name: 'farEllipsisH' }) }), _jsxs(DropdownMenu, { right: true, children: [_jsxs(DropdownItem, { className: styles.dropDownElement, onClick: onRenameInner, "data-test-id": `FAQ.SkillRow.${skill.name}.Dropdown.Rename`, disabled: writeDisabled, children: [_jsx(Icon, { name: 'farPen' }), " ", t(skill.type === SkillType.Faq ? 'FAQ:Home:SkillTable:Action:Rename' : 'Edit')] }), skill.type === SkillType.Faq && !isZfl && (_jsxs(DropdownItem, { className: styles.dropDownElement, onClick: showAnalyticInner, "data-test-id": `FAQ.SkillRow.${skill.name}.Dropdown.ViewAnalytics`, children: [_jsx(Icon, { name: 'farPoll' }), " ", t('FAQ:Home:SkillTable:Action:ViewAnalytics')] })), skill.type === SkillType.Cdqa && (_jsx(DropdownItem, { className: styles.dropDownElement, onClick: copySkillInner, "data-test-id": `FAQ.SkillRow.${skill.name}.Dropdown.CopySkill`, children: _jsxs("div", { className: 'dropdown-item--cdqa-copy', children: [_jsx("div", { children: _jsx(Icon, { name: 'farCode' }) }), _jsxs("div", { className: 'mt-1', children: [_jsx("p", { children: t('FAQ:Home:SkillTable:Action:CopySkill') }), _jsx("p", { className: 'text-secondary font-size-12', children: t('FAQ:Home:SkillTable:Action:CopySkillSub') })] })] }) })), _jsxs(DropdownItem, { className: cn(styles.dropDownElement, styles.danger), onClick: onDeleteInner, "data-test-id": `FAQ.SkillRow.${skill.name}.Dropdown.Delete`, disabled: writeDisabled, children: [_jsx(Icon, { name: 'farTrashAlt' }), " ", t('FAQ:Home:SkillTable:Action:Delete')] })] })] }) })] }));
}
const FaqHomeView = ({ skills, loading, onToggle, onRename, onDelete, onSelect, goToAnalytic, onUpdate, }) => {
    const [selectedSkill, setSelectedSkill] = useState();
    const { t } = useTranslation();
    const { actions: { addAlert }, isAccessFunction, isZfl, wrappedHistoryPush, } = useNLUModuleContext();
    const [deleteSkillState, deleteSkillApi] = usePromiseProcessing(() => (selectedSkill ? onDelete(selectedSkill.name) : Promise.resolve()), { deps: [onDelete, selectedSkill] });
    const [renameSkillState, renameSkillApi] = usePromiseProcessing((title) => __awaiter(void 0, void 0, void 0, function* () { return (selectedSkill ? onRename(selectedSkill.name, title, false) : Promise.resolve()); }), { deps: [onRename, selectedSkill] });
    const [updateSkillState, updateSkillApi] = usePromiseProcessing((updateData) => __awaiter(void 0, void 0, void 0, function* () { return (selectedSkill ? onUpdate(selectedSkill.id, updateData) : Promise.resolve()); }), { deps: [onRename, selectedSkill] });
    const [toggleSkillState, toggleSkillApi] = usePromiseProcessing((skillName, value) => __awaiter(void 0, void 0, void 0, function* () { return onToggle(skillName, value); }), { deps: [onToggle] });
    const [goToAnalyticState, goToAnalyticSkillApi] = usePromiseProcessing((skill) => goToAnalytic(skill), {
        deps: [goToAnalytic],
    });
    const [isRenameModalOpen, , , toggleRenameModalOpen] = useToggle(false);
    const [isDeleteModalOpen, , , toggleDeleteModalOpen] = useToggle(false);
    const [isSimilarsModalOpen, , , toggleSimilarsModalOpen] = useToggle(false);
    const onRenameModalOpen = useCallback((skill) => {
        toggleRenameModalOpen();
        setSelectedSkill(skill);
    }, [toggleRenameModalOpen]);
    const onToggleInner = useCallback((skill) => {
        setSelectedSkill(skill);
        toggleSkillApi(skill.name, !skill.enabled);
    }, [toggleSkillApi]);
    const onDeleteInner = useCallback((skill) => {
        toggleDeleteModalOpen();
        setSelectedSkill(skill);
    }, [toggleDeleteModalOpen]);
    const showAnalytic = useCallback((skill) => {
        setSelectedSkill(skill);
        goToAnalyticSkillApi(skill);
    }, [goToAnalyticSkillApi]);
    const copySkillCode = useCallback((skillName) => {
        navigator.clipboard.writeText(`state: NoMatch
    event!: noMatch
    script:
      var result = $caila.cdqaQuery($request.query, "${skillName}", 0.5)
        if (result.predicted){
              $reactions.answer(result.predicted)
          } else {
              $reactions.answer("${t('FAQ:Home:SkillNotFoundAnswer')}")
          }`);
        addAlert &&
            addAlert({
                message: t('FAQ:Home:SkillCopiedText'),
                type: 'info',
                showed: true,
                title: t('FAQ:Home:SkillCopiedTitle'),
                time: Date.now(),
            });
    }, [addAlert, t]);
    const onAddSkill = useCallback(() => {
        wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true, fromTab: HOME_TAB_CODE } });
    }, [wrappedHistoryPush]);
    const goToLL = useCallback(() => {
        wrappedHistoryPush({ url: 'log-labeling', historyState: { addSkill: true, fromTab: HOME_TAB_CODE } });
    }, [wrappedHistoryPush]);
    return (_jsx("div", { className: styles.FaqHomeView, children: _jsxs("div", { className: styles.FaqHomeView__content, children: [_jsx("h1", { className: styles.title, children: t('FAQ:Home:Title') }), (skills === null || skills === void 0 ? void 0 : skills.length) ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.FaqHomeView__addPanel, children: [_jsx(Button, { flat: true, color: 'secondary', onClick: onAddSkill, disabled: !isAccessFunction('BOT_CONTENT_WRITE'), size: 'sm', children: t('FAQ:AddSkill') }), isAccessFunction('nlu.log_labeling') && !isZfl && (_jsx(Button, { flat: true, color: 'secondary', onClick: goToLL, size: 'sm', children: t('FAQ:Home:GoToLL') })), !isZfl && (_jsx(Button, { flat: true, color: 'secondary', onClick: toggleSimilarsModalOpen, size: 'sm', children: t('similarsSettingBtn') }))] }), _jsxs("table", { className: styles.table, style: {
                                '--col-1-width': '100%;',
                                '--col-2-width': '200px',
                                '--col-3-width': '200px',
                                '--col-4-width': '100px',
                                '--col-5-width': '70px',
                            }, children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: t('FAQ:Home:SkillTable:Headers:Title') }), _jsx("th", { children: t('FAQ:Home:SkillTable:Headers:Edited') }), _jsx("th", { children: t('FAQ:Home:SkillTable:Headers:Created') }), _jsx("th", { children: t('FAQ:Home:SkillTable:Headers:Active') }), _jsx("th", {})] }) }), _jsx("tbody", { children: skills.map(skill => (_jsx(SkillRow, { onClick: onSelect, skill: skill, isLoading: (selectedSkill === null || selectedSkill === void 0 ? void 0 : selectedSkill.id) === skill.id &&
                                            (renameSkillState.loading ||
                                                deleteSkillState.loading ||
                                                toggleSkillState.loading ||
                                                updateSkillState.loading ||
                                                goToAnalyticState.loading), copySkill: copySkillCode, toggleActive: onToggleInner, onRename: onRenameModalOpen, onDelete: onDeleteInner, showAnalytic: showAnalytic }, skill.name))) })] }), _jsx(SimilarsModal, { isOpen: isSimilarsModalOpen, onClose: toggleSimilarsModalOpen }), selectedSkill && (_jsxs(_Fragment, { children: [selectedSkill.type === SkillType.Faq ? (_jsx(RenameSkillModal, { isOpen: isRenameModalOpen, skill: selectedSkill, skills: skills, onRename: renameSkillApi, onClose: toggleRenameModalOpen })) : (_jsx(CDQAModal, { isOpen: isRenameModalOpen, toggle: toggleRenameModalOpen, onSubmit: updateSkillApi, existingData: {
                                        name: selectedSkill.name,
                                        skillId: selectedSkill.id,
                                        settings: selectedSkill.settings,
                                    } })), _jsx(DeleteSkillModal, { skill: selectedSkill, isOpen: isDeleteModalOpen, onDelete: deleteSkillApi, onClose: toggleDeleteModalOpen })] }))] })) : !loading ? (_jsx(EmptyFaqContent, { onCreate: onAddSkill })) : null] }) }));
};
export default React.memo(FaqHomeView);
