import { reduxStateReference } from './services/ReduxStateReference';
import { axios } from './pipes/functions';
import { unsetCurrentProject } from './actions/projects.actions';
import { SET_CURRENT_CLIENT } from './constants/currentClient.actions';
import { NEWS_LOCALE_LOAD } from './constants/newsItem.actions';

export function clearCurrentProject() {
  reduxStateReference.getStore().dispatch(unsetCurrentProject());
}

export function getCurrentClient(props) {
  reduxStateReference.getStore().dispatch({
    type: SET_CURRENT_CLIENT,
    payload: axios.get(`/restapi/clients/${props.id}/log/`, { params: props }),
  });
}

export function getNewsLocales() {
  reduxStateReference.getStore().dispatch({
    type: NEWS_LOCALE_LOAD,
    payload: axios.get('/restapi/languages'),
  });
}

export const urlWithoutProjectSidebar = [
  '/projects',
  '/plans',
  '/project-reports',
  '/accountmanager',
  '/imputer',
  '/tagsettings',
  '/tokens',
  '/calllists',
  '/connectionslist',
  '/userlog',
  '/logsmasking',
  '/system',
  '/settings',
];
