import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGraph } from '../JGraphAsyncActions';
import { RootState } from '../../../storeTypes';
import { findScreenByPath } from '../Graph';
import { setEditMenuBlock } from '../index';

export const cailaIntentChange = createAsyncThunk('JGraph/cailaIntentChange', async (value: void, thunkAPI) => {
  await thunkAPI.dispatch(getGraph({}));
  const store = thunkAPI.getState() as RootState;
  if (store.JGraphReducer.editMenuBlock?.screen.path) {
    const currentScreen = findScreenByPath(
      store.JGraphReducer.editMenuBlock?.screen.path,
      store.JGraphReducer.graph.blocks
    );
    if (currentScreen)
      await thunkAPI.dispatch(
        setEditMenuBlock({
          screen: currentScreen,
          jBlockIndex: store.JGraphReducer.editMenuBlock?.jBlockIndex,
          path: store.JGraphReducer.editMenuBlock?.path,
        })
      );
  } else {
    await thunkAPI.dispatch(setEditMenuBlock(undefined));
  }
});
