import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { Icon, ScrollArea, useDebounceFn } from '@just-ai/just-ui';

import { t } from 'localization';

import { getTtsProvidersContent } from './providers';

import styles from './styles.module.scss';
import { ProviderList } from 'modules/TestTtsWidget/api/client';

interface CopyNotificationProps {
  active: boolean;
}
function CopyNotification({ active }: CopyNotificationProps) {
  return (
    <div className={styles.bottomNotification}>
      <div className={styles.bottomNotification__wrapper}>
        <div className={cn(styles.bottomNotification__notification, { [styles.active]: active })}>
          {t('TtsOperatorsInfo:CopyNotificationText')}
        </div>
      </div>
    </div>
  );
}

function useDelayToggle(defaultValue: boolean, time = 300): [boolean, () => void] {
  const [status, setStatus] = useState(defaultValue);
  const reset = useDebounceFn(() => setStatus(defaultValue), time);

  const toggle = useCallback(() => {
    setStatus(true);
    reset();
  }, [reset]);

  return [status, toggle];
}

interface TtsOperatorsInfoProps {
  provider: ProviderList;
}
const TtsOperatorsInfo = ({ provider }: TtsOperatorsInfoProps) => {
  const providerContent = getTtsProvidersContent()[provider];
  const [notified, trigger] = useDelayToggle(false, 1000);

  const copyText = useCallback(
    (text: string) => {
      navigator?.clipboard
        ?.writeText(text)
        .then(trigger)
        .catch(() => {});
    },
    [trigger]
  );

  return (
    <div className={styles.TtsOperatorsInfo}>
      <ScrollArea vertical className='just-ui-custom-scrollbar' style={{ maxHeight: 504 }}>
        <table style={{ width: '100%' }} className={styles.table}>
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap', width: '35%', paddingLeft: 8 }}>{t('TtsOperatorsInfo:Tabs:Tag')}</th>
              <th style={{ whiteSpace: 'nowrap', width: '65%', paddingLeft: 8 }}>
                {t('TtsOperatorsInfo:Tabs:Description')}
              </th>
            </tr>
          </thead>
          <tbody>
            {providerContent.rows.map(row => (
              <tr key={row.term}>
                <td className={styles.table__termRow}>
                  <span onClick={() => copyText(row.term)} className={styles.table__term}>
                    {row.term}
                  </span>
                </td>
                <td>
                  {row.description}
                  {row.example && (
                    <div onClick={() => copyText(row.example)} className={styles.table__example}>
                      {row.example}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollArea>
      <CopyNotification active={notified} />
      <div className={styles.footer}>
        <a href={providerContent.docLink} target='_blank' className={styles.docLink} rel='noreferrer'>
          {t('TtsOperatorsInfo:Documentation')} <Icon style={{ marginLeft: 4 }} size='sm' name='farExternalLink' />
        </a>
      </div>
    </div>
  );
};

TtsOperatorsInfo.displayName = 'TtsOperatorsInfo';

export default React.memo(TtsOperatorsInfo);
