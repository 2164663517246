import {
  CHANGE_CURRENT_LOGIN_ACCOUNT,
  DESELECT_ITEM,
  DROP_CURRENT_LOGIN_ACCOUNT,
  DROP_FILTER,
  FILTER_DATA,
  LOGIN_TO_ACCOUNT,
  LOGOUT_FROM_ACCOUNT,
  NEW_SELECT_FEATURES,
  REMOVE_ADD_ACCOUNT_ALERT,
  REMOVE_EDIT_ACCOUNT_ALERT,
  SELECT_ITEM,
  SET_CURRENT_LOGIN_ACCOUNT,
  DROP_EDIT_ACCOUNT_ERRORS,
  ACCOUNTS_BULK_FEATURES_EDIT,
} from '../../constants/accounts.actions';

import { isInternal, isLoggedIn } from '../../isAccessFunction';
import { axios } from '../../pipes/functions';
import moment from 'moment';

export const select = id => {
  return {
    type: SELECT_ITEM,
    id,
  };
};

export const getCurrentLoginAccount = () => {
  let loggedInAccount,
    loggedInAccountFeatures = null;
  if (isLoggedIn() && isInternal()) {
    try {
      loggedInAccount = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT'));
      loggedInAccountFeatures = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT_FEATURES'));

      if (!loggedInAccount) {
        loggedInAccountFeatures = null;
        loggedInAccount = null;
      }
    } catch (e) {
      loggedInAccount = null;
      loggedInAccountFeatures = null;
    }
  }

  return {
    type: SET_CURRENT_LOGIN_ACCOUNT,
    obj: loggedInAccount,
    features: loggedInAccountFeatures,
  };
};

export const setCurrentLoginAccount = (obj, features) => {
  return {
    type: SET_CURRENT_LOGIN_ACCOUNT,
    obj: obj,
    features: features,
  };
};

export const changeAccount = newAccountId => {
  return {
    type: CHANGE_CURRENT_LOGIN_ACCOUNT,
    payload: axios.get(`/restapi/accounts/${newAccountId}/login`, {
      params: {
        accountId: newAccountId,
      },
    }),
  };
};

export const dropCurrentLoginAccount = () => {
  return {
    type: DROP_CURRENT_LOGIN_ACCOUNT,
  };
};

export const deselect = () => {
  return {
    type: DESELECT_ITEM,
  };
};

export const setFilter = (what, value) => {
  if (!!value && what === 'createDateTo') {
    value.format('HH:mm:ss') === '00:00:00'
      ? value.set({ hour: 23, minute: 59, second: 59 })
      : value.set({ second: 0 });
  }
  let dateFromTimeLimit = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  if (!!value && what === 'createDateFrom') {
    if (value.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
      dateFromTimeLimit = moment();
    }
    if (value.format('x') > moment().format('x')) {
      value = moment();
    }
  }
  return {
    type: FILTER_DATA,
    what,
    value,
    dateFromTimeLimit,
  };
};

export const dropFilter = () => {
  localStorage.removeItem('ACCOUNTS_FILTER_DATA');
  localStorage.setItem('ACCOUNTS_PAGE', 0);

  return {
    type: DROP_FILTER,
  };
};

export const removeAddAccountAlert = key => {
  return {
    type: REMOVE_ADD_ACCOUNT_ALERT,
    key,
  };
};
export const removeEditAccountAlert = key => {
  return {
    type: REMOVE_EDIT_ACCOUNT_ALERT,
    key,
  };
};

export const dropEditAccountErrors = () => {
  return {
    type: DROP_EDIT_ACCOUNT_ERRORS,
  };
};

export const loginToAccount = accountId => {
  return {
    type: LOGIN_TO_ACCOUNT,
    payload: axios.get(`/restapi/accounts/${accountId}/login`, {
      params: {
        accountId: accountId,
      },
    }),
  };
};

export const logoutFromAccount = () => {
  return {
    type: LOGOUT_FROM_ACCOUNT,
    payload: axios.get(`/restapi/accounts/logout`),
  };
};

export const addNewSelectFeatures = (name, data) => {
  return {
    type: NEW_SELECT_FEATURES,
    data: {
      name,
      data,
    },
  };
};

export const accountsBulkFeaturesEditSelected = data => {
  return {
    type: ACCOUNTS_BULK_FEATURES_EDIT,
    payload: axios.post('/restapi/accountsadmin/accounts/bulk-edit/selected', data),
  };
};

export const accountsBulkFeaturesEditAll = data => {
  return {
    type: ACCOUNTS_BULK_FEATURES_EDIT,
    payload: axios.post('/restapi/accountsadmin/accounts/bulk-edit/all', data),
  };
};
