import React, { FunctionComponent, createContext, useContext } from 'react';
import { useLogs } from './useLogs';
import { LogItem, LoggerEnum, LevelEnum, TimeEnum } from '../../model/LogItem';

class LogContextType {
  logItems: LogItem[] = [];
  isFetching: boolean = false;
  startFetching: () => void = () => {};
  stopFetching: () => void = () => {};
  clear: () => void = () => {};

  textFilter: string = '';
  setTextFilter: React.Dispatch<React.SetStateAction<string>> = () => {};

  selectedLoggers: LoggerEnum[] = [];
  selectLoggers: React.Dispatch<React.SetStateAction<LoggerEnum[]>> = () => {};

  selectedLevel?: LevelEnum;
  selectLevel: React.Dispatch<React.SetStateAction<LevelEnum | undefined>> = () => {};

  selectedTime?: TimeEnum;
  selectTime: React.Dispatch<React.SetStateAction<TimeEnum | undefined>> = () => {};
}

export const LogContext = createContext(new LogContextType());

type LogContextProps = {};
export const LogContextProvider: FunctionComponent<LogContextProps> = ({ children }) => {
  return <LogContext.Provider value={useLogs()}>{children}</LogContext.Provider>;
};

export const useLogContext = () => useContext(LogContext);
