import React, { useContext, useCallback, CSSProperties, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useDrop, useDragLayer } from 'react-dnd';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import { TreeNode, DragTypes } from '../../types';
import { TreeContext, TREE_DEPTH_PADDING, TreeNodeViewProps } from '../../index';
import { DragNodeType } from '../Leaf';

import styles from '../../styles.module.scss';

function BranchDefaultView({ expanded, onToggle, node }: TreeNodeViewProps) {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle]
  );

  return (
    <>
      <span className={styles.toggleIcon} onClick={onClick} data-test-id={`Tree.Branch.${node.name}.toggle`}>
        {expanded ? <Icon name='faChevronDown' size='sm' /> : <Icon name='faChevronRight' size='sm' />}
      </span>
      <span className={styles.text}>{node.name}</span>
    </>
  );
}

interface BranchInterface {
  node: TreeNode;
  style?: CSSProperties;
}
const Branch = function ({ node, style }: BranchInterface) {
  const context = useContext(TreeContext);
  const isExpanded = useMemo(() => context.expandedMap[node.id], [context.expandedMap, node.id]);
  const isSelected = useMemo(() => context.selectedIds.includes(node.id), [context.selectedIds, node.id]);
  const isActive = useMemo(() => context.activeId && context.activeId === node.id, [context.activeId, node.id]);
  const containerNodeRef = useRef<HTMLDivElement>(null);

  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      context.handleContextMenu(event, node);
    },
    [context, node]
  );

  const [{ isOver }, drop] = useDrop({
    canDrop: () => !!context.isDndEnabled,
    accept: DragTypes.LEAF,
    drop: (item: DragNodeType) => {
      context.onDragDrop(item.node, node);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const { isDraggingOtherElement } = useDragLayer(monitor => ({
    isDraggingOtherElement: monitor.isDragging(),
  }));

  const depthLevel = node.depthLevel ?? 0;
  const paddingLeft = depthLevel ? depthLevel * TREE_DEPTH_PADDING : '';

  const View = context.treeNodeView ?? BranchDefaultView;

  return (
    <div style={style} ref={containerNodeRef}>
      <div
        ref={drop}
        className={cn(styles.node, {
          [styles.node__groupSelected]: isSelected,
          [styles.disableHover]: isDraggingOtherElement && !isOver,
          [styles.node__elementDisabled]: !node.enabled,
          [styles.node__isOver]: isOver,
          [styles.node__active]: isActive,
        })}
        style={{ paddingLeft }}
        onClick={() => context.onItemClick(node)}
        onDoubleClick={() => context.onItemDoubleClick(node)}
        onContextMenu={onContextMenu}
        data-test-id={`Tree.Branch.${node.name}`}
      >
        <View onToggle={() => context.onExpandToggle(node)} node={node} expanded={isExpanded} />
      </div>
    </div>
  );
};

export default React.memo(Branch);
