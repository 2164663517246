import React, { createContext, FC, useCallback, useContext, useMemo } from 'react';
import './RightSideMenu.scss';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { TCommonBlockProps, useEdit } from './hooks';
import { EditMenuBlock, JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import {
  ReactionsTagNames,
  TagNames,
  TButtonParameters,
  TJBlock,
  TReactionsTagNames,
  TTagParameters,
} from '../../utils/types';
import { t } from 'localization';
import { ActivationsMenuType, AddingMenuType, EventsState, TChangeEventParams, useEvents } from './hooks/EventsHook';
import { useTransitionsMenu } from './hooks/useTransitionsMenu';
import { useAppDispatch, useAppSelector } from '../../../../storeHooks';
import { AppDispatch } from '../../../../storeTypes';
import { CustomTagData } from '../../../Editor/api/client';
import { useCustomTagEdit } from './hooks/useCustomTagEdit';
import { BehaviorSubject, Observable } from 'rxjs';
import { isEditBlockTypeCustomTag } from './utils/common';
import { FooterSaveCustomTag } from './FooterSaveCustomTag';
import { tWithCheck } from '../../../../localization';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { simplifyStateName } from 'modules/JGraph/utils/state';
import { BlockType } from 'modules/JGraph/view/RightSideMenu/types';

export type RightSideMenuContextType = {
  customTags: CustomTagData[];
  editMenuScreen: JStateWithId;
  jBlockIndex?: number;
  path?: string;
  getTitle: () => string | undefined;
  getBreadcrumb: () => string[] | undefined;
  getEditBlockType: () => BlockType;
  cancel: () => unknown;
  isExistStartState: boolean;
  getEditBlockTag: () => TJBlock | undefined;
  setScreenValue: (screen: JStateWithId) => unknown;
  onChangeButtons: (tagData: TButtonParameters, elementIndex: number) => unknown;
  onAddNewButton: () => unknown;
  deleteButton: (elementIndex: number) => unknown;
  setAddingMenuType: (addingMenuType?: AddingMenuType, prefix?: string) => unknown;

  onChangeCommonTag: (obj: TCommonBlockProps) => unknown;
  onSetToEdit?: (index: number) => void;

  setActivationsMenuType: (menuType?: ActivationsMenuType) => unknown;
  cancelAddingEvents: () => unknown;
  selectActivationEvent: (activationEvent?: string) => unknown;

  eventsState: EventsState;
  changeEventParams: (value: TChangeEventParams) => unknown;
  changePattern: (value: string) => unknown;
  changeIntent: (value: string) => unknown;
  setPreventGuideTour: (value: boolean) => unknown;

  saveEvent: () => unknown;
  save: () => unknown;
  savePatterns: () => unknown;
  saveIntent: () => unknown;
  savePatternsFromState: () => unknown;
  saveIntentFromState: () => unknown;
  saveEventFromState: () => unknown;
  savePatternsToState: () => unknown;
  saveIntentToState: () => unknown;
  saveEventToState: () => unknown;
  resetModifications: () => unknown;
  setWasFromNewIntentsMenu: (wasFromNewIntentsMenu: boolean) => unknown;

  setTransitionMenuOpen: (isTransitionMenuOpen: boolean) => unknown;

  isDirty: boolean;
  dispatch: AppDispatch;
  isForCampaign?: boolean;
  isNeedToShowDotsMenu?: boolean;

  customTagEdit: {
    tagParametersObj: Record<string, BehaviorSubject<TTagParameters<string, any>>>;
    onChange: (key: string, value: any) => unknown;
    disabledSave: Observable<boolean>;
    isRequiredNotFilled: (key: string) => boolean;
    isRequired: (key: string) => boolean;
    fieldValue: (key: string) => any;
    save: () => unknown;
    cancel: () => unknown;
  };
  screenCreationMenuOpen: boolean;
};

export const RightSideMenuContext = createContext({} as RightSideMenuContextType);

export const RightSideMenuWrapper = React.memo(() => {
  const { editMenuBlock, customTags, screenCreationMenuOpen } = useAppSelector(state => ({
    editMenuBlock: state.JGraphReducer.editMenuBlock,
    customTags: state.JGraphReducer.customTags,
    screenCreationMenuOpen: state.JGraphReducer.screenCreationMenu.open,
  }));
  if (!editMenuBlock) return null;
  return (
    <RightSideMenu
      editMenuBlock={editMenuBlock}
      customTags={customTags}
      screenCreationMenuOpen={screenCreationMenuOpen}
    />
  );
});

const UNKNOWN_JGRAPH_BLOCK_TITLE = 'some jblock edit';
export const UNKNOWN_JGRAPH_BLOCK_TAG = 'unknown';

export const RightSideMenu: FC<{
  editMenuBlock: EditMenuBlock;
  customTags?: CustomTagData[];
  screenCreationMenuOpen: boolean;
}> = ({ editMenuBlock, customTags = [], screenCreationMenuOpen }) => {
  const {
    isDirty,
    setScreenValue,
    newEditMenuScreen,
    jBlockIndex,
    path,
    save,
    cancel,
    onChangeButtons,
    onAddNewButton,
    deleteButton,
    onChangeCommonTag,
    resetModifications,
  } = useEdit(editMenuBlock);
  const { screens } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
  }));

  const {
    eventsState,
    setAddingMenuType,
    setActivationsMenuType,
    cancelAddingEvents,
    selectActivationEvent,
    changeEventParams,
    saveEvent,
    savePatterns,
    changePattern,
    changeIntent,
    saveIntent,
    savePatternsFromState,
    saveIntentFromState,
    saveEventFromState,
    savePatternsToState,
    saveIntentToState,
    saveEventToState,
    setWasFromNewIntentsMenu,
    setPreventGuideTour,
  } = useEvents();

  const { transitionMenuOpen, setTransitionMenuOpen } = useTransitionsMenu();

  const dispatch = useAppDispatch();

  const { activationsMenuType, pattern, activationIntent, addingMenuType } = eventsState;

  const getTitle = useCallback(() => {
    if (!newEditMenuScreen) return UNKNOWN_JGRAPH_BLOCK_TITLE;
    if (transitionMenuOpen) return t(`RightSideMenu:transitions_settings`);
    if (addingMenuType === 'activations') return t(`RightSideMenu:NewActivation`);
    if (activationsMenuType && pattern.length > 0) {
      return t(`RightSideMenu:activationMenuTitle_pattern_filled`, pattern);
    }
    if (activationsMenuType && !!activationIntent) {
      return t(`RightSideMenu:activationMenuTitle_intent_filled`, activationIntent);
    }
    if (activationsMenuType === 'intent' && addingMenuType === 'toStateEvents') return t(`ActivationItem intent`);
    if (activationsMenuType) return t(`RightSideMenu:activationMenuTitle_${activationsMenuType}`);

    if (path && ScreenBlockPath.isPathRecursive(path)) {
      const block = ScreenBlockPath.getBlockByPathAndIndex(newEditMenuScreen.blocks, path, jBlockIndex);
      if (!block) return UNKNOWN_JGRAPH_BLOCK_TITLE;
      return tWithCheck(`CustomTag:${block.tagName}:caption`) || t(`ReactionItem ${block.tagName}`);
    }

    if (jBlockIndex === undefined || !newEditMenuScreen.blocks) {
      if (!path) return simplifyStateName(newEditMenuScreen.path);
      return UNKNOWN_JGRAPH_BLOCK_TITLE;
    }

    const blockFoundedByIndex = newEditMenuScreen.blocks[jBlockIndex];
    if (!blockFoundedByIndex) return UNKNOWN_JGRAPH_BLOCK_TITLE;

    let tagName = blockFoundedByIndex?.tagName;
    if ([TagNames.else, TagNames.elseif].includes(tagName)) {
      tagName = TagNames.if;
    }
    const tagValue = blockFoundedByIndex?.tagValue;
    const isReaction = ReactionsTagNames().includes(tagName as TReactionsTagNames);
    return (
      tWithCheck(`CustomTag:${tagName}:caption`) ||
      tWithCheck(`${isReaction ? 'ReactionItem' : 'ActivationTitle'} ${tagName}`, tagValue) ||
      tagName
    );
  }, [
    addingMenuType,
    transitionMenuOpen,
    newEditMenuScreen,
    activationsMenuType,
    pattern,
    activationIntent,
    jBlockIndex,
    path,
  ]);

  const getBreadcrumb = useCallback(() => [], []);

  const getEditBlockType = useCallback((): BlockType => {
    if (!newEditMenuScreen) return UNKNOWN_JGRAPH_BLOCK_TAG;
    if (transitionMenuOpen) return 'transitionMenu';
    if (jBlockIndex === undefined && !path) return 'screenBlocksList';

    const isPathHaveRecursive = path && ScreenBlockPath.isPathRecursive(path);
    if (isPathHaveRecursive) {
      const block = ScreenBlockPath.getBlockByPathAndIndex(newEditMenuScreen.blocks, path, jBlockIndex);
      if (!block) return UNKNOWN_JGRAPH_BLOCK_TAG;
      return block.tagName;
    }

    if (jBlockIndex === undefined || !newEditMenuScreen.blocks) return UNKNOWN_JGRAPH_BLOCK_TAG;
    const block = newEditMenuScreen.blocks[jBlockIndex];
    if (!block) return UNKNOWN_JGRAPH_BLOCK_TAG;
    if ([TagNames.else, TagNames.elseif].includes(block.tagName)) {
      return TagNames.if;
    }
    return block.tagName;
  }, [jBlockIndex, newEditMenuScreen, path, transitionMenuOpen]);

  const getEditBlockTag = useCallback(() => {
    if (!newEditMenuScreen || !newEditMenuScreen.blocks) return;
    const isPathHaveRecursive = path && ScreenBlockPath.isPathRecursive(path);
    if (isPathHaveRecursive) {
      const block = ScreenBlockPath.getBlockByPathAndIndex(newEditMenuScreen.blocks, path, jBlockIndex);
      if (!block) return;
      return block;
    }

    if (jBlockIndex === undefined) return;
    return newEditMenuScreen.blocks[jBlockIndex];
  }, [jBlockIndex, newEditMenuScreen, path]);

  const customTagEdit = useCustomTagEdit(
    newEditMenuScreen,
    dispatch,
    cancel,

    jBlockIndex,
    path,

    getEditBlockTag(),
    getEditBlockTag()?.tagParameters
  );

  const isExistStartState = useMemo(
    () =>
      screens.some(screen =>
        screen.blocks.some(block => block.tagName === TagNames.q_ && block.tagValue === '$regex</start>')
      ),
    [screens]
  );

  const editBlockType = getEditBlockType();
  const isNeedToShowDotsMenu = useMemo(
    () =>
      eventsState?.addingMenuType !== 'activations' &&
      editBlockType !== 'transitionMenu' &&
      eventsState?.activationsMenuType !== 'intent' &&
      eventsState?.addingMenuType !== 'toStateEvents',
    [eventsState?.addingMenuType, eventsState?.activationsMenuType, editBlockType]
  );

  return (
    <RightSideMenuContext.Provider
      value={{
        customTags: customTags,
        editMenuScreen: newEditMenuScreen,
        jBlockIndex: jBlockIndex,
        path: path,
        getTitle: getTitle,
        getBreadcrumb: getBreadcrumb,
        getEditBlockType: getEditBlockType,
        getEditBlockTag: getEditBlockTag,
        setPreventGuideTour,
        setScreenValue: setScreenValue,
        onChangeButtons,
        onAddNewButton,
        deleteButton,
        cancel,
        save,
        onChangeCommonTag,
        isExistStartState,
        isNeedToShowDotsMenu,

        setWasFromNewIntentsMenu,
        setAddingMenuType,
        eventsState,
        setActivationsMenuType,
        cancelAddingEvents,
        selectActivationEvent,
        saveEvent,
        changeEventParams,
        savePatterns,
        changePattern,
        changeIntent,
        saveIntent,
        setTransitionMenuOpen,
        savePatternsFromState,
        saveIntentFromState,
        saveEventFromState,
        savePatternsToState,
        saveIntentToState,
        saveEventToState,

        isDirty,
        resetModifications,
        dispatch,

        customTagEdit,
        screenCreationMenuOpen,
      }}
    >
      <div className='JGraph-RightSideMenu' id='jgraph-right-side-menu'>
        <Header />
        <Body />
        {isEditBlockTypeCustomTag(getEditBlockTag(), customTags) ? (
          <FooterSaveCustomTag />
        ) : (
          <Footer isDirty={isDirty} save={save} />
        )}
      </div>
    </RightSideMenuContext.Provider>
  );
};

export const useRightSideMenuContext = () => useContext(RightSideMenuContext);
