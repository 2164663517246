import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { TagNames } from 'modules/JGraph/utils/types';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { getLastPartOfPath } from 'modules/JGraph/utils/themesUtils';

import { findScreenByPath, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { saveScreen } from 'reducers/JGraph.reducer/index';
import { updateState, addNewStateWithSave } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

import createTheme from './createTheme';

const addNewStartState = createAsyncThunk(
  'JGraph/addNewStartState',
  async (
    data: {
      screenPath: string;
      updateExisted?: boolean;
    },
    thunkAPI
  ) => {
    if (!data.screenPath.startsWith('/')) {
      data.screenPath = '/' + data.screenPath;
    }
    let state = thunkAPI.getState() as RootState;
    if (data.updateExisted) {
      const screenToUpdate = findScreenByPath(data.screenPath, state.JGraphReducer.graph.blocks);
      if (!screenToUpdate) return;
      const newState: JStateWithId = {
        ...screenToUpdate,
        blocks: [
          {
            tagName: TagNames.q_,
            tagValue: '$regex</start>',
            tagParameters: [],
            comment: [],
            jblocks: [],
          },
          ...(screenToUpdate?.blocks || []),
        ],
      };
      thunkAPI.dispatch(saveScreen(newState));
      mainSave$.next({
        type: 'update',
        path: screenToUpdate.path,
        action: () => thunkAPI.dispatch(updateState(newState)),
      });
      window.requestAnimationFrame(() => {
        scrollToTargetGlobal$.next({
          targetPathId: screenToUpdate.pathId,
        });
      });
      return;
    }

    const startState = {
      screenPath: data.screenPath,
      theme: '/',
      blocks: [
        {
          tagName: TagNames.q_,
          tagValue: '$regex</start>',
          tagParameters: [],
          comment: [],
          jblocks: [],
        },
      ],
    };

    const rootTheme = state.JGraphReducer.graph.themes.find(theme => theme.value === '/');

    if (!rootTheme) {
      await thunkAPI.dispatch(
        createTheme({
          themeToCreate: { value: startState.theme, x: 0, y: 0 },
          file: {
            filename: state.JGraphReducer.entrypoint.filename,
            lastModification: state.JGraphReducer.graph.files[state.JGraphReducer.entrypoint.filename],
          },
          states: [
            {
              ...startState,
              value: getLastPartOfPath(startState.screenPath),
              x: 0,
              y: 0,
            },
          ],
        })
      );
      scrollToTargetGlobal$.next({
        targetPathId: getValidKonvaName(startState.screenPath),
      });
      return;
    }

    await thunkAPI.dispatch(addNewStateWithSave({ ...startState, setEdit: true }));
  }
);

export default addNewStartState;
