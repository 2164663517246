import { BehaviorSubject, debounceTime, distinctUntilChanged, scan, shareReplay } from 'rxjs';
import { JBlockLocator } from '../../Editor/api/client';

export interface JWidgetMessageLocator extends Omit<JBlockLocator, 'jblocks'> {
  fromStatePath?: string;
  tagStatePath?: string;
  toStatePath?: string;
  toState?: string;
}

class DebugDataPipe {
  lastAction: JWidgetMessageLocator[] = [];
  storedLocators: string[] = [];
}

export const debugDataState$ = new BehaviorSubject<JWidgetMessageLocator[] | 'clear'>([]);
export const debugDataPipe$ = debugDataState$.pipe(
  debounceTime(100),
  distinctUntilChanged((prevValue, newValue) => JSON.stringify(prevValue) === JSON.stringify(newValue)),
  scan((prevState, nextComingValue) => {
    if (nextComingValue === 'clear') {
      return new DebugDataPipe();
    }

    prevState.lastAction = nextComingValue;
    nextComingValue.forEach((nextLocatorValue, index) => {
      if (
        nextLocatorValue.tagStatePath &&
        prevState.storedLocators.length > 0 &&
        nextLocatorValue.tagStatePath !== prevState.storedLocators[prevState.storedLocators.length - 1]
      ) {
        prevState.storedLocators.push(nextLocatorValue.tagStatePath);
      }

      if (nextLocatorValue.tagStatePath && prevState.storedLocators.length === 0) {
        prevState.storedLocators.push(nextLocatorValue.tagStatePath);
      }
      if (nextComingValue.length - 1 === index) {
        const lastToState = (nextLocatorValue.toState || nextLocatorValue.toStatePath) as string;
        if (!!lastToState && lastToState !== prevState.storedLocators[prevState.storedLocators.length - 1]) {
          prevState.storedLocators.push(lastToState);
        }
      }
    });

    return prevState;
  }, new DebugDataPipe()),
  shareReplay(1)
);
