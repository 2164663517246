import React, { FC, useEffect, useRef, useState } from 'react';
import { Path } from 'react-konva';
import Konva from 'konva';

import { rafAppScheduler } from 'utils/sheduler/buildScheduler';
import { getConnectorPath } from 'modules/JGraph/utils/connectionLayerUtils';

import { ThemeConnector, themesConnectorStore$ } from '../ThemesStage/ThemesConnectionsLayer';
import { themePositionBatch$, themePositionState$ } from '../ThemesStage/ThemeCard';

type ThemeConnectorProps = {
  connector: ThemeConnector;
};
export const ThemeConnectorLine: FC<ThemeConnectorProps> = ({ connector }) => {
  const PathRef = useRef<Konva.Path | null>(null);
  const [statePath, setStatePath] = useState<string>('');
  const connectorPathPositions = useRef<string>('');

  const fromNodeRef = useRef<Konva.Node | null | undefined>(null);
  const toNodeRef = useRef<Konva.Node | null | undefined>(null);

  useEffect(() => {
    const sub = themesConnectorStore$.subscribe(store => {
      const stage = PathRef.current?.getStage();
      fromNodeRef.current = store[connector.from]?.fromRef;
      toNodeRef.current = store[connector.to]?.toRef;

      if (!stage || !fromNodeRef.current || !toNodeRef.current) return;
      rafAppScheduler(() => {
        if (!fromNodeRef.current || !toNodeRef.current) return;
        let fromPosition = fromNodeRef.current!.getAbsolutePosition(stage);
        let toPosition = toNodeRef.current!.getAbsolutePosition(stage);
        const stringPositionsValue = JSON.stringify({ from: fromPosition, to: toPosition });
        if (!connectorPathPositions.current || connectorPathPositions.current !== stringPositionsValue) {
          connectorPathPositions.current = stringPositionsValue;
          setStatePath(getConnectorPath(fromPosition, toPosition));
        } else {
          setStatePath(prevState => prevState);
        }
      });
    });
    return () => sub.unsubscribe();
  }, [connector.from, connector.to]);

  useEffect(() => {
    const sub = themePositionState$.subscribe(state => {
      if (state.theme.value !== connector.from && state.theme.value !== connector.to) return;

      const stage = PathRef.current?.getStage();
      if (!stage || !fromNodeRef.current || !toNodeRef.current) return;

      rafAppScheduler(() => {
        const fromPosition = fromNodeRef.current!.getAbsolutePosition(stage);
        const toPosition = toNodeRef.current!.getAbsolutePosition(stage);
        const connectorPath = getConnectorPath(fromPosition, toPosition);
        setStatePath(connectorPath);
      });
    });

    const sub2 = themePositionBatch$.subscribe(batch => {
      const diff = batch.filter(({ themeId }) => [connector.fromPathId, connector.toPathId].includes(themeId));
      if (!diff) return;
      const stage = PathRef.current?.getStage();
      if (!stage || !fromNodeRef.current || !toNodeRef.current) return;

      rafAppScheduler(() => {
        const fromPosition = fromNodeRef.current!.getAbsolutePosition(stage);
        const toPosition = toNodeRef.current!.getAbsolutePosition(stage);
        const connectorPath = getConnectorPath(fromPosition, toPosition);
        setStatePath(connectorPath);
      });
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, [connector]);

  return <Path listening={false} ref={PathRef} data={statePath} stroke='#A6ABAF' strokeWidth={2} opacity={1} />;
};
