import React, { FC, useCallback } from 'react';
import classes from '../../../../settings/ScreenBlocksList/ReactionItem.module.scss';
import classesUnique from './JGraphCailaIntentItem.module.scss';
import classNames from 'classnames';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { TActivationTagNames, TagNames, TReactionsTagNames } from '../../../../../../utils/types';
import { buildIcon, DragHandler, ReactionItemProps } from '../../../../settings/ScreenBlocksList/ReactionItem';
import { t, tWithCheck } from 'localization';
import { tagParametersToObj } from '../../../../../../../../reducers/JGraph.reducer/utils';
import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';

export const JGraphCailaIntentItem: FC<ReactionItemProps> = ({
  block,
  onClick,
  setOpenDelete,
  blockIndex,
  withDrag,
  disableDelete = false,
}) => {
  const setOpenDeleteHandler = useCallback(() => {
    if (setOpenDelete) {
      setOpenDelete(blockIndex);
    }
  }, [blockIndex, setOpenDelete]);

  const { currentBotLanguage, IntentsService } = useJGraphContext();

  if (!block) return null;
  const isSystemIntent = IntentsService.SystemIntents[currentBotLanguage].includes(
    block.tagValue as TReactionsTagNames
  );
  const blockTagParameters = tagParametersToObj(block.tagParameters);

  return (
    <div
      className={classNames(
        'ReactionItemContainer',
        classes.ReactionItemContainer,
        classesUnique.JGraphCailaIntentItemContainer
      )}
    >
      <div
        className={classNames(classes.ReactionItem, classesUnique.JGraphCailaIntentItem, { [classes.active]: onClick })}
        onClick={onClick}
      >
        {withDrag && <DragHandler className={classes.handler} />}

        <div className={classNames(classes.reactionIconContainer, classesUnique.reactionIconContainerCustom)}>
          {isSystemIntent && buildIcon('systemIntent', classes.reactionIcon)}
          {!isSystemIntent && buildIcon(block.tagName as TActivationTagNames, classes.reactionIcon)}
        </div>

        <div className={classNames(classes.nameAndValueContainer, classesUnique.nameAndValueContainer)}>
          <div className={classes.name}>
            {tWithCheck(`EventsGroup:onlyActivations:${block.tagValue}`) ||
              tWithCheck(`ChooseReadyIntent ${block.tagValue}`) ||
              hideRootSlashInPath(block.tagValue || '')}
          </div>
          <JGraphCailaIntentItemValue
            tagName={block.tagName}
            toStateValue={blockTagParameters.toState.value !== './' ? blockTagParameters.toState.value : undefined}
          />
        </div>
        {!disableDelete && (
          <DropdownButton
            className={classes.noBorder}
            direction='down'
            flat
            color='none'
            onClick={e => e.stopPropagation()}
          >
            <DropdownToggle outline iconButton>
              <Icon className='btn-icon' name='farEllipsisH' />
            </DropdownToggle>
            <DropdownMenu>
              {/*<DropdownItem>{t('RightSideMenu:blockActions_duplicate')}</DropdownItem>*/}
              <DropdownItem danger onClick={setOpenDeleteHandler}>
                {t('RightSideMenu:blockActions_delete')}
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
        )}
      </div>
    </div>
  );
};

const JGraphCailaIntentItemValue: FC<{ tagName: TagNames; toStateValue?: string }> = ({ tagName, toStateValue }) => {
  return (
    <div className={classNames(classes.value, classesUnique.value)}>
      {toStateValue ? (
        <>
          <svg width='17' height='8' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='3' cy='4' r='2' stroke='#A6ABAF' />
            <path d='M16.5 4L12.5 1V4V7L16.5 4Z' fill='#A6ABAF' />
            <path
              d='M12.5 4V7L16.5 4L12.5 1V4ZM12.5 4H7.5'
              stroke='#A6ABAF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          {hideRootSlashInPath(toStateValue)}
        </>
      ) : tagName === TagNames.intent ? (
        t('JGraphCailaIntentItemValue:AddPhrasesToIntentPlaceholder')
      ) : (
        t('JGraphCailaIntentItemValue:ChooseTransition')
      )}
    </div>
  );
};
