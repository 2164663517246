import React, { FunctionComponent } from 'react';
import { Locator, ParseError, StorageTypeEnum } from 'modules/Editor/api/client';
import { DEPENDENCY_FILE_PREFIX, useEditorContext } from 'modules/Editor/context/EditorContext';

type Props = {
  item: ParseError;
};

const composeMessage = (item: ParseError) => {
  let parsedError = item.template;
  if (Array.isArray(item.args))
    item.args.forEach((arg, index) => {
      parsedError = parsedError.replace(`{${index}}`, arg);
    });
  return parsedError;
};

const inBounds = (locator: Locator) => locator.col >= 0 && locator.line >= 0;

const getFileId = (locator: Locator) => {
  switch (locator.storageType) {
    case StorageTypeEnum.Git:
    case StorageTypeEnum.System:
      return DEPENDENCY_FILE_PREFIX + locator.filename;
    case StorageTypeEnum.Local:
      return locator.filename;
    default:
      return locator.filename;
  }
};

export const ParseErrorsItem: FunctionComponent<Props> = ({ item }) => {
  const { selectFileId } = useEditorContext();

  const openFile = () =>
    selectFileId(getFileId(item.locator), false, { row: item.locator.line, column: item.locator.col });

  const isDep = item.locator.storageType === StorageTypeEnum.Git || item.locator.storageType === StorageTypeEnum.System;
  return (
    <div className='errors-item' onClick={openFile}>
      <p className='errors-item__locator'>
        {isDep && <span className='errors-item__dependency-flag'>dep</span>}
        {item.locator.filename}
        {inBounds(item.locator) && (
          <>
            [{item.locator.line + 1}:{item.locator.col + 1}]
          </>
        )}
      </p>
      <p className='errors-item__error'> {composeMessage(item)} </p>
    </div>
  );
};
