import React, { FC } from 'react';
import { Group, Text } from 'react-konva';

import { useOnClickBlockDefault } from '../../hooks';

import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';
import { useRenderBlocksContext, RenderBlocks } from './RenderBlocks';
import { TJBlock, TagNames } from 'modules/JGraph/utils/types';
import { ConditionBodyContext } from './ConditionBody';
import { t } from 'localization';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';

export interface ItemBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  blocks: TJBlock[];
  prefix: string;
}

export const RandomBody: FC<ItemBodyProps> = ({ blocks, prefix, globalJBlockIterator, ...rest }) => {
  const { screen, baseWidth } = useRenderBlocksContext();

  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);

  const currentPrefix = ScreenBlockPath.joinIndexToPath(prefix, TagNames.random, globalJBlockIterator);

  return (
    <ConditionBodyContext.Provider value={{ blocks }}>
      <BaseItemBody
        {...rest}
        bottomPadding={4}
        onClick={onClickDefault}
        renderChild={() => {
          if (blocks.length === 0) {
            return (
              <Group x={-12} y={28}>
                <Text
                  x={36}
                  y={4}
                  verticalAlign='top'
                  key={`RandomBlock:${currentPrefix}`}
                  text={t('RandomBody:empty_text')}
                  lineHeight={21 / 14}
                  fontSize={14}
                  letterSpacing={0.02}
                  fill='#888E93'
                />
              </Group>
            );
          }
          return (
            <Group x={-12} y={28}>
              <RenderBlocks
                blocks={blocks}
                baseWidth={baseWidth - 13}
                activeType='background'
                prefix={currentPrefix}
                externalGlobalIndex={globalJBlockIterator}
              />
            </Group>
          );
        }}
      />
    </ConditionBodyContext.Provider>
  );
};
