import { ComponentType } from 'react';

export function downloadTableResultBuilder({ data, headers }: { data: BlobPart; headers: { [key: string]: any } }) {
  data = data as BlobPart;
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  const regexr = /filename="(.+)"/g;
  const suggestedFileName = regexr.exec(headers['content-disposition']);
  link.href = url;
  link.setAttribute('download', (suggestedFileName && suggestedFileName[1]) || 'results_report.xlsx');
  document.body.appendChild(link);
  link.click();
}

export function composeSubComponents<BASE extends ComponentType<any>, SUBCOMPONENTS extends Record<string, any>>(
  baseCmp: BASE,
  subComponents: SUBCOMPONENTS
) {
  return Object.assign(baseCmp, subComponents);
}
