export const NEWS_LOAD = 'NEWS_LOAD';
export const NEWS_LOAD_FULFILLED = 'NEWS_LOAD_FULFILLED';
export const NEWS_LOAD_REJECTED = 'NEWS_LOAD_REJECTED';
export const NEWS_LOAD_PENDING = 'NEWS_LOAD_PENDING';

export const PAGINATE_NEWS = 'PAGINATE_NEWS';
export const PAGINATE_NEWS_FULFILLED = 'PAGINATE_NEWS_FULFILLED';
export const PAGINATE_NEWS_REJECTED = 'PAGINATE_NEWS_REJECTED';
export const PAGINATE_NEWS_PENDING = 'PAGINATE_NEWS_PENDING';

export const LOAD_AVAILABLE_FILTER = 'LOAD_AVAILABLE_FILTER';
export const LOAD_AVAILABLE_FILTER_FULFILLED = 'LOAD_AVAILABLE_FILTER_FULFILLED';
export const LOAD_AVAILABLE_FILTER_REJECTED = 'LOAD_AVAILABLE_FILTER_REJECTED';
export const LOAD_AVAILABLE_FILTER_PENDING = 'LOAD_AVAILABLE_FILTER_PENDING';

export const SET_SELECT = 'SET_SELECT';
export const SET_PARSER_TIME = 'SET_PARSER_TIME';
export const SET_CLIENT_PENDING_TIME = 'SET_CLIENT_PENDING_TIME';

export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_FULFILLED = 'SET_FILTER_FULFILLED';
export const SET_FILTER_REJECTED = 'SET_FILTER_REJECTED';
export const SET_FILTER_PENDING = 'SET_FILTER_PENDING';

export const DROP_FILTER = 'DROP_FILTER';
export const DROP_FILTER_FULFILLED = 'DROP_FILTER_FULFILLED';
export const DROP_FILTER_REJECTED = 'DROP_FILTER_REJECTED';
export const DROP_FILTER_PENDING = 'DROP_FILTER_PENDING';

export const LOAD_NEWS_SETTINGS = 'LOAD_NEWS_SETTINGS';
export const LOAD_NEWS_SETTINGS_FULFILLED = 'LOAD_NEWS_SETTINGS_FULFILLED';
export const LOAD_NEWS_SETTINGS_REJECTED = 'LOAD_NEWS_SETTINGS_REJECTED';
export const LOAD_NEWS_SETTINGS_PENDING = 'LOAD_NEWS_SETTINGS_PENDING';

export const LOAD_NEWS_PERIOD_SETTING = 'LOAD_NEWS_PERIOD_SETTING';
export const LOAD_NEWS_PERIOD_SETTING_FULFILLED = 'LOAD_NEWS_PERIOD_SETTING_FULFILLED';
export const LOAD_NEWS_PERIOD_SETTING_REJECTED = 'LOAD_NEWS_PERIOD_SETTING_REJECTED';
export const LOAD_NEWS_PERIOD_SETTING_PENDING = 'LOAD_NEWS_PERIOD_SETTING_PENDING';

export const SETTINGS_UPDATE_TRIGGER_PERIOD = 'SETTINGS_UPDATE_TRIGGER_PERIOD';
export const SETTINGS_UPDATE_TRIGGER_PERIOD_FULFILLED = 'SETTINGS_UPDATE_TRIGGER_PERIOD_FULFILLED';
export const SETTINGS_UPDATE_TRIGGER_PERIOD_REJECTED = 'SETTINGS_UPDATE_TRIGGER_PERIOD_REJECTED';
export const SETTINGS_UPDATE_TRIGGER_PERIOD_PENDING = 'SETTINGS_UPDATE_TRIGGER_PERIOD_PENDING';

export const SETTINGS_UPDATE_OTHER = 'SETTINGS_UPDATE_OTHER';
export const SETTINGS_UPDATE_OTHER_FULFILLED = 'SETTINGS_UPDATE_OTHER_FULFILLED';
export const SETTINGS_UPDATE_OTHER_REJECTED = 'SETTINGS_UPDATE_OTHER_REJECTED';
export const SETTINGS_UPDATE_OTHER_PENDING = 'SETTINGS_UPDATE_OTHER_PENDING';

export const DELETE_NEWS_ITEM = 'DELETE_NEWS_ITEM';
export const DELETE_NEWS_ITEM_FULFILLED = 'DELETE_NEWS_ITEM_FULFILLED';
export const DELETE_NEWS_ITEM_REJECTED = 'DELETE_NEWS_ITEM_REJECTED';
export const DELETE_NEWS_ITEM_PENDING = 'DELETE_NEWS_ITEM_PENDING';
