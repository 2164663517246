import React, { useCallback, useState, useEffect } from 'react';
import keyboardJS from 'keyboardjs';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon, useToggle } from '@just-ai/just-ui';
import { getActivationsTagNames, ReactionsTagNames, TActivationTagNames, TReactionsTagNames } from '../../utils/types';
import { useRightSideMenuContext } from './index';
import { t } from 'localization';
import { useAppSelector, useAppDispatch } from 'storeHooks';
import {
  deleteBlockInScreenAsync,
  deleteState,
  updateStateInReduxAndApi,
  complexRenameStateInReduxAndApi,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import RenameStateModal from 'modules/JGraph/view/RightSideMenu/Modals/RenameStateModal';
import DeleteStateModal from 'modules/JGraph/view/RightSideMenu/Modals/DeleteStateModal';
import DeleteBlockModal from 'modules/JGraph/view/RightSideMenu/Modals/DeleteBlockModal';
import DeleteGroupModal from 'modules/JGraph/view/RightSideMenu/Modals/DeleteGroupModal';
import keyboardjs from 'keyboardjs';
import ChangeGroupModal from './Modals/ChangeGroupModal';
import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';

import { useCopyToClipboardBlock, useStateBlocksInClipboard, useCopyPasteState } from 'modules/JGraph/utils/clipboard';
import { isMac } from 'utils/platform';
import { callIfAbleToApplyHotKey } from 'utils/hotkeys';

export const DropDownMenu = () => {
  const { screens, themes } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
  }));
  const {
    getEditBlockType,
    editMenuScreen,
    jBlockIndex,
    path,
    setTransitionMenuOpen,
    setAddingMenuType,
    dispatch,
    screenCreationMenuOpen,
    eventsState,
  } = useRightSideMenuContext();
  const { addingMenuType } = eventsState;
  const { isJGraphLite } = useJGraphContext();
  const appDispatch = useAppDispatch();
  const [deleteType, setDeleteType] = useState<'block' | 'state' | 'group' | undefined>(undefined);
  const [isDeleteModalOpened, openDeleteModal, closeDeleteModal] = useToggle(false);
  const [isOpenRenameModal, openRenameModal, closeRenameModal] = useToggle(false);
  const [isOpenChangeGroup, openChangeGroupModal, closeChangeGroupModal] = useToggle(false);

  const deleteHandler = useCallback(() => {
    switch (deleteType) {
      case 'block':
        let deleteIndex = jBlockIndex;
        let deletePath = path;
        if (path && jBlockIndex === undefined) {
          [deleteIndex, deletePath] = ScreenBlockPath.separateIndexFromPath(path);
        }
        if (deleteIndex === undefined) return;
        dispatch(
          deleteBlockInScreenAsync({
            screenId: editMenuScreen.pathId,
            blockIndex: deleteIndex,
            path: deletePath,
          })
        );
        break;
      case 'state':
        dispatch(deleteState({ targetStatePath: editMenuScreen.path, filename: editMenuScreen.filename }));
        break;
    }
    closeDeleteModal();
    setDeleteType(undefined);
  }, [
    deleteType,
    closeDeleteModal,
    jBlockIndex,
    path,
    dispatch,
    editMenuScreen.pathId,
    editMenuScreen.path,
    editMenuScreen.filename,
  ]);

  const openTransitionMenu = useCallback(() => {
    setTransitionMenuOpen(true);
  }, [setTransitionMenuOpen]);

  const hasBlockDropDown =
    ReactionsTagNames().includes(getEditBlockType() as TReactionsTagNames) ||
    getActivationsTagNames(isJGraphLite).includes(getEditBlockType() as TActivationTagNames);
  const hasStateDropDown = getEditBlockType() === 'screenBlocksList';

  const deleteBlockHandler = useCallback(() => {
    setDeleteType('block');
    openDeleteModal();
  }, [openDeleteModal]);

  const copyBlockHandler = useCopyToClipboardBlock(editMenuScreen.blocks, path, jBlockIndex);
  const blocksInClipboard = useStateBlocksInClipboard();

  const pasteBlockHandler = useCallback(async () => {
    if (!blocksInClipboard) return;
    appDispatch(
      updateStateInReduxAndApi({ ...editMenuScreen, blocks: [...editMenuScreen.blocks, ...blocksInClipboard] })
    );
  }, [appDispatch, blocksInClipboard, editMenuScreen]);

  useEffect(() => {
    if (!hasStateDropDown) return;
    keyboardJS.bind(['ctrl + v', 'command + v'], pasteBlockHandler);
    return () => {
      keyboardJS.unbind(['ctrl + v', 'command + v'], pasteBlockHandler);
    };
  }, [hasStateDropDown, pasteBlockHandler]);

  const deleteStateHandler = useCallback(() => {
    setDeleteType('state');
    openDeleteModal();
  }, [openDeleteModal]);

  const openActivationsAddingMenu = useCallback(() => {
    setAddingMenuType('activations');
  }, [setAddingMenuType]);

  const renameState = useCallback(
    (name: string) => {
      appDispatch(
        complexRenameStateInReduxAndApi({
          statePath: editMenuScreen.path,
          newValue: name,
          oldValue: editMenuScreen.value,
        })
      );
      closeRenameModal();
    },
    [appDispatch, closeRenameModal, editMenuScreen]
  );

  useEffect(() => {
    const handler = () => callIfAbleToApplyHotKey(deleteStateHandler);
    if (
      hasStateDropDown &&
      !deleteType &&
      !isOpenRenameModal &&
      !isDeleteModalOpened &&
      !isOpenChangeGroup &&
      !screenCreationMenuOpen &&
      !addingMenuType
    ) {
      keyboardjs.bind(['delete', 'backspace'], handler);
    }
    return () => {
      keyboardjs.unbind(['delete', 'backspace'], handler);
    };
  }, [
    deleteStateHandler,
    deleteType,
    hasStateDropDown,
    isDeleteModalOpened,
    isOpenRenameModal,
    isOpenChangeGroup,
    screenCreationMenuOpen,
    addingMenuType,
  ]);

  const { copyState } = useCopyPasteState();

  return (
    <div className='JGraph-RightSideMenu-header-dropdownWrapper' data-test-id='RightSideMenu:dropdownMenu'>
      {/*TODO screen editing*/}
      {/*<IconButton flat name='farCog' color='secondary' />*/}
      <DropdownButton direction='down'>
        <DropdownToggle color='none' iconButton data-test-id='RightSideMenu:dropdownMenu:toggle'>
          <Icon className='btn-icon' name='farEllipsisH' />
        </DropdownToggle>
        {hasBlockDropDown && (
          <>
            <DropdownMenu>
              {/*TODO duplicate*/}
              {/*<DropdownItem>{t('RightSideMenu:blockActions_duplicate')}</DropdownItem>*/}
              <DropdownItem danger onClick={deleteBlockHandler} data-test-id='RightSideMenu:dropdownMenu:delete'>
                {t('RightSideMenu:blockActions_delete')}
              </DropdownItem>
              <DropdownItem onClick={copyBlockHandler} data-test-id='RightSideMenu:dropdownMenu:copy'>
                {t('RightSideMenu:blockActions:Copy', { cmd: isMac() ? '⌘-C' : 'Ctrl-C' })}
              </DropdownItem>
            </DropdownMenu>
          </>
        )}
        {hasStateDropDown && (
          <>
            <DropdownMenu>
              <DropdownItem onClick={openRenameModal} data-test-id='RightSideMenu:dropdownMenu:rename'>
                {t('RightSideMenu:Actions:Rename')}
              </DropdownItem>
              <DropdownItem
                disabled={!blocksInClipboard}
                onClick={pasteBlockHandler}
                data-test-id='RightSideMenu:dropdownMenu:paste'
              >
                {t('RightSideMenu:Actions:Paste', { cmd: isMac() ? '⌘-V' : 'Ctrl-V' })}
              </DropdownItem>
              <DropdownItem onClick={() => copyState(editMenuScreen)} data-test-id='RightSideMenu:dropdownMenu:copy'>
                {t('RightSideMenu:Actions:Copy', { cmd: isMac() ? '⌘-C' : 'Ctrl-C' })}
              </DropdownItem>
              <DropdownItem onClick={openActivationsAddingMenu} data-test-id='RightSideMenu:dropdownMenu:addActivation'>
                {t('RightSideMenu:AddActivation')}
              </DropdownItem>
              {/*
              @m.podkopaev © "а может вернем потом"
              <DropdownItem onClick={openTransitionMenu} data-test-id='RightSideMenu:dropdownMenu:openTransitionMenu'>
                {t('RightSideMenu:transitions_settings')}
              </DropdownItem>*/}
              <DropdownItem onClick={openChangeGroupModal} data-test-id='RightSideMenu:dropdownMenu:openChangeGroup'>
                {t('RightSideMenu:open_change_group')}
              </DropdownItem>
              <DropdownItem
                danger
                onClick={deleteStateHandler}
                data-test-id='RightSideMenu:dropdownMenu:blockActions_delete'
              >
                {t('RightSideMenu:blockActions_delete')}
              </DropdownItem>
            </DropdownMenu>
          </>
        )}
      </DropdownButton>

      <DeleteStateModal
        isOpen={deleteType === 'state' && isDeleteModalOpened}
        onCancel={closeDeleteModal}
        screen={editMenuScreen}
        onSubmit={deleteHandler}
      />
      <DeleteBlockModal
        isOpen={deleteType === 'block' && isDeleteModalOpened}
        onCancel={closeDeleteModal}
        onSubmit={deleteHandler}
      />
      <DeleteGroupModal
        isOpen={deleteType === 'group' && isDeleteModalOpened}
        onCancel={closeDeleteModal}
        onSubmit={deleteHandler}
      />
      <RenameStateModal
        key={'RenameStateModal:' + editMenuScreen.value}
        screen={editMenuScreen}
        isOpen={isOpenRenameModal}
        onRename={renameState}
        onClose={closeRenameModal}
      />
      <ChangeGroupModal
        key={'ChangeGroupModal:' + editMenuScreen.value}
        screen={editMenuScreen}
        screens={screens}
        themes={themes}
        isOpen={isOpenChangeGroup}
        onClose={closeChangeGroupModal}
      />
    </div>
  );
};
