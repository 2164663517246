import { t } from 'localization';
import React from 'react';

import { ItemMenu } from '../MultiLevelSelect/types';
import { Icon } from '@just-ai/just-ui';

export const getTestOption = (): ItemMenu => ({
  label: t('Header:testActionLabel'),
  value: 'test',
  cantSelect: true,
  children: [
    {
      label: t('Header:startAutoTestsActionLabel'),
      value: 'test:startAutoTests',
      iconCmp: props => <Icon {...props} name='faPlay' color='primary' />,
    },
    {
      label: t('Header:startAutoTestsInBgActionLabel'),
      value: 'test:startAutoTestsInBg',
      iconCmp: props => <Icon {...props} name='faPlay' color='success' />,
    },
    {
      label: t('Header:doNotRunAutotestsActionLabel'),
      value: 'test:doNotRunAutotests',
      iconCmp: props => <Icon {...props} name='faPlay' color='warning' />,
    },
  ],
});

export const getTrainOption = (): ItemMenu => ({
  label: t('Header:trainActionLabel'),
  value: 'train',
  children: [],
});

export const getPublishOption = (): ItemMenu => ({
  label: t('Header:publishActionLabel'),
  value: 'publish',
  cantSelect: true,
  children: [
    {
      label: t('Header:startAutoTestsActionLabel'),
      value: 'publish:startAutoTests',
      iconCmp: props => <Icon {...props} name='faPlay' color='primary' />,
    },
    {
      label: t('Header:startAutoTestsInBgActionLabel'),
      value: 'publish:startAutoTestsInBg',
      iconCmp: props => <Icon {...props} name='faPlay' color='success' />,
    },
    {
      label: t('Header:doNotRunAutotestsActionLabel'),
      value: 'publish:doNotRunAutotests',
      iconCmp: props => <Icon {...props} name='faPlay' color='warning' />,
    },
  ],
});

export function getFlatCanSelectedOptions(options: ItemMenu[], __result: ItemMenu[] = []): ItemMenu[] {
  return options.reduce((acc, option) => {
    if (!option.cantSelect) acc.push(option);
    if (!option?.children?.length) return acc;
    return acc.concat(getFlatCanSelectedOptions(option.children, acc));
  }, __result);
}
