import { JsFormatter } from './JsFormatter';
import { YamlFormatter } from './YamlFormatter';
import { JsonFormatter } from './JsonFormatter';
import { FormatResult } from './BaseFormatter';

export type FormattedLanguages = 'yaml' | 'yml' | 'js' | 'json';

const jsFormatter = new JsFormatter();
const yamlFormatter = new YamlFormatter();
const jsonFormatter = new JsonFormatter();

class AppFormatter {
  format(code: string, lang: FormattedLanguages): Promise<FormatResult> {
    switch (lang) {
      case 'yml':
      case 'yaml':
        return yamlFormatter.format(code);
      case 'js':
        return jsFormatter.format(code);
      case 'json':
        return jsonFormatter.format(code);
      default:
        return Promise.resolve(['', null]);
    }
  }
}

export const codeFormatter = new AppFormatter();
