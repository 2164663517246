import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { TranslationContextProvider } from '@just-ai/just-ui/dist/contexts/transtationContext';
import { faqLocalization } from '@just-ai/nlu-modules/dist/localization/faq.loc';
import '@just-ai/just-ui/fonts/build.fonts.scss';

import localize, { t, tWithCheck } from 'localization';
import store from './store';
import AppErrorBoundary from './components/AppErrorBoundary';
import App from './App';
import './App.scss';
import './sentry';
import { AppContextProvider } from './modules/Caila/components/AppContext';

localize.addTranslations(faqLocalization);

ReactDOM.render(
  <AppErrorBoundary prefix='GlobalRoot'>
    <Provider store={store}>
      <TranslationContextProvider t={t} tWithCheck={tWithCheck} getLocale={localize.getLocale}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </TranslationContextProvider>
    </Provider>
  </AppErrorBoundary>,
  document.getElementById('root')
);
