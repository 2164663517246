import { ProjectApi } from '../api/client';
import { axios } from '../../../pipes/functions';
import { excludeUnavailableTags } from 'isAccessFunction';

export default class ProjectService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private projectId: string;
  private projectApi: ProjectApi;

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
    this.projectApi = new ProjectApi({}, ProjectService.BASE_PATH, axios);
  }

  getCustomTags = async () => {
    const { data: tags } = await this.projectApi.listCustomTags(this.accountId, this.projectId);
    return excludeUnavailableTags(tags);
  };
}
