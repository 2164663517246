import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { Circle } from 'react-konva';
import { activeColor } from '../parts/KIcons';
import Konva from 'konva';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import {
  FromStateConnectionsStore,
  getBlockConnections,
  getGroupStatesConnections,
} from '../../../../reducers/JGraph.reducer/Graph';
import { getStageContextFromRef } from '../../utils/stageUtils';
import { useStatesGroupContext } from './context';

type GroupStateItemTransitionCircleProps = {
  screen: JStateWithId;
  showConnectionsFromChildren: boolean;
  substates: string[];
};

export const GroupStateItemTransitionCircle = forwardRef<Konva.Circle | null, GroupStateItemTransitionCircleProps>(
  ({ screen, showConnectionsFromChildren, substates }, forwardCircleRef) => {
    const CircleRef = useRef<Konva.Circle | null>(null);
    const { mainScreen } = useStatesGroupContext();
    const { path: mainScreenPath } = mainScreen;

    useEffect(() => {
      if (forwardCircleRef) {
        if (typeof forwardCircleRef === 'function') {
          forwardCircleRef(CircleRef.current);
        } else {
          forwardCircleRef.current = CircleRef.current;
        }
      }
    }, [forwardCircleRef]);

    const stateConnectors = useMemo(() => {
      return showConnectionsFromChildren
        ? getGroupStatesConnections(screen)
        : getBlockConnections(screen, new FromStateConnectionsStore({}), screen.blocks)[0];
    }, [screen, showConnectionsFromChildren]);

    useEffect(() => {
      if (stateConnectors.length === 0) return;
      const { connectorsFromPipe$, ConnectorsSubject$, getConnectors } = getStageContextFromRef(CircleRef);
      let aboveConnectors = getConnectors().filter(
        connection => connection.from.includes('fromState') && connection.fromNodeOriginalPath === screen.path
      );

      let valueToPipe: { [key: string]: {} } = {};
      requestAnimationFrame(() => {
        stateConnectors.forEach(stateConnector => {
          if (!stateConnector.from.includes('fromState')) {
            ConnectorsSubject$.next({ type: 'add', connector: stateConnector });
            valueToPipe[stateConnector.from] = {
              fromRef: CircleRef.current,
            };
          }
        });
        aboveConnectors.forEach(stateConnector => {
          ConnectorsSubject$.next({ type: 'add', connector: stateConnector });
          valueToPipe[stateConnector.from] = {
            fromRef: CircleRef.current,
          };
        });
        connectorsFromPipe$.next(valueToPipe);
      });

      return () => {
        stateConnectors.forEach(stateConnector => {
          if (!stateConnector.from.includes('fromState')) {
            ConnectorsSubject$.next({ type: 'remove', connector: stateConnector });

            valueToPipe[stateConnector.from] = {
              fromRef: undefined,
            };
          }
        });
        aboveConnectors.forEach(stateConnector => {
          ConnectorsSubject$.next({ type: 'remove', connector: stateConnector });

          valueToPipe[stateConnector.from] = {
            fromRef: undefined,
          };
        });
        connectorsFromPipe$.next(valueToPipe);
      };
    }, [screen, mainScreenPath, stateConnectors]);

    if (stateConnectors.length === 0) {
      return null;
    }
    return (
      <>
        <Circle
          x={280 - 24 - 2}
          y={10}
          radius={6}
          strokeWidth={1}
          width={12}
          height={12}
          fill='white'
          stroke='#C3C6C9'
          isCollapsed={showConnectionsFromChildren}
          ref={CircleRef}
          name={screen.pathId}
          substates={substates}
        />
        <Circle x={280 - 24 - 2} y={10} fill={activeColor} width={6} height={6} radius={3} />
      </>
    );
  }
);
