import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from 'storeHooks';
import localize, { t } from 'localization';
import { amplitudeInstance } from 'pipes/functions';

import JGraphStartTooltip from './components/JGraphStartTooltip';
import { useSkipGuideTour, SkipGuideTourForeverModal } from './skipGuideTourFeature';
import { JGraphGuideTourLocalization } from './localization/JGraphGuideTour.loc';

import { getElementWithRetries } from './utils';
import { GuideStep, GuideTourActions } from '../../../../components/GuideTourStepper/types';
import tagClasses from '../../components/Tag/Tag.module.scss';
import { useJGraphContext } from '../../contexts/JGraphContext';
import { GuideTourStepper } from 'components/GuideTourStepper';
import { CallBackProps } from 'components/GuideTour';

localize.addTranslations(JGraphGuideTourLocalization);

const getGreetingJGraphTour = (isOnlyRootThemeExist: boolean) => {
  return [
    {
      target: '.editor__editor',
      dataTestId: 'JGraphGuideTour:TourStep_1_initial',
      title: t('JGraphGuideTour:Step:1:Title'),
      content: t('JGraphGuideTour:Step:1'),
      tooltipComponent: JGraphStartTooltip,
      placement: 'center',
      disableBeacon: true,
    },
    {
      beforeStep: () => amplitudeInstance.logEvent('J-Graph Tutorial Started'),
      target: isOnlyRootThemeExist
        ? '[data-test-id="Editor.MainToolbar.Themes"]'
        : '[data-test-id="Editor.MainToolbar.Themes.Globe"]',
      dataTestId: 'JGraphGuideTour:TourStep_Themes_1',
      placement: 'bottom-start',
      offset: 20,
      content: t('JGraphGuideTour:Step:Themes:Start_1'),
      afterContent: <span dangerouslySetInnerHTML={{ __html: t('JGraphGuideTour:Step:Themes:Start_1:Image') }} />,
    },
    {
      target: '[data-test-id="Editor.MainToolbar.Themes.Globe"]',
      dataTestId: 'JGraphGuideTour:TourStep_Themes_2',
      placement: 'bottom-start',
      offset: 20,
      content: t('JGraphGuideTour:Step:Themes:Start_2'),
    },
    {
      target: '[data-test-id="JGToolbar:Search"]',
      dataTestId: 'JGraphGuideTour:TourStep_2_create_screen',
      placement: 'right-start',
      offset: 20,
      styles: {
        spotlight: {
          display: 'none',
        },
        tooltipArrow: {
          opacity: 0,
        },
      },
      content: t('JGraphGuideTour:Step:2'),
      afterContent: <span dangerouslySetInnerHTML={{ __html: t('JGraphGuideTour:Step:2:Image') }} />,
    },
    {
      waitEvent: 'OpenedRightSideMenuWithStartState',
      dataTestId: 'JGraphGuideTour:TourStep_3_menu_open',
      target: '.JGraph-RightSideMenu',
      content: t('JGraphGuideTour:Step:3'),
      placement: 'left',
      offset: 20,
    },
    {
      target: '[data-test-id="RightSideMenu:ScreenBlocksList:ReactionsBlock"] > div',
      dataTestId: 'JGraphGuideTour:TourStep_4_reactions',
      content: t('JGraphGuideTour:Step:4'),
      placement: 'left',
      offset: 20,
    },
    {
      target: '[data-test-id="RightSideMenu:ScreenBlocksList:PhrasesAndEvents"] > div',
      dataTestId: 'JGraphGuideTour:TourStep_5_phrases',
      content: t('JGraphGuideTour:Step:5'),
      placement: 'left',
    },
    {
      target: '[data-test-id="RightSideMenu:FastReactionItems:a"]',
      dataTestId: 'JGraphGuideTour:TourStep_6_its_answer',
      content: t('JGraphGuideTour:Step:6'),
      placement: 'left',
    },
    {
      waitEvent: 'AddedNewBlock::a OpenedRightSideMenuWithStartState',
      target: '[data-test-id="RightSideMenu:FastActivationItems:intent"]',
      dataTestId: 'JGraphGuideTour:TourStep_7_here_you_add_intents',
      content: t('JGraphGuideTour:Step:7'),
      placement: 'top-end',
    },
    {
      beforeStep: async (guideTour: GuideTourActions) => {
        const isUserPhraseExist = await Promise.race([
          getElementWithRetries('[data-test-id="JGraphCailaIntents"] > .ReactionItemContainer:last-of-type', {
            checkInterval: 100,
            timeout: 1000,
          }).then(() => true),
          getElementWithRetries('[data-test-id="JGraphCailaIntentChooseCreation:create"]', {
            checkInterval: 50,
            timeout: 1000,
          }).then(() => false),
        ]).catch(() => {});

        if (!isUserPhraseExist) return;

        const idx = guideTour.getStepIndexByDataTestId('JGraphGuideTour:TourStep_7-3_success_create_phrase');
        idx &&
          guideTour.goToStep(idx, {
            waitEvent: null,
          });
      },
      waitEvent: 'OpenActivationsMenuType:intent',
      target: '[data-test-id="JGraphCailaIntentChooseCreation"]',
      dataTestId: 'JGraphGuideTour:TourStep_7-1_what_is_phrases',
      content: <span dangerouslySetInnerHTML={{ __html: t('JGraphGuideTour:Step:7-1') }} />,
      placement: 'left',
    },
    {
      waitEvent: 'JGraphCailaIntentChooseCreation:ClickCreate',
      dataTestId: 'JGraphGuideTour:TourStep_7-2_enter_client_phrase',
      target: '[data-test-id="JGraphCailaIntentChooseCreation"]',
      content: (
        <div
          dangerouslySetInnerHTML={{ __html: t('JGraphGuideTour:Step:7-2', tagClasses.Tag, tagClasses.secondary) }}
        />
      ),
      placement: 'left',
    },
    {
      waitEvent: 'AddedNewBlock::intent',
      dataTestId: 'JGraphGuideTour:TourStep_7-3_success_create_phrase',
      target: '.ReactionItemContainer:last-of-type',
      content: <span dangerouslySetInnerHTML={{ __html: t('JGraphGuideTour:Step:7-3') }} />,
      placement: 'left',
    },
    {
      waitEvent: 'IntentsActivationEdit:userIntentsLoaded',
      dataTestId: 'JGraphGuideTour:TourStep_7-4_add_more_phrases_to_intent',
      target: '[data-test-id="IntentsActivationEdit:main"]',
      content: t('JGraphGuideTour:Step:7-4'),
      placement: 'left',
    },
    {
      target: '[data-test-id="NewCommonActivationSelectState"]',
      dataTestId: 'JGraphGuideTour:TourStep_7-5_choose_transition_target',
      content: t('JGraphGuideTour:Step:7-5'),
      placement: 'left',
    },
    window.isIFrame
      ? {
          target: '.editor__main-toolbar.visual',
          dataTestId: 'JGraphGuideTour:TourStep_8_launch_widget',
          placement: 'bottom-end',
          content: t('JGraphGuideTour:Step:8-aimy'),
        }
      : {
          waitEvent: 'SaveScreen||RightSideMenu::Cancel',
          dataTestId: 'JGraphGuideTour:TourStep_8_launch_widget',
          target: '#test-publish-train-widget',
          content: t('JGraphGuideTour:Step:8'),
          placement: 'bottom',
        },
    {
      waitEvent: 'TestWidget:Opened',
      target: '[data-test-id="Justwidget"]',
      dataTestId: 'JGraphGuideTour:TourStep_9_widget_opened',
      content: t('JGraphGuideTour:Step:9'),
      placement: 'left',
    },
    {
      waitEvent: 'DebugDataReceived',
      dataTestId: 'JGraphGuideTour:TourStep_10_debug_presentation',
      target: '[data-test-id="JGToolbar:Search"]',
      placement: 'right',
      offset: 20,
      styles: {
        spotlight: {
          display: 'none',
        },
        tooltipArrow: {
          opacity: 0,
        },
      },
      content: t('JGraphGuideTour:Step:10'),
    },
    {
      target: '.editor__editor',
      dataTestId: 'JGraphGuideTour:TourStep_11_final',
      placement: 'center',
      disableOverlay: true,
      beforeStep: () => {
        amplitudeInstance.logEvent('J-Graph Tutorial finished');
      },
      styles: {
        spotlight: {
          display: 'none',
        },
        tooltipArrow: {
          opacity: 0,
        },
      },
      content: t('JGraphGuideTour:Step:11'),
    },
  ].map((step, index, steps) => {
    const nextStep = steps[index + 1];
    if (!nextStep || !nextStep.waitEvent) return step;
    (step as GuideStep).goNextOnNextEvent = nextStep.waitEvent;
    return step;
  }) as GuideStep[];
};

const JGraphGuideTour = function () {
  const { isJGraphLite } = useJGraphContext();
  const hasOnlyRootTheme = useAppSelector(
    state => state.JGraphReducer.graph.themes.length === 1 && state.JGraphReducer.graph.themes[0].value === '/'
  );

  const guideSteps = useMemo(() => {
    return getGreetingJGraphTour(hasOnlyRootTheme);
  }, [hasOnlyRootTheme]);

  const { isShouldShowGuideTourState, skip, skipForever, isShowSkipForeverModal, closeSkipForeverModal } =
    useSkipGuideTour();

  const onSkip = useCallback(
    (data: CallBackProps) => {
      if (data.index === 0) {
        amplitudeInstance.logEvent('J-Graph Tutorial declined');
      } else {
        amplitudeInstance.logEvent('J-Graph Tutorial aborted', {
          'Tutorial step': data.index + 1,
        });
      }
      skip();
    },
    [skip]
  );

  if (!isShouldShowGuideTourState || isJGraphLite) return null;

  return (
    <>
      <GuideTourStepper guideSteps={guideSteps} skip={onSkip} skipForever={skipForever} />

      <SkipGuideTourForeverModal
        isModalOpen={isShowSkipForeverModal}
        onAgree={closeSkipForeverModal}
        onDisagree={skipForever}
      />
    </>
  );
};

export default React.memo(JGraphGuideTour);
