import React, { useRef } from 'react';
import { Text } from 'react-konva';
import Konva from 'konva';
import { useForceUpdate } from '@just-ai/just-ui';
import moment from 'moment';

import { t } from 'localization';

import KonvaTooltip from '../KonvaTooltip';
import { StickerInfo } from '../types';

import styles from './styles.module.scss';

interface AuthorLabelProps {
  sticker: StickerInfo;
  x: number;
  y: number;
}
const AuthorLabel = ({ x, y, sticker }: AuthorLabelProps) => {
  const authorRef = useRef<Konva.Text | null>(null);
  const forceUpdate = useForceUpdate();

  if (!sticker.history) return null;

  const createdAt = moment(sticker.history.createdBy.time);
  const updatedAt = moment(sticker.history.lastUpdatedBy.time);

  return (
    <>
      <Text
        x={x}
        y={y}
        ref={ref => {
          if (authorRef.current) return;
          authorRef.current = ref;
          forceUpdate();
        }}
        width={208}
        height={14}
        text={sticker.history.createdBy.userName || t('AuthorLabel:StickerSadminText')}
        fontFamily='Roboto'
        fontSize={12}
        textDecoration='underline'
        fill='#6A7178'
        lineHeight={14 / 12}
        ellipsis={true}
      />
      <KonvaTooltip target={authorRef.current} trigger='click' placement='bottom-start' mainAxisOffset={8}>
        <div className={styles.AuthorLabel__tooltip}>
          <div>
            <div>{t('JGraph:Stickers:AuthorLabel:Created')}</div>
            <div>{sticker.history.createdBy.userName || t('AuthorLabel:StickerSadminText')}</div>
            <div title={createdAt.format('DD.MM.YYYY HH:mm')}>{createdAt.calendar()}</div>
          </div>
          <div>
            <div>{t('JGraph:Stickers:AuthorLabel:Updated')}</div>
            <div>{sticker.history.lastUpdatedBy.userName || t('AuthorLabel:StickerSadminText')}</div>
            <div title={updatedAt.format('DD.MM.YYYY HH:mm')}>{updatedAt.calendar()}</div>
          </div>
        </div>
      </KonvaTooltip>
    </>
  );
};

export default React.memo(AuthorLabel);
