import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { translate } from './localization';
import { Collapse, Fade } from 'reactstrap';
import { Icon } from '../Icon';
import './AlertNotification.scss';
import { Button } from '../index';
export function AlertNotificationItem({ type, title = '', message, messageComponent, hideMessage, time, buttons, stopped, toggleModal, copyLog, alertsToDisplay, animationType, dark, disableCopyButton, className, temporal, locale = 'ru', alertType, duration, alertsStackCount, }) {
    const ref = React.useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    const [isTextOverflow, setIsTextOverflow] = useState(false);
    useEffect(() => {
        const slideOutTimer = setTimeout(() => {
            if (ref && ref.current) {
                ref.current.classList.remove('slideOutLeft');
            }
        }, 500);
        return () => clearTimeout(slideOutTimer);
    }, []);
    useEffect(() => {
        if (!stopped) {
            const timer = setTimeout(() => {
                if ((type !== 'error' && type !== 'warning') || temporal)
                    dismissAlert();
            }, duration || 3500);
            return () => clearTimeout(timer);
        }
    }, [stopped, alertsToDisplay, duration]);
    const dismissAlert = () => {
        ref && ref.current && ref.current.classList.add('notification-hidden');
        setIsOpen(false);
        setTimeout(() => {
            hideMessage && hideMessage(time);
        }, 250);
    };
    const checkTextOverflow = (flag) => {
        setIsTextOverflow(flag);
    };
    const copyLogAndHide = () => () => {
        copyLog && typeof message === 'string' && copyLog(message);
        dismissAlert();
    };
    if (alertType === 'toasts')
        return (_jsx(AnimationComponent, { isOpen: isOpen, animationType: animationType, children: _jsxs("div", { ref: ref, "data-test-id": 'AlertNotification.NotificationContainer', className: classNames('notification', 'slideOutTop', { 'dark-theme': dark }, className), children: [renderIcon(type, alertType), _jsx("div", { className: classNames('notification-body', {
                            'dark-theme': dark,
                        }), children: _jsx(NotificationMessage, { message: message, MessageComponent: messageComponent, checkTextOverflow: checkTextOverflow }) }), _jsx("div", { className: 'notification-close', "data-test-id": 'AlertNotification.DeleteButton', onClick: dismissAlert, children: _jsx(Icon, { name: 'falTimes', color: 'secondary', style: dark ? { color: '#E4E5E6' } : undefined }) })] }) }));
    return (_jsx(AnimationComponent, { isOpen: isOpen, animationType: animationType, children: _jsxs("div", { ref: ref, "data-test-id": 'AlertNotification.NotificationContainer', className: classNames('notification', animationType === 'slideIn' ? 'slideOutLeft' : '', { 'dark-theme': dark }, className), children: [_jsx("div", { className: 'notification-close', "data-test-id": 'AlertNotification.DeleteButton', onClick: dismissAlert, children: _jsx(Icon, { name: 'faTimes', color: 'secondary', size: 'sm', style: dark ? { color: '#E4E5E6' } : undefined }) }), _jsxs("div", { className: classNames('notification-header', {
                        'no-title': !title,
                        'dark-theme': dark,
                    }), children: [renderIcon(type), title && _jsx("div", { className: 'title', children: title })] }), _jsxs("div", { className: classNames('notification-body', {
                        'no-title': !title,
                        'dark-theme': dark,
                    }), children: [_jsx(NotificationMessage, { message: message, MessageComponent: messageComponent, checkTextOverflow: checkTextOverflow }), (buttons || isTextOverflow || type === 'error') && (_jsxs("div", { className: 'notification-buttons', children: [isTextOverflow &&
                                    !buttons &&
                                    renderButton(type, time, {
                                        buttonText: translate('Show log', locale),
                                        buttonAction: () => (toggleModal ? toggleModal(time) : null),
                                        'data-test-id': 'Notification.ViewLog',
                                    }), type === 'error' && !disableCopyButton && (_jsx(Button, { color: 'danger', size: 'sm', "data-test-id": 'Notification.Copy', outline: isTextOverflow, onClick: copyLogAndHide(), children: translate('Copy', locale) })), buttons === null || buttons === void 0 ? void 0 : buttons.map((button, index) => renderButton(type, time, button, index))] }))] }), alertsStackCount && alertsStackCount > 1 && (_jsx("span", { className: 'notification-stack-count', children: alertsStackCount }))] }) }));
}
const AnimationComponent = ({ children, isOpen, animationType, }) => animationType === 'slideIn' ? _jsx(Collapse, { isOpen: isOpen, children: children }) : _jsx(Fade, { in: isOpen, children: children });
export const NotificationMessage = ({ message, MessageComponent, checkTextOverflow }) => {
    const ref = React.createRef();
    useLayoutEffect(() => {
        if (ref && ref.current) {
            const notificationMessage = ref.current;
            if (notificationMessage.scrollHeight > notificationMessage.clientHeight) {
                checkTextOverflow(true);
            }
        }
    }, []);
    if (MessageComponent) {
        return (_jsx("div", { ref: ref, className: 'notification-message', children: _jsx(MessageComponent, {}) }));
    }
    return (_jsx("div", { ref: ref, title: message, className: 'notification-message', children: message }));
};
function renderButton(type, time, button, index = 0) {
    let color;
    switch (type) {
        case 'success':
            color = 'success';
            break;
        case 'info':
            color = 'primary';
            break;
        case 'error':
            color = 'danger';
            break;
        case 'warning':
            color = 'warning';
            break;
        default:
            color = 'primary';
            break;
    }
    return (_jsx(Button, { onClick: button.buttonAction, color: color, size: 'sm', outline: !button.buttonFill && (index !== 0 || type === 'error'), "data-test-id": button['data-test-id'], children: button.buttonText }, `actionButton_${button.buttonText}_${time}`));
}
function renderIcon(type, alertType) {
    let name;
    let color;
    switch (type) {
        case 'success':
            name = alertType === 'alerts' ? 'faCheckSquare' : 'faCheckCircle';
            color = 'success';
            break;
        case 'info':
            name = 'faInfoCircle';
            color = 'primary';
            break;
        case 'error':
            name = 'faExclamationCircle';
            color = 'danger';
            break;
        case 'warning':
            name = 'faExclamationTriangle';
            color = 'warning';
            break;
        default:
            name = 'faInfoCircle';
            color = 'primary';
            break;
    }
    return _jsx(Icon, { name: name, color: color });
}
