import React, { KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import { AddingPhraseInput } from '../../../../../../Caila/pages/IntentsPage/IntentItemAddingInput';
import { useAppContext } from '../../../../../../Caila/components/AppContext';
import { IntentItem, IntentItemType } from '../../../../../../Caila/model';
import { toIntentItem } from '../../../../../../Caila/utils/intents';
import { convertToPhraseTextArray } from './utils';
import { useJGraphContext } from '../../../../../contexts/JGraphContext';
import { SimilarPhraseData } from '../../../../../../Caila/api/client';
import 'modules/Caila/pages/IntentsPage/style.scss';
import { AppLogger } from 'services/AppLogger';
import ValidationErrorPanel from '@just-ai/nlu-modules/dist/views/FAQTreePage/components/IntentFaqEditView/components/ValidationErrorPanel';

type IntentDetailNewItemProps = {
  valErrorData: SimilarPhraseData[];
  onAddPhrases: (intents: IntentItem[], softValidate?: boolean) => Promise<unknown>;
  clearValError: () => void;
};

export function IntentDetailNewItem({ onAddPhrases, valErrorData, clearValError }: IntentDetailNewItemProps) {
  const { currentProject, appConfig } = useAppContext();
  const { IntentsService } = useJGraphContext();
  const [itemType, setItemType] = useState<IntentItemType>(IntentItemType.phrase);
  const [newItemText, setNewItemText] = useState('');
  const [paraphraseCache, setParaphraseCache] = useState('');

  const [paraphraseLoading, setParaphraseLoading] = useState(false);
  const [paraphraseVariants, setParaphraseVariants] = useState<string[]>([]);
  const rerollRef = useRef<HTMLElement | null>(null);
  const loadingRef = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef(null);

  const isValErrorInNewItem = useMemo(() => {
    return valErrorData?.some(phrase => phrase.origin === newItemText);
  }, [valErrorData, newItemText]);

  const addPhrases = useCallback(
    (event?: React.SyntheticEvent<HTMLButtonElement, MouseEvent>, softValidate?: boolean) => {
      if (!newItemText) return Promise.resolve();
      const phrasesArray: IntentItem[] = convertToPhraseTextArray(newItemText).map(text => {
        return toIntentItem(text, itemType);
      });
      return onAddPhrases(phrasesArray, softValidate).then(() => {
        setNewItemText('');
      });
    },
    [itemType, newItemText, onAddPhrases]
  );
  const paraphraseButtonCondition = useMemo(
    () =>
      itemType === IntentItemType.phrase &&
      !paraphraseLoading &&
      !isValErrorInNewItem &&
      appConfig.paraphrase?.enabled &&
      (currentProject?.language?.toLowerCase() === 'ru' || currentProject?.language?.toLowerCase() === 'en'),
    [appConfig.paraphrase?.enabled, currentProject?.language, isValErrorInNewItem, itemType, paraphraseLoading]
  );
  const paraphraseClick = useCallback(async () => {
    if (!appConfig.paraphrase.enabled) return;
    if (paraphraseVariants.length > 0) {
      const localVariants = [...paraphraseVariants];
      setNewItemText(localVariants.shift()!);
      setParaphraseVariants(localVariants);
      return;
    }
    setParaphraseLoading(true);
    try {
      const paraphraserResponse = await IntentsService.getParaphrasedItems(
        paraphraseCache,
        currentProject?.language?.toLowerCase()
      );
      const phrasesArr = paraphraserResponse.texts_list[0].values;
      setNewItemText(phrasesArr.shift() || '');
      setParaphraseVariants(phrasesArr);
    } catch (error) {
      AppLogger.error({
        message: `Error during paraphrasing`,
        exception: error as Error,
      });
    } finally {
      setParaphraseLoading(false);
    }
  }, [IntentsService, appConfig, currentProject?.language, paraphraseCache, paraphraseVariants]);

  const handleTextInput = useCallback(
    (value: string) => {
      setNewItemText(value.trimStart());
      if (isValErrorInNewItem) {
        clearValError();
      }
      setParaphraseVariants([]);
      setParaphraseCache(value.trim());
    },
    [clearValError, isValErrorInNewItem]
  );

  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;
      event.preventDefault();
      addPhrases();
    },
    [addPhrases]
  );

  return (
    <>
      <AddingPhraseInput
        switchType={() =>
          setItemType(itemType === IntentItemType.phrase ? IntentItemType.pattern : IntentItemType.phrase)
        }
        itemType={itemType}
        paraphraseLoading={paraphraseLoading}
        editDisabled={false}
        addPhrases={addPhrases}
        paraphraseButtonCondition={paraphraseButtonCondition}
        paraphraseClick={paraphraseClick}
        newItemText={newItemText}
        appConfig={appConfig}
        rerollRef={rerollRef}
        tooltipContent={undefined}
        onChange={handleTextInput}
        onKeyDown={onKeyPress}
        valErrorInNewItem={Boolean(isValErrorInNewItem)}
        iconRef={iconRef}
        loadingRef={loadingRef}
      />
      {isValErrorInNewItem && (
        <div className='my-4'>
          <ValidationErrorPanel
            usageArea='intent'
            type='questions'
            updateWithoutValidation={() => {
              addPhrases(undefined, false);
            }}
            validationErrorData={valErrorData}
          />
        </div>
      )}
    </>
  );
}
