import React, { FC } from 'react';

export function generateContextFromHook<DATA>(hook: () => DATA) {
  const Context = React.createContext({} as DATA);
  const Provider: FC = ({ children }) => <Context.Provider value={hook()}>{children}</Context.Provider>;
  return {
    Provider,
    useContext: () => React.useContext(Context),
  };
}
