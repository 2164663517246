import { Ace } from 'ace-builds';

import { CompletionType } from './BaseCompleter';

type ParamTypeEnum = 'bool' | 'string';
type ParamType = { name: string; type: ParamTypeEnum; defaultValue?: string };

export class TagParamsCompleter {
  private paramsMap: Record<string, ParamType[]> = {
    'a:': [
      { name: 'htmlEnabled', type: 'bool' },
      { name: 'html', type: 'string' },
      { name: 'tts', type: 'string' },
      { name: 'ttsEnabled', type: 'bool' },
      { name: 'lang', type: 'string' },
      { name: 'alexaVoiceEnabled', type: 'bool' },
      { name: 'alexaVoice', type: 'string' },
    ],
    'q:': [
      { name: 'fromState', type: 'string' },
      { name: 'toState', type: 'string' },
      { name: 'onlyThisState', type: 'bool', defaultValue: '' },
    ],
    'intent:': [
      { name: 'fromState', type: 'string' },
      { name: 'toState', type: 'string' },
      { name: 'onlyThisState', type: 'bool', defaultValue: '' },
    ],
    'intentGroup:': [
      { name: 'fromState', type: 'string' },
      { name: 'toState', type: 'string' },
      { name: 'onlyThisState', type: 'bool', defaultValue: '' },
    ],
    'event:': [
      { name: 'fromState', type: 'string' },
      { name: 'toState', type: 'string' },
      { name: 'onlyThisState', type: 'bool', defaultValue: '' },
    ],
    'state:': [
      { name: 'sessionResult', type: 'string' },
      { name: 'sessionResultColor', type: 'string' },
      { name: 'noContext', type: 'bool', defaultValue: '' },
      { name: 'modal', type: 'bool', defaultValue: '' },
    ],
    'theme:': [
      { name: 'noContext', type: 'bool' },
      { name: 'modal', type: 'bool' },
    ],
    'audio:': [{ name: 'name', type: 'string' }],
    'video:': [{ name: 'name', type: 'string' }],
  };

  private typeToDefault: Record<ParamTypeEnum, string> = {
    string: '""',
    bool: 'false',
  };

  private paramValuesByType: Record<ParamTypeEnum, string[]> = {
    string: ['""'],
    bool: ['true', 'false'],
  };

  constructor(private name: string) {}

  public isApplicable = (tokensBeforePosition: Ace.Token[]) => {
    const onlyParamsTokens = this.getParamsTokens(tokensBeforePosition);
    return this.canMakeNewParam(onlyParamsTokens) || this.canSuggestParameter(onlyParamsTokens);
  };

  public getCompletions = (
    tag: string,
    position: Ace.Point,
    session: Ace.EditSession,
    tokensBeforePosition: Ace.Token[],
    tokensInRow: Ace.Token[]
  ): CompletionType => {
    const newParamValueCompletion = this.getCompletionsForParamsValue(tag, tokensBeforePosition);
    if (newParamValueCompletion.length) return newParamValueCompletion;
    return this.getCompletionsNewParam(tag, position, session, tokensBeforePosition, tokensInRow);
  };

  private getParamsTokens = (tokensBeforePosition: Ace.Token[]) => {
    return tokensBeforePosition.slice(tokensBeforePosition.findIndex(el => el.value === '||') + 1);
  };

  private getCompletionsNewParam = (
    tag: string,
    position: Ace.Point,
    session: Ace.EditSession,
    tokensBeforePosition: Ace.Token[],
    tokensInRow: Ace.Token[]
  ): CompletionType => {
    const onlyParamsTokens = this.getParamsTokens(tokensBeforePosition);
    if (!this.canMakeNewParam(onlyParamsTokens)) return [];
    const lastToken = tokensBeforePosition[tokensBeforePosition.length - 1];
    if (
      !lastToken ||
      (!lastToken.value.endsWith('||') && !lastToken.value.endsWith(' ') && !lastToken.value.endsWith(','))
    ) {
      return [];
    }

    const definedParams = tokensInRow.filter(token => token.type === 'identifier').map(token => token.value);
    const allowedParams = (this.paramsMap[tag] || []).filter(param => !definedParams.includes(param.name));

    let appendText = tokensInRow.length === tokensBeforePosition.length ? '' : ',';
    const textAfterPosition = session.getLine(position.row).slice(position.column);
    if (textAfterPosition && !textAfterPosition.startsWith(' ')) {
      appendText += ' ';
    }

    return allowedParams.map(param => {
      return {
        value: this.buildSuggestByParam(param) + appendText,
        name: param.name,
        score: 10000,
        meta: this.name,
      };
    });
  };

  private getCompletionsForParamsValue = (tag: string, tokensBeforePosition: Ace.Token[]): CompletionType => {
    const onlyParamsTokens = this.getParamsTokens(tokensBeforePosition);
    if (!this.canSuggestParameter(onlyParamsTokens)) return [];

    const lastTokens = onlyParamsTokens.slice(onlyParamsTokens.length - 2);
    if (lastTokens[0]?.type !== 'identifier' || lastTokens[1]?.type !== 'keyword.operator') return [];
    const paramName = lastTokens[0].value;

    const allowedParams = this.paramsMap[tag]?.find(param => paramName === param.name);
    if (!allowedParams) return [];

    const typeValues = this.paramValuesByType[allowedParams.type];
    return typeValues.reverse().map((param, i) => {
      return {
        value: param,
        name: param,
        score: 10000 + i,
        meta: this.name,
      };
    });
  };

  private buildSuggestByParam(param: ParamType) {
    return `${param.name} = ${param.defaultValue ?? this.typeToDefault[param.type]}`;
  }

  private canMakeNewParam(paramsTokens: Ace.Token[]): boolean {
    const sequence = [/identifier/, /keyword.operator/, /constant.language\..+/];
    const ignoreTokensPattern = /text/;
    return this.isSequence(paramsTokens, sequence, ignoreTokensPattern);
  }

  private canSuggestParameter(paramsTokens: Ace.Token[]): boolean {
    const sequence = [/identifier/, /keyword.operator/];
    const lastTokens = paramsTokens.slice(paramsTokens.length - sequence.length);
    return this.isSequence(lastTokens, sequence);
  }

  private isSequence(paramsTokens: Ace.Token[], sequence: RegExp[], ignore?: RegExp) {
    let seqCursor = 0;
    for (const token of paramsTokens) {
      if (sequence[seqCursor].test(token.type)) {
        seqCursor = (seqCursor + 1) % sequence.length;
        continue;
      }
      if (ignore?.test(token.type)) continue;
      return false;
    }
    return seqCursor === 0;
  }
}
