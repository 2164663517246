import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import {
  FormGroup,
  Label,
  SwitchButton,
  Button,
  InlineRadioButton,
  Option,
  TextAreaLikeElement,
} from '@just-ai/just-ui';

import localize, { t } from 'localization';

import { tagParametersToObj } from 'reducers/JGraph.reducer/utils';
import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { JGLocalization } from 'modules/JGraph/localization/JG.loc';
import { TagNames, TJBlock } from 'modules/JGraph/utils/types';
import { WysiwygEditor } from 'modules/JGraph/components/Wysiwyg';
import {
  addNewBlockInReduxAndApi,
  deleteBlockInScreenAsync,
  migrateTextToRandomInReduxAndApi,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { useRightSideMenuContext } from '../..';

import styles from './styles.module.scss';
import BotSayTab from './BotSayTab';

localize.addTranslations(JGLocalization);

const getValueForHtml = (answerToEdit: TJBlock<TagNames.a>) => {
  return (
    (answerToEdit?.tagParameters.find(tagParameter => tagParameter.name === 'html')?.value as string) ||
    answerToEdit.tagValue
  );
};

const migrateTextToRandom = async (
  dispatch: any,
  editMenuScreenPathId: string,
  isDirty: boolean,
  jBlockIndex = 0,
  path?: string,
  answerToEdit?: TJBlock<TagNames.a>
) => {
  if (!isDirty || !answerToEdit) return;
  await dispatch(deleteBlockInScreenAsync({ screenId: editMenuScreenPathId, path: path, blockIndex: jBlockIndex }));
  await dispatch(addNewBlockInReduxAndApi({ tagName: TagNames.random, prefix: path, insertPosition: jBlockIndex }));
  dispatch(migrateTextToRandomInReduxAndApi({ block: answerToEdit, prefix: path, blockIndex: jBlockIndex }));
};

export const AnswerSettings: FC<{}> = () => {
  const {
    getEditBlockTag,
    onChangeCommonTag,
    isDirty,
    isForCampaign,
    dispatch,
    editMenuScreen,
    jBlockIndex,
    path,
    setPreventGuideTour,
  } = useRightSideMenuContext();
  const answerToEdit = useMemo(() => {
    return getEditBlockTag() as TJBlock<TagNames.a>;
  }, [getEditBlockTag]);
  const htmlInputRef = useRef<HTMLDivElement>(null);
  const htmlValue = useRef(getValueForHtml(answerToEdit));

  const isBlockInRandom = path?.endsWith('random_');
  const [isHintShowed, setIsHintShowed] = useState(() => (isBlockInRandom ? false : !answerToEdit.tagValue));

  useEffect(() => {
    setIsHintShowed(isBlockInRandom ? false : !answerToEdit.tagValue);
    //@ts-ignore DO NOT include answerToEdit.tagValue in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockInRandom]);

  useEffect(() => {
    htmlValue.current = getValueForHtml(answerToEdit);
    if (htmlInputRef.current) htmlInputRef.current.innerHTML = htmlValue.current || '';
  }, [answerToEdit]);

  let isHtmlEnabled = false;
  const foundParam = (answerToEdit?.tagParameters || []).find(tagParameter => tagParameter.name === 'htmlEnabled');
  if (foundParam && foundParam.name === 'htmlEnabled') {
    isHtmlEnabled = foundParam.value || false;
  }

  const changeHtml = useCallback(
    (value: string) => {
      const tagParams = tagParametersToObj(answerToEdit.tagParameters);
      htmlValue.current = value;
      tagParams.html.value = value;
      onChangeCommonTag({
        tagValue: htmlInputRef.current?.innerText,
        tagParameters: Object.values(tagParams),
      });
    },
    [answerToEdit.tagParameters, onChangeCommonTag]
  );

  const changeHtmlEnabled = useCallback(
    (value: boolean) => {
      const tagParams = tagParametersToObj(answerToEdit.tagParameters);
      tagParams.htmlEnabled.value = value;
      tagParams.html.value = htmlValue.current;

      onChangeCommonTag({
        tagValue: htmlInputRef.current?.innerText,
        tagParameters: Object.values(tagParams),
      });
    },
    [answerToEdit.tagParameters, onChangeCommonTag]
  );

  const changeText = useCallback(
    (value: string) => {
      htmlValue.current = value.replace(/\n/g, '<br>');

      const tagParams = tagParametersToObj(answerToEdit.tagParameters);
      if (tagParams.html && tagParams.html.value !== null) {
        tagParams.html.value = htmlValue.current;
      }
      onChangeCommonTag({
        tagValue: value,
        tagParameters: Object.values(tagParams),
      });
    },
    [answerToEdit.tagParameters, onChangeCommonTag]
  );

  const changeSayText = useCallback(
    (value: string) => {
      const tagParams = tagParametersToObj(answerToEdit.tagParameters);
      tagParams.tts.value = value || null;
      onChangeCommonTag({
        tagValue: answerToEdit.tagValue,
        tagParameters: Object.values(tagParams),
      });
    },
    [answerToEdit.tagParameters, answerToEdit.tagValue, onChangeCommonTag]
  );

  const createRandomAnswer = useCallback(() => {
    migrateTextToRandom(dispatch, editMenuScreen.pathId, isDirty, jBlockIndex, path, answerToEdit);
  }, [dispatch, editMenuScreen.pathId, isDirty, jBlockIndex, path, answerToEdit]);

  const [answerType, setAnswerType] = useState('write');

  const onTabClick = useCallback(
    (name: string, value: Option) => {
      setPreventGuideTour(value.value === 'say');
      setAnswerType(value.value as string);
    },
    [setPreventGuideTour]
  );

  const ttsValue = useMemo(() => {
    return (answerToEdit?.tagParameters.find(el => el.name === 'tts')?.value as string) ?? '';
  }, [answerToEdit?.tagParameters]);

  return (
    <div className={cn('JGraph-RightSideMenu-commonContainer', styles.AnswerSettings)}>
      {isHintShowed && !isForCampaign && (
        <Banner
          type={BannerType.INFO}
          title={t('AnswerSettings:RandomHint:Title')}
          onClose={() => setIsHintShowed(false)}
          content={
            <>
              <div
                className='margin-bottom-2x'
                dangerouslySetInnerHTML={{ __html: t('AnswerSettings:RandomHint:Description') }}
              />
              <Button
                type='button'
                color='primary'
                outline
                onClick={createRandomAnswer}
                data-test-id='ConvertAnswerToRandomButton'
              >
                {t('AnswerSettings:CreateRandomAnswer')}
              </Button>
            </>
          }
          className='margin-bottom-4x'
        />
      )}

      <div className={cn(styles.AnswerSettings__tabsWrapper, 'margin-bottom-6x')}>
        <InlineRadioButton
          active={answerType}
          color='primary'
          className='d-flex'
          name='buttonType'
          onChange={onTabClick}
          options={[
            { name: t('AnswerSettings:Tabs:Write'), value: 'write', dataTestId: 'JGraph.RightSideMenu.Text.write' },
            { name: t('AnswerSettings:Tabs:Say'), value: 'say', dataTestId: 'JGraph.RightSideMenu.Text.say' },
          ]}
          outline
          size='sm'
        />
      </div>

      {answerType === 'write' && (
        <FormGroup tag='div'>
          <div className='d-flex justify-content-between'>
            <Label for='answerToEditText'>{t('AnswerSettings:BotAnswer')}</Label>
            <div className='d-flex' data-test-id='AnswerSettings:HtmlEnabler:toggle_container'>
              <SwitchButton
                data-test-id='AnswerSettings:HtmlEnabler:toggle'
                id='switch-button'
                label={t('AnswerSettings:HtmlEnabler')}
                labelPosition='left'
                onChange={changeHtmlEnabled}
                size='md'
                value={isHtmlEnabled}
              />
            </div>
          </div>
          <div data-test-id='AnswerSettings:textInput'>
            {!isHtmlEnabled && (
              <TextAreaLikeElement
                id='answerToEditText'
                onChange={changeText}
                placeholder={t('AnswerSettings:BotAnswer')}
                maxRows={5}
                minRows={1}
                autoFocus
                value={answerToEdit?.tagValue || ''}
              />
            )}
            {isHtmlEnabled && (
              <WysiwygEditor autoFocus ref={htmlInputRef} defaultText={htmlValue.current} onChange={changeHtml} />
            )}
          </div>
          {isHtmlEnabled && (
            <a
              className='d-flex mt-2'
              rel='noopener noreferrer'
              target='_blank'
              href={t('AnswerSettings:LinkToFormattingDocs')}
            >
              {t('AnswerSettings:LinkTextFormattingDocs')}
            </a>
          )}
        </FormGroup>
      )}

      {answerType === 'say' && <BotSayTab onChange={changeSayText} value={ttsValue} />}
    </div>
  );
};
