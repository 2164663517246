export const logsLocalization = {
  'Logs:questionGetSystemLog': {
    eng: 'Get system log',
    ru: 'Скачать системный лог',
  },
  'Logs:LogsMasking.navLink': {
    eng: 'Log obfuscation',
    ru: 'Маскирование логов',
  },
  'Logs:LogsMasking.pageTitle': {
    eng: 'Log obfuscation',
    ru: 'Маскирование логов',
  },
  'Logs:LogsMasking.testButton': {
    eng: 'Test',
    ru: 'Тестировать',
  },
  'Logs:LogsMasking.createRuleButton': {
    eng: 'Create rule',
    ru: 'Создать правило',
  },
  'Logs:LogsMasking.UserRules.title': {
    eng: 'User rules',
    ru: 'Пользовательские правила',
  },
  'Logs:LogsMasking.UserRules.noRules': {
    eng: 'No user rules',
    ru: 'Нет пользовательских правил',
  },
  'Logs:LogsMasking.SystemRules.title': {
    eng: 'System rules',
    ru: 'Системные правила',
  },
  'Logs:LogsMasking.SystemRules.noRules': {
    eng: 'No system rules',
    ru: 'Нет системных правил',
  },
  'Logs:LogsMasking.EditRule.backButton': {
    eng: 'Log obfuscation',
    ru: 'Маскирование логов',
  },
  'Logs:LogsMasking.EditRule.pageTitle': {
    eng: 'New rule',
    ru: 'Новое правило',
  },
  'Logs:LogsMasking.EditRule.unnamedRule': {
    eng: 'Rule',
    ru: 'Правило',
  },
  'Logs:LogsMasking.EditRule.helpButton': {
    eng: 'Help',
    ru: 'Справка',
  },
  'Logs:LogsMasking.EditRule.nameLabel': {
    eng: 'Name',
    ru: 'Название',
  },
  'Logs:LogsMasking.EditRule.regexpLabel': {
    eng: 'Regular expresion',
    ru: 'Регулярное выражение',
  },
  'Logs:LogsMasking.EditRule.regexpHelpLink': {
    eng: 'Read more',
    ru: 'Прочтите подробнее',
  },
  'Logs:LogsMasking.EditRule.regexpHelpRest': {
    eng: 'about regular expressions',
    ru: 'о регулярных выражениях',
  },
  'Logs:LogsMasking.EditRule.caseSensitiveLabel': {
    eng: 'Case sensitive',
    ru: 'Учитывать регистр',
  },
  'Logs:LogsMasking.EditRule.replaceFormatLabel': {
    eng: 'Replace format',
    ru: 'Формат замены',
  },
  'Logs:LogsMasking.EditRule.useInProjectsLabel': {
    eng: 'Use in projects',
    ru: 'Использовать правило в проектах',
  },
  'Logs:LogsMasking.EditRule.useInNewProjectsLabel': {
    eng: 'Use in new projects',
    ru: 'Использовать правило в новых проектах',
  },
  'Logs:LogsMasking.EditRule.Save': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'Logs:LogsMasking.EditRule.Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'Logs:LogsMasking.EditRule.regexpHelpLinkHref': {
    eng: 'https://docs.oracle.com/javase/8/docs/api/java/util/regex/Pattern.html',
    ru: 'https://docs.oracle.com/javase/8/docs/api/java/util/regex/Pattern.html',
  },
  'Logs:LogsMasking.Rule.enableForNewTrue': {
    eng: 'Enabled for new projects',
    ru: 'Включено для новых проектов',
  },
  'Logs:LogsMasking.Rule.enableForNewFalse': {
    eng: 'Disabled for new projects',
    ru: 'Выключено для новых проектов',
  },
  'Logs:LogsMasking.Rule.enableTrue': {
    eng: 'Enabled',
    ru: 'Включено',
  },
  'Logs:LogsMasking.Rule.enableFalse': {
    eng: 'Disabled',
    ru: 'Выключено',
  },
  'Logs:LogsMasking.Rule.test': {
    eng: 'Test',
    ru: 'Тестировать',
  },
  'Logs:LogsMasking.Rule.edit': {
    eng: 'Edit',
    ru: 'Редактировать',
  },
  'Logs:LogsMasking.Rule.delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'Logs:LogsMasking.TestModal.title': {
    eng: 'General testing',
    ru: 'Общее тестирование',
  },
  'Logs:LogsMasking.TestModal.text': {
    eng: 'Message text',
    ru: 'Текст сообщения',
  },
  'Logs:LogsMasking.TestModal.textPlaceholder': {
    eng: 'Enter text here',
    ru: 'Введите текст',
  },
  'Logs:LogsMasking.TestModal.result': {
    eng: 'Result',
    ru: 'Результат',
  },
  'Logs:LogsMasking.TestModal.cancel': {
    eng: 'Close',
    ru: 'Закрыть',
  },
};
