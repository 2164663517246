import React, { useCallback } from 'react';
import { Icon, Button, useToggle } from '@just-ai/just-ui';

import { useAppDispatch } from 'storeHooks';
import { t } from 'localization';

import CreateStartStateModal from 'modules/JGraph/view/EmptyWarningBanner/CreateStartStateModal';
import addNewStartState from 'reducers/JGraph.reducer/AsyncActions/addNewStartState';
import WarningBanner from 'modules/JGraph/view/WarningBanner';

import styles from './styles.module.scss';

interface EmptyWarningBannerInterface {
  title: string;
  fixable: boolean;
}
const EmptyWarningBanner = function ({ title, fixable }: EmptyWarningBannerInterface) {
  const dispatch = useAppDispatch();
  const [isModalOpen, , closeModal, toggleModal] = useToggle(false);

  const onCreateInner = useCallback(
    (title: string) => {
      return dispatch(addNewStartState({ screenPath: title }));
    },
    [dispatch]
  );

  return (
    <>
      <WarningBanner className={styles.EmptyWarningBanner} data-test-id='JGraphBanner:EmptyWarning'>
        <Icon name='faExclamationTriangle' />
        <span>{title}</span>
        {fixable && (
          <Button
            color='secondary'
            onClick={toggleModal}
            className={styles.button}
            data-test-id='JGraphBanner:FixButton'
          >
            {t('EmptyMode:WarningFixButton')}
          </Button>
        )}
      </WarningBanner>
      <CreateStartStateModal isModalOpen={isModalOpen} onCreate={onCreateInner} onCancel={closeModal} />
    </>
  );
};

export default React.memo(EmptyWarningBanner);
