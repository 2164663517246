import { Stage } from 'konva/lib/Stage';
import { MovementData } from '@just-ai/api/dist/generated/Editorbe';

import { JStateWithId, TConnector } from 'reducers/JGraph.reducer/types';
import { batchMove } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { getTargetAndHeightByPathId } from 'modules/JGraph/utils/stageUtils';
import { findParentScreenName } from 'modules/JGraph/hooks';

import { ConnectorStore } from '../../contexts/types';
import { StageObservablesContextType } from '../../contexts/StageObservablesProvider';

import { GraphOptions, INodeGraph } from './INodeGraph';
import { PlacedNode } from '../AutoLayout/BaseAutoLayout';
import { BlocksPositions, AutoLayoutType } from '../../hooks/useAutoplacement';

type MapScreenPathIdToSize = Record<
  string,
  {
    height: number;
    path: string;
    filename: string;
  }
>;

export class StatesNodeGraph implements INodeGraph {
  private mapScreenPathIdToSize: MapScreenPathIdToSize;

  constructor(private screens: JStateWithId[], private stage: Stage, private dispatch: any) {
    this.mapScreenPathIdToSize = screens.reduce((prevValue, currentItem) => {
      const { targetHeight } = getTargetAndHeightByPathId(currentItem.pathId, stage);
      prevValue[currentItem.pathId] = {
        height: targetHeight,
        path: currentItem.path,
        filename: currentItem.filename,
      };
      return prevValue;
    }, {} as MapScreenPathIdToSize);
  }

  getGraphOptions(): GraphOptions {
    return {
      nodeSpacing: 300,
    };
  }

  getNodes() {
    return Object.keys(this.mapScreenPathIdToSize).map(id => ({
      id,
      width: 280,
      height: this.mapScreenPathIdToSize[id].height,
      label: this.mapScreenPathIdToSize[id].path,
    }));
  }

  getEdges(): [string, string][] {
    const result: [string, string][] = [];
    const screensPathIds = this.screens.map(screen => screen.pathId);

    const { connectorsFromStore$, ConnectorsStore$ } = this.stage.attrs.ctx as StageObservablesContextType;
    let fromToNodes: ConnectorStore = {};
    const sub = connectorsFromStore$.subscribe(storedValues => {
      fromToNodes = storedValues;
    });
    sub.unsubscribe();
    let connections: TConnector[] = [];
    const sub2 = ConnectorsStore$.subscribe(storedConnections => {
      connections = storedConnections;
    });
    sub2.unsubscribe();

    const replaceMapFromScreenConnections = new Map();
    const replaceMapToScreenConnections = new Map();

    screensPathIds.forEach(screenPathId => {
      replaceMapFromScreenConnections.set(screenPathId, screenPathId);
      replaceMapToScreenConnections.set(screenPathId, screenPathId);
    });

    for (let connection of connections) {
      if (!replaceMapFromScreenConnections.has(connection.fromNode)) {
        const screenGroupTarget =
          fromToNodes[connection.from]?.fromRef || fromToNodes[connection.fromNode]?.fromRefFallBack;
        if (!screenGroupTarget) continue;
        const [parentName] = findParentScreenName(screenGroupTarget);
        replaceMapFromScreenConnections.set(connection.fromNode, parentName);
      }
      if (!replaceMapToScreenConnections.has(connection.to!)) {
        const screenGroupTarget = fromToNodes[connection.to!]?.toRef;
        if (!screenGroupTarget) continue;
        const [parentName] = findParentScreenName(screenGroupTarget);
        replaceMapToScreenConnections.set(connection.to!, parentName);
      }

      if (
        replaceMapFromScreenConnections.has(connection.fromNode) &&
        replaceMapToScreenConnections.has(connection.to!)
      ) {
        result.push([connection.fromNode, connection.to!]);
      }
    }
    return result;
  }

  applyNewPositions(placedNodes: PlacedNode[], type: AutoLayoutType): void {
    const { positionsToSave, positions } = this.getStatesPositions(placedNodes);
    return this.dispatch(batchMove({ positions, positionsForApi: positionsToSave, strategy: type }));
  }

  private getStatesPositions(placedNodes: PlacedNode[]) {
    const padY: { [x: number]: number } = {};
    let positionsToSave = {} as Record<string, MovementData[]>;
    const positions = placedNodes.reduce((ds, node) => {
      const nodeData = node;
      if (!nodeData || !nodeData.x || !nodeData.y) return ds;
      const x = nodeData.x || 0;
      let y = nodeData.y;
      if (!positionsToSave[this.mapScreenPathIdToSize[nodeData.id].filename]) {
        positionsToSave[this.mapScreenPathIdToSize[nodeData.id].filename] = [];
      }

      if (typeof y !== 'number' || isNaN(y)) {
        if (padY[nodeData.x]) {
          y = padY[nodeData.x];
          padY[nodeData.x] = y + nodeData.height! + 100;
        } else {
          y = 0;
          padY[nodeData.x] = nodeData.height! + 100;
        }
      }
      positionsToSave[this.mapScreenPathIdToSize[nodeData.id].filename].push({
        target: nodeData.label,
        x: x,
        y: y,
      });
      return Object.assign(ds, { [nodeData.id]: { x, y } });
    }, {} as BlocksPositions);
    return { positionsToSave, positions };
  }
}
