import { GraphOptions } from '../NodeGraph/INodeGraph';

export type Node = { id: string; width: number; height: number; label: string };
export type PlacedNode = { id: string; width: number; height: number; label: string; x: number; y: number };

export abstract class BaseAutoLayout {
  public constructor(protected options: GraphOptions) {}
  abstract addNode(node: Node): void;
  abstract getCountOfNode(): number;
  abstract addEdge(from: string, to: string): void;
  abstract calculate(): Promise<PlacedNode[] | undefined>;
}
