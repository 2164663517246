import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';
import { isSystemAccess } from 'isAccessFunction';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { JGraphLicensed } from 'modules/JGraph/constants';
import { blockModalSubject$ } from 'modules/JGraph/view/BlockModal';
import { LastModification } from 'modules/Editor/api/client';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { setEditMenuBlock, restoreState } from 'reducers/JGraph.reducer/index';
import { getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi, getParentPaths } from '../utils';

export const deleteStateNotRevertible = createAsyncThunk(
  'JGraph/deleteState',
  async (deleteJStateData: { filename: string; targetStatePath: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    if (!isSystemAccess([JGraphLicensed])) {
      blockModalSubject$.next(true);
      return;
    }
    const { data } = await GraphApi.deleteState(userAccountId || accountId, projectShortName, {
      ...deleteJStateData,
      file: {
        filename: deleteJStateData.filename,
        lastModification: state.JGraphReducer.graph.files[deleteJStateData.filename],
      },
      includeChildren: true,
    });
    mainSave$.next({
      type: 'delete',
      path: deleteJStateData.targetStatePath,
      action: () => Promise.resolve(),
    });
    await thunkAPI.dispatch(setEditMenuBlock(undefined));
    await thunkAPI.dispatch(getJGraphVisuals());
    await thunkAPI.dispatch(getGraph({}));

    return data;
  }
);

export const revertDeleteState = createAsyncThunk(
  'JGraph/revertDeleteState',
  async (revertEvent: RevertEvent<{ filename: string; targetStatePath: string }, LastModification>, thunkAPI) => {
    const deletedScreen = findScreenByPath(
      revertEvent.payload.targetStatePath,
      revertEvent.prevState.JGraphReducer.graph.blocks
    );
    if (!deletedScreen) return;

    const { parentStatePath, parentThemePath } = getParentPaths(
      revertEvent.payload.targetStatePath,
      deletedScreen.theme
    );

    await thunkAPI.dispatch(
      restoreState({
        screen: deletedScreen,
      })
    );

    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    const { data } = await GraphApi.createState(userAccountId || accountId, projectShortName, {
      stateToCreate: deletedScreen,
      parentThemePath,
      parentStatePath,
      file: {
        filename: deletedScreen.filename,
        lastModification: state.JGraphReducer.graph.files[deletedScreen.filename],
      },
    });
    return data;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertDeleteState, deleteStateNotRevertible);
