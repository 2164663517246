import { last } from 'lodash';

import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { normalizePath } from './stageUtils';

export function hideRootSlashInPath(statePath: string) {
  return statePath.startsWith('/') ? statePath.substring(1) : statePath;
}

export function simplifyStateName(statePath: string) {
  const pathParts = statePath.split('/');
  return last(pathParts);
}

export function getPathInTheme(state: JStateWithId): string {
  if (!state.theme) throw new Error(`Theme in state not found. \nState: ${state.path}`);
  if (!state.path.startsWith(state.theme))
    throw new Error(`Theme path not in state.\nTheme: ${state.theme} \nState: ${state.path}`);
  return state.path.substring(state.theme.length);
}

export function getPathDepth(path: string): number {
  let pathNorm = normalizePath(path);
  pathNorm = pathNorm.replaceAll(/(^\/|\/$)/g, '');
  return pathNorm.split('/').length;
}
