export const AlertBannerLocalization = {
  'LimitBanner:noConnectionWithServerTooLong': {
    eng: 'JAICP server has lost connection to the license server. Chat bots may stop. Ask your JAICP network administrator to fix connection issues.',
    ru: 'Отсутствует связь с сервером лицензирования, работа ботов может быть остановлена. Обратитесь к системному администратору JAICP, чтобы устранить проблемы с сетевой доступностью лицензионного сервера.',
    cn: 'JAICP服务器已失去与许可证服务器的连接。聊天机器人可能会在一段时间后停止。请联系网络管理员修复连接问题。',
  },
  'LimitBanner:noConnectionWithServerNow': {
    eng: 'No connection to the license server.',
    ru: 'Отсутствует связь с лицензионным сервером.',
    cn: '没有与许可证服务器连接。',
  },
  'LimitBanner:noActualKey': {
    eng: 'There is no valid license key in the platform, chat bots are not operating. Contact your JAICP administrator to purchase a new license key and to activate the platform.',
    ru: 'В платформе отсутствует лицензионный ключ, чат-боты не работают. Обратитесь к администратору JAICP, чтобы приобрести ключ и активировать платформу.',
    cn: '系统中没有有效的许可证密钥，聊天机器人无法运行。请联系JAICP管理员以获取新的许可证密钥并激活系统。',
  },
  'LimitBanner:platformBlockedSessions': {
    eng: 'Text bots are paused. Contact your JAICP administrator to purchase a new license key and to activate the platform.',
    ru: 'Работа текстовых ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести ключ и активировать платформу.',
  },
  'LimitBanner:platformBlockedUniqueUsers': {
    eng: 'Text bots are paused. Contact your JAICP administrator to purchase a new license key and to activate the platform.',
    ru: 'Работа текстовых ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести ключ и активировать платформу.',
  },
  'LimitBanner:platformBlockedMinutes': {
    eng: 'Phone bots are paused. Contact your JAICP administrator to purchase a new license key and to activate the platform.',
    ru: 'Работа телефонных ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести ключ и активировать платформу.',
  },
  'LimitBanner:platformBlockedAll': {
    eng: 'Bots are paused. Contact your JAICP administrator to purchase a new license key and to activate the platform.',
    ru: 'Работа ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести ключ и активировать платформу.',
  },
  'LimitBanner:usedAllTariffLimitSessions': {
    eng: 'You have reached the main limit for sessions. After you reach the limits of additional packages, text bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Вы израсходовали основной лимит сессий. Работа текстовых ботов будет приостановлена, если закончатся дополнительные пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:usedAllTariffLimitUniqueUsers': {
    eng: 'You have reached the main limit for unique users. After you reach the limits of additional packages, text bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Вы израсходовали основной лимит уникальных пользователей. Работа текстовых ботов будет приостановлена, если закончатся дополнительные пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:usedAllTariffLimitMinutes': {
    eng: 'You have reached the main limit for minutes. After you reach the limits of additional packages, phone bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: ' Вы израсходовали основной лимит минут. Работа телефонных ботов будет приостановлена, если закончатся дополнительные пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:activatedMoreThenHalfOfAdditionPackagesSessions': {
    eng: 'You have reached the main limit for sessions and used half of the additional packages. After you reach all package limits, text bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Вы израсходовали основной лимит сессий и половину дополнительных пакетов. Работа текстовых ботов будет приостановлена, если закончатся все пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:activatedMoreThenHalfOfAdditionPackagesUniqueUsers': {
    eng: 'You have reached the main limit for unique users and used half of the additional packages. After you reach all package limits, text bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Вы израсходовали основной лимит уникальных пользователей и половину дополнительных пакетов. Работа текстовых ботов будет приостановлена, если закончатся все пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:activatedMoreThenHalfOfAdditionPackagesMinutes': {
    eng: 'You have reached the main limit for minutes and used half of the additional packages. After you reach all package limits, phone bots will be paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Вы израсходовали основной лимит минут и половину дополнительных пакетов. Работа телефонных ботов будет приостановлена, если закончатся все пакеты. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:usedAllAdditionPackagesSessions': {
    eng: 'You have reached the session limit. Text bots are paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Превышен лимит сессий. Работа текстовых ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:usedAllAdditionPackagesUniqueUsers': {
    eng: 'You have reached the unique user limit. Text bots are paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Превышен лимит уникальных пользователей. Работа текстовых ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
  'LimitBanner:usedAllAdditionPackagesMinutes': {
    eng: 'You have reached the minute limit. Phone bots are paused. Contact your JAICP administrator to purchase a license with higher limits.',
    ru: 'Превышен лимит минут. Работа телефонных ботов приостановлена. Обратитесь к администратору JAICP, чтобы приобрести лицензию с большими лимитами.',
  },
};
