import { LogLabelingReducer, LogLabelingState } from '.';
import { filterAllPhrases } from './grouping.reducer';
import { Phrases } from '../../../utils';

const initReducer = (state: LogLabelingState) => {
  const { filteredAllPhrases } = filterAllPhrases(state);
  return { ...state, phrases: filteredAllPhrases };
};

const SourceReducer: LogLabelingReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initReducer(state);
    case 'SET_PHRASES_FILTER':
    case 'TOGGLE_SHOW_LABELED':
    case 'DELETE_PHRASES':
    case 'STAGE_PHRASES':
    case 'CLEAR_PHRASES':
      return { ...state, phrases: Phrases.sortDataset(state.filteredAllPhrases) };
    default:
      return { ...state };
  }
};

export default SourceReducer;
