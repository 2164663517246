import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useEffect } from 'react';
import { Modal, InputText, Spinner, useTranslation } from '@just-ai/just-ui';
import { useLoading } from '../../../../nlu-related/utils';
const FAQ_SKILL_PREFIX = 'FAQ.';
const RenameSkillModal = function ({ isOpen, onClose, skill, skills, onRename }) {
    const [name, setName] = useState(skill.name);
    const [isValid, setIsValid] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isLoading, load] = useLoading();
    const { t } = useTranslation();
    useEffect(() => {
        setName(skill.name);
        setIsValid(true);
        setErrorText('');
    }, [skill]);
    useEffect(() => {
        setName(skill.name);
    }, [skill.name]);
    const onChange = useCallback((title) => {
        if (title.length < 4 || !title.startsWith(FAQ_SKILL_PREFIX)) {
            title = FAQ_SKILL_PREFIX;
        }
        setName(title);
        title = title.trim();
        const isUniqTitle = !skills.some(otherSkill => skill.name !== title && otherSkill.name === title);
        setIsValid(isUniqTitle);
        setErrorText(!isUniqTitle ? t('FAQ:Home:RenameModal:Error:NoUniq') : '');
    }, [skill.name, skills]);
    const OnCloseInner = useCallback(() => {
        setName(skill.name);
        onClose();
    }, [onClose, skill.name]);
    const onSubmit = useCallback(() => {
        if (name.trim().length <= FAQ_SKILL_PREFIX.length)
            return OnCloseInner();
        return load(onRename(name.trim())).finally(() => OnCloseInner());
    }, [OnCloseInner, load, name, onRename]);
    return (_jsx(Modal, { isOpen: isOpen, title: t(`FAQ:Home:RenameModal:Title`), buttonSubmitColor: 'primary', buttonSubmitText: t(`FAQ:Home:RenameModal:Submit`), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), buttonSubmitDisabled: !isValid, onCancelClick: onClose, onActionClick: onSubmit, disableActionButtonAutoFocus: true, children: _jsxs("div", { children: [isLoading && _jsx(Spinner, { size: 'sm' }), _jsx("div", { className: 'margin-bottom-1x', style: { color: 'var(--gray-800)' }, children: t('FAQ:Home:RenameModal:Field') }), _jsx(InputText, { id: 'skillName', name: 'skillName', autoFocus: true, defaultValue: skill.name, value: name, errorText: errorText, onChange: onChange })] }) }));
};
export default React.memo(RenameSkillModal);
