import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import { Vector2d } from 'konva/lib/types';
import { MovementsDataWithFiles } from '@just-ai/api/dist/generated/Editorbe';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { AutoLayoutProgressSubject$, AutoLayoutType } from 'modules/JGraph/hooks/useAutoplacement';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { batchMoveThemes } from '../index';
import { JGraphTheme } from '../types';
import moveTheme from './moveTheme';

type BatchMovePayload = {
  themes: Record<string, Vector2d>;
  positionsToSave: Record<string, MovementsDataWithFiles['movementsData']>;
  type: AutoLayoutType;
};
const batchMoveThemesAndSyncByApi = createAsyncThunk(
  'JGraph/batchMoveThemesAndSyncByApi',
  async (payload: BatchMovePayload, thunkAPI) => {
    await thunkAPI.dispatch(batchMoveThemes(payload));

    const batchedMovePromise = thunkAPI.dispatch(
      moveTheme({
        movementsDataWithFiles: Object.keys(payload.positionsToSave).map(filename => ({
          filenames: [filename],
          movementsData: payload.positionsToSave[filename],
        })),
      })
    );

    mainSave$.next({
      type: 'move',
      path: 'themes batch save move',
      action: () => batchedMovePromise,
    });
  }
);

export const revertBatchMoveThemesAndSyncByApi = createAsyncThunk(
  'JGraph/revertBatchMoveThemesAndSyncByApi',
  async (revertEvent: RevertEvent<BatchMovePayload>, thunkAPI) => {
    AutoLayoutProgressSubject$.next({ type: undefined, status: 'pending' });

    const positions = Object.keys(revertEvent.payload.themes).reduce((acc, pathId) => {
      const theme = revertEvent.prevState.JGraphReducer.graph.themes.find(el => el.pathId === pathId);
      if (!theme) return acc;
      acc[pathId] = theme;
      return acc;
    }, {} as Record<string, JGraphTheme>);

    await thunkAPI.dispatch(
      batchMoveThemes({
        themes: positions,
        type: revertEvent.payload.type,
      })
    );

    const positionsGroupedByFilename = groupBy(positions, el => el.mainFileName);
    const batchedMovePromise = Promise.all(
      Object.entries(positionsGroupedByFilename).map(([filename, prevTheme]) => {
        return thunkAPI.dispatch(
          moveTheme({
            movementsDataWithFiles: [
              {
                filenames: [filename],
                movementsData: prevTheme.map(el => ({
                  target: el.value,
                  y: el.y,
                  x: el.x,
                })),
              },
            ],
          })
        );
      })
    );

    AutoLayoutProgressSubject$.next({ type: undefined, status: 'done' });

    mainSave$.next({
      type: 'move',
      path: 'revert themes batch save move',
      action: () => batchedMovePromise,
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertBatchMoveThemesAndSyncByApi,
  batchMoveThemesAndSyncByApi
);
