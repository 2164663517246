import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VirtualList from 'react-tiny-virtual-list';
import ResizeObserver from 'react-resize-observer';
import { isEqual } from 'lodash';
import Leaf from './NodesView/Leaf';
import Branch from './NodesView/Branch';
import styles from './styles.module.scss';
import DragArea from './NodesView/DragArea';
import KeyboardService from '../../../../services/KeyboardService';
import { TreeSelectionLogicState } from '../../../../views/FAQTreePage/components/Tree/TreeSelectionLogicState';
import { FAQIntentService } from '../../../../services/FAQIntentService';
import cn from 'classnames';
import { withTranslationContext } from '@just-ai/just-ui';
export const TREE_DEPTH_PADDING = 24;
export const TREE_NODE_PADDING = 16;
VirtualList.prototype.scrollTo = function (value) {
    // @ts-ignore
    this.rootNode.scrollTo({ top: value, behavior: 'smooth' });
};
export const TreeContext = React.createContext({
    expandedMap: {},
    selectedIds: [],
    onExpandToggle: () => { },
    onSelectToggle: () => { },
    maxDepthLevel: Infinity,
    onDragDrop: () => { },
    handleContextMenu: () => { },
    setEditId: () => { },
    editId: undefined,
    onRename: () => Promise.resolve(),
    setError: (error) => { },
    hasError: false,
});
class Tree extends PureComponent {
    constructor(props) {
        super(props);
        this.shiftPressed = false;
        this.ctrlOrCmdPressed = false;
        this.lastSelectedId = null;
        this.lastShiftSelectedIds = [];
        this.state = {
            selectedIds: [],
            expandedMap: {},
            editId: undefined,
            containerHeight: 0,
            preventSelections: false,
        };
        this.bindKeys = () => {
            this.keyboardService.bind('shift', () => {
                this.shiftPressed = true;
                this.setState({ preventSelections: this.shiftPressed });
            }, () => {
                this.shiftPressed = false;
                this.setState({ preventSelections: this.shiftPressed });
            });
            this.keyboardService.bind(['ctrl', 'command'], () => {
                this.ctrlOrCmdPressed = true;
            }, () => {
                this.ctrlOrCmdPressed = false;
            });
        };
        this.unbindKeys = () => {
            this.keyboardService.unbindAll();
            this.shiftPressed = false;
            this.ctrlOrCmdPressed = false;
        };
        this.onSelectToggle = (node) => {
            if (!this.props.treeLogicState)
                return;
            let selectedIds = this.state.selectedIds;
            if (this.ctrlOrCmdPressed) {
                const { selectedIds: newSelectedIds, lastSelectedId } = this.selectionState.calculateSelectWithCtrl(node, selectedIds, this.lastSelectedId);
                this.lastSelectedId = lastSelectedId;
                this.setState({
                    selectedIds: newSelectedIds,
                });
                return;
            }
            if (this.shiftPressed) {
                const { selectedIds: newSelectedIds, lastSelectedId, lastShiftSelectedIds, } = this.selectionState.calculateSelectWithShift(node, selectedIds, this.lastSelectedId, this.lastShiftSelectedIds);
                this.lastSelectedId = lastSelectedId;
                this.lastShiftSelectedIds = lastShiftSelectedIds;
                this.setState({
                    selectedIds: newSelectedIds,
                });
                return;
            }
            if (!node.isFolder) {
                this.props.onSelectOne(node);
            }
            const { selectedIds: newSelectedIds, lastSelectedId } = this.selectionState.calculateSelect(node);
            this.lastSelectedId = lastSelectedId;
            this.setState({
                selectedIds: newSelectedIds,
            });
        };
        this.onExpandToggle = (node) => {
            const isExpanded = this.state.expandedMap[node.id];
            this.setState({
                expandedMap: Object.assign(Object.assign({}, this.state.expandedMap), { [node.id]: !isExpanded }),
            });
        };
        this.onDragDrop = (draggedNode, dropTargetNode) => {
            if (this.props.isSearchActive)
                return;
            const selectedNodes = this.getSelectedLeafNodesForDnd(draggedNode);
            if (dropTargetNode.isFolder) {
                this.props.onMoveQuestionInFolder(dropTargetNode, selectedNodes);
                return;
            }
            if (dropTargetNode.parentId) {
                const parentNode = this.props.treeLogicState.getElementById(dropTargetNode.parentId);
                if (!parentNode) {
                    console.error(`Not found treeNode by id = "${dropTargetNode.parentId}"`);
                    return;
                }
                this.props.onMoveQuestionInFolder(parentNode, selectedNodes);
                return;
            }
            if (draggedNode.id !== dropTargetNode.id) {
                this.props.onCreateFolderWithNodes([...selectedNodes, dropTargetNode]);
            }
            return;
        };
        this.onDropInRoot = (draggedNode) => {
            const selectedNodes = this.getSelectedLeafNodesForDnd(draggedNode);
            this.props.onDropInRoot(selectedNodes);
        };
        this.handleContextMenu = (e, node) => {
            const selectedNodes = this.getSelectedNodesBy(node);
            this.props.handleContextMenu(e, node, selectedNodes);
        };
        this.recalculateContainerHeight = () => {
            var _a;
            const clientRect = (_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (!clientRect)
                return;
            this.setState({
                containerHeight: clientRect.height,
            });
        };
        this.getStubElement = (type) => {
            return {
                nodeId: type === 'group' ? 'groupTitle' : 'questionTitle',
                name: type === 'group' ? 'FAQ:Search:Group' : 'FAQ:Search:Question',
                id: -1,
                children: [],
                enabled: false,
                path: '/Root',
                isFolder: false,
                isInRoot: true,
                parent: '',
                parentId: 0,
            };
        };
        this.getSearchCategoryName = (nodesArr, originalNodes, node) => {
            if (!this.props.isSearchActive || node.isFolder || FAQIntentService.isInRoot(node.path))
                return '';
            const haveDuplicate = nodesArr.find(item => item.name === node.name && item.id !== node.id);
            if (haveDuplicate) {
                const originNode = originalNodes.find(item => item.id === node.id && node.isInRoot);
                return (originNode === null || originNode === void 0 ? void 0 : originNode.path.split('/')[1]) || '';
            }
            return '';
        };
        this.getSearchNodes = (type) => {
            const groupNodes = this.props.searchTrees[type].getViewedTreeAsPlainList(this.state.expandedMap);
            if (groupNodes.length > 0) {
                return [this.getStubElement(type), ...groupNodes];
            }
            return [];
        };
        this.containerRef = React.createRef();
        this.keyboardService = new KeyboardService();
        this.scrollRef = React.createRef();
        this.selectionState = new TreeSelectionLogicState(this.props.treeLogicState);
    }
    componentDidMount() {
        this.bindKeys();
        this.recalculateContainerHeight();
    }
    componentWillUnmount() {
        this.unbindKeys();
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedId && this.props.selectedId !== prevProps.selectedId) {
            this.lastSelectedId = this.props.selectedId;
            let newState = {
                activeId: this.props.selectedId,
                selectedIds: [this.props.selectedId],
                expandedMap: this.state.expandedMap,
            };
            this.setState(newState);
        }
        if (this.props.isSearchActive && !prevProps.isSearchActive)
            this.unbindKeys();
        if (!this.props.isSearchActive && prevProps.isSearchActive)
            this.bindKeys();
        if (isEqual(prevProps.treeLogicState, this.props.treeLogicState)) {
            this.selectionState = new TreeSelectionLogicState(this.props.treeLogicState);
        }
    }
    getSelectedLeafNodesForDnd(node) {
        const isDraggedNodeIsSelected = this.state.selectedIds.includes(node.id);
        if (!isDraggedNodeIsSelected) {
            return [node];
        }
        return this.state.selectedIds
            .map(id => Object.values(this.props.treeLogicState.dataset).find(node => node.id === id))
            .filter(node => !node || !node.isFolder);
    }
    getSelectedNodesBy(node) {
        const isDraggedNodeIsSelected = this.state.selectedIds.includes(node.id);
        if (!isDraggedNodeIsSelected) {
            return [node];
        }
        return this.state.selectedIds
            .map(id => Object.values(this.props.treeLogicState.getViewedTreeAsPlainList()).find(node => node.id === id))
            .filter(Boolean);
    }
    getItemHeight(node) {
        const childrenCount = node.children.length;
        if (childrenCount === 0 && this.state.expandedMap[node.id]) {
            return 45 * 2;
        }
        return 45;
    }
    setupScrollActions(nodes) {
        const indexMap = [];
        nodes.forEach((node, index) => {
            const containIds = node.containIds;
            if (containIds) {
                indexMap.push([index, containIds]);
            }
        });
        indexMap.sort((a, b) => a[1].size - b[1].size);
        this.props.treeScroll.scrollToNode = (id) => {
            var _a, _b;
            const mapElement = indexMap.find(el => el[1].has(id));
            if (!mapElement)
                return;
            (_a = this.scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo((_b = this.scrollRef.current) === null || _b === void 0 ? void 0 : _b.getOffsetForIndex(mapElement[0]));
        };
    }
    renderVirtualList() {
        if (!this.props.treeLogicState)
            return;
        const selectedIdsSet = new Set(this.state.selectedIds);
        const nodes = !!this.props.searchValue
            ? [...this.getSearchNodes('group'), ...this.getSearchNodes('answer')]
            : this.props.treeLogicState.getViewedTreeAsPlainList(this.state.expandedMap);
        const initialNodesCopy = this.props.treeLogicState.getViewedTreeAsPlainList(this.state.expandedMap);
        this.setupScrollActions(nodes);
        return (_jsx(VirtualList, { itemCount: nodes.length, height: this.state.containerHeight, className: cn('just-ui-custom-scrollbar just-ui-custom-scrollbar_black', {
                [styles.noselect]: this.state.preventSelections,
            }), itemSize: index => this.getItemHeight(nodes[index]), scrollToAlignment: 'center', width: '100%', ref: this.scrollRef, renderItem: itemInfo => {
                var _a;
                const node = nodes[itemInfo.index];
                const nodeBeforeSearchIndex = initialNodesCopy.findIndex(nodeBeforeSearch => nodeBeforeSearch.nodeId === node.nodeId);
                if (node.nodeId === 'groupTitle' || node.nodeId === 'questionTitle') {
                    return (_jsx("div", { style: itemInfo.style, className: styles.searchTitle, "data-test-id": node.nodeId, children: this.props.t(node.name) }, node.nodeId));
                }
                return (_jsxs("div", { style: itemInfo.style, children: [node.isFolder ? (_jsx(_Fragment, { children: _jsx(Branch, { node: node, isEdit: this.props.editId === node.id, searchValue: this.props.searchValue, initialChildrenLength: (_a = initialNodesCopy[nodeBeforeSearchIndex]) === null || _a === void 0 ? void 0 : _a.children.length, toggleSearchMode: this.props.toggleSearchMode, isSearchActive: this.props.isSearchActive }) })) : (_jsx(Leaf, { node: node, isParentSelected: !!(node.parentId && selectedIdsSet.has(node.parentId)), categoryName: this.getSearchCategoryName(nodes, initialNodesCopy, node), isSearchActive: this.props.isSearchActive, isDndEnabled: !this.props.isUploadModalOpen && !this.props.writeDisabled })), !this.props.writeDisabled && _jsx(DragArea, { onDrop: this.onDropInRoot })] }, node.nodeId));
            } }));
    }
    render() {
        const { setEditId, onRename, setError, hasError } = this.props;
        return (_jsx(DndProvider, { backend: HTML5Backend, children: _jsx(TreeContext.Provider, { value: {
                    activeId: this.state.activeId,
                    selectedIds: this.state.selectedIds,
                    expandedMap: this.state.expandedMap,
                    onExpandToggle: this.onExpandToggle,
                    onSelectToggle: this.onSelectToggle,
                    setEditId: setEditId,
                    editId: this.state.editId,
                    maxDepthLevel: this.props.treeLogicState.maxDepthLevel,
                    onDragDrop: this.onDragDrop,
                    onRename: onRename,
                    handleContextMenu: this.handleContextMenu,
                    setError: setError,
                    hasError: hasError,
                }, children: _jsxs("div", { ref: this.containerRef, className: styles.tree, children: [this.renderVirtualList(), _jsx(ResizeObserver, { onResize: this.recalculateContainerHeight })] }) }) }));
    }
}
export default withTranslationContext(Tree);
