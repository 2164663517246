import { SecretApi, SecretRequest } from 'views/Channels/api/client';

export default class TokensService {
  static readonly BASE_PATH = '';
  secretApi: SecretApi;

  constructor(
    private accountId: number,
    private projectShortName: string
  ) {
    this.secretApi = new SecretApi({}, TokensService.BASE_PATH);
  }

  createSecret(name: string, value: SecretRequest) {
    return this.secretApi.createSecret(this.accountId, this.projectShortName, name, value);
  }

  updateSecret(name: string, value: SecretRequest) {
    return this.secretApi.updateSecret(this.accountId, this.projectShortName, name, value);
  }

  getSecretNames() {
    return this.secretApi.getSecretNames(this.accountId, this.projectShortName);
  }

  deleteSecret(name: string) {
    return this.secretApi.deleteSecret(this.accountId, this.projectShortName, name);
  }
}
