import React, { FC } from 'react';
import { InputText, SwitchButton, Checkbox } from '@just-ai/just-ui';
import { Label } from 'reactstrap';
import { t } from 'localization';

export const StateSettings: FC = () => {
  return (
    <div className='JGraph-RightSideMenu-stateSettings'>
      <div className='JGraph-RightSideMenu-stateSettings-group'>
        <Label for='stateName'>{t('JGraph-RightSideMenu-stateName-label')}</Label>
        <InputText id='stateName' bsSize='default' placeholder={t('JGraph-RightSideMenu-stateName-placeholder')} />
      </div>
      <div className='JGraph-RightSideMenu-stateSettings-group'>
        <Label for='isParentState'>
          <b>{t('JGraph-RightSideMenu-isParentState-label')}</b>
        </Label>
        <SwitchButton id='switch-button' label={t('JGraph-RightSideMenu-isParentState-text')} labelPosition='right' />
      </div>
      <div className='JGraph-RightSideMenu-stateSettings-group'>
        <Label for='isParentState'>
          <b>{t('JGraph-RightSideMenu-context-label')}</b>
        </Label>
        <div className='JGraph-RightSideMenu-checkboxes'>
          <Checkbox label={t('JGraph-RightSideMenu-noContext-label')} name='noContext' />
          <Checkbox label={t('JGraph-RightSideMenu-modal-label')} name='modal' />
        </div>
      </div>
    </div>
  );
};
