import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { uniqueId } from 'lodash';
import { RangeAlt } from '../Range';
import InputGroup from '../InputGroup';
import { InputText } from '../InputText';
import './SimilarsRange.scss';
export default function SimilarsRange(props) {
    const { value, onChange, max = 1, min = 0, step = 0.01, inputTestId } = props;
    const handleTextChange = useCallback((value) => {
        if (isNaN(+value) || +value > 100)
            return;
        onChange(+value / 100);
    }, [onChange]);
    return (_jsxs("div", { className: 'similars-range', children: [_jsxs("div", { className: 'similars-range__wrapper', children: [_jsx("p", { children: min }), _jsx(RangeAlt, { mode: 1, min: min, max: max, step: step, value: [value], onChange: value => onChange(value[0]), handleIds: [`Similars.Handle_${uniqueId()}`] }), _jsx("p", { children: max * 100 })] }), _jsx(InputGroup, { Append: _jsx("div", { children: "%" }), children: _jsx(InputText, { "data-test-id": inputTestId, value: String(Math.round(value * 100)), type: 'number', size: 'sm', onChange: handleTextChange }) })] }));
}
