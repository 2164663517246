var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputTextBase } from './InputTextBase';
import { InputTextUncontrolled } from './InputTextUncontrolled';
import './style.scss';
import classNames from 'classnames';
import { DropDownHint } from '../DropDownHint/DropDownHint';
export function InputText(_a) {
    var { type = 'text', name, value, warning, validPositionLeft, clearable, clearButtonProps, loadable, className, onChange, dark, compact, withEvent, blurInactive, dropDownHint } = _a, restProps = __rest(_a, ["type", "name", "value", "warning", "validPositionLeft", "clearable", "clearButtonProps", "loadable", "className", "onChange", "dark", "compact", "withEvent", "blurInactive", "dropDownHint"]);
    return onChange ? (_jsxs(_Fragment, { children: [_jsx(InputTextBase, Object.assign({ type: type, name: name, value: value, onChange: onChange, warning: warning, validPositionLeft: validPositionLeft, clearable: clearable, clearButtonProps: clearButtonProps, loadable: loadable, className: classNames({ dark: dark, compact: compact, error: Boolean(restProps.errorText) || restProps.invalid }, className), withEvent: withEvent, blurInactive: blurInactive }, restProps)), dropDownHint ? _jsx(DropDownHint, { dropDownHint: dropDownHint }) : null] })) : (_jsxs(_Fragment, { children: [_jsx(InputTextUncontrolled, Object.assign({ type: type, name: name, value: value, warning: warning, validPositionLeft: validPositionLeft, clearable: clearable, clearButtonProps: clearButtonProps, loadable: loadable, blurInactive: blurInactive, className: classNames({ dark: dark, compact: compact, error: Boolean(restProps.errorText || restProps.invalid) }, className) }, restProps)), dropDownHint ? _jsx(DropDownHint, { dropDownHint: dropDownHint }) : null] }));
}
