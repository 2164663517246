import { createAsyncThunk } from '@reduxjs/toolkit';
import { JGraphStickersInfo } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { StickerInfo } from 'modules/JGraph/view/Sticker/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { addSticker, updateSticker, deleteSticker, loadStickers } from '../index';
import { jGraphStickersApi } from '../utils';

export const upsertStickerWithoutRollback = createAsyncThunk(
  'JGraph/upsertSticker',
  async (sticker: Omit<StickerInfo, 'history'>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const existedSticker = state.JGraphReducer.stickers.find(el => el.id === sticker.id);
    if (existedSticker) {
      thunkAPI.dispatch(updateSticker({ ...existedSticker, ...sticker }));
    } else {
      thunkAPI.dispatch(addSticker(sticker));
    }

    mainSave$.next({
      type: 'upsertSticker',
      path: '',
      action: async () => {
        const { data: createResponse } = await jGraphStickersApi.upsertJGraphSticker(
          userAccountId || accountId,
          projectShortName,
          sticker.id,
          {
            version: state.JGraphReducer.stickersVersion.version,
            lastModified: state.JGraphReducer.stickersVersion.lastModified,
            sticker,
          }
        );
        thunkAPI.dispatch(loadStickers(createResponse));
      },
    });

    return {
      isUpdateAction: Boolean(existedSticker),
      sticker: sticker,
    };
  }
);

export const revertUpsertSticker = createAsyncThunk(
  'JGraph/revertUpsertSticker',
  async (
    revertEvent: RevertEvent<Omit<StickerInfo, 'history'>, { sticker: StickerInfo; isUpdateAction: boolean }>,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const revertedSticker = revertEvent.prevState.JGraphReducer.stickers.find(
      el => el.id === revertEvent.result.sticker.id
    );
    if (revertEvent.result.isUpdateAction) {
      revertedSticker && thunkAPI.dispatch(updateSticker(revertedSticker));
    } else {
      thunkAPI.dispatch(deleteSticker(revertEvent.result.sticker));
    }

    mainSave$.next({
      type: 'revertUpsertSticker',
      path: '',
      action: async () => {
        let response: JGraphStickersInfo | undefined = undefined;
        if (revertEvent.result.isUpdateAction && revertedSticker) {
          const updateResponse = await jGraphStickersApi.upsertJGraphSticker(
            userAccountId || accountId,
            projectShortName,
            revertedSticker.id,
            {
              version: state.JGraphReducer.stickersVersion.version,
              lastModified: state.JGraphReducer.stickersVersion.lastModified,
              sticker: revertedSticker,
            }
          );
          response = updateResponse.data;
        }
        if (!revertEvent.result.isUpdateAction) {
          const updateResponse = await jGraphStickersApi.deleteJGraphSticker(
            userAccountId || accountId,
            projectShortName,
            revertEvent.result.sticker.id
          );
          response = updateResponse.data;
        }
        response && thunkAPI.dispatch(loadStickers(response));
      },
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertUpsertSticker, upsertStickerWithoutRollback);
