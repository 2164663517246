import React, { FC, useEffect, useRef } from 'react';
import { IconNames, KIcon } from './KIcons';
import { Circle, Text } from 'react-konva';
import Konva from 'konva';
import { useRenderBlocksContext } from './RenderBlocks';
import { TagNames, TJBlock } from '../../utils/types';
import { FromStateConnectionsStore, getBlockConnections } from '../../../../reducers/JGraph.reducer/Graph';
import { useConditionBodyContext } from './ConditionBody';

import { getStageContextFromRef } from '../../utils/stageUtils';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { Subject } from 'rxjs';
import { ConnectorStore, TConnectorSubj } from '../../contexts/types';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';

type ItemHeadingPropsType = {
  icon?: IconNames;
  title: string;
  collapsible?: boolean;
  collapsed?: boolean;
  onClick?: (e: Konva.KonvaEventObject<MouseEvent>) => unknown;
  gap: number;
  hasChildren: boolean;
  screenBlock?: TJBlock;
  currentContextIndex?: number;
};

export const ItemHeading: FC<ItemHeadingPropsType> = ({
  icon,
  title,
  collapsible,
  collapsed = false,
  onClick = () => {},
  gap,
  hasChildren,
  screenBlock,
  currentContextIndex = 1,
}) => {
  const { baseWidth, screen, prefix } = useRenderBlocksContext();
  const { blocks = [] } = useConditionBodyContext();
  const CircleRef = useRef<Konva.Circle>(null);

  const iconWidth = 16;
  const textWidth =
    baseWidth -
    24 /*offset from main*/ -
    12 /*main group offset*/ -
    (icon ? iconWidth + gap : 0) -
    (collapsible ? iconWidth + gap : 0);

  useEffect(() => {
    let extConnections: TConnector[] = [];
    let extConnectionsSubject$: Subject<TConnectorSubj> | null = null;
    let extConnectorsFromPipe$: Subject<ConnectorStore> | null = null;
    if (collapsed && screenBlock && CircleRef.current) {
      const { ConnectorsSubject$, connectorsFromPipe$ } = getStageContextFromRef(CircleRef);
      extConnectionsSubject$ = ConnectorsSubject$;
      extConnectorsFromPipe$ = connectorsFromPipe$;
      let isItElseOrElseIf = [TagNames.else, TagNames.elseif].includes(screenBlock.tagName);
      let innerPrefix = isItElseOrElseIf
        ? ScreenBlockPath.getAscendingBlockPath(blocks, currentContextIndex, TagNames.if)
        : '';

      const [connections] = getBlockConnections(
        screen,
        new FromStateConnectionsStore({}),
        screenBlock.jblocks,
        `${prefix + innerPrefix}${currentContextIndex}_${screenBlock.tagName}_`
      );
      extConnections = connections;

      connections.forEach(connection => {
        ConnectorsSubject$.next({ type: 'add', connector: connection });
        connectorsFromPipe$.next({
          [connection.from]: {
            fromRef: CircleRef.current!,
          },
        });
      });
    }

    return () => {
      extConnections.forEach(connection => {
        extConnectionsSubject$ && extConnectionsSubject$.next({ type: 'remove', connector: connection });
        extConnectorsFromPipe$ &&
          extConnectorsFromPipe$.next({
            [connection.from]: {
              fromRef: undefined,
            },
          });
      });
    };
  }, [blocks, collapsed, currentContextIndex, prefix, screen, screenBlock]);

  return (
    <>
      {icon && <KIcon icon={icon} width={iconWidth} height={16} y={10} />}
      <Text
        width={textWidth - 10}
        ellipsis={true}
        fontVariant='bold'
        text={title}
        fill='#6A7178'
        letterSpacing={0.0075 * 14}
        x={icon ? 16 + gap : 0}
        y={8}
        lineHeight={20 / 14}
        fontSize={14}
        height={26}
        wrap='none'
      />
      {hasChildren && (
        <Circle ref={CircleRef} x={(icon ? iconWidth + gap : 0) + textWidth + gap - 4} y={16} width={12} height={12} />
      )}
      {collapsible && (
        <KIcon
          dataType='CollapseHeadingIcon'
          icon={collapsed ? IconNames.collapsible_open : IconNames.collapsible_close}
          width={iconWidth}
          height={16}
          x={(icon ? iconWidth + gap : 0) + textWidth + gap - 4}
          y={8}
          onClick={onClick}
        />
      )}
    </>
  );
};
