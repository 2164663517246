import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { format, addMonths } from 'date-fns';
import { IconButton, Button } from '../Button';
import './Months.scss';
import classNames from 'classnames';
const YEARS_NUMBER = 12;
var MONTHS_STATE;
(function (MONTHS_STATE) {
    MONTHS_STATE["days"] = "days";
    MONTHS_STATE["months"] = "months";
    MONTHS_STATE["years"] = "years";
})(MONTHS_STATE || (MONTHS_STATE = {}));
const FORMAT_MATRIX = {
    [MONTHS_STATE.days]: 'MMMM yyyy',
    [MONTHS_STATE.months]: 'yyyy',
};
const MONTH_OFFSET = {
    [MONTHS_STATE.days]: 1,
    [MONTHS_STATE.months]: 12,
    [MONTHS_STATE.years]: 12 * YEARS_NUMBER,
};
export const Months = ({ setMonth, setYearAndMonth, showCalendar, singleDateMode, currentDate, locale, maxDate, minDate, }) => {
    const [state, setState] = useState(MONTHS_STATE.days);
    const [monthCorrection, setMonthCorrection] = useState(0);
    const [defaultCurrentDate, setDefaultCurrentDate] = useState(currentDate);
    const { minMonth, maxMonth, yearsRange } = useMemo(() => {
        var _a;
        let minMonth = 0;
        let maxMonth = 11;
        let yearsRange = [(_a = minDate === null || minDate === void 0 ? void 0 : minDate.getFullYear()) !== null && _a !== void 0 ? _a : 1987, maxDate.getFullYear()];
        const currentYear = currentDate.getFullYear();
        if (maxDate && currentYear >= yearsRange[1]) {
            maxMonth = maxDate.getMonth();
        }
        if (minDate && currentYear <= yearsRange[0]) {
            minMonth = minDate.getMonth();
        }
        return { minMonth, maxMonth, yearsRange };
    }, [minDate, maxDate, currentDate]);
    const dateWithCorrection = useMemo(() => {
        return addMonths(currentDate, monthCorrection);
    }, [monthCorrection, currentDate]);
    const setValue = useCallback(({ year, month }) => {
        setYearAndMonth(month !== null && month !== void 0 ? month : dateWithCorrection.getMonth(), year !== null && year !== void 0 ? year : dateWithCorrection.getFullYear());
        if (month !== undefined) {
            setState(MONTHS_STATE.days);
            setMonthCorrection(0);
        }
        if (year !== undefined) {
            setState(MONTHS_STATE.months);
        }
    }, [currentDate]);
    const changeShowState = useCallback((position) => {
        if (state === MONTHS_STATE.months) {
            setState(MONTHS_STATE.years);
            return;
        }
        if (state === MONTHS_STATE.days) {
            setMonthCorrection(position === 'right' ? 1 : 0);
            setDefaultCurrentDate(currentDate);
            setState(MONTHS_STATE.months);
        }
    }, [state]);
    useEffect(() => {
        if (!showCalendar && state !== MONTHS_STATE.days) {
            setState(MONTHS_STATE.days);
            setMonthCorrection(0);
        }
    }, [showCalendar, state]);
    const years = getYears(yearsRange, dateWithCorrection, defaultCurrentDate);
    const months = getMonths(locale, singleDateMode);
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const previousButtonDisabled = isPreviousButtonDisabled({ yearsRange, state, years, currentDate, minDate });
    const nextButtonDisabled = isNextButtonDisabled({ yearsRange, state, years, currentDate, maxDate });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'rdrMonthsSwitcher', children: [_jsx(IconButton, { className: 'rdrMonthButton', name: 'faChevronLeft', disabled: previousButtonDisabled, onClick: () => setMonth(MONTH_OFFSET[state] * -1) }), _jsx(Button, { onClick: () => changeShowState('left'), className: classNames('rdrMonthCustomName', singleDateMode && 'rdrMonthCustomNameSingle'), color: state === MONTHS_STATE.years ? 'secondary' : 'primary', flat: true, size: 'md', disabled: state === MONTHS_STATE.years, style: { gridArea: 'firstMonth' }, children: state === MONTHS_STATE.years
                            ? `${years[0]} - ${years[years.length - 1]}`
                            : format(currentDate, FORMAT_MATRIX[state], { locale }) }), !singleDateMode && state === MONTHS_STATE.days && (_jsxs(_Fragment, { children: [_jsx("div", { style: { width: '4.5rem' } }), _jsx(Button, { onClick: () => changeShowState('right'), className: 'rdrMonthCustomName', color: 'primary', flat: true, size: 'md', style: { gridArea: 'secondMonth' }, children: format(addMonths(currentDate, 1), 'MMMM yyyy', { locale }) })] })), _jsx(IconButton, { className: 'rdrMonthButton', name: 'faChevronRight', style: { marginLeft: '0.3rem' }, disabled: nextButtonDisabled, onClick: () => setMonth(MONTH_OFFSET[state]) })] }), (state === MONTHS_STATE.months || state === MONTHS_STATE.years) && (_jsxs("div", { className: classNames('rdrMonthYearPicker', singleDateMode && 'rdrMonthYearPickerSingle'), children: [state === MONTHS_STATE.months &&
                        months.map(month => (_jsx(Button, { className: currentMonth === month.value ? 'rdrActiveElem' : '', disabled: month.value > maxMonth || month.value < minMonth, onClick: () => setValue({ month: month.value }), children: month.label }, month.value))), state === MONTHS_STATE.years &&
                        years.map(year => (_jsx("button", { className: currentYear === year ? 'rdrActiveElem' : '', onClick: () => setValue({ year }), children: year }, year)))] }))] }));
};
function getYears(yearsRange, currentDate, defaultCurrentDate) {
    const result = [];
    if (!yearsRange) {
        for (let i = currentDate.getFullYear() - YEARS_NUMBER / 2; i < currentDate.getFullYear() + YEARS_NUMBER / 2; i++) {
            result.push(i);
        }
        return result;
    }
    let rightBorder;
    let leftBorder;
    const defaultYear = defaultCurrentDate.getFullYear();
    const currentYear = currentDate.getFullYear();
    const diffWithStart = defaultYear - yearsRange[0];
    const diffWithEnd = yearsRange[1] - defaultYear;
    if (yearsRange[1] - yearsRange[0] <= YEARS_NUMBER) {
        rightBorder = yearsRange[1];
        leftBorder = yearsRange[0];
    }
    else if (diffWithStart > diffWithEnd) {
        const skippedPeriods = Math.floor((yearsRange[1] - currentYear) / YEARS_NUMBER);
        rightBorder = yearsRange[1] - skippedPeriods * YEARS_NUMBER;
        leftBorder = rightBorder - YEARS_NUMBER > yearsRange[0] ? rightBorder - YEARS_NUMBER + 1 : yearsRange[0];
    }
    else {
        const skippedPeriods = Math.floor((currentYear - yearsRange[0]) / YEARS_NUMBER);
        leftBorder = yearsRange[0] + skippedPeriods * YEARS_NUMBER;
        rightBorder = leftBorder + YEARS_NUMBER > yearsRange[1] ? yearsRange[1] : leftBorder + YEARS_NUMBER;
    }
    for (let i = leftBorder; i <= rightBorder; i++) {
        result.push(i);
    }
    return result;
}
function getMonths(locale, shortForm) {
    const result = [];
    const baseDate = new Date();
    baseDate.setMonth(0);
    for (let i = 0; i < 12; i++) {
        const date = addMonths(baseDate, i);
        const label = format(date, shortForm ? 'MMM' : 'MMMM', { locale });
        result.push({
            year: date.getFullYear(),
            value: i,
            label: shortForm ? label.substr(0, 3) : label,
        });
    }
    return result;
}
function isPreviousButtonDisabled({ yearsRange, state, years, currentDate, minDate, }) {
    if (!yearsRange)
        return false;
    if (state === MONTHS_STATE.years)
        return years[0] <= yearsRange[0];
    if (state === MONTHS_STATE.months)
        return currentDate.getFullYear() <= yearsRange[0];
    const isLastYear = currentDate.getFullYear() <= yearsRange[0];
    const isLastMonth = currentDate.getMonth() <= (minDate ? minDate.getMonth() : 0);
    return isLastYear && isLastMonth;
}
function isNextButtonDisabled({ yearsRange, state, years, currentDate, maxDate, }) {
    if (!yearsRange)
        return false;
    if (state === MONTHS_STATE.years)
        return years[years.length - 1] >= yearsRange[1];
    if (state === MONTHS_STATE.months)
        return currentDate.getFullYear() >= yearsRange[1];
    const isLastYear = currentDate.getFullYear() >= yearsRange[1];
    const isLastMonth = currentDate.getMonth() >= (maxDate ? maxDate.getMonth() : 11);
    return isLastYear && isLastMonth;
}
