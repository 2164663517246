import React, { useMemo, useEffect } from 'react';
import { BehaviorSubject, scan } from 'rxjs';
import { Layer } from 'react-konva';

import { ThemesConnectorStore } from 'modules/JGraph/contexts/types';
import { getConnectionsFromBlocks, getAllStates, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';

import { ThemeConnectorLine } from '../../Connector/ThemeConnector';

export type ThemeConnector = { from: string; to: string; fromPathId: string; toPathId: string };

function transformPathToBasicForm(path: string) {
  return path.replace(/\/$/g, '');
}

export const themesConnectors$ = new BehaviorSubject<ThemeConnector[]>([]);
export const themesConnectorPipe$ = new BehaviorSubject<ThemesConnectorStore>({});
export const themesConnectorStore$ = themesConnectorPipe$.pipe(
  scan((store: ThemesConnectorStore, newValue) => {
    const name = Object.keys(newValue);
    name.forEach(name => {
      store[name] = {
        ...store[name],
        ...newValue[name],
      };
    });
    return store;
  }, {} as ThemesConnectorStore)
);

interface ThemesConnectionsLayerProps {
  themes: JGraphTheme[];
  states: JStateWithId[];
}
const ThemesConnectionsLayer = ({ themes, states }: ThemesConnectionsLayerProps) => {
  const statePathsToThemeMap = useMemo(() => {
    return themes.reduce((acc, theme) => {
      const themeStates = states.filter(state => state.theme === theme.value);
      const statesPaths = getAllStates(themeStates);
      for (let statePath of statesPaths) {
        acc[transformPathToBasicForm(statePath)] = theme.value;
      }
      return acc;
    }, {} as Record<string, string>);
  }, [states, themes]);

  const themeConnectors = useMemo(() => {
    return themes.reduce((acc, theme) => {
      const themeStates = states.filter(state => state.theme === theme.value);
      const { connections } = getConnectionsFromBlocks(themeStates);

      const themesConnections = connections
        .map(el => {
          const from = statePathsToThemeMap[transformPathToBasicForm(el.fromNodeOriginalPath)] || '';
          const to = statePathsToThemeMap[transformPathToBasicForm(el.toNodeOriginalPath)] || '';
          if (!from || !to) return null;
          if (from === to) return null;
          return { from, to, fromPathId: getValidKonvaName(from), toPathId: getValidKonvaName(to) } as ThemeConnector;
        })
        .filter(Boolean) as ThemeConnector[];
      return acc.concat(themesConnections);
    }, [] as ThemeConnector[]);
  }, [statePathsToThemeMap, states, themes]);

  useEffect(() => {
    themesConnectors$.next(themeConnectors);
    return () => themesConnectors$.next([]);
  }, [themeConnectors]);

  return (
    <Layer isLayer={true} listening={false}>
      {themeConnectors.map((connector, index) => (
        <ThemeConnectorLine key={connector.from + '->' + connector.to + index} connector={connector} />
      ))}
    </Layer>
  );
};

ThemesConnectionsLayer.displayName = 'ThemesConnectionsLayer';

export default React.memo(ThemesConnectionsLayer);
