import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useLayoutEffect, useReducer } from 'react';
import { Modal, Spinner, SwitchButton, useTranslation } from '@just-ai/just-ui';
import SimilarsRange from '@just-ai/just-ui/dist/SimilarsRange';
import { useLoading } from '../../../../nlu-related/utils';
import { useNLUModuleContext } from '../../../../context';
const defaultValues = {
    answerValidationEnabled: false,
    answerValidationThreshold: 0.5,
    phraseValidationEnabled: false,
    phraseValidationThreshold: 0.5,
};
const SimilarsModal = function ({ isOpen, onClose }) {
    const [isLoading, load] = useLoading();
    const { currentProject, SettingsApi, accountId, setCurrentProject } = useNLUModuleContext();
    const { t } = useTranslation();
    const [validationData, setValidationData] = useReducer((current, update) => (Object.assign(Object.assign({}, current), update)), (currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation) || defaultValues);
    useLayoutEffect(() => {
        setValidationData((currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation) || defaultValues);
    }, [currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation, isOpen]);
    const handleSimilarPhrasesToggle = useCallback(value => {
        setValidationData({ phraseValidationEnabled: value });
    }, [setValidationData]);
    const handleSimilarAnswersToggle = useCallback(value => {
        setValidationData({ answerValidationEnabled: value });
    }, [setValidationData]);
    const handleSimilarPhrasesRange = useCallback((value) => {
        setValidationData({ phraseValidationThreshold: value });
    }, [setValidationData]);
    const handleSimilarAnswersRange = useCallback((value) => {
        setValidationData({ answerValidationThreshold: value });
    }, [setValidationData]);
    const onValSettingsSave = useCallback((validationData) => {
        if (!(currentProject === null || currentProject === void 0 ? void 0 : currentProject.shortName))
            return Promise.reject('No currentProject');
        const updatedSettings = {
            classificationAlgorithm: currentProject.classificationAlgorithm,
            language: currentProject.language,
            shared: currentProject.shared,
            extendedSettings: currentProject.extendedSettings,
            spellingCorrection: currentProject.spellingCorrection,
            timezone: currentProject.timezone,
            validation: validationData,
        };
        return SettingsApi.setProjectSettings(accountId, currentProject.shortName, updatedSettings).then(() => {
            if (setCurrentProject) {
                setCurrentProject(Object.assign(Object.assign({}, currentProject), { validation: validationData }));
            }
            onClose();
        });
    }, [SettingsApi, accountId, currentProject, onClose, setCurrentProject]);
    return (_jsxs(Modal, { isOpen: isOpen, title: t(`FAQ:Home:SimilarsModal:Title`), buttonSubmitColor: 'primary', buttonSubmitText: t(`Save`), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: onClose, onActionClick: () => load(onValSettingsSave(validationData)), disableActionButtonAutoFocus: true, children: [isLoading && _jsx(Spinner, { size: 'sm' }), _jsx("div", { className: 'margin-bottom-2x', style: { color: 'var(--gray-800)', whiteSpace: 'pre-line' }, children: t('FAQ:Home:SimilarsModal:Content') }), _jsxs("div", { className: 'mt-5 ml-1 mb-2', children: [_jsxs("div", { className: 'mb-5', children: [_jsx(SwitchButton, { id: 'similarsPhrasesToggle', label: t('FAQ:Home:SimilarsModal:QuestionsLabel'), onChange: handleSimilarPhrasesToggle, value: validationData.phraseValidationEnabled }), validationData.phraseValidationEnabled && (_jsx(SimilarsRange, { value: validationData.phraseValidationThreshold || 0, onChange: handleSimilarPhrasesRange }))] }), _jsxs("div", { children: [_jsx(SwitchButton, { id: 'similarsAnswersToggle', label: t('FAQ:Home:SimilarsModal:AnswersLabel'), onChange: handleSimilarAnswersToggle, value: validationData.answerValidationEnabled }), validationData.answerValidationEnabled && (_jsx(SimilarsRange, { value: validationData.answerValidationThreshold || 0, onChange: handleSimilarAnswersRange }))] })] })] }));
};
export default React.memo(SimilarsModal);
