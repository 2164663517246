import axios from 'axios';
import { OperatorPlaceApi } from '../api/aimychatClient';

export default class AimychatOperatorPlaceService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private configsApi: OperatorPlaceApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.configsApi = new OperatorPlaceApi({}, AimychatOperatorPlaceService.BASE_PATH, axios);
  }

  async getOperatorPlace() {
    const { data } = await this.configsApi.getOperatorPlace(this.accountId);
    return data;
  }
}
