import localize from '../localization';
import { getGoogleTag, getYmTag } from '../utils';
import aimylogic from '../img/aimylogic.svg';
import aimyvoice from '../img/aimyvoice.svg';
import cloud from '../img/cloud.svg';
import jaicp from '../img/jaicp.svg';
import jaicf from '../img/jaicf.svg';
import caila from '../img/caila.svg';
import aimybox from '../img/aimybox.svg';
import tovie_platform from '../img/tovie_platform.svg';
import tovie_dialogstudio from '../img/tovie_dialogstudio.svg';
import tovie_cloud from '../img/tovie_cloud.svg';
import tovie_voice from '../img/tovie_voice.svg';
import tovie_nlp from '../img/tovie_nlp.svg';

export const isDev = () => window.isDev || process.env.NODE_ENV === 'development';

export const getUserLanguage = userCountryIsoCode => {
  if (Boolean(userCountryIsoCode)) {
    return userCountryIsoCode.toLowerCase();
  }

  if (Boolean(localStorage.getItem('USER_LANGUAGE'))) {
    return localStorage.getItem('USER_LANGUAGE');
  } else {
    let lang = window.navigator.language.split('-')[0];

    switch (lang) {
      case 'ru': {
        return 'ru';
      }
      case 'cn':
      case 'zh': {
        return 'cn';
      }
      default:
        return 'eng';
    }
  }
};

export function timeoutPromise(timeout, err, promise) {
  return new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
  });
}

export function flattenObject(ob) {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export const detectSelection = () => {
  let txt = '';
  if (window.getSelection) {
    txt = window.getSelection().toString();
  } else {
    // IE, используем объект selection
    txt = document.selection.createRange().text;
  }
  return txt;
};

export const sort = (arr, field, dir = 'asc') => {
  let newArr = arr.slice();
  newArr.sort((a, b) => {
    switch (dir) {
      case 'desc': {
        if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
          return 1;
        }
        if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
          return -1;
        }
        return 0;
      }
      default: {
        if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
          return 1;
        }
        if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
          return -1;
        }
        return 0;
      }
    }
  });
  return newArr;
};

export const getUserValidName = (firstName, lastName, login, fullName) => {
  let computedName = '';
  if (!!firstName && !fullName) {
    computedName += firstName + ' ';
  }
  if (!!lastName && !fullName) {
    computedName += lastName;
  }
  if (fullName) {
    computedName = fullName;
  }
  if (computedName === '' && !!login) {
    computedName += login;
  }
  if (computedName === '') {
    computedName += localize.translate('No data specified');
  }
  return computedName;
};

export const stringHashCode = s => {
  let h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export const removeWidgetCode = () => {
  if (!!document.querySelector('script#justwidget')) {
    document.querySelector('script#justwidget').remove();
  }

  if (!!document.getElementsByClassName('justwidget')[0]) {
    document.getElementsByClassName('justwidget')[0].remove();
  }

  if (!!document.querySelector('script[src*="s/widget.js"]')) {
    document.querySelector('script[src*="s/widget.js"]').remove();
  }

  if (!!document.querySelector('script[src*="s/themes/"]')) {
    document.querySelector('script[src*="s/themes/"]').remove();
  }

  let styleList = document.querySelectorAll('style[class="justwidget-styles"]');
  for (let i = 0; i < styleList.length; i++) {
    styleList[i].remove();
  }

  window.JustWidget = undefined;
};

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getIntFromPageNumber = (number, fallBackNumber) => {
  return Number.isInteger(number) ? number : fallBackNumber || 0;
};

export const getAmplitudeKey = () => {
  const { hostname } = window.location;
  if (hostname.endsWith('test-ai.net') && localStorage.getItem('AMPLITUDE') === 'true')
    return 'a681ce4de8deedf8ccf4d049c985e7ca';
  if (hostname.endsWith('aimylogic.com') || hostname.endsWith('jaicp.com') || hostname.endsWith('just-ai.com'))
    return 'fc1b9c4089a417a0d43d05ad4cdceb35';
  return '';
};

export function GA(
  eventCategory,
  eventAction,
  options = {},
  event = 'GAEvent',
  eventLabel = undefined,
  eventValue = undefined
) {
  if (Boolean(window.dataLayer) && getGoogleTag()) {
    window.dataLayer.push({
      event: event,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
      ...options,
    });
  }

  if (window.debugJaicp || isDev()) {
    console.log({
      event: event,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
      ...options,
    });
  }
}

export function fbqEvent(eventAction, eventType) {
  if (getGoogleTag() && window.fbq) {
    window.fbq(eventAction, eventType);
  }

  if (window.debugJaicp || isDev()) {
    console.log(`fbq(${eventAction}, ${eventType});`);
  }
}
export function ymEvent(eventType, eventAction = 'reachGoal') {
  if (getYmTag() && window.ym) {
    window.ym(getYmTag(), eventAction, eventType);
  }

  if (window.debugJaicp || isDev()) {
    console.log(`ym(${getYmTag()}, ${eventType}, ${eventAction});`);
  }
}

export function trackMultipleEvents(
  googleEventOptions = {
    eventCategory: '',
    eventAction: '',
    options: {},
    event: 'GAEvent',
    eventLabel: undefined,
    eventValue: undefined,
  },
  fbEventOptions = {
    eventAction: '',
    eventType: '',
  },
  ymEventOptions = {
    eventType: '',
    eventAction: undefined,
  }
) {
  GA(
    googleEventOptions.eventCategory,
    googleEventOptions.eventAction,
    googleEventOptions.options,
    googleEventOptions.event,
    googleEventOptions.eventLabel,
    googleEventOptions.eventValue
  );
  fbqEvent(fbEventOptions.eventAction, fbEventOptions.eventType);
  ymEvent(ymEventOptions.eventType, ymEventOptions.eventAction);
}

export const getLogoByProduct = product => {
  switch (product) {
    case 'aimylogic':
      return aimylogic;
    case 'aimyvoice':
      return aimyvoice;
    case 'cc':
      return cloud;
    case 'jaicp':
      return jaicp;
    case 'jaicf':
      return jaicf;
    case 'caila':
      return caila;
    case 'aimybox':
      return aimybox;
    case 'Tovie DialogStudio':
      return tovie_dialogstudio;
    case 'Tovie Voice':
      return tovie_voice;
    case 'Tovie Cloud':
      return tovie_cloud;
    case 'Tovie Platform':
      return tovie_platform;
    case 'Tovie NLP':
      return tovie_nlp;
    default:
      return;
  }
};
