import React, { useCallback, useState, FC, ReactElement, useRef } from 'react';
import { ItemHeading } from './ItemHeading';
import { AutopositionGroup } from './AutopositionGroup';
import { AutosizeRect } from './AutosizeRect';
import Konva from 'konva';
import { Group } from 'react-konva';

import { KIconsProp } from './KIcons';
import { KonvaEventObject } from 'konva/lib/Node';
import { useRenderBlocksContext } from './RenderBlocks';
import { TJBlock } from '../../utils/types';

type TRenderChildProps = { innerHeight?: number; collapsed?: boolean };

export interface TBaseItemBodyProps {
  renderChild: (props: TRenderChildProps) => ReactElement | null;
  globalJBlockIterator?: number;
  currentContextIndex?: number;
  icon?: KIconsProp['name'];
  title?: string;
  maxHeight?: number;
  active?: boolean;
  bottomPadding?: number;
  name?: string;
  onClick?: (evt: KonvaEventObject<MouseEvent>) => unknown;
  gap?: number;
  collapsible?: boolean;
  hasChildren?: boolean;
  cornerRadius?: number | number[];
  yOffset?: number;
  screenBlock?: TJBlock;
  debugActive?: boolean;
}

export const BaseItemBody: FC<TBaseItemBodyProps> = ({
  icon,
  title,
  maxHeight,
  renderChild,
  active,
  bottomPadding = 0,
  name,
  onClick,
  gap = 8,
  hasChildren = false,
  cornerRadius = 8,
  yOffset = 4,
  screenBlock,
  debugActive,
  ...rest
}) => {
  const { baseWidth = 280 } = useRenderBlocksContext();
  const [rowsCount, setRowsCount] = useState(1);
  const [shortenText, setShortenText] = useState(() => false);
  const [collapsible, setCollapsible] = useState(() => rest.collapsible || false);
  const prevTextValue = useRef('');
  // const [debugRect, setDebugRect] = useState(0);

  const renderChildMaxHeightCalculation = useCallback(() => {
    //getting the child props from render;
    //remove height from childProps
    const child = renderChild({});
    if (
      child &&
      child.type === 'Text' &&
      maxHeight &&
      maxHeight > 0 &&
      (!prevTextValue.current || prevTextValue.current !== child.props.text)
    ) {
      let { height, ...childProps } = child.props;
      prevTextValue.current = child.props.text;
      const renderPure = new Konva.Text({ ...childProps });
      const newRowsCount = renderPure.height() / (childProps.fontSize * childProps.lineHeight);
      if (newRowsCount > 1) {
        // setDebugRect(renderPure.height());
        setCollapsible(true);
        if (newRowsCount > maxHeight) {
          setRowsCount(maxHeight);
          return maxHeight;
        }
      }
      setCollapsible(newRowsCount > 1);
      setRowsCount(newRowsCount);
      return newRowsCount;
    }

    return rowsCount;
  }, [maxHeight, renderChild, rowsCount]);

  const innerHeight = shortenText ? 1 : renderChildMaxHeightCalculation();

  const collapseText = useCallback((event: Konva.KonvaEventObject<MouseEvent>) => {
    event.cancelBubble = true;
    setShortenText(prevState => !prevState);
  }, []);

  if (!hasChildren && rest.collapsible) return null;
  return (
    <AutopositionGroup
      yOffset={yOffset}
      xOffset={baseWidth < 280 ? 12 : 0}
      name={name}
      onClick={onClick}
      hasChildren={hasChildren}
      dataType={`${name}:body`}
    >
      <AutosizeRect
        fill='white'
        width={baseWidth - 24}
        cornerRadius={cornerRadius}
        bottomPadding={bottomPadding}
        active={active}
        name={name}
        debugActive={debugActive}
      >
        <Group x={12}>
          {title && (
            <ItemHeading
              icon={icon}
              title={title}
              collapsible={collapsible}
              onClick={collapseText}
              collapsed={shortenText}
              gap={gap}
              hasChildren={hasChildren}
              screenBlock={screenBlock}
              currentContextIndex={rest.currentContextIndex}
            />
          )}
          {renderChild({ innerHeight, collapsed: shortenText })}
        </Group>
      </AutosizeRect>
    </AutopositionGroup>
  );
};
