import React, { ContextType, PureComponent, SyntheticEvent } from 'react';
import { InferenceResultData } from '../../../Caila/api/client';
import { convertConfidenceToPercents, Intents } from '../../../Caila/utils';
import { Icon, IconButton, Tooltip } from '@just-ai/just-ui';
import AlignmentDialog, { Alignment, WordInfoData, AlignmentWeights } from './Alignment';
import { AppContext } from '../../../Caila/components/AppContext';
import { EntityModal } from '../EntityModal';
import classNames from 'classnames';
import { t } from 'localization';

interface IntentDebugData {
  phrase1: Array<WordInfoData>;
  phrase2: Array<WordInfoData>;
  weights: AlignmentWeights;
  alignment: Array<Alignment>;
}

interface IntentProps {
  variant: InferenceResultData;
  onClick?: () => any;
  isShared?: boolean;
}

class IntentState {
  isSlotsOpen: boolean = false;
  isAlignmentOpen: boolean = false;
  isModalOpen: boolean = false;
}

class Intent extends PureComponent<IntentProps, IntentState> {
  static contextType = AppContext;
  context!: ContextType<typeof AppContext>;

  state = new IntentState();

  getDebugInfo = (): IntentDebugData | undefined => {
    const weights = this.props.variant.weights;
    if (
      this.props.variant?.debug?.hasOwnProperty('alignments') &&
      weights?.patterns !== undefined &&
      weights?.phrases !== undefined &&
      weights?.phrases > weights?.patterns
    ) {
      return this.props.variant.debug.alignments as IntentDebugData;
    }
    return undefined;
  };

  alignmentInfo?: IntentDebugData = this.getDebugInfo();

  toggleSlots = () => {
    this.setState({ isSlotsOpen: !this.state.isSlotsOpen });
  };

  showAlignment = () => {
    if (!this.alignmentInfo) {
      return;
    }
    this.setState({
      isAlignmentOpen: true,
    });
  };
  hideAlignment = () => {
    this.setState({
      isAlignmentOpen: false,
    });
  };

  callPropsOnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  toggleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  };

  renderButton = () => {
    const invalidRules = this.props.variant.debug?.rules?.invalid?.refs || [];
    const validRules = this.props.variant.debug?.rules?.valid?.refs || [];

    if (invalidRules.length === 0 && validRules.length === 0) {
      return (
        <>
          <span id={`noRulesIconButton${this.props.variant.intent?.id}`}>
            <IconButton flat square disabled name='farMinus' color='secondary' size='sm' />
          </span>
          <Tooltip target={`noRulesIconButton${this.props.variant.intent?.id}`} placement='left'>
            {t('NLUWidgetRulesNotSpecified')}
          </Tooltip>
        </>
      );
    }
    if (invalidRules && invalidRules.length > 0) {
      return <IconButton flat square name='farTimes' size='sm' onClick={this.toggleModal} />;
    }
    if (invalidRules.length === 0 && validRules && validRules.length > 0) {
      return <IconButton flat square name='farCheck' size='sm' onClick={this.toggleModal} />;
    }
  };

  render() {
    const { isSlotsOpen } = this.state;
    const { variant, isShared } = this.props;
    if (!variant) return null;

    const { intent, confidence, slots } = variant;
    const invalidRules = this.props.variant.debug?.rules?.invalid?.refs;

    if (!intent || !confidence) return null;
    //NOSONAR
    const name = Intents.getNameFromPath(intent.path);
    return (
      <>
        <div
          className={classNames('intent-container', {
            disabled: invalidRules && invalidRules.length > 0,
          })}
          onClick={this.toggleSlots}
          data-test-id='Widget.Intent'
        >
          <div className='percentage' data-test-id='Widget.Intent.Confidence'>
            {convertConfidenceToPercents(confidence)}
          </div>
          <div className='intent-details'>
            <div
              className={!isShared ? 'pseudo-link' : ''}
              style={{
                float: 'left',
                paddingRight: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                width: '100%',
              }}
            >
              <span className='name' onClick={this.callPropsOnClick} data-test-id='Widget.Intent.Name'>
                {name}
              </span>
              {slots && slots.length > 0 ? (
                <div className='slotsKey'>
                  <Icon name={isSlotsOpen ? 'faChevronUp' : 'faChevronDown'} size='sm' />
                </div>
              ) : null}
            </div>
            <div className='intent-class text-muted' title={intent.path} data-test-id='Widget.Intent.Path'>
              {intent.path}
            </div>
          </div>
          {this.renderButton()}
          {this.alignmentInfo ? (
            <IconButton
              name='faBars'
              size='sm'
              onClick={this.showAlignment}
              data-test-id='Widget.Intent.ShowAlignment'
              className='alignmentButton'
            />
          ) : null}
        </div>
        {slots && slots.length > 0 ? (
          <div className={`slotsDrop ${isSlotsOpen ? '' : 'hidden'}`}>
            {slots.map((slot, index) => (
              <div key={`slotKey${index}`} className='row'>
                <div className='col-6'>{slot.name}</div>
                <div className='col-6 pl0'>{slot.value}</div>
              </div>
            ))}
          </div>
        ) : null}
        {this.alignmentInfo && this.state.isAlignmentOpen ? (
          <AlignmentDialog
            phrase1={this.alignmentInfo.phrase1}
            phrase2={this.alignmentInfo.phrase2}
            alignment={this.alignmentInfo.alignment}
            weights={this.alignmentInfo.weights}
            isOpen={this.state.isAlignmentOpen}
            toggle={this.hideAlignment}
          />
        ) : null}
        <EntityModal
          variant={variant}
          toggle={() => this.setState({ isModalOpen: false })}
          isOpen={this.state.isModalOpen}
          redirect={this.callPropsOnClick}
          isShared={this.props.isShared || false}
        />
      </>
    );
  }
}

export default Intent;
