// tslint:disable
//@ts-nocheck
/**
 * asr tts adapter api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AvailableProvidersDTO
 */
export interface AvailableProvidersDTO {
  /**
   *
   * @type {Array<TextToSpeechProviderDetail>}
   * @memberof AvailableProvidersDTO
   */
  customProviders: Array<TextToSpeechProviderDetail>;
  /**
   *
   * @type {Array<TextToSpeechProviderDetail>}
   * @memberof AvailableProvidersDTO
   */
  internalProviders: Array<TextToSpeechProviderDetail>;
}
/**
 *
 * @export
 * @interface DefaultProvidersDTO
 */
export interface DefaultProvidersDTO {
  /**
   *
   * @type {ProviderList}
   * @memberof DefaultProvidersDTO
   */
  asrProvider: ProviderList;
  /**
   *
   * @type {ProviderList}
   * @memberof DefaultProvidersDTO
   */
  ttsProvider: ProviderList;
}
/**
 *
 * @export
 * @interface LanguageAndVoices
 */
export interface LanguageAndVoices {
  /**
   *
   * @type {string}
   * @memberof LanguageAndVoices
   */
  language?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LanguageAndVoices
   */
  voices?: Array<string>;
}
/**
 *
 * @export
 * @interface OldAvailableProvidersDTO
 */
export interface OldAvailableProvidersDTO {
  /**
   *
   * @type {Array<TextToSpeechProviderDetail>}
   * @memberof OldAvailableProvidersDTO
   */
  providers: Array<TextToSpeechProviderDetail>;
}
/**
 *
 * @export
 * @interface ProviderAndLanguages
 */
export interface ProviderAndLanguages {
  /**
   *
   * @type {TextToSpeechProviderDetail}
   * @memberof ProviderAndLanguages
   */
  provider: TextToSpeechProviderDetail;
  /**
   *
   * @type {Array<LanguageAndVoices>}
   * @memberof ProviderAndLanguages
   */
  languages?: Array<LanguageAndVoices>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ProviderList {
  YANDEX = 'YANDEX',
  GOOGLE = 'GOOGLE',
  TINKOFF = 'TINKOFF',
  MTS = 'MTS',
  ZITECH = 'ZITECH',
  AMIVOICE = 'AMIVOICE',
  AZURE = 'AZURE',
  ASM = 'ASM',
  REVERIE = 'REVERIE',
  SBER = 'SBER',
  KALDI = 'KALDI',
  AIMYVOICE = 'AIMYVOICE',
  CAILA = 'CAILA',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SberScope {
  SALUTESPEECHPERS = 'SALUTE_SPEECH_PERS',
  SALUTESPEECHCORP = 'SALUTE_SPEECH_CORP',
  SBERSPEECH = 'SBER_SPEECH',
}

/**
 *
 * @export
 * @interface SpeechProviderAimyvoice
 */
export interface SpeechProviderAimyvoice {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAimyvoice
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface SpeechProviderAmiVoice
 */
export interface SpeechProviderAmiVoice {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAmiVoice
   */
  appKey: string;
}
/**
 *
 * @export
 * @interface SpeechProviderAsm
 */
export interface SpeechProviderAsm {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAsm
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface SpeechProviderAzure
 */
export interface SpeechProviderAzure {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAzure
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAzure
   */
  subscriptionKey: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderAzure
   */
  connectionType: string;
}
/**
 *
 * @export
 * @interface SpeechProviderCreateDTO
 */
export interface SpeechProviderCreateDTO {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderCreateDTO
   */
  name: string;
  /**
   *
   * @type {SpeechProviderYandex}
   * @memberof SpeechProviderCreateDTO
   */
  yandex?: SpeechProviderYandex;
  /**
   *
   * @type {SpeechProviderGoogle}
   * @memberof SpeechProviderCreateDTO
   */
  google?: SpeechProviderGoogle;
  /**
   *
   * @type {SpeechProviderMts}
   * @memberof SpeechProviderCreateDTO
   */
  mts?: SpeechProviderMts;
  /**
   *
   * @type {SpeechProviderAzure}
   * @memberof SpeechProviderCreateDTO
   */
  azure?: SpeechProviderAzure;
  /**
   *
   * @type {SpeechProviderZitech}
   * @memberof SpeechProviderCreateDTO
   */
  zitech?: SpeechProviderZitech;
  /**
   *
   * @type {SpeechProviderAmiVoice}
   * @memberof SpeechProviderCreateDTO
   */
  amiVoice?: SpeechProviderAmiVoice;
  /**
   *
   * @type {SpeechProviderAsm}
   * @memberof SpeechProviderCreateDTO
   */
  asm?: SpeechProviderAsm;
  /**
   *
   * @type {SpeechProviderReverie}
   * @memberof SpeechProviderCreateDTO
   */
  reverie?: SpeechProviderReverie;
  /**
   *
   * @type {SpeechProviderAimyvoice}
   * @memberof SpeechProviderCreateDTO
   */
  aimyvoice?: SpeechProviderAimyvoice;
  /**
   *
   * @type {SpeechProviderTinkoff}
   * @memberof SpeechProviderCreateDTO
   */
  tinkoff?: SpeechProviderTinkoff;
  /**
   *
   * @type {SpeechProviderSber}
   * @memberof SpeechProviderCreateDTO
   */
  sber?: SpeechProviderSber;
  /**
   *
   * @type {SpeechProviderKaldi}
   * @memberof SpeechProviderCreateDTO
   */
  kaldi?: SpeechProviderKaldi;
}
/**
 *
 * @export
 * @interface SpeechProviderDTO
 */
export interface SpeechProviderDTO {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderDTO
   */
  name: string;
  /**
   *
   * @type {SpeechProviderYandex}
   * @memberof SpeechProviderDTO
   */
  yandex?: SpeechProviderYandex;
  /**
   *
   * @type {SpeechProviderGoogle}
   * @memberof SpeechProviderDTO
   */
  google?: SpeechProviderGoogle;
  /**
   *
   * @type {SpeechProviderMts}
   * @memberof SpeechProviderDTO
   */
  mts?: SpeechProviderMts;
  /**
   *
   * @type {SpeechProviderAzure}
   * @memberof SpeechProviderDTO
   */
  azure?: SpeechProviderAzure;
  /**
   *
   * @type {SpeechProviderZitech}
   * @memberof SpeechProviderDTO
   */
  zitech?: SpeechProviderZitech;
  /**
   *
   * @type {SpeechProviderAmiVoice}
   * @memberof SpeechProviderDTO
   */
  amiVoice?: SpeechProviderAmiVoice;
  /**
   *
   * @type {SpeechProviderAsm}
   * @memberof SpeechProviderDTO
   */
  asm?: SpeechProviderAsm;
  /**
   *
   * @type {SpeechProviderReverie}
   * @memberof SpeechProviderDTO
   */
  reverie?: SpeechProviderReverie;
  /**
   *
   * @type {SpeechProviderAimyvoice}
   * @memberof SpeechProviderDTO
   */
  aimyvoice?: SpeechProviderAimyvoice;
  /**
   *
   * @type {SpeechProviderTinkoff}
   * @memberof SpeechProviderDTO
   */
  tinkoff?: SpeechProviderTinkoff;
  /**
   *
   * @type {SpeechProviderSber}
   * @memberof SpeechProviderDTO
   */
  sber?: SpeechProviderSber;
  /**
   *
   * @type {SpeechProviderKaldi}
   * @memberof SpeechProviderDTO
   */
  kaldi?: SpeechProviderKaldi;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderDTO
   */
  id: string | null;
}
/**
 *
 * @export
 * @interface SpeechProviderDTOAllOf
 */
export interface SpeechProviderDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderDTOAllOf
   */
  id: string | null;
}
/**
 *
 * @export
 * @interface SpeechProviderGoogle
 */
export interface SpeechProviderGoogle {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderGoogle
   */
  jsonToken: string;
}
/**
 *
 * @export
 * @interface SpeechProviderKaldi
 */
export interface SpeechProviderKaldi {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderKaldi
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderKaldi
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface SpeechProviderMts
 */
export interface SpeechProviderMts {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderMts
   */
  token: string;
}
/**
 *
 * @export
 * @interface SpeechProviderReverie
 */
export interface SpeechProviderReverie {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderReverie
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderReverie
   */
  appId: string;
}
/**
 *
 * @export
 * @interface SpeechProviderSber
 */
export interface SpeechProviderSber {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderSber
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderSber
   */
  clientSecret: string;
  /**
   *
   * @type {SberScope}
   * @memberof SpeechProviderSber
   */
  scope?: SberScope;
}
/**
 *
 * @export
 * @interface SpeechProviderTinkoff
 */
export interface SpeechProviderTinkoff {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderTinkoff
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderTinkoff
   */
  secretKey: string;
}
/**
 *
 * @export
 * @interface SpeechProviderYandex
 */
export interface SpeechProviderYandex {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderYandex
   */
  folderId: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderYandex
   */
  serviceAccountId: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderYandex
   */
  pemPrivateKey: string;
  /**
   *
   * @type {string}
   * @memberof SpeechProviderYandex
   */
  keyId: string;
}
/**
 *
 * @export
 * @interface SpeechProviderZitech
 */
export interface SpeechProviderZitech {
  /**
   *
   * @type {string}
   * @memberof SpeechProviderZitech
   */
  token: string;
}
/**
 *
 * @export
 * @interface SuggestedVoicesDTO
 */
export interface SuggestedVoicesDTO {
  /**
   *
   * @type {Array<ProviderAndLanguages>}
   * @memberof SuggestedVoicesDTO
   */
  providers: Array<ProviderAndLanguages>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TextToSpeechProviderDetail {
  GOOGLE = 'GOOGLE',
  MTS = 'MTS',
  YANDEX = 'YANDEX',
  ZITECH = 'ZITECH',
  AZURE = 'AZURE',
  BAIDU = 'BAIDU',
  REVERIE = 'REVERIE',
  AIMYVOICE = 'AIMYVOICE',
  CAILA = 'CAILA',
  SBER = 'SBER',
}

/**
 *
 * @export
 * @interface TtsAudioDTO
 */
export interface TtsAudioDTO {
  /**
   *
   * @type {object}
   * @memberof TtsAudioDTO
   */
  audio: object;
}
/**
 *
 * @export
 * @interface TtsConfigDTO
 */
export interface TtsConfigDTO {
  /**
   *
   * @type {TextToSpeechProviderDetail}
   * @memberof TtsConfigDTO
   */
  provider: TextToSpeechProviderDetail;
  /**
   *
   * @type {string}
   * @memberof TtsConfigDTO
   */
  projectLanguage?: string | null;
  /**
   *
   * @type {string}
   * @memberof TtsConfigDTO
   */
  voiceName: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TtsConfigDTO
   */
  useDiagnosticMode?: boolean;
}
/**
 *
 * @export
 * @interface TtsDTO
 */
export interface TtsDTO {
  /**
   *
   * @type {string}
   * @memberof TtsDTO
   */
  text: string;
}
/**
 *
 * @export
 * @interface TtsRequestDTO
 */
export interface TtsRequestDTO {
  /**
   *
   * @type {TtsDTO}
   * @memberof TtsRequestDTO
   */
  speech: TtsDTO;
  /**
   *
   * @type {string}
   * @memberof TtsRequestDTO
   */
  speechId?: string | null;
  /**
   *
   * @type {TtsConfigDTO}
   * @memberof TtsRequestDTO
   */
  ttsConfig: TtsConfigDTO;
}
/**
 *
 * @export
 * @interface TtsRequestDetailDTO
 */
export interface TtsRequestDetailDTO {
  /**
   *
   * @type {object}
   * @memberof TtsRequestDetailDTO
   */
  initiatedAt: object;
  /**
   *
   * @type {string}
   * @memberof TtsRequestDetailDTO
   */
  requestedText: string;
}
/**
 *
 * @export
 * @interface TtsResultDTO
 */
export interface TtsResultDTO {
  /**
   *
   * @type {TtsRequestDetailDTO}
   * @memberof TtsResultDTO
   */
  requestDetail: TtsRequestDetailDTO;
  /**
   *
   * @type {TtsConfigDTO}
   * @memberof TtsResultDTO
   */
  configDetail: TtsConfigDTO;
  /**
   *
   * @type {TtsResultDetailDTO}
   * @memberof TtsResultDTO
   */
  resultDetail: TtsResultDetailDTO | null;
}
/**
 *
 * @export
 * @interface TtsResultDetailDTO
 */
export interface TtsResultDetailDTO {
  /**
   *
   * @type {string}
   * @memberof TtsResultDetailDTO
   */
  key: string;
}

/**
 * AvailableProvidersAndVoicesApi - axios parameter creator
 * @export
 */
export const AvailableProvidersAndVoicesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableProvidersForAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAvailableProvidersForAccount.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/available-providers`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersByHost(accountId: number, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDefaultProvidersByHost.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/default-providers/by-host`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount(accountId: number, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDefaultProvidersForAccount.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/default-providers/by-tariff`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount_1(accountId: number, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDefaultProvidersForAccount_1.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/default-providers`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuggestedVoicesForProvider(options: any = {}): RequestArgs {
      const localVarPath = `/api/asr-tts-adapter/suggested-voices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AvailableProvidersAndVoicesApi - functional programming interface
 * @export
 */
export const AvailableProvidersAndVoicesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableProvidersForAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableProvidersDTO> {
      const localVarAxiosArgs = AvailableProvidersAndVoicesApiAxiosParamCreator(
        configuration
      ).getAvailableProvidersForAccount(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersByHost(
      accountId: number,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultProvidersDTO> {
      const localVarAxiosArgs = AvailableProvidersAndVoicesApiAxiosParamCreator(
        configuration
      ).getDefaultProvidersByHost(accountId, language, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount(
      accountId: number,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultProvidersDTO> {
      const localVarAxiosArgs = AvailableProvidersAndVoicesApiAxiosParamCreator(
        configuration
      ).getDefaultProvidersForAccount(accountId, language, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount_1(
      accountId: number,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultProvidersDTO> {
      const localVarAxiosArgs = AvailableProvidersAndVoicesApiAxiosParamCreator(
        configuration
      ).getDefaultProvidersForAccount_1(accountId, language, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuggestedVoicesForProvider(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestedVoicesDTO> {
      const localVarAxiosArgs =
        AvailableProvidersAndVoicesApiAxiosParamCreator(configuration).getSuggestedVoicesForProvider(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AvailableProvidersAndVoicesApi - factory interface
 * @export
 */
export const AvailableProvidersAndVoicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableProvidersForAccount(accountId: number, options?: any): AxiosPromise<AvailableProvidersDTO> {
      return AvailableProvidersAndVoicesApiFp(configuration).getAvailableProvidersForAccount(accountId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersByHost(accountId: number, language?: string, options?: any): AxiosPromise<DefaultProvidersDTO> {
      return AvailableProvidersAndVoicesApiFp(configuration).getDefaultProvidersByHost(
        accountId,
        language,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount(
      accountId: number,
      language?: string,
      options?: any
    ): AxiosPromise<DefaultProvidersDTO> {
      return AvailableProvidersAndVoicesApiFp(configuration).getDefaultProvidersForAccount(
        accountId,
        language,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} [language] project language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultProvidersForAccount_1(
      accountId: number,
      language?: string,
      options?: any
    ): AxiosPromise<DefaultProvidersDTO> {
      return AvailableProvidersAndVoicesApiFp(configuration).getDefaultProvidersForAccount_1(
        accountId,
        language,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuggestedVoicesForProvider(options?: any): AxiosPromise<SuggestedVoicesDTO> {
      return AvailableProvidersAndVoicesApiFp(configuration).getSuggestedVoicesForProvider(options)(axios, basePath);
    },
  };
};

/**
 * AvailableProvidersAndVoicesApi - object-oriented interface
 * @export
 * @class AvailableProvidersAndVoicesApi
 * @extends {BaseAPI}
 */
export class AvailableProvidersAndVoicesApi extends BaseAPI {
  /**
   *
   * @param {number} accountId Account identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailableProvidersAndVoicesApi
   */
  public getAvailableProvidersForAccount(accountId: number, options?: any) {
    return AvailableProvidersAndVoicesApiFp(this.configuration).getAvailableProvidersForAccount(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId Account identifier.
   * @param {string} [language] project language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailableProvidersAndVoicesApi
   */
  public getDefaultProvidersByHost(accountId: number, language?: string, options?: any) {
    return AvailableProvidersAndVoicesApiFp(this.configuration).getDefaultProvidersByHost(
      accountId,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier.
   * @param {string} [language] project language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailableProvidersAndVoicesApi
   */
  public getDefaultProvidersForAccount(accountId: number, language?: string, options?: any) {
    return AvailableProvidersAndVoicesApiFp(this.configuration).getDefaultProvidersForAccount(
      accountId,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier.
   * @param {string} [language] project language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailableProvidersAndVoicesApi
   */
  public getDefaultProvidersForAccount_1(accountId: number, language?: string, options?: any) {
    return AvailableProvidersAndVoicesApiFp(this.configuration).getDefaultProvidersForAccount_1(
      accountId,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailableProvidersAndVoicesApi
   */
  public getSuggestedVoicesForProvider(options?: any) {
    return AvailableProvidersAndVoicesApiFp(this.configuration).getSuggestedVoicesForProvider(options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * SpeechProviderListApi - axios parameter creator
 * @export
 */
export const SpeechProviderListApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpeechProviderList(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSpeechProviderList.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/speech`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpeechProviderListApi - functional programming interface
 * @export
 */
export const SpeechProviderListApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpeechProviderList(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeechProviderDTO>> {
      const localVarAxiosArgs = SpeechProviderListApiAxiosParamCreator(configuration).getSpeechProviderList(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SpeechProviderListApi - factory interface
 * @export
 */
export const SpeechProviderListApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpeechProviderList(accountId: number, options?: any): AxiosPromise<Array<SpeechProviderDTO>> {
      return SpeechProviderListApiFp(configuration).getSpeechProviderList(accountId, options)(axios, basePath);
    },
  };
};

/**
 * SpeechProviderListApi - object-oriented interface
 * @export
 * @class SpeechProviderListApi
 * @extends {BaseAPI}
 */
export class SpeechProviderListApi extends BaseAPI {
  /**
   *
   * @param {number} accountId Account identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpeechProviderListApi
   */
  public getSpeechProviderList(accountId: number, options?: any) {
    return SpeechProviderListApiFp(this.configuration).getSpeechProviderList(accountId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * TextToSpeechApi - axios parameter creator
 * @export
 */
export const TextToSpeechApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} audioKey Key of synthesized audio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAudio(accountId: number, audioKey: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling downloadAudio.'
        );
      }
      // verify required parameter 'audioKey' is not null or undefined
      if (audioKey === null || audioKey === undefined) {
        throw new RequiredError(
          'audioKey',
          'Required parameter audioKey was null or undefined when calling downloadAudio.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/tts/synthesize/key/{audioKey}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'audioKey'}}`, encodeURIComponent(String(audioKey)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {TtsRequestDTO} ttsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synthesize(accountId: number, ttsRequestDTO: TtsRequestDTO, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling synthesize.'
        );
      }
      // verify required parameter 'ttsRequestDTO' is not null or undefined
      if (ttsRequestDTO === null || ttsRequestDTO === undefined) {
        throw new RequiredError(
          'ttsRequestDTO',
          'Required parameter ttsRequestDTO was null or undefined when calling synthesize.'
        );
      }
      const localVarPath = `/api/asr-tts-adapter/accountId/{accountId}/tts/synthesize`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof ttsRequestDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(ttsRequestDTO !== undefined ? ttsRequestDTO : {})
        : ttsRequestDTO || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TextToSpeechApi - functional programming interface
 * @export
 */
export const TextToSpeechApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} audioKey Key of synthesized audio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAudio(
      accountId: number,
      audioKey: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TtsAudioDTO> {
      const localVarAxiosArgs = TextToSpeechApiAxiosParamCreator(configuration).downloadAudio(
        accountId,
        audioKey,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {TtsRequestDTO} ttsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synthesize(
      accountId: number,
      ttsRequestDTO: TtsRequestDTO,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TtsResultDTO> {
      const localVarAxiosArgs = TextToSpeechApiAxiosParamCreator(configuration).synthesize(
        accountId,
        ttsRequestDTO,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TextToSpeechApi - factory interface
 * @export
 */
export const TextToSpeechApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {string} audioKey Key of synthesized audio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAudio(accountId: number, audioKey: string, options?: any): AxiosPromise<TtsAudioDTO> {
      return TextToSpeechApiFp(configuration).downloadAudio(accountId, audioKey, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier.
     * @param {TtsRequestDTO} ttsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synthesize(accountId: number, ttsRequestDTO: TtsRequestDTO, options?: any): AxiosPromise<TtsResultDTO> {
      return TextToSpeechApiFp(configuration).synthesize(accountId, ttsRequestDTO, options)(axios, basePath);
    },
  };
};

/**
 * TextToSpeechApi - object-oriented interface
 * @export
 * @class TextToSpeechApi
 * @extends {BaseAPI}
 */
export class TextToSpeechApi extends BaseAPI {
  /**
   *
   * @param {number} accountId Account identifier.
   * @param {string} audioKey Key of synthesized audio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextToSpeechApi
   */
  public downloadAudio(accountId: number, audioKey: string, options?: any) {
    return TextToSpeechApiFp(this.configuration).downloadAudio(accountId, audioKey, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier.
   * @param {TtsRequestDTO} ttsRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextToSpeechApi
   */
  public synthesize(accountId: number, ttsRequestDTO: TtsRequestDTO, options?: any) {
    return TextToSpeechApiFp(this.configuration).synthesize(
      accountId,
      ttsRequestDTO,
      options
    )(this.axios, this.basePath);
  }
}
