import React, { FC, ReactText, useCallback } from 'react';
import { JustSelect } from '@just-ai/just-ui';
import { t } from 'localization';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';

type CreateOrChooseStateProps = {
  selectOptions: OptionType[];
  onSelectChange: (value: ReactText) => unknown;
  value?: string;
};

export const CreateOrChooseState: FC<CreateOrChooseStateProps> = ({ value, selectOptions, onSelectChange }) => {
  const selectChange = useCallback(
    (value: ReactText[] | null) => {
      if (value && value[0]) {
        onSelectChange(value[0]);
      }
    },
    [onSelectChange]
  );

  return (
    <JustSelect
      data-test-id='CreateOrChooseState'
      defaultValue={value}
      inputPlaceholder={t('Choose state from dropdown')}
      fullWidth
      options={selectOptions}
      onChange={selectChange}
    />
  );
};
