import loadScript from 'simple-load-script';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

//@ts-ignore
export const toggleWidget = () => window.JustWidget?.toggleWidget();
export const loadWidget = async (url: string) => {
  try {
    await loadScript({
      url,
      inBody: true,
      attrs: {
        id: 'justwidget',
        charset: 'UTF-8',
        async: true,
      },
    });
  } catch (e) {
    window.dispatchEvent(
      new CustomEvent('INNER_EVENT', {
        detail: { code: 'Errors:chatwidget.load.error', type: 'error', title: 'Errors:chatwidget.error.title' },
      })
    );
    throw e;
  }
};

export function timeoutPromise<TResult>(ms: number, err: Error, promise: Promise<TResult>) {
  return new Promise<TResult>((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), ms);
  });
}

export const clearSession = () => cookies.remove('waSessionId', { path: '/' });
export const clearUser = () => cookies.remove('waUserId', { path: '/' });
export const clearTestMode = () => cookies.remove('waTestMode', { path: '/' });
export const setTestMode = () => cookies.set('waTestMode', 'true', { path: '/' });

export const removeWidgetListener = () => document.removeEventListener('justwidget_ready', toggleWidget);
export const addWidgetListener = () => document.addEventListener('justwidget_ready', toggleWidget);

export const removeWidgetFromDom = () => {
  removeWidgetListener();

  //@ts-ignore
  window.webpackJsonpJustWidget = [];
  //@ts-ignore
  if (typeof window.JustWidget?.unmount === 'function') window.JustWidget.unmount();

  document.querySelectorAll('[data-origin="justwidget"]').forEach(instance => instance.remove());

  document.getElementById('justwidget')?.remove();
  document.getElementsByClassName('justwidget')[0]?.remove();
  document.getElementById('widget-root')?.remove();
  document.querySelector('script#justwidget')?.remove();

  //@ts-ignore
  delete window.JustWidget;

  clearSession();
  clearUser();
  clearTestMode();
};

export const forceMountWidget = () => {
  const widget = document.getElementById('widget-root');
  const mountPlace = document.getElementById('WidgetMountPlace');

  if (widget && mountPlace && !widget?.dataset?.notTestWidget) mountPlace.appendChild(widget);
};
