import React, { Component } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

class ChartControl extends Component {
  toggleChart = e => {
    const { toggleCommonChartLabels, name, active } = this.props;
    toggleCommonChartLabels(name, !active);
  };

  render() {
    const { name, color, active, percentages } = this.props;
    let total = 0;
    let value = 0;
    let perc = 0;
    if (!!percentages) {
      total = percentages.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.data;
      }, total);

      value = percentages.find(item => item.name === name);
      perc = parseInt((value.data / total) * 100, 10) + '%';
    }
    return (
      <div
        style={{ whiteSpace: 'pre-wrap' }}
        className={classnames('form-group cursor--pointer ', { 'line-through': !active })}
        onClick={this.toggleChart}
      >
        <div className='d-inline-block' style={{ width: 30, height: 15, marginRight: 5, background: color }} />
        {name}
        {!!percentages ? ' ' + perc : null}
      </div>
    );
  }
}

ChartControl.propTypes = {
  toggleCommonChartLabels: propTypes.func.isRequired,
  percentages: propTypes.array,
};

export default ChartControl;
