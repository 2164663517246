import React, { FC } from 'react';
import { Icon } from '@just-ai/just-ui';
import cn from 'classnames';

import classes from './styles.module.scss';
import { useSelectIntent } from './hooks';
import { t } from 'localization';

type NluSystemIntentItemProps = {
  selectedId?: string;
  setSelectedId: (id: string) => unknown;
  id: string;
  path: string;
};

export const NluSystemIntentItem: FC<NluSystemIntentItemProps> = ({ selectedId, id, setSelectedId, path }) => {
  const setSelectedIdHandler = useSelectIntent(id, setSelectedId);

  return (
    <div
      className={cn('d-flex w-100', classes.CailaIntentsSimple_listItem, {
        [classes.isSelected]: selectedId === id,
      })}
      onClick={setSelectedIdHandler}
    >
      <div className='d-flex font-size-14 flex-grow-1'>{t(`ChooseReadyIntent ${path}`)}</div>
      <div className={cn('d-flex position-relative', classes.CailaIntentsSimple_listItem_action)}>
        <Icon name='farBolt' />
      </div>
    </div>
  );
};
