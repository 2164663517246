import { Component } from 'react';
import history from '../../appHistory';
import { CancelToken } from '../../pipes/functions';
import { GA } from '../../pipes/pureFunctions';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { addMessage } from 'actions/globalAlert.actions';
import { resendEmail } from '../../actions/register.actions';
import { Action, bindActionCreators } from 'redux';
import localize, { t } from '../../localization';
import { getPaymentData } from '../../actions/currentUser.actions';
import * as loginActions from '../../actions/currentUser.actions';
import { Icon, Button } from '@just-ai/just-ui';
import cn from 'classnames';

import moment from 'moment';
import { redirectToPaymentSystem } from 'pipes/paymentFunction';
import { demoblockedLocalization } from './localization/demoblocked.loc';

import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { PaymentStatusInfoDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { AxiosResponse } from 'axios';

import styles from './styles.module.scss';
import { Canceler } from 'axios';
import { RCurrentUser, TariffOptionsData, TariffLimitsData, TariffType } from 'types';

import store from 'store';
import { Color } from '@just-ai/just-ui/src/lib/Common';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { AppLogger } from '@just-ai/logger';
import BillingService from '@just-ai/api/dist/services/BillingService';

localize.addTranslations(demoblockedLocalization);

const accountsadminService = new AccountsadminService();

const iconName = {
  warning: 'farExclamationTriangle',
  success: 'farCheckCircle',
  danger: 'farExclamationSquare',
};

let cancel: Canceler | null = null;

const PAYMENT_WAITING = 'paymentWaiting';

type Props = {
  currentUser: RCurrentUser;
  language: string;
  optionsData: TariffOptionsData;
  limitsData: TariffLimitsData;
  actions: {
    getPaymentData: (
      accountId: number,
      paymentId: string,
      options?: any
    ) => Promise<{ value: AxiosResponse<PaymentStatusInfoDto>; action: Action }>;
    addMessage: (message: AlertNotificationItemProps) => void;
  };
  loginActions: {
    getUserAccount: (obj?: any) => any;
    getManualControlInfo: (accountId: number) => void;
  };
  tariff: TariffType;
};

const BannerType = {
  blocked: 'blocked',
  subscriptionExpires: 'subscriptionExpires',
  paymentUnsuccessful: 'paymentUnsuccessful',
  noSubscriptionCard: 'noSubscriptionCard',
  YandexPayment: 'YandexPayment',
  freeTariffLimitsWarning: 'freeTariffLimitsWarning',
  YandexPaymentSuccess: 'YandexPaymentSuccess',
  usersDangerFinal: 'usersDangerFinal',
  usersDanger: 'usersDanger',
  freeTariffLimitsDanger: 'freeTariffLimitsDanger',
};

class State {
  show: boolean = false;
  type: string | null = null;
  text: React.ReactNode | null = null;
  loading: boolean = false;
  buttonText: string = '';
}

class DemoBlocked extends Component<Props, State> {
  state = new State();

  requestPaymentJob = false;

  closed = false;

  billingService = new BillingService();

  componentWillUnmount() {
    if (this.requestPaymentJob && cancel) cancel('Operation canceled by the user.');
    window.removeEventListener(PAYMENT_WAITING, this.checkUserTariff);
    this.closeBanner();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.language !== nextProps.language) {
      this.state.type &&
        this.setState({
          text: this.getText(this.state.type),
        });
    }
  }

  componentDidMount() {
    window.addEventListener(PAYMENT_WAITING, this.checkUserTariff);
    this.checkUserTariff();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.type === BannerType.YandexPayment && !this.requestPaymentJob && this.props.currentUser) {
      let parsedData = this.getLocalStorageData();

      this.requestPaymentJob = true;
      setTimeout(() => {
        if (parsedData?.paymentId) {
          this.startYandexPaymentJob(parsedData?.paymentId);
        } else {
          this.startYandexPaymentJob(null);
        }
      }, 10000);
    }

    if (!this.state.show) {
      this.checkUserTariff(prevState);
    } else {
      if (
        this.state.type &&
        [BannerType.freeTariffLimitsDanger, BannerType.freeTariffLimitsWarning].includes(this.state.type) &&
        this.props.currentUser &&
        prevProps.optionsData.currentUniqueUsers !== this.props.optionsData.currentUniqueUsers
      ) {
        this.recheckTariffLimits();
      }
    }
  }

  shouldShowLimitBanner = () => {
    const date = localStorage.getItem('limitBannerLastClosedAt');

    if (!date) return true;

    const savedDate = new Date(date);
    const currentDate = new Date();
    const differenceInMs = currentDate.getTime() - savedDate.getTime();
    const hoursInMs = 1000 * 60 * 60;
    const shouldBannerBeShown = differenceInMs >= 24 * hoursInMs;

    return shouldBannerBeShown;
  };

  startYandexPaymentJob = (id: string | null) => {
    const { currentUser, tariff } = this.props;

    if (!Boolean(id)) {
      id = Boolean(this.props.currentUser) ? this.props.currentUser?.paymentProcessing?.id : null;
    }

    if (!id) return;
    this.props.actions
      .getPaymentData(currentUser.account.id, id, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(payload => {
        switch (payload.value.data.status) {
          case 'limits_updated_locally':
          case 'processed':
          case 'succeeded': {
            if (currentUser) {
              localStorage.removeItem(`${currentUser.account.id}_PAYMENT_DATA`);
            }

            let event = new Event(BannerType.YandexPaymentSuccess);
            window.dispatchEvent(event);

            this.requestPaymentJob = false;
            this.setShow(BannerType.YandexPaymentSuccess);
            this.props.loginActions.getUserAccount().then((payload: any) => {
              this.props.loginActions.getManualControlInfo(currentUser.account.id);
              GA('GAEvent', 'payment', 'success', payload.value.data.tariffName, payload.value.data.price);
            });
            break;
          }
          case 'canceled': {
            if (payload.value.data.forSubscription) {
              if (currentUser) {
                localStorage.removeItem(`${currentUser.account.id}_PAYMENT_DATA`);
              }

              let event = new Event(BannerType.YandexPaymentSuccess);
              window.dispatchEvent(event);

              this.requestPaymentJob = false;
              this.setShow(BannerType.YandexPaymentSuccess);
            } else {
              if (currentUser) {
                localStorage.removeItem(`${currentUser.account.id}_PAYMENT_DATA`);
              }
              this.requestPaymentJob = false;
              if (!currentUser?.subscription || tariff?.blocked) {
                this.setShow(BannerType.blocked);
              } else {
                this.closeBanner();
              }
            }
            break;
          }
          default:
            setTimeout(() => {
              this.startYandexPaymentJob(null);
            }, 5000);
        }
      })
      .catch(AppLogger.createErrorHandler('DemoBlocked.startYandexPaymentJob'));
  };

  getLocalStorageData = () => {
    const accountId = this.props.currentUser?.account?.id;
    if (!accountId) return;
    const paymentData = localStorage.getItem(`${accountId}_PAYMENT_DATA`);

    try {
      const parsedData = JSON.parse(paymentData || '{}');
      if (parsedData?.accountId !== accountId) {
        return;
      }
      return parsedData;
    } catch (error) {
      AppLogger.error({
        message: 'DemoBlocked.parsePaymentData',
        exception: error as Error,
      });
    }
  };

  recheckTariffLimits = () => {
    const { limitsData, optionsData, tariff } = this.props;
    if (!limitsData) return null;
    const { uniqueUsers, nluSeconds, asrSeconds } = limitsData;
    const { maxUniqueUsersByPockets, currentUniqueUsers, maxUniqueUsers } = optionsData || {};
    if (
      (!maxUniqueUsersByPockets && maxUniqueUsersByPockets !== 0) ||
      (!currentUniqueUsers && currentUniqueUsers !== 0) ||
      (!maxUniqueUsers && maxUniqueUsers !== 0)
    )
      return null;
    const usedPackageUsers = maxUniqueUsersByPockets - uniqueUsers?.extraLimit;
    const usedUniqueUsers = currentUniqueUsers + usedPackageUsers;
    const maxUniqueUsersCalc = maxUniqueUsers + maxUniqueUsersByPockets;
    const percentageUniqUsers = usedUniqueUsers / maxUniqueUsersCalc;

    const percentageNluSeconds = nluSeconds.usedLimit / nluSeconds.tariffLimit;
    const percentageAsrSeconds = asrSeconds.usedLimit / asrSeconds.tariffLimit;
    const nluSecondsLeft = nluSeconds.tariffLimit - nluSeconds.usedLimit;
    const asrSecondsLeft = asrSeconds.tariffLimit - asrSeconds.usedLimit;
    const percentageMixedMinutes = nluSecondsLeft < asrSecondsLeft ? percentageNluSeconds : percentageAsrSeconds;

    if (tariff.tariffUniqueName === 'free') {
      if (percentageUniqUsers >= 0.8 || percentageMixedMinutes >= 0.8) {
        this.setShow(BannerType.freeTariffLimitsDanger);
      } else {
        this.setShow(BannerType.freeTariffLimitsWarning);
      }
      return true;
    }
  };

  checkUserTariff = (prevState?: State | Event) => {
    const { currentUser, tariff } = this.props;
    const manualControl = tariff?.manualControl;

    const localStoragePaymentData = this.getLocalStorageData();

    if (
      Boolean(currentUser) &&
      Boolean(currentUser.paymentProcessing) &&
      Boolean(currentUser.paymentProcessing.status) &&
      (currentUser.paymentProcessing.status === 'waiting_for_capture' ||
        currentUser.paymentProcessing.status === 'pending') &&
      !this.closed
    ) {
      this.setShow(BannerType.YandexPayment);
      return undefined;
    }

    if (this.props.currentUser && localStoragePaymentData?.paymentId && !this.closed) {
      this.setShow(BannerType.YandexPayment);
      return undefined;
    }

    if (!tariff) {
      return undefined;
    }

    if (Boolean(tariff) && (!Boolean(prevState) || (prevState && 'show' in prevState && !Boolean(prevState.show)))) {
      if (tariff.blocked && Boolean(currentUser?.subscription) && !currentUser?.subscription.decline) {
        this.setShow(BannerType.paymentUnsuccessful);
        return undefined;
      }

      if (tariff.blocked) {
        this.setShow(BannerType.blocked);
        return undefined;
      }

      if (
        !tariff?.manualControl &&
        !Boolean(currentUser?.subscription) &&
        typeof tariff?.price === 'number' &&
        tariff?.price !== 0
      ) {
        this.setShow(BannerType.noSubscriptionCard);
        return undefined;
      }

      if (!tariff.blocked) {
        let dateOfPaymentMoment = tariff?.blocked ? moment(tariff?.startDate).utc() : moment(tariff?.dueDate).utc();

        const nowMoment = moment();
        dateOfPaymentMoment = nowMoment > dateOfPaymentMoment ? nowMoment : dateOfPaymentMoment;
        const subscriptionDaysLeft = dateOfPaymentMoment.diff(nowMoment, 'days');

        if (subscriptionDaysLeft <= 7 && !manualControl) {
          if (
            tariff.price > 0 &&
            (!currentUser?.subscription || (Boolean(currentUser?.subscription) && currentUser?.subscription.decline))
          ) {
            this.setShow(BannerType.subscriptionExpires);
            return undefined;
          }
        }
      }

      if (this.recheckTariffLimits()) return;
    }
  };

  handleBannerClick = () => {
    const { currentUser, actions, tariff } = this.props;
    const { type } = this.state;

    switch (type) {
      case BannerType.blocked: {
        this.billingService
          .changeTariff(tariff.nextTariffUniqueName, window.location.origin + '/accountmanager')
          .then(data => {
            if (data.changed && !data.paymentId && !data.redirectUrl) {
              if (data.changed) {
                history.push('/accountmanager');
              } else {
                this.setState({
                  loading: false,
                });
              }
            } else {
              redirectToPaymentSystem(data, currentUser);
            }
          })
          .catch(e => {
            this.setState({
              loading: false,
            });
            const error = e?.response?.data?.error?.errorCode;
            if (error) {
              actions.addMessage({
                type: 'error',
                message: t(`Payment:BE-error ${error}`),
                time: Date.now(),
                showed: true,
              });
            }
          });
        break;
      }

      case BannerType.subscriptionExpires: {
        GA('GAEvent', 'top_plan', 'clicked');
        history.push('/plans');
        break;
      }

      case BannerType.paymentUnsuccessful: {
        GA('GAEvent', 'top_plan_over', 'clicked');

        history.push('/accountmanager');
        break;
      }

      case BannerType.noSubscriptionCard: {
        accountsadminService
          .createPaymentSubscription(currentUser.account.id, {
            redirectUrl: `${window.location.origin}/accountmanager`,
          })
          .then(({ data }) => {
            this.setState({
              loading: false,
            });
            redirectToPaymentSystem(data, currentUser);
          })
          .catch(e => {
            this.setState({
              loading: false,
            });
            actions.addMessage({
              type: 'error',
              message: Boolean(e.response.data.error) ? e.response.data.error : e.response.data,
              time: Date.now(),
              showed: true,
            });
          });
        break;
      }

      default: {
        history.push('/plans');

        break;
      }
    }
  };

  getText = (type: string) => {
    const { currentUser, optionsData, limitsData, tariff } = this.props;

    const { maxUniqueUsersByPockets, currentUniqueUsers, maxUniqueUsers } = optionsData || {};

    if (maxUniqueUsersByPockets === null || currentUniqueUsers === null || maxUniqueUsers === null) return null;
    const usedPackageUsers = maxUniqueUsersByPockets - limitsData?.uniqueUsers?.extraLimit;
    const usedUniqueUsers = currentUniqueUsers + usedPackageUsers;
    const maxUniqueUsersCalc = maxUniqueUsers + maxUniqueUsersByPockets;
    const uniqueUsersLeft = maxUniqueUsersCalc - usedUniqueUsers;
    const nluSeconds = limitsData?.nluSeconds || {};
    const asrSeconds = limitsData?.asrSeconds || {};
    const nluSecondsLeft = nluSeconds.tariffLimit - nluSeconds.usedLimit + nluSeconds.extraLimit;
    const asrSecondsLeft = asrSeconds.tariffLimit - asrSeconds.usedLimit + asrSeconds.extraLimit;
    const mixedMinutesLeft = Math.floor((nluSecondsLeft < asrSecondsLeft ? nluSecondsLeft : asrSecondsLeft) / 60);

    switch (type) {
      case BannerType.freeTariffLimitsDanger:
      case BannerType.freeTariffLimitsWarning: {
        const locale = currentUser?.language || (localize.getLocale() === 'eng' ? 'EN' : 'RU');
        const intl = new Intl.PluralRules(locale);

        const uniquesDeclination = intl.select(uniqueUsersLeft);
        const minutesDeclination = intl.select(mixedMinutesLeft);

        return (
          <p className='mb-0'>
            {t(
              `DemoBlocked ${type} text`,
              uniqueUsersLeft,
              t(`DemoBlocked uniques ${uniquesDeclination}`),
              mixedMinutesLeft,
              t(`DemoBlocked minutes ${minutesDeclination}`)
            )}
            <NavLink to='/plans'>{t(`DemoBlocked ${type} link`)}</NavLink>
          </p>
        );
      }
      case BannerType.YandexPayment: {
        return (
          <p className='mb-0'>
            <b>
              {t(`DemoBlocked ${type} bold text`, tariff.paymentSystem === 'STRIPE' ? 'Stripe' : t('yandex kassa'))}
            </b>{' '}
            {t(`DemoBlocked ${type} text`)}
          </p>
        );
      }
      case BannerType.subscriptionExpires:
      case BannerType.paymentUnsuccessful: {
        return (
          <p className='mb-0'>
            <b>{t(`DemoBlocked ${type} bold text`)}</b>
          </p>
        );
      }
      case BannerType.noSubscriptionCard: {
        return <p className='mb-0'>{t(`DemoBlocked ${type} text`, moment(tariff.dueDate).format('DD.MM.YYYY'))}</p>;
      }
      case BannerType.blocked: {
        return (
          <p className='mb-0'>
            <b>{t(`DemoBlocked ${type} bold text`)}</b>
          </p>
        );
      }
      case BannerType.YandexPaymentSuccess: {
        return (
          <p className='mb-0'>
            <b>{t(`DemoBlocked ${type} bold text`)}</b> {t(`DemoBlocked ${type} text`)} &#128515;
          </p>
        );
      }

      default:
        return <p className='mb-0'>{t(`DemoBlocked ${type} text`)}</p>;
    }
  };

  getButtons = (type: string) => {
    switch (type) {
      case BannerType.YandexPayment:
      case BannerType.YandexPaymentSuccess: {
        return null;
      }

      case BannerType.freeTariffLimitsDanger:
      case BannerType.freeTariffLimitsWarning: {
        return null;
      }

      default: {
        return t(`DemoBlocked ${type} button text`);
      }
    }
  };

  setShow = (type: string) => {
    if (!this.shouldShowLimitBanner()) return;

    this.setState({
      show: true,
      type: type,
      text: this.getText(type),
      buttonText: this.getButtons(type),
    });
  };

  closeBanner = () => {
    localStorage.setItem('limitBannerLastClosedAt', new Date().toISOString());

    const { currentUser } = this.props;

    if (this.state.type === BannerType.YandexPayment) {
      this.closed = true;
      if (currentUser) {
        localStorage.removeItem(`${currentUser.account.id}_PAYMENT_DATA`);
        this.recheckTariffLimits();
      }
    }
    this.setState({
      show: false,
      type: null,
      text: '',
      buttonText: '',
    });
  };

  getColor = () => {
    switch (this.state.type) {
      case BannerType.YandexPaymentSuccess: {
        return 'success';
      }
      case BannerType.subscriptionExpires:
      case BannerType.YandexPayment:
      case BannerType.freeTariffLimitsWarning:
        return 'warning';
      default:
        return 'danger';
    }
  };

  render() {
    const { show, type, text, buttonText } = this.state;

    if (!show) return null;

    return (
      <div
        className={cn(styles.DemoBlocker, { [styles[this.getColor()]]: this.getColor() })}
        data-test-id='DemoBlocked.LimitBanner'
      >
        <div className={styles.DemoBlocker__limitBanner}>
          <Icon name={iconName[this.getColor()]} color={this.getColor() as Color} />
          {text}
          {buttonText && (
            <Button color={this.getColor()} onClick={this.handleBannerClick} size='sm'>
              {buttonText}
            </Button>
          )}
        </div>
        {type && !['blocked', 'usersDanger', 'usersDangerFinal'].includes(type) && (
          <div className={styles.DemoBlocker__close} onClick={this.closeBanner}>
            <Icon name='falTimes' />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: {
  CurrentUserReducer: { currentUser: RCurrentUser; language: string };
  AccountManagerReducer: {
    optionsData: TariffOptionsData;
    limitsData: TariffLimitsData;
    tariff: TariffType;
  };
}) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    language: state.CurrentUserReducer.language,
    optionsData: state.AccountManagerReducer.optionsData,
    limitsData: state.AccountManagerReducer.limitsData,
    tariff: state.AccountManagerReducer,
  };
}

const mapDispatchToProps = (dispatch: typeof store.dispatch) => ({
  actions: bindActionCreators(
    {
      resendEmail,
      getPaymentData,
      addMessage,
    },
    dispatch
  ),
  loginActions: bindActionCreators(
    {
      ...loginActions,
    },
    dispatch
  ),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DemoBlocked));
