import { State } from 'modules/Editor/api/client';
import FileNode from 'modules/Editor/model/FileNode';
import { getFullState, isPreviousNonEmptyTokenInArray, removePathPrefix } from './utils';

export class States {
  static KEYWORDS = ['go:', 'go!:'];
  static NAME = 'state';

  static hasKeyword = (value: string) => States.KEYWORDS.some(keyword => value.includes(keyword));

  localStates: State[];
  states: State[];
  fileName: string;

  constructor(states: State[], fileName: string) {
    this.states = states;
    this.localStates = states.filter(state => state.locator.filename === fileName).sort(States.byRow);
    this.fileName = fileName;
  }

  getCompletions = (value: string, row: number, indentLevel: number) => {
    const fullState = getFullState(value, row, indentLevel, this.localStates);

    return this.states.filter(States.isCompletionFor(fullState)).map(state => ({
      value: removePathPrefix(state.name, fullState),
      name: state.name,
      score: 1000,
      meta: FileNode.getName(state.locator.filename),
    }));
  };

  hasCompletions = (tokens: string[], row: number, indentLevel: number) => {
    const fullState = getFullState(tokens[tokens.length - 1], row, indentLevel, this.localStates);

    return this.states.some(States.isCompletionFor(fullState));
  };

  isApplicable = (tokens: string[], row: number, indentLevel: number) =>
    isPreviousNonEmptyTokenInArray(tokens, States.KEYWORDS) && this.hasCompletions(tokens, row, indentLevel);

  static isCompletionFor = (value: string) => (state: State) => state.name.startsWith(value) && state.name !== value;
  static byRow = (a: State, b: State) => a.locator.line - b.locator.line;
}
