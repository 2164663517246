import React, { FC, useLayoutEffect, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Rect } from 'react-konva';
import Konva from 'konva';
import { callBlocksRedraw, getAboveGroupsHeightsAndOffsets } from '../../utils/blockLayerUtils';
import { activeColor } from './KIcons';
import { RenderBlocksContextType, useRenderBlocksContext } from './RenderBlocks';
import { getStageFromRef } from '../../utils/stageUtils';
import { bgActiveRect, debugActiveColor } from '../../colors';

export type TAutosizeRectProps = {
  bottomPadding?: number;
  active?: boolean;
  debugActive?: boolean;
  name?: string;
  cornerRadius?: number | number[];
  stroke?: string;
  fill?: string;
  width?: number;
  defaultHeight?: number;
  activeTypeProp?: RenderBlocksContextType['activeType'];
};

const DEFAULT_HEIGHT = 50;

export const AutosizeRect: FC<TAutosizeRectProps> = ({
  children,
  name,
  active,
  debugActive,
  fill = '#F4F5F5',
  width = 280,
  cornerRadius = 0,
  bottomPadding = 8,
  defaultHeight = DEFAULT_HEIGHT,
  stroke = '#E0E1E3',
  activeTypeProp = 'border',
}) => {
  const { activeType = activeTypeProp } = useRenderBlocksContext();
  const [height, setHeight] = useState(defaultHeight);
  const selfRef = useRef<Konva.Rect>(null);

  const calculateHeight = useCallback(() => {
    const childrenWithoutSelf = (selfRef.current?.parent?.children || []).filter(
      child => child._id !== selfRef.current?._id
    );
    const everyChildShouldBeGroup = childrenWithoutSelf.every(child => child.getType() === 'Group');
    if (!everyChildShouldBeGroup) {
      throw Error('Every child in AutosizeRect should be Group!');
    }
    const childrenHaO = getAboveGroupsHeightsAndOffsets(childrenWithoutSelf);

    setHeight(childrenHaO.height + childrenHaO.offsetY + bottomPadding);
  }, [bottomPadding]);

  useLayoutEffect(() => {
    if (selfRef.current) {
      requestAnimationFrame(calculateHeight);
    }
  }, [calculateHeight, children]);

  useEffect(() => {
    if (height !== DEFAULT_HEIGHT && name) {
      const stage = getStageFromRef(selfRef);
      requestAnimationFrame(() => callBlocksRedraw(selfRef.current, stage!));
    }
  }, [height, name]);

  const styles = useMemo(() => {
    if (activeType !== 'border') {
      let bgActiveStyles = {
        fill: fill,
        stroke: undefined,
        strokeWidth: undefined,
      };
      if (active) {
        bgActiveStyles.fill = bgActiveRect;
      }
      return bgActiveStyles;
    }
    //border default
    const style = {
      fill: fill,
      stroke: stroke,
      strokeWidth: 1,
    };
    if (debugActive) {
      style.stroke = debugActiveColor;
      style.strokeWidth = 2;
    }
    //active last because it overrides all
    if (active) {
      style.stroke = activeColor;
      style.strokeWidth = 2;
    }
    return style;
  }, [activeType, fill, stroke, active, debugActive]);

  return (
    <>
      <Rect
        fill={styles.fill}
        stroke={styles.stroke}
        strokeWidth={styles.strokeWidth}
        width={width}
        height={height}
        ref={selfRef}
        cornerRadius={cornerRadius}
        calculateHeight={calculateHeight}
        name={`AutosizeRect ${name}`}
        parentNeedToPlaceAfter={activeType === 'background'}
      />
      {children}
    </>
  );
};
