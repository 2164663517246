import { t } from 'localization';

import {
  buildCustomValidationResolver,
  composeCustomValidationResolver,
} from 'utils/validator/customValidationResolver';

import { isNameAlreadyExist, isTextHasSlash, joinPaths } from '../stageUtils';

const checkEmptyStateName = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (name) return {};
  return { name: '' };
});

const checkDoubleVerticalSlashes = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (!/\|\|/.test(name)) return {};
  return { name: t('CreationScreenMenu:NameField:Error:DoubleVerticalSlashes') };
});

const checkStateNameIsBusy = buildCustomValidationResolver<
  { name: string },
  { allStates: string[]; existedThemes: string[]; parentPath?: string }
>(async ({ name }, context) => {
  if (!context) throw new Error('Context in validator "checkStateNameIsBusy" not found');
  if (isNameAlreadyExist(name, context.allStates, context.parentPath)) {
    return {
      name: t('CreationScreenMenu:NameField:Error:AlreadyExist'),
    };
  }
  if (isNameAlreadyExist(name, context.existedThemes, context.parentPath)) {
    return {
      name: t('CreationScreenMenu:NameField:Error:AlreadyExistTheme', {
        themeName: joinPaths(name, context.parentPath),
      }),
    };
  }
  return {};
});

export const checkSlashInStateName = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  if (!isTextHasSlash(name)) return {};
  return { name: t('CreationScreenMenu:title:error:denySlash') };
});

export const stateNameValidation = composeCustomValidationResolver(
  checkEmptyStateName,
  checkSlashInStateName,
  checkDoubleVerticalSlashes,
  checkStateNameIsBusy
);
