export function isAbleToApplyHotKey() {
  const focusedElement = document.activeElement;
  if (!focusedElement) return false;
  const nodeType = focusedElement?.tagName.toLowerCase();
  const isContentEditable = focusedElement?.hasAttribute('contenteditable');
  return !['input', 'textarea'].includes(nodeType) && !isContentEditable;
}

export function callIfAbleToApplyHotKey(cb: () => void) {
  if (isAbleToApplyHotKey()) {
    cb();
  }
}
