export const channeltypeLocalization = {
  'ChannelType YANDEX': {
    eng: 'Yandex Alice',
    ru: 'Алиса',
  },
  'ChannelType NO_CHANNELS': {
    eng: 'Without channels',
    ru: 'Без каналов',
  },
  'ChannelType INSTAGRAM': {
    eng: 'Instagram',
    ru: 'Instagram',
  },
  'ChannelType CHAT_WIDGET': {
    eng: 'Chat widget',
    ru: 'Чат-виджет',
    cn: '聊天小部件!',
  },
  'ChannelType GOOGLE': {
    eng: 'Google Assistant',
    ru: 'Google Ассистент',
    cn: 'google助手',
  },
  'ChannelType TELEGRAM': {
    eng: 'Telegram',
    ru: 'Телеграм',
    cn: '电报',
  },
  'ChannelType ZENBOX': {
    eng: 'Aimybox',
    ru: 'Aimybox',
  },
  'ChannelType JAICP_ALEXA': {
    eng: 'Alexa',
    ru: 'Alexa',
  },
  'ChannelType DIALOGFLOW': {
    eng: 'Dialogflow',
    ru: 'Dialogflow',
  },
  'ChannelType CHAT_API': {
    eng: 'Chat API',
    ru: 'Chat API',
    cn: '聊天API',
  },
  'ChannelType CHATAPI': {
    eng: 'Chat API',
    ru: 'Chat API',
    cn: '聊天API',
  },
  'ChannelType VK': {
    eng: 'VK',
    ru: 'ВКонтакте',
  },
  'ChannelType FACEBOOK': {
    eng: 'Facebook',
    ru: 'Facebook',
  },
  'ChannelType VIBER': {
    eng: 'Viber',
    ru: 'Viber',
  },
  'ChannelType RESTERISK': {
    eng: 'Telephony',
    ru: 'Телефония',
    cn: '电话',
  },
  'ChannelType SLACK': {
    eng: 'Slack',
    ru: 'Slack',
  },
  'ChannelType LIVETEX_PROMPTER': {
    eng: 'LiveTex',
    ru: 'LiveTex',
  },
  'ChannelType WECHAT': {
    eng: 'WeChat',
    ru: 'WeChat',
    cn: '微信',
  },
  'ChannelType ZENDESK': {
    eng: 'ZenDesk',
    ru: 'ZenDesk',
  },
  'ChannelType ZOPIM': {
    eng: 'ZenDesk Chat',
    ru: 'ZenDesk Chat',
  },
  'ChannelType WEBIM': {
    eng: 'Webim (External Bot API 1.0)',
    ru: 'Webim (External Bot API 1.0)',
  },
  'ChannelType INCOMING_WEBIM2': {
    eng: 'Webim (External Bot API 2.0)',
    ru: 'Webim (External Bot API 2.0)',
  },
  'ChannelType CUSTOM_CHANNEL_API': {
    eng: 'Webim (Custom Channel API)',
    ru: 'Webim (Custom Channel API)',
  },
  'ChannelType NEXMO': {
    eng: 'Vonage',
    ru: 'Vonage',
  },
  'ChannelType THREADS': {
    eng: 'edna.chatCenter',
    ru: 'edna.chatCenter',
  },
  'ChannelType I_DIGITAL': {
    eng: 'i-Digital WhatsApp',
    ru: 'i-Digital WhatsApp',
  },
  'ChannelType INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
  },
  'ChannelType AZURE': {
    eng: 'Azure Bot',
    ru: 'Azure Bot',
  },
  'ChannelType MARUSIA': {
    eng: 'Marusia',
    ru: 'Маруся',
  },
  'ChannelType WAZZUP': {
    eng: 'Wazzup',
    ru: 'Wazzup',
  },
  'ChannelType WHATSAPP': {
    eng: 'Whatsapp',
    ru: 'Whatsapp',
  },
  'ChannelType BITRIX': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
  },
  'ChannelType ODNOKLASSNIKI': {
    eng: 'Odnoklassniki',
    ru: 'Одноклассники',
  },
  'ChannelType SALESFORCE': {
    eng: 'Salesforce',
    ru: 'Salesforce',
  },
  'ChannelType JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
  },
  'ChannelType AIMYCHAT': {
    eng: 'Aimychat',
    ru: 'Aimychat',
  },
  'ChannelType TOVIE AGENT': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
  },
  'ChannelType OPERATORAPI': {
    eng: 'Operator API',
    ru: 'Operator API',
  },
  'ChannelType SBER': {
    eng: 'Sber Salut',
    ru: 'Сбер Салют',
  },
  'ChannelType LIVETEX': {
    eng: 'LiveTex (obsolete)',
    ru: 'LiveTex (устарел)',
  },
  'ChannelType LIVETEX_VISITOR': {
    eng: 'LiveTex',
    ru: 'LiveTex',
  },
  'ChannelType PROMPTER_API': {
    eng: 'Prompter API',
    ru: 'Prompter API',
  },
  'ChannelType INBOUND_LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
  },
  'ChannelType CHAT2DESK': {
    eng: 'Chat2Desk',
    ru: 'Chat2Desk',
  },
  'ChannelType EDNA_PLATFORM': {
    eng: 'edna WhatsApp 2.0',
    ru: 'edna WhatsApp 2.0',
  },
  'ChannelType resterisk': {
    eng: 'Telephony',
    ru: 'Телефония',
    cn: '电话',
  },
  'ChannelType MATTERMOST': {
    eng: 'Mattermost',
    ru: 'Mattermost',
  },
  'DeployChannelDropdown:PublishButtonText': {
    eng: 'Publish',
    ru: 'Опубликовать',
    cn: 'Publish',
  },
  'Auth:Facebook:Continue': {
    eng: 'Continue with Facebook',
  },
  'Auth:Facebook:Connection:Fail': {
    eng: 'Connection error',
  },
  'Auth:Facebook:Account:Fail': {
    eng: 'Account connection error',
  },
  'Auth:Instagram:Connection:Fail': {
    eng: 'Connection error',
  },
  'Auth:Instagram:Account:Fail': {
    eng: 'Account connection error',
  },
  'Auth:Facebook:Error:Base': {
    eng: 'An error occurred while connecting. Please try again or contact technical support.',
    ru: 'При подключении возникла ошибка. Попробуйте еще раз или обратитесь в службу технической поддержки.',
  },
  'Facebook:Banner:Message': {
    eng: 'To connect your bot to Facebook, allow $[1] - Tovie Platform to send messages from your page. <a href="$[2]">Learn more about our Facebook integration</a>',
  },
  'Instagram:Banner:Message': {
    eng: 'To connect your bot to Instagram, allow $[1] - Tovie Platform to send messages from your page. <a href="$[2]">Learn more about our Instagram integration</a>',
  },
  'WhatsApp:Banner:Message': {
    eng: 'To connect your bot to WhatsApp, allow $[1] - Tovie Platform to send messages from your page. <a href="$[2]">Learn more about our Whatsapp integration</a>',
  },
  'ChannelsAdding:Aimychat:Group': {
    eng: 'Default agent group',
    ru: 'Группа операторов по умолчанию',
  },
  'ChannelsAdding:Aimychat:Group:Placeholder': {
    eng: 'Select group',
    ru: 'Выберите группу',
  },
  'Connect:Aimychat': {
    eng: 'How to connect a channel Aimychat?',
    ru: 'Как подключить канал Aimychat?',
  },
  'Connect:Aimychat:Tovie': {
    eng: 'How to connect a channel Tovie Agent?',
    ru: 'Как подключить канал Tovie Agent?',
  },
  'Connect:Aimychat:Docs:Link': {
    eng: '#{config.botadmin.helpUrl}/operator_channels/agent-platform',
    ru: '#{config.botadmin.helpUrl}/operator_channels/agent-platform',
  },
  'Error:Webim2:WrongCredentials': {
    eng: 'Invalid credentials',
    ru: 'Неправильные учетные данные',
  },
  'Error:Webim2:NoInternetConnection': {
    eng: 'No internet connection',
    ru: 'Нет соединения с интернетом',
  },
  'Connect:Prompter': {
    eng: 'How to connect the Prompter API channel',
    ru: 'Как подключить канал Prompter API?',
  },
  'Connect:Prompter:Error': {
    eng: 'Create channel failed because of the system error.<br />Please try again later.',
    ru: 'Не удалось создать канал из-за ошибки системы.<br />Попробуйте позже.',
  },
  'Connect:Prompter:Link': {
    eng: '#{config.botadmin.helpUrl}/prompter/prompter-api',
    ru: '#{config.botadmin.helpUrl}/prompter/prompter-api',
  },
  'Auth:NoChannels:Instagram': {
    ru: 'Канал Instagram не подключен. При настройке разрешений для $[1] вы не выбрали бизнес-аккаунт Instagram или связанную с ним Facebook-страницу. Пройдите процедуру подключения еще раз.',
    eng: 'The Instagram channel is not connected. While configuring permissions for $[1], you have not specified an Instagram Business Account or a Facebook Page connected to it. Please connect the channel again.',
  },
  'How:Connect:Instagram': {
    ru: 'Подробнее',
    eng: 'Read more',
  },
};
