import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TJBlock } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { resortBlocksInIf } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';

const resortBlocksInIfAsync = createAsyncThunk(
  'JGraph/resortBlocksInScreenAsync',
  async (data: { newBlocks: TJBlock[]; contextPrefix: string }, thunkAPI) => {
    await thunkAPI.dispatch(resortBlocksInIf(data));
    const state = thunkAPI.getState() as RootState;
    let newScreen = findScreenByPathId(
      state.JGraphReducer.editMenuBlock?.screen.pathId || '',
      state.JGraphReducer.graph.blocks
    );
    if (newScreen) {
      mainSave$.next({
        type: 'update',
        path: newScreen.path,
        action: () => {
          return thunkAPI.dispatch(updateState(newScreen as JStateWithId));
        },
      });
    }
  }
);

export default resortBlocksInIfAsync;
