import { applyMiddleware, createStore } from 'redux';
import createRootReducer from './reducers';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import history from './appHistory';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { isDev } from './pipes/pureFunctions';
import { reduxStateReference } from 'services/ReduxStateReference';

const middleware = applyMiddleware(promise, thunk, routerMiddleware(history));
const composeEnhancers = composeWithDevTools({ trace: isDev(), traceLimit: 10 });
const store = createStore(createRootReducer(history), composeEnhancers(middleware));

reduxStateReference.setStore(store);

export default store;
