import React, { FC, useState, useCallback } from 'react';
import { InlineRadioButton, Icon, FileUpload, Option, InputText, FormText, Message } from '@just-ai/just-ui';
import { t } from 'localization';
import { useRightSideMenuContext } from '../index';
import { TagNames, TJBlock } from '../../../utils/types';
import { axios } from '../../../../../pipes/functions';
import { session } from 'modules/JGraph/utils/insertSessionFunction';

export const ImageSettings: FC<{}> = () => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();
  const editBlock = getEditBlockTag() as TJBlock<TagNames.image>;
  const imageTagValue = editBlock.tagValue || '';
  const imageLinkWithSession = imageTagValue.includes('}}');
  const [activeTab, setActiveTab] = useState(imageLinkWithSession ? 'link' : 'file');
  const [isLoading, setLoading] = useState(false);

  const tabChange = useCallback((_, tab: Option) => {
    setActiveTab(tab.value as string);
  }, []);

  const loadImage = useCallback(
    (value: FileList) => {
      setLoading(true);
      let fd = new FormData();
      fd.append('file', value[0]);
      axios.post('/restapi/file/upload', fd).then(payload => {
        onChangeCommonTag({ tagValue: payload.data });
        setLoading(false);
      });
    },
    [onChangeCommonTag]
  );

  // @ts-ignore
  return (
    <div className='JGraph-RightSideMenu-commonContainer'>
      <div className='InlineButtons-full-width'>
        <InlineRadioButton
          active={activeTab}
          color='primary'
          name='buttonType'
          onChange={tabChange}
          options={[
            { name: t('ImageSettings:upload-file'), value: 'file', dataTestId: 'JGraph.RightSideMenu.Image.file' },
            { name: t('ImageSettings:file-link'), value: 'link', dataTestId: 'JGraph.RightSideMenu.Image.link' },
          ]}
          outline
          size='default'
        />
      </div>
      {activeTab === 'file' && (
        <div className='ImageSettings-FileUploadContainer'>
          {!imageTagValue && (
            <FileUpload
              accept='image/gif, image/jpeg, image/png'
              onChange={loadImage}
              loading={isLoading}
              loadingType='progressBar'
            >
              <span
                className='pseudoLink'
                onClick={e => e.preventDefault()}
                data-test-id='JGraph.RightSideMenu.Image.upload-file'
              >
                {t('ImageSettings:drop_image')}
              </span>
              {'\n'}
              {t('ImageSettings:drop_image_subtext')}
            </FileUpload>
          )}

          {/*<div className='divider' />*/}
          <div className='image'>
            {!imageLinkWithSession && imageTagValue && (
              <>
                <img
                  src={imageTagValue}
                  data-test-id='JGraph.RightSideMenu.Image.image'
                  alt='pure link without session'
                />
                <div className='cleanIcon' onClick={() => onChangeCommonTag({ tagValue: '' })}>
                  <Icon name='farTimes' size='xs' />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {activeTab === 'link' && (
        <div className='ImageSettings-text-input'>
          <Icon name='farLink' />
          <InputText
            data-test-id='JGraph.RightSideMenu.Image.LinkText'
            defaultValue={session.parse(imageTagValue)}
            onChange={value => onChangeCommonTag({ tagValue: value })}
          />
          <FormText>{t('ImageSettings:vars_can_be_added')}</FormText>
          {imageLinkWithSession && <Message type='info' text={t('ImageSettings:info_message')} />}
        </div>
      )}
    </div>
  );
};
