// tslint:disable
// @ts-nocheck
/**
 * Aimychat service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AddOperatorDto
 */
export interface AddOperatorDto {
  /**
   * User login
   * @type {string}
   * @memberof AddOperatorDto
   */
  login: string;
  /**
   *
   * @type {OperatorRole}
   * @memberof AddOperatorDto
   */
  role: OperatorRole;
}
/**
 * How clients are sorted in the \"Queue\" tab
 * @export
 * @enum {string}
 */
export enum ClientQueueSorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 *
 * @export
 * @interface CreateOperatorGroupDto
 */
export interface CreateOperatorGroupDto {
  /**
   * Name of operator group
   * @type {string}
   * @memberof CreateOperatorGroupDto
   */
  name: string;
  /**
   * List of operator ids to add to the group.
   * @type {Array<number>}
   * @memberof CreateOperatorGroupDto
   */
  operators?: Array<number>;
}
/**
 *
 * @export
 * @interface E2ECheckResult
 */
export interface E2ECheckResult {
  /**
   *
   * @type {string}
   * @memberof E2ECheckResult
   */
  totalStatus?: string;
  /**
   *
   * @type {number}
   * @memberof E2ECheckResult
   */
  totalTime?: number;
  /**
   *
   * @type {Array<E2EComponent>}
   * @memberof E2ECheckResult
   */
  e2EComponents?: Array<E2EComponent>;
}
/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime?: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime?: number;
}
/**
 *
 * @export
 * @interface OperatorDto
 */
export interface OperatorDto {
  /**
   * Id of operator
   * @type {number}
   * @memberof OperatorDto
   */
  id: number;
  /**
   * Login of operator
   * @type {string}
   * @memberof OperatorDto
   */
  login: string;
  /**
   * Is the operator online
   * @type {boolean}
   * @memberof OperatorDto
   */
  isOnline: boolean;
  /**
   *
   * @type {OperatorRole}
   * @memberof OperatorDto
   */
  role: OperatorRole;
  /**
   * First name of operator
   * @type {string}
   * @memberof OperatorDto
   */
  firstName?: string;
  /**
   * Last name of operator
   * @type {string}
   * @memberof OperatorDto
   */
  lastName?: string;
  /**
   * Phone number of operator
   * @type {string}
   * @memberof OperatorDto
   */
  phone?: string;
  /**
   * Photo url of operator
   * @type {string}
   * @memberof OperatorDto
   */
  photoUrl?: string;
  /**
   * Email of operator
   * @type {string}
   * @memberof OperatorDto
   */
  email?: string;
}
/**
 *
 * @export
 * @interface OperatorGroupDto
 */
export interface OperatorGroupDto {
  /**
   * Id of operator group.
   * @type {number}
   * @memberof OperatorGroupDto
   */
  id: number;
  /**
   * Name of operator group
   * @type {string}
   * @memberof OperatorGroupDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface OperatorGroupWithOperatorsDto
 */
export interface OperatorGroupWithOperatorsDto {
  /**
   * Id of operator group.
   * @type {number}
   * @memberof OperatorGroupWithOperatorsDto
   */
  id: number;
  /**
   * Name of operator group
   * @type {string}
   * @memberof OperatorGroupWithOperatorsDto
   */
  name: string;
  /**
   * Operators that are in a group
   * @type {Array<OperatorDto>}
   * @memberof OperatorGroupWithOperatorsDto
   */
  operators: Array<OperatorDto>;
}
/**
 *
 * @export
 * @interface OperatorGroupsDto
 */
export interface OperatorGroupsDto {
  /**
   * Operator groups
   * @type {Array<OperatorGroupWithOperatorsDto>}
   * @memberof OperatorGroupsDto
   */
  groups: Array<OperatorGroupWithOperatorsDto>;
}
/**
 *
 * @export
 * @interface OperatorIdDto
 */
export interface OperatorIdDto {
  /**
   * Id of operator
   * @type {number}
   * @memberof OperatorIdDto
   */
  id: number;
}
/**
 *
 * @export
 * @interface OperatorPlaceDto
 */
export interface OperatorPlaceDto {
  /**
   * Id of operator place. Same as account id.
   * @type {number}
   * @memberof OperatorPlaceDto
   */
  id: number;
  /**
   * Token for creating an operator channel.
   * @type {string}
   * @memberof OperatorPlaceDto
   */
  token: string;
  /**
   * Whether it is necessary to transfer clients to the operator automatically.
   * @type {boolean}
   * @memberof OperatorPlaceDto
   */
  autoAssignment: boolean;
  /**
   * How many clients can the operator communicate with at the same time.
   * @type {number}
   * @memberof OperatorPlaceDto
   */
  maxActiveChatsForOperator: number;
  /**
   *
   * @type {ClientQueueSorting}
   * @memberof OperatorPlaceDto
   */
  clientQueueSorting: ClientQueueSorting;
  /**
   * After what time (seconds) of silence of the operator the client will be transferred to the general queue.
   * @type {number}
   * @memberof OperatorPlaceDto
   */
  maxOperatorResponseTime: number;
}
/**
 *
 * @export
 * @interface OperatorPlaceTokenDto
 */
export interface OperatorPlaceTokenDto {
  /**
   * Token for creating an operator channel.
   * @type {string}
   * @memberof OperatorPlaceTokenDto
   */
  token: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum OperatorRole {
  AGENT = 'AGENT',
  ADMIN = 'ADMIN',
}

/**
 *
 * @export
 * @interface OperatorWithGroupsDto
 */
export interface OperatorWithGroupsDto {
  /**
   * Id of operator
   * @type {number}
   * @memberof OperatorWithGroupsDto
   */
  id: number;
  /**
   * Login of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  login: string;
  /**
   * Is the operator online
   * @type {boolean}
   * @memberof OperatorWithGroupsDto
   */
  isOnline: boolean;
  /**
   *
   * @type {OperatorRole}
   * @memberof OperatorWithGroupsDto
   */
  role: OperatorRole;
  /**
   * First name of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  firstName?: string;
  /**
   * Last name of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  lastName?: string;
  /**
   * Phone number of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  phone?: string;
  /**
   * Photo url of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  photoUrl?: string;
  /**
   * Email of operator
   * @type {string}
   * @memberof OperatorWithGroupsDto
   */
  email?: string;
  /**
   * Operator groups that the operator belongs to
   * @type {Array<OperatorGroupDto>}
   * @memberof OperatorWithGroupsDto
   */
  groups: Array<OperatorGroupDto>;
}
/**
 *
 * @export
 * @interface OperatorsDto
 */
export interface OperatorsDto {
  /**
   * Operators
   * @type {Array<OperatorWithGroupsDto>}
   * @memberof OperatorsDto
   */
  operators: Array<OperatorWithGroupsDto>;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface ResultDto
 */
export interface ResultDto {
  /**
   *
   * @type {boolean}
   * @memberof ResultDto
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface ResultWithOperatorDto
 */
export interface ResultWithOperatorDto {
  /**
   *
   * @type {boolean}
   * @memberof ResultWithOperatorDto
   */
  result: boolean;
  /**
   *
   * @type {OperatorWithGroupsDto}
   * @memberof ResultWithOperatorDto
   */
  operator?: OperatorWithGroupsDto;
}
/**
 *
 * @export
 * @interface UpdateOperatorDto
 */
export interface UpdateOperatorDto {
  /**
   *
   * @type {OperatorRole}
   * @memberof UpdateOperatorDto
   */
  role: OperatorRole;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateOperatorDto
   */
  operatorGroupIds: Array<number>;
}
/**
 *
 * @export
 * @interface UpdateOperatorGroupDto
 */
export interface UpdateOperatorGroupDto {
  /**
   * Name of operator group
   * @type {string}
   * @memberof UpdateOperatorGroupDto
   */
  name: string;
  /**
   * List of operator ids to add to the group.
   * @type {Array<number>}
   * @memberof UpdateOperatorGroupDto
   */
  operators?: Array<number>;
}
/**
 *
 * @export
 * @interface UpdateOperatorPlaceDto
 */
export interface UpdateOperatorPlaceDto {
  /**
   * Whether it is necessary to transfer clients to the operator automatically.
   * @type {boolean}
   * @memberof UpdateOperatorPlaceDto
   */
  autoAssignment: boolean;
  /**
   * How many clients can the operator communicate with at the same time.
   * @type {number}
   * @memberof UpdateOperatorPlaceDto
   */
  maxActiveChatsForOperator: number;
  /**
   *
   * @type {ClientQueueSorting}
   * @memberof UpdateOperatorPlaceDto
   */
  clientQueueSorting: ClientQueueSorting;
  /**
   * After what time (seconds) of silence of the operator the client will be transferred to the general queue.
   * @type {number}
   * @memberof UpdateOperatorPlaceDto
   */
  maxOperatorResponseTime: number;
}
/**
 *
 * @export
 * @interface UpdateOperatorStatusDto
 */
export interface UpdateOperatorStatusDto {
  /**
   * Is operator online
   * @type {boolean}
   * @memberof UpdateOperatorStatusDto
   */
  isOnline: boolean;
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.'
        );
      }
      const localVarPath = `/api/aimychat-be/e2e-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (zRequestId !== undefined && zRequestId !== null) {
        localVarHeaderParameter['Z-requestId'] = String(zRequestId);
      }

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(
      loggingEnabledE2ECheck: boolean,
      zRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ECheckResult> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(
        loggingEnabledE2ECheck,
        zRequestId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any): AxiosPromise<E2ECheckResult> {
      return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   * Method for E2ECheck.
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {string} [zRequestId] Request id header
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any) {
    return E2ECheckApiFp(this.configuration).e2ECheck(
      loggingEnabledE2ECheck,
      zRequestId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OperatorApi - axios parameter creator
 * @export
 */
export const OperatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToOperatorPlace(accountId: number, addOperatorDto: AddOperatorDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling addOperatorToOperatorPlace.'
        );
      }
      // verify required parameter 'addOperatorDto' is not null or undefined
      if (addOperatorDto === null || addOperatorDto === undefined) {
        throw new RequiredError(
          'addOperatorDto',
          'Required parameter addOperatorDto was null or undefined when calling addOperatorToOperatorPlace.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/add-operator`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof addOperatorDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(addOperatorDto !== undefined ? addOperatorDto : {})
        : addOperatorDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperator(accountId: number, operatorId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperator.'
        );
      }
      // verify required parameter 'operatorId' is not null or undefined
      if (operatorId === null || operatorId === undefined) {
        throw new RequiredError(
          'operatorId',
          'Required parameter operatorId was null or undefined when calling getOperator.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorId'}}`, encodeURIComponent(String(operatorId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorByCcUserId(accountId: number, ccUserId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperatorByCcUserId.'
        );
      }
      // verify required parameter 'ccUserId' is not null or undefined
      if (ccUserId === null || ccUserId === undefined) {
        throw new RequiredError(
          'ccUserId',
          'Required parameter ccUserId was null or undefined when calling getOperatorByCcUserId.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/by-cc-user-id`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (ccUserId !== undefined) {
        localVarQueryParameter['ccUserId'] = ccUserId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperators(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperators.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromOperatorPlace(accountId: number, operatorId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling removeOperatorFromOperatorPlace.'
        );
      }
      // verify required parameter 'operatorId' is not null or undefined
      if (operatorId === null || operatorId === undefined) {
        throw new RequiredError(
          'operatorId',
          'Required parameter operatorId was null or undefined when calling removeOperatorFromOperatorPlace.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorId'}}`, encodeURIComponent(String(operatorId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperator(
      accountId: number,
      operatorId: number,
      updateOperatorDto: UpdateOperatorDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperator.'
        );
      }
      // verify required parameter 'operatorId' is not null or undefined
      if (operatorId === null || operatorId === undefined) {
        throw new RequiredError(
          'operatorId',
          'Required parameter operatorId was null or undefined when calling updateOperator.'
        );
      }
      // verify required parameter 'updateOperatorDto' is not null or undefined
      if (updateOperatorDto === null || updateOperatorDto === undefined) {
        throw new RequiredError(
          'updateOperatorDto',
          'Required parameter updateOperatorDto was null or undefined when calling updateOperator.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorId'}}`, encodeURIComponent(String(operatorId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateOperatorDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateOperatorDto !== undefined ? updateOperatorDto : {})
        : updateOperatorDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update operator status
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorStatus(
      accountId: number,
      operatorId: number,
      updateOperatorStatusDto: UpdateOperatorStatusDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperatorStatus.'
        );
      }
      // verify required parameter 'operatorId' is not null or undefined
      if (operatorId === null || operatorId === undefined) {
        throw new RequiredError(
          'operatorId',
          'Required parameter operatorId was null or undefined when calling updateOperatorStatus.'
        );
      }
      // verify required parameter 'updateOperatorStatusDto' is not null or undefined
      if (updateOperatorStatusDto === null || updateOperatorStatusDto === undefined) {
        throw new RequiredError(
          'updateOperatorStatusDto',
          'Required parameter updateOperatorStatusDto was null or undefined when calling updateOperatorStatus.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorId'}}`, encodeURIComponent(String(operatorId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateOperatorStatusDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateOperatorStatusDto !== undefined ? updateOperatorStatusDto : {})
        : updateOperatorStatusDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorApi - functional programming interface
 * @export
 */
export const OperatorApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToOperatorPlace(
      accountId: number,
      addOperatorDto: AddOperatorDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithOperatorDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).addOperatorToOperatorPlace(
        accountId,
        addOperatorDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperator(
      accountId: number,
      operatorId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWithGroupsDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperator(accountId, operatorId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorByCcUserId(
      accountId: number,
      ccUserId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWithGroupsDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorByCcUserId(
        accountId,
        ccUserId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperators(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorsDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperators(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromOperatorPlace(
      accountId: number,
      operatorId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).removeOperatorFromOperatorPlace(
        accountId,
        operatorId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperator(
      accountId: number,
      operatorId: number,
      updateOperatorDto: UpdateOperatorDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWithGroupsDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperator(
        accountId,
        operatorId,
        updateOperatorDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update operator status
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorStatus(
      accountId: number,
      operatorId: number,
      updateOperatorStatusDto: UpdateOperatorStatusDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWithGroupsDto> {
      const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperatorStatus(
        accountId,
        operatorId,
        updateOperatorStatusDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OperatorApi - factory interface
 * @export
 */
export const OperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToOperatorPlace(
      accountId: number,
      addOperatorDto: AddOperatorDto,
      options?: any
    ): AxiosPromise<ResultWithOperatorDto> {
      return OperatorApiFp(configuration).addOperatorToOperatorPlace(
        accountId,
        addOperatorDto,
        options
      )(axios, basePath);
    },
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperator(accountId: number, operatorId: number, options?: any): AxiosPromise<OperatorWithGroupsDto> {
      return OperatorApiFp(configuration).getOperator(accountId, operatorId, options)(axios, basePath);
    },
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorByCcUserId(accountId: number, ccUserId: number, options?: any): AxiosPromise<OperatorWithGroupsDto> {
      return OperatorApiFp(configuration).getOperatorByCcUserId(accountId, ccUserId, options)(axios, basePath);
    },
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperators(accountId: number, options?: any): AxiosPromise<OperatorsDto> {
      return OperatorApiFp(configuration).getOperators(accountId, options)(axios, basePath);
    },
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromOperatorPlace(accountId: number, operatorId: number, options?: any): AxiosPromise<ResultDto> {
      return OperatorApiFp(configuration).removeOperatorFromOperatorPlace(
        accountId,
        operatorId,
        options
      )(axios, basePath);
    },
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperator(
      accountId: number,
      operatorId: number,
      updateOperatorDto: UpdateOperatorDto,
      options?: any
    ): AxiosPromise<OperatorWithGroupsDto> {
      return OperatorApiFp(configuration).updateOperator(
        accountId,
        operatorId,
        updateOperatorDto,
        options
      )(axios, basePath);
    },
    /**
     * Update operator status
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorStatus(
      accountId: number,
      operatorId: number,
      updateOperatorStatusDto: UpdateOperatorStatusDto,
      options?: any
    ): AxiosPromise<OperatorWithGroupsDto> {
      return OperatorApiFp(configuration).updateOperatorStatus(
        accountId,
        operatorId,
        updateOperatorStatusDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * OperatorApi - object-oriented interface
 * @export
 * @class OperatorApi
 * @extends {BaseAPI}
 */
export class OperatorApi extends BaseAPI {
  /**
   * Add operator to operator place
   * @param {number} accountId Account identifier
   * @param {AddOperatorDto} addOperatorDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public addOperatorToOperatorPlace(accountId: number, addOperatorDto: AddOperatorDto, options?: any) {
    return OperatorApiFp(this.configuration).addOperatorToOperatorPlace(
      accountId,
      addOperatorDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get operator
   * @param {number} accountId Account identifier
   * @param {number} operatorId Operator identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public getOperator(accountId: number, operatorId: number, options?: any) {
    return OperatorApiFp(this.configuration).getOperator(accountId, operatorId, options)(this.axios, this.basePath);
  }

  /**
   * Add operator to operator place
   * @param {number} accountId Account identifier
   * @param {number} ccUserId Conversational cloud user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public getOperatorByCcUserId(accountId: number, ccUserId: number, options?: any) {
    return OperatorApiFp(this.configuration).getOperatorByCcUserId(
      accountId,
      ccUserId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get operators
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public getOperators(accountId: number, options?: any) {
    return OperatorApiFp(this.configuration).getOperators(accountId, options)(this.axios, this.basePath);
  }

  /**
   * Remove operator
   * @param {number} accountId Account identifier
   * @param {number} operatorId Operator identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public removeOperatorFromOperatorPlace(accountId: number, operatorId: number, options?: any) {
    return OperatorApiFp(this.configuration).removeOperatorFromOperatorPlace(
      accountId,
      operatorId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Update operator
   * @param {number} accountId Account identifier
   * @param {number} operatorId Operator identifier
   * @param {UpdateOperatorDto} updateOperatorDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public updateOperator(accountId: number, operatorId: number, updateOperatorDto: UpdateOperatorDto, options?: any) {
    return OperatorApiFp(this.configuration).updateOperator(
      accountId,
      operatorId,
      updateOperatorDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Update operator status
   * @param {number} accountId Account identifier
   * @param {number} operatorId Operator identifier
   * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorApi
   */
  public updateOperatorStatus(
    accountId: number,
    operatorId: number,
    updateOperatorStatusDto: UpdateOperatorStatusDto,
    options?: any
  ) {
    return OperatorApiFp(this.configuration).updateOperatorStatus(
      accountId,
      operatorId,
      updateOperatorStatusDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OperatorGroupApi - axios parameter creator
 * @export
 */
export const OperatorGroupApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling addOperatorToGroup.'
        );
      }
      // verify required parameter 'operatorGroupId' is not null or undefined
      if (operatorGroupId === null || operatorGroupId === undefined) {
        throw new RequiredError(
          'operatorGroupId',
          'Required parameter operatorGroupId was null or undefined when calling addOperatorToGroup.'
        );
      }
      // verify required parameter 'operatorIdDto' is not null or undefined
      if (operatorIdDto === null || operatorIdDto === undefined) {
        throw new RequiredError(
          'operatorIdDto',
          'Required parameter operatorIdDto was null or undefined when calling addOperatorToGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/add-operator`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorGroupId'}}`, encodeURIComponent(String(operatorGroupId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof operatorIdDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
        : operatorIdDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorGroup(
      accountId: number,
      createOperatorGroupDto: CreateOperatorGroupDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createOperatorGroup.'
        );
      }
      // verify required parameter 'createOperatorGroupDto' is not null or undefined
      if (createOperatorGroupDto === null || createOperatorGroupDto === undefined) {
        throw new RequiredError(
          'createOperatorGroupDto',
          'Required parameter createOperatorGroupDto was null or undefined when calling createOperatorGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createOperatorGroupDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOperatorGroupDto !== undefined ? createOperatorGroupDto : {})
        : createOperatorGroupDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroup(accountId: number, operatorGroupId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperatorGroup.'
        );
      }
      // verify required parameter 'operatorGroupId' is not null or undefined
      if (operatorGroupId === null || operatorGroupId === undefined) {
        throw new RequiredError(
          'operatorGroupId',
          'Required parameter operatorGroupId was null or undefined when calling getOperatorGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorGroupId'}}`, encodeURIComponent(String(operatorGroupId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroups(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperatorGroups.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroupsByToken(token: string, options: any = {}): RequestArgs {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling getOperatorGroupsByToken.'
        );
      }
      const localVarPath = `/api/aimychat-be/tokens/{token}/operator-groups`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling removeOperatorFromGroup.'
        );
      }
      // verify required parameter 'operatorGroupId' is not null or undefined
      if (operatorGroupId === null || operatorGroupId === undefined) {
        throw new RequiredError(
          'operatorGroupId',
          'Required parameter operatorGroupId was null or undefined when calling removeOperatorFromGroup.'
        );
      }
      // verify required parameter 'operatorIdDto' is not null or undefined
      if (operatorIdDto === null || operatorIdDto === undefined) {
        throw new RequiredError(
          'operatorIdDto',
          'Required parameter operatorIdDto was null or undefined when calling removeOperatorFromGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/remove-operator`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorGroupId'}}`, encodeURIComponent(String(operatorGroupId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof operatorIdDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
        : operatorIdDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorGroup(accountId: number, operatorGroupId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling removeOperatorGroup.'
        );
      }
      // verify required parameter 'operatorGroupId' is not null or undefined
      if (operatorGroupId === null || operatorGroupId === undefined) {
        throw new RequiredError(
          'operatorGroupId',
          'Required parameter operatorGroupId was null or undefined when calling removeOperatorGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorGroupId'}}`, encodeURIComponent(String(operatorGroupId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      updateOperatorGroupDto: UpdateOperatorGroupDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperatorGroup.'
        );
      }
      // verify required parameter 'operatorGroupId' is not null or undefined
      if (operatorGroupId === null || operatorGroupId === undefined) {
        throw new RequiredError(
          'operatorGroupId',
          'Required parameter operatorGroupId was null or undefined when calling updateOperatorGroup.'
        );
      }
      // verify required parameter 'updateOperatorGroupDto' is not null or undefined
      if (updateOperatorGroupDto === null || updateOperatorGroupDto === undefined) {
        throw new RequiredError(
          'updateOperatorGroupDto',
          'Required parameter updateOperatorGroupDto was null or undefined when calling updateOperatorGroup.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorGroupId'}}`, encodeURIComponent(String(operatorGroupId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateOperatorGroupDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateOperatorGroupDto !== undefined ? updateOperatorGroupDto : {})
        : updateOperatorGroupDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorGroupApi - functional programming interface
 * @export
 */
export const OperatorGroupApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupWithOperatorsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).addOperatorToGroup(
        accountId,
        operatorGroupId,
        operatorIdDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorGroup(
      accountId: number,
      createOperatorGroupDto: CreateOperatorGroupDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupWithOperatorsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).createOperatorGroup(
        accountId,
        createOperatorGroupDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupWithOperatorsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroup(
        accountId,
        operatorGroupId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroups(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroups(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroupsByToken(
      token: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupsByToken(
        token,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupWithOperatorsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorFromGroup(
        accountId,
        operatorGroupId,
        operatorIdDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorGroup(
        accountId,
        operatorGroupId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      updateOperatorGroupDto: UpdateOperatorGroupDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorGroupWithOperatorsDto> {
      const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).updateOperatorGroup(
        accountId,
        operatorGroupId,
        updateOperatorGroupDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OperatorGroupApi - factory interface
 * @export
 */
export const OperatorGroupApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorToGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options?: any
    ): AxiosPromise<OperatorGroupWithOperatorsDto> {
      return OperatorGroupApiFp(configuration).addOperatorToGroup(
        accountId,
        operatorGroupId,
        operatorIdDto,
        options
      )(axios, basePath);
    },
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorGroup(
      accountId: number,
      createOperatorGroupDto: CreateOperatorGroupDto,
      options?: any
    ): AxiosPromise<OperatorGroupWithOperatorsDto> {
      return OperatorGroupApiFp(configuration).createOperatorGroup(
        accountId,
        createOperatorGroupDto,
        options
      )(axios, basePath);
    },
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      options?: any
    ): AxiosPromise<OperatorGroupWithOperatorsDto> {
      return OperatorGroupApiFp(configuration).getOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
    },
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroups(accountId: number, options?: any): AxiosPromise<OperatorGroupsDto> {
      return OperatorGroupApiFp(configuration).getOperatorGroups(accountId, options)(axios, basePath);
    },
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorGroupsByToken(token: string, options?: any): AxiosPromise<OperatorGroupsDto> {
      return OperatorGroupApiFp(configuration).getOperatorGroupsByToken(token, options)(axios, basePath);
    },
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorFromGroup(
      accountId: number,
      operatorGroupId: number,
      operatorIdDto: OperatorIdDto,
      options?: any
    ): AxiosPromise<OperatorGroupWithOperatorsDto> {
      return OperatorGroupApiFp(configuration).removeOperatorFromGroup(
        accountId,
        operatorGroupId,
        operatorIdDto,
        options
      )(axios, basePath);
    },
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOperatorGroup(accountId: number, operatorGroupId: number, options?: any): AxiosPromise<void> {
      return OperatorGroupApiFp(configuration).removeOperatorGroup(
        accountId,
        operatorGroupId,
        options
      )(axios, basePath);
    },
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorGroup(
      accountId: number,
      operatorGroupId: number,
      updateOperatorGroupDto: UpdateOperatorGroupDto,
      options?: any
    ): AxiosPromise<OperatorGroupWithOperatorsDto> {
      return OperatorGroupApiFp(configuration).updateOperatorGroup(
        accountId,
        operatorGroupId,
        updateOperatorGroupDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * OperatorGroupApi - object-oriented interface
 * @export
 * @class OperatorGroupApi
 * @extends {BaseAPI}
 */
export class OperatorGroupApi extends BaseAPI {
  /**
   * Add operator to operator group
   * @param {number} accountId Account identifier
   * @param {number} operatorGroupId Operator group identifier
   * @param {OperatorIdDto} operatorIdDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public addOperatorToGroup(accountId: number, operatorGroupId: number, operatorIdDto: OperatorIdDto, options?: any) {
    return OperatorGroupApiFp(this.configuration).addOperatorToGroup(
      accountId,
      operatorGroupId,
      operatorIdDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Create operator group
   * @param {number} accountId Account identifier
   * @param {CreateOperatorGroupDto} createOperatorGroupDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public createOperatorGroup(accountId: number, createOperatorGroupDto: CreateOperatorGroupDto, options?: any) {
    return OperatorGroupApiFp(this.configuration).createOperatorGroup(
      accountId,
      createOperatorGroupDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get operator group
   * @param {number} accountId Account identifier
   * @param {number} operatorGroupId Operator group identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public getOperatorGroup(accountId: number, operatorGroupId: number, options?: any) {
    return OperatorGroupApiFp(this.configuration).getOperatorGroup(
      accountId,
      operatorGroupId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get operator groups
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public getOperatorGroups(accountId: number, options?: any) {
    return OperatorGroupApiFp(this.configuration).getOperatorGroups(accountId, options)(this.axios, this.basePath);
  }

  /**
   * Get operator groups
   * @param {string} token Operator place token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public getOperatorGroupsByToken(token: string, options?: any) {
    return OperatorGroupApiFp(this.configuration).getOperatorGroupsByToken(token, options)(this.axios, this.basePath);
  }

  /**
   * Remove operator from operator group
   * @param {number} accountId Account identifier
   * @param {number} operatorGroupId Operator group identifier
   * @param {OperatorIdDto} operatorIdDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public removeOperatorFromGroup(
    accountId: number,
    operatorGroupId: number,
    operatorIdDto: OperatorIdDto,
    options?: any
  ) {
    return OperatorGroupApiFp(this.configuration).removeOperatorFromGroup(
      accountId,
      operatorGroupId,
      operatorIdDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Remove operator group
   * @param {number} accountId Account identifier
   * @param {number} operatorGroupId Operator group identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public removeOperatorGroup(accountId: number, operatorGroupId: number, options?: any) {
    return OperatorGroupApiFp(this.configuration).removeOperatorGroup(
      accountId,
      operatorGroupId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Update operator group
   * @param {number} accountId Account identifier
   * @param {number} operatorGroupId Operator group identifier
   * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorGroupApi
   */
  public updateOperatorGroup(
    accountId: number,
    operatorGroupId: number,
    updateOperatorGroupDto: UpdateOperatorGroupDto,
    options?: any
  ) {
    return OperatorGroupApiFp(this.configuration).updateOperatorGroup(
      accountId,
      operatorGroupId,
      updateOperatorGroupDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OperatorPlaceApi - axios parameter creator
 * @export
 */
export const OperatorPlaceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorPlace(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperatorPlace.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlace(
      accountId: number,
      updateOperatorPlaceDto: UpdateOperatorPlaceDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperatorPlace.'
        );
      }
      // verify required parameter 'updateOperatorPlaceDto' is not null or undefined
      if (updateOperatorPlaceDto === null || updateOperatorPlaceDto === undefined) {
        throw new RequiredError(
          'updateOperatorPlaceDto',
          'Required parameter updateOperatorPlaceDto was null or undefined when calling updateOperatorPlace.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateOperatorPlaceDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateOperatorPlaceDto !== undefined ? updateOperatorPlaceDto : {})
        : updateOperatorPlaceDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlaceToken(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperatorPlaceToken.'
        );
      }
      const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorPlaceApi - functional programming interface
 * @export
 */
export const OperatorPlaceApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorPlace(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorPlaceDto> {
      const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).getOperatorPlace(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlace(
      accountId: number,
      updateOperatorPlaceDto: UpdateOperatorPlaceDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorPlaceDto> {
      const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlace(
        accountId,
        updateOperatorPlaceDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlaceToken(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorPlaceTokenDto> {
      const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlaceToken(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OperatorPlaceApi - factory interface
 * @export
 */
export const OperatorPlaceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorPlace(accountId: number, options?: any): AxiosPromise<OperatorPlaceDto> {
      return OperatorPlaceApiFp(configuration).getOperatorPlace(accountId, options)(axios, basePath);
    },
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlace(
      accountId: number,
      updateOperatorPlaceDto: UpdateOperatorPlaceDto,
      options?: any
    ): AxiosPromise<OperatorPlaceDto> {
      return OperatorPlaceApiFp(configuration).updateOperatorPlace(
        accountId,
        updateOperatorPlaceDto,
        options
      )(axios, basePath);
    },
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorPlaceToken(accountId: number, options?: any): AxiosPromise<OperatorPlaceTokenDto> {
      return OperatorPlaceApiFp(configuration).updateOperatorPlaceToken(accountId, options)(axios, basePath);
    },
  };
};

/**
 * OperatorPlaceApi - object-oriented interface
 * @export
 * @class OperatorPlaceApi
 * @extends {BaseAPI}
 */
export class OperatorPlaceApi extends BaseAPI {
  /**
   * Get operator place configuration
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorPlaceApi
   */
  public getOperatorPlace(accountId: number, options?: any) {
    return OperatorPlaceApiFp(this.configuration).getOperatorPlace(accountId, options)(this.axios, this.basePath);
  }

  /**
   * Update operator place configuration
   * @param {number} accountId Account identifier
   * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorPlaceApi
   */
  public updateOperatorPlace(accountId: number, updateOperatorPlaceDto: UpdateOperatorPlaceDto, options?: any) {
    return OperatorPlaceApiFp(this.configuration).updateOperatorPlace(
      accountId,
      updateOperatorPlaceDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Update operator place token
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorPlaceApi
   */
  public updateOperatorPlaceToken(accountId: number, options?: any) {
    return OperatorPlaceApiFp(this.configuration).updateOperatorPlaceToken(accountId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options: any = {}): RequestArgs {
      const localVarPath = `/healthCheck`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options: any = {}): RequestArgs {
      const localVarPath = `/version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options?: any): AxiosPromise<object> {
      return SystemApiFp(configuration).version(options)(axios, basePath);
    },
  };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
  /**
   *
   * @summary Service health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public healthCheck(options?: any) {
    return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Service version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public version(options?: any) {
    return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
  }
}
