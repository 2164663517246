import React, { useEffect, useRef, useState } from 'react';
import { RenderingModal } from './index';
import { scan, Subject } from 'rxjs';
import { composeSubComponents } from 'utils/helpFunctions';

class WrapperState {
  cancellable = false;
  title = '';
  cancel?: () => unknown = undefined;
}

const State$ = new Subject<WrapperState>();

type PipeValue = { current: number; total: number; startTime: number };

const Percentage$ = new Subject<{ type: 'start' | 'tick' | 'cleanup'; total?: number; startTime?: number }>();
const PercentagePipe$ = Percentage$.pipe(
  scan(
    (prevValue, currentValue) => {
      switch (currentValue.type) {
        case 'start':
          prevValue.total = currentValue.total || 0;
          prevValue.startTime = currentValue.startTime || 0;
          prevValue.current = 0;
          break;
        case 'tick':
          if (prevValue.total === undefined || prevValue.total < prevValue.current + 1) {
            prevValue.current = 0;
            prevValue.total = 0;
            break;
          }
          prevValue.current += 1;
          break;
        case 'cleanup':
          prevValue.current = 0;
          prevValue.total = 0;
          break;
      }
      if (prevValue.current === prevValue.total) {
        // console.log('Load time', Date.now() - prevValue.startTime);
        prevValue.startTime = 0;
        prevValue.current = 0;
        prevValue.total = 0;
      }
      return prevValue;
    },
    { current: 0, total: 0, startTime: 0 } as PipeValue
  )
);

export const RenderingBlockWrapper = React.memo(() => {
  const [state, setState] = useState<WrapperState>(new WrapperState());
  const [percentage, setPercentage] = useState(0);

  const PercentagePipeRef = useRef<PipeValue>({ current: 0, total: 0, startTime: 0 });

  useEffect(() => {
    const sub = PercentagePipe$.subscribe(pipeValue => {
      PercentagePipeRef.current = pipeValue;
      setPercentage(Math.ceil((pipeValue.current / pipeValue.total) * 100));
    });

    return () => {
      Percentage$.next({ type: 'cleanup' });
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = State$.subscribe(stateValue => setState(stateValue));

    return () => {
      sub.unsubscribe();
    };
  }, []);

  if (PercentagePipeRef.current.current === 0 && PercentagePipeRef.current.total === 0) return null;

  return <RenderingModal {...state} percentage={percentage} />;
});

RenderingBlockWrapper.displayName = 'RenderingBlockWrapper';

export default composeSubComponents(RenderingBlockWrapper, {
  State$,
  Percentage$,
});
