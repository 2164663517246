import React, { FC, useMemo } from 'react';
import { CustomTagData, CustomTagParameterType } from '@just-ai/api/dist/generated/Editorbe';

import { tWithCheck, t } from 'localization';

import { TJBlock } from 'modules/JGraph/utils/types';

import Banner, { BannerType } from 'modules/JGraph/components/Banner';

import { useRightSideMenuContext } from '../../index';

import { ControllerWrapperProps, TControllerInjectedProps } from './Controller';

import { StringFieldViewForCustomTag } from './String.field';
import { StringArray } from './StringArray';
import { NameValueArray } from './NameValueArray';
import { JsonField } from './Json.field';
import { BoolField } from './Bool.field';
import { StateField } from './State.field';
import { HtmlField } from './Html.field';
import { ParametersField } from './Parameters';
import { SelectCustomTagField } from './SelectCustomTagField';
import { CommonFieldProps, CustomTagUiFieldType } from './types';

export type FieldProps = ControllerWrapperProps & Omit<CommonFieldProps, keyof TControllerInjectedProps>;

const fieldMappings: Record<CustomTagParameterType, FC<FieldProps>> = {
  [CustomTagParameterType.Integer]: StringFieldViewForCustomTag,
  [CustomTagParameterType.String]: StringFieldViewForCustomTag,
  [CustomTagParameterType.Intents]: StringArray,
  [CustomTagParameterType.StringArray]: StringArray,
  [CustomTagParameterType.NameValueList]: NameValueArray,
  [CustomTagParameterType.Parameters]: ParametersField,
  [CustomTagParameterType.Json]: JsonField,
  [CustomTagParameterType.Bool]: BoolField,
  [CustomTagParameterType.State]: StateField,
  [CustomTagParameterType.Html]: HtmlField,
};

const uiFieldMappings: Record<CustomTagUiFieldType, FC<FieldProps>> = {
  [CustomTagUiFieldType.Select]: SelectCustomTagField,
};

export const CommonCustomTagSettings: FC<{ descriptor: CustomTagData; block: TJBlock }> = ({ descriptor, block }) => {
  const { editMenuScreen } = useRightSideMenuContext();

  const textDescription = useMemo(() => {
    return tWithCheck(`CustomTag:${block.tagName}:description`, t('Config:Botadmin:HelpUrl'));
  }, [block.tagName]);

  return (
    <div className='JGraph-RightSideMenu-commonContainer' key={editMenuScreen.path + block.tagName}>
      {textDescription && (
        <Banner
          type={BannerType.INFO}
          content={<div dangerouslySetInnerHTML={{ __html: textDescription }} />}
          className='margin-bottom-4x'
        />
      )}
      {descriptor.parameters?.map(tagParam => {
        const label = tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name!}`);
        const description = tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name!}:description`);

        let UIField = fieldMappings[tagParam.type!];
        const uiFieldType = tagParam.userInterfaceField?.type as CustomTagUiFieldType | undefined;
        if (uiFieldType && uiFieldMappings?.[uiFieldType]) {
          UIField = uiFieldMappings[uiFieldType];
        }

        if (!UIField) return null;

        return (
          <UIField
            key={tagParam.name!}
            name={tagParam.name!}
            label={label}
            description={description}
            type={uiFieldType ?? tagParam.type}
            // @ts-ignore
            options={tagParam.userInterfaceField?.options ?? []}
          />
        );
      })}
    </div>
  );
};
