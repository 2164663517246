import {
  DATE_CHANGED,
  DROP_FILTER,
  FILTER_SETTINGS_FULFILLED,
  FILTER_SETTINGS_PENDING,
  INIT_FULFILLED,
  INIT_PENDING,
  INIT_REJECTED,
  LIVECHAT_CHANGE,
  PAGINATE_FULFILLED,
  PAGINATE_PENDING,
  SELECT_CHANNEL,
  SELECT_LABELS,
  SELECT_SESSION_LABELS,
  SELECT_TAGS,
  UPDATE_FILTER_PARAMS,
} from '../../constants/dialogs.actions';

import { CHANGE_CURRENT_PROJECT } from '../../constants/projects.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import forEach from 'lodash/forEach';
import { getIntFromPageNumber } from '../../pipes/functions';

// todo !!!!  remove all filter properties after dialogs page removing !!!!
// todo !!!!  new filter use analytics filter reducer !!!!

const InitialState = {
  totalPages: 0,
  totalElements: 0,
  page: 0,
  size: 20,
  dialogs: [],

  labels: [],
  selectedLabels: [],
  sessionLabels: [],
  selectedSessionLabels: [],

  // todo remove after removing dialogs page
  tags: [],
  selectedTags: [],
  sessionLabelsIds: [],

  channels: [],
  selectedChannel: [],
  answerTypes: [],
  selectedAnswerType: [],
  dateFrom: null,
  dateTo: null,
  filterActive: false,

  filter: null,

  fetching: false,
  filterFetching: false,
  filterParams: null,
  livechat: null,

  moreRowsExist: false,
  dialogsInitError: false,
};

export default function DialogsReducer(state = InitialState, action) {
  switch (action.type) {
    case INIT_PENDING: {
      return {
        ...state,
        dialogsInitError: false,
        fetching: true,
      };
    }
    case PAGINATE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case INIT_FULFILLED:
    case PAGINATE_FULFILLED: {
      const payload = action.payload;

      let totalPages = payload.data?.totalPages;
      if (!Number.isInteger(totalPages)) {
        const paging = payload.paging || payload.data;
        totalPages = Math.ceil(paging.totalCount / paging.pageSize);
      }
      let { size, totalCount } = payload.data || {};

      if (payload.data?.paging) {
        const { paging } = payload.data;
        size = paging.pageSize;
        totalPages = paging.totalCount / paging.pageSize;
        totalCount = paging.totalCount;
      }
      return {
        ...state,
        dialogs: payload.data?.content || payload.sessions || payload.data.sessions,
        totalPages,
        page: getIntFromPageNumber(payload.data?.number, payload.paging?.pageNum),
        size: size || payload.paging.pageSize,
        fetching: false,
        filterActive: payload.config?.filter || null,
        totalElements: getIntFromPageNumber(
          action.payload.data?.totalElements,
          payload.paging?.totalCount || totalCount
        ),
        filter: payload.config?.filter ? payload.config.searchObj : null,
        filterParams: payload.config?.filter ? payload.config.searchObj : null,
        moreRowsExist: payload.data?.moreRowsExist || false,
        dialogsInitError: false,
      };
    }
    case LIVECHAT_CHANGE: {
      return {
        ...state,
        livechat: action.value,
      };
    }

    case DROP_FILTER: {
      return {
        ...state,
        selectedLabels: InitialState.selectedLabels,
        selectedSessionLabels: InitialState.selectedSessionLabels,
        selectedAnswerType: InitialState.selectedAnswerType,
        selectedChannel: InitialState.selectedChannel,
        dateFrom: InitialState.dateFrom,
        dateTo: InitialState.dateTo,
        filterParams: InitialState.filterParams,
        filter: InitialState.filterParams,

        // todo remove after dialogs page removing
        selectedTags: [],
      };
    }

    case UPDATE_FILTER_PARAMS: {
      return {
        ...state,
        filterParams: action.params,
      };
    }

    case FILTER_SETTINGS_PENDING: {
      return {
        ...state,
        filterFetching: true,
      };
    }
    case FILTER_SETTINGS_FULFILLED: {
      let sessionLabelsIds = [];
      forEach(action.payload.data.sessionLabels, (curVal, i) => {
        sessionLabelsIds.push(curVal.id);
      });
      let allLabels = action.payload.data.logGroups.slice();
      allLabels.splice(0, 0, {
        id: 'session',
        priority: 0,
        name: '',
        active: true,
        labels: action.payload.data.sessionLabels,
      });
      return {
        ...state,
        filterFetching: false,
        channels: action.payload.data.channels,
        labels: action.payload.data.logGroups,
        sessionLabels: action.payload.data.sessionLabels,

        // todo remove after dialogs page removing
        tags: allLabels,
        sessionLabelsIds: sessionLabelsIds,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED:
    case CHANGE_CURRENT_PROJECT: {
      return {
        ...state,
        selectedAnswerType: [],
        selectedChannel: [],
        dateFrom: null,
        dateTo: null,
        dialogs: InitialState.dialogs,
        totalPages: InitialState.totalPages,
        page: InitialState.page,
        size: InitialState.size,
        fetching: false,
        filterActive: InitialState.filterActive,
        totalElements: InitialState.totalElements,
        filter: InitialState.filter,
        channels: InitialState.channels,
        sessionLabels: InitialState.sessionLabels,
        labels: InitialState.labels,
        selectedLabels: InitialState.selectedLabels,
        selectedSessionLabels: InitialState.selectedSessionLabels,

        // todo remove after dialogs page removing
        tags: InitialState.tags,
        sessionLabelsIds: InitialState.sessionLabelsIds,
        selectedTags: [],
      };
    }

    case DATE_CHANGED: {
      return {
        ...state,
        [action.dateType]: action.value,
      };
    }

    case SELECT_LABELS: {
      return {
        ...state,
        selectedLabels: action.selectedLabels,
      };
    }

    case SELECT_SESSION_LABELS: {
      return {
        ...state,
        selectedSessionLabels: action.selectedSessionLabels,
      };
    }

    // todo remove after removing dialogs page
    case SELECT_TAGS: {
      return {
        ...state,
        selectedTags: action.value,
      };
    }
    case SELECT_CHANNEL: {
      return {
        ...state,
        selectedChannel: action.value,
      };
    }
    case INIT_REJECTED: {
      return {
        ...state,
        dialogsInitError: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
