export const jgloaderrorLocalization = {
  'LoadJGraphErrorModal:title': {
    eng: 'Failed to load J-Graph',
    ru: 'Ошибка загрузки J-Graph',
  },
  'LoadJGraphErrorModal:Return to code': {
    eng: 'Go to code editor',
    ru: 'Перейти в редактор кода',
  },
  'LoadJGraphErrorModal:empty_state_name': {
    eng: 'The script contains states without names. It may cause J-Graph instability.',
    ru: 'В сценарии есть стейты без названий. Это может привести к нестабильной работе J-Graph.',
  },
  'LoadJGraphErrorModal:empty_state_name:hint': {
    eng: 'Go to the code editor and specify the names.',
    ru: 'Перейдите в редактор кода и укажите названия.',
  },
  'LoadJGraphErrorModal:Duplicates:Title': {
    eng: 'J-Graph error: duplicates found',
    ru: 'Ошибка J-Graph: обнаружены дубликаты',
  },
  'LoadJGraphErrorModal:StateDuplicate': {
    eng: 'Multiple states have the same path:\n{state}',
    ru: 'Несколько стейтов имеют одинаковый путь:\n{state}',
  },
  'LoadJGraphErrorModal:StateDuplicate:hint': {
    eng: 'Specify unique paths in the code editor',
    ru: 'Укажите уникальные пути в редакторе кода',
  },
  'LoadJGraphErrorModal:ThemesDuplicate': {
    eng: 'Multiple themes have the same name:\n{name}',
    ru: 'Несколько тем имеют одинаковое название:\n{name}',
  },
  'LoadJGraphErrorModal:ThemesDuplicate:hint': {
    eng: 'Specify unique names in the code editor',
    ru: 'Укажите уникальные названия в редакторе кода',
  },
  'LoadJGraphErrorModal:StateAndThemeDuplicate': {
    eng: 'The state path is the same as the theme name:\n{name}',
    ru: 'Путь стейта совпадает с названием темы:\n{name}',
  },
  'LoadJGraphErrorModal:StateAndThemeDuplicate:hint': {
    eng: 'Specify unique path or unique name in the code editor',
    ru: 'Укажите уникальный путь или уникальное название в редакторе кода',
  },
  'LoadJGraphErrorModal:EntrypointFileNotExists:Title': {
    eng: 'J-Graph error: main file not found',
    ru: 'Ошибка J-Graph: не найден главный файл',
  },
  'LoadJGraphErrorModal:EntrypointFileNotExists': {
    eng: 'The main project file was not found: {name}. Create this file in the code editor',
    ru: 'Не найден главный файл проекта: {name}. Создайте этот файл в редакторе кода',
  },
};
