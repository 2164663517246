import {
  DATE_CHANGED,
  DROP_FILTER,
  LIVECHAT_CHANGE,
  SELECT_CHANNEL,
  SELECT_LABELS,
  SELECT_SESSION_LABELS,
  SET_FILTER_NLP_SELECT,
  SET_FILTER_IS_INCOMING,
  SET_STR_SEARCH_IN_STORE,
  UPDATE_FILTER_PARAMS,
  SELECT_MAILING_RESULTS,
  FILTER_SETTINGS_FAST_FULFILLED,
  FILTER_SETTINGS_SLOW_FULFILLED,
  FILTER_SETTINGS_FAST_PENDING,
  FILTER_SETTINGS_SLOW_PENDING,
  SET_STR_CLIENTS_SEARCH_IN_STORE,
} from '../../constants/analyticsFilter.actions';

import { CHANGE_CURRENT_PROJECT } from '../../constants/projects.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import {
  TOGGLE_COMMON_CHART_LABELS,
  TOGGLE_COMMON_CHART_LABELS_PHRASE,
} from '../../constants/diagramAnalytics.actions';

import { getCurrentDate } from '../../pipes/functions';

import queryString from 'query-string';
import { forEach, pick } from 'lodash';

const InitialState = {
  labels: [],
  selectedLabels: [],
  sessionLabels: [],
  selectedSessionLabels: [],
  mailingResultsOptions: [],
  selectedMailingResults: [],
  channels: [],
  selectedChannel: null,
  answerTypes: [],
  selectedAnswerType: [],
  nlpClass: [],
  nlpState: [],
  labelsColors: [],
  labelsPhraseColors: [],
  isIncoming: null,
  nlpClassLike: true,
  stateLike: true,
  selectedNlpClass: null,
  selectedNlpState: null,
  dateFrom: getCurrentDate()[0],
  dateTo: null,
  livechat: null,
  livechatOptions: [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ],
  fetching: false,
  strDialogsSearch: '',
};

export const colorsForLabels = [
  '#f78c40',
  '#3b5998',
  '#ff0084',
  '#400191',
  '#e34f26',
  '#1f96d4',

  '#026466',

  '#ea4c89',

  '#cb2027',
  '#00bf8f',
  '#59267c',

  '#7DBEA5',
  '#F1E0B1',
  '#EE9D31',
  '#F26C1A',
  '#5A392B',
  '#A7C7C5',
  '#09504F',
  '#172A40',
  '#FFF7DC',
  '#D9383A',
  '#DA2536',
  '#EF525B',
  '#EAE7D6',
  '#24C2CB',
  '#082E41',
  '#BC1654',
  '#86415E',
  '#42A48D',
  '#F09648',
  '#F53435',
  '#5C4B51',
  '#8CBEB2',
  '#F2EBBF',
  '#F3B562',
  '#F06060',
  '#B8A48C',
  '#6D7D6B',
  '#4B5957',
  '#3B3833',
  '#2E2724',
  '#0C383B',
  '#789F8A',
  '#E2D8A3',
  '#E1B753',
  '#E1694E',
  '#2E112D',
  '#540032',
  '#820333',
  '#C9283E',
  '#F0433A',
  '#FCFFF5',
  '#CBDBD7',
  '#91AAB4',
  '#3E606F',
  '#193441',
  '#FCE1C5',
  '#E3C2B1',
  '#FAD9CF',
  '#E3B4B1',
  '#FCC5D5',
  '#012C40',
  '#00708C',
  '#DAEBF2',
  '#FF404C',
  '#1CA5B8',
  '#004358',
  '#1F8A70',
  '#BEDB39',
  '#FFE11A',
  '#FD7400',
  '#56B9D0',
  '#FEFEFE',
  '#FBBA42',
  '#F24C27',
  '#3B3F42',
  '#A44365',
  '#EDAC52',
  '#315054',
  '#D74C41',
  '#302E49',
  '#2C3E50',
  '#FC4349',
  '#D7DADB',
  '#6DBCDB',
  '#FFFFFF',
  '#A8BAA9',
  '#FFF5CF',
  '#DBCDAD',
  '#B39C7D',
  '#806854',
  '#F2637E',
  '#D94E8F',
  '#732F6E',
  '#241C59',
  '#151440',
  '#2D3C41',
  '#537173',
  '#A49A81',
  '#A68774',
  '#A6706C',
  '#192B38',
  '#22424B',
  '#2E5458',
  '#4E7367',
  '#7FA68C',
  '#192640',
  '#6B7363',
  '#D9BD8B',
  '#BF8A6B',
  '#8C2F0D',
  '#FF8E00',
  '#FF7F00',
  '#FF6702',
  '#FF551B',
  '#E54818',
  '#7B2A3B',
  '#E57661',
  '#F8C58C',
  '#F8E7A2',
  '#86DDB2',
  '#F34A53',
  '#FAE3B4',
  '#AAC789',
  '#437356',
  '#1E4147',
  '#FFB29E',
  '#F79B89',
  '#DE8467',
  '#A34F2E',
  '#843925',
  '#074358',
  '#458985',
  '#D7D6A5',
  '#DBA67B',
  '#A55C55',
  '#A6CCF5',
  '#C4DFF8',
  '#E0EEFE',
  '#8CB0EC',
  '#BACDEE',
  '#020E17',
  '#0E5159',
  '#09736A',
  '#15AB89',
  '#76D9B9',
  '#050C42',
  '#0284A8',
  '#02BEC4',
  '#A9E8DC',
  '#E1F7E7',
  '#EF1B24',
  '#F8F9FB',
  '#062E4C',
  '#D1EDF3',
  '#58C3C8',
  '#BF352F',
  '#FFD99B',
  '#88A34F',
  '#224732',
  '#132326',
  '#071930',
  '#023852',
  '#03A694',
  '#F24738',
  '#851934',
  '#64A0A8',
  '#1C2E34',
  '#DBC759',
  '#E0D9CC',
  '#D86840',
  '#2C3E50',
  '#FC4349',
  '#D7DADB',
  '#6DBCDB',
  '#FFFFFF',
  'var(--gray-950)',
  '#2E332F',
  '#FECB00',
  '#FFD535',
  '#FFFFFF',
  '#ACD711',
  '#92A847',
  '#ADC02F',
  '#A49441',
  '#756331',
  '#232529',
  '#571A1E',
  '#F34B56',
  '#F3F2EF',
  '#414174',
  '#1B4869',
  '#1593A2',
  '#ACF0F1',
  '#FF8B00',
  '#F2FFE3',
  '#2E2B24',
  '#A19680',
  '#FFFEFA',
  '#7B7B6F',
  '#6F1514',
  '#573544',
  '#8CBEB2',
  '#F2EBBF',
  '#449991',
  '#E8476D',
  '#2039CC',
  '#192DA1',
  '#111E6C',
  '#0C154A',
  '#080E33',
  '#C2E0F2',
  '#F2F2F2',
  '#F2D6B3',
  '#F2C894',
  '#BF826B',
  '#C7C2B9',
  '#EAE6E3',
  '#D6D6CC',
  '#D4C7C0',
  '#C6BCB4',
  '#D0EAFF',
  '#6194BC',
  '#A5D1F3',
  '#ECECEC',
  '#606060',
  '#EFFC21',
  '#FF715B',
  '#F835A4',
  '#E58EF4',
  '#1AC7C5',
  '#BF9693',
  '#7F6462',
  '#FFC8C4',
  '#403231',
  '#E5B4B0',
  '#F79E6B',
  '#F7CD82',
  '#5B584F',
  '#92A78C',
  '#E0D5AD',
  '#B53D3B',
  '#375569',
  '#B5C154',
  '#FBCA41',
  '#F5EDC2',
  '#FFFFFB',
  '#A60303',
  '#FFE11A',
  '#2734F1',
  'var(--gray-950)',
  '#0039FF',
  '#0C74E8',
  '#00B8FF',
  '#0CE1E8',
  '#0DFFC6',
  'var(--gray-950)',
  '#202020',
  '#404040',
  '#606060',
  '#808080',
  '#43312E',
  '#6D402C',
  '#D88848',
  '#EFBD97',
  '#F4B37F',
  '#56B9D0',
  '#FEFEFE',
  '#FBBA42',
  '#F24C27',
  '#3B3F42',
  '#040D40',
  '#03318C',
  '#0476D9',
  '#F2AB27',
  '#F23E16',
  '#F18381',
  '#FEDF91',
  '#CFD5E9',
  '#7477A0',
  '#222329',
  '#FF6138',
  '#FFFF9D',
  '#BEEB9F',
  '#79BD8F',
  '#00A388',
  '#001426',
  '#5E81A5',
  '#9CB9D9',
  '#F3DCA2',
  '#D78C8A',
  '#0A1827',
  '#5B9496',
  '#9AC0BA',
  '#CCD9CE',
  '#F6EDD3',
  '#092140',
  '#024959',
  '#F2C777',
  '#F24738',
  '#BF2A2A',
  '#064273',
  '#0D85E7',
  '#08599B',
  '#063B66',
  '#074A81',
  '#04A47A',
  '#E0F30E',
  '#DA9700',
  '#BD0048',
  '#070541',
  '#FF81AB',
  '#A07BE8',
  '#66CBFF',
  '#5CE886',
  '#F6FF71',
  '#FF7D0D',
  '#E8510C',
  '#FF2B00',
  '#E8110C',
  '#FF0D74',
  '#FFB88C',
  '#993333',
  '#FF8481',
  '#E8A94B',
  '#191E36',
  '#222F40',
  '#1C588C',
  '#F2F2F2',
  '#F2B035',
  '#BFBCBA',
  '#404040',
  '#5C5C5C',
  '#E91E63',
  '#00BCD4',
  '#F8BBD0',
  '#FDF9EE',
  '#9CEFE9',
  '#90D7D4',
  '#EF9940',
  '#EE7229',
  '#214154',
  '#265961',
  '#227066',
  '#76A665',
  '#FFDD5C',
  '#FBFFD4',
  '#E8D9C1',
  '#FFE2E1',
  '#D5C3E8',
  '#D4EEFF',
  '#043A43',
  '#028A8E',
  '#015859',
  '#E9F2F2',
  '#6FA856',
  '#5A5559',
  '#D8C8AE',
  '#892627',
  '#74191E',
  '#211924',
  '#FFBC67',
  '#DA727E',
  '#AC6C82',
  '#685C79',
  '#455C7B',
  '#022601',
  '#F27405',
  '#D95204',
  '#730202',
  '#400101',
];

export default function AnalyticsFilterReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_FILTER_NLP_SELECT: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }

    case SET_FILTER_IS_INCOMING: {
      return {
        ...state,
        isIncoming: action.value,
      };
    }

    case LIVECHAT_CHANGE: {
      return {
        ...state,
        livechat: action.value,
      };
    }

    case DROP_FILTER: {
      return {
        ...state,
        selectedLabels: InitialState.selectedLabels,
        selectedSessionLabels: InitialState.selectedSessionLabels,
        selectedAnswerType: InitialState.selectedAnswerType,
        selectedChannel: InitialState.selectedChannel,
        selectedNlpClass: InitialState.selectedNlpClass,
        selectedNlpState: InitialState.selectedNlpState,
        isIncoming: InitialState.isIncoming,
        selectedMailingResults: InitialState.selectedMailingResults,
        dateFrom: InitialState.dateFrom,
        dateTo: InitialState.dateTo,
        livechat: InitialState.livechat,
      };
    }

    case UPDATE_FILTER_PARAMS: {
      let channel = null;
      let selectedNlpClass = null;
      let selectedNlpState = null;
      let isIncoming = null;

      if (!!action.params['nlpClass.name']) {
        selectedNlpClass = state.nlpClass.find(item => {
          return item.fullName === action.params['nlpClass.name'];
        });
        if (!!selectedNlpClass) {
          selectedNlpClass = {
            label: selectedNlpClass.fullName,
            value: selectedNlpClass.fullName,
          };
        } else {
          selectedNlpClass = null;
        }
      }

      if (!!action.params['state.name']) {
        selectedNlpState = state.nlpState.find(item => {
          return item.fullName === action.params['state.name'];
        });
        if (!!selectedNlpState) {
          selectedNlpState = {
            label: selectedNlpState.fullName,
            value: selectedNlpState.fullName,
          };
        } else {
          selectedNlpState = null;
        }
      }

      if (action.params.isIncoming === true || action.params.isIncoming === 'true') {
        isIncoming = true;
      }
      if (action.params.isIncoming === false || action.params.isIncoming === 'false') {
        isIncoming = false;
      }

      if (!!action.params.channel) {
        channel = state.channels.find(item => item.id === parseInt(action.params.channel, 10));

        if (!!channel) {
          channel = {
            label: channel.botName,
            value: channel.id,
          };
        } else {
          channel = state.selectedChannel;
        }
      }

      const livechat = action.params.livechat || state.livechat;
      const livechatOption = livechat ? state.livechatOptions.find(option => livechat === option.value) : null;

      return {
        ...state,
        selectedLabels: !!action.params.logLabels ? action.params.logLabels.split(',') : state.selectedLabels,
        selectedSessionLabels: !!action.params.sessionLabels
          ? action.params.sessionLabels.split(',')
          : state.selectedSessionLabels,
        selectedMailingResults: !!action.params.mailingResults ? action.params.mailingResults.split(',') : [],
        selectedAnswerType: action.params.selectedAnswerType || state.selectedAnswerType,
        selectedChannel: channel,
        dateFrom: action.params.dateFrom || state.dateFrom,
        dateTo: action.params.dateTo || state.dateTo,
        livechat: livechatOption,
        isIncoming: isIncoming,

        selectedNlpState: selectedNlpState,
        selectedNlpClass: selectedNlpClass,
      };
    }

    case FILTER_SETTINGS_FAST_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FILTER_SETTINGS_SLOW_PENDING: {
      const projectShortName = window.location.pathname.split('/')[1];
      const existingFilterDataSlow = JSON.parse(localStorage.getItem(`FILTER_SETTINGS_${projectShortName}`));
      return {
        ...state,
        mailingResultsOptions: existingFilterDataSlow?.mailingResults || InitialState.mailingResultsOptions,
        nlpClass: existingFilterDataSlow?.nlpClasses || InitialState.nlpClass,
        nlpState: existingFilterDataSlow?.nlpStates || InitialState.nlpState,
      };
    }

    case FILTER_SETTINGS_FAST_FULFILLED: {
      let labelsColors = [];
      forEach(action.payload.data.sessionLabels, (sessionLabel, key) => {
        labelsColors.push({
          name: sessionLabel.name,
          color: colorsForLabels[key],
          active: true,
        });
      });
      let labelsPhraseColors = [];
      forEach(action.payload.data.logGroups, (logGroup, k1) => {
        let tmpArr = logGroup.labels.map((label, k2) => {
          return {
            name: label.name,
            color: colorsForLabels[labelsColors.length + k1 + k2],
            active: true,
          };
        });
        labelsPhraseColors = labelsPhraseColors.concat(tmpArr);
      });

      let selectedChannel = null;

      const channelInSearchString =
        state.strDialogsSearch && pick(queryString.parse(state.strDialogsSearch), ['channel']);
      if (channelInSearchString) {
        const foundChannel = action.payload.data.channels.find(
          channel => channel.botId === channelInSearchString.channel
        );
        if (foundChannel) {
          selectedChannel = {
            label: foundChannel.botName,
            value: foundChannel.id,
          };
        }
      }

      return {
        ...state,
        fetching: false,
        channels: action.payload.data.channels,
        labels: action.payload.data.logGroups,
        sessionLabels: action.payload.data.sessionLabels,
        labelsColors: labelsColors,
        labelsPhraseColors: labelsPhraseColors,

        selectedChannel,
      };
    }

    case FILTER_SETTINGS_SLOW_FULFILLED: {
      const data = action.payload.data || action.payload;

      localStorage.setItem(
        `FILTER_SETTINGS_${
          action.payload.config?.projectShortName || localStorage.getItem('CURRENT_PROJECT_SHORT_NAME')
        }`,
        JSON.stringify(data)
      );
      return {
        ...state,
        mailingResultsOptions: data.mailingResults,
        nlpClass: data.nlpClasses,
        nlpState: data.nlpStates,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED:
    case CHANGE_CURRENT_PROJECT: {
      return {
        ...state,
        strDialogsSearch: '',
        strClientsSearch: '',
        selectedAnswerType: [],
        selectedChannel: [],
        dateFrom: InitialState.dateFrom,
        dateTo: InitialState.dateTo,
        fetching: false,
        channels: InitialState.channels,
        sessionLabels: InitialState.sessionLabels,
        labels: InitialState.labels,
        selectedLabels: InitialState.selectedLabels,
        selectedMailingResults: InitialState.selectedMailingResults,
        callTaskId: InitialState.callTaskId,
        isIncoming: InitialState.isIncoming,
        selectedSessionLabels: InitialState.selectedSessionLabels,
        livechat: InitialState.livechat,
        livechatOptions: InitialState.livechatOptions,
        answerTypes: InitialState.answerTypes,
      };
    }

    case DATE_CHANGED: {
      return {
        ...state,
        [action.dateType]: action.value,
        dateFromTimeLimit: action.dateFromTimeLimit,
      };
    }

    case SELECT_LABELS: {
      const selectedLabels = action.selectedLabels
        ? Array.isArray(action.selectedLabels)
          ? action.selectedLabels?.map(label => label?.value?.toString())
          : [action.selectedLabels?.value?.toString()]
        : null;
      return {
        ...state,
        selectedLabels: selectedLabels,
      };
    }

    case SELECT_SESSION_LABELS: {
      const selectedSessionLabels = action.selectedSessionLabels
        ? Array.isArray(action.selectedSessionLabels)
          ? action.selectedSessionLabels?.map(label => label?.value?.toString())
          : [action.selectedSessionLabels?.value?.toString()]
        : null;
      return {
        ...state,
        selectedSessionLabels: selectedSessionLabels,
      };
    }

    case SELECT_MAILING_RESULTS: {
      const selectedMailingResults = action.selectedMailingResults
        ? Array.isArray(action.selectedMailingResults)
          ? action.selectedMailingResults?.map(option => option?.value?.toString())
          : [action.selectedMailingResults?.value?.toString()]
        : null;
      return {
        ...state,
        selectedMailingResults: selectedMailingResults,
      };
    }

    case SELECT_CHANNEL: {
      return {
        ...state,
        selectedChannel: action.value,
      };
    }

    case TOGGLE_COMMON_CHART_LABELS: {
      let newLabelsColors = [...state.labelsColors];
      const index = newLabelsColors.findIndex(item => {
        return item.name === action.name;
      });
      if (index > -1) {
        newLabelsColors[index].active = action.flag;
      }

      return {
        ...state,
        labelsColors: newLabelsColors,
      };
    }

    case TOGGLE_COMMON_CHART_LABELS_PHRASE: {
      let newLabelsPhraseColors = [...state.labelsPhraseColors];
      const indexPhrase = newLabelsPhraseColors.findIndex(item => {
        return item.name === action.name;
      });
      if (indexPhrase > -1) {
        newLabelsPhraseColors[indexPhrase].active = action.flag;
      }

      return {
        ...state,
        labelsPhraseColors: newLabelsPhraseColors,
      };
    }

    case SET_STR_SEARCH_IN_STORE: {
      localStorage.setItem(`STR_DIALOGS_SEARCH${action.projectShortName}`, action.value);
      return {
        ...state,
        strDialogsSearch: action.value,
      };
    }

    case SET_STR_CLIENTS_SEARCH_IN_STORE: {
      localStorage.setItem(`STR_CLIENTS_SEARCH${action.projectShortName}`, action.value);
      return {
        ...state,
        strClientsSearch: action.value,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
