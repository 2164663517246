import React, { useState, FC, useCallback } from 'react';
import { IconButton } from '@just-ai/just-ui';

import { useAppSelector } from 'storeHooks';

import { intSerializer, useLocalStorageState } from 'utils/hooks/useLocalStorageState';
import HorizontalResizeNode from 'components/HorizontalResizeNode';

import HierarchyView from './detailViews/HierarchyView';

import styles from './styles.module.scss';

enum DetailViewKey {
  Hierarchy = 'hierarchy',
}
export type DetailViewContentProps = {
  closeAll: () => void;
};
const detailViewMap: Record<DetailViewKey, FC<DetailViewContentProps>> = {
  [DetailViewKey.Hierarchy]: HierarchyView,
};

type DetailViewProps = { view: DetailViewKey; closeAll: () => void };
function DetailView(props: DetailViewProps) {
  const { projectShortName, accountId } = useAppSelector(store => ({
    projectShortName: store.CurrentProjectsReducer.currentProject,
    accountId: store.CurrentAccountReducer.account?.id,
  }));
  const [detailWidth, setDetailWidth] = useLocalStorageState<number>(
    200,
    `Hierarchy.DetailWidth.${props.view}.${projectShortName}.${accountId}`,
    true,
    intSerializer
  );

  const Cmp = detailViewMap[props.view] ?? (() => null);
  return (
    <HorizontalResizeNode
      maxWidth={500}
      minWidth={200}
      width={detailWidth || 200}
      onWidthChange={setDetailWidth}
      className={styles.detailPanel}
    >
      <Cmp closeAll={props.closeAll} />
    </HorizontalResizeNode>
  );
}

const LeftSideBar = () => {
  const [detailViewKey, setDetailViewKey] = useState<DetailViewKey | null>(null);

  const toggleView = useCallback((view: DetailViewKey) => {
    setDetailViewKey(prev => (prev === view ? null : view));
  }, []);

  const closeAll = useCallback(() => setDetailViewKey(null), []);

  return (
    <div className={styles.LeftSideBar}>
      <div className={styles.menuPanel}>
        <IconButton
          name='farFolderTree'
          flat
          color='primary'
          active={detailViewKey === DetailViewKey.Hierarchy}
          onClick={() => toggleView(DetailViewKey.Hierarchy)}
        />
      </div>
      {detailViewKey && <DetailView view={detailViewKey} closeAll={closeAll} />}
    </div>
  );
};

LeftSideBar.displayName = 'LeftSideBar';

export default React.memo(LeftSideBar);
