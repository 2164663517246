import React from 'react';
import { Amplitude } from 'react-amplitude-hooks';

export interface AmplitudeHOCProps {
  logEvent: (eventType: string, eventPropertiesIn?: object, callback?: any) => void;
  instrument: <InstrumentType extends Function>(eventType: string, func: InstrumentType) => InstrumentType;
}

const withAmplitude = (eventProps: object = {}) => (Component: any) => (props: any) => {
  return (
    <Amplitude eventProperties={eventProps}>
      {({ ...amplitudeProps }) => <Component {...props} {...(amplitudeProps as AmplitudeHOCProps)} />}
    </Amplitude>
  );
};

export default withAmplitude;
