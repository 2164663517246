import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { ConnectorProps } from './index';
import Konva from 'konva';

import { getStageContextFromRef } from '../../utils/stageUtils';
import { allBlocksSetted, applyIslandConnectorOffsets } from '../../utils/connectionLayerUtils';

import { rafAppScheduler } from '../../../../utils/sheduler/buildScheduler';
import { getIsScreenFromRef } from '../../utils/blockLayerUtils';
import { IslandConnector } from './IslandConnector';

export const IslandConnectors: FC<ConnectorProps> = memo(({ connector }) => {
  const { from, to, deferred, fromNode, fromNodeOriginalPath, toNodeOriginalPath } = connector;
  const LabelFromRef = useRef<Konva.Label | null>(null);
  const groupFromRef = useRef<Konva.Group | null>(null);
  const groupToRef = useRef<Konva.Group | null>(null);
  const LabelToRef = useRef<Konva.Label | null>(null);
  const fromNodeRef = useRef<Konva.Node | null | undefined>(null);
  const toNodeRef = useRef<Konva.Node | null | undefined>(null);
  const isToNodeScreen = useRef(true);
  const isFromScreen = useRef(true);

  const setPositionsHandler = useCallback(() => {
    const { GStage } = getStageContextFromRef(LabelFromRef);
    if (!GStage) return;

    isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
    isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);

    let fromPosition = fromNodeRef.current?.getAbsolutePosition(GStage);
    let toPosition = toNodeRef.current?.getAbsolutePosition(GStage);
    if (!fromPosition || !toPosition) return;
    const { fromPosition: newFromPos, toPosition: newToPos } = applyIslandConnectorOffsets(fromPosition, toPosition, {
      isToNodeScreen: isToNodeScreen.current,
    });
    groupFromRef?.current?.attrs.updatePosition(newFromPos);
    groupToRef?.current?.attrs.updatePosition(newToPos);
  }, []);

  useEffect(() => {
    if (!LabelFromRef.current) return;
    const { connectorsFromStore$, GStage } = getStageContextFromRef(LabelFromRef);

    const sub = connectorsFromStore$.subscribe(store => {
      if (allBlocksSetted(store, from, to, fromNode) && GStage) {
        fromNodeRef.current = store[from].fromRef || store[fromNode].fromRefFallBack;
        toNodeRef.current = store[to!].toRef;
        isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
        isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);
        //should calculate after stage redraw
        setTimeout(() => {
          rafAppScheduler(() => {
            setPositionsHandler();
          });
        }, 0);
      }
    });
    return () => sub.unsubscribe();
  }, [deferred, from, fromNode, setPositionsHandler, to]);

  if (connector.to === '') return null;

  return (
    <>
      <IslandConnector
        groupRef={groupFromRef}
        labelRef={LabelFromRef}
        isOutgoing={true}
        fromCircleNode={fromNodeRef.current}
        isFromScreen={isFromScreen.current}
        isToNodeScreen={false}
        text={toNodeOriginalPath}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
      />
      <IslandConnector
        groupRef={groupToRef}
        labelRef={LabelToRef}
        isOutgoing={false}
        toScreenNode={toNodeRef.current}
        isFromScreen={false}
        isToNodeScreen={isToNodeScreen.current}
        text={fromNodeOriginalPath}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
      />
    </>
  );
});

IslandConnectors.displayName = 'IslandConnectors';
