import React, { RefObject, useEffect, useMemo } from 'react';
import { Spinner, useBehaviorSubject } from '@just-ai/just-ui';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import { AutoLayoutProgressSubject$ } from '../hooks/useAutoplacement';
import RenderingBlockWrapper from './RenderingModal/RenderingBlockWrapper';
import StageObservablesProvider from '../contexts/StageObservablesProvider';

import { StageInGroupView } from './StageInGroupView';
import { CurrentGroupPath } from './CurrentGroupPath';
import { StageView } from './StageView';
import ContextMenu from './ContextMenu';
import { findStartStateInStates } from 'reducers/JGraph.reducer/Graph';

interface MainStageViewProps {
  containerRef: RefObject<HTMLDivElement>;
}
const MainStageView = ({ containerRef }: MainStageViewProps) => {
  const { loadingGraph, loadingCustomTags, selectedTheme, themes, screens, allScreens, stickers, error, reverting } =
    useAppSelector(state => ({
      error: state.JGraphReducer.error,
      screens:
        state.JGraphReducer.graph.blocks.filter(
          screen => state.JGraphReducer.selectedTheme && screen.theme === state.JGraphReducer.selectedTheme.value
        ) || [],
      allScreens: state.JGraphReducer.graph.blocks,
      stickers: state.JGraphReducer.stickers,
      reverting: state.JGraphReducer.graph.reverting,
      loadingGraph: state.JGraphReducer.loadingGraph,
      loadingCustomTags: state.JGraphReducer.loadingCustomTags,
      selectedTheme: state.JGraphReducer.selectedTheme,
      themes: state.JGraphReducer.graph.themes,
    }));

  const autoLayoutProgress = useBehaviorSubject(AutoLayoutProgressSubject$);
  const loaderShowed = React.useRef<boolean>(false);

  useEffect(() => {
    if (!loaderShowed.current && !(loadingGraph || loadingCustomTags) && !error) {
      RenderingBlockWrapper.State$.next({ cancellable: false, title: t('RenderingModal:BuildingScenarioTitle') });

      RenderingBlockWrapper.Percentage$.next({
        type: 'start',
        total: screens.filter(screen => screen.hasOwnProperty('canRender')).length,
        startTime: Date.now(),
      });
      loaderShowed.current = true;
    }
  }, [error, loadingCustomTags, loadingGraph, screens, selectedTheme]);

  useEffect(
    () => () => {
      loaderShowed.current = false;
    },
    [selectedTheme]
  );

  const startState = useMemo(() => findStartStateInStates(screens), [screens]);

  return (
    <StageObservablesProvider
      themes={themes}
      startState={startState}
      screens={screens}
      allScreens={allScreens}
      stickers={stickers}
      key={selectedTheme?.value}
    >
      {(reverting || autoLayoutProgress.status === 'pending') && <Spinner />}
      <CurrentGroupPath />
      <ContextMenu />
      <StageView containerRef={containerRef} screens={screens} showIncomingOutgoingConnections={screens.length > 0} />
      <StageInGroupView />
    </StageObservablesProvider>
  );
};

MainStageView.displayName = 'MainStageView';

export default React.memo(MainStageView);
