const prefix = '@phrasesList/';

export const FETCH = prefix + 'FETCH';
export const FETCH_FULFILLED = prefix + 'FETCH_FULFILLED';
export const FETCH_PENDING = prefix + 'FETCH_PENDING';
export const FETCH_REJECTED = prefix + 'FETCH_REJECTED';

export const PAGINATE_FULFILLED = prefix + 'PAGINATE_FULFILLED';
export const PAGINATE_PENDING = prefix + 'PAGINATE_PENDING';
export const PAGINATE_REJECTED = prefix + 'PAGINATE_REJECTED';

export const ADD_LABELS = prefix + 'ADD_LABELS';
export const ADD_LABELS_PENDING = prefix + 'ADD_LABELS_PENDING';
export const ADD_LABELS_FULFILLED = prefix + 'ADD_LABELS_FULFILLED';
export const ADD_LABELS_REJECTED = prefix + 'ADD_LABELS_REJECTED';

export const ADD_TEXTAREA = prefix + 'ADD_TEXTAREA';
export const ADD_TEXTAREA_PENDING = prefix + 'ADD_TEXTAREA_PENDING';
export const ADD_TEXTAREA_FULFILLED = prefix + 'ADD_TEXTAREA_FULFILLED';
export const ADD_TEXTAREA_REJECTED = prefix + 'ADD_TEXTAREA_REJECTED';

export const ADD_LOG_TASK = prefix + 'ADD_LOG_TASK';
export const ADD_LOG_TASK_PENDING = prefix + 'ADD_LOG_TASK_PENDING';
export const ADD_LOG_TASK_FULFILLED = prefix + 'ADD_LOG_TASK_FULFILLED';
export const ADD_LOG_TASK_REJECTED = prefix + 'ADD_LOG_TASK_REJECTED';

export const UPDATE_LOG_TASK = prefix + 'UPDATE_LOG_TASK';
export const UPDATE_LOG_TASK_PENDING = prefix + 'UPDATE_LOG_TASK_PENDING';
export const UPDATE_LOG_TASK_FULFILLED = prefix + 'UPDATE_LOG_TASK_FULFILLED';
export const UPDATE_LOG_TASK_REJECTED = prefix + 'UPDATE_LOG_TASK_REJECTED';

export const CHANGE_MARK_FIELD = prefix + 'CHANGE_MARK_FIELD';
export const SET_SELECTED_LOG_MARKS = prefix + 'SET_SELECTED_LOG_MARKS';
