import React from 'react';
import { Banner } from '@just-ai/just-ui';

import { t } from 'localization';

interface CustomTagWarningBlockProps {
  isValidFormat: boolean;
  isMaybeVariable: boolean;
  type?: string;
}
const CustomTagWarningBlock = ({ isValidFormat, isMaybeVariable, type }: CustomTagWarningBlockProps) => {
  const getBannerText = () => {
    if (isValidFormat) {
      if (isMaybeVariable) return t('CustomTag:TypeMismatch:MaybeVariable');
      return '';
    }
    if (isMaybeVariable) return t('CustomTag:TypeMismatch:InvalidFormatCaseByVariable');
    return t('CustomTag:TypeMismatch', { type });
  };

  const bannerText = getBannerText();

  return (
    <div className='margin-top-2x'>
      {bannerText && (
        <Banner withIcon BannerText={() => <span dangerouslySetInnerHTML={{ __html: bannerText }} />} type='warning' />
      )}
    </div>
  );
};

CustomTagWarningBlock.displayName = 'CustomTagWarningBlock';

export default React.memo(CustomTagWarningBlock);
