import React, { FunctionComponent } from 'react';
import { useEditorContext } from 'modules/Editor/context/EditorContext';
import { ParseErrorsItem } from './ParseErrorsItem';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';
import { ScrollArea } from '@just-ai/just-ui';

const { t } = i18nTranslation('Editor');

export const ParseErrorsArea: FunctionComponent = () => {
  const { parseErrors } = useEditorContext();

  return (
    <ScrollArea vertical className='errors-area-wrapper'>
      {parseErrors.length !== 0 ? (
        <div style={{ height: '100%' }} data-test-id='LogPanel.errors.content'>
          {parseErrors.map(parseError => (
            <ParseErrorsItem item={parseError} key={`errors-item_${JSON.stringify(parseError)}`} />
          ))}
        </div>
      ) : (
        <div className='errors-area-empty-caption' data-test-id='LogPanel.errors.empty'>
          {t('errorsAreaEmpty')}
        </div>
      )}
    </ScrollArea>
  );
};
