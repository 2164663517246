import { PhraseStatusData, PhraseIndexWithConfidence, IntentData } from '../api/client';
import { ListDataset } from '@just-ai/just-ui';
import { GroupsDataset } from './groups';
import { ConfidenceThreshold } from '../pages/LogLabelingPage/reducers';

export type TopN = {
  groupId: string;
  confidence: number;
}[];
export interface PhraseItem {
  id: string;
  text: string;
  status: PhraseStatusData;
  confidence?: number;
  topN?: TopN;
  sessionId?: string;
}

export function isDefined<Type>(arg: Type | undefined): arg is Type {
  return arg !== undefined;
}

export type PhrasesDataset = ListDataset<PhraseItem>;

export const toPhraseItems = (allPhrases: string[], allStatuses: PhraseStatusData[]): PhraseItem[] =>
  allPhrases.map((text, index) => ({ id: prefixed(index), text, status: allStatuses[index] }));

export const toSortedDataset = (phrases: PhraseIndexWithConfidence[], allPhrases: PhraseItem[]): PhrasesDataset =>
  phrases
    .map(phrase =>
      toPhrase(allPhrases[Number(phrase.phraseIdx)], phrase.phraseIdx, phrase.confidence, phrase.sessionId)
    )
    .sort(byStatusAndConfidence)
    .reduce(addNode, {});

export const sortDataset = (phrases: PhrasesDataset) =>
  Object.values(phrases).sort(byStatusAndConfidence).reduce(addNode, {});

export const toSortedDatasetWithTopN = (
  phrases: PhraseIndexWithConfidence[],
  allPhrases: PhraseItem[],
  groups: GroupsDataset
): PhrasesDataset =>
  phrases
    .map(phrase => toPhraseWithTopN(phrase, allPhrases, groups))
    .sort(byStatusAndConfidence)
    .reduce(addNode, {});

const toPhraseWithTopN = (
  phrase: PhraseIndexWithConfidence,
  allPhrases: PhraseItem[],
  groups: GroupsDataset
): PhraseItem => ({
  ...allPhrases[Number(phrase.phraseIdx)],
  confidence: phrase.confidence,
  topN: getTopN(phrase.phraseIdx, groups),
  sessionId: phrase.sessionId,
});
const getTopN = (idx: number, groups: GroupsDataset): TopN =>
  Object.values(groups)
    .map(group => ({ groupId: group.nodeId, phrase: group.phrases.find(({ phraseIdx }) => phraseIdx === idx) }))
    .filter(group => group.phrase !== undefined)
    .map(group => ({ groupId: group.groupId, confidence: Number(group.phrase!.confidence) }))
    .sort(byConfidence);

const toPhrase = (phrase: PhraseItem, index: number, confidence?: number, originId?: string): PhraseItem => ({
  id: String(index),
  text: phrase.text,
  status: phrase.status ? phrase.status : PhraseStatusData.P,
  confidence: confidence ? confidence : 0,
  sessionId: originId,
});

const updateStatus = (phrases: PhraseItem[], id: string, newStatus: PhraseStatusData) => {
  if (phrases[clearPrefix(id)]) {
    phrases[clearPrefix(id)].status = newStatus;
  }
  return phrases;
};

export const switchStatuses = (
  allPhrases: PhraseItem[],
  indexes: string[],
  newStatus: PhraseStatusData
): PhraseItem[] => indexes.reduce((phrases, index) => updateStatus(phrases, index, newStatus), [...allPhrases]);

const applyIfStaged = (phrase: PhraseItem): PhraseItem =>
  phrase.status === PhraseStatusData.S ? { ...phrase, status: PhraseStatusData.A } : { ...phrase };
export const applyStaged = (allPhrases: PhraseItem[]): PhraseItem[] => allPhrases.map(applyIfStaged);

type HasStatus = { status: PhraseStatusData };
const statusPriority = {
  [PhraseStatusData.S]: 0,
  [PhraseStatusData.P]: 1,
  [PhraseStatusData.D]: 2,
  [PhraseStatusData.A]: 3,
};
const byStatus = (a: HasStatus, b: HasStatus) => statusPriority[a.status] - statusPriority[b.status];

type HasConfidence = { confidence?: number };
const byConfidence = (a: HasConfidence, b: HasConfidence) =>
  typeof a.confidence === 'number' && typeof b.confidence === 'number' ? b.confidence - a.confidence : 0;

type HasStatusAndConfidence = HasStatus & HasConfidence;
const byStatusAndConfidence = (a: HasStatusAndConfidence, b: HasStatusAndConfidence) => {
  const statusDiff = byStatus(a, b);
  return statusDiff === 0 ? byConfidence(a, b) : statusDiff;
};

const toPhraseWithTopNFrom = (groups: GroupsDataset) => (phrase: PhraseItem) => ({
  ...phrase,
  topN: getTopN(clearPrefix(phrase.id), groups),
});

export const isNotLabeled = (phrase: PhraseItem) => !phrase.status || phrase.status === PhraseStatusData.P;
export const matchesFilter = (phrase: PhraseItem, filter: string) =>
  phrase.text.toLowerCase().includes(filter.toLowerCase());
export const filterAndOptionallyAddTopN = (
  phrases: PhraseItem[],
  filter: string,
  showLabeled: boolean,
  withTopN: boolean,
  groups?: GroupsDataset
): PhrasesDataset => {
  let resultPhrases = phrases;
  if (!showLabeled) {
    resultPhrases = resultPhrases.filter(isNotLabeled);
  }
  if (filter !== '') {
    resultPhrases = resultPhrases.filter(phrase => matchesFilter(phrase, filter));
  }
  if (withTopN && groups) {
    resultPhrases = resultPhrases.map(toPhraseWithTopNFrom(groups));
  }
  return resultPhrases.reduce(addNode, {});
};

const isItemOf = (phrases: PhrasesDataset) =>
  Object.values(phrases).length > 0
    ? (groupPhrase: PhraseIndexWithConfidence) => phrases[prefixed(groupPhrase.phraseIdx)] !== undefined
    : () => false;

const hasConfidenceAboveOrEqualTo = (confidenceThreshold: ConfidenceThreshold) =>
  confidenceThreshold?.min > 0 || confidenceThreshold.max < 1
    ? (phrase: HasConfidence) =>
        phrase.confidence &&
        phrase.confidence >= confidenceThreshold.min &&
        phrase.confidence <= confidenceThreshold.max
    : () => true;

export const filterGroupPhrases = (
  groupPhrases: PhraseIndexWithConfidence[],
  phrases: PhrasesDataset,
  confidenceThreshold: ConfidenceThreshold
) => groupPhrases.filter(isItemOf(phrases)).filter(hasConfidenceAboveOrEqualTo(confidenceThreshold));

export const filterGroupPhrasesAndHideConflicts = (
  groupPhrases: PhraseIndexWithConfidence[],
  phrases: PhrasesDataset,
  confidenceThreshold: ConfidenceThreshold,
  groupId: string
) => filterGroupPhrases(groupPhrases, phrases, confidenceThreshold).filter(hasTopConfidenceWith(groupId, phrases));

export const hasTopConfidenceOrWithoutTopN = (phrase: PhraseItem, groupId: string) =>
  !phrase.topN || (phrase.topN[0] && phrase.topN[0].groupId === groupId);

const hasTopConfidenceWith = (groupId: string, phrases: PhrasesDataset) => (groupPhrase: PhraseIndexWithConfidence) =>
  hasTopConfidenceOrWithoutTopN(phrases[prefixed(groupPhrase.phraseIdx)], groupId);

export const filterPhrases = (
  phrases: PhrasesDataset,
  showLabeled: boolean,
  hideConflicts: boolean,
  filter: string,
  confidenceThreshold: ConfidenceThreshold,
  currentGroupId: string
): PhrasesDataset => {
  let phrasesList = Object.values(phrases);
  if (!showLabeled) {
    phrasesList = phrasesList.filter(isNotLabeled);
  }
  if (hideConflicts) {
    phrasesList = phrasesList.filter(phrase => !hasTopConfidenceOrWithoutTopN(phrase, currentGroupId));
  }
  if (filter !== '') {
    phrasesList = phrasesList.filter(phrase => matchesFilter(phrase, filter));
  }
  if (confidenceThreshold) {
    phrasesList = phrasesList.filter(hasConfidenceAboveOrEqualTo(confidenceThreshold));
  }
  return phrasesList.reduce(addNode, {});
};

export const hasNotLabeled = (dataset: PhrasesDataset) => Object.values(dataset).some(isNotLabeled);

export const intentPhrasesToPhrasesDataset = (intent: IntentData, allPhrases: PhraseItem[]): PhrasesDataset => {
  if (!intent.phrases) return {};
  return intent.phrases
    .map(({ stagedPhraseIdx }) => stagedPhraseIdx)
    .filter(idx => typeof idx === 'number')
    .map(id => ({ ...allPhrases[Number(id)] }))
    .reduce(addNode, {});
};

const toPhraseItemFrom =
  (allPhrases: PhrasesDataset) =>
  (groupPhrase: PhraseIndexWithConfidence): PhraseItem => ({
    ...allPhrases[prefixed(groupPhrase.phraseIdx)],
    confidence: groupPhrase.confidence,
    sessionId: groupPhrase.sessionId,
  });

const isInDataset =
  (dataset: PhrasesDataset) =>
  (groupPhrase: PhraseIndexWithConfidence): boolean =>
    Boolean(dataset[prefixed(groupPhrase.phraseIdx)]);

export const selectGroupPhrases = (
  groupPhrases: PhraseIndexWithConfidence[],
  allPhrases: PhrasesDataset
): PhrasesDataset => groupPhrases.filter(isInDataset(allPhrases)).map(toPhraseItemFrom(allPhrases)).reduce(addNode, {});

export const selectStagingGroupPhrases = (groupPhrases: string[], allStagedPhrases: PhraseItem[]): PhrasesDataset => {
  return groupPhrases
    .map(phrase => allStagedPhrases.find(stagedPhrase => stagedPhrase.text === phrase))
    .filter(isDefined)
    .reduce(addNode, {});
};

export const addNode = (dataset: PhrasesDataset, phrase: PhraseItem) =>
  Object.assign(dataset, { [prefixed(phrase.id)]: phrase });

const ID_PREFIX = 'phrase_';
const isPrefixed = (id: string) => id.startsWith(ID_PREFIX);
export const prefixed = (id: string | number) =>
  typeof id === 'number' || !isPrefixed(id) ? `${ID_PREFIX}${id}` : `${id}`;
export const clearPrefix = (id: string) => (isPrefixed(id) ? Number(id.slice(ID_PREFIX.length)) : Number(id));

export const getPhrasesCount = (phrases: PhrasesDataset) =>
  Object.values(phrases).filter(phrase => phrase.status !== PhraseStatusData.A).length;

export const formatConfidence = (confidence?: number) => (confidence ? confidence.toFixed(2) : '0.00');
