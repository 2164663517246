import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import classNames from 'classnames';
import './Inputs.scss';
import { InputDateAndTime } from '../InputDateAndTime';
import { Icon } from '../Icon';
export class Inputs extends Component {
    constructor() {
        super(...arguments);
        this.state = { timeOptions: [] };
    }
    render() {
        const { columns = 1, range, setTime, onReset, locale, placeholderEnd, placeholderStart, type, minDate, maxDate, dark, rootClassName, onEscape, disabled, } = this.props;
        return (_jsxs("div", { className: classNames('rdrInputDateMainContainer', 'rdrInputDateMainContainerWithTime', rootClassName, {
                disabled: disabled,
            }), children: [_jsxs("div", { style: { position: 'relative' }, children: [_jsx(InputDateAndTime, { value: range === null || range === void 0 ? void 0 : range.startDate, onChange: (value, date) => setTime('startDate', date), onEscape: onEscape, type: type, min: minDate, max: maxDate, placeholder: placeholderStart, dark: dark, locale: locale, disabled: disabled }), _jsx(Icon, { className: 'rdrInputCalendarIcon', name: 'farCalendar', color: 'secondary' }), (range === null || range === void 0 ? void 0 : range.startDate) && !disabled && (_jsx("button", { tabIndex: -1, className: 'rdrInputDateIconContainer', "data-test-id": 'JustUI.DatePicker.StartClearInputButton', onClick: onReset, children: _jsx(Icon, { name: 'faTimesCircle', size: 'md', color: 'secondary', onClick: onReset }) }))] }), columns === 2 && (_jsxs(_Fragment, { children: [_jsx("div", { children: "\u00A0\u2013\u00A0" }), _jsxs("div", { style: { position: 'relative' }, children: [_jsx(InputDateAndTime, { value: range === null || range === void 0 ? void 0 : range.endDate, type: type, min: minDate, max: maxDate, placeholder: placeholderEnd, dark: dark, onChange: (value, date) => setTime('endDate', date), onEscape: onEscape, locale: locale, disabled: disabled }), _jsx(Icon, { className: 'rdrInputCalendarIcon', name: 'farCalendar', size: 'sm', color: 'secondary' }), (range === null || range === void 0 ? void 0 : range.endDate) && !disabled && (_jsx("button", { className: 'rdrInputDateIconContainer', tabIndex: -1, "data-test-id": 'JustUI.DatePicker.EndClearInputButton', onClick: onReset, children: _jsx(Icon, { name: 'faTimesCircle', size: 'md', color: 'secondary' }) }))] })] }))] }));
    }
}
