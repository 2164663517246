import { IntentData } from '../../../../../../Caila/api/client';
import { CustomIntentItem } from '../../../../../../Caila/pages/IntentsPage/IntentItemsEdit';

export type IntentWithItems = {
  intent: IntentData;
  items: CustomIntentItem[];
};

export class ComplexErrorsType {
  isPathInvalid: boolean = false;
  duplicateSlotNames: string[] = [];
  emptySlotNames: string[] = [];
  isIntentValid: boolean = true;
}
