import React, { FC, useCallback } from 'react';
import { Icon } from '@just-ai/just-ui';

import localize, { t } from 'localization';

import { ActivationsMenuType } from '../../hooks/EventsHook';
import { useRightSideMenuContext } from '../../index';
import { EventActivation } from './EventActivation';
import { PatternsActivation } from './PatternsActivation';
import { eventactivationLocalization } from './localization/eventactivation.loc';
import classes from './Activations.module.scss';
import { JGraphCailaIntents } from './JGraphCailaIntents';
import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';
import { IntentsActivation } from './IntentsActivation';

localize.addTranslations(eventactivationLocalization);

const useAddingTypes = (): ActivationsMenuType[] => {
  const { isJGraphLite } = useJGraphContext();
  const initialActivationsTypes: ActivationsMenuType[] = ['intent', 'event'];
  if (!isJGraphLite) {
    initialActivationsTypes.push('pattern');
  }
  return initialActivationsTypes;
};

export const ActivationsAddingMenu: FC = () => {
  const { eventsState, setActivationsMenuType } = useRightSideMenuContext();
  const addingTypes = useAddingTypes();
  const { activationsMenuType, addingMenuType } = eventsState;
  if (!activationsMenuType) {
    return (
      <div className={classes.ActivationsAddingMenu}>
        <div className={classes.title}>
          <p>{t('ActivationsAddingMenu:title')}</p>
        </div>
        <div className={classes.addingTypesContainer}>
          {addingTypes.map(type => (
            <AddingType
              key={type}
              testId={t(`ActivationsMenuType:title_${type}`)}
              title={t(`ActivationsMenuType:title_${type}`)}
              description={t(`ActivationsMenuType:description_${type}`)}
              onClick={() => setActivationsMenuType(type)}
            />
          ))}
        </div>
      </div>
    );
  }
  switch (activationsMenuType) {
    case 'event':
      return <EventActivation />;
    case 'pattern':
      return <PatternsActivation />;
    case 'intent':
      if (addingMenuType === 'toStateEvents') {
        return <JGraphCailaIntents />;
      }
      return <IntentsActivation />;
  }
  return <div>{activationsMenuType}</div>;
};

export const AddingType: FC<{
  title: string | JSX.Element;
  testId?: string;
  onClick?: () => unknown;
  description?: string | JSX.Element;
  link?: string;
}> = ({ onClick, title, description, link, testId }) => {
  const openLink = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  return (
    <div className={classes.addingType} onClick={onClick} data-test-id={`AddingType:${testId || ''}`}>
      <div className={classes.addingTypeText}>
        <div className={classes.addingTypeTitle}>{title}</div>
        {description && <div className={classes.addingTypeDescription}>{description}</div>}
      </div>
      {link && (
        <div className={classes.addingTypeInfo} onClick={openLink}>
          <Icon name='farQuestionCircle' color='secondary' />
        </div>
      )}
    </div>
  );
};
