import { useEffect, useState } from 'react';
export function usePipeValue(rxjsPipe) {
    const [subjectValue, setSubjectValue] = useState();
    useEffect(() => {
        const unsub = rxjsPipe.subscribe(data => {
            setSubjectValue(data);
        });
        return () => {
            unsub.unsubscribe();
        };
    }, [rxjsPipe]);
    return subjectValue;
}
