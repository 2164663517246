import { createAsyncThunk } from '@reduxjs/toolkit';

import { TagNames } from 'modules/JGraph/utils/types';

import { addNewStateWithSave } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { ProjectData } from 'modules/Caila/components/AppContext';

function getNoMatchStateText(nluLang: string): string {
  switch (nluLang) {
    case 'ru':
      return 'Извините, я не понял.'; // checkLocalizationScript:disabled
    case 'en':
      return 'Sorry, I don’t understand.';
    default:
      return 'Sorry, I don’t understand.';
  }
}

const addNewNoMatchState = createAsyncThunk(
  'JGraph/addNewNoMatchState',
  async (
    data: {
      screenPath: string;
      currentProject?: ProjectData;
    },
    thunkAPI
  ) => {
    if (!data.screenPath.startsWith('/')) {
      data.screenPath = '/' + data.screenPath;
    }
    const nluLang = data.currentProject?.language || '';
    const localState = {
      screenPath: data.screenPath,
      theme: '/',
      blocks: [
        {
          tagName: TagNames.event_,
          tagValue: 'noMatch',
          tagParameters: [],
          comment: [],
          jblocks: [],
        },
        {
          tagName: TagNames.a,
          tagValue: getNoMatchStateText(nluLang),
          tagParameters: [],
          comment: [],
          jblocks: [],
        },
      ],
    };
    thunkAPI.dispatch(addNewStateWithSave({ ...localState, setEdit: true }));
  }
);

export default addNewNoMatchState;
