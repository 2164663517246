import * as DeployInfoConsts from '../../constants/deployInfo.actions';

const InitialState = {
  data: {},
  error: false,
  fetching: false,
};
export default function DeployInfoReducer(state = InitialState, action) {
  switch (action.type) {
    case DeployInfoConsts.INIT_PENDING: {
      return {
        ...state,
        error: false,
        fetching: true,
      };
    }
    case DeployInfoConsts.INIT_REJECTED: {
      return {
        ...state,
        error: true,
        fetching: false,
      };
    }
    case DeployInfoConsts.INIT_FULFILLED: {
      if (typeof action.payload.data === 'string') {
        return {
          ...DeployInfoReducer(state, {
            type: DeployInfoConsts.INIT_REJECTED,
          }),
        };
      }

      return {
        ...state,
        error: false,
        data:
          process.env.NODE_ENV === 'production'
            ? action.payload.data
            : {
                'http://localhost:9010': [
                  {
                    scenarioId: 'default-40-alisa-193-1',
                    accountId: 'default',
                    bot: '40-alisa-193',
                    scenarioName: '40-alisa-193',
                    revision: 1,
                    revisionHash: 'ba41ee25bd6797b58585970adac7bb81f091e784',
                    revisionComment:
                      'Merged in YA-160-fix-fortune (pull request #133)\n\nYA-160 fix fortune\n\nApproved-by: Ivan Golubev <www.golubev@gmail.com>\nApproved-by: Dasha Serdyuk <d.serdyuk@just-ai.com>\n',
                    preloadOnStart: true,
                    keepInMemory: true,
                    showException: false,
                  },
                ],
                'http://localhost:9009': [
                  {
                    scenarioId: 'default-39-alisa-196-1',
                    accountId: 'default',
                    bot: '39-alisa-196',
                    scenarioName: '39-alisa-196',
                    revision: 1,
                    revisionHash: null,
                    revisionComment: null,
                    preloadOnStart: true,
                    keepInMemory: true,
                    showException: false,
                  },
                  {
                    scenarioId: 'default-40-alisa-193-1',
                    accountId: 'default',
                    bot: '40-alisa-193',
                    scenarioName: '40-alisa-193',
                    revision: 1,
                    revisionHash: 'ba41ee25bd6797b58585970adac7bb81f091e784',
                    revisionComment:
                      'Merged in YA-160-fix-fortune (pull request #133)\n\nYA-160 fix fortune\n\nApproved-by: Ivan Golubev <www.golubev@gmail.com>\nApproved-by: Dasha Serdyuk <d.serdyuk@just-ai.com>\n',
                    preloadOnStart: true,
                    keepInMemory: true,
                    showException: false,
                  },
                ],
              },
        fetching: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
