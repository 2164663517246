import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import { ProviderObserverScrollModel } from './model';
const observerScrollModels = {};
export var IntersectionObserverRootType;
(function (IntersectionObserverRootType) {
    IntersectionObserverRootType["WINDOW"] = "window";
    IntersectionObserverRootType["CHILDREN"] = "children";
})(IntersectionObserverRootType || (IntersectionObserverRootType = {}));
function getRootByType(type, child) {
    switch (type) {
        case IntersectionObserverRootType.WINDOW:
            return null;
        case IntersectionObserverRootType.CHILDREN:
            return child;
        default:
            return null;
    }
}
export const ProviderObserverScroll = React.forwardRef(({ children, onChange, contextKey, threshold = 1.0, debounceTime = 0, intersectionObserverRootType = IntersectionObserverRootType.CHILDREN, childrenRefPropName = 'ref', }, ref) => {
    if (!observerScrollModels[contextKey]) {
        observerScrollModels[contextKey] = new ProviderObserverScrollModel();
    }
    const child = useRef();
    const callbackDebounced = useRef();
    useEffect(() => {
        callbackDebounced.current = debounce((data) => {
            onChange && onChange(data);
        }, debounceTime);
    }, [onChange, debounceTime]);
    const connect = useCallback(() => {
        const root = getRootByType(intersectionObserverRootType, child.current);
        if (root === undefined)
            return;
        const model = observerScrollModels[contextKey];
        model.setCallback(callbackDebounced.current ? callbackDebounced.current : onChange);
        model.connect(root, threshold);
        return () => model.disconnect();
    }, [intersectionObserverRootType, contextKey, onChange, threshold]);
    useEffect(connect, [threshold, onChange]);
    useEffect(() => () => {
        observerScrollModels[contextKey] = null;
    }, []);
    if (intersectionObserverRootType === IntersectionObserverRootType.CHILDREN) {
        // Клонируем children чтоб ему можно было подставить наш ref
        children = React.cloneElement(children, {
            [childrenRefPropName]: (innerRef) => {
                ref && ref(innerRef);
                if (innerRef) {
                    child.current = innerRef;
                    connect();
                }
                // @ts-ignore
                children.ref && children.ref(innerRef);
            },
        });
    }
    return children;
});
export const ObservedScrollElement = React.forwardRef(({ children, contextKey, innerValue }, ref) => {
    const childRef = useRef();
    const model = observerScrollModels[contextKey];
    const connect = useCallback((currentNode) => {
        model.registerElement(currentNode, innerValue);
        childRef.current = currentNode;
    }, []);
    useEffect(() => {
        return () => {
            childRef.current && model.unregisterElement(childRef.current);
        };
    }, [model]);
    if (!model)
        return null;
    return React.cloneElement(children, {
        ref: (innerRef) => {
            // @ts-ignore
            ref && ref(innerRef);
            innerRef && !childRef.current && connect(innerRef);
            // @ts-ignore
            children.ref && children.ref(innerRef);
        },
    });
});
