import React, { Component } from 'react';

class Robot503 extends Component {
  render() {
    let icon = (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 278 220'>
        <defs>
          <path
            d='M0.706,109.552 C0.706,169.599 49.384,218.278 109.432,218.278 C169.48,218.278 218.158,169.599 218.158,109.552 C218.158,49.504 169.48,0.824 109.432,0.824 C49.384,0.824 0.706,49.504 0.706,109.552 Z'
            id='path-1'
          />
          <polygon id='path-3' points='0 226 290.498 226 290.498 0.825 0 0.825' />
          <path
            d='M0.904,16.923 C0.882,25.988 8.331,33.472 17.396,33.493 L23.795,33.509 L23.875,0.445 L17.475,0.429 C17.461,0.429 17.449,0.429 17.435,0.429 C8.387,0.43 0.925,7.87 0.904,16.923 Z'
            id='path-5'
          />
          <path
            d='M0.904,16.923 C0.882,25.988 8.331,33.472 17.396,33.493 L23.795,33.509 L23.875,0.445 L17.475,0.429 C17.461,0.429 17.449,0.429 17.435,0.429 C8.387,0.43 0.925,7.87 0.904,16.923 Z'
            id='path-7'
          />
          <path
            d='M0.707,9.839 C0.693,14.846 4.743,18.915 9.75,18.926 C14.757,18.938 18.826,14.889 18.838,9.882 C18.85,4.876 14.8,0.806 9.793,0.794 C9.786,0.794 9.779,0.794 9.771,0.794 C4.775,0.794 0.718,4.84 0.707,9.839 Z'
            id='path-9'
          />
          <path
            d='M0.707,9.839 C0.693,14.846 4.743,18.915 9.75,18.926 C14.757,18.938 18.826,14.889 18.838,9.882 C18.85,4.876 14.8,0.806 9.793,0.794 C9.786,0.794 9.779,0.794 9.771,0.794 C4.775,0.794 0.718,4.84 0.707,9.839 Z'
            id='path-11'
          />
          <polygon id='path-13' points='0 226 290.498 226 290.498 0.825 0 0.825' />
          <path
            d='M7.024,0.429 L0.624,0.445 L0.702,33.509 L7.102,33.493 C16.169,33.472 23.616,25.988 23.594,16.923 C23.574,7.87 16.112,0.43 7.063,0.429 C7.05,0.429 7.037,0.429 7.024,0.429 Z'
            id='path-15'
          />
          <path
            d='M7.024,0.429 L0.624,0.445 L0.702,33.509 L7.102,33.493 C16.169,33.472 23.616,25.988 23.594,16.923 C23.574,7.87 16.112,0.43 7.063,0.429 C7.05,0.429 7.037,0.429 7.024,0.429 Z'
            id='path-17'
          />
          <path
            d='M9.704,0.794 C4.698,0.806 0.649,4.876 0.66,9.882 C0.672,14.889 4.74,18.938 9.749,18.926 C14.755,18.915 18.804,14.846 18.793,9.839 C18.781,4.84 14.724,0.794 9.726,0.794 C9.719,0.794 9.712,0.794 9.704,0.794 Z'
            id='path-19'
          />
          <path
            d='M9.704,0.794 C4.698,0.806 0.649,4.876 0.66,9.882 C0.672,14.889 4.74,18.938 9.749,18.926 C14.755,18.915 18.804,14.846 18.793,9.839 C18.781,4.84 14.724,0.794 9.726,0.794 C9.719,0.794 9.712,0.794 9.704,0.794 Z'
            id='path-21'
          />
          <polygon id='path-23' points='0 226 290.498 226 290.498 0.825 0 0.825' />
          <polygon id='path-25' points='0.516 0.15 7.981 0.15 7.981 38.547 0.516 38.547' />
          <path
            d='M0.717,16.814 L0.717,23.213 L33.781,23.213 L33.781,16.814 C33.781,7.748 26.315,0.282 17.249,0.282 C8.183,0.282 0.717,7.748 0.717,16.814 Z'
            id='path-27'
          />
          <path
            d='M0.183,9.15 C0.183,14.158 4.242,18.216 9.249,18.216 C14.256,18.216 18.315,14.158 18.315,9.15 C18.315,4.144 14.256,0.085 9.249,0.085 C4.242,0.085 0.183,4.144 0.183,9.15 Z'
            id='path-29'
          />
          <path
            d='M21.053,0.628 C9.855,0.628 0.788,9.694 0.788,20.893 L0.788,76.889 C0.788,88.088 9.855,97.154 21.053,97.154 L139.445,97.154 C150.644,97.154 159.71,88.088 159.71,76.889 L159.71,20.893 C159.71,9.694 150.644,0.628 139.445,0.628 L21.053,0.628 Z'
            id='path-31'
          />
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Robot' transform='translate(-6.000000, -1.000000)'>
            <g id='other_details'>
              <g id='Group-5' transform='translate(36.000000, 1.175000)'>
                <path
                  d='M218.1584,109.5512 C218.1584,169.5992 169.4794,218.2782 109.4314,218.2782 C49.3844,218.2782 0.7054,169.5992 0.7054,109.5512 C0.7054,49.5032 49.3844,0.8242 109.4314,0.8242 C169.4794,0.8242 218.1584,49.5032 218.1584,109.5512'
                  id='Fill-1'
                  fill='var(--white)'
                />
                <path
                  d='M218.1584,109.5512 C218.1584,169.5992 169.4794,218.2782 109.4314,218.2782 C49.3844,218.2782 0.7054,169.5992 0.7054,109.5512 C0.7054,49.5032 49.3844,0.8242 109.4314,0.8242 C169.4794,0.8242 218.1584,49.5032 218.1584,109.5512 Z'
                  id='Stroke-3'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
              </g>
              <g id='Group-9' transform='translate(36.000000, 1.175000)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1' />
                </mask>
                <g id='Clip-7' />
                <polygon
                  id='Fill-6'
                  fill='#C8E6FC'
                  mask='url(#mask-2)'
                  points='59.091 224 158.283 224 158.283 171.006 59.091 171.006'
                />
                <polygon
                  id='Stroke-8'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                  mask='url(#mask-2)'
                  points='59.091 224 158.283 224 158.283 171.006 59.091 171.006'
                />
              </g>
              <g id='Group-17' transform='translate(0.000000, 0.175000)'>
                <path
                  d='M254.1584,110.5512 C254.1584,170.5992 205.4794,219.2782 145.4314,219.2782 C85.3844,219.2782 36.7054,170.5992 36.7054,110.5512 C36.7054,50.5032 85.3844,1.8242 145.4314,1.8242 C205.4794,1.8242 254.1584,50.5032 254.1584,110.5512 Z'
                  id='Stroke-10'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <polygon
                  id='Fill-12'
                  fill='var(--white)'
                  points='58.966 124.5072 59.002 109.4122 12.975 109.3042 12.939 124.3992'
                />
                <mask id='mask-4' fill='white'>
                  <use xlinkHref='#path-3' />
                </mask>
                <g id='Clip-15' />
                <polygon
                  id='Fill-14'
                  fill='#1A5E8F'
                  mask='url(#mask-4)'
                  points='16.763 120.593 55.16 120.593 55.16 113.127 16.763 113.127'
                />
                <path
                  d='M59.4748,100.4291 L65.8738,100.4451 L65.7948,133.5091 L59.3958,133.4931 C50.3298,133.4721 42.8818,125.9891 42.9038,116.9221 C42.9248,107.8571 50.4088,100.4081 59.4748,100.4291'
                  id='Fill-16'
                  fill='#C8E6FC'
                  mask='url(#mask-4)'
                />
              </g>
              <g id='Group-24' transform='translate(42.000000, 100.175000)'>
                <g id='Group-20'>
                  <mask id='mask-6' fill='white'>
                    <use xlinkHref='#path-5' />
                  </mask>
                  <g id='Clip-19' />
                  <polygon
                    id='Fill-18'
                    fill='#C8E6FC'
                    mask='url(#mask-6)'
                    points='30.4455 40.1908 -5.8185 40.1048 -5.7075 -6.2912 30.5555 -6.2052'
                  />
                </g>
                <g id='Group-23'>
                  <mask id='mask-8' fill='white'>
                    <use xlinkHref='#path-7' />
                  </mask>
                  <g id='Clip-22' />
                  <polygon
                    id='Stroke-21'
                    stroke='#1A5E8F'
                    strokeWidth='2'
                    mask='url(#mask-8)'
                    points='30.4455 40.1908 -5.8185 40.1048 -5.7075 -6.2912 30.5555 -6.2052'
                  />
                </g>
              </g>
              <g id='Group-29' transform='translate(7.000000, 100.175000)'>
                <path
                  d='M52.4748,0.4291 L58.8738,0.4451 L58.7948,33.5091 L52.3958,33.4931 C43.3298,33.4721 35.8818,25.9891 35.9038,16.9221 C35.9248,7.8571 43.4088,0.4081 52.4748,0.4291 Z'
                  id='Stroke-25'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <path
                  d='M9.7502,25.9262 C4.7432,25.9142 0.6932,21.8462 0.7062,16.8392 C0.7182,11.8322 4.7862,7.7822 9.7932,7.7942 C14.8002,7.8062 18.8502,11.8752 18.8382,16.8822 C18.8262,21.8892 14.7572,25.9382 9.7502,25.9262'
                  id='Fill-27'
                  fill='#C8E6FC'
                />
              </g>
              <g id='Group-36' transform='translate(7.000000, 107.175000)'>
                <g id='Group-32'>
                  <mask id='mask-10' fill='white'>
                    <use xlinkHref='#path-9' />
                  </mask>
                  <g id='Clip-31' />
                  <polygon
                    id='Fill-30'
                    fill='#C8E6FC'
                    mask='url(#mask-10)'
                    points='25.467 25.6303 -5.998 25.5553 -5.923 -5.9087 25.541 -5.8347'
                  />
                </g>
                <g id='Group-35'>
                  <mask id='mask-12' fill='white'>
                    <use xlinkHref='#path-11' />
                  </mask>
                  <g id='Clip-34' />
                  <polygon
                    id='Stroke-33'
                    stroke='#1A5E8F'
                    strokeWidth='2'
                    mask='url(#mask-12)'
                    points='25.467 25.6303 -5.998 25.5553 -5.923 -5.9087 25.541 -5.8347'
                  />
                </g>
              </g>
              <g id='Group-44' transform='translate(0.000000, 0.175000)'>
                <path
                  d='M16.7502,125.9262 C11.7432,125.9142 7.6932,121.8462 7.7062,116.8392 C7.7182,111.8322 11.7862,107.7822 16.7932,107.7942 C21.8002,107.8062 25.8502,111.8752 25.8382,116.8822 C25.8262,121.8892 21.7572,125.9382 16.7502,125.9262 Z'
                  id='Stroke-37'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <polygon
                  id='Fill-39'
                  fill='var(--white)'
                  points='231.5324 124.5072 231.4964 109.4122 277.5234 109.3042 277.5594 124.3992'
                />
                <mask id='mask-14' fill='white'>
                  <use xlinkHref='#path-13' />
                </mask>
                <g id='Clip-42' />
                <polygon
                  id='Fill-41'
                  fill='#1A5E8F'
                  mask='url(#mask-14)'
                  points='235.338 120.593 273.735 120.593 273.735 113.127 235.338 113.127'
                />
                <path
                  d='M231.0237,100.4291 L224.6247,100.4451 L224.7027,133.5091 L231.1017,133.4931 C240.1687,133.4721 247.6167,125.9891 247.5947,116.9221 C247.5737,107.8571 240.0897,100.4081 231.0237,100.4291'
                  id='Fill-43'
                  fill='#C8E6FC'
                  mask='url(#mask-14)'
                />
              </g>
              <g id='Group-51' transform='translate(224.000000, 100.175000)'>
                <g id='Group-47'>
                  <mask id='mask-16' fill='white'>
                    <use xlinkHref='#path-15' />
                  </mask>
                  <g id='Clip-46' />
                  <polygon
                    id='Fill-45'
                    fill='#C8E6FC'
                    mask='url(#mask-16)'
                    points='-5.948 40.1908 30.317 40.1048 30.206 -6.2912 -6.057 -6.2052'
                  />
                </g>
                <g id='Group-50'>
                  <mask id='mask-18' fill='white'>
                    <use xlinkHref='#path-17' />
                  </mask>
                  <g id='Clip-49' />
                  <polygon
                    id='Stroke-48'
                    stroke='#1A5E8F'
                    strokeWidth='2'
                    mask='url(#mask-18)'
                    points='-5.948 40.1908 30.317 40.1048 30.206 -6.2912 -6.057 -6.2052'
                  />
                </g>
              </g>
              <g id='Group-56' transform='translate(224.000000, 100.175000)'>
                <path
                  d='M7.0237,0.4291 L0.6247,0.4451 L0.7027,33.5091 L7.1017,33.4931 C16.1687,33.4721 23.6167,25.9891 23.5947,16.9221 C23.5737,7.8571 16.0897,0.4081 7.0237,0.4291 Z'
                  id='Stroke-52'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <path
                  d='M49.7483,25.9262 C54.7553,25.9142 58.8043,21.8462 58.7923,16.8392 C58.7803,11.8322 54.7123,7.7822 49.7043,7.7942 C44.6973,7.8062 40.6483,11.8752 40.6603,16.8822 C40.6723,21.8892 44.7403,25.9382 49.7483,25.9262'
                  id='Fill-54'
                  fill='#C8E6FC'
                />
              </g>
              <g id='Group-63' transform='translate(264.000000, 107.175000)'>
                <g id='Group-59'>
                  <mask id='mask-20' fill='white'>
                    <use xlinkHref='#path-19' />
                  </mask>
                  <g id='Clip-58' />
                  <polygon
                    id='Fill-57'
                    fill='#C8E6FC'
                    mask='url(#mask-20)'
                    points='-5.9685 25.6303 25.4955 25.5553 25.4215 -5.9087 -6.0425 -5.8347'
                  />
                </g>
                <g id='Group-62'>
                  <mask id='mask-22' fill='white'>
                    <use xlinkHref='#path-21' />
                  </mask>
                  <g id='Clip-61' />
                  <polygon
                    id='Stroke-60'
                    stroke='#1A5E8F'
                    strokeWidth='2'
                    mask='url(#mask-22)'
                    points='-5.9685 25.6303 25.4955 25.5553 25.4215 -5.9087 -6.0425 -5.8347'
                  />
                </g>
              </g>
              <g id='Group-68' transform='translate(0.000000, 0.175000)'>
                <path
                  d='M273.7483,125.9262 C278.7553,125.9142 282.8043,121.8462 282.7923,116.8392 C282.7803,111.8322 278.7123,107.7822 273.7043,107.7942 C268.6973,107.8062 264.6483,111.8752 264.6603,116.8822 C264.6723,121.8892 268.7403,125.9382 273.7483,125.9262 Z'
                  id='Stroke-64'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <mask id='mask-24' fill='white'>
                  <use xlinkHref='#path-23' />
                </mask>
                <g id='Clip-67' />
                <polygon
                  id='Fill-66'
                  fill='#1A5E8F'
                  mask='url(#mask-24)'
                  points='141.516 64.547 148.982 64.547 148.982 26.151 141.516 26.151'
                />
              </g>
              <g id='Group-71' transform='translate(141.000000, 26.175000)'>
                <mask id='mask-26' fill='white'>
                  <use xlinkHref='#path-25' />
                </mask>
                <g id='Clip-70' />
                <polygon
                  id='Fill-69'
                  fill='#1A5E8F'
                  mask='url(#mask-26)'
                  points='-6.15 45.214 14.649 45.214 14.649 -6.515 -6.15 -6.515'
                />
              </g>
              <g id='Group-77' transform='translate(128.000000, 52.175000)'>
                <mask id='mask-28' fill='white'>
                  <use xlinkHref='#path-27' />
                </mask>
                <g id='Clip-75' />
                <polygon
                  id='Fill-74'
                  fill='#C8E6FC'
                  mask='url(#mask-28)'
                  points='-5.949 29.881 40.447 29.881 40.447 -6.384 -5.949 -6.384'
                />
                <polygon
                  id='Stroke-76'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                  mask='url(#mask-28)'
                  points='-5.949 29.881 40.447 29.881 40.447 -6.384 -5.949 -6.384'
                />
                <path
                  d='M0.717,16.8139 L0.717,23.2129 L33.781,23.2129 L33.781,16.8139 C33.781,7.7489 26.315,0.2819 17.249,0.2819 C8.183,0.2819 0.717,7.7489 0.717,16.8139'
                  id='Fill-72'
                  fill='#C8E6FC'
                  mask='url(#mask-28)'
                />
              </g>
              <g id='Group-82' transform='translate(128.000000, 17.175000)'>
                <path
                  d='M0.717,51.8139 L0.717,58.2129 L33.781,58.2129 L33.781,51.8139 C33.781,42.7489 26.315,35.2819 17.249,35.2819 C8.183,35.2819 0.717,42.7489 0.717,51.8139 Z'
                  id='Stroke-78'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <path
                  d='M26.3147,9.1508 C26.3147,4.1438 22.2557,0.0848 17.2497,0.0848 C12.2427,0.0848 8.1827,4.1438 8.1827,9.1508 C8.1827,14.1578 12.2427,18.2158 17.2497,18.2158 C22.2557,18.2158 26.3147,14.1578 26.3147,9.1508'
                  id='Fill-80'
                  fill='#C8E6FC'
                />
              </g>
              <g id='Group-86' transform='translate(136.000000, 17.175000)'>
                <mask id='mask-30' fill='white'>
                  <use xlinkHref='#path-29' />
                </mask>
                <g id='Clip-84' />
                <polygon
                  id='Fill-83'
                  fill='#C8E6FC'
                  mask='url(#mask-30)'
                  points='-6.483 24.883 24.982 24.883 24.982 -6.582 -6.483 -6.582'
                />
                <polygon
                  id='Stroke-85'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                  mask='url(#mask-30)'
                  points='-6.483 24.883 24.982 24.883 24.982 -6.582 -6.483 -6.582'
                />
              </g>
              <g id='Group-91' transform='translate(65.000000, 17.175000)'>
                <path
                  d='M89.3147,9.1508 C89.3147,4.1438 85.2557,0.0848 80.2497,0.0848 C75.2427,0.0848 71.1827,4.1438 71.1827,9.1508 C71.1827,14.1578 75.2427,18.2158 80.2497,18.2158 C85.2557,18.2158 89.3147,14.1578 89.3147,9.1508 Z'
                  id='Stroke-87'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <path
                  d='M139.4445,155.1537 L21.0535,155.1537 C9.8545,155.1537 0.7885,146.0887 0.7885,134.8887 L0.7885,78.8927 C0.7885,67.6937 9.8545,58.6287 21.0535,58.6287 L139.4445,58.6287 C150.6435,58.6287 159.7105,67.6937 159.7105,78.8927 L159.7105,134.8887 C159.7105,146.0887 150.6435,155.1537 139.4445,155.1537'
                  id='Fill-89'
                  fill='#C8E6FC'
                />
              </g>
              <g id='Group-95' transform='translate(65.000000, 75.175000)'>
                <mask id='mask-32' fill='white'>
                  <use xlinkHref='#path-31' />
                </mask>
                <g id='Clip-93' />
                <polygon
                  id='Fill-92'
                  fill='#C8E6FC'
                  mask='url(#mask-32)'
                  points='-5.878 103.82 166.376 103.82 166.376 -6.038 -5.878 -6.038'
                />
                <polygon
                  id='Stroke-94'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                  mask='url(#mask-32)'
                  points='-5.878 103.82 166.376 103.82 166.376 -6.038 -5.878 -6.038'
                />
              </g>
              <g id='Group-106' transform='translate(65.000000, 75.175000)'>
                <path
                  d='M139.4445,97.1537 L21.0535,97.1537 C9.8545,97.1537 0.7885,88.0887 0.7885,76.8887 L0.7885,20.8927 C0.7885,9.6937 9.8545,0.6287 21.0535,0.6287 L139.4445,0.6287 C150.6435,0.6287 159.7105,9.6937 159.7105,20.8927 L159.7105,76.8887 C159.7105,88.0887 150.6435,97.1537 139.4445,97.1537 Z'
                  id='Stroke-96'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                />
                <polygon
                  id='Fill-98'
                  fill='#1A5E8F'
                  opacity='0.239999995'
                  points='30.837 110.338 128.119 110.338 128.119 98.498 30.837 98.498'
                />
                <path
                  d='M116.6809,61.6254 C110.7209,68.9804 96.7389,74.1444 80.4389,74.1444 C64.1399,74.1444 50.1569,68.9804 44.1969,61.6254'
                  id='Stroke-100'
                  stroke='#1A5E8F'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
            </g>
            <path
              d='M193.922,115.121 C195.396,115.909 196.95,119.096 197.533,120.751 L192.751,125.533 C191.095,124.95 187.909,123.397 187.121,121.922 L185.808,122.466 C186.294,124.066 185.139,127.418 184.382,129 L177.618,129 C176.86,127.418 175.706,124.066 176.191,122.466 L174.879,121.922 C174.091,123.397 170.904,124.95 169.249,125.533 L164.467,120.751 C165.05,119.096 166.603,115.909 168.078,115.121 L167.534,113.809 C165.934,114.294 162.582,113.14 161,112.382 L161,105.618 C162.582,104.861 165.934,103.706 167.534,104.192 L168.078,102.879 C166.603,102.091 165.05,98.905 164.467,97.249 L169.249,92.467 C170.904,93.05 174.091,94.604 174.879,96.078 L176.191,95.534 C175.706,93.935 176.86,90.582 177.618,89 L184.382,89 C185.139,90.582 186.294,93.935 185.808,95.534 L187.121,96.078 C187.909,94.604 191.095,93.05 192.751,92.467 L197.533,97.249 C196.95,98.905 195.396,102.091 193.922,102.879 L194.466,104.192 C196.065,103.706 199.418,104.861 201,105.618 L201,112.382 C199.418,113.14 196.065,114.294 194.466,113.809 L193.922,115.121 Z M186.657,103.343 C183.532,100.219 178.468,100.219 175.343,103.343 C172.219,106.468 172.219,111.532 175.343,114.657 C178.468,117.781 183.532,117.781 186.657,114.657 C189.781,111.532 189.781,106.468 186.657,103.343 Z'
              id='cogwheel_right'
              stroke='#1A5E8F'
              strokeWidth='2'
            />
            <path
              d='M121.922,115.121 C123.396,115.909 124.95,119.096 125.533,120.751 L120.751,125.533 C119.095,124.95 115.909,123.397 115.121,121.922 L113.808,122.466 C114.294,124.066 113.139,127.418 112.382,129 L105.618,129 C104.86,127.418 103.706,124.066 104.191,122.466 L102.879,121.922 C102.091,123.397 98.904,124.95 97.249,125.533 L92.467,120.751 C93.05,119.096 94.603,115.909 96.078,115.121 L95.534,113.809 C93.934,114.294 90.582,113.14 89,112.382 L89,105.618 C90.582,104.861 93.934,103.706 95.534,104.192 L96.078,102.879 C94.603,102.091 93.05,98.905 92.467,97.249 L97.249,92.467 C98.904,93.05 102.091,94.604 102.879,96.078 L104.191,95.534 C103.706,93.935 104.86,90.582 105.618,89 L112.382,89 C113.139,90.582 114.294,93.935 113.808,95.534 L115.121,96.078 C115.909,94.604 119.095,93.05 120.751,92.467 L125.533,97.249 C124.95,98.905 123.396,102.091 121.922,102.879 L122.466,104.192 C124.065,103.706 127.418,104.861 129,105.618 L129,112.382 C127.418,113.14 124.065,114.294 122.466,113.809 L121.922,115.121 Z M114.657,103.343 C111.532,100.219 106.468,100.219 103.343,103.343 C100.219,106.468 100.219,111.532 103.343,114.657 C106.468,117.781 111.532,117.781 114.657,114.657 C117.781,111.532 117.781,106.468 114.657,103.343 Z'
              id='cogwheel_left'
              stroke='#1A5E8F'
              strokeWidth='2'
            />
          </g>
        </g>
        <path
          d='M226.494298,53.6662265 L216.945872,59.1865267 C215.212559,60.2220589 212.9851,60.3058151 211.114946,59.2245977 L201.498099,53.6662265 L192.246161,48.3134388 C190.322792,47.3768914 189,45.4048117 189,43.1129354 L189,31.9961929 L189,20.8794504 L189.015205,20.8794504 C189.015205,18.8921424 190.049111,16.9581337 191.888855,15.8921447 L201.505702,10.3337735 L211.054128,4.81347332 C212.787441,3.77794115 215.0149,3.69418487 216.885054,4.77540229 L226.501901,10.3337735 L235.753839,15.6865612 C237.677208,16.6231086 239,18.5951883 239,20.8870646 L239,32.0038071 L239,43.1205496 L238.984795,43.1205496 C238.984795,45.1078576 237.950889,47.0418663 236.111145,48.1078553 L226.494298,53.6662265 Z'
          id='Path'
          fill='#1B7ABE'
        />
        <path
          d='M225.855951,25.656878 L217.245583,10.8803192 C216.926961,10.3244141 216.464201,9.83611911 215.864889,9.4980687 C214.089712,8.47640526 211.813844,9.07738376 210.789703,10.8352459 L202.14899,25.656878 L202.156576,25.656878 L202.14899,25.6643902 L193.690347,40.1780208 C193.257932,40.7789993 193,41.515198 193,42.3114945 C193,44.3473091 194.66897,46 196.724838,46 L214.006264,46 L231.287689,46 L231.287689,45.9849755 C231.917346,45.9849755 232.554589,45.8272187 233.138729,45.4966805 C234.913906,44.4825293 235.528391,42.2438844 234.50425,40.4860223 L225.97733,25.8597082 C225.939399,25.7920981 225.909054,25.724488 225.863537,25.656878 L225.855951,25.656878 Z'
          id='Path'
          fill='#93D5F6'
        />
        <path
          d='M227,47 C223.640115,50.0975955 219.051003,52 214,52 C208.948997,52 204.359885,50.0905233 201,47 L214.00745,47 L227,47 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M202.313433,25.4579595 L195.564953,37 C195.201216,35.5247591 195,33.9883682 195,32.406115 C195,23.6769691 200.912659,16.3084081 209,14 L202.305694,25.4503157 L202.313433,25.4503157 L202.305694,25.4579595 L202.313433,25.4579595 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M232.442169,37 L225.810183,25.6606786 L225.693968,25.4540918 L219,14 C227.088545,16.3183633 233,23.6866267 233,32.416833 C233,33.993014 232.806309,35.5309381 232.442169,37 Z'
          id='Path'
          fill='#1CBBEE'
        />
        <path
          d='M226.492501,26.4363383 L233,37.7992565 C232.133347,41.3568773 230.286008,44.5234665 227.762071,47 L214.503801,47 L201.230327,47 C198.713992,44.5311338 196.866653,41.3722119 196,37.8222584 L202.629135,26.2446561 L202.636737,26.2369888 L202.629135,26.2369888 L209.205054,14.7513941 C210.885145,14.2606877 212.664064,14 214.503801,14 C216.35114,14 218.137662,14.268355 219.825354,14.7590613 L226.393672,26.2369888 L226.507705,26.4440056 L226.492501,26.4363383 Z'
          id='Path'
          fill='#FEFEFE'
        />
      </svg>
    );

    return <div>{icon}</div>;
  }
}

export default Robot503;
