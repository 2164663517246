import { TConnector, JStateWithId } from '../../../reducers/JGraph.reducer/types';
import Konva from 'konva';
import { Observable } from 'rxjs';
import { Vector2d } from 'konva/lib/types';
import { RefObject, useEffect } from 'react';
import {
  getConnectorPropsFromName,
  getValidKonvaName,
  getAllStatePaths,
  getConnectionsFromBlocks,
} from '../../../reducers/JGraph.reducer/Graph';
import { TagNames } from './types';
import { useScreenContext } from '../hooks';
import { v4 as uuid } from 'uuid';

import { ConnectorStore } from '../contexts/types';
import { getStageContextFromRef } from './stageUtils';
import { Vector2D } from './2DVector';
import { usePathContext } from '../view/IncomingOutgoingConnections/IncomingConnectionsList';

//m0 3 l5,3 l-5,3z
export const STATIC_TRIANGLE = (deferred?: boolean) =>
  deferred ? 'm-12 -3 l12,3 l-12,3 l2,-3 l-2,-3 l12,3' : 'm-8 -3 l8,3 l-8,3 l2,-3 l-2,-3 l8,3';

const reverseXYQ = (str: string): string => {
  const arr = str.split(' ').map(num => parseInt(num, 10));
  if (arr.every(num => num >= 0) || arr.every(num => num <= 0)) {
    return `${-arr[1]} ${-arr[0]} ${-arr[3]} ${-arr[2]}`;
  }
  return `${arr[1]} ${arr[0]} ${arr[3]} ${arr[2]}`;
};

export const getConnectorPath = (fromPosition: Vector2d, toPosition: Vector2d, deferred?: boolean): string => {
  const yDistance = toPosition.y - fromPosition.y;
  const xDistance = toPosition.x - fromPosition.x;
  const ySign = Math.abs(yDistance) / yDistance;
  const xSign = Math.abs(xDistance) / xDistance;
  let curveRadius = 11;
  const minDistance = Math.min(Math.abs(yDistance) / 2, Math.abs(xDistance) / 2);
  if (minDistance < curveRadius) {
    curveRadius = minDistance / 2;
  }
  let xyQ = {
    first: `${curveRadius} 0 ${curveRadius} -${curveRadius}`,
    second: `0 -${curveRadius} ${curveRadius} -${curveRadius}`,
  };
  switch (ySign + xSign) {
    case -2: {
      xyQ = {
        first: `-${curveRadius} 0 -${curveRadius} -${curveRadius}`,
        second: `0 -${curveRadius} ${curveRadius} -${curveRadius}`,
      };
      break;
    }
    case 0: {
      if (xSign === -1) {
        xyQ = {
          first: `-${curveRadius} 0 -${curveRadius} ${curveRadius}`,
          second: `0 ${curveRadius} ${curveRadius} ${curveRadius}`,
        };
        break;
      }
      xyQ = {
        first: `${curveRadius} 0 ${curveRadius} -${curveRadius}`,
        second: `0 -${curveRadius} ${curveRadius} -${curveRadius}`,
      };
      break;
    }
    case 2: {
      xyQ = {
        first: `${curveRadius} 0 ${curveRadius} ${curveRadius}`,
        second: `0 ${curveRadius} ${curveRadius} ${curveRadius}`,
      };
      break;
    }
    default: {
      xyQ = {
        first: `0 0 0 0`,
        second: `0 0 0 0`,
      };
      break;
    }
  }
  //q dx1 dy1, dx dy
  // data='M10 280 h 50 q 11 0 11 -11 v -100 q 0 -11 11 -11 h 30'
  const firstHorizontalMove = xSign > 0 ? xDistance / 2 - curveRadius * 2 : xDistance - 30;
  const firstVerticalMove = yDistance - curveRadius * 2 * ySign;
  const lastHorizontalMove = xSign > 0 ? xDistance / 2 - 5 : 25;
  return `M${fromPosition.x} ${fromPosition.y} h ${firstHorizontalMove} q ${xyQ.first} v ${firstVerticalMove} q ${
    xyQ.second
  } h ${lastHorizontalMove} ${STATIC_TRIANGLE(deferred)} m-3 h ${-lastHorizontalMove} q ${reverseXYQ(
    xyQ.second
  )} v ${-firstVerticalMove} q ${reverseXYQ(xyQ.first)} h ${-firstHorizontalMove}`;
};

export const getConnectorName = (connector: TConnector) => {
  return `${connector.from}_${connector.to}_${connector.deferred}`;
};

const substractStageOffset = (
  start: Konva.Vector2d,
  end: Konva.Vector2d,
  stageOffset: Konva.Vector2d
): [Konva.Vector2d, Konva.Vector2d] => {
  start.x -= stageOffset.x;
  start.y -= stageOffset.y;

  end.x -= stageOffset.x;
  end.y -= stageOffset.y;
  return [start, end];
};

const divideStageScale = (start: Konva.Vector2d, end: Konva.Vector2d, stage: Konva.Stage) => {
  start.x = start.x / stage.scaleX();
  start.y = start.y / stage.scaleY();
  end.x = end.x / stage.scaleX();
  end.y = end.y / stage.scaleY();
  return [start, end];
};

export const updateUsingStageProps = (
  stage: Konva.Stage,
  start: Konva.Vector2d,
  end: Konva.Vector2d
): [Vector2D, Vector2D] => {
  const stageAbsolutePos = stage.position();
  [start, end] = substractStageOffset(start, end, stageAbsolutePos);
  [start, end] = divideStageScale(start, end, stage);

  return [Vector2D.from(start.x, start.y), Vector2D.from(end.x, end.y)];
};

export const absolutePositionToRelative = (stage: Konva.Stage, pos: Konva.Vector2d): Vector2D => {
  return Vector2D.from(pos.x, pos.y)
    .subtract(stage.position())
    .divide(stage.scale() ?? { x: 1, y: 1 });
};

export const allBlocksSetted = (
  store: ConnectorStore,
  from: TConnector['from'],
  to: TConnector['to'],
  fromNode: TConnector['fromNode']
) => {
  return (
    store[from] &&
    to &&
    store[to] &&
    store[to].toRef &&
    (store[from].fromRef || (store[fromNode] && store[fromNode].fromRefFallBack))
  );
};

export const applyConnectorOffsets = (
  fromPosition: Vector2d,
  toPosition: Vector2d,
  options: { isToNodeScreen: boolean }
) => {
  if (options.isToNodeScreen) {
    toPosition.y += 18;
    if (fromPosition.x > toPosition.x) {
      fromPosition.x -= 200;
    } else {
      fromPosition.x += 15;
    }
  } else {
    toPosition.x -= 10;
    toPosition.y += 8;
  }
  return { fromPosition, toPosition };
};
export const applyIslandConnectorOffsets = (
  fromPosition: Vector2d,
  toPosition: Vector2d,
  options: { isToNodeScreen: boolean }
) => {
  if (options.isToNodeScreen) {
    toPosition.y += 18;
  } else {
    toPosition.x -= 10;
    toPosition.y += 8;
  }
  return { fromPosition, toPosition };
};

export const recalculateConnectionsOnBlockMove = (
  nodeId: string,
  stage: Konva.Stage | null,
  ConnectorsStore$: Observable<TConnector[]>,
  connectorsFromStore$: Observable<ConnectorStore>
): void => {
  if (!stage) return;
  let sub = ConnectorsStore$.subscribe(connections => {
    const affectedConnections = connections.filter(
      connection => connection.to?.startsWith(nodeId) || connection.fromNode.startsWith(nodeId)
    );
    if (affectedConnections.length > 0) {
      recalculateAffectedConnections(affectedConnections, connectorsFromStore$, stage, [nodeId]);

      stage.batchDraw();
    }
  });
  sub.unsubscribe();
};

export const recalculateAffectedConnections = (
  affectedConnections: TConnector[],
  connectorsFromStore$: Observable<ConnectorStore>,
  stage: Konva.Stage,
  actionNodeIds: string[] = []
) => {
  const splinesLayer = stage.children && stage.children[0];
  if (!splinesLayer || !splinesLayer.children) return;

  const splinesMap = splinesLayer.children.reduce((acc, node) => {
    if (!acc.has(node.name())) {
      acc.set(node.name(), [
        {
          node,
          from: node.attrs?.from,
          to: node.attrs?.to,
        },
      ]);
    } else {
      acc.get(node.name())?.push({
        node,
        from: node.attrs?.from,
        to: node.attrs?.to,
      });
    }

    return acc;
  }, new Map<string, { from: string; to: string; node: Konva.Node }[]>([]));

  let sub = connectorsFromStore$.subscribe(store => {
    affectedConnections.forEach(connection => {
      const { from, to, fromNode } = connection;
      if (to && from && fromNode && allBlocksSetted(store, from, to, fromNode) && stage) {
        splinesMap
          .get(getConnectorName(connection))
          ?.map(el => el.node as Konva.Path)
          .forEach(node => node.data(node.attrs.calculatePath()));
      }
    });

    const islandGroups: Konva.Node[] = [];

    const outConnections = splinesMap.get('OutgoingConnector');
    outConnections?.forEach(outConnection => {
      if (outConnection && actionNodeIds.some(el => outConnection.from.includes(el))) {
        islandGroups.push(outConnection.node);
      }
    });

    const inConnections = splinesMap.get('IncomingConnector');
    inConnections?.forEach(inConnection => {
      if (inConnection && actionNodeIds.some(el => inConnection.to.includes(el))) {
        islandGroups.push(inConnection.node);
      }
    });

    if (islandGroups) {
      islandGroups.forEach(island => {
        if (island.attrs?.hasOwnProperty('setPositionsHandler')) {
          island.attrs.setPositionsHandler();
        }
      });
    }
  });
  sub.unsubscribe();
};

export const useConnectorStore = (
  name: string,
  tagName: TagNames,
  transitionTo: string = '',
  CircleRef: RefObject<Konva.Node>,
  debugActive: boolean = false
) => {
  const { screen } = useScreenContext();
  const { path } = usePathContext();

  const screenPath = (screen || {})?.path;

  useEffect(() => {
    const transitionParams = getConnectorPropsFromName(name);
    const { ConnectorsSubject$ } = getStageContextFromRef(CircleRef);
    let Connector: TConnector | null = null;
    if (transitionTo) {
      let value = transitionParams.tagName.split('_').filter(value => !!value);
      let valueToShow = value[value.length - 1];
      Connector = {
        from: name,
        fromNode: transitionParams.blockPathId,
        uuid: uuid(),
        value: valueToShow,
        fromNodeOriginalPath: screenPath || path,
        toNodeOriginalPath: transitionTo,
        tagName: tagName,
        to: transitionTo ? getValidKonvaName(transitionTo) : transitionTo,
        deferred: tagName === TagNames.go,
        debugActive: debugActive,
      };
      ConnectorsSubject$.next({ type: 'add', connector: Connector });
    }

    return () => {
      if (Connector) {
        ConnectorsSubject$.next({ type: 'remove', connector: Connector });
      }
    };
  }, [CircleRef, name, screenPath, tagName, transitionTo, path, debugActive]);
};

export function replaceAllConnectionsToGroup(screen: JStateWithId, targetGroup: Konva.Group, fromCircle: Konva.Circle) {
  const { ConnectorsSubject$, connectorsFromPipe$, getConnectors } = getStageContextFromRef({
    current: targetGroup,
  });
  const connectorsFromPipeSave$ = connectorsFromPipe$;
  const statePaths = getAllStatePaths(screen);
  let valueToPipe: { [key: string]: {} } = {};
  statePaths.forEach(statePath => {
    valueToPipe[getValidKonvaName(statePath)] = {
      toRef: targetGroup,
    };
  });
  connectorsFromPipe$.next(valueToPipe);

  const stateConnectors = getConnectionsFromBlocks(screen.states || []).connections.filter(
    connection => !connection.to?.startsWith(screen.pathId)
  );
  const aboveConnectors = getConnectors().filter(connection => connection.from.includes('fromState'));

  stateConnectors.forEach(stateConnector => {
    if (!stateConnector.from.includes('fromState')) {
      ConnectorsSubject$.next({ type: 'add', connector: stateConnector });
      connectorsFromPipe$.next({
        [stateConnector.from]: {
          fromRef: fromCircle,
        },
      });
    }
  });
  aboveConnectors.forEach(stateConnector => {
    ConnectorsSubject$.next({ type: 'add', connector: stateConnector });
    connectorsFromPipe$.next({
      [stateConnector.from]: {
        fromRef: fromCircle,
      },
    });
  });

  return () => {
    if (!connectorsFromPipeSave$ || !screen.path) return;
    let valueToPipe: { [key: string]: {} } = {
      [getValidKonvaName(screen.path)]: {
        toRef: undefined,
      },
    };
    statePaths.forEach(statePath => {
      valueToPipe[getValidKonvaName(statePath)] = {
        toRef: undefined,
      };
    });
    connectorsFromPipeSave$.next(valueToPipe);
    stateConnectors.forEach(stateConnector => {
      if (!stateConnector.from.includes('fromState')) {
        ConnectorsSubject$.next({ type: 'remove', connector: stateConnector });
        connectorsFromPipe$.next({
          [stateConnector.from]: {
            fromRef: undefined,
          },
        });
      }
    });
    aboveConnectors.forEach(stateConnector => {
      ConnectorsSubject$.next({ type: 'remove', connector: stateConnector });
      connectorsFromPipe$.next({
        [stateConnector.from]: {
          fromRef: undefined,
        },
      });
    });
  };
}
