import React, { FunctionComponent } from 'react';
import {
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  Select,
  InputGroup,
  InputText,
  DropdownButton,
  DropdownToggle,
  Icon,
  DropdownMenu,
  DropdownItem,
} from '@just-ai/just-ui';
import { LoggerEnum, LevelEnum, LevelEnumFrom, TimeEnum } from '../../model/LogItem';
import { useLogContext } from '../LogContext';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import localize from '../../../../localization';

type LoggerOption = { label: string; value: LoggerEnum };
const LOGGER_OPTIONS: LoggerOption[] = [
  { label: 'Engine', value: LoggerEnum.ENGINE },
  { label: 'Executor', value: LoggerEnum.EXECUTOR },
  { label: 'Matcher', value: LoggerEnum.MATCHER },
  { label: 'JS', value: LoggerEnum.JS },
  { label: 'HTTP', value: LoggerEnum.HTTP },
  { label: 'BotAPI', value: LoggerEnum.BOT_API },
  { label: 'Other', value: LoggerEnum.OTHER },
];
const LoggerOptionsFromValues = (loggers: LoggerEnum[]): LoggerOption[] =>
  loggers.map(logger => LOGGER_OPTIONS.find(option => option.value === logger)!);

type LevelOption = { label: string; value: LevelEnum };
const LEVEL_OPTIONS: LevelOption[] = [
  { label: 'Alert', value: LevelEnum.ALERT },
  { label: 'Critical', value: LevelEnum.CRITICAL },
  { label: 'Error', value: LevelEnum.ERROR },
  { label: 'Warning', value: LevelEnum.WARNING },
  { label: 'Notice', value: LevelEnum.NOTICE },
  { label: 'Debug', value: LevelEnum.DEBUG },
  { label: 'Info', value: LevelEnum.INFO },
];
const LevelOptionFromValue = (level?: LevelEnum) =>
  level ? LEVEL_OPTIONS.find(option => option.value === level) : undefined;

const { t } = i18nTranslation('LogPanel');

type TimeOption = { label: string; value: TimeEnum };

const TimeDropdown = () => {
  const { selectTime } = useLogContext();

  const { translate } = localize;

  const TIME_OPTIONS: TimeOption[] = [
    { label: translate(`logger time ${TimeEnum.MINUTES5}`), value: TimeEnum.MINUTES5 },
    { label: translate(`logger time ${TimeEnum.MINUTES30}`), value: TimeEnum.MINUTES30 },
    { label: translate(`logger time ${TimeEnum.HOUR}`), value: TimeEnum.HOUR },
    { label: translate(`logger time ${TimeEnum.HOURS12}`), value: TimeEnum.HOURS12 },
    { label: translate(`logger time ${TimeEnum.DAY}`), value: TimeEnum.DAY },
  ];

  return (
    <DropdownButton className='time-dropdown' compact dark direction='down' data-test-id='LogPanel.toolbar.timeButton'>
      <DropdownToggle caret color='none' compact dark iconButton size='md'>
        <Icon className='btn-icon' color='none' name='farClock' size='md' />
      </DropdownToggle>
      <DropdownMenu right className='py-3 px-4 time-dropdown__menu dropdown-menu-down'>
        {TIME_OPTIONS.map(option => (
          <DropdownItem
            key={`LogPanel.toolbar.time.${option.value}`}
            data-test-id={`LogPanel.toolbar.time.${option.value}`}
            onClick={() => selectTime(option.value)}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownButton>
  );
};

type FilterToolbarProps = {};
const FilterToolbar: FunctionComponent<FilterToolbarProps> = () => {
  const { textFilter, setTextFilter, selectedLoggers, selectLoggers, selectedLevel, selectLevel } = useLogContext();

  return (
    <Toolbar className='filter-toolbar'>
      <ToolbarLeft>
        <Select
          isMulti
          placeholder={t('loggersSelectPlaceholder')}
          size='sm'
          dark
          className='filter-toolbar__select-logger'
          options={LOGGER_OPTIONS}
          value={LoggerOptionsFromValues(selectedLoggers)}
          data-test-id='LogPanel.toolbar.logType'
          onChange={options =>
            selectLoggers(options && Array.isArray(options) ? options.map(option => option.value) : [])
          }
        />{' '}
        <Select
          isClearable
          placeholder={t('levelsSelectPlaceholder')}
          size='sm'
          dark
          className='filter-toolbar__select-level'
          data-test-id='LogPanel.toolbar.events'
          options={LEVEL_OPTIONS}
          value={LevelOptionFromValue(selectedLevel)}
          onChange={option =>
            //@ts-ignore
            selectLevel(option && !Array.isArray(option) ? LevelEnumFrom(option.value) : undefined)
          }
        />
      </ToolbarLeft>
      <ToolbarRight>
        <InputGroup
          PrependInner={[
            {
              name: 'faSearch',
            },
          ]}
          Append={<TimeDropdown />}
        >
          <InputText
            dark
            placeholder={t('searchInputPlaceholder')}
            value={textFilter}
            data-test-id='LogPanel.toolbar.search'
            onChange={value => setTextFilter(value)}
          />
        </InputGroup>
      </ToolbarRight>
    </Toolbar>
  );
};

export default FilterToolbar;
