import {
  DELETE_ISSUE_FULFILLED,
  DELETE_ISSUE_PENDING,
  DELETE_SESSION_ISSUE_FULFILLED,
  DELETE_SESSION_ISSUE_PENDING,
  FILTER_PRIORITY_SETTINGS_FULFILLED,
  FILTER_PRIORITY_SETTINGS_PENDING,
  FILTER_SETTINGS_FULFILLED,
  FILTER_SETTINGS_PENDING,
  MARK_TASK_AS_CHANGEABLE,
  SET_SELECT,
  SET_STORE,
  SET_TASK_SELECT,
  TASKS_LOAD_FULFILLED,
  TASKS_LOAD_PENDING,
  TOTAL_ELEMENTS_LOAD_FULFILLED,
  UPDATE_ISSUE_FULFILLED,
  UPDATE_ISSUE_PENDING,
  UPDATE_ISSUE_REJECTED,
  UPDATE_SESSION_ISSUE_FULFILLED,
  UPDATE_SESSION_ISSUE_PENDING,
  UPDATE_SESSION_ISSUE_REJECTED,
} from '../../constants/tasks.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import forEach from 'lodash/forEach';
import moment from 'moment';

let today = new Date();
let todayStart = moment(today.setHours(0, 0, 0, 0)).subtract(1, 'month').format('x');
let todayEnd = moment(today.setHours(23, 59, 59, 999)).format('x');

const InitialState = {
  tasks: [],
  selectedTask: null,

  totalTasksElements: [],
  tags: [],
  channels: [],
  states: [],
  users: [],
  priorities: [],

  sessionLabelsIds: [],
  selectedTags: [],
  selectedBotName: [],
  selectedPriorities: [],
  selectedStates: [],
  selectedUsers: [],

  totalPages: 0,
  totalCount: 0,
  page: 0,
  filterActive: false,
  filterFetching: false,

  modalLoad: false,
  fetching: false,

  updateIssueErrors: [],

  dateFrom: todayStart,
  dateTo: todayEnd,
};

export default function TasksReducer(state = InitialState, action) {
  switch (action.type) {
    case TASKS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case TASKS_LOAD_FULFILLED: {
      forEach(action.payload.data.content, item => {
        if (item.labels.length > 0) {
          let labelVals = [];
          forEach(item.labels, item => {
            labelVals.push({
              value: item.id,
              label: item.name,
            });
          });
        }
      });
      return {
        ...state,
        tasks: action.payload.data.content,
        totalCount: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        page: action.payload.data.number,
        filterActive: action.payload.config.filterActive,
        fetching: false,
      };
    }

    case TOTAL_ELEMENTS_LOAD_FULFILLED: {
      return {
        ...state,
        totalTasksElements: action.payload.data.totalElements,
        totalCount: action.payload.data.totalElements,
      };
    }
    case UPDATE_SESSION_ISSUE_PENDING:
    case UPDATE_ISSUE_PENDING: {
      return {
        ...state,
        modalLoad: true,
        updateIssueErrors: [],
      };
    }

    case UPDATE_SESSION_ISSUE_FULFILLED:
    case UPDATE_ISSUE_FULFILLED: {
      let newStateTasks = [...state.tasks];
      const index = newStateTasks.findIndex(item => {
        return item.id === action.payload.data.id;
      });
      newStateTasks[index] = {
        ...newStateTasks[index],
        ...action.payload.data,
      };
      return {
        ...state,
        tasks: newStateTasks,
        modalLoad: false,
        selectedTask: null,
      };
    }
    case UPDATE_SESSION_ISSUE_REJECTED:
    case UPDATE_ISSUE_REJECTED: {
      return {
        ...state,
        updateIssueErrors: action.payload.response.data.errors,
        modalLoad: false,
      };
    }
    case DELETE_SESSION_ISSUE_PENDING:
    case DELETE_ISSUE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case DELETE_SESSION_ISSUE_FULFILLED:
    case DELETE_ISSUE_FULFILLED: {
      let newTasks = state.tasks.slice();
      return {
        ...state,
        tasks: newTasks.filter(item => {
          return item.id !== action.payload.config.id;
        }),
        fetching: false,
      };
    }

    case FILTER_SETTINGS_PENDING: {
      return {
        ...state,
        filterFetching: true,
      };
    }
    case FILTER_PRIORITY_SETTINGS_PENDING: {
      return {
        ...state,
        filterFetching: true,
      };
    }
    case FILTER_PRIORITY_SETTINGS_FULFILLED: {
      return {
        ...state,
        priorities: action.payload.data,
      };
    }
    case FILTER_SETTINGS_FULFILLED: {
      return {
        ...state,
        filterFetching: false,
        users: action.payload.data.users,

        tags: action.payload.data.labels,
        states: action.payload.data.states,
      };
    }
    case SET_SELECT: {
      return {
        ...state,
        [action.label]: action.array,
      };
    }
    case MARK_TASK_AS_CHANGEABLE: {
      let selectedTask = [null];
      if (action.taskId > 0) {
        selectedTask = state.tasks.filter(item => {
          return item.id === action.taskId;
        });
      }
      return {
        ...state,
        selectedTask: selectedTask[0],
        updateIssueErrors: [],
      };
    }
    case SET_TASK_SELECT: {
      let newSelectedTask = { ...state.selectedTask };
      newSelectedTask[action.label] = action.value;
      return {
        ...state,
        selectedTask: newSelectedTask,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED: {
      return {
        ...InitialState,
      };
    }

    case SET_STORE: {
      return {
        ...state,
        ...action.payload,
        dateFromTimeLimit: action.dateFromTimeLimit,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
