import { isPreviousNonEmptyTokenInArray, removePathPrefix } from './utils';

export class Intents {
  intents: string[];
  KEYWORDS: string[];
  NAME: string;

  constructor(intents: string[], KEYWORDS: string[], NAME: string) {
    this.intents = intents;
    this.KEYWORDS = KEYWORDS;
    this.NAME = NAME;
  }

  getCompletions = (value: string) =>
    this.intents
      .filter(intent => intent.startsWith(value) && intent !== value)
      .map(intent => {
        return {
          value: removePathPrefix(intent, value),
          name: intent,
          score: 1000,
          meta: this.NAME,
        };
      });

  hasCompletions = (tokens: string[]) => {
    return this.intents.some(
      intent => intent.startsWith(tokens[tokens.length - 1]) && intent !== tokens[tokens.length - 1]
    );
  };

  isApplicable = (tokens: string[]) =>
    isPreviousNonEmptyTokenInArray(tokens, this.KEYWORDS) && this.hasCompletions(tokens);
}
