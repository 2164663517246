const prefix = '@SIP_TRUNK/';

export const LIST_SIP_TRUNK = prefix + 'LIST_SIP_TRUNK';
export const LIST_SIP_TRUNK_PENDING = prefix + 'LIST_SIP_TRUNK_PENDING';
export const LIST_SIP_TRUNK_FULFILLED = prefix + 'LIST_SIP_TRUNK_FULFILLED';
export const LIST_SIP_TRUNK_REJECTED = prefix + 'LIST_SIP_TRUNK_REJECTED';

export const AVAILABLE_CODECS = prefix + 'AVAILABLE_CODECS';
export const AVAILABLE_CODECS_PENDING = prefix + 'AVAILABLE_CODECS_PENDING';
export const AVAILABLE_CODECS_FULFILLED = prefix + 'AVAILABLE_CODECS_FULFILLED';
export const AVAILABLE_CODECS_REJECTED = prefix + 'AVAILABLE_CODECS_REJECTED';

export const CREATE_SIP_TRUNK = prefix + 'CREATE_SIP_TRUNK';
export const CREATE_SIP_TRUNK_PENDING = prefix + 'CREATE_SIP_TRUNK_PENDING';
export const CREATE_SIP_TRUNK_FULFILLED = prefix + 'CREATE_SIP_TRUNK_FULFILLED';
export const CREATE_SIP_TRUNK_REJECTED = prefix + 'CREATE_SIP_TRUNK_REJECTED';

export const DELETE_SIP_TRUNK = prefix + 'DELETE_SIP_TRUNK';
export const DELETE_SIP_TRUNK_PENDING = prefix + 'DELETE_SIP_TRUNK_PENDING';
export const DELETE_SIP_TRUNK_FULFILLED = prefix + 'DELETE_SIP_TRUNK_FULFILLED';
export const DELETE_SIP_TRUNK_REJECTED = prefix + 'DELETE_SIP_TRUNK_REJECTED';

export const EDIT_SIP_TRUNK = prefix + 'EDIT_SIP_TRUNK';
export const EDIT_SIP_TRUNK_PENDING = prefix + 'EDIT_SIP_TRUNK_PENDING';
export const EDIT_SIP_TRUNK_FULFILLED = prefix + 'EDIT_SIP_TRUNK_FULFILLED';
export const EDIT_SIP_TRUNK_REJECTED = prefix + 'EDIT_SIP_TRUNK_REJECTED';

export const READ_SIP_TRUNK = prefix + 'READ_SIP_TRUNK';
export const READ_SIP_TRUNK_PENDING = prefix + 'READ_SIP_TRUNK_PENDING';
export const READ_SIP_TRUNK_FULFILLED = prefix + 'READ_SIP_TRUNK_FULFILLED';
export const READ_SIP_TRUNK_REJECTED = prefix + 'READ_SIP_TRUNK_REJECTED';

export const UNSET_EDITABLE_SIP_TRUNK = prefix + 'UNSET_EDITABLE_SIP_TRUNK';
