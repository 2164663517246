import React, { FC } from 'react';

import { withController } from './Controller';
import { DEFAULT_EDITOR_PROPS } from '../../../../../Editor/components/CodeEditor';
import AceEditor from 'react-ace';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';
import CustomTagWarningBlock from './CustomTagWarningBlock';
import { t } from 'localization';

const JsonView: FC<CommonFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  onBlur,
  invalid,
  description,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  return (
    <div className='form-group form-group-fix overflow-hidden'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <div
        style={{
          height: 208,
          width: '100%',
        }}
      >
        <AceEditor {...DEFAULT_EDITOR_PROPS} value={value} mode='json' theme='github' onChange={onChange} />
      </div>
      {invalid && (
        <div className='invalid-feedback' style={{ display: 'block' }}>
          {t('Required Field')}
        </div>
      )}
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const JsonField = withController(JsonView);
