import React, { FunctionComponent } from 'react';
import { Button } from '@just-ai/just-ui';
import { useGlobalBottomPanelContext } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';

const { t } = i18nTranslation('LogPanel');

export const LogButton: FunctionComponent = () => {
  const { togglePanel } = useGlobalBottomPanelContext();

  return (
    <Button
      data-test-id='LogPanel.bottomToolbar.logsButton'
      iconLeft='faListUl'
      size='sm'
      color='none'
      onClick={() => togglePanel('log')}
    >
      {t('panelButton')}
    </Button>
  );
};
