import React, { PureComponent } from 'react';
import * as DiagramActions from '../../../actions/diagramAnalytics.actions';

import { getFilterFromLocation } from '../../../pipes/functions';
import moment from 'moment';
import CommonChartControl from './CommonChartControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, forEach, difference } from 'lodash';
import ChartComponent from './PieChart';
import { Scrollbars } from 'react-custom-scrollbars';
import { AnalyticsContext } from '@just-ai/analytic-front';
import { isReporterEnabled } from '../../../isAccessFunction';
import localize from '../../../localization';
import { getChartMonths } from '../../../reducers/projectReportFilter.reducer';

class MainDiagram extends PureComponent {
  static contextType = AnalyticsContext;

  state = {
    shouldUpdate: false,
  };

  componentWillMount() {
    const { location, withReporter } = this.props;
    const { projectShortName } = this.props.match.params;
    const { initDiagram } = this.props.actions;

    if (withReporter) return;

    let params = getFilterFromLocation(location.search);
    if (!!params.dateFrom) {
      params.dateFrom = moment(params.dateFrom).format('x');
    }
    if (!!params.dateTo) {
      params.dateTo = moment(params.dateTo).format('x');
    }

    initDiagram(projectShortName, params);
  }

  componentWillReceiveProps(nextProps) {
    const { location, filters } = this.props;
    const { withReporter } = nextProps;
    const newFilters = nextProps.filters;
    const { projectShortName } = this.props.match.params;
    const { initDiagram } = this.props.actions;

    if (!withReporter) {
      let params = getFilterFromLocation(location.search);
      let nextParams = getFilterFromLocation(nextProps.location.search);
      if (!isEqual(params, nextParams)) {
        if (!!nextParams.dateFrom) {
          nextParams.dateFrom = moment(nextParams.dateFrom).format('x');
        }
        if (!!nextParams.dateTo) {
          nextParams.dateTo = moment(nextParams.dateTo).format('x');
        }

        initDiagram(projectShortName, nextParams);
      }
    } else {
      if (filters !== newFilters) {
        this.context.getMessagesAndSessionStats(location, { filters: newFilters });
      }
    }
  }

  componentDidMount() {
    const { location, filters } = this.props;
    if (filters) {
      this.context.getMessagesAndSessionStats(location, { filters: filters });
    }
  }

  componentToggleSessionChartLabel = (name, flag) => {
    const toggleName = name === localize.translate('Sessions') ? 'sessionStats' : 'messagesStats';
    const setter = async () => {
      (await isReporterEnabled())
        ? this.context.toggleChartActivity(toggleName)
        : this.props.actions.toggleSessionChartLabels(name, flag);
      await this.setState({
        shouldUpdate: true,
      });
    };

    setter().then(() => {
      this.setState({
        shouldUpdate: false,
      });
    });
  };

  shouldComponentUpdate(nextProps) {
    const { disableOldFetching } = this.props;

    /* eslint-disable */
    if (!disableOldFetching) {
      return (
        (!nextProps.mainDiagramFetching && nextProps.mainDiagramFetching !== this.props.mainDiagramFetching) ||
        this.state.shouldUpdate ||
        !this.initialize ||
        difference(this.props.filters, nextProps.filters).length > 0
      );
    }
    /* eslint-disable */
  }

  render() {
    let { sessionsStatsGraphData, chartMonths } = this.props;
    const {
      analyticsChartData: { sessionData },
    } = this.context;
    //SESSION
    let __sessionsStatsGraphData = {
      labels: [],
      datasets: [],
    };

    if (isReporterEnabled()) {
      __sessionsStatsGraphData.labels = sessionData.labels;
      if (!chartMonths) chartMonths = getChartMonths(sessionData.ts);
      if (!sessionsStatsGraphData) sessionsStatsGraphData = {};
      sessionsStatsGraphData.datasets = this.context.prepareMessagesAndSessionsGraphData();
      __sessionsStatsGraphData.datasets = sessionsStatsGraphData.datasets.filter(dataset => dataset.active);
    } else {
      if (!!sessionsStatsGraphData) {
        __sessionsStatsGraphData = {
          labels: sessionsStatsGraphData.labels,
          datasets: [],
        };
        forEach(sessionsStatsGraphData.datasets, sessionsStatsGraphDataItem => {
          if (sessionsStatsGraphDataItem.active) {
            __sessionsStatsGraphData.datasets.push({
              ...sessionsStatsGraphDataItem,
            });
          }
        });
      }
    }

    return (
      <div className='mb-3'>
        <div className='row'>
          <div className='col-8 dialogs-session-data-chart'>
            <ChartComponent
              chartMonths={chartMonths}
              chartData={__sessionsStatsGraphData}
              config={{
                type: 'line',
                data: __sessionsStatsGraphData,
                options: {
                  legend: {
                    display: false,
                  },
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: 10,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontSize: 10,
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                  tooltips: {
                    enabled: false,
                    custom: this.props.customChart(),
                  },
                },
              }}
            />
          </div>
          <div className='col-2'>
            <Scrollbars
              renderThumbVertical={props => <div {...props} className='vertical-scroll_chart' />}
              renderThumbHorizontal={props => <div {...props} className='horizontal-scroll_chart' />}
              renderView={props => (
                <div
                  {...props}
                  style={{ overflowX: 'auto' }}
                  className='chartWrapper chartWrapper_common-controls chartWrapper_scrollable'
                />
              )}
            >
              <div className='form-inline'>
                {!!sessionsStatsGraphData && sessionsStatsGraphData.datasets.length > 0
                  ? sessionsStatsGraphData.datasets.map((item, index) => (
                      <CommonChartControl
                        key={`labelSessionColor${index}`}
                        name={item.label}
                        color={item.borderColor}
                        active={item.active}
                        toggleCommonChartLabels={this.componentToggleSessionChartLabel}
                      />
                    ))
                  : null}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mainDiagramFetching: state.DiagramAnalyticsReducer.mainDiagramFetching,

    sessionsStats: state.DiagramAnalyticsReducer.sessionsStats,
    sessionsStatsGraphData: state.DiagramAnalyticsReducer.sessionsStatsGraphData,
    logsStats: state.DiagramAnalyticsReducer.logsStats,
    logsStatsGraphData: state.DiagramAnalyticsReducer.logsStatsGraphData,

    chartMonths: state.DiagramAnalyticsReducer.chartMonths,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DiagramActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainDiagram));
