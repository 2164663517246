var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import Message from './Message';
import './MessagesList.scss';
import { AnalyticsContext } from '../../../context/AnalyticsContext';
import { labelsReducer } from '../MassAddingLabels';
import MassAddingLabels from '../MassAddingLabels/MassAddingLabels';
import { Checkbox } from '../../Checkbox';
function MessagesList({ messages, t, history, showAdditionalAction, showAnswers, showMarkup, isLoading, isSessionMessages, compactView, downloadReportComponent, page, questionIdForScroll, totalCount, filters, onMassLabelsAddingCallback, }) {
    var _a;
    const questionIdForScrollRef = useRef(null);
    const { projectShortName, labelsContext: { setLabelToMessages }, } = useContext(AnalyticsContext);
    const [labelsState, setLabelsDispatcher] = useReducer(labelsReducer, {
        key: 'SESSION_ID',
        options: [],
        comparisonType: 'none',
    });
    const [scrollToMessagePage, setScrollToMessagePage] = useState(true);
    const { options, comparisonType } = labelsState;
    const onSubmit = useCallback((labels) => __awaiter(this, void 0, void 0, function* () {
        yield setLabelToMessages({
            //@ts-ignore
            filters: { filters },
            labels: labels.map(x => x.id),
            //@ts-ignore
            comparisonType: labelsState.comparisonType === 'include' ? 'CONTAINS' : 'NOT_CONTAINS',
            sessionIds: labelsState.options,
        });
        setLabelsDispatcher({ type: 'reset' });
        onMassLabelsAddingCallback && onMassLabelsAddingCallback();
    }), [labelsState]);
    useEffect(() => {
        var _a;
        if (!isLoading && questionIdForScrollRef.current && scrollToMessagePage) {
            questionIdForScrollRef.current.style.background = '#F9FAFC';
            (_a = questionIdForScrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            setScrollToMessagePage(false);
        }
    }, [questionIdForScrollRef.current, isLoading]);
    const startTimeDialog = (_a = messages[0]) === null || _a === void 0 ? void 0 : _a.timestamp;
    return (_jsxs("div", { className: 'PhraseList_container', children: [comparisonType !== 'none' && !isSessionMessages && messages.length > 0 && (_jsx(MassAddingLabels, { labelsState: labelsState, setLabelsDispatcher: setLabelsDispatcher, t: t, totalCount: totalCount, onSubmit: onSubmit })), comparisonType === 'none' && !isSessionMessages && messages.length > 0 && (_jsxs("div", { className: 'PhraseList_massAddingCheckbox', children: [_jsx(Checkbox, { name: 'choose_all_sessions', onChange: () => setLabelsDispatcher({ type: 'exclude' }) }), _jsx("span", { children: t('Analytics: choose all labels') })] })), messages.length === 0 && !isLoading ? (_jsx("div", { style: { padding: 24, paddingBottom: 0 }, children: t('Analytics: No messages found') })) : (messages.map((item, key) => (_jsx(Message, Object.assign({ questionIdForScrollRef: item.questionId === questionIdForScroll ? questionIdForScrollRef : null, "data-test-id": 'phraseListItem', index: page !== undefined ? page * 20 + key + 1 : undefined, startTimeDialog: startTimeDialog }, item, { projectShortName: projectShortName, showAnswers: showAnswers, showAdditionalAction: showAdditionalAction, showMarkup: showMarkup, history: history, isSessionMessages: Boolean(isSessionMessages), downloadReportComponent: downloadReportComponent, compactView: compactView, onChooseForLabels: setLabelsDispatcher, isSelected: options.includes(item.questionId) === (comparisonType === 'exclude' ? false : true), t: t }), `PhraseListItem_${key}`))))] }));
}
export default MessagesList;
