import React, { FunctionComponent, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { IconButton, CustomRxTooltip } from '@just-ai/just-ui';
import isAccess from 'isAccessFunction';
import { useAppContext } from 'modules/Caila/components/AppContext';
import { ItemMenu } from 'components/Header/MultiLevelSelect/types';
import {
  getTrainOption,
  getPublishOption,
  getTestOption,
  getFlatCanSelectedOptions,
} from 'components/Header/TestPublishTrainWidget/actions';
import { useTestWidgetContext } from 'modules/TestWidget';
import DeploymentService from 'modules/Editor/service/DeploymentService';
import MultiLevelSelect from '../MultiLevelSelect';
import useTestWidgetBotId from './useTestWidgetBotId';
import localize, { t } from '../../../localization';
import './style.scss';
import { intentspageLocalization } from '../../../modules/Caila/localization/intentspage.loc';

localize.addTranslations(intentspageLocalization);

type Props = {
  startDeploy: (skipTests: boolean, runTestsInBackground: boolean) => Promise<void>;
};

const LOCAL_STORAGE_KEY = 'TEST_PUBLISH_TRAIN_SELECTED_OPTION';

export const TestPublishTrainWidget: FunctionComponent<Props> = ({ startDeploy }) => {
  const [selectedActionOption, setSelectedActionOption] = useState<ItemMenu['value']>();
  const { accountId, currentProject } = useAppContext();
  const botId = useTestWidgetBotId();

  const deploymentService = useMemo<DeploymentService | null>(() => {
    if (!accountId || !currentProject?.shortName) return null;
    return new DeploymentService(accountId, currentProject?.shortName);
  }, [accountId, currentProject]);

  const {
    showTestWidget,
    hideTestWidget,
    showNluWidget,
    hideNluWidget,
    shownWidgetUrl,
    isTestWidgetShown,
    isNluWidgetShown,
    trainNlu,
  } = useTestWidgetContext();
  const [isDeployDisabled, setDeployDisabled] = useState(false);

  const startDeployIfNotInProgress = useCallback(
    (skipTests: boolean = false, runTestsInBackground = false) => {
      if (isDeployDisabled) return Promise.reject();
      setDeployDisabled(true);
      return startDeploy(skipTests, runTestsInBackground).finally(() => setDeployDisabled(false));
    },
    [isDeployDisabled, startDeploy]
  );

  const actionOptions = useMemo(() => {
    if (!currentProject) return [];

    const actions: ItemMenu[] = [];

    if (currentProject.jaicfProject && currentProject.environment === 'external') {
      if (isAccess(['BOT_CONTENT_READ', 'nlu'])) actions.push(getTrainOption());
      return actions;
    }

    actions.push(getTestOption());
    if (isAccess(['BOT_CONTENT_READ', 'nlu'])) actions.push(getTrainOption());
    if (isAccess('BOT_DEPLOY_WRITE')) actions.push(getPublishOption());

    return actions;
  }, [currentProject]);

  useEffect(() => {
    if (actionOptions.length === 0) {
      setSelectedActionOption(undefined);
      return;
    }
    const savedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    const canSelectedOptions = getFlatCanSelectedOptions(actionOptions);

    if (savedValue) {
      const option = canSelectedOptions.find(option => option.value === savedValue);
      if (option) {
        setSelectedActionOption(savedValue ?? '');
        return;
      }
    }
    setSelectedActionOption(canSelectedOptions[0].value);
  }, [actionOptions]);

  const activeOptionsIsProcessing = useMemo<boolean>(() => {
    if (!selectedActionOption) return false;
    const mainPart = selectedActionOption?.split(':')[0];
    return (mainPart === 'test' && isTestWidgetShown) || (mainPart === 'train' && isNluWidgetShown);
  }, [isNluWidgetShown, isTestWidgetShown, selectedActionOption]);

  const isPreviousShownWidgetUrl = useRef(shownWidgetUrl);
  useEffect(() => {
    if (shownWidgetUrl && !isPreviousShownWidgetUrl.current) {
      if (botId && selectedActionOption === 'test:startAutoTestsInBg') {
        deploymentService?.runTests(botId);
      }
    }
    isPreviousShownWidgetUrl.current = shownWidgetUrl;
  }, [shownWidgetUrl, selectedActionOption, botId, deploymentService]);

  const selectActionOption = useCallback(
    (option: ItemMenu, hide: boolean = false) => {
      setSelectedActionOption(option.value);
      localStorage.setItem(LOCAL_STORAGE_KEY, option.value);

      if (activeOptionsIsProcessing && hide) {
        const mainPart = selectedActionOption?.split(':')[0];
        switch (mainPart) {
          case 'test':
            hideTestWidget();
            return;
          case 'train':
            hideNluWidget();
            return;
        }
        return;
      }

      switch (option.value) {
        case 'test:startAutoTests':
          showTestWidget();
          return;
        case 'test:doNotRunAutotests':
          showTestWidget(undefined, undefined, true);
          return;
        case 'test:startAutoTestsInBg':
          showTestWidget(undefined, undefined, true);
          return;
        case 'train':
          showNluWidget();
          return;
        case 'publish:startAutoTests':
          startDeployIfNotInProgress();
          return;
        case 'publish:doNotRunAutotests':
          startDeployIfNotInProgress(true);
          return;
        case 'publish:startAutoTestsInBg':
          startDeployIfNotInProgress(false, true);
          return;
      }
    },
    [
      activeOptionsIsProcessing,
      selectedActionOption,
      hideTestWidget,
      hideNluWidget,
      showTestWidget,
      showNluWidget,
      startDeployIfNotInProgress,
    ]
  );

  if (!currentProject || actionOptions.length === 0) return null;
  return (
    <div className='publish-widget'>
      <MultiLevelSelect
        activeItemCode={selectedActionOption}
        options={actionOptions}
        activeOptionsIsProcessing={activeOptionsIsProcessing}
        onSelect={selectActionOption}
        data-test-id='TestPublishTrainWidget.select'
      />
      {selectedActionOption === 'test' && isTestWidgetShown && (
        <IconButton
          name='faSync'
          flat
          onClick={() => showTestWidget(true)}
          data-test-id='TestPublishTrainWidget.test.restart'
        />
      )}
      {selectedActionOption === 'train' && isNluWidgetShown && (
        <>
          <IconButton
            id='TestPublishTrainWidget_train_restart'
            name='faSync'
            flat
            onClick={() => trainNlu()}
            data-test-id='TestPublishTrainWidget.train.restart'
          />
          <CustomRxTooltip target='#TestPublishTrainWidget_train_restart' placement='bottom' textAlign='center'>
            {t('TestPublishTrainWidget:trainNLU')}
          </CustomRxTooltip>
        </>
      )}
    </div>
  );
};
