import React, { FC } from 'react';
import { Text } from 'react-konva';
import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';

import { t } from 'localization';
import { useOnClickBlockDefault } from '../../hooks';
import { useRenderBlocksContext } from './RenderBlocks';

export interface ItemBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  text: string;
  maxHeight?: number;
}

export const AnswerBody: FC<ItemBodyProps> = ({ text, globalJBlockIterator, ...rest }) => {
  const { baseWidth, prefix, screen } = useRenderBlocksContext();
  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator || 0, prefix);
  return (
    <BaseItemBody
      {...rest}
      onClick={onClickDefault}
      renderChild={({ innerHeight = 1 }) => {
        return (
          <Text
            x={24}
            y={32}
            verticalAlign='top'
            key={`isTextShorten${innerHeight}`}
            text={text || t('AnswerBody:placeholder')}
            height={innerHeight * 21}
            width={baseWidth - 24 - 36 - 34}
            lineHeight={21 / 14}
            fontSize={14}
            letterSpacing={0.02}
            ellipsis={true}
            fill={text ? '#333C45' : '#888E93'}
            dataType='AnswerBody:text'
          />
        );
      }}
    />
  );
};
