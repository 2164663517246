import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type RadioPadsOption = {
  label: string | React.ReactFragment;
  value: string;
  disabled?: boolean;
};

interface RadioPadsInterface {
  name: string;
  options: RadioPadsOption[];
  onChange: (value: RadioPadsOption) => void;
  selectedOption: RadioPadsOption;
  padsDisabled?: boolean;
}
const RadioPads = function ({ selectedOption, onChange, options, name, padsDisabled }: RadioPadsInterface) {
  return (
    <div className={cn(styles.radioPads, 'radio-pads')}>
      {options.map(option => {
        const active = option.value === selectedOption?.value;
        return (
          <div
            key={option.value + option.label}
            className={cn(styles.pad, {
              [styles.active]: active,
              [styles.disabled]: option.disabled || padsDisabled,
            })}
            onClick={() => !option.disabled && !padsDisabled && onChange(option)}
            data-test-id={`Classifier.Option.${option.value}`}
          >
            {option.label}
            <input
              type='radio'
              name={name}
              value={option.value}
              onChange={() => onChange(option)}
              disabled={option.disabled || padsDisabled}
              checked={active}
            />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(RadioPads);
