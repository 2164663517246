import React, { useState, useRef, useEffect } from 'react';
import { clamp, throttle } from 'lodash';

interface HorizontalResizeNodeProps {
  width: number;
  maxWidth?: number;
  minWidth?: number;
  onWidthChange?: (width: number) => void;
}
const HorizontalResizeNode = ({
  maxWidth = Infinity,
  minWidth = 0,
  width,
  onWidthChange,
  ...props
}: HorizontalResizeNodeProps & React.HTMLAttributes<HTMLDivElement>) => {
  const [innerWidth, setInnerWidth] = useState(() => clamp(width, minWidth, maxWidth));
  const widthRef = useRef(innerWidth);
  const widthBeforeDrag = useRef(innerWidth);
  const onWidthChangeRef = useRef(onWidthChange);
  onWidthChangeRef.current = onWidthChange;

  useEffect(() => {
    if (width === widthRef.current) return;
    setInnerWidth(width);
    widthRef.current = width;
  }, [width]);

  const dragNode = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let isDragging = false;
    let startPosition = 0;
    const startDragHandler = (event: MouseEvent) => {
      if (event.target !== dragNode.current) return;
      isDragging = true;
      startPosition = event.clientX;
      widthBeforeDrag.current = widthRef.current;
    };
    const endDragHandler = () => {
      if (!isDragging) return;
      isDragging = false;
      startPosition = 0;
      onWidthChangeRef.current?.(widthRef.current);
    };
    const dragHandler = throttle((event: MouseEvent) => {
      if (!isDragging || !dragNode.current) return;
      const offset = event.clientX - startPosition;

      const newWidth = clamp(widthBeforeDrag.current + offset, minWidth, maxWidth);
      widthRef.current = newWidth;
      setInnerWidth(newWidth);
    }, 17);

    document.addEventListener('mousemove', dragHandler);
    document.addEventListener('mousedown', startDragHandler);
    document.addEventListener('mouseup', endDragHandler);
    return () => {
      document.removeEventListener('mousemove', dragHandler);
      document.removeEventListener('mousedown', startDragHandler);
      document.removeEventListener('mouseup', endDragHandler);
    };
  }, [maxWidth, minWidth]);

  return (
    <div {...props} style={{ ...(props.style || {}), position: 'relative', width: innerWidth }}>
      <div
        ref={dragNode}
        style={{
          position: 'absolute',
          right: -4,
          top: 0,
          bottom: 0,
          width: 8,
          cursor: 'ew-resize',
          zIndex: 1,
        }}
      />
      {props.children}
    </div>
  );
};

export default HorizontalResizeNode;
