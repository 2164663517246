import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames';
import './style.scss';
import { Spinner } from '@just-ai/just-ui';
import {
  addWidgetListener,
  loadWidget,
  removeWidgetFromDom,
  setTestMode,
  timeoutPromise,
} from 'modules/TestWidget/utils';
import { useAppContext } from 'modules/Caila/components/AppContext';
import { useTestWidgetContext } from 'modules/TestWidget/context/TestWidgetContext';
import { getVoiceWidgetUrl, getWidgetUrl } from 'modules/TestWidget/service/TestWidgetService';
import { NotificationsListErrorModalLogData$ } from 'modules/Notifications';
require('dom-element-watcher');

export const TestWidget: FunctionComponent = React.memo(() => {
  const abortController = useRef<AbortController>();
  const showTestWidget = useRef<(abortController: AbortController) => Promise<void>>();
  const { projectShortName } = useAppContext();
  const { requestedWidget, onWidgetLoad, onWidgetError, hideTestWidget, isTestWidgetShown } = useTestWidgetContext();
  const widgetToggleListener = useCallback(
    //@ts-ignore
    ({ detail }: Event) => {
      if (detail) return;
      return hideTestWidget();
    },
    [hideTestWidget]
  );

  const { isLoads, testWidgetType } = useTestWidgetContext();

  const bindErrorHandler = useCallback(() => {
    (window as any).JustWidget.showErrorOverlay = (error: string) => NotificationsListErrorModalLogData$.next(error);
  }, []);

  useEffect(() => {
    document.addEventListener('justwidget_mounted', bindErrorHandler);
    document.addEventListener('justwidget_toggle', widgetToggleListener);
    return () => {
      document.removeEventListener('justwidget_mounted', bindErrorHandler);
      document.removeEventListener('justwidget_toggle', widgetToggleListener);
    };
  }, [bindErrorHandler, widgetToggleListener]);

  showTestWidget.current = async (abortController: AbortController) => {
    if (projectShortName === '') return;
    try {
      const url = await (testWidgetType === 'textWidget'
        ? requestedWidget?.url ||
          getWidgetUrl(projectShortName, requestedWidget?.forceDeploy, requestedWidget?.skipTests)
        : getVoiceWidgetUrl(projectShortName, requestedWidget?.skipTests));
      if (abortController.signal.aborted) return;

      await timeoutPromise(3000, new Error('Chatwidget load timeout error!'), loadWidget(url));
      if (abortController.signal.aborted) return;

      setTestMode();
      addWidgetListener();

      onWidgetLoad(url);
    } catch (error) {
      onWidgetError(requestedWidget?.url, error);
    }
  };

  useEffect(() => {
    if (!isTestWidgetShown) return;
    abortController.current = new AbortController();
    showTestWidget.current?.(abortController.current);
  }, [isTestWidgetShown]);

  useEffect(() => {
    return () => {
      removeWidgetFromDom();
      abortController.current?.abort();
    };
  }, []);

  return (
    <>
      <div
        id='WidgetMountPlace'
        className={classNames('global-test-widget', 'test-widget')}
        style={{ backgroundColor: 'white' }}
      />
      {isLoads && <Spinner size='4x' backgroundColor='white' />}
    </>
  );
});
TestWidget.displayName = 'Memo(TestWidget)';
