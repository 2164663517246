import React, { FC, SyntheticEvent } from 'react';
import classes from './JGToolbar.module.scss';
import cn from 'classnames';
import { Button, CustomRxTooltip, IconButton } from '@just-ai/just-ui';
import { UncontrolledTooltipProps } from 'reactstrap/lib/Tooltip';
import { IconButtonProps } from '@just-ai/just-ui';

type JGToolbarProps = { type: 'vertical' | 'horizontal'; top?: number; left?: number };

export const JGToolbar: FC<JGToolbarProps> = React.memo(({ children, type }) => {
  return <div className={cn(classes.container, classes[type])}>{children}</div>;
});
JGToolbar.displayName = 'JGToolbar';

type JGToolbarIconButtonProps = {
  onClick?: (event: SyntheticEvent) => unknown;
  onMouseDown?: (event: SyntheticEvent) => unknown;
  id?: string;
  text?: string;
  tooltip?: string;
  placement?: UncontrolledTooltipProps['placement'];
  iconName?: IconButtonProps['name'];
  'data-test-id': string;
  className?: string;
};

export const JGToolbarIconButton = React.forwardRef(
  ({ onClick, id, tooltip, iconName, placement, className, onMouseDown, ...rest }: JGToolbarIconButtonProps, ref) => {
    if (!iconName) return null;

    return (
      <>
        <IconButton
          className={className}
          name={iconName}
          flat
          onClick={onClick}
          onMouseDown={onMouseDown}
          data-test-id={rest['data-test-id']}
          id={`${id}`}
          ref={ref}
        />
        {tooltip && (
          <CustomRxTooltip target={`#${id}`} placement={placement}>
            {tooltip}
          </CustomRxTooltip>
        )}
      </>
    );
  }
);

type JGToolbarButtonProps = {
  onClick?: (event: SyntheticEvent) => unknown;
  id?: string;
  text?: string;
  tooltip?: string;
  placement?: UncontrolledTooltipProps['placement'];
  iconName?: IconButtonProps['name'];
  'data-test-id': string;
  className?: string;
};
export const JGToolbarButton = React.forwardRef(
  ({ onClick, id, tooltip, iconName, placement, className, text, ...rest }: JGToolbarButtonProps, ref) => {
    if (!iconName && !text) return null;

    return (
      <>
        <Button
          className={className}
          iconLeft={iconName}
          flat
          onClick={onClick}
          data-test-id={rest['data-test-id']}
          id={`${id}`}
          ref={ref}
        >
          {text}
        </Button>
        {tooltip && (
          <CustomRxTooltip target={`#${id}`} placement={placement}>
            {tooltip}
          </CustomRxTooltip>
        )}
      </>
    );
  }
);
