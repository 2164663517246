import React, { FunctionComponent, Fragment } from 'react';
import { Icon, Button } from '@just-ai/just-ui';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { NotificationData, DateNotifications, getTypeByNotification } from '../../utils/notificaions';
import localize from '../../../../localization/index';
import { Notification } from './Notification';
import { getDateStr } from '../../utils';

export interface NotificationsAreaProps {
  activeNotifications: DateNotifications;
  deleteNotification: (index?: number, id?: string) => void;
  markHovered: (index?: number) => (e: React.MouseEvent) => void;
  setCopyData: (data: any) => void;
  openModal: () => void;
  copyLogs: () => void;
}
export const NotificationsArea: FunctionComponent<NotificationsAreaProps> = ({
  activeNotifications,
  deleteNotification,
  markHovered,
  setCopyData,
  openModal,
  copyLogs,
}) => (
  <div className='notifications__scroll-area'>
    <PerfectScrollbar>
      {Object.keys(activeNotifications).length ? (
        Object.keys(activeNotifications)
          .reverse()
          .map((key: string) => (
            <Fragment key={`notifications__date-${key}`}>
              <div className='notifications__date'>
                <span>{getDateStr(key)}</span>
              </div>
              {activeNotifications[key].map((notification: NotificationData, i: number) =>
                !notification.seen ? (
                  <Notification
                    key={`notification_${i}`}
                    type={getTypeByNotification(notification)}
                    id={notification.index}
                    onDelete={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      deleteNotification(notification.index, notification.id);
                    }}
                    hovered={notification.hovered}
                    onClick={markHovered(notification.index)}
                    onMouseLeave={markHovered(notification.index)}
                  >
                    <h6>
                      {notification.message?.code
                        ? localize.translate(`notifications ${notification.message.code.code}`)
                        : null}
                    </h6>
                    <p className='notification__time'>
                      {moment(notification.timestamp).format('HH:mm')}
                      {' — '} {notification.projectId}
                    </p>
                    <div>
                      {notification.message?.data?.output ? (
                        <p className='notification__message'>{notification.message.data.output}</p>
                      ) : (
                        notification.message?.data?.botId && (
                          <p className='notification__message'>{notification.message?.data?.botId}</p>
                        )
                      )}
                    </div>
                    {(getTypeByNotification(notification) === 'error' ||
                      getTypeByNotification(notification) === 'warn') &&
                    notification.message?.data?.output ? (
                      <div className='notification__buttons'>
                        <Button
                          color={getTypeByNotification(notification) === 'error' ? 'danger' : 'warning'}
                          size='sm'
                          outline
                          onClick={() => {
                            setCopyData(
                              notification.message?.data?.output
                                ? notification.message?.data?.output
                                : notification.message?.data
                            );
                            openModal();
                          }}
                          data-test-id='Notification.ViewLog'
                        >
                          {localize.translate('notifications view log')}
                        </Button>
                        <Button
                          color={getTypeByNotification(notification) === 'error' ? 'danger' : 'warning'}
                          size='sm'
                          flat
                          onClick={() => {
                            setCopyData(
                              notification.message?.data?.output
                                ? notification.message?.data?.output
                                : notification.message?.data
                            );
                            copyLogs();
                          }}
                          data-test-id='Notification.Copy'
                        >
                          {localize.translate('notifications copy button')}
                        </Button>
                      </div>
                    ) : null}
                  </Notification>
                ) : null
              )}
            </Fragment>
          ))
      ) : (
        <div className='notifications__empty'>
          <span>{localize.translate('notifications empty list')}</span>
          <Icon name='faInbox' size='6x' />
        </div>
      )}
    </PerfectScrollbar>
  </div>
);
