import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { StickerInfo } from 'modules/JGraph/view/Sticker/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { deleteSticker as deleteStickerInState, loadStickers } from '../index';
import { jGraphStickersApi } from '../utils';

const deleteSticker = createAsyncThunk('JGraph/deleteSticker', async (stickerId: string, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const existedSticker = state.JGraphReducer.stickers.find(el => el.id === stickerId);
  if (!existedSticker) return;

  thunkAPI.dispatch(deleteStickerInState(existedSticker));

  mainSave$.next({
    type: 'deleteSticker',
    path: '',
    action: async () => {
      const { data: response } = await jGraphStickersApi.deleteJGraphSticker(
        userAccountId || accountId,
        projectShortName,
        stickerId
      );
      thunkAPI.dispatch(loadStickers(response));
    },
  });

  return {
    deletedSticker: existedSticker,
  };
});

export const revertDeleteSticker = createAsyncThunk(
  'JGraph/revertDeleteSticker',
  async (revertEvent: RevertEvent<string, { deletedSticker: StickerInfo }>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    mainSave$.next({
      type: 'revertDeleteSticker',
      path: '',
      action: async () => {
        const { data: createResponse } = await jGraphStickersApi.upsertJGraphSticker(
          userAccountId || accountId,
          projectShortName,
          revertEvent.result.deletedSticker.id,
          {
            version: state.JGraphReducer.stickersVersion.version,
            lastModified: state.JGraphReducer.stickersVersion.lastModified,
            sticker: revertEvent.result.deletedSticker,
          }
        );
        thunkAPI.dispatch(loadStickers(createResponse));
      },
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertDeleteSticker, deleteSticker);
