import { useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
export default function useControlledForm({ defaultValues, onChange, validationResolver, }) {
    const [state, setState] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [isTouched, setTouched] = useState(false);
    const resetTo = useCallback((data) => {
        setErrors({});
        setTouched(false);
        setState(data);
    }, []);
    const onChangeInner = useCallback((value, name) => {
        if (!name)
            return;
        setTouched(true);
        setState(state => {
            const newState = Object.assign(Object.assign({}, state), { [name]: value });
            const errors = validationResolver(newState);
            setErrors(errors);
            onChange({
                errors,
                isValid: isEmpty(errors),
                data: newState,
            });
            return newState;
        });
    }, [onChange, validationResolver]);
    return { state, onChange: onChangeInner, errors, isTouched, isValid: isEmpty(errors), resetTo };
}
