import { loadStripe } from '@stripe/stripe-js/pure';
import { isInternal } from '../isAccessFunction';
import history from 'appHistory';

export const redirectToPaymentSystem = (data, currentUser) => {
  if (Boolean(data.paymentId) && currentUser && !isInternal()) {
    const localStorageObj = {
      accountId: currentUser.account.id,
      paymentId: data.paymentId,
    };

    localStorage.setItem(`${currentUser.account.id}_PAYMENT_DATA`, JSON.stringify(localStorageObj));
    let event = new Event('paymentWaiting');
    window.dispatchEvent(event);
  }
  if (data.redirectUrl && data.paymentSystem === 'YANDEX_CASH') {
    window.location.href = data.redirectUrl;
  } else if (data.paymentSystem === 'STRIPE' && data.stripePublicKey && data.stripeSessionId) {
    let stripe = loadStripe(data.stripePublicKey);
    stripe.then(payload => {
      payload && payload.redirectToCheckout({ sessionId: data.stripeSessionId });
    });
  } else {
    history.push('/accountmanager');
  }
};
