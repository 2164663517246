import React, { Component } from 'react';
import localize from '../../../localization';
import history from '../../../appHistory';
import Robot503 from './Robot503';
import { Button } from 'reactstrap';

class Index extends Component {
  render() {
    return (
      <div className='container container404'>
        <div className='row justify-content-center '>
          <div className='col-md-12'>
            <div className='clearfix'>
              <Robot503 />
              <h1>{localize.translate('Technical difficulties')}</h1>
              <p>{localize.translate('We work')}</p>
              <Button
                type='submit'
                color='primary'
                size='md'
                block
                className='btn-primary'
                onClick={() => history.push('/')}
              >
                {localize.translate('To project page')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
