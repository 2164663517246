var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { SwitchButton, InputText, Icon, Tooltip, InputGroup, RichTextEditor, useTranslation, withTranslationContext, } from '@just-ai/just-ui';
import cn from 'classnames';
import { debounce } from 'lodash';
import { getErrorMessageFromReason, Intents } from '../../../../nlu-related/utils';
import { answerPartsToReplies } from '../../../../utils';
import { useNLUModuleContext } from '../../../../context';
import { convertToIntentPhrases } from '../PhrasesBlock/utils';
import PhrasesBlock from '../PhrasesBlock';
import useControlledForm from './useControlledForm';
import ValidationErrorPanel from './components/ValidationErrorPanel';
import styles from './styles.module.scss';
import './styles.scss';
const validationResolver = (values) => {
    const errors = {};
    const name = values.name.replaceAll(/\s/g, '');
    if (!name || name.includes('/'))
        errors['name'] = true;
    return errors;
};
const IntentFaqEditView = function ({ onUpdate, error, question, savingReplies, writeDisabled, validationError, handleLinkToAnswer, }) {
    const { t } = useTranslation();
    const { isWhiteLabel, saveAudioToServer, saveFileToServer, validateFile } = useNLUModuleContext();
    const [markupMode, setMarkupMode] = useState(() => {
        const repliesMarkups = question.replies.map(reply => reply.markup);
        if (repliesMarkups.includes('html')) {
            return true;
        }
        return false;
    });
    const [savingVisible, setSavingVisible] = useState(false);
    const savingTimeoutRef = useRef();
    const AnswerBlockWithTranslation = useMemo(() => withTranslationContext(RichTextEditor), []);
    useEffect(() => {
        if (savingReplies) {
            return setSavingVisible(true);
        }
        if (savingVisible && !savingReplies) {
            savingTimeoutRef.current = setTimeout(() => {
                setSavingVisible(false);
            }, 5000);
        }
        return () => {
            savingTimeoutRef.current && clearTimeout(savingTimeoutRef.current);
        };
    }, [savingReplies, savingVisible]);
    useEffect(() => {
        const repliesMarkups = question.replies.map(reply => reply.markup);
        if (repliesMarkups.includes('html')) {
            setMarkupMode(true);
        }
    }, [question.replies]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnUpdate = useCallback(debounce(onUpdate, 300), []);
    let intent = question.intent;
    const { state, errors, onChange } = useControlledForm({
        defaultValues: {
            enabled: !!intent.enabled,
            answer: intent.answer || '',
            name: Intents.getNameFromPath(intent.path),
            items: convertToIntentPhrases(intent),
        },
        validationResolver,
        onChange: useCallback(({ data, isValid }) => {
            if (!isValid)
                return;
            let newIntent = Object.assign(Object.assign({}, intent), { enabled: data.enabled, path: `${Intents.getParentPathFromPath(intent.path)}/${data.name}` });
            newIntent = Intents.updateItems(newIntent, data.items);
            const newQuestion = {
                id: question.id,
                intent: newIntent,
                replies: question.replies,
            };
            debouncedOnUpdate(newQuestion);
        }, [debouncedOnUpdate, intent, question.id, question.replies]),
    });
    const onRepliesUpdate = useCallback((newReplies, signal, softValidate) => __awaiter(this, void 0, void 0, function* () {
        const formattedReplies = answerPartsToReplies(newReplies);
        const newQuestion = {
            id: question.id,
            intent: question.intent,
            replies: formattedReplies,
        };
        onUpdate(newQuestion, true, softValidate, signal);
    }), [onUpdate, question.id, question.intent]);
    const answersValError = validationError === null || validationError === void 0 ? void 0 : validationError.answers;
    const phrasesValError = validationError === null || validationError === void 0 ? void 0 : validationError.phrases;
    return (_jsxs("div", { className: cn('intentFaqEditView', styles.intentFaqEditView), children: [_jsxs("div", { className: styles.panel, children: [_jsxs("div", { className: styles.panel__header, children: [_jsx("h3", { className: styles.label, children: t('FAQ:Intent:Field:Question') }), !isWhiteLabel() && (_jsxs(_Fragment, { children: [_jsx(Icon, { name: 'faQuestionCircle', id: 'questionTooltip', color: 'secondary' }), _jsx(Tooltip, { autohide: false, className: 'tooltip--dark faq-answer__tooltip faq-answer__tooltip--top-fix', placement: 'top', target: 'questionTooltip', flip: false, offset: 20, children: _jsx("p", { className: cn({ 'links-disabled': isWhiteLabel() }), dangerouslySetInnerHTML: {
                                                __html: t('AnswerBlocks:question_tooltip', t('AnswerBlocks:question_tooltip_link')),
                                            } }) })] })), _jsx(SwitchButton, { name: 'enabled', id: 'switch-intent-header', label: '', onLabel: t('Intent:switchActivityLabelEnable'), offLabel: t('Intent:switchActivityLabelDisable'), labelPosition: 'left', onChange: onChange, value: state.enabled, "data-test-id": 'FAQPage.IntentEdit.EnabledSwitch', disabled: writeDisabled })] }), _jsxs("div", { children: [_jsx(InputGroup, { className: 'faq-edit__question-input', AppendInner: !!phrasesValError && phrasesValError.length
                                    ? [{ name: 'faExclamationCircle', color: 'danger' }]
                                    : undefined, children: _jsx(InputText, { name: 'name', value: state.name, onChange: onChange, placeholder: t('FAQPage:Field:Question:Placeholder'), invalid: !!errors.name || !!error, "data-test-id": 'FAQPage.IntentEdit.Question', autoFocus: true, readOnly: writeDisabled }) }), error && (_jsx("div", { className: 'error font-size-12', "data-test-id": 'FAQPage.IntentEdit.Error', children: error }))] }), _jsx(PhrasesBlock, { name: 'items', intentName: state.name, items: state.items, onChange: onChange, writeDisabled: writeDisabled, valErrors: phrasesValError }), !!phrasesValError && !!phrasesValError.length && (_jsx(ValidationErrorPanel, { updateWithoutValidation: () => onUpdate(question, false, false), validationErrorData: phrasesValError, type: 'questions', usageArea: 'FAQ', handleLinkToAnswer: handleLinkToAnswer }))] }), _jsxs("div", { className: styles.panel, children: [_jsxs("div", { className: cn(styles.panel__header, styles['panel__header--answers']), children: [_jsx("h3", { className: styles.label, children: t('FAQ:Intent:Field:Answer') }), !isWhiteLabel() && (_jsxs(_Fragment, { children: [_jsx(Icon, { name: 'faQuestionCircle', color: 'secondary', id: 'answerTooltip' }), _jsx(Tooltip, { autohide: false, className: 'tooltip--dark faq-answer__tooltip', placement: 'top', target: 'answerTooltip', flip: false, offset: 20, children: _jsx("p", { dangerouslySetInnerHTML: {
                                                __html: t('AnswerBlocks:answer_tooltip', t('AnswerBlocks:answer_tooltip_link')),
                                            } }) })] })), savingVisible && (_jsxs("div", { className: 'faq-answers__saving-indicator', children: [_jsx(Icon, { size: 'sm', name: !!answersValError && !!answersValError.length ? 'falTimes' : savingReplies ? 'farSync' : 'farCheck', color: 'secondary' }), t(!!answersValError && !!answersValError.length
                                        ? 'IntentEdit:titleNotSaved'
                                        : savingReplies
                                            ? 'AnswerBlocks:saving'
                                            : 'IntentEdit:titleSaved')] })), _jsxs("div", { className: styles.panel__switch, children: [_jsx("p", { children: t('AnswerSettings:HtmlEnabler') }), _jsx(SwitchButton, { id: 'markupSwitch', value: markupMode, onChange: setMarkupMode, disabled: writeDisabled, "data-test-id": 'FAQPage.AnswerBlock.MarkupSwitch' })] })] }), _jsx(AnswerBlockWithTranslation, { replies: question.replies, onUpdate: onRepliesUpdate, markupMode: markupMode, writeDisabled: writeDisabled, MAX_ANSWERS_COUNT: 20, INITIAL_REPLIES: [{ type: 'text', markup: 'plain', text: '' }], isWhiteLabel: isWhiteLabel, getErrorMessageFromReason: getErrorMessageFromReason, saveAudioToServer: saveAudioToServer, saveFileToServer: saveFileToServer, validateFile: validateFile, ValErrorComponent: answersValError && answersValError.length
                            ? ({ updateWithoutValidation }) => {
                                return (_jsx("div", { className: 'faq-answer__validation-err', children: _jsx(ValidationErrorPanel, { updateWithoutValidation: updateWithoutValidation, validationErrorData: answersValError, type: 'answers', usageArea: 'FAQ', handleLinkToAnswer: handleLinkToAnswer }) }));
                            }
                            : undefined })] })] }));
};
export default React.memo(IntentFaqEditView);
