import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { getStageContextFromRef, getStageFromRef, setCursorOnMouseEnterAndLeave } from '../../utils/stageUtils';
import {
  allBlocksSetted,
  applyConnectorOffsets,
  getConnectorName,
  getConnectorPath,
} from '../../utils/connectionLayerUtils';
import { rafAppScheduler } from '../../../../utils/sheduler/buildScheduler';
import { KonvaEventObject } from 'konva/lib/Node';
import { highLightConnectors$, useConnectorHighLight } from '../../hooks/highLightConnectors';
import { FloatingConnectorMenu$ } from '../FloatingConnectorMenu';
import { Path } from 'react-konva';
import { debugActiveColor } from '../../colors';
import { ConnectorProps } from '.';

export const SplineConnector: FC<ConnectorProps> = ({ connector }) => {
  const { yellowActive, blueActive, grayscaled, setBlueActive } = useConnectorHighLight(connector);
  const { from, to, deferred, fromNode, debugActive } = connector;

  const PathRef = useRef<Konva.Path | null>(null);
  const wasActiveBlue = useRef(false);
  const [statePath, setStatePath] = useState<string>('');
  const connectorPathPositions = useRef<string>('');

  const fromNodeRef = useRef<Konva.Node | null | undefined>(null);
  const toNodeRef = useRef<Konva.Node | null | undefined>(null);

  useEffect(() => {
    const { connectorsFromStore$, GStage } = getStageContextFromRef(PathRef);

    const sub = connectorsFromStore$.subscribe(store => {
      const stage = GStage;
      if (allBlocksSetted(store, from, to, fromNode) && stage) {
        fromNodeRef.current = store[from].fromRef || store[fromNode].fromRefFallBack;
        toNodeRef.current = store[to!].toRef;

        const isToNodeScreen = !!toNodeRef.current?.attrs.isScreen;
        //should calculate after stage redraw
        setTimeout(() => {
          rafAppScheduler(() => {
            let fromPosition = fromNodeRef.current!.getAbsolutePosition(stage);
            let toPosition = toNodeRef.current!.getAbsolutePosition(stage);
            const stringPositionsValue = JSON.stringify({ from: fromPosition, to: toPosition });

            if (!connectorPathPositions.current || connectorPathPositions.current !== stringPositionsValue) {
              connectorPathPositions.current = stringPositionsValue;

              const { fromPosition: newFromPos, toPosition: newToPos } = applyConnectorOffsets(
                fromPosition,
                toPosition,
                {
                  isToNodeScreen,
                }
              );
              setStatePath(getConnectorPath(newFromPos, newToPos, deferred));
            } else {
              setStatePath(prevState => prevState);
            }
          });
        }, 0);
      } else {
        connectorPathPositions.current = '';
        setStatePath('');
      }
    });
    return () => sub.unsubscribe();
  }, [deferred, from, fromNode, to]);

  const calculatedPath = useCallback(() => {
    const stage = getStageFromRef(PathRef);
    const { connectorsFromStore$ } = getStageContextFromRef(PathRef);
    if (stage && from && to) {
      if (!fromNodeRef.current || !toNodeRef.current) {
        const sub = connectorsFromStore$.subscribe(store => {
          if (allBlocksSetted(store, from, to, fromNode)) {
            fromNodeRef.current = store[from].fromRef;
            toNodeRef.current = store[to!].toRef;
          }
        });
        sub.unsubscribe();
      }
      if (fromNodeRef.current && toNodeRef.current) {
        const { fromPosition, toPosition } = applyConnectorOffsets(
          fromNodeRef.current.getAbsolutePosition(stage),
          toNodeRef.current.getAbsolutePosition(stage),
          {
            isToNodeScreen: !!toNodeRef.current?.attrs.isScreen,
          }
        );

        return getConnectorPath(fromPosition, toPosition, deferred);
      }
    }
    return '';
  }, [deferred, from, fromNode, to]);

  const onMouseEnter = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      setCursorOnMouseEnterAndLeave(() =>
        setBlueActive(prevBlueActive => {
          wasActiveBlue.current = prevBlueActive;
          return true;
        })
      )(event);
    },
    [setBlueActive]
  );
  const onMouseLeave = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      setCursorOnMouseEnterAndLeave(() => setBlueActive(wasActiveBlue.current))(event);
    },
    [setBlueActive]
  );

  const onClickHandler = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      highLightConnectors$.next({ ...highLightConnectors$.getValue(), connector });
      FloatingConnectorMenu$.next({ event, connector });
    },
    [connector]
  );

  return (
    <Path
      ref={PathRef}
      data={statePath}
      calculatePath={calculatedPath}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClickHandler}
      // data='M10 280 h 50 q 11 0 11 -11 v -100 q 0 -11 11 -11 h 30'
      stroke={
        yellowActive
          ? '#5492CD' //designer choice
          : grayscaled
          ? '#333C45'
          : blueActive
          ? '#5492CD'
          : debugActive
          ? debugActiveColor
          : '#A6ABAF'
      }
      strokeWidth={2}
      hitStrokeWidth={10}
      dash={deferred ? [3, 3] : [0, 0]}
      name={getConnectorName(connector)}
      from={fromNode}
      to={to}
      connector={connector}
      opacity={grayscaled ? 0.16 : 1}
    />
  );
};
