import {
  DIAGRAM_INIT_PENDING,
  DIAGRAM_INIT_REJECTED,
  DIAGRAM_INIT_FULFILLED,
  TOGGLE_SESSION_AND_LABEL_CHART_LABELS,
  DIAGRAM_SESSION_FULFILLED,
  DIAGRAM_DIALOGS_FULFILLED,
  DIAGRAM_SESSION_PENDING,
  DIAGRAM_DIALOGS_PENDING,
  DIAGRAM_SESSION_REJECTED,
  DIAGRAM_DIALOGS_REJECTED,
} from '../../constants/diagramAnalytics.actions';
import moment from 'moment';
import localize from '../../localization';

const InitialState = {
  mainDiagramFetching: false,
  sessionFetching: false,
  phraseFetching: false,

  errors: [],
  sessionsStats: [],
  logsStats: [],
  sessionsStatsGraphData: null,
  startMonthDate: null,
  startMonthName: null,
  endMonthDate: '',
  endMonthName: '',
  chartMonths: null,
  labelCountData: null,
  labelTimeData: null,

  phraseCount: null,
  phraseTime: null,
};

export default function DiagramAnalyticsReducer(state = InitialState, action) {
  switch (action.type) {
    case DIAGRAM_DIALOGS_PENDING: {
      return {
        ...state,
        phraseFetching: true,
      };
    }
    case DIAGRAM_SESSION_PENDING: {
      return {
        ...state,
        sessionFetching: true,
      };
    }
    case DIAGRAM_INIT_PENDING: {
      return {
        ...state,
        mainDiagramFetching: true,
      };
    }

    case DIAGRAM_DIALOGS_REJECTED: {
      return {
        ...state,
        phraseFetching: false,
        errors: action.payload.config.response.data.errors,
      };
    }

    case DIAGRAM_SESSION_REJECTED: {
      return {
        ...state,
        sessionFetching: false,
        errors: action.payload.config.response?.data?.errors,
      };
    }
    case DIAGRAM_INIT_REJECTED: {
      return {
        ...state,
        mainDiagramFetching: false,
        errors: action.payload.config.response.data.errors,
      };
    }
    case DIAGRAM_INIT_FULFILLED: {
      let data = action.payload.data;
      data.sessionsStats.sessionLabels = data.sessionsStats.dates.map(dateMilliseconds => {
        return new Date(dateMilliseconds).getDate();
      });
      //todo get current locale
      const locale = localStorage.getItem('USER_LANGUAGE') || 'ru';

      let startMonthDate = new Date(data.sessionsStats.dates[0]);
      let startMonthName = startMonthDate.toLocaleString(locale, { month: 'long' });
      let endMonthDate = new Date(data.sessionsStats.dates[data.sessionsStats.dates.length - 1]);
      let endMonthName = endMonthDate.toLocaleString(locale, { month: 'long' });

      const sessionsStats = {
        labels: data.sessionsStats.sessionLabels,
        datasets: [
          {
            label: localize.translate('Sessions'),
            data: data.sessionsStats.values,
            fill: false,
            borderColor: 'rgb(255, 127, 80)',
            lineTension: 0.1,
            active: true,
          },
          {
            label: localize.translate('Messages'),
            data: data.logsStats.values,
            fill: false,
            borderColor: 'rgb(255, 206, 86)',
            lineTension: 0.1,
            active: true,
          },
        ],
      };

      return {
        ...state,
        mainDiagramFetching: false,
        sessionsStats: data.sessionsStats,
        sessionsStatsGraphData: sessionsStats,
        logsStats: data.logsStats,
        startMonthDate: startMonthDate,
        startMonthName: startMonthName,
        endMonthDate: endMonthDate,
        endMonthName: endMonthName,
        chartMonths: { startMonthName, endMonthName },
      };
    }

    case DIAGRAM_SESSION_FULFILLED: {
      const data = action.payload.stats || action.payload.data || action.payload;
      const labelCount = {
        labels: (data.countDistributions || data).map(item => item.name || item.label.name),
        datasets: [
          {
            data: (data.countDistributions || data).map(item => {
              return item.count || item.totalCount;
            }),
            backgroundColor: [],
          },
        ],
      };

      //TIME
      let labels = [];
      let datasets = [];
      if (data.timeDistribution) {
        labels = data.timeDistribution.labels.map(strDate => {
          let date = moment(strDate);
          return date.format('DD.MM');
        });
        datasets = data.timeDistribution?.datasets;
      } else {
        labels = data[0] ? data[0].tsToValues.map(timeAndValue => moment(timeAndValue.ts).format('DD.MM')) : [];
        datasets = data.map(dataItem => {
          return {
            label: dataItem.label.name,
            data: dataItem.tsToValues.map(tsToValue => tsToValue.value),
          };
        });
      }

      return {
        ...state,
        sessionFetching: false,
        labelCountData: labelCount,
        labelTimeData: {
          labels: labels,
          datasets: datasets,
        },
      };
    }

    case DIAGRAM_DIALOGS_FULFILLED: {
      const data = action.payload.stats || action.payload.data || action.payload;

      //COUNT
      const labelPhraseCount = {
        labels: (data.countDistributions || data).map(item => item.name || item.label.name),
        datasets: [
          {
            data: (data.countDistributions || data).map(item => {
              return item.count || item.totalCount;
            }),
            backgroundColor: [],
          },
        ],
      };

      //TIME
      let labelsPhraseTime = [];
      let datasets = [];
      if (data.timeDistribution) {
        labelsPhraseTime = data.timeDistribution.labels.map(strDate => {
          let date = moment(strDate);
          return date.format('DD.MM');
        });
        datasets = data.timeDistribution?.datasets;
      } else {
        labelsPhraseTime = (data[0]?.tsToValues || []).map(timeAndValue => moment(timeAndValue.ts).format('DD.MM'));
        datasets = data.map(dataItem => {
          return {
            label: dataItem.label.name,
            data: dataItem.tsToValues.map(tsToValue => tsToValue.value),
          };
        });
      }

      return {
        ...state,
        phraseFetching: false,
        phraseCount: labelPhraseCount,
        phraseTime: {
          labels: labelsPhraseTime,
          datasets: datasets,
        },
      };
    }

    case TOGGLE_SESSION_AND_LABEL_CHART_LABELS: {
      let newSessionsStatsGraphData = { ...state.sessionsStatsGraphData };
      const index = newSessionsStatsGraphData.datasets.findIndex(item => {
        return item.label === action.name;
      });
      if (index > -1) {
        newSessionsStatsGraphData.datasets[index] = {
          ...newSessionsStatsGraphData.datasets[index],
          active: action.flag,
        };
      }
      return {
        ...state,
        sessionsStatsGraphData: newSessionsStatsGraphData,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
