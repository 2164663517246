import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle, useTranslation } from '@just-ai/just-ui';
import { TreeStructure } from '../Tree/TreeStructure';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import classes from './styles.module.scss';
const MAX_HEIGHT = 300;
export var ContextMenuActions;
(function (ContextMenuActions) {
    ContextMenuActions["TurnOff"] = "turnOff";
    ContextMenuActions["TurnOn"] = "turnOn";
    ContextMenuActions["Copy"] = "copy";
    ContextMenuActions["Group"] = "group";
    ContextMenuActions["Ungroup"] = "ungroup";
    ContextMenuActions["Export"] = "export";
    ContextMenuActions["Delete"] = "delete";
    ContextMenuActions["Rename"] = "rename";
    ContextMenuActions["TurnOffGroup"] = "turnOffGroup";
    ContextMenuActions["TurnOnGroup"] = "turnOnGroup";
    ContextMenuActions["CopyGroup"] = "copyGroup";
    ContextMenuActions["ExportGroup"] = "exportGroup";
    ContextMenuActions["DeleteGroup"] = "deleteGroup";
})(ContextMenuActions || (ContextMenuActions = {}));
const useContextMenuActionsMap = () => {
    const { t } = useTranslation();
    const ContextMenuActionsMap = {
        [ContextMenuActions.TurnOff]: {
            name: ContextMenuActions.TurnOff,
            iconName: `farToggleOff`,
            text: t('FAQ:contextMenuTurnOff'),
        },
        [ContextMenuActions.TurnOn]: {
            name: ContextMenuActions.TurnOn,
            iconName: `farToggleOn`,
            text: t('FAQ:contextMenuTurnOn'),
        },
        [ContextMenuActions.Copy]: {
            name: ContextMenuActions.Copy,
            iconName: `farCopy`,
            text: t('FAQ:contextMenuCopy'),
        },
        [ContextMenuActions.Ungroup]: {
            name: ContextMenuActions.Ungroup,
            iconName: `farObjectUngroup`,
            text: t('FAQ:contextMenuUngroup'),
        },
        [ContextMenuActions.Group]: {
            name: ContextMenuActions.Group,
            iconName: `farObjectGroup`,
            text: t('FAQ:contextMenuGroup'),
        },
        [ContextMenuActions.Export]: {
            name: ContextMenuActions.Export,
            iconName: `farArrowToBottom`,
            text: t('FAQ:contextMenuExportNode'),
        },
        [ContextMenuActions.Delete]: {
            name: ContextMenuActions.Delete,
            iconName: `farTrashAlt`,
            className: classes.faqTreeContextMenu__delete,
            text: t('FAQ:contextMenuDelete'),
        },
        [ContextMenuActions.Rename]: {
            name: ContextMenuActions.Rename,
            iconName: 'farPen',
            text: t('FAQ:contextMenuRename'),
        },
        [ContextMenuActions.TurnOffGroup]: {
            name: ContextMenuActions.TurnOffGroup,
            iconName: `farToggleOff`,
            text: t('FAQ:contextMenuTurnOffGroup'),
            textIfMany: t('FAQ:contextMenuTurnOffGroup_many'),
        },
        [ContextMenuActions.TurnOnGroup]: {
            name: ContextMenuActions.TurnOnGroup,
            iconName: `farToggleOn`,
            text: t('FAQ:contextMenuTurnOnGroup'),
            textIfMany: t('FAQ:contextMenuTurnOnGroup_many'),
        },
        [ContextMenuActions.CopyGroup]: {
            name: ContextMenuActions.CopyGroup,
            iconName: `farCopy`,
            text: t('FAQ:contextMenuCopyGroup'),
            textIfMany: t('FAQ:contextMenuCopyGroup_many'),
        },
        [ContextMenuActions.ExportGroup]: {
            name: ContextMenuActions.ExportGroup,
            iconName: `farArrowToBottom`,
            text: t('FAQ:contextMenuExportFolder'),
        },
        [ContextMenuActions.DeleteGroup]: {
            name: ContextMenuActions.DeleteGroup,
            iconName: `farTrashAlt`,
            className: classes.faqTreeContextMenu__delete,
            text: t('FAQ:contextMenuDeleteGroup'),
            textIfMany: t('FAQ:contextMenuDeleteGroup_many'),
        },
    };
    return ContextMenuActionsMap;
};
function modifyMenuItem(menuItem, options) {
    const newMenuItem = Object.assign({}, menuItem);
    if (options.count && options.count > 1) {
        newMenuItem.text = `${menuItem.textIfMany || menuItem.text} (${options.count})`;
    }
    if (options.nodes) {
        newMenuItem.nodes = options.nodes;
    }
    if (options.disabled !== undefined) {
        newMenuItem.disabled = options.disabled;
    }
    return newMenuItem;
}
const useFAQTreeContextMenuItems = (targetNode, nodes, writeDisabled) => {
    const ContextMenuActionsMap = useContextMenuActionsMap();
    return useMemo(() => {
        const menu = [];
        const isTargetNodeInSelectionList = nodes.find(node => node.id === targetNode.id);
        const selectedNodes = isTargetNodeInSelectionList ? nodes : [targetNode];
        let nodesToSwitch = selectedNodes;
        let areAllNodesDisabled = false;
        if (targetNode.isFolder) {
            const folderNodes = selectedNodes.filter(node => node.isFolder);
            const rootNodes = selectedNodes.filter(node => node.isInRoot);
            nodesToSwitch = folderNodes
                .map(node => TreeStructure.getNodeAsPlainList(node))
                .flat()
                .filter(node => !node.isFolder);
            areAllNodesDisabled = !(nodesToSwitch === null || nodesToSwitch === void 0 ? void 0 : nodesToSwitch.find(node => node.enabled));
            const isRenameDisabled = folderNodes.length > 1 || rootNodes.length > 0 || writeDisabled;
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Rename], {
                disabled: isRenameDisabled,
                nodes: folderNodes,
            }));
            menu.push(modifyMenuItem(areAllNodesDisabled
                ? ContextMenuActionsMap[ContextMenuActions.TurnOnGroup]
                : ContextMenuActionsMap[ContextMenuActions.TurnOffGroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.CopyGroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Ungroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.ExportGroup], {
                count: folderNodes.length,
                nodes: selectedNodes,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.DeleteGroup], {
                count: folderNodes.length,
                nodes: selectedNodes,
                disabled: writeDisabled,
            }));
            return menu;
        }
        const questionNodes = selectedNodes.filter(node => !node.isFolder);
        nodesToSwitch = questionNodes;
        areAllNodesDisabled = !(nodesToSwitch === null || nodesToSwitch === void 0 ? void 0 : nodesToSwitch.find(node => node.enabled));
        menu.push(modifyMenuItem(areAllNodesDisabled
            ? ContextMenuActionsMap[ContextMenuActions.TurnOn]
            : ContextMenuActionsMap[ContextMenuActions.TurnOff], {
            count: questionNodes.length,
            nodes: nodesToSwitch,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Copy], {
            count: questionNodes.length,
            nodes: questionNodes,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Group], {
            count: questionNodes.length,
            nodes: questionNodes,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Export], {
            count: questionNodes.length,
            nodes: selectedNodes,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Delete], {
            count: questionNodes.length,
            nodes: selectedNodes,
            disabled: writeDisabled,
        }));
        return menu;
    }, [nodes, targetNode, ContextMenuActionsMap, writeDisabled]);
};
const ContextMenu = ({ targetNode, nodes, position, close, onSelectAction, writeDisabled }) => {
    const direction = useMemo(() => ((position === null || position === void 0 ? void 0 : position.y) + MAX_HEIGHT < document.body.offsetHeight ? 'down' : 'up'), [position]);
    const menuItems = useFAQTreeContextMenuItems(targetNode, nodes, writeDisabled);
    return (_jsxs(Dropdown, { compact: true, dark: true, isOpen: true, toggle: close, direction: direction, style: Object.assign({ zIndex: 3002, position: 'fixed', left: position.x }, (direction === 'down'
            ? { top: (position === null || position === void 0 ? void 0 : position.y) - 13 }
            : { bottom: document.body.offsetHeight - (position === null || position === void 0 ? void 0 : position.y) - 13 })), children: [_jsx(DropdownToggle, { style: { padding: 0, border: 'none' } }), _jsx(DropdownMenu, { className: classes.faqTreeContextMenu, children: menuItems.map(({ name, nodes, iconName, text, className, disabled }, key) => (_jsxs(DropdownItem, { className: className, onClick: () => onSelectAction(name, nodes), "data-test-id": `FAQTree.ContextMenu.action.${name}`, disabled: disabled, children: [_jsx(Icon, { name: iconName }), text] }, key))) })] }));
};
export default ContextMenu;
