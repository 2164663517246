var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { generateUniqId } from '../views/FAQTreePage/components/PhrasesBlock/utils';
import { t } from '../localization';
export function getPlural(pluralNumber, plurals) {
    if (pluralNumber === 1)
        return plurals[0];
    if (pluralNumber > 1 && pluralNumber <= 4)
        return plurals[1];
    if (pluralNumber === 0 || pluralNumber > 4)
        return plurals[2];
    return plurals[0];
}
export function downloadTxtFile(text, fileName) {
    const element = document.createElement('a');
    const file = new Blob([text], {
        type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.txt`;
    document.body.appendChild(element);
    element.click();
}
export const saveFileToServer = (file_1, _a, axiosInstance_1) => __awaiter(void 0, [file_1, _a, axiosInstance_1], void 0, function* (file, { signal }, axiosInstance) {
    const fd = new FormData();
    fd.append('file', file);
    const payload = yield axiosInstance.post('/restapi/file/upload', fd, { signal });
    const payloadUrl = payload.data;
    return payloadUrl;
});
export const repliesToAnswerParts = (replies) => {
    const getMainFieldByType = (reply) => {
        switch (reply.type) {
            case 'text':
                return reply.text;
            case 'file':
                return reply.fileUrl;
            case 'audio':
                return reply.audioUrl;
            case 'image':
                return reply.imageUrl;
            default:
                return reply.text;
        }
    };
    return replies.map(reply => ({
        type: reply.type,
        value: getMainFieldByType(reply) || '',
        audioName: reply.audioName,
        fileName: reply.fileName,
        markup: reply.markup,
        mimeType: reply.mimeType,
        uploadDate: reply.uploadDate,
        id: reply.key || generateUniqId(),
        error: reply.error,
    }));
};
export const answerPartsToReplies = (answerParts) => {
    const getReplyByAnswerType = (part) => {
        switch (part.type) {
            case 'text':
                return {
                    type: part.type,
                    text: part.value,
                    markup: part.markup,
                    key: part.id,
                };
            case 'file':
                return {
                    type: part.type,
                    fileUrl: part.value,
                    fileName: part.fileName,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            case 'audio':
                return {
                    type: part.type,
                    audioUrl: part.value,
                    audioName: part.audioName || part.fileName,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            case 'image':
                return {
                    type: part.type,
                    imageUrl: part.value,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            default:
                return {
                    type: part.type,
                    text: part.value,
                    key: part.id,
                };
        }
    };
    return answerParts.map(part => getReplyByAnswerType(part));
};
export const validateUrl = (value) => {
    try {
        new URL(value);
        return true;
    }
    catch (error) {
        return false;
    }
};
const tenMbInBites = 10485760;
export const validateFile = (type, file) => {
    switch (type) {
        case 'image':
            if (file.size > tenMbInBites) {
                throw new Error(t('AudioUploadField:Error:FileSize'));
            }
            if (!file.type.includes('image')) {
                throw new Error(t('AudioUploadField:Error:InvalidImageExtension'));
            }
            return;
        case 'file':
            if (file.size > tenMbInBites) {
                throw new Error(t('AudioUploadField:Error:FileSize'));
            }
            return;
        default:
            break;
    }
};
