import {
  DEPLOY_HISTORY_PAGINATE_FULFILLED,
  DEPLOY_HISTORY_PAGINATE_PENDING,
  DEPLOY_HISTORY_PAGINATE_REJECTED,
  FEETCH_CHANNEL_DATA_FULFILLED,
  FEETCH_CHANNEL_DATA_PENDING,
  FEETCH_CHANNEL_DATA_REJECTED,
  FEETCH_DEPLOY_HISTORY_FULFILLED,
  FEETCH_DEPLOY_HISTORY_PENDING,
  FEETCH_DEPLOY_HISTORY_REJECTED,
  REFETCH_DEPLOY_HISTORY_FULFILLED,
  REFETCH_DEPLOY_HISTORY_PENDING,
  REFETCH_DEPLOY_HISTORY_REJECTED,
  SET_CURRENT_BUILD,
} from '../../constants/deployHistory.actions';

const InitialState = {
  buildList: [],
  currentBuild: null,
  currentChannel: null,
  canBeUpdated: false,
  fetching: false,
  fetched: false,
  loadError: [],
  page: 0,
  totalPages: 0,
  totalElements: '1',
  pageSize: 10,
};

export default function DeployHistoryReducer(state = InitialState, action) {
  switch (action.type) {
    case REFETCH_DEPLOY_HISTORY_PENDING: {
      return {
        ...state,
        fetched: false,
      };
    }
    case REFETCH_DEPLOY_HISTORY_REJECTED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        loadError: true,
      };
    }
    case REFETCH_DEPLOY_HISTORY_FULFILLED: {
      let buildList = [...action.payload.data.content];
      let currentBuild = null;

      if (!!state.currentBuild) {
        currentBuild = buildList.find(build => {
          return build.id === state.currentBuild.id;
        });
      } else {
        console.log(action.payload.data);
      }

      return {
        ...state,
        buildList: action.payload.data.content,
        fetched: true,
        currentBuild: currentBuild,
        fetching: false,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
      };
    }
    case FEETCH_CHANNEL_DATA_FULFILLED:
      return {
        ...state,
        currentChannel: action.payload.data,
        fetched: true,
        fetching: false,
      };
    case FEETCH_CHANNEL_DATA_REJECTED:
      return {
        ...state,
        loadError: true,
        fetched: true,
        fetching: false,
      };
    case FEETCH_CHANNEL_DATA_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case FEETCH_DEPLOY_HISTORY_FULFILLED:
      let buildList = action.payload.data.content;
      let currentBuild = !!state.currentChannel
        ? buildList.find(build => {
            return build.id === state.currentChannel.lastDeployResult.id;
          })
        : state.currentBuild;

      return {
        ...state,
        buildList: buildList,
        currentBuild: currentBuild,
        fetched: true,
        fetching: false,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
      };
    case FEETCH_DEPLOY_HISTORY_REJECTED:
      return {
        ...state,
        loadError: true,
        fetched: true,
        fetching: false,
      };
    case FEETCH_DEPLOY_HISTORY_PENDING:
    case DEPLOY_HISTORY_PAGINATE_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case DEPLOY_HISTORY_PAGINATE_FULFILLED:
      return {
        ...state,
        buildList: action.payload.data.content,
        fetched: true,
        fetching: false,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
      };
    case DEPLOY_HISTORY_PAGINATE_REJECTED:
      return {
        ...state,
        loadError: true,
        fetched: true,
        fetching: false,
      };
    case SET_CURRENT_BUILD:
      let selectedBuild = state.buildList.find(build => {
        return build.id === action.buildId;
      });
      return {
        ...state,
        currentBuild: !!selectedBuild ? selectedBuild : state.currentBuild,
      };
    default:
      return state;
  }
}
