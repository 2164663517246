const prefix = '@accountManager/';

export const SET_USER_HISTORY_FILTER = prefix + 'SET_USER_HISTORY_FILTER';
export const DROP_ERRORS = prefix + 'DROP_ERRORS';

export const CHANGE_USER_TARIFF = prefix + 'CHANGE_USER_TARIFF';
export const CHANGE_USER_TARIFF_PENDING = prefix + 'CHANGE_USER_TARIFF_PENDING';
export const CHANGE_USER_TARIFF_FULFILLED = prefix + 'CHANGE_USER_TARIFF_FULFILLED';
export const CHANGE_USER_TARIFF_REJECTED = prefix + 'CHANGE_USER_TARIFF_REJECTED';

export const CHANGE_USER_LOCK = prefix + 'CHANGE_USER_LOCK';
export const CHANGE_USER_LOCK_PENDING = prefix + 'CHANGE_USER_LOCK_PENDING';
export const CHANGE_USER_LOCK_FULFILLED = prefix + 'CHANGE_USER_LOCK_FULFILLED';
export const CHANGE_USER_LOCK_REJECTED = prefix + 'CHANGE_USER_LOCK_REJECTED';

export const ADD_PACKAGE = prefix + 'ADD_PACKAGE';
export const ADD_PACKAGE_PENDING = prefix + 'ADD_PACKAGE_PENDING';
export const ADD_PACKAGE_FULFILLED = prefix + 'ADD_PACKAGE_FULFILLED';
export const ADD_PACKAGE_REJECTED = prefix + 'ADD_PACKAGE_REJECTED';

export const ADD_MINUTES = prefix + 'ADD_MINUTES';
export const ADD_MINUTES_PENDING = prefix + 'ADD_MINUTES_PENDING';
export const ADD_MINUTES_FULFILLED = prefix + 'ADD_MINUTES_FULFILLED';
export const ADD_MINUTES_REJECTED = prefix + 'ADD_MINUTES_REJECTED';

export const FETCH_USER_HISTORY = prefix + 'FETCH_USER_HISTORY';
export const FETCH_USER_HISTORY_PENDING = prefix + 'FETCH_USER_HISTORY_PENDING';
export const FETCH_USER_HISTORY_FULFILLED = prefix + 'FETCH_USER_HISTORY_FULFILLED';
export const FETCH_USER_HISTORY_REJECTED = prefix + 'FETCH_USER_HISTORY_REJECTED';

export const FETCH_USER_DATA = prefix + 'FETCH_USER_DATA';
export const FETCH_USER_DATA_PENDING = prefix + 'FETCH_USER_DATA_PENDING';
export const FETCH_USER_DATA_FULFILLED = prefix + 'FETCH_USER_DATA_FULFILLED';
export const FETCH_USER_DATA_REJECTED = prefix + 'FETCH_USER_DATA_REJECTED';

export const FETCH_AVAILABLE_OPTIONS_DATA = prefix + 'FETCH_AVAILABLE_OPTIONS_DATA';
export const FETCH_AVAILABLE_OPTIONS_DATA_PENDING = prefix + 'FETCH_AVAILABLE_OPTIONS_DATA_PENDING';
export const FETCH_AVAILABLE_OPTIONS_DATA_FULFILLED = prefix + 'FETCH_AVAILABLE_OPTIONS_DATA_FULFILLED';
export const FETCH_AVAILABLE_OPTIONS_DATA_REJECTED = prefix + 'FETCH_AVAILABLE_OPTIONS_DATA_REJECTED';

export const FETCH_OWNER_DATA = prefix + 'FETCH_OWNER_DATA';
export const FETCH_OWNER_DATA_PENDING = prefix + 'FETCH_OWNER_DATA_PENDING';
export const FETCH_OWNER_DATA_FULFILLED = prefix + 'FETCH_OWNER_DATA_FULFILLED';
export const FETCH_OWNER_DATA_REJECTED = prefix + 'FETCH_OWNER_DATA_REJECTED';

export const CHANGE_MANUAL_CONTROL = prefix + 'CHANGE_MANUAL_CONTROL';
export const CHANGE_MANUAL_CONTROL_PENDING = prefix + 'CHANGE_MANUAL_CONTROL_PENDING';
export const CHANGE_MANUAL_CONTROL_FULFILLED = prefix + 'CHANGE_MANUAL_CONTROL_FULFILLED';
export const CHANGE_MANUAL_CONTROL_REJECTED = prefix + 'CHANGE_MANUAL_CONTROL_REJECTED';

export const FETCH_PARTNER_DATA = prefix + 'FETCH_PARTNER_DATA';
export const FETCH_PARTNER_DATA_PENDING = prefix + 'FETCH_PARTNER_DATA_PENDING';
export const FETCH_PARTNER_DATA_FULFILLED = prefix + 'FETCH_PARTNER_DATA_FULFILLED';
export const FETCH_PARTNER_DATA_REJECTED = prefix + 'FETCH_PARTNER_DATA_REJECTED';

export const FETCH_PARTNER_REFERRALS = prefix + 'FETCH_PARTNER_REFERRALS';
export const FETCH_PARTNER_REFERRALS_PENDING = prefix + 'FETCH_PARTNER_REFERRALS_PENDING';
export const FETCH_PARTNER_REFERRALS_FULFILLED = prefix + 'FETCH_PARTNER_REFERRALS_FULFILLED';
export const FETCH_PARTNER_REFERRALS_REJECTED = prefix + 'FETCH_PARTNER_REFERRALS_REJECTED';

export const CHANGE_PARTNER_DATA = prefix + 'CHANGE_PARTNER_DATA';
export const CHANGE_PARTNER_DATA_PENDING = prefix + 'CHANGE_PARTNER_DATA_PENDING';
export const CHANGE_PARTNER_DATA_FULFILLED = prefix + 'CHANGE_PARTNER_DATA_FULFILLED';
export const CHANGE_PARTNER_DATA_REJECTED = prefix + 'CHANGE_PARTNER_DATA_REJECTED';

export const ADD_PARTNER = prefix + 'ADD_PARTNER';
export const ADD_PARTNER_PENDING = prefix + 'ADD_PARTNER_PENDING';
export const ADD_PARTNER_FULFILLED = prefix + 'ADD_PARTNER_FULFILLED';
export const ADD_PARTNER_REJECTED = prefix + 'ADD_PARTNER_REJECTED';

export const ADD_PARTNER_REFERRAL = prefix + 'ADD_PARTNER_REFERRAL';
export const ADD_PARTNER_REFERRAL_PENDING = prefix + 'ADD_PARTNER_REFERRAL_PENDING';
export const ADD_PARTNER_REFERRAL_FULFILLED = prefix + 'ADD_PARTNER_REFERRAL_FULFILLED';
export const ADD_PARTNER_REFERRAL_REJECTED = prefix + 'ADD_PARTNER_REFERRAL_REJECTED';

export const DISCONNECT_PARTNER_REFERRAL = prefix + 'DISCONNECT_PARTNER_REFERRAL';
export const DISCONNECT_PARTNER_REFERRAL_PENDING = prefix + 'DISCONNECT_PARTNER_REFERRAL_PENDING';
export const DISCONNECT_PARTNER_REFERRAL_FULFILLED = prefix + 'DISCONNECT_PARTNER_REFERRAL_FULFILLED';
export const DISCONNECT_PARTNER_REFERRAL_REJECTED = prefix + 'DISCONNECT_PARTNER_REFERRAL_REJECTED';

export const MOVE_PARTNER_REFERRAL = prefix + 'MOVE_PARTNER_REFERRAL';
export const MOVE_PARTNER_REFERRAL_PENDING = prefix + 'MOVE_PARTNER_REFERRAL_PENDING';
export const MOVE_PARTNER_REFERRAL_FULFILLED = prefix + 'MOVE_PARTNER_REFERRAL_FULFILLED';
export const MOVE_PARTNER_REFERRAL_REJECTED = prefix + 'MOVE_PARTNER_REFERRAL_REJECTED';

export const DROP_REFERRALS_ERRORS = prefix + 'DROP_REFERRALS_ERRORS';
export const DROP_PARTNER_DATA = prefix + 'DROP_PARTNER_DATA';
export const DROP_ACCOUNT_MANAGER_DATA_ERRORS = prefix + 'DROP_ACCOUNT_MANAGER_DATA_ERRORS';

export const ADD_PACKAGES = prefix + 'ADD_PACKAGES';
export const ADD_PACKAGES_PENDING = prefix + 'ADD_PACKAGES_PENDING';
export const ADD_PACKAGES_FULFILLED = prefix + 'ADD_PACKAGES_FULFILLED';
export const ADD_PACKAGES_REJECTED = prefix + 'ADD_PACKAGES_REJECTED';

export const DEDUCT_LIMITS = prefix + 'DEDUCT_LIMITS';
export const DEDUCT_LIMITS_PENDING = prefix + 'DEDUCT_LIMITS_PENDING';
export const DEDUCT_LIMITS_FULFILLED = prefix + 'DEDUCT_LIMITS_FULFILLED';
export const DEDUCT_LIMITS_REJECTED = prefix + 'DEDUCT_LIMITS_REJECTED';

export const GET_USER_TARIFF_CHANGE = prefix + 'GET_USER_TARIFF_CHANGE';
export const GET_USER_TARIFF_CHANGE_PENDING = prefix + 'GET_USER_TARIFF_CHANGE_PENDING';
export const GET_USER_TARIFF_CHANGE_FULFILLED = prefix + 'GET_USER_TARIFF_CHANGE_FULFILLED';
export const GET_USER_TARIFF_CHANGE_REJECTED = prefix + 'GET_USER_TARIFF_CHANGE_REJECTED';

export const GET_REFERRAL_PARTNER = prefix + 'GET_REFERRAL_PARTNER';
export const GET_REFERRAL_PARTNER_PENDING = prefix + 'GET_REFERRAL_PARTNER_PENDING';
export const GET_REFERRAL_PARTNER_FULFILLED = prefix + 'GET_REFERRAL_PARTNER_FULFILLED';
export const GET_REFERRAL_PARTNER_REJECTED = prefix + 'GET_REFERRAL_PARTNER_REJECTED';

export const ADD_PARTNER_TO_REFERRAL = prefix + 'ADD_PARTNER_TO_REFERRAL';
export const ADD_PARTNER_TO_REFERRAL_PENDING = prefix + 'ADD_PARTNER_TO_REFERRAL_PENDING';
export const ADD_PARTNER_TO_REFERRAL_FULFILLED = prefix + 'ADD_PARTNER_TO_REFERRAL_FULFILLED';
export const ADD_PARTNER_TO_REFERRAL_REJECTED = prefix + 'ADD_PARTNER_TO_REFERRAL_REJECTED';
