import { IntentData } from '../../../../../../Caila/api/client';
import { Intents } from '../../../../../../Caila/utils';
import { getDuplicateSlotNames, getEmptySlotNames } from '../../../../../../Caila/utils/intents';
import { IntentItem } from '../../../../../../Caila/model';
import { CustomIntentItem } from '../../../../../../Caila/pages/IntentsPage/IntentItemsEdit';
import { uniqueId } from 'lodash';

export const getPureDisplayValue = (path: string = '') => {
  const lastIndexOfSlash = path.lastIndexOf('/');
  //отрезаем весь путь до последнего слеша
  return path.substring(lastIndexOfSlash + 1, path.length);
};
export const convertToPhraseTextArray = (value: string) => value.split('\n').filter(phrase => phrase !== '');

export const validateIntent = (intent: IntentData) => {
  const isPathInvalid = !Intents.isValidPath(intent.path);
  const duplicateSlotNames = getDuplicateSlotNames(intent.slots);
  const emptySlotNames = getEmptySlotNames(intent.slots);
  const isIntentValid = !isPathInvalid && duplicateSlotNames.length === 0 && emptySlotNames.length === 0;

  return {
    isPathInvalid,
    duplicateSlotNames: duplicateSlotNames,
    emptySlotNames: emptySlotNames,
    isIntentValid,
  };
};

export const convertIntentItemWithId = (item: IntentItem, i: number): CustomIntentItem => {
  return {
    ...item,
    index: i,
    id: (item as CustomIntentItem).id || uniqueId(String(i)),
    error: undefined,
  };
};
