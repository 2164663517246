import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { RadioButton } from '@just-ai/just-ui';

import { t } from 'localization';
import { UiMode } from 'types';

import { TagNames, TJBlock } from 'modules/JGraph/utils/types';
import { NewCommonActivationSelectState } from 'modules/JGraph/components/NewCommonActivationsParams';
import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';

import { useRightSideMenuContext } from '../index';

export const TransitionSettings: FC = () => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();
  const { uiMode } = useJGraphContext();
  const editBlock = getEditBlockTag() as TJBlock<TagNames.go | TagNames.go_>;

  return (
    <div className='JGraph-RightSideMenu-settings'>
      {uiMode === UiMode.DevX_user && (
        <div className='JGraph-RightSideMenu-transitionSettings-group'>
          <Label>{t('TransitionSettings:TransitionType')}</Label>
          <div className='inline-radio'>
            <RadioButton
              label={t('TransitionSettings:TransitionType_deffered')}
              name='transitionType'
              data-test-id={`TransitionSettings:TransitionType:${TagNames.go}`}
              checked={editBlock.tagName === TagNames.go}
              onChange={() => onChangeCommonTag({ tagName: TagNames.go })}
            />
            <RadioButton
              label={t('TransitionSettings:TransitionType_not_deffered')}
              name='transitionType'
              data-test-id={`TransitionSettings:TransitionType:${TagNames.go_}`}
              checked={editBlock.tagName === TagNames.go_}
              onChange={() => onChangeCommonTag({ tagName: TagNames.go_ })}
            />
          </div>
        </div>
      )}
      <NewCommonActivationSelectState
        fromStateValue={editBlock.tagValue || ''}
        toStateValue={editBlock.tagValue || ''}
        isStateSelectionOpened
        isGlobal={false}
        isFromState={false}
        isToState={false}
        onChangeStateValue={value => onChangeCommonTag({ tagValue: value })}
        onChangeOnlyThisState={() => false}
        onlyThisState={false}
      />
    </div>
  );
};
