import { useMemo } from 'react';
export function useSupportAudioInfo(url) {
    return useMemo(() => {
        var _a;
        const result = { incompatible: [], compatible: [] };
        let innerUrl;
        try {
            innerUrl = new URL(url);
        }
        catch (e) {
            return result;
        }
        if (!innerUrl || !innerUrl.search)
            return result;
        try {
            const searchParams = new URLSearchParams(innerUrl.search);
            const channels = JSON.parse((_a = searchParams.get('channels')) !== null && _a !== void 0 ? _a : '');
            channels.incompatible = channels.incompatible.filter(x => x !== 'ALEXA');
            channels.compatible = channels.compatible.filter(x => x !== 'ALEXA');
            return channels;
        }
        catch (e) { }
        return result;
    }, [url]);
}
