export const projectGroupLocalization = {
  'ProjectGroupSelector:DefaultGroup': {
    eng: 'My projects',
    ru: 'Мои проекты',
  },
  'ProjectGroupSelector:CreateGroup': {
    eng: 'Create a group',
    ru: 'Создать группу',
  },
  'ProjectGroupSelector:InvitedToGroupBadge': {
    eng: 'You were invited',
    ru: 'Вас пригласили',
  },
  'ProjectGroupSelector:LeaveGroup': {
    eng: 'Leave the group',
    ru: 'Выйти из группы',
  },
};
