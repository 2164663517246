import React, { useEffect, useRef, useState } from 'react';
import { StateStorePositionSize } from '../utils/blockLayerUtils';
import { BehaviorSubject, distinctUntilChanged, shareReplay } from 'rxjs';
import { Group, Rect } from 'react-konva';

type GroupHighlightType = 'withBottomRect' | 'simple';
export const GroupHighlighterSubject$ = new BehaviorSubject<
  { statePath: string; nameValue: string; coords: StateStorePositionSize; type: GroupHighlightType } | undefined
>(undefined);

export const GroupHighlighterSubjectPipe$ = GroupHighlighterSubject$.pipe(
  distinctUntilChanged((prevValue, newValue) => JSON.stringify(prevValue) === JSON.stringify(newValue)),
  shareReplay(1)
);

export const GroupHighlighter = React.memo(({ statePath }: { statePath: string }) => {
  const [isShown, setShow] = useState(false);
  const [groupHighlightType, setGroupHighlightType] = useState<GroupHighlightType>('simple');
  const intersection = useRef<{ statePath: string; nameValue: string; coords: StateStorePositionSize } | undefined>(
    undefined
  );
  useEffect(() => {
    const sub = GroupHighlighterSubjectPipe$.subscribe(value => {
      if (value && statePath === value.statePath) {
        intersection.current = value;
        setGroupHighlightType(value.type);
        setShow(true);
      }

      if (!value) setShow(!!value);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [statePath]);
  if (!isShown) return null;
  if (!intersection.current) return null;

  if (groupHighlightType === 'withBottomRect') {
    return (
      <Group
        x={intersection.current?.coords.x - 1}
        y={intersection.current?.coords.y}
        width={280}
        height={intersection.current?.coords.height}
      >
        <Rect
          x={12}
          y={intersection.current?.coords.height + 8}
          width={280 - 24}
          fill='#E3DEFB'
          stroke='#C8BFF6'
          strokeWidth={1}
          cornerRadius={8}
          height={32}
        />
        <Rect
          width={280}
          fill='#605FE3'
          opacity={0.1}
          stroke='#605FE3'
          cornerRadius={8}
          height={intersection.current?.coords.height + 40 + 8}
        />
        <Rect
          width={280}
          stroke='#605FE3'
          strokeWidth={2}
          cornerRadius={8}
          height={intersection.current?.coords.height + 40 + 8}
        />
      </Group>
    );
  }

  if (groupHighlightType === 'simple') {
    return (
      <Group
        x={intersection.current?.coords.x - 1}
        y={intersection.current?.coords.y}
        width={280}
        height={intersection.current?.coords.height}
      >
        <Rect
          width={280}
          fill='#605FE3'
          opacity={0.1}
          stroke='#605FE3'
          cornerRadius={8}
          height={intersection.current?.coords.height}
        />
        <Rect
          width={280}
          stroke='#605FE3'
          strokeWidth={2}
          cornerRadius={8}
          height={intersection.current?.coords.height}
        />
      </Group>
    );
  }

  return null;
});
