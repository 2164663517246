import React from 'react';
import { Circle, Group, Rect } from 'react-konva';

import { transformRectInfo } from '../consts';

const StickerTransform = () => {
  return (
    <Group>
      <Rect
        width={transformRectInfo.width}
        height={transformRectInfo.height}
        x={transformRectInfo.x}
        y={transformRectInfo.y}
        fill='transparent'
        stroke='#2296F5'
      />
      <Circle x={transformRectInfo.x} y={transformRectInfo.y} radius={6} fill='white' stroke='#225680' />
      <Circle
        x={transformRectInfo.x + transformRectInfo.width}
        y={transformRectInfo.y}
        radius={6}
        fill='white'
        stroke='#225680'
      />
      <Circle
        x={transformRectInfo.x}
        y={transformRectInfo.y + transformRectInfo.height}
        radius={6}
        fill='white'
        stroke='#225680'
      />
      <Circle
        x={transformRectInfo.x + transformRectInfo.width}
        y={transformRectInfo.y + transformRectInfo.height}
        radius={6}
        fill='white'
        stroke='#225680'
      />
    </Group>
  );
};

export default React.memo(StickerTransform);
