const packInfo = {
    lib: "../../node_modules/@fortawesome/free-regular-svg-icons",
    prefix: "far",
    iconsNameMap: {
        "farAddressBook": () => import('@fortawesome/free-regular-svg-icons/faAddressBook'),
        "farAddressCard": () => import('@fortawesome/free-regular-svg-icons/faAddressCard'),
        "farAngry": () => import('@fortawesome/free-regular-svg-icons/faAngry'),
        "farArrowAltCircleDown": () => import('@fortawesome/free-regular-svg-icons/faArrowAltCircleDown'),
        "farArrowAltCircleLeft": () => import('@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft'),
        "farArrowAltCircleRight": () => import('@fortawesome/free-regular-svg-icons/faArrowAltCircleRight'),
        "farArrowAltCircleUp": () => import('@fortawesome/free-regular-svg-icons/faArrowAltCircleUp'),
        "farBell": () => import('@fortawesome/free-regular-svg-icons/faBell'),
        "farBellSlash": () => import('@fortawesome/free-regular-svg-icons/faBellSlash'),
        "farBookmark": () => import('@fortawesome/free-regular-svg-icons/faBookmark'),
        "farBuilding": () => import('@fortawesome/free-regular-svg-icons/faBuilding'),
        "farCalendar": () => import('@fortawesome/free-regular-svg-icons/faCalendar'),
        "farCalendarAlt": () => import('@fortawesome/free-regular-svg-icons/faCalendarAlt'),
        "farCalendarCheck": () => import('@fortawesome/free-regular-svg-icons/faCalendarCheck'),
        "farCalendarMinus": () => import('@fortawesome/free-regular-svg-icons/faCalendarMinus'),
        "farCalendarPlus": () => import('@fortawesome/free-regular-svg-icons/faCalendarPlus'),
        "farCalendarTimes": () => import('@fortawesome/free-regular-svg-icons/faCalendarTimes'),
        "farCaretSquareDown": () => import('@fortawesome/free-regular-svg-icons/faCaretSquareDown'),
        "farCaretSquareLeft": () => import('@fortawesome/free-regular-svg-icons/faCaretSquareLeft'),
        "farCaretSquareRight": () => import('@fortawesome/free-regular-svg-icons/faCaretSquareRight'),
        "farCaretSquareUp": () => import('@fortawesome/free-regular-svg-icons/faCaretSquareUp'),
        "farChartBar": () => import('@fortawesome/free-regular-svg-icons/faChartBar'),
        "farCheckCircle": () => import('@fortawesome/free-regular-svg-icons/faCheckCircle'),
        "farCheckSquare": () => import('@fortawesome/free-regular-svg-icons/faCheckSquare'),
        "farCircle": () => import('@fortawesome/free-regular-svg-icons/faCircle'),
        "farClipboard": () => import('@fortawesome/free-regular-svg-icons/faClipboard'),
        "farClock": () => import('@fortawesome/free-regular-svg-icons/faClock'),
        "farClone": () => import('@fortawesome/free-regular-svg-icons/faClone'),
        "farClosedCaptioning": () => import('@fortawesome/free-regular-svg-icons/faClosedCaptioning'),
        "farComment": () => import('@fortawesome/free-regular-svg-icons/faComment'),
        "farCommentAlt": () => import('@fortawesome/free-regular-svg-icons/faCommentAlt'),
        "farCommentDots": () => import('@fortawesome/free-regular-svg-icons/faCommentDots'),
        "farComments": () => import('@fortawesome/free-regular-svg-icons/faComments'),
        "farCompass": () => import('@fortawesome/free-regular-svg-icons/faCompass'),
        "farCopy": () => import('@fortawesome/free-regular-svg-icons/faCopy'),
        "farCopyright": () => import('@fortawesome/free-regular-svg-icons/faCopyright'),
        "farCreditCard": () => import('@fortawesome/free-regular-svg-icons/faCreditCard'),
        "farDizzy": () => import('@fortawesome/free-regular-svg-icons/faDizzy'),
        "farDotCircle": () => import('@fortawesome/free-regular-svg-icons/faDotCircle'),
        "farEdit": () => import('@fortawesome/free-regular-svg-icons/faEdit'),
        "farEnvelope": () => import('@fortawesome/free-regular-svg-icons/faEnvelope'),
        "farEnvelopeOpen": () => import('@fortawesome/free-regular-svg-icons/faEnvelopeOpen'),
        "farEye": () => import('@fortawesome/free-regular-svg-icons/faEye'),
        "farEyeSlash": () => import('@fortawesome/free-regular-svg-icons/faEyeSlash'),
        "farFile": () => import('@fortawesome/free-regular-svg-icons/faFile'),
        "farFileAlt": () => import('@fortawesome/free-regular-svg-icons/faFileAlt'),
        "farFileArchive": () => import('@fortawesome/free-regular-svg-icons/faFileArchive'),
        "farFileAudio": () => import('@fortawesome/free-regular-svg-icons/faFileAudio'),
        "farFileCode": () => import('@fortawesome/free-regular-svg-icons/faFileCode'),
        "farFileExcel": () => import('@fortawesome/free-regular-svg-icons/faFileExcel'),
        "farFileImage": () => import('@fortawesome/free-regular-svg-icons/faFileImage'),
        "farFilePdf": () => import('@fortawesome/free-regular-svg-icons/faFilePdf'),
        "farFilePowerpoint": () => import('@fortawesome/free-regular-svg-icons/faFilePowerpoint'),
        "farFileVideo": () => import('@fortawesome/free-regular-svg-icons/faFileVideo'),
        "farFileWord": () => import('@fortawesome/free-regular-svg-icons/faFileWord'),
        "farFlag": () => import('@fortawesome/free-regular-svg-icons/faFlag'),
        "farFlushed": () => import('@fortawesome/free-regular-svg-icons/faFlushed'),
        "farFolder": () => import('@fortawesome/free-regular-svg-icons/faFolder'),
        "farFolderOpen": () => import('@fortawesome/free-regular-svg-icons/faFolderOpen'),
        "farFontAwesomeLogoFull": () => import('@fortawesome/free-regular-svg-icons/faFontAwesomeLogoFull'),
        "farFrown": () => import('@fortawesome/free-regular-svg-icons/faFrown'),
        "farFrownOpen": () => import('@fortawesome/free-regular-svg-icons/faFrownOpen'),
        "farFutbol": () => import('@fortawesome/free-regular-svg-icons/faFutbol'),
        "farGem": () => import('@fortawesome/free-regular-svg-icons/faGem'),
        "farGrimace": () => import('@fortawesome/free-regular-svg-icons/faGrimace'),
        "farGrin": () => import('@fortawesome/free-regular-svg-icons/faGrin'),
        "farGrinAlt": () => import('@fortawesome/free-regular-svg-icons/faGrinAlt'),
        "farGrinBeam": () => import('@fortawesome/free-regular-svg-icons/faGrinBeam'),
        "farGrinBeamSweat": () => import('@fortawesome/free-regular-svg-icons/faGrinBeamSweat'),
        "farGrinHearts": () => import('@fortawesome/free-regular-svg-icons/faGrinHearts'),
        "farGrinSquint": () => import('@fortawesome/free-regular-svg-icons/faGrinSquint'),
        "farGrinSquintTears": () => import('@fortawesome/free-regular-svg-icons/faGrinSquintTears'),
        "farGrinStars": () => import('@fortawesome/free-regular-svg-icons/faGrinStars'),
        "farGrinTears": () => import('@fortawesome/free-regular-svg-icons/faGrinTears'),
        "farGrinTongue": () => import('@fortawesome/free-regular-svg-icons/faGrinTongue'),
        "farGrinTongueSquint": () => import('@fortawesome/free-regular-svg-icons/faGrinTongueSquint'),
        "farGrinTongueWink": () => import('@fortawesome/free-regular-svg-icons/faGrinTongueWink'),
        "farGrinWink": () => import('@fortawesome/free-regular-svg-icons/faGrinWink'),
        "farHandLizard": () => import('@fortawesome/free-regular-svg-icons/faHandLizard'),
        "farHandPaper": () => import('@fortawesome/free-regular-svg-icons/faHandPaper'),
        "farHandPeace": () => import('@fortawesome/free-regular-svg-icons/faHandPeace'),
        "farHandPointDown": () => import('@fortawesome/free-regular-svg-icons/faHandPointDown'),
        "farHandPointLeft": () => import('@fortawesome/free-regular-svg-icons/faHandPointLeft'),
        "farHandPointRight": () => import('@fortawesome/free-regular-svg-icons/faHandPointRight'),
        "farHandPointUp": () => import('@fortawesome/free-regular-svg-icons/faHandPointUp'),
        "farHandPointer": () => import('@fortawesome/free-regular-svg-icons/faHandPointer'),
        "farHandRock": () => import('@fortawesome/free-regular-svg-icons/faHandRock'),
        "farHandScissors": () => import('@fortawesome/free-regular-svg-icons/faHandScissors'),
        "farHandSpock": () => import('@fortawesome/free-regular-svg-icons/faHandSpock'),
        "farHandshake": () => import('@fortawesome/free-regular-svg-icons/faHandshake'),
        "farHdd": () => import('@fortawesome/free-regular-svg-icons/faHdd'),
        "farHeart": () => import('@fortawesome/free-regular-svg-icons/faHeart'),
        "farHospital": () => import('@fortawesome/free-regular-svg-icons/faHospital'),
        "farHourglass": () => import('@fortawesome/free-regular-svg-icons/faHourglass'),
        "farIdBadge": () => import('@fortawesome/free-regular-svg-icons/faIdBadge'),
        "farIdCard": () => import('@fortawesome/free-regular-svg-icons/faIdCard'),
        "farImage": () => import('@fortawesome/free-regular-svg-icons/faImage'),
        "farImages": () => import('@fortawesome/free-regular-svg-icons/faImages'),
        "farKeyboard": () => import('@fortawesome/free-regular-svg-icons/faKeyboard'),
        "farKiss": () => import('@fortawesome/free-regular-svg-icons/faKiss'),
        "farKissBeam": () => import('@fortawesome/free-regular-svg-icons/faKissBeam'),
        "farKissWinkHeart": () => import('@fortawesome/free-regular-svg-icons/faKissWinkHeart'),
        "farLaugh": () => import('@fortawesome/free-regular-svg-icons/faLaugh'),
        "farLaughBeam": () => import('@fortawesome/free-regular-svg-icons/faLaughBeam'),
        "farLaughSquint": () => import('@fortawesome/free-regular-svg-icons/faLaughSquint'),
        "farLaughWink": () => import('@fortawesome/free-regular-svg-icons/faLaughWink'),
        "farLemon": () => import('@fortawesome/free-regular-svg-icons/faLemon'),
        "farLifeRing": () => import('@fortawesome/free-regular-svg-icons/faLifeRing'),
        "farLightbulb": () => import('@fortawesome/free-regular-svg-icons/faLightbulb'),
        "farListAlt": () => import('@fortawesome/free-regular-svg-icons/faListAlt'),
        "farMap": () => import('@fortawesome/free-regular-svg-icons/faMap'),
        "farMeh": () => import('@fortawesome/free-regular-svg-icons/faMeh'),
        "farMehBlank": () => import('@fortawesome/free-regular-svg-icons/faMehBlank'),
        "farMehRollingEyes": () => import('@fortawesome/free-regular-svg-icons/faMehRollingEyes'),
        "farMinusSquare": () => import('@fortawesome/free-regular-svg-icons/faMinusSquare'),
        "farMoneyBillAlt": () => import('@fortawesome/free-regular-svg-icons/faMoneyBillAlt'),
        "farMoon": () => import('@fortawesome/free-regular-svg-icons/faMoon'),
        "farNewspaper": () => import('@fortawesome/free-regular-svg-icons/faNewspaper'),
        "farObjectGroup": () => import('@fortawesome/free-regular-svg-icons/faObjectGroup'),
        "farObjectUngroup": () => import('@fortawesome/free-regular-svg-icons/faObjectUngroup'),
        "farPaperPlane": () => import('@fortawesome/free-regular-svg-icons/faPaperPlane'),
        "farPauseCircle": () => import('@fortawesome/free-regular-svg-icons/faPauseCircle'),
        "farPlayCircle": () => import('@fortawesome/free-regular-svg-icons/faPlayCircle'),
        "farPlusSquare": () => import('@fortawesome/free-regular-svg-icons/faPlusSquare'),
        "farQuestionCircle": () => import('@fortawesome/free-regular-svg-icons/faQuestionCircle'),
        "farRegistered": () => import('@fortawesome/free-regular-svg-icons/faRegistered'),
        "farSadCry": () => import('@fortawesome/free-regular-svg-icons/faSadCry'),
        "farSadTear": () => import('@fortawesome/free-regular-svg-icons/faSadTear'),
        "farSave": () => import('@fortawesome/free-regular-svg-icons/faSave'),
        "farShareSquare": () => import('@fortawesome/free-regular-svg-icons/faShareSquare'),
        "farSmile": () => import('@fortawesome/free-regular-svg-icons/faSmile'),
        "farSmileBeam": () => import('@fortawesome/free-regular-svg-icons/faSmileBeam'),
        "farSmileWink": () => import('@fortawesome/free-regular-svg-icons/faSmileWink'),
        "farSnowflake": () => import('@fortawesome/free-regular-svg-icons/faSnowflake'),
        "farSquare": () => import('@fortawesome/free-regular-svg-icons/faSquare'),
        "farStar": () => import('@fortawesome/free-regular-svg-icons/faStar'),
        "farStarHalf": () => import('@fortawesome/free-regular-svg-icons/faStarHalf'),
        "farStickyNote": () => import('@fortawesome/free-regular-svg-icons/faStickyNote'),
        "farStopCircle": () => import('@fortawesome/free-regular-svg-icons/faStopCircle'),
        "farSun": () => import('@fortawesome/free-regular-svg-icons/faSun'),
        "farSurprise": () => import('@fortawesome/free-regular-svg-icons/faSurprise'),
        "farThumbsDown": () => import('@fortawesome/free-regular-svg-icons/faThumbsDown'),
        "farThumbsUp": () => import('@fortawesome/free-regular-svg-icons/faThumbsUp'),
        "farTimesCircle": () => import('@fortawesome/free-regular-svg-icons/faTimesCircle'),
        "farTired": () => import('@fortawesome/free-regular-svg-icons/faTired'),
        "farTrashAlt": () => import('@fortawesome/free-regular-svg-icons/faTrashAlt'),
        "farUser": () => import('@fortawesome/free-regular-svg-icons/faUser'),
        "farUserCircle": () => import('@fortawesome/free-regular-svg-icons/faUserCircle'),
        "farWindowClose": () => import('@fortawesome/free-regular-svg-icons/faWindowClose'),
        "farWindowMaximize": () => import('@fortawesome/free-regular-svg-icons/faWindowMaximize'),
        "farWindowMinimize": () => import('@fortawesome/free-regular-svg-icons/faWindowMinimize'),
        "farWindowRestore": () => import('@fortawesome/free-regular-svg-icons/faWindowRestore')
    }
};
export default packInfo;
