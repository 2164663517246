import React, { useCallback, useEffect, useState } from 'react';
import classes from './style.module.scss';
import { useAppSelector } from '../../../../storeHooks';
import { BehaviorSubject } from 'rxjs';
import cn from 'classnames';
import { t } from 'localization';
import { Icon } from '@just-ai/just-ui';
import { useTestWidgetContext } from '../../../TestWidget';

export const NeedToRedeployWidgetSubject$ = new BehaviorSubject<boolean>(false);

export const NeedToRedeployWidget = React.memo(() => {
  const { tagLocatorsLoaded } = useAppSelector(state => ({
    tagLocatorsLoaded: state.JGraphReducer.tagLocatorsLoaded,
  }));

  const { showTestWidget, hideTestWidget } = useTestWidgetContext();

  const [show, setShow] = useState(false);

  useEffect(() => {
    const sub = NeedToRedeployWidgetSubject$.subscribe(value => {
      setShow(value);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const redeployWidget = useCallback(() => {
    hideTestWidget();
    requestAnimationFrame(() => showTestWidget(true));
    NeedToRedeployWidgetSubject$.next(false);
  }, [hideTestWidget, showTestWidget]);

  if (!tagLocatorsLoaded) return null;
  if (!show) return null;

  return (
    <div className={cn(classes.NeedToRedeployWidget)} onClick={redeployWidget}>
      <div className={classes.text}>{t('JGraph:ReloadWidget')}</div>
      <div className={classes.button}>
        <Icon name='farSyncAlt' size='md' color='secondary' />
      </div>
    </div>
  );
});
