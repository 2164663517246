const localization = {
    "This month": {
        en: 'This month',
        ru: 'Этот месяц',
        pt: 'Este mês'
    },
    "This day": {
        en: 'Today',
        ru: 'Сегодня',
        pt: 'Hoje'
    },
    "This week": {
        en: 'This week',
        ru: 'Этa неделя',
        pt: 'Esta semana'
    },
    "Last month": {
        en: 'Last month',
        ru: 'Прошлый месяц',
        pt: 'Mês passado'
    }
};
export function translate(text, locale) {
    if (!localization[text])
        return text;
    return localization[text][locale] ? localization[text][locale] : localization[text].en;
}
