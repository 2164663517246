import React, { FunctionComponent } from 'react';
import { Icon, IconProps } from '@just-ai/just-ui';
import { NotificationData } from 'modules/Notifications/api/client';
import { getTypeByNotification, NotificationType } from 'modules/Notifications/utils/notificaions';
import { deprefixTaskCode, isLogsDownload, isReportGeneration, tsToString } from 'modules/Notifications/utils/tasks';
import localize from '../../../../localization';

type Props = {
  notification: NotificationData;
};

const getIconProps = (notificationType: NotificationType): IconProps => {
  switch (notificationType) {
    case 'error':
      return {
        name: 'faExclamationCircle',
        color: 'danger',
      };
    case 'warn':
      return {
        name: 'faExclamationTriangle',
        color: 'warning',
      };
    default:
      return {
        name: 'faCheckCircle',
        color: 'success',
      };
  }
};

export const Step: FunctionComponent<Props> = ({ notification }) => {
  const notificationType = getTypeByNotification(notification);
  if (
    notificationType === 'error' ||
    notificationType === 'success' ||
    notification?.message?.code?.code === 'editorbe.deploy.bot'
  ) {
    return null;
  }
  const code = notification?.message?.code?.code;

  return (
    <div key={notification.timestamp} className='event-container'>
      <div className='event-heading'>
        <div className='event-circle' />
        {notification.timestamp && (
          <>
            <Icon size='sm' {...getIconProps(notificationType)} />
            <span>
              {tsToString(notification.timestamp)}{' '}
              {notificationType === 'warn'
                ? ' — ' + localize.translate('tasks warning')
                : typeof code === 'string' &&
                  (isReportGeneration(code) || isLogsDownload(code)) &&
                  ' - ' + localize.translate(`tasks step ${deprefixTaskCode(code)}`)}
            </span>
          </>
        )}
      </div>
      {notification?.message?.code?.text && (
        <div className='event-message'>
          <div className='event-line' />
          <div className='event-message__text'>
            {localize.translate(`notifications ${notification?.message?.code?.text}`)}
          </div>
        </div>
      )}
      {notification?.message?.data?.output && (
        <div className='event-message'>
          <div className='event-line' />
          <div className='event-message__text'>
            {notification.message.code?.code?.includes('finished')
              ? localize.translate('Bot deploy success', notification.taskId)
              : notification?.message?.data?.output}
          </div>
        </div>
      )}
    </div>
  );
};
