import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TJBlock } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { getNewNameWithIndex, scrollToTargetGlobal$, joinPaths } from 'modules/JGraph/utils/stageUtils';

import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { addNewState } from 'reducers/JGraph.reducer/index';
import { createState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { getLastPartOfPath } from 'modules/JGraph/utils/themesUtils';

const addNewStateWithSave = createAsyncThunk(
  'JGraph/addNewStateWithSave',
  async (
    data: {
      screenPath: string;
      addingBlock?: TJBlock['tagName'];
      parentStatePath?: string;
      theme?: string;
      blocks?: TJBlock[];
      position?: { x: number; y: number };
      saveWithoutSetEdit?: boolean;
      setEdit?: boolean;
    },
    thunkAPI
  ) => {
    const oldState = thunkAPI.getState() as RootState;
    const { saveWithoutSetEdit, ...innerData } = data;

    const parentState = innerData.parentStatePath
      ? findScreenByPath(innerData.parentStatePath, oldState.JGraphReducer.graph.blocks)
      : undefined;
    const theme = innerData.theme || parentState?.theme || oldState.JGraphReducer.selectedTheme?.value || '/';

    const parentPath = parentState?.path || theme || '/';
    const newScreenPath = joinPaths(getLastPartOfPath(innerData.screenPath), parentPath);
    innerData.screenPath = getLastPartOfPath(
      getNewNameWithIndex(
        newScreenPath,
        oldState.JGraphReducer.graph.blocks.map(el => el.path)
      )
    );
    thunkAPI.dispatch(addNewState({ ...innerData, theme }));
    const state = thunkAPI.getState() as RootState;

    if (data.setEdit) {
      const justAddedBlock = state.JGraphReducer.editMenuBlock;
      if (justAddedBlock) {
        mainSave$.next({
          type: 'createState',
          path: justAddedBlock.screen.path,
          action: () =>
            thunkAPI.dispatch(
              createState({
                ...justAddedBlock.screen,
                parentStatePath: innerData.parentStatePath,
              })
            ),
        });
        setTimeout(() => {
          scrollToTargetGlobal$.next({ targetPathId: justAddedBlock.screen.pathId });
        }, 0);
      }
    }
    if (saveWithoutSetEdit) {
      const screenToSave = findScreenByPath(newScreenPath, state.JGraphReducer.graph.blocks);
      if (screenToSave) {
        mainSave$.next({
          type: 'createState',
          path: screenToSave.path,
          action: () => thunkAPI.dispatch(createState(screenToSave)),
        });
      }
    }
  }
);

export default addNewStateWithSave;
