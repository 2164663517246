import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateJThemeData } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi } from '../utils';
import { closeThemeCreationMenu, createThemeInState, deleteThemeInState } from '..';
import { getValidKonvaName } from '../Graph';

const createTheme = createAsyncThunk('JGraph/createTheme', async (createJThemeData: CreateJThemeData, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  thunkAPI.dispatch(closeThemeCreationMenu());
  thunkAPI.dispatch(
    createThemeInState({
      theme: {
        ...createJThemeData.themeToCreate,
        filenames: [createJThemeData.file?.filename || ''],
        mainFileName: createJThemeData.file?.filename || '',
        x: 0,
        y: 0,
        pathId: getValidKonvaName(createJThemeData.themeToCreate.value),
        canRender: false,
      },
    })
  );

  const action = async () => {
    await GraphApi.createTheme(userAccountId || accountId, projectShortName, [createJThemeData]);

    scrollToTargetGlobal$.next({
      targetPathId: getValidKonvaName(createJThemeData.themeToCreate.value),
      type: 'theme',
    });
    await thunkAPI.dispatch(getJGraphVisuals());
    await thunkAPI.dispatch(getGraph({}));
  };

  const actionPromise = action();
  mainSave$.next({ type: 'createTheme', path: createJThemeData.themeToCreate.value, action: () => actionPromise });
  await actionPromise;
});

export const revertCreateTheme = createAsyncThunk(
  'JGraph/revertCreateTheme',
  async (revertEvent: RevertEvent<CreateJThemeData>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const theme = state.JGraphReducer.graph.themes.find(
      theme => theme.value === revertEvent.payload.themeToCreate.value
    );
    if (!theme) return;

    thunkAPI.dispatch(deleteThemeInState({ themeValue: theme.value }));

    const action = async () => {
      await GraphApi.deleteTheme(userAccountId || accountId, projectShortName, {
        targetTheme: theme.value,
        files: theme.filenames.map(filename => ({
          filename,
          lastModification: state.JGraphReducer.graph.files[filename],
        })),
      });
      await thunkAPI.dispatch(getJGraphVisuals());
      await thunkAPI.dispatch(getGraph({}));
    };

    const actionPromise = action();
    mainSave$.next({ type: 'deleteTheme', path: theme.value, action: () => actionPromise });
    await actionPromise;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertCreateTheme, createTheme);
