export type TreeNode = {
  id: string;
  nodeId: string;
  name: string;
  enabled: boolean;

  isFolder: boolean;
  isGhostNode?: boolean;
  isInRoot: boolean;

  parentId?: string;
  depthLevel?: number;
  _containIds?: Set<string>;
  children?: TreeNode[];
};

export type TreeDataset<ELEMENT extends TreeNode> = ELEMENT[];

export type ErrorMessageTooltipType = {
  text?: string;
  node?: HTMLElement;
  direction?: DirectionType;
  position?: PositionType;
};

export type PositionType = {
  top: number;
  left: number;
};

export type DirectionType = 'top' | 'right' | 'bottom' | 'left';

export enum DragTypes {
  LEAF = 'LEAF',
  BRANCH = 'BRANCH',
}
