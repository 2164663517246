import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { TagNames, TJBlock } from '../../utils/types';
import { ReactionItem, ReactionItemProps } from '../../view/RightSideMenu/settings/ScreenBlocksList/ReactionItem';
import { TSortableItem } from '../../view/RightSideMenu/types';
import { useRightSideMenuContext } from '../../view/RightSideMenu';

import { setEditMenuBlock } from '../../../../reducers/JGraph.reducer';
import React, { ComponentType } from 'react';
import { useRenderConditionContext } from '../../view/RightSideMenu/settings/ConditionSettings';

const SortableItem = SortableElement(
  ({
    block,
    blockIndex,
    onClick,
    setOpenDelete,
    withDrag = true,
    parentBlocks = [],
    componentType = ReactionItem,
    disabledByLicense,
  }: {
    block: TJBlock;
    blockIndex: number;
    parentBlocks: TJBlock[];
    onClick?: () => unknown;
    setOpenDelete: (index: number | undefined) => unknown;
    withDrag?: boolean;
    componentType?: ComponentType<ReactionItemProps>;
    disabledByLicense?: boolean;
  }) => {
    const Component = componentType;

    return (
      <Component
        parentBlocks={parentBlocks}
        block={block}
        blockIndex={blockIndex}
        withDrag={withDrag}
        onClick={onClick}
        setOpenDelete={setOpenDelete}
        disabled={disabledByLicense}
      />
    );
  }
);

export const SortableReactionsContainer = SortableContainer(
  ({
    sortableBlocks,
    setOpenDelete,
    withDrag,
    isFromNewIntentsMenu = false,
    'data-test-id': dataTestId,
    disabled,
  }: {
    sortableBlocks: TSortableItem[];
    setOpenDelete: (index: number | undefined) => unknown;
    withDrag?: boolean;
    isFromNewIntentsMenu?: boolean;
    'data-test-id'?: string;
    disabled?: boolean;
  }) => {
    const {
      editMenuScreen,
      isForCampaign,
      onSetToEdit,
      dispatch,
      setWasFromNewIntentsMenu,
    } = useRightSideMenuContext();
    const { prefix } = useRenderConditionContext();

    if (sortableBlocks.length === 0) return null;
    return (
      <div className='ScreenBlocksList-sortable-container' data-test-id={dataTestId}>
        {sortableBlocks.map((descriptor, index) => (
          <SortableItem
            parentBlocks={descriptor.parentBlocks}
            setOpenDelete={setOpenDelete}
            block={descriptor.block}
            index={index}
            withDrag={withDrag}
            componentType={descriptor.componentType}
            blockIndex={descriptor.blockIndex}
            key={`${prefix}_${descriptor.block.tagName}_${index}`}
            onClick={() => {
              if (isForCampaign && onSetToEdit) return onSetToEdit(index);
              setWasFromNewIntentsMenu(isFromNewIntentsMenu);
              dispatch(
                setEditMenuBlock({
                  screen: editMenuScreen,
                  jBlockIndex: [TagNames.else, TagNames.elseif, TagNames.if].includes(descriptor.block.tagName)
                    ? undefined
                    : descriptor.blockIndex,
                  path:
                    [TagNames.else, TagNames.elseif, TagNames.if].includes(descriptor.block.tagName) && !prefix
                      ? `${descriptor.blockIndex}_if_`
                      : prefix,
                })
              );
            }}
            disabledByLicense={disabled}
          />
        ))}
      </div>
    );
  }
);
