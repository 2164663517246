// tslint:disable
// @ts-nocheck
/**
 * TEST WIDGET REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * TestWidgetApi - axios parameter creator
 * @export
 */
export const TestWidgetApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Publish test widget and get its import script
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [forceDeploy] Force deploy
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publish(projectId: string, forceDeploy?: boolean, skipTests?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling publish.'
        );
      }
      const localVarPath = `/botconfig/testwidget/{projectId}/publish`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (forceDeploy !== undefined) {
        localVarQueryParameter['forceDeploy'] = forceDeploy;
      }

      if (skipTests !== undefined) {
        localVarQueryParameter['skipTests'] = skipTests;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TestWidgetApi - functional programming interface
 * @export
 */
export const TestWidgetApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Publish test widget and get its import script
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [forceDeploy] Force deploy
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publish(
      projectId: string,
      forceDeploy?: boolean,
      skipTests?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = TestWidgetApiAxiosParamCreator(configuration).publish(
        projectId,
        forceDeploy,
        skipTests,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TestWidgetApi - factory interface
 * @export
 */
export const TestWidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Publish test widget and get its import script
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [forceDeploy] Force deploy
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publish(projectId: string, forceDeploy?: boolean, skipTests?: boolean, options?: any): AxiosPromise<string> {
      return TestWidgetApiFp(configuration).publish(projectId, forceDeploy, skipTests, options)(axios, basePath);
    },
  };
};

/**
 * TestWidgetApi - object-oriented interface
 * @export
 * @class TestWidgetApi
 * @extends {BaseAPI}
 */
export class TestWidgetApi extends BaseAPI {
  /**
   *
   * @summary Publish test widget and get its import script
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [forceDeploy] Force deploy
   * @param {boolean} [skipTests] Skip tests flag.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestWidgetApi
   */
  public publish(projectId: string, forceDeploy?: boolean, skipTests?: boolean, options?: any) {
    return TestWidgetApiFp(this.configuration).publish(
      projectId,
      forceDeploy,
      skipTests,
      options
    )(this.axios, this.basePath);
  }
}
