import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { Subject } from 'rxjs';
import { Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import { useAppSelector } from 'storeHooks';
import { getThemeName } from 'modules/JGraph/utils/themesUtils';
import { findScreenByPath, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { JGraphTheme } from 'reducers/JGraph.reducer/types';
import { normalizePath, joinPaths } from 'modules/JGraph/utils/stageUtils';

import { StageObservablesContextType, useStageObservableContext } from '../../contexts/StageObservablesProvider';

import styles from './styles.module.scss';

export const goUpSubject$ = new Subject();

function getPathTestId(pathItems: string[], index: number, themePath: string) {
  const stagePath = pathItems.slice(0, index + 1).join('/') || '/';
  const baseId = `JGraph.Breadcrumbs.${joinPaths(stagePath, themePath)}.${index}`;
  const isLast = pathItems.length - 1 === index ? '.last' : '';
  return `${baseId}${isLast}`;
}

const PathItem: FC<{
  pathItems: string[];
  currentIndex: number;
  setSelectedGroup: StageObservablesContextType['setSelectedGroup'];
  theme?: JGraphTheme;
}> = ({ pathItems, currentIndex, setSelectedGroup, theme }) => {
  const pathItem = pathItems[currentIndex];
  const themePath = theme?.value || '';
  const setGroupPath = useCallback(() => {
    if (pathItem === '') {
      setSelectedGroup({
        path: undefined,
        pathId: undefined,
      });
    } else {
      let path: string[] = [];
      if (themePath) {
        path.push(themePath);
      }
      for (let i = 0; i <= currentIndex; i += 1) {
        path.push(pathItems[i]);
      }
      const constructPath = normalizePath(path.join('/'));
      setSelectedGroup({
        path: constructPath,
        pathId: getValidKonvaName(constructPath),
      });
    }
  }, [currentIndex, pathItem, pathItems, setSelectedGroup, themePath]);

  useEffect(() => {
    const sub = goUpSubject$.subscribe(() => {
      if (currentIndex === pathItems.length - 2) {
        setGroupPath();
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [currentIndex, pathItems.length, setGroupPath]);

  return (
    <>
      <span
        data-test-id={getPathTestId(pathItems, currentIndex, themePath)}
        onClick={setGroupPath}
        className={cn(styles.CurrentGroupPath, 'btn btn-link')}
        key={pathItem === '' ? t('CurrentGroupPath:Main_Scenario') : `${pathItem}${currentIndex}`}
      >
        <span>{pathItem}</span>
      </span>
      <span>/</span>
    </>
  );
};

export const CurrentGroupPath: FC = React.memo(() => {
  const { screens, selectedTheme } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
    selectedTheme: state.JGraphReducer.selectedTheme,
  }));
  const { selectedGroupPath, setSelectedGroup } = useStageObservableContext();
  const prevReduceValue = useRef<string[]>([]);

  const selectedGroupPathFromTheme = useMemo(() => {
    if (!selectedTheme?.value || !selectedGroupPath?.startsWith(selectedTheme.value)) return selectedGroupPath;
    let pathInTheme = selectedGroupPath?.substring(selectedTheme.value.length);
    pathInTheme = pathInTheme?.replace(/^\//, '');
    return pathInTheme;
  }, [selectedGroupPath, selectedTheme]);

  const pathItems = useMemo(() => {
    return (selectedGroupPathFromTheme || '').split('/').reduce((acc, curr, index, array) => {
      let value = curr;
      if (value) {
        if (prevReduceValue.current.length > 0) {
          value = [...prevReduceValue.current, curr].join('/');
        }
        let fullPath = normalizePath([selectedTheme?.value || '', ...acc, value].join('/'));

        const target = findScreenByPath(fullPath, screens);
        if (target) {
          prevReduceValue.current = [];
          acc.push(value);
        } else {
          prevReduceValue.current.push(curr);
          if (index === array.length - 1) {
            acc.push(value);
          }
        }
      } else {
        acc.push(curr);
      }

      return acc.filter(Boolean);
    }, [] as string[]);
  }, [screens, selectedGroupPathFromTheme, selectedTheme?.value]);
  prevReduceValue.current = [];

  const onThemeClick = useCallback(() => {
    setSelectedGroup({
      path: undefined,
      pathId: undefined,
    });
  }, [setSelectedGroup]);

  const bound = document.getElementById('JGraphBoundingPlace');
  if (!bound) return null;

  if (!selectedTheme) return createPortal(null, bound);

  return createPortal(
    <div className='JGraphPath'>
      <span className={styles.pathSeparator}>/</span>
      {selectedTheme && (
        <>
          <div
            data-test-id={`JGraph.Breadcrumbs.Theme.${selectedTheme.value}`}
            className={cn(styles.themeContainer, { [styles.active]: !!pathItems?.length })}
            onClick={onThemeClick}
          >
            <Icon name='farShapes' />
            {getThemeName(selectedTheme.value)}
          </div>
        </>
      )}
      {pathItems.length > 0 && <span className='pathSeparator'>/</span>}
      {pathItems.map((pathItem, index) => {
        if (index < pathItems.length - 1) {
          return (
            <PathItem
              key={pathItem}
              pathItems={pathItems}
              currentIndex={index}
              setSelectedGroup={setSelectedGroup}
              theme={selectedTheme}
            />
          );
        }
        return (
          <span data-test-id={getPathTestId(pathItems, pathItems.length - 1, selectedTheme?.value)} key={pathItem}>
            {pathItem}
          </span>
        );
      })}
    </div>,
    bound
  );
});
