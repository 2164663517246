import React from 'react';

import { t } from 'localization';

import CloudSyncOkIcon from 'img/jgraph/cloud-sync-ok.png';

import Tooltip from '../index';
import styles from './styles.module.scss';

interface SavedTooltipProps {
  onClose: () => void;
}
const SavedTooltip = ({ onClose }: SavedTooltipProps) => {
  return (
    <Tooltip className={styles.SavedTooltip} onClose={onClose}>
      <img src={CloudSyncOkIcon} alt='' width={121} loading='eager' />
      <span className={styles.SavedTooltip__title}>{t('StatusIndicator:SavedTooltip:Title')}</span>
      <span className={styles.SavedTooltip__description}>{t('StatusIndicator:SavedTooltip:Description')}</span>
    </Tooltip>
  );
};

export default React.memo(SavedTooltip);
