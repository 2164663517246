export const faqLocalization = {
    'FAQ:TreeHeader': {
        eng: 'QUESTIONS',
        ru: 'ВОПРОСЫ',
        pt: 'PERGUNTAS',
    },
    'Intent:switchActivityLabelEnable': {
        eng: 'Enabled',
        ru: 'Включен',
        pt: 'Ativado',
    },
    'Intent:switchActivityLabelDisable': {
        eng: 'Disabled',
        ru: 'Отключен',
        pt: 'Desabilitado',
    },
    'FAQ:Intent:Field:Question': {
        eng: 'Question',
        ru: 'Вопрос',
        pt: 'Pergunta',
    },
    'FAQ:Intent:Field:Answer': {
        eng: 'Answer',
        ru: 'Ответ',
        pt: 'Resposta',
    },
    'FAQ:Intent:BaseQuestionName': {
        eng: 'New question',
        ru: 'Новый вопрос',
        pt: 'Nova pergunta',
    },
    'FAQ:Intent:BaseGroupName': {
        eng: 'New group',
        ru: 'Новая группа',
        pt: 'Novo grupo',
    },
    'FAQ:Intent:EmptyGroup': {
        eng: 'Group does not contain any questions',
        ru: 'В группе нет вопросов',
        pt: 'O grupo não contém perguntas',
    },
    'FAQPage:Field:Question:Placeholder': {
        eng: 'New question',
        ru: 'Новый вопрос',
        pt: 'Nova pergunta',
    },
    'FAQPage:Field:Answer:Placeholder': {
        eng: 'Answer text',
        ru: 'Текст ответа',
        pt: 'Texto da resposta',
    },
    'FAQ:FAQCreation': {
        eng: 'Create knowledge base',
        ru: 'Создание базы знаний',
        pt: 'Criar base de conhecimento',
    },
    'FAQ:CreateFAQDescription': {
        eng: 'The knowledge base provides a variety of chatbot or voice assistant modules \n that can help you understand customers and achieve your business goals.',
        ru: 'В базе знаний собраны различные модули для чат-бота или голосового ассистента, \n которые позволят понимать ваших клиентов и решать бизнес-задачи.',
        pt: 'A base de conhecimento fornece uma variedade de módulos de chatbot ou assistente de voz \n que podem ajudá-lo a entender os clientes e atingir seus objetivos de negócios.',
    },
    'FAQ:AddNewSkill': {
        eng: 'Add new module',
        ru: 'Добавьте новый модуль',
        pt: 'Adicione novo módulo',
    },
    'FAQ:AddSkill': {
        eng: 'Add module',
        ru: 'Добавить модуль',
        pt: 'Adicione o módulo',
    },
    'FAQ:AddNewSkillDescription': {
        eng: 'Upload a file with a module, create it from scratch, or choose one of the templates.',
        ru: 'Загрузите файл с модулем, создайте с нуля или выберите один из шаблонов.',
        pt: 'Carregue um arquivo com um módulo, crie-o do zero ou escolha um dos modelos.',
    },
    'FAQ:CreateFAQ': {
        eng: 'Add',
        ru: 'Добавить',
        pt: 'Adicionar',
    },
    'FAQ:NewQuestion': {
        eng: 'New question',
        ru: 'Новый вопрос',
        pt: 'Nova pergunta',
    },
    'FAQ:EmptyFAQ': {
        eng: 'Empty FAQ',
        ru: 'Пустой FAQ',
        pt: 'Perguntas frequentes vazias',
    },
    'FAQ:NewFAQ': {
        eng: 'New FAQ',
        ru: 'Новый FAQ',
        pt: 'Nova pergunta frequente',
    },
    'FAQ:DatabasesWork': {
        eng: 'Working with databases',
        ru: 'Работа по базам данных',
        pt: 'Trabalhando com bancos de dados',
    },
    'FAQ:CreateError': {
        eng: 'Failed to create FAQ',
        ru: 'Ошибка создания FAQ',
        pt: 'Falha ao criar pergunta frequente',
    },
    'FAQ:RenameError:AlreadyExists': {
        eng: 'A group with this name already exists',
        ru: 'Группа с таким названием уже существует',
        pt: 'Já existe um grupo com este nome',
    },
    'FAQ:SelectTemplate': {
        eng: 'Choose a template',
        ru: 'Выберите шаблон',
        pt: 'Escolha um modelo',
    },
    'FAQ:contextMenuRename': {
        eng: 'Rename',
        ru: 'Переименовать',
        pt: 'Renomear',
    },
    'FAQ:contextMenuTurnOffGroup': {
        eng: 'Disable group',
        ru: 'Отключить группу',
        pt: 'Desativar grupo',
    },
    'FAQ:contextMenuTurnOffGroup_many': {
        eng: 'Disable groups',
        ru: 'Отключить группы',
        pt: 'Desativar grupos',
    },
    'FAQ:contextMenuTurnOnGroup': {
        eng: 'Enable group',
        ru: 'Включить группу',
        pt: 'Ativar grupo',
    },
    'FAQ:contextMenuTurnOnGroup_many': {
        eng: 'Enable groups',
        ru: 'Включить группы',
        pt: 'Ativar grupos',
    },
    'FAQ:contextMenuCopyGroup': {
        eng: 'Duplicate group',
        ru: 'Дублировать группу',
        pt: 'Duplicar grupo',
    },
    'FAQ:contextMenuCopyGroup_many': {
        eng: 'Duplicate groups',
        ru: 'Дублировать группы',
        pt: 'Duplicar grupos',
    },
    'FAQ:contextMenuUngroup': {
        eng: 'Ungroup',
        ru: 'Разгруппировать',
        pt: 'Desagrupar',
    },
    'FAQ:contextMenuExportNode': {
        eng: 'Export',
        ru: 'Экспортировать',
        pt: 'Exportar',
    },
    'FAQ:contextMenuExpor': {
        eng: 'Export',
        ru: 'Экспортировать',
        pt: 'Exportar',
    },
    'FAQ:contextMenuDeleteGroup': {
        eng: 'Delete group',
        ru: 'Удалить группу',
        pt: 'Excluir grupo',
    },
    'FAQ:contextMenuDeleteGroup_many': {
        eng: 'Delete groups',
        ru: 'Удалить группы',
        pt: 'Excluir grupos',
    },
    'FAQ:contextMenuTurnOff': {
        eng: 'Disable',
        ru: 'Отключить',
        pt: 'Desativar',
    },
    'FAQ:contextMenuTurnOn': {
        eng: 'Enable',
        ru: 'Включить',
        pt: 'Ativar',
    },
    'FAQ:contextMenuCopy': {
        eng: 'Duplicate',
        ru: 'Дублировать',
        pt: 'Duplicar',
    },
    'FAQ:contextMenuGroup': {
        eng: 'Group',
        ru: 'Сгруппировать',
        pt: 'Grupo',
    },
    'FAQ:contextMenuDelete': {
        eng: 'Delete',
        ru: 'Удалить',
        pt: 'Excluir',
    },
    'FAQ:help popup title': {
        eng: 'Tip',
        ru: 'Подсказка',
        pt: 'Dica',
    },
    'FAQ:helpPopupQuestionMassSelection': {
        eng: 'To select several questions, use the Shift key.',
        ru: 'Чтобы выбрать несколько вопросов, используйте клавишу Shift.',
        pt: 'Para selecionar várias perguntas, use a tecla Shift.',
    },
    'FAQ:helpPopupQuestionSeparateSelection': {
        eng: 'To select specific questions, use the Ctrl/Cmd key.',
        ru: 'Чтобы выбрать определенные вопросы, используйте клавишу Ctrl/Cmd.',
        pt: 'Para selecionar perguntas específicas, use a tecla Ctrl/Cmd.',
    },
    'FAQ:helpPopupDragQuestion': {
        eng: 'To move a question, drag it.',
        ru: 'Чтобы переместить вопрос, перетащите его.',
        pt: 'Para mover uma pergunta, arraste-a.',
    },
    'FAQ:helpPopupUniteQuestionsInGroups': {
        eng: 'To group questions, drag one question on the other.',
        ru: 'Чтобы сгруппировать вопросы, перетащите один вопрос на другой.',
        pt: 'Para agrupar perguntas, arraste uma pergunta sobre a outra.',
    },
    'FAQ:helpPopupSeeAvailableActions': {
        eng: 'To view additional actions for a module, a group, or a question, right-click on it.',
        ru: 'Чтобы посмотреть доступные действия для модуля, группы или вопроса, щелкните правой кнопкой мыши.',
        pt: 'Para visualizar ações adicionais para um módulo, um grupo ou uma pergunta, clique com o botão direito.',
    },
    'FAQ:deleteModal:Message:DeleteQuestion': {
        eng: 'Are you sure you want to delete this question?',
        ru: 'Вы действительно хотите удалить этот вопрос?',
        pt: 'Tem certeza de que deseja excluir essa pergunta?',
    },
    'FAQ:deleteModal:Message:DeleteQuestions': {
        eng: 'Are you sure you want to delete these questions?',
        ru: 'Вы действительно хотите удалить эти вопросы?',
        pt: 'Tem certeza de que deseja excluir essas perguntas?',
    },
    'FAQ:deleteModal:Message:DeleteGroup': {
        eng: 'Are you sure you want to delete this group with its questions?',
        ru: 'Вы действительно хотите удалить эту группу вместе с вопросами?',
        pt: 'Tem certeza de que deseja excluir esse grupo com suas perguntas?',
    },
    'FAQ:deleteModal:Message:DeleteGroups': {
        eng: 'Are you sure you want to delete these groups with their questions?',
        ru: 'Вы действительно хотите удалить эти группы вместе с вопросами?',
        pt: 'Tem certeza de que deseja excluir esses grupos com suas perguntas?',
    },
    'FAQ:deleteModal:Message:DeleteGroupsAndQuestions': {
        eng: 'Are you sure you want to delete these groups and questions?',
        ru: 'Вы действительно хотите удалить эти группы и вопросы?',
        pt: 'Tem certeza de que deseja excluir esses grupos e perguntas?',
    },
    'FAQ:deleteModal:Message:DeleteQuestionsSelected $[1]': {
        eng: 'Questions selected: $[1]',
        ru: 'Выбрано вопросов: $[1]',
        pt: 'Perguntas selecionadas: $[1]',
    },
    'FAQ:deleteModal:Message:DeleteQuestionsInGroupsSelected $[1]': {
        eng: 'Questions in the group: $[1]',
        ru: 'Вопросов в группе: $[1]',
        pt: 'Perguntas no grupo: $[1]',
    },
    'FAQ:deleteModal:Message:DeleteQuestionsInGroupSelected $[1]': {
        eng: 'Questions in these groups: $[1]',
        ru: 'Вопросов в этих группах: $[1]',
        pt: 'Perguntas nestes grupos: $[1]',
    },
    'FAQ:deleteModal:Message:DeleteGroupsSelected $[1]': {
        eng: 'Groups selected: $[1]',
        ru: 'Выбрано групп: $[1]',
        pt: 'Grupos selecionados: $[1]',
    },
    'FAQ:deleteModal:Message:DeleteUngroupedQuestionsSelected $[1]': {
        eng: 'Ungrouped questions selected: $[1]',
        ru: 'Выбрано вопросов без группы: $[1]',
        pt: 'Perguntas não agrupadas selecionadas: $[1]',
    },
    'FAQ:deleteModal:Title:DeleteQuestions': {
        eng: 'Delete questions',
        ru: 'Удалить вопросы',
        pt: 'Excluir perguntas',
    },
    'FAQ:deleteModal:Title:DeleteGroupsAndQuestions': {
        eng: 'Delete groups and questions',
        ru: 'Удалить группы и вопросы',
        pt: 'Excluir grupos e perguntas',
    },
    'FAQ:deleteModal:Button:Cancel': {
        eng: 'Cancel',
        ru: 'Отмена',
        pt: 'Cancelar',
    },
    'FAQ:deleteModal:Button:Delete': {
        eng: 'Delete',
        ru: 'Удалить',
        pt: 'Excluir',
    },
    'FAQ:cdqaModal:title': {
        eng: 'Set up the CDQA module',
        ru: 'Настройка модуля CDQA',
        pt: 'Configurar o módulo CDQA',
    },
    'FAQ:cdqaModal:subtitle': {
        eng: 'You can create only one module.',
        ru: 'Вы можете создать только один модуль.',
        pt: 'Você pode criar apenas um módulo.',
    },
    'FAQ:cdqaModal:linkText': {
        eng: 'About module',
        ru: 'О модуле',
        pt: 'Sobre o módulo',
    },
    'FAQ:cdqaModal:nameLabel': {
        eng: 'CDQA name',
        ru: 'Название CDQA',
        pt: 'Nome do CDQA',
    },
    'FAQ:cdqaModal:langOptionRuEn': {
        eng: 'Russian and English',
        ru: 'Русский и английский',
        pt: 'Russo e inglês',
    },
    'FAQ:cdqaModal:langOptionOther': {
        eng: 'Other languages',
        ru: 'Другие языки',
        pt: 'Outros idiomas',
    },
    'FAQ:Header:AddGroup': {
        eng: 'Add group',
        ru: 'Добавить группу',
        pt: 'Adicionar grupo',
    },
    'FAQ:Header:AddQuestion': {
        eng: 'Add question',
        ru: 'Добавить вопрос',
        pt: 'Adicionar pergunta',
    },
    'Errors:caila.intents.intent_with_provided_path_already_exists_group': {
        eng: 'A group with the provided path already exists',
        ru: 'Группа с таким названием уже существует',
        pt: 'Já existe um grupo com o caminho fornecido',
        cn: '于该路径的意图已存在',
    },
    'Errors:caila.intents.intent_with_provided_path_already_exists_question': {
        eng: 'A question with the provided path already exists',
        ru: 'Вопрос с таким названием уже существует',
        pt: 'Já existe uma pergunta com o caminho fornecido',
        cn: '于该路径的意图已存在',
    },
    'FAQ:importModal:Title:ImportFile': {
        eng: 'Import FAQ file',
        ru: 'Импорт файла FAQ',
        pt: 'Importar arquivo de perguntas frequentes',
    },
    'FAQ:importModal:ImportInfo': {
        eng: 'We will create and set up an FAQ using your file.',
        ru: 'Мы создадим и настроим FAQ по вашему файлу.',
        pt: 'Vamos criar e configurar uma pergunta frequente usando seu arquivo.',
    },
    'FAQ:importModal:ImportExampleInfo': {
        eng: 'Before uploading a file, take a look at our',
        ru: 'Перед загрузкой файла рекомендуем ознакомиться с',
        pt: 'Antes de enviar um arquivo, dê uma olhada em nossos',
    },
    'FAQ:importModal:ImportExampleInfo:link': {
        eng: 'spreadsheet example.',
        ru: 'примером таблицы.',
        pt: 'exemplo de planilha.',
    },
    'FAQ:importModal:FileUpload:upload': {
        eng: 'Upload a file',
        ru: 'Загрузите файл',
        pt: 'Carregar um arquivo',
    },
    'FAQ:importModal:FileUpload:formats': {
        eng: 'Supported formats: XLS, XLSX',
        ru: 'Поддерживаемые форматы: XLS, XLSX',
        pt: 'Formatos suportados: XLS, XLSX',
    },
    'FAQ:importModal:FileUpload:formats:error': {
        eng: 'Allowed file formats: XLS, XLSX',
        ru: 'Допустимые форматы: XLS, XLSX',
        pt: 'Formatos de arquivo permitidos: XLS, XLSX',
    },
    'FAQ:importModal:FileUpload:maxSize': {
        eng: 'File size cannot exceed 3 MB.',
        ru: 'Размер файла не должен превышать 3 Мб.',
        pt: 'O tamanho do arquivo não pode exceder 3 MB',
    },
    'FAQ:importModal:FileUpload:maxRows': {
        eng: 'Maximum number of rows: 10,000',
        ru: 'Максимальное количество строк: 10 000',
        pt: 'Número máximo de linhas: 10.000',
    },
    'FAQ:importModal:Title:process': {
        eng: 'File processing',
        ru: 'Обработка файла',
        pt: 'Processamento de arquivo',
    },
    'FAQ:importModal:process:pending': {
        eng: 'File data processing will take some time.',
        ru: 'Обработка данных файла займет некоторое время.',
        pt: 'O processamento dos dados do arquivo levará algum tempo.',
    },
    'FAQ:importModal:error': {
        eng: 'Failed to upload the file',
        ru: 'Ошибка загрузки файла',
        pt: 'Falha ao carregar o arquivo',
    },
    'FAQ:importModal:success': {
        eng: 'File uploaded',
        ru: 'Файл загружен',
        pt: 'Arquivo enviado',
    },
    'FAQ:importModal:Title:notCreated': {
        eng: 'Module not created',
        ru: 'Модуль не создан',
        pt: 'Módulo não criado',
    },
    'FAQ:importModal:Title:createdWithErrors': {
        eng: 'Module created with errors',
        ru: 'Модуль создан с ошибками',
        pt: 'Módulo criado com erros',
    },
    'FAQ:importModal:error:critical:fixErrors': {
        eng: 'Correct errors in the file and upload it again. Before uploading, take a look at our spreadsheet example.',
        ru: 'Исправьте ошибки в файле и загрузите его заново. Перед загрузкой ознакомьтесь с примером заполненного файла.',
        pt: 'Corrija os erros no arquivo e carregue-o novamente. Antes de carregar, dê uma olhada no nosso exemplo de planilha.',
    },
    'FAQ:importModal:error:createdWithErrors:fixErrors': {
        eng: 'Upload a new file or go to the created module to correct the errors. You cannot test and use the knowledge base if at least one module contains errors.',
        ru: 'Загрузите новый файл или перейдите в созданный модуль для исправления ошибок. Вы не сможете тестировать или запускать базу знаний с ошибками в модуле.',
        pt: 'Carregue um novo arquivo ou acesse o módulo criado para corrigir os erros. Não é possível testar e usar a base de conhecimento se pelo menos um módulo contiver erros.',
    },
    'FAQ:importModal:error:area:title': {
        eng: 'Errors in the file',
        ru: 'Ошибки в файле',
        pt: 'Erros no arquivo',
    },
    'FAQ:importModal:button:toSkill': {
        eng: 'Go to module',
        ru: 'Перейти в модуль',
        pt: 'Acessar módulo',
    },
    'FAQ:importModal:button:replaceFile': {
        eng: 'Replace file',
        ru: 'Заменить файл',
        pt: 'Substituir arquivo',
    },
    'FAQ:importModal:button:copy': {
        eng: 'Copy',
        ru: 'Копировать',
        pt: 'Copiar',
    },
    'FAQ:Header:Search': {
        eng: 'Search',
        ru: 'Поиск',
        pt: 'Pesquisa',
    },
    'FAQ:Search:Group': {
        eng: 'Groups and questions',
        ru: 'По группам и вопросам',
        pt: 'Grupos e perguntas',
    },
    'FAQ:Search:Question': {
        eng: 'Phrases and answers',
        ru: 'По формулировкам и ответам',
        pt: 'Frases e respostas',
    },
    'FAQ:Phrases:AddAlternativePhrases': {
        eng: 'Add alternative phrases',
        ru: 'Добавить формулировки',
        pt: 'Adicionar frases alternativas',
    },
    'FAQ:Phrases:AlternativePhrases': {
        eng: 'Alternative phrases',
        ru: 'Формулировки',
        pt: 'Frases alternativas',
    },
    'FAQ:Phrases:SearchField:Placeholder': {
        eng: 'Search',
        ru: 'Поиск',
        pt: 'Pesquisa',
    },
    'FAQ:Phrase:Placeholder': {
        eng: 'Text',
        ru: 'Текст',
        pt: 'Texto',
    },
    'FAQ:Text:Placeholder': {
        eng: 'Pattern',
        ru: 'Паттерн',
        pt: 'Padrão',
    },
    'FAQ:Phrases:NoMatchesFound': {
        eng: 'No matches found',
        ru: 'Ничего не найдено',
        pt: 'Nenhuma correspondência encontrada',
    },
    'FAQ:Phrases:ChangeTypeText': {
        eng: 'Click to switch from a text to a pattern',
        ru: 'Нажмите, чтобы изменить с текста на паттерн',
        pt: 'Clique para mudar de um texto para um padrão',
    },
    'FAQ:Phrases:ChangeTypePhrase': {
        eng: 'Click to switch from a pattern to a text',
        ru: 'Нажмите, чтобы изменить с паттерна на текст',
        pt: 'Clique para mudar de um padrão para um texto',
    },
    'FAQ:Phrases:InsertTooltip': {
        eng: 'Or press Enter',
        ru: 'Или нажмите Enter',
        pt: 'Ou pressione Enter',
    },
    'FAQ:Header:Import': {
        eng: 'Import FAQ',
        ru: 'Импортировать FAQ',
        pt: 'Importar perguntas frequentes',
    },
    'FAQ:Header:Export': {
        eng: 'Export FAQ',
        ru: 'Экспортировать FAQ',
        pt: 'Exportar perguntas frequentes',
    },
    'FAQ:importModal:Title:ImportFAQ': {
        eng: 'Import FAQ',
        ru: 'Импорт FAQ',
        pt: 'Importar perguntas frequentes',
    },
    'FAQ:contextMenuExportFolder': {
        eng: 'Export group',
        ru: 'Экспортировать группу',
        pt: 'Exportar grupo',
    },
    'FAQ:importModal:infoBlock': {
        eng: 'When you upload the file, all questions and answers in the current module will be overwritten.',
        ru: 'При загрузке файла все вопросы и ответы в текущем модуле будут перезаписаны.',
        pt: 'Ao carregar o arquivo, todas as perguntas e respostas do módulo atual serão substituídas.',
    },
    'FAQ:Train:ApplyTest': {
        eng: 'Apply and test',
        ru: 'Применить и тестировать',
        pt: 'Aplicar e testar',
    },
    'FAQ:Train:ApplyChanges': {
        eng: 'Apply changes',
        ru: 'Применить изменения',
        pt: 'Aplicar alterações',
    },
    'FAQ:Train:ApplyingChanges': {
        eng: 'Applying',
        ru: 'Применение',
        pt: 'Aplicando',
    },
    'FAQ:Train:OpenWidget': {
        eng: 'Open test widget',
        ru: 'Открыть тестовый виджет',
        pt: 'Abrir widget de teste',
    },
    'FAQ:Addmodules:MaxTooltip': {
        eng: 'One project cannot have more than 50 modules',
        ru: 'В проекте может быть не больше 50 модулей',
        pt: 'Um projeto não pode ter mais de 50 módulos',
    },
    'FAQ:RenameSkill:existingName': {
        eng: 'A module with this name already exists',
        ru: 'Модуль с таким названием уже существует',
        pt: 'Já existe um módulo com este nome',
    },
    'FAQ:DeleteSkill': {
        eng: 'Delete module',
        ru: 'Удалить модуль',
        pt: 'Excluir módulo',
    },
    'FAQ:DeleteSkill:prompt': {
        eng: 'Delete the $[1] module?',
        ru: 'Вы действительно хотите удалить модуль $[1]?',
        pt: 'Excluir o módulo $[1]?',
    },
    examples: {
        eng: 'Examples',
        ru: 'Примеры',
        pt: 'Exemplos',
    },
    sampleQuestions: {
        eng: '$[1] sample questions',
        ru: 'Примеры вопросов для $[1]',
        pt: '$[1] exemplos de perguntas',
    },
    FAQEmptyTemplateDesc: {
        eng: 'The module will be created from scratch. You can fill it with any kind of questions and answers.',
        ru: 'Mодуль будет создан с нуля. Вы сможете наполнить его любыми вопросами и ответами.',
        pt: 'O módulo será criado do zero. Você pode preenchê-lo com qualquer tipo de perguntas e respostas.',
    },
    disableSkill: {
        eng: 'Disable module',
        ru: 'Отключить модуль',
        pt: 'Desativar módulo',
    },
    enableSkill: {
        eng: 'Enable module',
        ru: 'Включить модуль',
        pt: 'Ativar módulo',
    },
    'ClassificationRules:Header': {
        eng: 'Classification rules',
        ru: 'Правила классификации',
        pt: 'Regras de classificação',
    },
    'ClassificationRules:AddRule': {
        eng: 'Add rule',
        ru: 'Добавить правило',
        pt: 'Adicionar regra',
    },
    'ClassificationRules:Empty': {
        eng: 'Classification rules allow you to set additional conditions for intent recognition. Select entities or configure patterns or synonyms which should always be present in or absent from user requests.',
        ru: 'Правила классификации позволяют задать дополнительные условия распознавания интента. Выберите сущности либо задайте паттерны или синонимы, которые обязательно должны встречаться или отсутствовать во фразах клиента.',
        pt: 'As regras de classificação permitem definir condições adicionais para reconhecimento de intenção. Selecione entidades ou configure padrões ou sinônimos que sempre devem estar presentes ou ausentes nas solicitações do usuário.',
    },
    'ClassificationRules:ClientPhrase': {
        eng: 'User request:',
        ru: 'Фраза клиента:',
        pt: 'Solicitação de usuário:',
    },
    'ClassificationRules:contains': {
        eng: 'Contains',
        ru: 'Содержит',
        pt: 'Contém',
    },
    'ClassificationRules:contains_not': {
        eng: 'Does not contain',
        ru: 'Не содержит',
        pt: 'Não contém',
    },
    'ClassificationRules:chooseEntity': {
        eng: 'Select entity',
        ru: 'Выберите сущность',
        pt: 'Selecione a entidade',
    },
    'FAQ:activationNotification': {
        eng: 'For the bot to start using the FAQ modules, connect them to the script.',
        ru: 'Чтобы бот начал использовать FAQ-модули, подключите их к сценарию.',
        pt: 'Para que o bot comece a usar os módulos de perguntas frequentes, conecte-os ao script.',
    },
    'FAQ:activationNotification:button:details': {
        eng: 'Learn more',
        ru: 'Подробнее',
        pt: 'Saiba mais',
    },
    'FAQ:activationNotification:button:connect': {
        eng: 'Connect',
        ru: 'Подключить',
        pt: 'Conectar',
    },
    'FAQ:activationNotification:alertText': {
        eng: 'The KnowledgeBase state is now a part of the script. It can be accessed from anywhere in the dialog.',
        ru: 'Стейт KnowledgeBase добавлен в сценарий. В него можно перейти из любого места диалога.',
        pt: 'O estado KnowledgeBase agora faz parte do script. Ele pode ser acessado de qualquer lugar na caixa de diálogo.',
    },
    'AnswerBlocks:invalid_url_format': {
        eng: 'Invalid URL format',
        ru: 'Некорректный формат URL',
        pt: 'Formato de URL inválido',
    },
    'AnswerBlocks:delete_block_title': {
        eng: 'Delete message',
        ru: 'Удалить сообщение',
        pt: 'Excluir mensagem',
    },
    'AnswerBlocks:delete_block_text': {
        eng: 'Are you sure you want to delete $[1]?',
        ru: 'Вы уверены, что хотите удалить $[1]?',
        pt: 'Tem certeza de que deseja excluir $[1]?',
    },
    'AnswerBlocks:type_message': {
        eng: 'Message',
        ru: 'Сообщение',
        pt: 'Mensagem',
    },
    'AnswerBlocks:type_file': {
        eng: 'File',
        ru: 'Файл',
        pt: 'Arquivo',
    },
    'AnswerBlocks:type_audio': {
        eng: 'Audio',
        ru: 'Аудио',
        pt: 'Áudio',
    },
    'AnswerBlocks:type_image': {
        eng: 'Image',
        ru: 'Изображение',
        pt: 'Imagem',
    },
    'AnswerBlocks:type_text': {
        eng: 'Text message',
        ru: 'Текстовое сообщение',
        pt: 'Mensagem de texto',
    },
    'AnswerBlocks:type_text_button': {
        eng: 'Text',
        ru: 'Текст',
        pt: 'Texto',
    },
    'AnswerModal:title_image': {
        eng: 'Upload an image',
        ru: 'Загрузка изображения',
        pt: 'Carregar uma imagem',
    },
    'AnswerModal:title_audio': {
        eng: 'Upload an audio',
        ru: 'Загрузка аудио',
        pt: 'Carregar um áudio',
    },
    'AnswerModal:title_file': {
        eng: 'Upload a file',
        ru: 'Загрузка файла',
        pt: 'Carregar um arquivo',
    },
    'AnswerModal:subtext_file': {
        eng: 'Supported formats: XLS, PDF',
        ru: 'Поддерживаемые форматы: XLS, PDF',
        pt: 'Formatos suportados: XLS, PDF',
    },
    'AnswerModal:subtext_audio': {
        eng: 'Supported formats: WAV, MP3',
        ru: 'Поддерживаемые форматы: WAV, MP3',
        pt: 'Formatos suportados: WAV, MP3',
    },
    'AnswerModal:info_audio': {
        eng: 'Make sure your audio file meets all <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">the requirements of the channel</a>.',
        ru: 'Убедитесь, что ваш аудио-файл соответствует всем <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">условиям канала</a>.',
        pt: 'Certifique-se de que seu arquivo de áudio atenda a todos <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">os requisitos do canal</a>.',
    },
    'AnswerModal:info_file': {
        eng: 'Make sure your file meets all <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">the requirements of the channel</a>.',
        ru: 'Убедитесь, что ваш файл соответствует всем <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">условиям канала</a>.',
        pt: 'Certifique-se de que seu arquivo atenda a todos <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">os requisitos do canal</a>.',
    },
    'AnswerModal:info_image': {
        eng: 'Make sure your image meets all <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">the requirements of the channel</a>.',
        ru: 'Убедитесь, что ваше изображение соответствует всем <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">условиям канала</a>.',
        pt: 'Certifique-se de que sua imagem atenda a todos <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">os requisitos do canal</a>.',
    },
    'AnswerModal:close_warning': {
        eng: 'Do not close this window until your audio file is uploaded',
        ru: 'Не закрывайте окно до конца загрузки',
        pt: 'Não feche esta janela até que seu arquivo de áudio seja carregado',
    },
    'AnswerBlocks:saving': {
        eng: 'Saving…',
        ru: 'Сохранение…',
        pt: 'Salvando…',
    },
    'AnswerBlocks:question_tooltip': {
        eng: 'Learn more about how to <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">word questions</a> for your bot',
        ru: 'Подробнее о том, как правильно <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">формулировать вопросы</a>',
        pt: 'Saiba mais sobre como <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">redigir perguntas</a> para o seu bot',
    },
    'AnswerBlocks:answer_tooltip': {
        eng: 'Make sure the selected reply type is supported by the channel you are using. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Learn more</a>',
        ru: 'Убедитесь, что выбранный тип ответа поддерживается каналом, который вы используете. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Подробнее</a>',
        pt: 'Certifique-se de que o tipo de resposta selecionado é compatível com o canal que você está usando. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Saiba mais</a>',
    },
    'AnswerBlocks:answer_media_date': {
        eng: '$[1]',
        ru: '$[1]',
        pt: '$[1]',
    },
    'AnswerSettings:HtmlEnabler': {
        eng: 'Text formatting',
        ru: 'Форматирование текста',
        pt: 'Formatação de texto',
    },
    'AnswerBlocks:delete_block_submit': {
        eng: 'Delete',
        ru: 'Удалить',
        pt: 'Excluir',
    },
    'AnswerBlocks:validationTooltip': {
        eng: 'Other questions already have similar answers',
        ru: 'На другие вопросы уже есть похожие ответы',
        pt: 'Outras perguntas já têm respostas semelhantes',
    },
    'PhraseBlocks:validationTooltip': {
        eng: 'Other questions already have similar alternative phrases',
        ru: 'В других вопросах уже есть похожие формулировки',
        pt: 'Outras perguntas já têm frases alternativas semelhantes',
    },
    'FAQValErrorPanel.answers:header': {
        eng: 'Other questions already have similar answers',
        ru: 'На другие вопросы уже есть похожие ответы',
        pt: 'Outras perguntas já têm respostas semelhantes',
    },
    'FAQValErrorPanel.answers:info': {
        eng: 'As a result, the bot may answer the wrong question during the dialog. We recommend you rewrite this answer or move it to another question. Make sure the content of the questions is not the same.',
        ru: 'Из-за них бот может ответить не на тот вопрос во время диалога. Рекомендуем переписать этот ответ или переместить его в другой вопрос. Убедитесь, что содержание вопросов не пересекается.',
        pt: 'Como resultado, o bot pode responder à pergunta errada durante o diálogo. Recomendamos que você reescreva esta resposta ou mova-a para outra pergunta. Certifique-se de que o conteúdo das perguntas não é o mesmo.',
    },
    'FAQValErrorPanel.questions:header': {
        eng: 'Other questions already have similar alternative phrases',
        ru: 'В других вопросах уже есть похожие формулировки',
        pt: 'Outras perguntas já têm frases alternativas semelhantes',
    },
    'FAQValErrorPanel.questions:info': {
        eng: 'As a result, the bot may answer the wrong question during the dialog. We recommend you rewrite this alternative phrase or move it to another question. Make sure the content of the questions is not the same.',
        ru: 'Из-за них бот может ответить не на тот вопрос во время диалога. Рекомендуем переписать эту формулировку или переместить ее в другой вопрос. Убедитесь, что содержание вопросов не пересекается.',
        pt: 'Como resultado, o bot pode responder à pergunta errada durante o diálogo. Recomendamos que você reescreva esta frase alternativa ou mova-a para outra pergunta. Certifique-se de que o conteúdo das perguntas não é o mesmo.',
    },
    'AnswersPartModal:upload-file': {
        eng: 'Upload',
        ru: 'Загрузить',
        pt: 'Carregar',
    },
    'AnswersPartModal:InsertLink': {
        eng: 'Insert link',
        ru: 'Вставить ссылку',
        pt: 'Inserir link',
    },
    'AudioSettings:compatibleChannelsTitle': {
        eng: 'Supported in',
        ru: 'Поддерживается в',
        pt: 'Suportado em',
    },
    'AudioSettings:incompatibleChannelsTitle': {
        eng: 'Not supported in',
        ru: 'Не поддерживается в',
        pt: 'Não suportado em',
    },
    'AnswerBlocks:max_answers': {
        eng: 'The maximum number of messages in a reply is 20',
        ru: 'Максимум сообщений в ответе – 20',
        pt: 'O número máximo de mensagens em uma resposta é 20',
    },
    'AudioUploadField:Error:FileSize': {
        eng: 'File size cannot exceed 10 MB.',
        ru: 'Размер файла не должен превышать 10 Мбайт.',
        pt: 'O tamanho do arquivo não pode exceder 10 MB.',
    },
    'FileUploadField:Error:InvalidFileExtension': {
        eng: 'Invalid file extension. Supported file formats: .xls or .pdf.',
        ru: 'Неверное расширение файла. Поддерживаемые форматы: .xls или .pdf.',
        pt: 'Extensão de arquivo inválida. Formatos de arquivo suportados: .xls ou .pdf.',
    },
    'AudioUploadField:Error:InvalidImageExtension': {
        eng: 'Invalid file extension. Supported file formats: .gif, .png or .jpeg.',
        ru: 'Неверное расширение файла. Поддерживаемые форматы: .gif, .png или .jpeg.',
        pt: 'Extensão de arquivo inválida. Formatos de arquivo suportados: .gif, .png ou .jpeg.',
    },
    'FAQ:Home:Title': {
        eng: 'Knowledge base',
        ru: 'База знаний',
        pt: 'Base de conhecimento',
    },
    'FAQ:Home:RenameModal:Title': {
        eng: 'Rename module',
        ru: 'Переименовать модуль',
        pt: 'Renomear módulo',
    },
    'FAQ:Home:RenameModal:Submit': {
        eng: 'Save',
        ru: 'Сохранить',
        pt: 'Salvar',
    },
    'FAQ:Home:RenameModal:Field': {
        eng: 'FAQ title',
        ru: 'Название FAQ',
    },
    'FAQ:Home:RenameModal:Error:NoUniq': {
        eng: 'A module with this name already exists',
        ru: 'Модуль с таким названием уже есть',
        pt: 'Já existe um módulo com este nome',
    },
    'FAQ:Home:DeleteModal:Title': {
        eng: 'Delete module',
        ru: 'Удалить модуль',
        pt: 'Excluir módulo',
    },
    'FAQ:Home:DeleteModal:Content $[1]': {
        eng: 'Delete the $[1] module?',
        ru: 'Вы действительно хотите удалить модуль $[1]?',
        pt: 'Excluir o módulo $[1]?',
    },
    'FAQ:Home:SkillTable:Action:Rename': {
        eng: 'Rename',
        ru: 'Переименовать',
        pt: 'Renomear',
    },
    'FAQ:Home:SkillTable:Action:ViewAnalytics': {
        eng: 'View analytics',
        ru: 'Посмотреть аналитику',
        pt: 'Visualizar análises',
    },
    'FAQ:Home:SkillTable:Action:CopySkill': {
        eng: 'Copy the module code',
        ru: 'Скопировать код модуля',
        pt: 'Copiar o código do módulo',
    },
    'FAQ:Home:SkillTable:Action:CopySkillSub': {
        eng: 'To use it in the script',
        ru: 'Чтобы использовать в сценарии',
        pt: 'Para usá-lo no script',
    },
    'FAQ:Home:SkillTable:Action:Delete': {
        eng: 'Delete',
        ru: 'Удалить',
        pt: 'Excluir',
    },
    'FAQ:Home:SkillTable:Headers:Title': {
        eng: 'Module',
        ru: 'Модуль',
        pt: 'Módulo',
    },
    'FAQ:Home:SkillTable:Headers:Edited': {
        eng: 'Edited',
        ru: 'Изменен',
        pt: 'Editado',
    },
    'FAQ:Home:SkillTable:Headers:Created': {
        eng: 'Created',
        ru: 'Создан',
        pt: 'Criado',
    },
    'FAQ:Home:SkillTable:Headers:Active': {
        eng: 'Active',
        ru: 'Активен',
        pt: 'Ativo',
    },
    'FAQ:Home:EmptyContent:Title': {
        eng: 'There are no modules in the knowledge base',
        ru: 'В базе знаний нет модулей',
        pt: 'Não há módulos na base de conhecimento',
    },
    'FAQ:cdqa:description': {
        eng: 'Quick document search',
        ru: 'Быстрый поиск по документу',
        pt: 'Pesquisa rápida de documentos',
    },
    'FAQ:cdqa:alreadyCreated': {
        eng: 'This module has already been created',
        ru: 'Модуль уже создан',
        pt: 'Este módulo já foi criado',
    },
    'FAQ:cdqa:document': {
        eng: 'Document',
        ru: 'Документ',
        pt: 'Documento',
    },
    'FAQ:cdqa:documentAttach': {
        eng: 'Attach document',
        ru: 'Прикрепите документ',
        pt: 'Anexe o documento',
    },
    'FAQ:cdqa:uploadDialogInfoItem1': {
        eng: 'Format: TXT, encoding: UTF-8.',
        ru: 'Формат: TXT, кодировка: UTF-8.',
        pt: 'Formato: TXT, codificação: UTF-8.',
    },
    'FAQ:cdqa:uploadDialogInfoItem2': {
        eng: 'Document size: up to 1 Mbyte',
        ru: 'Размер: до 1 Мбайт.',
        pt: 'Tamanho do documento: até 1 MB',
    },
    'FAQ:cdqa:loadingLabel': {
        eng: 'The document is loading...',
        ru: 'Документ загружается',
        pt: 'O documento está sendo carregado…',
    },
    'FAQ:cdqa:fileReqError': {
        eng: 'Upload the document',
        ru: 'Загрузите документ',
        pt: 'Carregue o documento',
    },
    'FAQ:cdqa:fileValError': {
        eng: 'This document does not meet the requirements.',
        ru: 'Документ не подходит по требованиям.',
        pt: 'Este documento não atende aos requisitos.',
    },
    'FAQ:Home:SkillCopiedTitle': {
        eng: 'Module code copied',
        ru: 'Код модуля скопирован',
        pt: 'Código do módulo copiado',
    },
    'FAQ:Home:SkillCopiedText': {
        eng: 'Paste the code into the bot script',
        ru: 'Вставьте код в сценарий бота',
        pt: 'Cole o código no script do bot',
    },
    'FAQ:Home:SkillNotFoundAnswer': {
        eng: "I didn't find the answer in my documents. Please ask something else.",
        ru: 'Я не нашел ответ в своих документах. Пожалуйста, спросите что-нибудь другое.',
        pt: 'Não encontrei a resposta em meus documentos. Pergunte outra coisa.',
    },
    'FAQ:Home:GoToLL': {
        eng: 'Use fine-tuning',
        ru: 'Использовать дообучение',
        pt: 'Usar ajuste fino',
    },
    'FAQ:Home:SimilarsModal:Title': {
        eng: 'Set up search for matches',
        ru: 'Поиск совпадений',
        pt: 'Configurar pesquisa de correspondências',
    },
    'FAQ:Home:SimilarsModal:Content': {
        eng: 'This allows you to avoid similar questions and answers when you save them. Determine how similar they might be. \n This needs a pre-trained classifier to work.',
        ru: 'Позволяет избегать похожих вопросов и ответов при их сохранении. Установите, насколько они могут быть схожи. \n Работает только на обученном классификаторе.',
        pt: 'Isso permite que você evite perguntas e respostas semelhantes ao salvá-las. Determine o quão semelhantes elas podem ser. \n É preciso um classificador pré-treinado para funcionar.',
    },
    'FAQ:Home:SimilarsModal:QuestionsLabel': {
        eng: 'In questions',
        ru: 'В вопросах',
        pt: 'Em perguntas',
    },
    'FAQ:Home:SimilarsModal:AnswersLabel': {
        eng: 'In answers',
        ru: 'В ответах',
        pt: 'Em respostas',
    },
    'AnswerBlocks:AddButton:type_file': {
        eng: 'File',
        ru: 'Файл',
        pt: 'Arquivo',
    },
    'AnswerBlocks:AddButton:type_audio': {
        eng: 'Audio',
        ru: 'Аудио',
        pt: 'Áudio',
    },
    'AnswerBlocks:AddButton:type_text': {
        eng: 'Text',
        ru: 'Текст',
        pt: 'Texto',
    },
    'AnswerBlocks:AddButton:type_image': {
        eng: 'Image',
        ru: 'Изображение',
        pt: 'Imagem',
    },
    'ImportModal:actionButton': {
        eng: 'Import',
        ru: 'Импортировать',
        pt: 'Importar',
    },
    'AudioSettings:UploadLabelFirst': {
        eng: 'Attach a file',
        ru: 'Загрузите аудиофайл',
        pt: 'Anexe um arquivo',
    },
    'AudioSettings:UploadLabelSecond': {
        eng: ' or drag it here',
        ru: ' или перетащите его сюда',
        pt: ' ou arraste-o aqui',
    },
    'AudioSettings:upload-file': {
        eng: 'From device',
        ru: 'C компьютера',
        pt: 'Do dispositivo',
    },
    'AudioSettings:file-link': {
        eng: 'By URL',
        ru: 'По ссылке',
        pt: 'Por URL',
    },
    'Errors:caila.faq.import.wrong_enabled_value': {
        eng: '! Critical: Invalid cell value in the Enabled column: $[2] row: $[1]. Possible values: TRUE, FALSE; 1, 0.',
        ru: '! Критическая: Недопустимое значение ячейки в столбце Enabled: столбец: $[2] строка: $[1]. Возможные значения: TRUE, FALSE; 1, 0.',
        pt: '! Crítico: valor de célula inválido na coluna Enabled: $[2] linha: $[1]. Valores possíveis: TRUE, FALSE; 1, 0.',
    },
    'Errors:caila.faq.import.file_too_large': {
        eng: 'File size cannot exceed $[1] MB.',
        ru: 'Размер файла не должен превышать $[1] Мб.',
        pt: 'O tamanho do arquivo não pode exceder $[1] MB.',
    },
    'Errors:caila.faq.import.max_intent_number_exceeded': {
        eng: '! Critical: Maximum number of rows: $[1].',
        ru: '! Критическая: Максимальное количество строк: $[1].',
        pt: '! Crítico: Número máximo de linhas: $[1].',
    },
    'Errors:caila.faq.import.required_header_missing': {
        eng: '! Critical: Required headers missing: $[1].',
        ru: '! Критическая: Отсутствуют обязательные столбцы: $[1].',
        pt: '! Crítico: Faltam cabeçalhos obrigatórios: $[1].',
    },
    'Errors:caila.faq.import.empty_question_and_group': {
        eng: '! Critical: Values in the Question and Group columns cannot be missing at the same time: column: $[2] row: $[1].',
        ru: '! Критическая: Значения в столбцах Question и Group не могут отсутствовать одновременно: столбец: $[2] строка: $[1].',
        pt: '! Crítico: os valores nas colunas Question e Group não podem faltar ao mesmo tempo: coluna: $[2] linha: $[1].',
    },
    'Errors:caila.faq.import.empty_question': {
        eng: '! Critical: Cell values in the Question column cannot be empty, except if you want to create an empty group. In this case, clear all the other cells in this row as well: column: $[2] row: $[1].',
        ru: '! Критическая: Значение ячейки в столбце Question не может быть пустым, если только вы не хотите создать пустую группу. В таком случае также очистите все остальные ячейки в этой строке: столбец: $[2] строка: $[1].',
        pt: '! Crítico: os valores das células na coluna Question não podem estar vazios, exceto se você quiser criar um grupo vazio. Neste caso, limpe também todas as outras células nesta linha: coluna: $[2] linha: $[1].',
    },
    'Errors:caila.faq.import.duplicated_header_value': {
        eng: '! Critical: Column headers must be unique: $[1].',
        ru: '! Критическая: Названия столбцов должны быть уникальными: $[1].',
        pt: '! Crítico: os cabeçalhos das colunas devem ser exclusivos: $[1].',
    },
    'Errors:caila.faq.import.extension_not_supported': {
        eng: '! Critical: File format or file extension is not valid. Verify that the file has not been corrupted and that the file extension matches the format of the file.',
        ru: '! Критическая: Недопустимый формат или расширение файла. Убедитесь, что файл не поврежден и что расширение файла соответствует формату файла.',
        pt: '! Crítico: o formato ou a extensão do arquivo não é válido. Verifique se o arquivo não foi corrompido e se a extensão do arquivo corresponde ao formato do arquivo.',
    },
    'Errors:caila.faq.import.cannot_parse_file': {
        eng: '! Critical: File format or file extension is not valid. Verify that the file has not been corrupted and that the file extension matches the format of the file.',
        ru: '! Критическая: Недопустимый формат или расширение файла. Убедитесь, что файл не поврежден и что расширение файла соответствует формату файла.',
        pt: '! Crítico: o formato ou a extensão do arquivo não é válido. Verifique se o arquivo não foi corrompido e se a extensão do arquivo corresponde ao formato do arquivo.',
    },
    'Errors:caila.faq.import.invalid_header': {
        eng: '“$[1]” is not a valid column header. Possible headers: Group, Question, Enabled, Alternative phrases-Text, Alternative phrases-Patterns, Answer.',
        ru: 'Недопустимый заголовок для столбца: “$[1]”. Возможные заголовки: Group, Question, Enabled, Alternative phrases-Text, Alternative phrases-Patterns, Answer.',
        pt: '“$[1]” não é um cabeçalho de coluna válido. Cabeçalhos possíveis: Group, Question, Enabled, Alternative phrases-Text, Alternative phrases-Patterns, Answer.',
    },
    'Errors:caila.faq.import.header_cannot_be_empty': {
        eng: 'Header cannot be empty: $[1]. The column will not be imported.',
        ru: 'Заголовок не может быть пустым: $[1]. Столбец не будет импортирован.',
        pt: 'O cabeçalho não pode estar vazio: $[1]. A coluna não será importada.',
    },
    'Errors:caila.faq.import.wrong_pattern': {
        eng: 'Invalid pattern: $[4] row: $[1] column: $[2] line: $[3].',
        ru: 'Ошибка в паттерне: $[4] ряд: $[1] столбец: $[2] строка: $[3].',
        pt: 'Padrão inválido: $[4] linha: $[1] coluna: $[2] linha: $[3].',
    },
    'Errors:caila.faq.import.empty_answer_type': {
        eng: 'The type is not specified for the answer (row $[1], column $[2]). The default value will be used: text.',
        ru: 'Для ответа не указан тип (строка $[1], столбец $[2]). Будет использовано значение по умолчанию: text.',
        pt: 'O tipo não é especificado para a resposta (linha $[1], coluna $[2]). O valor padrão será usado: text.',
    },
    'Errors:caila.faq.import.no_answer_type_column': {
        eng: 'Answer type column is empty. Default value: text.',
        ru: 'Не заполнен столбец Answer type. Значение по умолчанию: text.',
        pt: 'A coluna Answer type está vazia. Valor padrão: text.',
    },
    'Errors:caila.faq.import.wrong_answer_type': {
        eng: 'Wrong answer type value: row $[1], column $[2].',
        ru: 'Неподдерживаемый тип ответа: строка $[1], столбец $[2].',
        pt: 'Valor do tipo de resposta incorreto: linha $[1], coluna $[2].',
    },
    'Errors:caila.faq.import.max_replies_number_exceeded': {
        eng: '! Critical: The maximum number of messages in a reply exceeded ($[1]): row $[2].',
        ru: '! Критическая: Превышено максимальное количество сообщений в ответе ($[1]): строка $[2].',
        pt: '! Crítico: o número máximo de mensagens em uma resposta foi excedido ($[1]): linha $[2].',
    },
};
