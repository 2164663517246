import React, { FC } from 'react';
import { Group, Rect } from 'react-konva';
import { setTitleOnMouseEnterAndLeave } from '../../utils/stageUtils';

const cornerRadius = [12, 0, 0, 12];

export const Bookmark: FC<{
  fill: string;
  stroke: string;
  isCollapsed: boolean;
  title?: string;
  strokeWidth?: number;
  y?: number;
  x?: number;
}> = ({ children, fill, stroke, strokeWidth = 1, title, y = 0, x = 0, isCollapsed }) => {
  return (
    <Group
      name='BookMark'
      x={x}
      y={y}
      onMouseEnter={setTitleOnMouseEnterAndLeave(title)}
      onMouseLeave={setTitleOnMouseEnterAndLeave()}
    >
      <Rect
        width={isCollapsed ? 28 : 36}
        height={28}
        cornerRadius={isCollapsed ? 8 : cornerRadius}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <Group x={isCollapsed ? 8 : 12} y={8}>
        {children}
      </Group>
    </Group>
  );
};
