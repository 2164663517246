export var Theme;
(function (Theme) {
    Theme["dark"] = "dark";
    Theme["light"] = "light";
})(Theme || (Theme = {}));
export var InputStates;
(function (InputStates) {
    InputStates["default"] = "default";
    InputStates["error"] = "error";
    InputStates["warning"] = "warning";
    InputStates["success"] = "success";
})(InputStates || (InputStates = {}));
