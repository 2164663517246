import {
  GET_CALL_TASK_PENDING,
  GET_CALL_TASK_FULFILLED,
  GET_CALL_TASK_REJECTED,
  CREATE_CALL_TASK_PENDING,
  CREATE_CALL_TASK_REJECTED,
  CREATE_CALL_TASK_FULFILLED,
  RESET,
  CONTROL_CALL_TASK,
  CONTROL_CALL_TASK_FULFILLED,
  UPDATE_CALL_TASK_PENDING,
  UPDATE_CALL_TASK_FULFILLED,
  UPDATE_CALL_TASK_REJECTED,
  CONTROL_CALL_TASK_REJECTED,
  DELETE_CALL_TASK_REJECTED,
  CONTROL_CALL_TASK_PENDING,
} from '../../constants/callTask.actions';

const InitialState = {
  callTask: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function CallTaskReducer(state = InitialState, action) {
  switch (action.type) {
    case CONTROL_CALL_TASK:
    case CREATE_CALL_TASK_PENDING:
    case GET_CALL_TASK_PENDING:
    case UPDATE_CALL_TASK_PENDING:
    case CONTROL_CALL_TASK_PENDING:
      return {
        ...InitialState,
        fetching: true,
      };

    case UPDATE_CALL_TASK_FULFILLED:
    case CONTROL_CALL_TASK_FULFILLED:
    case CREATE_CALL_TASK_FULFILLED:
    case GET_CALL_TASK_FULFILLED:
      return {
        ...state,
        callTask: action.payload.data,
        fetched: true,
        fetching: false,
      };

    case UPDATE_CALL_TASK_REJECTED:
    case CONTROL_CALL_TASK_REJECTED:
    case CREATE_CALL_TASK_REJECTED:
    case GET_CALL_TASK_REJECTED:
    case DELETE_CALL_TASK_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.response.data.error || action.payload.response.data.type,
      };

    case RESET:
      return InitialState;

    default:
      return state;
  }
}
