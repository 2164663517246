import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button } from '@just-ai/just-ui';
import MassLabelsModal from './MassLabelsModal';
import { Checkbox } from '../../Checkbox';
import classnames from 'classnames';
import { useLabelsContext } from '../../../../Labels/context/LabelsContext';
export const labelsReducer = (state, action) => {
    var _a;
    let result;
    switch (action.type) {
        case 'exclude':
            result = { key: 'SESSION_ID', options: [], comparisonType: 'exclude' };
            break;
        case 'include':
            result = { key: 'SESSION_ID', options: [], comparisonType: 'include' };
            break;
        case 'change':
            if ((_a = state.options) === null || _a === void 0 ? void 0 : _a.includes(action.value)) {
                const newOptions = [...state.options.filter(x => x !== action.value)];
                result = {
                    key: 'SESSION_ID',
                    options: newOptions,
                    comparisonType: newOptions.length === 0 && state.comparisonType === 'include' ? 'none' : state.comparisonType,
                };
            }
            else {
                result = {
                    key: 'SESSION_ID',
                    options: [...state.options, action.value],
                    comparisonType: state.comparisonType === 'none' ? 'include' : state.comparisonType,
                };
            }
            break;
        case 'reset':
        default:
            result = { key: 'SESSION_ID', options: [], comparisonType: 'none' };
    }
    return result;
};
function MassAddingLabels({ labelsState, setLabelsDispatcher, t, totalCount, onSubmit, isSession = false, }) {
    const { options, comparisonType } = labelsState;
    const [openModal, setOpenModal] = useState(false);
    const { hasTagCreatePermission } = useLabelsContext();
    return (_jsxs("div", { className: classnames('Sessions_massAddingLabels_container', isSession && 'Sessions_massAddingLabels_container_sessionsList'), children: [_jsx(Checkbox, { checked: true, halfChecked: comparisonType === 'include' ? totalCount !== options.length : options.length > 0, name: 'choose_labels', onClick: () => setLabelsDispatcher({ type: 'reset' }) }), _jsxs("span", { children: [comparisonType === 'include' ? options.length : totalCount - options.length, ' ', t('Analytics: choose label founded')] }), ((comparisonType === 'include' && options.length < totalCount) ||
                (comparisonType === 'exclude' && options.length > 0)) && (_jsx(Button, { color: 'primary', flat: true, onClick: () => setLabelsDispatcher({ type: 'exclude' }), children: t('Analytics: choose all labels') })), _jsx("div", { className: 'Sessions_massAddingLabels_delimiter' }), _jsx(Button, { color: 'primary', flat: true, onClick: () => setLabelsDispatcher({ type: 'reset' }), children: t('Analytics: reset choosed labels') }), _jsx("div", { className: 'Sessions_massAddingLabels_delimiter' }), _jsx(Button, { color: 'primary', flat: true, onClick: () => setOpenModal(true), disabled: !hasTagCreatePermission, children: t('Analytics: messages add labels') }), openModal && (_jsx(MassLabelsModal, { onSubmit: onSubmit, isSession: isSession, onClose: () => setOpenModal(false), t: t }))] }));
}
export default React.memo(MassAddingLabels);
