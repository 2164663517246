import React, { useCallback, useMemo, CSSProperties } from 'react';
import { Icon, ICONS } from '@just-ai/just-ui';

import { TreeNodeViewProps } from 'components/Tree';

import { HierarchyTreeNode } from '../model';

import styles from './styles.module.scss';

const iconsForNodeType: Record<'theme' | 'stateSolid' | 'stateOutline', ICONS> = {
  theme: 'faJGTheme',
  stateSolid: 'fasJGState',
  stateOutline: 'farJGState',
};
function TreeNodeView({ node, onToggle, expanded }: TreeNodeViewProps<HierarchyTreeNode>) {
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle]
  );

  const icon = useMemo(() => {
    switch (node.nodeType) {
      case 'theme':
        return iconsForNodeType.theme;
      case 'state':
        if (node.screenLabel) return iconsForNodeType.stateSolid;
        return iconsForNodeType.stateOutline;
    }
  }, [node.nodeType, node.screenLabel]);

  return (
    <>
      <span className={styles.iconPlace}>
        {node.isFolder && (
          <span className={styles.toggleIcon} onClick={onClick} data-test-id={`Tree.Branch.${node.name}.toggle`}>
            <Icon name={expanded ? 'faChevronDown' : 'faChevronRight'} size='xs' />
          </span>
        )}
      </span>
      <span
        className={styles.text}
        style={
          {
            '--nodeColor': node.screenLabel?.color.mainColor,
            '--nodeStrokeColor': node.screenLabel?.color.altBorderColor,
          } as CSSProperties
        }
      >
        <Icon name={icon} />
        <span>{node.name}</span>
      </span>
    </>
  );
}

TreeNodeView.displayName = 'TreeNodeView';

export default React.memo(TreeNodeView);
