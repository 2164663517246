export const editorLocalization = {
  'Editor:filesPaneTitle': {
    eng: 'Files',
    ru: 'Файлы',
    cn: '文件',
  },
  'Editor:settingsDialogTitle': {
    eng: 'Settings',
    ru: 'Настройки',
    cn: '设置',
  },
  'Editor:settingsDialogSaveButton': {
    eng: 'Save',
    ru: 'Сохранить',
    cn: '保存',
  },
  'Editor:settingsDialogCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'Editor:settingsDialogFontSize': {
    eng: 'Font size',
    ru: 'Размер шрифта',
    cn: '字体大小',
  },
  'Editor:settingsDialogKeyboardHandler': {
    eng: 'Hotkeys',
    ru: 'Горячие клавиши',
  },
  'Editor:settingsDialogShowGutter': {
    eng: 'String numbers',
    ru: 'Номера строк',
  },
  'Editor:settingsDialogTabsBehavior': {
    eng: 'Tab display',
    ru: 'Отображение вкладок',
  },
  'Editor:settingsDialogCloseTabPosition': {
    eng: 'Tab close position',
    ru: 'Кнопка закрытия вкладки',
  },
  'Editor:settingsDialogCloseTabPositionRight': {
    eng: 'Right',
    ru: 'Справа',
  },
  'Editor:settingsDialogCloseTabPositionLeft': {
    eng: 'Left',
    ru: 'Слева',
  },
  'Editor:settingsDialogShowHiddenFiles': {
    eng: 'Show hidden files',
    ru: 'Показывать скрытые файлы',
  },
  'Editor:commitDialogTitle': {
    eng: 'Save script',
    ru: 'Сохранить сценарий',
    cn: '保存脚本版本',
  },
  'Editor:commitDialogConflictTitle': {
    eng: 'External repository conflict',
    ru: 'Конфликт с внешним репозиторием',
  },
  'Editor:commitDialogFixConflictButton': {
    eng: 'Fix',
    ru: 'Исправить',
  },
  'Editor:commitDialogSaveButton': {
    eng: 'Save',
    ru: 'Сохранить',
    cn: '保存',
  },
  'Editor:commitDialogCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'Editor:onPageClose': {
    eng: 'Have unsaved data, close the tab?',
    ru: 'Есть несохраненные данные, закрыть вкладку?',
  },
  'Editor:commitDialogCommitMessageLabel': {
    eng: 'Leave a comment describing the changes',
    ru: 'Оставьте комментарий к изменениям',
    cn: '对此版本的评论',
  },
  'Editor:commitDialogCommitMessagePlaceholder': {
    eng: 'Your comment',
    ru: 'Ваш комментарий',
    cn: '您的评论',
  },
  'Editor:commitDialogResolutionAnswer': {
    eng: 'A new version appeared in the repository while you were working with your script. What do you want to do?',
    ru: 'Пока вы работали над сценарием, во внешнем репозитории появилась новая версия. Что вы хотите сделать?',
  },
  'Editor:commitDialogResolutionIgnoreRemote': {
    eng: 'Make your version the active one',
    ru: 'Сделать актуальной вашу версию',
  },
  'Editor:commitDialogResolutionReset': {
    eng: 'Delete your version and download the active one from the repository',
    ru: 'Удалить вашу версию и загрузить актуальную из репозитория',
  },
  'Editor:commitDialogResolutionIntegrate': {
    eng: 'Automatically merge',
    ru: 'Выполнить автоматическое слияние',
  },
  'Editor:commitDialogResolutionNewBranch': {
    eng: 'Save changes to another branch of the repository',
    ru: 'Сохранить изменения в другую ветку репозитория',
  },
  'Editor:discardDialogTitle': {
    eng: 'Discard all changes?',
    ru: 'Отменить все изменения?',
    cn: '撤消更改',
  },
  'Editor:discardDialogSaveButton': {
    eng: 'Yes, discard the changes',
    ru: 'Да, отменить изменения',
    cn: '保存',
  },
  'Editor:discardDialogCancelButton': {
    eng: 'No, keep them ',
    ru: 'Нет, оставить',
    cn: '取消',
  },
  'Editor:discardDialogMessage': {
    eng: 'All changes made since the last save operation will be lost.',
    ru: 'Все изменения с момента последнего сохранения будут удалены.',
    cn: '您确认需要撤消这些更改？自上次提交以来的所有更新都将被丢弃。',
  },
  'Editor:aceEmptyFile': {
    eng: 'Empty',
    ru: 'Пусто',
    cn: '空',
  },
  'Editor:aceNoFileSelected': {
    eng: 'Select a file',
    ru: 'Выберите файл',
    cn: '选择一个文件',
  },
  'Editor:contextMenuNewFile': {
    eng: 'New file',
    ru: 'Создать файл',
    cn: '新文件',
  },
  'Editor:contextMenuNewFolder': {
    eng: 'New folder',
    ru: 'Создать папку',
    cn: '新文件夹',
  },
  'Editor:newFileTooltip': {
    eng: 'New file',
    ru: 'Создать файл',
    cn: '新文件',
  },
  'Editor:newFolderTooltip': {
    eng: 'New folder',
    ru: 'Создать папку',
    cn: '新文件夹',
  },
  'Editor:contextMenuCut': {
    eng: 'Cut',
    ru: 'Вырезать',
    cn: '剪切',
  },
  'Editor:contextMenuCopy': {
    eng: 'Copy',
    ru: 'Копировать',
    cn: '复制',
  },
  'Editor:contextMenuPaste': {
    eng: 'Paste',
    ru: 'Вставить',
    cn: '粘贴',
  },
  'Editor:contextMenuRename': {
    eng: 'Rename',
    ru: 'Переименовать',
    cn: '重命名',
  },
  'Editor:contextMenuDelete': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'Editor:modeCode': {
    eng: 'Code editor',
    ru: 'Редактор кода',
    cn: '代码编辑器',
  },
  'Editor:modeGraph': {
    eng: 'Graph editor',
    ru: 'Графический редактор',
    cn: '图形编辑器',
  },
  'Editor:runTestWidget': {
    eng: 'Test',
    ru: 'Тест',
    cn: '测试',
  },
  'Editor:stopTestWidget': {
    eng: 'Stop',
    ru: 'Остановить',
    cn: '停止',
  },
  'Editor:pull': {
    eng: 'Pull',
    ru: 'Обновить',
  },
  'Editor:commit': {
    eng: 'Commit',
    ru: 'Коммит',
    cn: '提交',
  },
  'Editor:format': {
    eng: 'Format',
    ru: 'Форматирование',
    cn: '',
  },
  'Editor:discard': {
    eng: 'Discard',
    ru: 'Откатить изменения',
    cn: '丢弃',
  },
  'Editor:overwrite': {
    eng: 'Overwrite',
    ru: 'Перезаписать',
  },
  'Editor:conflictResolutionNotificationTitle': {
    eng: 'File {{name}} was modified by another user',
    ru: 'Файл {{name}} изменён другим пользователем',
  },
  'Editor:conflictResolutionNotificationMessage': {
    eng: 'Which version of the file should be saved to the server?',
    ru: 'Какую версию файла сохранить на сервер?',
  },
  'Editor:conflictResolutionOverwrite': {
    eng: 'Mine, remove other modifications',
    ru: 'Мою, удалить чужие изменения',
  },
  'Editor:conflictResolutionReset': {
    eng: 'Another version',
    ru: 'Другую версию',
  },
  'Editor:showSettings': {
    eng: 'Settings',
    ru: 'Настройки',
    cn: '设置',
  },
  'Editor:search': {
    eng: 'Search/Replace',
    ru: 'Поиск/Замена',
    cn: '搜索/替换',
  },
  'Editor:TTS': {
    eng: 'TTS',
    ru: 'TTS',
  },
  'Editor:TTS:Title': {
    eng: 'Speech synthesis widget',
    ru: 'Виджет синтеза речи',
  },
  'Editor:filesTab': {
    eng: 'Files',
    ru: 'Файлы',
  },
  'Editor:searchTab': {
    eng: 'Search',
    ru: 'Поиск',
  },
  'Editor:SearchPanel.noResults': {
    eng: 'Search results appear here',
    ru: 'Здесь появятся результаты поиска',
  },
  'Editor:SearchToolbar.find': {
    eng: 'Find',
    ru: 'Найти',
  },
  'Editor:SearchToolbar.wholeWord': {
    eng: 'Whole word',
    ru: 'Совпадение по целому слову',
  },
  'Editor:SearchToolbar.caseSensitive': {
    eng: 'Case sensitive',
    ru: 'Чувствительность к регистру',
  },
  'Editor:SearchToolbar.regEx': {
    eng: 'Regular expression',
    ru: 'Регулярное выражение',
  },
  'Editor:SearchToolbar.searchIn': {
    eng: 'Search in',
    ru: 'Найти в',
  },
  'Editor:SearchToolbar.replace': {
    eng: 'Replace',
    ru: 'Заменить',
  },
  'Editor:SearchToolbar.replaceAll': {
    eng: 'Replace all',
    ru: 'Заменить все',
  },
  'Editor:SearchResultsBrowser.replace': {
    eng: 'Replace',
    ru: 'Заменить',
  },
  'Editor:SearchResultsBrowser.cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'Editor:SearchResultsBrowser.for': {
    eng: 'for',
    ru: 'на',
  },
  'Editor:SearchResultsBrowser.results': {
    eng: 'results',
    ru: 'результатов',
  },
  'Editor:SearchResultsBrowser.results_0': {
    eng: 'result',
    ru: 'результат',
  },
  'Editor:SearchResultsBrowser.results_1': {
    eng: 'results',
    ru: 'результата',
  },
  'Editor:SearchResultsBrowser.results_2': {
    eng: 'results',
    ru: 'результатов',
  },
  'Editor:SearchResultsBrowser.in': {
    eng: 'in',
    ru: 'в',
  },
  'Editor:SearchResultsBrowser.files': {
    eng: 'files',
    ru: 'файлах',
  },
  'Editor:SearchResultsBrowser.replacedInfo': {
    eng: 'Replaced $[1] $[5] “$[3]” in $[2] $[6] with “$[4]”',
    ru: 'Заменено $[1] $[5] «$[3]» в $[2] $[6] на «$[4]»',
  },
  'Editor:SearchResultsBrowser.files_0': {
    eng: 'file',
    ru: 'файле',
  },
  'Editor:SearchResultsBrowser.files_1': {
    eng: 'files',
    ru: 'файлах',
  },
  'Editor:SearchResultsBrowser.files_2': {
    eng: 'files',
    ru: 'файлах',
  },
  'Editor:SearchResultsBrowser.hasMoreInfo': {
    eng: 'Only the first 100 search results are shown.',
    ru: 'Показываются только первые 100 результатов поиска.',
  },
  'Editor:connectionErrorTitle': {
    eng: 'No internet',
    ru: 'Отсутствует интернет',
  },
  'Editor:connectionErrorTooltip': {
    eng: 'Internet connection problem. The file changes will be uploaded to the server when the connection is restored',
    ru: 'Проблема с интернет соединением. Изменения будут загружены на сервер при следующем подключении.',
  },
  'Editor:connectionErrorMessage': {
    eng: 'The file changes will be uploaded to the server when the connection is restored',
    ru: 'Изменения файла будут загружены на сервер при следующем подключении',
  },
  'Editor:useCodeEditorOnScreenAdd': {
    eng: 'Use code editor when adding a screen',
    ru: 'Использовать редактор кода при добавлении экрана',
    cn: '使用代码编辑器添加视窗',
  },
  'Editor:testSectionName': {
    eng: 'Test',
    ru: 'Тест',
  },
  'Editor:scenarioSectionName': {
    eng: 'Script',
    ru: 'Сценарий',
  },
  'Editor:blocksPanelTitle': {
    eng: 'Blocks',
    ru: 'Блоки',
  },
  'Editor:errorsAreaEmpty': {
    eng: 'Empty',
    ru: 'Пусто',
    cn: '空',
  },
  'Editor:ParseErrorsPanel.title': {
    eng: 'Errors',
    ru: 'Ошибки',
  },
  'Editor:parseErrorsButton': {
    eng: 'Errors',
    ru: 'Ошибки',
  },
  'Editor:depsPanelTitle': {
    eng: 'Dependencies',
    ru: 'Зависимости',
  },
  'Editor:systemDepsTooltip': {
    eng: 'You can import system modules using the “require” tag, for example: \nrequire: slotfilling/slotFilling.sc\n module = sys.zb-common',
    ru: 'Вы можете импортировать системные модули с помощью тега «require», например: \nrequire: slotfilling/slotFilling.sc\n module = sys.zb-common',
  },
  'Editor:syncDepsTooltip': {
    eng: 'Sync',
    ru: 'Обновить',
  },
  'Editor:messages-isEmpty': {
    eng: 'No elements',
    ru: 'Нет элементов',
  },
  'Editor:messages-nothingFound': {
    eng: 'No elements',
    ru: 'Нет элементов',
  },
  'EditorContext:attempt to save empty file': {
    eng: 'Are you sure you want to save an empty file?',
    ru: 'Вы уверены, что хотите сохранить пустой файл?',
  },
  'EditorContext:hotkey for tts widget cmd': {
    eng: '⌘ + F10',
    ru: '⌘ + F10',
  },
  'EditorContext:hotkey for tts widget ctrl': {
    eng: 'Ctrl + F10',
    ru: 'Ctrl + F10',
  },
};
