var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { Button, IconButton } from '@just-ai/just-ui';
import './Session.css';
import { AnalyticsContext } from '../../context/AnalyticsContext';
import { LabelsGroups } from '../Sessions/MessagesList/Message/LabelsGroups';
class SessionForm extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            editTaskModal: false,
            editLabelsModal: false,
        };
        this.togglePopup = (modalName, value) => {
            this.setState(state => {
                let newStateModals = Object.assign({}, state);
                newStateModals[modalName] = value !== undefined ? value : !newStateModals[modalName];
                return newStateModals;
            });
        };
        this.toggleTasksModal = () => {
            this.togglePopup('editTaskModal');
        };
        this.hideTaskModal = () => {
            this.togglePopup('editTaskModal', false);
            this.context.loadSessionIssue();
        };
        this.createNewLabelAndAddToSession = (value, groupId) => {
            if (!value)
                return null;
            const labels = this.context.session.labels || [];
            return this.context.createNewLabelAndAddToSession(this.context.session.sessionId, value, labels);
        };
        this.saveLabels = (label) => __awaiter(this, void 0, void 0, function* () {
            const labels = this.context.session.labels || [];
            if (label) {
                let newLabels;
                if (labels.find(x => x.id === label.id)) {
                    newLabels = labels.filter((x) => x.id != label.id);
                }
                else {
                    newLabels = [...labels, label];
                }
                this.context.saveSessionLabels(this.context.session.sessionId, newLabels);
            }
        });
    }
    render() {
        var _a, _b, _c, _d;
        let { t } = this.props;
        const { config, labelsContext, session } = this.context;
        const { labels, issue: rawIssue, sessionId } = session || {};
        const issue = Array.isArray(rawIssue) ? rawIssue[0] : rawIssue;
        return (_jsx(_Fragment, { children: ((_a = config.session) === null || _a === void 0 ? void 0 : _a.labelsEditComponent) && labelsContext.sessionLabels && (_jsxs("div", { className: 'labelGroups_container', children: [_jsx("span", { children: t('Analytics: current labels label') }), _jsx(LabelsGroups, { groups: [{ id: 1, labels: labelsContext.sessionLabels, name: '', priority: 1 }], currentLabels: labels || [], onChange: this.saveLabels, t: t, createNewLabelAndAddToMessage: this.createNewLabelAndAddToSession }), issue && (_jsxs("div", { className: 'Session_form_item_container', children: [_jsx("span", { children: t('Analytics: current task label') }), _jsxs("div", { children: [_jsxs("span", { children: [issue.name, issue.priority && _jsx("div", { className: 'Session_form_item_green', children: issue.priority.name })] }), _jsxs("div", { children: [issue.state && _jsx("div", { className: 'Session_form_item_blue', children: issue.state.name }), _jsx(IconButton, { "data-test-id": 'qaTaskTaskIcon', name: 'farEdit', size: 'sm', onClick: this.toggleTasksModal })] })] })] })), ((_b = config.session) === null || _b === void 0 ? void 0 : _b.tasksEditComponent) && !issue && (_jsx(Button, { "data-test-id": 'qaTaskTask', size: 'sm', color: 'link', onClick: this.toggleTasksModal, children: t('Analytics: messages add tasks') })), ((_c = config.session) === null || _c === void 0 ? void 0 : _c.tasksEditComponent) &&
                        this.state.editTaskModal &&
                        ((_d = config.session) === null || _d === void 0 ? void 0 : _d.tasksEditComponent({
                            isShown: this.state.editTaskModal,
                            sessionId,
                            issue,
                            toggle: this.hideTaskModal,
                        }))] })) }));
    }
}
SessionForm.contextType = AnalyticsContext;
export default SessionForm;
