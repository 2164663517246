import {
  ADD_LOG_LABELS,
  ADD_LOG_LABELS_FAST_FULFILLED,
  ADD_LOG_TASK,
  ADD_LOG_TEXTAREA,
  ADD_SESSION_TASK,
  GET_ISSUES_BY_QUESTION_ID,
  LOAD_DETAIL,
  LOAD_SESSION_LABELS,
  LOAD_TASK_CREATION_DATA,
  LOAD_TASK_LABELS,
  MAIN_SELECTED_MARKS,
  MARK_SESSION_ISSUE,
  MARK_SESSION_LOG,
  SEL_LAST_SELECTED_Priority,
  SEL_LAST_SELECTED_User,
  SET_CURRENT_INDEX,
  SET_PAGE,
  SET_SELECT_PHRASE_LABELS,
  SET_SELECTED_LOG_MARKS,
  SET_SELECTED_MAIN_MARKS,
  SET_SELECTED_MARKS,
  UPDATE_LOG_TASK,
  UPDATE_SESSION_TASK,
} from '../../constants/dialogsDetail.actions';
import { axios } from '../../pipes/functions';
import { isReporterEnabled } from '../../isAccessFunction';

export const setPage = page => {
  return {
    type: SET_PAGE,
    page: page,
  };
};

export const setLastSelectedUser = (value = null) => {
  return {
    type: SEL_LAST_SELECTED_User,
    value,
  };
};

export const setLastSelectedPriority = (value = null) => {
  return {
    type: SEL_LAST_SELECTED_Priority,
    value,
  };
};

export const loadDetail = (projectShortName, sessionId, params = {}, action = null) => {
  return {
    type: LOAD_DETAIL,
    payload: action
      ? action()
      : axios._get(`/restapi/logs/${projectShortName}/${encodeURIComponent(sessionId)}`, { params: params, sessionId }),
  };
};

export const setCurrentIndex = currentIndex => {
  return {
    type: SET_CURRENT_INDEX,
    currentIndex: currentIndex,
  };
};

export const getSessionsLabel = () => {
  return {
    type: LOAD_SESSION_LABELS,
    payload: axios.get(`/restapi/logs/labels/sessions`),
  };
};
export const getTaskCreationData = () => {
  return {
    type: LOAD_TASK_CREATION_DATA,
    payload: axios.get(`/restapi/logs/issues/creation-data`),
  };
};
export const addLabelToSession = (projectShortName, sessionId, markIds) => {
  return {
    type: MARK_SESSION_LOG,
    payload: axios._put(
      isReporterEnabled()
        ? `/restapi/logs/projects/${projectShortName}/sessions-labels/byNaturalId?sessionId=${sessionId}`
        : `/restapi/logs/sessions/${projectShortName}/${sessionId}/labels`,
      {
        labels: markIds,
      },
      {
        sessionMarkLabels: markIds,
      }
    ),
  };
};
export const addLabelsToLog = (logId, projectShortName, array = [], addingOrRemovedId) => {
  return {
    type: ADD_LOG_LABELS,
    logId: logId,
    payload: axios._put(
      isReporterEnabled()
        ? `/restapi/logs/${projectShortName}/${logId /*that is questionId*/}/labels/byQuestionId`
        : `/restapi/logs/${projectShortName}/${logId}/labels`,
      {
        labels: array,
      },
      {
        logId: logId,
        addingOrRemovedId,
      }
    ),
  };
};
export const addLabelsToLogFast = (logId, projectShortName, array = []) => {
  return {
    type: ADD_LOG_LABELS_FAST_FULFILLED,
    payload: {
      logId,
      labels: array,
    },
  };
};
export const addTextToLog = (logId, projectShortName, comment = '') => {
  return {
    type: ADD_LOG_TEXTAREA,
    logId: logId,
    payload: axios._put(
      isReporterEnabled()
        ? `/restapi/logs/${projectShortName}/${logId /*that is questionId*/}/comment/byQuestionId`
        : `/restapi/logs/${projectShortName}/${logId}/comment`,
      {
        comment: comment,
      },
      {
        logId: logId,
      }
    ),
  };
};
export const createLogTask = (projectShortName, obj = {}) => {
  return {
    type: ADD_LOG_TASK,
    payload: axios._post(
      isReporterEnabled()
        ? `/restapi/logs/issues/${projectShortName}/createByQuestionId`
        : `/restapi/logs/issues/${projectShortName}`,
      obj,
      { obj: obj }
    ),
  };
};
export const updateLogTask = (projectShortName, obj = {}) => {
  return {
    type: UPDATE_LOG_TASK,
    payload: axios._put(`/restapi/logs/issues/${projectShortName}/${obj.issueId}`, obj, { obj: obj }),
  };
};
export const createSessionTask = (projectShortName, obj = {}) => {
  return {
    type: ADD_SESSION_TASK,
    payload: axios._post(
      isReporterEnabled()
        ? `/restapi/logs/issues/${projectShortName}/sessions/createByNaturalId`
        : `/restapi/logs/issues/${projectShortName}/sessions`,
      obj,
      { obj }
    ),
  };
};
export const updateSessionTask = (projectShortName, obj = {}) => {
  return {
    type: UPDATE_SESSION_TASK,
    payload: axios._put(`/restapi/logs/issues/${projectShortName}/sessions/${obj.issueId}`, obj, { obj: obj }),
  };
};
export const getTaskLabel = () => {
  return {
    type: LOAD_TASK_LABELS,
    payload: axios.get(`/restapi/logs/labels/groups`),
  };
};
export const setSelectMarks = (value, type, id) => {
  return {
    type: SET_SELECTED_MARKS,
    value: value,
    markType: type,
    id: id,
  };
};
export const setSelectPhraseLabels = (value, id) => {
  return {
    type: SET_SELECT_PHRASE_LABELS,
    value: value,
    id: id,
  };
};
export const setSelectLogItemMarks = (value, type, id) => {
  return {
    type: SET_SELECTED_LOG_MARKS,
    value: value,
    markType: type,
    id: id,
  };
};
export const mainSelectMarks = (value, type) => {
  return {
    type: MAIN_SELECTED_MARKS,
    value: value,
    markType: type,
  };
};

export const setSessionIssueAsEditing = (flag = true) => {
  return {
    type: MARK_SESSION_ISSUE,
    flag,
  };
};
export const selectMainMarks = (value, type) => {
  return {
    type: SET_SELECTED_MAIN_MARKS,
    value: value,
    markType: type,
  };
};

export const getIssuesByQuestionId = (projectShortname, sessionId, questionsId = []) => {
  return {
    type: GET_ISSUES_BY_QUESTION_ID,
    payload: axios.get(
      `/restapi/logs/issues/${projectShortname}/session-issues/byQuestionIdList?questionIds=${questionsId.join(
        ','
      )}&sessionId=${sessionId}`
    ),
  };
};
