import React from 'react';
import styles from './styles.module.scss';
import { ICONS } from '@just-ai/just-ui/dist/Icon';
import { Icon } from '@just-ai/just-ui/dist';
import cn from 'classnames';

export enum BannerType {
  DEFAULT,
  DANGER,
  INFO,
  WARNING,
  SUCCESS,
}

const stylesMap: Record<
  BannerType,
  {
    icon: ICONS | null;
    className: string;
  }
> = {
  [BannerType.DEFAULT]: {
    icon: null,
    className: styles.Banner__default,
  },
  [BannerType.DANGER]: {
    icon: 'faInfoCircle',
    className: styles.Banner__danger,
  },
  [BannerType.INFO]: {
    icon: 'faInfoCircle',
    className: styles.Banner__info,
  },
  [BannerType.WARNING]: {
    icon: 'faExclamationTriangle',
    className: styles.Banner__warning,
  },
  [BannerType.SUCCESS]: {
    icon: 'faCheckSquare',
    className: styles.Banner__success,
  },
};

interface BannerInterface {
  content: React.ReactNode;
  title?: string;
  withoutIcon?: boolean;
  type?: BannerType;
  className?: string;
  onClose?: () => void;
}
const Banner = function ({ title, content, className, withoutIcon, onClose, type = BannerType.INFO }: BannerInterface) {
  const style = stylesMap[type];
  return (
    <div className={cn(styles.Banner, style.className, className)}>
      {!withoutIcon && style.icon && <Icon wrapperClassName={styles.icon} name={style.icon} size='md' />}
      <div>
        {title && (
          <div className='margin-bottom-2x title'>
            <b>{title}</b>
          </div>
        )}
        <div className='description'>{content}</div>
      </div>
      {onClose && (
        <Icon
          wrapperClassName='cursor-pointer'
          name='farTimes'
          size='md'
          onClick={onClose}
          data-test-id='Banner:Close'
        />
      )}
    </div>
  );
};

export default React.memo(Banner);
