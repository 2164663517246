import React, { FC } from 'react';
import { PositionType } from './index';

export const PseudoSelection: FC<{ position: PositionType }> = ({ position }) => {
  return (
    <div
      className='selection'
      style={{ top: position.top, left: position.left, width: position.width, height: position.height }}
    />
  );
};
