import React from 'react';
import {
  Dropdown,
  DropdownToggle,
  Icon,
  CustomRxTooltip,
  useToggle,
  DropdownMenu,
  DropdownItem,
  IconButton,
} from '@just-ai/just-ui';

import { t } from 'localization';

import { HierarchyViewContext } from '../index';

import styles from './styles.module.scss';

function HierarchyHeader() {
  const { setCollapseForAll, closeView } = React.useContext(HierarchyViewContext);
  const [dropdownOpened, , , toggleDropdown] = useToggle(false);

  return (
    <div className={styles.HierarchyHeader}>
      <div className={styles.HierarchyHeader__title}>{t('JGraph:Hierarchy:Title')}</div>
      <div className={styles.HierarchyHeader__actions}>
        <Dropdown direction='down' isOpen={dropdownOpened} toggle={toggleDropdown}>
          <DropdownToggle compact iconButton size='sm' data-test-id='JGraph.Hierarchy.Actions.Dots'>
            <Icon id='JGraphHierarchyActionsDots' name='farEllipsisH' color='secondary' onClick={() => {}} />
            <CustomRxTooltip target='#JGraphHierarchyActionsDots' debounceTime={800} withoutArrow placement='bottom'>
              {t('JGraph:Hierarchy:Actions:Dots:Tooltip')}
            </CustomRxTooltip>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setCollapseForAll(true)} data-test-id='JGraph.Hierarchy.Actions.unCollapseAll'>
              <Icon name='farExpandAlt' />
              {t('JGraph:Hierarchy:Actions:ExpandAll')}
            </DropdownItem>
            <DropdownItem onClick={() => setCollapseForAll(false)} data-test-id='JGraph.Hierarchy.Actions.collapseAll'>
              <Icon name='farCompressAlt' />
              {t('JGraph:Hierarchy:Actions:CollapseAll')}
            </DropdownItem>
            <DropdownItem
              tag='a'
              href={t('JGraph:Hierarchy:Actions:Help:Url')}
              target='_blank'
              data-test-id='JGraph.Hierarchy.Actions.Help'
            >
              <Icon name='farQuestionCircle' />
              {t('JGraph:Hierarchy:Actions:Help')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <IconButton
          id='JGraphHierarchyActionsHide'
          name='farMinus'
          flat
          color='secondary'
          onClick={closeView}
          data-test-id='JGraph.Hierarchy.Actions.Close'
          size='md'
          square
        />
        <CustomRxTooltip target='#JGraphHierarchyActionsHide' debounceTime={800} withoutArrow placement='bottom'>
          {t('JGraph:Hierarchy:Actions:Hide:Tooltip')}
        </CustomRxTooltip>
      </div>
    </div>
  );
}

HierarchyHeader.displayName = 'HierarchyHeader';

export default React.memo(HierarchyHeader);
