import React from 'react';

import { t } from 'localization';

import { useKhubTokenValidationResolver } from '../../domain/validation';
import { AddExistedTokenByName, TokenType } from '../../external';

interface AddExistedKhubTokenByNameProps {
  secretName: string;
  isOpen: boolean;
  onSubmit?: (payload: TokenType) => void;
  onCancel?: () => void;
}
const AddExistedKhubTokenByName = (props: AddExistedKhubTokenByNameProps) => {
  const resolver = useKhubTokenValidationResolver();

  return (
    <AddExistedTokenByName
      title={t('KhubIntegration:AddExistedTokenByNameModal:Title')}
      buttonSubmitText={t('KhubIntegration:AddExistedTokenByNameModal:Submit')}
      buttonCancelText={t('KhubIntegration:AddExistedTokenByNameModal:Cancel')}
      valueFieldLabel={t('KhubIntegration:AddExistedTokenByNameModal:FieldLabel')}
      valueFieldPlaceholder={t('KhubIntegration:AddExistedTokenByNameModal:FieldPlaceholder')}
      key={props.secretName}
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
      secretName={props.secretName}
      resolver={resolver}
      reValidateMode='onSubmit'
    />
  );
};

AddExistedKhubTokenByName.displayName = 'AddExistedKhubTokenByName';

export default React.memo(AddExistedKhubTokenByName);
