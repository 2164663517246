import { useScreenContext } from '../../../hooks';
import { useJGEventsContext } from '../EventsGroup';
import { RefObject, useEffect } from 'react';
import { FromStateTransition, TConnector } from '../../../../../reducers/JGraph.reducer/types';
import { FromStateConnectionsStore, getBlockConnections } from '../../../../../reducers/JGraph.reducer/Graph';
import Konva from 'konva';
import { getStageContextFromRef } from '../../../utils/stageUtils';
import { TagNames } from '../../../utils/types';

export const useScreenCollapseConnections = (collapsed: boolean, circleRef: RefObject<Konva.Circle>, name?: string) => {
  const { screen } = useScreenContext();
  const { fromStateTransition = [] } = useJGEventsContext();

  useEffect(() => {
    let outerConnections: TConnector[] | undefined;
    if (!circleRef.current) return;
    if (!circleRef.current.getStage()) return;
    const { ConnectorsSubject$, connectorsFromPipe$ } = getStageContextFromRef(circleRef);
    let outerFromStateTransitions: FromStateTransition[] | undefined; //they are from another blocks TODO
    if (collapsed && circleRef.current) {
      let [connections] = getBlockConnections(screen, new FromStateConnectionsStore({}), screen.blocks);
      if (name?.endsWith('reactions')) {
        outerConnections = connections.filter(connect => !connect.from.includes('fromState'));
        outerConnections.forEach(connection => {
          ConnectorsSubject$.next({ type: 'add', connector: connection });
          connectorsFromPipe$.next({
            [connection.from]: {
              fromRef: circleRef.current!,
            },
          });
        });
      }
      if (name?.endsWith('events')) {
        outerFromStateTransitions = fromStateTransition;
        fromStateTransition.forEach(descriptor => {
          ConnectorsSubject$.next({ type: 'add', connector: descriptor.connectionRef });
          connectorsFromPipe$.next({
            [descriptor.connectionRef.from]: {
              fromRef: circleRef.current!,
            },
          });
        });
        connections.forEach(connection => {
          if ([TagNames.q, TagNames.event, TagNames.intent].includes(connection.tagName as TagNames)) {
            if (!outerConnections) outerConnections = [];
            outerConnections.push(connection);
            ConnectorsSubject$.next({ type: 'add', connector: connection });
            connectorsFromPipe$.next({
              [connection.from]: {
                fromRef: circleRef.current!,
              },
            });
          }
        });
      }
    }

    return () => {
      if (outerConnections) {
        outerConnections.forEach(connection => {
          ConnectorsSubject$.next({ type: 'remove', connector: connection });
          connectorsFromPipe$.next({
            [connection.from]: {
              fromRef: undefined,
            },
          });
        });
      }
      if (outerFromStateTransitions) {
        outerFromStateTransitions.forEach(connection => {
          ConnectorsSubject$.next({ type: 'remove', connector: connection.connectionRef });
          connectorsFromPipe$.next({
            [connection.connectionRef.from]: {
              fromRef: undefined,
            },
          });
        });
      }
    };
  }, [screen, collapsed, name, fromStateTransition, circleRef]);
};
