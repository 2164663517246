var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import './Spinner.scss';
const SIZES = {
    xs: 8,
    sm: 12,
    '1x': 16,
    md: 16,
    lg: 24,
    xl: 32,
    '2x': 32,
    '3x': 40,
    '4x': 64,
    '5x': 80,
    '6x': 96,
    '7x': 112,
    '8x': 128,
    '9x': 144,
    '10x': 160,
};
const containerStyle = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    cursor: 'wait',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
};
export var Colors;
(function (Colors) {
    Colors["primary"] = "primary";
    Colors["secondary"] = "gray";
    Colors["gray"] = "gray";
    Colors["indigo"] = "indigo";
    Colors["success"] = "green";
    Colors["green"] = "green";
    Colors["blue"] = "blue";
    Colors["red"] = "red";
    Colors["yellow"] = "yellow";
    Colors["warning"] = "yellow";
    Colors["white"] = "white";
    Colors["danger"] = "red";
})(Colors || (Colors = {}));
export var BackgroundType;
(function (BackgroundType) {
    BackgroundType["main"] = "var(--main-spinner-background)";
    BackgroundType["compact"] = "var(--compact-spinner-background)";
    BackgroundType["without"] = "without";
})(BackgroundType || (BackgroundType = {}));
function getColor(color) {
    if (color && Object.keys(Colors).includes(color))
        return `var(--${Colors[color]})`;
    if (color)
        return color;
    return 'var(--primary)';
}
function getBackground({ size, backgroundType, color, bluredBackground, }) {
    if (bluredBackground) {
        return { backdropFilter: 'blur(3px)' };
    }
    if (color)
        return { backgroundColor: color };
    if (backgroundType)
        return { backgroundColor: backgroundType };
    if (size === '2x')
        return { backgroundColor: 'var(--compact-spinner-background)' };
    if (backgroundType === BackgroundType.without)
        return { backgroundColor: 'none' };
    return { backgroundColor: 'var(--main-spinner-background)' };
}
export const Spinner = (_a) => {
    var { style, children, hidden = false, inline, size, color, animationTime = 1000, onUnmount, backgroundType, backgroundColor, bluredBackground } = _a, restProps = __rest(_a, ["style", "children", "hidden", "inline", "size", "color", "animationTime", "onUnmount", "backgroundType", "backgroundColor", "bluredBackground"]);
    useEffect(() => {
        return () => {
            onUnmount && onUnmount();
        };
    }, []);
    if (hidden)
        return null;
    const props = Object.assign({ color: getColor(color), animationTime: animationTime, 'data-test-id': 'spinner', style }, restProps);
    return inline ? (_jsx(SpinnerBody, Object.assign({ size: SIZES[size || '1x'] }, props))) : (_jsxs("div", { className: 'just-ui-spinner', style: Object.assign(Object.assign(Object.assign({}, containerStyle), style), getBackground({ size, backgroundType, color: backgroundColor, bluredBackground })), "data-test-id": 'spinner', children: [_jsx(SpinnerBody, Object.assign({ size: SIZES[size || '7x'] }, props)), children] }));
};
function SpinnerBody(_a) {
    var { size = 40, color = 'var(--primary)', animationTime = 1000, style } = _a, restProps = __rest(_a, ["size", "color", "animationTime", "style"]);
    let styleWithTime = { animationDuration: `${animationTime}ms` };
    if (style) {
        styleWithTime = Object.assign(Object.assign({}, style), styleWithTime);
    }
    return (_jsxs("svg", Object.assign({ className: 'just-ui_spinner_body', width: size, height: size, viewBox: '0 0 40 40', fill: 'none', style: styleWithTime }, restProps, { children: [_jsx("path", { opacity: '0.3', fillRule: 'evenodd', clipRule: 'evenodd', d: 'M20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z', fill: color }), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.7618 0.997777C16.7654 0.0117154 19.96 -0.248271 23.0836 0.239143C26.2071 0.726557 29.1707 1.94748 31.7311 3.80179C34.2915 5.65609 36.3759 8.09094 37.8132 10.9066C39.2506 13.7223 40 16.8386 40 20C40 21.3807 38.8807 22.5 37.5 22.5C36.1193 22.5 35 21.3807 35 20C35 17.629 34.438 15.2917 33.3599 13.18C32.2819 11.0682 30.7186 9.24207 28.7983 7.85134C26.878 6.46061 24.6554 5.54492 22.3127 5.17936C19.97 4.8138 17.5741 5.00879 15.3213 5.74833C14.0095 6.17899 12.597 5.46466 12.1663 4.15283C11.7356 2.841 12.45 1.42844 13.7618 0.997777Z', fill: color })] })));
}
