import React, { FC, useEffect, useState } from 'react';

import { hiddenConnectionsPipe$ } from '../../hooks/hiddenConnections';
import { SplineConnector } from './SplineConnector';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { IslandConnectors } from './IslandConnectors';

export type ConnectorProps = {
  connector: TConnector;
};

export const Connector: FC<ConnectorProps> = React.memo(({ connector }) => {
  const { toNodeOriginalPath, fromNodeOriginalPath } = connector;

  const [isIslandConnector, setIslandConnector] = useState(false);

  useEffect(() => {
    const sub = hiddenConnectionsPipe$.subscribe(value => {
      setIslandConnector(value.to.has(toNodeOriginalPath) || value.from.has(fromNodeOriginalPath));
    });

    return () => sub.unsubscribe();
  }, [fromNodeOriginalPath, toNodeOriginalPath]);

  if (isIslandConnector) return <IslandConnectors connector={connector} />;

  return <SplineConnector connector={connector} />;
});
