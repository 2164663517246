import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Icon } from '../Icon';
import { DropdownMenu, DropdownItem, Dropdown } from '../Dropdown';
import { formatNumberValue } from './utils/utils';
import classNames from 'classnames';
import 'flag-icon-css/css/flag-icon.css';
import './style/phoneNumber.scss';
import { useOnClickOutside } from '../utils';
import parsePhoneNumberFromString from 'libphonenumber-js';
import InputMask from 'react-input-mask';
const PhoneNumber = ({ onChange, phoneNumber, focused, disabled = false, countryList, placeholderSearch, selectFixedPosition, error, }) => {
    const [code, setCode] = useState(phoneNumber.code);
    const [number, setNumber] = useState(phoneNumber.number);
    const [country, setCountry] = useState(phoneNumber.country);
    const countryListRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const onChangeNumber = useCallback(event => {
        setNumber(event.target.value);
    }, [code, country]);
    const onNumberPaste = useCallback(e => {
        const number = parsePhoneNumberFromString(e.clipboardData.getData('Text'));
        if (number === null || number === void 0 ? void 0 : number.country) {
            e.preventDefault();
            const countryByParsedIsoCode = countryList.find(country => number.country === country[0]);
            const phoneCode = countryByParsedIsoCode === null || countryByParsedIsoCode === void 0 ? void 0 : countryByParsedIsoCode[3];
            const phoneNumber = number.number.replace(phoneCode, '').replace('+', '');
            setCountry(number.country.toLowerCase());
            setCode(phoneCode);
            setNumber(phoneNumber);
        }
    }, [code, country]);
    useEffect(() => {
        if (selectFixedPosition) {
            const setPositionTimeout = setTimeout(() => {
                var _a;
                const phoneNumberContainerCoords = (_a = phoneNumberRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (countryListRef.current && phoneNumberContainerCoords) {
                    countryListRef.current.style.position = 'fixed';
                    countryListRef.current.style.top = phoneNumberContainerCoords.y + phoneNumberContainerCoords.height + 'px';
                    countryListRef.current.style.left = phoneNumberContainerCoords.x + 'px';
                    countryListRef.current.style.width = phoneNumberContainerCoords.width + 'px';
                }
            }, 1000);
            return () => clearTimeout(setPositionTimeout);
        }
    }, [selectFixedPosition]);
    useEffect(() => {
        onChange && onChange({ code, number, country });
        return;
    }, [code, number, country, onChange]);
    useEffect(() => {
        const formattedNumberValue = formatNumberValue(code, number, country);
        setNumber(formattedNumberValue);
    }, [code, country]);
    useEffect(() => {
        if (phoneNumber.number === '')
            setNumber('');
    }, [phoneNumber]);
    useEffect(() => {
        setCountry(phoneNumber.country);
    }, [phoneNumber.country]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'phone-container', children: [_jsx("div", { className: classNames('phone-wrapper', { focused, error }), ref: phoneNumberRef, children: code === '7' && country !== 'kz' ? (_jsx(InputMask, { mask: '(999) 999-99-99', 
                        // @ts-ignore
                        maskChar: null, onChange: onChangeNumber, onPaste: onNumberPaste, value: number, className: 'phone-input', type: 'tel', disabled: disabled })) : (_jsx("input", { className: 'phone-input', type: 'tel', id: 'phoneNumber', name: 'phoneNumber', defaultValue: number, value: number, autoFocus: true, onChange: onChangeNumber, onPaste: onNumberPaste, disabled: disabled })) }), _jsx(PhoneNavigation, { code: phoneNumber.code, country: phoneNumber.country, countryList: countryList, setNumberCode: setCode, placeholderSearch: placeholderSearch, countryListRef: countryListRef, setCountry: setCountry, disabled: disabled })] }) }));
};
const PhoneNavigation = ({ code, country, countryList, setNumberCode, placeholderSearch, countryListRef, setCountry, disabled = false, }) => {
    const [showChooseCountry, setShowChooseCountry] = useState(false);
    const [isoCode, setIsoCode] = useState('ru');
    useEffect(() => {
        const choosedCountryItem = countryList.find(countryItem => country ? countryItem[0].toLowerCase() === country : countryItem[3] === code);
        if (choosedCountryItem) {
            setIsoCode(choosedCountryItem[0].toLowerCase());
            setCountry(choosedCountryItem[0].toLowerCase());
            setNumberCode(choosedCountryItem[3].toLowerCase());
        }
    }, [code, country]);
    const onToggleShowChooseCountry = useCallback(() => setShowChooseCountry(!showChooseCountry), [showChooseCountry]);
    useOnClickOutside(countryListRef, () => setShowChooseCountry(false));
    return (_jsxs(_Fragment, { children: [_jsxs("button", { className: 'phone-nav', onClick: onToggleShowChooseCountry, disabled: disabled, type: 'button', children: [_jsx("div", { className: 'phone-nav__flag', children: _jsx("span", { className: `flag flag-icon flag-icon-${isoCode}` }) }), _jsx("div", { className: 'phone-nav__btn', children: _jsx(Icon, { name: 'faJustSelect' }) }), _jsxs("div", { className: 'phone-nav__code-number', children: ["+", code] })] }), _jsx(CountryList, { countryList: countryList, isOpen: showChooseCountry, setIsoCode: setIsoCode, setNumberCode: setNumberCode, placeholderSearch: placeholderSearch, countryListRef: countryListRef, onToggleShowChooseCountry: onToggleShowChooseCountry, setCountry: setCountry })] }));
};
const CountryList = ({ countryList, isOpen, setIsoCode, setNumberCode, placeholderSearch, countryListRef, onToggleShowChooseCountry, setCountry, }) => {
    const [search, setSearch] = useState('');
    const onSearch = useCallback(event => setSearch(event.target.value), []);
    const filteredCountryList = useMemo(() => {
        if (search.trim().length === 0)
            return countryList;
        const donwcaseSearch = search.toLowerCase();
        return countryList.filter((country) => country[2].includes(donwcaseSearch) || country[3].includes(donwcaseSearch));
    }, [countryList, search]);
    return (_jsx("div", { ref: countryListRef, children: _jsx(Dropdown, { isOpen: isOpen, toggle: () => { }, children: _jsxs(DropdownMenu, { className: 'country-list-menu', children: [_jsxs("div", { className: 'country-list-search', children: [_jsx("input", { className: 'country-list-search__input', placeholder: placeholderSearch, value: search, onChange: onSearch }), _jsx("div", { className: 'country-list-search__icon', children: _jsx(Icon, { name: 'faSearch' }) })] }), _jsx("div", { className: 'country-list', children: filteredCountryList.map(country => (_jsx(CountryItem, { countryName: country[1], countryISOCode: country[0], countryNumberCode: country[3], setNumberCode: setNumberCode, setIsoCode: setIsoCode, onToggleShowChooseCountry: onToggleShowChooseCountry, setCountry: setCountry }, `country_${country[0]}`))) })] }) }) }));
};
const CountryItem = ({ countryName, countryISOCode, countryNumberCode, setIsoCode, setNumberCode, onToggleShowChooseCountry, setCountry, }) => {
    return (_jsx(DropdownItem, { onClick: () => {
            setIsoCode(countryISOCode.toLowerCase());
            setCountry(countryISOCode.toLowerCase());
            setNumberCode(countryNumberCode);
            onToggleShowChooseCountry();
        }, children: _jsxs("div", { className: 'country', children: [_jsx("div", { className: 'country__flag', children: _jsx("span", { className: `flag flag-icon flag-icon-${countryISOCode.toLowerCase()}` }) }), _jsx("div", { className: 'country__name', children: countryName }), _jsxs("div", { className: 'country__code', children: ["+", countryNumberCode] })] }) }));
};
export default React.memo(PhoneNumber);
