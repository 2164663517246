import React, { FC, useEffect, useState } from 'react';
import { CustomRxTooltip, IconButton, useId } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from '../styles.module.scss';

import { t } from '../../../../../localization';
import { hiddenConnections$, hiddenConnectionsPipe$ } from 'modules/JGraph/hooks/hiddenConnections';

export const ToggleConnectionsButtons: FC<{ screenPath: string }> = ({ screenPath }) => {
  const idFrom = useId();
  const idTo = useId();

  const [isActiveFrom, setActiveFrom] = useState(false);
  const [isActiveTo, setActiveTo] = useState(false);

  useEffect(() => {
    const sub = hiddenConnectionsPipe$.subscribe(value => {
      setActiveFrom(value.from.has(screenPath));
      setActiveTo(value.to.has(screenPath));
    });
    return () => sub.unsubscribe();
  }, [screenPath]);

  return (
    <>
      <IconButton
        flat
        data-test-id='JGraph.LabellingToolMenu.ToggleIncomingConnectionsButton'
        id={idTo}
        color='secondary'
        wrapperIconClassName='d-flex'
        name={isActiveTo ? 'faIncomingIsland' : 'faIncomingRightArrow'}
        className={cn(styles.selectLabelColor, {
          [styles.selectLabelColor_fillBlue]: isActiveTo,
          [styles.selectLabelColor_fillBlue_active]: isActiveTo,
        })}
        onClick={() => hiddenConnections$.next({ to: screenPath, action: isActiveTo ? 'remove' : 'add' })}
      />
      <CustomRxTooltip target={`#${idTo}`} placement='bottom'>
        {isActiveTo
          ? t('JGraph:LabellingToolMenu:ToggleIncomingConnectionsButton:tooltip_off')
          : t('JGraph:LabellingToolMenu:ToggleIncomingConnectionsButton:tooltip_on')}
      </CustomRxTooltip>
      <IconButton
        flat
        data-test-id='JGraph.LabellingToolMenu.ToggleOutgoingConnectionsButton'
        id={idFrom}
        color='secondary'
        wrapperIconClassName='d-flex'
        name={isActiveFrom ? 'faOutgoingIsland' : 'faOutgoingRightArrow'}
        className={cn(styles.selectLabelColor, {
          [styles.selectLabelColor_fillBlue]: isActiveFrom,
          [styles.selectLabelColor_fillBlue_active]: isActiveFrom,
        })}
        onClick={() => hiddenConnections$.next({ from: screenPath, action: isActiveFrom ? 'remove' : 'add' })}
      />
      <CustomRxTooltip target={`#${idFrom}`} placement='bottom'>
        {isActiveFrom
          ? t('JGraph:LabellingToolMenu:ToggleOutgoingConnectionsButton:tooltip_off')
          : t('JGraph:LabellingToolMenu:ToggleOutgoingConnectionsButton:tooltip_on')}
      </CustomRxTooltip>
    </>
  );
};
