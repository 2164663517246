import React, { FC, useMemo } from 'react';
import { Text, Group } from 'react-konva';
import Konva from 'konva';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { getStageActionsFromEvent, setCursorOnMouseEnterAndLeave } from 'modules/JGraph/utils/stageUtils';
import { simplifyStateName } from 'modules/JGraph/utils/state';

import { KonvaButton } from './KonvaButton';

const textProps = (screenPath: string) => ({
  fontSize: 14,
  fontStyle: 'bold',
  fontFamily: 'Roboto',
  height: 20,
  letterSpacing: 0.75,
  lineHeight: 20 / 14,
  text: simplifyStateName(screenPath),
});

export const ScreenHeader: FC<{ screen: JStateWithId; statePath?: string }> = ({ statePath = '', screen }) => {
  const textPathProps = useMemo(() => {
    return textProps(screen.path);
  }, [screen.path]);

  const width = useMemo(() => {
    const textRenderer = new Konva.Text(textPathProps);
    const textRenderWidth = textRenderer.width();
    if (textRenderWidth > 220) {
      return 220 - 24;
    }
    return textRenderWidth;
  }, [textPathProps]);

  return (
    <Group name='header'>
      <Text
        {...textPathProps}
        x={12}
        y={11}
        onMouseEnter={setCursorOnMouseEnterAndLeave()}
        onMouseLeave={setCursorOnMouseEnterAndLeave()}
        ellipsis={true}
        wrap='none'
        width={width}
        /*onClick={event => {
          getStageActionsFromEvent(event).showStateNameEditField(event, block.path);
        }}*/
      />
      {/*<Rect width={18} height={18} fill='#008871' cornerRadius={2} x={modalFlagX} y={10} />
      <Text
        fill='white'
        text='M'
        fontFamily='Roboto'
        fontSize={14}
        lineHeight={1}
        height={20}
        fontStyle='bold'
        x={modalFlagX + 3}
        y={10 + 4}
      />*/}
      <KonvaButton
        x={14 + 220 + 12}
        y={8}
        onClick={event => {
          getStageActionsFromEvent(event).toggleAddingActionsMenu(event, screen.pathId);
        }}
        type='actions'
      />
    </Group>
  );
};
