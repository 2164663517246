import { get } from 'lodash';
function equalFn(a, b) {
    return a === b;
}
export function buildEqualFn(fieldsNames) {
    return (a, b) => {
        return fieldsNames.every(field => get(a, field) === get(b, field));
    };
}
export default function diffInArrays(newElements, oldElements, isSameObjects, isEqualFn) {
    if (!isSameObjects)
        isSameObjects = equalFn;
    if (!isEqualFn)
        isEqualFn = equalFn;
    const toDelete = [];
    const toCreate = [];
    const toUpdate = [];
    const updateDiff = [];
    oldElements.forEach(oldElement => {
        const reference = newElements.find(newElement => isSameObjects(newElement, oldElement));
        if (!reference) {
            toDelete.push(oldElement);
            return;
        }
        if (!isEqualFn(oldElement, reference)) {
            toUpdate.push(reference);
            updateDiff.push([oldElement, reference]);
        }
    });
    newElements.forEach(newElement => {
        const reference = oldElements.find(oldElement => isSameObjects(newElement, oldElement));
        if (!reference) {
            toCreate.push(newElement);
            return;
        }
    });
    return {
        toDelete,
        toCreate,
        toUpdate,
        updateDiff,
    };
}
