import {
  LOGIN,
  LOGOUT,
  SET_CURRENT_USER,
  CHECK_TOKEN,
  DROP_CURRENT_USER,
  DROP_EDITABLE_USER,
  SET_MARKUP_VIEW,
  GET_USER_ACCOUNT,
  CLEAN_LOGIN_ERRORS,
  GET_USER_SUBSCRIPTION,
  CHANGE_USER_SUBSCRIPTION_STATUS,
  ADD_USER_SUBSCRIPTION,
  CHANGE_USER_TARIFF,
  GET_USER_ACCOUNT_PAYMENT_DATA,
  GET_MANUAL_CONTROL_INFO,
} from '../../constants/currentUser.actions';
import { axios } from '../../pipes/functions';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';

const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode: function (input) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    } // Whend

    return output;
  }, // End Function encode

  // public method for decoding
  decode: function (input) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9+/]/g, '');
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }

      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    } // Whend

    output = Base64._utf8_decode(output);

    return output;
  }, // End Function decode

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    let utftext = '';
    string = string.replace(/\r\n/g, '\n');

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    } // Next n

    return utftext;
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = '';
    let i = 0;
    let c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    } // Whend

    return string;
  }, // End Function _utf8_decode
};
export const login = (obj = null, autoLoginToken = null) => {
  if (Boolean(obj)) {
    return {
      type: LOGIN,
      payload: axios.get('/restapi/public/authenticate', {
        headers: {
          Product: 'botadmin',
          Authorization: 'Basic ' + Base64.encode((obj.login || obj.email) + ':' + obj.password),
          'X-Requested-With': 'XMLHttpRequest',
          'Time-Zone-Offset': new Date().getTimezoneOffset(),
        },
      }),
    };
  }
  return {
    type: LOGIN,
    payload: axios.get('/restapi/public/authenticate', {
      params: {
        autoLoginToken,
      },
    }),
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
    payload: axios.get('/logout'),
  };
};

export const cleanLoginErrors = () => {
  return {
    type: CLEAN_LOGIN_ERRORS,
  };
};

export const getCurrentUser = () => {
  let currentUser = null;

  try {
    currentUser = JSON.parse(localStorage.CURRENT_USER);
    if (!currentUser) {
      currentUser = null;
    }
  } catch (e) {
    currentUser = null;
  }

  if (!!currentUser) {
    return {
      type: SET_CURRENT_USER,
      currentUser: currentUser,
    };
  } else {
    return {
      type: DROP_CURRENT_USER,
    };
  }
};

export const setCurrentUser = currentUser => {
  return {
    type: SET_CURRENT_USER,
    currentUser: currentUser,
  };
};

export const dropCurrentUser = () => {
  return {
    type: DROP_CURRENT_USER,
  };
};

export const dropEditableUser = () => {
  return {
    type: DROP_EDITABLE_USER,
  };
};

export const saveMarkupView = markupView => {
  return {
    type: SET_MARKUP_VIEW,
    markupView,
  };
};

export const checkToken = (user, token) => {
  return {
    type: CHECK_TOKEN,
    payload: axios.get(`/restapi/public/user/verify/${user}/${token}`),
  };
};

export const getUserAccount = (obj = {}) => {
  return {
    type: GET_USER_ACCOUNT,
    payload: axios._get(`/restapi/billing/accountTariff/state`, { ...obj }),
  };
};

export const getUserSubscription = (accountId, obj = {}) => {
  const accountsadminSevoce = new AccountsadminService(axios);

  return {
    type: GET_USER_SUBSCRIPTION,
    payload: accountsadminSevoce.getPaymentSubscription(accountId),
  };
};

export const changeSubscription = (accountId, obj = {}) => {
  const accountsadminSevoce = new AccountsadminService(axios);

  return {
    type: CHANGE_USER_SUBSCRIPTION_STATUS,
    payload: accountsadminSevoce.updatePaymentSubscription(accountId, obj),
  };
};

export const addSubscription = (accountId, obj = {}) => {
  const accountsadminSevoce = new AccountsadminService(axios);

  return {
    type: ADD_USER_SUBSCRIPTION,
    payload: accountsadminSevoce.createPaymentSubscription(accountId, obj),
  };
};

export const changeTariff = (obj = {}, params = {}) => {
  return {
    type: CHANGE_USER_TARIFF,
    payload: axios.post(`restapi/billing/tariff/change`, { ...obj, ...params }),
  };
};

export const getPaymentData = (accountId, paymentId, options) => {
  const accountsadminService = new AccountsadminService(axios);

  return {
    type: GET_USER_ACCOUNT_PAYMENT_DATA,
    payload: accountsadminService.getPaymentStatusById(accountId, paymentId, options),
  };
};

/**
 * @typedef ManualControlData
 * @property {(boolean|undefined)} blockAfterPeriod - заблокировать по окончанию периода
 * @property {(string|undefined)} from - дата начала периода
 * @property {('MANUAL_CONTROL' | 'FREE' | undefined)} periodType - тип бесплатного периода
 * @property {(string|undefined)} tariff - тариф на время периода
 * @property {(string|undefined)} tariffAfterPeriod - тариф после периода
 * @property {(string|undefined)} tariffAfterPeriodName - название тарифа после периода
 * @property {(string|undefined)} tariffName - название тарифа на время периода
 * @property {(string|undefined)} to - дата окончания периода
 * @property {('TariffChangeStateDataPeriod' | 'TariffChangeStateDataMonthlyBilling')} type - тип периода
 */
/**
 * @typedef getManualControlInfoReturns
 * @property {(GET_MANUAL_CONTROL_INFO)} type - redux action type
 * @property {(Promise<ManualControlData>)} payload - redux action payload
 */
/**
 * Получение данных о типе периода (оплата картой, бесплатный период, период по договору) и дат периода
 * @param {number} accountId - Id аккаунта
 * @returns getManualControlInfoReturns
 */
export const getManualControlInfo = accountId => {
  return {
    type: GET_MANUAL_CONTROL_INFO,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/tariff/change-state-v2`),
  };
};
