const prefix = '@userLogFilter/';

export const FETCH_USER_LOG = 'FETCH_USER_LOG';
export const FETCH_USER_LOG_PENDING = 'FETCH_USER_LOG_PENDING';
export const FETCH_USER_LOG_REJECTED = 'FETCH_USER_LOG_REJECTED';
export const FETCH_USER_LOG_FULFILLED = 'FETCH_USER_LOG_FULFILLED';

export const FILTER_USER_LOG = 'FILTER_USER_LOG';
export const FILTER_USER_LOG_PENDING = 'FILTER_USER_LOG_PENDING';
export const FILTER_USER_LOG_REJECTED = 'FILTER_USER_LOG_REJECTED';
export const FILTER_USER_LOG_FULFILLED = 'FILTER_USER_LOG_FULFILLED';

export const FILTER_DATA = prefix + 'FILTER_DATA';
export const DROP_FILTER = prefix + 'DROP_FILTER';
