import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../storeHooks';
import { closeStateNameEditField, changeStateName } from 'reducers/JGraph.reducer';
import './StateNameEditField.scss';

export const StateNameEditField: FC = () => {
  const StateNameEditFieldRef = useRef<HTMLInputElement>(null);

  const { stateNameInputValue, stateNameInputPosition } = useAppSelector(rootState => ({
    stateNameInputValue: rootState.JGraphReducer.stateNameInputValue,
    stateNameInputPosition: rootState.JGraphReducer.stateNameInputPosition,
  }));
  const dispatch = useAppDispatch();

  const checkClick = useCallback(
    event => {
      if (StateNameEditFieldRef.current !== event.target) {
        if (stateNameInputValue !== StateNameEditFieldRef.current?.value) {
          dispatch(changeStateName(StateNameEditFieldRef?.current?.value.trim() || ''));
        } else {
          dispatch(closeStateNameEditField());
        }
      }
    },
    [dispatch, stateNameInputValue]
  );
  const checkKey = useCallback(
    event => {
      switch (event.key) {
        case 'Escape': {
          dispatch(closeStateNameEditField());
          break;
        }
        case 'Enter': {
          dispatch(changeStateName(StateNameEditFieldRef?.current?.value.trim() || ''));
          break;
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', checkClick);
      document.addEventListener('mousedown', checkClick);
      document.addEventListener('keydown', checkKey);
    }, 0);

    return () => {
      setTimeout(() => {
        document.removeEventListener('click', checkClick);
        document.removeEventListener('mousedown', checkClick);
        document.removeEventListener('keydown', checkKey);
      }, 0);
    };
  }, [stateNameInputValue, checkClick, checkKey]);

  if (stateNameInputValue) {
    return (
      <input
        ref={StateNameEditFieldRef}
        className='StateNameEditField'
        type='text'
        autoFocus
        defaultValue={stateNameInputValue}
        style={{ left: stateNameInputPosition.x, top: stateNameInputPosition.y }}
      />
    );
  }

  return null;
};
