import { FC, useState, useCallback, useMemo } from 'react';

export const useStepper = (steps: FC[]) => {
  const [stepIndex, setStepIndex] = useState(0);
  const nextStep = useCallback(() => setStepIndex((stepIndex + 1) % steps.length), [stepIndex, steps.length]);
  const prevStep = useCallback(
    () => setStepIndex((steps.length + stepIndex - 1) % steps.length),
    [stepIndex, steps.length]
  );
  const isLastStep = useMemo(() => stepIndex + 1 === steps.length, [stepIndex, steps.length]);

  return {
    stepIndex,
    setStepIndex,
    nextStep,
    prevStep,
    isLastStep,
    stepsCount: steps.length,
  };
};
