import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { SearchInput, useToggle, useTranslation } from '@just-ai/just-ui';
import { findMatchPositionsInText } from '@just-ai/just-ui/dist/utils';
import { assignValidationErrorToPhrase, useDebounce } from '../../../../nlu-related/utils';
import LinkButton from '../IntentFaqEditView/components/LinkButton';
import Collapse from '../IntentFaqEditView/components/Collapse';
import PhraseList from './PhraseList';
function SearchField({ onChange, debounce = 0 }) {
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    useDebounce(value, debounce, onChange);
    return (_jsx(SearchInput, { maxWidth: '50%', clearable: true, alwaysOpen: true, value: value, onClear: () => setValue(''), onChange: setValue, placeholder: t('FAQ:Phrases:SearchField:Placeholder'), "data-test-id": 'FAQ.FAQTree.SearchField' }));
}
function getPhrasesBlockLabel(t, counter) {
    let resultString = t('FAQ:Phrases:AlternativePhrases');
    if (counter && counter > 0) {
        resultString += ` (${counter})`;
    }
    return resultString;
}
function isNeedToShowExpandedView(intentName, items) {
    return items.length > 0;
}
const PhrasesBlock = function ({ name, intentName, items, onChange, writeDisabled, valErrors }) {
    const [isShowExpandedView, , , toggleIsShowExpandedView] = useToggle(isNeedToShowExpandedView(intentName, items));
    const [isCollapseOpened, , , toggleIsCollapseOpened] = useToggle(!!valErrors && !!valErrors.length);
    const [isSearching, setIsSearching] = useState(false);
    const { t } = useTranslation();
    const itemsWithErrors = useMemo(() => {
        if (!valErrors)
            return items.map(item => (Object.assign(Object.assign({}, item), { error: undefined })));
        return assignValidationErrorToPhrase(valErrors, items);
    }, [items, valErrors]);
    const [filteredElements, setFilteredElements] = useState(itemsWithErrors);
    const timeoutId = useRef();
    const onUpdate = useCallback((items) => {
        onChange(items, name);
        if (timeoutId.current)
            clearTimeout(timeoutId.current);
        const filteredList = items.filter((element, index, arr) => {
            delete element.error;
            if (element.text === intentName)
                return false;
            return index === arr.findIndex(t => t.text === element.text);
        });
        if (items.length === filteredList.length)
            return;
        timeoutId.current = setTimeout(() => {
            onChange(filteredList, name);
        }, 1000);
    }, [intentName, name, onChange]);
    const onSearch = useCallback((searchValue) => {
        setIsSearching(!!searchValue);
        if (!searchValue) {
            setFilteredElements(itemsWithErrors);
            return;
        }
        searchValue = searchValue.toLowerCase();
        const filteredItems = itemsWithErrors.reduce((acc, item) => {
            const matches = findMatchPositionsInText(item.text, searchValue);
            if (matches.length === 0)
                return acc;
            acc.push(Object.assign(Object.assign({}, item), { searchMatches: matches }));
            return acc;
        }, []);
        setFilteredElements(filteredItems);
    }, [itemsWithErrors]);
    const focusOnExpand = useCallback(() => {
        const input = document.getElementById('PhraseRow-ForInsertion');
        if (input && input.focus) {
            input.focus();
        }
    }, []);
    if (!isShowExpandedView) {
        return (_jsx(LinkButton, { text: t('FAQ:Phrases:AddAlternativePhrases'), onClick: () => {
                toggleIsShowExpandedView();
                toggleIsCollapseOpened();
            }, dataTestId: 'FAQ.Phrases.AddAlternativePhrases' }));
    }
    return (_jsx(Collapse, { text: getPhrasesBlockLabel(t, isCollapseOpened ? 0 : items.length), isOpened: isCollapseOpened, onToggle: toggleIsCollapseOpened, dataTestId: 'FAQ.PhrasesCollapse', onEntered: focusOnExpand, children: _jsxs("div", { className: 'flex-column gap-3x margin-top-3x', children: [_jsx(SearchField, { debounce: 500, onChange: onSearch }), isSearching && filteredElements.length === 0 ? (_jsx("span", { className: 'margin-bottom-7x', children: t('FAQ:Phrases:NoMatchesFound') })) : (_jsx(PhraseList, { isSearching: isSearching, filteredItems: filteredElements, items: itemsWithErrors, onChange: onUpdate, writeDisabled: writeDisabled || !!(valErrors === null || valErrors === void 0 ? void 0 : valErrors.length) }))] }) }));
};
export default React.memo(PhrasesBlock);
