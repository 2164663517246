import { matchesFilter, PhraseItem, prefixed } from 'modules/Caila/utils/phrases';
import { LogLabelingReducer, LogLabelingState } from '.';
import { Phrases, Groups, uniquify } from '../../../utils';
import { GroupTreeNode, GroupInfo } from '../../../utils/groups';
import { filterAllPhrases } from './grouping.reducer';

const addPhrases = (group: GroupTreeNode<string>, indexes: string[]) => ({
  ...group,
  phrases: uniquify([...group.phrases, ...indexes]),
});

const removePhrases = (group: GroupTreeNode<string>, phrases: string[]) => ({
  ...group,
  phrases: group.phrases.filter(phrase => !phrases.includes(phrase)),
});

const filterStagedPhrases = (stagedPhrases: PhraseItem[], filter?: string) => {
  if (filter === '' || !filter) {
    return stagedPhrases;
  }
  return stagedPhrases.filter(phrase => matchesFilter(phrase, filter));
};

const selectIntent = (state: LogLabelingState<string>, selectedIds: string[], filter?: string) =>
  selectedIds.length > 0
    ? {
        ...state,
        selectedGroupIds: [selectedIds[0]],
        phrases: Phrases.sortDataset(
          Phrases.selectStagingGroupPhrases(
            state.groups[selectedIds[0]].phrases,
            filterStagedPhrases(state.stagedPhrases, filter)
          )
        ),
      }
    : { ...state, selectedGroupIds: [], phrases: {} };

const removePhrasesFromCurrentIntent = (
  state: LogLabelingState<string>,
  phrasesIds: string[]
): LogLabelingState<string> => {
  const stagedPhrases = [...state.stagedPhrases];
  const fromNodeId = state.selectedGroupIds[0];

  const updatedStagedPhrases = stagedPhrases?.filter(stagedPhrase => !phrasesIds.includes(prefixed(stagedPhrase.id)));

  return selectIntent({ ...state, stagedPhrases: updatedStagedPhrases }, [fromNodeId]);
};

const movePhrasesFromSelectedIntent = (
  state: LogLabelingState<string>,
  phrases: string[],
  intentIdTo: number
): LogLabelingState<string> => {
  const groups = { ...state.groups };
  const fromNodeId = state.selectedGroupIds[0];
  const to = Object.values(state.groups).find(group => group.intentId === intentIdTo);
  if (!to) return { ...state };

  const phraseValues = phrases.map(phrase => state.phrases[phrase]?.text);

  groups[fromNodeId] = removePhrases(groups[fromNodeId], phraseValues);
  groups[to.nodeId] = addPhrases(groups[to.nodeId], phraseValues);
  Groups.fillChildrenPhrasesCount(groups);

  return selectIntent({ ...state, groups }, [fromNodeId]);
};

const initReducer = (state: LogLabelingState<string>, allGroups: GroupInfo[]) => {
  return filterAllPhrases({
    ...state,
    groups: Groups.toTreeDatasetForStaging(allGroups),
    showLabeled: true,
    isTree: true,
  });
};

const StagingReducer: LogLabelingReducer<string> = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initReducer(state, action.allGroups);
    case 'DELETE_PHRASES':
    case 'CLEAR_PHRASES':
      return removePhrasesFromCurrentIntent(state, action.indexes);
    case 'UPDATE_INTENTS':
      return { ...state, groups: Groups.toTreeDatasetForStaging(action.allGroups || []) };
    case 'STAGE_PHRASES':
      return typeof action.intentId === 'number'
        ? movePhrasesFromSelectedIntent(state, action.indexes, action.intentId)
        : { ...state };
    case 'SET_PHRASES_FILTER':
      return selectIntent(state, state.selectedGroupIds, action.value);
    case 'SELECT_GROUPS':
      return selectIntent(state, action.groupIds);
    default:
      return { ...state };
  }
};

export default StagingReducer;
