export class AnalyticsActiveFilters {
    constructor() {
        this.filters = [];
        this.set = (values) => {
            this.filters = values;
        };
        this.get = () => {
            return this.filters;
        };
    }
}
