export const tasksLocalization = {
  'Tasks tags': {
    eng: 'Task labels',
    ru: 'Метки для задач',
    cn: '任务标签',
  },
  'tasks modal title': {
    eng: 'Background task',
    ru: 'Фоновая задача',
  },
  'tasks modal submit button': {
    eng: 'OK',
    ru: 'Понятно',
  },
  'tasks modal dont show': {
    eng: 'Don’t show this warning',
    ru: 'Не показывать это предупреждение',
  },
  'tasks modal title editorbe.deploy.bot': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_created': {
    eng: 'Text campaign created',
    ru: 'Текстовая рассылка создана',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_ready': {
    eng: 'Text campaign scheduled',
    ru: 'Текстовая рассылка запланирована',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_started': {
    eng: 'Text campaign started',
    ru: 'Текстовая рассылка запущена',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_failed': {
    eng: 'Failed to start text campaign',
    ru: 'Ошибка запуска текстовой рассылки',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_postponed': {
    eng: 'Texting has been postponed',
    ru: 'Текстовая рассылка отложена',
  },
  'tasks title text_campaign_service.reports.text_campaign_created': {
    eng: 'Text campaign created',
    ru: 'Текстовая рассылка создана',
  },
  'tasks title text_campaign_service.reports.text_campaign_failed': {
    eng: 'Failed to start text campaign',
    ru: 'Ошибка запуска текстовой рассылки',
  },
  'tasks title text_campaign_service.reports.text_campaign_ready': {
    eng: 'Text campaign scheduled',
    ru: 'Текстовая рассылка запланирована',
  },
  'tasks title text_campaign_service.reports.text_campaign_started': {
    eng: 'Text campaign started',
    ru: 'Текстовая рассылка запущена',
  },
  'tasks title text_campaign_service.reports.text_campaign_deleted': {
    eng: 'Text campaign deleted',
    ru: 'Текстовая рассылка удалена',
  },
  'tasks title text_campaign_service.reports.text_campaign_completed': {
    eng: 'Text campaign completed',
    ru: 'Текстовая рассылка завершена',
  },
  'tasks title text_campaign_service.reports.text_campaign_progress': {
    eng: 'Text campaign is in progress',
    ru: 'Текстовая рассылка в работе',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_completed': {
    eng: 'Text campaign completed',
    ru: 'Текстовая рассылка завершена',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_deleted': {
    eng: 'Text campaign deleted',
    ru: 'Текстовая рассылка удалена',
  },
  'tasks modal title text_campaign_service.reports.text_campaign_progress': {
    eng: 'Text campaign is in progress',
    ru: 'Текстовая рассылка в работе',
  },
  'tasks modal help text editorbe.deploy.bot': {
    eng: 'Deploying the project to the channel will take some time. You will receive a notification once the project has been deployed.',
    ru: 'Публикация проекта в канал займет некоторое время. Вы получите оповещение после того, как проект будет опубликован.',
  },
  'tasks modal help text link editorbe.deploy.bot': {
    eng: 'Channel details',
    ru: 'Подробнее о каналах',
  },
  'tasks title editorbe.deploy.bot': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks modal title editorbe.deploy.bot.info': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks modal help text editorbe.deploy.bot.info': {
    eng: 'Deploying the project to the channel will take some time. You will receive a notification once the project has been deployed.',
    ru: 'Публикация проекта в канал займет некоторое время. Вы получите оповещение после того, как проект будет опубликован.',
  },
  'tasks modal help text link editorbe.deploy.bot.info': {
    eng: 'Channel details',
    ru: 'Подробнее о каналах',
  },
  'tasks title editorbe.deploy.bot.info': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks title editorbe.deploy.bot.finished': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks title editorbe.deploy.bot.failed': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks title editorbe.deploy.bot.test_failed': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'tasks today': {
    eng: 'Today',
    ru: 'Сегодня',
  },
  'tasks yesterday': {
    eng: 'Yesterday',
    ru: 'Вчера',
  },
  'tasks delete completed button': {
    eng: 'Delete Completed',
    ru: 'Удалить завершенные',
  },
  'tasks delete button': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'tasks generating label': {
    eng: 'Generating...',
    ru: 'Формируется...',
  },
  'tasks start task': {
    eng: 'Task start',
    ru: 'Старт задачи',
  },
  'tasks end task': {
    eng: 'End task',
    ru: 'Задача завершена',
  },
  'tasks warning': {
    eng: 'Warning',
    ru: 'Предупреждение',
  },
  'tasks errors': {
    eng: 'Errors',
    ru: 'Ошибки',
  },
  'tasks tabs all': {
    eng: 'All',
    ru: 'Все',
  },
  'tasks tabs errors': {
    eng: 'Errors',
    ru: 'Ошибки',
  },
  'tasks tabs progress': {
    eng: 'In progress',
    ru: 'В процессе',
  },
  'tasks tabs done': {
    eng: 'Done',
    ru: 'Завершённые',
  },
  'tasks task list': {
    eng: 'Tasks list',
    ru: 'Список задач',
  },
  'tasks task list empty': {
    eng: 'Tasks list is empty',
    ru: 'Список задач пуст',
  },
  'tasks publication start popover': {
    eng: 'Project deployment has started',
    ru: 'Публикация проекта началась',
  },
  'tasks tooltip text': {
    eng: 'Background tasks',
    ru: 'Фоновые задачи',
  },
  'tasks modal title reporter.reports.report_generation_task_created': {
    eng: 'Report generation will take some time',
    ru: 'Формирование отчета займет некоторое время',
  },
  'tasks modal help text reporter.reports.report_generation_task_created': {
    eng: 'You will receive a notification once the report has been generated.',
    ru: 'Вы получите оповещение, когда отчет будет сформирован.',
  },
  'tasks title reporter.reports.report_generation_task_completed': {
    eng: 'Log export',
    ru: 'Экспорт логов',
  },
  'tasks title reporter.reports.report_generation_task_created': {
    eng: 'Log export',
    ru: 'Экспорт логов',
  },
  'tasks title reporter.reports.report_generation_task_started': {
    eng: 'Log export',
    ru: 'Экспорт логов',
  },
  'tasks title reporter.reports.report_generation_task_completedSESSIONS': {
    eng: 'Session log export',
    ru: 'Экспорт логов сессий',
  },
  'tasks title reporter.reports.report_generation_task_createdSESSIONS': {
    eng: 'Session log export',
    ru: 'Экспорт логов сессий',
  },
  'tasks title reporter.reports.report_generation_task_startedSESSIONS': {
    eng: 'Message log export',
    ru: 'Экспорт логов сессий',
  },
  'tasks title reporter.reports.report_generation_task_completedMESSAGES': {
    eng: 'Message log export',
    ru: 'Экспорт логов сообщений',
  },
  'tasks title reporter.reports.report_generation_task_createdMESSAGES': {
    eng: 'Message log export',
    ru: 'Экспорт логов сообщений',
  },
  'tasks title reporter.reports.report_generation_task_startedMESSAGES': {
    eng: 'Message log export',
    ru: 'Экспорт логов сообщений',
  },
  'tasks title reporter.reports.report_generation_task_completedSTATE_ROUTES': {
    eng: 'Route log export',
    ru: 'Экспорт логов маршрутов',
  },
  'tasks title reporter.reports.report_generation_task_createdSTATE_ROUTES': {
    eng: 'Route log export',
    ru: 'Экспорт логов маршрутов',
  },
  'tasks title reporter.reports.report_generation_task_startedSTATE_ROUTES': {
    eng: 'Route log export',
    ru: 'Экспорт логов маршрутов',
  },
  'tasks title reporter.reports.report_generation_task_progress': {
    eng: 'Report generation is in progress',
    ru: 'Отчет формируется',
  },
  'tasks title reporter.reports.report_generation_task_progressSESSIONS': {
    eng: 'Session report generation is in progress',
    ru: 'Отчет по сессиям формируется',
  },
  'tasks title reporter.reports.report_generation_task_progressSTATE_ROUTES': {
    eng: 'Route report generation is in progress',
    ru: 'Отчет по маршрутам формируется',
  },
  'tasks title reporter.reports.report_generation_task_progressMESSAGES': {
    eng: 'Message report generation is in progress',
    ru: 'Отчет по сообщениям формируется',
  },
  'tasks title reporter.reports.report_generation_task_stopped': {
    eng: 'Report generation has been canceled',
    ru: 'Формирование отчета прервано',
  },
  'tasks title reporter.reports.report_generation_task_stoppedSESSIONS': {
    eng: 'Session report generation has been canceled',
    ru: 'Формирование отчета по сессиям прервано',
  },
  'tasks title reporter.reports.report_generation_task_stoppedMESSAGES': {
    eng: 'Message report generation has been canceled',
    ru: 'Формирование отчета по сообщениям прервано',
  },
  'tasks title reporter.reports.report_generation_task_stoppedSTATE_ROUTES': {
    eng: 'Route report generation has been canceled',
    ru: 'Формирование отчета по маршрутам прервано',
  },
  'tasks title reporter.reports.report_generation_task_failed': {
    eng: 'Report generation has failed',
    ru: 'Ошибка при формировании отчета',
  },
  'tasks title reporter.reports.report_generation_task_failedSESSIONS': {
    eng: 'Session report generation has failed',
    ru: 'Ошибка при формировании отчета по сессиям',
  },
  'tasks title reporter.reports.report_generation_task_failedMESSAGES': {
    eng: 'Message report generation has failed',
    ru: 'Ошибка при формировании отчета по сообщениям',
  },
  'tasks title reporter.reports.report_generation_task_failedSTATE_ROUTES': {
    eng: 'Message report generation has failed',
    ru: 'Ошибка при формировании отчета по сообщениям',
  },
  'tasks title reporter.reports.report_generation_task_deleted': {
    eng: 'Report generation has been canceled',
    ru: 'Формирование отчета прервано',
  },
  'tasks title reporter.reports.report_generation_task_deletedSESSIONS': {
    eng: 'Session report generation has been canceled',
    ru: 'Формирование отчета по сессиям прервано',
  },
  'tasks title reporter.reports.report_generation_task_deletedMESSAGES': {
    eng: 'Message report generation has been canceled',
    ru: 'Формирование отчета по сообщениям прервано',
  },
  'tasks title reporter.reports.report_generation_task_deletedSTATE_ROUTES': {
    eng: 'Route report generation has been canceled',
    ru: 'Формирование отчета по маршрутам прервано',
  },
  'tasks modal title botadmin.logs.logs_download_task_created': {
    eng: 'Report generation will take some time',
    ru: 'Формирование отчета займет некоторое время',
  },
  'tasks modal help text botadmin.logs.logs_download_task_created': {
    eng: 'You will receive a notification once the report has been generated.',
    ru: 'Вы получите оповещение, когда отчет будет сформирован.',
  },
  'tasks title botadmin.logs.logs_download_task_completed': {
    eng: 'System log export',
    ru: 'Экспорт системных логов',
  },
  'tasks title botadmin.logs.logs_download_task_created': {
    eng: 'System log export',
    ru: 'Экспорт системных логов',
  },
  'tasks title botadmin.logs.logs_download_task_started': {
    eng: 'System log export',
    ru: 'Экспорт системных логов',
  },
  'tasks title botadmin.logs.logs_download_task_pending': {
    eng: 'Pending',
    ru: 'В ожидании',
  },
  'tasks title botadmin.logs.logs_download_task_progress': {
    eng: 'Report generation is in progress',
    ru: 'Отчет формируется',
  },
  'tasks title botadmin.logs.logs_download_task_stopped': {
    eng: 'Report generation has been canceled',
    ru: 'Формирование отчета прервано',
  },
  'tasks title botadmin.logs.logs_download_task_failed': {
    eng: 'Report generation has failed',
    ru: 'Ошибка при формировании отчета',
  },
  'tasks title botadmin.logs.logs_download_task_deleted': {
    eng: 'Report generation has been canceled',
    ru: 'Формирование отчета прервано',
  },
  'tasks title botscorer.test_run.finished.successfully': {
    eng: 'Bot quality evaluation on the {testSuiteName} set is completed',
    ru: 'Оценка качества бота на наборе {testSuiteName} завершена',
  },
  'tasks title botscorer.test_run.start': {
    eng: 'Bot quality evaluation started on the {testSuiteName} set',
    ru: 'Запущена оценка качества бота на наборе {testSuiteName}',
  },
  'tasks title botscorer.test_run.finished.unsuccessfully': {
    eng: 'Bot quality evaluation ended with an error',
    ru: 'Оценка качества бота завершилась с ошибкой',
  },
  'tasks modal title botscorer.test_run.start': {
    eng: 'Report generation will take some time',
    ru: 'Формирование отчета займет некоторое время',
  },
  'tasks step task_created': {
    eng: 'Created',
    ru: 'Создана',
  },
  'tasks step task_completed': {
    eng: 'Completed',
    ru: 'Завершена',
  },
  'tasks step task_started': {
    eng: 'Started',
    ru: 'Старт',
  },
  'tasks step task_pending': {
    eng: 'Pending',
    ru: 'В ожидании',
  },
  'tasks step task_progress': {
    eng: 'In progress',
    ru: 'В процессе',
  },
  'tasks step task_stopped': {
    eng: 'Canceled',
    ru: 'Отменена',
  },
  'tasks step task_failed': {
    eng: 'Error',
    ru: 'Ошибка',
  },
  'tasks step task_deleted': {
    eng: 'Canceled',
    ru: 'Отменена',
  },
};
