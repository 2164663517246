import { CustomTagParameterType } from '@just-ai/api/dist/generated/Editorbe';
import { CustomTagUiFieldType, CustomTagViewTag } from './types';

const isStringArray = (value: any) => {
  if (value === null || value === '') return true;
  try {
    let list = value;
    if (typeof value === 'string') {
      list = JSON.parse(value);
    }
    if (!Array.isArray(list)) return false;
    if (list.some(el => typeof el !== 'string')) return false;
    return true;
  } catch (e) {
    return false;
  }
};

const isNameValueList = (value: any) => {
  if (value === null || value === '') return true;
  try {
    let list = value;
    if (typeof value === 'string') {
      list = JSON.parse(value);
    }
    if (!Array.isArray(list)) return false;
    const IsSomeValuesHaveInvalidFormat = list.some(el => {
      if (typeof el !== 'object') return true;
      if (!('name' in el || typeof el.name !== 'string')) return true;
      if (!('value' in el || typeof el.value !== 'string')) return true;
      return false;
    });
    if (IsSomeValuesHaveInvalidFormat) return false;
    return true;
  } catch (e) {
    return false;
  }
};

const isParameters = (value: any) => {
  if (value === null || value === '') return true;
  try {
    let obj = value;
    if (typeof value === 'string') {
      obj = JSON.parse(value);
    }
    if (typeof obj !== 'object') return false;
    const IsSomeValuesHaveInvalidFormat = Object.values(obj).some(el => {
      return typeof el !== 'string';
    });
    if (IsSomeValuesHaveInvalidFormat) return false;
    return true;
  } catch (e) {
    return false;
  }
};

const isJSON = (value: string) => {
  if (value === null || value === '') return true;
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

const isNumber = (value: string) => {
  if (value === null || value === '') return true;
  return !Number.isNaN(Number(value));
};

const typeValidationMap: Record<CustomTagViewTag, (value: any) => boolean> = {
  [CustomTagParameterType.String]: value => true,
  [CustomTagParameterType.Html]: value => true,
  [CustomTagParameterType.Integer]: isNumber,
  [CustomTagParameterType.Bool]: value => true,
  [CustomTagParameterType.StringArray]: isStringArray,
  [CustomTagParameterType.NameValueList]: isNameValueList,
  [CustomTagParameterType.Json]: isJSON,
  [CustomTagParameterType.State]: value => true,
  [CustomTagParameterType.Intents]: isStringArray,
  [CustomTagParameterType.Parameters]: isParameters,
  [CustomTagUiFieldType.Select]: val => !!val?.toString(),
};

export const validateParameterValueByType = (value: any, type?: CustomTagViewTag) => {
  if (!type) return typeValidationMap.string(value);
  if (!typeValidationMap[type]) return false;
  return typeValidationMap[type](value);
};

export const isParameterValueMaybeVariable = (value: any) => {
  if (!value || typeof value !== 'string') return false;
  return !!value.match(/\s*\{\{\s*.*\s*\}\}\s*/);
};
