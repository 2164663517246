import { CustomTagParameterType } from '@just-ai/api/dist/generated/Editorbe';
import { TControllerInjectedProps } from './Controller';

export enum CustomTagUiFieldType {
  Select = 'select',
}

export type CustomTagViewTag = CustomTagParameterType | CustomTagUiFieldType;

export interface CommonFieldProps extends TControllerInjectedProps {
  type?: CustomTagViewTag;
  label?: string;
  description?: string;
  options?: { value: string; localization: any }[];
}
