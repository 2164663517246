import React, { useState } from 'react';
import localize from '../../../../localization';
import { InputText } from '@just-ai/just-ui';
import { GithubTokenWarning } from './GithubTokenWarning';

export function ChangePassword({
  hidden,
  editableProject,
  repositoryLoginRef,
  repositoryPasswordRef,
  editingDisabled,
  showGithubWarning,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className='form-group row'>
        <label className='col-md-4 form-control-label' htmlFor='repositoryLg'>
          {localize.translate('Repository login')}
          <span className='red-dot'>*</span>
        </label>
        <div className='col-md-8'>
          <InputText
            readOnly
            onClick={e => e.target.removeAttribute('readonly')}
            onFocus={e => e.target.removeAttribute('readonly')}
            className='form-control skip-readonly'
            autoComplete='new-password'
            required={hidden}
            disabled={editingDisabled || !hidden}
            defaultValue={editableProject.repositoryLogin ? editableProject.repositoryLogin : ''}
            maxLength='100'
            id='repositoryLg'
            placeholder={localize.translate('Repository login placeholder')}
            innerRef={repositoryLoginRef}
            data-test-id='projectsPage.createProjectModal.remoteRepositoryLoginInput'
          />
        </div>
      </div>

      <div className='form-group row'>
        <label className='col-md-4 form-control-label' htmlFor='repositoryPs'>
          {localize.translate('ProjectEditForm:ChangePassword:PersonalToken:Title')}
          <span className='red-dot'>*</span>
        </label>
        <div className='col-md-8'>
          <span className='custom-label' onClick={() => setShowPassword(!showPassword)}>
            <span className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} />
          </span>
          <InputText
            type={showPassword ? 'text' : 'password'}
            required={hidden}
            disabled={editingDisabled || !hidden}
            readOnly
            onClick={e => e.target.removeAttribute('readonly')}
            onFocus={e => e.target.removeAttribute('readonly')}
            className='form-control skip-readonly'
            autoComplete='false'
            maxLength='100'
            id='repositoryPs'
            defaultValue={
              Boolean(editableProject) && editableProject.repositoryPassword ? editableProject.repositoryPassword : ''
            }
            innerRef={repositoryPasswordRef}
            data-test-id='projectsPage.createProjectModal.remoteRepositoryPasswordInput'
          />
        </div>
      </div>
      {showGithubWarning && <GithubTokenWarning />}
    </div>
  );
}
