import React, { FC, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import localize, { t } from 'localization';

import { useGuideTourEventsSubscribe } from 'modules/JGraph/view/JGraphGuideTour/guideTourEvents';
import { getElementWithRetries } from 'modules/JGraph/view/JGraphGuideTour/utils';
import GuideTour, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE } from 'components/GuideTour';

import { GuideTourStepperLocalization } from './GuideTourStepper.loc';
import { beforeStepHandler } from './hooks';
import { GuideStep } from './types';

localize.addTranslations(GuideTourStepperLocalization);

export const getDefaultLocale = () => ({
  skip: t('GuideTourStepper:Actions:Skip'),
  back: t('GuideTourStepper:Actions:Back'),
  close: t('GuideTourStepper:Actions:Close'),
  last: t('GuideTourStepper:Actions:Last'),
  next: t('GuideTourStepper:Actions:Next'),
  open: t('GuideTourStepper:Actions:OpenModal'),
});

type TGuideTourStepperProps = {
  guideSteps: GuideStep[];
  skip?: (data: CallBackProps) => unknown;
  skipForever?: (data: CallBackProps) => unknown;
  locale?: {
    skip: string;
    back: string;
    close: string;
    last: string;
    next: string;
    open: string;
  };
  callback?: (data: CallBackProps) => unknown;
  styles?: object;
};
export const GuideTourStepper: FC<TGuideTourStepperProps> = ({
  guideSteps,
  skip,
  skipForever,
  locale = getDefaultLocale(),
  ...rest
}) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const currentStep = useRef<GuideStep | null>(null);

  const { callback: PropCallback } = rest;

  useGuideTourEventsSubscribe(currentStep, async () => {
    if (currentStep.current) {
      const currentStepItem = currentStep.current;
      currentStep.current = null;
      const [newStep, newIndex] = await beforeStepHandler(currentStepItem, guideSteps);
      if (newStep && newIndex !== undefined) {
        setStepIndex(newIndex);
        setTimeout(() => setIsRunning(true), 500);
        return;
      }
    }

    setStepIndex(stepIndex => stepIndex + 1);
    setTimeout(() => setIsRunning(true), 500);
  });

  const callback = useCallback(
    async (data: CallBackProps) => {
      PropCallback?.(data);
      if (
        (data.action === ACTIONS.SKIP && data.type === 'tour:end') ||
        (data.action === ACTIONS.CLOSE && data.lifecycle === LIFECYCLE.COMPLETE)
      ) {
        setIsRunning(false);
        setStepIndex(0);
        skip?.(data);

        return;
      }
      if (data.action === ACTIONS.NEXT && data.index === data.size - 1) {
        setIsRunning(false);
        setStepIndex(0);
        skipForever?.(data);
        return;
      }
      if (data.type === EVENTS.TARGET_NOT_FOUND && typeof data.step.target === 'string') {
        setIsRunning(false);
        getElementWithRetries(data.step.target)
          .then(() => setIsRunning(true))
          .catch(() => {});
        return;
      }
      if (data.action === ACTIONS.NEXT && data.type === EVENTS.STEP_AFTER && stepIndex === data.index) {
        let nextStepIndex = stepIndex + 1;
        let nextStep = guideSteps[nextStepIndex];
        currentStep.current = nextStep;
        if (!nextStep) {
          setIsRunning(false);
          setStepIndex(0);
          return;
        }

        if (nextStep.waitEvent) {
          setIsRunning(false);
          return;
        }

        const [newStep, newIndex] = await beforeStepHandler(nextStep, guideSteps);
        if (newStep && newIndex !== undefined) {
          currentStep.current = newStep;
          nextStepIndex = newIndex;
        }

        const isCompleteGuide = guideSteps.length === nextStepIndex;
        if (isCompleteGuide) {
          setIsRunning(false);
          setStepIndex(0);
          skipForever?.(data);
          return;
        }

        setStepIndex(nextStepIndex);
        return;
      }
    },
    [PropCallback, stepIndex, skip, skipForever, guideSteps]
  );

  return ReactDOM.createPortal(
    <GuideTour
      locale={locale}
      steps={guideSteps}
      run={isRunning}
      stepIndex={stepIndex}
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      hideBackButton
      disableBeacon
      continuous
      showProgress
      scrollOffset={200}
      callback={callback}
      disableScrollParentFix
      styles={rest.styles}
    />,
    document.body
  );
};
