import React, { FunctionComponent } from 'react';
import './style.scss';
import { WindowControl, WindowPane } from '@just-ai/just-ui';
import FilterToolbar from './FilterToolbar';
import ActionsToolbar from './ActionsToolbar';
import LogArea from './LogArea';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import classNames from 'classnames';
import { useGlobalBottomPanelContext } from 'components/GlobalBottomPanel/GlobalBottomPanelContext';
import localize from 'localization';
import { logsLocalization } from '../../localization/logs.loc';

localize.addTranslations(logsLocalization);

const { t } = i18nTranslation('LogPanel');
export const LOGS_PANEL_TYPE = 'log';

type LogPanelProps = {
  control: WindowControl;
};
const LogPanel: FunctionComponent<LogPanelProps> = ({ control }) => {
  const { shownPanel, hidePanel } = useGlobalBottomPanelContext();

  return (
    <WindowPane
      title={t('panelTitle')}
      className={classNames('log-panel', { hidden: shownPanel !== LOGS_PANEL_TYPE })}
      control={control}
      dark
      minimizable
      maximizable
      onMinimize={() => hidePanel(LOGS_PANEL_TYPE)}
    >
      <FilterToolbar />
      <ActionsToolbar />
      <LogArea />
    </WindowPane>
  );
};

export default LogPanel;
