import dagre from 'dagre';

import { BaseAutoLayout, PlacedNode } from './BaseAutoLayout';
import { GraphOptions } from '../NodeGraph/INodeGraph';

export class DagreAutoLayout extends BaseAutoLayout {
  private readonly graph = new dagre.graphlib.Graph();

  constructor(options: GraphOptions) {
    super(options);
    this.graph.setDefaultEdgeLabel(() => ({}));
    this.graph.setGraph({
      rankdir: 'LR',
      nodesep: options.nodeSpacing || 100,
      ranksep: 500,
      marginx: 150,
      marginy: 500,
      ranker: 'network-simplex', //network-simplex, tight-tree or longest-path
    });
  }

  addNode(node: { id: string; width: number; height: number; label: string }) {
    this.graph.setNode(node.id, node);
  }

  getCountOfNode() {
    return this.graph.nodeCount();
  }

  addEdge(from: string, to: string) {
    this.graph.setEdge(from, to);
  }

  async calculate() {
    dagre.layout(this.graph);

    return this.graph
      .nodes()
      .map(nodeId => {
        const node = this.graph.node(nodeId);
        if (!node) return;

        return {
          id: nodeId,
          height: node.height,
          width: node.width,
          label: node.label || '',
          x: node.x,
          y: node.y,
        };
      })
      .filter(Boolean) as PlacedNode[];
  }
}
