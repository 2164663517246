var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button as RsButton } from 'reactstrap';
import classNames from 'classnames';
import { Icon } from '../Icon';
import './style.scss';
export const Button = React.forwardRef((_a, ref) => {
    var { withoutPadding = false, flat = false, iconLeft, iconRight, children, className, square, sharp, color = 'none', compact, tooltipWrapperId, wrapperIconClassName } = _a, restProps = __rest(_a, ["withoutPadding", "flat", "iconLeft", "iconRight", "children", "className", "square", "sharp", "color", "compact", "tooltipWrapperId", "wrapperIconClassName"]);
    if (tooltipWrapperId)
        return (_jsx("div", { id: tooltipWrapperId, children: _jsxs(RsButton, Object.assign({ className: classNames(className, 'justui_button', {
                    'btn-flat': flat,
                    withoutPadding: withoutPadding,
                    sharp: sharp,
                    'btn-square': square,
                    compact: compact,
                    noIconsMargin: !children,
                }), color: color }, restProps, { children: [iconLeft ? (_jsx(Icon, { wrapperClassName: wrapperIconClassName, className: 'iconLeft btn-icon btn-color', name: iconLeft, size: restProps.size, color: color })) : null, children, iconRight ? (_jsx(Icon, { wrapperClassName: wrapperIconClassName, className: 'iconRight btn-icon btn-color', name: iconRight, size: restProps.size, color: color })) : null] })) }));
    return (_jsxs(RsButton, Object.assign({ innerRef: ref, className: classNames(className, 'justui_button', {
            'btn-flat': flat,
            withoutPadding: withoutPadding,
            sharp: sharp,
            'btn-square': square,
            compact: compact,
            noIconsMargin: !children,
        }), color: color }, restProps, { children: [iconLeft ? (_jsx(Icon, { wrapperClassName: wrapperIconClassName, className: 'iconLeft btn-icon btn-color', name: iconLeft, size: restProps.size, color: color })) : null, children, iconRight ? (_jsx(Icon, { wrapperClassName: wrapperIconClassName, className: 'iconRight btn-icon btn-color', name: iconRight, size: restProps.size, color: color })) : null] })));
});
export const IconButton = React.forwardRef((_a, ref) => {
    var { name, color = 'none', size, flat, compact, className, wrapperClassName, wrapperIconClassName } = _a, restProps = __rest(_a, ["name", "color", "size", "flat", "compact", "className", "wrapperClassName", "wrapperIconClassName"]);
    return (_jsx(Button, Object.assign({ ref: ref, flat: flat || compact, color: color, size: size, className: classNames('justui_icon-button', className), compact: compact }, restProps, { children: _jsx(Icon, { wrapperClassName: wrapperIconClassName, color: color, size: compact ? 'md' : size, name: name, className: classNames('btn-icon btn-color', { compact: compact }) }) })));
});
IconButton.displayName = 'JuiIconButton';
Button.displayName = 'JuiButton';
