import React, { FC, useCallback } from 'react';
import cn from 'classnames';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { Badge, Label } from '@just-ai/just-ui';

import { t } from 'localization';

import { DEFAULT_EDITOR_PROPS } from 'modules/Editor/components/CodeEditor';
import { TagNames, TJBlock } from 'modules/JGraph/utils/types';

import { useRightSideMenuContext } from '../../index';

import styles from './styles.module.scss';

export const ScriptSettings: FC<{ isEs6?: boolean }> = ({ isEs6 }) => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();
  const scriptToEdit = getEditBlockTag() as TJBlock<TagNames.script>;
  const changeScript = useCallback((value: string) => onChangeCommonTag({ tagValue: value }), [onChangeCommonTag]);

  return (
    <div className={cn('JGraph-RightSideMenu-commonContainer', styles.ScriptSettings)}>
      <div className={styles.ScriptSettings__title}>
        <Label for='answerToEditText'>{t('ScriptSettings:ScriptAnswer')}</Label>
        {isEs6 && <Badge color='indigo' pastel text='ECMAScript 6' />}
      </div>

      <div className={styles.ScriptSettings__fieldContainer}>
        <AceEditor
          {...DEFAULT_EDITOR_PROPS}
          value={scriptToEdit.tagValue}
          mode='javascript'
          theme='github'
          onChange={changeScript}
          wrapEnabled={true}
        />
      </div>
    </div>
  );
};
