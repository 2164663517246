const prefix = '@currentUser/';
export const LOGIN = prefix + 'LOGIN';
export const LOGIN_FULFILLED = prefix + 'LOGIN_FULFILLED';
export const LOGIN_PENDING = prefix + 'LOGIN_PENDING';
export const LOGIN_REJECTED = prefix + 'LOGIN_REJECTED';
export const CLEAN_LOGIN_ERRORS = prefix + 'CLEAN_LOGIN_ERRORS';

export const LOGOUT = prefix + 'LOGOUT';
export const LOGOUT_FULFILLED = prefix + 'LOGOUT_FULFILLED';
export const LOGOUT_PENDING = prefix + 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = prefix + 'LOGOUT_REJECTED';

export const SET_CURRENT_USER = prefix + 'SET_CURRENT_USER';
export const DROP_CURRENT_USER = prefix + 'DROP_CURRENT_USER';
export const DROP_EDITABLE_USER = prefix + 'DROP_EDITABLE_USER';

export const CHECK_TOKEN = prefix + 'CHECK_TOKEN';
export const CHECK_TOKEN_FULFILLED = prefix + 'CHECK_TOKEN_FULFILLED';
export const CHECK_TOKEN_PENDING = prefix + 'CHECK_TOKEN_PENDING';
export const CHECK_TOKEN_REJECTED = prefix + 'CHECK_TOKEN_REJECTED';

export const SET_MARKUP_VIEW = prefix + 'SET_MARKUP_VIEW';

export const GET_USER_ACCOUNT = prefix + 'GET_USER_ACCOUNT';
export const GET_USER_ACCOUNT_FULFILLED = prefix + 'GET_USER_ACCOUNT_FULFILLED';
export const GET_USER_ACCOUNT_PENDING = prefix + 'GET_USER_ACCOUNT_PENDING';
export const GET_USER_ACCOUNT_REJECTED = prefix + 'GET_USER_ACCOUNT_REJECTED';

export const GET_TARIFF_LIMITS_STATUS = prefix + 'GET_TARIFF_LIMITS_STATUS';
export const GET_TARIFF_LIMITS_STATUS_FULFILLED = prefix + 'GET_TARIFF_LIMITS_STATUS_FULFILLED';
export const GET_TARIFF_LIMITS_STATUS_PENDING = prefix + 'GET_TARIFF_LIMITS_STATUS_PENDING';
export const GET_TARIFF_LIMITS_STATUS_REJECTED = prefix + 'GET_TARIFF_LIMITS_STATUS_REJECTED';

export const GET_USER_SUBSCRIPTION = prefix + 'GET_USER_SUBSCRIPTION';
export const GET_USER_SUBSCRIPTION_FULFILLED = prefix + 'GET_USER_SUBSCRIPTION_FULFILLED';
export const GET_USER_SUBSCRIPTION_PENDING = prefix + 'GET_USER_SUBSCRIPTION_PENDING';
export const GET_USER_SUBSCRIPTION_REJECTED = prefix + 'GET_USER_SUBSCRIPTION_REJECTED';

export const CHANGE_USER_SUBSCRIPTION_STATUS = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS';
export const CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED';
export const CHANGE_USER_SUBSCRIPTION_STATUS_PENDING = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_PENDING';
export const CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED';

export const ADD_USER_SUBSCRIPTION = prefix + 'ADD_USER_SUBSCRIPTION';
export const ADD_USER_SUBSCRIPTION_FULFILLED = prefix + 'ADD_USER_SUBSCRIPTION_FULFILLED';
export const ADD_USER_SUBSCRIPTION_PENDING = prefix + 'ADD_USER_SUBSCRIPTION_PENDING';
export const ADD_USER_SUBSCRIPTION_REJECTED = prefix + 'ADD_USER_SUBSCRIPTION_REJECTED';

export const CHANGE_USER_TARIFF = prefix + 'CHANGE_USER_TARIFF';
export const CHANGE_USER_TARIFF_FULFILLED = prefix + 'CHANGE_USER_TARIFF_FULFILLED';
export const CHANGE_USER_TARIFF_PENDING = prefix + 'CHANGE_USER_TARIFF_PENDING';
export const CHANGE_USER_TARIFF_REJECTED = prefix + 'CHANGE_USER_TARIFF_REJECTED';

export const GET_USER_ACCOUNT_PAYMENT_DATA = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA';
export const GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED';
export const GET_USER_ACCOUNT_PAYMENT_DATA_PENDING = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_PENDING';
export const GET_USER_ACCOUNT_PAYMENT_DATA_REJECTED = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_REJECTED';

export const GET_MANUAL_CONTROL_INFO = prefix + 'GET_MANUAL_CONTROL_INFO';
export const GET_MANUAL_CONTROL_INFO_PENDING = prefix + 'GET_MANUAL_CONTROL_INFO_PENDING';
export const GET_MANUAL_CONTROL_INFO_FULFILLED = prefix + 'GET_MANUAL_CONTROL_INFO_FULFILLED';
export const GET_MANUAL_CONTROL_INFO_REJECTED = prefix + 'GET_MANUAL_CONTROL_INFO_REJECTED';

export const langBackMap = {
  EN: 'eng',
  RU: 'ru',
  CN: 'cn',
};
