import React, { FC, useCallback, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { KonvaEventObject } from 'konva/lib/Node';
import classes from './FloatingConnectorMenu.module.scss';
import { IconButton } from '@just-ai/just-ui';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { ReactionsTagNames, TReactionsTagNames } from '../../utils/types';
import { t } from 'localization';
import { useAppDispatch } from '../../../../storeHooks';
import { deleteConnectorAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { scrollToTargetGlobal$ } from '../../utils/stageUtils';
import { hideRootSlashInPath } from '../../utils/state';

type TFloatingConnectorMenu = {
  event?: KonvaEventObject<MouseEvent>;
  connector?: TConnector;
};

export const FloatingConnectorMenu$ = new Subject<TFloatingConnectorMenu>();

export const FloatingConnectorMenu: FC = () => {
  const dispatch = useAppDispatch();
  const [connector, setConnector] = useState<TConnector | undefined>();
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    let sub = FloatingConnectorMenu$.subscribe(floatingMenuTarget => {
      if (floatingMenuTarget.event) {
        const targetPosition = floatingMenuTarget.event.evt;
        //@ts-ignore
        let left = targetPosition.layerX;
        //@ts-ignore
        let top = targetPosition.layerY;
        setPosition({
          left: left - 126,
          top: top - 64,
        });
      }
      setConnector(floatingMenuTarget.connector);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const scrollToFrom = useCallback(() => {
    if (connector) {
      FloatingConnectorMenu$.next({ connector: undefined });
      scrollToTargetGlobal$.next({ targetPathId: connector.fromNode });
    }
  }, [connector]);
  const scrollToTo = useCallback(() => {
    if (connector) {
      FloatingConnectorMenu$.next({ connector: undefined });
      scrollToTargetGlobal$.next({ targetPathId: connector.to || '' });
    }
  }, [connector]);

  const deleteConnectorHandler = useCallback(() => {
    if (connector) {
      FloatingConnectorMenu$.next({ connector: undefined });
      dispatch(deleteConnectorAsync(connector));
    }
  }, [dispatch, connector]);

  if (!connector) return null;

  const isReaction = ReactionsTagNames().includes(connector.tagName as TReactionsTagNames);

  return (
    <div className={classes.floatingMenu} style={{ left: position.left, top: position.top }}>
      <div className={classes.fromToContainer}>
        <div className={classes.fromTo}>
          <div className={classes.fromToName} onClick={scrollToFrom}>
            {hideRootSlashInPath(connector.fromNodeOriginalPath)}
          </div>
          <div>
            <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10.5 4.00488L6.5 1.00488V4.00488V7.00488L10.5 4.00488Z' fill='#888E93' />
              <path
                d='M6.5 4.00488V7.00488L10.5 4.00488L6.5 1.00488V4.00488ZM6.5 4.00488H1.5'
                stroke='#888E93'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={classes.fromToName} onClick={scrollToTo}>
            {hideRootSlashInPath(connector.toNodeOriginalPath)}
          </div>
        </div>
        <div className={classes.transitionName}>
          {isReaction ? t(`ReactionItem ${connector.tagName}`) : t(`ActivationItem ${connector.tagName}`)}
        </div>
      </div>
      <div className={classes.delete}>
        <IconButton name='farTrashAlt' outline flat onClick={deleteConnectorHandler} />
      </div>
    </div>
  );
};
