import React from 'react';
import { t } from '../../localization';
import { useToggle } from '../../modules/Caila/utils';
import { DropdownItem, Dropdown, Popover, IconButton, Icon } from '@just-ai/just-ui';
import './HelMenu.scss';
import { isEuroInstance } from '../../isAccessFunction';

const HelpMenu = props => {
  const [isOpen, , , toggle] = useToggle(false);

  return (
    <>
      <IconButton className='navbar-btn' id='helpMenuPopover' name='farQuestionCircle' onClick={toggle} />
      <Popover
        isOpen={isOpen}
        placement='bottom'
        target='helpMenuPopover'
        className='header-menu-popover help-menu-popover HelpMenu_container'
      >
        <Dropdown className='header-menu' isOpen={true} toggle={toggle}>
          <a href={t('Config:Botadmin:HelpUrl')} target='_blank' rel='noopener noreferrer'>
            <DropdownItem className='header-menu__item'>
              {t('Product:Docs')}
              <Icon name='faExternalLinkAlt' size='sm' className='btn-icon right external-link' color='primary' />
            </DropdownItem>
          </a>
          {!isEuroInstance() && (
            <>
              {t('HelpMenu: video course link') !== ' ' && !isEuroInstance() && (
                <a href={t('HelpMenu: video course link')} target='_blank' rel='noopener noreferrer'>
                  <DropdownItem className='header-menu__item'>
                    {t(isEuroInstance() ? 'HelpMenu: video course title Platform' : 'HelpMenu: video course title')}
                    <Icon name='faExternalLinkAlt' size='sm' className='btn-icon right external-link' color='primary' />
                  </DropdownItem>
                </a>
              )}
              <a href={t('JAICP community url')} target='_blank' rel='noopener noreferrer'>
                <DropdownItem className='header-menu__item'>
                  {t('JAICP community')}
                  <Icon name='faExternalLinkAlt' size='sm' className='btn-icon right external-link' color='primary' />
                </DropdownItem>
              </a>
              <a href={t('support requests link')} target='_blank' rel='noopener noreferrer'>
                <DropdownItem className='header-menu__item'>
                  {t('contact support')}
                  <Icon name='faExternalLinkAlt' size='sm' className='btn-icon right external-link' color='primary' />
                </DropdownItem>
              </a>
            </>
          )}
        </Dropdown>
      </Popover>
    </>
  );
};

export default HelpMenu;
