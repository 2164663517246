import { useMemo } from 'react';

import { findParentByPathId } from 'reducers/JGraph.reducer/Graph';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { useAppSelector } from 'storeHooks';

export function useStickersByPath(path?: string) {
  const { stickers, selectedTheme } = useAppSelector(state => ({
    stickers: state.JGraphReducer.stickers,
    selectedTheme: state.JGraphReducer.selectedTheme,
  }));

  return useMemo(() => {
    return stickers.filter(sticker => {
      const parentPath = path || selectedTheme?.value;
      return sticker.stagePath === parentPath;
    });
  }, [path, selectedTheme, stickers]);
}

export function useIsStickersWillBeDeletedWithState(screen?: JStateWithId) {
  const { screens } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
  }));
  const parentScreen = useMemo(() => (screen ? findParentByPathId(screens, screen.pathId) : null), [screens, screen]);
  const stickers = useStickersByPath(parentScreen?.path);
  const notConnectedStickers = useMemo(() => stickers.filter(sticker => !sticker.connection), [stickers]);

  const isStickersWillBeDeletedWithState = useMemo(() => {
    if (!screen) return false;
    const tryToDeleteLastStateInStage = parentScreen?.states?.length === 1;
    const noConnectedStickersOnStageExist = notConnectedStickers.length > 0;
    return noConnectedStickersOnStageExist && tryToDeleteLastStateInStage;
  }, [screen, parentScreen?.states?.length, notConnectedStickers.length]);

  return {
    isStickersWillBeDeletedWithState,
    parentScreen,
  };
}
