import React, {
  useState,
  ComponentType,
  CSSProperties,
  MouseEvent,
  useCallback,
  useMemo,
  ReactElement,
  Ref,
  useRef,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { range, uniqueId } from 'lodash';
import { Color } from '@just-ai/just-ui/dist/Common';
import { Checkbox, Button, SearchInput } from '@just-ai/just-ui';
import VirtualListWithDynamicHeight from '@just-ai/just-ui/dist/VirtualListWithDynamicHeight';
import { DEFAULT_PHRASE_HEIGHT } from '@just-ai/nlu-modules/dist/views/FAQTreePage/components/PhrasesBlock/PhraseList';

import { t } from 'localization';

import styles from './style.module.scss';

const TABLE_NAME_PLACEHOLDER = `${uniqueId('table-')}`;

export type RenderSelectionAction = (props: {
  selectedIndexes: number[];
  resetSelection: () => void;
}) => React.ReactNode;

interface TableProps<TableDataEntity, AdditionalPayload> {
  onChange: (entity: TableDataEntity) => void;
  columns?: Column<TableDataEntity>[];
  data: TableDataEntity[];
  tableName: string;
  RowComponent: RowComponentType<TableDataEntity, AdditionalPayload>;
  rowComponentPayload: AdditionalPayload;
  filterFunction?: (item: TableDataEntity, filter: string) => boolean;
  withColspan?: boolean;
  withFilter?: boolean;
  externalFilter?: boolean;
  externalFilterValue?: string;
  externalFilterChange?: (value: string) => void;
  stickyHeader?: boolean;
  stickyHeaderOffset?: number;
  style?: CSSProperties;
  selectedLabel?: string;
  discardLabel?: string;
  switchOnLabel?: string;
  switchOffLabel?: string;
  scrollToIndex?: number;
  seacrhButtonColor?: Color;
  maxTableHeight?: number;
  withVirtualList?: boolean;
  renderSelectionActions?: RenderSelectionAction;
}

interface Column<TableDataEntity> {
  property: keyof TableDataEntity;
  caption: string;
}

export type RowComponentProps<TableDataEntity, AdditionalPayload> = {
  data: TableDataEntity;
  onChange: (entity: TableDataEntity) => unknown;
  index: number;
  payload?: AdditionalPayload;
};

export type RowComponentType<TableDataEntity, AdditionalPayload = null> = ComponentType<
  RowComponentProps<TableDataEntity, AdditionalPayload>
>;

interface VirtualRowProps<TableDataEntity, AdditionalPayload> {
  index: number;
  data: TableDataEntity;
  RowComponent: RowComponentType<TableDataEntity, AdditionalPayload>;
  getSelectRowFunction: (index: number) => void;
  isSelected: (index: number) => boolean;
  rowComponentPayload: AdditionalPayload;
  onChange: (entity: TableDataEntity) => unknown;
}

const stickyStyle: CSSProperties = {
  position: 'sticky',
  top: '56px',
  width: '100%',
  zIndex: 1,
  minHeight: '50px',
};

export default function TableWithVirtualList<TableDataEntity extends { id: string }, AdditionalPayload>(
  props: TableProps<TableDataEntity, AdditionalPayload>
) {
  const {
    data,
    columns,
    onChange,
    RowComponent,
    rowComponentPayload,
    filterFunction,
    externalFilterChange,
    stickyHeader = false,
    withFilter = false,
    externalFilter = false,
    externalFilterValue = '',
    selectedLabel = t('Selected'),
    discardLabel = t('IntentItemsEdit:discardLabel'),
    style,
    tableName = TABLE_NAME_PLACEHOLDER,
    scrollToIndex,
    seacrhButtonColor,
    maxTableHeight,
    withVirtualList,
    stickyHeaderOffset,
    renderSelectionActions,
    ...restProps
  } = props;

  const [selectedEntityIndexes, setSelectedEntityIndexes] = useState([] as number[]);
  const [filter, setFilter] = useState('');

  const dataLengthRef = useRef(data.length);

  useEffect(() => {
    if (data.length !== dataLengthRef.current) clearSelection();
    dataLengthRef.current = data.length;
  }, [data.length, selectedEntityIndexes.length]);

  const hasSelection = selectedEntityIndexes.length > 0;
  const isSelected = useCallback((index: number) => selectedEntityIndexes.includes(index), [selectedEntityIndexes]);
  const clearSelection = () => setSelectedEntityIndexes([]);
  const filtredData = useMemo(
    () => (filter ? data.filter(item => filterFunction && filterFunction(item, filter)) : data),
    [filter, data, filterFunction]
  );

  const selectAll = (checked: boolean) =>
    setSelectedEntityIndexes(checked ? filtredData.map((_entity, index) => index) : []);

  const getSelectRowFunction = useCallback(
    (selectedIndex: number) => (event: MouseEvent<HTMLDivElement>) => {
      const itemSelected = isSelected(selectedIndex);
      if (event.shiftKey && selectedEntityIndexes.length) {
        document.getSelection()?.removeAllRanges();
        const lastIndex = selectedEntityIndexes[0];
        const targetIndex = selectedIndex;
        const newSelectedIds = [] as number[];

        const from = Math.min(lastIndex, targetIndex);
        const to = Math.max(lastIndex, targetIndex);
        range(from, to + 1).forEach(item => {
          newSelectedIds.push(item);
        });
        setSelectedEntityIndexes(newSelectedIds);
      } else {
        setSelectedEntityIndexes(
          itemSelected
            ? selectedEntityIndexes.filter(index => index !== selectedIndex)
            : [...selectedEntityIndexes, selectedIndex]
        );
      }
    },
    [isSelected, selectedEntityIndexes]
  );

  const parsedColumns = useMemo(() => {
    if (!columns) return [];
    if (columns.length) return [...columns];
    return Object.keys(data[0])
      .filter(key => key !== 'id')
      .map(key => ({
        property: key,
        caption: '',
      }));
  }, [columns, data]);

  const itemRender = useCallback(
    index => {
      const item = filtredData[index];
      return (
        <VirtualRow<TableDataEntity, AdditionalPayload>
          index={index}
          data={item}
          getSelectRowFunction={getSelectRowFunction}
          isSelected={isSelected}
          RowComponent={RowComponent}
          onChange={onChange}
          rowComponentPayload={rowComponentPayload}
        />
      );
    },
    [RowComponent, filtredData, getSelectRowFunction, isSelected, onChange, rowComponentPayload]
  );

  return (
    <div className={classNames('justui-table', styles.TableWithVirtualList)} style={style} {...restProps}>
      <div
        className='justui-table__head'
        style={stickyHeader ? { ...stickyStyle, top: stickyHeaderOffset || '56px' } : {}}
      >
        <div className={classNames('justui-table__row table-selected', { 'hidden-row': !hasSelection })}>
          <div className='justui-table__cell'>
            <Checkbox
              noMargin={false}
              onChange={selectAll}
              value={filtredData.length === selectedEntityIndexes.length && filtredData.length !== 0}
              data-test-id='Table.Checkbox.SelectAll'
              className='table-row-checkbox'
              name={`header_row_checkbox-${tableName}`}
            />
          </div>
          <div className='justui-table__cell' style={{ flex: 1 }}>
            <div className='header-fixed'>
              <span data-test-id='Table.SelectedItems' className={styles.selectedLabel}>
                {selectedLabel}: {selectedEntityIndexes.length}
              </span>

              {renderSelectionActions?.({
                selectedIndexes: selectedEntityIndexes,
                resetSelection: clearSelection,
              })}

              <Button size='sm' color='primary' onClick={clearSelection} data-test-id='Table.CancelButton'>
                {discardLabel}
              </Button>
            </div>
          </div>
        </div>
        <div className={classNames('justui-table__row', { 'hidden-row': hasSelection })}>
          <div className='justui-table__cell'>
            <Checkbox
              noMargin={false}
              onChange={selectAll}
              value={filtredData.length === selectedEntityIndexes.length && filtredData.length !== 0}
              data-test-id='Table.Checkbox.SelectAll'
              className='table-row-checkbox'
              name={`header_row_checkbox-${tableName}`}
            />
          </div>
          {parsedColumns.length > 0 &&
            parsedColumns.map(col => (
              <div className='justui-table__cell' key={`table_column_header-${String(col.property)}`}>
                {col.caption}
              </div>
            ))}
          {(withFilter || externalFilter) && (
            <div className='justui-table__cell' style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                  paddingRight: '8px',
                }}
              >
                <span style={{ marginRight: 10 }} data-test-id='Table.SelectedItems'>
                  {selectedLabel}:&nbsp;0
                </span>
                <span>
                  {withFilter && (
                    <SearchInput
                      buttonColor={seacrhButtonColor}
                      value={filter}
                      onChange={setFilter}
                      data-test-id='Table.SeacrhInput'
                    />
                  )}
                  {externalFilter && (
                    <SearchInput
                      initiallyOpen={Boolean(externalFilterValue)}
                      onChange={externalFilterChange}
                      value={externalFilterValue}
                      data-test-id='Table.SeacrhInput'
                      buttonColor={seacrhButtonColor}
                    />
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='justui-table__body'>
        {withVirtualList && rowComponentPayload ? (
          <VirtualListWithDynamicHeight
            itemId={index => filtredData[index].id}
            defaultElementHeight={DEFAULT_PHRASE_HEIGHT}
            initialContainerHeight={200}
            itemCount={filtredData.length}
            className={classNames('just-ui-custom-scrollbar just-ui-custom-scrollbar_pink with-gutter')}
            itemRender={itemRender}
            containerMaxHeight={maxTableHeight}
          />
        ) : (
          filtredData.map((entity, index) => (
            <tr id={`${index}`} key={`table_column_row-${index}`} style={{ ...style }} data-test-id='Table.Row'>
              <td>
                <Checkbox
                  onClick={getSelectRowFunction(index)}
                  value={isSelected(index)}
                  data-test-id={`Table.Checkbox-${index}`}
                  className='table-row-checkbox'
                  name={`table_checkbox-${index}`}
                />
              </td>
              <RowComponent
                index={index}
                data={entity}
                onChange={updatedEntity => onChange(updatedEntity)}
                payload={rowComponentPayload}
              />
            </tr>
          ))
        )}
      </div>
    </div>
  );
}

/*
  ForwardRef does not allow generics out of the box, so we need this little hack below
  reference  https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
*/
const VirtualRowComponent = <TableDataEntity, AdditionalPayloadType>(
  {
    data,
    index,
    getSelectRowFunction,
    isSelected,
    RowComponent,
    onChange,
    rowComponentPayload,
  }: VirtualRowProps<TableDataEntity, AdditionalPayloadType>,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div ref={ref} className='virtual-table__row justui-table__row' data-test-id='Table.Row'>
      <div className='virtual-table__checkbox-cell'>
        <Checkbox
          onClick={getSelectRowFunction(index)}
          value={isSelected(index)}
          data-test-id={`Table.Checkbox-${index}`}
          className='table-row-checkbox'
          name={`virtual_row_checkbox-${index}`}
        />
      </div>
      <RowComponent index={index} data={data} onChange={onChange} payload={rowComponentPayload} />
    </div>
  );
};

const ForwardedVirtualRow = React.forwardRef(VirtualRowComponent) as <TableDataEntity, AdditionalPayloadType>(
  p: VirtualRowProps<TableDataEntity, AdditionalPayloadType> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;

const VirtualRow = React.memo(ForwardedVirtualRow) as <TableDataEntity, AdditionalPayloadType>(
  p: VirtualRowProps<TableDataEntity, AdditionalPayloadType> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
