import { useCallback, useMemo } from 'react';
import localize, { t } from '../../localization';
import { AlertBannerLocalization } from './localization/AlertBanner.loc';

localize.addTranslations(AlertBannerLocalization);

const priorityToStatusesMap = {
  0: ['noConnectionWithServerTooLong'],
  1: ['noConnectionWithServerNow', ' noActualKey'],
  2: ['platformBlockedAll'],
  3: ['usedAllAdditionPackagesSessions', 'usedAllAdditionPackagesUniqueUsers', 'usedAllAdditionPackagesMinutes'],
  4: ['platformBlockedSessions', 'platformBlockedUniqueUsers', 'platformBlockedMinutes'],
  5: [
    'activatedMoreThenHalfOfAdditionPackagesSessions',
    'activatedMoreThenHalfOfAdditionPackagesUniqueUsers',
    'activatedMoreThenHalfOfAdditionPackagesMinutes',
  ],
  6: ['usedAllTariffLimitSessions', 'usedAllTariffLimitUniqueUsers', 'usedAllTariffLimitMinutes'],
};

const AlertBanner = ({ appStatus }) => {
  const currentAppStatuses = useMemo(
    () =>
      Object.entries(appStatus)
        .map(([status, value]) => value && status)
        .filter(Boolean),
    [appStatus]
  );

  const getDisplayedStatuses = useCallback(() => {
    let skipAllNext = false;
    if (!currentAppStatuses.length) return [];

    return Object.values(priorityToStatusesMap)
      .map(statusesArr => {
        if (skipAllNext) return '';

        const includedStatuses = currentAppStatuses.filter(status => statusesArr.includes(status));
        if (includedStatuses.length) {
          skipAllNext = true;
          return includedStatuses;
        }

        return '';
      })
      .flat()
      .filter(Boolean);
  }, [currentAppStatuses]);

  return getDisplayedStatuses().map(status => (
    <div key={status} className='limit-banner'>
      {t(`LimitBanner:${status}`)}
    </div>
  ));
};

export default AlertBanner;
