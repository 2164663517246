import { TreeDataset, TreeNode } from './types';

type TreeStructureOptions<NODE extends TreeNode = TreeNode> = { sort: (a: NODE, b: NODE) => number };

export class TreeStructure<NODE extends TreeNode = TreeNode> {
  options: TreeStructureOptions<NODE>;

  nodes: TreeNode[];

  constructor(
    public dataset: TreeDataset<NODE>,
    public maxDepthLevel: number = 10,
    options: Partial<TreeStructureOptions<NODE>> = {}
  ) {
    const defaultOptions = { sort: (a: NODE, b: NODE) => a.name.localeCompare(b.name) };
    this.options = Object.assign({}, defaultOptions, options);

    this.nodes = dataset
      .filter(el => el.isInRoot)
      .sort(this.options.sort)
      .map(node => (node.isFolder ? this.buildBranch(node, 0) : { ...node, depthLevel: 0 }));
  }

  public getElementById(id: string) {
    return this.dataset.find(el => el.id === id);
  }

  public getChildrenByParentId(id: string) {
    const nodes = this.dataset.filter(el => el.parentId === id);
    return {
      branches: nodes.filter(el => el.isFolder),
      leafs: nodes.filter(el => !el.isFolder),
    };
  }

  public static getIdsInDepth(node: TreeNode, _result: string[] = []) {
    _result.push(node.id);

    if (node.children && node.children.length > 0) {
      _result = node.children.reduce((acc, node) => this.getIdsInDepth(node, acc), _result);
    }

    return _result;
  }

  public static getNodeAsPlainList(node: TreeNode, extendedMap?: Record<string, boolean>, _result: TreeNode[] = []) {
    node._containIds = new Set(TreeStructure.getIdsInDepth(node));

    _result.push(node);
    if (!node.isFolder || (extendedMap !== undefined && !extendedMap[node.id])) return _result;
    if (node.children && node.children.length > 0) {
      _result = node.children.reduce((acc, node) => this.getNodeAsPlainList(node, extendedMap, acc), _result);
    }

    return _result;
  }

  public getViewedTreeAsPlainList(extendedMap?: Record<string, boolean>): TreeNode[] {
    return this.nodes.map(node => TreeStructure.getNodeAsPlainList(node, extendedMap)).flat();
  }

  private buildBranch = (branch: TreeNode, depthLevel = 0, parentId?: string): TreeNode => {
    const isOutOfDepthLevel = depthLevel === this.maxDepthLevel - 1;

    const children = Object.values(this.dataset)
      .filter(el => el.parentId === branch.nodeId)
      .sort(this.options.sort)
      .map(el =>
        el.isFolder && !isOutOfDepthLevel
          ? this.buildBranch(el, depthLevel + 1, branch.id)
          : { ...el, depthLevel: depthLevel + 1, parentId: branch.id }
      );
    return {
      ...branch,
      parentId,
      depthLevel,
      children,
    };
  };

  public getViewedElementsCount(node: TreeNode, extendedMap: Record<string, boolean>, _count = 0): number {
    _count++;
    if (!node.isFolder || !extendedMap[node.id]) return _count;
    if (node.children && node.children.length > 0) {
      _count = node.children.reduce((acc, node) => this.getViewedElementsCount(node, extendedMap, acc), _count);
    }
    return _count;
  }

  public getNextDisplayedNode(selectedId: string | null, expandedMap: Record<string, boolean>) {
    const viewedItems = this.getViewedTreeAsPlainList(expandedMap);
    if (!selectedId) return viewedItems[0].id;
    const selectedIndex = viewedItems.findIndex(el => el.id === selectedId);
    if (selectedIndex === -1) return;
    const isLast = selectedIndex + 1 === viewedItems.length;
    if (isLast) return;
    return viewedItems[selectedIndex + 1].id;
  }

  public getPrevDisplayedNode(selectedId: string | null, expandedMap: Record<string, boolean>) {
    const viewedItems = this.getViewedTreeAsPlainList(expandedMap);
    if (!selectedId) return viewedItems[0].id;
    const selectedIndex = viewedItems.findIndex(el => el.id === selectedId);
    if (selectedIndex === -1) return;
    const isFirst = selectedIndex === 0;
    if (isFirst) return;
    return viewedItems[selectedIndex - 1].id;
  }

  public getParentNode(selectedId: string) {
    const selectedNode = this.getElementById(selectedId);
    if (!selectedNode) return;
    return selectedNode.parentId;
  }
}
