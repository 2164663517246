import React from 'react';
import { Controller } from 'react-hook-form';
import { Modal, JustSelect, InputText, Label, SwitchButton, Spinner, Banner } from '@just-ai/just-ui';

import { t } from 'localization';

import { useCreateIntegrationForm } from './logic';

import styles from './styles.module.scss';

interface CreateKhubIntegrationModalInterface {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}
const CreateKhubIntegrationModal = function ({ isOpen, onCancel, onSubmit }: CreateKhubIntegrationModalInterface) {
  const {
    form,
    getExistedKnowledgeBasesRequestState,
    basesListOptions,
    getKnowledgeBaseByTokenRequestState,
    isNeedToShowApiKeyField,
    isNeedToDisableNameField,
    isFormLoading,
    isCanSubmit,
    formError,
    submit,
  } = useCreateIntegrationForm({ onSubmit });

  return (
    <Modal
      className={styles.CreateKhubIntegrationModal}
      isOpen={isOpen}
      title={t(`KhubIntegration:CreateIntegrationModal:Title`)}
      data-test-id='KhubIntegration:CreateIntegrationModal'
      buttonSubmitColor='primary'
      buttonSubmitText={t(`KhubIntegration:CreateIntegrationModal:Submit`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Cancel')}
      onCancelClick={onCancel}
      disableActionButtonAutoFocus
      buttonSubmitDisabled={!isCanSubmit}
      onActionClick={submit}
      buttonSubmitTestId='KhubIntegration:CreateIntegrationModal:submitDelete'
      buttonCancelTestId='KhubIntegration:CreateIntegrationModal:cancelDelete'
      centered
    >
      <div className={styles.CreateKhubIntegrationModal__content}>
        {isFormLoading && <Spinner />}
        <Label className={styles.formLabel}>
          <span>{t('KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase')}</span>
          <Controller
            name='knowledgeBaseId'
            control={form.control}
            as={JustSelect}
            onChange={([value]) => {
              const val = value?.[0];
              form.setValue('knowledgeBaseId', val);
              return val;
            }}
            isLoading={getExistedKnowledgeBasesRequestState.loading}
            disabled={getExistedKnowledgeBasesRequestState.loading}
            options={basesListOptions}
            inputPlaceholder={t('KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase:Placeholder')}
            hint={t('KhubIntegration:CreateIntegrationModal:Fields:KnowledgeBase:Hint')}
            position='fixed'
            fullWidth
            data-test-id='CreateIntegrationModal.KnowledgeBase'
          />
        </Label>
        {isNeedToShowApiKeyField && (
          <Label className={styles.formLabel}>
            <span>{t('KhubIntegration:CreateIntegrationModal:Fields:ApiKey')}</span>
            <div>
              <Controller
                name='apiKey'
                control={form.control}
                shouldUnregister
                as={InputText}
                data-test-id='CreateIntegrationModal.ApiKey'
                disabled={getKnowledgeBaseByTokenRequestState.loading}
                onChange={([value]) => {
                  form.setValue('apiKey', value as unknown as string);
                  return value;
                }}
                placeholder={t('KhubIntegration:CreateIntegrationModal:Fields:ApiKey:Placeholder')}
                hint={
                  getKnowledgeBaseByTokenRequestState.loading
                    ? t('KhubIntegration:CreateIntegrationModal:Fields:ApiKey:HintLoading')
                    : getKnowledgeBaseByTokenRequestState.result
                      ? t('KhubIntegration:CreateIntegrationModal:Fields:ApiKey:HintLoaded', {
                          name: getKnowledgeBaseByTokenRequestState.result.name,
                        })
                      : undefined
                }
                errorText={form.errors.apiKey?.message}
                blurInactive
              />
            </div>
            <input name='knowledgeBaseIdByApiKey' hidden ref={form.register} />
          </Label>
        )}
        <Label className={styles.formLabel}>
          <span>{t('KhubIntegration:CreateIntegrationModal:Fields:Name')}</span>
          <Controller
            name='connectionName'
            control={form.control}
            as={InputText}
            disabled={isNeedToDisableNameField}
            onChange={([value]) => {
              form.setValue('connectionName', value as unknown as string);
              return value;
            }}
            data-test-id='CreateIntegrationModal.Name'
            hint={t('KhubIntegration:CreateIntegrationModal:Fields:Name:Hint')}
            errorText={form.errors.connectionName?.message}
            placeholder={t('KhubIntegration:CreateIntegrationModal:Fields:Name:Placeholder')}
            blurInactive
          />
        </Label>
        <div className='flex-column gap-2 margin-bottom-12'>
          <Controller
            name='needToInsertCode'
            control={form.control}
            as={SwitchButton}
            onChange={([value]) => {
              console.log('needToInsertCode', value);
              form.setValue('needToInsertCode', value as unknown as boolean);
              return value;
            }}
            id='createIntegrationModal-insertCode'
            data-test-id='CreateIntegrationModal.InsertCode'
            label={t('KhubIntegration:CreateIntegrationModal:Fields:NeedToInsertCode')}
            errorText={form.errors.needToInsertCode?.message}
            labelPosition='left'
            inline
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t('KhubIntegration:CreateIntegrationModal:Fields:NeedToInsertCode:Hint'),
            }}
          />
        </div>
        {formError && <Banner BannerText={() => <span>{formError}</span>} type='danger' />}
      </div>
    </Modal>
  );
};

export default React.memo(CreateKhubIntegrationModal);
