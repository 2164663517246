import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { Collapse } from "reactstrap";
import { Icon } from "..";
import classNames from "classnames";
import "./style.scss";
import { Theme } from "../Common";
const headerStyle = {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
};
const HeaderWrapper = ({ isOpen, toggleOpen, withWrapper, Header, HeaderContent, style = {}, "data-test-id": dataTestId, arrowPosition, theme, }) => withWrapper ? (_jsxs("div", { className: classNames("justui_spoiler-header-wrap", {
        "dark": theme === Theme["dark"],
    }), onClick: toggleOpen, "data-test-id": dataTestId, children: [_jsxs("div", { style: Object.assign(Object.assign({}, headerStyle), style), className: classNames("justui_spoiler-header", {
                "arrow-right": arrowPosition === "right",
            }), "aria-expanded": isOpen, children: [_jsx("span", { className: "spoiler-arrow", children: _jsx(Icon, { name: "faAngleDown", size: "md", className: classNames({ "spoiler-open": isOpen }) }) }), _jsx(Header, { isOpen: isOpen, toggleOpen: toggleOpen })] }), HeaderContent ? (_jsx("div", { className: "justui_spoiler-header-content", children: _jsx(HeaderContent, {}) })) : null] })) : (_jsx(Header, { isOpen: isOpen, toggleOpen: toggleOpen }));
export const Spoiler = ({ children, withWrapper = false, wrapperStyle = {}, Header, HeaderContent, initiallyOpened = false, "data-test-id": dataTestId, fullWidthCollapse = false, arrowPosition, openProp, toggleProp, theme = Theme["light"], }) => {
    const [isOpen, setOpen] = useState(initiallyOpened);
    useEffect(() => {
        setOpen(initiallyOpened);
    }, [initiallyOpened]);
    const toggleOpen = () => setOpen(!isOpen);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderWrapper, { arrowPosition: arrowPosition, withWrapper: withWrapper, Header: Header, HeaderContent: HeaderContent, isOpen: openProp || isOpen, toggleOpen: toggleProp || toggleOpen, style: wrapperStyle, "data-test-id": dataTestId, theme: theme }), _jsx(Collapse, { isOpen: openProp || isOpen, style: { width: fullWidthCollapse ? "100%" : "unset" }, children: _jsx("div", { className: classNames("justui_spoiler-collapse", {
                        "dark": theme === Theme["dark"],
                    }), children: children }) })] }));
};
