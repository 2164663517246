import Konva from 'konva';
import { t } from 'localization';
import React, { useMemo } from 'react';
import { Group, Rect, Text } from 'react-konva';

const TEXT_PROPS = () => ({
  fontFamily: 'Roboto',
  fontSize: 12,
  lineHeight: 16 / 12,
  letterSpacing: 0.01,
  fill: '#615108',
  padding: 4,
  text: t('JGraph:LastScreenFromDebug'),
  fontVariant: 'bold',
});

export const CurrentScreenFromDebug = React.memo<{ extraOffset?: number }>(({ extraOffset = 0 }) => {
  const rectWidth = useMemo(() => {
    const text = new Konva.Text(TEXT_PROPS());
    return text.width() + 8;
  }, []);
  return (
    <Group y={-24 + extraOffset} x={(280 - rectWidth) / 2}>
      <Rect width={rectWidth} height={24} fill='#FEDF78' cornerRadius={[8, 8, 0, 0]} />
      <Text x={4} {...TEXT_PROPS()} />
    </Group>
  );
});
