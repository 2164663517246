import React, { FunctionComponent, CSSProperties, useCallback, useMemo } from 'react';
import { Spoiler } from '@just-ai/just-ui';
import '../styles.scss';
import localize from '../../../../localization';
import {
  getTaskStatus,
  isLogsDownload,
  isReportGeneration,
  TASK_STATUSES,
  TaskStatusData,
  tsToString,
} from '../../utils/tasks';
import { uniqBy } from 'lodash';
import { Step } from './Step';
import { HeaderCompleted, HeaderErrors, HeaderInProgress, HeaderWarn } from './Header';

type TaskSpoilerProps = {
  task: TaskStatusData;
  isOpen: boolean;
  toggleOpen: () => void;
};

const TaskSpoiler: FunctionComponent<TaskSpoilerProps> = ({ task, isOpen, toggleOpen }) => {
  const header = useCallback(() => {
    const taskStatus = getTaskStatus(task);
    return (
      <div className='spoiler-header'>
        {taskStatus === TASK_STATUSES.IN_PROGRESS && <HeaderInProgress task={task} />}
        {taskStatus === TASK_STATUSES.COMPLETED && <HeaderCompleted task={task} />}
        {taskStatus === TASK_STATUSES.ERRORS && <HeaderErrors task={task} />}
        {taskStatus === TASK_STATUSES.WARN && <HeaderWarn task={task} />}
      </div>
    );
  }, [task]);

  const uniqNotifications = useMemo(() => {
    if (
      task.notifications?.some(
        notification =>
          !notification.message?.code?.code?.endsWith('task_progress') &&
          !(isReportGeneration(notification.message?.code?.code!) || isLogsDownload(notification.message?.code?.code!))
      )
    ) {
      return task.notifications;
    } else {
      return uniqBy(task.notifications, notification => notification.message?.code?.code);
    }
  }, [task.notifications]);

  return (
    //@ts-ignore
    <Spoiler openProp={isOpen} toggleProp={toggleOpen} withWrapper wrapperStyle={wrapperStyle} Header={header}>
      <div className='spoiler-container'>
        <div className='event-line' style={{ height: '22px', width: '2px' }} />
        {uniqNotifications?.map(notification => (
          <Step key={`notification-${notification.timestamp}`} notification={notification} />
        ))}
        {task.startTimestamp && (
          <div className='event-container'>
            <div className='event-heading'>
              <div className='event-circle' />
              <span>
                {tsToString(task.startTimestamp)} — {localize.translate('tasks start task')}
              </span>
            </div>
          </div>
        )}
      </div>
    </Spoiler>
  );
};

const wrapperStyle: CSSProperties = {
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  borderTop: '1px solid var(--gray-200)',
  padding: '12px 18px 12px 12px',
};

export default TaskSpoiler;
