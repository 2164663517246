import { LogLabelingReducer, LogLabelingState } from '.';
import { Phrases, Groups } from '../../../utils';
import { filterAllPhrasesAndAllGroups } from './grouping.reducer';
import { GroupInfo } from '../../../utils/groups';

const initReducer = (state: LogLabelingState, allGroups: GroupInfo[]) => {
  const newState = filterAllPhrasesAndAllGroups({
    ...state,
    allGroups: Groups.toDatasetWithUngrouped(allGroups, state.allPhrases),
  });
  return {
    ...newState,
    groups: newState.filteredAllGroups,
  };
};

const selectGroup = (state: LogLabelingState, groupId?: string) => {
  if (typeof groupId !== 'string' || !state.filteredAllGroups[groupId]) {
    return { ...state, selectedGroupIds: [], phrases: {}, groups: state.filteredAllGroups };
  }
  return {
    ...state,
    selectedGroupIds: [groupId],
    groups: state.filteredAllGroups,
    phrases: Phrases.sortDataset(
      Phrases.selectGroupPhrases(state.filteredAllGroups[groupId].phrases, state.filteredAllPhrases)
    ),
  };
};

const SimpleReducer: LogLabelingReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initReducer(state, action.allGroups);
    case 'SET_PHRASES_FILTER':
    case 'TOGGLE_SHOW_LABELED':
    case 'DELETE_PHRASES':
    case 'STAGE_PHRASES':
    case 'CLEAR_PHRASES':
      return selectGroup(state, state.selectedGroupIds[0]);
    case 'SELECT_GROUPS':
      return selectGroup(state, action.groupIds[0]);
    default:
      return { ...state };
  }
};

export default SimpleReducer;
