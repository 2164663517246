import { useCallback, useState, useRef } from 'react';
const emptyObj = {};
export default function usePromisifyComponent(callback, handlers, deps = []) {
    const handlersInner = useRef(emptyObj);
    handlersInner.current = handlers !== null && handlers !== void 0 ? handlers : emptyObj;
    const [opened, setOpen] = useState(false);
    const innerCallback = useCallback(callback, [callback, opened, ...deps]);
    const promise = useRef();
    const onClose = useCallback(() => {
        var _a;
        (_a = promise.current) === null || _a === void 0 ? void 0 : _a.reject(new Error('Component rejected'));
    }, []);
    const onResolve = useCallback(data => {
        var _a;
        (_a = promise.current) === null || _a === void 0 ? void 0 : _a.resolve(data);
    }, []);
    const openProps = useRef();
    const open = useCallback((props) => {
        openProps.current = props;
        setOpen(true);
        let resolveRef;
        let rejectRef;
        let promiseRef = new Promise((resolve, reject) => {
            resolveRef = resolve;
            rejectRef = reject;
        });
        promise.current = {
            promise: promiseRef,
            resolve: resolveRef,
            reject: rejectRef,
        };
        return promise.current
            .promise.then(data => {
            var _a, _b;
            (_b = (_a = handlersInner.current) === null || _a === void 0 ? void 0 : _a.onResolve) === null || _b === void 0 ? void 0 : _b.call(_a, data);
            return data;
        })
            .catch(err => {
            var _a, _b;
            (_b = (_a = handlersInner.current) === null || _a === void 0 ? void 0 : _a.onReject) === null || _b === void 0 ? void 0 : _b.call(_a, err);
            throw err;
        })
            .finally(() => {
            setOpen(false);
            promise.current = undefined;
            openProps.current = undefined;
        });
    }, [openProps]);
    return [innerCallback(onResolve, onClose, opened, openProps.current), open, onClose, opened];
}
