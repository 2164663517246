import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
//@ts-ignore
import { PositionType } from './index';
import { t } from 'localization';
import classNames from 'classnames';
import { Icon } from '@just-ai/just-ui';
import ClickAwayListener from '../../../../components/ClickAwayListener';

export interface UrlEditorProps {
  text: string;
  url: string;
  position: PositionType;
  close?: () => unknown;
  save?: (link: string, text: string) => unknown;
}

//TODO fix floating position

export const UrlEditor: FC<UrlEditorProps> = ({ position, text, url, close = () => {}, save = () => {} }) => {
  const positionCalculated = useRef<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRefOffset, setContainerRefOffset] = useState<PositionType>({ left: 0, top: 0, height: 0, width: 0 });
  const linkRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLInputElement>(null);

  const [linkValue, setLinkValue] = useState<string>(url);
  const [textValue, setTextValue] = useState<string>(text);

  const checkKey = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape': {
          close();
          break;
        }
        case 'Enter': {
          if (linkRef.current && textRef.current) save(linkRef.current.value, textRef.current.value);
          break;
        }
      }
    },
    [close, save]
  );

  useEffect(() => {
    document.addEventListener('keydown', checkKey);
    return () => {
      document.removeEventListener('keydown', checkKey);
    };
  }, [checkKey]);

  useEffect(() => {
    const openEvent = new CustomEvent('customConfirmationOpened', {
      detail: {
        opened: true,
      },
    });
    const closeEvent = new CustomEvent('customConfirmationOpened', {
      detail: {
        opened: false,
      },
    });
    document.dispatchEvent(openEvent);

    if (containerRef.current) {
      const relativeParent = containerRef.current.parentNode as Element;
      if (relativeParent) {
        const relativeParentPosition = relativeParent.getBoundingClientRect();
        let left = 0;
        if (position.left + 420 > relativeParentPosition.width) {
          left = position.left + 420 - relativeParentPosition.width;
        }
        positionCalculated.current = true;
        setContainerRefOffset(prev => ({
          ...prev,
          left: left,
        }));
      }
    }

    return () => {
      document.dispatchEvent(closeEvent);
    };
  }, [position.left]);

  const clearText = useCallback(
    (ref: RefObject<HTMLInputElement>) => () => {
      if (ref.current) ref.current.value = '';
    },
    []
  );

  return (
    <ClickAwayListener handleClickOut={close} handleEnabled={positionCalculated.current}>
      <div
        className={classNames('WysiwygUrlEditor', { show: positionCalculated.current })}
        data-test-id='WysiwygUrlEditor'
        style={
          positionCalculated.current
            ? { top: position.top + position.height, left: position.left - containerRefOffset.left }
            : {}
        }
        ref={containerRef}
      >
        <div className='urlEditorInput'>
          <Icon name='farLink' wrapperClassName='urlEditorInputIconLeft' />
          <input
            type='text'
            ref={linkRef}
            autoFocus
            data-test-id='WysiwygUrlEditor:UrlInput'
            defaultValue={url}
            onChange={event => setLinkValue(event.target.value)}
          />
          {linkValue && (
            <div className='urlEditorInputIconRight' onClick={clearText(linkRef)}>
              <Icon name='farTimes' />
            </div>
          )}
        </div>
        <div className='urlEditorInput'>
          <Icon name='faText' wrapperClassName='urlEditorInputIconLeft' />
          <input
            type='text'
            data-test-id='WysiwygUrlEditor:TextInput'
            ref={textRef}
            defaultValue={text}
            onChange={event => setTextValue(event.target.value)}
          />
          {textValue && (
            <div className='urlEditorInputIconRight' onClick={clearText(textRef)}>
              <Icon name='farTimes' />
            </div>
          )}
        </div>
        <div className='helperText' data-test-id='WysiwygUrlEditor:SaveBtn'>
          {t('UrlEditor:EnterToSave')}
        </div>
      </div>
    </ClickAwayListener>
  );
};
