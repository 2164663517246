import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { getDefaultValueOnParamType } from 'reducers/JGraph.reducer/utils';
import { useFirstRender } from 'utils/hooks/useFirstRender';
import { useRightSideMenuContext } from '../../index';
import { isParameterValueMaybeVariable, validateParameterValueByType } from './typeValidation';
import { CustomTagViewTag } from './types';

export type ControllerWrapperProps = {
  name: string;
  type?: CustomTagViewTag;
  onChange?: (name: string, value: any) => unknown;
};

export type TControllerInjectedProps = {
  name: string;
  onChange: (value: any) => unknown;
  value: any;
  originalValue?: any;
  onBlur: (event: React.SyntheticEvent) => unknown;
  invalid: boolean;
  isValidFormat: boolean;
  isParameterMaybeVariable: boolean;
  isShouldShowFormatWarning: boolean;
  isRequired: boolean;
};

export function withController<TProps extends ControllerWrapperProps>(WrappedComponent: React.ComponentType<TProps>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithController = (props: ControllerWrapperProps & Omit<TProps, keyof TControllerInjectedProps>) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { customTagEdit } = useRightSideMenuContext();
    const [value, setValue] = useState(() => customTagEdit.fieldValue(props.name));
    const isRequired = useRef(customTagEdit.isRequired(props.name));
    const onChange = useCallback(
      value => {
        customTagEdit.onChange(props.name, value);
        props.onChange && props.onChange(props.name, value);
      },
      [customTagEdit, props]
    );

    useEffect(() => {
      const sub = customTagEdit.tagParametersObj[props.name].subscribe(tTagParamValue => {
        setValue(tTagParamValue.value);
      });
      return () => {
        sub.unsubscribe();
      };
    }, [customTagEdit.tagParametersObj, props.name]);

    const onBlur = useCallback(
      event => {
        if (isRequired.current) {
          customTagEdit.onChange(props.name, event.target.value || '');
        }
      },
      [customTagEdit, props.name]
    );

    const isValidFormat = useMemo(() => validateParameterValueByType(value, props.type), [props.type, value]);
    const isParameterMaybeVariable = useMemo(() => isParameterValueMaybeVariable(value), [value]);

    const isMountPhase = useFirstRender();

    const displayedValue = useMemo(() => {
      if (!props.type || !isMountPhase.current) return value;
      if (!isValidFormat) {
        return getDefaultValueOnParamType(props.type);
      }
      return value;
    }, [isMountPhase, isValidFormat, props.type, value]);

    // props comes afterwards so the can override the default ones.
    return (
      <WrappedComponent
        {...(props as TProps)}
        value={displayedValue}
        originalValue={value}
        onChange={onChange}
        isValidFormat={isValidFormat}
        isShouldShowFormatWarning={isMountPhase}
        isParameterMaybeVariable={isParameterMaybeVariable}
        invalid={customTagEdit.isRequiredNotFilled(props.name)}
        onBlur={onBlur}
        isRequired={isRequired.current}
      />
    );
  };

  ComponentWithController.displayName = `withController(${displayName})`;

  return ComponentWithController;
}
