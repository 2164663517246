import React, { FC } from 'react';
import classes from './Activations.module.scss';
import { Label } from '@just-ai/just-ui';
import { CommonActivationParams } from './CommonActivationParams';
import { t } from 'localization';
import './textAreaLikeElement.scss';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';

export type EventStateChange = {
  isGlobal: boolean;
  fromStateValue: string;
  toStateValue: string;
  onlyThisState?: boolean;
};
type PatternActivationEditProps = {
  changePattern: (value: string) => unknown;
  pattern: string;
  isGlobal: boolean;
  changeEventParams: (value: EventStateChange) => unknown;
  eventState: EventStateChange;
  isFromState?: boolean;
  isToState?: boolean;
};

export const PatternActivationEdit: FC<PatternActivationEditProps> = ({
  pattern,
  isGlobal,
  changeEventParams,
  eventState,
  changePattern,
  isFromState,
  isToState,
}) => {
  return (
    <div className={classes.PatternsActivation}>
      <div>
        <Label>{t('PatternsActivation:initialLabel')}</Label>
        <TextAreaLikeElement
          dataTestId='PatternsActivation_Value'
          value={pattern}
          className='textAreaLikeElementVisible'
          placeholder={t('PatternsActivation:initialPlaceholder')}
          onChange={changePattern}
        />
      </div>
      <div className={classes.divider2} />
      <CommonActivationParams
        isFromState={isFromState}
        isToState={isToState}
        isGlobal={isGlobal}
        onChangeIsGlobal={value =>
          changeEventParams({
            isGlobal: value,
            onlyThisState: eventState.onlyThisState,
            fromStateValue: eventState.fromStateValue,
            toStateValue: eventState.toStateValue,
          })
        }
        onlyThisState={eventState.onlyThisState}
        onChangeOnlyThisState={value =>
          changeEventParams({
            isGlobal: isGlobal,
            onlyThisState: value,
            fromStateValue: eventState.fromStateValue,
            toStateValue: eventState.toStateValue,
          })
        }
        fromStateValue={eventState.fromStateValue}
        onChangeStateValue={value =>
          changeEventParams({
            isGlobal: isGlobal,
            onlyThisState: eventState.onlyThisState,
            fromStateValue: isFromState ? value : '',
            toStateValue: isToState ? value : '',
          })
        }
        toStateValue={eventState.toStateValue}
      />
    </div>
  );
};
