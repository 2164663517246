export const GuideTourStepperLocalization = {
  'GuideTourStepper:Actions:Skip': {
    eng: 'Skip for now',
    ru: 'В другой раз',
  },
  'GuideTourStepper:Actions:Next': {
    eng: 'Next',
    ru: 'Дальше',
  },
  'GuideTourStepper:Actions:Back': {
    eng: 'Back',
    ru: 'Назад',
  },
  'GuideTourStepper:Actions:Close': {
    eng: 'Close',
    ru: 'Закрыть',
  },
  'GuideTourStepper:Actions:Last': {
    eng: 'Done',
    ru: 'Завершить',
  },
  'GuideTourStepper:Actions:OpenModal': {
    eng: 'Open modal window',
    ru: 'Открыть модальное окно',
  },
};
