import { TReactionsTagNames, TagNames } from 'modules/JGraph/utils/types';
import { BlockType } from 'modules/JGraph/view/RightSideMenu/types';
import { CustomTagsStore$ } from '../../../../../reducers/JGraph.reducer/customTags.store';

export const BotResponsesDefault: TReactionsTagNames[] = [
  TagNames.a,
  TagNames.image,
  TagNames.audio,
  // TagNames.video,
  // TagNames.random,
];
export const LogicResponsesDefault: TReactionsTagNames[] = [
  TagNames.if,
  TagNames.random,
  TagNames.buttons,
  TagNames.go_,
  TagNames.script,
];
export const MetaResponsesDefault = () => CustomTagsStore$.getValue().map(tag => tag.tagName) as TReactionsTagNames[];

type ResponsesType = {
  BotResponses: TReactionsTagNames[];
  LogicResponses: TReactionsTagNames[];
  MetaResponses: TReactionsTagNames[];
};

export function getResponsesByBlockType(type: BlockType): ResponsesType {
  switch (type) {
    case 'random':
      return {
        BotResponses: BotResponsesDefault,
        LogicResponses: [TagNames.buttons, TagNames.go_, TagNames.script],
        MetaResponses: MetaResponsesDefault(),
      };
  }
  return {
    BotResponses: BotResponsesDefault,
    LogicResponses: LogicResponsesDefault,
    MetaResponses: MetaResponsesDefault(),
  };
}
