import React, { FC, useEffect, useState } from 'react';
import { CustomRxTooltip, IconButton, useBehaviorSubject, useId } from '@just-ai/just-ui';
import cn from 'classnames';
import { highLightConnectors$ } from '../../../hooks/highLightConnectors';

import { t } from 'localization';
import styles from '../styles.module.scss';

export const HighLightConnectorsButton: FC<{ screenPathId: string }> = ({ screenPathId }) => {
  const id = useId();
  const highLightConnectorsValue = useBehaviorSubject(highLightConnectors$);

  const [highLightBulb, setHighLightBulb] = useState(
    Boolean(highLightConnectorsValue.activePathId && highLightConnectorsValue.activePathId === screenPathId)
  );

  useEffect(() => {
    setHighLightBulb(
      Boolean(highLightConnectorsValue.activePathId && highLightConnectorsValue.activePathId === screenPathId)
    );
  }, [highLightConnectorsValue, screenPathId]);

  return (
    <>
      <IconButton
        flat
        data-test-id='JGraph.LabellingToolMenu.HighLightConnectorsButton'
        id={id}
        color='secondary'
        name='faLightbulb'
        className={cn(styles.selectLabelColor, {
          [styles.selectLabelColor_fillBlue]: highLightBulb,
          [styles.selectLabelColor_fillBlue_active]: highLightBulb,
        })}
        onClick={() =>
          highLightBulb
            ? highLightConnectors$.next({ connector: null })
            : highLightConnectors$.next({ activePathId: screenPathId, connector: null })
        }
      />
      <CustomRxTooltip target={`#${id}`} placement='bottom'>
        {highLightBulb
          ? t('JGraph:LabellingToolMenu:HighLightConnectorsButton:tooltip_off')
          : t('JGraph:LabellingToolMenu:HighLightConnectorsButton:tooltip_on')}
      </CustomRxTooltip>
    </>
  );
};
