import {
  CLEAR_EDITABLE_INTEGRATION,
  CREATE_INTEGRATION_FULFILLED,
  FETCH_INTEGRATIONS_FULFILLED,
  FETCH_INTEGRATIONS_PENDING,
  FETCH_INTEGRATIONS_REJECTED,
  REMOVE_EDITABLE_INTEGRATION_FULFILLED,
  SAVE_EDITABLE_INTEGRATION_FULFILLED,
  SET_EDITABLE_INTEGRATION,
} from '../../constants/integrations.actions';
import { remove } from 'lodash/array';
import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

const InitialState = {
  integrationsList: [],
  selectedIntegrationId: null,
  fetching: false,
  fetched: false,
  error: null,
  disabled: false,
};

export default function IntegrationsReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_INTEGRATIONS_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_INTEGRATIONS_FULFILLED:
      if (!Array.isArray(action.payload.data))
        return {
          ...state,
          fetching: false,
          fetched: true,
          disabled: true,
        };
      return {
        ...state,
        integrationsList: action.payload.data.reverse(),
        fetched: true,
        fetching: false,
      };

    case FETCH_INTEGRATIONS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data,
      };

    case SET_EDITABLE_INTEGRATION:
      return {
        ...state,
        selectedIntegrationId: action.integration.id,
      };

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case CLEAR_EDITABLE_INTEGRATION:
      return {
        ...state,
        selectedIntegrationId: null,
      };

    case CREATE_INTEGRATION_FULFILLED:
      let integrationsListAdded = state.integrationsList;
      integrationsListAdded.splice(0, 0, action.payload.data);
      return {
        ...state,
        integrationsList: integrationsListAdded,
        selectedIntegrationId: action.payload.data.id,
        fetched: true,
        fetching: false,
      };
    case SAVE_EDITABLE_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrationsList: state.integrationsList.map(integration => {
          return integration.id === action.payload.data.id ? action.payload.data : integration;
        }),
      };
    case REMOVE_EDITABLE_INTEGRATION_FULFILLED:
      let integrationsListRemoved = state.integrationsList;
      remove(integrationsListRemoved, integration => {
        return integration.id === action.payload.data.id;
      });
      return {
        ...state,
        integrationsList: integrationsListRemoved,
        selectedIntegrationId: null,
      };

    default:
      return state;
  }
}
