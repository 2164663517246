import { FC } from 'react';
import { Icon, IconProps } from '@just-ai/just-ui';

import { t } from 'localization';

export enum ContextAction {
  Rename = 'rename',
  Delete = 'delete',
  CreateTheme = 'create:theme',
  CreateStep = 'create:step',
  CreateInnerStep = 'create:innerStep',
}

export type ItemMenu = {
  value: ContextAction | string;
  label: string;
  color?: 'danger' | 'secondary';
  iconCmp?: FC<{ size?: IconProps['size'] }>;
  cantSelect?: boolean;
  children?: ItemMenu[];
};

export const getStateOption = (): ItemMenu[] => [
  {
    label: t('HierarchyContextMenu:Create'),
    value: 'create',
    cantSelect: true,
    children: [
      {
        label: t('HierarchyContextMenu:Create:Step'),
        value: ContextAction.CreateStep,
        iconCmp: props => <Icon {...props} name='farJGState' color='secondary' />,
      },
      {
        label: t('HierarchyContextMenu:Create:InnerStep'),
        value: ContextAction.CreateInnerStep,
        iconCmp: props => <Icon {...props} name='farJGState' color='secondary' />,
      },
      {
        label: t('HierarchyContextMenu:Create:Theme'),
        value: ContextAction.CreateTheme,
        iconCmp: props => <Icon {...props} name='faJGTheme' color='secondary' />,
      },
    ],
  },
  {
    label: t('HierarchyContextMenu:Rename'),
    value: ContextAction.Rename,
    iconCmp: props => <Icon {...props} size='sm' name='farPen' color='secondary' />,
  },
  {
    label: t('HierarchyContextMenu:Delete'),
    value: ContextAction.Delete,
    iconCmp: props => <Icon {...props} size='sm' name='farTrashAlt' color='secondary' />,
    color: 'danger',
  },
];

export const getThemeOption = (): ItemMenu[] => [
  {
    label: t('HierarchyContextMenu:Create'),
    value: 'create',
    cantSelect: true,
    children: [
      {
        label: t('HierarchyContextMenu:Create:Step'),
        value: ContextAction.CreateStep,
        iconCmp: props => <Icon {...props} name='farJGState' color='secondary' />,
      },
      {
        label: t('HierarchyContextMenu:Create:Theme'),
        value: ContextAction.CreateTheme,
        iconCmp: props => <Icon {...props} name='faJGTheme' color='secondary' />,
      },
    ],
  },
  {
    label: t('HierarchyContextMenu:Rename'),
    value: ContextAction.Rename,
    iconCmp: props => <Icon {...props} size='sm' name='farPen' color='secondary' />,
  },
  {
    label: t('HierarchyContextMenu:Delete'),
    value: ContextAction.Delete,
    iconCmp: props => <Icon {...props} size='sm' name='farTrashAlt' color='secondary' />,
    color: 'danger',
  },
];

export const getNoContextOptions = (): ItemMenu[] => [
  {
    label: t('HierarchyContextMenu:CreateTheme'),
    value: ContextAction.CreateTheme,
    iconCmp: props => <Icon {...props} name='farPlus' color='secondary' />,
  },
];
