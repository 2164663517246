import React, { FC, useState, useCallback, useMemo } from 'react';
import { InlineRadioButton, Option, AudioFieldMemo as AudioField } from '@just-ai/just-ui';
import cn from 'classnames';

import localize, { t } from 'localization';

import { useRightSideMenuContext } from 'modules/JGraph/view/RightSideMenu/index';

import { TJBlock, TagNames } from 'modules/JGraph/utils/types';
import { imputerLocalization } from 'modules/Imputer/localization/imputer.loc.js';

import { useStaticLocalizationUpdate } from 'utils/hooks/useStaticLocalizationUpdate';

import styles from './styles.module.scss';
import AudioUploadHint from './AudioUploadHint';
import getFileNameFromUrl from 'utils/url/getFileNameFromUrl';
import { setTagParameterInParametersList } from 'modules/JGraph/utils/stageBlock';
import AudioUrlField from 'modules/JGraph/view/RightSideMenu/settings/AudioSettings/AudioUrlField';
import { saveAudioToServer } from 'pipes/functions';

localize.addTranslations(imputerLocalization);

const getAudioTabsOptions = () => [
  { name: t('AudioSettings:upload-file'), value: 'file', dataTestId: 'JGraph.RightSideMenu.Audio.file' },
  { name: t('AudioSettings:file-link'), value: 'link', dataTestId: 'JGraph.RightSideMenu.Audio.link' },
];

export const AudioSettings: FC = () => {
  const { getEditBlockTag, onChangeCommonTag, resetModifications } = useRightSideMenuContext();
  const editBlock = getEditBlockTag() as TJBlock<TagNames.image>;
  const [activeTab, setActiveTab] = useState(() => {
    const audioTagValue = editBlock.tagValue || '';
    const audioLinkWithSession = audioTagValue.includes('}}');
    return audioLinkWithSession ? 'link' : 'file';
  });
  const audioTabs = useStaticLocalizationUpdate(getAudioTabsOptions);
  const tabChange = useCallback((_, tab: Option) => setActiveTab(tab.value?.toString() ?? ''), []);

  const onChange = useCallback(
    (file?: { url: string; name: string }) => {
      if (!file) {
        resetModifications();
        return;
      }
      onChangeCommonTag({
        tagValue: file.url,
        tagParameters: setTagParameterInParametersList(
          { name: 'name', required: false, value: file.name },
          editBlock.tagParameters
        ),
      });
    },
    [onChangeCommonTag, editBlock.tagParameters, resetModifications]
  );

  const onUrlSet = useCallback(
    (url: string) => {
      if (!url) {
        onChange();
        return;
      }
      onChange({
        url,
        name: getFileNameFromUrl(url),
      });
    },
    [onChange]
  );

  const fileName = useMemo(() => {
    const nameParameter = editBlock.tagParameters.find(tagParam => tagParam.name === 'name');
    if (!nameParameter || !nameParameter.value) return '';
    return nameParameter.value;
  }, [editBlock.tagParameters]);

  return (
    <div className={cn(styles.AudioSettings, 'flex-column gap-4x AudioSettings')}>
      <AudioUploadHint />
      <div className='flex-column gap-4x padding-left-7x padding-right-7x'>
        <div className='InlineButtons-full-width'>
          <InlineRadioButton
            active={activeTab}
            color='primary'
            name='buttonType'
            onChange={tabChange}
            options={audioTabs}
            outline
            size='xs'
          />
        </div>
        {activeTab === 'file' && (
          <AudioField
            saveAudioToServer={saveAudioToServer}
            url={editBlock.tagValue}
            fileName={fileName}
            onChange={onChange}
          />
        )}
        {activeTab === 'link' && <AudioUrlField url={editBlock.tagValue} onChange={onUrlSet} />}
      </div>
    </div>
  );
};
