import { DEPENDENCY_FILE_PREFIX, SYSTEM_DEPENDENCY_FOLDER } from 'modules/Editor/context/EditorContext';
import {
  StorageApi,
  ConflictResolutionType,
  HealthApi,
  FileData,
  GraphV2Api,
  GraphV2UpdateItemData,
  GraphV2UpdateData,
  CustomTagData,
  FileContentData,
  ParseError,
  DependencyApi,
  State,
  Locator,
  ValidationApi,
  ValidationResult,
} from '../api/client';
import hashSum from 'hash-sum';
import { axios } from '../../../pipes/functions';
import FileContent from '../model/FileContent';
import FileNode from '../model/FileNode';
import { dtoToGraph, Graph } from '../model/Graph';
import { KEEP_FILE_NAME } from '../model/FileTree';
import { AxiosPromise } from 'axios';
import isAccess, { isSystemAccess } from '../../../isAccessFunction';

export interface EditorValidationResult {
  parseErrors: ParseError[];
  states: State[];
}

const isReadOnly = (file: FileContentData) => {
  switch (true) {
    case file.name?.endsWith('jgraph.visuals'):
      return true;
    default:
      return false;
  }
};

export default class StorageService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private projectId: string;
  private storageApi: StorageApi;
  private graphApi: GraphV2Api;
  private dependencyApi: DependencyApi;
  private validationApi: ValidationApi;
  private healthApi: HealthApi;
  private filePromises: { [key: string]: AxiosPromise<FileContentData> };
  private savedFiles: { [key: string]: string };
  private hasExcessValidations: boolean;
  private validationPromise: AxiosPromise<ValidationResult> | null;

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
    this.storageApi = new StorageApi({}, StorageService.BASE_PATH, axios);
    this.graphApi = new GraphV2Api({}, StorageService.BASE_PATH, axios);
    this.dependencyApi = new DependencyApi({}, StorageService.BASE_PATH, axios);
    this.validationApi = new ValidationApi({}, StorageService.BASE_PATH, axios);
    this.healthApi = new HealthApi({}, StorageService.BASE_PATH, axios);
    this.filePromises = {};
    this.savedFiles = {};
    this.hasExcessValidations = false;
    this.validationPromise = null;
  }

  healthCheck = async () => this.healthApi.healthCheck();

  getFileTree = async (): Promise<[FileData[], string | undefined, FileData[], FileData[]]> => {
    const { data: fileTreeData } = await this.storageApi.getTree(this.accountId, this.projectId);
    return [
      fileTreeData.files || [],
      fileTreeData.mainFile,
      fileTreeData.dependencies || [],
      fileTreeData.systemDependencies || [],
    ];
  };

  updateDependencies = async () => {
    await this.dependencyApi.updateDependencies(this.accountId, this.projectId);
  };

  getDependency = async (fileId: string) => {
    const { data: file } = fileId.startsWith(DEPENDENCY_FILE_PREFIX + SYSTEM_DEPENDENCY_FOLDER)
      ? await this.dependencyApi.getSystemDependency(FileNode.deprefixed(fileId, DEPENDENCY_FILE_PREFIX))
      : await this.dependencyApi.getDependency(
          this.accountId,
          this.projectId,
          FileNode.deprefixed(fileId, DEPENDENCY_FILE_PREFIX)
        );

    return FileContent.fromDto(file, true);
  };

  getFile = async (fileId: string, lastModified?: number): Promise<FileContent> => {
    try {
      if (!this.filePromises[fileId]) {
        this.filePromises[fileId] = this.storageApi.getFile(this.accountId, this.projectId, fileId, lastModified);
      }
      const { data: file } = await this.filePromises[fileId];

      return FileContent.fromDto(file, isReadOnly(file));
    } finally {
      delete this.filePromises[fileId];
      delete this.savedFiles[fileId];
    }
  };

  checkFileVersionWasSaved = (path: string, content: string) => {
    const fileHashSum = hashSum(content);
    if (this.savedFiles[path] === fileHashSum) {
      return true;
    } else {
      this.savedFiles[path] = fileHashSum;
      return false;
    }
  };

  saveFile = async (file: FileContent, lastModified: number, force?: boolean): Promise<FileContent> => {
    const { data: updatedFile } = await this.storageApi.writeFile(
      this.accountId,
      this.projectId,
      file.path,
      {
        name: file.path,
        content: file.content,
        lastModified: lastModified,
      },
      force
    );
    return FileContent.fromDto(updatedFile);
  };

  getGraph = async (fileId: string, customTags: CustomTagData[]) => {
    if (isAccess('jgraph') && isSystemAccess(['jgraph_licensed'])) {
      return {
        path: '',
        lastModified: 0,
        blocks: [],
        connections: [],
      };
    }
    const { data: file } = await this.graphApi.getGraph(this.accountId, this.projectId, fileId);
    return dtoToGraph(file, customTags);
  };

  saveGraph = async (
    graph: Graph,
    lastModified: number,
    actions: GraphV2UpdateItemData[],
    customTags: CustomTagData[],
    force?: boolean
  ) => {
    const { data: updatedFile } = await this.graphApi.updateGraph(
      this.accountId,
      this.projectId,
      this.getUpdateGraphDto(graph, lastModified, actions),
      force
    );
    return dtoToGraph(updatedFile, customTags);
  };

  renameFile = async (fileId: string, newFileId: string, needToKeepFolder?: boolean) => {
    if (needToKeepFolder) {
      await this.createKeepFile(fileId.substring(0, fileId.lastIndexOf('/')));
    }
    const { data: fileTreeData } = await this.storageApi.renameFileOrFolder(this.accountId, this.projectId, {
      oldName: fileId,
      newName: newFileId,
    });
    return fileTreeData.files || [];
  };

  renameFolder = async (folderId: string, newFolderId: string, needToKeepFolder: boolean) => {
    if (needToKeepFolder) {
      await this.createKeepFile(folderId.substring(0, folderId.lastIndexOf('/')));
    }
    return this.renameFile(folderId + '/', newFolderId + '/');
  };

  createFile = async (fileId: string, content?: string) => {
    const { data: newFile } = await this.storageApi.writeFile(this.accountId, this.projectId, fileId, {
      name: fileId,
      lastModified: 0,
      content: content ? content : '',
    });
    return FileNode.fromDto(newFile);
  };

  createKeepFile = async (folderId: string) => {
    return this.createFile(`${folderId}/${KEEP_FILE_NAME}`);
  };

  deleteFile = async (fileId: string, needToKeepFolder?: boolean) => {
    if (needToKeepFolder) {
      await this.createKeepFile(fileId.substring(0, fileId.lastIndexOf('/')));
    }
    return await this.storageApi.deleteFile(this.accountId, this.projectId, fileId);
  };

  deleteFolder = async (folderPath: string) => {
    return this.storageApi.deleteFolder(this.accountId, this.projectId, folderPath);
  };

  copyFile = async (fileId: string, newFileId: string) => {
    const { data: fileTreeData } = await this.storageApi.copyFileOrFolder(this.accountId, this.projectId, {
      oldName: fileId,
      newName: newFileId,
    });
    return fileTreeData.files || [];
  };

  copyFolder = async (folderId: string, newFolderId: string) => {
    return await this.copyFile(folderId + '/', newFolderId + '/');
  };

  validate = (setValidationResult: (result: EditorValidationResult) => void) => {
    if (!this.validationPromise) {
      if (this.hasExcessValidations) return;
      this.validationPromise = this.validationApi.validateProject(this.accountId, this.projectId);

      this.validationPromise.then(({ data }) => {
        this.validationPromise = null;

        setValidationResult({
          parseErrors: this.getParseErrors(data),
          states: this.getStates(data),
        });

        if (this.hasExcessValidations) {
          this.hasExcessValidations = false;
          this.validate(setValidationResult);
        }
      });
      return;
    } else this.hasExcessValidations = true;
  };

  commit = async (commitMessage: string, conflictResolution?: ConflictResolutionType) => {
    return await this.storageApi.commitAndPush(this.accountId, this.projectId, { commitMessage, conflictResolution });
  };

  pull = async () => {
    return await this.storageApi.pull(this.accountId, this.projectId);
  };

  discard = async () => {
    return await this.storageApi.discardChanges(this.accountId, this.projectId);
  };

  private getUpdateGraphDto(graph: Graph, lastModified: number, actions: GraphV2UpdateItemData[]): GraphV2UpdateData {
    return {
      file: graph.path,
      lastModified: lastModified,
      items: actions,
    };
  }

  private normalizeLocator<TValue extends { locator: Locator }>(value: TValue): TValue {
    return {
      ...value,
      locator: {
        ...value.locator,
        filename: value.locator.filename.startsWith('/') ? value.locator.filename : '/' + value.locator.filename,
        line: value.locator.line - 1,
        col: value.locator.col - 1,
      },
    };
  }

  private getParseErrors(validation: ValidationResult): ParseError[] {
    return validation.validationErrors?.map(this.normalizeLocator) || [];
  }

  private getStates(validation: ValidationResult): State[] {
    return validation.projectStates?.map(this.normalizeLocator) || [];
  }
}
