import { isActivationToState } from 'modules/JGraph/utils/isActivationToState';
import { TJBlock, getActivationsTagNames, TActivationTagNames } from 'modules/JGraph/utils/types';
import { TSortableItem } from '../types';

export const getSortableActivations = (blocks: TJBlock[], isLiteMode = false) => {
  let result: TSortableItem[] = [];
  blocks.forEach((block, blockIndex) => {
    if (
      getActivationsTagNames(isLiteMode).includes(block.tagName as TActivationTagNames) &&
      !isActivationToState(block)
    ) {
      result.push({ block: block, blockIndex: blockIndex, parentBlocks: blocks });
    }
  });
  return result;
};
