const localization = {
    "Show log": {
        'ru': 'Посмотреть лог',
        'en': 'View log',
    },
    "Copy": {
        'ru': 'Скопировать',
        'en': 'Copy',
    },
    "Close": {
        'ru': 'Закрыть',
        'en': 'Close',
    },
};
export function translate(text, locale) {
    if (!localization[text])
        return text;
    return localization[text][locale] ? localization[text][locale] : localization[text].en;
}
