import { BaseAbstractStorage, StorageResponse } from './BaseAbstractStorage';

type RuleKey = 'includes';
type PermanentStoredRule = string | { rule: RuleKey; value: string };

const defaultRules: PermanentStoredRule[] = [
  'USER_LANGUAGE',
  'SIDEBAR_MIN',
  {
    rule: 'includes',
    value: 'imputer_request_sended_for_',
  },
];

export type Serializer<DATA = any> = {
  serialize(data: DATA): string;
  deserialize(str: string): DATA;
};

export const boolSerializer: Serializer<boolean> = {
  deserialize: str => str === 'true',
  serialize: data => (data ? 'true' : 'false'),
};
export const objectSerializer: Serializer<Record<string, any>> = {
  deserialize: str => JSON.parse(str),
  serialize: data => JSON.stringify(data),
};
export const stringSerializer: Serializer<string> = {
  deserialize: str => str,
  serialize: data => data,
};

export class LocalStorageService extends BaseAbstractStorage {
  private static _instance: LocalStorageService = new LocalStorageService();
  private permanentStoredRules: Set<PermanentStoredRule> = new Set(defaultRules);
  private readonly permanentStoredRulesKey = 'LocalStorageService:permanentStoredRules';

  constructor() {
    super();
    if (LocalStorageService._instance) return LocalStorageService._instance;
    LocalStorageService._instance = this;
    this.init();
  }

  private async init() {
    const permanentStoredRulesString = await this.get<PermanentStoredRule[]>(
      this.permanentStoredRulesKey,
      objectSerializer
    );
    if (!permanentStoredRulesString.success || !permanentStoredRulesString.payload) {
      this.permanentStoredRules = new Set(defaultRules);
      return;
    }
    this.permanentStoredRules = new Set(permanentStoredRulesString.payload);
  }

  set(key: string, value: any, inPermanentStore = false, serializer: Serializer | null = stringSerializer) {
    localStorage.setItem(key, serializer?.serialize(value) ?? value);
    if (inPermanentStore) this.addPermanentStoredRules(key);
    return Promise.resolve(this.createSuccess(null));
  }

  get<ELEMENT>(key: string, serializer: Serializer | null = stringSerializer) {
    return new Promise<StorageResponse<ELEMENT | null>>(resolve => {
      const storedData = localStorage.getItem(key);
      if (!storedData) return resolve(this.createSuccess(null));
      try {
        resolve(this.createSuccess((serializer?.deserialize(storedData) || storedData) as ELEMENT));
      } catch (err) {
        if (err instanceof Error) {
          resolve(this.createError(err.message));
          return;
        }
        resolve(this.createError('Unknown error'));
      }
    });
  }

  remove(key: string) {
    localStorage.removeItem(key);
    return Promise.resolve(this.createSuccess(null));
  }

  addPermanentStoredRules(key: string) {
    this.permanentStoredRules.add(key);
    this.savePermanentStoredRules();
  }

  savePermanentStoredRules() {
    localStorage.setItem(
      this.permanentStoredRulesKey,
      objectSerializer.serialize(Array.from(this.permanentStoredRules.values()))
    );
  }

  clearStorage() {
    const rules = Array.from(this.permanentStoredRules);
    const keysToSave = Object.keys(localStorage).filter(key =>
      rules.find(rule => {
        if (typeof rule === 'object') {
          switch (rule.rule) {
            case 'includes':
              if (key.includes(rule.value)) return true;
              break;
            default:
              break;
          }
        }
        return rule === key;
      })
    );
    const valuesByKeys = keysToSave
      .map(key => ({
        key,
        value: localStorage.getItem(key) || '',
      }))
      .filter(el => el.value);

    localStorage.clear();

    this.savePermanentStoredRules();
    valuesByKeys.forEach(el => localStorage.setItem(el.key, el.value));
  }
}
