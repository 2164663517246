import { BaseFormatter, FormatResult, isSyntaxError } from './BaseFormatter';

export class JsFormatter implements BaseFormatter {
  async format(code: string): Promise<FormatResult> {
    const prettier = await import(
      /* webpackChunkName: "prettier" */
      /* webpackMode: "lazy-once" */
      'prettier'
    );
    const prettierBabelParser = await import(
      /* webpackChunkName: "prettier-parser-babel" */
      /* webpackMode: "lazy-once" */
      'prettier/parser-babel'
    );

    try {
      return [
        await prettier.format(code, {
          parser: 'babel',
          // @ts-ignore
          plugins: [prettierBabelParser],
        }),
        null,
      ];
    } catch (e) {
      if (isSyntaxError(e)) return [null, e];
    }
    return ['', null];
  }
}
