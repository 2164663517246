import { CustomRxTooltip, Icon } from '@just-ai/just-ui';
import React, { FC, ReactElement } from 'react';
import { CommonFieldProps } from './types';
import classes from '../../RSAddingMenu/RSAddingMenu.module.scss';
import cn from 'classnames';

interface CommonLabelProps extends Pick<CommonFieldProps, 'name' | 'label' | 'isRequired' | 'description'> {
  labelClassName?: string;
  RightSideElement?: ReactElement;
}

export const CommonLabel: FC<CommonLabelProps> = ({
  name,
  label,
  isRequired,
  description,
  labelClassName,
  RightSideElement,
}) => {
  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        <span className={cn(`${name}_span d-flex align-items-center`, { 'cursor-pointer': description })}>
          {label || name} {isRequired ? <sup style={{ color: 'red' }}>*</sup> : null}{' '}
          {description && <Icon name='farInfoCircle' color='primary' wrapperClassName='ml-2 d-inline-flex' />}
        </span>
        {RightSideElement}
      </label>
      {description && (
        <CustomRxTooltip
          target={`.${name}_span`}
          innerClassName={classes.tooltipCustomWidth}
          placement='left'
          textAlign='left'
        >
          {description}
        </CustomRxTooltip>
      )}
    </>
  );
};
