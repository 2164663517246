import { createAsyncThunk } from '@reduxjs/toolkit';
import { JGraphStickerPosition } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { StickerInfo } from 'modules/JGraph/view/Sticker/types';

import { loadStickers } from '../index';
import { jGraphStickersApi } from '../utils';

export const moveStickers = createAsyncThunk(
  'JGraph/moveStickers',
  async (stickers: { stickerId: string; position: JGraphStickerPosition }[], thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const updatedStickers = stickers
      .map(sticker => {
        const existedSticker = state.JGraphReducer.stickers.find(el => el.id === sticker.stickerId);
        if (!existedSticker) return null;
        return { ...existedSticker, position: sticker.position };
      })
      .filter(Boolean) as StickerInfo[];

    mainSave$.next({
      type: 'upsertSticker',
      path: '',
      action: async () => {
        const { data: createResponse } = await jGraphStickersApi.batchUpsertJGraphSticker(
          userAccountId || accountId,
          projectShortName,
          {
            version: state.JGraphReducer.stickersVersion.version,
            lastModified: state.JGraphReducer.stickersVersion.lastModified,
            stickers: updatedStickers,
          }
        );
        thunkAPI.dispatch(loadStickers(createResponse));
      },
    });
  }
);

export default moveStickers;
