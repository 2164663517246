import React, { Component } from 'react';
import localize from '../../../localization';

export class StatusIcon extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    if (!!this.props.statusIconClassList && !!this.props.status) {
      const status = this.props.status.toLowerCase();
      const className = !!this.props.statusIconClassList[status]
        ? this.props.statusIconClassList[status] + ' system-status-icon font-2xl '
        : 'system-status-icon font-2xl ';
      return (
        <span>
          <i
            id={this.props.id}
            data-test-id={'channelStatus_' + status}
            className={!!this.props.iconClass ? className + this.props.iconClass : className}
          />

          {this.props.status !== 'outdated' ? (
            <span className='pl-2'>{localize.translate(`Channel status ${this.props.status}`)}</span>
          ) : null}
        </span>
      );
    } else {
      return null;
    }
  }
}
