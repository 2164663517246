import React, { useEffect, useState } from 'react';
import { Tooltip, CustomCollapse } from '@just-ai/just-ui';
import { CailaIntentsHelperLocalization } from './localization/CailaIntentsHelper.loc';
import localize, { t } from '../../../../../../../../localization';
import classes from './CailaIntentsHelper.module.scss';

localize.addTranslations(CailaIntentsHelperLocalization);

export const CailaIntentsHelper = () => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <CustomCollapse
      title={t('CailaIntentsHelper:Title')}
      headerClassName={classes.header}
      collapseClassName={classes.collapseInner}
      open={true}
    >
      <div
        className={classes.collapseInnerBox}
        dangerouslySetInnerHTML={{ __html: t('CailaIntentsHelper:CollapseList') }}
      />
      {rendered && (
        <Tooltip
          innerClassName={classes.customWidth}
          placement='bottom'
          target='collapseInnerBoxIntentTooltip'
          textAlign='left'
        >
          <div dangerouslySetInnerHTML={{ __html: t('CailaIntentsHelper:TooltipText') }} />
        </Tooltip>
      )}
    </CustomCollapse>
  );
};
