import React, { useCallback } from 'react';
import { Icon, Button, useToggle } from '@just-ai/just-ui';

import { useAppDispatch } from 'storeHooks';
import { t } from 'localization';

import { addNewNoMatchState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import WarningBanner from 'modules/JGraph/view/WarningBanner';
import { useAppContext } from 'modules/Caila/components/AppContext';

import CreateNoMatchStateModal from './CreateNoMatchStateModal';
import styles from './styles.module.scss';

interface EmptyWarningBannerInterface {
  title: string;
  fixable: boolean;
}
const EmptyNoMatchBanner = function ({ title, fixable }: EmptyWarningBannerInterface) {
  const dispatch = useAppDispatch();
  const { currentProject } = useAppContext();
  const [isModalOpen, , closeModal, toggleModal] = useToggle(false);

  const onCreateInner = useCallback(
    (title: string) => {
      return dispatch(addNewNoMatchState({ screenPath: title, currentProject }));
    },
    [currentProject, dispatch]
  );

  return (
    <>
      <WarningBanner className={styles.EmptyWarningBanner} data-test-id='JGraphBanner:EmptyNoMatch'>
        <Icon name='faExclamationTriangle' />
        <span>{title}</span>
        {fixable && (
          <Button
            color='secondary'
            onClick={toggleModal}
            className={styles.button}
            data-test-id='JGraphBanner:FixButton'
          >
            {t('EmptyMode:WarningFixButton')}
          </Button>
        )}
      </WarningBanner>
      <CreateNoMatchStateModal isModalOpen={isModalOpen} onCreate={onCreateInner} onCancel={closeModal} />
    </>
  );
};

export default React.memo(EmptyNoMatchBanner);
