import { JGraphApi, JGraphVisualsApi, } from "../../generated/Editorbe";
class JGraphService {
    constructor(accountId, projectId, axios) {
        this.renameIntent = (renameIntentData) => {
            return this.jGraphApi.renameIntent(this.accountId, this.projectShortName, renameIntentData);
        };
        this.getJGraphVisuals = (options) => {
            return this.jGraphVisualsApi.getJGraphVisuals(this.accountId, this.projectShortName, options);
        };
        this.updateJGraphVisuals = (jGraphVisuals, force, options) => {
            return this.jGraphVisualsApi.updateJGraphVisuals(this.accountId, this.projectShortName, jGraphVisuals, force, options);
        };
        this.accountId = accountId;
        this.projectShortName = projectId;
        this.jGraphApi = new JGraphApi({}, JGraphService.BASE_PATH, axios);
        this.jGraphVisualsApi = new JGraphVisualsApi({}, JGraphService.BASE_PATH, axios);
    }
}
JGraphService.BASE_PATH = "";
export default JGraphService;
