import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback } from 'react';
import { Tab } from '@just-ai/just-ui/dist/Tabs';
import { ObservedScrollElement } from '@just-ai/just-ui/dist/ProviderObserverScroll';
import FAQTabContextMenu from '../FAQTabContextMenu';
import cn from 'classnames';
import { useNLUModuleContext } from '../../../../context';
export default function TabWithContextMenu(props) {
    const { tabItem, contextKey, index, collapseLimit, activeTab, tabSelectProp, onClose, renameTab, onTabDisable } = props;
    const { isAccessFunction } = useNLUModuleContext();
    const [contextMenu, setContextMenu] = useState();
    const closeMenu = useCallback(() => {
        setContextMenu(undefined);
    }, []);
    const handleTabContextMenu = e => {
        e.preventDefault();
        if (tabItem.withoutContextMenu)
            return;
        setContextMenu({ position: { x: e.clientX, y: e.clientY } });
    };
    return (_jsxs(React.Fragment, { children: [_jsx(ObservedScrollElement, { contextKey: contextKey, innerValue: tabItem, children: _jsx(Tab, { className: cn('justui_tabs-tab-container', {
                        hidden: index >= collapseLimit,
                        disabled: tabItem.skillDisabled,
                    }), item: tabItem, keyId: tabItem.value, isActive: activeTab === tabItem.value, onClick: tabSelectProp(tabItem.value), onClose: () => onClose && onClose(tabItem.value), onContextMenu: handleTabContextMenu, tooltip: 'onOverflow' }) }, tabItem.value), contextMenu && (_jsx(FAQTabContextMenu, { toggle: closeMenu, onDelete: onClose, onRename: renameTab, position: contextMenu.position, tabValue: tabItem.value, isDisabled: tabItem.skillDisabled, onDisable: onTabDisable, writeDisabled: !isAccessFunction('BOT_CONTENT_WRITE') }))] }, tabItem.value));
}
