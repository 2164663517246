import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DefaultList } from './components';
const branchOrLeaf = ({ Leaf, Head, Edit, InnerContainer }, { root, dataset, expanded, selected, depth, itemHeight, editId, onDragndrop, dragndrop }) => {
    const node = dataset[root];
    if (!node)
        return null;
    return node.children ? (_jsx(Branch, { Leaf,
        Head,
        Edit,
        InnerContainer,
        dataset,
        root,
        depth,
        expanded,
        selected,
        itemHeight,
        editId,
        dragndrop,
        onDragndrop }, node.nodeId)) : editId && editId === node.nodeId ? (_jsx(Edit, { node: node, depth: depth, isSelected: selected[node.nodeId], itemHeight: itemHeight }, node.nodeId)) : (_jsx(Leaf, { node: node, depth: depth, isSelected: selected[node.nodeId], itemHeight: itemHeight }, node.nodeId));
};
export const Branch = props => {
    const { style, root, depth, itemHeight, dataset, expanded, selected, Leaf, Head, Edit, InnerContainer, editId } = props;
    const node = dataset[root] || {};
    const children = node.children || [];
    const mapNode = (i, incrementDepth) => branchOrLeaf({ Leaf, Head, Edit, InnerContainer }, { dataset, selected, expanded, root: i, depth: incrementDepth ? depth + 1 : depth, itemHeight, editId });
    switch (true) {
        case !!(children.length && node.name): {
            return (_jsxs(InnerContainer, { style: style, node: node, children: [editId && node.nodeId === editId ? (_jsx(Edit, { node: node, depth: depth, itemHeight: itemHeight, isExpanded: expanded[node.nodeId], isSelected: selected[node.nodeId] })) : (_jsx(Head, { node: node, depth: depth, itemHeight: itemHeight, isExpanded: expanded[node.nodeId], isSelected: selected[node.nodeId] })), children && expanded[node.nodeId] ? (_jsx(DefaultList, { children: children.map(nodeId => mapNode(nodeId, true)) })) : null] }));
        }
        case !!children.length: {
            return (_jsx(InnerContainer, { style: style, node: node, children: _jsx(DefaultList, { children: children.map(nodeId => mapNode(nodeId)) }) }));
        }
        case !!node.name: {
            return (_jsx(InnerContainer, { style: style, node: node, children: editId && node.nodeId === editId ? (_jsx(Edit, { node: node, depth: depth, itemHeight: itemHeight, isSelected: selected[node.nodeId] })) : (_jsx(Leaf, { node: node, depth: depth, itemHeight: itemHeight, isSelected: selected[node.nodeId] })) }));
        }
        default: {
            return null;
        }
    }
};
