export const REACTIONS = [
  'a:',
  'audio:',
  'video:',
  'image:',
  'script:',
  'buttons:',
  'inlineButtons:',
  'intent:',
  'intent!:',
  'q:',
  'q!:',
  'intentGroup:',
  'intentGroup!:',
  'event:',
  'event!:',
  'random:',
];

export const CONTEXTS = ['require:', 'theme:', 'state:', 'bind', 'init:', 'patterns:', ...REACTIONS];

export const PATH_CONTEXTS = [
  // Path completion is not needed after `theme:` and `state:`
  'go:',
  'go!:',
  'intent:',
  'intent!:',
  'intentGroup:',
  'intentGroup!:',
];
