import {
  ACTIVATE_PLATFORM_FULFILLED,
  ACTIVATE_PLATFORM_PENDING,
  ACTIVATE_PLATFORM_REJECTED,
  GET_CURRENT_TARIFF_FULFILLED,
  GET_CURRENT_TARIFF_PENDING,
  GET_CURRENT_TARIFF_REJECTED,
  GET_KEY_WITH_LIMITS_FULFILLED,
  GET_KEYS_FULFILLED,
  GET_KEYS_PENDING,
  GET_KEYS_REJECTED,
} from '../../constants/keysAndPlans.actions';

const InitialState = {
  tariffData: {
    tariffName: '',
    activatedAt: '',
    expiringAt: '',
    sessionsLimit: 0,
    usedSessions: 0,
    uniqueUsersLimit: 0,
    usedUniqueUsers: 0,
    limitsExpiringAt: '',
    tariffUnlimited: false,
    licensePeriodInMonths: 12,
  },
  keysData: [],
  fetching: false,
  fetchActivate: false,
};

export default function KeysAndPlansReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_CURRENT_TARIFF_PENDING:
    case GET_KEYS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_KEYS_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_KEYS_FULFILLED: {
      return {
        ...state,
        keysData: action.payload.data,
        fetching: false,
      };
    }

    case GET_CURRENT_TARIFF_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_CURRENT_TARIFF_FULFILLED: {
      return {
        ...state,
        tariffData: action.payload.data,
        fetching: false,
      };
    }

    case ACTIVATE_PLATFORM_PENDING: {
      return {
        ...state,
        fetchActivate: true,
      };
    }

    case ACTIVATE_PLATFORM_REJECTED: {
      return {
        ...state,
        fetchActivate: false,
      };
    }

    case ACTIVATE_PLATFORM_FULFILLED: {
      return {
        ...state,
        fetchActivate: false,
        error: null,
      };
    }

    case GET_KEY_WITH_LIMITS_FULFILLED: {
      return {
        ...state,
        keyData: action.payload.data.keyData,
        usedLimitsData: action.payload.data.usedLimitsData,
        usedMinuteLimitsData: action.payload.data.usedMinuteLimitsData,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
