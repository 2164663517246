import React from 'react';

import { HeaderNav } from './HeaderNav';
import { DropDownMenu } from './DropDownMenu';
import { useRightSideMenuContext } from '.';

import { ShowBlockGuide } from './ShowBlockGuide';

export const Header = () => {
  const { getTitle, isNeedToShowDotsMenu } = useRightSideMenuContext();

  return (
    <div className='JGraph-RightSideMenu-header'>
      <div className='JGraph-RightSideMenu-header-title'>
        <HeaderNav>
          <h3 data-test-id='RightSideMenu:header:title'>{getTitle()}</h3>
        </HeaderNav>
        {isNeedToShowDotsMenu && <DropDownMenu />}
        <ShowBlockGuide />
      </div>
    </div>
  );
};
