import React, { FC, useCallback } from 'react';
import { TagNames } from '../../../../utils/types';
import { JustSelect } from '@just-ai/just-ui/dist';
import { useAppDispatch } from '../../../../../../storeHooks';
import { useMainConditionContext, useRenderConditionContext } from './index';
import { useRightSideMenuContext } from '../../index';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon, InputText } from '@just-ai/just-ui';
import { addOrRemoveElse, setEditMenuBlock } from '../../../../../../reducers/JGraph.reducer';
import { t } from 'localization';
import classes from '../ScreenBlocksList/ReactionItem.module.scss';
import { ReactionItemProps } from '../ScreenBlocksList/ReactionItem';

export const TagNameChange: FC<{
  tagName: TagNames;
  blockIndex: number;
  tagValue?: string;
  setOpenDelete: ReactionItemProps['setOpenDelete'];
}> = ({ tagName, tagValue = '', blockIndex, setOpenDelete }) => {
  const dispatch = useAppDispatch();
  const { mainIfIndex } = useMainConditionContext();
  const { prefix } = useRenderConditionContext();
  const { onChangeCommonTag, editMenuScreen, save } = useRightSideMenuContext();

  const onIfFocus = useCallback(() => {
    if (editMenuScreen) {
      dispatch(setEditMenuBlock({ jBlockIndex: undefined, path: prefix || `${mainIfIndex}_if_` }));
    }
  }, [dispatch, editMenuScreen, mainIfIndex, prefix]);

  const onChangeIfConditionType = useCallback(
    async (value?: (string | number)[] | null) => {
      if (value && value[0]) {
        await save();
        dispatch(
          addOrRemoveElse({ path: prefix || `${mainIfIndex}_if_`, blockIndex: blockIndex, tag: value[0] as TagNames })
        );
      }
    },
    [blockIndex, dispatch, mainIfIndex, prefix, save]
  );

  const onChangeIfCondition = useCallback(
    (value: string) => {
      onChangeCommonTag({ tagValue: value });
    },
    [onChangeCommonTag]
  );

  const setOpenDeleteHandler = useCallback(() => {
    if (setOpenDelete) {
      setOpenDelete(blockIndex);
    }
  }, [blockIndex, setOpenDelete]);

  return (
    <div
      className='d-flex gap-8 align-items-center'
      data-test-id={`ConditionSettings:TagNameChange:condition_container:${tagName}`}
    >
      {[TagNames.else, TagNames.elseif].includes(tagName) ? (
        <div
          style={{ width: 91, minWidth: 91 }}
          data-test-id={`ConditionSettings:TagNameChange:condition_select_container:${tagName}`}
        >
          <JustSelect
            data-test-id={`ConditionSettings:TagNameChange:else_elseif_selector_${blockIndex}`}
            position='fixed'
            onOpen={onIfFocus}
            onChange={onChangeIfConditionType}
            fullWidth
            value={tagName}
            options={[
              { value: TagNames.else, label: TagNames.else },
              { value: TagNames.elseif, label: TagNames.elseif },
            ]}
          />
        </div>
      ) : (
        <b className='h3 mb-0' style={{ width: 17 }}>
          {tagName}
          {'  '}
        </b>
      )}

      {[TagNames.if, TagNames.elseif].includes(tagName) && (
        <InputText
          data-test-id={`ConditionSettings:TagNameChange:conditionInput_${blockIndex}`}
          defaultValue={tagValue}
          onChange={onChangeIfCondition}
          onFocus={onIfFocus}
          placeholder={t('TagNameChange:condition_placeholder')}
        />
      )}
      {TagNames.if === tagName && setOpenDelete && (
        <DropdownButton
          className={classes.noBorder}
          direction='down'
          flat
          color='none'
          onClick={e => e.stopPropagation()}
        >
          <DropdownToggle
            outline
            iconButton
            data-test-id={`ConditionSettings:TagNameChange:dropdownToggle_${blockIndex}`}
          >
            <Icon className='btn-icon' name='farEllipsisH' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              danger
              onClick={setOpenDeleteHandler}
              data-test-id={`ConditionSettings:TagNameChange:dropdownAction_${blockIndex}:Delete`}
            >
              {t('RightSideMenu:blockActions_delete')}
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
      )}
    </div>
  );
};
