import { scan, shareReplay, Subject } from 'rxjs';
import { TConnector } from '../../../reducers/JGraph.reducer/types';
import { TConnectorSubj } from './types';

export const globalConnectorsSubject$ = new Subject<TConnectorSubj>();
export const globalConnectorsStore$ = globalConnectorsSubject$.pipe(
  scan((prevValue, newValue) => {
    let returnValue = [...prevValue];
    switch (newValue.type) {
      case 'add': {
        returnValue.push(newValue.connector);
        break;
      }
      case 'remove': {
        const valueIndex = returnValue.findIndex(connector => connector === newValue.connector);
        if (valueIndex > -1) {
          returnValue.splice(valueIndex, 1);
        }
        break;
      }
    }
    return returnValue;
  }, [] as TConnector[]),
  shareReplay(1)
);

export const getGlobalConnectors = () => {
  let connections: TConnector[] = [];
  globalConnectorsStore$.subscribe(store => (connections = store)).unsubscribe();
  return connections;
};
