var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import memoize from 'memoizerific';
import React, { useCallback } from 'react';
const DefaultJustSelectItem = ({ value, onSelect, onHover, children, focused, active, disabled, }) => {
    const onSelectHandler = useCallback(() => {
        onSelect && onSelect(value);
    }, [onSelect, value]);
    return (_jsx("div", { className: classNames('just-select-item', { focused, active, clickable: onSelect && !disabled, disabled }), onClick: !disabled ? onSelectHandler : undefined, onMouseEnter: !disabled ? onHover : undefined, "data-test-id": `just-select-item__${value}`, role: 'menuitem', children: children }));
};
const createJustSelectItem = (Component, _a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const SelectItem = React.memo((_a) => {
        var { value, onSelect, active, focused, onHover, label, disabled } = _a, restProps = __rest(_a, ["value", "onSelect", "active", "focused", "onHover", "label", "disabled"]);
        return (_jsx(DefaultJustSelectItem, { onSelect: onSelect, value: value, active: active, focused: focused, onHover: onHover, disabled: disabled, children: _jsx(Component, Object.assign({}, rest, restProps, { label: label, value: value })) }));
    });
    SelectItem.displayName = `JustSelectChild_${Component.displayName || 'DefaultJustSelectItem'}`;
    return SelectItem;
};
export const DefaultListItem = (_a) => {
    var { label, LabelComponent, blockHtml } = _a, rest = __rest(_a, ["label", "LabelComponent", "blockHtml"]);
    if (LabelComponent) {
        return _jsx(LabelComponent, Object.assign({ label: label }, rest));
    }
    if (typeof label !== 'string')
        return label;
    if (blockHtml)
        return _jsx("span", { children: label });
    return _jsx("span", { dangerouslySetInnerHTML: { __html: label } });
};
export const getSelectListItem = memoize(1)((ListElement = DefaultListItem, onSelect = () => { }, props = {}) => {
    return createJustSelectItem(ListElement, props);
});
