import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Group, Circle } from 'react-konva';
import { IconNames, KIcon } from '../parts/KIcons';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import Konva from 'konva';
import { Subject } from 'rxjs';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { ConnectorStore } from '../../contexts/types';
import { getStageContextFromRef, scrollToTargetGlobal$ } from '../../utils/stageUtils';
import { getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';

import AccessibleText from 'modules/JGraph/components/AccessibleText';

type OutgoingItemProps = {
  path: string;
  width: number;
  displayPath?: string;
  connectorOffsetX?: number;
};

export const OutgoingItem: FC<OutgoingItemProps> = ({ path, displayPath, width, connectorOffsetX = 0 }) => {
  const StateRef = useRef<Konva.Circle | null>(null);
  const goToState = useCallback(
    event => {
      event.cancelBubble = true;
      scrollToTargetGlobal$.next({
        targetPathId: getValidKonvaName(path),
      });
    },
    [path]
  );

  useEffect(() => {
    let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;
    if (StateRef.current && path) {
      const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
      connectorsFromPipeSave$ = connectorsFromPipe$;
      connectorsFromPipe$.next({
        [getValidKonvaName(path)]: {
          toRef: StateRef.current,
        },
      });
    }
    return () => {
      if (connectorsFromPipeSave$) {
        connectorsFromPipeSave$.next({
          [getValidKonvaName(path)]: {
            toRef: undefined,
          },
        });
      }
    };
  }, [path]);

  return (
    <AutopositionGroup offsetY={0} padding={0} name={getValidKonvaName(path)} isScreen={true} type='OutgoingItem'>
      <Circle x={connectorOffsetX} ref={StateRef} height={1} width={0} fill='' opacity={0} listening={false} />
      <Group>
        <AccessibleText
          y={0}
          x={0}
          text={hideRootSlashInPath(displayPath || path)}
          fill='#0D1822'
          fontSize={12}
          lineHeight={18 / 12}
          letterSpacing={0.01}
          width={width - 38 - 8}
          wrap='none'
          ellipsis
        />
        <KIcon width={16} height={16} x={width - 42} y={0} icon={IconNames.stateLocation} onClick={goToState} />
      </Group>
    </AutopositionGroup>
  );
};
