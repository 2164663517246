import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Icon, Tooltip, usePrevious, useTranslation } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import usePressKey from '@just-ai/just-ui/dist/utils/usePressKey';
import insetHTMLInRawText from '@just-ai/just-ui/dist/utils/insetHTMLInRawText';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toggleIntentItemType } from '../utils';
import styles from './styles.module.scss';
import './styles.scss';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';
const PhraseRow = function ({ item, onChange, onDelete, onSubmit, index, writeDisabled }, ref) {
    const iconRef = useRef(null);
    const { t } = useTranslation();
    const onPress = usePressKey({
        key: 'Enter',
    }, () => onSubmit && onSubmit(item));
    const prevError = usePrevious(item.error);
    useEffect(() => {
        if (!prevError && item.error) {
            const elemToFocus = document.getElementById(`PhraseRow-${item.id}`);
            if (elemToFocus)
                elemToFocus.focus();
        }
    }, [prevError, item.error, item.id]);
    const richValue = useMemo(() => {
        var _a;
        return insetHTMLInRawText(item.text, ((_a = item.searchMatches) !== null && _a !== void 0 ? _a : []).map(match => ({
            start: match.from,
            end: match.to,
            renderHtml: text => `<span class="searchMark">${text}</span>`,
        })));
    }, [item]);
    return (_jsxs("div", { "data-test-id": `AlternativeQuestion.Row.id-${index}`, ref: ref, className: cn(styles.phraseRow, 'edit-phrase-block', 'phraseRow'), children: [_jsxs("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--prepend input-group-prepend'), children: [_jsx(IconButton, { id: `changePhraseType-${item.id}`, "data-test-id": 'changePhraseType', tabIndex: 0, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), name: item.type === IntentItemType.phrase ? 'farText' : 'faAt', disabled: writeDisabled, onClick: () => {
                            onChange(toggleIntentItemType(item));
                        } }), _jsx(Tooltip, { target: `changePhraseType-${item.id}`, className: styles.phraseRow__tooltip, placement: 'top', boundariesElement: 'window', "data-test-id": 'changePhraseType.tooltip', children: item.type === IntentItemType.phrase ? t('FAQ:Phrases:ChangeTypeText') : t('FAQ:Phrases:ChangeTypePhrase') })] }), _jsx("div", { className: cn(styles.phraseRow__part, { [styles['phraseRow__part--error']]: !!item.error }), children: _jsx(TextAreaLikeElement, { id: `PhraseRow-${item.id}`, dataTestId: 'PhraseRow.input', value: item.text, richValue: richValue, onKeyDown: onPress, placeholder: item.type === IntentItemType.phrase ? t('FAQ:Phrase:Placeholder') : t('FAQ:Text:Placeholder'), readOnly: writeDisabled, onChange: text => onChange(Object.assign(Object.assign({}, item), { text })), append: item.error && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.phraseRow__errorWrapper, ref: iconRef, children: _jsx(Icon, { name: 'faExclamationCircle', color: 'danger' }) }), _jsx(Tooltip, { target: iconRef, placement: 'top', children: t('PhraseBlocks:validationTooltip') })] })) }) }), _jsx("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--append input-group-append'), children: _jsx(IconButton, { tabIndex: 0, "data-test-id": 'deletePhrase', name: 'farTrashAlt', onClick: () => onDelete && onDelete(item), disabled: writeDisabled, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray') }) })] }));
};
export default React.memo(React.forwardRef(PhraseRow));
