import {
  CLEAR_CURRENT_PROJECT,
  CLEAR_EDITABLE_PROJECT,
  CREATE_PROJECT_FULFILLED,
  FETCH_PROJECTS_FULFILLED,
  FETCH_PROJECTS_PENDING,
  FETCH_PROJECTS_REJECTED,
  REMOVE_EDITABLE_PROJECT_FULFILLED,
  SAVE_EDITABLE_PROJECT_FULFILLED,
  SET_EDITABLE_PROJECT,
  UPLOAD_PROJECT_ZIP_FULFILLED,
  UPLOAD_PROJECT_ZIP_PENDING,
  UPLOAD_PROJECT_ZIP_REJECTED,
  SET_BOT_TO_DELETE,
  FINAL_DELETE,
  UPDATE_CHANNELS,
  UPDATE_PROJECT_TAGS,
  DELETE_ACCOUNT_TAG,
} from '../../constants/projects.actions';
import { SAVE_REPORT_PARAMETERS_FULFILLED } from '../../constants/projectReportFilter.actions';
import { LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

const InitialState = {
  projectList: [],
  selectedProjectId: null,
  fetching: false,
  fetched: false,
  fetchedProjectList: false,
  error: null,
};

export default function ProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case CLEAR_CURRENT_PROJECT: {
      return {
        ...state,
      };
    }
    case FETCH_PROJECTS_PENDING:
      return {
        ...state,
        fetchedProjectList: false,
        fetching: true,
      };

    case FETCH_PROJECTS_FULFILLED:
      return {
        ...state,
        projectList: action.payload.data,
        fetchedProjectList: true,
        fetching: false,
      };

    case FETCH_PROJECTS_REJECTED:
      let fetchedProjectList = action.payload.response?.status !== 401;
      return {
        ...state,
        fetching: false,
        fetchedProjectList: fetchedProjectList,
        error: action.payload.data,
      };

    case SET_EDITABLE_PROJECT:
      return {
        ...state,
        selectedProjectId: action.project.id,
      };

    case CLEAR_EDITABLE_PROJECT:
      return {
        ...state,
        selectedProjectId: null,
      };
    case UPDATE_CHANNELS:
      const data = action.data;
      if (!data.projectShortName) return { ...state };

      const newProjectsList = state.projectList.map(project => {
        if (project.shortName === data.projectShortName) project.botConfigs = [...data.channels];
        return { ...project };
      });

      return {
        ...state,
        projectList: newProjectsList,
      };
    case CREATE_PROJECT_FULFILLED:
      return {
        ...state,
        projectList: [...state.projectList, { ...action.payload.data, botConfigs: [] }],
        selectedProjectId: action.payload.data.id,
        fetched: true,
        fetching: false,
      };
    case SAVE_EDITABLE_PROJECT_FULFILLED:
      return {
        ...state,
        projectList: state.projectList.map(project => {
          return project.id === action.payload.data.id ? action.payload.data : project;
        }),
      };
    case REMOVE_EDITABLE_PROJECT_FULFILLED:
      let newProjectList = [...state.projectList];
      newProjectList = newProjectList.filter(project => {
        return project.id !== action.payload.data.id;
      });
      return {
        ...state,
        projectList: newProjectList,
        selectedProjectId: null,
      };

    case SAVE_REPORT_PARAMETERS_FULFILLED: {
      const newProjectList = [...state.projectList];

      const changedProjectIndex = newProjectList.findIndex(project => {
        return project.id === action.payload.data.id;
      });

      newProjectList[changedProjectIndex] = {
        ...newProjectList[changedProjectIndex],
        reportParameters: action.payload.data.reportParameters,
      };

      return {
        ...state,
        projectList: newProjectList,
      };
    }

    case UPLOAD_PROJECT_ZIP_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case UPLOAD_PROJECT_ZIP_REJECTED:
    case UPLOAD_PROJECT_ZIP_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case LOGOUT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      return {
        ...InitialState,
      };
    }
    case SET_BOT_TO_DELETE: {
      let botItem = state.projectList.find(item => item.id === action.id);
      botItem.pending = true;
      botItem.deleting = true;
      const newBotList = [...state.projectList];
      return {
        ...state,
        projectList: newBotList,
      };
    }

    case FINAL_DELETE: {
      let deletingBotIndex = state.projectList.findIndex(item => item.id === action.id);
      const newBotList = [...state.projectList];
      newBotList.splice(deletingBotIndex, 1);
      return {
        ...state,
        projectList: newBotList,
      };
    }
    case UPDATE_PROJECT_TAGS: {
      const newProjectList = [...state.projectList];
      const foundBotIndex = newProjectList.findIndex(botItem => botItem.shortName === action.payload.projectShortName);
      if (foundBotIndex > -1) {
        let newBot = {
          ...newProjectList[foundBotIndex],
          tagLabels: action.payload.tagsList,
        };
        newProjectList.splice(foundBotIndex, 1, newBot);
      }
      return {
        ...state,
        projectList: newProjectList,
      };
    }
    case DELETE_ACCOUNT_TAG: {
      const newProjectList = [...state.projectList].reduce((currentProjectList, currentBotItem) => {
        const newBotItem = { ...currentBotItem };
        newBotItem.tagLabels = newBotItem.tagLabels.filter(tagLabel => tagLabel.id !== action.payload.tagId);
        currentProjectList.push(newBotItem);
        return currentProjectList;
      }, []);

      return {
        ...state,
        projectList: newProjectList,
      };
    }

    default:
      return state;
  }
}
