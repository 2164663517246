import React, { useState, useRef, useCallback } from 'react';
import { Button, IconButton, useToggle } from '@just-ai/just-ui';

import { useAppDispatch } from 'storeHooks';
import ClickAwayListener from 'components/ClickAwayListener';
import addNewStartState from 'reducers/JGraph.reducer/AsyncActions/addNewStartState';

import emptyLogo from './emptyLogo.svg';
import StartStateCreationMenu from './StartStateCreationMenu';
import styles from './styles.module.scss';
import { t } from 'localization';

interface EmptyJgraphViewProps {
  fixable: boolean;
}
const EmptyJgraphView = function ({ fixable }: EmptyJgraphViewProps) {
  const dispatch = useAppDispatch();
  const cornerButtonRef = useRef<HTMLButtonElement>();
  const [isOpenedCornerPopup, , closeCornerPopup, toggleCornerPopup] = useToggle(false);
  const [isOpenedPopup, setOpenedPopup] = useState(false);

  const onCreate = useCallback((title: string) => dispatch(addNewStartState({ screenPath: title })), [dispatch]);

  return (
    <div className={styles.EmptyJgraphView}>
      <div className={styles.EmptyJgraphView__cornerButton}>
        {fixable && (
          <>
            <IconButton
              innerRef={cornerButtonRef as React.Ref<HTMLButtonElement>}
              name='farPlus'
              color='primary'
              onClick={toggleCornerPopup}
            />
            {isOpenedCornerPopup && (
              <ClickAwayListener handleClickOut={closeCornerPopup} refs={[cornerButtonRef.current]}>
                <StartStateCreationMenu onCreate={onCreate} />
              </ClickAwayListener>
            )}
          </>
        )}
      </div>

      {!isOpenedPopup ? (
        <div className={styles.EmptyJgraphView__content}>
          <img src={emptyLogo} alt='emptyLogo' />
          <h2>{t('EmptyMode:CreationPage:Title')}</h2>
          <Button color='primary' onClick={() => setOpenedPopup(true)} disabled={!fixable}>
            {t('EmptyMode:CreationPage:ActionButton')}
          </Button>
        </div>
      ) : (
        <ClickAwayListener handleClickOut={() => setOpenedPopup(false)}>
          <StartStateCreationMenu onCreate={onCreate} />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default React.memo(EmptyJgraphView);
