import { useToggle } from '../../modules/Caila/utils';
import { Popover, PopoverProps } from '@just-ai/just-ui';
import React from 'react';

interface PopohoverProps extends PopoverProps {
  closable?: boolean;
}

export function Popohover(props: PopohoverProps) {
  const { target, children, className, closable = true, ...restProps } = props;
  const [isOpen, , setClose, toggle] = useToggle(false);
  return (
    <Popover
      isOpen={isOpen}
      toggle={toggle}
      target={target}
      className={`popohover ${className}`}
      trigger='hover'
      {...restProps}
      onCancelClick={closable ? setClose : undefined}
    >
      <div>{children}</div>
    </Popover>
  );
}
