var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CustomInput } from 'reactstrap';
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider';
import './Range.scss';
import cn from 'classnames';
export function Range(_a) {
    var _b, _c;
    var { id, type, name, visibleStep, min, max, onChange = () => { }, visibleStepLabel, containerClassName, rangeClassName } = _a, restProps = __rest(_a, ["id", "type", "name", "visibleStep", "min", "max", "onChange", "visibleStepLabel", "containerClassName", "rangeClassName"]);
    const range = (_jsx(CustomInput, Object.assign({ id: id || name || 'range', type: 'range', name: name, min: min, max: max, onChange: event => onChange(Number(event.target.value), name) }, restProps)));
    if (visibleStep && typeof min === 'number' && max) {
        const fracPartLen = (_c = (_b = visibleStep.toString().split('.')[1]) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : -1;
        const visualSteps = () => {
            const range = [];
            for (let value = min; value <= max - visibleStep / 2; value += visibleStep) {
                let pushedValue = value;
                if (fracPartLen !== -1 && value !== min)
                    pushedValue = parseFloat(value.toFixed(fracPartLen));
                range.push(pushedValue);
            }
            range.push(max);
            return range;
        };
        return (_jsxs("div", { className: cn('justui-range-container', containerClassName), children: [_jsx("div", { className: cn('justui-range-steps', rangeClassName), children: visualSteps().map(value => (_jsxs("div", { children: [_jsx("svg", { width: '2', height: '4', viewBox: '0 0 2 4', fill: 'none', children: _jsx("rect", { x: '0.5', width: '1', height: '4', rx: '0.5', fill: 'var(--gray-800)' }) }), visibleStepLabel ? visibleStepLabel(value) : value] }, value))) }), range] }));
    }
    else {
        return range;
    }
}
export function RangeAlt(props) {
    const { min, max, value, onChange, step, mode = 1, handleIds } = props;
    return (_jsxs(Slider, { mode: mode, step: step, domain: [min, max], className: 'slider-root-styles just-ui__slider', onChange: onChange, values: value, children: [_jsx(Rail, { children: ({ getRailProps }) => (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: 'slider-outer-rail' }, getRailProps())), _jsx("div", { className: 'slider-inner-rail' })] })) }), _jsx(Handles, { children: ({ handles, getHandleProps }) => (_jsx("div", { className: 'slider-handles', children: handles.map((handle, index) => (_jsx(Handle, { disabled: false, handle: handle, domain: [min, max], getHandleProps: getHandleProps, testId: handleIds && handleIds[index] }, handle.id))) })) }), _jsx(Tracks, { left: mode === 2 ? false : undefined, right: false, children: ({ tracks, getTrackProps }) => (_jsx("div", { className: 'slider-tracks', children: tracks.map(({ id, source, target }) => (_jsx(Track, { disabled: false, source: source, target: target, getTrackProps: getTrackProps }, id))) })) })] }));
}
function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps, testId }) {
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: 'slider-handle', "data-test-id": testId, style: {
                    left: `${percent}%`,
                } }, getHandleProps(id))), _jsx("div", { role: 'slider', "aria-valuemin": min, "aria-valuemax": max, "aria-valuenow": value, style: {
                    left: `${percent}%`,
                    backgroundColor: disabled ? '#666' : 'white',
                } })] }));
}
function Track({ source, target, getTrackProps, disabled }) {
    return (_jsx("div", Object.assign({ className: 'slider-track', style: {
            backgroundColor: disabled ? '#999' : '#605FE3',
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`,
        } }, getTrackProps())));
}
export default Range;
