import React from 'react';
import { Button, CustomRxTooltip, useId } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from '../../styles.module.scss';

interface ToggleDetailViewButtonInterface {
  onClick: () => void;
  'data-test-id'?: string;
}
const ToggleDetailViewButton = function ({ onClick, ...rest }: ToggleDetailViewButtonInterface) {
  const id = useId();
  return (
    <div>
      <Button
        data-test-id={rest['data-test-id']}
        className={styles.selectLabelColor}
        onClick={onClick}
        withoutPadding
        size='sm'
        flat
        color='secondary'
        id={id}
      >
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1 6.90902V2.125C1 1.50367 1.50367 1 2.125 1H6.90902C7.20738 1 7.49353 1.11853 7.70451 1.32951L12.6705 6.29549C12.867 6.492 12.9756 6.74312 12.9963 7H9V9H7V12.9963C6.74312 12.9756 6.492 12.867 6.29549 12.6705L1.32951 7.70451C1.11853 7.49353 1 7.20738 1 6.90902ZM3.625 2.5C3.00367 2.5 2.5 3.00367 2.5 3.625C2.5 4.24633 3.00367 4.75 3.625 4.75C4.24633 4.75 4.75 4.24633 4.75 3.625C4.75 3.00367 4.24633 2.5 3.625 2.5ZM11 15V13H9V11H11V9H13V11H15L15 13H13V15H11Z'
            fill='#6A7178'
          />
        </svg>
      </Button>
      <CustomRxTooltip target={`#${id}`} placement='bottom'>
        {t('JGraph:LabellingToolMenu:ToggleButton')}
      </CustomRxTooltip>
    </div>
  );
};

export default React.memo(ToggleDetailViewButton);
