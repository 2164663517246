import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormText, InputText } from '../../index';
import cn from 'classnames';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function LinkTab(props) {
    const { type, localUrl, setLocalUrl, error } = props;
    const { t } = useTranslation();
    return (_jsxs("div", { className: 'ImageSettings-text-input', children: [_jsx(InputText, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.LinkText', value: localUrl, onChange: setLocalUrl }), _jsx(FormText, { className: 'mb-0', children: t('AudioSettings:urlHint') }), type === AnswerReplyType.audio && _jsx(FormText, { className: 'mt-0', children: t(`AnswerModal:subtext_${type}`) }), _jsx("p", { className: cn({ 'links-disabled': props.isWhiteLabel() }), dangerouslySetInnerHTML: {
                    __html: t(`AnswerModal:info_${type}`, t(`AnswerModal:info_link_${type}`)),
                } }), error && (_jsx(FormText, { color: 'danger', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.Error', children: error })), type === AnswerReplyType.image && localUrl && (_jsx("div", { className: 'faq-anwer-modal__img-preview margin-top-3x', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.ImgPreview', children: _jsx("img", { src: localUrl, alt: '' }) }))] }));
}
