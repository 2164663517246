import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import styles from './styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
export default function QuestionsModal(props) {
    const { t } = useTranslation();
    const { isOpen, onSubmit, toggle, name, questions = [], type } = props;
    const isEmptyTemplate = type === 'empty_template';
    return (_jsx(Modal, { className: styles.faqQuestionsModal, isOpen: isOpen, buttonSubmitText: t('FAQ:AddSkill'), onClickCloseIcon: toggle, onCancelClick: toggle, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), onActionClick: onSubmit, title: isEmptyTemplate ? name : t('sampleQuestions', name), formClassName: cn({ [styles['empty-template']]: isEmptyTemplate }), centered: true, children: _jsx("ul", { children: isEmptyTemplate
                ? t('FAQEmptyTemplateDesc')
                : questions === null || questions === void 0 ? void 0 : questions.map((question, index) => _jsx("li", { children: question }, index)) }) }));
}
