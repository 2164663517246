import { LocalStorageService } from 'services/Storage/LocalStorageService';
import { IFeaturesNotification } from './index';

type SkipStatusType = 'skippedOnce' | 'skippedForever' | 'none';
const SKIP_STATUS_KEY = 'GUIDE_TOUR_SKIP_STATUS_KEY';

export class GuideTourStatus implements IFeaturesNotification {
  private storage = new LocalStorageService();

  async isCanBeShowed(): Promise<boolean> {
    const skipStatus = await this.getStatus();
    return !skipStatus || skipStatus !== 'skippedForever';
  }

  public async getStatus() {
    return this.storage
      .get<SkipStatusType>(SKIP_STATUS_KEY)
      .then(status => (status.success && status.payload ? status.payload : 'none'));
  }

  public async skip() {
    await this.storage.set(SKIP_STATUS_KEY, 'skippedOnce', true);
  }

  async skipForever(): Promise<void> {
    await this.storage.set(SKIP_STATUS_KEY, 'skippedForever', true);
  }

  public finish() {
    return this.skipForever();
  }
}
