import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from 'date-fns';
import cn from 'classnames';
import { AudioFieldMemo, IconButton, Icon, validateUrl } from '../index';
import { useTranslation } from '../contexts/transtationContext';
import { AnswerReplyType } from './types';
export default function MediaPartBlock(props) {
    const { t } = useTranslation();
    const { type, value, onPartEdit, fileName, error, uploadDate, audioName, writeDisabled } = props;
    if (!validateUrl(value))
        return null;
    const parsedUrl = new URL(value);
    const fileNameFromUrl = decodeURI(parsedUrl.href).split('/').pop();
    return (_jsxs("div", { className: 'faq-answer-part__media', children: [_jsxs("div", { className: cn(`faq-answer-part__media__content faq-answer-part__media__content--${type}`), children: [type === AnswerReplyType.image && (_jsxs(_Fragment, { children: [_jsx("img", { src: value, alt: '' }), " ", _jsx("p", { children: fileNameFromUrl }), _jsx(IconButton, { name: 'farUndoAlt', size: 'sm', color: 'none', onClick: onPartEdit, "data-test-id": 'FAQPage:Answer:Media:EditBtn', disabled: writeDisabled, className: 'answer-part__media__undo-btn' })] })), type === AnswerReplyType.audio && (_jsx(AudioFieldMemo, { url: value, fileName: fileName || audioName || fileNameFromUrl || 'audio', saveAudioToServer: props.saveAudioToServer, onPartEdit: onPartEdit, disabled: writeDisabled })), type === 'file' && (_jsxs(_Fragment, { children: [_jsx(Icon, { name: error ? 'farTimesCircle' : 'farCheckCircle', color: error ? 'danger' : 'success' }), _jsx("p", { children: fileName || fileNameFromUrl }), _jsx(IconButton, { name: 'farUndoAlt', size: 'sm', color: 'none', onClick: onPartEdit, "data-test-id": 'FAQPage:Answer:Media:EditBtn', disabled: writeDisabled, className: 'answer-part__media__undo-btn' })] }))] }), _jsxs("div", { className: cn(`faq-answer-part__media__url`), children: [_jsx("a", { href: parsedUrl.href, target: '_blank', rel: 'noopener noreferrer', className: 'text-primary', "data-test-id": 'FAQPage:Answer:Media:Link', children: decodeURI(parsedUrl.href) }), _jsx("p", { className: 'text-secondary', children: t('AnswerBlocks:answer_media_date', format(uploadDate || new Date().getDate(), 'dd.MM.yy')) })] })] }));
}
