import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { ButtonGroup } from "reactstrap";
import { Button } from "./Button";
export const InlineRadioButton = ({ name, options = [], size = 'md', vertical = false, role, outline, active, color = 'secondary', onChange = () => { }, }) => {
    const onChangeInner = useCallback((option) => {
        onChange(name, option);
    }, [name]);
    return (_jsx(ButtonGroup, { size: size, vertical: vertical, name: name, role: role, children: options.map((option, index) => _jsx(Button, { color: color, style: { whiteSpace: 'pre' }, outline: outline, active: option.value ? option.value === active : option.name === active, onClick: () => onChangeInner(option), "data-test-id": option.dataTestId, children: option.name }, `option_${index}`)) }));
};
