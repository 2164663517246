import { useCallback, useMemo } from 'react';
import Konva from 'konva';

import { LabelingService } from 'modules/JGraph/view/LabelingToolMenu/LabelingService';

import { LabelingToolMenu$ } from '../index';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { useDebounceFn } from '@just-ai/just-ui';

export function useScreenLabel(screen: JStateWithId, stageNode: Konva.Group | null) {
  const screenLabel = useMemo(() => LabelingService.getLabelByScreen(screen), [screen]);

  const debouncedShowLabelingTool = useDebounceFn((isHovered: boolean) => {
    LabelingToolMenu$.next({ stageNode, screen, isHovered });
  }, 300);

  const onMouseEnter = useCallback(() => debouncedShowLabelingTool(true), [debouncedShowLabelingTool]);
  const onMouseLeave = useCallback(() => debouncedShowLabelingTool(false), [debouncedShowLabelingTool]);

  const onLabelClick = useCallback(() => {
    LabelingToolMenu$.next({
      stageNode: stageNode,
      screen,
      forceShow: true,
    });
  }, [screen, stageNode]);

  return { screenLabel, hoverProps: { onMouseEnter, onMouseLeave }, onLabelClick };
}
