import React, { FC } from 'react';
import { Line, Path } from 'react-konva';

import { STATIC_TRIANGLE } from '../../../utils/connectionLayerUtils';
import { border_active, BUTTON_WIDTH, gray_400 } from './index';

export const IncomingStraightLine: FC<{ textSize: number; debugActive?: boolean }> = React.memo(
  ({ textSize, debugActive }) => {
    return (
      <>
        <Line
          points={[textSize + 6 + BUTTON_WIDTH, 0, textSize + 6 + BUTTON_WIDTH + 60, 0]}
          strokeWidth={2}
          stroke={debugActive ? border_active : gray_400}
        />
        <Path
          x={textSize + 6 + BUTTON_WIDTH + 60}
          stroke={debugActive ? border_active : gray_400}
          y={0}
          data={STATIC_TRIANGLE()}
          strokeWidth={2}
          hitStrokeWidth={10}
        />
      </>
    );
  }
);
