export class ProviderObserverScrollModel {
    constructor() {
        this.elementsMap = new Map();
        this.state = [];
    }
    setCallback(func) {
        this.cb = func;
    }
    connect(root, threshold = 1) {
        if (this.observer) {
            this.disconnect();
        }
        this.observer = new IntersectionObserver(entries => {
            const newChanges = entries
                // .sort((a, b) => b.intersectionRatio - a.intersectionRatio)
                .map(ent => ({
                elValue: this.elementsMap.get(ent.target),
                isIntersecting: ent.isIntersecting,
                isFullyVisible: ent.intersectionRatio === 1,
                node: ent.target,
            }));
            this.state = this.compareWithPreviousResults(newChanges);
            this.cb && this.cb(this.state);
        }, { root, threshold });
        Array.from(this.elementsMap.keys()).forEach(node => this.observer.observe(node));
    }
    disconnect() {
        if (!this.observer)
            return;
        this.observer.disconnect();
    }
    registerElement(node, value) {
        this.elementsMap.set(node, value);
        if (this.observer) {
            this.observer.observe(node);
        }
    }
    unregisterElement(node) {
        this.elementsMap.delete(node);
        if (this.observer) {
            this.observer.unobserve(node);
        }
    }
    compareWithPreviousResults(newChanges) {
        return newChanges
            .reduce((acc, changeEl) => {
            const existElIndex = acc.findIndex(el => el.elValue === changeEl.elValue);
            if (existElIndex !== -1 && !changeEl.isIntersecting) {
                acc.splice(existElIndex, 1);
                return acc;
            }
            if (existElIndex === -1 && changeEl.isIntersecting) {
                acc.push(changeEl);
                return acc;
            }
            return acc;
        }, this.state)
            .filter(el => this.elementsMap.has(el.node));
    }
}
