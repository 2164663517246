const localization = {
    "Nothing's found": {
        en: "Nothing's found",
        ru: 'Ничего не найдено',
    },
    'No elements': {
        en: 'No elements',
        ru: 'Нет элементов',
    },
    removed: {
        en: 'removed',
        ru: 'удаленный',
    },
};
export function translate(text, locale) {
    if (!localization[text])
        return text;
    return localization[text][locale] ? localization[text][locale] : localization[text].en;
}
