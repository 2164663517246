import React from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import Tooltip from '../index';
import styles from '../contentStyles.module.scss';

interface ErrorTooltipProps {
  onClose: () => void;
  isActionsDisabled: boolean;
  onDiscardChanges: () => void;
}
const ErrorTooltip = ({ onClose, isActionsDisabled, onDiscardChanges }: ErrorTooltipProps) => {
  return (
    <Tooltip className={styles.ContentTooltip} onClose={onClose}>
      <span className={styles.ContentTooltip__title}>{t('StatusIndicator:ErrorTooltip:Title')}</span>
      <div className={styles.ContentTooltip__divider} />
      <div className={styles.ContentTooltip__content}>
        <span className={styles.ContentTooltip__description}>{t('StatusIndicator:ErrorTooltip:Description')}</span>
        <div className={styles.ContentTooltip__actions}>
          <Button size='sm' outline flat onClick={onDiscardChanges} color='secondary' disabled={isActionsDisabled}>
            {t('StatusIndicator:DiscardChanges')}
          </Button>
        </div>
      </div>
    </Tooltip>
  );
};

export default React.memo(ErrorTooltip);
