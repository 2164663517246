const clipboardCopyEventKey = 'copyToClipboard';
const clipboardPermissionEventKey = 'clipboardPermissionEvent';

export class ClipboardService {
  static async copyToClipboard(text: string) {
    if (!window.navigator?.clipboard) return;
    if (window.navigator?.permissions?.query) {
      const permissionStatus = await window.navigator.permissions
        .query({
          // @ts-ignore
          name: 'clipboard-read',
          allowWithoutGesture: false,
        })
        .catch(err => {
          console.error('Error in ClipboardService.copyToClipboard: ', err);
        });
      if (permissionStatus && permissionStatus.state !== 'granted') {
        document.dispatchEvent(new CustomEvent(clipboardPermissionEventKey));
        return;
      }
    }
    return window.navigator?.clipboard.writeText(text).then(data => {
      document.dispatchEvent(new CustomEvent(clipboardCopyEventKey));
      try {
        window.navigator.clipboard.readText();
      } catch {}
      return data;
    });
  }

  static readFromClipboard() {
    return new Promise<string>(async resolve => {
      try {
        const clipboardText = await window.navigator.clipboard.readText();
        resolve(clipboardText);
      } catch (e) {
        // Pass empty string if permission deny
        resolve('');
      }
    });
  }

  static onClipboardCopy(cb: (text: string) => void) {
    const innerCb = async () => {
      const text = await ClipboardService.readFromClipboard();
      if (!text) return;
      cb(text);
    };
    document.addEventListener('copy', innerCb as EventListener);
    document.addEventListener('cut', innerCb as EventListener);
    document.addEventListener(clipboardCopyEventKey, innerCb as EventListener);
    return () => {
      document.removeEventListener('copy', innerCb as EventListener);
      document.removeEventListener('cut', innerCb as EventListener);
      document.removeEventListener(clipboardCopyEventKey, innerCb as EventListener);
    };
  }

  static onClipboardPermissionError(cb: () => void) {
    document.addEventListener(clipboardPermissionEventKey, cb as EventListener);
    return () => {
      document.removeEventListener(clipboardPermissionEventKey, cb as EventListener);
    };
  }
}
