var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { HorizontallyResizableArea } from '../ResizableArea';
import classNames from 'classnames';
import './style.scss';
const removeWidth = (_a) => {
    var { width, minWidth, maxWidth } = _a, styleWithoutWidth = __rest(_a, ["width", "minWidth", "maxWidth"]);
    return styleWithoutWidth;
};
export const Sidebar = ({ resizeDirection, initialWidth, minWidth, maxWidth, dark, style, className, children, dataTestId, }) => resizeDirection
    ? _jsx(HorizontallyResizableArea, { className: classNames('justui_sidebar', { 'dark': dark }, className), resizeDirection: resizeDirection, initialWidth: initialWidth, minWidth: minWidth, maxWidth: maxWidth, style: style ? removeWidth(style) : undefined, dataTestId: dataTestId, children: children })
    : _jsx("div", { className: classNames('justui_sidebar', { 'dark': dark }, className), style: style, "data-test-id": dataTestId, children: children });
