import React, { FunctionComponent } from 'react';
import { Icon } from '@just-ai/just-ui';
import { TaskStatusData } from 'modules/Notifications/api/client';
import { getTypeByNotification } from 'modules/Notifications/utils/notificaions';
import localize from '../../../../localization';
import { tsToString } from 'modules/Notifications/utils/tasks';

type Props = {
  task: TaskStatusData;
};

export const HeaderInProgress: FunctionComponent<Props> = ({ task }) => (
  <>
    <Icon className='task-spinner' name='faSpinner' color='info' size='sm' />
    {task.notifications?.some(notification => getTypeByNotification(notification) === 'warn') && (
      <Icon name='faExclamationTriangle' color='warning' size='sm' />
    )}
    <span>{localize.translate('tasks generating label')}</span>
  </>
);

export const HeaderCompleted: FunctionComponent<Props> = ({ task }) => (
  <>
    <Icon name='faCheckSquare' color='success' size='sm' />
    {task.notifications?.some(notification => getTypeByNotification(notification) === 'warn') && (
      <Icon name='faExclamationTriangle' color='warning' size='sm' />
    )}
    <span>
      {tsToString(
        task.notifications?.find(notification => getTypeByNotification(notification) === 'info')?.timestamp ||
          task.timestamp
      )}{' '}
      — {localize.translate('tasks end task')}
    </span>
  </>
);

export const HeaderErrors: FunctionComponent<Props> = ({ task }) => (
  <>
    <Icon name='faExclamationCircle' color='danger' size='sm' />
    {task.notifications?.some(notification => getTypeByNotification(notification) === 'warn') && (
      <Icon name='faExclamationTriangle' color='warning' size='sm' />
    )}
    <span>
      {tsToString(
        task.notifications?.find(notification => getTypeByNotification(notification) === 'error')?.timestamp ||
          task.timestamp
      )}{' '}
      — {localize.translate('tasks errors')}
    </span>
  </>
);

export const HeaderWarn: FunctionComponent<Props> = ({ task }) => (
  <>
    <Icon name='faExclamationTriangle' color='warning' size='sm' />
    <span>
      {tsToString(
        task.notifications?.find(notification => getTypeByNotification(notification) === 'warn')?.timestamp ||
          task.timestamp
      )}{' '}
      — {localize.translate('tasks warning')}
    </span>
  </>
);
