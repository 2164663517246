import { OAuthResponseType } from 'components/OAuthBehaviour/types';

export class OAuthBehaviour {
  constructor(private redirectUrl: string) {}

  public tryToRequestAccess(accessUrl: string): Promise<OAuthResponseType> {
    const url = new URL(this.toAbsoluteUrl(accessUrl));
    url.searchParams.append('redirectUrl', this.toAbsoluteUrl(this.redirectUrl));
    return this.openModal(url.toString());
  }

  private openModal(accessUrl: string): Promise<any> {
    const windowArea = this.calcWindowPosition();
    const windowOpts = this.buildWindowOpts({
      popup: 1,
      toolbar: 0,
      scrollbars: 0,
      status: 0,
      resizable: 0,
      location: 0,
      menuBar: 0,
      width: windowArea.width,
      height: windowArea.height,
      left: windowArea.left,
      top: windowArea.top,
    });
    const authWindow = window.open(this.normalizeUrl(accessUrl), 'OAuthPopup', windowOpts);

    return new Promise<OAuthResponseType>((resolve, reject) => {
      if (!authWindow) return reject('window.open does not support');
      window.addEventListener(
        'message',
        (e: { data: OAuthResponseType }) => {
          if (e.data.type !== 'OAuthResponse') return;
          authWindow.close();
          resolve(e.data);
        },
        false
      );
    });
  }

  private getCurrentHost(): string {
    return `${window.location.protocol}//${window.location.host}`;
  }

  private toAbsoluteUrl(url: string): string {
    if (url.startsWith('http')) return url;
    if (url.startsWith('/')) {
      return this.getCurrentHost() + url;
    }
    return this.getCurrentHost() + '/' + url;
  }

  private calcWindowPosition() {
    const windowArea = {
      width: Math.floor(window.outerWidth * 0.3),
      height: Math.floor(window.outerHeight * 0.6),
      left: 0,
      top: 0,
    };
    windowArea.left = Math.floor(window.screenX + (window.outerWidth - windowArea.width) / 2);
    windowArea.top = Math.floor(window.screenY + (window.outerHeight - windowArea.height) / 8);
    return windowArea;
  }

  private normalizeUrl(url: string): string {
    const sep = url.indexOf('?') !== -1 ? '&' : '?';
    return `${url}${sep}`;
  }

  private buildWindowOpts(params: Record<string, string | number>): string {
    return Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join(',');
  }
}
