import { EditorMode } from '../context/EditorContext';
import LocalStorageService from '../service/LocalStorageService';

export const isFileTab = (object: any): object is FileTab => {
  if (typeof object !== 'object') return false;
  if (typeof object['open'] !== 'boolean') return false;
  if (object['editorMode'] !== 'editor' && object['editorMode'] !== 'visual-editor') return false;
  if (
    typeof object['cursorPosition'] !== 'object' ||
    typeof object['cursorPosition'].column !== 'number' ||
    typeof object['cursorPosition'].row !== 'number'
  )
    return false;
  return true;
};

export const isCursorPosition = (object: any): object is EditorCursorPosition => {
  if (typeof object !== 'object') return false;
  if (typeof object['row'] !== 'number') return false;
  if (typeof object['column'] !== 'number') return false;
  return true;
};

export type EditorCursorPosition = {
  column: number;
  row: number;
};

export class FileTab {
  open: boolean = true;
  editorMode: EditorMode = 'editor';
  cursorPosition: EditorCursorPosition = { column: 0, row: 0 };
}

export type FileTabs = {
  [key: string]: FileTab;
};

export class FileTabsHelper {
  private localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    this.localStorageService = localStorageService;
  }

  for(fileTabs: FileTabs) {
    return new FileTabsHelper.Helper(this.localStorageService, fileTabs);
  }

  private static isOpen([_key, value]: [string, FileTab]) {
    return value.open;
  }

  static Helper = class {
    private localStorageService: LocalStorageService;
    private fileTabs: FileTabs;

    constructor(localStorageService: LocalStorageService, fileTabs: FileTabs) {
      this.localStorageService = localStorageService;
      this.fileTabs = fileTabs;
    }

    openTab(fileId: string, mode: EditorMode) {
      if (!this.fileTabs[fileId]) this.fileTabs[fileId] = new FileTab();
      this.fileTabs[fileId].open = true;

      this.fileTabs[fileId].editorMode = mode;
      this.localStorageService.saveOpenFiles(this.fileTabs, fileId);
      return this.fileTabs;
    }

    closeTab(fileId: string) {
      if (!this.fileTabs[fileId]) this.fileTabs[fileId] = new FileTab();
      this.fileTabs[fileId].open = false;

      this.localStorageService.saveOpenFiles(this.fileTabs);
      return this.fileTabs;
    }

    setMode(fileId: string, mode: EditorMode) {
      if (!this.fileTabs[fileId]) this.fileTabs[fileId] = new FileTab();
      this.fileTabs[fileId].editorMode = mode;

      this.localStorageService.saveOpenFiles(this.fileTabs);
      return this.fileTabs;
    }

    findNextTabId(tabId: string) {
      const tabIds = Object.keys(this.fileTabs);
      const tabIndex = tabIds.findIndex(id => id === tabId);
      if (tabIndex === -1) return;

      const nextOpenTabLeft = tabIds
        .slice(0, tabIndex)
        .reverse()
        .find(tabId => this.fileTabs[tabId].open);
      if (nextOpenTabLeft) return nextOpenTabLeft;

      const nextOpenTabRight = tabIds.slice(tabIndex + 1).find(tabId => this.fileTabs[tabId].open);
      return nextOpenTabRight;
    }

    filterInArray(arr: string[]): FileTabs {
      const filteredTabs = Object.entries(this.fileTabs)
        .filter(([tabId]) => arr.includes(tabId))
        .reduce((tabs, [tabId, tab]) => Object.assign(tabs, { [tabId]: tab }), {});

      this.localStorageService.saveOpenFiles(filteredTabs);
      return filteredTabs;
    }

    renameFile(oldFileId: string, newFileId: string) {
      if (!this.fileTabs[oldFileId]) return this.fileTabs;
      this.fileTabs[newFileId] = this.fileTabs[oldFileId];
      delete this.fileTabs[oldFileId];

      this.localStorageService.saveOpenFiles(this.fileTabs);
      return this.fileTabs;
    }

    renameFolder(oldFileId: string, newFileId: string) {
      const newFileTabs = Object.entries(this.fileTabs)
        .map(
          ([tabId, tab]) =>
            [tabId.startsWith(`${oldFileId}/`) ? newFileId + tabId.substring(oldFileId.length) : tabId, tab] as [
              string,
              FileTab
            ]
        )
        .reduce((tabs, [tabId, tab]) => Object.assign(tabs, { [tabId]: tab }), {});

      this.localStorageService.saveOpenFiles(newFileTabs);
      return newFileTabs;
    }

    toTabsData() {
      return Object.entries(this.fileTabs)
        .filter(FileTabsHelper.isOpen)
        .map(([key]) => key);
    }
  };
}
