import { createAsyncThunk } from '@reduxjs/toolkit';
import diffInArrays from '@just-ai/just-ui/dist/utils/diffInArrays';
import { buildEqualFn } from '@just-ai/just-ui';

import { RootState } from 'storeTypes';

import { StickerInfo } from '../../../modules/JGraph/view/Sticker/types';
import { jGraphStickersApi } from '../utils';
import { loadStickers } from '../index';

const syncStickers = createAsyncThunk('JGraph/syncStickers', async (stickersBeforeUpdate: StickerInfo[], thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const stickersAfterUpdate = (thunkAPI.getState() as RootState).JGraphReducer.stickers;

  const { toUpdate } = diffInArrays(stickersAfterUpdate, stickersBeforeUpdate, buildEqualFn(['id']));

  const { data: response } = await jGraphStickersApi.batchUpsertJGraphSticker(
    userAccountId || accountId,
    projectShortName,
    {
      version: state.JGraphReducer.stickersVersion.version,
      lastModified: state.JGraphReducer.stickersVersion.lastModified,
      stickers: toUpdate,
    }
  );

  thunkAPI.dispatch(loadStickers(response));
});

export default syncStickers;
