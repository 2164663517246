export const channeltypeLocalization = {
    'ChannelType YANDEX': {
        eng: 'Yandex Alice',
        ru: 'Алиса',
        pt: 'Yandex Alice',
        cn: 'Yandex Alice',
    },
    'ChannelType NO_CHANNELS': {
        eng: 'Without channels',
        ru: 'Без каналов',
        pt: 'Sem canais',
        cn: 'Without channels',
    },
    'ChannelType INSTAGRAM': {
        eng: 'Instagram',
        ru: 'Instagram',
        pt: 'Instagram',
        cn: 'Instagram',
    },
    'ChannelType CHAT_WIDGET': {
        eng: 'Chat widget',
        ru: 'Чат-виджет',
        pt: 'Widget de chat',
        cn: '聊天小部件!',
    },
    'ChannelType GOOGLE': {
        eng: 'Google Assistant',
        ru: 'Google Ассистент',
        pt: 'Google Assistente',
        cn: 'google助手',
    },
    'ChannelType TELEGRAM': {
        eng: 'Telegram',
        ru: 'Телеграм',
        pt: 'Telegram',
        cn: '电报',
    },
    'ChannelType ZENBOX': {
        eng: 'Aimybox',
        ru: 'Aimybox',
        pt: 'Aimybox',
        cn: 'Aimybox',
    },
    'ChannelType JAICP_ALEXA': {
        eng: 'Alexa',
        ru: 'Alexa',
        pt: 'Alexa',
        cn: 'Alexa',
    },
    'ChannelType DIALOGFLOW': {
        eng: 'Dialogflow',
        ru: 'Dialogflow',
        pt: 'Dialogflow',
        cn: 'Dialogflow',
    },
    'ChannelType CHAT_API': {
        eng: 'Chat API',
        ru: 'Chat API',
        pt: 'Chat API',
        cn: '聊天API',
    },
    'ChannelType CHATAPI': {
        eng: 'Chat API',
        ru: 'Chat API',
        pt: 'Chat API',
        cn: '聊天API',
    },
    'ChannelType VK': {
        eng: 'VK',
        ru: 'ВКонтакте',
        pt: 'VK',
        cn: 'VK',
    },
    'ChannelType FACEBOOK': {
        eng: 'Facebook',
        ru: 'Facebook',
        pt: 'Facebook',
        cn: 'Facebook',
    },
    'ChannelType VIBER': {
        eng: 'Viber',
        ru: 'Viber',
        pt: 'Viber',
        cn: 'Viber',
    },
    'ChannelType RESTERISK': {
        eng: 'Telephony',
        ru: 'Телефония',
        pt: 'Telefonia',
        cn: '电话',
    },
    'ChannelType SLACK': {
        eng: 'Slack',
        ru: 'Slack',
        pt: 'Slack',
        cn: 'Slack',
    },
    'ChannelType LIVETEX_PROMPTER': {
        eng: 'LiveTex',
        ru: 'LiveTex',
        pt: 'LiveTex',
        cn: 'LiveTex',
    },
    'ChannelType WECHAT': {
        eng: 'WeChat',
        ru: 'WeChat',
        pt: 'WeChat',
        cn: '微信',
    },
    'ChannelType ZENDESK': {
        eng: 'ZenDesk',
        ru: 'ZenDesk',
        pt: 'ZenDesk',
        cn: 'ZenDesk',
    },
    'ChannelType ZOPIM': {
        eng: 'ZenDesk Chat',
        ru: 'ZenDesk Chat',
        pt: 'ZenDesk Chat',
        cn: 'ZenDesk Chat',
    },
    'ChannelType WEBIM': {
        eng: 'Webim (Custom Channel API)',
        ru: 'Webim (Custom Channel API)',
        pt: 'Webim (Custom Channel API)',
        cn: 'Webim (Custom Channel API)',
    },
    'ChannelType INCOMING_WEBIM2': {
        eng: 'Webim (External Bot API 2.0)',
        ru: 'Webim (External Bot API 2.0)',
        pt: 'Webim (External Bot API 2.0)',
        cn: 'Webim (External Bot API 2.0)',
    },
    'ChannelType NEXMO': {
        eng: 'Vonage',
        ru: 'Vonage',
        pt: 'Vonage',
        cn: 'Vonage',
    },
    'ChannelType THREADS': {
        eng: 'edna.chatCenter',
        ru: 'edna.chatCenter',
        pt: 'edna.chatCenter',
        cn: 'edna.chatCenter',
    },
    'ChannelType I_DIGITAL': {
        eng: 'i-Digital WhatsApp',
        ru: 'i-Digital WhatsApp',
        pt: 'i-Digital WhatsApp',
        cn: 'i-Digital WhatsApp',
    },
    'ChannelType INCOMING_JIVOSITE': {
        eng: 'JivoChat',
        ru: 'Jivo',
        pt: 'JivoChat',
        cn: 'JivoChat',
    },
    'ChannelType AZURE': {
        eng: 'Azure Bot',
        ru: 'Azure Bot',
        pt: 'Azure Bot',
        cn: 'Azure Bot',
    },
    'ChannelType MARUSIA': {
        eng: 'Marusia',
        ru: 'Маруся',
        pt: 'Marusia',
        cn: 'Marusia',
    },
    'ChannelType BITRIX': {
        eng: 'Bitrix24',
        ru: 'Битрикс24',
        pt: 'Bitrix24',
        cn: 'Bitrix24',
    },
    'ChannelType ODNOKLASSNIKI': {
        eng: 'Odnoklassniki',
        ru: 'Одноклассники',
        pt: 'Odnoklassniki',
        cn: 'Odnoklassniki',
    },
    'ChannelType SALESFORCE': {
        eng: 'Salesforce',
        ru: 'Salesforce',
        pt: 'Salesforce',
        cn: 'Salesforce',
    },
    'ChannelType JIVOSITE': {
        eng: 'JivoChat',
        ru: 'Jivo',
        pt: 'JivoChat',
        cn: 'JivoChat',
    },
    'ChannelType OPERATORAPI': {
        eng: 'Operator API',
        ru: 'Operator API',
        pt: 'Operator API',
        cn: 'Operator API',
    },
    'ChannelType SBER': {
        eng: 'Sber Salut',
        ru: 'Сбер Салют',
        pt: 'Sber Salut',
        cn: 'Sber Salut',
    },
    'ChannelType INBOUND_LIVETEX': {
        eng: 'LiveTex',
        ru: 'LiveTex',
        pt: 'LiveTex',
        cn: 'LiveTex',
    },
    'ChannelType CHAT2DESK': {
        eng: 'Chat2Desk',
        ru: 'Chat2Desk',
        pt: 'Chat2Desk',
        cn: 'Chat2Desk',
    },
    'ChannelType WAZZUP': {
        eng: 'Wazzup',
        ru: 'Wazzup',
        pt: 'Wazzup',
        cn: 'Wazzup',
    },
};
