export const asrLocales = [
  'ar-DZ',
  'ar-BH',
  'ar-EG',
  'ar-IQ',
  'ar-IL',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-PS',
  'ar-SY',
  'ar-TN',
  'ar-AE',
  'ar-YE',
  'bg-BG',
  'ca-ES',
  'zh-HK',
  'zh-CN',
  'zh-TW',
  'hr-HR',
  'cs-CZ',
  'da-DK',
  'nl-NL',
  'en-AU',
  'en-CA',
  'en-GH',
  'en-HK',
  'en-IN',
  'en-IE',
  'en-KE',
  'en-NZ',
  'en-NG',
  'en-PH',
  'en-SG',
  'en-ZA',
  'en-TZ',
  'en-GB',
  'en-US',
  'et-EE',
  'fil-PH',
  'fi-FI',
  'fr-CA',
  'fr-FR',
  'fr-CH',
  'de-AT',
  'de-DE',
  'el-GR',
  'gu-IN',
  'he-IL',
  'hi-IN',
  'hu-HU',
  'id-ID',
  'ga-IE',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lv-LV',
  'lt-LT',
  'ms-MY',
  'mt-MT',
  'mr-IN',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-CU',
  'es-DO',
  'es-EC',
  'es-SV',
  'es-GQ',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PR',
  'es-ES',
  'es-UY',
  'es-US',
  'es-VE',
  'sv-SE',
  'ta-IN',
  'te-IN',
  'th-TH',
  'tr-TR',
  'vi-VN',
];
export const ttsLocales = [
  'ar-EG',
  'ar-SA',
  'bg-BG',
  'ca-ES',
  'zh-HK',
  'zh-CN',
  'zh-TW',
  'hr-HR',
  'cs-CZ',
  'da-DK',
  'nl-BE',
  'nl-NL',
  'en-AU',
  'en-CA',
  'en-HK',
  'en-IN',
  'en-IE',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-ZA',
  'en-GB',
  'en-US',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-FR',
  'fr-CH',
  'de-AT',
  'de-DE',
  'de-CH',
  'el-GR',
  'gu-IN',
  'he-IL',
  'hi-IN',
  'hu-HU',
  'id-ID',
  'ga-IE',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lv-LV',
  'lt-LT',
  'ms-MY',
  'mt-MT',
  'mr-IN',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'es-AR',
  'es-CO',
  'es-MX',
  'es-ES',
  'es-US',
  'sw-KE',
  'sv-SE',
  'ta-IN',
  'te-IN',
  'th-TH',
  'tr-TR',
  'uk-UA',
  'ur-PK',
  'vi-VN',
  'cy-GB',
  'y-GB',
];
