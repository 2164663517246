import React, { useCallback } from 'react';
import { IconButton, Scrollbar } from '@just-ai/just-ui';

import classes from './style.module.scss';

type JsonContainerProps = {
  value: string;
};

export default function JsonContainer(props: JsonContainerProps) {
  const copyText = useCallback(() => {
    navigator.clipboard.writeText(props.value);
  }, [props.value]);

  return (
    <div className={classes.jsonContainer}>
      <IconButton name='farCopy' flat color='secondary' size='sm' onClick={copyText} />
      <Scrollbar
        options={{ suppressScrollX: true }}
        style={{ display: 'flex', overflow: 'hidden', maxWidth: '100%', minWidth: '50px' }}
        className={classes.jsonContainer__text}
      >
        <div>{props.value}</div>
      </Scrollbar>
    </div>
  );
}
