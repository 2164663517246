import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TStateToUpdate } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi } from '../utils';

const updateState = createAsyncThunk('JGraph/updateState', async (createJStateData: TStateToUpdate, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;
  const editMenuBlockOldPath = createJStateData.useSelfPathValue
    ? createJStateData.path
    : state.JGraphReducer.editMenuBlock
    ? state.JGraphReducer.editMenuBlock.screen.path
    : createJStateData.path;

  if (editMenuBlockOldPath !== createJStateData.path) debugger;
  const { data: lastModification } = await GraphApi.updateState(userAccountId || accountId, projectShortName, {
    targetStatePath: editMenuBlockOldPath,
    stateToUpdate: {
      ...createJStateData,
    },
    file: {
      filename: createJStateData.filename,
      lastModification: state.JGraphReducer.graph.files[createJStateData.filename],
    },
  });
  return lastModification;
});

export default updateState;
