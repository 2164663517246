import stickerImgGreen from 'img/jgraph/stickers/green.png';
import stickerImgCian from 'img/jgraph/stickers/cian.png';
import stickerImgBlue from 'img/jgraph/stickers/blue.png';
import stickerImgPink from 'img/jgraph/stickers/pink.png';
import stickerImgOrange from 'img/jgraph/stickers/orange.png';
import stickerImgLightBlue from 'img/jgraph/stickers/light_blue.png';
import stickerImgPurple from 'img/jgraph/stickers/purple.png';
import stickerImgYellow from 'img/jgraph/stickers/yellow.png';

export type StickerColor = {
  name: StickerColorKey;
  mainColor: string;
  img: string;
  altBorderColor?: string;
};

export enum StickerColorKey {
  GREEN = 'green',
  CIAN = 'cian',
  BLUE = 'blue',
  PINK = 'pink',
  ORANGE = 'orange',
  LIGHT_BLUE = 'lightBlue',
  PURPLE = 'purple',
  YELLOW = 'yellow',
}

export const stickerColors: Record<StickerColorKey, StickerColor> = {
  [StickerColorKey.GREEN]: {
    name: StickerColorKey.GREEN,
    mainColor: '#C4F794',
    img: stickerImgGreen,
  },
  [StickerColorKey.CIAN]: {
    name: StickerColorKey.CIAN,
    mainColor: '#94F7C7',
    img: stickerImgCian,
  },
  [StickerColorKey.BLUE]: {
    name: StickerColorKey.BLUE,
    mainColor: '#C9E0F8',
    img: stickerImgBlue,
  },
  [StickerColorKey.PINK]: {
    name: StickerColorKey.PINK,
    mainColor: '#F3D3F2',
    img: stickerImgPink,
  },
  [StickerColorKey.ORANGE]: {
    name: StickerColorKey.ORANGE,
    mainColor: '#F8D3D3',
    img: stickerImgOrange,
  },
  [StickerColorKey.LIGHT_BLUE]: {
    name: StickerColorKey.LIGHT_BLUE,
    mainColor: '#94F5F7',
    img: stickerImgLightBlue,
  },
  [StickerColorKey.PURPLE]: {
    name: StickerColorKey.PURPLE,
    mainColor: '#E6D5F6',
    img: stickerImgPurple,
  },
  [StickerColorKey.YELLOW]: {
    name: StickerColorKey.YELLOW,
    mainColor: '#F6F794',
    altBorderColor: '#C3C6C9',
    img: stickerImgYellow,
  },
};

export function getStickerColorInfo(color: string) {
  if (color in stickerColors) {
    return stickerColors[color as StickerColorKey];
  }
  return stickerColors[StickerColorKey.YELLOW];
}
