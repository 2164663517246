import React from 'react';
import cn from 'classnames';
import { useDragLayer, useDrop } from 'react-dnd';

import { DragNodeType } from '../Leaf';
import { TreeNode, DragTypes } from '../../types';

import styles from './styles.module.scss';

interface DragAreaInterface {
  onDrop: (node: TreeNode) => void;
}
const DragArea = function ({ onDrop }: DragAreaInterface) {
  const { isDragging } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const [{ isOver }, drop] = useDrop({
    accept: DragTypes.LEAF,
    drop: (item: DragNodeType) => {
      onDrop(item.node);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  if (!isDragging) return null;

  return (
    <div className={cn(styles.DragArea)}>
      <div ref={drop} className={cn(styles.DragArea__content)}>
        {isOver && <div className={styles.DragArea__preview} />}
      </div>
    </div>
  );
};

export default React.memo(DragArea);
