import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { isSystemAccess } from 'isAccessFunction';

import NotificationBell from 'components/SadminPanel/components/NotificationBell';

interface NonProjectedPageProps {
  children: React.ReactNode;
}
const NonProjectedPage = ({ children }: NonProjectedPageProps) => {
  const [notificationsListDOM, setNotificationsListDOM] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setNotificationsListDOM(document.getElementById('notifications-list'));
  }, []);

  const notificationBell = notificationsListDOM
    ? ReactDOM.createPortal(
        <>{isSystemAccess('account_billing_reports') && <NotificationBell forAccount theme='dark' />}</>,
        notificationsListDOM
      )
    : null;

  return (
    <>
      {children}
      {notificationBell}
    </>
  );
};

NonProjectedPage.displayName = 'NonProjectedPage';

export default NonProjectedPage;
