export const widgetLocalization = {
  'Widget:widgetTitle': {
    eng: 'Test',
    ru: 'Тестировать',
    cn: '测试',
  },
  'Widget:clearButton': {
    eng: 'clear',
    ru: 'очистить',
    cn: '清除',
  },
  'Widget:learningStatusText': {
    eng: 'NLU is training. Please wait',
    ru: 'NLU обучается. Пожалуйста, подождите',
    cn: 'NLU训练中，请等待',
  },
  'Widget:intentsTitle': {
    eng: 'Intents',
    ru: 'Интенты',
    cn: '意图',
  },
  'Widget:entitiesTitle': {
    eng: 'Entities',
    ru: 'Сущности',
    cn: '实体',
  },
  'Widget:modalHeader': {
    eng: 'JSON',
    ru: 'JSON',
  },
  'Widget:modalCloseButton': {
    eng: 'Close',
    ru: 'Закрыть',
    cn: '关闭',
  },
  'Widget:value': {
    eng: 'Value',
    ru: 'Значение',
    cn: '值',
  },
  'Widget:emptyListCaption': {
    eng: 'Enter your request',
    ru: 'Введите ваш запрос',
    cn: '输入您的请求',
  },
  'Widget:showJsonButton': {
    eng: 'Show JSON',
    ru: 'Показать JSON',
    cn: '显示JSON',
  },
  'Widget:intentNotRecognizedCaption': {
    eng: 'Intent isn’t recognized',
    ru: 'Интент не найден',
    cn: '意图无法识别',
  },
  'Widget:addToDictionary': {
    eng: 'Add to Dictionary',
    ru: 'Добавить в словарь',
    cn: '添加到词典',
  },
  'Widget:notCorrect': {
    eng: 'Ignore Word',
    ru: 'Не исправлять',
    cn: '忽略单词',
  },
  'Widget:titleText': {
    eng: 'Phrase processing',
    ru: 'Обработка фразы',
  },
  'Widget:addToDictionaryModalTitle': {
    eng: 'Add to Dictionary',
    ru: 'Добавить в словарь',
    cn: '添加到词典',
  },
  'Widget:addToDictonaryModalSubmit': {
    eng: 'Add',
    ru: 'Добавить',
    cn: '添加',
  },
  'Widget:addToDictonaryModalCancel': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'Widget:spellingPopoverTitleDisabled': {
    eng: 'Spellchecker is disabled',
    ru: 'Исправление орфографии отключено',
  },
  'Widget:spellingPopoverTitleEnabled': {
    eng: 'Spellchecker is enabled',
    ru: 'Исправление орфографии включено',
  },
  'Widget:spellingPopoverDescription': {
    eng: 'The system uses system and client dictionaries to correct spelling errors in words',
    ru: 'Система исправляет опечатки в словах с помощью системного и клиентского словарей',
  },
  'Widget:spellingPopoverButtonEnable': {
    eng: 'Enable',
    ru: 'Включить',
  },
  'Widget:spellingPopoverButtonSetting': {
    eng: 'Setting',
    ru: 'Настройка',
  },
  'Widget:spellingPopoverLinkText': {
    eng: 'About spellchecking',
    ru: 'Об исправлении опечаток',
  },
  'Widget:systemDictionary': {
    eng: 'System dictionary',
    ru: 'Системный словарь',
  },
  'Widget:userDictionary': {
    eng: 'User dictionary',
    ru: 'Словарь пользователя',
  },
  'Widget:cdqaModal:Info': {
    eng: 'The bot uses a fragment of the document when answering the user’s question.',
    ru: 'Бот использует фрагмент из документа при ответе на вопрос пользователя.',
  },
  'Widget:cdqaModal:AnswerText': {
    eng: 'Answer text',
    ru: 'Текст ответа',
  },
  'Widget:cdqaModal:OtherAnswers': {
    eng: 'Other answers in JSON',
    ru: 'Остальные ответы в JSON',
  },
};
