import React, { FC, useCallback, useMemo, useState } from 'react';
import { AutopositionGroup } from './AutopositionGroup';
import { KIcon } from './KIcons';
import { Text, Group, Rect } from 'react-konva';
import { TransitionCircle } from './TransitionCircle';
import { mapTypeToIcon } from './Activations';
import { getStageActionsFromEvent, setCursorOnMouseEnterAndLeave } from '../../utils/stageUtils';
import { TActivationTagNames, TagNames } from '../../utils/types';
import { t } from 'localization';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { AutosizeRect } from './AutosizeRect';
import { DebugMark } from './DebugMark';
import { JGraphProviderSubject$ } from '../../contexts/JGraphContext';
import { hideRootSlashInPath } from '../../utils/state';

type TEventButtonProps = {
  screen: JStateWithId;
  type: TagNames;
  text: string;
  name: string;
  index: number;

  active?: boolean;
  hasTransition?: boolean;

  bottomCornerRadius?: boolean;
  transitionTo?: string;
  isToState?: boolean;
  debugActive?: boolean;
};

const textProps = {
  fontFamily: 'Roboto',
  verticalAlign: 'middle',
  fontSize: 14,
  letterSpacing: 0.0075,
  height: 24,
};

export const EventButton: FC<TEventButtonProps> = ({
  screen,
  type,
  text,
  active,
  index,
  hasTransition,
  bottomCornerRadius,
  transitionTo,
  name,
  isToState = false,
}) => {
  const [hover, setHover] = useState(false);
  const [isSystemIntent, setIsSystemIntent] = useState(false);

  const cornerRadius = [0, 0, 0, 0];
  if (bottomCornerRadius) {
    cornerRadius[2] = 8;
    cornerRadius[3] = 8;
  }

  const showValue = useMemo(() => {
    if ([TagNames.intent_, TagNames.intent].includes(type as TagNames)) {
      const jgContext = JGraphProviderSubject$.getValue();
      if (jgContext.IntentsService.isSystemIntent(jgContext.currentBotLanguage, text)) {
        setIsSystemIntent(true);
        return t(`ChooseReadyIntent ${text}`);
      } else {
        setIsSystemIntent(false);
        const renderedText = new Konva.Text({ ...textProps, text: text });
        if (renderedText.width() > 184) {
          const letterSize = renderedText.width() / text.length;
          const newTextLength = Math.floor(184 / letterSize) - 3;
          return `...${text.slice(-newTextLength)}`;
        }
      }
    }
    return text;
  }, [text, type]);

  const setEditModalBlock = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      if (isToState) {
        const stageActions = getStageActionsFromEvent(event);
        stageActions.setEditMenuBlock({ screen: screen, jBlockIndex: index });
      }
    },
    [index, isToState, screen]
  );

  const isDebugActive = useMemo(() => {
    return !!(isToState && screen.debugActive && screen.blocks[index] && screen.blocks[index].debugActive);
  }, [index, isToState, screen.blocks, screen.debugActive]);

  return (
    <AutopositionGroup yOffset={0} xOffset={0}>
      <AutosizeRect
        fill='transparent'
        width={280}
        cornerRadius={cornerRadius}
        bottomPadding={bottomCornerRadius ? 0 : 8}
        activeTypeProp='background'
        name={name}
      >
        <Group x={12} y={0} onClick={setEditModalBlock}>
          {isDebugActive ? <DebugMark height={30} x={-12} y={-8} /> : null}
          <KIcon icon={mapTypeToIcon[isSystemIntent ? 'systemIntent' : (type as TActivationTagNames)]} />
          <Text
            x={24}
            y={-2}
            {...textProps}
            fill='#333C45'
            text={hideRootSlashInPath(showValue)}
            width={184}
            ellipsis={true}
          />
          <Rect
            y={-8}
            x={-12}
            fill='#000'
            opacity={hover || active ? 0.04 : 0}
            cornerRadius={cornerRadius}
            width={280}
            height={32}
            onMouseEnter={setCursorOnMouseEnterAndLeave(() => setHover(true))}
            onMouseLeave={setCursorOnMouseEnterAndLeave(() => setHover(false))}
          />
          <TransitionCircle
            x={280 - 24 - 6}
            y={9}
            tagName={type}
            hasTransition={hasTransition}
            name={name}
            canStartConnection={isToState}
            transitionTo={transitionTo === './' ? undefined : transitionTo}
          />
        </Group>
      </AutosizeRect>
    </AutopositionGroup>
  );
};
