export type LabelColor = {
  mainColor: string;
  textColor: string;
  altBorderColor?: string;
};

export const labelColors: LabelColor[] = [
  {
    mainColor: '#3E8080',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#15952F',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#7E47D1',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#CD4C2B',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#7524AA',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#B65A1E',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#143AD1',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#418614',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#FFFFFF',
    altBorderColor: '#E0E1E3',
    textColor: '#6A7178',
  },
  {
    mainColor: '#BC3737',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#8A7522',
    textColor: '#FFFFFF',
  },
  {
    mainColor: '#D93275',
    textColor: '#FFFFFF',
  },
];
