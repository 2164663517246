export const tokensLocalization = {
  TokensManagement: {
    eng: 'API access',
    ru: 'Доступ к API',
  },
  'TokensPage:Title': {
    eng: 'API access tokens',
    ru: 'Токены для доступа к API',
  },
  'TokensPage:Description': {
    eng: 'We are transitioning to unified tokens. Now a single token can grant access to multiple $[1] APIs. For more details, please refer to the documentation. The previous tokens remain active and accessible in the same platform sections where they were originally issued.',
    ru: 'Мы переходим на единые токены. Теперь один токен может давать доступ к нескольким API $[1]. Подробнее читайте в документации. Прошлые токены по-прежнему активны и доступны в тех же разделах платформы, где они были выпущены.',
  },
  'Tokens:Active': {
    eng: 'Active',
    ru: 'Активные',
  },
  'Tokens:Stoped': {
    eng: 'Inactive',
    ru: 'Остановленные',
  },
  'Tokens:Stop': {
    eng: 'Stop',
    ru: 'Остановить',
  },
  'Tokens:Edit': {
    eng: 'Edit',
    ru: 'Редактировать',
  },
  'Tokens:AddNew': {
    eng: 'Issue token',
    ru: 'Выпустить токен',
  },
  'TokensTable:Description': {
    eng: 'Not all APIs can currently be accessed using unified tokens. As we integrate new APIs, we will automatically grant access to them for existing tokens. There’s no need to reissue them.',
    ru: 'Пока не ко всем API можно разрешить доступ с помощью единого токена. По мере подключения API мы будем автоматически добавлять доступ к ним для существующих токенов. Перевыпускать их не нужно.',
  },
  'TokensTable:AlreadyConnetced': {
    eng: 'Already connected:',
    ru: 'Уже подключенные:',
  },
  'Tokens:Sort:RemainingTerm': {
    eng: 'By expiration date',
    ru: 'По дате окончания действия',
  },
  'Tokens:Sort:FromNew:ToOld': {
    eng: 'By issue date',
    ru: 'По дате выпуска',
  },
  'Tokens:Sort:FromOld:ToNew': {
    eng: 'By issue date',
    ru: 'По дате выпуска',
  },
  'Tokens:Sort:Owner': {
    eng: 'By owner',
    ru: 'По владельцу',
  },
  'Tokens:Sort:Name': {
    eng: 'By name',
    ru: 'По названию',
  },
  'Token:New:Dialog:Title': {
    eng: 'New token',
    ru: 'Новый токен',
  },
  'Token:Edit:Dialog:Title': {
    eng: 'Edit token',
    ru: 'Редактирование токена',
  },
  'Token:New:Dialog:CopyToken': {
    eng: 'Copy',
    ru: 'Скопировать',
  },
  'Token:Dialog:Name:Error': {
    eng: 'Enter a name',
    ru: 'Придумайте название',
  },
  'Token:Dialog:Apis:Error': {
    eng: 'Select at least 1 option',
    ru: 'Выберите хотя бы 1 вариант',
  },
  'Token:Dialog:Url:Error': {
    eng: 'Verify the accuracy of the link',
    ru: 'Проверьте правильность написания ссылки',
  },
  'Token:New:Dialog:Submit': {
    eng: 'Issue',
    ru: 'Выпустить',
  },
  'Token:New:Dialog:Save': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'Token:Dialog:Name:Label': {
    eng: 'Name',
    ru: 'Название',
  },
  'Token:Dialog:Term:Label': {
    eng: 'Lifetime',
    ru: 'Время жизни',
  },
  'Token:Dialog:Months:One': {
    eng: '1 month',
    ru: '1 месяц',
  },
  'Token:Dialog:Months:Few': {
    eng: '$[1] months',
    ru: '$[1] месяца',
  },
  'Token:Dialog:Months:Many': {
    eng: '$[1] months',
    ru: '$[1] месяцев',
  },
  'Token:Dialog:Api:Access:Label': {
    eng: 'API access',
    ru: 'Доступ к API',
  },
  'Token:Dialog:Url:Label': {
    eng: 'Callback URL (optional)',
    ru: 'URL обратного вызова (необязательно)',
  },
  'TokensTable:NoTokens': {
    eng: 'You don’t have any tokens yet',
    ru: 'У вас пока нет токенов',
  },
  'Token:YourToken:Dialog:Title': {
    eng: 'Your new token',
    ru: 'Ваш новый токен',
  },
  'Token:YourToken:Dialog:Description': {
    eng: '<b>Attention!</b> Please make sure to copy your access token now. Once this window is closed, the token will no longer be visible in the interface.',
    ru: '<b>Внимание!</b> Скопируйте ваш токен доступа сейчас. Когда вы закроете это окно, токен больше не будет отображаться в интерфейсе.',
  },
  'Token:Refresh:Dialog:Title': {
    eng: 'Reissue token',
    ru: 'Перевыпустить токен?',
  },
  'Token:Refresh:Dialog:Submit': {
    eng: 'Reissue',
    ru: 'Перевыпустить',
  },
  'Token:Refresh:Dialog:Description': {
    eng: 'A new sequence of characters will be created, making the old one invalid. The token’s lifetime will be extended.',
    ru: 'Будет создана новая последовательность символов, старая станет недействительной. Срок действия токена будет продлен.',
  },
  'Token:Stop:Dialog:Title': {
    eng: 'Revoke token',
    ru: 'Отозвать токен?',
  },
  'Token:Stop:Dialog:Description': {
    eng: 'You will not be able to activate this token in the future. Any applications or services that use this token will no longer have access to the API.',
    ru: 'Вы не сможете активировать этот токен в будущем. Любые приложения или сервисы, использующие этот токен, больше не смогут получить доступ к API.',
  },
  'Token:Stop:Dialog:Submit': {
    eng: 'Revoke',
    ru: 'Отозвать',
  },
  'Token:Status:BY_TIME': {
    eng: 'Expired',
    ru: 'Истек',
  },
  'Token:Status:MANUALLY': {
    eng: 'Revoked',
    ru: 'Отозван',
  },
  'Token:Table:Header:Token': {
    eng: 'Token',
    ru: 'Токен',
  },
  'Token:Table:Header:Owner': {
    eng: 'Owner',
    ru: 'Владелец',
  },
  'Token:Table:Header:Term': {
    eng: 'Expiration date',
    ru: 'Срок действия',
  },
  'Token:Table:Header:Stopped': {
    eng: 'Status',
    ru: 'Статус',
  },
  'Plural:Days:Zero': {
    eng: '0 days',
    ru: '0 дней',
  },
  'Plural:Days:One': {
    eng: '1 day',
    ru: '1 день',
  },
  'Plural:Days:Two': {
    eng: '2 days',
    ru: '2 дня',
  },
  'Plural:Days:Few': {
    eng: '$[1] days',
    ru: '$[1] дня',
  },
  'Plural:Days:Many': {
    eng: '$[1] days',
    ru: '$[1] дней',
  },
  'Plural:Months:Zero': {
    eng: '0 months',
    ru: '0 месяцев',
  },
  'Plural:Months:One': {
    eng: '1 month',
    ru: '1 месяц',
  },
  'Plural:Months:Two': {
    eng: '2 months',
    ru: '2 месяца',
  },
  'Plural:Months:Few': {
    eng: '$[1] months',
    ru: '$[1] месяца',
  },
  'Plural:Months:Many': {
    eng: '$[1] months',
    ru: '$[1] месяцев',
  },
  'Plural:Years:Zero': {
    eng: '0 years',
    ru: '0 лет',
  },
  'Plural:Years:One': {
    eng: '1 year',
    ru: '1 год',
  },
  'Plural:Years:Two': {
    eng: '2 years',
    ru: '2 года',
  },
  'Plural:Years:Few': {
    eng: '$[1] years',
    ru: '$[1] года',
  },
  'Plural:Years:Many': {
    eng: '$[1] years',
    ru: '$[1] лет',
  },
  'Token:Term:Left': {
    eng: '$[1] and $[2] left',
    ru: 'еще $[1] и $[2]',
  },
  'Token:Days:Left': {
    eng: '$[1] left',
    ru: 'еще $[1]',
  },
  from: {
    eng: 'From',
    ru: 'От',
  },

  'Tokens:Stoped:Tab:Tooltip': {
    eng: 'You don’t have any inactive tokens',
    ru: 'У вас нет остановленных токенов',
  },
  'Tokens:Term:Ends:Today': {
    eng: 'less than a day',
    ru: 'менее суток',
  },
  'Token:Form:Name:Placeholder': {
    eng: 'Token for CRM integration',
    ru: 'Токен для интеграции с CRM',
  },
  'Tokens:Search:Placeholder': {
    ru: 'Название, ID или владелец токена',
    eng: 'Token name, ID, or owner',
  },
  'Tokens:Apis:NotSelected': {
    eng: 'not selected',
    ru: 'не выбрано',
  },
  'Tokens:Apis:Selected': {
    eng: 'selected',
    ru: 'выбрано',
  },
};
