const prefix = '@projects/';

export const SET_EDITABLE_PROJECT = prefix + 'SET_EDITABLE_PROJECT';
export const SET_CURRENT_PROJECT = prefix + 'SET_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = prefix + 'CLEAR_CURRENT_PROJECT';
export const CLEAR_NLU_SETTINGS_ERROR = prefix + 'CLEAR_NLU_SETTINGS_ERROR';
export const CHANGE_CURRENT_PROJECT = prefix + 'CHANGE_CURRENT_PROJECT';
export const UPDATE_EDITABLE_PROJECT = prefix + 'UPDATE_EDITABLE_PROJECT';
export const CLEAR_EDITABLE_PROJECT = prefix + 'CLEAR_EDITABLE_PROJECT';
export const TOGGLE_EDIT_PROJECT_MODAL = prefix + 'TOGGLE_EDIT_PROJECT_MODAL';
export const TOGGLE_EXTERNAL_REPOSITORY = prefix + 'TOGGLE_EXTERNAL_REPOSITORY';
export const CHANGE_PROJECT_TEMPLATE = prefix + 'CHANGE_PROJECT_TEMPLATE';

export const SAVE_EDITABLE_PROJECT = prefix + 'SAVE_EDITABLE_PROJECT';
export const SAVE_EDITABLE_PROJECT_PENDING = prefix + 'SAVE_EDITABLE_PROJECT_PENDING';
export const SAVE_EDITABLE_PROJECT_FULFILLED = prefix + 'SAVE_EDITABLE_PROJECT_FULFILLED';
export const SAVE_EDITABLE_PROJECT_REJECTED = prefix + 'SAVE_EDITABLE_PROJECT_REJECTED';

export const FETCH_BOT_TEMPLATES = prefix + 'FETCH_BOT_TEMPLATES';
export const FETCH_BOT_TEMPLATES_PENDING = prefix + 'FETCH_BOT_TEMPLATES_PENDING';
export const FETCH_BOT_TEMPLATES_FULFILLED = prefix + 'FETCH_BOT_TEMPLATES_FULFILLED';
export const FETCH_BOT_TEMPLATES_REJECTED = prefix + 'FETCH_BOT_TEMPLATES_REJECTED';

export const CREATE_PROJECT = prefix + 'CREATE_PROJECT';
export const CREATE_PROJECT_PENDING = prefix + 'CREATE_PROJECT_PENDING';
export const CREATE_PROJECT_FULFILLED = prefix + 'CREATE_PROJECT_FULFILLED';
export const CREATE_PROJECT_REJECTED = prefix + 'CREATE_PROJECT_REJECTED';

export const CREATE_CAILA_PROJECT = prefix + 'CREATE_CAILA_PROJECT';
export const CREATE_CAILA_PROJECT_PENDING = prefix + 'CREATE_CAILA_PROJECT_PENDING';
export const CREATE_CAILA_PROJECT_FULFILLED = prefix + 'CREATE_CAILA_PROJECT_FULFILLED';
export const CREATE_CAILA_PROJECT_REJECTED = prefix + 'CREATE_CAILA_PROJECT_REJECTED';

export const SET_CAILA_PROJECT_LANGUAGE = prefix + 'SET_CAILA_PROJECT_LANGUAGE';
export const SET_CAILA_PROJECT_LANGUAGE_PENDING = prefix + 'SET_CAILA_PROJECT_LANGUAGE_PENDING';
export const SET_CAILA_PROJECT_LANGUAGE_FULFILLED = prefix + 'SET_CAILA_PROJECT_LANGUAGE_FULFILLED';
export const SET_CAILA_PROJECT_LANGUAGE_REJECTED = prefix + 'SET_CAILA_PROJECT_LANGUAGE_REJECTED';

export const GET_CAILA_PROJECT_SETTINGS = prefix + 'GET_CAILA_PROJECT_SETTINGS';
export const GET_CAILA_PROJECT_SETTINGS_PENDING = prefix + 'GET_CAILA_PROJECT_SETTINGS_PENDING';
export const GET_CAILA_PROJECT_SETTINGS_FULFILLED = prefix + 'GET_CAILA_PROJECT_SETTINGS_FULFILLED';
export const GET_CAILA_PROJECT_SETTINGS_REJECTED = prefix + 'GET_CAILA_PROJECT_SETTINGS_REJECTED';

export const GET_CAILA_LANGUAGES = prefix + 'GET_CAILA_LANGUAGES';
export const GET_CAILA_LANGUAGES_PENDING = prefix + 'GET_CAILA_LANGUAGES_PENDING';
export const GET_CAILA_LANGUAGES_FULFILLED = prefix + 'GET_CAILA_LANGUAGES_FULFILLED';
export const GET_CAILA_LANGUAGES_REJECTED = prefix + 'GET_CAILA_LANGUAGES_REJECTED';

export const GET_CAILA_ACCESS_KEY = prefix + 'GET_CAILA_ACCESS_KEY';
export const GET_CAILA_ACCESS_KEY_PENDING = prefix + 'GET_CAILA_ACCESS_KEY_PENDING';
export const GET_CAILA_ACCESS_KEY_FULFILLED = prefix + 'GET_CAILA_ACCESS_KEY_FULFILLED';
export const GET_CAILA_ACCESS_KEY_REJECTED = prefix + 'GET_CAILA_ACCESS_KEY_REJECTED';

export const REMOVE_EDITABLE_PROJECT = prefix + 'REMOVE_EDITABLE_PROJECT';
export const REMOVE_EDITABLE_PROJECT_PENDING = prefix + 'REMOVE_EDITABLE_PROJECT_PENDING';
export const REMOVE_EDITABLE_PROJECT_FULFILLED = prefix + 'REMOVE_EDITABLE_PROJECT_FULFILLED';
export const REMOVE_EDITABLE_PROJECT_REJECTED = prefix + 'REMOVE_EDITABLE_PROJECT_REJECTED';

export const FETCH_PROJECTS = prefix + 'FETCH_PROJECTS';
export const FETCH_PROJECTS_PENDING = prefix + 'FETCH_PROJECTS_PENDING';
export const FETCH_PROJECTS_FULFILLED = prefix + 'FETCH_PROJECTS_FULFILLED';
export const FETCH_PROJECTS_REJECTED = prefix + 'FETCH_PROJECTS_REJECTED';

export const FETCH_PROJECT_STATISTIC = prefix + 'FETCH_PROJECT_STATISTIC';
export const FETCH_PROJECT_STATISTIC_FULFILLED = prefix + 'FETCH_PROJECT_STATISTIC_FULFILLED';
export const FETCH_PROJECT_STATISTIC_REJECTED = prefix + 'FETCH_PROJECT_STATISTIC_REJECTED';
export const FETCH_PROJECT_STATISTIC_PENDING = prefix + 'FETCH_PROJECT_STATISTIC_PENDING';

export const UPLOAD_PROJECT_ZIP = prefix + 'UPLOAD_PROJECT_ZIP';
export const UPLOAD_PROJECT_ZIP_FULFILLED = prefix + 'UPLOAD_PROJECT_ZIP_FULFILLED';
export const UPLOAD_PROJECT_ZIP_REJECTED = prefix + 'UPLOAD_PROJECT_ZIP_REJECTED';
export const UPLOAD_PROJECT_ZIP_PENDING = prefix + 'UPLOAD_PROJECT_ZIP_PENDING';

export const IMPORT_CAILA_PROJECT = prefix + 'IMPORT_CAILA_PROJECT';
export const IMPORT_CAILA_PROJECT_PENDING = prefix + 'IMPORT_CAILA_PROJECT_PENDING';
export const IMPORT_CAILA_PROJECT_FULFILLED = prefix + 'IMPORT_CAILA_PROJECT_FULFILLED';
export const IMPORT_CAILA_PROJECT_REJECTED = prefix + 'IMPORT_CAILA_PROJECT_REJECTED';

export const SET_BOT_TO_DELETE = prefix + 'SET_BOT_TO_DELETE';
export const FINAL_DELETE = prefix + 'FINAL_DELETE';

export const UPDATE_CHANNELS = prefix + 'UPDATE_CHANNELS';

export const DELETE_ACCOUNT_TAG = prefix + 'DELETE_ACCOUNT_TAG';

export const UPDATE_PROJECT_TAGS = prefix + 'UPDATE_PROJECT_TAGS';

export const DISABLE_SPELLCHECKER = prefix + 'DISABLE_SPELLCHECKER';
