import React, { FC, useEffect, useRef } from 'react';
import { Text } from 'react-konva';
import { RectWithBorder } from './RectWithBorder';
import { AutopositionGroup } from './AutopositionGroup';

import { callBlocksRedraw } from '../../utils/blockLayerUtils';
import Konva from 'konva';

export const ScreenMark: FC<{ x?: number; text: string }> = ({ x, text }) => {
  const groupRef = useRef<Konva.Group | null>(null);

  useEffect(() => {
    const textInstance = groupRef.current;
    const parent = groupRef.current?.getParent();
    if (textInstance) {
      callBlocksRedraw(textInstance, textInstance.getStage());
    }

    return () => {
      if (parent) {
        callBlocksRedraw((parent as unknown) as Konva.Group, parent.getStage());
      }
    };
  }, []);

  return (
    <AutopositionGroup xOffset={x} yOffset={8} ref={groupRef}>
      <RectWithBorder width={280 - 24} height={34} />
      <Text
        y={0}
        x={6}
        text={text}
        lineHeight={24 / 14}
        padding={6}
        verticalAlign='middle'
        fontSize={14}
        fontFamily='Roboto'
        letterSpacing={0.75}
        fill='#333C45'
      />
    </AutopositionGroup>
  );
};
