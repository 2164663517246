import React, { useMemo, useContext, useState, useCallback } from 'react';

import 'moment/locale/ru';

import localize from '../../localization';
import { Session, AnalyticsContext } from '@just-ai/analytic-front';
import { Icon, Button } from '@just-ai/just-ui';
import { hasFeatures } from 'isAccessFunction';
import history from 'appHistory';
import { LogService } from 'modules/Logs/components/LogContext/LogService';
import { GetLogModal } from 'views/Channels/GetLogModal';

const { translate } = localize;

const session = ({ location, match }: { location: Location; match: any }) => {
  return <Session location={location} match={match} translate={translate} history={history} />;
};

export default session;

export function GetLogModalWithReporter() {
  const [showModal, setShowModal] = useState(false);
  const { projectShortName, session } = useContext(AnalyticsContext);
  //@ts-ignore
  const logService = useMemo(() => new LogService(projectShortName), [projectShortName]);
  const requestLog = useCallback(
    (range: any) => {
      if (session?.sessionId) {
        logService.downloadLogsBySession(session.sessionId, range);
      }
    },
    [session, logService]
  );
  return hasFeatures('export_system_log') ? (
    <>
      <Button data-test-id='dialogLogDownload' flat color='secondary' onClick={() => setShowModal(true)}>
        <Icon name='farDownload' /> {translate('Analytics: Session Log')}
      </Button>
      {/*@ts-ignore*/}
      {showModal && <GetLogModal onAction={requestLog} toggle={() => setShowModal(false)} />}
    </>
  ) : null;
}

export function GetMessageLoglWithReporter({ questionId }: { questionId: string }) {
  const { projectShortName } = useContext(AnalyticsContext);
  //@ts-ignore
  const logService = useMemo(() => new LogService(projectShortName), [projectShortName]);
  const requestLog = useCallback(
    (range: any) => {
      logService.downloadLogsByQuestion(questionId, range);
    },
    [logService, questionId]
  );

  return hasFeatures('export_system_log') ? (
    <Button style={{ alignSelf: 'end' }} data-test-id='dialogLogDownload' flat color='secondary' onClick={requestLog}>
      {translate('Analytics: Session Log')} <Icon name='farDownload' />
    </Button>
  ) : null;
}
