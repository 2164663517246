import { mapKeys } from 'lodash';
import { commonLocalization } from './localization/common.loc';
import { analyticLocalization } from './localization/analytic.loc';
import { analyticsLocalization } from './localization/analytics.loc';
import { channeltypeLocalization } from './localization/channeltype.loc';
import { projectoverviewLocalization } from './localization/projectoverview.loc';
import { newprojectoverviewLocalization } from './localization/newprojectoverview.loc';
import { reportsLocalization } from './localization/reports.loc';
const Localize = require('localize');
require('decliner/dist/decliner.min');
const localization = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonLocalization), analyticLocalization), analyticsLocalization), channeltypeLocalization), projectoverviewLocalization), newprojectoverviewLocalization), reportsLocalization);
export default localization;
const radix = 10;
export const localize = new Localize(Object.assign({}, localization));
localize.addTranslations = translate => {
    mapKeys(translate, key => {
        // eslint-disable-next-line no-prototype-builtins
        if (!localize.getTranslations().hasOwnProperty(key)) {
            localize.loadTranslations(translate);
        }
    });
};
localize.decliner = (arr, count) => {
    return arr.decline(parseInt(count, radix));
};
localize.checkExistKey = key => {
    return key !== localize.translate(key);
};
localize.throwOnMissingTranslation(false);
