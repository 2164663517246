import React, { PureComponent } from 'react';
import { Subject, Subscription } from 'rxjs';
import { Button, Dropdown, DropdownMenu, DropdownToggle, IconButton, InputText } from '@just-ai/just-ui';
import { Group } from 'konva/lib/Group';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import store from 'store';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { saveScreenLabelAsync, deleteScreenLabelAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { LabelingService } from 'modules/JGraph/view/LabelingToolMenu/LabelingService';
import ColorsDropdown from 'modules/JGraph/view/LabelingToolMenu/components/ColorsDropdown';
import { ScreenLabel } from 'modules/JGraph/view/LabelingToolMenu/types';

import ClickAwayListener from 'components/ClickAwayListener';
import ToggleDetailViewButton from './components/ToggleDetailViewButton';
import BottomLeftTriangleWrapper from './components/BottomLeftTriangleWrapper';
import StageLabel from './components/StageLabel';

import { labelColors, LabelColor } from './colors';
import styles from './styles.module.scss';

import localize from 'localization';
import { t } from 'localization';
import { labelingLocalization } from './labeling.loc';
import { HighLightConnectorsButton } from './components/HighLightConnectorsButton';
import { Separator } from './components/Separator';
import { ToggleConnectionsButtons } from './components/ToggleConnectionsButtons';
import { CollapseBlockButton } from './components/CollapseBlockButton';

localize.addTranslations(labelingLocalization);

type TLabelingToolMenu$ = {
  isHovered?: boolean;
  forceShow?: boolean;
  screen?: JStateWithId;
  stageNode?: Group | null;
};
type TLabelChange$ = {
  screen: JStateWithId;
  label: ScreenLabel | undefined;
};

const DEFAULT_LABELING_TOOL_MENU_HEIGHT = 48;
const DEFAULT_LABELING_TOOL_MENU_WIDTH = 320;

export const LabelingToolMenu$ = new Subject<TLabelingToolMenu$ | null>();

const DEFAULT_LABEL = {
  text: '',
  color: labelColors[8],
};

interface LabelingToolMenuState {
  position: {
    top: number;
    left: number;
  };
  togglePosition: {
    top: number;
    left: number;
  };
  currentLabel: ScreenLabel;
  isNeedToShow: boolean;
  isFullViewModeOpen: boolean;
  isDropdownOpen: boolean;
  screen?: JStateWithId;
  labelName: string;
}
class LabelingToolMenu extends PureComponent<
  {
    dispatch?: typeof store.dispatch;
  },
  LabelingToolMenuState
> {
  isUserOverMenu: boolean = false;
  hasDirtyData = false;

  LabelingToolMenuSubscription?: Subscription;

  state: LabelingToolMenuState = {
    position: {
      top: 0,
      left: 0,
    },
    togglePosition: {
      top: 0,
      left: 0,
    },
    currentLabel: DEFAULT_LABEL,
    isNeedToShow: false,
    isFullViewModeOpen: false,
    isDropdownOpen: false,
    screen: undefined,
    labelName: '',
  };

  componentDidMount() {
    this.LabelingToolMenuSubscription = LabelingToolMenu$.subscribe(LabelingToolMenuPayload => {
      if (!LabelingToolMenuPayload) {
        this.resetLabelToDefault();
        return;
      }
      const { stageNode, isHovered, screen, forceShow } = LabelingToolMenuPayload;
      const screenLabelFromEvent = screen ? LabelingService.getLabelByScreen(screen) : undefined;
      const { isFullViewModeOpen, currentLabel } = this.state;
      const isHoverLost = !isHovered && !this.isUserOverMenu;
      const isLabelIsSetManually = this.isLabelIsSetManually(currentLabel);

      if (screenLabelFromEvent && forceShow) {
        isLabelIsSetManually && this.onClose();
        this.showLabelMenu(stageNode, screen, forceShow);
        return;
      }

      if (isHovered) {
        isLabelIsSetManually && this.onClose();
        this.showLabelMenu(stageNode, screen);
        return;
      }

      if (!isFullViewModeOpen && isHoverLost) {
        this.onClose();
        return;
      }
    });
  }

  isLabelIsSetManually(label: ScreenLabel) {
    return label && label !== DEFAULT_LABEL;
  }

  componentWillUnmount() {
    this.LabelingToolMenuSubscription?.unsubscribe();
  }

  showLabelMenu(stageNode?: Group | null, screen?: JStateWithId, forceShowEditLabel: boolean = false) {
    if (!screen || !stageNode) return;
    const { x, y, width } = stageNode.getClientRect();
    const absoluteScale = stageNode.getAbsoluteScale();
    this.setState({
      screen: screen,
      isNeedToShow: true,
      position: {
        top: y - 16 - DEFAULT_LABELING_TOOL_MENU_HEIGHT,
        left: x + width / 2 - DEFAULT_LABELING_TOOL_MENU_WIDTH / 2,
      },
      togglePosition: {
        top: y - 16 - DEFAULT_LABELING_TOOL_MENU_HEIGHT,
        left: x + (width - 280 * absoluteScale.x), //bookmark enlarge width of group
      },
    });
    const screenLabel = LabelingService.getLabelByScreen(screen);
    if (!screenLabel) return;
    if (!forceShowEditLabel) return;
    this.setState({
      isFullViewModeOpen: true,
      currentLabel: screenLabel,
      labelName: screenLabel.text,
    });
  }

  onClose = () => {
    if (this.hasDirtyData) this.saveCurrentLabel();
    this.resetLabelToDefault();
  };

  saveCurrentLabel() {
    const { currentLabel, labelName, screen } = this.state;
    this.props.dispatch(
      saveScreenLabelAsync({
        color: currentLabel.color.mainColor,
        text: labelName,
        targetState: screen!,
      })
    );
  }

  resetLabelToDefault() {
    this.setState({
      isNeedToShow: false,
      screen: undefined,
      labelName: '',
      currentLabel: DEFAULT_LABEL,
      isDropdownOpen: false,
      isFullViewModeOpen: false,
    });
  }

  onColorChange = (color: LabelColor) => {
    const { currentLabel, screen } = this.state;
    const newLabel = { ...currentLabel, color };
    this.setState({
      isDropdownOpen: false,
      currentLabel: newLabel,
    });
    this.isUserOverMenu = false;
    if (!screen) return;
    if (newLabel.text.length > 0) {
      this.props.dispatch(
        saveScreenLabelAsync({
          color: newLabel.color.mainColor,
          text: newLabel.text,
          targetState: screen,
        })
      );
      this.hasDirtyData = false;
    }
  };

  deleteLabel = () => {
    const { screen } = this.state;
    if (!screen) return;
    this.props.dispatch(
      deleteScreenLabelAsync({
        targetState: screen,
      })
    );
    this.setState({
      isNeedToShow: false,
      screen: undefined,
      labelName: '',
      currentLabel: DEFAULT_LABEL,
      isDropdownOpen: false,
      isFullViewModeOpen: false,
    });
    this.hasDirtyData = false;
  };

  onTextChange = (text: string) => {
    this.setState({
      labelName: text,
    });
    this.hasDirtyData = true;
    const { currentLabel } = this.state;
    const newLabel = { ...currentLabel, text };
    this.saveLabelTextDebounced(newLabel);
  };

  saveLabelTextDebounced = debounce((label: ScreenLabel) => {
    if (label.text === '') {
      this.deleteLabel();
      return;
    }
    const { screen } = this.state;
    if (!screen) return;
    this.setState({
      currentLabel: label,
    });
    this.props.dispatch(
      saveScreenLabelAsync({
        color: label.color.mainColor,
        text: label.text,
        targetState: screen,
      })
    );
    this.hasDirtyData = false;
  }, 600);

  render() {
    const {
      currentLabel,
      labelName,
      isDropdownOpen,
      isFullViewModeOpen,
      position,
      togglePosition,
      isNeedToShow,
      screen,
    } = this.state;
    if (!isNeedToShow) return null;
    if (!isFullViewModeOpen) {
      const hasLabel = screen && Boolean(LabelingService.getLabelByScreen(screen));

      return (
        <div
          className={styles.floatingMenu__toggleDetailView}
          style={{ position: 'absolute', left: togglePosition.left, top: togglePosition.top }}
          onMouseEnter={() => {
            this.isUserOverMenu = true;
          }}
          onMouseLeave={() => {
            this.isUserOverMenu = false;
            LabelingToolMenu$.next({ isHovered: false });
          }}
        >
          {!hasLabel && (
            <>
              <ToggleDetailViewButton
                data-test-id='JGraph.LabellingToolMenu.ToggleButton'
                onClick={() => this.setState({ isFullViewModeOpen: true })}
              />
              <Separator />
            </>
          )}
          {screen && (
            <>
              <HighLightConnectorsButton screenPathId={screen.pathId} />
              <Separator />
            </>
          )}
          {screen && (
            <>
              <CollapseBlockButton screenPathId={screen.pathId} screenPath={screen.path} />
              <Separator />
            </>
          )}
          {screen && <ToggleConnectionsButtons screenPath={screen.path} />}
        </div>
      );
    }

    return (
      <ClickAwayListener handleClickOut={this.onClose}>
        <div
          className={styles.floatingMenu}
          style={{
            left: position.left,
            top: position.top,
            width: DEFAULT_LABELING_TOOL_MENU_WIDTH,
            height: DEFAULT_LABELING_TOOL_MENU_HEIGHT,
          }}
        >
          <Dropdown
            direction='up'
            isOpen={isDropdownOpen}
            toggle={() => this.setState({ isDropdownOpen: !isDropdownOpen })}
          >
            <DropdownToggle tag='div' outline compact disabled>
              <DropdownMenu className={styles.floatingMenu__dropdown}>
                <ColorsDropdown colors={labelColors} selected={currentLabel.color} onSelect={this.onColorChange} />
              </DropdownMenu>
            </DropdownToggle>
            <BottomLeftTriangleWrapper>
              <Button
                data-test-id='JGraph.LabellingToolMenu.OpenColorSelector'
                className={styles.selectLabelColor}
                onClick={() => this.setState({ isDropdownOpen: !isDropdownOpen })}
                withoutPadding
                size='sm'
                flat
                color='secondary'
              >
                <StageLabel theme={currentLabel.color || labelColors[8]} />
              </Button>
            </BottomLeftTriangleWrapper>
          </Dropdown>
          <InputText
            bsSize='sm'
            data-test-id='JGraph.LabellingToolMenu.InputText'
            placeholder={t('LabelingToolMenu:Text:Placeholder')}
            className={styles.input}
            value={labelName}
            onChange={this.onTextChange}
          />
          <IconButton
            className={styles.deleteBtn}
            disabled={!labelName}
            size='sm'
            color='secondary'
            name='farTrashAlt'
            flat
            onClick={this.deleteLabel}
          />
        </div>
      </ClickAwayListener>
    );
  }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
  return { dispatch };
};
export default connect(null, mapDispatchToProps)(LabelingToolMenu);
