import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from './styles.module.scss';
const EmptyFaqContent = ({ onCreate }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: cn(styles.EmptyFaqContent), children: [_jsx("h2", { className: styles.EmptyFaqContent__title, children: t('FAQ:Home:EmptyContent:Title') }), _jsx(Button, { color: 'primary', outline: true, onClick: onCreate, children: t('FAQ:AddSkill') })] }));
};
export default React.memo(EmptyFaqContent);
