import React, { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useRightSideMenuContext } from '../../index';
import { Modal } from '@just-ai/just-ui';
import { ReactionItemProps } from '../ScreenBlocksList/ReactionItem';
import { t } from 'localization';
import { getSortableReactions } from '../../utils/common';
import { SortableReactionsContainer } from 'modules/JGraph/components/SortableBlocks';
import { useAppDispatch, useAppSelector } from '../../../../../../storeHooks';
import { setEditMenuBlock } from '../../../../../../reducers/JGraph.reducer';
import { useBlockDelete, useCommonContextPrefix } from './hooks';
import { TagNameChange } from './TagNameChange';
import { CommonOpenAddingButton } from '../../../../components/CommonOpenAddingButton';
import { arrayMove } from 'react-sortable-hoc';
import {
  resortBlocksInIfAsync,
  addNewBlockInReduxAndApi,
} from '../../../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { cloneDeep, last } from 'lodash';
import { useSavingPipe } from '../../../../hooks/savingPipe';
import { TagNames } from '../../../../utils/types';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { AddBlockButton } from 'modules/JGraph/components/AddBlockButton';
import { guideTourEvent$ } from '../../../JGraphGuideTour/guideTourEvents';

type RenderConditionContextType = {
  prefix: string;
  globalIndex?: number;
};
export const RenderConditionContext = createContext<RenderConditionContextType>({} as RenderConditionContextType);
export const useRenderConditionContext = () => useContext(RenderConditionContext);

type MainConditionContext = {
  mainIfIndex: number;
};
const MainConditionContext = createContext<MainConditionContext>({} as MainConditionContext);
export const useMainConditionContext = () => useContext(MainConditionContext);

export const ConditionSettings: FC<ReactionItemProps> = ({ block }) => {
  const { ReduxEditMenuScreen } = useAppSelector(store => ({
    ReduxEditMenuScreen: store.JGraphReducer.editMenuBlock?.screen,
  }));
  const dispatch = useAppDispatch();
  const { editMenuScreen, path, jBlockIndex, save } = useRightSideMenuContext();

  const [, mainIfIndex] = useMemo(
    () => ScreenBlockPath.getMainIfBlockAndIndex(path, jBlockIndex, ReduxEditMenuScreen?.blocks),
    [path, jBlockIndex, ReduxEditMenuScreen?.blocks]
  );
  useEffect(() => {
    if (!path) {
      dispatch(setEditMenuBlock({ screen: ReduxEditMenuScreen, jBlockIndex: undefined, path: `${mainIfIndex}_if_` }));
    }
  }, [ReduxEditMenuScreen, dispatch, editMenuScreen, mainIfIndex, path]);

  const ifChainBlocks = useMemo(
    () => ScreenBlockPath.getIfChainBlocks(mainIfIndex || 0, editMenuScreen.blocks || []),
    [editMenuScreen.blocks, mainIfIndex]
  );

  const onElseAdd = useCallback(async () => {
    await save();
    await dispatch(
      addNewBlockInReduxAndApi({
        tagName: TagNames.else,
        withoutSetEdit: true,
        insertPosition: (mainIfIndex || 0) + ifChainBlocks.length,
      })
    );
    guideTourEvent$.next(`RightSideMenu:if:onElseAdd`);
  }, [save, dispatch, mainIfIndex, ifChainBlocks.length]);

  return (
    <div className='ScreenBlocksList-inner pt-4'>
      <div className='ScreenBlocksList-sortable-container'>
        <MainConditionContext.Provider value={{ mainIfIndex: mainIfIndex || 0 }}>
          <RenderConditionContext.Provider
            value={{
              prefix: ``,
              globalIndex: mainIfIndex || 0,
            }}
          >
            {ifChainBlocks.map((ifBodyBlock, index) => (
              <ConditionsBlocks
                block={ifBodyBlock}
                blockIndex={(mainIfIndex || 0) + index}
                key={`${block?.tagName}-${index}`}
              />
            ))}
            {last(ifChainBlocks)?.tagName !== TagNames.else && (
              <AddBlockButton onClick={onElseAdd} text={t('ConditionalSettings:AddElseButton')} />
            )}
          </RenderConditionContext.Provider>
        </MainConditionContext.Provider>
      </div>
    </div>
  );
};

export const ConditionsBlocks: FC<ReactionItemProps> = ({ block, blockIndex = 0, setOpenDelete }) => {
  const dispatch = useAppDispatch();
  const { setAddingMenuType, isDirty, editMenuScreen } = useRightSideMenuContext();
  const { stateUpdate } = useSavingPipe();
  const { ContextPrefix } = useCommonContextPrefix(blockIndex, block);

  const { openDeleteIndex, setOpenDeleteIndex, setOpenDeleteHandler, deleteHandler } = useBlockDelete(ContextPrefix);

  const openAddingMenu = () => {
    if (isDirty) {
      stateUpdate(cloneDeep(editMenuScreen));
    }
    setTimeout(() => {
      setAddingMenuType('reactions', ContextPrefix);
    }, 0);
  };

  const sortableReactions = useMemo(() => getSortableReactions(block?.jblocks || [], true), [block?.jblocks]);

  const onSortReactionsEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = sortableReactions[oldIndex]?.blockIndex;
        const newGlobalIndex = sortableReactions[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(block?.jblocks || [], oldGlobalIndex, newGlobalIndex);
          dispatch(resortBlocksInIfAsync({ newBlocks, contextPrefix: ContextPrefix }));
        }
      }
    },
    [sortableReactions, block?.jblocks, dispatch, ContextPrefix]
  );

  if (!block) return null;

  return (
    <RenderConditionContext.Provider
      value={{
        prefix: ContextPrefix,
        globalIndex: blockIndex,
      }}
    >
      <TagNameChange
        tagName={block.tagName}
        tagValue={block.tagValue}
        blockIndex={blockIndex}
        setOpenDelete={setOpenDelete}
      />
      <div className='pl-5 pr-0 d-flex flex-column gap-16' style={{ borderLeft: '1px solid var(--gray-200)' }}>
        {sortableReactions.length > 0 && (
          <SortableReactionsContainer
            sortableBlocks={sortableReactions}
            setOpenDelete={setOpenDeleteHandler}
            useDragHandle
            axis='y'
            lockAxis='y'
            lockOffset={['0%', '100%']}
            onSortEnd={onSortReactionsEnd}
            lockToContainerEdges={true}
            helperClass='draggingHelper'
          />
        )}
        <CommonOpenAddingButton onClick={openAddingMenu} />
      </div>

      <Modal
        isOpen={typeof openDeleteIndex === 'number'}
        title={t(`RightSideMenu:delete_block_title`)}
        buttonSubmitColor='danger'
        buttonSubmitText={t(`RightSideMenu:delete_block_submit`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={() => setOpenDeleteIndex(undefined)}
        onActionClick={deleteHandler}
      >
        <p>{t(`RightSideMenu:delete_block_text`)}</p>
      </Modal>
    </RenderConditionContext.Provider>
  );
};

export const JoinConditionsBlock: FC<ReactionItemProps> = ({ setOpenDelete, parentBlocks, block, blockIndex = 0 }) => {
  const blocksToRender = useMemo(
    () => ScreenBlockPath.getIfChainBlocks(blockIndex || 0, parentBlocks || []),
    [parentBlocks, blockIndex]
  );
  const lastBlock = useMemo(() => last(blocksToRender), [blocksToRender]);
  const { prefix } = useRenderConditionContext();
  const { save } = useRightSideMenuContext();
  const dispatch = useAppDispatch();

  const onElseAdd = useCallback(async () => {
    await save();
    dispatch(
      addNewBlockInReduxAndApi({
        prefix,
        tagName: TagNames.else,
        withoutSetEdit: true,
        insertPosition: (blockIndex || 0) + blocksToRender.length,
      })
    );
  }, [save, dispatch, prefix, blockIndex, blocksToRender.length]);

  if ([TagNames.else, TagNames.elseif].includes(block?.tagName || TagNames.a)) return <div className='d-none' />;

  return (
    <div className='ScreenBlocksList-sortable-container'>
      <RenderConditionContext.Provider
        value={{
          prefix: prefix,
          globalIndex: blockIndex || 0,
        }}
      >
        {blocksToRender.map((ifBodyBlock, index) => (
          <ConditionsBlocks
            block={ifBodyBlock}
            blockIndex={(blockIndex || 0) + index}
            key={`${block?.tagName}-${index}`}
            setOpenDelete={setOpenDelete}
          />
        ))}
        {lastBlock && lastBlock.tagName !== TagNames.else && (
          <AddBlockButton onClick={onElseAdd} text={t('ConditionalSettings:AddElseButton')} />
        )}
      </RenderConditionContext.Provider>
    </div>
  );
};
