export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const FETCH_USERS_LIST_PENDING = 'FETCH_USERS_LIST_PENDING';
export const FETCH_USERS_LIST_REJECTED = 'FETCH_USERS_LIST_REJECTED';
export const FETCH_USERS_LIST_FULFILLED = 'FETCH_USERS_LIST_FULFILLED';

export const TOGGLE_USER_CHECKED = 'TOGGLE_USER_CHECKED';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const SET_USERS_FILTER_SUBMIT = 'SET_USERS_FILTER_SUBMIT';
export const SET_USERS_FILTER_SUBMIT_PENDING = 'SET_USERS_FILTER_SUBMIT_PENDING';
export const SET_USERS_FILTER_SUBMIT_REJECTED = 'SET_USERS_FILTER_SUBMIT_REJECTED';
export const SET_USERS_FILTER_SUBMIT_FULFILLED = 'SET_USERS_FILTER_SUBMIT_FULFILLED';

export const CANCEL_FILTER = 'CANCEL_FILTER';
export const CANCEL_FILTER_FULFILLED = 'CANCEL_FILTER_FULFILLED';
export const CANCEL_FILTER_REJECTED = 'CANCEL_FILTER_REJECTED';
export const CANCEL_FILTER_PENDING = 'CANCEL_FILTER_PENDING';
export const SET_SELECT_CHANNEL_CLIENTS = '@clientsList@SET_SELECT_CHANNEL';

export const FETCH_USERS_MESSANGER_TYPES = 'FETCH_USERS_MESSANGER_TYPES';
export const FETCH_USERS_MESSANGER_TYPES_REJECTED = 'FETCH_USERS_MESSANGER_TYPES_REJECTED';
export const FETCH_USERS_MESSANGER_TYPES_FULFILLED = 'FETCH_USERS_MESSANGER_TYPES_FULFILLED';
export const FETCH_USERS_MESSANGER_TYPES_PENDING = 'FETCH_USERS_MESSANGER_TYPES_REJECTED';
