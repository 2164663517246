import { uniq, without } from 'lodash';
import { TreeStructure } from './TreeStructure';
export class TreeSelectionLogicState {
    constructor(treeStructure) {
        this.treeStructure = treeStructure;
        this.tree = treeStructure;
    }
    getElementIdBetween(from, to, options = { needIncludeNextId: false }, resultIds = []) {
        return [...this.tree.tree.branches, ...this.tree.tree.leafs].reduce((acc, node) => this.getNodeElementIdsBetween(from, to, node, options, acc), resultIds);
    }
    calculateSelectWithShift(node, selectedIds, lastSelectedId, lastShiftSelectedIds) {
        const nodeInSelectedGroup = node.parentId && selectedIds.includes(node.parentId);
        if (nodeInSelectedGroup || lastSelectedId === node.id) {
            return {
                selectedIds,
                lastSelectedId,
                lastShiftSelectedIds,
            };
        }
        if (!lastSelectedId) {
            lastSelectedId = node.id;
            return {
                selectedIds: [node.id],
                lastSelectedId,
                lastShiftSelectedIds,
            };
        }
        const from = lastSelectedId;
        const to = node.id;
        const shiftSelectedIds = this.getElementIdBetween(from, to);
        selectedIds = uniq([...without(selectedIds, ...lastShiftSelectedIds), ...shiftSelectedIds]);
        lastShiftSelectedIds = shiftSelectedIds;
        return {
            selectedIds,
            lastSelectedId,
            lastShiftSelectedIds,
        };
    }
    calculateSelectWithCtrl(node, selectedIds, lastSelectedId) {
        const nodeInSelectedGroup = node.parentId && selectedIds.includes(node.parentId);
        if (nodeInSelectedGroup) {
            return {
                selectedIds,
                lastSelectedId,
            };
        }
        const recursiveIds = TreeStructure.getIdsInDepth(node);
        if (selectedIds.includes(node.id)) {
            selectedIds = without(selectedIds, ...recursiveIds);
        }
        else {
            selectedIds = uniq([...selectedIds, ...recursiveIds]);
        }
        const newLastSelectedId = selectedIds.length > 0 ? selectedIds[selectedIds.length - 1] : null;
        return {
            selectedIds,
            lastSelectedId: newLastSelectedId,
        };
    }
    calculateSelect(node) {
        const recursiveIds = TreeStructure.getIdsInDepth(node);
        const lastSelectedId = node.id;
        return {
            selectedIds: recursiveIds,
            lastSelectedId,
        };
    }
    getNodeElementIdsBetween(from, to, node, options = { needIncludeNextId: false }, resultIds = []) {
        const isOnRangeEdges = [from, to].includes(node.id);
        const initialNeedIncludeNextId = options.needIncludeNextId;
        options.needIncludeNextId = options.needIncludeNextId || isOnRangeEdges;
        if (node.branches && node.branches.length > 0) {
            resultIds = node.branches.reduce((acc, node) => this.getNodeElementIdsBetween(from, to, node, options, acc), resultIds);
        }
        if (node.leafs && node.leafs.length > 0) {
            resultIds = node.leafs.reduce((acc, node) => this.getNodeElementIdsBetween(from, to, node, options, acc), resultIds);
        }
        if (isOnRangeEdges || (initialNeedIncludeNextId && options.needIncludeNextId)) {
            resultIds.push(node.id);
        }
        if (isOnRangeEdges) {
            options.needIncludeNextId = !initialNeedIncludeNextId;
        }
        return resultIds;
    }
}
