export class VisualEditorSettings {
  useCodeEditorOnScreenAdd: boolean = true;
  codeEditorSize: { width: number; height: number } = {
    width: 400,
    height: 400,
  };
}

export class VisualEditorStageFileSettings {
  stageScale: number = 1;
  stagePosition = {
    x: 0,
    y: 0,
  };
}

export default class VisualLocalStorageService {
  private static VISUAL_EDITOR_SETTINGS_KEY = 'VISUAL_EDITOR_SETTINGS';

  private accountId: number;
  private projectId: string;

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
  }

  private prefixed(key: string, fileName?: string) {
    return `${key}-${this.accountId}-${this.projectId}${fileName ? '-' + fileName : ''}`;
  }

  public saveSettings(settings: VisualEditorSettings) {
    localStorage.setItem(this.prefixed(VisualLocalStorageService.VISUAL_EDITOR_SETTINGS_KEY), JSON.stringify(settings));
  }

  public getSettings() {
    const settingsJson = localStorage.getItem(this.prefixed(VisualLocalStorageService.VISUAL_EDITOR_SETTINGS_KEY));
    if (!settingsJson) return null;

    try {
      return JSON.parse(settingsJson) as VisualEditorSettings;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  public saveStageFileSettings(settings: VisualEditorStageFileSettings, fileName: string) {
    localStorage.setItem(
      this.prefixed(VisualLocalStorageService.VISUAL_EDITOR_SETTINGS_KEY, fileName),
      JSON.stringify(settings)
    );
  }
  public getStageFileSettings(fileName?: string) {
    if (fileName) {
      const settingsJson = localStorage.getItem(
        this.prefixed(VisualLocalStorageService.VISUAL_EDITOR_SETTINGS_KEY, fileName)
      );
      if (!settingsJson) return null;

      try {
        return JSON.parse(settingsJson) as VisualEditorStageFileSettings;
      } catch (error) {
        console.error(error);
      }
    }

    return null;
  }
}
