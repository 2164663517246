import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { JGraphVisualsApi, JGraphVisualsData } from '@just-ai/api/dist/generated/Editorbe';
import { axios } from 'pipes/functions';

const jGraphVisualsApi = new JGraphVisualsApi({}, '', axios);
export const getJGraphVisuals = createAsyncThunk('JGraph/getJGraphVisuals', async (noParam: void, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const { data: jgVisuals } = await jGraphVisualsApi.getJGraphVisuals(userAccountId || accountId, projectShortName, {});
  return jgVisuals;
});
export const updateJGraphVisuals = createAsyncThunk(
  'JGraph/updateJGraphVisuals',
  async (payload: { jGraphVisualsData: JGraphVisualsData; force?: boolean; options?: object }, thunkAPI) => {
    const { jGraphVisualsData, force, options } = payload;
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    const visuals = state.JGraphReducer.visuals.visualsData;

    const { data: jgVisuals } = await jGraphVisualsApi.updateJGraphVisuals(
      userAccountId || accountId,
      projectShortName,
      {
        ...visuals,
        data: jGraphVisualsData,
      },
      force,
      options
    );
    return jgVisuals;
  }
);
