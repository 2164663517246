import { useCallback, useState } from 'react';
export const useDeleteModal = (initIsOpenState = false, onDelete) => {
    const [deleteItem, setDeleteItem] = useState(undefined);
    const [isOpen, setIsOpen] = useState(initIsOpenState);
    const open = useCallback((item) => {
        setDeleteItem(item);
        setIsOpen(true);
    }, []);
    const close = useCallback(() => {
        setDeleteItem(undefined);
        setIsOpen(false);
    }, []);
    const handleDelete = useCallback(() => {
        deleteItem && onDelete(deleteItem);
        close();
    }, [close, deleteItem, onDelete]);
    return [isOpen, open, close, handleDelete, deleteItem];
};
