var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import memoize from 'memoizerific';
import { Theme } from './components';
import classNames from 'classnames';
export const getContainer = memoize(1)((dragndrop) => dragndrop
    ? props => _jsx(DndProvider, { backend: HTML5Backend, children: _jsx(DroppableContainer, Object.assign({}, props)) })
    : props => _jsx(SimpleContainer, Object.assign({}, props)));
const SimpleContainer = (_a) => {
    var { className, children, theme, style, onContextMenu } = _a, props = __rest(_a, ["className", "children", "theme", "style", "onContextMenu"]);
    return (_jsx("div", { "data-test-id": props['data-test-id'], className: classNames('justui_tree', className, { 'theme-dark': theme === Theme.dark, 'theme-light': theme === Theme.light }), style: Object.assign({ height: '100%' }, style), onContextMenu: onContextMenu, children: children }));
};
const DroppableContainer = (_a) => {
    var { className, children, theme, style, onContextMenu, onDragndrop } = _a, props = __rest(_a, ["className", "children", "theme", "style", "onContextMenu", "onDragndrop"]);
    const handleDrop = (event, monitor) => {
        if (monitor.didDrop())
            return;
        onDragndrop(event.node, null);
    };
    const [{ isOver }, drop] = useDrop({
        accept: 'node',
        drop: handleDrop,
        collect: monitor => ({ isOver: monitor.isOver({ shallow: true }) })
    });
    return (_jsx("div", { "data-test-id": props['data-test-id'], className: classNames('justui_tree', className, { 'drop-target': isOver, 'theme-dark': theme === Theme.dark, 'theme-light': theme === Theme.light }), style: Object.assign({ height: '100%' }, style), onContextMenu: onContextMenu, ref: drop, children: children }));
};
export const getInnerContainer = memoize(1)((dragndrop, onDragndrop) => dragndrop
    ? props => _jsx(DroppableInnerContainer, Object.assign({ onDragndrop: onDragndrop }, props))
    : props => _jsx(SimpleInnerContainer, Object.assign({}, props)));
const SimpleInnerContainer = ({ children, style }) => {
    return (_jsx("div", { style: style, className: 'justui_tree__subtree', children: children }));
};
const DroppableInnerContainer = ({ children, style, onDragndrop = () => { }, node }) => {
    const handleDrop = (event, monitor) => {
        if (monitor.didDrop() || !onDragndrop || !node)
            return;
        onDragndrop(event.node, node);
    };
    const [{ isOver }, drop] = useDrop({
        accept: 'node',
        drop: handleDrop,
        collect: monitor => ({ isOver: monitor.isOver({ shallow: true }) })
    });
    return (_jsx("div", { style: style, className: classNames('justui_tree__subtree', { 'drop-target': isOver }), ref: drop, children: children }));
};
