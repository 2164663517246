import React, { useEffect, useState } from 'react';

import { NewConnector } from './NewConnector';
import { Layer } from 'react-konva';
import Konva from 'konva';
import { TConnector } from '../../../reducers/JGraph.reducer/types';
import { getStageContextFromRef } from '../utils/stageUtils';
import { Subscription } from 'rxjs';
import { Connector } from './Connector';

type ConnectorLayerProps = {
  isEditModeEnable: boolean;
};
export const ConnectorLayer = React.memo(
  React.forwardRef<Konva.Layer, ConnectorLayerProps>((props, ref) => {
    const [stateConnectors, setStateConnectors] = useState<TConnector[]>([]);
    useEffect(() => {
      let sub: Subscription | null = null;
      let unusedSub: Subscription | null = null;
      if (ref) {
        //@ts-ignore - forwardedRef can be function
        const { ConnectorsStore$, connectorsFromStore$ } = getStageContextFromRef(ref);
        unusedSub = connectorsFromStore$.subscribe();
        sub = ConnectorsStore$.subscribe(connectors => {
          setStateConnectors(connectors);
        });
      }
      return () => {
        if (unusedSub) unusedSub.unsubscribe();
        if (sub) sub.unsubscribe();
      };
    }, [ref]);

    return (
      <Layer ref={ref} isLayer={true} listening={props.isEditModeEnable}>
        {stateConnectors.map(connector => (
          <Connector connector={connector} key={connector.uuid} />
        ))}
        {props.isEditModeEnable && <NewConnector />}
      </Layer>
    );
  })
);
ConnectorLayer.displayName = 'ConnectorLayer';
