import React, { useState, useCallback, useEffect } from 'react';
import { Label } from 'reactstrap';
import { InputText, Modal } from '@just-ai/just-ui';

import { t } from 'localization';

import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';

interface CreateStartStateModalInterface {
  isModalOpen: boolean;
  onCreate: (title: string) => void;
  onCancel: () => void;
}
const CreateStartStateModal = function ({ onCreate, isModalOpen, onCancel }: CreateStartStateModalInterface) {
  const [title, setTitle] = useState('');
  const { isInvalid, errorText, triggerValidation, reset } = useNewStateNameValidation();

  const onCreateInner = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      onCreate(title.trim());
    },
    [onCreate, title]
  );

  const onChange = useCallback(
    (name: string) => {
      setTitle(name);
      triggerValidation(name, '/');
    },
    [triggerValidation]
  );

  useEffect(() => {
    if (!isModalOpen) {
      reset();
      setTitle('');
    }
  }, [isModalOpen, reset]);

  return (
    <Modal
      isOpen={isModalOpen}
      title={t(`EmptyMode:CreateForm:Title`)}
      buttonSubmitText={t(`EmptyMode:CreateForm:Submit`)}
      buttonSubmitColor='primary'
      buttonCancelOutline
      disableActionButtonAutoFocus
      buttonCancelText={t('EmptyMode:CreateForm:Cancel')}
      buttonCancelColor='secondary'
      buttonSubmitDisabled={isInvalid || !title}
      onCancelClick={onCancel}
      onActionClick={onCreateInner}
      data-test-id='JGraph:NewStartNameModal'
      buttonCancelTestId='JGraph:NewStartNameModal.CancelButton'
      buttonSubmitTestId='JGraph:NewStartNameModal.SubmitButton'
    >
      <div className='flex-column' style={{ color: 'var(--grey-800)' }}>
        <Label for='stateName'>{t('EmptyMode:CreateForm:StartName')}</Label>
        <InputText
          id='stateName'
          bsSize='default'
          errorText={errorText}
          value={title}
          data-test-id='JGraph:Fields:NewStartName'
          placeholder={t('EmptyMode:CreateForm:StartName:Placeholder')}
          onChange={onChange}
        />
      </div>
      <div className='margin-top-4x'>{t('EmptyMode:CreateForm:Description')}</div>
    </Modal>
  );
};

export default React.memo(CreateStartStateModal);
