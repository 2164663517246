import {
  LIST_SIP_TRUNK_FULFILLED,
  AVAILABLE_CODECS_FULFILLED,
  CREATE_SIP_TRUNK_FULFILLED,
  LIST_SIP_TRUNK_PENDING,
  CREATE_SIP_TRUNK_PENDING,
  AVAILABLE_CODECS_PENDING,
  READ_SIP_TRUNK_FULFILLED,
  READ_SIP_TRUNK_PENDING,
  UNSET_EDITABLE_SIP_TRUNK,
  EDIT_SIP_TRUNK_PENDING,
  EDIT_SIP_TRUNK_FULFILLED,
  DELETE_SIP_TRUNK_PENDING,
  DELETE_SIP_TRUNK_FULFILLED,
  CREATE_SIP_TRUNK_REJECTED,
  DELETE_SIP_TRUNK_REJECTED,
  EDIT_SIP_TRUNK_REJECTED,
} from '../../constants/sipTrunkList.actions';

const InitialState = {
  sipTrunkList: [],
  availableCodecs: [],
  fetching: false,
  readFetching: false,
  editableSipTrunk: null,
  error: {
    key: '',
    args: {
      channels: [],
      field: '',
    },
    uuid: '',
  },
};

export default function sipTrunkListReducer(state = InitialState, action) {
  switch (action.type) {
    case LIST_SIP_TRUNK_PENDING:
    case DELETE_SIP_TRUNK_PENDING:
    case CREATE_SIP_TRUNK_PENDING:
    case EDIT_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetching: true,
        error: {
          key: '',
          args: {
            channels: [],
            field: '',
          },
          uuid: '',
        },
      };
    }

    case LIST_SIP_TRUNK_FULFILLED:
      return {
        ...state,
        sipTrunkList: action.payload.data,
        fetching: false,
      };

    case AVAILABLE_CODECS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case AVAILABLE_CODECS_FULFILLED: {
      return {
        ...state,
        availableCodecs: action.payload.data,
        fetching: false,
        error: {
          key: '',
          args: {
            channels: [],
            field: '',
          },
          uuid: '',
        },
      };
    }

    case CREATE_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: {
          key: action.payload.response.data.error,
          message: action.payload.response.data.message,
          uuid: action.payload.response.data.uuid,
          args: action.payload.response.data.args,
        },
      };
    }

    case CREATE_SIP_TRUNK_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case READ_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case READ_SIP_TRUNK_FULFILLED: {
      return {
        ...state,
        editableSipTrunk: action.payload.data,
        fetching: false,
      };
    }

    case EDIT_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: {
          key: action.payload.response.data.error,
          uuid: action.payload.response.data.uuid,
          message: action.payload.response.data.message,
          args: action.payload.response.data.args,
        },
      };
    }

    case EDIT_SIP_TRUNK_FULFILLED: {
      return {
        ...state,
        fetching: false,
        error: {
          key: '',
          args: {
            channels: [],
            field: '',
          },
          uuid: '',
        },
      };
    }

    case UNSET_EDITABLE_SIP_TRUNK: {
      return {
        ...InitialState,
      };
    }

    case DELETE_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: {
          key: action.payload.response.data.error,
          message: action.payload.response.data.message,
          args: action.payload.response.data.args,
        },
      };
    }

    case DELETE_SIP_TRUNK_FULFILLED: {
      let sipTrunkList = state.sipTrunkList;
      let index = sipTrunkList.findIndex(trunk => trunk.id === action.payload.data.id);
      sipTrunkList.splice(index, 1);
      return {
        ...state,
        fetching: false,
        error: {
          key: '',
          args: {
            channels: [],
            field: '',
          },
        },
        sipTrunkList: sipTrunkList,
      };
    }

    default:
      return state;
  }
}
