import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { TagNames } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { addNewBlockInState, restoreState, setEditMenuBlock } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId, findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { ScreenBlockPath } from '../ScreenBlockPath';

export type AddNewBlockPayload = {
  tagName: TagNames;
  prefix?: string;
  insertPosition?: number;
  withoutSetEdit?: boolean;
};

const addNewBlockInReduxAndApi = createAsyncThunk(
  'JGraph/addNewBlockInReduxAndApi',
  async (payload: AddNewBlockPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    thunkAPI.dispatch(addNewBlockInState(payload));
    let newBlocks = [...state.JGraphReducer.graph.blocks];
    if (!state.JGraphReducer.editMenuBlock?.screen) return;
    const editBlock = findScreenByPathId(state.JGraphReducer.editMenuBlock!.screen.pathId, newBlocks);
    if (!editBlock) return;

    mainSave$.next({
      type: 'update',
      path: editBlock.path,
      action: () => thunkAPI.dispatch(updateState(editBlock)),
    });
    return { affectedScreen: editBlock };
  }
);

export const revertAddNewBlockInReduxAndApi = createAsyncThunk(
  'JGraph/revertAddNewBlockInReduxAndApi',
  async (revertEvent: RevertEvent<AddNewBlockPayload, { affectedScreen: JStateWithId }>, thunkAPI) => {
    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevScreen = findScreenByPath(revertEvent.result.affectedScreen.path, prevScreens);
    if (!prevScreen) return;

    await Promise.resolve(thunkAPI.dispatch(restoreState({ screen: prevScreen })));
    const state = thunkAPI.getState() as RootState;
    let editMenuBlock = state.JGraphReducer.editMenuBlock;
    let jBlockIndex = state.JGraphReducer.editMenuBlock?.jBlockIndex;
    if (editMenuBlock) {
      if (
        editMenuBlock.path &&
        ScreenBlockPath.isPathRecursive(editMenuBlock.path) &&
        !ScreenBlockPath.isRecursivePathStillExists(editMenuBlock.screen, editMenuBlock.path)
      ) {
        jBlockIndex = undefined;
      }
      if (!editMenuBlock.path && jBlockIndex) {
        jBlockIndex = !!editMenuBlock.screen.blocks[jBlockIndex] ? jBlockIndex : undefined;
      }
    }
    thunkAPI.dispatch(setEditMenuBlock({ screen: prevScreen, jBlockIndex: jBlockIndex, path: undefined }));

    mainSave$.next({
      type: 'update',
      path: prevScreen.path,
      action: () => thunkAPI.dispatch(updateState(prevScreen)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertAddNewBlockInReduxAndApi, addNewBlockInReduxAndApi);
