export function getRandomString(quant = 16) {
  let result = '';
  let words = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM~!@#$%^&*()_+-№"';
  let max_position = words.length - 1;
  for (let i = 0; i < quant; i += 1) {
    let position = Math.floor(Math.random() * max_position);
    result += words.substring(position, position + 1);
  }
  return result;
}
