import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useCallback, useMemo, useRef } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsContext';
import { IconButton } from '@just-ai/just-ui';
import moment from 'moment';
export default function SessionInfo({ t }) {
    const { session } = useContext(AnalyticsContext);
    const sessionRef = useRef(null);
    const clientRef = useRef(null);
    const { sessionId, client, botId, duration: rawDuration, startTime } = session || {};
    const duration = useMemo(() => {
        const duration = moment(new Date(rawDuration));
        return ['years', 'days', 'hours', 'minutes', 'seconds'].reduce((result, x) => {
            //@ts-ignore
            const diff = duration.diff(moment(0), x);
            if (diff > 0) {
                //@ts-ignore
                duration.subtract(diff, x);
                return result + `${diff} ${t(x, diff)} `;
            }
            else {
                return result;
            }
        }, '');
    }, [rawDuration]);
    const copySession = useCallback(() => {
        var _a, _b;
        let supported = document.queryCommandSupported('copy');
        if ((sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) && supported) {
            (_a = sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) === null || _a === void 0 ? void 0 : _a.select();
            (_b = sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            window.document.execCommand('copy');
        }
    }, []);
    const copyClient = useCallback(() => {
        var _a, _b;
        let supported = document.queryCommandSupported('copy');
        if ((clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) && supported) {
            (_a = clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) === null || _a === void 0 ? void 0 : _a.select();
            (_b = clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            window.document.execCommand('copy');
        }
    }, []);
    return (_jsx("div", { className: 'Session_SessionInfo_container', children: session && (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'Session_SessionInfo_sessionId_clientId', children: [sessionId && (_jsxs("div", { children: [_jsx("div", { children: t('Analytics: sessionId label') }), _jsxs("div", { title: sessionId, children: [sessionId, _jsx("input", { type: 'text', value: sessionId, ref: ref => (sessionRef.current = ref), className: 'hiddenInput' })] }), _jsx(IconButton, { name: 'farCopy', onClick: copySession, withoutPadding: true })] })), (client === null || client === void 0 ? void 0 : client.id) && (_jsxs("div", { children: [_jsx("div", { children: t('Analytics: clientId label') }), _jsxs("div", { className: 'Session_SessionInfo_clientId', title: client.id, children: [client.id, _jsx("input", { type: 'text', value: client.id, ref: ref => (clientRef.current = ref), className: 'hiddenInput' })] }), _jsx(IconButton, { name: 'farCopy', onClick: copyClient, withoutPadding: true })] }))] }), botId && (_jsxs("div", { className: 'Session_SessionInfo_botId', children: [_jsx("div", { children: t('Analytics: botId label') }), _jsx("div", { title: botId, children: botId })] })), _jsxs("div", { className: 'Session_SessionInfo_time', children: [_jsxs("div", { children: [_jsx("div", { children: t('Analytics: sessionsTable head startTime') }), _jsx("div", { children: moment(startTime).format('LLL') })] }), _jsxs("div", { children: [_jsx("div", { children: t('Analytics: sessionsTable head duration') }), _jsx("div", { children: duration })] })] })] })) }));
}
