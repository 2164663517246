import { LogLevel } from '../index';
var ConsoleLogTransport = /** @class */ (function () {
    function ConsoleLogTransport(level) {
        this.level = level;
    }
    ConsoleLogTransport.prototype.log = function (event, context) {
        switch (event.level) {
            case LogLevel.FATAl:
            case LogLevel.ERROR:
                console.error(event.message);
                if (event.exception)
                    console.error('Exception:', event.exception);
                if (context)
                    console.error('Context:', context);
                break;
            case LogLevel.WARN:
                console.warn(event.message);
                if (context)
                    console.warn('Context:', context);
                break;
            case LogLevel.LOG:
                console.log(event.message);
                if (context)
                    console.log('Context:', context);
                break;
            case LogLevel.INFO:
                console.info(event.message);
                if (context)
                    console.info('Context:', context);
                break;
            case LogLevel.DEBUG:
                console.debug(event.message);
                if (context)
                    console.debug('Context:', context);
                break;
            default:
                break;
        }
    };
    return ConsoleLogTransport;
}());
export { ConsoleLogTransport };
