import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { InputText, Label, Spinner } from '@just-ai/just-ui';

import type { ResultInterface } from '@just-ai/just-ui/dist/utils/usePromiseProcessing';
import { IntentDetailNewItem } from './IntentDetailNewItem';
import { getPureDisplayValue } from './utils';
import { t } from 'localization';

import cn from 'classnames';

import classes from './styles.module.scss';
import {
  CustomIntentItem,
  RowComponent,
  RowComponentPayload,
} from '../../../../../../Caila/pages/IntentsPage/IntentItemsEdit';

import TableWithVirtualList from '../../../../../../../components/TableWithVirtualList';
import { ShowFullPath } from './ShowFullPath';
import { IntentItem } from '../../../../../../Caila/model';
import { IntentWithItems } from './types';
import DeleteItemsAction from 'components/TableWithVirtualList/selectedItemActions/DeleteItemsAction';

type IntentDetailProps = {
  saveStatus: string;
  intentEditError: string;
  intentEditErrorArgs: any;
  complexErrors: {
    isPathInvalid: boolean;
    duplicateSlotNames: string[];
    emptySlotNames: string[];
    isIntentValid: boolean;
  };
  intentDetail: IntentWithItems;
  intentRequestState: ResultInterface<any>;

  clearValError: () => unknown;
  changePath: (value: string) => unknown;
  handleAddPhrases: (newPhrasesTexts: IntentItem[], softValidate?: boolean) => Promise<unknown>;
  onDeleteItem: (indexes: number[]) => unknown;
  handleChangeItemText: (
    updateData: CustomIntentItem | CustomIntentItem[],
    index?: number,
    softValidate?: boolean
  ) => unknown;
  payloadMemo: {
    openedItem: number;
    setOpenedItem: () => unknown;
    onDelete: IntentDetailProps['onDeleteItem'];
    editDisabled: boolean;
  };
};

export const IntentDetail: FC<IntentDetailProps> = ({
  saveStatus,
  clearValError,
  complexErrors,
  intentEditError,
  intentEditErrorArgs,
  intentDetail,
  intentRequestState,
  changePath,
  handleAddPhrases,
  onDeleteItem,
  payloadMemo,
  handleChangeItemText,
}) => {
  const [intentPath, setIntentPath] = useState(getPureDisplayValue(intentDetail.intent.path));
  useEffect(() => {
    setIntentPath(getPureDisplayValue(intentDetail.intent.path));
  }, [intentDetail.intent.path]);
  const isShowFullPath = useMemo(() => {
    const splittedPath = intentDetail.intent?.path?.split('/');
    return (splittedPath?.length && splittedPath?.length > 2) || false;
  }, [intentDetail.intent?.path]);

  const changePathHandler = useCallback(
    (value: string) => {
      if (value.includes('/')) return;
      setIntentPath(value);
      changePath(value);
    },
    [changePath]
  );

  if (intentRequestState.loading) return <Spinner />;

  return (
    <div className={cn(classes.IntentDetail)}>
      <h3 className='d-flex flex-row color-gray-800 margin-bottom-8 justify-content-between'>
        {t('CailaIntentsSimple:IntentDetail:title')}
        <small className='color-gray-600'>{saveStatus}</small>
      </h3>
      <div className={cn('margin-bottom-16', classes.IntentDetail_card)}>
        <Label>{t('CailaIntentsSimple:IntentDetail:Name')}</Label>
        <InputText
          autoFocus
          fullWidth
          value={intentPath}
          onChange={changePathHandler}
          invalid={complexErrors.isPathInvalid}
          errorText={intentEditError !== 'validation' ? intentEditError : ''}
        />
        {isShowFullPath && <ShowFullPath path={intentDetail.intent?.path || ''} />}
      </div>
      <h3 className='color-gray-800 margin-bottom-8'>{t('CailaIntentsSimple:IntentDetail:TrainingPhrases:Title')}</h3>
      <div className={cn(classes.IntentDetail_card)}>
        <Label className='d-block margin-bottom-2'>{t('CailaIntentsSimple:IntentDetail:NewPhrase:Title')}</Label>
        <small className='d-block margin-bottom-8 color-gray-600'>
          {t('CailaIntentsSimple:IntentDetail:NewPhrase:subtext')}
        </small>
        <IntentDetailNewItem
          onAddPhrases={handleAddPhrases}
          clearValError={clearValError}
          valErrorData={intentEditErrorArgs?.phrases}
        />

        {intentDetail.items.length ? (
          <>
            <div className='d-block margin-top-16'>
              {t('CailaIntentsSimple:IntentDetail:AdditionalPhrases')}
              <span className='color-gray-600 margin-left-4'>({intentDetail.items.length})</span>
            </div>
            <div className='intents-item-edit__items-table'>
              <TableWithVirtualList<CustomIntentItem, RowComponentPayload>
                data={[...intentDetail.items].reverse()}
                selectedLabel={t('IntentItemsEdit:selectedLabel')}
                discardLabel={t('IntentItemsEdit:discardLabel')}
                onChange={handleChangeItemText}
                rowComponentPayload={payloadMemo}
                RowComponent={RowComponent}
                data-test-id='IntentsPage.PhrasesEdit.Table'
                tableName='intents-records-table'
                seacrhButtonColor='secondary'
                maxTableHeight={750}
                withVirtualList
                renderSelectionActions={({ selectedIndexes, resetSelection }) => (
                  <DeleteItemsAction
                    onClick={() => {
                      onDeleteItem(selectedIndexes);
                      resetSelection();
                    }}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              color: 'var(--grey-500)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ marginTop: 20 }}>{t('IntentItemsEdit:emptyList')}</span>
          </div>
        )}
      </div>
    </div>
  );
};
