import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { ProjectApiService } from '../utils';

const getCustomTags = createAsyncThunk(
  'JGraph/listCustomTags',
  async (listCustomTagsParams: { options?: any }, thunkAPI) => {
    const { options = {} } = listCustomTagsParams;
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const { data: listCustomTags } = await ProjectApiService.listCustomTags(
      userAccountId || accountId,
      projectShortName,
      options
    );
    return listCustomTags;
  }
);

export default getCustomTags;
