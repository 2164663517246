import { useCallback, useEffect, useRef, useState } from 'react';

export default function useStateWithCleanUp<TYPE = null>(
  initialValue: TYPE,
  lifeTime = 1000
): [TYPE | null, (val: TYPE) => void] {
  const [val, setValInner] = useState<TYPE | null>(initialValue);
  const timeoutId = useRef<NodeJS.Timeout>();
  const setVal = useCallback(
    (value: TYPE) => {
      setValInner(value);
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        setValInner(null);
      }, lifeTime);
    },
    [lifeTime]
  );

  useEffect(
    () => () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    },
    []
  );
  return [val, setVal];
}
