import moment from 'moment';
import axios from 'axios';
import queryString from 'query-string';
import { pick, isArray } from 'lodash';
import { analyticsArrayFilter } from './constants';
import { getUserLanguage } from './pureFunctions';
export * from './pureFunctions';
export * from './constants';
export function formatDate(date, format = 'DD.MM.YYYY HH:mm:ss') {
    return moment(date).format(format);
}
const timezone = new Date().getTimezoneOffset();
const headers = {
    language: getUserLanguage().substring(0, 2).toUpperCase(),
    Product: 'botadmin',
    Timezone: timezone,
};
let axiosWithSessionCheck = axios.create({
    headers,
    validateStatus: status => {
        if (status === 401) {
            return false;
        }
        else if (status === 403) {
            return false;
        }
        else if (status === 503) {
        }
        return status >= 200 && status < 300;
    },
});
//@ts-ignore
axiosWithSessionCheck._get = (url, options) => {
    return new Promise((resolve, reject) => {
        axiosWithSessionCheck
            .get(url, options)
            .then(response => {
            response.config = Object.assign(Object.assign({}, response.config), options);
            resolve(response);
        })
            .catch(reject);
    });
};
const postPutDeleteInterceptor = (method) => (url, data, config) => {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        axiosWithSessionCheck[method](url, data, config)
            //@ts-ignore
            .then(response => {
            response.config = Object.assign(Object.assign({}, response.config), config);
            resolve(response);
        })
            .catch(reject);
    });
};
//@ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
//@ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
//@ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');
//@ts-ignore
export function setAxiosHeadersLanguage(val) {
    axiosWithSessionCheck.defaults.headers.language = val;
}
axiosWithSessionCheck.interceptors.response.use(response => {
    var _a, _b;
    const { data, config } = response;
    if (typeof data === 'string' && data.endsWith('</html>')) {
        console.log('%cBACKEND / NGINX ERROR!', 'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;');
        console.log(`%crequest ${(_a = config === null || config === void 0 ? void 0 : config.method) === null || _a === void 0 ? void 0 : _a.toUpperCase()} to ${config.url} returns html`, 'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: #000 1px 1px;');
        return Promise.reject(`BACKEND/NGINX ERROR: request ${(_b = config === null || config === void 0 ? void 0 : config.method) === null || _b === void 0 ? void 0 : _b.toUpperCase()} to ${config.url} returns html`);
    }
    return response;
});
//@ts-ignore
export function setAxiosHeadersAcessToken(val) {
    axiosWithSessionCheck.defaults.headers['ACCESS-TOKEN'] = val;
}
//@ts-ignore
export function setAxiosHeadersTarget(val) {
    try {
        axiosWithSessionCheck.defaults.headers.referrer = val;
        axios.defaults.headers.referrer = val;
        //@ts-ignore
        headers.referrer = val;
    }
    catch (e) { }
}
export { axiosWithSessionCheck as axios };
export const getFilterFromLocation = (search = '', array = analyticsArrayFilter) => {
    let parsedLocationSearch = queryString.parse(search);
    let filterParams = pick(parsedLocationSearch, array);
    if (filterParams.hasOwnProperty('dateFrom')) {
        // @ts-ignore
        filterParams.dateFrom = moment(filterParams.dateFrom, 'x');
    }
    if (filterParams.hasOwnProperty('dateTo')) {
        // @ts-ignore
        filterParams.dateTo = moment(filterParams.dateTo, 'x');
    }
    return filterParams;
};
export const parseErrors = (response) => {
    let errors;
    //todo check response status, don't save error with 401 status
    if (response && response.status !== 401) {
        if (response.data.hasOwnProperty('errors')) {
            errors = response.data.errors;
        }
        else if (response.data.hasOwnProperty('error')) {
            errors = [response.data.error];
        }
        else if (isArray(response.data)) {
            errors = response.data;
        }
        else {
            errors = [response.data];
        }
    }
    else {
        errors = [];
    }
    return errors;
};
export const getCurrentDate = () => {
    let defaultDateFrom = new Date();
    let defaultDateTo = new Date();
    defaultDateFrom.setDate(defaultDateFrom.getDate() - 1);
    return [moment(defaultDateFrom, 'DD.MM.YYYY HH:mm:ss'), moment(defaultDateTo, 'DD.MM.YYYY HH:mm:ss')];
};
export const setDefaultTimezone = (str) => {
    moment.tz.setDefault(str);
};
export const withOverflowDots = (text, limit) => {
    if (text.length <= limit)
        return text;
    return text.slice(0, limit) + '...';
};
