import React from 'react';
import { IconButton } from '@just-ai/just-ui';
import '../styles.scss';

interface NotificationLogProps {
  message: string;
  copy?: () => void;
  copyTestId?: string;
}
const NotificationLog: React.FC<NotificationLogProps> = ({ message, copy, copyTestId }) => (
  <div className='position-relative'>
    {copy && (
      <IconButton
        wrapperIconClassName='d-flex'
        className='notifications__logs_button'
        name='farCopy'
        size='sm'
        flat
        onClick={copy}
        data-test-id={copyTestId}
      />
    )}
    <pre className='notifications__logs'>{message}</pre>
  </div>
);

export default React.memo(NotificationLog);
