import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
export const toTabId = (id) => `tabs_nav_item_${id.toString().trim().replace(/ /g, '_')}`;
export const Tab = React.forwardRef(({ keyId, width, closable, isActive, closePlace = 'right', onClick, onClose, disabled = false, item, className, tabIndex, tooltip, tooltipDir = 'bottom', onContextMenu, }, ref) => {
    const textContainerRef = useRef(null);
    const [overflowing, setOverflowing] = useState(false);
    const showTooltip = tooltip === 'always' || (tooltip === 'onOverflow' && overflowing);
    const checkOverflow = useCallback(() => {
        if (!textContainerRef.current)
            return;
        const compare = textContainerRef.current.scrollWidth > textContainerRef.current.clientWidth;
        setOverflowing(compare);
    }, []);
    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [checkOverflow]);
    return (_jsxs(NavItem, { id: toTabId(keyId), onContextMenu: onContextMenu, style: { width }, tabIndex: tabIndex, className: classNames('justui__tab', { active: isActive }, { disabled: disabled }, className), children: [_jsxs(NavLink, { className: classNames({ active: isActive }), onClick: onClick, innerRef: ref, "data-test-id": item.dataTestId, disabled: disabled, children: [closable && closePlace === 'left' ? (_jsx("div", { className: 'icon-box', onClick: onClose, children: _jsx(Icon, { name: 'faTimes', size: 'sm', className: 'close-icon left-icon' }) })) : null, item.error ? _jsx(Icon, { color: 'danger', name: 'faExclamationCircle', size: 'sm', className: 'left-icon' }) : null, typeof item.name === 'string' ? (_jsx("span", { ref: textContainerRef, className: 'justui__tab-name', children: item.name })) : (_jsx(_Fragment, { children: item.name })), closable && closePlace === 'right' ? (_jsx("div", { onClick: onClose, className: 'icon-box', children: _jsx(Icon, { name: 'faTimes', size: 'sm', className: 'close-icon right-icon' }) })) : null] }), showTooltip && textContainerRef.current && (_jsx(Tooltip, { target: textContainerRef.current, placement: tooltipDir, children: item.name }))] }, toTabId(keyId)));
});
