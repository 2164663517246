import {
  LOAD_CLIENTS_LOG_FULFILLED,
  LOAD_CLIENTS_LOG_PENDING,
  SET_CURRENT_CLIENT_FULFILLED,
  TOGGLE_MODAL,
  TOGGLE_BLOCK_CLIENT_FULFILLED,
  SET_CURRENT_CLIENT_PENDING,
} from '../../constants/currentClient.actions';
import reverse from 'lodash/reverse';

const InitialState = {
  currentClient: {},
  logs: {},
  logsPending: false,
  modal: false,
  needUpdate: false,
  fetchingCurrentClient: false,
};

export default function CurrentClientReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLIENT_PENDING: {
      return {
        ...state,
        fetchingCurrentClient: true,
      };
    }
    case SET_CURRENT_CLIENT_FULFILLED: {
      reverse(action.payload.data.logs.content);
      return {
        ...state,
        currentClient: action.payload.data.client,
        logs: action.payload.data.logs,
        needUpdate: false,
        fetchingCurrentClient: false,
      };
    }
    case TOGGLE_BLOCK_CLIENT_FULFILLED: {
      return {
        ...state,
        needUpdate: true,
      };
    }
    case LOAD_CLIENTS_LOG_PENDING: {
      return {
        ...state,
        logsPending: true,
      };
    }
    case LOAD_CLIENTS_LOG_FULFILLED: {
      const logs = { ...state.logs };
      reverse(action.payload.data.logs.content);
      const logsContent = action.payload.data.logs.content.concat(logs.content);
      const logsPage = action.payload.data.logs.number;
      return {
        ...state,
        logsPending: false,
        logs: {
          ...logs,
          content: logsContent,
          number: logsPage,
        },
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }
    default:
      return state;
  }
}
