import React, { createContext, useContext } from 'react';
import webstomp, { Client, Frame } from 'webstomp-client';
import SockJS from 'sockjs-client';
import { AppContext, AppContextType } from 'modules/Caila/components/AppContext';

const RECONNECT_TIMEOUT = 5000;
const MAX_ATTEMPTS: number = 3; //0 - unlimited

export type SubscriberCallback = (body: any, frame?: any) => void;
interface Subscribers {
  [key: string]: {
    [key: string]: SubscriberCallback;
  };
}

interface ConnectedPathId {
  [key: string]: string;
}

interface QueueSubscribers {
  path: string;
  callback: SubscriberCallback;
  id: string;
}

interface QueueMessage {
  path: string;
  message: any;
}

export type WSContextType = {
  send: (path: string, message?: any) => void;
  subscribe: (path: string, callback: SubscriberCallback) => string;
  unsubscribe: (path: string, id: string) => void;
};

const WSContext = createContext({} as WSContextType);

class WSContextProvider extends React.Component {
  static contextType = AppContext;
  context!: AppContextType;

  stompClient?: Client;
  listeners: ((message: any) => void)[] = [];
  subscribers: Subscribers = {};
  reconnectTimerId?: NodeJS.Timeout = undefined;
  queueSubscribers: QueueSubscribers[] = [];
  connectedPathsId: ConnectedPathId = {};
  queueMessages: QueueMessage[] = [];
  attempt: number = 0;
  connectingToUniqUserId = '';
  connectedUniqUserId = '';

  getCurrentUserUniqId() {
    if (!this.context.userId && this.context.accountId) return '';
    return `${this.context.userId}:${this.context.accountId}`;
  }

  componentDidMount() {
    if (!this.context.userId) return;
    this.connect();
  }

  componentDidUpdate() {
    const userUniqId = this.getCurrentUserUniqId();
    if (!this.context.userId || userUniqId === this.connectedUniqUserId || userUniqId === this.connectingToUniqUserId)
      return;
    this.attempt = 0;
    this.connect();
  }

  componentWillUnmount() {
    if (this.stompClient?.connected) this.stompClient.disconnect();
    if (this.reconnectTimerId) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = undefined;
    }
  }

  getUrlForWS = () => {
    const url = `${window.location.protocol}//${window.location.host}`;
    return `${url}/api/signal/websocket`;
  };

  convertSubscribersToQueue = (subscribers: Subscribers) => {
    const queue: QueueSubscribers[] = [];
    Object.keys(subscribers).forEach(path => {
      Object.keys(subscribers[path]).forEach(id => {
        queue.push({ path, callback: subscribers[path][id], id });
      });
    });
    return queue;
  };

  onConnect = () => {
    this.connectedUniqUserId = this.connectingToUniqUserId;
    this.queueSubscribers = [...this.queueSubscribers, ...this.convertSubscribersToQueue(this.subscribers)];
    this.connectedPathsId = {};
    this.subscribers = {};

    const subscribersLength = this.queueSubscribers.length;
    for (let i = 0; i < subscribersLength; i++) {
      const subscriber = this.queueSubscribers.pop();
      subscriber && this.subscribe(subscriber.path, subscriber.callback, subscriber.id);
    }

    const messagesLength = this.queueMessages.length;
    for (let i = 0; i < messagesLength; i++) {
      const msg = this.queueMessages.pop();
      msg && this.send(msg.path, msg.message);
    }
  };

  onError = (error: Frame | CloseEvent) => {
    console.error('WS STOMP CONNECTION FAIL', error);
    this.attemptConnectAfterTimeout();
  };

  disconnect = () => {
    this.stompClient?.disconnect();
    this.connectedUniqUserId = '';
  };

  connect = () => {
    if (process.env.REACT_APP_ENABLE_WS_PROXY !== 'true') return;
    this.attempt++;
    if (this.stompClient?.connected) this.disconnect();
    this.connectingToUniqUserId = this.getCurrentUserUniqId();
    try {
      const ws = new SockJS(this.getUrlForWS());
      ws.onclose = () => this.attemptConnectAfterTimeout();
      this.stompClient = webstomp.over(ws, { debug: false });

      this.stompClient.connect(
        {},
        () => this.onConnect(),
        message => this.onError(message)
      );
    } catch (error) {
      console.error('WS CONNECT ERROR', error);
      this.attemptConnectAfterTimeout();
    }
  };

  attemptConnectAfterTimeout = () => {
    if (MAX_ATTEMPTS !== 0 && this.attempt >= MAX_ATTEMPTS) {
      console.error(`WS RECONNECT LIMIT REACHED: ${MAX_ATTEMPTS}`);
      return;
    }
    this.reconnectTimerId = setTimeout(() => this.connect(), RECONNECT_TIMEOUT);
  };

  send = (path: string, message?: any) => {
    if (this.stompClient?.connected) {
      this.stompClient.send(path, JSON.stringify(message));
    } else {
      this.queueMessages.push({ path, message });
    }
  };

  subscribe = (path: string, callback: SubscriberCallback, oldId?: string) => {
    const id = oldId || String(performance.now());
    if (!this.stompClient?.connected) {
      this.queueSubscribers.push({
        path,
        callback,
        id,
      });
      return id;
    }

    if (Object.keys(this.subscribers[path] || {}).length) {
      this.subscribers[path][id] = callback;
      return id;
    }
    this.subscribers[path] = {};
    this.subscribers[path][id] = callback;

    if (!this.connectedPathsId[path]) {
      this.connectedPathsId[path] = this.stompClient.subscribe(path, frame => {
        let body = frame && frame.body;
        try {
          body = JSON.parse(body);
        } catch (e) {
          console.error('WS PARSE BODY ERROR', e);
        }
        Object.keys(this.subscribers[path]).forEach(id => this.subscribers[path][id](body, frame));
      }).id;
    }

    return id;
  };

  unsubscribe = (path: string, id: string) => {
    if (!this.stompClient?.connected) {
      const deletedIndex = this.queueSubscribers.findIndex(s => s.path === path && s.id === id);
      if (deletedIndex !== -1) {
        this.queueSubscribers.splice(deletedIndex, 1);
      }
      return;
    }
    if (this.subscribers[path]?.[id]) {
      delete this.subscribers[path][id];
    }
  };

  render() {
    return (
      <WSContext.Provider
        value={{
          send: this.send,
          subscribe: this.subscribe,
          unsubscribe: this.unsubscribe,
        }}
      >
        {this.props.children}
      </WSContext.Provider>
    );
  }
}

const useWSContext = () => useContext(WSContext);

export { WSContext, WSContextProvider, useWSContext };
