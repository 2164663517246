import React, { FC, useCallback } from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import classes from './index.module.scss';

export const AddBlockButton: FC<{ onClick: () => unknown; text: string; disabled?: boolean }> = React.memo(
  ({ onClick, disabled, text }) => {
    const onClickHandler = useCallback(
      e => {
        e.stopPropagation();
        onClick();
      },
      [onClick]
    );
    return (
      <Button
        disabled={disabled}
        className={classes.AddBlockButton}
        color='secondary'
        outline
        onClick={onClickHandler}
        data-test-id='JGraph.RightSideMenu.Buttons.addButton'
      >
        <Icon name='farPlus' color='secondary' />
        {text}
      </Button>
    );
  }
);
AddBlockButton.displayName = 'React.memo(AddBlockButton)';
