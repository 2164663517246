import { IntentItemType } from '../../../../nlu-related/utils/intents';
import { Intents } from '../../../../nlu-related/utils';
export const generateUniqId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};
export const toggleIntentItemType = (item) => (Object.assign(Object.assign({}, item), { type: item.type === IntentItemType.pattern ? IntentItemType.phrase : IntentItemType.pattern }));
export const toIntentPhrase = (text, type) => ({
    id: generateUniqId(),
    type: type,
    text: text,
});
export const phraseToIntentItem = (phrase) => {
    return toIntentPhrase(phrase.text || '', IntentItemType.phrase);
};
export const patternToIntentItem = (pattern) => {
    return toIntentPhrase(pattern || '', IntentItemType.pattern);
};
export const convertToIntentPhrases = (intent) => {
    const intentItems = [
        ...(intent.patterns || []).map(patternToIntentItem),
        ...(intent.phrases || []).map(phraseToIntentItem),
    ];
    const intentQuestion = Intents.getNameFromPath(intent.path);
    return intentItems.filter(phrase => phrase.text !== intentQuestion);
};
