import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { debounce, isEqual } from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import { SortableAnswerPartsContainer } from './DragndropContainer';
import AddOrEditPartModal from './AddOrEditPartModal';
import ButtonWithTooltip from './ButtonWithTooltip';
import { generateUniqId, repliesToAnswerParts } from '../utils';
import './style.scss';
import { AnswerReplyType } from './types';
import { Modal } from '../Modal';
export class RichTextEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.removingEmptyAnswersTimeout = 0;
        this.debouncedUpdate = debounce((value) => { var _a; return this.props.onUpdate(value, (_a = this.intentUpdateAbortController) === null || _a === void 0 ? void 0 : _a.signal); }, this.props.onUpdateDebounce !== undefined ? this.props.onUpdateDebounce : 500);
        this.updateWithoutValidation = () => {
            var _a;
            this.props.onUpdate(this.state.answer, (_a = this.intentUpdateAbortController) === null || _a === void 0 ? void 0 : _a.signal, false);
        };
        this.onChange = (value, index) => {
            var _a;
            const updatedAnswer = [...this.state.answer];
            updatedAnswer[index].value = value;
            if (updatedAnswer[index].type === AnswerReplyType.text) {
                updatedAnswer[index].markup = this.props.markupMode ? 'html' : 'plain';
            }
            (_a = this.intentUpdateAbortController) === null || _a === void 0 ? void 0 : _a.abort();
            this.intentUpdateAbortController = new AbortController();
            this.setState({ answer: updatedAnswer }, () => {
                this.debouncedUpdate(updatedAnswer);
            });
        };
        this.addTextBlock = () => {
            const newBlock = {
                type: AnswerReplyType.text,
                value: '',
                markup: 'plain',
                id: generateUniqId(),
            };
            if (this.state.answer[this.state.answer.length - 1].value)
                return this.setState(prevState => (Object.assign(Object.assign({}, prevState), { answer: [...prevState.answer, newBlock] })));
        };
        this.setDeleteModalOpen = (index) => {
            return this.setState({ deleteModalOpen: index });
        };
        this.deleteHandler = () => {
            if (typeof this.state.deleteModalOpen !== 'number')
                return;
            const updatedAnswer = [...this.state.answer];
            if (updatedAnswer.length === 1) {
                updatedAnswer[0] = { type: AnswerReplyType.text, value: '', markup: 'plain' };
            }
            else {
                updatedAnswer.splice(this.state.deleteModalOpen, 1);
            }
            this.setState({ answer: updatedAnswer, deleteModalOpen: undefined });
            this.debouncedUpdate(updatedAnswer);
            return;
        };
        this.onPartsSortEnd = ({ oldIndex, newIndex }) => {
            if (oldIndex !== newIndex) {
                const newBlocks = arrayMove(this.state.answer, oldIndex, newIndex);
                this.setState({ answer: newBlocks });
                this.debouncedUpdate(newBlocks);
            }
        };
        this.setAddModalOpen = (type) => {
            return this.setState({ addModalOpen: type });
        };
        this.setEditModalOpen = (index) => {
            return this.setState({ editModalOpen: index });
        };
        this.addMediaBlock = (value, fileName, fileType) => {
            if (!this.state.addModalOpen)
                return;
            const newAnswerPart = {
                type: this.state.addModalOpen,
                value,
                fileName,
                uploadDate: new Date().getTime(),
                audioName: fileName,
                mimeType: fileType,
                id: generateUniqId(),
            };
            const updatedAnswer = [...this.state.answer];
            const replacementCondition = !updatedAnswer[updatedAnswer.length - 1].value.trim();
            if (replacementCondition) {
                updatedAnswer[updatedAnswer.length - 1] = newAnswerPart;
            }
            else {
                updatedAnswer.push(newAnswerPart);
            }
            this.setState({ answer: updatedAnswer, addModalOpen: undefined });
            return this.debouncedUpdate(updatedAnswer);
        };
        this.editMediaBlock = (value, name, fileType) => {
            if (typeof this.state.editModalOpen !== 'number')
                return;
            const updatedAnswer = [...this.state.answer];
            const updatedAnswerType = updatedAnswer[this.state.editModalOpen].type;
            updatedAnswer[this.state.editModalOpen].value = value;
            if (updatedAnswerType === 'file') {
                updatedAnswer[this.state.editModalOpen].fileName = name;
            }
            if (updatedAnswerType === AnswerReplyType.audio) {
                updatedAnswer[this.state.editModalOpen].audioName = name;
            }
            updatedAnswer[this.state.editModalOpen].uploadDate = new Date().getTime();
            updatedAnswer[this.state.editModalOpen].mimeType = fileType;
            this.setState({ answer: updatedAnswer, editModalOpen: undefined });
            return this.debouncedUpdate(updatedAnswer);
        };
        this.onTextPartBlur = (event) => {
            if (!event.currentTarget.value && this.state.answer.length > 1) {
                // removing all empty answer parts, if we press button to create media part, we put it in timeout for click to work and open a modal, effectively replacing the empty part with media
                const asyncingCondition = event.relatedTarget &&
                    event.relatedTarget.id &&
                    event.relatedTarget.id.includes('--btn') &&
                    !event.relatedTarget.id.includes('addTextBtn');
                if (asyncingCondition) {
                    clearTimeout(this.removingEmptyAnswersTimeout);
                    this.removingEmptyAnswersTimeout = setTimeout(() => {
                        const updatedAnswer = [...this.state.answer].filter(part => Boolean(part.value.trim()));
                        this.setState({ answer: updatedAnswer });
                        return this.debouncedUpdate(updatedAnswer);
                    }, 200);
                }
                else {
                    const updatedAnswer = [...this.state.answer].filter(part => Boolean(part.value.trim()));
                    this.setState({ answer: updatedAnswer });
                    return this.debouncedUpdate(updatedAnswer);
                }
            }
        };
        this.state = {
            answer: [],
            selected: -1,
        };
        this.buttonsDescriptor = {
            [AnswerReplyType.text]: {
                iconLeft: 'farText',
                'data-test-id': 'AnswerBlock.AddBtn.Text',
                onClick: this.addTextBlock,
                target: 'addTextBtn',
            },
            [AnswerReplyType.image]: {
                iconLeft: 'farImage',
                'data-test-id': 'AnswerBlock.AddBtn.Image',
                onClick: () => this.setAddModalOpen(AnswerReplyType.image),
                target: 'addImageBtn',
            },
            [AnswerReplyType.audio]: {
                iconLeft: 'farMusic',
                'data-test-id': 'AnswerBlock.AddBtn.Audio',
                onClick: () => this.setAddModalOpen(AnswerReplyType.audio),
                target: 'addAudioBtn',
            },
            [AnswerReplyType.file]: {
                iconLeft: 'farFileArchive',
                'data-test-id': 'AnswerBlock.AddBtn.File',
                onClick: () => this.setAddModalOpen(AnswerReplyType.file),
                target: 'addFileBtn',
            },
        };
    }
    componentDidMount() {
        this.setState({
            answer: repliesToAnswerParts(this.props.replies.length > 0 ? this.props.replies : this.props.INITIAL_REPLIES),
        });
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.replies, this.props.replies)) {
            this.setState({ answer: repliesToAnswerParts(this.props.replies) });
        }
    }
    componentWillUnmount() {
        clearTimeout(this.removingEmptyAnswersTimeout);
    }
    render() {
        const { answer, deleteModalOpen: deletingModalOpen, addModalOpen, editModalOpen } = this.state;
        const { markupMode, writeDisabled, t, ValErrorComponent } = this.props;
        const deleteModalMessage = () => {
            var _a;
            const filetype = typeof deletingModalOpen === 'number'
                ? t(`AnswerBlocks:type_${(_a = answer[deletingModalOpen]) === null || _a === void 0 ? void 0 : _a.type}`).toLowerCase()
                : t('AnswerBlocks:type_message');
            return t('AnswerBlocks:delete_block_text', filetype);
        };
        const maximumAnswersReached = answer.length >= this.props.MAX_ANSWERS_COUNT;
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'faq-answer-container', children: answer && answer.length > 0 && (_jsx(SortableAnswerPartsContainer, { sortableBlocks: answer, saveAudioToServer: this.props.saveAudioToServer, onChange: this.onChange, setOpenDelete: this.setDeleteModalOpen, maxTextSize: this.props.maxTextSize, useDragHandle: true, axis: 'y', lockAxis: 'y', lockOffset: ['0%', '100%'], lockToContainerEdges: true, helperClass: 'faq-answer-part--dragged', onSortEnd: this.onPartsSortEnd, markupMode: markupMode, pressDelay: 50, setOpenEdit: this.setEditModalOpen, onBlur: this.onTextPartBlur, writeDisabled: writeDisabled })) }), ValErrorComponent && _jsx(ValErrorComponent, { updateWithoutValidation: this.updateWithoutValidation }), _jsx("div", { className: 'faq-answer__add-part-btns', children: Object.entries(this.buttonsDescriptor).map(([name, value]) => {
                        if (this.props.showButtons) {
                            if (this.props.showButtons.includes(name)) {
                                return (_jsx(ButtonWithTooltip, { iconLeft: value.iconLeft, outline: true, color: 'secondary', className: `faq-answer__add-btn faq-answer__add-btn--${name}`, onClick: value.onClick, disabled: maximumAnswersReached || writeDisabled, tooltipText: t('AnswerBlocks:max_answers'), target: maximumAnswersReached ? value.target : undefined, "data-test-id": value['data-test-id'], id: `${value.target}--btn`, children: t(`AnswerBlocks:AddButton:type_${name}`) }, `ButtonWithTooltip-${name}`));
                            }
                            return null;
                        }
                        return (_jsx(ButtonWithTooltip, { iconLeft: value.iconLeft, outline: true, color: 'secondary', className: `faq-answer__add-btn faq-answer__add-btn--${name}`, onClick: value.onClick, disabled: maximumAnswersReached || writeDisabled || !!ValErrorComponent, tooltipText: t('AnswerBlocks:max_answers'), target: maximumAnswersReached ? value.target : undefined, "data-test-id": value['data-test-id'], id: `${value.target}--btn`, children: t(`AnswerBlocks:AddButton:type_${name}`) }, `ButtonWithTooltip-${name}`));
                    }) }), _jsx(Modal, { isOpen: typeof deletingModalOpen === 'number', title: t(`AnswerBlocks:delete_block_title`), buttonSubmitColor: 'danger', buttonSubmitText: t(`AnswerBlocks:delete_block_submit`), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: () => this.setDeleteModalOpen(undefined), onActionClick: this.deleteHandler, buttonCancelTestId: 'FAQPage.AnswerBlock.ModalDelete.CancelBtn', buttonSubmitTestId: 'FAQPage.AnswerBlock.ModalDelete.SubmitBtn', children: _jsx("p", { children: deleteModalMessage() }) }), !!addModalOpen && (_jsx(AddOrEditPartModal, { type: addModalOpen, cancelHandler: () => this.setState({ addModalOpen: undefined }), submitHandler: this.addMediaBlock, getErrorMessageFromReason: this.props.getErrorMessageFromReason, saveAudioToServer: this.props.saveAudioToServer, saveFileToServer: this.props.saveFileToServer, validateFile: this.props.validateFile, isWhiteLabel: this.props.isWhiteLabel })), typeof editModalOpen !== 'undefined' && (_jsx(AddOrEditPartModal, { type: answer[editModalOpen].type, value: answer[editModalOpen].value, cancelHandler: () => this.setState({ editModalOpen: undefined }), submitHandler: this.editMediaBlock, getErrorMessageFromReason: this.props.getErrorMessageFromReason, saveAudioToServer: this.props.saveAudioToServer, saveFileToServer: this.props.saveFileToServer, validateFile: this.props.validateFile, isWhiteLabel: this.props.isWhiteLabel }))] }));
    }
}
export default RichTextEditor;
