import React, { FC, FunctionComponent } from 'react';
import './style.scss';
import { Modal } from '@just-ai/just-ui';
import { useEditorContext } from '../../context/EditorContext';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { ModalControl } from '../../../Caila/utils';

type DiscardDialogProps = {
  control: ModalControl;
  discard: () => Promise<unknown>;
};
type DiscardDialogWrapperProps = {
  control: ModalControl;
};

const DiscardDialogWrapper: FC<DiscardDialogWrapperProps> = ({ control }) => {
  const { discard } = useEditorContext();

  return <DiscardDialog control={control} discard={discard} />;
};

export const DiscardDialog: FunctionComponent<DiscardDialogProps> = ({ control, discard }) => {
  const { t } = i18nTranslation('Editor');

  const handleAction = () => {
    discard();
    control.hide();
  };

  return (
    <Modal
      isOpen
      onCancelClick={control.hide}
      onActionClick={handleAction}
      title={t('discardDialogTitle')}
      buttonSubmitText={t('discardDialogSaveButton')}
      buttonCancelText={t('discardDialogCancelButton')}
      buttonCancelTestId='DiscardDialog.Cancel'
      buttonSubmitTestId='DiscardDialog.Submit'
      disableActionButtonAutoFocus
    >
      {t('discardDialogMessage')}
    </Modal>
  );
};

export default DiscardDialogWrapper;
