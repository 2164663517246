import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { JGraphData } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import { GraphApi } from '../utils';

type getGraphParamsType = {
  context?: string;
  options?: any;
};

export const getGraph = createAsyncThunk<
  JGraphData | undefined,
  getGraphParamsType,
  { rejectValue: { error: string; message: string; args?: object } }
>('JGraph/getGraph', async (getGraphParams, thunkAPI) => {
  const { options } = getGraphParams;
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;
  try {
    const result = await GraphApi.getGraph(userAccountId || accountId, projectShortName, options);
    if (!result?.data) return;
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
    throw error;
  }
});

export default getGraph;
