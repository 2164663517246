import {
  SET_UP_NEW_PASSWORD_FULFILLED,
  SET_UP_NEW_PASSWORD_PENDING,
  SET_UP_NEW_PASSWORD_REJECTED,
  REMOVE_NEW_PASSWORD_ALERT,
} from '../../constants/passwordRestore.actions';

import { parseErrors } from '../../pipes/functions';
import {
  GET_PASSWORD_LENGTH_FULFILLED,
  GET_PASSWORD_LENGTH_PENDING,
  GET_PASSWORD_LENGTH_REJECTED,
} from '../../constants/register.actions';

const InitialState = {
  errors: [],
  fetching: false,
  passwordLength: null,
};

export default function NewPasswordReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_UP_NEW_PASSWORD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        errors: InitialState.errors,
      };
    }
    case SET_UP_NEW_PASSWORD_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case SET_UP_NEW_PASSWORD_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case REMOVE_NEW_PASSWORD_ALERT: {
      let newRegisterAlert = [...state.errors];
      newRegisterAlert.splice(action.key, 1);
      return {
        ...state,
        errors: newRegisterAlert,
      };
    }

    case GET_PASSWORD_LENGTH_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_PASSWORD_LENGTH_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_PASSWORD_LENGTH_FULFILLED: {
      return {
        ...state,
        fetching: false,
        passwordLength: action.payload.data.passwordLength,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
