import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Circle, Group, Rect } from 'react-konva';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { t } from 'localization';

import { EditMenuBlock, JStateWithId } from 'reducers/JGraph.reducer/types';
import { replaceAllConnectionsToGroup } from '../../utils/connectionLayerUtils';
import { getStageActionsFromEvent, getStageContextFromEvent } from '../../utils/stageUtils';

import { AutosizeRect } from '../parts/AutosizeRect';
import { IconNames, KIcon } from '../parts/KIcons';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import { GroupStateItem } from './GroupStateItem';
import { GroupOffsetProvider, StatesGroupContext } from './context';
import { clearCache } from 'modules/JGraph/utils/blockLayerUtils';
import { ScreenMainCollapsibleGroup } from '../parts/ScreenMainCollapsibleGroup';
import { ScreenContext } from 'modules/JGraph/hooks';

type TStatesGroupProps = {
  screen: JStateWithId;
  parentGroup: Konva.Group | null;
  setEditMenuBlock: (screen: EditMenuBlock | undefined) => unknown;
  editMenuBlock?: EditMenuBlock;
  isEditModeEnable: boolean;
  isActiveByConnectorHighLight: boolean;
};

export const textParams: Konva.TextConfig = {
  fontSize: 14,
  lineHeight: 20 / 14,
  letterSpacing: 0.075,
};

const cornerRadius = [0, 0, 8, 8];

export const StatesGroup: FC<TStatesGroupProps> = ({
  screen,
  parentGroup,
  editMenuBlock,
  setEditMenuBlock,
  isEditModeEnable,
  isActiveByConnectorHighLight,
}) => {
  const CircleRef = useRef<Konva.Circle | null>(null);
  const StateRef = useRef<Konva.Group | null>(null);
  const [collapsed, setCollapsed] = useState(false);

  const { pathId } = screen;

  useEffect(() => {
    clearCache(parentGroup);

    let revertConnections: (() => void) | undefined;
    if (StateRef.current && CircleRef.current && collapsed) {
      revertConnections = replaceAllConnectionsToGroup(screen, StateRef.current, CircleRef.current);
    }
    return () => {
      revertConnections && revertConnections();
    };
  }, [collapsed, parentGroup, screen]);

  const setGroup = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      const { setSelectedGroup } = getStageContextFromEvent(event);
      const { setEditMenuBlock } = getStageActionsFromEvent(event);
      setEditMenuBlock(undefined);
      setSelectedGroup({
        pathId: screen.pathId,
        path: screen.path,
      });
    },
    [screen.path, screen.pathId]
  );

  const onCollapse = useCallback((value: boolean) => {
    setCollapsed(value);
  }, []);

  return (
    <StatesGroupContext.Provider
      value={{
        mainScreen: screen,
      }}
    >
      <ScreenContext.Provider value={{ screen, editMenuBlock, isEditModeEnable }}>
        <AutopositionGroup padding={0} xOffset={0} yOffset={0} name='StatesGroup'>
          <AutosizeRect
            width={280}
            fill='#fff'
            cornerRadius={cornerRadius}
            active={
              (editMenuBlock?.path === undefined &&
                editMenuBlock?.jBlockIndex === undefined &&
                editMenuBlock?.screen.pathId === pathId) ||
              isActiveByConnectorHighLight
            }
            debugActive={screen.debugActive}
            name='StatesGroupAutosizeRect'
          >
            <Group ref={StateRef}>
              <Circle x={280 - 24 - 6} y={16} radius={6} strokeWidth={1} width={12} height={12} ref={CircleRef} />
              <Rect fill='#fff' x={1} y={-1} width={278} height={3} />

              <ScreenMainCollapsibleGroup
                groupTitle={t('ScreenBody:CollapseGroup_ScreensTitle')}
                yOffset={4}
                name={`${screen.pathId}_groups`}
                pathId={screen.pathId}
                onCollapse={onCollapse}
              >
                <Group y={4} x={-12}>
                  <KIcon
                    icon={IconNames.goInSubscenario}
                    x={280 - 24 - 2}
                    y={-21}
                    width={16}
                    height={16}
                    onClick={setGroup}
                  />
                  <GroupOffsetProvider value={{ paddingLeft: 0 }} key={screen.states?.length || 0}>
                    {screen.states &&
                      screen.states.length > 0 &&
                      screen.states.map(innState => (
                        <GroupStateItem key={innState.path} state={innState} setEditMenuBlock={setEditMenuBlock} />
                      ))}
                  </GroupOffsetProvider>
                </Group>
              </ScreenMainCollapsibleGroup>
            </Group>
          </AutosizeRect>
        </AutopositionGroup>
      </ScreenContext.Provider>
    </StatesGroupContext.Provider>
  );
};
