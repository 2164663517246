import React, { Fragment } from 'react';
import localize from '../../../localization';
import { Icon, Textarea } from '@just-ai/just-ui';

import { Alert } from 'reactstrap';
import isAccess, { isConfigEnabled } from '../../../isAccessFunction';

type NLUSettingsProps = {
  extendedSettings: string;
  extendedSettingsRef: string;
  changeExtendedSettings: (value: string) => void;
  nluSettingsError: string;
  onChange: string;
  error: string;
};

export function NLUSettings({
  extendedSettings,
  extendedSettingsRef,
  changeExtendedSettings,
  nluSettingsError,
  onChange,
  error,
}: NLUSettingsProps) {
  const nluWriteDisabled = !isAccess(['NLU_WRITE']);
  return (
    <Fragment>
      <div className='form-group row'>
        <div className='col-md-12'>
          <label htmlFor='extendedSettings' className='form-control-label' style={{ marginBottom: 9 }}>
            {localize.translate('nlu extended settings')}
            {isConfigEnabled('showHelp') && (
              <a href={localize.translate('NLUSettings help link')} rel='noopener noreferrer' target='_blank'>
                <Icon color='primary' name='faInfoCircle' size='sm' />
              </a>
            )}
          </label>
          <Textarea
            name='extendedSettings'
            id='extendedSettings'
            rows={12}
            value={extendedSettings}
            innerRef={extendedSettingsRef}
            onChange={changeExtendedSettings}
            invalid={nluSettingsError || error}
            data-test-id='projectsPage.createProjectModal.extendedSettingsTextarea'
            readOnly={nluWriteDisabled}
          />
          {error && error.length > 0 && (
            <div className='row'>
              <div className='col-12'>
                <Alert color='danger' className='nlu-errors-block'>
                  <div>
                    <Icon name='faExclamationCircle' color='danger' size='sm' />
                  </div>
                  <div>
                    {Array.isArray(error) ? (
                      <>
                        {' '}
                        <p>{localize.translate('caila fix errors')}</p>
                        {error.map(message => (
                          <p key={`cailaError-${message}`}>{'— ' + message}</p>
                        ))}
                      </>
                    ) : (
                      <p>{error}</p>
                    )}
                  </div>
                </Alert>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
