import {
  ADD_DESTINATION,
  ADD_DESTINATION_IDS,
  CLEAR_EDITABLE_LIVECHAT_CHANNEL,
  CREATE_LIVECHAT_CHANNEL_FULFILLED,
  CREATE_LIVECHAT_CHANNEL_PENDING,
  CREATE_LIVECHAT_CHANNEL_REJECTED,
  DELETE_IDS_DESTINATION,
  FETCH_I_JIVOSITE_WEBHOOK_URL_FULFILLED,
  FETCH_I_JIVOSITE_WEBHOOK_URL_PENDING,
  FETCH_I_JIVOSITE_WEBHOOK_URL_REJECTED,
  HIDE_LIVECHAT_CHANNEL_VALIDATION_ERRORS,
  REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED,
  REMOVE_EDITABLE_LIVECHAT_CHANNEL_PENDING,
  REMOVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED,
  REMOVE_GROUP,
  SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED,
  SAVE_EDITABLE_LIVECHAT_CHANNEL_PENDING,
  SAVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED,
  SELECT_DESTINATION_IDS,
  SET_EDITABLE_LIVECHAT_CHANNEL,
  SHOW_LIVECHAT_CHANNEL_VALIDATION_ERRORS,
  UPDATE_EDITABLE_LIVECHAT_CHANNEL,
  VALIDATE_LIVECHAT_TOKEN_FULFILLED,
  VALIDATE_LIVECHAT_TOKEN_REJECTED,
  CLEAR_VALIDATE_TOKEN_ERROR,
  CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT,
} from '../../constants/livechatChannels.actions';
import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';
import { merge } from 'lodash/object';
import { union } from 'lodash/array';

const InitialState = {
  editableChannel: {},
  branches: [],
  themes: [],
  implementationScript: null,
  botName: null,
  isEdit: false,
  fetching: false,
  fetched: false,
  error: [],
  isTokenValid: true,
  extraData: {},
  jivositeWebhookUrl: null,
};

const newGroup = {
  name: '',
  description: '',
  ids: null,
};

export default function EditLivechatChannelReducer(state = InitialState, action) {
  let errors;

  switch (action.type) {
    case REMOVE_EDITABLE_LIVECHAT_CHANNEL_PENDING:
    case FETCH_I_JIVOSITE_WEBHOOK_URL_PENDING:
    case CREATE_LIVECHAT_CHANNEL_PENDING:
    case SAVE_EDITABLE_LIVECHAT_CHANNEL_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case VALIDATE_LIVECHAT_TOKEN_FULFILLED:
      return {
        ...state,
        isTokenValid: true,
        extraData: Boolean(action.payload.data.extraData) ? action.payload.data.extraData : InitialState.extraData,
      };

    case VALIDATE_LIVECHAT_TOKEN_REJECTED:
      return {
        ...state,
        isTokenValid: false,
        extraData: InitialState.extraData,
      };

    case CLEAR_VALIDATE_LIVECHAT_TOKEN_RESULT:
      return {
        ...state,
        isTokenValid: true,
        extraData: {},
      };

    case ADD_DESTINATION: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations.push({ ...newGroup });
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case REMOVE_GROUP: {
      let destinations = [...state.editableChannel.config.destinations];
      destinations.splice(action.indexInArray, 1);
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case SELECT_DESTINATION_IDS: {
      let destinations = [...state.editableChannel.config.destinations];
      destinations[action.f_key].ids = action.value;
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case ADD_DESTINATION_IDS: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations[action.f_key].ids.push('');
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case DELETE_IDS_DESTINATION: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations[action.f_key].ids.splice(action.s_key, 1);
      if (destinations[action.f_key].ids.length === 0) {
        destinations[action.f_key].ids.push('');
      }
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case SHOW_LIVECHAT_CHANNEL_VALIDATION_ERRORS:
      let newErrors = action.errors.filter(errorMsg => {
        return state.error.indexOf(errorMsg) === -1;
      });

      return {
        ...state,
        error: [...state.error, ...newErrors],
      };
    case HIDE_LIVECHAT_CHANNEL_VALIDATION_ERRORS:
      return {
        ...state,
        error: [],
      };
    case UPDATE_EDITABLE_LIVECHAT_CHANNEL:
      const config = { ...action.editableChannel.config };
      if (config.destinations) {
        config.destinations = config.destinations?.filter(x => x) || [];
      }
      return {
        ...state,
        editableChannel: merge({}, state.editableChannel, { ...action.editableChannel, config }),
      };

    case SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED:
      return {
        ...state,
        editableChannel: {},
        fetched: true,
        fetching: false,
        isEdit: false,
      };

    case SAVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED:
      errors = !!action.payload.response.data.error
        ? [action.payload.response.data.error]
        : action.payload.response.data.errors;

      return {
        ...state,
        fetching: false,
        fetched: false,
        error: union(state.error, errors),
      };

    case SET_EDITABLE_LIVECHAT_CHANNEL:
      const setConfig = { ...action.channel.config };
      if (setConfig.destinations === 'object') {
        setConfig.destinations = setConfig.destinations?.filter(x => x) || [];
      }
      return {
        ...state,
        editableChannel: { ...action.channel, config: setConfig },
        isTokenValid: true,
        implementationScript: null,
        channelName: null,
        error: [],
        isEdit: true,
      };

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case CLEAR_EDITABLE_LIVECHAT_CHANNEL:
      return {
        ...state,
        editableChannel: {},
        implementationScript: null,
        isTokenValid: true,
        channelName: null,
        error: [],
        isEdit: false,
      };

    case CREATE_LIVECHAT_CHANNEL_FULFILLED:
      return {
        ...state,
        editableChannel: action.payload.data,
        isEdit: true,
        fetching: false,
        error: [],
      };

    case CREATE_LIVECHAT_CHANNEL_REJECTED:
      errors = !!action.payload.response.data.error
        ? [action.payload.response.data.error]
        : action.payload.response.data.errors;

      return {
        ...state,
        fetching: false,
        error: union(state.error, errors),
      };

    case REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED:
      return {
        ...state,
        editableChannel: {},
        isTokenValid: true,
        channelName: null,
        error: [],
        isEdit: false,
        fetching: false,
      };

    case REMOVE_EDITABLE_LIVECHAT_CHANNEL_REJECTED:
      return {
        ...state,
        error: action.payload.data,
        fetching: false,
      };

    case FETCH_I_JIVOSITE_WEBHOOK_URL_REJECTED:
      return {
        ...state,
        error: action.payload.data || [],
        fetching: false,
      };
    case FETCH_I_JIVOSITE_WEBHOOK_URL_FULFILLED:
      return {
        ...state,
        jivositeWebhookUrl: action.payload.data,
        fetching: false,
      };
    case CLEAR_VALIDATE_TOKEN_ERROR:
      return {
        ...state,
        isTokenValid: true,
      };

    default:
      return state;
  }
}
