import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, IconButton, useTranslation } from '@just-ai/just-ui';
export default function FileUploaderContent(props) {
    const { clearFile, file, fileError, existingFileName, downloadFile } = props;
    const { t } = useTranslation();
    if (fileError && fileError === 'invalid')
        return (_jsxs("div", { className: 'upload-error', children: [_jsx("div", { children: _jsx(Icon, { name: 'farExclamationTriangle', color: 'danger', size: 'lg' }) }), _jsxs("div", { className: 'upload-error__text', children: [_jsx("p", { className: 'text-secondary font-size-12', children: t('FAQ:importModal:error') }), file && (_jsx("p", { children: _jsx("b", { children: file.name }) }))] }), _jsx("div", { className: 'ml-auto', children: _jsx(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', size: 'sm', type: 'button' }) }), _jsx("div", { children: _jsx(IconButton, { flat: true, name: 'farTrashAlt', color: 'none', size: 'sm', onClick: clearFile }) })] }));
    if (file)
        return (_jsxs("div", { className: 'upload-success', children: [_jsx("div", { children: _jsx(Icon, { name: 'farCheckCircle', color: 'success', size: 'lg' }) }), _jsxs("div", { className: 'upload-success__text', children: [_jsx("p", { className: 'text-secondary font-size-12', children: t('loaded') }), _jsx("p", { children: _jsx("b", { children: file.name }) })] }), _jsx("div", { className: 'ml-auto', children: _jsx(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', size: 'sm', type: 'button' }) }), _jsx("div", { children: _jsx(IconButton, { flat: true, name: 'farTrashAlt', color: 'none', size: 'sm', onClick: clearFile }) })] }));
    if (existingFileName)
        return (_jsxs("div", { className: 'upload-success', children: [_jsx("p", { children: _jsx("b", { children: existingFileName }) }), _jsx("div", { className: 'ml-auto', children: _jsx(IconButton, { flat: true, name: 'farArrowToBottom', color: 'none', size: 'sm', type: 'button', onClick: downloadFile }) }), _jsx("div", { children: _jsx(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', type: 'button', size: 'sm' }) })] }));
    return (_jsxs("div", { className: 'cdqa-file-upload__info', children: [_jsxs("p", { children: [_jsx("span", { className: 'pseudoLink', children: t('FAQ:cdqa:documentAttach') }), " ", t('or drop it here')] }), _jsxs("ul", { children: [_jsx("li", { children: _jsx("span", { children: t('FAQ:cdqa:uploadDialogInfoItem1') }) }), _jsx("li", { children: _jsx("span", { children: t('FAQ:cdqa:uploadDialogInfoItem2') }) })] })] }));
}
