import React from 'react';
import PropTypes from 'prop-types';

function JoyrideTooltipCloseBtn({ styles, ...props }) {
  const { color, height, width, ...style } = styles;

  const svgWidth = typeof width === 'number' ? `${width}px` : width;
  const svgHeight = typeof height === 'number' ? `${height}px` : height;
  return (
    <button style={style} type='button' {...props}>
      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='xMidYMid'
      >
        <path
          d='M13.4099 12L16.7099 8.71C16.8032 8.61676 16.8771 8.50607 16.9276 8.38425C16.9781 8.26243 17.004 8.13186 17.004 8C17.004 7.86814 16.9781 7.73758 16.9276 7.61575C16.8771 7.49393 16.8032 7.38324 16.7099 7.29C16.6167 7.19676 16.506 7.1228 16.3842 7.07234C16.2624 7.02188 16.1318 6.99591 15.9999 6.99591C15.8681 6.99591 15.7375 7.02188 15.6157 7.07234C15.4939 7.1228 15.3832 7.19676 15.2899 7.29L11.9999 10.59L8.70994 7.29C8.6167 7.19676 8.50601 7.1228 8.38419 7.07234C8.26237 7.02188 8.1318 6.99591 7.99994 6.99591C7.86808 6.99591 7.73751 7.02188 7.61569 7.07234C7.49387 7.1228 7.38318 7.19676 7.28994 7.29C7.1967 7.38324 7.12274 7.49393 7.07228 7.61575C7.02182 7.73758 6.99585 7.86814 6.99585 8C6.99585 8.13186 7.02182 8.26243 7.07228 8.38425C7.12274 8.50607 7.1967 8.61676 7.28994 8.71L10.5899 12L7.28994 15.29C7.19621 15.383 7.12182 15.4936 7.07105 15.6154C7.02028 15.7373 6.99414 15.868 6.99414 16C6.99414 16.132 7.02028 16.2627 7.07105 16.3846C7.12182 16.5064 7.19621 16.617 7.28994 16.71C7.3829 16.8037 7.4935 16.8781 7.61536 16.9289C7.73722 16.9797 7.86793 17.0058 7.99994 17.0058C8.13195 17.0058 8.26266 16.9797 8.38452 16.9289C8.50638 16.8781 8.61698 16.8037 8.70994 16.71L11.9999 13.41L15.2899 16.71C15.3829 16.8037 15.4935 16.8781 15.6154 16.9289C15.7372 16.9797 15.8679 17.0058 15.9999 17.0058C16.132 17.0058 16.2627 16.9797 16.3845 16.9289C16.5064 16.8781 16.617 16.8037 16.7099 16.71C16.8037 16.617 16.8781 16.5064 16.9288 16.3846C16.9796 16.2627 17.0057 16.132 17.0057 16C17.0057 15.868 16.9796 15.7373 16.9288 15.6154C16.8781 15.4936 16.8037 15.383 16.7099 15.29L13.4099 12Z'
          fill={color}
        />
      </svg>
    </button>
  );
}

JoyrideTooltipCloseBtn.propTypes = {
  styles: PropTypes.object.isRequired,
};

export default JoyrideTooltipCloseBtn;
