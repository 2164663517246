import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { TConnector, JStateWithId } from 'reducers/JGraph.reducer/types';
import { deleteConnection, restoreConnection } from 'reducers/JGraph.reducer/index';
import { getConnectorPropsFromName, findScreenByPathId, findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

const deleteConnectorAsync = createAsyncThunk(
  'JGraph/deleteConnectorAsync',
  async (connector: TConnector, thunkAPI) => {
    await thunkAPI.dispatch(deleteConnection(connector));
    const fromParams = getConnectorPropsFromName(connector.from);
    const state = thunkAPI.getState() as RootState;
    const storeScreens = state.JGraphReducer.graph.blocks;
    const screenToSave = findScreenByPathId(
      fromParams.isFromState ? connector.to || '' : connector.fromNode || '',
      storeScreens
    );
    if (!screenToSave) return;
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () => thunkAPI.dispatch(updateState({ ...screenToSave, useSelfPathValue: true })),
    });
    return { screenToSave };
  }
);

export const revertDeletingConnectorAsync = createAsyncThunk(
  'JGraph/revertDeletingConnectorAsync',
  async (revertEvent: RevertEvent<TConnector, { screenToSave: JStateWithId }>, thunkAPI) => {
    const storeScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevToNodeScreen = findScreenByPath(revertEvent.payload.toNodeOriginalPath, storeScreens);
    const prevFromNodeScreen = findScreenByPath(revertEvent.payload.fromNodeOriginalPath, storeScreens);

    if (!prevToNodeScreen || !prevFromNodeScreen) return;
    await thunkAPI.dispatch(
      restoreConnection({
        connector: revertEvent.payload,
        prevFromNodeScreen,
        prevToNodeScreen,
      })
    );
    if (!prevFromNodeScreen || !prevToNodeScreen) return;

    mainSave$.next({
      type: 'update',
      path: prevFromNodeScreen.path,
      action: () => thunkAPI.dispatch(updateState({ ...prevFromNodeScreen!, useSelfPathValue: true })),
    });
    mainSave$.next({
      type: 'update',
      path: prevToNodeScreen.path,
      action: () => thunkAPI.dispatch(updateState({ ...prevToNodeScreen!, useSelfPathValue: true })),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertDeletingConnectorAsync, deleteConnectorAsync);
