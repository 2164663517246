import React from 'react';

import { t } from 'localization';

import { useKhubTokenValidationResolver } from '../../domain/validation';
import { TokenType, ChangeTokenModal } from '../../external';

interface ChangeKhubTokenModalProps {
  secretName: string;
  isOpen: boolean;
  onSubmit?: (payload: TokenType) => void;
  onCancel?: () => void;
}
const ChangeKhubTokenModal = (props: ChangeKhubTokenModalProps) => {
  const resolver = useKhubTokenValidationResolver();

  return (
    <ChangeTokenModal
      title={t('KhubIntegration:AddExistedTokenByNameModal:Title')}
      buttonSubmitText={t('KhubIntegration:AddExistedTokenByNameModal:Submit')}
      buttonCancelText={t('KhubIntegration:AddExistedTokenByNameModal:Cancel')}
      valueFieldLabel={t('KhubIntegration:AddExistedTokenByNameModal:FieldLabel')}
      valueFieldPlaceholder={t('KhubIntegration:AddExistedTokenByNameModal:FieldPlaceholder')}
      key={props.secretName}
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
      secretName={props.secretName}
      resolver={resolver}
      reValidateMode='onSubmit'
    />
  );
};

ChangeKhubTokenModal.displayName = 'ChangeKhubTokenModal';

export default React.memo(ChangeKhubTokenModal);
