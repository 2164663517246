import { useState, useEffect } from 'react';
import { throttle } from 'lodash';
export function useThrottleFn(fn, time = 800) {
    const [throttleFn, setThrottleFn] = useState();
    useEffect(() => {
        setThrottleFn((prev) => {
            prev === null || prev === void 0 ? void 0 : prev.cancel();
            return throttle(fn, time);
        });
    }, [fn, time]);
    return throttleFn;
}
