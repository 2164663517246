import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { useDragLayer, useDrop } from 'react-dnd';
import { DragTypes } from '../../../../../FAQTreePage/DragTypes';
import styles from './styles.module.scss';
const DragArea = function ({ onDrop }) {
    const { isDragging } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    const [{ isOver }, drop] = useDrop({
        accept: DragTypes.LEAF,
        drop: (item) => {
            onDrop(item.node);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    });
    if (!isDragging)
        return null;
    return (_jsx("div", { className: cn(styles.DragArea), children: _jsx("div", { ref: drop, className: cn(styles.DragArea__content), children: isOver && _jsx("div", { className: styles.DragArea__preview }) }) }));
};
export default React.memo(DragArea);
