const packInfo = {
    lib: "../../node_modules/@fortawesome/pro-duotone-svg-icons",
    prefix: "fad",
    iconsNameMap: {
        "fadAbacus": () => import('@fortawesome/pro-duotone-svg-icons/faAbacus'),
        "fadAcorn": () => import('@fortawesome/pro-duotone-svg-icons/faAcorn'),
        "fadAd": () => import('@fortawesome/pro-duotone-svg-icons/faAd'),
        "fadAddressBook": () => import('@fortawesome/pro-duotone-svg-icons/faAddressBook'),
        "fadAddressCard": () => import('@fortawesome/pro-duotone-svg-icons/faAddressCard'),
        "fadAdjust": () => import('@fortawesome/pro-duotone-svg-icons/faAdjust'),
        "fadAirConditioner": () => import('@fortawesome/pro-duotone-svg-icons/faAirConditioner'),
        "fadAirFreshener": () => import('@fortawesome/pro-duotone-svg-icons/faAirFreshener'),
        "fadAlarmClock": () => import('@fortawesome/pro-duotone-svg-icons/faAlarmClock'),
        "fadAlarmExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faAlarmExclamation'),
        "fadAlarmPlus": () => import('@fortawesome/pro-duotone-svg-icons/faAlarmPlus'),
        "fadAlarmSnooze": () => import('@fortawesome/pro-duotone-svg-icons/faAlarmSnooze'),
        "fadAlbum": () => import('@fortawesome/pro-duotone-svg-icons/faAlbum'),
        "fadAlbumCollection": () => import('@fortawesome/pro-duotone-svg-icons/faAlbumCollection'),
        "fadAlicorn": () => import('@fortawesome/pro-duotone-svg-icons/faAlicorn'),
        "fadAlien": () => import('@fortawesome/pro-duotone-svg-icons/faAlien'),
        "fadAlienMonster": () => import('@fortawesome/pro-duotone-svg-icons/faAlienMonster'),
        "fadAlignCenter": () => import('@fortawesome/pro-duotone-svg-icons/faAlignCenter'),
        "fadAlignJustify": () => import('@fortawesome/pro-duotone-svg-icons/faAlignJustify'),
        "fadAlignLeft": () => import('@fortawesome/pro-duotone-svg-icons/faAlignLeft'),
        "fadAlignRight": () => import('@fortawesome/pro-duotone-svg-icons/faAlignRight'),
        "fadAlignSlash": () => import('@fortawesome/pro-duotone-svg-icons/faAlignSlash'),
        "fadAllergies": () => import('@fortawesome/pro-duotone-svg-icons/faAllergies'),
        "fadAmbulance": () => import('@fortawesome/pro-duotone-svg-icons/faAmbulance'),
        "fadAmericanSignLanguageInterpreting": () => import('@fortawesome/pro-duotone-svg-icons/faAmericanSignLanguageInterpreting'),
        "fadAmpGuitar": () => import('@fortawesome/pro-duotone-svg-icons/faAmpGuitar'),
        "fadAnalytics": () => import('@fortawesome/pro-duotone-svg-icons/faAnalytics'),
        "fadAnchor": () => import('@fortawesome/pro-duotone-svg-icons/faAnchor'),
        "fadAngel": () => import('@fortawesome/pro-duotone-svg-icons/faAngel'),
        "fadAngleDoubleDown": () => import('@fortawesome/pro-duotone-svg-icons/faAngleDoubleDown'),
        "fadAngleDoubleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faAngleDoubleLeft'),
        "fadAngleDoubleRight": () => import('@fortawesome/pro-duotone-svg-icons/faAngleDoubleRight'),
        "fadAngleDoubleUp": () => import('@fortawesome/pro-duotone-svg-icons/faAngleDoubleUp'),
        "fadAngleDown": () => import('@fortawesome/pro-duotone-svg-icons/faAngleDown'),
        "fadAngleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faAngleLeft'),
        "fadAngleRight": () => import('@fortawesome/pro-duotone-svg-icons/faAngleRight'),
        "fadAngleUp": () => import('@fortawesome/pro-duotone-svg-icons/faAngleUp'),
        "fadAngry": () => import('@fortawesome/pro-duotone-svg-icons/faAngry'),
        "fadAnkh": () => import('@fortawesome/pro-duotone-svg-icons/faAnkh'),
        "fadAppleAlt": () => import('@fortawesome/pro-duotone-svg-icons/faAppleAlt'),
        "fadAppleCrate": () => import('@fortawesome/pro-duotone-svg-icons/faAppleCrate'),
        "fadArchive": () => import('@fortawesome/pro-duotone-svg-icons/faArchive'),
        "fadArchway": () => import('@fortawesome/pro-duotone-svg-icons/faArchway'),
        "fadArrowAltCircleDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltCircleDown'),
        "fadArrowAltCircleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltCircleLeft'),
        "fadArrowAltCircleRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltCircleRight'),
        "fadArrowAltCircleUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltCircleUp'),
        "fadArrowAltDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltDown'),
        "fadArrowAltFromBottom": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltFromBottom'),
        "fadArrowAltFromLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltFromLeft'),
        "fadArrowAltFromRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltFromRight'),
        "fadArrowAltFromTop": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltFromTop'),
        "fadArrowAltLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltLeft'),
        "fadArrowAltRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltRight'),
        "fadArrowAltSquareDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltSquareDown'),
        "fadArrowAltSquareLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltSquareLeft'),
        "fadArrowAltSquareRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltSquareRight'),
        "fadArrowAltSquareUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltSquareUp'),
        "fadArrowAltToBottom": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltToBottom'),
        "fadArrowAltToLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltToLeft'),
        "fadArrowAltToRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltToRight'),
        "fadArrowAltToTop": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltToTop'),
        "fadArrowAltUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowAltUp'),
        "fadArrowCircleDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowCircleDown'),
        "fadArrowCircleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowCircleLeft'),
        "fadArrowCircleRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowCircleRight'),
        "fadArrowCircleUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowCircleUp'),
        "fadArrowDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowDown'),
        "fadArrowFromBottom": () => import('@fortawesome/pro-duotone-svg-icons/faArrowFromBottom'),
        "fadArrowFromLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowFromLeft'),
        "fadArrowFromRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowFromRight'),
        "fadArrowFromTop": () => import('@fortawesome/pro-duotone-svg-icons/faArrowFromTop'),
        "fadArrowLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowLeft'),
        "fadArrowRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowRight'),
        "fadArrowSquareDown": () => import('@fortawesome/pro-duotone-svg-icons/faArrowSquareDown'),
        "fadArrowSquareLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowSquareLeft'),
        "fadArrowSquareRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowSquareRight'),
        "fadArrowSquareUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowSquareUp'),
        "fadArrowToBottom": () => import('@fortawesome/pro-duotone-svg-icons/faArrowToBottom'),
        "fadArrowToLeft": () => import('@fortawesome/pro-duotone-svg-icons/faArrowToLeft'),
        "fadArrowToRight": () => import('@fortawesome/pro-duotone-svg-icons/faArrowToRight'),
        "fadArrowToTop": () => import('@fortawesome/pro-duotone-svg-icons/faArrowToTop'),
        "fadArrowUp": () => import('@fortawesome/pro-duotone-svg-icons/faArrowUp'),
        "fadArrows": () => import('@fortawesome/pro-duotone-svg-icons/faArrows'),
        "fadArrowsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faArrowsAlt'),
        "fadArrowsAltH": () => import('@fortawesome/pro-duotone-svg-icons/faArrowsAltH'),
        "fadArrowsAltV": () => import('@fortawesome/pro-duotone-svg-icons/faArrowsAltV'),
        "fadArrowsH": () => import('@fortawesome/pro-duotone-svg-icons/faArrowsH'),
        "fadArrowsV": () => import('@fortawesome/pro-duotone-svg-icons/faArrowsV'),
        "fadAssistiveListeningSystems": () => import('@fortawesome/pro-duotone-svg-icons/faAssistiveListeningSystems'),
        "fadAsterisk": () => import('@fortawesome/pro-duotone-svg-icons/faAsterisk'),
        "fadAt": () => import('@fortawesome/pro-duotone-svg-icons/faAt'),
        "fadAtlas": () => import('@fortawesome/pro-duotone-svg-icons/faAtlas'),
        "fadAtom": () => import('@fortawesome/pro-duotone-svg-icons/faAtom'),
        "fadAtomAlt": () => import('@fortawesome/pro-duotone-svg-icons/faAtomAlt'),
        "fadAudioDescription": () => import('@fortawesome/pro-duotone-svg-icons/faAudioDescription'),
        "fadAward": () => import('@fortawesome/pro-duotone-svg-icons/faAward'),
        "fadAxe": () => import('@fortawesome/pro-duotone-svg-icons/faAxe'),
        "fadAxeBattle": () => import('@fortawesome/pro-duotone-svg-icons/faAxeBattle'),
        "fadBaby": () => import('@fortawesome/pro-duotone-svg-icons/faBaby'),
        "fadBabyCarriage": () => import('@fortawesome/pro-duotone-svg-icons/faBabyCarriage'),
        "fadBackpack": () => import('@fortawesome/pro-duotone-svg-icons/faBackpack'),
        "fadBackspace": () => import('@fortawesome/pro-duotone-svg-icons/faBackspace'),
        "fadBackward": () => import('@fortawesome/pro-duotone-svg-icons/faBackward'),
        "fadBacon": () => import('@fortawesome/pro-duotone-svg-icons/faBacon'),
        "fadBacteria": () => import('@fortawesome/pro-duotone-svg-icons/faBacteria'),
        "fadBacterium": () => import('@fortawesome/pro-duotone-svg-icons/faBacterium'),
        "fadBadge": () => import('@fortawesome/pro-duotone-svg-icons/faBadge'),
        "fadBadgeCheck": () => import('@fortawesome/pro-duotone-svg-icons/faBadgeCheck'),
        "fadBadgeDollar": () => import('@fortawesome/pro-duotone-svg-icons/faBadgeDollar'),
        "fadBadgePercent": () => import('@fortawesome/pro-duotone-svg-icons/faBadgePercent'),
        "fadBadgeSheriff": () => import('@fortawesome/pro-duotone-svg-icons/faBadgeSheriff'),
        "fadBadgerHoney": () => import('@fortawesome/pro-duotone-svg-icons/faBadgerHoney'),
        "fadBagsShopping": () => import('@fortawesome/pro-duotone-svg-icons/faBagsShopping'),
        "fadBahai": () => import('@fortawesome/pro-duotone-svg-icons/faBahai'),
        "fadBalanceScale": () => import('@fortawesome/pro-duotone-svg-icons/faBalanceScale'),
        "fadBalanceScaleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faBalanceScaleLeft'),
        "fadBalanceScaleRight": () => import('@fortawesome/pro-duotone-svg-icons/faBalanceScaleRight'),
        "fadBallPile": () => import('@fortawesome/pro-duotone-svg-icons/faBallPile'),
        "fadBallot": () => import('@fortawesome/pro-duotone-svg-icons/faBallot'),
        "fadBallotCheck": () => import('@fortawesome/pro-duotone-svg-icons/faBallotCheck'),
        "fadBan": () => import('@fortawesome/pro-duotone-svg-icons/faBan'),
        "fadBandAid": () => import('@fortawesome/pro-duotone-svg-icons/faBandAid'),
        "fadBanjo": () => import('@fortawesome/pro-duotone-svg-icons/faBanjo'),
        "fadBarcode": () => import('@fortawesome/pro-duotone-svg-icons/faBarcode'),
        "fadBarcodeAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBarcodeAlt'),
        "fadBarcodeRead": () => import('@fortawesome/pro-duotone-svg-icons/faBarcodeRead'),
        "fadBarcodeScan": () => import('@fortawesome/pro-duotone-svg-icons/faBarcodeScan'),
        "fadBars": () => import('@fortawesome/pro-duotone-svg-icons/faBars'),
        "fadBaseball": () => import('@fortawesome/pro-duotone-svg-icons/faBaseball'),
        "fadBaseballBall": () => import('@fortawesome/pro-duotone-svg-icons/faBaseballBall'),
        "fadBasketballBall": () => import('@fortawesome/pro-duotone-svg-icons/faBasketballBall'),
        "fadBasketballHoop": () => import('@fortawesome/pro-duotone-svg-icons/faBasketballHoop'),
        "fadBat": () => import('@fortawesome/pro-duotone-svg-icons/faBat'),
        "fadBath": () => import('@fortawesome/pro-duotone-svg-icons/faBath'),
        "fadBatteryBolt": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryBolt'),
        "fadBatteryEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryEmpty'),
        "fadBatteryFull": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryFull'),
        "fadBatteryHalf": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryHalf'),
        "fadBatteryQuarter": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryQuarter'),
        "fadBatterySlash": () => import('@fortawesome/pro-duotone-svg-icons/faBatterySlash'),
        "fadBatteryThreeQuarters": () => import('@fortawesome/pro-duotone-svg-icons/faBatteryThreeQuarters'),
        "fadBed": () => import('@fortawesome/pro-duotone-svg-icons/faBed'),
        "fadBedAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBedAlt'),
        "fadBedBunk": () => import('@fortawesome/pro-duotone-svg-icons/faBedBunk'),
        "fadBedEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faBedEmpty'),
        "fadBeer": () => import('@fortawesome/pro-duotone-svg-icons/faBeer'),
        "fadBell": () => import('@fortawesome/pro-duotone-svg-icons/faBell'),
        "fadBellExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faBellExclamation'),
        "fadBellOn": () => import('@fortawesome/pro-duotone-svg-icons/faBellOn'),
        "fadBellPlus": () => import('@fortawesome/pro-duotone-svg-icons/faBellPlus'),
        "fadBellSchool": () => import('@fortawesome/pro-duotone-svg-icons/faBellSchool'),
        "fadBellSchoolSlash": () => import('@fortawesome/pro-duotone-svg-icons/faBellSchoolSlash'),
        "fadBellSlash": () => import('@fortawesome/pro-duotone-svg-icons/faBellSlash'),
        "fadBells": () => import('@fortawesome/pro-duotone-svg-icons/faBells'),
        "fadBetamax": () => import('@fortawesome/pro-duotone-svg-icons/faBetamax'),
        "fadBezierCurve": () => import('@fortawesome/pro-duotone-svg-icons/faBezierCurve'),
        "fadBible": () => import('@fortawesome/pro-duotone-svg-icons/faBible'),
        "fadBicycle": () => import('@fortawesome/pro-duotone-svg-icons/faBicycle'),
        "fadBiking": () => import('@fortawesome/pro-duotone-svg-icons/faBiking'),
        "fadBikingMountain": () => import('@fortawesome/pro-duotone-svg-icons/faBikingMountain'),
        "fadBinoculars": () => import('@fortawesome/pro-duotone-svg-icons/faBinoculars'),
        "fadBiohazard": () => import('@fortawesome/pro-duotone-svg-icons/faBiohazard'),
        "fadBirthdayCake": () => import('@fortawesome/pro-duotone-svg-icons/faBirthdayCake'),
        "fadBlanket": () => import('@fortawesome/pro-duotone-svg-icons/faBlanket'),
        "fadBlender": () => import('@fortawesome/pro-duotone-svg-icons/faBlender'),
        "fadBlenderPhone": () => import('@fortawesome/pro-duotone-svg-icons/faBlenderPhone'),
        "fadBlind": () => import('@fortawesome/pro-duotone-svg-icons/faBlind'),
        "fadBlinds": () => import('@fortawesome/pro-duotone-svg-icons/faBlinds'),
        "fadBlindsOpen": () => import('@fortawesome/pro-duotone-svg-icons/faBlindsOpen'),
        "fadBlindsRaised": () => import('@fortawesome/pro-duotone-svg-icons/faBlindsRaised'),
        "fadBlog": () => import('@fortawesome/pro-duotone-svg-icons/faBlog'),
        "fadBold": () => import('@fortawesome/pro-duotone-svg-icons/faBold'),
        "fadBolt": () => import('@fortawesome/pro-duotone-svg-icons/faBolt'),
        "fadBomb": () => import('@fortawesome/pro-duotone-svg-icons/faBomb'),
        "fadBone": () => import('@fortawesome/pro-duotone-svg-icons/faBone'),
        "fadBoneBreak": () => import('@fortawesome/pro-duotone-svg-icons/faBoneBreak'),
        "fadBong": () => import('@fortawesome/pro-duotone-svg-icons/faBong'),
        "fadBook": () => import('@fortawesome/pro-duotone-svg-icons/faBook'),
        "fadBookAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBookAlt'),
        "fadBookDead": () => import('@fortawesome/pro-duotone-svg-icons/faBookDead'),
        "fadBookHeart": () => import('@fortawesome/pro-duotone-svg-icons/faBookHeart'),
        "fadBookMedical": () => import('@fortawesome/pro-duotone-svg-icons/faBookMedical'),
        "fadBookOpen": () => import('@fortawesome/pro-duotone-svg-icons/faBookOpen'),
        "fadBookReader": () => import('@fortawesome/pro-duotone-svg-icons/faBookReader'),
        "fadBookSpells": () => import('@fortawesome/pro-duotone-svg-icons/faBookSpells'),
        "fadBookUser": () => import('@fortawesome/pro-duotone-svg-icons/faBookUser'),
        "fadBookmark": () => import('@fortawesome/pro-duotone-svg-icons/faBookmark'),
        "fadBooks": () => import('@fortawesome/pro-duotone-svg-icons/faBooks'),
        "fadBooksMedical": () => import('@fortawesome/pro-duotone-svg-icons/faBooksMedical'),
        "fadBoombox": () => import('@fortawesome/pro-duotone-svg-icons/faBoombox'),
        "fadBoot": () => import('@fortawesome/pro-duotone-svg-icons/faBoot'),
        "fadBoothCurtain": () => import('@fortawesome/pro-duotone-svg-icons/faBoothCurtain'),
        "fadBorderAll": () => import('@fortawesome/pro-duotone-svg-icons/faBorderAll'),
        "fadBorderBottom": () => import('@fortawesome/pro-duotone-svg-icons/faBorderBottom'),
        "fadBorderCenterH": () => import('@fortawesome/pro-duotone-svg-icons/faBorderCenterH'),
        "fadBorderCenterV": () => import('@fortawesome/pro-duotone-svg-icons/faBorderCenterV'),
        "fadBorderInner": () => import('@fortawesome/pro-duotone-svg-icons/faBorderInner'),
        "fadBorderLeft": () => import('@fortawesome/pro-duotone-svg-icons/faBorderLeft'),
        "fadBorderNone": () => import('@fortawesome/pro-duotone-svg-icons/faBorderNone'),
        "fadBorderOuter": () => import('@fortawesome/pro-duotone-svg-icons/faBorderOuter'),
        "fadBorderRight": () => import('@fortawesome/pro-duotone-svg-icons/faBorderRight'),
        "fadBorderStyle": () => import('@fortawesome/pro-duotone-svg-icons/faBorderStyle'),
        "fadBorderStyleAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBorderStyleAlt'),
        "fadBorderTop": () => import('@fortawesome/pro-duotone-svg-icons/faBorderTop'),
        "fadBowArrow": () => import('@fortawesome/pro-duotone-svg-icons/faBowArrow'),
        "fadBowlingBall": () => import('@fortawesome/pro-duotone-svg-icons/faBowlingBall'),
        "fadBowlingPins": () => import('@fortawesome/pro-duotone-svg-icons/faBowlingPins'),
        "fadBox": () => import('@fortawesome/pro-duotone-svg-icons/faBox'),
        "fadBoxAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBoxAlt'),
        "fadBoxBallot": () => import('@fortawesome/pro-duotone-svg-icons/faBoxBallot'),
        "fadBoxCheck": () => import('@fortawesome/pro-duotone-svg-icons/faBoxCheck'),
        "fadBoxFragile": () => import('@fortawesome/pro-duotone-svg-icons/faBoxFragile'),
        "fadBoxFull": () => import('@fortawesome/pro-duotone-svg-icons/faBoxFull'),
        "fadBoxHeart": () => import('@fortawesome/pro-duotone-svg-icons/faBoxHeart'),
        "fadBoxOpen": () => import('@fortawesome/pro-duotone-svg-icons/faBoxOpen'),
        "fadBoxTissue": () => import('@fortawesome/pro-duotone-svg-icons/faBoxTissue'),
        "fadBoxUp": () => import('@fortawesome/pro-duotone-svg-icons/faBoxUp'),
        "fadBoxUsd": () => import('@fortawesome/pro-duotone-svg-icons/faBoxUsd'),
        "fadBoxes": () => import('@fortawesome/pro-duotone-svg-icons/faBoxes'),
        "fadBoxesAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBoxesAlt'),
        "fadBoxingGlove": () => import('@fortawesome/pro-duotone-svg-icons/faBoxingGlove'),
        "fadBrackets": () => import('@fortawesome/pro-duotone-svg-icons/faBrackets'),
        "fadBracketsCurly": () => import('@fortawesome/pro-duotone-svg-icons/faBracketsCurly'),
        "fadBraille": () => import('@fortawesome/pro-duotone-svg-icons/faBraille'),
        "fadBrain": () => import('@fortawesome/pro-duotone-svg-icons/faBrain'),
        "fadBreadLoaf": () => import('@fortawesome/pro-duotone-svg-icons/faBreadLoaf'),
        "fadBreadSlice": () => import('@fortawesome/pro-duotone-svg-icons/faBreadSlice'),
        "fadBriefcase": () => import('@fortawesome/pro-duotone-svg-icons/faBriefcase'),
        "fadBriefcaseMedical": () => import('@fortawesome/pro-duotone-svg-icons/faBriefcaseMedical'),
        "fadBringForward": () => import('@fortawesome/pro-duotone-svg-icons/faBringForward'),
        "fadBringFront": () => import('@fortawesome/pro-duotone-svg-icons/faBringFront'),
        "fadBroadcastTower": () => import('@fortawesome/pro-duotone-svg-icons/faBroadcastTower'),
        "fadBroom": () => import('@fortawesome/pro-duotone-svg-icons/faBroom'),
        "fadBrowser": () => import('@fortawesome/pro-duotone-svg-icons/faBrowser'),
        "fadBrush": () => import('@fortawesome/pro-duotone-svg-icons/faBrush'),
        "fadBug": () => import('@fortawesome/pro-duotone-svg-icons/faBug'),
        "fadBuilding": () => import('@fortawesome/pro-duotone-svg-icons/faBuilding'),
        "fadBullhorn": () => import('@fortawesome/pro-duotone-svg-icons/faBullhorn'),
        "fadBullseye": () => import('@fortawesome/pro-duotone-svg-icons/faBullseye'),
        "fadBullseyeArrow": () => import('@fortawesome/pro-duotone-svg-icons/faBullseyeArrow'),
        "fadBullseyePointer": () => import('@fortawesome/pro-duotone-svg-icons/faBullseyePointer'),
        "fadBurgerSoda": () => import('@fortawesome/pro-duotone-svg-icons/faBurgerSoda'),
        "fadBurn": () => import('@fortawesome/pro-duotone-svg-icons/faBurn'),
        "fadBurrito": () => import('@fortawesome/pro-duotone-svg-icons/faBurrito'),
        "fadBus": () => import('@fortawesome/pro-duotone-svg-icons/faBus'),
        "fadBusAlt": () => import('@fortawesome/pro-duotone-svg-icons/faBusAlt'),
        "fadBusSchool": () => import('@fortawesome/pro-duotone-svg-icons/faBusSchool'),
        "fadBusinessTime": () => import('@fortawesome/pro-duotone-svg-icons/faBusinessTime'),
        "fadCabinetFiling": () => import('@fortawesome/pro-duotone-svg-icons/faCabinetFiling'),
        "fadCactus": () => import('@fortawesome/pro-duotone-svg-icons/faCactus'),
        "fadCalculator": () => import('@fortawesome/pro-duotone-svg-icons/faCalculator'),
        "fadCalculatorAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCalculatorAlt'),
        "fadCalendar": () => import('@fortawesome/pro-duotone-svg-icons/faCalendar'),
        "fadCalendarAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarAlt'),
        "fadCalendarCheck": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarCheck'),
        "fadCalendarDay": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarDay'),
        "fadCalendarEdit": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarEdit'),
        "fadCalendarExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarExclamation'),
        "fadCalendarMinus": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarMinus'),
        "fadCalendarPlus": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarPlus'),
        "fadCalendarStar": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarStar'),
        "fadCalendarTimes": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarTimes'),
        "fadCalendarWeek": () => import('@fortawesome/pro-duotone-svg-icons/faCalendarWeek'),
        "fadCamcorder": () => import('@fortawesome/pro-duotone-svg-icons/faCamcorder'),
        "fadCamera": () => import('@fortawesome/pro-duotone-svg-icons/faCamera'),
        "fadCameraAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCameraAlt'),
        "fadCameraHome": () => import('@fortawesome/pro-duotone-svg-icons/faCameraHome'),
        "fadCameraMovie": () => import('@fortawesome/pro-duotone-svg-icons/faCameraMovie'),
        "fadCameraPolaroid": () => import('@fortawesome/pro-duotone-svg-icons/faCameraPolaroid'),
        "fadCameraRetro": () => import('@fortawesome/pro-duotone-svg-icons/faCameraRetro'),
        "fadCampfire": () => import('@fortawesome/pro-duotone-svg-icons/faCampfire'),
        "fadCampground": () => import('@fortawesome/pro-duotone-svg-icons/faCampground'),
        "fadCandleHolder": () => import('@fortawesome/pro-duotone-svg-icons/faCandleHolder'),
        "fadCandyCane": () => import('@fortawesome/pro-duotone-svg-icons/faCandyCane'),
        "fadCandyCorn": () => import('@fortawesome/pro-duotone-svg-icons/faCandyCorn'),
        "fadCannabis": () => import('@fortawesome/pro-duotone-svg-icons/faCannabis'),
        "fadCapsules": () => import('@fortawesome/pro-duotone-svg-icons/faCapsules'),
        "fadCar": () => import('@fortawesome/pro-duotone-svg-icons/faCar'),
        "fadCarAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCarAlt'),
        "fadCarBattery": () => import('@fortawesome/pro-duotone-svg-icons/faCarBattery'),
        "fadCarBuilding": () => import('@fortawesome/pro-duotone-svg-icons/faCarBuilding'),
        "fadCarBump": () => import('@fortawesome/pro-duotone-svg-icons/faCarBump'),
        "fadCarBus": () => import('@fortawesome/pro-duotone-svg-icons/faCarBus'),
        "fadCarCrash": () => import('@fortawesome/pro-duotone-svg-icons/faCarCrash'),
        "fadCarGarage": () => import('@fortawesome/pro-duotone-svg-icons/faCarGarage'),
        "fadCarMechanic": () => import('@fortawesome/pro-duotone-svg-icons/faCarMechanic'),
        "fadCarSide": () => import('@fortawesome/pro-duotone-svg-icons/faCarSide'),
        "fadCarTilt": () => import('@fortawesome/pro-duotone-svg-icons/faCarTilt'),
        "fadCarWash": () => import('@fortawesome/pro-duotone-svg-icons/faCarWash'),
        "fadCaravan": () => import('@fortawesome/pro-duotone-svg-icons/faCaravan'),
        "fadCaravanAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCaravanAlt'),
        "fadCaretCircleDown": () => import('@fortawesome/pro-duotone-svg-icons/faCaretCircleDown'),
        "fadCaretCircleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faCaretCircleLeft'),
        "fadCaretCircleRight": () => import('@fortawesome/pro-duotone-svg-icons/faCaretCircleRight'),
        "fadCaretCircleUp": () => import('@fortawesome/pro-duotone-svg-icons/faCaretCircleUp'),
        "fadCaretDown": () => import('@fortawesome/pro-duotone-svg-icons/faCaretDown'),
        "fadCaretLeft": () => import('@fortawesome/pro-duotone-svg-icons/faCaretLeft'),
        "fadCaretRight": () => import('@fortawesome/pro-duotone-svg-icons/faCaretRight'),
        "fadCaretSquareDown": () => import('@fortawesome/pro-duotone-svg-icons/faCaretSquareDown'),
        "fadCaretSquareLeft": () => import('@fortawesome/pro-duotone-svg-icons/faCaretSquareLeft'),
        "fadCaretSquareRight": () => import('@fortawesome/pro-duotone-svg-icons/faCaretSquareRight'),
        "fadCaretSquareUp": () => import('@fortawesome/pro-duotone-svg-icons/faCaretSquareUp'),
        "fadCaretUp": () => import('@fortawesome/pro-duotone-svg-icons/faCaretUp'),
        "fadCarrot": () => import('@fortawesome/pro-duotone-svg-icons/faCarrot'),
        "fadCars": () => import('@fortawesome/pro-duotone-svg-icons/faCars'),
        "fadCartArrowDown": () => import('@fortawesome/pro-duotone-svg-icons/faCartArrowDown'),
        "fadCartPlus": () => import('@fortawesome/pro-duotone-svg-icons/faCartPlus'),
        "fadCashRegister": () => import('@fortawesome/pro-duotone-svg-icons/faCashRegister'),
        "fadCassetteTape": () => import('@fortawesome/pro-duotone-svg-icons/faCassetteTape'),
        "fadCat": () => import('@fortawesome/pro-duotone-svg-icons/faCat'),
        "fadCatSpace": () => import('@fortawesome/pro-duotone-svg-icons/faCatSpace'),
        "fadCauldron": () => import('@fortawesome/pro-duotone-svg-icons/faCauldron'),
        "fadCctv": () => import('@fortawesome/pro-duotone-svg-icons/faCctv'),
        "fadCertificate": () => import('@fortawesome/pro-duotone-svg-icons/faCertificate'),
        "fadChair": () => import('@fortawesome/pro-duotone-svg-icons/faChair'),
        "fadChairOffice": () => import('@fortawesome/pro-duotone-svg-icons/faChairOffice'),
        "fadChalkboard": () => import('@fortawesome/pro-duotone-svg-icons/faChalkboard'),
        "fadChalkboardTeacher": () => import('@fortawesome/pro-duotone-svg-icons/faChalkboardTeacher'),
        "fadChargingStation": () => import('@fortawesome/pro-duotone-svg-icons/faChargingStation'),
        "fadChartArea": () => import('@fortawesome/pro-duotone-svg-icons/faChartArea'),
        "fadChartBar": () => import('@fortawesome/pro-duotone-svg-icons/faChartBar'),
        "fadChartLine": () => import('@fortawesome/pro-duotone-svg-icons/faChartLine'),
        "fadChartLineDown": () => import('@fortawesome/pro-duotone-svg-icons/faChartLineDown'),
        "fadChartNetwork": () => import('@fortawesome/pro-duotone-svg-icons/faChartNetwork'),
        "fadChartPie": () => import('@fortawesome/pro-duotone-svg-icons/faChartPie'),
        "fadChartPieAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChartPieAlt'),
        "fadChartScatter": () => import('@fortawesome/pro-duotone-svg-icons/faChartScatter'),
        "fadCheck": () => import('@fortawesome/pro-duotone-svg-icons/faCheck'),
        "fadCheckCircle": () => import('@fortawesome/pro-duotone-svg-icons/faCheckCircle'),
        "fadCheckDouble": () => import('@fortawesome/pro-duotone-svg-icons/faCheckDouble'),
        "fadCheckSquare": () => import('@fortawesome/pro-duotone-svg-icons/faCheckSquare'),
        "fadCheese": () => import('@fortawesome/pro-duotone-svg-icons/faCheese'),
        "fadCheeseSwiss": () => import('@fortawesome/pro-duotone-svg-icons/faCheeseSwiss'),
        "fadCheeseburger": () => import('@fortawesome/pro-duotone-svg-icons/faCheeseburger'),
        "fadChess": () => import('@fortawesome/pro-duotone-svg-icons/faChess'),
        "fadChessBishop": () => import('@fortawesome/pro-duotone-svg-icons/faChessBishop'),
        "fadChessBishopAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessBishopAlt'),
        "fadChessBoard": () => import('@fortawesome/pro-duotone-svg-icons/faChessBoard'),
        "fadChessClock": () => import('@fortawesome/pro-duotone-svg-icons/faChessClock'),
        "fadChessClockAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessClockAlt'),
        "fadChessKing": () => import('@fortawesome/pro-duotone-svg-icons/faChessKing'),
        "fadChessKingAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessKingAlt'),
        "fadChessKnight": () => import('@fortawesome/pro-duotone-svg-icons/faChessKnight'),
        "fadChessKnightAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessKnightAlt'),
        "fadChessPawn": () => import('@fortawesome/pro-duotone-svg-icons/faChessPawn'),
        "fadChessPawnAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessPawnAlt'),
        "fadChessQueen": () => import('@fortawesome/pro-duotone-svg-icons/faChessQueen'),
        "fadChessQueenAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessQueenAlt'),
        "fadChessRook": () => import('@fortawesome/pro-duotone-svg-icons/faChessRook'),
        "fadChessRookAlt": () => import('@fortawesome/pro-duotone-svg-icons/faChessRookAlt'),
        "fadChevronCircleDown": () => import('@fortawesome/pro-duotone-svg-icons/faChevronCircleDown'),
        "fadChevronCircleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faChevronCircleLeft'),
        "fadChevronCircleRight": () => import('@fortawesome/pro-duotone-svg-icons/faChevronCircleRight'),
        "fadChevronCircleUp": () => import('@fortawesome/pro-duotone-svg-icons/faChevronCircleUp'),
        "fadChevronDoubleDown": () => import('@fortawesome/pro-duotone-svg-icons/faChevronDoubleDown'),
        "fadChevronDoubleLeft": () => import('@fortawesome/pro-duotone-svg-icons/faChevronDoubleLeft'),
        "fadChevronDoubleRight": () => import('@fortawesome/pro-duotone-svg-icons/faChevronDoubleRight'),
        "fadChevronDoubleUp": () => import('@fortawesome/pro-duotone-svg-icons/faChevronDoubleUp'),
        "fadChevronDown": () => import('@fortawesome/pro-duotone-svg-icons/faChevronDown'),
        "fadChevronLeft": () => import('@fortawesome/pro-duotone-svg-icons/faChevronLeft'),
        "fadChevronRight": () => import('@fortawesome/pro-duotone-svg-icons/faChevronRight'),
        "fadChevronSquareDown": () => import('@fortawesome/pro-duotone-svg-icons/faChevronSquareDown'),
        "fadChevronSquareLeft": () => import('@fortawesome/pro-duotone-svg-icons/faChevronSquareLeft'),
        "fadChevronSquareRight": () => import('@fortawesome/pro-duotone-svg-icons/faChevronSquareRight'),
        "fadChevronSquareUp": () => import('@fortawesome/pro-duotone-svg-icons/faChevronSquareUp'),
        "fadChevronUp": () => import('@fortawesome/pro-duotone-svg-icons/faChevronUp'),
        "fadChild": () => import('@fortawesome/pro-duotone-svg-icons/faChild'),
        "fadChimney": () => import('@fortawesome/pro-duotone-svg-icons/faChimney'),
        "fadChurch": () => import('@fortawesome/pro-duotone-svg-icons/faChurch'),
        "fadCircle": () => import('@fortawesome/pro-duotone-svg-icons/faCircle'),
        "fadCircleNotch": () => import('@fortawesome/pro-duotone-svg-icons/faCircleNotch'),
        "fadCity": () => import('@fortawesome/pro-duotone-svg-icons/faCity'),
        "fadClarinet": () => import('@fortawesome/pro-duotone-svg-icons/faClarinet'),
        "fadClawMarks": () => import('@fortawesome/pro-duotone-svg-icons/faClawMarks'),
        "fadClinicMedical": () => import('@fortawesome/pro-duotone-svg-icons/faClinicMedical'),
        "fadClipboard": () => import('@fortawesome/pro-duotone-svg-icons/faClipboard'),
        "fadClipboardCheck": () => import('@fortawesome/pro-duotone-svg-icons/faClipboardCheck'),
        "fadClipboardList": () => import('@fortawesome/pro-duotone-svg-icons/faClipboardList'),
        "fadClipboardListCheck": () => import('@fortawesome/pro-duotone-svg-icons/faClipboardListCheck'),
        "fadClipboardPrescription": () => import('@fortawesome/pro-duotone-svg-icons/faClipboardPrescription'),
        "fadClipboardUser": () => import('@fortawesome/pro-duotone-svg-icons/faClipboardUser'),
        "fadClock": () => import('@fortawesome/pro-duotone-svg-icons/faClock'),
        "fadClone": () => import('@fortawesome/pro-duotone-svg-icons/faClone'),
        "fadClosedCaptioning": () => import('@fortawesome/pro-duotone-svg-icons/faClosedCaptioning'),
        "fadCloud": () => import('@fortawesome/pro-duotone-svg-icons/faCloud'),
        "fadCloudDownload": () => import('@fortawesome/pro-duotone-svg-icons/faCloudDownload'),
        "fadCloudDownloadAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCloudDownloadAlt'),
        "fadCloudDrizzle": () => import('@fortawesome/pro-duotone-svg-icons/faCloudDrizzle'),
        "fadCloudHail": () => import('@fortawesome/pro-duotone-svg-icons/faCloudHail'),
        "fadCloudHailMixed": () => import('@fortawesome/pro-duotone-svg-icons/faCloudHailMixed'),
        "fadCloudMeatball": () => import('@fortawesome/pro-duotone-svg-icons/faCloudMeatball'),
        "fadCloudMoon": () => import('@fortawesome/pro-duotone-svg-icons/faCloudMoon'),
        "fadCloudMoonRain": () => import('@fortawesome/pro-duotone-svg-icons/faCloudMoonRain'),
        "fadCloudMusic": () => import('@fortawesome/pro-duotone-svg-icons/faCloudMusic'),
        "fadCloudRain": () => import('@fortawesome/pro-duotone-svg-icons/faCloudRain'),
        "fadCloudRainbow": () => import('@fortawesome/pro-duotone-svg-icons/faCloudRainbow'),
        "fadCloudShowers": () => import('@fortawesome/pro-duotone-svg-icons/faCloudShowers'),
        "fadCloudShowersHeavy": () => import('@fortawesome/pro-duotone-svg-icons/faCloudShowersHeavy'),
        "fadCloudSleet": () => import('@fortawesome/pro-duotone-svg-icons/faCloudSleet'),
        "fadCloudSnow": () => import('@fortawesome/pro-duotone-svg-icons/faCloudSnow'),
        "fadCloudSun": () => import('@fortawesome/pro-duotone-svg-icons/faCloudSun'),
        "fadCloudSunRain": () => import('@fortawesome/pro-duotone-svg-icons/faCloudSunRain'),
        "fadCloudUpload": () => import('@fortawesome/pro-duotone-svg-icons/faCloudUpload'),
        "fadCloudUploadAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCloudUploadAlt'),
        "fadClouds": () => import('@fortawesome/pro-duotone-svg-icons/faClouds'),
        "fadCloudsMoon": () => import('@fortawesome/pro-duotone-svg-icons/faCloudsMoon'),
        "fadCloudsSun": () => import('@fortawesome/pro-duotone-svg-icons/faCloudsSun'),
        "fadClub": () => import('@fortawesome/pro-duotone-svg-icons/faClub'),
        "fadCocktail": () => import('@fortawesome/pro-duotone-svg-icons/faCocktail'),
        "fadCode": () => import('@fortawesome/pro-duotone-svg-icons/faCode'),
        "fadCodeBranch": () => import('@fortawesome/pro-duotone-svg-icons/faCodeBranch'),
        "fadCodeCommit": () => import('@fortawesome/pro-duotone-svg-icons/faCodeCommit'),
        "fadCodeMerge": () => import('@fortawesome/pro-duotone-svg-icons/faCodeMerge'),
        "fadCoffee": () => import('@fortawesome/pro-duotone-svg-icons/faCoffee'),
        "fadCoffeePot": () => import('@fortawesome/pro-duotone-svg-icons/faCoffeePot'),
        "fadCoffeeTogo": () => import('@fortawesome/pro-duotone-svg-icons/faCoffeeTogo'),
        "fadCoffin": () => import('@fortawesome/pro-duotone-svg-icons/faCoffin'),
        "fadCoffinCross": () => import('@fortawesome/pro-duotone-svg-icons/faCoffinCross'),
        "fadCog": () => import('@fortawesome/pro-duotone-svg-icons/faCog'),
        "fadCogs": () => import('@fortawesome/pro-duotone-svg-icons/faCogs'),
        "fadCoin": () => import('@fortawesome/pro-duotone-svg-icons/faCoin'),
        "fadCoins": () => import('@fortawesome/pro-duotone-svg-icons/faCoins'),
        "fadColumns": () => import('@fortawesome/pro-duotone-svg-icons/faColumns'),
        "fadComet": () => import('@fortawesome/pro-duotone-svg-icons/faComet'),
        "fadComment": () => import('@fortawesome/pro-duotone-svg-icons/faComment'),
        "fadCommentAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAlt'),
        "fadCommentAltCheck": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltCheck'),
        "fadCommentAltDollar": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltDollar'),
        "fadCommentAltDots": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltDots'),
        "fadCommentAltEdit": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltEdit'),
        "fadCommentAltExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltExclamation'),
        "fadCommentAltLines": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltLines'),
        "fadCommentAltMedical": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltMedical'),
        "fadCommentAltMinus": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltMinus'),
        "fadCommentAltMusic": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltMusic'),
        "fadCommentAltPlus": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltPlus'),
        "fadCommentAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltSlash'),
        "fadCommentAltSmile": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltSmile'),
        "fadCommentAltTimes": () => import('@fortawesome/pro-duotone-svg-icons/faCommentAltTimes'),
        "fadCommentCheck": () => import('@fortawesome/pro-duotone-svg-icons/faCommentCheck'),
        "fadCommentDollar": () => import('@fortawesome/pro-duotone-svg-icons/faCommentDollar'),
        "fadCommentDots": () => import('@fortawesome/pro-duotone-svg-icons/faCommentDots'),
        "fadCommentEdit": () => import('@fortawesome/pro-duotone-svg-icons/faCommentEdit'),
        "fadCommentExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faCommentExclamation'),
        "fadCommentLines": () => import('@fortawesome/pro-duotone-svg-icons/faCommentLines'),
        "fadCommentMedical": () => import('@fortawesome/pro-duotone-svg-icons/faCommentMedical'),
        "fadCommentMinus": () => import('@fortawesome/pro-duotone-svg-icons/faCommentMinus'),
        "fadCommentMusic": () => import('@fortawesome/pro-duotone-svg-icons/faCommentMusic'),
        "fadCommentPlus": () => import('@fortawesome/pro-duotone-svg-icons/faCommentPlus'),
        "fadCommentSlash": () => import('@fortawesome/pro-duotone-svg-icons/faCommentSlash'),
        "fadCommentSmile": () => import('@fortawesome/pro-duotone-svg-icons/faCommentSmile'),
        "fadCommentTimes": () => import('@fortawesome/pro-duotone-svg-icons/faCommentTimes'),
        "fadComments": () => import('@fortawesome/pro-duotone-svg-icons/faComments'),
        "fadCommentsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCommentsAlt'),
        "fadCommentsAltDollar": () => import('@fortawesome/pro-duotone-svg-icons/faCommentsAltDollar'),
        "fadCommentsDollar": () => import('@fortawesome/pro-duotone-svg-icons/faCommentsDollar'),
        "fadCompactDisc": () => import('@fortawesome/pro-duotone-svg-icons/faCompactDisc'),
        "fadCompass": () => import('@fortawesome/pro-duotone-svg-icons/faCompass'),
        "fadCompassSlash": () => import('@fortawesome/pro-duotone-svg-icons/faCompassSlash'),
        "fadCompress": () => import('@fortawesome/pro-duotone-svg-icons/faCompress'),
        "fadCompressAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCompressAlt'),
        "fadCompressArrowsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCompressArrowsAlt'),
        "fadCompressWide": () => import('@fortawesome/pro-duotone-svg-icons/faCompressWide'),
        "fadComputerClassic": () => import('@fortawesome/pro-duotone-svg-icons/faComputerClassic'),
        "fadComputerSpeaker": () => import('@fortawesome/pro-duotone-svg-icons/faComputerSpeaker'),
        "fadConciergeBell": () => import('@fortawesome/pro-duotone-svg-icons/faConciergeBell'),
        "fadConstruction": () => import('@fortawesome/pro-duotone-svg-icons/faConstruction'),
        "fadContainerStorage": () => import('@fortawesome/pro-duotone-svg-icons/faContainerStorage'),
        "fadConveyorBelt": () => import('@fortawesome/pro-duotone-svg-icons/faConveyorBelt'),
        "fadConveyorBeltAlt": () => import('@fortawesome/pro-duotone-svg-icons/faConveyorBeltAlt'),
        "fadCookie": () => import('@fortawesome/pro-duotone-svg-icons/faCookie'),
        "fadCookieBite": () => import('@fortawesome/pro-duotone-svg-icons/faCookieBite'),
        "fadCopy": () => import('@fortawesome/pro-duotone-svg-icons/faCopy'),
        "fadCopyright": () => import('@fortawesome/pro-duotone-svg-icons/faCopyright'),
        "fadCorn": () => import('@fortawesome/pro-duotone-svg-icons/faCorn'),
        "fadCouch": () => import('@fortawesome/pro-duotone-svg-icons/faCouch'),
        "fadCow": () => import('@fortawesome/pro-duotone-svg-icons/faCow'),
        "fadCowbell": () => import('@fortawesome/pro-duotone-svg-icons/faCowbell'),
        "fadCowbellMore": () => import('@fortawesome/pro-duotone-svg-icons/faCowbellMore'),
        "fadCreditCard": () => import('@fortawesome/pro-duotone-svg-icons/faCreditCard'),
        "fadCreditCardBlank": () => import('@fortawesome/pro-duotone-svg-icons/faCreditCardBlank'),
        "fadCreditCardFront": () => import('@fortawesome/pro-duotone-svg-icons/faCreditCardFront'),
        "fadCricket": () => import('@fortawesome/pro-duotone-svg-icons/faCricket'),
        "fadCroissant": () => import('@fortawesome/pro-duotone-svg-icons/faCroissant'),
        "fadCrop": () => import('@fortawesome/pro-duotone-svg-icons/faCrop'),
        "fadCropAlt": () => import('@fortawesome/pro-duotone-svg-icons/faCropAlt'),
        "fadCross": () => import('@fortawesome/pro-duotone-svg-icons/faCross'),
        "fadCrosshairs": () => import('@fortawesome/pro-duotone-svg-icons/faCrosshairs'),
        "fadCrow": () => import('@fortawesome/pro-duotone-svg-icons/faCrow'),
        "fadCrown": () => import('@fortawesome/pro-duotone-svg-icons/faCrown'),
        "fadCrutch": () => import('@fortawesome/pro-duotone-svg-icons/faCrutch'),
        "fadCrutches": () => import('@fortawesome/pro-duotone-svg-icons/faCrutches'),
        "fadCube": () => import('@fortawesome/pro-duotone-svg-icons/faCube'),
        "fadCubes": () => import('@fortawesome/pro-duotone-svg-icons/faCubes'),
        "fadCurling": () => import('@fortawesome/pro-duotone-svg-icons/faCurling'),
        "fadCut": () => import('@fortawesome/pro-duotone-svg-icons/faCut'),
        "fadDagger": () => import('@fortawesome/pro-duotone-svg-icons/faDagger'),
        "fadDatabase": () => import('@fortawesome/pro-duotone-svg-icons/faDatabase'),
        "fadDeaf": () => import('@fortawesome/pro-duotone-svg-icons/faDeaf'),
        "fadDebug": () => import('@fortawesome/pro-duotone-svg-icons/faDebug'),
        "fadDeer": () => import('@fortawesome/pro-duotone-svg-icons/faDeer'),
        "fadDeerRudolph": () => import('@fortawesome/pro-duotone-svg-icons/faDeerRudolph'),
        "fadDemocrat": () => import('@fortawesome/pro-duotone-svg-icons/faDemocrat'),
        "fadDesktop": () => import('@fortawesome/pro-duotone-svg-icons/faDesktop'),
        "fadDesktopAlt": () => import('@fortawesome/pro-duotone-svg-icons/faDesktopAlt'),
        "fadDewpoint": () => import('@fortawesome/pro-duotone-svg-icons/faDewpoint'),
        "fadDharmachakra": () => import('@fortawesome/pro-duotone-svg-icons/faDharmachakra'),
        "fadDiagnoses": () => import('@fortawesome/pro-duotone-svg-icons/faDiagnoses'),
        "fadDiamond": () => import('@fortawesome/pro-duotone-svg-icons/faDiamond'),
        "fadDice": () => import('@fortawesome/pro-duotone-svg-icons/faDice'),
        "fadDiceD10": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD10'),
        "fadDiceD12": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD12'),
        "fadDiceD20": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD20'),
        "fadDiceD4": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD4'),
        "fadDiceD6": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD6'),
        "fadDiceD8": () => import('@fortawesome/pro-duotone-svg-icons/faDiceD8'),
        "fadDiceFive": () => import('@fortawesome/pro-duotone-svg-icons/faDiceFive'),
        "fadDiceFour": () => import('@fortawesome/pro-duotone-svg-icons/faDiceFour'),
        "fadDiceOne": () => import('@fortawesome/pro-duotone-svg-icons/faDiceOne'),
        "fadDiceSix": () => import('@fortawesome/pro-duotone-svg-icons/faDiceSix'),
        "fadDiceThree": () => import('@fortawesome/pro-duotone-svg-icons/faDiceThree'),
        "fadDiceTwo": () => import('@fortawesome/pro-duotone-svg-icons/faDiceTwo'),
        "fadDigging": () => import('@fortawesome/pro-duotone-svg-icons/faDigging'),
        "fadDigitalTachograph": () => import('@fortawesome/pro-duotone-svg-icons/faDigitalTachograph'),
        "fadDiploma": () => import('@fortawesome/pro-duotone-svg-icons/faDiploma'),
        "fadDirections": () => import('@fortawesome/pro-duotone-svg-icons/faDirections'),
        "fadDiscDrive": () => import('@fortawesome/pro-duotone-svg-icons/faDiscDrive'),
        "fadDisease": () => import('@fortawesome/pro-duotone-svg-icons/faDisease'),
        "fadDivide": () => import('@fortawesome/pro-duotone-svg-icons/faDivide'),
        "fadDizzy": () => import('@fortawesome/pro-duotone-svg-icons/faDizzy'),
        "fadDna": () => import('@fortawesome/pro-duotone-svg-icons/faDna'),
        "fadDoNotEnter": () => import('@fortawesome/pro-duotone-svg-icons/faDoNotEnter'),
        "fadDog": () => import('@fortawesome/pro-duotone-svg-icons/faDog'),
        "fadDogLeashed": () => import('@fortawesome/pro-duotone-svg-icons/faDogLeashed'),
        "fadDollarSign": () => import('@fortawesome/pro-duotone-svg-icons/faDollarSign'),
        "fadDolly": () => import('@fortawesome/pro-duotone-svg-icons/faDolly'),
        "fadDollyEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faDollyEmpty'),
        "fadDollyFlatbed": () => import('@fortawesome/pro-duotone-svg-icons/faDollyFlatbed'),
        "fadDollyFlatbedAlt": () => import('@fortawesome/pro-duotone-svg-icons/faDollyFlatbedAlt'),
        "fadDollyFlatbedEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faDollyFlatbedEmpty'),
        "fadDonate": () => import('@fortawesome/pro-duotone-svg-icons/faDonate'),
        "fadDoorClosed": () => import('@fortawesome/pro-duotone-svg-icons/faDoorClosed'),
        "fadDoorOpen": () => import('@fortawesome/pro-duotone-svg-icons/faDoorOpen'),
        "fadDotCircle": () => import('@fortawesome/pro-duotone-svg-icons/faDotCircle'),
        "fadDove": () => import('@fortawesome/pro-duotone-svg-icons/faDove'),
        "fadDownload": () => import('@fortawesome/pro-duotone-svg-icons/faDownload'),
        "fadDraftingCompass": () => import('@fortawesome/pro-duotone-svg-icons/faDraftingCompass'),
        "fadDragon": () => import('@fortawesome/pro-duotone-svg-icons/faDragon'),
        "fadDrawCircle": () => import('@fortawesome/pro-duotone-svg-icons/faDrawCircle'),
        "fadDrawPolygon": () => import('@fortawesome/pro-duotone-svg-icons/faDrawPolygon'),
        "fadDrawSquare": () => import('@fortawesome/pro-duotone-svg-icons/faDrawSquare'),
        "fadDreidel": () => import('@fortawesome/pro-duotone-svg-icons/faDreidel'),
        "fadDrone": () => import('@fortawesome/pro-duotone-svg-icons/faDrone'),
        "fadDroneAlt": () => import('@fortawesome/pro-duotone-svg-icons/faDroneAlt'),
        "fadDrum": () => import('@fortawesome/pro-duotone-svg-icons/faDrum'),
        "fadDrumSteelpan": () => import('@fortawesome/pro-duotone-svg-icons/faDrumSteelpan'),
        "fadDrumstick": () => import('@fortawesome/pro-duotone-svg-icons/faDrumstick'),
        "fadDrumstickBite": () => import('@fortawesome/pro-duotone-svg-icons/faDrumstickBite'),
        "fadDryer": () => import('@fortawesome/pro-duotone-svg-icons/faDryer'),
        "fadDryerAlt": () => import('@fortawesome/pro-duotone-svg-icons/faDryerAlt'),
        "fadDuck": () => import('@fortawesome/pro-duotone-svg-icons/faDuck'),
        "fadDumbbell": () => import('@fortawesome/pro-duotone-svg-icons/faDumbbell'),
        "fadDumpster": () => import('@fortawesome/pro-duotone-svg-icons/faDumpster'),
        "fadDumpsterFire": () => import('@fortawesome/pro-duotone-svg-icons/faDumpsterFire'),
        "fadDungeon": () => import('@fortawesome/pro-duotone-svg-icons/faDungeon'),
        "fadEar": () => import('@fortawesome/pro-duotone-svg-icons/faEar'),
        "fadEarMuffs": () => import('@fortawesome/pro-duotone-svg-icons/faEarMuffs'),
        "fadEclipse": () => import('@fortawesome/pro-duotone-svg-icons/faEclipse'),
        "fadEclipseAlt": () => import('@fortawesome/pro-duotone-svg-icons/faEclipseAlt'),
        "fadEdit": () => import('@fortawesome/pro-duotone-svg-icons/faEdit'),
        "fadEgg": () => import('@fortawesome/pro-duotone-svg-icons/faEgg'),
        "fadEggFried": () => import('@fortawesome/pro-duotone-svg-icons/faEggFried'),
        "fadEject": () => import('@fortawesome/pro-duotone-svg-icons/faEject'),
        "fadElephant": () => import('@fortawesome/pro-duotone-svg-icons/faElephant'),
        "fadEllipsisH": () => import('@fortawesome/pro-duotone-svg-icons/faEllipsisH'),
        "fadEllipsisHAlt": () => import('@fortawesome/pro-duotone-svg-icons/faEllipsisHAlt'),
        "fadEllipsisV": () => import('@fortawesome/pro-duotone-svg-icons/faEllipsisV'),
        "fadEllipsisVAlt": () => import('@fortawesome/pro-duotone-svg-icons/faEllipsisVAlt'),
        "fadEmptySet": () => import('@fortawesome/pro-duotone-svg-icons/faEmptySet'),
        "fadEngineWarning": () => import('@fortawesome/pro-duotone-svg-icons/faEngineWarning'),
        "fadEnvelope": () => import('@fortawesome/pro-duotone-svg-icons/faEnvelope'),
        "fadEnvelopeOpen": () => import('@fortawesome/pro-duotone-svg-icons/faEnvelopeOpen'),
        "fadEnvelopeOpenDollar": () => import('@fortawesome/pro-duotone-svg-icons/faEnvelopeOpenDollar'),
        "fadEnvelopeOpenText": () => import('@fortawesome/pro-duotone-svg-icons/faEnvelopeOpenText'),
        "fadEnvelopeSquare": () => import('@fortawesome/pro-duotone-svg-icons/faEnvelopeSquare'),
        "fadEquals": () => import('@fortawesome/pro-duotone-svg-icons/faEquals'),
        "fadEraser": () => import('@fortawesome/pro-duotone-svg-icons/faEraser'),
        "fadEthernet": () => import('@fortawesome/pro-duotone-svg-icons/faEthernet'),
        "fadEuroSign": () => import('@fortawesome/pro-duotone-svg-icons/faEuroSign'),
        "fadExchange": () => import('@fortawesome/pro-duotone-svg-icons/faExchange'),
        "fadExchangeAlt": () => import('@fortawesome/pro-duotone-svg-icons/faExchangeAlt'),
        "fadExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faExclamation'),
        "fadExclamationCircle": () => import('@fortawesome/pro-duotone-svg-icons/faExclamationCircle'),
        "fadExclamationSquare": () => import('@fortawesome/pro-duotone-svg-icons/faExclamationSquare'),
        "fadExclamationTriangle": () => import('@fortawesome/pro-duotone-svg-icons/faExclamationTriangle'),
        "fadExpand": () => import('@fortawesome/pro-duotone-svg-icons/faExpand'),
        "fadExpandAlt": () => import('@fortawesome/pro-duotone-svg-icons/faExpandAlt'),
        "fadExpandArrows": () => import('@fortawesome/pro-duotone-svg-icons/faExpandArrows'),
        "fadExpandArrowsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faExpandArrowsAlt'),
        "fadExpandWide": () => import('@fortawesome/pro-duotone-svg-icons/faExpandWide'),
        "fadExternalLink": () => import('@fortawesome/pro-duotone-svg-icons/faExternalLink'),
        "fadExternalLinkAlt": () => import('@fortawesome/pro-duotone-svg-icons/faExternalLinkAlt'),
        "fadExternalLinkSquare": () => import('@fortawesome/pro-duotone-svg-icons/faExternalLinkSquare'),
        "fadExternalLinkSquareAlt": () => import('@fortawesome/pro-duotone-svg-icons/faExternalLinkSquareAlt'),
        "fadEye": () => import('@fortawesome/pro-duotone-svg-icons/faEye'),
        "fadEyeDropper": () => import('@fortawesome/pro-duotone-svg-icons/faEyeDropper'),
        "fadEyeEvil": () => import('@fortawesome/pro-duotone-svg-icons/faEyeEvil'),
        "fadEyeSlash": () => import('@fortawesome/pro-duotone-svg-icons/faEyeSlash'),
        "fadFan": () => import('@fortawesome/pro-duotone-svg-icons/faFan'),
        "fadFanTable": () => import('@fortawesome/pro-duotone-svg-icons/faFanTable'),
        "fadFarm": () => import('@fortawesome/pro-duotone-svg-icons/faFarm'),
        "fadFastBackward": () => import('@fortawesome/pro-duotone-svg-icons/faFastBackward'),
        "fadFastForward": () => import('@fortawesome/pro-duotone-svg-icons/faFastForward'),
        "fadFaucet": () => import('@fortawesome/pro-duotone-svg-icons/faFaucet'),
        "fadFaucetDrip": () => import('@fortawesome/pro-duotone-svg-icons/faFaucetDrip'),
        "fadFax": () => import('@fortawesome/pro-duotone-svg-icons/faFax'),
        "fadFeather": () => import('@fortawesome/pro-duotone-svg-icons/faFeather'),
        "fadFeatherAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFeatherAlt'),
        "fadFemale": () => import('@fortawesome/pro-duotone-svg-icons/faFemale'),
        "fadFieldHockey": () => import('@fortawesome/pro-duotone-svg-icons/faFieldHockey'),
        "fadFighterJet": () => import('@fortawesome/pro-duotone-svg-icons/faFighterJet'),
        "fadFile": () => import('@fortawesome/pro-duotone-svg-icons/faFile'),
        "fadFileAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFileAlt'),
        "fadFileArchive": () => import('@fortawesome/pro-duotone-svg-icons/faFileArchive'),
        "fadFileAudio": () => import('@fortawesome/pro-duotone-svg-icons/faFileAudio'),
        "fadFileCertificate": () => import('@fortawesome/pro-duotone-svg-icons/faFileCertificate'),
        "fadFileChartLine": () => import('@fortawesome/pro-duotone-svg-icons/faFileChartLine'),
        "fadFileChartPie": () => import('@fortawesome/pro-duotone-svg-icons/faFileChartPie'),
        "fadFileCheck": () => import('@fortawesome/pro-duotone-svg-icons/faFileCheck'),
        "fadFileCode": () => import('@fortawesome/pro-duotone-svg-icons/faFileCode'),
        "fadFileContract": () => import('@fortawesome/pro-duotone-svg-icons/faFileContract'),
        "fadFileCsv": () => import('@fortawesome/pro-duotone-svg-icons/faFileCsv'),
        "fadFileDownload": () => import('@fortawesome/pro-duotone-svg-icons/faFileDownload'),
        "fadFileEdit": () => import('@fortawesome/pro-duotone-svg-icons/faFileEdit'),
        "fadFileExcel": () => import('@fortawesome/pro-duotone-svg-icons/faFileExcel'),
        "fadFileExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faFileExclamation'),
        "fadFileExport": () => import('@fortawesome/pro-duotone-svg-icons/faFileExport'),
        "fadFileImage": () => import('@fortawesome/pro-duotone-svg-icons/faFileImage'),
        "fadFileImport": () => import('@fortawesome/pro-duotone-svg-icons/faFileImport'),
        "fadFileInvoice": () => import('@fortawesome/pro-duotone-svg-icons/faFileInvoice'),
        "fadFileInvoiceDollar": () => import('@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar'),
        "fadFileMedical": () => import('@fortawesome/pro-duotone-svg-icons/faFileMedical'),
        "fadFileMedicalAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFileMedicalAlt'),
        "fadFileMinus": () => import('@fortawesome/pro-duotone-svg-icons/faFileMinus'),
        "fadFileMusic": () => import('@fortawesome/pro-duotone-svg-icons/faFileMusic'),
        "fadFilePdf": () => import('@fortawesome/pro-duotone-svg-icons/faFilePdf'),
        "fadFilePlus": () => import('@fortawesome/pro-duotone-svg-icons/faFilePlus'),
        "fadFilePowerpoint": () => import('@fortawesome/pro-duotone-svg-icons/faFilePowerpoint'),
        "fadFilePrescription": () => import('@fortawesome/pro-duotone-svg-icons/faFilePrescription'),
        "fadFileSearch": () => import('@fortawesome/pro-duotone-svg-icons/faFileSearch'),
        "fadFileSignature": () => import('@fortawesome/pro-duotone-svg-icons/faFileSignature'),
        "fadFileSpreadsheet": () => import('@fortawesome/pro-duotone-svg-icons/faFileSpreadsheet'),
        "fadFileTimes": () => import('@fortawesome/pro-duotone-svg-icons/faFileTimes'),
        "fadFileUpload": () => import('@fortawesome/pro-duotone-svg-icons/faFileUpload'),
        "fadFileUser": () => import('@fortawesome/pro-duotone-svg-icons/faFileUser'),
        "fadFileVideo": () => import('@fortawesome/pro-duotone-svg-icons/faFileVideo'),
        "fadFileWord": () => import('@fortawesome/pro-duotone-svg-icons/faFileWord'),
        "fadFilesMedical": () => import('@fortawesome/pro-duotone-svg-icons/faFilesMedical'),
        "fadFill": () => import('@fortawesome/pro-duotone-svg-icons/faFill'),
        "fadFillDrip": () => import('@fortawesome/pro-duotone-svg-icons/faFillDrip'),
        "fadFilm": () => import('@fortawesome/pro-duotone-svg-icons/faFilm'),
        "fadFilmAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFilmAlt'),
        "fadFilmCanister": () => import('@fortawesome/pro-duotone-svg-icons/faFilmCanister'),
        "fadFilter": () => import('@fortawesome/pro-duotone-svg-icons/faFilter'),
        "fadFingerprint": () => import('@fortawesome/pro-duotone-svg-icons/faFingerprint'),
        "fadFire": () => import('@fortawesome/pro-duotone-svg-icons/faFire'),
        "fadFireAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFireAlt'),
        "fadFireExtinguisher": () => import('@fortawesome/pro-duotone-svg-icons/faFireExtinguisher'),
        "fadFireSmoke": () => import('@fortawesome/pro-duotone-svg-icons/faFireSmoke'),
        "fadFireplace": () => import('@fortawesome/pro-duotone-svg-icons/faFireplace'),
        "fadFirstAid": () => import('@fortawesome/pro-duotone-svg-icons/faFirstAid'),
        "fadFish": () => import('@fortawesome/pro-duotone-svg-icons/faFish'),
        "fadFishCooked": () => import('@fortawesome/pro-duotone-svg-icons/faFishCooked'),
        "fadFistRaised": () => import('@fortawesome/pro-duotone-svg-icons/faFistRaised'),
        "fadFlag": () => import('@fortawesome/pro-duotone-svg-icons/faFlag'),
        "fadFlagAlt": () => import('@fortawesome/pro-duotone-svg-icons/faFlagAlt'),
        "fadFlagCheckered": () => import('@fortawesome/pro-duotone-svg-icons/faFlagCheckered'),
        "fadFlagUsa": () => import('@fortawesome/pro-duotone-svg-icons/faFlagUsa'),
        "fadFlame": () => import('@fortawesome/pro-duotone-svg-icons/faFlame'),
        "fadFlashlight": () => import('@fortawesome/pro-duotone-svg-icons/faFlashlight'),
        "fadFlask": () => import('@fortawesome/pro-duotone-svg-icons/faFlask'),
        "fadFlaskPoison": () => import('@fortawesome/pro-duotone-svg-icons/faFlaskPoison'),
        "fadFlaskPotion": () => import('@fortawesome/pro-duotone-svg-icons/faFlaskPotion'),
        "fadFlower": () => import('@fortawesome/pro-duotone-svg-icons/faFlower'),
        "fadFlowerDaffodil": () => import('@fortawesome/pro-duotone-svg-icons/faFlowerDaffodil'),
        "fadFlowerTulip": () => import('@fortawesome/pro-duotone-svg-icons/faFlowerTulip'),
        "fadFlushed": () => import('@fortawesome/pro-duotone-svg-icons/faFlushed'),
        "fadFlute": () => import('@fortawesome/pro-duotone-svg-icons/faFlute'),
        "fadFluxCapacitor": () => import('@fortawesome/pro-duotone-svg-icons/faFluxCapacitor'),
        "fadFog": () => import('@fortawesome/pro-duotone-svg-icons/faFog'),
        "fadFolder": () => import('@fortawesome/pro-duotone-svg-icons/faFolder'),
        "fadFolderDownload": () => import('@fortawesome/pro-duotone-svg-icons/faFolderDownload'),
        "fadFolderMinus": () => import('@fortawesome/pro-duotone-svg-icons/faFolderMinus'),
        "fadFolderOpen": () => import('@fortawesome/pro-duotone-svg-icons/faFolderOpen'),
        "fadFolderPlus": () => import('@fortawesome/pro-duotone-svg-icons/faFolderPlus'),
        "fadFolderTimes": () => import('@fortawesome/pro-duotone-svg-icons/faFolderTimes'),
        "fadFolderTree": () => import('@fortawesome/pro-duotone-svg-icons/faFolderTree'),
        "fadFolderUpload": () => import('@fortawesome/pro-duotone-svg-icons/faFolderUpload'),
        "fadFolders": () => import('@fortawesome/pro-duotone-svg-icons/faFolders'),
        "fadFont": () => import('@fortawesome/pro-duotone-svg-icons/faFont'),
        "fadFontAwesomeLogoFull": () => import('@fortawesome/pro-duotone-svg-icons/faFontAwesomeLogoFull'),
        "fadFontCase": () => import('@fortawesome/pro-duotone-svg-icons/faFontCase'),
        "fadFootballBall": () => import('@fortawesome/pro-duotone-svg-icons/faFootballBall'),
        "fadFootballHelmet": () => import('@fortawesome/pro-duotone-svg-icons/faFootballHelmet'),
        "fadForklift": () => import('@fortawesome/pro-duotone-svg-icons/faForklift'),
        "fadForward": () => import('@fortawesome/pro-duotone-svg-icons/faForward'),
        "fadFragile": () => import('@fortawesome/pro-duotone-svg-icons/faFragile'),
        "fadFrenchFries": () => import('@fortawesome/pro-duotone-svg-icons/faFrenchFries'),
        "fadFrog": () => import('@fortawesome/pro-duotone-svg-icons/faFrog'),
        "fadFrostyHead": () => import('@fortawesome/pro-duotone-svg-icons/faFrostyHead'),
        "fadFrown": () => import('@fortawesome/pro-duotone-svg-icons/faFrown'),
        "fadFrownOpen": () => import('@fortawesome/pro-duotone-svg-icons/faFrownOpen'),
        "fadFunction": () => import('@fortawesome/pro-duotone-svg-icons/faFunction'),
        "fadFunnelDollar": () => import('@fortawesome/pro-duotone-svg-icons/faFunnelDollar'),
        "fadFutbol": () => import('@fortawesome/pro-duotone-svg-icons/faFutbol'),
        "fadGalaxy": () => import('@fortawesome/pro-duotone-svg-icons/faGalaxy'),
        "fadGameBoard": () => import('@fortawesome/pro-duotone-svg-icons/faGameBoard'),
        "fadGameBoardAlt": () => import('@fortawesome/pro-duotone-svg-icons/faGameBoardAlt'),
        "fadGameConsoleHandheld": () => import('@fortawesome/pro-duotone-svg-icons/faGameConsoleHandheld'),
        "fadGamepad": () => import('@fortawesome/pro-duotone-svg-icons/faGamepad'),
        "fadGamepadAlt": () => import('@fortawesome/pro-duotone-svg-icons/faGamepadAlt'),
        "fadGarage": () => import('@fortawesome/pro-duotone-svg-icons/faGarage'),
        "fadGarageCar": () => import('@fortawesome/pro-duotone-svg-icons/faGarageCar'),
        "fadGarageOpen": () => import('@fortawesome/pro-duotone-svg-icons/faGarageOpen'),
        "fadGasPump": () => import('@fortawesome/pro-duotone-svg-icons/faGasPump'),
        "fadGasPumpSlash": () => import('@fortawesome/pro-duotone-svg-icons/faGasPumpSlash'),
        "fadGavel": () => import('@fortawesome/pro-duotone-svg-icons/faGavel'),
        "fadGem": () => import('@fortawesome/pro-duotone-svg-icons/faGem'),
        "fadGenderless": () => import('@fortawesome/pro-duotone-svg-icons/faGenderless'),
        "fadGhost": () => import('@fortawesome/pro-duotone-svg-icons/faGhost'),
        "fadGift": () => import('@fortawesome/pro-duotone-svg-icons/faGift'),
        "fadGiftCard": () => import('@fortawesome/pro-duotone-svg-icons/faGiftCard'),
        "fadGifts": () => import('@fortawesome/pro-duotone-svg-icons/faGifts'),
        "fadGingerbreadMan": () => import('@fortawesome/pro-duotone-svg-icons/faGingerbreadMan'),
        "fadGlass": () => import('@fortawesome/pro-duotone-svg-icons/faGlass'),
        "fadGlassChampagne": () => import('@fortawesome/pro-duotone-svg-icons/faGlassChampagne'),
        "fadGlassCheers": () => import('@fortawesome/pro-duotone-svg-icons/faGlassCheers'),
        "fadGlassCitrus": () => import('@fortawesome/pro-duotone-svg-icons/faGlassCitrus'),
        "fadGlassMartini": () => import('@fortawesome/pro-duotone-svg-icons/faGlassMartini'),
        "fadGlassMartiniAlt": () => import('@fortawesome/pro-duotone-svg-icons/faGlassMartiniAlt'),
        "fadGlassWhiskey": () => import('@fortawesome/pro-duotone-svg-icons/faGlassWhiskey'),
        "fadGlassWhiskeyRocks": () => import('@fortawesome/pro-duotone-svg-icons/faGlassWhiskeyRocks'),
        "fadGlasses": () => import('@fortawesome/pro-duotone-svg-icons/faGlasses'),
        "fadGlassesAlt": () => import('@fortawesome/pro-duotone-svg-icons/faGlassesAlt'),
        "fadGlobe": () => import('@fortawesome/pro-duotone-svg-icons/faGlobe'),
        "fadGlobeAfrica": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeAfrica'),
        "fadGlobeAmericas": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeAmericas'),
        "fadGlobeAsia": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeAsia'),
        "fadGlobeEurope": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeEurope'),
        "fadGlobeSnow": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeSnow'),
        "fadGlobeStand": () => import('@fortawesome/pro-duotone-svg-icons/faGlobeStand'),
        "fadGolfBall": () => import('@fortawesome/pro-duotone-svg-icons/faGolfBall'),
        "fadGolfClub": () => import('@fortawesome/pro-duotone-svg-icons/faGolfClub'),
        "fadGopuram": () => import('@fortawesome/pro-duotone-svg-icons/faGopuram'),
        "fadGraduationCap": () => import('@fortawesome/pro-duotone-svg-icons/faGraduationCap'),
        "fadGramophone": () => import('@fortawesome/pro-duotone-svg-icons/faGramophone'),
        "fadGreaterThan": () => import('@fortawesome/pro-duotone-svg-icons/faGreaterThan'),
        "fadGreaterThanEqual": () => import('@fortawesome/pro-duotone-svg-icons/faGreaterThanEqual'),
        "fadGrimace": () => import('@fortawesome/pro-duotone-svg-icons/faGrimace'),
        "fadGrin": () => import('@fortawesome/pro-duotone-svg-icons/faGrin'),
        "fadGrinAlt": () => import('@fortawesome/pro-duotone-svg-icons/faGrinAlt'),
        "fadGrinBeam": () => import('@fortawesome/pro-duotone-svg-icons/faGrinBeam'),
        "fadGrinBeamSweat": () => import('@fortawesome/pro-duotone-svg-icons/faGrinBeamSweat'),
        "fadGrinHearts": () => import('@fortawesome/pro-duotone-svg-icons/faGrinHearts'),
        "fadGrinSquint": () => import('@fortawesome/pro-duotone-svg-icons/faGrinSquint'),
        "fadGrinSquintTears": () => import('@fortawesome/pro-duotone-svg-icons/faGrinSquintTears'),
        "fadGrinStars": () => import('@fortawesome/pro-duotone-svg-icons/faGrinStars'),
        "fadGrinTears": () => import('@fortawesome/pro-duotone-svg-icons/faGrinTears'),
        "fadGrinTongue": () => import('@fortawesome/pro-duotone-svg-icons/faGrinTongue'),
        "fadGrinTongueSquint": () => import('@fortawesome/pro-duotone-svg-icons/faGrinTongueSquint'),
        "fadGrinTongueWink": () => import('@fortawesome/pro-duotone-svg-icons/faGrinTongueWink'),
        "fadGrinWink": () => import('@fortawesome/pro-duotone-svg-icons/faGrinWink'),
        "fadGripHorizontal": () => import('@fortawesome/pro-duotone-svg-icons/faGripHorizontal'),
        "fadGripLines": () => import('@fortawesome/pro-duotone-svg-icons/faGripLines'),
        "fadGripLinesVertical": () => import('@fortawesome/pro-duotone-svg-icons/faGripLinesVertical'),
        "fadGripVertical": () => import('@fortawesome/pro-duotone-svg-icons/faGripVertical'),
        "fadGuitar": () => import('@fortawesome/pro-duotone-svg-icons/faGuitar'),
        "fadGuitarElectric": () => import('@fortawesome/pro-duotone-svg-icons/faGuitarElectric'),
        "fadGuitars": () => import('@fortawesome/pro-duotone-svg-icons/faGuitars'),
        "fadH1": () => import('@fortawesome/pro-duotone-svg-icons/faH1'),
        "fadH2": () => import('@fortawesome/pro-duotone-svg-icons/faH2'),
        "fadH3": () => import('@fortawesome/pro-duotone-svg-icons/faH3'),
        "fadH4": () => import('@fortawesome/pro-duotone-svg-icons/faH4'),
        "fadHSquare": () => import('@fortawesome/pro-duotone-svg-icons/faHSquare'),
        "fadHamburger": () => import('@fortawesome/pro-duotone-svg-icons/faHamburger'),
        "fadHammer": () => import('@fortawesome/pro-duotone-svg-icons/faHammer'),
        "fadHammerWar": () => import('@fortawesome/pro-duotone-svg-icons/faHammerWar'),
        "fadHamsa": () => import('@fortawesome/pro-duotone-svg-icons/faHamsa'),
        "fadHandHeart": () => import('@fortawesome/pro-duotone-svg-icons/faHandHeart'),
        "fadHandHolding": () => import('@fortawesome/pro-duotone-svg-icons/faHandHolding'),
        "fadHandHoldingBox": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingBox'),
        "fadHandHoldingHeart": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingHeart'),
        "fadHandHoldingMagic": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingMagic'),
        "fadHandHoldingMedical": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingMedical'),
        "fadHandHoldingSeedling": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingSeedling'),
        "fadHandHoldingUsd": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd'),
        "fadHandHoldingWater": () => import('@fortawesome/pro-duotone-svg-icons/faHandHoldingWater'),
        "fadHandLizard": () => import('@fortawesome/pro-duotone-svg-icons/faHandLizard'),
        "fadHandMiddleFinger": () => import('@fortawesome/pro-duotone-svg-icons/faHandMiddleFinger'),
        "fadHandPaper": () => import('@fortawesome/pro-duotone-svg-icons/faHandPaper'),
        "fadHandPeace": () => import('@fortawesome/pro-duotone-svg-icons/faHandPeace'),
        "fadHandPointDown": () => import('@fortawesome/pro-duotone-svg-icons/faHandPointDown'),
        "fadHandPointLeft": () => import('@fortawesome/pro-duotone-svg-icons/faHandPointLeft'),
        "fadHandPointRight": () => import('@fortawesome/pro-duotone-svg-icons/faHandPointRight'),
        "fadHandPointUp": () => import('@fortawesome/pro-duotone-svg-icons/faHandPointUp'),
        "fadHandPointer": () => import('@fortawesome/pro-duotone-svg-icons/faHandPointer'),
        "fadHandReceiving": () => import('@fortawesome/pro-duotone-svg-icons/faHandReceiving'),
        "fadHandRock": () => import('@fortawesome/pro-duotone-svg-icons/faHandRock'),
        "fadHandScissors": () => import('@fortawesome/pro-duotone-svg-icons/faHandScissors'),
        "fadHandSparkles": () => import('@fortawesome/pro-duotone-svg-icons/faHandSparkles'),
        "fadHandSpock": () => import('@fortawesome/pro-duotone-svg-icons/faHandSpock'),
        "fadHands": () => import('@fortawesome/pro-duotone-svg-icons/faHands'),
        "fadHandsHeart": () => import('@fortawesome/pro-duotone-svg-icons/faHandsHeart'),
        "fadHandsHelping": () => import('@fortawesome/pro-duotone-svg-icons/faHandsHelping'),
        "fadHandsUsd": () => import('@fortawesome/pro-duotone-svg-icons/faHandsUsd'),
        "fadHandsWash": () => import('@fortawesome/pro-duotone-svg-icons/faHandsWash'),
        "fadHandshake": () => import('@fortawesome/pro-duotone-svg-icons/faHandshake'),
        "fadHandshakeAlt": () => import('@fortawesome/pro-duotone-svg-icons/faHandshakeAlt'),
        "fadHandshakeAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faHandshakeAltSlash'),
        "fadHandshakeSlash": () => import('@fortawesome/pro-duotone-svg-icons/faHandshakeSlash'),
        "fadHanukiah": () => import('@fortawesome/pro-duotone-svg-icons/faHanukiah'),
        "fadHardHat": () => import('@fortawesome/pro-duotone-svg-icons/faHardHat'),
        "fadHashtag": () => import('@fortawesome/pro-duotone-svg-icons/faHashtag'),
        "fadHatChef": () => import('@fortawesome/pro-duotone-svg-icons/faHatChef'),
        "fadHatCowboy": () => import('@fortawesome/pro-duotone-svg-icons/faHatCowboy'),
        "fadHatCowboySide": () => import('@fortawesome/pro-duotone-svg-icons/faHatCowboySide'),
        "fadHatSanta": () => import('@fortawesome/pro-duotone-svg-icons/faHatSanta'),
        "fadHatWinter": () => import('@fortawesome/pro-duotone-svg-icons/faHatWinter'),
        "fadHatWitch": () => import('@fortawesome/pro-duotone-svg-icons/faHatWitch'),
        "fadHatWizard": () => import('@fortawesome/pro-duotone-svg-icons/faHatWizard'),
        "fadHdd": () => import('@fortawesome/pro-duotone-svg-icons/faHdd'),
        "fadHeadSide": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSide'),
        "fadHeadSideBrain": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideBrain'),
        "fadHeadSideCough": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideCough'),
        "fadHeadSideCoughSlash": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideCoughSlash'),
        "fadHeadSideHeadphones": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideHeadphones'),
        "fadHeadSideMask": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideMask'),
        "fadHeadSideMedical": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideMedical'),
        "fadHeadSideVirus": () => import('@fortawesome/pro-duotone-svg-icons/faHeadSideVirus'),
        "fadHeadVr": () => import('@fortawesome/pro-duotone-svg-icons/faHeadVr'),
        "fadHeading": () => import('@fortawesome/pro-duotone-svg-icons/faHeading'),
        "fadHeadphones": () => import('@fortawesome/pro-duotone-svg-icons/faHeadphones'),
        "fadHeadphonesAlt": () => import('@fortawesome/pro-duotone-svg-icons/faHeadphonesAlt'),
        "fadHeadset": () => import('@fortawesome/pro-duotone-svg-icons/faHeadset'),
        "fadHeart": () => import('@fortawesome/pro-duotone-svg-icons/faHeart'),
        "fadHeartBroken": () => import('@fortawesome/pro-duotone-svg-icons/faHeartBroken'),
        "fadHeartCircle": () => import('@fortawesome/pro-duotone-svg-icons/faHeartCircle'),
        "fadHeartRate": () => import('@fortawesome/pro-duotone-svg-icons/faHeartRate'),
        "fadHeartSquare": () => import('@fortawesome/pro-duotone-svg-icons/faHeartSquare'),
        "fadHeartbeat": () => import('@fortawesome/pro-duotone-svg-icons/faHeartbeat'),
        "fadHeat": () => import('@fortawesome/pro-duotone-svg-icons/faHeat'),
        "fadHelicopter": () => import('@fortawesome/pro-duotone-svg-icons/faHelicopter'),
        "fadHelmetBattle": () => import('@fortawesome/pro-duotone-svg-icons/faHelmetBattle'),
        "fadHexagon": () => import('@fortawesome/pro-duotone-svg-icons/faHexagon'),
        "fadHighlighter": () => import('@fortawesome/pro-duotone-svg-icons/faHighlighter'),
        "fadHiking": () => import('@fortawesome/pro-duotone-svg-icons/faHiking'),
        "fadHippo": () => import('@fortawesome/pro-duotone-svg-icons/faHippo'),
        "fadHistory": () => import('@fortawesome/pro-duotone-svg-icons/faHistory'),
        "fadHockeyMask": () => import('@fortawesome/pro-duotone-svg-icons/faHockeyMask'),
        "fadHockeyPuck": () => import('@fortawesome/pro-duotone-svg-icons/faHockeyPuck'),
        "fadHockeySticks": () => import('@fortawesome/pro-duotone-svg-icons/faHockeySticks'),
        "fadHollyBerry": () => import('@fortawesome/pro-duotone-svg-icons/faHollyBerry'),
        "fadHome": () => import('@fortawesome/pro-duotone-svg-icons/faHome'),
        "fadHomeAlt": () => import('@fortawesome/pro-duotone-svg-icons/faHomeAlt'),
        "fadHomeHeart": () => import('@fortawesome/pro-duotone-svg-icons/faHomeHeart'),
        "fadHomeLg": () => import('@fortawesome/pro-duotone-svg-icons/faHomeLg'),
        "fadHomeLgAlt": () => import('@fortawesome/pro-duotone-svg-icons/faHomeLgAlt'),
        "fadHoodCloak": () => import('@fortawesome/pro-duotone-svg-icons/faHoodCloak'),
        "fadHorizontalRule": () => import('@fortawesome/pro-duotone-svg-icons/faHorizontalRule'),
        "fadHorse": () => import('@fortawesome/pro-duotone-svg-icons/faHorse'),
        "fadHorseHead": () => import('@fortawesome/pro-duotone-svg-icons/faHorseHead'),
        "fadHorseSaddle": () => import('@fortawesome/pro-duotone-svg-icons/faHorseSaddle'),
        "fadHospital": () => import('@fortawesome/pro-duotone-svg-icons/faHospital'),
        "fadHospitalAlt": () => import('@fortawesome/pro-duotone-svg-icons/faHospitalAlt'),
        "fadHospitalSymbol": () => import('@fortawesome/pro-duotone-svg-icons/faHospitalSymbol'),
        "fadHospitalUser": () => import('@fortawesome/pro-duotone-svg-icons/faHospitalUser'),
        "fadHospitals": () => import('@fortawesome/pro-duotone-svg-icons/faHospitals'),
        "fadHotTub": () => import('@fortawesome/pro-duotone-svg-icons/faHotTub'),
        "fadHotdog": () => import('@fortawesome/pro-duotone-svg-icons/faHotdog'),
        "fadHotel": () => import('@fortawesome/pro-duotone-svg-icons/faHotel'),
        "fadHourglass": () => import('@fortawesome/pro-duotone-svg-icons/faHourglass'),
        "fadHourglassEnd": () => import('@fortawesome/pro-duotone-svg-icons/faHourglassEnd'),
        "fadHourglassHalf": () => import('@fortawesome/pro-duotone-svg-icons/faHourglassHalf'),
        "fadHourglassStart": () => import('@fortawesome/pro-duotone-svg-icons/faHourglassStart'),
        "fadHouse": () => import('@fortawesome/pro-duotone-svg-icons/faHouse'),
        "fadHouseDamage": () => import('@fortawesome/pro-duotone-svg-icons/faHouseDamage'),
        "fadHouseDay": () => import('@fortawesome/pro-duotone-svg-icons/faHouseDay'),
        "fadHouseFlood": () => import('@fortawesome/pro-duotone-svg-icons/faHouseFlood'),
        "fadHouseLeave": () => import('@fortawesome/pro-duotone-svg-icons/faHouseLeave'),
        "fadHouseNight": () => import('@fortawesome/pro-duotone-svg-icons/faHouseNight'),
        "fadHouseReturn": () => import('@fortawesome/pro-duotone-svg-icons/faHouseReturn'),
        "fadHouseSignal": () => import('@fortawesome/pro-duotone-svg-icons/faHouseSignal'),
        "fadHouseUser": () => import('@fortawesome/pro-duotone-svg-icons/faHouseUser'),
        "fadHryvnia": () => import('@fortawesome/pro-duotone-svg-icons/faHryvnia'),
        "fadHumidity": () => import('@fortawesome/pro-duotone-svg-icons/faHumidity'),
        "fadHurricane": () => import('@fortawesome/pro-duotone-svg-icons/faHurricane'),
        "fadICursor": () => import('@fortawesome/pro-duotone-svg-icons/faICursor'),
        "fadIceCream": () => import('@fortawesome/pro-duotone-svg-icons/faIceCream'),
        "fadIceSkate": () => import('@fortawesome/pro-duotone-svg-icons/faIceSkate'),
        "fadIcicles": () => import('@fortawesome/pro-duotone-svg-icons/faIcicles'),
        "fadIcons": () => import('@fortawesome/pro-duotone-svg-icons/faIcons'),
        "fadIconsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faIconsAlt'),
        "fadIdBadge": () => import('@fortawesome/pro-duotone-svg-icons/faIdBadge'),
        "fadIdCard": () => import('@fortawesome/pro-duotone-svg-icons/faIdCard'),
        "fadIdCardAlt": () => import('@fortawesome/pro-duotone-svg-icons/faIdCardAlt'),
        "fadIgloo": () => import('@fortawesome/pro-duotone-svg-icons/faIgloo'),
        "fadImage": () => import('@fortawesome/pro-duotone-svg-icons/faImage'),
        "fadImagePolaroid": () => import('@fortawesome/pro-duotone-svg-icons/faImagePolaroid'),
        "fadImages": () => import('@fortawesome/pro-duotone-svg-icons/faImages'),
        "fadInbox": () => import('@fortawesome/pro-duotone-svg-icons/faInbox'),
        "fadInboxIn": () => import('@fortawesome/pro-duotone-svg-icons/faInboxIn'),
        "fadInboxOut": () => import('@fortawesome/pro-duotone-svg-icons/faInboxOut'),
        "fadIndent": () => import('@fortawesome/pro-duotone-svg-icons/faIndent'),
        "fadIndustry": () => import('@fortawesome/pro-duotone-svg-icons/faIndustry'),
        "fadIndustryAlt": () => import('@fortawesome/pro-duotone-svg-icons/faIndustryAlt'),
        "fadInfinity": () => import('@fortawesome/pro-duotone-svg-icons/faInfinity'),
        "fadInfo": () => import('@fortawesome/pro-duotone-svg-icons/faInfo'),
        "fadInfoCircle": () => import('@fortawesome/pro-duotone-svg-icons/faInfoCircle'),
        "fadInfoSquare": () => import('@fortawesome/pro-duotone-svg-icons/faInfoSquare'),
        "fadInhaler": () => import('@fortawesome/pro-duotone-svg-icons/faInhaler'),
        "fadIntegral": () => import('@fortawesome/pro-duotone-svg-icons/faIntegral'),
        "fadIntersection": () => import('@fortawesome/pro-duotone-svg-icons/faIntersection'),
        "fadInventory": () => import('@fortawesome/pro-duotone-svg-icons/faInventory'),
        "fadIslandTropical": () => import('@fortawesome/pro-duotone-svg-icons/faIslandTropical'),
        "fadItalic": () => import('@fortawesome/pro-duotone-svg-icons/faItalic'),
        "fadJackOLantern": () => import('@fortawesome/pro-duotone-svg-icons/faJackOLantern'),
        "fadJedi": () => import('@fortawesome/pro-duotone-svg-icons/faJedi'),
        "fadJoint": () => import('@fortawesome/pro-duotone-svg-icons/faJoint'),
        "fadJournalWhills": () => import('@fortawesome/pro-duotone-svg-icons/faJournalWhills'),
        "fadJoystick": () => import('@fortawesome/pro-duotone-svg-icons/faJoystick'),
        "fadJug": () => import('@fortawesome/pro-duotone-svg-icons/faJug'),
        "fadKaaba": () => import('@fortawesome/pro-duotone-svg-icons/faKaaba'),
        "fadKazoo": () => import('@fortawesome/pro-duotone-svg-icons/faKazoo'),
        "fadKerning": () => import('@fortawesome/pro-duotone-svg-icons/faKerning'),
        "fadKey": () => import('@fortawesome/pro-duotone-svg-icons/faKey'),
        "fadKeySkeleton": () => import('@fortawesome/pro-duotone-svg-icons/faKeySkeleton'),
        "fadKeyboard": () => import('@fortawesome/pro-duotone-svg-icons/faKeyboard'),
        "fadKeynote": () => import('@fortawesome/pro-duotone-svg-icons/faKeynote'),
        "fadKhanda": () => import('@fortawesome/pro-duotone-svg-icons/faKhanda'),
        "fadKidneys": () => import('@fortawesome/pro-duotone-svg-icons/faKidneys'),
        "fadKiss": () => import('@fortawesome/pro-duotone-svg-icons/faKiss'),
        "fadKissBeam": () => import('@fortawesome/pro-duotone-svg-icons/faKissBeam'),
        "fadKissWinkHeart": () => import('@fortawesome/pro-duotone-svg-icons/faKissWinkHeart'),
        "fadKite": () => import('@fortawesome/pro-duotone-svg-icons/faKite'),
        "fadKiwiBird": () => import('@fortawesome/pro-duotone-svg-icons/faKiwiBird'),
        "fadKnifeKitchen": () => import('@fortawesome/pro-duotone-svg-icons/faKnifeKitchen'),
        "fadLambda": () => import('@fortawesome/pro-duotone-svg-icons/faLambda'),
        "fadLamp": () => import('@fortawesome/pro-duotone-svg-icons/faLamp'),
        "fadLampDesk": () => import('@fortawesome/pro-duotone-svg-icons/faLampDesk'),
        "fadLampFloor": () => import('@fortawesome/pro-duotone-svg-icons/faLampFloor'),
        "fadLandmark": () => import('@fortawesome/pro-duotone-svg-icons/faLandmark'),
        "fadLandmarkAlt": () => import('@fortawesome/pro-duotone-svg-icons/faLandmarkAlt'),
        "fadLanguage": () => import('@fortawesome/pro-duotone-svg-icons/faLanguage'),
        "fadLaptop": () => import('@fortawesome/pro-duotone-svg-icons/faLaptop'),
        "fadLaptopCode": () => import('@fortawesome/pro-duotone-svg-icons/faLaptopCode'),
        "fadLaptopHouse": () => import('@fortawesome/pro-duotone-svg-icons/faLaptopHouse'),
        "fadLaptopMedical": () => import('@fortawesome/pro-duotone-svg-icons/faLaptopMedical'),
        "fadLasso": () => import('@fortawesome/pro-duotone-svg-icons/faLasso'),
        "fadLaugh": () => import('@fortawesome/pro-duotone-svg-icons/faLaugh'),
        "fadLaughBeam": () => import('@fortawesome/pro-duotone-svg-icons/faLaughBeam'),
        "fadLaughSquint": () => import('@fortawesome/pro-duotone-svg-icons/faLaughSquint'),
        "fadLaughWink": () => import('@fortawesome/pro-duotone-svg-icons/faLaughWink'),
        "fadLayerGroup": () => import('@fortawesome/pro-duotone-svg-icons/faLayerGroup'),
        "fadLayerMinus": () => import('@fortawesome/pro-duotone-svg-icons/faLayerMinus'),
        "fadLayerPlus": () => import('@fortawesome/pro-duotone-svg-icons/faLayerPlus'),
        "fadLeaf": () => import('@fortawesome/pro-duotone-svg-icons/faLeaf'),
        "fadLeafHeart": () => import('@fortawesome/pro-duotone-svg-icons/faLeafHeart'),
        "fadLeafMaple": () => import('@fortawesome/pro-duotone-svg-icons/faLeafMaple'),
        "fadLeafOak": () => import('@fortawesome/pro-duotone-svg-icons/faLeafOak'),
        "fadLemon": () => import('@fortawesome/pro-duotone-svg-icons/faLemon'),
        "fadLessThan": () => import('@fortawesome/pro-duotone-svg-icons/faLessThan'),
        "fadLessThanEqual": () => import('@fortawesome/pro-duotone-svg-icons/faLessThanEqual'),
        "fadLevelDown": () => import('@fortawesome/pro-duotone-svg-icons/faLevelDown'),
        "fadLevelDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faLevelDownAlt'),
        "fadLevelUp": () => import('@fortawesome/pro-duotone-svg-icons/faLevelUp'),
        "fadLevelUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faLevelUpAlt'),
        "fadLifeRing": () => import('@fortawesome/pro-duotone-svg-icons/faLifeRing'),
        "fadLightCeiling": () => import('@fortawesome/pro-duotone-svg-icons/faLightCeiling'),
        "fadLightSwitch": () => import('@fortawesome/pro-duotone-svg-icons/faLightSwitch'),
        "fadLightSwitchOff": () => import('@fortawesome/pro-duotone-svg-icons/faLightSwitchOff'),
        "fadLightSwitchOn": () => import('@fortawesome/pro-duotone-svg-icons/faLightSwitchOn'),
        "fadLightbulb": () => import('@fortawesome/pro-duotone-svg-icons/faLightbulb'),
        "fadLightbulbDollar": () => import('@fortawesome/pro-duotone-svg-icons/faLightbulbDollar'),
        "fadLightbulbExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faLightbulbExclamation'),
        "fadLightbulbOn": () => import('@fortawesome/pro-duotone-svg-icons/faLightbulbOn'),
        "fadLightbulbSlash": () => import('@fortawesome/pro-duotone-svg-icons/faLightbulbSlash'),
        "fadLightsHoliday": () => import('@fortawesome/pro-duotone-svg-icons/faLightsHoliday'),
        "fadLineColumns": () => import('@fortawesome/pro-duotone-svg-icons/faLineColumns'),
        "fadLineHeight": () => import('@fortawesome/pro-duotone-svg-icons/faLineHeight'),
        "fadLink": () => import('@fortawesome/pro-duotone-svg-icons/faLink'),
        "fadLips": () => import('@fortawesome/pro-duotone-svg-icons/faLips'),
        "fadLiraSign": () => import('@fortawesome/pro-duotone-svg-icons/faLiraSign'),
        "fadList": () => import('@fortawesome/pro-duotone-svg-icons/faList'),
        "fadListAlt": () => import('@fortawesome/pro-duotone-svg-icons/faListAlt'),
        "fadListMusic": () => import('@fortawesome/pro-duotone-svg-icons/faListMusic'),
        "fadListOl": () => import('@fortawesome/pro-duotone-svg-icons/faListOl'),
        "fadListUl": () => import('@fortawesome/pro-duotone-svg-icons/faListUl'),
        "fadLocation": () => import('@fortawesome/pro-duotone-svg-icons/faLocation'),
        "fadLocationArrow": () => import('@fortawesome/pro-duotone-svg-icons/faLocationArrow'),
        "fadLocationCircle": () => import('@fortawesome/pro-duotone-svg-icons/faLocationCircle'),
        "fadLocationSlash": () => import('@fortawesome/pro-duotone-svg-icons/faLocationSlash'),
        "fadLock": () => import('@fortawesome/pro-duotone-svg-icons/faLock'),
        "fadLockAlt": () => import('@fortawesome/pro-duotone-svg-icons/faLockAlt'),
        "fadLockOpen": () => import('@fortawesome/pro-duotone-svg-icons/faLockOpen'),
        "fadLockOpenAlt": () => import('@fortawesome/pro-duotone-svg-icons/faLockOpenAlt'),
        "fadLongArrowAltDown": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowAltDown'),
        "fadLongArrowAltLeft": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowAltLeft'),
        "fadLongArrowAltRight": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowAltRight'),
        "fadLongArrowAltUp": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowAltUp'),
        "fadLongArrowDown": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowDown'),
        "fadLongArrowLeft": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowLeft'),
        "fadLongArrowRight": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowRight'),
        "fadLongArrowUp": () => import('@fortawesome/pro-duotone-svg-icons/faLongArrowUp'),
        "fadLoveseat": () => import('@fortawesome/pro-duotone-svg-icons/faLoveseat'),
        "fadLowVision": () => import('@fortawesome/pro-duotone-svg-icons/faLowVision'),
        "fadLuchador": () => import('@fortawesome/pro-duotone-svg-icons/faLuchador'),
        "fadLuggageCart": () => import('@fortawesome/pro-duotone-svg-icons/faLuggageCart'),
        "fadLungs": () => import('@fortawesome/pro-duotone-svg-icons/faLungs'),
        "fadLungsVirus": () => import('@fortawesome/pro-duotone-svg-icons/faLungsVirus'),
        "fadMace": () => import('@fortawesome/pro-duotone-svg-icons/faMace'),
        "fadMagic": () => import('@fortawesome/pro-duotone-svg-icons/faMagic'),
        "fadMagnet": () => import('@fortawesome/pro-duotone-svg-icons/faMagnet'),
        "fadMailBulk": () => import('@fortawesome/pro-duotone-svg-icons/faMailBulk'),
        "fadMailbox": () => import('@fortawesome/pro-duotone-svg-icons/faMailbox'),
        "fadMale": () => import('@fortawesome/pro-duotone-svg-icons/faMale'),
        "fadMandolin": () => import('@fortawesome/pro-duotone-svg-icons/faMandolin'),
        "fadMap": () => import('@fortawesome/pro-duotone-svg-icons/faMap'),
        "fadMapMarked": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarked'),
        "fadMapMarkedAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt'),
        "fadMapMarker": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarker'),
        "fadMapMarkerAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt'),
        "fadMapMarkerAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerAltSlash'),
        "fadMapMarkerCheck": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerCheck'),
        "fadMapMarkerEdit": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerEdit'),
        "fadMapMarkerExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerExclamation'),
        "fadMapMarkerMinus": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerMinus'),
        "fadMapMarkerPlus": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerPlus'),
        "fadMapMarkerQuestion": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerQuestion'),
        "fadMapMarkerSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerSlash'),
        "fadMapMarkerSmile": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerSmile'),
        "fadMapMarkerTimes": () => import('@fortawesome/pro-duotone-svg-icons/faMapMarkerTimes'),
        "fadMapPin": () => import('@fortawesome/pro-duotone-svg-icons/faMapPin'),
        "fadMapSigns": () => import('@fortawesome/pro-duotone-svg-icons/faMapSigns'),
        "fadMarker": () => import('@fortawesome/pro-duotone-svg-icons/faMarker'),
        "fadMars": () => import('@fortawesome/pro-duotone-svg-icons/faMars'),
        "fadMarsDouble": () => import('@fortawesome/pro-duotone-svg-icons/faMarsDouble'),
        "fadMarsStroke": () => import('@fortawesome/pro-duotone-svg-icons/faMarsStroke'),
        "fadMarsStrokeH": () => import('@fortawesome/pro-duotone-svg-icons/faMarsStrokeH'),
        "fadMarsStrokeV": () => import('@fortawesome/pro-duotone-svg-icons/faMarsStrokeV'),
        "fadMask": () => import('@fortawesome/pro-duotone-svg-icons/faMask'),
        "fadMeat": () => import('@fortawesome/pro-duotone-svg-icons/faMeat'),
        "fadMedal": () => import('@fortawesome/pro-duotone-svg-icons/faMedal'),
        "fadMedkit": () => import('@fortawesome/pro-duotone-svg-icons/faMedkit'),
        "fadMegaphone": () => import('@fortawesome/pro-duotone-svg-icons/faMegaphone'),
        "fadMeh": () => import('@fortawesome/pro-duotone-svg-icons/faMeh'),
        "fadMehBlank": () => import('@fortawesome/pro-duotone-svg-icons/faMehBlank'),
        "fadMehRollingEyes": () => import('@fortawesome/pro-duotone-svg-icons/faMehRollingEyes'),
        "fadMemory": () => import('@fortawesome/pro-duotone-svg-icons/faMemory'),
        "fadMenorah": () => import('@fortawesome/pro-duotone-svg-icons/faMenorah'),
        "fadMercury": () => import('@fortawesome/pro-duotone-svg-icons/faMercury'),
        "fadMeteor": () => import('@fortawesome/pro-duotone-svg-icons/faMeteor'),
        "fadMicrochip": () => import('@fortawesome/pro-duotone-svg-icons/faMicrochip'),
        "fadMicrophone": () => import('@fortawesome/pro-duotone-svg-icons/faMicrophone'),
        "fadMicrophoneAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMicrophoneAlt'),
        "fadMicrophoneAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMicrophoneAltSlash'),
        "fadMicrophoneSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMicrophoneSlash'),
        "fadMicrophoneStand": () => import('@fortawesome/pro-duotone-svg-icons/faMicrophoneStand'),
        "fadMicroscope": () => import('@fortawesome/pro-duotone-svg-icons/faMicroscope'),
        "fadMicrowave": () => import('@fortawesome/pro-duotone-svg-icons/faMicrowave'),
        "fadMindShare": () => import('@fortawesome/pro-duotone-svg-icons/faMindShare'),
        "fadMinus": () => import('@fortawesome/pro-duotone-svg-icons/faMinus'),
        "fadMinusCircle": () => import('@fortawesome/pro-duotone-svg-icons/faMinusCircle'),
        "fadMinusHexagon": () => import('@fortawesome/pro-duotone-svg-icons/faMinusHexagon'),
        "fadMinusOctagon": () => import('@fortawesome/pro-duotone-svg-icons/faMinusOctagon'),
        "fadMinusSquare": () => import('@fortawesome/pro-duotone-svg-icons/faMinusSquare'),
        "fadMistletoe": () => import('@fortawesome/pro-duotone-svg-icons/faMistletoe'),
        "fadMitten": () => import('@fortawesome/pro-duotone-svg-icons/faMitten'),
        "fadMobile": () => import('@fortawesome/pro-duotone-svg-icons/faMobile'),
        "fadMobileAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMobileAlt'),
        "fadMobileAndroid": () => import('@fortawesome/pro-duotone-svg-icons/faMobileAndroid'),
        "fadMobileAndroidAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMobileAndroidAlt'),
        "fadMoneyBill": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyBill'),
        "fadMoneyBillAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyBillAlt'),
        "fadMoneyBillWave": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyBillWave'),
        "fadMoneyBillWaveAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyBillWaveAlt'),
        "fadMoneyCheck": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyCheck'),
        "fadMoneyCheckAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt'),
        "fadMoneyCheckEdit": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyCheckEdit'),
        "fadMoneyCheckEditAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMoneyCheckEditAlt'),
        "fadMonitorHeartRate": () => import('@fortawesome/pro-duotone-svg-icons/faMonitorHeartRate'),
        "fadMonkey": () => import('@fortawesome/pro-duotone-svg-icons/faMonkey'),
        "fadMonument": () => import('@fortawesome/pro-duotone-svg-icons/faMonument'),
        "fadMoon": () => import('@fortawesome/pro-duotone-svg-icons/faMoon'),
        "fadMoonCloud": () => import('@fortawesome/pro-duotone-svg-icons/faMoonCloud'),
        "fadMoonStars": () => import('@fortawesome/pro-duotone-svg-icons/faMoonStars'),
        "fadMortarPestle": () => import('@fortawesome/pro-duotone-svg-icons/faMortarPestle'),
        "fadMosque": () => import('@fortawesome/pro-duotone-svg-icons/faMosque'),
        "fadMotorcycle": () => import('@fortawesome/pro-duotone-svg-icons/faMotorcycle'),
        "fadMountain": () => import('@fortawesome/pro-duotone-svg-icons/faMountain'),
        "fadMountains": () => import('@fortawesome/pro-duotone-svg-icons/faMountains'),
        "fadMouse": () => import('@fortawesome/pro-duotone-svg-icons/faMouse'),
        "fadMouseAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMouseAlt'),
        "fadMousePointer": () => import('@fortawesome/pro-duotone-svg-icons/faMousePointer'),
        "fadMp3Player": () => import('@fortawesome/pro-duotone-svg-icons/faMp3Player'),
        "fadMug": () => import('@fortawesome/pro-duotone-svg-icons/faMug'),
        "fadMugHot": () => import('@fortawesome/pro-duotone-svg-icons/faMugHot'),
        "fadMugMarshmallows": () => import('@fortawesome/pro-duotone-svg-icons/faMugMarshmallows'),
        "fadMugTea": () => import('@fortawesome/pro-duotone-svg-icons/faMugTea'),
        "fadMusic": () => import('@fortawesome/pro-duotone-svg-icons/faMusic'),
        "fadMusicAlt": () => import('@fortawesome/pro-duotone-svg-icons/faMusicAlt'),
        "fadMusicAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMusicAltSlash'),
        "fadMusicSlash": () => import('@fortawesome/pro-duotone-svg-icons/faMusicSlash'),
        "fadNarwhal": () => import('@fortawesome/pro-duotone-svg-icons/faNarwhal'),
        "fadNetworkWired": () => import('@fortawesome/pro-duotone-svg-icons/faNetworkWired'),
        "fadNeuter": () => import('@fortawesome/pro-duotone-svg-icons/faNeuter'),
        "fadNewspaper": () => import('@fortawesome/pro-duotone-svg-icons/faNewspaper'),
        "fadNotEqual": () => import('@fortawesome/pro-duotone-svg-icons/faNotEqual'),
        "fadNotesMedical": () => import('@fortawesome/pro-duotone-svg-icons/faNotesMedical'),
        "fadObjectGroup": () => import('@fortawesome/pro-duotone-svg-icons/faObjectGroup'),
        "fadObjectUngroup": () => import('@fortawesome/pro-duotone-svg-icons/faObjectUngroup'),
        "fadOctagon": () => import('@fortawesome/pro-duotone-svg-icons/faOctagon'),
        "fadOilCan": () => import('@fortawesome/pro-duotone-svg-icons/faOilCan'),
        "fadOilTemp": () => import('@fortawesome/pro-duotone-svg-icons/faOilTemp'),
        "fadOm": () => import('@fortawesome/pro-duotone-svg-icons/faOm'),
        "fadOmega": () => import('@fortawesome/pro-duotone-svg-icons/faOmega'),
        "fadOrnament": () => import('@fortawesome/pro-duotone-svg-icons/faOrnament'),
        "fadOtter": () => import('@fortawesome/pro-duotone-svg-icons/faOtter'),
        "fadOutdent": () => import('@fortawesome/pro-duotone-svg-icons/faOutdent'),
        "fadOutlet": () => import('@fortawesome/pro-duotone-svg-icons/faOutlet'),
        "fadOven": () => import('@fortawesome/pro-duotone-svg-icons/faOven'),
        "fadOverline": () => import('@fortawesome/pro-duotone-svg-icons/faOverline'),
        "fadPageBreak": () => import('@fortawesome/pro-duotone-svg-icons/faPageBreak'),
        "fadPager": () => import('@fortawesome/pro-duotone-svg-icons/faPager'),
        "fadPaintBrush": () => import('@fortawesome/pro-duotone-svg-icons/faPaintBrush'),
        "fadPaintBrushAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPaintBrushAlt'),
        "fadPaintRoller": () => import('@fortawesome/pro-duotone-svg-icons/faPaintRoller'),
        "fadPalette": () => import('@fortawesome/pro-duotone-svg-icons/faPalette'),
        "fadPallet": () => import('@fortawesome/pro-duotone-svg-icons/faPallet'),
        "fadPalletAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPalletAlt'),
        "fadPaperPlane": () => import('@fortawesome/pro-duotone-svg-icons/faPaperPlane'),
        "fadPaperclip": () => import('@fortawesome/pro-duotone-svg-icons/faPaperclip'),
        "fadParachuteBox": () => import('@fortawesome/pro-duotone-svg-icons/faParachuteBox'),
        "fadParagraph": () => import('@fortawesome/pro-duotone-svg-icons/faParagraph'),
        "fadParagraphRtl": () => import('@fortawesome/pro-duotone-svg-icons/faParagraphRtl'),
        "fadParking": () => import('@fortawesome/pro-duotone-svg-icons/faParking'),
        "fadParkingCircle": () => import('@fortawesome/pro-duotone-svg-icons/faParkingCircle'),
        "fadParkingCircleSlash": () => import('@fortawesome/pro-duotone-svg-icons/faParkingCircleSlash'),
        "fadParkingSlash": () => import('@fortawesome/pro-duotone-svg-icons/faParkingSlash'),
        "fadPassport": () => import('@fortawesome/pro-duotone-svg-icons/faPassport'),
        "fadPastafarianism": () => import('@fortawesome/pro-duotone-svg-icons/faPastafarianism'),
        "fadPaste": () => import('@fortawesome/pro-duotone-svg-icons/faPaste'),
        "fadPause": () => import('@fortawesome/pro-duotone-svg-icons/faPause'),
        "fadPauseCircle": () => import('@fortawesome/pro-duotone-svg-icons/faPauseCircle'),
        "fadPaw": () => import('@fortawesome/pro-duotone-svg-icons/faPaw'),
        "fadPawAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPawAlt'),
        "fadPawClaws": () => import('@fortawesome/pro-duotone-svg-icons/faPawClaws'),
        "fadPeace": () => import('@fortawesome/pro-duotone-svg-icons/faPeace'),
        "fadPegasus": () => import('@fortawesome/pro-duotone-svg-icons/faPegasus'),
        "fadPen": () => import('@fortawesome/pro-duotone-svg-icons/faPen'),
        "fadPenAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPenAlt'),
        "fadPenFancy": () => import('@fortawesome/pro-duotone-svg-icons/faPenFancy'),
        "fadPenNib": () => import('@fortawesome/pro-duotone-svg-icons/faPenNib'),
        "fadPenSquare": () => import('@fortawesome/pro-duotone-svg-icons/faPenSquare'),
        "fadPencil": () => import('@fortawesome/pro-duotone-svg-icons/faPencil'),
        "fadPencilAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPencilAlt'),
        "fadPencilPaintbrush": () => import('@fortawesome/pro-duotone-svg-icons/faPencilPaintbrush'),
        "fadPencilRuler": () => import('@fortawesome/pro-duotone-svg-icons/faPencilRuler'),
        "fadPennant": () => import('@fortawesome/pro-duotone-svg-icons/faPennant'),
        "fadPeopleArrows": () => import('@fortawesome/pro-duotone-svg-icons/faPeopleArrows'),
        "fadPeopleCarry": () => import('@fortawesome/pro-duotone-svg-icons/faPeopleCarry'),
        "fadPepperHot": () => import('@fortawesome/pro-duotone-svg-icons/faPepperHot'),
        "fadPercent": () => import('@fortawesome/pro-duotone-svg-icons/faPercent'),
        "fadPercentage": () => import('@fortawesome/pro-duotone-svg-icons/faPercentage'),
        "fadPersonBooth": () => import('@fortawesome/pro-duotone-svg-icons/faPersonBooth'),
        "fadPersonCarry": () => import('@fortawesome/pro-duotone-svg-icons/faPersonCarry'),
        "fadPersonDolly": () => import('@fortawesome/pro-duotone-svg-icons/faPersonDolly'),
        "fadPersonDollyEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faPersonDollyEmpty'),
        "fadPersonSign": () => import('@fortawesome/pro-duotone-svg-icons/faPersonSign'),
        "fadPhone": () => import('@fortawesome/pro-duotone-svg-icons/faPhone'),
        "fadPhoneAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneAlt'),
        "fadPhoneLaptop": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneLaptop'),
        "fadPhoneOffice": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneOffice'),
        "fadPhonePlus": () => import('@fortawesome/pro-duotone-svg-icons/faPhonePlus'),
        "fadPhoneRotary": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneRotary'),
        "fadPhoneSlash": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneSlash'),
        "fadPhoneSquare": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneSquare'),
        "fadPhoneSquareAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneSquareAlt'),
        "fadPhoneVolume": () => import('@fortawesome/pro-duotone-svg-icons/faPhoneVolume'),
        "fadPhotoVideo": () => import('@fortawesome/pro-duotone-svg-icons/faPhotoVideo'),
        "fadPi": () => import('@fortawesome/pro-duotone-svg-icons/faPi'),
        "fadPiano": () => import('@fortawesome/pro-duotone-svg-icons/faPiano'),
        "fadPianoKeyboard": () => import('@fortawesome/pro-duotone-svg-icons/faPianoKeyboard'),
        "fadPie": () => import('@fortawesome/pro-duotone-svg-icons/faPie'),
        "fadPig": () => import('@fortawesome/pro-duotone-svg-icons/faPig'),
        "fadPiggyBank": () => import('@fortawesome/pro-duotone-svg-icons/faPiggyBank'),
        "fadPills": () => import('@fortawesome/pro-duotone-svg-icons/faPills'),
        "fadPizza": () => import('@fortawesome/pro-duotone-svg-icons/faPizza'),
        "fadPizzaSlice": () => import('@fortawesome/pro-duotone-svg-icons/faPizzaSlice'),
        "fadPlaceOfWorship": () => import('@fortawesome/pro-duotone-svg-icons/faPlaceOfWorship'),
        "fadPlane": () => import('@fortawesome/pro-duotone-svg-icons/faPlane'),
        "fadPlaneAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPlaneAlt'),
        "fadPlaneArrival": () => import('@fortawesome/pro-duotone-svg-icons/faPlaneArrival'),
        "fadPlaneDeparture": () => import('@fortawesome/pro-duotone-svg-icons/faPlaneDeparture'),
        "fadPlaneSlash": () => import('@fortawesome/pro-duotone-svg-icons/faPlaneSlash'),
        "fadPlanetMoon": () => import('@fortawesome/pro-duotone-svg-icons/faPlanetMoon'),
        "fadPlanetRinged": () => import('@fortawesome/pro-duotone-svg-icons/faPlanetRinged'),
        "fadPlay": () => import('@fortawesome/pro-duotone-svg-icons/faPlay'),
        "fadPlayCircle": () => import('@fortawesome/pro-duotone-svg-icons/faPlayCircle'),
        "fadPlug": () => import('@fortawesome/pro-duotone-svg-icons/faPlug'),
        "fadPlus": () => import('@fortawesome/pro-duotone-svg-icons/faPlus'),
        "fadPlusCircle": () => import('@fortawesome/pro-duotone-svg-icons/faPlusCircle'),
        "fadPlusHexagon": () => import('@fortawesome/pro-duotone-svg-icons/faPlusHexagon'),
        "fadPlusOctagon": () => import('@fortawesome/pro-duotone-svg-icons/faPlusOctagon'),
        "fadPlusSquare": () => import('@fortawesome/pro-duotone-svg-icons/faPlusSquare'),
        "fadPodcast": () => import('@fortawesome/pro-duotone-svg-icons/faPodcast'),
        "fadPodium": () => import('@fortawesome/pro-duotone-svg-icons/faPodium'),
        "fadPodiumStar": () => import('@fortawesome/pro-duotone-svg-icons/faPodiumStar'),
        "fadPoliceBox": () => import('@fortawesome/pro-duotone-svg-icons/faPoliceBox'),
        "fadPoll": () => import('@fortawesome/pro-duotone-svg-icons/faPoll'),
        "fadPollH": () => import('@fortawesome/pro-duotone-svg-icons/faPollH'),
        "fadPollPeople": () => import('@fortawesome/pro-duotone-svg-icons/faPollPeople'),
        "fadPoo": () => import('@fortawesome/pro-duotone-svg-icons/faPoo'),
        "fadPooStorm": () => import('@fortawesome/pro-duotone-svg-icons/faPooStorm'),
        "fadPoop": () => import('@fortawesome/pro-duotone-svg-icons/faPoop'),
        "fadPopcorn": () => import('@fortawesome/pro-duotone-svg-icons/faPopcorn'),
        "fadPortalEnter": () => import('@fortawesome/pro-duotone-svg-icons/faPortalEnter'),
        "fadPortalExit": () => import('@fortawesome/pro-duotone-svg-icons/faPortalExit'),
        "fadPortrait": () => import('@fortawesome/pro-duotone-svg-icons/faPortrait'),
        "fadPoundSign": () => import('@fortawesome/pro-duotone-svg-icons/faPoundSign'),
        "fadPowerOff": () => import('@fortawesome/pro-duotone-svg-icons/faPowerOff'),
        "fadPray": () => import('@fortawesome/pro-duotone-svg-icons/faPray'),
        "fadPrayingHands": () => import('@fortawesome/pro-duotone-svg-icons/faPrayingHands'),
        "fadPrescription": () => import('@fortawesome/pro-duotone-svg-icons/faPrescription'),
        "fadPrescriptionBottle": () => import('@fortawesome/pro-duotone-svg-icons/faPrescriptionBottle'),
        "fadPrescriptionBottleAlt": () => import('@fortawesome/pro-duotone-svg-icons/faPrescriptionBottleAlt'),
        "fadPresentation": () => import('@fortawesome/pro-duotone-svg-icons/faPresentation'),
        "fadPrint": () => import('@fortawesome/pro-duotone-svg-icons/faPrint'),
        "fadPrintSearch": () => import('@fortawesome/pro-duotone-svg-icons/faPrintSearch'),
        "fadPrintSlash": () => import('@fortawesome/pro-duotone-svg-icons/faPrintSlash'),
        "fadProcedures": () => import('@fortawesome/pro-duotone-svg-icons/faProcedures'),
        "fadProjectDiagram": () => import('@fortawesome/pro-duotone-svg-icons/faProjectDiagram'),
        "fadProjector": () => import('@fortawesome/pro-duotone-svg-icons/faProjector'),
        "fadPumpMedical": () => import('@fortawesome/pro-duotone-svg-icons/faPumpMedical'),
        "fadPumpSoap": () => import('@fortawesome/pro-duotone-svg-icons/faPumpSoap'),
        "fadPumpkin": () => import('@fortawesome/pro-duotone-svg-icons/faPumpkin'),
        "fadPuzzlePiece": () => import('@fortawesome/pro-duotone-svg-icons/faPuzzlePiece'),
        "fadQrcode": () => import('@fortawesome/pro-duotone-svg-icons/faQrcode'),
        "fadQuestion": () => import('@fortawesome/pro-duotone-svg-icons/faQuestion'),
        "fadQuestionCircle": () => import('@fortawesome/pro-duotone-svg-icons/faQuestionCircle'),
        "fadQuestionSquare": () => import('@fortawesome/pro-duotone-svg-icons/faQuestionSquare'),
        "fadQuidditch": () => import('@fortawesome/pro-duotone-svg-icons/faQuidditch'),
        "fadQuoteLeft": () => import('@fortawesome/pro-duotone-svg-icons/faQuoteLeft'),
        "fadQuoteRight": () => import('@fortawesome/pro-duotone-svg-icons/faQuoteRight'),
        "fadQuran": () => import('@fortawesome/pro-duotone-svg-icons/faQuran'),
        "fadRabbit": () => import('@fortawesome/pro-duotone-svg-icons/faRabbit'),
        "fadRabbitFast": () => import('@fortawesome/pro-duotone-svg-icons/faRabbitFast'),
        "fadRacquet": () => import('@fortawesome/pro-duotone-svg-icons/faRacquet'),
        "fadRadar": () => import('@fortawesome/pro-duotone-svg-icons/faRadar'),
        "fadRadiation": () => import('@fortawesome/pro-duotone-svg-icons/faRadiation'),
        "fadRadiationAlt": () => import('@fortawesome/pro-duotone-svg-icons/faRadiationAlt'),
        "fadRadio": () => import('@fortawesome/pro-duotone-svg-icons/faRadio'),
        "fadRadioAlt": () => import('@fortawesome/pro-duotone-svg-icons/faRadioAlt'),
        "fadRainbow": () => import('@fortawesome/pro-duotone-svg-icons/faRainbow'),
        "fadRaindrops": () => import('@fortawesome/pro-duotone-svg-icons/faRaindrops'),
        "fadRam": () => import('@fortawesome/pro-duotone-svg-icons/faRam'),
        "fadRampLoading": () => import('@fortawesome/pro-duotone-svg-icons/faRampLoading'),
        "fadRandom": () => import('@fortawesome/pro-duotone-svg-icons/faRandom'),
        "fadRaygun": () => import('@fortawesome/pro-duotone-svg-icons/faRaygun'),
        "fadReceipt": () => import('@fortawesome/pro-duotone-svg-icons/faReceipt'),
        "fadRecordVinyl": () => import('@fortawesome/pro-duotone-svg-icons/faRecordVinyl'),
        "fadRectangleLandscape": () => import('@fortawesome/pro-duotone-svg-icons/faRectangleLandscape'),
        "fadRectanglePortrait": () => import('@fortawesome/pro-duotone-svg-icons/faRectanglePortrait'),
        "fadRectangleWide": () => import('@fortawesome/pro-duotone-svg-icons/faRectangleWide'),
        "fadRecycle": () => import('@fortawesome/pro-duotone-svg-icons/faRecycle'),
        "fadRedo": () => import('@fortawesome/pro-duotone-svg-icons/faRedo'),
        "fadRedoAlt": () => import('@fortawesome/pro-duotone-svg-icons/faRedoAlt'),
        "fadRefrigerator": () => import('@fortawesome/pro-duotone-svg-icons/faRefrigerator'),
        "fadRegistered": () => import('@fortawesome/pro-duotone-svg-icons/faRegistered'),
        "fadRemoveFormat": () => import('@fortawesome/pro-duotone-svg-icons/faRemoveFormat'),
        "fadRepeat": () => import('@fortawesome/pro-duotone-svg-icons/faRepeat'),
        "fadRepeat1": () => import('@fortawesome/pro-duotone-svg-icons/faRepeat1'),
        "fadRepeat1Alt": () => import('@fortawesome/pro-duotone-svg-icons/faRepeat1Alt'),
        "fadRepeatAlt": () => import('@fortawesome/pro-duotone-svg-icons/faRepeatAlt'),
        "fadReply": () => import('@fortawesome/pro-duotone-svg-icons/faReply'),
        "fadReplyAll": () => import('@fortawesome/pro-duotone-svg-icons/faReplyAll'),
        "fadRepublican": () => import('@fortawesome/pro-duotone-svg-icons/faRepublican'),
        "fadRestroom": () => import('@fortawesome/pro-duotone-svg-icons/faRestroom'),
        "fadRetweet": () => import('@fortawesome/pro-duotone-svg-icons/faRetweet'),
        "fadRetweetAlt": () => import('@fortawesome/pro-duotone-svg-icons/faRetweetAlt'),
        "fadRibbon": () => import('@fortawesome/pro-duotone-svg-icons/faRibbon'),
        "fadRing": () => import('@fortawesome/pro-duotone-svg-icons/faRing'),
        "fadRingsWedding": () => import('@fortawesome/pro-duotone-svg-icons/faRingsWedding'),
        "fadRoad": () => import('@fortawesome/pro-duotone-svg-icons/faRoad'),
        "fadRobot": () => import('@fortawesome/pro-duotone-svg-icons/faRobot'),
        "fadRocket": () => import('@fortawesome/pro-duotone-svg-icons/faRocket'),
        "fadRocketLaunch": () => import('@fortawesome/pro-duotone-svg-icons/faRocketLaunch'),
        "fadRoute": () => import('@fortawesome/pro-duotone-svg-icons/faRoute'),
        "fadRouteHighway": () => import('@fortawesome/pro-duotone-svg-icons/faRouteHighway'),
        "fadRouteInterstate": () => import('@fortawesome/pro-duotone-svg-icons/faRouteInterstate'),
        "fadRouter": () => import('@fortawesome/pro-duotone-svg-icons/faRouter'),
        "fadRss": () => import('@fortawesome/pro-duotone-svg-icons/faRss'),
        "fadRssSquare": () => import('@fortawesome/pro-duotone-svg-icons/faRssSquare'),
        "fadRubleSign": () => import('@fortawesome/pro-duotone-svg-icons/faRubleSign'),
        "fadRuler": () => import('@fortawesome/pro-duotone-svg-icons/faRuler'),
        "fadRulerCombined": () => import('@fortawesome/pro-duotone-svg-icons/faRulerCombined'),
        "fadRulerHorizontal": () => import('@fortawesome/pro-duotone-svg-icons/faRulerHorizontal'),
        "fadRulerTriangle": () => import('@fortawesome/pro-duotone-svg-icons/faRulerTriangle'),
        "fadRulerVertical": () => import('@fortawesome/pro-duotone-svg-icons/faRulerVertical'),
        "fadRunning": () => import('@fortawesome/pro-duotone-svg-icons/faRunning'),
        "fadRupeeSign": () => import('@fortawesome/pro-duotone-svg-icons/faRupeeSign'),
        "fadRv": () => import('@fortawesome/pro-duotone-svg-icons/faRv'),
        "fadSack": () => import('@fortawesome/pro-duotone-svg-icons/faSack'),
        "fadSackDollar": () => import('@fortawesome/pro-duotone-svg-icons/faSackDollar'),
        "fadSadCry": () => import('@fortawesome/pro-duotone-svg-icons/faSadCry'),
        "fadSadTear": () => import('@fortawesome/pro-duotone-svg-icons/faSadTear'),
        "fadSalad": () => import('@fortawesome/pro-duotone-svg-icons/faSalad'),
        "fadSandwich": () => import('@fortawesome/pro-duotone-svg-icons/faSandwich'),
        "fadSatellite": () => import('@fortawesome/pro-duotone-svg-icons/faSatellite'),
        "fadSatelliteDish": () => import('@fortawesome/pro-duotone-svg-icons/faSatelliteDish'),
        "fadSausage": () => import('@fortawesome/pro-duotone-svg-icons/faSausage'),
        "fadSave": () => import('@fortawesome/pro-duotone-svg-icons/faSave'),
        "fadSaxHot": () => import('@fortawesome/pro-duotone-svg-icons/faSaxHot'),
        "fadSaxophone": () => import('@fortawesome/pro-duotone-svg-icons/faSaxophone'),
        "fadScalpel": () => import('@fortawesome/pro-duotone-svg-icons/faScalpel'),
        "fadScalpelPath": () => import('@fortawesome/pro-duotone-svg-icons/faScalpelPath'),
        "fadScanner": () => import('@fortawesome/pro-duotone-svg-icons/faScanner'),
        "fadScannerImage": () => import('@fortawesome/pro-duotone-svg-icons/faScannerImage'),
        "fadScannerKeyboard": () => import('@fortawesome/pro-duotone-svg-icons/faScannerKeyboard'),
        "fadScannerTouchscreen": () => import('@fortawesome/pro-duotone-svg-icons/faScannerTouchscreen'),
        "fadScarecrow": () => import('@fortawesome/pro-duotone-svg-icons/faScarecrow'),
        "fadScarf": () => import('@fortawesome/pro-duotone-svg-icons/faScarf'),
        "fadSchool": () => import('@fortawesome/pro-duotone-svg-icons/faSchool'),
        "fadScrewdriver": () => import('@fortawesome/pro-duotone-svg-icons/faScrewdriver'),
        "fadScroll": () => import('@fortawesome/pro-duotone-svg-icons/faScroll'),
        "fadScrollOld": () => import('@fortawesome/pro-duotone-svg-icons/faScrollOld'),
        "fadScrubber": () => import('@fortawesome/pro-duotone-svg-icons/faScrubber'),
        "fadScythe": () => import('@fortawesome/pro-duotone-svg-icons/faScythe'),
        "fadSdCard": () => import('@fortawesome/pro-duotone-svg-icons/faSdCard'),
        "fadSearch": () => import('@fortawesome/pro-duotone-svg-icons/faSearch'),
        "fadSearchDollar": () => import('@fortawesome/pro-duotone-svg-icons/faSearchDollar'),
        "fadSearchLocation": () => import('@fortawesome/pro-duotone-svg-icons/faSearchLocation'),
        "fadSearchMinus": () => import('@fortawesome/pro-duotone-svg-icons/faSearchMinus'),
        "fadSearchPlus": () => import('@fortawesome/pro-duotone-svg-icons/faSearchPlus'),
        "fadSeedling": () => import('@fortawesome/pro-duotone-svg-icons/faSeedling'),
        "fadSendBack": () => import('@fortawesome/pro-duotone-svg-icons/faSendBack'),
        "fadSendBackward": () => import('@fortawesome/pro-duotone-svg-icons/faSendBackward'),
        "fadSensor": () => import('@fortawesome/pro-duotone-svg-icons/faSensor'),
        "fadSensorAlert": () => import('@fortawesome/pro-duotone-svg-icons/faSensorAlert'),
        "fadSensorFire": () => import('@fortawesome/pro-duotone-svg-icons/faSensorFire'),
        "fadSensorOn": () => import('@fortawesome/pro-duotone-svg-icons/faSensorOn'),
        "fadSensorSmoke": () => import('@fortawesome/pro-duotone-svg-icons/faSensorSmoke'),
        "fadServer": () => import('@fortawesome/pro-duotone-svg-icons/faServer'),
        "fadShapes": () => import('@fortawesome/pro-duotone-svg-icons/faShapes'),
        "fadShare": () => import('@fortawesome/pro-duotone-svg-icons/faShare'),
        "fadShareAll": () => import('@fortawesome/pro-duotone-svg-icons/faShareAll'),
        "fadShareAlt": () => import('@fortawesome/pro-duotone-svg-icons/faShareAlt'),
        "fadShareAltSquare": () => import('@fortawesome/pro-duotone-svg-icons/faShareAltSquare'),
        "fadShareSquare": () => import('@fortawesome/pro-duotone-svg-icons/faShareSquare'),
        "fadSheep": () => import('@fortawesome/pro-duotone-svg-icons/faSheep'),
        "fadShekelSign": () => import('@fortawesome/pro-duotone-svg-icons/faShekelSign'),
        "fadShield": () => import('@fortawesome/pro-duotone-svg-icons/faShield'),
        "fadShieldAlt": () => import('@fortawesome/pro-duotone-svg-icons/faShieldAlt'),
        "fadShieldCheck": () => import('@fortawesome/pro-duotone-svg-icons/faShieldCheck'),
        "fadShieldCross": () => import('@fortawesome/pro-duotone-svg-icons/faShieldCross'),
        "fadShieldVirus": () => import('@fortawesome/pro-duotone-svg-icons/faShieldVirus'),
        "fadShip": () => import('@fortawesome/pro-duotone-svg-icons/faShip'),
        "fadShippingFast": () => import('@fortawesome/pro-duotone-svg-icons/faShippingFast'),
        "fadShippingTimed": () => import('@fortawesome/pro-duotone-svg-icons/faShippingTimed'),
        "fadShishKebab": () => import('@fortawesome/pro-duotone-svg-icons/faShishKebab'),
        "fadShoePrints": () => import('@fortawesome/pro-duotone-svg-icons/faShoePrints'),
        "fadShoppingBag": () => import('@fortawesome/pro-duotone-svg-icons/faShoppingBag'),
        "fadShoppingBasket": () => import('@fortawesome/pro-duotone-svg-icons/faShoppingBasket'),
        "fadShoppingCart": () => import('@fortawesome/pro-duotone-svg-icons/faShoppingCart'),
        "fadShovel": () => import('@fortawesome/pro-duotone-svg-icons/faShovel'),
        "fadShovelSnow": () => import('@fortawesome/pro-duotone-svg-icons/faShovelSnow'),
        "fadShower": () => import('@fortawesome/pro-duotone-svg-icons/faShower'),
        "fadShredder": () => import('@fortawesome/pro-duotone-svg-icons/faShredder'),
        "fadShuttleVan": () => import('@fortawesome/pro-duotone-svg-icons/faShuttleVan'),
        "fadShuttlecock": () => import('@fortawesome/pro-duotone-svg-icons/faShuttlecock'),
        "fadSickle": () => import('@fortawesome/pro-duotone-svg-icons/faSickle'),
        "fadSigma": () => import('@fortawesome/pro-duotone-svg-icons/faSigma'),
        "fadSign": () => import('@fortawesome/pro-duotone-svg-icons/faSign'),
        "fadSignIn": () => import('@fortawesome/pro-duotone-svg-icons/faSignIn'),
        "fadSignInAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSignInAlt'),
        "fadSignLanguage": () => import('@fortawesome/pro-duotone-svg-icons/faSignLanguage'),
        "fadSignOut": () => import('@fortawesome/pro-duotone-svg-icons/faSignOut'),
        "fadSignOutAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSignOutAlt'),
        "fadSignal": () => import('@fortawesome/pro-duotone-svg-icons/faSignal'),
        "fadSignal1": () => import('@fortawesome/pro-duotone-svg-icons/faSignal1'),
        "fadSignal2": () => import('@fortawesome/pro-duotone-svg-icons/faSignal2'),
        "fadSignal3": () => import('@fortawesome/pro-duotone-svg-icons/faSignal3'),
        "fadSignal4": () => import('@fortawesome/pro-duotone-svg-icons/faSignal4'),
        "fadSignalAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSignalAlt'),
        "fadSignalAlt1": () => import('@fortawesome/pro-duotone-svg-icons/faSignalAlt1'),
        "fadSignalAlt2": () => import('@fortawesome/pro-duotone-svg-icons/faSignalAlt2'),
        "fadSignalAlt3": () => import('@fortawesome/pro-duotone-svg-icons/faSignalAlt3'),
        "fadSignalAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faSignalAltSlash'),
        "fadSignalSlash": () => import('@fortawesome/pro-duotone-svg-icons/faSignalSlash'),
        "fadSignalStream": () => import('@fortawesome/pro-duotone-svg-icons/faSignalStream'),
        "fadSignature": () => import('@fortawesome/pro-duotone-svg-icons/faSignature'),
        "fadSimCard": () => import('@fortawesome/pro-duotone-svg-icons/faSimCard'),
        "fadSink": () => import('@fortawesome/pro-duotone-svg-icons/faSink'),
        "fadSiren": () => import('@fortawesome/pro-duotone-svg-icons/faSiren'),
        "fadSirenOn": () => import('@fortawesome/pro-duotone-svg-icons/faSirenOn'),
        "fadSitemap": () => import('@fortawesome/pro-duotone-svg-icons/faSitemap'),
        "fadSkating": () => import('@fortawesome/pro-duotone-svg-icons/faSkating'),
        "fadSkeleton": () => import('@fortawesome/pro-duotone-svg-icons/faSkeleton'),
        "fadSkiJump": () => import('@fortawesome/pro-duotone-svg-icons/faSkiJump'),
        "fadSkiLift": () => import('@fortawesome/pro-duotone-svg-icons/faSkiLift'),
        "fadSkiing": () => import('@fortawesome/pro-duotone-svg-icons/faSkiing'),
        "fadSkiingNordic": () => import('@fortawesome/pro-duotone-svg-icons/faSkiingNordic'),
        "fadSkull": () => import('@fortawesome/pro-duotone-svg-icons/faSkull'),
        "fadSkullCow": () => import('@fortawesome/pro-duotone-svg-icons/faSkullCow'),
        "fadSkullCrossbones": () => import('@fortawesome/pro-duotone-svg-icons/faSkullCrossbones'),
        "fadSlash": () => import('@fortawesome/pro-duotone-svg-icons/faSlash'),
        "fadSledding": () => import('@fortawesome/pro-duotone-svg-icons/faSledding'),
        "fadSleigh": () => import('@fortawesome/pro-duotone-svg-icons/faSleigh'),
        "fadSlidersH": () => import('@fortawesome/pro-duotone-svg-icons/faSlidersH'),
        "fadSlidersHSquare": () => import('@fortawesome/pro-duotone-svg-icons/faSlidersHSquare'),
        "fadSlidersV": () => import('@fortawesome/pro-duotone-svg-icons/faSlidersV'),
        "fadSlidersVSquare": () => import('@fortawesome/pro-duotone-svg-icons/faSlidersVSquare'),
        "fadSmile": () => import('@fortawesome/pro-duotone-svg-icons/faSmile'),
        "fadSmileBeam": () => import('@fortawesome/pro-duotone-svg-icons/faSmileBeam'),
        "fadSmilePlus": () => import('@fortawesome/pro-duotone-svg-icons/faSmilePlus'),
        "fadSmileWink": () => import('@fortawesome/pro-duotone-svg-icons/faSmileWink'),
        "fadSmog": () => import('@fortawesome/pro-duotone-svg-icons/faSmog'),
        "fadSmoke": () => import('@fortawesome/pro-duotone-svg-icons/faSmoke'),
        "fadSmoking": () => import('@fortawesome/pro-duotone-svg-icons/faSmoking'),
        "fadSmokingBan": () => import('@fortawesome/pro-duotone-svg-icons/faSmokingBan'),
        "fadSms": () => import('@fortawesome/pro-duotone-svg-icons/faSms'),
        "fadSnake": () => import('@fortawesome/pro-duotone-svg-icons/faSnake'),
        "fadSnooze": () => import('@fortawesome/pro-duotone-svg-icons/faSnooze'),
        "fadSnowBlowing": () => import('@fortawesome/pro-duotone-svg-icons/faSnowBlowing'),
        "fadSnowboarding": () => import('@fortawesome/pro-duotone-svg-icons/faSnowboarding'),
        "fadSnowflake": () => import('@fortawesome/pro-duotone-svg-icons/faSnowflake'),
        "fadSnowflakes": () => import('@fortawesome/pro-duotone-svg-icons/faSnowflakes'),
        "fadSnowman": () => import('@fortawesome/pro-duotone-svg-icons/faSnowman'),
        "fadSnowmobile": () => import('@fortawesome/pro-duotone-svg-icons/faSnowmobile'),
        "fadSnowplow": () => import('@fortawesome/pro-duotone-svg-icons/faSnowplow'),
        "fadSoap": () => import('@fortawesome/pro-duotone-svg-icons/faSoap'),
        "fadSocks": () => import('@fortawesome/pro-duotone-svg-icons/faSocks'),
        "fadSolarPanel": () => import('@fortawesome/pro-duotone-svg-icons/faSolarPanel'),
        "fadSolarSystem": () => import('@fortawesome/pro-duotone-svg-icons/faSolarSystem'),
        "fadSort": () => import('@fortawesome/pro-duotone-svg-icons/faSort'),
        "fadSortAlphaDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortAlphaDown'),
        "fadSortAlphaDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortAlphaDownAlt'),
        "fadSortAlphaUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortAlphaUp'),
        "fadSortAlphaUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortAlphaUpAlt'),
        "fadSortAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortAlt'),
        "fadSortAmountDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortAmountDown'),
        "fadSortAmountDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortAmountDownAlt'),
        "fadSortAmountUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortAmountUp'),
        "fadSortAmountUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortAmountUpAlt'),
        "fadSortCircle": () => import('@fortawesome/pro-duotone-svg-icons/faSortCircle'),
        "fadSortCircleDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortCircleDown'),
        "fadSortCircleUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortCircleUp'),
        "fadSortDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortDown'),
        "fadSortNumericDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortNumericDown'),
        "fadSortNumericDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortNumericDownAlt'),
        "fadSortNumericUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortNumericUp'),
        "fadSortNumericUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortNumericUpAlt'),
        "fadSortShapesDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortShapesDown'),
        "fadSortShapesDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortShapesDownAlt'),
        "fadSortShapesUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortShapesUp'),
        "fadSortShapesUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortShapesUpAlt'),
        "fadSortSizeDown": () => import('@fortawesome/pro-duotone-svg-icons/faSortSizeDown'),
        "fadSortSizeDownAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortSizeDownAlt'),
        "fadSortSizeUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortSizeUp'),
        "fadSortSizeUpAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSortSizeUpAlt'),
        "fadSortUp": () => import('@fortawesome/pro-duotone-svg-icons/faSortUp'),
        "fadSoup": () => import('@fortawesome/pro-duotone-svg-icons/faSoup'),
        "fadSpa": () => import('@fortawesome/pro-duotone-svg-icons/faSpa'),
        "fadSpaceShuttle": () => import('@fortawesome/pro-duotone-svg-icons/faSpaceShuttle'),
        "fadSpaceStationMoon": () => import('@fortawesome/pro-duotone-svg-icons/faSpaceStationMoon'),
        "fadSpaceStationMoonAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSpaceStationMoonAlt'),
        "fadSpade": () => import('@fortawesome/pro-duotone-svg-icons/faSpade'),
        "fadSparkles": () => import('@fortawesome/pro-duotone-svg-icons/faSparkles'),
        "fadSpeaker": () => import('@fortawesome/pro-duotone-svg-icons/faSpeaker'),
        "fadSpeakers": () => import('@fortawesome/pro-duotone-svg-icons/faSpeakers'),
        "fadSpellCheck": () => import('@fortawesome/pro-duotone-svg-icons/faSpellCheck'),
        "fadSpider": () => import('@fortawesome/pro-duotone-svg-icons/faSpider'),
        "fadSpiderBlackWidow": () => import('@fortawesome/pro-duotone-svg-icons/faSpiderBlackWidow'),
        "fadSpiderWeb": () => import('@fortawesome/pro-duotone-svg-icons/faSpiderWeb'),
        "fadSpinner": () => import('@fortawesome/pro-duotone-svg-icons/faSpinner'),
        "fadSpinnerThird": () => import('@fortawesome/pro-duotone-svg-icons/faSpinnerThird'),
        "fadSplotch": () => import('@fortawesome/pro-duotone-svg-icons/faSplotch'),
        "fadSprayCan": () => import('@fortawesome/pro-duotone-svg-icons/faSprayCan'),
        "fadSprinkler": () => import('@fortawesome/pro-duotone-svg-icons/faSprinkler'),
        "fadSquare": () => import('@fortawesome/pro-duotone-svg-icons/faSquare'),
        "fadSquareFull": () => import('@fortawesome/pro-duotone-svg-icons/faSquareFull'),
        "fadSquareRoot": () => import('@fortawesome/pro-duotone-svg-icons/faSquareRoot'),
        "fadSquareRootAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSquareRootAlt'),
        "fadSquirrel": () => import('@fortawesome/pro-duotone-svg-icons/faSquirrel'),
        "fadStaff": () => import('@fortawesome/pro-duotone-svg-icons/faStaff'),
        "fadStamp": () => import('@fortawesome/pro-duotone-svg-icons/faStamp'),
        "fadStar": () => import('@fortawesome/pro-duotone-svg-icons/faStar'),
        "fadStarAndCrescent": () => import('@fortawesome/pro-duotone-svg-icons/faStarAndCrescent'),
        "fadStarChristmas": () => import('@fortawesome/pro-duotone-svg-icons/faStarChristmas'),
        "fadStarExclamation": () => import('@fortawesome/pro-duotone-svg-icons/faStarExclamation'),
        "fadStarHalf": () => import('@fortawesome/pro-duotone-svg-icons/faStarHalf'),
        "fadStarHalfAlt": () => import('@fortawesome/pro-duotone-svg-icons/faStarHalfAlt'),
        "fadStarOfDavid": () => import('@fortawesome/pro-duotone-svg-icons/faStarOfDavid'),
        "fadStarOfLife": () => import('@fortawesome/pro-duotone-svg-icons/faStarOfLife'),
        "fadStarShooting": () => import('@fortawesome/pro-duotone-svg-icons/faStarShooting'),
        "fadStarfighter": () => import('@fortawesome/pro-duotone-svg-icons/faStarfighter'),
        "fadStarfighterAlt": () => import('@fortawesome/pro-duotone-svg-icons/faStarfighterAlt'),
        "fadStars": () => import('@fortawesome/pro-duotone-svg-icons/faStars'),
        "fadStarship": () => import('@fortawesome/pro-duotone-svg-icons/faStarship'),
        "fadStarshipFreighter": () => import('@fortawesome/pro-duotone-svg-icons/faStarshipFreighter'),
        "fadSteak": () => import('@fortawesome/pro-duotone-svg-icons/faSteak'),
        "fadSteeringWheel": () => import('@fortawesome/pro-duotone-svg-icons/faSteeringWheel'),
        "fadStepBackward": () => import('@fortawesome/pro-duotone-svg-icons/faStepBackward'),
        "fadStepForward": () => import('@fortawesome/pro-duotone-svg-icons/faStepForward'),
        "fadStethoscope": () => import('@fortawesome/pro-duotone-svg-icons/faStethoscope'),
        "fadStickyNote": () => import('@fortawesome/pro-duotone-svg-icons/faStickyNote'),
        "fadStocking": () => import('@fortawesome/pro-duotone-svg-icons/faStocking'),
        "fadStomach": () => import('@fortawesome/pro-duotone-svg-icons/faStomach'),
        "fadStop": () => import('@fortawesome/pro-duotone-svg-icons/faStop'),
        "fadStopCircle": () => import('@fortawesome/pro-duotone-svg-icons/faStopCircle'),
        "fadStopwatch": () => import('@fortawesome/pro-duotone-svg-icons/faStopwatch'),
        "fadStopwatch20": () => import('@fortawesome/pro-duotone-svg-icons/faStopwatch20'),
        "fadStore": () => import('@fortawesome/pro-duotone-svg-icons/faStore'),
        "fadStoreAlt": () => import('@fortawesome/pro-duotone-svg-icons/faStoreAlt'),
        "fadStoreAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faStoreAltSlash'),
        "fadStoreSlash": () => import('@fortawesome/pro-duotone-svg-icons/faStoreSlash'),
        "fadStream": () => import('@fortawesome/pro-duotone-svg-icons/faStream'),
        "fadStreetView": () => import('@fortawesome/pro-duotone-svg-icons/faStreetView'),
        "fadStretcher": () => import('@fortawesome/pro-duotone-svg-icons/faStretcher'),
        "fadStrikethrough": () => import('@fortawesome/pro-duotone-svg-icons/faStrikethrough'),
        "fadStroopwafel": () => import('@fortawesome/pro-duotone-svg-icons/faStroopwafel'),
        "fadSubscript": () => import('@fortawesome/pro-duotone-svg-icons/faSubscript'),
        "fadSubway": () => import('@fortawesome/pro-duotone-svg-icons/faSubway'),
        "fadSuitcase": () => import('@fortawesome/pro-duotone-svg-icons/faSuitcase'),
        "fadSuitcaseRolling": () => import('@fortawesome/pro-duotone-svg-icons/faSuitcaseRolling'),
        "fadSun": () => import('@fortawesome/pro-duotone-svg-icons/faSun'),
        "fadSunCloud": () => import('@fortawesome/pro-duotone-svg-icons/faSunCloud'),
        "fadSunDust": () => import('@fortawesome/pro-duotone-svg-icons/faSunDust'),
        "fadSunHaze": () => import('@fortawesome/pro-duotone-svg-icons/faSunHaze'),
        "fadSunglasses": () => import('@fortawesome/pro-duotone-svg-icons/faSunglasses'),
        "fadSunrise": () => import('@fortawesome/pro-duotone-svg-icons/faSunrise'),
        "fadSunset": () => import('@fortawesome/pro-duotone-svg-icons/faSunset'),
        "fadSuperscript": () => import('@fortawesome/pro-duotone-svg-icons/faSuperscript'),
        "fadSurprise": () => import('@fortawesome/pro-duotone-svg-icons/faSurprise'),
        "fadSwatchbook": () => import('@fortawesome/pro-duotone-svg-icons/faSwatchbook'),
        "fadSwimmer": () => import('@fortawesome/pro-duotone-svg-icons/faSwimmer'),
        "fadSwimmingPool": () => import('@fortawesome/pro-duotone-svg-icons/faSwimmingPool'),
        "fadSword": () => import('@fortawesome/pro-duotone-svg-icons/faSword'),
        "fadSwordLaser": () => import('@fortawesome/pro-duotone-svg-icons/faSwordLaser'),
        "fadSwordLaserAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSwordLaserAlt'),
        "fadSwords": () => import('@fortawesome/pro-duotone-svg-icons/faSwords'),
        "fadSwordsLaser": () => import('@fortawesome/pro-duotone-svg-icons/faSwordsLaser'),
        "fadSynagogue": () => import('@fortawesome/pro-duotone-svg-icons/faSynagogue'),
        "fadSync": () => import('@fortawesome/pro-duotone-svg-icons/faSync'),
        "fadSyncAlt": () => import('@fortawesome/pro-duotone-svg-icons/faSyncAlt'),
        "fadSyringe": () => import('@fortawesome/pro-duotone-svg-icons/faSyringe'),
        "fadTable": () => import('@fortawesome/pro-duotone-svg-icons/faTable'),
        "fadTableTennis": () => import('@fortawesome/pro-duotone-svg-icons/faTableTennis'),
        "fadTablet": () => import('@fortawesome/pro-duotone-svg-icons/faTablet'),
        "fadTabletAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTabletAlt'),
        "fadTabletAndroid": () => import('@fortawesome/pro-duotone-svg-icons/faTabletAndroid'),
        "fadTabletAndroidAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTabletAndroidAlt'),
        "fadTabletRugged": () => import('@fortawesome/pro-duotone-svg-icons/faTabletRugged'),
        "fadTablets": () => import('@fortawesome/pro-duotone-svg-icons/faTablets'),
        "fadTachometer": () => import('@fortawesome/pro-duotone-svg-icons/faTachometer'),
        "fadTachometerAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAlt'),
        "fadTachometerAltAverage": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAltAverage'),
        "fadTachometerAltFast": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAltFast'),
        "fadTachometerAltFastest": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAltFastest'),
        "fadTachometerAltSlow": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAltSlow'),
        "fadTachometerAltSlowest": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAltSlowest'),
        "fadTachometerAverage": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerAverage'),
        "fadTachometerFast": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerFast'),
        "fadTachometerFastest": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerFastest'),
        "fadTachometerSlow": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerSlow'),
        "fadTachometerSlowest": () => import('@fortawesome/pro-duotone-svg-icons/faTachometerSlowest'),
        "fadTaco": () => import('@fortawesome/pro-duotone-svg-icons/faTaco'),
        "fadTag": () => import('@fortawesome/pro-duotone-svg-icons/faTag'),
        "fadTags": () => import('@fortawesome/pro-duotone-svg-icons/faTags'),
        "fadTally": () => import('@fortawesome/pro-duotone-svg-icons/faTally'),
        "fadTanakh": () => import('@fortawesome/pro-duotone-svg-icons/faTanakh'),
        "fadTape": () => import('@fortawesome/pro-duotone-svg-icons/faTape'),
        "fadTasks": () => import('@fortawesome/pro-duotone-svg-icons/faTasks'),
        "fadTasksAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTasksAlt'),
        "fadTaxi": () => import('@fortawesome/pro-duotone-svg-icons/faTaxi'),
        "fadTeeth": () => import('@fortawesome/pro-duotone-svg-icons/faTeeth'),
        "fadTeethOpen": () => import('@fortawesome/pro-duotone-svg-icons/faTeethOpen'),
        "fadTelescope": () => import('@fortawesome/pro-duotone-svg-icons/faTelescope'),
        "fadTemperatureDown": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureDown'),
        "fadTemperatureFrigid": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureFrigid'),
        "fadTemperatureHigh": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureHigh'),
        "fadTemperatureHot": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureHot'),
        "fadTemperatureLow": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureLow'),
        "fadTemperatureUp": () => import('@fortawesome/pro-duotone-svg-icons/faTemperatureUp'),
        "fadTenge": () => import('@fortawesome/pro-duotone-svg-icons/faTenge'),
        "fadTennisBall": () => import('@fortawesome/pro-duotone-svg-icons/faTennisBall'),
        "fadTerminal": () => import('@fortawesome/pro-duotone-svg-icons/faTerminal'),
        "fadText": () => import('@fortawesome/pro-duotone-svg-icons/faText'),
        "fadTextHeight": () => import('@fortawesome/pro-duotone-svg-icons/faTextHeight'),
        "fadTextSize": () => import('@fortawesome/pro-duotone-svg-icons/faTextSize'),
        "fadTextWidth": () => import('@fortawesome/pro-duotone-svg-icons/faTextWidth'),
        "fadTh": () => import('@fortawesome/pro-duotone-svg-icons/faTh'),
        "fadThLarge": () => import('@fortawesome/pro-duotone-svg-icons/faThLarge'),
        "fadThList": () => import('@fortawesome/pro-duotone-svg-icons/faThList'),
        "fadTheaterMasks": () => import('@fortawesome/pro-duotone-svg-icons/faTheaterMasks'),
        "fadThermometer": () => import('@fortawesome/pro-duotone-svg-icons/faThermometer'),
        "fadThermometerEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faThermometerEmpty'),
        "fadThermometerFull": () => import('@fortawesome/pro-duotone-svg-icons/faThermometerFull'),
        "fadThermometerHalf": () => import('@fortawesome/pro-duotone-svg-icons/faThermometerHalf'),
        "fadThermometerQuarter": () => import('@fortawesome/pro-duotone-svg-icons/faThermometerQuarter'),
        "fadThermometerThreeQuarters": () => import('@fortawesome/pro-duotone-svg-icons/faThermometerThreeQuarters'),
        "fadTheta": () => import('@fortawesome/pro-duotone-svg-icons/faTheta'),
        "fadThumbsDown": () => import('@fortawesome/pro-duotone-svg-icons/faThumbsDown'),
        "fadThumbsUp": () => import('@fortawesome/pro-duotone-svg-icons/faThumbsUp'),
        "fadThumbtack": () => import('@fortawesome/pro-duotone-svg-icons/faThumbtack'),
        "fadThunderstorm": () => import('@fortawesome/pro-duotone-svg-icons/faThunderstorm'),
        "fadThunderstormMoon": () => import('@fortawesome/pro-duotone-svg-icons/faThunderstormMoon'),
        "fadThunderstormSun": () => import('@fortawesome/pro-duotone-svg-icons/faThunderstormSun'),
        "fadTicket": () => import('@fortawesome/pro-duotone-svg-icons/faTicket'),
        "fadTicketAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTicketAlt'),
        "fadTilde": () => import('@fortawesome/pro-duotone-svg-icons/faTilde'),
        "fadTimes": () => import('@fortawesome/pro-duotone-svg-icons/faTimes'),
        "fadTimesCircle": () => import('@fortawesome/pro-duotone-svg-icons/faTimesCircle'),
        "fadTimesHexagon": () => import('@fortawesome/pro-duotone-svg-icons/faTimesHexagon'),
        "fadTimesOctagon": () => import('@fortawesome/pro-duotone-svg-icons/faTimesOctagon'),
        "fadTimesSquare": () => import('@fortawesome/pro-duotone-svg-icons/faTimesSquare'),
        "fadTint": () => import('@fortawesome/pro-duotone-svg-icons/faTint'),
        "fadTintSlash": () => import('@fortawesome/pro-duotone-svg-icons/faTintSlash'),
        "fadTire": () => import('@fortawesome/pro-duotone-svg-icons/faTire'),
        "fadTireFlat": () => import('@fortawesome/pro-duotone-svg-icons/faTireFlat'),
        "fadTirePressureWarning": () => import('@fortawesome/pro-duotone-svg-icons/faTirePressureWarning'),
        "fadTireRugged": () => import('@fortawesome/pro-duotone-svg-icons/faTireRugged'),
        "fadTired": () => import('@fortawesome/pro-duotone-svg-icons/faTired'),
        "fadToggleOff": () => import('@fortawesome/pro-duotone-svg-icons/faToggleOff'),
        "fadToggleOn": () => import('@fortawesome/pro-duotone-svg-icons/faToggleOn'),
        "fadToilet": () => import('@fortawesome/pro-duotone-svg-icons/faToilet'),
        "fadToiletPaper": () => import('@fortawesome/pro-duotone-svg-icons/faToiletPaper'),
        "fadToiletPaperAlt": () => import('@fortawesome/pro-duotone-svg-icons/faToiletPaperAlt'),
        "fadToiletPaperSlash": () => import('@fortawesome/pro-duotone-svg-icons/faToiletPaperSlash'),
        "fadTombstone": () => import('@fortawesome/pro-duotone-svg-icons/faTombstone'),
        "fadTombstoneAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTombstoneAlt'),
        "fadToolbox": () => import('@fortawesome/pro-duotone-svg-icons/faToolbox'),
        "fadTools": () => import('@fortawesome/pro-duotone-svg-icons/faTools'),
        "fadTooth": () => import('@fortawesome/pro-duotone-svg-icons/faTooth'),
        "fadToothbrush": () => import('@fortawesome/pro-duotone-svg-icons/faToothbrush'),
        "fadTorah": () => import('@fortawesome/pro-duotone-svg-icons/faTorah'),
        "fadToriiGate": () => import('@fortawesome/pro-duotone-svg-icons/faToriiGate'),
        "fadTornado": () => import('@fortawesome/pro-duotone-svg-icons/faTornado'),
        "fadTractor": () => import('@fortawesome/pro-duotone-svg-icons/faTractor'),
        "fadTrademark": () => import('@fortawesome/pro-duotone-svg-icons/faTrademark'),
        "fadTrafficCone": () => import('@fortawesome/pro-duotone-svg-icons/faTrafficCone'),
        "fadTrafficLight": () => import('@fortawesome/pro-duotone-svg-icons/faTrafficLight'),
        "fadTrafficLightGo": () => import('@fortawesome/pro-duotone-svg-icons/faTrafficLightGo'),
        "fadTrafficLightSlow": () => import('@fortawesome/pro-duotone-svg-icons/faTrafficLightSlow'),
        "fadTrafficLightStop": () => import('@fortawesome/pro-duotone-svg-icons/faTrafficLightStop'),
        "fadTrailer": () => import('@fortawesome/pro-duotone-svg-icons/faTrailer'),
        "fadTrain": () => import('@fortawesome/pro-duotone-svg-icons/faTrain'),
        "fadTram": () => import('@fortawesome/pro-duotone-svg-icons/faTram'),
        "fadTransgender": () => import('@fortawesome/pro-duotone-svg-icons/faTransgender'),
        "fadTransgenderAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTransgenderAlt'),
        "fadTransporter": () => import('@fortawesome/pro-duotone-svg-icons/faTransporter'),
        "fadTransporter1": () => import('@fortawesome/pro-duotone-svg-icons/faTransporter1'),
        "fadTransporter2": () => import('@fortawesome/pro-duotone-svg-icons/faTransporter2'),
        "fadTransporter3": () => import('@fortawesome/pro-duotone-svg-icons/faTransporter3'),
        "fadTransporterEmpty": () => import('@fortawesome/pro-duotone-svg-icons/faTransporterEmpty'),
        "fadTrash": () => import('@fortawesome/pro-duotone-svg-icons/faTrash'),
        "fadTrashAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTrashAlt'),
        "fadTrashRestore": () => import('@fortawesome/pro-duotone-svg-icons/faTrashRestore'),
        "fadTrashRestoreAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTrashRestoreAlt'),
        "fadTrashUndo": () => import('@fortawesome/pro-duotone-svg-icons/faTrashUndo'),
        "fadTrashUndoAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTrashUndoAlt'),
        "fadTreasureChest": () => import('@fortawesome/pro-duotone-svg-icons/faTreasureChest'),
        "fadTree": () => import('@fortawesome/pro-duotone-svg-icons/faTree'),
        "fadTreeAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTreeAlt'),
        "fadTreeChristmas": () => import('@fortawesome/pro-duotone-svg-icons/faTreeChristmas'),
        "fadTreeDecorated": () => import('@fortawesome/pro-duotone-svg-icons/faTreeDecorated'),
        "fadTreeLarge": () => import('@fortawesome/pro-duotone-svg-icons/faTreeLarge'),
        "fadTreePalm": () => import('@fortawesome/pro-duotone-svg-icons/faTreePalm'),
        "fadTrees": () => import('@fortawesome/pro-duotone-svg-icons/faTrees'),
        "fadTriangle": () => import('@fortawesome/pro-duotone-svg-icons/faTriangle'),
        "fadTriangleMusic": () => import('@fortawesome/pro-duotone-svg-icons/faTriangleMusic'),
        "fadTrophy": () => import('@fortawesome/pro-duotone-svg-icons/faTrophy'),
        "fadTrophyAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTrophyAlt'),
        "fadTruck": () => import('@fortawesome/pro-duotone-svg-icons/faTruck'),
        "fadTruckContainer": () => import('@fortawesome/pro-duotone-svg-icons/faTruckContainer'),
        "fadTruckCouch": () => import('@fortawesome/pro-duotone-svg-icons/faTruckCouch'),
        "fadTruckLoading": () => import('@fortawesome/pro-duotone-svg-icons/faTruckLoading'),
        "fadTruckMonster": () => import('@fortawesome/pro-duotone-svg-icons/faTruckMonster'),
        "fadTruckMoving": () => import('@fortawesome/pro-duotone-svg-icons/faTruckMoving'),
        "fadTruckPickup": () => import('@fortawesome/pro-duotone-svg-icons/faTruckPickup'),
        "fadTruckPlow": () => import('@fortawesome/pro-duotone-svg-icons/faTruckPlow'),
        "fadTruckRamp": () => import('@fortawesome/pro-duotone-svg-icons/faTruckRamp'),
        "fadTrumpet": () => import('@fortawesome/pro-duotone-svg-icons/faTrumpet'),
        "fadTshirt": () => import('@fortawesome/pro-duotone-svg-icons/faTshirt'),
        "fadTty": () => import('@fortawesome/pro-duotone-svg-icons/faTty'),
        "fadTurkey": () => import('@fortawesome/pro-duotone-svg-icons/faTurkey'),
        "fadTurntable": () => import('@fortawesome/pro-duotone-svg-icons/faTurntable'),
        "fadTurtle": () => import('@fortawesome/pro-duotone-svg-icons/faTurtle'),
        "fadTv": () => import('@fortawesome/pro-duotone-svg-icons/faTv'),
        "fadTvAlt": () => import('@fortawesome/pro-duotone-svg-icons/faTvAlt'),
        "fadTvMusic": () => import('@fortawesome/pro-duotone-svg-icons/faTvMusic'),
        "fadTvRetro": () => import('@fortawesome/pro-duotone-svg-icons/faTvRetro'),
        "fadTypewriter": () => import('@fortawesome/pro-duotone-svg-icons/faTypewriter'),
        "fadUfo": () => import('@fortawesome/pro-duotone-svg-icons/faUfo'),
        "fadUfoBeam": () => import('@fortawesome/pro-duotone-svg-icons/faUfoBeam'),
        "fadUmbrella": () => import('@fortawesome/pro-duotone-svg-icons/faUmbrella'),
        "fadUmbrellaBeach": () => import('@fortawesome/pro-duotone-svg-icons/faUmbrellaBeach'),
        "fadUnderline": () => import('@fortawesome/pro-duotone-svg-icons/faUnderline'),
        "fadUndo": () => import('@fortawesome/pro-duotone-svg-icons/faUndo'),
        "fadUndoAlt": () => import('@fortawesome/pro-duotone-svg-icons/faUndoAlt'),
        "fadUnicorn": () => import('@fortawesome/pro-duotone-svg-icons/faUnicorn'),
        "fadUnion": () => import('@fortawesome/pro-duotone-svg-icons/faUnion'),
        "fadUniversalAccess": () => import('@fortawesome/pro-duotone-svg-icons/faUniversalAccess'),
        "fadUniversity": () => import('@fortawesome/pro-duotone-svg-icons/faUniversity'),
        "fadUnlink": () => import('@fortawesome/pro-duotone-svg-icons/faUnlink'),
        "fadUnlock": () => import('@fortawesome/pro-duotone-svg-icons/faUnlock'),
        "fadUnlockAlt": () => import('@fortawesome/pro-duotone-svg-icons/faUnlockAlt'),
        "fadUpload": () => import('@fortawesome/pro-duotone-svg-icons/faUpload'),
        "fadUsbDrive": () => import('@fortawesome/pro-duotone-svg-icons/faUsbDrive'),
        "fadUsdCircle": () => import('@fortawesome/pro-duotone-svg-icons/faUsdCircle'),
        "fadUsdSquare": () => import('@fortawesome/pro-duotone-svg-icons/faUsdSquare'),
        "fadUser": () => import('@fortawesome/pro-duotone-svg-icons/faUser'),
        "fadUserAlien": () => import('@fortawesome/pro-duotone-svg-icons/faUserAlien'),
        "fadUserAlt": () => import('@fortawesome/pro-duotone-svg-icons/faUserAlt'),
        "fadUserAltSlash": () => import('@fortawesome/pro-duotone-svg-icons/faUserAltSlash'),
        "fadUserAstronaut": () => import('@fortawesome/pro-duotone-svg-icons/faUserAstronaut'),
        "fadUserChart": () => import('@fortawesome/pro-duotone-svg-icons/faUserChart'),
        "fadUserCheck": () => import('@fortawesome/pro-duotone-svg-icons/faUserCheck'),
        "fadUserCircle": () => import('@fortawesome/pro-duotone-svg-icons/faUserCircle'),
        "fadUserClock": () => import('@fortawesome/pro-duotone-svg-icons/faUserClock'),
        "fadUserCog": () => import('@fortawesome/pro-duotone-svg-icons/faUserCog'),
        "fadUserCowboy": () => import('@fortawesome/pro-duotone-svg-icons/faUserCowboy'),
        "fadUserCrown": () => import('@fortawesome/pro-duotone-svg-icons/faUserCrown'),
        "fadUserEdit": () => import('@fortawesome/pro-duotone-svg-icons/faUserEdit'),
        "fadUserFriends": () => import('@fortawesome/pro-duotone-svg-icons/faUserFriends'),
        "fadUserGraduate": () => import('@fortawesome/pro-duotone-svg-icons/faUserGraduate'),
        "fadUserHardHat": () => import('@fortawesome/pro-duotone-svg-icons/faUserHardHat'),
        "fadUserHeadset": () => import('@fortawesome/pro-duotone-svg-icons/faUserHeadset'),
        "fadUserInjured": () => import('@fortawesome/pro-duotone-svg-icons/faUserInjured'),
        "fadUserLock": () => import('@fortawesome/pro-duotone-svg-icons/faUserLock'),
        "fadUserMd": () => import('@fortawesome/pro-duotone-svg-icons/faUserMd'),
        "fadUserMdChat": () => import('@fortawesome/pro-duotone-svg-icons/faUserMdChat'),
        "fadUserMinus": () => import('@fortawesome/pro-duotone-svg-icons/faUserMinus'),
        "fadUserMusic": () => import('@fortawesome/pro-duotone-svg-icons/faUserMusic'),
        "fadUserNinja": () => import('@fortawesome/pro-duotone-svg-icons/faUserNinja'),
        "fadUserNurse": () => import('@fortawesome/pro-duotone-svg-icons/faUserNurse'),
        "fadUserPlus": () => import('@fortawesome/pro-duotone-svg-icons/faUserPlus'),
        "fadUserRobot": () => import('@fortawesome/pro-duotone-svg-icons/faUserRobot'),
        "fadUserSecret": () => import('@fortawesome/pro-duotone-svg-icons/faUserSecret'),
        "fadUserShield": () => import('@fortawesome/pro-duotone-svg-icons/faUserShield'),
        "fadUserSlash": () => import('@fortawesome/pro-duotone-svg-icons/faUserSlash'),
        "fadUserTag": () => import('@fortawesome/pro-duotone-svg-icons/faUserTag'),
        "fadUserTie": () => import('@fortawesome/pro-duotone-svg-icons/faUserTie'),
        "fadUserTimes": () => import('@fortawesome/pro-duotone-svg-icons/faUserTimes'),
        "fadUserUnlock": () => import('@fortawesome/pro-duotone-svg-icons/faUserUnlock'),
        "fadUserVisor": () => import('@fortawesome/pro-duotone-svg-icons/faUserVisor'),
        "fadUsers": () => import('@fortawesome/pro-duotone-svg-icons/faUsers'),
        "fadUsersClass": () => import('@fortawesome/pro-duotone-svg-icons/faUsersClass'),
        "fadUsersCog": () => import('@fortawesome/pro-duotone-svg-icons/faUsersCog'),
        "fadUsersCrown": () => import('@fortawesome/pro-duotone-svg-icons/faUsersCrown'),
        "fadUsersMedical": () => import('@fortawesome/pro-duotone-svg-icons/faUsersMedical'),
        "fadUsersSlash": () => import('@fortawesome/pro-duotone-svg-icons/faUsersSlash'),
        "fadUtensilFork": () => import('@fortawesome/pro-duotone-svg-icons/faUtensilFork'),
        "fadUtensilKnife": () => import('@fortawesome/pro-duotone-svg-icons/faUtensilKnife'),
        "fadUtensilSpoon": () => import('@fortawesome/pro-duotone-svg-icons/faUtensilSpoon'),
        "fadUtensils": () => import('@fortawesome/pro-duotone-svg-icons/faUtensils'),
        "fadUtensilsAlt": () => import('@fortawesome/pro-duotone-svg-icons/faUtensilsAlt'),
        "fadVacuum": () => import('@fortawesome/pro-duotone-svg-icons/faVacuum'),
        "fadVacuumRobot": () => import('@fortawesome/pro-duotone-svg-icons/faVacuumRobot'),
        "fadValueAbsolute": () => import('@fortawesome/pro-duotone-svg-icons/faValueAbsolute'),
        "fadVectorSquare": () => import('@fortawesome/pro-duotone-svg-icons/faVectorSquare'),
        "fadVenus": () => import('@fortawesome/pro-duotone-svg-icons/faVenus'),
        "fadVenusDouble": () => import('@fortawesome/pro-duotone-svg-icons/faVenusDouble'),
        "fadVenusMars": () => import('@fortawesome/pro-duotone-svg-icons/faVenusMars'),
        "fadVest": () => import('@fortawesome/pro-duotone-svg-icons/faVest'),
        "fadVestPatches": () => import('@fortawesome/pro-duotone-svg-icons/faVestPatches'),
        "fadVhs": () => import('@fortawesome/pro-duotone-svg-icons/faVhs'),
        "fadVial": () => import('@fortawesome/pro-duotone-svg-icons/faVial'),
        "fadVials": () => import('@fortawesome/pro-duotone-svg-icons/faVials'),
        "fadVideo": () => import('@fortawesome/pro-duotone-svg-icons/faVideo'),
        "fadVideoPlus": () => import('@fortawesome/pro-duotone-svg-icons/faVideoPlus'),
        "fadVideoSlash": () => import('@fortawesome/pro-duotone-svg-icons/faVideoSlash'),
        "fadVihara": () => import('@fortawesome/pro-duotone-svg-icons/faVihara'),
        "fadViolin": () => import('@fortawesome/pro-duotone-svg-icons/faViolin'),
        "fadVirus": () => import('@fortawesome/pro-duotone-svg-icons/faVirus'),
        "fadVirusSlash": () => import('@fortawesome/pro-duotone-svg-icons/faVirusSlash'),
        "fadViruses": () => import('@fortawesome/pro-duotone-svg-icons/faViruses'),
        "fadVoicemail": () => import('@fortawesome/pro-duotone-svg-icons/faVoicemail'),
        "fadVolcano": () => import('@fortawesome/pro-duotone-svg-icons/faVolcano'),
        "fadVolleyballBall": () => import('@fortawesome/pro-duotone-svg-icons/faVolleyballBall'),
        "fadVolume": () => import('@fortawesome/pro-duotone-svg-icons/faVolume'),
        "fadVolumeDown": () => import('@fortawesome/pro-duotone-svg-icons/faVolumeDown'),
        "fadVolumeMute": () => import('@fortawesome/pro-duotone-svg-icons/faVolumeMute'),
        "fadVolumeOff": () => import('@fortawesome/pro-duotone-svg-icons/faVolumeOff'),
        "fadVolumeSlash": () => import('@fortawesome/pro-duotone-svg-icons/faVolumeSlash'),
        "fadVolumeUp": () => import('@fortawesome/pro-duotone-svg-icons/faVolumeUp'),
        "fadVoteNay": () => import('@fortawesome/pro-duotone-svg-icons/faVoteNay'),
        "fadVoteYea": () => import('@fortawesome/pro-duotone-svg-icons/faVoteYea'),
        "fadVrCardboard": () => import('@fortawesome/pro-duotone-svg-icons/faVrCardboard'),
        "fadWagonCovered": () => import('@fortawesome/pro-duotone-svg-icons/faWagonCovered'),
        "fadWalker": () => import('@fortawesome/pro-duotone-svg-icons/faWalker'),
        "fadWalkieTalkie": () => import('@fortawesome/pro-duotone-svg-icons/faWalkieTalkie'),
        "fadWalking": () => import('@fortawesome/pro-duotone-svg-icons/faWalking'),
        "fadWallet": () => import('@fortawesome/pro-duotone-svg-icons/faWallet'),
        "fadWand": () => import('@fortawesome/pro-duotone-svg-icons/faWand'),
        "fadWandMagic": () => import('@fortawesome/pro-duotone-svg-icons/faWandMagic'),
        "fadWarehouse": () => import('@fortawesome/pro-duotone-svg-icons/faWarehouse'),
        "fadWarehouseAlt": () => import('@fortawesome/pro-duotone-svg-icons/faWarehouseAlt'),
        "fadWasher": () => import('@fortawesome/pro-duotone-svg-icons/faWasher'),
        "fadWatch": () => import('@fortawesome/pro-duotone-svg-icons/faWatch'),
        "fadWatchCalculator": () => import('@fortawesome/pro-duotone-svg-icons/faWatchCalculator'),
        "fadWatchFitness": () => import('@fortawesome/pro-duotone-svg-icons/faWatchFitness'),
        "fadWater": () => import('@fortawesome/pro-duotone-svg-icons/faWater'),
        "fadWaterLower": () => import('@fortawesome/pro-duotone-svg-icons/faWaterLower'),
        "fadWaterRise": () => import('@fortawesome/pro-duotone-svg-icons/faWaterRise'),
        "fadWaveSine": () => import('@fortawesome/pro-duotone-svg-icons/faWaveSine'),
        "fadWaveSquare": () => import('@fortawesome/pro-duotone-svg-icons/faWaveSquare'),
        "fadWaveTriangle": () => import('@fortawesome/pro-duotone-svg-icons/faWaveTriangle'),
        "fadWaveform": () => import('@fortawesome/pro-duotone-svg-icons/faWaveform'),
        "fadWaveformPath": () => import('@fortawesome/pro-duotone-svg-icons/faWaveformPath'),
        "fadWebcam": () => import('@fortawesome/pro-duotone-svg-icons/faWebcam'),
        "fadWebcamSlash": () => import('@fortawesome/pro-duotone-svg-icons/faWebcamSlash'),
        "fadWeight": () => import('@fortawesome/pro-duotone-svg-icons/faWeight'),
        "fadWeightHanging": () => import('@fortawesome/pro-duotone-svg-icons/faWeightHanging'),
        "fadWhale": () => import('@fortawesome/pro-duotone-svg-icons/faWhale'),
        "fadWheat": () => import('@fortawesome/pro-duotone-svg-icons/faWheat'),
        "fadWheelchair": () => import('@fortawesome/pro-duotone-svg-icons/faWheelchair'),
        "fadWhistle": () => import('@fortawesome/pro-duotone-svg-icons/faWhistle'),
        "fadWifi": () => import('@fortawesome/pro-duotone-svg-icons/faWifi'),
        "fadWifi1": () => import('@fortawesome/pro-duotone-svg-icons/faWifi1'),
        "fadWifi2": () => import('@fortawesome/pro-duotone-svg-icons/faWifi2'),
        "fadWifiSlash": () => import('@fortawesome/pro-duotone-svg-icons/faWifiSlash'),
        "fadWind": () => import('@fortawesome/pro-duotone-svg-icons/faWind'),
        "fadWindTurbine": () => import('@fortawesome/pro-duotone-svg-icons/faWindTurbine'),
        "fadWindWarning": () => import('@fortawesome/pro-duotone-svg-icons/faWindWarning'),
        "fadWindow": () => import('@fortawesome/pro-duotone-svg-icons/faWindow'),
        "fadWindowAlt": () => import('@fortawesome/pro-duotone-svg-icons/faWindowAlt'),
        "fadWindowClose": () => import('@fortawesome/pro-duotone-svg-icons/faWindowClose'),
        "fadWindowFrame": () => import('@fortawesome/pro-duotone-svg-icons/faWindowFrame'),
        "fadWindowFrameOpen": () => import('@fortawesome/pro-duotone-svg-icons/faWindowFrameOpen'),
        "fadWindowMaximize": () => import('@fortawesome/pro-duotone-svg-icons/faWindowMaximize'),
        "fadWindowMinimize": () => import('@fortawesome/pro-duotone-svg-icons/faWindowMinimize'),
        "fadWindowRestore": () => import('@fortawesome/pro-duotone-svg-icons/faWindowRestore'),
        "fadWindsock": () => import('@fortawesome/pro-duotone-svg-icons/faWindsock'),
        "fadWineBottle": () => import('@fortawesome/pro-duotone-svg-icons/faWineBottle'),
        "fadWineGlass": () => import('@fortawesome/pro-duotone-svg-icons/faWineGlass'),
        "fadWineGlassAlt": () => import('@fortawesome/pro-duotone-svg-icons/faWineGlassAlt'),
        "fadWonSign": () => import('@fortawesome/pro-duotone-svg-icons/faWonSign'),
        "fadWreath": () => import('@fortawesome/pro-duotone-svg-icons/faWreath'),
        "fadWrench": () => import('@fortawesome/pro-duotone-svg-icons/faWrench'),
        "fadXRay": () => import('@fortawesome/pro-duotone-svg-icons/faXRay'),
        "fadYenSign": () => import('@fortawesome/pro-duotone-svg-icons/faYenSign'),
        "fadYinYang": () => import('@fortawesome/pro-duotone-svg-icons/faYinYang')
    }
};
export default packInfo;
