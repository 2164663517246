import { useState, useEffect, useCallback, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { useAppContext } from 'modules/Caila/components/AppContext';
import { BotProjectReadWithConfigDto } from 'modules/Botadmin/api/client';

function getBotTestIdFromProject(project: BotProjectReadWithConfigDto): string | undefined {
  // @ts-ignore
  return project.botConfigs?.find(conf => conf.channelType === 'TEST_WIDGET')?.botserverBotId;
}

export default function useTestWidgetBotId(): string | undefined {
  const { currentProject, originalProjects, BotProjectsService, setOriginalProjects } = useAppContext();
  const [botId, setBotId] = useState<string>();
  const isLoading = useRef(false);

  const tryToSyncOriginalProjectsForCurrentProject = useCallback(
    (currentProjectId?: number) => {
      isLoading.current = true;
      BotProjectsService.getProjects()
        .then(data => {
          const currentProjects = data.data;
          if (!currentProjects) return;
          const localCurrentProject = currentProjects.find(project => project.id === currentProjectId);
          if (!localCurrentProject) return;
          setOriginalProjects(cloneDeep(currentProjects));
        })
        .finally(() => {
          isLoading.current = false;
        });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (!currentProject) return;
    const originalProject = originalProjects.find(project => project.id === currentProject.id);
    if (!originalProject) {
      !isLoading.current && tryToSyncOriginalProjectsForCurrentProject(currentProject.id);
      return;
    }
    const botId = getBotTestIdFromProject(originalProject);
    if (!botId) {
      console.error(`BotId not found for project with id = ${currentProject.id}`);
      return;
    }
    setBotId(botId);
  }, [
    originalProjects,
    currentProject,
    BotProjectsService,
    setOriginalProjects,
    tryToSyncOriginalProjectsForCurrentProject,
  ]);

  return botId;
}
