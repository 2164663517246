export const MoveStateInGroupLocalization = {
  'MoveStateInGroup:title': {
    eng: 'Change state nesting level',
    ru: 'Изменение вложенности шага',
  },
  'MoveStateInGroup:body': {
    eng: 'This will make the state “$[1]” nested into “$[2]”. Are you sure?',
    ru: 'Вы уверены, что хотите вложить шаг $[1] в $[2]?',
  },
  'MoveStateInGroup:confirmMoveToGroup': {
    eng: 'Yes, nest this state',
    ru: 'Да, сделать вложенным',
  },
};
