import React, { FC, useEffect } from 'react';
import { Button } from '@just-ai/just-ui';
import { t } from 'localization';
import { useRightSideMenuContext } from './index';
import { validateBlockFulfillment } from './utils/common';

export const FooterSaveCustomTag: FC = () => {
  const { getEditBlockTag, customTagEdit, customTags } = useRightSideMenuContext();

  const [customTagDisabledSave, setCustomTagDisabledSave] = React.useState(true);

  useEffect(() => {
    const sub = customTagEdit.disabledSave.subscribe(value => {
      setCustomTagDisabledSave(value);
    });
    return () => sub.unsubscribe();
  }, [customTagEdit.disabledSave]);

  const { save, cancel } = customTagEdit;
  const editBlockTag = getEditBlockTag();
  if (!editBlockTag) return null;
  const simpleBlockCheckDisabled = validateBlockFulfillment(editBlockTag, customTagDisabledSave, customTags);

  return (
    <div className='JGraph-RightSideMenu-footer'>
      <Button color='primary' data-test-id='RightSideMenu:save' disabled={simpleBlockCheckDisabled} onClick={save}>
        {t('Save')}
      </Button>
      <Button flat color='secondary' data-test-id='RightSideMenu:cancel' onClick={cancel}>
        {t('Cancel')}
      </Button>
    </div>
  );
};
