import { translate } from './localization';
export function getCurrentPeriodValueLength(currentPeriodValue, period) {
    if (['month', 'day', 'minutes', 'hours', 'AM/PM'].includes(period))
        return 2;
    return 4;
}
export function buildStringValue(value, placeholder, locale, addZero, addSpacesAfter) {
    if (value === null) {
        return translate(placeholder, locale);
    }
    else if (addZero) {
        return value < 10 ? `0${value}` : `${value}`;
    }
    else if (addSpacesAfter) {
        return `${value}${' '.repeat(4 - `${value}`.length)}`;
    }
    else {
        return `${value}`;
    }
}
export function validateAndNormalizeValue(rawValue, period, currentValues, options) {
    if (rawValue === null || (['year', 'month', 'day'].includes(period) && (rawValue === '0' || rawValue === 0)))
        return null;
    let value = typeof rawValue === 'string' ? Number(rawValue.substr(0, 4)) : rawValue;
    if (period === 'month' && value > 12)
        value = 12;
    /*if (period === 'year' && options?.minValueSetted && value !== undefined && value > 0 && value < 100)
      value = value + 2000;*/
    if (period === 'year' && value > 9999)
        value = 9999;
    if (period === 'day') {
        const maxValue = getMaxValueForCurrentMonth(currentValues[1], currentValues[2]);
        value = value > maxValue ? maxValue : value;
    }
    if (period === 'hours' && value > 23)
        value = 23;
    if (period === 'hours' && (options === null || options === void 0 ? void 0 : options.amEnabled) && value > 11)
        value = 11;
    if (period === 'minutes' && value > 59)
        value = 59;
    if (['month', 'day'].includes(period) && value < 1 && (options === null || options === void 0 ? void 0 : options.checkOnZero))
        value = 1;
    if (['month', 'day', 'minutes', 'hours'].includes(period) && value < 0 && (options === null || options === void 0 ? void 0 : options.checkOnZero))
        value = 0;
    return value;
}
export function getMaxValueForCurrentMonth(month, year) {
    let maxValue = 31;
    if (month && month === 2) {
        if (year === null || year % 4 === 0) {
            maxValue = 29;
        }
        else {
            maxValue = 28;
        }
    }
    else if (month && [4, 6, 9, 11].includes(month)) {
        maxValue = 30;
    }
    return maxValue;
}
export function buildCurrentValue(order, { day, month, year, hours, minutes, ampm }, locale) {
    const splitter = locale === 'en' ? '/' : '.';
    return order.reduce((result, period) => {
        if (period === 'day') {
            result = `${result}${buildStringValue(day, 'dd', locale, true, true)}${splitter}`;
        }
        if (period === 'month') {
            result = `${result}${buildStringValue(month, 'mm', locale, true, true)}${splitter}`;
        }
        if (period === 'year') {
            result = `${result}${buildStringValue(year, 'yyyy', locale, false, true)}`;
        }
        if (period === 'hours') {
            let hoursValue = order.includes('AM/PM') && hours ? hours % 12 : hours;
            result = `${result}${order.includes('year') ? ' ' : ''}${buildStringValue(hoursValue === 0 && order.includes('AM/PM') ? 12 : hoursValue, 'HH', locale, true)}`;
        }
        if (period === 'minutes') {
            result = `${result}:${buildStringValue(minutes, 'MM', locale, true)}`;
        }
        if (period === 'AM/PM') {
            result = `${result} ${ampm === 1 ? 'PM' : 'AM'}`;
        }
        return result;
    }, '');
}
export function checkIsNeedToMovePosition(value, position, { month, year }) {
    let result = value.length >= (position === 'year' ? 4 : 2);
    if (position === 'day') {
        const maxValue = getMaxValueForCurrentMonth(month, year);
        if (Number(value + '0') >= maxValue)
            result = true;
    }
    if (position === 'month' && !['1', '0'].includes(value))
        result = true;
    return result;
}
