import { axios } from '../../../pipes/functions';

import { DeploymentApi } from '../api/client';

export default class DeploymentService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private projectShortName: string;
  private deploymentApi: DeploymentApi;

  constructor(accountId: number, projectShortName: string) {
    this.accountId = accountId;
    this.projectShortName = projectShortName;
    this.deploymentApi = new DeploymentApi({}, DeploymentService.BASE_PATH, axios);
  }

  public runTests(botId: string) {
    return this.deploymentApi.runTests(this.accountId, this.projectShortName, botId);
  }
}
