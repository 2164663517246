import React, { useCallback } from 'react';
import cn from 'classnames';
import { IconButton, Button } from '@just-ai/just-ui';

import { t } from 'localization';

import { TaskStatusData } from '../../../types';
import { TaskProps, NotificationViewInfo } from '../../index';

import styles from './styles.module.scss';

function getTaskStatus(task: TaskStatusData) {
  switch (task.message.code.code) {
    case 'reporter.reports.report_generation_task_completed':
      return 'success';
    case 'reporter.reports.report_generation_task_failed':
      return 'error';
    case 'reporter.reports.report_generation_task_created':
    case 'reporter.reports.report_generation_task_started':
    case 'reporter.reports.report_generation_task_progress':
      return 'pending';
    default:
      return 'pending';
  }
}

export function getNotificationViewInfoForBillingReportTask(
  task: TaskStatusData,
  forAccount?: boolean
): NotificationViewInfo {
  return {
    title: t(`BillingReportTask:Title:${getTaskStatus(task)}`),
    description: t(`BillingReportTask:Description:${getTaskStatus(task)}`, {
      name: t(
        forAccount
          ? `BillingReportType:ForUser:${task.message.data.reportType}`
          : `BillingReportType:ForAdmin:${task.message.data.reportType}`
      ),
      errorInfo: task.message.data.output || '""',
    }),
    component: BillingReportTask,
    event: task,
    isShowInAlert: [
      'reporter.reports.report_generation_task_completed',
      'reporter.reports.report_generation_task_failed',
    ].includes(task.message.code.code),
  };
}

interface BillingReportTaskProps extends TaskProps {}
const BillingReportTask = ({ task, notificationViewInfo, onDelete, onlyContent }: BillingReportTaskProps) => {
  const taskData = task.message.data;
  const onDeleteInner = useCallback(() => onDelete?.(task), [onDelete, task]);

  return (
    <div
      className={cn(styles.Notification, {
        [styles.withoutPadding]: onlyContent,
      })}
    >
      {!onlyContent && (
        <div className={styles.title}>
          <div>{notificationViewInfo.title}</div>
          <IconButton withoutPadding name='farTrashAlt' flat onClick={onDeleteInner} />
        </div>
      )}
      <div className={cn(styles.body)}>
        <div className={styles.description}>{notificationViewInfo.description}</div>
        {taskData.fileUrl && (
          <a href={taskData.fileUrl} download={taskData.fileUrl} rel='noreferrer'>
            <Button color='primary'>{t('BillingReportTask:DownloadReport')}</Button>
          </a>
        )}
      </div>
    </div>
  );
};

BillingReportTask.displayName = 'BillingReportTask';

export default React.memo(BillingReportTask);
