import React, { FC, useCallback, useMemo } from 'react';
import classes from './HeaderNav.module.scss';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { useRightSideMenuContext } from './index';
import classNames from 'classnames';
import { backToNewCailaIntentsCreation } from './RSAddingMenu/ActivationsAddingMenu/JGraphCailaIntents/JGraphCailaIntentChooseCreation';
import { wasFromNewIntentsMenu$ } from './hooks/EventsHook';

export const HeaderNav: FC = ({ children }) => {
  const {
    getEditBlockType,
    eventsState,
    cancel,
    cancelAddingEvents,
    setActivationsMenuType,
    selectActivationEvent,
    setTransitionMenuOpen,
  } = useRightSideMenuContext();
  const { addingMenuType, activationsMenuType } = eventsState;

  const isActive = useMemo(() => {
    if (!!getEditBlockType() && getEditBlockType() !== 'screenBlocksList') {
      return true;
    }
    return !!(addingMenuType || activationsMenuType);
  }, [activationsMenuType, addingMenuType, getEditBlockType]);

  const goBackHandler = useCallback(() => {
    if (!isActive) return;

    if (activationsMenuType) {
      setActivationsMenuType(undefined);
      selectActivationEvent(undefined);
      if (addingMenuType) cancelAddingEvents();
      return;
    }
    if (addingMenuType) {
      cancelAddingEvents();
      return;
    }
    setTransitionMenuOpen(false);
    const editBlockType = getEditBlockType();
    if (!editBlockType || ['screenBlocksList'].includes(editBlockType)) return;
    cancel();
    window.requestAnimationFrame(() => {
      if (wasFromNewIntentsMenu$.getValue()) {
        backToNewCailaIntentsCreation();
      }
    });
  }, [
    isActive,
    activationsMenuType,
    addingMenuType,
    setTransitionMenuOpen,
    getEditBlockType,
    cancel,
    setActivationsMenuType,
    selectActivationEvent,
    cancelAddingEvents,
  ]);

  return (
    <div
      data-test-id='RightSideMenu:header'
      onClick={goBackHandler}
      className={classNames(classes.HeaderNav, { [classes.active]: isActive })}
    >
      {isActive && <Icon name='farChevronLeft' />}
      {children}
    </div>
  );
};
