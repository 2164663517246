import { CustomDataDto } from 'modules/Botadmin/api/client';
import BotConfigService from 'views/Channels/services/BotConfigService';
import {
  CLEAR_EDITABLE_CHANNEL,
  CLEAR_VALIDATE_TOKEN_ERROR,
  CREATE_CHANNEL,
  FETCH_BRANCHES,
  FETCH_REFS,
  FETCH_CHANNELS,
  HIDE_CHANNEL_VALIDATION_ERRORS,
  PUBLISH_CHANNEL,
  REMOVE_EDITABLE_CHANNEL,
  SAVE_EDITABLE_CHANNEL,
  SET_EDITABLE_CHANNEL,
  SET_PUBLISH_CHANNEL_ID,
  SHOW_CHANNEL_VALIDATION_ERRORS,
  UPDATE_EDITABLE_CHANNEL,
  VALIDATE_TOKEN,
  FETCH_THEMES,
  SAVE_AVATAR_TO_SERVER,
  GET_CHANNEL,
  GET_AVAILABLE_TRUNKS,
  SET_CHANNEL,
  FETCH_WEBIM2_OPERATORS,
  GET_SPEECH_PROVIDERS,
  ADD_SPEECH_PROVIDER,
  DELETE_SPEECH_PROVIDER,
  UPDATE_SPEECH_PROVIDER,
  GET_AIMYVOICE_VOICES,
  GET_AZURE_RECOMMENDED_SETTINGS,
  GET_ASM_MODELS,
  GET_INTERNAL_ASM_MODELS,
  VALIDATE_CUSTOM_DATA,
} from '../../constants/channels.actions';
import { FETCH_LIVECHAT_CHANNELS } from '../../constants/livechatChannels.actions';
import { axios } from '../../pipes/functions';

export const getAsmModels = (accountId: number, speechId: number) => ({
  type: GET_ASM_MODELS,
  payload: axios.get(`/api/botadmin/accounts/${accountId}/resterisk-speech/asm/${speechId}/models`),
});

export const getInternalAsmModels = (accountId: number) => ({
  type: GET_INTERNAL_ASM_MODELS,
  payload: axios.get(`/api/botadmin/accounts/${accountId}/resterisk-speech/asm/internal/models`),
});

export const getAzureRecommendedSettings = (accountId: number) => ({
  type: GET_AZURE_RECOMMENDED_SETTINGS,
  payload: axios.get(`/api/botadmin/accounts/${accountId}/resterisk-speech/azure/recommended-settings`),
});

export const getAimyvoiceVoices = (accountId: number) => ({
  type: GET_AIMYVOICE_VOICES,
  payload: axios.get(`/api/botadmin/accounts/${accountId}/aimyvoice/voices`),
});

export const validateToken = (type: string, token: string, url: string) => {
  let validateUrl = `/restapi/channels/validate/${type.toLowerCase()}?token=${token}${
    url ? '&apiEndpoint=' + url : ''
  }`;

  return {
    type: VALIDATE_TOKEN,
    payload: axios.get(validateUrl),
  };
};

export const validateCustomDataForChannel = (accountId: number, channelType: string, customData: CustomDataDto) => {
  const service = new BotConfigService(accountId);
  return {
    type: VALIDATE_CUSTOM_DATA,
    payload: service.validateCustomDataForChannel(channelType, customData),
  };
};

export const clearValidateTokenError = () => {
  return {
    type: CLEAR_VALIDATE_TOKEN_ERROR,
  };
};

export const showClientValidationsErrors = (messageList: unknown[]) => {
  return {
    type: SHOW_CHANNEL_VALIDATION_ERRORS,
    errors: messageList,
  };
};

export const hideClientValidationsErrors = (messageList: unknown[]) => {
  return {
    type: HIDE_CHANNEL_VALIDATION_ERRORS,
    errors: messageList,
  };
};

export const loadChannels = (currentProjectShortName: string) => {
  return {
    type: FETCH_CHANNELS,
    payload: axios.get(`/restapi/botconfig/project/${currentProjectShortName}`),
  };
};
export const loadChannel = (id: number) => {
  return {
    type: GET_CHANNEL,
    payload: axios.get(`/restapi/botconfig/${id}`),
  };
};
export const setChannel = (newChannel: unknown) => {
  return {
    type: SET_CHANNEL,
    payload: { data: newChannel },
  };
};

export const loadChatwidgetThemes = () => {
  return {
    type: FETCH_THEMES,
    payload: axios.get('/restapi/botconfig/chatwidget/themes'),
  };
};

export const updateEditableChannel = (editableChannel: unknown) => {
  return {
    type: UPDATE_EDITABLE_CHANNEL,
    editableChannel: editableChannel,
  };
};

export const selectChannelToEdit = (channel: unknown) => {
  return {
    type: SET_EDITABLE_CHANNEL,
    channel: channel,
  };
};

export const dropEditableChannel = () => {
  return {
    type: CLEAR_EDITABLE_CHANNEL,
  };
};

export const createNewChannel = (channel: unknown, currentProjectShortName: string) => {
  return {
    type: CREATE_CHANNEL,
    payload: axios({
      method: 'post',
      url: `/restapi/botconfig/${currentProjectShortName}`,
      data: channel,
    }),
  };
};

export const saveEditableChannel = (id: number, channelData: unknown) => {
  return {
    type: SAVE_EDITABLE_CHANNEL,
    payload: axios({
      method: 'put',
      url: `/restapi/botconfig/${id}`,
      data: channelData,
    }),
  };
};

export const removeEditableChannel = (id: number) => {
  return {
    type: REMOVE_EDITABLE_CHANNEL,
    payload: axios({
      method: 'delete',
      url: `/restapi/botconfig/${id}`,
    }),
  };
};

export const setChannelToPublish = (id: number) => {
  return {
    type: SET_PUBLISH_CHANNEL_ID,
    id: id,
  };
};

export const publishChannel = (id: number, skipTests = false, runTestsInBackground = false) => {
  return {
    type: PUBLISH_CHANNEL,
    payload: axios.post(`/restapi/botconfig/${id}/publish`, null, { params: { skipTests, runTestsInBackground } }),
  };
};

export const getBranches = (currentProjectShortName: string) => {
  return {
    type: FETCH_BRANCHES,
    payload: axios.get(`/restapi/content/${currentProjectShortName}/branches`),
  };
};

export const getRefs = (accountId: number, projectId: number) => {
  return {
    type: FETCH_REFS,
    payload: axios.get(`/api/editorbe/accounts/${accountId}/projects/${projectId}/content/refs`),
  };
};

export const fetchWebim2Operators = ({
  domain,
  email,
  password,
}: {
  domain: string;
  email: string;
  password: string;
}) => {
  return {
    type: FETCH_WEBIM2_OPERATORS,
    payload: axios.get(
      `/restapi/channels/incoming_webim2/operators?domain=${domain}&adminEmail=${email}&adminPassword=${password}`
    ),
  };
};

export const getOperatorsChannels = (currentProjectShortName: string) => {
  return {
    type: FETCH_LIVECHAT_CHANNELS,
    payload: axios.get(`/restapi/botconfig/project/${currentProjectShortName}/livechat`),
  };
};

export const saveUrlImageToServer = (file: Blob) => {
  let fd = new FormData();
  fd.append('file', file);
  return {
    type: SAVE_AVATAR_TO_SERVER,
    payload: axios.post('/restapi/file/upload', fd),
  };
};

export const getAvailableTrunks = () => {
  return {
    type: GET_AVAILABLE_TRUNKS,
    payload: axios.get('/restapi/sip-trunk/available-trunks'),
  };
};

export const getSpeechProviders = () => {
  return {
    type: GET_SPEECH_PROVIDERS,
    payload: axios.get('/restapi/resterisk-speech'),
  };
};

export const addSpeechProvider = (obj: unknown) => {
  return {
    type: ADD_SPEECH_PROVIDER,
    payload: axios.post('/restapi/resterisk-speech', obj),
  };
};

export const updateSpeechProvider = (obj: unknown) => {
  return {
    type: UPDATE_SPEECH_PROVIDER,
    payload: axios.patch('/restapi/resterisk-speech', obj),
  };
};

export const deleteSpeechProvider = (id: number, channelId: number) => {
  return {
    type: DELETE_SPEECH_PROVIDER,
    payload: axios.delete(`/restapi/resterisk-speech/${id}`, {
      data: {
        botConfigId: channelId,
      },
    }),
  };
};
