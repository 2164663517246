import React from 'react';
import isAccess, { isInternal, isLoggedInAccount, isAccountUser } from '../../isAccessFunction';
import { NavLink } from 'react-router-dom';
import localize from '../../localization';
import { Icon } from '@just-ai/just-ui/dist';
import { cloudDomainsService } from 'services/CloudDomainsService';

const OldUnprojected = props => {
  //todo ZB-3552
  return (
    <nav className='sidebar-nav sidebar-custom'>
      <ul className='nav'>
        {isAccess('ACCOUNTS_READ') && isInternal() && !isLoggedInAccount() ? (
          <li className='nav-item'>
            <a
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.accountsNavLink'
              href={`${cloudDomainsService.getFullDomainPath('cc')}/c/accounts`}
            >
              <Icon name='faParking' size='sm' />
              <span className='nav-item-name'>{localize.translate('Accounts')}</span>
              <div className='sidebar-tooltip'>{localize.translate('Accounts')}</div>
            </a>
          </li>
        ) : null}

        <li className='nav-item'>
          <NavLink
            exact
            to='/'
            className='nav-link'
            activeClassName='active'
            data-test-id='unprojectedSidebar.projectsNavLink'
          >
            <Icon name='faParking' size='sm' wrapperClassName='visible_icon' />
            <span className='nav-item-name'>{localize.translate('Projects')}</span>
            <div className='sidebar-tooltip'>{localize.translate('Projects')}</div>
          </NavLink>
        </li>
        {(isAccess('USERS_READ') || isInternal()) && (
          <li className='nav-item'>
            <NavLink
              to={isInternal() ? '/accounts_userslist' : '/userslist'}
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.userListNavLink'
            >
              <Icon name='faUsers' size='sm' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{localize.translate('Users')}</span>
              <div className='sidebar-tooltip'>{localize.translate('Users')}</div>
            </NavLink>
          </li>
        )}

        <li className='nav-item'>
          <NavLink
            to='/tagsettings'
            className='nav-link'
            activeClassName='active'
            data-test-id='unprojectedSidebar.tagSettingsNavLink'
          >
            <Icon name='faEdit' size='sm' wrapperClassName='visible_icon' />
            <span className='nav-item-name'>{localize.translate('Directories of tags')}</span>
            <div className='sidebar-tooltip'>{localize.translate('Directories of tags')}</div>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink
            to='/calllists'
            className='nav-link'
            activeClassName='active'
            data-test-id='unprojectedSidebar.callListNavLink'
          >
            <Icon name='faPhoneSquare' size='sm' wrapperClassName='visible_icon' />
            <span className='nav-item-name'>{localize.translate('Call lists')}</span>
            <div className='sidebar-tooltip'>{localize.translate('Call lists')}</div>
          </NavLink>
        </li>
        {(isAccess(['SIP_TRUNK_READ']) || isInternal()) && !isAccountUser() && props.telephonyEnabled ? (
          <li className='nav-item'>
            <NavLink
              to='/connectionslist'
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.connectionsListNavLink'
            >
              <Icon name='faServer' size='sm' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{localize.translate('Telephony settings')}</span>
              <div className='sidebar-tooltip'>{localize.translate('Telephony settings')}</div>
            </NavLink>
          </li>
        ) : null}
        {isAccess(['FEATURES_READ']) && !isLoggedInAccount() ? (
          <li className='nav-item'>
            <NavLink
              to='/features'
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.featuresNavLink'
            >
              <i className='icon-note' />
              <span className='nav-item-name'>{localize.translate('Feature settings')}</span>
              <div className='sidebar-tooltip'>{localize.translate('Feature settings')}</div>
            </NavLink>
          </li>
        ) : null}
        {isAccess('AUDIT_READ') ? (
          <li className='nav-item'>
            <NavLink
              to='/userlog'
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.userlogNavLink'
            >
              <Icon name='faIdCard' size='sm' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{localize.translate('User log')}</span>
              <div className='sidebar-tooltip'>{localize.translate('User log')}</div>
            </NavLink>
          </li>
        ) : null}
        {(isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) && props.billingEnabled ? (
          <li className='nav-item'>
            <NavLink
              to='/accountmanager'
              className='nav-link'
              activeClassName='active'
              data-test-id='unprojectedSidebar.userlogNavLink'
            >
              <Icon name='faUserCircle' size='sm' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{localize.translate('account details')}</span>
              <div className='sidebar-tooltip'>{localize.translate('account details')}</div>
            </NavLink>
          </li>
        ) : null}
      </ul>
    </nav>
  );
};
export default OldUnprojected;
