var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { filterSessionIdController } from '../../index';
const OFFSET_TO_REQUEST_NEXT_LIST = 5;
const PAGE_SIZE = 20;
export class SessionIdsController {
    constructor({ filterSetId, accountId, initialPage, projectShortName, getSessionsByFilter, }) {
        this.list = [];
        this.idIndex = 0;
        this.listIndex = 0;
        this.maxListIndex = 0;
        this.normalizeFilters = (filters) => {
            return filters.map(filter => {
                if (filter.key === 'MESSAGE_TIME') {
                    //This is necessary to prevent adding new sessions to the start of the list and shift all sessions
                    //@ts-ignore
                    filter.to = new Date().toISOString();
                }
                return Object.assign({}, filter);
            });
        };
        this.filterSetId = filterSetId;
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.getSessions = getSessionsByFilter;
        this.initialPage = initialPage;
        this.totalCount = 0;
    }
    init(sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.requestSessionsList(this.initialPage);
            this.listIndex = this.initialPage;
            if (this.list[this.listIndex]) {
                const session = this.list[this.listIndex].find(session => sessionId === session.sessionId);
                if (!session)
                    return { sessionId, hasBack: false, hasForward: false, totalCount: 0, currentPosition: 0 };
                this.idIndex = this.list[this.listIndex].indexOf(session);
            }
            return {
                sessionId,
                hasBack: this.initialPage > 0 || this.idIndex > 0,
                hasForward: this.maxListIndex > this.initialPage || this.idIndex < this.list[this.listIndex].length - 1,
                totalCount: this.totalCount,
                currentPosition: this.initialPage * PAGE_SIZE + this.idIndex,
            };
        });
    }
    requestSessionsList(page) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.filters === undefined) {
                this.filters = yield filterSessionIdController.get(this.accountId, this.projectShortName, undefined, this.filterSetId);
                this.filters = this.normalizeFilters(this.filters);
            }
            const { sessions, paging: { totalCount }, } = yield this.getSessions(page, PAGE_SIZE, { filters: this.filters });
            this.totalCount = totalCount;
            const normalisedSessions = sessions.map(({ sessionId, startTime }) => ({ sessionId, startTime }));
            this.maxListIndex = Math.ceil(totalCount / PAGE_SIZE) - 1;
            this.list[page] = normalisedSessions;
        });
    }
    goBack() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.idIndex === 0 && this.listIndex === 0) {
                return this.buildResults();
            }
            if (this.idIndex < OFFSET_TO_REQUEST_NEXT_LIST &&
                !this.list[this.listIndex - 1] &&
                this.listIndex > 0 &&
                !this.backRequest) {
                this.backRequest = this.requestSessionsList(this.listIndex - 1);
                if (this.idIndex === 0) {
                    yield this.backRequest;
                    this.forwardRequest = undefined;
                }
            }
            if (this.idIndex < OFFSET_TO_REQUEST_NEXT_LIST && !this.list[this.listIndex - 1] && this.backRequest) {
                yield this.backRequest;
                this.backRequest = undefined;
            }
            if (this.idIndex === 0 && this.listIndex > 0) {
                this.listIndex -= 1;
                this.idIndex = PAGE_SIZE - 1;
            }
            else {
                this.idIndex -= 1;
            }
            return this.buildResults();
        });
    }
    goForward() {
        return __awaiter(this, void 0, void 0, function* () {
            const maxIndex = this.list[this.listIndex].length - 1;
            if (this.listIndex === this.maxListIndex && this.idIndex === maxIndex) {
                return this.buildResults();
            }
            if (this.idIndex > maxIndex - OFFSET_TO_REQUEST_NEXT_LIST &&
                !this.list[this.listIndex + 1] &&
                !this.forwardRequest) {
                this.forwardRequest = this.requestSessionsList(this.listIndex + 1);
                if (this.idIndex === maxIndex) {
                    yield this.forwardRequest;
                    this.forwardRequest = undefined;
                }
            }
            if (this.idIndex > maxIndex - OFFSET_TO_REQUEST_NEXT_LIST &&
                !this.list[this.listIndex + 1] &&
                this.forwardRequest) {
                yield this.forwardRequest;
                this.forwardRequest = undefined;
            }
            if (this.idIndex === maxIndex) {
                this.listIndex += 1;
                this.idIndex = 0;
            }
            else {
                this.idIndex += 1;
            }
            return this.buildResults();
        });
    }
    buildResults() {
        const session = this.list[this.listIndex][this.idIndex];
        const currentPosition = this.listIndex * PAGE_SIZE + this.idIndex;
        return {
            sessionId: session.sessionId,
            startTime: session.startTime,
            hasForward: currentPosition < this.totalCount - 1,
            hasBack: currentPosition > 0,
            totalCount: this.totalCount,
            currentPosition,
        };
    }
}
