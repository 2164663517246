import {
  FETCH_FULFILLED,
  FETCH_PENDING,
  FETCH_REJECTED,
  PAGINATE_FULFILLED,
  PAGINATE_PENDING,
  PAGINATE_REJECTED,
  ADD_LABELS_FULFILLED,
  ADD_TEXTAREA_FULFILLED,
  CHANGE_MARK_FIELD,
  SET_SELECTED_LOG_MARKS,
  UPDATE_LOG_TASK_FULFILLED,
  UPDATE_LOG_TASK_REJECTED,
  UPDATE_LOG_TASK_PENDING,
  ADD_LOG_TASK_REJECTED,
  ADD_LOG_TASK_FULFILLED,
  ADD_LOG_TASK_PENDING,
} from '../../constants/phrases.actions';

import {
  GET_ISSUES_BY_QUESTION_ID_FULFILLED,
  LOAD_TASK_CREATION_DATA_PENDING,
} from '../../constants/dialogsDetail.actions';
import { CHANGE_CURRENT_PROJECT } from '../../constants/projects.actions';

import { FETCH_ISSUE_DATA_FULFILLED } from '../../constants/tasks.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import { forEach, isEmpty } from 'lodash';
import { getIntFromPageNumber } from '../../pipes/functions';

const InitialState = {
  totalPages: 0,
  totalElements: 0,
  page: 0,
  size: 20,
  phrases: [],
  fetching: false,
  errors: [],
  selectedMarks: {},

  saveIssueErrors: [],
  saveIssueFetching: false,

  selectedIssue: null,
};

export default function PhrasesReducer(state = InitialState, action) {
  switch (action.type) {
    case PAGINATE_PENDING:
    case FETCH_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case PAGINATE_FULFILLED:
    case FETCH_FULFILLED: {
      const phrasesList = action.payload.data?.content || action.payload.logs;
      let selectedMarks = {};

      forEach(phrasesList, item => {
        let tmpSelectedMarks = [];
        forEach(item.labels, mark => {
          tmpSelectedMarks.push(mark.id);
        });
        selectedMarks[item.logId || item.questionId] = tmpSelectedMarks;
      });

      return {
        ...state,
        phrases: phrasesList,
        totalPages: getIntFromPageNumber(
          action.payload.data?.totalPages,
          Math.ceil(action.payload?.paging?.totalCount / action.payload?.paging?.pageSize)
        ),
        page: getIntFromPageNumber(action.payload.data?.number, action.payload.paging?.pageNum),
        size: action.payload.data?.size,
        fetching: false,
        totalElements: action.payload.data?.totalElements || action.payload.paging?.totalCount || 0,
        phraseMarksState: [],
        phraseTextareaState: [],
        selectedMarks: selectedMarks,
        errors: InitialState.errors,
      };
    }

    case PAGINATE_REJECTED:
    case FETCH_REJECTED: {
      return {
        ...state,
        fetching: false,
        errors: !!action.payload.response.data.error
          ? [action.payload.response.data.error]
          : action.payload.response.data.errors,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED:
    case CHANGE_CURRENT_PROJECT: {
      return {
        ...state,
        ...InitialState,
      };
    }

    case ADD_LABELS_FULFILLED: {
      let updatedMarks = { ...state.selectedMarks };

      updatedMarks[action.payload.config.logId] = JSON.parse(action.payload.config.data).labels;
      return {
        ...state,
        selectedMarks: updatedMarks,
      };
    }

    case FETCH_ISSUE_DATA_FULFILLED: {
      const updatedPhrases = state.phrases.slice();

      const index = updatedPhrases.findIndex(phrase => {
        return phrase.logId === action.payload.config.logId;
      });

      updatedPhrases[index].issues = [action.payload.data];

      return {
        ...state,
        phrases: updatedPhrases,
      };
    }

    case ADD_TEXTAREA_FULFILLED: {
      const updatedPhrases = state.phrases.slice();
      const index = updatedPhrases.findIndex(phrase => {
        return phrase.logId === action.payload.config.logId || phrase.questionId === action.payload.config.logId;
      });
      if (index === -1) return state;
      updatedPhrases[index].comment = JSON.parse(action.payload.config.data).comment;

      return {
        ...state,
        phrases: updatedPhrases,
      };
    }

    case CHANGE_MARK_FIELD: {
      let newPhrases = [...state.phrases];
      const index = newPhrases.findIndex(item => {
        return item.logId === action.logId;
      });
      if (index > -1) {
        newPhrases[index].labels = action.value.map(item => {
          return { id: item.value, name: item.label };
        });
      }
      return {
        ...state,
        phrases: newPhrases,
      };
    }

    case SET_SELECTED_LOG_MARKS: {
      let newPhrases = state.phrases.slice();
      const dialogIndex = newPhrases.findIndex(item => {
        return item.logId === action.logId || item.questionId === action.logId;
      });
      let curPhrase = newPhrases[dialogIndex];
      if (!curPhrase) return { ...state };

      if (!isEmpty(curPhrase.issues)) {
        curPhrase.issues[0][action.markType] = action.value;
      } else {
        curPhrase.issues = [];
        curPhrase.issues.push({
          [action.markType]: action.value,
        });
      }

      return {
        ...state,
        phrases: newPhrases,
      };
    }

    case ADD_LOG_TASK_FULFILLED: {
      let dialog = [...state.phrases];
      const index = dialog.findIndex(
        dialog =>
          dialog.logId === action.payload.config.obj.logId || dialog.questionId === action.payload.config.obj.logId
      );

      if (!dialog[index]) return { ...state };

      dialog[index].issues = [];
      dialog[index].issues.push(action.payload.data);

      return {
        ...state,
        phrases: dialog,
        saveIssueFetching: false,
      };
    }

    case UPDATE_LOG_TASK_FULFILLED: {
      const dialog = [...state.phrases];
      const index = dialog.findIndex(
        dialog =>
          dialog.logId === action.payload.config.obj.logId || dialog.questionId === action.payload.config.obj.logId
      );

      if (!dialog[index]) return { ...state };

      const issueIndex = dialog[index].issues.findIndex(issue => {
        return issue.id === action.payload.config.obj.issueId;
      });

      dialog[index].issues[issueIndex] = action.payload.data;
      return {
        ...state,
        phrases: dialog,
        saveIssueFetching: false,
      };
    }

    case ADD_LOG_TASK_REJECTED:
    case UPDATE_LOG_TASK_REJECTED: {
      let errors = !!action.payload.response.data.error
        ? [action.payload.response.data.error]
        : action.payload.response.data.errors;
      return {
        ...state,
        saveIssueErrors: errors,
        saveIssueFetching: false,
      };
    }

    case LOAD_TASK_CREATION_DATA_PENDING: {
      return {
        ...state,
        saveIssueErrors: InitialState.saveIssueErrors,
      };
    }

    case ADD_LOG_TASK_PENDING:
    case UPDATE_LOG_TASK_PENDING: {
      return {
        ...state,
        saveIssueErrors: InitialState.saveIssueErrors,
        saveIssueFetching: true,
      };
    }

    case GET_ISSUES_BY_QUESTION_ID_FULFILLED: {
      const newPhrases = [...state.phrases];
      if (newPhrases.length === 0) return state;
      const issues = action.payload.data;
      Object.keys(issues).forEach(questionId => {
        const index = newPhrases.findIndex(phrase => phrase.questionId === questionId);
        if (index > -1) {
          newPhrases[index].issues = issues[questionId];
        }
      });

      return {
        ...state,
        phrases: newPhrases,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
