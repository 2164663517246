import { PayloadAction } from '@reduxjs/toolkit';
import { ICONS } from '@just-ai/just-ui';
import { Color } from '@just-ai/just-ui/dist/Common';

export type IndicatorContent = {
  title: string;
  icon: ICONS;
  iconColor: Color;
  className?: string;
  dataTestId?: string;
};

export enum JGraphReduxActionEventStatus {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
export type JGraphReduxActionEvent = { type: JGraphReduxActionEventStatus; action?: PayloadAction<any> };

export type JGraphReduxStatus = {
  isError: boolean;
  isPending: boolean;
  action?: PayloadAction<any>;
};
