var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Modal, IconButton } from '@just-ai/just-ui';
import { LabelsGroups } from './LabelsGroups';
export function LabelsForm({ allLabels, currentLabels, isOpen, t, toggle, open, createNewLabelAndAddToMessage, saveLabels, compactView, }) {
    const [openLabels, setOpenLabels] = useState(false);
    const onChangeLabels = useCallback((label) => {
        let newState;
        if (currentLabels.find(x => x.id === label.id)) {
            newState = currentLabels.filter(x => x.id != label.id);
        }
        else {
            newState = [...currentLabels, label];
        }
        saveLabels(newState);
    }, [saveLabels, currentLabels]);
    const onCreateNewLabel = useCallback((labelName, groupId) => __awaiter(this, void 0, void 0, function* () {
        yield createNewLabelAndAddToMessage(labelName, groupId, currentLabels);
    }), [currentLabels, createNewLabelAndAddToMessage]);
    const toggleFullView = useCallback(() => {
        setOpenLabels(x => !x);
    }, [setOpenLabels]);
    useEffect(() => {
        setOpenLabels(!compactView);
    }, [compactView]);
    return (_jsxs(_Fragment, { children: [(allLabels === null || allLabels === void 0 ? void 0 : allLabels.length) > 0 && ((currentLabels && currentLabels.length > 0) || !compactView) && (_jsxs("div", { className: 'Message_form_item_container', children: [_jsx("span", { children: t('Analytics: current labels label') }), _jsxs("div", { className: 'Message_form_item_labels_short', children: [_jsx("div", { children: openLabels ? (_jsx(LabelsGroups, { groups: allLabels, currentLabels: currentLabels, onChange: onChangeLabels, t: t, createNewLabelAndAddToMessage: onCreateNewLabel })) : (currentLabels.map(x => (_jsx("div", { className: 'Message_form_green', children: `${x.group.name}: ${x.name}` }, x.id)))) }), _jsx(IconButton, { "data-test-id": 'qaTaskTaskIcon', name: compactView ? 'farEdit' : openLabels ? 'farChevronUp' : 'farChevronDown', size: 'sm', onClick: compactView ? open : toggleFullView })] })] })), (allLabels === null || allLabels === void 0 ? void 0 : allLabels.length) > 0 && isOpen && (_jsx(EditLabelModal, { isOpen: isOpen, currentLabels: currentLabels || [], allLabels: allLabels, t: t, toggle: toggle, createNewLabelAndAddToMessage: createNewLabelAndAddToMessage, saveLabels: saveLabels }))] }));
}
function EditLabelModal({ isOpen, toggle, t, saveLabels, currentLabels, allLabels, createNewLabelAndAddToMessage, }) {
    const [choosedLabels, setChoosedLabels] = useState(currentLabels);
    useEffect(() => {
        setChoosedLabels(currentLabels);
    }, [currentLabels]);
    const onChangeLabels = useCallback((label) => {
        let newState;
        if (choosedLabels.find(x => x.id === label.id)) {
            newState = choosedLabels.filter(x => x.id != label.id);
        }
        else {
            newState = [...choosedLabels, label];
        }
        setChoosedLabels(newState);
    }, [setChoosedLabels, choosedLabels]);
    const onCreateNewLabel = useCallback((labelName, groupId) => __awaiter(this, void 0, void 0, function* () {
        createNewLabelAndAddToMessage(labelName, groupId, choosedLabels);
    }), [choosedLabels]);
    return (_jsx(Modal, { isOpen: isOpen, onCancelClick: toggle, onActionClick: () => saveLabels(choosedLabels), title: t('Tags'), buttonCancelText: t('Cancel'), buttonCancelColor: 'secondary', buttonSubmitText: t('Analytics: Save changes'), buttonSubmitColor: 'info', children: _jsx(LabelsGroups, { groups: allLabels, currentLabels: choosedLabels, createNewLabelAndAddToMessage: onCreateNewLabel, t: t, onChange: onChangeLabels }) }));
}
