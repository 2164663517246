import React, { FC } from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';

type NluIntentTitleProps = {
  title: string;
  count?: number;
};

export const NluIntentTitle: FC<NluIntentTitleProps> = ({ title, count }) => {
  return (
    <div className={cn('d-flex w-100', classes.CailaIntentsSimple_listItemTitle)}>
      <div className='d-flex font-size-14 font-bold flex-grow-1'>{title}</div>
      {count && count > 0 && (
        <div
          className={cn(
            'd-flex color-gray-600 font-size-14 justify-content-center',
            classes.CailaIntentsSimple_listItemTitle_counter
          )}
        >
          {count}
        </div>
      )}
    </div>
  );
};
