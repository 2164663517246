import isAccess from 'isAccessFunction';

export type TimeZoneType = {
  value: string;
  label: string;
};

export const TIMEZONES: TimeZoneType[] = [
  { value: 'UTC-12:00', label: 'UTC-12:00' },
  { value: 'UTC-11:00', label: 'UTC-11:00' },
  { value: 'UTC-10:00', label: 'UTC-10:00' },
  { value: 'UTC-09:00', label: 'UTC-09:00' },
  { value: 'UTC-08:00', label: 'UTC-08:00' },
  { value: 'UTC-07:00', label: 'UTC-07:00' },
  { value: 'UTC-06:00', label: 'UTC-06:00' },
  { value: 'UTC-05:00', label: 'UTC-05:00' },
  { value: 'UTC-04:00', label: 'UTC-04:00' },
  { value: 'UTC-03:00', label: 'UTC-03:00' },
  { value: 'UTC-02:00', label: 'UTC-02:00' },
  { value: 'UTC-01:00', label: 'UTC-01:00' },
  { value: 'UTC', label: 'UTC' },
  { value: 'UTC+01:00', label: 'UTC+01:00' },
  { value: 'UTC+02:00', label: 'UTC+02:00' },
  { value: 'UTC+03:00', label: 'UTC+03:00' },
  { value: 'UTC+04:00', label: 'UTC+04:00' },
  { value: 'UTC+05:00', label: 'UTC+05:00' },
  { value: 'UTC+06:00', label: 'UTC+06:00' },
  { value: 'UTC+07:00', label: 'UTC+07:00' },
  { value: 'UTC+08:00', label: 'UTC+08:00' },
  { value: 'UTC+09:00', label: 'UTC+09:00' },
  { value: 'UTC+10:00', label: 'UTC+10:00' },
  { value: 'UTC+11:00', label: 'UTC+11:00' },
  { value: 'UTC+12:00', label: 'UTC+12:00' },
  { value: 'UTC+13:00', label: 'UTC+13:00' },
  { value: 'UTC+14:00', label: 'UTC+14:00' },
];

export type ClassificationAlgorithmType = {
  label: string;
  value: string;
  disabled?: boolean;
};
export const getClassificationAlgorithmTypes = (): ClassificationAlgorithmType[] => [
  {
    value: 'sts',
    label: 'STS',
  },
  {
    value: 'transformer',
    label: 'Transformer',
    disabled: !isAccess('mlp'),
  },
  {
    value: 'regression',
    label: 'Classic ML',
  },
  {
    value: 'deeplearning',
    label: 'Deep Learning',
  },
];
