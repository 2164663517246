var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { withRouter } from 'react-router-dom';
import { subMonths } from 'date-fns';
import { Spinner, Button, TranslationContextCustomLocalization, withTranslationContext, } from '@just-ai/just-ui';
import { ProjectStatsService } from '@just-ai/analytic-front/dist/Reporter/service/AnalyticsService';
import { FilterKey } from '@just-ai/analytic-front/dist/Reporter/api/client/api';
import { SkillType } from '@just-ai/api/dist/generated/Caila/';
import { FAQIntentService } from '../../services/FAQIntentService';
import FAQApiService from '../../services/FAQApiService';
import { generateFAQPathByTemplateName } from '../../nlu-related/utils/intents';
import FaqTemplatesTabs from './components/FaqTemplatesTabs';
import InputComponent from './components/TabsCollapsible/TabRenameInput';
import { FaqSkillViewWithTranslation } from './view/FaqSkillView';
import FaqHomeView from './view/FaqHomeView';
import { HOME_TAB_CODE, isKnowledgeBaseBeenVisitedLSKey } from './constants';
import { NLUModuleContext } from '../../context';
import { faqLocalization } from '../../localization/faq.loc';
import classes from './styles.module.scss';
class FAQ extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.projectShortName = '';
        this.state = {
            activeSkillName: '',
            activeTemplateId: '',
            skillViewKey: 0,
            loadingInPage: false,
            skills: [],
            needsTraining: false,
            faqActivated: false,
        };
        this.hiddenUpdateIntentsData = () => this.updateIntentsData('');
        this.updateIntentsData = (loadingType = 'loadingInSidebar', activeTemplate) => this.promiseLoadingHelper(loadingType, this.skillsApiService.listSkills().then(skills => {
            var _a, _b, _c;
            if (((_a = this.context.currentProject) === null || _a === void 0 ? void 0 : _a.id) && this.context.setCurrentProject) {
                this.context.setCurrentProject(Object.assign(Object.assign({}, this.context.currentProject), { isCdqaCreated: skills.some(skill => skill.type === SkillType.Cdqa), isCdqaEnabled: (_b = skills.find(skill => skill.type === SkillType.Cdqa)) === null || _b === void 0 ? void 0 : _b.enabled }));
            }
            if (skills.length > 0) {
                localStorage.setItem(`${isKnowledgeBaseBeenVisitedLSKey}-${this.projectShortName}`, 'true');
            }
            const isKnowledgeBaseNotBeenVisited = localStorage.getItem(`${isKnowledgeBaseBeenVisitedLSKey}-${this.projectShortName}`);
            if (!isKnowledgeBaseNotBeenVisited) {
                this.context.wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true } });
                return;
            }
            const skillsSorted = (skills || []).sort((a, b) => { var _a, _b; return (((_a = b.created) === null || _a === void 0 ? void 0 : _a.timestamp) || 0) - (((_b = a.created) === null || _b === void 0 ? void 0 : _b.timestamp) || 0); });
            const activeSkillName = activeTemplate ||
                this.getInitialTemplate(skillsSorted.map(skill => skill.name), HOME_TAB_CODE);
            const activeTemplateId = activeSkillName ? ((_c = skills.find(skill => skill.name === activeSkillName)) === null || _c === void 0 ? void 0 : _c.id) || '' : '';
            const newState = {
                skills: skillsSorted,
                activeSkillName,
                activeTemplateId,
                needsTraining: true,
            };
            this.setState(newState);
            return newState;
        }));
        this.getProjectFaqStatus = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const hasFaqState = yield this.faqApiService.projectHasFaqState();
                this.setState({ faqActivated: hasFaqState });
            }
            catch (error) {
                if (error instanceof Error) {
                    this.context.appLogger.error({
                        message: 'FAQ: Error fetching project tags',
                        exception: error,
                    });
                }
            }
        });
        this.trainNlu = () => __awaiter(this, void 0, void 0, function* () { return this.context.TrainingApi.trainNLU(this.context.accountId, this.context.projectShortName); });
        this.setTab = (tagCode) => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                activeSkillName: tagCode,
            });
            yield this.updateIntentsData();
        });
        this.handleTabDelete = (tabValue) => __awaiter(this, void 0, void 0, function* () {
            const skillToDelete = this.state.skills.find(skill => skill.name === tabValue);
            if (!skillToDelete)
                return;
            try {
                yield this.skillsApiService.deleteSkill(skillToDelete);
                if (this.state.activeTemplateId === skillToDelete.id) {
                    this.setState({
                        activeSkillName: '',
                        activeTemplateId: '',
                    }, () => {
                        this.props.history.replace(this.props.location.pathname);
                        this.updateIntentsData();
                    });
                    return;
                }
                this.updateIntentsData();
            }
            catch (error) {
                if (error instanceof Error) {
                    this.context.appLogger.error({
                        message: 'FAQ: Error is FAQ deleting',
                        exception: error,
                    });
                }
            }
        });
        this.handleTabRename = (oldValue, newValue, changeTab) => __awaiter(this, void 0, void 0, function* () {
            const updatedSkills = [...this.state.skills];
            const updatedSkillIndex = updatedSkills.findIndex(tab => tab.name === oldValue);
            if (updatedSkillIndex === -1)
                return;
            updatedSkills[updatedSkillIndex].name = newValue.trim();
            updatedSkills[updatedSkillIndex].editComponent = undefined;
            try {
                if (oldValue !== newValue.trim()) {
                    updatedSkills[updatedSkillIndex] = yield this.skillsApiService.updateSkill(updatedSkills[updatedSkillIndex]);
                }
                this.setState({ skills: updatedSkills }, () => {
                    if (changeTab)
                        this.setTab(newValue);
                });
            }
            catch (error) {
                if (error instanceof Error) {
                    this.context.appLogger.error({
                        message: 'FAQ: Error is FAQ renaming',
                        exception: error,
                    });
                }
            }
        });
        this.handleTabRenameMode = (tabValue) => __awaiter(this, void 0, void 0, function* () {
            const updatedSkills = [...this.state.skills];
            const updatedSkillIndex = updatedSkills.findIndex(tab => tab.name === tabValue);
            if (updatedSkills[updatedSkillIndex].name !== this.state.activeSkillName) {
                yield this.setTab(tabValue);
            }
            requestAnimationFrame(() => {
                updatedSkills[updatedSkillIndex].editComponent = (_jsx(InputComponent, { initialValue: updatedSkills[updatedSkillIndex].name, tabsNames: this.state.skills.map(skill => skill.name), handleTabBlur: (oldVal, newVal) => this.handleTabRename(oldVal, newVal, true) }));
                this.setState({ skills: updatedSkills });
            });
        });
        this.switchFullSkill = (skillName, value) => __awaiter(this, void 0, void 0, function* () {
            const skillToUpdate = this.state.skills.find(skill => skill.name === skillName);
            if (!skillToUpdate)
                return Promise.resolve();
            if (value === undefined) {
                value = !skillToUpdate.enabled;
            }
            if (skillToUpdate.enabled === value)
                return Promise.resolve();
            yield this.skillsApiService.updateSkill(Object.assign(Object.assign({}, skillToUpdate), { enabled: value }));
            yield this.hiddenUpdateIntentsData();
            this.setState({ skillViewKey: this.state.skillViewKey + 1 });
        });
        this.activateFaqInScenario = () => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.faqApiService.activateFaqInScenario();
                this.setState({ faqActivated: true }, () => {
                    this.context.actions.addAlert({
                        showed: true,
                        type: 'info',
                        message: this.props.t('FAQ:activationNotification:alertText'),
                        title: '',
                        time: Date.now(),
                    });
                });
            }
            catch (error) {
                if (error instanceof Error) {
                    this.context.appLogger.error({
                        message: 'FAQ: Error activating faq in project scenario',
                        exception: error,
                    });
                }
            }
        });
        this.goToAnalytic = (skill) => __awaiter(this, void 0, void 0, function* () {
            const { accountId, projectShortName, FAQApi } = this.context;
            const { data: skillQuestions } = yield FAQApi.getFaqQuestionList(accountId, projectShortName, generateFAQPathByTemplateName(skill.name));
            const filterSetId = yield this.projectStatsService.getFilterSetId({
                filters: [
                    {
                        key: FilterKey.MESSAGETIME,
                        type: 'DATE_TIME_RANGE',
                        // @ts-ignore
                        from: subMonths(new Date(), 1).toISOString(),
                        to: new Date().toISOString(),
                    },
                    {
                        key: FilterKey.INTENT,
                        type: 'TREE',
                        // @ts-ignore
                        options: skillQuestions.map(question => question.intent.path),
                    },
                ],
            });
            window.open(`/${projectShortName}/statistic/dialogs?activeTab=messages&filterSetId=${filterSetId}`);
        });
        this.handleSkillUpdate = (skillId, newSkillData) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (newSkillData.file) {
                yield this.skillsApiService.updateSkillFile(skillId, SkillType.Cdqa, newSkillData.file);
            }
            if (newSkillData.lang || newSkillData.name) {
                const updatedSkill = this.state.skills.find(skill => skill.type === SkillType.Cdqa);
                const updatedSkillIndex = this.state.skills.findIndex(skill => skill.type === SkillType.Cdqa);
                if (!updatedSkill)
                    return;
                const updateResult = yield this.skillsApiService.updateSkill({
                    id: skillId,
                    enabled: updatedSkill.enabled,
                    name: newSkillData.name || updatedSkill.name,
                    type: SkillType.Cdqa,
                    settings: Object.assign(Object.assign({}, updatedSkill.settings), { languageCode: newSkillData.lang || ((_a = updatedSkill.settings) === null || _a === void 0 ? void 0 : _a.languageCode) }),
                });
                const updatedSkills = [...this.state.skills];
                updatedSkills[updatedSkillIndex] = updateResult;
                this.setState({ skills: updatedSkills });
            }
        });
    }
    componentDidMount() {
        var _a, _b, _c, _d;
        const { accountId, projectShortName, IntentsApi, FAQApi, skillsApiService, axiosInstance } = this.context;
        this.projectShortName = projectShortName;
        this.faqApiService = new FAQApiService(accountId, projectShortName, IntentsApi, FAQApi, axiosInstance);
        this.projectStatsService = new ProjectStatsService(accountId, projectShortName);
        this.skillsApiService = skillsApiService;
        this.getProjectFaqStatus();
        const fromIntentPath = (_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.fromIntent;
        const templateName = (_b = this.props.location.state) === null || _b === void 0 ? void 0 : _b.templateName;
        const returnedFromTemplatePage = sessionStorage.getItem('FAQ_from_root') === 'true';
        const isKnowledgeBaseBeenVisited = localStorage.getItem(`${isKnowledgeBaseBeenVisitedLSKey}-${this.projectShortName}`);
        // если проектов нет, и человек просто хочет уйти с бз, то его возвращаем на 3 назад - /faq, /faq-template, /faq, -> /target
        // если на эту страницу попали по кнопке "назад" в браузере и это было со страницы с шаблонами, при этом никаких шаблонов не выбрано, возвращаем туда где юзер был до захода на /faq
        if (!isKnowledgeBaseBeenVisited &&
            (((_c = this.props.location.state) === null || _c === void 0 ? void 0 : _c.addSkill) || returnedFromTemplatePage) &&
            !fromIntentPath &&
            !templateName) {
            window.history.replaceState({}, document.title);
            this.props.history.go(((_d = this.props.location.state) === null || _d === void 0 ? void 0 : _d.addSkill) ? -3 : -1);
            return sessionStorage.clear();
        }
        window.history.replaceState({}, document.title);
        const activeTemplate = templateName || (fromIntentPath ? FAQIntentService.getTemplateTitleFromPath(fromIntentPath) : '');
        this.updateIntentsData('loadingInPage', activeTemplate);
    }
    promiseLoadingHelper(type, promise) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState(prev => (Object.assign(Object.assign({}, prev), { [type]: true })));
            try {
                const result = yield promise;
                this.setState(prev => (Object.assign(Object.assign({}, prev), { [type]: false })));
                return result;
            }
            catch (e) {
                this.setState(prev => (Object.assign(Object.assign({}, prev), { [type]: false })));
                return Promise.reject(e);
            }
        });
    }
    getInitialTemplate(existedTemplates, defaultValue = '') {
        var _a, _b, _c;
        if (this.state.activeSkillName) {
            return this.state.activeSkillName;
        }
        if (existedTemplates.length === 0) {
            return defaultValue;
        }
        if ((_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.fromTab) {
            return (_b = this.props.location.state) === null || _b === void 0 ? void 0 : _b.fromTab;
        }
        if ((_c = this.props.location.state) === null || _c === void 0 ? void 0 : _c.templateTitle) {
            return this.props.location.state.templateTitle;
        }
        return defaultValue;
    }
    render() {
        const writeDisabled = !this.context.isAccessFunction('BOT_CONTENT_WRITE');
        const isLoaded = !this.state.loadingInPage;
        if (this.state.loadingInPage) {
            return _jsx(Spinner, {});
        }
        return (_jsxs("div", { className: classes.faqPage, children: [isLoaded && !this.state.faqActivated && !this.context.isZfl && (_jsxs("div", { className: classes.faqPage__notification, children: [this.props.t('FAQ:activationNotification'), _jsx("a", { href: this.props.t('FAQ:activationNotification:url'), target: '_blank', rel: 'noreferrer noopener', children: _jsx(Button, { outline: true, size: 'sm', color: 'secondary', children: this.props.t('FAQ:activationNotification:button:details') }) }), _jsx(Button, { outline: true, size: 'sm', color: 'primary', onClick: this.activateFaqInScenario, children: this.props.t('FAQ:activationNotification:button:connect') })] })), this.state.loadingInPage && _jsx(Spinner, { backgroundColor: 'rgba(255, 255, 255, 0.2)' }), _jsx(FaqTemplatesTabs, { homeTabCode: HOME_TAB_CODE, activeTab: this.state.activeSkillName, onChange: this.setTab, skills: this.state.skills.filter(skill => skill.type !== SkillType.Cdqa), needsTraining: this.state.needsTraining, trainNlu: this.trainNlu, onTabDelete: this.handleTabDelete, onTabRename: this.handleTabRenameMode, onTabToggle: this.switchFullSkill, writeDisabled: writeDisabled }), this.state.activeSkillName === HOME_TAB_CODE ? (_jsx(FaqHomeView, { skills: this.state.skills, loading: this.state.loadingInPage, onSelect: this.setTab, onDelete: this.handleTabDelete, onRename: this.handleTabRename, onToggle: this.switchFullSkill, goToAnalytic: this.goToAnalytic, onUpdate: this.handleSkillUpdate })) : (_jsx(FaqSkillViewWithTranslation, { activeSkillName: this.state.activeSkillName, onToggleEnable: this.switchFullSkill, setSkillTab: this.setTab, history: this.props.history }, this.state.skillViewKey))] }));
    }
}
FAQ.contextType = NLUModuleContext;
const ConnectedFaq = withRouter(withTranslationContext(FAQ));
const MemoizedFAQ = React.memo(({ localizationObject = {} }) => (_jsx(TranslationContextCustomLocalization, { localizationObject: Object.assign(Object.assign({}, faqLocalization), localizationObject), children: _jsx(ConnectedFaq, {}) })));
MemoizedFAQ.displayName = 'React.memo(FAQTemplate)';
export default MemoizedFAQ;
