import { GuideTourStatus } from './GuideTourStatus';
import { ThemesInformerModalStatus } from './ThemesInformerModalStatus';

export enum JGraphNotificationFeature {
  GuideTour,
  ThemesInformerModal,
}

export interface IFeaturesNotification {
  isCanBeShowed(): Promise<boolean>;
  getStatus(): Promise<string>;
  skip(): Promise<void>;
  skipForever(): Promise<void>;
  finish(): void;
}

class FeaturesNotificationService {
  private guideTourStatus = new GuideTourStatus();
  private themesInformerStatus = new ThemesInformerModalStatus();

  public async shouldNotifyAbout(feature: JGraphNotificationFeature): Promise<boolean> {
    switch (feature) {
      case JGraphNotificationFeature.GuideTour:
        return this.guideTourStatus.isCanBeShowed();
      case JGraphNotificationFeature.ThemesInformerModal:
        const isGuideTourMayBeShowed = await this.guideTourStatus.isCanBeShowed();
        const isThemesInformerMayBeShowed = await this.themesInformerStatus.isCanBeShowed();
        return !isGuideTourMayBeShowed && isThemesInformerMayBeShowed;
    }
  }
  public async getStatus(feature: JGraphNotificationFeature): Promise<string> {
    switch (feature) {
      case JGraphNotificationFeature.GuideTour:
        return this.guideTourStatus.getStatus();
      case JGraphNotificationFeature.ThemesInformerModal:
        return this.themesInformerStatus.getStatus();
    }
  }
  public async skipNotification(feature: JGraphNotificationFeature): Promise<void> {
    switch (feature) {
      case JGraphNotificationFeature.GuideTour:
        await this.guideTourStatus.skip();
        break;
      case JGraphNotificationFeature.ThemesInformerModal:
        await this.themesInformerStatus.skip();
        break;
    }
  }
  public async skipForeverNotification(feature: JGraphNotificationFeature): Promise<void> {
    switch (feature) {
      case JGraphNotificationFeature.GuideTour:
        await this.guideTourStatus.skipForever();
        await this.themesInformerStatus.skipForever();
        break;
      case JGraphNotificationFeature.ThemesInformerModal:
        await this.themesInformerStatus.skipForever();
        break;
    }
  }
  public finishNotification(feature: JGraphNotificationFeature): void {
    switch (feature) {
      case JGraphNotificationFeature.GuideTour:
        // noinspection ES6MissingAwait
        this.guideTourStatus.finish();
        this.themesInformerStatus.finish();
        break;
      case JGraphNotificationFeature.ThemesInformerModal:
        // noinspection ES6MissingAwait
        this.themesInformerStatus.finish();
        break;
    }
  }
}

export const FeaturesNotificationServiceInstance = new FeaturesNotificationService();
