import React, { FunctionComponent, useState, useEffect, useMemo, Fragment, useRef, useCallback } from 'react';
import { Popover, IconButton, Icon, Tabs, Button, Modal, Tooltip, ButtonProps, Locale } from '@just-ai/just-ui';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactDOM from 'react-dom';
import { useToggle } from '../../../Caila/utils';
import '../styles.scss';
import localize from '../../../../localization';
import {
  filterTasks,
  toSortedByDateObject,
  getLatestTaskNotificationMessage,
  getLogOutputFromMessage,
  findFileUrl,
  download,
  TaskStatusData,
  getTaskBodyText,
} from '../../utils/tasks';
import { useAppContext } from '../../../Caila/components/AppContext';
import { useTasks, LOCALSTORAGE_PREFIX } from '../../context/TasksContext';
import Task from './Task';
import classNames from 'classnames';
import { getDateStr } from '../../utils';

import TaskInProgressModal from './TaskInProgressModal';
import { tasksLocalization } from '../../localization/tasks.loc';
import NotificationLog from '../Notifications/NotificationLog';

localize.addTranslations(tasksLocalization);

interface TasksListProps {
  locale: Locale;
}

const DEFAULT_LOG_CONTENT = { title: '', message: '' };

export const TasksList: FunctionComponent<TasksListProps> = ({ locale }) => {
  const [isOpen, open, close] = useToggle(false);
  const [activeTab, setActiveTab] = useState('all');
  const { projectShortName, appConfig } = useAppContext();
  const {
    tasks,
    deleteTask,
    deleteReportGenerationTask,
    deleteAllTasks,
    markHovered,
    markHoveredAll,
    modalType,
    isOpenModal,
    setModalOpen,
    popoverText,
    isOpenedDeployPopover,
    closeDeployPopover,
    isOpenLog,
    setLogOpen,
    selectedTaskId,
  } = useTasks();
  const [hasHotTask, setHasHotTask] = useState(false);
  const [tasksListsDOM, setTasksListsDOM] = useState<HTMLElement | null>(null);
  const [isOpenTooltip, openTooltip, closeTooltip] = useToggle(false);
  const [logContent, setLogContent] = useState(() => DEFAULT_LOG_CONTENT);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [openSpoilers, setOpenSpoilers] = useState<String[]>([]);

  const toggleSpoiler = (id: string) => {
    if (openSpoilers.includes(id)) {
      return setOpenSpoilers([...openSpoilers.filter(openId => openId !== id)]);
    }
    return setOpenSpoilers([...openSpoilers, id]);
  };

  const tabValues = [
    {
      name: localize.translate('tasks tabs all'),
      value: 'all',
      dataTestId: 'Tasks.Tabs.All',
    },
    {
      name: localize.translate('tasks tabs progress'),
      value: 'progress',
      dataTestId: 'Tasks.Tabs.InProgress',
    },
    {
      name: localize.translate('tasks tabs done'),
      value: 'completed',
      dataTestId: 'Tasks.Tabs.Completed',
    },
    {
      name: localize.translate('tasks tabs errors'),
      value: 'canceled',
      dataTestId: 'Tasks.Tabs.Errors',
    },
  ];

  const tasksToDisplay = useMemo(() => {
    return toSortedByDateObject(filterTasks(tasks, activeTab, projectShortName));
  }, [activeTab, projectShortName, tasks]);

  const checkClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e?.target as HTMLElement;
      const popover = document.getElementById('tasksPopover');
      if (
        popover &&
        target &&
        buttonRef &&
        buttonRef.current &&
        !popover.contains(target as Node) &&
        !buttonRef.current.contains(target as Node)
      ) {
        close();
      }
    },
    [close]
  );

  const onOpenLog = useCallback(
    (taskId: string) => {
      const taskToOpen = tasks.find(task => task.taskId === taskId);
      if (taskToOpen) {
        const taskLatestMessage = taskToOpen.message?.data?.output
          ? getLogOutputFromMessage(taskToOpen.message)
          : getLatestTaskNotificationMessage(taskToOpen);
        setLogOpen(true);
        setLogContent({
          title: localize.translate('Scenario deployment results'),
          message: taskLatestMessage || 'No message received',
        });
      }
    },
    [setLogOpen, tasks]
  );

  useMemo(() => {
    setHasHotTask(tasks.some(task => task.projectId === projectShortName && task.hovered));
  }, [tasks, projectShortName]);

  useEffect(() => {
    document.addEventListener('tasks_list_open', open);
    return () => {
      document.removeEventListener('tasks_list_open', open);
    };
  }, [open]);

  useEffect(() => {
    setTasksListsDOM(document.getElementById('tasks-list'));
  }, []);

  useEffect(() => {
    if (isOpenLog && selectedTaskId && !logContent.message) {
      onOpenLog(selectedTaskId);
    }
  }, [isOpenLog, selectedTaskId, logContent, onOpenLog]);

  useEffect(() => {
    if (isOpen) closeTooltip();
  }, [closeTooltip, isOpen]);

  useEffect(() => {
    isOpen && !isOpenLog && document.addEventListener('click', checkClickOutside);
    return () => document.removeEventListener('click', checkClickOutside);
  }, [checkClickOutside, isOpen, isOpenLog]);

  const hideModalToggle = (type: string) => {
    const newValue = localStorage.getItem(`${LOCALSTORAGE_PREFIX}${type}`) === 'true' ? 'false' : 'true';
    localStorage.setItem(`${LOCALSTORAGE_PREFIX}${type}`, newValue);
  };

  const onClose = () => {
    close();
    markHoveredAll();
  };

  const onCloseLog = useCallback(() => {
    setLogOpen(false);
    setLogContent(DEFAULT_LOG_CONTENT);
  }, [setLogOpen]);

  const hasLog = useCallback(
    (taskId): boolean => {
      const taskToOpen = tasks.find(task => task.taskId === taskId);
      if (taskToOpen) {
        const taskLatestMessage = taskToOpen.message?.data?.output
          ? getLogOutputFromMessage(taskToOpen.message)
          : getLatestTaskNotificationMessage(taskToOpen);
        if (taskLatestMessage.length > 0) {
          return true;
        }
      }
      return false;
    },
    [tasks]
  );

  const getActionOnclickFunction = useCallback(
    (task: TaskStatusData): ButtonProps & { caption?: string } => {
      let onClick = task.message?.data && hasLog(task.taskId) ? () => onOpenLog(task.taskId) : null;
      let color: ButtonProps['color'] = 'success';
      const messageWithFileUrl: TaskStatusData['message'] | string =
        task.message?.data?.fileUrl || findFileUrl(task.notifications);
      let caption = undefined;

      let result: ButtonProps & { caption?: string } = {
        caption,
        color,
      };

      if (onClick) {
        result.onClick = onClick;
      }

      if (messageWithFileUrl) {
        result.color = 'primary';
        //@ts-ignore
        let fileUrl: string = messageWithFileUrl;
        if (typeof messageWithFileUrl === 'object') {
          //@ts-ignore
          fileUrl = messageWithFileUrl.data?.fileUrl as string;
        }

        result.caption = localize.translate('Download');

        onClick = download(fileUrl, fileUrl);

        result.onClick = onClick;
      }

      return result;
    },
    [hasLog, onOpenLog]
  );

  const getCancelTaskAction = useCallback(
    task => {
      switch (task.message?.code?.code) {
        case 'reporter.reports.report_generation_task_created':
        case 'reporter.reports.report_generation_task_progress': {
          return () => deleteReportGenerationTask(task);
        }
        case 'botadmin.logs.logs_download_task_created':
        case 'botadmin.logs.logs_download_task_started':
        case 'botadmin.logs.logs_download_task_progress': {
          return () => deleteTask(task);
        }
        default:
          return undefined;
      }
    },
    [deleteReportGenerationTask, deleteTask]
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const hideByModalType = useCallback(() => {
    hideModalToggle(String(modalType));
  }, [modalType]);

  const openHandler = useCallback(() => {
    open();
    closeDeployPopover();
  }, [closeDeployPopover, open]);

  useEffect(() => {
    if (isOpen) {
      closeDeployPopover();
    }
  }, [closeDeployPopover, isOpen]);

  const deleteTaskHandler = useCallback(() => (task: TaskStatusData) => deleteTask(task), [deleteTask]);

  if (!tasksListsDOM) return null;

  return ReactDOM.createPortal(
    <div>
      <IconButton
        name='farListAlt'
        id='tasks-popover'
        data-test-id='Header.TasksButton'
        onClick={() => (isOpen ? onClose() : openHandler())}
        className={classNames('tasks__button navbar-btn', { 'tasks__button-active': hasHotTask })}
        onMouseOver={() => closeDeployPopover()}
        innerRef={buttonRef}
      />
      <Tooltip
        target='tasks-popover'
        isOpen={isOpenTooltip}
        placement='bottom'
        textAlign='center'
        toggle={() => {
          !isOpenTooltip && !isOpen && !isOpenLog ? openTooltip() : closeTooltip();
        }}
        delay={{ show: 400, hide: 200 }}
      >
        {localize.translate('tasks tooltip text')}
      </Tooltip>
      <Popover
        isOpen={isOpenedDeployPopover}
        target='tasks-popover'
        placement='bottom'
        className='tasks'
        onClick={openHandler}
      >
        <span>{popoverText}</span>
        <Icon style={{ marginLeft: 8 }} className='task-spinner ' name='faSpinner' color='info' size='sm' />
      </Popover>
      <Popover
        isOpen={isOpen}
        target='tasks-popover'
        placement='bottom'
        title={localize.translate('tasks task list')}
        onCancelClick={onClose}
        id='tasksPopover'
        className='notifications'
      >
        <div className='notifications__body'>
          <div className='notifications__tabs'>
            <Tabs
              tabs={tabValues}
              activeTab={activeTab}
              name='notification-tabs'
              onChange={tab => setActiveTab(tab)}
              compact
            />
          </div>
          <div className={classNames('tasks__container', { empty: Object.keys(tasksToDisplay).length < 1 })}>
            {Object.keys(tasksToDisplay).length < 1 ? (
              <div className='notifications__empty'>
                <span>{localize.translate('tasks task list empty')}</span>
                <Icon name='faInbox' size='6x' />
              </div>
            ) : (
              <>
                <PerfectScrollbar>
                  {Object.entries(tasksToDisplay)
                    .reverse()
                    .map(([key, tasks]) => (
                      <Fragment key={`tasks__date-${key}`}>
                        <div className='tasks__date'>
                          <span>{getDateStr(key)}</span>
                        </div>
                        {tasks.map(task => (
                          <Task
                            key={task.taskId}
                            task={task}
                            body={getTaskBodyText(task)}
                            onDelete={deleteTaskHandler()}
                            onCancel={getCancelTaskAction(task)}
                            markHovered={markHovered}
                            actionButtonProp={getActionOnclickFunction(task)}
                            openedSpoiler={openSpoilers.includes(task.taskId)}
                            toggleSpoiler={() => {
                              toggleSpoiler(task.taskId);
                            }}
                          />
                        ))}
                      </Fragment>
                    ))}
                </PerfectScrollbar>
              </>
            )}
            <div className='tasks__delete'>
              <Button
                color='secondary'
                flat
                iconRight='farTrashAlt'
                onClick={() => {
                  deleteAllTasks();
                  close();
                }}
              >
                {localize.translate('tasks delete completed button')}
              </Button>
            </div>
          </div>
        </div>
      </Popover>
      <TaskInProgressModal
        isOpenModal={isOpenModal}
        modalType={modalType}
        onActionClick={closeModal}
        hideModalToggle={hideByModalType}
        showHelp={appConfig?.botadmin?.showHelp}
      />
      <Modal
        size='lg'
        isOpen={isOpenLog}
        title={logContent.title}
        buttonSubmitText={localize.translate('tasks modal submit button')}
        onActionClick={onCloseLog}
        buttonSubmitTestId='TaskList.ModalLogs.ButtonSubmit'
      >
        <NotificationLog message={logContent.message} />
      </Modal>
    </div>,
    tasksListsDOM
  );
};
