import React, { FC, useCallback } from 'react';
import { Button, Icon } from '@just-ai/just-ui';
import { t } from 'localization';
import classes from './index.module.scss';
import cn from 'classnames';

export const CommonOpenAddingButton: FC<{ onClick: () => unknown; className?: string; title?: string }> = React.memo(
  ({ onClick, className = '', title }) => {
    const onClickHandler = useCallback(
      e => {
        e.stopPropagation();
        onClick();
      },
      [onClick]
    );
    return (
      <Button
        className={cn(classes.ScreenBlocksListOpenAdder, className)}
        color='secondary'
        outline
        onClick={onClickHandler}
        data-test-id='ScreenBlocksListOpenAdder'
      >
        {title || t('ScreenBlocksList:openReactionMenu')}
        <Icon name='farChevronRight' color='secondary' />
      </Button>
    );
  }
);
