import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import {
  Button,
  FormGroup,
  Label,
  Popover,
  Spinner,
  TextAreaLikeElement,
  usePromiseProcessing,
  useToggle,
} from '@just-ai/just-ui';

import { t } from 'localization';

import { useAudioService } from 'services/AudioService/hook';
import { useAppContext } from 'modules/Caila/components/AppContext';
import { TextToSpeechProviderDetail, TtsRequestDTO } from 'modules/TestTtsWidget/api/client';

import TtsOperatorsInfo from './TtsOperatorsInfo';

import styles from './styles.module.scss';

import { isProviderHaveDocContent } from './TtsOperatorsInfo/providers';
import { first } from 'lodash';
import { AxiosError } from 'axios';

interface BotSayTabProps {
  onChange: (value: string) => void;
  value: string;
}
const BotSayTab = (props: BotSayTabProps) => {
  const [ttsHintOpened, , closeTtsHint, toggleTtsHint] = useToggle(false);
  const { TextToSpeechService, currentProject } = useAppContext();
  const [audioInstance, { isPlayed }] = useAudioService();
  const [errorText, setErrorText] = useState('');

  const currentProjectLanguage = currentProject?.language || 'en';
  const [defaultProvidersState, getDefaultProviders] = usePromiseProcessing(
    async () => {
      const res = await TextToSpeechService.getDefaultProvidersForAccount(currentProjectLanguage);
      return res.data.ttsProvider;
    },
    {
      deps: [currentProjectLanguage],
      onError: () => {
        setErrorText(t('AnswerSettings:BotSay:TtsAdapterError:Unavailable'));
      },
    }
  );
  const defaultProvider = defaultProvidersState.result;

  useEffect(() => {
    getDefaultProviders();
  }, [getDefaultProviders]);

  const [synthesizeVoiceState, synthesizeVoice] = usePromiseProcessing(
    async text => {
      if (!defaultProvidersState.result) return;
      text = text.trim();
      setErrorText('');
      if (isPlayed) {
        audioInstance.clear();
        return;
      }
      if (!text) {
        setErrorText(t('AnswerSettings:BotSay:SynthesizeError:Empty'));
        return;
      }
      audioInstance.clear();

      const defaultProviderKey = defaultProvider as unknown as TextToSpeechProviderDetail;
      const voice = first(TextToSpeechService.getVoicesForProvider(defaultProviderKey, currentProjectLanguage)) || null;
      const ttsRequest: TtsRequestDTO = {
        speech: { text },
        ttsConfig: {
          provider: defaultProviderKey,
          voiceName: voice,
          projectLanguage: currentProjectLanguage,
        },
      };

      try {
        const { data } = await TextToSpeechService.synthesize(ttsRequest);
        const audioKey = data.resultDetail?.key;
        if (!audioKey) return;
        const { data: dataAudio } = await TextToSpeechService.downloadAudio(audioKey);
        const audioString = dataAudio.audio as unknown as string;
        const audioSrc = `data:audio/wav;base64,${audioString}`;
        return audioInstance.play(audioSrc);
      } catch (e) {
        if (!(e instanceof AxiosError)) {
          setErrorText(t('AnswerSettings:BotSay:SynthesizeError:Common'));
          return;
        }
        const isRateLimit = e.response?.status === 429;
        const errorMassage = e.response?.data?.message || '';
        setErrorText(isRateLimit ? t('AnswerSettings:BotSay:SynthesizeError:RateLimit') : errorMassage);
      }
    },
    { deps: [isPlayed, defaultProvider] }
  );

  return (
    <>
      <FormGroup tag='div' className={styles.BotSayTab}>
        {defaultProvider && isProviderHaveDocContent(defaultProvider) && (
          <div className={cn(styles.hint, 'margin-bottom-3x')}>
            {t('AnswerSettings:Tabs:Say:Hint')}{' '}
            <span onClick={toggleTtsHint} className={styles.hint__link}>
              {t('AnswerSettings:Tabs:Say:HintPart2')}
            </span>
          </div>
        )}

        {defaultProvidersState.loading ? (
          <Spinner />
        ) : (
          <div className='d-flex flex-column'>
            <Label for='sayAnswerToEditText'>{t('AnswerSettings:BotSay')}</Label>
            <div className={styles.sayField}>
              <TextAreaLikeElement
                id='sayAnswerToEditText'
                onChange={props.onChange}
                placeholder={t('AnswerSettings:BotSay:Placeholder')}
                data-test-id='AnswerSettings:BotSay:Text'
                maxRows={6}
                minRows={3}
                autoFocus
                value={props.value}
              />
              <div
                className={cn(styles.playWrapper, {
                  [styles.playWrapper_isPlayed]: isPlayed,
                })}
              >
                {synthesizeVoiceState.loading ? (
                  <Spinner size='lg' style={{ margin: 8 }} inline />
                ) : (
                  <Button
                    onClick={() => synthesizeVoice(props.value)}
                    color='primary'
                    flat
                    disabled={!!defaultProvidersState.error}
                    iconLeft={isPlayed ? 'farStop' : 'farPlay'}
                  >
                    {t('AnswerSettings:BotSay:Play')}
                  </Button>
                )}
              </div>
            </div>
            {errorText && <div className={styles.error}>{errorText}</div>}
          </div>
        )}
      </FormGroup>
      {defaultProvider && (
        <Popover
          isOpen={ttsHintOpened}
          target='jgraph-right-side-menu'
          placement='left-start'
          title={t('TtsOperatorsInfo:Title')}
          onCancelClick={closeTtsHint}
          className={styles.ttsHintWrapper}
        >
          <TtsOperatorsInfo provider={defaultProvider} />
        </Popover>
      )}
    </>
  );
};

BotSayTab.displayName = 'BotSayTab';

export default React.memo(BotSayTab);
