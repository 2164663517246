import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button } from "../Button";
import { translate } from "./localization";
import { startOfISOWeek, startOfDay, endOfDay, startOfMonth, endOfMonth, endOfISOWeek, addMonths, startOfWeek, endOfWeek } from "date-fns";
import './FastChoicePeriods.scss';
function FastChoicePeriods({ locale, setTemplateRange, maxDate, baseDate, minDate, singleDateMode }) {
    const date = new Date(baseDate);
    const updateRangeWithMaxCheck = useCallback(({ start, end }) => {
        if (maxDate && end > maxDate)
            end = new Date(maxDate);
        setTemplateRange(start, end);
    }, [setTemplateRange, maxDate]);
    const setThisDay = useCallback(() => {
        const start = startOfDay(date);
        let end = endOfDay(date);
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setThisWeek = useCallback(() => {
        const start = startOfDay(locale === 'en' ? startOfWeek(date) : startOfISOWeek(date));
        let end = endOfDay(locale === 'en' ? endOfWeek(date) : endOfISOWeek(date));
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setThisMonth = useCallback(() => {
        const start = startOfDay(startOfMonth(date));
        let end = endOfDay(endOfMonth(date));
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setPreviousMonth = useCallback(() => {
        const lastMonthDate = addMonths(date, -1);
        const start = startOfDay(startOfMonth(lastMonthDate));
        let end = endOfDay(endOfMonth(lastMonthDate));
        setTemplateRange(start, end);
    }, [setTemplateRange]);
    return _jsx("div", { className: "rdrFastChoicePeriodsContainer", children: singleDateMode ? _jsx(Button, { size: "sm", color: "primary", flat: true, onClick: setThisDay, "data-test-id": 'DatePicker.FastChoice.ThisDay', children: translate('This day', locale) }) : _jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.ThisWeek', onClick: setThisWeek, children: translate('This week', locale) }), _jsx(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.ThisMonth', onClick: setThisMonth, children: translate('This month', locale) }), _jsx(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.LastMonth', onClick: setPreviousMonth, children: translate('Last month', locale) })] }) });
}
export default React.memo(FastChoicePeriods);
