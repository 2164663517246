import React from 'react';
import { AutopositionGroup } from './AutopositionGroup';
import { Rect, Text } from 'react-konva';
import { setCursorOnMouseEnterAndLeave } from '../../utils/stageUtils';

type AddReactionProps = {
  text: string;
  onClick?: (evt: any) => unknown;
};

function AddButton({ text, onClick }: AddReactionProps) {
  return (
    <AutopositionGroup
      xOffset={0}
      yOffset={8}
      onMouseEnter={setCursorOnMouseEnterAndLeave()}
      onMouseLeave={setCursorOnMouseEnterAndLeave()}
      onClick={onClick}
    >
      <Rect x={0} y={0} cornerRadius={8} width={256} strokeWidth={1} stroke='#E0E1E3' height={30} dash={[3, 3]} />
      <Text
        fontFamily='Roboto'
        fontSize={12}
        lineHeight={16 / 12}
        width={256}
        align='center'
        padding={8}
        letterSpacing={0.01 * 12}
        fill='#6A7178'
        text={text}
      />
    </AutopositionGroup>
  );
}

export default React.memo(AddButton);
