import React, { createContext, FC, useCallback, useContext, useMemo, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { AutopositionGroup } from './AutopositionGroup';
import { AutosizeRect } from './AutosizeRect';

import { EventButton } from './EventButton';

import {
  EditMenuBlock,
  FromStateTransition,
  JStateWithId,
  TConnector,
} from '../../../../reducers/JGraph.reducer/types';
import Konva from 'konva';
import { CollapseGroupTitle } from './ScreenMainCollapsibleGroup';
import { Group, Rect } from 'react-konva';
import { t } from 'localization';
import { ScreenContext } from 'modules/JGraph/hooks';
import { isActivationToStateByTagParam } from '../../utils/blockLayerUtils';
import { makeMapTagParametersFromBlock } from '../../utils/makeMapTagParametersFromBlock';

import { activationBlocks, TagNames } from 'modules/JGraph/utils/types';
import {
  getConnectionsFromBlocks,
  getValidKonvaName,
  getConnectorFrom,
} from '../../../../reducers/JGraph.reducer/Graph';
import { useFilterDeniedFromStateTransitions } from 'modules/JGraph/view/RightSideMenu/settings/ScreenBlocksList';
import AddButton from './AddReaction';
import { RightSideMenuExternalEventsPipe$ } from '../RightSideMenu/hooks/EventsHook';
import { tWithCheck } from '../../../../localization';
import { getStageContextFromRef } from 'modules/JGraph/utils/stageUtils';

type TEventsGroupProps = {
  screen: JStateWithId;
  fromStateTransition?: FromStateTransition[];
  editMenuBlock?: EditMenuBlock;
  onBlockClickHandler: (evt: any) => unknown;
  isEditModeEnable: boolean;
  isActiveByConnectorHighLight: boolean;
};

type JGEventsContextType = {
  fromStateTransition: FromStateTransition[];
};
const JGEventsContext = createContext({} as JGEventsContextType);
export const useJGEventsContext = () => useContext(JGEventsContext);

type TActivationBlock = {
  text: string;
  tagName: TagNames;
  connectionRef: TConnector;
  globalBlockIndex: number;
};

export const EventsGroup: FC<TEventsGroupProps> = ({
  screen,
  editMenuBlock,
  fromStateTransition = [],
  onBlockClickHandler,
  isEditModeEnable,
  isActiveByConnectorHighLight,
}) => {
  const StateRef = useRef<Konva.Group | null>(null);
  const { jGraphContext } = getStageContextFromRef(StateRef);
  const currentFromStateTransition = useFilterDeniedFromStateTransitions(
    fromStateTransition,
    jGraphContext?.isJGraphLite
  ) as FromStateTransition[];
  const [collapsed, setCollapsed] = useState(() => false);
  const { pathId } = screen;
  const changeBlockIdOnClick = useCallback((event: Konva.KonvaEventObject<MouseEvent>) => {
    event.cancelBubble = true;
    setCollapsed(prevCollapsed => !prevCollapsed);
  }, []);

  const onlyActivations = useMemo(() => {
    let allConnections = getConnectionsFromBlocks([screen]);
    return screen.blocks?.reduce((currentList, block, screenJBlockIterator) => {
      if (activationBlocks.includes(block.tagName)) {
        const found = allConnections.connections.find(connector =>
          connector.from.includes(`${screenJBlockIterator}__${screen.pathId}__${getValidKonvaName(block.tagName)}`)
        );
        const tagParamsMap = makeMapTagParametersFromBlock(block);
        if (isActivationToStateByTagParam(tagParamsMap)) {
          currentList.push({
            tagName: block.tagName,
            text: tWithCheck(`EventsGroup:onlyActivations:${block.tagValue}`) || block.tagValue || '',
            connectionRef: found || {
              from: getConnectorFrom(screenJBlockIterator, screen.pathId, block.tagName),
              to: getValidKonvaName(tagParamsMap.toState.value || ''),
              fromNode: screen.pathId,
              deferred: false,
              uuid: uuid(),
              fallback: `${screen.pathId}_events`,

              value: block.tagValue || '',
              fromNodeOriginalPath: screen.path,
              toNodeOriginalPath: tagParamsMap.toState.value,
              tagName: block.tagName,
            },
            globalBlockIndex: screenJBlockIterator,
          });
        }
      }
      return currentList;
    }, [] as TActivationBlock[]);
  }, [screen]);

  const onBlockClickHandlerEvent = useCallback(
    evt => {
      onBlockClickHandler(evt);
      window.requestAnimationFrame(() => {
        RightSideMenuExternalEventsPipe$.next({
          setAddingMenuType: {
            menuType: 'toStateEvents',
            activationsMenuType: 'intent',
          },
        });
      });
    },
    [onBlockClickHandler]
  );

  return (
    <ScreenContext.Provider value={{ screen, editMenuBlock, isEditModeEnable }}>
      <AutopositionGroup xOffset={0} yOffset={0} name='EventsGroup' ref={StateRef}>
        <AutosizeRect
          fill='white'
          cornerRadius={screen.states && screen.states.length > 0 ? [0, 0, 0, 0] : [0, 0, 8, 8]}
          bottomPadding={8}
          active={
            (editMenuBlock?.path === undefined &&
              editMenuBlock?.jBlockIndex === undefined &&
              editMenuBlock?.screen.pathId === pathId) ||
            isActiveByConnectorHighLight
          }
          debugActive={screen.debugActive}
          name='EventsGroupAutosizeRect'
        >
          <AutopositionGroup>
            <Group y={4}>
              <JGEventsContext.Provider value={{ fromStateTransition: currentFromStateTransition }}>
                <CollapseGroupTitle
                  onClick={
                    currentFromStateTransition.length > 0 || onlyActivations?.length > 0
                      ? changeBlockIdOnClick
                      : undefined
                  }
                  collapsed={collapsed}
                  groupTitle={t('EventsGroup:title', currentFromStateTransition.length + onlyActivations?.length)}
                  name={`${pathId}_events`}
                />
              </JGEventsContext.Provider>
            </Group>
            {(currentFromStateTransition.length > 0 || onlyActivations?.length > 0) && (
              <Rect width={23} height={30} x={224 + 8} y={0} />
            )}

            {/*<KonvaButton
            x={224 + 8}
            y={0}
            onClick={event => {
              event.cancelBubble = true;
              getStageActionsFromEvent(event).toggleAddingActionsMenu(event);
            }}
            type='reactions'
          />*/}
          </AutopositionGroup>
          {!collapsed && (currentFromStateTransition.length > 0 || onlyActivations.length > 0) && (
            <AutopositionGroup xOffset={0} yOffset={6}>
              {onlyActivations.map((toState, index) => (
                <EventButton
                  screen={screen}
                  key={toState.connectionRef.from}
                  type={toState.tagName}
                  text={toState.text}
                  name={toState.connectionRef.from}
                  transitionTo={toState.connectionRef.toNodeOriginalPath}
                  hasTransition={toState.connectionRef.toNodeOriginalPath !== './'}
                  bottomCornerRadius={index === currentFromStateTransition.length + onlyActivations.length - 1}
                  isToState={true}
                  active={
                    editMenuBlock?.path === undefined &&
                    editMenuBlock?.jBlockIndex === toState.globalBlockIndex &&
                    screen.pathId === editMenuBlock?.screen.pathId
                  }
                  index={toState.globalBlockIndex}
                />
              ))}
              {currentFromStateTransition?.map((fromState, index) => (
                <EventButton
                  screen={screen}
                  key={`${fromState.connectionRef.from}${onlyActivations.length}`}
                  type={fromState.tagName}
                  text={fromState.text}
                  name={fromState.connectionRef.from}
                  transitionTo={fromState.connectionRef.toNodeOriginalPath}
                  hasTransition={true}
                  index={index}
                  bottomCornerRadius={
                    onlyActivations?.length + index === currentFromStateTransition.length + onlyActivations.length - 1
                  }
                />
              ))}
            </AutopositionGroup>
          )}
          <AutopositionGroup xOffset={12} yOffset={0}>
            <AddButton text={t('JGraphActivations:AddPhrase')} onClick={onBlockClickHandlerEvent} />
          </AutopositionGroup>
        </AutosizeRect>
      </AutopositionGroup>
    </ScreenContext.Provider>
  );
};
