import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import cn from 'classnames';
import VirtualList from 'react-tiny-virtual-list';
import { Icon, InputText } from '@just-ai/just-ui';

import { useAppSelector, useAppDispatch } from 'storeHooks';
import { t, tWithPlural } from 'localization';
import { amplitudeInstance } from 'pipes/functions';
import { getUserLanguage } from 'pipes/pureFunctions';

import { useLocalStorageState } from 'utils/hooks/useLocalStorageState';

import { setEditMenuBlock } from 'reducers/JGraph.reducer';
import { findScreenByPathId, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { CustomTagsStore$ } from 'reducers/JGraph.reducer/customTags.store';
import {
  SearchResult,
  JGSearch,
  SearchResultInfo,
  SearchContentLocationType,
  SearchGroupType,
} from 'modules/JGraph/services/JGSearch';

import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { objectSerializer } from 'services/Storage/LocalStorageService';
import { GlobalScrollToTarget } from 'modules/JGraph/contexts/types';

import { FloatingConnectorMenu$ } from '../FloatingConnectorMenu';
import { useGlobalSearchVisibility, useSearchRowKeyboardNavigation } from './hooks';
import SearchFilter from './SearchFilter';
import { SearchResultRow } from './SearchResultRow';

import styles from './style.module.scss';

export type SearchFilterState = {
  searchText: string;
  filters: string[];
};

const locationTypeToScrollType: Record<SearchContentLocationType, GlobalScrollToTarget['type']> = {
  [SearchContentLocationType.STATE]: 'state',
  [SearchContentLocationType.THEME]: 'theme',
  [SearchContentLocationType.STICKER]: 'sticker',
};

export const JGSearchBtn = React.memo(() => {
  const { screens, themes, stickers, currentProject, currentUser } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
    stickers: state.JGraphReducer.stickers,
    currentProject: state.CurrentProjectsReducer.currentProject,
    currentUser: state.CurrentUserReducer.currentUser,
  }));

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { openSearch, setCloseSearch } = useGlobalSearchVisibility();
  const [searchFilterState, setSearchFilterState] = useLocalStorageState<SearchFilterState>(
    { searchText: '', filters: [] },
    `JGGlobalSearch:${currentProject}:${currentUser?.account?.id}`,
    true,
    objectSerializer
  );
  const [searchResult, setSearchResult] = useState<SearchResultInfo>();

  useEffect(() => {
    if (inputRef.current && openSearch) {
      inputRef.current.focus();
      amplitudeInstance.logEvent('J-Graph Search Activated');
    }
  }, [openSearch]);

  useEffect(() => {
    if (!openSearch) return;
    JGSearch.instance.buildSearchIndex(themes, screens, stickers, CustomTagsStore$.getValue(), getUserLanguage());
  }, [openSearch, themes, screens, stickers]);

  useEffect(() => {
    setSearchResult(JGSearch.instance.search(searchFilterState.searchText, searchFilterState.filters));
    return JGSearch.instance.subscribe('indexChanged', () => {
      setSearchResult(JGSearch.instance.search(searchFilterState.searchText, searchFilterState.filters));
    });
  }, [searchFilterState]);

  const dispatch = useAppDispatch();
  const handleChange = useCallback(
    (searchResult: SearchResult) => {
      amplitudeInstance.logEvent('J-Graph Search Go To Result', {
        searchText: searchFilterState.searchText,
        'Search Filter': searchFilterState.filters,
      });
      FloatingConnectorMenu$.next({ connector: undefined });
      setCloseSearch();

      const locationTypeToScrollTypeElement = locationTypeToScrollType[searchResult.location.type];
      switch (searchResult.location.type) {
        case SearchContentLocationType.STATE:
          const shouldOpenStateEdit = searchResult.groupType !== SearchGroupType.LABEL;

          scrollToTargetGlobal$.next({
            targetPathId: searchResult.location.id,
            type: locationTypeToScrollTypeElement,
          });
          if (shouldOpenStateEdit) {
            const targetState = findScreenByPathId(searchResult.location.id, screens);
            dispatch(setEditMenuBlock({ screen: targetState, jBlockIndex: undefined, path: undefined }));
          }
          break;
        case SearchContentLocationType.THEME:
          scrollToTargetGlobal$.next({
            targetPathId: searchResult.location.id,
            type: locationTypeToScrollTypeElement,
          });
          break;
        case SearchContentLocationType.STICKER:
          scrollToTargetGlobal$.next({
            targetPathId: searchResult.location.id,
            parentPathId: getValidKonvaName(searchResult.location.path),
            type: locationTypeToScrollTypeElement,
          });
          break;
      }
    },
    [searchFilterState, setCloseSearch, screens, dispatch]
  );

  const allMatches = useMemo(() => {
    return searchResult?.results.flatMap(el => el.matches.map(match => ({ ...el, matches: [match] })));
  }, [searchResult]);

  const handleKeyDown = useSearchRowKeyboardNavigation(focusedIndex => {
    if (!allMatches) return;
    const selectedItem = allMatches[focusedIndex];
    handleChange(selectedItem);
  });

  if (!openSearch) return null;

  const itemHeight = 56;
  return (
    <>
      <div className={styles.backDrop} onClick={setCloseSearch} />
      <div className={cn(styles.searchState)} data-test-id='JGraph.GlobalSearch'>
        <div className={styles.searchContainer} onKeyDown={handleKeyDown}>
          <SearchFilter.Provider
            filters={searchFilterState.filters}
            onChange={filters => setSearchFilterState({ ...searchFilterState, filters })}
          >
            <div className={styles.filterHeader}>
              <div className={styles.inputContainer}>
                <Icon wrapperClassName={styles.searchIcon} name='farSearch' color='secondary' />
                <InputText
                  compact
                  innerRef={inputRef}
                  className={styles.searchInput}
                  data-test-id='JGraph.GlobalSearch.Input'
                  value={searchFilterState.searchText || ''}
                  onChange={text => setSearchFilterState({ ...searchFilterState, searchText: text })}
                />
                <SearchFilter />
              </div>
              <SearchFilter.ChipsView />
            </div>
          </SearchFilter.Provider>

          {searchResult && allMatches && allMatches.length > 0 && (
            <>
              <div className={styles.searchInfo}>
                <span>
                  {t('JGGlobalSearch:SearchHint', {
                    matches: tWithPlural('JGGlobalSearch:SearchHint:Matches', searchResult.matches),
                    elements: tWithPlural('JGGlobalSearch:SearchHint:Elements', searchResult.results.length),
                  })}
                </span>
              </div>
              <VirtualList
                className={styles.searchList}
                itemCount={allMatches.length}
                height={Math.min(allMatches.length * itemHeight, 300)}
                itemSize={itemHeight}
                width='100%'
                renderItem={itemInfo => {
                  const el = allMatches[itemInfo.index];
                  return (
                    <SearchResultRow
                      key={el.content + itemInfo.index + el.type}
                      data-test-id={`JGraph.GlobalSearch.Result:${el.type}:${el.location.id}`}
                      data-search-row-index={itemInfo.index}
                      style={itemInfo.style}
                      searchResult={el}
                      onClick={() => handleChange(el)}
                    />
                  );
                }}
              />
            </>
          )}
          {searchFilterState.searchText && allMatches && allMatches.length === 0 && (
            <div className={styles.noResults}>{t('JGGlobalSearch:NoResults')}</div>
          )}
        </div>
      </div>
    </>
  );
});
