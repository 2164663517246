type WaitElementOptions = {
  timeout: number;
  checkInterval: number;
};

const defaultProps: WaitElementOptions = {
  timeout: 10000,
  checkInterval: 1000,
};

export function getElementWithRetries(selector: string, options?: WaitElementOptions) {
  const startTime = Date.now();
  const opts = Object.assign(defaultProps, options);

  let element;
  return new Promise<Element>((resolve, reject) => {
    const tryMakeQuery = () => {
      element = document.querySelector(selector);
      if (element) {
        resolve(element);
        return;
      }
      const nowTime = Date.now();

      if (nowTime - startTime > opts.timeout) {
        reject('timeout');
      }
      setTimeout(() => {
        tryMakeQuery();
      }, opts.checkInterval);
    };
    tryMakeQuery();
  });
}
