import Konva from 'konva';
import React, { FC, useEffect, useRef } from 'react';
import { Path } from 'react-konva';
import { newConnectorPipe$ } from '../hooks';
import { getConnectorPath } from '../utils/connectionLayerUtils';

export const NewConnector: FC = () => {
  const newConnectorRef = useRef<Konva.Path>(null);
  useEffect(() => {
    const sub = newConnectorPipe$.subscribe(storeValue => {
      if (newConnectorRef.current) {
        if (storeValue.from && storeValue.toPosition) {
          newConnectorRef.current.data(getConnectorPath(storeValue.fromPosition, storeValue.toPosition, false));
        } else {
          newConnectorRef.current.data('');
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <Path
      ref={newConnectorRef}
      // data='M10 280 h 50 q 11 0 11 -11 v -100 q 0 -11 11 -11 h 30'
      strokeWidth={3}
      name='new_connector'
      stroke='red'
    />
  );
};
