import React, { memo } from 'react';
import { Line } from 'react-konva';

import { border_active, gray_400, STATIC_TOP_OFFSET } from './index';

function OutgoingCurvedLine(props: { baseX: number; topIncomingOffset: number; debugActive?: boolean }) {
  return (
    <>
      <Line
        points={[props.baseX, 0, props.baseX - 14, 0]}
        strokeWidth={2}
        stroke={props.debugActive ? border_active : gray_400}
      />
      <Line
        points={[props.baseX - 14, 0, props.baseX - 14, -32]}
        strokeWidth={2}
        stroke={props.debugActive ? border_active : gray_400}
      />
      {props.topIncomingOffset === STATIC_TOP_OFFSET && (
        <>
          <Line
            points={[props.baseX - 14, -32, props.baseX - 64, -32]}
            strokeWidth={2}
            stroke={props.debugActive ? border_active : gray_400}
          />
        </>
      )}
    </>
  );
}

export const MemoOutgoingCurvedLine = memo(OutgoingCurvedLine);
