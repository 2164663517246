import {
  FEETCH_DEPLOY_HISTORY,
  FEETCH_CHANNEL_DATA,
  SET_CURRENT_BUILD,
  DEPLOY_HISTORY_PAGINATE,
  REFETCH_DEPLOY_HISTORY,
} from '../../constants/deployHistory.actions';
import { axios } from '../../pipes/functions';
import defaultAxios from 'axios';
const CancelToken = defaultAxios.CancelToken;
let CancelPoolingRequest = () => {};

const PAGE_SIZE = 10;

export const loadDeployHistory = (channelId, pageSize = PAGE_SIZE) => {
  return {
    type: FEETCH_DEPLOY_HISTORY,
    payload: axios.get(`/restapi/botconfig/${channelId}/history?size=${pageSize}&page=0`),
  };
};

export const refetchDeployHistory = (channelId, obj) => {
  const source = CancelToken.source();
  CancelPoolingRequest = source.cancel;

  return {
    type: REFETCH_DEPLOY_HISTORY,
    payload: axios.get(`/restapi/botconfig/${channelId}/history`, {
      params: obj,
      cancelToken: source.token,
    }),
  };
};

export const fetchChannelData = channelId => {
  return {
    type: FEETCH_CHANNEL_DATA,
    payload: axios.get(`/restapi/botconfig/${channelId}`),
  };
};

export const setCurrentBuild = buildId => {
  return {
    type: SET_CURRENT_BUILD,
    buildId: buildId,
  };
};

export const paginate = (channelId, pageSize, pageNumber) => {
  CancelPoolingRequest();
  return {
    type: DEPLOY_HISTORY_PAGINATE,
    payload: axios.get(`/restapi/botconfig/${channelId}/history`, {
      params: {
        size: pageSize,
        page: pageNumber,
      },
    }),
  };
};
