import { Observable, timer } from 'rxjs';

type Config<SOURCE> = { passAnyway: (event: SOURCE) => boolean };

export default function waitTime<SOURCE>(getWaitTime: (event: SOURCE) => number | undefined, config?: Config<SOURCE>) {
  let pause = false;
  let lastSkippedEvent: SOURCE | undefined;
  return (source: Observable<SOURCE>) => {
    return new Observable<SOURCE>(subscriber => {
      const subscription = source.subscribe(value => {
        if (config?.passAnyway && config.passAnyway(value)) {
          subscriber.next(value);
          lastSkippedEvent = undefined;
          return;
        }
        if (pause) {
          lastSkippedEvent = value;
          return;
        }
        subscriber.next(value);
        const timeForWaiting = getWaitTime(value);
        if (timeForWaiting) {
          pause = true;
          timer(timeForWaiting).subscribe(() => {
            pause = false;
            subscriber.next(lastSkippedEvent);
            lastSkippedEvent = undefined;
          });
        }
      });
      return () => subscription.unsubscribe();
    });
  };
}
