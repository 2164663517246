export const analyticLocalization = {
    'Analytic: session audio record': {
        eng: 'Call recording',
        ru: 'Запись диалога',
        pt: 'Gravação de chamada',
        cn: 'Call recording',
    },
    'Analytic:Experiments head label': {
        eng: 'A/B testing',
        ru: 'А/В-тестирование',
        pt: 'Teste A/B',
        cn: 'A/B testing',
    },
    'Analytic:Experiments show finished experiments': {
        eng: 'Show finished experiments',
        ru: 'Показать завершенные эксперименты',
        pt: 'Mostrar experimentos finalizados',
        cn: 'Show finished experiments',
    },
    'Analytic:Experiments add modal button': {
        eng: 'Create experiment',
        ru: 'Создать эксперимент',
        pt: 'Criar experimento',
        cn: 'Create experiment',
    },
    'Analytic:Experiments code head': {
        eng: 'Code',
        ru: 'Код',
        pt: 'Código',
        cn: 'Code',
    },
    'Analytic:Experiments sessions head': {
        eng: 'Sessions',
        ru: 'Сессий',
        pt: 'Sessões',
        cn: 'Sessions',
    },
    'Analytic:Experiments branches head': {
        eng: 'Branches',
        ru: 'Ветви',
        pt: 'Ramificações',
        cn: 'Branches',
    },
    'Analytic:Experiments status head': {
        eng: 'Status',
        ru: 'Статус',
        pt: 'Status',
        cn: 'Status',
    },
    'Analytic:Experiments finish button': {
        eng: 'Finish',
        ru: 'Завершить',
        pt: 'Finalizar',
        cn: 'Finish',
    },
    'Analytic:Experiments finish experiment modal title': {
        eng: 'Finish experiment',
        ru: 'Завершить эксперимент',
        pt: 'Finalizar experimento',
        cn: 'Finish experiment',
    },
    'Analytic:Experiments finish experiment modal description': {
        eng: 'Are you sure you want to finish ',
        ru: 'Вы действительно хотите завершить ',
        pt: 'Tem certeza de quer finalizar ',
        cn: 'Are you sure you want to finish ',
    },
    'Analytic:Experiments new experiment modal title': {
        eng: 'New experiment',
        ru: 'Новый эксперимент',
        pt: 'Novo experimento',
        cn: 'New experiment',
    },
    'Analytic:Experiments new experiment modal create button': {
        eng: 'Create experiment',
        ru: 'Создать эксперимент',
        pt: 'Criar experimento',
        cn: 'Create experiment',
    },
    'Analytic:Experiments modal code placeholder': {
        eng: 'Code',
        ru: 'Код',
        pt: 'Código',
        cn: 'Code',
    },
    'Analytic:Experiments modal description placeholder': {
        eng: 'Experiment description',
        ru: 'Описание эксперимента',
        pt: 'Descrição do experimento',
        cn: 'Experiment description',
    },
    'Analytic:Experiments modal main label': {
        eng: 'Use the experiment code in the script. Learn more in the $[1]',
        ru: 'Код эксперимента используется в сценарии. Подробности использования в $[1]',
        pt: 'Use o código do experimento no script. Saiba mais no $[1]',
        cn: 'Use the experiment code in the script. Learn more in the $[1]',
    },
    'Analytic:Experiments modal main label documentation link label': {
        eng: 'documentation',
        ru: 'документации',
        pt: 'documentação',
        cn: 'documentation',
    },
    'Analytic:Experiments modal main label documentation link': {
        eng: '/platform_ux/experiments',
        ru: '/platform_ux/experiments',
        pt: '/platform_ux/experiments',
        cn: '/platform_ux/experiments',
    },
    'Analytic:Experiments modal branches labels': {
        eng: 'Add two to five experiment branches',
        ru: 'Добавьте от двух до пяти ветвей эксперимента',
        pt: 'Adicione de duas a cinco ramificações de experimento',
        cn: 'Add two to five experiment branches',
    },
    'Analytic:Experiments modal branch placeholder': {
        eng: 'Branch $[1] description',
        ru: 'Описание ветви $[1]',
        pt: 'Descrição da ramificação $[1]',
        cn: 'Branch $[1] description',
    },
    'Analytic:Experiments modal new branch button': {
        eng: 'Add branch',
        ru: 'Добавить ветвь',
        pt: 'Adicionar ramificação',
        cn: 'Add branch',
    },
    'Analytic:Experiments status STOPPED': {
        eng: 'finished',
        ru: 'завершен',
        pt: 'finalizado',
        cn: 'finished',
    },
    'Analytic:Experiments status RUNNING': {
        eng: 'active',
        ru: 'активен',
        pt: 'ativo',
        cn: 'active',
    },
    'Analytic:Experiments:error reporter.common.duplicate_resource': {
        eng: 'Experiment code must be unique',
        ru: 'Код эксперимента должен быть уникальным',
        pt: 'O código do experimento deve ser único',
        cn: 'Experiment code must be unique',
    },
    'Analytic: previous session': {
        eng: 'Previous session',
        ru: 'Предыдущая сессия',
        pt: 'Sessão anterior',
        cn: 'Previous session',
    },
    'Analytic: next session': {
        eng: 'Next session',
        ru: 'Следующая сессия',
        pt: 'Próxima sessão',
        cn: 'Next session',
    },
    'Analytic: current session position': {
        eng: '$[1] out of $[2]',
        ru: '$[1] из $[2]',
        pt: '$[1] de $[2]',
        cn: '$[1] out of $[2]',
    },
};
