const prefix = '@register/';

export const REGISTER = prefix + 'REGISTER';
export const REGISTER_PENDING = prefix + 'REGISTER_PENDING';
export const REGISTER_FULFILLED = prefix + 'REGISTER_FULFILLED';
export const REGISTER_REJECTED = prefix + 'REGISTER_REJECTED';
export const CLEAN_REGISTER_ERRORS = prefix + 'CLEAN_REGISTER_ERRORS';

export const REMOVE_REGISTER_ALERT = prefix + 'REMOVE_REGISTER_ALERT';

export const GET_PASSWORD_LENGTH = prefix + 'GET_PASSWORD_LENGTH';
export const GET_PASSWORD_LENGTH_PENDING = prefix + 'GET_PASSWORD_LENGTH_PENDING';
export const GET_PASSWORD_LENGTH_FULFILLED = prefix + 'GET_PASSWORD_LENGTH_FULFILLED';
export const GET_PASSWORD_LENGTH_REJECTED = prefix + 'GET_PASSWORD_LENGTH_REJECTED';

export const RESEND_EMAIL = prefix + 'RESEND_EMAIL';
