export const blockmodalLocalization = {
  'BlockModal:title': {
    eng: 'The full functionality of J-Graph is not included in your license key.',
    ru: 'Полная функциональность J-Graph не включена в лицензионный ключ.',
  },
  'BlockModal:text': {
    eng: 'Please contact your account manager to upgrade your license.',
    ru: 'Пожалуйста, обратитесь к вашему аккаунт-менеджеру для расширения текущей лицензии.',
  },
};
