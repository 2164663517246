import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Portal from 'components/Portal';

import {
  checkNodeIsSelected,
  generateId,
  getUsedNodes,
  saveEditorSelection,
  restoreEditorSelection,
  replaceSelectedText,
  dropSelections,
} from './utils';
import { WysiwygControls } from './WysiwygControls';
import { UrlEditor } from './UrlEditor';
import { PseudoSelection } from './PseudoSelection';
import './Wysiwyg.scss';

export interface WysiwygEditorProps {
  maxLength?: number;
  defaultText?: string;
  label?: string;
  className?: string;
  invalid?: boolean;
  onChange?: (value: string) => unknown;
  inputRef?: (inputRef: HTMLDivElement | null) => unknown;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => unknown;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => unknown;
  onFocus?: (event: React.SyntheticEvent<HTMLDivElement>) => unknown;
  autoFocus?: boolean;
  triggerChangeOnControls?: boolean;
  showControls?: boolean;
  editDisabled?: boolean;
}

export type PositionType = {
  left: number;
  top: number;
  height: number;
  width: number;
};

export const WysiwygEditor = React.memo(
  React.forwardRef<HTMLDivElement, WysiwygEditorProps>(
    (
      {
        defaultText = '',
        label,
        className = '',
        onChange,
        inputRef,
        maxLength,
        onKeyDown,
        onBlur = () => {},
        autoFocus,
        showControls = true,
        triggerChangeOnControls,
        onFocus: onFocusProp,
        editDisabled,
        invalid,
      },
      ref
    ) => {
      const htmlText = useRef<string>(defaultText);
      const editor = useRef<HTMLDivElement>(null);
      const editorContainerRef = useRef<HTMLDivElement>(null);
      const justSavedLink = useRef<boolean>(false);
      const id = useRef<string>(generateId());
      const position = useRef<PositionType>({
        left: 0,
        top: 0,
        height: 0,
        width: 0,
      });
      const textInUrl = useRef<string>('');
      const urlText = useRef<string>('');
      const savedRanges = useRef<Range[]>([]);

      const [selectedNodes, setSelected] = useState<string[]>([]);
      const [showUrlEditor, setUrlEditor] = useState<boolean>(false);

      const onChangeHandler = useCallback(() => {
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
          setSelected(usedNodes);
        }
        const nodes = editor.current?.querySelectorAll('a');
        if (nodes) {
          const arrayNodes = Array.from(nodes);
          arrayNodes.forEach(nodeA => {
            if (!nodeA.target) {
              nodeA.target = '_blank';
            }
          });
        }
        onChange && editor.current && onChange(editor.current.innerHTML);
      }, [selectedNodes, onChange]);

      const makeElement = useCallback(
        (e: React.SyntheticEvent) => {
          const target = e.target as HTMLElement;
          const role = target.dataset?.role;
          if (editor.current && role) {
            let ranges = saveEditorSelection(editor.current?.parentElement!);
            if (!ranges || ranges.length === 0) return;
            switch (role) {
              case 'pre': {
                let range = ranges ? ranges[0] : null;

                if (range) {
                  let text = range.toString();
                  if (text) {
                    const isAlreadyPre = !!checkNodeIsSelected(selectedNodes, 'PRE');
                    range.deleteContents();
                    if (isAlreadyPre) {
                      const replacedRange = document.createRange();
                      let parent = range.commonAncestorContainer.parentNode;
                      while (parent && parent !== editor.current && parent.nodeName !== 'PRE') {
                        parent = parent.parentNode;
                      }
                      if (parent) {
                        replacedRange.selectNode(parent);
                        replaceSelectedText(replacedRange, text);
                      }
                    } else {
                      let pre = document.createElement('pre');
                      pre.appendChild(document.createTextNode(text));
                      range.insertNode(pre);
                    }
                  }
                }
                onChangeHandler();
                break;
              }
              case 'url': {
                if (ranges && editorContainerRef.current) {
                  textInUrl.current = ranges[0].toString();
                  urlText.current = '';

                  if (checkNodeIsSelected(selectedNodes, 'A')) {
                    let foundLink = ranges[0].commonAncestorContainer.parentNode;
                    while (foundLink && foundLink.nodeName !== 'A') {
                      foundLink = foundLink.parentNode;
                    }
                    if (foundLink) {
                      let range = document.createRange();
                      range.selectNode(foundLink);
                      ranges = [range];
                      urlText.current = (foundLink as HTMLLinkElement).href;
                      textInUrl.current = (foundLink as HTMLLinkElement).innerText;
                    }
                    if (!ranges) return;
                  }

                  savedRanges.current = ranges;
                  const caretPosition = ranges[0].getBoundingClientRect();
                  position.current = {
                    left: caretPosition.left,
                    top: caretPosition.top,
                    height: caretPosition.height,
                    width: caretPosition.width,
                  };
                  setUrlEditor(true);
                }
                break;
              }
              default: {
                document.execCommand(role, false);
                triggerChangeOnControls && onChangeHandler();
                break;
              }
            }
            const event = new Event('input');
            editor.current.dispatchEvent(event);
            editor.current.focus();

            const usedNodes = getUsedNodes(editor.current);

            if (usedNodes.join('') !== selectedNodes.join('')) {
              setSelected(usedNodes);
            }
          }
        },
        [onChangeHandler, selectedNodes, triggerChangeOnControls]
      );

      const checkClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        //READ this is needed because of action on url click. it just need to be function
      };

      const checkMouseKeys = () => {
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
          setSelected(usedNodes);
        }
      };
      const nodeIsSelected = useCallback(
        (nodeName: string) => {
          return checkNodeIsSelected(selectedNodes, nodeName);
        },
        [selectedNodes]
      );

      const checkKeys = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
          const usedNodes = getUsedNodes(editor.current);
          if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
          }

          if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            return;
          }

          if (justSavedLink.current) {
            justSavedLink.current = false;
            e.preventDefault();
            return false;
          }

          if (e.type === 'keydown') {
            onKeyDown && onKeyDown(e);
          }

          if (
            e.key === 'Enter' &&
            e.type === 'keydown' &&
            !(nodeIsSelected('UL') || nodeIsSelected('PRE') || nodeIsSelected('OL'))
          ) {
            e.preventDefault();
            document.execCommand('insertHTML', false, '<br/><br/>');
            return false;
          }
          if (e.key === 'Enter' && e.type === 'keydown' && !e.shiftKey && nodeIsSelected('PRE')) {
            e.preventDefault();
            document.execCommand('insertHTML', false, '<br/>');
            return false;
          }
        },
        [selectedNodes, nodeIsSelected, onKeyDown]
      );

      const saveUrl = useCallback(
        (link, text) => {
          if (savedRanges.current) {
            setUrlEditor(false);
            const replacedSelection = replaceSelectedText(savedRanges.current[0], text);
            restoreEditorSelection([replacedSelection]);
            justSavedLink.current = true;

            if (link) {
              let linkToSave = link.startsWith('http://') || link.startsWith('https://') ? link : '//' + link;
              document.execCommand('CreateLink', false, linkToSave);
            }
            dropSelections([replacedSelection]);
            onChangeHandler();
          }
        },
        [onChangeHandler]
      );

      const onFocus = useCallback(
        event => {
          const usedNodes = getUsedNodes(editor.current);
          if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
          }
          onFocusProp && onFocusProp(event);
        },
        [selectedNodes, onFocusProp]
      );

      const pasteHandler = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const clip = event.clipboardData.getData('text/plain').replace(/\n/g, '<br/>');
        let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        let urlq = clip.match(urlRegex);
        if (urlq) {
          document.execCommand('CreateLink', false, clip);
        } else {
          document.execCommand('insertHTML', false, clip);
        }
      }, []);

      useEffect(() => {
        const editorScope = editor.current;

        editorScope?.addEventListener('input', onChangeHandler);

        return () => editorScope?.removeEventListener('input', onChangeHandler);
      }, [onChangeHandler]);

      useEffect(() => {
        if (!ref) return;
        //@ts-ignore
        ref.current = editor.current;
      }, [ref]);

      useLayoutEffect(() => {
        if (autoFocus) editor.current?.focus();
      }, [autoFocus]);

      const closeUrlEditor = useCallback(() => setUrlEditor(false), []);

      return (
        <div className={cn('editorContainer', className)} ref={editorContainerRef}>
          <div
            data-test-id='WysiwygEditor:contenteditable'
            className={cn('editor', { filled_editor: htmlText.current, invalid: invalid })}
            id={id.current}
            ref={editor}
            contentEditable={!editDisabled}
            dangerouslySetInnerHTML={{ __html: htmlText.current }}
            onKeyDown={checkKeys}
            onKeyUp={checkKeys}
            onMouseDown={checkMouseKeys}
            onMouseUp={checkMouseKeys}
            onFocus={onFocus}
            onClick={checkClick}
            onPaste={pasteHandler}
            onBlur={onBlur}
          />
          {label && (
            <label onClick={() => document?.getElementById(id.current)?.focus()} htmlFor={id.current}>
              {label}
            </label>
          )}
          {showControls && (
            <WysiwygControls checkNodeIsSelected={nodeIsSelected} makeElement={makeElement} disabled={editDisabled} />
          )}
          {showUrlEditor && (
            <Portal targetNodeSelector='body'>
              <UrlEditor
                text={textInUrl.current}
                url={urlText.current}
                position={position.current}
                close={closeUrlEditor}
                save={saveUrl}
              />
            </Portal>
          )}
          {showUrlEditor && <PseudoSelection position={position.current} />}
        </div>
      );
    }
  )
);
