import { useState, useCallback, useEffect } from 'react';

export type SetTrueCallback = () => void;
export type SetFalseCallback = () => void;
export type ToggleCallback = () => void;
export type SetValueCallback = (value: boolean) => void;
export const useToggle = (
  initialValue: boolean = false
): [boolean, SetTrueCallback, SetFalseCallback, ToggleCallback, SetValueCallback] => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(value => !value), []);
  return [value, setTrue, setFalse, toggle, setValue];
};

export type LoadCallback = <Tcallback>(promise: Promise<Tcallback>) => Promise<Tcallback>;
export const useLoading = (): [boolean, LoadCallback] => {
  const [isLoading, setLoading] = useState(false);

  const load: LoadCallback = useCallback(promise => {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);

  return [isLoading, load];
};

export type ModalControl = {
  isShown: boolean;
  show: SetTrueCallback;
  hide: SetFalseCallback;
  toggle: ToggleCallback;
};
export const useModal = (initialShown?: boolean) => {
  const [isShown, show, hide, toggle] = useToggle(initialShown);
  return { isShown, show, hide, toggle };
};
export const modalControlStub: ModalControl = Object.freeze({
  isShown: false,
  show: () => {},
  hide: () => {},
  toggle: () => {},
});

export const useClientRect = (): [ClientRect | null, (node: HTMLElement | null) => void] => {
  const [rect, setRect] = useState<ClientRect | null>(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};

export const useDebounce = <Tstate>(state: Tstate, delay?: number, callback?: (value: Tstate) => void) => {
  const [debouncedState, setDebouncedState] = useState(state);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedState(state);
      if (callback) {
        callback(state);
      }
    }, delay);
    return () => clearTimeout(handler);
  }, [state, delay, callback]);

  return debouncedState;
};

export const useStateWithDebouncedRemote = <Tvalue>(
  setRemoteState: (value: Tvalue) => void,
  remoteState: Tvalue,
  delay?: number
): [Tvalue, React.Dispatch<React.SetStateAction<Tvalue>>] => {
  const [state, setState] = useState(remoteState);
  useDebounce(state, delay, setRemoteState);
  useEffect(() => setState(remoteState), [remoteState]);
  return [state, setState];
};
