import React from 'react';
import cn from 'classnames';
import { Button } from '@just-ai/just-ui';

import StageLabel from '../../components/StageLabel';

import styles from './styles.module.scss';

type ColorMark = {
  mainColor: string;
  altBorderColor?: string;
};

type ColorsDropdownInterface<COLOR> = {
  colors: COLOR[];
  selected?: COLOR;
  className?: string;
  onSelect?: (item: COLOR) => void;
};
const ColorsDropdown = function <COLOR extends ColorMark>({
  selected,
  colors,
  className,
  onSelect,
}: ColorsDropdownInterface<COLOR>) {
  return (
    <div className={cn(styles.colorsDropdown, className)}>
      {colors.map(label => (
        <Button
          key={label.mainColor}
          className={cn(styles.button, {
            [styles.selectedOption]: selected && label.mainColor === selected.mainColor,
          })}
          data-test-id={`JGraph.ColorsDropdown.${label.mainColor}`}
          onClick={() => onSelect && onSelect(label)}
          withoutPadding
          size='sm'
          flat
          color='secondary'
        >
          <StageLabel theme={label} />
        </Button>
      ))}
    </div>
  );
};

export default React.memo(ColorsDropdown) as typeof ColorsDropdown;
