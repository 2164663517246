import React, { FC, useMemo } from 'react';
import { JustSelect, OptionType } from '@just-ai/just-ui';

import localization from 'localization';

import { withController } from './Controller';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';
import CustomTagWarningBlock from './CustomTagWarningBlock';

const SelectField: FC<CommonFieldProps> = ({
  name,
  label,
  options,
  invalid,
  isRequired,
  value,
  originalValue,
  onChange,
  description,
  type,
  isValidFormat,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  const selectValue = originalValue || value || '';
  const selectOptions = useMemo<OptionType[]>(() => {
    const optionsToSelect: OptionType[] = [];

    if (options) {
      optionsToSelect.push(
        ...options.map(el => ({
          value: el.value,
          label: el.localization?.[localization.getLocale()] || el.value,
        }))
      );
    }

    const isValueExistInOptions = optionsToSelect.find(el => el.value === selectValue);
    if (!isValueExistInOptions) {
      optionsToSelect.unshift({ value: selectValue, label: selectValue, disabled: true });
    }

    return optionsToSelect;
  }, [options, selectValue]);

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <JustSelect
        value={selectValue}
        options={selectOptions}
        onChange={el => onChange(el?.[0])}
        invalid={invalid}
        position='fixed'
      />
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const SelectCustomTagField = withController(SelectField);
