import React from 'react';

const iconToggle = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='svg-inline--fa fa-code fa-w-20 fa-sm justui_icon'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4ZM4.6 3.6C4.04772 3.6 3.6 4.04772 3.6 4.6V15.4C3.6 15.9523 4.04772 16.4 4.6 16.4H10V3.6H4.6Z'
        fill='var(--gray-300)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4ZM4.6 3.6C4.04772 3.6 3.6 4.04772 3.6 4.6V15.4C3.6 15.9523 4.04772 16.4 4.6 16.4H6.8V3.6H4.6Z'
        fill='var(--gray-300)'
      />
    </svg>
  );
};

export default iconToggle;
