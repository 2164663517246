import { SWComposer } from './SWComposer';

const twoMinutes = 1000 * 60 * 60 * 2;

class EditorFileCacheSW extends SWComposer {
  constructor(options) {
    super(options);

    this.setOnAfterLoad(() => setInterval(() => this.clearCache(), twoMinutes));
  }

  clearCache() {
    this.postMessage({ type: 'CHECK_CACHE' });
  }
}

const editorFileCacheSW = new EditorFileCacheSW({
  filePath: '/editorFilesCacheServiceWorker.js',
  scope: '/',
});

export const editorFileCacheService = editorFileCacheSW;
