import { useCallback, useEffect, useReducer } from 'react';

import { useAppDispatch, useAppSelector } from 'storeHooks';
import { amplitudeInstance } from 'pipes/functions';

import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';
import {
  addNewBlockInReduxAndApi,
  saveEventAsync,
  saveIntentAsync,
  savePatternsAsync,
} from '../../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { TActivationParameters, TagNames } from '../../../utils/types';
import { cleanUnsaved, cleanUpStateOnAddingMenu } from '../../../../../reducers/JGraph.reducer';
import { useSavingPipe } from '../../../hooks/savingPipe';
import { findScreenByPath } from '../../../../../reducers/JGraph.reducer/Graph';
import { BehaviorSubject, Subject } from 'rxjs';
import { guideTourEvent$ } from '../../JGraphGuideTour/guideTourEvents';

interface RightSideMenuExternalEventsTypes {
  setAddingMenuType?: {
    menuType: AddingMenuType;
    activationsMenuType?: ActivationsMenuType;
  };
}

export const RightSideMenuExternalEventsPipe$ = new Subject<RightSideMenuExternalEventsTypes>();

export type AddingMenuType = 'activations' | 'reactions' | 'fromStateEvents' | 'toStateEvents';
export type ActivationsMenuType = 'pattern' | 'intent' | 'event';

export class EventsState {
  addingMenuType?: AddingMenuType = undefined;
  addingMenuTypePrefix?: string;
  activationsMenuType?: ActivationsMenuType = undefined;
  activationEvent?: string = '';
  onlyThisState?: boolean;
  activationIntent: string = '';
  pattern: string = '';
  isGlobal: boolean = false;
  fromStateValue: string = '';
  toStateValue: string = '';
  preventGuideTour?: boolean;
}

export const wasFromNewIntentsMenu$ = new BehaviorSubject(false);
export const setWasFromNewIntentsMenu = (isItWasFromNewIntentsMenu: boolean) => {
  wasFromNewIntentsMenu$.next(isItWasFromNewIntentsMenu);
};

export type TChangeEventParams = {
  isGlobal: EventsState['isGlobal'];
  fromStateValue: EventsState['fromStateValue'];
  toStateValue: EventsState['toStateValue'];
  onlyThisState?: EventsState['onlyThisState'];
};

type Action =
  | { type: 'SET_ADDING_MENU_TYPE'; addingMenu: EventsState['addingMenuType']; addingMenuTypePrefix?: string }
  | { type: 'SET_ACTIVATIONS_MENU_TYPE'; activationsMenu: EventsState['activationsMenuType'] }
  | { type: 'SET_ACTIVATIONS_EVENT'; activationEvent: EventsState['activationEvent'] }
  | { type: 'CANCEL_ADDING_EVENTS' }
  | {
      type: 'CHANGE_EVENT_PARAMS';
      eventParams: TChangeEventParams;
    }
  | {
      type: 'ADD_PATTERN';
      value: string;
    }
  | {
      type: 'CHANGE_INTENT';
      value: string;
    }
  | {
      type: 'REMOVE_PATTERN';
      index: number;
    }
  | {
      type: 'CHANGE_PATTERN';
      value: string;
    }
  | {
      type: 'PREVENT_GUIDE_TOUR';
      value: boolean;
    };

const eventsHookReducer = (state: EventsState, action: Action) => {
  switch (action.type) {
    case 'SET_ADDING_MENU_TYPE':
      return {
        ...state,
        addingMenuType: action.addingMenu,
        addingMenuTypePrefix: action.addingMenuTypePrefix,
      };
    case 'CHANGE_EVENT_PARAMS':
      return {
        ...state,
        isGlobal: action.eventParams.isGlobal,
        onlyThisState: action.eventParams.isGlobal ? false : action.eventParams.onlyThisState,
        fromStateValue: action.eventParams.isGlobal ? '' : action.eventParams.fromStateValue,
        toStateValue: action.eventParams.isGlobal ? '' : action.eventParams.toStateValue,
      };
    case 'SET_ACTIVATIONS_MENU_TYPE':
      return {
        ...state,
        activationsMenuType: action.activationsMenu,
      };
    case 'SET_ACTIVATIONS_EVENT':
      return {
        ...state,
        activationEvent: action.activationEvent,
      };
    case 'CHANGE_PATTERN':
      return {
        ...state,
        pattern: action.value,
      };
    case 'CHANGE_INTENT':
      return {
        ...state,
        activationIntent: action.value,
      };
    case 'PREVENT_GUIDE_TOUR':
      return {
        ...state,
        preventGuideTour: action.value,
      };
    case 'CANCEL_ADDING_EVENTS':
      return new EventsState();
    default:
      throw new Error();
  }
};

export const useEvents = () => {
  const { currentBotLanguage, IntentsService } = useJGraphContext();
  const [eventsState, dispatchLocal] = useReducer(eventsHookReducer, new EventsState());
  const { actionToAddFromFloatingMenu, screens, editMenuScreen } = useAppSelector(state => ({
    actionToAddFromFloatingMenu: state.JGraphReducer.actionToAdd,
    screens: state.JGraphReducer.graph.blocks,
    editMenuScreen: state.JGraphReducer.editMenuBlock,
  }));
  const { stateCreate } = useSavingPipe();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatchLocal({ type: 'CANCEL_ADDING_EVENTS' });
  }, [editMenuScreen]);

  const setAddingMenuType = useCallback((addingMenu: EventsState['addingMenuType'], addingMenuPrefix?: string) => {
    setWasFromNewIntentsMenu(false);
    dispatchLocal({ type: 'SET_ADDING_MENU_TYPE', addingMenu, addingMenuTypePrefix: addingMenuPrefix });
  }, []);
  const setActivationsMenuType = useCallback((activationsMenu: EventsState['activationsMenuType']) => {
    setWasFromNewIntentsMenu(false);
    guideTourEvent$.next(`OpenActivationsMenuType:${activationsMenu}`);
    dispatchLocal({ type: 'SET_ACTIVATIONS_MENU_TYPE', activationsMenu });
  }, []);
  const selectActivationEvent = useCallback((activationEvent: EventsState['activationEvent']) => {
    dispatchLocal({ type: 'SET_ACTIVATIONS_EVENT', activationEvent });
  }, []);
  const setPreventGuideTour = useCallback((preventGuideTour: boolean) => {
    dispatchLocal({ type: 'PREVENT_GUIDE_TOUR', value: preventGuideTour });
  }, []);

  const changeEventParams = useCallback(
    (eventParams: {
      isGlobal: EventsState['isGlobal'];
      fromStateValue: EventsState['fromStateValue'];
      toStateValue: EventsState['toStateValue'];
      onlyThisState?: EventsState['onlyThisState'];
    }) => {
      dispatchLocal({ type: 'CHANGE_EVENT_PARAMS', eventParams });
    },
    []
  );
  const cancelAddingEvents = useCallback(
    (cleanStore: boolean = true) => {
      dispatchLocal({ type: 'CANCEL_ADDING_EVENTS' });
      if (cleanStore) {
        dispatch(cleanUnsaved());
      }
    },
    [dispatch]
  );

  const changePattern = useCallback((value: string) => {
    dispatchLocal({ type: 'CHANGE_PATTERN', value });
  }, []);
  const changeIntent = useCallback((value: string) => {
    dispatchLocal({ type: 'CHANGE_INTENT', value });
  }, []);

  useEffect(() => {
    if (actionToAddFromFloatingMenu) {
      if (['pattern', 'intent', 'event'].includes(actionToAddFromFloatingMenu)) {
        setAddingMenuType('activations');
        setActivationsMenuType(actionToAddFromFloatingMenu as ActivationsMenuType);
      } else {
        dispatch(addNewBlockInReduxAndApi({ tagName: actionToAddFromFloatingMenu as TagNames }));
      }
      dispatch(cleanUpStateOnAddingMenu());
    }
  }, [dispatch, actionToAddFromFloatingMenu, setActivationsMenuType, setAddingMenuType]);

  useEffect(() => {
    const sub = RightSideMenuExternalEventsPipe$.subscribe(next => {
      if (next.setAddingMenuType) {
        setAddingMenuType(next.setAddingMenuType.menuType, next.setAddingMenuType.activationsMenuType);
        setActivationsMenuType(next.setAddingMenuType.activationsMenuType);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [setActivationsMenuType, setAddingMenuType]);

  const saveEvent = useCallback(() => {
    if (eventsState.activationEvent) {
      amplitudeInstance.logEvent('J-Graph Event added', { eventName: eventsState.activationEvent });
      dispatch(
        saveEventAsync({
          value: eventsState.activationEvent,
          isGlobal: eventsState.isGlobal,
          tagParameters: eventsState.isGlobal
            ? []
            : ([
                { name: 'fromState', value: eventsState.fromStateValue || null, required: false },
                { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
                { name: 'toState', value: eventsState.toStateValue || null, required: false },
              ] as TActivationParameters),
        })
      );
    }
    cancelAddingEvents(false);
  }, [
    cancelAddingEvents,
    dispatch,
    eventsState.activationEvent,
    eventsState.fromStateValue,
    eventsState.isGlobal,
    eventsState.onlyThisState,
    eventsState.toStateValue,
  ]);

  const savePatterns = useCallback(() => {
    dispatch(
      savePatternsAsync({
        value: eventsState.pattern,
        isGlobal: eventsState.isGlobal,
        tagParameters: eventsState.isGlobal
          ? []
          : ([
              { name: 'fromState', value: eventsState.fromStateValue || null, required: false },
              { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
              { name: 'toState', value: eventsState.toStateValue || null, required: false },
            ] as TActivationParameters),
      })
    );
    cancelAddingEvents(false);
  }, [eventsState, cancelAddingEvents, dispatch]);

  const saveIntent = useCallback(() => {
    if (IntentsService.isSystemIntent(currentBotLanguage, eventsState.activationIntent)) {
      amplitudeInstance.logEvent('J-Graph System intent added.', { intentName: eventsState.activationIntent });
    }
    dispatch(
      saveIntentAsync({
        value: eventsState.activationIntent,
        isGlobal: eventsState.isGlobal,
        tagParameters: eventsState.isGlobal
          ? []
          : ([
              { name: 'fromState', value: eventsState.fromStateValue || null, required: false },
              { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
              { name: 'toState', value: eventsState.toStateValue || null, required: false },
            ] as TActivationParameters),
      })
    );
    cancelAddingEvents(false);
  }, [eventsState, cancelAddingEvents, dispatch]);

  const savePatternsFromState = useCallback(() => {
    const screenToSave = findScreenByPath(eventsState.fromStateValue, screens);
    if (screenToSave && eventsState.pattern.length > 0 && editMenuScreen) {
      const isUnsaved = screenToSave.isUnsaved;
      if (isUnsaved) stateCreate(screenToSave);

      dispatch(
        savePatternsAsync({
          value: eventsState.pattern,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: editMenuScreen.screen.path, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: null, required: false },
          ] as TActivationParameters,
          targetState: screenToSave,
        })
      );
    }
  }, [
    dispatch,
    editMenuScreen,
    eventsState.fromStateValue,
    eventsState.onlyThisState,
    eventsState.pattern,
    screens,
    stateCreate,
  ]);
  const saveIntentFromState = useCallback(() => {
    const screenToSave = findScreenByPath(eventsState.fromStateValue, screens);

    if (screenToSave && eventsState.activationIntent && editMenuScreen) {
      const isUnsaved = screenToSave.isUnsaved;
      if (isUnsaved) stateCreate(screenToSave);

      if (IntentsService.isSystemIntent(currentBotLanguage, eventsState.activationIntent)) {
        amplitudeInstance.logEvent('J-Graph System intent added.', { intentName: eventsState.activationIntent });
      }
      dispatch(
        saveIntentAsync({
          value: eventsState.activationIntent,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: editMenuScreen.screen.path, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: null, required: false },
          ] as TActivationParameters,
          targetState: screenToSave,
        })
      );
    }
  }, [
    dispatch,
    editMenuScreen,
    eventsState.activationIntent,
    eventsState.fromStateValue,
    eventsState.onlyThisState,
    screens,
    stateCreate,
  ]);

  const saveEventFromState = useCallback(() => {
    const screenToSave = findScreenByPath(eventsState.fromStateValue, screens);
    if (screenToSave && eventsState.activationEvent && editMenuScreen) {
      const isUnsaved = screenToSave.isUnsaved;
      if (isUnsaved) stateCreate(screenToSave);

      amplitudeInstance.logEvent('J-Graph Event added', { eventName: eventsState.activationEvent });
      dispatch(
        saveEventAsync({
          value: eventsState.activationEvent,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: editMenuScreen.screen.path, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: null, required: false },
          ] as TActivationParameters,
          targetState: screenToSave,
        })
      );
    }
  }, [
    dispatch,
    editMenuScreen,
    eventsState.activationEvent,
    eventsState.fromStateValue,
    eventsState.onlyThisState,
    screens,
    stateCreate,
  ]);
  //toState save
  const savePatternsToState = useCallback(() => {
    if (eventsState.pattern.length > 0) {
      dispatch(
        savePatternsAsync({
          value: eventsState.pattern,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: null, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: eventsState.toStateValue, required: false },
          ] as TActivationParameters,
        })
      );
    }
  }, [dispatch, eventsState.onlyThisState, eventsState.pattern, eventsState.toStateValue]);

  const saveIntentToState = useCallback(() => {
    if (IntentsService.isSystemIntent(currentBotLanguage, eventsState.activationIntent)) {
      amplitudeInstance.logEvent('J-Graph System intent added.', { intentName: eventsState.activationIntent });
    }
    if (eventsState.activationIntent) {
      dispatch(
        saveIntentAsync({
          value: eventsState.activationIntent,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: null, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: eventsState.toStateValue, required: false },
          ] as TActivationParameters,
        })
      );
    }
  }, [dispatch, eventsState.activationIntent, eventsState.onlyThisState, eventsState.toStateValue]);

  const saveEventToState = useCallback(() => {
    if (eventsState.activationEvent) {
      amplitudeInstance.logEvent('J-Graph Event added', { eventName: eventsState.activationEvent });
      dispatch(
        saveEventAsync({
          value: eventsState.activationEvent,
          isGlobal: false,
          tagParameters: [
            { name: 'fromState', value: null, required: false },
            { name: 'onlyThisState', value: eventsState.onlyThisState, required: false },
            { name: 'toState', value: eventsState.toStateValue, required: false },
          ] as TActivationParameters,
        })
      );
    }
  }, [dispatch, eventsState.activationEvent, eventsState.onlyThisState, eventsState.toStateValue]);

  return {
    eventsState,
    setAddingMenuType,
    setActivationsMenuType,
    selectActivationEvent,
    cancelAddingEvents,
    changeEventParams,
    saveEvent,
    savePatterns,
    changePattern,
    changeIntent,
    saveIntent,
    savePatternsFromState,
    saveIntentFromState,
    saveEventFromState,
    savePatternsToState,
    saveIntentToState,
    saveEventToState,
    setWasFromNewIntentsMenu,
    setPreventGuideTour,
  };
};
