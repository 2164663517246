import React from 'react';

export const TovieLogo = ({ width, height, logoUrl }) => {
  return logoUrl?.length > 1 ? (
    <img src={logoUrl} alt='tovie_logo' style={{ width: width, height: height }} />
  ) : (
    <svg style={{ width: width, height: height }} alt='tovie_logo' viewBox='0 0 94 94' fill='none'>
      <path
        d='M0 47C0 34.5348 4.95178 22.5802 13.766 13.766C22.5802 4.95177 34.5348 0 47 0H94V47C94 53.1721 92.7843 59.2838 90.4224 64.9861C88.0604 70.6884 84.5984 75.8697 80.234 80.234C75.8697 84.5984 70.6884 88.0604 64.9861 90.4223C59.2838 92.7843 53.1721 94 47 94V94C34.5348 94 22.5802 89.0482 13.766 80.234C4.95178 71.4198 0 59.4652 0 47Z'
        fill='#6565F0'
      />
      <path
        d='M49.25 39.8999V66.843C49.25 69.8367 50.46 71.3335 53.76 71.3335H57.36V82.4301H52.64C44.18 82.4301 36.64 77.6501 36.64 66.8629V11.4301H49.25V39.8999Z'
        fill='#F4F4FB'
      />
      <path d='M47 27.7301H30.03V38.1401H47V27.7301Z' fill='#F4F4FB' />
      <path
        d='M60.3 38.5101C64.5305 38.5101 67.96 35.0806 67.96 30.8501C67.96 26.6196 64.5305 23.1901 60.3 23.1901C56.0695 23.1901 52.64 26.6196 52.64 30.8501C52.64 35.0806 56.0695 38.5101 60.3 38.5101Z'
        fill='#F4F4FB'
      />
    </svg>
  );
};

export const TovieLabel = ({ width = 398, height = 92 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 398 92' fill='none' alt='Tovie'>
      <path
        d='M211.23 18.76V26.88H202.23V71.4H190.23V26.88H183.62V18.76H189.62V17.31C189.62 11.61 191.12 7.4 194.42 4.7C197.42 1.7 202.23 0.5 208.54 0.5H211.24V11.61H210.34C207.34 11.61 205.54 12.21 204.34 13.11C203.14 14.01 202.54 15.51 202.54 17.62V18.81L211.23 18.76Z'
        fill='#2A2A36'
      />
      <path
        d='M306.59 26.91C295.66 27.85 292.84 32.53 292.84 42.83V71.55H280.05V43.49C280.05 27.9 289.05 14.77 306.59 14.77V26.91Z'
        fill='#2A2A36'
      />
      <path
        d='M361.18 71.5501V39.1001C361.226 36.0831 362.446 33.2027 364.579 31.0692C366.713 28.9356 369.593 27.7165 372.61 27.6701C375.677 27.6484 378.628 28.8359 380.826 30.9753C383.023 33.1148 384.289 36.0338 384.35 39.1001V71.5501H397.02V39.4101C397.007 32.9402 394.431 26.7391 389.856 22.1642C385.281 17.5893 379.08 15.0133 372.61 15.0001C369.227 14.983 365.88 15.6878 362.792 17.0674C359.703 18.4469 356.945 20.4695 354.7 23.0001C352.451 20.469 349.689 18.4463 346.597 17.0668C343.506 15.6873 340.155 14.9827 336.77 15.0001C330.3 15.0159 324.099 17.5924 319.523 22.1665C314.947 26.7405 312.368 32.9401 312.35 39.4101V71.5501H325.35V39.1001C325.35 36.0679 326.551 33.1593 328.69 31.0101C330.301 29.4385 332.337 28.3741 334.547 27.9488C336.757 27.5234 339.043 27.7557 341.122 28.6169C343.201 29.4781 344.982 30.9304 346.243 32.7938C347.505 34.6572 348.192 36.8498 348.22 39.1001V71.5501H361.18Z'
        fill='#2A2A36'
      />
      <path
        d='M28.25 14.77C20.769 14.8068 13.6049 17.795 8.31494 23.0849C3.025 28.3749 0.0368012 35.539 0 43.02V91.86H12.13V66.41C15.7825 68.9496 19.9817 70.5933 24.3876 71.2081C28.7935 71.8229 33.2822 71.3915 37.4904 69.9488C41.6985 68.5061 45.5077 66.0926 48.6096 62.9039C51.7115 59.7152 54.019 55.8408 55.345 51.5944C56.671 47.348 56.9783 42.8491 56.2422 38.4618C55.506 34.0746 53.7471 29.9224 51.1077 26.3414C48.4683 22.7604 45.0226 19.8513 41.0498 17.8497C37.077 15.8481 32.6886 14.8102 28.24 14.82L28.25 14.77ZM28.25 59.12C24.5462 59.1264 20.9548 57.8475 18.0886 55.5016C15.2224 53.1557 13.2589 49.888 12.533 46.256C11.807 42.624 12.3635 38.8526 14.1078 35.5852C15.852 32.3178 18.6759 29.7567 22.0977 28.3388C25.5194 26.9209 29.327 26.7341 32.8711 27.8102C36.4152 28.8863 39.4761 31.1586 41.5319 34.2396C43.5876 37.3206 44.5108 41.0193 44.1439 44.7049C43.777 48.3906 42.1428 51.8348 39.52 54.45C36.5275 57.4339 32.476 59.1128 28.25 59.12V59.12Z'
        fill='#2A2A36'
      />
      <path d='M65.1299 71.55V0.400024H77.7399V71.55H65.1299Z' fill='#2A2A36' />
      <path
        d='M228.13 43.03C228.13 48.13 229.63 52.03 232.33 55.03C235.03 57.74 238.64 59.23 243.45 59.23C245.503 59.278 247.545 58.9071 249.45 58.14C251.36 57.3645 253.089 56.2037 254.53 54.73C257.23 51.73 258.44 47.83 258.44 42.73C258.44 38.22 257.23 34.62 254.24 31.62C252.845 30.1665 251.165 29.0166 249.304 28.2422C247.444 27.4679 245.445 27.0859 243.43 27.12C241.366 27.0586 239.311 27.4269 237.397 28.2015C235.483 28.9762 233.751 30.1403 232.31 31.62C229.532 34.7625 228.039 38.8364 228.13 43.03ZM215.22 43.03C215.156 39.4165 215.872 35.8316 217.32 32.5201C218.667 29.0417 220.709 25.874 223.32 23.21C225.846 20.5772 228.918 18.5293 232.32 17.21C235.716 15.6611 239.398 14.8402 243.13 14.8C246.729 14.8802 250.286 15.591 253.64 16.9C256.971 18.2232 260.024 20.1601 262.64 22.61C265.522 25.1711 267.782 28.3549 269.25 31.92C270.758 35.3288 271.574 39.0033 271.65 42.73C271.619 46.6266 270.908 50.4878 269.55 54.14C268.226 57.5393 266.178 60.6101 263.55 63.14C261.115 65.952 258.024 68.1194 254.55 69.45C250.928 70.9136 247.046 71.6279 243.14 71.55C239.428 71.6281 235.743 70.9121 232.33 69.45C228.883 67.9046 225.732 65.7692 223.02 63.14C220.633 60.3626 218.708 57.2191 217.32 53.83C215.86 50.4213 215.154 46.7372 215.25 43.03H215.22Z'
        fill='#2A2A36'
      />
      <path
        d='M114.13 14.77C106.731 14.9814 99.7067 18.0733 94.5535 23.387C89.4004 28.7007 86.5254 35.8164 86.5411 43.2184C86.5567 50.6205 89.4617 57.7239 94.6373 63.0158C99.8128 68.3077 106.85 71.3699 114.25 71.55C119.934 71.5994 125.494 69.8916 130.17 66.66V71.31H142.37V43.15C142.362 35.6498 139.389 28.4569 134.098 23.1403C128.808 17.8238 121.63 14.8149 114.13 14.77V14.77ZM114.3 59.04C110.921 59.0205 107.637 57.9168 104.932 55.8913C102.227 53.8658 100.244 51.0254 99.2744 47.7883C98.3047 44.5512 98.3998 41.0883 99.5457 37.9093C100.692 34.7303 102.828 32.0032 105.64 30.1292C108.452 28.2551 111.791 27.3333 115.166 27.4993C118.541 27.6653 121.774 28.9105 124.388 31.0514C127.003 33.1923 128.861 36.116 129.689 39.3921C130.518 42.6682 130.272 46.1237 128.99 49.25C128.195 51.1803 127.026 52.934 125.55 54.4101C124.074 55.8861 122.32 57.0553 120.39 57.85C118.443 58.6526 116.355 59.0572 114.25 59.04H114.3Z'
        fill='#2A2A36'
      />
      <path d='M211.23 16.6702H183.62V27.0802H211.23V16.6702Z' fill='#2A2A36' />
      <path
        d='M166.02 28.9001V55.9001C166.02 58.9001 167.23 60.4001 170.53 60.4001H174.13V71.5201H169.41C160.95 71.5201 153.41 66.7301 153.41 55.9201V0.370117H166.02V28.9001V28.9001Z'
        fill='#2A2A36'
      />
      <path d='M174.13 16.6702H146.8V27.0802H174.13V16.6702Z' fill='#2A2A36' />
    </svg>
  );
};

export const TovieWithBrandname = ({ width = 261, height = 51 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 523 103' fill='none'>
      <path
        d='M336.23 29.76V37.88H327.23V82.4H315.23V37.88H308.62V29.76H314.62V28.31C314.62 22.61 316.12 18.4 319.42 15.7C322.42 12.7 327.23 11.5 333.54 11.5H336.24V22.61H335.34C332.34 22.61 330.54 23.21 329.34 24.11C328.14 25.01 327.54 26.51 327.54 28.62V29.81L336.23 29.76Z'
        fill='#F4F4FB'
      />
      <path
        d='M431.59 37.91C420.66 38.85 417.84 43.53 417.84 53.83V82.55H405.05V54.49C405.05 38.9 414.05 25.77 431.59 25.77V37.91Z'
        fill='#F4F4FB'
      />
      <path
        d='M486.18 82.5501V50.1001C486.226 47.0831 487.446 44.2027 489.579 42.0692C491.713 39.9356 494.593 38.7165 497.61 38.6701C500.677 38.6484 503.628 39.8359 505.826 41.9753C508.023 44.1148 509.289 47.0338 509.35 50.1001V82.5501H522.02V50.4101C522.007 43.9402 519.431 37.7391 514.856 33.1642C510.281 28.5893 504.08 26.0133 497.61 26.0001C494.227 25.983 490.88 26.6878 487.792 28.0674C484.703 29.4469 481.945 31.4695 479.7 34.0001C477.451 31.469 474.689 29.4463 471.597 28.0668C468.506 26.6873 465.155 25.9827 461.77 26.0001C455.3 26.0159 449.099 28.5924 444.523 33.1665C439.947 37.7405 437.368 43.9401 437.35 50.4101V82.5501H450.35V50.1001C450.35 47.0679 451.551 44.1593 453.69 42.0101C455.301 40.4385 457.337 39.3741 459.547 38.9488C461.757 38.5234 464.043 38.7557 466.122 39.6169C468.201 40.4781 469.982 41.9304 471.243 43.7938C472.505 45.6572 473.192 47.8498 473.22 50.1001V82.5501H486.18Z'
        fill='#F4F4FB'
      />
      <path
        d='M153.25 25.77C145.769 25.8068 138.605 28.795 133.315 34.0849C128.025 39.3749 125.037 46.539 125 54.02V102.86H137.13V77.41C140.782 79.9496 144.982 81.5933 149.388 82.2081C153.793 82.8229 158.282 82.3915 162.49 80.9488C166.699 79.5061 170.508 77.0926 173.61 73.9039C176.712 70.7152 179.019 66.8408 180.345 62.5944C181.671 58.348 181.978 53.8491 181.242 49.4618C180.506 45.0746 178.747 40.9224 176.108 37.3414C173.468 33.7604 170.023 30.8513 166.05 28.8497C162.077 26.8481 157.689 25.8102 153.24 25.82L153.25 25.77ZM153.25 70.12C149.546 70.1264 145.955 68.8475 143.089 66.5016C140.222 64.1557 138.259 60.888 137.533 57.256C136.807 53.624 137.364 49.8526 139.108 46.5852C140.852 43.3178 143.676 40.7567 147.098 39.3388C150.519 37.9209 154.327 37.7341 157.871 38.8102C161.415 39.8863 164.476 42.1586 166.532 45.2396C168.588 48.3206 169.511 52.0193 169.144 55.7049C168.777 59.3906 167.143 62.8348 164.52 65.45C161.527 68.4339 157.476 70.1128 153.25 70.12Z'
        fill='#F4F4FB'
      />
      <path d='M190.13 82.55V11.4H202.74V82.55H190.13Z' fill='#F4F4FB' />
      <path
        d='M353.13 54.03C353.13 59.13 354.63 63.03 357.33 66.03C360.03 68.74 363.64 70.23 368.45 70.23C370.503 70.278 372.545 69.9071 374.45 69.14C376.36 68.3645 378.089 67.2037 379.53 65.73C382.23 62.73 383.44 58.83 383.44 53.73C383.44 49.22 382.23 45.62 379.24 42.62C377.845 41.1665 376.165 40.0166 374.304 39.2422C372.444 38.4679 370.445 38.0859 368.43 38.12C366.366 38.0586 364.311 38.4269 362.397 39.2015C360.483 39.9762 358.751 41.1403 357.31 42.62C354.532 45.7625 353.039 49.8364 353.13 54.03ZM340.22 54.03C340.156 50.4165 340.872 46.8316 342.32 43.5201C343.667 40.0417 345.709 36.874 348.32 34.21C350.846 31.5772 353.918 29.5293 357.32 28.21C360.716 26.6611 364.398 25.8402 368.13 25.8C371.729 25.8802 375.286 26.591 378.64 27.9C381.971 29.2232 385.024 31.1601 387.64 33.61C390.522 36.1711 392.782 39.3549 394.25 42.92C395.758 46.3288 396.574 50.0033 396.65 53.73C396.619 57.6266 395.908 61.4878 394.55 65.14C393.226 68.5393 391.178 71.6101 388.55 74.14C386.115 76.952 383.024 79.1194 379.55 80.45C375.928 81.9136 372.046 82.6279 368.14 82.55C364.428 82.6281 360.743 81.9121 357.33 80.45C353.883 78.9046 350.732 76.7692 348.02 74.14C345.633 71.3626 343.708 68.2191 342.32 64.83C340.86 61.4213 340.154 57.7372 340.25 54.03H340.22Z'
        fill='#F4F4FB'
      />
      <path
        d='M239.13 25.77C231.731 25.9814 224.707 29.0733 219.554 34.387C214.4 39.7007 211.525 46.8164 211.541 54.2184C211.557 61.6205 214.462 68.7239 219.637 74.0158C224.813 79.3077 231.85 82.3699 239.25 82.55C244.934 82.5994 250.494 80.8916 255.17 77.66V82.31H267.37V54.15C267.362 46.6498 264.389 39.4569 259.098 34.1403C253.808 28.8238 246.63 25.8149 239.13 25.77ZM239.3 70.04C235.921 70.0205 232.637 68.9168 229.932 66.8913C227.227 64.8658 225.244 62.0254 224.274 58.7883C223.305 55.5512 223.4 52.0883 224.546 48.9093C225.692 45.7303 227.828 43.0032 230.64 41.1292C233.452 39.2551 236.791 38.3333 240.166 38.4993C243.541 38.6653 246.774 39.9105 249.388 42.0514C252.003 44.1923 253.861 47.116 254.689 50.3921C255.518 53.6682 255.272 57.1237 253.99 60.25C253.195 62.1803 252.026 63.934 250.55 65.4101C249.074 66.8861 247.32 68.0553 245.39 68.85C243.443 69.6526 241.355 70.0572 239.25 70.04H239.3Z'
        fill='#F4F4FB'
      />
      <path d='M336.23 27.6702H308.62V38.0802H336.23V27.6702Z' fill='#F4F4FB' />
      <path
        d='M291.02 39.9001V66.9001C291.02 69.9001 292.23 71.4001 295.53 71.4001H299.13V82.5201H294.41C285.95 82.5201 278.41 77.7301 278.41 66.9201V11.3701H291.02V39.9001Z'
        fill='#F4F4FB'
      />
      <path d='M299.13 27.6702H271.8V38.0802H299.13V27.6702Z' fill='#F4F4FB' />
      <path
        d='M0 47C0 34.5348 4.95178 22.5802 13.766 13.766C22.5802 4.95177 34.5348 0 47 0H94V47C94 53.1721 92.7843 59.2838 90.4224 64.9861C88.0604 70.6884 84.5984 75.8697 80.234 80.234C75.8697 84.5984 70.6884 88.0604 64.9861 90.4223C59.2838 92.7843 53.1721 94 47 94C34.5348 94 22.5802 89.0482 13.766 80.234C4.95178 71.4198 0 59.4652 0 47Z'
        fill='#6565F0'
      />
      <path
        d='M49.25 39.8999V66.843C49.25 69.8367 50.46 71.3335 53.76 71.3335H57.36V82.4301H52.64C44.18 82.4301 36.64 77.6501 36.64 66.8629V11.4301H49.25V39.8999Z'
        fill='#F4F4FB'
      />
      <path d='M47 27.7301H30.03V38.1401H47V27.7301Z' fill='#F4F4FB' />
      <path
        d='M60.3 38.5101C64.5305 38.5101 67.96 35.0806 67.96 30.8501C67.96 26.6196 64.5305 23.1901 60.3 23.1901C56.0695 23.1901 52.64 26.6196 52.64 30.8501C52.64 35.0806 56.0695 38.5101 60.3 38.5101Z'
        fill='#F4F4FB'
      />
      <path
        d='M0 47C0 34.5348 4.95178 22.5802 13.766 13.766C22.5802 4.95177 34.5348 0 47 0H94V47C94 53.1721 92.7843 59.2838 90.4224 64.9861C88.0604 70.6884 84.5984 75.8697 80.234 80.234C75.8697 84.5984 70.6884 88.0604 64.9861 90.4223C59.2838 92.7843 53.1721 94 47 94C34.5348 94 22.5802 89.0482 13.766 80.234C4.95178 71.4198 0 59.4652 0 47Z'
        fill='#6565F0'
      />
      <path
        d='M49.25 39.8999V66.843C49.25 69.8367 50.46 71.3335 53.76 71.3335H57.36V82.4301H52.64C44.18 82.4301 36.64 77.6501 36.64 66.8629V11.4301H49.25V39.8999Z'
        fill='#F4F4FB'
      />
      <path d='M47 27.7301H30.03V38.1401H47V27.7301Z' fill='#F4F4FB' />
      <path
        d='M60.3 38.5101C64.5305 38.5101 67.96 35.0806 67.96 30.8501C67.96 26.6196 64.5305 23.1901 60.3 23.1901C56.0695 23.1901 52.64 26.6196 52.64 30.8501C52.64 35.0806 56.0695 38.5101 60.3 38.5101Z'
        fill='#F4F4FB'
      />
    </svg>
  );
};

export const JAICPLogo = props => {
  const { width, height, logoUrl } = props;

  return logoUrl?.length > 1 ? (
    <img src={logoUrl} alt='jaicp_logo' style={{ width: width, height: height }} />
  ) : (
    <svg style={{ width: width, height: height }} alt='jaicp_logo' viewBox='0 0 44 48' fill='none'>
      <path
        d='M40.2445 10.0245L32.3421 5.48791L24.0002 0.658376C22.5365 -0.219459 20.6344 -0.219459 19.0242 0.658376L10.8289 5.34144L2.488 10.171C0.877835 11.0488 0 12.8054 0 14.5611V33.5854C0 35.4875 1.17077 37.0977 2.78093 37.9755L10.6834 42.6586L19.0252 47.3416C20.4889 48.2195 22.391 48.2195 24.0012 47.3416L32.1966 42.6586L40.5374 37.829C42.1476 36.9512 43.0254 35.341 43.0254 33.5854V14.4146C43.0245 12.5125 41.8537 10.9023 40.2445 10.0245ZM36.368 35.8334H32.8643C29.9157 38.6625 25.9216 40.4105 21.5132 40.4105C17.1038 40.4105 13.1097 38.6625 10.1611 35.8334H6.65747C4.17236 35.8334 2.61905 33.1431 3.86209 30.9914L5.60619 27.9705C5.28917 26.6966 5.10223 25.3717 5.10223 23.9995C5.10223 16.5028 10.1351 10.1931 17.0026 8.23126L18.7178 5.2605C19.9599 3.1088 23.0665 3.1088 24.3096 5.2605L26.0247 8.23126C32.8923 10.1931 37.9251 16.5018 37.9251 23.9995C37.9251 25.3717 37.7382 26.6966 37.4212 27.9705L39.1653 30.9914C40.4064 33.1431 38.8531 35.8334 36.368 35.8334Z'
        fill='#3D3DA5'
      />
      <path
        d='M26.0238 8.232L24.3086 5.26124C23.0666 3.10953 19.9599 3.10953 18.7169 5.26124L17.0017 8.232C18.4365 7.82247 19.9465 7.58928 21.5123 7.58928C23.0781 7.58928 24.589 7.82247 26.0238 8.232Z'
        fill='#ACACFC'
      />
      <path
        d='M5.60608 27.9707L3.86198 30.9916C2.6199 33.1433 4.17225 35.8336 6.65736 35.8336H10.161C7.96592 33.7272 6.36443 31.0186 5.60608 27.9707Z'
        fill='#ACACFC'
      />
      <path
        d='M37.4192 27.9707C36.6599 31.0186 35.0593 33.7272 32.8643 35.8336H36.3679C38.853 35.8336 40.4063 33.1433 39.1633 30.9916L37.4192 27.9707Z'
        fill='#ACACFC'
      />
      <path
        d='M5.10229 24.0007C5.10229 25.3728 5.28923 26.6978 5.60626 27.9717L17.0027 8.2334C10.1351 10.1933 5.10229 16.503 5.10229 24.0007Z'
        fill='#6262ED'
      />
      <path
        d='M21.5123 40.4106C25.9207 40.4106 29.9148 38.6626 32.8634 35.8335H10.1611C13.1097 38.6626 17.1038 40.4106 21.5123 40.4106Z'
        fill='#6262ED'
      />
      <path
        d='M37.9231 24.0007C37.9231 16.5039 32.8902 10.1943 26.0227 8.23242L37.4191 27.9707C37.7362 26.6968 37.9231 25.3719 37.9231 24.0007Z'
        fill='#6262ED'
      />
      <path
        d='M37.4193 27.9703L26.0238 8.23207C24.589 7.82255 23.0791 7.58936 21.5132 7.58936C19.9474 7.58936 18.4374 7.82255 17.0026 8.23207L5.6062 27.9703C6.36455 31.0182 7.96604 33.7268 10.1611 35.8333H32.8643C35.0594 33.7268 36.6609 31.0182 37.4193 27.9703Z'
        fill='white'
      />
      <path
        d='M22.525 21.1976L19.8452 19.6163C19.5648 19.4506 19.3933 19.15 19.3933 18.8252V15.6512C19.3933 15.3264 19.5648 15.0248 19.8452 14.86L22.525 13.2778C22.8131 13.1073 23.1716 13.1073 23.4597 13.2778L26.1394 14.86C26.4198 15.0258 26.5914 15.3264 26.5914 15.6512V18.8252C26.5914 19.15 26.4198 19.4516 26.1394 19.6163L23.4597 21.1976C23.1706 21.3682 22.8131 21.3682 22.525 21.1976ZM20.8127 18.6932L22.8545 19.8977C22.9393 19.9478 23.0434 19.9478 23.1282 19.8977L25.1701 18.6932C25.252 18.645 25.3021 18.5564 25.3021 18.461V16.0154C25.3021 15.92 25.252 15.8323 25.1701 15.7832L23.1282 14.5777C23.0434 14.5276 22.9393 14.5276 22.8545 14.5777L20.8127 15.7832C20.7308 15.8313 20.6807 15.92 20.6807 16.0154V18.461C20.6807 18.5564 20.7308 18.6441 20.8127 18.6932Z'
        fill='#3D3DA5'
      />
      <path
        d='M26.4978 27.1121L24.4483 25.9019C24.1679 25.7361 23.9963 25.4355 23.9963 25.1108V22.6806C23.9963 22.3558 24.1679 22.0542 24.4483 21.8895L26.4978 20.6792C26.7859 20.5086 27.1434 20.5086 27.4325 20.6792L29.483 21.8895C29.7634 22.0552 29.935 22.3558 29.935 22.6806V25.1108C29.935 25.4355 29.7634 25.7371 29.483 25.9019L27.4325 27.1121C27.1444 27.2817 26.7859 27.2817 26.4978 27.1121ZM25.4157 24.9787L26.8283 25.8123C26.9131 25.8624 27.0172 25.8624 27.102 25.8123L28.5146 24.9787C28.5965 24.9306 28.6466 24.8419 28.6466 24.7465V23.0438C28.6466 22.9485 28.5965 22.8608 28.5146 22.8116L27.102 21.9781C27.0172 21.928 26.9131 21.928 26.8283 21.9781L25.4157 22.8116C25.3338 22.8598 25.2837 22.9485 25.2837 23.0438V24.7465C25.2837 24.8419 25.3338 24.9296 25.4157 24.9787Z'
        fill='#3D3DA5'
      />
      <path
        d='M17.1529 31.6997L12.8186 29.1414C12.5382 28.9756 12.3667 28.675 12.3667 28.3503V23.223C12.3667 22.8982 12.5382 22.5966 12.8186 22.4319L17.1529 19.8735C17.441 19.703 17.7985 19.703 18.0876 19.8735L22.4218 22.4319C22.7022 22.5976 22.8737 22.8982 22.8737 23.223V28.3503C22.8737 28.675 22.7022 28.9766 22.4218 29.1414L18.0876 31.6997C17.7985 31.8693 17.441 31.8693 17.1529 31.6997ZM13.787 28.2173L17.4834 30.3989C17.5682 30.449 17.6722 30.449 17.757 30.3989L21.4534 28.2173C21.5353 28.1691 21.5854 28.0805 21.5854 27.9851V23.5863C21.5854 23.4909 21.5353 23.4032 21.4534 23.354L17.757 21.1724C17.6722 21.1223 17.5682 21.1223 17.4834 21.1724L13.787 23.354C13.7051 23.4022 13.655 23.4909 13.655 23.5863V27.9851C13.6541 28.0814 13.7051 28.1691 13.787 28.2173Z'
        fill='#3D3DA5'
      />
    </svg>
  );
};

export const JAICPLabel = ({ height = 48, width = 102 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 102 48' fill='none' alt='JAICP'>
      <path
        d='M47.8681 38.8718C46.9633 38.8718 46.23 38.1385 46.23 37.2337V10.81C46.23 9.90517 46.9633 9.17188 47.8681 9.17188C48.7729 9.17188 49.5062 9.90517 49.5062 10.81V37.2337C49.5062 38.1375 48.7729 38.8718 47.8681 38.8718Z'
        fill='#333333'
      />
      <path
        d='M99.2277 11.3885C97.6965 10.0279 95.7664 9.39968 93.9713 9.2378C93.5165 9.17998 93.0693 9.17805 92.6222 9.17131L91.3946 9.16938L88.9885 9.17709C87.3851 9.18576 85.7807 9.18865 84.1773 9.16553C84.1657 9.16553 84.1474 9.16553 84.1368 9.16553C84.1127 9.16553 84.0896 9.17227 84.0665 9.17324C83.1752 9.18865 82.4573 9.91328 82.4573 10.8085V37.2591C82.4573 38.1639 83.1906 38.8972 84.0954 38.8972C85.0002 38.8972 85.7335 38.1639 85.7335 37.2591V25.0253C87.016 25.0147 88.2995 25.0109 89.5821 25.0166L91.9882 25.0244C92.7485 25.0273 93.7111 25.0378 94.6024 24.9261C95.514 24.7979 96.4458 24.5744 97.3496 24.1639C98.2535 23.7611 99.113 23.1309 99.7914 22.3417C101.187 20.7364 101.622 18.6598 101.635 16.8328C101.639 14.8247 100.77 12.7405 99.2277 11.3885ZM97.3689 20.3239C96.6954 21.2249 95.5226 21.7597 94.1755 21.9987C92.8785 22.2126 91.1682 22.1172 89.5831 22.1471C88.3005 22.1538 87.017 22.149 85.7345 22.1394V12.0534C86.8195 12.0476 87.9054 12.0399 88.9904 12.0457L91.3965 12.0534C92.2301 12.0544 92.9499 12.0534 93.6244 12.1633C95.0014 12.3415 96.2396 12.8667 97.0837 13.6886C97.9114 14.5183 98.3701 15.6418 98.3604 16.8425C98.3662 18.1963 98.0579 19.4413 97.3689 20.3239Z'
        fill='#333333'
      />
      <path
        d='M74.6637 17.0357C74.0509 15.0353 72.3694 13.3866 70.3478 12.6312C68.3483 11.8468 65.8709 11.8892 63.8387 12.661C61.793 13.4261 60.1433 15.0064 59.1373 17.0213C58.1227 19.0304 57.6438 21.369 57.611 23.7221C57.5561 26.0877 57.9174 28.4572 58.8174 30.5212C59.7078 32.591 61.2476 34.2638 63.2519 35.1234C65.2244 35.9877 67.7191 36.1506 69.8101 35.5483C71.9242 34.9615 73.7368 33.5113 74.656 31.5581C75.0183 30.7872 75.9376 30.4567 76.7085 30.819C77.4793 31.1823 77.8099 32.1006 77.4476 32.8714C77.437 32.8946 77.4254 32.9167 77.4138 32.9389L77.3936 32.9774C75.9819 35.6081 73.4419 37.6094 70.6041 38.3591C69.1963 38.759 67.723 38.8477 66.318 38.7879C64.9006 38.7195 63.4542 38.442 62.0888 37.8783C60.7224 37.3232 59.4611 36.4714 58.4156 35.4259C57.3797 34.3708 56.5424 33.1451 55.9372 31.8423C54.7019 29.2319 54.3049 26.4009 54.3406 23.6576C54.4109 20.9026 54.9717 18.0793 56.3574 15.5441C57.6977 13.0137 60.0161 10.8254 62.8221 9.86371C64.2116 9.37228 65.6676 9.16221 67.0879 9.1651C68.5237 9.14198 69.9941 9.38095 71.3798 9.89165C74.1549 10.9102 76.5206 13.1476 77.5776 16.023L77.5873 16.049C77.8812 16.8488 77.4707 17.7353 76.6709 18.0292C75.8711 18.3231 74.9846 17.9126 74.6907 17.1128C74.6811 17.0907 74.6714 17.0608 74.6637 17.0357Z'
        fill='#333333'
      />
      <path
        d='M2.31713 34.8634C3.09378 35.4156 4.27419 35.642 5.53939 35.669C6.23895 35.6864 6.66294 35.6362 7.14184 35.4898C7.59762 35.3433 8.0033 35.1024 8.35983 34.7661C9.06036 34.081 9.57781 33.049 9.82063 31.8994C9.94108 31.3203 9.99986 30.7316 10.0085 30.1139V27.9709V10.8266C10.0085 9.92177 10.7418 9.18848 11.6466 9.18848C12.5515 9.18848 13.2848 9.92177 13.2848 10.8266V27.9709V30.1139C13.2819 30.9243 13.2086 31.7636 13.041 32.5845C12.6835 34.213 11.9502 35.8983 10.5366 37.1645C9.83316 37.7889 8.96496 38.2688 8.05148 38.5241C7.16015 38.7882 6.13681 38.8151 5.41122 38.7602C3.85117 38.6629 2.07237 38.3545 0.548932 37.1501C-0.077404 36.6548 -0.183401 35.7461 0.311886 35.1198C0.790792 34.5137 1.65706 34.3951 2.27954 34.8355L2.31713 34.8634Z'
        fill='#333333'
      />
      <path
        d='M41.6684 36.6652L30.8944 10.2415C30.6574 9.66043 30.0937 9.28174 29.4673 9.28174C28.841 9.28174 28.2763 9.66139 28.0402 10.2415L17.2653 36.6652C16.9435 37.4534 17.3222 38.3534 18.1104 38.6742C18.8977 38.9951 19.7986 38.6174 20.1195 37.8292L23.0797 30.5685H35.8511L38.8123 37.8292C39.0561 38.4276 39.6323 38.7899 40.2403 38.7899C40.434 38.7899 40.6315 38.7523 40.8223 38.6752C41.6115 38.3534 41.9902 37.4534 41.6684 36.6652ZM24.2591 27.6768L29.0723 15.8737C29.2804 15.309 29.4413 14.6663 29.4664 14.4408C29.4914 14.6663 29.6533 15.3129 29.8624 15.8785L34.6736 27.6768H24.2591V27.6768Z'
        fill='#333333'
      />
    </svg>
  );
};

export const JAICFLogo = props => {
  const { width, height, logoUrl } = props;

  return logoUrl?.length > 1 ? (
    <img src={logoUrl} alt='jaicf_logo' style={{ width: width, height: height }} />
  ) : (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.0923 21.2841L11.9933 23.6535C11.6159 23.8795 11.1848 24.0002 10.7449 24.003C10.3051 24.0059 9.87249 23.8907 9.49221 23.6696L5.36499 21.2841L1.39635 18.9927C0.977903 18.7906 0.624933 18.4745 0.377948 18.0809C0.130962 17.6872 -3.16592e-05 17.2319 5.73939e-09 16.7672V7.23732C0.000449762 6.80395 0.114785 6.3783 0.331551 6.00303C0.548316 5.62776 0.859898 5.31605 1.23508 5.09912L5.3623 2.71633L9.4613 0.349655C9.83856 0.123854 10.2695 0.00337558 10.7091 0.000775419C11.1488 -0.00182474 11.5811 0.113549 11.961 0.334872L16.0882 2.71633L20.0569 5.00102C20.4751 5.20343 20.8279 5.51954 21.0749 5.91314C21.3218 6.30674 21.453 6.76192 21.4532 7.22657V16.7564C21.4533 17.1894 21.3397 17.6149 21.1236 17.9901C20.9076 18.3654 20.5967 18.6773 20.2222 18.8946L16.0923 21.2841Z'
          fill='#562AE6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.8331 9.42504L12.1278 3.00775C11.9897 2.76206 11.7891 2.55725 11.5463 2.41405C11.3035 2.27085 11.0272 2.19435 10.7454 2.1923C10.4635 2.19026 10.1862 2.26273 9.94133 2.40239C9.69651 2.54205 9.49294 2.74394 9.35125 2.98759L5.63393 9.42504L1.9932 15.7281C1.79901 15.9992 1.69552 16.3247 1.69754 16.6581C1.69825 17.083 1.86744 17.4902 2.16801 17.7906C2.46857 18.0909 2.87597 18.2597 3.30085 18.2601H18.1621V18.252C18.4417 18.2517 18.7163 18.1781 18.9585 18.0383C19.2007 17.8986 19.4019 17.6977 19.5421 17.4557C19.6822 17.2138 19.7564 16.9393 19.7572 16.6597C19.7579 16.3801 19.6852 16.1052 19.5463 15.8625L15.8787 9.5124C15.8586 9.48149 15.8478 9.45192 15.8331 9.42504Z'
          fill='#CFC5FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.4256 18.2627C14.9004 19.7443 12.8577 20.573 10.7314 20.573C8.605 20.573 6.56236 19.7443 5.03711 18.2627H16.4256Z'
          fill='#977BFF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.63653 9.42769L2.79142 14.3546C2.28756 12.3035 2.59557 10.137 3.65113 8.30762C4.70669 6.47823 6.42818 5.12736 8.45611 4.53711L5.63385 9.425L5.63653 9.42769Z'
          fill='#977BFF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.6698 14.3465L15.8785 9.50833L15.8328 9.42097L13.0105 4.53711C15.0349 5.13022 16.7527 6.48083 17.8067 8.30806C18.8607 10.1353 19.1699 12.2985 18.6698 14.3478V14.3465Z'
          fill='#977BFF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.8786 9.51227L18.67 14.3504C18.3039 15.8402 17.5246 17.1964 16.4216 18.2626H5.03307C3.93284 17.1979 3.15459 15.8449 2.78735 14.3585L5.63381 9.42761L8.45607 4.53971C9.94544 4.11144 11.5253 4.11144 13.0147 4.53971L15.837 9.42357L15.8827 9.51093L15.8786 9.51227Z'
          fill='#FEFEFE'
        />
        <path
          d='M13.352 8.62001C13.3399 8.56759 13.3141 8.51931 13.2773 8.48011C13.2405 8.44092 13.1939 8.4122 13.1423 8.39691C13.0906 8.38124 13.0357 8.37944 12.9831 8.39171C12.9305 8.40397 12.882 8.42987 12.8426 8.4668L10.7273 10.4625L8.61191 8.4668C8.57248 8.42987 8.52403 8.40397 8.47142 8.39171C8.41881 8.37944 8.3639 8.38124 8.31221 8.39691C8.26064 8.4122 8.21406 8.44092 8.17722 8.48011C8.14038 8.51931 8.11462 8.56759 8.10256 8.62001L6.82716 14.0791C6.81221 14.1417 6.82027 14.2077 6.84987 14.2649C6.87947 14.3222 6.92865 14.3669 6.98843 14.3909L10.3872 15.6837C10.6036 15.7665 10.8429 15.7665 11.0592 15.6837L14.458 14.3909C14.5188 14.3678 14.5692 14.3235 14.5998 14.2662C14.6305 14.2089 14.6393 14.1424 14.6247 14.0791L13.352 8.62001ZM8.53262 9.13877L10.355 10.8496L7.52601 13.3695L8.53262 9.13877ZM10.7273 15.1999L7.60664 14.0213L10.7273 11.238L13.8425 14.0213L10.7273 15.1999ZM11.0995 10.8496L12.9219 9.13877L13.9245 13.3762L11.0995 10.8496Z'
          fill='#562AE6'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='21.4546' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AimylogicLogo = props => {
  const { width, height, logoUrl } = props;

  return logoUrl?.length > 1 ? (
    <img src={logoUrl} alt='aimylogic_logo' style={{ width: width, height: height }} />
  ) : (
    <svg style={{ width: width, height: height }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70.74 77.69'>
      <path
        d='M53.05,68.9,39.54,76.56a8.26,8.26,0,0,1-8.25,0L17.68,68.9,4.6,61.48A8,8,0,0,1,0,54.27V23.42H0A8,8,0,0,1,4.08,16.5l13.6-7.71L31.2,1.13a8.24,8.24,0,0,1,8.25,0l13.6,7.71,13.09,7.42a8,8,0,0,1,4.6,7.21V54.27h0a8,8,0,0,1-4.06,6.92Z'
        fill='#004d8e'
        fillRule='evenodd'
      />
      <path
        d='M52.19,30.51,40,9.74A5.21,5.21,0,0,0,38,7.79a5.32,5.32,0,0,0-7.2,1.88L18.58,30.51h0v0l-12,20.4a5.14,5.14,0,0,0-1,3,5.23,5.23,0,0,0,5.28,5.19h49v0a5.27,5.27,0,0,0,2.62-.69,5.11,5.11,0,0,0,1.94-7L52.35,30.8A2.69,2.69,0,0,0,52.19,30.51Z'
        fill='#89bbe6'
        fillRule='evenodd'
      />
      <path d='M54.14,59.12a27.32,27.32,0,0,1-37.54,0H54.14Z' fill='#097cbf' fillRule='evenodd' />
      <path
        d='M18.58,30.53,9.2,46.48a26.19,26.19,0,0,1-.78-6.36A26.56,26.56,0,0,1,27.88,14.69l-9.3,15.82h0Z'
        fill='#097cbf'
        fillRule='evenodd'
      />
      <path
        d='M61.55,46.45,52.35,30.8l-.16-.29L42.9,14.7A26.56,26.56,0,0,1,62.33,40.12,26,26,0,0,1,61.55,46.45Z'
        fill='#097cbf'
        fillRule='evenodd'
      />
      <path
        d='M52.35,30.8l9.2,15.65a26.22,26.22,0,0,1-7.41,12.67H16.6A26.39,26.39,0,0,1,9.2,46.48l9.38-15.95v0h0l9.3-15.82a27.51,27.51,0,0,1,15,0l9.29,15.81Z'
        fill='#fff'
        fillRule='evenodd'
      />
      <path
        d='M50.46,37.56a2.5,2.5,0,0,0,0-3.56l-5.41-5.42a2.53,2.53,0,0,0-3.57,0l-2.35,2.36a3.07,3.07,0,0,0-.29-.34l-7-7A2.5,2.5,0,0,0,30,22.84a2.46,2.46,0,0,0-1.78.74l-7,7a2.54,2.54,0,0,0,0,3.56l7,7a1.94,1.94,0,0,0,.31.26l-2.84,2.84a2.52,2.52,0,0,0,0,3.57L34,56.1h0a2.54,2.54,0,0,0,3.56,0l8.25-8.25a2.52,2.52,0,0,0,0-3.57l-1-1.05.31-.25,5.41-5.42ZM30,39.84h0a.44.44,0,0,1-.31-.13l-7-7a.43.43,0,0,1,0-.62l7-7a.44.44,0,0,1,.31-.13.46.46,0,0,1,.31.13l7,7a.46.46,0,0,1,0,.62L34.2,35.86A1.92,1.92,0,0,0,34,36l-3.76,3.75a.38.38,0,0,1-.18.05Zm14.28,6.53h0l-8.25,8.25a.44.44,0,0,1-.62,0l-8.24-8.25a.4.4,0,0,1-.13-.3.4.4,0,0,1,.13-.31l4.38-4.39a2.11,2.11,0,0,0,.23-.19l3.76-3.76a.49.49,0,0,1,.18,0,.48.48,0,0,1,.21,0,.64.64,0,0,0,.1.13L41.48,43a2.09,2.09,0,0,0,.29.23l2.55,2.55a.42.42,0,0,1,0,.61ZM37.54,36h0a.27.27,0,0,0-.08-.07.54.54,0,0,1,0-.19.4.4,0,0,1,.13-.31L43,30.06a.42.42,0,0,1,.31-.13.39.39,0,0,1,.3.13L49,35.47a.44.44,0,0,1,0,.62L43.57,41.5a.44.44,0,0,1-.3.13l-.17,0Z'
        fill='#004d8e'
        fillRule='evenodd'
      />
    </svg>
  );
};

export const JAICPWithBrandname = props => {
  const { width = 161, height = 48 } = props;
  return (
    <svg width={width} height={height} viewBox='0 0 161 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40.2445 10.0245L32.3421 5.48791L24.0002 0.658376C22.5365 -0.219459 20.6344 -0.219459 19.0242 0.658376L10.8289 5.34144L2.488 10.171C0.877835 11.0488 0 12.8054 0 14.5611V33.5854C0 35.4875 1.17077 37.0977 2.78093 37.9755L10.6834 42.6586L19.0252 47.3416C20.4889 48.2195 22.391 48.2195 24.0012 47.3416L32.1966 42.6586L40.5374 37.829C42.1476 36.9512 43.0254 35.341 43.0254 33.5854V14.4146C43.0245 12.5125 41.8537 10.9023 40.2445 10.0245ZM36.368 35.8334H32.8643C29.9157 38.6625 25.9216 40.4105 21.5132 40.4105C17.1038 40.4105 13.1097 38.6625 10.1611 35.8334H6.65747C4.17236 35.8334 2.61905 33.1431 3.86209 30.9914L5.60619 27.9705C5.28917 26.6966 5.10223 25.3717 5.10223 23.9995C5.10223 16.5028 10.1351 10.1931 17.0026 8.23126L18.7178 5.2605C19.9599 3.1088 23.0665 3.1088 24.3096 5.2605L26.0248 8.23126C32.8923 10.1931 37.9251 16.5018 37.9251 23.9995C37.9251 25.3717 37.7382 26.6966 37.4212 27.9705L39.1653 30.9914C40.4064 33.1431 38.8531 35.8334 36.368 35.8334Z'
        fill='#3D3DA5'
      />
      <path
        d='M26.0238 8.232L24.3086 5.26124C23.0666 3.10953 19.9599 3.10953 18.7169 5.26124L17.0017 8.232C18.4365 7.82247 19.9465 7.58928 21.5123 7.58928C23.0781 7.58928 24.589 7.82247 26.0238 8.232Z'
        fill='#ACACFC'
      />
      <path
        d='M5.60608 27.9707L3.86198 30.9916C2.6199 33.1433 4.17225 35.8336 6.65736 35.8336H10.161C7.96592 33.7272 6.36443 31.0186 5.60608 27.9707Z'
        fill='#ACACFC'
      />
      <path
        d='M37.4192 27.9707C36.6599 31.0186 35.0593 33.7272 32.8643 35.8336H36.3679C38.853 35.8336 40.4063 33.1433 39.1633 30.9916L37.4192 27.9707Z'
        fill='#ACACFC'
      />
      <path
        d='M5.10229 24.0007C5.10229 25.3728 5.28923 26.6978 5.60626 27.9717L17.0027 8.2334C10.1351 10.1933 5.10229 16.503 5.10229 24.0007Z'
        fill='#6262ED'
      />
      <path
        d='M21.5123 40.4106C25.9207 40.4106 29.9148 38.6626 32.8634 35.8335H10.1611C13.1097 38.6626 17.1038 40.4106 21.5123 40.4106Z'
        fill='#6262ED'
      />
      <path
        d='M37.9231 24.0007C37.9231 16.5039 32.8902 10.1943 26.0227 8.23242L37.4191 27.9707C37.7362 26.6968 37.9231 25.3719 37.9231 24.0007Z'
        fill='#6262ED'
      />
      <path
        d='M37.4193 27.9703L26.0238 8.23207C24.589 7.82255 23.0791 7.58936 21.5132 7.58936C19.9474 7.58936 18.4374 7.82255 17.0026 8.23207L5.6062 27.9703C6.36455 31.0182 7.96604 33.7268 10.1611 35.8333H32.8644C35.0594 33.7268 36.6609 31.0182 37.4193 27.9703Z'
        fill='white'
      />
      <path
        d='M22.525 21.1976L19.8452 19.6163C19.5648 19.4506 19.3933 19.15 19.3933 18.8252V15.6512C19.3933 15.3264 19.5648 15.0248 19.8452 14.86L22.525 13.2778C22.8131 13.1073 23.1716 13.1073 23.4597 13.2778L26.1394 14.86C26.4198 15.0258 26.5914 15.3264 26.5914 15.6512V18.8252C26.5914 19.15 26.4198 19.4516 26.1394 19.6163L23.4597 21.1976C23.1706 21.3682 22.8131 21.3682 22.525 21.1976ZM20.8127 18.6932L22.8545 19.8977C22.9393 19.9478 23.0434 19.9478 23.1282 19.8977L25.1701 18.6932C25.252 18.645 25.3021 18.5564 25.3021 18.461V16.0154C25.3021 15.92 25.252 15.8323 25.1701 15.7832L23.1282 14.5777C23.0434 14.5276 22.9393 14.5276 22.8545 14.5777L20.8127 15.7832C20.7308 15.8313 20.6807 15.92 20.6807 16.0154V18.461C20.6807 18.5564 20.7308 18.6441 20.8127 18.6932Z'
        fill='#3D3DA5'
      />
      <path
        d='M26.4978 27.1121L24.4483 25.9019C24.1679 25.7361 23.9963 25.4355 23.9963 25.1108V22.6806C23.9963 22.3558 24.1679 22.0542 24.4483 21.8895L26.4978 20.6792C26.7859 20.5086 27.1434 20.5086 27.4325 20.6792L29.483 21.8895C29.7634 22.0552 29.935 22.3558 29.935 22.6806V25.1108C29.935 25.4355 29.7634 25.7371 29.483 25.9019L27.4325 27.1121C27.1444 27.2817 26.7859 27.2817 26.4978 27.1121ZM25.4157 24.9787L26.8283 25.8123C26.9131 25.8624 27.0172 25.8624 27.102 25.8123L28.5146 24.9787C28.5965 24.9306 28.6466 24.8419 28.6466 24.7465V23.0438C28.6466 22.9485 28.5965 22.8608 28.5146 22.8116L27.102 21.9781C27.0172 21.928 26.9131 21.928 26.8283 21.9781L25.4157 22.8116C25.3338 22.8598 25.2837 22.9485 25.2837 23.0438V24.7465C25.2837 24.8419 25.3338 24.9296 25.4157 24.9787Z'
        fill='#3D3DA5'
      />
      <path
        d='M17.1529 31.6997L12.8186 29.1414C12.5382 28.9756 12.3667 28.675 12.3667 28.3503V23.223C12.3667 22.8982 12.5382 22.5966 12.8186 22.4319L17.1529 19.8735C17.441 19.703 17.7985 19.703 18.0876 19.8735L22.4218 22.4319C22.7022 22.5976 22.8737 22.8982 22.8737 23.223V28.3503C22.8737 28.675 22.7022 28.9766 22.4218 29.1414L18.0876 31.6997C17.7985 31.8693 17.441 31.8693 17.1529 31.6997ZM13.787 28.2173L17.4834 30.3989C17.5682 30.449 17.6722 30.449 17.757 30.3989L21.4534 28.2173C21.5353 28.1691 21.5854 28.0805 21.5854 27.9851V23.5863C21.5854 23.4909 21.5353 23.4032 21.4534 23.354L17.757 21.1724C17.6722 21.1223 17.5682 21.1223 17.4834 21.1724L13.787 23.354C13.7051 23.4022 13.655 23.4909 13.655 23.5863V27.9851C13.6541 28.0814 13.7051 28.1691 13.787 28.2173Z'
        fill='#3D3DA5'
      />
      <path
        d='M106.702 38.8718C105.797 38.8718 105.063 38.1385 105.063 37.2337V10.81C105.063 9.90517 105.797 9.17188 106.702 9.17188C107.606 9.17188 108.34 9.90517 108.34 10.81V37.2337C108.34 38.1375 107.606 38.8718 106.702 38.8718Z'
        fill='#fff'
      />
      <path
        d='M158.061 11.3885C156.53 10.0279 154.6 9.39968 152.805 9.2378C152.35 9.17998 151.903 9.17805 151.456 9.17131L150.228 9.16938L147.822 9.17709C146.219 9.18576 144.614 9.18865 143.011 9.16553C142.999 9.16553 142.981 9.16553 142.97 9.16553C142.946 9.16553 142.923 9.17227 142.9 9.17324C142.009 9.18865 141.291 9.91328 141.291 10.8085V37.2591C141.291 38.1639 142.024 38.8972 142.929 38.8972C143.834 38.8972 144.567 38.1639 144.567 37.2591V25.0253C145.85 25.0147 147.133 25.0109 148.416 25.0166L150.822 25.0244C151.582 25.0273 152.545 25.0378 153.436 24.9261C154.347 24.7979 155.279 24.5744 156.183 24.1639C157.087 23.7611 157.946 23.1309 158.625 22.3417C160.02 20.7364 160.456 18.6598 160.468 16.8328C160.472 14.8247 159.604 12.7405 158.061 11.3885ZM156.202 20.3239C155.529 21.2249 154.356 21.7597 153.009 21.9987C151.712 22.2126 150.002 22.1172 148.417 22.1471C147.134 22.1538 145.851 22.149 144.568 22.1394V12.0534C145.653 12.0476 146.739 12.0399 147.824 12.0457L150.23 12.0534C151.064 12.0544 151.783 12.0534 152.458 12.1633C153.835 12.3415 155.073 12.8667 155.917 13.6886C156.745 14.5183 157.204 15.6418 157.194 16.8425C157.2 18.1963 156.891 19.4413 156.202 20.3239Z'
        fill='#fff'
      />
      <path
        d='M133.497 17.0357C132.884 15.0353 131.203 13.3866 129.181 12.6312C127.182 11.8468 124.704 11.8892 122.672 12.661C120.626 13.4261 118.977 15.0064 117.971 17.0213C116.956 19.0304 116.477 21.369 116.444 23.7221C116.39 26.0877 116.751 28.4572 117.651 30.5212C118.541 32.591 120.081 34.2638 122.085 35.1234C124.058 35.9877 126.553 36.1506 128.644 35.5483C130.758 34.9615 132.57 33.5113 133.49 31.5581C133.852 30.7872 134.771 30.4567 135.542 30.819C136.313 31.1823 136.643 32.1006 136.281 32.8714C136.27 32.8946 136.259 32.9167 136.247 32.9389L136.227 32.9774C134.815 35.6081 132.275 37.6094 129.438 38.3591C128.03 38.759 126.556 38.8477 125.152 38.7879C123.734 38.7195 122.288 38.442 120.922 37.8783C119.556 37.3232 118.295 36.4714 117.249 35.4259C116.213 34.3708 115.376 33.1451 114.771 31.8423C113.535 29.2319 113.138 26.4009 113.174 23.6576C113.244 20.9026 113.805 18.0793 115.191 15.5441C116.531 13.0137 118.85 10.8254 121.656 9.86371C123.045 9.37228 124.501 9.16221 125.921 9.1651C127.357 9.14198 128.828 9.38095 130.213 9.89165C132.988 10.9102 135.354 13.1476 136.411 16.023L136.421 16.049C136.715 16.8488 136.304 17.7353 135.504 18.0292C134.705 18.3231 133.818 17.9126 133.524 17.1128C133.515 17.0907 133.505 17.0608 133.497 17.0357Z'
        fill='#fff'
      />
      <path
        d='M61.1506 34.8634C61.9273 35.4156 63.1077 35.642 64.3729 35.669C65.0725 35.6863 65.4964 35.6362 65.9753 35.4898C66.4311 35.3433 66.8368 35.1024 67.1933 34.7661C67.8939 34.081 68.4113 33.049 68.6541 31.8994C68.7746 31.3203 68.8334 30.7316 68.842 30.1139V27.9709V10.8266C68.842 9.92177 69.5753 9.18848 70.4801 9.18848C71.385 9.18848 72.1183 9.92177 72.1183 10.8266V27.9709V30.1139C72.1154 30.9243 72.0421 31.7636 71.8745 32.5845C71.517 34.213 70.7837 35.8983 69.3701 37.1645C68.6667 37.7889 67.7985 38.2688 66.885 38.5241C65.9936 38.7882 64.9703 38.8151 64.2447 38.7602C62.6847 38.6629 60.9059 38.3545 59.3824 37.1501C58.7561 36.6548 58.6501 35.7461 59.1454 35.1198C59.6243 34.5137 60.4906 34.3951 61.113 34.8355L61.1506 34.8634Z'
        fill='#fff'
      />
      <path
        d='M100.502 36.6652L89.7279 10.2415C89.4909 9.66043 88.9272 9.28174 88.3008 9.28174C87.6745 9.28174 87.1098 9.66139 86.8737 10.2415L76.0988 36.6652C75.777 37.4534 76.1557 38.3534 76.9439 38.6742C77.7312 38.9951 78.6321 38.6174 78.953 37.8292L81.9132 30.5685H94.6846L97.6458 37.8292C97.8895 38.4276 98.4658 38.7899 99.0738 38.7899C99.2675 38.7899 99.465 38.7523 99.6558 38.6752C100.445 38.3534 100.824 37.4534 100.502 36.6652ZM83.0926 27.6768L87.9058 15.8737C88.1139 15.309 88.2748 14.6663 88.2999 14.4408C88.3249 14.6663 88.4868 15.3129 88.6959 15.8785L93.5071 27.6768H83.0926Z'
        fill='#fff'
      />
    </svg>
  );
};
