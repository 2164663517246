import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { ButtonGroup } from 'reactstrap';
import { Button } from '@just-ai/just-ui/dist/Button';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { DropdownButton, DropdownToggle, DropdownMenu, DropdownItem } from '@just-ai/just-ui/dist/Dropdown';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { useNLUModuleContext } from '../../../../context';
import classes from './styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
export default function ApplyChangesButton({ needsTraining, }) {
    const { showNluWidget, trainNlu, trainingNlu } = useNLUModuleContext();
    const { t } = useTranslation();
    const buttonText = t(trainingNlu ? 'FAQ:Train:ApplyingChanges' : 'FAQ:Train:ApplyTest');
    if (!trainNlu)
        return null;
    return (_jsx("div", { className: cn('faqtemplate__apply-changes', classes.faqTemplatesTabs__applyBtn), children: _jsxs(ButtonGroup, { className: 'combo-btn', "aria-disabled": trainingNlu, children: [_jsxs(Button, { size: 'sm', color: 'primary', disabled: trainingNlu, outline: !needsTraining, onClick: () => trainNlu(true), children: [trainingNlu && _jsx(Spinner, { size: 'sm', color: 'primary', style: { position: 'static' } }), buttonText] }), _jsxs(DropdownButton, { direction: 'down', className: cn({ outline: !needsTraining }), tabIndex: -1, size: 'sm', disabled: trainingNlu, children: [_jsx(DropdownToggle, { color: 'primary', iconButton: true, size: 'sm', disabled: trainingNlu, outline: !needsTraining, children: _jsx(Icon, { className: 'btn-icon', name: 'faCaretDown', size: 'sm' }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onClick: () => trainNlu(), children: t('FAQ:Train:ApplyChanges') }), _jsxs(DropdownItem, { onClick: showNluWidget, children: [" ", t('FAQ:Train:OpenWidget')] })] })] })] }) }));
}
