import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { TActivationParameters } from 'modules/JGraph/utils/types';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { saveEvent, restoreState } from 'reducers/JGraph.reducer/index';
import { findScreenByPathId, findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

type SaveEventAsyncPayload = {
  value: string;
  isGlobal: boolean;
  tagParameters: TActivationParameters;
  targetState?: JStateWithId;
};
const saveEventAsync = createAsyncThunk('JGraph/saveEventAsync', async (data: SaveEventAsyncPayload, thunkAPI) => {
  thunkAPI.dispatch(saveEvent(data));
  const state = thunkAPI.getState() as RootState;
  const storeScreens = state.JGraphReducer.graph.blocks;
  const editMenuScreen = data.targetState || state.JGraphReducer.editMenuBlock?.screen;
  const screenToSave = findScreenByPathId(editMenuScreen!.pathId, storeScreens);
  if (screenToSave) {
    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () =>
        thunkAPI.dispatch(updateState(data.targetState ? { ...screenToSave, useSelfPathValue: true } : screenToSave)),
    });
  }
});

const revertSaveEventAsync = createAsyncThunk(
  'JGraph/revertSaveEventAsync',
  async (revertEvent: RevertEvent<SaveEventAsyncPayload>, thunkAPI) => {
    const editMenuScreen = revertEvent.payload.targetState || revertEvent.prevState.JGraphReducer.editMenuBlock?.screen;
    if (!editMenuScreen) return;
    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevScreen = findScreenByPath(editMenuScreen.path, prevScreens);
    if (!prevScreen) return;
    thunkAPI.dispatch(restoreState({ screen: prevScreen }));

    mainSave$.next({
      type: 'update',
      path: prevScreen.path,
      action: () => thunkAPI.dispatch(updateState(prevScreen)),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertSaveEventAsync, saveEventAsync);
