var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import FreeRegularSVGIcons from './IconPackMaps/FreeRegularSVGIcons';
import ProLightSVGIcons from './IconPackMaps/ProLightSVGIcons';
import ProRegularSVGIcons from './IconPackMaps/ProRegularSVGIcons';
import ProDuotoneSVGIcons from './IconPackMaps/ProDuotoneSVGIcons';
import ProSolidSVGIcons from './IconPackMaps/ProSolidSVGIcons';
import CustomIcons from './IconPackMaps/Custom';
import './Icon.scss';
import { useIsMounted } from '../utils/useIsMounted';
export const iconPacks = [
    FreeRegularSVGIcons,
    ProLightSVGIcons,
    ProRegularSVGIcons,
    ProDuotoneSVGIcons,
    ProSolidSVGIcons,
    CustomIcons,
];
const preloadedIconMap = {};
export function registerPreloadedIcons(iconNames) {
    iconNames.forEach(iconName => {
        const pack = iconPacks.find(pack => iconName in pack.iconsNameMap);
        const importFn = pack === null || pack === void 0 ? void 0 : pack.iconsNameMap[iconName];
        if (!importFn)
            return;
        return importFn().then(icon => {
            if (!icon)
                return;
            preloadedIconMap[iconName] = icon;
        });
    });
}
export const Icon = (_a) => {
    var { name, color = 'none', size, className, wrapperClassName, id } = _a, restProps = __rest(_a, ["name", "color", "size", "className", "wrapperClassName", "id"]);
    const [icon, setIcon] = useState();
    const isMounted = useIsMounted();
    useEffect(() => {
        const preloadedIcon = preloadedIconMap[name];
        if (preloadedIcon) {
            setIcon(preloadedIcon);
            return;
        }
        const pack = iconPacks.find(pack => name in pack.iconsNameMap);
        const importFn = pack === null || pack === void 0 ? void 0 : pack.iconsNameMap[name];
        if (!importFn)
            return;
        importFn().then(e => {
            if (isMounted)
                setIcon(e);
        });
    }, [name, isMounted]);
    return (_jsx("span", { id: id, className: classNames('justui__icon-wrapper', wrapperClassName), children: (icon === null || icon === void 0 ? void 0 : icon.definition) && (_jsx(FAIcon, Object.assign({ icon: icon.definition, size: size === 'md' || size === 'xl' || size === 'xxl' ? undefined : size, className: classNames(className, 'justui_icon', {
                'fa-color-primary': color === 'primary',
                'fa-color-info': color === 'info',
                'fa-color-secondary': color === 'secondary',
                'fa-color-success': color === 'success',
                'fa-color-warning': color === 'warning',
                'fa-color-danger': color === 'danger',
            }) }, restProps))) }));
};
