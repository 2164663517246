import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import SelectIntentModal, {
  SelectIntentModalProps,
} from '../../../modules/Caila/pages/LogLabelingPage/SelectIntentModal';
import { useError, useLoading } from '../../../modules/Caila/utils';
import { AppContext } from '../../../modules/Caila/components/AppContext';
import localize from '../../../localization';

const EditIntentsModal: FunctionComponent<SelectIntentModalProps> = ({
  hide = () => {},
  phrase = '',
  ...restProps
}) => {
  const [isLoading, load] = useLoading();
  const [errorMessage, setError, clearError] = useError();
  const { IntentsApi, accountId, projectShortName } = useContext(AppContext);
  const [newPhrase, setNewPhrase] = useState<string>(phrase);

  useEffect(() => {
    setNewPhrase(phrase);
  }, [phrase]);

  const onChangePhrase = (phrase: string) => {
    setNewPhrase(phrase);
  };

  const addPhrases = (intentId: number) => {
    load(IntentsApi.getIntent(accountId, projectShortName, intentId))
      .then(response => {
        const intentData = response.data;
        return IntentsApi.recursiveUpdate(accountId, projectShortName, intentId, {
          ...intentData,
          phrases: [...(intentData.phrases || []), { text: newPhrase }],
        });
      })
      .then(() => {
        clearError();
        hide();
      })
      .catch(setError);
  };

  return (
    <div style={{ position: 'relative' }}>
      <SelectIntentModal
        hide={hide}
        phrase={newPhrase}
        onSuccess={addPhrases}
        loading={isLoading}
        onChangePhrase={onChangePhrase}
        showPhraseEdit
        error={errorMessage}
        disabledAddButton={!newPhrase}
        {...restProps}
      />
    </div>
  );
};

export function EditIntentsModalForReporter({
  isShown,
  toggle,
  phrase,
}: {
  isShown: boolean;
  toggle: () => void;
  phrase: string;
}) {
  return (
    <EditIntentsModal
      isShown={isShown}
      hide={toggle}
      titleText={localize.translate('EditIntentsModal Choose Intent')}
      cancelButtonText={localize.translate('EditIntentsModal Close')}
      addButtonText={localize.translate('EditIntentsModal button ok text')}
      phrase={phrase}
      phraseText={localize.translate('EditIntentsModal Phrase')}
    />
  );
}

export default EditIntentsModal;
