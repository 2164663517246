import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
export function useDebounceFn(fn, time = 800) {
    const fnRef = useRef(fn);
    const [debouncedFn, setDebouncedFn] = useState(() => debounce((...args) => fnRef.current(...args), time));
    fnRef.current = fn;
    useEffect(() => {
        setDebouncedFn((prev) => {
            prev === null || prev === void 0 ? void 0 : prev.cancel();
            return debounce((...args) => fnRef.current(...args), time);
        });
    }, [time]);
    return debouncedFn;
}
