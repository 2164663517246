// @file checkLocalizationScript:disabled

import { t } from 'localization';
import { ProviderList } from 'modules/TestTtsWidget/api/client';

type TTSContent = { term: string; description: React.ReactNode; example: string };

export function isProviderHaveDocContent(providerKey: ProviderList) {
  return !!getTtsProvidersContent()[providerKey]?.rows.length;
}

export const getTtsProvidersContent = (): Record<ProviderList, { rows: TTSContent[]; docLink: string }> => ({
  [ProviderList.AMIVOICE]: { docLink: '', rows: [] },
  [ProviderList.ASM]: { docLink: '', rows: [] },
  [ProviderList.KALDI]: { docLink: '', rows: [] },
  [ProviderList.MTS]: { docLink: '', rows: [] },
  [ProviderList.REVERIE]: { docLink: '', rows: [] },
  [ProviderList.TINKOFF]: { docLink: '', rows: [] },
  [ProviderList.CAILA]: { docLink: '', rows: [] },
  [ProviderList.ZITECH]: {
    rows: [
      {
        term: 'speak',
        description: 'Корневой тег, используемый для обрамления текста.',
        example: '<speak>Вместе легче преодолеть любые трудности.</speak>',
      },
      {
        term: 'break',
        description: 'Добавление в речь паузы заданной длительности. Длительность указывается с помощью атрибута time.',
        example: '<speak>Держите себя в руках, <break time="1s"/> перестаньте петь! </speak>',
      },
      {
        term: 's',
        description: 'Добавление пауз между предложениями.',
        example: '<speak> <s>Нет, нет, нет!</s> <s>Ни слова больше!</s> </speak>',
      },
      {
        term: 'p',
        description: 'Добавление паузы между абзацами. Пауза после абзаца больше, чем пауза после предложения.',
        example:
          '<speak> <p>Бор на противоположном берегу реки помутнел, размазался и растворился.</p> <p>Вода сплошной пеленой валила за окном.</p> </speak>',
      },
      {
        term: 'sub',
        description:
          'Подмена текста на другой текст при произношении. Например, чтобы правильно произнести аббревиатуру. В атрибуте alias указывается подменяемый текст.',
        example:
          '<speak>Иван не ответил, <sub alias="так как">т.к.</sub> счел это приветствие в данных условиях неуместным.</speak>',
      },
      {
        term: 'audio',
        description: 'Использование предзаписи в аудио. Id записи указывается с помощью атрибута id.',
        example: '<speak>Здравствуйте!<audio id="29b908de-1f92-4951-8a9e-4b33e576560e"></speak>',
      },
    ],
    docLink: 'https://3i-vox.ru/documentation/synthesis-markup',
  },
  [ProviderList.AIMYVOICE]: {
    rows: [
      {
        term: '+',
        description: 'Указывает на ударную гласную в слове. Символ ставится после ударной гласной.',
        example: 'На амбаре висел большо+й замо+к',
      },
      {
        term: '<break>',
        description: 'Добавляет в текст паузу, может синтезировать дыхание',
        example:
          'Мой дядя <break time="1s"/> самых честных правил. <break time="0.8s" breath="1" breath_power="0.8" breath_dura="1.2"/>',
      },
      {
        term: '<emphasis>',
        description: 'Задает место фразового ударения в предложении. Можно использовать символ #',
        example: 'Вы <emphasis>сегодня</emphasis> подписываете договор? #Вы сегодня подписываете договор?',
      },
      {
        term: '<p>',
        description: 'Добавляет паузу, по длительности равную паузе между абзацами.',
        example: '<p>В тёмно-синем лесу</p> <p>Где трепещут осины</p>',
      },
      {
        term: '<prosody>',
        description: 'Задает громкость, темп и тон речи.',
        example:
          'Голос будет <prosody volume="175">звучать громче.</prosody> ' +
          'Речь будет <prosody rate="140">произноситься быстрее.</prosody> ' +
          'Речь будет <prosody rate="75">произноситься медленнее.</prosody> ' +
          '<prosody pitch="125"> Тон голоса будет повышен.</prosody>',
      },
      {
        term: '<s>',
        description: 'Добавляет паузу, по длительности равную паузе после точки.',
        example: '<s>Нет я не плачу</s> <s>И не рыдаю</s>',
      },
      {
        term: '<say-as>',
        description: 'Задает словесное ударение и другие правила озвучивания текста.',
        example:
          '<say-as interpret-as="date" format="ydm">20212102</say-as> <say-as interpret-as="telephone">89123456789</say-as>',
      },
      {
        term: '<style>',
        description: (
          <span>
            Изменяет манеру (стиль) речи диктора. Полный набор стилей доступен в{' '}
            <a target='_blank' href='https://help.cloud.just-ai.com/aimyvoice/use-voice/ssml#style' rel='noreferrer'>
              документации
            </a>
            .
          </span>
        ),
        example: '<style name="exilant">Сегодня пятница, значит впереди долгожданные выходные!</style>',
      },
      {
        term: '<sub>',
        description: 'Заменяет звучание слова на другое.',
        example: 'Дни и ночи, у <sub alias="мартэновских">мартеновских</sub> печей.',
      },
    ],
    docLink: 'https://help.cloud.just-ai.com/aimyvoice/use-voice/ssml',
  },
  [ProviderList.YANDEX]: {
    rows: [
      {
        term: '<break>',
        description: 'Используйте тег <break>, чтобы добавить в речь паузу заданной длительности.',
        example: 'Эй, секундочку<break time="1s"/> Что вы делаете?',
      },
      {
        term: '<s>',
        description: 'Используйте тег <s>, чтобы добавить паузу между предложениями.',
        example: '<s>Первое предложение</s> <s>Второе предложение</s>',
      },
      {
        term: '<p>',
        description: 'Используйте тег <p>, чтобы добавить паузу между абзацами.',
        example: '<p>В тёмно-синем лесу</p> <p>Где трепещут осины</p>',
      },
      {
        term: '<sub>',
        description: 'Используйте тег <sub>, чтобы подменить текст на другой текст при произношении.',
        example: 'Мой любимый химический элемент — <sub alias="ртуть">Hg</sub>, потому что блестит.',
      },
      {
        term: '<phoneme>',
        description:
          'Используйте тег <phoneme>, чтобы контролировать правильность произношения с помощью фонем. Для воспроизведения будет использован текст, указанный в атрибуте ph. В атрибуте alphabet укажите используемый стандарт: ipa или x-sampa.',
        example:
          'В разных регионах России по-разному произносят букву\n' +
          '<phoneme alphabet="ipa" ph="o">О</phoneme> в словах.\n' +
          'Где-то говорят <phoneme alphabet="ipa" ph="məlɐko">молоко</phoneme>,\n' +
          'а где-то <phoneme alphabet="ipa" ph="mələko">молоко</phoneme>.',
      },
    ],
    docLink: 'https://cloud.yandex.ru/docs/speechkit/tts/markup/ssml',
  },
  [ProviderList.SBER]: {
    rows: [
      {
        term: "'",
        description: 'Указывает на ударную гласную в слове. Символ ставится после ударной гласной.',
        example: 'На амбаре висел большо’й замо’к.',
      },
      {
        term: '<break>',
        description: 'Добавляет в текст паузу.',
        example:
          'Привет!\n' +
          '<break time="200ms" />\n' +
          'Как прошел день?\n' +
          '<break strength="weak" />\n' +
          'Все хорошо?',
      },
      {
        term: '<emphasis>',
        description: 'Задает место фразового ударения в предложении. Можно использовать символ *',
        example: 'Вы <emphasis>сегодня</emphasis> подписываете договор? *Вы сегодня подписываете договор?',
      },
      {
        term: '<say-as>',
        description: 'Задает словесное ударение и другие правила озвучивания текста.',
        example:
          '<say-as interpret-as="date" format="ydm">20212102</say-as>\n' +
          '<say-as interpret-as="telephone">89123456789</say-as>',
      },
      {
        term: '<sub>',
        description: 'Заменяет звучание слова на другое.',
        example: 'Дни и ночи, у <sub alias="мартэновских">мартеновских</sub> печей.',
      },
      {
        term: '<paint>',
        description: 'Управление тоном, интонацией, скоростью и громкостью',
        example: '<paint pitch="5" speed="4">высоко и быстро, <paint loudness="5">а еще и громко</paint> </paint>',
      },
      {
        term: '<extra.background-audio>',
        description: (
          <span>
            Добавление фонового звука из библиотеки звуков{' '}
            <a
              target='_blank'
              href='https://developers.sber.ru/docs/ru/va/how-to/conversation/sound-library'
              rel='noreferrer'
            >
              https://developers.sber.ru/docs/ru/va/how-to/conversation/sound-library
            </a>
          </span>
        ),
        example:
          '<extra.background-audio src="sm-sounds-nature-rain-2">Ну и погодка! Добрый хозяин собаку из дома не выгонит </extra.background-audio>',
      },
      {
        term: '<extra.fulldate>',
        description: 'Полное произнесение года',
        example: '<extra.fulldate>Акция Черная пятница начнётся 23.11.23 и продлится три дня</extra.fulldate>',
      },
    ],
    docLink: 'https://developers.sber.ru/docs/ru/salutespeech/synthesis/ssml',
  },
  [ProviderList.GOOGLE]: {
    rows: [
      {
        term: '<break>',
        description: 'An empty element that controls pausing or other prosodic boundaries between words.',
        example:
          'Step 1, take a deep breath. <break time="200ms"/>\n' +
          'Step 2, exhale.\n' +
          'Step 3, take a deep breath again. <break strength="weak"/>\n' +
          'Step 4, exhale.',
      },
      {
        term: '<say‑as>',
        description:
          'This element lets you indicate information about the type of text construct that is contained within the element. It also helps specify the level of detail for rendering the contained text.',
        example:
          "<say-as interpret-as='currency' language='en-US'>$42.01</say-as>\n" +
          '<say-as interpret-as="date" format="yyyymmdd" detail="1">1960-09-10</say-as>',
      },
      {
        term: '<p>,<s>',
        description: 'Sentence and paragraph elements.',
        example: '<p>\n<s>This is sentence one.</s>\n<s>This is sentence two.</s>\n</p>',
      },
      {
        term: '<sub>',
        description:
          'Indicate that the text in the alias attribute value replaces the contained text for pronunciation.',
        example: '<sub alias="World Wide Web Consortium">W3C</sub>',
      },
      {
        term: '<prosody>',
        description: 'Used to customize the pitch, speaking rate, and volume of text contained by the element.',
        example: '<prosody rate="slow" pitch="-2st">Can you hear me now?</prosody>',
      },
      {
        term: '<emphasis>',
        description: 'Used to add or remove emphasis from text contained by the element.',
        example: '<emphasis level="moderate">This is an important announcement</emphasis>',
      },
      {
        term: '<voice>',
        description: 'The <voice> tag allows you to use more than one voice in a single SSML request.',
        example:
          'And then she asked, <voice language="fr-FR" gender="female">qu\'est-ce qui\n' +
          't\'amène ici</voice><break time="250ms"/> in her sweet and gentle voice.',
      },
      {
        term: '<lang>',
        description: 'You can use <lang> to include text in multiple languages within the same SSML request.',
        example: 'The french word for cat is <lang xml:lang="fr-FR">chat</lang>',
      },
      {
        term: '<phoneme>',
        description: (
          <span>
            You can use the {'<phoneme>'} tag to produce custom pronunciations of words inline. Text-to-Speech accepts
            the{' '}
            <a target='_blank' href='https://en.wikipedia.org/wiki/International_Phonetic_Alphabet' rel='noreferrer'>
              IPA
            </a>{' '}
            and{' '}
            <a target='_blank' href='https://en.wikipedia.org/wiki/X-SAMPA' rel='noreferrer'>
              X-SAMPA
            </a>{' '}
            phonetic alphabets. See the{' '}
            <a target='_blank' href='https://cloud.google.com/text-to-speech/docs/phonemes' rel='noreferrer'>
              phonemes page
            </a>{' '}
            for a list of supported languages and phonemes.
          </span>
        ),
        example:
          '<phoneme alphabet="ipa" ph="ˌmænɪˈtoʊbə">manitoba</phoneme>\n' +
          '<phoneme alphabet="x-sampa" ph=\'m@"hA:g@%ni:\'>mahogany</phoneme>',
      },
    ],
    docLink: 'https://cloud.google.com/text-to-speech/docs/ssml',
  },
  [ProviderList.AZURE]: {
    rows: [
      {
        term: '<break>',
        description: 'Use the break element to override the default behavior of breaks or pauses between words.',
        example:
          'Step 1, take a deep breath. <break time="200ms"/>\n' +
          'Step 2, exhale.\n' +
          'Step 3, take a deep breath again. <break strength="weak"/>\n' +
          'Step 4, exhale.',
      },
      {
        term: '<mstts:silence>',
        description:
          'Use the mstts:silence element to insert pauses before or after text, or between two adjacent sentences.\n' +
          'One of the differences between mstts:silence and break is that a break element can be inserted anywhere in the text. Silence only works at the beginning or end of input text or at the boundary of two adjacent sentences.',
        example:
          '<mstts:silence type="Sentenceboundary" value="200ms"/>\n' +
          "If we're home schooling, the best we can do is roll with what each day brings and try to have fun along the way.",
      },
      {
        term: '<p>,<s>',
        description: 'Sentence and paragraph elements.',
        example: '<p>\n<s>This is sentence one.</s>\n<s>This is sentence two.</s>\n</p>',
      },
      {
        term: '<phoneme>',
        description:
          'You can use the <phoneme> tag to produce custom pronunciations of words inline. Text-to-Speech accepts the <a target="_blank" href="https://en.wikipedia.org/wiki/International_Phonetic_Alphabet">IPA</a>, <a target="_blank" href="https://en.wikipedia.org/wiki/X-SAMPA">X-SAMPA</a>,  <a target="_blank" href="https://documentation.help/Microsoft-Speech-Platform-SDK-11/17509a49-cae7-41f5-b61d-07beaae872ea.htm">Universal Phone Set</a> and <a target="_blank" href="https://learn.microsoft.com/en-us/azure/ai-services/speech-service/speech-ssml-phonetic-sets#map-x-sampa-to-ipa">SSML phonetic alphabets</a> phonetic alphabets. See the <a target="_blank" href="https://cloud.google.com/text-to-speech/docs/phonemes">phonemes page</a> for a list of supported languages and phonemes.',
        example:
          '<phoneme alphabet="ipa" ph="ˌmænɪˈtoʊbə">manitoba</phoneme>\n' +
          '<phoneme alphabet="x-sampa" ph=\'m@"hA:g@%ni:\'>mahogany</phoneme>',
      },
      {
        term: '<emphasis>',
        description: 'Used to add or remove emphasis from text contained by the element.',
        example: '<emphasis level="moderate">This is an important announcement</emphasis>',
      },
      {
        term: '<prosody>',
        description: 'Used to customize the pitch, speaking rate, and volume of text contained by the element.',
        example: '<prosody rate="slow" pitch="-2st">Can you hear me now?</prosody>',
      },
      {
        term: '<mstts:express-as>',
        description: 'You can adjust the speaking style, style degree, and role at the sentence level.',
        example:
          '<mstts:express-as style="cheerful" styledegree="2">\n' + "That'd be just amazing!\n" + '</mstts:express-as>',
      },
      {
        term: '<say‑as>',
        description:
          'This element lets you indicate information about the type of text construct that is contained within the element. It also helps specify the level of detail for rendering the contained text.',
        example:
          "<say-as interpret-as='currency' language='en-US'>$42.01</say-as>\n" +
          '<say-as interpret-as="date" format="yyyymmdd" detail="1">1960-09-10</say-as>',
      },
      {
        term: '<sub>',
        description:
          'Indicate that the text in the alias attribute value replaces the contained text for pronunciation.',
        example: '<sub alias="World Wide Web Consortium">W3C</sub>',
      },
      {
        term: '<voice>',
        description: 'The <voice> tag allows you to use more than one voice in a single SSML request.',
        example:
          'And then she asked, <voice language="fr-FR" gender="female">qu\'est-ce qui\n' +
          't\'amène ici</voice><break time="250ms"/> in her sweet and gentle voice.',
      },
      {
        term: '<lang>',
        description: 'You can use <lang> to include text in multiple languages within the same SSML request.',
        example: 'The french word for cat is <lang xml:lang="fr-FR">chat</lang>',
      },
    ],
    docLink: 'https://learn.microsoft.com/en-us/azure/ai-services/speech-service/speech-synthesis-markup',
  },
});
