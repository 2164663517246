import React, { Fragment } from 'react';
import localize from '../../../localization';
import { SwitchButton } from '@just-ai/just-ui';
import isAccess from '../../../isAccessFunction';
import InputMask from 'react-input-mask';
import { isNumber } from 'lodash';

export function OtherSettings({ toggleCleanup, cleanupAvailable, editableProject, createRef }) {
  return (
    <Fragment>
      {isAccess(['dialogs.cleanup']) && (
        <div className='form-group row'>
          <label className='col-md-4 form-control-label' htmlFor='accessToken'>
            {localize.translate('Cleanup dialogs')}
          </label>
          <div className='col-md-8'>
            <SwitchButton
              id='cleanupDialogs'
              data-test-id='projectsPage.createProjectModal.cleanup.checkboxLabel'
              label=''
              onChange={toggleCleanup}
              value={cleanupAvailable}
            />
          </div>
        </div>
      )}
      {isAccess(['dialogs.cleanup']) && cleanupAvailable && (
        <div className='form-group row'>
          <label className='col-md-4 form-control-label' htmlFor='accessToken'>
            {localize.translate('Cleanup period')}
          </label>
          <div className='col-md-8'>
            <InputMask
              mask='999'
              maskChar=' '
              defaultValue={
                editableProject && isNumber(editableProject.cleanupOlderThenDays)
                  ? editableProject.cleanupOlderThenDays
                  : 30
              }
              className='form-control'
              inputRef={createRef}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}
