import React, { FC, useCallback, useState } from 'react';
import { produce } from 'immer';
import { withController } from './Controller';
import { Button, useDebounceFn } from '@just-ai/just-ui';
import { v4 as uuid } from 'uuid';
import { t } from 'localization';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';
import { ParameterItem } from './ParameterItem.field';
import CustomTagWarningBlock from './CustomTagWarningBlock';

type TNameValueArrayItem = { name: string; value: string };

const createValueFromString = (str: string) => {
  try {
    let returnValue = [];
    const obj = JSON.parse(str);
    for (const [key, value] of Object.entries(obj)) {
      returnValue.push({
        key: uuid(),
        value: {
          name: key,
          value: value,
        },
      });
    }
    return returnValue;
  } catch (e) {
    return [
      {
        key: uuid(),
        value: {
          name: '',
          value: '',
        },
      },
    ];
  }
};
const createValueFromObj = (obj: Record<string, string>) => {
  try {
    let returnValue = [];
    for (const [key, value] of Object.entries(obj)) {
      returnValue.push({
        key: uuid(),
        value: {
          name: key,
          value: value,
        },
      });
    }
    return returnValue;
  } catch (e) {
    return [
      {
        key: uuid(),
        value: {
          name: '',
          value: '',
        },
      },
    ];
  }
};

const prepareValues = (value: string) => {
  return (typeof value === 'string' ? createValueFromString(value) : createValueFromObj(value)) as {
    key: string;
    value: TNameValueArrayItem;
  }[];
};

const Parameters: FC<CommonFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  description,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  const [innerValues, setInnerValues] = useState(prepareValues(value));

  const triggerOnChange = useDebounceFn(
    useCallback(() => {
      const value1 = innerValues
        .map(value => value.value || { name: '', value: '' })
        .reduce((acc, val) => {
          acc[val.name] = val.value;
          return acc;
        }, {} as Record<string, string>);
      onChange(value1);
    }, [innerValues, onChange]),
    300
  );

  const onClickAdd = useCallback(() => {
    setInnerValues(innerValues => {
      innerValues.push({
        key: uuid(),
        value: {
          name: '',
          value: '',
        },
      });
      return [...innerValues];
    });
    triggerOnChange();
  }, [triggerOnChange]);

  const onChangeItem = useCallback(
    (index: number, field: keyof TNameValueArrayItem, value: string) => {
      setInnerValues(innerValues =>
        produce(innerValues, draft => {
          draft[index].value[field] = value;
        })
      );
      triggerOnChange();
    },
    [triggerOnChange]
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      setInnerValues(innerValues => {
        innerValues.splice(index, 1);
        return [...innerValues];
      });
      triggerOnChange();
    },
    [triggerOnChange]
  );

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      {innerValues.map((innerValue, index) => (
        <ParameterItem
          key={innerValue.key}
          onRemove={onRemoveItem}
          index={index}
          onChange={onChangeItem}
          value={innerValue.value}
        />
      ))}
      <div>
        <Button color='primary' iconLeft='farPlus' iconRight='' flat onClick={onClickAdd} size='md'>
          {t('CustomTags:StringArray:AddField')}
        </Button>
      </div>
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const ParametersField = withController(Parameters);
