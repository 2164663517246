import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  targetNodeSelector: string;
  children: React.ReactNode;
}
const Portal = ({ children, targetNodeSelector }: PortalProps) => {
  const element = document.querySelector(targetNodeSelector);
  if (!element) return null;
  return ReactDOM.createPortal(children, element);
};

export default Portal;
