var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProjectApi } from '@just-ai/api/dist/generated/Editorbe';
import { FAQIntentService } from './FAQIntentService';
import { Intents } from '../nlu-related/utils';
import { INITIAL_REPLIES } from '../constants/faqTemplates';
class FAQApiService {
    constructor(accountId, projectShortName, IntentsApiInstance, faqApi, axios) {
        this.intentsApi = IntentsApiInstance;
        this.faqApi = faqApi;
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.projectApi = new ProjectApi({}, FAQApiService.BASE_PATH, axios);
    }
    createFAQIntent(templateTitle, templateType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!templateType.includes('empty_template')) {
                return yield this.faqApi.importFAQFromTemplate(this.accountId, this.projectShortName, templateType);
            }
            const listIntentsResponse = yield this.intentsApi.listIntents(this.accountId, this.projectShortName);
            const newFAQIntents = FAQIntentService.createNewFAQ(listIntentsResponse.data, templateTitle);
            const rootIntent = newFAQIntents[0];
            const firstQuestion = {
                intent: newFAQIntents[1],
                replies: INITIAL_REPLIES,
            };
            //корневой интент - через интенты, первый пустой вопрос - через faqapi
            const createFAQResponse = yield Promise.all([
                this.intentsApi.createIntent(this.accountId, this.projectShortName, rootIntent),
                this.faqApi.createFaqQuestion(this.accountId, this.projectShortName, firstQuestion),
            ]);
            return createFAQResponse;
        });
    }
    createFAQQuestion(intentList, templateName) {
        return __awaiter(this, void 0, void 0, function* () {
            const newQuestionIntent = FAQIntentService.createNewQuestion(intentList, templateName);
            const createFAQResponse = yield this.faqApi.createFaqQuestion(this.accountId, this.projectShortName, {
                intent: newQuestionIntent,
                replies: INITIAL_REPLIES,
            });
            return createFAQResponse;
        });
    }
    createFAQGroup(intentList, templateName, categoryName, enabled) {
        return __awaiter(this, void 0, void 0, function* () {
            const newGroupIntent = FAQIntentService.createNewGroup(intentList, templateName, categoryName);
            if (enabled !== undefined) {
                newGroupIntent.enabled = enabled;
            }
            const createIntentResult = yield this.intentsApi.createIntent(this.accountId, this.projectShortName, newGroupIntent);
            return createIntentResult.data;
        });
    }
    deleteQuestion(intentIds, templateName) {
        return __awaiter(this, void 0, void 0, function* () {
            const deleteQuestionResponse = yield this.intentsApi.deleteIntents(this.accountId, this.projectShortName, intentIds);
            return deleteQuestionResponse;
        });
    }
    deleteFAQSkill(intentId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.intentsApi.recursiveDelete(this.accountId, this.projectShortName, intentId);
        });
    }
    updateFAQIntent(intent_1) {
        return __awaiter(this, arguments, void 0, function* (intent, needNormalize = true, questionData, softValidate, options) {
            if (needNormalize) {
                intent = FAQIntentService.normalizeIntentLikeFAQQuestion(intent);
            }
            if (!intent.id)
                return;
            if (!questionData || !questionData.id) {
                return this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, intent.id, intent, undefined, softValidate, options);
            }
            return this.faqApi.updateFaqQuestion(this.accountId, this.projectShortName, questionData.id, questionData, softValidate, options);
        });
    }
    updateFAQIntents(intents) {
        return __awaiter(this, void 0, void 0, function* () {
            const updateIntentsArr = intents.map(intent => ({
                intent,
                intentId: intent.id,
            }));
            return this.intentsApi.recursiveUpdateMultipleIntents(this.accountId, this.projectShortName, updateIntentsArr);
        });
    }
    createFaqQuestions(questions) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.allSettled(questions.map(question => this.faqApi.createFaqQuestion(this.accountId, this.projectShortName, question)));
        });
    }
    onCreateFolderWithNodes(templateName, groupName, intents) {
        return __awaiter(this, void 0, void 0, function* () {
            if (intents.length === 0)
                return null;
            const groupPath = `${Intents.generateFAQPathByTemplateName(templateName)}/${groupName}`;
            const { data: newGroupIntent } = yield this.intentsApi.createIntent(this.accountId, this.projectShortName, {
                path: groupPath,
            });
            let addedIntentNames = [];
            for (let intent of intents) {
                const intentName = Intents.getNameFromPath(intent.path);
                let name = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                let newIntent = Object.assign(intent, { path: `${groupPath}/${name}` });
                newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                if (!newIntent.id)
                    continue;
                yield this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent);
                addedIntentNames.push(name);
            }
            return newGroupIntent;
        });
    }
    moveQuestionInFolder(folderIntent, existedChildrenNames, intents) {
        return __awaiter(this, void 0, void 0, function* () {
            if (intents.length === 0 || !folderIntent.path)
                return null;
            let addedIntentNames = existedChildrenNames;
            for (let intent of intents) {
                if (intent.path && FAQIntentService.isIntentChildrenForPath(folderIntent.path, intent.path)) {
                    continue;
                }
                const intentName = Intents.getNameFromPath(intent.path);
                let name = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                let newIntent = Object.assign(intent, { path: `${folderIntent.path}/${name}` });
                newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                if (!newIntent.id)
                    continue;
                yield this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent);
                addedIntentNames.push(name);
            }
            return null;
        });
    }
    moveQuestionInRoot(folderPath, existedChildrenNames, intents) {
        return __awaiter(this, void 0, void 0, function* () {
            if (intents.length === 0 || !folderPath)
                return null;
            let addedIntentNames = existedChildrenNames;
            for (let intent of intents) {
                if (intent.path && FAQIntentService.isIntentChildrenForPath(folderPath, intent.path))
                    continue;
                const intentName = Intents.getNameFromPath(intent.path);
                let name = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                let newIntent = Object.assign(intent, { path: `${folderPath}/${name}` });
                newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                if (!newIntent.id)
                    continue;
                yield this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent);
                addedIntentNames.push(name);
            }
            return null;
        });
    }
    loadFAQTemplates(language) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data: templates } = yield this.faqApi.getFAQTemplates(this.accountId, this.projectShortName, language);
            return templates.map((template) => {
                var _a, _b;
                const [type, lang] = (_b = (_a = template.id) === null || _a === void 0 ? void 0 : _a.split(/_([^_]+$)/).filter(Boolean)) !== null && _b !== void 0 ? _b : [];
                return Object.assign(Object.assign({}, template), { type, lang });
            });
        });
    }
    uploadFAQTemplateFile(file_1) {
        return __awaiter(this, arguments, void 0, function* (file, ignoreError = false) {
            const { data: faqName } = yield this.faqApi.importFAQFromFile(this.accountId, this.projectShortName, ignoreError, file);
            return faqName;
        });
    }
    exportFAQ(exportIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data: exportedFile } = yield this.faqApi.exportFAQ(this.accountId, this.projectShortName, exportIds, {
                responseType: 'blob',
            });
            return window.URL.createObjectURL(exportedFile);
        });
    }
    importFAQ(faqName_1, file_1) {
        return __awaiter(this, arguments, void 0, function* (faqName, file, ignoreError = false) {
            yield this.faqApi.importFAQFromFileToExistingFAQ(this.accountId, this.projectShortName, ignoreError, faqName, file);
        });
    }
    projectHasFaqState() {
        return __awaiter(this, void 0, void 0, function* () {
            const faqTags = {
                tagName: 'intentGroup',
                includeGlobal: true,
            };
            const { data: tags } = yield this.projectApi.getTagsList(this.accountId, this.projectShortName, faqTags);
            for (const tag of tags) {
                if (tag.tagName.includes('intentGroup') && tag.tagValue && tag.tagValue.startsWith('/KnowledgeBase')) {
                    return true;
                }
            }
            return false;
        });
    }
    activateFaqInScenario() {
        return __awaiter(this, void 0, void 0, function* () {
            const existingData = yield this.projectHasFaqState();
            const newState = {
                state: {
                    value: existingData ? 'KnowledgeBaseHandler' : 'KnowledgeBase',
                    blocks: [
                        {
                            tagName: 'intentGroup!',
                            tagValue: '/KnowledgeBase',
                        },
                        {
                            tagName: 'script',
                            tagValue: '$faq.pushReplies();',
                        },
                    ],
                },
            };
            const { data: result } = yield this.projectApi.createRootState(this.accountId, this.projectShortName, newState);
            return result;
        });
    }
}
FAQApiService.BASE_PATH = '';
export default FAQApiService;
