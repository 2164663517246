import React, { useState, useCallback } from 'react';
import { InputText, Button } from '@just-ai/just-ui';

import { t } from 'localization';

import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';

import styles from './styles.module.scss';

interface StartStateCreationMenuInterface {
  onCreate: (title: string) => void;
}
const StartStateCreationMenu = function ({ onCreate }: StartStateCreationMenuInterface) {
  const [title, setTitle] = useState('');

  const onCreateInner = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onCreate(title.trim());
    },
    [onCreate, title]
  );

  const { errorText, isInvalid, triggerValidation } = useNewStateNameValidation();

  const onChange = useCallback(
    (name: string) => {
      setTitle(name);
      triggerValidation(name, '/');
    },
    [triggerValidation]
  );

  return (
    <form className={styles.StartStateCreationMenu} onSubmit={onCreateInner}>
      <div className={styles.header}>
        <div className={styles.title}>{t('CreationScreenMenu:title')}</div>
        <div>
          <InputText value={title} onChange={onChange} autoFocus />
        </div>
        {errorText && <span style={{ color: 'var(--red-500)' }}>{errorText}</span>}
      </div>
      <div className={styles.footer}>
        <Button color='primary' type='submit' disabled={!title.trim() || isInvalid}>
          {t('CreationScreenMenu:CreateState')}
        </Button>
      </div>
    </form>
  );
};

export default React.memo(StartStateCreationMenu);
