import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FileUploadContent from './FileUploadContent';
import { FormText, IconButton } from '../../index';
import cn from 'classnames';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function FileTab(props) {
    const { t } = useTranslation();
    const { type, localFile, isLoading, handleAudioChange, localChange, error, isWhiteLabel } = props;
    return (_jsxs("div", { children: [_jsx(FileUploadContent, { saveAudioToServer: props.saveAudioToServer, type: type, localFile: localFile, isLoading: isLoading, localChange: localChange, handleAudioChange: handleAudioChange }), type === AnswerReplyType.audio && _jsx(FormText, { children: t(`AnswerModal:subtext_${type}`) }), type === AnswerReplyType.image && localFile && (_jsxs("div", { className: 'faq-anwer-modal__img-preview', children: [_jsx("img", { src: URL.createObjectURL(localFile), alt: '' }), _jsx(IconButton, { size: 'sm', type: 'button', name: 'farTimes', color: 'none', onClick: () => localChange(undefined) })] })), _jsx("p", { className: cn('mt-2', { 'links-disabled': isWhiteLabel() }), dangerouslySetInnerHTML: {
                    __html: t(`AnswerModal:info_${type}`, t(`AnswerModal:info_link_${type}`)),
                } }), isLoading && (_jsx(FormText, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.CloseWarning', children: t('AnswerModal:close_warning') })), error && (_jsx(FormText, { color: 'danger', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.Error', children: error }))] }));
}
