import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { IconButton, useToggle, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { TIPS_MIN } from '../../../../constants/faqTemplates';
import classes from './styles.module.scss';
const HelpPopup = ({ handleClose }) => {
    const [isCollapsed, , , toggleIsCollapsed] = useToggle(window.localStorage.getItem(TIPS_MIN) === 'true');
    const { t } = useTranslation();
    useEffect(() => {
        window.localStorage.setItem(TIPS_MIN, String(isCollapsed));
    }, [isCollapsed]);
    return (_jsxs("div", { className: classes.helpPopup, children: [_jsxs("div", { className: classes.helpPopup__header, children: [_jsx("h4", { className: classes.helpPopup__title, children: t('FAQ:help popup title') }), _jsxs("div", { className: classes.helpPopup__control, children: [_jsx(IconButton, { name: isCollapsed ? 'farWindowMaximize' : 'farWindowMinimize', className: classes.button, size: 'sm', onClick: toggleIsCollapsed }), _jsx(IconButton, { className: classes.button, name: 'farTimes', size: 'sm', onClick: handleClose })] })] }), _jsx("div", { className: cn(classes.helpPopup__content, isCollapsed && classes.helpPopup__collapsed), children: _jsxs("ul", { children: [_jsx("li", { children: t(`FAQ:helpPopupQuestionMassSelection`) }), _jsx("li", { children: t(`FAQ:helpPopupQuestionSeparateSelection`) }), _jsx("li", { children: t(`FAQ:helpPopupDragQuestion`) }), _jsx("li", { children: t(`FAQ:helpPopupUniteQuestionsInGroups`) }), _jsx("li", { children: t(`FAQ:helpPopupSeeAvailableActions`) })] }) })] }));
};
export default React.memo(HelpPopup);
