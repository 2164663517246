var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CustomCollapse } from '../CustomCollapse';
import classes from './DropDownHint.module.scss';
import cn from 'classnames';
export const DropDownHint = (_a) => {
    var { dropDownHint, chevronPosition = 'right' } = _a, otherProps = __rest(_a, ["dropDownHint", "chevronPosition"]);
    return (_jsx(CustomCollapse, Object.assign({}, otherProps, { chevronPosition: chevronPosition, title: dropDownHint.title, wrapperClassName: cn('margin-top-4', classes.DropDownHintWrapper), className: classes.DropDownHint, headerClassName: classes.DropDownHint_Header, headerOpenClassName: classes.DropDownHint_HeaderOpen, collapseClassName: classes.DropDownHint_Collapse, children: _jsx("p", { className: classes.DropDownHint_Content, dangerouslySetInnerHTML: { __html: dropDownHint.body } }) })));
};
