import React, { Component } from 'react';
import localize from '../../../localization';
import { getCurrentDate, getFilterFromLocation } from '../../../pipes/functions';
import * as DiagramActions from '../../../actions/diagramAnalytics.actions';
import moment from 'moment';
import ChartComponent from './PieChart';
import CommonChartControl from './CommonChartControl';
import { isEqual, forEach, max, difference } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isReporterEnabled } from '../../../isAccessFunction';
import { AnalyticsContext } from '@just-ai/analytic-front';
import { convertDatesToX } from '../utils';
import { colorsForLabels } from '../../../reducers/analyticsFilter.reducer';

class SessionMarksDiagram extends Component {
  static contextType = AnalyticsContext;

  state = {
    graphCount: false,
    shouldUpdate: false,
    labelsColors: [],
  };

  componentWillMount() {
    this.setState({
      labelsColors: this.props.withReporter ? this.context.labelsContext.sessionLabelColors : this.props.labelsColors,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { projectShortName } = this.props.match.params;
    const { initDiagramSession } = this.props.actions;
    const params = getFilterFromLocation(location.search);
    let nextParams = getFilterFromLocation(nextProps.location.search);

    if (!isEqual(params, nextParams)) {
      if (!!nextParams.dateFrom) {
        nextParams.dateFrom = moment(nextParams.dateFrom).format('x');
      }
      if (!!nextParams.dateTo) {
        nextParams.dateTo = moment(nextParams.dateTo).format('x');
      }
      if (isReporterEnabled() && Object.keys(params).length === 0) {
        nextParams.dateFrom = getCurrentDate()[0];
        nextParams = convertDatesToX(params);
      }

      if (this.state.graphCount) {
        initDiagramSession(
          projectShortName,
          nextParams,
          isReporterEnabled() ? () => this.context.getSessionLabelsWithNewFilters({ filters: nextProps.filters }) : null
        );
      }
    }
    if (this.props.withReporter) {
      this.setState({ labelsColors: this.context.labelsContext.sessionLabelColors });
    }

    if (!this.props.withReporter && difference(nextProps.labelsColors, this.props.labelsColors).length > 0) {
      this.setState({
        labelsColors: nextProps.labelsColors,
      });
    }
  }

  graphCountChanged = () => {
    const { projectShortName } = this.props.match.params;
    const { initDiagramSession } = this.props.actions;
    const { location, filters } = this.props;

    if (this.graphCount.checked) {
      const params = getFilterFromLocation(location.search);
      if (!!params.dateFrom) {
        params.dateFrom = moment(params.dateFrom).format('x');
      }
      if (!!params.dateTo) {
        params.dateTo = moment(params.dateTo).format('x');
      }

      delete params.activeTab;

      const action = isReporterEnabled()
        ? () => this.context.getSessionLabelsStats(location, { filters: filters })
        : null;

      initDiagramSession(projectShortName, params, action).then(() => {
        this.setState({
          graphCount: this.graphCount.checked,
        });
      });
    } else {
      this.setState({
        graphCount: this.graphCount.checked,
      });
    }
  };

  toggleCommonSessionLabels = (name, flag) => {
    let newlabelsColors = [...this.state.labelsColors];
    const indexPhrase = newlabelsColors.findIndex(item => {
      return item.name === name;
    });
    if (indexPhrase > -1) {
      newlabelsColors[indexPhrase].active = flag;
    }

    const setter = async () => {
      await this.setState({
        labelsColors: newlabelsColors,
        shouldUpdate: true,
      });
    };

    setter().then(() => {
      this.setState({
        shouldUpdate: false,
      });
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    /* eslint-disable */
    return (
      (!nextProps.sessionFetching && nextProps.sessionFetching !== this.props.sessionFetching) ||
      !this.state.graphCount ||
      this.state.shouldUpdate ||
      this.state.graphCount !== nextState.graphCount
    );
    /* eslint-disable */
  }

  render() {
    const { labelsColors } = this.state;
    const { labelCountData, labelTimeData } = this.props;

    let __labelCountData = null;
    let sessionPercentages = [];
    if (!!labelCountData) {
      __labelCountData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      forEach(labelCountData.labels, (labelsCountItem, key) => {
        let labelsCountItemColor = labelsColors.find(item => item.name === labelsCountItem);
        if (!!labelsCountItemColor) {
          if (labelsCountItemColor.active) {
            __labelCountData.labels.push(labelsCountItem);
            __labelCountData.datasets[0].data.push(labelCountData.datasets[0].data[key]);
            __labelCountData.datasets[0].backgroundColor.push(labelsCountItemColor.color);
          }
          if (labelCountData.datasets[0].data[key] > 0) {
            sessionPercentages.push({
              name: labelsCountItem,
              data: labelCountData.datasets[0].data[key],
            });
          }
        } else {
          __labelCountData.labels.push(labelsCountItem);
          __labelCountData.datasets[0].data.push(labelCountData.datasets[0].data[key]);
          __labelCountData.datasets[0].backgroundColor.push(colorsForLabels[key]);
          if (labelCountData.datasets[0].data[key] > 0) {
            sessionPercentages.push({
              name: labelsCountItem,
              data: labelCountData.datasets[0].data[key],
            });
          }
        }
      });
    }

    let __labelTimeData = null;
    let maxData = [];
    if (!!labelTimeData) {
      __labelTimeData = {
        labels: labelTimeData.labels,
        datasets: [],
      };
      forEach(labelTimeData.datasets, (labelsTimeItem, index) => {
        let labelsTimeItemColor = labelsColors.find(item => item.name === labelsTimeItem.label);
        if (!!labelsTimeItemColor && labelsTimeItemColor.active) {
          maxData = this.props.sumArray(labelsTimeItem.data, maxData);

          __labelTimeData.datasets.push({
            ...labelsTimeItem,
            backgroundColor: labelsTimeItemColor.color,
          });
        }
      });
    }

    let filteredSessionColors = labelsColors.filter(item => {
      return !!sessionPercentages.find(perc => {
        return perc.name === item.name;
      });
    });

    return (
      <div className='mb-3'>
        <label className='switch switch-3d switch-xs switch-success'>
          <input
            type='checkbox'
            className='switch-input'
            ref={input => (this.graphCount = input)}
            onChange={this.graphCountChanged}
            defaultChecked={false}
            id={'sessionDiagramsLabels'}
          />
          <span className='switch-label' />
          <span className='switch-handle' />
        </label>{' '}
        <label htmlFor={'sessionDiagramsLabels'}>{localize.translate('Session marks')}</label>
        {this.state.graphCount ? (
          <div className='row'>
            <div className='col-8 dialogs-session-data-chart'>
              <ChartComponent
                chartData={__labelTimeData}
                config={{
                  type: 'bar',
                  data: __labelTimeData,
                  options: {
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontSize: 10,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            stepSize: max(maxData) < 10 ? 1 : null,
                            fontSize: 10,
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: false,
                      custom: this.props.customChart(),
                      mode: 'x',
                    },
                  },
                }}
              />
            </div>
            <div className='col-2 dialogs-session-data-chart'>
              <ChartComponent
                chartData={__labelCountData}
                config={{
                  data: __labelCountData,
                  options: {
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                      enabled: false,
                      custom: this.props.customChart(),
                    },
                  },
                }}
              />
            </div>
            <div className='col-2'>
              <Scrollbars
                renderThumbVertical={props => <div {...props} className='vertical-scroll_chart' />}
                renderThumbHorizontal={props => <div {...props} className='horizontal-scroll_chart' />}
                renderView={props => (
                  <div
                    {...props}
                    style={{ overflowX: 'auto' }}
                    className='chartWrapper chartWrapper_common-controls chartWrapper_scrollable'
                  />
                )}
              >
                <div className='form-inline'>
                  {filteredSessionColors.map((labelColor, index) => (
                    <CommonChartControl
                      key={`labelColor${index}`}
                      percentages={sessionPercentages}
                      {...labelColor}
                      toggleCommonChartLabels={this.toggleCommonSessionLabels}
                    />
                  ))}
                </div>
              </Scrollbars>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionFetching: state.DiagramAnalyticsReducer.sessionFetching,

    labelCountData: state.DiagramAnalyticsReducer.labelCountData,
    labelTimeData: state.DiagramAnalyticsReducer.labelTimeData,

    labelsColors: state.AnalyticsFilterReducer.labelsColors,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DiagramActions, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionMarksDiagram));
