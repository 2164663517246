var _a;
import { Intents } from '../nlu-related/utils';
import '../localization';
import { t } from '../localization';
import { FAQBaseIntentName, FAQBaseIntentNamePrefix, FAQIntentBasePath, FAQIntentBaseQuestion, FAQIntentBaseGroup, FAQIntentRootGroup, } from '../constants/faqTemplates';
export var FAQIntentPathType;
(function (FAQIntentPathType) {
    FAQIntentPathType[FAQIntentPathType["Base"] = 0] = "Base";
    FAQIntentPathType[FAQIntentPathType["TemplateName"] = 1] = "TemplateName";
    FAQIntentPathType[FAQIntentPathType["Group"] = 2] = "Group";
    FAQIntentPathType[FAQIntentPathType["Question"] = 3] = "Question";
})(FAQIntentPathType || (FAQIntentPathType = {}));
export class FAQIntentService {
    static generateFAQTemplateName(name) {
        return `${FAQBaseIntentNamePrefix}.${name}`;
    }
    static getIntentDepth(path) {
        return path.split('/').filter(Boolean).length;
    }
    static isNormalizedFAQQuestionIntent(newIntent) {
        const intentName = Intents.getNameFromPath(newIntent === null || newIntent === void 0 ? void 0 : newIntent.path);
        if (!newIntent.phrases || newIntent.phrases.length === 0) {
            return false;
        }
        return !!newIntent.phrases.find(phrase => phrase.text === intentName);
    }
    static normalizeIntentLikeFAQQuestion(newIntent, oldIntent) {
        const oldIntentName = Intents.getNameFromPath(oldIntent === null || oldIntent === void 0 ? void 0 : oldIntent.path);
        const intentQuestion = Intents.getNameFromPath(newIntent.path);
        const basePhrase = { text: intentQuestion };
        if (!newIntent.phrases || newIntent.phrases.length === 0) {
            return Object.assign(Object.assign({}, newIntent), { phrases: [basePhrase] });
        }
        const existedPhrase = newIntent.phrases.find(phrase => phrase.text === intentQuestion);
        if (!existedPhrase) {
            const phrases = [...newIntent.phrases, basePhrase];
            const index = phrases.findIndex(el => el.text === oldIntentName);
            if (index !== -1 && oldIntentName !== intentQuestion) {
                phrases.splice(index, 1);
            }
            newIntent = Object.assign(Object.assign({}, newIntent), { phrases });
        }
        return newIntent;
    }
    static generateNewQuestionName(intentsList, templateName) {
        const startTemplatePathForChildren = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
        const topChildren = intentsList
            .filter(intent => {
            const parentPath = Intents.getParentPathFromPath(intent.path);
            return parentPath === startTemplatePathForChildren || parentPath === `${startTemplatePathForChildren}/Root`;
        })
            .map(intent => Intents.getNameFromPath(intent.path));
        return this.generateNewUniqNameWithIndex(topChildren, FAQIntentBaseQuestion);
    }
    static createNewQuestion(intentsList, templateName) {
        const newQuestionName = this.generateNewQuestionName(intentsList, templateName);
        let newIntent = {
            path: FAQIntentPathBuilder.initWithTemplateName(templateName)
                .addRootGroup()
                .addQuestion(newQuestionName)
                .getPath(),
        };
        newIntent = this.normalizeIntentLikeFAQQuestion(newIntent);
        return newIntent;
    }
    static createNewGroup(intentsList, templateName, groupName) {
        const newGroupName = this.normalizeNewGroupName(intentsList, templateName, groupName);
        let newIntent = {
            path: FAQIntentPathBuilder.initWithTemplateName(templateName).addGroup(newGroupName).getPath(),
        };
        return newIntent;
    }
    static getTreeByIntentName(intentsList, intentName) {
        return intentsList
            .filter(intent => { var _b; return (_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(`/${FAQBaseIntentName}/${intentName}/`); })
            .map(intent => {
            var _b;
            return (Object.assign(Object.assign({}, intent), { path: (_b = intent.path) === null || _b === void 0 ? void 0 : _b.replace(`/KnowledgeBase/${intentName}`, '') }));
        })
            .filter(intent => intent.path);
    }
    static getTopChildrenIntentsByParentPath(parentPath, intentsList) {
        return intentsList
            .filter(intent => { var _b; return (_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(parentPath); })
            .map(intent => {
            var _b, _c;
            const parts = (_c = (_b = intent.path) === null || _b === void 0 ? void 0 : _b.split('/').filter(Boolean)) !== null && _c !== void 0 ? _c : [];
            return parts.length === 1 ? parts[0] : '';
        })
            .filter(name => !!name);
    }
    static generateNewUniqNameWithIndex(existedNames, baseName, initStep = 2) {
        let newName = baseName;
        let step = initStep;
        while (existedNames.includes(newName)) {
            newName = `${baseName} ${step++}`;
        }
        return newName;
    }
    static parseTemplatesNameForIntents(intents) {
        const templates = intents.reduce((acc, intent) => {
            if (!intent.path)
                return acc;
            const templateName = Intents.getFAQTemplateName(intent.path);
            if (templateName && templateName.startsWith(FAQBaseIntentNamePrefix)) {
                acc.add(templateName);
            }
            return acc;
        }, new Set());
        return Array.from(templates);
    }
    static getTemplatePath(templateName) {
        return `/${FAQBaseIntentName}/${templateName}`;
    }
    static generateRootPath(templateName) {
        return `${_a.getTemplatePath(templateName)}/${FAQIntentRootGroup}`;
    }
    static isNodeFolder(path) {
        return (path !== `/${FAQIntentRootGroup}` &&
            !(path === null || path === void 0 ? void 0 : path.startsWith(`/${FAQIntentRootGroup}/`)) &&
            _a.getIntentDepth(path || '') === 1);
    }
    static isInRoot(path) {
        return !!(path === null || path === void 0 ? void 0 : path.startsWith(`/${FAQIntentRootGroup}/`));
    }
    static transformToFAQTree(intents) {
        const intentsDataset = Intents.mapIntentsToTree(intents);
        const treeDataset = {};
        for (let nodeId in intentsDataset) {
            const node = intentsDataset[nodeId];
            treeDataset[nodeId] = Object.assign(Object.assign({}, node), { isFolder: _a.isNodeFolder(node.path), isInRoot: _a.isInRoot(node.path) });
        }
        return treeDataset;
    }
    static switchIntentsEnablement(intents, enabled = false) {
        return intents.map(intent => (Object.assign(Object.assign({}, intent), { enabled })));
    }
    static areAllIntentsDisabled(intents) {
        return !intents.find(intent => intent.enabled);
    }
    static filterIntentsByTemplateName(intents, templateName, excludeRootIntent = false) {
        const path = FAQIntentPathBuilder.initWithTemplateName(templateName).add('/').getPath();
        const filteredIntents = intents.filter(intent => { var _b; return (_b = intent === null || intent === void 0 ? void 0 : intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path); });
        if (!excludeRootIntent)
            return filteredIntents;
        const rootPath = FAQIntentPathBuilder.init(path).addRootGroup().getPath();
        return filteredIntents.filter(intent => (intent === null || intent === void 0 ? void 0 : intent.path) !== rootPath);
    }
    static findClosestNode(nodes, nodesToMeetIds) {
        let newSelectedNode = undefined;
        let nodeWasFound = false;
        nodes.some(node => {
            if (nodesToMeetIds.find(id => id === node.id)) {
                if (!newSelectedNode) {
                    nodeWasFound = true;
                    return false;
                }
                return true;
            }
            if (node.isFolder)
                return false;
            if (nodeWasFound) {
                newSelectedNode = node;
                return true;
            }
            newSelectedNode = node;
            return false;
        });
        return newSelectedNode;
    }
}
_a = FAQIntentService;
FAQIntentService.getFAQIntentPathIndexRegExp = (initPath) => new RegExp(`^${initPath}\\s*(\\d*)`);
FAQIntentService.findFAQIntent = (intentsList, path, strict) => {
    return intentsList.find(intent => { var _b; return (strict ? intent.path === path : !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path))); });
};
FAQIntentService.isIntentChildrenForPath = (parentPath, childrenPath) => {
    if (!parentPath.endsWith('/')) {
        parentPath = parentPath + '/';
    }
    return childrenPath.startsWith(parentPath);
};
FAQIntentService.filterFAQIntents = (intentsList, path = FAQIntentBasePath) => {
    return intentsList.filter(intent => { var _b; return !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path)); });
};
FAQIntentService.getFAQIntentByName = (intentsList, templateName, strict) => {
    const intentPath = FAQIntentPathBuilder.init().addTemplateName(templateName).getPath();
    return _a.findFAQIntent(intentsList, intentPath, strict);
};
FAQIntentService.getNewFAQIntentPath = (intentsList, baseName) => {
    const existingPathes = intentsList
        .filter(intent => intent.path)
        .map(intent => _a.getTemplateTitleFromPath(intent.path));
    const templateName = _a.generateNewUniqNameWithIndex(existingPathes, baseName, 1);
    const templateIntentPath = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
    const questionIntentPath = FAQIntentPathBuilder.initBaseQuestionIntent(templateName).getPath();
    return [templateIntentPath, questionIntentPath];
};
FAQIntentService.createNewFAQ = (intentsList, templateTitle) => {
    const [templateIntentPath, questionIntentPath] = _a.getNewFAQIntentPath(intentsList, templateTitle);
    return [
        { path: templateIntentPath },
        {
            path: questionIntentPath,
            phrases: [{ text: FAQIntentBaseQuestion }],
        },
    ];
};
FAQIntentService.getTemplateTitleFromPath = (path) => {
    if (!path)
        return '';
    const childrenPath = path.replace(FAQIntentBasePath, '');
    return childrenPath.indexOf('/') === -1 ? childrenPath : childrenPath.substring(0, childrenPath.indexOf('/'));
};
FAQIntentService.getIntentType = (path) => {
    const question = FAQIntentPathParser.getQuestion(path);
    if (question)
        return FAQIntentPathType['Question'];
    const group = FAQIntentPathParser.getGroup(path);
    if (group)
        return FAQIntentPathType['Group'];
    const template = FAQIntentPathParser.getTemplateName(path);
    if (template)
        return FAQIntentPathType['TemplateName'];
    return FAQIntentPathType['Base'];
};
FAQIntentService.getLelevelNameFromPath = (path, parentPath = FAQIntentBasePath) => {
    if (!path)
        return '';
    const childrenPath = path.replace(parentPath, '');
    return childrenPath.substring(0, childrenPath.indexOf('/'));
};
FAQIntentService.normalizeNewGroupName = (intentsList, templateName, groupName = FAQIntentBaseGroup) => {
    const baseIntentPath = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
    const groupNames = intentsList
        .filter(intent => { var _b; return !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(baseIntentPath)); })
        .map(intent => FAQIntentPathParser.getGroup(intent.path));
    return _a.generateNewUniqNameWithIndex(groupNames, groupName);
};
export class FAQIntentPathParser {
    static getPathPart(path = undefined, pathPartName) {
        if (!path)
            return undefined;
        const pathParts = path.split('/').filter(Boolean);
        if (!pathParts.length || pathParts.length - 1 < FAQIntentPathType[pathPartName])
            return undefined;
        return pathParts[FAQIntentPathType[pathPartName]];
    }
    static getBase(path) {
        return this.getPathPart(path, 'Base');
    }
    static getTemplateName(path) {
        return this.getPathPart(path, 'TemplateName');
    }
    static getGroup(path) {
        return this.getPathPart(path, 'Group');
    }
    static getQuestion(path) {
        return this.getPathPart(path, 'Question');
    }
}
export class FAQIntentPathBuilder {
    static init(initPath = FAQIntentBasePath) {
        this.path = initPath;
        return this;
    }
    static initBaseQuestionIntent(templateName) {
        this.path = this.init().addTemplateName(templateName).addRootGroup().addQuestion().getPath();
        return this;
    }
    static initWithTemplateName(templateName = FAQIntentBasePath) {
        this.path = this.init().addTemplateName(templateName).getPath();
        return this;
    }
    static add(path) {
        this.path += path;
        return this;
    }
    static addTemplateName(templateName) {
        this.path += templateName;
        return this;
    }
    static resetPath() {
        this.path = null;
        return this;
    }
    static getPath() {
        const pathToReturn = this.path;
        this.resetPath();
        return pathToReturn || '';
    }
    static addIndex(index = 0) {
        if (index !== 0) {
            this.path += ` ${index}`;
        }
        return this;
    }
    static addGroup(group = FAQIntentBaseGroup) {
        this.path += `/${group}`;
        return this;
    }
    static addRootGroup() {
        return this.addGroup(FAQIntentRootGroup);
    }
    static addQuestion(question = t('FAQ:Intent:BaseQuestionName')) {
        this.path += `/${question}`;
        return this;
    }
}
FAQIntentPathBuilder.path = null;
