import { axios } from '../../../pipes/functions';
import { FilterKey, FiltersApi, FiltersDto } from '../api';

export default class FiltersService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private language: string;
  private configsApi: FiltersApi;

  constructor(accountId: number, language: string) {
    this.accountId = accountId;
    this.language = language;
    this.configsApi = new FiltersApi(undefined, FiltersService.BASE_PATH, axios);
  }

  getAvailableFilters = async () => {
    const { data } = await this.configsApi.getAvailableFilters(this.accountId, this.language);
    return data;
  };

  getFilterOptions = async (filterKey: FilterKey, page: number, size: number, filtersDto: FiltersDto) => {
    const { accountId, language } = this;
    const { data } = await this.configsApi.getFilterOptions(accountId, filterKey, language, page, size, filtersDto);
    return data;
  };
}
