import React, { FunctionComponent, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactDOM, { unstable_batchedUpdates } from 'react-dom';
import { Popover, IconButton, Tabs, Button, Modal, Locale, useToggle } from '@just-ai/just-ui';
import classNames from 'classnames';
import 'react-perfect-scrollbar/dist/css/styles.css';
import localize from '../../../../localization/index';
import {
  toSortedByDateObject,
  filterByTypeAndProject,
  getTypeByNotification,
  NotificationData,
} from '../../utils/notificaions';
import '../styles.scss';
import { NotificationsArea } from './NotificationsArea';
import NotificationLog from './NotificationLog';
import {
  useNotifications,
  MARK_SEEN_PATH,
  Notifications_RemoteChangesStore$,
} from '../../context/NotificationsContext';
import { useAppContext } from '../../../Caila/components/AppContext';
import { markHovered, markHoveredAll } from '../../utils';
import { notificationsLocalization } from '../../localization/notifications.loc';
import { Subject } from 'rxjs';

localize.addTranslations(notificationsLocalization);

interface NotificationsListProps {
  locale: Locale;
}

export const NotificationsListErrorModalLogData$ = new Subject<string>();

const NotificationsList: FunctionComponent<NotificationsListProps> = React.memo(({ locale }) => {
  const [isOpen, open, close] = useToggle();
  const [activeTab, setActiveTab] = useState('all');
  const { notifications, setNotifications, wsContext } = useNotifications();
  const { projectShortName } = useAppContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [hasHotNotification, setHasHotNotification] = useState(false);
  const hiddenInput = useRef<HTMLTextAreaElement>(null);
  const [isOpenModal, openModal, closeModal] = useToggle();
  const [copyData, setCopyData] = useState<any>({});
  const [activeNotifications, setActiveNotifications] = useState(() =>
    toSortedByDateObject(filterByTypeAndProject(notifications, activeTab, projectShortName))
  );
  const notificationsListDOM = useRef<HTMLElement | null>(null);
  useEffect(() => {
    notificationsListDOM.current = document.getElementById('notifications-list');
  }, []);

  const TAB_VALUES = [
    { name: localize.translate('notifications tabs all'), value: 'all', dataTestId: 'Notifications.Tabs.All' },
    { name: localize.translate('notifications tabs errors'), value: 'error', dataTestId: 'Notifications.Tabs.Errors' },
    {
      name: localize.translate('notifications tabs warnings'),
      value: 'warn',
      dataTestId: 'Notifications.Tabs.Warnings',
    },
    { name: localize.translate('notifications tabs info'), value: 'info', dataTestId: 'Notifications.Tabs.Info' },
  ];

  const deleteNotification = (index?: number, id?: string) => {
    setTimeout(() => {
      if (typeof index === 'undefined') return;
      markSeen(String(id));
      const newNotifications = [...notifications];
      newNotifications.splice(index, 1);
      setNotifications(newNotifications);
    }, 0);
  };

  const markSeen = (notificationsToDelete: string | string[]) => {
    wsContext.send(MARK_SEEN_PATH, {
      notifications: typeof notificationsToDelete === 'string' ? [notificationsToDelete] : notificationsToDelete,
    });
  };

  const onClose = () => {
    close();
    markHoveredAll(notifications, setNotifications, projectShortName);
  };

  const deleteAll = () => {
    const notificationsToDelete: string[] = [];
    const newNotifications: any[] = [];
    notifications.forEach(n => {
      if (projectShortName === n.projectId && (activeTab === 'all' || getTypeByNotification(n) === activeTab)) {
        notificationsToDelete.push(n.id);
      } else {
        newNotifications.push(n);
      }
    });
    setNotifications(newNotifications);
    markSeen(notificationsToDelete);
    close();
  };

  const copyLogs = useCallback(() => {
    if (hiddenInput && hiddenInput.current && document.queryCommandSupported('copy')) {
      hiddenInput.current.value = copyData;
      hiddenInput.current.select();
      hiddenInput.current.focus();
      window.document.execCommand('copy');
    }
    closeModal();
  }, [closeModal, copyData]);

  const checkClickOutside = (e: MouseEvent) => {
    const target = e?.target as HTMLElement;
    const popover = document.querySelector('.popover');
    if (
      popover &&
      target &&
      buttonRef &&
      buttonRef.current &&
      !popover.contains(target as Node) &&
      !buttonRef.current.contains(target as Node)
    ) {
      close();
    }
  };

  useMemo(() => {
    setHasHotNotification(
      notifications.some(notification => notification.projectId === projectShortName && notification.hovered)
    );
  }, [notifications, projectShortName]);

  useEffect(() => {
    const filteredNotifications = filterByTypeAndProject(notifications, 'warn', projectShortName);
    const allPullNotifications = filteredNotifications.filter((notification: NotificationData) => {
      return notification?.message?.code?.code?.includes('editorbe.projects.remote_changes_found');
    });
    Notifications_RemoteChangesStore$.next(allPullNotifications);
  }, [notifications, projectShortName]);

  useMemo(() => {
    const filteredNotifications = filterByTypeAndProject(notifications, activeTab, projectShortName);
    setActiveNotifications(toSortedByDateObject(filteredNotifications));
  }, [notifications, activeTab, projectShortName]);

  useEffect(() => {
    isOpen && document.addEventListener('click', checkClickOutside);
    return () => document.removeEventListener('click', checkClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isOpenModal]);

  useEffect(() => {
    !isOpenModal && copyLogs();
  }, [copyData, copyLogs, isOpenModal]);

  useEffect(() => {
    const sub = NotificationsListErrorModalLogData$.subscribe(value => {
      unstable_batchedUpdates(() => {
        setCopyData(value);
        openModal();
      });
    });

    return () => {
      sub.unsubscribe();
    };
  }, [openModal]);

  if (!notificationsListDOM.current) return null;

  return ReactDOM.createPortal(
    <div className={classNames({ notifications__hidden: !projectShortName })}>
      <IconButton
        name='farBell'
        id='notification-popover'
        data-test-id='Header.NotificationButton'
        onClick={() => (isOpen ? onClose() : open())}
        className={classNames('notifications__button navbar-btn', {
          'notifications__button-active': hasHotNotification,
        })}
        innerRef={buttonRef}
      />
      <Popover
        isOpen={isOpen}
        target='notification-popover'
        placement='bottom'
        title={localize.translate('notifications title')}
        onCancelClick={onClose}
        className='notifications'
      >
        <div className='notifications__body'>
          <div className='notifications__tabs'>
            <Tabs
              tabs={TAB_VALUES}
              activeTab={activeTab}
              name='notification-tabs'
              onChange={tab => setActiveTab(tab)}
              compact
            />
          </div>

          <NotificationsArea
            activeNotifications={activeNotifications}
            markHovered={index => markHovered(notifications, setNotifications, index)}
            deleteNotification={deleteNotification}
            setCopyData={setCopyData}
            openModal={openModal}
            copyLogs={copyLogs}
          />

          <div className='notifications__delete'>
            <Button color='secondary' flat iconRight='farTrashAlt' onClick={deleteAll}>
              {activeTab === 'all'
                ? localize.translate('notifications delete all button')
                : localize.translate('tasks delete button')}
            </Button>
          </div>
        </div>
      </Popover>

      <Modal
        size='lg'
        isOpen={isOpenModal}
        title={localize.translate('notifications view log')}
        buttonCancelText={localize.translate('Close')}
        buttonCancelOutline={false}
        buttonCancelTestId='NotificationsList.Modal.CloseButton'
        onCancelClick={closeModal}
      >
        <NotificationLog message={copyData} copy={copyLogs} copyTestId='Notifications.Modal.CopyLog' />
      </Modal>
      <textarea ref={hiddenInput} readOnly className='hiddenInput' />
    </div>,
    notificationsListDOM.current
  );
});
NotificationsList.displayName = `Memo(NotificationsList)`;
export default NotificationsList;
