import React, { useEffect, useRef } from 'react';
import classes from './JGraphCailaIntentChooseCreation.module.scss';
import { Tag } from '../../../../../../components/Tag';
import { t } from 'localization';
import cn from 'classnames';
import { useToggle } from '@just-ai/just-ui';
import { isMac } from 'utils/platform';

const hotkey = isMac() ? '⌘' : 'Ctrl';

export const CustomHelperText = () => {
  const [isOpen, , closeHelperText, toggleHelperText] = useToggle(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      closeHelperText();
    };

    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [closeHelperText]);

  return (
    <div className={cn(classes.JGraphCailaIntentChooseCreationHelper, { [classes.isShown]: isOpen })} ref={ref}>
      <span className={cn('pseudoLink', classes.pseudoLink)} onClick={toggleHelperText}>
        {t('JGraphCailaIntentChooseCreation:helperText')}
      </span>
      <div className={classes.helperPopup}>
        <div className={classes.line}>
          <Tag color='secondary'>Enter</Tag> <span>{t('JGraphCailaIntentChooseCreation:helperText:Line1')}</span>
        </div>
        <div className={classes.line}>
          <Tag color='secondary'>{hotkey} + Enter</Tag>{' '}
          <span>{t('JGraphCailaIntentChooseCreation:helperText:Line2')}</span>
        </div>
      </div>
    </div>
  );
};
