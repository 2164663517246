import { MutableRefObject, useRef } from 'react';
import Konva from 'konva';
import { cacheScheduler } from 'utils/sheduler/buildScheduler';

export function useCachedGroup(groupRef: MutableRefObject<Konva.Group | null>) {
  const cancelRef = useRef<(() => void) | null>(null);

  if (cancelRef.current) {
    cancelRef.current();
    cancelRef.current = null;
  }

  const groupNode = groupRef.current;
  if (groupNode && groupNode.isCached()) groupNode.clearCache();

  cancelRef.current = cacheScheduler(() => {
    if (!groupRef.current) return;
    groupRef.current.cache();
  }).cancel;
}
