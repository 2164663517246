import React, { useState, FC, useCallback, useMemo, useRef } from 'react';
import { Group, Text, Rect, Circle } from 'react-konva';
import Konva from 'konva';
import { getStageFromEvent, setCursorOnMouseEnterAndLeave } from '../../../utils/stageUtils';

import { AutopositionGroup } from '../AutopositionGroup';

import { useScreenContext } from 'modules/JGraph/hooks';
import { callBlocksRedraw } from '../../../utils/blockLayerUtils';

import { useScreenCollapseConnections } from './hooks';
import { KonvaEventObject } from 'konva/lib/Node';

type CollapsedGroupTitleType = {
  collapsed: boolean;
  groupTitle: string;
  name?: string;
  onClick?: (event: Konva.KonvaEventObject<MouseEvent>) => unknown;
  pathId?: string;
  isFallbackFrom?: boolean;
};

const textProps = {
  fontSize: 12,
  lineHeight: 16 / 12,
  letterSpacing: 0.01,
  fontFamily: 'Roboto',
};

export const CollapseGroupTitle = React.forwardRef<Konva.Group, CollapsedGroupTitleType>(
  ({ collapsed, onClick, groupTitle, name, pathId, isFallbackFrom }, ref) => {
    const circleRef = useRef<Konva.Circle | null>(null);

    const textWidth = useMemo(() => {
      return new Konva.Text({ text: groupTitle || 'groupTitle', ...textProps }).width();
    }, [groupTitle]);

    useScreenCollapseConnections(collapsed, circleRef, name);

    const mouseEnterAndLeave = useCallback((event: KonvaEventObject<MouseEvent>) => {
      setCursorOnMouseEnterAndLeave()(event);
    }, []);

    return (
      <Group
        onMouseEnter={mouseEnterAndLeave}
        onMouseLeave={mouseEnterAndLeave}
        // onClick={onClick}
        ref={ref}
        isScreen={false}
      >
        <Rect x={textWidth + 8} y={2} width={16} height={12} />
        {/*<Path
          offset={{ x: 6, y: 6 }}
          rotation={collapsed ? -180 : 0}
          data='M9.01553 8.25025H2.9845C2.56689 8.25025 2.35776 7.74533 2.65305 7.45004L5.66856 4.43453C5.85161 4.25148 6.14842 4.25148 6.33147 4.43453L9.34698 7.45004C9.6423 7.74533 9.43314 8.25025 9.01553 8.25025Z'
          width={12}
          height={12}
          fill='#6A7178'
          x={textWidth + 12}
          y={collapsed ? 10 : 8}
        />*/}
        <Text text={groupTitle} y={2} fill='#6A7178' {...textProps} />
        <Circle x={240} y={8} width={12} height={12} name={name} ref={circleRef} />
      </Group>
    );
  }
);
type TScreenMainCollapsibleGroupProps = {
  groupTitle: string;
  yOffset?: number;
  name?: string;
  pathId?: string;
  isFallbackFrom?: boolean;
  collapsedDefault?: boolean;
  onCollapse?: (value: boolean) => unknown;
};

export const ScreenMainCollapsibleGroup: FC<TScreenMainCollapsibleGroupProps> = ({
  groupTitle = '',
  children,
  yOffset = 8,
  name,
  isFallbackFrom,
  pathId,
  collapsedDefault = false,
  onCollapse,
}) => {
  const { screen } = useScreenContext();
  const [collapsed, setCollapsed] = useState(() => collapsedDefault);
  const GroupTitleRef = useRef<Konva.Group>(null);
  const changeBlockIdOnClick = useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      event.cancelBubble = true;
      const stage = getStageFromEvent(event);
      setCollapsed(prevCollapsed => {
        onCollapse && onCollapse(!prevCollapsed);
        return !prevCollapsed;
      });
      callBlocksRedraw(GroupTitleRef.current, stage);
    },
    [onCollapse]
  );

  return (
    <AutopositionGroup blockId={screen.id} yOffset={yOffset}>
      <CollapseGroupTitle
        onClick={changeBlockIdOnClick}
        collapsed={collapsed}
        groupTitle={groupTitle}
        name={name}
        isFallbackFrom={isFallbackFrom}
        pathId={pathId}
        ref={GroupTitleRef}
      />
      {!collapsed && <Group y={18}>{children}</Group>}
    </AutopositionGroup>
  );
};
