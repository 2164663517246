import React, { useCallback, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';

import Tree from 'components/Tree';

import { DetailViewContentProps } from '../..';

import HierarchyHeader from './HierarchyHeader';
import TreeNodeView from './TreeNodeView';
import HierarchyContextMenu from './HierarchyContextMenu';
import { HierarchyTreeNode, useHierarchyModel } from './model';

import styles from './styles.module.scss';

type HierarchyViewContextValue = {
  closeView: () => void;
  setCollapseForAll: (value: boolean) => void;
  openContextMenu$: BehaviorSubject<{ event: React.MouseEvent; node?: HierarchyTreeNode } | undefined>;
};
export const HierarchyViewContext = React.createContext({} as HierarchyViewContextValue);

const HierarchyView = ({ closeAll }: DetailViewContentProps) => {
  const {
    selectNodeInCanvas,
    activeId,
    setExpandedNodesMap,
    expandedNodesMap,
    treeStructure,
    openContextMenu,
    onKeyDownOnNode,
    openContextMenu$,
  } = useHierarchyModel();
  const treeRef = useRef<Tree<HierarchyTreeNode>>();

  const setCollapseForAll = useCallback((value: boolean) => {
    if (!treeRef.current) return;
    treeRef.current.setCollapseForAll(value);
  }, []);

  return (
    <div className={styles.HierarchyView}>
      <HierarchyViewContext.Provider value={{ closeView: closeAll, setCollapseForAll, openContextMenu$ }}>
        <HierarchyHeader />
        <Tree<HierarchyTreeNode>
          activeId={activeId}
          cmpRef={treeRef}
          className={styles.HierarchyView__treeView}
          treeLogicState={treeStructure}
          treeNodeView={TreeNodeView}
          onItemDoubleClick={selectNodeInCanvas}
          handleContextMenu={openContextMenu}
          writeDisabled={true}
          expandedMap={expandedNodesMap}
          onExpandChange={setExpandedNodesMap}
          onKeyDownOnNode={onKeyDownOnNode}
        />
        <HierarchyContextMenu />
      </HierarchyViewContext.Provider>
    </div>
  );
};

HierarchyView.displayName = 'HierarchyView';

export default React.memo(HierarchyView);
