import { LocalStorageService, boolSerializer } from '../Storage/LocalStorageService';

import { SyncFrontendVersionService } from './SyncFrontendVersionService';

const lsKey = 'IncorrectFrontVersionErrorProcessor:page-refreshed';

export class IncorrectFrontVersionErrorProcessor {
  private isWasCalledSyncFrontend = false;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private localFrontVersion?: string,
    private readonly syncFrontendVersionService?: SyncFrontendVersionService
  ) {}

  public async tryToHandleError(error: Error) {
    if (!this.isCanHandle(error)) return false;
    if (!this.syncFrontendVersionService) return false;
    if (this.isWasCalledSyncFrontend) return false;
    this.isWasCalledSyncFrontend = true;

    const isActualVersion = await this.syncFrontendVersionService
      .isVersionActual(this.localFrontVersion || '')
      .catch(() => {});
    if (isActualVersion) {
      void this.localStorageService.remove(lsKey);
      return false;
    }

    const isPageWasRefreshed = await this.localStorageService
      .get<boolean>(lsKey, boolSerializer)
      .then(res => res.success && res.payload);

    if (isPageWasRefreshed) {
      void this.localStorageService.remove(lsKey);
      return false;
    }

    await this.localStorageService.set(lsKey, true, false, boolSerializer);
    this.syncFrontendVersionService.syncFrontend();
    return true;
  }

  private isCanHandle(error: Error) {
    return this.syncFrontendVersionService && error.name === 'ChunkLoadError';
  }
}
