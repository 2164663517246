import {
  CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED,
  LOGIN_TO_ACCOUNT_FULFILLED,
  DROP_CURRENT_LOGIN_ACCOUNT,
  LOGOUT_FROM_ACCOUNT_FULFILLED,
  SET_CURRENT_LOGIN_ACCOUNT,
} from '../../constants/accounts.actions';

import {
  LOGIN_FULFILLED,
  LOGOUT_FULFILLED,
  DROP_CURRENT_USER,
  SET_CURRENT_USER,
} from '../../constants/currentUser.actions';

const InitialState = {
  account: {},
};

export default function CurrentAccountReducer(state = InitialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED:
    case LOGIN_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED: {
      if (!action.payload.data.account) return state;
      return {
        ...state,
        account: action.payload.data.account,
      };
    }

    case SET_CURRENT_USER: {
      if (!action.currentUser || !action.currentUser.account) return state;
      return {
        ...state,
        account: action.currentUser.account,
      };
    }

    case SET_CURRENT_LOGIN_ACCOUNT: {
      if (!action.obj) return state;
      return {
        ...state,
        account: action.obj,
      };
    }

    case LOGOUT_FULFILLED:
    case DROP_CURRENT_USER:
    case DROP_CURRENT_LOGIN_ACCOUNT:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      return {
        ...state,
        account: {},
      };
    }

    default: {
      return state;
    }
  }
}
