import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import './style.scss';
import { useVariableWidth, useVariableHeight } from './useVariableLength';
import classNames from 'classnames';
export const HorizontallyResizableArea = ({ resizeDirection, style, children, className, initialWidth, minWidth, maxWidth, dataTestId, onResize = () => { }, }) => {
    const ref = useRef(null);
    const [width, handleDrag] = useVariableWidth(resizeDirection, initialWidth, minWidth, maxWidth);
    useEffect(() => onResize(width), [onResize, width]);
    return (_jsxs("div", { className: classNames('justui_resizable-area', 'justui_horizontally-resizable-area', className), ref: ref, style: Object.assign(Object.assign({}, style), { width }), "data-test-id": dataTestId, children: [resizeDirection === 'left' && _jsx(LeftDragHandle, { onDrag: (e) => handleDrag(e, ref) }), children, resizeDirection === 'right' && _jsx(RightDragHandle, { onDrag: (e) => handleDrag(e, ref) })] }));
};
export const VerticallyResizableArea = ({ resizeDirection, style, children, className, initialHeight, minHeight, maxHeight, maximized, dataTestId, onResize = () => { }, }) => {
    const ref = useRef(null);
    const [height, handleDrag] = useVariableHeight(resizeDirection, initialHeight, minHeight, maxHeight);
    useEffect(() => onResize(height), [onResize, height]);
    return (_jsxs("div", { className: classNames('justui_resizable-area', 'justui_vertically-resizable-area', { 'justui_vertically-resizable-area--maximized': maximized }, className), ref: ref, style: Object.assign(Object.assign({}, style), { height: maximized ? '100%' : height }), "data-test-id": dataTestId, children: [resizeDirection === 'up' && _jsx(TopDragHandle, { onDrag: (e) => handleDrag(e, ref) }), children, resizeDirection === 'down' && _jsx(BottomDragHandle, { onDrag: (e) => handleDrag(e, ref) })] }));
};
const stopPropagationAndPassDragEvent = (handler) => ({
    onDragStart: (event) => { event.stopPropagation(); },
    onDragEnd: (event) => { event.stopPropagation(); },
    onDrag: (event) => { event.stopPropagation(); handler(event); },
});
const LeftDragHandle = ({ onDrag }) => _jsx("div", Object.assign({ className: 'justui_drag-handle justui_drag-handle-left', draggable: true }, stopPropagationAndPassDragEvent(onDrag)));
const RightDragHandle = ({ onDrag }) => _jsx("div", Object.assign({ className: 'justui_drag-handle justui_drag-handle-right', draggable: true }, stopPropagationAndPassDragEvent(onDrag)));
const TopDragHandle = ({ onDrag }) => _jsx("div", Object.assign({ className: 'justui_drag-handle justui_drag-handle-top', draggable: true }, stopPropagationAndPassDragEvent(onDrag)));
const BottomDragHandle = ({ onDrag }) => _jsx("div", Object.assign({ className: 'justui_drag-handle justui_drag-handle-bottom', draggable: true }, stopPropagationAndPassDragEvent(onDrag)));
