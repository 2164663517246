import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Icon } from '@just-ai/just-ui';
import {
  InferenceResultData,
  NamedEntitiesWithRecordsData,
  ClassificationRuleEntityRef,
  NamedEntityData,
} from '../../../Caila/api/client';
import { t } from 'localization';
import { useAppContext } from 'modules/Caila/components/AppContext';
import { EntityRow } from './EntityRow';
import { EntityType } from 'modules/Caila/model';
import { getEntityUrl } from 'modules/Caila/utils';
import history from 'appHistory';
import './EntityModal.scss';

type EntityModalProps = {
  variant: InferenceResultData;
  isOpen: boolean;
  toggle: () => void;
  redirect: (e: SyntheticEvent) => void;
  isShared: boolean;
};

const systemPrefixes = ['duckling', 'mystem', 'pymorphy', 'mlps-obscene', 'zb'];
const classificationRulesPrefix = 'classRule';

export const EntityModal = ({ variant, isOpen, toggle, redirect, isShared }: EntityModalProps) => {
  const [nonSystemEntities, setNonSystemEntities] = useState<NamedEntitiesWithRecordsData>(
    [] as NamedEntitiesWithRecordsData
  );
  const [allEntitiesNames, setAllEntitiesNames] = useState<string[]>([]);
  const [allCustomEntities, setAllCustomEntities] = useState<NamedEntityData[]>([]);
  const { accountId, projectShortName, EntitiesApi } = useAppContext();
  const { debug } = variant;
  const invalidRules = useMemo(() => debug?.rules?.invalid?.refs || [], [debug?.rules?.invalid?.refs]);
  const validRules = useMemo(() => debug?.rules?.valid?.refs || [], [debug?.rules?.valid?.refs]);

  const isInvalid = invalidRules.length > 0;

  const getRecordsAndNames = useCallback(() => {
    if (!invalidRules || !validRules) return;
    const entitiesNames = [...invalidRules, ...validRules].map(rule => rule.entityName);
    const entitiesNamesToGetRecord =
      entitiesNames
        .filter(entityName => entityName?.includes(classificationRulesPrefix))
        .filter((item): item is string => item !== undefined) || [];
    const entitiesNamesToGetEntity =
      entitiesNames
        .filter(
          entityName =>
            entityName && !systemPrefixes.includes(entityName) && !entityName?.includes(classificationRulesPrefix)
        )
        .filter((item): item is string => item !== undefined) || [];

    EntitiesApi.listEntitiesByNames(accountId, projectShortName, entitiesNamesToGetRecord).then(response =>
      setNonSystemEntities(response.data)
    );
    EntitiesApi.getAllEntityNames(accountId, projectShortName).then(response => {
      setAllEntitiesNames(response.data);
    });
    EntitiesApi.getEntitiesByNames(accountId, projectShortName, entitiesNamesToGetEntity).then(response => {
      setAllCustomEntities(response.data);
    });
  }, [EntitiesApi, accountId, invalidRules, projectShortName, validRules]);

  useEffect(() => {
    if (isOpen) {
      getRecordsAndNames();
    }
  }, [getRecordsAndNames, isOpen]);

  const getRecordByEntityName = (entityName: string) => {
    const entity = nonSystemEntities.entities?.find(entity => entity.entity?.name === entityName);
    const record = entity?.records && entity?.records[0];
    return record?.rule.join(', ') || '';
  };

  const redirectToIntent = (e: SyntheticEvent) => {
    if (isShared) return;
    redirect(e);
    toggle();
  };
  const redirectToEntity = (entity: NamedEntityData) => {
    if (entity.shared) return;
    return () => {
      toggle();
      history.push(`/${projectShortName}${getEntityUrl(EntityType.named, entity.id)}`);
    };
  };

  const renderRows = (rule: ClassificationRuleEntityRef, valid: boolean) => {
    if (!rule || !rule.predicate || !rule.entityName) return;

    const isSystemEntity =
      systemPrefixes.filter(prefix => rule.entityName && rule.entityName.includes(prefix)).length > 0;
    const isEntity = allEntitiesNames.includes(rule.entityName) && !rule.entityName.includes(classificationRulesPrefix);
    const entity = allCustomEntities.find(entity => entity.name === rule.entityName);
    const redirect = isEntity && !isSystemEntity ? entity && redirectToEntity(entity) : redirectToIntent;

    return (
      <EntityRow
        redirect={redirect}
        predicate={rule.predicate}
        content={isEntity ? `@${rule.entityName}` : getRecordByEntityName(rule.entityName)}
        valid={valid}
        isSystem={isSystemEntity}
        key={rule.entityName}
        isShared={(isEntity && !isSystemEntity ? entity?.shared : isShared) || false}
      />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickCloseIcon={toggle}
      title={
        <div className='entity-modal__header'>
          <Icon name={isInvalid ? 'farTimes' : 'farCheck'} size='lg' />
          {t(isInvalid ? 'NLUWidgetModalTitle:invalid' : 'NLUWidgetModalTitle:valid')}
        </div>
      }
      size='lg'
      onCancelClick={toggle}
      buttonCancelColor='secondary'
      buttonCancelText={t('Close')}
    >
      <div className='widget__rules-modal'>
        <p>{t(isInvalid ? 'NLUWidgetModalSubtitle:invalid' : 'NLUWidgetModalSubtitle:valid')}</p>
      </div>
      <table className='entity-modal__table'>
        <thead>
          <tr>
            <td>{t('ClassificationRules:ClientPhrase')}</td>
            <td></td>
            <td>{t('NLUWidgetModalTableExecute')}</td>
          </tr>
        </thead>
        <tbody>
          {invalidRules.map(invalidRule => renderRows(invalidRule, false))}
          {validRules.map(validRule => renderRows(validRule, true))}
        </tbody>
      </table>
    </Modal>
  );
};
