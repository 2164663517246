type Memory = {
  value: string;
  iterationInputString: string;
};

type InsertSessionRulesType = {
  from: RegExp;
  to: string & ((match: string, ...args: any[]) => string);
};

export const session = {
  regexobject: /(\$[^\d\s,"“”!&?#^:;*<>+%№@/\\\-«»='{}~`.()$|[\]]\w*)(?!\1)/u,

  insertSessionRules: [
    {
      from: /AMAZON\./g,
      to: 'AMAZON_',
    },
    ...[
      'chathistoryjson',
      'chathistory',
      'client',
      'analytics',
      'jsapi',
      'context',
      'response',
      'dialer',
      'caila',
      'pushgate',
      'integration',
    ].map(name => {
      return {
        from: new RegExp(`\\$session\\.${name}($|\\.|\\s|=)`, 'gm'),
        to: (match: string, ...args: any[]) => {
          switch (name) {
            case 'chathistoryjson': {
              return `$jsapi.chatHistoryJson()${!!args[0] ? args[0] : ''}`;
            }
            case 'chathistory': {
              return `$jsapi.chatHistory()${!!args[0] ? args[0] : ''}`;
            }
            default:
              return `$${name}${!!args[0] ? args[0] : ''}`;
          }
        },
      };
    }),
  ],

  replaceWithSpacesByRegexp: function (replaceString: string, match: RegExpExecArray): string {
    const startPos = match.index;
    const endPos = match.index + match[0].length;
    let firstPart = replaceString.slice(0, startPos);
    let plot = replaceString.slice(startPos, endPos);
    let lastPart = replaceString.slice(endPos, replaceString.length);

    return [firstPart, Array(plot.length + 1).join(' '), lastPart].join('');
  },

  replaceWithSpacesStartEnd: function (replaceString: string, value: string, startPos: number, endPos: number): string {
    let firstPart = replaceString.slice(0, startPos);
    let lastPart = replaceString.slice(endPos, replaceString.length);

    return [firstPart, value, lastPart].join('');
  },

  getExecCount: function (reg: RegExp, stringToFoundIn: string): number {
    let count = 0;
    while (reg.exec(stringToFoundIn)) {
      count += 1;
    }
    return count;
  },

  checkBracesAreClosed: function (
    match: RegExpExecArray | null,
    initialMatchesValues: Memory,
    defaultReturn: string
  ): string {
    if (match) {
      let countLeftBraces = this.getExecCount(/\(/g, match[0]);
      let countRightBraces = this.getExecCount(/\)/g, match[0]);
      if (countLeftBraces !== countRightBraces) {
        return initialMatchesValues.iterationInputString;
      }

      countLeftBraces = this.getExecCount(/\[/g, match[0]);
      countRightBraces = this.getExecCount(/]/g, match[0]);
      if (countLeftBraces !== countRightBraces) {
        return initialMatchesValues.iterationInputString;
      }
    }
    return defaultReturn;
  },

  insert$Session: function (replaceIn: string, httpResponseInsertSessionFlag: boolean): string {
    const returnValue = replaceIn
      .replace(/\$/g, '$session.')
      .replace(
        httpResponseInsertSessionFlag ? /\$session\.httpResponse/g : '',
        httpResponseInsertSessionFlag ? '$httpResponse' : ''
      );

    return this.insertSessionRules.reduce((resultString, currValue) => {
      return resultString.replace(currValue.from, currValue.to as InsertSessionRulesType['to']);
    }, returnValue);
  },

  replace$Session: function (replaceIn: string): string {
    return replaceIn
      .replace(/\$session\./g, '$')
      .replace(/\$jsapi\.chatHistoryJson\(\)/g, '$chathistoryjson')
      .replace(/\$jsapi\.chatHistory\(\)/g, '$chathistory')
      .replace(/\$AMAZON_/g, '$AMAZON.');
  },

  getMatches: function (stringToParse: string) {
    let modifyString = stringToParse;

    let matches: (RegExpExecArray | null)[] = [];
    let initialMatchesValues: Memory[] = [];
    let match: RegExpExecArray | null = null;
    while ((match = this.regexobject.exec(modifyString))) {
      matches.push(match);
      initialMatchesValues.push({
        value: match[0],
        iterationInputString: '',
      });
      modifyString = this.replaceWithSpacesByRegexp(modifyString, match);
    }

    let matchesShouldBeDeleted: number[] = [];

    //для каждого совпадения проверяем следующий символ
    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      // @ts-ignore
      let prevMatches = i > 0 ? [...matches].splice(0, i).map(m => m.index + m[0].length) : [];

      let prevMatchesIndex = -1;
      //проверяем что текущий матч не находится внутри предыдущих
      if (
        prevMatches.length > 0 &&
        match &&
        (prevMatchesIndex = prevMatches.findIndex(endPosition => endPosition > match!.index)) > -1
      ) {
        const insertPos = match.index - prevMatches[prevMatchesIndex];
        const insertPosEnd = insertPos + match[0].length;
        //если находится внутри предыдущего, засовываем его туда и помечаем для удаления из сета
        matchesShouldBeDeleted.push(i);
        if (matches[prevMatchesIndex] && matches[prevMatchesIndex]![0]) {
          matches[prevMatchesIndex]![0] = this.replaceWithSpacesStartEnd(
            matches[prevMatchesIndex]![0],
            match[0],
            insertPos,
            insertPosEnd
          );
        }
        continue;
      }
      let nextSymbol = match?.input[match?.index + match[0].length];
      let savedModifiedString = '';
      while (match && nextSymbol && ['.', '(', '[', '::'].indexOf(nextSymbol) > -1) {
        const mainStart = match!.index + match![0].length + nextSymbol.length;
        let substrMatch = null;
        let stringAfterMatch = modifyString.slice(mainStart, modifyString.length);
        savedModifiedString = modifyString;
        switch (nextSymbol) {
          case '::':
          //fallback is OK т.к. за :: должно быть слово
          // eslint-disable-next-line no-fallthrough
          case '.':
            substrMatch = /^\w+|\p{Script=Han}+/u.exec(stringAfterMatch);
            break;
          case '(':
            substrMatch = /\)/.exec(stringAfterMatch);
            break;
          case '[':
            substrMatch = /]/.exec(stringAfterMatch);
            break;
        }
        nextSymbol = '';
        if (substrMatch) {
          const start = match!.index + match![0].length;
          const end = substrMatch.index + substrMatch[0].length + mainStart;
          match[0] += modifyString.slice(start, end);
          initialMatchesValues[i].value = match[0];
          initialMatchesValues[i].iterationInputString = modifyString;
          modifyString = this.replaceWithSpacesStartEnd(modifyString, Array(end - start + 1).join(' '), start, end);
          nextSymbol = match?.input[end];
          let afterNextSymbol = match?.input[end + 1];
          if (nextSymbol + afterNextSymbol === '::') {
            nextSymbol += afterNextSymbol;
          }
        }
      }
      //проверка того что все скобки закрыты, если нет возвращаем исходное значение
      modifyString = this.checkBracesAreClosed(match, initialMatchesValues[i], modifyString);
      if (modifyString === savedModifiedString) {
        match![0] = initialMatchesValues[i].value;
      }
    }
    //нужно подчистить массив matches чтобы убрать значения которые используются в других значениях
    if (matchesShouldBeDeleted.length > 0) {
      matches = matches.reduce((prevValue, currentValue, index) => {
        if (matchesShouldBeDeleted.indexOf(index) === -1 && currentValue) {
          prevValue.push(currentValue);
        }
        return prevValue;
      }, [] as RegExpExecArray[]);
    }
    return matches;
  },

  escapeRegExp: function (value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  },

  parse: function (stringToParse: string): string {
    const matches = this.getMatches(stringToParse);
    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      if (match) {
        const value = this.replace$Session(match[0]);
        stringToParse = stringToParse.replace(match[0], value);
        const doubleCurlyBracesValue = new RegExp(`{{${this.escapeRegExp(value)}}}`, '');
        stringToParse = stringToParse.replace(doubleCurlyBracesValue, value);
      }
    }

    return stringToParse;
  },
  insert: function (
    stringToParse: string,
    withCurlyBraces: boolean = true,
    httpResponseInsertSessionFlag: boolean = false
  ) {
    const matches = this.getMatches(stringToParse);
    //проходим по массиву matches и вставляем $session везде где есть $ не проверяя более ничего
    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      if (match) {
        const openBraces = withCurlyBraces ? '{{' : '';
        const closeBraces = withCurlyBraces ? '}}' : '';
        const value = `${openBraces}${this.insert$Session(match[0], httpResponseInsertSessionFlag)}${closeBraces}`;
        stringToParse = stringToParse.replace(match[0], value);
      }
    }
    return stringToParse;
  },
};
