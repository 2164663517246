import useLocationFunction from 'wouter/use-location';
import { useMemo, ComponentType, FunctionComponent, createElement } from 'react';
import { getDisplayName } from '.';
import { INTENTS_PAGE_PATH_ROOT, ENTITIES_PAGE_PATH_ROOT, LOG_LABELING_PAGE_PATH_ROOT } from '../constants/path';
import { EntityType } from '../model';
import { LocationTuple, useLocation, PushCallback, Path } from 'wouter';

const makeUseBasepathLocation = (basepath: string) => (): LocationTuple => {
  const [location, setLocation] = useLocationFunction();

  const normalized = location.startsWith(basepath) ? location.slice(basepath.length) : location;

  return [normalized, (to: string, replace?: boolean) => setLocation(basepath + to, replace)];
};

export const useBasepathLocationHook = (basepath: string) =>
  useMemo(() => makeUseBasepathLocation(basepath), [basepath]);

export type LocationProps = {
  location: Path;
  setLocation: PushCallback;
};
export function withLocation<Prop extends LocationProps>(
  WrappedComponent: ComponentType<Prop>
): ComponentType<Omit<Prop, keyof LocationProps>> {
  const Location: FunctionComponent<Omit<Prop, keyof LocationProps>> = (props: Omit<Prop, keyof LocationProps>) => {
    const [location, setLocation] = useLocation();
    const passDownProps = {
      ...props,
      location,
      setLocation,
    } as Prop;
    return createElement(WrappedComponent, passDownProps);
  };
  Location.displayName = `withLocation(${getDisplayName(WrappedComponent)})`;
  return Location;
}

export const normalizeUrlPart = (id: string | number) => String(id).replace('.', '_');
export const deNormalizeUrlPart = (id: string | number) => String(id).replace('_', '.');

export const getIntentsUrl = () => INTENTS_PAGE_PATH_ROOT;
export const getIntentUrl = (id?: string | number) => (id ? `${getIntentsUrl()}/${id}` : getIntentsUrl());
export const getBasePathIntentsUrl = (basePath: string) => `${basePath}${getIntentsUrl()}`;
export const getEntitiesUrl = (type: EntityType = EntityType.named) => `${ENTITIES_PAGE_PATH_ROOT}/${type}`;
export const getEntityUrl = (type?: EntityType, id?: string | number) =>
  id ? `${getEntitiesUrl(type)}/${normalizeUrlPart(id)}` : getEntitiesUrl(type);
export const getBasePathEntitiesUrl = (basePath: string, type?: EntityType) => `${basePath}${getEntitiesUrl(type)}`;
export const getLogLabelingUrl = () => LOG_LABELING_PAGE_PATH_ROOT;
export const getLogLabelingSessionUrl = (id?: string | number) =>
  id ? `${getLogLabelingUrl()}/${id}` : getLogLabelingUrl();
