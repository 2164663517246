import { t } from 'localization';

import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { hideRootSlashInPath } from './state';
import { traverseScreen, getNewNameWithIndex } from './stageUtils';

export function getThemeName(themePath: string) {
  return themePath === '/' ? t('Themes:MainTheme') : hideRootSlashInPath(themePath);
}

export function getPathWithoutThemePrefix(path: string, theme: string) {
  if (!path.startsWith(theme)) return path;
  return path.substring(theme.length);
}

export function transformNameToThemePath(text: string) {
  if (!text) return '';
  text = text.trim();
  if (!text.startsWith('/')) {
    text = '/' + text;
  }
  return text;
}

export const getLastPartOfPath = (path: string): string => {
  return path.split('/').slice(-1)[0];
};

export function renameInlineStateIfNameIsBusy(state: JStateWithId, busyPaths: string[]) {
  const existedPaths = [...busyPaths];
  const replacedPaths = {} as Record<string, string>;

  traverseScreen(state, screen => {
    for (let parentPath of Object.keys(replacedPaths)) {
      if (screen.path.startsWith(parentPath + '/')) {
        screen.path = screen.path.replace(parentPath, replacedPaths[parentPath]);
      }
    }
    const path = getNewNameWithIndex(screen.path, existedPaths);
    if (path !== screen.path) {
      replacedPaths[screen.path] = path;
      existedPaths.push(path);
      screen.path = path;
      screen.value = getLastPartOfPath(path);
    }
  });
}

export function stateChangePath(state: JStateWithId, newPath: string, oldPath?: string): JStateWithId {
  if (oldPath === undefined) oldPath = state.path;
  if (!state.path.startsWith(oldPath)) return state;
  const renamedPath = state.path.replace(oldPath, newPath);
  const newState = {
    ...state,
    path: renamedPath,
    value: getLastPartOfPath(renamedPath),
  };
  if (state.states) {
    newState.states = state.states.map(state => stateChangePath(state, newPath, oldPath));
  }
  return newState;
}
