var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spinner, Icon } from '../';
import './FileUpload.scss';
import classNames from 'classnames';
import { ProgressBar } from '../ProgressBar';
const inputStyles = {
    display: 'block',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    border: 'none',
};
const LoadingComponent = ({ loadingLabel, loadingType }) => {
    return (_jsx("div", { className: 'file-upload__loading', onClick: event => event.stopPropagation(), children: loadingType === 'spinner' ? (_jsxs(_Fragment, { children: [_jsx("div", { style: { position: 'relative', height: 50 }, children: _jsx(Spinner, { size: '3x' }) }), _jsx("span", { children: loadingLabel })] })) : (_jsxs("div", { className: 'file-upload__loading--progress', children: [_jsx("p", { children: loadingLabel }), _jsx(ProgressBar, {})] })) }));
};
export const FileUpload = (_a) => {
    var { loading = false, loadingLabel, children, name, onChange, className, loadingType = 'spinner', accept, iconSize, disabled } = _a, restProps = __rest(_a, ["loading", "loadingLabel", "children", "name", "onChange", "className", "loadingType", "accept", "iconSize", "disabled"]);
    const onDrop = useCallback(acceptedFiles => {
        if (disabled)
            return;
        onChange && onChange(acceptedFiles, name);
    }, [disabled, name, onChange]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (_jsxs("div", Object.assign({}, getRootProps(), { className: classNames(className, 'FileUploadContainer', {
            FileUploadContainer_DnD: isDragActive && !disabled,
            'FileUploadContainer--disabled': disabled,
        }) }, restProps, { children: [loading && _jsx(LoadingComponent, { loadingLabel: loadingLabel, loadingType: loadingType }), _jsx("input", Object.assign({}, getInputProps({ style: inputStyles, accept }), { disabled: disabled })), isDragActive && !disabled && (_jsx("div", { className: 'upload-icon-wrapper', children: _jsx(Icon, { name: 'farCloudUpload', color: 'primary', size: iconSize || '5x' }) })), children] })));
};
