import {
  CLEAR_EDITABLE_LIVECHAT_CHANNEL,
  CREATE_LIVECHAT_CHANNEL_FULFILLED,
  FETCH_LIVECHAT_CHANNELS_FULFILLED,
  FETCH_LIVECHAT_CHANNELS_PENDING,
  FETCH_LIVECHAT_CHANNELS_REJECTED,
  REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED,
  SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED,
  SET_EDITABLE_LIVECHAT_CHANNEL,
} from '../../constants/livechatChannels.actions';
import { remove } from 'lodash/array';
import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

const InitialState = {
  channelList: [],
  selectedChannelId: null,
  publishingChannelId: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function IntegrationReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_LIVECHAT_CHANNELS_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_LIVECHAT_CHANNELS_FULFILLED:
      let channelList = action.payload.data;
      channelList = channelList.reverse();
      return {
        ...state,
        channelList: channelList,
        fetched: true,
        fetching: false,
      };

    case FETCH_LIVECHAT_CHANNELS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data,
      };

    case SET_EDITABLE_LIVECHAT_CHANNEL:
      return {
        ...state,
        selectedChannelId: action.channel.id,
      };

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case CLEAR_EDITABLE_LIVECHAT_CHANNEL:
      return {
        ...state,
        selectedChannelId: null,
      };

    case CREATE_LIVECHAT_CHANNEL_FULFILLED:
      let channelListAdded = state.channelList;
      channelListAdded.splice(0, 0, action.payload.data);
      return {
        ...state,
        channelList: channelListAdded,
        selectedChannelId: action.payload.data.id,
        fetched: true,
        fetching: false,
      };
    case SAVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED:
      return {
        ...state,
        channelList: state.channelList.map(channel => {
          return channel.id === action.payload.data.id ? action.payload.data : channel;
        }),
      };
    case REMOVE_EDITABLE_LIVECHAT_CHANNEL_FULFILLED:
      let channelListRemoved = state.channelList;
      remove(channelListRemoved, channel => {
        return channel.id === action.payload.data.id;
      });
      return {
        ...state,
        channelList: channelListRemoved,
        selectedChannelId: null,
      };

    default:
      return state;
  }
}
