import React, { FC } from 'react';
import { Group, Line, Text } from 'react-konva';
import { BaseItemBody, TBaseItemBodyProps } from './BaseItemBody';
import { IconNames, KIcon } from './KIcons';
import { TransitionCircle } from './TransitionCircle';
import { TagNames, TButtonParameters, TJBlock, TTagParameters } from '../../utils/types';
import { getConnectorFrom } from '../../../../reducers/JGraph.reducer/Graph';
import { getStageActionsFromEvent, setCursorOnMouseEnterAndLeave } from 'modules/JGraph/utils/stageUtils';
import { t } from 'localization';
import { useOnClickBlockDefault } from '../../hooks';
import { useRenderBlocksContext } from './RenderBlocks';

export interface ButtonsProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  screenBlock: TJBlock;
  buttons: TButtonParameters[];
}

const getButtonKeys = (param: TTagParameters): string => {
  return `${[param.name, param.transition, param.url].join('_')}`;
};

export const Buttons: FC<ButtonsProps> = ({ buttons, globalJBlockIterator = 0, ...rest }) => {
  const fontSize = 14;
  const height = 32;
  const topOffset = 28;
  const iterateOffset = topOffset + height;

  const { baseWidth, prefix, screen } = useRenderBlocksContext();
  const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);
  return (
    <BaseItemBody
      {...rest}
      onClick={onClickDefault}
      renderChild={() => {
        return (
          <Group x={24}>
            {buttons.length > 0 &&
              buttons.map((button, index) => {
                const topOffsetInner = topOffset + height * index;
                const topIterateOffsetInner = iterateOffset + height * index;

                return (
                  <Group
                    onMouseEnter={setCursorOnMouseEnterAndLeave()}
                    onMouseLeave={setCursorOnMouseEnterAndLeave()}
                    key={getButtonKeys(button)}
                    onClick={evt => {
                      evt.cancelBubble = true;
                      const stageActions = getStageActionsFromEvent(evt);
                      stageActions.setEditMenuBlock({
                        screen: screen,
                        jBlockIndex: globalJBlockIterator,
                        path: `${prefix}tagParameters[${index}]`,
                      });
                    }}
                  >
                    <Text
                      verticalAlign='middle'
                      y={topOffsetInner}
                      text={button.name}
                      height={height}
                      lineHeight={height}
                      width={baseWidth - 100}
                      fontSize={fontSize}
                      letterSpacing={0.02}
                      ellipsis
                      wrap='none'
                    />
                    {!!button.url && <KIcon x={baseWidth - 24 - 36 - 24} y={topOffsetInner + 9} icon={IconNames.url} />}
                    {!button.url && (
                      <TransitionCircle
                        name={getConnectorFrom(
                          globalJBlockIterator || 0,
                          screen.pathId,
                          `${prefix}${rest.screenBlock.tagName}`,
                          index
                        )}
                        debugActive={button.debugActive}
                        tagName={TagNames.buttons}
                        transitionTo={button.transition || ''}
                        y={topOffsetInner + 16}
                        x={baseWidth - 24 - 36 - 18}
                        hasTransition={!!button.transition}
                      />
                    )}

                    {index < buttons.length - 1 && (
                      <Line
                        points={[0, topIterateOffsetInner, baseWidth - 60, topIterateOffsetInner]}
                        stroke='#E0E1E3'
                        strokeWidth={1}
                      />
                    )}
                  </Group>
                );
              })}
            {buttons.length === 0 && (
              <Group
                onMouseEnter={setCursorOnMouseEnterAndLeave()}
                onMouseLeave={setCursorOnMouseEnterAndLeave()}
                key='button_placeholder'
              >
                <Text
                  verticalAlign='middle'
                  y={topOffset}
                  text={t('KonvaPlaceholder-SetButtons')}
                  height={height}
                  lineHeight={height}
                  width={baseWidth - 100}
                  fontSize={fontSize}
                  letterSpacing={0.02}
                  ellipsis
                  fill='#888E93'
                />
              </Group>
            )}
          </Group>
        );
      }}
    />
  );
};
