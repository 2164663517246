import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Circle, Rect } from 'react-konva';
import { activeColor } from './KIcons';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

import { useConnectorStore } from 'modules/JGraph/utils/connectionLayerUtils';

import { setCursorOnMouseEnterAndLeave } from 'modules/Editor/components/VisualEditor/utils/stageFunctions';

import { newConnectorSubject$, useScreenContext } from '../../hooks';
import { TagNames } from '../../utils/types';
import { getStageContextFromRef, getStageFromEvent, getStageFromRef } from '../../utils/stageUtils';
import { debugActiveColor } from '../../colors';

type TTransitionCircleProps = {
  x: number;
  y: number;
  name: string;
  tagName: TagNames;
  hasTransition?: boolean;
  transitionTo?: string;
  canStartConnection?: boolean;
  debugActive?: boolean;
};

export const TransitionCircle: FC<TTransitionCircleProps> = React.memo(
  ({ x, y, hasTransition, name, tagName, transitionTo, canStartConnection = true, debugActive = false }) => {
    const CircleRef = useRef<Konva.Circle | null>(null);
    const { isEditModeEnable } = useScreenContext();
    useConnectorStore(name, tagName, transitionTo, CircleRef, debugActive);

    const setNewConnectionCanBeStartedRx = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        if (!isEditModeEnable) return;
        setCursorOnMouseEnterAndLeave(event);

        const stage = getStageFromEvent(event);
        newConnectorSubject$.next({
          canStart: event.type === 'mouseenter',
          GStage: stage,
        });
      },
      [isEditModeEnable]
    );

    const onClickCreateConnectionRx = useCallback(
      (event: KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();
        if (!isEditModeEnable) return;
        const stage = getStageFromRef(CircleRef);
        if (stage) {
          newConnectorSubject$.next({
            from: name,
            fromPosition: CircleRef.current?.getAbsolutePosition(stage),
            toPosition: CircleRef.current?.getAbsolutePosition(stage),
            transitionTo: transitionTo,
            started: true,
          });
        }
      },
      [isEditModeEnable, name, transitionTo]
    );

    useEffect(() => {
      if (CircleRef.current && CircleRef.current?.getStage() && hasTransition) {
        const { connectorsFromPipe$ } = getStageContextFromRef(CircleRef);
        if (connectorsFromPipe$) {
          connectorsFromPipe$.next({
            [name]: {
              fromRef: CircleRef.current,
            },
          });
        }
      }
    }, [hasTransition, name]);

    useEffect(() => {
      const { connectorsFromPipe$ } = getStageContextFromRef(CircleRef);
      return () => {
        if (connectorsFromPipe$) {
          connectorsFromPipe$.next({
            [name]: {
              fromRef: undefined,
            },
          });
        }
      };
    }, [name]);

    return (
      <>
        {debugActive ? (
          <Circle
            x={x}
            y={y}
            radius={8}
            strokeWidth={2}
            width={16}
            height={16}
            fill='white'
            stroke={debugActiveColor}
          />
        ) : null}
        <Circle
          x={x}
          y={y}
          radius={6}
          strokeWidth={1}
          width={12}
          height={12}
          stroke='#C3C6C9'
          name={name}
          transitionTo={transitionTo}
          ref={CircleRef}
          // actionStyles={{
          //   mouseenter: {
          //     shadowColor: 'blue',
          //     shadowBlur: 10,
          //     shadowOpacity: 0.5,
          //     stroke: 'blue',
          //     strokeWidth: 1,
          //   },
          // }}
        />
        {hasTransition && <Circle x={x} y={y} fill={activeColor} width={6} height={6} radius={3} />}
        <Rect
          x={x - 6}
          y={y - 6}
          width={12}
          height={12}
          dataType='TransitionCircleStart'
          onMouseDown={canStartConnection ? onClickCreateConnectionRx : undefined}
          onMouseEnter={setNewConnectionCanBeStartedRx}
          onMouseLeave={setNewConnectionCanBeStartedRx}
        />
      </>
    );
  }
);
TransitionCircle.displayName = 'TransitionCircle';
