import {
  ADD_LOG_TASK_FULFILLED,
  ADD_LOG_TEXTAREA_FULFILLED,
  LOAD_DETAIL_FULFILLED,
  LOAD_DETAIL_PENDING,
  LOAD_DETAIL_REJECTED,
  LOAD_SESSION_LABELS_FULFILLED,
  LOAD_TASK_CREATION_DATA_PENDING,
  LOAD_TASK_CREATION_DATA_FULFILLED,
  LOAD_TASK_LABELS_FULFILLED,
  LOAD_USERS_FULFILLED,
  MAIN_SELECTED_MARKS,
  MARK_SESSION_ISSUE,
  MARK_SESSION_LOG_FULFILLED,
  SEL_LAST_SELECTED_Priority,
  SEL_LAST_SELECTED_User,
  SET_CURRENT_INDEX,
  SET_PAGE,
  SET_SELECT_PHRASE_LABELS,
  SET_SELECTED_LOG_MARKS,
  SET_SELECTED_MAIN_MARKS,
  SET_SELECTED_MARKS,
  UPDATE_LOG_TASK_FULFILLED,
  UPDATE_LOG_TASK_REJECTED,
  UPDATE_LOG_TASK_PENDING,
  UPDATE_SESSION_TASK_PENDING,
  UPDATE_SESSION_TASK_REJECTED,
  UPDATE_SESSION_TASK_FULFILLED,
  ADD_SESSION_TASK_REJECTED,
  ADD_SESSION_TASK_PENDING,
  ADD_SESSION_TASK_FULFILLED,
  ADD_LOG_TASK_PENDING,
  ADD_LOG_TASK_REJECTED,
  GET_ISSUES_BY_QUESTION_ID_FULFILLED,
  ADD_LOG_LABELS_FAST_FULFILLED,
  ADD_LOG_LABELS_REJECTED,
} from '../../constants/dialogsDetail.actions';
import { getIntFromPageNumber, getUserValidName } from '../../pipes/functions';
import { forEach, isEmpty, union } from 'lodash';
import { GET_ISSUES_BY_SESSIONID_FULFILLED } from '../../constants/dialogs.actions';

const InitialState = {
  errors: [],

  dialog: [],
  sessionMarkLabels: [],

  marks: [],
  states: [],
  statuses: [],
  users: [],
  classes: [],
  sessionLabels: [],
  createTaskMarks: [],

  baseInfo: {
    channel: '',
    session: '',
    clientName: '',
    channelType: '',
    userId: '',
    startTime: 0,
    lastTime: 0,
    asrAudioUrlBase: '',
  },
  sessionData: null,

  selectedIssue: null,
  saveIssueErrors: [],
  saveIssueFetching: false,

  selectedMarks: {},
  selectedState: [],
  selectedStatus: [],
  selectedUsers: [],
  selectedSessionLabels: [],
  selectedCreateTaskMarks: [],
  issuesByQuestionId: [],

  page: 0,
  totalPages: 0,

  textareaValue: null,

  fetchingLog: false,

  currentIndex: -1,

  lastSelectedUser: null,
  lastSelectedPriority: null,
};

export default function DialogsDetailReducer(state = InitialState, action) {
  switch (action.type) {
    case SEL_LAST_SELECTED_User: {
      return {
        ...state,
        lastSelectedUser: action.value,
      };
    }
    case SEL_LAST_SELECTED_Priority: {
      return {
        ...state,
        lastSelectedPriority: action.value,
      };
    }

    case SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case LOAD_DETAIL_PENDING: {
      return {
        ...state,
        fetchingLog: true,
      };
    }

    case LOAD_DETAIL_REJECTED: {
      let errors = !!action.payload.response.data.error
        ? [action.payload.response.data.error]
        : action.payload.response.data.errors;

      return {
        ...state,
        fetchingLog: false,
        errors: union(state.error, errors),
        dialog: [],
      };
    }
    case LOAD_DETAIL_FULFILLED: {
      const { sessionData } = state;
      const data = action.payload.data || action.payload;
      const issues = sessionData?.issues
        ?.map(issue => {
          if (
            issue.naturalSessionId &&
            data?.session?.sessionId &&
            issue.naturalSessionId === data?.session?.sessionId
          ) {
            return issue;
          } else {
            return null;
          }
        })
        .filter(x => x != null);

      if (issues?.length > 0) {
        data.session.issues = issues;
      } else if (!data.session?.issues) {
        data.session.issues = [];
      }

      let dialog = [];

      if (Boolean(data.logs)) {
        dialog = data.logs?.content || data.logs;
      }
      let selectedMarks = {};
      let selectedUsers = [];
      let selectedCreateTaskMarks = [];
      let selectedStatus = [];

      forEach(dialog, item => {
        let tmpSelectedMarks = [];
        forEach(item.labels, mark => {
          tmpSelectedMarks.push(mark.id + '');
        });
        selectedMarks[item.id || item.questionId] = tmpSelectedMarks;
      });

      let sessionMarkLabels = [];
      if (Boolean(data?.session)) {
        forEach(data.session.labels || action.payload.session.labels, labels => {
          sessionMarkLabels.push(labels.id);
        });
      }

      return {
        ...state,
        dialog: dialog,
        page: getIntFromPageNumber(data.logs?.number, data.paging?.pageNum),
        totalPages: getIntFromPageNumber(
          data.logs?.totalPages,
          Math.ceil(data.paging?.totalCount / data.paging?.pageSize)
        ),
        baseInfo: {
          channel: data.channel || data.session?.channelType,
          session: data.session.id || data.session?.sessionId,
          clientName: getUserValidName(
            data.session.credential?.firstName || data.session.client?.firstName || '',
            data.session.credential?.lastName || data.session.client?.lastName || '',
            data.session.credential?.username || data.session.client?.username || '',
            data.session.credential?.fullName || data.session.client?.fullName || ''
          ),
          channelType: data.session.credential?.channelType || data.session.channelType,
          userId: data.session.userId || data.session.client.id,
          startTime: data.session.startTime,
          lastTime: data.session.lastTime || 0,
          asrAudioUrlBase: data.asrAudioUrlBase,
          customFields: data.customFields || data.session.customFields,
          sessionDuration: data.session.duration,
        },
        sessionData: data.session,
        sessionMarkLabels: sessionMarkLabels,

        selectedCreateTaskMarks: selectedCreateTaskMarks,
        selectedMarks: selectedMarks,
        selectedStatus: selectedStatus,
        selectedUsers: selectedUsers,

        fetchingLog: false,

        errors: InitialState.errors,
      };
    }

    case SET_CURRENT_INDEX: {
      return {
        ...state,
        currentIndex: action.currentIndex,
      };
    }

    case LOAD_SESSION_LABELS_FULFILLED: {
      return {
        ...state,
        sessionLabels: action.payload.data,
      };
    }

    case ADD_LOG_LABELS_REJECTED: {
      let updatedMarks = { ...state.selectedMarks };
      let found = updatedMarks[action.payload.config.logId].find(
        label => label.toString() === action.payload.config.addingOrRemovedId.toString()
      );
      if (found) {
        updatedMarks[action.payload.config.logId] = updatedMarks[action.payload.config.logId].filter(
          label => label.toString() !== action.payload.config.addingOrRemovedId.toString()
        );
      } else {
        updatedMarks[action.payload.config.logId].splice(0, 0, action.payload.config.addingOrRemovedId);
      }

      return {
        ...state,
        selectedMarks: updatedMarks,
      };
    }

    case ADD_LOG_LABELS_FAST_FULFILLED: {
      let updatedMarks = { ...state.selectedMarks };
      updatedMarks[action.payload.logId] = action.payload.labels;
      return {
        ...state,
        selectedMarks: updatedMarks,
      };
    }

    case ADD_LOG_TEXTAREA_FULFILLED: {
      const dialog = state.dialog.slice();
      const index = dialog.findIndex(dialog => {
        return dialog.id === action.payload.config.logId || dialog.questionId === action.payload.config.logId;
      });
      dialog[index] = { ...dialog[index], comment: JSON.parse(action.payload.config.data).comment };

      return {
        ...state,
        dialog: dialog,
      };
    }

    case ADD_LOG_TASK_FULFILLED: {
      const dialog = state.dialog.slice();
      const index = dialog.findIndex(dialog => {
        return dialog.id === action.payload.config.obj.logId || dialog.questionId === action.payload.config.obj.logId;
      });

      if (index === -1) return state;

      dialog[index].issues = [action.payload.data];

      return {
        ...state,
        dialog: dialog,
        saveIssueFetching: false,
      };
    }
    case UPDATE_LOG_TASK_FULFILLED: {
      const dialog = [...state.dialog];
      const index = dialog.findIndex(dialog => {
        return dialog.id === action.payload.config.obj.logId || dialog.questionId === action.payload.config.obj.logId;
      });
      if (index === -1) debugger;
      const issueIndex = dialog[index].issues.findIndex(issue => {
        return issue.id === action.payload.config.obj.issueId || dialog.questionId === action.payload.config.obj.logId;
      });
      dialog[index].issues[issueIndex] = action.payload.data;
      return {
        ...state,
        saveIssueFetching: false,
        dialog: dialog,
      };
    }
    case ADD_SESSION_TASK_FULFILLED: {
      if (!state.sessionData) return state;

      let sessionDataIssues = state.sessionData?.issues?.slice() || [];
      sessionDataIssues.push(action.payload.data);
      return {
        ...state,
        saveIssueFetching: false,
        sessionData: {
          ...state.sessionData,
          issues: sessionDataIssues,
        },
      };
    }
    case ADD_LOG_TASK_REJECTED:
    case UPDATE_LOG_TASK_REJECTED:
    case UPDATE_SESSION_TASK_REJECTED:
    case ADD_SESSION_TASK_REJECTED: {
      let errors = !!action.payload.response.data.error
        ? [action.payload.response.data.error]
        : action.payload.response.data.errors;
      return {
        ...state,
        saveIssueErrors: errors,
        saveIssueFetching: false,
      };
    }
    case ADD_LOG_TASK_PENDING:
    case UPDATE_LOG_TASK_PENDING:
    case UPDATE_SESSION_TASK_PENDING:
    case ADD_SESSION_TASK_PENDING: {
      return {
        ...state,
        saveIssueErrors: InitialState.saveIssueErrors,
        saveIssueFetching: true,
      };
    }
    case LOAD_TASK_CREATION_DATA_PENDING: {
      return {
        ...state,
        saveIssueErrors: InitialState.saveIssueErrors,
      };
    }
    case UPDATE_SESSION_TASK_FULFILLED: {
      if (!state.sessionData) return state;

      let sessionDataIssues = state.sessionData.issues.slice() || [];
      const index = sessionDataIssues.findIndex(issue => {
        return issue.id === action.payload.config.obj.issueId;
      });

      sessionDataIssues.splice(index, 1, action.payload.data);
      return {
        ...state,
        saveIssueFetching: false,
        sessionData: {
          ...state.sessionData,
          issues: sessionDataIssues,
        },
      };
    }
    case MARK_SESSION_LOG_FULFILLED: {
      return {
        ...state,
        sessionMarkLabels: action.payload.config.sessionMarkLabels,
      };
    }
    case LOAD_TASK_LABELS_FULFILLED: {
      return {
        ...state,
        marks: action.payload.data,
      };
    }
    case LOAD_USERS_FULFILLED: {
      return {
        ...state,
        users: action.payload.data.content,
      };
    }
    case LOAD_TASK_CREATION_DATA_FULFILLED: {
      return {
        ...state,
        users: action.payload.data.users,
        createTaskMarks: action.payload.data.labels,
        statuses: action.payload.data.priorities,
        states: action.payload.data.states,
      };
    }
    case SET_SELECTED_MARKS: {
      let newActionMarks = [...state[action.markType]];
      let index = newActionMarks.findIndex(item => {
        return item.id === action.id;
      });
      newActionMarks.splice(index, 1, { id: action.id, value: action.value });

      return {
        ...state,
        [action.markType]: newActionMarks,
      };
    }
    case SET_SELECT_PHRASE_LABELS: {
      let dialog = [...state.dialog];
      const index = dialog.findIndex(item => {
        return item.id === action.id;
      });
      if (index > -1) {
        dialog[index].labels = action.value.map(item => {
          return {
            id: item.value,
            name: item.label,
          };
        });
      }
      return {
        ...state,
        dialog: dialog,
      };
    }
    case SET_SELECTED_MAIN_MARKS: {
      return {
        ...state,
        selectedIssue: {
          ...state.selectedIssue,
          [action.markType]: action.value,
        },
      };
    }
    case SET_SELECTED_LOG_MARKS: {
      let newDialogs = [...state.dialog];
      const dialogIndex = newDialogs.findIndex(item => {
        return item.id === action.id || item.questionId === action.id;
      });
      if (dialogIndex === -1) return state;

      let curDialog = newDialogs[dialogIndex];
      if (!isEmpty(curDialog.issues)) {
        curDialog.issues[0][action.markType] = action.value;
      } else {
        curDialog.issues = [];
        curDialog.issues.push({
          [action.markType]: action.value,
        });
      }

      return {
        ...state,
        dialog: newDialogs,
      };
    }
    case MARK_SESSION_ISSUE: {
      let selectedIssue = {};
      if (action.flag) {
        if (Boolean(state.sessionData.issues) && state.sessionData.issues.length > 0) {
          selectedIssue = { ...state.sessionData.issues[0] };
        }
      } else {
        selectedIssue = null;
      }

      return {
        ...state,
        selectedIssue: selectedIssue,
      };
    }
    case MAIN_SELECTED_MARKS: {
      return {
        ...state,
        [action.markType]: action.value,
      };
    }
    case GET_ISSUES_BY_QUESTION_ID_FULFILLED: {
      const issues = action.payload.data;
      const questionIds = Object.keys(issues);
      let newDialog = [...state.dialog];
      questionIds.forEach(questionId => {
        let dialog = state.dialog.find(dialog => dialog.questionId === questionId);
        if (dialog) {
          dialog.issues = issues[questionId];
        }
      });
      return {
        ...state,
        dialog: newDialog,
        issuesByQuestionId: action.payload.data,
      };
    }

    case GET_ISSUES_BY_SESSIONID_FULFILLED: {
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          issues: action.payload.data,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
