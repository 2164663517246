import moment from 'moment';
import { cloneDeep } from 'lodash';
export function getMethodDateFormat(...dates) {
    return dates.map(date => {
        let localDate = null;
        if (typeof date === 'string' && date.match(/^(\d+\.){2}\d{4}$/g)) {
            localDate = moment(date, 'DD-MM-YYYY');
        }
        if (typeof date === 'string' && date.match(/^\d{13}$/g)) {
            localDate = moment(parseInt(date));
        }
        if (typeof date === 'number') {
            localDate = moment(date);
        }
        return (localDate || moment()).format('YYYY-MM-DD');
    });
}
export const prepareOptions = (options) => {
    options = cloneDeep(options);
    if (options['nlpClass.name']) {
        options['nlpClass'] = options['nlpClass.name'];
    }
    if (options['nlpClass.like']) {
        options['nlpClassLikeSearch'] = options['nlpClass.like'];
    }
    if (options['dialogsPage']) {
        options['page'] = options['dialogsPage'];
    }
    if (options['phrasesPage']) {
        options['page'] = options['phrasesPage'];
    }
    if (options['state.name']) {
        options['nlpState'] = options['state.name'];
    }
    if (options['state.like']) {
        options['nlpStateLikeSearch'] = options['state.like'];
    }
    if (options['response']) {
        options['answer'] = options['response'];
    }
    if (options['channel']) {
        //@ts-ignore
        options['botIds'] = [options['channel']];
    }
    if (options['isIncoming']) {
        options['isIncomingCall'] = options['isIncoming'];
    }
    if (options['logLabels']) {
        //@ts-ignore
        options['logLabels'] = options['logLabels'].split(',');
    }
    if (options['sessionLabels']) {
        //@ts-ignore
        options['sessionLabels'] = options['sessionLabels'].split(',');
    }
    if (options['mailingResults']) {
        //@ts-ignore
        options['mailingResults'] = options['mailingResults'].split(',');
    }
    if (options['sessionResult']) {
        //@ts-ignore
        options['sessionResult'] = options['sessionResult'].split(',');
    }
    if (options['client']) {
        options['clientName'] = options['client'];
    }
    if (options['withoutEmptyClientMessages']) {
        options['withoutEmptySessions'] = options['withoutEmptyClientMessages'];
    }
    if (options['dateFrom']) {
        //@ts-ignore
        let dateFunction = options['dateFrom'];
        if (!options['dateFrom'].hasOwnProperty('_i')) {
            dateFunction = moment(parseInt(options['dateFrom'], 10));
        }
        options['startTime'] = dateFunction.utc().format();
        options['dateFrom'] = dateFunction.utc().format();
    }
    if (options['dateTo']) {
        //@ts-ignore
        let dateFunction = options['dateTo'];
        if (!options['dateTo'].hasOwnProperty('_i')) {
            dateFunction = moment(parseInt(options['dateTo'], 10));
        }
        options['endTime'] = dateFunction.utc().format();
        options['dateTo'] = dateFunction.utc().format();
    }
    return options;
};
