// tslint:disable
// @ts-nocheck
/**
 * LOGS REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChangeMaskingRuleDto
 */
export interface ChangeMaskingRuleDto {
  /**
   *
   * @type {number}
   * @memberof ChangeMaskingRuleDto
   */
  accountId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ChangeMaskingRuleDto
   */
  projectIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ChangeMaskingRuleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ChangeMaskingRuleDto
   */
  regexp: string;
  /**
   *
   * @type {string}
   * @memberof ChangeMaskingRuleDto
   */
  substitution: string;
  /**
   *
   * @type {boolean}
   * @memberof ChangeMaskingRuleDto
   */
  enable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChangeMaskingRuleDto
   */
  isSystem?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChangeMaskingRuleDto
   */
  isCaseSensitive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChangeMaskingRuleDto
   */
  enableForNew?: boolean;
}
/**
 *
 * @export
 * @interface LogDownloadTaskResponse
 */
export interface LogDownloadTaskResponse {
  /**
   *
   * @type {number}
   * @memberof LogDownloadTaskResponse
   */
  taskId: number;
}
/**
 *
 * @export
 * @interface MaskingRuleDto
 */
export interface MaskingRuleDto {
  /**
   *
   * @type {number}
   * @memberof MaskingRuleDto
   */
  accountId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MaskingRuleDto
   */
  projectIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MaskingRuleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MaskingRuleDto
   */
  regexp: string;
  /**
   *
   * @type {string}
   * @memberof MaskingRuleDto
   */
  substitution: string;
  /**
   *
   * @type {boolean}
   * @memberof MaskingRuleDto
   */
  enable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MaskingRuleDto
   */
  isSystem?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MaskingRuleDto
   */
  isCaseSensitive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MaskingRuleDto
   */
  enableForNew?: boolean;
  /**
   *
   * @type {string}
   * @memberof MaskingRuleDto
   */
  id: string;
}
/**
 *
 * @export
 * @interface MaskingRuleDtoAllOf
 */
export interface MaskingRuleDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof MaskingRuleDtoAllOf
   */
  id: string;
}
/**
 *
 * @export
 * @interface ServerLogsDownloadRequest
 */
export interface ServerLogsDownloadRequest {
  /**
   *
   * @type {string}
   * @memberof ServerLogsDownloadRequest
   */
  botId?: string;
  /**
   *
   * @type {string}
   * @memberof ServerLogsDownloadRequest
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof ServerLogsDownloadRequest
   */
  questionId?: string;
  /**
   *
   * @type {Date}
   * @memberof ServerLogsDownloadRequest
   */
  fromTimestamp?: Date;
  /**
   *
   * @type {Date}
   * @memberof ServerLogsDownloadRequest
   */
  toTimestamp?: Date;
}
/**
 *
 * @export
 * @interface WatchOptions
 */
export interface WatchOptions {
  /**
   *
   * @type {string}
   * @memberof WatchOptions
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof WatchOptions
   */
  botId?: string;
  /**
   *
   * @type {string}
   * @memberof WatchOptions
   */
  clientId?: string;
  /**
   *
   * @type {boolean}
   * @memberof WatchOptions
   */
  emptyClient?: boolean;
  /**
   *
   * @type {string}
   * @memberof WatchOptions
   */
  logStreamUrl: string;
}

/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete all completed log download tasks for account
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompletedLogDownloadTasks(projectShortName: string, timezone?: number, options: any = {}): RequestArgs {
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling deleteCompletedLogDownloadTasks.'
        );
      }
      const localVarPath = `/serverLogs/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (timezone !== undefined && timezone !== null) {
        localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete log download task
     * @param {string} taskId Task identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogDownloadTask(taskId: string, options: any = {}): RequestArgs {
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          'taskId',
          'Required parameter taskId was null or undefined when calling deleteLogDownloadTask.'
        );
      }
      const localVarPath = `/serverLogs/download/{taskId}`.replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary download server logs
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {ServerLogsDownloadRequest} [serverLogsDownloadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(
      projectShortName: string,
      timezone?: number,
      serverLogsDownloadRequest?: ServerLogsDownloadRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling download.'
        );
      }
      const localVarPath = `/serverLogs/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (timezone !== undefined && timezone !== null) {
        localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof serverLogsDownloadRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(serverLogsDownloadRequest !== undefined ? serverLogsDownloadRequest : {})
        : serverLogsDownloadRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get last logs
     * @param {string} streamId stream identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(streamId: string, options: any = {}): RequestArgs {
      // verify required parameter 'streamId' is not null or undefined
      if (streamId === null || streamId === undefined) {
        throw new RequiredError('streamId', 'Required parameter streamId was null or undefined when calling getLogs.');
      }
      const localVarPath = `/serverLogs/{streamId}`.replace(`{${'streamId'}}`, encodeURIComponent(String(streamId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get watch url
     * @param {string} [projectId] Project identifier
     * @param {string} [botId] Bot identifier
     * @param {string} [clientId] Client identifier
     * @param {boolean} [emptyClient] Empty client
     * @param {boolean} [immediate] Immediate watch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    watch(
      projectId?: string,
      botId?: string,
      clientId?: string,
      emptyClient?: boolean,
      immediate?: boolean,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/serverLogs/watch`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectId !== undefined) {
        localVarQueryParameter['projectId'] = projectId;
      }

      if (botId !== undefined) {
        localVarQueryParameter['botId'] = botId;
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (emptyClient !== undefined) {
        localVarQueryParameter['emptyClient'] = emptyClient;
      }

      if (immediate !== undefined) {
        localVarQueryParameter['immediate'] = immediate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete all completed log download tasks for account
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompletedLogDownloadTasks(
      projectShortName: string,
      timezone?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).deleteCompletedLogDownloadTasks(
        projectShortName,
        timezone,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete log download task
     * @param {string} taskId Task identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogDownloadTask(
      taskId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).deleteLogDownloadTask(taskId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary download server logs
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {ServerLogsDownloadRequest} [serverLogsDownloadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(
      projectShortName: string,
      timezone?: number,
      serverLogsDownloadRequest?: ServerLogsDownloadRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogDownloadTaskResponse> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).download(
        projectShortName,
        timezone,
        serverLogsDownloadRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary get last logs
     * @param {string} streamId stream identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(streamId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).getLogs(streamId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary get watch url
     * @param {string} [projectId] Project identifier
     * @param {string} [botId] Bot identifier
     * @param {string} [clientId] Client identifier
     * @param {boolean} [emptyClient] Empty client
     * @param {boolean} [immediate] Immediate watch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    watch(
      projectId?: string,
      botId?: string,
      clientId?: string,
      emptyClient?: boolean,
      immediate?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchOptions> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).watch(
        projectId,
        botId,
        clientId,
        emptyClient,
        immediate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete all completed log download tasks for account
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompletedLogDownloadTasks(projectShortName: string, timezone?: number, options?: any): AxiosPromise<void> {
      return LogsApiFp(configuration).deleteCompletedLogDownloadTasks(
        projectShortName,
        timezone,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete log download task
     * @param {string} taskId Task identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLogDownloadTask(taskId: string, options?: any): AxiosPromise<void> {
      return LogsApiFp(configuration).deleteLogDownloadTask(taskId, options)(axios, basePath);
    },
    /**
     *
     * @summary download server logs
     * @param {string} projectShortName Project identifier
     * @param {number} [timezone] Timezone offset
     * @param {ServerLogsDownloadRequest} [serverLogsDownloadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(
      projectShortName: string,
      timezone?: number,
      serverLogsDownloadRequest?: ServerLogsDownloadRequest,
      options?: any
    ): AxiosPromise<LogDownloadTaskResponse> {
      return LogsApiFp(configuration).download(
        projectShortName,
        timezone,
        serverLogsDownloadRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary get last logs
     * @param {string} streamId stream identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(streamId: string, options?: any): AxiosPromise<string> {
      return LogsApiFp(configuration).getLogs(streamId, options)(axios, basePath);
    },
    /**
     *
     * @summary get watch url
     * @param {string} [projectId] Project identifier
     * @param {string} [botId] Bot identifier
     * @param {string} [clientId] Client identifier
     * @param {boolean} [emptyClient] Empty client
     * @param {boolean} [immediate] Immediate watch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    watch(
      projectId?: string,
      botId?: string,
      clientId?: string,
      emptyClient?: boolean,
      immediate?: boolean,
      options?: any
    ): AxiosPromise<WatchOptions> {
      return LogsApiFp(configuration).watch(
        projectId,
        botId,
        clientId,
        emptyClient,
        immediate,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
  /**
   *
   * @summary Delete all completed log download tasks for account
   * @param {string} projectShortName Project identifier
   * @param {number} [timezone] Timezone offset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public deleteCompletedLogDownloadTasks(projectShortName: string, timezone?: number, options?: any) {
    return LogsApiFp(this.configuration).deleteCompletedLogDownloadTasks(
      projectShortName,
      timezone,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete log download task
   * @param {string} taskId Task identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public deleteLogDownloadTask(taskId: string, options?: any) {
    return LogsApiFp(this.configuration).deleteLogDownloadTask(taskId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary download server logs
   * @param {string} projectShortName Project identifier
   * @param {number} [timezone] Timezone offset
   * @param {ServerLogsDownloadRequest} [serverLogsDownloadRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public download(
    projectShortName: string,
    timezone?: number,
    serverLogsDownloadRequest?: ServerLogsDownloadRequest,
    options?: any
  ) {
    return LogsApiFp(this.configuration).download(
      projectShortName,
      timezone,
      serverLogsDownloadRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary get last logs
   * @param {string} streamId stream identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getLogs(streamId: string, options?: any) {
    return LogsApiFp(this.configuration).getLogs(streamId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary get watch url
   * @param {string} [projectId] Project identifier
   * @param {string} [botId] Bot identifier
   * @param {string} [clientId] Client identifier
   * @param {boolean} [emptyClient] Empty client
   * @param {boolean} [immediate] Immediate watch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public watch(
    projectId?: string,
    botId?: string,
    clientId?: string,
    emptyClient?: boolean,
    immediate?: boolean,
    options?: any
  ) {
    return LogsApiFp(this.configuration).watch(
      projectId,
      botId,
      clientId,
      emptyClient,
      immediate,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * MaskingRulesApi - axios parameter creator
 * @export
 */
export const MaskingRulesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create masking rule
     * @param {number} accountId User identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaskingRule(accountId: number, changeMaskingRuleDto: ChangeMaskingRuleDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createMaskingRule.'
        );
      }
      // verify required parameter 'changeMaskingRuleDto' is not null or undefined
      if (changeMaskingRuleDto === null || changeMaskingRuleDto === undefined) {
        throw new RequiredError(
          'changeMaskingRuleDto',
          'Required parameter changeMaskingRuleDto was null or undefined when calling createMaskingRule.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeMaskingRuleDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeMaskingRuleDto !== undefined ? changeMaskingRuleDto : {})
        : changeMaskingRuleDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete masking rules by accountId
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllMaskingRulesByAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteAllMaskingRulesByAccount.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaskingRule(accountId: number, maskingRuleId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteMaskingRule.'
        );
      }
      // verify required parameter 'maskingRuleId' is not null or undefined
      if (maskingRuleId === null || maskingRuleId === undefined) {
        throw new RequiredError(
          'maskingRuleId',
          'Required parameter maskingRuleId was null or undefined when calling deleteMaskingRule.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/{maskingRuleId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'maskingRuleId'}}`, encodeURIComponent(String(maskingRuleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Enable or disable masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {boolean} [enable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMaskingRule(accountId: number, maskingRuleId: string, enable?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling enableMaskingRule.'
        );
      }
      // verify required parameter 'maskingRuleId' is not null or undefined
      if (maskingRuleId === null || maskingRuleId === undefined) {
        throw new RequiredError(
          'maskingRuleId',
          'Required parameter maskingRuleId was null or undefined when calling enableMaskingRule.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/{maskingRuleId}/enable`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'maskingRuleId'}}`, encodeURIComponent(String(maskingRuleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (enable !== undefined) {
        localVarQueryParameter['enable'] = enable;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRule(accountId: number, maskingRuleId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getMaskingRule.'
        );
      }
      // verify required parameter 'maskingRuleId' is not null or undefined
      if (maskingRuleId === null || maskingRuleId === undefined) {
        throw new RequiredError(
          'maskingRuleId',
          'Required parameter maskingRuleId was null or undefined when calling getMaskingRule.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/{maskingRuleId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'maskingRuleId'}}`, encodeURIComponent(String(maskingRuleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of masking rules by account
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRules(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getMaskingRules.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return input text with all replacements
     * @param {number} accountId User identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testAllRulesByAccount(accountId: number, text: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling testAllRulesByAccount.'
        );
      }
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError(
          'text',
          'Required parameter text was null or undefined when calling testAllRulesByAccount.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/test`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return input text with specific replacements
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testRule(accountId: number, maskingRuleId: string, text: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling testRule.'
        );
      }
      // verify required parameter 'maskingRuleId' is not null or undefined
      if (maskingRuleId === null || maskingRuleId === undefined) {
        throw new RequiredError(
          'maskingRuleId',
          'Required parameter maskingRuleId was null or undefined when calling testRule.'
        );
      }
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError('text', 'Required parameter text was null or undefined when calling testRule.');
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/{maskingRuleId}/test`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'maskingRuleId'}}`, encodeURIComponent(String(maskingRuleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update masking rule
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaskingRuleByAccount(
      accountId: number,
      maskingRuleId: string,
      changeMaskingRuleDto: ChangeMaskingRuleDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateMaskingRuleByAccount.'
        );
      }
      // verify required parameter 'maskingRuleId' is not null or undefined
      if (maskingRuleId === null || maskingRuleId === undefined) {
        throw new RequiredError(
          'maskingRuleId',
          'Required parameter maskingRuleId was null or undefined when calling updateMaskingRuleByAccount.'
        );
      }
      // verify required parameter 'changeMaskingRuleDto' is not null or undefined
      if (changeMaskingRuleDto === null || changeMaskingRuleDto === undefined) {
        throw new RequiredError(
          'changeMaskingRuleDto',
          'Required parameter changeMaskingRuleDto was null or undefined when calling updateMaskingRuleByAccount.'
        );
      }
      const localVarPath = `/restapi/accounts/{accountId}/masking-rules/{maskingRuleId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'maskingRuleId'}}`, encodeURIComponent(String(maskingRuleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeMaskingRuleDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeMaskingRuleDto !== undefined ? changeMaskingRuleDto : {})
        : changeMaskingRuleDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaskingRulesApi - functional programming interface
 * @export
 */
export const MaskingRulesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create masking rule
     * @param {number} accountId User identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaskingRule(
      accountId: number,
      changeMaskingRuleDto: ChangeMaskingRuleDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskingRuleDto> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).createMaskingRule(
        accountId,
        changeMaskingRuleDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete masking rules by accountId
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllMaskingRulesByAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).deleteAllMaskingRulesByAccount(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaskingRule(
      accountId: number,
      maskingRuleId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).deleteMaskingRule(
        accountId,
        maskingRuleId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Enable or disable masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {boolean} [enable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMaskingRule(
      accountId: number,
      maskingRuleId: string,
      enable?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskingRuleDto> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).enableMaskingRule(
        accountId,
        maskingRuleId,
        enable,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRule(
      accountId: number,
      maskingRuleId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskingRuleDto> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).getMaskingRule(
        accountId,
        maskingRuleId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return list of masking rules by account
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRules(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaskingRuleDto>> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).getMaskingRules(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return input text with all replacements
     * @param {number} accountId User identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testAllRulesByAccount(
      accountId: number,
      text: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).testAllRulesByAccount(
        accountId,
        text,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return input text with specific replacements
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testRule(
      accountId: number,
      maskingRuleId: string,
      text: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).testRule(
        accountId,
        maskingRuleId,
        text,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update masking rule
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaskingRuleByAccount(
      accountId: number,
      maskingRuleId: string,
      changeMaskingRuleDto: ChangeMaskingRuleDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskingRuleDto> {
      const localVarAxiosArgs = MaskingRulesApiAxiosParamCreator(configuration).updateMaskingRuleByAccount(
        accountId,
        maskingRuleId,
        changeMaskingRuleDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaskingRulesApi - factory interface
 * @export
 */
export const MaskingRulesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Create masking rule
     * @param {number} accountId User identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaskingRule(
      accountId: number,
      changeMaskingRuleDto: ChangeMaskingRuleDto,
      options?: any
    ): AxiosPromise<MaskingRuleDto> {
      return MaskingRulesApiFp(configuration).createMaskingRule(
        accountId,
        changeMaskingRuleDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete masking rules by accountId
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllMaskingRulesByAccount(accountId: number, options?: any): AxiosPromise<void> {
      return MaskingRulesApiFp(configuration).deleteAllMaskingRulesByAccount(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaskingRule(accountId: number, maskingRuleId: string, options?: any): AxiosPromise<void> {
      return MaskingRulesApiFp(configuration).deleteMaskingRule(accountId, maskingRuleId, options)(axios, basePath);
    },
    /**
     *
     * @summary Enable or disable masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {boolean} [enable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableMaskingRule(
      accountId: number,
      maskingRuleId: string,
      enable?: boolean,
      options?: any
    ): AxiosPromise<MaskingRuleDto> {
      return MaskingRulesApiFp(configuration).enableMaskingRule(
        accountId,
        maskingRuleId,
        enable,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Return masking rule by maskingRuleId
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRule(accountId: number, maskingRuleId: string, options?: any): AxiosPromise<MaskingRuleDto> {
      return MaskingRulesApiFp(configuration).getMaskingRule(accountId, maskingRuleId, options)(axios, basePath);
    },
    /**
     *
     * @summary Return list of masking rules by account
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaskingRules(accountId: number, options?: any): AxiosPromise<Array<MaskingRuleDto>> {
      return MaskingRulesApiFp(configuration).getMaskingRules(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Return input text with all replacements
     * @param {number} accountId User identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testAllRulesByAccount(accountId: number, text: string, options?: any): AxiosPromise<string> {
      return MaskingRulesApiFp(configuration).testAllRulesByAccount(accountId, text, options)(axios, basePath);
    },
    /**
     *
     * @summary Return input text with specific replacements
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {string} text Text for replacement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testRule(accountId: number, maskingRuleId: string, text: string, options?: any): AxiosPromise<string> {
      return MaskingRulesApiFp(configuration).testRule(accountId, maskingRuleId, text, options)(axios, basePath);
    },
    /**
     *
     * @summary Update masking rule
     * @param {number} accountId User identifier.
     * @param {string} maskingRuleId Masking rule identifier.
     * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaskingRuleByAccount(
      accountId: number,
      maskingRuleId: string,
      changeMaskingRuleDto: ChangeMaskingRuleDto,
      options?: any
    ): AxiosPromise<MaskingRuleDto> {
      return MaskingRulesApiFp(configuration).updateMaskingRuleByAccount(
        accountId,
        maskingRuleId,
        changeMaskingRuleDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * MaskingRulesApi - object-oriented interface
 * @export
 * @class MaskingRulesApi
 * @extends {BaseAPI}
 */
export class MaskingRulesApi extends BaseAPI {
  /**
   *
   * @summary Create masking rule
   * @param {number} accountId User identifier.
   * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public createMaskingRule(accountId: number, changeMaskingRuleDto: ChangeMaskingRuleDto, options?: any) {
    return MaskingRulesApiFp(this.configuration).createMaskingRule(
      accountId,
      changeMaskingRuleDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete masking rules by accountId
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public deleteAllMaskingRulesByAccount(accountId: number, options?: any) {
    return MaskingRulesApiFp(this.configuration).deleteAllMaskingRulesByAccount(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete masking rule by maskingRuleId
   * @param {number} accountId User identifier.
   * @param {string} maskingRuleId Masking rule identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public deleteMaskingRule(accountId: number, maskingRuleId: string, options?: any) {
    return MaskingRulesApiFp(this.configuration).deleteMaskingRule(
      accountId,
      maskingRuleId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Enable or disable masking rule by maskingRuleId
   * @param {number} accountId User identifier.
   * @param {string} maskingRuleId Masking rule identifier.
   * @param {boolean} [enable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public enableMaskingRule(accountId: number, maskingRuleId: string, enable?: boolean, options?: any) {
    return MaskingRulesApiFp(this.configuration).enableMaskingRule(
      accountId,
      maskingRuleId,
      enable,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return masking rule by maskingRuleId
   * @param {number} accountId User identifier.
   * @param {string} maskingRuleId Masking rule identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public getMaskingRule(accountId: number, maskingRuleId: string, options?: any) {
    return MaskingRulesApiFp(this.configuration).getMaskingRule(
      accountId,
      maskingRuleId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return list of masking rules by account
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public getMaskingRules(accountId: number, options?: any) {
    return MaskingRulesApiFp(this.configuration).getMaskingRules(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return input text with all replacements
   * @param {number} accountId User identifier.
   * @param {string} text Text for replacement.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public testAllRulesByAccount(accountId: number, text: string, options?: any) {
    return MaskingRulesApiFp(this.configuration).testAllRulesByAccount(
      accountId,
      text,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return input text with specific replacements
   * @param {number} accountId User identifier.
   * @param {string} maskingRuleId Masking rule identifier.
   * @param {string} text Text for replacement.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public testRule(accountId: number, maskingRuleId: string, text: string, options?: any) {
    return MaskingRulesApiFp(this.configuration).testRule(
      accountId,
      maskingRuleId,
      text,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update masking rule
   * @param {number} accountId User identifier.
   * @param {string} maskingRuleId Masking rule identifier.
   * @param {ChangeMaskingRuleDto} changeMaskingRuleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaskingRulesApi
   */
  public updateMaskingRuleByAccount(
    accountId: number,
    maskingRuleId: string,
    changeMaskingRuleDto: ChangeMaskingRuleDto,
    options?: any
  ) {
    return MaskingRulesApiFp(this.configuration).updateMaskingRuleByAccount(
      accountId,
      maskingRuleId,
      changeMaskingRuleDto,
      options
    )(this.axios, this.basePath);
  }
}
