import { BehaviorSubject, debounceTime } from 'rxjs';
import { TConnector } from '../../../reducers/JGraph.reducer/types';
import { useEffect, useState } from 'react';

export const highLightConnectors$ = new BehaviorSubject<{ activePathId?: string; connector: TConnector | null }>({
  activePathId: undefined,
  connector: null,
});
export const actionsPipe$ = highLightConnectors$.pipe(debounceTime(0));

export const useConnectorHighLight = (currentConnector: TConnector) => {
  const [yellowActive, setActive] = useState(false);
  const [blueActive, setBlueActive] = useState(false);
  const [grayscaled, setGrayscaled] = useState(false);

  useEffect(() => {
    const sub = actionsPipe$.subscribe(nextAction => {
      if (
        nextAction.connector &&
        nextAction.connector.from === currentConnector.from &&
        nextAction.connector.to === currentConnector.to
      ) {
        setActive(true);
        setGrayscaled(false);
        return;
      }
      if (nextAction.activePathId) {
        if (
          nextAction.connector &&
          nextAction.activePathId &&
          (nextAction.connector.fromNode === nextAction.activePathId ||
            nextAction.connector.to === nextAction.activePathId) &&
          (currentConnector.fromNode === nextAction.activePathId || currentConnector.to === nextAction.activePathId)
        ) {
          setActive(false);
          setGrayscaled(true);
          return;
        }
        if (nextAction.activePathId === currentConnector.fromNode || nextAction.activePathId === currentConnector.to) {
          setGrayscaled(false);
          setActive(false);
          setBlueActive(true);
          return;
        }
      }
      setActive(false);
      setBlueActive(false);
    });
    return () => sub.unsubscribe();
  }, [currentConnector]);

  return {
    yellowActive,
    blueActive,
    grayscaled,
    setBlueActive,
  };
};
