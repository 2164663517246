import React, { FunctionComponent, useEffect, useState, useRef, useCallback, useMemo } from 'react';

import './style.scss';
import { useLogContext } from '../LogContext';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { LogItemPanel } from './LogItemPanel';
import VirtualList from 'react-tiny-virtual-list';
import ResizeObserver from 'react-resize-observer';

const STANDART_ITEM_HEIGHT = 44;

type LogAreaProps = {};
const { t } = i18nTranslation('LogPanel');

const LogArea: FunctionComponent<LogAreaProps> = () => {
  const { logItems, startFetching, stopFetching, isFetching } = useLogContext();
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const toggleOpenedIndex = useCallback(
    (index: number) => (index === openedIndex ? setOpenedIndex(null) : setOpenedIndex(index)),
    [openedIndex]
  );
  const [openedItemHeight, setOpenedItemHeight] = useState(STANDART_ITEM_HEIGHT);
  const scrollAreaRef = useRef<VirtualList>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const openedItemRef = useRef<HTMLDivElement>(null);

  const [containerHeight, setContainerHeight] = useState(0);

  const recalculateContainerHeight = useCallback(() => {
    const clientRect = containerRef.current?.getBoundingClientRect();
    if (!clientRect) return;
    setContainerHeight(clientRect.height);
  }, [containerRef]);

  const itemSizes: number[] = useMemo(() => {
    const sizes = Array(logItems.length).fill(STANDART_ITEM_HEIGHT);
    if (openedIndex !== null) sizes[openedIndex] = openedItemHeight;
    return sizes;
  }, [logItems.length, openedIndex, openedItemHeight]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(recalculateContainerHeight, [containerRef.current]);

  useEffect(() => {
    startFetching();
    return stopFetching;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching) {
      scrollAreaRef.current?.scrollTo(scrollAreaRef.current?.getOffsetForIndex(logItems.length - 1));
    }
  }, [isFetching, itemSizes, logItems.length]);

  const handleScroll = useCallback(
    (offset: number) => {
      if (!scrollAreaRef.current) return;
      if (isScrolledToBottom(scrollAreaRef.current, offset, logItems.length)) {
        startFetching();
      } else {
        stopFetching();
      }
    },
    [logItems.length, startFetching, stopFetching]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (openedIndex === null) {
        setOpenedItemHeight(STANDART_ITEM_HEIGHT);
        return;
      }
      const height = openedItemRef.current?.getBoundingClientRect().height;
      setOpenedItemHeight(STANDART_ITEM_HEIGHT + (height ? height : 0));
    }, 0);
    return () => clearTimeout(timeout);
  }, [openedIndex]);

  return (
    <div className='log-area-wrapper' ref={containerRef} data-test-id='LogPanel.logs.content'>
      {logItems.length !== 0 ? (
        <>
          <VirtualList
            itemCount={logItems.length}
            height={containerHeight}
            itemSize={itemSizes}
            width='100%'
            onScroll={handleScroll}
            ref={scrollAreaRef}
            renderItem={itemInfo => (
              <LogItemPanel
                logItem={logItems[itemInfo.index]}
                key={`log-item_${itemInfo.index}`}
                style={itemInfo.style}
                isOpen={itemInfo.index === openedIndex}
                toggleOpened={() => toggleOpenedIndex(itemInfo.index)}
                ref={itemInfo.index === logItems.length - 1 ? lastItemRef : null}
                openedItemRef={itemInfo.index === openedIndex ? openedItemRef : null}
              />
            )}
          />
          <ResizeObserver onResize={recalculateContainerHeight} />
        </>
      ) : (
        <div className='log-area-empty-caption'>{t('logAreaEmpty')}</div>
      )}
    </div>
  );
};

const isScrolledToBottom = (element: VirtualList, offset: number, length: number) => {
  const height = element.getOffsetForIndex(length - 1);
  return offset >= height - 20;
};

export default LogArea;
