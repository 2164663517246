type Options = {
  filePath: string;
  scope?: string;
};

type SWMessage = {
  type: string;
};

export class SWComposer {
  publicUrl: string;
  options: Options;
  onAfterLoad?: () => {};

  constructor(options: Options) {
    this.publicUrl = window.location.protocol + '//' + window.location.host + options.filePath;
    this.options = options;
  }

  public start = () => {
    if (!this.isCanWork()) return;
    this.register().then(reg => {
      if (!reg) return;
      this.reRegisterIfSWUncontrolled(reg);
      if (this.onAfterLoad) {
        this.onAfterLoad();
      }
    });
  };

  public postMessage = (msg: SWMessage) => {
    if (!this.isControlled()) return;
    navigator.serviceWorker.controller?.postMessage(msg);
  };

  public isCanWork = () => 'serviceWorker' in navigator && navigator.serviceWorker;
  public isControlled = () => this.isCanWork() && !!navigator.serviceWorker.controller;

  public setOnAfterLoad = (fn: () => {}) => {
    this.onAfterLoad = fn;
  };

  private reRegisterIfSWUncontrolled = (reg: ServiceWorkerRegistration) => {
    // happens when hard reload
    if (reg && reg.active && !navigator.serviceWorker.controller) {
      reg.unregister().then(this.register);
    }
  };

  private register = () =>
    navigator.serviceWorker.register(this.options.filePath, { scope: this.options.scope }).catch(error => {
      console.log('Registration failed with ' + error);
    });
}
