import {
  SET_USER_HISTORY_FILTER,
  CHANGE_USER_TARIFF,
  ADD_PACKAGE,
  CHANGE_USER_LOCK,
  DROP_ERRORS,
  FETCH_USER_DATA,
  ADD_MINUTES,
  FETCH_AVAILABLE_OPTIONS_DATA,
  FETCH_USER_HISTORY,
  FETCH_OWNER_DATA,
  CHANGE_MANUAL_CONTROL,
  FETCH_PARTNER_DATA,
  FETCH_PARTNER_REFERRALS,
  CHANGE_PARTNER_DATA,
  ADD_PARTNER,
  ADD_PARTNER_REFERRAL,
  DISCONNECT_PARTNER_REFERRAL,
  MOVE_PARTNER_REFERRAL,
  DROP_REFERRALS_ERRORS,
  DROP_PARTNER_DATA,
  DROP_ACCOUNT_MANAGER_DATA_ERRORS,
  ADD_PACKAGES,
  GET_USER_TARIFF_CHANGE,
  GET_REFERRAL_PARTNER,
  ADD_PARTNER_TO_REFERRAL,
  DEDUCT_LIMITS,
} from '../../constants/accountManager.actions';
import { axios } from '../../pipes/functions';
import moment from 'moment';

export const setDates = (what, value) => {
  let dateFromTimeLimit = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  if (!!value && what === 'dateFrom') {
    if (value.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
      dateFromTimeLimit = moment();
    }
    if (value.format('x') > moment().format('x')) {
      value = moment();
    }
  }

  return {
    type: SET_USER_HISTORY_FILTER,
    value,
    what,
    dateFromTimeLimit,
  };
};

export const dropErrors = () => {
  return {
    type: DROP_ERRORS,
  };
};

export const changeUserLock = (accountId, dataObj) => {
  return {
    type: CHANGE_USER_LOCK,
    payload: axios.post(`/restapi/billing/accountTariff/block?accountId=${accountId}`, dataObj),
  };
};

export const changeUserTariff = (accountId, dataObj) => {
  return {
    type: CHANGE_USER_TARIFF,
    payload: axios._post(`/restapi/accountsadmin/accounts/${accountId}/billing/tariff/system-change`, dataObj, dataObj),
  };
};

export const getTariffChangeInfo = accountId => {
  return {
    type: GET_USER_TARIFF_CHANGE,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/tariff/change-state-v2`),
  };
};

export const changeTariffToMaster = () => {
  return {
    type: CHANGE_USER_TARIFF,
    payload: axios._post(`/restapi/billing/tariff/jaicf_master`),
  };
};

export const addPackage = (accountId, dataObj) => {
  return {
    type: ADD_PACKAGE,
    payload: axios._post(`/restapi/billing/accountTariff/addUnique?accountId=${accountId}`, dataObj, dataObj),
  };
};

export const addMinutes = (accountId, dataObj) => {
  return {
    type: ADD_MINUTES,
    payload: axios.post(`/restapi/billing/accountTariff/addMinutes?accountId=${accountId}`, dataObj),
  };
};

export const fetchUserData = accountId => {
  return {
    type: FETCH_USER_DATA,
    payload: axios.get(`/restapi/billing/accountTariff/${accountId}/wastedState`),
  };
};

export const fetchAvailableOptionsData = accountId => {
  return {
    type: FETCH_AVAILABLE_OPTIONS_DATA,
    payload: axios.get(`/restapi/billing/accountManagerData?accountId=${accountId}`),
  };
};

export const fetchUserHistory = (accountId, searchObj) => {
  return {
    type: FETCH_USER_HISTORY,
    payload: axios.get(`/restapi/accounts/${accountId}/history/tariff`, { params: searchObj }),
  };
};

export const fetchOwnerData = ownerId => {
  return {
    type: FETCH_OWNER_DATA,
    payload: axios.get(`/restapi/users/${ownerId}`),
  };
};

export const changeManualControl = (accountId, dataObj) => {
  return {
    type: CHANGE_MANUAL_CONTROL,
    payload: axios.post(`/restapi/billing/accountTariff/manualControl?accountId=${accountId}`, dataObj),
  };
};

export const fetchPartnerData = accountId => {
  return {
    type: FETCH_PARTNER_DATA,
    payload: axios.get(`/restapi/accounts/${accountId}/partner`),
  };
};

export const fetchPartnerReferrals = partnerId => {
  return {
    type: FETCH_PARTNER_REFERRALS,
    payload: axios.get(`/restapi/partners/${partnerId}/referrals`),
  };
};

export const changePartnerData = (partnerId, dataObj) => {
  return {
    type: CHANGE_PARTNER_DATA,
    payload: axios.put(`/restapi/partners/${partnerId}`, dataObj),
  };
};

export const addPartner = (accountId, dataObj) => {
  return {
    type: ADD_PARTNER,
    payload: axios.post(`/restapi/accounts/${accountId}/partner`, dataObj),
  };
};

export const addPartnerReferral = (partnerId, dataObj) => {
  return {
    type: ADD_PARTNER_REFERRAL,
    payload: axios.post(`/restapi/partners/${partnerId}/referrals`, dataObj),
  };
};

export const movePartnerReferral = (partnerId, referralAccountId, dataObj) => {
  return {
    type: MOVE_PARTNER_REFERRAL,
    payload: axios._put(`/restapi/partners/${partnerId}/referrals/${referralAccountId}`, dataObj, dataObj),
  };
};

export const disconnectPartnerReferral = (partnerId, referralAccountId) => {
  return {
    type: DISCONNECT_PARTNER_REFERRAL,
    payload: axios.delete(`/restapi/partners/${partnerId}/referrals/${referralAccountId}`),
  };
};

export const dropReferralsErrors = () => {
  return {
    type: DROP_REFERRALS_ERRORS,
  };
};

export const dropAccountManagerDataErrors = () => {
  return {
    type: DROP_ACCOUNT_MANAGER_DATA_ERRORS,
  };
};

export const dropPartnerData = () => {
  return {
    type: DROP_PARTNER_DATA,
  };
};

export const addPackages = (accountId, dataObj) => {
  return {
    type: ADD_PACKAGES,
    payload: axios.post(`/restapi/billing/accountTariff/addPockets?accountId=${accountId}`, dataObj),
  };
};

export const deductLimits = (accountId, dataObj) => {
  return {
    type: DEDUCT_LIMITS,
    payload: axios.post(`/api/accountsadmin/c/accounts/${accountId}/decrement-additional-package`, dataObj),
  };
};

export const getReferralPartner = accountId => {
  return {
    type: GET_REFERRAL_PARTNER,
    payload: axios.get(`/restapi/accounts/${accountId}/referral-binding`),
  };
};

export const addPartnerToReferral = (ownerLogin, referralAccountId) => {
  return {
    type: ADD_PARTNER_TO_REFERRAL,
    payload: axios.post(`/restapi/partners/accounts/owner/${ownerLogin}/bind-referral/${referralAccountId}`),
  };
};
