type BuildSchedulerConfig = { maxTimeout?: number; scheduleFn?: (cb: () => void) => void };
export const buildScheduler = (config?: BuildSchedulerConfig) => {
  const callbacksMap = new Map<number, Function>();
  let callbacksIds: number[] = [];
  let currentId = 0;

  const conf = Object.assign(
    {
      maxTimeout: Infinity,
      scheduleFn: (cb: () => void) => requestAnimationFrame(cb),
    },
    config
  );

  let isPending = false;
  const run = () => {
    isPending = true;
    const startTime = Date.now();
    conf.scheduleFn(() => {
      const callbacksToExec = [...callbacksIds];
      callbacksIds = [];

      let dontExecutedCallbacks: number[] = [];
      for (let i = 0; i < callbacksToExec.length; i += 1) {
        const id = callbacksToExec[i];
        const fn = callbacksMap.get(id);
        if (!fn) continue;
        const execTime = Date.now() - startTime;
        if (i > 0 && execTime > conf.maxTimeout) {
          dontExecutedCallbacks = callbacksToExec.slice(i);
          break;
        }
        fn();
        callbacksMap.delete(id);
      }
      if (dontExecutedCallbacks.length > 0) {
        callbacksIds = dontExecutedCallbacks.concat(callbacksIds);
      }
      if (callbacksIds.length > 0) {
        run();
      } else {
        currentId = 0;
        isPending = false;
      }
    });
  };

  return (cb: () => void) => {
    const id = currentId++;
    callbacksMap.set(id, cb);
    callbacksIds.push(id);
    if (!isPending) {
      run();
    }
    return {
      cancel: () => {
        const fn = callbacksMap.get(id);
        if (fn !== cb) return;
        callbacksMap.delete(id);
      },
    };
  };
};

export const rafAppScheduler = buildScheduler({ maxTimeout: 16 });
export const cacheScheduler = buildScheduler();
