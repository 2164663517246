import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
const getPositionForDirection = (domRect, direction) => {
    switch (direction) {
        case 'left': {
            return { top: domRect.top + domRect.height / 2, left: domRect.left + domRect.width + 7 };
        }
        default: {
            return { top: domRect.top + domRect.height / 2, left: domRect.left + domRect.width + 7 };
        }
    }
};
const ErrorMessageTooltip = ({ text = 'Error', show, direction = 'left', node, position: positionProp, dataTestId, }) => {
    const [position, setPosition] = useState(undefined);
    useEffect(() => {
        if (positionProp) {
            setPosition(positionProp);
        }
        else if (node) {
            const domRect = node === null || node === void 0 ? void 0 : node.getBoundingClientRect();
            if (!domRect)
                return;
            const newPosition = getPositionForDirection(domRect, direction);
            setPosition(newPosition);
        }
        return () => {
            setPosition(undefined);
        };
    }, [direction, node, positionProp]);
    return ReactDOM.createPortal(_jsx("div", { className: cn(styles.errorTooltip, show && styles['errorTooltip--active'], styles[`arrow-${direction}`]), style: { position: 'fixed', top: position === null || position === void 0 ? void 0 : position.top, left: position === null || position === void 0 ? void 0 : position.left }, "data-test-id": dataTestId, children: text }), document.body);
};
export default React.memo(ErrorMessageTooltip);
