import {
  FETCH_USERS_LIST_PENDING,
  FETCH_USERS_LIST_REJECTED,
  FETCH_USERS_LIST_FULFILLED,
  TOGGLE_USER_CHECKED,
  SET_USERS_FILTER,
  SET_USERS_FILTER_SUBMIT_PENDING,
  SET_USERS_FILTER_SUBMIT_FULFILLED,
  CANCEL_FILTER_FULFILLED,
  SET_USERS_FILTER_SUBMIT_REJECTED,
  CANCEL_FILTER_REJECTED,
  FETCH_USERS_MESSANGER_TYPES_FULFILLED,
  FETCH_USERS_MESSANGER_TYPES_REJECTED,
  FETCH_USERS_MESSANGER_TYPES_PENDING,
  SET_SELECT_CHANNEL_CLIENTS,
  CANCEL_FILTER_PENDING,
} from '../../constants/clientsList.actions';
import { SET_SELECT_CHANNEL } from '../../constants/newsItem.actions';
import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import { CHANGE_CURRENT_PROJECT } from '../../constants/projects.actions';

const InitialState = {
  usersList: [],
  clientName: '',
  filterMessenger: [],
  joinDateFrom: null,
  joinDateTo: null,
  lastActiveDateFrom: null,
  lastActiveDateTo: null,
  fetching: false,
  fetched: false,
  error: null,
  page: 0,
  totalPages: 0,
  totalElements: '1',
  pageSize: '20',
  selectedUsers: [],
  channelTypes: [],
  newsChannelTypes: [],
  filterActive: false,
  withoutEmptyClientMessages: false,
};

export default function ClientsListReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_USERS_MESSANGER_TYPES_PENDING:
    case FETCH_USERS_LIST_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_USERS_LIST_FULFILLED:
      return {
        ...state,
        usersList: action.payload.data.content,
        fetched: true,
        fetching: false,
        pageSize: action.payload.data.size,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
      };
    case FETCH_USERS_MESSANGER_TYPES_FULFILLED: {
      return {
        ...state,
        channelTypes: action.payload.data,
        newsChannelTypes: action.payload.data,
      };
    }

    case TOGGLE_USER_CHECKED:
      const newSelectedUsers = state.selectedUsers.slice(0);
      if (newSelectedUsers.indexOf(action.id) > -1) {
        newSelectedUsers.splice(newSelectedUsers.indexOf(action.id), 1);
      } else {
        newSelectedUsers.push(action.id);
      }
      return {
        ...state,
        selectedUsers: newSelectedUsers,
      };
    case SET_SELECT_CHANNEL_CLIENTS: {
      return {
        ...state,
        filterMessenger: action.payload,
      };
    }
    case SET_SELECT_CHANNEL: {
      switch (action.payload) {
        case 'ALL': {
          return {
            ...state,
            filterMessenger: [],
            newsChannelTypes: state.channelTypes,
          };
        }
        default: {
          if (!!action.payload) {
            let arr = [];
            arr.push(action.payload);
            return {
              ...state,
              filterMessenger: [],
              newsChannelTypes: arr,
            };
          } else {
            return {
              ...state,
            };
          }
        }
      }
    }
    case SET_USERS_FILTER: {
      return {
        ...state,
        filterActive: true,
        clientName: action.payload?.name || '',
        filterMessenger: action.payload?.filterMessenger || state.filterMessenger,
        joinDateFrom: action.payload?.joinDateFrom || null,
        joinDateTo: action.payload?.joinDateTo || null,
        lastActiveDateFrom: action.payload?.lastActiveDateFrom || null,
        lastActiveDateTo: action.payload?.lastActiveDateTo || null,
        withoutEmptyClientMessages: action.payload?.withoutEmptyClientMessages || false,
        page: 0,
      };
    }

    case CANCEL_FILTER_PENDING:
    case SET_USERS_FILTER_SUBMIT_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case SET_USERS_FILTER_SUBMIT_FULFILLED: {
      return {
        ...state,
        usersList: action.payload.data.content,
        totalElements: action.payload.data.totalElements,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        filterActive: true,
        clientName: action.payload.config.params.clientName,
        withoutEmptyClientMessages: action.payload.withoutEmptyClientMessages || false,
        fetching: false,
      };
    }

    case CANCEL_FILTER_FULFILLED: {
      return {
        ...state,
        fetching: false,
        clientName: '',
        filterMessenger: [],
        joinDateFrom: null,
        joinDateTo: null,
        lastActiveDateFrom: null,
        lastActiveDateTo: null,
        filterActive: false,
        usersList: action.payload.data.content,
        totalElements: action.payload.data.totalElements,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        withoutEmptyClientMessages: InitialState.withoutEmptyClientMessages,
      };
    }

    case FETCH_USERS_MESSANGER_TYPES_REJECTED:
    case SET_USERS_FILTER_SUBMIT_REJECTED:
    case CANCEL_FILTER_REJECTED:
    case FETCH_USERS_LIST_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case CHANGE_CURRENT_PROJECT:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      return {
        ...InitialState,
      };
    }

    default:
      return state;
  }
}
