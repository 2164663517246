import React from 'react';
import moment from 'moment';
import { Button, usePromisifyComponent, Icon } from '@just-ai/just-ui';
import { KnowledgeHubStatus } from '@just-ai/api/dist/generated/Editorbe';

import { t, tWithCheck } from 'localization';

import { cloudDomainsService } from '../../external';
import { KnowledgeBaseIntegration } from '../../domain/types';

import AddExistedKhubTokenByName from '../AddExistedKhubTokenByName';
import ChangeKhubTokenModal from '../ChangeKhubTokenModal';
import { ListTable } from './ListTable';

import styles from './styles.module.scss';

const getStatusColor = (status: KnowledgeBaseIntegration['status']): string => {
  switch (status) {
    case KnowledgeHubStatus.ACTIVE:
      return 'green';
    case KnowledgeHubStatus.CREATED:
    case KnowledgeHubStatus.INGESTINGDOCUMENTS:
      return 'gray';
    case KnowledgeHubStatus.DELETED:
    case KnowledgeHubStatus.INGESTFAILED:
    case KnowledgeHubStatus.SECRETNOTFOUND:
    case KnowledgeHubStatus.TOKENNOTVALID:
      return 'red';
    default:
      return 'gray';
  }
};

interface IntegrationsListProps {
  list: KnowledgeBaseIntegration[];
  canEdit?: boolean;
  onChangeList: () => void;
  rowActionsCellRender?: (row: KnowledgeBaseIntegration) => React.ReactNode;
  rowOptionsCellRender?: (row: KnowledgeBaseIntegration) => React.ReactNode;
}
export const IntegrationsList = React.memo(
  ({ list, onChangeList, rowOptionsCellRender, rowActionsCellRender, canEdit }: IntegrationsListProps) => {
    const [changeTokenModalNode, showChangeTokenModal] = usePromisifyComponent(
      (resolve, reject, opened, props?: { integration: KnowledgeBaseIntegration }) => {
        if (!opened || !props?.integration) return;
        return (
          <ChangeKhubTokenModal
            key={props.integration.secretName}
            isOpen={opened}
            onCancel={reject}
            onSubmit={resolve}
            secretName={props.integration.secretName}
          />
        );
      },
      { onResolve: onChangeList }
    );

    const [addTokenModalNode, showAddTokenModal] = usePromisifyComponent(
      (resolve, reject, opened, props?: { integration: KnowledgeBaseIntegration }) => {
        if (!opened || !props?.integration) return;
        return (
          <AddExistedKhubTokenByName
            key={props.integration.secretName}
            isOpen={opened}
            onCancel={reject}
            onSubmit={resolve}
            secretName={props.integration.secretName}
          />
        );
      },
      { onResolve: onChangeList }
    );

    return (
      <div className={styles.IntegrationsList}>
        <ListTable
          elements={list}
          elementId={el => el.integrationId.toString()}
          columns={[
            {
              title: t('KhubIntegration:IntegrationList:Col:Name'),
              code: 'knowledgeBaseName',
              tdProps: { className: styles.IntegrationsList__titleRow },
              width: '30%',
              renderCell: el => (
                <div className={styles.titleWrapper}>
                  <span className={styles.title}>{el.secretName}</span>
                  {el.knowledgeBaseName && <span className={styles.subTitle}>{el.knowledgeBaseName}</span>}
                </div>
              ),
            },
            {
              title: t('KhubIntegration:IntegrationList:Col:UpdatedAt'),
              code: 'audit.updatedAt',
              width: '20%',
              tdProps: { className: styles.IntegrationsList__updatedAtRow },
              renderCell: el => (el.updateAt ? <span>{moment.unix(el.updateAt).format('L LT')}</span> : null),
            },
            {
              title: t('KhubIntegration:IntegrationList:Col:Status'),
              code: 'status',
              width: '20%',
              tdProps: { className: styles.IntegrationsList__statusRow },
              renderCell: el => (
                <>
                  <span className={`status-dot ${getStatusColor(el.status)}`}></span>
                  {tWithCheck(`KhubIntegration:Status:${el.status}`) ?? el.status}
                </>
              ),
            },
            {
              title: '',
              code: 'actions',
              width: '160px',
              tdProps: { className: styles.IntegrationsList__actionsRow },
              renderCell: el => {
                switch (el.status) {
                  case KnowledgeHubStatus.ACTIVE:
                  case KnowledgeHubStatus.DELETED:
                    return null;
                  case KnowledgeHubStatus.CREATED:
                  case KnowledgeHubStatus.INGESTINGDOCUMENTS:
                  case KnowledgeHubStatus.INGESTFAILED:
                    if (!el.knowledgeBaseId) return null;
                    return (
                      <a
                        href={cloudDomainsService.getFullDomainPath('khub') + '/hubs/' + el.knowledgeBaseId}
                        target='_blank'
                        rel='noreferrer'
                        data-test-id={`KhubIntegration.${el.secretName}.GoToKnowledgeBase`}
                        className={styles.link}
                      >
                        <Icon name='farExternalLink' />
                        {t('KhubIntegration:IntegrationList:Action:GoToKnowledgeBase')}
                      </a>
                    );
                  case KnowledgeHubStatus.SECRETNOTFOUND:
                    if (!canEdit) return null;
                    return (
                      <Button
                        size='sm'
                        color='primary'
                        data-test-id={`KhubIntegration.${el.secretName}.AddApiKey`}
                        onClick={() => showAddTokenModal({ integration: el }).catch(() => {})}
                      >
                        {t('KhubIntegration:IntegrationList:Action:AddApiKey')}
                      </Button>
                    );
                  case KnowledgeHubStatus.TOKENNOTVALID:
                    if (!canEdit) return null;
                    return (
                      <Button
                        size='sm'
                        color='primary'
                        data-test-id={`KhubIntegration.${el.secretName}.ReplaceApiKey`}
                        onClick={() => showChangeTokenModal({ integration: el }).catch(() => {})}
                      >
                        {t('KhubIntegration:IntegrationList:Action:ReplaceApiKey')}
                      </Button>
                    );
                  default:
                    return null;
                }
              },
            },
            {
              title: '',
              code: 'links',
              width: '80px',
              tdProps: { className: styles.IntegrationsList__linksRow },
              renderCell: rowActionsCellRender,
            },
            {
              title: '',
              code: 'options',
              width: '70px',
              tdProps: { className: styles.IntegrationsList__optionsRow },
              renderCell: rowOptionsCellRender,
            },
          ]}
        />
        {changeTokenModalNode}
        {addTokenModalNode}
      </div>
    );
  }
);
