var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UncontrolledDropdown, DropdownToggle as RsDropdownToggle, DropdownItem as RsDropdownItem, Dropdown as RsDropdown, DropdownMenu as RsDropdownMenu, } from 'reactstrap';
import { Icon } from '../Icon';
import './Dropdown.scss';
import classNames from 'classnames';
export const DropdownButton = (_a) => {
    var { className, direction = 'right', compact, dark } = _a, restProps = __rest(_a, ["className", "direction", "compact", "dark"]);
    return (_jsx(UncontrolledDropdown, Object.assign({ className: classNames('justui_dropdown', 'justui_dropdown-button', { dark: dark, compact: compact }, className), direction: direction }, restProps)));
};
export const DropdownToggle = (_a) => {
    var { className, children, caret, iconButton, compact, dark, color, outline } = _a, restProps = __rest(_a, ["className", "children", "caret", "iconButton", "compact", "dark", "color", "outline"]);
    return (_jsxs(RsDropdownToggle, Object.assign({ color: dark ? 'none' : color, className: classNames('justui_dropdown-toggle', {
            'justui_icon-button': iconButton,
            'compact btn-flat': compact,
            'btn-white': dark,
        }, className), outline: outline === null || outline === void 0 ? void 0 : outline.toString() }, restProps, { children: [children, caret ? (_jsx(Icon, { name: compact ? 'faCaretDown' : 'faAngleDown', size: compact ? 'xs' : '1x', className: 'dropdown-caret' })) : null] })));
};
export const DropdownItem = (_a) => {
    var { children, style, danger = false, iconRight, iconLeft, iconColor, iconSize } = _a, restProps = __rest(_a, ["children", "style", "danger", "iconRight", "iconLeft", "iconColor", "iconSize"]);
    return (_jsxs(RsDropdownItem, Object.assign({ style: Object.assign({ cursor: 'pointer' }, style), className: classNames('justui_dropdown-item', restProps.className, { 'dropdown-item-danger': danger }) }, restProps, { children: [iconLeft ? _jsx(Icon, { name: iconLeft, size: iconSize || 'sm', color: iconColor }) : null, children, iconRight ? _jsx(Icon, { name: iconRight, size: iconSize || 'sm', color: iconColor }) : null] })));
};
export const Dropdown = (_a) => {
    var { className, compact, dark } = _a, restProps = __rest(_a, ["className", "compact", "dark"]);
    return (_jsx(RsDropdown, Object.assign({ className: classNames('justui_dropdown', { compact, dark }, className) }, restProps)));
};
export const DropdownMenu = (_a) => {
    var { className } = _a, restProps = __rest(_a, ["className"]);
    return (_jsx(RsDropdownMenu, Object.assign({ className: classNames('justui_dropdown-menu', className) }, restProps)));
};
