import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../Icon';
import './Announcement.scss';
import classNames from 'classnames';
import { Button } from '../Button';
const IconByAnnouncementType = {
    success: 'faCheckSquare',
    info: 'faInfoCircle',
    danger: 'faExclamationCircle',
    warning: 'faExclamationTriangle',
};
export function Announcement({ AnnouncementText, onClose, type = 'info', action, dark = false, dataTestId, iconName, iconSize, className = '', }) {
    return (_jsxs("div", { "data-test-id": dataTestId || 'Announcement.Container', className: classNames(className, 'justui_announcement', type, { dark: dark }), children: [_jsx(Icon, { wrapperClassName: 'justui_announcement-icon-type', name: iconName || IconByAnnouncementType[type], size: iconSize, color: type }), _jsx("div", { className: 'justui_announcement-text', children: _jsx(AnnouncementText, {}) }), action && (_jsx("div", { className: 'justui_announcement-buttons', children: Array.isArray(action) ? (action.map(actionItem => (_jsx(Button, { size: 'sm', color: type, onClick: actionItem.callback, children: actionItem.text }, `Announcement-button-${actionItem.text}`)))) : (_jsx(Button, { size: 'sm', color: type, onClick: action.callback, children: action.text })) })), onClose && (_jsx("div", { className: 'justui_announcement-close', "data-test-id": 'Announcement.CloseButton', onClick: onClose, children: _jsx(Icon, { name: 'faTimes', color: 'secondary', size: 'sm' }) }))] }));
}
