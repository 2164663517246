import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface WarningBannerInterface {
  children: React.ReactNode;
  className?: string;
  'data-test-id'?: string;
}
const WarningBanner = function ({ children, className, 'data-test-id': dataTestId }: WarningBannerInterface) {
  return (
    <div className={cn(styles.EmptyWarningBanner, className)} data-test-id={dataTestId}>
      <div className='flex-row gap-2x'>{children}</div>
    </div>
  );
};

export default WarningBanner;
