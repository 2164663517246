import React, { FC } from 'react';
import { Checkbox, FormGroup } from '@just-ai/just-ui';
import { t } from 'localization';
import classes from './CommonActivationParams.module.scss';

import classNames from 'classnames';

import { CommonActivationSelectState } from './CommonActivationSelectState';
import { NewCommonActivationSelectState } from 'modules/JGraph/components/NewCommonActivationsParams';

type CommonActivationParamsProps = {
  isGlobal: boolean;
  onChangeIsGlobal: (value: boolean) => unknown;
  onChangeOnlyThisState: (value: boolean) => unknown;
  onChangeStateValue: (value: string) => unknown;
  fromStateValue: string;
  toStateValue: string;
  onlyThisState?: boolean;
  isFromState?: boolean;
  isToState?: boolean;
  isIntentMenu?: boolean;
};

export const CommonActivationParams: FC<CommonActivationParamsProps> = ({
  isGlobal,
  onChangeIsGlobal,
  onlyThisState,
  onChangeOnlyThisState,
  fromStateValue,
  toStateValue,
  onChangeStateValue,
  isFromState,
  isToState,
  isIntentMenu,
}) => {
  const showNewIntentMenu = toStateValue && isIntentMenu;

  return (
    <>
      {!isToState && (
        <FormGroup tag='div' className={classes.formGroup}>
          <Checkbox
            label={
              <div className={classes.checkboxWithSmall}>
                <div>{t('EventActivation:make_global')}</div>
                <small>{t('EventActivation:make_global_description')}</small>
              </div>
            }
            name='global'
            value={isGlobal}
            onChange={onChangeIsGlobal}
            data-test-id='EventActivation:make_global'
          />
        </FormGroup>
      )}
      <FormGroup tag='div' className='form-group-fix'>
        {showNewIntentMenu ? (
          <NewCommonActivationSelectState
            fromStateValue={fromStateValue}
            toStateValue={toStateValue}
            isGlobal={isGlobal}
            isFromState={isFromState}
            isToState={isToState}
            onChangeStateValue={onChangeStateValue}
            onChangeOnlyThisState={onChangeOnlyThisState}
            onlyThisState={onlyThisState}
          />
        ) : (
          <CommonActivationSelectState
            fromStateValue={fromStateValue}
            toStateValue={toStateValue}
            isGlobal={isGlobal}
            isFromState={isFromState}
            isToState={isToState}
            onChangeStateValue={onChangeStateValue}
          />
        )}
      </FormGroup>
      {!showNewIntentMenu && (
        <FormGroup tag='div' className={classNames(classes.formGroup, { [classes.formGroupDisabled]: isGlobal })}>
          <Checkbox
            disabled={isGlobal}
            data-test-id='EventActivation:onlyThisState'
            label={
              <div className={classes.checkboxWithSmall}>
                <div>
                  {isToState ? t('EventActivation:onlyThisStateAsToState') : t('EventActivation:onlyThisState')}
                </div>
                {(!isFromState || !isToState) && (
                  <small>
                    {isToState
                      ? t('EventActivation:onlyThisStateAsToStateDescription')
                      : t('EventActivation:onlyThisStateDescription')}
                  </small>
                )}
              </div>
            }
            name='onlyThisState'
            value={onlyThisState}
            onChange={onChangeOnlyThisState}
          />
        </FormGroup>
      )}
    </>
  );
};
