import React, { FC } from 'react';

import { TaskStatusData } from '../types';

import { getNotificationViewInfoForBillingReportTask } from './NotificationByTypes/BillingReportTask';

export interface TaskProps {
  task: TaskStatusData;
  notificationViewInfo: NotificationViewInfo;
  onDelete?: (task: TaskStatusData) => void;
  onlyContent?: boolean;
  forAccount?: boolean;
}

export type NotificationViewInfo = {
  component: FC<TaskProps>;
  title: string;
  description?: string;
  isShowInAlert?: boolean;
  event: TaskStatusData;
};
export function getNotificationViewInfo(task: TaskStatusData, forAccount?: boolean): NotificationViewInfo | undefined {
  if (task.message.code.code.startsWith('reporter.reports.report_generation')) {
    return getNotificationViewInfoForBillingReportTask(task, forAccount);
  }
}

const Notification = React.forwardRef(
  (props: Omit<TaskProps, 'notificationViewInfo'>, ref: React.Ref<HTMLDivElement>) => {
    const viewInfo = getNotificationViewInfo(props.task, props.forAccount);
    if (!viewInfo) return <div ref={ref} />;
    const Cmp = viewInfo.component;
    return (
      <div ref={ref}>
        <Cmp {...props} notificationViewInfo={viewInfo} />
      </div>
    );
  }
);

Notification.displayName = 'Notification';

export default Notification;
