import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import cn from 'classnames';
import { Icon, Tooltip, useTranslation } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';
import usePressKey from '@just-ai/just-ui/dist/utils/usePressKey';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toggleIntentItemType } from '../utils';
import styles from './styles.module.scss';
const PhraseRowForInsertion = function ({ item, onChange, onInsert, index, writeDisabled }, ref) {
    const iconRef = useRef(null);
    const { t } = useTranslation();
    const onPress = usePressKey({
        key: 'Enter',
    }, onInsert);
    return (_jsxs("div", { "data-test-id": `AlternativeQuestion.Row.id-${index}`, ref: ref, className: cn(styles.phraseRow, 'edit-phrase-block'), children: [_jsxs("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--prepend input-group-prepend'), children: [_jsx(IconButton, { id: `changePhraseType-${item.id}`, "data-test-id": 'changePhraseType', tabIndex: 0, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), name: item.type === IntentItemType.phrase ? 'farText' : 'faAt', disabled: writeDisabled, onClick: () => {
                            onChange(toggleIntentItemType(item));
                        } }), _jsx(Tooltip, { target: `changePhraseType-${item.id}`, className: styles.phraseRow__tooltip, boundariesElement: 'window', placement: 'top', "data-test-id": 'changePhraseType.tooltip', children: item.type === IntentItemType.phrase ? t('FAQ:Phrases:ChangeTypeText') : t('FAQ:Phrases:ChangeTypePhrase') })] }), _jsxs("div", { className: styles.phraseRow__part, children: [_jsx(TextAreaLikeElement, { id: `PhraseRow-${item.id}`, dataTestId: 'PhraseRow.input', value: item.text, richValue: item.text, placeholder: item.type === IntentItemType.phrase ? t('FAQ:Phrase:Placeholder') : t('FAQ:Text:Placeholder'), onChange: text => onChange(Object.assign(Object.assign({}, item), { text })), onKeyDown: onPress, readOnly: writeDisabled, autoFocus: true }), item.error && (_jsxs(_Fragment, { children: [_jsx("div", { ref: iconRef, style: { display: 'flex' }, children: _jsx(Icon, { name: 'faExclamationCircle', color: 'danger' }) }), _jsx(Tooltip, { target: iconRef, children: t('PhraseBlocks:validationTooltip') })] }))] }), _jsxs("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--append input-group-append'), children: [_jsx(IconButton, { id: `insertPhrase-${item.id}`, "data-test-id": 'insertPhrase', tabIndex: 0, name: 'farCheck', onClick: onInsert, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), disabled: writeDisabled }), _jsx(Tooltip, { target: `insertPhrase-${item.id}`, className: styles.phraseRow__tooltip, placement: 'top', boundariesElement: 'window', "data-test-id": 'insertPhrase.tooltip', children: t('FAQ:Phrases:InsertTooltip') })] })] }));
};
export default React.memo(React.forwardRef(PhraseRowForInsertion));
