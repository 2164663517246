import React from 'react';
import isAccess, {
  isInternal,
  isLoggedInAccount,
  isAccountUser,
  isSystemAccess,
  isSAdmin,
  isEuroInstance,
} from '../../isAccessFunction';
import { NavLink } from 'react-router-dom';
import { t } from '../../localization';
import './Unprojected.scss';
import { Icon } from '@just-ai/just-ui';
import { Button } from '@just-ai/just-ui/dist';
import { createPlanPaymentString } from '../../utils';
import { useProjectGroupsContext } from '../../modules/ProjectGroups/context';
import { cloudDomainsService } from 'services/CloudDomainsService';

type UserType = {
  fullName: string;
  email: string;
  login: string;
  accountOwner: boolean;
  account: {
    id: number;
  };
  logInAccount: {
    accountId: number;
  };
};

type propsType = {
  dialerEnabled: boolean;
  telephonyEnabled: boolean;
  billingEnabled: boolean;
  logout: () => void;
  currentUser: UserType;
  tariffData: any;
  countryIsoCode: string;
};

type linkType = {
  to: string;
  dataTestId: string;
  label: string;
};

function getPersonalLinks(props: propsType): linkType[] {
  const accoundId = isSAdmin() ? props.currentUser?.logInAccount?.accountId : props.currentUser?.account?.id;
  return [
    {
      to: '/c/profile',
      dataTestId: 'unprojectedSidebar.accountNavLink',
      label: 'Profile',
    },
    (isAccess(['ROLES_WRITE']) || isAccess(['ROLES_READ']) || props.currentUser?.accountOwner) &&
      accoundId && {
        to: `/c/accounts/${accoundId}`,
        dataTestId: 'unprojectedSidebar.accountsNavLink',
        label: 'Account',
      },
    props.currentUser?.accountOwner && {
      to: '/project-reports',
      dataTestId: 'unprojectedSidebar.ProjectReports',
      label: 'Sidebar:ProjectReports',
    },
    (isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) &&
      props.billingEnabled && {
        to: '/accountmanager',
        dataTestId: 'unprojectedSidebar.userlogNavLink',
        label: 'Sidebar: tariff and limits',
      },
  ].filter(link => Boolean(link)) as linkType[];
}

function getLinksList(props: propsType): linkType[] {
  return [
    isAccess('ACCOUNTS_READ') &&
      isInternal() &&
      !isLoggedInAccount() && {
        to: `/c/accounts`,
        dataTestId: 'unprojectedSidebar.accountsNavLink',
        label: 'Accounts',
      },

    isAccess('SPECIFIC_ACCOUNT_MANAGEMENT') && {
      to: '/tokens',
      dataTestId: 'unprojectedSidebar.tokens',
      label: 'TokensManagement',
    },
    !isAccess('analytics.hide_labels') && {
      to: '/tagsettings',
      dataTestId: 'unprojectedSidebar.tagSettingsNavLink',
      label: 'Directories of tags',
    },
    {
      to: '/calllists',
      dataTestId: 'unprojectedSidebar.callListNavLink',
      label: 'Client lists',
    },
    (isAccess(['SIP_TRUNK_READ']) || isInternal()) &&
      !isAccountUser() &&
      props.telephonyEnabled && {
        to: '/connectionslist',
        dataTestId: 'unprojectedSidebar.connectionsListNavLink',
        label: 'Telephony settings',
      },
    isAccess('AUDIT_READ') && {
      to: '/userlog',
      dataTestId: 'unprojectedSidebar.userlogNavLink',
      label: 'User log',
    },
    isAccess('MASKING_RULE_READ') && {
      to: '/logsmasking',
      dataTestId: 'unprojectedSidebar.logsmaskingNavLink',
      label: 'Logs:LogsMasking.navLink',
    },
  ].filter(link => Boolean(link)) as linkType[];
}

const Unprojected = (props: propsType) => {
  const { setIsLeaveGroupModalOpen } = useProjectGroupsContext();
  return (
    <nav className='Unprojected_container'>
      {props.currentUser && (
        <>
          <PersonalInfo tariffData={props.tariffData} user={props.currentUser} />
          <div className='divider' />
        </>
      )}
      {getPersonalLinks(props).map(link => (
        <SidebarLink {...link} key={link.to} />
      ))}
      <div className='divider' />
      <SidebarLink to='/imputer' dataTestId='unprojectedSidebar.logsmaskingNavLink' label='Imputer: sidebar label' />
      <div className='divider' />
      {getLinksList(props).map(link => (
        <SidebarLink {...link} key={link.to} />
      ))}
      {isSystemAccess(['botadmin_partner_feature']) && props.countryIsoCode === 'RU' && (
        <>
          <div className='divider' />
          <a
            href={t(isEuroInstance() ? 'partnership link Tovie AI' : 'partnership link')}
            target='_blank'
            rel='noreferrer noopenner'
            data-test-id='userHeader.becomePartner'
          >
            <span>{t('become partner')} &nbsp;</span>
            <Icon color='secondary' size='xs' name='farExternalLink' />
          </a>
        </>
      )}
      {!isInternal() && !props.currentUser?.accountOwner && (
        <>
          <div className='divider' />
          <Button size='lg' color='danger' flat onClick={() => setIsLeaveGroupModalOpen(true)}>
            {t('ProjectGroupSelector:LeaveGroup')}
          </Button>
        </>
      )}
      <div className='divider' />
      <Button size='lg' flat onClick={props.logout}>
        {t('Exit')}
      </Button>
    </nav>
  );
};

function SidebarLink({ to, dataTestId, label }: linkType) {
  if (to.startsWith('/c/')) {
    return (
      <a data-test-id={dataTestId} href={`${cloudDomainsService.getFullDomainPath('cc')}${to}`}>
        {t(label)}
      </a>
    );
  }
  return (
    <NavLink to={to} activeClassName='active' data-test-id={dataTestId}>
      <span className='nav-item-name'>{t(label)}</span>
    </NavLink>
  );
}

export function PersonalInfo({ user, tariffData }: { user: UserType; tariffData: any }) {
  const paymentInfo = tariffData
    ? createPlanPaymentString(
        tariffData.blockType,
        tariffData.demo,
        tariffData.dueDate,
        tariffData.manualControl,
        true,
        tariffData.price,
        true
      )
    : '';
  return (
    <div className='Unprojected_personalInfo'>
      <div>
        <b>{Boolean(user.fullName) ? user.fullName : user.email || user.login}</b>
      </div>
      {isSystemAccess(['botadmin_billing_feature']) && (
        <>
          <div>
            {typeof paymentInfo === 'string' || !paymentInfo ? (
              paymentInfo || tariffData.tariffName
            ) : (
              <>
                {tariffData && (isSAdmin() ? tariffData.tariffName : t(tariffData.tariffName))}
                &nbsp;
                <span className='Unprojected_date'>
                  {t('to')}&nbsp;{(paymentInfo as any).format('DD MMMM')}
                </span>
              </>
            )}
          </div>
          <NavLink to='/plans' activeClassName='active' data-test-id='unprojectedSidebar.plans'>
            <span>{t('Sidebar: all plans')}</span>
          </NavLink>
        </>
      )}
    </div>
  );
}

export default Unprojected;
