const prefix = '@dialogsDetail/';
export const LOAD_DETAIL = prefix + 'LOAD_DETAIL';
export const LOAD_DETAIL_PENDING = prefix + 'LOAD_DETAIL_PENDING';
export const LOAD_DETAIL_REJECTED = prefix + 'LOAD_DETAIL_REJECTED';
export const LOAD_DETAIL_FULFILLED = prefix + 'LOAD_DETAIL_FULFILLED';

export const LOAD_SESSION_LABELS = prefix + 'LOAD_SESSION_LABELS';
export const LOAD_SESSION_LABELS_PENDING = prefix + 'LOAD_SESSION_LABELS_PENDING';
export const LOAD_SESSION_LABELS_REJECTED = prefix + 'LOAD_SESSION_LABELS_REJECTED';
export const LOAD_SESSION_LABELS_FULFILLED = prefix + 'LOAD_SESSION_LABELS_FULFILLED';

export const LOAD_TASK_LABELS = prefix + 'LOAD_TASK_LABELS';
export const LOAD_TASK_LABELS_PENDING = prefix + 'LOAD_TASK_LABELS_PENDING';
export const LOAD_TASK_LABELS_REJECTED = prefix + 'LOAD_TASK_LABELS_REJECTED';
export const LOAD_TASK_LABELS_FULFILLED = prefix + 'LOAD_TASK_LABELS_FULFILLED';

export const LOAD_TASK_CREATION_DATA = prefix + 'LOAD_TASK_CREATION_DATA';
export const LOAD_TASK_CREATION_DATA_PENDING = prefix + 'LOAD_TASK_CREATION_DATA_PENDING';
export const LOAD_TASK_CREATION_DATA_REJECTED = prefix + 'LOAD_TASK_CREATION_DATA_REJECTED';
export const LOAD_TASK_CREATION_DATA_FULFILLED = prefix + 'LOAD_TASK_CREATION_DATA_FULFILLED';

export const LOAD_USERS = prefix + 'LOAD_USERS';
export const LOAD_USERS_PENDING = prefix + 'LOAD_USERS_PENDING';
export const LOAD_USERS_REJECTED = prefix + 'LOAD_USERS_REJECTED';
export const LOAD_USERS_FULFILLED = prefix + 'LOAD_USERS_FULFILLED';

export const ADD_LOG_LABELS = prefix + 'ADD_LOG_LABELS';
export const ADD_LOG_LABELS_PENDING = prefix + 'ADD_LOG_LABELS_PENDING';
export const ADD_LOG_LABELS_REJECTED = prefix + 'ADD_LOG_LABELS_REJECTED';
export const ADD_LOG_LABELS_FULFILLED = prefix + 'ADD_LOG_LABELS_FULFILLED';

export const ADD_LOG_LABELS_FAST_FULFILLED = prefix + 'ADD_LOG_LABELS_FAST_FULFILLED';

export const ADD_LOG_TEXTAREA = prefix + 'ADD_LOG_TEXTAREA';
export const ADD_LOG_TEXTAREA_PENDING = prefix + 'ADD_LOG_TEXTAREA_PENDING';
export const ADD_LOG_TEXTAREA_REJECTED = prefix + 'ADD_LOG_TEXTAREA_REJECTED';
export const ADD_LOG_TEXTAREA_FULFILLED = prefix + 'ADD_LOG_TEXTAREA_FULFILLED';

export const ADD_LOG_TASK = prefix + 'ADD_LOG_TASK';
export const ADD_LOG_TASK_PENDING = prefix + 'ADD_LOG_TASK_PENDING';
export const ADD_LOG_TASK_REJECTED = prefix + 'ADD_LOG_TASK_REJECTED';
export const ADD_LOG_TASK_FULFILLED = prefix + 'ADD_LOG_TASK_FULFILLED';

export const UPDATE_LOG_TASK = prefix + 'UPDATE_LOG_TASK';
export const UPDATE_LOG_TASK_PENDING = prefix + 'UPDATE_LOG_TASK_PENDING';
export const UPDATE_LOG_TASK_REJECTED = prefix + 'UPDATE_LOG_TASK_REJECTED';
export const UPDATE_LOG_TASK_FULFILLED = prefix + 'UPDATE_LOG_TASK_FULFILLED';

export const ADD_SESSION_TASK = prefix + 'ADD_SESSION_TASK';
export const ADD_SESSION_TASK_PENDING = prefix + 'ADD_SESSION_TASK_PENDING';
export const ADD_SESSION_TASK_REJECTED = prefix + 'ADD_SESSION_TASK_REJECTED';
export const ADD_SESSION_TASK_FULFILLED = prefix + 'ADD_SESSION_TASK_FULFILLED';

export const UPDATE_SESSION_TASK = prefix + 'UPDATE_SESSION_TASK';
export const UPDATE_SESSION_TASK_PENDING = prefix + 'UPDATE_SESSION_TASK_PENDING';
export const UPDATE_SESSION_TASK_REJECTED = prefix + 'UPDATE_SESSION_TASK_REJECTED';
export const UPDATE_SESSION_TASK_FULFILLED = prefix + 'UPDATE_SESSION_TASK_FULFILLED';

export const MARK_SESSION_LOG = prefix + 'MARK_SESSION_LOG';
export const MARK_SESSION_LOG_PENDING = prefix + 'MARK_SESSION_LOG_PENDING';
export const MARK_SESSION_LOG_REJECTED = prefix + 'MARK_SESSION_LOG_REJECTED';
export const MARK_SESSION_LOG_FULFILLED = prefix + 'MARK_SESSION_LOG_FULFILLED';

export const SET_PAGE = prefix + 'SET_PAGE';
export const SET_SELECTED_MARKS = prefix + 'SET_SELECTED_MARKS';
export const SET_SELECT_PHRASE_LABELS = prefix + 'SET_SELECT_PHRASE_LABELS';
export const SET_SELECTED_MAIN_MARKS = prefix + 'SET_SELECTED_MAIN_MARKS';
export const SET_SELECTED_LOG_MARKS = prefix + 'SET_SELECTED_LOG_MARKS';
export const MAIN_SELECTED_MARKS = prefix + 'MAIN_SELECTED_MARKS';
export const MARK_SESSION_ISSUE = prefix + 'MARK_SESSION_ISSUE';

export const SET_CURRENT_INDEX = prefix + 'SET_CURRENT_INDEX';

export const SEL_LAST_SELECTED_User = prefix + 'SEL_LAST_SELECTED_User';
export const SEL_LAST_SELECTED_Priority = prefix + 'SEL_LAST_SELECTED_Priority';

export const GET_ISSUES_BY_QUESTION_ID = prefix + 'GET_ISSUES_BY_QUESTION_ID';
export const GET_ISSUES_BY_QUESTION_ID_PENDING = prefix + 'GET_ISSUES_BY_QUESTION_ID_PENDING';
export const GET_ISSUES_BY_QUESTION_ID_REJECTED = prefix + 'GET_ISSUES_BY_QUESTION_ID_REJECTED';
export const GET_ISSUES_BY_QUESTION_ID_FULFILLED = prefix + 'GET_ISSUES_BY_QUESTION_ID_FULFILLED';
