import {
  CHECK_CHECK_BOX,
  DROP_FILTER_FLOW_FULFILLED,
  DROP_FILTER_FLOW_PENDING,
  FETCH_BOTS_TYPES_FULFILLED,
  FETCH_USERS_MESSANGER_TYPES_FULFILLED,
  FETCH_USERS_MESSANGER_TYPES_PENDING,
  FLOW_LOAD_FULFILLED,
  FLOW_LOAD_PENDING,
  SET_BOT_CHANNEL,
  SET_FILTER_CHANNEL,
  SET_FILTER_DATE,
  SET_FILTER_FLOW_FULFILLED,
  SET_FILTER_FLOW_PENDING,
  SET_PAGINATE_FULFILLED,
  SET_PAGINATE_PENDING,
  SET_STATE_FILTER_FLOW_FULFILLED,
  SET_STATE_FILTER_FLOW_PENDING,
  STATE_CHANGE_FULFILLED,
  STATE_CHANGE_PENDING,
  DROP_DATA_FLOW,
} from '../../constants/pageFlow.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import { getCurrentDate } from '../../pipes/functions';

const InitialState = {
  flow: [],
  fetching: false,
  nextPages: [],
  prevPages: [],
  filterActive: false,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  page: 0,
  channels: [],

  filterDateFrom: getCurrentDate()[0],
  filterDateTo: getCurrentDate()[1],
  filterChannel: null,
  filterBotOptions: null,
  bots: [],
  checkBox: false,
};

export default function PageFlowReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_FILTER_FLOW_PENDING:
    case DROP_FILTER_FLOW_PENDING:
    case FETCH_USERS_MESSANGER_TYPES_PENDING:
    case FLOW_LOAD_PENDING:
    case SET_STATE_FILTER_FLOW_PENDING:
    case STATE_CHANGE_PENDING:
    case SET_PAGINATE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case CHECK_CHECK_BOX: {
      return {
        ...state,
        checkBox: action.payload,
      };
    }
    case FETCH_BOTS_TYPES_FULFILLED: {
      return {
        ...state,
        bots: action.payload.data,
      };
    }
    case FETCH_USERS_MESSANGER_TYPES_FULFILLED: {
      return {
        ...state,
        channels: action.payload.data,
        fetching: false,
      };
    }
    case SET_PAGINATE_FULFILLED: {
      return {
        ...state,
        flow: action.payload.data.content,
        page: action.payload.data.number,
        numberOfElements: action.payload.data.numberOfElements,
        totalElements: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        fetching: false,
      };
    }
    case DROP_FILTER_FLOW_FULFILLED:
    case FLOW_LOAD_FULFILLED: {
      return {
        ...state,
        flow: action.payload.data.content,
        page: action.payload.data.number,
        numberOfElements: action.payload.data.numberOfElements,
        totalElements: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        fetching: false,
        filterActive: false,
        nextPages: [],
        prevPages: [],
        filterDateFrom: getCurrentDate()[0],
        filterDateTo: getCurrentDate()[1],
        filterChannel: null,
        filterBotOptions: null,
        checkBox: false,
      };
    }
    case DROP_DATA_FLOW: {
      return {
        ...state,
        flow: InitialState.flow,
        page: InitialState.page,
        numberOfElements: InitialState.numberOfElements,
        totalElements: InitialState.totalElements,
        totalPages: InitialState.totalPages,
        fetching: false,
        filterActive: false,
        nextPages: InitialState.nextPages,
        prevPages: InitialState.prevPages,
        filterDateFrom: InitialState.filterDateFrom,
        filterDateTo: InitialState.filterDateTo,
        filterChannel: InitialState.filterChannel,
        filterBotOptions: InitialState.filterBotOptions,
        checkBox: InitialState.checkBox,
      };
    }
    case SET_FILTER_FLOW_FULFILLED: {
      return {
        ...state,
        flow: action.payload.data.content,
        page: action.payload.data.number,
        numberOfElements: action.payload.data.numberOfElements,
        totalElements: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        fetching: false,
        filterActive: true,
        nextPages: [],
        prevPages: [],
      };
    }
    case STATE_CHANGE_FULFILLED:
    case SET_STATE_FILTER_FLOW_FULFILLED: {
      let arr = [];
      if (!!action.payload.data.currentPage) {
        arr.push(action.payload.data.currentPage);
      }
      return {
        ...state,
        flow: arr,
        nextPages: action.payload.data.nextPages,
        prevPages: action.payload.data.prevPages,
        totalPages: 0,
        pages: 0,
        fetching: false,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED:
    case LOGOUT_FULFILLED: {
      return {
        ...state,
        flow: InitialState.flow,
        page: InitialState.page,
        numberOfElements: InitialState.numberOfElements,
        totalElements: InitialState.totalElements,
        totalPages: InitialState.totalPages,
        fetching: false,
        filterActive: InitialState.filterActive,
        nextPages: InitialState.nextPages,
        prevPages: InitialState.prevPages,
        filterDateFrom: InitialState.filterDateFrom,
        filterDateTo: InitialState.filterDateTo,
        filterChannel: InitialState.filterChannel,
        filterBotOptions: InitialState.filterBotOptions,
        checkBox: InitialState.checkBox,
        bots: InitialState.bots,
        channels: InitialState.channels,
      };
    }

    case SET_FILTER_DATE: {
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      };
    }
    case SET_FILTER_CHANNEL: {
      return {
        ...state,
        filterChannel: action.payload,
        fetching: false,
      };
    }
    case SET_BOT_CHANNEL: {
      return {
        ...state,
        filterBotOptions: action.payload,
        fetching: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
