import { RootState } from 'storeTypes';

type ReduxStore = { getState: () => RootState };

class ReduxStateReference {
  private store!: ReduxStore;

  setStore(store: ReduxStore) {
    this.store = store;
  }

  getStore() {
    return this.store;
  }

  getState() {
    return this.store.getState();
  }
}

export const reduxStateReference = new ReduxStateReference();
