import { FAQIntentRootGroup } from '../../../../constants/faqTemplates';
export class TreeStructure {
    constructor(dataset, maxDepthLevel = 1) {
        this.dataset = dataset;
        this.maxDepthLevel = maxDepthLevel;
        this.buildBranch = (branch, depthLevel = 0, parentId) => {
            const isOutOfDepthLevel = depthLevel === this.maxDepthLevel - 1;
            const children = Object.values(this.dataset).filter(el => el.parent === branch.nodeId);
            const branches = isOutOfDepthLevel
                ? []
                : children
                    .filter(el => el.children.length > 0)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(branch => this.buildBranch(branch, depthLevel + 1, branch.id));
            const leafs = children
                .filter(el => el.children.length === 0)
                .map(leaf => (Object.assign(Object.assign({}, leaf), { depthLevel: depthLevel + 1, parentId: branch.id })));
            return Object.assign(Object.assign({}, branch), { parentId,
                depthLevel,
                branches,
                leafs });
        };
        const branches = Object.values(dataset)
            .filter(el => el.isFolder)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(branch => this.buildBranch(branch, 0));
        const RootLeafs = Object.values(dataset)
            .filter(el => { var _a; return (_a = el.path) === null || _a === void 0 ? void 0 : _a.startsWith(`/${FAQIntentRootGroup}/`); })
            .map(node => (Object.assign(Object.assign({}, node), { depthLevel: 0 })))
            .sort((a, b) => a.name.localeCompare(b.name));
        this.tree = {
            branches,
            leafs: RootLeafs,
        };
    }
    getElementById(id) {
        return Object.values(this.dataset).find(el => el.id === id);
    }
    static getIdsInDepth(node, _result = []) {
        _result.push(node.id);
        if (node.leafs && node.leafs.length > 0) {
            _result = node.leafs.reduce((acc, node) => this.getIdsInDepth(node, acc), _result);
        }
        if (node.branches && node.branches.length > 0) {
            _result = node.branches.reduce((acc, node) => this.getIdsInDepth(node, acc), _result);
        }
        return _result;
    }
    static getNodeAsPlainList(node, extendedMap, _result = []) {
        node.containIds = new Set(TreeStructure.getIdsInDepth(node));
        _result.push(node);
        if (!node.isFolder || (extendedMap !== undefined && !extendedMap[node.id]))
            return _result;
        if (node.leafs && node.leafs.length > 0) {
            _result = node.leafs.reduce((acc, node) => this.getNodeAsPlainList(node, extendedMap, acc), _result);
        }
        if (node.branches && node.branches.length > 0) {
            _result = node.branches.reduce((acc, node) => this.getNodeAsPlainList(node, extendedMap, acc), _result);
        }
        return _result;
    }
    getViewedTreeAsPlainList(extendedMap) {
        return [...this.tree.branches, ...this.tree.leafs]
            .map(node => TreeStructure.getNodeAsPlainList(node, extendedMap))
            .flat();
    }
    getTreeFlat() {
        return [...this.getTreeLeafs(this.tree.branches), ...this.tree.leafs];
    }
    getTreeLeafs(nodes) {
        return [
            ...nodes === null || nodes === void 0 ? void 0 : nodes.map(node => {
                var _a, _b;
                const branches = ((_a = node === null || node === void 0 ? void 0 : node.branches) === null || _a === void 0 ? void 0 : _a.filter(Boolean)) || [];
                const leafs = ((_b = node === null || node === void 0 ? void 0 : node.leafs) === null || _b === void 0 ? void 0 : _b.filter(Boolean)) || [];
                return [...this.getTreeLeafs(branches), ...leafs];
            }).flat(),
        ];
    }
    getViewedElementsCount(node, extendedMap, _count = 0) {
        _count++;
        if (!node.isFolder || !extendedMap[node.id])
            return _count;
        if (node.leafs && node.leafs.length > 0) {
            _count = node.leafs.reduce((acc, node) => this.getViewedElementsCount(node, extendedMap, acc), _count);
        }
        if (node.branches && node.branches.length > 0) {
            _count = node.branches.reduce((acc, node) => this.getViewedElementsCount(node, extendedMap, acc), _count);
        }
        return _count;
    }
    getIdsBranchesInPathToId(id) {
        for (let branch of this.tree.branches) {
            const idsPath = this.getIdsBranchesInPathBranchToId(branch, id);
            if (idsPath.length > 0) {
                return idsPath;
            }
        }
        return [];
    }
    getIdsBranchesInPathBranchToId(node, targetId, _extendedIds = []) {
        if (node.leafs && node.leafs.length > 0) {
            for (const leaf of node.leafs) {
                if (leaf.id === targetId) {
                    return [node.id];
                }
            }
        }
        if (node.branches && node.branches.length > 0) {
            _extendedIds = node.branches.reduce((acc, node) => this.getIdsBranchesInPathBranchToId(node, targetId, acc), _extendedIds);
        }
        if (_extendedIds.length > 0) {
            _extendedIds.push(node.id);
        }
        return _extendedIds;
    }
}
