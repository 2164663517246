import React, { Component } from 'react';
import { Spinner } from '@just-ai/just-ui';
import { bindActionCreators } from 'redux';
import { logout } from '../../../actions/currentUser.actions';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Logout extends Component {
  componentDidMount() {
    const { logout, ccSessionsAreEnabled } = this.props;
    logout().then(() => {
      localStorage.removeItem('CURRENT_USER');
      window.location.href = '/c/login';
    });
  }

  render() {
    return <Spinner size='4x' />;
  }
}

function mapStateToProps(state) {
  return {
    ccSessionsAreEnabled: state.AppConfigReducer.ccSessionsAreEnabled,
  };
}

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
