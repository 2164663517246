import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StatusIcon } from '../Components/StatusIcon';
import { CollapsibleContent } from '../Components/CollapsibleContent';
import ReactPaginate from 'react-paginate';
import { formatDate } from '../../pipes/functions';
import localize from '../../localization';
import { Spinner } from '@just-ai/just-ui';

import * as DeployHistoryAction from '../../actions/deployHistoryAction.actions';
import moment from 'moment';

const statusIconClassList = {
  ok: 'fa fa-check system-status-icon-success',
  test_failed: 'fa fa-exclamation system-status-icon-warning',
  outdated: 'fa fa-calendar-times-o system-status-icon-warning',
  deploy_failed: 'fa fa-window-close system-status-icon-danger',
  deployed_manually: 'fa fa-hand-paper-o system-status-icon-warning',
  unavailable: 'fa fa-ban system-status-icon-unavaliable',
  in_progress: 'fa fa-cog fa-spin system-status-icon-progress',
};

class Build extends Component {
  handleClick = event => {
    event.preventDefault();
    this.props.onClick(this.props.build.id);
  };
  render() {
    const { build } = this.props;
    let deployDate = formatDate(build.startDate);
    return (
      <li className={this.props.active ? 'build-list-item active' : 'build-list-item'}>
        <div className='build-list-item-status'>
          <StatusIcon
            statusIconClassList={statusIconClassList}
            status={build.status}
            id={'listItem_status_' + build.status}
            iconClass='small'
          />
        </div>
        <div className='build-list-item-info'>
          <a
            href='#!'
            onClick={this.handleClick}
            className='build-list-item-id text-muted text-uppercase font-weight-bold font-xs'
          >
            #{build.id}
          </a>
          <span className='build-list-item-date'>{deployDate}</span>
          <br />
          {!!build.startBy ? (
            <span className='build-list-item-initiator'>
              <span className='text-muted font-xs'>Last deploy: </span>
              {build.startBy.firstName && build.startBy.lastName
                ? `${build.startBy.firstName} ${build.startBy.lastName}`
                : build.startBy.fullName}
            </span>
          ) : null}
        </div>
      </li>
    );
  }
}

class BuildList extends Component {
  render() {
    const { builds } = this.props;

    return (
      <div className='card animated fadeIn'>
        <div className='card-header'>
          <div className='row'>
            <div className='col'>{localize.translate('Build history')}</div>
          </div>
        </div>
        <div className='card-body no-padding'>
          <ul className='build-list'>
            {builds.map(build => (
              <Build
                key={`component_${build.id}_${build.status}`}
                onClick={this.props.selectBuild}
                build={build}
                active={build.id === this.props.currentBuildId}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export class DeployHistory extends Component {
  timer = 0;

  state = {
    localFetching: false,
    isFirstLoadDone: false,
  };

  componentWillMount() {
    const { fetchChannelData, loadDeployHistory } = this.props.actions;
    const { match, pageSize } = this.props;

    fetchChannelData(match.params.id)
      .then(() => loadDeployHistory(match.params.id, pageSize))
      .then(this.startRefresh)
      .finally(() => this.setState({ isFirstLoadDone: true }));
  }

  refresh = () => {
    const { loadDeployHistory } = this.props.actions;
    const { match, pageSize } = this.props;
    loadDeployHistory(match.params.id, pageSize);
  };

  selectBuild(buildId) {
    this.props.actions.setCurrentBuild(buildId);
  }

  goToPage = page => {
    clearTimeout(this.timer);
    this.timer = 0;
    const { match } = this.props;
    this.setState({
      localFetching: true,
    });
    this.props.actions.paginate(match.params.id, this.props.pageSize, page.selected).then(() => {
      this.setState({
        localFetching: false,
      });
    });
  };

  startRefresh = () => {
    const { refetchDeployHistory } = this.props.actions;
    const { match, pageSize, page } = this.props;
    if (page === 0) {
      refetchDeployHistory(match.params.id, {
        size: pageSize,
        page: page,
      }).then(() => {
        this.timer = window.setTimeout(() => {
          this.startRefresh();
        }, 2000);
      });
    }
  };

  componentDidUpdate() {}
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { currentBuild, buildList, currentChannel, totalPages, page, fetching } = this.props;
    const { localFetching, isFirstLoadDone } = this.state;
    let logOutput = '';
    let deployStartDate = null;
    let deployFinishDate = null;
    if (!!currentBuild) {
      deployStartDate = !!currentBuild ? new Date(currentBuild.startDate) : null;
      deployFinishDate = !!currentBuild ? new Date(currentBuild.finishDate) : null;
      if (!!currentBuild.logOutput) {
        logOutput = currentBuild.logOutput;
      }
    }

    return (
      <div className='animated fadeIn'>
        {(fetching && !currentBuild) || localFetching ? <Spinner size='4x' /> : null}
        {currentBuild && !fetching ? (
          <div className='card deploy-history'>
            <div className='card-header'>
              <div className='row'>
                <div className='col-sm-7'>
                  <div className='deploy-history-status pull-left'>
                    <StatusIcon
                      statusIconClassList={statusIconClassList}
                      status={currentBuild.status}
                      id={'lastBuild_status_' + currentBuild.status}
                    />
                  </div>
                  <div>
                    <h4 className='card-title mb-0'>
                      Bot: <span className='bot-label'>{currentChannel.botName}</span>
                    </h4>
                    <span className='small text-muted '>
                      {localize.translate('Branch')}: {currentBuild.branch}
                    </span>
                  </div>
                </div>
                <div className='col-sm-5'>
                  <div className='pull-right'>
                    <div>
                      <span className='small text-muted'>{localize.translate('Build')}: </span>
                      <span className='build-number'>{currentBuild.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-sm-3'>
                  <BuildList
                    builds={buildList}
                    selectBuild={this.selectBuild.bind(this)}
                    fetching={fetching}
                    currentBuildId={currentBuild.id}
                  />

                  {totalPages > 1 ? (
                    <ReactPaginate
                      pageCount={totalPages}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={this.goToPage}
                      forcePage={page}
                      containerClassName='pagination'
                      pageClassName='page-item'
                      pageLinkClassName='page-link'
                      activeClassName='active'
                      breakClassName='page-item--break'
                      nextLabel={localize.translate('Next')}
                      previousLabel={localize.translate('Prev.')}
                    />
                  ) : null}
                </div>
                <div className='col-sm-9'>
                  <div className='build-general-info row'>
                    <div className='col-sm-6'>
                      <div className='build-general-info-branch '>
                        <i className='fa fa-folder' />
                        {' ' + localize.translate('Project') + ': '}
                        <b>{currentChannel.project.name}</b>
                      </div>
                      <div className='build-general-info-branch '>
                        <i className='fa fa-code-fork' />
                        {' ' + localize.translate('Branch') + ': '}
                        <b>{currentBuild.branch}</b>
                      </div>
                      <div className='build-general-info-dates'>
                        <div className='build-date'>
                          <i className='fa fa-calendar' aria-hidden='true' />
                          {' ' + localize.translate('Started') + ': '}
                          <b>{moment(deployStartDate).format('DD.MM.YYYY HH:mm:ss')}</b>
                        </div>
                        {deployFinishDate > 0 ? (
                          <div className='build-date'>
                            <i className='fa fa-calendar-check-o' aria-hidden='true' />
                            {' ' + localize.translate('Ended') + ': '}
                            <b>{moment(deployFinishDate).format('DD.MM.YYYY HH:mm:ss')}</b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      {!!currentBuild.startBy ? (
                        <div className='build-general-info-user'>
                          <i className='fa fa-user' aria-hidden='true' /> {localize.translate('Initiated by user')}:
                          <b>
                            {' ' +
                              (currentBuild.startBy.firstName && currentBuild.startBy.lastName
                                ? `${currentBuild.startBy.firstName} ${currentBuild.startBy.lastName}`
                                : currentBuild.startBy.fullName)}
                          </b>
                        </div>
                      ) : (
                        <div className='build-general-info-user'>
                          <i className='fa fa-cogs' aria-hidden='true' />
                          {localize.translate('Initiated automatically')}{' '}
                        </div>
                      )}
                      <div className='build-general-info-revision'>
                        <i className='fa fa-history' aria-hidden='true' />
                        {' ' + localize.translate('Revision') + ': '}
                        <b>{currentBuild.revisionHash}</b>
                      </div>
                      <div className=''>
                        <i className='fa fa-comments' aria-hidden='true' />
                        {' ' + localize.translate('Revision comment') + ': '}
                        <code>{currentBuild.revisionComment}</code>
                      </div>
                    </div>
                  </div>
                  {currentBuild.status.toLowerCase() !== 'in_progress' ? (
                    <CollapsibleContent headline='Deployment log'>
                      <div className='code--style' style={{ whiteSpace: 'pre-wrap' }}>
                        {logOutput}
                      </div>
                    </CollapsibleContent>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {isFirstLoadDone && !currentBuild && buildList.length === 0 ? (
          <div className='animated fadeIn'>
            <div className='card p-4'>
              <h4>{localize.translate('For this channel, there were no deploys yet')}</h4>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentBuild: state.DeployHistoryReducer.currentBuild,
    buildList: state.DeployHistoryReducer.buildList,
    currentChannel: state.DeployHistoryReducer.currentChannel,
    totalPages: state.DeployHistoryReducer.totalPages,
    pageSize: state.DeployHistoryReducer.pageSize,
    page: state.DeployHistoryReducer.page,
    fetching: state.DeployHistoryReducer.fetching,
    fetched: state.DeployHistoryReducer.fetched,
  };
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DeployHistoryAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeployHistory));
