export const newprojectoverviewLocalization = {
    'ProjectOverview:Title': {
        eng: 'Project overview',
        ru: 'Обзор проекта',
    },
    'ProjectOverview:Filters:7Days': {
        eng: 'Week',
        ru: 'Неделя',
    },
    'ProjectOverview:Filters:30Days': {
        eng: 'Month',
        ru: 'Месяц',
    },
    'ProjectOverview:Filters:6Months': {
        eng: 'Six months',
        ru: 'Полгода',
    },
    'ProjectOverview:Filters:YourPeriod': {
        eng: 'Custom period',
        ru: 'Свой период',
    },
    'ProjectOverview:Filters:Day': {
        eng: 'Daily',
        ru: 'По дням',
    },
    'ProjectOverview:Filters:Week': {
        eng: 'Weekly',
        ru: 'По неделям',
    },
    'ProjectOverview:Filters:Month': {
        eng: 'Monthly',
        ru: 'По месяцам',
    },
    'ProjectOverview:Filters:Apply': {
        eng: 'Apply',
        ru: 'Применить',
    },
    'ProjectOverview:Filters:AllChannels': {
        eng: 'All channels',
        ru: 'Все каналы',
    },
    'ProjectOverview:Filters:RemoteChannels': {
        eng: 'Removed channels',
        ru: 'Удаленные каналы',
    },
    'ProjectOverview:Indicators:Title': {
        eng: 'Metrics',
        ru: 'Показатели',
    },
    'ProjectOverview:Indicators:users': {
        eng: 'Users',
        ru: 'Пользователи',
    },
    'ProjectOverview:Indicators:newUsers': {
        eng: 'New users',
        ru: 'Новые пользователи',
    },
    'ProjectOverview:Indicators:sessions': {
        eng: 'Sessions',
        ru: 'Сессии',
    },
    'ProjectOverview:Indicators:messages': {
        eng: 'Inbound messages',
        ru: 'Входящие сообщения',
    },
    'ProjectOverview:Indicators:Tooltip': {
        eng: '$[1] $[2]',
        ru: '$[1] $[2]',
    },
    'ProjectOverview:UserActivity:Title': {
        eng: 'Users',
        ru: 'Пользователи',
    },
    'ProjectOverview:UserActivity:OnAverage': {
        eng: 'on average </br> each day',
        ru: 'в среднем </br> каждый день',
    },
    'ProjectOverview:UserActivity:NewOnes': {
        eng: 'new ones every </br> day',
        ru: 'новых каждый </br> день',
    },
    'ProjectOverview:UserActivity:Checkbox:NewUsers': {
        eng: 'New',
        ru: 'Новые',
    },
    'ProjectOverview:UserActivity:Checkbox:Users': {
        eng: 'Total',
        ru: 'Всего',
    },
    'ProjectOverview:Indicators:users_one': {
        eng: 'user',
        ru: 'пользователь',
    },
    'ProjectOverview:Indicators:users_few': {
        eng: 'users',
        ru: 'пользователя',
    },
    'ProjectOverview:Indicators:users_many': {
        eng: 'users',
        ru: 'пользователей',
    },
    'ProjectOverview:Indicators:users_other': {
        eng: 'users',
        ru: 'пользователей',
    },
    'ProjectOverview:Indicators:newUsers_one': {
        eng: 'new user',
        ru: 'новый пользователь',
    },
    'ProjectOverview:Indicators:newUsers_few': {
        eng: 'new users',
        ru: 'новых пользователя',
    },
    'ProjectOverview:Indicators:newUsers_many': {
        eng: 'new users',
        ru: 'новых пользователей',
    },
    'ProjectOverview:Indicators:newUsers_other': {
        eng: 'new users',
        ru: 'новых пользователей',
    },
    'ProjectOverview:Indicators:sessions_one': {
        eng: 'session',
        ru: 'сессия',
    },
    'ProjectOverview:Indicators:sessions_few': {
        eng: 'sessions',
        ru: 'сессии',
    },
    'ProjectOverview:Indicators:sessions_many': {
        eng: 'sessions',
        ru: 'сессий',
    },
    'ProjectOverview:Indicators:sessions_other': {
        eng: 'sessions',
        ru: 'сессий',
    },
    'ProjectOverview:Indicators:messages_one': {
        eng: 'inbound message',
        ru: 'входящее сообщение',
    },
    'ProjectOverview:Indicators:messages_few': {
        eng: 'inbound messages',
        ru: 'входящих сообщения',
    },
    'ProjectOverview:Indicators:messages_many': {
        eng: 'inbound messages',
        ru: 'входящих сообщений',
    },
    'ProjectOverview:Indicators:messages_other': {
        eng: 'inbound messages',
        ru: 'входящих сообщений',
    },
    'ProjectOverview:AutomationChart:Stub:Link': {
        eng: '#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus',
        ru: '#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus',
    },
    'ProjectOverview:AutomationChart:Title': {
        eng: 'Automation',
        ru: 'Автоматизация',
    },
    'ProjectOverview:AutomationChart:Tooltip': {
        eng: 'dialogs </br> automated',
        ru: 'диалогов </br> автоматизировано',
    },
    'ProjectOverview:AutomationChart:Tooltip:Description': {
        eng: `The percentage of automated dialogs. <br> You mark which bot actions can be considered as automated successfully or not. Use the setSessionAutomation method for this. <a href="#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus" target="_blank">How to set up</a>`,
        ru: `Процент автоматизированных диалогов. <br> Вы сами размечаете, какие действия бота считать успешно автоматизированными, а какие нет. Это делается с помощью метода setSessionAutomation. <a href="#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus" target="_blank">Как настроить</a>`,
    },
    'ProjectOverview:AutomationChart:Stub:Description': {
        eng: 'Get information on how many requests were automated with your bot.',
        ru: 'Получайте информацию о том, сколько обращений было автоматизировано с помощью бота.',
    },
    'ProjectOverview:Stub:Button': {
        eng: 'How to set up',
        ru: 'Как настроить',
    },
    'ProjectOverview:NpsChart:Title': {
        eng: 'Customer score',
        ru: 'Оценка от клиентов',
    },
    'ProjectOverview:NpsChart:Tooltip': {
        eng: 'average </br> score',
        ru: 'средняя </br> оценка',
    },
    'ProjectOverview:NpsChart:Tooltip:Description': {
        eng: `The chart depends on the way you set up metric collection in the script. For example, it may be CSAT, NPS, or CSI. <a href="#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps" target="_blank">How to set up</a>`,
        ru: `Отображение графика зависит от того, как вы измеряете этот показатель в сценарии. Это может быть, например, CSAT, NPS или CSI. <a href="#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps" target="_blank">Как настроить</a>`,
    },
    'ProjectOverview:NpsChart:Stub:Link': {
        eng: `#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps`,
        ru: `#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps`,
    },
    'ProjectOverview:NpsChart:Stub:Description': {
        eng: 'Set up your project to collect customer experience metrics: CSAT, NPS, or CSI.',
        ru: 'Измеряйте в вашем проекте показатели клиентского опыта: CSAT, NPS или CSI.',
    },
    'ProjectOverview:Topics:Stub:Link': {
        eng: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
        ru: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
    },
    'ProjectOverview:Topics:Title': {
        eng: 'Topics',
        ru: 'Темы обращений',
    },
    'ProjectOverview:Topics:Tooltip:Description': {
        eng: `When users get to a state marked with a certain topic, JAICP considers it and displays it on the chart. You can manage topics in the Analytics → Metrics configuration section. <a href="#{config.botadmin.helpUrl}/platform_ux/metrics-configuration" target="_blank">How to set up topics</a>`,
        ru: `Когда пользователи попадают в стейт определенной темы, JAICP учитывает это и отображает на графике. Самими темами можно управлять в разделе Аналитика → Настройка метрик. <a href="#{config.botadmin.helpUrl}/platform_ux/metrics-configuration" target="_blank">Как настроить темы</a>`,
    },
    'ProjectOverview:Topics:Stub:Description': {
        eng: 'Set up topics to mark important script branches. When users get to the marked states in your script, JAICP will count the topics and display total distribution.',
        ru: 'Настройте темы обращений, чтобы обозначить важные ветки сценария. Когда пользователь попадет в отмеченные места в сценарии, JAICP учтет темы и подсчитает их общее распределение.',
    },
    'ProjectOverview:Result:Stub:Link': {
        eng: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
        ru: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
    },
    'ProjectOverview:Result:Title': {
        eng: 'Results',
        ru: 'Результаты',
    },
    'ProjectOverview:Result:Tooltip:Description': {
        eng: `Session results. When users get to the state where the result is recorded, it is displayed on the chart. <a href="#{config.botadmin.helpUrl}/platform_ux/metrics-configuration" target="_blank">How to record results</a>`,
        ru: `Результаты сессий. Когда пользователи попадают в стейт, где фиксируется результат, он отображается на графике. <a href="#{config.botadmin.helpUrl}/platform_ux/metrics-configuration" target="_blank">Как фиксировать результаты</a>`,
    },
    'ProjectOverview:Result:Stub:Description': {
        eng: 'Track the results of conversations with the bot to evaluate the effectiveness of your script.',
        ru: 'Отслеживайте итоги разговоров с ботом, чтобы оценить эффективность вашего сценария.',
    },
    'ProjectOverview:Progress:Other': {
        eng: 'Other',
        ru: 'Прочее',
    },
    'ProjectOverview:Banner:Title': {
        eng: 'Set up metrics while your bot has no users',
        ru: 'Настройте метрики проекта, пока у вашего бота нет пользователей',
    },
    'ProjectOverview:Banner:Link': {
        eng: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
        ru: `#{config.botadmin.helpUrl}/platform_ux/metrics-configuration`,
    },
    'ProjectOverview:Banner:Description': {
        eng: 'You will see data here when users start communicate with your bot. </br> It’s a good time to set up your metrics now to get detailed statistics in the future.',
        ru: 'Когда у вашего бота появятся пользователи, здесь будут сводные данные по проекту. </br> Настройте метрики сейчас, чтобы в будущем получать полную статистику.',
    },
    'ProjectOverview:Banner:Button': {
        eng: 'How to set up metrics',
        ru: 'Как настроить метрики',
    },
    'ProjectOverview:Error:Title': {
        eng: 'No data for this report',
        ru: 'Нет данных для этого отчета',
    },
    'ProjectOverview:Error:Button': {
        eng: 'Update',
        ru: 'Обновить',
    },
};
