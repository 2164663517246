import React, { useRef } from 'react';
import { ComputePositionConfig, offset } from '@floating-ui/react-dom';

import { t } from 'localization';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import useHover from 'modules/JGraph/hooks/useHover';

import styles from './styles.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'absolute',
  placement: 'top-start',
  middleware: [offset({ crossAxis: 16 })],
};

const formatter = new Intl.NumberFormat('ru');

interface TotalIntentsInfoProps {
  intentsCount: number;
  phrasesCount: number;
}
const TotalIntentsInfo = ({ intentsCount, phrasesCount }: TotalIntentsInfoProps) => {
  const { isHovered, hoverProps } = useHover();
  const targetRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useFloaterPosition({
    enable: isHovered,
    floaterElement: tooltipRef,
    target: targetRef.current,
    options: floaterOptions,
  });

  return (
    <div ref={targetRef} className={styles.TotalIntentsInfo} {...hoverProps}>
      <span data-test-id='TotalIntentsInfo'>
        {t('TotalIntentsInfo:CommonText', { intentsCount: formatter.format(intentsCount) })}
      </span>
      {isHovered && (
        <div ref={tooltipRef} className={styles.TotalIntentsInfoTooltip}>
          <div className={styles.TotalIntentsInfoTooltip__wrapper} data-test-id='TotalIntentsInfo:Tooltip'>
            <div className={styles.TotalIntentsInfoTooltip__title}>{t('TotalIntentsInfo:Tooltip:Title')}</div>
            <div className={styles.TotalIntentsInfoTooltip__divider} />
            <div className={styles.TotalIntentsInfoTooltip__content}>
              <div className={styles.TotalIntentsInfoTooltip__row} data-test-id='TotalIntentsInfo:Tooltip:Intents'>
                <span>{t('TotalIntentsInfo:Intents')} </span>
                <span>{formatter.format(intentsCount)}</span>
              </div>
              <div
                className={styles.TotalIntentsInfoTooltip__row}
                data-test-id='TotalIntentsInfo:Tooltip:PhrasesAndPatterns'
              >
                <span>{t('TotalIntentsInfo:PhrasesAndPatterns')} </span>
                <span>{formatter.format(phrasesCount)}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TotalIntentsInfo.displayName = 'TotalIntentsInfo';

export default React.memo(TotalIntentsInfo);
