import { Moment } from 'moment';

type FilterObjectWithDateFromAndTo = {
  dateFrom?: number | string | Moment;
  dateTo?: number | string | Moment;
  [key: string]: any;
};
export const convertDatesToX = (filterObj: FilterObjectWithDateFromAndTo) => {
  ['dateFrom', 'dateTo'].forEach(type => {
    if (filterObj[type]) {
      switch (typeof filterObj[type]) {
        case 'object': {
          filterObj[type] = filterObj[type].format('x');
        }
      }
    }
  });

  return filterObj;
};

export function normalizeNexmoName(oldName: string): string {
  let name = oldName.replace('Nexmo whatsapp', 'Vonage');
  name = name.replace(/##\d*$/, '');
  return name;
}
