import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { makeNewConnector, restoreState } from 'reducers/JGraph.reducer/index';
import { getConnectorPropsFromName, findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';

type MakeNewConnectorAsyncPayload = {
  from: string;
  to: string;
};
const makeNewConnectorAsync = createAsyncThunk(
  'JGraph/makeNewConnectorAsync',
  async (data: MakeNewConnectorAsyncPayload, thunkAPI) => {
    await thunkAPI.dispatch(makeNewConnector(data));
    const state = thunkAPI.getState() as RootState;
    const storeScreens = state.JGraphReducer.graph.blocks;
    const affectedScreenPathId = getConnectorPropsFromName(data.from);
    const screenToSave = findScreenByPathId(affectedScreenPathId.blockPathId, storeScreens);

    if (screenToSave) {
      mainSave$.next({
        type: 'update',
        path: screenToSave.path,
        action: () => thunkAPI.dispatch(updateState({ ...screenToSave, useSelfPathValue: true })),
      });
    }
  }
);

export const revertConnectorChangeAsync = createAsyncThunk(
  'JGraph/revertMakingNewConnectorAsync',
  async (revertEvent: RevertEvent<MakeNewConnectorAsyncPayload>, thunkAPI) => {
    const storeScreens = [...revertEvent.prevState.JGraphReducer.graph.blocks];
    const affectedScreenPathId = getConnectorPropsFromName(revertEvent.payload.from);
    const screenToSave = findScreenByPathId(
      affectedScreenPathId.isFromState ? revertEvent.payload.to || '' : affectedScreenPathId.blockPathId || '',
      storeScreens
    );
    if (!screenToSave) return;

    await thunkAPI.dispatch(restoreState({ screen: screenToSave }));

    mainSave$.next({
      type: 'update',
      path: screenToSave.path,
      action: () => thunkAPI.dispatch(updateState({ ...screenToSave, useSelfPathValue: true })),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertConnectorChangeAsync, makeNewConnectorAsync);
