import React, { FC } from 'react';
import classes from './Tag.module.scss';
import cn from 'classnames';

type TagProps = {
  color?: string;
};

export const Tag: FC<TagProps> = ({ children, color = 'secondary' }) => {
  return <span className={cn(classes.Tag, classes[color])}>{children}</span>;
};
