import { CustomTagData, CustomTagParameterData, CustomTagParameterType } from '../api/client';
import { Block } from '../model/Block';

export const getParamDefaults = (param: CustomTagParameterData) => {
  switch (param.type) {
    case CustomTagParameterType.State:
    case CustomTagParameterType.String:
      return '';
    case CustomTagParameterType.NameValueList:
    case CustomTagParameterType.StringArray:
    case CustomTagParameterType.Intents:
      return [];
    case CustomTagParameterType.Integer:
      return 0;
    case CustomTagParameterType.Parameters:
      return {};
    case CustomTagParameterType.Bool:
      return false;
  }
  if (param.name === 'fields' && param.type === 'json') {
    return {};
  }
  if (param.name === 'actions') {
    return [];
  }
};

export const getNewUniqId = (initialId: string | undefined, blocks: Block[], iterator: number = -1): string => {
  let id = initialId || '/new_node';
  id += iterator > -1 ? `_${iterator}` : '';
  const foundIndex = blocks.findIndex(block => block.statePath === id);
  if (foundIndex > -1) {
    return getNewUniqId(initialId, blocks, iterator + 1);
  }
  return id;
};

export const checkRequiredFields = (customTags: CustomTagData[], block?: Block): boolean => {
  if (block) {
    const foundTag: CustomTagData | undefined = customTags.find(_tag => _tag.tagName === block.type);
    if (foundTag) {
      const requiredFields = foundTag.parameters?.filter(param => param.required);
      if (requiredFields) {
        let flag = false;
        requiredFields.forEach(requiredField => {
          if (requiredField.name && !flag) {
            switch (requiredField.type) {
              case 'string': {
                //@ts-ignore
                if (block[`${requiredField.name}`] === '') {
                  flag = true;
                }
                break;
              }
              default:
                break;
            }
          }
        });
        return flag;
      }
    }
  }
  return false;
};

export const getColNumber = (aimyCol: string): number => {
  return parseInt(aimyCol.split('#')[1], 10);
};
