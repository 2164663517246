import React, { FC, useMemo } from 'react';
import { BaseItemBody, TBaseItemBodyProps } from '../BaseItemBody';
import { useRenderBlocksContext } from '../RenderBlocks';
import { useOnClickBlockDefault } from '../../../hooks';
import { CustomTagData } from '../../../../Editor/api/client';
import { Group, Line } from 'react-konva';
import { tagParametersToObj } from '../../../../../reducers/JGraph.reducer/utils';
import { tWithCheck } from '../../../../../localization';
import { CustomTagValueItem } from './CustomTagValueItem';

export interface CustomTagBodyProps extends Omit<TBaseItemBodyProps, 'renderChild' | 'onClick'> {
  descriptor: CustomTagData;
}

export const CustomTagBody: FC<CustomTagBodyProps> = React.memo(
  ({ screenBlock, globalJBlockIterator = 0, descriptor, ...rest }) => {
    const { baseWidth, prefix, screen } = useRenderBlocksContext();
    const { onClickDefault } = useOnClickBlockDefault(screen, globalJBlockIterator, prefix);

    const screenBlockFields = useMemo(() => {
      return tagParametersToObj(screenBlock?.tagParameters || []);
    }, [screenBlock?.tagParameters]);

    const bodyFields = useMemo(() => {
      return descriptor.parameters?.filter(field => field.required && field.type !== 'state') ?? [];
    }, [descriptor]);

    const bodyStrings = useMemo(() => {
      return bodyFields.reduce((strings, field) => {
        if (field.name && screenBlockFields[field.name] && screenBlock) {
          strings.push({
            name: tWithCheck(`CustomTag:${screenBlock.tagName}:param:${field.name}`) || field.name,
            value: screenBlockFields[field.name].value,
          });
        }
        return strings;
      }, [] as { name: string; value: string }[]);
    }, [bodyFields, screenBlockFields, screenBlock]);

    const transitionsParameters = useMemo(() => {
      return descriptor.parameters?.filter(field => field.type === 'state') ?? [];
    }, [descriptor]);
    const transitionsValues = useMemo(() => {
      return transitionsParameters.reduce((transitionsValues, field, index) => {
        if (field.name && screenBlockFields[field.name] && screenBlock) {
          transitionsValues.push({
            name: tWithCheck(`CustomTag:${screenBlock.tagName}:param:${field.name}`) || field.name,
            value: screenBlockFields[field.name].value,
            index: index,
            debugActive: screenBlockFields[field.name].debugActive,
          });
        }
        return transitionsValues;
      }, [] as { name: string; value: string; index: number; debugActive: boolean }[]);
    }, [screenBlock, screenBlockFields, transitionsParameters]);

    if (!screenBlock) {
      return null;
    }

    return (
      <BaseItemBody
        {...rest}
        debugActive={screenBlock.debugActive}
        onClick={onClickDefault}
        renderChild={({ innerHeight = 1 }) => {
          return (
            <>
              <Group x={24} y={32}>
                {bodyStrings.map((bodyString, index) => (
                  <CustomTagValueItem
                    key={`customTagValue-${bodyString.name}-${bodyString.value}`}
                    globalJBlockIterator={globalJBlockIterator}
                    tagName={screenBlock.tagName}
                    name={bodyString.name}
                    value={bodyString.value}
                    baseWidth={baseWidth}
                    offsetTop={index * 28}
                  />
                ))}
                {transitionsParameters.length > 0 ? (
                  <Line
                    x={-36}
                    points={[0, bodyStrings.length * 28, baseWidth - 24, bodyStrings.length * 28]}
                    stroke='#E0E1E3'
                    strokeWidth={1}
                  />
                ) : null}
                <Group y={bodyStrings.length * 28 + 4}>
                  {transitionsValues.map((transitionValue, index) => (
                    <CustomTagValueItem
                      key={`customTagValue-${transitionValue.name}-${transitionValue.value}`}
                      globalJBlockIterator={globalJBlockIterator}
                      tagName={screenBlock.tagName}
                      name={transitionValue.name}
                      value={transitionValue.value}
                      baseWidth={baseWidth}
                      offsetTop={index * 28}
                      transition={transitionValue}
                    />
                  ))}
                </Group>
              </Group>
            </>
          );
        }}
      />
    );
  }
);
