import React from 'react';
import { TagNames, TJBlock } from '../../../utils/types';
import { EventsState } from '../hooks/EventsHook';
import { GuideStep } from '../../../../../components/GuideTourStepper/types';
import { t } from '../../../../../localization';

export const getTourSteps = (block?: TJBlock, eventsState?: EventsState): GuideStep[] => {
  if (eventsState?.preventGuideTour || eventsState?.addingMenuType === 'reactions') return [];
  switch (block?.tagName) {
    case TagNames.a: {
      return [
        {
          dataTestId: 'GuideTour:TagName_a:1',
          target: '[data-test-id="AnswerSettings:textInput"]',
          content: t('GuideTour:TagName_a:1'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
        {
          dataTestId: 'GuideTour:TagName_a:2',
          target: '[data-test-id="AnswerSettings:HtmlEnabler:toggle_container"]',
          content: t('GuideTour:TagName_a:2'),
          placement: 'left',
          offset: 20,
        },
      ];
    }
    case TagNames.image:
      return [
        {
          dataTestId: 'GuideTour:TagName_image',
          target: '.JGraph-RightSideMenu-commonContainer',
          content: t('GuideTour:TagName_image'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
    case TagNames.audio:
      return [
        {
          dataTestId: 'GuideTour:TagName_audio',
          target: '.AudioSettings',
          content: t('GuideTour:TagName_audio'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
    case TagNames.if:
      return [
        {
          dataTestId: 'GuideTour:TagName_if:1',
          target: '[data-test-id="ConditionSettings:TagNameChange:condition_container:if"]',
          content: <div dangerouslySetInnerHTML={{ __html: t('GuideTour:TagName_if:1') }} />,
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
        {
          dataTestId: 'GuideTour:TagName_if:2',
          target: '[data-test-id="ScreenBlocksListOpenAdder"]',
          content: <div dangerouslySetInnerHTML={{ __html: t('GuideTour:TagName_if:2') }} />,
          placement: 'left',
          offset: 20,
        },
        {
          dataTestId: 'GuideTour:TagName_if:3',
          target: '[data-test-id="JGraph.RightSideMenu.Buttons.addButton"]',
          content: <div dangerouslySetInnerHTML={{ __html: t('GuideTour:TagName_if:3') }} />,
          placement: 'left',
          offset: 20,
          goNextOnNextEvent: 'RightSideMenu:if:onElseAdd',
        },
        {
          waitEvent: 'RightSideMenu:if:onElseAdd',
          dataTestId: 'GuideTour:TagName_if:4',
          target: '[data-test-id="ConditionSettings:TagNameChange:condition_select_container:else"]',
          content: <div dangerouslySetInnerHTML={{ __html: t('GuideTour:TagName_if:4') }} />,
          placement: 'left',
          offset: 20,
        },
      ];
    case TagNames.random:
      return [
        {
          dataTestId: 'GuideTour:TagName_random',
          target: '[data-test-id="RandomSettings:Container"]',
          content: t('GuideTour:TagName_random'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
    case TagNames.inlineButtons:
    case TagNames.buttons:
      return [
        {
          dataTestId: 'GuideTour:TagName_buttons',
          target: '.JGraph-RightSideMenu-body-content',
          content: t('GuideTour:TagName_buttons'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
    case TagNames.go:
    case TagNames.go_:
      return [
        {
          dataTestId: 'GuideTour:TagName_go',
          target: '.JGraph-RightSideMenu-settings',
          content: t('GuideTour:TagName_go'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
    case TagNames.script:
      return [
        {
          dataTestId: 'GuideTour:TagName_script',
          target: '.JGraph-RightSideMenu-commonContainer',
          content: t('GuideTour:TagName_script'),
          placement: 'left',
          disableBeacon: true,
          offset: 20,
        },
      ];
  }
  return [];
};
