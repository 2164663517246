import React, { FC, useCallback, useState } from 'react';
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';
import { Button, useDebounceFn } from '@just-ai/just-ui';

import { t } from 'localization';

import safeJsonParse from 'utils/safeJsonParse';

import { withController } from './Controller';
import { NameValueItem } from './NameValueItem.field';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';
import CustomTagWarningBlock from './CustomTagWarningBlock';

type TNameValueArrayItem = { name: string; value: string };
type NameValueArrayItemWithKey = { key: string; value: TNameValueArrayItem };

const prepareValues = (value: string | TNameValueArrayItem[]): NameValueArrayItemWithKey[] => {
  let nameValueArray: TNameValueArrayItem[] = [];
  if (typeof value === 'string') {
    nameValueArray = safeJsonParse(value) ?? [];
  }
  if (Array.isArray(value)) {
    nameValueArray = value;
  }
  if (!Array.isArray(nameValueArray)) {
    nameValueArray = [];
  }
  return nameValueArray.map(value => ({
    key: uuid(),
    value,
  }));
};

const NameValueView: FC<CommonFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  description,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  const [innerValues, setInnerValues] = useState(prepareValues(value));

  const triggerOnChange = useDebounceFn(
    useCallback(
      () => onChange(innerValues.map(value => value.value || { name: '', value: '' })),
      [innerValues, onChange]
    ),
    300
  );

  const onClickAdd = useCallback(() => {
    setInnerValues(innerValues => [...innerValues, { key: uuid(), value: { name: '', value: '' } }]);
    triggerOnChange();
  }, [triggerOnChange]);

  const onChangeItem = useCallback(
    (index: number, field: keyof TNameValueArrayItem, value: string) => {
      setInnerValues(innerValues =>
        produce(innerValues, draft => {
          draft[index].value[field] = value;
        })
      );
      triggerOnChange();
    },
    [triggerOnChange]
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      setInnerValues(innerValues => {
        innerValues.splice(index, 1);
        return [...innerValues];
      });
      triggerOnChange();
    },
    [triggerOnChange]
  );

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      {innerValues.map((innerValue, index) => (
        <NameValueItem
          key={innerValue.key}
          onRemove={onRemoveItem}
          index={index}
          onChange={onChangeItem}
          value={innerValue.value}
        />
      ))}
      <div>
        <Button color='primary' iconLeft='farPlus' iconRight='' flat onClick={onClickAdd} size='md'>
          {t('CustomTags:StringArray:AddField')}
        </Button>
      </div>
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const NameValueArray = withController(NameValueView);
