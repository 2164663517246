import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './styles.module.scss';
const LinkButton = function ({ text, onClick, dataTestId }) {
    return (_jsx("a", { href: '#!', className: styles.linkButton, onClick: e => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }, "data-test-id": dataTestId, children: text }));
};
export default React.memo(LinkButton);
