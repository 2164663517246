import React, { Component } from 'react';
import * as DiagramActions from '../../../actions/diagramAnalytics.actions';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PhraseMarksDiagram from './PhraseMarksDiagram';
import SessionMarksDiagram from './SessionMarksDiagram';
import MainDiagram from './MainDiagram';
import { isReporterEnabled } from '../../../isAccessFunction';
import { AnalyticsContext } from '@just-ai/analytic-front';
import { Spinner } from '@just-ai/just-ui';

class Diagram extends Component {
  static contextType = AnalyticsContext;
  customChart = () => {
    return function (tooltipModel) {
      let tooltipEl = document.getElementById('chartjs-tooltip');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        let bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '<thead>';

        titleLines.forEach(function (title) {
          innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        bodyLines.forEach(function (body, i) {
          let colors = tooltipModel.labelColors[i];
          let bgColor = colors.backgroundColor;
          if (colors.backgroundColor === 'rgba(0, 0, 0, 0.1)') {
            bgColor = colors.borderColor;
          }
          let style = 'background:' + bgColor;
          style += '; border-color:' + colors.borderColor;
          style += '; border-width: 2px';
          let span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
          innerHtml += '<tr><td>' + span + body + '</td></tr>';
        });
        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      let position = this._chart.canvas.getBoundingClientRect();
      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + tooltipModel.caretY - tooltipEl.clientHeight / 2 + 'px';
      tooltipEl.style.fontSize = tooltipModel.fontSize;
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    };
  };

  sumArray = (a, b) => {
    let c = [];
    for (let i = 0; i < Math.max(a.length, b.length); i++) {
      c.push((a[i] || 0) + (b[i] || 0));
    }
    return c;
  };

  render() {
    if (isReporterEnabled() && this.context.methodsNotAllowed) {
      return <Spinner size='4x' />;
    }

    return (
      <div>
        <MainDiagram
          customChart={this.customChart}
          filters={this.props.filters}
          withReporter={this.props.withReporter}
        />

        <SessionMarksDiagram
          customChart={this.customChart}
          sumArray={this.sumArray}
          filters={this.props.filters}
          withReporter={this.props.withReporter}
        />

        <PhraseMarksDiagram
          customChart={this.customChart}
          sumArray={this.sumArray}
          filters={this.props.filters}
          withReporter={this.props.withReporter}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mainDiagramFetching: state.DiagramAnalyticsReducer.mainDiagramFetching,
    sessionFetching: state.DiagramAnalyticsReducer.sessionFetching,
    phraseFetching: state.DiagramAnalyticsReducer.phraseFetching,

    errors: state.DiagramAnalyticsReducer.errors,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DiagramActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Diagram));
