import React, { HTMLProps } from 'react';

import styles from './styles.module.scss';

interface ListTableProps<ELEMENT> {
  elements: ELEMENT[];
  elementId: (el: ELEMENT) => string;
  columns: {
    title: React.ReactNode;
    code: string;
    width?: string;
    tdProps?: HTMLProps<HTMLTableCellElement>;
    renderCell?: (el: ELEMENT) => React.ReactNode;
  }[];
}
export function ListTable<ELEMENT>({ columns, elements, elementId }: ListTableProps<ELEMENT>) {
  return (
    <table className={styles.ListTable}>
      <thead>
        <tr>
          {columns.map(rowConfig => (
            <th style={{ width: rowConfig.width }} key={rowConfig.code}>
              {rowConfig.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {elements.map(element => (
          <tr key={elementId(element)}>
            {columns.map(rowConfig => (
              <td key={rowConfig.code} {...rowConfig.tdProps}>
                {rowConfig.renderCell?.(element)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

ListTable.displayName = 'ListTable';
