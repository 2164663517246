import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
export default function InputComponent(props) {
    const { initialValue, tabsNames, handleTabBlur, className } = props;
    const [skillNameNoPrefix, setSkillNameNoPrefix] = useState(() => initialValue.split('.').splice(1).join('.'));
    const [error, setError] = useState('');
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const handleBlur = useCallback((e) => {
        const trimmedNameValue = skillNameNoPrefix.trim();
        if (tabsNames.includes(`FAQ.${trimmedNameValue}`) && initialValue !== `FAQ.${trimmedNameValue}`) {
            const newContextMenu = document.getElementById('tabContextMenu');
            if (!newContextMenu || !e.relatedTarget || !newContextMenu.contains(e.relatedTarget)) {
                setError(t('FAQ:RenameSkill:existingName'));
                return e.target.focus();
            }
            handleTabBlur(initialValue, initialValue);
            return e.relatedTarget.click();
        }
        return handleTabBlur(initialValue, 'FAQ.' + (trimmedNameValue || initialValue.split('.').splice(1).join('.')));
    }, [handleTabBlur, initialValue, tabsNames, skillNameNoPrefix]);
    const handleInput = useCallback((e) => {
        setError('');
        setSkillNameNoPrefix(e.target.value);
    }, []);
    const handleEnterPress = useCallback((e) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);
    const stopPropagator = useCallback(e => {
        e.stopPropagation();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { id: 'tabNameInputWrapper', className: cn('faq-tab-name-input__wrapper', className), onContextMenu: stopPropagator, onClick: stopPropagator, children: [_jsx("p", { children: "FAQ." }), _jsx("input", { onContextMenu: stopPropagator, onKeyDown: handleEnterPress, autoFocus: true, placeholder: t('Description'), ref: inputRef, onBlur: handleBlur, value: skillNameNoPrefix, onChange: handleInput })] }), error && (_jsx(Tooltip, { isOpen: true, 
                //@ts-ignore
                onContextMenu: stopPropagator, onClick: stopPropagator, className: 'tooltip--error', autohide: false, target: 'tabNameInputWrapper', children: error }))] }));
}
