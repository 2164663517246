import React, { useMemo } from 'react';
import { groupBy } from 'lodash';

import { GroupedConnector } from 'reducers/JGraph.reducer/types';
import { getPathWithoutThemePrefix } from 'modules/JGraph/utils/themesUtils';

import { AutopositionGroup } from '../parts/AutopositionGroup';
import { OutgoingItem } from './OutgoingItem';

type OutgoingConnectionsListProps = {
  group: GroupedConnector;
  width: number;
  xOffset?: number;
};
function OutgoingConnectionsList({ group, width, xOffset }: OutgoingConnectionsListProps) {
  const groupedConnections = useMemo(() => {
    return groupBy(group.connectors, connector => connector.toNodeOriginalPath);
  }, [group.connectors]);
  return (
    <AutopositionGroup key={group.theme.value} xOffset={xOffset}>
      {Object.keys(groupedConnections).map(toNodeOriginalPath => (
        <OutgoingItem
          key={toNodeOriginalPath}
          path={toNodeOriginalPath}
          displayPath={getPathWithoutThemePrefix(toNodeOriginalPath, group.theme.value)}
          width={width - 24}
          connectorOffsetX={-12}
        />
      ))}
    </AutopositionGroup>
  );
}

OutgoingConnectionsList.displayName = 'OutgoingConnectionsList';

export default React.memo(OutgoingConnectionsList);
