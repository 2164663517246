import React, { ComponentType } from 'react';
import { ValidationOptions } from 'react-hook-form/dist/types';

import { t } from 'localization';

import { CommonHookFormProps } from './fields';
import isAccess from '../../isAccessFunction';

import { ProjectCreationData } from 'modules/Botadmin/service/helperClasses';
import { ICONS } from '@just-ai/just-ui/dist/Icon';

export type Template = {
  type: string;
  language: string;
  name: string;
  title: string;
  summary: string;
  thumbnail: string;
  jaicf?: boolean;

  parsedJson: TemplateParsedJson;
};

type TemplateParsedJson = {
  name: string;
  index: number;
  title: string;
  summary: string;

  namespaces: string[];
  thumbnail: string;
  language: string;
  experrtoId: number;
  caila: boolean;
  forCreateForm: boolean;
  defaultTemplate?: boolean;
  jgraph?: boolean;
};

export type ProjectCreationDataKeys = keyof ProjectCreationData;
export const defaultProjectCreationData = Object.freeze(new ProjectCreationData());
export const beFieldErrors: { [key: string]: ProjectCreationDataKeys } = {
  branch: 'defaultBranch',
  externalRepository: 'repositoryUrl',
};
export const getDefaultProjectDTOValue = (field: ProjectCreationDataKeys) => {
  return defaultProjectCreationData[field];
};
//TODO make true error on new API
export type CreateProjectError = string;
export type CreateProjectOldApiError = { fieldName: string; errorText: string };
export type CreateProjectNewApiError = {
  args: {
    field: string;
    max: number;
  };
  message: string;
  error: string;
};
export type CreateProjectErrorMessage = string;
//TODO update error handling

export type CreateProjectContextType = {
  projectCreationData: ProjectCreationData;
  setPartialCreationData: (payload: Partial<ProjectCreationData>, shouldSave?: boolean) => void;
  filteredTemplates: Template[];
  languages: string[];
  language: string;
  chooseTemplate: (templateCode: string) => void;
  changeLanguage: (newLanguage: string) => void;
  refilterTemplates: () => unknown;
  getDefaultProjectDTOValue: (field: ProjectCreationDataKeys) => unknown;
  error: CreateProjectError;
  errorMessage: CreateProjectErrorMessage;
  errors: CreateProjectOldApiError[];
  newApiErrors: CreateProjectNewApiError[];
};
export enum ProjectGitModelKeysToFieldType {
  'string' = 'string',
  'select' = 'select',
  'key' = 'key',
  'password' = 'password',
}

export type GetFieldsByType<FromType, KeepType = any, Include = true> = {
  [Key in keyof FromType]: FromType[Key] extends KeepType
    ? Include extends true
      ? Key
      : never
    : Include extends false
    ? never
    : Key;
}[keyof FromType];

export type FieldRepresentation = {
  type: ProjectGitModelKeysToFieldType;
  isRequired?: boolean;
  pattern?: ValidationOptions['pattern'];
  options?: string[];
  denyAccess?: boolean;
  [key: string]: MapGitCreds | any;
};

export type GitFields = Pick<
  ProjectCreationData,
  | 'externalTemplate'
  | 'repositoryUrl'
  | 'contentDirectory'
  | 'defaultBranch'
  | 'repositoryAuthType'
  | 'repositoryLogin'
  | 'repositoryPassword'
  | 'justAiKey'
  | 'workspaceIdGitIntegration'
  | 'projectIdGitIntegration'
  | 'repositoryNameGitIntegration'
  | 'repositoryUrlGitIntegration'
  | 'repositoryVisibility'
  | 'branchNameGitIntegration'
>;
export type KeyOfGitFields = keyof GitFields;
export type MapGitCreds = {
  [key in KeyOfGitFields]?: FieldRepresentation;
};

export type CommonHookFormSelectProps = {
  defaultValue: string;
  options: string[] | { label: string; value: string }[];
  onInput?: (value: string) => unknown;
  hideSubText?: boolean;
  iconLeft?: ICONS;
  gitLogin: string;
  value?: string;
  onChange?: (value: string) => void;
  [key: string]: any;
};

export type MapFieldTypeToComponent = {
  [ProjectGitModelKeysToFieldType.string]: ComponentType<CommonHookFormProps>;
  [ProjectGitModelKeysToFieldType.key]: ComponentType<CommonHookFormProps>;
  [ProjectGitModelKeysToFieldType.password]: ComponentType<CommonHookFormProps>;
  [ProjectGitModelKeysToFieldType.select]: ComponentType<CommonHookFormProps & CommonHookFormSelectProps>;
};

export const MapProjectGitOAuthModelKeysToFieldType = (): MapGitCreds => ({
  repositoryNameGitIntegration: {
    type: ProjectGitModelKeysToFieldType.string,
    isRequired: true,
  },
  repositoryVisibility: {
    type: ProjectGitModelKeysToFieldType.select,
    hideSubText: true,
    defaultValue: 'public',
    options: ['public', 'private'],
  },
});

export const MapBitbucketOAuthModelKeysToFieldType = (): MapGitCreds => ({
  repositoryNameGitIntegration: {
    type: ProjectGitModelKeysToFieldType.string,
    isRequired: true,
    pattern: {
      value: /^[a-z0-9_\-.]*$/,
      message: t(`CreateProject:enter repositoryNameGitIntegration pattern error`),
    },
  },
  repositoryVisibility: {
    type: ProjectGitModelKeysToFieldType.select,
    hideSubText: true,
    isRequired: true,
    defaultValue: 'public',
    options: ['public', 'private'],
  },
});

export const MapProjectGitModelKeysToFieldType = (): MapGitCreds => ({
  repositoryUrl: {
    type: ProjectGitModelKeysToFieldType.string,
  },
  contentDirectory: {
    type: ProjectGitModelKeysToFieldType.string,
  },
  defaultBranch: {
    type: ProjectGitModelKeysToFieldType.string,
  },
  repositoryAuthType: {
    denyAccess: isAccess('projects.hide_advanced_settings'),
    type: ProjectGitModelKeysToFieldType.select,
    defaultValue: isAccess('projects.hide_advanced_settings') ? 'PERSONAL_USER' : 'GUEST_USER',
    options: isAccess('projects.hide_advanced_settings') ? ['PERSONAL_USER'] : ['PERSONAL_USER', 'GUEST_USER'],
    PERSONAL_USER: {
      repositoryLogin: {
        type: ProjectGitModelKeysToFieldType.string,
        isRequired: false,
      },
      repositoryPassword: {
        type: ProjectGitModelKeysToFieldType.password,
        isRequired: false,
      },
    },
    GUEST_USER: {
      justAiKey: {
        type: ProjectGitModelKeysToFieldType.key,
      },
    },
  },
});

export enum CreationWays {
  'CAILA' = 'CAILA',
  'EMPTY' = 'EMPTY',
  'TEMPLATE' = 'TEMPLATE',
  'GIT' = 'GIT',
  'ZIP' = 'ZIP',
  'CLONE' = 'CLONE',
}

export const CreationWaysByProjectType = {
  jaicf: [CreationWays.CAILA, CreationWays.GIT, CreationWays.CLONE, CreationWays.ZIP],
  jaicp: [CreationWays.EMPTY, CreationWays.TEMPLATE, CreationWays.GIT, CreationWays.ZIP],
};

export const mapCreationWayToView = {
  [CreationWays.EMPTY]: {
    image: (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='20' cy='20' r='16' fill='#2EA9D6' />
        <path
          d='M26.5714 18.8375H20.8571V13.0875C20.8571 12.9286 20.7293 12.8 20.5714 12.8H19.4285C19.2707 12.8 19.1428 12.9286 19.1428 13.0875V18.8375H13.4285C13.2707 18.8375 13.1428 18.9661 13.1428 19.125V20.275C13.1428 20.4338 13.2707 20.5625 13.4285 20.5625H19.1428V26.3125C19.1428 26.4713 19.2707 26.6 19.4285 26.6H20.5714C20.7293 26.6 20.8571 26.4713 20.8571 26.3125V20.5625H26.5714C26.7292 20.5625 26.8571 20.4338 26.8571 20.275V19.125C26.8571 18.9661 26.7292 18.8375 26.5714 18.8375Z'
          fill='white'
        />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.EMPTY}_title`,
    description: '',
  },
  [CreationWays.CAILA]: {
    image: (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='20' cy='20' r='16' fill='#2EA9D6' />
        <path
          d='M26.5714 18.8375H20.8571V13.0875C20.8571 12.9286 20.7293 12.8 20.5714 12.8H19.4285C19.2707 12.8 19.1428 12.9286 19.1428 13.0875V18.8375H13.4285C13.2707 18.8375 13.1428 18.9661 13.1428 19.125V20.275C13.1428 20.4338 13.2707 20.5625 13.4285 20.5625H19.1428V26.3125C19.1428 26.4713 19.2707 26.6 19.4285 26.6H20.5714C20.7293 26.6 20.8571 26.4713 20.8571 26.3125V20.5625H26.5714C26.7292 20.5625 26.8571 20.4338 26.8571 20.275V19.125C26.8571 18.9661 26.7292 18.8375 26.5714 18.8375Z'
          fill='white'
        />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.CAILA}_title`,
    description: `CreateProjectItem:${CreationWays.CAILA}_description`,
  },
  [CreationWays.TEMPLATE]: {
    image: (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.93115 18.9766L11.6801 13.8934L25.3334 14.7467L26.1868 28.4L21.1036 34.149C21.1036 34.149 18.5068 28.4 15.0934 24.9867C11.6801 21.5734 5.93115 18.9766 5.93115 18.9766Z'
          fill='var(--primary-400)'
        />
        <path
          d='M4.83034 35.2268C4.83034 35.2268 4.79962 28.418 7.37754 25.8401C9.95546 23.2622 16.8 23.4397 16.8 23.4397C16.8 23.4397 16.7992 30.1068 14.2392 32.6668C11.6792 35.2268 4.83034 35.2268 4.83034 35.2268Z'
          fill='#FFAC33'
        />
        <path
          d='M11.6792 31.8134C13.5643 31.8134 15.0925 30.2852 15.0925 28.4C15.0925 26.5149 13.5643 24.9867 11.6792 24.9867C9.79407 24.9867 8.26587 26.5149 8.26587 28.4C8.26587 30.2852 9.79407 31.8134 11.6792 31.8134Z'
          fill='#FFCC4D'
        />
        <path
          d='M34.719 5.35988C34.719 5.35988 25.8519 4.08465 20.8275 9.01118C15.8031 13.9377 19.561 10.2779 15.9457 13.8932C13.5013 15.9303 11.1108 21.6974 10.8965 24.1332C10.8965 24.1332 12.7633 26.0846 13.6551 26.9765C14.5661 27.8875 15.9457 29.1834 15.9457 29.1834C18.207 28.8085 24.1965 26.5202 26.1857 24.1332C30.5813 19.7376 26.1547 24.1643 31.0676 19.2513C35.9806 14.3383 34.719 5.35988 34.719 5.35988Z'
          fill='#AEDEEF'
        />
        <path
          d='M15.0927 28.4L11.6802 24.9867C12.5335 24.1333 22.7744 15.6009 23.6268 16.4533C24.4793 17.3058 15.946 27.5467 15.0927 28.4Z'
          fill='var(--primary-400)'
        />
        <circle cx='27.8025' cy='12.2775' r='2.2069' transform='rotate(45 27.8025 12.2775)' fill='var(--primary-400)' />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.TEMPLATE}_title`,
    description: `CreateProjectItem:${CreationWays.TEMPLATE}_description`,
  },
  [CreationWays.GIT]: {
    image: (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M35.3971 18.5749L21.4253 4.60381C20.6212 3.79922 19.3162 3.79922 18.5109 4.60381L15.6101 7.50531L19.2904 11.1855C20.1459 10.8968 21.1264 11.0905 21.8081 11.7721C22.1329 12.0973 22.3597 12.5073 22.4625 12.9552C22.5653 13.4032 22.5399 13.8711 22.3893 14.3053L25.9362 17.8527C26.7943 17.5569 27.7847 17.748 28.4697 18.4345C29.4279 19.3919 29.4279 20.9441 28.4697 21.902C28.2419 22.1298 27.9716 22.3106 27.674 22.4339C27.3764 22.5572 27.0575 22.6206 26.7354 22.6206C26.4133 22.6206 26.0943 22.5572 25.7967 22.4339C25.4992 22.3106 25.2288 22.1298 25.0011 21.902C24.659 21.5597 24.4258 21.1238 24.3308 20.6492C24.2359 20.1747 24.2834 19.6826 24.4675 19.235L21.1599 15.9272L21.1594 24.6324C21.3994 24.7506 21.6185 24.9071 21.8081 25.0958C22.766 26.0532 22.766 27.6049 21.8081 28.5644C20.8501 29.5218 19.2975 29.5218 18.3405 28.5644C17.3828 27.6051 17.3042 26.0535 18.2617 25.0958C18.4984 24.8593 18.7319 24.6806 18.9854 24.5609V15.7751C18.7319 15.6554 18.4987 15.478 18.2614 15.2399C17.5362 14.5149 17.4006 13.4503 17.7727 12.559L14.1647 8.93071L4.59427 18.5103C3.78942 19.3156 3.79449 20.6206 4.59934 21.4255L18.5735 35.3965C19.3778 36.2011 20.6838 36.2011 21.4894 35.3965L35.3961 21.4903C36.201 20.6852 36.2017 19.3795 35.3971 18.5749Z'
          fill='#F34F29'
        />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.GIT}_title`,
    description: `CreateProjectItem:${CreationWays.GIT}_description`,
  },
  [CreationWays.ZIP]: {
    image: (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.5 7.30263C7.5 6.03092 8.53092 5 9.80263 5H24.9435C25.5682 5 26.1661 5.25382 26.6 5.70324L31.7223 11.0084C32.1368 11.4377 32.3684 12.0111 32.3684 12.6078V32.6316C32.3684 33.9033 31.3375 34.9342 30.0658 34.9342H9.80263C8.53092 34.9342 7.5 33.9033 7.5 32.6316V7.30263Z'
          fill='#2EA9D6'
        />
        <path
          d='M14.0215 22.6154H17.2855V23.8816H11.9675V23.014L15.2221 18.3244H11.9487V17.0535H17.2527V17.8977L14.0215 22.6154Z'
          fill='white'
        />
        <path d='M20.5581 23.8816H18.9168V17.0535H20.5581V23.8816Z' fill='white' />
        <path
          d='M24.1168 21.5649V23.8816H22.4708V17.0535H25.1954C25.7175 17.0535 26.1787 17.1504 26.5789 17.3443C26.9822 17.535 27.2933 17.8086 27.5121 18.165C27.7341 18.5183 27.8451 18.92 27.8451 19.3702C27.8451 20.0361 27.6059 20.5692 27.1276 20.9694C26.6523 21.3664 25.9989 21.5649 25.1673 21.5649H24.1168ZM24.1168 20.2941H25.1954C25.5143 20.2941 25.7566 20.2143 25.9223 20.0549C26.0911 19.8954 26.1756 19.6703 26.1756 19.3796C26.1756 19.0607 26.0896 18.8059 25.9176 18.6152C25.7457 18.4245 25.5112 18.3275 25.2142 18.3244H24.1168V20.2941Z'
          fill='white'
        />
        <circle cx='31.6777' cy='34.2435' r='5.75658' fill='var(--gray-100)' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.5264 36.7762H32.829V34.0131H30.5264V36.7762Z'
          fill='#2EA9D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34.6712 34.4737H28.6843L31.6779 31.7106L34.6712 34.4737Z'
          fill='#2EA9D6'
        />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.ZIP}_title`,
    description: `CreateProjectItem:${CreationWays.ZIP}_description`,
  },
  [CreationWays.CLONE]: {
    image: (
      <svg width='58' height='40' viewBox='0 0 58 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M27.1665 3.5C27.1665 1.56693 21.7301 0 15.0238 0C8.31744 0 2.88086 1.56693 2.88086 3.5C2.88086 5.43307 8.31744 7 15.0238 7C21.7301 7 27.1665 5.43307 27.1665 3.5Z'
          fill='var(--primary-200)'
        />
        <path
          d='M15.0238 14C21.7275 14 27.1665 12.435 27.1665 10.5V3.5C27.1665 5.435 21.7275 7 15.0238 7C8.31982 7 2.88086 5.435 2.88086 3.5V10.5C2.88086 12.435 8.31982 14 15.0238 14Z'
          fill='var(--primary-300)'
        />
        <path
          d='M15.0238 21C21.7275 21 27.1665 19.435 27.1665 17.5V10.5C27.1665 12.435 21.7275 14 15.0238 14C8.31982 14 2.88086 12.435 2.88086 10.5V17.5C2.88086 19.435 8.31982 21 15.0238 21Z'
          fill='#51B8DE'
        />
        <path
          d='M27.1665 17.5C27.1665 19.435 21.7275 21 15.0238 21C8.31982 21 2.88086 19.435 2.88086 17.5V24.5C2.88086 26.435 8.31982 28 15.0238 28C21.7275 28 27.1665 26.435 27.1665 24.5V17.5Z'
          fill='#2EA9D6'
        />
        <path
          d='M55.1665 15.5C55.1665 13.5669 49.7301 12 43.0238 12C36.3174 12 30.8809 13.5669 30.8809 15.5C30.8809 17.4331 36.3174 19 43.0238 19C49.7301 19 55.1665 17.4331 55.1665 15.5Z'
          fill='var(--primary-200)'
        />
        <path
          d='M43.0238 26C49.7275 26 55.1665 24.435 55.1665 22.5V15.5C55.1665 17.435 49.7275 19 43.0238 19C36.3198 19 30.8809 17.435 30.8809 15.5V22.5C30.8809 24.435 36.3198 26 43.0238 26Z'
          fill='var(--primary-300)'
        />
        <path
          d='M43.0238 33C49.7275 33 55.1665 31.435 55.1665 29.5V22.5C55.1665 24.435 49.7275 26 43.0238 26C36.3198 26 30.8809 24.435 30.8809 22.5V29.5C30.8809 31.435 36.3198 33 43.0238 33Z'
          fill='#51B8DE'
        />
        <path
          d='M55.1665 29.5C55.1665 31.435 49.7275 33 43.0238 33C36.3198 33 30.8809 31.435 30.8809 29.5V36.5C30.8809 38.435 36.3198 40 43.0238 40C49.7275 40 55.1665 38.435 55.1665 36.5V29.5Z'
          fill='#2EA9D6'
        />
      </svg>
    ),
    title: `CreateProjectItem:${CreationWays.CLONE}_title`,
    description: `CreateProjectItem:${CreationWays.CLONE}_description`,
  },
};
