import React, { FunctionComponent } from 'react';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';
import { Banner } from '@just-ai/just-ui';
import './style.scss';

const { t } = i18nTranslation('ProjectEditForm', 'GithubTokenWarning');

export const GithubTokenWarning: FunctionComponent = () => {
  return (
    <div className='form-group row github-token-warning-container'>
      <div className='col-md-8 offset-md-4 github-token-warning'>
        <Banner
          BannerText={() => (
            <>
              {t('text')}
              <br />
              <a href={t('linkHref')} target='_blank' rel='noopener noreferrer'>
                {t('linkLabel')}
              </a>
            </>
          )}
          type='warning'
          withIcon
        />
      </div>
    </div>
  );
};
