import React, { Component } from 'react';
import localize from '../../../localization';

class Page403 extends Component {
  render() {
    return (
      <div className='container' data-test-id='page403'>
        <div className='row justify-content-center'>
          <div className='col-md-6'>
            <div className='clearfix'>
              <h1 className='float-left display-3 mr-4'>403</h1>
              <h4 className='pt-3'>{localize.translate('Forbidden')}.</h4>
              <p className='text-muted'>{localize.translate('Access is denied')}.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page403;
