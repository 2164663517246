import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { PayloadAction } from '@reduxjs/toolkit';
import { Icon } from '@just-ai/just-ui';
import cn from 'classnames';

import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import useStateWithCleanUp from 'utils/hooks/useStateWithCleanUp';
import useOnlineStatus from 'utils/hooks/useOnlineStatus';
import useForceUpdate from 'utils/hooks/useForceUpdate';

import { getGraph } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { setJGraphInEditMode } from 'reducers/JGraph.reducer';
import Portal from 'components/Portal';

import SavedTooltip from './Tooltip/SavedTooltip';
import NoConnectionTooltip from './Tooltip/NoConnectionTooltip';
import ErrorTooltip from './Tooltip/ErrorTooltip';

import useJGraphStatusIndicatorSubscribe from './useJGraphStatusIndicatorSubscribe';
import { IndicatorContent, JGraphReduxActionEvent, JGraphReduxStatus } from './types';
import JGraphStatusWidget from './JGraphStatusWidget';
import styles from './styles.module.scss';
import { useJGraphContext } from '../../contexts/JGraphContext';

export let JGraphReduxActions$ = new Subject<JGraphReduxActionEvent>();

function getIndicatorContent(isOnline: boolean, isLoading: boolean, isError: boolean): IndicatorContent {
  if (isLoading) {
    return {
      title: t('StatusIndicator:Status:Saving'),
      icon: 'farSyncAlt',
      iconColor: 'secondary',
      dataTestId: 'loading',
      className: styles.JGraphStatusIndicator__rotateIcon,
    };
  }
  if (!isOnline) {
    return {
      title: t('StatusIndicator:Status:NoConnect'),
      icon: 'faCloudOffline',
      iconColor: 'danger',
      dataTestId: 'notOnline',
    };
  }
  if (isError) {
    return {
      title: t('StatusIndicator:Status:ErrorSaving'),
      icon: 'faCloudOffline',
      iconColor: 'danger',
      dataTestId: 'error',
    };
  }

  return {
    title: t('StatusIndicator:Status:Saved'),
    icon: 'faCloudOk',
    iconColor: 'success',
    dataTestId: 'success',
  };
}

const animationTime = 2e3;

enum ModalType {
  SAVED,
  NO_CONNECT,
  ERROR,
  PENDING,
}

interface JGraphStatusIndicatorProps {
  className?: string;
}
const JGraphStatusIndicator = ({ className }: JGraphStatusIndicatorProps) => {
  const { isJGraphLite } = useJGraphContext();
  const isOnline = useOnlineStatus();
  const forceUpdate = useForceUpdate();
  const dispatch = useAppDispatch();
  const { isEditModeEnable } = useAppSelector(state => ({
    isEditModeEnable: state.JGraphReducer.isEditModeEnable,
  }));

  const [modalState, setModalState] = useState<{
    isModalOpen: boolean;
    modalType?: ModalType;
  }>({ isModalOpen: false });
  const lastActionWithError = useRef<PayloadAction<any> | undefined>();
  const indicatorStatus = useRef<JGraphReduxStatus>({
    isError: false,
    isPending: false,
  });

  useEffect(() => {
    if (!isEditModeEnable) return;
    indicatorStatus.current = { isPending: false, isError: false };
    setModalState({ isModalOpen: false });
  }, [isEditModeEnable]);

  useJGraphStatusIndicatorSubscribe(status => {
    indicatorStatus.current = status;
    forceUpdate();
    if (status.isError) {
      lastActionWithError.current = status.action;
      dispatch(setJGraphInEditMode(false));
    }
  });

  useEffect(() => {
    dispatch(setJGraphInEditMode(isOnline));
  }, [dispatch, isOnline]);

  const statusContent = useMemo(
    () => getIndicatorContent(isOnline, indicatorStatus.current.isPending, indicatorStatus.current.isError),
    // eslint-disable-next-line
    [isOnline, indicatorStatus.current.isPending, indicatorStatus.current.isError]
  );
  const [showTitleClass, setShowTitleClass] = useStateWithCleanUp('', animationTime);
  useEffect(() => setShowTitleClass(styles.JGraphStatusIndicator__showTitle), [setShowTitleClass, statusContent.icon]);

  const onClick = useCallback(() => {
    if (modalState.isModalOpen) {
      setModalState({ isModalOpen: false });
      return;
    }
    if (!isOnline) {
      setModalState({ isModalOpen: true, modalType: ModalType.NO_CONNECT });
      return;
    }
    if (indicatorStatus.current.isError) {
      setModalState({ isModalOpen: true, modalType: ModalType.ERROR });
      return;
    }
    if (indicatorStatus.current.isPending) {
      setModalState({ isModalOpen: true, modalType: ModalType.PENDING });
      return;
    }
    setModalState({ isModalOpen: true, modalType: ModalType.SAVED });
  }, [indicatorStatus, modalState, isOnline]);

  const onFakeRetry = useCallback(() => {
    setModalState({ isModalOpen: false });
    const prevState = indicatorStatus.current;
    indicatorStatus.current = { isPending: true, isError: false };
    forceUpdate();
    setTimeout(() => {
      indicatorStatus.current = prevState;
      forceUpdate();
    }, 2000);
  }, [forceUpdate]);

  const onDiscardChanges = useCallback(async () => {
    dispatch(getGraph({}))
      .unwrap()
      .then((result: any) => {
        dispatch(setJGraphInEditMode(true));
        return result;
      });
  }, [dispatch]);

  if (isJGraphLite) return null;

  return (
    <div className={styles.wrapper} data-test-id='JGraphStatusIndicator'>
      <div
        onClick={onClick}
        data-test-id={`JGraphStatusIndicator:${statusContent.dataTestId}`}
        className={cn(styles.JGraphStatusIndicator, showTitleClass, statusContent.className, className)}
        style={{ '--animationTime': animationTime / 2 } as React.CSSProperties}
      >
        <span className={styles.JGraphStatusIndicator__title}>{statusContent.title}</span>
        <Icon name={statusContent.icon} color={statusContent.iconColor} />
      </div>

      {modalState.isModalOpen && (
        <div className={styles.JGraphStatusIndicator__tooltip}>
          {modalState.modalType === ModalType.SAVED && <SavedTooltip onClose={onClick} />}
          {modalState.modalType === ModalType.NO_CONNECT && (
            <NoConnectionTooltip
              onClose={onClick}
              isActionsDisabled={isOnline || indicatorStatus.current.isPending || indicatorStatus.current.isError}
              onRetry={onFakeRetry}
            />
          )}
          {modalState.modalType === ModalType.ERROR && (
            <ErrorTooltip
              onClose={onClick}
              isActionsDisabled={indicatorStatus.current.isPending}
              onDiscardChanges={onDiscardChanges}
            />
          )}
        </div>
      )}
      <Portal targetNodeSelector='#JGraphStatusWidget'>
        {!isOnline ? (
          <JGraphStatusWidget
            title={t('StatusIndicator:NoConnectionTooltip:Title')}
            icon='farSyncAlt'
            iconTooltip={t('StatusIndicator:TryAgain')}
            onClick={onFakeRetry}
          />
        ) : (
          indicatorStatus.current.isError && (
            <JGraphStatusWidget
              title={t('StatusIndicator:ErrorTooltip:Title')}
              icon='farSyncAlt'
              iconTooltip={t('StatusIndicator:DiscardChanges')}
              onClick={onDiscardChanges}
            />
          )
        )}
      </Portal>
    </div>
  );
};

export default React.memo(JGraphStatusIndicator);
