import { useRightSideMenuContext } from '../index';
import { useCallback, useMemo } from 'react';
import { TActivationParameters, TagNames, TTagParameters } from '../../../utils/types';
import { EventStateChange } from '../RSAddingMenu/ActivationsAddingMenu/PatternActivationEdit';

const emptyArr = [] as const;

export const useCommonActivationSettings = (whatBlockIsGlobal: TagNames, whatBlockIsNotGlobal: TagNames) => {
  const { editMenuScreen, jBlockIndex, onChangeCommonTag } = useRightSideMenuContext();
  const block = editMenuScreen.blocks[jBlockIndex!];
  const tagParameters = block?.tagParameters ?? emptyArr;
  const mapTagParametersToObj = useMemo(() => {
    let obj: Record<string, TTagParameters<string, any>> = {};
    tagParameters.forEach(tagParam => {
      obj[tagParam.name] = tagParam;
    });
    return obj;
  }, [tagParameters]);

  const isGlobal = block?.tagName === whatBlockIsGlobal;

  const changeEventParams = useCallback(
    ({ isGlobal, onlyThisState, fromStateValue, toStateValue }: EventStateChange) => {
      let newTagParameters = [] as TActivationParameters;
      if (isGlobal) {
        newTagParameters = [];
      } else {
        if (mapTagParametersToObj.onlyThisState) {
          mapTagParametersToObj.onlyThisState.value = null;
        } else {
          mapTagParametersToObj.onlyThisState = {
            name: 'onlyThisState',
            value: null,
            required: false,
          };
        }

        if (mapTagParametersToObj.fromState) {
          mapTagParametersToObj.fromState.value = null;
        } else {
          mapTagParametersToObj.fromState = {
            name: 'fromState',
            value: null,
            required: false,
          };
        }

        if (onlyThisState) {
          if (mapTagParametersToObj.onlyThisState) {
            mapTagParametersToObj.onlyThisState.value = onlyThisState;
          } else {
            mapTagParametersToObj.onlyThisState = {
              name: 'onlyThisState',
              value: onlyThisState,
              required: false,
            };
          }
          newTagParameters = Object.keys(mapTagParametersToObj).map(
            objKey => mapTagParametersToObj[objKey]
          ) as TActivationParameters;
        }
        if (fromStateValue) {
          if (mapTagParametersToObj.fromState) {
            mapTagParametersToObj.fromState.value = fromStateValue;
          } else {
            mapTagParametersToObj.fromState = {
              name: 'fromState',
              value: fromStateValue,
              required: false,
            };
          }
        }
        if (toStateValue) {
          if (mapTagParametersToObj.toState) {
            mapTagParametersToObj.toState.value = toStateValue;
          } else {
            mapTagParametersToObj.toState = {
              name: 'toState',
              value: toStateValue,
              required: false,
            };
          }
        }
        newTagParameters = Object.keys(mapTagParametersToObj).map(
          objKey => mapTagParametersToObj[objKey]
        ) as TActivationParameters;
      }

      onChangeCommonTag({
        tagName: isGlobal ? whatBlockIsGlobal : whatBlockIsNotGlobal,
        tagParameters: newTagParameters,
      });
    },
    [mapTagParametersToObj, onChangeCommonTag, whatBlockIsGlobal, whatBlockIsNotGlobal]
  );

  return {
    mapTagParametersToObj,
    isGlobal,
    changeEventParams,
    tagParameters,
    block,
    onChangeCommonTag,
  };
};
