export class StatsData {
    constructor() {
        this.labels = [];
        this.dataset = [];
        this.active = true;
        this.ts = [];
    }
}
class AnalyticsChartData {
    constructor() {
        this.setMessagesAndSessionData = (sessionData, messagesData) => {
            this.messagesStats.ts = messagesData.map(statDataItem => statDataItem.ts);
            this.messagesStats.labels = messagesData.map(statDataItem => new Date(statDataItem.ts).getDate());
            this.messagesStats.dataset = messagesData.map(statDataItem => statDataItem.value);
            this.sessionStats.ts = sessionData.map(statDataItem => statDataItem.ts);
            this.sessionStats.labels = sessionData.map(statDataItem => new Date(statDataItem.ts).getDate());
            this.sessionStats.dataset = sessionData.map(statDataItem => statDataItem.value);
        };
        this.toggleActivity = (type) => {
            this[type].active = !this[type].active;
        };
        this.prepareMessagesAndSessionsGraphData = (t) => {
            return [
                {
                    label: t('Analytics: Sessions'),
                    data: this.sessionStats.dataset,
                    fill: false,
                    borderColor: 'rgb(255, 127, 80)',
                    lineTension: 0.1,
                    active: this.sessionStats.active,
                },
                {
                    label: t('Analytics: Messages'),
                    data: this.messagesStats.dataset,
                    fill: false,
                    borderColor: 'rgb(255, 206, 86)',
                    lineTension: 0.1,
                    active: this.messagesStats.active,
                },
            ];
        };
        this.messagesStats = new StatsData();
        this.sessionStats = new StatsData();
    }
}
export default AnalyticsChartData;
