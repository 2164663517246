import React, { FC, useCallback } from 'react';
import { FormGroup, Label, InputText } from '@just-ai/just-ui';

import { t } from 'localization';

import { CommonActivationParams } from '../CommonActivationParams';
import { IEventActivationEdit } from './index';
import classes from '../Activations.module.scss';

const CustomEventActivationEdit: FC<IEventActivationEdit> = ({
  onChange,
  activationEvent,
  isGlobal,
  onChangeIsGlobal,
  onlyThisState,
  onChangeOnlyThisState,
  fromStateValue,
  toStateValue,
  onChangeFromStateValue,
  isFromState,
  isToState,
}) => {
  const onChangeLocal = useCallback(
    (title: string) => {
      onChange(!title ? 'Custom event' : title);
    },
    [onChange]
  );

  return (
    <div className={classes.EventActivation}>
      <FormGroup tag='div'>
        <Label for='eventName'>{t('EventActivation:CustomEvent:Title')}</Label>
        <InputText
          id='eventName'
          placeholder={t('EventActivation:CustomEvent:Title:Placeholder')}
          value={activationEvent === 'Custom event' ? '' : activationEvent}
          onChange={onChangeLocal}
          autoFocus
          data-test-id='EventActivation:CustomEvent_Value'
        />
      </FormGroup>
      <div className={classes.divider} style={{ marginLeft: 0, marginRight: -32, width: 'auto' }} />
      <CommonActivationParams
        isFromState={isFromState}
        isToState={isToState}
        isGlobal={isGlobal}
        onChangeIsGlobal={onChangeIsGlobal}
        onlyThisState={onlyThisState}
        onChangeOnlyThisState={onChangeOnlyThisState}
        fromStateValue={fromStateValue}
        toStateValue={toStateValue}
        onChangeStateValue={onChangeFromStateValue}
      />
    </div>
  );
};

export default CustomEventActivationEdit;
