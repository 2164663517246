import { useCallback, useMemo } from 'react';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

import { amplitudeInstance } from 'pipes/functions';

import { TagNames, TJBlock, TTagParameters } from 'modules/JGraph/utils/types';
import { JStateWithId } from '../../../../../reducers/JGraph.reducer/types';
import { useSavingPipe } from '../../../hooks/savingPipe';
import { deleteBlockInScreen } from 'reducers/JGraph.reducer';
import {
  createBlockTagParameters,
  getCustomTagParametersFieldsType,
  tagParametersToObj,
} from '../../../../../reducers/JGraph.reducer/utils';
import { isEditBlockTypeCustomTag } from '../utils/common';
import { CustomTagParameterType } from '../../../../Editor/api/client';

export const useCustomTagEdit = (
  editMenuScreen: JStateWithId,
  dispatch: any = () => {},
  returnToPreviousMenu: () => unknown,
  jBlockIndex?: number,
  path?: string,
  block?: TJBlock,
  tagParams?: TJBlock['tagParameters']
) => {
  const { stateUpdate } = useSavingPipe();

  const tagParametersObj = useMemo(() => {
    const tagParametersObj: Record<string, BehaviorSubject<TTagParameters<string, any>>> = {};
    if (isEditBlockTypeCustomTag(block)) {
      (tagParams || []).forEach(value => {
        if (!value.value && block?.tagName) {
          const blockDefaults = createBlockTagParameters(block!.tagName);
          const tagParamsObj = tagParametersToObj(blockDefaults);
          switch (block!.tagName) {
            case TagNames.Email:
              tagParamsObj.files = {
                ...tagParamsObj.files,
                value: '[]',
              };
              break;
            case TagNames.HttpRequest:
              tagParamsObj.timeout = {
                ...tagParamsObj.timeout,
                value: '0',
              };
              tagParamsObj.headers = {
                ...tagParamsObj.headers,
                value: [{ name: '', value: '' }],
              };
              tagParamsObj.vars = {
                ...tagParamsObj.vars,
                value: [{ name: '', value: '' }],
              };
              break;
          }
          value = tagParamsObj[value.name];
        }
        tagParametersObj[value.name] = new BehaviorSubject<TTagParameters<string, any>>(value);
      });
    }
    return tagParametersObj;
  }, [block, tagParams]);

  const fieldDescriptor = useMemo(() => (block ? getCustomTagParametersFieldsType(block.tagName) : {}), [block]);

  const isBlockNew = useMemo(() => {
    return (tagParams || []).some(value => value.required && !value.value);
  }, [tagParams]);

  const onChange = useCallback(
    (key: string, value: any) => {
      const currentValue = tagParametersObj[key].getValue();
      tagParametersObj[key].next({ ...currentValue, value });
    },
    [tagParametersObj]
  );

  const checkRequiredFulfilledByFieldType = useCallback(
    (key: string, currentValue: TTagParameters<string, any>) => {
      if (fieldDescriptor && fieldDescriptor[key] === CustomTagParameterType.Bool) return false;
      return currentValue.required && !currentValue.value;
    },
    [fieldDescriptor]
  );

  const isRequired = useCallback(
    (key: string) => {
      const currentValue = tagParametersObj[key].getValue();
      return currentValue.required;
    },
    [tagParametersObj]
  );

  const isRequiredNotFilled = useCallback(
    (key: string) => {
      const currentValue = tagParametersObj[key].getValue();
      return checkRequiredFulfilledByFieldType(key, currentValue);
    },
    [checkRequiredFulfilledByFieldType, tagParametersObj]
  );

  const isAllRequiredFilled = useCallback(() => {
    const fieldNames = Object.keys(tagParametersObj);
    return Object.values(tagParametersObj).every((param, index) => {
      const paramValue = param.getValue();
      return !checkRequiredFulfilledByFieldType(fieldNames[index], paramValue);
    });
  }, [checkRequiredFulfilledByFieldType, tagParametersObj]);

  const isAllRequiredNotFilled = useMemo(() => {
    return combineLatest(Object.values(tagParametersObj)).pipe(
      map(allObservablesValues =>
        allObservablesValues.some(value => checkRequiredFulfilledByFieldType(value.name, value))
      )
    );
  }, [checkRequiredFulfilledByFieldType, tagParametersObj]);

  const fieldValue = useCallback(
    key => {
      const currentValue = tagParametersObj[key].getValue();
      return currentValue.value;
    },
    [tagParametersObj]
  );

  const save = useCallback(() => {
    const tagParamsValues = Object.values(tagParametersObj).map(observable => observable.getValue());
    if (block && isAllRequiredFilled()) {
      block.tagParameters = tagParamsValues.map(tagParameter => {
        if (fieldDescriptor && fieldDescriptor[tagParameter.name] === CustomTagParameterType.Json) {
          const numberOfLineBreaks = (tagParameter.value.match(/\n/g) || []).length;
          if (!tagParameter.value.startsWith('\n') && numberOfLineBreaks > 0) {
            tagParameter.value = '\n' + tagParameter.value;
          }
          tagParameter.value = tagParameter.value.replace(/(\n)+(\s)*/i, '\n');
        }
        return tagParameter;
      });
      amplitudeInstance.logEvent('J-Graph Action added', { tagName: block.tagName });
      stateUpdate(editMenuScreen);
    }
  }, [block, editMenuScreen, fieldDescriptor, isAllRequiredFilled, stateUpdate, tagParametersObj]);

  const cancel = useCallback(() => {
    if (isBlockNew) {
      dispatch(
        deleteBlockInScreen({
          screenId: editMenuScreen.pathId,
          blockIndex: jBlockIndex || 0,
          path,
        })
      );
      return;
    }
    returnToPreviousMenu();
  }, [isBlockNew, returnToPreviousMenu, dispatch, editMenuScreen.pathId, jBlockIndex, path]);

  return {
    tagParametersObj,
    onChange,
    disabledSave: isAllRequiredNotFilled,
    isRequiredNotFilled,
    isRequired,
    fieldValue,
    save,
    cancel,
  };
};
