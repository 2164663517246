const prefix = '@callTask/';

export const CREATE_CALL_TASK = prefix + 'CREATE_CALL_TASK';
export const CREATE_CALL_TASK_PENDING = prefix + 'CREATE_CALL_TASK_PENDING';
export const CREATE_CALL_TASK_FULFILLED = prefix + 'CREATE_CALL_TASK_FULFILLED';
export const CREATE_CALL_TASK_REJECTED = prefix + 'CREATE_CALL_TASK_REJECTED';

export const LIST_CALL_TASK = prefix + 'LIST_CALL_TASK';
export const LIST_CALL_TASK_PENDING = prefix + 'LIST_CALL_TASK_PENDING';
export const LIST_CALL_TASK_FULFILLED = prefix + 'LIST_CALL_TASK_FULFILLED';
export const LIST_CALL_TASK_REJECTED = prefix + 'LIST_CALL_TASK_REJECTED';

export const GET_CALL_TASK = prefix + 'GET_CALL_TASK';
export const GET_CALL_TASK_PENDING = prefix + 'GET_CALL_TASK_PENDING';
export const GET_CALL_TASK_FULFILLED = prefix + 'GET_CALL_TASK_FULFILLED';
export const GET_CALL_TASK_REJECTED = prefix + 'GET_CALL_TASK_REJECTED';

export const UPDATE_CALL_TASK = prefix + 'UPDATE_CALL_TASK';
export const UPDATE_CALL_TASK_PENDING = prefix + 'UPDATE_CALL_TASK_PENDING';
export const UPDATE_CALL_TASK_FULFILLED = prefix + 'UPDATE_CALL_TASK_FULFILLED';
export const UPDATE_CALL_TASK_REJECTED = prefix + 'UPDATE_CALL_TASK_REJECTED';

export const DELETE_CALL_TASK = prefix + 'DELETE_CALL_TASK';
export const DELETE_CALL_TASK_PENDING = prefix + 'DELETE_CALL_TASK_PENDING';
export const DELETE_CALL_TASK_FULFILLED = prefix + 'DELETE_CALL_TASK_FULFILLED';
export const DELETE_CALL_TASK_REJECTED = prefix + 'DELETE_CALL_TASK_REJECTED';

export const CONTROL_CALL_TASK = prefix + 'CONTROL_CALL_TASK';
export const CONTROL_CALL_TASK_PENDING = prefix + 'CONTROL_CALL_TASK_PENDING';
export const CONTROL_CALL_TASK_FULFILLED = prefix + 'CONTROL_CALL_TASK_FULFILLED';
export const CONTROL_CALL_TASK_REJECTED = prefix + 'CONTROL_CALL_TASK_REJECTED';

export const RESET = prefix + 'RESET';
