var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import "./style.scss";
export const Toolbar = (_a) => {
    var { className, editor, TabsComponent } = _a, restProps = __rest(_a, ["className", "editor", "TabsComponent"]);
    return (_jsxs(_Fragment, { children: [TabsComponent && _jsx(TabsComponent, {}), _jsx("div", Object.assign({ className: classNames("justui_toolbar", "justui_toolbar__horizontal", { "justui_toolbar": editor }, className), style: { paddingRight: TabsComponent && '1px' } }, restProps))] }));
};
export const ToolbarLeft = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar-left", className) }, restProps, { children: children })));
};
export const ToolbarRight = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar-right", className) }, restProps, { children: children })));
};
export const ToolbarVertical = (_a) => {
    var { className } = _a, restProps = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar", "justui_toolbar__vertical", className) }, restProps)));
};
export const ToolbarTop = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar-top", className) }, restProps, { children: children })));
};
export const ToolbarBottom = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar-bottom", className) }, restProps, { children: children })));
};
export const ToolbarButtonBox = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("button-box", className) }, restProps, { children: children })));
};
export const ToolbarSection = (_a) => {
    var { children, className } = _a, restProps = __rest(_a, ["children", "className"]);
    return (_jsx("div", Object.assign({ className: classNames("justui_toolbar_section", className) }, restProps, { children: children })));
};
