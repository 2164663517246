const prefix = '@accounts/';

export const LOGIN_TO_ACCOUNT = prefix + 'LOGIN_TO_ACCOUNT';
export const LOGIN_TO_ACCOUNT_PENDING = prefix + 'LOGIN_TO_ACCOUNT_PENDING';
export const LOGIN_TO_ACCOUNT_FULFILLED = prefix + 'LOGIN_TO_ACCOUNT_FULFILLED';
export const LOGIN_TO_ACCOUNT_REJECTED = prefix + 'LOGIN_TO_ACCOUNT_REJECTED';

export const CHANGE_CURRENT_LOGIN_ACCOUNT = prefix + 'CHANGE_CURRENT_LOGIN_ACCOUNT';
export const CHANGE_CURRENT_LOGIN_ACCOUNT_PENDING = prefix + 'CHANGE_CURRENT_LOGIN_ACCOUNT_PENDING';
export const CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED = prefix + 'CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED';
export const CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED = prefix + 'CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED';

export const LOGOUT_FROM_ACCOUNT = prefix + 'LOGOUT_FROM_ACCOUNT';
export const LOGOUT_FROM_ACCOUNT_PENDING = prefix + 'LOGOUT_FROM_ACCOUNT_PENDING';
export const LOGOUT_FROM_ACCOUNT_FULFILLED = prefix + 'LOGOUT_FROM_ACCOUNT_FULFILLED';
export const LOGOUT_FROM_ACCOUNT_REJECTED = prefix + 'LOGOUT_FROM_ACCOUNT_REJECTED';

export const NEW_SELECT_FEATURES = prefix + 'NEW_SELECT_FEATURES';
export const SET_CURRENT_LOGIN_ACCOUNT = prefix + 'SET_CURRENT_LOGIN_ACCOUNT';
export const CHANGE_SELECTED_ITEM = prefix + 'CHANGE_SELECTED_ITEM';
export const SELECT_ITEM = prefix + 'SELECT_ITEM';
export const DESELECT_ITEM = prefix + 'DESELECT_ITEM';
export const FILTER_DATA = prefix + 'FILTER_DATA';
export const DROP_FILTER = prefix + 'DROP_FILTER';
export const DROP_CURRENT_LOGIN_ACCOUNT = prefix + 'DROP_CURRENT_LOGIN_ACCOUNT';
export const REMOVE_ADD_ACCOUNT_ALERT = prefix + 'REMOVE_ADD_ACCOUNT_ALERT';
export const REMOVE_EDIT_ACCOUNT_ALERT = prefix + 'REMOVE_EDIT_ACCOUNT_ALERT';
export const DROP_EDIT_ACCOUNT_ERRORS = prefix + 'DROP_EDIT_ACCOUNT_ERRORS';

export const ACCOUNTS_BULK_FEATURES_EDIT = prefix + 'ACCOUNTS_BULK_FEATURES_EDIT';
