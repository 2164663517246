import React, { SyntheticEvent, useCallback } from 'react';
import { Icon } from '@just-ai/just-ui';
import { ClassificationPredicate } from 'modules/Caila/api/client';
import { t } from 'localization';
import classNames from 'classnames';

type EntityRowProps = {
  predicate: ClassificationPredicate;
  content: string;
  valid: boolean;
  redirect?: (e: SyntheticEvent) => void;
  isSystem: boolean;
  isShared: boolean;
};

export const EntityRow = ({ predicate, content, valid, isSystem, redirect, isShared }: EntityRowProps) => {
  const handleClick = useCallback(
    event => {
      if (isSystem || isShared) return;
      redirect && redirect(event);
    },
    [isSystem, isShared, redirect]
  );
  return (
    <tr>
      <td>{t(`ClassificationRules:${predicate.toLowerCase()}`)}</td>
      <td className={classNames({ withHover: !isSystem && !isShared })} onClick={handleClick}>
        {content}
      </td>
      <td>
        <Icon name={valid ? 'farCheck' : 'farTimes'} />
      </td>
    </tr>
  );
};
