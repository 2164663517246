import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import cn from 'classnames';
import { Tooltip } from '@just-ai/just-ui/dist/Tooltip';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import ApplyChangesButton from './ApplyChangesButton';
import { FAQ_MAX_SKILLS } from '../../constants';
import { TabsCollapsible } from '../TabsCollapsible';
import { useDeleteModal } from '../FaqTreeContextMenu/hooks';
import { DeleteSkillModal } from '../DeleteModal';
import classes from './styles.module.scss';
import './styles.scss';
import { useNLUModuleContext } from '../../../../context';
import { useTranslation } from '@just-ai/just-ui';
const FaqTemplatesTabs = function ({ skills, activeTab, onChange, needsTraining, trainNlu, onTabDelete, onTabRename, onTabToggle, writeDisabled, homeTabCode, }) {
    const { wrappedHistoryPush } = useNLUModuleContext();
    const { t } = useTranslation();
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal, handleDelete, skillToDelete] = useDeleteModal(false, onTabDelete);
    const maxSkillsReached = skills.length >= FAQ_MAX_SKILLS;
    const tabsWithHomeTab = useMemo(() => {
        return [
            { dataTestId: 'FAQHomeTab', withoutContextMenu: true, value: homeTabCode, name: _jsx(Icon, { name: 'farHomeLgAlt' }) },
            ...skills.map(skill => ({
                name: skill.editComponent || skill.name,
                value: skill.name,
                dataTestId: skill.name,
                skillDisabled: !skill.enabled,
            })),
        ];
    }, [homeTabCode, skills]);
    return (_jsxs("div", { className: cn('faqTemplatesTabs', classes.faqTemplatesTabs), children: [_jsx(TabsCollapsible, { activeTab: activeTab, onChange: onChange, className: classes.faqTemplatesTabs__tabs, tabs: tabsWithHomeTab, onTabDelete: openDeleteModal, onTabRename: onTabRename, onTabDisable: onTabToggle }), _jsxs("span", { onClick: () => !maxSkillsReached &&
                    !writeDisabled &&
                    wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true, fromTab: activeTab } }), className: cn(classes.faqTemplatesTabs__actionBtn, (maxSkillsReached || writeDisabled) && classes['faqTemplatesTabs__actionBtn--disabled']), id: 'addSkillBtn', children: [_jsx(Icon, { name: 'farPlus', size: 'sm' }), t('FAQ:AddSkill')] }), maxSkillsReached && _jsx(Tooltip, { target: 'addSkillBtn', children: t('FAQ:AddSkills:MaxTooltip') }), _jsx(ApplyChangesButton, { needsTraining: needsTraining, trainNlu: trainNlu }), _jsx(DeleteSkillModal, { isOpen: isDeleteModalOpen, close: closeDeleteModal, handleDelete: handleDelete, deleteItem: skillToDelete })] }));
};
export default React.memo(FaqTemplatesTabs);
