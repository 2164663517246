import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ClickAwayListener, InputText } from '@just-ai/just-ui';
import { shift, offset, flip, ComputePositionConfig } from '@floating-ui/react-dom';
import KeyboardService from '@just-ai/nlu-modules/dist/services/KeyboardService';

import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { amplitudeInstance } from 'pipes/functions';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import { closeThemeCreationMenu } from 'reducers/JGraph.reducer';
import { getAllStates } from 'reducers/JGraph.reducer/Graph';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import createTheme from 'reducers/JGraph.reducer/AsyncActions/createTheme';
import { themeNameValidation } from 'modules/JGraph/utils/validators/themeName';
import { getNewNameWithIndex } from 'modules/JGraph/utils/stageUtils';

import { transformNameToThemePath } from '../../utils/themesUtils';

import styles from './styles.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'right-start',
  middleware: [
    offset({
      mainAxis: -10,
      alignmentAxis: -30,
    }),
    flip(),
    shift(),
  ],
};

const ThemeCreationMenu: FC = () => {
  const { themeCreationMenu, blocks, themes } = useAppSelector(state => ({
    blocks: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
    themeCreationMenu: state.JGraphReducer.themeCreationMenu,
  }));

  const dispatch = useAppDispatch();
  const [nameExistError, setError] = useState(false);
  const menuWrapper = useRef<HTMLDivElement>(null);
  const keyboardService = useRef(new KeyboardService());
  const [errorText, setErrorText] = useState('');
  const allThemesNames = useMemo(() => themes.map(el => el.value), [themes]);
  const allStatesNames = useMemo(() => getAllStates(blocks), [blocks]);

  const [themeName, setThemeName] = useState(() => {
    return getNewNameWithIndex(`NewTheme`, [...allThemesNames, ...allStatesNames]);
  });
  const touched = useRef(false);

  useEffect(() => {
    if (!themeCreationMenu.open) {
      setThemeName(getNewNameWithIndex(`NewTheme`, [...allThemesNames, ...allStatesNames]));
      touched.current = false;
      setError(false);
      setErrorText('');
    }
  }, [allStatesNames, allThemesNames, blocks.length, themeCreationMenu.open, themes.length]);

  const closeModal = useCallback(() => {
    dispatch(closeThemeCreationMenu());
  }, [dispatch]);

  useEffect(() => {
    if (!themeCreationMenu.open) return;
    const keyboardServiceFromRef = keyboardService.current;
    keyboardServiceFromRef.bind('esc', closeModal);
    return () => keyboardServiceFromRef.unbindAll();
  }, [themeCreationMenu.open, closeModal]);

  const setThemeNameInner = useCallback(
    async value => {
      setThemeName(value);

      const themeValue = transformNameToThemePath(value);
      const validationResult = await themeNameValidation(
        { name: themeValue.trim() },
        { existedThemes: allThemesNames, existedStates: allStatesNames }
      );
      setErrorText(validationResult.errors?.name?.message || '');
      setError(!validationResult.isValid);

      touched.current = true;
    },
    [allThemesNames, allStatesNames]
  );

  const createScreen = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!themeName) return;

      amplitudeInstance.logEvent('J-Graph Theme Created');

      mainSave$.next({
        type: 'createTheme',
        path: themeName,
        action: () =>
          dispatch(
            createTheme({
              themeToCreate: {
                value: transformNameToThemePath(themeName),
                ...themeCreationMenu.themePosition,
              },
            })
          ),
      });
    },
    [dispatch, themeCreationMenu.themePosition, themeName]
  );

  useFloaterPosition({
    enable: themeCreationMenu.open,
    floaterElement: menuWrapper,
    target: useMemo(
      () => ({
        width: 0,
        height: 0,
        x: themeCreationMenu.pointerPosition.x,
        y: themeCreationMenu.pointerPosition.y,
      }),
      [themeCreationMenu.pointerPosition]
    ),
    options: floaterOptions,
  });

  if (!themeCreationMenu.open) return null;

  return (
    <>
      <form onSubmit={createScreen}>
        <ClickAwayListener handleClickOut={closeModal} delay={100}>
          <div ref={menuWrapper} className={styles.ThemeCreationMenu}>
            <div className={styles.header}>
              <div className={styles.title}>{t('ThemeCreationMenu:title')}</div>
              <div>
                <InputText
                  data-test-id='ThemeCreationMenu:name'
                  value={themeName}
                  errorText={errorText}
                  onChange={setThemeNameInner}
                  hint={themeName === '/' ? t('ThemeCreationMenu:Hint:ThisIsRootTheme') : ''}
                  autoFocus
                />
              </div>
            </div>

            <div className={styles.footer}>
              <Button
                color='primary'
                type='submit'
                disabled={themeName.trim().length === 0 || nameExistError}
                data-test-id='ThemeCreationMenu:Create:submit'
              >
                {t('ThemeCreationMenu:Create')}
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </form>
    </>
  );
};

export default React.memo(ThemeCreationMenu);
