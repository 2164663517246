import { Vector2d } from 'konva/lib/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { OnStateMoved$ } from 'modules/JGraph/view/StickerLayer';

import { GraphApi } from '../utils';

const moveThemeApi = createAsyncThunk(
  'JGraph/moveThemeApi',
  async (moveJStateData: { newPositions: Vector2d; themeValue: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const theme = state.JGraphReducer.graph.themes.find(theme => theme.value === moveJStateData.themeValue);

    if (!theme) return;

    const { data: graphModel } = await GraphApi.move(userAccountId || accountId, projectShortName, {
      movementsDataWithFiles: [
        {
          filenames: theme.filenames,
          movementsData: [
            {
              target: moveJStateData.themeValue,
              ...moveJStateData.newPositions,
            },
          ],
        },
      ],
    });

    OnStateMoved$.next(undefined);

    return graphModel;
  }
);

export default moveThemeApi;
