const localization = {
    "dd": {
        en: "dd",
        ru: "дд",
        pt: 'dd'
    },
    "mm": {
        en: "mm",
        ru: "мм",
        pt: 'mm'
    },
    "yyyy": {
        en: "yyyy",
        ru: "гггг",
        pt: 'aaaa'
    },
    "HH": {
        en: "hh",
        ru: "чч",
        pt: 'hh'
    },
    "MM": {
        en: "mm",
        ru: "мм",
        pr: 'mm'
    },
    "full placeholder": {
        en: 'Date and time',
        ru: 'Дата и время',
        pt: 'Data e hora'
    },
    "date placeholder": {
        en: 'Date',
        ru: 'Дата',
        pt: 'Data'
    },
    "time placeholder": {
        en: 'Time',
        ru: 'Время',
        pt: 'Hora'
    },
};
export function translate(text, locale) {
    if (!localization[text])
        return text;
    return localization[text][locale] ? localization[text][locale] : localization[text].en;
}
