import { getBreadcrumbConfig } from '../views/Breadcrumb';

import { amplitudeInstance, isDev } from '../pipes/functions';
import { reduxStateReference } from '../services/ReduxStateReference';

type CrumbsDataType = {
  path: string[];
  index: number;
  location: 'dynamicRoutesMap' | 'staticRoutesMap';
  pathname: string;
  matched: Match[];
};

type Match = {
  path: string;
  value: string;
};

const crumbsData = [
  ...Object.keys(getBreadcrumbConfig().dynamicRoutesMap).map<CrumbsDataType>((crumb, index) => ({
    path: crumb.split('/'),
    index: index,
    pathname: crumb,
    location: 'dynamicRoutesMap',
    matched: [],
  })),
  ...Object.keys(getBreadcrumbConfig().staticRoutesMap).map<CrumbsDataType>((crumb, index) => ({
    path: crumb.split('/'),
    index: index,
    pathname: crumb,
    location: 'staticRoutesMap',
    matched: [],
  })),
];

const fillSplittedCrumbWithLocationParams = (crSplittedParam: CrumbsDataType, splittedPathname: string[]) => {
  for (let i = 0; i < crSplittedParam.path.length; i += 1) {
    if (crSplittedParam.path[i].startsWith(':')) {
      crSplittedParam.matched.push({
        path: crSplittedParam.path[i],
        value: splittedPathname[i],
      });
      crSplittedParam.path[i] = splittedPathname[i];
    }
  }
  return crSplittedParam;
};

const getCrumbPath = (crumbPath: string[]) => {
  if (crumbPath[1] === 'project-create') {
    let returnCrumbPath = [...crumbPath];
    returnCrumbPath.shift();
    return returnCrumbPath.join('/');
  }
  if (crumbPath[2] === 'statistic' && crumbPath[3]) return [crumbPath[2], crumbPath[3]].join('/');
  return crumbPath[2];
};

let previousPath = '';
export const locationParserForAmplitude = (splitPathname: string[], pathname: string) => {
  let modifiedCrumbs = [...crumbsData]
    .filter(crSplitted => crSplitted.path.length === splitPathname.length)
    .map(crSplittedParam => fillSplittedCrumbWithLocationParams(crSplittedParam, splitPathname))
    .map(crSplittedParam => {
      crSplittedParam.pathname = crSplittedParam.path.join('/');
      return crSplittedParam;
    })
    .filter(crParamWithPathname => crParamWithPathname.pathname === pathname);

  const result: { [key: string]: any } = {};
  const storeState = reduxStateReference.getState();
  const currentProject = storeState.CurrentProjectsReducer.currentProject;
  //@ts-ignore
  const projectList = storeState.ProjectsReducer.projectList;
  const currentProjectFromList = projectList.find(
    (project: object & { shortName: string; name: string }) => project.shortName === currentProject
  );
  result['Project_Id'] = currentProject || '';
  result['Project_Title'] = currentProjectFromList?.name || '';
  if (modifiedCrumbs.length > 0 && modifiedCrumbs[0].location !== 'staticRoutesMap') {
    const crumb = modifiedCrumbs[0];
    result['Page_Id'] =
      crumb.matched.length > 0 || crumb.pathname.startsWith('/project-create')
        ? getCrumbPath(crumb.path)
        : crumb.path[1];
  } else {
    if (result['Project_Id']) {
      result['Page_Id'] = 'project_index';
    } else {
      result['Page_Id'] = 'account_index';
    }
  }

  if (previousPath && previousPath === pathname) return;
  previousPath = pathname;

  isDev() && console.log(result);
  amplitudeInstance.logEvent('Page activated', result);
};
