import React, { useCallback } from 'react';

import { useAppSelector, useAppDispatch } from 'storeHooks';

import ThemesHeaderIcon from 'modules/JGraph/view/ThemesHeaderIcon';
import { openThemesPage } from 'reducers/JGraph.reducer';

import styles from './styles.module.scss';

interface JGraphBreadcrumbProps {}
const JGraphBreadcrumb = ({}: JGraphBreadcrumbProps) => {
  const dispatch = useAppDispatch();

  const { isThemesOpen } = useAppSelector(state => ({
    isThemesOpen: state.JGraphReducer.isThemesOpen,
  }));

  const onOpenThemes = useCallback(() => {
    if (isThemesOpen) return;
    dispatch(openThemesPage());
  }, [dispatch, isThemesOpen]);

  return (
    <div className={styles.JGraphBreadcrumb} data-test-id='Editor.MainToolbar.Themes'>
      <ThemesHeaderIcon active={!isThemesOpen} onClick={onOpenThemes} />
      <div style={{ display: 'flex' }} id='JGraphBoundingPlace' />
    </div>
  );
};

JGraphBreadcrumb.displayName = 'JGraphBreadcrumb';

export default React.memo(JGraphBreadcrumb);
