import React, { Component } from 'react';
import { Input, Alert, Label, Col, FormGroup } from 'reactstrap';
import { Modal } from '@just-ai/just-ui';
import { AnalyticsContext } from '@just-ai/analytic-front';
import ReactSelect from 'react-select';
import { getUserValidName } from '../../pipes/functions';
import localize from '../../localization';
import { forEach, isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setLastSelectedUser, getTaskCreationData } from 'actions/dialogsDetail.actions';

import { createLogTask, updateLogTask } from '../../actions/phrases.actions';
import { createSessionTask, updateSessionTask } from '../../actions/dialogsDetail.actions';
import { getTotalUserTasksCount } from '../../actions/tasks.actions';
import { Spinner } from '@just-ai/just-ui/dist';

export default class TaskModal extends Component {
  constructor(props) {
    super(props);

    this.input = {
      description: React.createRef(),
      name: React.createRef(),
    };
  }

  saveTask = async () => {
    const { issue, lastSelectedPriority, lastSelectedUser, saveTask } = this.props;

    let task = {
      description: this.input.description.current.value,
      name: this.input.name.current.value,
      issueLabels:
        !isEmpty(issue) && !!issue.labels
          ? issue.labels.map(item => {
              return item.id;
            })
          : [],
    };

    if (!isEmpty(issue) && issue.id > 0) {
      task = {
        ...task,
        issueId: issue.id,
      };
    }

    if (!isEmpty(issue) && !!issue.priority) {
      task = {
        ...task,
        priorityId: issue.priority.id,
      };
    } else if (Boolean(lastSelectedPriority)) {
      task = {
        ...task,
        priorityId: lastSelectedPriority,
      };
    }

    if (!isEmpty(issue) && !!issue.responsible) {
      const { users } = this.props;
      let selectedUsers = null;
      if (!issue.responsible.email && users.length > 0) {
        let responsible = users.find(item => {
          return item.id === issue.responsible.id;
        });
        issue.responsible = {
          ...issue.responsible,
          ...responsible,
        };
      }
      selectedUsers = {
        value: issue.responsible.id,
        label: getUserValidName(
          issue.responsible.firstName,
          issue.responsible.lastName,
          issue.responsible.email,
          issue.responsible.fullName
        ),
      };

      if (!selectedUsers) {
        if (!!lastSelectedUser) {
          selectedUsers = { ...lastSelectedUser };
        }
      }
      task = {
        ...task,
        responsibleUserId: selectedUsers.value,
      };
    } else if (Boolean(lastSelectedUser)) {
      task = {
        ...task,
        responsibleUserId: lastSelectedUser,
      };
    }

    if (!isEmpty(issue) && !!issue.state) {
      task = {
        ...task,
        stateId: issue.state.id,
      };
    }

    await saveTask(task);
  };

  setSelectStates = val => {
    const { setTaskSelect } = this.props;
    setTaskSelect({ id: val.value, name: val.label }, 'state');
  };

  setSelectPriorities = val => {
    const { setTaskSelect, setLastSelectedPriority } = this.props;
    let value = Boolean(val) ? { id: val.value, name: val.label } : null;
    setTaskSelect(value, 'priority');
    if (Boolean(value)) {
      setLastSelectedPriority(val.value);
    }
  };

  setSelectUsers = val => {
    const { setTaskSelect, setLastSelectedUser } = this.props;
    setTaskSelect({ id: val.value, firstName: val.label }, 'responsible');
    if (Boolean(val.value)) {
      setLastSelectedUser(val.value);
    }
  };

  setSelectLabels = val => {
    const { setTaskSelect } = this.props;
    let value = [];
    if (val && val.length > 0) {
      forEach(val, item => {
        value.push({
          id: item.value,
          name: item.label,
        });
      });
    }
    setTaskSelect(value, 'labels');
  };

  render() {
    const {
      issue,
      modalLoad,
      errors,
      users,
      lastSelectedPriority,
      lastSelectedUser,
      togglePopup,
      popup,

      issuesPriorities = [],
      issuesLabels = [],
      issuesStates = [],
    } = this.props;

    if (!issuesPriorities) debugger;

    const prioritiesOptions = issuesPriorities.map(status => {
      return { value: status.id, label: status.name };
    });

    let selectedPriority = null;
    if (!isEmpty(issue) && Boolean(issue.priority) && Boolean(issue.priority.id)) {
      if (!issue.priority.name && issuesPriorities.length > 0) {
        let priority = issuesPriorities.find(item => {
          return item.id === issue.priority.id;
        });
        issue.priority.name = priority.name;
      }
      selectedPriority = { value: issue.priority.id, label: issue.priority.name };
    }

    const statesOptions = issuesStates.map(state => {
      return { label: state.name, value: state.id };
    });

    let selectedState = null;
    if (!isEmpty(issue) && Boolean(issue.state)) {
      selectedState = statesOptions.find(state => {
        return state.value === issue.state.id;
      });
    }

    if (!selectedPriority) {
      if (Boolean(lastSelectedPriority)) {
        selectedPriority = lastSelectedPriority;
      }
    }

    let usersOptions = [];
    forEach(users, item => {
      usersOptions.push({
        value: item.id,
        label: getUserValidName(item.firstName, item.lastName, item.email, item.fullName),
      });
    });

    let selectedUsers = null;
    if (!isEmpty(issue) && !!issue.responsible) {
      if (!issue.responsible.email && users.length > 0) {
        let responsible = users.find(item => {
          return item.id === issue.responsible.id;
        });
        issue.responsible = {
          ...issue.responsible,
          ...responsible,
        };
      }
      selectedUsers = {
        value: issue.responsible.id,
        label: getUserValidName(
          issue.responsible.firstName,
          issue.responsible.lastName,
          issue.responsible.email,
          issue.responsible.fullName
        ),
      };
    }

    if (!selectedUsers) {
      if (!!lastSelectedUser) {
        selectedUsers = { ...lastSelectedUser };
      }
    }

    let marksOptions = issuesLabels.map(item => {
      return { value: item.id, label: item.name };
    });

    let selectedMark = [];
    if (!!issue && !!issue.labels) {
      forEach(issue.labels, item => {
        if (!item.name && marksOptions.length > 0) {
          let namedOption = marksOptions.find(mark => {
            return mark.value === item.id;
          });
          item.name = namedOption.label;
        }
        selectedMark.push({ value: item.id, label: item.name });
      });
    }

    const customStyles = {
      singleValue: (provided, state) => ({
        ...provided,
      }),
    };

    return (
      <Modal
        isOpen={popup}
        onCancelClick={togglePopup}
        title={Boolean(issue.id) ? localize.translate('Task changing') : localize.translate('Task creation')}
        onActionClick={this.saveTask}
        buttonCancelText={localize.translate('Cancel')}
        buttonSubmitColor='primary'
        buttonCancelColor='secondary'
        buttonSubmitText={Boolean(issue.id) ? localize.translate('Change task') : localize.translate('Create task')}
        className='createChannelModal'
        disableActionButtonAutoFocus
      >
        {modalLoad && <Spinner size='4x' />}

        {errors?.map((error, ind) => (
          <Alert color='danger' key={`updateIssueErrors_${ind}`}>
            {error}
          </Alert>
        ))}
        <FormGroup className='d-flex'>
          <Label className='pl-0' for='task_name' sm={3}>
            {localize.translate('Title')} <span className='color-danger'>*</span>
          </Label>
          <Col className='pr-0' sm={9}>
            <Input
              type='text'
              name='name'
              id='task_name'
              required
              innerRef={this.input.name}
              defaultValue={!isEmpty(issue) ? issue.name : ''}
              autoFocus
            />
          </Col>
        </FormGroup>
        <FormGroup className='d-flex'>
          <Label className='pl-0' for='task_description' sm={3}>
            {localize.translate('Description task')}
          </Label>
          <Col className='pr-0' sm={9}>
            <Input
              type='textarea'
              name='description'
              id='task_description'
              innerRef={this.input.description}
              defaultValue={!isEmpty(issue) ? issue.description : ''}
            />
          </Col>
        </FormGroup>
        {statesOptions.length > 0 ? (
          <FormGroup className='d-flex'>
            <Label className='pl-0' sm={3}>
              {localize.translate('Task status')}
            </Label>
            <Col className='pr-0' sm={9}>
              <ReactSelect
                id='taskStatus'
                placeholder=''
                className='react-select'
                classNamePrefix='react-select'
                options={statesOptions}
                style={{ width: '100%' }}
                styles={customStyles}
                value={selectedState}
                menuPosition='fixed'
                onChange={this.setSelectStates}
              />
            </Col>
          </FormGroup>
        ) : null}
        {prioritiesOptions.length > 0 ? (
          <FormGroup className='d-flex'>
            <Label className='pl-0' sm={3}>
              {localize.translate('Priority')}
            </Label>
            <Col className='pr-0' sm={9}>
              <ReactSelect
                id='taskPriority'
                placeholder=''
                options={prioritiesOptions}
                isClearable
                className='react-select'
                classNamePrefix='react-select'
                style={{ width: '100%' }}
                styles={customStyles}
                value={selectedPriority}
                menuPosition='fixed'
                onChange={this.setSelectPriorities}
              />
            </Col>
          </FormGroup>
        ) : null}
        {usersOptions.length > 0 ? (
          <FormGroup className='d-flex'>
            <Label className='pl-0' sm={3}>
              {localize.translate('Responsible')}
            </Label>
            <Col className='pr-0' sm={9}>
              <ReactSelect
                id='Responsible'
                placeholder=''
                options={usersOptions}
                className='react-select'
                classNamePrefix='react-select'
                style={{ width: '100%' }}
                styles={customStyles}
                value={selectedUsers}
                menuPosition='fixed'
                onChange={this.setSelectUsers}
              />
            </Col>
          </FormGroup>
        ) : null}

        {marksOptions.length > 0 ? (
          <FormGroup className='d-flex'>
            <Label className='pl-0' sm={3}>
              {localize.translate('Tags')}
            </Label>
            <Col className='pr-0' sm={9}>
              <ReactSelect
                id='Tags'
                placeholder=''
                className='react-select'
                classNamePrefix='react-select'
                isMulti
                noOptionsMessage={() => localize.translate('No matches')}
                options={marksOptions}
                style={{ width: '100%' }}
                styles={customStyles}
                value={selectedMark}
                menuPosition='fixed'
                onChange={this.setSelectLabels}
              />
            </Col>
          </FormGroup>
        ) : null}
      </Modal>
    );
  }
}

class TaskEditModalForReporter extends Component {
  static contextType = AnalyticsContext;
  state = {
    show: true,
    settings: {},
    errors: [],
  };

  componentDidMount() {
    this.props.actions.getTaskCreationData();
  }

  saveTask = async taskObj => {
    const {
      questionId,
      sessionId,
      actions: { updateSessionTask, updateLogTask, createSessionTask, createLogTask, getTotalUserTasksCount },
    } = this.props;

    const { projectShortName } = this.context;

    taskObj.logId = questionId;
    if (questionId) {
      taskObj.questionId = questionId;
    }

    if (sessionId) {
      taskObj.sessionId = sessionId;
      taskObj.naturalSessionId = sessionId;
    }

    if (!!taskObj.issueId) {
      (questionId ? updateLogTask : updateSessionTask)(projectShortName, taskObj).then(result => {
        getTotalUserTasksCount(projectShortName, this.getUsersForTasks());
        this.props.toggle(result);
      });
    } else {
      (questionId ? createLogTask : createSessionTask)(projectShortName, taskObj).then(result => {
        getTotalUserTasksCount(projectShortName, this.getUsersForTasks());
        this.props.toggle(result);
      });
    }
    window.dispatchEvent(new CustomEvent('UPDATE_TASK_COUNT_EVENT', {}));
  };

  getUsersForTasks = () => {
    if (this.props.selectedUsers === null) return undefined;
    return {
      users:
        Array.isArray(this.props.selectedUsers) && this.props.selectedUsers.length === 0
          ? this.props.currentUser.id
          : this.props.selectedUsers.map(user => user.value ?? user).join(','),
    };
  };

  setSelectPriorities = id => {
    this.setState({ settings: { ...this.state.settings, priority: { id } } });
  };

  setTaskSelect = (value, type) => {
    this.setState({ settings: { ...this.state.settings, [type]: value } });
  };

  render() {
    const { isShown, issue, toggle, users, sessionId, errors, sessionErrors } = this.props;
    const { show, settings } = this.state;
    if (show === false) return null;

    const { issuesPriorities, issuesStates, issuesLabels } = this.context?.labelsContext || {};

    return (
      <TaskModal
        togglePopup={toggle}
        popup={isShown}
        saveTask={this.saveTask}
        issuesPriorities={issuesPriorities || []}
        issuesStates={issuesStates || []}
        issue={{ ...issue, ...settings } || { ...settings }}
        errors={sessionId ? sessionErrors : errors}
        issuesLabels={issuesLabels || []}
        users={users}
        setLastSelectedPriority={this.setSelectPriorities}
        setTaskSelect={this.setTaskSelect}
        setLastSelectedUser={this.props.actions.setLastSelectedUser}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.DialogsDetailReducer.users,
    sessionErrors: state.DialogsDetailReducer.saveIssueErrors,
    errors: state.PhrasesReducer.saveIssueErrors,
    currentUser: state.CurrentUserReducer.currentUser,
    selectedUsers: state.TasksReducer.selectedUsers,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLastSelectedUser,
      getTaskCreationData,
      updateSessionTask,
      updateLogTask,
      createSessionTask,
      createLogTask,
      getTotalUserTasksCount,
    },
    dispatch
  ),
});

const TaskEditModalForReporterWrapped = connect(mapStateToProps, mapDispatchToProps)(TaskEditModalForReporter);

export function WrappedTaskEditModalForReporter({ isShown, toggle, issue, questionId, sessionId }) {
  return (
    <TaskEditModalForReporterWrapped
      isShown={isShown}
      toggle={toggle}
      issue={issue}
      questionId={questionId}
      sessionId={sessionId}
    />
  );
}
