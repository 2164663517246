import { useEffect, useState } from 'react';
export function useBehaviorSubject(behaviorSubject) {
    const [subjectValue, setSubjectValue] = useState(behaviorSubject.getValue());
    useEffect(() => {
        const unsub = behaviorSubject.subscribe(data => {
            setSubjectValue(data);
        });
        return () => unsub.unsubscribe();
    }, [behaviorSubject]);
    return subjectValue;
}
