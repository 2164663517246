import keyboardjs, { KeyEvent } from 'keyboardjs';

export default class KeyboardService {
  private listeners: Map<string, [(event?: KeyEvent) => void, ((event?: KeyEvent) => void) | undefined]>;

  constructor() {
    this.listeners = new Map();
  }

  public bind(
    keysPattern: string | string[],
    keyDownCallback: (event?: KeyEvent) => void,
    keyUpCallback?: (event?: KeyEvent) => void
  ) {
    keyboardjs.on(keysPattern, keyDownCallback, keyUpCallback);
    const key = Array.isArray(keysPattern) ? keysPattern.join(',') : keysPattern;
    this.listeners.set(key, [keyDownCallback, keyUpCallback]);
  }

  public unbindAll() {
    Array.from(this.listeners.keys()).forEach(key => {
      // @ts-ignore
      const [onPress, onRelease] = this.listeners.get(key);
      keyboardjs.unbind(key, onPress, onRelease);
    });
  }
}
