import React, { PureComponent } from 'react';
import { Checkbox, Modal } from '@just-ai/just-ui';
import localize from '../../../../localization';

type PureComponentProps = {
  isOpenModal: boolean;
  onActionClick: () => void;
  hideModalToggle: () => void;

  modalType?: string;
  showHelp?: boolean;
};

class TaskInProgressModal extends PureComponent<PureComponentProps> {
  render() {
    const { isOpenModal, modalType, onActionClick, hideModalToggle, showHelp } = this.props;
    return (
      <Modal
        isOpen={isOpenModal}
        title={localize.translate(`tasks modal title ${modalType}`)}
        buttonSubmitText={localize.translate('tasks modal submit button')}
        onActionClick={onActionClick}
        buttonSubmitTestId='TaskList.Modal.ButtonSubmit'
      >
        <div>
          {localize.checkExistKey(`tasks modal help text ${modalType}`) && (
            <p>{localize.translate(`tasks modal help text ${modalType}`)}</p>
          )}
          {showHelp && localize.checkExistKey(`tasks modal help text link ${modalType}`) && (
            <p>
              <a href={localize.translate(`tasks modal help link ${modalType}`)} target='_blank' rel='noreferrer'>
                {localize.translate(`tasks modal help text link ${modalType}`)}
              </a>
            </p>
          )}
          <p>
            <Checkbox
              inline
              label={localize.translate('tasks modal dont show')}
              name='tasks-list_modal'
              onChange={hideModalToggle}
            />
          </p>
        </div>
      </Modal>
    );
  }
}

export default TaskInProgressModal;
