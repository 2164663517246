import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal, InputText } from '@just-ai/just-ui';

import { t } from 'localization';

import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { isTextHasSlash } from 'modules/JGraph/utils/stageUtils';
import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { getParentPaths } from 'reducers/JGraph.reducer/utils';

interface RenameStateModalInterface {
  isOpen: boolean;
  screen: JStateWithId;
  onClose: () => void;
  onRename: (name: string) => void;
}
const RenameStateModal = function ({ isOpen, onClose, screen, onRename }: RenameStateModalInterface) {
  const [name, setName] = useState(screen.value);

  const currentNameSupportRename = useMemo(() => !isTextHasSlash(screen.value), [screen.value]);

  const { errorText, isInvalid, triggerValidation, reset } = useNewStateNameValidation();

  const onSubmit = useCallback(() => onRename(name.trim()), [name, onRename]);

  const onChange = useCallback(
    (name: string) => {
      setName(name);
      triggerValidation(name, getParentPaths(screen.path, screen.theme).parentStatePath || screen.theme, screen.value);
    },
    [screen.path, screen.theme, screen.value, triggerValidation]
  );

  useEffect(() => {
    if (!isOpen) {
      reset();
      setName(screen.value);
    }
  }, [isOpen, reset, screen.value]);

  return (
    <Modal
      isOpen={isOpen}
      title={t(`RenameState:Modal:Title`)}
      buttonSubmitColor='primary'
      buttonSubmitText={t(`RenameState:Modal:SubmitTitle`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Cancel')}
      buttonSubmitDisabled={isInvalid || !currentNameSupportRename || screen.value === name.trim()}
      onCancelClick={onClose}
      onActionClick={onSubmit}
      disableActionButtonAutoFocus
    >
      <div>
        <InputText
          id='stateName'
          name='stateName'
          defaultValue={screen.value}
          placeholder={t('RenameState:Modal:Name:Placeholder')}
          value={name}
          hint={t('CreationScreenMenu:title:hint')}
          errorText={errorText}
          disabled={!currentNameSupportRename}
          onChange={onChange}
        />
      </div>
      {!currentNameSupportRename && (
        <Banner type={BannerType.DANGER} content={t('RenameState:Modal:InvalidName:Hint')} className='margin-top-4x' />
      )}
    </Modal>
  );
};

export default React.memo(RenameStateModal);
