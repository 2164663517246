import React, { useCallback, useState, FC, useMemo } from 'react';
import { Dropdown, DropdownToggle, useToggle, DropdownMenu, IconButton, Icon } from '@just-ai/just-ui';
import cn from 'classnames';

import { t } from 'localization';

import { composeSubComponents } from 'utils/helpFunctions';

import { SearchGroupType } from 'modules/JGraph/services/JGSearch';

import styles from './styles.module.scss';

const getFiltersOptions = (): Record<SearchGroupType | string, string> => ({
  ALL: t('JGGlobalSearch:Filter:ALL'),
  [SearchGroupType.REACTION]: t('JGGlobalSearch:Filter:REACTION'),
  [SearchGroupType.PHRASES_AND_EVENTS]: t('JGGlobalSearch:Filter:PHRASES_AND_EVENTS'),
  [SearchGroupType.ACTIVATION]: t('JGGlobalSearch:Filter:ACTIVATION'),
  [SearchGroupType.THEME]: t('JGGlobalSearch:Filter:THEME'),
  [SearchGroupType.STATE]: t('JGGlobalSearch:Filter:STATE'),
  [SearchGroupType.STICKER]: t('JGGlobalSearch:Filter:STICKER'),
  [SearchGroupType.LABEL]: t('JGGlobalSearch:Filter:LABEL'),
});

type SearchFilterContext = { filters: Set<string>; onToggle: (key: string) => void };
const SearchFilterContext = React.createContext<SearchFilterContext>({ filters: new Set(), onToggle: () => {} });

type Props = {
  onChange: (filters: string[]) => void;
  filters: string[];
};
const Provider: FC<Props> = ({ children, onChange, filters }) => {
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    () => new Set(filters.length > 0 ? filters : ['ALL'])
  );

  const onToggle = useCallback(
    (key: string) => {
      setSelectedFilters(prev => {
        if (key === 'ALL') {
          onChange([]);
          return new Set(['ALL']);
        }
        const newSet = new Set(prev);
        newSet.delete('ALL');
        newSet.has(key) ? newSet.delete(key) : newSet.add(key);
        onChange(Array.from(newSet));
        if (newSet.size === 0) newSet.add('ALL');
        return newSet;
      });
    },
    [onChange]
  );

  return (
    <SearchFilterContext.Provider value={{ filters: selectedFilters, onToggle }}>
      {children}
    </SearchFilterContext.Provider>
  );
};

const ChipsView = React.memo(() => {
  const filterContext = React.useContext(SearchFilterContext);
  const chips = useMemo(() => Array.from(filterContext.filters).filter(el => el !== 'ALL'), [filterContext]);

  if (chips.length === 0) return null;
  return (
    <div className={styles.FilterChips}>
      {chips.map(filter => (
        <div className={styles.FilterChip} key={filter}>
          {getFiltersOptions()[filter]}
          <Icon className={styles.closeIcon} name='farTimes' onClick={() => filterContext.onToggle(filter)} />
        </div>
      ))}
    </div>
  );
});

const SearchFilter = () => {
  const [filterOpened, , , toggleFilter] = useToggle(false);
  const filterContext = React.useContext(SearchFilterContext);

  const onSelect = useCallback(
    (event: React.MouseEvent, key: string) => {
      event.stopPropagation();
      event.preventDefault();
      filterContext.onToggle(key);
    },
    [filterContext]
  );

  return (
    <div className={styles.SearchFilter}>
      <Dropdown direction='down' isOpen={filterOpened} toggle={toggleFilter} data-test-id='JGraph.GlobalSearch.Filter'>
        <DropdownToggle tag='div' outline compact disabled>
          <IconButton
            name='farFilter'
            flat
            color='secondary'
            className={cn(styles.filterButton, { [styles.active]: filterOpened })}
            onClick={toggleFilter}
          />
        </DropdownToggle>
        <DropdownMenu positionFixed data-test-id='dropdown-menu' left>
          {Object.entries(getFiltersOptions()).map(([key, value]) => (
            <div tabIndex={0} className={styles.option} key={key} onClick={ev => onSelect(ev, key)}>
              <span>{value}</span>
              {filterContext.filters.has(key) && <Icon name='farCheck' color='secondary' />}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

SearchFilter.displayName = 'SearchFilter';

export default composeSubComponents(SearchFilter, {
  Provider,
  ChipsView,
});
