import React, { useEffect, useRef } from 'react';
import { scan, Subject } from 'rxjs';
import { Layer } from 'react-konva';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';

import { StickerInfo } from './Sticker/types';
import { StickerActions } from './Sticker/hooks';
import Sticker from './Sticker';

type StickerPosition = { id: string; position: Vector2d };

export const OnStateMoved$ = new Subject();
export const StickerMoveReceiver$ = new Subject<{
  type: 'add';
  payload: StickerPosition;
} | null>();
const StickerMovePipe$ = StickerMoveReceiver$.pipe(
  scan((storedValue, currentValue) => {
    if (!currentValue) return {};
    storedValue[currentValue.payload.id] = currentValue.payload.position;
    return storedValue;
  }, {} as Record<string, Vector2d>)
);

type StickerLayerProps = {
  stickers: StickerInfo[];
  stickerActions: StickerActions;
};
export const StickerLayer = React.memo(
  React.forwardRef<Konva.Layer, StickerLayerProps>((props, ref) => {
    const moveStickers = props.stickerActions.moveStickers;

    const stickersOffsets = useRef<StickerPosition[]>([]);
    useEffect(() => {
      const sub = StickerMovePipe$.subscribe(payload => {
        const offsets: { id: string; position: Vector2d }[] = [];
        for (let key in payload) {
          offsets.push({ id: key, position: payload[key] });
        }
        stickersOffsets.current = offsets;
      });
      const sub2 = OnStateMoved$.subscribe(() => {
        moveStickers(stickersOffsets.current.map(el => ({ stickerId: el.id, position: el.position })));
        StickerMoveReceiver$.next(null);
      });

      return () => {
        sub.unsubscribe();
        sub2.unsubscribe();
      };
    }, [moveStickers]);

    return (
      <Layer ref={ref} isLayer={true} listening={true}>
        {props.stickers.map(sticker => (
          <Sticker key={sticker.id} stickerActions={props.stickerActions} info={sticker} />
        ))}
      </Layer>
    );
  })
);
