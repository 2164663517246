export default function insetJsxInRawTest(
  text: string,
  list: { start: number; end: number; jsx: JSX.Element }[]
): JSX.Element[] {
  let offset = 0;
  let processedText = text;
  return list
    .reduce((acc, element) => {
      const prevText = processedText.substring(0, element.start - offset);
      if (prevText.trim()) {
        acc.push(prevText);
      }
      acc.push(element.jsx);
      processedText = processedText.substring(element.end - offset);
      offset = element.end;
      return acc;
    }, [] as any[])
    .concat([processedText]);
}
