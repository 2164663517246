import { NamedEntityData, SystemNamedEntityData } from '../api/client';
import { Dataset, TreeNode } from '@just-ai/just-ui';
import { getRandomString, normalizeUrlPart } from '.';

const ID_PREFIX = 'entity_';
const isPrefixed = (id: string) => id.startsWith(ID_PREFIX);
export const prefixed = (id: string | number) =>
  typeof id === 'number' || !isPrefixed(id) ? `${ID_PREFIX}${id}` : `${id}`;
export const clearPrefix = (id: string) => (isPrefixed(id) ? id.slice(ID_PREFIX.length) : id);

export type VersionedSystemNamedEntityData = SystemNamedEntityData & { versions?: string[] };

export const mapEntitiesToTree = (entities: NamedEntityData[]): Dataset =>
  entities.reduce((dataset, entity) => addNode(dataset, entityToTreeNode(entity)), {} as Dataset);

const entityToTreeNode = (entity: NamedEntityData): TreeNode => ({
  ...entity,
  nodeId: prefixed(getId(entity)),
  id: getId(entity),
});

export const updateEntityItemsCountInTree = (dataset: Dataset, id: string, newItemsCount: number) => {
  const newDataset = { ...dataset };
  const node = newDataset[id];
  addNode(newDataset, { ...node, itemsCount: newItemsCount });
  return newDataset;
};

export const mapSystemEntitiesToTree = (entities: SystemNamedEntityData[]): Dataset =>
  entities.reduce((dataset, entity) => {
    let existingSystemEntity;
    let versionedEntity = { ...entity } as VersionedSystemNamedEntityData;
    if (entity.externalName) {
      existingSystemEntity = dataset[entity.externalName.replace('.', '_')] as VersionedSystemNamedEntityData;
    }
    if (existingSystemEntity && existingSystemEntity.engine?.version && !existingSystemEntity.versions) {
      existingSystemEntity.versions = [existingSystemEntity.engine?.version];
    }

    if (entity.engine?.version) {
      versionedEntity.versions = [entity.engine.version];
    }

    if (
      existingSystemEntity &&
      existingSystemEntity.versions &&
      existingSystemEntity.engine?.version &&
      entity.engine?.version
    ) {
      existingSystemEntity.engine = entity.engine;
      existingSystemEntity.versions.push(entity.engine.version);
    }

    return addNode(dataset, systemEntityToTreeNode(existingSystemEntity || versionedEntity || entity));
  }, {} as Dataset);

const systemEntityToTreeNode = (entity: SystemNamedEntityData | VersionedSystemNamedEntityData): TreeNode => ({
  ...entity,
  name: entity.externalName || '',
  id: getSystemEntityId(entity),
  nodeId: getSystemEntityId(entity),
});

export const updateNameInTree = (dataset: Dataset, id: number, name: string) =>
  addNode({ ...dataset }, { ...dataset[prefixed(id)], name });

export const updateClientStateInTree = (dataset: Dataset, id: number, client: boolean) =>
  addNode({ ...dataset }, { ...dataset[prefixed(id)], client });
export const updateEnableStateInTree = (dataset: Dataset, id: number, enabled: boolean) =>
  addNode({ ...dataset }, { ...dataset[prefixed(id)], enabled });

export const updateEntityVersionInTree = (
  dataset: Dataset,
  id: string,
  version: string,
  newLocales?: SystemNamedEntityData['locales']
) =>
  addNode(
    { ...dataset },
    { ...dataset[id], engine: { ...dataset[id]?.engine, version }, locales: newLocales || dataset[id].locales }
  );

export const removeEntitiesFromTree = (removedIds: string[], dataset: Dataset) => {
  return Object.values(dataset)
    .filter(({ id }) => !removedIds.includes(id))
    .reduce(addNode, {});
};

export const mergeNewEntityIntoTree = (entity: NamedEntityData, dataset: Dataset) =>
  addNode({ ...dataset }, entityToTreeNode(entity));

const getId = (entity: NamedEntityData) => (entity.id ? String(entity.id) : `node-without-id_${getRandomString()}`);
const getSystemEntityId = (entity: SystemNamedEntityData) =>
  entity.externalName ? normalizeUrlPart(entity.externalName) : `node-without-id_${getRandomString()}`;

const addNode = (dataset: Dataset, node: TreeNode) => Object.assign(dataset, { [node.nodeId]: node });

export const getNewUniqueName = (defaultNewEntityName: string, tree?: Dataset): string => {
  const simpleName = defaultNewEntityName;
  if (!tree) {
    return `${simpleName}_${(Math.random() * 10000).toFixed()}`;
  }
  if (!isEntityWithNameExists(simpleName, tree)) return simpleName;

  for (let index = 1; index <= Infinity; index++) {
    const path = `${defaultNewEntityName}_${index}`;
    if (!isEntityWithNameExists(path, tree)) return path;
  }

  return `${defaultNewEntityName}_${getRandomString()}`;
};

const isEntityWithNameExists = (name: string, tree: Dataset): boolean =>
  Object.values(tree).findIndex(node => node.name === name) > -1;

export const getSystemEntityLocale = (
  selectedSystemEntity: VersionedSystemNamedEntityData | null,
  userLocaleLanguage: string
) => {
  const defaultLocalePlaceholder = {
    description: '',
    examples: [],
  };
  if (!selectedSystemEntity || !selectedSystemEntity.locales) return defaultLocalePlaceholder;
  if (!selectedSystemEntity.locales.find(locale => locale.language === userLocaleLanguage.toLowerCase()))
    return (
      selectedSystemEntity.locales.find(locale => locale.language === 'en')?.locale ||
      selectedSystemEntity.locales[0].locale ||
      defaultLocalePlaceholder
    );
  return (
    selectedSystemEntity.locales.find(locale => locale.language === userLocaleLanguage.toLowerCase())?.locale ||
    defaultLocalePlaceholder
  );
};
