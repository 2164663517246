import { LocalStorageService } from 'services/Storage/LocalStorageService';

export const EXTERNAL_REFERER_STORAGE_KEY = 'EXTERNAL_REFERER';

const localStorageService = new LocalStorageService();
localStorageService.addPermanentStoredRules(EXTERNAL_REFERER_STORAGE_KEY);

function getSafeUrlOrigin(url: string) {
  try {
    return new URL(url).origin;
  } catch {}
  return '';
}

function isExternalUrl(url: string) {
  const origin = getSafeUrlOrigin(url);
  if (!origin) return false;
  return origin !== document.location.origin;
}

export function resetLastExternalReferer() {
  return localStorageService.remove(EXTERNAL_REFERER_STORAGE_KEY);
}

export function initLastExternalReferer() {
  const params = new URLSearchParams(window.location.search);
  const referrer = document.referrer || params.get('referrer');

  if (!referrer || !isExternalUrl(referrer)) return;

  return localStorageService.set(EXTERNAL_REFERER_STORAGE_KEY, referrer, true);
}

export function getLastExternalReferer() {
  return localStorageService.get<string>(EXTERNAL_REFERER_STORAGE_KEY);
}
