import { FnParametersByIndex } from '../types';

type EmptyObject = {
  [key in string | number]: never;
};

type ResolverResponse<DATA> =
  | {
      values: EmptyObject;
      isValid: false;
      errors: Partial<Record<keyof DATA, { message: string; type: string }>>;
    }
  | {
      values: DATA;
      isValid: true;
      errors: EmptyObject;
    };

export type ReactHookFormResolver<DATA, CONTEXT = undefined> = (
  ...args: undefined extends CONTEXT ? [DATA] : [DATA, CONTEXT]
) => Promise<ResolverResponse<DATA>>;

export function buildCustomValidationResolver<DATA, CONTEXT = undefined>(
  fn: (data: DATA, context: CONTEXT) => Promise<Partial<Record<keyof DATA, string>>>
): ReactHookFormResolver<DATA, CONTEXT> {
  return async function validationResolver(values: DATA, context?: CONTEXT) {
    const errors = await fn(values, context || ({} as CONTEXT));
    if (Object.keys(errors).length === 0) return { values, isValid: true, errors: {} };
    return {
      values: {},
      isValid: false,
      errors: Object.fromEntries(
        Object.entries(errors).map(([key, error]) => {
          return [key, { message: error, type: 'custom' }];
        })
      ) as Partial<Record<keyof DATA, { message: string; type: string }>>,
    };
  };
}

export function composeCustomValidationResolver<
  RESOLVERS extends ReactHookFormResolver<any, any>[],
  DATA extends FnParametersByIndex<RESOLVERS, 0>,
  CONTEXT extends FnParametersByIndex<RESOLVERS, 1>
>(...resolvers: [...RESOLVERS]): ReactHookFormResolver<DATA, CONTEXT> {
  return async function (data: DATA, context?: CONTEXT) {
    let result;
    for (let resolver of resolvers) {
      // @ts-ignore
      result = await resolver(data, context);
      if (!result.isValid) break;
    }
    if (!result)
      return {
        values: data,
        isValid: true,
        errors: {},
      };
    return result;
  };
}
