import { CustomTagData, CustomTagParameterData, CustomTagParameterType } from '../../../api/client';
import { States } from './States';
import { Ace } from 'ace-builds';
import { CompletionType } from './BaseCompleter';

export class CustomTagParamsCompleter {
  name: string = 'customTagsParams';
  private customTagParametersTokens: Ace.Token[][] = [];
  private paramsMap: CustomTagParameterData[] = [];
  private states: States = new States([], '');

  typeToDefault: Record<CustomTagParameterType, string> = {
    [CustomTagParameterType.Bool]: 'false',
    [CustomTagParameterType.StringArray]: '[""]',
    [CustomTagParameterType.Integer]: '0',
    [CustomTagParameterType.State]: '',
    [CustomTagParameterType.Intents]: '',
    [CustomTagParameterType.Parameters]: '{}',
    [CustomTagParameterType.NameValueList]: '[{ name: "", value: ""}]',
    [CustomTagParameterType.Json]: '{}',
    [CustomTagParameterType.Html]: '',
    [CustomTagParameterType.String]: '',
  };

  setCustomTagParameters = (
    customTagParametersTokens: Ace.Token[][],
    customTagsParams: CustomTagData['parameters'] = [],
    states: States
  ) => {
    this.paramsMap = [...customTagsParams];
    this.paramsMap.sort((param1, param2) => {
      if (param1.required && !param2.required) return 1;
      if (param1.required && param2.required) return 0;
      if (!param1.required && param2.required) return -1;
      return 0;
    });
    this.states = states;
    this.customTagParametersTokens = customTagParametersTokens;
  };

  isApplicable = (session: Ace.EditSession, position: Ace.Point) => {
    const currentLine = session.getLine(position.row);
    return currentLine.replace(/\s+/, '').length === 0;
  };

  public getCompletions = (): CompletionType => {
    const alreadyFilledTagParams = this.customTagParametersTokens
      .map(tokens => tokens.find(token => token.type === 'constant.character')?.value)
      .filter(value => value);

    const allowedParams: CustomTagParameterData[] = this.paramsMap.filter(
      param => !alreadyFilledTagParams.includes(param.name)
    );
    return allowedParams.map((param, index) => {
      return {
        value: this.buildSuggestByParam(param),
        name: param.name!,
        score: 10000 + index,
        meta: this.name,
      };
    });
  };

  private buildSuggestByParam(param: CustomTagParameterData) {
    return `${param.name} = ${this.typeToDefault[param.type!]}`;
  }
}
