import { PaymentStatusInfoDto } from '@just-ai/api/dist/generated/Accountsadmin';

export enum ConversationalCloudProduct {
  Jaicp = 'jaicp',
  Aimylogic = 'aimylogic',
  Aimyvoice = 'aimyvoice',
  Cc = 'cc',
  Caila = 'caila',
}

export enum UiMode {
  NoCode_user = 'NoCode_user',
  DevX_user = 'DevX_user',
  Manager_user = 'Manager_user',
}

export interface DomainOptions {
  domain: string;
  redirectAllowed: boolean;
  defaultTheme: string;
  product: ConversationalCloudProduct;
  showInNavigation: boolean;
}

export type CloudDomains = { [key: string]: DomainOptions };

export type BEError = {
  args?: {
    [key: string]: string | number;
  } | null;
  error: string;
  message?: string;
  uuid?: string;
};

export type LimitData = { tariffLimit: number; extraLimit: number; usedLimit: number };

export type TariffOptionsData = {
  currentUniqueUsers: number | null;
  maxUniqueUsers: number | null;
  maxUniqueUsersByPockets: number | null;
  maxSmsByTariff: number | null;
  maxSmsByPockets: number | null;
  currentPhrases: number | null;
  maxPhrases: number | null;
  currentKnowledgeBasePhrases: number | null;
  maxKnowledgeBasePhrases: number | null;
  currentChannel: number | null;
  maxChannel: number | null;
  currentSeconds: number | null;
  maxSeconds: number | null;
  autoAddUniqueClient: Boolean | null;

  telephonyTariffLimits: {
    nluMaxSecondsByTariff: number;
    asrMaxSecondsByTariff: number;
    telephonyMaxSecondsByTariff: number;
    nluMaxSecondsByPockets: number;
    asrMaxSecondsByPockets: number;
    telephonyMaxSecondsByPockets: number;
  };

  curentCailaDirectApiCalls: number | null;

  aimychatTariffLimits: {
    maxOperators: number;
    maxOperatorGroups: number;
  };

  cailaTariffLimits: {
    directApiCallsByTariff: number;
    directApiCallsByPockets: number;
  };

  mlpTokensLimitsDto: {
    mlpTokensPackageLimit: number;
    mlpTokensTariffLimit: number;
    mlpTokensUsedPackageLimit: number;
    mlpTokensUsedTariffLimit: number;
  };
};

export type TariffLimitsData = {
  asrSeconds: LimitData;
  nluSeconds: LimitData;
  justaiSeconds: LimitData;
  uniqueUsers: LimitData;
  sms: LimitData;
};

export type TariffType = {
  countryIsoCode: string;
  tariffUniqueName: string;
  nextTariffUniqueName: string;
  blocked: boolean;
  blockType: string;
  dueDate: string;
  startDate: string;
  paymentSystem: string;
  limitsData: TariffLimitsData;
  manualControl: boolean;
  tariffName: string;
  price: number;
  manualControlData: {
    nextTariff: string;
    nextTariffDate: string;
    type: string;
  };
};

export type RCurrentUser = {
  id: number;
  accountOwner: boolean;
  account: { id: number; partner?: any; projectReportsEnabled: boolean; name: string };
  ccSession: boolean;
  email: string;
  login?: string;
  subscription?: any;
  emailVerified: boolean;
  emailVerificationRequired: boolean;
  internal: boolean;
  logInAccount: { accountId: number } | null;
  fullName: string;
  language: string;
  phoneNumberNational?: {
    countryCode?: number;
    nationalNumber?: string;
  };
  phone: string | null;
  phoneVerified: boolean;
  tariff: TariffType;
  paymentProcessing: PaymentStatusInfoDto;
};
