import { isEmpty, mapValues } from 'lodash';
import LZString from 'lz-string';
import FileContent from '../model/FileContent';
import { FileTabs, isFileTab, EditorCursorPosition, isCursorPosition } from '../model/FileTabs';
import safeJsonParse from 'utils/safeJsonParse';

export type EditorSettings = {
  fontSize?: number;
  showGutter?: boolean;
  keyboardHandler?: 'sublime' | 'vim' | 'emacs';
  tabsBehavior?: 'compact' | 'safari' | 'chrome';
  closeTabPosition?: 'left' | 'right';
  showHiddenFiles?: boolean;
};

export const DEFAULT_SETTINGS: EditorSettings = {
  showGutter: true,
  fontSize: 14,
  keyboardHandler: 'sublime',
  tabsBehavior: 'compact',
  closeTabPosition: 'right',
  showHiddenFiles: false,
};

export type SearchQuery = {
  query: string;
  isRegex?: boolean;
  isCaseSensitive?: boolean;
  isWholeWord?: boolean;
  fileExtensions?: string[];
};

type LocalFileContent = Pick<FileContent, 'lastModified' | 'content'>;

export type OpenFiles = { fileTabs?: FileTabs; currentFile?: string };

export default class LocalStorageService {
  private static EDITOR_SETTINGS_KEY = 'EDITOR_SETTINGS';
  private static OPEN_FILES_KEY = 'EDITOR_OPEN_FILES';
  private static CURSOR_POSITIONS_KEY = 'EDITOR_CURSOR_POSITIONS';
  private static SEARCHES_KEY = 'SEARCHES';
  private static EDITOR_TEMP_CHANGES = 'EDITOR_TEMP_CHANGES';

  private accountId: number;
  private projectId: string;

  constructor(accountId: number, projectId: string) {
    this.accountId = accountId;
    this.projectId = projectId;
  }

  private prefixed(key: string) {
    return `${key}-${this.accountId}-${this.projectId}`;
  }

  private parseLocalFile(file: string): [number, string] {
    const localFile = JSON.parse(LZString.decompressFromUTF16(file) || '') as LocalFileContent;
    return [localFile.lastModified, localFile.content];
  }

  saveSettings(settings: EditorSettings) {
    localStorage.setItem(this.prefixed(LocalStorageService.EDITOR_SETTINGS_KEY), JSON.stringify(settings));
  }

  getSettings() {
    const settingsJson = localStorage.getItem(this.prefixed(LocalStorageService.EDITOR_SETTINGS_KEY));
    if (!settingsJson) return null;

    try {
      const settings = JSON.parse(settingsJson) as EditorSettings;
      return settings;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  saveOpenFiles(fileTabs: FileTabs, currentFile?: string) {
    localStorage.setItem(
      this.prefixed(LocalStorageService.OPEN_FILES_KEY),
      JSON.stringify({
        fileTabs,
        currentFile,
      })
    );
  }

  saveTempChanges = ({ path, lastModified, content }: FileContent) => {
    const localChanges = this.getTempChanges(true) || {};

    localStorage.setItem(
      this.prefixed(LocalStorageService.EDITOR_TEMP_CHANGES),
      JSON.stringify({
        ...localChanges,
        [path]: LZString.compressToUTF16(JSON.stringify({ lastModified, content })) || '',
      })
    );
  };

  getTempChanges = (compressed?: boolean) => {
    const localChanges = localStorage.getItem(this.prefixed(LocalStorageService.EDITOR_TEMP_CHANGES));
    if (!localChanges) return false;

    const files = JSON.parse(localChanges) as { [key: string]: string };
    if (compressed) return files;
    return mapValues(files, (file, path) => new FileContent(path, ...this.parseLocalFile(file)));
  };

  clearTempChanges = (path?: string) => {
    const localChanges = this.getTempChanges(true);
    if (!localChanges) return false;

    if (path) delete localChanges[path];

    if (isEmpty(localChanges) || !path) {
      localStorage.removeItem(this.prefixed(LocalStorageService.EDITOR_TEMP_CHANGES));
    } else {
      localStorage.setItem(this.prefixed(LocalStorageService.EDITOR_TEMP_CHANGES), JSON.stringify({ ...localChanges }));
    }
  };

  deleteFile = (mutationFunc: (fileTabs: FileTabs) => FileTabs) => {
    // LocalStorage operations is too slow, async solve this problem
    return new Promise<void>(resolve => {
      const openFilesText = localStorage.getItem(this.prefixed(LocalStorageService.OPEN_FILES_KEY));
      if (!openFilesText) return resolve();
      const openFiles = safeJsonParse<{ fileTabs: FileTabs }>(openFilesText);
      if (!openFiles) return resolve();
      openFiles.fileTabs = mutationFunc(openFiles.fileTabs);
      localStorage.setItem(this.prefixed(LocalStorageService.OPEN_FILES_KEY), JSON.stringify(openFiles));
      return resolve();
    });
  };

  getOpenFiles() {
    const openFilesJson = localStorage.getItem(this.prefixed(LocalStorageService.OPEN_FILES_KEY));
    if (!openFilesJson) return null;

    try {
      const openFiles = JSON.parse(openFilesJson);
      const result: OpenFiles = {};

      if (typeof openFiles.fileTabs === 'object' && Object.values(openFiles.fileTabs).every(isFileTab)) {
        result.fileTabs = openFiles.fileTabs;
      }

      if (typeof openFiles.currentFile === 'string') {
        result.currentFile = openFiles.currentFile;
      }

      return result;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  saveCursorPositions(cursorPositions: { [key: string]: EditorCursorPosition }) {
    localStorage.setItem(this.prefixed(LocalStorageService.CURSOR_POSITIONS_KEY), JSON.stringify(cursorPositions));
  }

  getCursorPositions() {
    const cursorPositionsJson = localStorage.getItem(this.prefixed(LocalStorageService.CURSOR_POSITIONS_KEY));
    if (!cursorPositionsJson) return null;

    try {
      const cursorPositions = JSON.parse(cursorPositionsJson);

      if (typeof cursorPositions === 'object' && Object.values(cursorPositions).every(isCursorPosition)) {
        return cursorPositions as { [key: string]: EditorCursorPosition };
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  saveSearches(searches: SearchQuery[]) {
    localStorage.setItem(this.prefixed(LocalStorageService.SEARCHES_KEY), JSON.stringify(searches));
  }

  getSearches() {
    const searchesJson = localStorage.getItem(this.prefixed(LocalStorageService.SEARCHES_KEY));
    if (!searchesJson) return [];

    try {
      let searches = JSON.parse(searchesJson);
      searches =
        (Array.isArray(searches) &&
          searches.filter(search => typeof search === 'object' && typeof search.query === 'string')) ||
        [];

      return searches as SearchQuery[];
    } catch (error) {
      console.error(error);
    }
    return [];
  }
}
