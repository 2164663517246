import React, { FC } from 'react';

import { withController } from './Controller';
import { WysiwygEditor } from 'modules/JGraph/components/Wysiwyg';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';
import CustomTagWarningBlock from './CustomTagWarningBlock';

import { t } from 'localization';

const HtmlView: FC<CommonFieldProps> = ({
  name,
  label,
  value,
  onChange,
  isRequired,
  invalid,
  description,
  isValidFormat,
  type,
  isParameterMaybeVariable,
  isShouldShowFormatWarning,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className='form-group form-group-fix overflow-hidden'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <WysiwygEditor ref={ref} defaultText={value} onChange={onChange} invalid={invalid} />
      {invalid && (
        <div className='invalid-feedback' style={{ display: 'block' }}>
          {t('Required Field')}
        </div>
      )}
      {isShouldShowFormatWarning && (
        <CustomTagWarningBlock isValidFormat={isValidFormat} type={type} isMaybeVariable={isParameterMaybeVariable} />
      )}
    </div>
  );
};

export const HtmlField = withController(HtmlView);
