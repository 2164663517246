import { ERRORS_PANEL_TYPE } from 'modules/Editor/components/ParseErrorsPanel';
import { LOGS_PANEL_TYPE } from 'modules/Logs/components/LogPanel';
import React, { FunctionComponent, createContext, useContext, useState, useCallback } from 'react';

export type BottomPanelType = typeof LOGS_PANEL_TYPE | typeof ERRORS_PANEL_TYPE | undefined;

export type GlobalBottomPanelContextType = {
  shownPanel?: BottomPanelType;
  showPanel(panelType: BottomPanelType): void;
  hidePanel(panelType: BottomPanelType): void;
  togglePanel(panelType: BottomPanelType): void;
};

export const GlobalBottomPanelContext = createContext({} as GlobalBottomPanelContextType);
export const useGlobalBottomPanelContext = () => useContext(GlobalBottomPanelContext);

export const GlobalRightPanelContextProvider: FunctionComponent = React.memo(({ children }) => {
  const [shownPanel, setShownPanel] = useState<BottomPanelType>();

  const showPanel = useCallback(
    (panelType: BottomPanelType) => {
      if (shownPanel === panelType) return;
      setShownPanel(panelType);
    },
    [shownPanel]
  );

  const hidePanel = useCallback(
    (panelType: BottomPanelType) => {
      if (shownPanel !== panelType) return;
      setShownPanel(undefined);
    },
    [shownPanel]
  );

  const togglePanel = useCallback(
    (panelType: BottomPanelType) => {
      if (shownPanel === panelType) setShownPanel(undefined);
      else setShownPanel(panelType);
    },
    [shownPanel]
  );

  return (
    <GlobalBottomPanelContext.Provider
      value={{
        shownPanel,
        showPanel,
        hidePanel,
        togglePanel,
      }}
    >
      {children}
    </GlobalBottomPanelContext.Provider>
  );
});
GlobalRightPanelContextProvider.displayName = 'Memo(GlobalRightPanelContextProvider)';
