import { GitApi } from '../api/client';

export default class GitIntegrationsService {
  static readonly BASE_PATH = '';
  accountId: number;
  gitApi: GitApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.gitApi = new GitApi({}, GitIntegrationsService.BASE_PATH);
  }

  getUserCredentialsByIntegrationId(integrationId: string) {
    return this.gitApi.getUserCredentialsByIntegrationId(this.accountId, integrationId);
  }

  async getBranches(integrationId: string, repositoryUri: string) {
    return this.gitApi.getBranches(this.accountId, integrationId, repositoryUri);
  }

  async getRepositories(integrationId: string, workspaceId?: string, projectId?: string) {
    return this.gitApi.getRepositories(this.accountId, integrationId, workspaceId, projectId);
  }

  async getGitProjects(integrationId: string, workspaceId: string) {
    return this.gitApi.getBitbucketProjects(this.accountId, integrationId, workspaceId);
  }

  async getWorkspaces(integrationId: string) {
    return this.gitApi.getBitbucketWorkspaces(this.accountId, integrationId);
  }

  createRepository({
    integrationId,
    repositoryName,
    workspaceId,
    projectId,
    isPrivate,
  }: {
    integrationId: string;
    repositoryName: string;
    workspaceId?: string;
    projectId?: string;
    isPrivate?: boolean;
  }) {
    return this.gitApi.createRepository(
      this.accountId,
      integrationId,
      repositoryName,
      workspaceId,
      projectId,
      isPrivate
    );
  }
}
