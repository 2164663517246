const prefix = '@channel/';

export const FETCH_CHANNELS = 'FETCH_CHANNELS';
export const FETCH_CHANNELS_PENDING = 'FETCH_CHANNELS_PENDING';
export const FETCH_CHANNELS_FULFILLED = 'FETCH_CHANNELS_FULFILLED';
export const FETCH_CHANNELS_REJECTED = 'FETCH_CHANNELS_PENDING';

export const SET_EDITABLE_CHANNEL = 'SET_EDITABLE_CHANNEL';
export const CLEAR_EDITABLE_CHANNEL = 'CLEAR_EDITABLE_CHANNEL';

export const UPDATE_EDITABLE_CHANNEL = 'UPDATE_EDITABLE_CHANNEL';

export const SAVE_EDITABLE_CHANNEL = 'SAVE_EDITABLE_CHANNEL';
export const SAVE_EDITABLE_CHANNEL_PENDING = 'SAVE_EDITABLE_CHANNEL_PENDING';
export const SAVE_EDITABLE_CHANNEL_FULFILLED = 'SAVE_EDITABLE_CHANNEL_FULFILLED';
export const SAVE_EDITABLE_CHANNEL_REJECTED = 'SAVE_EDITABLE_CHANNEL_REJECTED';

export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const CREATE_CHANNEL_PENDING = 'CREATE_CHANNEL_PENDING';
export const CREATE_CHANNEL_FULFILLED = 'CREATE_CHANNEL_FULFILLED';
export const CREATE_CHANNEL_REJECTED = 'CREATE_CHANNEL_REJECTED';

export const CLEAR_VALIDATE_TOKEN_ERROR = 'CLEAR_VALIDATE_TOKEN_ERROR';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VALIDATE_TOKEN_PENDING = 'VALIDATE_TOKEN_PENDING';
export const VALIDATE_TOKEN_FULFILLED = 'VALIDATE_TOKEN_FULFILLED';
export const VALIDATE_TOKEN_REJECTED = 'VALIDATE_TOKEN_REJECTED';

export const VALIDATE_CUSTOM_DATA = 'VALIDATE_CUSTOM_DATA';
export const VALIDATE_CUSTOM_DATA_PENDING = 'VALIDATE_CUSTOM_DATA_PENDING';
export const VALIDATE_CUSTOM_DATA_FULFILLED = 'VALIDATE_CUSTOM_DATA_FULFILLED';
export const VALIDATE_CUSTOM_DATA_REJECTED = 'VALIDATE_CUSTOM_DATA_REJECTED';

export const REMOVE_EDITABLE_CHANNEL = 'REMOVE_EDITABLE_CHANNEL';
export const REMOVE_EDITABLE_CHANNEL_PENDING = 'REMOVE_EDITABLE_CHANNEL_PENDING';
export const REMOVE_EDITABLE_CHANNEL_FULFILLED = 'REMOVE_EDITABLE_CHANNEL_FULFILLED';
export const REMOVE_EDITABLE_CHANNEL_REJECTED = 'REMOVE_EDITABLE_CHANNEL_REJECTED';

export const PUBLISH_CHANNEL = 'PUBLISH_CHANNEL';
export const PUBLISH_CHANNEL_PENDING = 'PUBLISH_CHANNEL_PENDING';
export const PUBLISH_CHANNEL_FULFILLED = 'PUBLISH_CHANNEL_FULFILLED';
export const PUBLISH_CHANNEL_REJECTED = 'PUBLISH_CHANNEL_REJECTED';

export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const FETCH_REFS = 'FETCH_REFS';
export const FETCH_BRANCHES_PENDING = 'FETCH_BRANCHES_PENDING';
export const FETCH_REFS_PENDING = 'FETCH_REFS_PENDING';
export const SET_PUBLISH_CHANNEL_ID = 'SET_PUBLISH_CHANNEL_ID';
export const FETCH_BRANCHES_FULFILLED = 'FETCH_BRANCHES_FULFILLED';
export const FETCH_REFS_FULFILLED = 'FETCH_REFS_FULFILLED';
export const FETCH_BRANCHES_REJECTED = 'FETCH_BRANCHES_REJECTED';
export const FETCH_REFS_REJECTED = 'FETCH_REFS_REJECTED';

export const SHOW_CHANNEL_VALIDATION_ERRORS = 'SHOW_CHANNEL_VALIDATION_ERRORS';
export const HIDE_CHANNEL_VALIDATION_ERRORS = 'HIDE_CHANNEL_VALIDATION_ERRORS';

export const FETCH_THEMES = 'FETCH_THEMES';
export const FETCH_THEMES_PENDING = 'FETCH_THEMES_PENDING';
export const FETCH_THEMES_FULFILLED = 'FETCH_THEMES_FULFILLED';
export const FETCH_THEMES_REJECTED = 'FETCH_THEMES_REJECTED';

export const FETCH_WEBIM2_OPERATORS = 'FETCH_WEBIM2_OPERATORS';
export const FETCH_WEBIM2_OPERATORS_PENDING = 'FETCH_WEBIM2_OPERATORS_PENDING';
export const FETCH_WEBIM2_OPERATORS_FULFILLED = 'FETCH_WEBIM2_OPERATORS_FULFILLED';
export const FETCH_WEBIM2_OPERATORS_REJECTED = 'FETCH_WEBIM2_OPERATORS_REJECTED';

export const SET_CLIENT_VALIDATION_STATUS = 'SET_CLIENT_VALIDATION_STATUS';

export const SAVE_AVATAR_TO_SERVER = 'SAVE_AVATAR_TO_SERVER';
export const SAVE_AVATAR_TO_SERVER_PENDING = 'SAVE_AVATAR_TO_SERVER_PENDING';
export const SAVE_AVATAR_TO_SERVER_FULFILLED = 'SAVE_AVATAR_TO_SERVER_FULFILLED';
export const SAVE_AVATAR_TO_SERVER_REJECTED = 'SAVE_AVATAR_TO_SERVER_REJECTED';

export const GET_CHANNEL = prefix + 'GET_CHANNEL';
export const GET_CHANNEL_PENDING = prefix + 'GET_CHANNEL_PENDING';
export const GET_CHANNEL_FULFILLED = prefix + 'GET_CHANNEL_FULFILLED';
export const GET_CHANNEL_REJECTED = prefix + 'GET_CHANNEL_REJECTED';

export const SET_CHANNEL = prefix + 'SET_CHANNEL';

export const GET_AVAILABLE_TRUNKS = prefix + 'GET_AVAILABLE_TRUNKS';
export const GET_AVAILABLE_TRUNKS_PENDING = prefix + 'GET_AVAILABLE_TRUNKS_PENDING';
export const GET_AVAILABLE_TRUNKS_FULFILLED = prefix + 'GET_AVAILABLE_TRUNKS_FULFILLED';
export const GET_AVAILABLE_TRUNKS_REJECTED = prefix + 'GET_AVAILABLE_TRUNKS_REJECTED';

export const GET_SPEECH_PROVIDERS = prefix + 'GET_SPEECH_PROVIDERS';
export const GET_SPEECH_PROVIDERS_PENDING = prefix + 'GET_SPEECH_PROVIDERS_PENDING';
export const GET_SPEECH_PROVIDERS_FULFILLED = prefix + 'GET_SPEECH_PROVIDERS_FULFILLED';
export const GET_SPEECH_PROVIDERS_REJECTED = prefix + 'GET_SPEECH_PROVIDERS_REJECTED';

export const ADD_SPEECH_PROVIDER = prefix + 'ADD_SPEECH_PROVIDER';
export const ADD_SPEECH_PROVIDER_PENDING = prefix + 'ADD_SPEECH_PROVIDER_PENDING';
export const ADD_SPEECH_PROVIDER_FULFILLED = prefix + 'ADD_SPEECH_PROVIDER_FULFILLED';
export const ADD_SPEECH_PROVIDER_REJECTED = prefix + 'ADD_SPEECH_PROVIDER_REJECTED';

export const UPDATE_SPEECH_PROVIDER = prefix + 'UPDATE_SPEECH_PROVIDER';
export const UPDATE_SPEECH_PROVIDER_PENDING = prefix + 'UPDATE_SPEECH_PROVIDER_PENDING';
export const UPDATE_SPEECH_PROVIDER_FULFILLED = prefix + 'UPDATE_SPEECH_PROVIDER_FULFILLED';
export const UPDATE_SPEECH_PROVIDER_REJECTED = prefix + 'UPDATE_SPEECH_PROVIDER_REJECTED';

export const DELETE_SPEECH_PROVIDER = prefix + 'DELETE_SPEECH_PROVIDER';
export const DELETE_SPEECH_PROVIDER_PENDING = prefix + 'DELETE_SPEECH_PROVIDER_PENDING';
export const DELETE_SPEECH_PROVIDER_FULFILLED = prefix + 'DELETE_SPEECH_PROVIDER_FULFILLED';
export const DELETE_SPEECH_PROVIDER_REJECTED = prefix + 'DELETE_SPEECH_PROVIDER_REJECTED';

export const GET_AIMYVOICE_VOICES = prefix + 'GET_AIMYVOICE_VOICES';
export const GET_AIMYVOICE_VOICES_FULLFILLED = prefix + 'GET_AIMYVOICE_VOICES_FULLFILLED';

export const GET_AZURE_RECOMMENDED_SETTINGS = prefix + 'GET_AZURE_RECOMMENDED_SETTINGS';

export const GET_ASM_MODELS = prefix + 'GET_ASM_MODALS';
export const GET_INTERNAL_ASM_MODELS = prefix + 'GET_INTERNAL_ASM_MODALS';
