export default function insetHTMLInRawText(text, matches) {
    let offset = 0;
    let processedText = text;
    return matches
        .reduce((acc, element) => {
        const prevText = processedText.substring(0, element.start - offset);
        if (prevText) {
            acc.push(prevText);
        }
        const text = processedText.substring(element.start - offset, element.end - offset);
        acc.push(element.renderHtml(text));
        processedText = processedText.substring(element.end - offset);
        offset = element.end;
        return acc;
    }, [])
        .concat([processedText])
        .join('');
}
