import React, { FC } from 'react';
import { BaseItemBody } from './BaseItemBody';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { IconNames } from './KIcons';
import { Activation } from './Activation';
import { Group } from 'react-konva';
import { TActivationTagNames, TagNames, TJBlock } from 'modules/JGraph/utils/types';

interface TActivationsTypeProps {
  jstateBlocks: { blockIndex: number; block: TJBlock }[];
  screen: JStateWithId;
  active?: boolean;
}

export const mapTypeToIcon: Record<TActivationTagNames | 'systemIntent', IconNames> = {
  [TagNames.q_]: IconNames.pattern,
  [TagNames.q]: IconNames.pattern,
  [TagNames.e]: IconNames.example,
  [TagNames.e_]: IconNames.example,
  [TagNames.eg]: IconNames.exampleGroup,
  [TagNames.eg_]: IconNames.exampleGroup,
  [TagNames.intent]: IconNames.intent,
  [TagNames.intent_]: IconNames.intent,
  [TagNames.intentGroup]: IconNames.intentGroup,
  [TagNames.intentGroup_]: IconNames.intentGroup,
  [TagNames.event]: IconNames.event,
  [TagNames.event_]: IconNames.event,
  systemIntent: IconNames.systemIntent,
};

export const Activations: FC<TActivationsTypeProps> = ({ jstateBlocks = [], screen, active }) => {
  return (
    <BaseItemBody
      bottomPadding={0}
      active={active}
      name='Activations'
      renderChild={() => (
        <Group x={-12}>
          {jstateBlocks.map((activation, index) => (
            <Activation
              activation={activation.block}
              key={`${activation.block.tagName}_${activation.block.tagValue}_${index}`}
              tagName={activation.block.tagName}
              icon={mapTypeToIcon[(activation.block.tagName as TActivationTagNames) || 'q']}
              value={activation.block.tagValue || ''}
              index={activation.blockIndex}
              screen={screen}
              topCornerRadius={index === 0}
              bottomCornerRadius={index === jstateBlocks?.length - 1}
            />
          ))}
        </Group>
      )}
    />
  );
};
