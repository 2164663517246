// tslint:disable
// @ts-nocheck
/**
 * Editor BE REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface BatchUpdateContentData
 */
export interface BatchUpdateContentData {
  /**
   *
   * @type {boolean}
   * @memberof BatchUpdateContentData
   */
  force?: boolean;
  /**
   *
   * @type {string}
   * @memberof BatchUpdateContentData
   */
  comment?: string;
  /**
   *
   * @type {ConflictResolutionType}
   * @memberof BatchUpdateContentData
   */
  conflictResolution?: ConflictResolutionType;
  /**
   *
   * @type {Array<BatchUpdateFileData>}
   * @memberof BatchUpdateContentData
   */
  files: Array<BatchUpdateFileData>;
}
/**
 *
 * @export
 * @interface BatchUpdateFileData
 */
export interface BatchUpdateFileData {
  /**
   *
   * @type {string}
   * @memberof BatchUpdateFileData
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof BatchUpdateFileData
   */
  previousLastModified?: number;
  /**
   *
   * @type {string}
   * @memberof BatchUpdateFileData
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof BatchUpdateFileData
   */
  markedForDelete?: boolean;
}
/**
 *
 * @export
 * @interface BotTemplateData
 */
export interface BotTemplateData {
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  summary?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  thumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotTemplateData
   */
  jaicf?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  json?: string;
  /**
   *
   * @type {string}
   * @memberof BotTemplateData
   */
  redirect?: string;
}
/**
 *
 * @export
 * @interface BotTemplateVariables
 */
export interface BotTemplateVariables {
  /**
   *
   * @type {{ [key: string]: { [key: string]: object; }; }}
   * @memberof BotTemplateVariables
   */
  templateVariables?: { [key: string]: { [key: string]: object } };
}
/**
 *
 * @export
 * @interface CommitAndPushData
 */
export interface CommitAndPushData {
  /**
   *
   * @type {string}
   * @memberof CommitAndPushData
   */
  commitMessage?: string;
  /**
   *
   * @type {ConflictResolutionType}
   * @memberof CommitAndPushData
   */
  conflictResolution?: ConflictResolutionType;
}
/**
 *
 * @export
 * @interface ComplexRenameStateData
 */
export interface ComplexRenameStateData {
  /**
   *
   * @type {string}
   * @memberof ComplexRenameStateData
   */
  statePath: string;
  /**
   *
   * @type {string}
   * @memberof ComplexRenameStateData
   */
  newValue: string;
  /**
   *
   * @type {string}
   * @memberof ComplexRenameStateData
   */
  oldValue: string;
  /**
   *
   * @type {Array<JFile>}
   * @memberof ComplexRenameStateData
   */
  files: Array<JFile>;
}
/**
 * Conflict resolution types:  * `integrate` - Automatically merge  * `ignoreRemote` - Make your version is active one  * `newBranch` - Save changes to another branch of the repository
 * @export
 * @enum {string}
 */
export enum ConflictResolutionType {
  Integrate = 'integrate',
  IgnoreRemote = 'ignoreRemote',
  NewBranch = 'newBranch',
}

/**
 *
 * @export
 * @interface CreateJStateData
 */
export interface CreateJStateData {
  /**
   *
   * @type {StateToCreate}
   * @memberof CreateJStateData
   */
  stateToCreate: StateToCreate;
  /**
   *
   * @type {string}
   * @memberof CreateJStateData
   */
  parentThemePath: string;
  /**
   *
   * @type {string}
   * @memberof CreateJStateData
   */
  parentStatePath?: string;
  /**
   *
   * @type {JFile}
   * @memberof CreateJStateData
   */
  file: JFile;
  /**
   *
   * @type {boolean}
   * @memberof CreateJStateData
   */
  force?: boolean;
}
/**
 *
 * @export
 * @interface CreateProjectData
 */
export interface CreateProjectData {
  /**
   *
   * @type {ProjectData}
   * @memberof CreateProjectData
   */
  project?: ProjectData;
  /**
   * Internal bot template name.
   * @type {string}
   * @memberof CreateProjectData
   */
  template?: string;
  /**
   * External bot template URL. If this property is set, \'template\' property is ignored.
   * @type {string}
   * @memberof CreateProjectData
   */
  externalTemplate?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CreateProjectData
   */
  templateParams?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CreateRootStateRequest
 */
export interface CreateRootStateRequest {
  /**
   *
   * @type {StateDescription}
   * @memberof CreateRootStateRequest
   */
  state: StateDescription;
}
/**
 *
 * @export
 * @interface CustomTagData
 */
export interface CustomTagData {
  /**
   *
   * @type {string}
   * @memberof CustomTagData
   */
  tagName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomTagData
   */
  color?: string;
  /**
   *
   * @type {number}
   * @memberof CustomTagData
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof CustomTagData
   */
  botId?: string;
  /**
   *
   * @type {string}
   * @memberof CustomTagData
   */
  startState?: string;
  /**
   *
   * @type {boolean}
   * @memberof CustomTagData
   */
  sharedForPlatform?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomTagData
   */
  sharedForAccount?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomTagData
   */
  jgraphHidden?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomTagData
   */
  hint?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomTagData
   */
  caption?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomTagData
   */
  description?: { [key: string]: string };
  /**
   *
   * @type {Array<CustomTagParameterData>}
   * @memberof CustomTagData
   */
  parameters?: Array<CustomTagParameterData>;
}
/**
 *
 * @export
 * @interface CustomTagParameterData
 */
export interface CustomTagParameterData {
  /**
   *
   * @type {string}
   * @memberof CustomTagParameterData
   */
  name?: string;
  /**
   *
   * @type {CustomTagParameterType}
   * @memberof CustomTagParameterData
   */
  type?: CustomTagParameterType;
  /**
   *
   * @type {boolean}
   * @memberof CustomTagParameterData
   */
  required?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomTagParameterData
   */
  localization?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CustomTagParameterData
   */
  description?: { [key: string]: string };
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CustomTagParameterType {
  String = 'string',
  StringArray = 'stringArray',
  Integer = 'integer',
  State = 'state',
  Bool = 'bool',
  Intents = 'intents',
  Parameters = 'parameters',
  NameValueList = 'nameValueList',
  Json = 'json',
  Html = 'html',
}

/**
 *
 * @export
 * @interface CustomTagPreferencesData
 */
export interface CustomTagPreferencesData {
  /**
   *
   * @type {string}
   * @memberof CustomTagPreferencesData
   */
  tagName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomTagPreferencesData
   */
  color?: string;
}
/**
 *
 * @export
 * @interface DeleteJStateData
 */
export interface DeleteJStateData {
  /**
   *
   * @type {JFile}
   * @memberof DeleteJStateData
   */
  file: JFile;
  /**
   *
   * @type {string}
   * @memberof DeleteJStateData
   */
  targetStatePath: string;
  /**
   *
   * @type {boolean}
   * @memberof DeleteJStateData
   */
  force?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeleteJStateData
   */
  includeChildren?: boolean;
}
/**
 *
 * @export
 * @interface DeployConfigTestData
 */
export interface DeployConfigTestData {
  /**
   *
   * @type {string}
   * @memberof DeployConfigTestData
   */
  deployArtifactsTempDirName?: string;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  platformMaxDeployCount?: number;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  perAccountMaxDeployCount?: number;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  perInstanceMaxDeployCount?: number;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  alexaDeployTimeout?: number;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  perTaskMaxDeployAttemptCount?: number;
  /**
   *
   * @type {number}
   * @memberof DeployConfigTestData
   */
  deployTimeout?: number;
}
/**
 *
 * @export
 * @interface DeploymentBotConfigData
 */
export interface DeploymentBotConfigData {
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  botId?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  branch?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  refType?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  refValue?: string;
  /**
   *
   * @type {RolloutType}
   * @memberof DeploymentBotConfigData
   */
  rollout?: RolloutType;
  /**
   *
   * @type {boolean}
   * @memberof DeploymentBotConfigData
   */
  snapshot?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeploymentBotConfigData
   */
  alexaChannel?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  alexaInvocationName?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentBotConfigData
   */
  channelType?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeploymentBotConfigData
   */
  outdated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeploymentBotConfigData
   */
  removed?: boolean;
}
/**
 *
 * @export
 * @interface DeploymentBotStatusData
 */
export interface DeploymentBotStatusData {
  /**
   *
   * @type {string}
   * @memberof DeploymentBotStatusData
   */
  botId: string;
  /**
   *
   * @type {boolean}
   * @memberof DeploymentBotStatusData
   */
  outdated?: boolean;
  /**
   *
   * @type {DeploymentBotStatusType}
   * @memberof DeploymentBotStatusData
   */
  status?: DeploymentBotStatusType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DeploymentBotStatusType {
  Ok = 'ok',
  TestFailed = 'testFailed',
  DeployFailed = 'deployFailed',
  InProgress = 'inProgress',
  Pending = 'pending',
  NoDeployments = 'noDeployments',
}

/**
 *
 * @export
 * @interface DeploymentHistoryData
 */
export interface DeploymentHistoryData {
  /**
   *
   * @type {Pagination}
   * @memberof DeploymentHistoryData
   */
  paging: Pagination;
  /**
   *
   * @type {Array<DeploymentTaskData>}
   * @memberof DeploymentHistoryData
   */
  records: Array<DeploymentTaskData>;
}
/**
 *
 * @export
 * @interface DeploymentTaskData
 */
export interface DeploymentTaskData {
  /**
   *
   * @type {number}
   * @memberof DeploymentTaskData
   */
  taskId?: number;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  botId: string;
  /**
   *
   * @type {number}
   * @memberof DeploymentTaskData
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  userLogin?: string;
  /**
   *
   * @type {DeploymentBotStatusType}
   * @memberof DeploymentTaskData
   */
  status?: DeploymentBotStatusType;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  branch?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  refType?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  refValue?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  revisionComment?: string;
  /**
   *
   * @type {string}
   * @memberof DeploymentTaskData
   */
  revisionHash?: string;
  /**
   *
   * @type {Date}
   * @memberof DeploymentTaskData
   */
  startDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof DeploymentTaskData
   */
  finishDate?: Date;
}
/**
 *
 * @export
 * @interface DeploymentTaskIdData
 */
export interface DeploymentTaskIdData {
  /**
   *
   * @type {number}
   * @memberof DeploymentTaskIdData
   */
  taskId: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DictionaryType {
  Extended = 'extended',
  Small = 'small',
  Disabled = 'disabled',
  Custom = 'custom',
}

/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime: number;
}
/**
 *
 * @export
 * @interface EnvVarRequest
 */
export interface EnvVarRequest {
  /**
   *
   * @type {string}
   * @memberof EnvVarRequest
   */
  envVarValue: string;
  /**
   *
   * @type {boolean}
   * @memberof EnvVarRequest
   */
  isExported?: boolean;
}
/**
 *
 * @export
 * @interface EnvVarResponse
 */
export interface EnvVarResponse {
  /**
   *
   * @type {number}
   * @memberof EnvVarResponse
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof EnvVarResponse
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof EnvVarResponse
   */
  envVarName: string;
  /**
   *
   * @type {string}
   * @memberof EnvVarResponse
   */
  envVarValue: string;
  /**
   *
   * @type {Date}
   * @memberof EnvVarResponse
   */
  creationDate: Date;
  /**
   *
   * @type {Date}
   * @memberof EnvVarResponse
   */
  updateDate: Date;
  /**
   *
   * @type {string}
   * @memberof EnvVarResponse
   */
  lastUserId: string;
  /**
   *
   * @type {boolean}
   * @memberof EnvVarResponse
   */
  exported: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EnvVarResponse
   */
  published: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EnvVarResponse
   */
  toDelete: boolean;
}
/**
 * Project environment. Defines where the JAICF project should be hosted.
 * @export
 * @enum {string}
 */
export enum Environment {
  JaicpCloud = 'jaicpCloud',
  External = 'external',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ExternalRepoAuthType {
  PersonalUser = 'personalUser',
  SystemUser = 'systemUser',
}

/**
 *
 * @export
 * @interface ExternalRepoData
 */
export interface ExternalRepoData {
  /**
   *
   * @type {string}
   * @memberof ExternalRepoData
   */
  url?: string;
  /**
   *
   * @type {ExternalRepoAuthType}
   * @memberof ExternalRepoData
   */
  authType?: ExternalRepoAuthType;
  /**
   *
   * @type {string}
   * @memberof ExternalRepoData
   */
  login?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalRepoData
   */
  password?: string;
}
/**
 *
 * @export
 * @interface FileContentData
 */
export interface FileContentData {
  /**
   *
   * @type {string}
   * @memberof FileContentData
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FileContentData
   */
  lastModified?: number;
  /**
   *
   * @type {string}
   * @memberof FileContentData
   */
  content?: string;
}
/**
 *
 * @export
 * @interface FileData
 */
export interface FileData {
  /**
   *
   * @type {string}
   * @memberof FileData
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FileData
   */
  lastModified?: number;
  /**
   *
   * @type {boolean}
   * @memberof FileData
   */
  hasLocalChanges?: boolean;
}
/**
 *
 * @export
 * @interface FileTreeData
 */
export interface FileTreeData {
  /**
   *
   * @type {string}
   * @memberof FileTreeData
   */
  mainFile?: string;
  /**
   *
   * @type {Array<FileData>}
   * @memberof FileTreeData
   */
  files?: Array<FileData>;
  /**
   *
   * @type {Array<FileData>}
   * @memberof FileTreeData
   */
  dependencies?: Array<FileData>;
  /**
   *
   * @type {Array<FileData>}
   * @memberof FileTreeData
   */
  systemDependencies?: Array<FileData>;
}
/**
 *
 * @export
 * @interface GitIntegration
 */
export interface GitIntegration {
  /**
   *
   * @type {string}
   * @memberof GitIntegration
   */
  integrationId: string;
  /**
   *
   * @type {string}
   * @memberof GitIntegration
   */
  repositoryName?: string;
  /**
   * Bitbucket workspace slug. Null for integration with other services
   * @type {string}
   * @memberof GitIntegration
   */
  workspaceId?: string;
  /**
   * Bitbucket project id. Null for integration with other services
   * @type {string}
   * @memberof GitIntegration
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof GitIntegration
   */
  repositoryUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GitIntegration
   */
  branchName?: string;
  /**
   *
   * @type {boolean}
   * @memberof GitIntegration
   */
  repositoryIsPrivate?: boolean;
}
/**
 *
 * @export
 * @interface GraphData
 */
export interface GraphData {
  /**
   *
   * @type {string}
   * @memberof GraphData
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof GraphData
   */
  modificationId?: string;
  /**
   *
   * @type {number}
   * @memberof GraphData
   */
  lastModified?: number;
}
/**
 *
 * @export
 * @interface GraphModificationData
 */
export interface GraphModificationData {
  /**
   *
   * @type {string}
   * @memberof GraphModificationData
   */
  modificationId?: string;
}
/**
 *
 * @export
 * @interface GraphV2Data
 */
export interface GraphV2Data {
  /**
   *
   * @type {string}
   * @memberof GraphV2Data
   */
  file?: string;
  /**
   *
   * @type {string}
   * @memberof GraphV2Data
   */
  context?: string;
  /**
   *
   * @type {object}
   * @memberof GraphV2Data
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof GraphV2Data
   */
  lastModified?: number;
}
/**
 *
 * @export
 * @interface GraphV2TreeData
 */
export interface GraphV2TreeData {
  /**
   *
   * @type {string}
   * @memberof GraphV2TreeData
   */
  file?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GraphV2TreeData
   */
  context?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum GraphV2UpdateActionType {
  Add = 'add',
  UpdateLayout = 'updateLayout',
  UpdateProps = 'updateProps',
  UpdateCode = 'updateCode',
  Delete = 'delete',
}

/**
 *
 * @export
 * @interface GraphV2UpdateData
 */
export interface GraphV2UpdateData {
  /**
   *
   * @type {string}
   * @memberof GraphV2UpdateData
   */
  file?: string;
  /**
   *
   * @type {number}
   * @memberof GraphV2UpdateData
   */
  lastModified?: number;
  /**
   *
   * @type {Array<GraphV2UpdateItemData>}
   * @memberof GraphV2UpdateData
   */
  items?: Array<GraphV2UpdateItemData>;
}
/**
 *
 * @export
 * @interface GraphV2UpdateItemData
 */
export interface GraphV2UpdateItemData {
  /**
   *
   * @type {GraphV2UpdateActionType}
   * @memberof GraphV2UpdateItemData
   */
  action?: GraphV2UpdateActionType;
  /**
   *
   * @type {string}
   * @memberof GraphV2UpdateItemData
   */
  oldPath?: string;
  /**
   *
   * @type {object}
   * @memberof GraphV2UpdateItemData
   */
  data?: object;
}
/**
 *
 * @export
 * @interface HasFeatureData
 */
export interface HasFeatureData {
  /**
   *
   * @type {boolean}
   * @memberof HasFeatureData
   */
  hasFeature: boolean;
}
/**
 *
 * @export
 * @interface JBlock
 */
export interface JBlock {
  /**
   *
   * @type {string}
   * @memberof JBlock
   */
  tagName: string;
  /**
   *
   * @type {string}
   * @memberof JBlock
   */
  tagValue?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof JBlock
   */
  tagParameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof JBlock
   */
  comment?: Array<string>;
  /**
   *
   * @type {Array<JBlock>}
   * @memberof JBlock
   */
  jblocks?: Array<JBlock>;
}
/**
 *
 * @export
 * @interface JBlockLocator
 */
export interface JBlockLocator {
  /**
   *
   * @type {string}
   * @memberof JBlockLocator
   */
  tagName: string;
  /**
   *
   * @type {TagLocator}
   * @memberof JBlockLocator
   */
  locator: TagLocator;
  /**
   *
   * @type {Array<JBlockLocator>}
   * @memberof JBlockLocator
   */
  jblocks: Array<JBlockLocator>;
}
/**
 *
 * @export
 * @interface JFile
 */
export interface JFile {
  /**
   *
   * @type {string}
   * @memberof JFile
   */
  filename: string;
  /**
   *
   * @type {number}
   * @memberof JFile
   */
  lastModification: number;
}
/**
 *
 * @export
 * @interface JGraph
 */
export interface JGraph {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof JGraph
   */
  files: { [key: string]: number };
  /**
   *
   * @type {Array<JTheme>}
   * @memberof JGraph
   */
  themes?: Array<JTheme>;
}
/**
 *
 * @export
 * @interface JGraphData
 */
export interface JGraphData {
  /**
   *
   * @type {JGraph}
   * @memberof JGraphData
   */
  jgraph: JGraph;
}
/**
 *
 * @export
 * @interface JGraphLocators
 */
export interface JGraphLocators {
  /**
   *
   * @type {Array<JStateLocator>}
   * @memberof JGraphLocators
   */
  jstates: Array<JStateLocator>;
}
/**
 *
 * @export
 * @interface JState
 */
export interface JState {
  /**
   *
   * @type {string}
   * @memberof JState
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof JState
   */
  path: string;
  /**
   *
   * @type {Array<object>}
   * @memberof JState
   */
  parameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof JState
   */
  comment?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof JState
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof JState
   */
  y: number;
  /**
   *
   * @type {string}
   * @memberof JState
   */
  aimyPosition?: string;
  /**
   *
   * @type {Array<JState>}
   * @memberof JState
   */
  states?: Array<JState>;
  /**
   *
   * @type {Array<JBlock>}
   * @memberof JState
   */
  blocks?: Array<JBlock>;
}
/**
 *
 * @export
 * @interface JStateLocator
 */
export interface JStateLocator {
  /**
   *
   * @type {string}
   * @memberof JStateLocator
   */
  path: string;
  /**
   *
   * @type {Array<JBlockLocator>}
   * @memberof JStateLocator
   */
  blocks: Array<JBlockLocator>;
}
/**
 *
 * @export
 * @interface JStateMovementsData
 */
export interface JStateMovementsData {
  /**
   *
   * @type {Array<MovementsDataWithFile>}
   * @memberof JStateMovementsData
   */
  movementsDataWithFile: Array<MovementsDataWithFile>;
}
/**
 *
 * @export
 * @interface JTheme
 */
export interface JTheme {
  /**
   *
   * @type {string}
   * @memberof JTheme
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof JTheme
   */
  value: string;
  /**
   *
   * @type {Array<JState>}
   * @memberof JTheme
   */
  states: Array<JState>;
}
/**
 *
 * @export
 * @interface LastModification
 */
export interface LastModification {
  /**
   *
   * @type {number}
   * @memberof LastModification
   */
  lastModification: number;
}
/**
 *
 * @export
 * @interface LastModificationLogForProject
 */
export interface LastModificationLogForProject {
  /**
   *
   * @type {string}
   * @memberof LastModificationLogForProject
   */
  projectId?: string;
  /**
   *
   * @type {Date}
   * @memberof LastModificationLogForProject
   */
  modificationDate?: Date;
}
/**
 *
 * @export
 * @interface Locator
 */
export interface Locator {
  /**
   *
   * @type {string}
   * @memberof Locator
   */
  filename: string;
  /**
   *
   * @type {number}
   * @memberof Locator
   */
  line: number;
  /**
   *
   * @type {number}
   * @memberof Locator
   */
  col: number;
  /**
   * system - error in system library. git - error in dependency with type git. local - error in source directory or dependency with type module.
   * @type {string}
   * @memberof Locator
   */
  storageType?: StorageTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StorageTypeEnum {
  Git = 'git',
  Local = 'local',
  System = 'system',
}

/**
 *
 * @export
 * @interface ModificationLogData
 */
export interface ModificationLogData {
  /**
   *
   * @type {Date}
   * @memberof ModificationLogData
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ModificationLogData
   */
  author?: string;
  /**
   *
   * @type {string}
   * @memberof ModificationLogData
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ModificationLogData
   */
  modificationId?: string;
}
/**
 *
 * @export
 * @interface MovementData
 */
export interface MovementData {
  /**
   *
   * @type {string}
   * @memberof MovementData
   */
  targetState: string;
  /**
   *
   * @type {number}
   * @memberof MovementData
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof MovementData
   */
  y: number;
}
/**
 *
 * @export
 * @interface MovementsDataWithFile
 */
export interface MovementsDataWithFile {
  /**
   *
   * @type {string}
   * @memberof MovementsDataWithFile
   */
  filename: string;
  /**
   *
   * @type {Array<MovementData>}
   * @memberof MovementsDataWithFile
   */
  statesMovementsData: Array<MovementData>;
}
/**
 *
 * @export
 * @interface MultiRenameData
 */
export interface MultiRenameData {
  /**
   *
   * @type {string}
   * @memberof MultiRenameData
   */
  tagName: string;
  /**
   *
   * @type {string}
   * @memberof MultiRenameData
   */
  oldValue: string;
  /**
   *
   * @type {string}
   * @memberof MultiRenameData
   */
  newValue: string;
}
/**
 *
 * @export
 * @interface NamedEntity
 */
export interface NamedEntity {
  /**
   *
   * @type {string}
   * @memberof NamedEntity
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NamedEntity
   */
  docLink?: string;
  /**
   *
   * @type {number}
   * @memberof NamedEntity
   */
  valuesCount?: number;
  /**
   *
   * @type {number}
   * @memberof NamedEntity
   */
  synonymsCount?: number;
  /**
   *
   * @type {string}
   * @memberof NamedEntity
   */
  csvPath?: string;
}
/**
 *
 * @export
 * @interface NluSettingsData
 */
export interface NluSettingsData {
  /**
   *
   * @type {number}
   * @memberof NluSettingsData
   */
  noMatchThreshold?: number;
  /**
   *
   * @type {DictionaryType}
   * @memberof NluSettingsData
   */
  dictionary?: DictionaryType;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface ParseError
 */
export interface ParseError {
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  template: string;
  /**
   *
   * @type {object}
   * @memberof ParseError
   */
  args: object;
  /**
   *
   * @type {Locator}
   * @memberof ParseError
   */
  locator: Locator;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ProductType {
  Aimylogic = 'aimylogic',
  Jaicp = 'jaicp',
}

/**
 *
 * @export
 * @interface ProjectData
 */
export interface ProjectData {
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  projectId?: string;
  /**
   *
   * @type {ExternalRepoData}
   * @memberof ProjectData
   */
  externalRepository?: ExternalRepoData;
  /**
   *
   * @type {GitIntegration}
   * @memberof ProjectData
   */
  gitIntegration?: GitIntegration;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  contentDirectory?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  branch?: string;
  /**
   * Can be [revision, tag, branch]
   * @type {string}
   * @memberof ProjectData
   */
  refType?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  refValue?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectData
   */
  secretKey?: string;
  /**
   *
   * @type {ProductType}
   * @memberof ProjectData
   */
  product: ProductType;
  /**
   * Whether it is a JAICF project or not. This field is set on project creation.
   * @type {boolean}
   * @memberof ProjectData
   */
  jaicfProject?: boolean;
  /**
   * External bot token. It is used in chatadapter for bots that are hosted outside botserver. In case of JAICF projects it is the same thing that JAICF token.
   * @type {string}
   * @memberof ProjectData
   */
  externalBotToken?: string;
  /**
   *
   * @type {Environment}
   * @memberof ProjectData
   */
  environment?: Environment;
  /**
   * JAICF bot with remote host will accept messages from this link.
   * @type {string}
   * @memberof ProjectData
   */
  webHookUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProjectData
   */
  skipBranchExistsCheck?: boolean;
}
/**
 *
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
  /**
   *
   * @type {string}
   * @memberof ProjectSettings
   */
  language: string;
}
/**
 *
 * @export
 * @interface ProjectStatisticData
 */
export interface ProjectStatisticData {
  /**
   *
   * @type {number}
   * @memberof ProjectStatisticData
   */
  intentPhrasesCount: number;
}
/**
 *
 * @export
 * @interface ProjectStatusData
 */
export interface ProjectStatusData {
  /**
   *
   * @type {string}
   * @memberof ProjectStatusData
   */
  projectId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProjectStatusData
   */
  unsavedChanges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectStatusData
   */
  buildOutdated?: boolean;
}
/**
 *
 * @export
 * @interface ProjectsData
 */
export interface ProjectsData {
  /**
   *
   * @type {Pagination}
   * @memberof ProjectsData
   */
  paging: Pagination;
  /**
   *
   * @type {Array<ProjectData>}
   * @memberof ProjectsData
   */
  records: Array<ProjectData>;
}
/**
 *
 * @export
 * @interface QueryLocation
 */
export interface QueryLocation {
  /**
   *
   * @type {string}
   * @memberof QueryLocation
   */
  fileName: string;
  /**
   *
   * @type {number}
   * @memberof QueryLocation
   */
  lineNumber: number;
  /**
   *
   * @type {number}
   * @memberof QueryLocation
   */
  startPosition: number;
  /**
   *
   * @type {number}
   * @memberof QueryLocation
   */
  endPosition: number;
  /**
   *
   * @type {string}
   * @memberof QueryLocation
   */
  queryResult: string;
  /**
   *
   * @type {string}
   * @memberof QueryLocation
   */
  lineContent: string;
}
/**
 *
 * @export
 * @interface RefsData
 */
export interface RefsData {
  /**
   *
   * @type {Array<string>}
   * @memberof RefsData
   */
  branches?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof RefsData
   */
  tags?: Array<string>;
}
/**
 *
 * @export
 * @interface RenameFileData
 */
export interface RenameFileData {
  /**
   *
   * @type {string}
   * @memberof RenameFileData
   */
  oldName?: string;
  /**
   *
   * @type {string}
   * @memberof RenameFileData
   */
  newName?: string;
}
/**
 *
 * @export
 * @interface RenameIntentData
 */
export interface RenameIntentData {
  /**
   *
   * @type {string}
   * @memberof RenameIntentData
   */
  oldValue: string;
  /**
   *
   * @type {string}
   * @memberof RenameIntentData
   */
  newValue: string;
}
/**
 *
 * @export
 * @interface ReplaceInfo
 */
export interface ReplaceInfo {
  /**
   *
   * @type {string}
   * @memberof ReplaceInfo
   */
  fileName: string;
  /**
   *
   * @type {Array<QueryLocation>}
   * @memberof ReplaceInfo
   */
  queryLocations: Array<QueryLocation>;
}
/**
 *
 * @export
 * @interface ReplaceRequest
 */
export interface ReplaceRequest {
  /**
   *
   * @type {string}
   * @memberof ReplaceRequest
   */
  substitution: string;
  /**
   *
   * @type {Array<ReplaceInfo>}
   * @memberof ReplaceRequest
   */
  replaceInfos: Array<ReplaceInfo>;
}
/**
 *
 * @export
 * @interface RestoreProjectData
 */
export interface RestoreProjectData {
  /**
   *
   * @type {number}
   * @memberof RestoreProjectData
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof RestoreProjectData
   */
  projectId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RolloutType {
  Auto = 'auto',
  Manual = 'manual',
}

/**
 *
 * @export
 * @interface RunTestsTaskIdData
 */
export interface RunTestsTaskIdData {
  /**
   *
   * @type {string}
   * @memberof RunTestsTaskIdData
   */
  taskId: string;
}
/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
  /**
   *
   * @type {Array<QueryLocation>}
   * @memberof SearchResult
   */
  queryLocations: Array<QueryLocation>;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  linesCount: number;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  filesCount: number;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  totalFilesCount: number;
  /**
   *
   * @type {boolean}
   * @memberof SearchResult
   */
  hasMore: boolean;
}
/**
 *
 * @export
 * @interface SearchStatistics
 */
export interface SearchStatistics {
  /**
   *
   * @type {number}
   * @memberof SearchStatistics
   */
  filesCount: number;
  /**
   *
   * @type {number}
   * @memberof SearchStatistics
   */
  linesCount: number;
  /**
   *
   * @type {Array<ReplaceInfo>}
   * @memberof SearchStatistics
   */
  replaceInfos: Array<ReplaceInfo>;
}
/**
 *
 * @export
 * @interface ServerLog
 */
export interface ServerLog {
  /**
   *
   * @type {Date}
   * @memberof ServerLog
   */
  time: Date;
  /**
   *
   * @type {string}
   * @memberof ServerLog
   */
  message: string;
  /**
   *
   * @type {ServerLogApp}
   * @memberof ServerLog
   */
  app: ServerLogApp;
  /**
   *
   * @type {ServerLogLevel}
   * @memberof ServerLog
   */
  level: ServerLogLevel;
  /**
   *
   * @type {ServerLogModule}
   * @memberof ServerLog
   */
  module: ServerLogModule;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ServerLogApp {
  Botserver = 'botserver',
  Editorbe = 'editorbe',
  Cailapub = 'cailapub',
}

/**
 * l0 - Emergency, l1 - Alert, l2 - Critical, l3 - Error, l4 - Warning, l5 - Notice, l6 - Informational, l7 - Debug
 * @export
 * @enum {string}
 */
export enum ServerLogLevel {
  L0 = 'l0',
  L1 = 'l1',
  L2 = 'l2',
  L3 = 'l3',
  L4 = 'l4',
  L5 = 'l5',
  L6 = 'l6',
  L7 = 'l7',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ServerLogModule {
  Engine = 'engine',
  EngineV2 = 'engine_v2',
  Cailapub = 'cailapub',
  Executor = 'executor',
  Matcher = 'matcher',
  Js = 'js',
  Http = 'http',
  Botapi = 'botapi',
  Inflector = 'inflector',
  Other = 'other',
}

/**
 *
 * @export
 * @interface State
 */
export interface State {
  /**
   *
   * @type {string}
   * @memberof State
   */
  name: string;
  /**
   *
   * @type {Locator}
   * @memberof State
   */
  locator: Locator;
}
/**
 *
 * @export
 * @interface StateBlock
 */
export interface StateBlock {
  /**
   *
   * @type {string}
   * @memberof StateBlock
   */
  tagName: string;
  /**
   *
   * @type {string}
   * @memberof StateBlock
   */
  tagValue?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof StateBlock
   */
  tagParameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof StateBlock
   */
  comment?: Array<string>;
  /**
   *
   * @type {Array<StateBlock>}
   * @memberof StateBlock
   */
  blocks?: Array<StateBlock>;
}
/**
 *
 * @export
 * @interface StateDescription
 */
export interface StateDescription {
  /**
   *
   * @type {string}
   * @memberof StateDescription
   */
  value: string;
  /**
   *
   * @type {Array<object>}
   * @memberof StateDescription
   */
  parameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof StateDescription
   */
  comment?: Array<string>;
  /**
   *
   * @type {Array<StateBlock>}
   * @memberof StateDescription
   */
  blocks?: Array<StateBlock>;
}
/**
 *
 * @export
 * @interface StateToCreate
 */
export interface StateToCreate {
  /**
   *
   * @type {string}
   * @memberof StateToCreate
   */
  value: string;
  /**
   *
   * @type {Array<object>}
   * @memberof StateToCreate
   */
  parameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof StateToCreate
   */
  comment?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof StateToCreate
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof StateToCreate
   */
  y: number;
  /**
   *
   * @type {Array<StateToCreate>}
   * @memberof StateToCreate
   */
  states?: Array<StateToCreate>;
  /**
   *
   * @type {Array<JBlock>}
   * @memberof StateToCreate
   */
  blocks?: Array<JBlock>;
}
/**
 *
 * @export
 * @interface StateToUpdate
 */
export interface StateToUpdate {
  /**
   *
   * @type {string}
   * @memberof StateToUpdate
   */
  value: string;
  /**
   *
   * @type {Array<object>}
   * @memberof StateToUpdate
   */
  parameters?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof StateToUpdate
   */
  comment?: Array<string>;
  /**
   *
   * @type {Array<JBlock>}
   * @memberof StateToUpdate
   */
  blocks?: Array<JBlock>;
}
/**
 *
 * @export
 * @interface TagLocator
 */
export interface TagLocator {
  /**
   *
   * @type {string}
   * @memberof TagLocator
   */
  filename: string;
  /**
   *
   * @type {number}
   * @memberof TagLocator
   */
  line: number;
  /**
   *
   * @type {number}
   * @memberof TagLocator
   */
  col: number;
}
/**
 *
 * @export
 * @interface TagsListEntry
 */
export interface TagsListEntry {
  /**
   *
   * @type {string}
   * @memberof TagsListEntry
   */
  tagName: string;
  /**
   *
   * @type {string}
   * @memberof TagsListEntry
   */
  tagValue?: string;
  /**
   *
   * @type {TagsListEntryLocator}
   * @memberof TagsListEntry
   */
  locator: TagsListEntryLocator;
}
/**
 *
 * @export
 * @interface TagsListEntryLocator
 */
export interface TagsListEntryLocator {
  /**
   *
   * @type {string}
   * @memberof TagsListEntryLocator
   */
  fileName: string;
  /**
   *
   * @type {number}
   * @memberof TagsListEntryLocator
   */
  line: number;
  /**
   *
   * @type {number}
   * @memberof TagsListEntryLocator
   */
  col: number;
}
/**
 * Filters \"tagName\" and \"tagValue\" are joined with \"AND\" operator
 * @export
 * @interface TagsListFilter
 */
export interface TagsListFilter {
  /**
   *
   * @type {string}
   * @memberof TagsListFilter
   */
  tagName?: string;
  /**
   *
   * @type {string}
   * @memberof TagsListFilter
   */
  tagValue?: string;
  /**
   * If true and tag name is present then search not only by \"<tagName>\" but also by \"<tagName>!\"
   * @type {boolean}
   * @memberof TagsListFilter
   */
  includeGlobal?: boolean;
}
/**
 *
 * @export
 * @interface UpdateJStateData
 */
export interface UpdateJStateData {
  /**
   *
   * @type {string}
   * @memberof UpdateJStateData
   */
  targetStatePath: string;
  /**
   *
   * @type {StateToUpdate}
   * @memberof UpdateJStateData
   */
  stateToUpdate: StateToUpdate;
  /**
   *
   * @type {JFile}
   * @memberof UpdateJStateData
   */
  file: JFile;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJStateData
   */
  force?: boolean;
}
/**
 *
 * @export
 * @interface UpdateJStateResponseData
 */
export interface UpdateJStateResponseData {
  /**
   *
   * @type {JState}
   * @memberof UpdateJStateResponseData
   */
  jstate: JState;
  /**
   *
   * @type {number}
   * @memberof UpdateJStateResponseData
   */
  lastModification: number;
}
/**
 *
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
  /**
   *
   * @type {Array<ParseError>}
   * @memberof ValidationResult
   */
  validationErrors?: Array<ParseError>;
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationResult
   */
  filesConnectedToProject?: Array<string>;
  /**
   *
   * @type {Array<State>}
   * @memberof ValidationResult
   */
  projectStates?: Array<State>;
}

/**
 * AimyApi - axios parameter creator
 * @export
 */
export const AimyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Deploy all bots for project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildAndDeploy(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling buildAndDeploy.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling buildAndDeploy.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/deploy-for-aimylogic`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDeploymentTasks(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAllDeploymentTasks.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getAllDeploymentTasks.'
        );
      }
      const localVarPath = `/api/editorbe-for-test/accounts/{accountId}/projects/{projectId}/get-tasks`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTasks(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDeploymentTasks.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getDeploymentTasks.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getHistory.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getHistory.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/history-aimylogic`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNluSettings(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getNluSettings.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getNluSettings.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/nlu-settings`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnErrorMessages(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOnErrorMessages.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getOnErrorMessages.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/on-error`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
     * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore(restoreProjectData: Array<RestoreProjectData>, options: any = {}): RequestArgs {
      // verify required parameter 'restoreProjectData' is not null or undefined
      if (restoreProjectData === null || restoreProjectData === undefined) {
        throw new RequiredError(
          'restoreProjectData',
          'Required parameter restoreProjectData was null or undefined when calling restore.'
        );
      }
      const localVarPath = `/api/editorbe/restore`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof restoreProjectData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(restoreProjectData !== undefined ? restoreProjectData : {})
        : restoreProjectData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NluSettingsData} nluSettingsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNluSettings(
      accountId: number,
      projectId: string,
      nluSettingsData: NluSettingsData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling saveNluSettings.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling saveNluSettings.'
        );
      }
      // verify required parameter 'nluSettingsData' is not null or undefined
      if (nluSettingsData === null || nluSettingsData === undefined) {
        throw new RequiredError(
          'nluSettingsData',
          'Required parameter nluSettingsData was null or undefined when calling saveNluSettings.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/nlu-settings`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof nluSettingsData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(nluSettingsData !== undefined ? nluSettingsData : {})
        : nluSettingsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOnErrorMessages(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling saveOnErrorMessages.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling saveOnErrorMessages.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling saveOnErrorMessages.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/on-error`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} body
     * @param {boolean} [systemSave] System save.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraphAimy(
      accountId: number,
      projectId: string,
      file: string,
      body: string,
      systemSave?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateGraphAimy.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateGraphAimy.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling updateGraphAimy.');
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateGraphAimy.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph-aimylogic`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (systemSave !== undefined) {
        localVarQueryParameter['systemSave'] = systemSave;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AimyApi - functional programming interface
 * @export
 */
export const AimyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Deploy all bots for project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildAndDeploy(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).buildAndDeploy(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDeploymentTasks(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentTaskData>> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getAllDeploymentTasks(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTasks(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentTaskData>> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getDeploymentTasks(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModificationLogData>> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getHistory(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNluSettings(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NluSettingsData> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getNluSettings(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnErrorMessages(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getOnErrorMessages(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
     * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore(
      restoreProjectData: Array<RestoreProjectData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestoreProjectData>> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).restore(restoreProjectData, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NluSettingsData} nluSettingsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNluSettings(
      accountId: number,
      projectId: string,
      nluSettingsData: NluSettingsData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NluSettingsData> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).saveNluSettings(
        accountId,
        projectId,
        nluSettingsData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOnErrorMessages(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).saveOnErrorMessages(
        accountId,
        projectId,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} body
     * @param {boolean} [systemSave] System save.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraphAimy(
      accountId: number,
      projectId: string,
      file: string,
      body: string,
      systemSave?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphModificationData> {
      const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).updateGraphAimy(
        accountId,
        projectId,
        file,
        body,
        systemSave,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AimyApi - factory interface
 * @export
 */
export const AimyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Deploy all bots for project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildAndDeploy(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return AimyApiFp(configuration).buildAndDeploy(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDeploymentTasks(
      accountId: number,
      projectId: string,
      options?: any
    ): AxiosPromise<Array<DeploymentTaskData>> {
      return AimyApiFp(configuration).getAllDeploymentTasks(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTasks(accountId: number, projectId: string, options?: any): AxiosPromise<Array<DeploymentTaskData>> {
      return AimyApiFp(configuration).getDeploymentTasks(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(accountId: number, projectId: string, options?: any): AxiosPromise<Array<ModificationLogData>> {
      return AimyApiFp(configuration).getHistory(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNluSettings(accountId: number, projectId: string, options?: any): AxiosPromise<NluSettingsData> {
      return AimyApiFp(configuration).getNluSettings(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnErrorMessages(accountId: number, projectId: string, options?: any): AxiosPromise<Array<string>> {
      return AimyApiFp(configuration).getOnErrorMessages(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
     * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore(restoreProjectData: Array<RestoreProjectData>, options?: any): AxiosPromise<Array<RestoreProjectData>> {
      return AimyApiFp(configuration).restore(restoreProjectData, options)(axios, basePath);
    },
    /**
     *
     * @summary Update nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NluSettingsData} nluSettingsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNluSettings(
      accountId: number,
      projectId: string,
      nluSettingsData: NluSettingsData,
      options?: any
    ): AxiosPromise<NluSettingsData> {
      return AimyApiFp(configuration).saveNluSettings(accountId, projectId, nluSettingsData, options)(axios, basePath);
    },
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOnErrorMessages(
      accountId: number,
      projectId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return AimyApiFp(configuration).saveOnErrorMessages(accountId, projectId, requestBody, options)(axios, basePath);
    },
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} body
     * @param {boolean} [systemSave] System save.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraphAimy(
      accountId: number,
      projectId: string,
      file: string,
      body: string,
      systemSave?: boolean,
      options?: any
    ): AxiosPromise<GraphModificationData> {
      return AimyApiFp(configuration).updateGraphAimy(
        accountId,
        projectId,
        file,
        body,
        systemSave,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AimyApi - object-oriented interface
 * @export
 * @class AimyApi
 * @extends {BaseAPI}
 */
export class AimyApi extends BaseAPI {
  /**
   *
   * @summary Deploy all bots for project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public buildAndDeploy(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).buildAndDeploy(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get internal state of deployment task queue for current project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public getAllDeploymentTasks(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).getAllDeploymentTasks(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get internal state of deployment task queue for current project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public getDeploymentTasks(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).getDeploymentTasks(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary history
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public getHistory(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).getHistory(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Nlu settings
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public getNluSettings(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).getNluSettings(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary On error
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public getOnErrorMessages(accountId: number, projectId: string, options?: any) {
    return AimyApiFp(this.configuration).getOnErrorMessages(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
   * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public restore(restoreProjectData: Array<RestoreProjectData>, options?: any) {
    return AimyApiFp(this.configuration).restore(restoreProjectData, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update nlu settings
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {NluSettingsData} nluSettingsData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public saveNluSettings(accountId: number, projectId: string, nluSettingsData: NluSettingsData, options?: any) {
    return AimyApiFp(this.configuration).saveNluSettings(
      accountId,
      projectId,
      nluSettingsData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary On error
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public saveOnErrorMessages(accountId: number, projectId: string, requestBody: Array<string>, options?: any) {
    return AimyApiFp(this.configuration).saveOnErrorMessages(
      accountId,
      projectId,
      requestBody,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary graphRequest
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {string} body
   * @param {boolean} [systemSave] System save.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyApi
   */
  public updateGraphAimy(
    accountId: number,
    projectId: string,
    file: string,
    body: string,
    systemSave?: boolean,
    options?: any
  ) {
    return AimyApiFp(this.configuration).updateGraphAimy(
      accountId,
      projectId,
      file,
      body,
      systemSave,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * DependencyApi - axios parameter creator
 * @export
 */
export const DependencyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get content of dependency
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDependency(accountId: number, projectId: string, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDependency.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getDependency.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getDependency.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/dependency`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get content of system dependency file
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemDependency(file: string, options: any = {}): RequestArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling getSystemDependency.'
        );
      }
      const localVarPath = `/api/editorbe/content/systemDependency`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary update user dependencies from chatbot.yaml
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDependencies(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateDependencies.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateDependencies.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/updateDependencies`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DependencyApi - functional programming interface
 * @export
 */
export const DependencyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get content of dependency
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDependency(
      accountId: number,
      projectId: string,
      file: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileContentData> {
      const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).getDependency(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary get content of system dependency file
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemDependency(
      file: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileContentData> {
      const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).getSystemDependency(file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary update user dependencies from chatbot.yaml
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDependencies(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).updateDependencies(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DependencyApi - factory interface
 * @export
 */
export const DependencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary get content of dependency
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDependency(accountId: number, projectId: string, file: string, options?: any): AxiosPromise<FileContentData> {
      return DependencyApiFp(configuration).getDependency(accountId, projectId, file, options)(axios, basePath);
    },
    /**
     *
     * @summary get content of system dependency file
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemDependency(file: string, options?: any): AxiosPromise<FileContentData> {
      return DependencyApiFp(configuration).getSystemDependency(file, options)(axios, basePath);
    },
    /**
     *
     * @summary update user dependencies from chatbot.yaml
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDependencies(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return DependencyApiFp(configuration).updateDependencies(accountId, projectId, options)(axios, basePath);
    },
  };
};

/**
 * DependencyApi - object-oriented interface
 * @export
 * @class DependencyApi
 * @extends {BaseAPI}
 */
export class DependencyApi extends BaseAPI {
  /**
   *
   * @summary get content of dependency
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DependencyApi
   */
  public getDependency(accountId: number, projectId: string, file: string, options?: any) {
    return DependencyApiFp(this.configuration).getDependency(
      accountId,
      projectId,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary get content of system dependency file
   * @param {string} file File path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DependencyApi
   */
  public getSystemDependency(file: string, options?: any) {
    return DependencyApiFp(this.configuration).getSystemDependency(file, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary update user dependencies from chatbot.yaml
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DependencyApi
   */
  public updateDependencies(accountId: number, projectId: string, options?: any) {
    return DependencyApiFp(this.configuration).updateDependencies(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * DeploymentApi - axios parameter creator
 * @export
 */
export const DeploymentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel task if it doesn\'t start yet
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTask(accountId: number, projectId: string, taskId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling cancelTask.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling cancelTask.'
        );
      }
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError('taskId', 'Required parameter taskId was null or undefined when calling cancelTask.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check system projects init completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSystemProjectsInit(options: any = {}): RequestArgs {
      const localVarPath = `/api/editorbe-for-test/check-system-projects`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create task to deploy bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {boolean} [forceDeploy] Force deploy flag.
     * @param {boolean} [skipNotifications] Skip notifications flag.
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {boolean} [runTestsInBackground] Run tests in background.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deploy(
      accountId: number,
      projectId: string,
      botId: string,
      forceDeploy?: boolean,
      skipNotifications?: boolean,
      skipTests?: boolean,
      runTestsInBackground?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deploy.');
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling deploy.');
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling deploy.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/deploy`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (forceDeploy !== undefined) {
        localVarQueryParameter['forceDeploy'] = forceDeploy;
      }

      if (skipNotifications !== undefined) {
        localVarQueryParameter['skipNotifications'] = skipNotifications;
      }

      if (skipTests !== undefined) {
        localVarQueryParameter['skipTests'] = skipTests;
      }

      if (runTestsInBackground !== undefined) {
        localVarQueryParameter['runTestsInBackground'] = runTestsInBackground;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List deploy configs and status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllConfigs(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAllConfigs.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getAllConfigs.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get global deploy config from application.yml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeployConfig(options: any = {}): RequestArgs {
      const localVarPath = `/api/editorbe-for-test/get-deploy-config`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get deployment task info
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTask(accountId: number, projectId: string, taskId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDeploymentTask.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getDeploymentTask.'
        );
      }
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          'taskId',
          'Required parameter taskId was null or undefined when calling getDeploymentTask.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get deployment log for a task
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTaskLog(accountId: number, projectId: string, taskId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDeploymentTaskLog.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getDeploymentTaskLog.'
        );
      }
      // verify required parameter 'taskId' is not null or undefined
      if (taskId === null || taskId === undefined) {
        throw new RequiredError(
          'taskId',
          'Required parameter taskId was null or undefined when calling getDeploymentTaskLog.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}/log`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'taskId'}}`, encodeURIComponent(String(taskId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get deployment history for bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryByBot(
      accountId: number,
      projectId: string,
      botId: string,
      page?: number,
      size?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getHistoryByBot.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getHistoryByBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling getHistoryByBot.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/{botId}/history`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get last deploy results for all bots by account.
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastDeployResultsForAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLastDeployResultsForAccount.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/deployment/history`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get status for single bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusByBot(accountId: number, projectId: string, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getStatusByBot.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getStatusByBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling getStatusByBot.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get status for all bot configs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusForAll(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getStatusForAll.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getStatusForAll.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run tests
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runTests(accountId: number, projectId: string, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling runTests.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling runTests.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling runTests.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/runTests`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update deploy configurations
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllConfigs(
      accountId: number,
      projectId: string,
      deploymentBotConfigData: Array<DeploymentBotConfigData>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAllConfigs.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateAllConfigs.'
        );
      }
      // verify required parameter 'deploymentBotConfigData' is not null or undefined
      if (deploymentBotConfigData === null || deploymentBotConfigData === undefined) {
        throw new RequiredError(
          'deploymentBotConfigData',
          'Required parameter deploymentBotConfigData was null or undefined when calling updateAllConfigs.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof deploymentBotConfigData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deploymentBotConfigData !== undefined ? deploymentBotConfigData : {})
        : deploymentBotConfigData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeploymentApi - functional programming interface
 * @export
 */
export const DeploymentApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel task if it doesn\'t start yet
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTask(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).cancelTask(
        accountId,
        projectId,
        taskId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Check system projects init completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSystemProjectsInit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).checkSystemProjectsInit(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create task to deploy bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {boolean} [forceDeploy] Force deploy flag.
     * @param {boolean} [skipNotifications] Skip notifications flag.
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {boolean} [runTestsInBackground] Run tests in background.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deploy(
      accountId: number,
      projectId: string,
      botId: string,
      forceDeploy?: boolean,
      skipNotifications?: boolean,
      skipTests?: boolean,
      runTestsInBackground?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentTaskIdData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).deploy(
        accountId,
        projectId,
        botId,
        forceDeploy,
        skipNotifications,
        skipTests,
        runTestsInBackground,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List deploy configs and status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllConfigs(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentBotConfigData>> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getAllConfigs(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get global deploy config from application.yml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeployConfig(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeployConfigTestData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeployConfig(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get deployment task info
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTask(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentTaskData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeploymentTask(
        accountId,
        projectId,
        taskId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get deployment log for a task
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTaskLog(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeploymentTaskLog(
        accountId,
        projectId,
        taskId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get deployment history for bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryByBot(
      accountId: number,
      projectId: string,
      botId: string,
      page?: number,
      size?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentHistoryData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getHistoryByBot(
        accountId,
        projectId,
        botId,
        page,
        size,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get last deploy results for all bots by account.
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastDeployResultsForAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentTaskData>> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getLastDeployResultsForAccount(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get status for single bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusByBot(
      accountId: number,
      projectId: string,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentBotStatusData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getStatusByBot(
        accountId,
        projectId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get status for all bot configs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusForAll(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentBotStatusData>> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getStatusForAll(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Run tests
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runTests(
      accountId: number,
      projectId: string,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunTestsTaskIdData> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).runTests(
        accountId,
        projectId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update deploy configurations
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllConfigs(
      accountId: number,
      projectId: string,
      deploymentBotConfigData: Array<DeploymentBotConfigData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).updateAllConfigs(
        accountId,
        projectId,
        deploymentBotConfigData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DeploymentApi - factory interface
 * @export
 */
export const DeploymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Cancel task if it doesn\'t start yet
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTask(accountId: number, projectId: string, taskId: number, options?: any): AxiosPromise<void> {
      return DeploymentApiFp(configuration).cancelTask(accountId, projectId, taskId, options)(axios, basePath);
    },
    /**
     *
     * @summary Check system projects init completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSystemProjectsInit(options?: any): AxiosPromise<void> {
      return DeploymentApiFp(configuration).checkSystemProjectsInit(options)(axios, basePath);
    },
    /**
     *
     * @summary Create task to deploy bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {boolean} [forceDeploy] Force deploy flag.
     * @param {boolean} [skipNotifications] Skip notifications flag.
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {boolean} [runTestsInBackground] Run tests in background.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deploy(
      accountId: number,
      projectId: string,
      botId: string,
      forceDeploy?: boolean,
      skipNotifications?: boolean,
      skipTests?: boolean,
      runTestsInBackground?: boolean,
      options?: any
    ): AxiosPromise<DeploymentTaskIdData> {
      return DeploymentApiFp(configuration).deploy(
        accountId,
        projectId,
        botId,
        forceDeploy,
        skipNotifications,
        skipTests,
        runTestsInBackground,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary List deploy configs and status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllConfigs(accountId: number, projectId: string, options?: any): AxiosPromise<Array<DeploymentBotConfigData>> {
      return DeploymentApiFp(configuration).getAllConfigs(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get global deploy config from application.yml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeployConfig(options?: any): AxiosPromise<DeployConfigTestData> {
      return DeploymentApiFp(configuration).getDeployConfig(options)(axios, basePath);
    },
    /**
     *
     * @summary Get deployment task info
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTask(
      accountId: number,
      projectId: string,
      taskId: number,
      options?: any
    ): AxiosPromise<DeploymentTaskData> {
      return DeploymentApiFp(configuration).getDeploymentTask(accountId, projectId, taskId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get deployment log for a task
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeploymentTaskLog(accountId: number, projectId: string, taskId: number, options?: any): AxiosPromise<string> {
      return DeploymentApiFp(configuration).getDeploymentTaskLog(
        accountId,
        projectId,
        taskId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get deployment history for bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryByBot(
      accountId: number,
      projectId: string,
      botId: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<DeploymentHistoryData> {
      return DeploymentApiFp(configuration).getHistoryByBot(
        accountId,
        projectId,
        botId,
        page,
        size,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get last deploy results for all bots by account.
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastDeployResultsForAccount(accountId: number, options?: any): AxiosPromise<Array<DeploymentTaskData>> {
      return DeploymentApiFp(configuration).getLastDeployResultsForAccount(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get status for single bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusByBot(
      accountId: number,
      projectId: string,
      botId: string,
      options?: any
    ): AxiosPromise<DeploymentBotStatusData> {
      return DeploymentApiFp(configuration).getStatusByBot(accountId, projectId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get status for all bot configs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusForAll(accountId: number, projectId: string, options?: any): AxiosPromise<Array<DeploymentBotStatusData>> {
      return DeploymentApiFp(configuration).getStatusForAll(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Run tests
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runTests(accountId: number, projectId: string, botId: string, options?: any): AxiosPromise<RunTestsTaskIdData> {
      return DeploymentApiFp(configuration).runTests(accountId, projectId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update deploy configurations
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAllConfigs(
      accountId: number,
      projectId: string,
      deploymentBotConfigData: Array<DeploymentBotConfigData>,
      options?: any
    ): AxiosPromise<void> {
      return DeploymentApiFp(configuration).updateAllConfigs(
        accountId,
        projectId,
        deploymentBotConfigData,
        options
      )(axios, basePath);
    },
  };
};

/**
 * DeploymentApi - object-oriented interface
 * @export
 * @class DeploymentApi
 * @extends {BaseAPI}
 */
export class DeploymentApi extends BaseAPI {
  /**
   *
   * @summary Cancel task if it doesn\'t start yet
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} taskId Bot identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public cancelTask(accountId: number, projectId: string, taskId: number, options?: any) {
    return DeploymentApiFp(this.configuration).cancelTask(
      accountId,
      projectId,
      taskId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Check system projects init completed
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public checkSystemProjectsInit(options?: any) {
    return DeploymentApiFp(this.configuration).checkSystemProjectsInit(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create task to deploy bot
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} botId Bot identifier.
   * @param {boolean} [forceDeploy] Force deploy flag.
   * @param {boolean} [skipNotifications] Skip notifications flag.
   * @param {boolean} [skipTests] Skip tests flag.
   * @param {boolean} [runTestsInBackground] Run tests in background.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public deploy(
    accountId: number,
    projectId: string,
    botId: string,
    forceDeploy?: boolean,
    skipNotifications?: boolean,
    skipTests?: boolean,
    runTestsInBackground?: boolean,
    options?: any
  ) {
    return DeploymentApiFp(this.configuration).deploy(
      accountId,
      projectId,
      botId,
      forceDeploy,
      skipNotifications,
      skipTests,
      runTestsInBackground,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List deploy configs and status
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getAllConfigs(accountId: number, projectId: string, options?: any) {
    return DeploymentApiFp(this.configuration).getAllConfigs(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get global deploy config from application.yml
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getDeployConfig(options?: any) {
    return DeploymentApiFp(this.configuration).getDeployConfig(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get deployment task info
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} taskId Bot identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getDeploymentTask(accountId: number, projectId: string, taskId: number, options?: any) {
    return DeploymentApiFp(this.configuration).getDeploymentTask(
      accountId,
      projectId,
      taskId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get deployment log for a task
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} taskId Bot identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getDeploymentTaskLog(accountId: number, projectId: string, taskId: number, options?: any) {
    return DeploymentApiFp(this.configuration).getDeploymentTaskLog(
      accountId,
      projectId,
      taskId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get deployment history for bot
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} botId Bot identifier.
   * @param {number} [page] Page.
   * @param {number} [size] Maximum number of items to return.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getHistoryByBot(
    accountId: number,
    projectId: string,
    botId: string,
    page?: number,
    size?: number,
    options?: any
  ) {
    return DeploymentApiFp(this.configuration).getHistoryByBot(
      accountId,
      projectId,
      botId,
      page,
      size,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get last deploy results for all bots by account.
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getLastDeployResultsForAccount(accountId: number, options?: any) {
    return DeploymentApiFp(this.configuration).getLastDeployResultsForAccount(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Get status for single bot
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} botId Bot identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getStatusByBot(accountId: number, projectId: string, botId: string, options?: any) {
    return DeploymentApiFp(this.configuration).getStatusByBot(
      accountId,
      projectId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get status for all bot configs
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public getStatusForAll(accountId: number, projectId: string, options?: any) {
    return DeploymentApiFp(this.configuration).getStatusForAll(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Run tests
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} botId Bot identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public runTests(accountId: number, projectId: string, botId: string, options?: any) {
    return DeploymentApiFp(this.configuration).runTests(
      accountId,
      projectId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update deploy configurations
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeploymentApi
   */
  public updateAllConfigs(
    accountId: number,
    projectId: string,
    deploymentBotConfigData: Array<DeploymentBotConfigData>,
    options?: any
  ) {
    return DeploymentApiFp(this.configuration).updateAllConfigs(
      accountId,
      projectId,
      deploymentBotConfigData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary E2ECheck
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.'
        );
      }
      const localVarPath = `/api/editorbe/e2e-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary E2ECheck
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(
      loggingEnabledE2ECheck: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<E2EComponent>> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary E2ECheck
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, options?: any): AxiosPromise<Array<E2EComponent>> {
      return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   *
   * @summary E2ECheck
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public e2ECheck(loggingEnabledE2ECheck: boolean, options?: any) {
    return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
  }
}

/**
 * EnvironmentVariableApi - axios parameter creator
 * @export
 */
export const EnvironmentVariableApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply environment variables to bots
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyEnvVars(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling applyEnvVars.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling applyEnvVars.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/apply`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createEnvVar.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createEnvVar.'
        );
      }
      // verify required parameter 'envVarName' is not null or undefined
      if (envVarName === null || envVarName === undefined) {
        throw new RequiredError(
          'envVarName',
          'Required parameter envVarName was null or undefined when calling createEnvVar.'
        );
      }
      // verify required parameter 'envVarRequest' is not null or undefined
      if (envVarRequest === null || envVarRequest === undefined) {
        throw new RequiredError(
          'envVarRequest',
          'Required parameter envVarRequest was null or undefined when calling createEnvVar.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'envVarName'}}`, encodeURIComponent(String(envVarName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof envVarRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(envVarRequest !== undefined ? envVarRequest : {})
        : envVarRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnvVar(accountId: number, projectId: string, envVarName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteEnvVar.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteEnvVar.'
        );
      }
      // verify required parameter 'envVarName' is not null or undefined
      if (envVarName === null || envVarName === undefined) {
        throw new RequiredError(
          'envVarName',
          'Required parameter envVarName was null or undefined when calling deleteEnvVar.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'envVarName'}}`, encodeURIComponent(String(envVarName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of environment variables
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnvVars(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getEnvVars.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getEnvVars.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateEnvVar.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateEnvVar.'
        );
      }
      // verify required parameter 'envVarName' is not null or undefined
      if (envVarName === null || envVarName === undefined) {
        throw new RequiredError(
          'envVarName',
          'Required parameter envVarName was null or undefined when calling updateEnvVar.'
        );
      }
      // verify required parameter 'envVarRequest' is not null or undefined
      if (envVarRequest === null || envVarRequest === undefined) {
        throw new RequiredError(
          'envVarRequest',
          'Required parameter envVarRequest was null or undefined when calling updateEnvVar.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'envVarName'}}`, encodeURIComponent(String(envVarName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof envVarRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(envVarRequest !== undefined ? envVarRequest : {})
        : envVarRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EnvironmentVariableApi - functional programming interface
 * @export
 */
export const EnvironmentVariableApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply environment variables to bots
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyEnvVars(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).applyEnvVars(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).createEnvVar(
        accountId,
        projectId,
        envVarName,
        envVarRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).deleteEnvVar(
        accountId,
        projectId,
        envVarName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of environment variables
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnvVars(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvVarResponse>> {
      const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).getEnvVars(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).updateEnvVar(
        accountId,
        projectId,
        envVarName,
        envVarRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EnvironmentVariableApi - factory interface
 * @export
 */
export const EnvironmentVariableApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Apply environment variables to bots
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyEnvVars(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return EnvironmentVariableApiFp(configuration).applyEnvVars(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Create environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options?: any
    ): AxiosPromise<void> {
      return EnvironmentVariableApiFp(configuration).createEnvVar(
        accountId,
        projectId,
        envVarName,
        envVarRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnvVar(accountId: number, projectId: string, envVarName: string, options?: any): AxiosPromise<void> {
      return EnvironmentVariableApiFp(configuration).deleteEnvVar(
        accountId,
        projectId,
        envVarName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get list of environment variables
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnvVars(accountId: number, projectId: string, options?: any): AxiosPromise<Array<EnvVarResponse>> {
      return EnvironmentVariableApiFp(configuration).getEnvVars(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnvVar(
      accountId: number,
      projectId: string,
      envVarName: string,
      envVarRequest: EnvVarRequest,
      options?: any
    ): AxiosPromise<void> {
      return EnvironmentVariableApiFp(configuration).updateEnvVar(
        accountId,
        projectId,
        envVarName,
        envVarRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * EnvironmentVariableApi - object-oriented interface
 * @export
 * @class EnvironmentVariableApi
 * @extends {BaseAPI}
 */
export class EnvironmentVariableApi extends BaseAPI {
  /**
   *
   * @summary Apply environment variables to bots
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnvironmentVariableApi
   */
  public applyEnvVars(accountId: number, projectId: string, options?: any) {
    return EnvironmentVariableApiFp(this.configuration).applyEnvVars(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create environment variable
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} envVarName Project environment variable name
   * @param {EnvVarRequest} envVarRequest Environment variable value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnvironmentVariableApi
   */
  public createEnvVar(
    accountId: number,
    projectId: string,
    envVarName: string,
    envVarRequest: EnvVarRequest,
    options?: any
  ) {
    return EnvironmentVariableApiFp(this.configuration).createEnvVar(
      accountId,
      projectId,
      envVarName,
      envVarRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete environment variable
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} envVarName Project environment variable name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnvironmentVariableApi
   */
  public deleteEnvVar(accountId: number, projectId: string, envVarName: string, options?: any) {
    return EnvironmentVariableApiFp(this.configuration).deleteEnvVar(
      accountId,
      projectId,
      envVarName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get list of environment variables
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnvironmentVariableApi
   */
  public getEnvVars(accountId: number, projectId: string, options?: any) {
    return EnvironmentVariableApiFp(this.configuration).getEnvVars(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update environment variable
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} envVarName Project environment variable name
   * @param {EnvVarRequest} envVarRequest Environment variable value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnvironmentVariableApi
   */
  public updateEnvVar(
    accountId: number,
    projectId: string,
    envVarName: string,
    envVarRequest: EnvVarRequest,
    options?: any
  ) {
    return EnvironmentVariableApiFp(this.configuration).updateEnvVar(
      accountId,
      projectId,
      envVarName,
      envVarRequest,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ForTestsApi - axios parameter creator
 * @export
 */
export const ForTestsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if editorbe has spesific feature
     * @param {number} accountId User identifier.
     * @param {string} feature Feature name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasFeature(accountId: number, feature: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling hasFeature.'
        );
      }
      // verify required parameter 'feature' is not null or undefined
      if (feature === null || feature === undefined) {
        throw new RequiredError('feature', 'Required parameter feature was null or undefined when calling hasFeature.');
      }
      const localVarPath = `/api/editorbe-for-test/accounts/{accountId}/has-feature/{feature}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'feature'}}`, encodeURIComponent(String(feature)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ForTestsApi - functional programming interface
 * @export
 */
export const ForTestsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if editorbe has spesific feature
     * @param {number} accountId User identifier.
     * @param {string} feature Feature name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasFeature(
      accountId: number,
      feature: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HasFeatureData> {
      const localVarAxiosArgs = ForTestsApiAxiosParamCreator(configuration).hasFeature(accountId, feature, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ForTestsApi - factory interface
 * @export
 */
export const ForTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Check if editorbe has spesific feature
     * @param {number} accountId User identifier.
     * @param {string} feature Feature name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasFeature(accountId: number, feature: string, options?: any): AxiosPromise<HasFeatureData> {
      return ForTestsApiFp(configuration).hasFeature(accountId, feature, options)(axios, basePath);
    },
  };
};

/**
 * ForTestsApi - object-oriented interface
 * @export
 * @class ForTestsApi
 * @extends {BaseAPI}
 */
export class ForTestsApi extends BaseAPI {
  /**
   *
   * @summary Check if editorbe has spesific feature
   * @param {number} accountId User identifier.
   * @param {string} feature Feature name.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForTestsApi
   */
  public hasFeature(accountId: number, feature: string, options?: any) {
    return ForTestsApiFp(this.configuration).hasFeature(accountId, feature, options)(this.axios, this.basePath);
  }
}

/**
 * GraphApi - axios parameter creator
 * @export
 */
export const GraphApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getGraph.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getGraph.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getGraph.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (modificationId !== undefined) {
        localVarQueryParameter['modificationId'] = modificationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GraphApi - functional programming interface
 * @export
 */
export const GraphApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphData> {
      const localVarAxiosArgs = GraphApiAxiosParamCreator(configuration).getGraph(
        accountId,
        projectId,
        file,
        modificationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GraphApi - factory interface
 * @export
 */
export const GraphApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options?: any
    ): AxiosPromise<GraphData> {
      return GraphApiFp(configuration).getGraph(accountId, projectId, file, modificationId, options)(axios, basePath);
    },
  };
};

/**
 * GraphApi - object-oriented interface
 * @export
 * @class GraphApi
 * @extends {BaseAPI}
 */
export class GraphApi extends BaseAPI {
  /**
   *
   * @summary graphRequest
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {string} [modificationId] Revision.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public getGraph(accountId: number, projectId: string, file: string, modificationId?: string, options?: any) {
    return GraphApiFp(this.configuration).getGraph(
      accountId,
      projectId,
      file,
      modificationId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * GraphV2Api - axios parameter creator
 * @export
 */
export const GraphV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileTree(accountId: number, projectId: string, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFileTree.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFileTree.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getFileTree.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2/tree`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [context] Context path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(accountId: number, projectId: string, file: string, context?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getGraph.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getGraph.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getGraph.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (context !== undefined) {
        localVarQueryParameter['context'] = context;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {GraphV2UpdateData} graphV2UpdateData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraph(
      accountId: number,
      projectId: string,
      graphV2UpdateData: GraphV2UpdateData,
      force?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateGraph.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateGraph.'
        );
      }
      // verify required parameter 'graphV2UpdateData' is not null or undefined
      if (graphV2UpdateData === null || graphV2UpdateData === undefined) {
        throw new RequiredError(
          'graphV2UpdateData',
          'Required parameter graphV2UpdateData was null or undefined when calling updateGraph.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2/update`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof graphV2UpdateData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(graphV2UpdateData !== undefined ? graphV2UpdateData : {})
        : graphV2UpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GraphV2Api - functional programming interface
 * @export
 */
export const GraphV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileTree(
      accountId: number,
      projectId: string,
      file: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphV2TreeData> {
      const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).getFileTree(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [context] Context path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      file: string,
      context?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphV2Data> {
      const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).getGraph(
        accountId,
        projectId,
        file,
        context,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {GraphV2UpdateData} graphV2UpdateData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraph(
      accountId: number,
      projectId: string,
      graphV2UpdateData: GraphV2UpdateData,
      force?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphV2Data> {
      const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).updateGraph(
        accountId,
        projectId,
        graphV2UpdateData,
        force,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GraphV2Api - factory interface
 * @export
 */
export const GraphV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileTree(accountId: number, projectId: string, file: string, options?: any): AxiosPromise<GraphV2TreeData> {
      return GraphV2ApiFp(configuration).getFileTree(accountId, projectId, file, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [context] Context path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      file: string,
      context?: string,
      options?: any
    ): AxiosPromise<GraphV2Data> {
      return GraphV2ApiFp(configuration).getGraph(accountId, projectId, file, context, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {GraphV2UpdateData} graphV2UpdateData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGraph(
      accountId: number,
      projectId: string,
      graphV2UpdateData: GraphV2UpdateData,
      force?: boolean,
      options?: any
    ): AxiosPromise<GraphV2Data> {
      return GraphV2ApiFp(configuration).updateGraph(
        accountId,
        projectId,
        graphV2UpdateData,
        force,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GraphV2Api - object-oriented interface
 * @export
 * @class GraphV2Api
 * @extends {BaseAPI}
 */
export class GraphV2Api extends BaseAPI {
  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphV2Api
   */
  public getFileTree(accountId: number, projectId: string, file: string, options?: any) {
    return GraphV2ApiFp(this.configuration).getFileTree(accountId, projectId, file, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {string} [context] Context path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphV2Api
   */
  public getGraph(accountId: number, projectId: string, file: string, context?: string, options?: any) {
    return GraphV2ApiFp(this.configuration).getGraph(
      accountId,
      projectId,
      file,
      context,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {GraphV2UpdateData} graphV2UpdateData
   * @param {boolean} [force] Force.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphV2Api
   */
  public updateGraph(
    accountId: number,
    projectId: string,
    graphV2UpdateData: GraphV2UpdateData,
    force?: boolean,
    options?: any
  ) {
    return GraphV2ApiFp(this.configuration).updateGraph(
      accountId,
      projectId,
      graphV2UpdateData,
      force,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Health check of mongo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options: any = {}): RequestArgs {
      const localVarPath = `/api/editorbe/health-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Health check of mongo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = HealthApiAxiosParamCreator(configuration).healthCheck(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Health check of mongo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return HealthApiFp(configuration).healthCheck(options)(axios, basePath);
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @summary Health check of mongo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public healthCheck(options?: any) {
    return HealthApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
  }
}

/**
 * JGraphApi - axios parameter creator
 * @export
 */
export const JGraphApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ComplexRenameStateData} complexRenameStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    complexRenameState(
      accountId: number,
      projectId: string,
      complexRenameStateData: ComplexRenameStateData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling complexRenameState.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling complexRenameState.'
        );
      }
      // verify required parameter 'complexRenameStateData' is not null or undefined
      if (complexRenameStateData === null || complexRenameStateData === undefined) {
        throw new RequiredError(
          'complexRenameStateData',
          'Required parameter complexRenameStateData was null or undefined when calling complexRenameState.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/complex-rename-state`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof complexRenameStateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(complexRenameStateData !== undefined ? complexRenameStateData : {})
        : complexRenameStateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateJStateData} createJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createState(
      accountId: number,
      projectId: string,
      createJStateData: CreateJStateData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createState.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createState.'
        );
      }
      // verify required parameter 'createJStateData' is not null or undefined
      if (createJStateData === null || createJStateData === undefined) {
        throw new RequiredError(
          'createJStateData',
          'Required parameter createJStateData was null or undefined when calling createState.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/create`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createJStateData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createJStateData !== undefined ? createJStateData : {})
        : createJStateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteJStateData} deleteJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteState(
      accountId: number,
      projectId: string,
      deleteJStateData: DeleteJStateData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteState.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteState.'
        );
      }
      // verify required parameter 'deleteJStateData' is not null or undefined
      if (deleteJStateData === null || deleteJStateData === undefined) {
        throw new RequiredError(
          'deleteJStateData',
          'Required parameter deleteJStateData was null or undefined when calling deleteState.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/delete`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof deleteJStateData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deleteJStateData !== undefined ? deleteJStateData : {})
        : deleteJStateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getGraph.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getGraph.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/get`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagLocators(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTagLocators.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getTagLocators.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/tagLocators`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JStateMovementsData} jStateMovementsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStates(
      accountId: number,
      projectId: string,
      jStateMovementsData: JStateMovementsData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling moveStates.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling moveStates.'
        );
      }
      // verify required parameter 'jStateMovementsData' is not null or undefined
      if (jStateMovementsData === null || jStateMovementsData === undefined) {
        throw new RequiredError(
          'jStateMovementsData',
          'Required parameter jStateMovementsData was null or undefined when calling moveStates.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/move`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof jStateMovementsData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(jStateMovementsData !== undefined ? jStateMovementsData : {})
        : jStateMovementsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameIntentData} renameIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameIntent(
      accountId: number,
      projectId: string,
      renameIntentData: RenameIntentData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling renameIntent.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling renameIntent.'
        );
      }
      // verify required parameter 'renameIntentData' is not null or undefined
      if (renameIntentData === null || renameIntentData === undefined) {
        throw new RequiredError(
          'renameIntentData',
          'Required parameter renameIntentData was null or undefined when calling renameIntent.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/rename-intent`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof renameIntentData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(renameIntentData !== undefined ? renameIntentData : {})
        : renameIntentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateJStateData} updateJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateState(
      accountId: number,
      projectId: string,
      updateJStateData: UpdateJStateData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateState.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateState.'
        );
      }
      // verify required parameter 'updateJStateData' is not null or undefined
      if (updateJStateData === null || updateJStateData === undefined) {
        throw new RequiredError(
          'updateJStateData',
          'Required parameter updateJStateData was null or undefined when calling updateState.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/update`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json; charset=UTF-8';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateJStateData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateJStateData !== undefined ? updateJStateData : {})
        : updateJStateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JGraphApi - functional programming interface
 * @export
 */
export const JGraphApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ComplexRenameStateData} complexRenameStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    complexRenameState(
      accountId: number,
      projectId: string,
      complexRenameStateData: ComplexRenameStateData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JGraphData> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).complexRenameState(
        accountId,
        projectId,
        complexRenameStateData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateJStateData} createJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createState(
      accountId: number,
      projectId: string,
      createJStateData: CreateJStateData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastModification> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).createState(
        accountId,
        projectId,
        createJStateData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteJStateData} deleteJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteState(
      accountId: number,
      projectId: string,
      deleteJStateData: DeleteJStateData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastModification> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).deleteState(
        accountId,
        projectId,
        deleteJStateData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JGraphData> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).getGraph(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagLocators(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JGraphLocators> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).getTagLocators(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JStateMovementsData} jStateMovementsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStates(
      accountId: number,
      projectId: string,
      jStateMovementsData: JStateMovementsData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).moveStates(
        accountId,
        projectId,
        jStateMovementsData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameIntentData} renameIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameIntent(
      accountId: number,
      projectId: string,
      renameIntentData: RenameIntentData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).renameIntent(
        accountId,
        projectId,
        renameIntentData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateJStateData} updateJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateState(
      accountId: number,
      projectId: string,
      updateJStateData: UpdateJStateData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateJStateResponseData> {
      const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).updateState(
        accountId,
        projectId,
        updateJStateData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * JGraphApi - factory interface
 * @export
 */
export const JGraphApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ComplexRenameStateData} complexRenameStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    complexRenameState(
      accountId: number,
      projectId: string,
      complexRenameStateData: ComplexRenameStateData,
      options?: any
    ): AxiosPromise<JGraphData> {
      return JGraphApiFp(configuration).complexRenameState(
        accountId,
        projectId,
        complexRenameStateData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateJStateData} createJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createState(
      accountId: number,
      projectId: string,
      createJStateData: CreateJStateData,
      options?: any
    ): AxiosPromise<LastModification> {
      return JGraphApiFp(configuration).createState(accountId, projectId, createJStateData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteJStateData} deleteJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteState(
      accountId: number,
      projectId: string,
      deleteJStateData: DeleteJStateData,
      options?: any
    ): AxiosPromise<LastModification> {
      return JGraphApiFp(configuration).deleteState(accountId, projectId, deleteJStateData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGraph(accountId: number, projectId: string, options?: any): AxiosPromise<JGraphData> {
      return JGraphApiFp(configuration).getGraph(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagLocators(accountId: number, projectId: string, options?: any): AxiosPromise<JGraphLocators> {
      return JGraphApiFp(configuration).getTagLocators(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JStateMovementsData} jStateMovementsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveStates(
      accountId: number,
      projectId: string,
      jStateMovementsData: JStateMovementsData,
      options?: any
    ): AxiosPromise<void> {
      return JGraphApiFp(configuration).moveStates(accountId, projectId, jStateMovementsData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameIntentData} renameIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameIntent(
      accountId: number,
      projectId: string,
      renameIntentData: RenameIntentData,
      options?: any
    ): AxiosPromise<void> {
      return JGraphApiFp(configuration).renameIntent(accountId, projectId, renameIntentData, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateJStateData} updateJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateState(
      accountId: number,
      projectId: string,
      updateJStateData: UpdateJStateData,
      options?: any
    ): AxiosPromise<UpdateJStateResponseData> {
      return JGraphApiFp(configuration).updateState(accountId, projectId, updateJStateData, options)(axios, basePath);
    },
  };
};

/**
 * JGraphApi - object-oriented interface
 * @export
 * @class JGraphApi
 * @extends {BaseAPI}
 */
export class JGraphApi extends BaseAPI {
  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ComplexRenameStateData} complexRenameStateData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public complexRenameState(
    accountId: number,
    projectId: string,
    complexRenameStateData: ComplexRenameStateData,
    options?: any
  ) {
    return JGraphApiFp(this.configuration).complexRenameState(
      accountId,
      projectId,
      complexRenameStateData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {CreateJStateData} createJStateData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public createState(accountId: number, projectId: string, createJStateData: CreateJStateData, options?: any) {
    return JGraphApiFp(this.configuration).createState(
      accountId,
      projectId,
      createJStateData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {DeleteJStateData} deleteJStateData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public deleteState(accountId: number, projectId: string, deleteJStateData: DeleteJStateData, options?: any) {
    return JGraphApiFp(this.configuration).deleteState(
      accountId,
      projectId,
      deleteJStateData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public getGraph(accountId: number, projectId: string, options?: any) {
    return JGraphApiFp(this.configuration).getGraph(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public getTagLocators(accountId: number, projectId: string, options?: any) {
    return JGraphApiFp(this.configuration).getTagLocators(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {JStateMovementsData} jStateMovementsData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public moveStates(accountId: number, projectId: string, jStateMovementsData: JStateMovementsData, options?: any) {
    return JGraphApiFp(this.configuration).moveStates(
      accountId,
      projectId,
      jStateMovementsData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {RenameIntentData} renameIntentData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public renameIntent(accountId: number, projectId: string, renameIntentData: RenameIntentData, options?: any) {
    return JGraphApiFp(this.configuration).renameIntent(
      accountId,
      projectId,
      renameIntentData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {UpdateJStateData} updateJStateData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JGraphApi
   */
  public updateState(accountId: number, projectId: string, updateJStateData: UpdateJStateData, options?: any) {
    return JGraphApiFp(this.configuration).updateState(
      accountId,
      projectId,
      updateJStateData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method for getting logs.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Date} from Start time for requesting logs. UTC.
     * @param {string} [botId] Bot identifier.
     * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
     * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
     * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
     * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(
      accountId: number,
      projectId: string,
      from: Date,
      botId?: string,
      to?: Date,
      level?: Array<ServerLogLevel>,
      module?: Array<ServerLogModule>,
      app?: Array<ServerLogApp>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLogs.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getLogs.'
        );
      }
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError('from', 'Required parameter from was null or undefined when calling getLogs.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/server-logs`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (botId !== undefined) {
        localVarQueryParameter['botId'] = botId;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (level) {
        localVarQueryParameter['level'] = level;
      }

      if (module) {
        localVarQueryParameter['module'] = module;
      }

      if (app) {
        localVarQueryParameter['app'] = app;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Method for getting logs.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Date} from Start time for requesting logs. UTC.
     * @param {string} [botId] Bot identifier.
     * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
     * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
     * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
     * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(
      accountId: number,
      projectId: string,
      from: Date,
      botId?: string,
      to?: Date,
      level?: Array<ServerLogLevel>,
      module?: Array<ServerLogModule>,
      app?: Array<ServerLogApp>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServerLog>> {
      const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).getLogs(
        accountId,
        projectId,
        from,
        botId,
        to,
        level,
        module,
        app,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Method for getting logs.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Date} from Start time for requesting logs. UTC.
     * @param {string} [botId] Bot identifier.
     * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
     * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
     * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
     * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogs(
      accountId: number,
      projectId: string,
      from: Date,
      botId?: string,
      to?: Date,
      level?: Array<ServerLogLevel>,
      module?: Array<ServerLogModule>,
      app?: Array<ServerLogApp>,
      options?: any
    ): AxiosPromise<Array<ServerLog>> {
      return LogsApiFp(configuration).getLogs(
        accountId,
        projectId,
        from,
        botId,
        to,
        level,
        module,
        app,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
  /**
   *
   * @summary Method for getting logs.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Date} from Start time for requesting logs. UTC.
   * @param {string} [botId] Bot identifier.
   * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
   * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
   * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
   * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getLogs(
    accountId: number,
    projectId: string,
    from: Date,
    botId?: string,
    to?: Date,
    level?: Array<ServerLogLevel>,
    module?: Array<ServerLogModule>,
    app?: Array<ServerLogApp>,
    options?: any
  ) {
    return LogsApiFp(this.configuration).getLogs(
      accountId,
      projectId,
      from,
      botId,
      to,
      level,
      module,
      app,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} newProjectId New project id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(accountId: number, projectId: string, newProjectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling clone.');
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling clone.');
      }
      // verify required parameter 'newProjectId' is not null or undefined
      if (newProjectId === null || newProjectId === undefined) {
        throw new RequiredError(
          'newProjectId',
          'Required parameter newProjectId was null or undefined when calling clone.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/clone`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (newProjectId !== undefined) {
        localVarQueryParameter['newProjectId'] = newProjectId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(accountId: number, createProjectData: CreateProjectData, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createProject.'
        );
      }
      // verify required parameter 'createProjectData' is not null or undefined
      if (createProjectData === null || createProjectData === undefined) {
        throw new RequiredError(
          'createProjectData',
          'Required parameter createProjectData was null or undefined when calling createProject.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createProjectData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createProjectData !== undefined ? createProjectData : {})
        : createProjectData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new state in root theme inside entrypoint
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateRootStateRequest} createRootStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRootState(
      accountId: number,
      projectId: string,
      createRootStateRequest: CreateRootStateRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createRootState.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling createRootState.'
        );
      }
      // verify required parameter 'createRootStateRequest' is not null or undefined
      if (createRootStateRequest === null || createRootStateRequest === undefined) {
        throw new RequiredError(
          'createRootStateRequest',
          'Required parameter createRootStateRequest was null or undefined when calling createRootState.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/scenario/state`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createRootStateRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createRootStateRequest !== undefined ? createRootStateRequest : {})
        : createRootStateRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteProject.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryForAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getHistoryForAccount.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/content/history`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProject.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectSettings.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProjectSettings.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/settings`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (snapshot !== undefined) {
        localVarQueryParameter['snapshot'] = snapshot;
      }

      if (refType !== undefined) {
        localVarQueryParameter['refType'] = refType;
      }

      if (refValue !== undefined) {
        localVarQueryParameter['refValue'] = refValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project statistic.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatistic(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectStatistic.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProjectStatistic.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/statistic`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatus(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getProjectStatus.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getProjectStatus.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get tags list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {TagsListFilter} [tagsListFilter] Tags list filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagsList(accountId: number, projectId: string, tagsListFilter?: TagsListFilter, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTagsList.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getTagsList.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/scenario/tags`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof tagsListFilter !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagsListFilter !== undefined ? tagsListFilter : {})
        : tagsListFilter || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single template by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(accountId: number, templateName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTemplate.'
        );
      }
      // verify required parameter 'templateName' is not null or undefined
      if (templateName === null || templateName === undefined) {
        throw new RequiredError(
          'templateName',
          'Required parameter templateName was null or undefined when calling getTemplate.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'templateName'}}`, encodeURIComponent(String(templateName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single template description by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateDescription(accountId: number, templateName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTemplateDescription.'
        );
      }
      // verify required parameter 'templateName' is not null or undefined
      if (templateName === null || templateName === undefined) {
        throw new RequiredError(
          'templateName',
          'Required parameter templateName was null or undefined when calling getTemplateDescription.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}/description`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'templateName'}}`, encodeURIComponent(String(templateName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single template variables by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateVariables(accountId: number, templateName: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTemplateVariables.'
        );
      }
      // verify required parameter 'templateName' is not null or undefined
      if (templateName === null || templateName === undefined) {
        throw new RequiredError(
          'templateName',
          'Required parameter templateName was null or undefined when calling getTemplateVariables.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}/variables`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'templateName'}}`, encodeURIComponent(String(templateName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Custom blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomBlocks(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listCustomBlocks.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listCustomBlocks.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-blocks`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (snapshot !== undefined) {
        localVarQueryParameter['snapshot'] = snapshot;
      }

      if (refType !== undefined) {
        localVarQueryParameter['refType'] = refType;
      }

      if (refValue !== undefined) {
        localVarQueryParameter['refValue'] = refValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomTags(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listCustomTags.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling listCustomTags.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-tags`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listProjects.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List templates
     * @param {number} accountId User identifier.
     * @param {string} namespace Namespace.
     * @param {string} [language] Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTemplates(accountId: number, namespace: string, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listTemplates.'
        );
      }
      // verify required parameter 'namespace' is not null or undefined
      if (namespace === null || namespace === undefined) {
        throw new RequiredError(
          'namespace',
          'Required parameter namespace was null or undefined when calling listTemplates.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/templates`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(projectId: string, accountId: number, projectData: ProjectData, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateProject.'
        );
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateProject.'
        );
      }
      // verify required parameter 'projectData' is not null or undefined
      if (projectData === null || projectData === undefined) {
        throw new RequiredError(
          'projectData',
          'Required parameter projectData was null or undefined when calling updateProject.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof projectData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(projectData !== undefined ? projectData : {})
        : projectData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsUserPreferences(
      accountId: number,
      projectId: string,
      customTagPreferencesData: Array<CustomTagPreferencesData>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateTagsUserPreferences.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateTagsUserPreferences.'
        );
      }
      // verify required parameter 'customTagPreferencesData' is not null or undefined
      if (customTagPreferencesData === null || customTagPreferencesData === undefined) {
        throw new RequiredError(
          'customTagPreferencesData',
          'Required parameter customTagPreferencesData was null or undefined when calling updateTagsUserPreferences.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-tags/preferences`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof customTagPreferencesData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(customTagPreferencesData !== undefined ? customTagPreferencesData : {})
        : customTagPreferencesData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} newProjectId New project id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(
      accountId: number,
      projectId: string,
      newProjectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).clone(
        accountId,
        projectId,
        newProjectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(
      accountId: number,
      createProjectData: CreateProjectData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).createProject(
        accountId,
        createProjectData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create new state in root theme inside entrypoint
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateRootStateRequest} createRootStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRootState(
      accountId: number,
      projectId: string,
      createRootStateRequest: CreateRootStateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).createRootState(
        accountId,
        projectId,
        createRootStateRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).deleteProject(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryForAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LastModificationLogForProject>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getHistoryForAccount(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProject(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettings> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectSettings(
        accountId,
        projectId,
        snapshot,
        refType,
        refValue,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project statistic.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatistic(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectStatisticData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectStatistic(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatus(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectStatusData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectStatus(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get tags list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {TagsListFilter} [tagsListFilter] Tags list filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagsList(
      accountId: number,
      projectId: string,
      tagsListFilter?: TagsListFilter,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagsListEntry>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTagsList(
        accountId,
        projectId,
        tagsListFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get single template by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(
      accountId: number,
      templateName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotTemplateData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplate(
        accountId,
        templateName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get single template description by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateDescription(
      accountId: number,
      templateName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplateDescription(
        accountId,
        templateName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get single template variables by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateVariables(
      accountId: number,
      templateName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotTemplateVariables> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplateVariables(
        accountId,
        templateName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Custom blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomBlocks(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listCustomBlocks(
        accountId,
        projectId,
        snapshot,
        refType,
        refValue,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomTags(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomTagData>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listCustomTags(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectData>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listProjects(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List templates
     * @param {number} accountId User identifier.
     * @param {string} namespace Namespace.
     * @param {string} [language] Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTemplates(
      accountId: number,
      namespace: string,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotTemplateData>> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listTemplates(
        accountId,
        namespace,
        language,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      accountId: number,
      projectData: ProjectData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectData> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).updateProject(
        projectId,
        accountId,
        projectData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsUserPreferences(
      accountId: number,
      projectId: string,
      customTagPreferencesData: Array<CustomTagPreferencesData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).updateTagsUserPreferences(
        accountId,
        projectId,
        customTagPreferencesData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} newProjectId New project id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(accountId: number, projectId: string, newProjectId: string, options?: any): AxiosPromise<ProjectData> {
      return ProjectApiFp(configuration).clone(accountId, projectId, newProjectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(accountId: number, createProjectData: CreateProjectData, options?: any): AxiosPromise<ProjectData> {
      return ProjectApiFp(configuration).createProject(accountId, createProjectData, options)(axios, basePath);
    },
    /**
     *
     * @summary Create new state in root theme inside entrypoint
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateRootStateRequest} createRootStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRootState(
      accountId: number,
      projectId: string,
      createRootStateRequest: CreateRootStateRequest,
      options?: any
    ): AxiosPromise<void> {
      return ProjectApiFp(configuration).createRootState(
        accountId,
        projectId,
        createRootStateRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return ProjectApiFp(configuration).deleteProject(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryForAccount(accountId: number, options?: any): AxiosPromise<Array<LastModificationLogForProject>> {
      return ProjectApiFp(configuration).getHistoryForAccount(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(accountId: number, projectId: string, options?: any): AxiosPromise<ProjectData> {
      return ProjectApiFp(configuration).getProject(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectSettings(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options?: any
    ): AxiosPromise<ProjectSettings> {
      return ProjectApiFp(configuration).getProjectSettings(
        accountId,
        projectId,
        snapshot,
        refType,
        refValue,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get project statistic.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatistic(accountId: number, projectId: string, options?: any): AxiosPromise<ProjectStatisticData> {
      return ProjectApiFp(configuration).getProjectStatistic(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectStatus(accountId: number, projectId: string, options?: any): AxiosPromise<ProjectStatusData> {
      return ProjectApiFp(configuration).getProjectStatus(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get tags list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {TagsListFilter} [tagsListFilter] Tags list filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagsList(
      accountId: number,
      projectId: string,
      tagsListFilter?: TagsListFilter,
      options?: any
    ): AxiosPromise<Array<TagsListEntry>> {
      return ProjectApiFp(configuration).getTagsList(accountId, projectId, tagsListFilter, options)(axios, basePath);
    },
    /**
     *
     * @summary Get single template by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(accountId: number, templateName: string, options?: any): AxiosPromise<BotTemplateData> {
      return ProjectApiFp(configuration).getTemplate(accountId, templateName, options)(axios, basePath);
    },
    /**
     *
     * @summary Get single template description by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateDescription(accountId: number, templateName: string, options?: any): AxiosPromise<string> {
      return ProjectApiFp(configuration).getTemplateDescription(accountId, templateName, options)(axios, basePath);
    },
    /**
     *
     * @summary Get single template variables by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateVariables(accountId: number, templateName: string, options?: any): AxiosPromise<BotTemplateVariables> {
      return ProjectApiFp(configuration).getTemplateVariables(accountId, templateName, options)(axios, basePath);
    },
    /**
     *
     * @summary Custom blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomBlocks(
      accountId: number,
      projectId: string,
      snapshot?: boolean,
      refType?: string,
      refValue?: string,
      options?: any
    ): AxiosPromise<Array<object>> {
      return ProjectApiFp(configuration).listCustomBlocks(
        accountId,
        projectId,
        snapshot,
        refType,
        refValue,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary List custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomTags(accountId: number, projectId: string, options?: any): AxiosPromise<Array<CustomTagData>> {
      return ProjectApiFp(configuration).listCustomTags(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listProjects(accountId: number, options?: any): AxiosPromise<Array<ProjectData>> {
      return ProjectApiFp(configuration).listProjects(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary List templates
     * @param {number} accountId User identifier.
     * @param {string} namespace Namespace.
     * @param {string} [language] Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTemplates(
      accountId: number,
      namespace: string,
      language?: string,
      options?: any
    ): AxiosPromise<Array<BotTemplateData>> {
      return ProjectApiFp(configuration).listTemplates(accountId, namespace, language, options)(axios, basePath);
    },
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      accountId: number,
      projectData: ProjectData,
      options?: any
    ): AxiosPromise<ProjectData> {
      return ProjectApiFp(configuration).updateProject(projectId, accountId, projectData, options)(axios, basePath);
    },
    /**
     *
     * @summary Update custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsUserPreferences(
      accountId: number,
      projectId: string,
      customTagPreferencesData: Array<CustomTagPreferencesData>,
      options?: any
    ): AxiosPromise<void> {
      return ProjectApiFp(configuration).updateTagsUserPreferences(
        accountId,
        projectId,
        customTagPreferencesData,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
  /**
   *
   * @summary Create project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} newProjectId New project id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public clone(accountId: number, projectId: string, newProjectId: string, options?: any) {
    return ProjectApiFp(this.configuration).clone(
      accountId,
      projectId,
      newProjectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create project
   * @param {number} accountId User identifier.
   * @param {CreateProjectData} createProjectData New project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public createProject(accountId: number, createProjectData: CreateProjectData, options?: any) {
    return ProjectApiFp(this.configuration).createProject(
      accountId,
      createProjectData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create new state in root theme inside entrypoint
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {CreateRootStateRequest} createRootStateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public createRootState(
    accountId: number,
    projectId: string,
    createRootStateRequest: CreateRootStateRequest,
    options?: any
  ) {
    return ProjectApiFp(this.configuration).createRootState(
      accountId,
      projectId,
      createRootStateRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete project
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public deleteProject(accountId: number, projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).deleteProject(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary history
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getHistoryForAccount(accountId: number, options?: any) {
    return ProjectApiFp(this.configuration).getHistoryForAccount(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getProject(accountId: number, projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).getProject(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [snapshot] Snapshot flag.
   * @param {string} [refType] Can be [revision, tag, branch].
   * @param {string} [refValue] refValue
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getProjectSettings(
    accountId: number,
    projectId: string,
    snapshot?: boolean,
    refType?: string,
    refValue?: string,
    options?: any
  ) {
    return ProjectApiFp(this.configuration).getProjectSettings(
      accountId,
      projectId,
      snapshot,
      refType,
      refValue,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project statistic.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getProjectStatistic(accountId: number, projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).getProjectStatistic(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getProjectStatus(accountId: number, projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).getProjectStatus(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get tags list
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {TagsListFilter} [tagsListFilter] Tags list filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTagsList(accountId: number, projectId: string, tagsListFilter?: TagsListFilter, options?: any) {
    return ProjectApiFp(this.configuration).getTagsList(
      accountId,
      projectId,
      tagsListFilter,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get single template by name
   * @param {number} accountId User identifier.
   * @param {string} templateName Template identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTemplate(accountId: number, templateName: string, options?: any) {
    return ProjectApiFp(this.configuration).getTemplate(accountId, templateName, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get single template description by name
   * @param {number} accountId User identifier.
   * @param {string} templateName Template identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTemplateDescription(accountId: number, templateName: string, options?: any) {
    return ProjectApiFp(this.configuration).getTemplateDescription(
      accountId,
      templateName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get single template variables by name
   * @param {number} accountId User identifier.
   * @param {string} templateName Template identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public getTemplateVariables(accountId: number, templateName: string, options?: any) {
    return ProjectApiFp(this.configuration).getTemplateVariables(
      accountId,
      templateName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Custom blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [snapshot] Snapshot flag.
   * @param {string} [refType] Can be [revision, tag, branch].
   * @param {string} [refValue] refValue
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public listCustomBlocks(
    accountId: number,
    projectId: string,
    snapshot?: boolean,
    refType?: string,
    refValue?: string,
    options?: any
  ) {
    return ProjectApiFp(this.configuration).listCustomBlocks(
      accountId,
      projectId,
      snapshot,
      refType,
      refValue,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary List custom tags
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public listCustomTags(accountId: number, projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).listCustomTags(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Return list of projects
   * @param {number} accountId User identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public listProjects(accountId: number, options?: any) {
    return ProjectApiFp(this.configuration).listProjects(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary List templates
   * @param {number} accountId User identifier.
   * @param {string} namespace Namespace.
   * @param {string} [language] Language.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public listTemplates(accountId: number, namespace: string, language?: string, options?: any) {
    return ProjectApiFp(this.configuration).listTemplates(
      accountId,
      namespace,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update project.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {number} accountId User identifier.
   * @param {ProjectData} projectData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public updateProject(projectId: string, accountId: number, projectData: ProjectData, options?: any) {
    return ProjectApiFp(this.configuration).updateProject(
      projectId,
      accountId,
      projectData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update custom tags
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public updateTagsUserPreferences(
    accountId: number,
    projectId: string,
    customTagPreferencesData: Array<CustomTagPreferencesData>,
    options?: any
  ) {
    return ProjectApiFp(this.configuration).updateTagsUserPreferences(
      accountId,
      projectId,
      customTagPreferencesData,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Run migration to elasticsearch for all projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateToElasticsearch(options: any = {}): RequestArgs {
      const localVarPath = `/api/editorbe-for-test/migrate-to-elasticsearch`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replace in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ReplaceRequest} replaceRequest Replace in all files request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replace(accountId: number, projectId: string, replaceRequest: ReplaceRequest, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling replace.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling replace.'
        );
      }
      // verify required parameter 'replaceRequest' is not null or undefined
      if (replaceRequest === null || replaceRequest === undefined) {
        throw new RequiredError(
          'replaceRequest',
          'Required parameter replaceRequest was null or undefined when calling replace.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/replace`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof replaceRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(replaceRequest !== undefined ? replaceRequest : {})
        : replaceRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary search in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling search.');
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling search.');
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError('query', 'Required parameter query was null or undefined when calling search.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/search`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (fileExtensions) {
        localVarQueryParameter['fileExtensions'] = fileExtensions;
      }

      if (isRegex !== undefined) {
        localVarQueryParameter['isRegex'] = isRegex;
      }

      if (isCaseSensitive !== undefined) {
        localVarQueryParameter['isCaseSensitive'] = isCaseSensitive;
      }

      if (isWholeWord !== undefined) {
        localVarQueryParameter['isWholeWord'] = isWholeWord;
      }

      if (isHiddenFiles !== undefined) {
        localVarQueryParameter['isHiddenFiles'] = isHiddenFiles;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary whole search stats for all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchStatistics(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling searchStatistics.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling searchStatistics.'
        );
      }
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchStatistics.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/search/stats`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (fileExtensions) {
        localVarQueryParameter['fileExtensions'] = fileExtensions;
      }

      if (isRegex !== undefined) {
        localVarQueryParameter['isRegex'] = isRegex;
      }

      if (isCaseSensitive !== undefined) {
        localVarQueryParameter['isCaseSensitive'] = isCaseSensitive;
      }

      if (isWholeWord !== undefined) {
        localVarQueryParameter['isWholeWord'] = isWholeWord;
      }

      if (isHiddenFiles !== undefined) {
        localVarQueryParameter['isHiddenFiles'] = isHiddenFiles;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Run migration to elasticsearch for all projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateToElasticsearch(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).migrateToElasticsearch(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Replace in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ReplaceRequest} replaceRequest Replace in all files request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replace(
      accountId: number,
      projectId: string,
      replaceRequest: ReplaceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).replace(
        accountId,
        projectId,
        replaceRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary search in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResult> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).search(
        accountId,
        projectId,
        query,
        fileExtensions,
        isRegex,
        isCaseSensitive,
        isWholeWord,
        isHiddenFiles,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary whole search stats for all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchStatistics(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchStatistics> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).searchStatistics(
        accountId,
        projectId,
        query,
        fileExtensions,
        isRegex,
        isCaseSensitive,
        isWholeWord,
        isHiddenFiles,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Run migration to elasticsearch for all projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateToElasticsearch(options?: any): AxiosPromise<void> {
      return SearchApiFp(configuration).migrateToElasticsearch(options)(axios, basePath);
    },
    /**
     *
     * @summary Replace in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ReplaceRequest} replaceRequest Replace in all files request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replace(accountId: number, projectId: string, replaceRequest: ReplaceRequest, options?: any): AxiosPromise<void> {
      return SearchApiFp(configuration).replace(accountId, projectId, replaceRequest, options)(axios, basePath);
    },
    /**
     *
     * @summary search in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options?: any
    ): AxiosPromise<SearchResult> {
      return SearchApiFp(configuration).search(
        accountId,
        projectId,
        query,
        fileExtensions,
        isRegex,
        isCaseSensitive,
        isWholeWord,
        isHiddenFiles,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary whole search stats for all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchStatistics(
      accountId: number,
      projectId: string,
      query: string,
      fileExtensions?: Array<string>,
      isRegex?: boolean,
      isCaseSensitive?: boolean,
      isWholeWord?: boolean,
      isHiddenFiles?: boolean,
      options?: any
    ): AxiosPromise<SearchStatistics> {
      return SearchApiFp(configuration).searchStatistics(
        accountId,
        projectId,
        query,
        fileExtensions,
        isRegex,
        isCaseSensitive,
        isWholeWord,
        isHiddenFiles,
        options
      )(axios, basePath);
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   *
   * @summary Run migration to elasticsearch for all projects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public migrateToElasticsearch(options?: any) {
    return SearchApiFp(this.configuration).migrateToElasticsearch(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Replace in all files
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {ReplaceRequest} replaceRequest Replace in all files request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public replace(accountId: number, projectId: string, replaceRequest: ReplaceRequest, options?: any) {
    return SearchApiFp(this.configuration).replace(
      accountId,
      projectId,
      replaceRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary search in all files
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} query
   * @param {Array<string>} [fileExtensions]
   * @param {boolean} [isRegex]
   * @param {boolean} [isCaseSensitive]
   * @param {boolean} [isWholeWord]
   * @param {boolean} [isHiddenFiles]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public search(
    accountId: number,
    projectId: string,
    query: string,
    fileExtensions?: Array<string>,
    isRegex?: boolean,
    isCaseSensitive?: boolean,
    isWholeWord?: boolean,
    isHiddenFiles?: boolean,
    options?: any
  ) {
    return SearchApiFp(this.configuration).search(
      accountId,
      projectId,
      query,
      fileExtensions,
      isRegex,
      isCaseSensitive,
      isWholeWord,
      isHiddenFiles,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary whole search stats for all files
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} query
   * @param {Array<string>} [fileExtensions]
   * @param {boolean} [isRegex]
   * @param {boolean} [isCaseSensitive]
   * @param {boolean} [isWholeWord]
   * @param {boolean} [isHiddenFiles]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchStatistics(
    accountId: number,
    projectId: string,
    query: string,
    fileExtensions?: Array<string>,
    isRegex?: boolean,
    isCaseSensitive?: boolean,
    isWholeWord?: boolean,
    isHiddenFiles?: boolean,
    options?: any
  ) {
    return SearchApiFp(this.configuration).searchStatistics(
      accountId,
      projectId,
      query,
      fileExtensions,
      isRegex,
      isCaseSensitive,
      isWholeWord,
      isHiddenFiles,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Update project content for backward compatibility with BotAdmin
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {BatchUpdateContentData} batchUpdateContentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(
      accountId: number,
      projectId: string,
      batchUpdateContentData: BatchUpdateContentData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling batchUpdate.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling batchUpdate.'
        );
      }
      // verify required parameter 'batchUpdateContentData' is not null or undefined
      if (batchUpdateContentData === null || batchUpdateContentData === undefined) {
        throw new RequiredError(
          'batchUpdateContentData',
          'Required parameter batchUpdateContentData was null or undefined when calling batchUpdate.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/batch-update`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof batchUpdateContentData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(batchUpdateContentData !== undefined ? batchUpdateContentData : {})
        : batchUpdateContentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cleanup mirror folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanupMirrorFolder(options: any = {}): RequestArgs {
      const localVarPath = `/api/editorbe-for-test/cleanup-mirror-folder`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Push
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CommitAndPushData} commitAndPushData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAndPush(
      accountId: number,
      projectId: string,
      commitAndPushData: CommitAndPushData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling commitAndPush.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling commitAndPush.'
        );
      }
      // verify required parameter 'commitAndPushData' is not null or undefined
      if (commitAndPushData === null || commitAndPushData === undefined) {
        throw new RequiredError(
          'commitAndPushData',
          'Required parameter commitAndPushData was null or undefined when calling commitAndPush.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/push`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof commitAndPushData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(commitAndPushData !== undefined ? commitAndPushData : {})
        : commitAndPushData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Copy file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling copyFileOrFolder.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling copyFileOrFolder.'
        );
      }
      // verify required parameter 'renameFileData' is not null or undefined
      if (renameFileData === null || renameFileData === undefined) {
        throw new RequiredError(
          'renameFileData',
          'Required parameter renameFileData was null or undefined when calling copyFileOrFolder.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file/copy`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof renameFileData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(renameFileData !== undefined ? renameFileData : {})
        : renameFileData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(accountId: number, projectId: string, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteFile.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteFile.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling deleteFile.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete folder
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} folder Folder path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder(accountId: number, projectId: string, folder: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteFolder.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteFolder.'
        );
      }
      // verify required parameter 'folder' is not null or undefined
      if (folder === null || folder === undefined) {
        throw new RequiredError('folder', 'Required parameter folder was null or undefined when calling deleteFolder.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/folder`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (folder !== undefined) {
        localVarQueryParameter['folder'] = folder;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset project state to the previous sync state
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(accountId: number, projectId: string, modificationId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling discardChanges.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling discardChanges.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/reset`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (modificationId !== undefined) {
        localVarQueryParameter['modificationId'] = modificationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export zip archive with bot content.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportZip(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling exportZip.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling exportZip.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/export`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get binary file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinaryFile(accountId: number, projectId: string, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getBinaryFile.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getBinaryFile.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getBinaryFile.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/binaryFile`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary branches
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getBranches.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getBranches.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/branches`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {number} [lastModified] Last modified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFile(accountId: number, projectId: string, file: string, lastModified?: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFile.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFile.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling getFile.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (lastModified !== undefined) {
        localVarQueryParameter['lastModified'] = lastModified;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileByRevision(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFileByRevision.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getFileByRevision.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling getFileByRevision.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file-by-revision`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (modificationId !== undefined) {
        localVarQueryParameter['modificationId'] = modificationId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getHistory.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getHistory.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/history`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary refs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefs(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getRefs.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getRefs.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/refs`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get file list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTree(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getTree.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getTree.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/tree`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importZip(accountId: number, projectId: string, commit?: boolean, file?: any, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling importZip.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling importZip.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/import`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (commit !== undefined) {
        localVarQueryParameter['commit'] = commit;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pull(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling pull.');
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError('projectId', 'Required parameter projectId was null or undefined when calling pull.');
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/pull`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Rename file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling renameFileOrFolder.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling renameFileOrFolder.'
        );
      }
      // verify required parameter 'renameFileData' is not null or undefined
      if (renameFileData === null || renameFileData === undefined) {
        throw new RequiredError(
          'renameFileData',
          'Required parameter renameFileData was null or undefined when calling renameFileOrFolder.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file/rename`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof renameFileData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(renameFileData !== undefined ? renameFileData : {})
        : renameFileData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update file content
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {FileContentData} fileContentData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeFile(
      accountId: number,
      projectId: string,
      file: string,
      fileContentData: FileContentData,
      force?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling writeFile.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling writeFile.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling writeFile.');
      }
      // verify required parameter 'fileContentData' is not null or undefined
      if (fileContentData === null || fileContentData === undefined) {
        throw new RequiredError(
          'fileContentData',
          'Required parameter fileContentData was null or undefined when calling writeFile.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (file !== undefined) {
        localVarQueryParameter['file'] = file;
      }

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof fileContentData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fileContentData !== undefined ? fileContentData : {})
        : fileContentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Update project content for backward compatibility with BotAdmin
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {BatchUpdateContentData} batchUpdateContentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(
      accountId: number,
      projectId: string,
      batchUpdateContentData: BatchUpdateContentData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).batchUpdate(
        accountId,
        projectId,
        batchUpdateContentData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cleanup mirror folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanupMirrorFolder(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).cleanupMirrorFolder(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Push
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CommitAndPushData} commitAndPushData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAndPush(
      accountId: number,
      projectId: string,
      commitAndPushData: CommitAndPushData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).commitAndPush(
        accountId,
        projectId,
        commitAndPushData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Copy file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileTreeData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).copyFileOrFolder(
        accountId,
        projectId,
        renameFileData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(
      accountId: number,
      projectId: string,
      file: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).deleteFile(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete folder
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} folder Folder path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder(
      accountId: number,
      projectId: string,
      folder: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).deleteFolder(
        accountId,
        projectId,
        folder,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reset project state to the previous sync state
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(
      accountId: number,
      projectId: string,
      modificationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).discardChanges(
        accountId,
        projectId,
        modificationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export zip archive with bot content.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportZip(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).exportZip(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get binary file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinaryFile(
      accountId: number,
      projectId: string,
      file: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getBinaryFile(
        accountId,
        projectId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary branches
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getBranches(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {number} [lastModified] Last modified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFile(
      accountId: number,
      projectId: string,
      file: string,
      lastModified?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileContentData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getFile(
        accountId,
        projectId,
        file,
        lastModified,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileByRevision(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileContentData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getFileByRevision(
        accountId,
        projectId,
        file,
        modificationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModificationLogData>> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getHistory(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary refs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefs(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefsData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getRefs(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get file list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTree(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileTreeData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getTree(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importZip(
      accountId: number,
      projectId: string,
      commit?: boolean,
      file?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).importZip(
        accountId,
        projectId,
        commit,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pull(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).pull(accountId, projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Rename file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileTreeData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).renameFileOrFolder(
        accountId,
        projectId,
        renameFileData,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update file content
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {FileContentData} fileContentData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeFile(
      accountId: number,
      projectId: string,
      file: string,
      fileContentData: FileContentData,
      force?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileContentData> {
      const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).writeFile(
        accountId,
        projectId,
        file,
        fileContentData,
        force,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Update project content for backward compatibility with BotAdmin
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {BatchUpdateContentData} batchUpdateContentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(
      accountId: number,
      projectId: string,
      batchUpdateContentData: BatchUpdateContentData,
      options?: any
    ): AxiosPromise<void> {
      return StorageApiFp(configuration).batchUpdate(
        accountId,
        projectId,
        batchUpdateContentData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Cleanup mirror folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanupMirrorFolder(options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).cleanupMirrorFolder(options)(axios, basePath);
    },
    /**
     *
     * @summary Push
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CommitAndPushData} commitAndPushData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAndPush(
      accountId: number,
      projectId: string,
      commitAndPushData: CommitAndPushData,
      options?: any
    ): AxiosPromise<void> {
      return StorageApiFp(configuration).commitAndPush(
        accountId,
        projectId,
        commitAndPushData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Copy file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options?: any
    ): AxiosPromise<FileTreeData> {
      return StorageApiFp(configuration).copyFileOrFolder(
        accountId,
        projectId,
        renameFileData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(accountId: number, projectId: string, file: string, options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).deleteFile(accountId, projectId, file, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete folder
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} folder Folder path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder(accountId: number, projectId: string, folder: string, options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).deleteFolder(accountId, projectId, folder, options)(axios, basePath);
    },
    /**
     *
     * @summary Reset project state to the previous sync state
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discardChanges(accountId: number, projectId: string, modificationId?: string, options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).discardChanges(accountId, projectId, modificationId, options)(axios, basePath);
    },
    /**
     *
     * @summary Export zip archive with bot content.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportZip(accountId: number, projectId: string, options?: any): AxiosPromise<any> {
      return StorageApiFp(configuration).exportZip(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get binary file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinaryFile(accountId: number, projectId: string, file: string, options?: any): AxiosPromise<any> {
      return StorageApiFp(configuration).getBinaryFile(accountId, projectId, file, options)(axios, basePath);
    },
    /**
     *
     * @summary branches
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranches(accountId: number, projectId: string, options?: any): AxiosPromise<Array<string>> {
      return StorageApiFp(configuration).getBranches(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {number} [lastModified] Last modified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFile(
      accountId: number,
      projectId: string,
      file: string,
      lastModified?: number,
      options?: any
    ): AxiosPromise<FileContentData> {
      return StorageApiFp(configuration).getFile(accountId, projectId, file, lastModified, options)(axios, basePath);
    },
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileByRevision(
      accountId: number,
      projectId: string,
      file: string,
      modificationId?: string,
      options?: any
    ): AxiosPromise<FileContentData> {
      return StorageApiFp(configuration).getFileByRevision(
        accountId,
        projectId,
        file,
        modificationId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(accountId: number, projectId: string, options?: any): AxiosPromise<Array<ModificationLogData>> {
      return StorageApiFp(configuration).getHistory(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary refs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRefs(accountId: number, projectId: string, options?: any): AxiosPromise<RefsData> {
      return StorageApiFp(configuration).getRefs(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get file list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTree(accountId: number, projectId: string, options?: any): AxiosPromise<FileTreeData> {
      return StorageApiFp(configuration).getTree(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importZip(accountId: number, projectId: string, commit?: boolean, file?: any, options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).importZip(accountId, projectId, commit, file, options)(axios, basePath);
    },
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pull(accountId: number, projectId: string, options?: any): AxiosPromise<void> {
      return StorageApiFp(configuration).pull(accountId, projectId, options)(axios, basePath);
    },
    /**
     *
     * @summary Rename file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renameFileOrFolder(
      accountId: number,
      projectId: string,
      renameFileData: RenameFileData,
      options?: any
    ): AxiosPromise<FileTreeData> {
      return StorageApiFp(configuration).renameFileOrFolder(
        accountId,
        projectId,
        renameFileData,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update file content
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {FileContentData} fileContentData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeFile(
      accountId: number,
      projectId: string,
      file: string,
      fileContentData: FileContentData,
      force?: boolean,
      options?: any
    ): AxiosPromise<FileContentData> {
      return StorageApiFp(configuration).writeFile(
        accountId,
        projectId,
        file,
        fileContentData,
        force,
        options
      )(axios, basePath);
    },
  };
};

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
  /**
   *
   * @summary Update project content for backward compatibility with BotAdmin
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {BatchUpdateContentData} batchUpdateContentData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public batchUpdate(
    accountId: number,
    projectId: string,
    batchUpdateContentData: BatchUpdateContentData,
    options?: any
  ) {
    return StorageApiFp(this.configuration).batchUpdate(
      accountId,
      projectId,
      batchUpdateContentData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Cleanup mirror folder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public cleanupMirrorFolder(options?: any) {
    return StorageApiFp(this.configuration).cleanupMirrorFolder(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Push
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {CommitAndPushData} commitAndPushData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public commitAndPush(accountId: number, projectId: string, commitAndPushData: CommitAndPushData, options?: any) {
    return StorageApiFp(this.configuration).commitAndPush(
      accountId,
      projectId,
      commitAndPushData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Copy file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {RenameFileData} renameFileData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public copyFileOrFolder(accountId: number, projectId: string, renameFileData: RenameFileData, options?: any) {
    return StorageApiFp(this.configuration).copyFileOrFolder(
      accountId,
      projectId,
      renameFileData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public deleteFile(accountId: number, projectId: string, file: string, options?: any) {
    return StorageApiFp(this.configuration).deleteFile(accountId, projectId, file, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete folder
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} folder Folder path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public deleteFolder(accountId: number, projectId: string, folder: string, options?: any) {
    return StorageApiFp(this.configuration).deleteFolder(
      accountId,
      projectId,
      folder,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Reset project state to the previous sync state
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} [modificationId] Revision.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public discardChanges(accountId: number, projectId: string, modificationId?: string, options?: any) {
    return StorageApiFp(this.configuration).discardChanges(
      accountId,
      projectId,
      modificationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export zip archive with bot content.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public exportZip(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).exportZip(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get binary file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getBinaryFile(accountId: number, projectId: string, file: string, options?: any) {
    return StorageApiFp(this.configuration).getBinaryFile(
      accountId,
      projectId,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary branches
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getBranches(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).getBranches(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {number} [lastModified] Last modified.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getFile(accountId: number, projectId: string, file: string, lastModified?: number, options?: any) {
    return StorageApiFp(this.configuration).getFile(
      accountId,
      projectId,
      file,
      lastModified,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {string} [modificationId] Revision.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getFileByRevision(accountId: number, projectId: string, file: string, modificationId?: string, options?: any) {
    return StorageApiFp(this.configuration).getFileByRevision(
      accountId,
      projectId,
      file,
      modificationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary history
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getHistory(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).getHistory(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary refs
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getRefs(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).getRefs(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get file list
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public getTree(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).getTree(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Pull
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public importZip(accountId: number, projectId: string, commit?: boolean, file?: any, options?: any) {
    return StorageApiFp(this.configuration).importZip(
      accountId,
      projectId,
      commit,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Pull
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public pull(accountId: number, projectId: string, options?: any) {
    return StorageApiFp(this.configuration).pull(accountId, projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Rename file
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {RenameFileData} renameFileData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public renameFileOrFolder(accountId: number, projectId: string, renameFileData: RenameFileData, options?: any) {
    return StorageApiFp(this.configuration).renameFileOrFolder(
      accountId,
      projectId,
      renameFileData,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update file content
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {string} file File path inside bot project
   * @param {FileContentData} fileContentData
   * @param {boolean} [force] Force.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageApi
   */
  public writeFile(
    accountId: number,
    projectId: string,
    file: string,
    fileContentData: FileContentData,
    force?: boolean,
    options?: any
  ) {
    return StorageApiFp(this.configuration).writeFile(
      accountId,
      projectId,
      file,
      fileContentData,
      force,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SystemEntityApi - axios parameter creator
 * @export
 */
export const SystemEntityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Download csv entity file
     * @param {string} entityName system entity name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadSystemEntity(entityName: string, options: any = {}): RequestArgs {
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling downloadSystemEntity.'
        );
      }
      const localVarPath = `/api/editorbe/system/entity/{entityName}.csv`.replace(
        `{${'entityName'}}`,
        encodeURIComponent(String(entityName))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemEntityApi - functional programming interface
 * @export
 */
export const SystemEntityApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Download csv entity file
     * @param {string} entityName system entity name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadSystemEntity(
      entityName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = SystemEntityApiAxiosParamCreator(configuration).downloadSystemEntity(
        entityName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemEntityApi - factory interface
 * @export
 */
export const SystemEntityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Download csv entity file
     * @param {string} entityName system entity name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadSystemEntity(entityName: string, options?: any): AxiosPromise<any> {
      return SystemEntityApiFp(configuration).downloadSystemEntity(entityName, options)(axios, basePath);
    },
  };
};

/**
 * SystemEntityApi - object-oriented interface
 * @export
 * @class SystemEntityApi
 * @extends {BaseAPI}
 */
export class SystemEntityApi extends BaseAPI {
  /**
   *
   * @summary Download csv entity file
   * @param {string} entityName system entity name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemEntityApi
   */
  public downloadSystemEntity(entityName: string, options?: any) {
    return SystemEntityApiFp(this.configuration).downloadSystemEntity(entityName, options)(this.axios, this.basePath);
  }
}

/**
 * ValidationApi - axios parameter creator
 * @export
 */
export const ValidationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Validates the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProject(accountId: number, projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling validateProject.'
        );
      }
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling validateProject.'
        );
      }
      const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/validate`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ValidationApi - functional programming interface
 * @export
 */
export const ValidationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Validates the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProject(
      accountId: number,
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResult> {
      const localVarAxiosArgs = ValidationApiAxiosParamCreator(configuration).validateProject(
        accountId,
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ValidationApi - factory interface
 * @export
 */
export const ValidationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Validates the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProject(accountId: number, projectId: string, options?: any): AxiosPromise<ValidationResult> {
      return ValidationApiFp(configuration).validateProject(accountId, projectId, options)(axios, basePath);
    },
  };
};

/**
 * ValidationApi - object-oriented interface
 * @export
 * @class ValidationApi
 * @extends {BaseAPI}
 */
export class ValidationApi extends BaseAPI {
  /**
   *
   * @summary Validates the project.
   * @param {number} accountId User identifier.
   * @param {string} projectId Project identifier. Use project-short-name here
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ValidationApi
   */
  public validateProject(accountId: number, projectId: string, options?: any) {
    return ValidationApiFp(this.configuration).validateProject(
      accountId,
      projectId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Notify system about repository push event.
     * @param {string} body Push data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(body: string, options: any = {}): RequestArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling notify.');
      }
      const localVarPath = `/api/editorbe/repository-webhook`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Notify system about repository push event.
     * @param {string} body Push data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(body: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = WebhooksApiAxiosParamCreator(configuration).notify(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Notify system about repository push event.
     * @param {string} body Push data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(body: string, options?: any): AxiosPromise<void> {
      return WebhooksApiFp(configuration).notify(body, options)(axios, basePath);
    },
  };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
  /**
   *
   * @summary Notify system about repository push event.
   * @param {string} body Push data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public notify(body: string, options?: any) {
    return WebhooksApiFp(this.configuration).notify(body, options)(this.axios, this.basePath);
  }
}
