import { LevelEnum, LoggerEnum, LogItem, TimeEnum, TimeEnumMS } from '../../model/LogItem';
import moment from 'moment';

const LEVELS_BY_MIN_LEVEL = {
  [LevelEnum.ALERT]: [LevelEnum.ALERT],
  [LevelEnum.CRITICAL]: [LevelEnum.ALERT, LevelEnum.CRITICAL],
  [LevelEnum.ERROR]: [LevelEnum.ALERT, LevelEnum.CRITICAL, LevelEnum.ERROR],
  [LevelEnum.WARNING]: [LevelEnum.ALERT, LevelEnum.CRITICAL, LevelEnum.ERROR, LevelEnum.WARNING],
  [LevelEnum.NOTICE]: [LevelEnum.ALERT, LevelEnum.CRITICAL, LevelEnum.ERROR, LevelEnum.WARNING, LevelEnum.NOTICE],
  [LevelEnum.DEBUG]: [
    LevelEnum.ALERT,
    LevelEnum.CRITICAL,
    LevelEnum.ERROR,
    LevelEnum.WARNING,
    LevelEnum.NOTICE,
    LevelEnum.DEBUG,
  ],
  [LevelEnum.INFO]: [
    LevelEnum.ALERT,
    LevelEnum.CRITICAL,
    LevelEnum.ERROR,
    LevelEnum.WARNING,
    LevelEnum.NOTICE,
    LevelEnum.DEBUG,
    LevelEnum.INFO,
  ],
};

export class Filter {
  textFilter: string = '';
  loggers: LoggerEnum[] = [];
  levels: LevelEnum[] = [];
  time?: TimeEnum;

  constructor(textFilter?: string, loggers?: LoggerEnum[], minLevel?: LevelEnum, time?: TimeEnum) {
    if (textFilter) this.textFilter = textFilter;
    if (loggers) this.loggers = loggers;
    if (minLevel) this.levels = LEVELS_BY_MIN_LEVEL[minLevel];
    if (time) this.time = time;
  }

  apply(logItems: LogItem[]) {
    let filteredItems = logItems;

    if (this.textFilter !== '') {
      filteredItems = filteredItems.filter(item => item.text.toUpperCase().includes(this.textFilter.toUpperCase()));
    }

    if (this.loggers.length > 0) {
      filteredItems = filteredItems.filter(item => this.loggers.includes(item.logger));
    }

    if (this.levels.length > 0) {
      filteredItems = filteredItems.filter(item => item.level && this.levels.includes(item.level));
    }

    if (this.time) {
      filteredItems = filteredItems.filter(item => {
        const now = moment();
        const logTimestamp = moment.utc(item.timestamp);

        if (this.time && now.diff(logTimestamp) < TimeEnumMS[this.time]) return true;
        return false;
      });
    }

    return filteredItems;
  }
}
