import keyboardJS, { KeyEvent } from 'keyboardjs';
import { useEffect, useCallback, useRef, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

export const GlobalSearchOpenedByButton$ = new BehaviorSubject<boolean>(false);
export const ToggleGlobalSearch$ = new Subject<void>();

export function useGlobalSearchVisibility() {
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    const open = (e?: KeyEvent) => {
      e?.preventDefault();
      setOpenSearch(true);
    };
    const close = (e?: KeyEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      GlobalSearchOpenedByButton$.next(false);
      setOpenSearch(false);
    };

    const sub = ToggleGlobalSearch$.subscribe(() =>
      setOpenSearch(prev => {
        const newValue = !prev;
        GlobalSearchOpenedByButton$.next(newValue);
        return newValue;
      })
    );

    keyboardJS.bind(['ctrl + f', 'command + f'], open);
    keyboardJS.bind('esc', close);

    return () => {
      sub.unsubscribe();
      keyboardJS.unbind(['ctrl + f', 'command + f'], open);
      keyboardJS.unbind('esc', close);
    };
  }, [setOpenSearch]);

  const setCloseSearch = useCallback(() => {
    setOpenSearch(false);
    GlobalSearchOpenedByButton$.next(false);
  }, [setOpenSearch]);

  return { openSearch, setCloseSearch };
}

export function useSearchRowKeyboardNavigation(onSelect: (index: number) => void) {
  const cb = useRef(onSelect);
  cb.current = onSelect;
  return useCallback((event: React.KeyboardEvent) => {
    if (!document.activeElement) return;
    const focusedIndexAttr = document.activeElement.getAttribute('data-search-row-index');
    if (!focusedIndexAttr) {
      if (event.key === 'ArrowDown') {
        document.querySelector<HTMLDivElement>('[data-search-row-index="0"]')?.focus();
      }
      return;
    }

    const focusedIndex = parseInt(focusedIndexAttr);
    switch (event.key) {
      case 'Enter':
        cb.current(focusedIndex);
        break;
      case 'ArrowUp':
        const prev: any = document.activeElement.previousElementSibling;
        if (prev?.getAttribute('data-search-row-index')) prev.focus();
        break;
      case 'ArrowDown':
        const next: any = document.activeElement.nextElementSibling;
        if (next?.getAttribute('data-search-row-index')) next.focus();
        break;
    }
  }, []);
}
