import React, { FC, useCallback } from 'react';
import { Banner, Button } from '@just-ai/just-ui';

import localize, { t, tWithCheck } from 'localization';
import history from 'appHistory';

import { useAppContext } from 'modules/Caila/components/AppContext';
import { JGLoadError } from 'reducers/JGraph.reducer';

import { jgloaderrorLocalization } from './jgloaderror.loc';

import styles from './styles.module.scss';

localize.addTranslations(jgloaderrorLocalization);

type ErrorText = {
  description: string;
  title?: string;
  hint?: string;
};
const defaultErrorFields: Partial<ErrorText> = {
  title: 'LoadJGraphErrorModal:title',
  description: '',
};

const errorsMap: Record<string, ErrorText> = {
  'editorbe.jgraph.empty_state_name': {
    description: 'LoadJGraphErrorModal:empty_state_name',
    hint: 'LoadJGraphErrorModal:empty_state_name:hint',
  },

  'editorbe.jgraph.state_duplicate': {
    title: 'LoadJGraphErrorModal:Duplicates:Title',
    description: 'LoadJGraphErrorModal:StateDuplicate',
    hint: 'LoadJGraphErrorModal:StateDuplicate:hint',
  },
  'editorbe.jgraph.theme_duplicate': {
    title: 'LoadJGraphErrorModal:Duplicates:Title',
    description: 'LoadJGraphErrorModal:ThemesDuplicate',
    hint: 'LoadJGraphErrorModal:ThemesDuplicate:hint',
  },
  'editorbe.jgraph.path_duplicate': {
    title: 'LoadJGraphErrorModal:Duplicates:Title',
    description: 'LoadJGraphErrorModal:StateAndThemeDuplicate',
    hint: 'LoadJGraphErrorModal:StateAndThemeDuplicate:hint',
  },

  'editorbe.jgraph.entry_point_file_not_exists': {
    title: 'LoadJGraphErrorModal:EntrypointFileNotExists:Title',
    description: 'LoadJGraphErrorModal:EntrypointFileNotExists',
  },
};

export const JGraphLoadError: FC<{ error: JGLoadError }> = React.memo(({ error }) => {
  const { projectShortName } = useAppContext();
  const returnToCode = useCallback(() => {
    history.push(`/${projectShortName}/editor`);
  }, [projectShortName]);

  const errorText = Object.assign({}, defaultErrorFields, errorsMap[error.code]);
  if (!errorText) return null;

  return (
    <div className={styles.JGraphLoadError} data-test-id='JGraphLoadError'>
      <div className={styles.content}>
        <h2 className={styles.title} data-test-id='JGraphLoadError:Title'>
          {t(errorText.title)}
        </h2>
        <div className={styles.description}>
          <div
            className={styles.description__text}
            data-test-id='JGraphLoadError:Description'
            dangerouslySetInnerHTML={{ __html: tWithCheck(errorText.description, error.args) || error.message }}
          />
          {errorText.hint && (
            <Banner
              type='warning'
              withIcon
              BannerText={() => (
                <div
                  className={styles.hint}
                  data-test-id='JGraphLoadError:Hint'
                  dangerouslySetInnerHTML={{ __html: t(errorText.hint, error.args) }}
                />
              )}
            />
          )}
        </div>
        <div className={styles.actions}>
          <Button color='primary' onClick={returnToCode} data-test-id='JGraphLoadError:ReturnToCode'>
            {t('LoadJGraphErrorModal:Return to code')}
          </Button>
        </div>
      </div>
    </div>
  );
});
