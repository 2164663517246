import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { isEqual, isNull } from 'lodash';

import localize from '../../../localization';

const defaultConfig = {
  type: 'pie',
  data: null,
  options: {
    responsive: true,
    legend: {
      position: 'top',
    },
  },
};

class ChartComponent extends Component {
  constructor(props) {
    super(props);
    this.chartContainer = null;
    this.chart = null;
    this.newConfig = null;
    this.config = {
      ...defaultConfig,
      ...this.props.config,
    };
  }

  createChart = chartData => {
    const ctx = this.chartContainer.getContext('2d');
    if (!!chartData) {
      this.config = {
        ...this.config,
        data: chartData,
      };
    }
    this.chart = new Chart(ctx, this.config);
  };

  componentDidMount() {
    const { chartData } = this.props;

    if (!!chartData) {
      this.createChart(chartData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (isNull(nextProps.chartData)) {
      this.chart = null;
      this.chartContainer = null;
    }
  }

  componentDidUpdate() {
    const { chartData } = this.props;

    if (!!this.chart) {
      if (!!chartData) {
        this.newConfig = {
          ...this.config,
          data: chartData,
        };
      }

      if (!isEqual(this.config, this.newConfig)) {
        this.chart.data.labels = chartData.labels;
        this.chart.data.datasets = chartData.datasets;
        this.chart.config = {
          ...this.chart.config,
          options: {
            ...this.chart.config.options,
            ...this.props.config.options,
          },
        };
        this.chart.update();
      }
    } else {
      if (!!this.chartContainer && !!chartData) {
        this.createChart(chartData);
      }
    }
  }

  render() {
    const { chartData, chartMonths } = this.props;
    return (
      <div>
        {!!chartData ? (
          <div>
            <canvas
              ref={div => {
                this.chartContainer = div;
              }}
            />
            {!!chartMonths ? (
              <div className='ucb-chart-months row'>
                <div className='text-left col-6'>{chartMonths.startMonthName}</div>
                <div className='text-right col-6'>{chartMonths.endMonthName}</div>
              </div>
            ) : null}
          </div>
        ) : (
          <p>{localize.translate('No data')}</p>
        )}
      </div>
    );
  }
}

ChartComponent.propTypes = {
  chartData: PropTypes.object,
  style: PropTypes.object,
  chartMonths: PropTypes.object,
  config: PropTypes.object,
};

export default ChartComponent;
