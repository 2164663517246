var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Input } from "reactstrap";
import { InputStates, Theme } from "./Common";
import { Icon } from "./Icon";
const DEFAULT_ROWS = 3;
export function Textarea(_a) {
    var { type, name, value, rows = DEFAULT_ROWS, onChange = () => { }, theme = Theme["light"], className, inputState, wrapperClassName, clearable } = _a, restProps = __rest(_a, ["type", "name", "value", "rows", "onChange", "theme", "className", "inputState", "wrapperClassName", "clearable"]);
    return (_jsxs("div", { className: classNames("textarea-container", wrapperClassName, {
            "dark": theme === Theme["dark"],
        }), children: [_jsx(Input, Object.assign({ type: "textarea", rows: rows, name: name, className: classNames(className, {
                    "dark": theme === Theme["dark"],
                    "is-warning": inputState === InputStates["warning"],
                }, `textarea-state--${inputState || "default"}`, 'form-control'), onChange: (event) => onChange(event.target.value, name), value: value, valid: inputState === InputStates["success"], invalid: inputState === InputStates["error"] }, restProps)), !restProps.disabled && Boolean(value) && clearable && (_jsx("div", { className: "textarea__clear-button", onClick: () => onChange("", name), children: _jsx(Icon, { className: "clear-icon", name: "faTimesCircle", size: "md" }) }))] }));
}
