import keyboardjs from 'keyboardjs';
export default class KeyboardService {
    constructor() {
        this.listeners = new Map();
    }
    bind(keysPattern, keyDownCallback, keyUpCallback) {
        keyboardjs.on(keysPattern, keyDownCallback, keyUpCallback);
        const key = Array.isArray(keysPattern) ? keysPattern.join(',') : keysPattern;
        this.listeners.set(key, [keyDownCallback, keyUpCallback]);
    }
    unbindAll() {
        Array.from(this.listeners.keys()).forEach(key => {
            // @ts-ignore
            const [onPress, onRelease] = this.listeners.get(key);
            keyboardjs.unbind(key, onPress, onRelease);
        });
    }
}
