import React, { Component } from 'react';
import localize from '../../../localization';
import history from '../../../appHistory';
import Robot404 from './Robot404';
import { Button } from 'reactstrap';

class Page404 extends Component {
  render() {
    return (
      <div className='container container404'>
        <div className='row justify-content-center '>
          <div className='col-md-8'>
            <div className='clearfix'>
              <Robot404 />
              <h1>{localize.translate('Page not found')}</h1>
              <p className='col-md-11'>{localize.translate('Dont care')}</p>
              <Button
                type='submit'
                color='primary'
                size='md'
                block
                className='btn-primary'
                onClick={() => history.push('/')}
              >
                {localize.translate('To project page')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page404;
