import {
  Button,
  FileUpload,
  FormGroup,
  FormText,
  Icon,
  IconButton,
  InlineRadioButton,
  InputText,
  Message,
  Option,
  SwitchButton,
  TextAreaLikeElement,
} from '@just-ai/just-ui';
import { t } from 'localization';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useRightSideMenuContext } from '../index';
import { TagNames, TJBlock, TTagParameters } from '../../../utils/types';
import { WysiwygEditor } from '../../../components/Wysiwyg';
import { session } from 'modules/JGraph/utils/insertSessionFunction';
import { axios } from '../../../../../pipes/functions';
import classNames from 'classnames';
import classes from './EmailSettings.module.scss';
import { StringField } from './CommonCustomTagSettings/String.field';
import { tWithCheck } from '../../../../../localization';
import { CommonLabel } from './CommonCustomTagSettings/CommonLabel';
import { withController } from './CommonCustomTagSettings/Controller';
import { CommonFieldProps } from './CommonCustomTagSettings/types';

export const EmailSettings: FC<{}> = () => {
  const { getEditBlockTag, customTagEdit } = useRightSideMenuContext();
  const editBlock = getEditBlockTag() as TJBlock<TagNames.Email>;
  const mapTagParametersToObj: Record<string, TTagParameters<string, any>> = {};
  editBlock.tagParameters.forEach(tagParam => {
    mapTagParametersToObj[tagParam.name] = tagParam;
  });
  const htmlInputRef = useRef<HTMLDivElement>(null);
  const htmlValue = useRef(session.parse(mapTagParametersToObj.html?.value || mapTagParametersToObj.text?.value || ''));

  const [isHtmlEnabled, setHtmlEnabled] = useState((mapTagParametersToObj.htmlEnabled?.value || false) as boolean);

  const changeHtmlEnabled = (value: boolean) => {
    setHtmlEnabled(prevEnabled => !prevEnabled);
    customTagEdit.onChange('htmlEnabled', value);
  };

  const changeHtml = (value: string) => {
    htmlValue.current = value;
    customTagEdit.onChange('html', session.insert(value));
    customTagEdit.onChange('text', session.insert(htmlInputRef.current?.innerText || ''));
  };

  const changeText = (name: string, value: string) => {
    const valueWithSession = session.insert(value);
    htmlValue.current = value.replace(/\n/g, '<br>');
    customTagEdit.onChange('text', valueWithSession);
    customTagEdit.onChange('html', valueWithSession.replace(/\n/g, '<br>'));
  };

  return (
    <>
      <div className='JGraph-RightSideMenu-commonContainer'>
        <StringField
          name='destination'
          label={tWithCheck('CustomTag:Email:destination') || t('EmailSettings:destination')}
          inputType='text'
          description={tWithCheck('CustomTag:Email:destination:description')}
        />
        <StringField
          name='subject'
          label={tWithCheck('CustomTag:Email:subject') || t('EmailSettings:subject')}
          inputType='text'
          description={tWithCheck('CustomTag:Email:subject:description')}
        />
        <FormGroup tag='div'>
          <CommonLabel
            name='text'
            isRequired={true}
            label={tWithCheck('CustomTag:Email:text') || t('EmailSettings:text')}
            description={tWithCheck('CustomTag:Email:subject:description')}
            RightSideElement={
              <div>
                <SwitchButton
                  id='switch-button'
                  label={t('AnswerSettings:HtmlEnabler')}
                  labelPosition='left'
                  onChange={changeHtmlEnabled}
                  size='md'
                  value={isHtmlEnabled}
                />
              </div>
            }
          />
          {isHtmlEnabled ? (
            <WysiwygEditor ref={htmlInputRef} defaultText={htmlValue.current} onChange={changeHtml} />
          ) : (
            <TextareaWithController name='text' onChange={changeText} />
          )}
        </FormGroup>
      </div>
      <FileAdderWithController name='files' />
    </>
  );
};

const TextareaWithController = withController(({ name, value, invalid, onChange }: CommonFieldProps) => {
  return (
    <TextAreaLikeElement
      minRows={2}
      maxRows={5}
      id={name}
      value={session.parse(value)}
      onChange={onChange}
      invalid={invalid}
    />
  );
});
const FileAdderWithController = withController(({ value }: CommonFieldProps) => {
  return <FileAdder files={typeof value === 'string' ? JSON.parse(value === '{}' ? '[]' : value) : value} />;
});

const FileAdder: FC<{ files: { name: string; url: string }[] }> = ({ files }) => {
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('file');
  const { customTagEdit } = useRightSideMenuContext();
  const [stateFiles, setFiles] = useState(files);

  const tabChange = useCallback((_, tab: Option) => {
    setActiveTab(tab.value as string);
  }, []);

  const loadImage = useCallback(
    (values: FileList) => {
      setLoading(true);
      const ArrayFromFiles = Array.from(values);
      const fileNames = ArrayFromFiles.map(file => file.name);
      let promiseArray: PromiseLike<{ data: string }>[] = [];
      ArrayFromFiles.forEach(value => {
        let fd = new FormData();
        fd.append('file', value);
        promiseArray.push(axios.post('/restapi/file/email/upload', fd));
      });

      Promise.all(promiseArray).then(promises => {
        setFiles(prevFiles => {
          const newFiles = [...prevFiles];
          promises.forEach((promiseRes, index) =>
            newFiles.splice(0, 0, { name: fileNames[index], url: promiseRes.data })
          );
          customTagEdit.onChange('files', JSON.stringify(newFiles));
          return newFiles;
        });
        setLoading(false);
      });
    },
    [customTagEdit]
  );

  const removeFile = useCallback(
    index => () => {
      setFiles(prevFiles => {
        prevFiles.splice(index, 1);
        customTagEdit.onChange('files', JSON.stringify(prevFiles));
        return [...prevFiles];
      });
    },
    [customTagEdit]
  );

  const addValueHandler = useCallback(
    (value: string) => {
      setFiles(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles.splice(0, 0, {
          name: value,
          url: session.insert('$' + value),
        });
        customTagEdit.onChange('files', JSON.stringify(newFiles));
        return newFiles;
      });
    },
    [customTagEdit]
  );

  useEffect(() => {
    customTagEdit.onChange('files', JSON.stringify(files));
    //DO NOT INCLUDE `files` IN DEPS
    // run once because of https://just-ai.atlassian.net/browse/ZB-20684
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('JGraph-RightSideMenu-commonContainer', classes.fileAdder)}>
      <h3>{t('EmailSettings:FileAdder:title')}</h3>
      <br />
      <div className='InlineButtons-full-width'>
        <InlineRadioButton
          active={activeTab}
          color='primary'
          name='buttonType'
          onChange={tabChange}
          options={[
            { name: t('EmailSettings:FileAdder:upload-file'), value: 'file' },
            { name: t('EmailSettings:FileAdder:file-link'), value: 'link' },
          ]}
          outline
          size='default'
        />
      </div>
      <br />
      {activeTab === 'file' && (
        <div>
          <FileUpload accept='*' onChange={loadImage} loading={isLoading} loadingType='progressBar'>
            <p>
              <span className='pseudoLink'>{t('EmailSettings:FileAdder:drop_file')}</span>{' '}
              {t('EmailSettings:FileAdder:drop_file_subtext')}
            </p>
            <small>{t('EmailSettings:FileAdder:drop_file_small_subtext')}</small>
          </FileUpload>
        </div>
      )}
      {activeTab === 'link' && <LinkAdder addValue={addValueHandler} />}
      <br />
      {stateFiles.length > 0 && (
        <div className={classes.fileList}>
          <p>
            <b>{t('EmailSettings:FileAdder:file_list_title')}</b>
          </p>
          {stateFiles.map((file, index) => (
            <div className={classes.fileItem} key={file.url}>
              <Icon name='farFileArchive' />
              <div className={classes.fileName}>{file.url.startsWith('http') ? file.name : '$' + file.name}</div>
              <IconButton className={classes.fixColor} name='farTrashAlt' onClick={removeFile(index)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const LinkAdder: FC<{ addValue: (value: string) => unknown }> = ({ addValue }) => {
  const [value, setValue] = useState('');

  const onSubmit = useCallback(() => {
    setValue(prevValue => {
      addValue(prevValue);
      return '';
    });
  }, [addValue]);

  return (
    <>
      <div className={classes.addLink}>
        <div className={classes.addLinkInputContainer}>
          <Icon name='farLink' wrapperClassName={classes.justui__icon} />
          <InputText onChange={value => setValue(value)} placeholder='variable_name' value={value} />
          <FormText color='muted' tag='small'>
            {t('EmailSettings:FileAdder:LinkAdder:helper_text')}
          </FormText>
        </div>
        <Button disabled={!value} onClick={onSubmit} color='primary'>
          {t('Add')}
        </Button>
      </div>
      <Message type='info' text={t('EmailSettings:FileAdder:LinkAdder:message_text')} />
    </>
  );
};
