import React from 'react';
import { t } from 'localization';

import classes from './RenderingModal.module.scss';
import { Button, ProgressBar } from '@just-ai/just-ui';

type RenderingModalProps = {
  percentage: number;

  title?: string;
  cancellable?: boolean;
  cancel?: () => unknown;
};

export const RenderingModal = React.memo(({ cancellable = false, title, percentage, cancel }: RenderingModalProps) => {
  const text = () => {
    if (percentage < 25) return t('RenderingModal:Below25Percentage');
    if (percentage >= 25 && percentage < 50) return t('RenderingModal:Below50Percentage');
    if (percentage >= 50 && percentage < 75) return t('RenderingModal:More50Percentage');
    if (percentage >= 75) return t('RenderingModal:More75Percentage');
  };

  return (
    <div className={classes.RenderingModal}>
      <div className={classes.RenderingModalContainer}>
        <h2 className='mb-5'>{title}</h2>
        <div className='mb-3'>
          <ProgressBar progress={percentage} unknownProgress={false} />
        </div>
        <span style={{ color: 'var(--gray-600)' }}>{text()}</span>
        {cancellable && (
          <Button color='secondary' onClick={cancel}>
            {t('Cancel')}
          </Button>
        )}
      </div>
    </div>
  );
});

RenderingModal.displayName = 'RenderingModal';
