export const nluIntentSimpleModalLocalization = {
  'IntentItemPlaceholder:text': {
    eng: 'Here will be a list of your intents',
    ru: 'Здесь будет список ваших интентов',
  },
  'IntentContentPlaceholder:title': {
    eng: 'Create your first intent',
    ru: 'Создайте первый интент',
  },
  'IntentContentPlaceholder:title_WithoutSystemIntents': {
    eng: 'Select an intent from the list or create a new one',
    ru: 'Выберите интент из списка или создайте новый',
  },
  'IntentContentPlaceholder:text': {
    eng: 'An intent is a set of phrases which are similar in meaning and help the bot understand user intention.',
    ru: 'Интент — это набор фраз со схожим смыслом, которые помогают боту понять намерение пользователя.',
  },
  'IntentContentPlaceholder:CreateIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
  },
  'CailaIntentsSimple:IntentListTitle:System': {
    eng: 'System',
    ru: 'Системные',
  },
  'CailaIntentsSimple:IntentListTitle:User': {
    eng: 'Custom',
    ru: 'Пользовательские',
  },
  'CailaIntentsSimple:IntentDetail:Name': {
    eng: 'Name',
    ru: 'Название',
  },
  'CailaIntentsSimple:IntentDetail:TrainingPhrases:Title': {
    eng: 'Training phrases',
    ru: 'Тренировочные фразы',
  },
  'CailaIntentsSimple:IntentDetail:NewPhrase:Title': {
    eng: 'New phrase',
    ru: 'Новая фраза',
  },
  'CailaIntentsSimple:IntentDetail:NewPhrase:subtext': {
    eng: 'Type in a phrase and press Enter to add it',
    ru: 'Введите фразу и нажмите Enter, чтобы добавить',
  },
  'CailaIntentsSimple:IntentDetail:AdditionalPhrases': {
    eng: 'Added phrases',
    ru: 'Добавленные фразы',
  },
  'CailaIntentsSimple:IntentDetail:ShowFullPath:title': {
    eng: 'The full path of the intent:',
    ru: 'Полный путь к интенту:',
  },
  'CailaIntentsSimple:ListIntents:DropDown:disableIntent': {
    eng: 'Disable intent',
    ru: 'Отключить интент',
  },
  'CailaIntentsSimple:ListIntents:DropDown:enableIntent': {
    eng: 'Enable intent',
    ru: 'Включить интент',
  },
  'CailaIntentsSimple:ListIntents:DropDown:deleteIntent': {
    eng: 'Delete intent',
    ru: 'Удалить интент',
  },
  'CailaIntentsSimple:IntentDetail:title': {
    eng: 'Intent',
    ru: 'Интент',
  },
  'CailaIntentsSimple:IntentSystemDetail:warning_text': {
    eng: 'This is a system intent — a built-in set of phrases for understanding user intentions',
    ru: 'Это системный интент — готовый набор фраз для понимания намерений пользователя',
  },
  'CailaIntentsSimple:IntentSystemDetail:description_title': {
    eng: 'Description',
    ru: 'Описание',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:agreement': {
    eng: 'This intent recognizes consent phrases, such as “yes”, “ok”, and their synonyms.',
    ru: 'Интент распознает фразы согласия, например, «да», «окей» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:negation': {
    eng: 'This intent recognizes rejection phrases, such as “no”, “cancel”, and their synonyms.',
    ru: 'Интент распознает фразы отказа, например, «нет», «отменить» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:hello': {
    eng: 'This intent recognizes greeting phrases, such as “hello”, “good day”, and their synonyms.',
    ru: 'Интент распознает фразы приветствия, например, «здравствуйте», «добрый день» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:parting': {
    eng: 'This intent recognizes goodbye phrases, such as “bye”, “see you”, and their synonyms.',
    ru: 'Интент распознает фразы прощания, например, «пока», «до свидания» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:thanks': {
    eng: 'This intent recognizes gratitude phrases, such as “thank you”, “thanks”, and their synonyms.',
    ru: 'Интент распознает фразы благодарности, например, «спасибо», «благодарю» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:uncertainty': {
    eng: 'This intent recognizes phrases expressing doubt, such as “maybe”, “perhaps”, and their synonyms.',
    ru: 'Интент распознает фразы, выражающие сомнение, например, «может быть», «возможно» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:insults': {
    eng: 'This intent recognizes obscene or offensive words.',
    ru: 'Интент распознает нецензурную лексику или оскорбления.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:approval': {
    eng: 'This intent recognizes phrases expressing endorsement, such as “alright”, “like it”, and their synonyms.',
    ru: 'Интент распознает фразы, выражающие одобрение, например, «хорошо», «нравится» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:negative': {
    eng: 'This intent recognizes phrases with a negative reaction, such as “bad”, “don’t like it”, and their synonyms.',
    ru: 'Интент распознает фразы с негативной реакцией, например, «плохо», «не нравится» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:normal': {
    eng: 'This intent recognizes phrases with a neutral reaction, such as “fine”, “whatever”, and their synonyms.',
    ru: 'Интент распознает фразы с нейтральной оценкой, например, «нормально», «сойдет» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:gender': {
    eng: 'This intent recognizes phrases indicating gender, such as “man” and “boy”, “woman” and “girl”, and their synonyms.',
    ru: 'Интент распознает фразы с указанием пола, например, «мужчина» или «женщина», «мальчик» или «девочка» и их синонимы.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:phone': {
    eng: 'This intent recognizes a phone number.',
    ru: 'Интент распознает номер телефона.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:datetime': {
    eng: 'This intent recognizes a date and time in different formats.',
    ru: 'Интент распознает дату и время в разных форматах.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:city': {
    eng: 'This intent recognizes a city from the system dictionary.',
    ru: 'Интент распознает город из системного справочника.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:capital': {
    eng: 'This intent recognizes a capital from the system dictionary.',
    ru: 'Интент распознает столицу из системного справочника.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:country': {
    eng: 'This intent recognizes a country from the system dictionary.',
    ru: 'Интент распознает страну из системного справочника.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:name': {
    eng: 'This intent recognizes a name from the system dictionary.',
    ru: 'Интент распознает имя из системного справочника.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:email': {
    eng: 'This intent recognizes an email address.',
    ru: 'Интент распознает адрес электронной почты.',
  },
  'CailaIntentsSimple:IntentSystemDetail:description:switch': {
    eng: 'This intent recognizes phrases for calling an agent, such as “agent” or “manager”, and their synonyms.',
    ru: 'Интент распознает фразы для вызова оператора, например, «оператор», «менеджер» и их синонимы.',
  },

  'CailaIntentsSimple:ListIntents:modalDeleteIntentTitle': {
    eng: 'Delete intent',
    ru: 'Удаление интента',
    cn: '删除意图',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentTitle_zero': {
    eng: 'Delete $[1] intent',
    ru: 'Удаление $[1] интента',
    cn: '删除 $[1] 意图',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentTitle_two': {
    eng: 'Delete $[1] intents',
    ru: 'Удаление $[1] интентов',
    cn: '删除$[1]意图',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentTitle_few': {
    eng: 'Delete $[1] intents',
    ru: 'Удаление $[1] интентов',
    cn: '删除$[1]意图',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentActionButton': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentMessage': {
    eng: 'Are you sure you want to remove intent along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить интент вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要删除意图以及相关的训练短语？此操作无法回滚恢复。',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentMessage_zero': {
    eng: 'Are you sure you want to remove intent along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $[1] интент вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要删除意图以及相关的训练短语？此操作无法回滚恢复。',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentMessage_two': {
    eng: 'Are you sure you want to remove $[1] intents along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $[1] интента вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要移除 $[1] 以及相关的训练短语？此操作无法回滚恢复。',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentMessage_few': {
    eng: 'Are you sure you want to remove $[1] intents along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $[1] интентa вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要移除 $[1] 以及相关的训练短语？此操作无法回滚恢复。',
  },
  'CailaIntentsSimple:ListIntents:modalDeleteIntentMessage_many': {
    eng: 'Are you sure you want to remove $[1] intents along with associated training phrases? This action cannot be undone.',
    ru: 'Вы действительно хотите удалить $[1] интентов вместе с тренировочными фразами? Данное действие невозможно отменить.',
    cn: '是否确定要移除 $[1] 以及相关的训练短语？此操作无法回滚恢复。',
  },
  'CailaIntentsSimple:IntentDetail:saveStatus:Saving': {
    eng: 'Saving',
    ru: 'Сохранение',
  },
  'CailaIntentsSimple:IntentDetail:saveStatus:Saved': {
    eng: 'Saved',
    ru: 'Сохранено',
  },
  'CailaIntentsSimple:IntentDetail:saveStatus:NotSaved': {
    eng: 'Not saved',
    ru: 'Не сохранено',
  },
};
