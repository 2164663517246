var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, createContext, useContext } from 'react';
import { ProjectStatsService, LabelsService } from '../service/AnalyticsService';
import { getMethodDateFormat, prepareOptions } from '../utils/ContextUtils';
import AnalyticsChartData, { StatsData } from '../service/AnalyticsChartData';
import { axios } from '../utils/pipes/functions';
import filterSessionIdController from '../service/filterSessionIdController';
import { AnalyticsActiveFilters } from '../service/AnalyticsActiveFilters';
import { LabelsContext } from '../../Labels';
import { Spinner } from '@just-ai/just-ui';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export const AnalyticsContext = createContext({});
class State {
    constructor() {
        this.isLoading = false;
        this.methodsNotAllowed = true;
        this.analyticsContextIsReady = false;
        this.clientsFilterQuery = {};
        this.sessions = [];
        this.routes = [];
        this.session = null;
        this.messages = [];
        this.sessionMessages = [];
        this.analyticsChartData = {
            sessionData: new StatsData(),
            messagesData: new StatsData(),
        };
        this.channelsOptionsFilter = [];
    }
}
export class AnalyticsProvider extends Component {
    constructor() {
        super(...arguments);
        this.analyticsStatsService = new ProjectStatsService(NaN, '');
        this.labelsService = new LabelsService(NaN, '');
        this.analyticsChartData = new AnalyticsChartData();
        this.filtersData = new AnalyticsActiveFilters();
        this.state = new State();
        this.messagesByDay = [];
        this.axios = axios;
        this.setFilterData = (filterData) => {
            this.filtersData.set(filterData);
        };
        this.getFilterData = () => {
            return this.filtersData.get();
        };
        this.load = promise => {
            this.setState({ isLoading: true });
            return promise.finally(() => this.setState({ isLoading: false }));
        };
        this.getSessionWithNewFilters = (filters) => {
            return this.load(this.analyticsStatsService.getSessionWithNewFilters(filters));
        };
        this.getSessionLabelsWithNewFilters = (filters) => {
            return this.load(this.analyticsStatsService.getSessionLabelWithNewFilters(filters));
        };
        this.getLogLabelsWithNewFilters = (filters) => {
            return this.load(this.analyticsStatsService.getLogLabelWithNewFilters(filters));
        };
        this.getMessageWithNewFilters = (filters) => {
            return this.load(this.analyticsStatsService.getMessageWithNewFilters(filters));
        };
        this.getFilterSetId = (filters) => __awaiter(this, void 0, void 0, function* () {
            return this.analyticsStatsService.getFilterSetId(filters);
        });
        this.getLogsByFilter2 = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const data = yield this.load(this.analyticsStatsService.getLogsByFilter2(page, size, filtersDto));
            this.setState({ messages: data.messages }, this.loadIssues.bind(this, 'sessionMessages'));
            return data;
        });
        this.loadIssues = (...args_1) => __awaiter(this, [...args_1], void 0, function* (type = 'messages', questionId) {
            var _a;
            const messages = this.state[type];
            const { projectShortName } = this.props;
            if ((_a = this.props.config.messages) === null || _a === void 0 ? void 0 : _a.downloadIssue) {
                let uniqQuestionIdsInSession = messages.reduce((result, x) => {
                    if (questionId && x.questionId !== questionId)
                        return result;
                    if (!result[x.sessionId]) {
                        result[x.sessionId] = new Set();
                    }
                    result[x.sessionId].add(x.questionId);
                    return result;
                }, {});
                let issues = {};
                const issuesPromises = Object.keys(uniqQuestionIdsInSession).map((sessionId) => __awaiter(this, void 0, void 0, function* () {
                    const { messages } = this.props.config;
                    if (messages === null || messages === void 0 ? void 0 : messages.downloadIssue) {
                        const issue = yield messages.downloadIssue({
                            projectShortName,
                            sessionId,
                            uniqQuestionIdsInSession,
                        });
                        if (Object.keys(issue.data).length > 0) {
                            issues = Object.assign(Object.assign({}, issues), issue.data);
                        }
                    }
                }));
                yield Promise.all(issuesPromises);
                if (Object.keys(issues).length > 0) {
                    const messagesWithIssues = messages.map(x => {
                        if (issues[x.questionId])
                            x.issues = issues[x.questionId];
                        return x;
                    });
                    this.setState({ [type]: messagesWithIssues });
                }
            }
        });
        this.loadSessionIssue = () => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const { projectShortName } = this.props;
            const { session } = this.state;
            const { sessionId } = this.state.session || {};
            if (((_b = this.props.config.session) === null || _b === void 0 ? void 0 : _b.downloadIssue) && sessionId) {
                const { data } = yield this.props.config.session.downloadIssue({
                    projectShortName,
                    sessionId: encodeURIComponent(sessionId),
                });
                if ((session === null || session === void 0 ? void 0 : session.sessionId) === sessionId) {
                    this.setState({ session: Object.assign(Object.assign({}, session), { issue: data[0] || data }) });
                }
            }
        });
        this.getSessionsByFilter = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const data = yield this.load(this.analyticsStatsService.getSessionsByFilter(page, size, filtersDto));
            this.setState({ sessions: data.sessions });
            return data;
        });
        this.createClientReport = (filtersDto, timezone) => __awaiter(this, void 0, void 0, function* () {
            return this.load(this.analyticsStatsService.createClientReport(filtersDto, timezone));
        });
        this.saveMessageComment = (questionId, comment, isSessionMessages) => __awaiter(this, void 0, void 0, function* () {
            const messages = isSessionMessages ? this.state.sessionMessages : this.state.messages;
            const newMessages = messages.map((x) => {
                if (x.questionId === questionId) {
                    return Object.assign(Object.assign({}, x), { comment });
                }
                else {
                    return x;
                }
            });
            //@ts-ignore
            this.setState(isSessionMessages ? { sessionMessages: newMessages } : { messages: newMessages });
        });
        this.saveMessageLabels = (questionId, sessionId, labels, isSessionMessages) => __awaiter(this, void 0, void 0, function* () {
            const labelsWithGroups = labels.map(x => {
                //@ts-ignore
                if (x.groupId) {
                    //@ts-ignore
                    return Object.assign(Object.assign({}, x), { group: this.context.groups.find(group => group.id === x.groupId) });
                }
                else {
                    return x;
                }
            });
            yield this.setLogLabels(questionId, sessionId, labels.map(x => ({ labelId: x.id })));
            const messages = isSessionMessages ? this.state.sessionMessages : this.state.messages;
            const newMessages = messages.map(x => {
                if (x.questionId === questionId) {
                    return Object.assign(Object.assign({}, x), { labels: [...labelsWithGroups] });
                }
                else {
                    return x;
                }
            });
            //@ts-ignore
            this.setState(isSessionMessages ? { sessionMessages: newMessages } : { messages: newMessages });
        });
        this.saveSessionLabels = (sessionId, labels) => __awaiter(this, void 0, void 0, function* () {
            yield this.setSessionLabels(sessionId, labels.map(x => ({ labelId: x.id })));
            if (this.state.session) {
                this.setState({ session: Object.assign(Object.assign({}, this.state.session), { labels }) });
            }
        });
        this.createNewLabelAndAddToMessage = (questionId, sessionId, labelName, labelGroupId, labels, isSessionMessages) => __awaiter(this, void 0, void 0, function* () {
            var _c;
            const label = yield ((_c = this.context) === null || _c === void 0 ? void 0 : _c.createLabel(labelName, labelGroupId));
            let newLabels = [label, ...labels];
            if (labels) {
                this.saveMessageLabels(questionId, sessionId, newLabels, isSessionMessages);
            }
        });
        this.createNewLabelAndAddToSession = (sessionId, labelName, labels) => __awaiter(this, void 0, void 0, function* () {
            const label = yield this.context.createSessionLabel(labelName);
            let newLabels = [label, ...labels];
            if (label) {
                this.saveSessionLabels(sessionId, newLabels);
            }
        });
        //done SWITCH
        this.getSwitchedSessionCountByDay = (dateFrom, dateTo, transferToOperatorMethod, botIds) => {
            const [dateFromFormatted, dateToFormatted] = getMethodDateFormat(dateFrom, dateTo);
            return this.load(this.analyticsStatsService.getSwitchedSessionCountByDay(dateFromFormatted, dateToFormatted, transferToOperatorMethod, botIds));
        };
        this.saveClientsFilterQuery = (clientsFilterQuery) => {
            this.setState({ clientsFilterQuery });
        };
        this.setLogLabels = (questionId, sessionId, labels) => {
            return this.load(this.labelsService.setLogLabels(questionId, sessionId, labels));
        };
        this.setSessionLabels = (sessionId, labels) => {
            return this.load(this.labelsService.setSessionLabels(sessionId, labels));
        };
        this.getClientByFilter = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            var _d;
            return this.load(this.analyticsStatsService.getClientByFilter(page, size, filtersDto, {
                headers: { Language: ((_d = this.props.locale) === null || _d === void 0 ? void 0 : _d.toUpperCase()) || 'ENG' },
            }));
        });
        this.getMessagesForClient = (botId, clientId, logCount, startDate) => {
            return this.load(this.analyticsStatsService.getMessagesForClient(botId, decodeURIComponent(clientId), logCount, startDate));
        };
        this.getMessagesForClientDialog = (botId, clientId, logCount, startDate, sort, page, channelType, options) => {
            return this.analyticsStatsService.getMessagesForClient(botId, decodeURIComponent(clientId), logCount, startDate, sort, page, channelType, options);
        };
        this.blockClient = (botId, clientId, blockClientDto) => {
            return this.load(
            //@ts-ignore
            axios._put(`/restapi/clients/block?clientId=${encodeURIComponent(clientId)}&botId=${encodeURIComponent(botId)}`, Object.assign(Object.assign({}, blockClientDto), { accountId: this.props.accountId })));
        };
        this.unblockClient = (botId, clientId) => {
            return this.load(
            //@ts-ignore
            axios._put(`/restapi/clients/unblock?clientId=${encodeURIComponent(clientId)}&botId=${encodeURIComponent(botId)}`));
        };
        this.getMessagePageInSession = (sessionId, questionId, botId) => {
            return this.load(this.analyticsStatsService.getMessagePageInSession(sessionId, questionId, botId));
        };
        this.getMessagesForSession = (sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages) => __awaiter(this, void 0, void 0, function* () {
            var _e;
            this.setState({ isLoading: true });
            (_e = this.cancelTokenMessagesForSession) === null || _e === void 0 ? void 0 : _e.cancel();
            this.cancelTokenMessagesForSession = Axios.CancelToken.source();
            let data;
            try {
                data = yield this.analyticsStatsService.getMessagesForSession(sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages, { cancelToken: this.cancelTokenMessagesForSession.token });
                this.setState({
                    isLoading: false,
                    sessionMessages: data.messages,
                    session: data.session,
                }, this.loadIssues);
            }
            catch (e) {
                console.log(e);
                return { logs: [], session: {}, paging: {} };
            }
            return data;
        });
        this.createMessageReport = (options = {}) => {
            return this.load(this.analyticsStatsService.createMessageReport(options));
        };
        this.createRouteReport = (options = {}) => {
            return this.load(this.analyticsStatsService.createRouteReport(options));
        };
        this.setAnalyticChartData = () => {
            this.setState({
                analyticsChartData: {
                    sessionData: this.analyticsChartData.sessionStats,
                    messagesData: this.analyticsChartData.messagesStats,
                },
            });
        };
        this.getRoutesDataByFilter = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const data = yield this.load(this.analyticsStatsService.getRoutesDataByFilter(page, size, filtersDto));
            const routesWithId = data.routes.map(route => (Object.assign(Object.assign({}, route), { generatedId: uuidv4() })));
            this.setState({ routes: routesWithId });
            return data;
        });
        this.getMessagesAndSessionStats = (location, filters) => __awaiter(this, void 0, void 0, function* () {
            const [sessionData, messageData] = yield Promise.all([
                this.getSessionWithNewFilters(filters),
                this.getMessageWithNewFilters(filters),
            ]);
            filterSessionIdController.save(location, sessionData.filterSetId, this.props.history);
            this.analyticsChartData.setMessagesAndSessionData(sessionData.stats, messageData.stats);
            this.setAnalyticChartData();
        });
        this.toggleChartActivity = (name) => {
            this.analyticsChartData.toggleActivity(name);
            this.setAnalyticChartData();
        };
        this.getSessionLabelsStats = (location, filters) => {
            return this.load(this.analyticsStatsService.getSessionLabelsStats(filters));
        };
        this.getLogLabelStats = (filter) => {
            return this.load(this.analyticsStatsService.getLogLabelWithNewFilters(filter));
        };
        this.getTelephonyStats = (dateFrom, dateTo, botIds) => {
            // const preparedFilter = prepareOptions(options);
            const [dateFromFormatted, dateToFormatted] = getMethodDateFormat(dateFrom, dateTo);
            return this.load(
            //@ts-ignore
            this.analyticsStatsService.getTelephonyStats(dateFromFormatted, dateToFormatted, botIds));
        };
        this.getTelephonyReport = (options) => {
            let filterOptions = prepareOptions(options);
            return this.load(this.analyticsStatsService.getTelephonyReport(filterOptions));
        };
        this.deleteReportGenerationTask = (options) => {
            return this.load(this.analyticsStatsService.deleteReportGenerationTask(options));
        };
        this.deleteCompletedReportGenerationTasks = (options) => {
            return this.load(this.analyticsStatsService.deleteCompletedReportGenerationTasks(options));
        };
        this.createSessionReport = (options = {}) => {
            return this.load(this.analyticsStatsService.createSessionReport(options));
        };
        this.createAnalyticsToken = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            const data = yield this.analyticsStatsService.createAnalyticsToken(projectShortName);
            return data.token;
        });
        this.getAnalyticsTokenByProjectShortName = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            const data = yield this.analyticsStatsService.getAnalyticsTokenByProjectShortName(projectShortName);
            return data.token;
        });
        this.deleteAnalyticsTokenByProjectShortName = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            return yield this.analyticsStatsService.deleteAnalyticsTokenByProjectShortName(projectShortName);
        });
        this.setChannelsOptionsFilter = (options) => {
            this.setState({ channelsOptionsFilter: options });
        };
    }
    componentDidMount() {
        this.axios = this.props.axios || axios;
        this.timeZone = this.props.timeZone;
        this.analyticsStatsService = new ProjectStatsService(this.props.accountId, this.props.projectShortName, this.axios);
        this.labelsService = new LabelsService(this.props.accountId, this.props.projectShortName, this.axios);
        if (this.props.accountId > 0 && Boolean(this.props.projectShortName)) {
            this.setState({
                methodsNotAllowed: false,
                analyticsContextIsReady: true,
            });
        }
        if (!this.context.loaded && this.context.getLabels) {
            this.context.getLabels();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.analyticsStatsService = new ProjectStatsService(this.props.accountId, this.props.projectShortName, this.axios);
        this.labelsService = new LabelsService(this.props.accountId, this.props.projectShortName, this.axios);
        let methodsNotAllowed = false;
        if (prevProps.projectShortName &&
            this.props.projectShortName &&
            prevProps.projectShortName !== this.props.projectShortName) {
            methodsNotAllowed = true;
        }
        if (!methodsNotAllowed &&
            this.props.accountId > 0 &&
            Boolean(this.props.projectShortName) &&
            this.state.methodsNotAllowed) {
            methodsNotAllowed = false;
        }
        if (this.state.methodsNotAllowed !== methodsNotAllowed) {
            this.setState({
                methodsNotAllowed: methodsNotAllowed,
                analyticsContextIsReady: !methodsNotAllowed,
            });
        }
    }
    render() {
        const { clientsFilterQuery } = this.state;
        return (_jsxs(AnalyticsContext.Provider, { value: {
                axios: this.axios,
                accountId: this.props.accountId,
                analyticsChartData: this.state.analyticsChartData,
                analyticsContextIsReady: this.state.analyticsContextIsReady,
                clientsFilterQuery,
                config: this.props.config,
                isLoading: this.state.isLoading,
                projectShortName: this.props.projectShortName,
                locale: this.props.locale,
                methodsNotAllowed: this.state.methodsNotAllowed,
                messages: this.state.messages,
                sessions: this.state.sessions,
                session: this.state.session,
                sessionMessages: this.state.sessionMessages,
                routes: this.state.routes,
                blockClient: this.blockClient,
                createAnalyticsToken: this.createAnalyticsToken,
                createClientReport: this.createClientReport,
                createSessionReport: this.createSessionReport,
                createMessageReport: this.createMessageReport,
                createRouteReport: this.createRouteReport,
                createNewLabelAndAddToMessage: this.createNewLabelAndAddToMessage,
                createNewLabelAndAddToSession: this.createNewLabelAndAddToSession,
                getSessionWithNewFilters: this.getSessionWithNewFilters,
                deleteReportGenerationTask: this.deleteReportGenerationTask,
                deleteCompletedReportGenerationTasks: this.deleteCompletedReportGenerationTasks,
                deleteAnalyticsTokenByProjectShortName: this.deleteAnalyticsTokenByProjectShortName,
                getAnalyticsTokenByProjectShortName: this.getAnalyticsTokenByProjectShortName,
                getClientByFilter: this.getClientByFilter,
                getMessagesForClient: this.getMessagesForClient,
                getMessagesForClientDialog: this.getMessagesForClientDialog,
                getFilterData: this.getFilterData,
                getFilterSetId: this.getFilterSetId,
                getLogLabelsWithNewFilters: this.getLogLabelsWithNewFilters,
                getLogLabelStats: this.getLogLabelStats,
                getLogsByFilter2: this.getLogsByFilter2,
                getMessagesForSession: this.getMessagesForSession,
                getMessagesAndSessionStats: this.getMessagesAndSessionStats,
                getMessagePageInSession: this.getMessagePageInSession,
                getSwitchedSessionCountByDay: this.getSwitchedSessionCountByDay,
                getSessionLabelsStats: this.getSessionLabelsStats,
                getSessionsByFilter: this.getSessionsByFilter,
                getRoutesByFilter: this.getRoutesDataByFilter,
                getTelephonyStats: this.getTelephonyStats,
                getTelephonyReport: this.getTelephonyReport,
                t: this.props.t,
                prepareMessagesAndSessionsGraphData: () => this.analyticsChartData.prepareMessagesAndSessionsGraphData(this.props.t),
                saveClientsFilterQuery: this.saveClientsFilterQuery,
                saveMessageComment: this.saveMessageComment,
                saveMessageLabels: this.saveMessageLabels,
                saveSessionLabels: this.saveSessionLabels,
                loadIssues: this.loadIssues,
                loadSessionIssue: this.loadSessionIssue,
                setFilterData: this.setFilterData,
                setLogLabels: this.setLogLabels,
                toggleChartActivity: this.toggleChartActivity,
                unblockClient: this.unblockClient,
                labelsContext: this.context,
                history: this.props.history,
                timeZone: this.timeZone,
                isAccess: this.props.isAccess || (() => false),
                setChannelsOptionsFilter: this.setChannelsOptionsFilter,
                channelsOptionsFilter: this.state.channelsOptionsFilter,
            }, children: [this.props.children, this.state.isLoading && _jsx(Spinner, { size: '4x', backgroundColor: 'rgba(255,255,255, 0.3)' })] }));
    }
}
AnalyticsProvider.contextType = LabelsContext;
export const useAnalyticsContext = () => useContext(AnalyticsContext);
