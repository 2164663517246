export const FLOW_LOAD = 'FLOW_LOAD';
export const FLOW_LOAD_FULFILLED = 'FLOW_LOAD_FULFILLED';
export const FLOW_LOAD_PENDING = 'FLOW_LOAD_PENDING';
export const FLOW_LOAD_REJECTED = 'FLOW_LOAD_REJECTED';

export const STATE_CHANGE = 'STATE_CHANGE';
export const STATE_CHANGE_FULFILLED = 'STATE_CHANGE_FULFILLED';
export const STATE_CHANGE_PENDING = 'STATE_CHANGE_PENDING';
export const STATE_CHANGE_REJECTED = 'STATE_CHANGE_REJECTED';

export const SET_PAGINATE = 'SET_PAGINATE';
export const SET_PAGINATE_FULFILLED = 'SET_PAGINATE_FULFILLED';
export const SET_PAGINATE_PENDING = 'SET_PAGINATE_PENDING';
export const SET_PAGINATE_REJECTED = 'SET_PAGINATE_REJECTED';

export const FETCH_USERS_MESSANGER_TYPES = 'FETCH_USERS_MESSANGER_TYPES';
export const FETCH_USERS_MESSANGER_TYPES_FULFILLED = 'FETCH_USERS_MESSANGER_TYPES_FULFILLED';
export const FETCH_USERS_MESSANGER_TYPES_PENDING = 'FETCH_USERS_MESSANGER_TYPES_PENDING';
export const FETCH_USERS_MESSANGER_TYPES_REJECTED = 'FETCH_USERS_MESSANGER_TYPES_REJECTED';

export const DROP_FILTER_FLOW = 'DROP_FILTER_FLOW';
export const DROP_FILTER_FLOW_FULFILLED = 'DROP_FILTER_FLOW_FULFILLED';
export const DROP_FILTER_FLOW_PENDING = 'DROP_FILTER_FLOW_PENDING';
export const DROP_FILTER_FLOW_REJECTED = 'DROP_FILTER_FLOW_REJECTED';

export const SET_FILTER_FLOW = 'SET_FILTER_FLOW';
export const SET_FILTER_FLOW_FULFILLED = 'SET_FILTER_FLOW_FULFILLED';
export const SET_FILTER_FLOW_PENDING = 'SET_FILTER_FLOW_PENDING';
export const SET_FILTER_FLOW_REJECTED = 'SET_FILTER_FLOW_REJECTED';

export const FETCH_BOTS_TYPES = 'FETCH_BOTS_TYPES';
export const FETCH_BOTS_TYPES_FULFILLED = 'FETCH_BOTS_TYPES_FULFILLED';
export const FETCH_BOTS_TYPES_PENDING = 'FETCH_BOTS_TYPES_PENDING';
export const FETCH_BOTS_TYPES_REJECTED = 'FETCH_BOTS_TYPES_REJECTED';

export const SET_STATE_FILTER_FLOW = 'SET_STATE_FILTER_FLOW';
export const SET_STATE_FILTER_FLOW_FULFILLED = 'SET_STATE_FILTER_FLOW_FULFILLED';
export const SET_STATE_FILTER_FLOW_PENDING = 'SET_STATE_FILTER_FLOW_PENDING';
export const SET_STATE_FILTER_FLOW_REJECTED = 'SET_STATE_FILTER_FLOW_REJECTED';

export const SET_FILTER_DATE = 'SET_FILTER_DATE';
export const SET_FILTER_CHANNEL = 'SET_FILTER_CHANNEL';
export const SET_BOT_CHANNEL = 'SET_BOT_CHANNEL';
export const CHECK_CHECK_BOX = 'CHECK_CHECK_BOX';

export const DROP_DATA_FLOW = 'DROP_DATA_FLOW';
