import { PhraseStatusData } from '@just-ai/api/dist/generated/Caila';
export const toPhraseItems = (allPhrases, allStatuses) => allPhrases.map((text, index) => ({ id: prefixed(index), text, status: allStatuses[index] }));
export const toSortedDataset = (phrases, allPhrases) => phrases
    .map(phrase => toPhrase(allPhrases[Number(phrase.phraseIdx)], phrase.phraseIdx, phrase.confidence, phrase.sessionId))
    .sort(byStatusAndConfidence)
    .reduce(addNode, {});
export const sortDataset = (phrases) => Object.values(phrases).sort(byStatusAndConfidence).reduce(addNode, {});
export const toSortedDatasetWithTopN = (phrases, allPhrases, groups) => phrases
    .map(phrase => toPhraseWithTopN(phrase, allPhrases, groups))
    .sort(byStatusAndConfidence)
    .reduce(addNode, {});
const toPhraseWithTopN = (phrase, allPhrases, groups) => (Object.assign(Object.assign({}, allPhrases[Number(phrase.phraseIdx)]), { confidence: phrase.confidence, topN: getTopN(phrase.phraseIdx, groups), sessionId: phrase.sessionId }));
const getTopN = (idx, groups) => Object.values(groups)
    .map(group => ({ groupId: group.nodeId, phrase: group.phrases.find(({ phraseIdx }) => phraseIdx === idx) }))
    .filter(group => group.phrase !== undefined)
    .map(group => ({ groupId: group.groupId, confidence: Number(group.phrase.confidence) }))
    .sort(byConfidence);
const toPhrase = (phrase, index, confidence, originId) => ({
    id: String(index),
    text: phrase.text,
    status: phrase.status ? phrase.status : PhraseStatusData.P,
    confidence: confidence ? confidence : 0,
    sessionId: originId,
});
const updateStatus = (phrases, id, newStatus) => {
    phrases[clearPrefix(id)].status = newStatus;
    return phrases;
};
export const switchStatuses = (allPhrases, indexes, newStatus) => indexes.reduce((phrases, index) => updateStatus(phrases, index, newStatus), [...allPhrases]);
const applyIfStaged = (phrase) => phrase.status === PhraseStatusData.S ? Object.assign(Object.assign({}, phrase), { status: PhraseStatusData.A }) : Object.assign({}, phrase);
export const applyStaged = (allPhrases) => allPhrases.map(applyIfStaged);
const statusPriority = {
    [PhraseStatusData.S]: 0,
    [PhraseStatusData.P]: 1,
    [PhraseStatusData.D]: 2,
    [PhraseStatusData.A]: 3,
};
const byStatus = (a, b) => statusPriority[a.status] - statusPriority[b.status];
const byConfidence = (a, b) => typeof a.confidence === 'number' && typeof b.confidence === 'number' ? b.confidence - a.confidence : 0;
const byStatusAndConfidence = (a, b) => {
    const statusDiff = byStatus(a, b);
    return statusDiff === 0 ? byConfidence(a, b) : statusDiff;
};
const toPhraseWithTopNFrom = (groups) => (phrase) => (Object.assign(Object.assign({}, phrase), { topN: getTopN(clearPrefix(phrase.id), groups) }));
export const isNotLabeled = (phrase) => !phrase.status || phrase.status === PhraseStatusData.P;
const matchesFilter = (phrase, filter) => phrase.text.toLowerCase().includes(filter.toLowerCase());
export const filterAndOptionallyAddTopN = (phrases, filter, showLabeled, withTopN, groups) => {
    let resultPhrases = phrases;
    if (!showLabeled) {
        resultPhrases = resultPhrases.filter(isNotLabeled);
    }
    if (filter !== '') {
        resultPhrases = resultPhrases.filter(phrase => matchesFilter(phrase, filter));
    }
    if (withTopN && groups) {
        resultPhrases = resultPhrases.map(toPhraseWithTopNFrom(groups));
    }
    return resultPhrases.reduce(addNode, {});
};
const isItemOf = (phrases) => Object.values(phrases).length > 0
    ? (groupPhrase) => phrases[prefixed(groupPhrase.phraseIdx)] !== undefined
    : () => false;
const hasConfidenceAboveOrEqualTo = (confidenceThreshold) => (confidenceThreshold === null || confidenceThreshold === void 0 ? void 0 : confidenceThreshold.min) > 0 || confidenceThreshold.max < 1
    ? (phrase) => phrase.confidence &&
        phrase.confidence >= confidenceThreshold.min &&
        phrase.confidence <= confidenceThreshold.max
    : () => true;
export const filterGroupPhrases = (groupPhrases, phrases, confidenceThreshold) => groupPhrases.filter(isItemOf(phrases)).filter(hasConfidenceAboveOrEqualTo(confidenceThreshold));
export const filterGroupPhrasesAndHideConflicts = (groupPhrases, phrases, confidenceThreshold, groupId) => filterGroupPhrases(groupPhrases, phrases, confidenceThreshold).filter(hasTopConfidenceWith(groupId, phrases));
export const hasTopConfidenceOrWithoutTopN = (phrase, groupId) => !phrase.topN || (phrase.topN[0] && phrase.topN[0].groupId === groupId);
const hasTopConfidenceWith = (groupId, phrases) => (groupPhrase) => hasTopConfidenceOrWithoutTopN(phrases[prefixed(groupPhrase.phraseIdx)], groupId);
export const filterPhrases = (phrases, showLabeled, hideConflicts, filter, confidenceThreshold, currentGroupId) => {
    let phrasesList = Object.values(phrases);
    if (!showLabeled) {
        phrasesList = phrasesList.filter(isNotLabeled);
    }
    if (hideConflicts) {
        phrasesList = phrasesList.filter(phrase => !hasTopConfidenceOrWithoutTopN(phrase, currentGroupId));
    }
    if (filter !== '') {
        phrasesList = phrasesList.filter(phrase => matchesFilter(phrase, filter));
    }
    if (confidenceThreshold) {
        phrasesList = phrasesList.filter(hasConfidenceAboveOrEqualTo(confidenceThreshold));
    }
    return phrasesList.reduce(addNode, {});
};
export const hasNotLabeled = (dataset) => Object.values(dataset).some(isNotLabeled);
export const intentPhrasesToPhrasesDataset = (intent, allPhrases) => {
    if (!intent.phrases)
        return {};
    return intent.phrases
        .map(({ stagedPhraseIdx }) => stagedPhraseIdx)
        .filter(idx => typeof idx === 'number')
        .map(id => (Object.assign({}, allPhrases[Number(id)])))
        .reduce(addNode, {});
};
const toPhraseItemFrom = (allPhrases) => (groupPhrase) => (Object.assign(Object.assign({}, allPhrases[prefixed(groupPhrase.phraseIdx)]), { confidence: groupPhrase.confidence, sessionId: groupPhrase.sessionId }));
const isInDataset = (dataset) => (groupPhrase) => Boolean(dataset[prefixed(groupPhrase.phraseIdx)]);
export const selectGroupPhrases = (groupPhrases, allPhrases) => groupPhrases.filter(isInDataset(allPhrases)).map(toPhraseItemFrom(allPhrases)).reduce(addNode, {});
export const addNode = (dataset, phrase) => Object.assign(dataset, { [prefixed(phrase.id)]: phrase });
const ID_PREFIX = 'phrase_';
const isPrefixed = (id) => id.startsWith(ID_PREFIX);
export const prefixed = (id) => typeof id === 'number' || !isPrefixed(id) ? `${ID_PREFIX}${id}` : `${id}`;
export const clearPrefix = (id) => (isPrefixed(id) ? Number(id.slice(ID_PREFIX.length)) : Number(id));
export const getPhrasesCount = (phrases) => Object.values(phrases).filter(phrase => phrase.status !== PhraseStatusData.A).length;
export const formatConfidence = (confidence) => (confidence ? confidence.toFixed(2) : '0.00');
