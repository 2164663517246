import React, { FC, useMemo } from 'react';
import { JustSelect } from '@just-ai/just-ui';

import { t } from 'localization';

import { EventActivationsGroups, getAllActions, allUniqEvents } from '../EventActivation';
import { CommonActivationParams } from '../CommonActivationParams';
import CustomEventActivationEdit from './CustomEventActivationEdit';

import classes from '../Activations.module.scss';

export interface IEventActivationEdit {
  isFromState: boolean;
  isToState: boolean;
  activationEvent: string;
  onChange: (value: string) => unknown;
  isGlobal: boolean;
  onChangeIsGlobal: (value: boolean) => unknown;
  onChangeOnlyThisState: (value: boolean) => unknown;
  fromStateValue: string;
  toStateValue: string;
  onChangeFromStateValue: (value: string) => unknown;
  onlyThisState?: boolean;
}

const DefaultEventActivationEdit: FC<IEventActivationEdit> = ({
  activationEvent,
  onChange,
  isGlobal,
  onChangeIsGlobal,
  onlyThisState,
  onChangeOnlyThisState,
  fromStateValue,
  toStateValue,
  onChangeFromStateValue,
  isFromState,
  isToState,
}) => {
  const foundActivationEvent = useMemo(() => {
    if (activationEvent) {
      const groupForEvent = EventActivationsGroups.find(group => group.events.indexOf(activationEvent!) > -1);
      if (groupForEvent) {
        return {
          name: activationEvent,
          description: t(`EventActivationItem:title_${groupForEvent.name.replace(/\W/g, '_')}_${activationEvent}`),
        };
      }
    }
    return null;
  }, [activationEvent]);
  const allEventsList = useMemo(() => {
    return getAllActions()
      .flatMap(group => group.events)
      .map(event => ({
        value: event.event,
        label: event.event,
      }));
  }, []);

  return (
    <div className={classes.EventActivation}>
      <JustSelect
        fullWidth
        options={allEventsList}
        value={activationEvent}
        onChange={value => (value && value[0] ? onChange(value[0] as string) : onChange(''))}
        position='fixed'
        data-test-id='EventActivation_Select'
      />
      {foundActivationEvent && (
        <div className={classes.selectedEventDescription}>
          <div>{t(`EventActivation:selected_event_description`)}:</div>
          <div>{foundActivationEvent.description}</div>
        </div>
      )}

      <CommonActivationParams
        isFromState={isFromState}
        isToState={isToState}
        isGlobal={isGlobal}
        onChangeIsGlobal={onChangeIsGlobal}
        onlyThisState={onlyThisState}
        onChangeOnlyThisState={onChangeOnlyThisState}
        fromStateValue={fromStateValue}
        toStateValue={toStateValue}
        onChangeStateValue={onChangeFromStateValue}
      />
    </div>
  );
};

export default function EventActivationEdit(props: IEventActivationEdit) {
  if (!allUniqEvents.includes(props.activationEvent)) {
    return <CustomEventActivationEdit {...props} />;
  }

  return <DefaultEventActivationEdit {...props} />;
}
