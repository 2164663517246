import React, { FC, FunctionComponent, useState } from 'react';
import { Modal, Textarea } from '@just-ai/just-ui';
import { ModalControl, getErrorCodeFromReason } from '../../../Caila/utils';
import { useEditorContext } from '../../context/EditorContext';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { FormGroup, Label, Input } from 'reactstrap';
import { ConflictResolutionType } from '../../api/client';
import FileTree from 'modules/Editor/model/FileTree';

import styles from './style.module.scss';
import { useAppDispatch } from 'storeHooks';
import { getCustomTags } from 'reducers/JGraph.reducer/JGraphAsyncActions';

const CONFLICT_ERROR_CODE = 'editorbe.storage.cannot_push_with_remote_changes';

type CommitDialogProps = {
  control: ModalControl;
  showError: (reason: any) => void;
  commit: (message: string) => Promise<unknown>;
  discard: () => Promise<unknown>;
  resolve: (message: string, resolution: ConflictResolutionType) => Promise<unknown>;
  commitMessage: string;
  setCommitMessage: (value: string) => unknown;
};
type CommitDialogWrapperProps = {
  control: ModalControl;
};

type Mode = 'commit' | 'resolve';
type Resolution = 'reset' | 'integrate' | 'ignoreRemote' | 'newBranch';

const getEditedFilesSummary = (fileTree: FileTree): string => {
  const changedFiles = fileTree.getChangedFilesList();
  if (changedFiles.length === 0) return '';

  const filePaths = changedFiles.map(file => file.path).join('\n    ');
  return 'Edited files:\n    ' + filePaths;
};

const CommitDialogWrapper: FC<CommitDialogWrapperProps> = ({ control }) => {
  const { commit, discard, resolve, fileTree, showError } = useEditorContext();
  const [commitMessage, setCommitMessage] = useState<string>(() => getEditedFilesSummary(fileTree));

  return (
    <CommitDialog
      control={control}
      commit={commit}
      discard={discard}
      resolve={resolve}
      showError={showError}
      commitMessage={commitMessage}
      setCommitMessage={setCommitMessage}
    />
  );
};

export const CommitDialog: FunctionComponent<CommitDialogProps> = ({
  control,
  setCommitMessage,
  commitMessage,
  commit,
  discard,
  resolve,
  showError,
}) => {
  const dispatch = useAppDispatch();
  const { t } = i18nTranslation('Editor');
  const [mode, setMode] = useState<Mode>('commit');
  const [resolution, setResolution] = useState<Resolution>('ignoreRemote');

  const handleSave = () => {
    commit(commitMessage)
      .then(() => control.hide())
      .catch(reason => {
        if (getErrorCodeFromReason(reason) === CONFLICT_ERROR_CODE) setMode('resolve');
        else showError(reason);
      })
      .finally(() => {
        dispatch(getCustomTags({}));
      });
  };

  const handleResolve = () => {
    if (resolution === 'reset') {
      discard().then(() => control.hide());
    } else {
      resolve(commitMessage, resolution as ConflictResolutionType).then(() => control.hide());
    }
  };

  return mode === 'commit' ? (
    <Modal
      isOpen
      className={styles.CommitDialog}
      onCancelClick={control.hide}
      onActionClick={handleSave}
      title={t('commitDialogTitle')}
      buttonSubmitText={t('commitDialogSaveButton')}
      buttonSubmitTestId='Editor.CommitDialog.submit'
      buttonCancelText={t('commitDialogCancelButton')}
      buttonCancelColor='secondary'
      buttonCancelTestId='Editor.CommitDialog.cancel'
      disableActionButtonAutoFocus
      data-test-id='Editor.CommitDialog'
    >
      <label htmlFor='commitText'>{t('commitDialogCommitMessageLabel')}</label>
      <Textarea
        id='commitText'
        autofocus
        placeholder={t('commitDialogCommitMessagePlaceholder')}
        value={commitMessage}
        onChange={setCommitMessage}
        data-test-id='Editor.CommitDialog.commitMessage'
      />
    </Modal>
  ) : (
    <Modal
      isOpen
      onCancelClick={control.hide}
      onActionClick={handleResolve}
      title={t('commitDialogConflictTitle')}
      buttonSubmitText={t('commitDialogFixConflictButton')}
      buttonSubmitTestId='Editor.ConflictDialog.submit'
      buttonCancelText={t('commitDialogCancelButton')}
      buttonCancelTestId='Editor.ConflictDialog.cancel'
      buttonCancelColor='secondary'
      disableActionButtonAutoFocus
      data-test-id='Editor.ConflictDialog'
    >
      {t('commitDialogResolutionAnswer')}
      <FormGroup tag='fieldset'>
        <FormGroup check>
          <Label check>
            <Input
              type='radio'
              name='radio1'
              checked={resolution === 'ignoreRemote'}
              onChange={() => setResolution('ignoreRemote')}
              data-test-id='Editor.ConflictDialog.ignoreRemote'
            />{' '}
            {t('commitDialogResolutionIgnoreRemote')}
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type='radio'
              name='radio1'
              checked={resolution === 'reset'}
              onChange={() => setResolution('reset')}
              data-test-id='Editor.ConflictDialog.reset'
            />{' '}
            {t('commitDialogResolutionReset')}
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type='radio'
              name='radio1'
              checked={resolution === 'integrate'}
              onChange={() => setResolution('integrate')}
              data-test-id='Editor.ConflictDialog.integrate'
            />{' '}
            {t('commitDialogResolutionIntegrate')}
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type='radio'
              name='radio1'
              checked={resolution === 'newBranch'}
              onChange={() => setResolution('newBranch')}
              data-test-id='Editor.ConflictDialog.newBranch'
            />{' '}
            {t('commitDialogResolutionNewBranch')}
          </Label>
        </FormGroup>
      </FormGroup>
    </Modal>
  );
};

export default CommitDialogWrapper;
